
import React from 'react'
import { SvgIcon } from '../../uim-components'


const Icon = (props) => {
  let classes = [
    'icon',
    props.className,
  ].join(' '),
  height = props.height || 24,
  width = props.width || 24,
  fillVal = props.fill ,
  fillOpacity =  props.fillOpacity || 1;
  return (
  
    <svg  className={classes} role={props.role || 'figure'} aria-label={props['aria-label']} width={width} height={height} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
<path d="M5.5 6.84692L3.67468 8.70717C3.29157 9.09761 2.67043 9.09761 2.28733 8.70717C1.90422 8.31674 1.90422 7.68372 2.28733 7.29328L6.5 3L10.7127 7.29328C11.0958 7.68372 11.0958 8.31674 10.7127 8.70717C10.3296 9.09761 9.70843 9.09761 9.32532 8.70717L7.5 6.84692V21H5.5V6.84692ZM16.5 17.1531V3H18.5V17.1531L20.3253 15.2928C20.7084 14.9024 21.3296 14.9024 21.7127 15.2928C22.0958 15.6833 22.0958 16.3163 21.7127 16.7067L17.5 21L13.2873 16.7067C12.9042 16.3163 12.9042 15.6833 13.2873 15.2928C13.6704 14.9024 14.2916 14.9024 14.6747 15.2928L16.5 17.1531Z" fill="#333840"/>
</svg>

  )
}

export default Icon
