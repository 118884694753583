import React from 'react'
import { Route } from 'react-router-dom'
import connect from './../utils/connect'
import ConfigurationContainer from '../components/setupWizard/deviceDiscovery/ConfigurationContainer'


const Container =(props)=>{
    let className = ['main-setupwizard'].join(' ');
    return(
        <main className={className}>
        <ConfigurationContainer acl={props.acl} isAgentSelected={props.selectedAgentInfo!=null}/>
        </main>
    )
}

const ConfigurationContainerRoute = containerProps =>{

        // return <Route path="/discover-devices-tiles" component={Container} />

        return containerProps.acl.canManageDiscovery?(<Route path="/discover-devices-tiles" render={
            (props) => <Container acl={containerProps.acl} selectedAgentInfo={containerProps.selectedAgentInfo}  />
          } />):null

        }


const mapStateToProps = state => {
    return {
        saas: state.saas,
        acl: state.acl,
        selectedAgentInfo:state.agents.selectedAgent,
    }
}

export default connect(mapStateToProps,null)(ConfigurationContainerRoute)
