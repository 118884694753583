import {
  SET_TIME_RANGE_PROP,
  SHOW_TIME_RANGE_SELECT,
  TIME_RANGE_TYPES
} from './actionTypes'

const cabiInput = (state = {timeRange: TIME_RANGE_TYPES.TWO_HOUR, showTimeRange: false,
  start_date: null, end_date: null}, action) => {
  var returnState = state;

  switch (action.type) {
    case SET_TIME_RANGE_PROP:
      returnState = Object.assign({}, state, {
        timeRange: action.timeRange,
        start_date: action.start_date,
        end_date: action.end_date
      });
      break;
    case SHOW_TIME_RANGE_SELECT:
      returnState = Object.assign({}, state, {
        showTimeRange: action.showTimeRange
      });
      break;
  }

  return returnState;
}

export default cabiInput
