// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //settings
  content["Settings"] =
  "Configuración";

  //admin console
  content["Admin Console"] =
  "Consola de administración";

  // END OF LOCALIZATION

export default content;
