import React, { Component } from 'react'
import downloads from '../../api/downloads/downloads'
import { ErrorIcon } from '../ui-components/uim-components'
import {Button as RaisedButton} from '@mineral/core'
import Resource from './Resource'

//If you are not installing with root access, use either sudo
let instMesg = Resource.get('If you are not installing with root access, use either sudo');
//You can also use su to get a root shell and execute the command.
let suMesg = Resource.get('You can also use su to get a root shell and execute the command.');
//Copy the appropriate Windows installer to any folder on the target system:
let winInstMsg = Resource.get('Extract the zip file and copy the appropriate Windows installer to any folder on the target system:');
//Copy the nms-robot-vars.cfg answer file to the same folder. 
let answerMsg = Resource.get('Copy the nms-robot-vars.cfg answer file to the same folder and define the following parameters:');
//update hub ip address in the nms-robot-vars.cfg file
let answerMsgHubDesc = Resource.get('hubip: IP address of the server configured as the relay hub.');
//update robot ip address in the nms-robot-vars.cfg file
let answerMsgRobotDesc = Resource.get('robotip: IP address of the server where you will execute the installer.');
//Install the robot by executing:
let instRobot = Resource.get('Install the robot by executing:');
//Installation
let installation = Resource.get('Installation');
//Robot Installers (including answer file)
let robotInst = Resource.get('Robot Installers (including answer file)');
//The installation is complete. To start the robot, execute the following command:
let instComp = Resource.get('The installation is complete. To start the robot, execute the following command:');
//Execute the RobotConfigurer.sh script to configure the robot when the installer exits.
let instExit = Resource.get('Execute the RobotConfigurer.sh script to configure the robot when the installer exits.');
//The rpm flags function as follows:
let rpmFlag = Resource.get('The rpm flags function as follows:');
//The default install directory is /opt/nimsoft. To specify the installation directory, execute the following command, where <directory> is the full path:
let deftInstDir = Resource.get('The default install directory is /opt/nimsoft. To specify the installation directory, execute the following command, where <directory> is the full path:');
//Copy the appropriate Linux installer to /opt on the target system:
let linuxInst = Resource.get('Extract the zip file and copy the appropriate Linux installer to /opt on the target system:');
//Install the robot with the following command, where &lt;arch&gt;is i386 or x86_64:
let robotInstComm = Resource.get('Install the robot with the following command, where <arch> is i386 or x86_64:');  
//The default install folder is C:\Program Files (x86)\Nimsoft for 32-bit systems, and C:\Program Files\Nimsoft for 64-bit systems.
let instFolder = Resource.get('The default install folder is C:\\Program Files (x86)\\Nimsoft for 32-bit systems, and C:\\Program Files\\Nimsoft for 64-bit systems.');
//To specify the folder, append the following parameter to the command. Quotation marks are required.
let folderSpecify = Resource.get('To specify the folder, append the following parameter to the command. Quotation marks are required.')
//To specify the log file, append:
let logfile = Resource.get('To specify the log file, append:');
//After installation, the robot starts automatically.
let afterInstMsg = Resource.get('After installation, the robot starts automatically.');
//Copy the Debian installer to /opt on the target system:
let debianInst = Resource.get('Extract the zip file and copy the Debian installer to /opt on the target system:');
//Note that only 64 bit Debian systems are supported as robots.
let debianSupp = Resource.get('Note that only 64 bit Debian systems are supported as robots.');
//Install the robot with the following command:
let debianRobot = Resource.get('Install the robot with the following command:');
//Execute the RobotConfigurer.sh script to configure the robot when the installer exits.
let debianInstExit = Resource.get('Execute the RobotConfigurer.sh script to configure the robot when the installer exits.');
//The installation is complete. To start the robot, execute the following command:
let debianInstComp = Resource.get('The installation is complete. To start the robot, execute the following command:');
//Copy the Ubuntu installer to /opt on the target system:
let ubuntuCopy = Resource.get('Extract the zip file and copy the Ubuntu installer to /opt on the target system:');
//Note that only 64 bit Ubuntu systems are supported as robots.
let ubuntusupp = Resource.get('Note that only 64 bit Ubuntu systems are supported as robots.');
// Execute the RobotConfigurer.sh script to configure the robot when the installer exits.
let ubuntuRobConfig = Resource.get('Execute the RobotConfigurer.sh script to configure the robot when the installer exits.');
//(Ubuntu 16 only) Execute the following command to enable the robot service. Note: This step is not required for Ubuntu 14.
let ubuntuEnable = Resource.get('(Ubuntu 16 only) Execute the following command to enable the robot service. Note: This step is not required for Ubuntu 14.');
//Copy the solaris installer to /opt on the target system:
let solarisCopy = Resource.get('Extract the zip file and copy the Solaris installer to /opt on the target system:');
//extract the solaris installer to /opt on the target system:
let solarisExtract = Resource.get('Extract the gz file into to /opt on the target system:');

//Copy the aix installer to /opt on the target system:
let aixInst = Resource.get('Extract the zip file and copy the AIX installer to /opt on the target system:');
//Note that only 64 bit AIX systems are supported as robots.
let aixSupp = Resource.get('Note that only 64 bit AIX systems are supported as robots.');
//Execute the following command as the root user to install the robot to the default directory
let aixRoboIns = Resource.get('Install the robot with the following command as a root user.');
//Enable the Nimbus service as root
let aixServiceRoot = Resource.get('Enable the Nimbus service as root.');
//Start the robot as root. If the robot is configured to run as a nonroot account, the processes are running as the nonroot user.
let aixStartAsRoot =  Resource.get('Installation is complete.Start the robot as root. If the robot is configured to run as a nonroot account, the processes are running as the nonroot user.');
//To run the robot as a nonroot user account, take the following steps
let aixNotRootUser = Resource.get('Steps to run the robot as a nonroot user account');
//Add the nonroot user to
let aixNotRootUserStep1 = Resource.get('Add the nonroot user to');
//Change the ownership of the
let aixNotRootUserStep2 = Resource.get('Change the ownership of the');
//file, and the UIM installation to the nonroot user
let aixNotRootUserStep3 = Resource.get('file, and the UIM installation to the nonroot user:');

class RobotInstallerInstructions extends Component {
  state = {
    downloadLocation: null,
    errorMessage: null,
  }

  getRootNote() {
    return (
      <p className="robot-installer__section-body">
        {instMesg}    
        &lt;command&gt; or su -c "&lt;command&gt;". 
        {suMesg}
      </p>
    )
  }

  getInstallerDownloadButtons(installer) {
    const downloadLoc = this.state.downloadLocation
    const errorMsg = this.state.errorMessage

    if (errorMsg !== null) {
      return (
        <div className="robot-installer__NoDownloadLocation">
          <ErrorIcon className="robot-installer__errorIcon" />
          <p className="robot-installer__errorMessage">{errorMsg}</p>
        </div>
      )
    } else if (installer === 'windows') {
      return (
        <div>
          <RaisedButton
            children="Windows X64"
            variant="contained"
            color="primary"
            disabled={false}
            className="installScreen__button"
            href={downloadLoc + '/rest/v1/download/robot/windows64'}
          />
          <RaisedButton
            children="Windows X86"
            variant="contained"
            color="primary"
            disabled={false}
            className="installScreen__button"
            href={downloadLoc + '/rest/v1/download/robot/windows32'}
          />
        </div>
      )
    } else if (installer === 'debian') {
      return (
        <div>
          <RaisedButton
            children="Debian X64"
            variant="contained"
             color="primary"
            disabled={false}
            className="installScreen__button"
            href={downloadLoc + '/rest/v1/download/robot/debian64'}
          />
        </div>
      )
    } else if (installer === 'ubuntu') {
      return (
        <div>
          <RaisedButton
            children="Ubuntu X64"
            variant="contained"
             color="primary"
            disabled={false}
            className="installScreen__button"
            href={downloadLoc + '/rest/v1/download/robot/ubuntu64'}
          />
        </div>
      )
    }else if (installer === 'solaris') {
        return (
                <div>
                  <RaisedButton
                    children="solaris sparcv9"
                    variant="contained"
                     color="primary"
                    disabled={false}
                    className="installScreen__button"
                    href={downloadLoc + '/rest/v1/download/robot/sparcv9'}
                  />
                </div>
              )
    } else if(installer === 'aix'){
      return (
        <div>
          <RaisedButton
            children="AIX X64"
            variant="contained"
             color="primary"
            disabled={false}
            className="installScreen__button"
            href={downloadLoc + '/rest/v1/download/robot/aix64'}
          />
        </div>
      )
    } else {
      //RHEL & CentOS
      return (
        <div>
          <RaisedButton
            children="RPM X64"
            variant="contained"
             color="primary"
            disabled={false}
            className="installScreen__button"
            href={downloadLoc + '/rest/v1/download/robot/rpm64'}
          />
          <RaisedButton
            children="RPM X86"
            variant="contained"
             color="primary"
            disabled={false}
            className="installScreen__button"
            href={downloadLoc + '/rest/v1/download/robot/rpm32'}
          />
        </div>
      )
    }
  }

  getInstructions(installer) {
    if (installer === 'windows') {
      return (
        <ol>
          <li>
          {winInstMsg}
          </li>
          <ul>
            <li>nimsoft-robot-x64.exe (64-bit)</li>
            <li>nimsoft-robot.exe (32-bit)</li>
          </ul>          
          <li>{answerMsg}</li>
          <ul>
        	<li>{answerMsgHubDesc}</li>
        	<li>{answerMsgRobotDesc}</li>
        </ul>
          <li>{instRobot}</li>
          <p className="robot-installer__cmd">
            {' '}
            &lt;EXE_package&gt;.exe &#x2F;VERYSILENT &#x2F;SUPPRESSMSGBOXES
            &#x2F;NORESTART
          </p>
          {instFolder}
          {folderSpecify}
          <p className="robot-installer__cmd">&#x2F;DIR="c:\path\to\install"</p>
          {logfile}
          <p className="robot-installer__cmd">
            &#x2F;LOG="name_of_install_log.txt"
          </p>            
          <li>{afterInstMsg}</li>
        </ol>
      )
    } else if (installer === 'debian') {
      return (
        <div>
          {this.getRootNote()}
          <ol>
            <li>{debianInst}
            </li>
            <ul>
              <li>nimsoft-robot+debian_amd64.deb (64-bit)</li>
            </ul>{debianSupp}            
            <li>{answerMsg}</li>
            <ul>
            	<li>{answerMsgHubDesc}</li>
            	<li>{answerMsgRobotDesc}</li>
            </ul>            
            <li>{debianRobot}</li>
            <p className="robot-installer__cmd">
              dpkg -i nimsoft-robot+debian_amd64.deb
            </p>
            <li>{debianInstExit}
            </li>
            <p className="robot-installer__cmd">
              cd &#x2F;opt&#x2F;nimsoft&#x2F;install
            </p>
            <p className="robot-installer__cmd">bash ./RobotConfigurer.sh </p>
            <li>
            {debianInstComp}
            </li>
            <p className="robot-installer__cmd">
              {' '}
              &#x2F;etc&#x2F;init.d&#x2F;nimbus start
            </p>
          </ol>
        </div>
      )
    } else if (installer === 'ubuntu') {
      return (
        <div>
          {this.getRootNote()}
          <ol>
            <li>
            {ubuntuCopy}
            </li>
            <ul>
              <li>nimsoft-robot+ubuntu_amd64.deb (64-bit)</li>
            </ul> 
            {ubuntusupp}            
            <li>{answerMsg}</li>
            <ul>
            	<li>{answerMsgHubDesc}</li>
            	<li>{answerMsgRobotDesc}</li>
            </ul> 
            <li>{debianRobot}</li>
            <p className="robot-installer__cmd">
              dpkg -i nimsoft-robot+ubuntu_amd64.deb
            </p>
            <li>
            {ubuntuRobConfig}
            </li>
            <p className="robot-installer__cmd">
              cd &#x2F;opt&#x2F;nimsoft&#x2F;install
            </p>
            <p className="robot-installer__cmd">bash ./RobotConfigurer.sh</p>
            <li>
            {ubuntuEnable}
            </li>
            <p className="robot-installer__cmd">
              &#x2F;opt&#x2F;nimsoft&#x2F;install&#x2F;service-ctrl.sh enable{' '}
            </p>
            <li>
            {debianInstComp}
            </li>
            <p className="robot-installer__cmd">
              {' '}
              &#x2F;opt&#x2F;nimsoft&#x2F;bin&#x2F;niminit start
            </p>
          </ol>
        </div>
      )
    }
    else if (installer === 'solaris') {
        return (
          <div>
            {this.getRootNote()}
            <ol>
              <li>
              {solarisCopy}
              </li>
              <ul>
                <li>nimsoft-robot-sparcv9.gz (sparcv9)</li>
              </ul> 
              <li>{solarisExtract}</li>
              <p className="robot-installer__cmd">
                gunzip nimsoft-robot-sparcv9.gz
              </p> 
              <li>{answerMsg}</li>
              <ul>
              	<li>{answerMsgHubDesc}</li>
              	<li>{answerMsgRobotDesc}</li>
              </ul> 
              <li>{debianRobot}</li>
              <p className="robot-installer__cmd">
              	pkgadd -d nimsoft-robot-sparcv9
              </p>
              <li>
              {ubuntuRobConfig}
              </li>
              <p className="robot-installer__cmd">
                cd &#x2F;opt&#x2F;nimsoft&#x2F;install
              </p>
              <p className="robot-installer__cmd">bash ./RobotConfigurer.sh</p>
              <li>
              {debianInstComp}
              </li>
              <p className="robot-installer__cmd">
                {' '}
                &#x2F;opt&#x2F;nimsoft&#x2F;bin&#x2F;niminit start
              </p>
            </ol>
          </div>
        )
    }
    else if(installer === 'aix'){
      //installing message
		  const M1=  Resource.get('install the software package')
		  //status message
		  const M2 = Resource.get('display a simple status line to show what is being installed (verbose mode)')
		  // display message
		  const  M3 =  Resource.get('display fifty hash marks (#) to show the status as thevinstall proceeds; when all fifty have displayed, installation is complete.')
      return (
      <div>
      {this.getRootNote()}
      <ol>
            <li> {aixInst}
            </li>
            <ul>
              <li>nimsoft-robot.aix6.1.ppc64.rpm (64-bit)</li>
            </ul>            
            <li>{answerMsg}</li>
            <ul>
            	<li>{answerMsgHubDesc}</li>
            	<li>{answerMsgRobotDesc}</li>
            </ul> 
            <li>
            {aixRoboIns}
            </li>
            <p className="robot-installer__cmd">
              rpm -ivh nimsoft-robot.aix6.1.ppc64.rpm
            </p>
              {deftInstDir}
              <p className="robot-installer__cmd">
              rpm -ivh nimsoft-robot.aix6.1.ppc64.rpm --prefix=&lt;directory&gt;
            </p>
            {rpmFlag}
            <ul>
              <li>-i {M1}</li>
              <li>
                -v {M2}
              </li>
              <li>
                -h {M3}
              </li>
            </ul>
            <li>
              {aixNotRootUser}
            </li>
            <ul>
              <li>{aixNotRootUserStep1} /etc/nimbus.conf:
              <p className="robot-installer__cmd">
                echo "NIMBUS_USR=&lt;NonRootUser&gt;" &gt;&gt; /etc/nimbus.conf
              </p>
              </li>
              <li>{aixNotRootUserStep2} /etc/nimbus.conf {aixNotRootUserStep3}
              <p className="robot-installer__cmd">
              chown &lt;NonRootUser&gt; /etc/nimbus.conf
              </p>
              <p className="robot-installer__cmd">
             chown -R &lt;NonRootUser&gt; /UIMHOME
            </p>
              </li>
            </ul>
            <li>
            {instExit}
            </li>
            <p className="robot-installer__cmd">
              cd &#x2F;opt&#x2F;nimsoft&#x2F;install
            </p>
            <p className="robot-installer__cmd">sh RobotConfigurer.sh</p>
            <li>
            {aixServiceRoot}
            </li>
            <p className="robot-installer__cmd">
              /opt/nimsoft/install/service-ctrl.sh enable
            </p>
            <li>
              {aixStartAsRoot}
            </li>
            <p className="robot-installer__cmd">
              /usr/bin/startsrc -s nimbus
            </p>
          </ol>
     </div>
  )
    }
    else {
    	//installing message
		  const M1=  Resource.get('install the software package')
		  //status message
		  const M2 = Resource.get('display a simple status line to show what is being installed (verbose mode)')
		  // display message
		  const  M3 =  Resource.get('display fifty hash marks (#) to show the status as thevinstall proceeds; when all fifty have displayed, installation is complete.')
      return (
    		  
        <div>
          {' '}
          {/*RHEL & Centos*/}
          {this.getRootNote()}
          <ol>
            <li> {linuxInst}
            </li>
            <ul>
              <li>nimsoft-robot.x86_64.rpm (64-bit SuSE, SLES, or RHEL)</li>
              <li>nimsoft-robot.i386.rpm (32-bit SuSE, SLES, or RHEL)</li>
            </ul>            
            <li>{answerMsg}</li>
            <ul>
            	<li>{answerMsgHubDesc}</li>
            	<li>{answerMsgRobotDesc}</li>
            </ul> 
            <li>
            {robotInstComm}
            </li>
            <p className="robot-installer__cmd">
              rpm -ivh nimsoft-robot.&lt;arch&gt;.rpm
            </p>
              {deftInstDir}
              <p className="robot-installer__cmd">
              rpm -ivh nimsoft-robot.&lt;arch&gt;.rpm --prefix=&lt;directory&gt;
            </p>
            {rpmFlag}
            <ul>
              <li>-i {M1}</li>
              <li>
                -v {M2}
              </li>
              <li>
                -h {M3}
              </li>
            </ul>
            <li>
            {instExit}
            </li>
            <p className="robot-installer__cmd">
              cd &#x2F;opt&#x2F;nimsoft&#x2F;install
            </p>
            <p className="robot-installer__cmd">bash ./RobotConfigurer.sh</p>
            <li>
            {instComp}
            </li>
            <p className="robot-installer__cmd">
              &#x2F;etc&#x2F;init.d&#x2F;nimbus start
            </p>
          </ol>
        </div>
      )
    }
  }

  componentWillMount = () => {
    downloads
      .getDownloadLocation()
      .then(response => {
        this.setState({
          downloadLocation: response.data.entity,
          errorMessage: null,
        })
      })
      .catch(error => {
        this.setState({
          downloadLocation: null,
          errorMessage: 'Unable to access installers.',
        })
      })
  }

  render() {
    return (
      <div className="robot-installer__instructions">
        <p className="robot-installer__section-body robot-installer__section-title">
        {robotInst}
        </p>
        {this.getInstallerDownloadButtons(this.props.selectedInstaller)}
        <p className="robot-installer__section-body robot-installer__section-title">
        {installation}
        </p>
        <div className="robot-installer__section-body">
          {this.getInstructions(this.props.selectedInstaller)}
        </div>
      </div>
    )
  }
}
export default RobotInstallerInstructions
