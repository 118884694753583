import React from "react";
import {Menu,MenuItem} from "@mineral/core";
import get from "lodash/get";
import { useDispatch, useSelector } from "react-redux";
import entityTypes from "../../api/entityTypes";
import {
  deleteGroupDialog,
  toggleAddDeviceToGroup,
  toggleAddGroupDialog,
  toggleEditSubGroup,
} from "../../api/treeView/actions";

const ITEM_HEIGHT = 48;

export default function TreeViewActionMenu({
  anchorEl,
  handleActionMenuClose,
}) {
  const open = Boolean(anchorEl);

  const dispatch = useDispatch();

  const entityType = useSelector((state) => state.entity.type);
  const treeView = useSelector((state) => state.tree_view);
  const acl = useSelector((state) => state.acl);
  const entities = useSelector((state) => state.entities);

  const canModifyGroup = get(acl.aclInfo, "roles", "").includes(
    "USM Group Modification"
  );
  const canAddGroup = get(acl.aclInfo, "roles", "").includes("OC Group Add");
  const canEditGroup = get(acl.aclInfo, "roles", "").includes("OC Group Edit");
  const canRemoveGroup = get(acl.aclInfo, "roles", "").includes(
    "OC Group Delete"
  );
  const selectedNodeType = window.location.pathname.endsWith("/treeviewnew")
    ? treeView.treeNode.entityType
    : entityType;
  const groupType = window.location.pathname.endsWith("/treeviewnew")
    ? selectedNodeType
    : entityType;
  let parentGroupType = window.location.pathname.endsWith("/treeviewnew")
    ? treeView.treeNode.parentType
    : "";
  let childrenType = window.location.pathname.endsWith("/treeviewnew")
    ? treeView.treeNode.childrenType
    : "";

  if (!window.location.pathname.endsWith("/treeviewnew")) {
    parentGroupType = "EMPTY";
    childrenType = "";
    if (entityType === entityTypes.CONTAINER && entities.length > 0) {
      childrenType = entities[0].type;
      if (childrenType === "AUTOMATIC") {
        parentGroupType = "AUTOMATIC";
      } else if (
        childrenType === "DYNAMIC" ||
        childrenType === "STATIC" ||
        childrenType === "CONTAINER"
      ) {
        parentGroupType = "NON_REMOVABLE";
      }
    }
  }

  const addGroup =
    (canModifyGroup || canAddGroup) &&
    (groupType === entityTypes.CONTAINER || groupType === entityTypes.ROOT) &&
    !(
      parentGroupType === entityTypes.AUTOMATIC &&
      childrenType === entityTypes.AUTOMATIC
    ) &&
    !window.location.pathname.endsWith("/alarms") &&
    !window.location.pathname.endsWith("/dashboard") &&
    !window.location.pathname.endsWith("/configuration");

  const editGroup =
    (canModifyGroup || canEditGroup) &&
    groupType !== entityTypes.STATIC &&
    groupType !== entityTypes.ROOT &&
    !window.location.pathname.endsWith("/groups/0") &&
    !window.location.pathname.endsWith("/groups/0/alarms") &&
    !window.location.pathname.endsWith("/groups/0/dashboard") &&
    !window.location.pathname.endsWith("/groups/0/card");

  const editGroup2 =
    (canModifyGroup || canEditGroup) &&
    groupType !== entityTypes.CONTAINER &&
    groupType !== entityTypes.ROOT &&
    groupType === entityTypes.STATIC &&
    groupType === entityTypes.AUTOMATIC;

  const deleteGroup =
    (canModifyGroup || canRemoveGroup) &&
    groupType !== entityTypes.ROOT &&
    groupType !== entityTypes.AUTOMATIC &&
    parentGroupType !== "NON_REMOVABLE" &&
    !(
      parentGroupType === entityTypes.AUTOMATIC &&
      childrenType === entityTypes.AUTOMATIC
    ) &&
    !window.location.pathname.endsWith("/groups/0") &&
    !window.location.pathname.endsWith("/groups/0/alarms") &&
    !window.location.pathname.endsWith("/groups/0/cabi") &&
    !window.location.pathname.endsWith("/groups/0/card");

  return (
    <Menu
      id="long-menu"
      anchorEl={anchorEl}
      keepMounted
      open={open}
      onClose={handleActionMenuClose}
      PaperProps={{
        style: {
          maxHeight: ITEM_HEIGHT * 4.5,
          width: "20ch",
        },
      }}
    >
      {addGroup ? (
        <MenuItem
          onClick={() => {
            dispatch(toggleAddGroupDialog(true));
            handleActionMenuClose();
          }}
        >
          Add Group
        </MenuItem>
      ) : null}

      {editGroup ? (
        <MenuItem
          onClick={() => {
            dispatch(toggleEditSubGroup(true));
            handleActionMenuClose();
          }}
        >
          Edit Group
        </MenuItem>
      ) : null}

      {editGroup2 ? (
        <MenuItem
          onClick={() => {
            dispatch(toggleAddDeviceToGroup(true));
            handleActionMenuClose();
          }}
        >
          Edit Group
        </MenuItem>
      ) : null}

      {deleteGroup ? (
        <MenuItem
          onClick={() => {
            dispatch(deleteGroupDialog(true));
            handleActionMenuClose();
          }}
        >
          Delete Group
        </MenuItem>
      ) : null}
      {!(addGroup || editGroup || editGroup2 || deleteGroup) ? (
        <p style={{ padding: "0 5px", margin: 0 }}>No actions</p>
      ) : null}
    </Menu>
  );
}
