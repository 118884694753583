import React, { useState } from 'react'
import PerformanceQosData from './qosData/PerformanceQosData'
import './PerformanceLayout.less'
import PerformanceLayoutSelection from './sidebarPanel/PerformanceLayoutSelection'

const PerformanceLayout = () => {
    const [isToggleQos, setIsToggleQos] = useState(true)

    return (
        
        <div  style={{ display: "flex", height: "100%" }}>

            <PerformanceLayoutSelection isToggleQos={isToggleQos}/>

            <PerformanceQosData isToggleQos={isToggleQos} setIsToggleQos={setIsToggleQos}/>
        </div>
       
    )
}

export default PerformanceLayout
