import {
  FETCH_AG,
  FETCH_AG_SUCCESS,
  FETCH_AG_FAILURE,
  UPDATE_AG,
  UPDATE_AG_SUCCESS,
  UPDATE_AG_FAILURE,
  FETCH_PAG,
  FETCH_PAG_SUCCESS,
  FETCH_PAG_FAILURE,
  UPDATE_PAG,
  UPDATE_PAG_SUCCESS,
  UPDATE_PAG_FAILURE,

} from "./actionTypes";
import automatic_group from "./automatic_group";
import * as IntegrationError from "./../../components/settings/integrations/IntegrationError";

function getResponseError(error) {
  if (error.response.data && error.response.data.message) {
    return error.response.data;
  } else {
    return IntegrationError.getDefaultError();
  }
}

export function fetchAutomaticGroup() {
  return {
    type: FETCH_AG,
  };
}
export function fetchAutomaticGroupSuccess(json) {
  return {
    type: FETCH_AG_SUCCESS,
    automatic_group: json,
  };
}
export function fetchAutomaticGroupFailure(error) {
  return {
    type: FETCH_AG_FAILURE,
    errorMessage: error.message,
  };
}
export function getAutomaticGroup() {
  return (dispatch) => {
    dispatch(fetchAutomaticGroup());
    return (
      automatic_group
        .getAutomaticGroups()
        // .then((response) => response.json())
        .then((response) => response.data._items) // TODO:remove this as it is for mock API
        .then((json) => dispatch(fetchAutomaticGroupSuccess(json)))
        .catch(function (error) {
          dispatch(fetchAutomaticGroupFailure(getResponseError(error)));
        })
    );
  };
}

export function updateAutomaticGroup() {
  return {
    type: UPDATE_AG,
  };
}

export function updateAutomaticGroupSuccess() {
  return {
    type: UPDATE_AG_SUCCESS,
  };
}

export function updateAutomaticGroupFailure(error) {
  return {
    type: UPDATE_AG_FAILURE,
    errorMessage: error.message,
  };
}

export function update(data) {
  return (dispatch) => {
    dispatch(updateAutomaticGroup());
    return automatic_group
      .update(data)
      .then((response) => {
        dispatch(updateAutomaticGroupSuccess());
      })
      .catch(function (error) {
        dispatch(updateAutomaticGroupFailure(error));
      });
  };
}

export function fetchPreConfigureAutomaticGroup() {
  return {
    type: FETCH_PAG,
  };
}
export function fetchPreConfigureGroupSuccess(json) {
  return {
    type: FETCH_PAG_SUCCESS,
    pre_automatic_group: json,
  };
}
export function fetchPreConfigureGroupFailure(error) {
  return {
    type: FETCH_PAG_FAILURE,
    preErrorMessage: error.message,
  };
}

export function getPreConfigureGroup() {
  return (dispatch) => {
    dispatch(fetchPreConfigureAutomaticGroup());
    return (
      automatic_group
        .getPreConfigureGroups()
        // .then((response) => response.json())
        .then((response) => response.data._items) // TODO:remove this as it is for mock API
        .then((json) => dispatch(fetchPreConfigureGroupSuccess(json)))
        .catch(function (error) {
          dispatch(fetchPreConfigureGroupFailure(getResponseError(error)));
        })
    );
  };
}

export function updatePreConfigureGroup() {
  return {
    type: UPDATE_PAG,
  };
}

export function updatePreConfigureGroupSuccess() {
  return {
    type: UPDATE_PAG_SUCCESS,
  };
}

export function updatePreConfigureGroupFailure(error) {
  return {
    type: UPDATE_PAG_FAILURE,
    preErrorMessage: error.message,
  };
}

export function updatePreconfGroup(toCreate,toDelete) {
  return (dispatch) => {
    dispatch(updatePreConfigureGroup());
    return automatic_group
      .updatePreconfGroup(toCreate,toDelete)
      .then((response) => {
        dispatch(updatePreConfigureGroupSuccess());
      })
      .catch(function (error) {
        dispatch(updatePreConfigureGroupFailure(error));
      });
  };
}
