import React, { Component } from 'react'
import connect from './../../utils/connect'
import CabiDetails from './CabiDetails'
import { addBreadCrumb, setBreadCrumbs } from './../breadcrumbs/actions'

class Overview extends Component {
  componentWillMount() {
    this.props.setBreadCrumbs()
  }
  render() {
    return (
      <main className="cabi__main--overview">
        <CabiDetails {...this.props} />
      </main>
    )
  }
}

const mapStateToProps = state => {
  return {
    sid: state.sid,
    saas: state.saas,
    doiurl:state.user.doiurl,
    featureFlags: state.configuration.items
  }
}

const mapDispatchToProps = dispatch => {
  return {
    addBreadCrumb: item => dispatch(addBreadCrumb(item)),
    setBreadCrumbs: crumbs => dispatch(setBreadCrumbs(crumbs)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Overview)
