import React from "react";

const Icon = (props) => {
  let classes = ["icon", props.className].join(" ");
  return (
    <svg
      className={classes}
      role={props.role || "figure"}
      aria-label={props.title}
      width="24px"
      height="16px"
      viewBox="0 0 24 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{props.title}</title>
      <g>
        <polygon points="0 2.1817143 4.36342861 2.1817143 4.36342861 0 0 0"></polygon>
        <polygon points="6.54514291 2.1817143 10.9085715 2.1817143 10.9085715 0 6.54514291 0"></polygon>
        <polygon points="13.0902858 2.1817143 17.4537144 2.1817143 17.4537144 0 13.0902858 0"></polygon>
        <polygon points="19.6354287 2.1817143 23.9988573 2.1817143 23.9988573 0 19.6354287 0"></polygon>
        <polygon points="0 6.54514291 4.36342861 6.54514291 4.36342861 4.36342861 0 4.36342861"></polygon>
        <polygon points="6.54514291 6.54514291 10.9085715 6.54514291 10.9085715 4.36342861 6.54514291 4.36342861"></polygon>
        <polygon points="13.0902858 6.54514291 17.4537144 6.54514291 17.4537144 4.36342861 13.0902858 4.36342861"></polygon>
        <polygon points="19.6354287 6.54514291 23.9988573 6.54514291 23.9988573 4.36342861 19.6354287 4.36342861"></polygon>
        <polygon points="0 10.9085715 4.36342861 10.9085715 4.36342861 8.72685721 0 8.72685721"></polygon>
        <polygon points="6.54514291 10.9085715 10.9085715 10.9085715 10.9085715 8.72685721 6.54514291 8.72685721"></polygon>
        <polygon points="13.0902858 10.9085715 17.4537144 10.9085715 17.4537144 8.72685721 13.0902858 8.72685721"></polygon>
        <polygon points="19.6354287 10.9085715 23.9988573 10.9085715 23.9988573 8.72685721 19.6354287 8.72685721"></polygon>
        <polygon points="0 15.2720001 4.36342861 15.2720001 4.36342861 13.0902858 0 13.0902858"></polygon>
        <polygon points="6.54514291 15.2720001 10.9085715 15.2720001 10.9085715 13.0902858 6.54514291 13.0902858"></polygon>
        <polygon points="13.0902858 15.2720001 17.4537144 15.2720001 17.4537144 13.0902858 13.0902858 13.0902858"></polygon>
        <polygon points="19.6354287 15.2720001 23.9988573 15.2720001 23.9988573 13.0902858 19.6354287 13.0902858"></polygon>
      </g>
    </svg>
  );
};

export default Icon;
