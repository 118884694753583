import React from "react";
import { SvgIcon } from "../ui-components/uim-components";

const Icon = (props) => {
  let classes = ["icon", props.className].join(" ");
  return (
    <SvgIcon {...props} className={classes}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.7025 13.375L21.3098 17.9823C22.2934 18.9659 22.3459 20.5082 21.4271 21.4271C20.5082 22.3459 18.9659 22.2934 17.9823 21.3098L13.375 16.7025L16.7025 13.375ZM19.433 2.57183L15.2507 6.09468L15.2479 8.75202L17.9109 8.75484L21.4281 4.56695C22.5291 6.53642 22.2425 9.07286 20.5682 10.7471C19.0174 12.298 16.7267 12.6582 14.8322 11.8279L5.12772 21.5324C4.39314 22.2669 3.20215 22.2669 2.46756 21.5324C1.73298 20.7978 1.73298 19.6068 2.46756 18.8722L12.1721 9.16771C11.3417 7.27318 11.7019 4.98256 13.2528 3.4317C14.9271 1.75743 17.4635 1.4708 19.433 2.57183ZM2.87362 1.91663L7.59467 4.30868L7.46612 5.86215L9.70829 8.12323L8.13269 9.71211L5.89052 7.45103L4.35082 7.57988L1.91663 2.88169L2.87362 1.91663Z"
          fill="#333840"
        />
      </svg>
    </SvgIcon>
  );
};

export default Icon;
