import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import entityTypes from "./../../api/entityTypes";
import AclTypes from "./../../api/aclTypes";
import {
  AlarmsIcon,
  ErrorIcon as Information,
  Grid,
  Paginate,
  RobotIcon,
} from "../ui-components/uim-components";
import moment from "moment";
import numeral from "numeral";
import each from "lodash/each";
import get from "lodash/get";
import DetailPopUp from "./../groups/detailPopUp/DetailPopUp";
import SeverityIcon from "./../icons/SeverityIcon";
import { monitorProbes as probeTitles } from "@uim/uim-common-strings";
import "./table.less";
import { sortColumns } from "../../utils/tableUtils";
import rowSelectionCache from "../../utils/rowSelectionCache";
import Resource from "./Resource";
import SeverityBadge from "../alarms/SeverityBadges";

class Table extends Component {
  constructor(props) {
    //console.log(props)
    super(props);
    this.pageLimit = 15;
    this.numPages = 0;
    this.groupPage = 0;
    this.updateRowSelectionCache = true;
    this.state = {
      sortedData: this.props.data,
      pageNumber: 0,
      showDialog: false,
      device: null,
      overlayNoRowsDisplay:
        '<div><div class="nohubs__contentImg"></div><span style="color: #333333;font-family: Roboto;font-size: 1.25rem;">No Data found. Try different filter options.</span></div>',
    };
    this.hideCheckboxes = false;
    if (
      (!this.props.saas && this.props.inventorySearch) ||
      this.props.acl.acl === AclTypes.OPERATOR
    ) {
      this.hideCheckboxes = true;
    }
    this.groupCols = [
      {
        headerName: "Alarm Count",
        field: "totalAlarms",
        cellRendererFramework: (params) => {
          return (
            <div style={{ width: "90px", textAlign: "center" }}>
              {params.value}
            </div>
          );
        },
        headerTooltip: "Total Alarm Count",
        // tooltipField: "maxAlarmSeverity.label",
        tooltipValueGetter: (params) =>
          params.data
            ? params.data.totalAlarms
              ? params.data.totalAlarms
              : params.data.totalAlarmCount
            : null,
        suppressMovable: true,
        width: 115,
        minWidth: 115,
        maxWidth: 115,
        sortable: true,
        suppressMenu: true,
        suppressSizeToFit: false,
        resizable: true,
        comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
          const aAlarmCount = nodeA.data.totalAlarms
            ? nodeA.data.totalAlarms
            : nodeA.data.totalAlarmCount;
          const bAlarmCount = nodeB.data.totalAlarms
            ? nodeB.data.totalAlarms
            : nodeB.data.totalAlarmCount;

          return aAlarmCount - bAlarmCount;
        },
        filter: "agNumberColumnFilter",
        filterParams: {
          suppressAndOrCondition: true,
          filterOptions: [
            "greaterThan",
            "greaterThanOrEqual",
            "lessThan",
            "lessThanOrEqual",
          ],
          clearButton: true,
        },
        valueGetter: (params) => {
          return params.data?.totalAlarms
            ? params.data.totalAlarms
            : params.data?.totalAlarmCount;
        },
        headerComponentParams: {
          // Max Alarm Severity
          // icon: <AlarmsIcon title={Resource.get("Max Alarm Severity")} />,
          enableMenu: false,
        },
      },
      {
        headerName: "Max Severity",
        field: "maxAlarmSeverity",
        cellRendererFramework: this.addAlarmIconToData,
        //Highest Alarm Severity Level and Total Alarm Count
        headerTooltip: Resource.get("Highest Alarm Severity Level"),
        // tooltipField: "maxAlarmSeverity.label",
        tooltipValueGetter: (params) =>
          params.data?.maxAlarmSeverity?.label
            ? params.data.maxAlarmSeverity.label
            : "",
        suppressMovable: true,
        suppressSizeToFit: true,
        resizable: true,
        sortable: true,
        filter: "customSetFilter",
        filterParams: {
          values: ["Info", "Warning", "Minor", "Major", "Critical", "None"],
          valueToCompare: function (data, node) {
            return data.maxAlarmSeverity.label;
          },
        },
        comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
          return valueA.localeCompare(valueB);
        },
        valueGetter: (params) => {
          return params.data?.maxAlarmSeverity?.label
            ? params.data.maxAlarmSeverity.label
            : "";
        },
        width: 60,
        minWidth: 50,
        headerComponentParams: (params) => {
          if (params.column.actualWidth > 99) {
            return {
              enableMenu: false,
            };
          } else {
            return {
              enableMenu: false,
              icon: <AlarmsIcon title={Resource.get("Max Alarm Severity")} />,
            };
          }
        },
      },
      {
        headerName: "",
        field: " ",
        cellRendererFramework: this.addInformationIcon,
        suppressResize: true,
        width: 30,
        suppressMovable: true,
        suppressSizeToFit: true,
        sortable: true,
      },
      {
        //Name
        headerName: Resource.get("Name"),
        field: "name",
        tooltipField: "name",
        minWidth: 50,
        sortable: true,
      },
      {
        //Elements
        headerName: Resource.get("Count"),
        field: "memberCount",
        tooltipField: "memberCount",
        minWidth: 50,
        sortable: true,
      },
      {
        //Elements
        headerName: Resource.get("LastUpdate"),
        field: "lastUpdate",
        tooltipField: "memberCount",
        minWidth: 50,
        sortable: true,
      },
    ];

    this.csCols = [
      {
        headerName: "",
        field: "checkbox",
        checkboxSelection: true,
        headerCheckboxSelection: true,
        suppressMenu: true,
        suppressSorting: true,
        suppressResize: true,
        suppressSizeToFit: true,
        width: 50,
      },
      {
        headerName: "Alarm Count",
        field: "totalAlarms",
        cellRendererFramework: (params) => {
          return (
            <div style={{ width: "90px", textAlign: "center" }}>
              {params.value}
            </div>
          );
        },
        headerTooltip: "Total Alarm Count",
        // tooltipField: "maxAlarmSeverity.label",
        tooltipValueGetter: (params) =>
          params.data
            ? params.data.totalAlarms
              ? params.data.totalAlarms
              : params.data.totalAlarmCount
            : null,
        suppressMovable: true,
        width: 115,
        minWidth: 115,
        maxWidth: 115,
        sortable: true,
        suppressMenu: true,
        suppressSizeToFit: false,
        resizable: true,
        comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
          const aAlarmCount = nodeA.data.totalAlarms
            ? nodeA.data.totalAlarms
            : nodeA.data.totalAlarmCount;
          const bAlarmCount = nodeB.data.totalAlarms
            ? nodeB.data.totalAlarms
            : nodeB.data.totalAlarmCount;

          return aAlarmCount - bAlarmCount;
        },
        filter: "agNumberColumnFilter",
        filterParams: {
          suppressAndOrCondition: true,
          filterOptions: [
            "greaterThan",
            "greaterThanOrEqual",
            "lessThan",
            "lessThanOrEqual",
          ],
          clearButton: true,
        },
        valueGetter: (params) => {
          return params.data?.totalAlarms
            ? params.data.totalAlarms
            : params.data?.totalAlarmCount;
        },
        headerComponentParams: {
          // Max Alarm Severity
          // icon: <AlarmsIcon title={Resource.get("Max Alarm Severity")} />,
          enableMenu: false,
        },
      },
      {
        headerName: "Max Severity",
        field: "maxAlarmSeverity",
        cellRendererFramework: this.addAlarmIconToData,
        //Highest Alarm Severity Level and Total Alarm Count
        headerTooltip: Resource.get("Highest Alarm Severity Level"),
        // tooltipField: "maxAlarmSeverity.label",
        tooltipValueGetter: (params) =>
          params.data?.maxAlarmSeverity?.label
            ? params.data.maxAlarmSeverity.label
            : "",
        suppressMovable: true,
        suppressSizeToFit: true,
        resizable: true,
        sortable: true,
        filter: "customSetFilter",
        filterParams: {
          values: ["Info", "Warning", "Minor", "Major", "Critical", "None"],
          valueToCompare: function (data, node) {
            return data.maxAlarmSeverity.label;
          },
        },
        comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
          return valueA.localeCompare(valueB);
        },
        valueGetter: (params) => {
          return params.data?.maxAlarmSeverity?.label
            ? params.data.maxAlarmSeverity.label
            : "";
        },
        width: 60,
        minWidth: 50,
        headerComponentParams: (params) => {
          if (params.column.actualWidth > 99) {
            return {
              enableMenu: false,
            };
          } else {
            return {
              enableMenu: false,
              icon: <AlarmsIcon title={Resource.get("Max Alarm Severity")} />,
            };
          }
        },
      },
      {
        headerName: "",
        field: " ",
        cellRendererFramework: this.addInformationIcon,
        suppressResize: true,
        width: 30,
        suppressMovable: true,
        suppressSizeToFit: true,
        suppressSorting: true,
        minWidth: 50,
        sortable: true,
      },
      {
        //Name
        headerName: Resource.get("Name"),
        field: "name",
        tooltipField: "name",
        minWidth: 50,
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["startsWith", "endsWith", "contains", "equals"],
          suppressAndOrCondition: true,
          newRowsAction: "keep",
          clearButton: true,
        },
        sortable: true,
      },
      {
        //Device Type
        headerName: Resource.get("Device Type"),
        field: "role",
        tooltipField: "role",
        minWidth: 50,
        filter: "customSetFilter",
        filterParams: {
          values: [
            "ApplicationComponent",
            "ApplicationSystem",
            "API Endpoint",
            "AWS DataBase",
            "AWSAutoScalingGroup",
            "Cluster",
            "CiscoUcsManager",
            "DatabaseServer",
            "Device",
            "Firewall",
            "Host",
            "Interface",
            "LoadBalancer",
            "Printer",
            "Router",
            "SecurityAppliance",
            "Storage",
            "StorageSegment",
            "Switch",
            "SwitchRouter",
            "vCenter",
            "VirtualMachine",
            "VirtualMachineHost",
            "WAN",
            "WebServer",
            "WirelessAccessPoint",
          ],
          newRowsAction: "keep",
        },
        sortable: true,
      },
      {
        //Operating system
        headerName: Resource.get("Operating system"),
        field: "osName",
        tooltipField: "osName",
        minWidth: 50,
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["startsWith", "endsWith", "contains", "equals"],
          suppressAndOrCondition: true,
          newRowsAction: "keep",
          clearButton: true,
        },
        sortable: true,
      },
      {
        //IP address
        headerName: Resource.get("IP address"),
        field: "ipAddresses",
        tooltipField: "ipAddresses",
        minWidth: 50,
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["startsWith", "endsWith", "contains", "equals"],
          suppressAndOrCondition: true,
          newRowsAction: "keep",
          clearButton: true,
        },
        sortable: true,
        valueGetter: (model) => {
          let probes = get(model, "data.ipAddresses", []);
          return probes.join(", ");
        },
      },
      {
        //Monitored by
        headerName: Resource.get("Monitored by"),
        field: "attributes.monitoredBy",
        tooltipField: "attributes.monitoredBy",
        filter: "customSetFilter",
        filterParams: {
          values: Object.keys(probeTitles),
          doesFilterPass: function (data, node, selectedValues) {
            let len = selectedValues.length,
              isPresent = false;
            let probes = get(data, "attributes.monitoredBy", []);
            for (let i = 0; i < len; i++) {
              if (probes.includes(selectedValues[i])) {
                isPresent = true;
                break;
              }
            }
            return isPresent;
          },
        },
        minWidth: 50,
        sortable: true,
        valueGetter: (model) => {
          let probes = get(model, "data.attributes.monitoredBy", []);
          return probes.join(", ");
        },
      },
      {
        headerName: "busType",
        field: "busType",
        filter: "customSetFilter",
        filterParams: {
          values: ["Yes", "No"],
          valueToCompare: function (data, node) {
            return data.busType === "hub" || data.busType === "robot"
              ? "Yes"
              : "No";
          },
        },
        cellRendererFramework: (model) => {
          const item = model.data;
          const hasRobot = item.busType === "hub" || item.busType === "robot";
          return hasRobot ? (
            <div title={hasRobot}>
              <RobotIcon title={hasRobot} />
            </div>
          ) : null;
        },
        //Has Robot
        headerTooltip: Resource.get("Has Robot"),
        suppressMovable: true,
        suppressSizeToFit: true,
        suppressSorting: true,
        suppressResize: true,
        width: 75,
        headerComponentParams: {
          //Has Robot
          icon: <RobotIcon title={Resource.get("Has Robot")} />,
          enableMenu: true,
        },
        valueGetter: (model) => {
          const item = model.data;
          const hasRobot = item.busType === "hub" || item.busType === "robot";
          return hasRobot;
        },
      },
      {
        //Discovered
        headerName: Resource.get("Discovered"),
        field: "attributes.create_time",
        sortingOrder: ["asc", "desc", null],
        filter: "customSetFilter",
        filterParams: {
          values: [
            "< 4 hours",
            "< 10 hours",
            " < 1 day",
            " < 2 days",
            " < 1 week",
            " < 1 month",
            " < 3 months",
            " < 6 months",
            " < 1 year",
            " > 1 year",
          ],
          doesFilterPass: function (data, node, selectedValues) {
            const createdTime = parseInt(
              get(data, "attributes.create_time[0]", 0),
              10
            );
            let currentTime = moment();
            let presentTime = currentTime.valueOf();
            let fourHrFromNow = moment().subtract(4, "h");
            let tenHrFromNow = moment().subtract(10, "h");
            let oneDyFromNow = moment().subtract(1, "d");
            let twoDyFromNow = moment().subtract(2, "d");
            let oneWkFromNow = moment().subtract(1, "w");
            let oneMnthFromNow = moment().subtract(1, "M");
            let threeMnthFromNow = moment().subtract(3, "M");
            let sixMnthFromNow = moment().subtract(6, "M");
            let oneYrFromNow = moment().subtract(1, "y");

            let len = selectedValues.length,
              isPresent = false;
            for (let i = 0; i < len; i++) {
              if (
                selectedValues[i] === "< 4 hours" &&
                createdTime > fourHrFromNow
              ) {
                isPresent = true;
                break;
              } else if (
                selectedValues[i] === "< 10 hours" &&
                createdTime > tenHrFromNow
              ) {
                isPresent = true;
                break;
              } else if (
                selectedValues[i] === " < 1 day" &&
                createdTime > oneDyFromNow
              ) {
                isPresent = true;
                break;
              } else if (
                selectedValues[i] === " < 2 days" &&
                createdTime > twoDyFromNow
              ) {
                isPresent = true;
                break;
              } else if (
                selectedValues[i] === " < 1 week" &&
                createdTime > oneWkFromNow
              ) {
                isPresent = true;
                break;
              } else if (
                selectedValues[i] === " < 1 month" &&
                createdTime > oneMnthFromNow
              ) {
                isPresent = true;
                break;
              } else if (
                selectedValues[i] === " < 3 months" &&
                createdTime > threeMnthFromNow
              ) {
                isPresent = true;
                break;
              } else if (
                selectedValues[i] === " < 6 months" &&
                createdTime > sixMnthFromNow
              ) {
                isPresent = true;
                break;
              } else if (
                selectedValues[i] === " < 1 year" &&
                createdTime > oneYrFromNow
              ) {
                isPresent = true;
                break;
              } else if (
                selectedValues[i] === " > 1 year" &&
                createdTime < oneYrFromNow
              ) {
                isPresent = true;
                break;
              }
            }
            return isPresent;
          },
        },
        minWidth: 50,
        sortable: true,
        cellRendererFramework: (model) => {
          const createdTime = parseInt(
            get(model, "data.attributes.create_time[0]", 0),
            10
          );
          const time = moment(createdTime).fromNow();
          const formattedTime = moment(createdTime).format(
            "MMMM Do YYYY, h:mm a"
          );
          let view = (
            <span
              className="ag-cell"
              style={{ width: "100%" }}
              title={formattedTime}
            >
              {time}
            </span>
          );
          if (!createdTime) {
            view = null;
          }
          return view;
        },
      },
    ];
    if (this.hideCheckboxes) {
      this.csCols[0].checkboxSelection = false;
      this.csCols[0].headerCheckboxSelection = false;
    }
  }
  addAlarmIconToData = (model) => {
    var item = model.data;
    // let alarmCount = item.totalAlarmCount;
    // if (item.totalAlarmCount > 1000) {
    //   alarmCount = numeral(item.totalAlarmCount).format("0a") + "+";
    // }
    return (
      <div className="alarm__icon-link">
        {model.column.actualWidth > 99 ? (
          <SeverityBadge level={item.maxAlarmSeverity.value} />
        ) : (
          <SeverityIcon
            level={item.maxAlarmSeverity.value}
            class="grid__icon"
          />
        )}
      </div>
    );
  };
  addInformationIcon = () => {
    return (
      <div className="information__icon--dialog">
        <Information title="Information" />
      </div>
    );
  };
  componentDidMount() {
    this.props.onRef(this);
    window.addEventListener("mouseover", (e) => this.mouseOverClassAddition(e));
    window.addEventListener("mouseout", (e) => this.mouseOutClassDeletion(e));
    window.addEventListener("beforeunload", this.componentCleanup);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.columnDefs !== this.props.columnDefs) {
      this.api.sizeColumnsToFit();
    }
    if (
      this.api &&
      prevProps.visibleDataCount !== this.api.rowModel.rowsToDisplay.length &&
      this.props.handleColumnFilterChange
    ) {
      this.props.handleColumnFilterChange(
        this.api.rowModel.rowsToDisplay.length
      );
    }
  }
  componentCleanup = (onactualUnmount) => {
    rowSelectionCache.clear();
    if (this.props.handleCsRowsSelected) {
      const ids = rowSelectionCache.getData();
      this.props.handleCsRowsSelected(ids);
    }
    window.removeEventListener("beforeunload", this.componentCleanup);
    if (!onactualUnmount) {
      let mountNode = React.findDOMNode(this.refs.viewref);
      if (mountNode) {
        let unmount = React.unmountComponentAtNode(mountNode);
      }
    }
  };
  componentWillReceiveProps(nextProps) {
    this.setState({
      sortedData: nextProps.data,
      pageNumber: 0,
    });
  }
  componentWillUnmount() {
    this.componentCleanup(true);
  }
  handleDrillDown = (row) => {
    //console.log("from handleDrillDowm")
    const history = this.props.history;
    //console.log("from histry",history);
    if (this.props.customRowClicking != undefined) {
      if (!this.props.customRowClicking) {
        let el = row.event.target;
        let columnName = el.closest("[col-id]").getAttribute("col-id");
        let itemType = row.data.entityType;
        if (
          itemType !== entityTypes.COMPUTER_SYSTEM &&
          columnName !== "maxAlarmSeverity.value" &&
          columnName !== " "
        ) {
          this.props.handleGroupCellClick(row, history);
        } else if (columnName === " ") {
          this.handleSetDialogFlag(el.closest("[col-id]"), row);
        } else if (columnName !== "checkbox") {
          if (columnName === "maxAlarmSeverity.value") {
            this.props.handleAlarmCellClick(
              row,
              history,
              this.props.saas,
              this.props.doiurl,
              this.props.featureFlags
            );
          } else {
            this.props.handleCabiCellClick(row, history);
          }
        }
      }
    } else {
      let itemType = this.props.entityType;
      if (row.type != "COMPUTER_SYSTEM") {
        this.props.handleGroupCellClick(row, history);
      } else {
        this.props.handleCabiCellClick(row, history);
      }
    }
  };
  handleExitClickFlag = (element) => {
    this.setState({
      showDialog: false,
    });
  };
  handlePageClick = (page) => {
    this.setState({
      pageNumber: page.selected,
    });
  };
  handleSetDialogFlag = (element, row) => {
    let device = row.data;
    this.setState({
      showDialog: true,
      device: device,
    });
  };
  getColDefs(props) {
    if (props.columnDefs === "groupCols") {
      return this.groupCols;
    } else if (props.columnDefs === "csCols") {
      return this.csCols;
    } else {
      return props.columnDefs;
    }
  }

  mouseOverClassAddition(e) {
    if (e.target && e.target.closest(".ag-cell")) {
      let hoveredCell = e.target.closest(".ag-cell");
      if (e.target.closest(".ag-row")) {
        let children =
          e.target.closest(".ag-row").attributes.class.ownerElement.childNodes;
        if (hoveredCell.getAttribute("col-id") === "maxAlarmSeverity.value") {
          each(children, (child) => {
            if (child.getAttribute("col-id") === "maxAlarmSeverity.value") {
              child.className += " grid__row--hover";
            } else if (
              child.getAttribute("col-id") === "checkbox" &&
              this.hideCheckboxes
            ) {
              child.className += " grid__row--hover";
            }
          });
        } else if (hoveredCell.getAttribute("col-id") === " ") {
          each(children, (child) => {
            if (child.getAttribute("col-id") === " ") {
              child.className += " grid__row--hover";
            }
          });
        } else if (
          hoveredCell.getAttribute("col-id") === "checkbox" &&
          this.hideCheckboxes
        ) {
          each(children, (child) => {
            if (
              child.getAttribute("col-id") === "maxAlarmSeverity.value" ||
              child.getAttribute("col-id") === "checkbox"
            ) {
              child.className += " grid__row--hover";
            }
          });
        } else {
          each(children, (child) => {
            if (
              child.getAttribute("col-id") !== "maxAlarmSeverity.value" &&
              child.getAttribute("col-id") !== " "
            ) {
              if (
                this.hideCheckboxes &&
                child.getAttribute("col-id") === "checkbox"
              ) {
                return;
              } else {
                child.className += " grid__row--hover";
              }
            }
          });
        }
      }
    }
  }
  mouseOutClassDeletion(e) {
    if (e.target && e.target.closest(".ag-cell")) {
      if (e.target.closest(".ag-row")) {
        let children =
          e.target.closest(".ag-row").attributes.class.ownerElement.childNodes;
        each(children, (child) => {
          child.className = child.className.replace(" grid__row--hover", "");
        });
      }
    }
  }
  onGridReady(params) {
    this.api = params.api;
    this.api.sizeColumnsToFit();
    if (this.props.onGridReady) {
      this.props.onGridReady(params);
    }
    setTimeout(
      function () {
        if (
          this.api &&
          this.props.visibleDataCount !==
            this.api.rowModel.rowsToDisplay.length &&
          this.props.handleColumnFilterChange
        ) {
          this.props.handleColumnFilterChange(
            this.api.rowModel.rowsToDisplay.length
          );
        }
      }.bind(this),
      0
    );

    this.updateRowSelection();
  }
  rowDataChanged = () => {
    this.updateRowSelection();
  };
  filterChanged = (params) => {
    this.api = params.api;
    if (this.api.rowModel.rowsToDisplay.length === 0) {
      this.api.showNoRowsOverlay();
    } else {
      this.api.hideOverlay();
    }
    if (
      this.api &&
      this.props.visibleDataCount !== this.api.rowModel.rowsToDisplay.length &&
      this.props.handleColumnFilterChange
    ) {
      this.props.handleColumnFilterChange(
        this.api.rowModel.rowsToDisplay.length
      );
    }
  };

  getNoRowInfo = () => {
    let noDataMsg = Resource.get("No Data Found"); //No Data Found
    let classNames = ["setup-wizard__localprobes__info"];
    let msg2 = Resource.get("Try different filter options"); //Try different filter options
    return (
      <div style={{ position: "relative" }} className={classNames.join(" ")}>
        <div
          style={{
            width: "calc(100% - 60px)",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          <div className="nohubs__contentImg"></div>
          <span className="setup-wizard__localprobes__content">
            <span
              style={{
                padding: "2px 13px 2px 20px",
                borderRight: "2px solid #999999",
              }}
            >
              {noDataMsg}
            </span>
            <span style={{ paddingLeft: "9px" }}>{msg2}</span>
          </span>
        </div>
      </div>
    );
  };

  updateRowSelection = () => {
    setTimeout(
      function () {
        if (this.api) {
          this.updateRowSelectionCache = false;
          rowSelectionCache.updateOnRowDataChanged(this.api);
          this.updateRowSelectionCache = true;
          if (this.api.rowModel.rowsToDisplay.length === 0) {
            this.api.showNoRowsOverlay();
          } else {
            this.api.hideOverlay();
          }
        }
      }.bind(this),
      0
    );
  };
  selectionChanged = () => {
    if (this.updateRowSelectionCache && this.api) {
      rowSelectionCache.onChange(this.api);
    }
    if (this.props.handleCsRowsSelected) {
      const ids = rowSelectionCache.getData();
      this.props.handleCsRowsSelected(ids);
    }
  };
  sortColumns = (column, order) => {
    const newSortedData = sortColumns(column, order, this.state.sortedData);
    this.setState({
      sortedData: newSortedData,
      pageNumber: 0,
    });

    this.updateRowSelection();
  };

  render() {
    // No results found
    const NoReultsMsg = Resource.get("No results found");
    let view;
    const headerHeight = 36;
    const rowHeight = 48;

    let rowData = this.state.sortedData;
    let heightOfDiv = 15 * rowHeight + headerHeight + 30;
    let onSelectionChanged = null;
    let rowSelection = "single";
    if (this.props.columnDefs === "csCols") {
      onSelectionChanged = this.selectionChanged;
      rowSelection = "multiple";
    }

    view = (
      <div ref="viewref">
        <div
          className="ag-material tablecontainer"
          style={{ height: "65vh", width: "94vw" }}
        >
          <Grid
            {...this.props}
            headerHeight={headerHeight}
            rowHeight={rowHeight}
            rowData={rowData}
            columnDefs={this.getColDefs(this.props)}
            onRowClicked={this.handleDrillDown}
            onGridReady={this.onGridReady.bind(this)}
            overlayNoRowsTemplate={this.state.overlayNoRowsDisplay}
            onSelectionChanged={onSelectionChanged}
            onFilterChanged={this.filterChanged.bind(this)}
            onRowDataChanged={this.rowDataChanged}
            rowSelection={rowSelection}
          />
        </div>
        <div className="pagination">
          <DetailPopUp
            entity={this.state.device}
            showDialog={this.state.showDialog}
            handleExitClickFlag={this.handleExitClickFlag}
            featureflag={this.props.featureFlags}
            saas={this.props.saas}
            doiurl={this.props.doiurl}
          />
        </div>
      </div>
    );
    return view;
  }
}

export default withRouter(Table);
