// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Key Icon
  content["Key Icon"] =
  "Key Icon";

  //No Items Available
  content["No Items Available"] =
  "No Items Available";

  //WMI (Windows Management Instrumentation)
  content["WMI (Windows Management Instrumentation)   "] =
  "WMI (Windows Management Instrumentation)   ";

  //discovery scans servers running Windows to gather system information.
  content["discovery scans servers running Windows to gather system information."] =
  "discovery scans servers running Windows to gather system information.";

  //Click + to add a WMI authentication profile, or click on an existing authentication profile to make changes.
  content["to add a WMI authentication profile, or click on an existing authentication profile to make changes."] =
  "to add a WMI authentication profile, or click on an existing authentication profile to make changes.";

  //Select
  content["Select "] =
  "Select ";

  //SSH (Secure Shell)
  content["SSH (Secure Shell) "] =
  "SSH (Secure Shell) ";

  //SNMP
  content["SNMP (Simple Network Management Protocol) discovery supports SNMP versions 1, 2c, and 3 "] =
  "SNMP (Simple Network Management Protocol) discovery supports SNMP versions 1, 2c, and 3 ";

  //Snmp Click acton
  content["to add an SNMP authentication profile, or click on an existing authentication profile to make changes."] =
  "to add an SNMP authentication profile, or click on an existing authentication profile to make changes.";

  //or
  content["or"] =
  "or";

  // Telnet
  content[" Telnet "] =
  " Telnet ";

  // to gather system information.
  content["to gather system information. "] =
  "to gather system information. ";

  //Click + to add a Linux / Unix authentication profile, or click on an existing authentication profile to make changes.
  content["to add a Linux / Unix authentication profile, or click on an existing authentication profile to make changes."] =
  "to add a Linux / Unix authentication profile, or click on an existing authentication profile to make changes.";

  //Click
  content["Click"] =
  "Click";

  //Discovering
  content["Discovering"] =
  "Discovering";

  //Unable to access installers
  content["Unable to access installers."] =
  "Unable to access installers.";

  //Show Only Available
  content["Show Only Available"] =
  "Show Only Available";

  //Waiting for Configuration
  content["Waiting for Configuration"] =
  "Waiting for Configuration";

  //No Schedule
  content["No Schedule"] =
  "No Schedule";

  //Schedule Expired Reconfigure
  content["Schedule Expired, Reconfigure"] =
  "Schedule Expired, Reconfigure";

  //Job Scheduled
  content["Job Scheduled"] =
  "Job Scheduled";

  //Running Discovery
  content["Running Discovery"] =
  "Running Discovery";

  //Unable to Contact Agent
  content["Unable to Contact Agent"] =
  "Unable to Contact Agent";

  //Processsing Results
  content["Processing Results"] =
  "Processing Results";

  //Status Unknown
  content["Status Unknown"] =
  "Status Unknown";

  //Awaiting Agent Status
  content["Awaiting Agent Status"] =
  "Awaiting Agent Status";

  //No  Hubs available. Please download the required  hub installers to proceed
  content["No  Hubs available. Please download the required  hub installers to proceed"] =
  "No  Hubs available. Please download the required  hub installers to proceed";

  //Relay Hub Installers
  content["Download Installers"] =
  "Download Installers";

  //Windows
  content["Windows"] =
  "Windows";

  //Linux
  content["Linux"] =
  "Linux";

  //Configure Discovery
  content["Configure Discovery"] =
  "Configure Discovery";

  //Choose Agent
  content["Choose a Discovery Agent"] =
  "Choose a Discovery Agent";

  //Custom Scope Discovery
  content["Custom Scope Discovery"] =
  "Custom Scope Discovery";

  //Robot Unavailable
  content["Robot Unavailable"] =
  "Robot Unavailable";

  //Discovery Scheduled
  content["Discovery Scheduled"] =
  "Discovery Scheduled";

  //Discovery Schedule Deleted
  content["Discovery Schedule Deleted"] =
  "Discovery Schedule Deleted";

  //Discovery Job Run Successfully
  content["Discovery Job Run Successfully"] =
  "Discovery Job Run Successfully";

  //Next Discovery
  content["Next"] =
  "Next";

  //Finish Discovery
  content["Finish"] =
  "Finish";

  //Configure Discovery
  content["Configure Device Discovery"] =
  "Configure Device Discovery";

  //Configure Another
  content["Configure Another"] =
  "Configure Another";

  //Close
  content["Close"] =
  "Close";

  //Unsaved changes will be lost.Do you want to redirect to agents list
  content["Unsaved changes will be lost. Do you want to continue?"] =
  "Unsaved changes will be lost. Do you want to continue?";

  //Yes
  content["Yes"] =
  "Yes";

  //No
  content["No"] =
  "No";

  //jobConfirmationHeader
  content["View the discovered devices in the Inventory"] =
  "View the discovered devices in the Inventory";

  // Help title
  content["Help"] =
  "Help";

  // Help link text
  content["Click to open Help link"] =
  "Click to open Help link";

  // Confirm
  content["Confirm "] =
  "Confirm ";

  // Exit
  content["Exit"] =
  "Exit";

  // style ={{backgroundColor: '#FFFFFF', ...buttonStyle}}
  //labelStyle={{ color: '#00AEEF' }}
  // className="prev-button"
  // classes={{root:buttonStyle}}
  // label={buttonLabelStyle}
  //Previous Message
  content["Previous"] =
  "Previous";

  //Cancel
  content["Cancel"] =
  "Cancel";

  //Run Discovery
  content["Run Discovery"] =
  "Run Discovery";

  //Error Adding Credentials
  content["Error Adding Credentials"] =
  "Error Adding Credentials";

  //Error Updating Credentials
  content["Error Updating Credentials"] =
  "Error Updating Credentials";

  //Error Deleting Credentials
  content["Error Deleting Credentials"] =
  "Error Deleting Credentials";

  //Remove
  content["Remove"] =
  "Remove";

  //Remove this credential?
  content["Remove this credential?"] =
  "Remove this credential?";

  //New WMI Credential
  content["New SNMP Credential"] =
  "New SNMP Credential";

  //Name
  content["Name"] =
  "Name";

  //ID
  content["ID"] =
  "ID";

  //Password
  content["Password"] =
  "Password";

  //User
  content["User"] =
  "User";

  //Show Password
  content["Show Password"] =
  "Show Password";

  //Required
  content["Required"] =
  "Required";

  //Reset
  content["Reset"] =
  "Reset";

  //Add
  content["Add"] =
  "Add";

  //Update
  content["Update"] =
  "Update";

  //version
  content["Version"] =
  "Version";

  //one
  content["1"] =
  "1";

  //two
  content["2"] =
  "2";

  //three
  content["3"] =
  "3";

  //community string
  content["Community string"] =
  "Community string";

  //Port
  content["Port"] =
  "Port";

  //Method
  content["Method"] =
  "Method";

  //MD5
  content["MD5"] =
  "MD5";

  //SHA
  content["SHA"] =
  "SHA";

  //SHA-256
  content["SHA-256"] =
  "SHA-256";

  //security
  content["Security"] =
  "Security";

  //NOAuthNoPriv
  content["NOAuthNoPriv"] =
  "NOAuthNoPriv";

  //PrivProtocol
  content["Priv.Protocol"] =
  "Priv.Protocol";

  // DES
  content["DES"] =
  "DES";

  //AES
  content["AES"] =
  "AES";

  //AES-256
  content["AES-256"] =
  "AES-256";

  //AuthPriv
  content["AuthPriv"] =
  "AuthPriv";

  //AuthNoPriv
  content["AuthNoPriv"] =
  "AuthNoPriv";

  //priv.password
  content["Priv.Password"] =
  "Priv.Password";

  //Error Icon
  content["Error Icon"] =
  "Error Icon";

  //Delete Icon
  content["Delete Icon"] =
  "Delete Icon";

  //No scopes available
  content["No scopes available. Please configure at least one scope to run discovery"] =
  "No scopes available. Please configure at least one scope to run discovery";

  //New Linux / Unix Credential
  content["Discovery Schedule"] =
  "Discovery Schedule";

  //Description1
  content["You can run discovery immediately and / or in the future."] =
  "You can run discovery immediately and / or in the future.";

  //configure
  content["Configure"] =
  "Configure";

  //Description2label1
  content[" Run Discovery Now "] =
  " Run Discovery Now ";

  //Description2label2
  content["terminates any discovery currently executing and initiates the new discovery."] =
  "terminates any discovery currently executing and initiates the new discovery.";

  //Description2label3
  content[" Scheduled Discovery "] =
  " Scheduled Discovery ";

  //Description2label4
  content["overwrites the previously configured discovery and initiates the latest discovery at the scheduled time."] =
  "overwrites the previously configured discovery and initiates the latest discovery at the scheduled time.";

  //Description2Label5
  content["Configuring a previous date and time always resets it to the current date and time."] =
  "Configuring a previous date and time always resets it to the current date and time.";

  //discoverNow
  content["Run Discovery Now"] =
  "Run Discovery Now";

  //scheduleDiscovery
  content["Schedule Discovery"] =
  "Schedule Discovery";

  //postDiscovery
  content["Post Discovery"] =
  "Post Discovery";

  //installRobot
  content["Install Robot on Discovered Hosts"] =
  "Install Robot on Discovered Hosts";

  //startDate
  content["Starting"] =
  "Starting";

  //time
  content["At"] =
  "At";

  //time
  content["(24 Hour)"] =
  "(24 Hour)";

  //occurance
  content["Recurring Every"] =
  "Recurring Every";

  //Hour(s)
  content["Hour(s)"] =
  "Hour(s)";

  //WindowsRobot
  content["You can auto deploy a Windows robot only on the Windows relay hub."] =
  "You can auto deploy a Windows robot only on the Windows relay hub.";

  // Enter multiple IPs (one per line)
  content["Enter multiple IPs (one per line)"] =
  "Enter multiple IPs (one per line)";

  //Error Adding Scopes
  content["Error Adding Scopes"] =
  "Error Adding Scopes";

  //Error Updating Scopes
  content["Error Updating Scopes"] =
  "Error Updating Scopes";

  //Remove this Scope?
  content["Remove this Scope?"] =
  "Remove this Scope?";

  //Error Deleting Scopes
  content["Error Deleting Scopes"] =
  "Error Deleting Scopes";

  // error text
  content["Invalid IP address (source)"] =
  "Invalid IP address (source)";

  // error text
  content["Invalid IP address (loopback)"] =
  "Invalid IP address (loopback)";

  // error text
  content["Invalid IP address (link-local)"] =
  "Invalid IP address (link-local)";

  // error text
  content["Invalid IP address (anycast)"] =
  "Invalid IP address (anycast)";

  // error text
  content["Invalid IP address (multicast)"] =
  "Invalid IP address (multicast)";

  // error text
  content["Invalid IP address (default route)"] =
  "Invalid IP address (default route)";

  //Enter a valid IP address
  content["Enter a valid IP address"] =
  "Enter a valid IP address";

  // error text
  content["Invalid IP address"] =
  "Invalid IP address";

  // error text
  content["Enter a valid Mask or Range"] =
  "Enter a valid Mask or Range";

  //The number entered is too small
  content["The number entered is too small"] =
  "The number entered is too small";

  // error text
  content["The number entered is too large"] =
  "The number entered is too large";

  //New Range Scope
  content["New Range Scope"] =
  "New Range Scope";

  //Scope Definition
  content["Scope Definition"] =
  "Scope Definition";

  //to
  content["to"] =
  "to";

  //import
  content["Import"] =
  "Import";

  //Network
  content["Network"] =
  "Network";

  //Define network addresses, ranges, or masks where devices are to be discovered.
  content["Define network addresses, ranges, or masks where devices are to be discovered."] =
  "Define network addresses, ranges, or masks where devices are to be discovered.";

  //to add a
  content["to add a "] =
  "to add a ";

  //range scope
  content["range scope"] =
  "range scope";

  //scope welcome
  content[", or click an existing scope to make changes. The credentials allow you to assign any combination of Linux/Unix, and WMI authentication profiles to a scope"] =
  ", or click an existing scope to make changes. The credentials allow you to assign any combination of Linux/Unix, and WMI authentication profiles to a scope";

  //Remove this Scope
  content["Remove this Scope ?"] =
  "Remove this Scope ?";

  //Discovery Scopes
  content["Discovery Scopes"] =
  "Discovery Scopes";

  //Credentials
  content["Credentials"] =
  "Credentials";

  //Linux/Unix
  content["Linux/Unix"] =
  "Linux/Unix";

  //Wmi
  content["WMI"] =
  "WMI";

  //All
  content["All"] =
  "All";

  //SNMPLabel
  content["SNMP"] =
  "SNMP";

  //Hide Unused
  content["Hide Unused"] =
  "Hide Unused";

  //Search
  content["Search"] =
  "Search";

  //New Linux / Unix Credential
  content["New Linux / Unix Credential"] =
  "New Linux / Unix Credential";

  //SSH
  content["SSH"] =
  "SSH";

  //Telnet
  content["Telnet"] =
  "Telnet";

  //New WMI Credential
  content["New WMI Credential"] =
  "New WMI Credential";

  //Non admin User
  content["Non admin User"] =
  "Non admin User";

  //Non admin Password
  content["Non admin Password"] =
  "Non admin Password";

  //Run Robot as non admin user
  content["Run Robot as non admin user"] =
  "Run Robot as non admin user";

  // END OF LOCALIZATION

export default content;
