import React from 'react'
import SavedFilters from './SavedFilters'
import './inventoryFilter.less'
import connect from './../../../utils/connect'
import { showInventoryFilter } from './actions'

const InventoryFilter = ({ show, ...props }) => {
  return (
    <section className={`inventory__filter ${show ? '' : 'hidden'}`} {...props}>
      Inventory Filter
      <SavedFilters />
    </section>
  )
}
InventoryFilter.defaultProps = {
  show: false,
}



const InventoryFilterRedux = (props) => {
  return (
    <InventoryFilter show={props.showFilter} />
  )
}

const mapStateToProps = (state) => {
  return {
    showFilter: state.inventoryFilter.showFilter,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    setShowFilter: (showFilter) => dispatch(showInventoryFilter(showFilter))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(InventoryFilterRedux)