import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './advancedCheckbox.less';
import {Checkbox} from '@mineral/core';

class AdvancedCheckbox extends Component {
  render() {
    if (this.props.icon === undefined) {
      return (
        <div className="closecheckbox-wrapper">
          <Checkbox {...this.props} />
        </div>
      )
    } else {
      return (
        <div className="closecheckbox-wrapper">
          <Checkbox {...this.props} label="" style={{ width: 'auto' }} />
          <div className="closecheckbox-icon-wrapper">
            {this.props.icon}
          </div>
          <label className="closecheckbox-label" style={this.props.labelStyle}>
            {this.props.label}
          </label>
        </div>
      )
    }
  }

  onClick = (...args) => {
    this.handleCheck(args[1], !this.state.checked)
  }

  handleCheck = (event, isChecked) => {
    this.setState({ checked: isChecked })
    if (this.props.onCheck) {
      this.props.onCheck(event, isChecked)
    }
  }

}

AdvancedCheckbox.propTypes = {
  icon: PropTypes.element,
  onCheck: PropTypes.func
}

export default AdvancedCheckbox;
