import React from 'react'
import PropTypes from 'prop-types'
import './iframe.less'

const IFrame = (props) => {
  return (
    <iframe {...props}/>
  )
}

IFrame.propTypes ={
  src: PropTypes.string.isRequired,
}
IFrame.defaultProps = {
  className: 'iframe',
  frameBorder: '0',
  marginHeight: '0',
  marginWidth: '0',
  onLoad: (evt, props) => {
    console.info('IFrame loaded:', evt, props && props.src)
  },
}

export default IFrame
