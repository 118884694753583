import React, { Component } from 'react'
import ParentGroup from './ParentGroup';
import {Box} from '@mineral/core';
import './administration.less';

export class Administration extends Component {
   
    render() {
        
        return (
            <Box className="box-container">
                 <ParentGroup></ParentGroup> 
            </Box>
        )
    }
}

export default Administration
