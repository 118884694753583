import React, { Component } from "react";
import "./credentialVault.less";
import CredentialVaultCatalogRedux from "./CredentialVaultCatalogRedux";
import credentials from "./../../../api/DeviceDiscovery/credentials/credentials";
import Resource from "./Resource";
import CredentialWelcomeText from "./CredentialWelcomeText";


import { Checkbox,Snackbar,SnackbarContent,FormControl,TextField,InputLabel,FormControlLabel,FormLabel,Radio,RadioGroup } from "@mineral/core";
import { CheckBoxOutlineBlankIcon as UnCheckedIcon } from "@material-ui/icons/CheckBoxOutlineBlank";
import deleteIcon from "../../../assets/icons/deleteIcon.svg";
import errorIcon from "../../../assets/icons/error_alert.svg";
import { Button } from "@mineral/core";

import CustomSnackbar from '../../common/CustomSnackbar'
/**
* @Author namam01
*/
class UnixCredentialVault extends Component {
  constructor() {
    super();
    this.state = {
      credentialName: "",
      isCredentialNameValid: true,
      credentialId: "",
      user: "",
      isUserValid: true,
      password: "",
      isPasswordValid: true,
      showPassword: false,
      showWelcomeText: true,
      protocol: "ssh",
      editLabel: "",
      isDirty: false,
      isPasswordTouched: false,
      showRemoveButton: true,
      savepopUpOpen: false,
      popMassage:"",
     popErrorType:'info',
      error: {
        errorOccurred: false,
        errorMessage: "",
        errorDescription: "",
      },
    };
  }
  credentialVaultHandle = null;
  enableWelcomeText = () => {
    this.setState({
      showWelcomeText: true,
    });
  };
  //credential name validation
  handleCredentialNameChange = (e) => {
    let name = e.target.value.trim();
    if (name == "") {
      this.setState({
        credentialName: name,
        isCredentialNameValid: false,
        isDirty: true,
      });
    } else {
      this.setState({
        credentialName: name,
        isCredentialNameValid: true,
        isDirty: true,
      });
    }
  };
  //user name validation
  handleUserNameChange = (e) => {
    let user = e.target.value.trim();
    if (user == "") {
      this.setState({
        user: user,
        isUserValid: false,
        isDirty: true,
      });
    } else {
      this.setState({
        user: user,
        isUserValid: true,
        isDirty: true,
      });
    }
  };
  //password validation
  handlePasswordChange = (e) => {
    let pass = e.target.value.trim();
    let crdId = this.state.credentialId;
    if (pass === "") {
      this.setState({
        password: pass,
        isPasswordValid: false,
        isDirty: true,
        // isPasswordTouched: false,
      });
    } else {
      this.setState({
        password: pass,
        isPasswordValid: true,
        isDirty: true,
        isPasswordTouched: crdId === "" ? false : true,
      });
    }
  };
  SaveOrUpdateTheCredential = (e) => {
    let credentialsInfo = {};
    if (this.state.credentialId == "") {
      //Add
      credentialsInfo.name = this.state.credentialName;
      credentialsInfo.password = this.state.password;
      credentialsInfo.userName = this.state.user;
      credentialsInfo.protocol = this.state.protocol;
      credentials.saveUNIX(credentialsInfo).then(
        (response) => {
          this.SaveOrUpdateTheCredentialSuccess(response.data);
          if (this.credentialVaultHandle) {
            this.credentialVaultHandle.refreshPostCreate(response.data, "UNIX");
          }
          this.setState({
            savepopUpOpen: true,
            popMassage:"Added Successfully",
            popErrorType:'success',
          })
          this.closeError();
        },
        (err) => {
          let errMssg = Resource.get("Error Adding Credentials"); //Error Adding Credentials
          let errorDescription = err.response.data.errorMsg;
          let error = {
            message: errMssg,
            description: errorDescription,
          };
          this.setState({
            savepopUpOpen: true,
            popMassage:errMssg,
            popErrorType:'error',
          })
          this.setError(error);
          //console.log(err)
        }
        );
      } else {
        //edit
        if (this.state.credentialName !== this.state.credentialObject.name) {
          credentialsInfo.name = this.state.credentialName;
        }
        if (this.state.user !== this.state.credentialObject.userName) {
          credentialsInfo.userName = this.state.user;
        }
        if (this.state.password !== this.state.credentialObject.password) {
          credentialsInfo.password = this.state.password;
        }
        if (
          this.state.protocol !==
          this.state.credentialObject.protocol.toLowerCase()
          ) {
            credentialsInfo.protocol = this.state.protocol;
          }
          credentialsInfo.id = this.state.credentialId;
          credentials.editUNIX(credentialsInfo).then(
            (response) => {
              this.SaveOrUpdateTheCredentialSuccess(response.data);
              if (this.credentialVaultHandle) {
                this.credentialVaultHandle.refreshPostUpdate(response.data, "UNIX");
              }
              this.setState({
                savepopUpOpen: true,
                popMassage:"Updated Successfully",
                popErrorType:'success',
              })
              this.closeError();
            },
            (err) => {
              let errMssg = Resource.get("Error Updating Credentials"); //Error Updating Credentials
              let errorDescription = err.response.data.errorMsg;
              let error = {
                message: errMssg,
                description: errorDescription,
              };
              this.setState({
                savepopUpOpen: true,
                popMassage:errMssg,
                popErrorType:'error',
              })
              this.setError(error);
              // console.log(err)
            }
            );
          }
        };
        setError = (error) => {
          this.setState({
            error: {
              errorOccurred: true,
              errorMessage: error.message,
              errorDescription: error.description,
            },
          });
        };
        deleteCredential = () => {
          credentials.deleteUNIX(this.state.credentialId).then(
            (response) => {
              this.setState({
                showWelcomeText: true,
                showRemoveButton: true,
              });
              if (this.credentialVaultHandle) {
                this.credentialVaultHandle.refreshPostDelete(
                  this.state.credentialId,
                  "UNIX"
                  );
                }
                this.setState({
                  savepopUpOpen: true,
                  popMassage:"Removed Successfully",
                  popErrorType:'success',
                })
                this.closeError();
              },
              (err) => {
                let errMssg = Resource.get("Error Deleting Credentials"); //Error Deleting Credentials
                let errorDescription = err.response.data.errorMsg;
                let error = {
                  message: errMssg,
                  description: errorDescription,
                };
                this.setState({
                  savepopUpOpen: true,
                  popMassage:errMssg,
                  popErrorType:'error',
                })
                this.setError(error);
                // console.log(err)
              }
              );
            };
            closeDeleteCredential = () => {
              //console.log('No called check how to close this mess')
              this.setState({
                showRemoveButton: true,
              });
            };
            deleteCredentialConfirmation = () => {
              this.setState({
                showRemoveButton: false,
              });
              //console.log('called delete')
            };
            SaveOrUpdateTheCredentialSuccess = (obj) => {
              this.setState({
                credentialObject: obj,
                credentialName: obj.name,
                credentialId: obj.id,
                user: obj.userName,
                password: obj.password,
                protocol: obj.protocol.toLowerCase(),
                showPassword: false,
                editLabel: obj.name,
                isDirty: false,
                isPasswordTouched: false,
              });
            };
            updateProtocol = (e) => {
              let val = e.target.value.trim();
              this.setState({
                protocol: val,
                isDirty: true,
              });
            };
            handleShowPasswordChange = (e) => {
              let val = e.target.checked;
              this.setState({
                showPassword: val,
                isDirty: true,
              });
            };
            makePasswordTouchedDirty = (e) => {
              if (this.state.credentialId !== "") {
                this.setState({
                  isPasswordTouched: false,
                  password: "",
                  isPasswordValid: false,
                });
              }
            };
            resetTheCredentialForm = () => {
              this.closeError();
              if (this.state.credentialId === "") {
                this.setState({
                  credentialName: "",
                  isCredentialNameValid: true,
                  credentialId: "",
                  user: "",
                  isUserValid: true,
                  password: "",
                  isPasswordValid: true,
                  showPassword: false,
                  showWelcomeText: false,
                  editLabel: "",
                  protocol: "ssh",
                  isDirty: false,
                  isPasswordTouched: false,
                  savepopUpOpen: true,
                  popMassage:"Reset Successfully",
                  popErrorType:'success',
                });
              } else {
                let temp = this.state.credentialObject;
                this.setState({
                  credentialName: temp.name,
                  isCredentialNameValid: true,
                  credentialId: temp.id,
                  user: temp.userName,
                  protocol: temp.protocol.toLowerCase(),
                  isUserValid: true,
                  password: temp.password,
                  isPasswordValid: true,
                  showPassword: false,
                  showWelcomeText: false,
                  editLabel: temp.name,
                  isDirty: false,
                  isPasswordTouched: false,
                  savepopUpOpen: true,
                  popMassage:"Reset Successfully",
                  popErrorType:'success',

                });
              }
            };
            updateCredentialForm = (credObject) => {
              this.closeError();
              if (!credObject) {
                this.setState({
                  credentialObject: {},
                  credentialName: "",
                  editLabel: "",
                  isCredentialNameValid: true,
                  credentialId: "",
                  user: "",
                  isUserValid: true,
                  password: "",
                  protocol: "ssh",
                  isPasswordValid: true,
                  showPassword: false,
                  showWelcomeText: false,
                  isDirty: false,
                  isPasswordTouched: false,
                  showRemoveButton: true,
                });
              } else {
                this.setState({
                  credentialObject: credObject,
                  credentialName: credObject.name,
                  editLabel: credObject.name,
                  protocol: credObject.protocol.toLowerCase(),
                  isCredentialNameValid: true,
                  credentialId: credObject.id,
                  user: credObject.userName,
                  isUserValid: true,
                  password: credObject.password,
                  isPasswordValid: true,
                  showPassword: false,
                  showWelcomeText: false,
                  isDirty: false,
                  isPasswordTouched: false,
                  showRemoveButton: true,
                });
              }
            };
            closeError = () => {
              this.setState({
                error: {
                  errorOccurred: false,
                  errorMessage: "",
                  errorDescription: "",
                },
              });
            };
            getDeleteTag = () => {
              let removeLabel = Resource.get("Remove"); //Remove
              let confirmationMessage = Resource.get("Remove this credential?"); //Remove this credential?
              let yesLabel = Resource.get("Yes"); //Yes
              let noLabel = Resource.get("No"); //No
              if (this.state.showRemoveButton) {
                return (
                  <div style={{ paddingTop: "25px" }}>
                  <Button
                 variant="contained" color="error"
                  
                  onClick={this.deleteCredentialConfirmation}
                  >
                  {removeLabel}
                  </Button>
                  </div>
                  );
                } else {
                  return (
                    <div style={{ paddingTop: "25px" }}>
                    <span style={{ fontSize: "14px", marginRight: "15px" }}>
                    {confirmationMessage}
                    </span>
                    <Button
                    style={{marginRight:'8px'}}
          
                    onClick={this.deleteCredential.bind(this)}
                    >
                    {yesLabel}
                    </Button>
                    <Button
                   
                    onClick={this.closeDeleteCredential.bind(this)}
                    >
                    {noLabel}
                    </Button>
                    </div>
                    );
                  }
                };
                onDescription = () => {
                  this.setState({
                    savepopUpOpen: false,
                  });
                };
                handleRequestClose = () => {
                  this.setState({
                    savepopUpOpen: false,
                  });
                };
                render() {
                  let addHeaderLabel = Resource.get("New Linux / Unix Credential"); //New Linux / Unix Credential
                  let headerLabel =
                  this.state.credentialId === "" ? addHeaderLabel : this.state.editLabel;
                  let nameLabel = Resource.get("Name"); //Name
                  let IDLabel = Resource.get("ID"); //ID
                  let passwordLabel = Resource.get("Password"); //Password
                  let userLabel = Resource.get("User"); //User
                  let showPasswordLabel = Resource.get("Show Password"); //Show Password
                  let sshLabel = Resource.get("SSH"); //SSH
                  let telnetLabel = Resource.get("Telnet"); //Telnet
                  let requiredLabel = Resource.get("Required"); //Required
                  let showWelcomeTxt = this.state.showWelcomeText;
                  let resetLabel = Resource.get("Reset"); //Reset
                  let addLabel = Resource.get("Add"); //Add
                  let updateLabel = Resource.get("Update"); //Update
                  let deleteText = this.getDeleteTag();
                  
                  return (
                    <div className="credential-vault">
                    <CredentialVaultCatalogRedux
                    type="UNIX"
                    handleError={this.setError}
                    refreshWelcome={this.enableWelcomeText}
                    populateCred={this.updateCredentialForm}
                    OnCredintialsReady={(api) => {
                      this.credentialVaultHandle = api;
                    }}
                    />
                    {showWelcomeTxt ? (
                      <CredentialWelcomeText type="UNIX" />
                      ) : (
                        <div className="add-edit-credential" id={"add-edit-credential"}>
                        <h3 className="header"> {headerLabel}</h3>
                        {/*this.state.error.errorOccurred ? (
                          <div className="error-ribbon">
                          <div style={{ display: "inline-block", width: "100%" }}>
                          <div
                          style={{
                            display: "inline-block",
                            marginLeft: "22px",
                            marginRight: "22px",
                            verticalAlign: "middle",
                          }}
                          >
                          <a style={{ float: "left" }}>
                          <img src={errorIcon} />
                          </a>
                          </div>
                          <div style={{ display: "inline-block", width: "90%" }}>
                          <span>{this.state.error.errorMessage}</span>
                          <span
                          style={{
                            borderLeft: "2px solid #999999",
                            display: "inline-block",
                            margin: "10px 15px -5px",
                            paddingTop: "20px",
                          }}
                          ></span>
                          <span>{this.state.error.errorDescription}</span>
                          </div>
                          <div
                          style={{
                            display: "inline-block",
                            cursor: "pointer",
                            marginRight: "20px",
                            marginLeft: "10px",
                            verticalAlign: "middle",
                          }}
                          onClick={() => this.closeError()}
                          >
                          <a style={{ float: "left" }}>
                          {" "}
                          <img src={deleteIcon} />
                          </a>
                          </div>
                          </div>
                          </div>
                          ) : (
                            ""
                            )*/}
                            <div style={{ width: "420px" }}>
                            <FormControl style={{ width: "420px" }}>
            <InputLabel id="input-nameLabel" htmlFor="name" > {nameLabel}<span className="credentialvault-required-label">{requiredLabel}</span></InputLabel>
                          
                           
                          <TextField labelId="input-nameLabel"
                           inputProps={{'aria-required':true}}
                          autoFocus
                          error={!this.state.isCredentialNameValid}
                          fullWidth variant="outlined" size="small"
                           id="name"   type="text"
                           value={this.state.credentialName}
                          // onBlur={this.handleCredentialNameChange.bind(this)}
                onChange={this.handleCredentialNameChange.bind(this)}/>

</FormControl>
                           
                                </div>
                                {this.state.credentialId !== "" ? (
                                  
                                  <div style={{ width: "420px" ,marginTop:'8px' }}>
<FormControl style={{ width: "420px" }}>
            <InputLabel id="input-IDLabel" htmlFor="credentialId" > {IDLabel}</InputLabel>
                          
                           
                          <TextField labelId="input-IDLabel"
                          
                           
                        
                          fullWidth variant="outlined" size="small"
                           id="credentialId"
                           type="text" disabled="true"
                           value={this.state.credentialId}
                          
                          
                          />

</FormControl>
</div>
                                  ) : (
                                    ""
                                    )}
                                    <div style={{ width: "420px" ,marginTop:'8px' }}>
<FormControl style={{ width: "420px" }}>
            <InputLabel id="input-userLabel" htmlFor="user" > {userLabel}<span className="credentialvault-required-label">{requiredLabel}</span></InputLabel>
                          
                           
                          <TextField labelId="input-userLabel"
                           inputProps={{'aria-required':true}}
                           
                          error={!this.state.isUserValid}
                          fullWidth variant="outlined" size="small"
                           id="user"
                           value={this.state.user}
                          onChange={this.handleUserNameChange.bind(this)}/>

</FormControl>
</div>

<div style={{ width: "420px",marginTop:'8px' }}>
<FormControl style={{ width: "420px" }}>
            <InputLabel id="input-passwordLabel" htmlFor="password" > {passwordLabel}<span className="credentialvault-required-label">{requiredLabel}</span></InputLabel>
                          
                           
                          <TextField labelId="input-passwordLabel"
                           inputProps={{'aria-required':true}}
                          
                          error={!this.state.isPasswordValid}
                          fullWidth variant="outlined" size="small"
                           id="password"
                           value={this.state.password}
                           type={this.state.showPassword ? "text" : "password"}
                          onClick={this.makePasswordTouchedDirty.bind(this)}
                onBlur={this.handlePasswordChange.bind(this)}
                onChange={this.handlePasswordChange.bind(this)}
                          />

</FormControl>
</div>
                                           
                                          {this.state.credentialId === "" || this.state.isPasswordTouched ? (    <div style={{marginTop:'8px'}}>


<FormControlLabel
                                                control={
                                                  <Checkbox 
                                                  color="primary"
                                                  classes={{root:"credentialvault-checkbox-container"}}
                                                  checked={this.state.showPassword}
                                                  onChange={this.handleShowPasswordChange.bind(this)}
                                                 
                                                      />}
                                                label={showPasswordLabel}/>
  
</div>):null}
                                              
                                                
                                                <FormControl  style={{marginTop:'8px'}}>
  <InputLabel id="unixProtocol">Select Protocol</InputLabel>
  <RadioGroup row
    aria-labelledby="unixProtocol"
    defaultValue={this.state.protocol}
    name="unixProtocol-group"
  >
    <FormControlLabel style={{marginRight:'16px'}} value="ssh" control={<Radio checked={this.state.protocol === "ssh"} onChange={this.updateProtocol.bind(this)} classes={{root:"credentialvault-checkbox-container"}} />} label={sshLabel}/>
    <FormControlLabel value="telnet" control={<Radio   checked={this.state.protocol === "telnet"} onChange={this.updateProtocol.bind(this)} classes={{root:"credentialvault-checkbox-container"}} />} label={telnetLabel} />
   
  </RadioGroup>
</FormControl>
                                               
                                                {!this.state.isDirty && this.state.credentialId !== "" ? (
                                                  deleteText
                                                  ) : (
                                                    <div style={{ paddingTop: "25px" }}>
                                                    <Button
                                                    style={{marginRight:'8px'}}
                                                    aria-label={this.state.credentialId === "" ? addLabel : updateLabel}
                                                    disabled={
                                                      this.state.credentialName == "" ||
                                                      this.state.user == "" ||
                                                      this.state.password == ""
                                                    }
                                                   
                                                    onClick={this.SaveOrUpdateTheCredential.bind(this)}
                                                    >
                                                    {this.state.credentialId === "" ? addLabel : updateLabel}{" "}
                                                    </Button>
                                                    <Button
                                                    
                                                     aria-label={resetLabel}
                                                     variant="text"
                                                    onClick={this.resetTheCredentialForm.bind(this)}
                                                    >
                                                    <span>{resetLabel}</span>
                                                    </Button>
                                                    </div>
                                                    )}
                                                    </div>
                                                    )}
                                                    {this.state.savepopUpOpen === true  && this.state.popErrorType!=='error' ? (
              <div>
                {/*<Snackbar
                  key="snackbar"
                  open={this.state.savepopUpOpen}
                  autoHideDuration={2000}
                  onClose={() => this.setState({ savepopUpOpen: null })}
                // onActionTouchTap={this.onDescription}
                // onRequestClose={this.handleRequestClose}
                >
                 
                    {this.state.popMassage}
                  
                </Snackbar>*/}
                <CustomSnackbar message={this.state.popMassage} severity={this.state.popErrorType} close={() => this.setState({ savepopUpOpen: null })} autoHideDuration={4000} open={this.state.savepopUpOpen} />
     
              </div>
            ) : ([])}
      <div>
          
          <CustomSnackbar  message={`${this.state.error.errorMessage} ${this.state.error.errorDescription?": "+this.state.error.errorDescription:""}`} severity={"error"} 
          close={() => this.closeError()} autoHideDuration={4000} open={this.state.error.errorOccurred} />
     
        </div>
                                                    </div>
                                                    );
                                                  }
                                                }
                                                export default UnixCredentialVault;
                                                