//Export Preferences
export const EXPORT_PREFERENCES = "Export Preferences"
export const EXPROT_TXT = "Export"
export const ORIENTATION = "Orientation"
export const SIZE = "Size"

//Export Options
export const EXPORT_AS_XML = "Export as XML"
export const EXPORT_AS_PDF = "Export as PDF"

//Orientation
export const LANDSCAPE = "Landscape"
export const PORTRAIT = "Portrait"

//Size
export const A0 = "A0"
export const A1 = "A1"
export const A2 = "A2"
export const A3 = "A3"
export const A4 = "A4"
export const A5 = "A5"
export const LEGAL = "Legal"
export const LETTER = "Letter"
export const TABLOID = "Tabloid"

//Buttons
export const EXPORT_XML = "Export XML"
export const EXPORT_PDF = "Export PDF"
export const CANCEL = "Cancel"