// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Relay Hub Installers
  content["Relay Hub Installers"] =
  "Relay Hub Installers";

  //A relay hub sends metrics and alarms data to your instance of Infrastructure Management.
  content["A relay hub sends metrics and alarms data to your instance of Infrastructure Management."] =
  "A relay hub sends metrics and alarms data to your instance of Infrastructure Management.";

  //Pick the correct installer for your environment
  content["Pick the correct installer for your environment"] =
  "Pick the correct installer for your environment";

  //If you will be monitoring Windows devices or a mix of Windows and Linux devices, use the Windows installer. The Linux relay hub can only monitor Linux servers.
  content["If you will be monitoring Windows devices or a mix of Windows and Linux devices, use the Windows installer. The Linux relay hub can only monitor Linux servers."] =
  "If you will be monitoring Windows devices or a mix of Windows and Linux devices, use the Windows installer. The Linux relay hub can only monitor Linux servers.";

  //If monitoring cloud-based infrastructure, a relay hub is needed for each isolated virtual network (such as AWS Virtual Private Cloud or Azure Virtual Network).
  content["If monitoring cloud-based infrastructure, a relay hub is needed for each isolated virtual network (such as AWS Virtual Private Cloud or Azure Virtual Network)."] =
  "If monitoring cloud-based infrastructure, a relay hub is needed for each isolated virtual network (such as AWS Virtual Private Cloud or Azure Virtual Network).";

  //Ensure the relay hub can phone home
  content["Ensure the relay hub can phone home"] =
  "Ensure the relay hub can phone home";

  //Ports 48000 to 48025 must be open
  content["Ports 48000 to 48025 must be open"] =
  "Ports 48000 to 48025 must be open";

  //for the relay hub to communicate with the Infrastructure Management application.
  content["for the relay hub to communicate with the Infrastructure Management application."] =
  "for the relay hub to communicate with the Infrastructure Management application.";

  //The system where you install the relay hub must meet the following minimum requirements:
  content["The system where you install the relay hub must meet the following minimum requirements:"] =
  "The system where you install the relay hub must meet the following minimum requirements:";

  //Requirements
  content["Requirements"] =
  "Requirements";

  //Infrastructure Management supports the same Windows and Linux x64 operating systems that are supported for the on-premises CA UIM product.
  content["Infrastructure Management supports the same Windows and Linux x64 operating systems that are supported for the on-premises CA UIM product. "] =
  "Infrastructure Management supports the same Windows and Linux x64 operating systems that are supported for the on-premises CA UIM product. ";

  //For more information about which operating systems are supported, refer to the
  content["For more information about which operating systems are supported, refer to the"] =
  "For more information about which operating systems are supported, refer to the";

  //Compatibility Support Matrix
  content["Compatibility Support Matrix"] =
  "Compatibility Support Matrix";

  //Issues?
  content["Issues?"] =
  "Issues?";

  //If the relay hub does not appear within Infrastructure Management under the appropriate Operating System group after 10 minutes,  there is likely a communication issue.
  content["If the relay hub does not appear within Infrastructure Management under the appropriate Operating System group after 10 minutes, there is likely a communication issue."] =
  "If the relay hub does not appear within Infrastructure Management under the appropriate Operating System group after 10 minutes, there is likely a communication issue.";

  //Make sure the relay hub server has an active Internet connection.
  content["Make sure the relay hub server has an active Internet connection."] =
  "Make sure the relay hub server has an active Internet connection.";

  //For more guidance on deploying a relay hub see
  content["For more guidance on deploying a relay hub see"] =
  "For more guidance on deploying a relay hub see";

  //windowslabel message
  content["WINDOWS"] =
  "WINDOWS";

  //linuxlabel message
  content["LINUX"] =
  "LINUX";

  //Install Relay Hub
  content["Install Relay Hub"] =
  "Install Relay Hub";

  //Unable to access installers
  content["Unable to access installers."] =
  "Unable to access installers.";

  //DONE
  content["DONE"] =
  "DONE";

  //Information
  content["Information"] =
  "Information";

  //Home
  content["Home"] =
  "Home";

  // Download and install a relay hub
  content["Download and install a relay hub"] =
  "Download and install a relay hub";

  //4 GB of available memory
  content["4 GB of available memory"] =
  "4 GB of available memory";

  //2 or more CPUs
  content["2 or more CPUs"] =
  "2 or more CPUs";

  //10 GB of available disk space
  content["10 GB of available disk space"] =
  "10 GB of available disk space";

  //Valid external IP address for the system
  content["Valid external IP address for the system"] =
  "Valid external IP address for the system";

  //Ability to connect to the Internet
  content["Ability to connect to the Internet"] =
  "Ability to connect to the Internet";

  //HELP
  content["HELP"] =
  "HELP";

  //Help
  content["Help"] =
  "Help";

  // END OF LOCALIZATION

export default content;
