export const FETCH_SERVICENOW_CONFIG = 'FETCH_SERVICENOW_CONFIG';
export const FETCH_SERVICENOW_CONFIG_SUCCESS = 'FETCH_SERVICENOW_CONFIG_SUCCESS';
export const FETCH_SERVICENOW_CONFIG_FAILURE = 'FETCH_SERVICENOW_CONFIG_FAILURE';
export const SAVE_SERVICENOW_CONFIG = 'SAVE_SERVICENOW_CONFIG';
export const SAVE_SERVICENOW_CONFIG_SUCCESS = 'SAVE_SERVICENOW_CONFIG_SUCCESS';
export const SAVE_SERVICENOW_CONFIG_FAILURE = 'SAVE_SERVICENOW_CONFIG_FAILURE';
export const DELETE_SERVICENOW_CONFIG = 'DELETE_SERVICENOW_CONFIG';
export const DELETE_SERVICENOW_CONFIG_SUCCESS = 'DELETE_SERVICENOW_CONFIG_SUCCESS';
export const DELETE_SERVICENOW_CONFIG_FAILURE = 'DELETE_SERVICENOW_CONFIG_FAILURE';

