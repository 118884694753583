import React, { Component } from "react";
import { connect } from "react-redux";
import { isEqual } from "lodash";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import {
  pollMetricDeviceChartStop,
  fetchMetricDeviceChartPoll,
  getMetricsData,
  getMetricsDevicesData,
  getMetricsDevicesCiNameData,
} from "../../../api/dashboardMetrics/actions";
import {CircularProgress} from "@mineral/core";

import { ClearIcon as Clear } from "../../ui-components/uim-components";
import {
  Card,
  Select as SelectField,
  MenuItem,
  InputLabel,
  FormControl,
  IconButton,
} from "@mineral/core";
import SeverityIcon from "./../../icons/SeverityIcon";

import Resource from "./Resource";
import moment from "moment";
import "./ResourceConsumptionChart.less";

// Metric
const METRIC = Resource.get("Metric");

// Device
const DEVICE = Resource.get("Device");

// Component
const COMPONENT = Resource.get("Component");

// Resource Consumption
const TITLE = Resource.get("Resource Consumption");

const randomColor = [
  "#2F8DFA",
  "#5C6BC0",
  "#AB47BC",
  "#e67e22",
  "#ef5350",
  "#26C6DA",
  "#66BB6A",
  "#D4E157",
  "#00897B",
  "#FFCA28",
  "#FFA726",
  "#FF7043",
  "#8D6E63",
  "#BDBDBD",
  "#78909C",
  "#0099BC",
  "#8E8CD8",
  "#00B294",
  "#10893E",
  "#567C73",
  "#7E735F",
  "#2D7D9A",
  "#767676",
  "#7A7574",
];
const randomColoFn = () => {
  let color =
    "#" +
    ("000000" + Math.floor(Math.random() * 16777215).toString(16)).slice(-6);
  return color;
};

let chartArray = [];
class ResourceConsumptionChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      metricsForDevices: null,
      containerWidth: this.props.fromCsView
        ? 1300
        : this.props.isTreeOpen
        ? 1000
        : 1200,
      containerChartWidth: this.props.fromCsView
        ? 1300
        : this.props.isTreeOpen
        ? 900
        : 1100,
      probeId: props.probeId,
      qosMetric: "",
      device: null,
      ciName: null,
      devices: [],
      ciNames: [],
      hours: "24",
      isInterface: false,
      csIds: [],
      ciNameList: [],
      metrics: [],
      disableCompSelect: false,
    };
  }
  handleChange = (event) => {
    let value = event.target.value;
    let changeKey = event.target.name;
    if (changeKey === "metric-select") {
      this.setState({
        qosMetric: value,
        device: null,
        ciName: null,
        disableCompSelect: true,
      });
      this.props.getMetricsDevicesData(this.state.probeId, value);
    } else {
      if (changeKey === "device-select") {
        let csIds = [];
        csIds.push(value);
        this.setState({
          csIds: csIds,
          device: value,
          ciName: null,
          disableCompSelect: false,
        });
        this.props.getMetricsDevicesCiNameData(
          this.state.probeId,
          this.state.qosMetric,
          value
        );
      } else if (changeKey === "component-select") {
        let ciNameList = [];
        ciNameList.push(value);
        this.setState({ ciNameList: ciNameList, ciName: value });

        this.props.fetchMetricDeviceChartPoll(
          this.state.csIds,
          this.state.qosMetric,
          this.state.hours,
          ciNameList,
          this.state.isInterface,
          Date.now()
        );
      }
    }
  };
  xAxisTickFormatter = (tick) => {
    tick = moment(tick * 1000).format("YYYY-MM-DD HH:mm");
    return tick;
  };
  tooltipLabelFormatter = (val) => {
    val = moment(val * 1000).format("YYYY-MM-DD HH:mm");
    return val;
  };
  getDeviceChartData = (metricDevice) => {
    let lineChartArray = [];
    if (metricDevice.deviceData) {
      metricDevice.deviceData.map((device, i) => {
        let dataKey = device.deviceName;
        if (device.ciName) {
          dataKey = `${dataKey}-${device.ciName}`;
        }
        let lineData = metricDevice
          ? metricDevice.chartDefinationRaw
            ? metricDevice.chartDefinationRaw[dataKey]
            : null
          : null;
        lineChartArray.push(
          <Line
            type="monotone"
            animationDuration={0}
            strokeWidth={2}
            dataKey={dataKey}
            key={dataKey}
            data={lineData}
            dot={false}
            connectNulls={false}
            stroke={randomColor[i] || randomColoFn()}
            fill={randomColor[i] || randomColoFn()}
          />
        );
      });
    }
    return lineChartArray;
  };
  getCharts = () => {
    let context = this;
    chartArray = [];
    if (
      this.props.dashboardMetrics &&
      this.props.dashboardMetrics.metricsForDevices &&
      this.props.dashboardMetrics.metricsForDevices != null
    ) {
      chartArray.push({
        chartName: `${this.props.dashboardMetrics.metricsForDevices.name} (in ${this.props.dashboardMetrics.metricsForDevices.unit})`,
        chartInfo: (
          <LineChart
            width={window.innerWidth / 2 + 30}
            height={200}
            //data={this.props.dashboardMetrics.metricsForDevices.chartDefination}
            key={0}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
            id={`${this.props.dashboardMetrics.metricsForDevices.name}_${0}`}
          >
            <CartesianGrid horizontal={true} vertical={false} />
            <XAxis
              dataKey={
                this.props.dashboardMetrics.metricsForDevices.chartDefinationRaw
                  ? "ts"
                  : "sampleTime"
              }
              interval={"preserveStartEnd"}
              type={
                this.props.dashboardMetrics.metricsForDevices.chartDefinationRaw
                  ? "number"
                  : "category"
              }
              domain={["dataMin", "dataMax"]}
              tickCount={15}
              tickFormatter={(tick) => this.xAxisTickFormatter(tick)}
            />
            <YAxis axisLine={true} tickLine={false} />
            <Tooltip
              labelFormatter={(value) => this.tooltipLabelFormatter(value)}
            />
            <Legend />
            {this.getDeviceChartData(
              this.props.dashboardMetrics.metricsForDevices
            )}
          </LineChart>
        ),
      });
    }

    if (!chartArray.length) {
      return (
        <div
          style={{
            margin: "20px",
            minHeight: "~calc(100vh - 226px)",
            color: "#333333",
          }}
        >
          You have not selected any metric yet.
        </div>
      );
    }

    return (
      <div className={this.props.fromGroup ? "tree-view-style" : ""}>
        {chartArray.map((cData, index) => (
          <div className="timeserie-wrapper" key={index}>
            <div className="title" title={cData.chartName}>
              {cData.chartName}
            </div>
            {cData.chartInfo}
          </div>
        ))}
      </div>
    );
  };
  componentDidMount() {
    this.props.getMetricsData(this.props.probeId);
  }
  componentWillUnmount() {
    this.props.pollMetricDeviceChartStop();
  }
  componentWillReceiveProps(nextProps) {
    if (
      nextProps.dashboardMetrics.metrics &&
      !isEqual(nextProps.dashboardMetrics.metrics, this.state.metrics)
    ) {
      this.setState({
        metrics: nextProps.dashboardMetrics.metrics,
      });
    }

    if (
      nextProps.dashboardMetrics.devices &&
      !isEqual(nextProps.dashboardMetrics.devices, this.state.devices)
    ) {
      let csIds = [];
      if (
        nextProps.dashboardMetrics.devices &&
        nextProps.dashboardMetrics.devices.length > 0
      ) {
        csIds = nextProps.dashboardMetrics.devices.map((device) => device.csId);
      }
      this.setState({
        devices: nextProps.dashboardMetrics.devices,
        csIds: csIds,
      });

      nextProps.dashboardMetrics.devices &&
        this.props.fetchMetricDeviceChartPoll(
          csIds,
          this.state.qosMetric,
          this.state.hours,
          [],
          this.state.isInterface,
          Date.now()
        );
    }
    if (
      nextProps.dashboardMetrics.ciNames &&
      !isEqual(nextProps.dashboardMetrics.ciNames, this.state.ciNames)
    ) {
      let ciNameList = [];
      if (
        nextProps.dashboardMetrics.ciNames &&
        nextProps.dashboardMetrics.ciNames.length > 0
      ) {
        ciNameList = nextProps.dashboardMetrics.ciNames.map((ci) => ci.ciName);
      }
      this.setState({
        ciNames: nextProps.dashboardMetrics.ciNames,
        ciNameList: ciNameList,
      });

      nextProps.dashboardMetrics.ciNames &&
        this.props.fetchMetricDeviceChartPoll(
          this.state.csIds,
          this.state.qosMetric,
          this.state.hours,
          ciNameList,
          this.state.isInterface,
          Date.now()
        );
    }
    if (
      nextProps.dashboardMetrics.metricsForDevices &&
      !isEqual(
        nextProps.dashboardMetrics.metricsForDevices,
        this.state.metricsForDevices
      )
    ) {
      this.setState({
        metricsForDevices: nextProps.dashboardMetrics.metricsForDevices,
      });
    }
  }

  render() {
    let view = null;
    if (
      this.props.dashboardMetrics.isFetchingDevice ||
      this.props.dashboardMetrics.isFetching
    ) {
      view = (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress style={{ color: "#3272D9" }} />
        </div>
      );
    } else {
      view = <div>{this.getCharts()}</div>;
    }
    return (
      <Card
        className={this.props.className}
        style={{
          padding: "18px",
        }}
      >
        <h2 className="overview_container_title">{TITLE}</h2>
        <div
          style={{
            overflow: "hidden auto",
            paddingTop: "5px",
            height:
              window.innerHeight < 800
                ? "23vh"
                : window.innerHeight == 800
                ? "28vh"
                : window.innerHeight < 1080
                ? "31vh"
                : "34vh",
            marginTop: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: "-25px",
              marginBottom: "10px",
            }}
          >
            <FormControl className="resource-consumption-select">
              <InputLabel id="metric-select-label">{METRIC}</InputLabel>

              <SelectField
                labelId="metric-select-label"
                value={this.state.qosMetric}
                onChange={this.handleChange}
                name="metric-select"
              >
                {this.props.dashboardMetrics &&
                  this.props.dashboardMetrics.metrics &&
                  this.props.dashboardMetrics.metrics.map((data, index) => (
                    <MenuItem
                      key={index}
                      //value={`${data.metric_description}(${data.unit}) ${data.qos_name}`}
                      value={data.metric_type_id}
                      children={
                        <div className="resource-consumption">
                          <div className="resource-consumption-descr">
                            <SeverityIcon
                              size="15px"
                              level={
                                data.alarmSeverity
                                  ? data.alarmSeverity.value
                                  : 0
                              }
                              fromDashboard={true}
                            />
                            <span>{`${data.metric_description}(${data.unit})`}</span>
                           
                            <div>{data.qos_name}</div>
                          </div>
                        </div>
                      }
                    />
                  ))}
              </SelectField>
            </FormControl>

            <FormControl className="resource-consumption-select">
              <InputLabel id="device-select-label">{DEVICE}</InputLabel>
              <SelectField
                labelId="device-select-label"
                value={this.state.device}
                onChange={this.handleChange}
                name="device-select"
                selectedmenuitemstyle={{ color: "rgb(0, 174, 239)" }}
                disabled={
                  this.props.dashboardMetrics.devices &&
                  !this.props.dashboardMetrics.devices.length
                }
              >
                {this.props.dashboardMetrics &&
                  this.props.dashboardMetrics.devices &&
                  this.props.dashboardMetrics.devices.map((data, index) => (
                    <MenuItem
                      key={index}
                      value={data.csId}
                      children={
                        <div className="resource-consumption">
                          <div className="resource-consumption-descr">
                            <SeverityIcon
                              size="15px"
                              level={
                                data.alarmSeverity
                                  ? data.alarmSeverity.value
                                  : 0
                              }
                              fromDashboard={true}
                            />
                            <span>{data.csName}</span>
                          </div>
                        </div>
                      }
                    />
                  ))}
              </SelectField>
            </FormControl>

            <FormControl className="resource-consumption-select">
              <InputLabel id="component-select-label">{COMPONENT}</InputLabel>
              <SelectField
                labelId="component-select-label"
                value={this.state.ciName}
                onChange={this.handleChange}
                name="component-select"
                selectedmenuitemstyle={{ color: "rgb(0, 174, 239)" }}
                disabled={
                  (this.props.dashboardMetrics.ciNames &&
                    !this.props.dashboardMetrics.ciNames.length) ||
                  this.state.disableCompSelect
                }
              >
                {this.props.dashboardMetrics &&
                  this.props.dashboardMetrics.ciNames &&
                  this.props.dashboardMetrics.ciNames.map((data, index) => (
                    <MenuItem
                      key={index}
                      value={data.ciName}
                      children={
                        <div className="resource-consumption">
                          <div className="resource-consumption-descr">
                            <SeverityIcon
                              size="15px"
                              level={
                                data.alarmSeverity
                                  ? data.alarmSeverity.value
                                  : 0
                              }
                              fromDashboard={true}
                            />
                            <span>{data.ciName}</span>
                          </div>
                        </div>
                      }
                    />
                  ))}
              </SelectField>
            </FormControl>
            <div style={{ display: "flex", alignItems: "center" }}>
              {!this.props.hideCloseButton && (
                <span className="overview_header_seprator">|</span>
              )}
              {!this.props.hideCloseButton && (
                <Tooltip title="Close">
                <IconButton 
                  style={{ display: "flex", alignItems: "center", padding: 0 }}
                  aria-label={"Close "}
                  onClick={() => {
                    this.props.handleCloseDialog("showProbeMetrics");
                  }}
                >
                  {" "}
                  <Clear className="clearIcon" role="button" title="figure" />
                </IconButton>
                </Tooltip>
              )}
            </div>
          </div>
          {view}
        </div>
      </Card>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    pollMetricDeviceChartStop: () => dispatch(pollMetricDeviceChartStop()),
    fetchMetricDeviceChartPoll: (
      deviceIds,
      metricType,
      hours,
      selectedCIs,
      isInterface,
      endDate
    ) =>
      dispatch(
        fetchMetricDeviceChartPoll(
          deviceIds,
          metricType,
          hours,
          selectedCIs,
          isInterface,
          endDate
        )
      ),
    getMetricsData: (probeId) => dispatch(getMetricsData(probeId)),
    getMetricsDevicesData: (probeId, qosMetric) =>
      dispatch(getMetricsDevicesData(probeId, qosMetric)),
    getMetricsDevicesCiNameData: (probeId, qosMetric, csId) =>
      dispatch(getMetricsDevicesCiNameData(probeId, qosMetric, csId)),
  };
};

const mapStateToProps = (state) => {
  return {
    columns: state.columns,
    dashboardMetrics: state.dashboardMetric,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResourceConsumptionChart);
