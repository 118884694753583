import React, { Component } from "react";
import DeviceList from "./DeviceList";
import MetricList from "./MetricList";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import {
  filterDeviceList,
  getMetricDevices,
  saveMetric,
  fetchAllMetric,
} from "./../../api/deviceMetrics/actions";
import { isEqual } from "lodash";

let selectedDeviceArr = [];

class Group extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDeviceView: !this.props.singleDevice,
      isMetricView: this.props.singleDevice,
      selectedIndices: "",
      isBackClicked: false,
    };
  }

  componentWillMount() {
    this.props.singleDevice &&
      this.props.devices.length &&
      this.openMetric([this.props.isInterface?this.props.contextValue:this.props.devices[0]]);
    !this.props.singleDevice && this.openDevice();
    const advanceFilterText = {
      groupId: this.props.groupId,
      searchText: "",
      advanceFilter: [],
      advanceFilterCondition: "All",
      advanceCustomFilter: "",
      pageNo: 0,
    };
    this.props.filterDeviceList(advanceFilterText);
  }

  openMetric = (selected) => {
    if (selected && selected.length) {

       if (this.props.singleDevice) {
          selectedDeviceArr = selected;
       } else {
         selectedDeviceArr =
           selected[0] === "all"
             ? this.props.searchDeviceResults.map((device) => device.id)
             : this.props.searchDeviceResults
                 .filter((row, index) => selected.indexOf(index) > -1)
                 .map((device) => device.id);
       }

      this.setState({
        selectedIndices: selected,
        isDeviceView: false,
        isMetricView: true,
        isBackClicked: false
      });
    }
  };

  openDevice = (isClicked) => {
    const advanceFilterText = {
      groupId: this.props.groupId,
      searchText: "",
      advanceFilter: [],
      advanceFilterCondition: "All",
      advanceCustomFilter: "",
      pageNo: 0,
    };
    this.props.filterDeviceList(advanceFilterText);
    this.setState({
      isDeviceView: true,
      isMetricView: false,
      isBackClicked: isClicked
    });
  };

  componentWillReceiveProps(nextProps) {
    if (!isEqual(nextProps.devices, this.props.devices)) {
      this.props.toggleDrawer(true);
      this.setState(
        {
          isDeviceView: !nextProps.singleDevice,
          isMetricView: nextProps.singleDevice,
          selectedIndices: "",
        },
        () => {
          !nextProps.singleDevice && this.openDevice();
          if (nextProps.singleDevice && nextProps.devices.length) {
            this.openMetric([nextProps.devices[0]]);
          }
        }
      );
    }
    if (
      this.props.singleDevice != nextProps.singleDevice &&
      nextProps.singleDevice
    ) {
      this.setState({
        selectedIndices: [nextProps.devices[0]],
        isDeviceView: false,
        isMetricView: true,
      });
    }
    if (
      this.props.singleDevice != nextProps.singleDevice &&
      !nextProps.singleDevice
    ) {
      this.openDevice();
    }
  }
  handleSave = (params) => {
    let { selectedMetrics, selectedCIs } = params;
    let reqBody = {
      name: this.props.viewName,
      isDefault: this.props.isDefault ? 1 : 0,
      viewType: this.props.viewType,
      id: this.props.id,
      isGoldenMetricView: 1,
      csId: this.props.contextValue,
      groupId: null,
      definition: JSON.stringify({
        csIds: selectedDeviceArr,
        metrics: selectedMetrics.map((mtrId) => ({
          metricTypeId: mtrId,
          ciNames: selectedCIs[mtrId] ? selectedCIs[mtrId] : [],
        })),
      }),
    };
    if(this.props.masterId)
    {
      reqBody.masterId=this.props.masterId;
      reqBody.csId=this.props.contextValue;
    }
    else if (this.props.fromCsView) {
      reqBody.csId = this.props.contextValue;
    } else if (this.props.fromGroup) {
      reqBody.groupId = this.props.groupId;
    }
    let isEdit = this.props.viewAction === 2;
    this.props
      .saveMetric(reqBody, isEdit)
      .then(() => {
        if (!this.props.deviceMetrics.didInvalidateSave) {
          !this.props.singleDevice && this.openDevice();
          this.props.toggleDrawer();
          this.props.loadedView();
          this.props.getMetricForDevices(
            selectedDeviceArr.join(),
            selectedMetrics,
            this.props.timerange,
            selectedCIs,
            this.props.isInterface,
            this.props.timerangeEndDate,
           this.props.aggregationValue,
           this.props.aggregateFunction
          );
          let body = {};
          if(this.props.isInterface)
          {
          body.masterId = this.props.masterId;
          body.csId=this.props.contextValue;
          }
          if (this.props.fromCsView) {
            body.csId = this.props.contextValue;
          } else if (this.props.fromGroup) {
            body.groupId = this.props.groupId;
          }
          this.props.fetchAllMetric(body);
          
        }
      })
      .catch((err) => console.log("in catch", err));
  };

  render() {
    const { searchDeviceResults, isNextPage } = this.props;
    const { manageViews, deleteView } = this.props;
    return (
      <div>
        {this.state.isDeviceView && (
          <DeviceList
            openMetric={this.openMetric}
            tableData={searchDeviceResults || []}
            isNextPage={isNextPage}
            toggleDrawer={this.props.toggleDrawer}
            selectedIndices={this.state.selectedIndices}
            drawerIsClosing={this.props.drawerIsClosing}
            deviceMetrics={this.props.deviceMetrics}
            viewAction={this.props.viewAction}
            filterDeviceList={this.props.filterDeviceList}
            groupId={this.props.groupId}
            loadedView={this.props.loadedView}
            isBackClicked = {this.state.isBackClicked}
            viewName = {this.props.viewName}
          />
        )}
        {this.state.isMetricView && (
          <MetricList
            selectedDevices={selectedDeviceArr.join()}
            toggleDrawer={this.props.toggleDrawer}
            openDevice={this.openDevice}
            singleDevice={this.props.singleDevice}
            drawerIsClosing={this.props.drawerIsClosing}
            groupId={this.props.groupId}
            groupName={this.props.groupName}
            isInterface={this.props.isInterface}
            devices={this.props.devices}
            viewAction={this.props.viewAction}
            handleSave={this.handleSave}
            manageViews={manageViews}
            deleteView={deleteView}
            loadedView={this.props.loadedView}
            viewName = {this.props.viewName}
          />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    filterDeviceList: (advanceSearchText) =>
      dispatch(filterDeviceList(advanceSearchText)),
    saveMetric: (data, isEdit) => dispatch(saveMetric(data, isEdit)),
    getMetricForDevices: (...data) => dispatch(getMetricDevices(...data)),
    fetchAllMetric: (body) => dispatch(fetchAllMetric(body)),
  };
};

const mapStateToProps = (state) => {
  return {
    deviceMetrics: state.deviceMetrics,
    searchDeviceResults: state.deviceMetrics.searchDeviceResults,
    isNextPage: state.deviceMetrics.isNextPage,
    timerange: state.timerange.hours,
    timerangeEndDate: state.timerange.endDate,
    aggregationValue: state.timerange.aggregationInterval,
    aggregateFunction: state.timerange.aggregationFunction ,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Group));
