import React from 'react';
import PropTypes from 'prop-types';

const TYPE_GOOD = 'good';
const TYPE_ERROR = 'error';
const TYPE_NEW = 'new';

const DEFAULT_ICON_SIZE = '24px';

const IntegrationStateIcon = (props) => {
  var style = {};
  if(props.size) {
    style.width = props.size;
    style.height = props.size;
  } else {
    style.width = DEFAULT_ICON_SIZE;
    style.height = DEFAULT_ICON_SIZE;
  }

  if (props.type === TYPE_GOOD) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" style={style} {...props} >
          <g fill="#5CBA49" fillRule="evenodd" transform="translate(-3 -3)">
            <path d="M17.985,3 C9.705,3 3,9.72 3,18 C3,26.28 9.705,33 17.985,33 C26.28,33 33,26.28 33,18 C33,9.72 26.28,3 17.985,3 Z M18,30 C11.37,30 6,24.63 6,18 C6,11.37 11.37,6 18,6 C24.63,6 30,11.37 30,18 C30,24.63 24.63,30 18,30 Z"/>
            <path d="M19.7352814,21.2352814 L13.7352814,21.2352814 L13.7352814,24.2352814 L22.7352814,24.2352814 L22.7352814,21.9852814 L22.7352814,9.23528137 L19.7352814,9.23528137 L19.7352814,21.2352814 Z" transform="rotate(45 18.235 16.735)"/>
          </g>
        </svg>
    );
  } else if (props.type === TYPE_ERROR) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" style={style} {...props} >
          <path fill="#E63333" fillRule="evenodd"
              d="M16.5,22.5 L19.5,22.5 L19.5,25.5 L16.5,25.5 L16.5,22.5 Z M16.5,10.5 L19.5,10.5 L19.5,19.5 L16.5,19.5 L16.5,10.5 Z M17.985,3 C9.705,3 3,9.72 3,18 C3,26.28 9.705,33 17.985,33 C26.28,33 33,26.28 33,18 C33,9.72 26.28,3 17.985,3 Z M18,30 C11.37,30 6,24.63 6,18 C6,11.37 11.37,6 18,6 C24.63,6 30,11.37 30,18 C30,24.63 24.63,30 18,30 Z"
              transform="translate(-3 -3)"/>
        </svg>
    );
  } else if (props.type === TYPE_NEW) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" style={style} {...props} >
          <path fill="#000000" fillRule="evenodd" d="M19.5,10.5 L16.5,10.5 L16.5,16.5 L10.5,16.5 L10.5,19.5 L16.5,19.5 L16.5,25.5 L19.5,25.5 L19.5,19.5 L25.5,19.5 L25.5,16.5 L19.5,16.5 L19.5,10.5 L19.5,10.5 Z M18,3 C9.75,3 3,9.75 3,18 C3,26.25 9.75,33 18,33 C26.25,33 33,26.25 33,18 C33,9.75 26.25,3 18,3 L18,3 Z M18,30 C11.4,30 6,24.6 6,18 C6,11.4 11.4,6 18,6 C24.6,6 30,11.4 30,18 C30,24.6 24.6,30 18,30 L18,30 Z" transform="translate(-3 -3)" opacity=".54"/>
        </svg>
    );
  } else {
    return null;
  }
}

IntegrationStateIcon.propTypes = {
  type: PropTypes.string.isRequired,
  size: PropTypes.string
}

export default IntegrationStateIcon;
export {TYPE_NEW, TYPE_GOOD, TYPE_ERROR};
