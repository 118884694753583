import React from "react";
import "./titleBarAccount.less";
import Resource from "./Resource";
import ChangePasswordDialog from "./ChangePasswordDialog";
import {
  Menu,
  MenuItem,
  Button as FlatButton,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,Tooltip,Select
} from "@mineral/core";
import {
  Popover,
  List,
  ListItemText,
  ListItem,
  ListItemButton,
  Button,
  IconButton,
} from "@mineral/core";
import { MoreIcon } from "../ui-components/uim-components";
import MenuOpen from "../icons/MenuOpen";
import { Component } from "react";
import { loadCommon, getCookieBaseUrl } from "./../../utils/common";
import logout from "../../api/logout/api";
import { getWalkMeVariables } from "./../../utils/walkMeLoad";
import account from "../../api/account/account";
import { NavLink, Redirect, withRouter } from "react-router-dom";
import isEqual from "lodash/isEqual";
import { Close as CloseIcon } from "@material-ui/icons";
import { Warning as WarningIcon } from "@material-ui/icons";
import Slide from "@mui/material/Slide";
import moment from "moment";
import prd from "../../api/performanceReports/prd";
import CustomSnackbar from "../common/CustomSnackbar"

class TitlebarAccountContainer extends Component {
  state = {
    //Unknown
    userName: Resource.get("Unknown"),
    accountName: "",
    openChangePwd: false,
    openMenu: false,
    anchorEl: null,
    showFloatingMessage: false,
    floatingMessageType:'success',
    floatingMessageValue: "",
    userPreferencesSetDefaultViewStatus: {
      status: "none",
      userpreference: {},
      preferencename: "",
    },
    userPreferencesGlobalPersonalListGetStatus: {
      isFetching: false,
      status: "none",
      userpreferences: { items: [], totalCount: 0 },
    },
    currentPersonalPreference: "",
    dayExpire: 0,
    dateExpire: "",
    openPwdExpireDialog: false,
  };

  Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
  });

  componentDidMount() {
    if (
      this.props.aclAccountUser &&
      this.props.canUpdatePassword &&
      !this.props.isLdapUser
    )
      this.getExpiredPasswordInfo();
  }

  getExpiredPasswordInfo = () => {
    (async () => {
      const result = await prd.expiredPasswordInfo();
      if (result && result.data) {
        if (result.data.showExpireNotification) {
          this.setState({
            openPwdExpireDialog: true,
            dayExpire: result.data.daysLeftToExpire,
            dateExpire: moment(result.data.passwordExpirationDate).format(
              "llll"
            ),
          });
        } else {
          this.setState({ openPwdExpireDialog: false });
        }
      }
    })();
  };

  openChangePwdDialog = () => {
    this.handleChangePwdOpen();
    this.closeExpireDialog();
  };

  closeExpireDialog = () => {
    this.setState({ openPwdExpireDialog: false });
  };

  handleClick = (event) => {
    // This prevents ghost click.
    event.preventDefault();

    this.setState({
      openMenu: true,
      anchorEl: event.currentTarget,
    });
  };

  handleRequestClose = () => {
    this.setState({
      openMenu: false,
      anchorEl: null,
    });
  };

  componentWillMount() {
    let walkMeVariables = getWalkMeVariables();
    if (this.props.saas) {
      loadCommon().then((ca_cloud) => {
        ca_cloud.getUser().then((response) => {
          this.setState({
            userName: response.data.name,
            accountName: response.data.accountName,
          });
          walkMeVariables.userName = response.data.name;
          walkMeVariables.tenant = response.data.tenantId;
        });
      });
    } else {
      account.getUser().then((response) => {
        this.setState({
          userName: response.data.username,
          accountName: response.data.accountName,
        });
      });
    }
  }

  onFloatingMessageClose = () => {
    this.setState({ showFloatingMessage: false, floatingMessageValue: "",floatingMessageType:'success' });
  };

  handleUserpErson = () => {
    const params = [
      {
        pref_id: this.state.pref_id,
        pref_level: this.state.valueSelected,
        "@landing_page": this.state.dropdownValueSelected,
      },
    ];
  };

  handleLogout = () => {
    if (!this.props.saas) {
      let baseUrl = "/operatorconsole_portlet/standalone_login.jsp";
      logout
        .delete()
        .then((response) => {
          window.location = baseUrl;
        })
        .catch((error) => {
          window.location = baseUrl;
        });
    } else {
      loadCommon().then((ca_cloud) => {
        ca_cloud
          .logout()
          .then((response) => {
            logout.delete();
            window.location = response.data.redirectUrl;
          })
          .catch((error) => {
            const baseUrl = getCookieBaseUrl();
            window.location = baseUrl + "/sppclient";
          });
      });
    }
  };

  handler = (e) => {
    if (e.key === 'Escape') {
      this.handleChangePwdClose();
      window.removeEventListener('keyup',this.handler)
    }
  }
  //dp039841
  handleChangePwdOpen = () =>{
   this.setState ({openChangePwd:true, openMenu: false, anchorEl: null})
   window.addEventListener('keyup',this.handler)
  }

  closeMenu = () => {
    this.setState({ openMenu: false });
  };

  handleChangePwdClose = () => {
    this.setState({ openChangePwd: false });
  };

  componentWillReceiveProps(nextProps) {
    if (
      !isEqual(
        this.state.userPreferencesSetDefaultViewStatus,
        nextProps.userpreferences.userPreferencesSetDefaultViewStatus
      )
    ) {
      this.setState({
        userPreferencesSetDefaultViewStatus:
          nextProps.userpreferences.userPreferencesSetDefaultViewStatus,
      });

      if (
        nextProps.userpreferences.userPreferencesSetDefaultViewStatus.status ==
        "success"
      ) {
        let message = Resource.get(
          "Personal User preference saved successfully"
        ); //PersonalUserPrefsSuccess
        this.setState({
          showFloatingMessage: true,
          floatingMessageValue: message,
          floatingMessageType:'success'
        });
        this.props.fetchGlobalAndPersonalUserPreferences();
      } else if (
        nextProps.userpreferences.userPreferencesSetDefaultViewStatus.status ==
        "failure"
      ) {
        let message = Resource.get("Failed to save personal user preference"); //PersonalUserPrefsFailure
        this.setState({
          showFloatingMessage: true,
          floatingMessageValue: message,
          floatingMessageType:'error'
        });
      }
    }

    if (
      !isEqual(
        this.state.userPreferencesGlobalPersonalListGetStatus,
        nextProps.userpreferences.userPreferencesGlobalPersonalListGetStatus
      )
    ) {
      this.setState({
        userPreferencesGlobalPersonalListGetStatus:
          nextProps.userpreferences.userPreferencesGlobalPersonalListGetStatus,
      });
      if (
        nextProps.userpreferences.userPreferencesGlobalPersonalListGetStatus
          .status == "success"
      ) {
        if (
          nextProps.userpreferences.userPreferencesGlobalPersonalListGetStatus
            .userpreferences.length != 0
        ) {
          let prefFound = false;
          for (let userPreference of nextProps.userpreferences
            .userPreferencesGlobalPersonalListGetStatus.userpreferences) {
            if ("Personal" == userPreference.preferenceLevel) {
              prefFound = true;
              this.setState({
                currentPersonalPreference: userPreference.landingPage,
              });
              break;
            }
          }
        }
      }
    }
  }

  makeThisMyDefaultView = () => {
    if (!window.location.pathname.endsWith("/personalize")) {
      let landingPage = "";
      if (window.location.pathname.endsWith("/overview")) landingPage = "Home";
      else if (window.location.pathname.endsWith("/uim-alarms"))
        landingPage = "Alarms";
      else if (
        window.location.pathname.endsWith("/inventory") ||
        window.location.pathname.indexOf("/computer-systems") > -1
      )
        landingPage = "Inventory";
      else if (window.location.pathname.indexOf("/reports") > -1)
        landingPage = "Reports";
      else if (
        window.location.pathname.endsWith("/technologies") ||
        window.location.pathname.indexOf("/uim-cabi") > -1 ||
        window.location.pathname.endsWith("/mcsDeploymentStatus")
      )
        landingPage = "Dashboard";
      else if (window.location.pathname.endsWith("/slm")) landingPage = "SLM";
      else if (window.location.pathname.indexOf("/groups/0") > -1)
        landingPage = "Groups";
      else landingPage = "Home";
      let prefFound = false;
      //if(this.props.userPreferences)
      for (let userPreference of this.props.userpreferences
        .userPreferencesGlobalPersonalListGetStatus.userpreferences) {
        if ("Personal" == userPreference.preferenceLevel) {
          prefFound = true;
          if (landingPage != userPreference.landingPage) {
            const params = [
              {
                pref_id: userPreference.id,
                pref_level: "Personal",
                "@landing_page": landingPage,
              },
            ];
            this.props.makeThisMyDefaultView(params);
          }
        }
      }
      if (prefFound == false) {
        const params = [
          {
            pref_id: null,
            pref_level: "Personal",
            "@landing_page": landingPage,
          },
        ];
        this.props.makeThisMyDefaultView(params);
      }
    }
  };

  render() {
    //Change Password
    let changePasswordText = Resource.get("Change Password");
    // let accountTitle = this.state.accountName != ''? `${this.state.accountName}/${this.state.userName}`: this.state.userName ;
    let makeThisMyDefaultViewText = Resource.get("Set as default view"); //makethismydefaultview
    let disableSetDefaultView = false;
    if (
      !(
        window.location.pathname.endsWith("/overview") ||
        window.location.pathname.endsWith("/uim-alarms") ||
        window.location.pathname.endsWith("/inventory") ||
        window.location.pathname.indexOf("/computer-systems") > -1 ||
        window.location.pathname.indexOf("/reports") > -1 ||
        window.location.pathname.endsWith("/technologies") ||
        window.location.pathname.indexOf("/uim-cabi") > -1 ||
        window.location.pathname.endsWith("/mcsDeploymentStatus") ||
        window.location.pathname.endsWith("/slm") ||
        window.location.pathname.indexOf("/groups/0") > -1
      )
    )
      disableSetDefaultView = true;
    if (disableSetDefaultView == false) {
      if (
        (window.location.pathname.endsWith("/overview") &&
          this.state.currentPersonalPreference == "Home") ||
        (window.location.pathname.endsWith("/uim-alarms") &&
          this.state.currentPersonalPreference == "Alarms") ||
        ((window.location.pathname.endsWith("/inventory") ||
          window.location.pathname.indexOf("/computer-systems") > -1) &&
          this.state.currentPersonalPreference == "Inventory") ||
        (window.location.pathname.indexOf("/reports") > -1 &&
          this.state.currentPersonalPreference == "Reports") ||
        ((window.location.pathname.endsWith("/technologies") ||
          window.location.pathname.indexOf("/uim-cabi") > -1 ||
          window.location.pathname.endsWith("/mcsDeploymentStatus")) &&
          this.state.currentPersonalPreference == "Dashboard") ||
        (window.location.pathname.endsWith("/slm") &&
          this.state.currentPersonalPreference == "SLM") ||
        (window.location.pathname.indexOf("/groups/0") > -1 &&
          this.state.currentPersonalPreference == "Groups")
      )
        disableSetDefaultView = true;
    }

    return (
      <div>
        <span
          title={
            this.state.accountName != ""
              ? `${this.state.accountName}/${this.state.userName}`
              : this.state.userName
          }
        >
          <Button aria-label={ this.state.accountName != ""
                ? `${this.state.accountName}/${this.state.userName}`
                : this.state.userName}
            variant="text"
            children={
              this.state.accountName != ""
                ? `${this.state.accountName}/${this.state.userName}`
                : this.state.userName
            }
            role="button" aria-roledescription="Click to view user options"
            color="primary"
            startIcon={<MenuOpen />}
            onClick={this.handleClick}
            classes={{ label: "titlebar_button_label", startIcon: "iconStyle" }}
            style={{
             maxWidth: "260px",
              color: "black",
              textTransform: "none",
              marginRight: "10px",
            }}
           // aria-expanded={this.state.openMenu}
          />
        </span>
        <div>
          <Popover
            open={this.state.openMenu}
            anchorEl={this.state.anchorEl}
            onClose={this.handleRequestClose}
            className="titleAccountMenu"
          >
            <List dense>
              {this.props.aclAccountUser &&
                this.props.canUpdatePassword &&
                !this.props.isLdapUser && (
                  <ListItem style={{ paddingTop: 0, paddingBottom: 0 }}>
                    <ListItemButton
                      onClick={this.handleChangePwdOpen}
                      component="a"
                      role="button"
                    >
                      <ListItemText primary={changePasswordText} />
                    </ListItemButton>
                  </ListItem>
                )}

              {!disableSetDefaultView && (
                <ListItem
                  disablePadding
                  secondaryAction={
                    // <NavLink to="/settings/personalize">
                    <Tooltip title="Navigate to Personalize page">
                    <IconButton aria-roledescription="Click to navigate to Personalize page"
                      onClick={() => {
                        this.props.history.push("/settings/personalize");
                        this.closeMenu();
                      }}
                      style={{ marginLeft: "10px", padding: "9px" }}
                      role="link"
                    >
                      <MoreIcon />
                    </IconButton>
                    </Tooltip>
                    // </NavLink>
                  }
                  style={{ paddingTop: 0, paddingBottom: 0 }}
                >
                  <ListItemButton
                    onClick={this.makeThisMyDefaultView}
                    component="a"
                    role="button"
                  >
                    <ListItemText
                      // className="secondaryTextStyle"
                      primary={makeThisMyDefaultViewText}
                    />
                  </ListItemButton>
                </ListItem>
              )}
              <ListItem style={{ paddingTop: 0, paddingBottom: 0 }}>
                <ListItemButton
                  onClick={this.handleLogout}
                  component="a"
                  role="button"
                >
                  <ListItemText
                    // className="secondaryTextStyle"
                    primary={Resource.get("Logout")}
                  />
                </ListItemButton>
              </ListItem>
            </List>
          </Popover>
        </div>

        {this.state.openChangePwd ? (
          <ChangePasswordDialog
            value={this.state.openChangePwd}
            handleChangePwdClose={this.handleChangePwdClose}
            sid={this.props.sid}
            changePassword={this.props.updatePassword}
            success={this.props.success}
            error={this.props.error}
            title={changePasswordText}
            resetResponse={this.props.resetResponseMsg}
          />
        ) : null}

        {this.state.showFloatingMessage && <CustomSnackbar severity={this.state.floatingMessageType}
          open={this.state.showFloatingMessage}
          message={this.state.floatingMessageValue}
          autoHideDuration={4000}
          
          close={this.onFloatingMessageClose}
        />}

        <Dialog
          className="password-expire-info"
          open={this.state.openPwdExpireDialog}
          TransitionComponent={this.Transition}
          fullWidth={true}
          maxWidth={"sm"}
          onClose={this.closeExpireDialog}
        >
          <DialogTitle>
            <Typography variant="div">
              <div style={{ float: "left", paddingTop: 4, paddingRight: 10 }}>
                <WarningIcon style={{ color: "orange" }} />
              </div>
              <div style={{ display: "flex", fontWeight: "bold" }}>
                {" "}
                Consider changing your password.
              </div>
            </Typography>
           
          </DialogTitle>
          <div style={{height:'0px'}}>
          <Tooltip title="Close">
          <IconButton autoFocus style={{float:'right',marginRight:'8px',top:'-60px'}}
              aria-label="Close"
              onClick={this.closeExpireDialog}
            >
              <CloseIcon />
            </IconButton>
            </Tooltip>
            </div>
          <DialogContent dividers={true}>
            <div>
              <p>
                Your password will expire
                {this.state.dayExpire === 0 || this.state.dayExpire === "0"
                  ? " on "
                  : " in "}
                <span style={{ fontWeight: "bold" }}>
                  {this.state.dayExpire === 0 || this.state.dayExpire === "0"
                    ? this.state.dateExpire
                    : this.state.dayExpire === 1 || this.state.dayExpire === "1"
                    ? this.state.dayExpire + " day!"
                    : this.state.dayExpire + " days!"}
                </span>
              </p>
              <p>
                To change your password, click on username and "
                <Button variant="text"
                  onClick={this.openChangePwdDialog}
                 
                >
                  Change password
                </Button>
                ".
              </p>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

export default withRouter(TitlebarAccountContainer);
