import React, { Component } from "react";
import { Link, Route } from "react-router-dom";
import {
  ShowMoreIcon as ShowMore,
  ErrorIcon as Information,
  SeverityIcon,
  Error,
} from "../ui-components/uim-components";
import {CircularProgress,IconButton} from "@mineral/core";
//import SeverityIcon from "./../icons/SeverityIcon";
import SimpleBarChart from "./../charts/SimpleBarChart";
import StackedBarChart from "./../charts/StackedBarChart";
import StackedAreaChart from "./../charts/StackedAreaChart";
import CardDot from "./CardDot";
import entityTypes from "./../../api/entityTypes";
import "./card.less";
import numeral from "numeral";
import Resource from "./Resource";
import { getAlarmUrl, getContainerGroupFilters } from "./../../utils/common";

const isComputerSystemContainer = (entity) => {
  return (
    entity &&
    entity.type !== entityTypes.ROOT &&
    entity.type !== entityTypes.CONTAINER
  );
};
const isInterfaceContainer = (entity) => {
  return (
    entity &&
    entity.type !== entityTypes.ROOT &&
    entity.type !== entityTypes.CONTAINER &&
    entity.masterElementGroup
  );
};

const CustomLink = ({ to, _onClick, children, ...rest }) => (
  <Route
    children={({ history }) => (
      <Link
        to={to}
        onClick={(e) => {
          e.preventDefault();
          _onClick(e, to, history);
        }}
        {...rest}
      >
        {children}
      </Link>
    )}
  />
);

const MemberCountLink = (props) => {
  const { id, memberCount } = props.item;
  const { handleEntityDrillDown, history } = props;
  const pathname = `${history.location.pathname.replace("/card", "")}`;
  const to = isInterfaceContainer(props.item)
    ? `${pathname}/${id}/card/interfaces`
    : isComputerSystemContainer(props.item)
    ? `${pathname}/${id}`
    : `${pathname}/${id}/card`;
  return (
    <CustomLink
      to={to}
      className="card__link card__member-countCell"
      _onClick={(e, to, history) => {
        history.push(to);
        handleEntityDrillDown(to);
      }}
    >
      <div className="card__member-count">
        <span className="card__member-value">{memberCount}</span>
        <span className="card__member-elementLabel">
          {
            //Elements
            Resource.get("Elements")
          }
        </span>
      </div>
      <ShowMore className="showMore__caret" />
    </CustomLink>
  );
};

const NameLink = (props) => {
  const { id, name, maxAlarmSeverity } = props.item;
  const { handleEntityDrillDown, history } = props;
  const pathname = `${history.location.pathname.replace("/card", "")}`;
  const to = isInterfaceContainer(props.item)
    ? `${pathname}/${id}/card/interfaces`
    : `${pathname}/${id}/card`;
  return (
    <CustomLink
      to={to}
      className="nameLink"
      _onClick={(e, to, history) => {
        history.push(to);
        handleEntityDrillDown(to);
      }}
    >
      <div className="card__titlebar">
        <span className="card__icon" title={maxAlarmSeverity.label}>
          <SeverityIcon level={maxAlarmSeverity.value} />
        </span>
        <div className="card__title" title={name}>
          {name}
        </div>
        <div className="cardInformation__icon" onClick={props.showDialog}>
          <Information
            title="Information"
            className="cardInformation__icon--img"
          />
        </div>
      </div>
    </CustomLink>
  );
};

const ComputerSystemCabiLink = (props) => {
  const { id, osName } = props.item;
  const { handleEntityDrillDown, history } = props;
  let showMore = null;
  if (osName) {
    showMore = <ShowMore className="showMore__caret" />;
  }
  const pathname = `${history.location.pathname.replace("/card", "")}`;
  return (
    <CustomLink
      to={`${pathname}/computer-systems/${id}/dashboard`}
      className="card__link card__member-countCell"
      _onClick={(e, to, history) => {
        history.push(to);
        handleEntityDrillDown(to);
      }}
    >
      <div className="card__member-count">
        <span className="card__member-elementLabel">{osName}</span>
      </div>
      {showMore}
    </CustomLink>
  );
};

const NameCabiLink = (props) => {
  const { id, name, maxAlarmSeverity } = props.item;
  const { handleEntityDrillDown, history } = props;
  const pathname = `${history.location.pathname.replace("/card", "")}`;
 
  return (
    <CustomLink
      to={`${pathname}/computer-systems/${id}/dashboard`}
      className="nameLink"
      _onClick={(e, to, history) => {
        history.push(to);
        handleEntityDrillDown(to);
      }}
    >
      <div className="card__titlebar">
        <span className="card__icon" title={maxAlarmSeverity.label}>
          <SeverityIcon level={maxAlarmSeverity.value} />
        </span>
        <div className="card__title" title={name}>
          {name}
        </div>
        <div className="cardInformation__icon" onClick={props.showDialog}>
          <Information
            title="Information"
            className="cardInformation__icon--img"
          />
        </div>
      </div>
    </CustomLink>
  );
};

const AlarmLink = ({
  item,
  launchAlarmViewer,
  totalAlarmCount,
  history,
  saas,
  doiurl,
  featureFlags,
}) => {
  if (saas && doiurl && featureFlags && featureFlags.globalAlarmsView) {
    let filters;
    if (item.entityType === entityTypes.GROUP) {
      let groupIds = [item.id];
      let groupNames = [item.name];
      filters = getContainerGroupFilters(item, groupIds, groupNames);
    } else {
      filters = `csId=${item.id}&deviceName=${item.name}`;
    }
    let alarmCount = totalAlarmCount;
    if (totalAlarmCount > 1000) {
      alarmCount = numeral(totalAlarmCount).format("0a") + "+";
    }

    return (
      <a
        class="card__link card__alarm-countCell"
        href={getAlarmUrl(doiurl, filters)}
      >
        <div className="card__alarm-count">
          <span className="card__alarm-value">{alarmCount}</span>

          <span className="card__alarm-elementLabel">
            {
              //Total Alarms
              Resource.get("Total Alarms")
            }
          </span>
        </div>{" "}
        <ShowMore className="showMore__caret" />
      </a>
    );
  } else {
    let isCardView=history.location.pathname?.includes("/card");
    const pathname =   item.entityType === entityTypes.GROUP?`${history.location.pathname.replace("/card", "")}`:`${history.location.pathname.replace("/card", "")}`;
 
    const to =
      item.entityType === entityTypes.GROUP
        ? `${pathname}/${item.id}/${isCardView?'card/':''}alarms`
        : `${pathname}/computer-systems/${item.id}/${isCardView?'card/':''}alarms`;
    let alarmCount = totalAlarmCount;
    if (totalAlarmCount > 1000) {
      alarmCount = numeral(totalAlarmCount).format("0a") + "+";
    }
    return (
      <CustomLink
        to={to}
        className="card__link card__alarm-countCell"
        _onClick={(e, to, history) => {
          history.push(to);
          launchAlarmViewer(to);
        }}
      >
        <div className="card__alarm-count">
          <span className="card__alarm-value">{alarmCount}</span>

          <span className="card__alarm-elementLabel">
            {
              //Total Alarms
              Resource.get("Total Alarms")
            }
          </span>
        </div>{" "}
        <ShowMore className="showMore__caret" />
      </CustomLink>
    );
  }
};

class Card extends Component {
  constructor(props) {
    super(props);
    this.selectChart = this.selectChart.bind(this);
    this.renderChart = this.renderChart.bind(this);
    this.state = {
      selectedChart: 1,
    };
  }
  selectChart(chartId) {
    this.props.handleChartChange(this.props.item, chartId);
    this.setState({
      selectedChart: chartId,
    });
  }
  showDialog = (element) => {
    element.preventDefault();
    element.stopPropagation();
    this.props.setShowDialog(element, this.props.item);
  };
  handleCaretClick = (event) => {
    let selectedChart = 1;
    if (this.state.selectedChart === 1) {
      selectedChart = 3;
    }
    this.props.handleChartChange(this.props.item, selectedChart);
    this.setState({
      selectedChart: selectedChart,
    });
  };
  renderChart() {
    let chart;
    const chartColors = ["#DE1B1B", "#F78F4A", "#F7CD3B", "#80BED9", "#3272D9"];
    //Graph Error message
    const graphErrorText = Resource.get(
      "The data required for this graph is not currently available."
    );
    if (this.state.selectedChart === 2) {
      //past chart that we no longer show
      chart = (
        <StackedBarChart
          data={this.props.chartAlarmHistory}
          chartColors={chartColors}
        />
      );
    } else if (this.state.selectedChart === 3) {
      chart = (
        <StackedAreaChart
          fillOpacity={1.0}
          areaType="monotone"
          data={this.props.chartLineData}
          chartColors={chartColors}
        />
      );
      // chart loading/error states
      if (this.props.historicAlarmsIsFetching) {
        chart = (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress style={{ color: "#3272D9" }} />
          </div>
        );
      }
      if (this.props.historicAlarmsDidInvalidate) {
        chart = <Error message={graphErrorText} />;
      }
    } else {
      chart = (
        <SimpleBarChart
          max={this.props.item.maxAlarmCount}
          data={this.props.item.alarmCountSummary}
          chartColors={chartColors}
        />
      );
    }
    return chart;
  }
  render() {
    const { entityType, totalAlarmCount } = this.props.item;
    const launchAlarmViewer = this.props.onAlarmClick;
    const memberCountEl =
      entityType === entityTypes.GROUP ? (
        <MemberCountLink {...this.props} />
      ) : (
        <ComputerSystemCabiLink {...this.props} />
      );

    const cardTitleEl =
      entityType === entityTypes.GROUP ? (
        <NameLink showDialog={this.showDialog} {...this.props} />
      ) : (
        <NameCabiLink showDialog={this.showDialog} {...this.props} />
      );

    return (
      <div className="group__card">
        {cardTitleEl}
        <div className="card__details">
          {memberCountEl}
          <AlarmLink
            item={this.props.item}
            history={this.props.history}
            launchAlarmViewer={launchAlarmViewer}
            totalAlarmCount={totalAlarmCount}
            saas={this.props.saas}
            featureFlags={this.props.featureFlags}
            doiurl={this.props.doiurl}
          />
        </div>
        <div className="card__chart">{this.renderChart()}</div>
        <div className="graph__navigation">
          <IconButton aria-label={"Show Previous"} onClick={this.handleCaretClick}>
            <ShowMore aria-label={"Show Previous"} className="shiftDots__caret caret__reverse" />
          </IconButton>
          <div className="card__dots">
            <CardDot
              key={1}
              id={1}
              handleClick={this.selectChart}
              selected={1 === this.state.selectedChart}
            />
            <CardDot
              key={3}
              id={3}
              handleClick={this.selectChart}
              selected={3 === this.state.selectedChart}
            />
          </div>
          <IconButton aria-label="Show Next" onClick={this.handleCaretClick}>
            <ShowMore aria-label={"Show Next"} className="shiftDots__caret" />
          </IconButton>
        </div>
      </div>
    );
  }
}

export default Card;
