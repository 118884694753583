import {
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  SETPRODUCTLIST,
  SETDOIURL
} from './actionTypes'

const user = (state = {
  sid: null,
  userName: null,
  productList:[],
  doiurl:null
}, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return Object.assign({}, state, {
        sid: action.sid,
        userName: action.userName
      })
    case LOGIN_FAILURE:
      return Object.assign({}, state, {
        sid: null,
        userName: null,
        errorMessage: action.errorMessage
      })
      case SETPRODUCTLIST:
      return Object.assign({}, state, {
        productList: action.productList
      })
      case SETDOIURL:
      return Object.assign({}, state, {
        doiurl: action.doiurl
      })
    default:
      return state
  }
}
export default user
