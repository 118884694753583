// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Settings
  content["Settings"] =
  "Settings";

  //Close Rule
  content["Close Rule"] =
  "Close Rule";

  ////1 hour
  content["1 hour"] =
  "1 hour";

  //12 hours
  content["12 hours"] =
  "12 hours";

  //24 hours
  content["24 hours"] =
  "24 hours";

  //3 days
  content["3 days"] =
  "3 days";

  //1 week
  content["1 week"] =
  "1 week";

  //2 weeks
  content["2 weeks"] =
  "2 weeks";

  //1 month
  content["1 month"] =
  "1 month";

  //3 months
  content["3 months"] =
  "3 months";

  //Never
  content["Never"] =
  "Never";

  //Communication Error
  content["Communication Error"] =
  "Communication Error";

  // help message
  content["Automatically close non-threshold violating alarms (for example, system log events or server reboots) if they have been open more than..."] =
  "Automatically close non-threshold violating alarms (for example, system log events or server reboots) if they have been open more than...";

  // Close Rule help message
  content["And their severity is..."] =
  "And their severity is...";

  //Critical
  content["Critical"] =
  "Critical";

  //Major
  content["Major"] =
  "Major";

  //Minor
  content["Minor"] =
  "Minor";

  //Warning
  content["Warning"] =
  "Warning";

  //Info
  content["Info"] =
  "Info";

  //CANCEL
  content["CANCEL"] =
  "CANCEL";

  //SAVE
  content["SAVE"] =
  "SAVE";

  // END OF LOCALIZATION

export default content;
