// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Highest Alarm Severity Level and Total Alarm Count
  content["Highest Alarm Severity Level and Total Alarm Count"] =
  "Höchster Alarmschweregrad und Gesamtzahl der Alarme";

  //Max Alarm Severity
  content["Max Alarm Severity"] =
  "Max. Alarmschweregrad";

  //Name
  content["Name"] =
  "Name";

  //Elements
  content["Elements"] =
  "Elemente";

  //Device Type
  content["Device Type"] =
  "Gerätetyp";

  //Operating system
  content["Operating system"] =
  "Betriebssystem";

  //IP address
  content["IP address"] =
  "IP-Adresse";

  //Monitored by
  content["Monitored by"] =
  "Überwacht von";

  //Has Robot
  content["Has Robot"] =
  "Hat einen Robot";

  //Discovered
  content["Discovered"] =
  "Erkannt";

  // END OF LOCALIZATION

export default content;
