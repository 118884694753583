import React from 'react'
import './icons.less'

const Icon = (props) => {
  let classes = [
    'icon',
    props.className,
  ].join(' ')
  return (
    <svg className={classes} role={props.role || 'figure'} aria-label="[title]" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g>
        <title>{props.title}</title>
        <path fillRule="evenodd" d="M4,17 L20,17 L20,19 L4,19 L4,17 Z M6,13 L8,13 L8,16 L6,16 L6,13 Z M9,9 L11,9 L11,16 L9,16 L9,9 Z M12,12 L14,12 L14,16 L12,16 L12,12 Z M15,6 L17,6 L17,16 L15,16 L15,6 Z"/>
      </g>
    </svg>
  )
}

export default Icon
