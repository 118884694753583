import connect from "./../../utils/connect";
import TitleBar from "./TitleBar";
import {
  addGroup,
  requestGroup,
  deleteGroup,
  renameGroup,
  removeDevicesFromGroup,
  removeInterfacesFromGroup,
  pollGroupsStop,
  setSelectedCsIds,
  setSelectedIfIds,
} from "./../../api/groups/actions";
import { removeEntities, renameEntity } from "./../entities/actions";
import {
  deleteDevices,
  searchComputerSystems,
} from "./../../api/computer-systems/actions";
import { setQuickFilterEntities } from "./../entities/actions";
import { refreshTree } from "./../../api/treeView/actions";
import { getAccount } from "../../api/account/actions";
import { fetchInterfaces } from "./../../api/interfaces/actions";
import { changePassword } from "./../../api/changePassword/actions";
import { resetResponseMsg } from "./../../api/changePassword/actions";
import { homeScreenComponentsChanged } from "./../../api/home-screen/actions";
import { groupDashboardComponentsChanged } from "./../../api/groups/actions";
import {
  setDefaultViewUserPreference,
  fetchGlobalAndPersonalUserPreferences,
} from "./../../api/userpreferences/actions";

const mapStateToProps = (state) => {
  // console.log("state.entity", state.entity);
  return {
    sid: state.sid,
    entityType: state.entity.type,
    selectedId: state.entity.id,
    selectedName: state.entity.name,
    parentId: state.entity.parentId,
    isInterfaceGroup: state.tree_view.treeNode.isInterfaceGroup,
    entities: state.entities,
    selectedCsIds: state.selectedCsIds,
    selectedIfIds: state.selectedIfIds,
    didInvalidate: state.groups.didInvalidate,
    isFetching: state.groups.isFetching,
    groupErrorMsg: state.groups.errorMessage,
    saas: state.saas,
    acl: state.acl,
    //aclAccountUser: state.acl.isAccountUser,//dp039841
    //isCpaEnabled: state.cpa.isEnabled,
    groupManagementPermission: state.groupManagementPermission,
    type: state.entity.type,
    criteria: state.entity.criteria,
    treeView: state.tree_view,
    accounts: state.account.accounts,
    interfaceData: state.interfaces.interfaceData,
    entitySelected: state.entity,
    successMessage: state.changePassword.successMessage,
    errorMessage: state.changePassword.errorMessage,
    homeScreenComponents: state.homeScreen.homeScreenComponents,
    currentView: state.groups.currentTreeViewNav,
    groupDashboardComponents: state.groupsDashboard.groupDashboardComponents,
    selectedEntity: state.entity,
    setDefaultViewStatus:
      state.userpreferences.userPreferencesSetDefaultViewStatus.status,
    userpreferences: state.userpreferences,
    breadCrumbs: state.breadCrumbs,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addGroup: (newGroup) => dispatch(addGroup(newGroup)),
    deleteGroup: (
      groupId,
      updateRoute,
      closeDialog,
      parentId,
      openError,
      openSuccess
    ) => {
      return dispatch(deleteGroup(groupId)).then((response) => {
        if (response.type === "DELETE_GROUP_SUCCESS") {
          updateRoute(parentId);
          closeDialog("deleteSubGroupOpen");
          if (openSuccess) {
            openSuccess();
          }
        } else {
          closeDialog("deleteSubGroupOpen");
          openError("deleteGroup");
        }
      });
    },
    renameGroup: (group) => dispatch(renameGroup(group)),
    getGroup: (id) => dispatch(requestGroup({ id: id })),
    renameEntity: (name) => dispatch(renameEntity(name)),
    removeDevicesFromGroup: (groupId, ids) =>
      dispatch(removeDevicesFromGroup(groupId, ids)),
    removeInterfacesFromGroup: (groupId, ids) =>
      dispatch(removeInterfacesFromGroup(groupId, ids)),
    removeEntities: (entities) => dispatch(removeEntities(entities)),
    setSelectedCsIds: (ids) => dispatch(setSelectedCsIds(ids)),
    setSelectedIfIds: (ids) => dispatch(setSelectedIfIds(ids)),
    deleteDevices: (ids) => dispatch(deleteDevices(ids)),
    pollGroupsStop: (id) => dispatch(pollGroupsStop(id)),
    setSearchTerm: (searchTerm) => dispatch(setQuickFilterEntities(searchTerm)),
    refreshTree: () => dispatch(refreshTree(true)),
    getAccount: () => dispatch(getAccount()),
    getInterfaces: (params) => dispatch(fetchInterfaces(params)),
    updatePassword: (params) => dispatch(changePassword(params)), //dp039841
    resetResponseMsg: () => dispatch(resetResponseMsg()), //dp039841
    onHomeComponentChanged: (data) =>
      dispatch(homeScreenComponentsChanged(data)),
    groupDashboardComponentsChanged: (data) =>
      dispatch(groupDashboardComponentsChanged(data)),
    makeThisMyDefaultView: (params) =>
      dispatch(setDefaultViewUserPreference(params)),
    fetchGlobalAndPersonalUserPreferences: () =>
      dispatch(fetchGlobalAndPersonalUserPreferences()),
    setQuickFilter: () =>
      dispatch(searchComputerSystems(null, null, null, null)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TitleBar);
