import { call, put, takeEvery, takeLatest, take, cancel } from 'redux-saga/effects'
import { delay } from 'redux-saga/effects'
import { FETCH_MAINTENANCE,POLL_MAINTENANCE_START,POLL_MAINTENANCE_STOP } from './actionTypes'
import { fetchMaintenanceSuccess, fetchMaintenanceFailure } from './actions'
import maintenance from './maintenance';
function* fetchMaintenanceCreds() {
  try {
    const response = yield call(maintenance.getMaintenance)
    yield put(fetchMaintenanceSuccess(response.data))
  } catch (error) {
    yield put(fetchMaintenanceFailure(error))
  }
}
export function* getMaintenanceSaga() {
  yield takeEvery(FETCH_MAINTENANCE, fetchMaintenanceCreds)
}
function* pollMaintenanceCreds() {
    while (true) {
      yield delay(30000)
      yield call(fetchMaintenanceCreds)
    }
  }
export function* maintenancePollingSaga() {
    while (true) {
      const watcherInstance = yield takeLatest(POLL_MAINTENANCE_START, pollMaintenanceCreds)
      // cancel polling on POLL_HUBS_STOP
      yield take(POLL_MAINTENANCE_STOP)
      yield cancel(watcherInstance)
    }
  }







