import {
  REQUEST_ROBOTS_STATUS,
  FETCH_ROBOTS_STATUS_SUCCESS,
  FETCH_ROBOTS_STATUS_FAILURE,
  RESET_ROBOTS_STATUS,
  STORE_PROBE_JOBID,
  REQUEST_PROBES_STATUS_SUCCESS,
} from "./actionTypes";
import robotsStatus from "./robotsStatus";
import axios from "axios";

export function requestRobotsStatus() {
  return {
    type: REQUEST_ROBOTS_STATUS,
  };
}

export function fetchRobotsStatusSuccess(json) {
  return {
    type: FETCH_ROBOTS_STATUS_SUCCESS,
    robots: json,
  };
}

export function fetchRobotsStatusFailure(error) {
  return {
    type: FETCH_ROBOTS_STATUS_FAILURE,
    errorMessage: error.message,
  };
}
export function resetRobotsStatus() {
  return {
    type: RESET_ROBOTS_STATUS,
  };
}

export function fetchProbesStatusSuccess(info) {
  return {
    type: REQUEST_PROBES_STATUS_SUCCESS,
    probes: info,
  };
}

export function fetchProbesStatus(jobIdsArr) {
  return (dispatch) => {
    let i,
      successIdsArr = [],
      errorIdsArr = [],
      item,
      probesStatusArr = [];
    for (i = 0; i < jobIdsArr.length; i++) {
      item = jobIdsArr[i];
      if (item.jobId !== null) {
        successIdsArr.push(item.jobId);
      } else if (item.fromError) {
        errorIdsArr.push(item);
      }
    }

    robotsStatus.deployProbeStatus(successIdsArr).then((results) => {
      let info = results.data._items.slice(0);
      errorIdsArr.forEach((item) => {
        info.push({
          status: false,
          jobId: null,
          fromError: true,
          robotInfo: item.robotInfo,
        });
      });
      dispatch(fetchProbesStatusSuccess(info));
    });
  };
}

export function fetchRobotsStatus(params) {
  const callbackEnabled = params.callbackEnabled;
  const probe = params.probe;
  return (dispatch, getState) => {
    dispatch(requestRobotsStatus());
    return robotsStatus
      .get(params)
      .then((response) => response.data)
      .then((json) => {
        let i,
          successcnt = 0,
          comingcnt = 0,
          data = [];
        let prevRobotsStatus = getState().robotsStatus.status;
        let robotsStatusFulfilled = true;
        if (callbackEnabled) {
          for (i = 0; i < json._items.length; i++) {
            let item = json._items[i];
            if (item.state === "IN_PROGRESS") {
              robotsStatusFulfilled = false;
            }
            if (
              item.state === "SUCCEEDED" &&
              !probeAlreadyDeployed(prevRobotsStatus, item.adejobID)
            ) {
              successcnt++;
              const inputs = {};
              inputs.probe = probe;
              const nimAdressList = [];
              nimAdressList.push(json._items[i].robotAddress);
              inputs.data = nimAdressList;
              data.push({ inputs: inputs, item: item });
            }
          }
          for (i = 0; i < data.length; i++) {
            (function ({ item, inputs }) {
              robotsStatus
                .deployProbe(inputs)
                .then((response) => {
                  comingcnt++;
                  let items = response.data._items,
                    data = { jobId: null, robotInfo: item },
                    idsInfo = [],
                    i,
                    len = items.length;
                  for (i = 0; i < len; i++) {
                    data.jobId = items[i].adeJobID;
                    idsInfo.push(data);
                  }
                  dispatch(
                    storeProbeJobIds(
                      idsInfo,
                      comingcnt === successcnt ? robotsStatusFulfilled : false
                    )
                  );
                })
                .catch(function (error) {
                  comingcnt++;
                  let data = { jobId: null, robotInfo: item, fromError: true };
                  dispatch(
                    storeProbeJobIds(
                      [data],
                      comingcnt === successcnt ? robotsStatusFulfilled : false
                    )
                  );
                 // console.log(error);
                });
            })(data[i]);
          }
        }
        dispatch(fetchRobotsStatusSuccess(json));
      })
      .catch(function (error) {
       // console.log(error);
        dispatch(fetchRobotsStatusFailure(error));
      });
  };
}

export function storeProbeJobIds(idsArr, robotsStatusFulfilled) {
  return {
    type: STORE_PROBE_JOBID,
    idsArr: idsArr,
    status: robotsStatusFulfilled,
  };
}

function probeAlreadyDeployed(prevRobotsStatus, adejobID) {
  let status = false,
    i,
    len;
  if (prevRobotsStatus && prevRobotsStatus.length > 0) {
    len = prevRobotsStatus.length;
    for (i = 0; i < len; i++) {
      if (
        prevRobotsStatus[i].adejobID === adejobID &&
        prevRobotsStatus[i].state === "SUCCEEDED"
      ) {
        status = true;
        break;
      }
    }
  }
  return status;
}

export function pollFetchRobotsStatus(params) {
  const callbackEnabled = params.callbackEnabled;
  const probe = params.probe;

  return (dispatch, getState) => {
    return robotsStatus
      .get(params)
      .then((response) => response.data)
      .then((json) => {
        let i,
          successcnt = 0,
          comingcnt = 0,
          data = [];
        let prevRobotsStatus = getState().robotsStatus.status;
        let robotsStatusFulfilled = true;
        if (callbackEnabled) {
          for (i = 0; i < json._items.length; i++) {
            let item = json._items[i];
            if (item.state === "IN_PROGRESS") {
              robotsStatusFulfilled = false;
            }
            if (
              item.state === "SUCCEEDED" &&
              !probeAlreadyDeployed(prevRobotsStatus, item.adejobID)
            ) {
              successcnt++;
              const inputs = {};
              inputs.probe = probe;
              const nimAdressList = [];
              nimAdressList.push(json._items[i].robotAddress);
              inputs.data = nimAdressList;
              data.push({ inputs: inputs, item: item });
            }
          }
          for (i = 0; i < data.length; i++) {
            (function ({ item, inputs }) {
              robotsStatus
                .deployProbe(inputs)
                .then((response) => {
                  comingcnt++;
                  let items = response.data._items,
                    data = { jobId: null, robotInfo: item },
                    idsInfo = [],
                    i,
                    len = items.length;
                  for (i = 0; i < len; i++) {
                    data.jobId = items[i].adeJobID;
                    idsInfo.push(data);
                  }
                  dispatch(
                    storeProbeJobIds(
                      idsInfo,
                      comingcnt === successcnt ? robotsStatusFulfilled : false
                    )
                  );
                })
                .catch(function (error) {
                  comingcnt++;
                  let data = { jobId: null, robotInfo: item, fromError: true };
                  dispatch(
                    storeProbeJobIds(
                      [data],
                      comingcnt === successcnt ? robotsStatusFulfilled : false
                    )
                  );
                  //console.log(error);
                });
            })(data[i]);
          }
        }
        dispatch(fetchRobotsStatusSuccess(json));
      })
      .catch(function (error) {
        dispatch(fetchRobotsStatusFailure(error));
      });
  };
}
