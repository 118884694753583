import axios from 'axios'
import get from 'lodash/get'

// Probe Details Url
const probeWhiteList = [
  'aws',
  'azure',
  'apache',
  'windows',
  'unix',
  'vmware',
  'docker_monitor',
  'office365',
  'sap_basis',
  'exchange_monitor',
  'ews_response',
  'ldap_response',
  'exchange_monitor',
  'email_response',
  'adevl',
  'ad_server',
  'ad_response',
  'iis',
  'sql_response',
  'mysql',
  'sqlserver',
]
//const probeDetails = `/mcs-ui-app/api/template-summary/Probelist?probeName=${probeWhiteList.join(",")}`



// we have to renew the sid for every request because the alarm api is not under the same namespace


//&& probeListUrl.indexOf("profileCountFlag") === -1
class ProbesList {
  fetchProbeDetails(params={}) {
    let probeListUrl = `/mcs-ui-app/api/_count/probe-details`
    if(process.env.NODE_ENV=="development"){
      return axios.get("http://10.252.25.208:8080/castlemock/mock/rest/project/TK8t3o/application/UXxhjW/_count/probe-details")
    }
    const useEnhancedTemplate = get(params, 'useEnhancedflag', true)
    if(useEnhancedTemplate)
      probeListUrl = params.count ? probeListUrl+"?profileCountFlag=true&filterGroupProfiles=true&useEnhancedTemplates=" + useEnhancedTemplate : probeListUrl+"?profileCountFlag=false&useEnhancedTemplates=" + useEnhancedTemplate; 
    else
      probeListUrl = params.count ? probeListUrl+"?profileCountFlag=true&filterGroupProfiles=true" : probeListUrl+"?profileCountFlag=false" ; 
    
    const categories = params.categories;
    if(typeof categories === 'string'){
    	probeListUrl += '&categories='+categories
    }
    
    return axios.get(probeListUrl);
}
}

let probesList = new ProbesList()
export default probesList
