// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Monitoring Config
  content["Monitoring Config"] =
  "Monitoring Config";

  // END OF LOCALIZATION

export default content;
