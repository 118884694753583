import React, { useEffect } from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import './mineralDatePicker.less'
import moment from "moment";
import DatePickerIcon from '../../icons/DatePickerIcon'

export const MineralYearMonthPicker = ({
  label,
  placeholder,
  onValueChange,
  ...restProps
}) => {
  const [monthYear, setMonthYear] = React.useState(moment(restProps.value));
  const [openPicker, setOpenPicker] = React.useState(false);
  useEffect(()=>{
    if(!openPicker){
      if (moment(monthYear).format('MMMM, YYYY') !== restProps.value){
            onValueChange(monthYear)
    }
  }
  },[openPicker])

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        views={['year', 'month']}
        label={label}
        value={monthYear}
        open={openPicker}
        onOpen={() => {
          setOpenPicker(true);
        }}
        onClose={() => {
          setOpenPicker(false)
        }}
        onChange={(newValue) => {
          setMonthYear(newValue);
        }}
        onChangeRaw={ (e) => {
  e.preventDefault();
}}
        className="mineralDateTimePickerWidget"
        componentsProps={{
          switchViewButton: {
            sx: {
              margin: '20px 5px'
            }
          }
        }}
        PopperProps={{
          "aria-label":{label}
        }}
        PaperProps={{
          className: 'mineralDatePicker',
        }}
        InputProps={{
          className: 'mineralDateTimePicker_TextBox',
        }}
        OpenPickerButtonProps={{tooltip:'Change date'}}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              placeholder: placeholder || 'Select Date',
              readOnly:true
            }}
            readOnly={true}
          />
        )}
        keyboardIcon={<DatePickerIcon />}
        {...restProps}
      />
    </LocalizationProvider>
  );
};