import React, { Component } from 'react'
import get from 'lodash/get'
import {
  Card,
  CardActions,
  Button as RaisedButton,Button
} from '@mineral/core'
import {HelpIcon} from "../ui-components/uim-components"
import Resource from './Resource'
import './wizard.less'
import isEqual from 'lodash/isEqual';
import { Cross as CloseIcon } from '@mineral/icons';
import { IconButton } from '@mineral/core'


let probeWhiteListflag ="aws,azure,apache,windows,unix,vmware,docker_monitor,office365,sap_basis,tomcat";

export default class Wizard extends Component {
  state = {
    showNewLookInfo:true,
    probesSTBCount: 0,
  }
  categories = 'NON_LOCAL';
  componentWillMount() {
    let useEnhancedTemplates =
      get(
        this.props.featureFlags,
        'useEnhancedTemplates',
        true
      )
    this.props.fetchProbes(true,useEnhancedTemplates,this.categories)
    this.filterSTBProbes(this.props.probeList);
    this.pollProbesCount(useEnhancedTemplates)
    this.props.getJobsRunning();
    this.props.pollJobsRunningStart();
    this.props.selectedAgent(null);
    this.props.setBreadCrumbs({
      //Setup Wizard
      name: Resource.get('Setup Wizard'),
      link: `/getting-started`,
    })
  }

  componentWillUnmount() {
    this.props.pollProbesStop() ;
    this.props.pollJobsRunningStop();
  }

  filterSTBProbes = (probeList) => {
    // Filter to show only the white listed probes
    let useEnhancedTemplates =
      get(
        this.props.featureFlags,
        'useEnhancedTemplates',
        true
      )
    probeWhiteListflag =
      get(
        this.props.featureFlags,
        'probeWhiteList',
        false
      )
    if(useEnhancedTemplates) {
      let probeWhiteListSTB =
        get(
          this.props.featureFlags,
          'probeWhiteListSTB',
          false
        )
      if(typeof probeWhiteListSTB === 'string' && typeof probeWhiteListflag === 'string'){
        probeWhiteListflag = Array.from(new Set(probeWhiteListSTB.concat(',').concat(probeWhiteListflag).split(','))).join(',');
      }
      else{
        probeWhiteListflag = probeWhiteListSTB;
      }
    }
    const cards = probeList && probeList.length ? probeList.filter(probe => {
      if(probe.name.endsWith("(RESTMon)")){
        return true;
      }
      if(typeof probeWhiteListflag === 'string'){
        return probeWhiteListflag.includes(probe.name)
      }else{
        return probeWhiteListflag
      }
    })
      : null

    let probesCount = cards===null?0:cards.length;
    this.setState({
      probesSTBCount: probesCount,
    })
  }

  componentWillReceiveProps = (newProps) =>{
    if(!isEqual(newProps.probeList, this.props.probeList)) {
      this.filterSTBProbes(newProps.probeList);
    }
  }

  pollProbesCount = (useEnhancedTemplates) => {
    this.props.pollProbesStart(useEnhancedTemplates,this.categories)
    this.filterSTBProbes(this.props.probeList);
  }

  getNewLookInfo = () => {
    let classNames = ["setup-wizard__localprobes__info"];
    //Presenting the All New Setup Wizard
    let msg1 = Resource.get('Presenting the All New Setup Wizard'),
      //Towards improving your experience with managing and monitoring your infrastructure, we are presenting this new enhanced interface that meets your needs better.
      msg2 = Resource.get('Towards improving your experience with managing and monitoring your infrastructure, we are presenting this new enhanced interface that meets your needs better.'),
      //See what's changed
      msg3 = Resource.get('See what\'s changed');
    if(!this.state.showNewLookInfo){
      classNames.push('hide');
    }
    // Close
    const CLOSE = Resource.get('Close')
    // Close Icon
    const CLOSE_ICON = Resource.get('Close Icon')
    
    return (
      <div style={{position:'relative'}} className={classNames.join(' ')}>
        <div style={{width: 'calc(100% - 60px)',overflow: 'hidden',textOverflow: 'ellipsis',whiteSpace: 'nowrap'}}>
          <div className="setup-wizard__localprobes__contentImg" aria-label= "setup wizard local probes content Image" role = "img" alt="setup wizard local probes content Image"></div>
          <span className="setup-wizard__localprobes__content">
            <span style={{padding:'2px 13px 2px 20px',borderRight:'2px solid #999999'}} title={msg1} >{msg1}</span>
            <span style={{paddingLeft:'9px'}} title={msg2} >{msg2}
              <Button variant="text" style={{color:'#590B0B',paddingLeft: 4,cursor:'pointer'}} autoFocus={true} id="first-content-element" tabIndex={0} role="link" roledescription="Click to open documentation" onClick={() =>
                window.open(
                  'https://techdocs.broadcom.com/bin/gethidpage?hid=SetupWizard&space=UIM204&language=&format=rendered',
                  )
                  /*  window.open(
                    'https://techdocs.broadcom.com/bin/gethidpage?hid=OChome&space=UIM204&language=&format=rendered',
                    '_blank',
                    ) */
                    
                  }>{msg3}</Button></span>
                  </span>
                  </div>
                  <IconButton className='setup-wizard__localprobes__contentClose' onClick={() => {
                    
                    this.setState({showNewLookInfo:false});
                    document.getElementById("discoverDevicesCardButton")? document.getElementById("discoverDevicesCardButton").focus():document.getElementById("remoteCloudCardButton")?.focus();
                    }} aria-label={CLOSE}>
                      <CloseIcon style={{color: '#58606E'}}  aria-label={CLOSE_ICON} role= "img" alt={CLOSE_ICON} />
                    </IconButton>
                    </div>
                    );
                  }
                  
                  
                  handleCardClick = (link,displayname) =>{
                    this.props.addBreadCrumb({
                      name: displayname,
                      link: link,
                    })
                    this.props.history.push(link)
                  }
                  getCard(link,dispalyName,cardInfo){
                    let buttonLabelStyle = { 'paddingRight': '0px', 'paddingLeft': '0px' ,height:'40px',textTransform:'none'};
                    return(
                      <Card
                        className="card"
                        style={{
                          width: 318,
                          backgroundColor: '#F5F5F5',
                          boxShadow: null,
                          position:'relative',
                          minHeight:255,
                          maxHeight:355
                        }}
                      >
                        <CardActions className="card-actions" style={{justifyContent:'center'}}>
                          <div className="card-container">
                            <div>
                              <div aria-label= {cardInfo.imgClass} role = "img" alt={cardInfo.imgClass} className={cardInfo.imgClass}></div>
                            </div>
                            <div><p className="label" style={{paddingBottom: 8}} aria-label={`${cardInfo.label + " count"}`}>{cardInfo.labelContent}</p><p className="sub-label" aria-label={cardInfo.label}>{cardInfo.label}</p></div>
                            <div style={{display: 'flex',justifyContent: 'center'}}>
                              <RaisedButton id={cardInfo.id?cardInfo.id:"card"+dispalyName} autoFocus={cardInfo.autoFocus}
                                variant="contained"
                                color ="primary"
                                children={dispalyName}
                               
                                style ={{height:40,lineHeight:'40px'}}
                                onClick={() => this.handleCardClick(link,dispalyName)}
                               
                              //overlayStyle={{height:'40px'}}
                              /></div>
                          </div>
                        </CardActions>
                      </Card>
                    )
                  }
                  getCards(){
                    let canSeeDiscoverDevicesAndRemoteAndCloud=this.props.acl.canManageDiscovery && this.props.acl.canDeployRobot && this.props.acl.canMonitorConfigService &&
                    this.props.acl.canEditMCSTemplates && this.props.acl.canMonitorConfigService;
                    let canSeeRemoteAndCLoudOnly=this.props.acl.canDeployRobot && this.props.acl.canMonitorConfigService &&
                    this.props.acl.canEditMCSTemplates && this.props.acl.canMonitorConfigService
                    let displayName1 = Resource.get("Remote And Cloud") ,//Remote And Cloud
                      displayName2 = Resource.get("Discover Devices") ,//Discover Devices
                     
                      discoverDevices = this.getCard('/discover-devices-tiles',displayName2,{
                        imgClass:'discover-image',
                        label:'Jobs in progress',
                        labelContent:this.props.jobsCount,
                        id:this.state.showNewLookInfo?'discoverDevicesCardButton':'first-content-element',
                        autoFocus:this.state.showNewLookInfo?false:(this.props.acl && this.props.acl.isBusUser && (canSeeDiscoverDevicesAndRemoteAndCloud || this.props.acl.canManageDiscovery) ?true:false)
                      }),
                      remoteAndCloudCard =this.getCard('/remote-cloud-tiles',displayName1,{
                        imgClass:'remote-cloud-image',
                        label:'Technologies',
                        labelContent:this.state.probesSTBCount,
                        id:this.state.showNewLookInfo?'remoteCloudCardButton':'first-content-element',
                        autoFocus:this.state.showNewLookInfo?false:(this.props.acl && this.props.acl.isBusUser && (!canSeeDiscoverDevicesAndRemoteAndCloud && !this.props.acl.canManageDiscovery && canSeeRemoteAndCLoudOnly)?true:false)
                    
                      });
                    if(this.props.acl && this.props.acl.isBusUser){
                      if(canSeeDiscoverDevicesAndRemoteAndCloud){
                        return[discoverDevices,remoteAndCloudCard]
                      }else if(this.props.acl.canManageDiscovery){
                        return[discoverDevices]
                      }else if(canSeeRemoteAndCLoudOnly){
                        return[remoteAndCloudCard]
                      }
                    }else{
                      return []
                    }
                  }
                  render(){
                    let cards = this.getCards();

                       
                    

                    let label1 = Resource.get("Welcome to Setup Wizard") ,//Welcome to Setup Wizard
                    label2 = Resource.get("Your home to setup and configure monitoring infrastructure") ;//Your home to setup and configure monitoring infrastructure
                    // wizard image
                    const WIZARD_IMAGE = Resource.get('wizard image')
                    return(
                      <div style={{height:'calc(100% - 8px)',width:'100%'}}>
                        {this.state.showNewLookInfo?this.getNewLookInfo():null}
                        <div className="wizard-container" style={{height:'100%'}}>
                          <div>
                            <div style={{flex:1}}>
                              <div className="wizard-image" aria-label={WIZARD_IMAGE} role="img" alt={WIZARD_IMAGE}></div>
                              <div>
                                <h2 style={{textAlign: 'center'}}>{label1}</h2><p className="sub-label" style={{textTransform:'none',paddingBottom: 10 }}>{label2}</p>
                              </div>
                            </div>
                            <div style={{flex:2,display:'flex',justifyContent:'center',flexWrap:'wrap',paddingBottom: '20px'}}>
                              {cards}
                            </div>
                            {!this.state.showNewLookInfo ? <div style={{marginBottom:'24px',display:'flex',justifyContent:'center',flexWrap:'wrap'}}>
                              <IconButton 
                                onClick={() =>
                                  window.open(
                                    'https://techdocs.broadcom.com/bin/gethidpage?hid=OChome&space=UIM204&language=&format=rendered',
                                    '_blank',
                                  )}
                              >
                                <HelpIcon title="Help" />
                              </IconButton>
                            </div>:''}
                          </div>
                        </div>
                      </div>
                    )
                  }
                }
                