import connect from './../../../utils/connect'
import ScopesCatalog from './ScopesCatalog'
import {getWMI,pollWmiCredStart,pollWmiCredStop,getSNMP,pollsnmpCredStart,pollsnmpCredStop}  from './../../../api/DeviceDiscovery/credentials/actions';
import {getUNIX,pollUnixCredStart,pollUnixCredStop}  from './../../../api/DeviceDiscovery/unixCredentials/actions';
import ScopesCredentials from "./ScopesCredentials";

const mapStateToProps = state => {
  return {
	  wmiCredentials: state.credentials.items,
	  wmiCredsIsFetching: state.credentials.isFetching,
	  unixCredItems : state.unixCredentials.unixItems,
    unixCredsIsFetching : state.unixCredentials.isFetching,
    snmpCredItems:state.credentials.sncmpItems
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getSNMP:() =>dispatch(getSNMP()),
    getWMI: () => dispatch(getWMI()),
    getUNIX: () => dispatch(getUNIX()),
    pollWmiCredStart: () => dispatch(pollWmiCredStart()),
    pollWmiCredStop: () => dispatch(pollWmiCredStop()),
    pollUnixCredStart: () => dispatch(pollUnixCredStart()),
    pollUnixCredStop: () => dispatch(pollUnixCredStop()),
    pollsnmpCredStart: () => dispatch(pollsnmpCredStart()),
    pollsnmpCredStop: () => dispatch(pollsnmpCredStop()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ScopesCredentials)
