// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  // instructions message
  content["To add additional monitoring to this device, a local robot must be installed"] =
  "Para agregar una monitorización adicional a este dispositivo, se debe instalar un robot local.";

  // instructions message1
  content["Use the Setup Wizard to add local monitoring based on a device\'s operating system: Windows or Linux, and select manual installation."] =
  "Utilice el Asistente de instalación para agregar una monitorización local basada en el sistema operativo del dispositivo (Windows o Linux) y seleccione la opción de instalación manual.";

  //Local robot required
  content["Local robot required"] =
  "Se requiere un robot local.";

  //Cancel
  content["Cancel"] =
  "Cancelar";

  //Add devices...
  content["Add devices..."] =
  "Agregar dispositivos...";

  // instruction message
  content["To add additional monitoring to the devices in this group, local robots must be installed"] =
  "Para agregar una monitorización adicional a los dispositivos de este grupo, deben instalarse robots locales.";

  //  instruction message1
  content["Use the Setup Wizard to add local monitoring based on a device\'s operating system: Windows or Linux."] =
  "Utilice el Asistente de instalación para agregar una monitorización local basada en el sistema operativo del dispositivo (Windows o Linux).";

  // END OF LOCALIZATION

export default content;
