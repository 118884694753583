// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Relay Hub Installers
  content["Relay Hub Installers"] =
  "Instaladores del concentrador de transmisión";

  //A relay hub sends metrics and alarms data to your instance of Infrastructure Management.
  content["A relay hub sends metrics and alarms data to your instance of Infrastructure Management."] =
  "Un concentrador de transmisión envía datos de métrica y alarma a la instancia de Infrastructure Management.";

  //Pick the correct installer for your environment
  content["Pick the correct installer for your environment"] =
  "Seleccione el instalador adecuado para su entorno.";

  //If you will be monitoring Windows devices or a mix of Windows and Linux devices, use the Windows installer. The Linux relay hub can only monitor Linux servers.
  content["If you will be monitoring Windows devices or a mix of Windows and Linux devices, use the Windows installer. The Linux relay hub can only monitor Linux servers."] =
  "Para monitorizar dispositivos Windows o una combinación de dispositivos Windows y Linux, utilice el instalador de Windows. El concentrador de transmisión de Linux solo puede monitorizar servidores Linux.";

  //If monitoring cloud-based infrastructure, a relay hub is needed for each isolated virtual network (such as AWS Virtual Private Cloud or Azure Virtual Network).
  content["If monitoring cloud-based infrastructure, a relay hub is needed for each isolated virtual network (such as AWS Virtual Private Cloud or Azure Virtual Network)."] =
  "Si se desea llevar a cabo la monitorización de una infraestructura basada en la nube, se requiere un concentrador de transmisión para cada red virtual (por ejemplo, la nube privada virtual de AWS o la red virtual de Azure).";

  //Ensure the relay hub can phone home
  content["Ensure the relay hub can phone home"] =
  "Asegúrese de que el concentrador de transmisión puede llamar a casa.";

  //Ports 48000 to 48025 must be open
  content["Ports 48000 to 48025 must be open"] =
  "Los puertos 48000-48025 deben estar abiertos ";

  //for the relay hub to communicate with the Infrastructure Management application.
  content["for the relay hub to communicate with the Infrastructure Management application."] =
  "para que el concentrador de transmisión pueda comunicarse con la aplicación Infrastructure Management.";

  //The system where you install the relay hub must meet the following minimum requirements:
  content["The system where you install the relay hub must meet the following minimum requirements:"] =
  "El sistema donde se instale el concentrador de transmisión debe cumplir los requisitos mínimos siguientes:";

  //Requirements
  content["Requirements"] =
  "Requisitos";

  //Infrastructure Management supports the same Windows and Linux x64 operating systems that are supported for the on-premises CA UIM product.
  content["Infrastructure Management supports the same Windows and Linux x64 operating systems that are supported for the on-premises CA UIM product. "] =
  "Infrastructure Management es compatible con los mismos sistemas operativos Windows y Linux x64 que admite la instancia local del producto CA UIM. ";

  //For more information about which operating systems are supported, refer to the
  content["For more information about which operating systems are supported, refer to the"] =
  "Para obtener más información acerca de los sistemas operativos compatibles, consulte la";

  //Compatibility Support Matrix
  content["Compatibility Support Matrix"] =
  "matriz de compatibilidad de soporte";

  //Issues?
  content["Issues?"] =
  "¿Incidencias?";

  //If the relay hub does not appear within Infrastructure Management under the appropriate Operating System group after 10 minutes,  there is likely a communication issue.
  content["If the relay hub does not appear within Infrastructure Management under the appropriate Operating System group after 10 minutes, there is likely a communication issue."] =
  "Si el concentrador de transmisión no aparece en Infrastructure Management en el grupo apropiado del sistema operativo tras 10 minutos, es posible que haya un problema de comunicación.";

  //Make sure the relay hub server has an active Internet connection.
  content["Make sure the relay hub server has an active Internet connection."] =
  "Asegúrese de que el servidor del concentrador de transmisión tiene una conexión a Internet activa.";

  //For more guidance on deploying a relay hub see
  content["For more guidance on deploying a relay hub see"] =
  "Para obtener más instrucciones sobre cómo implementar un concentrador de transmisión, consulte";

  //windowslabel message
  content["WINDOWS"] =
  "Windows";

  //linuxlabel message
  content["LINUX"] =
  "LINUX";

  //Install Relay Hub
  content["Install Relay Hub"] =
  "Instalación del concentrador de transmisión";

  //Unable to access installers
  content["Unable to access installers."] =
  "No se puede acceder a los instaladores.";

  //DONE
  content["DONE"] =
  "FINALIZADO";

  //Information
  content["Information"] =
  "Información";

  //Home
  content["Home"] =
  "Casa";

  // Download and install a relay hub
  content["Download and install a relay hub"] =
  "Descarga e instalación de un concentrador de transmisión";

  //4 GB of available memory
  content["4 GB of available memory"] =
  "4 GB de memoria disponibles";

  //2 or more CPUs
  content["2 or more CPUs"] =
  "2 o más CPU";

  //10 GB of available disk space
  content["10 GB of available disk space"] =
  "10 GB de espacio en disco disponibles";

  //Valid external IP address for the system
  content["Valid external IP address for the system"] =
  "Dirección IP externa válida para el sistema";

  //Ability to connect to the Internet
  content["Ability to connect to the Internet"] =
  "Capacidad para conectarse a Internet";

  //HELP
  content["HELP"] =
  "Ayuda";

  //Help
  content["Help"] =
  "Ayuda";

  // END OF LOCALIZATION

export default content;
