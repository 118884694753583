// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Settings
  content["Settings"] =
  "設定";

  //Close Rule
  content["Close Rule"] =
  "ルールのクローズ";

  ////1 hour
  content["1 hour"] =
  "1 時間";

  //12 hours
  content["12 hours"] =
  "12 時間";

  //24 hours
  content["24 hours"] =
  "24 時間";

  //3 days
  content["3 days"] =
  "3 日";

  //1 week
  content["1 week"] =
  "1 週間";

  //2 weeks
  content["2 weeks"] =
  "2 週間";

  //1 month
  content["1 month"] =
  "1 か月";

  //3 months
  content["3 months"] =
  "3 か月";

  //Never
  content["Never"] =
  "なし";

  //Communication Error
  content["Communication Error"] =
  "通信エラー";

  // help message
  content["Automatically close non-threshold violating alarms (for example, system log events or server reboots) if they have been open more than..."] =
  "しきい値違反ではないアラーム (たとえば、システム ログ イベント、またはサーバ再起動) が次の回数を超えて開かれた場合に、自動的に閉じます...";

  // Close Rule help message
  content["And their severity is..."] =
  "およびその重大度...";

  //Critical
  content["Critical"] =
  "クリティカル";

  //Major
  content["Major"] =
  "メジャー";

  //Minor
  content["Minor"] =
  "マイナー";

  //Warning
  content["Warning"] =
  "警告";

  //Info
  content["Info"] =
  "情報";

  //CANCEL
  content["CANCEL"] =
  "キャンセル";

  //SAVE
  content["SAVE"] =
  "保存";

  // END OF LOCALIZATION

export default content;
