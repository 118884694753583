import React, { useEffect } from "react";
import {
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label,
  ReferenceLine,
  ComposedChart,
} from "recharts";
import CustomTooltip from "./CustomTooltip";
import CustomLegend from "./CustomLegend";
import { Box, Typography, Switch, FormControlLabel } from "@mineral/core";
import moment from "moment";
import CustomizedDot from "./CustomPlotPoint";

export const MineralChart = ({
  selectedCombos,
  metricDevice,
  index,
  width,
  height,
  aggregationValue,
  xAxisTickFormatter,
  layout,
  ref,
  columns,
  expandedChartIndex,
  isColorChange
}) => {  
  const data = metricDevice.deviceData[0];  
  let refRec;
  if(aggregationValue===-1)
  refRec = metricDevice.chartDefinationRaw[data.deviceName + '-' + data.ciName];  
  else  
  refRec = metricDevice.chartDefination;
  const toTimestamp = (strDate) => {
    return new Date(strDate).getTime()/1000;
  };  
  const [refRecord, setRefRecord] = React.useState(refRec!==undefined && refRec[refRec?.length - 1]);
  const [defaultRefPoint, setDefaultRefPoint] = React.useState(refRecord);
  const [selectedColors,setSelectedColors]=React.useState([]);
  const startTime = refRec?.ts!==undefined?refRec[0].ts:refRec?toTimestamp(refRec[0]?.sampleTime):0;
  const endTime = refRec?.ts!==undefined?refRec[refRec.length - 1].ts:refRec?toTimestamp(refRec[refRec.length - 1]?.sampleTime):0;   

  React.useEffect(()=>{
    setDefaultRefPoint(refRecord);
  },[refRecord])

  React.useEffect(()=>{
    if(!isColorChange)
    {      
      if(localStorage.getItem("color_"+index)!==null)
      setSelectedColors(JSON.parse(localStorage.getItem("color_"+index)))
      else
      {
        localStorage.setItem("color_"+index,JSON.stringify(selectedCombos))
        setSelectedColors(selectedCombos)
      }
    }
    else
    {
      localStorage.setItem("color_"+index,JSON.stringify(selectedCombos))
      setSelectedColors(selectedCombos)
    }
    
  },[selectedCombos])
  
  const ReferenceLineLabel = (props) => {
    const { viewBox, label, layout } = props;
    let xcoord = 0
    if(layout === 'vertical') {
      if (viewBox.x > 150 && viewBox.x < 550) {
        xcoord = viewBox.x - 140
      }
      else if (viewBox.x < 150) {
        xcoord = viewBox.x - 50
      }
      else {
        xcoord = viewBox.x - 275
      }
    }
    else {
      if (viewBox.x > 150 && viewBox.x < 500) {
        xcoord = viewBox.x - 140
      }
      else if (viewBox.x < 150) {
        xcoord = viewBox.x - 50
      }
      else {
        xcoord = viewBox.x - 270
      }
    }
    return (
      <g>
        <foreignObject
          x={xcoord}
          y={viewBox.y - 20}
          width={275}
          height={viewBox.height + 20}
        >
          <Box
            sx={{
              backgroundColor: "#434A54",
              color: "white",
              textAlign: "center",
              borderRadius: 1,
              height: "20px",
            }}
          >
            <Typography variant="body2" sx={{ color: "white" }}>{label}</Typography>
          </Box>
        </foreignObject>
      </g>
    );
  };

  const getStroke = (stroke) => {
    switch (stroke) {
      case 'dashed':
        return '5 5'
        break;
      case 'spacedDashed':
        return '5 0'
        break;
      case 'dotted':
        return '2 2'
        break;
      case 'dashDot':
        return '10 10'
        break;
      case 'solid':
      default: return '0 0'
    }
  }

 const dataInterPolation = (dataArray, aggregationInterval) => {
    if (aggregationInterval == -1) {
      return dataArray;
    }
    let outputArray = [];
    let count = 0;
    let keys = [];
    dataArray &&
      dataArray.forEach((dataObject) => {
        outputArray.push(dataObject);
        if (count < dataArray.length - 1) {
          let nextObject = dataArray[count + 1];
          if (count === 0) {
            keys = Object.keys(dataObject);
            keys = keys.filter((key) => key !== "sampleTime");
          }
          let dataObjectMilli = getDateInMilli(dataObject.sampleTime);
          let nextObjectMilli = getDateInMilli(nextObject.sampleTime);
          let sampleDiff = nextObjectMilli - dataObjectMilli;
          let copiedObject = dataObject;
          while (sampleDiff > aggregationInterval * 1000) {
            let newObject = {};
            let newSampleTime = dataObjectMilli + aggregationInterval * 1000;
            let newDate = new Date(newSampleTime);
            newObject["sampleTime"] =
              newDate.getFullYear() +
              "-" +
              (newDate.getMonth() + 1 + "").padStart(2, "0") +
              "-" +
              (newDate.getDate() + "").padStart(2, "0") +
              " " +
              (newDate.getHours() + "").padStart(2, "0") +
              ":" +
              (newDate.getMinutes() + "").padStart(2, "0");
            keys &&
              keys.forEach((key) => {
                let value = copiedObject[key];
                let value1 = nextObject[key];
                let newValue = null;
                if (value !== null && value1 !== null) {
                  let y =
                    value +
                    (newSampleTime - dataObjectMilli) *
                      ((value1 - value) / (nextObjectMilli - dataObjectMilli));
                  newValue = y < 0 ? y * -1 : y;
                  newValue =
                    newValue == 0
                      ? 0
                      : ((newValue + Number.EPSILON) * 100) / 100;
                }
                newObject[key] = newValue;
              });
            outputArray.push(newObject);
            sampleDiff = nextObjectMilli - newSampleTime;
            dataObjectMilli = newSampleTime;
            copiedObject = newObject;
          }
          count++;
        }
      });
    return outputArray;
  };

  const getDateInMilli = (dateObject) => {
    let dataDateObjArray = dateObject.split(" ");
    let dataTimeArray = dataDateObjArray[1].split(":");
    let dataDateArray = dataDateObjArray[0].split("-");
    let dataDate = new Date(
      dataDateArray[0],
      dataDateArray[1] * 1 - 1,
      dataDateArray[2],
      dataTimeArray[0],
      dataTimeArray[1]
    );
    return dataDate.getTime();
  };

  const getDeviceChartData = (metricDevice, aggInterval, selectedCombos) => {
    let lineChartArray = [];
    let sampleDataLength = 0;
    if (aggInterval !== -1) {
      sampleDataLength = metricDevice ? (metricDevice.chartDefination ? metricDevice.chartDefination.length : 0) : 0;
    } else {
      sampleDataLength = metricDevice ? (metricDevice.sampleTimeLength ? metricDevice.sampleTimeLength : 0) : 0;
    }
    if (metricDevice.deviceData) {
      metricDevice.deviceData.map((device, i) => {
        let dataKey = device.deviceName;
        if (device.ciName) {
          dataKey = `${dataKey}-${device.ciName}`;
        }
        let lineData = metricDevice ? (metricDevice.chartDefinationRaw ?
          metricDevice.chartDefinationRaw[dataKey]
          : null) : null;

        lineChartArray.push(
          <Line
            data={lineData}
            // strokeDasharray={getStroke(selectedCombos[i].stroke)}
            dataKey={dataKey}
            key={dataKey}
            dot={false}   
            connectNulls={false}         
            stroke={selectedCombos[i]?.color}
            isAnimationActive={false}
            animationDuration={0}
          />
        );
      });
    }
    return lineChartArray;
  };
  //let data1 = dataInterPolation(metricDevice.chartDefination,aggregationValue)
  let data1 = metricDevice.chartDefination
  return (
    <>
      <ResponsiveContainer width={'95%'} height={height}>
        <ComposedChart
        style={{ zindex:-1 }}
          data={aggregationValue !== -1 ? data1 : null}
          connectNulls={false}
          key={index}
          margin={{
            top: 20,
            right: 30,
            left: 30,
            bottom: 0,
          }}
          onClick={(nextState, event) => {
            if (nextState) {
              setRefRecord(nextState.activePayload[0].payload);
              setDefaultRefPoint(nextState.activePayload[0].payload);
            }
          }}
          onMouseMove={(nextState, event) => {
            if (nextState.isTooltipActive) {
              setDefaultRefPoint(nextState.activePayload[0].payload);
            }
            else {
              setDefaultRefPoint(refRecord);
            }
          }}
          onMouseLeave={() => {
            setDefaultRefPoint(refRecord);
          }}
          id={`${metricDevice.name}_${index}`}
          ref={ref}
        >
          <CartesianGrid strokeDasharray={2} />

          <XAxis dataKey={aggregationValue !== -1 ? "sampleTime" : "ts"}
            type={aggregationValue !== -1 ? 'category': 'number'}
            interval={"preserveStartEnd"}
            tickFormatter={(tick) => xAxisTickFormatter(tick, `${metricDevice.name}_${index}`, aggregationValue)}
            domain={['dataMin', 'dataMax']}
            tickCount={15}
          />
          <YAxis axisLine={true} tickLine={false} 
                  domain={((metricDevice.unit.toUpperCase() == "pct".toUpperCase() || metricDevice.unit.toUpperCase == "Percent".toUpperCase) && metricDevice.hasMax == 1)?[0,100]
                  :(metricDevice.isBool == 1?[0,1]:[0,'auto'])} />
          <Tooltip
            content={<CustomTooltip aggregationValue={aggregationValue} />}
          />
          <Legend
            wrapperStyle={{
              width: layout === 'vertical' ? '30%' : '95%',
              height: layout === 'vertical' ? '95%' : '50%',
              paddingTop: layout !== 'vertical' ? '10px' : undefined,
              paddingLeft: layout === 'vertical' ? '20px' : undefined, 
              left: (index!==expandedChartIndex)?'30px':'68px',  
              padding: '0 0 0 0',           
            }}
            layout={layout}
            verticalAlign={layout === 'vertical' ? "top" : "bottom"}
            align={layout === 'vertical' ? "right" : undefined}
            content={(props) => CustomLegend({
              data: metricDevice,
              selectedCombos: selectedColors,
              referenceData: aggregationValue !== -1 ? defaultRefPoint?.sampleTime : defaultRefPoint?.ts,
              refLineData: refRecord,
              setRefLineData: setRefRecord,              
              startTime: startTime,
              endTime: endTime,
              columns: columns,
              aggregationValue: aggregationValue,
              ...props
            })}            
          />
          <ReferenceLine x={refRecord?.ts?refRecord?.ts:refRecord?.sampleTime} stroke="#434A54" strokeDasharray="3 3">
            <Label
              position={"top"}
              content={""}
            ></Label>
          </ReferenceLine>
          {getDeviceChartData(metricDevice, aggregationValue, selectedColors)}
        </ComposedChart>
      </ResponsiveContainer>
    </>
  );
} 