// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //dashError
  content["Dashboard failed to load."] =
  "无法加载显示板。";

  //missing component
  content["CA Business Intelligence (CABI) system component is needed."] =
  "需要 CA Business Intelligence (CABI) 系统组件。";

  //Breadcrumb
  content["Dashboards"] =
  "显示板";

  //Breadcrumb
  content["Overview"] =
  "概览";

  // END OF LOCALIZATION

export default content;
