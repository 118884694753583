import React, {Component} from 'react'
import connect from './../../utils/connect'
import {setBreadCrumbs} from './../breadcrumbs/actions'
import {requestReportsFolder} from '../../api/reports/actions'
import Reports from './Reports'
import {CenterError as Error} from '../ui-components/uim-components'
import {CircularProgress} from '@mineral/core'
import fetchStatusTypes from '../../api/fetchStatusTypes'
import CabiApi from '../../api/cabi/cabi'
import Resource from './Resource'
import { withRouter } from "react-router";
import visualize from "./../../utils/visualize";

class ReportsRedux extends Component {
  state = {
    cabiInfoStatus: 'loading',
    cabiInfo: undefined

  }

  componentWillMount() {
    const reportFolderPath = this.props.match.params.folderIds;

    // get CABI server and login info
    CabiApi.get('en', this.props.sid)
      .then(response => response.data)
      .then(json => {
        this.setState({
          cabiInfoStatus: 'complete',
          cabiInfo: {
            address: json.address,
            token: json.token,
            userLocale: json.locale,
            orgidl: json.orgidl ? json.orgidl : "ca"
          }
        }, () => {
        	window.sessionStorage.setItem("cabiAddress", this.state.cabiInfo.address)	
   
          let viz = new visualize();
          let cabiProps={saas:this.props.saas,doiurl: this.props.doiurl,featureFlags:this.props.featureFlags};
          viz.setProductList(cabiProps);
          viz.checkAndLoadVisualize(json.address).then((isLoaded) => {
                if (isLoaded) { viz.justLogin({
            url:  json.address,
            jasperToken: json.token,
            orgidl: json.orgidl ? json.orgidl : "ca",
            locale: json.locale,
            
                                      })
          .then((response) => {
            //console.log("Successfully logged into jasperserver")
            this.props.requestReportsFolder(reportFolderPath, this.state.cabiInfo)
            this.update(this.props)

          })
            .catch((error) => {
             // console.log("error in loading dashboard", error);
             //resolve([]);
             console.log("Failed to login to jasperserver"+error);
              this.setState({
                cabiInfoStatus: "failed",
              });
            });
          }else
          {
            console.log("Failed to login to jasperserver");
            this.setState({
              cabiInfoStatus: "failed",
            });
          }}).catch((error) => {
              // console.log("error in loading dashboard", error);
              //resolve([]);
              console.log("Failed to login to jasperserver"+error);
              this.setState({
                cabiInfoStatus: "failed",
              });
              });
         
        })
      })
      .catch(error => {
        console.error('Error retrieving cabi info:', error)
        this.setState({
          cabiInfoStatus: 'failed',
        })
      })

  }

  componentWillReceiveProps(nextProps) {
    this.update(nextProps)
  }

  update = (props) => {

    const newFolderPath = props.match.params.folderIds ? props.match.params.folderIds : ''
    const priorFolderPath = this.props.match.params.folderIds ? this.props.match.params.folderIds : ''

    if(newFolderPath !== priorFolderPath){
      props.requestReportsFolder(newFolderPath, this.state.cabiInfo)
    }

    let link = '/reports'
    //Reports
    const reportsCrumb = {name: Resource.get('Reports'), link:link }
    if(newFolderPath.length > 0){
      // Set the bread crumb based on the path to the report folder
      const pathParts = newFolderPath.split('/')
      const crumbsArray = [reportsCrumb]

      // add a crumb for each folder in the path
      pathParts.forEach(part => {
        link += '/' + part
        // Uppercase first character of label
        const partDisplayLabel = part.charAt(0).toUpperCase() + part.slice(1)
        crumbsArray.push({name:partDisplayLabel, link:link})
      })
      props.setBreadCrumbs(crumbsArray)
    }else{
      // Top level breadcrumbs need to be set as an object, rather than an array or it wont display correctly
      props.setBreadCrumbs(reportsCrumb)
    }


  }

  render() {
    const cabiInfoStatus = this.state.cabiInfoStatus
    const fetchStatus = this.props.reports.fetchStatus
    let view
    if(cabiInfoStatus === 'failed'){
      view = <Error 
      //CABI is not available or accessible
      title={Resource.get("CABI is not available or accessible")}
    		  //Please contact your administrator.
    		  message= {Resource.get('. Please contact your administrator.')} />
    }else{
      switch (fetchStatus) {
        case fetchStatusTypes.COMPLETE:
          view = <Reports items={this.props.reports.items} cabiInfo ={this.state.cabiInfo}/>
          break
        case fetchStatusTypes.FAIL:
          view = <Error />
          break
        case fetchStatusTypes.FETCHING:
        default:
          view = (
            <div style={{ 
              height: 'calc(100vh - 205px)', 
              width: '100%', 
              display: 'flex', 
              justifyContent: 'center', 
              alignItems: 'center' 
              }}>
              <CircularProgress style={{color: '#3272D9'}} />
            </div>
          )
          break
      }
    }

    return view
  }

}

const mapStateToProps = (state) => {
  return {
    reports: state.reports,
    sid: state.sid,
    saas: state.saas,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setBreadCrumbs: (items) => dispatch(setBreadCrumbs(items)),
    requestReportsFolder: (path, cabiInfo) => dispatch(requestReportsFolder(path, cabiInfo))
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReportsRedux))