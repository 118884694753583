import React from "react";
import Breadcrumbs from "./../breadcrumbs/BreadcrumbsRedux";
import TitleBarMenu from "./TitleBarMenu";
import Branding from "./branding/Branding";
import Vr from "./../vr/Vr";
import "./titleBar.less";
import BrandLogo from "./BrandLogo";

const TitleBar = (props) => {
  let breadcrumbArray = window.location.pathname.endsWith("/treeviewnew")?props.breadCrumbs.slice(0,2):props.breadCrumbs;
  breadcrumbArray = breadcrumbArray.filter((v,i,a)=>a.findIndex(v2=>['name'].every(k=>v2[k] ===v[k]))===i);

  breadcrumbArray = breadcrumbArray.filter((bredCrumb) => bredCrumb.name !== undefined)
  return (
    <header className="titlebarheader">
      <BrandLogo />
      <Branding saas={props.saas} />
      <Vr />
      <div
        style={{
          display: "flex",
          flexFlow: "column nowrap",
          position: "relative",
        }}
      >
        <button
          tabIndex={0}
          className="skip-content-link" style={{marginTop:breadcrumbArray?.length===1?'2px':'8px'}}
          onClick={()=>{document.getElementById('first-content-element')?.focus()}}
        >
          Skip to main content
        </button>
        <Breadcrumbs className="titlebarheader__breadcrumb" />
      </div>
      <TitleBarMenu {...props} />
    </header>
  );
};

export default TitleBar;
