import React from "react";
import axios from "axios";

import { Autocomplete ,CircularProgress, TextField, Tooltip,FormControl,InputLabel,Typography } from '@mineral/core'

import { useDispatch } from "react-redux";
import "./PerformanceLayoutSelect.less";
import {
  getHostsByTarget,
  getTargetsByHost,
} from "../../../../../../api/performanceReports/actions";

const PerformanceLayoutSelect = (props) => {
  const dispatch = useDispatch();

  const [searchText, setSearchText] = React.useState("");

  React.useEffect(() => {
    let active = true;

    if (!props.loading) {
      return undefined;
    }

    (async () => {
      let selectData;
      try {
        let updatedUrl;
        if (props.url.includes("searchText") && props.value !== null) {
          updatedUrl = props.url.replace(
            "searchText=",
            `searchText=${searchText}`
          );
        } else {
          setSearchText("");
          updatedUrl = props.url;
        }

        let response = null;
        if (process.env.NODE_ENV == "development") {
          response = await axios.get(updatedUrl);
        } else {
          response = await axios.post(updatedUrl);
        }

        selectData = response.data;
      } catch (error) {
        console.log(error);
        active = false;
      }

      if (active) {
        props.setOptions(selectData);
      }

      return () => {
        active = false;
      };
    })();
  }, [props.loading, searchText]);

  React.useEffect(() => {
    if (!props.open) {
      props.setOptions([]);
    }
  }, [props.open]);

  return (
    <FormControl fullWidth={true} style={{marginBottom:'8px'}}>
      <InputLabel>{props.name}</InputLabel>
      <Tooltip title={props.name}>
        <Autocomplete
          value={props.value !== null ? props.value : ""}
          disabled={props.disabled}
          id={props.id}
          classes={{ root: "prd-select-box" }}
          open={props.open}
          onOpen={() => {
            props.setOpen(true);
          }}
          onClose={() => {
            props.setOpen(false);
          }}
          onChange={(event, value) => {
            props.selectedValue(value);
            if (props.id === "Host-host" || props.id === "QoS-target") {
              props.resetNextValue("");
            }
            dispatch(getTargetsByHost([]));
            dispatch(getHostsByTarget([]));
          }}
          getOptionSelected={(option, value) => option === value}
          getOptionLabel={(option) => option}
          options={props.options}
          loading={props.loading}
          renderOption={(props, option, state) => (
            <Tooltip title = {option} placement="top-end">
            <li {...props}>
              <Typography variant="inherit" noWrap>
                {option}
              </Typography>
            </li>
            </Tooltip>
          )}
          renderInput={(params) => {
            return (
              <TextField
                {...params} size="small"
                placeholder={props.placeholder}
                variant="outlined"
                // value={searchText}
                onChange={(event) => {
                  setSearchText(event.target.value);
                }}
                InputProps={{
                  ...params.InputProps,
                  style: {
                    padding: "5px 35px 5px 5px",
                  },
                  endAdornment: (
                    <React.Fragment>
                      {props.loading ? (
                        <div
                          style={{
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <CircularProgress style={{ color: "#3272D9" }} />
                        </div>
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            );
          }}
        />
      </Tooltip>
    </FormControl>
  );
};

export default PerformanceLayoutSelect;
