import React, { Component } from "react";
import PropTypes from "prop-types";
import { Card ,Button} from "@mineral/core";
import "./portlet.less";
import { NavLink } from "react-router-dom";
import Resource from "../Resource";

class Portlet extends Component {
  getCardClassName() {
    return ["portlet", this.props.className].join(" ");
  }

  render() {
    //Navigate to
    const NAVIGATETO=Resource.get("Navigate to");

    const linkPurpose = `${NAVIGATETO} ${this.props.logo.props.label}`;
    return (
      <div className="settings-card"  tabIndex={-1}> 
        <NavLink
          className="settings__card--navlink"  tabIndex={-1}
          to={this.props.configRoute} 
        >
          <Button style={{backgroundColor:'#FFFFFF'}} variant="outlined" role ="link" component="button" aria-label={linkPurpose} className={this.getCardClassName()} id={this.props.id} autoFocus={this.props.autoFocusAttr}>
          
            <div className="portlet--body" style={{flexDirection:'column'}}>
           
            {this.props.logo}
            
            <div style={{marginTop: '10px'}}>
                <span className="portlet--subLabelOne">
                  {this.props.subLabelOne}
                </span>
                </div>
                <div style={{display:'flex',flexDirection:'row'}}>
                <span className="portlet--subLabelTwo">
                  {this.getSubLabelTwo()}
                </span>
                <div className="portlet--icon">{this.getIcon()}</div>
              </div>
            
            </div>
          </Button>
        </NavLink>
      </div>
    );
  }
}

export default Portlet;

Portlet.propTypes = {
  logo: PropTypes.element.isRequired,
  subLabelOne: PropTypes.string.isRequired,
  subLabelTwo: PropTypes.string.isRequired,
  configRoute: PropTypes.string.isRequired,
};
