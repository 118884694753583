// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Device Details
  content["Device Details"] =
  "Device Details";

  // Alarms
  content["Alarms"] =
  "Alarms";

  // Dashboards
  content["Dashboards"] =
  "Dashboards";

  // Maintenance
  content["Maintenance"] =
  "Maintenance";

  // Metrics
  content["Metrics"] =
  "Metrics";

  // Monitoring Config
  content["Monitoring Config"] =
  "Monitoring Config";

  // Interfaces
  content["Interfaces"] =
  "Interfaces";

  // Card View
  content["Card View"] =
  "Card View";

  // List View
  content["List View"] =
  "List View";

  // Tree View
  content["Tree View"] =
  "Tree View";

  //Cards
  content["Cards"] =
  "Cards";

  //List
  content["List"] =
  "List";

  //List
  content["Tree"] =
  "Tree";

  //Detail
  content["Detail View"] =
  "Detail View";

  // END OF LOCALIZATION

export default content;
