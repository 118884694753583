import * as React from 'react';
import { SvgIcon } from '@mineral/core';

export const FilledSquare = React.forwardRef(
  (props, ref) => (
    <SvgIcon data-testid="LearnIcon" viewBox="0 0 24 24" {...props} ref={ref}>
      <rect x="4" y="4" width={props.width} height={props.height} fill={props.color} />
    </SvgIcon>
  )
);

