import {
  FETCH_PRODUCT_USAGE_METRICS,
  FETCH_PRODUCT_USAGE_METRICS_SUCCESS,
  FETCH_PRODUCT_USAGE_METRICS_FAILURE,
 
} from "./actionTypes";
import productUsageMetrics from "./productUsageMetrics";
import * as IntegrationError from "../../components/settings/integrations/IntegrationError";

function getResponseError(error) {
  if (error.response.data && error.response.data.message) {
    return error.response.data;
  } else {
    return IntegrationError.getDefaultError();
  }
}


export function fetchProductUsageMetrics() {
  return {
    type: FETCH_PRODUCT_USAGE_METRICS,
  };
}
export function fetchProductUsageMetricsSuccess(productUsageMetricsData) {
  return {
    type: FETCH_PRODUCT_USAGE_METRICS_SUCCESS,
    productUsageMetricsData: productUsageMetricsData,
  };
}
export function fetchProductUsageMetricsFailure(error) {
  return {
    type: FETCH_PRODUCT_USAGE_METRICS_FAILURE,
    errorMessage: error.message,
  };
}

export function getProductUsageMetricsData(params) {
  return (dispatch) => {
    dispatch(fetchProductUsageMetrics());
    return productUsageMetrics
      .getUser(params)
      .then((response) => dispatch(fetchProductUsageMetricsSuccess(response.data)))
      .catch(function (error) {
        dispatch(fetchProductUsageMetricsFailure(getResponseError(error)));
      });
  };
}
