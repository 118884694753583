// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //OK
  content["Yes"] =
  "Sí";

  //OK
  content["No"] =
  "No";

  //OK
  content["OK"] =
  "Aceptar";

  //Upload JSON file(s)
  content["Upload JSON file(s)"] =
  "Cargar archivos JSON";

  //Click TEST to begin
  content["Click PROCEED to begin"] =
  "Haga clic en CONTINUAR para empezar.";

  // Processing...
  content["Processing..."] =
  "Procesando...";

  //Attempting to communicate with API
  content["Attempting to communicate with API"] =
  "Intentando establecer la comunicación con la API...";

  // Schema passed tests
  content["Schema passed tests, click DONE below"] =
  "El esquema ha superado las pruebas. Haga clic en FINALIZADO.";

  //Schema could not be processed.Check logs below
  content["Schema could not be processed.Check logs below"] =
  "No se ha podido procesar el esquema. Compruebe los siguientes registros.";

  //Unable  to communicate with API
  content["Unable to communicate with API"] =
  "No se puede establecer la comunicación con la API.";

  //Click TEST to begin
  content["click TEST to begin"] =
  "Haga clic en PROBAR para empezar.";

  // Download RESTMon schema template
  content["Download RESTMon schema template"] =
  "Descargar plantilla de esquema RESTMon";

  // Download the RESTMon schema template and then configure to monitor RESTful API
  content["Download the RESTMon schema template and then configure to monitor your RESTful API"] =
  "Descargue la plantilla de esquema RESTMon y, a continuación, configure la monitorización de la API de RESTful.";

  //RESTMon schema template
  content["RESTMon schema template"] =
  "Plantilla de esquema RESTMon";

  //DOWNLOAD
  content["DOWNLOAD"] =
  "DESCARGAR";

  //Upload custom RESTMon schema
  content["Upload custom RESTMon schema"] =
  "Cargar el esquema de RESTMon personalizado";

  // RESTMon instruction message
  content["After modifying the RESTMon schema to meet your needs, upload the JSON file to be tested"] =
  "Después de modificar el esquema de RESTMon en función de sus requisitos, cargue el archivo JSON para probarlo.";

  // Upload file...
  content["Upload File..."] =
  "Cargar archivo...";

  // Test custom schema
  content["Validate and deploy custom schema"] =
  "Validar e implementar esquema personalizado";

  //schema validation & deployment
  content["schema validation & probe package deployment"] =
  "validación del esquema e implementación del paquete de la sonda";

  //TEST
  content["PROCEED"] =
  "CONTINUAR";

  //Directions
  content["Directions"] =
  "Direcciones";

  //Home
  content["Home"] =
  "Casa";

  // Modify schema to collect API-defined metrics
  content["Modify schema to collect API-defined metrics	"] =
  "Modifique el esquema para recopilar las métricas definidas por la API.	";

  // Upload modified schema (JSON file)
  content["Upload schema file(s) related to a technology"] =
  "Cargue el archivo o archivos del esquema relacionados con una tecnología.";

  // Test schema syntax
  content["Test schema syntax"] =
  "Probar sintaxis de esquema";

  //Select Done
  content["Select Done"] =
  "Seleccione Finalizado.";

  //HELP
  content["HELP"] =
  "Ayuda";

  //Help
  content["Help"] =
  "Ayuda";

  //Reset
  content["Reset"] =
  "Restablecer";

  //Done
  content["Done"] =
  "Finalizado";

  //Browse
  content["Browse"] =
  "Examinar";

  //To validate and deploy the schema upload the file(s) and provide a friendly name, and then, click Proceed
  content["To validate and deploy the schema upload the file(s) and provide a friendly name, and then, click Proceed"] =
  "Para validar e implementar el esquema, cargue el archivo o archivos, proporcione un nombre descriptivo y, a continuación, haga clic en Continuar.";

  //Default template schemas(restmon-sample-schemas) can be found at support.nimsoft.com
  content["Default template schemas (restmon-sample-schemas) can be found at support.nimsoft.com"] =
  "Para obtener esquemas de plantillas predeterminados (restmon-sample-schemas), vaya a support.nimsoft.com.";

  //Friendly Name can not contain any of the following characters
  content["Friendly Name can not contain any of the following characters"] =
  "Un nombre descriptivo no puede contener ninguno de los caracteres siguientes";

  // END OF LOCALIZATION

export default content;
