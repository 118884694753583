import {
    REQUEST_DEVICES,FETCH_DEVICES_DASHBOARD_SUCCESS, FETCH_DEVICES_DASHBOARD_FAILURE,
    REQUEST_DEVICE_PROFILES,FETCH_DEVICE_PROFILES_DASHBOARD_SUCCESS,FETCH_DEVICE_PROFILES_DASHBOARD_FAILURE,
	REQUEST_PROFILE_BY_PROFILEID,FETCH_PROFILE_BY_PROFILEID_DASHBOARD_SUCCESS,FETCH_PROFILE_BY_PROFILEID_DASHBOARD_FAILURE
   
    
} from './actionTypes'



  export function fetchDevices(options) {
      return {
          type: REQUEST_DEVICES,
          options:options
          
      }
  }
    export function fetchDevicesSuccess(json) {
        return {
            type: FETCH_DEVICES_DASHBOARD_SUCCESS,
           devicesDetails: json
        }
    }

    export function fetchDevicesFailure(error) {
        return {
            type: FETCH_DEVICES_DASHBOARD_FAILURE,
            errorMessage: error.message,
            options:error.options
        }
	}
	
	

    export function fetchDeviceProfiles(options) {
        return {
            type: REQUEST_DEVICE_PROFILES,
            options:options
        }
    }
export function fetchDeviceProfilesFailure(error) {
    return {
        type: FETCH_DEVICE_PROFILES_DASHBOARD_FAILURE,
        errorMessage: error.message,
        options:error.options
    }
}

    export function fetchDeviceProfilesSuccess(json) {
        return {
            type: FETCH_DEVICE_PROFILES_DASHBOARD_SUCCESS,
            profilesDetails: json
        }
    }

   

    export function fetchProfileByProfileId(profileId) {
        return {
            type: REQUEST_PROFILE_BY_PROFILEID,
            profileId : profileId
        }
    }
    export function fetchProfileByProfileIdSuccess(json) {
        return {
            type: FETCH_PROFILE_BY_PROFILEID_DASHBOARD_SUCCESS,
            profileDetails: json
        }
    }

    export function fetchPofileByProfileIdFailure(error) {
        return {
            type: FETCH_PROFILE_BY_PROFILEID_DASHBOARD_FAILURE,
            errorMessage: error.message
        }

    }

     
    



  
  
  