import React from 'react'

const InformationIcon = ({ width, height }) => {
    return (
        <svg width={width ? width : '16'} height={height ? height : '16'} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10 0C15.5228 0 20 4.09304 20 9.14205C20 14.1911 15.5228 18.2841 10 18.2841C8.30706 18.2841 6.71238 17.8995 5.31479 17.2207L2.30476 19.8008C1.94565 20.1086 1.41593 20.0536 1.1216 19.6781C0.968361 19.4826 0.903015 19.2275 0.942242 18.9781L1.68918 14.2281C0.622338 12.774 0 11.0245 0 9.14205C0 4.09304 4.47715 0 10 0ZM11.12 8H8.87V14.5H11.12V8ZM10 3.3C9.17157 3.3 8.5 3.97157 8.5 4.8C8.5 5.62843 9.17157 6.3 10 6.3C10.8284 6.3 11.5 5.62843 11.5 4.8C11.5 3.97157 10.8284 3.3 10 3.3Z"
                fill="#58606E" />
        </svg>
    )
}

export default InformationIcon