// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //help msg
  content["Unable to connect to Operator Console. Restore the connection to {0} and verify that wasp is running. Attempting to reestablish connection..."] =
  "オペレータ コンソールに接続できません。{0} への接続をリストアし、wasp が実行されていることを確認してください。接続の再確立を試行しています...";

  //Unable to connect to server
  content["Unable to connect to server"] =
  "サーバに接続することができません";

  // END OF LOCALIZATION

export default content;
