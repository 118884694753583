// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Metrics Monitoring
  content["Metrics Monitoring"] =
  "Metrics Monitoring";

  //Top 10 Entities
  content["Top 10 Entities"] =
  "Top 10 Entities";

  //By Severity, By Alarms
  content["By Severity, By Alarms"] =
  "By Severity, By Alarms";

  //Close
  content["Close"] =
  "Close";

  // END OF LOCALIZATION

export default content;
