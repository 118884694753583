import connect from './utils/connect'
import App from './App'

function mapStateToProps(state) {
  return {
    featureFlags: state.configuration.items,
    heartbeat: state.heartbeat,
    saas: state.saas,
    isTimedout: state.isTimedout,
    ocviews: state.ocviews,
  }
}
export default connect(mapStateToProps, null)(App)
