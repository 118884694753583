// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Scope
  content["Scope"] =
  "Scope";

  //View
  content["View"] =
  "View";

  //published Dashboards
  content["Published Dashboards"] =
  "Published Dashboards";

  //Accounts
  content["Accounts"] =
  "Accounts";

  //Selectall
  content["Select All"] =
  "Select All";

  //Clearall
  content["Clear All"] =
  "Clear All";

  //multiSelectHint
  content["Hold Shift or Command or Control key to select multiple."] =
  "Hold Shift or Command or Control key to select multiple.";

  //unassignedAccountHint
  content["Unassigned accounts default to Home."] =
  "Unassigned accounts default to Home.";

  //Clear
  content["Clear"] =
  "Clear";

  //Delete
  content["Delete"] =
  "Delete";

  //Apply
  content["Apply"] =
  "Apply";

  //UserPrefsSuccess
  content["User preferences saved successfully"] =
  "User preferences saved successfully";

  //UserPrefsFailure
  content["Failed to save user preferences"] =
  "Failed to save user preferences";

  //UserPrefsResetSuccess
  content["User preferences deleted successfully"] =
  "User preferences deleted successfully";

  //UserPrefsResetFail
  content["Failed to delete user preferences"] =
  "Failed to delete user preferences";

  //UserPrefsDeleteNo
  content["No user preferences to delete"] =
  "No user preferences to delete";

  //default
  content["Default"] =
  "Default";

  //Available
  content["Available"] =
  "Available";

  //Preview
  content["Preview"] =
  "Preview";

  //NoPrev
  content["No Preview Found. Choose a view to preview."] =
  "No Preview Found. Choose a view to preview.";

  //Manage Display Preferences
  content["Manage display preferences"] =
  "Manage display preferences";

  //customizeSubTitle
  content["Customize default views to user after login."] =
  "Customize default views to user after login.";

  //Settings
  content["Settings"] =
  "Settings";

  //Personalize
  content["Personalize"] =
  "Personalize";

  // END OF LOCALIZATION

export default content;
