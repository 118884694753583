import connect from './../../utils/connect'
import AdminConsole from './AdminConsole'
import { addBreadCrumb, setBreadCrumbs } from './../breadcrumbs/actions'

const mapStateToProps = (state) => {
  return {
    sid: state.sid,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    addBreadCrumb: (items) => dispatch(addBreadCrumb(items)),
    setBreadCrumbs: (breadCrumbs) => dispatch(setBreadCrumbs(breadCrumbs))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(AdminConsole)