import React from 'react';
import PropTypes from 'prop-types';

import {IconButton,Tooltip,Chip} from '@mineral/core';
import { Cross as CloseIcon } from '@mineral/icons';
import Resource from '../titlebar/Resource';
const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    padding: theme.spacing.unit / 2,
  },
  chip: {
    margin: theme.spacing.unit / 2,
  },
});
const removeicon = Resource.get("Remove file");//Remove file
class FilesList extends React.Component {

  render() {
    return (
      <div style={{display:'flex',justifyContent:'baseline',padding:'4px',maxWidth:'800px',minWidth:'20px',minHeight:'38px',maxHeight:'30px',overflowY:'auto',flexWrap:'wrap'}}>
        {this.props.data && this.props.data.length>0 ?this.props.data.map((data,index) => {          
          return (
            <Chip id={"fileuploadedrestmon"+index}
              tabIndex={-1}
              deleteIcon={<Tooltip title="Remove file"><IconButton  aria-label={removeicon} style={{marginBottom: '2px',marginRight: "10px"}}><CloseIcon role='figure' /></IconButton></Tooltip>}
              key={index}
              label={data.name}
              role ="group"
              style={{margin:'3px'}}
              onDelete={()=>this.props.onFileDelete(data)}              
            />
          );
        }):null}
      </div>
    );
  }
}


export default FilesList;