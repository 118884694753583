import React from 'react'
import { useDispatch, useSelector } from 'react-redux';

import {TextField ,FormControl,InputLabel}from '@mineral/core';
import { makeStyles } from '@material-ui/core/styles';

import '../PerformanceLayoutSelection.less'
import PerformanceLayoutSelect from '../PerformanceLayoutSelect';
import PerformanceLayoutCheckboxes from '../PerformanceLayoutCheckboxes';
import { getQosValueByTarget, getTargetValueByTarget, getHostsByTarget } from '../../../../../../../api/performanceReports/actions';
import prd from '../../../../../../../api/performanceReports/prd'


const PerformanceLayoutTarget = (props) => {
    
    const qosValue = useSelector((state) => state.performanceReports.byTarget.qos)
    const targetValue = useSelector((state) => state.performanceReports.byTarget.target)
    const selectedHost = useSelector((state) => state.performanceReports.byTarget.hosts)
    const dispatch = useDispatch()

    const [openQoS, setOpenQoS] = React.useState(false);
    const [optionsQoS, setOptionsQoS] = React.useState([]);
    const loadingQoS = openQoS && optionsQoS.length === 0;
    const urlQoS = prd.getQosList();
    const nameQoS = "QoS"
    const idQos = "QoS-target"
    const placeholderQoS = "Search QoS or select from list"

    const [openTarget, setOpenTarget] = React.useState(false);
    const [optionsTarget, setOptionsTarget] = React.useState([]);
    const loadingTarget = openTarget && optionsTarget.length === 0;
    const urlTarget = prd.getTargetListByQos(qosValue);
    const nameTarget = "Target"
    const idTarget = "Target-target"
    const placeholderTarget = "Search Target or select from list"

    const [openHost, setOpenHost] = React.useState(false);
    const [optionsHost, setOptionsHost] = React.useState([]);
    const [searchHost, setSearchHost] = React.useState(optionsTarget)
    const urlHost = prd.getSourceListByQosAndTarget(qosValue,targetValue);
    const nameHost = "Host"
    const idHost = "Host-target"
    const placeholderHost = "Search host or select from list"

    const resetTargetValueHandler = (value) => {
        dispatch(getTargetValueByTarget(value))
    }

    React.useEffect(() => {
        props.isSelectedHost(selectedHost)
        props.isTargetValue(targetValue)
    },[selectedHost, targetValue])

    return (
        <div style={{padding: '10px'}}>

                <PerformanceLayoutSelect
                    open={openQoS} 
                    setOpen={setOpenQoS}
                    loading={loadingQoS}
                    options={optionsQoS}
                    setOptions={setOptionsQoS}
                    name={nameQoS}
                    url={urlQoS}
                    placeholder={placeholderQoS}
                    id={idQos}
                    value={qosValue}
                    selectedValue={value => dispatch(getQosValueByTarget(value))}
                    resetNextValue={resetTargetValueHandler}
                />

                <PerformanceLayoutSelect
                    open={openTarget} 
                    setOpen={setOpenTarget}
                    loading={loadingTarget}
                    options={optionsTarget}
                    setOptions={setOptionsTarget}
                    name={nameTarget}
                    url={urlTarget}
                    placeholder={placeholderTarget}
                    id={idTarget}
                    disabled={!qosValue}
                    selectedValue={value => dispatch(getTargetValueByTarget(value))}
                    value={targetValue}
                />

                {!targetValue && <FormControl fullWidth={true} 
                           >
                        <InputLabel>{nameHost}</InputLabel>
                        <TextField  
                            disabled={!targetValue}  
                            variant="outlined" 
                            placeholder={placeholderHost}
                            
                        />
                    </FormControl> }

                 { targetValue && <PerformanceLayoutCheckboxes
                    placeholder={placeholderHost}  
                    url={urlHost} 
                    name={nameHost}
                    open={openHost}
                    setOpen={setOpenHost}
                    options={optionsHost}
                    setOptions={setOptionsHost}
                    value={targetValue}
                    selected={selectedHost}
                    setSelected={value => dispatch(getHostsByTarget(value))}
                    search={searchHost}
                    setSearch={setSearchHost}
                  />}
        </div>
    )
}

export default PerformanceLayoutTarget
