import React from 'react'
import Resource from '../../../icons/Resource';
//import './icons.less'

const label = Resource.get("Close Icon");
const Icon = (props) => {
  let classes = [
    props.className,
  ].join(' ')
  return (
    <svg className={classes} role={props.role || 'figure'} aria-label={props.title?props.title:label} alt={props.title?props.title:label} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" 
    >
      <g>
        <title>{props.title}</title>
        <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" stroke ={props.stroke?props.stroke:''}/>
      </g>
    </svg>
  )
}

export default Icon
