import connect from './../../../utils/connect'
import DiscoveryConfiguration from './DiscoveryConfiguration'
import { 
  fetchAgentsStatus,
  pollAgentsStatusStart,
  pollAgentsStatusStop,setSelectedAgent } from '../../../api/DeviceDiscovery/agents/actions'

const mapStateToProps = state => {
    return {
      agentsStatus:state.agents.status,
      selectedAgentInfo:state.agents.selectedAgent
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
      selectedAgent:(agentInfo)=>dispatch(setSelectedAgent(agentInfo)),
      fetchAgentsStatus:()=>dispatch(fetchAgentsStatus()),
      pollAgentsStatusStart:()=>dispatch(pollAgentsStatusStart()),
      pollAgentsStatusStop:()=>dispatch(pollAgentsStatusStop()),
    }
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(DiscoveryConfiguration)