import React, { Component } from "react";
import connect from "./../../../../utils/connect";
import ListDesignerComponent from '@uim/listdesigner'
import axios from "axios";

class ListDesignerWrapper extends Component {
  state = {
    sid: null,
  };

  componentWillMount = () => {
    this.setState({ sid: this.props.sid });
  };

  render() {
    return (
      <div style={{
        minHeight: 'calc(100vh - 64px)',
        width: 'calc(100vw - 60px)',
        marginLeft: 60
      }}>
        <ListDesignerComponent
          axiosInterceptors={axios.default.interceptors}
          sid={this.state.sid} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    sid: state.sid,
  };
};

export default connect(mapStateToProps, null)(ListDesignerWrapper);