import { SET_SELECTED_IF_IDS } from "./actionTypes";

const selectedIfIds = (state = [], action) => {
  switch (action.type) {
    case SET_SELECTED_IF_IDS:     
      return action.ids ? [...action.ids] : state;
    default:
      return state;
  }
};

export default selectedIfIds;
