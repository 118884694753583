import React from "react";
import { useDrag } from "react-dnd";
import {Checkbox,FormControlLabel,Tooltip,Typography} from "@mineral/core";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "200px",
    maxHeight: "250px",
    overflow: "auto",
    width: "100%",
  },
  formLabelSelectAll: {
    margin: 0,
    borderBottom: "1px solid #C8D1E0",
  },
  formLabel: {
    margin: 0,
  },
  formControl: {
    width: "100%",
  },
  indeterminateColor: {
    color: "#00aeef",
  },
  dragIconColor: {
    color: "#8E99AB",
  },
  searchFieldHeight: {
    height: "10px",
  },
}));

const truncateString = (string, limit) => {
  if (string.length > limit) {
    return string.substring(0, limit) + "...";
  } else {
    return string;
  }
};
const PrdCheckboxComponent = (props) => {
  const classes = useStyles();
  const [{ isDragging }, drag, dragPreview] = useDrag(() => ({
    // "type" is required. It is used by the "accept" specification of drop targets.
    type: "ChartItem",
    item: { data: props.data },
    // The collect function utilizes a "monitor" instance (see the Overview for what this is)
    // to pull important pieces of state from the DnD system.
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));
  return (
    <div>
        <div ref={drag}>
        <FormControlLabel
          className={classes.formLabel}
          control={
            <>
              <DragIndicatorIcon />{" "}
              <Checkbox
                onChange={(e) => props.handleChange(e, props.data)}
                checked={props.selected.includes(props.data)}
              style={{marginRight:'4px'}}
                name={props.data}
              />
            </>
          }
          label={(<Typography variant="inherit" noWrap>
            {props.data}
          </Typography>)}
        />
      </div>
    </div>
  );
};

export default PrdCheckboxComponent;