import React from "react";
import { Component } from "react";
import Resource from "./Resource";
import './personalize.less'
import {CircularProgress} from '@mineral/core'
import isEqual from "lodash/isEqual";
const previewLbl=Resource.get("Preview"); //Preview
const noPrev=Resource.get("No Preview Found. Choose a view to preview.") //NoPrev
class RightPane extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    };
  }
  hideSpinner = () => {
    this.setState({
      loading: false
    });
  };
  componentWillMount() {
    // this.setState({
    //   btmHeight: "",
    // });
  }
  getURL() {
    if (window.location.port !== "") {
      return `${window.location.protocol}//${window.location.hostname}:${window.location.port}/operatorconsole_portlet/${this.props.selectedPage}`;
    } else {
      return `${window.location.protocol}//${window.location.hostname}/operatorconsole_portlet/${this.props.selectedPage}`;
    }
  }
  componentWillReceiveProps(nextProps) {
    if( !isEqual(this.props.selectedPage, nextProps.selectedPage) && nextProps.selectedPage!=="")
    this.setState({
      loading: true
    });
  }
  render() {
    if(!this.props.selectedPage || this.props.selectedPage=="")
    return(
      
      <div className="personalizeLeftPane"
       style={{width: "calc(100vw - 376px)"}}>
       
       
       
       
       
       <div className="nopreview__contentImg" 
       style={{ "background-color":"#FFFFFF"}}>
       </div><span className="personalizeLeftPaneSpan">{noPrev}</span></div>


    )
    return (
      <div readOnly={true} style={{width: "calc(100vw - 376px)"}} className="personalizeLeftPane right-pane">
        <div readOnly={true} 
 style={{
            paddingTop: "16px",
            paddingLeft: "16px",
            fontFamily: "Roboto",
fontStyle: "normal",
fontWeight: 500,
fontSize: "14px",
lineHeight: "16px",
color: "#666666",
            display: "flex",
            flexDirection: "column",
            "background-color":"#FFFFFF"
          }}
        >
          <span>{previewLbl}  </span>
</div>
{this.state.loading ? (
          <div readOnly={true} style={{width: '100%', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><CircularProgress aria-label="Loading page..." style={{backgroundColor:'transparent',color: '#3272D9'}}/></div>
        ) : null}
        <div readOnly={true} aria-hidden = {true} className="personalizePaneInsideDiv"
        title = "Personalize PaneInside Div"
        name = "personalizePaneInsideDiv"
        aria-label="Personalize PaneInside Div"
          style={{
            paddingTop: "16px",
            paddingLeft: "16px",
            userSelect: "none",
            pointerEvents: "none",
            "background-color":"#FFFFFF"
           
          }}
        >
          <iframe tabIndex={-1} readOnly={true} 
            src={this.getURL()}
           className="personalizeIframe"
            title = "Personalize Iframe"
           name = "personalizeIframe"
           aria-label="Personalize Iframe"
           onLoad={this.hideSpinner}
            // style="position: absolute; visibility: hidden;"
            // onload="this.style.position='static'; this.style.visibility='visible';"
            //-webkit-transform:scale(0.5);-moz-transform-scale(0.5);
            //             -moz-transform: scale(0.25, 0.25);
            //   -webkit-transform: scale(0.25, 0.25);
            //   -o-transform: scale(0.25, 0.25);
            //   -ms-transform: scale(0.25, 0.25);
            //   transform: scale(0.25, 0.25);
            //   -moz-transform-origin: top left;
            //   -webkit-transform-origin: top left;
            //   -o-transform-origin: top left;
            //   -ms-transform-origin: top left;
            //   transform-origin: top left;
            
            style={{
              "-ms-transform": "scale(0.55)",
              "-moz-transform": "scale(0.55)",
              "-o-transform": "scale(0.55)",
              "-webkit-transform": "scale(0.55)",
              transform: "scale(0.55)",

              "-ms-transform-origin": "0 0",
              "-moz-transform-origin": "0 0",
              "-o-transform-origin": "0 0",
              "-webkit-transform-origin": "0 0",
              "transform-origin": "0 0",
              "border":"none",
              "padding": "16px",
              "box-shadow": "0 0 0 16px #F6F6F6 inset",
              
            }}
            scrolling="no"
            frameborder="0"
            marginheight="0px"
            marginwidth="0px"
            
            // style={{
            //   overflow: "hidden",
            //   WebkitTransform: "scale(0.5,0.5)",
            //   transform: "scale(0.5,0.5)",
            //   msTransform: "scale(0.5,0.5)",
            //   //   MozTransformOrigin: "top left",
            //   //   OTransformOrigin: "top left",
            //   //   WebkitTransformOrigin: "top left",
            //   //   transformOrigin: "top left",
            // }}
          />
        </div>
      </div>
    );
  }
}
export default RightPane;