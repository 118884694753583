import React, { Component } from 'react'
import './card.less'
import MCSDashBoardBar from "./MCSDashBoardBar";
import {
  Button,
  
} from "@mineral/core";
class MCSDashBoardCard extends Component {
  constructor(props) {
    super(props)
   
  }

  render() {
      let readings = [
          { name: "Deployed", value: this.props.counts && this.props.counts.deployed?this.props.counts.deployed:0 ,color :"#038537",colorUnSelect:"#CDE7D7"},
          { name: "Failed", value: this.props.counts && this.props.counts.failed?this.props.counts.failed:0,color :"#DE1B1B",colorUnSelect: "#F8D1D1"},
          { name: "Pending", value: this.props.counts && this.props.counts.pending?this.props.counts.pending:0,color :"#8E99AB",colorUnSelect: "#E8EBEE"},
          { name: "Not Applicable", value: this.props.counts && this.props.counts.na?this.props.counts.na:0,color :"#CCCCCC",colorUnSelect:"#FFFFFF" }
      ];
      const type = this.props.type;
    return (
        
    		<Button component="div" role="group" tabIndex={0} aria-label={`${this.props.counts && this.props.counts.total?this.props.counts.total:0} ${type} with state ${readings[0].name}: ${readings[0].value} ${readings[1].name}:${readings[1].value} ${readings[2].name}: ${readings[2].value} ${readings[3].name}: ${readings[3].value}`} tabIndex={0} disableRipple={true} className='card__chart__mcs' style={this.props.style} onClick={() => this.props.onCardclick({type})}>
    		<MCSDashBoardBar readings={readings} noOfEntities = {this.props.counts && this.props.counts.total?this.props.counts.total:0} type = {type} onBarClick = {this.props.onBarClick} onClearClick={this.props.onCardclick} disableKeys = {this.props.disableKeys} />
        </Button>
    )
  }
}

export default MCSDashBoardCard;
