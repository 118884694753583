import {
  createStore,
  applyMiddleware,
} from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import metrics from './reducers'

const middlewares = [thunk]
const middlewareEnhancer = applyMiddleware(...middlewares)
const storeEnhancers = [middlewareEnhancer]
const composedEnhancer = composeWithDevTools({ name: 'metrics' })(...storeEnhancers)

export default function configureStore() {
  return createStore(
      metrics,
      composedEnhancer,
  )
}
