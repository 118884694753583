// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Capacity predective Analytics
  content["Capacity Predictive Analytics"] =
  "容量予測分析";

  //Communication Error
  content["Communication Error"] =
  "通信エラー";

  // END OF LOCALIZATION

export default content;
