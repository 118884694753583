// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //instruction message
  content["To use robot installation form above, WMI or SSH access with installation permissions are required.For other situations (e.g. AWS & Azure or no installation permission) use the manual installation process through the"] =
  "Para utilizar el formulario de instalación del robot anterior, se requiere acceso a WMI o SSH con permisos de instalación. Para otras situaciones (p. ej. Azure o AWS, o sin permisos de instalación) utilice el proceso de instalación manual a través de";

  //SetupWizard
  content["Setup Wizard"] =
  "Asistente de instalación";

  // END OF LOCALIZATION

export default content;
