import React from 'react'
import ErrorIcon from './../icons/Error'
import './error.less'

const Error = (props) => {
  return (
    <article className='error' title={props.title}>
      <ErrorIcon className='error__icon icon--error'/>
      <section className='error__section'>
        <header className='error__header'>{props.title}</header>
        <p className='error__message'>{props.message}</p>
      </section>
    </article>
  )
}

// Error.propTypes ={
//   title: PropTypes.string,
//   message: PropTypes.string,
// }
Error.defaultProps = {
  title: 'Data Access Error',
  message: 'The data required for this page is not currently available.',
}

export default Error
