import React, {Component} from 'react'
import compact from 'lodash/compact'
import get from 'lodash/get'
import {Button} from '@mineral/core'
import Link from './../common/reactRouter/Link'
import RobotHubSelect from './RobotHubSelect'
import RobotDeployList from './RobotDeployListRedux'
import DeployPending from './deployedMessage/DeployPending'
import DeploySuccess from './deployedMessage/DeploySuccess'
import DeployFailed from './deployedMessage/DeployFailed'
import DeploymentStatusRedux from './../setupWizard/DeploymentStatusRedux'
import './robotDeploy.less'
import Resource from './Resource'

const DeploySuccessMessage = () => {
  const SetupWizard = (
    <Link
    className='link'
    to='getting-started'>
    {// Setup Wizard
      Resource.get('Setup Wizard')}
      </Link>
      )
      const Text = (
        <span>
        Click {SetupWizard} to continue monitoring setup.
        </span>
        )
        return (
          <DeploySuccess message={Text}/>
          )
        }
        const DeployPendingMessage = () => {
          const SetupWizard = (
            <Link
            className='link'
            to='getting-started'>{// Setup Wizard
              Resource.get('Setup Wizard')}
              </Link>
              )
              const Text = (
                <span>
                When your installation is complete, click {SetupWizard} to continue.
                </span>
                )
                return (
                  <DeployPending message={Text}/>
                  )
                }
                
                class RobotDeploy extends Component {
                  // sticking this in the state will cause redundant renders resulting in perf issues
                  robots = []
                  state = {
                    hub: this.props.selectedHub || null,
                    fieldErrors: {},
                    submitted: this.props.submitted || false,
                    failedRobotsStatus: null,
                    succeededRobotsStatus: null,
                    adeRobotAddress: null,
                    adeJobIds: null,
                  }
                  _reset = (robots=[]) => {
                    this.robots = robots
                    this.setState({
                      fieldErrors: {},
                      submitted: false,
                      failedRobotsStatus: null,
                      succeededRobotsStatus: null,
                      adeRobotAddress: null,
                      adeJobIds: null,
                    })
                  }
                  _getFailedRobots = (failedRobotsStatus, robots=this.robots) => {
                    const failedRobotHostnames = failedRobotsStatus
                    .map(robotsStatus => robotsStatus.host)
                    const failedRobots = robots
                    .map(robot => {
                      const failedRobotStatus = failedRobotsStatus
                      .find(robotStatus => robotStatus.host === robot.hostname)
                      return Object.assign({}, robot, {statusError: get(failedRobotStatus, 'errorMessage')})
                    })
                    .filter(robot => failedRobotHostnames.includes(robot.hostname))
                    return failedRobots
                  }
                  _statusMessage = () => {
                    const succeeded = this.state.succeededRobotsStatus
                    const failed = this.state.failedRobotsStatus
                    let statusMessage = <DeployPendingMessage />
                    
                    if (succeeded && succeeded.length) {
                      statusMessage = <DeploySuccessMessage />
                    }
                    if (failed && failed.length) {
                      statusMessage = (
                        <DeployFailed onClick={() => {
                          const failedRobots = this._getFailedRobots(this.state.failedRobotsStatus)
                          this._reset(failedRobots)
                        }}/>
                        )
                      }
                      return statusMessage
                    }
                    componentWillMount() {
                      let urlParams = new URLSearchParams(window.location.search)
                      const adeRobotAddress = urlParams.get('adeRobotAddress')
                      // get adeJobIds as array and remove falsey (false, null, 0, "", undefined, and NaN)
                      const adeJobIds = compact(urlParams.get('adeJobIds').split(','))
                      
                      if (this.props.robotsStatus) {
                        let failedRobotsStatus=[],data = this.props.robotsStatus;
                        failedRobotsStatus = this.props.probesStatus.filter(info => info.status === false);
                        //TODO: ProbeStatusCheck - remap probes data to robots status data based on probes status
                        let i,len,probeFailRobotsIds=[],info;
                        if(this.props.probesStatus && failedRobotsStatus.length >0){
                          len = failedRobotsStatus.length;
                          for(i=0;i<len;i++){
                            this.props.probeJobIds.forEach(function(item){
                              if(item.fromError === true || item.jobId === failedRobotsStatus[i].jobId){
                                probeFailRobotsIds.push(item.robotInfo.adejobID);
                              }
                            });
                          }
                          if(probeFailRobotsIds.length>0){
                            data=[];
                            this.props.robotsStatus.forEach(function(item){
                              if(probeFailRobotsIds.indexOf(item.adejobID) !== -1){
                                info = Object.assign({},item,{state:'FAILED'});
                              }
                              else{
                                info = Object.assign({},item);
                              }
                              data.push(info);
                            });
                          }
                        }
                        failedRobotsStatus = data.filter(status => status.state === 'FAILED');
                        const robots = this.props.clientRobots
                        const failedRobots = this._getFailedRobots(failedRobotsStatus, robots)
                        this._reset(failedRobots)
                      }
                      
                      this.props.setBreadCrumbs([
                        {
                          //Setup Wizard
                          name: Resource.get('Setup Wizard'),
                          link: '/getting-started',
                        },
                        {
                          //Robot deployment  
                          name: Resource.get('Robot deployment'),
                          link: '/robot-deploy',
                        },
                      ])
                    }
                    validate = () => {
                      let invalid = false
                      const fieldErrors = this.state.fieldErrors
                      // return on first fieldError
                      const hasError = !!Object.keys(fieldErrors).find(key => fieldErrors[key])
                      
                      if (!this.state.hub) {
                        invalid = true
                      }
                      if (!this.robots.length) {
                        invalid = true
                      }
                      if (this.state.submitted) {
                        invalid = true
                      }
                      if (hasError) {
                        invalid = true
                      }
                      return invalid
                    }
                    render() {
                      const submitted = this.state.submitted
                      return (
                        <article>
                        {submitted ? (
                          <DeploymentStatusRedux
                          fixLink={false}
                          adeRobotAddress={this.state.adeRobotAddress}
                          adeJobIds={this.state.adeJobIds}
                          probe={this.props.probe}
                          onComplete={({succeeded=[], failed=[]}) => {
                            this.setState({
                              succeededRobotsStatus: succeeded,
                              failedRobotsStatus: failed,
                              adeRobotAddress: null,
                              adeJobIds: null,
                            })
                          }} />
                          ) : null}
                          {submitted ? null : (
                            <section className='hub__section'>
                            <RobotHubSelect onChange={hub => this.setState({hub})} hub={this.state.hub}/>
                            </section>
                            )}
                            <section className='robot__section'>
                            {submitted ? null : (
                              <RobotDeployList
                              hub={this.state.hub}
                              robots={this.robots}
                              probe={this.props.probe}
                              onChange={(robots, hasErrors) => {
                                const robotErrors = this.state.fieldErrors['robots']
                                this.robots = robots
                                if (robotErrors !== hasErrors) {
                                  this.setState({
                                    fieldErrors: Object.assign({}, this.state.fieldErrors, {
                                      robots: hasErrors
                                    })
                                  })
                                }
                              }} />
                              )}
                              {submitted ? this._statusMessage() : null}
                              </section>
                              <aside className='robot__actions'>
                              <Button
                              variant="contained"
                              //Deploy
                              children= {Resource.get('Deploy')}
                              color = "primary"
                              disabled={this.validate()}
                              onClick={() => {
                                this.props.resetRobotsStatus();
                                const hub = this.state.hub
                                const _robots = this.robots.map((robot) => {
                                  let _robot = {
                                    hostname: robot.hostname,
                                    robotIp: robot.hostname,
                                    profile: robot.profile,
                                    arch: robot.arch,
                                    username: robot.username,
                                    password: robot.password,
                                    hubIp: hub.hubIp,
                                    hubRobotAddress: hub.hubRobotAddress,
                                  }
                                  if (robot.sudoPassword) {
                                    _robot['sudoPassword'] = robot.sudoPassword
                                  }
                                  return _robot
                                })
                                this.props.saveRobots(_robots).then((response) => {
                                  const robots = response.data._items
                                  // adeRobotAddress is hubRobotAddress
                                  // we expect all robots to have the same hubRobotAddress so just grab the first one
                                  const adeRobotAddress = (robots.find((robot) => robot.hubRobotAddress) || {}).hubRobotAddress
                                  const adeJobIds = robots.filter((robot) => robot.adeJobId).map((robot) => robot.adeJobId)
                                  this.robots = robots
                                  this.setState({
                                    submitted: true,
                                    adeRobotAddress,
                                    adeJobIds,
                                  })
                                  this.props.setAdeRobotAddress(adeRobotAddress)
                                  this.props.setAdeJobIds(adeJobIds)
                                  this.props.setRobots(robots)
                                  if(hub  && hub !== 'add_hub'){
                                    this.props.setSelectedHub(hub);
                                  }
                                })
                              }}/>
                              </aside>
                              </article>
                              )
                            }
                          }
                          export default RobotDeploy
                          
                          