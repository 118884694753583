import {
    FILTERED_GROUPTREE_ROWS 
    } from './actionTypes'
    
    
    const filteredGroupTreeData = (state = '', action) => {
      switch (action.type) {
      case FILTERED_GROUPTREE_ROWS:
        return action.items
      default:
        return state
      }
    }
    
    export default filteredGroupTreeData