import {
  SET_ACL,
  FETCH_ACL,
  FETCH_ACL_SUCCESS,
  FETCH_ACL_FAILURE,
  SET_SELF_CERT_STATE,
} from "./actionTypes";
import acls from "./acls";
import * as IntegrationError from "./../../components/settings/integrations/IntegrationError";

function getResponseError(error) {
  if (error.response.data && error.response.data.message) {
    return error.response.data;
  } else {
    return IntegrationError.getDefaultError();
  }
}

export function setACL(acl) {
  return {
    type: SET_ACL,
    acl: acl,
  };
}

export function setSelfCertState(state) {
  return {
    type: SET_SELF_CERT_STATE,
    selfcert: state,
  };
}

export function fetchAcl() {
  return {
    type: FETCH_ACL,
  };
}
export function fetchAclSuccess(aclInfo) {
  return {
    type: FETCH_ACL_SUCCESS,
    aclInfo: aclInfo,
  };
}
export function fetchAclFailure(error) {
  return {
    type: FETCH_ACL_FAILURE,
    errorMessage: error.message,
  };
}

export function getAclData() {
  return (dispatch) => {
    dispatch(fetchAcl());
    return acls
      .getUser()
      .then((response) => dispatch(fetchAclSuccess(response.data)))
      .catch(function (error) {
        dispatch(fetchAclFailure(getResponseError(error)));
      });
  };
}
