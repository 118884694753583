import React, { Component } from "react";
import Resource from "./Resource";
import "./maintenance.less";
import { RemoveIcon } from "../ui-components/uim-components";
import maintenanceApi from "../../api/maintenance/maintenance";
import {
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,  
  Snackbar,
  IconButton
} from "@mineral/core";
import { isEqual } from "lodash";

class MaintenanceInfoDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disableButton: false,
      isError: false,
      showSpinner: false,
      isSuccess: false,
      schedules: [],
      openSnackBar: false,
      failMsg: "",
    };
  }
  componentDidMount() {
    this.fetchMaintenanceInformation(this.props);
  }

  componentWillReceiveProps(nextProps) {
    !isEqual(nextProps, this.props) &&
      this.fetchMaintenanceInformation(nextProps);
  }
  fetchMaintenanceInformation = (maintenanceProps) => {
    maintenanceApi
      .getScheduleForDevices(maintenanceProps.entity.id)
      .then((res) => {
        this.setState({ schedules: res.data });
        // this.setState({ schedules: res.data._items });
      })
      .catch((err) =>
        this.setState({ openSnackBar: true, failMsg: "Error in fetching list" })
      );
  };

  handleCloseSnackBar = () => {
    this.setState({ openSnackBar: false, failMsg: "" });
  };

  deleteSchedule = (sdlId) => {
    maintenanceApi
      .removeDeviceForSchedule(sdlId, this.props.entity.id)
      .then((res) => {
        this.setState({ schedules: res.data });
        // this.setState({ schedules: res.data._items });
      })
      .catch((err) =>
        this.setState({
          openSnackBar: true,
          failMsg: "Error in deleting schedules",
        })
      );
  };
  render() {
    let maintenanceSchedule = Resource.get("Schedule"); //Maintenance Schedule
    let maintenanceStatus = Resource.get("Status"); //Maintenance Status
    let maintennceAction = Resource.get("Disassociate"); //Maintenance Disassociate
    let content = (
      <div>
        <Snackbar
          open={this.state.openSnackBar}
          message={this.state.failMsg}
          autoHideDuration={4000}
          onClose={this.handleCloseSnackBar}
        />
        <Table selectable={false}>
          <TableHead
            displaySelectAll={false}
            adjustForCheckbox={false}
            enableSelectAll={false}
          >
            <TableRow>
              <TableCell className="column-header">
                {maintenanceSchedule}
              </TableCell>
              <TableCell className="column-header">
                {maintenanceStatus}
              </TableCell>
              <TableCell className="column-header">
                {maintennceAction}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody displayRowCheckbox={false}>
            {this.state.schedules.map((sdl) => (
              <TableRow key={sdl.scheduleId}>
                <TableCell className="row-column">
                  {sdl.scheduleName}
                </TableCell>
                <TableCell className="row-column">
                  {sdl.status}
                </TableCell>
                <TableCell>
                  <IconButton
                    classes={{root:"maintenance__icon"}}
                    onClick={() => this.deleteSchedule(sdl.scheduleId)}
                    disabled = {!this.props.acl.canEditMaintModeDevice}
                    style ={this.props.acl.canEditMaintModeDevice ?{}:{ opacity: "0.5", cursor:"not-allowed"}}
                  >
                    <RemoveIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    );
    return <div className="inventory-maintenance-dialog">{content}</div>;
  }
}

export default MaintenanceInfoDialog;
