import React, { useEffect, useState } from "react";
import { Tooltip } from "@mineral/core";
import {
  Menu,
  MenuItem,
  TextField,
  InputAdornment,
  Popover, DialogContent, Dialog,
  DialogActions,
  DialogTitle, Typography,
  Divider,
  DialogContentText,
} from "@mineral/core";
import { Button } from "@mineral/core";
import { ThreeBars,Info } from "@mineral/icons";
import CloseIcon from "@material-ui/icons/Close";
import "./QosChartToolbar.less";
import {
  Undo as UndoIcon,
  Redo as RedoIcon,
  MoreVert,
  Add,
  Edit as EditIcon,
  Info as InfoIcon,
  ArrowDropDown,
  SdCard,
} from "@material-ui/icons";
import {
  FormControl,
  InputLabel,
  Select,
  IconButton,
  TextField as TextField1,
} from "@mineral/core";
import Resource from "./Resource";
//DateTimePicker - mui
import DateTextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";

// import AdapterDateFns from "@mui/lab/AdapterDateFns";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";
// import DateTimePicker from "@mui/lab/DateTimePicker";


import { MineralDateTimePicker } from '../../../../../../common/datepickers/MineralDateTimePicker'

import HelpingText from '../../../../../../common/HelpingText'
import DatePickerIcon from '../../../../../../icons/DatePickerIcon'
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { js2xml } from "xml-js";
import X2JS from "xmljson-converter";
import prd from "../../../../../../../api/performanceReports/prd";
import {
  setAggregationLevel,
  setReportTitle,
  setTimeFrameAction,
  clearQosSelectionData,
  updateSavedReport,
  setStatusMessage,
} from "../../../../../../../api/performanceReports/actions";
import QosImportChartDialog from "./QosImportChartDialog";
import QosExportPdfDialog from "./QosExportPdfDialog";
import { display } from "@mui/system";
//Snack bar
import Snackbar from "@mui/material/Snackbar";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import AddToPhotosIcon from "@mui/icons-material/AddToPhotos";
import SaveIcon from "@mui/icons-material/Save";
import ClearIcon from "@mui/icons-material/Clear";

export const timeFrameList = [
  "Last Hour",
  "Last Day",
  "Last Week",
  "Last Month",
  "Custom",
];
export const aggregationOptions = [
  "Automatic",
  "None",
  "1 minute",
  "5 minutes",
  "15 minutes",
  "30 minutes",
  "1 hour",
  "2 hours",
  "4 hours",
  "8 hours",
  "12 hours",
  "1 Day",
];
const QosChartToolbar = (props) => {
  const timeFrameRef = React.useRef();
  const qosChartTitleRef = React.useRef();
  const [openClearConfirmation, setOpenClearConfirmation] = React.useState(false)
  const [showClearSuccess, setShowClearSuccess] = React.useState(false)
  const [openedSnackbar, setOpenedSnackbar] = useState(false);
  const [currentTitle,setCurrentTitle]= useState("");

  const dispatch = useDispatch();
  const chartData = useSelector((state) => state.performanceReports.chartData);
  const aggLevel = useSelector(
    (state) => state.performanceReports.chartData.State.aggLevel
  );
  const timeFrame = useSelector((state) =>
    state.performanceReports.chartData.State.timeFrame
      ? state.performanceReports.chartData.State.timeFrame
      : "Custom"
  );
  const chartTitle = useSelector(
    (state) => state.performanceReports.chartData.State.title
  );
  const isSaved = useSelector((state) => state.performanceReports.saved);
  const startDate = useSelector((state) =>
    moment(+state.performanceReports.chartData.State.TimeStart).toDate()
  );
  const endDate = useSelector((state) =>
    moment(+state.performanceReports.chartData.State.TimeStop).toDate()
  );

  const snackMessage = useSelector((state) => state.performanceReports.message);
  const prs = useSelector((state) => state.performanceReports);

  // const [startDate, setStartDate] = React.useState(
  // 	new Date(moment().subtract(1, "hours"))
  // );
  // const [endDate, setEndDate] = React.useState(new Date());
  const [showCustomDate, setShowCustomDate] = React.useState(false);
  const [saveTriggered, setSaveTriggered] = React.useState(false);
  const [isImportDialogOpen, setIsImportDialogOpen] = React.useState(false);
  const [typeDialog, setTypeDialog] = React.useState("import");
  const [isExportDialogOpen, setIsExportDialogOpen] = React.useState(false);
  const [editReportTitle, setEditReportTitle] = React.useState(false);
  //Snack bar
  const [openSnackBar, setOpenSnackBar] = useState(false);

  const [startDateError, setStartDateError] = useState(null);

  const [endDateError, setEndDateError] = useState(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [qosStartDate, setQosStartDate] = useState(new Date().setHours(new Date().getHours() - 1));
  const [qosEndDate, setQosEndDate] = useState(new Date());
  const [open, setOpen] = React.useState(false);
  const [isReportTitleChanged, setIsReportTitleChanged] = useState(false);
  const [reportOldName, setReportOldName] = useState("");
  const [isSaveDone,setIsSaveDone]= useState(true);

  useEffect(() => {    
    setReportOldName(chartTitle);
    setCurrentTitle(chartTitle);    
  }, [chartTitle])

  const handleSelectClose = () => {
    setOpen(false);
  };

  const handleSelectOpen = () => {
    setOpen(true);
  };

  const truncateString = (string, limit) => {
    if (string.length > limit) {
      return string.substring(0, limit) + "...";
    } else {
      return string;
    }
  };

  const onSaveReport = () => {
    dispatch(updateSavedReport(true));
    setSaveTriggered(true);
    setOpenSnackBar(true);
    dispatch(setStatusMessage(prs.message));
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackBar(false);
  };
  //Close Snack Bar

  useEffect(() => {
    if (saveTriggered) {
      (async () => {
        var options = {
          fullTagEmptyElement: true,
          compact: true,
          ignoreComment: true,
          spaces: 0,
          ignoreCdata: true,
          ignoreDoctype: true,
          ignoreDeclaration: true,
        };
        var x2js = new X2JS({ skipEmptyTextNodesForObj: false });
        var result = x2js.json2xml_str(chartData);
        //var result = js2xml(chartData, options);
        var encodedString = Buffer.from(result).toString("base64");
        const pathData = chartData.State.path.includes("/")?chartData.State.path.substring(0, chartData.State.path.lastIndexOf('/')):undefined;
        var currentPath = pathData?(pathData+"/"+currentTitle):currentTitle;
        var oldReportPath = chartData.State.path;
        if (chartData.State.path !== currentPath) {
          const resolvedData = await prd.listPrdReports();
          const dupReport = resolvedData.data.filter((item) => item.path === currentPath);                
          if (dupReport?.length > 0) {            
            setIsReportTitleChanged(false);
            dispatch(setReportTitle(reportOldName));
            setOpenedSnackbar(true);
          }
          else {            
            setIsReportTitleChanged(true);                       
            chartData.State.helpPath=currentPath;
            chartData.State.path=currentPath;
            chartData.State.title=currentTitle;
          }
        }  
        //console.log("chartData",chartData);
        result = x2js.json2xml_str(chartData);        
        encodedString = Buffer.from(result).toString("base64");
        let requestBody = {
          report: currentPath,
          base64EncodedReportStructure: encodedString,
          visibility: chartData.State.visibility.type,
          visibilityIdentifier:
            chartData.State.visibility.type == "account"
              ? chartData.State.visibility.owner
              : 0,
          reportOldName: oldReportPath,
          isNameModified: isReportTitleChanged,
        };
        console.log("requestBody", requestBody);
        if (process.env.NODE_ENV != "development") {
          const resolvedData = await prd.savePrdReport(requestBody);
          dispatch(setReportTitle(currentTitle));
          dispatch(setStatusMessage(resolvedData.data));
        }
        setSaveTriggered(false);
      })();
    }
  }, [saveTriggered]);

  // useEffect(()=>{
  //     console.log(startDate)
  //     console.log(startTime)
  //     dispatch(setTimeFrameAction(timeFrameList[4],{startDate,endDate}))
  // },[startDate,endDate])

  async function createAndOpenFile() {
    var options = {
      fullTagEmptyElement: true,
      compact: true,
      ignoreComment: true,
      spaces: 0,
      ignoreCdata: true,
      ignoreDoctype: true,
      ignoreDeclaration: true,
    };
    // var result = js2xml(chartData, options);
    var x2js = new X2JS({ skipEmptyTextNodesForObj: false });
    var result = x2js.json2xml_str(chartData);

    function download(filename, text) {
      var element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:text/plain;charset=utf-8," + encodeURIComponent(text)
      );
      element.setAttribute("download", filename);
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    }

    download(`${chartData.State.title}.xml`, result);
  }

  //Snck Bar Action
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      ></IconButton>
    </React.Fragment>
  );
  // Close snackBar action

  return (
    <div className="chart-controls-toolbar">
      <div className="chart-toolbar-title-bar">
        <div className="toolbar-left-content">
          <Tooltip arrow title="Edit report title">
            <IconButton style={{ marginRight: editReportTitle ? '8px' : '0px' }}
              // className="chart-title-edit-icon"
              aria-label="Edit report title"
              //style={{ padding: 0 }}
              onClick={() => {
                setEditReportTitle(true);
                setTimeout(() => {
                  qosChartTitleRef.current.firstChild?.firstChild?.focus();
                });
              }}
            >
              <EditIcon role="img" title="Edit" />
            </IconButton>
          </Tooltip>
          <Tooltip arrow title={chartTitle}>
            {editReportTitle ? (
              <TextField
                ref={qosChartTitleRef}
                onChange={(event) => {
                  setIsReportTitleChanged(true);
                  !event.target.value.includes("/") && setCurrentTitle(event.target.value)
                }}
                onBlur={(event) => {
                  setIsReportTitleChanged(true);
                  setEditReportTitle(false);
                  !event.target.value.includes("/") && setCurrentTitle(event.target.value);
                }}
                size="small"
                value={
                  editReportTitle ? currentTitle : truncateString(currentTitle, 60)
                }
                error={chartTitle.includes("/") ? "/ is not allowed" : null}
                //classes={{ root: "chart-title" }}
                inputProps={{ "aria-label": "PRD title" }}
              />
            ) : (
              <h2
                style={{ margin: 0, marginLeft: "10px", fontSize: "16px", wordBreak: "break-word" }}
                onClick={() => {
                  setEditReportTitle(true);                  
                  setTimeout(() => {
                    qosChartTitleRef.current.firstChild?.firstChild?.focus();
                  });
                }}
              >
                {editReportTitle ? currentTitle : truncateString(currentTitle, 40)}
              </h2>
            )}
          </Tooltip>
          <Snackbar
            open={openedSnackbar}
            autoHideDuration={5000}
            onClose={() => setOpenedSnackbar(false)}
          >
            <Alert severity="error">
              A report with the same name already exists.
            </Alert>
          </Snackbar>
        </div>
        <div className="toolbar-right-content">
          <div className="toolbar-left-action-content">

            <Tooltip arrow title="Add Chart">
              <Button
                variant="contained"
                //OK
                children={"Add Chart"}
                style={{ width: "125px", marginRight: "8px", marginTop: "20px"}}
                color="primary"
                classes={{ containedPrimary: "add-qos-chart-button" }}
                onClick={() => {
                  props.addChart();
                }}
                startIcon={<Add />}
              />
            </Tooltip>

            <FormControl className="timeFrame-dropdown">
              <InputLabel
                id="tame-frame-select-label" htmlFor="time-range-select"

              >
                Time Frame
              </InputLabel>
              <Select role="combobox" size="small"
                labelId="tame-frame-select-label"
                ref={timeFrameRef}
                id="time-range-select"
                open={open}
                onClose={handleSelectClose}
                onOpen={handleSelectOpen}

                aria-label="Time Frame"
                aria-labelledby="tame-frame-select-label"

                inputProps={{
                  'aria-label': " Time Frame",
                  role: 'combobox',
                }}
                MenuProps={{
                  MenuListProps: {
                    'aria-label': `Time Frame options list`,
                    'aria-labelledby': "tame-frame-select-label",

                    title: "Time Frame"
                  }
                }}
                value={timeFrame ? timeFrame : "Custom"}
                onChange={(event) => {
                  if (event.target.value == timeFrameList[4]) {
                    // dispatch(
                    //   setTimeFrameAction(event.target.value, {
                    //     startDate: moment(startDate).valueOf(),
                    //     endDate: moment(endDate).valueOf(),
                    //   })
                    // );
                    setShowCustomDate(true);
                  } else {
                    handleSelectClose();
                    dispatch(setTimeFrameAction(event.target.value, ""));
                    setShowCustomDate(false);
                    // setEndDate(new Date());
                    // if (event.target.value == timeFrameList[0]) {
                    // 	setStartDate(new Date(moment().subtract(1, "hours")));
                    // } else if (event.target.value == timeFrameList[1]) {
                    // 	setStartDate(new Date(moment().subtract(24, "hours")));
                    // } else if (event.target.value == timeFrameList[2]) {
                    // 	setStartDate(new Date(moment().subtract(168, "hours")));
                    // } else if (event.target.value == timeFrameList[3]) {
                    // 	setStartDate(new Date(moment().subtract(720, "hours")));
                    // }
                  }
                  //dispatch(setAggregationLevel(aggLevel));
                  dispatch(setAggregationLevel("Automatic"));
                }}
                disabled={props.isloading}
              >
                {timeFrameList.map((source, index) => (
                  (source == "Last Month") ? (
                    <MenuItem
                      classes={{ root: "timeFrame-menu-item" }}
                      key={`${source}-${index}-menuitem`}
                      value={source}
                      divider={true}
                    >
                      {source}
                    </MenuItem>)
                    :
                    ((source == "Custom") ?
                      (<MenuItem
                        classes={{ root: "timeFrame-menu-item" }}
                        key={`${source}-${index}-menuitem`}
                        value={source}
                        style={{ pointerEvents: "none" }}
                      >
                        {source}
                      </MenuItem>) : (<MenuItem
                        classes={{ root: "timeFrame-menu-item" }}
                        key={`${source}-${index}-menuitem`}
                        value={source}
                      >
                        {source}
                      </MenuItem>)
                    )
                ))}

                <div style={{ display: "grid", margin: "1px 10px 10px 10px" }}>
                  {(
                    <>
                      <div className="qos-performance-date-picker">
                        <div
                          style={{
                            display: "flex",
                            flexFlow: "column nowrap",
                            marginRight: "8px",
                          }}
                        >
                          <MineralDateTimePicker
                            value={qosStartDate}
                            onValueChange={(event) => {
                              if (moment(event).isAfter(moment(qosEndDate)))
                                setStartDateError('Start date cannot be after end date')
                              else {
                                setStartDateError(null)
                                setQosStartDate(moment(event).toDate());

                              }
                            }}


                            variant="inline"
                            //maxDateTime={endDate}
                            keyboardIcon={<DatePickerIcon />}
                            label="Start Date"
                            ampm={false}
                          />
                          <HelpingText
                            hint={null}
                            errorMessage={startDateError}
                            disabled={!startDateError}
                          />


                        </div>

                        <div
                          style={{
                            display: "flex",
                            flexFlow: "column nowrap",
                            marginRight: "8px",
                          }}
                        >
                          <MineralDateTimePicker
                            value={qosEndDate}
                            onValueChange={(event) => {
                              if (moment(event).isBefore(moment(qosStartDate).add(1, "minute")))
                                setEndDateError('End date cannot be before start date')
                              if (moment(event).isAfter())
                                setEndDateError('End date cannot be in future')
                              else {
                                setEndDateError(null)
                                setQosEndDate(moment(event).toDate());
                              }
                            }}


                            variant="inline"
                            // minDateTime={new Date(moment(startDate).add(1, "minute"))}
                            // maxDateTime={new Date()}
                            keyboardIcon={<Tooltip title="Change date"><DatePickerIcon /></Tooltip>}
                            label="End Date"
                            ampm={false}
                          />
                          <HelpingText
                            hint={null}
                            errorMessage={endDateError}
                            disabled={!endDateError}
                          />

                        </div>

                      </div>
                    </>
                  )}
                </div>
                <Divider />
                <div>
                  <Button
                    variant="text"
                    children="Apply"
                    style={{ float: "right" }}
                    disabled={
                      startDateError || endDateError || qosStartDate == null || qosEndDate == null || !moment(qosStartDate).isBefore(endDate)
                    }
                    onClick={(event) => {
                      handleSelectClose();
                      dispatch(
                        setTimeFrameAction("Custom", {
                          startDate: moment(qosStartDate).valueOf(),
                          endDate: moment(qosEndDate).valueOf(),
                        })
                      );
                      dispatch(setAggregationLevel(aggLevel));
                    }}
                  />
                </div>
              </Select>

            </FormControl>

            <FormControl className="timeFrame-dropdown">
              <InputLabel id="agg-level-select-label" >
                Aggregation Interval
              </InputLabel>
              <Select role="combobox" size="small"
                labelId="agg-level-select-label"
                id="agg-level-select"
                aria-label="Aggregation Interval"
                aria-labelledby="agg-level-select-label"

                inputProps={{
                  'aria-label': " Aggregation Interval",
                  role: 'combobox',
                }}
                MenuProps={{
                  MenuListProps: {
                    'aria-label': `Aggregation Interval options list`,
                    'aria-labelledby': "agg-level-select-label",

                    title: "Aggregation Interval"
                  }
                }}

                value={aggLevel ? aggLevel : "Automatic"}
                onChange={(event) => {
                  dispatch(setAggregationLevel(event.target.value));
                  dispatch(
                    setTimeFrameAction(timeFrame, {
                      startDate: moment(startDate).valueOf(),
                      endDate: moment(endDate).valueOf(),
                    })
                  );
                }}
              >
                {aggregationOptions.map((source, index) => (
                  <MenuItem
                    key={`${source}-${index}-agglevel`}
                    value={source}
                    disabled={(timeFrame==="Last Month" && (index===2 || index===3)) && true}
                  >
                    {source}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="toolbar-right-action-content">
            {/* 
                <Tooltip title="Undo"><IconButton classes={{root:"toolbar-icon"}}>  <UndoIcon className="toolbar-icons-color"/></IconButton></Tooltip>
                <Tooltip title="Redo"><IconButton classes={{root:"toolbar-icon"}}> <RedoIcon className="toolbar-icons-color"/></IconButton></Tooltip>
                <IconButton classes={{root:"toolbar-icon"}} onClick={onSaveReport}> <SaveIcon className="toolbar-icons-color" /></IconButton>
            */}
            <div
              style={{

              }}
            ><Tooltip title="More actions">

                <IconButton
                  aria-controls="menuAction"
                  aria-haspopup="true"
                  onClick={(event) => {
                    setMenuAnchorEl(event.currentTarget)
                  }}

                  aria-label="More Actions"
                >
                  <ThreeBars style={{ color: "black" }} />
                </IconButton>
              </Tooltip>
              <Menu
                id="menuAction"
                anchorEl={menuAnchorEl}
                keepMounted
                onClose={() => setMenuAnchorEl(null)}
                open={menuAnchorEl != null}
                PaperProps={{
                  style: {
                    width: "100px",
                    padding: "16px",
                    display: "table-cell",
                  },
                }}
              >
                <MenuItem
                  id={"import"}
                  children={
                    //Import
                    Resource.get("Open/Import")
                  }
                  disabled={false}
                  onClick={() => {
                    setMenuAnchorEl(null)
                    setTypeDialog("import");
                    setIsImportDialogOpen(true);
                    setIsSaveDone(true);
                  }}
                />
                <MenuItem
                  id={"export"}
                  children={
                    //export
                    Resource.get("Export")
                  }
                  disabled={false}
                  onClick={() => {
                    setMenuAnchorEl(null)
                    setIsExportDialogOpen(true)
                  }} />
                <MenuItem id={"save"}
                  children={
                    //save
                    Resource.get("Save")
                  }
                  disabled={false}
                  onClick={(event) => {
                    setMenuAnchorEl(null)
                    if (isSaved) {
                      onSaveReport();
                    } else {
                      setTypeDialog("save");
                      setIsImportDialogOpen(true);
                    }
                  }} />
                <MenuItem id={"saveAs"}
                  children={
                    //save as
                    Resource.get("Save As")
                  }
                  disabled={!isSaved}
                  onClick={(event) => {
                    setMenuAnchorEl(null)
                    setTypeDialog("save");
                    setIsImportDialogOpen(true);
                  }} />
                <MenuItem id={"clear"}
                  children={
                    //clear
                    Resource.get("New")
                  }
                  disabled={false}
                  onClick={(event) => {
                    setMenuAnchorEl(null)
                    setOpenClearConfirmation(true)
                  }} />
              </Menu>
            </div>

            <Snackbar
              open={openSnackBar}
              autoHideDuration={5000}
              message={snackMessage}
              onClose={() => setOpenSnackBar(false)}
            />

            {/*<Tooltip arrow title="Add Chart">
              <Button
                variant="contained"
                //OK
                children={"Add Chart"}
                style={{ width: "125px", marginLeft: "8px" }}
                color="primary"
                classes={{ containedPrimary: "add-qos-chart-button" }}
                onClick={() => {
                  props.addChart();
                }}
                startIcon={<Add />}
              />
              </Tooltip>*/}
            {/* <Tooltip arrow title="Export">
            <Button
              variant="outlined"
              children={"Export"}
              color="primary"
              classes={{ outlinedPrimary: "import-qos-chart-button" }}
              onClick={() => setIsExportDialogOpen(true)}
            />
          </Tooltip>
          <Tooltip arrow title="Import">
            <Button
              variant="outlined"
              //OK
              children={"Import"}
              color="primary"
              classes={{ outlinedPrimary: "import-qos-chart-button" }}
              onClick={() => {
                setTypeDialog("import");
                setIsImportDialogOpen(true);
              }}
            />
          </Tooltip> */}
          </div>

          <QosImportChartDialog
            open={isImportDialogOpen}
            type={typeDialog}
            isSaved={isSaved}
            chartData={chartData}
            handleImportDialogClose={() => {
              setIsImportDialogOpen(false);
            }}
          />
          <QosExportPdfDialog
            open={isExportDialogOpen}
            xml={createAndOpenFile}
            handleExportDialogClose={() => setIsExportDialogOpen(false)}
          />
          <Dialog
            open={openClearConfirmation}
            onClose={() => setOpenClearConfirmation(false)}
            aria-labelledby="alert-chart-clear-dialog-title"
            aria-describedby="alert-chart-clear-dialog-description"
          >
            <DialogTitle component="h1">

              <Typography

                variant="h4"
                component="div"
              >
                {"Clear report"}
              </Typography>


            </DialogTitle>
            <div style={{ height: '0px' }}>
              <Tooltip title="Close">
                <IconButton style={{ top: '-50px', float: 'right', marginRight: '8px' }}
                  aria-label="Close"
                  autoFocus
                  onClick={() => setOpenClearConfirmation(false)}
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </div>
            <DialogContent>
              <DialogContentText id="alert-chart-clear-dialog-description">
                <pre>
                  {`Do you want to clear report : ${chartTitle}?`}
                </pre>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                variant="text"
                onClick={() => setOpenClearConfirmation(false)}
              >
                Cancel
              </Button>
              <Button variant="contained"
                onClick={() => {
                  setOpenClearConfirmation(false);
                  dispatch(clearQosSelectionData());
                  setShowClearSuccess(true);

                }}
              >
                Agree
              </Button>
            </DialogActions>
          </Dialog>
          <Snackbar
            open={showClearSuccess}
            autoHideDuration={5000}
            message={"Successfully cleared report details"}
            onClose={() => setShowClearSuccess(false)}
          />
        </div>
      </div>
    </div>
  );
};

export default QosChartToolbar;
