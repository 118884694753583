export const getOverflowingElementsNumber = (selector = '', offsetPx = 30) => {
    const allActiveFilters = [...document.querySelectorAll(selector)]
    let numberOfOverflowingElements = 0
    if (allActiveFilters && allActiveFilters?.length > 0) {
      allActiveFilters.map((element) => {
        if (element.offsetTop > offsetPx) numberOfOverflowingElements += 1
      })
    }
  
    return numberOfOverflowingElements
  }
  