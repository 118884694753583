// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Import
  content["Open/Import"] =
  "Open/Import";

  //export
  content["Export"] =
  "Export";

  //save
  content["Save"] =
  "Save";

  //save as
  content["Save As"] =
  "Save As";

  //clear
  content["New"] =
  "New";

  // END OF LOCALIZATION

export default content;
