import React, { Component } from "react";
import {CircularProgress} from '@mineral/core'
import Resource from "./Resource";
import ErrorDialog from "./../titlebar/ErrorDialog";
import {Cross as CloseIcon } from "@mineral/icons"
import { Checkbox, Dialog,DialogActions,DialogTitle, FormControlLabel, IconButton,Button as FlatButton,Snackbar,DialogContent,Typography ,Tooltip} from "@mineral/core";
import "./DeleteDevicesInventoryDialog.less"
const DELETE_DEVICES_FROM_INVENTORY_OPEN = "deleteDevicesFromInventoryOpen";
const DELETE_DEVICE_FROM_INVENTORY = "deleteDeviceFromInventory";
const INIT_DELETE_DEVICE_OPTIONS = {
  preventReDiscovery: true,
  acknowledgeAlarms: true,
  deleteMeasurements: true,
};

class DeleteDevicesFromInventoryDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disableButton: false,
      isError: false,
      showSpinner: false,
      isSuccess: false,
      deleteDeviceOptions: Object.assign({}, INIT_DELETE_DEVICE_OPTIONS),
      openSnackBar: false,
    };
  }
  componentWillReceiveProps() {
    this.setState({
      disableButton: false,
      isError: false,
      showSpinner: false,
      isSuccess: false,
      deleteDeviceOptions: Object.assign({}, INIT_DELETE_DEVICE_OPTIONS),
      openSnackBar: false,
    });
  }
  handleCloseSnackBar = () => {
    this.setState({ openSnackBar: false });
  };

  render() {
   
    let actions = [
      //Cancel
      <FlatButton
        variant="text"  color="primary"
        children={Resource.get("Cancel")}
        onClick={this.onClose} style={{textTransform:'none'}}
        disableRipple={true}
      />,
      //Ok
      <FlatButton
        variant="contained" color="error"
        children={Resource.get("Delete")}
        disableRipple={true} style={{textTransform:'none'}}
        onClick={this.handleDeleteDevicesFromInventory}
        disabled={this.state.disableButton}
      />,
    ];
    let title = "";
    if (this.props.selectedCsIds.length > 1) {
      title = `Delete ${this.props.selectedCsIds.length} devices`;
    } else {
      //Delete Device
      title = Resource.get("Delete Device");
    }
    let content = (
      <div >
       
        <p className='dialogContent'>
        
          {this.props.selectedCsIds.length > 1 //Delete devices text
            ? Resource.get(
                "Are you sure you want to queue devices for deletion? Please make sure that monitoring for all these devices stopped before this operation."
              )
            : //Delete device text
              Resource.get(
                "Are you sure you want to queue device for deletion? Please make sure that monitoring for this device stopped before this operation."
              )}
        </p>
        <div style={{ fontSize: '14px'}}>
        <div >
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={
                  this.state.deleteDeviceOptions.preventReDiscovery
                    ? true
                    : false
                }
                onChange={(event) => {
                  let stateCopy = Object.assign({}, this.state);
                  stateCopy.disableButton = false;
                  stateCopy.deleteDeviceOptions.preventReDiscovery =
                    event.target.checked;
                  this.setState(stateCopy);
                }}                
              />
            }
            //Prevent rediscovery
            label={Resource.get("Prevent rediscovery")}            
          />
         
        </div>
        <div >
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={
                  this.state.deleteDeviceOptions.acknowledgeAlarms
                    ? true
                    : false
                }
                onChange={(event) => {
                  let stateCopy = Object.assign({}, this.state);
                  stateCopy.disableButton = false;
                  stateCopy.deleteDeviceOptions.acknowledgeAlarms =
                    event.target.checked;
                  this.setState(stateCopy);
                }}
                
              />
            }
            //Acknowledge alarms
            label={Resource.get("Acknowledge alarms")}
            className="delete-devices-container"
          />
          
        </div>
        <div >
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={
                  this.state.deleteDeviceOptions.deleteMeasurements
                    ? true
                    : false
                }
                onChange={(event) => {
                  let stateCopy = Object.assign({}, this.state);
                  stateCopy.disableButton = false;
                  stateCopy.deleteDeviceOptions.deleteMeasurements =
                    event.target.checked;
                  this.setState(stateCopy);
                }}
              />
            }
            //Delete measurements
            label={Resource.get("Delete measurements")}            
          />     
        </div>
        </div>
        <p className='dialogContent'>
       
          {
            // help message1
            Resource.get("Note: This process may take a few minutes")
          }
        </p>
      </div>
    );
    return (
      <div>
        <Snackbar
          open={this.state.openSnackBar}
          message={
            //delete in progress
            Resource.get(
              "Deletion is in progress. Click on Refresh button to see the changes."
            )
          }
          autoHideDuration={4000}
          onClose={this.handleCloseSnackBar}
        />
        <ErrorDialog
          open={this.state.isError}
          handleClose={this.props.handleClose.bind(this)}
          errorType={"deleteDeviceFromInventory"}
        />

        <Dialog          
          modal={false}
          open={this.props.open}
          onClose={this.onClose}
        >
          <DialogTitle  component="div" disableTypography>
         <Typography component="h1" variant="h4">{title}</Typography>
          
         
          </DialogTitle>
          <div style={{height:'0px'}}>
          <Tooltip title="Close">
          <IconButton
              aria-label={"Close"}
              onClick={this.onClose}
              autoFocus  style={{
           
          marginTop:'-50px',
          float: 'right',
          marginRight:'8px'
       
          }}
            >
              <CloseIcon />
            </IconButton>
            </Tooltip>
            </div>
        <DialogContent>
          {content}
          {this.state.showSpinner ? 
            <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: "hidden" }}><CircularProgress style={{color: '#3272D9'}} /></div>
            : null}
            </DialogContent>
          <DialogActions className="buttons-wrapper">
              {actions}
          </DialogActions>
        </Dialog>
      </div>
    );
  }
  onClose = () => {
    this.errorMsg = null;
    this.props.handleClose(DELETE_DEVICES_FROM_INVENTORY_OPEN);
  };
  handleDeleteDevicesFromInventory = () => {
    this.setState({
      disableButton: true,
      showSpinner: true,
    });
    this.props
      .deleteDevicesFromInventory(
        this.props.selectedCsIds,
        this.state.deleteDeviceOptions
      )
      .then((response) => {
        if (response.type === "DELETE_DEVICES_FROM_INVENTORY_SUCCESS") {
          this.props.setSelectedCsIds && this.props.setSelectedCsIds([]);
          this.props.handleClose(DELETE_DEVICES_FROM_INVENTORY_OPEN);
          this.setState({ openSnackBar: true });
        } else {
          this.props.setSelectedCsIds && this.props.setSelectedCsIds([]);
          this.props.handleClose(DELETE_DEVICES_FROM_INVENTORY_OPEN);
          this.setState({
            showSpinner: false,
            isError: true,
            isSuccess: false,
          });
          /*
          this.props.handleErrorDialogOpen(DELETE_DEVICE_FROM_INVENTORY)*/
        }
        this.props.handleDisabled(true);
      })
      .catch((error) => {
        this.props.setSelectedCsIds && this.props.setSelectedCsIds([]);
        this.props.handleClose(DELETE_DEVICES_FROM_INVENTORY_OPEN);
        this.setState({
          showSpinner: false,
          isError: true,
          isSuccess: false,
        });
        this.props.handleDisabled(false);

        /* this.props.handleClose(DELETE_DEVICES_FROM_INVENTORY_OPEN)
        this.props.handleErrorDialogOpen(DELETE_DEVICE_FROM_INVENTORY)*/
      });
  };
}
DeleteDevicesFromInventoryDialog.defaultProps = {
  selectedCsIds: [],
};
export default DeleteDevicesFromInventoryDialog;
