import {
  SET_TIME_RANGE_PROP,
  SHOW_TIME_RANGE_SELECT
} from './actionTypes'

export const setTimeRange = (timeRange,start_date,end_date) => {
  return {
    type: SET_TIME_RANGE_PROP,
    timeRange: timeRange,
    start_date: start_date,
    end_date: end_date
  };
}

export const setShowTimeRange = (showTimeRange) => {
  return {
    type: SHOW_TIME_RANGE_SELECT,
    showTimeRange: showTimeRange
  };
}