import {
 SET_SID
} from './actionTypes'

const sid = (state = null, action) => {
  switch (action.type) {
    case SET_SID:
      return  action.sid
    default:
      return state
  }
}
export default sid
