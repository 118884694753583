// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //dashError
  content["Dashboard failed to load."] =
  "Falha ao carregar o painel.";

  //missing component
  content["CA Business Intelligence (CABI) system component is needed."] =
  "O componente de sistema CABI (CA Business Intelligence) é necessário.";

  //Breadcrumb
  content["Dashboards"] =
  "Painéis";

  //Breadcrumb
  content["Overview"] =
  "Visão geral";

  // END OF LOCALIZATION

export default content;
