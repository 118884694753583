// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //instruction message
  content["To use robot installation form above, WMI or SSH access with installation permissions are required.For other situations (e.g. AWS & Azure or no installation permission) use the manual installation process through the"] =
  "To use robot installation form above, WMI or SSH access with installation permissions are required.For other situations (e.g. AWS & Azure or no installation permission) use the manual installation process through the";

  //SetupWizard
  content["Setup Wizard"] =
  "Setup Wizard";

  // END OF LOCALIZATION

export default content;
