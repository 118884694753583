// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //ID
  content["ID"] =
  "ID";

  //Name
  content["Name"] =
  "Name";

  //Alias
  content["Alias"] =
  "Alias";

  //IP Address
  content["IP Address"] =
  "IP Address";

  //Hub
  content["Hub"] =
  "Hub";

  //Dedicated
  content["Dedicated"] =
  "Dedicated";

  //OS Type
  content["OS Type"] =
  "OS Type";

  //OS Name
  content["OS Name"] =
  "OS Name";

  //OS Version
  content["OS Version"] =
  "OS Version";

  //OS Description
  content["OS Description"] =
  "OS Description";

  //MAC Address
  content["MAC Address"] =
  "MAC Address";

  //Origin
  content["Origin"] =
  "Origin";

  //latestorigin
  content["Latest Origin"] =
  "Latest Origin";

  //Role
  content["Role"] =
  "Role";

  //Model
  content["Model"] =
  "Model";

  //Bustype
  content["Bus Type"] =
  "Bus Type";

  //User Tag 1
  content["User Tag 1"] =
  "User Tag 1";

  //User Tag 2
  content["User Tag 2"] =
  "User Tag 2";

  //Monitoring Hosts
  content["Monitoring Hosts"] =
  "Monitoring Hosts";

  //Maintenance
  content["Maintenance"] =
  "Maintenance";

  //Export
  content["Export"] =
  "Export";

  //Vendor
  content["Vendor"] =
  "Vendor";

  //Edit Alias
  content["Edit Alias"] =
  "Edit Alias";

  //Edit Icon
  content["Edit Icon"] =
  "Edit Icon";

  //Schedule
  content["Schedule"] =
  "Schedule";

  //Status
  content["Status"] =
  "Status";

  //Delete
  content["Delete"] =
  "Delete";

  //Open Alarms
  content["Open Alarms"] =
  "Open Alarms";

  //Apply
  content["Apply"] =
  "Apply";

  //Cancel
  content["Cancel"] =
  "Cancel";

  //Device Details
  content["Device Details"] =
  "Device Details";

  //Close
  content["Close"] =
  "Close";

  //Assign Group
  content["Assign Group"] =
  "Assign Group";

  //Group Name
  content["Group Name"] =
  "Group Name";

  //Type
  content["Type"] =
  "Type";

  //Actions
  content["Actions"] =
  "Actions";

  //Close Icon
  content["Close Icon"] =
  "Close Icon";

  //Assign Group Type Dialog
  content["Assign Group Type Dialog"] =
  "Assign Group Type Dialog";

  //No Groups found
  content["No Groups found"] =
  "No Groups found";

  //Search
  content["Search"] =
  "Search";

  //Search Icon
  content["Search Icon"] =
  "Search Icon";

  //Group Name Search Icon
  content["Group Name Search Icon"] =
  "Group Name Search Icon";

  //Delete Disabled Icon
  content["Delete Disabled Icon"] =
  "Delete Disabled Icon";

  //Device Group Delete Button
  content["Device Group Delete Button"] =
  "Device Group Delete Button";

  //tooltip={"Type"}
  content["You do not have permissions to remove this group"] =
  "You do not have permissions to remove this group";

  //Device Groups
  content["Device Groups"] =
  "Device Groups";

  //Device Count
  content["Device Count"] =
  "Device Count";

  //No Roles found
  content["No Roles found"] =
  "No Roles found";

  //Devices By Roles
  content["Devices By Roles"] =
  "Devices By Roles";

  //View All
  content["View All"] =
  "View All";

  //Technologies
  content["Technologies"] =
  "Technologies";

  //No Technologies Found
  content["No Technologies Found"] =
  "No Technologies Found";

  // Metric
  content["Metric"] =
  "Metric";

  // Device
  content["Device"] =
  "Device";

  // Component
  content["Component"] =
  "Component";

  // Resource Consumption
  content["Resource Consumption"] =
  "Resource Consumption";

  //Sub Groups
  content["Sub Groups"] =
  "Sub Groups";

  //No Sub Groups Found
  content["No Sub Groups Found"] =
  "No Sub Groups Found";

  //Alarm Count
  content["Alarm Count"] =
  "Alarm Count";

  //No Alarms Found
  content["No Alarms Found"] =
  "No Alarms Found";

  //Devices
  content["Devices"] =
  "Devices";

  //No Devices Found
  content["No Devices Found"] =
  "No Devices Found";

  //Top 10 Devices
  content["Top 10 Devices"] =
  "Top 10 Devices";

  //By Severity, By Alarms
  content["By Severity, By Alarms"] =
  "By Severity, By Alarms";

  // END OF LOCALIZATION

export default content;
