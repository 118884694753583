// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Add
  content["Add"] =
  "Add";

  //Service Name
  content["Service Name"] =
  "Service Name";

  //Settings
  content["Settings"] =
  "Settings";

  //PagerDuty
  content["PagerDuty"] =
  "PagerDuty";

  //CANCEL
  content["CANCEL"] =
  "CANCEL";

  //DELETE
  content["DELETE"] =
  "DELETE";

  //This action removes the PagerDuty integration
  content["This action removes the PagerDuty integration"] =
  "This action removes the PagerDuty integration";

  //help message
  content["Alarms will no longer be synchronized between CA Infrastructure Management and PagerDuty."] =
  "Alarms will no longer be synchronized between CA Infrastructure Management and PagerDuty.";

  //Delete Integration?
  content["Delete integration?"] =
  "Delete integration?";

  //Communication Error
  content["Communication Error"] =
  "Communication Error";

  //API Key message
  content["PagerDuty generated API key"] =
  "PagerDuty generated API key";

  //Service Name message
  content["Service name"] =
  "Service name";

  //help message
  content[" Create new PagerDuty incidents when Infrastructure Management generates alarms with the following severities:"] =
  " Create new PagerDuty incidents when Infrastructure Management generates alarms with the following severities:";

  //Trigger incident creation
  content["Trigger incident creation"] =
  "Trigger incident creation";

  //Immediatley
  content["Immediatley"] =
  "Immediatley";

  //After 5 minutes
  content["After 5 minutes"] =
  "After 5 minutes";

  //After 10 minutes
  content["After 10 minutes"] =
  "After 10 minutes";

  //After 15 minutes
  content["After 15 minutes"] =
  "After 15 minutes";

  //After 30 minutes
  content["After 30 minutes"] =
  "After 30 minutes";

  //help message
  content["Adding a delay may resolve flapping a situation where alarms open and auto-close in a short time frame."] =
  "Adding a delay may resolve flapping a situation where alarms open and auto-close in a short time frame.";

  // Pager Duty Message
  content["To integrate PagerDuty with Infrastructure Management, you must first open an account and generate an API key for use in the configuration window. The following link describes how to generate an API key."] =
  "To integrate PagerDuty with Infrastructure Management, you must first open an account and generate an API key for use in the configuration window. The following link describes how to generate an API key.";

  //Help Message
  content["Help"] =
  "Help";

  //Note message
  content["Note: To enable this functionality, the UIM server must have access to the internet"] =
  "Note: To enable this functionality, the UIM server must have access to the internet";

  //PagerDuty Integration
  content["PagerDuty Integration"] =
  "PagerDuty Integration";

  //Critical
  content["Critical"] =
  "Critical";

  //Major
  content["Major"] =
  "Major";

  //Minor
  content["Minor"] =
  "Minor";

  //Warning
  content["Warning"] =
  "Warning";

  //Info
  content["Info"] =
  "Info";

  //instruction message
  content["When an alarm is closed in Infrastructure Management, resolve the associated incident in PagerDuty."] =
  "When an alarm is closed in Infrastructure Management, resolve the associated incident in PagerDuty.";

  //SAVE
  content["SAVE"] =
  "SAVE";

  // END OF LOCALIZATION

export default content;
