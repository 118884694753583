import {
  FETCH_INTERFACE_LIST,
  FETCH_INTERFACE_LIST_SUCCESS,
  FETCH_INTERFACE_LIST_FAILURE,
  FETCH_METRIC_LIST,
  FETCH_METRIC_LIST_SUCCESS,
  FETCH_METRIC_LIST_FAILURE,
  FETCH_INTERFACE_LIST_ADD_SUCCESS,
  FETCH_INTERFACE_LIST_ADD_FAILURE,
  CHANGE_INTERFACES_COUNT,
} from "./actionTypes";
import interfaceAPI from "./interfaces";
import * as IntegrationError from "./../../components/settings/integrations/IntegrationError";

function getResponseError(error) {
  if (error.response && error.response.data && error.response.data.message) {
    return error.response.data;
  } else {
    return IntegrationError.getDefaultError();
  }
}

export function fetchInterfacesList() {
  return {
    type: FETCH_INTERFACE_LIST,
  };
}

export function fetchInterfacesSuccess(json) {
  return {
    type: FETCH_INTERFACE_LIST_SUCCESS,
    data: json,
  };
}

export function fetchInterfacesFailure(error) {
  return {
    type: FETCH_INTERFACE_LIST_FAILURE,
    errorMessage: error.message,
  };
}
export function fetchInterfacesAddSuccess(json) {
  return {
    type: FETCH_INTERFACE_LIST_ADD_SUCCESS,
    data: json,
  };
}

export function fetchInterfacesAddFailure(error) {
  return {
    type: FETCH_INTERFACE_LIST_ADD_FAILURE,
    errorMessage: error.message,
  };
}
export function fetchMetricData() {
  return {
    type: FETCH_METRIC_LIST,
  };
}

export function fetchMetricSuccess(json) {
  return {
    type: FETCH_METRIC_LIST_SUCCESS,
    data: json,
  };
}

export function fetchMetricFailure(error) {
  return {
    type: FETCH_METRIC_LIST_FAILURE,
    errorMessage: error.message,
  };
}

export function changeInterfacesCount(count) {
  return {
    type: CHANGE_INTERFACES_COUNT,
    interfacesCount: count
  }
}

export function fetchInterfaces(params) {
  return (dispatch) => {
    dispatch(fetchInterfacesList());
    return interfaceAPI
      .getRegularFields(params)
      .then((response) => response.data)
      .then((json) => dispatch(fetchInterfacesSuccess(json)))
      .catch(function (error) {
        dispatch(fetchInterfacesFailure(getResponseError(error)));
      });
  };
}

export function fetchInterfacesToAdd(params) {
  return (dispatch) => {
    dispatch(fetchInterfacesList());
    return interfaceAPI
      .getMasterElements(params)
      .then((response) => response.data)
      .then((json) => dispatch(fetchInterfacesAddSuccess(json)))
      .catch(function (error) {
        dispatch(fetchInterfacesAddFailure(getResponseError(error)));
      });
  };
}

export function fetchMetrics(params) {
  return (dispatch) => {
    dispatch(fetchMetricData());
    return interfaceAPI
      .getMetricFields(params)
      .then((response) => response.data)
      .then((json) => dispatch(fetchMetricSuccess(json)))
      .catch(function (error) {
        dispatch(fetchMetricFailure()(getResponseError(error)));
      });
  };
}
