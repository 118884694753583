import React from "react";
import IntegrationCard from "../integrations/IntegrationCard";
import ArrowIcon from "../ArrowIcon";
import Resource from "./Resource";
import "./../settings.less";
import {Tooltip} from '@mineral/core';

export default class ProductUsageMetricsCard extends IntegrationCard {
  getIcon() {
    return <ArrowIcon />;
  }

  getSubLabelTwo = () => {
    return this.props.subLabelTwo;
  };
}

const TextLogo = ({ label }) => {
  return (
    <Tooltip title={label}>
      <span className="settings-text-logo admin__console--card">{label}</span>
    </Tooltip>
  );
};

ProductUsageMetricsCard.defaultProps = {
  logo: (
    <TextLogo
      //Product Usage
      label={Resource.get("Product Usage")}
    />
  ),
  //View usage metrics for this product instance.
  subLabelOne: Resource.get("View usage metrics for this product instance."),
  configRoute: "/settings/usagemetrics",
};