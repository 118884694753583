import React from 'react'
import { SvgIcon } from '../ui-components/uim-components'

const Icon = props => {
  return (
    <SvgIcon {...props}>
      <svg
        role='figure' 
        alt={props.title}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24">
        <g fill="none" fillRule="evenodd">
        <title>{props.title}</title>
       <rect id="Box" x="0" y="0" width="26" height="26"></rect>
                        <path d="M19.5,19.5 L19.5,7.68181818 C19.5,5.83250106 17.9236692,4.33333333 15.9791667,4.33333333 L8.66666667,4.33333333 L8.66666667,1.08333333 L19.2291667,1.08333333 C21.1736692,1.08333333 22.75,2.58250106 22.75,4.43181818 L22.75,19.5 L19.5,19.5 Z" id="60" fill="#CCCCCC" opacity="0.6"></path>
                        <path d="M17.3333333,9.84848485 L17.3333333,24.9166667 L3.25,24.9166667 L3.25,6.5 L13.8125,6.5 C15.7570026,6.5 17.3333333,7.99916773 17.3333333,9.84848485 Z M6.5,9.75 L6.5,11.9166667 L11.9166667,11.9166667 L11.9166667,9.75 L6.5,9.75 Z M6.5,13 L6.5,15.1666667 L14.0833333,15.1666667 L14.0833333,13 L6.5,13 Z" id="100" fill="#CCCCCC"></path>
						</g>
      </svg>
    </SvgIcon>
  )
}

export default Icon
