import React, { Component } from "react";
import {
  Dialog,
  TextField,
  Typography,
  Divider,
  Button as FlatButton,
  DialogActions,
  Snackbar,Grid,DialogTitle,DialogContent,Tooltip,IconButton
} from "@mineral/core";
import {
  FormControlLabel,
  Radio as RadioButton,
  RadioGroup as RadioButtonGroup,
  Checkbox,InputLabel,FormControl
} from "@mineral/core";
import "./copyDialog.less";
import { isEqual } from "lodash";
import MetricViewAdvancedFilter from "./MetricViewAdvancedFilter";
import Resource from "./Resource";

import { withStyles } from "@material-ui/core/styles";
import {

  ClearIcon as Clear,
} from "../ui-components/uim-components";
import MetricViewMatchingDeviceList from "./MetricViewMatchingDeviceList";
import metricAPI from "./api/deviceMetrics/metric";
import treeViewAPI from "./../../api/treeView/treeView";

const sortingFieldMap = [
  { value: "name", label: "name" },
  { value: "alias", label: "alias" },
  { value: "role", label: "role" },
  { value: "os_name", label: "osName" },
  { value: "os_type", label: "osType" },
  { value: "origin", label: "origin" },
  { value: "user_tag1", label: "userTag1" },
  { value: "user_tag2", label: "userTag2" },
  { value: "group_name", label: "grpName" },
  { value: "parent_group", label: "parentGrpName" },
  { value: "group_type", label: "grpType" },
];

//ErrorFindingDevicesGroups
const errorFindingGroups = Resource.get(
  "Error in finding devices/groups. Please check the filter criteria."
);

class MetricViewCopyDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: props.open,
      copyMetricName: "",
      anchorEl: null,
      advanceFilterOpen: true,
      targetType: props.isGroupView ? "group" : "device",
      filteredData: [],
      selectedNode: [],
      selectedGroup: [],
      selectedCSIds: "",
      advanceFilter: [],
      advanceFilterCondition: "",
      advanceCustomFilter: "",
      index: -1,
      totalDeviceCount: 0,
      currentGroupId: -1,
      currentGroupName: "",
      groupDeviceMapping: [],
      selectedGroupDeviceMapping: [],
      isBackClicked: false,
      isNextClicked: false,
      isFilterApplied: false,
      errorFinding: false,
      isExcessDevice: false,
      errorMessage: errorFindingGroups,
      deviceConditionsFromGrid: {
        pageNum: 0,
        pageSize: 500,
        sortColumn: "",
        sortDirection: "",
      },
    };
  }
  advanceFilterEnable = (event) => {
    event.preventDefault();
    this.setState({ advanceFilterOpen: true, anchorEl: event.currentTarget });
  };
  componentWillReceiveProps(nextProps) {
    if (!isEqual(nextProps.open, this.props.open)) {
      this.setState({ open: nextProps.open });
    }
  }

  handleCopyMetricName = (event) => {
    this.setState({ copyMetricName: event.target.value });
  };
  handleDialogClose = (isSave) => {
    this.props.onClose(isSave);
    this.setState({
      copyMetricName: "",
      anchorEl: null,
      advanceFilterOpen: true,
      targetType: this.props.isGroupView ? "group" : "device",
      filteredData: [],
      selectedNode: [],
      selectedGroup: [],
      selectedCSIds: "",
      advanceFilter: [],
      advanceFilterCondition: "",
      advanceCustomFilter: "",
      index: -1,
      totalDeviceCount: 0,
      currentGroupId: -1,
      currentGroupName: "",
      groupDeviceMapping: [],
      selectedGroupDeviceMapping: [],
      isBackClicked: false,
      isNextClicked: false,
      isFilterApplied: false,
      isDefault: false,
      isExcessDevice: false,
      deviceConditionsFromGrid: {
        pageNum: 0,
        pageSize: 500,
        sortColumn: "",
        sortDirection: "",
      },
    });
  };
  handleTargetTypeClicked = (event) => {
    this.setState({
      targetType: event.target.value,
      filteredData: [],
      selectedNode: [],
      selectedGroup: [],
      selectedCSIds: "",
      advanceFilter: [],
      advanceFilterCondition: "",
      advanceCustomFilter: "",
      index: -1,
      totalDeviceCount: 0,
      currentGroupId: -1,
      currentGroupName: "",
      groupDeviceMapping: [],
      selectedGroupDeviceMapping: [],
      isBackClicked: false,
      isNextClicked: false,
      isFilterApplied: false,
      isDefault: false,
      isExcessDevice: false,
      errorFinding: false,
      errorMessage: errorFindingGroups,
      deviceConditionsFromGrid: {
        pageNum: 0,
        pageSize: 500,
        sortColumn: "",
        sortDirection: "",
      },
    });
  };
  handleConditionFromGrid = (gridconditions) => {
    //this.setState({deviceConditionsFromGrid: gridconditions})
    let sortObj = sortingFieldMap.filter((sort) => {
      return sort.label === gridconditions.sortColumn;
    });
    let requestBody = {
      filterList: [...this.state.advanceFilter],
      advanceFilterCondition: this.state.advanceFilterCondition,
      advanceCustomFilter: this.state.advanceCustomFilter,
      sortColumn: sortObj[0].value, //gridconditions.sortColumn,
      sortDirection: gridconditions.sortDirection,
      pageNumber: gridconditions.pageNum,
      pageSize: gridconditions.pageSize,
    };
    metricAPI
      .getFilteredDevices(requestBody, this.state.targetType)
      .then((response) => {
        let filteredData =
          this.state.targetType == "device"
            ? response.data.computerSystemList
            : response.data.groupList;
        this.setState({
          filteredData: filteredData,
          totalDeviceCount: response.data.totalCount,
          deviceConditionsFromGrid: gridconditions,
          isFilterApplied: false,
          isExcessDevice: false,
        });
      });
  };
  handleApplyFilter = (
    advanceFilter,
    advanceFilterCondition,
    advanceCustomFilter
  ) => {
    let isValidFilter = true;
    advanceFilter.forEach((filter) => {
      if (filter.value == "") {
        isValidFilter = false;
      }
    });
    if (isValidFilter) {
      let requestBody = {
        filterList: [...advanceFilter],
        advanceFilterCondition: advanceFilterCondition,
        advanceCustomFilter: advanceCustomFilter
          .toLowerCase()
          .split("or")
          .join("or ")
          .split("and")
          .join("and ")
          .replace(/\s+/g, " ")
          .trim(),
        sortColumn: this.state.deviceConditionsFromGrid.sortColumn,
        sortDirection: this.state.deviceConditionsFromGrid.sortDirection,
        pageNumber: this.state.deviceConditionsFromGrid.pageNum,
        pageSize: this.state.deviceConditionsFromGrid.pageSize,
      };
      metricAPI
        .getFilteredDevices(requestBody, this.state.targetType)
        .then((response) => {
          let filteredData =
            this.state.targetType == "device"
              ? response.data.computerSystemList
              : response.data.groupList;
          this.setState({
            filteredData: filteredData,
            totalDeviceCount: response.data.totalCount,
            advanceFilter: [...advanceFilter],
            advanceFilterCondition: advanceFilterCondition,
            advanceCustomFilter: advanceCustomFilter,
            isFilterApplied: true,
            isExcessDevice: false,
          });
        })
        .catch((error) => {
          this.setState({
            filteredData: [],
            totalDeviceCount: 0,
            errorMessage: errorFindingGroups,
            errorFinding: true,
          });
        });
    } else {
      this.setState({
        filteredData: [],
        totalDeviceCount: 0,
        errorMessage: "Invalid Filter condition.",
        errorFinding: true,
        isExcessDevice: false,
      });
    }
  };

  /*  handleFindDevices = (requestBody) =>{
          
         metricAPI.getFilteredDevices(requestBody,this.state.targetType).then((response)=>{
            console.log("in metric response");
            let filteredData = this.state.targetType == "device" ? 
                    response.data.computerSystemList : response.data.groupList
            this.setState({
                filteredData: filteredData,
                 totalDeviceCount: response.data.totalCount
            });
        }) 
    } */
  updateSelectedDevices = (selectedNode) => {
    let seldNode = selectedNode;
    seldNode = seldNode.filter((node) => node.rowIndex != null);
    if (this.state.index == -1) {
      if (this.state.targetType == "device") {
        this.setState({
          selectedNode: [...selectedNode],
          isExcessDevice: false,
        });
      } else {
        let selGroupIds = [];
        seldNode.forEach((node) => {
          selGroupIds.push({ id: node.id, name: node.data.grpName });
        });
        this.setState({
          selectedGroup: [...selGroupIds],
          selectedGroupDeviceMapping: [],
          isExcessDevice: false,
        });
      }
    } else if (this.state.index > -1) {
      //let seldNode = selectedNode;
      //seldNode = seldNode.filter(node => node.rowIndex != null);
      let selCsIds = [];
      seldNode.forEach((node) => {
        selCsIds.push({ id: node.id });
      });
      const selGroupDeviceMapping = this.state.selectedGroupDeviceMapping;
      if (selCsIds.length > 0) {
        if (selCsIds.length > 100) {
          this.setState({ isExcessDevice: true });
        } else {
          this.setState({ isExcessDevice: false });
          if (
            selGroupDeviceMapping.length != 0 &&
            selGroupDeviceMapping[this.state.index]
          ) {
            let groupObj = selGroupDeviceMapping[this.state.index];
            groupObj.selDevices = selCsIds;
            // groupObj.groupId = this.state.currentGroupId;
            selGroupDeviceMapping[this.state.index] = groupObj;
          } else {
            selGroupDeviceMapping.push({
              groupId: this.state.currentGroupId,
              selDevices: selCsIds,
            });
          }
        }
      } else {
        this.setState({ isExcessDevice: false });
      }
      this.setState({ selectedGroupDeviceMapping: [...selGroupDeviceMapping] });
    }
  };
  saveNewMetricView = () => {
    if (this.state.targetType == "device") {
      let csIds = [];
      this.state.selectedNode.forEach((node) => {
        csIds.push(node.id);
      });
      let metricData = {
        sourceMetricViewId: this.props.selectedMetricId,
        metricViewNameToCopy: this.state.copyMetricName,
        targetCsIds: csIds.toString(),
        copiedViewDefault: this.state.isDefault,
      };
      this.props.saveNewMetricView(metricData, this.state.targetType);
    } else {
      let requestBody = [];
      this.state.selectedGroupDeviceMapping.forEach((group) => {
        let targetCsIds = [];
        let targetGroupId = group.groupId;
        let selectedCsIds = group.selDevices;
        selectedCsIds.forEach((node) => {
          targetCsIds.push(node.id);
        });
        let metricData = {
          sourceMetricViewId: this.props.selectedMetricId,
          metricViewNameToCopy: this.state.copyMetricName,
          targetCsIds: targetCsIds.toString(),
          targetGroupId: targetGroupId,
          copiedViewDefault: this.state.isDefault,
        };
        requestBody.push(metricData);
      });

      this.props.saveNewMetricView(requestBody, this.state.targetType);
    }

    this.handleDialogClose(true);
  };
  handleNextClick = () => {
    let groupDeviceMapping = [];
    let index = parseInt(this.state.index);
    if (this.state.selectedGroup.length > index) {
      index = index + 1;
    }
    let groupId = this.state.selectedGroup[index].id;
    let groupName = this.state.selectedGroup[index].name;
    treeViewAPI.getDeviceData(groupId).then((response) => {
      if (response.data && response.data._items) {
        let groupResponse = {
          groupId: groupId,
          deviceList: response.data._items,
        };
        groupDeviceMapping.push(groupResponse);
        this.setState({
          groupDeviceMapping: [...groupDeviceMapping],
          currentGroupId: groupId,
          currentGroupName: groupName,
          filteredData: response.data._items,
          totalDeviceCount: response.data.total,
          index: index,
          isBackClicked: false,
          isNextClicked: true,
          isFilterApplied: false,
        });
      }
    });
  };

  handleBackClick = () => {
    let groupDeviceMapping = [];
    let index = parseInt(this.state.index);
    if (index != -1) {
      index = index - 1;
    }
    if (index > -1) {
      let groupId = this.state.selectedGroup[index].id;
      let groupName = this.state.selectedGroup[index].name;
      treeViewAPI.getDeviceData(groupId).then((response) => {
        if (response.data && response.data._items) {
          let groupResponse = {
            groupId: groupId,
            deviceList: response.data._items,
          };
          groupDeviceMapping.push(groupResponse);
          this.setState({
            groupDeviceMapping: [...groupDeviceMapping],
            currentGroupId: groupId,
            currentGroupName: groupName,
            filteredData: response.data._items,
            totalDeviceCount: response.data.total,
            index: index,
            isBackClicked: true,
            isNextClicked: false,
            isFilterApplied: false,
            isExcessDevice: false,
          });
        }
      });
    } else if (index == -1) {
      this.setState({
        index: index,
        isBackClicked: true,
        isNextClicked: false,
        isExcessDevice: false,
      });
      this.handleApplyFilter(
        this.state.advanceFilter,
        this.state.advanceFilterCondition,
        this.state.advanceCustomFilter
      );
    }
  };
  handleDefaultCheckbox = (event) => {
    this.setState({ isDefault: event.target.checked });
  };
  closeSnackBar = () => {
    this.setState({
      errorFinding: false,
      errorMessage: errorFindingGroups,
    });
  };

  render() {
    const TITLE = "Copy Metric View"; //Copy Metric View
    // specify Metric view name
    const text =
      "Specify the metric view name and select groups to apply the copied metric view";

      let confirmMassage = 'Are you sure you want to close the dialog?'
      const confirmActions = [
      
        <FlatButton      
            
          children={"No"}
          variant="outlined"
          color="primary"
          onClick={()=>{this.setState({
            showCloseCopyMetricsConfirmation:false
          });}}
         
        />  
       ,
        
        <FlatButton      
         
          children={"Yes"}
          variant="contained"
          color="error"
          onClick={()=>{
            this.setState({
              showCloseCopyMetricsConfirmation:false
      });
      this.handleDialogClose(false)}}
         
        />  ];
    return (
      <>
      <Dialog
        className="copyDialog"
        open={this.state.open}
        onClose={() =>  {this.setState({
              showCloseCopyMetricsConfirmation:true
      });}}
    
        scroll={"paper"}
      >
        <DialogTitle disableTypography variant="div">
          <Typography component="h1" variant="h3">
            {this.state.index == -1
              ? TITLE
              : `${TITLE}-${this.state.copyMetricName}-${this.state.currentGroupName}`}
          </Typography>
        </DialogTitle>
        <div style={{height:'0px'}}>

       <Tooltip title={"Close"} style={{float:'right',marginRight:'8px',top:'-50px'}}> 
        <IconButton autoFocus  onClick={()=>{this.setState({
          showCloseCopyMetricsConfirmation:true
        });}}>
          <Clear role='figure' />
        </IconButton>
         </Tooltip> 
       </div>
      
        <DialogContent dividers={true}
          style={{ height: "665px" }}
        >
          <Typography component="span">{text}</Typography>

          <div>
            {this.state.index == -1 && (
              <div>
                <Grid container spacing={2} style={{marginBottom:'16px',marginTop:'16px'}}>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      {/* MetricViewname */}
                      <InputLabel id="metricViewName_label" htmlFor="metricViewName" >
                        {"Metric View name"}
                        <span className="required-label">Required</span>
                      </InputLabel>
                     
                    
                    <TextField fullWidth
                      //Metric View Name
                      placeholder={"Metric View Name"}
                      id="metricViewName" labelId="metricViewName_label"
                      inputProps={{ "aria-label": "Metric View name" ,"aria-required":true}}
                      onChange={this.handleCopyMetricName}
                      value={this.state.copyMetricName}
                     
                      variant="outlined"
                                          
                    />
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <InputLabel>{"Target Type"} </InputLabel>
                    <RadioButtonGroup
                      name="targetType"
                      defaultValue={this.state.targetType}
                      onChange={this.handleTargetTypeClicked}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: "5px",
                      }}
                    >
                      <FormControlLabel
                        value={"group"}
                        control={
                          <RadioButton
                            type="radio"
                            style={{ height: "16px", width: "16px" }}
                            value={"group"}
                            checked={this.state.targetType == "group"}
                            //disabled={this.state.disableSchedule}
                          />
                        }
                        label={"Group"}
                        className="radioBtn-label"
                        style={{ marginRight: "61px" }}
                      />
                      <FormControlLabel
                        value={"device"}
                        control={
                          <RadioButton
                            type="radio"
                            style={{ height: "16px", width: "16px" }}
                            value={"device"}
                            checked={this.state.targetType == "device"}
                            //disabled={this.state.disableSchedule}
                          />
                        }
                        label={"Device"}
                        className="radioBtn-label"
                      />
                    </RadioButtonGroup>
                  </Grid>
                <Grid item xs={4}>
                  <FormControl>
                    <FormControlLabel 
                      control={
                        <Checkbox
                          checked={this.state.isDefault}
                          style={{marginRight:'4px'}}
                          onChange={(event) =>
                            this.handleDefaultCheckbox(event)
                          }
                        />
                      }
                      label="Default View"
                     
                    />
                    </FormControl>
                  </Grid>
                </Grid>
                <MetricViewAdvancedFilter
                  applyFilter={this.handleApplyFilter}
                  targetType={this.state.targetType}
                  isGroupView={this.props.isGroupView}
                />
              </div>
            )}
            <MetricViewMatchingDeviceList
              targetType={this.state.targetType}
              rowData={this.state.filteredData}
              totalDeviceCount={this.state.totalDeviceCount}
              handleDeviceSelection={this.updateSelectedDevices}
              isGroupView={this.props.isGroupView}
              findDevices={this.handleConditionFromGrid}
              index={this.state.index}
              selectedGroupDeviceMapping={this.state.selectedGroupDeviceMapping}
              currentGroupId={this.state.currentGroupId}
              isBackClicked={this.state.isBackClicked}
              isNextClicked={this.state.isNextClicked}
              selectedGroupIds={this.state.selectedGroup}
              isFilterApplied={this.state.isFilterApplied}
              isExcessDevice={this.state.isExcessDevice}
            />
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              message={this.state.errorMessage}
              open={this.state.errorFinding}
              key={"top center"}
              autoHideDuration={4000}
              onClose={this.closeSnackBar}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <FlatButton
            children={"Cancel"}
            variant="text"
            color="primary"
            onClick={() =>  {this.setState({
              showCloseCopyMetricsConfirmation:true
      });}}
          />
          {this.state.targetType == "group" && this.state.index !== -1 && (
            <FlatButton
              children={"Back"}
              variant="outlined"
              color="primary"
              disabled={this.state.index == -1}
              onClick={() => this.handleBackClick(false)}
            />
          )}
          {((this.state.targetType == "group" && this.state.index !== -1) || (this.state.targetType !== "group")) && 
          <FlatButton
            children={"Copy Metric View"}
            variant="contained"
            color="primary"
            disabled={
              this.state.index == -1
                ? this.state.copyMetricName.length == 0 ||
                  this.state.selectedNode.length == 0
                : this.state.index < this.state.selectedGroup.length - 1 ||
                  this.state.selectedGroupDeviceMapping.length == 0 ||
                  this.state.isExcessDevice
            }
            style={{ float: "left" }}
            onClick={this.saveNewMetricView}
           
          />}
          {this.state.targetType == "group" && (this.state.index==-1 || this.state.index !== this.state.selectedGroup.length - 1) && (
            <FlatButton
              children={"Next"}
              variant="contained"
              color="primary"
              disabled={
                this.state.copyMetricName.length == 0 ||
                this.state.selectedGroup.length == 0 ||
                 
                this.state.isExcessDevice
              }
              onClick={this.handleNextClick}
            />
          )}
        </DialogActions>
      </Dialog>
      <Dialog
       open={this.state.showCloseCopyMetricsConfirmation}
       onClose={()=>{this.setState({
          showCloseCopyMetricsConfirmation:false
        });}}     
     >
       <DialogTitle component="div" disableTypography>
         
             
             <Typography component="h1" variant="h4">
               {"Confirm exit"}
             </Typography>
          
       </DialogTitle>
       <div style={{height:'0px'}}>

       <Tooltip title={"Close"} style={{float:'right',marginRight:'8px',top:'-50px'}}> 
        <IconButton autoFocus  onClick={()=>{this.setState({
          showCloseCopyMetricsConfirmation:false
        });}}>
          <Clear role='figure' />
        </IconButton>
         </Tooltip> 
       </div>
       <DialogContent>{confirmMassage}</DialogContent>
       <DialogActions>
         {confirmActions}
       </DialogActions>
     </Dialog>
     </>
    );
  }
}
export default MetricViewCopyDialog;
