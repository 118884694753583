import {
    SET_ISSAAS
} from './actionTypes'

export function setIsSaas(isSaas) {
    return {
        type: SET_ISSAAS,
        isSaas: isSaas
    }
}
