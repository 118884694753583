import React from "react";
import * as PropTypes from "prop-types";
import azsorted from '../../../assets/icons/a-z_sorted.svg';
import zasorted from '../../../assets/icons/z-a_sorted.svg';
import './dynamicGroupDialog.less'
import Resource from "../../setupWizard/deviceDiscovery/Resource";
/**
 * @Author namam01
 */

//Header Component to display custom sort icons
export default class CustomIconSortHeaderComponent extends React.Component {

    constructor(props) {
        super(props);
        //sortChanged gets called when sort gets changed, it will be changed when you set props.sort to diff value than previous
        this.props.column.addEventListener('sortChanged', this.onSortChanged);
        //Initialize the default sorts to be done on the column
         this.state = {
            sorted: this.props.defaultSort? this.props.defaultSort:null,            
            sortIcon:this.props.defaultSortIcon ? this.props.defaultSortIcon :	null,
        }
    }
    componentWillUnmount() {
        this.props.column.removeEventListener('sortChanged', this.onSortChanged);
    }
    render() {
        let sortElements = [];
        if (this.props.enableSorting && this.state.sortIcon != null) {
            let sortEle = <span style={{marginLeft:'0px'}} className='sortIcon active'><img style={{height:'14px', width:'16px'}}
                                                                 className="ap-cell ap-iconSrc" src={this.state.sortIcon}/></span>
          	sortElements.push(sortEle);

        }
        return  <div className="customHeaderLabel" onClick={this.onSortRequested.bind(this)}>
             				<span className="displayName">{this.props.displayName}</span>
             					{sortElements}
             		</div>
    }

    onSortRequested(order) {  
    	if(this.props.enableSorting){
	    	var sort=this.props.column.getSort();
	    	var nextSort=null;
	    	if(sort=== null || sort===undefined){
	    		nextSort='asc'; 		
	    	}else if(sort ==='asc'){
	    		nextSort='desc'; 
	    	}else if(sort === 'desc' ){
	    		nextSort='asc';
	    	}
	        this.props.setSort(nextSort, false);
    	}
    };

    onSortChanged = () => {
    	let sortFromGrid = this.props.column.getSort();
    	this.setState({
			sorted:sortFromGrid
		}); 
    
    	if(sortFromGrid ==null ){
    		this.setState({
    			sortIcon:null,
    		});
    	}else if(sortFromGrid==='asc' ){
    		this.setState({
    			sortIcon:azsorted,
    		}); 
    	}else if(sortFromGrid ==='desc' ){
    		this.setState({
    			sortIcon:zasorted,
    		}); 
    	}
    };
}
// the grid will always pass in one props called 'params',
// which is the grid passing you the params for the cellRenderer.
// this piece is optional. the grid will always pass the 'params'
// props, so little need for adding this validation meta-data.
CustomIconSortHeaderComponent.propTypes = {
    params: PropTypes.object
};