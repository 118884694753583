// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Add
  content["Add"] =
  "Add";

  //Setup complete
  content["Setup complete"] =
  "Setup complete";

  //slack
  content["slack"] =
  "slack";

  //Push alarms to Slack
  content["Push alarms to Slack"] =
  "Push alarms to Slack";

  // END OF LOCALIZATION

export default content;
