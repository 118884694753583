import React, { Component } from "react";
import get from "lodash/get";
import LocalStorageManager from "./../../utils/LocalStorageManager";
import {TextField,InputAdornment,CircularProgress,Tooltip} from "@mineral/core";
import {SearchIcon} from "../ui-components/uim-components"

import UtilPoller from "./../../utils/poller";
import DeploymentStatusRedux from "./DeploymentStatusRedux";
import SetupWizardCard from "./SetupWizardCard";
import SetupWizardDialog from "./SetupWizardDialog";
// import Filter from './../groups/secondaryNav/filter/Filter'
import "./setupWizard.less";
import theme from "../../../src/theme";
import Resource from "./Resource";
import SetupWizardContainer from "./SetupWizardContainer";
import orderBy from "lodash/orderBy";
import { Cross as CloseIcon } from '@mineral/icons';
import { IconButton } from "@mineral/core";
import { withStyles } from '@material-ui/styles';

import CustomSnackbar from '../common/CustomSnackbar'


let probeWhiteListflag =
  "aws,azure,apache,windows,unix,vmware,docker_monitor,office365,sap_basis,tomcat";
//Setup Infrastructure Monitoring
let selectMessage = Resource.get("Setup Infrastructure Monitoring");
//For more information, click
let infoMessage = Resource.get("For more information, click");

const styles = {
  'input': {
    '&::placeholder': {
      textOverflow: 'ellipsis !important',
      color: 'blue'
    }
  }
};


class SetupWizard extends Component {
  state = {
    open: false,
    deploymentInProgress: false,
    adeRobotAddress: null,
    adeJobIds: [],
    probeAddress: "",
    host: "",
    newlyDeployed: false,
    probe: {},
    searchTerm: "",
    value: "",
    showLocalProbesInfo: true,
    containerWidth: 0,
    requestSubmittedSuccessfuly:false,
  };
  categories = "NON_LOCAL";
  enableMonitoringChecked = false;
  cardWidth = 332;
  cardsInfo = {};
  SHOWALL_LIMIT = 500;
  sectionsOrder = [];
  debounceRef = null;
  componentWillMount() {
    let isValidUser = false;
    if (this.props.acl && this.props.acl.isBusUser) {
      if (
        this.props.acl.canManageDiscovery &&
        this.props.acl.canDeployRobot &&
        this.props.acl.canMonitorConfigService &&
        this.props.acl.canEditMCSTemplates &&
        this.props.acl.canMonitorConfigService
      ) {
        isValidUser = true;
      } else if (
        this.props.acl.canDeployRobot &&
        this.props.acl.canMonitorConfigService &&
        this.props.acl.canEditMCSTemplates &&
        this.props.acl.canMonitorConfigService
      ) {
        isValidUser = true;
      }
    }
    !isValidUser && this.props.history.replace("/overview");
    let useEnhancedTemplates = get(
      this.props.featureFlags,
      "useEnhancedTemplates",
      true
    );
    this.props.fetchProbes(true, useEnhancedTemplates, this.categories);
    this.props.getHubs();
    this.props.getRobots();
    this.props.setBreadCrumbs([
      {
        //Setup Wizard
        name: Resource.get("Setup Wizard"),
        link: `/getting-started`,
      },
      {
        //Remote And Cloud
        name: Resource.get("Remote And Cloud"),
        link: `/remote-cloud-tiles`,
      },
    ]);
    this.pollProbesCount(useEnhancedTemplates);
    this.pollHubs();
    this.pollRobots();
  }
  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
    this.updateDimensions();
  }
  updateDimensions = () => {
    var container = this.setupWizardContainer;
    if (container) {
      let rect = container.getBoundingClientRect();
      let width = rect.width - this.getStyleWidthForComponent(container);
      if (this.state.containerWidth !== width) {
        this.setState({
          containerWidth: width,
        });
      }
    }
  };
  getComputedStyleForComponent(component, style) {
    return parseInt(getComputedStyle(component)[style], 10);
  }
  getStyleWidthForComponent(component) {
    let width = 0;
    width += this.getComputedStyleForComponent(component, "margin-left") || 0;
    width += this.getComputedStyleForComponent(component, "margin-right") || 0;
    width += this.getComputedStyleForComponent(component, "padding-left") || 0;
    width += this.getComputedStyleForComponent(component, "padding-right") || 0;
    return width;
  }

  ////  Pollers     //////////
  pollHubs = () => {
    this.props.pollHubsStart();
  };

  pollRobots = () => {
    if (this.robotsPoller) {
      this.robotsPoller.stop();
    }
    this.robotsPoller = UtilPoller(() => this.props.pollRobots());
    this.robotsPoller.start();
  };
  pollProbesCount = (useEnhancedTemplates) => {
    this.props.pollProbesStart(useEnhancedTemplates, this.categories);
  };

  componentWillUnmount() {
    this.props.pollHubsStop();
    this.props.pollProbesStop();
    if (this.robotsPoller) {
      this.robotsPoller.stop();
    }
    if (this.probesCountPoller) {
      this.probesCountPoller.stop();
    }
    window.removeEventListener("resize", this.updateDimensions);
  }

  ////////// Deployment Status event Handlers
  setRobotsBeingDeployed = (robots) => {
    if (robots.length) {
      this.setState({ deploymentInProgress: true });
    }
    // adeRobotAddress is hubRobotAddress
    // we expect all robots to have the same hubRobotAddress so just grab the first one
    const adeRobotAddress = (
      robots.find((robot) => robot.hubRobotAddress) || {}
    ).hubRobotAddress;
    const adeJobIds = robots
      .filter((robot) => robot.adeJobId)
      .map((robot) => robot.adeJobId);
    this.setState({ adeRobotAddress, adeJobIds });
  };

  onComplete = (robotsStatus) => {
    if (this.state.probe.probeType === "remote" && robotsStatus[0]) {
      this.setState({
        probeAddress: encodeURIComponent(
          robotsStatus[0].robotAddress + "/" + this.state.probe.name
        ),
      });
      this.setState({ host: encodeURIComponent(robotsStatus[0].host) });
    }
    if (
      robotsStatus[0] &&
      robotsStatus[0].state &&
      robotsStatus[0].state === "SUCCEEDED"
    ) {
      //  this.newlyDeployed = true
      this.setState({ newlyDeployed: true });
    }
    this.setState({ deploymentInProgress: false });
  };

  onStatusChange = (robotsStatus) => {
    if (robotsStatus.succeeded) {
      this.setState({ newlyDeployed: true });
    }
  };

  deploymentStatus = () => {
    let callbackProbeList = this.props.featureFlags.callbackProbeList
      ? this.props.featureFlags.callbackProbeList
      : "";
    let callbackEnabled =
      callbackProbeList.includes(this.state.probe.name) ||
      (this.state.probe.name && this.state.probe.name.endsWith("(RESTMon)")
        ? true
        : false);
    if (this.props.robotsStatus.length === 0) {
      return;
    }
    return (
      <DeploymentStatusRedux
        adeRobotAddress={this.state.adeRobotAddress}
        adeJobIds={this.state.adeJobIds}
        probe={this.state.probe}
        callbackEnabled={callbackEnabled}
        fromSetupWizard={true}
        onComplete={(robotsStatus) => this.onComplete(robotsStatus)}
      />
    );
  };

  ////  Card event handlers
  //Add Button- showWalkThrough
  showWalkThrough = (probe) => {
    // console.log(`Add clicked ${probe} - `, probe.name);
    // this.setState({ deploymentInProgress: false, dockerProbeAddress: '', dockerEngineIp: '' })
    this.setState({
      open: true,
      probe: probe,
    });
  };

  //Config Button -  Edit config
  showEditConfig = (probe, profileId) => {
    const pathUrl =
      probe.name === "windows" || probe.name === "unix" ? "cdm" : probe.name;
    let link = probe.groupId
      ? `/configuration/${pathUrl}/${probe.probeType}/${probe.groupId}`
      : `/configuration/${probe.name}/${probe.probeType}`;
    if (profileId && Number(profileId) !== NaN) {
      if (probe.groupId) {
        link = `/configuration/${pathUrl}/${probe.probeType}/${probe.groupId}/onMaxEdit/${profileId}`;
      } else {
        link = `/configuration/${probe.name}/${probe.probeType}/onMaxEdit/${profileId}`;
      }
    }
    this.props.addBreadCrumb({
      name: probe.displayname,
      link: link,
    });
    // console.log(link);
    this.props.history.push(link);
  };
  ////////  Close dialog
  handleDialogClose = () => {
    // console.log('close clicked')
    this.setState({
      open: false,
      newlyDeployed: false,
      adeRobotAddress: null,
      adeJobIds: [],
      probeAddress: "",
      host: "",
      deploymentInProgress: false,
      probe: {},
    });
    let useEnhancedTemplates = get(
      this.props.featureFlags,
      "useEnhancedTemplates",
      true
    );
    this.props.fetchProbes(true, useEnhancedTemplates, this.categories);
    this.pollProbesCount(useEnhancedTemplates);
  };

  //////// Enable Monitoring
  enableMonitoringCheckbox = (event, checked) => {
    this.enableMonitoringChecked = checked;
  };

  onMaxEdit = (data) => {
    let probe = this.state.probe,
      id;
    this.handleDialogClose();
    if (probe) {
      if (data.profile) {
        id = data.profile.profileId;
      }
      this.showEditConfig(probe, id);
    }
  };

  enableMonitoring = (probe) => {
    let type = probe.name.toLowerCase() === "linux" ? "unix" : probe.name;
    if (probe.appDiscoveryEnabled || this.enableMonitoringChecked) {
      this.props.postDiscovery({ type: type });
      this.setState({requestSubmittedSuccessfuly:true});
    }
    //this.setState({ open: false })
    this.handleDialogClose();
  };

  ////    Cards filter    //////
  onFilterChange(searchTerm) {
    //console.log(searchTerm);
    this.setState({ searchTerm: searchTerm });
  }

  filterCards(cards, searchTerm) {
    return cards.filter(
      (probe) =>
        probe.name.toLowerCase().indexOf(searchTerm) !== -1 ||
        probe.displayname.toLowerCase().indexOf(searchTerm) !== -1
    );
  }

  splitCardSections = (cards) => {
    let sectionsMap = {},
      searchTerm = this.state.value.toLowerCase(),
      available = Math.floor(this.state.containerWidth / this.cardWidth),
      cardsLimitPerRow = available < 4 ? 4 : available;
    //Special scenario: if available width accommodate 3, then needs to allow max 6 otherwise
    if (available === 3) {
      cardsLimitPerRow = 6;
    }
    if (cards && cards.length && this.setupWizardContainer) {
      cards = orderBy(cards, [(card) => card.imageName.toLowerCase()], ["asc"]);
      for (let i = 0; i < cards.length; i++) {
        let probe = cards[i];
        if (
          this.checkSubStrings(probe.imageName, searchTerm) ||
          this.checkSubStrings(probe.name, searchTerm) ||
          this.checkSubStrings(probe.displayname, searchTerm) ||
          this.checkInTags(probe)
        ) {
          if (!sectionsMap[probe.category]) {
            sectionsMap[probe.category] = { totalInfo: [], displayedInfo: [] };
            if (!this.cardsInfo[probe.category]) {
              this.cardsInfo[probe.category] = {};
              this.cardsInfo[probe.category].hideRemaining = true;
              this.cardsInfo[probe.category].previouslyInHiddenState = false;
            }
            this.cardsInfo[probe.category].count = 0;
            this.cardsInfo[probe.category].displayedCount = 0;
          }
          let card = (
            <SetupWizardCard
              key={probe.category + "_" + probe.imageName}
              probe={probe}
              onShowWalkThrough={(probeName) => this.showWalkThrough(probeName)}
              onhandleConfigClick={(probe) => this.showEditConfig(probe)}
            />
          );

          this.cardsInfo[probe.category].count++;
          sectionsMap[probe.category].totalInfo.push(card);
          if (
            this.cardsInfo[probe.category].hideRemaining === false ||
            sectionsMap[probe.category].displayedInfo.length < cardsLimitPerRow
          ) {
            this.cardsInfo[probe.category].displayedCount++;
            sectionsMap[probe.category].displayedInfo.push(card);
          }
        }
      }
    }
    if (this.sectionsOrder.length === 0) {
      this.sectionsOrder.push(...Object.keys(sectionsMap));
    }
    return sectionsMap;
  };
  checkSubStrings(name, searchTerm) {
    let subStrings = name.split(" "),
      isFound = false;
    subStrings.forEach(function (item) {
      item = item.trim();
      if (item.toLowerCase().startsWith(searchTerm)) {
        isFound = true;
      }
    });
    return isFound;
  }
  checkInTags(probe) {
    let isPresent = false,
      i,
      len = probe.tagType.length,
      searchTerm = this.state.value.toLowerCase();
    for (i = 0; i < len; i++) {
      isPresent = this.checkSubStrings(probe.tagType[i], searchTerm);
      if (isPresent) {
        break;
      }
    }
    return isPresent;
  }
  getLocalProbesInfo = () => {
    let classNames = ["setup-wizard__localprobes__info"];
    //Local Monitoring
    let msg1 = Resource.get("Local Monitoring"),
      //You can configure additional monitoring in context of existing devices and groups.
      msg2 = Resource.get(
        "You can configure additional monitoring in context of existing devices and groups."
      ),
      //Read more
      msg3 = Resource.get("Read more");
    if (!this.state.showLocalProbesInfo) {
      classNames.push("hide");
    }
    // Close
    const CLOSE = Resource.get('Close')
    
    return (
      <div className={classNames.join(" ")}>
        <div
          style={{
            width: "calc(100% - 60px)",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          <div className="setup-wizard__localprobes__contentImg"></div>
          <span className="setup-wizard__localprobes__content">
            <span
              style={{
                padding: "2px 13px 2px 20px",
                borderRight: "2px solid #999999",
              }}
            >
              {msg1}
            </span>
            <span style={{ paddingLeft: "9px" }}>
              {msg2}
              {/* <span style={{color:'#3272D9',paddingLeft: 4,cursor:'pointer'}} onClick={() =>
              window.open(
                  	'https://docops.ca.com/rest/ca/product/latest/topic?format=rendered&language=&space=dxiim&hid=HID_Monitoring_Technologies',
                      '_blank',
                    )}>{msg3}</span> */}
            </span>
          </span>
        </div>
        <IconButton
          className="setup-wizard__localprobes__contentClose"
          onClick={() => {
            this.setState({ showLocalProbesInfo: false });
          }}
          aria-label={CLOSE}
        >
          <CloseIcon style={{color: "#58606E"}}/>
        </IconButton>
      </div>
    );
  };
  setSearchTerm = (event) => {
    let value = event.target.value;
    if (this.debounceRef) {
      clearTimeout(this.debounceRef);
    }
    this.debounceRef = setTimeout(() => {
      this.cardsInfo = {};
      this.debounceRef = null;
      this.setState({ value: value });
    }, 250);
  };
  render() {
    const { classes } = this.props;
    let deploymentStatus = this.deploymentStatus();
    let showSetupWizardAgainChecked = this.checkCookie();
    // Filter to show only the white listed probes
    let useEnhancedTemplates = get(
      this.props.featureFlags,
      "useEnhancedTemplates",
      true
    );
    probeWhiteListflag = get(this.props.featureFlags, "probeWhiteList", false);
    if (useEnhancedTemplates) {
      let probeWhiteListSTB = get(
        this.props.featureFlags,
        "probeWhiteListSTB",
        false
      );
      if (
        typeof probeWhiteListSTB === "string" &&
        typeof probeWhiteListflag === "string"
      ) {
        probeWhiteListflag = Array.from(
          new Set(
            probeWhiteListSTB.concat(",").concat(probeWhiteListflag).split(",")
          )
        ).join(",");
      } else {
        probeWhiteListflag = probeWhiteListSTB;
      }
    }
    const cards = this.props.probeList.length
      ? this.props.probeList.filter((probe) => {
          if (probe.name.endsWith("(RESTMon)")) {
            return true;
          }
          return probeWhiteListflag.includes(probe.name);
        })
      : null;

    const cardSections = this.splitCardSections(cards);
    let sectionsLabels = Object.keys(cardSections);

    if (sectionsLabels && sectionsLabels.length && this.sectionsOrder.length) {
      let info = [];
      this.sectionsOrder.forEach((label) => {
        if (sectionsLabels.indexOf(label) !== -1) {
          info.push(label);
        }
      });
      sectionsLabels = info;
    }

    const items =
      sectionsLabels && sectionsLabels.length ? (
        sectionsLabels.map((label, index) => {
          let count = this.cardsInfo[label] ? this.cardsInfo[label].count : 0,
            data = cardSections[label].displayedInfo,
            considerShowAll = true;
          if (count <= this.SHOWALL_LIMIT) {
            data = cardSections[label].totalInfo;
            considerShowAll = false;
          }
          return (
            <SetupWizardContainer
              label={label}
              key={label}
              count={count}
              considerShowAll={considerShowAll}
              info={this.cardsInfo[label]}
              toggleShowAll={(label) => {
                this.cardsInfo[label].hideRemaining = !this.cardsInfo[label]
                  .hideRemaining;
                if (!this.cardsInfo[label].previouslyInHiddenState) {
                  this.cardsInfo[label].previouslyInHiddenState = true;
                }
                this.forceUpdate();
              }}
            >
              {data}
            </SetupWizardContainer>
          );
        })
      ) : this.state.value.length !== 0 ? (
        <div style={{ fontSize: 24 }}>No results found</div>
      ) : (
        <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><CircularProgress style={{color: '#3272D9'}} /></div>
      );
    // UIM React component CircularProgress not adding class as expected - issue so adding class here
    const setupWizardDialogElem = this.state.open && (
      <SetupWizardDialog
        open={this.state.open}
        sid={this.props.sid}
        probe={this.state.probe}
        robots={this.props.robots}
        hubs={this.props.hubs}
        hubsIsFetching={this.props.hubsIsFetching}
        probeAddress={this.state.probeAddress}
        host={this.state.host}
        newlyDeployed={this.state.newlyDeployed}
        deploymentInProgress={this.state.deploymentInProgress}
        onDialogClose={() => this.handleDialogClose()}
        enableMonitoringCheckbox={(event, checked) =>
          this.enableMonitoringCheckbox(event, checked)
        }
        enableMonitoring={(probe) => this.enableMonitoring(probe)}
        enableMonitoringChecked={this.enableMonitoringChecked}
        setRobotsBeingDeployed={(robots) => this.setRobotsBeingDeployed(robots)}
        onComplete={(robotsStatus) => this.onComplete(robotsStatus)}
        onMaxEdit={(data) => this.onMaxEdit(data)}
      />
    );
    // Search 
    const SEARCH = Resource.get('Search')
    return (
      <div className="setup-wizard-container">
       <CustomSnackbar message={"Request submitted successfully"} severity={'info'} close={() => {this.setState({requestSubmittedSuccessfuly:false})}} autoHideDuration={4000} open={this.state.requestSubmittedSuccessfuly} />
      
        {this.getLocalProbesInfo()}
        {deploymentStatus}
        <div className="setup-wizard__header">
          <h2 className="setup-wizard__header__label"> {selectMessage}</h2>
          <div className="setup-wizard__header__search">
            <TextField id="first-content-element"
              name="q"
             
              inputProps={{'aria-label':SEARCH}}
              style={{
                width: 230,
              }}
              variant="outlined"
              placeholder={SEARCH}
              size='small'
              onChange={this.setSearchTerm}
              InputProps={{
           
            
           endAdornment: (
             <InputAdornment position="end" >
                <Tooltip title={"Search"}>
                 <SearchIcon  className="selecticon" style={{ height: 16 }} />
                 </Tooltip>
             </InputAdornment>
           )}}
            //  InputProps={{ classes: {input: this.props.classes['input']} }}

            />
           
          </div>
        </div>
        <div
          className="setup-wizard"
          ref={(element) => {
            this.setupWizardContainer = element;
          }}
        >
          {items}
        </div>
        {/*
        <div className="setup-wizard__footer">
          <Checkbox
            defaultChecked={showSetupWizardAgainChecked}
            onCheck={this.doNotShowAgain}
            className="setup-wizard__checkbox"
            // help text 
            label= {Resource.get('Don\'t show this screen by default')}
            style={{
              width: null,
            }}
          />
        </div>
        */}
        {setupWizardDialogElem}
      </div>
    );
  }

  ////
  checkCookie = () => {
    let showSetupWizardAgain = LocalStorageManager.getItem(
      "OPERATOR_CONSOLE",
      "showSetupWizardAgain"
    );
    if (
      showSetupWizardAgain === null ||
      showSetupWizardAgain === undefined ||
      showSetupWizardAgain === "true"
    ) {
      showSetupWizardAgain = true;
    } else {
      showSetupWizardAgain = false;
    }
    let showSetupWizardAgainChecked = !showSetupWizardAgain;
    return showSetupWizardAgainChecked;
  };

  doNotShowAgain = (event, isChecked) => {
    LocalStorageManager.setItem("OPERATOR_CONSOLE", {
      showSetupWizardAgain: (!isChecked).toString(),
    });
  };
}

export default withStyles(styles)(SetupWizard);