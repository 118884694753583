import React from 'react'
import './icons.less'

const Icon = (props) => {
  let classes = [
    'icon',
    props.className,
  ].join(' ')
  return (
      <svg className={classes} role={props.role || 'figure'} aria-label="[title]" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <g fill="#000000" fillRule="evenodd">
          <path d="M11,6.99967402 C11,6.44854939 11.4486957,6 12,6 C12.5519565,6 13,6.44854939 13,6.99967402 C13,7.55145061 12.5519565,8 12,8 C11.4486957,8 11,7.55145061 11,6.99967402 Z M16.0032782,11.0317666 C16.0032782,3.94680834 12.3434738,1.16852709 12.1558574,1.07417465 L12.0089953,1 L11.9910047,1 L11.8426739,1.07417465 C11.6565262,1.16852709 7.99635467,3.94680834 7.99635467,11.0317666 C7.54218353,11.4776671 5,14.4074238 5,14.4074238 L6.56665181,19 L8.88340196,16.4382746 L11.9979806,16.4382746 L12.0016522,16.4382746 L15.1162309,16.4382746 L17.432981,19 L19,14.4074238 C19,14.4074238 16.4563479,11.4776671 16.0032782,11.0317666"/>
          <path d="M13.4179465,19.1689204 C13.4582758,18.9303413 13.5404063,18.9744171 13.7455855,18.6781668 C13.9513534,18.3821358 12.8698209,17.6181566 12.8698209,17.6181566 L12.1912873,17 L11.8191978,17 L11.1524392,17.7902931 C11.1524392,17.7902931 10.2036697,18.3308238 10.3493851,18.5255464 C10.4948062,18.7196111 10.4344594,18.6283897 10.5525036,18.7976756 C10.6693704,18.968935 10.4011951,19.2996126 10.2304578,19.5471822 C10.0620755,19.7943133 9.95080184,19.9247862 10.0217461,20.2523938 C10.0906298,20.58044 10.5601574,20.8354652 10.5601574,20.8354652 C10.5601574,20.8354652 10.4067882,20.1543747 10.8168521,20.0581098 C11.2272103,19.9603099 11.531005,20.2280535 11.5433687,20.6786785 C11.5548493,21.1284263 10.9569744,21.8456984 10.9569744,21.8456984 C10.9569744,21.8456984 11.2896178,21.7571084 11.6210836,21.5235728 C11.6287374,21.5553688 11.6337417,21.5836562 11.6422786,21.6165485 C11.9401857,22.6658139 12.9781508,23 12.9781508,23 C12.9781508,23 12.3408296,22.0619107 12.3523103,21.4731379 C12.3655571,20.8854616 12.6914299,20.5350486 13.1285762,20.6626709 C13.5654282,20.7900738 13.4023446,21.6807982 13.4023446,21.6807982 C13.4023446,21.6807982 13.9036647,21.3459542 13.9772583,20.9168189 C14.0520295,20.4881222 13.9333965,20.3179592 13.7532392,19.9956144 C13.5713157,19.6721731 13.3764397,19.4066223 13.4179465,19.1689204"/>
        </g>
      </svg>
  )
}

export default Icon
