// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //FIX ISSUES
  content["FIX ISSUES"] =
  "問題の修正";

  //device
  content["device"] =
  "デバイス";

  //devices
  content["devices"] =
  "デバイス";

  //LasrDeployment message
  content["Last Deployment"] =
  "前回の展開";

  //pending
  content["pending"] =
  "保留中";

  //succeeded
  content["succeeded"] =
  "成功";

  // failed
  content["failed"] =
  "失敗";

  //Setup Infrastructure Monitoring
  content["Setup Infrastructure Monitoring"] =
  "インフラストラクチャ モニタリング セットアップ";

  //For more information, click
  content["For more information, click"] =
  "詳細については、次をクリックしてください";

  //Setup Wizard
  content["Setup Wizard"] =
  "セットアップ ウィザード";

  //Remote And Cloud
  content["Remote And Cloud"] =
  "リモートおよびクラウド";

  //Local Monitoring
  content["Local Monitoring"] =
  "ローカル モニタリング";

  //You can configure additional monitoring in context of existing devices and groups.
  content["You can configure additional monitoring in context of existing devices and groups."] =
  "既存のデバイスおよびグループのコンテキストで、追加のモニタリングを設定できます。";

  //Read more
  content["Read more"] =
  "続きを読む";

  // help text
  content["Don\'t show this screen by default"] =
  "デフォルトではこの画面を表示しません";

  //ADD
  content["ADD"] =
  "追加";

  //CONFIG
  content["CONFIG"] =
  "設定";

  //Show All
  content["Show All"] =
  "すべて表示";

  //Show Less
  content["Show Less"] =
  "簡易表示";

  //Technology
  content["Technology"] =
  "テクノロジ";

  //Technologies
  content["Technologies"] =
  "テクノロジ";

  //Cancel
  content["Cancel"] =
  "キャンセル";

  //PREVIOUS
  content["PREVIOUS"] =
  "前へ";

  //next
  content["next"] =
  "次へ";

  //FINISH
  content["FINISH"] =
  "完了";

  //Presenting the All New Setup Wizard
  content["Presenting the All New Setup Wizard"] =
  "全く新しいセットアップ ウィザードの紹介";

  //Towards improving your experience with managing and monitoring your infrastructure, we are presenting this new enhanced interface that meets your needs better.
  content["Towards improving your experience with managing and monitoring your infrastructure, we are presenting this new enhanced interface that meets your needs better."] =
  "インフラストラクチャの管理およびモニタリングにおけるユーザ エクスペリエンスの向上を目的とした、ユーザのニーズにより適合した、この拡張された新しいインターフェースをご紹介します。";

  //See what's changed
  content["See what\'s changed"] =
  "変更内容の確認";

  //Discover Devices
  content["Discover Devices"] =
  "デバイスの検出";

  //Welcome to Setup Wizard
  content["Welcome to Setup Wizard"] =
  "セットアップ ウィザードへようこそ";

  //Your home to setup and configure monitoring infrastructure
  content["Your home to setup and configure monitoring infrastructure"] =
  "モニタリング インフラストラクチャをセットアップおよび設定するためのホーム";

  // END OF LOCALIZATION

export default content;
