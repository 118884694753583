import React from 'react'
import {connect} from "react-redux";
import {
  Dialog, DialogTitle,
  Button as FlatButton,
  TextField,
  Snackbar,
  MenuItem,
  InputLabel, FormControl, Select,  DialogActions, DialogContent,Typography,Tooltip
} from "@mineral/core"
import CustomSnackbar from '../common/CustomSnackbar';

import {IconButton} from '@mineral/core';
import CloseIcon from "@material-ui/icons/Close";

import {CircularProgress} from '@mineral/core';
import Resource from "./Resource";
import visualize from "./../../utils/visualize";
// import "./createdashboarddialog.less"
import CabiApi from "../../api/cabi/cabi";
import isEqual from "lodash/isEqual";
import "../setupWizard/deviceDiscovery/credentialVault.less";

const cabiURLLabel = Resource.get("Cabi URL")//cabiurls
const dashboardPathLabel = Resource.get("Dashboard Path*")//dashboardPath
const defaultAlarmStateLabel = Resource.get("Default Alarm State")//defaultAlarmState
const defaultTimeRangeLabel = Resource.get("Default Time Range")//defaultTimeRange
const defaultMinimumAlarmLabel = Resource.get("Default Minimum Alarm")//defaultMinimumAlarm
const defaultTopNLabel = Resource.get("Default Top N")//defaultTopN
const staticFiltersLabel = Resource.get("Static Filters")//staticFilters
const dynamicFiltersLabel = Resource.get("Dynamic Filters")//dynamicFilters
const dashboardNameLabel = Resource.get("Dashcard name")//dashcardname
const dashboardDescriptionLabel = Resource.get("Description")//description


const alarmStateOpenAlarms = Resource.get("Open Alarms"); //openalarms
const alarmStateClosedAlarms = Resource.get("Closed Alarms"); //closedalarms
const alarmStateAllAlarms = Resource.get("All Alarms");//allalarms

const defaultTimeRange1Hour = Resource.get("1 Hour");//1hour
const defaultTimeRange2Hours = Resource.get("2 Hours");//2hourss
const defaultTimeRange12Hours = Resource.get("12 Hours");//12hours
const defaultTimeRange24Hours = Resource.get("24 Hours");//24hours
const defaultTimeRange3Days = Resource.get("3 Days");//3days
const defaultTimeRange1Week = Resource.get("1 Week");//1week
const defaultTimeRange2Weeks = Resource.get("2 Weeks");//2weeks
const defaultTimeRange1Month = Resource.get("1 Month");//1month
const defaultTimeRange3Months = Resource.get("3 Months");//3months

const defaultMinimumAlarmMinor = Resource.get("Minor");//minor
const defaultMinimumAlarmInformation = Resource.get("Information");//information
const defaultMinimumAlarmWarning = Resource.get("Warning");//warning
const defaultMinimumAlarmMajor = Resource.get("Major");//major
const defaultMinimumAlarmCritical = Resource.get("Critical");//critical
const  requiredLabel = Resource.get("Required"); //Required

// Save
const SAVE_DASHBOARD = Resource.get("Save")
// Create
const CREATE_DASHBOARD = Resource.get("Create")
// Cancel
const CANCEL_CREATE = Resource.get("Cancel")
// Ok
const OK = Resource.get("Ok")

const defaultTop5 = Resource.get("Top 5");//top5
const defaultTop10 = Resource.get("Top 10");//top10
const defaultTop20 = Resource.get("Top 20");//top20
const defaultTop50 = Resource.get("Top 50");//top50
const defaultTop100 = Resource.get("Top 100");//top100
const INIT_CABI_PREFERENCES = {
  dashboardPath: "",
  defaultAlarmState: "open",
  defaultTimeRange: 1,
  defaultMinimumAlarm: 3,
  defaultTopN: 10,
  staticFilters: "{}",
  dynamicFilters: "[]",
  dashboardName: "",
  description: ""
}

const styles = theme => ({
  formControl: {
    width: '100%',
  },
});

const ErrorDialog = function (props) {
  let msg1 = Resource.get("Error retrieving cabi information"), //cabiErrorRetrieve
    msg2 = Resource.get("Cabi is not available or accessible. Please contact your administrator."); //contactAdmin

  const action = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={props.onClose} autoFocus
      >
        <CloseIcon fontSize="small"/>
      </IconButton>
    </>
  );

  if(props.open)
  return (<CustomSnackbar severity={'info'}
          open={props.open}
          message={msg2}
          autoHideDuration={40000}
          
          close={props.onClose}/>);
          else
          return(null);
  
};


class CreateDashboardDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openErrorDialog: false,
      loadStatus: "loading",
      dashboardList: [],
      isSaveDisabled: true,
      isEdit: props.cabiPayload ? true : false,
      cabiPreferencesPayload: props.cabiPayload ? props.cabiPayload : INIT_CABI_PREFERENCES,
      cabiPreferencesPayloadBeforeEdit: props.cabiPayload,
    }
  }

  componentWillMount() {
    CabiApi.get("en", this.props.sid)
      .then((response) => response.data)
      .then((json) => {
        this.setState({
          jasperToken: json.token,
          cabiUrl: json.address,
          userLocale: json.locale,
          orgidl: json.orgidl ? json.orgidl : "ca",


        });
        let viz = new visualize();
        let cabiProps = {saas: this.props.saas, doiurl: this.props.doiurl, featureFlags: this.props.featureFlags};
        viz.setProductList(cabiProps);
        viz.checkAndLoadVisualize(json.address).then((isLoaded) => {
          if (isLoaded) {
            viz.getDashboardUrls({
              url: json.address,
              resourceType: "dashboard",
              jasperToken: json.token,
              orgidl: json.orgidl ? json.orgidl : "ca",
              locale: json.locale,

            })
              .then((response) => {

                let cabiPayload = Object.assign({}, this.state.cabiPreferencesPayload);
                let dashboardList = response;
                if ((!cabiPayload.dashboardPath || cabiPayload.dashboardPath.trim() == "") && dashboardList && dashboardList.length > 0) {
                  cabiPayload.dashboardPath = dashboardList[0].uri;
                }
                this.setState({dashboardList: response, loadStatus: "complete",})
              })
              .catch((error) => {
                // console.log("error in loading dashboard", error);
                //resolve([]);
                console.log("Failed to get dashboard urls" + error);
                this.setState({
                  loadStatus: "failed",
                });
              });
          } else {
            console.log("Failed to get dashboard urls");
            this.setState({
              loadStatus: "failed",
            });
          }
        }).catch((error) => {
          // console.log("error in loading dashboard", error);
          //resolve([]);
          console.log("Failed to load dashboard" + error);
          this.setState({
            loadStatus: "failed",
          });
        });

      })
      .catch((error) => {
        console.error("Error retrieving cabi info:", error);
        this.setState({
          loadStatus: "failed",
          openErrorDialog: true,
        });
      });
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.open && this.state.loadStatus === 'failed') {
      this.setState({openErrorDialog: true})
    }

  }

  onClose = () => {
    this.setState({cabiPreferencesPayload: INIT_CABI_PREFERENCES})
  }

  refreshDialog = () => {
    this.setState({
      cabiPreferencesPayload:  INIT_CABI_PREFERENCES,
    })
  }
  
  closeHandler = () => {
    this.props.onClose();
    this.refreshDialog();
  }

  saveHandler= () => {
    this.props.onSave(this.state.cabiPreferencesPayload)
    this.refreshDialog()
  }

  onCloseErrorDialog = () => {
    this.setState({openErrorDialog: false});
    this.props.onClose()
  };


  render() {
   

    const dashboardPathMenuItemArray = [];
    for (let i = 0; i < this.state.dashboardList.length; i++) {
      let dashboardObj = this.state.dashboardList[i];
      let dashboardLabel = dashboardObj.label + " (" + dashboardObj.uri + ")";
      dashboardPathMenuItemArray.push(
        <MenuItem key={i} value={dashboardObj.uri}>{dashboardLabel}</MenuItem>);
    }
    const dashboardPathComp = (
      <FormControl fullWidth={true} style={{marginBottom: '20px',marginTop:'8px'}}>
       
          <Select fullWidth
            id="dashboardpath-select"
            displayEmpty
            MenuProps={{MenuListProps:{'aria-label': "dashboardPath-ul"}}}
            inputProps={{'aria-label': 'Dashboarrd Path'}}
            onChange={(event) => {
              let payload = event.target.value;
              let cabiPayload = Object.assign({}, this.state.cabiPreferencesPayload);
              let isSaveDisabled = true;
              if (payload && payload.trim() !== "" && cabiPayload.dashboardName && cabiPayload.dashboardName.trim() !== "")
                isSaveDisabled = false;
              Object.assign(cabiPayload, {dashboardPath: payload});
              if (this.state.isEdit) {
                if (!isEqual(this.state.cabiPreferencesPayloadBeforeEdit, cabiPayload))
                  isSaveDisabled = isSaveDisabled || false;
              }
              this.setState({cabiPreferencesPayload: cabiPayload, isSaveDisabled: isSaveDisabled});
            }}
            value={this.state.cabiPreferencesPayload.dashboardPath}
            // selectedmenuitemstyle={{ color: "rgb(0, 125, 171)" }}  
          >
            <MenuItem value="" disabled>{dashboardPathLabel}</MenuItem>
            {dashboardPathMenuItemArray}
          </Select>
        
      </FormControl>
    );

    const defaultAlarmStateComponent = (
      
        <FormControl fullWidth={true} style={{marginBottom: '20px'}}>
          <InputLabel htmlFor="alarmState-select">{defaultAlarmStateLabel}</InputLabel>
          <Select fullWidth MenuProps={{MenuListProps:{'aria-label': "alarmstate-ul"}}}
            id="alarmState-select"
            onChange={(event) => {
              let payload = event.target.value;
              let cabiPayload = Object.assign({}, this.state.cabiPreferencesPayload);
              Object.assign(cabiPayload, {defaultAlarmState: payload});
              let isSaveDisabled = this.state.isSaveDisabled;

              if (this.state.isEdit) {
                if (!isEqual(this.state.cabiPreferencesPayloadBeforeEdit, cabiPayload)
                  && this.state.cabiPreferencesPayload.dashboardName &&
                  this.state.cabiPreferencesPayload.dashboardName.trim() !== "" &&
                  this.state.cabiPreferencesPayload.dashboardPath &&
                  this.state.cabiPreferencesPayload.dashboardPath.trim() !== "")
                  isSaveDisabled = false;

              }
              this.setState({cabiPreferencesPayload: cabiPayload, isSaveDisabled: isSaveDisabled});
            }}
            value={this.state.cabiPreferencesPayload.defaultAlarmState}
            
          >
            <MenuItem
              value={"open"}
            >{alarmStateOpenAlarms}</MenuItem>
            <MenuItem
              value={"closed"}
            >{alarmStateClosedAlarms}</MenuItem>
            <MenuItem
              value={"all"}
            >{alarmStateAllAlarms}</MenuItem>
          </Select>
        </FormControl>
    
    );

    const defaultTimeRangeComponent = (
     
        <FormControl fullWidth={true}  style={{marginBottom: '20px'}}>
          <InputLabel htmlFor="TimeRangeLabel-select">{defaultTimeRangeLabel}</InputLabel>
          <Select fullWidth MenuProps={{MenuListProps:{'aria-label': "timerange-ul"}}}
            id="TimeRangeLabel-select"
            onChange={(event) => {
              let payload = event.target.value
              let cabiPayload = Object.assign({}, this.state.cabiPreferencesPayload);
              Object.assign(cabiPayload, {defaultTimeRange: payload});
              let isSaveDisabled = this.state.isSaveDisabled;
              if (this.state.isEdit) {
                if (!isEqual(this.state.cabiPreferencesPayloadBeforeEdit, cabiPayload) &&
                  this.state.cabiPreferencesPayload.dashboardName &&
                  this.state.cabiPreferencesPayload.dashboardName.trim() !== "" &&
                  this.state.cabiPreferencesPayload.dashboardPath &&
                  this.state.cabiPreferencesPayload.dashboardPath.trim() !== "")
                  isSaveDisabled = false;
              }
              this.setState({cabiPreferencesPayload: cabiPayload, isSaveDisabled: isSaveDisabled});
            }}
            value={this.state.cabiPreferencesPayload.defaultTimeRange}
            

          >
            <MenuItem
              value={1}
            >{defaultTimeRange1Hour}</MenuItem>
            <MenuItem
              value={2}
            >{defaultTimeRange2Hours}</MenuItem>
            <MenuItem
              value={12}
            >{defaultTimeRange12Hours}</MenuItem>
            <MenuItem
              value={24}
            >{defaultTimeRange24Hours}</MenuItem>
            <MenuItem
              value={72}
            >{defaultTimeRange3Days}</MenuItem>
            <MenuItem
              value={168}
            >{defaultTimeRange1Week}</MenuItem>
            <MenuItem
              value={336}
            >{defaultTimeRange2Weeks}</MenuItem>
            <MenuItem
              value={720}
            >{defaultTimeRange1Month}</MenuItem>
            <MenuItem
              value={2160}
            >{defaultTimeRange3Months}</MenuItem>
          </Select>
        </FormControl>
     
    );


    const defaultMinimumAlarmComponent = (
     
        <FormControl fullWidth={true} style={{marginBottom: '20px'}}>
          <InputLabel htmlFor="minAlarm-select">{defaultMinimumAlarmLabel}</InputLabel>
          <Select fullWidth MenuProps={{MenuListProps:{'aria-label': "minAlarm-ul"}}}
            id="minAlarm-select"
            onChange={(event) => {
              let payload = event.target.value;
              let cabiPayload = Object.assign({}, this.state.cabiPreferencesPayload);
              Object.assign(cabiPayload, {defaultMinimumAlarm: payload});
              let isSaveDisabled = this.state.isSaveDisabled;
              if (this.state.isEdit) {
                if (!isEqual(this.state.cabiPreferencesPayloadBeforeEdit, cabiPayload) && this.state.cabiPreferencesPayload.dashboardName &&
                  this.state.cabiPreferencesPayload.dashboardName.trim() !== "" &&
                  this.state.cabiPreferencesPayload.dashboardPath &&
                  this.state.cabiPreferencesPayload.dashboardPath.trim() !== "")
                  isSaveDisabled = false;
              }
              this.setState({cabiPreferencesPayload: cabiPayload, isSaveDisabled: isSaveDisabled});
            }}
            value={this.state.cabiPreferencesPayload.defaultMinimumAlarm}
            

          >
            <MenuItem
              value={1}
            >{defaultMinimumAlarmInformation}</MenuItem>
            <MenuItem
              value={2}
            >{defaultMinimumAlarmWarning}</MenuItem>
            <MenuItem
              value={3}
            >{defaultMinimumAlarmMinor}</MenuItem>
            <MenuItem
              value={4}
            >{defaultMinimumAlarmMajor}</MenuItem>
            <MenuItem
              value={5}
            >{defaultMinimumAlarmCritical}</MenuItem>
          </Select>
        </FormControl>
     
    );

    const defaultTopNComponent = (
      
        <FormControl style={{marginBottom: '20px'}} fullWidth={true}>
          <InputLabel htmlFor="topNLabel-select">{defaultTopNLabel}</InputLabel>
          <Select fullWidth MenuProps={{MenuListProps:{'aria-label': "topn-ul"}}}
            id="topNLabel-select"
            onChange={(event) => {
              let payload = event.target.value
              let cabiPayload = Object.assign({}, this.state.cabiPreferencesPayload);
              Object.assign(cabiPayload, {defaultTopN: payload});
              let isSaveDisabled = this.state.isSaveDisabled;
              if (this.state.isEdit) {
                if (!isEqual(this.state.cabiPreferencesPayloadBeforeEdit, cabiPayload) && this.state.cabiPreferencesPayload.dashboardName &&
                  this.state.cabiPreferencesPayload.dashboardName.trim() !== "" &&
                  this.state.cabiPreferencesPayload.dashboardPath &&
                  this.state.cabiPreferencesPayload.dashboardPath.trim() !== "")
                  isSaveDisabled = false;
              }
              this.setState({cabiPreferencesPayload: cabiPayload, isSaveDisabled: isSaveDisabled});
            }}
            value={this.state.cabiPreferencesPayload.defaultTopN}
            

          >
            <MenuItem
              value={5}
            >{defaultTop5}</MenuItem>
            <MenuItem
              value={10}
            >{defaultTop10}</MenuItem>
            <MenuItem
              value={20}
            >{defaultTop20}</MenuItem>
            <MenuItem
              value={50}
            >{defaultTop50}</MenuItem>
            <MenuItem
              value={100}
            >{defaultTop100}</MenuItem>
          </Select>
        </FormControl>
     
    );

    const staticFilters = (
      <FormControl fullWidth={true} style={{marginBottom: '20px'}}>
        <TextField
          fullWidth
          label={staticFiltersLabel}
          value={this.state.cabiPreferencesPayload.staticFilters}
          onChange={(evt) => {
            let cabiPayload = Object.assign({}, this.state.cabiPreferencesPayload);
            Object.assign(cabiPayload, {staticFilters: evt.target.value});
            let isSaveDisabled = this.state.isSaveDisabled;
            if (this.state.isEdit) {
              if (!isEqual(this.state.cabiPreferencesPayloadBeforeEdit, cabiPayload) && this.state.cabiPreferencesPayload.dashboardName &&
                this.state.cabiPreferencesPayload.dashboardName.trim() !== "" &&
                this.state.cabiPreferencesPayload.dashboardPath &&
                this.state.cabiPreferencesPayload.dashboardPath.trim() !== "")
                isSaveDisabled = false;
            }
            this.setState({cabiPreferencesPayload: cabiPayload, isSaveDisabled: isSaveDisabled});
          }}
        />
      </FormControl>
    );

    const dashboardnameComp = (
      <FormControl fullWidth={true} style={{marginBottom: '20px'}}>
       <InputLabel id="input-dashboardnameCompLabel" htmlFor="dashboardnameComp" > {dashboardNameLabel}<span className="credentialvault-required-label">{requiredLabel}</span></InputLabel>
                          
                           
        <TextField
          fullWidth  inputProps={{ 'aria-required': true }}
        id="dashboardnameComp" labelId="input-dashboardnameCompLabel"
         
          value={this.state.cabiPreferencesPayload.dashboardName}
          onChange={(evt) => {
            let cabiPayload = Object.assign({}, this.state.cabiPreferencesPayload);
            let isSaveDisabled = true;
            if (evt.target.value && evt.target.value.trim() !== "" && this.state.cabiPreferencesPayload.dashboardPath && this.state.cabiPreferencesPayload.dashboardPath.trim() !== "")
              isSaveDisabled = false;
            Object.assign(cabiPayload, {dashboardName: evt.target.value});
            if (this.state.isEdit) {
              if (!isEqual(this.state.cabiPreferencesPayloadBeforeEdit, cabiPayload))
                isSaveDisabled = isSaveDisabled || false;
            }
            this.setState({cabiPreferencesPayload: cabiPayload, isSaveDisabled: isSaveDisabled});


          }}
        />
      </FormControl>
    );
    const dashboardDescComp = (
      <FormControl fullWidth={true} style={{marginBottom:'8px'}}>
        <TextField
          fullWidth
          label={dashboardDescriptionLabel}
          value={this.state.cabiPreferencesPayload.description}
          onChange={(evt) => {
            let cabiPayload = Object.assign({}, this.state.cabiPreferencesPayload);
            Object.assign(cabiPayload, {description: evt.target.value});
            let isSaveDisabled = this.state.isSaveDisabled;
            if (this.state.isEdit) {
              if (!isEqual(this.state.cabiPreferencesPayloadBeforeEdit, cabiPayload) && this.state.cabiPreferencesPayload.dashboardName &&
                this.state.cabiPreferencesPayload.dashboardName.trim() !== "" &&
                this.state.cabiPreferencesPayload.dashboardPath &&
                this.state.cabiPreferencesPayload.dashboardPath.trim() !== "")
                isSaveDisabled = false;
            }
            this.setState({cabiPreferencesPayload: cabiPayload, isSaveDisabled: isSaveDisabled});


          }}

        /></FormControl>
    );

    const dynamicFilters = (

      <FormControl fullWidth={true} style={{marginBottom: '20px'}}>
        <TextField
          fullWidth
          value={this.state.cabiPreferencesPayload.dynamicFilters}
          onChange={(evt) => {
            let cabiPayload = Object.assign({}, this.state.cabiPreferencesPayload.dynamicFilters);
            Object.assign(cabiPayload, {dynamicFilters: evt.target.value});
            let isSaveDisabled = this.state.isSaveDisabled;
            if (this.state.isEdit) {
              if (!isEqual(this.state.cabiPreferencesPayloadBeforeEdit, cabiPayload) && this.state.cabiPreferencesPayload.dashboardName &&
                this.state.cabiPreferencesPayload.dashboardName.trim() !== "" &&
                this.state.cabiPreferencesPayload.dashboardPath &&
                this.state.cabiPreferencesPayload.dashboardPath.trim() !== "")
                isSaveDisabled = false;
            }
            this.setState({cabiPreferencesPayload: cabiPayload, isSaveDisabled: isSaveDisabled});
          }}
        />
      </FormControl>
    );

    let titleContent = this.props.cabiPayload ? Resource.get("Edit Dashboard") //edit Dashboard
      : Resource.get("Add Dashboard") //add dashboard
    
      
    let saveTitle = Resource.get('Save'); //Save
    let createTitle = Resource.get('Create'); //Create
    let dialogContent = <div>

      {dashboardPathComp}

      {defaultAlarmStateComponent}

      {defaultTimeRangeComponent}
      {defaultMinimumAlarmComponent}
      {defaultTopNComponent}
      {staticFilters}
      {dashboardnameComp}
      {dashboardDescComp}
    </div>

    if (this.state.loadStatus === "failed") {
      return (
        <ErrorDialog
          open={this.state.openErrorDialog}
          onClose={this.onCloseErrorDialog.bind(this)}
        />
      );
    } else {
      if (this.state.loadStatus === "loading")
        dialogContent = <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><CircularProgress style={{color: '#3272D9'}} /></div>

      return (
        <Dialog
          open={this.props.open}
          scroll="paper"
          onClose={this.props.onClose}
          //aria-labelledby="dialog-title"
          maxWidth="sm"
          fullWidth={true}
        >
          <DialogTitle component="h1"> <Typography variant="h3" component="div">{titleContent}</Typography></DialogTitle>
          <div style={{height:'0px'}}>
      <Tooltip title="Close">
      <IconButton style={{float:'right',top:'-50px',marginRight:'8px'}}
          aria-label="close" autoFocus
          onClick={this.closeHandler}
        >
          <CloseIcon />
        </IconButton>
        </Tooltip>
        </div>
    
          <DialogContent>

          <Typography variant="h5" component="h2">{cabiURLLabel}
          <Typography  component="span">{` ${this.state.cabiUrl}`}</Typography> </Typography>
         
         
         {dialogContent} </DialogContent>

          <DialogActions>
            <FlatButton
              //OK
              color="primary"
              onClick={this.closeHandler}
            >{CANCEL_CREATE}</FlatButton>

            <FlatButton
              //Save
              color="primary"
              disabled={this.state.isSaveDisabled}
              onClick={this.saveHandler}
            >

              {this.state.isEdit ? SAVE_DASHBOARD : CREATE_DASHBOARD}

            </FlatButton>
          </DialogActions>
        </Dialog>
      );
    }
  }
}


const mapStateToProps = (state, ownProps) => ({
  acl: state.acl,
  saas: state.saas,
  doiurl: state.user.doiurl,
  featureFlags: state.configuration.items,

});

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateDashboardDialog);

     
