// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Home
  content["Home"] =
  "ホーム";

  //Alarms
  content["Alarms"] =
  "アラーム";

  //Groups
  content["Groups"] =
  "グループ";

  //Inventory
  content["Inventory"] =
  "インベントリ";

  //Dashboards
  content["Dashboards"] =
  "ダッシュボード";

  //Reports
  content["Reports"] =
  "レポート";

  //Settings
  content["Settings"] =
  "設定";

  //Setup Wizard
  content["Setup Wizard"] =
  "セットアップ ウィザード";

  //Community
  content["Community"] =
  "コミュニティ";

  //Help
  content["Help"] =
  "ヘルプ";

  // END OF LOCALIZATION

export default content;
