import * as React from 'react';
import { SvgIcon } from '@mineral/core';

export const OutlinedStar02 = React.forwardRef(
  (props, ref) => (
    <SvgIcon data-testid="LearnIcon" viewBox="0 0 40 40" {...props} ref={ref}>
      <path d="M20 9.76308L22.4457 16.3726L22.7649 17.2351L23.6274 17.5543L30.2369 20L23.6274 22.4457L22.7649 22.7649L22.4457 23.6274L20 30.2369L17.5543 23.6274L17.2351 22.7649L16.3726 22.4457L9.76308 20L16.3726 17.5543L17.2351 17.2351L17.5543 16.3726L20 9.76308Z" fill={props.color} stroke="#1D5BBF" stroke-width="4"/>

    </SvgIcon>
  )
);
