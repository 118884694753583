import {
  SET_GROUP_SORT_PROP,
  GROUP_SORT_TYPES
} from './actionTypes'

//const groupSorter = (state = {prop: GROUP_SORT_TYPES.SEVERITY, order: 'desc' }, action) => {
// changing default column to Name n order to asc DE539284  
const groupSorter = (state = {prop: GROUP_SORT_TYPES.NAME, order: 'asc' }, action) => {
  switch (action.type) {
  case SET_GROUP_SORT_PROP:
    let props = {
      prop: action.prop,
      order: action.order
    }
    return props
  default:
    return state
  }
}

export default groupSorter
