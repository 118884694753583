import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import axios from "axios";
import {CircularProgress} from '@mineral/core';
import ListDashboardDesignerComponent from '@uim/listdesigner';
import connect from '../../../../utils/connect';
import { setBreadCrumbs } from '../../../breadcrumbs/actions';
import Resource from './Resource';

//List Dashboard Designer
export const title = Resource.get('List Viewer'); //List Viewer

class ListDashboardDesignerWrapper extends Component {
  state = {
    sid: null,
  };

  componentWillMount = () => {
    this.setState({ sid: this.props.sid });
  };

  componentDidMount() {
    this.props.setBreadCrumbs([
      {
        //Settings
        name: Resource.get('Settings'),
        link: '/settings',
      },
      {
        name: title,
        link: '/settings/listviewer',
      },
    ]);
  };

  render() {
    let view = (
      <div
        className="spinner"
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress style={{ color: '#3272D9' }} />
      </div>
    );

    if (this.state.sid) {
      let do_heartbeat = window.OPERATOR_CONSOLE.do_heartbeat;

      if (do_heartbeat !== 'true' && do_heartbeat !== 'false') {
        do_heartbeat = false;
      }

      view = (
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontWeight: 700,
          }}
        >
          <ListDashboardDesignerComponent
            axiosInterceptors={axios.default.interceptors}
            sid={this.state.sid}
            viewType='viewer'
            copyURL={this.props.copyURL}
          />
        </div>
      );
    }

    return view;
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setBreadCrumbs: (items) => dispatch(setBreadCrumbs(items)),
  };
};

const mapStateToProps = (state) => {
  return {
    sid: state.sid,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ListDashboardDesignerWrapper));
