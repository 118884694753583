import connect from './../../../../utils/connect'
import ToggleIcons from './ToggleIcons'

function mapStateToProps(state) {
  return {
    selectedEntity: state.entity,
    saas:state.saas,
    doiurl:state.user.doiurl,
    featureFlags:state.configuration.items
  }
}

export default connect(mapStateToProps)(ToggleIcons)