import React, { useState, useCallback, useEffect } from 'react'
import QosChart from './QosChart'
import { useDispatch, useSelector } from 'react-redux';
import {
	updateChartListOrder,
} from "../../../../../../../api/performanceReports/actions";

export function swapArrayLocs(arr, index1, index2) {
    var temp = arr[index1];
  
    arr[index1] = arr[index2];
    arr[index2] = temp;
}

const QosChartContainer =(props)=>{
    const dispatch = useDispatch();
    const source = useSelector((state) =>state.performanceReports.selectedSource=='host'?state.performanceReports.byHost.host:undefined)
    const qosValue = useSelector((state) =>state.performanceReports.selectedSource=='host'?state.performanceReports.byHost.qos:state.performanceReports.byTarget.qos)
    const target = useSelector((state) =>state.performanceReports.selectedSource=='target'?state.performanceReports.byTarget.target:undefined)
    const [orderCharList, setOrderChartList] = useState([]);


    function preparedChartList(newChartList) {
        const newOrderCharList = [];
        // orderCharList.forEach((idChart) => {
        //     if (newChartList.find(chart => idChart === chart.chartId)) {
        //         newOrderCharList.push(idChart);
        //     }
        // });
        newChartList.reverse().forEach(chart => {
            if (!newOrderCharList.includes(chart.chartId)) {
                newOrderCharList.unshift(chart.chartId);
            }
        })
        setOrderChartList(newOrderCharList);
    }

    function getCharts() {
        const preparedChartList = [];
        orderCharList.forEach(id => {
            const chart = props.chartList.find(newChart => newChart.chartId === id);
            if (chart) {
                preparedChartList.push(chart);
            }
        })
        return preparedChartList;
    }

    const moveChart = useCallback((dragIndex, hoverIndex) => {
        const prevOrderList = [...orderCharList]
        swapArrayLocs(prevOrderList, dragIndex, hoverIndex);
        setOrderChartList(prevOrderList);
        dispatch(updateChartListOrder(prevOrderList));
    }, [orderCharList]);

    useEffect(() => {
        // setChartList(props.chartList)
        preparedChartList(props.chartList)
    }, [props.chartList])

    return(
        <div className="chart-container">
        {
            getCharts().map((chart, index)=>
            <QosChart 
            key={`qos-chart-key-${chart.chartId}`} 
            chartData={chart} 
            data={{source,qosValue,target}}
            onQosChartDelete={()=>{props.onQosChartDelete(chart.chartId)}}
            moveChart={moveChart}
            index={index}
            chartList={props.chartList}
            changeLoading= {props.changeLoading}
            />
            )}
            
            </div>
            )
        }
        
        export default QosChartContainer;