import React from 'react'
import { Route } from 'react-router-dom'
import Cabi from './../components/cabi/UimCabiRedux'

const CabiRoute = () => {
  return (
    <Route path='/uim-cabi' component={Cabi}/>
  )
}

export default CabiRoute;