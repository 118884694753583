import React from "react";
import { FormControlLabel, Checkbox, Typography, Box } from "@mineral/core";
import { DataGrid, GridToolbarContainer, GridToolbarExport, GridToolbarDensitySelector } from "@mui/x-data-grid";
import { strokeColors } from './dataset'
import { Surface, Symbols } from "recharts";
import CalendarPicker from './CalendarPicker';
import CustomizedDot from "./CustomPlotPoint";
import { FilledCircle } from "./shapeSVG/FilledCircle";
import "./CustomLegend.less";
import { Tooltip } from '@mineral/core';

function CustomToolbar() {
  return (
    <GridToolbarContainer align="right">
      {/* <GridToolbarExport /> */}
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  );
}

const CustomLegend = (props) => {
  const [isColumnVisible, setColumnVisibility] = React.useState(false)
  const [columnsNumber,setColumnsNumbers]= React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [sortObj,setSortObj]=React.useState([{ "field": localStorage.getItem("sortFieldMetric")===null?"name":localStorage.getItem("sortFieldMetric"), "sort": localStorage.getItem("sortOrderMetric")!==null?localStorage.getItem("sortOrderMetric"):"desc" }]);
  React.useEffect(()=>{

    if(localStorage.getItem("sortFieldMetric")!==null && localStorage.getItem("sortOrderMetric")!==null)
    {
     setSortObj([{ "field": localStorage.getItem("sortFieldMetric"), "sort": localStorage.getItem("sortOrderMetric") }]);    
     //console.log("sortObj",{ "field": localStorage.getItem("sortField"), "sort": localStorage.getItem("sortOrder") });
    }   
 
   },[])
  React.useEffect(() => {
    if (props.data) {
      setLoading(false);
    }
    else {
      setLoading(true);
    }
    if(props.columns===null || parseInt(props.columns)===1 || parseInt(props.columns)===undefined)
    setColumnsNumbers(0);
    else
    setColumnsNumbers(parseInt(props.columns)-1);    
  }, [props.data])

  
  React.useEffect(() => {
    setTimeout(() => {
      const headerCell = document.getElementsByClassName('MuiDataGrid-columnHeader MuiDataGrid-columnHeader--sortable legend-header-color')
      for(let i = 0; i< headerCell.length; i++){
        if(headerCell[i]){
          headerCell[i].tabIndex = -1;
        }
      }
    }, 1000)
  }, [])

  const fieldWidth = [{
    color:80,
    name:1100,
    value:250,
  },{
    color:40,
    name:500,
    value:60,
  },{
    color:30,
    name:280,
    value:60,
  },{
    color:30,
    name:235,
    value:60,
  },{
    color:80,
    name:650,
    value:115,
  }]

  const columns= [
    {
        field: "color",
        headerName: "",
        headerClassName: 'legend-header-color',
        width: 40,
        editable: true,
        renderCell: (cellValues) => {
          const i = cellValues.row.index;
          const width = props.selectedCombos[i]?.icon === 'filledCircle' ? 25 : 15;
          const height = props.selectedCombos[i]?.icon === 'filledCircle' ? 25 : 15;
          return (
            <Box>
              <CustomizedDot color={props.selectedCombos[i]?.color} shape={props.selectedCombos[i]?.icon} width={width} height={height} />
            </Box>
            // <FilledCircle color='#000' width='5px' height='5px' />
          )
        },
    },
    {
        field: "name",
        headerName: "Name",
        headerClassName: 'legend-header',
        flex:1,
        editable: false,
        renderCell: (cellValues) => {
            return <Tooltip title={cellValues.value}><div style={{ textOverflow: "ellipsis", width: "90%", overflow: "hidden" }}>{cellValues.value}</div></Tooltip>
        }
    },
    {
        field: "ratio",
        headerName: "Value",
        headerClassName: 'legend-header',
        width: 125,
        editable: false,
        renderCell: (cellValues) => {
            return <Tooltip title={cellValues.value}><div style={{ textOverflow: "ellipsis", width: "90%", overflow: "hidden" }}>{cellValues.value}</div></Tooltip>
        }
    },

];
  
  const actionHandler = () => {
    setColumnVisibility(!isColumnVisible);
  }
  return (
    <Box display={'flex'} flexDirection={'column'} sx={{ height: '100%' }}>
      {props.isDatepickerDisplay===undefined && <CalendarPicker referenceData={props.refLineData} setRefLineData={props.setRefLineData} minTime={props.startTime} maxTime={props.endTime} aggregationValue={props.aggregationValue} />}

      {loading && (<Typography>Loading...</Typography>)}
      {!loading && (
        <DataGrid        
        rows={props.data.deviceData.map((device, index) => {
          let displayedRatio;
          if(props.aggregationValue===-1)
          displayedRatio = props.data.chartDefinationRaw[`${device.deviceName}-${device.ciName}`]?.filter((sample) => sample.ts === props.referenceData)[0];
          else
          displayedRatio = props.data.chartDefination?.filter((sample) => sample.sampleTime === props.referenceData)[0]
          return {
            ...device,
            index: index,
            id: index,
            name: device.deviceName+"-"+device.ciName,
            ratio: displayedRatio ? displayedRatio[`${device.deviceName}-${device.ciName}`] : '-'
          };
        })}
        getRowHeight={() => 30}
        columns={columns}
        rowHeight={20}        
        hideFooter
        disableColumnMenu
        disableSelectionOnClick={false}
        disableColumnSelector
        experimentalFeatures={{ newEditingApi: true }}
        sx={{
          marginTop: '8px',
          '& .MuiDataGrid-columnHeaders': {
            minHeight: '30px !important'
          },
          '& .MuiDataGrid-virtualScroller': {
            marginTop: '35px !important'
          }
        }}
       
        onSortModelChange={(model)=>{
          if(model.length>0 && model[0].field!=="" && model[0].sort!=="")
          {            
          localStorage.setItem("sortFieldMetric", model[0].field);
          localStorage.setItem("sortOrderMetric", model[0].sort);
          }      
        }}
    
        initialState={{
          
          sorting: {
            sortModel: sortObj,
          },
        }}
    
    
      />
      
      )}
    </Box>
  );
};

export default CustomLegend;
