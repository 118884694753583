import * as actions from './actionTypes'
import slack from './slack'
import * as IntegrationError from './../../../components/settings/integrations/IntegrationError';

export function fetchConfig() {
  return {
    type:actions.FETCH_SLACK_CONFIG
  }
}

export function fetchConfigSuccess(json) {
  return {
    type:actions.FETCH_SLACK_CONFIG_SUCCESS,
    config:json
  }
}

export function fetchConfigFailure(error) {
  return {
    type:actions.FETCH_SLACK_CONFIG_FAILURE,
    responseError:error
  }
}

export function saveConfig() {
  return {
    type:actions.SAVE_SLACK_CONFIG
  }
}

export function saveConfigSuccess(json) {
  return {
    type:actions.SAVE_SLACK_CONFIG_SUCCESS
  }
}

export function saveConfigFailure(error) {
  return {
    type:actions.SAVE_SLACK_CONFIG_FAILURE,
    responseError:error
  }
}

export function deleteConfig() {
  return {
    type:actions.DELETE_SLACK_CONFIG
  }
}

export function deleteConfigSuccess(json) {
  return {
    type:actions.DELETE_SLACK_CONFIG_SUCCESS
  }
}

export function deleteConfigFailure(error) {
  return {
    type:actions.DELETE_SLACK_CONFIG_FAILURE,
    responseError:error
  }
}

function getResponseError(error) {
  if(error.response.data && error.response.data.message) {
    return error.response.data;
  } else {
    return IntegrationError.getDefaultError();
  }
}

export function get() {
  return dispatch => {
    dispatch(fetchConfig());
    return slack.get()
       .then((response) => {
          dispatch(fetchConfigSuccess(response.data));
                    })
       .catch(function(error) {
          dispatch(fetchConfigFailure(getResponseError(error)))
                    })
  }
}

export function save(config, successCallback) {
  return dispatch => {
    dispatch(saveConfig());
    return slack.save(config)
                    .then((response) => {
                      dispatch(saveConfigSuccess());
                      if(successCallback) {
                        successCallback();
                      }
                    })
                    .catch(function(error) {
                      dispatch(saveConfigFailure(getResponseError(error)));
                    })
  }
}

export function deleteSlackConfig(config, successCallback, failureCallback) {
  return dispatch => {
    dispatch(deleteConfig());
    return slack.deleteConfig(config)
                    .then((response) => {
                      dispatch(deleteConfigSuccess());
                      if(successCallback) {
                        successCallback();
                      }
                    })
                    .catch(function(error) {
                      dispatch(deleteConfigFailure(getResponseError(error)));
                      if(failureCallback) {
                        failureCallback();
                      }
                    })
  }
}
