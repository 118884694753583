import React from 'react'
import InfoIcon from './../icons/Info'
import './info.less'

const Info = (props) => {
  return (
    <article className='info' title={props.title}>
      <InfoIcon className='info__icon icon--info'/>
      <section className='info__section'>
        <header className='info__header'>{props.title}</header>
        <p className='info__message'>{props.message}</p>
      </section>
    </article>
  )
}

// Info.propTypes ={
//   title: PropTypes.string,
//   message: PropTypes.string,
// }
Info.defaultProps = {
  title: 'No data found',
  message: null,
}

export default Info
