import React from "react";

import {TextField,FormControl,InputLabel} from "@mineral/core";
import { makeStyles } from '@mui/styles'
import prd from "../../../../../../../api/performanceReports/prd";
import "../PerformanceLayoutSelection.less";
import PerformanceLayoutSelect from "../PerformanceLayoutSelect";
import PerformanceLayoutCheckboxes from "../PerformanceLayoutCheckboxes";
import { useDispatch, useSelector } from "react-redux";
import {
  getHostValueByHost,
  getQosValueByHost,
  getTargetsByHost,
} from "../../../../../../../api/performanceReports/actions";

const useStyles = makeStyles((theme) => ({
  textField: {
    width: "100%",
  },
}));

const PerformanceLayoutHost = (props) => {
  const classes = useStyles();

  const hostValue = useSelector(
    (state) => state.performanceReports.byHost.host
  );
  const qosValue = useSelector((state) => state.performanceReports.byHost.qos);
  const selectedTarget = useSelector(
    (state) => state.performanceReports.byHost.targets
  );
  const dispatch = useDispatch();

  const [openHost, setOpenHost] = React.useState(false);
  const [optionsHost, setOptionsHost] = React.useState([]);
  const loadingHost = openHost && optionsHost.length === 0;
  const urlHost = prd.getQosSource();
  const nameHost = "Host";
  const idHost = "Host-host";
  const placeholderHost = "Search host or select from list";

  const [openQoS, setOpenQoS] = React.useState(false);
  const [optionsQoS, setOptionsQoS] = React.useState([]);
  const loadingQoS = openQoS && optionsQoS.length === 0;
  const urlQoS = prd.getQosListBySource(hostValue);
  const nameQoS = "QoS";
  const idQos = "QoS-host";
  const placeholderQoS = "Search QoS or select from list";

  const [openTarget, setOpenTarget] = React.useState(false);
  const [optionsTarget, setOptionsTarget] = React.useState([]);
  const [searchTargets, setSearchTargets] = React.useState(optionsTarget);
  const urlTarget = prd.gettargetListBySourceAndQos(hostValue, qosValue);
  const nameTarget = "Target";
  const idTarget = "Target-host";
  const placeholderTarget = "Search Target or select from list";

  const resetQoSValueHandler = (value) => {
    dispatch(getQosValueByHost(value));
  };

  React.useEffect(() => {
    props.isSelectedTarget(selectedTarget);
    props.isQosValue(qosValue);
  }, [selectedTarget, qosValue, dispatch]);

  return (
    <>
      <div style={{ padding: "10px 10px 1px 10px" }}>
        <PerformanceLayoutSelect
          open={openHost}
          setOpen={setOpenHost}
          loading={loadingHost}
          options={optionsHost}
          setOptions={setOptionsHost}
          name={nameHost}
          url={urlHost}
          placeholder={placeholderHost}
          id={idHost}
          selectedValue={(value) => dispatch(getHostValueByHost(value))}
          value={hostValue}
          resetNextValue={resetQoSValueHandler}
          key={1}
        />

        <PerformanceLayoutSelect
          open={openQoS}
          setOpen={setOpenQoS}
          loading={loadingQoS}
          options={optionsQoS}
          setOptions={setOptionsQoS}
          name={nameQoS}
          url={urlQoS}
          placeholder={placeholderQoS}
          id={idQos}
          disabled={!hostValue}
          selectedValue={(value) => dispatch(getQosValueByHost(value))}
          value={qosValue}
          key={2}
        />

        {!qosValue && (
          <FormControl fullWidth={true} style={{marginBottom:'8px'}}>
            <InputLabel>{nameTarget}</InputLabel>
            <TextField
             fullWidth 
              disabled={!qosValue}
              variant="outlined"
              placeholder={placeholderTarget}
             
            />
          </FormControl>
        )}

        {qosValue && (
          <PerformanceLayoutCheckboxes
            placeholder={placeholderTarget}
            url={urlTarget}
            name={nameTarget}
            open={openTarget}
            setOpen={setOpenTarget}
            options={optionsTarget}
            setOptions={setOptionsTarget}
            value={qosValue}
            selected={selectedTarget}
            setSelected={(value) => dispatch(getTargetsByHost(value))}
            search={searchTargets}
            setSearch={setSearchTargets}
          />
        )}
      </div>
    </>
  );
};

export default PerformanceLayoutHost;
