import {
    REQUEST_AGENTS,
    REQUEST_AGENTS_STATUS,
    FETCH_AGENTS_SUCCESS,
    FETCH_AGENTS_FAILURE,
    FETCH_AGENTS_STATUS_SUCCESS,
    FETCH_AGENTS_STATUS_FAILURE,
    SELECTED_AGENT,
    GET_JOBS_RUNNING,
    FETCH_JOBS_RUNNING_SUCCESS,
    FETCH_JOBS_RUNNING_FAILURE,
    CUSTOM_SCOPE_DISCOVERY,
} from './actionTypes'
import isEqual from 'lodash/isEqual'

const agents = (state ={
isJobCountFetching: false,
isAgentsFetching:false,
isAgentsStatusFetching:false,
items:[],
status:[],
runningJobsCount:0,
selectedAgent:null,
selectedAgentCustomScope:null,
didInvalidate:false,
selectedScope: [],
},action)=>{
 switch(action.type){
     case GET_JOBS_RUNNING:
        return Object.assign({}, state, {
            isJobCountFetching: true,
            didInvalidate: false
        });

    case FETCH_JOBS_RUNNING_SUCCESS:
        const runningJobsCount = action.jobsCount
        const updatedCountState =  Object.assign({}, state, {
            isJobCountFetching: false,
            didInvalidate: false,
            runningJobsCount:runningJobsCount
        });
        return isEqual(state,updatedCountState)?state:updatedCountState
     
    case FETCH_JOBS_RUNNING_FAILURE:
        return Object.assign({}, state, {
            isJobCountFetching: false,
            didInvalidate: true,
            errorMessage: action.errorMessage,
            runningJobsCount: 0
        });
    
     case REQUEST_AGENTS:
        return Object.assign({}, state, {
            isAgentsFetching: true,
            didInvalidate: false
        });
     case FETCH_AGENTS_SUCCESS:
        const items = action.agents

        const updatedAgentState =  Object.assign({}, state, {
            isAgentsFetching: false,
            didInvalidate: false,
            items:items
        });
        return isEqual(state,updatedAgentState)?state:updatedAgentState
     case FETCH_AGENTS_FAILURE:
     return Object.assign({}, state, {
        isAgentsFetching: false,
        didInvalidate: true,
        errorMessage: action.errorMessage,
        items: []
      });
     case REQUEST_AGENTS_STATUS:
        return Object.assign({}, state, {
            isAgentsStatusFetching: true,
            didInvalidate: false
        });
     case FETCH_AGENTS_STATUS_SUCCESS:
      const status = action.status

      const updatedStatusState =  Object.assign({}, state, {
            isAgentsStatusFetching: false,
            didInvalidate: false,
            status:status
        });
        return isEqual(state,updatedStatusState)?state:updatedStatusState
     case FETCH_AGENTS_STATUS_FAILURE:
     return Object.assign({}, state, {
        isAgentsStatusFetching: false,
        didInvalidate: true,
        errorMessage: action.errorMessage,
        status: []
      });
     case SELECTED_AGENT:
     return Object.assign({}, state, {
        selectedAgent:action.selectedAgent,
      });
     case CUSTOM_SCOPE_DISCOVERY:
     return Object.assign({}, state, {
        selectedAgentCustomScope:action.selectedAgentCustomScope
      });
     
     default:
     return state
 }
}

export default agents;
