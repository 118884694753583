import connect from './../../utils/connect'
import { setBreadCrumbs } from './../breadcrumbs/actions'
import { saveRobots } from './../../api/robots/actions'
import { setSelectedHub } from '../../api/hubs/actions'
import { 
  fetchRobotsStatus, 
  resetRobotsStatus 
} from '../../api/robotsStatus/actions'
import {
  setClientRobots,
  setAdeRobotAddress,
  setAdeJobIds,
} from './clientRobots/actions'
import RobotDeploy from './RobotDeploy'

const mapStateToProps = (state) => {
  return {
    isCreating: state.robots.isCreating,
    didInvalidateCreate: state.robots.didInvalidateCreate,
    clientRobots: state.clientRobots.robots,
    robotsStatus:state.robotsStatus.status,
    probesStatus:state.robotsStatus.probesStatus,
    probeJobIds:state.robotsStatus.probeJobIds,
    selectedHub:state.hubs.selectedHub
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    setBreadCrumbs: (items) => dispatch(setBreadCrumbs(items)),
    setRobots: (robots) => dispatch(setClientRobots(robots)),
    setAdeRobotAddress: (adeRobotAddress) => dispatch(setAdeRobotAddress(adeRobotAddress)),
    setAdeJobIds: (adeJobIds) => dispatch(setAdeJobIds(adeJobIds)),
    saveRobots: (data) => dispatch(saveRobots(data)),
    getRobotsStatus: (adeRobotAddress, adeJobIds) =>
      dispatch(fetchRobotsStatus({
        adeRobotAddress: adeRobotAddress,
        adeJobIds: adeJobIds,
      })),
    resetRobotsStatus: () => {
      dispatch(resetRobotsStatus())
    },
    setSelectedHub:selectedHub => 
     dispatch(setSelectedHub(selectedHub)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(RobotDeploy)