// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Groups
  content["Groups"] =
  "Groups";

  //Inventory
  content["Inventory"] =
  "Inventory";

  // END OF LOCALIZATION

export default content;
