// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Select what you would like to monitor...
  content["Select what you would like to monitor..."] =
  "é€‰æ‹©æ‚¨è¦�ç›‘æŽ§çš„å¯¹è±¡...";

  //For more information, click
  content["For more information, click"] =
  "æœ‰å…³è¯¦ç»†ä¿¡æ�¯ï¼Œè¯·å�•å‡»";


  //Setup Wizard
  content["Setup Wizard"] =
  "å®‰è£…å�‘å¯¼";

  //HELP
  content["HELP"] =
  "å¸®åŠ©";

  //Help
  content["Help"] =
  "å¸®åŠ©";

  //ADD
  content["ADD"] =
  "æ·»åŠ ";

  //CONFIG
  content["CONFIG"] =
  "é…�ç½®";

  //Cancel
  content["Cancel"] =
  "å�–æ¶ˆ";

  //PREVIOUS
  content["PREVIOUS"] =
  "ä¸Šä¸€æ­¥";

  //next
  content["next"] =
  "ä¸‹ä¸€æ­¥";

  //FINISH
  content["FINISH"] =
  "å®Œæˆ�";

  // END OF LOCALIZATION

export default content;
