// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Add
  content["Add"] =
  "Agregar";

  //Service Name
  content["Service Name"] =
  "Nombre del servicio";

  //Settings
  content["Settings"] =
  "Configuración";

  //PagerDuty
  content["PagerDuty"] =
  "PagerDuty";

  //CANCEL
  content["CANCEL"] =
  "CANCELAR";

  //DELETE
  content["DELETE"] =
  "SUPRIMIR";

  //This action removes the PagerDuty integration
  content["This action removes the PagerDuty integration"] =
  "Esta acción elimina la integración de PagerDuty.";

  //help message
  content["Alarms will no longer be synchronized between CA Infrastructure Management and PagerDuty."] =
  "Las alarmas dejarán de sincronizarse entre CA Infrastructure Management y PagerDuty.";

  //Delete Integration?
  content["Delete integration?"] =
  "¿Suprimir integración?";

  //Communication Error
  content["Communication Error"] =
  "Error de comunicación";

  //API Key message
  content["PagerDuty generated API key"] =
  "Clave de API generada por PagerDuty";

  //Service Name message
  content["Service name"] =
  "Nombre del servicio";

  //help message
  content[" Create new PagerDuty incidents when Infrastructure Management generates alarms with the following severities:"] =
  " Cree nuevos incidentes de PagerDuty cuando Infrastructure Management genere las alarmas con las severidades siguientes:";

  //Trigger incident creation
  content["Trigger incident creation"] =
  "Activar la creación del incidente";

  //Immediatley
  content["Immediatley"] =
  "Inmediatamente";

  //After 5 minutes
  content["After 5 minutes"] =
  "Tras 5 minutos";

  //After 10 minutes
  content["After 10 minutes"] =
  "Tras 10 minutos";

  //After 15 minutes
  content["After 15 minutes"] =
  "Tras 15 minutos";

  //After 30 minutes
  content["After 30 minutes"] =
  "Tras 30 minutos";

  //help message
  content["Adding a delay may resolve \'flapping\' a situation where alarms open and auto-close in a short time frame."] =
  "La agregación de un retraso puede resolver el error de visualización inconsistente, una situación donde las alarmas se abren y cierran automáticamente en un período de tiempo corto.";

  // Pager Duty Message
  content["To integrate PagerDuty with Infrastructure Management, you must first open an account and generate an API key for use in the configuration window. The following link describes how to generate an API key."] =
  "Para integrar PagerDuty con Infrastructure Management, primero debe abrirse una cuenta y generar una clave de API para utilizarse en la ventana de configuración. El vínculo siguiente describe cómo se debe generar una clave de API.";

  //Help Message
  content["Help"] =
  "Ayuda";

  //Note message
  content["Note: To enable this functionality, the UIM server must have access to the internet"] =
  "Nota: Para activar esta funcionalidad, el servidor de UIM debe tener acceso a Internet.";

  //PagerDuty Integration
  content["PagerDuty Integration"] =
  "Integración de PagerDuty";

  //Critical
  content["Critical"] =
  "Crítica";

  //Major
  content["Major"] =
  "Grave";

  //Minor
  content["Minor"] =
  "Leve";

  //Warning
  content["Warning"] =
  "Advertencia";

  //Info
  content["Info"] =
  "Información";

  //instruction message
  content["When an alarm is closed in Infrastructure Management, resolve the associated incident in PagerDuty."] =
  "Cuando se cierra una alarma en Infrastructure Management, se resuelve el incidente asociado en PagerDuty.";

  //SAVE
  content["SAVE"] =
  "GUARDAR";

  // END OF LOCALIZATION

export default content;
