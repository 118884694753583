import connect from './../../../utils/connect'
import CredentialVaultCatalog from './CredentialVaultCatalog'
import {getWMI,pollWmiCredStart,pollWmiCredStop,deleteWmiCred,updateWmiCred,addWmiCred,getSNMP,pollsnmpCredStart,pollsnmpCredStop,addSNMP,updateSNMPCred,deleteSNMPCred}  from './../../../api/DeviceDiscovery/credentials/actions';
import {getUNIX,pollUnixCredStart,pollUnixCredStop,deleteUnixCred,updateUnixCred,addUnixCred}  from './../../../api/DeviceDiscovery/unixCredentials/actions';


const mapStateToProps = state => {
  return {
	  catalogItems: state.credentials.items,
	  wmiCredsIsFetching: state.credentials.isFetching,
	  unixCredItems : state.unixCredentials.unixItems,
    unixCredsIsFetching : state.unixCredentials.isFetching,
    snmpCredItems:state.credentials.sncmpItems
    
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getSNMP:() =>dispatch(getSNMP()),
    getWMI: () => dispatch(getWMI()),
    getUNIX: () => dispatch(getUNIX()),
    pollWmiCredStart: () => dispatch(pollWmiCredStart()),
    pollWmiCredStop: () => dispatch(pollWmiCredStop()),
    pollsnmpCredStart: () => dispatch(pollsnmpCredStart()),
    pollsnmpCredStop: () => dispatch(pollsnmpCredStop()),
    pollUnixCredStart: () => dispatch(pollUnixCredStart()),
    pollUnixCredStop: () => dispatch(pollUnixCredStop()),
    deleteWmiCred:deletedItem =>(dispatch(deleteWmiCred(deletedItem))),
    updateWmiCred:credObject =>(dispatch(updateWmiCred(credObject))),
    addWmiCred:credObject =>(dispatch(addWmiCred(credObject))),
    deleteUnixCred:deletedItem =>(dispatch(deleteUnixCred(deletedItem))),
    updateUnixCred:credObject =>(dispatch(updateUnixCred(credObject))),
    addUnixCred:credObject =>(dispatch(addUnixCred(credObject))),
    addSNMP:credObject => (dispatch(addSNMP(credObject))),
    deleteSNMPCred:deletedItem =>(dispatch(deleteSNMPCred(deletedItem))),
    updateSNMPCred:credObject =>(dispatch(updateSNMPCred(credObject))),
    
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CredentialVaultCatalog)
