import {
  REQUEST_ROBOTS,
  FETCH_ROBOTS_SUCCESS,
  FETCH_ROBOTS_FAILURE,
  CREATE_ROBOTS,
  CREATE_ROBOTS_SUCCESS,
  CREATE_ROBOTS_FAILURE,
  DEPLOY_ROBOTS,
  DEPLOY_ROBOTS_SUCCESS,
  DEPLOY_ROBOTS_FAILURE,
} from './actionTypes'
import isEqual from 'lodash/isEqual'

const robots = (state = {
  isFetching: false,
  didInvalidate: false,
  isCreating: false,
  didInvalidateCreate: false,
  items: [],
  isDeploying: false,
  didInvalidateDeploy: false,
  itemsDeployed: [],
  is_post_deployment_done:false,
}, action) => {
  switch (action.type) {
    case REQUEST_ROBOTS:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false
      });
    case FETCH_ROBOTS_SUCCESS:
      const items = action.robots._items
      const updatedState = Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        items: items
      });
      return isEqual(updatedState, state) ? state : updatedState
    case FETCH_ROBOTS_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        errorMessage: action.errorMessage,
        items: []
      });
    case CREATE_ROBOTS:
      return Object.assign({}, state, {
        isCreating: true,
        didInvalidateCreate: false
      });
    case CREATE_ROBOTS_SUCCESS:
      const created = action.robots._items
      const updatedStateCreate = Object.assign({}, state, {
        isCreating: false,
        didInvalidateCreate: false,
        items: created
      });
      return isEqual(updatedStateCreate, state) ? state : updatedStateCreate
    case CREATE_ROBOTS_FAILURE:
      return Object.assign({}, state, {
        isCreating: false,
        didInvalidateCreate: true,
        errorMessage: action.errorMessage,
        items: []
      });
    case DEPLOY_ROBOTS:
      return Object.assign({}, state, {
        isDeploying: true,
        didInvalidateDeploy: false,
        is_post_deployment_done:false,
      });
    case DEPLOY_ROBOTS_SUCCESS:
      const deployed = action.robots._items
      const updatedStateDeploy = Object.assign({}, state, {
        isDeploying: false,
        didInvalidateDeploy: false,
        itemsDeployed: deployed,
        is_post_deployment_done:true,
      });
      return isEqual(updatedStateDeploy, state) ? state : updatedStateDeploy
    case DEPLOY_ROBOTS_FAILURE:
      return Object.assign({}, state, {
        isDeploying: false,
        didInvalidateDeploy: true,
        is_post_deployment_done:true,
        errorMessage: action.errorMessage,
        itemsDeployed: []
      });
    default:
      return state
  }
};

export default robots
