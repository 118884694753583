import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Dialog, DialogTitle, Button as FlatButton,Typography,Tooltip,IconButton,DialogContent } from "@mineral/core";
import Resource from "./Resource";
import { DialogActions } from "@mineral/core";
import "./dialogButtonStyle.less";
import {

  ClearIcon as Clear,
} from "../ui-components/uim-components";

class DeleteSubgroupDialog extends Component {
  state = {
    disableDeleteButton: false,
  };
  static propTypes = {
    history: PropTypes.object.isRequired,
  };
  componentWillReceiveProps() {
    this.setState({
      disableDeleteButton: false,
    });
  }
  _updateRoute = (parentGroupId) => {
    const history = this.props.history;
    const pathname = history.location.pathname;
    const listViewPath =!history.location.pathname.endsWith("card")
      ? ""
      : "/card";
    // new path is just the current path minus the last id
    let path = pathname.slice(0, pathname.lastIndexOf(`/${parentGroupId}`));
    path = listViewPath
      ? `${path}/${parentGroupId}`
      : `${path}/${parentGroupId}/card`;
    if (this.props.location.pathname.search("treeviewnew") < 0) {
      history.push(path);
      this.props.history.replace(path);
    } else {
      sessionStorage.setItem("groupDeleted", true);
      this.props.refreshTree();
    }
  };

  render() {
    let actions = [
      <FlatButton
        variant="text"
        //Cancel
        children={Resource.get("Cancel")}
        color="primary"
        onClick={this.onClose}
      />,
      <FlatButton
        
        //Delete
        children={Resource.get("Delete")}
        color="error"
        variant="contained" 
                onClick={this.handleDelete}
        disabled={this.state.disableDeleteButton}
      />,
    ];
    //Delete Group
    let title = Resource.get("Delete Group");
    //Your individual devices will not be deleted.
    const helplabelmessage = Resource.get(
      " Your individual devices will not be deleted. "
    );
    let content = (
      <div
        style={{
          fontSize: "16px",
          padding: "0px 24px 24px",
        }}
      >
        <p>
          This action removes the '{this.props.selectedName}' group from
          Infrastructure Management.
        </p>
        <p>{helplabelmessage} </p>
      </div>
    );

    return (
      <Dialog
        title={title}
        modal={false}
        open={this.props.open}
        onClose={this.onClose}
      >
       <DialogTitle component="div" disableTypography>
         
             
         <Typography component="h1" variant="h4">
         {title}
         </Typography>
      
   </DialogTitle>
   <div style={{height:'0px'}}>

   <Tooltip title={"Close"} style={{float:'right',marginRight:'8px',top:'-50px'}}> 
    <IconButton autoFocus  onClick={this.onClose}>
      <Clear role='figure' />
    </IconButton>
     </Tooltip> 
   </div> 
        <DialogContent >

        {content}
        </DialogContent>
       
        <DialogActions>{actions}</DialogActions>
      </Dialog>
    );
  }
  handleDelete = () => {
    this.setState({
      disableDeleteButton: true,
    });
    this.props.deleteGroup(
      this.props.selectedId,
      this._updateRoute,
      this.props.handleClose,
      this.props.parentId,
      this.props.handleErrorDialogOpen,
      this.props.handleDeleteSnackbar
    );
    sessionStorage.setItem("groupDeleted", true);
    this.props.refreshTree();
  };
  onClose = () => {
    this.props.handleClose("deleteSubGroupOpen");
  };
}

export default withRouter(DeleteSubgroupDialog);
