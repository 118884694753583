import * as React from 'react';
import { SvgIcon } from '@mineral/core';

export const FilledPolygon = React.forwardRef(
  (props, ref) => (
    <SvgIcon viewBox="0 0 40 40" {...props} ref={ref}>
      <path d="M20 4L27.5237 9.64458L35.2169 15.0557L32.1735 23.9554L29.4046 32.9443L20 32.8L10.5954 32.9443L7.82648 23.9554L4.7831 15.0557L12.4763 9.64458L20 4Z" fill={props.color} />
    </SvgIcon>
  )
);

export default FilledPolygon;