import React from 'react'
import { Route } from 'react-router-dom'
import AlarmPoliciesRedux from './../components/alarmPolicies/AlarmPoliciesRedux'
import PolicyRedux from './../components/alarmPolicies/PolicyRedux'
import connect from './../utils/connect'
import AclTypes from './../api/aclTypes'

const AlarmPolicies = props => {
  return (
    <main>
      <AlarmPoliciesRedux {...props} />
    </main>
  )
}

const PolicyComp = props => {
    return (
      
        <PolicyRedux {...props} />
     
    )
  }

const AlarmPoliciesRoute = props => {
  if (!(props.acl.canViewPolicy || props.acl.canManagePolicy)) {
    return null
  }
  return (
    <div>
      <Route exact path="/settings/alarm-policies" component={AlarmPolicies} />
      <Route
        path="/settings/alarm-policies/:policyId"
        component={PolicyComp}
      />
    </div>
  )
}

const mapStateToProps = state => {
  return {
    acl: state.acl,
    sid: state.sid
  }
}

export default connect(mapStateToProps, null)(AlarmPoliciesRoute)
