// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //ok message
  content["OK"] =
  "OK";

  // CSV template help message
  content["A maximum of 50 devices can be imported in a single CSV import. Only the first 50 records were imported."] =
  "A maximum of 50 devices can be imported in a single CSV import. Only the first 50 records were imported.";

  //Import CSV
  content["Import CSV"] =
  "Import CSV";

  // creation help message
  content["To add devices in bulk, create a CSV file with the following information for each robot:"] =
  "To add devices in bulk, create a CSV file with the following information for each robot:";

  //IP Address
  content["IP Address"] =
  "IP Address";

  // IP4 help message
  content["IPv4 address of system where robot/monitoring agent is to be installed"] =
  "IPv4 address of system where robot/monitoring agent is to be installed";

  //Operating System
  content["Operating System"] =
  "Operating System";

  // Values help message
  content["Values: centos, debian, opensuse, rhel, ubuntu or windows"] =
  "Values: centos, debian, opensuse, rhel, ubuntu or windows";

  //Architecture
  content["Architecture"] =
  "Architecture";

  // Architecture help message
  content["Values: 32 or 64"] =
  "Values: 32 or 64";

  //Username
  content["Username"] =
  "Username";

  // Username help message
  content["Enter username with installation permissions for system where robot is to be installed"] =
  "Enter username with installation permissions for system where robot is to be installed";

  //Password
  content["Password"] =
  "Password";

  // Password help message
  content["Password for username on the robot system"] =
  "Password for username on the robot system";

  //Sudo password
  content["Sudo password"] =
  "Sudo password";

  // Sudo password help message
  content["If username does not have root-level installation permissions, provide sudo password to elevate permissions to root-level for installation"] =
  "If username does not have root-level installation permissions, provide sudo password to elevate permissions to root-level for installation";

  // Note message
  content["Note: Up to 50 devices can be imported from CSV file."] =
  "Note: Up to 50 devices can be imported from CSV file.";

  //Example CSV file
  content["Example CSV file"] =
  "Example CSV file";

  // END OF LOCALIZATION

export default content;
