import * as React from 'react';
import { SvgIcon } from '@mineral/core';

export const FilledStar03 = React.forwardRef(
  (props, ref) => (
    <SvgIcon viewBox="0 0 40 40" {...props} ref={ref}>
      <path d="M20 4L23.0557 14.7073L33.8564 12L26.1115 20L33.8564 28L23.0557 25.2927L20 36L16.9443 25.2927L6.14359 28L13.8885 20L6.14359 12L16.9443 14.7073L20 4Z" fill={props.color} />
    </SvgIcon>
  )
);

export default FilledStar03;