// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Thank you message
  content["Thank you, your sales representative<br />will contact you soon"] =
  "ありがとうございます。販売担当者からすぐに<br />ご連絡します";

  //CLOSE
  content["CLOSE"] =
  "閉じる";

  //Trial Exired
  content["Trial Expired"] =
  "トライアルが期限切れになりました";

  // trial expired message
  content["Unfortunately, your trial period expired."] =
  "トライアルの期限が切れました。";

  //System update available
  content["System update available"] =
  "システム更新利用可能";

  //A new version of the robot software is available.
  content["A new version of the robot software is available."] =
  "ロボット ソフトウェアの新しいバージョンが使用可能です。";

  //Deployment takes about 1 minute, during which time no metrics are collected and no alarms are created.
  content["Deployment takes about 1 minute, during which time no metrics are collected and no alarms are created."] =
  "展開には、約 1 分かかります。その間、メトリクスは収集されず、アラームは作成されません。";

  //LATER
  content["LATER"] =
  "後で";

  //DEPLOY
  content["DEPLOY"] =
  "展開";

  // END OF LOCALIZATION

export default content;
