import CookieManager from './cookieManager'

const load = (
  url,
  libName,
  successCallback = () => {},
  failedCallback = () => {},
) => {
  if (!window[libName]) {
    var OPERATOR_CONSOLE = window.OPERATOR_CONSOLE;
    var nonce = OPERATOR_CONSOLE['cspNonce'];
    var script = document.createElement('script')
    script.type = 'text/javascript'
    script.nonce = nonce
    if (script.readyState) {
      //IE
      script.onreadystatechange = function() {
        if (
          script.readyState === 'loaded' ||
          script.readyState === 'complete'
        ) {
          script.onreadystatechange = null
          successCallback(window[libName])
        } else {
          failedCallback(Error('failed to load:', url))
        }
      }
    } else {
      //Others
      script.onload = function() {
        successCallback(window[libName])
      }
      script.onerror = error => {
        failedCallback(error)
      }
    }
    script.src = url
    document.getElementsByTagName('head')[0].appendChild(script)
  } else {
    successCallback(window[libName])
  }
}


export const getCookie = () => {
  const cookie = CookieManager.getCloudManagerCookie()
  return cookie
}
export const getCookieBaseUrl = () => {
  const cookie = CookieManager.getCloudManagerCookie()
  return cookie && cookie.baseUrl
}
export const loadChatLib = () => {
  let promise = new Promise((resolve, reject) => {
    // const url =
    //   'https://c.la3-c2cs-phx.salesforceliveagent.com/content/g/js/40.0/deployment.js'
    const url = ''
    load(
      url,
      'liveagent',
      liveagent => {
        resolve(liveagent)
      },
      e => {
        console.error('failed to load:', url)
        reject(e)
      },
    )
  })
  return promise
}

export const loadCommon = () => {
  let promise = new Promise((resolve, reject) => {
   // const cookieUrl = getCookieBaseUrl() || 'https://cloud.ca.com'
   // const commonUrl =
     // cookieUrl + '/cloudmanagement/static/js/ca_cloudmanagement.js'
     const commonUrl = ''
    load(
      commonUrl,
      'ca_cloudmanagement',
      ca_cloudmanagement => {
        resolve(ca_cloudmanagement)
      },
      e => {
       // console.error('failed to load:', commonUrl)
        reject(e)
      },
    )
  })
  return promise
}
 const getTime = () => {
  let time={
    toTime:"",
    fromTime:""
  }

  time.toTime=new Date().getTime()
  time.fromTime=time.toTime-(24 * 30 * 3 * 3600*1000)// default last 3 months
  return time
}
export const getAlarmUrl = (doiurl,filters) => {
  let time=getTime()
  let url=doiurl+'/alarms-analytics?sourceProduct=UIM&viewType=allAlarms&fromTime='+time.fromTime+'&toTime='+time.toTime+'&visible=true'
  if(filters)
   url=url+'&'+filters

return url
}
export const getContainerGroupFilters = (item,groupIds,groupNames) => {
  if(item && item.childGroups && item.childGroups.length){
    item.childGroups.filter(function (group) {
      return group.type!=='CONTAINER';
    }).forEach(function (group) {
    let totalalarm=group.alarmCountSummary.critical + group.alarmCountSummary.major + group.alarmCountSummary.minor + group.alarmCountSummary.warning + group.alarmCountSummary.information
    if(totalalarm){
      groupIds.push(group.id)
      groupNames.push(group.name)
    }
    })
  }
  return `groupId=${groupIds.toString()}&group=${groupNames.toString()}`
}
