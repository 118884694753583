import React, { Component } from 'react'
import { InfoIcon} from '../ui-components/uim-components'
import {Dialog, Button as FlatButton,DialogTitle} from "@mineral/core"
import './configurationRedirect.less'
import Resource from './Resource'
import { DialogActions } from '@mineral/core'
import config from "./../../api/config";

class NoRobotRedirect extends Component {
  state = {
    open: this.props.open || false,
  }
  handleClose = () => {
    this.setState({ open: false })
    if (this.props.onClose) {
      this.props.onClose()
    }
  }
  handleOpen = () => {
    this.setState({ open: true })
  }
  render() {
	  // instructions message
	  const helplabelmessage = Resource.get('To add additional monitoring to this device, a local robot must be installed')
	  // instructions message1 
	  const helplabelmessage1 =  Resource.get('Use the Setup Wizard to add local monitoring based on a device\'s operating system: Windows or Linux, and select manual installation.')	
	  //Local robot required
	  const helplabelmessage2 = Resource.get('Local robot required')
	  //Cancel
	  const helplabelmessage3 = Resource.get('Proceed')
	  //Add devices...
	  const helplabelmessage4 = Resource.get('Add Robots...')
    let message = (
      <span style={{marginLeft: 'calc(24px + 1rem)'}}>
        <p>{helplabelmessage}</p>
        <p>
        {helplabelmessage1}
        </p>
      </span>
    )
    if(this.props.message) {
      message = this.props.message
    }
   const actions=[
      <FlatButton
        variant="text"
        children= {helplabelmessage3}
        color="primary"
        onClick={this.handleClose} />,
        (this.props.acl &&
          this.props.acl.isBusUser && this.props.acl.canManageDiscovery)?(
        <FlatButton
          variant="text"
          children= {helplabelmessage4}
          color="primary"       
          href={`${config.basename}/remote-cloud-tiles`}   
           /> ):null
           //href={<Link to='/getting-started'/>}
    ]
    return (
      <Dialog
      /*   title={
          <div className='config-redirect__title'>
            <InfoIcon />
            {helplabelmessage2}
          </div>
        } */
        style={{
          marginLeft: 'calc(24px + 1rem)',
        }}
        modal={true}
        open={this.state.open}
        //actionsContainerClassName='config-redirect__actions'       
        aria-labelledby="dialog-title"
        className= "configurationDialog"
        >
        <DialogTitle id="dialog-title"> {
          <div className='config-redirect__title'>
            <InfoIcon />
            {helplabelmessage2}
          </div>
        }</DialogTitle>
          
        {message}
        <DialogActions>
          {actions}
        </DialogActions>
      </Dialog>
    )
  }
}
export default NoRobotRedirect
