// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Home
  content["Home"] =
  "홈";

  //Alarms
  content["Alarms"] =
  "경보";

  //Groups
  content["Groups"] =
  "그룹";

  //Inventory
  content["Inventory"] =
  "인벤토리";

  //Dashboards
  content["Dashboards"] =
  "대시보드";

  //Reports
  content["Reports"] =
  "보고서";

  //Settings
  content["Settings"] =
  "설정";

  //Setup Wizard
  content["Setup Wizard"] =
  "설치 마법사";

  //Community
  content["Community"] =
  "커뮤니티";

  //Help
  content["Help"] =
  "도움말";

  // END OF LOCALIZATION

export default content;
