const loadIPCE = (callback) => {
    const existingScript = document.getElementById('ipceScript');
    const script = document.createElement('script');
    script.src = './ipce.js';
    script.id = 'ipceScript';
    document.head.appendChild(script);
    script.onload = () => { 
        const productContext = { name: 'unified-infrastructure-management', version: '20.4' };
        const userContext = { userName: 'deependra', email: 'singh.deependra@broadcom.com' };
       // addIPCE();
        window?.IPCE?.init(productContext, userContext);
    };
    //}
    //if (existingScript && callback) callback();
  };
  export default loadIPCE;