import React, { Component } from 'react'
import Resource from './Resource'
import no_view_alert from "./../../assets/images/no_view_alert.svg"
// No View Message
const noViewMessage=Resource.get("You chose to hide all widgets. Use the Dashboard Options menu on the top right to restore them")

export default class NoViewPanel extends Component {
    
    render(){
        
        return(<div className="overview_no_view_selected">
        
        <span className="overview_no_view_text">
        <img src={no_view_alert} className='no_view_alert' alt="For your information" />
            {noViewMessage}
            </span>
        </div>)
    }
    
}