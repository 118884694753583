import React from "react";
import {Checkbox,TextField ,FormGroup,FormControl,InputAdornment,FormControlLabel,Divider} from "@mineral/core";

import { makeStyles } from '@mui/styles'

import SearchIcon from "@material-ui/icons/Search";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";

import axios from "axios";
import "./PerformanceLayoutCheckboxes.less";
import PrdCheckboxComponent from "./PrdCheckboxComponent";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "200px",
    maxHeight: "450px",
    overflow: "auto",
    width: "100%",
  },
  formLabelSelectAll: {
    margin: 0,
    borderBottom: "1px solid #C8D1E0",
  },
  formLabel: {
    margin: 0,
  },
  formControl: {
    width: "100%",
  },
  indeterminateColor: {
    color: "#00aeef",
  },
  dragIconColor: {
    color: "#8E99AB",
  },
  searchFieldHeight: {
    height: "10px",
  },
}));

const PerformanceLayoutCheckboxes = (props) => {
  const classes = useStyles();
  const [searchText, setSearchText] = React.useState("");
  const [searchResults, setSearchResults] = React.useState([]);
  React.useEffect(() => {
    let active = true;

    if (props.value) {
      (async () => {
        let checkboxData;
        try {
          let updatedUrl;
          if (props.url.includes("searchText") && props.value !== null) {
            updatedUrl = props.url.replace(
              "searchText=",
              `searchText=${searchText}`
            );
          } else {
            setSearchText("");
            setSearchResults([]);
            updatedUrl = props.url;
          }

          let response = null;
          if (process.env.NODE_ENV == "development") {
            response = await axios.get(updatedUrl);
          } else {
            response = await axios.post(updatedUrl);
          }

          checkboxData = response.data;
        } catch (error) {
          console.log(error);
          active = false;
        }
        // const response = await fetch(props.url);

        // const checkboxData = await response.json();

        if (active) {
          props.setOptions(checkboxData);
          props.setSearch(checkboxData);
          props.setSelected(props.selected);
        }
      })();
    }

    return () => {
      active = false;
    };
  }, [props.value]);

  const isAllSelected =
    props.options.length > 0 && props.selected.length === props.options.length;

  const handleChange = (event, option) => {
    const value = event.target.name;

    if (event.target.checked) {
      props.setSelected([...props.selected, value]);
    } else {
      const selected = props.selected.filter((item) => item !== option);
      props.setSelected(selected);
    }
    return;
  };

  const handleChangeSelectAll = () => {
    props.setSelected(
      props.selected.length === props.options.length ||
        searchResults.length === props.selected.length
        ? props.selected.length > 0
        ? []
          : props.options
        : searchResults.length !== 0
        ? searchResults
        : props.options
    );
    return;
  };

  const handleSearchCheckboxData = (event) => {
    let value = event.target.value.toLowerCase();

    const results = props.options.filter((item) => {
      return item.toLowerCase().includes(value);
    });
    setSearchResults(results);
    const resultArray = props.options.filter((item) => {
      return item.toLowerCase();
    });

    props.setSearch(results);
    setSearchText(value);
  };

  return (
    <>
      <p>{props.name}</p>
      <div className="targets-wrapper">
        <FormControl className={classes.formControl}>
          <TextField
            onChange={handleSearchCheckboxData}
            id={props.name}
            variant="outlined"
            value={searchText}
            placeholder={props.placeholder}
            sx={{
      input: {
        
         "&::placeholder": {    // <----- Add this.
            color: 'black !important',
            opacity:0.8
           
         },
      },
      
   }}
            InputProps={{
              style: {
                height: "30px",
              },
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon
                    className={classes.dragIconColor}
                    role="img"
                    name="Search icon"
                    title="Search icon"
                  />
                </InputAdornment>
              ),
            }}
          />

          {props.search.length ? (
            <FormGroup>
              <FormControlLabel
               
                control={
                  <>
                    <DragIndicatorIcon className={classes.dragIconColor} />
                    <Checkbox
                      onChange={(e) => handleChangeSelectAll(e)}
                     style={{marginRight:'4px'}}
                      checked={isAllSelected}
                      indeterminate={
                        props.selected.length > 0 &&
                        props.selected.length < props.options.length
                      }
                      name="Select All"
                    />
                  </>
                }
                label="Select All"
              />
              <Divider/>

              <div className={classes.root}>
                {props.search.map((option) => (
                  <PrdCheckboxComponent
                    key={option}
                    data={option}
                    handleChange={handleChange}
                    selected={props.selected}
                  />
                ))}
              </div>
            </FormGroup>
          ) : (
            <div style={{ textAlign: "center", marginTop: "10px" }}>
              {"No targets"}
            </div>
          )}
        </FormControl>
      </div>
    </>
  );
};

export default PerformanceLayoutCheckboxes;
