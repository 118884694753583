import React, { Component } from "react";
import { Table, TableBody, TableHead, TableCell, TableRow, TableSortLabel } from "@mineral/core";
import "./dashboardTable.less"
import { Folder as FolderIcon } from '@mineral/icons';
import { Dashboard as DashboardIcon } from '@mineral/icons';
import {Tooltip} from '@mineral/core';
import Resource from "./Resource";
import AccessibleTooltip from "../common/AccessibleTooltip";
import axios from "axios";
import { sortColumns } from "../../utils/tableUtils";
import {fetchDashboardPaths} from '../../api/dashboard/actions'
import { isEqual } from "lodash";
import orderBy from 'lodash/orderBy'
import get from 'lodash/get'
import * as _ from 'lodash'

class DashboardTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      table: {
        fixedHeader: true,
        fixedFooter: true,
        stripedRows: false,
        showRowHover: false,
        selectable: false,
        multiSelectable: false,
        enableSelectAll: false,
        deselectOnClickaway: false,
        showCheckboxes: false,
        height: "440px",
      },
      sid: null,
      isMaximizedView: false,
      selectionExceeded: false,  
      sortOrder:'asc',
      sortBy:"path",
      dashList:props.tableData     
    };
    this.hostname = window.location.hostname;
    this.port = window.location.port;
    this.protocol = window.location.protocol;
  }
  
  callPostMethod = (url) => {     

    var params = new URLSearchParams();
    params.append("pageNumber", 0);
    params.append("pageSize",   20);
    params.append("filterText", this.props.searchTxt);
    params.append("filterType", "FOLDERVIEW");
    this.props.fetchDashboardPaths(params);

    //Open dashboard
    const appForm = document.createElement('form')
    appForm.setAttribute('style','')
    appForm.style.display = 'none'
    appForm.method = 'POST'
    const urlWithParams = url
    const baseUrl = urlWithParams.split("&")
    
    appForm.action = baseUrl[0]
    appForm.target = '_blank'

    const sidParam = baseUrl[1].split("=")
    const keepaliveParam = baseUrl[2].split("=")        
        
    const sidInput = document.createElement('input')
    sidInput.type = 'text'
    sidInput.name = sidParam[0]
    sidInput.value = decodeURIComponent(sidParam[1])  
    appForm.appendChild(sidInput)
    
    const keepAliveInput = document.createElement('input')
    keepAliveInput.type = 'text'
    keepAliveInput.name = keepaliveParam[0]
    keepAliveInput.value = decodeURIComponent(keepaliveParam[1])  
    appForm.appendChild(keepAliveInput) 

    document.body.appendChild(appForm)
    appForm.submit();
  }

  componentWillReceiveProps(nextProps) {
    if (!isEqual(this.state.dashList, nextProps.tableData)) {
      this.setState({
        dashList:nextProps.tableData
      })
    }
  }

  
  requestSortNew(sortBy, sortOrder){
   
    let sortOrderUpdated = this.state.sortOrder;
    if (sortBy === this.state.sortBy) {
      if(sortOrder != ''){
      sortOrderUpdated = sortOrder === "asc" ? "desc" : "asc";
      }
     }else {
      sortOrderUpdated = "asc";
    }
    this.props.handleSortClick(sortBy, sortOrderUpdated);
    this.setState({
      sortBy:sortBy,
      sortOrder:sortOrderUpdated
    });

  }

  requestSort(sortBy, sortOrder){
    //const newSortedData = sortColumns(sortBy, sortOrder, this.state.dashList);
    let newSortedData = '';
    if (sortBy === 'path') {
        newSortedData = orderBy(
          this.state.dashList,
        [
          function(o) {
            return get(o, 'path', '').toLowerCase()
          },
        ],
        sortOrder
      )
    }else{
       newSortedData = orderBy(this.state.dashList, sortBy, sortOrder)
    }
    let sortOrderUpdated = this.state.sortOrder;
    if (sortBy === this.state.sortBy) {
      if(sortOrder != ''){
      sortOrderUpdated = sortOrder === "asc" ? "desc" : "asc";
      }
     }else {
      sortOrderUpdated = "asc";
    }
    this.setState({
      dashList: newSortedData,
      sortBy:sortBy,
      sortOrder:sortOrderUpdated
    });
  }

  componentWillMount = () => {
      let overrideSid=axios.defaults.headers.common["Authorization"];
      if(window.OPERATOR_CONSOLE.refreshToken && window.OPERATOR_CONSOLE.refreshToken!=="")
      {
          overrideSid=window.OPERATOR_CONSOLE.refreshToken;
          axios.defaults.headers.common["Authorization"]=window.OPERATOR_CONSOLE.refreshToken;
          window.OPERATOR_CONSOLE.refreshToken="";
      }
      this.setState({ sid: overrideSid})
  };

  // DISPLAY DASHBOARD NAME
  displayDashboardName(ip, port, protocol, path, name) {
    let url = `${protocol}//${ip}/dashboard/jsp/standalone.jsp?path=${path}&sid=${this.state.sid}&keep-alive=${this.props.keepalive}`;
    if (port !== "")
      url = `${protocol}//${ip}:${port}/dashboard/jsp/standalone.jsp?path=${path}&sid=${this.state.sid}&keep-alive=${this.props.keepalive}`;

    return (
      <TableCell style={{display: name.length > 28 ? "flex":""}} className="noBorderBottom" tabIndex={0} role="link">
        <div style={{width:"auto", display:"inline-block"}}>
          <DashboardIcon fontSize="medium"  style={{marginRight:"8px", color:"#333840", marginBottom:"-8px"}} />
        </div>
        <div style={{display:"inline-block"}}>
        <AccessibleTooltip arrow title={name.length > 28 ? name : ""}>
        <a style={{display:"inline-block"}} className={this.props.searchTxt.length < 3 ? "dashboardName dashboardMaxWidth dashboardEllipis" : "dashboardName folderMaxWidth dashboardEllipis" } rel="noopener noreferrer" target="_blank"
            //onClick={()=>{ this.callPostMethod(url)}}
          >
           {name.length >=60 ? name.substring(0, 60) + "..." : name}
        </a></AccessibleTooltip>
        </div>
      </TableCell>
    );
  }

  // DISPLAY FOLDER NAME
  displayFolderName(path, filterTxt, pathCnt, filterLevel, searchTxtLen) {
    return (
      <TableCell style={{display: filterTxt.length > 28 ? "flex":""}} className="noBorderBottom" tabIndex={0}>
        {
        <>
        <div style={{display:"inline-block"}}>
          <FolderIcon fontSize="medium"  style={{marginRight:"8px", color:"#333840", marginBottom:"-8px"}} />
        </div>
        <AccessibleTooltip arrow  title={filterTxt.length > 28 ? filterTxt : ""}>
        <div role="link" style={{display:"inline-block"}} className={this.props.searchTxt.length < 3 ? "dashboardPath dashboardMaxWidth dashboardEllipis" : "dashboardPath folderMaxWidth dashboardEllipis" }
         //onClick={() => {this.props.folderClick(path, filterTxt)}}
          >
          {filterTxt.length >=60 ? filterTxt.substring(0, 60) + "..." : filterTxt}
        </div></AccessibleTooltip>
        </>
        }
      </TableCell>
    );
  }

  getTime(timestamp) {
    let value=  timestamp? new Date(timestamp*1000).toLocaleString() : ''
    return (
    <AccessibleTooltip title={value}>
      <TableCell className="noBorderBottom">
        {value}
      </TableCell>
    </AccessibleTooltip>
    );
  }

  render() {
    let ip = this.hostname;
    let port = this.port;
    let protocol = this.protocol;
    const NO_RESULTS_FOUND = Resource.get("No dashboard found")

    return (
      <div className="dashboard-view">
        <Table
          height={this.state.table.height}
          fixedHeader={this.state.table.fixedHeader}
          fixedFooter={this.state.table.fixedFooter}
          selectable={this.state.table.selectable}
          multiSelectable={this.state.table.multiSelectable}
          onRowSelection={this.onSelectRow}
          className="dashboarTable"
          aria-label="Dashboard"
        >
          <TableHead
            displaySelectAll={this.state.table.showCheckboxes}
            adjustForCheckbox={this.state.table.showCheckboxes}
            enableSelectAll={this.state.table.enableSelectAll}
            className="dashboarTableHead"
          >
            <TableRow className="dashboarTableRow">
              <TableCell className={this.props.searchTxt.length >= 3 ? "TblCol1Width" : "TblCol2Width"}>
              <TableSortLabel
                  active={this.state.sortBy === "path"}
                  direction={this.state.sortOrder}
                  onClick={() => {this.requestSortNew("path",this.state.sortOrder)}}
                  className="tblColumnSortArrow"
                >
                  Name
                </TableSortLabel>
                </TableCell>
              <TableCell style={{ display: this.props.searchTxt.length < 3 ? "none" : "" }} className="TblCol1Width">Dashboard Path</TableCell>
              <TableCell className={this.props.searchTxt.length >= 3 ? "TblCol1Width" : "TblCol2Width"}>
              <TableSortLabel
                    active={this.state.sortBy === "visibility"}
                    direction={this.state.sortOrder}
                    onClick={() => {this.requestSortNew("visibility",this.state.sortOrder)}}
                    className="tblColumnSortArrow"
                  >
                    Visibility
                  </TableSortLabel>
                </TableCell>
              <TableCell className={this.props.searchTxt.length >= 3 ? "TblCol1Width" : "TblCol2Width"}>
                <TableSortLabel
                    active={this.state.sortBy === "created"}
                    direction={this.state.sortOrder}
                    onClick={() => {this.requestSortNew("created",this.state.sortOrder)}}
                    className="tblColumnSortArrow"
                  >
                    Created On
                  </TableSortLabel>
                </TableCell>
              <TableCell className={this.props.searchTxt.length >= 3 ? "TblCol1Width" : "TblCol2Width"}>
              <TableSortLabel
                    active={this.state.sortBy === "modified"}
                    direction={this.state.sortOrder}
                    onClick={() => {this.requestSortNew("modified",this.state.sortOrder)}}
                    className="tblColumnSortArrow"
                  >
                    Modified On
                  </TableSortLabel>
              </TableCell>
              <TableCell className={this.props.searchTxt.length >= 3 ? "TblCol1Width" : "TblCol2Width"}>Last Executed</TableCell>
            </TableRow>
          </TableHead>
          {(this.state.dashList && this.state.dashList.length > 0) ?
          <TableBody
            displayRowCheckbox={this.state.table.showCheckboxes}
            deselectOnClickaway={this.state.table.deselectOnClickaway}
            showRowHover={this.state.table.showRowHover}
            stripedRows={this.state.table.stripedRows}
            className="dashboarTableBody"
          >
            {
            this.state.dashList.map((row, index) => (
              <TableRow role="link" aria-roledescription={`Click to ${row.type === "DASHBOARD"?"open dashboard":"open folder"}`}
                key={row.id+row.visibility}
                className={ "selected-bg" }
                disabled ={true}
                style={{
                  backgroundColor: "inherit", borderBottom: "1px solid #C8D1E0",
                  color: "#fff", fontSize:"14px", cursor:"pointer"
                }}
                onClick={() => {
                  row.type === "DASHBOARD" ?
                    port !== "" ?
                      this.callPostMethod(`${protocol}//${ip}:${port}/dashboard/jsp/standalone.jsp?path=${row.path}&sid=${this.state.sid}&keep-alive=${this.props.keepalive}`)
                      :
                      this.callPostMethod(`${protocol}//${ip}/dashboard/jsp/standalone.jsp?path=${row.path}&sid=${this.state.sid}&keep-alive=${this.props.keepalive}`)
                    :
                    this.props.folderClick(row.path, row.path.split("/")[row.path.split("/").length-1])
                  }
                }
                onKeyPress= {(e) => {
                  if (e.key === 'Enter') {
                    row.type === "DASHBOARD" ?
                    port !== "" ?
                      this.callPostMethod(`${protocol}//${ip}:${port}/dashboard/jsp/standalone.jsp?path=${row.path}&sid=${this.props.sid}&keep-alive=${this.props.keepalive}`)
                      :
                      this.callPostMethod(`${protocol}//${ip}/dashboard/jsp/standalone.jsp?path=${row.path}&sid=${this.props.sid}&keep-alive=${this.props.keepalive}`)
                    :
                    this.props.folderClick(row.path, row.path.split("/")[row.path.split("/").length-1])
                  }
          }}
              >
                {
                  row.type === "DASHBOARD" ? 
                    this.displayDashboardName(ip, port, protocol, row.path, row.name) : 
                    this.displayFolderName(row.path, row.path.split("/")[row.path.split("/").length-1], row.path.split("/").length, this.props.filterLevel, this.props.searchTxt.length)
                }
                <Tooltip arrow middle title={row.path !== row.name && row.path.replace("/" + row.name, "").length > 28 ? row.path.replace("/" + row.name, "") : ""} >
                  <TableCell style={{ display: this.props.searchTxt.length < 3 ? "none" : "" }} className="noBorderBottom">
                      <div className={this.props.searchTxt.length < 3 ? "dashboardMaxWidth dashboardEllipis" : "folderMaxWidth dashboardEllipis"}>
                      {
                        row.path !== row.name ?  row.path.replace("/" + row.name, "")  : ""
                      }
                      </div>
                  </TableCell>
                </Tooltip>
                <AccessibleTooltip title={row.type === "DASHBOARD" ? row.visibility : ""}>
                  <TableCell className="noBorderBottom">
                    {row.type === "DASHBOARD" ? row.visibility : ""}
                  </TableCell>
                </AccessibleTooltip>
                {this.getTime(row.created)}
                {this.getTime(row.modified)}
                {row.lastexecutiontime && row.lastexecutiontime !== "" ? this.getTime(row.lastexecutiontime) : this.getTime("")}
              </TableRow>
            ))
           }
          </TableBody>
      :
      <TableBody aria-live="polite">
        <TableRow>
          <TableCell colSpan={6} style={{textAlign:'center'}}>{NO_RESULTS_FOUND}
          </TableCell>
        </TableRow>
      </TableBody>
 }
        </Table>  
      </div>
    );
  }
}

export default DashboardTable;