import React from 'react'
import { Link } from 'react-router-dom'

const MenuItem = ({
  label,
  icon: Icon,
  handleClick,
  handleCloseMenu,
  appId,
  endpointUrl,
  tenantId,
  selected,
  linkTo
}) => {
  let launchProduct = () => {
    if (appId !== 'CPA') {
      handleClick(appId, endpointUrl, tenantId)
    } else {
      handleCloseMenu()
    }
  }
  let liClass = 'menu-item '
  if (selected) {
    liClass += ' active'
  }
  const item = (
    <li className={liClass} onClick={launchProduct}>
      <a className="menu-item-link">
        <span className="menu-item-icon">
          <Icon />
        </span>
        {label}
      </a>
    </li>
  )
  let el
  if (linkTo) {
    el = (
      <Link to={linkTo} style={{ textDecoration: 'none' }}>
        {' '}{item}{' '}
      </Link>
    )
  } else {
    el = item
  }
  return el
}

export default MenuItem
