import React from 'react'
import {GoodIcon as SuccessIcon} from '../../ui-components/uim-components'
import Message from './CenterMessage'
import './deploySuccess.less'
import Resource from './Resource'

const DeploySuccess = (props) => {
  return (
    <Message
      className='message--success'
      title={props.title}
      message={props.message}
      icon={SuccessIcon}
    />
  )
}

DeploySuccess.defaultProps = {
	//Deployment status message
  title: Resource.get('Deployment was successful'),
  //NEXT help message
  message: Resource.get('Click NEXT below to finish your monitoring setup.'),
}

export default DeploySuccess