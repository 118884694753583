// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //ok message
  content["OK"] =
  "OK";

  // CSV template help message
  content["A maximum of 50 devices can be imported in a single CSV import. Only the first 50 records were imported."] =
  "1 回の CSV インポートで、最大 50 個のデバイスをインポートできます。最初の 50 個のレコードのみインポートされました。";

  //Import CSV
  content["Import CSV"] =
  "CSV のインポート";

  // creation help message
  content["To add devices in bulk, create a CSV file with the following information for each robot:"] =
  "デバイスを一括で追加するには、各ロボットに対する以下の情報で CSV ファイルを作成しますt:";

  //IP Address
  content["IP Address"] =
  "IP アドレス";

  // IP4 help message
  content["IPv4 address of system where robot/monitoring agent is to be installed"] =
  "ロボット/モニタリング エージェントがインストールされるシステムの IPv4 アドレス";

  //Operating System
  content["Operating System"] =
  "オペレーティング システム";

  // Values help message
  content["Values: centos, debian, opensuse, rhel, ubuntu or windows"] =
  "値: centos、debian、opensuse、rhel、ubuntu または windows";

  //Architecture
  content["Architecture"] =
  "アーキテクチャ";

  // Architecture help message
  content["Values: 32 or 64"] =
  "値: 32 または 64";

  //Username
  content["Username"] =
  "ユーザ名";

  // Username help message
  content["Enter username with installation permissions for system where robot is to be installed"] =
  "ロボットがインストールされるシステムに対して、インストール権限があるユーザ名を入力します";

  //Password
  content["Password"] =
  "パスワード";

  // Password help message
  content["Password for username on the robot system"] =
  "ロボット システム上のユーザ名のパスワード";

  //Sudo password
  content["Sudo password"] =
  "Sudo パスワード";

  // Sudo password help message
  content["If username does not have root-level installation permissions, provide sudo password to elevate permissions to root-level for installation"] =
  "ユーザ名にルート レベルのインストール権限がない場合は、sudo パスワードを指定して、インストール権限をルート レベルまで昇格させます";

  // Note message
  content["Note: Up to 50 devices can be imported from CSV file."] =
  "注: CSV ファイルから最大 50 個のデバイスまでインポートできます。";

  //Example CSV file
  content["Example CSV file"] =
  "サンプル CSV ファイル";

  // END OF LOCALIZATION

export default content;
