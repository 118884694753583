import { SET_ISTIMEDOUT } from './actionTypes'

const isTimedout = (state = false, action) => {
  switch (action.type) {
    case SET_ISTIMEDOUT:
      return action.isTimedout
    default:
      return state
  }
}
export default isTimedout
