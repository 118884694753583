import {
  REQUEST_ROBOTS_STATUS,
  FETCH_ROBOTS_STATUS_SUCCESS,
  FETCH_ROBOTS_STATUS_FAILURE,
  RESET_ROBOTS_STATUS,
  STORE_PROBE_JOBID,
  REQUEST_PROBES_STATUS_SUCCESS
} from './actionTypes'
import isEqual from 'lodash/isEqual'

const robotsStatus = (state = {
  isFetching: false,
  didInvalidate: false,
  status: [],
  probesStatus:[],
  probeJobIds:[],
  robotsStatusfulfilled:false
}, action) => {
  switch (action.type) {
    case REQUEST_ROBOTS_STATUS:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false
      })
    case RESET_ROBOTS_STATUS:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        status: [],
        probesStatus:[],
        robotsStatusfulfilled:false,
        probeJobIds:[]
      })
    case FETCH_ROBOTS_STATUS_SUCCESS:
      const status = action.robots._items
      const updatedStatusState = Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        status: status
      })
      return isEqual(updatedStatusState, state) ? state : updatedStatusState
    case FETCH_ROBOTS_STATUS_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        errorMessage: action.errorMessage,
        status: []
      })
    case STORE_PROBE_JOBID:
    	var data = state.probeJobIds.slice(0);
    	data = data.concat(action.idsArr);
    	return Object.assign({}, state, {
    		probeJobIds:data,
    		robotsStatusfulfilled:action.status
        })
    case REQUEST_PROBES_STATUS_SUCCESS:
    	const probesStatus = action.probes;
    	return  Object.assign({}, state, {
    		probesStatus:probesStatus
        });
    default:
      return state
  }
};

export default robotsStatus
