import React, { Component } from 'react';

import './columns.less';


export default class Columns extends Component {
  render() {
    const { n, children } = this.props;

    let renderedColumns = <div className='column' style={{width: '0', flex: '1'}}>{children}</div>;

    if (n > 1) {
      let columns = []
      React.Children.map(children, (child, i) => {
        const index = i % n
        columns[index] = columns[index] || []
        columns[index].push(child)
      })

      const width = 100/n
      renderedColumns = columns.map((column, index) => <div key={index} className='column' style={{width: `${width}%`}}>{column.map(React.cloneElement)}</div>);
    }

    return (
      <div className='columns' style={{width: '100%'}}>{renderedColumns}</div>
    )
  }
}
