import axios from 'axios'

class Wasp {
  isEcometerAdminInstall() {
   const url = process.env.NODE_ENV == "development" ?
   "http://10.252.25.208:8080/castlemock/mock/rest/project/TK8t3o/application/UXxhjW/ecometer" :
   '/operatorconsole_portlet/api/v1/wasp/ecometer'
    return axios.get(url)
  }
}

const wasp = new Wasp()
export default wasp