// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  // instructions message
  content["To add additional monitoring to this device, a local robot must be installed"] =
  "このデバイスにモニタリングを追加するにはローカルのロボットをインストールする必要があります";

  // instructions message1
  content["Use the Setup Wizard to add local monitoring based on a device\'s operating system: Windows or Linux, and select manual installation."] =
  "セットアップ ウィザードを使用して、デバイス\ のオペレーティング システム (Windows または Linux) に基づいてローカル モニタリングを追加し、手動インストールを選択します。";

  //Local robot required
  content["Local robot required"] =
  "ローカルのロボットが必須です";

  //Cancel
  content["Cancel"] =
  "キャンセル";

  //Add devices...
  content["Add devices..."] =
  "デバイスの追加...";

  // instruction message
  content["To add additional monitoring to the devices in this group, local robots must be installed"] =
  "このグループのデバイスにモニタリングを追加するには、ローカルのロボットをインストールする必要があります";

  //  instruction message1
  content["Use the Setup Wizard to add local monitoring based on a device\'s operating system: Windows or Linux."] =
  "セットアップ ウィザードを使用し、デバイス\ のオペレーティング システム (Windows または Linux) に基づいてローカル モニタリングを追加します。";

  // END OF LOCALIZATION

export default content;
