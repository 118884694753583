import {
  SET_GROUP_ID,
} from './actionTypes'

const groupId = (state = null, action) => {
  switch (action.type) {
    case SET_GROUP_ID:
      return action.id || null
    default:
      return state
  }
}

export default groupId
