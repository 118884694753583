// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Dashboards
  content["Dashboards"] =
  "대시보드";

  // END OF LOCALIZATION

export default content;
