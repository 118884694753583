import React, { Component } from "react";
import { Route } from "react-router-dom";
import SettingsView from "./../components/settings/Settings";
import connect from "./../utils/connect";
import RestoreDeletedDevices from "./../components/settings/infrastructure/RestoreDeletedDevices";
import PagerDutyConfig from "./../components/settings/integrations/pagerduty/PagerDutyConfig";
import SlackConfig from "./../components/settings/integrations/slack/SlackConfig";
import CloseRuleConfig from "./../components/settings/notifications/closeRule/CloseRuleConfig";
import ServiceNowConfig from "./../components/settings/integrations/servicenow/ServiceNowConfig";
import RestmonConfig from "./../components/settings/integrations/restmon/RestmonConfig";
import DesignDashboard from "./../components/settings/portlets/dashboard/DesignDashboard";
import PerformanceReports from "./../components/settings/portlets/performanceReports/PerformanceReports";
import SelfCert from "./../components/settings/portlets/selfCertification/SelfCert";
import SLM from "./../components/settings/portlets/slm/SLM";
import AccAdmin from "./../components/settings/portlets/accountAdmin/AccAdmin";
import Administration from "./../components/settings/notifications/Administration";
import EcometerAdmin from "./../components/settings/portlets/ecometer/EcometerAdmin";
import ReportScheduler from "./../components/settings/portlets/reportscheduler/ReportScheduler";
import PersonalizationHomePage from "./../components/settings/personalize/personalizationHomePage";
import ProductUsageReporting from "./../components/settings/usagemetrics/productUsageReporting";

import get from "lodash/get";
import ListDesignerWrapper from "../components/settings/portlets/listDesigner/ListDesignerWrapper";
import ListDashboardDesigner from "../components/settings/portlets/ListDashboardDesigner/ListDashboardDesigner";

class Settings extends Component {
  render = () => {
    let className = ["main-settings", this.props.className].join(" ");
    return <main className={className}>{this.props.component}</main>;
  };
}

class SettingsRoute extends Component {
  render = () => {
    // if (this.props.acl.acl === AclTypes.OPERATOR) {
    //   return null;
    // }
    return (
      <div>
        <Route
          exact
          path="/settings"
          render={() => {
            return <Settings component={<SettingsView />} />;
          }}
        />
        <Route
          path="/settings/infrastructure/deleted-devices"
          component={RestoreDeletedDevices}
        />
        <Route
          path="/settings/integrations/pagerduty"
          render={() => {
            return <Settings component={<PagerDutyConfig />} />;
          }}
        />
        <Route
          path="/settings/integrations/slack"
          render={() => {
            return <Settings component={<SlackConfig />} />;
          }}
        />
        <Route
          path="/settings/integrations/servicenow"
          render={() => {
            return <Settings component={<ServiceNowConfig />} />;
          }}
        />

        <Route
          path="/settings/close-rules"
          render={() => {
            return <Settings component={<CloseRuleConfig />} />;
          }}
        />
        {(this.props.acl.isPortalAdministrator) ? (
          <Route
            path="/settings/restmon"
            render={() => {
              return <Settings component={<RestmonConfig />} />;
            }}
          />) : null}
        {(this.props.acl.canDesignDashboard) ? (
          <Route
            path="/settings/dashboard"
            render={() => {
              return <Settings component={<DesignDashboard />} />;
            }}
          />) : null
        }
        {(this.props.acl.canPerformanceReports) ? (
          <Route
            path="/settings/performancereports"
            render={() => {
              return <Settings component={<PerformanceReports />} />;
            }}
          />) : null
        }
        {(this.props.acl.selfcert && this.props.acl.isPortalAdministrator) ? (
          <Route
            path="/settings/self-cert"
            render={() => {
              return <Settings component={<SelfCert />} />;
            }}
          />
        ) : null}
        <Route
          path="/settings/slm"
          render={() => {
            return <Settings component={<SLM />} />;
          }}
        />
        {this.props.acl.canAdministerAccount ? (
          <Route
            path="/settings/acc-admin"
            render={() => {
              return <Settings component={<AccAdmin />} />;
            }}
          />) : null}

        <Route
          path="/settings/dcimadmin"
          render={() => {
            return <Settings component={<EcometerAdmin />} />;
          }}
        />
        <Route
          path="/settings/listdesigner"
          render={() => {
            return <ListDesignerWrapper />;
          }}
        />
        <Route
          path="/settings/listviewer"
          exact
          render={() => {
            return <ListDashboardDesigner copyURL={false} />;
          }}
        />
        <Route
          path="/settings/administration"
          render={() => {
            if (this.props.acl.isPortalAdministrator) {
              return <Settings component={<Administration />} />;
            } else {
              return null;
            }
          }}
        />
        <Route
          path="/settings/reportscheduler"
          render={() => {
            return <Settings component={<ReportScheduler />} />;
          }}
        />
        <Route
          path="/settings/personalize"
          render={() => {
            if (get(this.props.featureFlags, 'enableUserPersonalization', false) == true) {
              return <Settings component={<PersonalizationHomePage />} />;
            } else {
              return null;
            }
          }}
        />
        {(this.props.acl.isBusUser && this.props.acl.canProductUsageData && (get(this.props.featureFlags, 'enableProductUsage', false) == true)) ? (
          <Route
            path="/settings/usagemetrics"
            render={() => {
              return <Settings component={<ProductUsageReporting />} />;
            }}
          />) : null}
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    featureFlags: state.configuration.items,
    acl: state.acl,
  };
};

export default connect(mapStateToProps, null)(SettingsRoute);
