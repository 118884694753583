import React, { Component } from "react";
import "./scopes.less";
import "./credentialVault.less";
import cloneDeep from "lodash/cloneDeep";
import ScopesCatalogRedux from "./ScopesCatalogRedux";
import scopes from "./../../../api/DeviceDiscovery/scopes/scopes";
import error from "../../../assets/icons/error_alert.svg";
import addDisabled from "../../../assets/icons/add_disabled.svg";
import Resource from "./Resource";
import credentials from "./../../../api/DeviceDiscovery/credentials/credentials";
import ScopeWelcomeText from "./ScopeWelcomeText";
import AccessibleTooltip from '../../common/AccessibleTooltip'
import { Checkbox, MenuItem, Select as SelectField, Snackbar, SnackbarContent, FormControl, TextField, InputLabel, FormControlLabel, FormLabel, Radio, RadioGroup, Grid ,IconButton} from "@mineral/core";

import CustomSnackbar from '../../common/CustomSnackbar'

import deleteIcon from "../../../assets/icons/deleteIcon.svg";
import ScopesCredentialsRedux from "./ScopesCredentialsRedux";
import add from "../../../assets/icons/addIcon.svg";
import errorIcon from "../../../assets/icons/error_alert.svg";
import importicon from "../../../assets/icons/import.svg";
import {CircularProgress} from "@mineral/core";
import * as constant from "./DiscoveryConstants";
import { Button } from "@mineral/core";

class Scope extends Component {
  constructor() {
    super();
    this.state = {
      scopeName: "",
      scopeId: "",
      agentId: "",
      isDirty: false,
      isScopeNameValid: true,
      disableAddButton: false,
      networkList: [
        {
          id: 0,
          maskOrRange: "",
          network: "",
          networkType: "Single",
        },
      ],
      showRemoveButton: true,
      networkListValid: [
        {
          ipValid: true,
          MaskorRangeValid: true,
          invalidIpMessage: "",
          invalidMaskorRangeMessage: "",
        },
      ],
      isIpValid: false,
      isMaskorRangeValid: "",
      shellProfile: [],
      wmiProfile: [],
      shellProfilesId: [],
      wmiProfilesId: [],
      snmpProfilesId: [],
      smpProfile: [],
      showWelcomeTxt: true,
      scopeObject: {},
      error: {
        errorOccurred: false,
        errorMessage: "",
        errorDescription: "",
      },
      isLoading: false,
      showimportbox: false,
      showRemoveButton: true,
      savepopUpOpen: false,
      popMassage: "",
      popErrorType:'info',
      importIps: [
        {
          id: 0,
          maskOrRange: "",
          network: "",
          networkType: "Single",
        },
      ],
    };
  }
  credentialVaultHandle = null;
  scopes = ["Single", "Range", "Mask"];
  enterMultipleIPs = Resource.get("Enter multiple IPs (one per line)")// Enter multiple IPs (one per line)
  componentDidMount() {}
  componentDidUpdate() {}
  scopesCatalogHandle = null;
  enableWelcomeText = () => {
    this.setState({
      showWelcomeTxt: true,
    });
  };
  isScopeNameValid = () => {
    let name = this.state.scopeName;
    if (name == "") {
      this.setState({
        isScopeNameValid: false,
      });
    } else {
      this.setState({
        isScopeNameValid: true,
      });
    }
  };
  importclick = () => {
    this.setState({
      showimportbox: true,
      disableAddButton: true,
    });
  };
  handleScopeNameChange = (e) => {
    let name = e.target.value.trim();
    if (name === "") {
      this.setState({
        scopeName: name,
        isDirty: true,
        isScopeNameValid: false,
      });
    } else {
      this.setState({
        scopeName: name,
        isDirty: true,
        isScopeNameValid: true,
      });
    }
  };

  SaveorUpdateScope = () => () => {
    let wmiCredentials = [],
      linuxCredentials = [];
    let snmpCredentials = [];
    if (this.credentialVaultHandle) {
      wmiCredentials = this.credentialVaultHandle.getSelectedWMICredentials();
      linuxCredentials =
        this.credentialVaultHandle.getSelectedUnixCredentials();
      snmpCredentials = this.credentialVaultHandle.getSelectedSNMPCredentials();
    }
    this.setState({
      isLoading: true,
    });
    if (this.state.scopeId === "") {
      let scopeInfo = {
        name: this.state.scopeName,
        networkList: this.state.networkList,
        shellProfile: linuxCredentials,
        wmiProfile: wmiCredentials,
        snmpProfile: snmpCredentials,
      };

      scopes.saveScope(scopeInfo, this.state.agentId).then(
        (response) => {
          this.SaveOrUpdateTheCredentialSuccess(response.data);
          if (this.scopesCatalogHandle) {
            this.scopesCatalogHandle.refreshPostCreate(response.data);
          }
          this.setState({
            savepopUpOpen: true,
            popMassage:"Added Successfully",
            popErrorType:'success'
          })
          this.closeError();
        },
        (err) => {
          let errMssg = Resource.get("Error Adding Scopes"); //Error Adding Scopes
          let errorDescription = err.response.data.errorMsg;
          let error = {
            message: errMssg,
            description: errorDescription,
          };
          this.setState({
            isLoading: false,
            savepopUpOpen: true,
            popMassage:errMssg,
            popErrorType:'error'
          });
          this.setError(error);
          //console.log(err);
        }
      );
    } else {
      let scopeInfo = {
        id: this.state.scopeId,
        name: this.state.scopeName,
        networkList: this.state.networkList,
        shellProfile: linuxCredentials,
        wmiProfile: wmiCredentials,
        snmpProfile: snmpCredentials,
      };
      scopes.updateScope(scopeInfo, this.state.agentId).then(
        (response) => {
          this.SaveOrUpdateTheCredentialSuccess(response.data);
          if (this.scopesCatalogHandle) {
            this.scopesCatalogHandle.refreshPostUpdate(response.data);
          }
          this.setState({
            savepopUpOpen: true,
            popMassage:"Updated Successfully",
            popErrorType:'success'
          })
          this.closeError();
        },
        (err) => {
          let errMssg = Resource.get("Error Updating Scopes"); //Error Updating Scopes
          let errorDescription = err.response.data.errorMsg;
          let error = {
            message: errMssg,
            description: errorDescription,
          };
          this.setState({
            savepopUpOpen: true,
            popMassage:errMssg,
            popErrorType:'error'
          })
          this.setError(error);
          //console.log(err);
        }
      );
    }
  };
  SaveOrUpdateTheCredentialSuccess = (obj) => {
    this.setState({
      scopeId: obj.id,
      shellProfilesId: obj.shellProfile,
      wmiProfilesId: obj.wmiProfile,
      snmpProfilesId: obj.snmpProfile,
      isDirty: false,
      isCredentialsDirty: false,
      isLoading: false,
    });
  };
  getDeleteTag = () => {
    let removeLabel = Resource.get("Remove"); //Remove
    let confirmationMessage = Resource.get("Remove this Scope?"); //Remove this Scope?
    let yesLabel = Resource.get("Yes"); //Yes
    let noLabel = Resource.get("No"); //No
    if (this.state.showRemoveButton) {
      return (
        <div style={{ paddingTop: "25px" }}>
          <Button
        
            variant="contained" color="error"
           
            onClick={this.deleteCredentialConfirmation}
          >
            {removeLabel}
          </Button>
        </div>
      );
    } else {
      return (
        <div style={{ paddingTop: "25px",display:'flex',flexDirection:'row' }}>
          <span style={{ fontSize: "14px" }}>
            {confirmationMessage}
          </span>
          <Button
        
      style={{marginRight:'8px'}}
           onClick={this.deleteCredential.bind(this)}
          >
            {yesLabel}
          </Button>
          <Button
           
           
            onClick={this.closeDeleteCredential.bind(this)}
          >
            {noLabel}
          </Button>
        </div>
      );
    }
  };
  setError = (error) => {
    this.setState({
      error: {
        errorOccurred: true,
        errorMessage: error.message,
        errorDescription: error.description,
        isLoading: false,
      },
    });
  };
  closeError = () => {
    this.setState({
      error: {
        errorOccurred: false,
        errorMessage: "",
        errorDescription: "",
      },
    });
  };
  deleteCredential = () => {
    scopes.deleteScope(this.state.agentId, this.state.scopeId).then(
      (response) => {
        this.setState({
          showWelcomeTxt: true,
          showRemoveButton: true,
        });
        if (this.scopesCatalogHandle) {
          this.scopesCatalogHandle.refreshPostDelete(this.state.scopeId);
        }
        this.setState({
          savepopUpOpen: true,
          popMassage:"Removed Successfully",
            popErrorType:'success'
        })
        this.closeError();
      },
      (err) => {
        let errMssg = Resource.get("Error Deleting Scopes"); //Error Deleting Scopes
        let errorDescription = err.response.data.errorMsg;
        let error = {
          message: errMssg,
          description: errorDescription,
        };
        this.setState({
          savepopUpOpen: true,
          popMassage:errMssg,
          popErrorType:'error'
        })
        this.setError(error);
        //console.log(err);
      }
    );
  };
  closeDeleteCredential = () => {
    //console.log('No called check how to close this mess')
    this.setState({
      showRemoveButton: true,
    });
  };
  deleteCredentialConfirmation = () => {
    this.setState({
      showRemoveButton: false,
    });
    //console.log('called delete')
  };
  resetTheScopeForm = (agentId) => {
    //Add

    //var result = this.state.map(person => ({ value: person.id, text: person.name }));
    this.setState({
      scopeName: "",
      scopeId: "",
      isScopeNameValid: true,
      networkList: [
        {
          id: 0,
          maskOrRange: "",
          network: "",
          networkType: "Single",
        },
      ],
      networkListValid: [
        {
          ipValid: true,
          MaskorRangeValid: true,
          invalidIpMessage: "",
          invalidMaskorRangeMessage: "",
        },
      ],
      agentId: agentId,
      isIpValid: false,
      isMaskorRangeValid: "",
      showWelcomeTxt: false,
      showimportbox: false,
      importIps: [
        {
          id: 0,
          maskOrRange: "",
          network: "",
          networkType: "Single",
        },
      ],
    });
  };

  resetClick = () => {
    //Add

    //var result = this.state.map(person => ({ value: person.id, text: person.name }));
    if (this.state.scopeId === "") {
      this.setState({
        scopeName: "",
        scopeId: "",
        importIps: [
          {
            id: 0,
            maskOrRange: "",
            network: "",
            networkType: "Single",
          },
        ],
        isScopeNameValid: true,
        networkList: [
          {
            id: 0,
            maskOrRange: "",
            network: "",
            networkType: "Single",
          },
        ],
        networkListValid: [
          {
            ipValid: true,
            MaskorRangeValid: true,
            invalidIpMessage: "",
            invalidMaskorRangeMessage: "",
          },
        ],

        isIpValid: false,
        isMaskorRangeValid: "",
        showWelcomeTxt: false,
        savepopUpOpen: true,
        popMassage:"Reset Successfully",
        popErrorType:'success'
      });
    } else {
      let newnetworkListValid = this.state.scopeObject.networkList.map(
        (network) => ({
          ipValid: true,
          MaskorRangeValid: true,
          invalidIpMessage: "",
          invalidMaskorRangeMessage: "",
        })
      );

      this.setState({
        scopeName: this.state.scopeObject.name,
        scopeId: this.state.scopeObject.id,
        isScopeNameValid: true,
        networkList: this.state.scopeObject.networkList,
        shellProfilesId: this.state.scopeObject.shellProfile,
        wmiProfilesId: this.state.scopeObject.wmiProfile,
        snmpProfilesId: this.state.scopeObject.snmpProfile,
        networkListValid: newnetworkListValid,
        isIpValid: true,
        isMaskorRangeValid: true,
        showWelcomeTxt: false,
        isDirty: false,
        isCredentialsDirty: false,
        savepopUpOpen: true,
        popMassage:"Reset Successfully",
        popErrorType:'success'
      });
    }
    this.credentialVaultHandle.resetCredentialsSelection();
  };
  setCredentialsDirtyFlag = (isDirty) => {
    this.setState({
      isCredentialsDirty: isDirty,
    });
  };
  updateScopeForm = (credObject, agentId) => {
    if (!credObject) {
      this.resetTheScopeForm(agentId);
    } else {
      let newnetworkListValid = credObject.networkList.map((network) => ({
        ipValid: true,
        MaskorRangeValid: true,
        invalidIpMessage: "",
        invalidMaskorRangeMessage: "",
      }));
      let i = 0;
      for (i = 0; i < credObject.networkList.length; i++) {
        var a = credObject.networkList[i].networkType.toLowerCase();
        credObject.networkList[i].networkType =
          a.charAt(0).toUpperCase() + a.slice(1);
      }
      let clonedObj = cloneDeep(credObject);
      this.setState({
        scopeObject: clonedObj,
        scopeName: credObject.name,
        scopeId: credObject.id,
        networkList: credObject.networkList,
        shellProfilesId: credObject.shellProfile,
        wmiProfilesId: credObject.wmiProfile,
        snmpProfilesId: credObject.snmpProfile,
        isScopeNameValid: true,
        networkListValid: newnetworkListValid,
        showWelcomeTxt: false,
        agentId: agentId,
        isIpValid: true,
        isMaskorRangeValid: true,

        showimportbox: false,
        importIps: [
          {
            id: 0,
            maskOrRange: "",
            network: "",
            networkType: "Single",
          },
        ],
      });
    }
  };

  addNetworkData = () => {
    let newNetworkListData = this.state.networkList.slice();
    let newNetworkListValid = this.state.networkListValid.slice();
    newNetworkListData.push({
      id: 0,
      maskOrRange: "",
      network: "",
      networkType: "Single",
    });
    newNetworkListValid.push({
      ipValid: true,
      MaskorRangeValid: true,
    });
    this.setState({
      networkList: newNetworkListData,
      networkListValid: newNetworkListValid,
      isIpValid: false,
      isDirty: true,
    });
  };
  setNetworkType = (i) => (proxy) => {
    let value = proxy.target.value;
    let newNetworkListData = this.state.networkList.slice();
    newNetworkListData[i].networkType = value;
    if (value === "Range" || value === "Mask") {
      this.setState({
        networkList: newNetworkListData,
        isMaskorRangeValid: false,
        isDirty: true,
      });
    } else {
      this.setState({
        networkList: newNetworkListData,
        isMaskorRangeValid: true,
        isDirty: true,
      });
    }
  };
  setNetwork = (i) => (e) => {
    let value = e.target.value.replace(/^\s+/g, "");
    let error = "";
    if (
      /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
        value
      )
    ) {
      let firstvalue = value.substring(0, value.indexOf("."));
      if (firstvalue === "0") {
        error = Resource.get("Invalid IP address (source)"); // error text
      } else if (firstvalue === "127") {
        error = Resource.get("Invalid IP address (loopback)"); // error text
      } else if (value.indexOf("169.254") === 0) {
        error = Resource.get("Invalid IP address (link-local)"); // error text
      } else if (value.indexOf("192.188.99") === 0) {
        error = Resource.get("Invalid IP address (anycast)"); // error text
      } else if (parseInt(firstvalue) >= 224 && parseInt(firstvalue) <= 239) {
        error = Resource.get("Invalid IP address (multicast)"); // error text
      } else if (parseInt(firstvalue) >= 240 && parseInt(firstvalue) <= 255) {
        error = Resource.get("Invalid IP address (multicast)"); // error text
      }
      if (error === "") {
        let newNetworkListData = this.state.networkList.slice();
        newNetworkListData[i].network = value;
        let newNetworkListValid = this.state.networkListValid.slice();
        newNetworkListValid[i].ipValid = true;
        this.setState({
          networkList: newNetworkListData,
          networkListValid: newNetworkListValid,
          isIpValid: true,
          isDirty: true,
        });
      } else {
        let newNetworkListData = this.state.networkList.slice();
        newNetworkListData[i].network = value;
        let newNetworkListValid = this.state.networkListValid.slice();
        newNetworkListValid[i].ipValid = false;
        newNetworkListValid[i].invalidIpMessage = error;
        this.setState({
          networkList: newNetworkListData,
          networkListValid: newNetworkListValid,
          isIpValid: false,
          isDirty: true,
        });
      }
    } else if (
      /(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))/gm.test(
        value
      )
    ) {
      if (/^FE[89ab].*/i.test(value)) {
        error = Resource.get("Invalid IP address (link-local)"); // error text
      } else if (/^FF.*/i.test(value)) {
        error = Resource.get("Invalid IP address (multicast)"); // error text
      } else if (/^(0*:)*1$/.test(value)) {
        error = Resource.get("Invalid IP address (loopback)"); // error text
      } else if (/^(0*:)*0*$/.test(value)) {
        error = Resource.get("Invalid IP address (default route)"); // error text
      }
      if (error === "") {
        let newNetworkListData = this.state.networkList.slice();
        newNetworkListData[i].network = value;
        let newNetworkListValid = this.state.networkListValid.slice();
        newNetworkListValid[i].ipValid = true;
        this.setState({
          networkList: newNetworkListData,
          networkListValid: newNetworkListValid,
          isIpValid: true,
          isDirty: true,
        });
      } else {
        let newNetworkListData = this.state.networkList.slice();
        newNetworkListData[i].network = value;
        let newNetworkListValid = this.state.networkListValid.slice();
        newNetworkListValid[i].ipValid = false;
        newNetworkListValid[i].invalidIpMessage = error;
        this.setState({
          networkList: newNetworkListData,
          networkListValid: newNetworkListValid,
          isIpValid: false,
          isDirty: true,
        });
      }
    } else {
      //Enter a valid IP address
      if (value == "") error = Resource.get("Enter a valid IP address");
      // error text
      else error = Resource.get("Invalid IP address"); // error text
      let newNetworkListData = this.state.networkList.slice();
      newNetworkListData[i].network = value;
      let newNetworkListValid = this.state.networkListValid.slice();
      newNetworkListValid[i].ipValid = false;
      newNetworkListValid[i].invalidIpMessage = error;
      this.setState({
        networkList: newNetworkListData,
        networkListValid: newNetworkListValid,
        isIpValid: false,
        isDirty: true,
      });
    }
  };
  setNetworkMaskorRange = (i) => (e) => {
    let value = parseInt(e.target.value.replace(/^\s+/g, ""));
    let error = Resource.get("Enter a valid Mask or Range"); // error text
    if (this.state.networkList[i].networkType === "Mask") {
      if (value >= 16 && value <= 30) {
        let newNetworkListData = this.state.networkList.slice();
        newNetworkListData[i].maskOrRange = value.toString();
        let newNetworkListValid = this.state.networkListValid.slice();
        newNetworkListValid[i].MaskorRangeValid = true;
        this.setState({
          networkList: newNetworkListData,
          networkListValid: newNetworkListValid,
          isMaskorRangeValid: true,
          isDirty: true,
        });
      } else {
        let newNetworkListData = this.state.networkList.slice();
        if (isNaN(value)) newNetworkListData[i].maskOrRange = "";
        else {
          newNetworkListData[i].maskOrRange = value.toString();
          if (value < 16)
            //The number entered is too small
            error = Resource.get("The number entered is too small");
          // error text
          else error = Resource.get("The number entered is too large"); // error text
        }

        let newNetworkListValid = this.state.networkListValid.slice();
        newNetworkListValid[i].MaskorRangeValid = false;
        newNetworkListValid[i].invalidMaskorRangeMessage = error;
        this.setState({
          networkList: newNetworkListData,
          networkListValid: newNetworkListValid,
          isMaskorRangeValid: false,
          isDirty: true,
        });
      }
    } else {
      if (
        value >=
          parseInt(
            this.state.networkList[i].network.substring(
              this.state.networkList[i].network.lastIndexOf(".") + 1
            )
          ) &&
        value <= 255
      ) {
        let newNetworkListData = this.state.networkList.slice();
        newNetworkListData[i].maskOrRange = value.toString();
        let newNetworkListValid = this.state.networkListValid.slice();
        newNetworkListValid[i].MaskorRangeValid = true;
        this.setState({
          networkList: newNetworkListData,
          networkListValid: newNetworkListValid,
          isMaskorRangeValid: true,
          isDirty: true,
        });
      } else {
        let newNetworkListData = this.state.networkList.slice();
        if (isNaN(value)) newNetworkListData[i].maskOrRange = "";
        else {
          if (
            value <
            parseInt(
              this.state.networkList[i].network.substring(
                this.state.networkList[i].network.lastIndexOf(".") + 1
              )
            )
          )
            //The number entered is too small
            error = Resource.get("The number entered is too small");
          // error text
          else error = Resource.get("The number entered is too large"); // error text
          newNetworkListData[i].maskOrRange = value.toString();
        }
        let newNetworkListValid = this.state.networkListValid.slice();
        newNetworkListValid[i].MaskorRangeValid = false;
        newNetworkListValid[i].invalidMaskorRangeMessage = error;
        this.setState({
          networkList: newNetworkListData,
          networkListValid: newNetworkListValid,
          isMaskorRangeValid: false,
          isDirty: true,
        });
      }
    }
  };
  removeNetworkData = (i) => {
    let newNetworkListData = this.state.networkList.slice();
    newNetworkListData.splice(i, 1);
    let newNetworkListValid = this.state.networkListValid.slice();
    newNetworkListValid.splice(i, 1);
    let j = 0;
    let ipvalid = true,
      maskorrangevalid = true;
    for (j = 0; j < newNetworkListValid.length; j++) {
      if (newNetworkListValid[j].ipValid === false) ipvalid = false;
      if (newNetworkListValid[j].MaskorRangeValid === false)
        maskorrangevalid = false;
    }
    this.setState({
      networkList: newNetworkListData,
      isDirty: true,
      isIpValid: ipvalid,
      isMaskorRangeValid: maskorrangevalid,
      networkListValid: newNetworkListValid,
    });
  };

  isAllowed = () => (evt) => {
    evt = evt ? evt : window.event;
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (
      (evt.ctrlKey && charCode === 86) ||
      (evt.ctrlKey && charCode === 67) ||
      (evt.ctrlKey && charCode === 65)
    ) {
      return true;
    }

    if (
      charCode > 31 &&
      charCode != 110 &&
      charCode !== 35 &&
      charCode !== 36 &&
      charCode !== 46 &&
      charCode !== 37 &&
      charCode !== 39 &&
      charCode !== 190 &&
      charCode !== 186 &&
      charCode !== 65 &&
      charCode !== 66 &&
      charCode !== 67 &&
      charCode !== 68 &&
      charCode !== 69 &&
      charCode !== 70 &&
      (charCode < 48 || charCode > 57) &&
      (charCode < 96 || charCode > 105)
    ) {
      evt.preventDefault();
      return false;
    }
    return true;
  };
  isNumber = () => (evt) => {
    evt = evt ? evt : window.event;
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (
      (evt.ctrlKey && charCode === 86) ||
      (evt.ctrlKey && charCode === 67) ||
      (evt.ctrlKey && charCode === 65)
    ) {
      return true;
    }
    if (
      charCode > 31 &&
      charCode !== 35 &&
      charCode !== 36 &&
      charCode !== 46 &&
      charCode !== 37 &&
      charCode !== 39 &&
      (charCode < 48 || charCode > 57) &&
      (charCode < 96 || charCode > 105)
    ) {
      evt.preventDefault();
      return false;
    }
    return true;
  };
  selectdisabled = (network) => {
    if (network.indexOf(":") !== -1) return true;
    else return false;
  };
  setImportIps = () => (e) => {
    let value = e.target.value.replace(/^\s+/g, "");
    let newimportIp = this.state.importIps;
    newimportIp[0].network = value;
    this.setState({
      importIps: newimportIp,
    });
  };
  okimportclick = () => {
    let i = 0;
    if (this.state.importIps[0].network === "") {
      let newnetworks = [
        { id: 0, maskOrRange: "", network: "", networkType: "Single" },
      ];
      let newnetworksvalid = [
        {
          ipValid: false,
          MaskorRangeValid: true,
          invalidIpMessage: "Enter a valid IP address",
          invalidMaskorRangeMessage: "",
        },
      ];
      let newNetworkListData = this.state.networkList.slice();
      let newNetworkListDataValid = this.state.networkListValid.slice();
      for (i = 0; i < newNetworkListData.length; i++) {
        if (
          newNetworkListData[i].network === "" &&
          newNetworkListData[i].maskOrRange === ""
        ) {
          newNetworkListData.splice(i, 1);
          newNetworkListDataValid.splice(i, 1);
        }
      }

      for (i = 0; i < newnetworks.length; i++)
        newNetworkListData.push(newnetworks[i]);
      for (i = 0; i < newnetworksvalid.length; i++)
        newNetworkListDataValid.push(newnetworksvalid[i]);
      let j = 0;
      let ipvalid = true,
        maskorrangevalid = true;
      for (j = 0; j < newNetworkListDataValid.length; j++) {
        if (newNetworkListDataValid[j].ipValid === false) ipvalid = false;
        if (newNetworkListDataValid[j].MaskorRangeValid === false)
          maskorrangevalid = false;
      }
      this.setState({
        showimportbox: false,
        networkList: newNetworkListData,
        networkListValid: newNetworkListDataValid,
        importIps: [
          { id: 0, maskOrRange: "", network: "", networkType: "Single" },
        ],
        isIpValid: ipvalid,
        isMaskorRangeValid: maskorrangevalid,
        disableAddButton: false,
        isDirty: true,
      });
    } else {
      let importips = this.state.importIps[0].network.split("\n");
      for (i = 0; i < importips.length; i++) {
        if (importips[i] === "") {
          importips.splice(i, 1);
          i--;
        }
      }
      let newnetworksvalid = importips.map((ip) => ({
        ipValid: true,
        MaskorRangeValid: true,
        invalidIpMessage: "",
        invalidMaskorRangeMessage: "",
      }));
      let newnetworks = importips.map((ip) => ({
        id: 0,
        maskOrRange: "",
        network: ip,
        networkType: "Single",
      }));
      for (i = 0; i < importips.length; i++) {
        let error = "";
        if (
          /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
            importips[i]
          )
        ) {
          let firstvalue = importips[i].substring(0, importips[i].indexOf("."));
          if (firstvalue === "0") {
            error = Resource.get("Invalid IP address (source)"); // error text
          } else if (firstvalue === "127") {
            error = Resource.get("Invalid IP address (loopback)"); // error text
          } else if (importips[i].indexOf("169.254") === 0) {
            error = Resource.get("Invalid IP address (link-local)"); // error text
          } else if (importips[i].indexOf("192.188.99") === 0) {
            error = Resource.get("Invalid IP address (anycast)"); // error text
          } else if (
            parseInt(firstvalue) >= 224 &&
            parseInt(firstvalue) <= 239
          ) {
            error = Resource.get("Invalid IP address (multicast)"); // error text
          } else if (
            parseInt(firstvalue) >= 240 &&
            parseInt(firstvalue) <= 255
          ) {
            error = Resource.get("Invalid IP address (multicast)"); // error text
          }
          if (error === "") {
            newnetworksvalid[i].ipValid = true;
          } else {
            newnetworksvalid[i].ipValid = false;
            newnetworksvalid[i].invalidIpMessage = error;
          }
        } else if (
          /(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))/gm.test(
            importips[i]
          )
        ) {
          if (/^FE[89ab].*/i.test(importips[i])) {
            error = Resource.get("Invalid IP address (link-local)"); // error text
          } else if (/^FF.*/i.test(importips[i])) {
            error = Resource.get("Invalid IP address (multicast)"); // error text
          } else if (/^(0*:)*1$/.test(importips[i])) {
            error = Resource.get("Invalid IP address (loopback)"); // error text
          } else if (/^(0*:)*0*$/.test(importips[i])) {
            error = Resource.get("Invalid IP address (default route)"); // error text
          }
          if (error === "") {
            newnetworksvalid[i].ipValid = true;
          } else {
            newnetworksvalid[i].ipValid = false;
            newnetworksvalid[i].invalidIpMessage = error;
          }
        } else {
          error = Resource.get("Invalid IP address"); // error text

          newnetworksvalid[i].ipValid = false;
          newnetworksvalid[i].invalidIpMessage = error;
        }
      }

      let newNetworkListData = this.state.networkList.slice();
      let newNetworkListDataValid = this.state.networkListValid.slice();
      for (i = 0; i < newNetworkListData.length; i++) {
        if (
          newNetworkListData[i].network === "" &&
          newNetworkListData[i].maskOrRange === ""
        ) {
          newNetworkListData.splice(i, 1);
          newNetworkListDataValid.splice(i, 1);
        }
      }

      for (i = 0; i < newnetworks.length; i++)
        newNetworkListData.push(newnetworks[i]);
      for (i = 0; i < newnetworksvalid.length; i++)
        newNetworkListDataValid.push(newnetworksvalid[i]);
      let j = 0;
      let ipvalid = true,
        maskorrangevalid = true;
      for (j = 0; j < newNetworkListDataValid.length; j++) {
        if (newNetworkListDataValid[j].ipValid === false) ipvalid = false;
        if (newNetworkListDataValid[j].MaskorRangeValid === false)
          maskorrangevalid = false;
      }
      this.setState({
        showimportbox: false,
        networkList: newNetworkListData,
        networkListValid: newNetworkListDataValid,
        importIps: [
          { id: 0, maskOrRange: "", network: "", networkType: "Single" },
        ],
        isIpValid: ipvalid,
        isMaskorRangeValid: maskorrangevalid,
        disableAddButton: false,
        isDirty: true,
      });
    }
  };

  closeimportclick = () => {
    this.setState({
      showimportbox: false,
      importIps: [
        {
          id: 0,
          maskOrRange: "",
          network: "",
          networkType: "Single",
        },
      ],
      disableAddButton: false,
    });
  };
  isKeyAllowed = () => (evt) => {
    evt = evt ? evt : window.event;
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (
      (evt.ctrlKey && charCode === 86) ||
      (evt.ctrlKey && charCode === 67) ||
      (evt.ctrlKey && charCode === 65)
    ) {
      return true;
    }
    if (
      charCode > 31 &&
      charCode != 110 &&
      charCode !== 35 &&
      charCode !== 36 &&
      charCode !== 46 &&
      charCode !== 33 &&
      charCode !== 34 &&
      charCode !== 37 &&
      charCode !== 38 &&
      charCode !== 39 &&
      charCode !== 40 &&
      charCode !== 190 &&
      charCode !== 186 &&
      charCode !== 13 &&
      charCode !== 65 &&
      charCode !== 66 &&
      charCode !== 67 &&
      charCode !== 68 &&
      charCode !== 69 &&
      charCode !== 70 &&
      (charCode < 48 || charCode > 57) &&
      (charCode < 96 || charCode > 105)
    ) {
      evt.preventDefault();
      return false;
    }
    return true;
  };
  onDescription = () => {
    this.setState({
      savepopUpOpen: false,
    });
  };
  handleRequestClose = () => {
    this.setState({
      savepopUpOpen: false,
    });
  };
  render() {
    let headerLabel = Resource.get("New Range Scope"); //New Range Scope
    let nameLabel = Resource.get("Name"); //Name
    let ScopeDefinitionLabel = Resource.get("Scope Definition"); //Scope Definition
    let resetLabel = Resource.get("Reset"); //Reset
    let requiredLabel = Resource.get("Required"); //Required
    let rangeLabel = Resource.get("to"); //to
    let showWelcomeTxt = this.state.showWelcomeTxt;
    let addLabel = Resource.get("Add"); //Add
    let updateLabel = Resource.get("Update"); //Update
    let importLabel = Resource.get("Import"); //import
    let cancelLabel = constant.CANCEL;
    let deleteText = this.getDeleteTag();
    
    let view = this.state.networkList.map((networkList, i) => {
      return (
        <div style={{ width: "100%" }}>
          <div style={{ display: "inline-block", width: "100%" }}>
            <div
              style={{
                
                display: "flex",
                flexDirection: "row",width:'100%'
              }}
            >
           <FormControl style={{ width: "150px" }} >
              <SelectField style={{marginTop:'18px',marginRight:'16px'}}
              MenuProps={{MenuListProps:{'aria-label': ScopeDefinitionLabel+"ul"}}}
               role="combobox" aria-label={ScopeDefinitionLabel+" type"}
               
                onChange={this.setNetworkType(i)}
                value={this.state.networkList[i].networkType}
               
                disabled={this.selectdisabled(
                  this.state.networkList[i].network
                )}
              >
                {this.scopes.map((scope) => (
                  <MenuItem key={scope} value={scope} children={scope} />
                ))}
              </SelectField>
              </FormControl>
             
              {networkList.networkType === "Range" ||
              networkList.networkType === "Mask" ? (
                <div  style={{
                
                display: "flex",
                flexDirection: "row"
              }}
                  
                >
                  
                  <FormControl  >
            <InputLabel id="input-networkLabel1" ><span className="credentialvault-required-label">{requiredLabel}</span></InputLabel>
                          
                           
                    <TextField
                    
                      onChange={this.setNetwork(i)}
                      
                      id="network1"
                      error={!this.state.networkListValid[i].ipValid ?this.state.networkListValid[i].invalidIpMessage:null}
                    
                      value={this.state.networkList[i].network}
                      onKeyDown={this.isAllowed()}
                      inputProps={{'aria-required':true,
                      'aria-label':   Resource.get("Network") //Network
                      
                         }}
                    />
                   </FormControl>
                  
                 
                   
                 <FormControl style={{marginTop:'38px',marginLeft:'12px',marginRight:'12px'}}>
                 <FormLabel id="input-networkLabel" htmlFor="network" > {networkList.networkType === "Range" ?rangeLabel:'/'}</FormLabel>
                 </FormControl>
                 
             
                
                 <FormControl  >

            <InputLabel id="input-networkLabel" >
            
            <span className="credentialvault-required-label">{requiredLabel}</span></InputLabel>
                          
                       
                    <TextField 
                     
                      onChange={this.setNetworkMaskorRange(i)}
                     
                      id="network"
                      error={!this.state.networkListValid[i].MaskorRangeValid? this.state.networkListValid[i].invalidMaskorRangeMessage:null}
                      inputProps={{'aria-required':true,
                      'aria-label':   Resource.get("Network") //Network
                      
                         }}
                      value={this.state.networkList[i].maskOrRange}
                      onKeyDown={this.isNumber()}
                      
                    />
                    </FormControl>
                   
                   
                 
                </div>
              ) : (
                
                <FormControl >
            <InputLabel id="input-networkLabel" htmlFor="network" > 
              
            <span className="credentialvault-required-label">{requiredLabel}</span></InputLabel>
                          
                       
                    <TextField labelId="input-networkLabel"
                     
                      onChange={this.setNetwork(i)}
                      id="network"
                      error={!this.state.networkListValid[i].ipValid? this.state.networkListValid[i].invalidIpMessage:null}
                      inputProps={{'aria-required':true,
                      'aria-label':   Resource.get("Network") //Network
                      
                         }}
                      value={this.state.networkList[i].network}
                      onKeyDown={this.isAllowed()}
                      
                    />
                    </FormControl>
                 
             
              )}
            
            {this.state.networkList.length > 1 ? (
              <AccessibleTooltip title={
                 //Delete Icon
                Resource.get("Delete Icon")}>
                <IconButton aria-label={
                   //Delete Icon
                  Resource.get("Delete Icon")}
                  style={{ marginLeft:'auto'}}
                  onClick={() => this.removeNetworkData(i)}
                >
              <img
                src={deleteIcon}
                //Delete Icon
                aria-label= {Resource.get("Delete Icon")}
               
                onClick={() => this.removeNetworkData(i)}
              />
              </IconButton></AccessibleTooltip>
            ) : (
              null
            )}
            </div>
          </div>
        </div>
      );
    });

    return (
      <div className="scopes">
        {this.state.isLoading ? (
          <div
            style={{
              position: "absolute",
              backgroundColor: "rgba(218, 208, 208,0.15)",
              top: "0px",
              height: "100%",
              width: "100%",
              zIndex: "5",
            }}
          >
            <CircularProgress
              style={{ color: "#3272D9" }}
              className="delayed__spinner"
            />
          </div>
        ) : (
          ""
        )}
        <ScopesCatalogRedux
          populateScope={this.updateScopeForm}
          refreshWelcome={this.enableWelcomeText}
          OnScopesReady={(api) => {
            this.scopesCatalogHandle = api;
          }}
        />
        {showWelcomeTxt ? (
          <div style={{ width: "75%" }}>
            <ScopeWelcomeText />
          </div>
        ) : (
          <div className="add-edit-credential" id={"add-edit-credential"}>
            <h3 className="header"> {headerLabel}</h3>
            {/*this.state.error.errorOccurred ? (
              <div className="error-ribbon">
                <div style={{ display: "inline-block", width: "100%" }}>
                  <div
                    style={{
                      display: "inline-block",
                      marginLeft: "22px",
                      marginRight: "22px",
                      verticalAlign: "middle",
                    }}
                  >
                    <a style={{ float: "left" }}>
                      <img 
                      src={errorIcon} 
                      //Error Icon
                      aria-label= {Resource.get("Error Icon")}/>
                    </a>
                  </div>
                  <div style={{ display: "inline-block", width: "90%" }}>
                    <span>{this.state.error.errorMessage}</span>
                    <span
                      style={{
                        borderLeft: "2px solid #999999",
                        display: "inline-block",
                        margin: "10px 15px -5px",
                        paddingTop: "20px",
                      }}
                    ></span>
                    <span>{this.state.error.errorDescription}</span>
                  </div>
                  <div
                    style={{
                      display: "inline-block",
                      cursor: "pointer",
                      marginRight: "20px",
                      marginLeft: "10px",
                      verticalAlign: "middle",
                    }}
                    onClick={() => this.closeError()}
                  >
                    <a style={{ float: "left" }}>
                      {" "}
                      <img src={deleteIcon}
                      //Delete Icon
                      aria-label= {Resource.get("Delete Icon")} />
                    </a>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )*/}
            <div style={{ width: "40%" }}>
            <FormControl fullWidth>
            <InputLabel id="input-scopeNameLabel" htmlFor="scopeName" > {nameLabel}<span className="credentialvault-required-label">{requiredLabel}</span></InputLabel>
                          
                           
                          <TextField labelId="input-scopeNameLabel"
                           inputProps={{'aria-required':true}}
                          
                aria-label={nameLabel}
                onChange={this.handleScopeNameChange.bind(this)}
              //  onBlur={this.handleScopeNameChange.bind(this)}
                autoFocus
                id="scopeName"
                value={this.state.scopeName}
                
              />
              </FormControl>
            </div>
            <div style={{ width: "40%",marginTop:'8px' }}>
              <div style={{display:'flex',flexDirection:'row',width:'100%'}}>
                <InputLabel style={{float:'left'}}>{ScopeDefinitionLabel}</InputLabel>
              
                <div
              style={{marginLeft:'auto'}}>
                     <AccessibleTooltip title={`Add scope`}>
                <IconButton aria-label={`Add scope`}
                style={{marginRight:'8px'}}
                  onClick={this.addNetworkData}
                >
                    <img
                      src={add}
                     //Add
                      aria-label= {Resource.get("Add")}
                     
                     disabled={this.state.disableAddButton}
                    />
                    </IconButton>
                    </AccessibleTooltip>
                  
                  <AccessibleTooltip title={`Import`}>
                <IconButton aria-label={`Import`}
                 
                  onClick={this.importclick}
                >
                  <img
                    src={importicon}
                    // Import
                    aria-label= {Resource.get("Import")}
                  
                    
                  />
                  </IconButton>
                  </AccessibleTooltip>
                  </div>
               
              </div>
              {this.state.showimportbox ? (
                <div
                  style={{
                    width: "100%",
                   
                   
                  }}
                >
                 
                 <div
                  style={{
                   display:'flex',flexDirection:'row'
                   
                  }}
                >
<InputLabel>{this.enterMultipleIPs} </InputLabel> <AccessibleTooltip title={
  Resource.get("Delete Icon") //Delete Icon
}>
               <IconButton
          
          onClick={this.closeimportclick.bind(this)}
              //Delete Icon
              aria-label= {Resource.get("Delete Icon")}
            style={{ marginLeft:'auto' }}
          >
            <img
          //Delete Icon
          alt= {Resource.get("Delete Icon")}
                    //Delete Icon
                    aria-label= {Resource.get("Delete Icon")}
                      src={deleteIcon}
                    
                    />
          </IconButton>
</AccessibleTooltip>
</div><FormControl style={{width:'100%',marginBottom:'8px'}}>
                  <TextField multiline
                    name="importbox" 
                    autoFocus
                    inputProps={{'aria-label':this.enterMultipleIPs}}
                    onChange={this.setImportIps()}
                    onKeyDown={this.isKeyAllowed()}
                    value={this.state.importIps[0].network}
                    maxRows={5}
                  />
                  </FormControl>
                  <div
                    style={{
                     
                      float: "right",
                     
                    }}
                   
                  >
                    <Button  onClick={this.okimportclick.bind(this)}>{importLabel}</Button>
                  </div>
                </div>
              ) : (
                view
              )}
            </div>
            <ScopesCredentialsRedux
              makeScopeDirty={this.setCredentialsDirtyFlag}
              scopeId={this.state.scopeId}
              OnCredintialsReady={(api) => {
                this.credentialVaultHandle = api;
              }}
              mode={this.state.scopeId === "" ? "add" : "edit"}
              selectedWMICredentials={this.state.wmiProfilesId}
              selectedUnixCredentials={this.state.shellProfilesId}
              SelectedSNMPCredentials={this.state.snmpProfilesId}
            />
            {!this.state.isDirty &&
            this.state.scopeId !== "" &&
            !this.state.isCredentialsDirty ? (
              deleteText
            ) : (
              <div style={{ paddingTop: "38px" }}>
                <Button
                 
                  aria-label={this.state.scopeId === "" ? addLabel : updateLabel}
                  disabled={
                    this.state.scopeName === "" ||
                    !this.state.isIpValid ||
                    (typeof this.state.isMaskorRangeValid === "boolean"
                      ? !this.state.isMaskorRangeValid
                      : false)
                  }
                 
                  onClick={this.SaveorUpdateScope()}
                >
                  {this.state.scopeId === "" ? addLabel : updateLabel}
                </Button>
                <Button
                  
                  aria-label={resetLabel}
                  variant="text"
                  onClick={this.resetClick.bind(this)}
                >
                  <span>{resetLabel}</span>
                </Button>
              </div>
            )}
          </div>
        )}
        {this.state.savepopUpOpen === true  && this.state.popErrorType!=='error'? (
              <div>
                {/*<Snackbar
                  key="snackbar"
                  open={this.state.savepopUpOpen}
                  autoHideDuration={2000}
                  onClose={() => this.setState({ savepopUpOpen: null })}
                // onActionTouchTap={this.onDescription}
                // onRequestClose={this.handleRequestClose}
                >
                 
                    {this.state.popMassage}
                  
                </Snackbar>*/}
                <CustomSnackbar message={this.state.popMassage} severity={this.state.popErrorType} close={() => this.setState({ savepopUpOpen: null })} autoHideDuration={4000} open={this.state.savepopUpOpen} />
     
              </div>
            ) : ([])}
      <div>
          
          <CustomSnackbar  message={`${this.state.error.errorMessage} ${this.state.error.errorDescription?": "+this.state.error.errorDescription:""}`} severity={"error"} 
          close={() => this.closeError()} autoHideDuration={4000} open={this.state.error.errorOccurred} />
     
        </div>
       
      </div>
    );
  }
}
export default Scope;
