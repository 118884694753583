// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Settings
  content["Settings"] =
  "Settings";

  //Alarms and Notifications
  content["Alarms and Notifications"] =
  "Alarms and Notifications";

  //Dashboard and Reports
  content["Dashboard and Reports"] =
  "Dashboard and Reports";

  //Integrations
  content["Integrations"] =
  "Integrations";

  //Infrastructure
  content["Infrastructure"] =
  "Infrastructure";

  //System Administration
  content["System and Administration"] =
  "System and Administration";

  // END OF LOCALIZATION

export default content;
