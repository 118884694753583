import React, { Component } from 'react'
import PropTypes from 'prop-types'
import isEqual from 'lodash/isEqual'

import Filter from '../Filter'

import MetricsSelection from './MetricsSelection'


class Selection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: '',
      selectAll: false,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (this.state.searchTerm !== nextState.searchTerm || !isEqual(this.props.types, nextProps.types))
  }

  handleFilterChange(searchTerm) {
    this.setState({
      searchTerm,
    });
  }

  getFilteredMetricTypes() {
    const { searchTerm } = this.state;
    const {
      types,
    } = this.props;
    if (searchTerm.length) {
      return types.filter((metricType) => {
        return metricType.name.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0
      });
    } else {
      return types
    }
  }

  onSelectAll() {
    const { selectAll } = this.state
    const {
      onSelectAll,
      setColumns,
      contextValue,
    } = this.props
    this.setState({
      selectAll: !selectAll,
    }, () => {
      onSelectAll(contextValue, this.state.selectAll)
      if (this.state.selectAll === true) {
        setColumns(2)
      } else {
        setColumns(1)
      }
    })
  }

  render() {
    const {
      searchTerm,
      selectAll,
    } = this.state;

    return (
      <div className="selection">
        <div className="filter-box">
          <Filter onChange={this.handleFilterChange.bind(this)} value={searchTerm} />
        </div>
        <MetricsSelection
          metricTypes={this.getFilteredMetricTypes()}
          initiallyOpen={true} />
      </div>
    );
  }
}

Selection.propTypes = {
  types: PropTypes.array,
  onCheck: PropTypes.func,
}

export default Selection;
