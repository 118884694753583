import React from "react";
import PropTypes from "prop-types";

const LEVEL_CLEAR = 0;
const LEVEL_INFO = 1;
const LEVEL_WARNING = 2;
const LEVEL_MINOR = 3;
const LEVEL_MAJOR = 4;
const LEVEL_CRITICAL = 5;

const SeverityIcon = (props) => {
  var style = { borderRadius: "100px", padding: "4px 8px" };
  if (props.size) {
    style.width = props.size.width;
    style.height = props.size.height;
  }

  if (props.level === LEVEL_CRITICAL) {
    return (
      <div aria-label="Critical" style={{ ...style, backgroundColor: "#DE1B1B", color: "white" }}>
        Critical
      </div>
    );
  } else if (props.level === LEVEL_MAJOR) {
    return (
      <div aria-label="Major" style={{ ...style, backgroundColor: "#F78F4A", color: "#4D270F" }}>
        Major
      </div>
    );
  } else if (props.level === LEVEL_MINOR) {
    return (
      <div aria-label="Minor" style={{ ...style, backgroundColor: "#F7CD3B", color: "#454208" }}>
        Minor
      </div>
    );
  } else if (props.level === LEVEL_WARNING) {
    return (
      <div aria-label="Warning" style={{ ...style, backgroundColor: "#80BED9", color: "#000000" }}>
        Warning
      </div>
    );
  } else if (props.level === LEVEL_INFO) {
    return (
      <div aria-label="Info"
        style={{
          ...style,
          backgroundColor: "white",
          color: "#3272D9",
          border: "3px solid #3272D9",
          padding: "1px 5px",
        }}
      >
        Info
      </div>
    );
  } else if (props.level === LEVEL_CLEAR) {
    return (
      <div  aria-label="Clear" style={{ ...style, backgroundColor: "#2A854E", color: "white" }}>
        Clear
      </div>
    );
  } else {
    return null;
  }
};

SeverityIcon.propTypes = {
  level: PropTypes.number.isRequired,
  size: PropTypes.object,
};

export default SeverityIcon;

export {
  LEVEL_CRITICAL,
  LEVEL_MAJOR,
  LEVEL_MINOR,
  LEVEL_WARNING,
  LEVEL_INFO,
  LEVEL_CLEAR,
};
