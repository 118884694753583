// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //settings
  content["Settings"] =
  "Einstellungen";

  //admin console
  content["Admin Console"] =
  "Admin-Konsole";

  // END OF LOCALIZATION

export default content;
