import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, FormControl, TextField, InputAdornment,Typography, Tooltip } from '@mineral/core'
import { toNumber } from 'lodash'
import HelpingText from './HelpingText'
import { REQUIRED, VALUE_SHOULD_BE_GREATER_THAN, VALUE_SHOULD_BE_LESS_THAN } from '../../settings/portlets/slm/ResourceKeys'
import { useStyles } from './muiStyle'
import { CaretDown, CaretUp } from '@mineral/icons'
import './customNumberField.less'
import AccessibleTooltip from '../AccessibleTooltip'
import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded'
const CustomNumberField = ({
  children,
  min = null,
  max = null,
  step,
  id,
  value,
  size,
  errorMessage,
  hint,
  name,
  srName,
  onChange,
  disabled,
  required,
  multiline,
  style,
  fullWidth,
  InputProps,
  unitsOfMeasurement,
  readOnly,

}) => {
  const classes = useStyles()

  const [edited, setEdited] = useState(false)
  const [errorOwnMessage, setErrorOwnMessage] = useState('')
  const [focused,setFocused] =useState(false);

  const settings = {
    small: { height: 32 },
    medium: { height: 40 },
  }
  const { height } = settings[size]
  const rawValue = value
  value = toNumber(value)

  const isMax = (value, max) => {
    return max !== null && value >= max
  }

  const isMin = (value, min) => {
    return min !== null && value <= min
  }

  const selfValidate = (value) => {
    if (min !== null && value < min) {
      setErrorOwnMessage(`${VALUE_SHOULD_BE_GREATER_THAN} ${min}`)
      return
    }
    if (max !== null && value > max) {
      setErrorOwnMessage(`${VALUE_SHOULD_BE_LESS_THAN} ${max}`)
      return
    }

    setErrorOwnMessage('')
  }

  const handleChange = (e) => {
    if (!edited) setEdited(true)

    selfValidate(e.target.value)
    onChange(e)
  }

  const handleUpClick = () => {
    let newValue = value + step
    if (min !== null && value < min) newValue = min

    handleChange({ target: { value: newValue, id } })
  }

  const handleDownClick = () => {
    let newValue = value - step
    if (max !== null && value > max) newValue = max

    handleChange({ target: { value: newValue, id } })
  }
  
  const onFocus = () => setFocused(true)
const onBlur = () => setFocused(false)

  return (
    <FormControl className="input__container input__container--number-field" style={style}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        {!name && srName ? (
          <label htmlFor={id} className="input__label sr-only">
            {srName}
          </label>
        ) : (
          <label htmlFor={id} className="input__label">
            {name}
          </label>
        )}
        <span className="input__error">{required && REQUIRED}</span>
      </div>

      <div style={{ display: 'flex' }}>
        <div className="wrapper" style={{ width: '100%' }}>
          <Tooltip title={value}>
            <TextField
              id={id}
              sx={{
                // backgroundColor: (disabled || readOnly) && '#EBEFF5',
                fontSize: 14,
                color: disabled && '#58606E',
                // WebkitTextFillColor: disabled && '#58606E !important',
              }}
              onFocus={onFocus} onBlur={onBlur}
              value={value}
              disabled={disabled}
              readOnly={readOnly}
              multiline={multiline}
              name={'test name'}
              type="number"
              size={size}
              variant="outlined"
              onChange={handleChange}
             

              inputProps={{
               
         
          
                'aria-label': name || srName || '',
                name: 'test name',
                min,
                max,
                style: {
                  borderBottomRightRadius: 0,
                  borderTopRightRadius: 0,
                  color: disabled && '#58606E',
                  backgroundColor: readOnly && '#EBEFF5',
                },
                classes: {
                  input: classes.input,
                  root: classes.root,
                 
                  disabled: classes.disabledInput,
                  notchedOutline: !(errorMessage || errorOwnMessage) ? classes.notchedOutline : classes.notchedOutlineError,
                },
                ...InputProps,
              }}
              fullWidth={fullWidth}
              style={{ width: '100%' }}
            />
          </Tooltip>
          <div className="units">
            <span className="invisible" id={"hiddenValue"+id}>
              {rawValue ? rawValue : value}
            </span>
            <Typography varia className="units-value" id={"unitsValue"+id} sx={{ color: disabled ? '#8E99AC' : '#212121' }}>
              {' '}
              {unitsOfMeasurement}
            </Typography>
          </div>
        </div>

        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {/* OktaId: hg044122 DE534503: VPAT Issues(added value in aria-label) in Add New SLA page  */}
          <AccessibleTooltip title="Increase value">
            <Button
              disabled={disabled || readOnly || isMax(value, max)}
              variant="outlined"
              sx={{
                marginTop: '2px',
                minWidth: '24px',
                maxHeight: '16px',
                padding: 0,
                borderRadius: 0,
                borderTopRightRadius: '4px',
              }}
              aria-label={(name || srName ? `${name || srName} add` : 'add')+' Current value:'+value}
              onClick={handleUpClick}
              role="button"
            >
              <CaretUp fontSize="small" />
            </Button>
          </AccessibleTooltip>
          <AccessibleTooltip title="Decrease value">
            <Button
              disabled={disabled || readOnly || isMin(value, min)}
              variant="outlined"
              sx={{
                minWidth: '24px',
                maxHeight: '16px',
                padding: 0,
                borderRadius: 0,
                borderBottomRightRadius: '4px',
              }}
              aria-label={(name || srName ? `${name || srName} subtract` : 'subtract')+' Current value:'+value}
              onClick={handleDownClick}
              role="button"
            >
              <CaretDown fontSize="small" />
            </Button>
          </AccessibleTooltip>
        </div>
      </div>

      <div role={focused?'alert':""}>
        <HelpingText hint={hint} errorMessage={errorMessage || errorOwnMessage} />
      </div>
    </FormControl>
  )
}

CustomNumberField.propTypes = {
  id: PropTypes.string,
  style: PropTypes.any,
  value: PropTypes.any.isRequired,
  errorMessage: PropTypes.string,
  hint: PropTypes.string,
  name: PropTypes.string,
  size: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  fullWidth: PropTypes.bool,
}

CustomNumberField.defaultProps = {
  required: false,
  disabled: false,
  min: null,
  max: null,
  step: 1,
  size: 'medium',
  fullWidth: true,
}

export default CustomNumberField
