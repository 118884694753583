import { INVENTORY_FILTER_ENTITIES, SET_QUICK_FILTER } from "./actionTypes";

const inventoryEntityFilter = (
  state = { colFilters: [], quickFilter: "" },
  action
) => {
  switch (action.type) {
    case INVENTORY_FILTER_ENTITIES:
      return action.searchData;
    case SET_QUICK_FILTER:
      return { ...state, quickFilter: action.quickFilter };
    default:
      return state;
  }
};

export default inventoryEntityFilter;
