//Open Alarms
export const REQUEST_OPEN_ALARMS = 'REQUEST_OPEN_ALARMS'
export const FETCH_OPEN_ALARMS = 'FETCH_OPEN_ALARMS'
export const FETCH_OPEN_ALARMS_SUCCESS = 'FETCH_OPEN_ALARMS_SUCCESS'
export const FETCH_OPEN_ALARMS_FAILURE = 'FETCH_OPEN_ALARMS_FAILURE'
export const POLL_OPEN_ALARMS_START = 'POLL_OPEN_ALARMS_START'
export const POLL_OPEN_ALARMS_STOP = 'POLL_OPEN_ALARMS_STOP'

//Active Devices
export const REQUEST_ACTIVE_DEVICES = 'REQUEST_ACTIVE_DEVICES'
export const FETCH_ACTIVE_DEVICES = 'FETCH_ACTIVE_DEVICES'
export const FETCH_ACTIVE_DEVICES_SUCCESS = 'FETCH_ACTIVE_DEVICES_SUCCESS'
export const FETCH_ACTIVE_DEVICES_FAILURE = 'FETCH_ACTIVE_DEVICES_FAILURE'
export const POLL_ACTIVE_DEVICES_START = 'POLL_ACTIVE_DEVICES_START'
export const POLL_ACTIVE_DEVICES_STOP = 'POLL_ACTIVE_DEVICES_STOP'

//Role Devices
export const REQUEST_ROLE_DEVICES = 'REQUEST_ROLE_DEVICES'
export const FETCH_ROLE_DEVICES = 'FETCH_ROLE_DEVICES'
export const FETCH_ROLE_DEVICES_SUCCESS = 'FETCH_ROLE_DEVICES_SUCCESS'
export const FETCH_ROLE_DEVICES_FAILURE = 'FETCH_ROLE_DEVICES_FAILURE'
export const POLL_ROLE_DEVICES_START = 'POLL_ROLE_DEVICES_START'
export const POLL_ROLE_DEVICES_STOP = 'POLL_ROLE_DEVICES_STOP'

//Technologies
export const REQUEST_TOP_TECHNOLOGIES = 'REQUEST_TOP_TECHNOLOGIES'
export const FETCH_TOP_TECHNOLOGIES = 'FETCH_TOP_TECHNOLOGIES'
export const FETCH_TOP_TECHNOLOGIES_SUCCESS = 'FETCH_TOP_TECHNOLOGIES_SUCCESS'
export const FETCH_TOP_TECHNOLOGIES_FAILURE = 'FETCH_TOP_TECHNOLOGIES_FAILURE'
export const POLL_TOP_TECHNOLOGIES_START = 'POLL_TOP_TECHNOLOGIES_START'
export const POLL_TOP_TECHNOLOGIES_STOP = 'POLL_TOP_TECHNOLOGIES_STOP'

//Top Groups
export const REQUEST_TOP_GROUPS = 'REQUEST_TOP_GROUPS'
export const FETCH_TOP_GROUPS = 'FETCH_TOP_GROUPS'
export const FETCH_TOP_GROUPS_SUCCESS = 'FETCH_TOP_GROUPS_SUCCESS'
export const FETCH_TOP_GROUPS_FAILURE = 'FETCH_TOP_GROUPS_FAILURE'
export const POLL_TOP_GROUPS_START = 'POLL_TOP_GROUPS_START'
export const POLL_TOP_GROUPS_STOP = 'POLL_TOP_GROUPS_STOP'

//Top Devices
export const REQUEST_TOP_DEVICES = 'REQUEST_TOP_DEVICES'
export const FETCH_TOP_DEVICES = 'FETCH_TOP_DEVICES'
export const FETCH_TOP_DEVICES_SUCCESS = 'FETCH_TOP_DEVICES_SUCCESS'
export const FETCH_TOP_DEVICES_FAILURE = 'FETCH_TOP_DEVICES_FAILURE'
export const POLL_TOP_DEVICES_START = 'POLL_TOP_DEVICES_START'
export const POLL_TOP_DEVICES_STOP = 'POLL_TOP_DEVICES_STOP'

export const HOME_SCREEN_COMPONENTS_CHANGE = 'HOME_SCREEN_COMPONENTS_CHANGE'
