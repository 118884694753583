// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Settings
  content["Settings"] =
  "설정";

  //INFRASTRUCTURE
  content["INFRASTRUCTURE"] =
  "인프라";

  //SYSTEM ADMINISTRATION
  content["SYSTEM ADMINISTRATION"] =
  "사이트 관리";

  //INTEGRATIONS
  content["INTEGRATIONS"] =
  "통합";

  //ALARMS AND NOTIFICATIONS
  content["ALARMS AND NOTIFICATIONS"] =
  "경보 및 알림";

  // END OF LOCALIZATION

export default content;
