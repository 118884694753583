import { call, put, takeEvery, takeLatest, take, cancel } from 'redux-saga/effects'
import { delay } from 'redux-saga/effects'
import { GET_SCOPES, POLL_SCOPES_START, POLL_SCOPES_STOP } from './actionTypes'
import { fetchScopesSuccess, fetchScopesFailure } from './actions'
import scopes from './scopes';

//Worker saga - For scopes

function* fetchScopes(params) {
    try {
        const response = yield call(scopes.getScopes,params)
        yield put(fetchScopesSuccess(response.data))
    } catch (error) {
        yield put(fetchScopesFailure(error))
    }
}

function* pollScopes(action) {
    while (true) {
        yield delay(30000)
        yield call(fetchScopes,{agentId: action.agentId})
    }
}

/*
  Starts fetchScopes on each dispatched `GET_SCOPES` action.
  Allows concurrent fetches of user.
*/
export function* getScopesSaga() {
    yield takeEvery(GET_SCOPES, fetchScopes)
}


export function* scopesPollingSaga() {
    while (true) {
        const watcherInstance = yield takeLatest(POLL_SCOPES_START, pollScopes)
        // cancel polling on POLL_SCOPES_STOP
        yield take(POLL_SCOPES_STOP)
        yield cancel(watcherInstance)
    }
}