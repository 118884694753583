import { connect } from 'react-redux'
import MenuItem from './MenuItem'
import { loadCommon } from './../../../utils/common'

const mapDispatchToProps = dispatch => {
  return {
    handleClick: (appId, endpointUrl, tenantId) => {
      loadCommon().then(ca_cloud => {
        ca_cloud.launchProduct({
          tenantId: tenantId,
          appId: appId,
          endpointUrl: endpointUrl,
        })
      })
    },
  }
}

const mapStateToProps = state => {
  return {
    daysRemaining: state.trialDaysRemaining,
    tenantId: state.user.tenantID,
  }
}
const MenuItemRedux = connect(mapStateToProps, mapDispatchToProps)(MenuItem)

export default MenuItemRedux
