import React from 'react'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { TextField } from '@mineral/core'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import dayjs from 'dayjs'
import './mineralDateTimePicker.less'

export const MineralDateTimePicker = ({
  id,
  disabled,
  label,
  value,
  placeholder,
  onValueChange,
  minDateTime,
  minDate,
  maxDate,
  maxDateTime,
}) => {
  const [dateValue, setValue] = React.useState(dayjs(value))
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateTimePicker
        id={id}
        label={label}
        value={dateValue}
        componentsProps={{
          switchViewButton: {
            sx: {
              margin: '20px 5px',
            },
          },
        }}
        onChangeRaw={ (e) => {
  e.preventDefault();
}}
        onChange={(newValue) => {
         let dateValue= newValue?.$d
        if(dateValue instanceof Date && !isNaN(dateValue))
        {
          setValue(newValue)
          onValueChange(newValue?.$d)
        }
        }
        }
        
        className="mineralDateTimePickerWidget"
        minDateTime={minDateTime ? dayjs(minDateTime) : undefined}
        maxDateTime={maxDateTime ? dayjs(maxDateTime) : undefined}
        minDate={minDate ? dayjs(minDate) : undefined}
        maxDate={maxDate ? dayjs(minDate) : undefined}
        disabled={disabled}
        PaperProps={{
          className: 'mineralDateTimePicker',
        }}
        PopperProps={{
          "aria-label":{label}
        }}
        InputProps={{
          className: 'mineralDateTimePicker_TextBox',
        }}
       OpenPickerButtonProps={{tooltip:'Change date'}}

        renderInput={(params) => (
          <TextField fullWidth={true}
            {...params}
            
            InputProps={{
              ...params.InputProps,
              style: { height: '32px'},
            }}
            inputProps={{
              ...params.inputProps,
              placeholder: placeholder || 'Select Date & Time',
              readOnly:true
            }}
            readOnly={true}
            
          />
        )}
      />
    </LocalizationProvider>
  )
}
