import React, {Component} from 'react';
import connect from './../../utils/connect';
import  {CSSTransition}  from 'react-transition-group';
import Resource from './Resource'

class SetupWizardContainer extends Component {
	
toggleShowAll = () =>{
 this.props.toggleShowAll(this.props.label);
}
  render() {
	  //Show All
	  let msg1 = Resource.get('Show All');
	  //Show Less
	  let msg2 = Resource.get('Show Less');
	  //Technology
	  let msg3 = Resource.get('Technology');
	//Technologies
	  let msg4 = Resource.get('Technologies');
    return (
        <div className='setup-wizard-section'>
          <div className="setup-wizard-section-header">
            <h3 className="name">{this.props.label.toUpperCase()}</h3>
            <span className="count" style={{paddingLeft:17}}>{this.props.count + ' '+ (this.props.count > 1 ? msg4:msg3)}</span>
          </div>
          
          <div className='setup-wizard-section-cards'>
          {/* <CSSTransition  component="div" transitionName="expand" transitionEnterTimeout={450} transitionLeaveTimeout={200}> */}
            {this.props.children}
            {/* </CSSTransition > */}
          </div>
          {(this.props.considerShowAll && (this.props.info.previouslyInHiddenState || this.props.info.count !== this.props.info.displayedCount)) ?
        		  <div className="setup-wizard-section-label"><span onClick = {this.toggleShowAll}>{this.props.info.hideRemaining ? msg1 : msg2}</span></div> : '' }
        </div>
    );
  };
}

export default connect(null, null)(SetupWizardContainer);