import React, { Component } from 'react'
import {HelpIcon} from '../../ui-components/uim-components'
import { 
    Tabs, Tab,
    Button as  RaisedButton,
    Button as    FlatButton,DialogTitle,Dialog,DialogActions,DialogContent,Typography,
    LinearProgress,
} from "@mineral/core"
import {CircularProgress} from '@mineral/core'
import Resource from './Resource'
import CredentialVault from './CredentialVault'
import ScheduleDiscovery from './ScheduleDiscovery'
import './discoveryConfiguration.less'
import Scope from './Scope'
import * as constant from "./DiscoveryConstants";
import { IconButton,Tooltip,Button } from '@mineral/core'

import { Cross as CloseIcon } from '@mineral/icons'
import NotComplianceSlaIcon from '../../../assets/icons/notComplianceSlaIcon'
import EditIcon from '@mui/icons-material/Edit'
const TabHeaders = {
    WMI: 'Setup WMI Credentials',
    UNIX: 'Setup Linux/Unix Credentials',
    SNMP: 'Setup SNMP Credentials',
    SCOPES: 'Define Scopes',
    SCHEDULE:'Schedule Discovery'
};

export default class DiscoveryAgent extends Component {
    constructor(){
        super();
        this.state ={
            value:'WMI',
            showJobStatus:false,
            statusMsg:'',
            isProgressNeeded:false,
            finishButtonStatus:false,
            changeSelectedAgent:false,
            initialJobFetchInProgress:false
        };
    }
    isPollingStarted = false
    componentWillUnmount(){
        if(this.isPollingStarted){
            this.props.pollAgentsStatusStop();
        }
    }
    scheduleComponentRefs = null;
    handleChange = (event,value) => {
        this.setState({
            value: value,
            finishButtonStatus:false
        });
    };
    handleCancel = ()=>{
        this.props.history.push(`/getting-started`)
    }
    handleTabSwitch = (direction)=>{
        let keys = Object.keys(TabHeaders),currentIndex = keys.indexOf(this.state.value);
        if(currentIndex !== -1){
            if(direction === 'prev'){
                if(currentIndex === 0){
                    this.props.showAgentScreen();
                }
                else if(currentIndex < keys.length ){
                    this.setState({
                        value: keys[currentIndex - 1],
                        finishButtonStatus:false
                    });
                }
            }
            else if(direction === 'next'){
                if(currentIndex < keys.length -1){
                    this.setState({
                        value: keys[currentIndex + 1],
                        finishButtonStatus:false
                    });
                }
                else if(this.scheduleComponentRefs){
                    //TODO:Handle finish API call
                    this.scheduleComponentRefs.onFinish();
                }
            }
        }
    }
    
    getActiveTabContent= ()=>{
        //TODO: based on selected key, get particular data
        
        if(this.state.value == 'WMI'){
            return ( <CredentialVault autoFocus={true} type={'WMI'} />)
        }else if(this.state.value == 'UNIX'){
            return ( <CredentialVault  type={'UNIX'} />) 
        }
        else if(this.state.value == 'SNMP'){
            return ( <CredentialVault  type={'SNMP'} />)  
        }
        else if(this.state.value == 'SCHEDULE'){
            return ( <ScheduleDiscovery 
                        onLoad={(refs)=>{
                            this.scheduleComponentRefs = refs;
                        }}
                        onFinishCall = {(data) =>{
                            let msg = 'No Modifications Found';
                            if(data.discoveryNowPromiseRef){
                                this.setState({
                                    showJobStatus:true,
                                    initialJobFetchInProgress:true
                                })
                                data.discoveryNowPromiseRef.then(res=>{
                                    msg = Resource.get('Discovering')//Discovering
                                    this.isPollingStarted = true;
                                    this.props.fetchAgentsStatus();
                                    this.props.pollAgentsStatusStart();
                                    this.setState({
                                        showJobStatus:true,
                                        statusMsg:msg,
                                        isProgressNeeded:true,
                                        initialJobFetchInProgress:false
                                    })
                                },err =>{
                                    this.setState({
                                        showJobStatus:true,
                                        statusMsg:'Error on submitting Discovery Job',
                                        initialJobFetchInProgress:false
                                    })
                                })
                                
                            }
                            else if(data.schedulePromiseRef){
                                this.setState({
                                    showJobStatus:true,
                                    initialJobFetchInProgress:true
                                })
                                data.schedulePromiseRef.then(res =>{
                                    if(data.scheduleDiscovery){
                                        msg = Resource.get('Discovery Scheduled')//Discovery Scheduled
                                    }
                                    else{
                                        msg = Resource.get('Discovery Schedule Deleted')//Discovery Schedule Deleted
                                    }
                                    this.setState({
                                        showJobStatus:true,
                                        statusMsg:msg,
                                        isProgressNeeded:false,
                                        initialJobFetchInProgress:false
                                    })
                                },err=>{
                                    this.setState({
                                        showJobStatus:true,
                                        statusMsg:'Error on submitting Discovery Job',
                                        initialJobFetchInProgress:false
                                    })
                                })
                            }
                            else if(data.scheduleDiscovery){
                                msg = Resource.get('Discovery Scheduled')//Discovery Scheduled
                                this.setState({
                                    showJobStatus:true,
                                    statusMsg:msg,
                                    isProgressNeeded:false
                                })
                            }
                            
                        }} 
                        handleFinishButton = {(status)=>{
                            this.setState({finishButtonStatus:status});
                        }}
            />)
        }
        else if(this.state.value == 'SCOPES'){
            return ( <Scope />)
        }
        return this.state.value;
    }
    
    getTabsInfo = ()=>{
        let info=[],buttonStyle = {height:'24px',fontFamily: 'Roboto',fontSize: '14px',fontWeight: 700,paddingRight:'32px'};
        for(let key in TabHeaders){
            info.push((
                <Tab fullWidth="true" autoFocus={key=='WMI'?true:false} id={key=='WMI' ?"first-content-element": "discoverytabs"+key} label={TabHeaders[key]} value={key}  >
                
                </Tab>
                ));
            }
            return info;
        }
        
        getStatusState=(statusState)=> {
            switch (statusState) {
                case 'WAITING_FOR_CONFIGURATION':
                return Resource.get('Waiting for Configuration') //Waiting for Configuration
                case 'NO_SCHEDULE':
                return Resource.get('Discovery Job Run Successfully') //Discovery Job Run Successfully
                case 'EXPIRED_SCHEDULE':
                return Resource.get('Schedule Expired, Reconfigure') //Schedule Expired Reconfigure
                case 'SCHEDULED' :
                return Resource.get('Job Scheduled') //Job Scheduled
                case 'RUNNING':
                return Resource.get('Running Discovery') //Running Discovery
                case 'UNRESPONSIVE' :
                return Resource.get('Unable to Contact Agent') //Unable to Contact Agent
                case 'PROCESSING':
                return Resource.get('Processing Results') //Processsing Results
                case 'UNKNOWN':
                return Resource.get('Status Unknown') //Status Unknown
                case 'LATENT':
                return Resource.get('Awaiting Agent Status') //Awaiting Agent Status
                default:
                return statusState.split('_').join(' ')
            }
        }
        
        getDiscoveringInfo=()=>{
            let selectedAgentId = this.props.selectedAgentInfo.id,
            msg = '',
            percent = 0;
            for(let i=0;i<this.props.agentsStatus.length;i++){
                if(this.props.agentsStatus[i].id === selectedAgentId){
                    msg = this.props.agentsStatus[i].state;
                    percent = this.props.agentsStatus[i].currentChecked;
                    break;
                }
            }
            
            return(
                <div>
                <Typography component="div" className="text" style={{paddingRight:'16px'}}>{this.getStatusState(msg)}</Typography>
                {msg.toLowerCase() === 'running' ? (<div style={{display:'inline-block'}}><div style={{width:'100px',display:'inline-block',paddingRight: '8px'}}>
                <LinearProgress style={{ height: '10px'}} mode="determinate" value={percent} />
                </div><span  style={{fontStyle :'normal'}}>{percent +'%'}</span></div>
                ):null}
                </div>
                )
            }
            
            render(){
                let cancelButtonLabel = constant.CANCEL,
                nextButtonLabel =  Resource.get('Next'),//Next Discovery
                finishButtonLabel =  Resource.get('Finish'),//Finish Discovery
                contentHeader = Resource.get('Configure Device Discovery'),//Configure Discovery
                configureAnotherMsg = Resource.get('Configure Another'),//Configure Another
                closeMsg = Resource.get('Close'),//Close
                buttonLabelStyle = { 'paddingRight': '20px', 'paddingLeft': '20px' ,textTransform:'none'},
                buttonStyle = {height:32,lineHeight:'32px',textTransform:'none'},
               
                agentMsg = Resource.get('Unsaved changes will be lost. Do you want to continue?');//Unsaved changes will be lost.Do you want to redirect to agents list
                let yesLabel = Resource.get('Yes')//Yes
                let noLabel = Resource.get('No')//No
                let tabsInfo = this.getTabsInfo(),
                agentInfo =this.props.selectedAgentInfo.hub+'/'+this.props.selectedAgentInfo.robot;
                let activeTabContent=this.getActiveTabContent();
                let jobConfirmationClasses=['job-confirmation'];
               
                //jobConfirmationHeader
                let jobConfirmationHeader = Resource.get('View the discovered devices in the Inventory');
                if(this.state.changeSelectedAgent){
                    jobConfirmationClasses.push('index');
                }
                // Help title
                const HELP = Resource.get("Help")
                // Help link text
                const HELP_LINK_TXT = Resource.get('Click to open Help link')

               // Yes
 const YES = Resource.get('Yes')

// No
 const NO = Resource.get('No')
 // Confirm
 const CONFIRM = Resource.get('Confirm ')
 
// Exit
const EXIT = Resource.get('Exit')
                return(
                    <div className='configuration-container'>
                    
                    
                                <div className="content">
                                <Dialog open={this.state.showJobStatus}>
      <DialogTitle disableTypography>
        <Typography component="h1" variant="h4">
          Discovery Run Status
        </Typography>
      </DialogTitle>
      <div style={{height:'0px'}}>
      <Tooltip title="Close">
      <IconButton style={{float:'right',top:'-50px',marginRight:'8px'}}
          aria-label="close" autoFocus
          onClick={()=>{this.setState({showJobStatus:false})
                                    this.handleCancel();
                                }}
        >
          <CloseIcon />
        </IconButton>
        </Tooltip>
        </div>
    

      <DialogContent>
       
                               <><div className="info">
                                {this.state.initialJobFetchInProgress?(<div style={{display:'flex',flexDirection:'column',alignItems:'center',height:'24px',width:'24px'}}>
                                <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><CircularProgress style={{color: '#3272D9'}} /></div>
                                </div>):(<div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
                               
                               
                                {this.state.isProgressNeeded?this.getDiscoveringInfo():this.state.statusMsg}
                               </div>)}
                                
                            
                                </div>
                                <div style={{color:'#000000',fontSize:'14px',paddingTop:'12px'}}>{jobConfirmationHeader}</div></>


                                </DialogContent>
          <DialogActions>

          <Button autoFocus={true}
                                   tabIndex={0}
                                   variant="outlined"
                                   style={{ margin: "10px 0" }}
                                  onClick={()=>{
                                    this.props.selectedAgent(null);
                                    this.props.showAgentScreen();
                                }}>{configureAnotherMsg}</Button>

<Button tabIndex={0} variant="text"  onClick={()=>{this.setState({showJobStatus:false})
                                    this.handleCancel();
                                }}>{closeMsg}</Button>
          </DialogActions>
        
      
    </Dialog>




                                <Dialog
            open={this.state.changeSelectedAgent}
           
            aria-labelledby="responsive-dialog-title"
            onClose={()=>{
                                    this.setState({changeSelectedAgent:false});
                                }}
          >
            <DialogTitle component="div">
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <NotComplianceSlaIcon size={'20'} />
                  <h2 style={{ fontWeight: 'bold', fontSize: '18px', color: '#58606E', marginLeft: '10px' }}>
                    {CONFIRM} {EXIT}
                  </h2>
                </div>
                
              </div>
            </DialogTitle>
            <div style={{height:'0px'}}>
            <Tooltip title="Close">
                <IconButton style={{float:'right',marginRight:'16px',top:'-60px'}}
                  aria-label="close"
                  onClick={()=>{
                                    this.setState({changeSelectedAgent:false});
                                }}
                  autoFocus
                >
                  <CloseIcon />
                </IconButton>
                </Tooltip>
                </div>
            <DialogContent>{agentMsg}</DialogContent>
            <DialogActions>
              <Button
              
                onClick={()=>{
                                    this.setState({changeSelectedAgent:false});
                                }}
                
              >
                {NO}
              </Button>
              <Button color="error"
                onClick={()=>{
                                this.props.showAgentScreen();
                            }}>
                {YES}
              </Button>
            </DialogActions>
          </Dialog>
                                <h2 className="header">{contentHeader}</h2>
                            <div style={{width:'100%'}}>
                                <div style={{display:'flex',flexDirection:'row',width:'100%'}}>
                                <Tabs variant="fullWidth" style={{width: '65%'}}
                                value={this.state.value}
                                onChange={this.handleChange}
                               // className="tab-root"
                                // contentContainerClassName = "tab-content"
                               // style={{backgroundColor:'#fff'}}
                                // inkBarStyle = {{backgroundColor:'#fff'}}
                                >
                                {tabsInfo}
                                
                                </Tabs>
                                
                            {!this.state.changeSelectedAgent?(<div style={{marginTop:'16px',marginLeft:'auto'}} className="agent-info">
                            <span className="title">Selected Agent:</span>
                            <div className="info">{agentInfo}</div>
                            <Tooltip title={"Change Agent"}>
                            <IconButton title={"Change Agent"} tabIndex={0}  style={{marginRight:'8px'}} onClick={()=>{
                                this.setState({changeSelectedAgent:true})
                            }}>
                               <EditIcon/>
                            </IconButton>
                            </Tooltip></div>):(null)}
                            <Tooltip title={HELP}>
                <IconButton role="link"  aria-roledescription={HELP_LINK_TXT} style={{float:'right',marginRight:'8px',marginTop:'8px'}}
                    onClick={() =>
                        window.open(
                            "https://techdocs.broadcom.com/bin/gethidpage?hid=OC_Discovery&space=UIM204&language=&format=rendered",
                            "_blank"
                            )
                            /*  window.open(
                                'https://techdocs.broadcom.com/bin/gethidpage?hid=SetupWizard&space=UIM204&language=&format=rendered',
                                ) */
                            }
                            >
                            <HelpIcon role="figure" title="Help" />
                            
                            </IconButton>
                            </Tooltip>
                                </div>
                                <div style={{height:'100%',marginBottom:'16px'}}>
                                {activeTabContent}   
                                </div>
                                </div>
                                </div>
                                <div className="footer" style={{marginBottom:'16px'}}>
                                <div>
                                <RaisedButton
                                variant="contained"
                                color="primary" 
                                // classes={{root:"cancel-button"}}
                                //className={"cancel-button"}
                                //label={buttonLabelStyle}                
                               // style ={buttonStyle}
                                onClick={this.handleCancel}
                                //labelStyle={buttonLabelStyle}
                                >{cancelButtonLabel}</RaisedButton>
                                </div>
                                <div>
                                <FlatButton
                               // style ={{backgroundColor: '#FFFFFF', ...buttonStyle}}
                                //labelStyle={{ color: '#00AEEF' }}
                               // className="prev-button"
                                // classes={{root:buttonStyle}}
                               // label={buttonLabelStyle}

                                //Previous Message            
                                children= {Resource.get('Previous')}
                                onClick={()=>{
                                    this.handleTabSwitch('prev');
                                }}
                                />
                                <RaisedButton
                                variant="contained"
                                children={this.state.value === 'SCHEDULE'?finishButtonLabel:nextButtonLabel}
                                disabled = {this.state.finishButtonStatus === true?true:false}
                                // classes={{root:"next-button"}}
                               
                                label={buttonLabelStyle}
                                style={{marginLeft:'15px', ...buttonStyle}}
                                color ="primary"
                                // buttonStyle ={buttonStyle}
                                onClick={()=>this.handleTabSwitch('next')}
                                //labelStyle={buttonLabelStyle}
                                />
                                </div>
                                </div>
                              

                                
                                </div>
                                );
                            }
                        }
                        