// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //For more information, click
  content["For more information, click "] =
  "자세한 내용을 보려면 다음을 클릭하십시오. ";

  // console.log(this.props);
  content["Default system monitoring for CPU, disk & memory will be applied"] =
  "CPU, 디스크 및 메모리에 대한 기본 시스템 모니터링이 적용됩니다.";

  //Automatically monitor
  content["Automatically monitor "] =
  "자동으로 모니터 ";

  // devices
  content[" devices"] =
  " 장치";

  //HELP
  content["HELP"] =
  "도움말";

  // END OF LOCALIZATION

export default content;
