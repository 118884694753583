import React, { Component } from "react";
import isEqual from "lodash/isEqual";

const GroupPath =(props)=> {
  const [entity,setEntity] = React.useState(props.entity);
  const [allGroups,setAllGroups] = React.useState(props.allGroups);
  const [allGrpIdNameArray,setAllGrpIdNameArray] = React.useState([]);
  
  const createGroupNamePathArray = (tmpArray, id, groups) => {
    if (groups && groups.length > 0) {
      var item = groups.find((item) => item.grpId === Number(id));
      var parentId = item.parentId;
      var grpName = item.name;
      if (parentId != 0 && parentId != -1) {
        tmpArray.push(grpName);
        createGroupNamePathArray(tmpArray, parentId, groups);
      } else {
        tmpArray.push(grpName);
      }
    }
  };
 const  loop = (tmpStateDataGroups, allGroups, grpId, loopContFlag) => {
    if (loopContFlag) {
      for (let i = 0; i < allGroups.length; i++) {
        if (allGroups[i].children && allGroups[i].children.length > 0) {
          var item = allGroups[i].children.find(
            (item) => Number(item.id) === grpId
          );
          if (item) {
            let grpEntry = {
              grpId: Number(item.id),
              parentId: Number(item.parentId),
              name: item.name,
            };
            tmpStateDataGroups.push(grpEntry);
            loopContFlag = false;
            break;
          } else {
            loop(
              tmpStateDataGroups,
              allGroups[i].children,
              grpId,
              loopContFlag
            );
          }
        }
      }
    }
  };
  const getUpdatedGrpData = (stateDataGroups, allGroups) => {
    let tmpStateDataGroups = [];
    if (
      stateDataGroups &&
      allGroups &&
      stateDataGroups.length &&
      allGroups.length
    ) {
      for (let i = 0; i < stateDataGroups.length; i++) {
        var item = allGroups.find(
          (item) => Number(item.id) === Number(stateDataGroups[i].grpId)
        );
        if (item) {
          let grpEntry = {
            grpId: Number(item.id),
            parentId: Number(item.parentId),
            name: item.name,
          };
          tmpStateDataGroups.push(grpEntry);
        } else {
          var loopContFlag = true;
          loop(
            tmpStateDataGroups,
            allGroups,
            Number(stateDataGroups[i].grpId),
            loopContFlag
          );
        }
      }
    }
    return tmpStateDataGroups;
  };

  React.useEffect(() => {
    let allGroupsWithNames = getUpdatedGrpData(
      props.stateDataGroups,
      props.allGroups
    );
    if (!isEqual(allGroups, props.allGroups)) {
      setAllGroups(props.allGroups)
    }
    if (!isEqual(allGroupsWithNames, allGrpIdNameArray)) {
      setAllGrpIdNameArray(allGroupsWithNames);
    }
  }, [getUpdatedGrpData, props.allGroups, props.stateDataGroups]);

  React.useEffect(() => {
    if (!isEqual(entity, props.entity)) {
      setEntity(props.entity)
    }
  }, [props.entity]);
  
  
let breadcrumbArray = [];
if (entity && allGrpIdNameArray.length > 0) {
  let currentGroupName = entity.name;
  let currentGroupParId = entity.parentId;
  let tmpArray = [];
  if (currentGroupName) {
    if (currentGroupParId != 0 && currentGroupParId != -1) {
      if (currentGroupName.localeCompare("Root") != 0) {
        tmpArray.push(currentGroupName);
      }
      createGroupNamePathArray(
        tmpArray,
        currentGroupParId,
        allGrpIdNameArray
      );
      breadcrumbArray = tmpArray.reverse();
    } else {
      if (currentGroupName.localeCompare("Root") != 0) {
        breadcrumbArray.push(currentGroupName);
      }
    }
  }
}

    return (
      <h2 style={props.groupPathStyles}>
        {breadcrumbArray?.length ? breadcrumbArray.join("/") : "Groups"}
      </h2>
    );
  }

export default GroupPath;
