import React, { Component } from "react";
import Iframe from "react-iframe";
import {CircularProgress} from '@mineral/core'
import "./reactFrame.less";
import axios from "axios";

export default class ReactFrame extends Component {
  state = {
    sid: null,
    loading: true,
  };

  hideSpinner = () => {
    this.setState({
      loading: false,
    });
  };

  componentWillMount = () => {
    let overrideSid = axios.defaults.headers.common["Authorization"];
    if (
      window.OPERATOR_CONSOLE.refreshToken &&
      window.OPERATOR_CONSOLE.refreshToken !== ""
    ) {
      overrideSid = window.OPERATOR_CONSOLE.refreshToken;
      axios.defaults.headers.common["Authorization"] =
        window.OPERATOR_CONSOLE.refreshToken;
      window.OPERATOR_CONSOLE.refreshToken = "";
    }
    this.setState({ sid: overrideSid });
  };

  submitFormRef = (form) => {
    form && form.submit();
  };

  getPort() {
    if (window.location.port !== "") {
      return `${window.location.protocol}//${window.location.hostname}:${window.location.port}/${this.props.url}?sid=${this.state.sid}&keep-alive=${this.props.keepalive}`;
    } else {
      return `${window.location.protocol}//${window.location.hostname}/${this.props.url}?sid=${this.state.sid}&keep-alive=${this.props.keepalive}`;
    }
  }
  render() {
    let view = (
      <div style={{width: '100%', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <CircularProgress style={{color: '#3272D9'}} aria-label="Loading..." />
      </div>
    );

    if (this.state.sid && this.props.method === "POST") {
      view = (
        <div className="frame-container">
          <form
            className="frame-form"
            id="dashForm"
            target="frameForm"
            action={
              window.location.port === ""
                ? `${window.location.protocol}//${window.location.hostname}/${this.props.url}`
                : `${window.location.protocol}//${window.location.hostname}:${window.location.port}/${this.props.url}`
            } // For local dev, replace hostname with local IP
            method={this.props.method}
            ref={this.submitFormRef}
          >
            <input type="text" name="sid" value={this.state.sid} />
            <input type="text" name="keep-alive" value={this.props.keepalive} />
            <input type="submit" />
          </form>
          {this.state.loading ? <div role="alert" style={{width: '100%', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><CircularProgress role="alert" aria-label="Loading..." style={{color: '#3272D9'}} aria-describedby="frameId" /></div> : null}
          <Iframe
            name="frameForm"
            id={`frameId_${this.props.portletId}`} 
            aria-label={this.props.portletId}
            title={this.props.portletId}
            className="frame-body"
            onLoad={this.hideSpinner}
          />
        </div>
      );
    }
    if (this.state.sid && this.props.method === "GET") {
      view = <div
          className={`frame-container ${this.props.portletId == "slm" && "frame-height"}`}
        >
          {this.state.loading ? <div role="alert" style={{width: '100%', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><CircularProgress role="alert" aria-label="Loading..." style={{color: '#3272D9'}} /></div> : null}
          <Iframe
            name="frameForm"
            id={`frameId_${this.props.portletId}`} 
            aria-label={this.props.portletId}
            title={this.props.portletId}
            className="frame-body"
            onLoad={this.hideSpinner}
            url={this.getPort()}
           
          />
        </div>
    }

    return view;
  }
}
