import * as React from 'react';
import { SvgIcon } from '@mineral/core';

export const FilledTriangleDown = React.forwardRef(
  (props, ref) => (
    <SvgIcon viewBox="0 0 40 40" {...props} ref={ref}>
      <path d="M20 34L4 6L36 6L20 34Z" fill={props.color} />
    </SvgIcon>
  )
);

export default FilledTriangleDown;