import React from 'react';
import IntegrationCard from './../IntegrationCard';
import connect from './../../../../utils/connect';
import {get} from './../../../../api/integrations/servicenow/actions';
import {CircularProgress} from '@mineral/core'
import IntegrationLogo, {TYPE_SERVICENOW} from './../../../icons/IntegrationLogo';
import IntegrationStateIcon, {TYPE_GOOD, TYPE_NEW, TYPE_ERROR} from './../../../icons/IntegrationStateIcon';
import Resource from './Resource'

class ServiceNowCard extends IntegrationCard {
  componentWillMount() {
    this.props.fetchConfig();
  }
  componentDidMount() {
    this.props.fetchConfig();
  }

  getIcon() {
    if(this.props.isFetching) {
      return (
          <span style={{padding:'3px'}}>
            <CircularProgress className='integration__card--icon-spinner' style={{color: '#3272D9'}} />
            
          </span>
      );
    } else if(this.props.responseError && this.props.responseError.message) {
      return (
          <span style={{padding:'3px'}} title={this.props.responseError.message}>
            <IntegrationStateIcon size='30px' type={TYPE_ERROR} />
          </span>
      );
    } else if(this.props.config.userName === '' || this.props.config.userPassword === '' || this.props.config.userURL === '') {
      return (
          <span style={{padding:'3px'}}>
            <IntegrationStateIcon size='30px' type={TYPE_NEW} />
          </span>
      );
    } else {
      return (
          <span style={{padding:'3px'}}>
            <IntegrationStateIcon size='30px' type={TYPE_GOOD} />
          </span>
      );
    }
  }

  getSubLabelTwo() {
    if(this.props.config.userName === '' || this.props.config.userPassword === '' || this.props.config.userURL === '') {
      //Add
      return Resource.get('Add');
    } else {
      //Setup complete 	
      return Resource.get('Setup complete');
    }
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchConfig:() => dispatch(get())
  }
};

const mapStateToProps = (state) => {
  return {
    isFetching:state.serviceNow.isFetching,
    responseError:state.serviceNow.responseError,
    state:state.serviceNow.config.state,
    config: state.serviceNow.mutableConfig,
    serviceName:state.serviceNow.config.serviceName,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceNowCard)

ServiceNowCard.defaultProps = {
  className:'service-now',
  logo:<IntegrationLogo height="26px" type={TYPE_SERVICENOW} />,
  //ServiceNow Ticketing
  subLabelOne:Resource.get('ServiceNow Ticketing'),
  subLabelTwo:'<>',
  configRoute:'/settings/integrations/servicenow'
};
