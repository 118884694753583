import React from 'react'
import { Route } from 'react-router-dom'
import Dashboards from './../components/dashboards/DashboardRedux'
import CabiRedux from './../components/cabi/CabiRedux'
import AlarmsRedux from './../components/alarms/AlarmsRedux'
import TechnologyView from './../components/technologyView/TechnologyView'
import McsDashboard from "../components/mcsDrillDown/McsDashboardRedux";
import DashboardContainerRedux from "./../components/dashboardContainer/DashboardContainerRedux";

const Dashboard = (props) => {
  return (
    <main style={{backgroundColor:"#FFFFFF"}}>
      <Dashboards {...props}/>
    </main>
  )
}

const McsDashboard_1 = () => {
    return (
        <main>
            <McsDashboard />
        </main>
    )
}

const McsDashboardRoute = (props) => {
  if (props.saas==true) {
    return null
  }
  return  <Route exact path='/mcsDeploymentStatus' component={McsDashboard_1} />     
}


const DashboardRoute = (props) => {
  return (
    <div>
        <Route exact path='/technologies' component={ () => <Dashboard saas={props.saas} />} />

        <Route exact path='/mcsDeploymentStatus' component={() => <McsDashboardRoute saas={props.saas} />} />       

        <Route
            exact
            path='/technologies/:prid/dashboard'
            render={({ match }) => (
              <TechnologyView component={<DashboardContainerRedux {...{probeId: match.params.prid} } />} />
              
            )}
          />
        <Route path='/technologies/:prid/cabi' render={({ match }) =>
             <TechnologyView component={<CabiRedux {...{probe: match.params.prid} } />} />
             
        } />
        <Route path='/technologies/:prid/alarms' render={({ match }) =>
             <TechnologyView component={<AlarmsRedux isFromOperatorConsole={true} {...{probe: match.params.prid} } />} />
        } />
    </div>
  )
}

export default DashboardRoute;