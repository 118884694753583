import React, { Component } from 'react'
import { Dialog, DialogTitle,Button as FlatButton, DialogActions } from '@mineral/core'
import Resource from './Resource'
import "./dialogButtonStyle.less";

const DELETE_DEVICES_OPEN = 'deleteDevicesOpen'
const DELETE_DEVICE = 'deleteDevice'

class DeleteDevicesDialog extends Component {
  constructor(props) {
    super(props)
    this.state = {
      disableButton: false
    }
  }
  componentWillReceiveProps() {
    this.setState({
      disableButton: false
    })
  }
  render() {
    let actions = [
       //Cancel
      <FlatButton 
        variant = "text"
        children= {Resource.get('Cancel')} 
        color = "primary" 
        onClick={this.onClose} />,
      <FlatButton
        variant = "text"
      	//Delete
        children= {Resource.get('Delete')}
        color = "primary"
        keyboardFocused={true}
        onClick={this.handleDeleteDevices}
        disabled={this.state.disableButton}
      />
    ]
    		let title = ''
    		if(this.props.selectedCsIds.length >1) {
    			title= `Delete ${this.props.selectedCsIds.length} devices`
    		}
    		else {
    			//DeleteDevice
    			title = Resource.get('Delete Device')
    		}

    let content = (
      <div style={{
        fontSize: '16px',
        color: 'rgba(0, 0, 0, 0.6)',
        padding: '0px 24px 24px'    
      }}>
        <p>{// help message
          Resource.get('This action will remove the selected device(s) from the system\'s inventory of discovered and monitored devices. It will no longer count against the billing cap.')}
        </p>
        <p>{// help message1
          Resource.get('To restore the device and enable monitoring, go to\… Setting > Deleted devices')}
        </p>
      </div>
    )

    return (
      <Dialog 
        modal={false}
        open={this.props.open}   
        aria-labelledby="dialog-title"
        className= "dialogConatiner"
      >
      <DialogTitle id="dialog-title"> {title}</DialogTitle>     
        {content}
        <div>{this.errorMsg}</div>
        <DialogActions>
            {actions}
        </DialogActions>
      </Dialog>
    )
  }
  onClose = () => {
    this.errorMsg = null
    this.props.handleClose(DELETE_DEVICES_OPEN)
  }
  handleDeleteDevices = () => {
    this.setState({
      disableButton: true
    })

    this.props
      .deleteDevices(this.props.selectedCsIds)
      .then(response => {
        if (response.type === 'DELETE_DEVICES_SUCCESS') {
          this.props.removeEntities(this.props.selectedCsIds)
          this.props.setSelectedCsIds([])
          this.props.handleClose(DELETE_DEVICES_OPEN)
        } else {
          this.props.handleClose(DELETE_DEVICES_OPEN)
          this.props.handleErrorDialogOpen(DELETE_DEVICE)
        }
      })
      .catch(error => {
        this.props.handleClose(DELETE_DEVICES_OPEN)
        this.props.handleErrorDialogOpen(DELETE_DEVICE)
      })
  }
}
DeleteDevicesDialog.defaultProps = {
  selectedCsIds: []
}
export default DeleteDevicesDialog
