// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Inventory
  content["Inventory"] =
  "인벤토리";

  //Refreshed
  content["Refreshed"] =
  "새로 고침";

  //Results
  content["Results"] =
  "결과";

  // END OF LOCALIZATION

export default content;
