// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Home
  content["Home"] =
  "主页";

  //Alarms
  content["Alarms"] =
  "警报";

  //Groups
  content["Groups"] =
  "组";

  //Inventory
  content["Inventory"] =
  "清单";

  //Dashboards
  content["Dashboards"] =
  "显示板";

  //Reports
  content["Reports"] =
  "报告";

  //Settings
  content["Settings"] =
  "设置";

  //Setup Wizard
  content["Setup Wizard"] =
  "安装向导";

  //Community
  content["Community"] =
  "社区";

  //Help
  content["Help"] =
  "帮助";

  // END OF LOCALIZATION

export default content;
