import React, { useEffect } from "react";
import {
  Line,
  LineChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label,
  ReferenceLine,
  ComposedChart,
} from "recharts";
import moment from "moment";
import CustomLegend from "../../metricpalettecode/components/MineralMetricChart/CustomLegend";
import CustomTooltip from "../../metricpalettecode/components/MineralMetricChart/CustomTooltip";
export const MatricsChart = ({
  selectedCombos,
  metricDevice,
  index,  
  aggregationValue, 
}) => {
  const data = metricDevice.deviceData[0];
  const refRec = metricDevice.chartDefinationRaw[data.deviceName + '-' + data.ciName];  
  const [refRecord, setRefRecord] = React.useState(refRec!==undefined && refRec[refRec?.length - 1]);
  const [defaultRefPoint, setDefaultRefPoint] = React.useState(refRecord);
  const startTime = refRec!==undefined?refRec[0].ts:0;
  const endTime = refRec!==undefined?refRec[refRec.length - 1].ts:0;
  const layout = "horizontal";

  const xAxisTickFormatter = (tick) => {
    tick = moment(tick*1000).format("YYYY-MM-DD HH:mm");
  return tick;
  };

  const getDeviceChartData = (metricDevice) => {
    let lineChartArray = [];
    if (metricDevice.deviceData) {
      metricDevice.deviceData.map((device, i) => {        
        let dataKey = device.deviceName;
        if (device.ciName) {
          dataKey = `${dataKey}-${device.ciName}`;
        }
        let lineData = metricDevice ? (metricDevice.chartDefinationRaw ?
          metricDevice.chartDefinationRaw[dataKey]
          : null) : null;
        lineChartArray.push(
          <Line
            dataKey={dataKey}
            key={dataKey}
            data={lineData}
            dot={false}
            stroke={selectedCombos[i]?.color}
            isAnimationActive={false}
            animationDuration={0}
          />
        );
      });
    }
    return lineChartArray;
  };
  return (
    <>
      <ResponsiveContainer>
        <LineChart
          data={aggregationValue !== -1 ? metricDevice.chartDefination : null}
          key={index}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
          id={`${metricDevice.name}_${index}`}
          onClick={(nextState, event) => {
            if (nextState) {
              setRefRecord(nextState.activePayload[0].payload);
              setDefaultRefPoint(nextState.activePayload[0].payload);
            }
          }}
          onMouseMove={(nextState, event) => {
            if (nextState.isTooltipActive) {
              setDefaultRefPoint(nextState.activePayload[0].payload);
            }
            else {
              setDefaultRefPoint(refRecord);
            }
          }}
          onMouseLeave={() => {
            setDefaultRefPoint(refRecord);
          }}
        >
          <CartesianGrid horizontal={true} vertical={false} />
          <XAxis dataKey={metricDevice.chartDefinationRaw ? "ts" : "sampleTime"}
            interval={"preserveStartEnd"}
            type={metricDevice.chartDefinationRaw ? 'number' : 'category'}
            domain={['dataMin', 'dataMax']}
            tickCount={15}
            tickFormatter={(tick) => xAxisTickFormatter(tick)}
          />
          <YAxis axisLine={true} tickLine={false} 
                  domain={((metricDevice.unit.toUpperCase() == "pct".toUpperCase() || metricDevice.unit.toUpperCase == "Percent".toUpperCase) && metricDevice.hasMax == 1)?[0,100]
                  :(metricDevice.isBool == 1?[0,1]:[0,'auto'])} />
          <Tooltip
            content={<CustomTooltip aggregationValue={aggregationValue}/>}
          />
          <Legend
            wrapperStyle={{
              width: layout === 'vertical' ? '30%' : '90%',
              height: '50%',
              paddingTop: layout !== 'vertical' ? '10px' : undefined,
              paddingLeft: layout === 'vertical' ? '20px' : undefined,              
            }}
            layout={"horizontal"}
            verticalAlign={layout === 'vertical' ? "top" : "bottom"}
            align={layout === 'vertical' ? "right" : undefined}
            content={(props) => CustomLegend({
              data: metricDevice,
              selectedCombos: selectedCombos,
              referenceData: defaultRefPoint.ts,
              refLineData: refRecord,
              setRefLineData: setRefRecord,
              selectedCombos: selectedCombos,
              startTime: startTime,
              endTime: endTime,
              columns: 5,
              aggregationValue: aggregationValue,
              isDatepickerDisplay: false,
              ...props
            })}
          />
          <ReferenceLine x={refRecord.ts} stroke="#434A54" strokeDasharray="3 3" />
          {getDeviceChartData(metricDevice)}
        </LineChart>
      </ResponsiveContainer>
    </>
  );
} 