// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //CurrentDate
  content["Current Date"] =
  "Current Date";

  // END OF LOCALIZATION

export default content;
