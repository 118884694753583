import axios from 'axios'

class Acl {
  getUser() {
    const url = process.env.NODE_ENV == "development" ? 
    "http://10.252.25.208:8080/castlemock/mock/rest/project/YAYKiT/application/xxKf0k/api/user":
    '/operatorconsole_portlet/api/user'
   // const url='http://10.252.25.208:8080/castlemock/mock/rest/project/YAYKiT/application/xxKf0k/api/user'
    //const url='http://10.252.25.208:8080/castlemock/mock/rest/project/YAYKiT/application/xxKf0k/api/user/operator'
    return axios.get(url)
  }
  
}

const acls = new Acl()
export default acls