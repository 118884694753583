import {
    FETCH_USER_DASHBOARDS,
FETCH_USER_DASHBOARDS_SUCCESS,
FETCH_USER_DASHBOARDS_FAILURE,

FETCH_USER_DASHBOARD,
FETCH_USER_DASHBOARD_SUCCESS,
FETCH_USER_DASHBOARD_FAILURE,


CREATE_USER_DASHBOARDS,
CREATE_USER_DASHBOARDS_SUCCESS,
CREATE_USER_DASHBOARDS_FAILURE,


UPDATE_USER_DASHBOARDS,
UPDATE_USER_DASHBOARDS_SUCCESS,
UPDATE_USER_DASHBOARDS_FAILURE,

DELETE_USER_DASHBOARDS,
DELETE_USER_DASHBOARDS_SUCCESS,
DELETE_USER_DASHBOARDS_FAILURE,

RESET_USER_DASHBOARD_STORE
  } from "./actionTypes";
  
  const INIT_STATE= {   
       
      
     
    userDashboardsListGetStatus:{isFetching: false,status: 'none',userdashboards: {items:[],totalCount:0},},
    userDashboardsGetStatus:{isFetching: false,didInvalidate: false,userdashboard: {},},
    
    userDashboardDeleteStatus:{status:'none',userdashboardId:0,dashboardname:''},
  
    userDashboardCreateStatus:{status:'none',userdashboard:{},dashboardname:''},
    userDashboardUpdateStatus:{status:'none',userdashboard:{},dashboardname:''},
   
  
  
  };
  const userdashboard = (
    state =INIT_STATE,
    action
  ) => {
    switch (action.type) {
      case RESET_USER_DASHBOARD_STORE:
        state=INIT_STATE;
      case FETCH_USER_DASHBOARDS:
        let userdashboardList=state.userDashboardsListGetStatus.userdashboards;
          return Object.assign({}, state, {
            userDashboardsListGetStatus:{isFetching: true,
          didInvalidate: false,
          userdashboards:userdashboardList,
          status:'inprogress'
          }});
      case FETCH_USER_DASHBOARDS_SUCCESS:
        return Object.assign({}, state, {
            userDashboardsListGetStatus:{isFetching: false,
                didInvalidate: false,
                userdashboards: action.userdashboards,
          status:'success'
        }});
      case FETCH_USER_DASHBOARDS_FAILURE:
        return {
          ...state,
          userDashboardsListGetStatus:{isFetching: false,
          didInvalidate: true,
          userdashboards: [],
        status:'failure'}
        };
        case FETCH_USER_DASHBOARD:
          return Object.assign({}, state, {
            userDashboardsGetStatus:{status:'inprogress',
            jobId: action.id,
            userdashboard:{},
          }});
        case FETCH_USER_DASHBOARD_SUCCESS:
          return Object.assign({}, state, {
            userDashboardsGetStatus:{status:'success',
            userdashboard:action.userdashboard,
          }});
        case FETCH_USER_DASHBOARD_FAILURE:
          return {
            ...state,
            userDashboardsGetStatus:{status:'failure',
            userdashboard:{},
          }};
         
          case CREATE_USER_DASHBOARDS:
            return Object.assign({}, state, {
              userDashboardCreateStatus:{status:'inprogress',userdashboard:{}},
            });
          case CREATE_USER_DASHBOARDS_SUCCESS:
            return Object.assign({}, state, {
              userDashboardCreateStatus:{status:'success',userdashboard:action.userdashboard,dashboardname:action.dashboardname},
         
            });
          case CREATE_USER_DASHBOARDS_FAILURE:
            return {
              ...state,
              userDashboardCreateStatus:{status:'failure',userdashboard:{},dashboardname:action.dashboardname},
         
            };
            case UPDATE_USER_DASHBOARDS:
              return Object.assign({}, state, {
                userDashboardUpdateStatus:{status:'inprogress',userdashboard:{},dashboardname:action.dashboardname},
              });
            case UPDATE_USER_DASHBOARDS_SUCCESS:
              return Object.assign({}, state, {
                userDashboardUpdateStatus:{status:'success',userdashboard:action.userdashboard,dashboardname:action.dashboardname},
           
              });
            case UPDATE_USER_DASHBOARDS_FAILURE:
              return {
                ...state,
                userDashboardUpdateStatus:{status:'failure',userdashboard:{},dashboardname:action.dashboardname},
           
              };

          case DELETE_USER_DASHBOARDS:
            return Object.assign({}, state, {
              userDashboardDeleteStatus:{status:'inprogress',dashboardname:action.dashboardname},
              
            });
          case DELETE_USER_DASHBOARDS_SUCCESS:
            return Object.assign({}, state, {
                userDashboardDeleteStatus:{status:'success', id:action.id,dashboardname:action.dashboardname},
            });
          case DELETE_USER_DASHBOARDS_FAILURE:
            return {
              ...state,
              userDashboardDeleteStatus:{status:'failure',dashboardname:action.dashboardname},
         
            };      
      default:
        return state;
    }
  };
  export default userdashboard;
  