import React from 'react'
import './footerBar.less'
import FooterName from './FooterName'

const FooterBar = (props, context) => {
 let currentYear = new Date().getFullYear()
 let classes = 'footer__bar-message'
 if(window.location.pathname.includes('cabi')) {
   classes = 'footer__bar-message footer__bar-message--cabi '
 }
 //All rights reserved.
//return (<div className={classes}>&copy;{currentYear} CA.  {Resource.get('All rights reserved.')}</div>)
   return (<footer className={classes}><FooterName/></footer>)
}
export default FooterBar