// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  // Alarms
  content["Alarms"] =
  "アラーム";

  // Dashboards
  content["Dashboards"] =
  "ダッシュボード";

  // Metrics
  content["Metrics"] =
  "メトリクス";

  // Interfaces
  content["Interfaces"] =
  "インターフェース";

  // END OF LOCALIZATION

export default content;
