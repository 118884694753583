import React, { Component } from "react";
import DiscoveryAgentRedux from "./DiscoveryAgentRedux";
import DiscoveryConfigurationRedux from "./DiscoveryConfigurationRedux";

export default class ConfigurationContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAgentSelected: props.isAgentSelected,
      showAgentSelection: !props.isAgentSelected,
    };
  }

  componentWillMount() {
    if (
      !this.props.acl ||
      !this.props.acl.isBusUser ||
      !this.props.acl.canManageDiscovery
    ) {
      this.props.history.replace("/overview");
    }
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      isAgentSelected: nextProps.isAgentSelected,
      showAgentSelection: !nextProps.isAgentSelected,
      showNextContainer: false
    });
  }

  render() {
    if  (this.state.showAgentSelection) {
      this.setState({
        showAgentSelection: false,
        showNextContainer: false
      })
    }

    return !this.state.showNextContainer ? ( <DiscoveryAgentRedux
        showNextContainer={() => {
          this.setState({
            showNextContainer: true
          })
        }}
        
        onAgentSelect={() => {
          this.setState({
            isAgentSelected: true,
            showAgentSelection: false,
          });
        }}
     />
     
     ) : ( <DiscoveryConfigurationRedux
      
         showNextContainer={() => {
          this.setState({
            showNextContainer: false
          })
        }}
        showAgentScreen={() => {
          this.setState({
            showAgentSelection: true,
          });
        }}
      />
    )
  }
}
