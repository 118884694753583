import { connect } from 'react-redux'
import { openModal } from '../../../api/modals/actions'
import TrialBarContainer from './TrialBar'


const mapDispatchToProps = dispatch => {
  return {
    handleExtendTrials: () => {
      dispatch(openModal('REQUEST_TRIALS_EXTENSION'))
    },
  }
}
const mapStateToProps = state => {
  return {
    featureFlags: state.configuration.items,
    saas: state.saas,
    
  }
}

const TrialBarRedux = connect(mapStateToProps, mapDispatchToProps)(
  TrialBarContainer,
)

export default TrialBarRedux
