import React, { Component } from "react";
import { Route } from "react-router-dom";
import connect from "./../../utils/connect";
import ProtectedDeviceConfiguration from "./ProtectedDeviceConfiguration";
import ProtectedGroupConfiguration from "./ProtectedGroupConfiguration";
import AlarmPoliciesRedux from "./../alarmPolicies/AlarmPoliciesRedux";
import PolicyRedux from "./../alarmPolicies/PolicyRedux";
import McsDashboard from "./../mcsDrillDown/McsDashboardRedux";

import {
  Tabs,
  Tab,
  
} from "@mineral/core";

const TabHeaders = {
  profile: "Profiles",
  policy: "Alarm Policies",
  overview: "MCS Deployment Overview",
};
class ProtectedConfigurationRoute extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "profile",
      showJobStatus: false,
      statusMsg: "",
      isProgressNeeded: false,
      changeSelectedAgent: false,
      initialJobFetchInProgress: false,
      showPolicyView: false,
      policyId: null,
      acl: props.acl,
    };
  }

  handleChange = (element,value) => {
    this.setState({
      value: value,
      showPolicyView: false,
      policyId: null,
    });
  };

  getTabsInfo = () => {
    let info = [],
      buttonStyle = {
        paddingLeft: "24px",
        marginTop: "10px",
        height: "24px",
        fontFamily: "Roboto",
        fontSize: "14px",
        fontWeight: 700,
        paddingRight: "32px",
      };
    for (let key in TabHeaders) {
      info.push(
        <Tab style={{maxWidth:'200px'}}
          label={TabHeaders[key]}
          value={key}
          /*buttonStyle={
            key === "profile"
              ? buttonStyle
              : Object.assign({}, buttonStyle, {
                  paddingLeft: "32px",
                  borderLeft: "1px solid #cccccc",
                })
          }*/
         // className="tab-section"
        
        >
        
           
        </Tab>
      );
    }
    return info;
  };

  getActiveTabContent = () => { 
    if (this.state.value == "profile") {
      return (
        <ProtectedGroupConfiguration
          id={this.props.groupId}
          {...this.props}
          policyRedirect={(policyId) => {
            this.setState({
              value: "policy",
              showPolicyView: true,
              policyId: policyId,
            });
          }}
        />
      );
    } else if (this.state.value == "policy") {
      if (this.state.showPolicyView) {
        return (
          <PolicyRedux
            launchFrom="group"
            policyId={this.state.policyId}
            groupId={this.props.groupId}
            goBack={() => {
              this.setState({
                showPolicyView: false,
                policyId: null,
              });
            }}
          />
        );
      } else {
        return (
          <AlarmPoliciesRedux
            launchFrom="group"
            {...this.props}
            handleRowClicked={(policyId) => {
              this.setState({
                showPolicyView: true,
                policyId: policyId,
              });
            }}
            switchtoPoliciesTab={() => {
              this.setState({
                value: "profile",
                showPolicyView: false,
                policyId: null,
              });
            }}
          />
        );
      }
    } else if (this.state.value == "overview") {
      return <McsDashboard currentHeight={"calc(100% - 245px)"}/>;
    }
    return this.state.value;
  };

  render() {
    let tabsInfo = this.getTabsInfo();
    return (
      <div style={this.state.value === 'profile'? { height: this.props.fromTreeView?"100%":"100%", overflow: "auto"} :{ height: "100%", overflow: "auto", overflowY: "scroll" }}>
        <Route
          path={`${this.props.entityMatchRegex}`}
          render={({ match }) => {
            if (this.props.csId) {
              return (
                <ProtectedDeviceConfiguration
                  id={this.props.csId}
                  {...this.props}
                />
              );
            } else {
              return (
                <div style={{height:'100%'}}>
                <Tabs
                  value={this.state.value}
                  onChange={this.handleChange}
                  //className="tab-root"
                  indicatorColor='primary'  
                >
                  {tabsInfo}
                </Tabs>
                <div style={{ height:'calc(100% - 42px)' }}>
                    {this.getActiveTabContent()}
                  {/* {this.state.value === key ? this.getActiveTabContent() : ""} */}
                </div>
                </div>
              );
            }
          }}
        />
      </div>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    featureFlags: state.configuration.items,
    acl: state.acl,
  };
};
export default connect(mapStateToProps, null)(ProtectedConfigurationRoute);
