import { call, put, takeEvery, takeLatest, take, cancel } from 'redux-saga/effects'
import { delay } from 'redux-saga/effects'
import { REQUEST_HUBS, POLL_HUBS_START, POLL_HUBS_STOP } from './actionTypes'
import { fetchHubsSuccess, fetchHubsFailure } from './actions'
import hubs from './hubs'

// worker Saga: will be fired on REQUEST_HUBS actions
function* fetchHubs() {
  try {
    const response = yield call(hubs.get)
    yield put(fetchHubsSuccess(response.data))
  } catch (error) {
    yield put(fetchHubsFailure(error))
  }
}

function* pollHubs() {
  while (true) {
    yield delay(30000)
    yield call(fetchHubs)
  }
}

/*
  Starts fetchHubs on each dispatched `REQUEST_HUBS` action.
  Allows concurrent fetches of user.
*/
export function* hubsRequestSaga() {
  yield takeEvery(REQUEST_HUBS, fetchHubs)
}

export function* hubsPollingSaga() {
  while (true) {
    const watcherInstance = yield takeLatest(POLL_HUBS_START, pollHubs)
    // cancel polling on POLL_HUBS_STOP
    yield take(POLL_HUBS_STOP)
    yield cancel(watcherInstance)
  }
}
