import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import connect from "./../../../../utils/connect";
import { setBreadCrumbs } from "./../../../breadcrumbs/actions";
import {CircularProgress} from '@mineral/core'
import "./SLM.less";

import ReactFrame from "./../ReactFrame";
import Resource from "./Resource";

// Settings
const SETTINGS = Resource.get("Settings")
//slm
const SLM_NAME = Resource.get("SLM")

class SLM extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillMount = () => {
   
        this.setState({ sid:this.props.sid });
      
  };

  componentDidMount() {
    this.props.setBreadCrumbs([
      {
        //Settings
        name: SETTINGS,
        link: "/settings",
      },
      {
        //SLM
        name: SLM_NAME,
        link: "/settings/slm",
      },
    ]);
  }
  render() {
    let view = (
      <div className="spinner" style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><CircularProgress style={{color: '#3272D9'}} /></div>

    );

    if (this.state.sid) {
        let do_heartbeat = window.OPERATOR_CONSOLE.do_heartbeat
        if (do_heartbeat !== 'true' && do_heartbeat !== 'false') {
          do_heartbeat = false
        }
      view = (
        <ReactFrame
          url="slm"
          keepalive={do_heartbeat}
          sid={this.state.sid}
          portletId="slm"
          method="GET"
        />
      );
    }

    return view;
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setBreadCrumbs: (items) => dispatch(setBreadCrumbs(items)),
  };
};

const mapStateToProps = (state) => {
  return {
    saas: state.saas,
    sid: state.sid,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SLM));
