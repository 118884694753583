// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Remote monitoring (also known as agentless monitoring) requires a monitoring host. The monitoring host is responsible for collecting data from the remote device that you are monitoring.
  content["Remote monitoring (also known as agentless monitoring) requires a monitoring host. The monitoring host is responsible for collecting data from the remote device that you are monitoring."] =
  "Die Remote-Überwachung (auch als \"agentenlose Überwachung\" bezeichnet) erfordert einen Überwachungshost. Der Überwachungshost ist für das Erfassen von Daten des überwachten Remote-Geräts zuständig.";

  //Monitoring host server
  content["Monitoring host server"] =
  "Überwachungs-Hostserver";

  //Deploy a monitoring
  content["Deploy a monitoring"] =
  "Überwachung bereitstellen";

  //Recommended minimum system requirements
  content["Recommended minimum system requirements"] =
  "Empfohlene Mindestanforderungen an das System";

  //Docker monitoring requires an agent (aka Robot) to be installed on the Docker Engine server.
  content["Docker monitoring requires an agent (aka Robot) to be installed on the Docker Engine server."] =
  "Die Docker-Überwachung erfordert einen Agenten (auch als \"Robot\" bezeichnet), der auf dem Docker Engine-Server installiert sein muss.";

  //IP address
  content["IP address"] =
  "IP-Adresse";

  //Username
  content["Username"] =
  "Benutzername";

  //Password
  content["Password"] =
  "Kennwort";

  //Sudo Password (Linux only)
  content["Sudo Password (Linux only)"] =
  "Sudo-Kennwort (nur Linux)";

  //4 GB of available memory
  content["4 GB of available memory"] =
  "4 GB verfügbarer Speicher";

  //2 or more CPUs
  content["2 or more CPUs"] =
  "2 oder mehr CPUs";

  //10 GB of available disk space
  content["10 GB of available disk space"] =
  "10 GB verfügbarer Speicherplatz";

  //Ports 48000 to 48025 open
  content["Ports 48000 to 48025 open"] =
  "Ports 48000 bis 48025 offen";

  // END OF LOCALIZATION

export default content;
