import React, { Component } from "react";
import {
  Checkbox,
  Dialog,
  DialogTitle,
  Select as DropDownMenu,
  Button as FlatButton,
  LinearProgress,
  MenuItem,
  Button as RaisedButton,
  TextField,
} from "@mineral/core";
import {
  InfoIcon,
  CenterError,
} from "../../../ui-components/uim-components";
import {CircularProgress} from '@mineral/core'
import InlineMessage from "./../../../message/InlineMessage";
import * as messageTypes from "./../../../message/InlineMessageTypes";
import "./slackConfig.less";
import connect from "./../../../../utils/connect";
import IntegrationLogo, { TYPE_SLACK } from "./../../../icons/IntegrationLogo";
import { withRouter } from "react-router-dom";
import {
  get,
  save,
  deleteSlackConfig,
} from "./../../../../api/integrations/slack/actions";
import * as IntegrationError from "./../IntegrationError";
import AdvancedCheckbox from "../pagerduty/AdvancedCheckbox";
import SeverityIcon, {
  LEVEL_CRITICAL,
  LEVEL_MAJOR,
  LEVEL_MINOR,
  LEVEL_WARNING,
  LEVEL_INFO,
} from "./../../../icons/SeverityIcon";
import { setBreadCrumbs } from "./../../../breadcrumbs/actions";

const SLACK_WEBHOOK_URL = "https://my.slack.com/services/new/incoming-webhook/";

class SlackConfig extends Component {
  state = {
    isDirty: false,
    showDeleteDialog: false,
    deleteDialogActionsDisabled: false,
  };

  componentWillMount() {
    this.props.setBreadCrumbs([
      {
        name: "Settings",
        link: "/settings",
      },
      {
        name: "Slack",
        link: "/settings/integrations/slack",
      },
    ]);
    this.props.fetchConfig();
  }

  stateChanged = () => {
    this.setState({ isDirty: true });
  };

  handleCancel = () => {
    this.props.history.push("/settings");
  };

  handleSave = () => {
    this.props.save({ ...this.props.config }, this.saveSuccess);
  };

  handleDelete = () => {
    this.setState({
      showDeleteDialog: true,
      deleteDialogActionsDisabled: false,
    });
  };

  handleWebhookChange = (event) => {
    this.stateChanged();
    this.props.config.webhook = event.target.value;
  };

  handleAutoCreateDelay = (event) => {
    this.stateChanged();
    this.props.config.autoCreateDelayInSeconds = event.target.value;
  };

  saveSuccess = () => {
    this.props.history.push("/settings");
  };

  deleteSuccess = () => {
    this.setState({ showDeleteDialog: false });
    this.props.history.push("/settings");
  };

  deleteFailure = () => {
    this.setState({ showDeleteDialog: false });
  };

  saveButtonDisabled = () => {
    if (this.state.isDirty && this.props.config.webhook) {
      return false;
    }

    return true;
  };

  getSeverityCheckboxState = (level) => {
    if (this.props.config.severityOptions) {
      return this.props.config.severityOptions.indexOf(level) >= 0;
    }

    return false;
  };

  handleSeverityCheck = (isChecked, severity) => {
    var items = this.props.config.severityOptions;
    var index;
    if (items) {
      if (isChecked) {
        index = items.indexOf(severity);
        if (index === -1) {
          items.push(severity);
          this.props.config.severityOptions = items;
          this.stateChanged();
        }
      } else {
        index = items.indexOf(severity);
        if (index > -1) {
          items.splice(index, 1);
          this.props.config.severityOptions = items;
          this.stateChanged();
        }
      }
    }
  };

  getGeneralErrorMessageComponent = () => {
    const generalError = IntegrationError.getErrorMessageForKey(
      IntegrationError.ERROR_GENERAL,
      this.props.responseError
    );
    if (generalError != null) {
      return <InlineMessage type={messageTypes.ERROR} message={generalError} />;
    }

    return null;
  };

  getDialogComponent = () => {
    const actions = [
      <FlatButton
        variant="text"
        disabled={this.state.deleteDialogActionsDisabled}
        children="CANCEL"
        color="primary"
        onClick={this.handleDeleteDialogCancel}
      />,
      <FlatButton
        variant="text"
        disabled={this.state.deleteDialogActionsDisabled}
        children="DELETE"
        color="primary"
        onClick={this.handleDeleteDialogAccept}
      />,
    ];

    return (
      <Dialog
        style={{ width: "330px" }}
        actions={actions}
        //title="Delete integration?"
        open={this.state.showDeleteDialog}
        modal={true}
        aria-labelledby="dialog-title"
      >
        <DialogTitle id="dialog-title">{"Delete integration?"}</DialogTitle>
        <span style={{ lineHeight: "24px" }}>
          <span>This action removes the Slack integration.</span>
          <br />
          <br />
          <span>Alarms will no longer trigger messages to Slack.</span>
        </span>
      </Dialog>
    );
  };

  handleDeleteDialogCancel = () => {
    this.setState({ showDeleteDialog: false });
  };

  handleDeleteDialogAccept = () => {
    this.setState({ deleteDialogActionsDisabled: true });
    this.props.deleteSlackConfig(
      { ...this.props.config },
      this.deleteSuccess,
      this.deleteFailure
    );
  };

  handleCreateWhenNewCheck = (event) => {
    this.props.config.createWhenNewAlarms = event.target.checked;
    this.stateChanged();
  };

  handleCreateWhenSeverityCheck = (event) => {
    this.props.config.createWhenSeverityChanges = event.target.checked;
    this.stateChanged();
  };

  handleCreateWhenAlarmsClosedCheck = (event) => {
    this.props.config.createWhenAlarmsClosed = event.target.checked;
    this.stateChanged();
  };

  render = () => {
    let fetchErrorMessage = IntegrationError.getErrorMessageForKey(
      IntegrationError.ERROR_FETCH,
      this.props.responseError
    );
    if (this.props.isFetching) {
      return (
        <div>
          <LinearProgress style={{ height: "8px" }} />
        </div>
      );
    } else if (fetchErrorMessage) {
      return (
        <div
          style={{ display: "flex", alignItems: "center", marginTop: "100px" }}
        >
          <CenterError
            message={fetchErrorMessage}
            title="Communication Error"
          />
        </div>
      );
    }

    return (
      <div className="slack-config__wrapper">
        {this.getGeneralErrorMessageComponent()}
        {this.getDialogComponent()}

        <div className="slack-config__content">
          <div className="slack-config__leftcontent">
            <div className="slack-config__header">
              <IntegrationLogo height="26px" type={TYPE_SLACK} />
            </div>

            <div className="slack-config__body">
              <div className="slack-config__item">
                <div className="slack-config__input-label">
                  Slack-generated webhook
                </div>
                <TextField
                  disabled={
                    this.props.state === "EXISTING" || this.props.isSaving
                  }
                  style={{ width: "720px" }}
                  value={this.props.config.webhook || ""}
                  //errorStyle={{ marginTop: '6px' }}
                  helperText={IntegrationError.getErrorMessageForKey(
                    IntegrationError.ERROR_SLACK_WEBHOOK,
                    this.props.responseError
                  )}
                  onChange={this.handleWebhookChange}
                />
              </div>

              <div className="slack-config__item">
                <div className="slack-config__input-header-label">
                  Create new Slack messages when...
                </div>
                <Checkbox
                  disabled={this.props.isSaving}
                  checked={this.props.config.createWhenNewAlarms}
                  labelStyle={{ fontSize: "16px", maxWidth: "350px" }}
                  children="New alarms are generated"
                  onChange={this.handleCreateWhenNewCheck}
                />
                <Checkbox
                  disabled={this.props.isSaving}
                  checked={this.props.config.createWhenSeverityChanges}
                  labelStyle={{ fontSize: "16px", maxWidth: "350px" }}
                  children="Alarms have severity changes"
                  onChange={this.handleCreateWhenSeverityCheck}
                />
                <Checkbox
                  disabled={this.props.isSaving}
                  checked={this.props.config.createWhenAlarmsClosed}
                  labelStyle={{ fontSize: "16px", maxWidth: "350px" }}
                  children="Alarms are closed"
                  onChange={this.handleCreateWhenAlarmsClosedCheck}
                />
              </div>

              <div className="slack-config__item">
                <div className="slack-config__input-header-label">
                  With the following severities...
                </div>
                <div>
                  <div style={{ paddingLeft: "16px" }}>
                    <div
                      style={{ display: "inline-block", verticalAlign: "top" }}
                    >
                      <AdvancedCheckbox
                        disabled={this.props.isSaving}
                        icon={
                          <SeverityIcon level={LEVEL_CRITICAL} size="24px" />
                        }
                        label="Critical"
                        checked={this.getSeverityCheckboxState(LEVEL_CRITICAL)}
                        onCheck={(event) => {
                          this.handleSeverityCheck(
                            event.target.checked,
                            LEVEL_CRITICAL
                          );
                        }}
                      />
                      <AdvancedCheckbox
                        disabled={this.props.isSaving}
                        icon={<SeverityIcon level={LEVEL_MAJOR} size="24px" />}
                        label="Major"
                        checked={this.getSeverityCheckboxState(LEVEL_MAJOR)}
                        onCheck={(event) => {
                          this.handleSeverityCheck(
                            event.target.checked,
                            LEVEL_MAJOR
                          );
                        }}
                      />
                      <AdvancedCheckbox
                        disabled={this.props.isSaving}
                        icon={<SeverityIcon level={LEVEL_MINOR} size="24px" />}
                        label="Minor"
                        checked={this.getSeverityCheckboxState(LEVEL_MINOR)}
                        onCheck={(event) => {
                          this.handleSeverityCheck(
                            event.target.checked,
                            LEVEL_MINOR
                          );
                        }}
                      />
                    </div>
                    <div
                      style={{
                        display: "inline-block",
                        verticalAlign: "top",
                        marginLeft: "48px",
                      }}
                    >
                      <AdvancedCheckbox
                        disabled={this.props.isSaving}
                        icon={
                          <SeverityIcon level={LEVEL_WARNING} size="24px" />
                        }
                        label="Warning"
                        checked={this.getSeverityCheckboxState(LEVEL_WARNING)}
                        onCheck={(event, isChecked) => {
                          this.handleSeverityCheck(isChecked, LEVEL_WARNING);
                        }}
                      />
                      <AdvancedCheckbox
                        disabled={this.props.isSaving}
                        icon={<SeverityIcon level={LEVEL_INFO} size="24px" />}
                        label="Info"
                        checked={this.getSeverityCheckboxState(LEVEL_INFO)}
                        onCheck={(event, isChecked) => {
                          this.handleSeverityCheck(isChecked, LEVEL_INFO);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="slack-config__item">
                <div
                  className="slack-config__input-header-label"
                  style={{ paddingLeft: "16px" }}
                >
                  Trigger message creation
                </div>
                <DropDownMenu
                  disabled={this.props.isSaving}
                  value={this.props.config.autoCreateDelayInSeconds}
                  onChange={this.handleAutoCreateDelay}
                >
                  <MenuItem value={0} children="Immediately" />
                  <MenuItem value={5 * 60} children="After 5 minutes" />
                  <MenuItem value={10 * 60} children="After 10 minutes" />
                  <MenuItem value={15 * 60} children="After 15 minutes" />
                  <MenuItem value={30 * 60} children="After 30 minutes" />
                </DropDownMenu>
                <div
                  className="slack-config__input-label"
                  style={{ paddingLeft: "16px" }}
                >
                  Adding a delay may resolve "flapping", a situation where
                  alarms open and auto-close in a short time frame.
                </div>
              </div>
            </div>

            <div className="slack-config__footer">
              <div className="slack-config__footer-left">
                <RaisedButton
                  variant="contained"
                  children="DELETE"
                  disabled={this.props.state === "NEW"}
                  onClick={this.handleDelete}
                />
              </div>

              <div className="slack-config__footer-right">
                <RaisedButton
                  variant="contained"
                  children="CANCEL"
                  disabled={this.props.isSaving}
                  onClick={this.handleCancel}
                />

                {this.props.isSaving ? (
                  <RaisedButton disabled={true} variant="contained">
                    <CircularProgress style={{color: '#3272D9'}} />
                  </RaisedButton>
                ) : (
                  <RaisedButton
                    variant="contained"
                    disabled={this.saveButtonDisabled()}
                    children="SAVE"
                    color="primary"
                    onClick={this.handleSave}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="slack-config__rightcontent">
            <div className="slack-config__rightcontent__infobox">
              <div className="slack-config__rightcontent__infobox-header">
                <InfoIcon className="slack-config__rightcontent__infobox-header-icon" />
                <span>Slack integration</span>
              </div>
              <div className="slack-config__rightcontent__infobox-content">
                <div>
                  To post Infrastructure Management alarms to Slack, a "webhook"
                  must be generated. Use the following link to select the target
                  Slack channel and generate a webhook.
                </div>
                <div>
                  <a href={SLACK_WEBHOOK_URL} target="_blank">
                    {SLACK_WEBHOOK_URL}
                  </a>
                </div>
                {!this.props.saas ? (
                  <div>
                    Note: To enable this functionality, the UIM server must have
                    access to the internet
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setBreadCrumbs: (items) => dispatch(setBreadCrumbs(items)),
    fetchConfig: () => dispatch(get()),
    save: (config, successCallback, failureCallback) =>
      dispatch(save(config, successCallback, failureCallback)),
    deleteSlackConfig: (config, successCallback, failureCallback) =>
      dispatch(deleteSlackConfig(config, successCallback, failureCallback)),
  };
};

const mapStateToProps = (state) => {
  return {
    isValidating: state.slack.isValidating,
    isFetching: state.slack.isFetching,
    isSaving: state.slack.isSaving,
    state: state.slack.config.state,
    config: state.slack.mutableConfig,
    responseError: state.slack.responseError,
    saas: state.saas,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SlackConfig));
