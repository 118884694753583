import axios from 'axios';
import config from './../../config';

class ScheduleDiscovery{
    getSchedule(agentId){
        return axios.get([config.basename, 'api/v1/schedules',agentId].join('/'));
    }
    postSchedule(agentId,info){
        return axios.post([config.basename, 'api/v1/schedules',agentId].join('/'),info);
    }
    putSchedule(agentId,info){
        return axios.put([config.basename, 'api/v1/schedules',agentId].join('/'),info);
    }
    deleteSchedule(agentId){
        return axios.delete([config.basename, 'api/v1/schedules',agentId].join('/'));
    }
    runDiscovery(agentId,isDeployRobot,networkIds){
        let url = [config.basename, 'api/v1/app-discovery/run/'+agentId+'?isDeployRobot='+isDeployRobot+'&networkIds='+networkIds].join('/')

        if(process.env.NODE_ENV=='development') {
            url = "http://10.252.25.208:8080/castlemock/mock/rest/project/TK8t3o/application/UXxhjW/runDiscoveryPost"
        }
    	return axios.post(url);
    }
}

export default new ScheduleDiscovery();