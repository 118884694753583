import React from 'react'
import IntegrationCard from './../IntegrationCard'
import ArrowIcon from './../../ArrowIcon'
import Resource from './Resource'
import {Tooltip} from '@mineral/core';

export default class RESTmonCard extends IntegrationCard {
  getIcon() {
    return <ArrowIcon />
  }

  getSubLabelTwo = () => {
    return this.props.subLabelTwo
  }
}

const TextLogo = ({ label }) => {
  return (
    <Tooltip title={label}>
    <span className="settings-text-logo admin__console--card">{label}</span>
    </Tooltip>
  )
}

//RESTMon
const RESTMonLabel = Resource.get('RESTMon')
//Create custom RESTful API Monitoring
const Creating = Resource.get('Create custom RESTful API Monitoring')

RESTmonCard.defaultProps = {
  logo: <TextLogo label= {RESTMonLabel} />,
  configRoute: '/settings/restmon',
  subLabelOne: Creating,
  subLabelTwo: '',
}
