import React from 'react'
import get from 'lodash/get'
import RequestTrialsExtension from './RequestTrialsExtension'
import UpgradeRobots from './UpgradeRobots'

import './modals.less'
const ModalManager = ({
  selectedModal,
  handleCloseModal,
  daysExpired,
  redirectToLogin,
  featureFlags,
}) => {
  let modalComponent
  switch (selectedModal) {
    case 'REQUEST_TRIALS_EXTENSION':
      modalComponent = (
        <RequestTrialsExtension handleCloseModal={handleCloseModal} />
      )
      break
    case 'SHOW_ROBOTS_FOR_UPGRADE':
     // console.log('SHow robot upgrade modal')
      modalComponent = get(featureFlags, 'disableRobotUpgrade') ? null : (
        <UpgradeRobots handleCloseModal={handleCloseModal} />
      )
      break
    /* case 'TRIAL_EXPIRED':
      modalComponent = (
        <TrialExpired
          handleCloseModal={handleCloseModal}
          daysExpired={daysExpired}
        />
      )
      break*/
    default:
      modalComponent = (
        <RequestTrialsExtension handleCloseModal={handleCloseModal} />
      )
      break
  }
  let view = null
  if (selectedModal && modalComponent) {
    view = (
      <div className="modalcontainer">
        <div className="modalcontainer__overlay" />
        {modalComponent}
      </div>
    )
  }
  return view
}

export default ModalManager
