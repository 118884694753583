import React, { Component } from "react";
import { loadCommon } from "./../../../utils/common";
import Menu from "./Menu";
import ReactClickOutside from "react-click-outside";

import { connect } from "react-redux";
class MenuContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productList: this.props.productList,
      open: false,
      tenantId: null,
    };
  }
  componentWillMount = () => {
    loadCommon().then((ca_cloud) => {
      ca_cloud.getUser().then((response) => {
        this.setState({
          tenantId: response.data.tenantId,
        });
      });
    });
  };
  openMenu = () => {
    this.setState({
      open: true,
    });
  };

  handleCloseMenu = () => {
    this.setState({
      open: false,
    });
  };
  handleClickOutside = () => {
    this.setState({ open: false });
  };
  render() {
    return (
      <Menu
        productList={this.props.productList}
        open={this.state.open}
        handleCloseMenu={this.handleCloseMenu}
        openMenu={this.openMenu}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    productList: state.user.productList,
  };
};

export default connect(mapStateToProps, null)(ReactClickOutside(MenuContainer));
