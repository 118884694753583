import {
  REQUEST_COMPUTER_SYSTEM,
  REQUEST_COMPUTER_SYSTEMS,
  FETCH_COMPUTER_SYSTEM_SUCCESS,
  FETCH_COMPUTER_SYSTEMS_SUCCESS,
  FETCH_COMPUTER_SYSTEM_FAILURE,
  FETCH_COMPUTER_SYSTEMS_FAILURE,
  INVENTORY_TREE_SELECTION,
  SET_COMPUTER_SYSTEMS_TOTAL_ITEMS,
  SET_COMPUTER_SYSTEMS_SORT,
  SET_COMPUTER_SYSTEMS_FILTER,
  SET_COMPUTER_SYSTEMS_COLUMN_FILTER,
  SET_COMPUTER_SYSTEMS_QUICK_FILTER,
  SET_COMPUTER_SYSTEMS_REFRESH_GRID_DATA,
  SET_COMPUTER_SYSTEMS_TREE,
  FETCH_LIST_SUCCESS,
} from "./actionTypes";
import entityTypes from "./../entityTypes";
import isEqual from "lodash/isEqual";
import cloneDeep from "lodash/cloneDeep";
import { cleanItems } from "../../components/entities/setEntitiesReducer";

let updatedState = {};

const computerSystems = (
  state = {
    isFetching: false,
    didInvalidate: false,
    items: [],
    monitoringProbes: [],
  },
  action
) => {
  switch (action.type) {
    case REQUEST_COMPUTER_SYSTEM:
    case REQUEST_COMPUTER_SYSTEMS:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false,
      });
    case FETCH_COMPUTER_SYSTEM_SUCCESS:
      const item = Object.assign({}, action.data, {
        entityType: entityTypes.COMPUTER_SYSTEM,
      });
      updatedState = Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        item: item,
      });
      // updatedState = Object.assign({}, state, {
      //   isFetching: false,
      //   didInvalidate: false,
      //   item: {
      //     ...action.data,
      //     entityType: entityTypes.COMPUTER_SYSTEM,
      //     _items: action.data._items.map(item => {
      //       return {...cloneDeep(item), entityType: entityTypes.COMPUTER_SYSTEM }
      //     })
      //   },
      // })
      return isEqual(updatedState, state) ? state : updatedState;
    case FETCH_COMPUTER_SYSTEMS_SUCCESS:
      // const items = action.data._items
      // updatedState = Object.assign({}, state, {
      //   isFetching: false,
      //   didInvalidate: false,
      //   items: items,
      // })
      const items = cleanItems(
        action.data._items.map((item) => {
          return {
            ...cloneDeep(item),
            entityType: entityTypes.COMPUTER_SYSTEM,
          };
        })
      );
      updatedState = Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        item: {
          ...action.data,
          entityType: entityTypes.COMPUTER_SYSTEM,
          items,
        },
      });
      return isEqual(updatedState, state) ? state : updatedState;
    case FETCH_COMPUTER_SYSTEM_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        errorMessage: action.errorMessage,
        item: [],
      });
    case FETCH_COMPUTER_SYSTEMS_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        errorMessage: action.errorMessage,
        items: [],
      });
    case INVENTORY_TREE_SELECTION:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        errorMessage: action.errorMessage,
        items: [],
      });
    case FETCH_LIST_SUCCESS:
      return Object.assign({}, state, {
        monitoringProbes: action.data,
      });

    default:
      return state;
  }
};

export function entitiesFilterSortConfig(
  state = {
    totalItems: 0,
    suppressGridUpdate: false,
    config: {
      scopeId: null,
      probeName: null,
      daId: null,
      pageSize: 100,
      sortColumn: null,
      sortOrder: null,
      quickFilter: null,
      colFilters: [],
    },
  },
  action
) {
  const newState = cloneDeep(state);
  switch (action.type) {
    case SET_COMPUTER_SYSTEMS_TOTAL_ITEMS:
      return state.totalItems === action.totalItems
        ? state
        : { ...state, totalItems: action.totalItems };
    case SET_COMPUTER_SYSTEMS_SORT:
      newState.config.sortColumn = action.sort.sortColumn;
      newState.config.sortOrder = action.sort.sortDirection;
      return newState;
    case SET_COMPUTER_SYSTEMS_FILTER:
      newState.config.filters = action.filters;
      return newState;
    case SET_COMPUTER_SYSTEMS_COLUMN_FILTER:
      newState.config.colFilters = action.colFilters;
      return newState;
    case SET_COMPUTER_SYSTEMS_QUICK_FILTER:
      newState.config.quickFilter = action.filter;
      return newState;
    case SET_COMPUTER_SYSTEMS_TREE:
      newState.config.scopeId = action.config.scopeId;
      newState.config.daId = action.config.daId;
      return newState;
    case SET_COMPUTER_SYSTEMS_REFRESH_GRID_DATA:
      newState.refreshGridData = action.refreshGridData;
      return newState;
    default:
      return state;
  }
}

export default computerSystems;
