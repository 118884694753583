import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
// import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import "url-search-params-polyfill";
import Bootstrap from "./Bootstrap";
import Heartbeat from "./utils/Heartbeat";
import loadIPCE from "./utils/scriptLoader";
import SessionTimeoutCheck from "./utils/SessionTimeoutCheck";
import configureStore from "./configureStore";
import config from "./api/config";
// import theme from "./theme";
import "./assets/css/index.less";
import { Button, ThemeProvider, theme } from "@mineral/core";

if (process.env.NODE_ENV == "development") {
  window.OPERATOR_CONSOLE = {
    sid: "Bearer " + "<%=sid%>",
    isSaas: "<%=isSaas%>",
    do_heartbeat: "<%=doHeartbeat%>",
    cspNonce: "<%=nonse%>",
  };
}
const store = configureStore();
loadIPCE();
const heartbeat = new Heartbeat(store.dispatch);
let do_heartbeat = window.OPERATOR_CONSOLE.do_heartbeat;

if (do_heartbeat !== "true" && do_heartbeat !== "false") {
  do_heartbeat = false;
}
if (do_heartbeat === "true") heartbeat.start(store.dispatch);

const sessionTimeoutCheck = new SessionTimeoutCheck(store.dispatch);
sessionTimeoutCheck.start(store.dispatch);

ReactDOM.render(
  <Provider store={store}>
    <Router basename={config.basename}>
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Bootstrap />
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </Router>
  </Provider>,
  document.getElementById("root")
);
