import {
    UPDATE_PWD,
    UPDATE_PWD_SUCCESS,
    UPDATE_PWD_FAILURE,
    RESET_MESSAGE

} from './actionTypes'


import {resetPassword} from './updatepwd';

export function setPassword() {
    return {
        type: UPDATE_PWD       
    }
}

export function changePwdSuccess(response) {
    return {
      type: UPDATE_PWD_SUCCESS,
      successMessage: response.data,
    };
  }
  export function changePwdFailure(error) {
    return {
      type: UPDATE_PWD_FAILURE,
      errorMessage: error.response.data,
    };
  }

  export function resetMessage(){
    return {
      type: RESET_MESSAGE
    }
  }

  export function changePassword(params) {
    return (dispatch) => {
      dispatch(setPassword()) 
      return  resetPassword(params)
        .then((response) => dispatch(changePwdSuccess(response)))
      
        .catch(function (error) {
          dispatch(changePwdFailure(error));
        });
    };
  }

  export function resetResponseMsg(){
    return (dispatch) => {
       dispatch(resetMessage())
    }
  }
