export const REQUEST_ROBOTS = 'REQUEST_ROBOTS'
export const FETCH_ROBOTS = 'FETCH_ROBOTS'
export const FETCH_ROBOTS_SUCCESS = 'FETCH_ROBOTS_SUCCESS'
export const FETCH_ROBOTS_FAILURE = 'FETCH_ROBOTS_FAILURE'
export const CREATE_ROBOTS = 'CREATE_ROBOTS'
export const CREATE_ROBOTS_SUCCESS = 'CREATE_ROBOTS_SUCCESS'
export const CREATE_ROBOTS_FAILURE = 'CREATE_ROBOTS_FAILURE'
export const REQUEST_ROBOTS_FOR_UPGRADE = 'REQUEST_ROBOTS_FOR_UPGRADE'
export const FETCH_ROBOTS_FOR_UPGRADE_SUCCESS =
  'FETCH_ROBOTS_FOR_UPGRADE_SUCCESS'
export const FETCH_ROBOTS_FOR_UPGRADE_FAILURE =
  'FETCH_ROBOTS_FOR_UPGRADE_FAILURE'
export const DEPLOY_ROBOTS = 'DEPLOY_ROBOTS'
export const DEPLOY_ROBOTS_SUCCESS = 'DEPLOY_ROBOTS_SUCCESS'
export const DEPLOY_ROBOTS_FAILURE = 'DEPLOY_ROBOTS_FAILURE'  
