import Resource from './Resource'
export const ERROR_FETCH = 'ERROR_FETCH';
export const ERROR_GENERAL = 'ERROR_GENERAL';

export const ERROR_PAGERDUTY_APIKEY = 'ERROR_PAGERDUTY_APIKEY';
export const ERROR_PAGERDUTY_SERVICENAME = 'ERROR_PAGERDUTY_SERVICENAME';

export const ERROR_SLACK_WEBHOOK= 'ERROR_SLACK_WEBHOOK';
export const ERROR_SLACK_CHANNELNAME = 'ERROR_SLACK_CHANNELNAME';

export const ERROR_SERVICENOW_USERNAME = 'ERROR_SERVICENOW_USERNAME';
export const ERROR_SERVICENOW_PASSWORD = 'ERROR_SERVICENOW_PASSWORD';
export const ERROR_SERVICENOW_URL = 'ERROR_SERVICENOW_URL';

export function getDefaultError() {
  return {
    type : ERROR_GENERAL,
    //Communication Error
    message : Resource.get('Communication Error')
  }
}

export function getErrorMessageForKey(key, responseError) {
  if(responseError && responseError.type && responseError.type === key) {
    return responseError.message;
  }
  return null;
};
