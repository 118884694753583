import React from 'react'
import ReactPaginate from 'react-paginate'
import './paginate.less'

const Paginate = (props) => <ReactPaginate {...props} />

Paginate.defaultProps = {
  activeclassname: 'active',
  containerClassName: 'paginate',
  pageRangeDisplayed: 5,
  previousLabel: '<',
  marginPagesDisplayed: 2,
  nextLabel: '>',
  subContainerClassName: 'pages paginate',
}

export default Paginate