// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //System update available
  content["System update available"] =
  "Atualização de sistema disponível";

  //A new version of the robot software is available.
  content["A new version of the robot software is available."] =
  "Uma nova versão do software do robô está disponível.";

  //Deployment takes about 1 minute, during which time no metrics are collected and no alarms are created.
  content["Deployment takes about 1 minute, during which time no metrics are collected and no alarms are created."] =
  "A implantação leva cerca de 1 minuto e, durante esse tempo, nenhuma métrica será coletada e nenhum alarme será criado.";

  //LATER
  content["LATER"] =
  "MAIS TARDE";

  //DEPLOY
  content["DEPLOY"] =
  "IMPLANTAR";

  // END OF LOCALIZATION

export default content;
