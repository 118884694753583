import connect from "./../../utils/connect";
import SetupWizard from "./SetupWizard";
import { addBreadCrumb, setBreadCrumbs } from "./../breadcrumbs/actions";
import {
  requestHubs,
  pollHubsStart,
  pollHubsStop,
} from "../../api/hubs/actions";
import { fetchRobots, pollFetchRobots } from "../../api/robots/actions";
import { postDiscovery } from "../../api/discovery/actions";
import {
  fetchProbes,
  pollProbesStart,
  pollProbesStop,
} from "../../api/setup-wizard/actions";

const mapStateToProps = (state) => {
  return {
    sid: state.sid,
    hubs: state.hubs.items,
    hubsIsFetching: state.hubs.isFetching,
    robots: state.robots.items,
    robotsIsFetching: state.robots.isFetching,
    discovery: state.discovery.items,
    robotsStatus: state.robotsStatus.status,
    probeList: state.wizardProbeList.items,
    featureFlags: state.configuration.items,
    acl: state.acl,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addBreadCrumb: (item) => dispatch(addBreadCrumb(item)),
    getHubs: () => dispatch(requestHubs()),
    pollHubsStart: () => dispatch(pollHubsStart()),
    pollHubsStop: () => dispatch(pollHubsStop()),
    getRobots: () => dispatch(fetchRobots()),
    pollRobots: () => dispatch(pollFetchRobots()),
    setBreadCrumbs: (items) => dispatch(setBreadCrumbs(items)),
    postDiscovery: (formData) => dispatch(postDiscovery(formData)),
    fetchProbes: (count, useEnhancedTemplates, categories) =>
      dispatch(fetchProbes(count, useEnhancedTemplates, categories)),
    pollProbesStart: (useEnhancedTemplates, categories) =>
      dispatch(pollProbesStart(useEnhancedTemplates, categories)),
    pollProbesStop: () => dispatch(pollProbesStop()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SetupWizard);
