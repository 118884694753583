import React, {Component} from 'react';
import AdminConsoleApi from './../../../api/admin-console/adminConsole'
import ArrowIcon from './../ArrowIcon'
import Resource from './Resource'
import {Link} from 'react-router-dom';
import {Card,Button} from '@mineral/core';
import '../integrations/integrationCard.less';
import ErrorDialog from './../../titlebar/ErrorDialog'
import {Tooltip} from '@mineral/core';


export default class AdminConsoleCardOnPrem extends Component {

    constructor(props) {
        super(props)
        this.state = {adminconsoleUrl:"",isError:false,disableLink: true,}
      }

      componentWillMount() {
        this.getAdminConsoleURL()
      }
  getIcon() {
    return <ArrowIcon />
  }
  componentWillReceiveProps() {
      this.setState({
          adminconsoleUrl:"",isError:false,disableLink: true,
      })
      this.getAdminConsoleURL();
    }


  getAdminConsoleURL() {

      AdminConsoleApi.getAdminConsoleURL()
        .then(response => {
          let adminconsoleUrl = response.data

          this.setState({
              adminconsoleUrl:adminconsoleUrl,
              disableLink: false,
              isError:false
          })
        })
        .catch(error=>{
          //console.log(error)
          this.setState({
              disableLink: true,
              isError:true
          })
        })
    }

  getCardClassName() {
      return ['integration__card', this.props.className].join(' ');
    }
	
  launchAdminconsoleWithPost = () => {
   if(this.state.adminconsoleUrl){
	const appForm = document.createElement('form')
	appForm.setAttribute('style','')
	appForm.style.display = 'none'
	appForm.method = 'POST'
	const urlWithParams = this.state.adminconsoleUrl
	const baseUrl = urlWithParams.split("?")
	const params = baseUrl[1]
	appForm.action = baseUrl[0]
	appForm.target = '_blank'
	const paramArr = params.split("&")
	const userParams = paramArr[0].split("=")
	const passParams = paramArr[1].split("=")
   
	const userInput = document.createElement('input')
	userInput.type = 'text'
	userInput.name = userParams[0]
	userInput.value = decodeURIComponent(userParams[1])  
	appForm.appendChild(userInput)
  
	const passInput = document.createElement('input')
	passInput.type = 'text'
	passInput.name = passParams[0]
	passInput.value = decodeURIComponent(passParams[1])  
	appForm.appendChild(passInput)  
	document.body.appendChild(appForm)
	appForm.submit()
   }
  }



    handleCloseErrorDialog = () => {
        this.setState({ isError: false });
      };

    render() {

      return (
              <div>
              <ErrorDialog
            open={this.state.isError}
            handleClose={this.handleCloseErrorDialog}
            errorType={"adminConsoleLink"}
          />
          <div className='settings-card' tabIndex={-1}>
            <div tabIndex={-1} className="settings__card--navlink">
            
              <Button style={{backgroundColor:'#FFFFFF'}} variant="outlined" role ="link" aria-label= {
                //Admin Console
                Resource.get('Admin Console')}  className={this.getCardClassName()} onClick={this.launchAdminconsoleWithPost}>
              <div className='integration__card--body' style={{flexDirection:'column'}}>
              
              <Tooltip title={//Admin Console 
              Resource.get('Admin Console')}>
                <span className="settings-text-logo admin__console--card">{//Admin Console
                    Resource.get('Admin Console')}</span>
                    </Tooltip>
            
                <div style={{marginTop: '10px'}}>
                <span className='integration__card--subLabelOne'>{ //Managed via
                        Resource.get('Manage hubs,robots & probes')}</span>
                  </div>
                 
                <div className='integration__card--icon'>
                  {this.getIcon()}
                </div>
              </div>

              </Button>

              
            </div>
          </div>
                    </div>
      );
    }
  }
