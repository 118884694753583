import React from 'react'
import { Route } from 'react-router-dom'
import ToggleIcons from './toggleIcons/ToggleIconsRedux'
import CabiControlsRedux from './../../cabi/secondaryNav/controls/CabiControlsRedux'
import './secondaryNav.less'
import CrossLaunchHeader from '../../groups/secondaryNav/crossLaunchHeader/CrossLaunchHeader'

const SecondaryNav = (props, context) => {
  return (
    <nav className="nav--secondary">
      <Route path={'/technologies/:prid/cabi'} component={CabiControlsRedux} />
      <Route path={'/technologies/:prid/alarms'} component={CrossLaunchHeader} />
      <Route path={'/technologies/:prid/dashboard'} component={CrossLaunchHeader} />
      <ToggleIcons />
    </nav>
  )
}

export default SecondaryNav
