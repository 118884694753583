import React, { useEffect, useState } from "react";
import "./filter.less";
import {
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Popover,
  MenuList,
  Paper,
  Tooltip,
} from "@mineral/core";
import ClearIcon from "@mui/icons-material/Clear";
import { useToggle } from "../../../../utils/useToggle";
import {
  booleanFilterDropdownRenderer,
  stringsFilterDropdownRenderer,
  multiselectFilterDropdownRenderer,
} from "./DropdownRenderer";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUp from "@mui/icons-material/ArrowDropUp";

const ActiveFilter = ({
  filterInitial,
  handleUpdateFilter,
  handleMakeFilterInactive,
  hide,
  isFirstLine,
}) => {
  const [filter, setFilter] = useState(filterInitial);
  const [anchorEl, setAnchorEl] = useState(null);
  const [initialSelectedOptions, setInitialSelectedOptions] = useState(
    filterInitial.options
  );

  const handleUpdateExtraData = (e) => {
    setFilter({ ...filter, extraData: e.target.value });
  };

  const handleUpdateSelectedOptions = (e) => {
    if (filter.sortingDataType === "multiselect") {
      if (e.target.value === "Select All") {
        if (e.target.checked) {
          setFilter({
            ...filter,
            selectedOptions: [...filter.options.map((option) => option.id)],
          });
        } else {
          setFilter({ ...filter, selectedOptions: ["uncheckall"] });
        }
      } else {
        if (
          filter.selectedOptions.find((option) => option === e.target.value)
        ) {
          setFilter({
            ...filter,
            selectedOptions: [
              ...filter.selectedOptions.filter(
                (item) => item !== e.target.value
              ),
            ],
          });
        } else {
          setFilter({
            ...filter,
            selectedOptions: [
              ...filter.selectedOptions.filter((item) => item !== "uncheckall"),
              e.target.value,
            ],
          });
        }
      }
    } else if (filter.sortingDataType === "datetime") {
      const filterIndex = filter.options.findIndex(
        (option) => option.id === e.target.value
      );
      if (!e.target.checkbox) {
        setFilter({
          ...filter,
          selectedOptions:
            filterIndex === filter.options.length - 1
              ? [
                  ...filter.options
                    .filter((option, index) => index <= filterIndex)
                    .map((option) => option.id),
                  "all",
                ]
              : filter.options
                  .filter((option, index) => index <= filterIndex)
                  .map((option) => option.id),
        });
      } else {
        setFilter({
          ...filter,
          selectedOptions:
            filterIndex === 0
              ? ["uncheckall"]
              : filter.options
                  .filter((option, index) => index < filterIndex)
                  .map((option) => option.id),
        });
      }
    } else {
      setFilter({ ...filter, selectedOptions: [e.target.value] });
    }
  };

  const handleSubmitFilter = (e) => {
    handleUpdateFilter({
      ...filter,
      isModified:
        initialSelectedOptions.length !== filter.selectedOptions.length,
    });
    setAnchorEl(null);
  };

  const getLabelForFilter = (filter) => {
    let label = "";
    label += `${filter?.name} `;

    if (["string"].includes(filter?.sortingDataType)) {
      if (!filter.extraData) return label;

      let operationName = filter.options.find(
        (option) => option.id === filter?.selectedOptions[0]
      )?.name;
      label = `${label}${operationName} ${filter?.extraData}`;

      return label;
    }
    if (
      ["multiselect"].includes(filter?.sortingDataType) ||
      ["datetime"].includes(filter?.sortingDataType)
    ) {
      label = `${label} = ${filter?.selectedOptions
        .filter((option) => option !== "uncheckall")
        .toString()}`;
      return label;
    }
    label += filter?.selectedOptions?.map(
      (optionId, index, selectedOptions) =>
        `${filter.options.find((option) => option.id === optionId)?.name}${
          selectedOptions.length !== index + 1 ? ", " : ""
        }`
    );
    return label;
  };

  const renderValues = (filterInArray) => {
    let filter = filterInArray[0];
    let label = getLabelForFilter(filter);

    return (
      <div className="filter_label">
        {label}
        {/*<Tooltip title={'label'} placement="top" arrow>…</Tooltip>*/}
      </div>
    );
  };

  const rendererOptionsProps = {
    filter,
    handleUpdateExtraData,
    handleSubmitFilter,
    handleUpdateSelectedOptions,
  };
  const childrenRendererOptions = {
    string: stringsFilterDropdownRenderer(rendererOptionsProps),
    boolean: booleanFilterDropdownRenderer(rendererOptionsProps),
    multiselect: multiselectFilterDropdownRenderer(rendererOptionsProps),
    datetime: multiselectFilterDropdownRenderer(rendererOptionsProps),
  };

  let ChildrenRenderer = childrenRendererOptions[filterInitial.sortingDataType];
  if (!ChildrenRenderer) ChildrenRenderer = childrenRendererOptions[0];

  const handleOpen = (event) => {
    if (!anchorEl) {
      setAnchorEl(event.currentTarget.closest(".filter"));
      setFilter(filterInitial);
    }
  };

  const handleClose = () => {
    if (anchorEl) setAnchorEl(null);
  };

  return (
    <>
      <div
        className="filter"
        key={filter?.id}
        style={{
           //opacity: hide ? 0 : 1,
           display: hide ? "none" : "flex"
        }}
      >
        {/* <Select
        value={[filterInitial]}
        id={filter?.id}
        open={isOpen}
        onClose={handleClose}
        onOpen={handleOpen}
        variant="standard"
        renderValue={renderValues}
        multiple
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          getContentAnchorEl: null,
        }}
        disabled={hide}
      >
        {ChildrenRenderer}
      </Select> */}
        <Tooltip title={filter.name} placement="top" arrow>
          <button
            className="filter__button-label"
            disabled={hide}
            onClick={handleOpen}
            aria-expanded={Boolean(anchorEl)}
            aria-controls={filter.id}
            aria-label={filter.name}
          >
            {Boolean(anchorEl) ? <ArrowDropUp /> : <ArrowDropDownIcon />}
            {renderValues([filter])}
          </button>
        </Tooltip>
        <Tooltip title={"Clear "+filter.name +" filter"} placement="top" arrow>
        <button
          className="filter__button"
          name="Clear filter"
          aria-label="Clear filter"
          onClick={() => handleMakeFilterInactive(filter?.id)}
          disabled={hide}
        >
          <ClearIcon style={{ fontSize: 18, color: "#0C2938" }} />
        </button>
        </Tooltip>
      </div>
      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        id={filter.id}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Paper>
          <MenuList>{ChildrenRenderer}</MenuList>
        </Paper>
      </Popover>
    </>
  );
};

export default ActiveFilter;