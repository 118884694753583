import React from 'react'
import './icons.less'

const Icon = (props) => {
  let classes = [
    'icon',
    props.className,
  ].join(' ')
  return (
    <svg className={classes} role={props.role || 'figure'} aria-label={props.title} alt={props.alt} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g>
        <title>{props.title}</title>
        <circle cx="4" cy="4" r="3" fill="#000000" fillRule="evenodd"/>
      </g>
    </svg>
  )
}

export default Icon

