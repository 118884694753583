import React from "react";

import {Dialog} from "@mineral/core";
import {CircularProgress,DialogActions,DialogContent,DialogTitle,LinearProgress,Typography,Tooltip,IconButton} from "@mineral/core";

import CloseIcon from "@material-ui/icons/Close";
import { Button } from "@mineral/core";
import "./discoveryConfiguration.less";
import "./discoveryRunProgressDialog.less";
import { useDispatch, useSelector } from "react-redux";
import Resource from "./Resource";
import { makeStyles } from '@mui/styles'
import { setSelectedAgent } from "../../../api/DeviceDiscovery/agents/actions";
const useStyles = makeStyles((theme) => ({
  title: {
    display: "flex",
    justifyContent: "center",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const DiscoveryRunProgressDialog = (props) => {
  const classes = useStyles();

  const agentsStatus = useSelector((state) => state.agents.status);
  const selectedAgentInfo = useSelector((state) => state.agents.selectedAgent);

  const dispatch = useDispatch();

  //jobConfirmationHeader
  const jobConfirmationHeader = Resource.get(
    "View the discovered devices in the Inventory"
  );

  //Configure Another
  const configureAnotherMsg = Resource.get("Configure Another");

  //Close
  const closeMsg = Resource.get("Close");

  const getStatusState = (statusState) => {
    switch (statusState) {
      case "WAITING_FOR_CONFIGURATION":
        return Resource.get("Waiting for Configuration"); //Waiting for Configuration
      case "NO_SCHEDULE":
        return Resource.get("Discovery Job Run Successfully"); //Discovery Job Run Successfully
      case "EXPIRED_SCHEDULE":
        return Resource.get("Schedule Expired, Reconfigure"); //Schedule Expired Reconfigure
      case "SCHEDULED":
        return Resource.get("Job Scheduled"); //Job Scheduled
      case "RUNNING":
        return Resource.get("Running Discovery"); //Running Discovery
      case "UNRESPONSIVE":
        return Resource.get("Unable to Contact Agent"); //Unable to Contact Agent
      case "PROCESSING":
        return Resource.get("Processing Results"); //Processsing Results
      case "UNKNOWN":
        return Resource.get("Status Unknown"); //Status Unknown
      case "LATENT":
        return Resource.get("Awaiting Agent Status"); //Awaiting Agent Status
      default:
        return statusState.split("_").join(" ");
    }
  };

  const getDiscoveringInfo = () => {
    let selectedAgentId;
    // console.log(props.isProgressNeeded)
    // console.log(props.initialJobFetchInProgress)

    if (selectedAgentInfo) {
      selectedAgentId = selectedAgentInfo.id;
    }

    let msg = "";
    let percent = 0;
    for (let i = 0; i < agentsStatus.length; i++) {
      msg = agentsStatus[i].state;
      if (agentsStatus[i].id === selectedAgentId) {
        percent = agentsStatus[i].currentChecked;
        break;
      }
    }

    return (
      <div>
        <span className="text" style={{ paddingRight: "16px" }}>
          {getStatusState(msg)}
        </span>
        {msg.toLowerCase() === "running" ? (
          <div style={{ display: "inline-block" }}>
            <div
              style={{
                width: "100px",
                display: "inline-block",
                paddingRight: "8px",
              }}
            >
              <LinearProgress
                style={{ height: "10px" }}
               
                mode="determinate"
                value={percent}
              />
            </div>
            <span className="text" style={{ fontStyle: "normal" }}>
              {percent + "%"}
            </span>
          </div>
        ) : null}
      </div>
    );
  };

  const selectedAgent = (agentInfo) => {
    dispatch(setSelectedAgent(agentInfo));
  };

  return (
    <Dialog open={props.open}>
      <DialogTitle disableTypography>
        <Typography component="h1" variant="h4">
          Discovery Run Status
        </Typography>
      </DialogTitle>
      <div style={{height:'0px'}}>
      <Tooltip title="Close">
      <IconButton style={{float:'right',top:'-50px',marginRight:'8px'}}
          aria-label="close" autoFocus
          onClick={props.onClose}
        >
          <CloseIcon />
        </IconButton>
        </Tooltip>
        </div>
    

      <DialogContent className={classes.content}>
       
          <div
            className="msg"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {props.isProgressNeeded ? (
              getDiscoveringInfo()
            ) : (
              <span className="text">{props.statusMsg}</span>
            )}
            {/* {getDiscoveringInfo()} */}
            <span
              style={{ color: "#747474", fontSize: "14px", paddingTop: "12px" }}
            >
              {jobConfirmationHeader}
            </span>
          </div>
          </DialogContent>
          <DialogActions>

          <Button autoFocus={true}
            tabIndex={0}
            variant="outlined"
            style={{ margin: "10px 0" }}
            onClick={() => {
              selectedAgent(null);
              props.onClose();
              // this.props.showAgentScreen();
            }}
          >
            {configureAnotherMsg}
          </Button>

          <Button tabIndex={0} variant="text" onClick={props.onClose}>
            {closeMsg}
          </Button>
          </DialogActions>
        
      
    </Dialog>
  );
};

export default DiscoveryRunProgressDialog;
