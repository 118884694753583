import React, { Component } from "react";
import PropTypes from "prop-types";
import "./settings.less";
import connect from "./../../utils/connect";
import SettingsContainer from "./SettingsContainer";
import DeletedDevicesCard from "./infrastructure/DeletedDevicesCard";
import AdminConsoleCard from "./infrastructure/AdminConsoleCard";
import PersonalizationCard from "./infrastructure/PersonalizationCard";
import AdminConsoleCardOnPrem from "./infrastructure/AdminConsoleCardOnPrem";
import PagerDutyCard from "./integrations/pagerduty/PagerDutyCard";
import SlackCard from "./integrations/slack/SlackCard";
import AlarmPoliciesCard from "./notifications/AlarmPoliciesCard";
import { setBreadCrumbs } from "./../breadcrumbs/actions";
import { Snackbar } from "@mineral/core";
import { setRestoredSnackVisible } from "../../api/settings/actions";
import CloseRuleCard from "./notifications/closeRule/CloseRuleCard";
import ServiceNowCard from "./integrations/servicenow/ServiceNowCard";
import RESTmonCard from "./integrations/restmon/restmonCard";
import Resource from "./Resource";
import DashboardCard from "./portlets/dashboard/DashboardCard";
import SelfCertCard from "./portlets/selfCertification/SelfCertCard";
import AccAdminCard from "./portlets/accountAdmin/AccAdminCard";
import AdministrationCard from "./notifications/AdministrationCard";
import EcometerAdminCard from "./portlets/ecometer/EcometerAdminCard";
import ReportSchedulerCard from "./portlets/reportscheduler/ReportSchedulerCard";
import PerformanceReportsCard from "./portlets/performanceReports/PerformanceReportsCard";
import ListDesignerReportsCard from "./portlets/listDesigner/ListDesignerReportsCard";
import ListDashboardDesignerCard from "./portlets/ListDashboardDesigner/ListDashboardDesignerCard";
import ProductUsageMetricsCard from "./infrastructure/ProductUsageMetricsCard";

class Settings extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
  };

  componentWillMount = () => {
    this.props.setBreadCrumbs({
      //Settings
      name: Resource.get("Settings"),
      link: "/settings",
    });
  };

  componentDidUpdate = () => {
    this.props.setBreadCrumbs({
      //Settings
      name: Resource.get("Settings"),
      link: "/settings",
    });
  };

  onDismissRestoredSnack = () => {
    this.props.setRestoredSnackVisible(false);
  };

  render() {
    const infrastructureCards = [],
    integrationCards = [],
    systemAdministrationCards = [],
    alarmsAndNotificationsCards = [],
    dashboardCards = [];
    let isFirstCard=true;
    if (
      this.props.featureFlags &&
      this.props.featureFlags["alarmPolicies"] !== false &&
      (this.props.acl.canManagePolicy || this.props.acl.canViewPolicy)
      ) {
        alarmsAndNotificationsCards.push(
          <AlarmPoliciesCard  id={isFirstCard===true?'first-content-element':null} autoFocusAttr={isFirstCard===true?true:false} key="alarmPoliciesCard" />
          );
          isFirstCard=false;
        }
        if (this.props.saas) {
          if (this.props.featureFlags && this.props.featureFlags["closeRule"]) {
            alarmsAndNotificationsCards.push(<CloseRuleCard  id={isFirstCard===true?'first-content-element':null} autoFocusAttr={isFirstCard===true?true:false} key="CloseRuleCard" />);
            isFirstCard=false;
          }
        }
    if (this.props.acl.canDesignDashboard) {
      // TODO: Need to add a condition
      dashboardCards.push(<DashboardCard id={isFirstCard===true?'first-content-element':null} autoFocusAttr={isFirstCard===true?true:false} key="DashboardCard" />);
      isFirstCard=false;
      
    }
    if(this.props.acl.canScheduleReports)
    {
      dashboardCards.push(<ReportSchedulerCard  id={isFirstCard===true?'first-content-element':null} autoFocusAttr={isFirstCard===true?true:false} key="reportSchedulerCard" />);
      isFirstCard=false;
    }
    if(this.props.acl.canPerformanceReports)
    {
      dashboardCards.push(<PerformanceReportsCard  id={isFirstCard===true?'first-content-element':null} autoFocusAttr={isFirstCard===true?true:false} key="performanceReportsCard" />);
      isFirstCard=false;
    }
    if (this.props.acl.canListDesigner) {
      dashboardCards.push(<ListDesignerReportsCard  id={isFirstCard===true?'first-content-element':null} autoFocusAttr={isFirstCard===true?true:false}  key="listDesignerReportsCard" />);
    }
    if (this.props.acl.canListViewer) {
      dashboardCards.push(<ListDashboardDesignerCard  id={isFirstCard===true?'first-content-element':null} autoFocusAttr={isFirstCard===true?true:false}  key="listDashboardDesignerCard" />);
    }

    if (this.props.saas) {
      integrationCards.push(<PagerDutyCard  id={isFirstCard===true?'first-content-element':null} autoFocusAttr={isFirstCard===true?true:false} key="pagerDutyCard" />);
      isFirstCard=false;
      if (this.props.featureFlags && this.props.featureFlags["serviceNow"]) {
        integrationCards.push(<ServiceNowCard  id={isFirstCard===true?'first-content-element':null} autoFocusAttr={isFirstCard===true?true:false} key="serviceNowCard" />);
        isFirstCard=false;
      }
      integrationCards.push(<SlackCard  id={isFirstCard===true?'first-content-element':null} autoFocusAttr={isFirstCard===true?true:false} key="slackCard" />);
      isFirstCard=false;
    }
    if (
      this.props.featureFlags &&
      this.props.featureFlags["restmon"] !== false &&  this.props.acl.isPortalAdministrator
      ) {
        integrationCards.push(<RESTmonCard  id={isFirstCard===true?'first-content-element':null} autoFocusAttr={isFirstCard===true?true:false} key="restmonCard" />);
        isFirstCard=false;
      }
      if (this.props.acl.selfcert && this.props.acl.isPortalAdministrator) {
        // TODO: Need to add a condition
        integrationCards.push(<SelfCertCard  id={isFirstCard===true?'first-content-element':null} autoFocusAttr={isFirstCard===true?true:false} key="SelfCertCard" />);
        isFirstCard=false;
      }
      if (this.props.wasp.ecometerAdmin) {
        integrationCards.push(<EcometerAdminCard  id={isFirstCard===true?'first-content-element':null} autoFocusAttr={isFirstCard===true?true:false} key="EcometerAdminCard" />);
        isFirstCard=false;
      }
      
      

    if (this.props.saas) {
      infrastructureCards.push(<DeletedDevicesCard  id={isFirstCard===true?'first-content-element':null} autoFocusAttr={isFirstCard===true?true:false} key="deletedDevicesCard" />);
      isFirstCard=false;
    }
  
    if (this.props.saas) {
    
      systemAdministrationCards.push(
        <AdminConsoleCard  id={isFirstCard===true?'first-content-element':null} autoFocusAttr={isFirstCard===true?true:false} key="adminConsoleCard" />
        );
        isFirstCard=false;
      }
     
        
        if (this.props.acl?this.props.acl.canAdministerAccount:false) {
          // TODO: Need to add a condition
          systemAdministrationCards.push(<AccAdminCard  id={isFirstCard===true?'first-content-element':null} autoFocusAttr={isFirstCard===true?true:false} key="AccAdminCard" />);
          isFirstCard=false;
        }
        
       
			//Allow adminconsole url for account users also
            if (!this.props.saas){
              systemAdministrationCards.push(
                <AdminConsoleCardOnPrem  id={isFirstCard===true?'first-content-element':null} autoFocusAttr={isFirstCard===true?true:false} key="adminConsoleCard" />
                );
                isFirstCard=false;
              }
              
             
              if (true) {
                // TODO: Need to add a condition
                // alarmsAndNotificationsCards.push(<SLM_Card key="SLM_Card" />); //Removed for demo, needs to be added back
              }
              
            
              if (this.props.acl.isPortalAdministrator) {
                // TODO: Need to add a condition
                systemAdministrationCards.push(
                  <AdministrationCard  id={isFirstCard===true?'first-content-element':null} autoFocusAttr={isFirstCard===true?true:false} key="Administration_Card" />
                  
                  );
                  isFirstCard=false;
                }
                if(this.props.featureFlags && this.props.featureFlags["enableUserPersonalization"])
                {
                  systemAdministrationCards.push(        
                    <PersonalizationCard  id={isFirstCard===true?'first-content-element':null} autoFocusAttr={isFirstCard===true?true:false} key="personalizationCard" />
                    );
                    isFirstCard=false;
                  }

                // Product Usage Metrics
                if (this.props.acl && this.props.acl.isBusUser && this.props.acl.canProductUsageData && (this.props.featureFlags && this.props.featureFlags["enableProductUsage"])) {
                  systemAdministrationCards.push(<ProductUsageMetricsCard  id={isFirstCard===true?'first-content-element':null} autoFocusAttr={isFirstCard===true?true:false} key="ProductUsageMetricsCard" />);
                }


   

    
    return (
      <div>
        <div className="settings">
          <div className="settings-column">
            {alarmsAndNotificationsCards.length > 0 && (
              //Alarms and Notifications
              <SettingsContainer
                label={Resource.get("Alarms and Notifications")}
              >
                {alarmsAndNotificationsCards}
              </SettingsContainer>
            )}
            {dashboardCards.length > 0 && (this.props.acl.canDesignDashboard || this.props.acl.canScheduleReports || this.props.acl.canPerformanceReports) && (
              //Dashboard and Reports
              <SettingsContainer label={Resource.get("Dashboard and Reports")}>
                {dashboardCards}
              </SettingsContainer>
            )}
            {integrationCards.length > 0 && (
              //Integrations
              <SettingsContainer label={Resource.get("Integrations")}>
                {integrationCards}
              </SettingsContainer>
            )}
            <div className="settings-row">
              {infrastructureCards.length > 0 && (
                //Infrastructure
                <SettingsContainer label={Resource.get("Infrastructure")}>
                  {infrastructureCards}
                </SettingsContainer>
              )}
              {systemAdministrationCards.length > 0 && (
                //System Administration
                <SettingsContainer
                  label={Resource.get("System and Administration")}
                >
                  {systemAdministrationCards}
                </SettingsContainer>
              )}
            </div>
          </div>
        </div>
        <Snackbar
          message="The selected devices have been restored. It may take up to a day for devices to show up in their original groups and begin to show metrics and generate alarms."
          action={
            <span
              onClick={this.onDismissRestoredSnack}
              style={{
                color: "#00AEEF",
                lineHeight: "16px",
                height: "16px",
                cursor: "pointer",
              }}
            >
              DISMISS
            </span>
          }
          open={this.props.restoredSnackVisible}
          style={{ width: "688px", height: "88px", backgroundColor: "#333133" }}
          bodyStyle={{
            width: "688px",
            height: "88px",
            backgroundColor: "#333133",
          }}
          contentStyle={{
            width: "600px",
            height: "40px",
            paddingTop: "24px",
            lineHeight: "20px",
            backgroundColor: "#333133",
            display: "inline-flex",
          }}
          onRequestClose={this.onDismissRestoredSnack}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    featureFlags: state.configuration.items,
    restoredSnackVisible: state.settings.restoredSnackVisible,
    saas: state.saas,
    acl: state.acl,
    wasp: state.wasp,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setBreadCrumbs: (items) => dispatch(setBreadCrumbs(items)),
    setRestoredSnackVisible: (visible) =>
      dispatch(setRestoredSnackVisible(visible)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
