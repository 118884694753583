import React, {Component} from 'react'
import {SortIcon} from '../../../ui-components/uim-components'
import { MenuItem,  Select as SelectField,IconButton} from '@mineral/core'
import {GROUP_SORT_TYPES} from './../../../../api/groups/actionTypes'
import './sort.less'
import Resource from './Resource'
import AccessibleTooltip from '../../../common/AccessibleTooltip'

class Sort extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: this.props.sort,
      order: 'desc',
    }
  }
  handleChange = (event) => {
    let value = event.target.value;
    let defaultSortOrder = 'desc'
    if(value === 'name') {
      defaultSortOrder = 'asc'
    }
    if (this.props.onChange) {
      this.props.onChange(value, defaultSortOrder)
    }
    return this.setState({value: value, order: defaultSortOrder});
  }
  handleSortOrderClick = () => {
    let order = 'asc'
    if(this.state.order === 'asc') {
     order = 'desc'
    }
    if (this.props.onChange) {
      this.props.onChange(this.state.value, order)
    }
    return this.setState({order: order})
  }
  render() {
    let sortIconClassNames = 'sort__icon'
    if(this.state.order === 'asc') {
      sortIconClassNames += ' sort__icon--reverse'
    }
    return (
      <div className='sort' style={{marginLeft:'8px',marginRight:'8px'}}>
      <AccessibleTooltip title="Sort">
        <IconButton aria-label="Sort" onClick={this.handleSortOrderClick} style={{marginRight:'8px'}}>
          <SortIcon className={sortIconClassNames}/>
        </IconButton>
        </AccessibleTooltip>
        <SelectField
          value={this.state.value}
          onChange={this.handleChange}>
        
          <MenuItem value={GROUP_SORT_TYPES.NAME} 
          //Name
          children= {Resource.get('Name')} />
          
          <MenuItem value={GROUP_SORT_TYPES.SEVERITY} 
          //Severity 
          children= {Resource.get('Severity')} />
          
          <MenuItem value={GROUP_SORT_TYPES.ALARM_COUNT} 
          //Alarm Count 
          children= {Resource.get('Alarm Count')} />
         
          <MenuItem value={GROUP_SORT_TYPES.MEMBER_COUNT}  
          //Member Count
          children= {Resource.get('Member Count')} />
        </SelectField>
      </div>
    )
  }
}

export default Sort