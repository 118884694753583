import connect from "./../../utils/connect";
import Alarms from "./Alarms";
import { addBreadCrumb } from "./../breadcrumbs/actions";

const mapStateToProps = (state) => {
  return {
    sid: state.sid,
    accountInfo: state.account.item,
    acl: state.acl,
    selectedCsIds: state.selectedCsIds,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addBreadCrumb: (crumb) => dispatch(addBreadCrumb(crumb)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Alarms);
