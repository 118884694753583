// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Highest Alarm Severity Level and Total Alarm Count
  content["Highest Alarm Severity Level"] =
  "Highest Alarm Severity Level";

  // tooltipField: "maxAlarmSeverity.label",
  content["Max Alarm Severity"] =
  "Max Alarm Severity";

  //Name
  content["Name"] =
  "Name";

  //Elements
  content["Count"] =
  "Count";

  //Elements
  content["LastUpdate"] =
  "LastUpdate";

  //Device Type
  content["Device Type"] =
  "Device Type";

  //Operating system
  content["Operating system"] =
  "Operating system";

  //IP address
  content["IP address"] =
  "IP address";

  //Monitored by
  content["Monitored by"] =
  "Monitored by";

  //Has Robot
  content["Has Robot"] =
  "Has Robot";

  //Discovered
  content["Discovered"] =
  "Discovered";

  //No Data Found
  content["No Data Found"] =
  "No Data Found";

  //Try different filter options
  content["Try different filter options"] =
  "Try different filter options";

  // No results found
  content["No results found"] =
  "No results found";

  //CRITICAL
  content["CRITICAL"] =
  "CRITICAL";

  //MAJOR
  content["MAJOR"] =
  "MAJOR";

  //MINOR
  content["MINOR"] =
  "MINOR";

  //WARNING
  content["WARNING"] =
  "WARNING";

  // INFO
  content["INFO"] =
  "INFO";

  // NONE
  content["NONE"] =
  "NONE";

  // console.log("from render",item_value);
  // return items
  //creating the icon //
  content["Groups(#)"] =
  "Groups(#)";

  // No devices have been added
  content["No devices have been added"] =
  "No devices have been added";

  //No subgroups have been added
  content["No subgroups have been added"] =
  "No subgroups have been added";

  //No profiles in  this group
  content["No profiles in this group"] =
  "No profiles in this group";

  //No devices found
  content["No devices found"] =
  "No devices found";

  //No devices have been added yet.
  content["No devices have been added yet."] =
  "No devices have been added yet.";

  // END OF LOCALIZATION

export default content;
