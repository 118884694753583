export const FETCH_METRICS = "FETCH_METRICS";
export const FETCH_METRICS_SUCCESS = "FETCH_METRICS_SUCCESS";
export const FETCH_METRICS_FAILURE = "FETCH_METRICS_FAILURE";
export const FETCH_METRICS_DEVICES = "FETCH_METRICS_DEVICES";
export const FETCH_METRICS_DEVICES_SUCCESS = "FETCH_METRICS_DEVICES_SUCCESS";
export const FETCH_METRICS_DEVICES_FAILURE = "FETCH_METRICS_DEVICESS_FAILURE";
export const CLEAR_CHART_DATA = "CLEAR_CHART_DATA";
export const SAVE_NAMED_METRICS = "SAVE_NAMED_METRICS";
export const SAVE_NAMED_METRICS_SUCCESS = "SAVE_NAMED_METRICS_SUCCESS";
export const SAVE_NAMED_METRICS_FAILURE = "SAVE_NAMED_METRICS_FAILURE";
export const FETCH_NAMED_METRICS = "FETCH_NAMED_METRICS";
export const FETCH_NAMED_METRICS_SUCCESS = "FETCH_NAMED_METRICS_SUCCESS";
export const FETCH_NAMED_METRICS_FAILURE = "FETCH_NAMED_METRICS_FAILURE";
export const FETCH_NAMED_METRICS_BY_ID = "FETCH_NAMED_METRICS_BY_ID";
export const DELETE_NAMED_VIEWS = "DELETE_NAMED_VIEWS";
export const DELETE_NAMED_VIEWS_SUCCESS = "DELETE_NAMED_VIEWS_SUCCESS";
export const DELETE_NAMED_VIEWS_FAILURE = "DELETE_NAMED_VIEWS_FAILURE";
export const FETCH_NAMED_METRICS_BY_ID_SUCCESS =
  "FETCH_NAMED_METRICS_BY_ID_SUCCESS";
export const FETCH_NAMED_METRICS_BY_ID_FAILURE =
  "FETCH_NAMED_METRICS_BY_ID_FAILURE";
export const GET_DEVICE = "GET_DEVICE";
export const GET_DEVICE_SUCCESS = "GET_DEVICE_SUCCESS";
export const GET_DEVICE_FAILURE = "GET_DEVICE_FAILURE";
export const EXPORT_METRICS = "EXPORT_METRICS";
export const EXPORT_METRICS_SUCCESS = "EXPORT_METRICS_SUCCESS";
export const EXPORT_METRICS_FAILURE = "EXPORT_METRICS_FAILURE";
export const COPY_METRICS = "COPY_METRICS";
export const COPY_METRICS_SUCCESS = "COPY_METRICS_SUCCESS";
export const COPY_METRICS_FAILURE = "COPY_METRICS_FAILURE";

export const COPY_DEVICE_METRICVIEW = "COPY_DEVICE_METRICVIEW";
export const COPY_DEVICE_METRICVIEW_SUCCESS = "COPY_DEVICE_METRICVIEW_SUCCESS";
export const COPY_DEVICE_METRICVIEW_FAILURE = "COPY_DEVICE_METRICVIEW_FAILURE";
