import React, { Component} from "react";
import PropTypes from 'prop-types'
import { withRouter } from "react-router-dom";
import { Dialog,DialogTitle, Button as FlatButton, TextField, DialogActions } from "@mineral/core";
import Groups from "../../api/groups/groups";
import Resource from "./Resource";
import connect from "../../utils/connect";
import "./dialogButtonStyle.less";
import { getGroups } from "./../../api/treeView/actions";

class RenameSubgroupDialog extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
  };
  constructor(props) {
    super(props);
    this.state = {
      id: props.selectedId,
      name: props.groupName,
      newName: props.groupName,
      parentId: "",
      criteria: "",
      updateError: null,
      disableButton: false,
    };
    this.siblings = [];
    this.entities = props.entities;
  }
  componentWillReceiveProps(nextProps) {
    if (!this.props.open && nextProps.open && nextProps.parentId) {
      this.siblings = [];
      Groups.get({ id: nextProps.parentId })
        .then((results) => {
          const data = results.data.groups;
          this.siblings = data.map((sibling) => {
            return sibling.id.toString() === nextProps.selectedId
              ? null
              : sibling.name;
          });
        })
        .catch((error) => {
          console.error("could not get siblings", error);
        });
    }
    this.setState({
      id: nextProps.selectedId,
      name: nextProps.groupName,
      parentId: nextProps.parentId,
      criteria: nextProps.criteria,
    });
    if (nextProps.groupName != this.state.name) {
      this.setState({
        newName: nextProps.groupName,
      });
    }
  }
  handleNameChange = (event) => {
    const newName = event.target.value;
    const siblings = this.siblings;
    if (newName > 255) {
      this.setState({
        //Namemustbelessthan256 characters message
        nameError: Resource.get("Name must be less than 256 characters"),
        newName: newName,
        disableButton: true,
      });
    } else if (siblings.includes(newName)) {
      this.setState({
        //Groupnamelareadyexists message
        nameError: Resource.get("Group name already exists"),
        newName: newName,
        disableButton: true,
      });
    } else {
      this.setState({
        nameError: null,
        newName: newName,
        disableButton: false,
      });
    }
  };
  render() {
    const genericError = this.state.updateError;
    const actions = [
      <FlatButton
      variant = "text"
        //Cancel
        children={Resource.get("Cancel")}
        color = "primary"
        onClick={this.onClose}
      />,
      <FlatButton
      variant = "text"
        //Save
        children={Resource.get("Save")}
        color = "primary"
        onClick={this.handleSave}
        disabled={this.state.disableButton}
      />,
    ];
    return (
      <Dialog
        style={{ width: "300px", height: "321px",paddingBottom: "0px", paddingTop: "0px" }}        
        //Rename group Message
        //title={Resource.get("Rename group")}        
        modal={false}
        open={this.props.open}
        aria-labelledby="dialog-title"
        className= "dialogConatiner"        
        >
         <DialogTitle id="dialog-title"> {"Rename group"}</DialogTitle>
        <TextField
          //Group Name
          placeholder={Resource.get("Group Name")}
          //Group name
          label={Resource.get("Group name")}
          sx={{
   input: {
    
    "&::placeholder": {  // <----- Add this.
      color: 'black !important',
      opacity:0.8
     
    },
   }}}
          onChange={this.handleNameChange}
          value={this.state.newName}
          autoFocus={true}
          helperText={this.state.nameError}
        />
        <div
          style={{
            color: "rgb(244, 67, 54)",
            fontSize: "12px",
          }}
        >
          {genericError}
        </div>
        <DialogActions>
          {actions}
        </DialogActions>
      </Dialog>
    );
  }
  handleSave = () => {
    this.setState({
      disableButton: true,
      name: this.state.newName,
    });
    this.props
      .renameGroup({ ...this.state, name: this.state.newName })
      .then((response) => {
        if (this.props.location.pathname.search("treeviewnew") > -1) {
          this.props.getGroup(response.json.id);
        } else {
          this.props.fetchGroups();
          const history = this.props.history;
          this.props.history.replace(history.location.pathname);
        }
        this.props.renameEntity(this.state.newName);
        this.props.handleClose("renameSubGroupOpen");
        this.setState({ updateError: null });
        sessionStorage.setItem("renamedGroup", true);
      })
      .catch((error) => {
        if (error.response.status === 409) {
          this.setState({
            //Groupnamelareadyexists message
            nameError: Resource.get("Group name already exists"),
          });
        } else {
          this.props.handleClose("renameSubGroupOpen");
          this.props.handleErrorDialogOpen("rename");
        }
      });
    this.props.refreshTree();
  };
  onClose = () => {
    this.setState({
      updateError: null,
      nameError: null,
      newName: this.state.name,
    });
    this.props.handleClose("renameSubGroupOpen");
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchGroups: () => dispatch(getGroups(0)),
  };
};

const mapStateToProps = (state) => {};

// export default withRouter(RenameSubgroupDialog);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(RenameSubgroupDialog));
