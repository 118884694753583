import React, {Component} from 'react'
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  Legend
} from 'recharts'
import './chart.less'
import Resource from './Resource'

const renderLegend = (props) => {
  return (
    <div style={{
      color: '#3D8DA0',
      fontSize: '10px',
      textAlign: 'center',
      marginLeft: '40px'
    }}>{//ALARM HISTORY LAST 30 DAYS
    	Resource.get('ALARM HISTORY LAST 30 DAYS')}</div>
    
  )
}

class StackedAreaChart extends Component {
  render() {
    var hide = false
    return (
      <AreaChart width={275} height={155} data={this.props.data} margin={{top: 10, right: 0, left: -40, bottom: -10}}>
        <XAxis  dataKey='day' hide={hide}/>
        <YAxis />
        <Tooltip/>
        <Legend content={renderLegend}/>
        <Area type={this.props.areaType} fillOpacity={this.props.fillOpacity} dataKey='Information' stackId='1' fill={this.props.chartColors[4]} dot={false} />
        <Area type={this.props.areaType} fillOpacity={this.props.fillOpacity} dataKey='Warning' stackId='1' fill={this.props.chartColors[3]} dot={false} />
        <Area type={this.props.areaType} fillOpacity={this.props.fillOpacity} dataKey='Minor' stackId='1' fill={this.props.chartColors[2]} dot={false} />
        <Area type={this.props.areaType} fillOpacity={this.props.fillOpacity} dataKey='Major' stackId='1' fill={this.props.chartColors[1]} dot={false} />
        <Area type={this.props.areaType} fillOpacity={this.props.fillOpacity} dataKey='Critical' stackId='1' fill={this.props.chartColors[0]} dot={false} />
      </AreaChart>
    )
  }
}

export default StackedAreaChart;
