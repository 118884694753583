export const FETCH_MAINTENANCE = 'FETCH_MAINTENANCE'
export const FETCH_MAINTENANCE_SUCCESS = 'FETCH_MAINTENANCE_SUCCESS'
export const FETCH_MAINTENANCE_FAILURE = 'FETCH_MAINTENANCE_SUCCESS'
export const POLL_MAINTENANCE_START = 'POLL_MAINTENANCE_START'
export const POLL_MAINTENANCE_STOP = 'POLL_MAINTENANCE_STOP'
export const FETCH_SCHEDULE_DEVICES='FETCH_SCHEDULE_DEVICES'
export const FETCH_SCHEDULE_DEVICES_SUCCESS='FETCH_SCHEDULE_DEVICES_SUCCESS'
export const FETCH_SCHEDULE_DEVICES_FAILURE='FETCH_SCHEDULE_DEVICES_FAILURE'
export const FETCH_GROUP_DEVICES='FETCH_GROUP_DEVICES'
export const FETCH_GROUP_DEVICES_SUCCESS='FETCH_GROUP_DEVICES_SUCCESS'
export const FETCH_GROUP_DEVICES_FAILURE='FETCH_GROUP_DEVICES_FAILURE'
export const FETCH_TIMEZONES='FETCH_TIMEZONES'
export const FETCH_TIMEZONES_SUCCESS='FETCH_TIMEZONES_SUCCESS'
export const FETCH_TIMEZONES_FAILURE='FETCH_TIMEZONES_FAILURE'
export const FETCH_CS_MAINTENANCE_LIST = 'FETCH_CS_MAINTENANCE_LIST'
export const FETCH_CS_MAINTENANCE_LIST_SUCCESS = 'FETCH_CS_MAINTENANCE_LIST_SUCCESS'
export const FETCH_CS_MAINTENANCE_LIST_FAILURE = 'FETCH_CS_MAINTENANCE_LIST_FAILURE'
export const REMOVE_CS_MAINTENANCE_LIST = 'REMOVE_CS_MAINTENANCE_LIST'
export const REMOVE_CS_MAINTENANCE_LIST_SUCCESS = 'REMOVE_CS_MAINTENANCE_LIST_SUCCESS'
export const REMOVE_CS_MAINTENANCE_LIST_FAILURE = 'REMOVE_CS_MAINTENANCE_LIST_FAILURE'
