import React from 'react'
import Message from './Message'
import './centerMessage.less'

const CenterMessage = (props) => {
  let classes = [
    'center-message',
    props.className,
  ].join(' ')
  return (
    <div className={classes} style={props.style}>
      <Message {...props} />
    </div>
  )
}

export default CenterMessage
