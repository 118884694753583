import React, {Component} from 'react'
import './cardDot.less'

class CardDot extends Component {
  _handleClick = (e) => {
    e.stopPropagation();
    this.props.handleClick(this.props.id)
  }

  render() {
    let selected = this.props.selected
    return (
      <span className={`card-dot ${selected ? 'card-dot--selected' : ''}`} onClick={this._handleClick} role="button" title="Tab"></span>
    )
  }
}
export default CardDot
