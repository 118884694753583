import React from "react";
import { SvgIcon } from "../ui-components/uim-components";

const Icon = (props) => {
  let classes = ["icon", props.className].join(" ");
  return (
    <SvgIcon {...props} className={classes}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity={props.disabled?"0.4":"0.8"} d="M5 8V21H14V9.09091C14 8.51149 13.4622 8 12.75 8H5ZM16 9.09091V23H3V6H12.75C14.5449 6 16 7.38385 16 9.09091Z" fill="#333840"/>
        <path opacity={props.disabled?"0.4":"0.8"} fillRule="evenodd" clipRule="evenodd" d="M10 4H8V1H17.75C19.5449 1 21 2.38385 21 4.09091V18H18V16H19V4.09091C19 3.51149 18.4622 3 17.75 3H10V4Z" fill="#333840"/>
    </svg>

    </SvgIcon>
  );
};

export default Icon;
