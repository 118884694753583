export const SET_GROUP_SORT_PROP = 'SET_GROUP_SORT_PROP'
export const SET_GROUP_ID = 'SET_GROUP_ID'
export const SET_GROUP_IDS = 'SET_GROUP_IDS'
export const REQUEST_GROUP = 'REQUEST_GROUP'
export const REQUEST_GROUPS = 'REQUEST_GROUPS'
export const FETCH_GROUP = 'FETCH_GROUP'
export const FETCH_GROUPS = 'FETCH_GROUPS'
export const FETCH_GROUP_SUCCESS = 'FETCH_GROUP_SUCCESS'
export const FETCH_GROUPS_SUCCESS = 'FETCH_GROUPS_SUCCESS'
export const FETCH_GROUP_FAILURE = 'FETCH_GROUP_FAILURE'
export const FETCH_GROUPS_FAILURE = 'FETCH_GROUPS_FAILURE'
export const REQUEST_CABI_DETAILS = 'REQUEST_CABI_DETAILS'
export const FETCH_CABI_DETAILS_SUCCESS = 'FETCH_CABI_DETAILS_SUCCESS'
export const FETCH_CABI_DETAILS_FAILURE = 'FETCH_CABI_DETAILS_FAILURE'
export const ADD_GROUP = 'ADD_GROUP'
export const ADD_GROUP_SUCCESS = 'ADD_GROUP_SUCCESS'
export const ADD_GROUP_FAILURE = 'ADD_GROUP_FAILURE'
export const REQUEST_ADD_GROUP = 'REQUEST_ADD_GROUP'
export const DELETE_GROUP = 'DELETE_GROUP'
export const DELETE_GROUP_FAILURE = 'DELETE_GROUP_FAILURE'
export const DELETE_GROUP_SUCCESS = 'DELETE_GROUP_SUCCESS'
export const RENAME_GROUP = 'RENAME_GROUP'
export const RENAME_GROUP_SUCCESS = 'RENAME_GROUP_SUCCESS'
export const RENAME_GROUP_FAILURE = 'RENAME_GROUP_FAILURE'
export const REMOVE_DEVICES_FROM_GROUP = 'REMOVE_DEVICES_FROM_GROUP'
export const REMOVE_DEVICES_FAILURE = 'REMOVE_DEVICES_FAILURE'
export const REMOVE_DEVICES_SUCCESS = 'REMOVE_DEVICES_SUCCESS'
export const SET_SELECTED_CS_IDS = 'SET_SELECTED_CS_IDS'
export const SET_SELECTED_IF_IDS = 'SET_SELECTED_IF_IDS'
export const ADD_DEVICE_TO_GROUP = 'ADD_DEVICE_TO_GROUP'
export const ADD_TO_GROUP_FAILURE = 'ADD_TO_GROUP_FAILURE'
export const ADD_TO_GROUP_SUCCESS = 'ADD_TO_GROUP_SUCCESS'
export const POLL_GROUPS_START = 'POLL_GROUPS_START'
export const POLL_GROUPS_STOP = 'POLL_GROUPS_STOP'
export const REMOVE_INTERFACES_FROM_GROUP = 'REMOVE_INTERFACES_FROM_GROUP'
export const REMOVE_INTERFACES_FAILURE = 'REMOVE_INTERFACES_FAILURE'
export const REMOVE_INTERFACES_SUCCESS = 'REMOVE_INTERFACES_SUCCESS'
export const ADD_INTERFACES_TO_GROUP_FAILURE = 'ADD_INTERFACES_TO_GROUP_FAILURE'
export const ADD_INTERFACES_TO_GROUP_SUCCESS = 'ADD_INTERFACES_TO_GROUP_SUCCESS'
export const SWITCH_TREE_VIEW_NAV="SWITCH_TREE_VIEW_NAV"
export const DISABLE_TREE_VIEW_NAV="DISABLE_TREE_VIEW_NAV"
export const GROUP_SORT_TYPES = {
  SEVERITY: 'maxAlarmSeverity.value',
  ALARM_COUNT: 'totalAlarmCount',
  MEMBER_COUNT: 'memberCount',
  NAME: 'name',
}
export const GROUP_DASHBOARD_COMPONENTS_CHANGE = 'GROUP_DASHBOARD_COMPONENTS_CHANGE'

//TOP Sub Group
//Top Groups
export const REQUEST_TOP_SUB_GROUP = 'REQUEST_TOP_SUB_GROUP'
export const FETCH_TOP_SUB_GROUP = 'FETCH_TOP_SUB_GROUP'
export const FETCH_TOP_SUB_GROUP_SUCCESS = 'FETCH_TOP_SUB_GROUP_SUCCESS'
export const FETCH_TOP_SUB_GROUP_FAILURE = 'FETCH_TOP_SUB_GROUP_FAILURE'
export const POLL_TOP_SUB_GROUP_START = 'POLL_TOP_SUB_GROUP_START'
export const POLL_TOP_SUB_GROUP_STOP = 'POLL_TOP_SUB_GROUP_STOP'

export const FILTERED_GROUPTREE_ROWS = 'FILTERED_GROUPTREE_ROWS'

export const SAVEDCOLUMNS_LISTVIEW = 'SAVEDCOLUMNS_LISTVIEW'



