import connect from './../../utils/connect'
import Policy from './Policy'
import { setBreadCrumbs } from './../breadcrumbs/actions'

const mapStateToProps = state => {
  return {
    account: state.account,
    apiInfo: state.info.apiInfo,
    saas: state.saas,
    sid: state.sid,
    featureFlags: state.configuration.items,
    acl: state.acl
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setBreadCrumbs: items => dispatch(setBreadCrumbs(items)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Policy)
