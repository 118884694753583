import React from 'react'
import './icons.less'

const Icon = (props) => {
  let classes = [
    'icon',
    props.className,
  ].join(' '),
  height = props.height || 24,
  width = props.width || 24,
  fillVal = props.fill || '#007DAB',
  fillOpacity =  props.fillOpacity || 1;
  return (
    <svg className={classes} role={props.role || 'figure'} aria-label="[title]" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24"> 
        <path d="M17.2054443,20 L15.4366455,20 L14.7335205,18.1707764 L11.5145264,18.1707764 L10.8498535,20 L9.125,20 L12.2615967,11.9470215 L13.980957,11.9470215 L17.2054443,20 Z M2.25,15.8471225 L2.25,2 C2.25,1.58578644 2.58578644,1.25 3,1.25 C3.41421356,1.25 3.75,1.58578644 3.75,2 L3.75,15.8700372 L4.70651456,14.9115512 C4.99880502,14.6186583 5.47318988,14.6181699 5.76608278,14.9104603 C5.76626478,14.910642 5.76644667,14.9108237 5.76662848,14.9110055 C6.05912029,15.2034973 6.05912029,15.6777202 5.76662848,15.970212 C5.76638638,15.9704541 5.76614411,15.9706961 5.76590167,15.9709378 L4.06690779,17.6652781 L3.01128328,18.7199069 L0.220084535,15.9360923 C-0.0728081705,15.6439744 -0.0734363061,15.1697296 0.218681555,14.8768369 C0.218915075,14.8766028 0.21914875,14.8763688 0.21938258,14.876135 C0.51217528,14.5833423 0.986886041,14.5833423 1.27967874,14.876135 C1.2797394,14.8761956 1.27980005,14.8762563 1.27986068,14.876317 L2.25,15.8471225 Z M14.2116699,16.8139648 L12.0144043,16.8139648 L13.1020508,13.8256836 L14.2116699,16.8139648 Z M9.87084961,8.125 L9.87084961,6.6583252 L14.1005859,1.43432617 L10.3487549,1.43432617 L10.3487549,0.0720214844 L16.2429199,0.0720214844 L16.2429199,1.33544922 L11.8319092,6.76818848 L16.413208,6.76818848 L16.413208,8.125 L9.87084961,8.125 Z" id="path-1" style={{fill:fillVal,fillOpacity:fillOpacity}}></path> 
    </svg>
  )
}

export default Icon

