import {
  ADD_BREADCRUMB,
  SELECT_BREADCRUMB,
  SET_BREADCRUMBS,
  SET_HOME_BREADCRUMB,
  POP_LAST_BREADCRUMB,
} from './actionTypes'
import Resource from './Resource'

const home = [
  {
    //Home
    name: Resource.get('breadcrumb', 'Home'),
    link: '/overview',
  },
]

const breadcrumbs = (state = home, action) => {
  switch (action.type) {
    case ADD_BREADCRUMB:
      return state.concat(action.item)
    case SELECT_BREADCRUMB:
      let index = state.findIndex(crumb => {
        return crumb === action.item
      })
      // clip minumum value at 0
      index = Math.max(index, 0)
      return state.slice(0, index + 1)
    case SET_BREADCRUMBS:
      let crumbs = home.concat(action.items)
      if (!action.items) {
        crumbs = home
      }
      return crumbs
    case POP_LAST_BREADCRUMB:
      if (state.length === 1) {
        return state
      } else if (state.length === 2) {
        return home
      } else {
        return state.slice(0, -1)
      }
    case SET_HOME_BREADCRUMB:
      let item = Array.isArray(action.item) ? action.item : [action.item]
      // remove falsy array values
      item = item.filter(i => !!i)
      item = item.length > 0 ? item : home
      return item.concat(state.slice(1))
    default:
      return state
  }
}
export default breadcrumbs
