import { SET_ISSAAS } from './actionTypes'

const saas = (state = false, action) => {
  switch (action.type) {
    case SET_ISSAAS:
      return action.isSaas
    default:
      return state
  }
}
export default saas
