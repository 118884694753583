/**
@Author namam01
 **/
import React from 'react'

// Renders the "BusType" column
export default function BusTypeCellRenderer(props) {
    const item = props.data
    let value= item.attributes.nimbus_type[0]
    if(!value && value == ""){
        value='-';
    }else if(value == 1){
        value='Robot'
    }else if(value == 2){
        value="Hub"
    }else{
        value='-'
    }
    return (
        <span className="ap-cell">&nbsp;&nbsp;{value}</span>
    )
}
