import config from './../config'
import axios from 'axios'

const url = `${config.basename}/api/v1/userdashboard`
class UserDashboards {
  getAll() {
    if(process.env.NODE_ENV=='development'){
      return axios.get(
        "http://10.252.25.208:8080/castlemock/mock/rest/project/TK8t3o/application/UXxhjW/userdashboard/list"
        );
      }
      return axios.get(url+"/list");
    }
    createUserDashboard(data = []) {
      return axios.post(url, data)
    }
    
    updateUserDashboard(data = []) {
      return axios.put(url+"/"+data.id, data)
    }
    
    deleteUserDashboard(userDashboardId)
    {
      /*let params = new URLSearchParams();
      params.append("id", userDashboardId);
      
      let request = {
        params: params
      }*/
      return axios.delete(url+"/"+userDashboardId);
      
    }
    
    getUserDashboard(userDashboardId)
    {
      /*let params = new URLSearchParams();
      params.append("id", userDashboardId);
      
      let request = {
        params: params
      }*/
      return axios.get(url,"/"+userDashboardId);
      
    }
    
  }
  
  let userdashboards = new UserDashboards()
  export default userdashboards
  