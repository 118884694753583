import React, { Component } from "react";
import DevicesByRole from "./panels/DevicesByRole";
import TotalAlarms from "./panels/TotalAlarms";
import TopSubGroupsByDevice from "./panels/TopSubGroupsByDevice";
import TotalAlarmsByDevice from "./panels/TotalAlarmsByDevice";
import MonitoredTechnologies from "./panels/MonitoredTechnologies";
import "./dashboard-container.less";
import "./dashborad-container-responsive.less";
import {Tooltip,IconButton} from '@mineral/core';
import isEqual from "lodash/isEqual";
import NoViewPanel from "../overview/NoViewPanel";
import Resource from "../inventory/Resource";
import EntityTypes from "./../../api/entityTypes";
import { ClearIcon as Clear } from "../ui-components/uim-components";
import get from "lodash/get";


class GroupDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showOpenAlarms: true,
      showDevicesByRoles: true,
      showTopEntitiesContainer: true,
      showTopTechnologies: true,
      showTopGroups: true,
      showTopDevices: true,
    };
  }

  componentWillMount() {
    //  this.props.setBreadCrumbs();
  }

  componentDidMount() {
    if (!isEqual(this.props.groupDashboardComponents, this.state)) {
      this.setState({ ...this.props.groupDashboardComponents });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!isEqual(nextProps.groupDashboardComponents, this.state)) {
      this.setState({ ...nextProps.groupDashboardComponents });
    }
  }

  componentWillUpdate(prevState, currState) {
    if (!isEqual(this.state, currState)) {
      this.props.groupDashboardComponentsChanged(currState);
    }
  }

  render() {
    let entityType = get(this.props.selectedEntity, "type");
    let anyMenuSelected =
      this.state.showOpenAlarms ||
      this.state.showDevicesByRoles ||
      (entityType != EntityTypes.CONTAINER
        ? this.state.showTopTechnologies
        : false) ||
      (entityType == EntityTypes.CONTAINER
        ? this.state.showTopGroups
        : false) ||
      this.state.showTopDevices;
    const handleCloseDialog = (propertyName) => {
      if (propertyName == "showTopEntitiesContainer") {
        this.setState({
          showTopEntitiesContainer: false,
          showTopTechnologies: false,
          showTopGroups: false,
          showTopDevices: false,
        });
      } else {
        this.setState({
          [propertyName]: false,
        });
      }
    };
    //Top 10 Entities
    let title = Resource.get("Top 10 Entities");
    //By Severity, By Alarms
    let sevTitle = Resource.get("By Severity, By Alarms");
    //Close
    let CLOSE=Resource.get("Close");
    let cardHeader = (
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h2 className="overview_container_title">{title}</h2>
          <span
            style={{
              fontSize: "14px",
              alignSelf: "center",
              marginLeft: "10px",
              color: "#58606E",
            }}
          >
            {sevTitle}
          </span>
        </div>
<Tooltip title="Close">
        <IconButton
          onClick={() => {
            handleCloseDialog("showTopEntitiesContainer");
          }}
          style={{ display: "flex", alignItems: "center" }}
          
          aria-label={CLOSE}
        >
          <Clear className="clearIcon"  role="figure"
            title={CLOSE}/>
        </IconButton>
        </Tooltip>
      </div>
    );
    return (
      <div
        className="dashboard dashboard__main"
        style={{ marginLeft: "0px", backgroundColor: "transparent" ,height:'100%'}}
      >
        {anyMenuSelected ? (
          <section className="dashboard__cards" style={{height:'100%'}}>
            {this.state.showOpenAlarms || this.state.showDevicesByRoles ? (
              <div
                className="dashboard_row_container"
                style={{ display: "flex", gridGap: "16px", flex: "1" }}
              >
                {this.state.showOpenAlarms ? (
                  <div className="dashboard__alarms--total dashboard__row-item ">
                    {" "}
                    <TotalAlarms
                      handleCloseDialog={handleCloseDialog}
                      groupIds={this.props.groupId}
                      csId={this.props.csId}
                      probeId={this.props.probeId}
                      selectedEntity={this.props.selectedEntity}
                    />
                  </div>
                ) : null}
                {this.state.showDevicesByRoles ? (
                  <div className="dashboard_container2 dashboard__row-item">
                    {this.state.showDevicesByRoles ? (
                      <DevicesByRole showDevicesByRoles={this.state.showDevicesByRoles}
                        handleCloseDialog={handleCloseDialog}
                        groupIds={this.props.groupId}
                        probeId={this.props.probeId}
                        className="dashboard__devices--by-role "
                      />
                    ) : null}
                  </div>
                ) : null}
              </div>
            ) : null}
            {(this.state.showTopTechnologies ||
              this.state.showTopGroups ||
              this.state.showTopDevices) &&
            this.state.showTopEntitiesContainer ? (
              <div className="dashboard_container3 dashboard_row_container">
                {cardHeader}
                <div
                  style={{
                    display: "flex",
                    gridGap: "16px",
                    background: "white",
                  }}
                >
                  {this.state.showTopTechnologies &&
                  entityType != EntityTypes.CONTAINER ? (
                    <MonitoredTechnologies
                      handleCloseDialog={handleCloseDialog}
                      className="dashboard__devices"
                      groupIds={this.props.groupId}
                      csId={this.props.csId}
                    />
                  ) : null}
                  {this.state.showTopGroups &&
                  entityType == EntityTypes.CONTAINER ? (
                    <TopSubGroupsByDevice
                      handleCloseDialog={handleCloseDialog}
                      className="dashboard__alarms--by-group "
                      groupIds={this.props.groupId}
                    />
                  ) : null}
                  {this.state.showTopDevices ? (
                    <TotalAlarmsByDevice
                      handleCloseDialog={handleCloseDialog}
                      className="dashboard__alarms--by-device "
                      groupIds={this.props.groupId}
                      probeId={this.props.probeId}
                    />
                  ) : null}
                </div>
              </div>
            ) : null}
          </section>
        ) : (
          <NoViewPanel />
        )}
      </div>
    );
  }
}

export default GroupDashboard;
