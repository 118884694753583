import React, { Component } from 'react'
import {FilterIcon } from '../../ui-components/uim-components'
import {TextField} from "@mineral/core"
import './filter.less'

// TODO see if this component can be integrated into uim-react-component
// this is  a copy/paste from operatorconsole-ui
class Filter extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.onKeyUp = this.onKeyUp.bind(this)
  }
  handleChange(event) {
    if (this.props.onChange) {
      this.props.onChange(event.target.value)
    }
  }
  onKeyUp(evt) {
    let value = ''
    //clear filter when user presses esc key
    if (evt.which === 27) {
      this.props.onChange(value)
    }
  }
  render() {
    const fontColorStyle = { color: '#fff' }
    return (
      <div className="metric__filter">
        <FilterIcon className="metric-filter__icon" />
        <TextField
          {...this.props}
          hintStyle={fontColorStyle}
          inputStyle={fontColorStyle}
          name="filter"
          placeholder="no filter"
          onChange={this.handleChange}
          onKeyUp={this.onKeyUp}
        />
      </div>
    )
  }
}

export default Filter
