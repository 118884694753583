import React, { Component } from "react";
import InventoryNav from "./InventoryNav";
import InventoryApi from "./InventoryApi";
import TableRedux from "./TableRedux";

import { Cross as ClearIcon } from "@mineral/icons";
import InventoryFilter from "./filter/InventoryFilter";
import "./inventory.less";
import Resource from "./Resource";
import InventoryTree from "./InventoryTree";
import DeviceImportDialog from "./DeviceImportDialog";
import InstallRobotDialog from "./../titlebar/InstallRobotDialog";
import DiscoveryWizardErrorDialog from "./../titlebar/DiscoveryWizardErrorDialog";
import DeleteDevicesFromInventoryDialog from "./DeleteDevicesFromInventoryDialog";
import CustomColumnsDialog from "./CustomColumnsDialog";
import computerSystemsApi from "./../../api/computer-systems/computerSystems";
import { Drawer,Typography } from "@mineral/core";
import FileSaver from "file-saver";
import * as XLSX from "xlsx";
import moment from "moment";
import { Button as RaisedButton,  Tooltip } from "@mineral/core";
import { isEqual } from "lodash";
import { IconButton } from "@mineral/core";
import { getDefaultColumns, getTableColumns } from "./columnData";
import axios from "axios";
import config from "../../api/config";
const DISCOVERYAGENTSANDSCOPES= Resource.get("Discovery Agents and Scopes"); //Discovery Agents and Scopes

const CLOSE = Resource.get("Close"); //Close
class Inventory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      isDiscoveryWizardNav: false,
      openDeviceImportDialog: false,
      openRobotDeployDialog: false,
      openDiscoveryWizardErrorDialog: false,
      openDeleteDevicesFromInventoryDialog: false,
      showRobotDeployStatusButton: false,
      exportError: false,
      openEditColumnDailog: false,
      savedColumns: [],
      inventoryTreeData: {},
      isRobotDeployment: false,
      savedFilters: null,
      navHeight: 0,
      isButtonDisabled: false,
    };
  }
  componentWillMount() {
    this.props.setBreadCrumbs({
      //Inventory
      name: Resource.get("Inventory"),
      link: `/inventory`,
    });
    this.props.switchTreeViewNav("CABI");
    this.props.disableTreeViewNav([]);
    computerSystemsApi.getSavedColumns().then((response) => {
      let columns = response.data.columns;
      const filters = JSON.parse(response.data.colFilters);
      this.setState({
        savedColumns: columns?.length > 0 ? columns : getDefaultColumns()[1],
        savedFilters: Array.isArray(filters) ? filters : [],
      });
    });
    if(this.props.acl && this.props.acl.isBusUser )
    {
    computerSystemsApi.getInventory().then((computerSystemsAction) => {
      const inventoryData = computerSystemsAction?.data;
      this.setState({
        inventoryTreeData: inventoryData,
      });
    });
  }
  }
  toggleDrawer() {
    this.setState({
      open: !this.state.open,
    });
  }
  onGlobalActionMenu = (event, child) => {
    /* if (event.currentTarget.id == "dwizard") {
      if (this.state.isDiscoveryWizardNav) {
        this.setState({
          open: true,
          isDiscoveryWizardNav: false,
        });
        this.props.history.push("/discover-devices-tiles");
      } else {
        this.setState({
          openDiscoveryWizardErrorDialog: true,
        });
      }
    } else  */
    if (event.currentTarget.id == "robotDeploy") {
      this.setState({
        openRobotDeployDialog: true,
      });
      /*robots.deployRobot(this.props.selectedCsIds)
      .then(response => {
         console.log("Success")
      })
      .catch(error => {
        console.log("error")
      })*/
    } else if (event.currentTarget.id === "devImport") {
      this.setState({
        openDeviceImportDialog: true,
      });
    } else if (event.currentTarget.id === "deleteDevicesFromInventory") {
      this.setState({
        openDeleteDevicesFromInventoryDialog: true,
      });
    } else if (event.currentTarget.id === "editcolumn") {
      this.setState({ openEditColumnDailog: true });
    } else if (event.currentTarget.id == "export") {
      //dp039841 *export to excel
      child.then((data) => {
        const fromTableData = data._items;
        if (fromTableData.length > 0) {
          let d = new Date();
          let dformat = `${d.getDate()}-${
            d.getMonth() + 1
          }-${d.getFullYear()}-${d.getHours()}-${d.getMinutes()}-${d.getSeconds()}`;
          let fileName = "Inventory_" + dformat;
          let rawdata = fromTableData;
          let exportData = [];
          const fileType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
          const fileExtension = ".xlsx";
          let savedColumns =
            this.state.savedColumns.length > 0
              ? this.state.savedColumns
              : [
                  "Name",
                  "Device Type",
                  "OS Name",
                  "Origin",
                  "IP Address",
                  "Monitored By",
                  "Bus Type",
                  "Discovered",
                ];
          rawdata.forEach(function (data) {
            const createdTime = data.attributes.create_time ? parseInt(data.attributes.create_time[0], 10) : null;
            const time = createdTime ? moment(createdTime).fromNow() : "";
            let csvData = {};

            savedColumns.forEach((headerName) => {
              switch (headerName) {
                case "Name":
                  csvData[headerName] = data.name;
                  break;
                case "Alias":
                  csvData[headerName] = data.alias;
                  break;
                case "IP Address":
                  csvData[headerName] = data.ipAddresses
                    ? data.ipAddresses.toString()
                    : "";
                  break;
                case "Caption":
                  csvData[headerName] = data.attributes.caption
                    ? data.attributes.caption.toString()
                    : "";
                  break;
                case "Device Type":
                  csvData[headerName] = data.role;
                  break;
                case "Description":
                  csvData[headerName] = data.attributes.description
                    ? data.attributes.description.toString()
                    : "";
                  break;
                case "Dedicated":
                  csvData[headerName] = data.attributes.dedicated
                    ? data.attributes.dedicated.toString()
                    : "";
                  break;
                case "OS Type":
                  csvData[headerName] = data.osType;
                  break;
                case "OS Name":
                  csvData[headerName] = data.osName;
                  break;
                case "OS Version":
                  csvData[headerName] = data.osVersion;
                  break;
                case "OS Description":
                  csvData[headerName] = data.osDescription;
                  break;
                case "Origin":
                  csvData[headerName] = data.attributes.origin
                    ? data.attributes.origin.toString()
                    : "";
                  break;
                case "Latest Origin":
                  csvData[headerName] = data.attributes.latestOrigin
                    ? data.attributes.latestOrigin.toString()
                    : "";
                  break;
                case "MAC Address":
                  csvData[headerName] = data.attributes.mac
                    ? data.attributes.mac.toString()
                    : "";
                  break;
                case "User Tag 1":
                  csvData[headerName] = data.attributes.user_tag_1
                    ? data.attributes.user_tag_1.toString()
                    : "";
                  break;
                case "User Tag 2":
                  csvData[headerName] = data.attributes.user_tag_2
                    ? data.attributes.user_tag_2.toString()
                    : "";
                  break;
                case "Monitored By":
                  csvData[headerName] = data.attributes.monitoredBy
                    ? data.attributes.monitoredBy.toString()
                    : "";
                  break;
                case "Bus Type":
                  csvData[headerName] = data.busType;
                  break;
                case "CS Id":
                  csvData[headerName] = data.attributes.cs_id
                    ? data.attributes.cs_id.toString()
                    : "";
                  break;
                case "CS Key":
                  csvData[headerName] = data.attributes.cs_key
                    ? data.attributes.cs_key.toString()
                    : "";
                  break;
                case "Discovered":
                  csvData[headerName] = time;
                  break;
                case "Has KeyIcon":
                  csvData[headerName] =
                    (data.attributes && data.attributes.wmi_profile != null) ||
                    (data.attributes && data.attributes.shell_profile != null)
                      ? "Yes"
                      : "No";
                  break;
                case "Last Updated":
                  csvData[headerName] =
                    data.attributes && data.attributes.change_time != null
                      ? moment(
                          parseInt(data.attributes.change_time.toString(), 10)
                        ).format("DD/MM/YYYY")
                      : "";
                  break;
                case "Removed":
                  csvData[headerName] = data.attributes.deleted
                    ? data.attributes.deleted.toString()
                    : "False";
                  break;
              }
            });
            exportData.push(csvData);
          });
          const ws = XLSX.utils.json_to_sheet(exportData);
          const wb = {
            Sheets: { "Device Details": ws },
            SheetNames: ["Device Details"],
          };
          const excelBuffer = XLSX.write(wb, {
            bookType: "xlsx",
            type: "array",
          });
          const exporteddata = new Blob([excelBuffer], { type: fileType });
          FileSaver.saveAs(exporteddata, fileName + fileExtension);
        }
      });
    }
  };

  handleSaveSelectedColumns(selectedColumns, selectedFilters) {
    if (this.state.openEditColumnDailog) {
      this.setState({ openEditColumnDailog: false });
    }
    const params = {
      columns: this.state.savedColumns,
      colFilters: JSON.stringify(this.state.savedFilters),
    };
    if (selectedColumns) {
      let toSaveColList = [];
      selectedColumns.forEach((col) => {
        toSaveColList.push(col.headerName);
      });
      params.columns = toSaveColList;
    }
    if (selectedFilters) {
      params.colFilters = JSON.stringify(selectedFilters);
    }
    computerSystemsApi.saveSelectedColumns(params).then((response) => {
      if (response.data) {
        computerSystemsApi.getSavedColumns().then((response) => {
          let columns = response.data.columns;
          const filters = JSON.parse(response.data.colFilters);
          const newState = {};
          if (columns && !isEqual(columns, this.state.savedColumns)) {
            newState.savedColumns =
              columns && columns.length > 0 ? columns : [];
          }
          if (
            filters &&
            Array.isArray(filters) &&
            !isEqual(filters, this.state.savedFilters)
          ) {
            newState.savedFilters = filters;
          }
          if (Object.keys(newState)) {
            this.setState({
              savedColumns: columns && columns.length > 0 ? columns : [],
              savedFilters: Array.isArray(filters) ? filters : [],
            });
          }
        });
      }
    });
  }

  openDiscoveryWizard = (state) => {
    this.setState({
      isDiscoveryWizardNav: state,
    });
  };
  handleDiscoveryWizard = () => {
    if (this.state.isDiscoveryWizardNav) {
      this.setState({
        open: false,
        isDiscoveryWizardNav: false,
      });
      this.props.history.push("/discover-devices-tiles");
    } else {
      this.setState({
        openDiscoveryWizardErrorDialog: true,
        open: false,
      });
    }
  };
  setSelectedRowData = (dataArr) => {
    this.setState({ isRobotDeployment: false, isButtonDisabled: false });
    if (dataArr && dataArr.length > 0) {
      dataArr.forEach((row) => {
        if (
          (this.props.selectedCsIds.indexOf(row.id) !== -1 &&
            row.attributes &&
            row.attributes.wmi_profile != null) ||
          (row.attributes && row.attributes.shell_profile != null)
        ) {
          this.setState({ isRobotDeployment: true });
        }
      });
    }
  };

  updateHeight = (height) => {
    this.setState({ navHeight: height });
  };

  handleInventoryTreeUpdate(){
    var url = process.env.NODE_ENV == "development" ?
    'http://10.252.25.208:8080/castlemock/mock/rest/project/TK8t3o/application/UXxhjW/inventoryTree':    
    [config.basename, 'api/v1/app-discovery/inventoryTree'].join('/') ;

    axios.get(url)
    .then(r=>this.setState({inventoryTreeData:r?.data}))
    .catch(err=>console.log(err));
  };

  render() {
    return (
      <div>
        <main className="inventory__main">
          <InventoryNav
            isCheckboxSelected={this.props.selectedCsIds.length > 0}
            onGlobalActionMenu={this.onGlobalActionMenu}
            showRobotDeployStatusButton={this.state.showRobotDeployStatusButton}
            toggleDrawer={this.toggleDrawer.bind(this)}
            isRobotDeployment={this.state.isRobotDeployment}
            selectedCsIds={this.props.selectedCsIds}
            savedColumns={this.state.savedColumns}
            savedFilters={this.state.savedFilters}
            updateHeight={this.updateHeight}
            handleSaveSelectedColumns={this.handleSaveSelectedColumns.bind(
              this
            )}
            isDisabled={this.state.isButtonDisabled}
            handleInventoryTreeUpdate={()=>this.handleInventoryTreeUpdate()}
          />
          <Drawer
            anchor="right"
            open={this.state.open}
            onClose={() => {
              this.toggleDrawer();
              this.setState({ isDiscoveryWizardNav: false });
            }}
          >
            <aside aria-label={DISCOVERYAGENTSANDSCOPES} className="inventory-tree">
              <div className="drawer_inventory-title">
                <Typography component="h1" variant="h3">
                  {DISCOVERYAGENTSANDSCOPES}
                </Typography>
                <Tooltip title={CLOSE}>
                  <IconButton
                    onClick={() => {
                      this.toggleDrawer();
                      this.setState({ isDiscoveryWizardNav: false });
                    }}
                  
                    style={{ float: "right",  }}
                    aria-label={CLOSE}
                    autoFocus
                  >
                    <ClearIcon role="button" />
                  </IconButton>
                </Tooltip>
              </div>

{this.openDiscoveryWizard && 
              <InventoryTree
                openDiscoveryWizard={this.openDiscoveryWizard}
                inventoryTreeData={this.state.inventoryTreeData}
              />}
              {this.props.acl &&
              this.props.acl.isBusUser &&
              this.props.acl.canManageDiscovery ? (
                <div style={{ textAlign: "right", margin: "32px" }}>
                  <Tooltip title="Cancel">
                    <RaisedButton
                      disableRipple
                      style={{
                        marginRight: "8px",
                        textTransform: "capitalize",
                      }}
                      variant="text"
                      color="primary"
                      //Cancel
                      children={Resource.get("Cancel")}
                      onClick={() => {
                        this.toggleDrawer();
                        this.setState({ isDiscoveryWizardNav: false });
                      }}
                    />
                  </Tooltip>
                  <Tooltip title={"Navigate to Discovery Wizard"}>
                    <RaisedButton role="link" aria-roledescription="Click to navigate to discovery wizard"
                      disableRipple
                      variant="contained"
                      style={{ textTransform: "capitalize" }}
                      children={"Navigate to Discovery Wizard"}
                      onClick={this.handleDiscoveryWizard.bind(this)}
                    />
                  </Tooltip>
                </div>
              ) : (
                <div style={{ textAlign: "right", margin: "32px" }}>
                  <Tooltip title="Cancel">
                    <RaisedButton
                      disableRipple
                      variant="text"
                      style={{ textTransform: "capitalize" }}
                      //Cancel
                      children={Resource.get("Cancel")}
                      color="primary"
                      onClick={() => {
                        this.toggleDrawer();
                        this.setState({ isDiscoveryWizardNav: false });
                      }}
                    />
                  </Tooltip>
                </div>
              )}
            </aside>
          </Drawer>
          <InventoryFilter />
          <TableRedux
            fromInventory={true}
            navHeight={this.state.navHeight}
            data={this.props.data}
            rowModelType="infinite"
            savedColsListView={this.state.savedColumns}
            setSelectedRowData={this.setSelectedRowData}
            handleSaveSelectedColumns={this.handleSaveSelectedColumns.bind(
              this
            )}
          />
          {/* <InventoryApi /> */}

          <CustomColumnsDialog
            open={this.state.openEditColumnDailog}
            presistedColumns={this.state.savedColumns}
            saveSelectedCols={this.handleSaveSelectedColumns.bind(this)}
            handleClose={() => {
              this.setState({ openEditColumnDailog: false });
            }}
          />

          <DeviceImportDialog
            open={this.state.openDeviceImportDialog}
            handleClose={() => {
              this.setState({ openDeviceImportDialog: false });
            }}
          />
          <InstallRobotDialog
            open={this.state.openRobotDeployDialog}
            handleClose={(info) => {
              info === "installRobotOpen"
                ? this.setState({
                    openRobotDeployDialog: false,
                    showRobotDeployStatusButton: true,
                  })
                : this.setState({
                    openRobotDeployDialog: false,
                    showRobotDeployStatusButton: false,
                  });
            }}
            devices={this.props.selectedCsIds}
            handleErrorDialogOpen={() => {
              this.setState({ openRobotDeployDialog: false });
            }}
            {...this.props}
          />
          <DeleteDevicesFromInventoryDialog
            open={this.state.openDeleteDevicesFromInventoryDialog}
            handleErrorDialogOpen={() => {
              this.setState({ openDeleteDevicesFromInventoryDialog: false });
            }}
            handleClose={() => {
              this.setState({ openDeleteDevicesFromInventoryDialog: false });
            }}
            handleDisabled={(e)=>{
              this.setState({isButtonDisabled: e});
            }}
            {...this.props}
          />

          <DiscoveryWizardErrorDialog
            open={this.state.openDiscoveryWizardErrorDialog}
            handleClose={() => {
              this.setState({ openDiscoveryWizardErrorDialog: false });
            }}
            {...this.props}
          />
        </main>
      </div>
    );
  }
}

export default Inventory;
