import {
	SAVE_WMI_CREDENTIALS,
	SAVE_WMI_CREDENTIALS_SUCCESS,
	SAVE_WMI_CREDENTIALS_FAILURE,
	EDIT_WMI_CREDENTIALS,
	EDIT_WMI_CREDENTIALS_SUCCESS,
	EDIT_WMI_CREDENTIALS_FAILURE,
	SAVE_UNIX_CREDENTIALS,
	SAVE_UNIX_CREDENTIALS_SUCCESS,
	SAVE_UNIX_CREDENTIALS_FAILURE,
	EDIT_UNIX_CREDENTIALS,
	EDIT_UNIX_CREDENTIALS_SUCCESS,
	EDIT_UNIX_CREDENTIALS_FAILURE,
	GET_WMI_CREDENTIALS,
	FETCH_WMI_CREDENTIALS_SUCCESS,
	FETCH_WMI_CREDENTIALS_FAILURE,
	GET_UNIX_CREDENTIALS,
	FETCH_UNIX_CREDENTIALS_SUCCESS,
	FETCH_UNIX_CREDENTIALS_FAILURE,
	DELETE_UNIX_CRED,
	UPDATE_UNIX_CRED,
	ADDED_UNIX_CRED,
} from './actionTypes'
import isEqual from 'lodash/isEqual'


const unixCredentials = (
  state = {
    isSaving: false,
    isUpdating:false,
    didInvalidate: false,
    isFetching: false,
    items:[],
    unixItems:[],
  },
  action,
) => {
  switch (action.type) {
      case GET_UNIX_CREDENTIALS:
          return Object.assign({}, state, {
        	    isFetching: true,
          	    didInvalidate: false,
         })
      case FETCH_UNIX_CREDENTIALS_SUCCESS:
          const itemsUnix = action.unixCredentials
          const updatedUnixState = Object.assign({}, state, {
            isFetching: false,
            didInvalidate: false,
            unixItems: itemsUnix
          });
          return isEqual(updatedUnixState, state) ? state : updatedUnixState
        case FETCH_UNIX_CREDENTIALS_FAILURE:
          return Object.assign({}, state, {
            isFetching: false,
            didInvalidate: true,
            errorMessage: action.errorMessage,
            unixItems: []
          })
    case SAVE_WMI_CREDENTIALS:
	       return Object.assign({}, state, {
	    	isSaving: true,
	        didInvalidate: false,
	      })
    case SAVE_WMI_CREDENTIALS_SUCCESS:
	      return Object.assign({}, state, {
	        isSaving: false,
	        didInvalidate: false,
	      })
    case SAVE_WMI_CREDENTIALS_FAILURE:
    	 return Object.assign({}, state, {
    	        isSaving: false,
    	        didInvalidate: true,
    	  })
    case EDIT_WMI_CREDENTIALS:
	       return Object.assign({}, state, {
	    	 isUpdating: true,
	        didInvalidate: false,
	      })
    case EDIT_WMI_CREDENTIALS_SUCCESS:
	      return Object.assign({}, state, {
	    	  isUpdating: false,
	        didInvalidate: false,
	      })
    case EDIT_WMI_CREDENTIALS_FAILURE:
	 	 return Object.assign({}, state, {
	 		isUpdating: false,
	 	        didInvalidate: true,
	 	  })
    case SAVE_UNIX_CREDENTIALS:
	       return Object.assign({}, state, {
	    	isSaving: true,
	        didInvalidate: false,
	      })
 case SAVE_UNIX_CREDENTIALS_SUCCESS:
	      return Object.assign({}, state, {
	        isSaving: false,
	        didInvalidate: false,
	      })
 case SAVE_UNIX_CREDENTIALS_FAILURE:
 	 return Object.assign({}, state, {
 	        isSaving: false,
 	        didInvalidate: true,
 	  })
 case EDIT_UNIX_CREDENTIALS:
	       return Object.assign({}, state, {
	    	 isUpdating: true,
	        didInvalidate: false,
	      })
 case EDIT_UNIX_CREDENTIALS_SUCCESS:
	      return Object.assign({}, state, {
	    	  isUpdating: false,
	        didInvalidate: false,
	      })
 case EDIT_UNIX_CREDENTIALS_FAILURE:
	 	 return Object.assign({}, state, {
	 		isUpdating: false,
	 	        didInvalidate: true,
	 	  })
case DELETE_UNIX_CRED:
    	const deletedCred = action.deletedCredential
    	let filteredList=[];
            filteredList=state.unixItems.filter(item => {
                return item.id !== deletedCred
            })
        const updatedItem =  Object.assign({}, state, {
          isFetching: false,
          didInvalidate: false,
          unixItems: filteredList
        })
        return isEqual(updatedItem, state) ? state : updatedItem
    case UPDATE_UNIX_CRED:
	const updatedCred = action.updatedCredential
    	let updatedCatalog = state.unixItems
    	for (let i = 0; i < updatedCatalog.length ; i++){
            if(updatedCred.id === updatedCatalog[i].id){
                updatedCatalog[i]=updatedCred;
                break;
            }
        }
        const updatedCatalogItem =  Object.assign({}, state, {
          isFetching: false,
          didInvalidate: false,
          unixItems: updatedCatalog
        })
        return isEqual(updatedCatalogItem, state) ? state : updatedCatalogItem
    case ADDED_UNIX_CRED:
	const addedCred = action.addedCredential
    	let addedCatalog = state.unixItems
    	let isNewCredential=true
        for (let i = 0; i < addedCatalog.length ; i++){
            if(addedCred.id === addedCatalog[i].id){
               	isNewCredential=false
				break;
            }
        }
        if(isNewCredential){
        	addedCatalog.push(addedCred);
		}
        const addCatalogItem =  Object.assign({}, state, {
          isFetching: false,
          didInvalidate: false,
          unixItems: addedCatalog
        })
        return isEqual(addCatalogItem, state) ? state : addCatalogItem
    default:
      return state
  }
}

export default unixCredentials
