import connect from "./../../utils/connect";
import { setBreadCrumbs } from "./../breadcrumbs/actions";
import TableManager from "./../table/TableManager";
import { setCardPage } from "./../cards/actions";
import { setSelectedCsIds } from "../../api/groups/actions";
import { filteredRowEntities } from "./../entities/actions";
import { getAlarmUrl } from "../../utils/common";
import { getAccount } from "../../api/account/actions";
import {
  setInvetoryFilteredData,
  searchComputerSystems,
  getLookupList,
  fetchAllComputerSystems,
} from "../../api/computer-systems/actions";

const mapStateToProps = (state) => {
  return {
    featureFlags: state.configuration.items,
    computerSystemsDidInvalidate: state.computerSystems.didInvalidate,
    computerSystemsIsFetching: state.computerSystems.isFetching,
    computerSystemsItems: state.computerSystems.item,
    columnDefs: window.location.href.includes("groups")
      ? "groupCols"
      : "csCols",
    entities: state.entities,
    breadcrumbs: state.breadCrumbs,
    inventorySearch: true,
    saas: state.saas,
    acl: state.acl,
    visibleDataCount: state.columnFilter,
    doiurl: state.user.doiurl,
    entitiesFilterSortConfig: state.entitiesFilterSortConfig,
    refreshGridData: state.entitiesFilterSortConfig.refreshGridData,
    accounts: state.account.accounts,
    quickFilter: state.inventoryEntityFilter,
    selectedCsIds: state.selectedCsIds,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    handleAlarmCellClick: (row, history, saas, doiurl, featureFlags) => {
      if (saas && doiurl && featureFlags && featureFlags.globalAlarmsView) {
        window.location.href = getAlarmUrl(
          doiurl,
          "csId=" + row.data.id + "&deviceName=" + row.data.name
        );
      } else {
        const newRoute = `/computer-systems/${row.data.id}/alarms`;
        history.push(newRoute);
      }
    },
    handleCabiCellClick: (row, history) => {
      // const newRoute = `/computer-systems/${row.data.id}/cabi`
      const newRoute = `/computer-systems/${row.data.id}/dashboard`;
      history.push({
        pathname: newRoute,
        state: { detail: row.data, csId: row.data.id },
      });
    },
    handleColumnFilterChange: (rowsLen) => {
      dispatch(filteredRowEntities(rowsLen));
    },
    handlePageClick: (selectedPage) => {
      dispatch(setCardPage(selectedPage.selected));
    },
    resetCardPage: () => {
      dispatch(setCardPage(0));
    },
    handleCsRowsSelected: (ids) => {
      dispatch(setSelectedCsIds(ids));
    },
    setBreadCrumbs: (updatedBreadCrumbs) =>
      dispatch(setBreadCrumbs(updatedBreadCrumbs)),
    getAccount: () => dispatch(getAccount()),

    //dp039841
    setInvetoryFilteredData: (rowData) =>
      dispatch(setInvetoryFilteredData(rowData)),
    searchComputerSystems: (nextPage) =>
      dispatch(searchComputerSystems(nextPage, null, null, null)),
    sortChange: (newSort) =>
      dispatch(searchComputerSystems(null, newSort, null, null)),
    setFilter: (newFilter) =>
      dispatch(searchComputerSystems(null, null, newFilter, null)),
    setQuickFilter: () =>
      dispatch(searchComputerSystems(null, null, null, null)),
    getLookupList: (lookupType) => dispatch(getLookupList(lookupType)),
    getAllComputerSystems: () => dispatch(fetchAllComputerSystems()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TableManager);
