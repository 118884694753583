import {
  COLUMNS_SET,
} from './actionTypes'


export const setColumns = (n) => {
  return {
    type: COLUMNS_SET,
    columns: n,
  }
}
