import React from 'react'
import SecondaryNav from './secondaryNav/SecondaryNav'
import { setBreadCrumbs } from './../breadcrumbs/actions'
import { setShowTimeRange } from './../../api/cabi/actions'
import connect from './../../utils/connect'
import { monitorProbes as probeTitles } from '@uim/uim-common-strings'
import './technologyView.less'
const TechnologyView = props => {
  let probeBreadcrumb = probeTitles[props.component.props.probe]
  ? probeTitles[props.component.props.probe]
  : props.component.props.probe

  if(props.component.props.probeId){
    probeBreadcrumb=props.component.props.probeId;
  }
  
  let probeLink=props.component.props.probe?`/technologies/${props.component.props.probe}/cabi`:
  `/technologies/${props.component.props.probeId}/dashboard`
  props.setBreadCrumbs([
    {
      name: 'Dashboards',
      link: `/technologies`,
    },
    {
      name: probeBreadcrumb,
      link: probeLink,
    },
  ])
  
  return (
    <div>
    <SecondaryNav />
    <main className="cabi__main--groups cabi__technology-view">
    {props.component}
    </main>
    </div>
    )
  }
  
  const mapStateToProps = state => {
    return {
      sid: state.sid,
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
      setBreadCrumbs: item => dispatch(setBreadCrumbs(item)),
      setShowTimeRange: showTimeRange =>
      dispatch(setShowTimeRange(showTimeRange)),
    }
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(TechnologyView)
  