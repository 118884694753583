import React from 'react'

const DeviceSummary = props => {
  // const entity = props.entity
  return (
    <section
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        height: '100%',
      }}>
      <p>No data found for the following metrics</p>
      <p>[1.6:1, 1.5:1, 1.1:3, 1.6:29, 1.1:14, 1.6:2]</p>
      <p>Jun 22, 2017 4:59 PM - Jun 23, 2017 4:59 PM</p>
    </section>
  )
}

DeviceSummary.defaultProps = {
  entity: {},
}
export default DeviceSummary
