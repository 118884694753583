import connect from "./../../../../utils/connect";
import Controls from "./Controls";
import { setCardPage } from "./../../../cards/actions";
import { setQuickFilterEntities } from "./../../../entities/actions";
import { setGroupSortProp } from "./../../../../api/groups/actions";

function mapStateToProps(state, ownProps) {
  return {
    sort: state.groupSorter.prop,
    showSort: ownProps.showSort,
    filterHint: ownProps.filterHint,
    searchTerm: state.inventoryEntityFilter.quickFilter,
    allGroups: state.tree_view.groups[0]
      ? state.tree_view.groups[0].children
      : [],
    entity: state.entity,
    stateDataGroups: state.tree_view.stateData.Groups,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    handleChange: (searchTerm) => {
      dispatch(setQuickFilterEntities(searchTerm));
      dispatch(setCardPage(0));
    },
    onSortChange: (value, order) => {
      dispatch(setGroupSortProp(value, order));
      dispatch(setCardPage(0));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Controls);
