// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Robot installer download and instructions
  content["Robot installer download and instructions"] =
  "Instrucciones y descarga del instalador de robots";

  //via relay hub
  content["Robots manage the probes (monitoring agents) used to collect metric data from your systems, sending the data to Infrastructure Management via the hub."] =
  "Los robots gestionan las sondas (agentes de monitorización) que se utilizan para recopilar datos de métrica de los sistemas enviando los datos a Infrastructure Management a través del concentrador.";

  //ROBOT INSTALLERS
  content["ROBOT INSTALLERS"] =
  "INSTALADORES DE ROBOTS";

  // Setup Wizard
  content["Setup Wizard"] =
  "Asistente de instalación";

  //Robot deployment
  content["Robot deployment"] =
  "Implementación del robot";

  //Deploy
  content["Deploy"] =
  "Implementar";

  //Add Devices
  content["Add Devices"] =
  "Agregación de dispositivos";

  //Windows devices are not supported on this monitoring technology
  content["Windows devices are not supported on this monitoring technology"] =
  "Los dispositivos de Windows no son compatibles con esta tecnología de monitorización.";

  //OK
  content["OK"] =
  "Aceptar";

  //Windowds deploy help message
  content["A Windows relay hub must be selected to deploy a Windows device."] =
  "Debe seleccionarse un concentrador de transmisión de Windows para implementar un dispositivo de Windows.";

  //IP Address Required
  content["IP Address Required"] =
  "La dirección IP es obligatoria.";

  //No duplicate IPs
  content["No duplicate IPs"] =
  "No hay direcciones IP duplicadas.";

  //Required
  content["Required"] =
  "Obligatorio";

  //Username
  content["Username"] =
  "Nombre de usuario";

  //Username Required
  content["Username Required"] =
  "El nombre del usuario es obligatorio.";

  //Password
  content["Password"] =
  "Contraseña";

  //Password Required
  content["Password Required"] =
  "La contraseña es obligatoria.";

  //Sudo Password Required Message
  content["Sudo Password is only applicable for Linux systems where username is not \'root\'."] =
  "La contraseña de Sudo solamente se aplica a los sistemas Linux donde el nombre de usuario no sea root.";

  //Sudo password
  content["Sudo password"] =
  "Contraseña sudo";

  //OS
  content["OS"] =
  "SO";

  //CentOS
  content["CentOS"] =
  "CentOS";

  //Debian
  content["Debian"] =
  "Debian";

  //Open SUSE
  content["openSUSE"] =
  "openSUSE";

  //RHEL
  content["RHEL"] =
  "RHEL";

  //SUSE
  content["SUSE"] =
  "SUSE";

  //Ubuntu
  content["Ubuntu"] =
  "Ubuntu";

  //solaris
  content["Solaris"] =
  "Solaris";

  //aix
  content["AIX"] =
  "AIX";

  //Windows
  content["Windows"] =
  "Windows";

  //help message of deploying on linux hub
  content["Windows can not be deployed on a UNIX hub"] =
  "No se puede implementar Windows en un concentrador de UNIX.";

  //Profile Required
  content["Profile Required"] =
  "Perfil requerido";

  //ARCH
  content["ARCH"] =
  "ARCH";

  //64-bit
  content["64-bit"] =
  "64 bits";

  //32-bit
  content["32-bit"] =
  "32 bits";

  //sparcv9/sparc
  content["sparcv9"] =
  "sparcv9";

  //Architecture Required
  content["Architecture Required"] =
  "Arquitectura requerida";

  //Add Row
  content["Add Row"] =
  "Agregar fila";

  //Duplicate Row
  content["Duplicate Row"] =
  "Fila duplicada";

  //Device IP
  content["Device IP"] =
  "IP del dispositivo";

  //Architecture
  content["Architecture"] =
  "Arquitectura";

  //Sudo password (Linux only)
  content["Sudo password (Linux only)"] =
  "Contraseña sudo (solo Linux)";

  //Too many robots deployed
  content["Too many robots deployed"] =
  "Hay demasiados robots implementados.";

  // A CSV file was already imported
  content["A CSV file was already imported"] =
  "Ya se ha importado un archivo CSV.";

  //Delete Row
  content["Delete Row"] =
  "Suprimir fila";

  //AUTO DEPLOY
  content["AUTO DEPLOY"] =
  "IMPLEMENTACIÓN AUTOMÁTICA";

  //Failed Robot Deployment
  content["Failed Robot Deployment"] =
  "Error en la implementación del robot";

  //Check your server for proper configuration
  content["Check your server for proper configuration."] =
  "Compruebe el servidor para verificar que está configurado correctamente.";

  //Click "Deploy" to add the devices to UIM
  content["Click Deploy to add the devices to UIM. Enabling monitoring happens on the next step."] =
  "Haga clic en Implementar para agregar los dispositivos a UIM. La activación de la monitorización se lleva a cabo en el paso siguiente.";

  //Click deploy to install the monitoring host.  When complete, click Next.
  content["Click deploy to install the monitoring host.  When complete, click Next."] =
  "Haga clic en Implementar para instalar el host de monitorización. Una vez completada la instalación, haga clic en Siguiente.";

  // with the selected hub
  content["Devices added must be able to communicate with the selected  hub."] =
  "Los dispositivos agregados deben ser capaces de comunicarse con el concentrador seleccionado.";

  //Ports 48000 to 48025 must be open
  content["Ports 48000 to 48025 must be open"] =
  "Los puertos 48000-48025 deben estar abiertos ";

  //for the  hub to communicate with the CA Infrastructure Management application.
  content["for the  hub to communicate with the CA Infrastructure Management application."] =
  "para que el concentrador pueda comunicarse con la aplicación CA Infrastructure Management.";

  //If monitoring cloud-based infrastructure, a  hub is needed for each isolated virtual network (such as AWS Virtual Private Cloud or Azure Virtual Network).
  content["If monitoring cloud-based infrastructure, a  hub is needed for each isolated virtual network (such as AWS Virtual Private Cloud or Azure Virtual Network)."] =
  "Si se desea llevar a cabo la monitorización de una infraestructura basada en la nube, se requiere un concentrador para cada red virtual (por ejemplo, AWS Virtual Private Cloud o Azure Virtual Network).";

  //Ensure that the
  content["Ensure that the "] =
  "Asegúrese de que el ";

  //If you are configuring monitoring technologies supported only on Windows, such as Windows NT Services, select a Windows  hub.
  content["If you are configuring monitoring technologies supported only on Windows, such as Windows NT Services, select a Windows  hub."] =
  "Si está configurando tecnologías de monitorización que solamente son compatibles con Windows, como Windows NT Services, seleccione un concentrador de Windows.";

  //Select hub
  content["Select hub"] =
  "Seleccionar concentrador";

  // No hub selected
  content["*No hub selected"] =
  "*Ningún concentrador seleccionado";

  //Select a hub
  content["Select a hub"] =
  "Seleccionar un concentrador";

  // ADD  HUB...
  content["ADD  HUB..."] =
  "AGREGAR CONCENTRADOR...";

  //If you are not installing with root access, use either sudo
  content["If you are not installing with root access, use either sudo"] =
  "Si no se realiza la instalación con acceso root, se debe utilizar sudo";

  //You can also use su to get a root shell and execute the command.
  content["You can also use su to get a root shell and execute the command."] =
  "También se puede utilizar su para obtener el shell root y ejecutar el comando.";

  //Copy the appropriate Windows installer to any folder on the target system:
  content["Extract the zip file and copy the appropriate Windows installer to any folder on the target system:"] =
  "Extraiga el archivo .zip y copie al instalador de Windows apropiado en cualquier carpeta del sistema de destino:";

  //Copy the nms-robot-vars.cfg answer file to the same folder.
  content["Copy the nms-robot-vars.cfg answer file to the same folder and define the following parameters:"] =
  "Copie el archivo de respuesta nms-robot-vars.cfg en la misma carpeta y defina los parámetros siguientes:";

  //update hub ip address in the nms-robot-vars.cfg file
  content["hubip: IP address of the server configured as the relay hub."] =
  "hubip: La dirección IP del servidor configurada como el concentrador de transmisión.";

  //update robot ip address in the nms-robot-vars.cfg file
  content["robotip: IP address of the server where you will execute the installer."] =
  "robotip: La dirección IP del servidor donde se tiene previsto ejecutar el instalador.";

  //Install the robot by executing:
  content["Install the robot by executing:"] =
  "Instale el robot mediante la ejecución del comando siguiente:";

  //Installation
  content["Installation"] =
  "Instalación";

  //Robot Installers (including answer file)
  content["Robot Installers (including answer file)"] =
  "Instaladores de robots (incluido el archivo de respuesta)";

  //The installation is complete. To start the robot, execute the following command:
  content["The installation is complete. To start the robot, execute the following command:"] =
  "La instalación se ha completado. Para iniciar el robot, ejecute el comando siguiente:";

  //Execute the RobotConfigurer.sh script to configure the robot when the installer exits.
  content["Execute the RobotConfigurer.sh script to configure the robot when the installer exits."] =
  "Ejecute el script RobotConfigurer.sh para configurar el robot cuando se cierre el instalador.";

  //The rpm flags function as follows:
  content["The rpm flags function as follows:"] =
  "Los indicadores rpm funcionan del modo siguiente:";

  //The default install directory is /opt/nimsoft. To specify the installation directory, execute the following command, where <directory> is the full path:
  content["The default install directory is /opt/nimsoft. To specify the installation directory, execute the following command, where <directory> is the full path:"] =
  "El directorio de instalación predeterminado es /opt/nimsoft. Para especificar el directorio de instalación, ejecute el comando siguiente, donde <directory> es la ruta completa:";

  //Copy the appropriate Linux installer to /opt on the target system:
  content["Extract the zip file and copy the appropriate Linux installer to /opt on the target system:"] =
  "Extraiga el archivo .zip y copie al instalador de Linux apropiado en la carpeta /opt en el sistema de destino:";

  //Install the robot with the following command, where &lt;arch&gt;is i386 or x86_64:
  content["Install the robot with the following command, where <arch> is i386 or x86_64:"] =
  "Instale el robot con el siguiente comando, donde <arch> es i386 o x86_64:";

  //The default install folder is C:\Program Files (x86)\Nimsoft for 32-bit systems, and C:\Program Files\Nimsoft for 64-bit systems.
  content["The default install folder is C:\\Program Files (x86)\\Nimsoft for 32-bit systems, and C:\\Program Files\\Nimsoft for 64-bit systems."] =
  "La carpeta de instalación predeterminada es C:\\Archivos de programa (x86)\\Nimsoft para sistemas de 32 bits y C:\\Archivos de programa\\Nimsoft para sistemas de 64 bits.";

  //To specify the folder, append the following parameter to the command. Quotation marks are required.
  content["To specify the folder, append the following parameter to the command. Quotation marks are required."] =
  "Para especificar la carpeta, añada el siguiente parámetro al comando. Se requiere el uso de comillas.";

  //To specify the log file, append:
  content["To specify the log file, append:"] =
  "Para especificar el archivo de registro, añada:";

  //After installation, the robot starts automatically.
  content["After installation, the robot starts automatically."] =
  "Tras la instalación, el robot se inicia automáticamente.";

  //Copy the Debian installer to /opt on the target system:
  content["Extract the zip file and copy the Debian installer to /opt on the target system:"] =
  "Extraiga el archivo .zip y copie al instalador de Debian apropiado en la carpeta /opt en el sistema de destino:";

  //Note that only 64 bit Debian systems are supported as robots.
  content["Note that only 64 bit Debian systems are supported as robots."] =
  "Tenga en cuenta que únicamente se admiten como robots los sistemas Debian de 64 bits.";

  //Install the robot with the following command:
  content["Install the robot with the following command:"] =
  "Instale el robot con el comando siguiente:";

  //Copy the Ubuntu installer to /opt on the target system:
  content["Extract the zip file and copy the Ubuntu installer to /opt on the target system:"] =
  "Extraiga el archivo .zip y copie al instalador de Ubuntu apropiado en la carpeta /opt en el sistema de destino:";

  //Note that only 64 bit Ubuntu systems are supported as robots.
  content["Note that only 64 bit Ubuntu systems are supported as robots."] =
  "Tenga en cuenta que únicamente se admiten como robots los sistemas Ubuntu de 64 bits.";

  //(Ubuntu 16 only) Execute the following command to enable the robot service. Note: This step is not required for Ubuntu 14.
  content["(Ubuntu 16 only) Execute the following command to enable the robot service. Note: This step is not required for Ubuntu 14."] =
  "(Ubuntu 16 solamente) Ejecute el comando siguiente para activar el servicio del robot. Nota: Este paso no es necesario para Ubuntu 14.";

  //Copy the solaris installer to /opt on the target system:
  content["Extract the zip file and copy the Solaris installer to /opt on the target system:"] =
  "Extraiga el archivo .zip y copie el instalador de Solaris en la carpeta /opt en el sistema de destino:";

  //extract the solaris installer to /opt on the target system:
  content["Extract the gz file into to /opt on the target system:"] =
  "Extraiga el archivo .gz en la carpeta /opt en el sistema de destino:";

  //Copy the aix installer to /opt on the target system:
  content["Extract the zip file and copy the AIX installer to /opt on the target system:"] =
  "Extraiga el archivo .zip y copie el instalador de AIX en la carpeta /opt en el sistema de destino:";

  //Note that only 64 bit AIX systems are supported as robots.
  content["Note that only 64 bit AIX systems are supported as robots."] =
  "Tenga en cuenta que únicamente se admiten como robots los sistemas AIX de 64 bits.";

  //Execute the following command as the root user to install the robot to the default directory
  content["Install the robot with the following command as a root user."] =
  "Instale el robot con el comando siguiente como usuario root.";

  //Enable the Nimbus service as root
  content["Enable the Nimbus service as root."] =
  "Active el servicio de Nimbus como raíz.";

  //Start the robot as root. If the robot is configured to run as a nonroot account, the processes are running as the nonroot user.
  content["Installation is complete.Start the robot as root. If the robot is configured to run as a nonroot account, the processes are running as the nonroot user."] =
  "La instalación está completa. Inicie el robot como root. Si el robot está configurado para ejecutarse como una cuenta no root, los procesos se estarán ejecutando como el usuario no root.";

  //To run the robot as a nonroot user account, take the following steps
  content["Steps to run the robot as a nonroot user account"] =
  "Pasos para ejecutar el robot como una cuenta de usuario no root";

  //Add the nonroot user to
  content["Add the nonroot user to"] =
  "Agregar usuario no root";

  //Change the ownership of the
  content["Change the ownership of the"] =
  "Cambiar la propiedad de";

  //file, and the UIM installation to the nonroot user
  content["file, and the UIM installation to the nonroot user:"] =
  "archivo y la instalación de UIM para el usuario no root:";

  //installing message
  content["install the software package"] =
  "Instala el paquete de software.";

  //status message
  content["display a simple status line to show what is being installed (verbose mode)"] =
  "Muestra una línea de estado simple para visualizar los elementos que se están instalado (modo detallado).";

  // display message
  content["display fifty hash marks (#) to show the status as thevinstall proceeds; when all fifty have displayed, installation is complete."] =
  "Muestra cincuenta signos de almohadilla (#) para visualizar el estado de la instalación. Cuando aparecen los cincuenta signos de almohadilla en pantalla, significa que la instalación se ha completado.";

  //RHEL & CentOS
  content["RHEL & CentOS"] =
  "RHEL y CentOS";

  // END OF LOCALIZATION

export default content;
