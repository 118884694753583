import React, { Component, useMemo } from "react";
import {
  ClearIcon as Clear,
  CenterError as Error,
  CenterInfo,
} from "../../ui-components/uim-components";
import { CircularProgress } from "@mineral/core";
import {
  Bar,
  BarChart,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  LabelList,
} from "recharts";
import theme from "./../../../theme";
import isEqual from "lodash/isEqual";
import Resource from "./Resource";
import { Card, IconButton, Button } from "@mineral/core";
import connect from "./../../../utils/connect";
import {
  fetchOpenAlarmspoll,
  pollOpenAlarmsStop,
} from "./../../../api/home-screen/actions";

import AccessibleTooltip from "./../../common/AccessibleTooltip";

//const chartColors = ['#A11D2E', '#F7922F', '#FEE276', '#9EC6D0', '#D9E9EC']
const chartColors = ["#DE1B1B", "#F78F4A", "#F7CD3B", "#80BED9", "#3272D9"];
//Alarm Count
const alarmCountText = Resource.get("Alarm Count");
const Panel = (props) => {

  useMemo(() => {
    if (props?.data) {
      props?.data.map((item, idx) => {
        item.severityNameAlarmCount = `${item.severityName}`

      })
    }
  }, [props?.data])

  const onClick = (e, index) => {
    props.history.push(`/uim-alarms?severity=${5 - index}`);
  };

  const CustomLabel = ({ x, y, stroke, value, width, index }) => {
    try {
      const labelX = x + width / 2;
      return (
        <text
          className="recharts-text"
          onClick={(e) => onClick(e, index)}
          x={labelX}
          y={y}
          dy={-4}
          fill={stroke}
          style={{ fill: chartColors[index], cursor: "pointer" }}
          fontSize={14}
          textAnchor="middle"

        >
          <tspan>{value}</tspan>
        </text>
      );
    } catch (e) {
      return null;
    }
  };

  const CustomAriaLabel = ({ x, y, stroke, value, width, index }) => {


    return (value)


  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="chartTooltip">
           <div>{`${label.split('-')[0]}`}</div>
          <div>{`${alarmCountText} : ${payload[0].value}`}</div>
        </div>
      );
    }

    return null;
  };
  return (
    <div style={{ flex: "90%", height: "95%", width: "100%" }} tabIndex={0} role="img" aria-label={"A bar graph showing alarm count statistics with Severity on X axis and open alarm count on Y axis: " + props?.data?.map(entry => entry?.severityName + " " + alarmCountText + " " + entry?.alarmCount) + " Click on bars to navigate to alarms of a specific severity"}>
      <ResponsiveContainer
        width="100%"
        height="100%"
        style={{ maxHeight: "200px", margin: "16px" }}
        role="figure"
      >
        <BarChart data={props.data} style={{ backgroundColor: "#f4f4f4" }}
        margin={{
          top: 16,
          bottom:16,
        }}
        >
          <Bar
            style={{ backgroundColor: "red" }}
            onClick={onClick}
            tabIndex={0}
            dataKey="alarmCount"
            maxBarSize={70}
            label={<CustomLabel />}

          // label={{
          //   fill: theme.palette.lightBlack,
          // }}
          >
            {/*<LabelList dataKey="alarmCount" position="top" onClick={onClickLabel}/>*/}
            {props.data.map((entry, index) => (
              <Cell
                className={
                  "total-alarm__overview--bar chart__severity--" +
                  entry.severityName.toUpperCase()
                }
                key={`cell-${index}`}
                fill={chartColors[index]}

              />
            ))}
          </Bar>
          <XAxis dataKey="severityNameAlarmCount" axisLine={true} tickLine={false} />

          <YAxis />
          <Tooltip
            isAnimationActive="false"
            cursor={{ fill: "transparent" }}
            content={<CustomTooltip />}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

class PanelContainer extends Component {
  state = {
    status: "loading",
    alarms: [],
    alarmsCount:0
  };

  apiError = () => {
    this.setState({
      status: "failure",
    });
  };

  componentWillUnmount() {
    this.props.pollOpenAlarmsStop();
  }

  componentDidMount() {
    this.props.fetchOpenAlarms(
      this.props.csId ? this.props.csId : null,
      this.props.groupIds ? this.props.groupIds : null,
      this.props.probeId ? this.props.probeId : null
    );
  }

  componentWillReceiveProps(nextProps) {

    let count = nextProps.openAlarms.length? nextProps.openAlarms.reduce((a,b)=>{
      return a+b.alarmCount;
    },0): 0
    this.setState({
      alarms: nextProps.openAlarms,
      status: nextProps.isOpenAlarmsFetching ? "loading" : "complete",
      alarmsCount:count,
    });
  }

  render() {
    let view = (
      <Panel
        className={this.props.className}
        data={this.state.alarms}
        history={this.props.history}
      />
    );
    //Open Alarms
    let title = Resource.get("Open Alarms");
    //No Alarms Found
    let noAlarmsText = Resource.get("No Alarms Found");
    //View All
    let viewAllText = Resource.get("View All");
    let cardHeader = (
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h2 className="overview_container_title">{title}</h2>

        <div style={{ display: "flex", alignItems: "center" }}>
          {!this.props.didOpenAlarmsInvalidate && (
            <AccessibleTooltip title={viewAllText}>
              <Button variant="text" style={{ height: '16px' }}
                id="first-content-element"
                aria-label="Click to view all Open Alarms"
                onClick={() => {
                  this.props.history.push("/uim-alarms");
                }}
              >
                {viewAllText}
              </Button>
            </AccessibleTooltip>
          )}
          {!this.props.didOpenAlarmsInvalidate && (
            <span className="overview_header_seprator" aria-label="Separator">|</span>
          )}
          <AccessibleTooltip title={"Close"}>
            <IconButton
              style={{ padding: 0 }}
              title={"Close"} aria-label="Close Open Alarms"
              onClick={() => {
                this.props.handleCloseDialog("showOpenAlarms");
              }}
            >

              <Clear className="clearIcon" />
            </IconButton>
          </AccessibleTooltip>
        </div>
      </div>
    );

    if (
      this.state.status === "loading" &&
      !this.props.didOpenAlarmsInvalidate
    ) {

      view = (
        <div
          className="no_data_panel"
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100%",
          }}
        >
          <CircularProgress style={{ color: "#3272D9" }} />
        </div>
      );
    } else if (
      this.state.status === "failure" ||
      this.props.didOpenAlarmsInvalidate
    ) {

      view = (
        <div className="no_data_panel" style={{ minHeight:"100%" }}>
          <Error />
        </div>
      );
    } else if (
      this.state.status === "complete" &&
      this.state.alarms.length == 0
    ) {

      view = (
        <div className="no_data_panel" style={{ minHeight:"100%" }}>
          <CenterInfo title={noAlarmsText} />
        </div>
      );
    }
    else if (
      this.state.status === "complete" &&
      this.state.alarms.length != 0 &&
      this.state.alarmsCount === 0
    ) {

      view = (
        <div className="no_data_panel" style={{ minHeight:"100%" }}>
          <CenterInfo title={noAlarmsText} />
        </div>
      );
    }
    return (
      <Card
        className={this.props.className}
        // style={{
        //   height: '100%',
        //   width: null,
        //   margin: null,
        // }}
        style={{
          height: "46vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          padding: "16px",
          boxSizing: "border-box",
        }}
      >
        {cardHeader}
        {view}
      </Card>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchOpenAlarms: (csId, groupIds, probeId) =>
      dispatch(fetchOpenAlarmspoll(csId, groupIds, probeId)),
    pollOpenAlarmsStop: () => dispatch(pollOpenAlarmsStop()),
  };
};
const mapStateToProps = (state) => {
  return {
    openAlarms: state.homeScreen.openAlarms,
    isOpenAlarmsFetching: state.homeScreen.isOpenAlarmsFetching,
    didOpenAlarmsInvalidate: state.homeScreen.didOpenAlarmsInvalidate,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PanelContainer); //connect so that we can get a handle on history
