import React, { Component } from "react";
import isEqual from "lodash/isEqual";
import { ClearIcon as Clear, Error } from "../../ui-components/uim-components";
import {CircularProgress} from "@mineral/core";
import Resource from "./Resource";
import connect from "../../../utils/connect";
import Autocomplete from "@material-ui/lab/Autocomplete";
import SearchIcon from "@material-ui/icons/Search";
import {
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  TableContainer,
  Card,
  CardContent,
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  ListItemText,
  Popover,
  List,
  ListItem,
  ListItemIcon,
  Box,
  Snackbar,
  SnackbarContent,
  Tooltip,
  ClickAwayListener,
  InputAdornment
} from "@mineral/core";
import { getDeviceGroups } from "../../../api/devicegroups/actions";
import capitalize from "lodash/capitalize";
import CustomTooltip from "../../tooltip/CustomTooltip";
import { NavLink } from "react-router-dom";
import groupsApi from "./../../../api/groups/groups";
import { ReactComponent as DeleteIcon } from "./icons/delete-icon.svg";
import { ReactComponent as DeleteDisabledIcon } from "./icons/delete-disable-icon.svg";
import { ReactComponent as CloseContainerIcon } from "./icons/close-container-icon.svg";
import { ReactComponent as OpenContainerIcon } from "./icons/open-container-icon.svg";
import { ReactComponent as CloseArrowIcon } from "./icons/close-arrow-icon.svg";
import { ReactComponent as OpenArrowIcon } from "./icons/open-arrow-icon.svg";
import { ReactComponent as GroupFolderIcon } from "./icons/group-folder-icon.svg";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import CloseIcon from "@material-ui/icons/Close";
import WarningIcon from "@material-ui/icons/Warning";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import cloneDeep from "lodash/cloneDeep";
import get from "lodash/get";
import { Link, TextField, ThemeProvider, theme, Checkbox } from "@mineral/core";
import "../../treeViewNew/treeContainer.less";

function useEventListener(eventName, handler, element = window) {
  const savedHandler = React.useRef()
  React.useEffect(() => {
    savedHandler.current = handler
  }, [handler])
  React.useEffect(() => {
    const isSupported = element && element.addEventListener
    if (!isSupported) return
    const eventListener = (event) => savedHandler.current(event)
    element.addEventListener(eventName, eventListener)
    return () => {
      element.removeEventListener(eventName, eventListener)
    }
  }, [eventName, element])
}
const Panel = (props) => {
  const [open, setOpen] = React.useState(false);
  const [hoverValue, setHoverValue] = React.useState("");

  const onOpen = (value) => {
    setOpen(true);
    setHoverValue(value);
  }
  const onClose = () => {
    setOpen(false);
    setHoverValue("");
  }

  const handler = React.useCallback((e) => {
    if (e.key === 'Escape') {
      onClose();
    }
  }, [])
  useEventListener('keydown', handler)
  const handleDrillDown = (rowNumber, colId) => {
    props.history.push(`/groups/0/${props.data[rowNumber].groupId}/dashboard`);
  };

  const [selectedGroup, setSelectedGroup] = React.useState(null);
  const [searchNameGroup, setSearchNameGroup] = React.useState(false);
  const [searchNameGroupFilter, setSearchNameGroupFilter] = React.useState("");
  const [searchTypeGroupFilter, setSearchTypeGroupFilter] = React.useState([
    "static",
    "dynamic",
  ]);
  const [typeElement, setTypeElement] = React.useState(null);
  const [openRemoveDialog, setOpenRemoveDialog] = React.useState(null);
  const [groupTypes, setGroupTypes] = React.useState(["Static", "Dynamic"]);
  const [openGroupAssign, setOpenGroupAssign] = React.useState(null);
  const [allCurrentGroups, setAllCurrentGroups] = React.useState([]);
  const [textValuePathGroup, setTextValuePathGroup] = React.useState("");
  const [currentTree, setCurrentTree] = React.useState({ children: null });

  const assignElement = React.useRef(null);

  React.useEffect(() => {
    setOpenGroupAssign(null);
    if (props.data.length && props.data[0].groupType) {
      const newTypesGroups = Array.from(
        new Set(
          props.data.map((row) =>
            row.groupType.replace(/\w\S*/g, function (txt) {
              return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            })
          )
        )
      );
      setGroupTypes(newTypesGroups);
      setSearchTypeGroupFilter(
        newTypesGroups.map((type) => type.toLowerCase())
      );
    } else {
      setGroupTypes(["Static", "Dynamic"]);
      setSearchTypeGroupFilter(["static", "dynamic"]);
    }
  }, [props.data]);

  React.useEffect(() => {
    setOpenGroupAssign(null);
    setCurrentTree(props.treeGroups);
    setTextValuePathGroup("");
  }, [props.treeGroups]);

  React.useEffect(() => {
    setOpenGroupAssign(null);
    setAllCurrentGroups(
      props.allGroups.map((group) => ({
        ...group,
        open: false,
      }))
    );
  }, [props.allGroups]);

  const handleKeypress = (e,allCurrentGroups, isOpen, indexCurrentGroup) => {
    //it triggers by pressing the enter key
    if (e.keyCode === 13) {
      handleOnClick(allCurrentGroups, isOpen, indexCurrentGroup);
    }
  };
  const handleOnClick = (allCurrentGroups, isOpen, indexCurrentGroup) => {
    const newCurrentGroups = cloneDeep(allCurrentGroups);
    newCurrentGroups[indexCurrentGroup].open = !isOpen;
    setAllCurrentGroups(newCurrentGroups);
  }
  const handleKeyDownOnSeletecItem = (e,group) => {
    //it triggers by pressing the enter key
    if (e.keyCode === 13) {
      handleOnSelectItem(group);
    }
  };
  const handleOnSelectItem = (group) => {
    setSelectedGroup(group);
    setTextValuePathGroup(group.path);
    setOpenGroupAssign(null);
    setCurrentTree(props.treeGroups);
  }

  const getTreeComponent = (groupsLevel) => {
    return groupsLevel.map((group) => {
      const indexCurrentGroup = allCurrentGroups.findIndex(
        (currentGroup) => +currentGroup.id === +group.id
      );
      if (indexCurrentGroup !== -1 && !group.masterElementGroup) {
        const isOpen = allCurrentGroups[indexCurrentGroup].open;
        return group.type === "CONTAINER" ? (
          <div>
            <div
              onKeyDown={(e) => handleKeypress(e,allCurrentGroups,isOpen,indexCurrentGroup)}
              tabIndex={0}
              autoFocus={true}
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "15px",
                cursor: "pointer",
              }}
              onClick={() => {
                handleOnClick(allCurrentGroups, isOpen, indexCurrentGroup);
                // const newCurrentGroups = cloneDeep(allCurrentGroups);
                // newCurrentGroups[indexCurrentGroup].open = !isOpen;
                // setAllCurrentGroups(newCurrentGroups);
              }}
            >
              {isOpen ? (
                <>
                <Tooltip title={"Collapse Group"}>
                  <OpenArrowIcon />
                  </Tooltip>
                  <OpenContainerIcon
                    style={{ marginRight: "8px", marginLeft: "5px" }}
                  />
                </>
              ) : (
                <>
                <Tooltip title={"Expand Group"}>
                  <CloseArrowIcon />
                  </Tooltip>
                  <CloseContainerIcon
                    style={{ marginRight: "8px", marginLeft: "5px" }}
                  />
                </>
              )}
              <span style={{ color: "#58606E" }}>
                {group.name + ` (${group.children.length})`}
              </span>
            </div>
            {isOpen ? (
              <div style={{ paddingLeft: "12px" }}>
                {getTreeComponent(group.children)}
              </div>
            ) : null}
          </div>
        ) : (
          <div
          onKeyDown={(e)=>handleKeyDownOnSeletecItem(e,group)}
            tabIndex={0}
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "20px",
              cursor: "pointer",
            }}
            autoFocus={true}
            onClick={() => {
              handleOnSelectItem(group)
              // setSelectedGroup(group);
              // setTextValuePathGroup(group.path);
              // setOpenGroupAssign(null);
              // // if (group.path.length >= 3) {
              // //   const newTree = { children: [] };
              // //   props.treeGroups.children.forEach((currentGroup) => {
              // //     getTreeWithSearchText(currentGroup, newTree, group.path);
              // //   });
              // //   setCurrentTree(newTree);
              // // }
              // setCurrentTree(props.treeGroups);
            }}
          >
            <GroupFolderIcon style={{ marginRight: "8px" }} />
            <span
              style={{
                color: "#58606E",
                fontWeight:
                  selectedGroup && +selectedGroup.id === +group.id
                    ? "bold"
                    : "normal",
                maxWidth: "300px",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {group.name}
            </span>
          </div>
        );
      } else {
        return null;
      }
    });
  };

  const getTreeWithSearchText = (group, newTree, text) => {
    const searchName = text.includes("/") ? group.path : group.name;
    if (searchName.toLowerCase().includes(text.toLowerCase())) {
      newTree.children.push(group);
    } else {
      group.children.forEach((currentGroup) => {
        getTreeWithSearchText(currentGroup, newTree, text);
      });
    }
  };

  if (!Array.isArray(props.data)) return null;
  let close = Resource.get("Close");//Close
  let cancel = Resource.get("Cancel");//Cancel
  let assignGroup = Resource.get("Assign Group");//Assign Group
  let groupName = Resource.get("Group Name");//Group Name
  let groupType = Resource.get("Type");//Type
  let groupActions = Resource.get("Actions");//Actions
  let closeIcon = Resource.get("Close Icon");//Close Icon
  let assignee_groupType_dialog = Resource.get("Assign Group Type Dialog") //Assign Group Type Dialog
  const modal = (
    <Dialog
     
      open={props.openedDialog}
      onClose={() => {
        if(openGroupAssign != null){
          setOpenGroupAssign(null);
        }
        else{
        setSelectedGroup(null);
        setTextValuePathGroup("");
        props.handleCloseDialog(null);
        }
      }}
      
      PaperProps={{ style: { position: "static" },  "aria-label": assignee_groupType_dialog}}
    >
     
      <DialogTitle > 
      
      
      {"Assign a Group"}
         
       
      </DialogTitle>
      <div style={{height:'0px'}}>
      <Tooltip arrow title={close} placement="top">
            <IconButton style={{marginRight:'8px',top:'-50px',float:'right'}}
              autoFocus
              onClick={() => {
                setSelectedGroup(null);
                setTextValuePathGroup("");
                props.handleCloseDialog(null);
              }}>
              <Clear role="figure" />
            </IconButton>
          </Tooltip>
      </div>
      <DialogContent>
        <DialogContentText
         
        >
          Select an existing static group.
        </DialogContentText>
        <div style={{ display: "flex", flexFlow: "column nowrap" }}>
          <InputLabel
            id="group-label-dialog"
           
          >
            Groups
          </InputLabel>
          {/* <Select
          value={groupId}
          onChange={(event) => {
            setGroupId(event.target.value);
          }}
          displayEmpty
          variant="outlined"
          disabled={!props.groups.length}
          inputProps={{ "aria-labelledby": "group-label-dialog" }}
          style={{ width: "450px", height: "40px" }}
        >
          {props.groups.map((row) => (
            <MenuItem value={row.id}>{row.name}</MenuItem>
          ))}
        </Select> */}
          {/* <Autocomplete
          disabled={!props.groups.length}
          options={props.groups}
          style={{ width: "450px" }}
          getOptionLabel={(option) => option.name}
          value={selectedGroup}
          // inputProps={{ "aria-labelledby": "group-label-dialog" }}
          onChange={(event, group) => {
            setSelectedGroup(group);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder={"Enter or select from below"}
            />
          )}
        /> */}
          <ClickAwayListener
            onClickAway={(event) => {
              setOpenGroupAssign(null);
            }}
          >
            <div style={{ position: "static" }}>
              <TextField
               sx={{
      input: {
        
         "&::placeholder": {    // <----- Add this.
            color: 'black !important',
            opacity:0.8
           
         },
      },
      
   }}
                className="assign-group-device"
                style={{ width: "450px" }}
                variant="outlined"
                placeholder={"Enter or select from below"}
                value={textValuePathGroup}
                onFocus={(event) => {
                  setOpenGroupAssign(assignElement.current);
                }}
                onChange={(event) => {
                  if (!openGroupAssign) {
                    setOpenGroupAssign(assignElement.current);
                  }
                  setTextValuePathGroup(event.target.value);
                  const selectedGroup = allCurrentGroups.find(
                    (currentGroup) =>
                      currentGroup.type !== "CONTAINER" &&
                      currentGroup.path === event.target.value
                  );
                  if (event.target.value.length >= 3) {
                    const newTree = { children: [] };
                    props.treeGroups.children.forEach((group) => {
                      getTreeWithSearchText(group, newTree, event.target.value);
                    });
                    setCurrentTree(newTree);
                  } else {
                    setCurrentTree(props.treeGroups);
                  }
                  if (selectedGroup) {
                    setSelectedGroup(selectedGroup);
                  } else {
                    setSelectedGroup(null);
                  }
                }}
                autoFocus={false}
                ref={assignElement}
              />
              {/* <ArrowDropUpIcon
              style={{
                position: "absolute",
                right: assignElement.current
                  ? assignElement.current.getBoundingClientRect().left + 300
                  : 0,
                top: assignElement.current
                  ? assignElement.current.getBoundingClientRect().top + 8
                  : 0,
                cursor: "pointer",
              }}
              onClick={(event) => {
                setOpenGroupAssign(assignElement.current);
                event.stopPropagation();
                event.preventDefault();
              }}
            /> */}
              {Boolean(openGroupAssign) ? (
                <div className="GroupAssignDropdown"
                  style={{
                    position: "absolute",
                    height: "280px",
                    width: "415px",
                    overflow: "auto",
                    padding: "0 18px 13px",
                    top:
                      openGroupAssign.getBoundingClientRect().top +
                      openGroupAssign.getBoundingClientRect().height,
                    left: openGroupAssign.getBoundingClientRect().left,
                    boxShadow:
                      "0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)",
                    backgroundColor: "#fff",
                    borderRadius: "4px",
                    color: "rgba(0, 0, 0, 0.87)",
                    zIndex: "10"
                  }}
                >
                  {getTreeComponent(currentTree.children)}
                </div>
              ) : null}
            </div>
          </ClickAwayListener>
          {/* <Popover
          open={Boolean(openGroupAssign)}
          anchorEl={openGroupAssign}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          disableAutoFocus={true}
          disableEnforceFocus={true}
          onClose={() => setOpenGroupAssign(null)}
        >
          <ClickAwayListener
            onClick={() => console.log("onClick")}
            onClickAway={(event) => {
              console.log("onClickAway");
              console.log(event.srcElement);
              // setOpenGroupAssign(null);
            }}
          >
            <div
              style={{
                height: "280px",
                minWidth: "415px",
                overflow: "auto",
                padding: "0 18px 13px",
              }}
            >
              {getTreeComponent(props.treeGroups.children)}
            </div>
          </ClickAwayListener>
        </Popover> */}
        </div>
      </DialogContent>
      <DialogActions >
        <Tooltip arrow title={cancel} placement="top-start">
          <Button 
            variant="outlined"
            label={cancel}
            onClick={() => {
              setSelectedGroup(null);
              setTextValuePathGroup("");
              props.handleCloseDialog(null);
            }}
            
            color="primary"
            onFocus={(event) => {
              setOpenGroupAssign(null);
            }}
          >
            {cancel}
          </Button>
        </Tooltip>
        <Tooltip arrow title={assignGroup} placement="top-start">
          <Button  autoFocus
            name={assignGroup}
            label={assignGroup}
            onClick={() => {
              props.handleCloseDialog({ ...selectedGroup });
              setSelectedGroup(null);
              setTextValuePathGroup("");
            }}
            
            onFocus={(event) => {
              setOpenGroupAssign(null);
            }}
            variant="contained"
            disabled={!selectedGroup}
            color="primary"
           
          >
            {assignGroup}
          </Button>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
  
  if (props.data.length === 0 || !props.data[0].groupType) {
    //No Groups found
    let noGroupsFound = Resource.get("No Groups found");
    return (
      <>
        <CardContent
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "20px",
            backgroundColor: "#f4f4f4",
            color: "#5B676C",
            overflow: "auto",
            height: "72%",
          }}
        >
          <div>{noGroupsFound}</div>
        </CardContent>
        {modal}
      </>
    );
  }
  //Search
  let search = Resource.get("Search");
  //Search Icon
  let searchIcon = Resource.get("Search Icon");
  //Group Name Search Icon
  let groupNameSearchIcon = Resource.get("Group Name Search Icon");
  //Delete Disabled Icon
  let deleteDisabledIcon = Resource.get("Delete Disabled Icon");
  //Delete
  let deleteButton = Resource.get("Delete");
  //Device Group Delete Button
  let deviceGroupDeleteButton = Resource.get("Device Group Delete Button");
  return (
    <CardContent
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "0 2px 2px",
        backgroundColor: "#f4f4f4",
        color: "#5B676C",
        position: "absolute",
        height: "calc(100% - 50px)",
        width: "100%",
        boxSizing: "border-box",
        bottom: 0,
        left: 0,
        overflow: "auto",
        borderTop: "1px solid #dde3ed",
      }}
      className="devicegroup_grid"
    >
      {/* <div className="devicegroup_grid" style={{ position: "relative" }}>
        <TableContainer
          style={{
            height: "200px",
            position: "absolute",
            top: 0,
            left: 0,
            height: "100%",
          }}
        > */}
      <Table
        // height={
        //   window.innerHeight < 800
        //     ? "17vh"
        //     : window.innerHeight == 800
        //     ? "20vh"
        //     : "22vh"
        // }
        style={{ backgroundColor: "rgb(255, 255, 255)" }}
        onCellClick={handleDrillDown}
        stickyHeader
      >
        <TableHead>
          <TableRow>
            <Tooltip arrow open={hoverValue === groupName && open} onOpen={() => onOpen(groupName)} onClose={onClose} title={groupName} placement="top-start">
              <TableCell
                className="column-header"
                //tooltip={"Group Name"}
                height={30}>
                {!searchNameGroup ? (
                  <div
                    role = "column"
                    title= {groupName}
                    aria-label = {groupName}
                  >
                    <span style={{display: "flex",columnGap: "10px"}}>
                      <span>{groupName}</span>
                      <span>
                        <button  style={{
                          display: "flex",
                          justifyContent: "space-between",
                          cursor: "pointer",
                          background:"none",
                          border:"none",
                          position: "absolute",
                          marginLeft: "10px"
                        }}
                          role = "button" title={search} aria-label ={search} name={search} tabIndex={0} onClick={() => setSearchNameGroup(true)}>
                          <SearchIcon role = "figure" alt ={groupNameSearchIcon} title={searchIcon} aria-label ={searchIcon} />
                        </button>
                      </span>
                    </span>
                  </div>
                ) : (
                  <div style={{ width: "100%",display:"inline-flex" }} aria-label = {search}>
                    <TextField
                      id="search-group-name-label"
                      placeholder="Search a group name..."
                      label={search}
                      title={search}
                      hideLabel
                      autoFocus
                      style={{ width: "100%", height: "30px" }}
                      value={searchNameGroupFilter}
                      onChange={(event) =>
                        setSearchNameGroupFilter(event.target.value)
                      }
                      onBlur={() => {
                        if (!searchNameGroupFilter) {
                          setSearchNameGroup(false);
                        }
                      }}
                    />
                    <SearchIcon
                      role = "figure" alt ={groupNameSearchIcon} title={searchIcon} aria-label ={searchIcon}
                      style={{ position: "absolute", left: 12,top:21 }}
                    />
                    {searchNameGroupFilter ? (
                      <IconButton tabIndex={0} style={{display: "inline-table"}}>
                        <CloseIcon
                          role = "button"
                          title = {closeIcon}
                          style={{
                            position: "absolute",
                            right: 5,
                            cursor: "pointer",
                          }}
                          onClick={() => setSearchNameGroupFilter("")}
                        />
                      </IconButton>
                    ) : null}
                    {/* <TextField
                    variant="outlined"
                    autoFocus
                    style={{ width: "100%" }}
                    onChange={(event) =>
                      setSearchNameGroupFilter(event.target.value)
                    }
                    onBlur={() => {
                      if (!searchNameGroupFilter) {
                        setSearchNameGroup(false);
                      }
                    }}
                    placeholder="Search a group name..."
                  />
                  <SearchIcon
                    style={{ position: "absolute", right: 12, top: 12 }}
                  /> */}
                  </div>
                )}
              </TableCell>
            </Tooltip>
            <Tooltip arrow open={hoverValue === groupType && open} onOpen={()=> onOpen(groupType)} onClose={onClose} title={groupType} placement="top-start">
              <TableCell
                className="column-header"
                //tooltip={"Type"}
                align="center"
                width={100}
              >
                {/* <FormControl variant="standard">
                <InputLabel id="select-type-label">Type</InputLabel>
                <Select
                  style={{ width: "90px" }}
                  labelId="select-type-label"
                  value={searchTypeGroupFilter}
                  onChange={(event) =>
                    setSearchTypeGroupFilter(event.target.value)
                  }
                  label="Type"
                  disableUnderline
                >
                  <MenuItem value={""}>All</MenuItem>
                  <MenuItem value={"static"}>Static</MenuItem>
                  <MenuItem value={"dynamic"}>Dynamic</MenuItem>
                </Select>
              </FormControl> */}
                <Button
                  role = "listbox"
                  title={groupType}
                  className="TypeElementButton"
                  onClick={(event) => setTypeElement(event.target)}
                >
                  <span
                    style={{
                      fontSize: "0.875rem",
                      fontWeight: "bold",
                    }}
                  >
                    {groupType}
                  </span>
                  <ArrowDropDownIcon />
                </Button>
                <Popover
                  open={Boolean(typeElement)}
                  anchorEl={typeElement}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  onClose={() => setTypeElement(null)}
                  className="popover-groupType-table"
                >
                  <List>
                    {groupTypes.map((type) => (
                      <ListItem
                        key={type}
                        onClick={() => {
                          const newTypes = [...searchTypeGroupFilter];
                          const index = newTypes.indexOf(type.toLowerCase());
                          if (index !== -1) {
                            newTypes.splice(index, 1);
                          } else {
                            newTypes.push(type.toLowerCase());
                          }
                          setSearchTypeGroupFilter(newTypes);
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={searchTypeGroupFilter.includes(
                              type.toLowerCase()
                            )}
                            aria-labelledby={`${type.label}`}
                            color="primary"
                            style={{ marginLeft: "5px" }}
                          />
                        </ListItemIcon>
                        <ListItemText id={`${type.label}`} primary={type} />
                      </ListItem>
                    ))}
                  </List>
                </Popover>
              </TableCell>
            </Tooltip>
            <Tooltip arrow  open={hoverValue === groupActions && open} onOpen={()=> onOpen(groupActions)} onClose={onClose} title={groupActions} placement="top-start">
              <TableCell className="column-header" align="center" width={90}>
                {groupActions}
              </TableCell>
            </Tooltip>
          </TableRow>
        </TableHead>
        <TableBody stripedRows={false} displayRowCheckbox={false}>
          {props.data
            .filter(
              (row) =>
                row.groupName
                  .toLowerCase()
                  .includes(searchNameGroupFilter.toLowerCase()) &&
                (row.groupType
                  ? searchTypeGroupFilter.includes(row.groupType.toLowerCase())
                  : true)
            )
            .map((row, index) => (
              <TableRow key={row.groupId} className="technologies-row">
                <TableCell className="technologies_grid_col_name">
                  <Tooltip arrow open={hoverValue === `${row.groupName}` && open} onOpen={()=> onOpen(`${row.groupName}`)} onClose={onClose} title={`${row.groupName}`} placement="top-start">
                    <NavLink
                      to={`/groups/0/${row.groupId}`}
                      className="link"
                      activeclassname="link--active"
                    >
                      {/* <CustomTooltip message={row.groupName}>
                    </CustomTooltip> */}
                      <Link>{row.groupName}</Link>
                    </NavLink>
                  </Tooltip>
                </TableCell>
                <TableCell style={{ paddingLeft: "12px" }}>
                  {row.groupType
                    ? row.groupType.replace(/\w\S*/g, function (txt) {
                      return (
                        txt.charAt(0).toUpperCase() +
                        txt.substr(1).toLowerCase()
                      );
                    })
                    : null}
                </TableCell>
                <TableCell align="center">
                  {row.groupType &&
                    row.groupType === "STATIC" &&
                    props.canRemove ? (
                    <IconButton
                      className="DeviceGroupDeleteButton"
                      role = "button"
                      title={deleteButton}
                      name={deleteButton}
                      aria-label={deleteButton}
                      onClick={() => {
                        setOpenRemoveDialog(row);
                      }}
                    >
                      <DeleteIcon className="DeviceGroupDeleteButton" style={{color: "#707A8A !important"}} />
                    </IconButton>
                  ) : (
                    <Tooltip
                      open={hoverValue === `${row.groupId}` && open} onOpen={()=> onOpen(`${row.groupId}`)} onClose={onClose}
                      title={
                        props.canRemove
                          ? `You cannot remove this device from a ${row.groupType.toLowerCase()} group.`
                          : Resource.get("You do not have permissions to remove this group")
                      }
                    >
                      <IconButton className="DeviceGroupDeleteButton" name= {deviceGroupDeleteButton} aria-label = {deviceGroupDeleteButton} title= {deviceGroupDeleteButton} role = "button">
                        <DeleteDisabledIcon role = "figure" alt = {deleteDisabledIcon} name= {deleteDisabledIcon} aria-label = {deleteDisabledIcon} title= {deleteDisabledIcon} className="DeviceGroupDeleteButton" style={{color: "#707A8A !important" }} />
                      </IconButton>
                    </Tooltip>
                  )}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      {/* </TableContainer>
      </div> */}
      {modal}
      <Dialog
        open={Boolean(openRemoveDialog)}
        onClose={() => setOpenRemoveDialog(null)}
        className="assignee-groupType-dialog"
      >
        <DialogTitle style={{ paddingBottom: 0 }}>
          <Box display="flex" alignItems="center">
            <Box flexGrow={1}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <WarningIcon
                  style={{ color: "#DE1B1B", marginRight: "10px" }}
                />
                <span style={{ fontWeight: 600 }}>{"Confirm Removal"}</span>
              </div>
            </Box>
            <Box>
              <IconButton
                style={{ marginRight: "-20px" }}
                onClick={() => setOpenRemoveDialog(null)}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent style={{ paddingTop: 0 }}>
          <DialogContentText
            style={{ fontSize: "16px", fontWeight: "400", color: "#333840" }}
          >
            <p>
              Do you want to remove{" "}
              <span style={{ fontWeight: "600" }}>{props.dvcInfo.name}</span>{" "}
              from{" "}
              <span style={{ fontWeight: "600" }}>
                {openRemoveDialog ? openRemoveDialog.groupName : null}
              </span>
              ?
            </p>
            <p>This operation is irreversible.</p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ color: "#999999" }}
            onClick={() => setOpenRemoveDialog(null)}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              const currentGroup = { ...openRemoveDialog };
              setOpenRemoveDialog(null);
              groupsApi
                .deleteDevicesGroup([props.csId], +currentGroup.groupId)
                .then(() => {
                  props.getDeviceGroups(props.csId);
                  props.openSnackbar(
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <CheckCircleIcon
                        style={{ color: "#2A854E", marginRight: "10px" }}
                      />
                      <p style={{ color: "black", margin: 0 }}>
                        <span style={{ fontWeight: "600" }}>
                          {props.dvcInfo.name}
                        </span>{" "}
                        successfully removed from{" "}
                        <span style={{ fontWeight: "600" }}>
                          {currentGroup.groupName}
                        </span>
                        .
                      </p>
                    </div>
                  );
                });
            }}
            autoFocus
            variant="contained"
            style={{ backgroundColor: "#DE1B1B" }}
          >
            Remove
          </Button>
        </DialogActions>
      </Dialog>
    </CardContent>
  );
};

class DeviceGroups extends Component {
  constructor(props) {
    super(props);
    this.state = {
      failMsg: "",
      openedDialog: false,
      allGroups: [],
      disableAddGroupButton: false,
      snackbarMessage: null,
      treeGroups: {
        id: "0",
        name: "Groups",
        type: "CONTAINER",
        children: [],
        path: "",
      },
      open:false,
      hoverValue:"",
    };
  }
  handler = (e) => {
    if (e.key === 'Escape') {
      this.onClose();
      window.removeEventListener('keydown',this.handler)
    }
  }
  onOpen = (value) => {
    this.setState({ open:true,hoverValue:value });
    window.addEventListener('keydown',this.handler)
  }
  onClose = () => {
    this.setState({ open:false,hoverValue:"" });
  }
  componentDidMount() {
    this.props.getDeviceGroups(this.props.csId);
  }
  componentWillReceiveProps(nextProps) {
    if (!isEqual(this.props.csId, nextProps.csId)) {
      this.props.getDeviceGroups(nextProps.csId);
    }
  }

  handleCloseDialogGroup(group) {
    if (group) {
      groupsApi.addDevicesGroup([this.props.csId], +group.id).then(() => {
        this.props.getDeviceGroups(this.props.csId);
        this.openSnackbar(
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <CheckCircleIcon
              style={{ color: "#2A854E", marginRight: "10px" }}
            />
            <p style={{ color: "black", margin: 0 }}>
              <span style={{ fontWeight: "600" }}>
                {this.props.dvcInfo.name}
              </span>{" "}
              successfully assigned to{" "}
              <span style={{ fontWeight: "600" }}>{group.name}</span>.
            </p>
          </div>
        );
      });
    }
    this.setState({ openedDialog: false },document.getElementById("assignGroupButton")?.focus());

  }

  openSnackbar(message) {
    this.setState({
      snackbarMessage: message,
    });
  }

  createTree(group, allGroups, pathGroups) {
    if (group.type === "CONTAINER") {
      group.children = allGroups
        .filter((currentGroup) => +currentGroup.parentId === +group.id)
        .sort((currentGroup, nextGroup) =>
          currentGroup.name.localeCompare(nextGroup.name)
        )
        .map((currentGroup) => ({
          ...currentGroup,
          path:
            group.path +
            (currentGroup.type === "CONTAINER"
              ? currentGroup.name + "/"
              : currentGroup.name),
        }));
      group.children.forEach((childrenGroup) => {
        this.createTree(childrenGroup, allGroups, pathGroups);
      });
    }
    pathGroups.push(group);
  }

  render() {
    const deviceGroups = this.props.deviceGroups;

    const canModifyGroup = get(this.props.acl.aclInfo, "roles", "").includes(
      "USM Group Modification"
    );
    const canEditGroup = get(this.props.acl.aclInfo, "roles", "").includes(
      "OC Group Edit"
    );

    let view = (
      <Panel
        className={this.props.className}
        data={deviceGroups}
        history={this.props.history}
        isBusUser={this.props.acl.isBusUser}
        treeGroups={this.state.treeGroups}
        handleCloseDialog={this.handleCloseDialogGroup.bind(this)}
        openSnackbar={this.openSnackbar.bind(this)}
        canRemove={canModifyGroup || canEditGroup}
        groups={this.state.allGroups.filter(
          (currentGroup) =>
            currentGroup.type === "STATIC" &&
            !deviceGroups.find(
              (deviceGroup) => deviceGroup.groupId === +currentGroup.id
            )
        )}
        {...this.state}
        {...this.props}
      />
    );

    //Device Groups
    let title = Resource.get("Device Groups");
    let assignGroup = Resource.get("Assign Group")//Assign Group
    const disableAssignGroup =
      this.state.disableAddGroupButton || (!canModifyGroup && !canEditGroup);

    let cardHeader = (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "0 16px 8px",
          zIndex: 100,
        }}
        className="overview_container-header"
      >
        <h2
          className="overview_container_title"
          style={{ paddingRight: "5px", fontSize: "18px" }}
        >
          {title}
        </h2>
        <Tooltip arrow open={this.state.hoverValue === assignGroup && this.state.open} onOpen={() => this.onOpen(assignGroup)} onClose={this.onClose} title={assignGroup} placement="top-start">
          <Button id="assignGroupButton"
            variant="outlined"
            label={assignGroup}
            className="AssignGroupButton"
            children={assignGroup}
            color="primary" 
            disabled={disableAssignGroup}
            style={{
              height: "32px",
              color: !disableAssignGroup ? "#3A73B7" : "#8E99AB",
              fontWeight: "unset !important",
            }}
            onClick={() => {
              //this.setOpenGroupAssign(null);
              this.setState({ disableAddGroupButton: true });
              groupsApi
                .getAllGroups()
                .then((response) => {
                  const parentGroup = {
                    id: "0",
                    name: "Groups",
                    type: "CONTAINER",
                    children: [],
                    path: "",
                  };
                  const sortGroups = response.data._items.filter(
                    (item) =>
                      item.type &&
                      (item.type === "CONTAINER" || item.type === "STATIC") &&
                      !deviceGroups.find(
                        (deviceGroup) => deviceGroup.groupId === +item.id
                      )
                  );
                  const pathGroups = [];
                  this.createTree(parentGroup, sortGroups, pathGroups);
                  this.setState({
                    openedDialog: true,
                    allGroups: pathGroups,
                    disableAddGroupButton: false,
                    treeGroups: parentGroup,
                  });
                })
                .catch(() => {
                  this.setState({
                    disableAddGroupButton: false,
                  });
                });
            }}
          />
        </Tooltip>
      </div>
    );

    if (this.state.status === "loading") {
      view = (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress style={{ color: "#3272D9" }} />
        </div>
      );
    } else if (this.state.status === "failure") {
      view = <Error />;
    }
    
    return (
      <ThemeProvider theme={theme}>
        <Card
          className={this.props.className}
          style={{
            height: "20vh",
            display: "flex",
            flexDirection: "column",
            padding: "8px 0 0",
            border: "1px solid #DDE3ED",
            position: "relative",
          }}
        >
          {cardHeader}
          {view}
          <Snackbar
            open={Boolean(this.state.snackbarMessage)}
            autoHideDuration={4000}
            onClose={() => this.setState({ snackbarMessage: null })}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <SnackbarContent
              style={{
                backgroundColor: "white",
              }}
              message={this.state.snackbarMessage}
            />
          </Snackbar>
        </Card>
      </ThemeProvider>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getDeviceGroups: (csId) => dispatch(getDeviceGroups(csId)),
  };
};
const mapStateToProps = (state) => {
  return {
    acl: state.acl,
    deviceGroups: state.devicegroups.dvcGroupsInfo,
    status: state.devicegroups.status,
    dvcInfo: state.devices.dvcInfo,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(DeviceGroups);
