import React from 'react'
import IntegrationCard from './../../integrations/IntegrationCard'
import connect from './../../../../utils/connect'
import { get } from './../../../../api/close-rules/actions'
import AngleArrowIcon, { TYPE_ANGLE_RIGHT } from './../../../icons/AngleArrowIcon'
import {Tooltip} from '@mineral/core';

const timeDurationLabel = [
  {
    value: 1,
    label: '1 hour',
  },
  {
    value: 12,
    label: '12 hours',
  },
  {
    value: 24,
    label: '24 hours',
  },
  {
    value: 72,
    label: '3 days',
  },
  {
    value: 168,
    label: '1 week',
  },
  {
    value: 336,
    label: '2 weeks',
  },
  {
    value: 720,
    label: '1 month',
  },
  {
    value: 2160,
    label: '3 months',
  },
  {
    value: -1,
    label: 'Never',
  },
]

class CloseRuleCard extends IntegrationCard {
  componentDidMount() {
    this.props.fetchConfig()
  }

  getIcon() {
    return (
      <AngleArrowIcon
        size="36px"
        type={TYPE_ANGLE_RIGHT}
        style={{ width: '36px', height: '36px', fillOpacity: '.54' }}
      />
    )
  }

  getSubLabelTwo = () => {
    if (this.props.timeDuration !== '' && this.props.timeDuration !== undefined) {
      var label
      for (var i = 0; i < timeDurationLabel.length; i++) {
        if (timeDurationLabel[i]['value'] === this.props.timeDuration) {
          label = timeDurationLabel[i]['label']
        }
      }
      return 'Currently set to ' + label
    } else {
      return ''
    }
  }
}

const TextLogo = ({ label }) => {
  return (
    <Tooltip title={label}>
    <span className="settings-text-logo">
      {label}
    </span>
    </Tooltip>
  )
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchConfig: () => dispatch(get()),
  }
}

const mapStateToProps = state => {
  return {
    timeDuration: state.closeRule.config.timeDuration,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CloseRuleCard)

CloseRuleCard.defaultProps = {
  logo: <TextLogo label="Close Rule" />,
  subLabelOne: 'Policy for closing non-threshold alarms',
  subLabelTwo: '<>',
  configRoute: '/settings/close-rules',
}
