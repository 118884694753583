// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //No Errors
  content["No Errors"] =
  "Ningún error";

  //Errors
  content["Errors"] =
  "Errores";

  // END OF LOCALIZATION

export default content;
