import React, {Component} from 'react'
import connect from './../../utils/connect'
import {setBreadCrumbs} from './../breadcrumbs/actions'
import {fetchActiveProbes} from '../../api/active-probes/actions'
import Dashboards from './Dashboards'
import {CircularProgress} from '@mineral/core';
import {CenterError as Error, BulletPointIcon as BulletPoint, Paginate} from '../ui-components/uim-components'
import fetchStatusTypes from '../../api/fetchStatusTypes'
import {fetchDashboardPaths, fetchDashboardRouteMap} from '../../api/dashboard/actions'
import {fetchUserDashboards,deleteUserDashboard,updateUserDashboard,resetUserDashboardsStore} from '../../api/userdashboards/actions'
import isEqual from "lodash/isEqual";
import Resource from "./Resource";
import {  
  Snackbar
} from "@mineral/core";

class DashboardsRedux extends Component {

  constructor(props){
    super(props);
    this.state={ 
    userDashboardDeleteStatus:{status:'none',userdashboardId:0,dashboardname:''},
    userDashboardUpdateStatus:{status:'none',userdashboard:{},dashboardname:''},
    showFloatingMessage:false,
    floatingMessageValue:"",
              }
  }
  componentWillMount() {
    // Id like to make this a stateless functional react component, but when I do that and then
    // invoke fetchActiveProbes() we end up in an infinite loop, as fetchActiveProbes() triggers
    // an action to make an API call, which then causes this page to re render.
    // Is there another location where it would be more appropriate to invoke this call? If so then
    // we could convert this to stateless
    this.props.fetchActiveProbes();
    this.props.resetUserDashboardsStore();
    this.props.fetchUserDashboards();
    this.props.fetchDashboardRouteMap()
  }
  componentWillReceiveProps(nextProps){
   
    if(!isEqual(this.state.userDashboardDeleteStatus, nextProps.userdashboard.userDashboardDeleteStatus))
    {  

      this.setState({userDashboardDeleteStatus:nextProps.userdashboard.userDashboardDeleteStatus});
      if(nextProps.userdashboard.userDashboardDeleteStatus.status=='success')
      {
        let message=Resource.get("Successfully deleted user dashboard")+" "+nextProps.userdashboard.userDashboardDeleteStatus.dashboardname; //successfuldelete
        this.setState({showFloatingMessage:true,floatingMessageValue:message})
        this.props.fetchUserDashboards();

      }

      if(nextProps.userdashboard.userDashboardDeleteStatus.status=='failure')
      {
        let message=Resource.get("Failed to delete user dashboard")+" "+nextProps.userdashboard.userDashboardDeleteStatus.dashboardname//faileddelete
        this.setState({showFloatingMessage:true,floatingMessageValue:message})
      }

    }
    if(!isEqual(this.state.userDashboardUpdateStatus, nextProps.userdashboard.userDashboardUpdateStatus))
    {  

      this.setState({userDashboardUpdateStatus:nextProps.userdashboard.userDashboardUpdateStatus});
      if(nextProps.userdashboard.userDashboardUpdateStatus.status=='success')
      {
        let message=Resource.get("Successfully updated user dashboard")+" "+nextProps.userdashboard.userDashboardUpdateStatus.dashboardname; //successfulupdate
        this.setState({showFloatingMessage:true,floatingMessageValue:message})
        this.props.fetchUserDashboards();

      }

      if(nextProps.userdashboard.userDashboardUpdateStatus.status=='failure')
      {
        let message=Resource.get("Failed to update user dashboard")+" "+nextProps.userdashboard.userDashboardUpdateStatus.dashboardname//failedupdate
        this.setState({showFloatingMessage:true,floatingMessageValue:message})
      }

    }
  }
  onFloatingMessageClose=()=>{

    this.setState({showFloatingMessage:false,floatingMessageValue:""});
  }

  render() {
    const fetchStatus = this.props.activeProbes.fetchStatus
    let view
    switch (fetchStatus) {
      case fetchStatusTypes.COMPLETE:
        view = <Dashboards activeProbes={this.props.activeProbes.items} setBreadCrumbs={this.props.setBreadCrumbs} saas={this.props.saas}
         dashboardPaths={this.props.dashboardPaths} fetchDashboardPaths ={this.props.fetchDashboardPaths}  userdashboards={this.props.userdashboards}
         sid={this.props.sid} history={this.props.history}
        />
        break
      case fetchStatusTypes.FAIL:
        view = <Error />
        break
      case fetchStatusTypes.FETCHING:
      default:
        view = view = <div style={{
            height: 'calc(100vh - 100px)',
            width: '100%', 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center'
          }}>
            <CircularProgress style={{color: '#3272D9'}} />
        </div>
        break
    }
    return (
      <div>{view}<Snackbar
      open={this.state.showFloatingMessage}
      message={this.state.floatingMessageValue}
      autoHideDuration={4000}
      onClose={this.onFloatingMessageClose}
    /></div>
      )

  }

}

const mapStateToProps = (state) => {
  return {
    activeProbes: state.activeProbes,
    dashboardPaths: state.dashboardPaths,
    userdashboards: state.userdashboards.userDashboardsListGetStatus.userdashboards,
    sid: state.sid,
    userdashboard: state.userdashboards,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setBreadCrumbs: (items) => dispatch(setBreadCrumbs(items)),
    fetchActiveProbes: () => dispatch(fetchActiveProbes()),
    fetchDashboardPaths: (params) => dispatch(fetchDashboardPaths(params)),
    fetchUserDashboards:()=>dispatch(fetchUserDashboards()),
    resetUserDashboardsStore:()=>dispatch(resetUserDashboardsStore()),
    fetchDashboardRouteMap: () => dispatch(fetchDashboardRouteMap())
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(DashboardsRedux)