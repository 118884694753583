import {
  FETCH_DEVICES,
  FETCH_DEVICES_SUCCESS,
  FETCH_DEVICES_FAILURE,
  FETCH_SAVED_METRICS,
  FETCH_SAVED_METRICS_SUCCESS,
  FETCH_SAVED_METRICS_FAILURE,
  FETCH_DEVICE_METRICS,
  FETCH_DEVICE_METRICS_SUCCESS,
  FETCH_DEVICE_METRICS_FAILURE,
  FETCH_GOLDEN_METRICS,
  FETCH_GOLDEN_METRICS_SUCCESS,
  FETCH_GOLDEN_METRICS_FAILURE,
  UPDATE_ALIAS,
  UPDATE_ALIAS_SUCCESS,
  UPDATE_ALIAS_FAILURE,
  FETCH_DEVICE_DATA,
  FETCH_DEVICE_DATA_SUCCESS,
  FETCH_DEVICE_DATA_FAILURE,
  FETCH_MONITORINGHOSTS_DATA,
  FETCH_MONITORINGHOSTS_DATA_SUCCESS,
  FETCH_MONITORINGHOSTS_DATA_FAILURE
} from "./actionTypes";
import devices from "./devices";
import { forIn, startsWith } from "lodash";

export function fetchDevices() {
  return {
    type: FETCH_DEVICES,
  };
}
export function fetchDevicesSuccess(json) {
  return {
    type: FETCH_DEVICES_SUCCESS,
    devices: json,
  };
}
export function fetchDevicesFailure(error) {
  return {
    type: FETCH_DEVICES_FAILURE,
    errorMessage: error.message,
  };
}
export function get(params) {
  return (dispatch) => {
    dispatch(fetchDevices());
    const promise = devices.get(params);
    promise
      .then((response) => response.json())
      .then((json) => dispatch(fetchDevicesSuccess(json)))
      .catch(function (error) {
        dispatch(fetchDevicesFailure(error));
      });
    return promise;
  };
}

export function fetchDeviceInit() {
  return {
    type: FETCH_DEVICE_DATA,
  };
}
export function fetchDeviceDataSuccess(json) {
  return {
    type: FETCH_DEVICE_DATA_SUCCESS,
    dvcInfo: json,
  };
}
export function fetchDeviceDataFailure(error) {
  return {
    type: FETCH_DEVICE_DATA_FAILURE,
    errorMessage: error.message,
  };
}
export function getDeviceData(csId) {
  return (dispatch) => {
    dispatch(fetchDeviceInit());
    return devices
      .getDeviceData(csId)
      .then((response) => response.data)
      .then((json) => dispatch(fetchDeviceDataSuccess(json)))
      .catch(function (error) {
        dispatch(fetchDeviceDataFailure(error));
      });
  };
}

export function fetchSavedMetrics() {
  return {
    type: FETCH_SAVED_METRICS,
  };
}
export function fetchSavedMetricsSuccess(json) {
  return {
    type: FETCH_SAVED_METRICS_SUCCESS,
    savedMetrics: json,
  };
}
export function fetchSavedMetricsFailure(error) {
  return {
    type: FETCH_SAVED_METRICS_FAILURE,
    errorMessage: error.message ? error.message : "Request failed",
  };
}

export function fetchAllMetric(data) {
  return (dispatch) => {
    dispatch(fetchSavedMetrics());
    return devices
      .getAllSavedMetrics(data)
      .then((response) => response.data)
      .then((data) => dispatch(fetchSavedMetricsSuccess(data)))
      .catch(function (error) {
        dispatch(fetchSavedMetricsFailure(error));
      });
  };
}

export function fetchMetricDevices() {
  return {
    type: FETCH_DEVICE_METRICS,
  };
}

export function fetchMetricDeviceSuccess(
  json,
  deviceIds,
  metricTypes,
  hours,
  selectedCIs,
  isInterface,
  endDate,
  aggregationValue,
  aggregateFunction
) {
  return {
    type: FETCH_DEVICE_METRICS_SUCCESS,
    metricData: json,
    deviceIds,
    metricTypes,
    hours,
    selectedCIs,
    isInterface,
    endDate,
    aggregateFunction
  };
}

export function fetchMetricDeviceFailure(
  error,
  deviceIds,
  metricTypes,
  hours,
  selectedCIs,
  isInterface,
  endDate,
  aggregationValue,
  aggregateFunction 
) {
  return {
    type: FETCH_DEVICE_METRICS_FAILURE,
    errorMessage: error.message,
    deviceIds,
    metricTypes,
    hours,
    selectedCIs,
    isInterface,
    endDate,
    aggregationValue,
    aggregateFunction
  };
}

export function getMetricDevices(
  deviceIds,
  metricTypes,
  hours,
  selectedCIs,
  isInterface,
  endDate,
  aggregationValue,
  aggregateFunction,
  ciName
) {
  /*forIn(window.localStorage, (value, objKey) => {    
    if (true === startsWith(objKey, 'color_')) {      
        window.localStorage.removeItem(objKey);
    }
  })*/
  return (dispatch) => {
    dispatch(fetchMetricDevices());
    return (
      devices
        .getAllMetricDevices(
          deviceIds,
          metricTypes,
          hours,
          selectedCIs,
          isInterface,
          endDate,
          aggregationValue,
          aggregateFunction,
          ciName
        )
        .then((response) => [...response.map((res) => res.data)])
        //.then((response) => {return [response.data]}) //TODO: Remove it, only for mock
        // .then((response) => [...response.map((res) => res.data._items[0])]) //TODO: Remove it, only for mock
        .then((data) =>
          dispatch(
            fetchMetricDeviceSuccess(
              data,
              deviceIds,
              metricTypes,
              hours,
              selectedCIs,
              isInterface,
              endDate,
              aggregationValue,
              aggregateFunction,
              ciName
            )
          )
        )
        .catch(function (error) {
          dispatch(
            fetchMetricDeviceFailure(
              error,
              deviceIds,
              metricTypes,
              hours,
              selectedCIs,
              isInterface,
              endDate,
              aggregationValue,
              aggregateFunction,
              ciName
            )
          );
        })
    );
  };
}

export function fetchGoldenMetrics() {
  return {
    type: FETCH_GOLDEN_METRICS,
  };
}
export function fetchGoldenMetricsSuccess(json) {
  return {
    type: FETCH_GOLDEN_METRICS_SUCCESS,
    goldenMetrics: json,
  };
}
export function fetchGoldenMetricsFailure(error) {
  return {
    type: FETCH_GOLDEN_METRICS_FAILURE,
    errorMessage: error.message ? error.message : "Request failed",
  };
}

export function fetchGoldenMetricData(isInterface) {
  return (dispatch) => {
    dispatch(fetchGoldenMetrics());
    return devices
      .getGoldenMetricsData(isInterface)
      .then((response) => response.data)
      .then((data) => dispatch(fetchGoldenMetricsSuccess(data)))
      .catch(function (error) {
        dispatch(fetchGoldenMetricsFailure(error));
      });
  };
}

export function updateAliasInit() {
  return {
    type: UPDATE_ALIAS,
  };
}
export function updateAliasSuccess(json) {
  return {
    type: UPDATE_ALIAS_SUCCESS,
    successMsg: json,
  };
}
export function updateAliasFailure(error) {
  return {
    type: UPDATE_ALIAS_FAILURE,
    errorMessage: error.message ? error.message : "Request failed",
  };
}

export function updateAlias(newAlias, csId) {
  return (dispatch) => {
    dispatch(updateAliasInit());
    return devices
      .updateAlias(newAlias, csId)
      .then((response) => response.data)
      .then((data) => dispatch(updateAliasSuccess(data)))
      .catch(function (error) {
        dispatch(updateAliasFailure(error));
      });
  };
}

export function fetchMonitoringHostsInit() {
    return {
        type: FETCH_MONITORINGHOSTS_DATA,
    };
}
export function fetchMonitoringHostsSuccess(json) {
    return {
        type: FETCH_MONITORINGHOSTS_DATA_SUCCESS,
        monitoringHostsInfo: json,
    };
}
export function fetchMonitoringHostsFailure(error) {
    return {
        type: FETCH_MONITORINGHOSTS_DATA_FAILURE,
        errorMessage: error.message,
    };
}
export function getMonitoringHosts(csId) {
    return (dispatch) => {
        dispatch(fetchMonitoringHostsInit());
        return devices
            .getMonitoringHosts(csId)
            .then((response) => response.data)
            .then((json) => dispatch(fetchMonitoringHostsSuccess(json)))
            .catch(function (error) {
                dispatch(fetchMonitoringHostsFailure(error));
            });
    };
}