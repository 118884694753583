// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //System update available
  content["System update available"] =
  "系统更新可用";

  //A new version of the robot software is available.
  content["A new version of the robot software is available."] =
  "Robot 软件的新版本可用。";

  //Deployment takes about 1 minute, during which time no metrics are collected and no alarms are created.
  content["Deployment takes about 1 minute, during which time no metrics are collected and no alarms are created."] =
  "部署需要大约 1 分钟，在此期间不会收集度量标准，也不会创建警报。";

  //LATER
  content["LATER"] =
  "以后";

  //DEPLOY
  content["DEPLOY"] =
  "部署";

  // END OF LOCALIZATION

export default content;
