import React, { Component } from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  Collapse,
} from "@mineral/core";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import IndeterminateCheckBoxIcon from "@material-ui/icons/IndeterminateCheckBox";
import { connect } from "react-redux";
import MetricListItem from "./MetricListItem";
import { selectMetricTypeGroup } from "../../api/metricTypes/actions";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

class MetricsSelection extends Component {
  selectGroup(metricTypeGroup, selected) {
    let selectedStatus =
      selected && !metricTypeGroup.partialSelected ? true : false;
    this.props.onCheckMetricTypeGroup(metricTypeGroup, selectedStatus);
  }

  renderListItem(metricType) {
    let style = {
      color: "#fff",
      paddingLeft: "72px",
      backgroundColor: metricType.selected ? "#4D4D4D" : null,
    };
    if (metricType.metrics.length === 1) {
      return (
        <MetricListItem
          key={metricType.metrics[0]}
          metricId={metricType.metrics[0]}
        />
      );
    } else {
      return (
        <div>
          <ListItem
            style={style}
            key={metricType.metricType}
            /*  primaryText={`${metricType.name} (${metricType.metrics.length})`}
          secondaryText={`${metricType.description}, ${metricType.unit}`}
          secondaryTextLines={2}          
          nestedItems={metricType.metrics.map((metricId) => (
            <MetricListItem key={metricId} metricId={metricId} child={true} />
          ))} */
          >
            <ListItemIcon
              style={!metricType.selected ? { fill: "#C9C9C9" } : null}
            >
              <Checkbox
                style={{ left: "24px" }}
                checkedIcon={
                  //CheckBoxIcon
                  metricType.partialSelected ? (
                    <IndeterminateCheckBoxIcon
                      style={{ marginRight: "24px" }}
                    />
                  ) : (
                    <CheckBoxIcon style={{ marginRight: "24px" }} />
                  )
                }
                // iconStyle={!metricType.selected ? { fill: "#C9C9C9" } : null}
                defaultChecked={metricType.selected}
                onChange={(event) =>
                  this.selectGroup(metricType, event.target.checked)
                }
                aria-labelledby={`${metricType.name} (${metricType.metrics.length})`}
              />
            </ListItemIcon>
            <ListItemText
              primary={`${metricType.name} (${metricType.metrics.length})`}
              id={`${metricType.name} (${metricType.metrics.length})`}
              secondary={`${metricType.description}, ${metricType.unit}`}
            />
            {/* {open ? <ExpandLess /> : <ExpandMore />} */}
          </ListItem>

          {/* Need to implement for nestedItems
<Collapse in={open} timeout="auto" unmountOnExit>
<List component="div" disablePadding>
{metricType.metrics.map((metricId) => (
            <MetricListItem key={metricId} metricId={metricId} child={true} />
          ))}
</List>
</Collapse> */}
        </div>
      );
    }
  }

  render() {
    const { metricTypes } = this.props;

    let nestedItems;

    if (metricTypes.length === 0) {
      nestedItems = [
        <ListItem key="no-metrics" children="No metrics for this device" />,
      ];
    }

    nestedItems = metricTypes.map((metricType) =>
      this.renderListItem(metricType)
    );

    return <List>{nestedItems}</List>;
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onCheckMetricTypeGroup: (metricTypeGroup, selected) =>
      dispatch(selectMetricTypeGroup(metricTypeGroup, selected)),
  };
};

export default connect(null, mapDispatchToProps)(MetricsSelection);
//export default MetricsSelection
