import React, { Component } from 'react'
import WindowsCredentialVault from './WindowsCredentialVault'
import UnixCredentialVault from './UnixCredentialVault'
import SnmpCredentialVault from './SNMPCreditialVault'
/**
 * @Author namam01
 */
class CredentialVault extends Component{
	constructor(){
		  super();
		  this.state = {			    
		 }
	  }
	
	 render(){	
		 if(this.props.type == 'WMI'){
			 return(<WindowsCredentialVault/>)
		 }else if(this.props.type == 'UNIX'){
			 return(<UnixCredentialVault/>)
		 }
		 else if(this.props.type == 'SNMP'){
			return(<SnmpCredentialVault/>)
		}
	 }
}
 export default CredentialVault