import React from "react";
import Portlet from "../Portlet";
import ArrowIcon from "../../ArrowIcon";
import Resource from "../ecometer/Resource";
import {Tooltip} from '@mineral/core';


export default class ListDesignerReportsCard extends Portlet {
  getIcon() {
    return <ArrowIcon />;
  }

  getSubLabelTwo = () => {
    return this.props.subLabelTwo;
  };
}


//Performance reports
const label = Resource.get("List Designer");
//The Performance Reports Designer allows you to see a visual representation of QoS data
const desc = Resource.get("The List Designer allows administrators to design lists to be displayed in the List Viewer.");

ListDesignerReportsCard.defaultProps = {
  logo: <Tooltip title={label}><span className="settings-text-logo admin__console--card">{label}</span></Tooltip>,
  configRoute: "/settings/listdesigner",
  subLabelOne: desc,
  subLabelTwo: "",
}