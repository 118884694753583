import axios from 'axios'

class Ocview {
  getUser() {
    const url = process.env.NODE_ENV=='development'?"http://10.252.25.208:8080/castlemock/mock/rest/project/TK8t3o/application/UXxhjW/oc-views":
    '/operatorconsole_portlet/api/v1/oc-views'
    return axios.get(url)
  }
  
}

const ocviews= new Ocview()
export default ocviews