import React from 'react'
import './branding.less'
import BrandName from './BrandName'
import BrandProduct from './BrandProduct'


const Branding = props => {
  let view = (
    <div style={{ minWidth: '300px' }}>      
      <span className="brand__title"><BrandName/> </span>                  
      <div className="brand__product"><BrandProduct/></div>
    </div>
  )
  if (props.saas) {
    view = (
      <div style={{ minWidth: '300px' }}>
        <span className="brand__title">Digital Experience Insights</span>
        <div className="brand__product">Infrastructure Management</div>
      </div>
    )
  }
  return view
}

export default Branding
