import { 
FETCH_USER_DASHBOARDS,
FETCH_USER_DASHBOARDS_SUCCESS,
FETCH_USER_DASHBOARDS_FAILURE,

FETCH_USER_DASHBOARD,
FETCH_USER_DASHBOARD_SUCCESS,
FETCH_USER_DASHBOARD_FAILURE,


CREATE_USER_DASHBOARDS,
CREATE_USER_DASHBOARDS_SUCCESS,
CREATE_USER_DASHBOARDS_FAILURE,


UPDATE_USER_DASHBOARDS,
UPDATE_USER_DASHBOARDS_SUCCESS,
UPDATE_USER_DASHBOARDS_FAILURE,

DELETE_USER_DASHBOARDS,
DELETE_USER_DASHBOARDS_SUCCESS,
DELETE_USER_DASHBOARDS_FAILURE,
RESET_USER_DASHBOARD_STORE


} from './actionTypes'
import userdashboards from './userdashboards'


export function resetUserDashboardsStore() {
  return {
    type: RESET_USER_DASHBOARD_STORE,
  }
}

export function requestUserDashboards() {
    return {
      type: FETCH_USER_DASHBOARDS,
    }
  }
  
  export function fetchUserDashboardsSuccess(json) {
    return {
      type: FETCH_USER_DASHBOARDS_SUCCESS,
      userdashboards: json,
    }
  }
  
  export function fetchUserDashboardsFailure(error) {
    return {
      type: FETCH_USER_DASHBOARDS_FAILURE,
      errorMessage: error.message,
    }
  }
  
  export function fetchUserDashboards() {
    return dispatch => {
      dispatch(requestUserDashboards())
      return userdashboards
        .getAll()
        .then(response => response.data)
        .then(json => dispatch(fetchUserDashboardsSuccess(json)))
        .catch(function(error) {
          dispatch(fetchUserDashboardsFailure(error))
        })
    }
  }

  export function requestUserDashboard() {
    return {
      type: FETCH_USER_DASHBOARD,
    }
  }
  
  export function fetchUserDashboardSuccess(json) {
    return {
      type: FETCH_USER_DASHBOARD_SUCCESS,
      userdashboards: json,
    }
  }
  
  export function fetchUserDashboardFailure(error) {
    return {
      type: FETCH_USER_DASHBOARD_FAILURE,
      errorMessage: error.message,
    }
  }
  
  export function fetchUserDashboard(userdashboardId) {
    return dispatch => {
      dispatch(requestUserDashboard())
      return userdashboards
        .getUserDashboard(userdashboardId)
        .then(response => response.data)
        .then(json => dispatch(fetchUserDashboardSuccess(json)))
        .catch(function(error) {
          dispatch(fetchUserDashboardFailure(error))
        })
    }
  }

  export function createUserDashboardStart(userDashboardParams) {
    return {
      type: CREATE_USER_DASHBOARDS,
      dashboardname: userDashboardParams.dashboardName,
      
    };
  }
  
  export function createUserDashboardSuccess(userdashboard,dashboardName) {
    return {
      type: CREATE_USER_DASHBOARDS_SUCCESS,
      userdashboard:userdashboard,
      dashboardname:dashboardName,
      
    };
  }
  
  export function createUserDashboardFailure(error,dashboardName) {
    return {
      type: CREATE_USER_DASHBOARDS_FAILURE,
      error: error.message,
      dashboardname:dashboardName,

     
    };
  }
  
  export function createUserDashboard(userDashboardParams) {
    return (dispatch) => {
      dispatch(createUserDashboardStart(userDashboardParams));
      return userdashboards
        .createUserDashboard(userDashboardParams)
        .then((response) => response.data)
        .then((json) => dispatch(createUserDashboardSuccess(json,userDashboardParams.dashboardName)))
        .catch(function (error) {
          dispatch(createUserDashboardFailure(error,userDashboardParams.dashboardName));
        });
    };
  }


  export function updateUserDashboardStart(name) {
    return {
      type: UPDATE_USER_DASHBOARDS,
      dashboardname:name.dashboardName,
      
    };
  }
  
  export function updateUserDashboardSuccess(userdashboard) {
    return {
      type: UPDATE_USER_DASHBOARDS_SUCCESS,
      userdashboard:userdashboard,
      dashboardname:userdashboard.dashboardName,
      
    };
  }
  
  export function updateUserDashboardFailure(error,dashboardname) {
    return {
      type: UPDATE_USER_DASHBOARDS_FAILURE,
      error: error.message,
      dashboardname:dashboardname,
     
    };
  }
  
  export function updateUserDashboard(userDashboardParams) {
    return (dispatch) => {
      dispatch(updateUserDashboardStart(userDashboardParams));
      return userdashboards
        .updateUserDashboard(userDashboardParams)
        .then((response) => response.data)
        .then((json) => dispatch(updateUserDashboardSuccess(json)))
        .catch(function (error) {
          dispatch(updateUserDashboardFailure(error,userDashboardParams.dashboardName));
        });
    };
  }
  

  export function deleteUserDashboardStart(dashboardname) {
    return {
      type: DELETE_USER_DASHBOARDS,
      dashboardname: dashboardname
      
    };
  }
  
  export function deleteUserDashboardSuccess(dashboardname) {
    return {
      type: DELETE_USER_DASHBOARDS_SUCCESS,
      dashboardname: dashboardname
      
      
    };
  }
  
  export function deleteUserDashboardFailure(error,dashboardname) {
    return {
      type: DELETE_USER_DASHBOARDS_FAILURE,
      error: error.message,
      dashboardname: dashboardname
     
    };
  }
  
  export function deleteUserDashboard(userDashboardId,dashboardname) {
    return (dispatch) => {
      dispatch(deleteUserDashboardStart(dashboardname));
      return userdashboards
        .deleteUserDashboard(userDashboardId,dashboardname)
        .then((response) => response.data)
        .then((json) => dispatch(deleteUserDashboardSuccess(dashboardname)))
        .catch(function (error) {
          dispatch(deleteUserDashboardFailure(error));
        });
    };
  }
  