import React, { Component } from "react";
import isEqual from "lodash/isEqual";
import {
  Card,
  CardContent,
  Menu,
  IconButton,
  TextField,
  Tooltip,
  Snackbar,Divider,FormControl,Popover,Typography,InputLabel
} from "@mineral/core";
import {ClearIcon as Clear, RemoveIcon,Error } from '../../ui-components/uim-components'
import {CircularProgress} from '@mineral/core'
import Resource from "../Resource";
import connect from "../../../utils/connect";
import SeverityIcon from "./../../icons/SeverityIcon";
import MaintenanceIcon from "./../../icons/MaintenanceIcon";
import EditIcon from "@material-ui/icons/Edit";
import MaintenaneDialogRedux from "./../../maintenance/MaintenaneDialogRedux";
import maintenanceApi from "../../../api/maintenance/maintenance";
import {
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
} from "@mineral/core";
import { updateAlias, getDeviceData } from "../../../api/devices/actions";
import capitalize from "lodash/capitalize";
import CustomTooltip from "../../tooltip/CustomTooltip";
import ExportGroupDetailsDialog from "./../../treeViewNew/ExportGroupDetailsDialog";
import devicesApi from "../../../api/devices/devices";
import { NavLink } from "react-router-dom";
import config from "./../../../api/config";
import "../../treeViewNew/treeContainer.less";
import {Button} from '@mineral/core'
import CustomSnackbar from '../../common/CustomSnackbar';

function useEventListener(eventName, handler, element = window) {
  const savedHandler = React.useRef()
  React.useEffect(() => {
    savedHandler.current = handler
  }, [handler])
  React.useEffect(() => {
    const isSupported = element && element.addEventListener
    if (!isSupported) return
    const eventListener = (event) => savedHandler.current(event)
    element.addEventListener(eventName, eventListener)
    return () => {
      element.removeEventListener(eventName, eventListener)
    }
  }, [eventName, element])
}

  //ID
  const ID=Resource.get("ID");

  //Name
  const NAME=Resource.get("Name");

   //Alias
   const ALIAS=Resource.get("Alias");

    //IP Address
    const IPADDRESS=Resource.get("IP Address");

    //Hub
    const HUB=Resource.get("Hub");

    //Dedicated
    const DEDICATED=Resource.get("Dedicated");

    //OS Type
    const OSTYPE=Resource.get('OS Type');

    //OS Name
    const OSNAME=Resource.get('OS Name')


    //OS Version
    const OSVERSION=Resource.get('OS Version')

    //OS Description
    const OS_DESCRIPTION=Resource.get('OS Description');

    //MAC Address
    const MACADDRESS=Resource.get('MAC Address')

    //Origin
    const ORIGIN=Resource.get('Origin')

    //latestorigin
    const LATESTORIGIN=Resource.get('Latest Origin')

    //Role
    const ROLE=Resource.get('Role')


    //Model
    const MODEL=Resource.get('Model')

    //Bustype
    const BUSTYPE=Resource.get('Bus Type');

    //User Tag 1
    const USERTAG1=Resource.get('User Tag 1')

     //User Tag 2
     const USERTAG2=Resource.get('User Tag 2')

     //Monitoring Hosts
     const MONITORINGHOSTS=Resource.get('Monitoring Hosts');

     //Maintenance
     const MAINTENANCE=Resource.get('Maintenance');

     //Export
     const EXPORT=Resource.get('Export');

     //Vendor
     const VENDOR=Resource.get('Vendor')


const Panel = (props) => {
  const [open, setOpen] = React.useState(false);
  const [hoverValue, setHoverValue] = React.useState("");

  const onOpen = (value) => {
    setOpen(true);
    setHoverValue(value);
  }
  const onClose = () => {
    setOpen(false);
    setHoverValue("");
  }

  const handler = React.useCallback((e) => {
    if (e.key === 'Escape') {
      onClose()
    }
  }, [])
  useEventListener('keydown', handler)

  const handleDrillDown = (rowNumber, colId) => {
    props.history.push(
      `/computer-systems/${props.data[rowNumber].robotid}/dashboard`
    );
  };

  const getProcessedHosts = () => {
    let lookup = {};
    let newArr = [];
    props.monitoringHosts &&
      props.monitoringHosts.forEach((item) => {
        let lookupRobotid = lookup[item.robotid];
        if (lookupRobotid) {
          lookup[item.robotid]["prid"].push(item.prid);
        } else {
          let val = `${item.robot ? item.robot + " - " : ""}${item.prid}`;
          lookup[item.robotid] = {
            robot: item.robot,
            robotid: item.robotid,
            prid: [item.prid],
          };
        }
      });

    for (let key in lookup) {
      newArr.push(lookup[key]);
    }
    return newArr
  }
  //Edit Alias
  let editAlias = Resource.get("Edit Alias"); 
  //Edit Icon
  let editIcon = Resource.get("Edit Icon");
    return (    
    <CardContent
    style={{
      display: "flex",
      flexDirection: "column",
      backgroundColor: "#f4f4f4",
      padding: "10px",
      color: "#5B676C",
      overflow: "auto",
      height: "2000vh",
    }}
    >
    <table aria-label="Table listing device details with field described in column 1 and value in column 2">
    <tr>
        <td>
            <div style={{ padding: "2px 0px" }}> {`${ID}:`}</div>
        </td>
        <td>
          <div style={{ padding: "2px 0px" }}>
            {props.deviceData.attributes &&
              props.deviceData.attributes.cs_id &&
              props.deviceData.attributes.cs_id[0]}            
          </div>
        </td>
      </tr>
    <tr>
    <td>
            <div style={{ padding: "2px 0px" }}> {`${NAME}:`}</div>
    </td>
    <td>
    <div style={{ padding: "2px 0px" }}>
    <b> {props.deviceData.name}</b>
    </div>
    </td>
    </tr>
    <tr>
    <td>
            <div style={{ padding: "2px 0px" }}> {`${ALIAS}`}:</div>
    </td>
    <td style={{width: "100%"}}>
      <Tooltip arrow open={hoverValue === "alias" && open} onOpen={()=>onOpen("alias")} onClose={onClose} title={props.deviceData.alias}>
      <div className="alias_div_txt">
        {props.deviceData.alias}
      </div>
      </Tooltip>

      <div className="alias_edit_icon_div">
        {props.canEditDevice
          && (<Tooltip title={editAlias}>
          <IconButton
          role = "button"
          className="edit-alias" title={editAlias}
           //aria-haspopup="true"
          onClick={(e)=>{
            props.handleMenuClick(e);
          }}>
          <EditIcon role = "figure" title={editAlias} alt = {editIcon} style={{ color: "#FFFFFF" }} />
          </IconButton>
          </Tooltip>
        )
        }
      </div>
     
    </td>
    </tr>
    <tr>
    <td>
            <div style={{ padding: "2px 0px" }}> {`${IPADDRESS}:`} </div>
    </td>
    <td>
    <div style={{ padding: "2px 0px" }}>
    {" "}
    {props.deviceData.ipAddresses && props.deviceData.ipAddresses[0]}
    </div>
    </td>
    </tr>

  <tr>
    <td>
            <div style={{ padding: "2px 0px" }}> {`${HUB}:`}</div>
    </td>
    <td>
      <div style={{ padding: "2px 0px" }}>
        {props.deviceData.attributes &&
          props.deviceData.attributes.Hub &&
          props.deviceData.attributes.Hub[0]}
      </div>
    </td>
  </tr>

  <tr>
    <td>
            <div style={{ padding: "2px 0px" }}> {`${DEDICATED}:`}</div>
    </td>
    <td>
      <div style={{ padding: "2px 0px" }}>
        {props.deviceData.attributes &&
          props.deviceData.attributes.dedicated &&
          props.deviceData.attributes.dedicated[0]}
      </div>
    </td>
  </tr>

    <tr>
    <td>
            <div style={{ padding: "2px 0px" }}> {`${OSTYPE}:`}</div>
    </td>
    <td>
    <div style={{ padding: "2px 0px" }}>{props.deviceData.osType}</div>
    </td>
    </tr>
    <tr>
    <td>
            <div style={{ padding: "2px 0px" }}> {`${OSNAME}:`}</div>
    </td>
    <td>
    <div style={{ padding: "2px 0px" }}>{props.deviceData.osName}</div>
    </td>
    </tr>
    <tr>
    <td>
            <div style={{ padding: "2px 0px" }}> {`${OSVERSION}:`}</div>
    </td>
    <td>
    <div style={{ padding: "2px 0px" }}>
    {props.deviceData.osVersion}
    </div>
    </td>
    </tr>
    <tr>
    <td>
            <div style={{ padding: "2px 0px" }}> {`${OS_DESCRIPTION}:`}</div>
    </td>
    <td>
    <div style={{ padding: "2px 0px" }}>
    {props.deviceData.osDescription}
    </div>
    </td>
    </tr>

    <tr>
      <td>
        <div style={{ padding: "2px 0px" }}> {`${ORIGIN}:`}</div>
      </td>
      <td>
        <div style={{ padding: "2px 0px" }}>
          {props.deviceData.attributes &&
          props.deviceData.attributes.origin &&
          props.deviceData.attributes.origin[0]}
        </div>
      </td>
    </tr>

    <tr>
      <td>
        <div style={{ padding: "2px 0px" }}> {`${LATESTORIGIN}:`}</div>
      </td>
      <td>
        <div style={{ padding: "2px 0px" }}>
          {props.deviceData.attributes &&
          props.deviceData.attributes.latestOrigin &&
          props.deviceData.attributes.latestOrigin[0]}
        </div>
      </td>
    </tr>

      <tr>
      <td>
            <div style={{ padding: "2px 0px" }}> {`${MACADDRESS}:`}</div>
      </td>
      <td>
      <div style={{ padding: "2px 0px" }}>
      {props.deviceData.attributes &&
        props.deviceData.attributes.mac &&
        props.deviceData.attributes.mac[0]}
        </div>
        </td>
        </tr>
        <tr>
          <td>
             <div style={{ padding: "2px 0px" }}>{`${ROLE}:`}</div>
          </td>
          <td>
            <div style={{ padding: "2px 0px" }}>{props.deviceData.role}</div>
          </td>
        </tr>

        {props.deviceData.attributes &&
        props.deviceData.attributes.Vendor &&
        props.deviceData.attributes.Vendor[0] ? (
          <tr>
            <td>
              <div style={{ padding: "2px 0px" }}> {`${VENDOR}:`}</div>
            </td>
            <td>
              <div style={{ padding: "2px 0px" }}>
                {props.deviceData.attributes.Vendor[0]}
              </div>
            </td>
          </tr>
        ) : null}

        {props.deviceData.attributes &&
        props.deviceData.attributes.Model &&
        props.deviceData.attributes.Model[0] ? (
          <tr>
            <td>
              <div style={{ padding: "2px 0px" }}> {`${MODEL}:`}</div>
            </td>
            <td>
              <div style={{ padding: "2px 0px" }}>
                {props.deviceData.attributes.Model[0]}
              </div>
            </td>
          </tr>
        ) : null}

        {props.deviceData.busType ? (
          <tr>
            <td>
              <div style={{ padding: "2px 0px" }}> {`${BUSTYPE}:`}</div>
            </td>
            <td>
              <div style={{ padding: "2px 0px" }}>
                {props.deviceData.busType}
              </div>
            </td>
          </tr>
        ) : null}

        <tr>
          <td>
            <div style={{ padding: "2px 0px" }}> {`${USERTAG1}:`}</div>
          </td>
          <td>
            <div style={{ padding: "2px 0px" }}>
              {props.deviceData.attributes &&
              props.deviceData.attributes.user_tag_1 &&
              props.deviceData.attributes.user_tag_1[0]
                ? props.deviceData.attributes.user_tag_1[0]
                : null}
            </div>
          </td>
        </tr>

        <tr>
          <td>
            <div style={{ padding: "2px 0px" }}>{`${USERTAG2}:`}</div>
          </td>
          <td>
            <div style={{ padding: "2px 0px" }}>
              {props.deviceData.attributes &&
              props.deviceData.attributes.user_tag_2 &&
              props.deviceData.attributes.user_tag_2[0]
                ? props.deviceData.attributes.user_tag_2[0]
                : null}
            </div>
          </td>
        </tr>

              <tr>
                  <td colSpan="2">
                      <hr className="devicedetailsseperator" />
                  </td>
              </tr>
                <tr onCellClick={handleDrillDown}>
                    <td style={{
                        verticalAlign: "top"
                    }}>
                        <div style={{
                            padding: "2px 0px"}}>  {` ${MONITORINGHOSTS}:`}</div>
                  </td>
                    <td>
                        <div style={{ padding: "2px 0px" }}>
                            {getProcessedHosts().map((host, index) => (
                                (host.robot) ? (
                                    <div>
                                      <Tooltip arrow open={hoverValue === `${host.robot}` && open} onOpen={()=>onOpen(`${host.robot}`)} onClose={onClose} title={`${host.robot}`} placement="top">
                                        <a  aria-roledescription="Click to open dashboard of the host"
                                            className="linkmonitoringhosts"
                                            href={`${config.basename}/computer-systems/${host.robotid}/dashboard`}>
                                            {host.robot}
                                        </a>
                                        </Tooltip>
                                        {" - " + host.prid.join(', ')}<br />
                                    </div>) : <div>{host.prid.join(', ')}<br /></div>
                            ))}

                        </div>
                  </td>
              </tr>
          </table>
          <hr />          
          {props.content}
          </CardContent>
          );
};

class DeviceDetail extends Component {
          constructor(props) {
            super(props);
            this.state = {
              showAddDevicesDialog: false,
              showAliasMenu: false,
              aliasName: this.props.deviceInfo.alias,
              showFloatingMessage: false,
              floatingMessageType:'success',
              floatingMessageValue: "",
              schedules: [],
              openSnackBar: false,
              failMsg: "",
              openExportDialog: false,
              menuAnchorEl: null,
              monitoringHosts: [],
              open:false,
              hoverValue:"",
            };
            
          }
          handler = (e) => {
            if (e.key === 'Escape') {
              this.onClose();
              window.removeEventListener('keydown',this.handler)
            }
          }
          onOpen = (value) => {
            this.setState({ open:true,hoverValue:value });
            window.addEventListener('keydown',this.handler)
          }
          onClose = () => {
            this.setState({ open:false,hoverValue:"" });
          }
          componentDidMount() {
            this.props.getDeviceData(this.props.csId);
            this.fetchMaintenanceInformation(this.props);
            this.fetchMonitoringHostsInformation(this.props.csId);
          }
          componentWillReceiveProps(nextProps) {
            if (!isEqual(this.props.deviceInfo.alias, nextProps.deviceInfo.alias)) {
              this.setState({ aliasName: nextProps.deviceInfo.alias });
            }
            if (!isEqual(this.props.csId, nextProps.csId)) {
              this.props.getDeviceData(nextProps.csId);
              this.fetchMaintenanceInformation(nextProps);
              this.fetchMonitoringHostsInformation(this.props.csId);
            }
          }
          
          fetchMaintenanceInformation = (maintenanceProps) => {
            maintenanceApi
            .getScheduleForDevices(maintenanceProps.csId)
            .then((res) => {
              this.setState({ schedules: res.data });
              // this.setState({ schedules: res.data._items });
            })
            .catch((err) =>
            this.setState({ openSnackBar: true, failMsg: "Error in fetching list" })
            );
          };

fetchMonitoringHostsInformation = (csId) => {
                devicesApi.getMonitoringHosts(csId)
                  .then((res) => {
                    this.setState({ monitoringHosts: res.data });
                  })
                  .catch((err) =>
                  this.setState({ openSnackBar: true, failMsg: "Error in fetching list" })
                );
           };
          
          deleteSchedule = (sdlId) => {
            maintenanceApi
            .removeDeviceForSchedule(sdlId, this.props.csId)
            .then((res) => {
              this.setState({ schedules: res.data });
              // this.setState({ schedules: res.data._items });
            })
            .catch((err) =>
            this.setState({
              openSnackBar: true,
              failMsg: "Error in deleting schedules",
            })
            );
          };
          
          handleAddDevices = () => {
            this.props.acl.canEditMaintMode && 
            this.setState({ showAddDevicesDialog: true });
          };
          handleAddDevicesClose = () => {
            this.setState({ showAddDevicesDialog: false });
            this.fetchMaintenanceInformation(this.props);
          };
          
          handleCloseSnackBar = () => {
            this.setState({ openSnackBar: false, failMsg: "" });
          };
          
          handleAliasChangeName = (event) => {
            this.setState({ aliasName: event.target.value });
          };
          handleAliasMenu = () => {
            this.setState((prevState)=>({ showAliasMenu: !prevState.showAliasMenu }));
          };
          
          handleAlarmClick = () =>
          this.props.history.replace(`/computer-systems/${this.props.csId}/alarms`);
          
          saveAliasChange = () => {
            this.props
            .updateAlias(this.state.aliasName, this.props.csId)
            .then(() => this.props.getDeviceData(this.props.csId));
            this.setState((prevState)=>({ menuAnchorEl: null,showAliasMenu: !prevState.showAliasMenu,showFloatingMessage:true, floatingMessageType:'success',floatingMessageValue:"Successfully updated alias" }) )
            .error(()=>{
              this.setState({menuAnchorEl: null,showFloatingMessage:true,floatingMessageType:'error',floatingMessageValue:"Failed to update alias"})
            });
          };
          onFloatingMessageClose = () => {
            this.setState({ showFloatingMessage: false, floatingMessageValue: "",floatingMessageType:'success' });
          };
          //dp039841
          handleOpenExportDialog=()=>{
            this.setState({openExportDialog: true})
          }
          handleExportClose =() =>{
            this.setState({openExportDialog: false})
          }
          handleMenuClick = (event) => {
            this.setState({menuAnchorEl:event.currentTarget});
          };
          handleActionMenuClose = () => {
            this.setState({menuAnchorEl: null});
          };
          
          
          render() {
              const deviceData = this.props.deviceInfo;


            // let max = 0;
            // const alarmCountSummary =
            //   this.props.deviceInfo.alarmCountSummary &&
            //   Object.keys(this.props.deviceInfo.alarmCountSummary).map((key) => {
            //     let name = key === "information" ? "info" : key;
            //     let cnt = this.props.deviceInfo.alarmCountSummary[key];
            //     max = max >= cnt ? max : cnt;
            //     return { name: capitalize(name), cnt: cnt };
            //   });
            // let maxAlarmSeverity = {
            //   // NONE
            //   label: Resource.get("NONE"),
            //   value: 0,
            //   cnt: 0,
            // };
            // if (alarmCountSummary && alarmCountSummary.length) {
            //   if (alarmCountSummary[0].cnt > 0) {
            //     maxAlarmSeverity = {
            //       //CRITICAL
            //       label: Resource.get("CRITICAL"),
            //       value: 5,
            //       cnt: alarmCountSummary[0].cnt,
            //     };
            //   } else if (alarmCountSummary[1].cnt > 0) {
            //     maxAlarmSeverity = {
            //       //MAJOR
            //       label: Resource.get("MAJOR"),
            //       value: 4,
            //       cnt: alarmCountSummary[1].cnt,
            //     };
            //   } else if (alarmCountSummary[2].cnt > 0) {
            //     maxAlarmSeverity = {
            //       //MINOR
            //       label: Resource.get("MINOR"),
            //       value: 3,
            //       cnt: alarmCountSummary[2].cnt,
            //     };
            //   } else if (alarmCountSummary[3].cnt > 0) {
            //     maxAlarmSeverity = {
            //       //WARNING
            //       label: Resource.get("WARNING"),
            //       value: 2,
            //       cnt: alarmCountSummary[3].cnt,
            //     };
            //   } else if (alarmCountSummary[4].cnt > 0) {
            //     maxAlarmSeverity = {
            //       // INFO
            //       label: Resource.get("INFO"),
            //       value: 1,
            //       cnt: alarmCountSummary[4].cnt,
            //     };
            //   } else {
            //     maxAlarmSeverity = {
            //       // NONE
            //       label: Resource.get("NONE"),
            //       value: 0,
            //       cnt: 0,
            //     };
            //   }
            // }
            let maintenanceSchedule = Resource.get("Schedule"); //Schedule
            let maintenanceStatus = Resource.get("Status"); //Status
            let maintennceAction = Resource.get("Delete"); //Delete
            let openAlarms = Resource.get("Open Alarms");//Open Alarms
            let deviceExport = Resource.get("Export");//Export
            let maintenance = Resource.get("Maintenance");//Maintenance
            const APPLY=Resource.get("Apply")//Apply
    const CANCEL=Resource.get("Cancel")//Cancel
            let dialog = (
              <MaintenaneDialogRedux
              open={this.state.showAddDevicesDialog}
              handleClose={this.handleAddDevicesClose}
              // entities={this.props.entities}
              initialcsIds={this.props.csId}
              initialDeviceName={deviceData.name}
              />
              );
              let editAliasMenu =(
                
                 <Popover
                               open={this.state.menuAnchorEl!=null}
                               anchorEl={this.state.menuAnchorEl}
                              anchorOrigin={{
                                vertical: "center",
                                horizontal: "center",
                              }}
                              transformOrigin={{
                                vertical: "center",
                                horizontal: "center",
                              }}
                              onClose={()=>{
                  this.handleAliasMenu();
                  this.handleActionMenuClose()}}
                             
                            >
                            <div style={{height:'170px',width:'400px',margin:'16px'}}>
                            <div style={{width:'100%',display:'flex',flexDirection:'row'}}>
                            <Typography component="h1" variant="h4">Edit Alias</Typography>
                            <div style={{marginLeft:'auto'}}>
                            <Tooltip title={"Close"} > 
        <IconButton autoFocus style={{top:'-5px'}} onClick={()=>{
                  this.handleAliasMenu();
                  this.handleActionMenuClose()}}>
          <Clear role='figure' />
        </IconButton>
         </Tooltip> 
         </div>
                            </div>
                              <FormControl fullWidth style={{marginTop:'8px'}}>
                              <InputLabel>Alias</InputLabel>
                <TextField inputProps={{'aria-label':'Alias'}}
                title={this.state.aliasName}
                classes={{root:"alias-text"}}
                onChange={this.handleAliasChangeName}
                value={this.state.aliasName}
                fullWidth={true}
                />
                </FormControl>
                <FormControl style={{float:'right',marginTop:'16px',display:'flex',flexDirection:'row'}}>
                <Button 
                variant="text" style={{marginRight:'8px',}}
                tabIndex={0}
                onClick={()=>{
                  this.handleAliasMenu();
                  this.handleActionMenuClose()}}> {CANCEL}</Button>  
                 
                    <Button 
                    variant="contained"
                tabIndex={0}
                onClick={this.saveAliasChange}
                // style={{ display: "block" }}
                >
                {APPLY}
                </Button> 
                </FormControl>    
                </div>
                            </Popover>
                
               
                  );
                  
                  let exportDialog =(
                    <ExportGroupDetailsDialog 
                    open ={this.state.openExportDialog}
                    requestClose = {this.handleExportClose}
                    csId = {this.props.csId}
                    {...this.props}
                    fromTreeView={false}
                    onCloseTooltip={this.onClose}
                    onOpenTooltip={this.onOpen}
                    />
                    ); 
                    let content = (
                      <div className="device-detail-table" aria-describedby="device detail table">
                      <Snackbar
                      open={this.state.openSnackBar}
                      message={this.state.failMsg}
                      autoHideDuration={4000}
                      onClose={this.handleCloseSnackBar}
                      />
                      <Table aria-label="Maintenance schedule table describing schedule name in column 1, status in column 2 and delete action in column 3"
                      selectable={false}
                      headerStyle={{ height: "28px",color:'#000000' }}
                      >
                      <TableHead
                      displaySelectAll={false}
                      adjustForCheckbox={false}
                      enableSelectAll={false}
                      style={{ height: "28px" }}
                      >
                      <TableRow style={{ height: "28px" }}>
                      <Tooltip arrow open={this.state.hoverValue === maintenanceSchedule && this.state.open} onOpen={()=> this.onOpen(maintenanceSchedule)} onClose={this.onClose} title={maintenanceSchedule} placement="top-start">
                      <TableCell
                      className="column-header"
                      //tooltip={maintenanceSchedule}
                      style={{ height: "28px" }}
                      >
                      {maintenanceSchedule}
                      </TableCell>
                      </Tooltip>
                      <Tooltip arrow open={this.state.hoverValue === maintenanceStatus && this.state.open} onOpen={()=> this.onOpen(maintenanceStatus)} onClose={this.onClose} title={maintenanceStatus} placement="top-start">
                      <TableCell
                      className="column-header"
                      //tooltip={maintenanceStatus}
                      style={{ height: "28px" }}
                      >
                      {maintenanceStatus}
                      </TableCell>
                      </Tooltip>
                      <Tooltip arrow open={this.state.hoverValue === maintennceAction && this.state.open} onOpen={()=> this.onOpen(maintennceAction)} onClose={this.onClose} title={maintennceAction} placement="top-start">
                      <TableCell
                      className="column-header"
                      style={{ height: "28px", width: "60px" }}
                      >
                      {maintennceAction}
                      </TableCell>
                      </Tooltip>
                      </TableRow>
                      </TableHead>
                      <TableBody displayRowCheckbox={false}>
                      {this.state.schedules.map((sdl) => (
                        <TableRow key={sdl.scheduleId} style={{ height: "28px" }}>
                        <TableCell className="row-column">
                        <CustomTooltip>
                        {sdl.scheduleName}
                        </CustomTooltip>
                        </TableCell>
                        <TableCell className="row-column">
                        <CustomTooltip>
                        {sdl.status}
                        </CustomTooltip>
                        </TableCell>
                        <TableCell style={{ width: "60px" }}>
                        <Tooltip arrow open={this.state.hoverValue === sdl.scheduleId && this.state.open} onOpen={()=> this.onOpen(sdl.scheduleId)} onClose={this.onClose} title={maintennceAction} placement="top">
                        <IconButton
                        className="maintenance__icon"
                        onClick={() => this.deleteSchedule(sdl.scheduleId)}
                        disabled={!this.props.acl.canEditMaintModeDevice}
                        style={
                          this.props.acl.canEditMaintModeDevice
                          ? {}
                          : { opacity: "0.5", cursor: "not-allowed" }
                        }
                        >
                        <RemoveIcon role='figure' />
                        </IconButton>
                        </Tooltip>
                        </TableCell>
                        </TableRow>
                        ))}
                        </TableBody>
                        </Table>
                        </div>
                        );
                        let view = (
                          <Panel
                          className={this.props.className}
                          deviceData={deviceData}
                          aliasName={this.state.aliasName}
                          handleAliasChangeName={this.handleAliasChangeName}
                          saveAliasChange={this.saveAliasChange}
                          handleAliasMenu={this.handleAliasMenu}
                          content={content}
                          menuAnchorEl = {this.state.menuAnchorEl}
                          handleMenuClick={this.handleMenuClick}
                          handleActionMenuClose = {this.handleActionMenuClose}
                          canEditDevice={this.props.acl.canEditDevice}
                          monitoringHosts={this.state.monitoringHosts}
                          {...this.state}
                          />
                          );
                          
                          //Device Details
                          let title = Resource.get("Device Details");

    //Open Alarms
    const OPENALARMS=Resource.get('Open Alarms')
                          let cardHeader = (
                            <div className="device_details_header" >
                            <span style={{display: "inline-flex"}}>
          <h2
                            className="overview_container_title device_details_title"
                            >
                            {title}
          </h2>
                           
                            <Divider orientation="vertical" style={{height:'75%'}}/>
                            <span style={{ marginLeft: "5px" }} className="alarm-icon">
                            {deviceData.maintanceActive ? (
                              <Tooltip title="In Maintenance">
                              <MaintenanceIcon
                              className="nav-icon"
                              style={{
                                width: "20px",
                                height: "20px",
                                margin: 0,
                                paddingRight: "10px",
                              }}
                              />
                              </Tooltip>
                              ) : (
                                <Tooltip arrow open={this.state.hoverValue === openAlarms && this.state.open} onOpen={()=> this.onOpen(openAlarms)}  onClose={this.onClose} title={`${deviceData.totalAlarms || 0} Open Alarms` } placement="top">
                                <Button variant="text" style={{width: "150px !important"}}
                                onClick={this.handleAlarmClick}
                                >
                                {deviceData.totalAlarms || 0} {OPENALARMS}
                                </Button>
                                </Tooltip>
                                )}
                                </span>
                                </span>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                <Tooltip arrow open={this.state.hoverValue === maintenance && this.state.open} onOpen={()=> this.onOpen(maintenance)} onClose={this.onClose} title={maintenance} placement="top">
                                <Button variant="text" disabled={!this.props.acl.canEditMaintMode}
                               
                                onClick={this.handleAddDevices}
                                >
                                {maintenance}
                                </Button>
                                </Tooltip>
                                </div>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                <Tooltip arrow open={this.state.hoverValue === deviceExport && this.state.open} onOpen={()=> this.onOpen(deviceExport)} onClose={this.onClose} title={deviceExport} placement="top">
                                <Button variant="text"
                               
                                onClick={this.handleOpenExportDialog}
                                >
                                {deviceExport}
                                </Button>
                                </Tooltip>
                                </div>
                                </div>
                                );
                                if (this.state.status === "loading") {
                                  view = <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><CircularProgress style={{color: '#3272D9'}} /></div>;
                                } else if (this.state.status === "failure") {
                                  cardHeader = null;
                                  view = <Error />;
                                }
                                return (
                                  <Card
                                  className={this.props.className}
                                  
                                  style={{
                                    height: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                    padding: "16px",
                                  }}
                                  >
                                  {cardHeader}
                                  {view}
                                  {dialog}
                                  {exportDialog}
                                  {editAliasMenu}    
                                  <CustomSnackbar severity={this.state.floatingMessageType}
          open={this.state.showFloatingMessage}
          message={this.state.floatingMessageValue}
          autoHideDuration={4000}
          
          close={this.onFloatingMessageClose}
        />         
                                  </Card>      
                                  );
                                }
                              }
                              
                              const mapDispatchToProps = (dispatch) => {
                                return {
                                  updateAlias: (newAlias, csId) => dispatch(updateAlias(newAlias, csId)),
                                  getDeviceData: (csId) => dispatch(getDeviceData(csId)),
                                };
                              };
                              const mapStateToProps = (state) => {
                                return {
                                  acl: state.acl,
                                  deviceInfo: state.devices.dvcInfo,
                                };
                              };
                              export default connect(mapStateToProps, mapDispatchToProps)(DeviceDetail);
                              
