import React from "react";
import * as _ from "lodash";
import isEqual from "lodash/isEqual";
import TreeLayout from "./TreeLayout";

const isArrayEqual = function(x, y) {
    return _(x).differenceWith(y, isEqual).isEmpty();
};

function areEqual(prevProps, nextProps) {
  if (
    !isEqual(prevProps.GroupAlarmMapper, nextProps.GroupAlarmMapper) ||
    !isEqual(prevProps.DeviceAlarmMapper, nextProps.DeviceAlarmMapper) ||
    !isEqual(prevProps.InterfaceAlarmMapper, nextProps.InterfaceAlarmMapper) ||
    !isArrayEqual(prevProps.groupData, nextProps.groupData) ||
    !isEqual(prevProps.countData, nextProps.countData) ||
    !isEqual(prevProps.openTree, nextProps.openTree) ||
    !isEqual(prevProps.acl, nextProps.acl)
  ) {
    return false;
  }

  return true;
}

export const TreeLayoutContainer = React.memo((props) => {
  return (
    <TreeLayout
      GroupAlarmMapper={props.GroupAlarmMapper}
      DeviceAlarmMapper={props.DeviceAlarmMapper}
      InterfaceAlarmMapper={props.InterfaceAlarmMapper}
      groupData={props.groupData}
      countData={props.countData}
      gridSetter={props.gridSetter}
      selectNodeFromTable={props.selectNodeFromTable}
      expandParent={props.expandParent}
      openTree={props.openTree}
      collapseTreeView={props.collapseTreeView}
      acl={props.acl}
      {...props}
    />
  );
}, areEqual);
