import React from 'react'
import './trialbar.less'
import Resource from './Resource'

const RequestTrials = ({
  daysRemaining,
  extnRequested,
  handleExtendTrials,
}) => {
  let trialbarBtnClass = 'trialbar__button'

  if (extnRequested) {
    return (
      <span className="trialbarBtnClass trialbar__label--extensionrequested">
        {//Extension message
        Resource.get('Extension requested')}
      </span>
    )
  } else if (daysRemaining) {
    return (
      <span className={trialbarBtnClass} onClick={handleExtendTrials}>
      {//Request message 
       Resource.get('Request extension')}
      </span>
    )
  } else {
    return null
  }
}

export default RequestTrials
