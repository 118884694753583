import {
  REQUEST_METRIC,
  FETCH_METRIC_SUCCESS,
  FETCH_METRIC_FAILURE,
} from './actionTypes'


export function requestMetric(metricIds) {
  return {
    type: REQUEST_METRIC,
    data: metricIds,
  }
}

export function fetchMetrics(getMetrics, metricIds) {
  return dispatch => {
    dispatch(requestMetric(metricIds))
    return getMetrics()
      .then(json => {
        dispatch(fetchMetricSuccess(json))
      })
      .catch(error => dispatch(fetchMetricFailure(error)))
  }
}

export function fetchMetricSuccess(data) {
  return {
    type: FETCH_METRIC_SUCCESS,
    data,
  }
}

export function fetchMetricFailure(error) {
  return {
    type: FETCH_METRIC_FAILURE,
    errorMessage: error.message,
  }
}

