import React, { Component } from 'react'
import {  
  BulletPointIcon as BulletPoint,  
  HelpIcon,
  ErrorIcon as Information,
} from '../ui-components/uim-components'
import {CircularProgress} from '@mineral/core'
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button as RaisedButton} from "@mineral/core"
import { Link } from 'react-router-dom'
import './installRelayHubScreen.less'
import downloads from '../../api/downloads/downloads'
import Resource from './Resource'

//Relay Hub Installers
let relayHubTitle = Resource.get('Relay Hub Installers');
//A relay hub sends metrics and alarms data to your instance of Infrastructure Management.
let relayHubLargeText = Resource.get('A relay hub sends metrics and alarms data to your instance of Infrastructure Management.');
//Pick the correct installer for your environment
let correctInstTitle = Resource.get('Pick the correct installer for your environment');
//If you will be monitoring Windows devices or a mix of Windows and Linux devices, use the Windows installer. The Linux relay hub can only monitor Linux servers.
let winInstMsg = Resource.get('If you will be monitoring Windows devices or a mix of Windows and Linux devices, use the Windows installer. The Linux relay hub can only monitor Linux servers.');
//If monitoring cloud-based infrastructure, a relay hub is needed for each isolated virtual network (such as AWS Virtual Private Cloud or Azure Virtual Network).
let cloudMsg = Resource.get('If monitoring cloud-based infrastructure, a relay hub is needed for each isolated virtual network (such as AWS Virtual Private Cloud or Azure Virtual Network).');
//Ensure the relay hub can phone home
let msg = Resource.get('Ensure the relay hub can phone home');
//Ports 48000 to 48025 must be open
let portMsgStrt = Resource.get('Ports 48000 to 48025 must be open');
//for the relay hub to communicate with the Infrastructure Management application.
let portMsgEnd = Resource.get('for the relay hub to communicate with the Infrastructure Management application.');
//The system where you install the relay hub must meet the following minimum requirements:
let installReq = Resource.get('The system where you install the relay hub must meet the following minimum requirements:');
//Requirements
let requirements = Resource.get('Requirements');
//Infrastructure Management supports the same Windows and Linux x64 operating systems that are supported for the on-premises CA UIM product. 
let suppportMsgStrt = Resource.get('Infrastructure Management supports the same Windows and Linux x64 operating systems that are supported for the on-premises CA UIM product. ');
//For more information about which operating systems are supported, refer to the
let suppportMsgEnd = Resource.get('For more information about which operating systems are supported, refer to the'); 
//Compatibility Support Matrix
let compat = Resource.get('Compatibility Support Matrix');
//Issues?
let issues = Resource.get('Issues?');
//If the relay hub does not appear within Infrastructure Management under the appropriate Operating System group after 10 minutes,  there is likely a communication issue. 
let relayhubCommStrt = Resource.get('If the relay hub does not appear within Infrastructure Management under the appropriate Operating System group after 10 minutes, there is likely a communication issue.');
//Make sure the relay hub server has an active Internet connection.
let relayhubCommEnd = Resource.get('Make sure the relay hub server has an active Internet connection.');
//For more guidance on deploying a relay hub see
let guide = Resource.get('For more guidance on deploying a relay hub see');
//windowslabel message
let windowshelplabel = Resource.get('WINDOWS');
//linuxlabel message
let linuxhelplabel = Resource.get('LINUX');
class InstallRelayHubScreen extends Component {
  state = {
    downloadLocation: null,
    errorMessage: null,
  }

  componentWillMount() {
    if (this.props.addBreadCrumb !== false) {
      this.props.addBreadCrumb({
    	 //Install Relay Hub
        name: Resource.get('Install Relay Hub'),
        link: `/install-relay-hub`,
      })
    }

    downloads
      .getDownloadLocation()
      .then(response => {
        this.setState({
          downloadLocation: response.data.entity,
          errorMessage: null,
        })
      })
      .catch(error => {
        this.setState({
          downloadLocation: null,
          //Unable to access installers
          errorMessage: Resource.get('Unable to access installers.'),
        })
      })
  }

  getInstallerDownloadButtons() {
    const downloadLoc = this.state.downloadLocation
    const errorMsg = this.state.errorMessage

    if (errorMsg !== null) {
      return (
        <div className="robot-installer__NoDownloadLocation">
          <Information className="robot-installer__errorIcon" />
          <p className="robot-installer__errorMessage">{errorMsg}</p>
        </div>
      )
    } else {
      return (
        <div>
          <div className="installScreen__cardText install__relayHub-mediumText">
          {relayHubTitle}
          </div>
          <RaisedButton
          variant="contained"
          children= {windowshelplabel}
            href={downloadLoc + '/rest/v1/download/relayhub/windows'}
            color ="primary"
            className="installScreen__hubButton"
            download="setupCAUIMSaasRelayHub_win64.exe"
          />
          <RaisedButton
          variant="contained"
            children= {linuxhelplabel}
            href={downloadLoc + '/rest/v1/download/relayhub/linux'}
            color ="primary"
            className="installScreen__hubButton"
            download="setupCAUIMSaasRelayHub_linux64.bin"
          />
        </div>
      )
    }
  }

  render() {
    let doneButton = (
      <Link to="/getting-started">
        <RaisedButton
        variant="contained"
        //DONE
          children= {Resource.get("DONE")}
          color ="primary"
          className="installScreen__doneButton"
        />{' '}
      </Link>
    )
    if (this.props.hideDoneButton) {
      doneButton = ''
    }

    if(this.props.hubsIsFetching){
      return(
        <div style={{position:'absolute',top:'50%',left:'50%' }}>
          <CircularProgress style={{color: '#3272D9'}} />
        </div>
      )
    }
    //Information
    const InformationLabel = Resource.get('Information')
    //Home
    		const HomeLabel = Resource.get('Home')
    return (
      <div className="installScreen__content">
        <div>
          <Card className="installScreen__card">
            <CardHeader disableTypography={true}
            // Download and install a relay hub
              title={Resource.get("Download and install a relay hub")}
              className="installScreen__cardTitle"
            />
            <Divider />
            <CardContent className="installScreen__cardText">
              <p className="install__relayHub-largeText">              
              {relayHubLargeText}
              </p>
              <div className="installScreen__correctInstaller">
                <p>
                  <Information
                    title= {InformationLabel}
                    className="installScreen__information--icon"
                  />
                  <span className="installScreen__correctInstaller-title">
                  {correctInstTitle}
                  </span>
                </p>
                <p>
                  <ul>
                    <li>
                    {winInstMsg}
                    </li>
                    <li>
                    {cloudMsg}
                    </li>
                  </ul>
                </p>
              </div>
              <div className="installScreen_portNotice">
                <p>
                  <Information
                    title= {InformationLabel}
                    className="installScreen__information--icon installScreen_port-notice-icon"
                  />
                  <span className="installScreen_portNotice-title">
                  {msg}
                  </span>
                </p>
                <p>
                  <ul>
                    <li>                    
                      <b>{portMsgStrt}</b> 
                      {portMsgEnd}
                    </li>
                  </ul>
                </p>
              </div>
              <CardActions className="installScreen__cardActions">
                {this.getInstallerDownloadButtons()}
              </CardActions>
            </CardContent>
          </Card>
          {doneButton}
        </div>
        <div className="installScreen__sidebar">
          <div>
            <p className="install__relayHub-mediumText">{requirements}</p>
            <p>
            {installReq}
            </p>
            <List className="installScreen__sidebar__requirementsList">
              <ListItem
                style={{ padding: 0 }}
                disabled={true}
                classes={{root: "relayList__item"}}                
               >
                 <ListItemIcon>
                   <BulletPoint className="relayList__icon" title= {HomeLabel} />
                 </ListItemIcon>
                 <ListItemText 	//4 GB of available memory
                      primary={Resource.get("4 GB of available memory")} />
                </ListItem>

              <ListItem
                style={{ padding: 0 }}
                disabled={true}
                classes={{root: "relayList__item"}}
               >
                <ListItemIcon>
                   <BulletPoint className="relayList__icon" title= {HomeLabel} />
                </ListItemIcon>
                <ListItemText 	//2 or more CPUs
                      primary={Resource.get("2 or more CPUs")} />
              </ListItem>

              <ListItem
                style={{ padding: 0 }}
                disabled={true}
                classes={{root: "relayList__item"}}
               >
                <ListItemIcon>
                   <BulletPoint className="relayList__icon" title= {HomeLabel} />
                </ListItemIcon>
                <ListItemText 	//10 GB of available disk space
                      primary={Resource.get("10 GB of available disk space")} />
              </ListItem>

              <ListItem
                style={{ padding: 0 }}
                disabled={true}
                classes={{root: "relayList__item"}}
               >
                 <ListItemIcon>
                   <BulletPoint className="relayList__icon" title= {HomeLabel} />
                </ListItemIcon>
                <ListItemText 	//Valid external IP address for the system
                      primary={Resource.get("Valid external IP address for the system")} />
              </ListItem>

              <ListItem
                style={{ padding: 0 }}
                disabled={true}
                classes={{root: "relayList__item"}}
               >
                 <ListItemIcon>
                   <BulletPoint className="relayList__icon" title= {HomeLabel} />
                </ListItemIcon>
                <ListItemText 	//Ability to connect to the Internet
                      primary= {Resource.get("Ability to connect to the Internet")} />
              </ListItem>
            </List>
            <p className="installScreen__sidebar__requirement">
            {suppportMsgStrt}
            {suppportMsgEnd}
              <a
                href="https://techdocs.broadcom.com/bin/gethidpage?hid=HID_UIM_Matrix&space=UIM204&language=&format=rendered"
                target="_blank"
                className="installScreen__sidebarSupportLink">              
                {compat}
              </a>.
            </p>
          </div>
          <div className="installScreen__background">
            <p className="installScreen__issuesHeader installScreen__sidebarHeader">
              {' '}
              {issues}{' '}
            </p>
            <p>             
            {relayhubCommStrt}
            {relayhubCommEnd}
            </p>
          </div>
          {/* <div className="setup-wizard__additional-monitors-help install__relayHub-helpText">
            {' '}            
            {guide} &nbsp;
            <FlatButton
              onClick={() =>
                window.open(
                  '',
                  '_blank',
                )}
            //HELP
              label={Resource.get("HELP")}
              icon={
                <HelpIcon
                  className="setup-wizard__additional-monitors-help-icon"
                	  //Help
                  title={Resource.get("Help")}
                />
              }
              style={{
                width: 82,
                border: '1px solid rgba(0,0,0,0.12)',
                borderRadius: 2,
              }}
              labelStyle={{ paddingRight: 10, color: 'rgba(0,0,0,0.54)' }}
            />
          </div> */}
        </div>
      </div>
    )
  }
}

export default InstallRelayHubScreen
