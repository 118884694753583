import {
  Button,
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Checkbox,
  OutlinedInput,
} from "@mineral/core";
import React, { useRef } from "react";
import { DatePicker, TimePicker } from "@material-ui/pickers";
import moment from "moment";
export const stringsFilterDropdownRenderer = ({
  filter,
  handleUpdateExtraData,
  handleSubmitFilter,
  handleUpdateSelectedOptions,
}) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const inputEl = useRef(null);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const menuItemEl = useRef(null);

  const handleClickInputsMenuItem = () => {
    inputEl.current?.querySelector("input")?.focus();
  };

  const handleKeyDown = (e) => {
    e.stopPropagation();
    if ([38, 40].includes(e.keyCode)) menuItemEl.current.focus();
  };

  return [
    filter?.options.map((option) => (
      <MenuItem component="div"
        className="filter__menu-item"
        onClick={() =>
          handleUpdateSelectedOptions({ target: { value: option?.id } })
        }
        disableRipple={true}
      >
        <FormControlLabel
          checked={filter.selectedOptions.includes(option?.id)}
          value={option?.id}
          key={`${option?.id}-radioButton`}
          control={<Radio color="primary" />}
          label={option?.name}
        />
      </MenuItem>
    )),
    <MenuItem component="div"
      ref={menuItemEl}
      onClick={handleClickInputsMenuItem}
      disableRipple={true}
      className="filter__menu-item--no-hover"
    >
      {filter?.isDate ? (
        <DatePicker
          ref={inputEl}
          className="date-time-range"
          value={filter?.extraData ? filter?.extraData : null}
          autoOk
          format="MM/DD/YYYY"
          placeholder="MM/DD/YYYY"
          //onClick={() => this.setState({ pickerOpen: true })}
          onChange={(date) =>
            handleUpdateExtraData({
              target: { value: moment(date).format("MM/DD/YYYY") },
            })
          }
          style={{
            marginRight: "5px",
          }}
          locale="en-US"
        />
      ) : (
        <OutlinedInput inputProps={{'aria-label':"Filter value of "+filter?.name}}
          ref={inputEl}
          onKeyDown={handleKeyDown}
          value={filter?.extraData}
          variant="outlined"
          size="small"
          onChange={handleUpdateExtraData}
          fullWidth
        />
      )}
    </MenuItem>,
    <MenuItem component="div"
      className="filter__menu-item--no-hover"
      onClick={handleSubmitFilter}
      disableRipple={true}
    >
      <Button
        disabled={
          filter?.selectedOptions?.length === 0 || filter?.extraData === ""
        }
        variant="contained"
        color="primary"
        fullWidth
      >
        Done
      </Button>
    </MenuItem>,
  ];
};

export const booleanFilterDropdownRenderer = ({
  filter,
  handleUpdateExtraData,
  handleSubmitFilter,
  handleUpdateSelectedOptions,
}) => {
  return [
    filter?.options.map((option) => (
      <MenuItem component="div"
        className="filter__menu-item"
        onClick={() =>
          handleUpdateSelectedOptions({ target: { value: option?.id } })
        }
        disableRipple={true}
      >
        <FormControlLabel
          checked={filter.selectedOptions.includes(option?.id)}
          value={option?.id}
          key={option?.id + "radioButton"}
          control={<Radio id={option?.id} color={"primary"} />}
          label={option?.name}
        />
      </MenuItem>
    )),

    <MenuItem component="div"
      className="filter__menu-item--no-hover"
      onClick={handleSubmitFilter}
      disableRipple={true}
    >
      <Button
        disabled={filter?.selectedOptions?.length === 0}
        variant="contained"
        color="primary"
        onClick={handleSubmitFilter}
        fullWidth
      >
        Done
      </Button>
    </MenuItem>,
  ];
};

export const multiselectFilterDropdownRenderer = ({
  filter,
  handleUpdateExtraData,
  handleSubmitFilter,
  handleUpdateSelectedOptions,
}) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const inputEl = useRef(null);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const menuItemEl = useRef(null);

  const handleClickInputsMenuItem = () => {
    inputEl.current?.querySelector("input")?.focus();
  };

  const handleKeyDown = (e) => {
    e.stopPropagation();
    if ([38, 40].includes(e.keyCode)) menuItemEl.current.focus();
  };

  const preFilters =
    filter.sortingDataType === "datetime"
      ? []
      : [
          <MenuItem component="div"
            className="filter__menu-item"
            // onClick={() => handleUpdateSelectedOptions({ target: { value: option?.id } })}
            disableRipple={true}
          >
            <FormControlLabel
              checked={filter.selectedOptions.length === filter?.options.length}
              value={"Select All"}
              key={`selectall-radioButton`}
              control={
                <Checkbox
                  color="primary"
                  onChange={handleUpdateSelectedOptions}
                />
              }
              label={"Select All"}
            />
          </MenuItem>,
        ];

  return [
    ...preFilters,
    filter?.options.map((option) => (
      <MenuItem component="div"
        className="filter__menu-item"
        onClick={(event) => {
          event.stopPropagation();
          event.preventDefault();
          handleUpdateSelectedOptions({
            target: {
              value: option?.id,
              checkbox: filter.selectedOptions.includes(option?.id),
            },
          });
        }}
        disableRipple={true}
      >
        <FormControlLabel
          checked={filter.selectedOptions.includes(option?.id)}
          value={option?.id}
          key={`${option?.id}-radioButton`}
          control={<Checkbox color="primary" />}
          label={option?.name}
        />
      </MenuItem>
    )),
    <MenuItem component="div"
      className="filter__menu-item--no-hover"
      onClick={handleSubmitFilter}
      disableRipple={true}
    >
      <Button variant="contained" color="primary" fullWidth>
        Done
      </Button>
    </MenuItem>,
  ];
};