import * as React from 'react';
import { SvgIcon } from '@mineral/core';

export const OutlinedStar01 = React.forwardRef(
  (props, ref) => (
    <SvgIcon data-testid="LearnIcon" viewBox="0 0 40 40" {...props} ref={ref}>
        <path d="M20 10.4721L21.6901 15.6738L22.1391 17.0557H23.5922H29.0615L24.6368 20.2705L23.4612 21.1246L23.9102 22.5066L25.6003 27.7082L21.1756 24.4934L20 23.6393L18.8244 24.4934L14.3997 27.7082L16.0898 22.5066L16.5388 21.1246L15.3632 20.2705L10.9385 17.0557H16.4078H17.8609L18.3099 15.6738L20 10.4721Z" fill={props.color} stroke="#1D5BBF" stroke-width="4"/>
    </SvgIcon>
  )
);
