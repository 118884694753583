import React, { Component } from 'react';
import {SearchIcon} from "../../ui-components/uim-components"
import {  List, ListItem, Avatar,Snackbar,SnackbarContent ,TextField,InputAdornment,Button,IconButton,FormControl } from '@mineral/core';
import './scopes.less';
import scopes from './../../../api/DeviceDiscovery/scopes/scopes';
import deleteIcon from '../../../assets/icons/deleteIcon.svg';
import discover from '../../../assets/icons/discoverScopes.svg';
import isEqual from 'lodash/isEqual';
import Resource from './Resource'
import "./credentialVault.less";

import { Plus as PlusIcon } from "@mineral/icons";

import AccessibleTooltip from '../../common/AccessibleTooltip'
export default class ScopesCatalog extends Component {
	constructor(props){
		  super(props);
		  this.state = {			    
				  searchQuery:'',
                  filteredScopesItems:this.props.scopesItems,
                  scopesItems: this.props.scopesItems,				  
				  deletedItem:'',
				  selectedItem: '',
				  agentId : this.props.selectedAgentInfo.id,
				  savepopUpOpen: false,
				  popMassage:""
		  }
	  }

	componentDidMount() {
		this.props.getScopes(this.state.agentId)
		this.props.pollScopesStart(this.state.agentId)
		let api ={
            refreshPostDelete:this.refreshPostDelete,
            refreshPostCreate:this.refreshPostCreate,
            refreshPostUpdate:this.refreshPostUpdate
        }
        if(this.props.OnScopesReady){
            this.props.OnScopesReady(api);
        }
	}
	
	componentWillUnmount() {
		this.props.pollScopesStop();
	}

	componentWillReceiveProps = (newProps) =>{
		if(!isEqual(newProps, this.props.scopesItems)){
		     this.setState({ scopesItems: newProps.scopesItems,
				  			 filteredScopesItems:newProps.scopesItems
		     			  });
		   }
	}
	handleSearchTextChange=(e)=> {
		const filterItem = e.target.value.replace(/^\s+/g, '');
		this.filterOnSearch(filterItem,true);
	}
	
	filterOnSearch=(filterItem,allowSearch)=> {
		let searchList=[];
		if(filterItem.length !==0) {
			searchList = this.props.scopesItems.filter(item =>{
				if(item.name.toLowerCase().indexOf(filterItem.toLowerCase()) !==-1){
					return true;
				}
			})
			 if(!isEqual(searchList,this.state.filteredScopesItems) || allowSearch) {
				 this.setState({
						searchQuery:filterItem ,
						filteredScopesItems:searchList
					});
			 }
		} else {
			this.setState({
				searchQuery:  '' ,
				filteredScopesItems:this.props.scopesItems
			});
		}	
	}

	handleClickItem =(item)=>{
		this.setState({selectedItem : item.id})
		if(item){
			scopes.getScope(this.state.agentId,item.id).then(
					response => {
						//console.log("handle click")
						this.props.populateScope(response.data,this.state.agentId);
					},
					err => {
					//console.log(err)
					})
		}else{
			this.props.populateScope(null)
		}
	}

    handleAddNewScope =()=>{
    	this.setState({selectedItem : '' })
		if(this.props){
            this.props.populateScope(null,this.state.agentId);
		}
	}
    
    refreshPostDelete= (deletedId) =>{
    	this.props.deleteScopes(deletedId)
    }
	
    handleDeleteItem =(deleteItem)=>{
		if(deleteItem){
			scopes.deleteScope(this.state.agentId,deleteItem.id).then(
					response => {
						this.refreshPostDelete(response.data.id)
                        this.setState({deletedItem:''})
                        if(this.state.selectedItem ===response.data.id ){
                      	  this.props.refreshWelcome();
                        }
						this.setState({
							savepopUpOpen: true,
							popMassage:"Removed Successfully"
						  })
					},
					err => {
					//console.log(err)
					}
			)
		}
	}
    refreshPostUpdate=(credObject) =>{
    	this.props.updateScopes(credObject)
    }
    refreshPostCreate=(credObject) =>{
        if(this.state.filteredScopesItems){
        		this.props.addScopes(credObject)
                this.setState({selectedItem:credObject.id})
        }
    }

	deleteCatalogItem = (id) => {
		this.setState({deletedItem:id})
	}
	handleNoClick = () => {
		this.setState({deletedItem:''})
	}

	
	renderCatalogList (item) {
		let removeLabel = Resource.get('Remove this Scope ?');//Remove this Scope
		let enableStyle = ["catalogListItem"];
		let enableKey = ["avatar-key"]
	     if(this.state.selectedItem ===item.id)
		 {
			enableStyle.push('enableSelectedItem');
			enableKey.push('enableSelectedItem')
		 } 
		  return (
	            <div className={enableStyle.join(' ')} style={{marginLeft: '5px'}}  ref={this.props.inputRef}>
		            <ListItem component="div"
		            key={item.id}
		            style={{marginLeft: '0px', padding:'0px',lineHeight:'14px',cursor: 'inherit',backgroundColor:'none'}}
					aria-label={item.id}
		            >
		            {this.state.deletedItem === item.id ?
		            <div style={{width:'100%',display:'flex',flexDirection:'row'}}>
		            <span style={{fontSize:'14px',fontFamily: 'Roboto',marginRight:'8px	'}}>{removeLabel}</span>
		            <Button  style={{marginRight:'8px'}} onClick={() => this.handleDeleteItem(item)}>Yes</Button>
		            <Button onClick={() => this.handleNoClick()}>No</Button>
		            </div>
		            		:
		            <div style={{display:'flex',alignItems:'center',padding:'16px',width:'100%'}}>
			            <button   className="credentialvault-transparent-btn"
                tabIndex={0} style={{display:'flex',paddingRight:'10px',textOverflow: 'ellipsis',overflow: 'hidden',whiteSpace: 'nowrap',width:'93%',cursor: 'pointer',alignItems:'center'}} onClick={() => this.handleClickItem(item)}>
			            	<Avatar className={enableKey.join(' ')} imgProps={{alt:'Discovery Scope'}}  src={discover} role="figure"
							 //Discovery Scopes
							 aria-label= {Resource.get("Discovery Scopes")}/>
			            <span style={{cursor: 'pointer',fontSize:'14px',fontFamily: 'Roboto'}} >{item.name}</span>
			            </button>
			            <div>
						<AccessibleTooltip title={`Remove discovery scope - ${item.name}`}>
                <IconButton aria-label={`Remove discovery scope - ${item.name}`}
                  style={{ float: "right", cursor: "pointer", padding: 0 }}
                  onClick={() => this.deleteCatalogItem(item.id)}
                >
			           
							<img alt={`Remove discovery scope - ${item.name}`} src={deleteIcon}
							 //Delete Icon
							 aria-label= {Resource.get("Delete Icon")}
							/>
							</IconButton>
							</AccessibleTooltip>
			            </div>
		            </div>
		            }
		            </ListItem>
					{this.state.savepopUpOpen === true? (
        <div>
           <Snackbar
            key="snackbar"
            open={this.state.savepopUpOpen}
            autoHideDuration={2000}
            onClose={() => this.setState({ savepopUpOpen: null })}
            // onActionTouchTap={this.onDescription}
            // onRequestClose={this.handleRequestClose}
            >
            <SnackbarContent
              message={this.state.popMassage}
            />
          </Snackbar>
        </div>
      ):([])}
		        </div>
		  )
	  }
	render(){
		let noItemsLabel =Resource.get('No Items Available');//No Items Available
		let catalogList='';
		if(this.state.searchQuery !=='') {
			this.filterOnSearch(this.state.searchQuery);
		}
		if(this.state.filteredScopesItems.length !==0){
			catalogList = this.state.filteredScopesItems.map(item => this.renderCatalogList(item));
		}
		 
		return(
			<div className='scopes-catalog' > 
			<FormControl style={{width:'350px'}}>
			<TextField
                autoFocus={true}
                onChange={this.handleSearchTextChange.bind(this)}
                style={{ marginLeft: "24px" ,marginTop:'8px'}}
                placeholder="Search"
                
                value={this.state.searchQuery}
                InputProps={{
           
            
           endAdornment: (
             <InputAdornment position="end" >
                <AccessibleTooltip title={"Search"}>
                 <SearchIcon  className="selecticon" style={{ height: 16 }} />
                 </AccessibleTooltip>
             </InputAdornment>
           )}}

              />
			  </FormControl>
               <AccessibleTooltip title={`Add Scopes`}>
               <IconButton
          
            onClick={this.handleAddNewScope.bind(this)}
            aria-label={`Add Scope`}
            style={{  margin: "12px" }}
          >
            <PlusIcon className="addIcon" />
          </IconButton>
</AccessibleTooltip>
				
				<div style={{ padding:'5px',backgroundColor: '#F8F8F8'}}> {catalogList.length !==0 ?
						( <List component="div" role="group"  className='list'> 
							{catalogList}
						</List> ): <div style={{marginTop:'20px',marginLeft:'20px'}}>{noItemsLabel}</div>
						}
				</div>
			</div>
		)		 
	 }
}
