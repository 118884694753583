// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Relay hubs & robots
  content["Relay hubs & robots"] =
  "リレー ハブおよびロボット";

  //Managed via
  content["Managed via"] =
  "管理手段";

  //Admin Console
  content["Admin Console"] =
  "アドミン コンソール";

  //Deleted devices
  content["Deleted devices"] =
  "削除されたデバイス";

  //Available for restore
  content["Available for restore"] =
  "リストアに使用可能";

  //Name
  content["Name"] =
  "名前";

  //IP address
  content["IP address"] =
  "IP アドレス";

  //Role
  content["Role"] =
  "役割";

  //OS Type
  content["OS Type"] =
  "OS タイプ";

  //OS Name
  content["OS Name"] =
  "OS 名";

  //OS Version
  content["OS Version"] =
  "OS バージョン";

  //Settings
  content["Settings"] =
  "設定";

  //Deleted Devices
  content["Deleted Devices"] =
  "削除されたデバイス";

  //Filter
  content["Filter"] =
  "フィルタ";

  //Cancel
  content["Cancel"] =
  "キャンセル";

  //Restore
  content["Restore"] =
  "リストア";

  // END OF LOCALIZATION

export default content;
