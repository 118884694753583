import React from "react";
import { SvgIcon } from "../ui-components/uim-components";

const Icon = (props) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4,14 L8,14 L8,10 L4,10 L4,14 L4,14 Z M4,19 L8,19 L8,15 L4,15 L4,19 L4,19 Z M4,9 L8,9 L8,5 L4,5 L4,9 L4,9 Z M9,14 L21,14 L21,10 L9,10 L9,14 L9,14 Z M9,19 L21,19 L21,15 L9,15 L9,19 L9,19 Z M9,5 L9,9 L21,9 L21,5 L9,5 L9,5 Z"
          fill={props.color?props.color:"#333840"}
        />
      </svg>
    </SvgIcon>
  );
};

export default Icon;

