// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Settings
  content["Settings"] =
  "Configuración";

  //Restmon
  content["Restmon"] =
  "Restmon";

  //Create a custom probe for monitoring via REST APIs
  content["Create a custom probe for monitoring via REST APIs"] =
  "Crear una sonda personalizada para monitorizar a través de las API de REST";

  //RESTMon
  content["RESTMon"] =
  "RESTMon";

  //Create custom RESTful API Monitoring
  content["Create custom RESTful API Monitoring"] =
  "Crear una monitorización de la API de RESTful personalizada";

  // END OF LOCALIZATION

export default content;
