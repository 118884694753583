// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Severity
  content["Severity"] =
  "Severidad";

  //Alarm Severity
  content["Alarm Severity"] =
  "Severidad de la alarma";

  //Message
  content["Message"] =
  "Mensaje";

  //Device
  content["Device"] =
  "Dispositivo";

  //Type
  content["Type"] =
  "Tipo";

  //Created
  content["Created"] =
  "Creado";

  //Duration
  content["Duration"] =
  "Duración";

  //No alarms found
  content["No alarms found"] =
  "No se han encontrado alarmas.";

  //Name
  content["Name"] =
  "Nombre";

  //OS Type
  content["OS Type"] =
  "Tipo de SO";

  //OS Name
  content["OS Name"] =
  "Nombre del SO";

  //OS Description
  content["OS Description"] =
  "Descripción del sistema operativo";

  //Primary Role
  content["Primary Role"] =
  "Rol principal";

  //IP Addresses
  content["IP Addresses"] =
  "Direcciones IP";

  //Primary MAC Address
  content["Primary MAC Address"] =
  "Dirección de MAC principal";

  //Number Of Cores
  content["Number Of Cores"] =
  "Número de núcleos";

  //Computer Name
  content["Computer Name"] =
  "Nombre del equipo";

  //VM Name
  content["VM Name"] =
  "Nombre de la máquina virtual";

  //Virtualization Environment
  content["Virtualization Environment"] =
  "Entorno de virtualización";

  //Physical Serial Number
  content["Physical Serial Number"] =
  "Número de serie físico";

  //System Name
  content["System Name"] =
  "Nombre del sistema";

  //Vendor
  content["Vendor"] =
  "Suministrador";

  //Model
  content["Model"] =
  "Modelo";

  //Firmware Version
  content["Firmware Version"] =
  "Versión de firmware";

  // ---- VMWARE ----
  //Power State
  content["Power State"] =
  "Estado de alimentación";

  //Tools Version
  content["Tools Version"] =
  "Versión de las herramientas";

  //Tools Status
  content["Tools Status"] =
  "Estado de las herramientas";

  //Resource Pool
  content["Resource Pool"] =
  "Agrupación de recursos";

  //Hostname
  content["Hostname"] =
  "Nombre de host";

  // ---- AWS ----
  //AWS Instance State
  content["AWS Instance State"] =
  "Estado de la instancia de AWS";

  //AWS Availability Zone
  content["AWS Availability Zone"] =
  "Zona de disponibilidad de AWS";

  //AWS VPC
  content["AWS VPC"] =
  "VPC de AWS";

  //AWS Instance Type
  content["AWS Instance Type"] =
  "Tipo de instancia de AWS";

  //AWS Monitoring State
  content["AWS Monitoring State"] =
  "Estado de la monitorización de AWS";

  //Max Alarm Severity
  content["Max Alarm Severity"] =
  "Nivel de severidad de alarma máximo";

  //Total Alarms
  content["Total Alarms"] =
  "Núm. total de alarmas";

  //No Roles found
  content["No Roles found"] =
  "No se ha encontrado roles.";

  // END OF LOCALIZATION

export default content;
