// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Page Not Found
  content["Page Not Found"] =
  "ページが見つかりません";

  //The requested pag ecannot be found
  content["The requested page cannot be found"] =
  "リクエストされたページが見つかりません";

  //Return to Operator Console
  content["Return to Operator Console"] =
  "オペレータ コンソールに戻る";

  // END OF LOCALIZATION

export default content;
