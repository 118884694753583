// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Name
  content["Name"] =
  "Nombre";

  //Severity
  content["Severity"] =
  "Severidad";

  //Alarm Count
  content["Alarm Count"] =
  "Recuento de alarmas";

  //Member Count
  content["Member Count"] =
  "Recuento de miembros";

  // END OF LOCALIZATION

export default content;
