export const changeCheckedSearchItems = (arr, checked, searchText, fn) => {
  if (!arr.length) return [];

  const searchItems = arr.filter(({ name }) => fn(name, searchText));

  const cb = (el) => {
    const isDevice = searchItems.find((d) => d.csId === el.csId);

    if (isDevice) el.checked = !checked;

    return el;
  };

  return arr.map(cb);
};
