import * as React from 'react';
import { SvgIcon } from '@mineral/core';

export const FilledStar04 = React.forwardRef(
  (props, ref) => (
    <SvgIcon viewBox="0 0 24 24" {...props} ref={ref}>
      <path d="M20 4L22.0902 14.2571L30.2846 7.74329L25.2927 16.9443L35.7569 17.2216L26.0186 21.0612L33.8564 28L23.9284 24.6816L25.4723 35.0351L20 26.1115L14.5277 35.0351L16.0716 24.6816L6.14359 28L13.9814 21.0612L4.24308 17.2216L14.7073 16.9443L9.7154 7.74329L17.9098 14.2571L20 4Z" fill={props.color} />
    </SvgIcon>
  )
);

export default FilledStar04;