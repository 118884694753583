import React, { Component } from 'react'
import ReportCard from './ReportCard'
import FolderCard from './FolderCard'
import CreateReportCard from './CreateReportCard'
import './reports.less'
import { withRouter } from "react-router";

class Reports extends Component {

  render() {
    const { items, cabiInfo } = this.props

    // sort the items to have Folders first, then reports
    items.sort((a,b) => {
      if(a.resourceType === 'folder' && b.resourceType === 'reportUnit'){
        // a should appear first
        return -1
      }else if(a.resourceType === 'reportUnit' && b.resourceType === 'folder'){
        return 1
      }else{
        // both are either reportUnits or folders so compare on label
        return a.label.localeCompare(b.label)
      }
    })

    return (
      <div className="report-picker">
      {this.props.location && this.props.match.params.folderIds != 'customreports' && this.props.location.pathname.endsWith('reports')? <CreateReportCard cabiInfo = {cabiInfo} /> : ''}
      {items.map((item) => {
            if(item.resourceType === 'folder'){
              return (
                <FolderCard
                  key={item.uri}
                  label={item.label}
                  uri={item.uri}
                  description={item.description}
                  reportCount={item.reportCount}
                />
              )
            }else{
              return (
                <ReportCard
                  key={item.uri}
                  label={item.label}
                  uri={item.uri}
                  reportURL={item.reportURL}
                  scheduleURL={item.scheduleURL}
                />
              )
            }
          }
        )}
      </div>
    )
  }

}


export default withRouter(Reports)
