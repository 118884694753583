// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Name
  content["Name"] =
  "名前";

  //Severity
  content["Severity"] =
  "重大度";

  //Alarm Count
  content["Alarm Count"] =
  "アラーム数";

  //Member Count
  content["Member Count"] =
  "メンバー数";

  // END OF LOCALIZATION

export default content;
