import connect from './../../utils/connect'
import McsDashboardContainer from './McsDashboard'
import {fetchGroups,fetchGroupProfiles,fetchGroupDevices,fetchDeviceByDeviceId} from '../../api/mcsDashboard/groups/actions'
import {fetchDevices,fetchDeviceProfiles,fetchProfileByProfileId} from '../../api/mcsDashboard/devices/actions'

const mapStateToProps = state => {
    return {
        groups : state.mcsDashboardReducer.groups,
        profiles : state.mcsDashboardReducer.profiles,
        devices : state.mcsDashboardReducer.devices,
		requestType : state.mcsDashboardReducer.requestType,
        dashCardView: state.mcsDashboardReducer.dashCardView,
        device:state.mcsDashboardReducer.device,
        profile : state.mcsDashboardReducer.profile,
        filter:state.mcsDashboardReducer.filter,
        errorMessage: state.mcsDashboardReducer.errorMessage,
        exportData:state.mcsDashboardReducer.exportData
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchGroups:(options)=>dispatch(fetchGroups(options)),
        fetchGroupProfiles:(options)=>dispatch(fetchGroupProfiles(options)),
        fetchGroupDevices:(options)=>dispatch(fetchGroupDevices(options)),
        //handleGroupClick: (row, history) => dispatch(fetchProfilesByGroupId(row.data.groupId)), //fetchGroupProfiles
        //handleGroupProfileClick: (row, history) => dispatch(fetchDevicesByProfileId(row.data.groupProfileId)), //fetchGroupDevices
        handleGroupDeviceClick: (row, history) => dispatch(fetchDeviceByDeviceId(row.data.csId,row.data.groupProfileId)),
        //fetchGroupsMemberDevicesByStatus : (status) => dispatch(fetchGroupsMemberDevicesByStatus(status)),
        //fetchGroupProfilesByStatus : (status) => dispatch(fetchGroupProfilesByStatus(status)),
        //fetchGroupsByStatus : (status) => dispatch(fetchGroupsByStatus(status)),
        //fetchGroupProfilesByStatusAndGroupId : (status,groupId) => dispatch(fetchGroupProfilesByStatusAndGroupId(status, groupId)),
        //fetchGroupsMemberDevicesByStatusAndProfileId : (status, groupProfileId) => dispatch(fetchGroupsMemberDevicesByStatusAndProfileId(status, groupProfileId)),

		fetchDevices:(options)=>dispatch(fetchDevices(options)),
		fetchDeviceProfiles:(options)=>dispatch(fetchDeviceProfiles(options)),
		//handleDeviceClick: (row, history) => dispatch(fetchDeviceProfilesByDeviceId(row.data.csId)), //fetchDeviceProfiles
        handleProfileClick: (row, history) => dispatch(fetchProfileByProfileId(row.data.deviceProfileId)),
        //fetchDevicesByStatus : (status) => dispatch(fetchDevicesByStatus(status)),
        //fetchProfilesByStatus : (status) => dispatch(fetchProfilesByStatus(status)),
        //fetchProfilesByStatusAndDeviceId : (status,deviceId) => dispatch(fetchProfilesByStatusAndDeviceId(status,deviceId)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(McsDashboardContainer)