import React from "react";
import connect from "./../../utils/connect";
import { Route } from "react-router-dom";
import isNaN from "lodash/isNaN";
import {
  setGroupId,
  pollGroupsStart,
  pollGroupsStop,
  requestGroup,
} from "./actions";

class Group extends React.Component {
  _getGroup = (props) => {
    const id = props.id;
    const hasComputerSystems = props.hasComputerSystems;
    // don't poll if we're looking at computer-systems
    if (hasComputerSystems) {
      return this;
    }
    this.props.stopPolling(id);
    this.props.startPolling(id);
    props.setGroupId(id);
    props.getGroup(id);
  };
  componentWillMount() {
    this._getGroup(this.props);
  }
  componentWillReceiveProps(nextProps) {
    !nextProps.location.fromGroup &&
      !nextProps.location.fromCsView &&
      this._getGroup(nextProps);
  }
  componentWillUnmount() {
    this.props.stopPolling(this.props.id);
  }
  render() {
    return null;
  }
}
const GroupsApi = (props) => {
  return (
    <Route
      path={`${props.entityMatchRegex}`}
      render={({ match }) => {
        //const hasGroups = match.params[0]
        const hasComputerSystems = match.params[1];
        const getIds = (key) =>
          (match.params[key] ? match.params[key].split("/") : [])
            .map((id) => parseInt(id, 10))
            .filter((id) => !isNaN(id));
        const groupIds = getIds("groupIds");
        //const csIds = getIds('csIds')
        let groupId = groupIds[groupIds.length - 1];
        // if (props.location.pathname.indexOf("treeviewnew") == -1) {
        //   groupId = props.location.fromTree
        //     ? props.entity.id
        //     : groupIds[groupIds.length - 1];
        // }
        //const csId = csIds[csIds.length - 1]
        return (
          <Group
            id={groupId}
            hasComputerSystems={hasComputerSystems}
            {...props}
          />
        );
      }}
    />
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getGroup: (id) => dispatch(requestGroup({ id: id })),
    setGroupId: (id) => dispatch(setGroupId(id)),
    startPolling: (id) => dispatch(pollGroupsStart(id)),
    stopPolling: (id) => dispatch(pollGroupsStop(id)),
  };
};
export default connect(null, mapDispatchToProps)(GroupsApi);
