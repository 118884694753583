import React from 'react'

const ArrowIcon = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      style={{ width: '36px', height: '36px', fillOpacity: '.54' }}
    >
      <g fill="none" fillRule="evenodd">
        <polygon
          fill="#000000"
          points="11.75 8 19.5 15 18.373 16 11.75 10.001 5.127 16 4 15"
          transform="rotate(90 11.75 12)"
        />
      </g>
    </svg>
  )
}

export default ArrowIcon
