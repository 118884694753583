import connect from './../../utils/connect'
import { fetchRobots } from './../../api/robots/actions'
import RobotDeployList from './RobotDeployList'

const mapStateToProps = state => {
  return {
   
    deployedRobots: state.robots.items,
  }
}
const mapDispatchToProps = dispatch => {
  return {
    getRobots: () => dispatch(fetchRobots()),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(RobotDeployList)
