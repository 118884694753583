import connect from './../../utils/connect'
import { createSelector } from 'reselect'
import Card from './Card'
import entityTypes from './../../api/entityTypes'
import {
  fetchHistoricAlarmsForGroup,
  fetchHistoricAlarmsForCs
 } from './../../api/historic-alarms/actions'

const getHistoricAlarmsObject = (state,ownProps) => {
  const {
    id,
    entityType,
  } = ownProps.item
  if(entityType === entityTypes.GROUP){
      return state.historicAlarmsByGroupId[id]
  }
  else{
     return state.historicAlarmsByCsId[id]
  }
}

const getChartData = createSelector(
  [getHistoricAlarmsObject],
  (historicAlarmObject) => {
    if (!historicAlarmObject) return []
    return historicAlarmObject.items
  }
)

const getIsFetching = createSelector(
  [getHistoricAlarmsObject],
  (historicAlarmObject) => {
    if(!historicAlarmObject) return false
    return historicAlarmObject.isFetching
  }
)
const isHistoricAlarmDataInvalid = createSelector(
  [getHistoricAlarmsObject],
  (historicAlarmObject) => {
    if(!historicAlarmObject) return false
    return historicAlarmObject.didInvalidate
  }
)
function mapStateToProps(state, ownProps) {
  return {
    historicAlarmsDidInvalidate: isHistoricAlarmDataInvalid(state,ownProps),
    historicAlarmsIsFetching: getIsFetching(state,ownProps),
    chartLineData: getChartData(state,ownProps),
    saas:state.saas,
    doiurl:state.user.doiurl,
    featureFlags:state.configuration.items
  };
}
const mapDispatchToProps = (dispatch) => {
  return {
    handleChartChange: (item, chartType) => {
      if (chartType === 3) {
        var params = {};
        if (item.entityType !== entityTypes.COMPUTER_SYSTEM) {
          params.groupId = item.id
          dispatch(fetchHistoricAlarmsForGroup(params))
        } else {
          params.csId = item.id
          dispatch(fetchHistoricAlarmsForCs(params))
        }

      }
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Card)