// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  // Card View
  content["Card View"] =
  "カード ビュー";

  // List View
  content["List View"] =
  "並べて表示";

  // Tree View
  content["Tree View"] =
  "ツリー ビュー";

  //Cards
  content["Cards"] =
  "カード";

  //List
  content["List"] =
  "リスト";

  //List
  content["Tree"] =
  "ツリー";

  //Detail
  content["Detail View"] =
  "詳細ビュー";

  //Alarms
  content["Alarms"] =
  "アラーム";

  //Dashboards
  content["Dashboards"] =
  "ダッシュボード";

  //Maintenance
  content["Maintenance"] =
  "メンテナンス";

  //Metrics
  content["Metrics"] =
  "メトリクス";

  //Monitoring Config
  content["Monitoring Config"] =
  "モニタリング設定";

  //Interfaces
  content["Interfaces"] =
  "インターフェース";

  // END OF LOCALIZATION

export default content;
