import { call, put, takeLatest } from 'redux-saga/effects'
import { REQUEST_REPORTS_FOLDER } from './actionTypes'
import reports from './reports'
import { fetchReportsFolderSuccess, fetchReportsFolderFailure } from './actions'


function* fetchReportFolder(action) {
  try {
    const response = yield call(reports.get, action.path, action.cabiInfo)
    yield put(fetchReportsFolderSuccess(response))
  } catch (error) {
    yield put(fetchReportsFolderFailure(error))
  }
}

export function* fetchReportFolderSaga() {
  yield takeLatest(REQUEST_REPORTS_FOLDER, fetchReportFolder)
}
