
import {SET_ENTITY,RENAME_ENTITY} from './actionTypes'

const entity = (state = {}, action) => {
  switch (action.type) {
    case SET_ENTITY:
      return action.item || state
    case RENAME_ENTITY:
      return Object.assign({},state,{
        name:action.name
      })
    default:
      return state
  }
}

export default entity
