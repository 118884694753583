import React, { useEffect } from "react";
import QosChart from "./QosChart";
import "./QosChartUrl.less";
import { useDispatch, useSelector } from "react-redux";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import { expandChart } from "../../../../../../../api/performanceReports/actions";
import {
	Select,
	MenuItem,
	FormControl,
	InputLabel,
	Tooltip,
	CircularProgress
} from "@mineral/core";
import {
	setAggregationLevel,
	setTimeFrameAction,
	findAggregationLevel
} from "../../../../../../../api/performanceReports/actions";
import { timeFrameList, aggregationOptions } from "./QosChartToolbar";
import moment from "moment";
import X2JS from "xmljson-converter";

//DateTimePicker - mui
/*import DateTextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateTimePicker from "@mui/lab/DateTimePicker";*/

import { MineralDateTimePicker } from '../../../../../../common/datepickers/MineralDateTimePicker';
import DatePickerIcon from '../../../../../../icons/DatePickerIcon';
import prd from "../../../../../../../api/performanceReports/prd";
import { has } from "lodash";

const getJsonFromXML = (xmlString) => {
	const x2js = new X2JS({
		//emptyNodeForm: "object",
		arrayAccessFormPaths: [
			"State.page.row",
			"State.page.row.chart",
			"State.page.row.chart.series",
			"State.groups.group",
			"State.groups.group.rowSelectors.rowSelector",
		],
	});
	return x2js.xml_str2json(xmlString);
};

const QosChartUrl = (props) => {
	const timeFrameRef = React.useRef();
	const [showCustomDate, setShowCustomDate] = React.useState(false);
	const [startDate, setStartDate] = React.useState(
		new Date(moment().subtract(1, "hours"))
	);
	const [endDate, setEndDate] = React.useState(new Date());

	const dispatch = useDispatch();

	const [qosData, setQosData] = React.useState(null);
	const [loadingState, setLoadingState] = React.useState("initial");

	const aggLevel = useSelector(
		(state) => state.performanceReports.chartData.State.aggLevel
	);
	const timeFrame = useSelector(
		(state) => state.performanceReports.chartData.State.timeFrame
	);

	useEffect(() => {
		if (qosData && qosData.timeData.timeFrame === timeFrameList[4]) {
			dispatch(
				setTimeFrameAction(timeFrameList[4], {
					startDate: startDate.getTime(),
					endDate: endDate.getTime(),
				})
			);
		} else if (qosData) {
			dispatch(setTimeFrameAction(qosData.timeData.timeFrame, ""));
		}
	}, [qosData, startDate, endDate, dispatch]);

	useEffect(() => {
		if (qosData && qosData.chartData) {
			dispatch(expandChart(qosData.chartData.chartId));
			if (qosData.timeData.timeFrame === timeFrameList[4]) {
				dispatch(
					setTimeFrameAction(timeFrameList[4], {
						startDate: +qosData.timeData.startTime,
						endDate: +qosData.timeData.stopTime,
					})
				);
			} else {
				dispatch(setTimeFrameAction(qosData.timeData.timeFrame, ""));
			}
			dispatch(setAggregationLevel(
				qosData.timeData.aggregationInterval && qosData.timeData.aggLevel ? qosData.timeData.aggLevel : 'Automatic'
			));
			if (qosData.timeData.timeFrame === timeFrameList[4]) {
				setStartDate(new Date(+qosData.timeData.startTime));
				setEndDate(new Date(+qosData.timeData.stopTime));
			}
		}
	}, [dispatch, qosData]);

	const parseReportWithCustomSourceAndTarget = (chartData) => {
		const tempChartData = Object.assign({}, chartData);
		const params = new URLSearchParams(props.location.search);
		let source = params.get("source");
		let target = params.get("target");

		if (!source && !target) {
			return tempChartData;
		}

		if (Array.isArray(chartData.series)) {
			const tempSeriesData = [
				...chartData.series
			]
			tempChartData.series = tempSeriesData.filter((i) => {
				if (source && target) {
					if (i.source === source && i.target === target) {
						return true
					}
				} else {
					if (source && i.source === source) {
						return true
					}
					if (target && i.target === target) {
						return true
					}
				}

				return false;
			});
		}

		return tempChartData;
	};

	const getChartDataUsingReportName = async (reportName) => {
		try {
			const params = new URLSearchParams(props.location.search);
			const chartId = params.get("chartId");
			const response = await prd.loadReport(reportName);
			const data = response.data;
			if (data.reportStructure !== "" && data.reportStructure !== null) {
				const newData = getJsonFromXML(data.reportStructure);
				let chartData = null;
				let timeData = null;

				if (chartId) {
					if (
						has(newData, "State.page.row[0].chart") &&
						Array.isArray(newData.State.page.row[0].chart)
					) {
						const tempChartData = newData.State.page.row[0].chart.find(
							(i) => i.chartId == chartId
						);
						if (tempChartData) {
							chartData = tempChartData;
						}
					}
				} else if (has(newData, "State.page.row[0].chart[0]")) {
					chartData = newData.State.page.row[0].chart[0];
				}

				if (chartData) {
					chartData.legend.table = chartData.legend.table === "true";
					const aggrigationData = findAggregationLevel(
						parseInt(newData.State.aggregationInterval),
						parseInt(newData.State.TimeStart),
						parseInt(newData.State.TimeStop)
					);

					timeData = {
						aggregationInterval: parseInt(newData.State.aggregationInterval),
						aggLevel: aggrigationData.aggregationInterval || "Automatic",
						timeFrame: newData.State.timeFrame?newData.State.timeFrame:aggrigationData.timeFrame,
						startTime: parseInt(newData.State.TimeStart),
						stopTime: parseInt(newData.State.TimeStop),
					};

					chartData = parseReportWithCustomSourceAndTarget(chartData);
					setQosData({ chartData, timeData, source: "", qosValue: "" });
					setLoadingState("complete");
				} else {
					setQosData(null);
					setLoadingState("noContent");
				}
			}
		} catch (ex) {
			console.log(ex);
		}
	};

	useEffect(() => {
		const searchQuery = props.location.search;
		if (searchQuery) {
			console.log(searchQuery)
			const params = new URLSearchParams(searchQuery);
			const paramsData = params.get("params");
			const reportName = params.get("report");

			if (paramsData) {
				setQosData(JSON.parse(window.atob(decodeURIComponent(paramsData))));
				setLoadingState("complete");
			} else if (reportName) {
				getChartDataUsingReportName(reportName);
			} else {
				alert("Either params or QOS Id is required");
			}
		} else {
			alert("Search Query not found")
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.location.search]);

	return (
		<div
			className="chart-url-container"
			style={{
				width: "100vw",
				height: "100vh",
				display: "flex",
				flexFlow: "column nowrap",
				alignItems: "center",
			}}
		>
			{!qosData && loadingState === "initial" ? (
				<CircularProgress />
			) : !qosData && loadingState === "noContent" ? (
				<div>
					<p>No data found</p>
				</div>
			) : (
				<>
					<div className="toolbar-left-action-content">
						<FormControl className="timeFrame-dropdown">
							<InputLabel id="tame-frame-select-label">Time Frame</InputLabel>
							<Select
								labelId="tame-frame-select-label"
								ref={timeFrameRef}
								id="time-range-select"
								inputProps={{
									role: 'combobox'
								}}
								value={timeFrame ? timeFrame : "Custom"}
								onChange={(event) => {
									let timeFrameValue = event.target.value;
									if (timeFrameValue === timeFrameList[4]) {
										setShowCustomDate(true);
										dispatch(
											setTimeFrameAction(timeFrameList[4], {
												startDate: startDate.getTime(),
												endDate: endDate.getTime(),
											})
										);
									} else {
										setShowCustomDate(false);
										dispatch(setTimeFrameAction(timeFrameValue, ""));
									}
									dispatch(setAggregationLevel(aggLevel));
								}}
							>
								{timeFrameList.map((source, index) => (
									<MenuItem

										key={`${source}-${index}-menuitem`}
										value={source}
									>
										{source}
									</MenuItem>
								))}
							</Select>
						</FormControl>

						{timeFrame == timeFrameList[4] && (
							<>
								<div className="chart-url-date-picker">

									<div
										style={{
											display: "flex",
											flexFlow: "column nowrap",
											marginRight: "8px",
										}}
									>

										<MineralDateTimePicker
											value={startDate}
											onValueChange={(event) => {

												setStartDate(moment(event).toDate());
											}}


											variant="inline"
											maxDateTime={endDate}
											keyboardIcon={<DatePickerIcon />}
											label="Start Date"
											ampm={false}
										/>
									</div>

									<div
										style={{
											display: "flex",
											flexFlow: "column nowrap",
											marginRight: "8px",
										}}
									>
										<MineralDateTimePicker
											value={endDate}
											onValueChange={(event) => {
												setEndDate(moment(event).toDate());
											}}


											variant="inline"
											minDateTime={new Date(moment(startDate).add(1, "hours"))}
											maxDateTime={new Date()}
											keyboardIcon={<DatePickerIcon />}
											label="End Date"
											ampm={false}
										/>

									</div>

								</div>
							</>
						)}

						<FormControl className="timeFrame-dropdown">
							<InputLabel id="agg-level-select-label">
								Aggregation Interval
							</InputLabel>
							<Select


								id="agg-level-select"

								aria-labelledby="agg-level-select-label"

								inputProps={{
									'aria-label': " Aggregation Interval",
									role: 'combobox',
								}}
								MenuProps={{
									MenuListProps: {
										'aria-label': `Aggregation Interval options list`,
										'aria-labelledby': "agg-level-select-label",

									}
								}}


								value={aggLevel ? aggLevel : "Automatic"}
								onChange={(event) => {
									dispatch(setAggregationLevel(event.target.value));
								}}
							>
								{aggregationOptions.map((source, index) => (
									<MenuItem

										key={`${source}-${index}-agglevel`}
										value={source}
									>
										{source}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</div>
					<div className="chart-container">
						<DndProvider backend={HTML5Backend}>
							<QosChart
								chartData={qosData.chartData}
								data={{
									source: qosData.source,
									qosValue: qosData.qosValue,
									target: qosData.target,
								}}
								fromUrl
							/>
						</DndProvider>
					</div>
				</>
			)}
		</div>
	);
};
export default QosChartUrl;