import {
  FILTERED_INVENTORY_ROWS 
  } from './actionTypes'
  
  
  const filteredInventoryData = (state = '', action) => {
    switch (action.type) {
    case FILTERED_INVENTORY_ROWS:
      return action.items
    default:
      return state
    }
  }
  
  export default filteredInventoryData