import React from 'react'
import {Route} from 'react-router-dom'
import { CenterError as Error } from '../components/ui-components/uim-components'

const Unauthorized = () => {
  return (
    <main>
      <Error style={{height: 'calc(100vh - 205px)'}}
        title='Unauthorized'
        message='You have invalid credentials. Please return to the login page.'/>
    </main>
  )
}

const UnauthorizedRoute = () => {
  return (
    <Route path='/401' component={Unauthorized}/>
  )
}

export default UnauthorizedRoute