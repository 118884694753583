import { call, put, takeEvery, takeLatest, take, cancel } from 'redux-saga/effects'
import { delay } from 'redux-saga/effects'
import { GET_WMI_CREDENTIALS,POLL_WMI_START,POLL_WMI_STOP,GET_SNMP_CREDENTIALS,POLL_SNMP_START,POLL_SNMP_STOP } from './actionTypes'
import { fetchWMISuccess, fetchWMIFailure,fetchsnmpSuccess,fetchsnmpFailure } from './actions'
import credentials from './credentials';

// worker Saga: will be fired on GET_WMI_CREDENTIALS actions
function* fetchWMICreds() {
  try {
    const response = yield call(credentials.getWMI)
    yield put(fetchWMISuccess(response.data))
  } catch (error) {
    yield put(fetchWMIFailure(error))
  }
}
function* fetchSNMPCreds() {
  try {
    const response = yield call(credentials.getSNMP)
    yield put(fetchsnmpSuccess(response.data))
  } catch (error) {
    yield put(fetchsnmpFailure(error))
  }
}

function* pollWMICreds() {
  while (true) {
    yield delay(30000)
    yield call(fetchWMICreds)
  }
}
function* pollsnmpCreds() {
  while (true) {
    yield delay(30000)
    yield call(fetchSNMPCreds)
  }
}
/*
  Starts fetchWMICreds on each dispatched `GET_WMI_CREDENTIALS` action.
  Allows concurrent fetches of user.
*/
export function* getWmiSaga() {
  yield takeEvery(GET_WMI_CREDENTIALS, fetchWMICreds)
}

export function* getsnmpSaga() {
  yield takeEvery(GET_SNMP_CREDENTIALS, fetchSNMPCreds)
}


export function* wmiPollingSaga() {
  while (true) {
    const watcherInstance = yield takeLatest(POLL_WMI_START, pollWMICreds)
    // cancel polling on POLL_HUBS_STOP
    yield take(POLL_WMI_STOP)
    yield cancel(watcherInstance)
  }
}

export function* snmpPollingSaga() {
  while (true) {
    const watcherInstance = yield takeLatest(POLL_SNMP_START, pollsnmpCreds)
    // cancel polling on POLL_HUBS_STOP
    yield take(POLL_SNMP_STOP)
    yield cancel(watcherInstance)
  }
}


