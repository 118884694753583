// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //settings
  content["Settings"] =
  "Einstellungen";

  //alarm policies
  content["Alarm Policies"] =
  "Alarmrichtlinien";

  // END OF LOCALIZATION

export default content;
