// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Robot installer download and instructions
  content["Robot installer download and instructions"] =
  "ロボット インストーラのダウンロードおよび手順";

  //via relay hub
  content["Robots manage the probes (monitoring agents) used to collect metric data from your systems, sending the data to Infrastructure Management via the hub."] =
  "ロボットは、システムからのメトリクス データの収集に使用されるプローブ (モニタリング エージェント) を管理し、ハブ経由で、データを Infrastructure Management に送信します。";

  //ROBOT INSTALLERS
  content["ROBOT INSTALLERS"] =
  "ロボット インストーラ";

  // Setup Wizard
  content["Setup Wizard"] =
  "セットアップ ウィザード";

  //Robot deployment
  content["Robot deployment"] =
  "ロボット展開";

  //Deploy
  content["Deploy"] =
  "展開";

  //Add Devices
  content["Add Devices"] =
  "デバイスの追加";

  //Windows devices are not supported on this monitoring technology
  content["Windows devices are not supported on this monitoring technology"] =
  "このモニタリング テクノロジでは Windows デバイスはサポートされていません";

  //OK
  content["OK"] =
  "OK";

  //Windowds deploy help message
  content["A Windows relay hub must be selected to deploy a Windows device."] =
  "Windows デバイスを展開するには、Windows リレー ハブを選択する必要があります。";

  //IP Address Required
  content["IP Address Required"] =
  "IP アドレスが必要";

  //No duplicate IPs
  content["No duplicate IPs"] =
  "重複する IP はありません";

  //Required
  content["Required"] =
  "必須";

  //Username
  content["Username"] =
  "ユーザ名";

  //Username Required
  content["Username Required"] =
  "ユーザ名必須";

  //Password
  content["Password"] =
  "パスワード";

  //Password Required
  content["Password Required"] =
  "パスワード必須";

  //Sudo Password Required Message
  content["Sudo Password is only applicable for Linux systems where username is not \'root\'."] =
  "sudo パスワードは、ユーザ名が \'root\ ではない Linux システムにのみ適用できます。";

  //Sudo password
  content["Sudo password"] =
  "Sudo パスワード";

  //OS
  content["OS"] =
  "OS";

  //CentOS
  content["CentOS"] =
  "CentOS";

  //Debian
  content["Debian"] =
  "Debian";

  //Open SUSE
  content["openSUSE"] =
  "openSUSE";

  //RHEL
  content["RHEL"] =
  "RHEL";

  //SUSE
  content["SUSE"] =
  "SUSE";

  //Ubuntu
  content["Ubuntu"] =
  "Ubuntu";

  //solaris
  content["Solaris"] =
  "Solaris";

  //aix
  content["AIX"] =
  "AIX";

  //Windows
  content["Windows"] =
  "Windows";

  //help message of deploying on linux hub
  content["Windows can not be deployed on a UNIX hub"] =
  "UNIX ハブ上に Windows を展開できません。";

  //Profile Required
  content["Profile Required"] =
  "プロファイル要求";

  //ARCH
  content["ARCH"] =
  "ARCH";

  //64-bit
  content["64-bit"] =
  "64 ビット";

  //32-bit
  content["32-bit"] =
  "32 ビット";

  //sparcv9/sparc
  content["sparcv9"] =
  "sparcv9";

  //Architecture Required
  content["Architecture Required"] =
  "アーキテクチャ指定";

  //Add Row
  content["Add Row"] =
  "行の追加";

  //Duplicate Row
  content["Duplicate Row"] =
  "重複行";

  //Device IP
  content["Device IP"] =
  "デバイス IP";

  //Architecture
  content["Architecture"] =
  "アーキテクチャ";

  //Sudo password (Linux only)
  content["Sudo password (Linux only)"] =
  "sudo パスワード (Linux のみ)";

  //Too many robots deployed
  content["Too many robots deployed"] =
  "展開されるロボットの数が多すぎます";

  // A CSV file was already imported
  content["A CSV file was already imported"] =
  "CSV ファイルはすでにインポートされています";

  //Delete Row
  content["Delete Row"] =
  "行の削除";

  //AUTO DEPLOY
  content["AUTO DEPLOY"] =
  "自動展開";

  //Failed Robot Deployment
  content["Failed Robot Deployment"] =
  "失敗したロボット展開";

  //Check your server for proper configuration
  content["Check your server for proper configuration."] =
  "サーバの設定が適切かどうか確認してください。";

  //Click "Deploy" to add the devices to UIM
  content["Click Deploy to add the devices to UIM. Enabling monitoring happens on the next step."] =
  "UIM にデバイスを追加するには、[展開] をクリックします。次の手順で、モニタリングが有効になります。";

  //Click deploy to install the monitoring host.  When complete, click Next.
  content["Click deploy to install the monitoring host.  When complete, click Next."] =
  "モニタリング ホストをインストールするには、[展開] をクリックします。完了したら、[次へ] をクリックします。";

  // with the selected hub
  content["Devices added must be able to communicate with the selected  hub."] =
  "追加されたデバイスは、選択されたハブと通信可能である必要があります。";

  //Ports 48000 to 48025 must be open
  content["Ports 48000 to 48025 must be open"] =
  "ポート 48000 から 48025 までがオープンである必要があります";

  //for the  hub to communicate with the CA Infrastructure Management application.
  content["for the  hub to communicate with the CA Infrastructure Management application."] =
  "(ハブが CA Infrastructure Management アプリケーションと通信するために)。";

  //If monitoring cloud-based infrastructure, a  hub is needed for each isolated virtual network (such as AWS Virtual Private Cloud or Azure Virtual Network).
  content["If monitoring cloud-based infrastructure, a  hub is needed for each isolated virtual network (such as AWS Virtual Private Cloud or Azure Virtual Network)."] =
  "クラウド ベースのインフラストラクチャをモニタする場合、各隔離仮想ネットワーク (AWS Virtual Private Cloud または Azure Virtual Network など) に対してハブが必要です。";

  //Ensure that the
  content["Ensure that the "] =
  "次を確認します ";

  //If you are configuring monitoring technologies supported only on Windows, such as Windows NT Services, select a Windows  hub.
  content["If you are configuring monitoring technologies supported only on Windows, such as Windows NT Services, select a Windows  hub."] =
  "Windows 上でのみサポートされるモニタリング テクノロジ (Windows NT サービスなど) を設定している場合は、Windows ハブを選択します。";

  //Select hub
  content["Select hub"] =
  "ハブの選択";

  // No hub selected
  content["*No hub selected"] =
  "*ハブが選択されていません";

  //Select a hub
  content["Select a hub"] =
  "ハブの選択";

  // ADD  HUB...
  content["ADD  HUB..."] =
  "ハブの追加...";

  //If you are not installing with root access, use either sudo
  content["If you are not installing with root access, use either sudo"] =
  "root アクセスでインストールを行わなかった場合は、次のいずれかを使用します: sudo";

  //You can also use su to get a root shell and execute the command.
  content["You can also use su to get a root shell and execute the command."] =
  "su を使用して root シェルを取得し、コマンドを実行することもできます。";

  //Copy the appropriate Windows installer to any folder on the target system:
  content["Extract the zip file and copy the appropriate Windows installer to any folder on the target system:"] =
  "Zip ファイルを解凍し、適切な Windows インストーラをターゲット システム上の任意のフォルダにコピーします。";

  //Copy the nms-robot-vars.cfg answer file to the same folder.
  content["Copy the nms-robot-vars.cfg answer file to the same folder and define the following parameters:"] =
  "nms-robot-vars.cfg 応答ファイルを同じフォルダにコピーし、次のパラメータを定義します:";

  //update hub ip address in the nms-robot-vars.cfg file
  content["hubip: IP address of the server configured as the relay hub."] =
  "hubip: リレー ハブとして設定されているサーバの IP アドレス。";

  //update robot ip address in the nms-robot-vars.cfg file
  content["robotip: IP address of the server where you will execute the installer."] =
  "robotip: インストーラを実行するサーバの IP アドレス。";

  //Install the robot by executing:
  content["Install the robot by executing:"] =
  "次を実行してロボットをインストールします。";

  //Installation
  content["Installation"] =
  "インストール";

  //Robot Installers (including answer file)
  content["Robot Installers (including answer file)"] =
  "ロボット インストーラ (応答ファイルを含む)";

  //The installation is complete. To start the robot, execute the following command:
  content["The installation is complete. To start the robot, execute the following command:"] =
  "インストールは完了です。ロボットを開始するには、次のコマンドを実行します:";

  //Execute the RobotConfigurer.sh script to configure the robot when the installer exits.
  content["Execute the RobotConfigurer.sh script to configure the robot when the installer exits."] =
  "インストーラ終了時にロボットを設定するには、RobotConfigurer.sh スクリプトを実行します。";

  //The rpm flags function as follows:
  content["The rpm flags function as follows:"] =
  "rpm フラグは次のように機能します:";

  //The default install directory is /opt/nimsoft. To specify the installation directory, execute the following command, where <directory> is the full path:
  content["The default install directory is /opt/nimsoft. To specify the installation directory, execute the following command, where <directory> is the full path:"] =
  "デフォルトのインストール ディレクトリは、/opt/nimsoft です。インストール ディレクトリを指定するには、次のコマンドを実行します。ここで、<directory> はフル パスです:";

  //Copy the appropriate Linux installer to /opt on the target system:
  content["Extract the zip file and copy the appropriate Linux installer to /opt on the target system:"] =
  "Zip ファイルを解凍し、適切な Linux インストーラをターゲット システム上の /opt にコピーします。";

  //Install the robot with the following command, where &lt;arch&gt;is i386 or x86_64:
  content["Install the robot with the following command, where <arch> is i386 or x86_64:"] =
  "次のコマンドでロボットをインストールします。ここで、<arch> は i386 または x86_64 です:";

  //The default install folder is C:\Program Files (x86)\Nimsoft for 32-bit systems, and C:\Program Files\Nimsoft for 64-bit systems.
  content["The default install folder is C:\\Program Files (x86)\\Nimsoft for 32-bit systems, and C:\\Program Files\\Nimsoft for 64-bit systems."] =
  "デフォルトのインストール フォルダは、32 ビット システムの場合は C:\\Program Files (x86)\\Nimsoft、64 ビット システムの場合は C:\\Program Files\\Nimsoft です。";

  //To specify the folder, append the following parameter to the command. Quotation marks are required.
  content["To specify the folder, append the following parameter to the command. Quotation marks are required."] =
  "フォルダを指定するには、次のパラメータをコマンドの後ろに追加します。引用符が必要です。";

  //To specify the log file, append:
  content["To specify the log file, append:"] =
  "ログ ファイルを指定するには、次を追加します。";

  //After installation, the robot starts automatically.
  content["After installation, the robot starts automatically."] =
  "インストール後に、ロボットは自動的に開始します。";

  //Copy the Debian installer to /opt on the target system:
  content["Extract the zip file and copy the Debian installer to /opt on the target system:"] =
  "Zip ファイルを解凍し、Debian インストーラをターゲット システム上の /opt にコピーします。";

  //Note that only 64 bit Debian systems are supported as robots.
  content["Note that only 64 bit Debian systems are supported as robots."] =
  "64 ビット Debian システムのみがロボットとしてサポートされていることに注意してください。";

  //Install the robot with the following command:
  content["Install the robot with the following command:"] =
  "次のコマンドでロボットをインストールします:";

  //Copy the Ubuntu installer to /opt on the target system:
  content["Extract the zip file and copy the Ubuntu installer to /opt on the target system:"] =
  "Zip ファイルを解凍し、Ubuntu インストーラをターゲット システム上の /opt にコピーします。";

  //Note that only 64 bit Ubuntu systems are supported as robots.
  content["Note that only 64 bit Ubuntu systems are supported as robots."] =
  "64 ビット Ubuntu システムのみがロボットとしてサポートされていることに注意してください。";

  //(Ubuntu 16 only) Execute the following command to enable the robot service. Note: This step is not required for Ubuntu 14.
  content["(Ubuntu 16 only) Execute the following command to enable the robot service. Note: This step is not required for Ubuntu 14."] =
  "(Ubuntu 16 のみ) ロボット サービスを有効にするには、以下のコマンドを実行します。注: この手順は、Ubuntu 14 では必要ありません。";

  //Copy the solaris installer to /opt on the target system:
  content["Extract the zip file and copy the Solaris installer to /opt on the target system:"] =
  "Zip ファイルを解凍し、Solaris インストーラをターゲット システム上の /opt にコピーします。";

  //extract the solaris installer to /opt on the target system:
  content["Extract the gz file into to /opt on the target system:"] =
  "gz ファイルをターゲット システム上の /opt に解凍します。";

  //Copy the aix installer to /opt on the target system:
  content["Extract the zip file and copy the AIX installer to /opt on the target system:"] =
  "Zip ファイルを解凍し、AIX インストーラをターゲット システム上の /opt にコピーします。";

  //Note that only 64 bit AIX systems are supported as robots.
  content["Note that only 64 bit AIX systems are supported as robots."] =
  "64 ビット AIX システムのみがロボットとしてサポートされていることに注意してください。";

  //Execute the following command as the root user to install the robot to the default directory
  content["Install the robot with the following command as a root user."] =
  "root ユーザとして、次のコマンドでロボットをインストールします。";

  //Enable the Nimbus service as root
  content["Enable the Nimbus service as root."] =
  "root として Nimbus サービスを有効にします。";

  //Start the robot as root. If the robot is configured to run as a nonroot account, the processes are running as the nonroot user.
  content["Installation is complete.Start the robot as root. If the robot is configured to run as a nonroot account, the processes are running as the nonroot user."] =
  "インストールが完了しました。root として、ロボットを開始してください。root 以外のアカウントとして実行するようにロボットが設定されている場合は、プロセスは root 以外のユーザとして実行されます。";

  //To run the robot as a nonroot user account, take the following steps
  content["Steps to run the robot as a nonroot user account"] =
  "root 以外のユーザ アカウントとしてロボットを実行するステップ";

  //Add the nonroot user to
  content["Add the nonroot user to"] =
  "root 以外のユーザを次に追加";

  //Change the ownership of the
  content["Change the ownership of the"] =
  "次の所有権の変更";

  //file, and the UIM installation to the nonroot user
  content["file, and the UIM installation to the nonroot user:"] =
  "ファイル、および root 以外のユーザへの UIM インストール:";

  //installing message
  content["install the software package"] =
  "ソフトウェア パッケージのインストール";

  //status message
  content["display a simple status line to show what is being installed (verbose mode)"] =
  "インストール内容を示す単純なステータス行を表示します (詳細モード)";

  // display message
  content["display fifty hash marks (#) to show the status as thevinstall proceeds; when all fifty have displayed, installation is complete."] =
  "インストールの進捗状況のステータスを示す、50 個のハッシュ記号 (#) を表示します。50 個のハッシュ記号がすべて表示されると、インストールが完了します。";

  //RHEL & CentOS
  content["RHEL & CentOS"] =
  "RHEL および CentOS";

  // END OF LOCALIZATION

export default content;
