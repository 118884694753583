export const COMPONENT_NAME = `@uim/operatorconsole-ui`
export const VERSION = `0.1.0`
export const BUILD_DATE = `2023-06-29T18:14:10.174Z`
export const BUILD_NUMBER = `0`
export const BRANCH = `master`
export const COMMIT_ID = `e40a9976d7a7d8ff2d8da46703b0af34a42caab8`
// export const TAGS = '${git.tags}';
// export const DIRTY = '${git.dirty}';
// export const DESCRIBE = '${git.commit.id.describe}';
// export const COMMIT_TIME = '${git.commit.time}';
// export const CLOSEST_TAG_NAME = '${git.closest.tag.name}';
// export const CLOSEST_TAG_COMMIT_COUNT = '${git.closest.tag.commit.count}';

export default {
  COMPONENT_NAME,
  VERSION,
  BUILD_DATE,
  BUILD_NUMBER,
  BRANCH,
  COMMIT_ID,
}
