import React, {Component} from 'react';
import connect from './../../utils/connect';

class SettingsContainer extends Component {

  render() {
    return (
        <div className='settings-section'>
          <div className="settings-section-header">
            <h2>{this.props.label}</h2>
          </div>
          <div className='settings-section-cards'>
            {this.props.children}
          </div>
        </div>
    );
  };
}

export default connect(null, null)(SettingsContainer);
