import React, { useEffect } from 'react'


import {Dialog, DialogActions,DialogContent,DialogTitle,Button,Typography, IconButton,Tooltip,Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel } from '@mineral/core';
import "./discoveryConfiguration.less"
import * as constant from "./DiscoveryConstants";

import schedule from "./../../../api/DeviceDiscovery/schedule/scheduleDiscovery";
import { 
    fetchAgentsStatus,
    pollAgentsStatusStart,
    pollAgentsStatusStop, 
} from '../../../api/DeviceDiscovery/agents/actions';
import Resource from './Resource'
import {
    ClearIcon,
   
  } from "../../ui-components/uim-components";

    
import { useDispatch } from 'react-redux';


const CustomScopeDiscoveryDialog = (props) => {
    const dispatch = useDispatch()
    
    let isPollingStarted = false;
    const buttonLabelStyle = { 'paddingRight': '20px', 'paddingLeft': '20px' ,textTransform:'none'};
    const buttonStyle = {height:32,lineHeight:'32px',textTransform:'none'};
    const selectedNetworkIDs = props.selected.map(item => item.id).join()
    

    const handleChange = (event, option) => {    
        if (event.target.checked) {
            props.setSelected([ ...props.selected, option ])

        } else {
            const selectedItems = props.selected.filter(item => item.id !== option.id)
            props.setSelected(selectedItems)
        }   

        return;
    };

    const fetchAgents = () => {
        dispatch(fetchAgentsStatus())
    }

    const pollAgentsStart = () => {
        dispatch(pollAgentsStatusStart())
    }

    const pollAgentsStop = () => {
        dispatch(pollAgentsStatusStop())
    }
    
    const handleRunDiscoveryClick = () => {
        let discoveryNowPromiseRef = schedule.runDiscovery(props.data[0].id, false, selectedNetworkIDs);
        let msg = 'No Modifications Found';
        
        if(discoveryNowPromiseRef) {
            props.showJobStatusHandler();
            props.initialJobFetchInProgressHandler(true);
        }
        

        discoveryNowPromiseRef.then(res=>{
            msg = Resource.get('Discovering')//Discovering
            isPollingStarted = true;
            fetchAgents();
            pollAgentsStart();
            props.showJobStatusHandler();
            props.initialJobFetchInProgressHandler(true);
            props.isProgressNeededHandler();
            props.statusMsgHandler(msg)
        },err =>{
            props.showJobStatusHandler();
            props.statusMsgHandler('Error on submitting Discovery Job')
            props.initialJobFetchInProgressHandler(false);
        })
        props.openDiscoveryRunProgressDialog();
        props.onClose()
    }

    useEffect(() => {
        return () => {
         if (isPollingStarted) {
            pollAgentsStop()
         }
        }
      }, [])

    return (
        <Dialog maxWidth="xs"  open={props.open} onClose={props.onClose}>
            <DialogTitle component="div" disableTypography>
           
               <Typography component="h1" variant="h3"> {constant.CUSTOM_SCOPE_DISCOVERY_TITLE}</Typography>
              
              
             
               
            </DialogTitle>
            
            <div style={{height:'0px'}}>
            <Tooltip title={'Close'}>
              <IconButton autoFocus style={{float:'right',marginRight:'8px',top:'-50px'}} onClick={props.onClose} aria-label="Close">
                <ClearIcon className="clearIcon" aria-label="Close" role="button" sx={{ fontSize: '24px' }} />
              </IconButton>
            </Tooltip>
            </div>

            <DialogContent style={{ overflowX: "hidden",marginTop:'8px' }}>
            <FormLabel color="primary">{props.agentInfo}</FormLabel>
                <FormControl style={{width: "100%"}}>
                    <FormGroup >
                    {props.data[0]?.children.map(option => 
                            <FormControlLabel key={option.id}  style={{marginTop:'8px'}}
                      label={option.name}
                      control={
                        <Checkbox
                         style={{marginRight:'4px'}}
                       
                          onChange={(e) => handleChange(e, option)} 
                          checked={props.selected.includes(option)}
                          color="primary"
                        />
                      }
                    />
                            
                            )
                            }
                    </FormGroup>
                </FormControl>

            </DialogContent>

            <DialogActions>
                <Button 
                    color="primary" 
                    variant="contained" 
                    onClick={handleRunDiscoveryClick}
                    style ={buttonStyle}
                    label={buttonLabelStyle}
                    disabled={props.selected.length === 0}
                    >
                        {constant.RUN_DISCOVERY}
                </Button>

                <Button 
                    color="primary" 
                    variant="contained" 
                    style ={buttonStyle}
                    onClick={props.onClose}
                    label={buttonLabelStyle}
                >
                        {constant.CANCEL}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default CustomScopeDiscoveryDialog
