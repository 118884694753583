import axios from 'axios'
import get from 'lodash/get'


class AdminConsole {
  getAdminConsoleURL() {
      const url = '/operatorconsole_portlet/api/v1/wasp/adminconsoleurl'
          return axios.get(url)
  }
}

const adminConsole = new AdminConsole()
export default adminConsole
