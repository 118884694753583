import {
  REQUEST_DISCOVERY,
  FETCH_DISCOVERY_SUCCESS,
  FETCH_DISCOVERY_FAILURE,
  REQUEST_POST_DISCOVERY,
  POST_DISCOVERY_SUCCESS,
  POST_DISCOVERY_FAILURE,
} from './actionTypes'
import discovery from './../../api/discovery/discovery'

export function requestDiscovery() {
  return {
    type: REQUEST_DISCOVERY,
  }
}

export function requestPostDiscovery() {
  return {
    type: REQUEST_POST_DISCOVERY,
  }
}

export function fetchDiscoverySuccess(json) {
  return {
    type: FETCH_DISCOVERY_SUCCESS,
    discovery: json,
  }
}

export function fetchDiscoveryFailure(error) {
  return {
    type: FETCH_DISCOVERY_FAILURE,
    errorMessage: error.message,
  }
}

export function postDiscoverySuccess(json) {
  return {
    type: POST_DISCOVERY_SUCCESS,
    discovery: json,
  }
}

export function postDiscoveryFailure(error) {
  return {
    type: POST_DISCOVERY_FAILURE,
    errorMessage: error.message,
  }
}

export function postDiscovery(params) {
  return dispatch => {
    dispatch(requestPostDiscovery())
    return discovery.post(params)
  }
}
