// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Capacity predective Analytics
  content["Capacity Predictive Analytics"] =
  "Análisis predictivos de capacidad";

  //Communication Error
  content["Communication Error"] =
  "Error de comunicación";

  // END OF LOCALIZATION

export default content;
