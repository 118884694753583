// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Relay hubs & robots
  content["Relay hubs & robots"] =
  "Relay-Hubs und Robots";

  //Deleted devices
  content["Deleted devices"] =
  "Gelöschte Geräte";

  //Name
  content["Name"] =
  "Name";

  //IP address
  content["IP address"] =
  "IP-Adresse";

  //Role
  content["Role"] =
  "Rolle";

  //OS Type
  content["OS Type"] =
  "BS-Typ";

  //OS Name
  content["OS Name"] =
  "BS-Name";

  //OS Version
  content["OS Version"] =
  "BS-Version";

  //Settings
  content["Settings"] =
  "Einstellungen";

  //Deleted Devices
  content["Deleted Devices"] =
  "Gelöschte Geräte";

  //Filter
  content["Filter"] =
  "Filter";

  // END OF LOCALIZATION

export default content;
