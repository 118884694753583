import React from 'react'
import { connect } from 'react-redux'
import { CardActions, Button as FlatButton } from '@mineral/core'
import './modals.less'
import Api from '../../api/robots/robots'
import Resource from './Resource'

//System update available
let sysUpdate = Resource.get('System update available');
//A new version of the robot software is available.
let robotsoftMsg = Resource.get('A new version of the robot software is available.');
//Deployment takes about 1 minute, during which time no metrics are collected and no alarms are created.
let deployMsg = Resource.get('Deployment takes about 1 minute, during which time no metrics are collected and no alarms are created.');
//LATER
let later = Resource.get('LATER');
//DEPLOY
let deploy =Resource.get('DEPLOY');

const UpgradeRobots = ({ handleCloseModal, upgradeableRobots }) => {
  const handleUpgradeRobots = () => {
    Api.upgradeRobots(upgradeableRobots)
    handleCloseModal()
  }
  return (
    <div className="modal__upgraderobots">
      <header className="modal__upgraderobotsheader">      
        {sysUpdate}
      </header>
      <p className="modal__upgraderobotstext">
      {robotsoftMsg}
      </p>
      <p className="modal__upgraderobotstext">
      {deployMsg}
      </p>
      <CardActions>
        <FlatButton variant="text" color = "primary" onClick={handleCloseModal}>
        {later}
        </FlatButton>
        <FlatButton variant="text" color = "primary" onClick={handleUpgradeRobots}>
        {deploy}
        </FlatButton>
      </CardActions>
    </div>
  )
}

UpgradeRobots.defaultProps = {
  handleCloseModal: function() {
    //console.log('Close modal')
  },
  handleDeployRobots: function() {
   // console.log('Deploy robots')
  },
}

const mapStateToProps = state => {
  return {
    upgradeableRobots: state.upgradeableRobots.items,
  }
}
export default connect(mapStateToProps, null)(UpgradeRobots)
