// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Cards
  content["Cards"] =
  "卡片";

  //List
  content["List"] =
  "列表";

  //Alarms
  content["Alarms"] =
  "警报";

  //Dashboards
  content["Dashboards"] =
  "显示板";

  //Metrics
  content["Metrics"] =
  "度量标准";

  //Monitoring Config
  content["Monitoring Config"] =
  "监控配置";

  // END OF LOCALIZATION

export default content;
