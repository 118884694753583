import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import AclTypes from "./../../api/aclTypes";
import {
  AlarmsIcon,
  ErrorIcon as Information,
  Grid,
  RobotIcon,
} from "../ui-components/uim-components";
// import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import GroupInfoIcon from "../icons/GroupInfoIcon";
import moment from "moment";
import numeral from "numeral";
import each from "lodash/each";
import get from "lodash/get";
import DetailPopUp from "./../groups/detailPopUp/DetailPopUp";
import SeverityIcon from "./../icons/SeverityIcon";
import MaintenanceIcon from "./../icons/MaintenanceIcon";
import { monitorProbes as probeTitles } from "@uim/uim-common-strings";
import "./table.less";
import { sortColumns } from "../../utils/tableUtils";
import rowSelectionCache from "../../utils/rowSelectionCache";
import Resource from "./Resource";
import KeyIcon from "@material-ui/icons/VpnKey";
import { isEqual } from "lodash";
import SeverityBadge from "../alarms/SeverityBadges";
import debounce from "lodash/debounce";
import  NameColumnCellRendererGroupsTable from './NameColumnCellRendererGroupsTable'
import { defaultFiltersActiveObjects } from './filter/DefaultFilters'
import TableFilters from './filter/TableFilters'
let tabForward;
let tabBackward;
function getAallFocussableElementsOf(el) {
  return Array.from(
    el.querySelectorAll(
      'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
    )
  ).filter((el) => {
    return el.tabIndex !== -1;
  });
}
const severityLabels = [
  "clear",
  "info",
  "warning",
  "minor",
  "major",
  "critical",
];

class Table extends Component {
  constructor(props) {
    super(props);
    this.pageLimit = 15;
    this.numPages = 0;
    this.groupPage = 0;
    this.updateRowSelectionCache = true;
    this.state = {
      csCols: this.getCSColumns(),
      selectedColumns: [],
      savedColumnNames: this.props.savedColumns,
      sortedData: this.props.data,
      pageNumber: 0,
      showDialog: false,
      device: null,
      groupCount: this.props.groupCount,
      tooltipShowDelay: 0,
      overlayNoRowsDisplay:
        '<div><div class="nohubs__contentImg"></div><span style="color: #333333;font-family: Roboto;font-size: 1.25rem;">No Data found. Try different filter options.</span></div>',
    };
    this.hideCheckboxes = false;
    if (
      (!this.props.saas && this.props.inventorySearch) ||
      this.props.acl.acl === AclTypes.OPERATOR
    ) {
      this.hideCheckboxes = true;
    }
    const ipAddressLabel = Resource.get("IP address"); //IPAddress
    this.groupCols = [
      {
        headerName: "Alarm Count",
        field: "totalAlarms",
        cellRendererFramework: (params) => {
          return (
            <div style={{ width: "90px", textAlign: "center" }}>
              {params.value}
            </div>
          );
        },
        headerTooltip: "Total Alarm Count",
        // tooltipField: "maxAlarmSeverity.label",
        tooltipValueGetter: (params) =>
          params.data
            ? params.data.totalAlarms
              ? params.data.totalAlarms
              : params.data.totalAlarmCount
            : null,
        suppressMovable: true,
        width: 152,
        minWidth: 152,
        maxWidth: 152,
        sortable: true,
        suppressMenu: true,
        suppressSizeToFit: false,
        resizable: true,
        comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
          const aAlarmCount = nodeA.data.totalAlarms
            ? nodeA.data.totalAlarms
            : nodeA.data.totalAlarmCount;
          const bAlarmCount = nodeB.data.totalAlarms
            ? nodeB.data.totalAlarms
            : nodeB.data.totalAlarmCount;

          return aAlarmCount - bAlarmCount;
        },
        filter: "agNumberColumnFilter",
        filterParams: {
          suppressAndOrCondition: true,
          filterOptions: [
            "greaterThan",
            "greaterThanOrEqual",
            "lessThan",
            "lessThanOrEqual",
          ],
          clearButton: true,
        },
        valueGetter: (params) => {
          return params.data?.totalAlarms
            ? params.data.totalAlarms
            : params.data?.totalAlarmCount;
        },
        headerComponentParams: {
          // Max Alarm Severity
          // icon: <AlarmsIcon title={Resource.get("Max Alarm Severity")} />,
          enableMenu: false,
        },
        suppressNavigable:true
      },
      {
        headerName: "Max Severity",
        field: "maxAlarmSeverity",
        cellRendererFramework: this.addAlarmIconToData,
        //Highest Alarm Severity Level and Total Alarm Count
        headerTooltip: Resource.get("Highest Alarm Severity Level"),
        // tooltipField: "maxAlarmSeverity.label",
        tooltipValueGetter: (params) =>
          params.data?.maxAlarmSeverity?.label
            ? params.data.maxAlarmSeverity.label
            : "",
        suppressMovable: true,
        suppressSizeToFit: true,
        resizable: true,
        sortable: true,
        filter: "customSetFilter",
        filterParams: {
          values: ["Info", "Warning", "Minor", "Major", "Critical", "None"],
          valueToCompare: function (data, node) {
            return data.maxAlarmSeverity.label;
          },
        },
        comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
          const firstValue = severityLabels.indexOf(valueA.toLowerCase());
          const secondValue = severityLabels.indexOf(valueB.toLowerCase());
          if (firstValue === secondValue) {
            return 0;
          } else {
            return firstValue > secondValue ? 1 : -1;
          }
        },
        valueGetter: (params) => {
          return params.data?.maxAlarmSeverity?.label
            ? params.data.maxAlarmSeverity.label
            : "";
        },
        width: 95,
        minWidth: 95,
        headerComponentParams: (params) => {
          if (params.column.actualWidth > 155) {
            return {
              enableMenu: false,
            };
          } else {
            return {
              enableMenu: false,
              icon: <AlarmsIcon role="figure" title={Resource.get("Max Alarm Severity")} />,
            };
          }
        },
        suppressNavigable:true
      },
      // {
      //   headerName: "",
      //   field: " ",
      //   cellRendererFramework: this.addInformationIcon,
      //   suppressResize: true,
      //   // width: 30,
      //   suppressMovable: true,
      //   suppressSizeToFit: true,
      //   headerComponentParams: {
      //     columnSorting: this.sortColumns,
      //   },
      // },
      {
        //Name
        headerName: Resource.get("Name"),
        field: "name",
        tooltipField: "name",
        minWidth: 100,
        //width: 50,
        sortable: true,
        resizable: true,
        cellRendererFramework: NameColumnCellRendererGroupsTable,
       
        suppressNavigable:false
      },

      {
        //Type
        headerName: Resource.get("Type"),
        field: "type",
        tooltipField: "type",
        cellRendererFramework: this.updateType,
        minWidth: 100,
        //width: 50,
        sortable: true,
        resizable: true,
        suppressNavigable:true
      },
      {
        //Elements
        headerName: Resource.get("Count"),
        field: "memberCount",
        tooltipField: "memberCount",
        minWidth: 100,
        //width: 50,
        sortable: true,
        resizable: true,
        suppressNavigable:true
      },
      // {
      //   //Elements
      //   headerName: Resource.get("LastUpdate"),
      //   field: "lastUpdate",
      //   tooltipField: "memberCount",
      //   minWidth: 50,
      //   headerComponentParams: {
      //     columnSorting: this.sortColumns,
      //   },
      // },
    ];
    this.masterColumns = [
      {
        headerName: "",
        field: "checkbox",
        checkboxSelection: true,
        headerCheckboxSelection: false,
        suppressMenu: true,
        suppressSorting: true,
        suppressResize: true,
        suppressSizeToFit: true,
        width: 50,
      },
      {
        headerName: "Alarm Count",
        field: "totalAlarms",
        cellRendererFramework: (params) => {
          return (
            <div style={{ width: "90px", textAlign: "center" }}>
              {params.value}
            </div>
          );
        },
        headerTooltip: "Total Alarm Count",
        // tooltipField: "maxAlarmSeverity.label",
        tooltipValueGetter: (params) =>
          params.data
            ? params.data.totalAlarms
              ? params.data.totalAlarms
              : params.data.totalAlarmCount
            : null,
        suppressMovable: true,
        width: 152,
        minWidth: 152,
        maxWidth: 152,
        sortable: true,
        suppressMenu: true,
        suppressSizeToFit: false,
        resizable: true,
        comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
          const aAlarmCount = nodeA.data.totalAlarms
            ? nodeA.data.totalAlarms
            : nodeA.data.totalAlarmCount;
          const bAlarmCount = nodeB.data.totalAlarms
            ? nodeB.data.totalAlarms
            : nodeB.data.totalAlarmCount;

          return aAlarmCount - bAlarmCount;
        },
        filter: "agNumberColumnFilter",
        filterParams: {
          suppressAndOrCondition: true,
          filterOptions: [
            "greaterThan",
            "greaterThanOrEqual",
            "lessThan",
            "lessThanOrEqual",
          ],
          clearButton: true,
        },
        valueGetter: (params) => {
          return params.data?.totalAlarms
            ? params.data.totalAlarms
            : params.data?.totalAlarmCount;
        },
        headerComponentParams: {
          // Max Alarm Severity
          // icon: <AlarmsIcon title={Resource.get("Max Alarm Severity")} />,
          enableMenu: false,
        },
        suppressNavigable:true
      },
      {
        headerName: "Max Severity",
        field: "maxAlarmSeverity",
        cellRendererFramework: this.addAlarmIconToData,
        //Highest Alarm Severity Level and Total Alarm Count
        headerTooltip: Resource.get("Highest Alarm Severity Level"),
        // tooltipField: "maxAlarmSeverity.label",
        tooltipValueGetter: (params) =>
          params.data?.maxAlarmSeverity?.label
            ? params.data.maxAlarmSeverity.label
            : "",
        suppressMovable: true,
        suppressSizeToFit: true,
        resizable: true,
        sortable: true,
        filter: "customSetFilter",
        filterParams: {
          values: ["Info", "Warning", "Minor", "Major", "Critical", "None"],
          valueToCompare: function (data, node) {
            return data.maxAlarmSeverity.label;
          },
        },
        comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
          const firstValue = severityLabels.indexOf(valueA.toLowerCase());
          const secondValue = severityLabels.indexOf(valueB.toLowerCase());
          if (firstValue === secondValue) {
            return 0;
          } else {
            return firstValue > secondValue ? 1 : -1;
          }
        },
        valueGetter: (params) => {
          return params.data?.maxAlarmSeverity?.label
            ? params.data.maxAlarmSeverity.label
            : "";
        },
        width: 95,
        minWidth: 95,
        headerComponentParams: (params) => {
          if (params.column.actualWidth > 155) {
            return {
              enableMenu: false,
            };
          } else {
            return {
              enableMenu: false,
              icon: <AlarmsIcon title={Resource.get("Max Alarm Severity")} />,
            };
          }
        },
        suppressNavigable:true
      },
      {
        //Name message
        headerName: Resource.get("Display Name"),
        field: "displayName",
        tooltipField: "Display Name",
        minWidth: 50,
        sortable: true,
        resizable: true,
        cellRendererFramework: (params) => {
          return (
            <>
              <p
                style={{
                  margin: 0,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {params.data?.name ? params.data.name : ""}
              </p>
            </>
          );
        },
        suppressNavigable:true
      },
      {
        //Alias message
        headerName: Resource.get("Display Alias"),
        field: "displayAlias",
        tooltipField: "Display Alias",
        minWidth: 50,
        sortable: true,
        resizable: true,
        suppressNavigable:true
      },
      {
        //Description message
        headerName: Resource.get("Description"),
        field: "description",
        tooltipField: "Description",
        minWidth: 50,
        sortable: true,
        resizable: true,
        suppressNavigable:true
      },
      {
        //IPaddress message
        headerName: ipAddressLabel,
        field: "otherIPAddress",
        valueGetter: (model) => {
          let data = model.data;
          let ips = "";
          for (let ip in data.ipAddresses) {
            if (data.ipAddresses.hasOwnProperty(ip))
              ips += data.ipAddresses[ip] + ", ";
          }
          if (ips.length > 0) ips = ips.substring(0, ips.length - 2);
          return ips;
        },
        tooltipField: "ipAddresses",
        minWidth: 50,
        sortable: true,
        resizable: true,
        suppressNavigable:true
      },
      {
        //instanceName message
        headerName: Resource.get("Instance Name"),
        field: "instanceName",
        tooltipField: "instanceName",
        minWidth: 50,
        sortable: true,
        resizable: true,
        suppressNavigable:true
      },
    ];
  }

  //dp039841
  getSelectedColumn = (savedCols) => {
    let columns = this.getCustomColumns();
    let selectedColumns = [];
    let storedColumnList = savedCols;
    if (storedColumnList) {
      if (storedColumnList.length == 0) {
        storedColumnList.push("Name");
        storedColumnList.push("Device Type");
        storedColumnList.push("OS Name");
        storedColumnList.push("Origin");
        storedColumnList.push("IP Address");
        storedColumnList.push("Monitored By");
        storedColumnList.push("Bus Type");
        storedColumnList.push("Discovered");
      } //dp039841
      storedColumnList.forEach((data) => {
        selectedColumns.push(columns.find((col) => col.headerName == data));
      });
    }
    this.setState({
      csCols: [...this.getCSColumns(), ...selectedColumns],
      selectedColumns: [...selectedColumns],
    });
  };
  getCustomColumns() {
    //Name
    const NAME = Resource.get("Name");
    //alias
    const ALIAS = Resource.get("Alias");
    //devicetype
    const DEVICE = Resource.get("Device Type");
    //caption
    const CAPTION = Resource.get("Caption");
    //desc
    const DESCRIPTION = "Description";
    //dedicated
    const DEDICATED = Resource.get("Dedicated");
    //origin
    const ORIGIN = Resource.get("Origin");
    //latestorigin
    const LATESTORIGIN = Resource.get("Latest Origin");
    //mac
    const MAC = Resource.get("MAC Address");
    //monitor
    const MONITOR_BY = Resource.get("Monitored By");
    //robot
    const HAS_ROBOT = Resource.get("Bus Type");
    //csid
    const CS_ID = Resource.get("CS Id");
    //cskey
    const CS_KEY = Resource.get("CS Key");
    //discover
    const DISCOVERED = Resource.get("Discovered");
    //changetime
    const CHANGED = Resource.get("Last Updated");
    //deleted
    const DELETED = Resource.get("Removed");
    let columns = [
      {
        //Name
        headerName: NAME,
        headerTooltip: NAME,
        field: "name",
        tooltipField: "name",
        minWidth: 180,
        flex: 1,
        resizable: true,
        filter: "agTextColumnFilter",
        cellRendererFramework: NameColumnCellRendererGroupsTable,
       
        filterParams: {
          filterOptions: ["contains", "startsWith", "endsWith", "equals"],
          suppressAndOrCondition: true,
          newRowsAction: "keep",
          clearButton: true,
        },
        sortable: true,
        headerComponentParams: {
          //columnSorting: this.sortColumns,
          enableMenu: false,
        },
        suppressNavigable:true
      },
      {
        //Alias
        headerName: ALIAS,
        headerTooltip: ALIAS,
        field: "alias",
        tooltipField: "alias",
        minWidth: 150,
        flex: 1,
        filter: "agTextColumnFilter",
        resizable: true,
        cellRendererFramework: (params) => {
          return (
            <p
              style={{
                margin: 0,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {params.data?.alias ? params.data.alias : ""}
            </p>
          );
        },
        filterParams: {
          filterOptions: ["contains", "startsWith", "endsWith", "equals"],
          suppressAndOrCondition: true,
          newRowsAction: "keep",
          clearButton: true,
        },
        sortable: true,
        headerComponentParams: {
          //columnSorting: this.sortColumns,
          enableMenu: false,
        },
        cellRendererFramework: (model) => {
          return model.data && model.data.alias ? (
            <div className="ag-cell-value">
              <span>{model.data.alias}</span>
            </div>
          ) : (
            ""
          );
        },
        suppressNavigable:true
      },
      {
        //Device Type
        headerName: DEVICE,
        headerTooltip: DEVICE,
        field: "role",
        tooltipField: "role",
        minWidth: 150,
        flex: 1,
        resizable: true,
        filter: "customSetFilter",
        cellRendererFramework: (params) => {
          return (
            <p
              style={{
                margin: 0,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {params.data?.role ? params.data.role : ""}
            </p>
          );
        },
        filterParams: {
          values: [
            "ApplicationComponent",
            "ApplicationSystem",
            "API Endpoint",
            "AWS DataBase",
            "AWSAutoScalingGroup",
            "Cluster",
            "CiscoUcsManager",
            "DatabaseServer",
            "Device",
            "Firewall",
            "Host",
            "Interface",
            "LoadBalancer",
            "Printer",
            "Router",
            "SecurityAppliance",
            "Storage",
            "StorageSegment",
            "Switch",
            "SwitchRouter",
            "vCenter",
            "VirtualMachine",
            "VirtualMachineHost",
            "WAN",
            "WebServer",
            "WirelessAccessPoint",
          ],
          newRowsAction: "keep",
        },
        sortable: true,
        headerComponentParams: {
          enableMenu: false,
        },
        suppressNavigable:true
      },
      {
        //Caption
        headerName: CAPTION,
        field: "attributes.caption",
        headerTooltip: CAPTION,
        tooltipField: "attributes.caption",
        minWidth: 150,
        flex: 1,
        resizable: true,
        filter: "agTextColumnFilter",
        cellRendererFramework: (params) => {
          return (
            <p
              style={{
                margin: 0,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {params.data?.attributes?.caption
                ? params.data.attributes.caption
                : ""}
            </p>
          );
        },
        filterParams: {
          filterOptions: ["contains", "startsWith", "endsWith", "equals"],
          suppressAndOrCondition: true,
          newRowsAction: "keep",
          clearButton: true,
        },
        headerComponentParams: {
          enableMenu: false,
        },
        sortable: true,
        suppressNavigable:true
      },
      {
        //csDescription
        headerName: DESCRIPTION,
        headerTooltip: DESCRIPTION,
        field: "attributes.description",
        tooltipField: "attributes.description",
        minWidth: 150,
        flex: 1,
        resizable: true,
        filter: "agTextColumnFilter",
        cellRendererFramework: (params) => {
          return (
            <p
              style={{
                margin: 0,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {params.data?.attributes?.description
                ? params.data.attributes.description
                : ""}
            </p>
          );
        },
        filterParams: {
          filterOptions: ["contains", "startsWith", "endsWith", "equals"],
          suppressAndOrCondition: true,
          newRowsAction: "keep",
          clearButton: true,
        },
        sortable: true,
        headerComponentParams: {
          enableMenu: false,
        },
        suppressNavigable:true
      },

      {
        //Dedicated
        headerName: DEDICATED,
        headerTooltip: DEDICATED,
        field: "attributes.dedicated",
        tooltipField: "attributes.dedicated",
        minWidth: 150,
        flex: 1,
        resizable: true,
        filter: "agTextColumnFilter",
        cellRendererFramework: (params) => {
          return (
            <p
              style={{
                margin: 0,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {params.data?.attributes?.dedicated
                ? params.data.attributes.dedicated
                : ""}
            </p>
          );
        },
        filterParams: {
          filterOptions: ["contains", "startsWith", "endsWith", "equals"],
          suppressAndOrCondition: true,
          newRowsAction: "keep",
          clearButton: true,
        },
        sortable: true,
        headerComponentParams: {
          enableMenu: false,
        },
        suppressNavigable:true
      },
      {
        //OS TYPE
        headerName: Resource.get("OS Type"),
        //Operating system type
        headerTooltip: Resource.get("Operating System Type"),
        field: "osType",
        tooltipField: "osType",
        minWidth: 130,
        flex: 1,
        resizable: true,
        filter: "agTextColumnFilter",
        cellRendererFramework: (params) => {
          return (
            <p
              style={{
                margin: 0,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {params.data?.osType ? params.data.osType : ""}
            </p>
          );
        },
        filterParams: {
          filterOptions: ["contains", "startsWith", "endsWith", "equals"],
          suppressAndOrCondition: true,
          newRowsAction: "keep",
          clearButton: true,
        },
        sortable: true,
        headerComponentParams: {
          enableMenu: false,
        },
        suppressNavigable:true
      },

      {
        //osname
        headerName: Resource.get("OS Name"),
        //Operating system name
        headerTooltip: Resource.get("Operating System Name"),
        field: "osName",
        tooltipField: "osName",
        minWidth: 140,
        flex: 1,
        resizable: true,
        filter: "agTextColumnFilter",
        cellRendererFramework: (params) => {
          return (
            <p
              style={{
                margin: 0,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {params.data?.osName ? params.data.osName : ""}
            </p>
          );
          
        },
        filterParams: {
          filterOptions: ["contains", "startsWith", "endsWith", "equals"],
          suppressAndOrCondition: true,
          newRowsAction: "keep",
          clearButton: true,
        },
        sortable: true,
        headerComponentParams: {
          enableMenu: false,
        },
        cellRendererFramework: (model) => {
          return model.data && model.data.osName ? (
            <div className="ag-cell-value">
              <span>{model.data.osName}</span>
            </div>
          ) : (
            ""
          );
        },
        suppressNavigable:true
      },
      {
        //osversion
        headerName: Resource.get("OS Version"),
        //Operating system version
        headerTooltip: Resource.get("Operating System Version"),
        field: "osVersion",
        tooltipField: "osVersion",
        minWidth: 150,
        flex: 1,
        resizable: true,
        filter: "agTextColumnFilter",
        cellRendererFramework: (params) => {
          return (
            <p
              style={{
                margin: 0,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {params.data?.osVersion ? params.data.osVersion : ""}
            </p>
          );
        },
        filterParams: {
          filterOptions: ["contains", "startsWith", "endsWith", "equals"],
          suppressAndOrCondition: true,
          newRowsAction: "keep",
          clearButton: true,
        },
        sortable: true,
        headerComponentParams: {
          enableMenu: false,
        },
        suppressNavigable:true
      },
      {
        //osdesc
        headerName: Resource.get("OS Description"),
        //Operating system desc
        headerTooltip: Resource.get("Operating System Description"),
        field: "osDescription",
        resizable: true,
        tooltipField: "osDescription",
        minWidth: 175,
        flex: 1,
        filter: "agTextColumnFilter",
        cellRendererFramework: (params) => {
          return (
            <p
              style={{
                margin: 0,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {params.data?.osDescription ? params.data.osDescription : ""}
            </p>
          );
        },
        filterParams: {
          filterOptions: ["contains", "startsWith", "endsWith", "equals"],
          suppressAndOrCondition: true,
          newRowsAction: "keep",
          clearButton: true,
        },
        headerComponentParams: {
          enableMenu: false,
        },
        sortable: true,
        suppressNavigable:true
      },
      {
        //Origin
        headerName: ORIGIN,
        headerTooltip: ORIGIN,
        field: "attributes.origin",
        tooltipField: "attributes.origin",
        minWidth: 140,
        flex: 1,
        resizable: true,
        filter: "agTextColumnFilter",
        cellRendererFramework: (params) => {
          return (
            <p
              style={{
                margin: 0,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {params.data?.attributes?.origin
                ? params.data.attributes.origin
                : ""}
            </p>
          );
        },
        filterParams: {
          filterOptions: ["contains", "startsWith", "endsWith", "equals"],
          suppressAndOrCondition: true,
          newRowsAction: "keep",
          clearButton: true,
        },
        headerComponentParams: {
          enableMenu: false,
        },
        sortable: true,
        cellRendererFramework: (model) => {
          return model.data &&
            model.data.attributes &&
            model.data.attributes.origin ? (
            <div className="ag-cell-value">
              <span>{model.data.attributes.origin}</span>
            </div>
          ) : (
            ""
          );
        },
        suppressNavigable:true
      },

      {
        //Latest Origin
        headerName: LATESTORIGIN,
        headerTooltip: LATESTORIGIN,
        field: "attributes.latestOrigin",
        tooltipField: "attributes.latestOrigin",
        minWidth: 140,
        flex: 1,
        resizable: true,
        filter: "agTextColumnFilter",
        cellRendererFramework: (params) => {
          return (
            <p
              style={{
                margin: 0,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {params.data?.attributes?.latestOrigin
                ? params.data.attributes.latestOrigin
                : ""}
            </p>
          );
        },
        filterParams: {
          filterOptions: ["contains", "startsWith", "endsWith", "equals"],
          suppressAndOrCondition: true,
          newRowsAction: "keep",
          clearButton: true,
        },
        headerComponentParams: {
          enableMenu: true,
        },
        sortable: true,
        cellRendererFramework: (model) => {
          return model.data &&
            model.data.attributes &&
            model.data.attributes.latestOrigin ? (
            <div className="ag-cell-value">
              <span>{model.data.attributes.latestOrigin}</span>
            </div>
          ) : (
            ""
          );
        },
        suppressNavigable:true
      },

      {
        //macaddress
        headerName: MAC,
        headerTooltip: MAC,
        field: "attributes.mac",
        tooltipField: "attributes.mac",
        minWidth: 165,
        flex: 1,
        resizable: true,
        filter: "agTextColumnFilter",
        cellRendererFramework: (params) => {
          return (
            <p
              style={{
                margin: 0,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {params.data?.attributes?.mac ? params.data.attributes.mac : ""}
            </p>
          );
        },
        filterParams: {
          filterOptions: ["contains", "startsWith", "endsWith", "equals"],
          suppressAndOrCondition: true,
          newRowsAction: "keep",
          clearButton: true,
        },
        headerComponentParams: {
          enableMenu: false,
        },
        sortable: true,
        cellRendererFramework: (model) => {
          return model.data &&
            model.data.attributes &&
            model.data.attributes.mac ? (
            <div className="ag-cell-value">
              <span>{model.data.attributes.mac}</span>
            </div>
          ) : (
            ""
          );
        },
        suppressNavigable:true
      },
      {
        //tag1
        headerName: Resource.get("User Tag 1"),
        //usertag1
        headerTooltip: Resource.get("UserTag 1"),
        field: "attributes.user_tag_1",
        tooltipField: "attributes.user_tag_1",
        minWidth: 155,
        flex: 1,
        resizable: true,
        filter: "agTextColumnFilter",
        cellRendererFramework: (params) => {
          return (
            <p
              style={{
                margin: 0,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {params.data?.attributes?.user_tag_1
                ? params.data.attributes.user_tag_1
                : ""}
            </p>
          );
        },
        filterParams: {
          filterOptions: ["contains", "startsWith", "endsWith", "equals"],
          suppressAndOrCondition: true,
          newRowsAction: "keep",
          clearButton: true,
        },
        sortable: true,
        headerComponentParams: {
          enableMenu: false,
        },
        suppressNavigable:true
      },
      {
        //tag2
        headerName: Resource.get("User Tag 2"),
        //usertag2
        headerTooltip: Resource.get("UserTag 2"),
        field: "attributes.user_tag_2",
        tooltipField: "attributes.user_tag_2",
        minWidth: 150,
        flex: 1,
        resizable: true,
        filter: "agTextColumnFilter",
        cellRendererFramework: (params) => {
          return (
            <p
              style={{
                margin: 0,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {params.data?.attributes?.user_tag_2
                ? params.data.attributes.user_tag_2
                : ""}
            </p>
          );
        },
        filterParams: {
          filterOptions: ["contains", "startsWith", "endsWith", "equals"],
          suppressAndOrCondition: true,
          newRowsAction: "keep",
          clearButton: true,
        },
        sortable: true,
        headerComponentParams: {
          enableMenu: false,
        },
        suppressNavigable:true
      },

      {
        //IP address
        headerName: Resource.get("IP Address"),
        //ipaddress
        headerTooltip: Resource.get("IPAddress"),
        field: "ipAddresses",
        tooltipField: "ipAddresses",
        minWidth: 150,
        flex: 1,
        resizable: true,
        filter: "agTextColumnFilter",
        cellRendererFramework: (params) => {
          return (
            <p
              style={{
                margin: 0,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {params.value ? params.value : ""}
            </p>
          );
        },
        filterParams: {
          filterOptions: ["contains", "startsWith", "endsWith", "equals"],
          suppressAndOrCondition: true,
          newRowsAction: "keep",
          clearButton: true,
        },
        sortable: true,
        headerComponentParams: {
          enableMenu: false,
        },
        valueGetter: (model) => {
          let probes = get(model, "data.ipAddresses", []);
          return probes.join(", ");
        },
        suppressNavigable:true
      },
      {
        //Monitored by
        headerName: MONITOR_BY,
        //monitireby
        headerTooltip: MONITOR_BY,
        field: "attributes.monitoredBy",
        tooltipField: "attributes.monitoredBy",
        filter: "customSetFilter",
        flex: 1,
        resizable: true,
        cellRendererFramework: (params) => {
          let probes = params.data
            ? get(params, "data.attributes.monitoredBy", [])
            : [];
          return (
            <p
              style={{
                margin: 0,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {params.data?.attributes?.monitoredBy ? probes.join(", ") : ""}
            </p>
          );
        },
        filterParams: {
          values: Object.keys(probeTitles),
          doesFilterPass: function (data, node, selectedValues) {
            let len = selectedValues.length,
              isPresent = false;
            let probes = get(data, "attributes.monitoredBy", []);
            for (let i = 0; i < len; i++) {
              if (probes.includes(selectedValues[i])) {
                isPresent = true;
                break;
              }
            }
            return isPresent;
          },
        },
        minWidth: 150,
        sortable: true,
        headerComponentParams: {
          enableMenu: false,
        },
        suppressNavigable:true
      },
      {
        headerName: HAS_ROBOT,
        field: "busType",
        filter: "customSetFilter",
        resizable: true,
        filterParams: {
          values: ["Yes", "No"],
          valueToCompare: function (data, node) {
            return data.busType === "hub" || data.busType === "robot"
              ? "Yes"
              : "No";
          },
        },
        cellRendererFramework: (model) => {
          const item = model.data;
          const hasRobot = item.busType === "hub" || item.busType === "robot";
          return hasRobot ? (
            <div title={hasRobot}>
              <RobotIcon role="figure" title={hasRobot} />
            </div>
          ) : null;
        },
        //Has Robot
        headerTooltip: HAS_ROBOT,
        suppressMovable: true,
        suppressSizeToFit: true,
        suppressSorting: false,
        sortable:true,
        suppressResize: true,
        minWidth: 100,
        flex: 0.7,
        headerComponentParams: {
          //Has Robot
          icon: <RobotIcon title={Resource.get("Has Robot")} />,
          enableMenu: false,
        },
        valueGetter: (model) => {
          const item = model.data;
          const hasRobot = item.busType === "hub" || item.busType === "robot";
          return hasRobot;
        },
        suppressNavigable:true
      },
      {
        //csid
        headerName: CS_ID,
        headerTooltip: CS_ID,
        field: "attributes.cs_id",
        tooltipField: "attributes.cs_id",
        minWidth: 150,
        flex: 1,
        filter: "agTextColumnFilter",
        resizable: true,
        cellRendererFramework: (params) => {
          return (
            <p
              style={{
                margin: 0,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {params.data?.attributes?.cs_id
                ? params.data.attributes.cs_id
                : ""}
            </p>
          );
        },
        filterParams: {
          filterOptions: ["contains", "startsWith", "endsWith", "equals"],
          suppressAndOrCondition: true,
          newRowsAction: "keep",
          clearButton: true,
        },
        sortable: true,
        headerComponentParams: {
          enableMenu: false,
        },
        suppressNavigable:true
      },
      {
        //cskey
        headerName: CS_KEY,
        headerTooltip: CS_KEY,
        field: "attributes.cs_key",
        tooltipField: "attributes.cs_key",
        minWidth: 150,
        flex: 1,
        resizable: true,
        filter: "agTextColumnFilter",
        cellRendererFramework: (params) => {
          return (
            <p
              style={{
                margin: 0,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {params.data?.attributes?.cs_key
                ? params.data.attributes.cs_key
                : ""}
            </p>
          );
        },
        filterParams: {
          filterOptions: ["contains", "startsWith", "endsWith", "equals"],
          suppressAndOrCondition: true,
          newRowsAction: "keep",
          clearButton: true,
        },
        sortable: true,
        headerComponentParams: {
          enableMenu: false,
        },
        cellRendererFramework: (model) => {
          return model.data &&
            model.data.attributes &&
            model.data.attributes.cs_key ? (
            <div className="ag-cell-value">
              <span>{model.data.attributes.cs_key}</span>
            </div>
          ) : (
            ""
          );
        },
        suppressNavigable:true
      },
      {
        //Discovered
        headerName: DISCOVERED,
        headerTooltip: DISCOVERED,
        field: "attributes.create_time",
        resizable: true,
        minWidth: 150,
        flex: 1,
        sortingOrder: ["asc", "desc", null],
        filter: "customSetFilter",
        filterParams: {
          values: [
            "< 4 hours",
            "< 10 hours",
            " < 1 day",
            " < 2 days",
            " < 1 week",
            " < 1 month",
            " < 3 months",
            " < 6 months",
            " < 1 year",
            " > 1 year",
          ],
          doesFilterPass: function (data, node, selectedValues) {
            const createdTime = parseInt(
              get(data, "attributes.create_time[0]", 0),
              10
            );
            let currentTime = moment();
            let presentTime = currentTime.valueOf();
            let fourHrFromNow = moment().subtract(4, "h");
            let tenHrFromNow = moment().subtract(10, "h");
            let oneDyFromNow = moment().subtract(1, "d");
            let twoDyFromNow = moment().subtract(2, "d");
            let oneWkFromNow = moment().subtract(1, "w");
            let oneMnthFromNow = moment().subtract(1, "M");
            let threeMnthFromNow = moment().subtract(3, "M");
            let sixMnthFromNow = moment().subtract(6, "M");
            let oneYrFromNow = moment().subtract(1, "y");

            let len = selectedValues.length,
              isPresent = false;
            for (let i = 0; i < len; i++) {
              if (
                selectedValues[i] === "< 4 hours" &&
                createdTime > fourHrFromNow
              ) {
                isPresent = true;
                break;
              } else if (
                selectedValues[i] === "< 10 hours" &&
                createdTime > tenHrFromNow
              ) {
                isPresent = true;
                break;
              } else if (
                selectedValues[i] === " < 1 day" &&
                createdTime > oneDyFromNow
              ) {
                isPresent = true;
                break;
              } else if (
                selectedValues[i] === " < 2 days" &&
                createdTime > twoDyFromNow
              ) {
                isPresent = true;
                break;
              } else if (
                selectedValues[i] === " < 1 week" &&
                createdTime > oneWkFromNow
              ) {
                isPresent = true;
                break;
              } else if (
                selectedValues[i] === " < 1 month" &&
                createdTime > oneMnthFromNow
              ) {
                isPresent = true;
                break;
              } else if (
                selectedValues[i] === " < 3 months" &&
                createdTime > threeMnthFromNow
              ) {
                isPresent = true;
                break;
              } else if (
                selectedValues[i] === " < 6 months" &&
                createdTime > sixMnthFromNow
              ) {
                isPresent = true;
                break;
              } else if (
                selectedValues[i] === " < 1 year" &&
                createdTime > oneYrFromNow
              ) {
                isPresent = true;
                break;
              } else if (
                selectedValues[i] === " > 1 year" &&
                createdTime < oneYrFromNow
              ) {
                isPresent = true;
                break;
              }
            }
            return isPresent;
          },
        },
        sortable: true,
        headerComponentParams: {
          enableMenu: false,
        },
        cellRendererFramework: (model) => {
          const createdTime = parseInt(
            get(model, "data.attributes.create_time[0]", 0),
            10
          );
          const time = moment(createdTime).fromNow();
          const formattedTime = moment(createdTime).format(
            "MMMM Do YYYY, h:mm a"
          );
          let view = (
            <p style={{ width: "100%" }} title={formattedTime}>
              {time}
            </p>
          );
          if (!createdTime) {
            view = null;
          }
          return view;
        },
        suppressNavigable:true
      },
      {
        headerName: "Has KeyIcon",
        //  field: "",
        filter: "customSetFilter",
        resizable: true,
        filterParams: {
          values: ["Yes", "No"],
          valueToCompare: function (data, node) {
            if (
              (data.attributes && data.attributes.wmi_profile != null) ||
              (data.attributes && data.attributes.shell_profile != null)
            ) {
              return "Yes";
            } else {
              return "No";
            }
          },
        },
        cellRendererFramework: (model) => {
          const item = model.data;
          const hasKeyIcon =
            (item.attributes && item.attributes.wmi_profile != null) ||
            (item.attributes && item.attributes.shell_profile != null);
          return hasKeyIcon ? (
            <div title={hasKeyIcon}>
              <KeyIcon title={hasKeyIcon} />
            </div>
          ) : null;
        },
        //Has KeyIcon
        headerTooltip: Resource.get("Has KeyIcon"),
        suppressMovable: true,
        suppressSizeToFit: true,
        suppressSorting: false,
        sortable:true,
        suppressResize: true,
        minWidth: 100,
        flex: 0.7,

        headerComponentParams: {
          //Has KeyIcon
          icon: <KeyIcon title={Resource.get("Has KeyIcon")} />,
          enableMenu: false,
        },
        valueGetter: (model) => {
          const item = model.data;
          const hasKeyIcon =
            (item.attributes && item.attributes.wmi_profile != null) ||
            (item.attributes && item.attributes.shell_profile != null);
          return hasKeyIcon;
        },
        suppressNavigable:true
      },
      {
        headerName: CHANGED,
        headerTooltip: CHANGED,
        resizable: true,
        field: "attributes.change_time",
        cellRendererFramework: (model) => {
          const changedTime = parseInt(
            get(model, "data.attributes.change_time[0]", 0),
            10
          );
          const formattedTime = moment(changedTime).format("DD/MM/YYYY");
          return (
            <div>
              <span title={formattedTime}>{formattedTime}</span>
            </div>
          );
        },
        hide: false,
        comparator: this.honorBackendCompare,
        filter: "agDateColumnFilter",
        sortable: true,
        filterParams: {
          comparator: function (filterLocalDateAtMidnight, cellValue) {
            const changedTime = parseInt(cellValue, 10);
            const dateAsString = moment(changedTime).format("DD/MM/YYYY");
            if (dateAsString == null) return -1;
            var dateParts = dateAsString.split("/");
            var cellDate = new Date(
              Number(dateParts[2]),
              Number(dateParts[1]) - 1,
              Number(dateParts[0])
            );
            if (filterLocalDateAtMidnight.getTime() == cellDate.getTime()) {
              return 0;
            }
            if (cellDate < filterLocalDateAtMidnight) {
              return -1;
            }
            if (cellDate > filterLocalDateAtMidnight) {
              return 1;
            }
          },
          filterOptions: [
            "greaterThan",
            "greaterThanOrEqual",
            "lessThan",
            "lessThanOrEqual",
          ],
          browserDatePicker: true,
          suppressAndOrCondition: true,
          clearButton: true,
        },
        headerComponentParams: {
          enableMenu: false,
        },
        suppressNavigable:true
      },
      {
        headerName: DELETED,
        headerTooltip: DELETED,
        resizable: true,
        cellRendererFramework: (params) => {
          let value =
            params.data && params.data.attributes
              ? params.data.attributes.deleted
                ? "True"
                : "False"
              : "False";
          return <div>{value}</div>;
        },
        minWidth: 100,
        flex: 0.7,
        sortable: true,
        filter: "customSetFilter",
        filterParams: {
          values: ["True", "False"],
          valueToCompare: function (data, node) {
            if (data.attributes && data.attributes.deleted) {
              return "True";
            } else {
              return "False";
            }
          },
          clearButton: true,
        },
        valueGetter: (model) => {
          const item = model.data;
          let deleted = false;
          deleted = item.attributes && item.attributes.deleted != null;
          return deleted;
        },
        headerComponentParams: {
          enableMenu: false,
        },
        suppressNavigable:true
      },
    ];
    return columns;
  }

  getCSColumns() {
    let csColumns = [
      // {
      //   headerName: "",
      //   field: "checkbox",
      //   checkboxSelection: true,
      //   headerCheckboxSelection: false,
      //   suppressMenu: true,
      //   suppressSorting: true,
      //   suppressResize: true,
      //   suppressSizeToFit: true,
      //   lockPosition: true,
      //   minWidth: 50,
      //   width: 50,
      //   maxWidth: 50,
      // },
      {
        headerName: "Alarm Count",
        field: "totalAlarms",
        cellRendererFramework: (params) => {
          return (
            <div style={{ width: "90px", textAlign: "center" }}>
              {params.value}
            </div>
          );
        },
        headerTooltip: "Total Alarm Count",
        // tooltipField: "maxAlarmSeverity.label",
        tooltipValueGetter: (params) =>
          params.data
            ? params.data.totalAlarms
              ? params.data.totalAlarms
              : params.data.totalAlarmCount
            : null,
        suppressMovable: true,
        width: 152,
        minWidth: 152,
        maxWidth: 152,
        sortable: true,
        suppressMenu: true,
        suppressSizeToFit: false,
        resizable: true,
        comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
          const aAlarmCount = nodeA.data.totalAlarms
            ? nodeA.data.totalAlarms
            : nodeA.data.totalAlarmCount;
          const bAlarmCount = nodeB.data.totalAlarms
            ? nodeB.data.totalAlarms
            : nodeB.data.totalAlarmCount;

          return aAlarmCount - bAlarmCount;
        },
        filter: "agNumberColumnFilter",
        filterParams: {
          suppressAndOrCondition: true,
          filterOptions: [
            "greaterThan",
            "greaterThanOrEqual",
            "lessThan",
            "lessThanOrEqual",
          ],
          clearButton: true,
        },
        valueGetter: (params) => {
          return params.data?.totalAlarms
            ? params.data.totalAlarms
            : params.data?.totalAlarmCount;
        },
        headerComponentParams: {
          // Max Alarm Severity
          // icon: <AlarmsIcon title={Resource.get("Max Alarm Severity")} />,
          //this.props.rowModelType !== "infinite",
          enableMenu: false, 
        },
        suppressNavigable:true
      },
      {
        headerName: "Max Severity",
        field: "maxAlarmSeverity",
        cellRendererFramework: this.addAlarmIconToData,
        //Highest Alarm Severity Level and Total Alarm Count
        headerTooltip: Resource.get("Highest Alarm Severity Level"),
        // tooltipField: "maxAlarmSeverity.label",
        tooltipValueGetter: (params) =>
          params.data?.maxAlarmSeverity?.label
            ? params.data.maxAlarmSeverity.label
            : "",
        suppressMovable: true,
        suppressSizeToFit: true,
        resizable: true,
        sortable: true,
        filter: "customSetFilter",
        filterParams: {
          values: ["Info", "Warning", "Minor", "Major", "Critical", "None"],
          valueToCompare: function (data, node) {
            return data.maxAlarmSeverity.label;
          },
        },
        comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
          const firstValue = severityLabels.indexOf(valueA.toLowerCase());
          const secondValue = severityLabels.indexOf(valueB.toLowerCase());
          if (firstValue === secondValue) {
            return 0;
          } else {
            return firstValue > secondValue ? 1 : -1;
          }
        },
        valueGetter: (params) => {
          return params.data?.maxAlarmSeverity?.label
            ? params.data.maxAlarmSeverity.label
            : "";
        },
        width: 95,
        minWidth: 95,
        headerComponentParams: (params) => {
          if (params.column.actualWidth > 155) {
            return {
              enableMenu: false,
            };
          } else {
            return {
              enableMenu: false,
              icon: <AlarmsIcon title={Resource.get("Max Alarm Severity")} />,
            };
          }
        },
        suppressNavigable:true
      },
      // {
      //   headerName: "",
      //   field: "",
      //   cellRendererFramework: this.addInformationIcon,
      //   suppressResize: true,
      //   width: 10,
      //   suppressMovable: true,
      //   suppressSizeToFit: true,
      //   suppressSorting: true,
      //   minWidth: 30,
      //   sortable: false,
      //   lockPosition: true
      // },
    ];
    if (this.hideCheckboxes) {
      csColumns[0].checkboxSelection = false;
      csColumns[0].headerCheckboxSelection = false;
    }
    return csColumns;
  }

  autoSizeColumns() {
    var allColumnIds = [];
    this.columnApi &&
      this.columnApi.getAllColumns().forEach(function (column) {
        allColumnIds.push(column.colId);
      });
    this.columnApi && this.columnApi.autoSizeColumns(allColumnIds, false);
  }

  addAlarmIconToData = (model) => {
    var item = model.data;
    if (!item) {
      return <div></div>;
    }
    // let alarmCount = item.totalAlarmCount;
    // if (item.totalAlarmCount > 1000) {
    //   alarmCount = numeral(item.totalAlarmCount).format("0a") + "+";
    // }
    return (
      <div className="alarm__icon-link">
        {item.maintanceActive && item.maintanceActive == true ? (
          <MaintenanceIcon
            style={{ height: "18px", width: "20px", marginTop: "-2px" }}
            className="nav-icon"
          />
        ) : (
          <div>
            {model.column.actualWidth > 110 ? (
              <SeverityBadge
                level={item.maxAlarmSeverity ? item.maxAlarmSeverity.value : 0}
              />
            ) : (
              <SeverityIcon
                level={item.maxAlarmSeverity ? item.maxAlarmSeverity.value : 0}
                class="grid__icon"
              />
            )}
          </div>
        )}
      </div>
    );
  };

  updateType = (model) => {
    var item = model.data;
    let type = item.type;
    if (type && type.length > 0) {
      return (
        <div className="alarm__icon-link">
          {type[0] + type.substring(1).toLowerCase()}
        </div>
      );
    } else return <div></div>;
  };
  addInformationIcon = () => {
    return (
      <div className="information__icon--dialog">
        <Information title="Information" />
      </div>
    );
  };
  componentDidMount() {
    this.props.onRef(this);
    window.addEventListener("mouseover", (e) => this.mouseOverClassAddition(e));
    window.addEventListener("mouseout", (e) => this.mouseOutClassDeletion(e));
    window.addEventListener("beforeunload", this.componentCleanup);
    this.getSelectedColumn(this.props.savedColumns);
  }

  componentDidUpdate(prevProps, prevState) {
    /* if (prevProps.columnDefs !== this.props.columnDefs) {
      (this.props.columnDefs == "groupCols") && this.api.sizeColumnsToFit();
      this.autoSizeColumns();
    } */
    if (this.api) {
      this.api.sizeColumnsToFit();
      // this.autoSizeColumns();
    }
    if (
      this.api &&
      prevProps.visibleDataCount !== this.api.rowModel.rowsToDisplay.length &&
      this.props.handleColumnFilterChange
    ) {
      this.props.handleColumnFilterChange(
        this.api.rowModel.rowsToDisplay.length
      );
    }
    //this.props.setGroupTreeFilteredData(this.props.data);
  }
  componentCleanup = (onactualUnmount) => {
    rowSelectionCache.clear();
    if (this.props.handleCsRowsSelected) {
      const ids = rowSelectionCache.getData();
      this.props.handleCsRowsSelected(ids);
    }
    window.removeEventListener("beforeunload", this.componentCleanup);
    if (!onactualUnmount) {
      let mountNode = React.findDOMNode(this.refs.viewref);
      if (mountNode) {
        let unmount = React.unmountComponentAtNode(mountNode);
      }
    }
  };
  componentWillReceiveProps(nextProps) {
    this.setState({
      sortedData: nextProps.data,
      pageNumber: 0,
      groupCount: nextProps.groupCount,
    });
    if (
      nextProps.isTreeOpen != this.props.isTreeOpen &&
      // && nextProps.columnDefs != "groupCols"
      (nextProps.columnDefs == "groupCols" ||
        nextProps.columnDefs == "csCols") &&
      this.api
    ) {
      this.api.sizeColumnsToFit();
      this.autoSizeColumns();
    }
    if (
      !isEqual(
        this.props.data
          .map((device) =>
            device.id ? device.id : device.name ? device.name : device
          )
          .sort(),
        nextProps.data
          .map((device) =>
            device.id ? device.id : device.name ? device.name : device
          )
          .sort()
      ) &&
      this.api
    ) {
      this.api.setFilterModel(null);
      this.props.setGroupTreeFilteredData(nextProps.data);
    }
    this.getSelectedColumn(nextProps.savedColumns);
  }

  componentWillUnmount() {
    this.componentCleanup(true);
  }

  handleDrillDown = (row) => {
    if (row.data.type !== "COMPUTER_SYSTEM") {
      this.props.setTreeData("", "", true, {
        keySelected: [row.data.id.toString()],
        isLastNode: !row.data.groups && !row.data.computerSystem,
        rowData: row.data,
      });
    } else {
      this.props.openCabiView(row.data);
    }
  };
  /*
  handleDrillDown = (row) => {
    const history = this.props.history;
    let el = row.event.target;
    let columnName = el.closest("[col-id]").getAttribute("col-id");
    if (row.data.type !== "COMPUTER_SYSTEM")
    {

      this.props.setTreeData("", "", true, {
        keySelected: [row.data.id.toString()],
        isLastNode: !row.data.groups && !row.data.computerSystem,
        rowData: row.data,
      });
   if(columnName !== "maxAlarmSeverity.value" &&
    columnName !== " ") {
      this.props.handleGroupCellClick(row, history);
    }else if (columnName === " ") {
      this.handleSetDialogFlag(el.closest("[col-id]"), row);
      
    } else if (columnName !== "checkbox") {
      if (columnName === "maxAlarmSeverity.value") {
        this.props.handleAlarmCellClick(
          row,
          history,
          this.props.saas,
          this.props.doiurl,
          this.props.featureFlags
        );
      } else {
        this.props.handleCabiCellClick(row, history);
      }
      //this.props.openCabiView(row.data);
    }
  }
  };handleDrillDown1 = (row) => {
    //console.log("from handleDrillDowm")
    const history = this.props.history;
    //console.log("from histry",history);
    if (this.props.customRowClicking != undefined) {
      if (!this.props.customRowClicking) {
        let el = row.event.target;
        let columnName = el.closest("[col-id]").getAttribute("col-id");
        let itemType = row.data.entityType;
        if (
          itemType !== entityTypes.COMPUTER_SYSTEM &&
          columnName !== "maxAlarmSeverity.value" &&
          columnName !== " "
        ) {
          this.props.handleGroupCellClick(row, history);
        } else if (columnName === " ") {
          this.handleSetDialogFlag(el.closest("[col-id]"), row);
        } else if (columnName !== "checkbox") {
          if (columnName === "maxAlarmSeverity.value") {
            this.props.handleAlarmCellClick(
              row,
              history,
              this.props.saas,
              this.props.doiurl,
              this.props.featureFlags
            );
          } else {
            this.props.handleCabiCellClick(row, history);
          }
        }
      }
    } else {
      let itemType = this.props.entityType;
      if (row.type != "COMPUTER_SYSTEM") {
        this.props.handleGroupCellClick(row, history);
      } else {
        this.props.handleCabiCellClick(row, history);
      }
    }
  };*/
  handleExitClickFlag = (element) => {
    this.setState({
      showDialog: false,
    });
  };
  handlePageClick = (page) => {
    this.setState({
      pageNumber: page.selected,
    });
  };
  handleSetDialogFlag = (element, row) => {
    let device = row.data;
    this.setState({
      showDialog: true,
      device: device,
    });
  };
  getColDefs(props) {
    let columnDefs = this.state.csCols;
    if (
      props.selectedGroup &&
      props.selectedGroup.type !== "CONTAINER" &&
      props.selectedGroup.type !== "ROOT"
    ) {
      columnDefs = this.state.csCols;
    } else if (props.columnDefs === "groupCols") {
      columnDefs = this.groupCols;
    } else if (props.columnDefs === "csCols") {
      columnDefs = this.state.csCols;
    }
    if (props.selectedGroup && props.selectedGroup.masterElementGroup) {
      columnDefs = this.masterColumns;
    }
    return columnDefs;
  }

  mouseOverClassAddition(e) {
    if (e.target && e.target.closest(".ag-cell")) {
      let hoveredCell = e.target.closest(".ag-cell");
      if (e.target.closest(".ag-row")) {
        let children =
          e.target.closest(".ag-row").attributes.class.ownerElement.childNodes;
        if (hoveredCell.getAttribute("col-id") === "maxAlarmSeverity.value") {
          each(children, (child) => {
            if (child.getAttribute("col-id") === "maxAlarmSeverity.value") {
              child.className += " grid__row--hover";
            } else if (
              child.getAttribute("col-id") === "checkbox" &&
              this.hideCheckboxes
            ) {
              child.className += " grid__row--hover";
            }
          });
        } else if (hoveredCell.getAttribute("col-id") === " ") {
          each(children, (child) => {
            if (child.getAttribute("col-id") === " ") {
              child.className += " grid__row--hover";
            }
          });
        } else if (
          hoveredCell.getAttribute("col-id") === "checkbox" &&
          this.hideCheckboxes
        ) {
          each(children, (child) => {
            if (
              child.getAttribute("col-id") === "maxAlarmSeverity.value" ||
              child.getAttribute("col-id") === "checkbox"
            ) {
              child.className += " grid__row--hover";
            }
          });
        } else {
          each(children, (child) => {
            if (
              child.getAttribute("col-id") !== "maxAlarmSeverity.value" &&
              child.getAttribute("col-id") !== " "
            ) {
              if (
                this.hideCheckboxes &&
                child.getAttribute("col-id") === "checkbox"
              ) {
                return;
              } else {
                child.className += " grid__row--hover";
              }
            }
          });
        }
      }
    }
  }
  mouseOutClassDeletion(e) {
    if (e.target && e.target.closest(".ag-cell")) {
      if (e.target.closest(".ag-row")) {
        let children =
          e.target.closest(".ag-row").attributes.class.ownerElement.childNodes;
        each(children, (child) => {
          child.className = child.className.replace(" grid__row--hover", "");
        });
      }
    }
  }
  onGridReady(params) {
    this.api = params.api;
    this.columnApi = params.columnApi;
    (this.props.columnDefs == "groupCols" ||
      this.props.columnDefs == "csCols") &&
      this.api.sizeColumnsToFit();
    this.autoSizeColumns();
    if (this.props.onGridReady) {
      this.props.onGridReady(params);
    }
    setTimeout(
      function () {
        if (
          this.api &&
          this.props.visibleDataCount !==
            this.api.rowModel.rowsToDisplay.length &&
          this.props.handleColumnFilterChange
        ) {
          this.props.handleColumnFilterChange(
            this.api.rowModel.rowsToDisplay.length
          );
        }
        if (this.api) {
          (this.props.columnDefs == "groupCols" ||
            this.props.columnDefs == "csCols") &&
            this.api.sizeColumnsToFit();
        }
        //dp039841
        let rowData = [];
        this.api.forEachNodeAfterFilter((node) => {
          rowData.push(node.data);
        });
        this.props.setGroupTreeFilteredData(rowData);
      }.bind(this),
      0
    );

    this.updateRowSelection();
  }
  rowDataChanged = () => {
    this.updateRowSelection();
  };
  filterChanged = (params) => {
    this.api = params.api;

    if (this.api.rowModel.rowsToDisplay.length === 0) {
      this.api.showNoRowsOverlay();
    } else if (
      this.state.groupCount < 0 &&
      this.state.sortedData.length === 0
    ) {
      this.api.showLoadingOverlay();
    } else {
      this.api.hideOverlay();
    }
    if (
      this.api &&
      this.props.visibleDataCount !== this.api.rowModel.rowsToDisplay.length &&
      this.props.handleColumnFilterChange
    ) {
      console.log("save");
      this.props.handleColumnFilterChange(
        this.api.rowModel.rowsToDisplay.length
      );
    }
    //dp039841
    let rowData = [];
    this.api.forEachNodeAfterFilter((node) => {
      rowData.push(node.data);
    });
    this.props.setGroupTreeFilteredData(rowData);
  };

  getNoRowInfo = () => {
    let noDataMsg = Resource.get("No Data Found"); //No Data Found
    let classNames = ["setup-wizard__localprobes__info"];
    let msg2 = Resource.get("Try different filter options"); //Try different filter options
    return (
      <div style={{ position: "relative" }} className={classNames.join(" ")}>
        <div
          style={{
            width: "calc(100% - 60px)",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          <div className="nohubs__contentImg"></div>
          <span className="setup-wizard__localprobes__content">
            <span
              style={{
                padding: "2px 13px 2px 20px",
                borderRight: "2px solid #999999",
              }}
            >
              {noDataMsg}
            </span>
            <span style={{ paddingLeft: "9px" }}>{msg2}</span>
          </span>
        </div>
      </div>
    );
  };

  updateRowSelection = () => {
    setTimeout(
      function () {
        if (this.api) {
          this.updateRowSelectionCache = false;
          rowSelectionCache.updateOnRowDataChanged(this.api);
          this.updateRowSelectionCache = true;
          if (this.api.rowModel.rowsToDisplay.length === 0) {
            this.api.showNoRowsOverlay();
          } else if (
            this.state.groupCount < 0 &&
            this.state.sortedData.length === 0
          ) {
            this.api.showLoadingOverlay();
          } else {
            this.api.hideOverlay();
          }
          (this.props.columnDefs == "groupCols" ||
            this.props.columnDefs == "csCols") &&
            this.api.sizeColumnsToFit();
        }
      }.bind(this),
      0
    );
  };
  selectionChanged = () => {
    if (this.updateRowSelectionCache && this.api) {
      rowSelectionCache.onChange(this.api);
    }
    if (this.props.handleCsRowsSelected) {
      const ids = rowSelectionCache.getData();
      this.props.handleCsRowsSelected(ids);
    }
  };
  sortColumns = (column, order) => {
    const newSortedData = sortColumns(column, order, this.state.sortedData);
    this.setState({
      sortedData: newSortedData,
      pageNumber: 0,
    });

    this.updateRowSelection();
  };

  onColumnResized(event) {
    if (event?.column && event.column.colId === "maxAlarmSeverity") {
      event.api.refreshCells({
        force: true,
        columns: ["maxAlarmSeverity"],
      });
      event.api.setColumnDefs(event.api.getColumnDefs());
    }
  }

  debouncedColumnResized = debounce(
    (event) => this.onColumnResized(event),
    600
  );
  filterChanged1 = (params) => {
    let model={};
    params.colFilters.map((filter)=>{
      if(filter.filterType==="set")
      {
        model[filter.filterColumn]={
          values:filter.filterValue?.split(','),
          filterType: filter.filterType,
          }

      }else if(filter.filterType=='text')
      {
      model[filter.filterColumn]={
      filter:filter.filterValue,
      filterType: filter.filterType,
      type: filter.filterOperator}
      }

    })

    this.api.setFilterModel(model);

  }

  onSuppressKeyboardEvent(params) {
    let key = params.event.key;
    let shiftKey = params.event.shiftKey;
    let isTabbingForward = key === 'Tab' && shiftKey === false;
    let isTabbingBackWards = key === 'Tab' && shiftKey === true;

    // Handle cell children tabbing
    if (isTabbingForward || isTabbingBackWards) {
      tabForward = isTabbingForward;
      tabBackward = isTabbingBackWards;

      if (params.editing) {
        return false;
      }

      let eGridCell = params.event.path.find((el) => {
        if (el.classList === undefined) return false;
        return el.classList.contains('ag-cell');
      });

      let focusableChildrenElements = getAallFocussableElementsOf(eGridCell);
      let lastCellChildEl =
        focusableChildrenElements[focusableChildrenElements.length - 1];
      let firstCellChildEl = focusableChildrenElements[0];

      //  FORWARD
      // allow to movee to next cell when the cells last element is focused
      if (isTabbingForward && focusableChildrenElements.length > 0) {
        let isLastChildFocused =
          lastCellChildEl && document.activeElement === lastCellChildEl;

        if (isLastChildFocused === false) {
          return true;
        }
      }

      //  BACKWARDS
      //  focus last element if none are focused
      //  allow to tab backwards when first element is focused
      if (isTabbingBackWards && focusableChildrenElements.length > 0) {
        let cellHasFocusedChildren =
          eGridCell.contains(document.activeElement) &&
          eGridCell !== document.activeElement;

        if (!cellHasFocusedChildren) {
          params.event.preventDefault();
          lastCellChildEl.focus();
        }

        let isFirstChildFocused =
          firstCellChildEl && document.activeElement === firstCellChildEl;

        if (isFirstChildFocused === false) {
          return true;
        }
      }
    }

    return false;
  }

  onSuppressHeaderKeyboardEvent(params) {
    let key = params.event.key;
    let shiftKey = params.event.shiftKey;
    let isTabbingForward = key === 'Tab' && shiftKey === false;
    let isTabbingBackWards = key === 'Tab' && shiftKey === true;
    // Handle cell children tabbing

    if (isTabbingForward || isTabbingBackWards) {
      let eGridCell = params.event.path.find((el) => {
        if (el.classList === undefined) return false;
        return el.classList.contains('ag-header-cell');
      });

      let focusableChildrenElements = getAallFocussableElementsOf(eGridCell);
      let lastCellChildEl =
        focusableChildrenElements[focusableChildrenElements.length - 1];
      let firstCellChildEl = focusableChildrenElements[0];

      //  FORWARD
      // allow to movee to next cell when the cells last element is focused
      if (isTabbingForward && focusableChildrenElements.length > 0) {
        let isLastChildFocused =
          lastCellChildEl && document.activeElement === lastCellChildEl;

        if (isLastChildFocused === false) {
          return true;
        }
      }

      //  BACKWARDS
      //  focus last element if none are focused
      //  allow to tab backwards when first element is focused
      if (isTabbingBackWards && focusableChildrenElements.length > 0) {
        let cellHasFocusedChildren =
          eGridCell.contains(document.activeElement) &&
          eGridCell !== document.activeElement;
        if (!cellHasFocusedChildren) {
          params.event.preventDefault();

          lastCellChildEl.focus();
        }

        let isFirstChildFocused =
          firstCellChildEl && document.activeElement === firstCellChildEl;

        if (isFirstChildFocused === false) {
          return true;
        }
      }
    }

    return false;
  }
  onCellFocused2(params) {
    if (tabForward) {
      let cell = document.activeElement;
      let focusableChildren = getAallFocussableElementsOf(cell);

      if (focusableChildren.length > 0) {
        focusableChildren[0].focus();
      }

      tabForward = false;
    }

    if (tabBackward) {
      let cell = document.activeElement;
      let focusableChildren = getAallFocussableElementsOf(cell);

      if (focusableChildren.length > 0) {
        focusableChildren[focusableChildren.length - 1].focus();
      }

      tabBackward = false;
    }
  }
  render() {
    // No results found
    const NoReultsMsg = Resource.get("No results found");
    let view;
    const headerHeight = 36;
    const rowHeight = 48;

    let rowData = this.state.sortedData;
    let heightOfDiv = 15 * rowHeight + headerHeight + 30;
    let onSelectionChanged = null;
    let rowSelection = "single";
    if (this.props.columnDefs === "csCols") {
      onSelectionChanged = this.selectionChanged;
      rowSelection = "multiple";
    }

    view = (
      <div ref="viewref">
      <div>
    
      <TableFilters
              defaultFilters={defaultFiltersActiveObjects(
                this.getColDefs(this.props)
               
              )}
              handleUpdateFilters={this.filterChanged1.bind(this)}
              searchResultsNumber={this.api?.getDisplayedRowCount()}
              currentQuickFilter={this.props.searchTerm}
              onQuickFilterChange={this.props.handleChange}
             
            />
      </div>
        <div
          className="ag-material groups-tablecontainer group-member-container"
          style={{
            // height: 'calc(100vh - 65px - 51px - 94px)',
            height: "calc(100vh - 269px)",
          }}
        >
          <Grid
            {...this.props}
            headerHeight={headerHeight}
            rowHeight={rowHeight}
            rowData={rowData}
            columnDefs={this.getColDefs(this.props)}
            onRowClicked={this.handleDrillDown.bind(this)}
            onGridReady={this.onGridReady.bind(this)}
            overlayNoRowsTemplate={this.state.overlayNoRowsDisplay}
            onSelectionChanged={onSelectionChanged}
            onColumnResized={this.debouncedColumnResized.bind(this)}
            onFilterChanged={this.filterChanged.bind(this)}
            onRowDataChanged={this.rowDataChanged}
            rowSelection={rowSelection}
            tooltipShowDelay={this.state.tooltipShowDelay}
            onCellFocused={this.onCellFocused2.bind(this)}
            //suppressCellFocus={true}
            suppressKeyboardEvent= {this.onSuppressKeyboardEvent}
            context={this}
            suppressHeaderKeyboardEvent= {this.onSuppressHeaderKeyboardEvent}
          />
        </div>
        <div className="pagination">
          <DetailPopUp
            entity={this.state.device}
            showDialog={this.state.showDialog}
            handleExitClickFlag={this.handleExitClickFlag}
            featureflag={this.props.featureFlags}
            saas={this.props.saas}
            doiurl={this.props.doiurl}
          />
        </div>
      </div>
    );
    return view;
  }
}

export default withRouter(Table);
