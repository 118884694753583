import React, {Component} from 'react';
import PropTypes from 'prop-types'
import {ErrorIcon} from '../ui-components/uim-components';
import * as messageTypes from './InlineMessageTypes';
import './inlineMessage.less';

const MessageTypeItems = {
  [messageTypes.ERROR] : {
    classNameBody : 'inline-message--error',
    icon: <ErrorIcon className="icon--error" />
  }
};

class InlineMessage extends Component {

  render = () => {
    return (
        <div className={'inline-message__body ' + MessageTypeItems[this.props.type].classNameBody}>
          <div className="inline-message__icon">
            {MessageTypeItems[this.props.type].icon}
          </div>
          <div className="inline-message__text">
            <span>{this.props.message}</span>
          </div>
        </div>
    );
  }
}

export default InlineMessage;

InlineMessage.propTypes = {
  type:PropTypes.string.isRequired,
  message:PropTypes.string.isRequired
};
