import React, { Component } from 'react'
import Resource from './Resource'
import './DeviceDetails.less'


		 

const DeviceDetails = (props) => {

 let deviceDetailsHeading=Resource.get('Device Properties');//Device Properties
 let deviceTypeHeading=Resource.get('Device Type');//Device Type
 let operatingSystemHeading=Resource.get('Operating System');//Operating System
 let monitoredByHeading=Resource.get('Monitored By');//Monitored By
 let lastDeploymentStatusHeading=Resource.get('Last Deployment Status');//Last Deployment Status
 let lastDeploymentTimeHeading=Resource.get('Last Deployment Time');//Last Deployment Time
 let lastDeploymentStatusMessage=Resource.get('Last Deployment Status Message');//Last Deployment Status Message                    
	let status=props.device.status
	let statusButton=''
				if(status!==undefined && status!==undefined)
      {
          
         if( status.includes('Deployed'))
               statusButton=(<span style={{'color': '#038537','font-size': '25px',
  'vertical-align': 'sub'}}>●</span>)
         else if( status.includes('Failed'))
statusButton=(<span style={{'color': '#DE1B1B','font-size': '25px',
  'vertical-align': 'sub'}}>●</span>)
         else if( status.includes('Pending'))
statusButton=(<span style={{'color': '#8E99AB','font-size': '25px',
  'vertical-align': 'sub'}} >●</span>)
         else if( status.includes('Not Applicable') || status.includes('NA'))
statusButton=(<span style={{'color': '#CCCCCC','font-size': '25px',
  'vertical-align': 'sub'}} >●</span>)
      }			
    return (
         <div className="device_details">
			 			<h3>{deviceDetailsHeading}</h3>
						 <div style ={{paddingTop: "5px"}} className="head">
							<div> {deviceTypeHeading}</div>
						 </div>
						 <div className="text">
							<div> {props.device.typeName}</div>
						 </div>
						 <div className="head">
							<div> {operatingSystemHeading}</div>
						 </div>
						 <div className="text">
							<div> {props.device.operatingSystem}</div>
						 </div>
						 <div className="head">
							<div> {monitoredByHeading}</div>
						 </div>
						 <div className="text">
							<div> {props.device.monitoredByProbe}</div>
						 </div>
						 <div className="head">
							<div> {lastDeploymentStatusHeading}</div>
						 </div>
						 <div className="text">
							<div> {statusButton}<span>{props.device.status}</span></div>
						 </div>
						  <div className="head">
							<div> {lastDeploymentTimeHeading}</div>
						 </div>
						 <div className="text">
							<div> {props.device.lastDeployedTime}</div>
						 </div>
						 <div className="head">
							<div> {lastDeploymentStatusMessage}</div>
						 </div>
						 <div className="text" style={{'padding-bottom': '20px'}}>
							<div> {props.device.latestDeploymentSummary}</div>
						 </div>
					  </div>
    )
}

export default DeviceDetails;