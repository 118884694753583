import {
  UPDATE_PWD,
  UPDATE_PWD_SUCCESS,
  UPDATE_PWD_FAILURE,
  RESET_MESSAGE
} from './actionTypes'

const pwdState = (
  state = {
    isUpdating: false,
    isSuccess: false,
    successMessage: null,
    errorMessage: null

  }, 
  action) => {
  switch (action.type) {
    case UPDATE_PWD:
      return Object.assign({}, state, {
        isUpdating: true,
        successMessage: null,
        errorMessage: null 

      });
    case UPDATE_PWD_SUCCESS:
      return Object.assign({}, state, {
        isUpdating: false,
        isSuccess: true,
        errorMessage: null,
        successMessage: action.successMessage
      });
      case UPDATE_PWD_FAILURE:
        return Object.assign({}, state, {
          isUpdating: false,
          isSuccess: false,
          successMessage: null,
          errorMessage: action.errorMessage
        });
      case  RESET_MESSAGE:
        return Object.assign({}, state, {
          isUpdating: false,
          isSuccess: false,
          successMessage: null,
          errorMessage: null 

      });
    default:
      return state
  }
}
export default pwdState
