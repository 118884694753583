// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Robot installer download and instructions
  content["Robot installer download and instructions"] =
  "Robot-Installationsprogramm-Download und Anweisungen";

  //Robots manage the probes (monitoring agents) used to collect metric data from your systems, sending the data to Infrastructure Management via the relay hub.
  content["Robots manage the probes (monitoring agents) used to collect metric data from your systems, sending the data to Infrastructure Management via the relay hub."] =
  "Robots verwalten die Probes (�berwachungsagenten), die zum Erfassen von Kennzahlendaten des System verwendet werden, und senden die Daten �ber den Relay-Hub an Infrastructure Management.";

  //ROBOT INSTALLERS
  content["ROBOT INSTALLERS"] =
  "ROBOT-INSTALLATIONSPROGRAMME";

  //Add Devices
  content["Add Devices"] =
  "Ger�te hinzuf�gen";

  //Device IP
  content["Device IP"] =
  "Ger�te-IP";

  //OS
  content["OS"] =
  "Betriebssystem";

  //Architecture
  content["Architecture"] =
  "Architektur";

  //Username
  content["Username"] =
  "Benutzername";

  //Password
  content["Password"] =
  "Kennwort";

  //Sudo password (Linux only)
  content["Sudo password (Linux only)"] =
  "Sudo-Kennwort (nur Linux)";

  //AUTO DEPLOY
  content["AUTO DEPLOY"] =
  "AUTOMATISCHE BEREITSTELLUNG";

  //MANUAL DEPLOY
  content["MANUAL DEPLOY"] =
  "MANUELL BEREITSTELLEN";

  //"Deploy" starts the processes of adding devices to UIM.  Enabling monitoring happens on the next step.
  content["Deploy starts the processes of adding devices to UIM.  Enabling monitoring happens on the next step."] =
  "Die Bereitstellung startet die Prozesse zum Hinzuf�gen von Ger�ten zu UIM.  Die Aktivierung der �berwachung erfolgt im n�chsten Schritt.";

  //Click deploy to install the monitoring host.  When complete, click Next.
  content["Click deploy to install the monitoring host.  When complete, click Next."] =
  "Klicken Sie auf \"Bereitstellen\", um den �berwachungshost zu installieren.  Wenn Sie fertig sind, klicken Sie auf \"Weiter\".";

  //Deploy
  content["Deploy"] =
  "Bereitstellen";

  //Devices added must be able to communicate with the selected relay hub.
  content["Devices added must be able to communicate with the selected relay hub."] =
  "Die hinzugef�gten Ger�te m�ssen mit dem ausgew�hlten Relay-Hub kommunizieren k�nnen.";

  //Ports 48000 to 48025 must be open
  content["Ports 48000 to 48025 must be open"] =
  "Ports 48000 bis 48025 m�ssen offen sein";

  //for the relay hub to communicate with the CA Infrastructure Management application.
  content["for the relay hub to communicate with the CA Infrastructure Management application."] =
  "damit der Relay-Hub mit CA Infrastructure Management kommunizieren kann.";

  //If monitoring cloud-based infrastructure, a relay hub is needed for each isolated virtual network (such as AWS Virtual Private Cloud or Azure Virtual Network).
  content["If monitoring cloud-based infrastructure, a relay hub is needed for each isolated virtual network (such as AWS Virtual Private Cloud or Azure Virtual Network)."] =
  "Bei der �berwachung einer cloudbasierten Infrastruktur wird ein Relay-Hub f�r jedes isolierte virtuelle Netzwerk ben�tigt (wie z. B. AWS Virtual Private Cloud oder Azure Virtual Network).";

  //Select relay hub
  content["Select a relay hub"] =
  "Relay-Hub ausw�hlen";

  //If you are not installing with root access, use either sudo
  content["If you are not installing with root access, use either sudo"] =
  "Wenn Sie die Installation nicht mit Root-Zugriff durchf�hren, verwenden Sie entweder sudo";

  //You can also use su to get a root shell and execute the command.
  content["You can also use su to get a root shell and execute the command."] =
  "Sie k�nnen auch \"su\" verwenden, um eine Root-Shell abzurufen und den Befehl auszuf�hren.";

  //Copy the appropriate Windows installer to any folder on the target system:
  content["Copy the appropriate Windows installer to any folder on the target system:"] =
  "Kopieren Sie das entsprechende Windows-Installationsprogramm in einen beliebigen Ordner auf dem Zielsystem:";

  //Copy the nms-robot-vars.cfg answer file to the same folder.
  content["Copy the nms-robot-vars.cfg answer file to the same folder."] =
  "Kopieren Sie die nms-robot-vars.cfg-Antwortdatei in denselben Ordner.";

  //Install the robot by executing:
  content["Install the robot by executing:"] =
  "Installieren Sie den Robot, indem Sie Folgendes ausf�hren:";

  //Installation
  content["Installation"] =
  "Installation";

  //Robot Installers (including answer file)
  content["Robot Installers (including answer file)"] =
  "Robot-Installationsprogramme (einschlie�lich Antwortdatei)";

  //The installation is complete. To start the robot, execute the following command:
  content["The installation is complete. To start the robot, execute the following command:"] =
  "Die Installation ist abgeschlossen. F�hren Sie den folgenden Befehl aus, um den Robot zu starten:";

  //Execute the RobotConfigurer.sh script to configure the robot when the installer exits.
  content["Execute the RobotConfigurer.sh script to configure the robot when the installer exits."] =
  "F�hren Sie das RobotConfigurer.sh-Skript aus, um den Robot zu konfigurieren, wenn das Installationsprogramm vorhanden ist.";

  //The rpm flags function as follows:
  content["The rpm flags function as follows:"] =
  "Die rpm-Flags funktionieren wie folgt:";

//The default install directory is /opt/nimsoft. To specify the installation directory, execute the following command, where <directory> is the full path:
  content["The default install directory is /opt/nimsoft. To specify the installation directory, execute the following command, where <directory> is the full path:"] =
  "Das Standardinstallationsverzeichnis lautet &#x2F;opt&#x2F;nimsoft. Um das Installationsverzeichnis anzugeben, f�hren Sie den folgenden Befehl aus, wobei &lt;Verzeichnis&gt; der vollst�ndige Pfad ist:";

//Copy the appropriate Linux installer to /opt on the target system:
  content["Copy the appropriate Linux installer to /opt on the target system:"] =
  "Kopieren Sie das entsprechende Linux-Installationsprogramm in &#x2F;opt auf dem Zielsystem:";

  //Copy the nms-robot-vars.cfg answer file to &#x2F;opt.
  content["Copy the nms-robot-vars.cfg answer file to &#x2F;opt."] =
  "Kopieren Sie die nms-robot-vars.cfg-Antwortdatei in &#x2F;opt.";

  //Install the robot with the following command, where &lt;arch&gt;is i386 or x86_64:
  content["Install the robot with the following command, where <arch> is i386 or x86_64:"] =
  "Installieren Sie den Robot mit dem folgenden Befehl, wobei &lt;Arch&gt; i386 oder x86_64 ist:";

  //The default install folder is C:\Program Files (x86)\Nimsoft for 32-bit systems, and C:\Program Files\Nimsoft for 64-bit systems.
  content["The default install folder is C:\\Program Files (x86)\\Nimsoft for 32-bit systems, and C:\\Program Files\\Nimsoft for 64-bit systems."] =
  "Der Standardinstallationsordner ist C:\\Programme (x86)\\Nimsoft f�r 32-Bit-Systeme und C:\\Programme\\Nimsoft f�r 64-Bit-Systeme.";

  //To specify the folder, append the following parameter to the command. Quotation marks are required.
  content["To specify the folder, append the following parameter to the command. Quotation marks are required."] =
  "F�gen Sie den folgenden Parameter an den Befehl an, um den Ordner anzugeben. Es sind Anf�hrungszeichen erforderlich.";

  //To specify the log file, append:
  content["To specify the log file, append:"] =
  "Um die Protokolldatei anzugeben, h�ngen Sie Folgendes an:";

  //After installation, the robot starts automatically.
  content["After installation, the robot starts automatically."] =
  "Nach der Installation wird der Robot automatisch gestartet.";

//Copy the Debian installer to /opt on the target system:
  content["Copy the Debian installer to /opt on the target system:"] =
  "Kopieren Sie das Debian-Installationsprogramm in &#x2F;opt auf dem Zielsystem:";

  //Note that only 64 bit Debian systems are supported as robots.
  content["Note that only 64 bit Debian systems are supported as robots."] =
  "Beachten Sie, dass nur 64-Bit-Debian-Systeme als Robots unterst�tzt werden.";

  //Install the robot with the following command:
  content["Install the robot with the following command:"] =
  "Installieren Sie den Robot mit dem folgenden Befehl:";

//Copy the Ubuntu installer to /opt on the target system:
  content["Copy the Ubuntu installer to /opt on the target system:"] =
  "Kopieren Sie das Ubuntu-Installationsprogramm in &#x2F;opt auf dem Zielsystem:";

  //Note that only 64 bit Ubuntu systems are supported as robots.
  content["Note that only 64 bit Ubuntu systems are supported as robots."] =
  "Beachten Sie, dass nur 64-Bit-Ubuntu-Systeme als Robots unterst�tzt werden.";

  //(Ubuntu 16 only) Execute the following command to enable the robot service. Note: This step is not required for Ubuntu 14.
  content["(Ubuntu 16 only) Execute the following command to enable the robot service. Note: This step is not required for Ubuntu 14."] =
  "(Nur Ubuntu 16) F�hren Sie den folgenden Befehl aus, um den Robot-Dienst zu aktivieren. Hinweis: Dieser Schritt ist f�r Ubuntu 14 nicht erforderlich.";

  // END OF LOCALIZATION

export default content;
