import React from "react";
import { SvgIcon } from "../../components/ui-components/uim-components";

const Icon = (props) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.3596 11.2426C13.9453 11.8284 13.9453 12.7781 13.3596 13.3639C12.774 13.9497 11.8245 13.9497 11.2388 13.3639L6.9971 9.12128L2.7554 13.3639C2.16975 13.9497 1.22021 13.9497 0.634555 13.3639C0.0488985 12.7781 0.0488985 11.8284 0.634555 11.2426L4.87625 6.99996L0.634555 2.75732C0.0488985 2.17153 0.0488985 1.22178 0.634555 0.635995C1.22021 0.0502087 2.16975 0.0502087 2.7554 0.635995L6.9971 4.87863L11.2388 0.635995C11.8245 0.0502087 12.774 0.0502087 13.3596 0.635995C13.9453 1.22178 13.9453 2.17153 13.3596 2.75732L9.11795 6.99996L13.3596 11.2426Z"
        fill="#999999"
      />
    </svg>
  );
};

export default Icon;
