// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //reports message
  content["{0} reports"] =
  "{0} informes";

  //Run Now
  content["Run Now"] =
  "Ejecutar ahora";

  //Schedule
  content["Schedule"] =
  "Programación";

  //Reports
  content["Reports"] =
  "Informes";

  //Unable to get CABI info
  content["Unable to get CABI info."] =
  "No se puede obtener la información de CABI.";

  // END OF LOCALIZATION

export default content;
