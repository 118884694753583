import React, { Component } from "react";
import { connect } from "react-redux";
import { Card,
  CardContent,
  CardMedia,
  Dialog,
  Button as FlatButton,Snackbar} from "@mineral/core"
import {CircularProgress} from '@mineral/core'
import MessageFormat from "message-format";
import Resource from "./Resource";
import add from "./../../assets/images/AddReport.svg";
import addHover from "./../../assets/images/AddReportHover.svg";
import CreateDashboardDialog from "./CreateDashboardDialog";
import {createUserDashboard,fetchUserDashboards} from "../../api/userdashboards/actions";
import isEqual from "lodash/isEqual";
import './createdashboarddialog.less';



const customDashboard = Resource.get("Custom Dashboard"); //customdashboard
const cardStyle = {
  height: "15rem",
  width: "18.75rem",
};

const imgStyle = {
  paddingTop: "1.3rem",
};
const imgTagStyle = {};
const uri = "createDashboards";

const ErrorDialog = function (props) {
  let msg1 = Resource.get("Error retrieving cabi information"), //cabiErrorRetrieve
    msg2 = Resource.get("Cabi is not available or accessible. Please contact your administrator."); //contactAdmin
  let actions = [
    <FlatButton
      variant="text"
        //OK
      children={Resource.get("OK")}
      color="primary"
      onClick={() => {
        props.onClose();
      }}
    />,
  ];

  return (
    <Dialog
      actions={actions}
      modal={false}
      open={props.open}
      contentStyle={{
        width: "fit-content",
        minWidth: "500px",
        maxWidth: "700px",
      }}
      onClose={props.onClose}
    >
      <p style={{ fontSize: "16px", fontWeight: "600" }}>{msg1}</p>
      <p style={{ fontSize: "14px" }}>{msg2}</p>
    </Dialog>
  );
};

class CreateDashboardCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openDialog: false,
      //openErrorDialog: false,
      //loadStatus: "loading",
      showFloatingMessage:false,
      floatingMessageValue:"",
     
  
      userDashboardCreateStatus:{status:'none',userdashboard:{},dashboardname:''},
    };
  }
  onFloatingMessageClose=()=>{

    this.setState({showFloatingMessage:false,floatingMessageValue:""});
  }

 /* componentWillMount() {    
   CabiApi.get("en", this.props.sid)
      .then((response) => response.data)
      .then((json) => {
        this.setState({
          jasperToken: json.token,
          cabiUrl: json.address,
          userLocale: json.locale,
          orgidl: json.orgidl ? json.orgidl : "ca",
          loadStatus: "complete",
         
        });
        
      })
      .catch((error) => {
        console.error("Error retrieving cabi info:", error);
        this.setState({
          loadStatus: "failed",
          openErrorDialog: true,
        });
      });
  }*/

  componentWillReceiveProps(nextProps) {


    
    if(!isEqual(this.state.userDashboardCreateStatus, nextProps.userdashboard.userDashboardCreateStatus))
    {  

      this.setState({userDashboardCreateStatus:nextProps.userdashboard.userDashboardCreateStatus});
      if(nextProps.userdashboard.userDashboardCreateStatus.status=='success')
      {
        let message=Resource.get("Successfully created user dashboard")+" "+nextProps.userdashboard.userDashboardCreateStatus.dashboardname; //successfulcreate
        this.setState({showFloatingMessage:true,floatingMessageValue:message})
        this.props.fetchUserDashboards();

      }

      if(nextProps.userdashboard.userDashboardCreateStatus.status=='failure')
      {
        let message=Resource.get("Failed to create user dashboard")+" "+nextProps.userdashboard.userDashboardCreateStatus.dashboardname//failedcreate
        this.setState({showFloatingMessage:true,floatingMessageValue:message})
      }

    }
    
  }

  openCreateCabiDashPathPopup = () => {
    this.setState({ openDialog: true });
  };

  onClose = () => {
    this.setState({ openDialog: false });
  };

  
  onSave = (cabiPayload) => {
    this.props.createUserDashboardMeth(cabiPayload);
    this.props.fetchUserDashboards();
    this.setState({ openDialog: false });    
  };

  render() {
    let dialogView = null;
   /* if (this.state.loadStatus == "failed")
    {
      dialogView = (
        <ErrorDialog
          open={this.state.openErrorDialog}
          onClose={this.onCloseErrorDialog.bind(this)}
        />
      );
    }
    else if (this.state.loadStatus == "loading")
    {  dialogView = (
        <div className="configuration__spinner">
          <CircularProgress style={{color: '#3272D9'}} />
        </div>
      );
    }
    else  if (this.state.loadStatus == "complete" && this.state.openDialog==true)*/
        // <CreateDashboardDialog
        //   cabiUrl={this.state.cabiUrl}
        //   jasperToken={this.state.jasperToken}
        //   userLocale= {this.state.userLocale}
        //   orgidl= {this.state.orgidl}
        //   open={this.state.openDialog}
        //   onClose={() => this.onClose()}
        //   onSave={() => this.onSave()}
        // />

    return (
      <div className="createdashboardcard-css" style={{ display: "inline-block" }}>
        <FlatButton  
          onClick={this.openCreateCabiDashPathPopup.bind(this)}
          disabled={this.state.openDialog} variant="text"
          style={{  marginRight: '1rem', marginBottom: '1rem' }}
          tabIndex="0"
        >
          <Card
            className="create-custom-report-card"
            style={cardStyle}
            id={uri}
          >
            <CardMedia className="create-custom-report-media" style={imgStyle}>
              <img src={add} className="add-new-disabled" role="figure" />
              <img src={addHover} className="add-new" role="figure" />
            </CardMedia>
            <CardContent style={{ padding: "0px !important" }}>
              <div
                style={{
                  height: "16px",
                  color: "#333333",
                  fontFamily: "Roboto",
                  fontSize: "14px",
                  fontWeight: "600",
                  lineHeight: "16px",
                  textAlign: "center",
                  paddingTop: "16px",
                }}
              >
                {" "}
                New{" "}
              </div>
              <div
                style={{
                  height: "21px",
                  color: "#333333",
                  fontFamily: "Roboto",
                  fontSize: "18px",
                  fontWeight: "600",
                  lineHeight: "21px",
                  textAlign: "center",
                  paddingTop: "8px",
                }}
              >
                {" "}
                {customDashboard}
              </div>
            </CardContent>
          </Card>
        </FlatButton>
        <Snackbar
            open={this.state.showFloatingMessage}
            message={this.state.floatingMessageValue}
            autoHideDuration={4000}
            onClose={this.onFloatingMessageClose}
          />

            <CreateDashboardDialog
              cabiUrl={this.state.cabiUrl}
              jasperToken={this.state.jasperToken}
              userLocale= {this.state.userLocale}
              orgidl= {this.state.orgidl}
              open={this.state.openDialog}
              onClose={this.onClose}
              onSave={this.onSave}
            />

      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  sid: state.sid,
  acl: state.acl,
  userdashboard: state.userdashboards,
 
});

const mapDispatchToProps = (dispatch) => {
  return {
    createUserDashboardMeth: (params) => dispatch(createUserDashboard(params)),
    fetchUserDashboards:()=>dispatch(fetchUserDashboards())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateDashboardCard);
