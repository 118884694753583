import React, { Component } from 'react'
import {  ClearIcon as Close,} from '../ui-components/uim-components'
import {Dialog,
  Button as FlatButton,
  Step,
  Stepper,
  DialogContent,
  DialogTitle,
  StepLabel,Typography,
  DialogActions,Tooltip} from "@mineral/core"
  import setupWizardFlow, { PAGE } from './setupWizardFlow'
  import SetupWizardWalkThrough from './setupWizardWalkThrough/SetupWizardWalkThrough'
  import './setupWizard.less'
  import Resource from './Resource'
  import {IconButton} from '@mineral/core'
  
  export default class SetupWizardDialog extends Component {
    state = {
      activeStep: 1,
      visited: [],
    }
    flow = null
    //should component update - TODO: refine the function to minimize render changes
    // shouldComponentUpdate = (nextProps, nextState) => {
    //     //console.log(nextProps, nextState);
    //     if ((this.state.activeStep !== nextState.activeStep)
    //         || (this.props.deploymentInProgress !== nextProps.deploymentInProgress)
    //         || (this.props.hubs !== nextProps.hubs)
    //         || (this.props.robots !== nextProps.robots)
    //     ) {
    //         return true;
    //     }
    //     return false;
    // }
    setActivePage(me, step) {
      me.setState({ activeStep: step })
    }
    onMaxProfileEdit(me,data){
      me.props.onMaxEdit(data);
    }
    
    setActiveStep = (hubs)=>{
      if (this.props.probe.probeType === 'cloud') {
        this.setState({ activeStep: 2 })
      } else {
        if (hubs && hubs.length) {
          if (
            this.props.probe.probeType === 'remote' &&
            this.props.probe.name !== 'docker_monitor' &&
            (this.props.robots && this.props.robots.length)
            ) {
              this.setState({ activeStep: 2 })
            }else if(this.props.probe.probeType === 'local' &&
            this.props.probe.name !== 'apache' &&
            this.props.probe.name !== 'unix' &&
            this.props.probe.name !== 'windows' &&
            !this.props.probe.probeappDiscoveryEnabled &&
            this.props.robots && this.props.robots.length) {
              this.setState({ activeStep: 2 })
            }else {
              this.setState({ activeStep: 1 })
            }
          }
        }
      }
      componentWillMount = () => {
        //   console.log(this.props, this.state)
        this.setActiveStep(this.props.hubs);
      }
      
      componentWillReceiveProps = (newProps) =>{
        if(newProps.hubsIsFetching !== this.props.hubsIsFetching && this.state.activeStep === 1){
          this.setActiveStep(newProps.hubs);
        }
      }
      
      componentWillUnmount = () => {
        this.flow = null
      }
      
      // Close Dialog handler
      handleClose() {
        this.props.onDialogClose()
      }
      // Stepper functions
      stepClick(step) {
        if (!this.state.visited.includes(this.state.activeStep)) {
          this.state.visited.push(this.state.activeStep)
        }
        this.setState({ activeStep: step })
        
        let mcs = window.mcs.onStepChange
        if (mcs) {
          mcs.stepChange(mcs.scope, step)
        }
      }
      nextStep() {
        if (!this.state.visited.includes(this.state.activeStep)) {
          this.state.visited.push(this.state.activeStep)
        }
        const { activeStep } = this.state
        this.setState({ activeStep: activeStep + 1 })
      }
      
      previousStep() {
        if (!this.state.visited.includes(this.state.activeStep)) {
          this.state.visited.push(this.state.activeStep)
        }
        const { activeStep } = this.state
        if (activeStep > 1) {
          this.setState({ activeStep: activeStep - 1 })
        }
      }
      setWizardPage = activeStep => {
        this.setState({ activeStep: activeStep })
      }
      
      disableCancelbutton = (flow, dialogProps) => {
        if(flow.activePage === PAGE.DEPLOY && dialogProps.deploymentInProgress){
          return true
        }
        return false
      }
      disableNextbutton = (flow, dialogProps) => {
        if (
          flow.activePage === PAGE.RELAY_HUB &&
          dialogProps.hubs &&
          dialogProps.hubs.length
          ) {
            return false
          }
          if (
            flow.activePage === PAGE.DEPLOY &&
            dialogProps.robots &&
            dialogProps.robots.length
            ) {
              if (
                (dialogProps.probe.monitoringAgentRequired &&
                  !dialogProps.newlyDeployed) ||
                  dialogProps.deploymentInProgress
                  ) {
                    return true
                  } else {
                    return false
                  }
                }
                return true
              }
              
              // Stepper title click active state
              disabledState = step => {
                // page is already visited hence should not be disabled
                if (this.state.visited.includes(step)) {
                  return false
                }
                if (this.state.activeStep >= step) {
                  return false
                }
                return true
              }
              
              render() {
                const dialogProps = this.props
                const activeStep = this.state.activeStep
                
                //Get the flow for the Setup wizard based probetype
                this.flow = setupWizardFlow[dialogProps.probe.probeType](
                  activeStep,
                  dialogProps,
                  )
                  const flow = this.flow //setupWizardFlow[dialogProps.probe.probeType](activeStep, dialogProps);
                  const disableNext = this.disableNextbutton(flow, dialogProps)
                  const disableCancel = this.disableCancelbutton(flow, dialogProps)
                  
                  let dialogBodyClassName = 'setup-wizard__dialog_body'
                  let actions = [
                    <FlatButton tabIndex={0} 
                    //Cancel
                    children={Resource.get("Cancel")}
                    variant = "text"
                    color ="primary"
                    disabled={disableCancel}
                    onClick={() => this.handleClose()}
                   
                    />,
                  ]
                  // Stepper buttons handlers
                  if (flow.activePage === PAGE.DONE && flow.activePage !== PAGE.CONFIGURE && flow.activePage !== PAGE.RELAY_HUB ) {
                    actions.push(
                      <FlatButton tabIndex={0}
                      //Previous
                      children={Resource.get("Previous")}
                     
                      variant = "text"
                      color ="primary"
                     
                      onClick={() => this.previousStep()}
                      />,
                      )
                    }
                    if (
                      flow.activePage !== PAGE.CONFIGURE &&
                      flow.activePage !== PAGE.DONE &&
                      dialogProps.probe.probeType !== 'cloud'
                      ) {
                        actions.push(
                          <FlatButton tabIndex={0}
                          //next
                          children={Resource.get("Next")}
                          variant = "text"
                          color ="primary"
                          onClick={() => this.nextStep()}
                          disabled={disableNext}
                         
                          />,
                          )
                        }
                        if (
                          flow.activePage === PAGE.DONE &&
                          dialogProps.probe.probeType !== 'cloud'
                          ) {
                            actions.push(
                              <FlatButton tabIndex={0}
                              //Finish
                              children={Resource.get("Finish")}
                              variant = "text"
                              color ="primary"
                             
                              onClick={probe => this.props.enableMonitoring(dialogProps.probe)}
                              />,
                              )
                            }
                            if (
                              dialogProps.probe.probeType === 'cloud' ||
                              flow.activePage === PAGE.CONFIGURE ||
                              flow.activePage === PAGE.MULTITEMPLATE
                              ) {
                                actions = []
                                dialogBodyClassName = dialogProps.probe.probeType === 'cloud'?'setup-wizard__dialog_body-mcs-done' : 'setup-wizard__dialog_body-mcs-done-step'
                              }
                              
                              const stepperRender = () => {
                                let steps = [
                                  // { stepLabel: flow.step1Label, index: 0 },
                                  { stepLabel: flow.step2Label, index: 1 },
                                  { stepLabel: flow.step3Label, index: 2 },
                                ]
                                if (this.props.probe.name === 'sap_basis') {
                                  steps.push({ stepLabel: flow.step4Label, index: 3 })
                                }
                                return (
                                  <Stepper
                                  style={{ width: 700 }}
                                  activeStep={activeStep}
                                  orientation="horizontal"
                                  >
                                  {steps.map(step => {
                                    let index = step.index
                                    let stepLabel = step.stepLabel1
                                    let stepName = `step ${step.index}`
                                    return (
                                      <Step aria-labelledby={`stepLabel-setupwizard-${index}`} role="navigation" >
                                      <StepLabel id={`stepLabel-setupwizard-${index}`}
                                      onClick={() => this.stepClick(index)}
                                      active={!this.disabledState(index)}
                                      disabled={this.disabledState(index)}
                                      className={`stepper-btn setup-wizard__fonts ${activeStep ===
                                        index
                                        ? 'stepActive'
                                        : ''}`}
                                        >
                                        {stepLabel}
                                        </StepLabel>
                                        </Step>
                                        )
                                      })}
                                      </Stepper>
                                      )
                                    }
                                    let stepper = dialogProps.probe.probeType !== 'cloud' && stepperRender()
                                    let probeDisplayName =  dialogProps.probe.displayname
                                    if(probeDisplayName !=undefined && probeDisplayName.endsWith("(RESTMon)")){
                                      probeDisplayName = probeDisplayName.substring(0, probeDisplayName.length-9);
                                    }
                                    let title =    probeDisplayName.includes("Monitoring")? 'Add ' + probeDisplayName : 'Add '+ probeDisplayName + ' Monitoring';
                                    
                                      
                                      // let activePage = flow.activePage;
                                      //  console.log(`${this.state.activeStep} active page "${flow.activePage}"`)
                                      return (
                                        <Dialog
                                        open={true}
                                        style={{
                                          maxWidth: 'none',
                                          transform: 'translate(0, 0)'
                                        }}
                                        classes={{root:dialogBodyClassName}}
                                        onClose={() => this.handleClose()}
                                        >
                                        <DialogTitle  component="div" disableTypography>
                                      <Typography component="h1" variant="h3">  {title}</Typography>
                                        </DialogTitle>
                                        <div style={{height:'0px'}}>
                                        <Tooltip title="Close">
                                        <IconButton style={{top:'-50px',float:'right',marginRight:'8px'}}
                                      onClick={() => this.handleClose()}
                                      autoFocus
                                      aria-label='Close'
                                      >
                                      <Close />
                                      </IconButton>

                                      </Tooltip>
                                      </div>
                                      <div style={{height:'16px'}}>
                                      {stepper}
                                      </div>
                                     
                                        <DialogContent dividers classes={{root:"setup-wizard__dialog_content"}} style={{ padding: 0, width: '99%',marginTop:'32px' }}>
                                        <SetupWizardWalkThrough
                                        {...Object.assign({}, this.props, this.state, {
                                          handleDialogClose: () => this.handleClose(),
                                          activePage: flow.activePage,
                                          enableMonitoringCheckbox: (event, checked) =>
                                          this.props.enableMonitoringCheckbox(event, checked),
                                          monitoringEnabled: dialogProps.probe.probeappDiscoveryEnabled,
                                          setRobotsBeingDeployed: robots =>
                                          this.props.setRobotsBeingDeployed(robots),
                                          probeAddress: this.props.probeAddress,
                                          ip: this.props.host,
                                          onComplete: robotsStatus => this.props.onComplete(robotsStatus),
                                          setupWizardScope: this,
                                          setActive: this.setActivePage,
                                          onMaxProfileEdit:this.onMaxProfileEdit,
                                          
                                        })}
                                        setWizardPage={activeStep => this.setWizardPage(activeStep)}
                                        />
                                        </DialogContent>
                                        <DialogActions>
                                        {actions}
                                        </DialogActions>
                                        </Dialog>
                                        )
                                      }
                                    }