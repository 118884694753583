import {
  Card,
  CardHeader,
  CardMedia,
     Button as FlatButton,Dialog,DialogTitle, DialogActions,Typography,Button
} from "@mineral/core";
import {IconButton} from "@mineral/core";
import {DashboardsIcon} from '../ui-components/uim-components'
import { Link } from "react-router-dom";
import theme from "../../theme";
import "../setupWizard/setupWizardCard.less";
import React, { Component } from "react";
import Resource from "./Resource";
import Iframe from "react-iframe";
import isEqual from "lodash/isEqual";
import { connect } from "react-redux";

import CreateDashboardDialog from "./CreateDashboardDialog";
import {fetchUserDashboards,deleteUserDashboard,updateUserDashboard} from '../../api/userdashboards/actions'
import './createdashboarddialog.less';
import { withStyles } from '@material-ui/core/styles';

const useStyles = {
  overlayContentStyle : {
    background: theme.palette.lightBlack,
   /* height: '2rem',
    paddingTop: '5px',
    paddingBottom: '5px',
    top: '208px',*/
    height:"200px",
  
  top:'42px',
  background: "none",
    
  },
  titleStyle : {
    fontSize: '1rem',
    fontWeight: 'normal',
    lineHeight: '1.5rem',
    color: theme.palette.fullWhite
  },
  cardTitleContainerStyle :{
    display: 'flex',
    
    background: theme.palette.lightBlack,
    height: '2rem',
   
    alignItems:'left'
   
  },
  cardTitleStyle : {
    padding: '0px !important',
    
  
 
  },
  cardMediaStyle:{
    display:'flex',
    width:"300px",
    height:'208px'
  }
  
};
/*const cardStyle = {
  height: '15.625rem',
  width: '18.75rem',
  marginBottom: '1rem',
  marginRight: '1rem',
  hover: {
    cursor: "pointer",
  },
}
const overlayContentStyle = {
  height:"200px",
  
  top:'42px',
  background: "none",
 

}
const cardTitleStyle = {
  padding: null,  
  flex:"30%",
  background: theme.palette.lightBlack,
  height: '2rem',
  marginTop: "60px",
  
}
const titleStyle = {
  fontSize: '1rem',
  fontWeight: 'normal',
  lineHeight: '1.5rem',
  color: theme.palette.fullWhite
}
const cardTitleContainerStyle = {
  display: 'flex',
  padding: '0.2rem',
}
const cardTitleLableStyle = {
  marginLeft: '0.5rem',
}*/

class UserDashboardDashCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openDialog: false,
      openErrorDialog: false,
      loadStatus: "loading",
      openDeleteConfirmation:false,
      
      
    };
  }

  onDeleteClick = (event) => {
    event.stopPropagation();
    this.setState({ openDeleteConfirmation: true });
//this.props.deleteUserDashboard(this.props.currentuserdashboard.id,this.props.currentuserdashboard.dashboardName);


  };
  onDeleteConfirmClick = (event) => {
    event.stopPropagation();
    this.setState({ openDeleteConfirmation: false });
this.props.deleteUserDashboard(this.props.currentuserdashboard.id,this.props.currentuserdashboard.dashboardName);


  };
  onDeleteIgnoreClick = (event) => {
    event.stopPropagation();
    this.setState({ openDeleteConfirmation: false });
//this.props.deleteUserDashboard(this.props.currentuserdashboard.id,this.props.currentuserdashboard.dashboardName);


  };

  onEditClick = (event) => {
    event.stopPropagation();
    this.setState({ openDialog: true });

  };
  openCreateCabiDashPathPopup = () => {
    this.setState({ openDialog: true });
  };

  onClose = () => {
    this.setState({ openDialog: false });
  };


  onSave = (cabiPayload) => {
    this.setState({ openDialog: false });
    this.props.updateUserDashboard(cabiPayload);
    this.props.fetchUserDashboards();
  };

  


  

  clickLink=() =>{
    let item=this.props.currentuserdashboard;
    let dashboardPath=item.dashboardPath;
    let defaultAlarmState=item.defaultAlarmState;
    let defaultMinimumAlarm=item.defaultMinimumAlarm;
    let defaultTimeRange=item.defaultTimeRange;
    let defaultTopN=item.defaultTopN;
    let dynamicFilters=item.dynamicFilters;
    let staticFilters=item.staticFilters;
    let dashboardName=item.dashboardName
  
    staticFilters.replace(/\'/g, "\"");
    
    let params = new URLSearchParams(JSON.parse(staticFilters.replace(/\'/g, "\"")));
    params.append("time_range", defaultTimeRange);
    params.append("minimum_alarm_level", defaultMinimumAlarm);
    params.append("alarm_state", defaultAlarmState);
    params.append("top_n", defaultTopN);
    params.append("path", dashboardPath);
    params.append("dashboardName",dashboardName);
  
    /*for (var key in staticFilters) {
      if (Array.isArray(staticFilters[key])) {
        params.append([key],staticFilters[key]);
      }
    }*/
  
    /*if (typeof(staticFilters) === "object") {
      
    }*/
    let url="/uim-cabi?"+params.toString();
    this.props.history.push(url);
  }
  
  /**
   * Note: We set an id on the card to enable automated Selenium testing
   */
  render() {
    let dialogView = null;
    const classes = this.props.classes;
    
    let actions = [
      //Cancel
      <FlatButton
        variant="text"
        children={Resource.get("Cancel")}
        color="secondary"
        onClick={this.onDeleteIgnoreClick}
      />,
      //Ok
      <FlatButton
        variant="text"
        children={Resource.get("Ok")}
        keyboardFocused={true}
        onClick={this.onDeleteConfirmClick}        
      />,
    ];
    let title = Resource.get("Delete custom dashboard")//deletecustomdashboard;
    let deleteConfirmation=Resource.get("Are you sure you want to delete the custom dashboard : ");//deletCustomconfirmation
    let content= <div>
    <Typography style={{paddingLeft:'16px'}}>
     {deleteConfirmation+this.props.currentuserdashboard.dashboardName}
    </Typography>
    </div>
   let deleteDialogView=null;
   
   if(this.state.openDeleteConfirmation==true)
   deleteDialogView=<Dialog          
   modal={false}
   open={this.state.openDeleteConfirmation}
   
  
 >
   <DialogTitle disableTypography><Typography component="h1" variant="h4">{title}</Typography></DialogTitle>
   {content}
  
   <DialogActions>
       {actions}
   </DialogActions>
 </Dialog>
    if (this.state.openDialog==true)
     { dialogView = (
        <CreateDashboardDialog
          cabiUrl={this.props.cabiUrl}
          jasperToken={this.props.jasperToken}
          userLocale= {this.props.userLocale}
          orgidl= {this.props.orgidl}
          open={this.state.openDialog}
          cabiPayload={this.props.currentuserdashboard}
          onClose={this.onClose.bind(this)}
          onSave={this.onSave.bind(this)}
        />
      );
     }
     let deleteIcon= <IconButton aria-label="Delete" className="createdashboardcard-iconbutton"
     style={{ "z-index":"1", top:'-145px' }}
     onClick={this.onDeleteClick.bind(this)}
   >
   <svg
   role={"figure"}
   aria-label="[title]"
   xmlns="http://www.w3.org/2000/svg"
   width="24"
   height="24"
   viewBox="0 0 24 24"
 >
   <g stroke="#FFFFFF" fill="none" strokeWidth="3">
     <path
       fillRule="evenodd"
       d="M17.999,7 L5.999,7 L5.999,19 C5.999,20.104 6.895,21 8,21 L15.999,21 C17.105,21 17.999,20.104 17.999,19 L17.999,7 M14.499,2.999 L9.499,2.999 L8.5,4 L5.999,4 C5.448,4 5,4.448 5,4.999 L5,6 L19,6 L19,4.999 C19,4.448 18.552,4 17.999,4 L15.5,4 L14.499,2.999"
     />
   </g>
 </svg>
    
   </IconButton>
   let editIcon=<IconButton aria-label="Edit" className="createdashboardcard-iconbutton"
   style={{ "z-index":"1",  top:'-145px', marginLeft:'8px', marginRight:'8px'}}
   onClick={this.onEditClick.bind(this)}
 >
   <svg width="12px" height="12px" viewBox="0 0 12 12" version="1.1" xmlns="http://www.w3.org/2000/svg">
   <g id="Page-1" stroke="#FFFFFF" stroke-width="1" fill="none" fillRule="evenodd">
   <g id="7-Discover-Devices" transform="translate(-1394.000000, -128.000000)">
       <g id="Group" transform="translate(990.000000, 126.000000)">
           <g id="outline-edit-24px" transform="translate(402.000000, 0.000000)">
               <g id="Bounding_Boxes">
                   <polygon id="Shape" points="0 0 16 0 16 16 0 16"></polygon>
               </g>
               <g id="Outline_1_" transform="translate(2.000000, 2.000000)" fill="#999999" fillRule="nonzero">
                   <path d="M7.37333333,4.01333333 L7.98666667,4.62666667 L1.94666667,10.6666667 L1.33333333,10.6666667 L1.33333333,10.0533333 L7.37333333,4.01333333 Z M9.77333333,0 C9.60666667,0 9.43333333,0.0666666667 9.30666667,0.193333333 L8.08666667,1.41333333 L10.5866667,3.91333333 L11.8066667,2.69333333 C12.0666667,2.43333333 12.0666667,2.01333333 11.8066667,1.75333333 L10.2466667,0.193333333 C10.1133333,0.06 9.94666667,0 9.77333333,0 Z M7.37333333,2.12666667 L0,9.5 L0,12 L2.5,12 L9.87333333,4.62666667 L7.37333333,2.12666667 Z" id="XMLID_37_"></path>
               </g>
           </g>
       </g>
   </g>
</g>
</svg>
 </IconButton>
 
 const cardStyle = {
  height: '15rem',
  width: '18.75rem',
  
}
const cardTitleLableStyle = {
  
  color:'#FFFFFF',
  fontSize:'16px',
  lineHeight:'24px',
  fontWeight:'400'

}

   
      return (
        <div className="createdashboardcard-css">
        
            {dialogView}
            {deleteDialogView}
            <Button component="div" variant="text" role="group">
        <Card   style={cardStyle} id={this.props.id} onClick={this.clickLink.bind(this)}>
          <CardMedia 
          style={{position: "relative"}}
            overlayContentStyle={classes.overlayContentStyle}
           
          > <div style={{display:"flex",flexDirection:"column",height:"208px"}} >
             <img alt={this.props.title} src={this.props.image} />
            {this.props.nameOnImage && (
              <div
                className="restmon-probeName"
                title={this.props.nameOnImage}
                style={{
                  width: "75%",
                  maxWidth: "none",
                  minWidth: "none",
                  zIndex: "3",
                }}
              >
                {this.props.nameOnImage}
              </div>
            )}
        
          <div style={{ flex:"70%","margin-top": "-60px",
"margin-bottom": "60px",
"display": "flex",
justifyContent: "flex-end"}}>
  
  {deleteIcon}     
  {editIcon} 
         </div>
        
         </div>
            

           
          </CardMedia>
          <CardHeader disableTypography={true}
          classes={{
            root:classes.cardTitleStyle,
            title:classes.titleStyle,
            content:classes.cardTitleContainerStyle
            
          }}
               
                title={
                  <div style={{display:"flex"}}>
                    <DashboardsIcon
                      className="dashboard-title__icon"
                      title={this.props.title}
                    />
                    <label style={cardTitleLableStyle}>
                      {this.props.title}
                    </label>
                  </div>
                }
              />
        </Card>
        </Button>
      
        
        </div>
      );
    /*} else
     { return (
       <div>
        <Link to={this.props.linkTo}>
        {dialogView}
          <Card style={cardStyle} id={this.props.id}>
            <CardMedia
              overlayContentStyle={overlayContentStyle}
              overlay={
                <CardHeader
                  style={cardTitleStyle}
                  titleStyle={titleStyle}
                  title={
                    <div style={cardTitleContainerStyle}>
                      <DashboardsIcon
                        className="dashboard-title__icon"
                        title={this.props.title}
                      />
                      <label style={cardTitleLableStyle}>
                        {this.props.title}
                      </label>
                    </div>
                  }
                />
              }
            >
              <img src={this.props.image} role="figure" />
              {this.props.nameOnImage && (
                <div
                  className="restmon-probeName"
                  title={this.props.nameOnImage}
                  style={{
                    width: "75%",
                    maxWidth: "none",
                    minWidth: "none",
                    zIndex: "3",
                  }}
                >
                  {this.props.nameOnImage}
                </div>
              )}
            </CardMedia>
          </Card>
        </Link>
        <Snackbar
            open={this.state.showFloatingMessage}
            message={this.state.floatingMessageValue}
            autoHideDuration={4000}
            onRequestClose={this.onFloatingMessageClose}
          />
        </div>
      );
                }*/
  }
}

const mapStateToProps = (state, ownProps) => ({
  sid: state.sid,
  acl: state.acl,
 
 
});

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUserDashboards:()=>dispatch(fetchUserDashboards()),
    deleteUserDashboard:(userdashboardId,dashboardname) => dispatch(deleteUserDashboard(userdashboardId,dashboardname)),
    updateUserDashboard:(params)=> dispatch(updateUserDashboard(params)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(useStyles)(UserDashboardDashCard));