import axios from "axios";
import config from "../config";

class AutomaticGroup {
  getAutomaticGroups() {
    return axios.get([config.basename, "api/configureGroups/getAutomaticGroups"].join("/"));
  }

  update(data) {
    return axios.post([config.basename, "api/configureGroups/updateAutomaticGroups"].join("/"),{'automaticGroup' : data});
  }

  getPreConfigureGroups() {
    return axios.get([config.basename, "api/configureGroups/getPreconfiguredGroups"].join("/"));
  }

  updatePreconfGroup(toCreate,toDelete) {
    if(toDelete.length == 0){
      toDelete = []
    }
    if(toCreate.length == 0){
      toCreate = []
    }
    return axios.post([config.basename, "api/configureGroups/updatePreconfiguredGroups"].join("/"),{'toCreate' : toCreate , 'toDelete' :toDelete});
  }
}

let automatic_group = new AutomaticGroup();
export default automatic_group;
