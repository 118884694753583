import * as React from 'react';
import { SvgIcon } from '@mineral/core';

export const OutlinedRhombus = React.forwardRef(
  (props, ref) => (
    <SvgIcon data-testid="LearnIcon" viewBox="0 0 40 40" {...props} ref={ref}>
      <path d="M19.9706 32.8664L12.7904 20L19.9706 7.13358L27.1508 20L19.9706 32.8664Z" fill={props.color} stroke="#1D5BBF" stroke-width="4" />
    </SvgIcon>
  )
);
