import React, {Component} from 'react'
import {AlarmPolicyList} from '@uim/alarm-policy-components'
import {CircularProgress} from '@mineral/core';
import { Dialog ,Button as FlatButton} from '@mineral/core'
import get from 'lodash/get'

import './policies.less'
import Resource from './Resource'
import HelpButton from '../../utils/help'
import axios from 'axios'

const RedirectFailedDialog = function(props){
  let msg1 = Resource.get('Redirection failed'),//Redirection failed
  msg2 = Resource.get('Verify if the profile associated with the group or device exists.');//Redirection error
  let actions = [
    <FlatButton
    variant="text"
      //OK 
      children= {Resource.get('OK')}
      color ="primary"
      onClick={()=>{props.onClose('close')}}
    />,
  ]

  return (
    <Dialog
      actions={actions}
      modal={false}
      open={props.open}
      contentStyle={{
        width: 'fit-content',
        minWidth: '500px',
        maxWidth:'700px'
      }}
      onClose={props.onClose}>
      <p style={{fontSize:'16px',fontWeight:'600'}}>
        {msg1}
      </p>
      <p style={{fontSize:'14px'}}>{msg2}</p>
    </Dialog>
  )
}

class AlarmPolicies extends Component {
    constructor(props) {
        super(props);
        this.state = {
   acl:props.acl,
    sid: null,
    openDialog:false
  }
    }

  componentWillMount = () => {
	  let overrideSid=axios.defaults.headers.common["Authorization"];
      if(window.OPERATOR_CONSOLE.refreshToken && window.OPERATOR_CONSOLE.refreshToken!=="")
          {
          overrideSid=window.OPERATOR_CONSOLE.refreshToken;
          axios.defaults.headers.common["Authorization"]=window.OPERATOR_CONSOLE.refreshToken;
          window.OPERATOR_CONSOLE.refreshToken="";
          
          }
        this.setState({sid: overrideSid})
 
    if(!this.props.launchFrom) {
    	this.props.setBreadCrumbs([
    	      {
    		//settings
    	        name: Resource.get('Settings'),
    	        link: '/settings',
    	      },
    	      {
    		//alarm policies
    	        name: Resource.get('Alarm Policies'),
    	        link: '/settings/alarm-policies',
    	      },
    	    ])
    }
      
  }
  handleProfileRedirect=(data,profileName)=>{
    let parentProfileId,profileNames=[];
    if(data.parentDetails.length>0){
      let parentDetails = data.parentDetails;
      parentDetails.reverse();
      //Remove profiles startwith setup from list
      parentDetails = parentDetails.filter(item => {
        return item.profileName.includes('Setup adevl') || item.profileName.includes('Setup logmon') || 
        item.profileName.includes('Setup RSP')|| !item.profileName.startsWith('Setup');
      })
      if(parentDetails.length > 0){
        parentProfileId = parentDetails[0].profileId;
        profileNames = parentDetails.map(info=>info.profileName);
      }
      else{
        parentProfileId = data.profileId
      }
    }
    else{
      parentProfileId = data.profileId
    }
    profileNames.push(profileName);

    if(data.targetIds.length === 0 && !data.technologyName){
      this.setState({
        openDialog:true
      })
    }
    else{
     if(data.profileType === 'DEVICE'){
        const csId = data.targetIds[0];
        const InventoryRoute = `/computer-systems/${csId}/configuration`
        this.props.setBreadCrumbs([
          {
            //Inventory
            name: Resource.get("Inventory"),
            link: '/inventory',
          }
        ])
        this.props.history.push({pathname: InventoryRoute, state: {autoOpen: true,profileInfo:{parentProfileId:parentProfileId,profileNames:profileNames}}})
     }
     else if(data.profileType === 'GROUP'){
       data.targetIds.reverse();
       let groupsRoute = '/groups/'+data.targetIds.join('/')+'/configuration';
       if(this.props.launchFrom === 'group'){
           this.props.switchtoPoliciesTab();     
       }
      this.props.history.push({pathname: groupsRoute,state: {autoOpen: true,profileInfo:{parentProfileId:parentProfileId,profileNames:profileNames}}});
     }
     else if(data.profileType === 'CLOUD'){
      const setupWizardLink =  `/configuration/${data.technologyName}/cloud`;
        this.props.setBreadCrumbs([{
          //Setup Wizard
          name: Resource.get('Setup Wizard'),
          link: `/getting-started`,
        },
        {
          //Remote And Cloud
        name:Resource.get("Remote And Cloud") ,
        link: `/remote-cloud-tiles`,
        },
        {
          name:data.technologyDisplayName,
          link:setupWizardLink
        }])
       this.props.history.push({pathname:setupWizardLink,state: {autoOpen: true,profileInfo:{parentProfileId:parentProfileId,profileNames:profileNames}}});
     }
    }
  }

  render() {
    let view = (
      <div className="alarms__spinner" style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <CircularProgress style={{color: '#3272D9'}} />
      </div>
    ),
    helpLink = 'https://techdocs.broadcom.com/bin/gethidpage?hid=HID_Alarm_Policy&space=UIM204&language=&format=rendered'
    
	if(!this.props.saas){
    	helpLink = 'https://techdocs.broadcom.com/bin/gethidpage?hid=HID_Alarm_Policy&space=UIM204&language=&format=rendered'
    }

    if (this.state.sid) {
      view = (
	  <div style={{position:'relative'}}>
      <RedirectFailedDialog onClose={()=>{
            this.setState({
              openDialog:false
            })
      }} open = {this.state.openDialog} text={this.state.text}/>
	    	
        <AlarmPolicyList
          axiosInterceptors={axios.default.interceptors}
          acl={this.state.acl}
          sid={this.state.sid}
          saas={this.props.saas}
	      launchFrom={this.props.launchFrom}
	      groupId={this.props.groupId}
          addBreadCrumb={this.props.addBreadCrumb}
          deleteAllPolicies={get(this.props.featureFlags, 'deleteAllPolicies', false)}
          handleRowClicked={row => {
            if(this.props.launchFrom === 'group'){
                this.props.handleRowClicked(row.data.id);
            }
            else{
            const policyRoute = `/settings/alarm-policies/${row.data.id}`
            this.props.history.push(policyRoute)
            }
          }}
          handleAddClicked={row => {
            if(this.props.launchFrom === 'group'){
              this.props.handleRowClicked('add-policy');
            }
            else{
              const policyRoute = `/settings/alarm-policies/add-policy`
              this.props.history.push(policyRoute)
            }
          }}
          redirectTo = {(profileId,profileName)=>{
            axios.get(`/mcs-ui-app/api/profile/${profileId}/path`).then((response)=>{
              let data = response.data;
              this.handleProfileRedirect(data,profileName);
            }).catch((err)=>{
              this.setState({
                openDialog:true
              })
            })
          }}
        /></div>
      )
    }
    return view
  }
}

export default AlarmPolicies
