import React, { useState } from "react";
import { useEffect } from "react";
import Back from "@material-ui/icons/ArrowBack";
import Forward from "@material-ui/icons/ArrowForward";
import "./PerformanceQos.less";
import QosChartContainer from "./qos-chart-container/QosChartContainer";
import QosChartToolbar from "./qos-chart-container/QosChartToolbar";
import { useDispatch, useSelector } from "react-redux";
import {
  addNewChart,
  deleteChart,
} from "../../../../../../api/performanceReports/actions";
import { IconButton, Tooltip } from "@mineral/core";

export const uniqueChartId = (length = 16) => {
  return `${parseInt(
    Math.ceil(Math.random() * Date.now())
      .toPrecision(length)
      .toString()
      .replace(".", "")
  )}`;
};

export const chartObject = {
  chartId: uniqueChartId(),
  filterId: "",
  minYAxis1: "0",
  maxYAxis1: "0",
  minYAxis2: "0",
  maxYAxis2: "0",
  trend: "false",
  baseline: "false",
  stacked: "false",
  showLegend: "false",
  legend: {
    table: "true",
    source: "true",
    target: "true",
    min: "true",
    max: "true",
    last: "false",
    total: "false",
    average: "true",
    dev: "true",
    percentile: "true",
  },
  title: `Chart Title-1`,
  infoText: "",
  selectedSouce: "",
  series: [],
};
const PerformanceQosData = (props) => {
  const dispatch = useDispatch();
  const [isloading, setIsloading] = useState(false);
  // const [chartList, setChartList] = React.useState([chartObject]);
  const changeLoading = (val) => {
    setIsloading(val);
  }
  const getChartList = (rows) => {
    let chartList = [];
    for (let i = 0; i < rows.length; i++) {
      for (let j = 0; j < rows[i].chart.length; j++) {
        if (rows[i].chart[j].title.length == 0) {
          rows[i].chart[j].title = `Chart Title-${j + 1}`;
        }
        chartList = [...chartList, rows[i].chart[j]];
      }
    }
    return chartList;
  };
  const chartList = useSelector((state) =>
    getChartList(state.performanceReports.chartData.State.page.row)
  );
  const newPreparedCharts = useSelector(
    (state) => state.performanceReports.newPreparedCharts
  );

  const chartTitleExist = (count) => {
    count = count || 1;
    let newTitle = `Chart Title-${chartList.length + count}`;
    let chartTitleList = chartList ? chartList.map((ch) => ch.title) : [];
    let titleExist = chartTitleList.some((element) => {
      return element.trim().toLowerCase() === newTitle.trim().toLowerCase();
    });
    if (titleExist) {
      count = count + 1;
      newTitle = chartTitleExist(count);
    }
    return newTitle;
  };

  const addChart = (id) => {
    const chartId = id ? id : uniqueChartId();
    let count = 1;
    let newTitle =
      chartList.length == 0
        ? `Chart Title-${chartList.length + count}`
        : chartTitleExist(count);
    let chart = { ...chartObject, chartId, title: newTitle };
    let updatedList = [...chartList];
    updatedList.unshift(chart);
    dispatch(addNewChart(chart));
  };

  useEffect(() => {
    if (newPreparedCharts && newPreparedCharts.length > 0) {
      let newChart = newPreparedCharts.find(
        (newChart) =>
          chartList.find((chart) => chart.chartId == newChart.chartId) ==
          undefined
      );
      if (newChart != undefined) {
        addChart(newChart.chartId);
      }
    }
  }, [newPreparedCharts]);

  const isToggleQosHandler = () => {
    props.setIsToggleQos(!props.isToggleQos);
  };

  const toggleStyle = props.isToggleQos
    ? {
        backgroundColor: "white",
        width: "81%",
        height: "calc(100vh - 94px)",
        overflow: "auto",
        display: "flex",
      }
    : {
        backgroundColor: "white",
        width: "100%",
        height: "calc(100vh - 94px)",
        overflow: "auto",
        display: "flex",
      };

  return (
    <div style={toggleStyle}>
      <div className="halfCircle">
        <Tooltip title={props.isToggleQos ? "Close" : "Open"}>
          <IconButton
            onClick={isToggleQosHandler}
            style={{ padding: 0 }}
            name={props.isToggleQos ? "Close" : "Open"}
            aria-label={props.isToggleQos ? "Close" : "Open"}
          >
            {props.isToggleQos ? (
              <Back
                style={{
                  color: "black",
                  padding: "5px 0px 5px 2px",
                }}
              />
            ) : (
              <Forward
                style={{
                  color: "black",
                  padding: "5px 0px 5px 2px",
                }}
              />
            )}
          </IconButton>
        </Tooltip>
      </div>

      <div className="performance-qos-container">
        <QosChartToolbar addChart={addChart} isloading={isloading} />
        <QosChartContainer
          chartList={chartList}
          changeLoading={changeLoading}
          onQosChartDelete={(chartId) => {
            // setChartList(chartList.filter(chart=>chart.chartId!=chartId));
            dispatch(deleteChart(chartId));
          }}
        />
      </div>
    </div>
  );
};

export default PerformanceQosData;
