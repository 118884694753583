// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Settings
  content["Settings"] =
  "設定";

  //Alarms and Notifications
  content["Alarms and Notifications"] =
  "アラームおよび通知";

  //Dashboard and Reports
  content["Dashboard and Reports"] =
  "ダッシュボードおよびレポート";

  //Integrations
  content["Integrations"] =
  "統合";

  //Infrastructure
  content["Infrastructure"] =
  "インフラストラクチャ";

  //System Administration
  content["System Administration"] =
  "システム管理";

  // END OF LOCALIZATION

export default content;
