import { TreeTable, TreeState } from "cp-react-tree-table";
import React, { Component } from "react";
import "./metricList.less";
import { Menu, MenuItem } from "@mineral/core";
import {
  getMetricDevices,
  saveMetric,
  fetchAllMetric,
  getMetricsData,
} from "./../../api/deviceMetrics/actions";
import { IconButton, Tooltip, Button, Checkbox ,Switch,InputAdornment,TextField,OutlinedInput,  Dialog,Typography,
  DialogTitle,
  DialogContent,FormControlLabel,
  DialogActions,} from "@mineral/core";
import { connect } from "react-redux";
import { isEqual } from "lodash";
import DeleteOutlineIcon from "../../../icons/DeleteOutlineIcon";
import { styled } from "@mui/material/styles";
//import Switch from "@mui/material/Switch";
import SearchIcon from "@material-ui/icons/Search";
import SortIcon from "@material-ui/icons/Sort";
import debounce from "lodash.debounce";
import ArrowForward from "@material-ui/icons/ArrowForwardIos";
import {

  ClearIcon as Clear,
} from "../../../ui-components/uim-components";

let metricTypeArr = [];
let selectedDeviceArr = [];

const StyledSwitch =styled(Switch)(({ theme }) => ({
  padding: 8,

  
  "& .MuiSwitch-track": {
    backgroundColor: '#666666',
    opacity: 0.8,
    borderRadius: 22 / 2,
    "&:before, &:after": {
     
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
    "&:before": {
    
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    "&:after": {
     
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  "& .MuiSwitch-thumb": {
    
    backgroundColor:'#FFFFFF',
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2,
  },
}));

class MetricList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      treeValue: TreeState.create([]),
      selectedMetrics: [],
      selectedCIs: {},
      menuAnchorEl: null,
      selectedRowData: {},
      expanded: false,
      preparedTree: [],
      searchSourceName: "",
      searchTargetName: {},
      openedMetrics: [],
      namesTypes: [],
      showCloseMetricListConfirmation:false,
    };
  }

  componentDidMount() {
    this.props.singleDevice && this.props.devices.length
      ? this.fetchMetricData([this.props.devices[0]])
      : this.fetchMetricData(this.props.selectedDevices);
  }

  componentWillReceiveProps(nextProps) {
    if (!isEqual(nextProps.devices, this.props.devices)) {
      if (nextProps.singleDevice && nextProps.devices.length) {
        this.fetchMetricData([nextProps.devices[0]]);
      } else {
        this.fetchMetricData(nextProps.selectedDevices);
      }
    }
    if (!this.props.drawerIsClosing && nextProps.drawerIsClosing) {
      !this.props.singleDevice && this.props.openDevice(false);
      this.setMetricMetaData([], {});
    }
    if (
      nextProps.viewAction != this.props.viewAction &&
      nextProps.viewAction == 2
    ) {
      this.setMetricMetaData(
        nextProps.deviceMetrics.selectedMetrics,
        nextProps.deviceMetrics.selectedCIs,
        true
      );
    } else if (
      nextProps.viewAction == this.props.viewAction &&
      nextProps.viewAction == 2
    ) {
      if (sessionStorage.getItem("editCalled")) {
        this.setMetricMetaData(
          nextProps.deviceMetrics.selectedMetrics,
          nextProps.deviceMetrics.selectedCIs
        );
        sessionStorage.removeItem("editCalled");
      }
    } else if (
      nextProps.viewAction != this.props.viewAction &&
      nextProps.viewAction == 1
    ) {
      this.setMetricMetaData([], {});
    } else if (
      nextProps.viewAction == this.props.viewAction &&
      nextProps.viewAction == 1
    ) {
      if (sessionStorage.getItem("newCalled")) {
        this.setMetricMetaData([], {});
        sessionStorage.removeItem("newCalled");
      }
    } else {
      this.setMetricMetaData(
        nextProps.deviceMetrics.selectedMetrics,
        nextProps.deviceMetrics.selectedCIs,
        true
      );
    }
    if (!isEqual(this.props, { ...nextProps, viewName: this.props.viewName })) {
      setTimeout(() => {
        const namesTypes = [];
        this.getTypesAndNamesMetrics(
          nextProps.deviceMetrics.metrics,
          namesTypes
        );
        metricTypeArr = this.parseMetric(nextProps.deviceMetrics.metrics);
        this.setState({
          treeValue: TreeState.create(metricTypeArr),
          searchTargetName: {},
          namesTypes,
        });
        if (this.state.expanded) {
          this.handleOnExpandAll();
        }
      }, 0);
    }
  }

  fetchMetricData = (selectedIndices) => {
    if (selectedIndices && selectedIndices.length) {
      this.props
        .fetchMetrics(
          Array.isArray(selectedIndices)
            ? selectedIndices.join()
            : selectedIndices,
          this.props.isInterface
        )
        .then(() => {
          const namesTypes = [];
          this.getTypesAndNamesMetrics(
            this.props.deviceMetrics.metrics,
            namesTypes
          );
          metricTypeArr = this.parseMetric(this.props.deviceMetrics.metrics);
          this.setState({
            treeValue: TreeState.create(metricTypeArr),
            preparedTree: this.props.deviceMetrics.metrics,
            namesTypes,
            searchTargetName: {},
          });
        });
    }
  };

  getSubMetrics = (metric) => {
    let metricObj = {
      data: {
        name: metric.name,
      },
      height: 40,
      children: [],
    };
    if (metric.sub.length) {
      metricObj.children = metric.sub.map((mtr) => this.getSubMetrics(mtr));
    }
    if (metric.metrics.length) {
      metricObj.children = [
        ...metricObj.children,
        ...metric.metrics.map((mtr) => {
          return {
            data: {
              ...mtr,
              name: mtr.label,
              status:
                this.state.selectedMetrics.length &&
                this.state.selectedMetrics.indexOf(mtr.metricType) > -1,
              ciNameArr: this.state.selectedCIs[mtr.metricType]
                ? JSON.parse(
                    JSON.stringify(this.state.selectedCIs[mtr.metricType])
                  )
                : [],
            },
            // height: 100,
            height: 0,
            // 35 +
            // (this.state.openedMetrics.includes(
            //   `${mtr.label}-${mtr.metricType}`
            // )
            //   ? 30 + mtr.count * 30
            //   : 0),
          };
        }),
      ];
    }
    metricObj.data.childrenLength = metricObj.children.length;
    return metricObj;
  };

  parseMetric = (metricData) => {
    return metricData.map((metric) => {
      let metricObj = {
        data: { name: metric.name },
        height: 40,
        children: [],
      };
      if (metric.sub.length) {
        metricObj.children = metric.sub.map((mtr) => this.getSubMetrics(mtr));
      }
      if (metric.metrics.length) {
        metricObj.children = [
          ...metricObj.children,
          ...metric.metrics.map((mtr) => ({
            data: {
              ...mtr,
              name: mtr.label,
              status:
                this.state.selectedMetrics.length &&
                this.state.selectedMetrics.indexOf(mtr.metricType) > -1,
              ciNameArr: this.state.selectedCIs[mtr.metricType]
                ? JSON.parse(
                    JSON.stringify(this.state.selectedCIs[mtr.metricType])
                  )
                : [],
            },
            height: 0,
            // 35 +
            // (this.state.openedMetrics.includes(
            //   `${mtr.label}-${mtr.metricType}`
            // )
            //   ? 30 + mtr.count * 30
            //   : 0),
          })),
        ];
      }
      metricObj.data.childrenLength = metricObj.children.length;
      return metricObj;
    });
  };

  setMetricMetaData = (selectedMetrics, selectedCIs) => {
    this.setState({
      selectedMetrics,
      selectedCIs: selectedCIs,
    });
  };

  handleOnExpandAll = () => {
    this.state.treeValue.hasData &&
      this.setState((state) => {
        return {
          treeValue: TreeState.expandAll(state.treeValue),
          expanded: true,
          searchTargetName: {},
        };
      });
  };

  handleOnCollapseAll = () => {
    this.state.treeValue.hasData &&
      this.setState((state) => {
        return {
          treeValue: TreeState.collapseAll(state.treeValue),
          searchTargetName: {},
          expanded: false,
        };
      });
  };
  handleOnChange = (newValue) => {
    this.setState({ treeValue: newValue });
  };

  handleCancel = () => {
    !this.props.singleDevice && this.props.openDevice(false);
    this.setMetricMetaData([], {});
    this.props.toggleDrawer();
    this.props.loadedView();
    metricTypeArr = this.parseMetric(this.props.deviceMetrics.metrics);
    this.setState({
      treeValue: TreeState.create(metricTypeArr),
    });
  };
  handleBack = () => {
    this.props.openDevice(true);
  };

  manageViews = () => {
    this.props.manageViews();
  };
  deleteView = () => {
    const id = this.props.id;
    this.props.deleteView(id);
  };

  handleMenuClick = (event, row) => {
    this.setState({ menuAnchorEl: event.currentTarget, selectedRowData: row });
  };
  handleActionMenuClose = () => {
    this.setState({ menuAnchorEl: null });
  };

  setSearchResult = (tree, newState, textSearch) => {
    tree.forEach((node) => {
      if (
        node.name &&
        node.name.toLowerCase().includes(textSearch.toLowerCase())
      ) {
        newState.push(node);
      } else if (node.sub && node.sub.length) {
        this.setSearchResult(node.sub, newState, textSearch);
      } else if (node.metrics && node.metrics.length) {
        const newMetrics = node.metrics
          .map((metric) => {
            const filterCiNames = metric.ciNmaes.filter((ciName) =>
              ciName.toLowerCase().includes(textSearch.toLowerCase())
            );
            if (metric.label.toLowerCase().includes(textSearch.toLowerCase())) {
              return metric;
            } else if (filterCiNames.length) {
              return { ...metric, ciNmaes: filterCiNames };
            } else {
              return null;
            }
          })
          .filter((metric) => metric);
        if (newMetrics.length) {
          newState.push({ ...node, metrics: newMetrics });
        }
      }
    });
  };

  getTypesAndNamesMetrics(metrics, namesTypes) {
    metrics.forEach((metric) => {
      if (metric.metrics.length) {
        namesTypes.push(
          ...metric.metrics.map(
            (curMetric) => `${curMetric.label}-${curMetric.metricType}`
          )
        );
      }
      if (metric.sub) {
        this.getTypesAndNamesMetrics(metric.sub, namesTypes);
      }
    });
  }

  // searchResult = (textSearch) => {
  //   const newState = [];
  //   this.setSearchResult(this.state.preparedTree, newState, textSearch);
  //   console.log(newState);
  // };

  debouncedChangeValue = debounce((text) => {
    const newState = [];
    this.setSearchResult(this.state.preparedTree, newState, text);
    metricTypeArr = this.parseMetric(newState);
    this.setState({
      treeValue: TreeState.create(metricTypeArr),
    });
    if (this.state.expanded) {
      this.handleOnExpandAll();
    }
  }, 600);

  render() {
    const { treeValue, selectedMetrics, selectedCIs } = this.state;
    const { groupId, groupName } = this.props;
    let selectedCIsLength = 0;
    Object.keys(selectedCIs).forEach((cisType) => {
      selectedCIsLength += selectedCIs[cisType].length;
    });
    let confirmMassage = 'Are you sure you want to close the dialog?'
    const confirmActions = [
      
      <Button      
          
        children={"No"}
        variant="outlined"
        color="primary"
        onClick={()=>{this.setState({
          showCloseMetricListConfirmation:false
        });}}
       
      />  
     ,
      
      <Button      
       
        children={"Yes"}
        variant="contained"
        color="error"
        onClick={() => {
          this.setState({
          showCloseMetricListConfirmation:false
        });
        this.handleCancel()}}
       
      />  ];
    return (<>
    <Dialog
       open={this.state.showCloseMetricListConfirmation}
       onClose={()=>{this.setState({
          showCloseMetricListConfirmation:false
        });}}     
     >
       <DialogTitle component="div" disableTypography>
         
             
             <Typography component="h1" variant="h4">
               {"Confirm exit"}
             </Typography>
          
       </DialogTitle>
       <div style={{height:'0px'}}>

       <Tooltip title={"Close"} style={{float:'right',marginRight:'8px',top:'-50px'}}> 
        <IconButton autoFocus  onClick={()=>{this.setState({
          showCloseMetricListConfirmation:false
        });}}>
          <Clear role='figure' />
        </IconButton>
         </Tooltip> 
       </div>
       <DialogContent>{confirmMassage}</DialogContent>
       <DialogActions>
         {confirmActions}
       </DialogActions>
     </Dialog>

      <div className="metric-tree">
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
             
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <span className="view-name">Metrics Tree</span>
              <div className="metric-tree-delimiter"></div>
              <span style={{ color: "#333840", marginRight: "10px" }}>
                {selectedCIsLength} Selected
              </span>
              {groupId!==null && groupId!==undefined && groupName !==null && groupName!==undefined && ((groupId > 0) || (groupId==0 && groupName==='Root')) && (
                <span className="stepper">Step 2 of 2</span>
              )}
            </div>
            <div>
              <div style={{ display: "flex" }}>
                {/* <Button
                  variant="text"
                  className="expand--btn"
                  onClick={this.handleOnExpandAll}
                  disabled={!treeValue.hasData}
                >
                  Expand All
                </Button>
                <Button
                  variant="text"
                  className="expand--btn"
                  onClick={this.handleOnCollapseAll}
                  disabled={!treeValue.hasData}
                >
                  Collapse All
                </Button> */}
                <FormControlLabel
                  control={
                    <StyledSwitch
                      disabled={!treeValue.hasData}
                      checked={this.state.expanded}
                      inputProps={{ role: "switch" }}
                      onClick={() => {
                        if (this.state.expanded) {
                          this.handleOnCollapseAll();
                          this.setState({ openedMetrics: [] });
                        } else {
                          this.handleOnExpandAll();
                          this.setState({
                            openedMetrics: this.state.namesTypes,
                          });
                        }
                      }}
                    />
                  }
                  label={this.state.expanded ? "Collapse All" : "Expand All"}
                  labelPlacement="start"
                />
              </div>
            </div>
          </div>
          <div style={{marginTop:'16px'}}>
            <OutlinedInput fullWidth  sx={{
      input: {
        
         "&::placeholder": {    // <----- Add this.
            color: 'black !important',
            opacity:0.8
           
         }}}}
              placeholder="Search by Source or Metric or Target"
              inputProps={{ "aria-label": "Search by Source or Metric or Target" }}
              value={this.state.searchSourceName}
              onChange={(event) => {
                this.setState({ searchSourceName: event.target.value });
                this.debouncedChangeValue(event.target.value);

              }}
              // InputProps={{ disableUnderline: true }}
              style={{ paddingRight: "0" }}
              endAdornment={
                <SearchIcon role="figure" title="Search" name="Search" />
              }
            />
            {/* <span className="search-icon">
            <SearchIcon />
          </span> */}
          </div>
          

          <TreeTable style={{marginTop:'16px',paddingBottom:'16px'}}
            height={window.innerHeight - 280}
            headerHeight="40"
            value={treeValue}
            onChange={this.handleOnChange}
          >
            {/* <TreeTable.Column
              basis="90px"
              grow="1"
              renderCell={this.renderIndexCell}
              renderHeaderCell={() => <span>#</span>}
            /> */}
            <TreeTable.Column
              basis="300px"
              grow="2"
              renderCell={this.renderNameCell}
              renderHeaderCell={() => <span>Name</span>}
            />
            <TreeTable.Column
              grow="1"
              basis="40px"
              renderCell={this.renderUnitCell}
              renderHeaderCell={() => <span>Unit</span>}
            />
            {/* <TreeTable.Column
              grow="1"
              basis="40px"
              renderCell={this.renderCICell}
              renderHeaderCell={() => <span>#CI</span>}
            /> */}
          </TreeTable>
        </div>
        <div
          style={{marginBottom:'16px',
            display: "flex",
            justifyContent: "space-between",
            backgroundColor: "white",
          }}
        >
          {/*  {this.props.viewAction === 2 && (
            <div onClick={this.deleteView} className="btn-delete-view">
              <DeleteOutlineIcon />
            </div>
          )}*/}
          <Button
            onClick={this.manageViews}
            variant="text"
           
          >
            Manage Views
          </Button>
          <div className="buttons-container">
            {groupId >= 0 && groupName && (
              <Button
                variant="text"
                onClick={this.handleBack}
                // className="cancel--btn"
                style={{  marginRight: "16px" }}
              >
                Back
              </Button>
            )}
            <Button
              variant="text"
              onClick={()=>{this.handleCancel()}}
              // className="cancel--btn"
              style={{  marginRight: "16px" }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              disabled={this.props.viewName.trim().length == 0}
              onClick={() =>
                this.props.handleSave({
                  selectedMetrics: this.state.selectedMetrics,
                  selectedCIs: this.state.selectedCIs,
                })
              }
            >
              {this.props.viewAction === 1 ? "Create" : "Update"}
            </Button>
          </div>
          <Menu
            id="menuActionMetric"
            anchorEl={this.state.menuAnchorEl}
            keepMounted
            onClose={this.handleActionMenuClose}
            open={this.state.menuAnchorEl != null}
            multiple={true}
            //touchTapCloseDelay={0}
            maxHeight={200}
          >
            {this.state.selectedRowData.data &&
            this.state.selectedRowData.data.ciNmaes &&
            this.state.selectedRowData.data.ciNmaes.length
              ? this.state.selectedRowData.data.ciNmaes.map((ciName, index) => (
                  <MenuItem key={index}>
                  <FormControlLabel
              control={
                  <Checkbox 
                style={{ padding: 0, marginRight: "7px" }}
                disabled={!this.state.selectedRowData.data.status}
                      checked={
                        this.state.selectedRowData.data.ciNameArr &&
                        this.state.selectedRowData.data.ciNameArr.indexOf(
                          ciName
                        ) > -1
                      }
               
               
                inputProps={{ 'aria-label': ciName }}
                onChange={(event) => {
                        this.state.selectedRowData.data.ciNameArr.indexOf(
                          ciName
                        ) > -1
                          ? this.state.selectedRowData.data.ciNameArr.splice(
                              this.state.selectedRowData.data.ciNameArr.indexOf(
                                ciName
                              ),
                              1
                            )
                          : this.state.selectedRowData.data.ciNameArr.push(
                              ciName
                            );
                        let selectedCIs = JSON.parse(
                          JSON.stringify(this.state.selectedCIs)
                        );
                        !selectedCIs.hasOwnProperty(
                          this.state.selectedRowData.data.metricType
                        ) &&
                          (selectedCIs[
                            this.state.selectedRowData.data.metricType
                          ] = []);
                        selectedCIs[
                          this.state.selectedRowData.data.metricType
                        ].indexOf(ciName) > -1
                          ? selectedCIs[
                              this.state.selectedRowData.data.metricType
                            ].splice(
                              selectedCIs[
                                this.state.selectedRowData.data.metricType
                              ].indexOf(ciName),
                              1
                            )
                          : selectedCIs[
                              this.state.selectedRowData.data.metricType
                            ].push(ciName);
                        this.state.selectedRowData.updateData({
                          ...this.state.selectedRowData.data,
                          ciNameArr: [
                            ...this.state.selectedRowData.data.ciNameArr,
                          ],
                        });
                        this.setMetricMetaData(
                          this.state.selectedMetrics,
                          selectedCIs
                        );
                      }}/>}
                    
                      label={ciName}/>
                  </MenuItem>
                ))
              : ""}
          </Menu>
        </div>
      </div>
      </>
    );
  }

  renderIndexCell = (row) => {
    return (
      <div
        style={{ paddingLeft: row.metadata.depth * 15 + "px" }}
        className={
          row.metadata.hasChildren ? "with-children" : "without-children"
        }
      >
        {row.metadata.hasChildren ? (
          <button
            className={`toggle-button ${
              row.$state.isExpanded ? "rotate-icon" : null
            }`}
            onClick={row.toggleChildren}
          ></button>
        ) : (
        
                  <Checkbox 
                style={{ padding: 0, marginRight: "7px" }}
                onChange={(event) => {
              let selectedMetrics = [...this.state.selectedMetrics];
              row.data.status
                ? selectedMetrics.splice(
                    selectedMetrics.indexOf(row.data.metricType),
                    1
                  )
                : selectedMetrics.push(row.data.metricType);
              let newRowdata = row.data;
              newRowdata.status = !row.data.status;
              row.updateData(newRowdata);
              this.setMetricMetaData(selectedMetrics, this.state.selectedCIs);
            }}
                checked={row.data.status}
                />
        )}
      </div>
    );
  };

  renderNameCell = (row) => {
    let sort;
    if (
      !row.metadata.hasChildren &&
      row.data &&
      row.data.ciNmaes &&
      row.data.ciNmaes.length &&
      this.state.openedMetrics.includes(
        `${row.data.name}-${row.data.metricType}`
      )
    ) {
      if (isEqual(row.data.ciNmaes, row.data.ciNmaes.slice().sort())) {
        sort = "asc";
      } else if (
        isEqual(row.data.ciNmaes, row.data.ciNmaes.slice().sort().reverse())
      ) {
        sort = "desc";
      }
    }
    return (
      <div style={{ width: "100%" }}>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexFlow: "row nowrap",
            alignItems: "center",
          }}
        >
          <div
            // style={{ paddingLeft: row.metadata.depth * 20 + "px" }}
            className={
              row.metadata.hasChildren ? "with-children" : "without-children"
            }
            style={{
              height: "32px",
            }}
          >
            {row.metadata.hasChildren ? (
              // <button
              //   className={`toggle-button ${
              //     row.$state.isExpanded ? "rotate-icon" : null
              //   }`}
              //   onClick={row.toggleChildren}
              // ></button>
              <Tooltip title={row.$state.isExpanded ? "Collapse" : "Expand"}>
              <IconButton
                onClick={row.toggleChildren}
                aria-label={row.$state.isExpanded ? "Collapse" : "Expand"}
                style={{
                 
                  marginRight: "14px",
                  marginLeft: row.metadata.depth * 20 + "px",
                }}
              >
                <ArrowForward
                  className={`toggle-button ${
                    row.$state.isExpanded ? "rotate-icon" : null
                  }`}
                  role="img"
                  title={row.$state.isExpanded ? "Collapse" : "Expand"}
                  style={{
                    width: "15px",
                  }}
                />
              </IconButton>
              </Tooltip>
            ) : null}
          </div>
          {!row.metadata.hasChildren &&
          row.data &&
          row.data.ciNmaes &&
          row.data.ciNmaes.length ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                flexFlow: "row nowrap",
                cursor: "pointer",
                alignItems: "center",
              }}
            >
              {/* <button
                className={`toggle-button ${
                  this.state.openedMetrics.includes(
                    `${row.data.name}-${row.data.metricType}`
                  )
                    ? "rotate-icon"
                    : null
                }`} */}
                <Tooltip  title={row.$state.isExpanded ? "Collapse" : "Expand"}>
              <IconButton
                style={{
                  marginRight: "14px",
                  marginLeft: row.metadata.depth * 20 + "px",
                
                }}
                aria-label={row.$state.isExpanded ? "Collapse" : "Expand"}
                onClick={() => {
                  this.setState(
                    (prevState) => {
                      const prevOpenedMetrics = [...prevState.openedMetrics];
                      const indexMetric = prevOpenedMetrics.indexOf(
                        `${row.data.name}-${row.data.metricType}`
                      );
                      if (indexMetric === -1) {
                        prevOpenedMetrics.push(
                          `${row.data.name}-${row.data.metricType}`
                        );
                      } else {
                        prevOpenedMetrics.splice(indexMetric, 1);
                      }
                      return { openedMetrics: prevOpenedMetrics };
                    },
                    () => {
                      // this.debouncedChangeValue(this.state.searchSourceName);
                    }
                  );
                }}
              >
                <ArrowForward
                  className={
                    this.state.openedMetrics.includes(
                      `${row.data.name}-${row.data.metricType}`
                    )
                      ? "rotate-icon"
                      : null
                  }
                  role="img"
                  title={row.$state.isExpanded ? "Collapse" : "Expand"}
                  style={{
                    width: "15px",
                  }}
                />
              </IconButton>
              </Tooltip>
              <Checkbox 
                style={{ padding: 0, marginRight: "7px" }}
               
                checked={Boolean(
                  this.state.selectedCIs &&
                    this.state.selectedCIs[row.data.metricType] &&
                    this.state.selectedCIs[row.data.metricType].length &&
                    this.state.selectedCIs[row.data.metricType].length ===
                      row.data.ciNmaes.length
                )}
                indeterminate={Boolean(
                  this.state.selectedCIs &&
                    this.state.selectedCIs[row.data.metricType] &&
                    this.state.selectedCIs[row.data.metricType].length &&
                    this.state.selectedCIs[row.data.metricType].length !==
                      row.data.ciNmaes.length
                )}
                inputProps={{ 'aria-label': row.data.label }}
                onChange={(event) => {
                  const selectedMetrics = [...this.state.selectedMetrics];
                  const selectedCIs = JSON.parse(
                    JSON.stringify(this.state.selectedCIs)
                  );
                  if (event.target.checked) {
                    if (selectedMetrics.indexOf(row.data.metricType) === -1) {
                      selectedMetrics.push(row.data.metricType);
                    }
                    selectedCIs[row.data.metricType] = [...row.data.ciNmaes];
                  } else {
                    selectedMetrics.splice(
                      selectedMetrics.indexOf(row.data.metricType),
                      1
                    );
                    delete selectedCIs[row.data.metricType];
                  }
                  // row.data.status
                  //   ? selectedMetrics.splice(
                  //       selectedMetrics.indexOf(row.data.metricType),
                  //       1
                  //     )
                  //   : selectedMetrics.push(row.data.metricType);
                  // let newRowdata = row.data;
                  // newRowdata.status = !row.data.status;
                  // row.updateData(newRowdata);
                  this.setMetricMetaData(selectedMetrics, selectedCIs);
                  // row.data.ciNmaes.forEach((ciName) => {
                  //   if (
                  //     event.target.checked &&
                  //     row.data.ciNameArr.indexOf(ciName) === -1
                  //   ) {
                  //     this.selectCiNameMetric(row, ciName);
                  //   } else if (
                  //     !event.target.checked &&
                  //     row.data.ciNameArr.indexOf(ciName) !== -1
                  //   ) {
                  //     this.selectCiNameMetric(row, ciName);
                  //   }
                  // });
                }}
              />
              {/* <input
                type="checkbox"
                checked={row.data.status}
                style={{
                  width: "20px",
                  // height: "20px",
                  // margin: "0px 8px 0px 0px",
                }}
                onChange={(event) => {
                  let selectedMetrics = [...this.state.selectedMetrics];
                  row.data.status
                    ? selectedMetrics.splice(
                        selectedMetrics.indexOf(row.data.metricType),
                        1
                      )
                    : selectedMetrics.push(row.data.metricType);
                  let newRowdata = row.data;
                  newRowdata.status = !row.data.status;
                  row.updateData(newRowdata);
                  this.setMetricMetaData(
                    selectedMetrics,
                    this.state.selectedCIs
                  );
                }}
              /> */}
              <Tooltip title={row.data.name} arrow>
                <span
                  // className={`${row.data.status ? "make-bold" : null}`}
                  style={{
                    textOverflow: "ellipsis",
                    // whiteSpace: "nowrap",
                    overflow: "hidden",
                    // width: "100%",
                    textAlign: "left",
                    fontSize: "14px",
                  }}
                  onClick={() => {
                    this.setState(
                      (prevState) => {
                        const prevOpenedMetrics = [...prevState.openedMetrics];
                        const indexMetric = prevOpenedMetrics.indexOf(
                          `${row.data.name}-${row.data.metricType}`
                        );
                        if (indexMetric === -1) {
                          prevOpenedMetrics.push(
                            `${row.data.name}-${row.data.metricType}`
                          );
                        } else {
                          prevOpenedMetrics.splice(indexMetric, 1);
                        }
                        const newSearchTargetName = {
                          ...prevState.searchTargetName,
                        };
                        newSearchTargetName[
                          `${row.data.name}-${row.data.metricType}`
                        ] = null;
                        return {
                          openedMetrics: prevOpenedMetrics,
                          searchTargetName: newSearchTargetName,
                        };
                      },
                      () => {
                        // this.debouncedChangeValue(this.state.searchSourceName);
                      }
                    );
                  }}
                >
                  {row.data.name}
                </span>
              </Tooltip>
              <span style={{ color: "#333840 ", marginLeft: "8px" }}>
                (
                {row.data.ciNmaes && row.data.ciNmaes.length
                  ? row.data.ciNmaes.length
                  : 0}
                )
              </span>
              <Tooltip title={"Sort"+(sort === "desc"?" in ascending order.":" in descending order")} >
              <IconButton
                style={{ marginLeft: "auto" }}
                disabled={!sort}
                aria-label="Sort"
                onClick={(event) => {
                  if (!sort) {
                    row.updateData({
                      ...row.data,
                      ciNmaes: [...row.data.ciNmaes.slice().sort()],
                    });
                  } else if (sort === "asc") {
                    row.updateData({
                      ...row.data,
                      ciNmaes: [...row.data.ciNmaes.slice().sort().reverse()],
                    });
                  } else if (sort === "desc") {
                    row.updateData({
                      ...row.data,
                      ciNmaes: [...row.data.ciNmaes.slice().sort()],
                    });
                    this.setMetricMetaData(
                      this.state.selectedMetrics,
                      this.state.selectedCIs
                    );
                  }
                }}
              >
                <SortIcon
                  style={{
                    transform: `rotate(${sort === "desc" ? 0 : 180}deg)`,
                  }}
                  role="img"
                  title="Sort"
                  // color={sort ? undefined : "disabled"}
                />
              </IconButton>
              </Tooltip>
            </div>
          ) : (
            <Tooltip title={row.data.name} arrow>
              <span
                className={`${row.data.status ? "make-bold" : null}`}
                style={{
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  // width: "100%",
                  textAlign: "left",
                  fontSize: "14px",
                }}
              >
                {row.data.name}
              </span>
            </Tooltip>
          )}
        </div>
        {!row.metadata.hasChildren &&
        row.data &&
        row.data.ciNmaes &&
        row.data.ciNmaes.length &&
        this.state.openedMetrics.includes(
          `${row.data.name}-${row.data.metricType}`
        ) ? (
          <div
            style={{
              paddingLeft: row.metadata.depth * 20 + 60 + "px",
              marginBottom: "7px",
            }}
          >
            {/* <div
              style={{
                position: "relative",
                margin: "7px 0 5px",
                width: "250px",
              }}
            >
              <input
                style={{
                  width: "100%",
                  height: "22px",
                  border: "1px solid #C8D1E0",
                }}
                placeholder="Search by Target name"
                defaultValue={
                  this.state.searchTargetName[
                    `${row.data.name}-${row.data.metricType}`
                  ]
                }
                onChange={(event) => {
                  this.setState((prevState) => {
                    const newSearchTargetName = {
                      ...prevState.searchTargetName,
                    };
                    newSearchTargetName[
                      `${row.data.name}-${row.data.metricType}`
                    ] = event.target.value;
                    return { searchTargetName: newSearchTargetName };
                  });
                }}
              />
              <SearchIcon
                style={{ position: "absolute", top: "2px", right: "0" }}
                color="primary"
              />
            </div> */}
            {/* <div
              style={{
                marginTop: "5px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <input
                type="checkbox"
                style={{
                  width: "15px",
                  height: "15px",
                  margin: "0 8px 0 5px",
                  flex: "0 0 auto",
                }}
                disabled={!row.data.status}
                checked={
                  row.data.ciNameArr &&
                  row.data.ciNameArr.length === row.data.ciNmaes.length
                }
                onChange={(event) => {
                  row.data.ciNmaes.forEach((ciName) => {
                    if (
                      !event.target.checked ||
                      row.data.ciNameArr.indexOf(ciName) === -1
                    ) {
                      this.selectCiNameMetric(row, ciName);
                    }
                  });
                }}
              />
              <span>Select All</span>
              <SortIcon
                style={{
                  marginLeft: "auto",
                  transform: `rotate(${sort === "desc" ? 0 : 180}deg)`,
                  cursor: "pointer",
                }}
                color={sort ? undefined : "disabled"}
                onClick={(event) => {
                  if (!sort) {
                    row.updateData({
                      ...row.data,
                      ciNmaes: [...row.data.ciNmaes.slice().sort()],
                    });
                  } else if (sort === "asc") {
                    row.updateData({
                      ...row.data,
                      ciNmaes: [...row.data.ciNmaes.slice().sort().reverse()],
                    });
                  } else if (sort === "desc") {
                    row.updateData({
                      ...row.data,
                      ciNmaes: [...row.data.ciNmaes.slice().sort()],
                    });
                    this.setMetricMetaData(
                      this.state.selectedMetrics,
                      this.state.selectedCIs
                    );
                  }
                }}
              />
            </div> */}
            {row.data.ciNmaes
              .filter(
                (ciName) =>
                  !(
                    this.state.searchTargetName[
                      `${row.data.name}-${row.data.metricType}`
                    ] &&
                    !ciName
                      .toLowerCase()
                      .includes(
                        this.state.searchTargetName[
                          `${row.data.name}-${row.data.metricType}`
                        ].toLowerCase()
                      )
                  )
              )
              .map((ciName) => (
                <div
                  key={ciName}
                  style={{
                    marginTop: "5px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Checkbox inputProps={{ 'aria-label': ciName }}
                    style={{ padding: 0, margin: "0 7px 0 5px" }}
                    // style={{
                    //   width: "15px",
                    //   height: "15px",
                    //   margin: "0 8px 0 5px",
                    //   flex: "0 0 auto",
                    // }}
                    // disabled={!row.data.status}
                    checked={Boolean(
                      this.state.selectedCIs[row.data.metricType] &&
                        this.state.selectedCIs[row.data.metricType].indexOf(
                          ciName
                        ) > -1
                    )}
                    onChange={(event) => {
                      const selectedMetrics = [...this.state.selectedMetrics];
                      const selectedCIs = JSON.parse(
                        JSON.stringify(this.state.selectedCIs)
                      );
                      if (event.target.checked) {
                        if (
                          selectedMetrics.indexOf(row.data.metricType) === -1
                        ) {
                          selectedMetrics.push(row.data.metricType);
                          selectedCIs[row.data.metricType] = [ciName];
                        } else {
                          selectedCIs[row.data.metricType].push(ciName);
                        }
                      } else {
                        if (
                          selectedCIs[row.data.metricType].length === 1 &&
                          selectedCIs[row.data.metricType][0] === ciName
                        ) {
                          selectedMetrics.splice(
                            selectedMetrics.indexOf(row.data.metricType),
                            1
                          );
                          delete selectedCIs[row.data.metricType];
                        } else {
                          selectedCIs[row.data.metricType].splice(
                            selectedCIs[row.data.metricType].indexOf(ciName),
                            1
                          );
                        }
                      }
                      this.setMetricMetaData(selectedMetrics, selectedCIs);
                      // this.selectCiNameMetric(row, ciName);
                      // row.data.ciNameArr.indexOf(ciName) > -1
                      //   ? row.data.ciNameArr.splice(
                      //       row.data.ciNameArr.indexOf(ciName),
                      //       1
                      //     )
                      //   : row.data.ciNameArr.push(ciName);
                      // let selectedCIs = JSON.parse(
                      //   JSON.stringify(this.state.selectedCIs)
                      // );
                      // !selectedCIs.hasOwnProperty(row.data.metricType) &&
                      //   (selectedCIs[row.data.metricType] = []);
                      // selectedCIs[row.data.metricType].indexOf(ciName) > -1
                      //   ? selectedCIs[row.data.metricType].splice(
                      //       selectedCIs[row.data.metricType].indexOf(ciName),
                      //       1
                      //     )
                      //   : selectedCIs[row.data.metricType].push(ciName);
                      // row.updateData({
                      //   ...row.data,
                      //   ciNameArr: [...row.data.ciNameArr],
                      // });
                      // this.setMetricMetaData(
                      //   this.state.selectedMetrics,
                      //   selectedCIs
                      // );
                    }}
                  />
                  <span>{ciName}</span>
                </div>
              ))}
          </div>
        ) : (
          ""
        )}
      </div>
    );
  };

  selectCiNameMetric(row, ciName) {
    row.data.ciNameArr.indexOf(ciName) > -1
      ? row.data.ciNameArr.splice(row.data.ciNameArr.indexOf(ciName), 1)
      : row.data.ciNameArr.push(ciName);
    let selectedCIs = JSON.parse(JSON.stringify(this.state.selectedCIs));
    !selectedCIs.hasOwnProperty(row.data.metricType) &&
      (selectedCIs[row.data.metricType] = []);
    selectedCIs[row.data.metricType].indexOf(ciName) > -1
      ? selectedCIs[row.data.metricType].splice(
          selectedCIs[row.data.metricType].indexOf(ciName),
          1
        )
      : selectedCIs[row.data.metricType].push(ciName);
    row.updateData({
      ...row.data,
      ciNameArr: [...row.data.ciNameArr],
    });
    this.setMetricMetaData(this.state.selectedMetrics, selectedCIs);
  }

  renderUnitCell = (row) => {
    return <span className="unit-cell">{row.data.unit}</span>;
  };

  renderCICell = (row) => {
    return (
      <span className="ci-cell" style={{ textAlign: "center" }}>
        {/* <IconButton
          aria-controls="menuActionMetric"
          aria-haspopup="true"
          onClick={(e) => this.handleMenuClick(e, row)}
        >
          <MoreVertIcon style={{ color: "black" }} />
        </IconButton> */}
        {row.metadata.hasChildren ? row.data.childrenLength : row.data.count}
      </span>
    );
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    saveMetric: (data, isEdit) => dispatch(saveMetric(data, isEdit)),
    getMetricForDevices: (...data) => dispatch(getMetricDevices(...data)),
    fetchAllMetric: (body) => dispatch(fetchAllMetric(body)),
    fetchMetrics: (deviceIds, isInterface) =>
      dispatch(getMetricsData(deviceIds, isInterface)),
  };
};

const mapStateToProps = (state) => {
  return {
    deviceMetrics: state.deviceMetrics,
    timerange: state.timerange.hours,
    timerangeEndDate: state.timerange.endDate,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MetricList);
