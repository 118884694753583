import React from 'react'
import theme from './../../../theme'
import Resource from './Resource'
//Error help message
const defaultErrorText = Resource.get('Deployment failed. This may be due to an inability to communicate with the target device.')
//Robot deploy error message
let deployErrorText = Resource.get('To troubleshoot, verify your network connection or firewall settings on the host.');
const DeployErrorMessage = (props) => {
  let helperText
  switch (props.helperText) {
    case 'java.lang.NullPointerException':
      helperText = defaultErrorText
      break
    default:
      let index=props.helperText.indexOf("Description");
      if(index!==-1){
        let index1=index+12;
        let substr1= props.helperText.substr(index1);
        let index2= substr1.indexOf("\n");
        let substr2= substr1.substr(0,index2-1);
        helperText = substr2;
        if(helperText.indexOf("RPC server")!=-1){
        helperText=helperText.concat(deployErrorText);
        }
      }
      else	
      helperText = props.helperText
      break
  }
  return (
    <p className='deploy-error__message'
      style={{
        color: theme.palette.errorColor,
      }}>
      {helperText}
    </p>
  )
}

DeployErrorMessage.defaultProps = {
  helperText: defaultErrorText
}
export default DeployErrorMessage