import {
  SHOW_INVENTORY_FILTER,
} from './actionTypes'

export const showInventoryFilter = (showFilter) => {
  return {
    type: SHOW_INVENTORY_FILTER,
    showFilter: {
      showFilter: showFilter
    },
  }
}