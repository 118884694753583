// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Highest Alarm Severity Level and Total Alarm Count
  content["Highest Alarm Severity Level and Total Alarm Count"] =
  "최대 경보 심각도 수준 및 전체 경보 수";

  //Max Alarm Severity
  content["Max Alarm Severity"] =
  "최대 경보 심각도";

  //Name
  content["Name"] =
  "이름";

  //Elements
  content["Elements"] =
  "요소";

  //Device Type
  content["Device Type"] =
  "장치 유형";

  //Operating system
  content["Operating system"] =
  "운영 체제";

  //IP address
  content["IP address"] =
  "IP 주소";

  //Monitored by
  content["Monitored by"] =
  "모니터링:";

  //Has Robot
  content["Has Robot"] =
  "로봇 있음";

  //Discovered
  content["Discovered"] =
  "검색됨";

  // END OF LOCALIZATION

export default content;
