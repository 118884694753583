import React from 'react';

const PlayCircle = ({className}) => {
    return (
    <svg width="16px" height="16px" role='figure' className={className} viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <title>play_circle</title>
        <desc>Created with Sketch.</desc>
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fillRule="evenodd">
            <g id="1-Run-And-Custom-Report" transform="translate(-413.000000, -252.000000)">
                <g id="Group" transform="translate(327.000000, 63.000000)">
                    <g id="Group-6" transform="translate(85.000000, 188.000000)">
                        <g id="Group-5">
                            <g id="outline-play_circle_outline-24px">
                                <polygon id="Shape" points="0 0 18 0 18 18 0 18"></polygon>
                                <path d="M7.5,12.375 L12,9 L7.5,5.625 L7.5,12.375 Z M9,1.5 C4.86,1.5 1.5,4.86 1.5,9 C1.5,13.14 4.86,16.5 9,16.5 C13.14,16.5 16.5,13.14 16.5,9 C16.5,4.86 13.14,1.5 9,1.5 Z M9,15 C5.6925,15 3,12.3075 3,9 C3,5.6925 5.6925,3 9,3 C12.3075,3 15,5.6925 15,9 C15,12.3075 12.3075,15 9,15 Z" id="Shape" fill="#333333" fillRule="nonzero"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
    );
};

export default PlayCircle;