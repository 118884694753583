export const FETCH_USER_DASHBOARDS = 'FETCH_USER_DASHBOARDS'
export const FETCH_USER_DASHBOARDS_SUCCESS = 'FETCH_USER_DASHBOARDS_SUCCESS'
export const FETCH_USER_DASHBOARDS_FAILURE = 'FETCH_USER_DASHBOARDS_FAILURE'


export const CREATE_USER_DASHBOARDS = 'CREATE_USER_DASHBOARDS'
export const CREATE_USER_DASHBOARDS_SUCCESS = 'CREATE_USER_DASHBOARDS_SUCCESS'
export const CREATE_USER_DASHBOARDS_FAILURE = 'CREATE_USER_DASHBOARDS_FAILURE'


export const UPDATE_USER_DASHBOARDS = 'UPDATE_USER_DASHBOARDS'
export const UPDATE_USER_DASHBOARDS_SUCCESS = 'UPDATE_USER_DASHBOARDS_SUCCESS'
export const UPDATE_USER_DASHBOARDS_FAILURE = 'UPDATE_USER_DASHBOARDS_FAILURE'



export const DELETE_USER_DASHBOARDS = 'DELETE_USER_DASHBOARDS'
export const DELETE_USER_DASHBOARDS_SUCCESS = 'DELETE_USER_DASHBOARDS_SUCCESS'
export const DELETE_USER_DASHBOARDS_FAILURE = 'DELETE_USER_DASHBOARDS_FAILURE'

export const FETCH_USER_DASHBOARD = 'FETCH_USER_DASHBOARD'
export const FETCH_USER_DASHBOARD_SUCCESS = 'FETCH_USER_DASHBOARD_SUCCESS'
export const FETCH_USER_DASHBOARD_FAILURE = 'FETCH_USER_DASHBOARD_FAILURE'

export const RESET_USER_DASHBOARD_STORE ='RESET_USER_DASHBOARD_STORE'

