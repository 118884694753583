import axios from 'axios';
import config from './../../config';

class unixCredentials { 
   getWMI() {
    if(process.env.NODE_ENV === "development" )
    return axios.get('http://10.252.25.208:8080/castlemock/mock/rest/project/d0QO1h/application/YedIdg/wmi');
	return axios.get([config.basename, 'api/v1/credentials/wmi'].join('/'));
  }
   getUNIX(){
    if(process.env.NODE_ENV == "development" )
    return axios.get('http://10.252.25.208:8080/castlemock/mock/rest/project/d0QO1h/application/YedIdg/shell');
    return axios.get([config.basename, 'api/v1/credentials/shell'].join('/')); 
   }
  saveWMI(credentialObj) {
    return axios.post([config.basename, 'api/v1/credentials/wmi'].join('/'), credentialObj);
  }
  editWMI(credentialObj) {
    return axios.put([config.basename, 'api/v1/credentials/wmi'].join('/'), credentialObj);
  }
  saveUNIX(credentialObj) {
	    return axios.post([config.basename, 'api/v1/credentials/shell'].join('/'), credentialObj);
 }
  editUNIX(credentialObj) {
    return axios.post([config.basename, 'api/v1/credentials/shell'].join('/'), credentialObj);
  }
}
export default new unixCredentials();