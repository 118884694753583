/**
 * This is a subset of the Probe ID to Probe name mappings found in the
 * CABI localization file here:
 *
 * https://github.dev.fco/UIM/cabi-core-reports/blob/develop/i18n/src/messages.properties
 *
 * Only monitoring probes need to be listed here. An attempt was made to remove infrastructure
 * probes from this list but some may have been missed. Those can be removed as they are identified.
 *
 * */
const monitorProbes = {
  ad_response: 'Active Directory Response',
  ad_server: 'Active Directory Server',
  ada_inventory: 'ADA Inventory',
  adevl: 'Active Directory Event Log',
  apache: 'Apache',
  apmgtw: 'Application Performance Management Gateway',
  applogic_mon: 'AppLogic Application Monitoring',
  applogic_ws: 'AppLogic Monitoring Using WS_API',
  aws: 'Amazon Web Services',
  azure: 'Microsoft Azure',
  capman_da: 'Capacity Management Data Adapter',
  cassandra_monitor: 'Cassandra Monitoring',
  cdm: 'CPU, Disk and Memory',
  cisco_monitor: 'Cisco SNMP Device Monitoring',
  cisco_nxos: 'Cisco NXOS',
  cisco_qos: 'Cisco QOS',
  cisco_ucm: 'Cisco Unified Communications Manager Monitoring',
  cisco_ucs: 'Cisco UCS Monitoring',
  cisco_unity: 'Unified messaging solution for the Cisco Architecture',
  clariion: 'Clariion Monitoring',
  cloudstack: 'Cloudstack',
  cluster: 'Clustered Environment Support',
  cpa: 'Capacity Predictive Analytics',
  cmdbgtw: 'SLM Database Gateway',
  db2: 'DB2',
  dhcp_response: 'DHCP Server Response Monitoring',
  dirscan: 'File and Directory Scan',
  diskstat: 'iSeries Disk Monitoring',
  distsrv: 'Monitor Distribution Server',
  dns_response: 'DNS Response',
  docker_monitor: 'Docker',
  dom_traps: 'An extension to Snmp Trap Demon for Dell OpenManage',
  e2e_appmon: 'E2E Application Response Monitoring',
  e2e_appmon_dev: 'E2E Application Response Monitoring Development Edition',
  easerver: 'EAServer',
  ecometer_3rdpartylibs: 'CA ecometer_monitor Third-Party Libraries',
  ecometer_admin: 'CA ecoMeter Administrator for UIM',
  ecometer_effiencyreport: 'CA ecoMeter Efficiency Report',
  ecometer_monitor: 'CA ecoMeter Monitoring',
  elb: 'Amazon ELB',
  email_response: 'Email Response',
  emailgtw: 'Email Gateway',
  ems: 'Event Management Service',
  ews_response: 'Microsoft Exchange Server Response',
  exchange_monitor: 'Microsoft Exchange Server',
  fault_correlation_engine: 'Fault Correlattion Engine',
  fetchmsg: 'Retrieve QSYSOPR Messages on iSeries',
  file_adapter: 'File Adapter',
  flow: 'Flow Analysis',
  fsmounts: 'File Systems Mounts Monitoring',
  google_app_engine: 'Google App Engine Monitoring',
  google_apps: 'Google Apps Monitoring',
  HA: 'High Availability',
  hadoop_monitor: 'Hadoop Monitoring',
  health_index: 'Health Index',
  healthcheck: 'CA UIM healthcheck',
  history: 'iSeries QHST Data Monitoring',
  hitachi: 'Hitachi Storage Monitoring',
  hp_3par: 'HP 3PAR Storage Monitoring',
  hpsmgtw: 'HP Service Manager gateway',
  html_alarm_console: 'HTML Alarm Console Portlet',
  httpgtw: 'HTTP Gateway',
  hyperv: 'Microsoft Hyper-V',
  ibm_cloud: 'IBM Cloud',
  ibm_ds_next: 'IBM Disk Storage System 8000 Series Monitoring',
  ibm_svc: 'IBM SVC Monitoring',
  ibmvm: 'IBM Virtualization Monitoring',
  ibmvm_rest: 'IBM Virtualization REST Monitoring',
  ica_response: 'Citrix Client Response',
  ica_server: 'Terminal Server',
  icmp: 'Internet Control Message Protocol',
  iis: 'Microsoft IIS',
  informix: 'Informix Database Monitoring',
  interface_traffic: 'Interface Traffic Monitoring',
  iostat: 'Disk Activity Monitoring',
  iSeries_robot_update: 'Nimsoft iSeries Robot Update',
  iSeriesRobot: 'Nimsoft iSeries Robot',
  jboss: 'JBoss Monitoring',
  jdbc_response: 'Java Database Connectivity SQL Queries Response Monitoring',
  jdbcgtw: 'Java Database Connectivity Gateway',
  jmx: 'JMX Data Extractor',
  jobqs: 'iSeries Job Queues Monitoring',
  jobs: 'iSeries Job Monitoring',
  jobsched: 'iSeries Job Schedule Monitoring',
  journal: 'iSeries Journal Message Monitoring',
  jvm_monitor: 'Java Virtual Machine Monitoring',
  ldap_response: 'LDAP Response',
  log_forwarder: 'AXA Log Forwarder',
  log_monitoring_service: 'AXA Log Monitoring Service',
  logmon: 'Log Monitoring',
  lync_monitor: 'Microsoft Lync Monitoring',
  meraki: 'Cisco Meraki Monitoring',
  mongodb_monitor: 'MongoDB Monitoring',
  mysql: 'MySQL',
  net_connect: 'Network Connectivity (ICMP/TCP)',
  net_traffic: 'Network traffic analyzer',
  netapp: 'NetApp Storage Monitoring',
  netapp_ontap: 'NetApp Data ONTAP Monitoring',
  nexec: 'Command Execution',
  nfa_inventory: 'NFA Inventory',
  nimldr: 'Utility to install Nimsoft infrastructure on UNIX platforms',
  nis_server: 'NIS Server',
  nisapi_wasp: 'NIS Restful API (Wasp Edition)',
  notes_response: 'Lotus Notes Client Response Monitoring',
  notes_server: 'Lotus Notes Server Monitoring',
  nq_services: 'NQ Services',
  nsa: 'Script Agent',
  nsdgtw: 'ServiceDesk Gateway Monitoring',
  ntevl: 'WindowsNT Event Log',
  ntp_response: 'Network Time Protocol Response Monitoring',
  ntperf: 'WindowsNT Performance',
  ntservices: 'WindowsNT Services',
  nutanix_monitor: 'Nutanix',
  ocs_monitor: 'Monitors of Office Communication Server Monitoring',
  office365: 'Office 365',
  openstack: 'OpenStack',
  oracle: 'Oracle',
  oracle_logmon: 'Oracle Database Log Monitoring',
  outqs: 'iSeries Output Queue Monitoring',
  ovnnm: 'OpenView NNM integration',
  pagerdutygtw: 'PagerDuty Gateway',
  perfmon: 'Performance Engine',
  power: 'Power Monitor',
  pp_defaults: ' Provisioning Default Templates',
  ppm: 'Probe Provisioning Manager',
  prediction_engine: 'Prediction Engine',
  printers: 'Printer Monitoring',
  probe_configuration_archive: 'Configuration Archive',
  processes: 'Processes',
  pu: ' Utility Tool for Controller',
  puppetgtw: 'Puppet Gateway',
  purestorage: 'PureStorage All-Flash Array Monitoring',
  pvs: 'PVS',
  qos_processor: 'QoS Processor',
  queue_stats: 'CA UIM Hub Queue Statistics',
  rackspace: 'Rackspace Cloud Server and Cloud File Monitoring',
  relationship_services: 'Relationship Services',
  remedy_response: 'Monitor response times to Remedy ARS',
  remedygtw: 'Remedy Gateway',
  restmon : 'RESTMon',
  restsdgtw: 'RESTful Service Desk Gateway',
  rsp: 'Remote System Monitoring',
  saa_monitor: 'Cisco IPSLA/SAA Monitoring',
  salesforce: 'Salesforce monitoring',
  sap_basis: 'SAP',
  sdgtw: 'Service Desk Gateway',
  service_host: 'Service Host',
  sharepoint: 'Microsoft SharePoint Server',
  sla_engine: 'Calculates Service Level Agreements',
  smsgtw: 'Short Message Service Gateway',
  sngtw: 'ServiceNow Gateway',
  snmpcollector: 'SNMP Data Monitoring',
  snmpget: 'SNMP GET',
  snmpgtw: 'SNMP Gateway',
  snmptd: 'SNMP Trap Daemon Monitoring',
  snmptoolkit: 'Simple Network Management Protocol Toolkit',
  spectrumgtw: 'Spectrum Gateway',
  sql_response: 'SQL Response',
  sqlserver: 'MS SQL Server',
  SupernaNET_Converge_Render: 'Superna Converged Infrastructure Rendering Platform',
  sybase: 'Sybase Monitor',
  sybase_rs: 'Sybase Replication Server Monitoring',
  sysloggtw: 'System Log Gateway',
  sysstat: 'iSeries System Statistics Monitoring',
  tcp_proxy: 'TCP/IP Proxy Service',
  telemetry: 'Collects info from various config files and queries',
  threshold_migrator: 'Threshold Migrator',
  timedQ: 'Timed posting of messages in a queue to another Hub',
  TNGgtw: 'Framework Integration for Unicenter TNG',
  tngobjects: 'Extract object states from CORE',
  TNGwvf: 'TNG WorldView Client Support Files',
  tomcat: 'Apache Tomcat',
  topology_agent: 'Topology Discovery Agent',
  topology_fault_correlation: 'Topology Fault Correlation Application',
  udm_manager: 'UDM Manager',
  unix: 'Linux',
  url_response: 'URL Response',
  usage_metering: 'Usage Metering',
  vcloud: 'vCloud Director Monitoring',
  vmax: 'EMC VMAX Storage Systems Monitoring',
  vmware: 'VMware',
  vplex: 'EMC VPLEX Monitoring',
  webalarms: 'HTML Alarm Console & Web Alarms',
  webgtw: 'Web Gateway',
  weblogic: 'Oracle WebLogic',
  webservicemon: 'Webservice URL Monitoring',
  webservices_rest: 'Web Service REST SDK',
  websphere: 'IBM WebSphere',
  websphere_mq: 'IBM WebSphere MQ',
  windows: 'Windows',
  wins_response: 'Windows Internet Name Service Response Monitoring',
  xenapp: 'Citrix Xenapp Monitoring',
  xendesktop: 'Citrix Xendesktop',
  xenserver: 'Citrix Xenserver Monitoring',
  xmlparser: 'XML Parser',
  zdataservice: 'Data Service',
  znetwork: 'Network Monitoring',
  zones: 'Solaris Zones Monitoring',
  zops: 'Zops Monitoring',
  zstorage: 'ZStorage Monitoring',
  zvm: 'ZVM Monitoring with Templates',
  tandberg_cseries:'Cisco C Series',
  tandberg_ex90:'Cisco Ex90',
  tandberg_mxp:'Cisco Mxp',
  dellstorage:'Dell Storage',
  elasticsearch:'Elasticsearch',
  jarvis:'Jarvis',
  logstash:'Logstash',
  marklogic:'Marklogic',
  paloalto:'Paloalto',
  pexip:'Pexip',
  ticketmaster:'Ticketmaster'
}

export default monitorProbes