import Resource from "./Resource";

//Device Details
export const DEVICE_DETAILS = Resource.get("Device Details")
// Alarms
export const ALARMS = Resource.get("Alarms")
// Dashboards
export const DASHBOARDS = Resource.get("Dashboards")
// Maintenance
export const MAINTENANCE = Resource.get("Maintenance")
// Metrics
export const METRICS = Resource.get("Metrics")
// Monitoring Config
export const MONITORING_CONFIG = Resource.get("Monitoring Config")
// Interfaces
export const INTERFACES = Resource.get("Interfaces")