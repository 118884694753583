import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import connect from "../../utils/connect";
import Table from "./Table";
import "../cards/cards.less";
import "./table.less";
import { MoreIcon } from "../ui-components/uim-components";
import DashboardContainerRedux from "./../dashboardContainer/DashboardContainerRedux";
import CustomColumnsDialog from "./../inventory/CustomColumnsDialog";
import computerSystemsApi from "./../../api/computer-systems/computerSystems";
import { Menu, IconButton, MenuItem } from "@mineral/core";
import ExportGroupDetailsDialog from "./ExportGroupDetailsDialog";
import entityTypes from "./../../api/entityTypes";
import { setCardPage } from "../cards/actions";
import { setQuickFilterEntities } from "../entities/actions";
import Filter from "../groups/secondaryNav/filter/Filter";
import AccessibleTooltip from "../common/AccessibleTooltip";

const WIDTH_COLLAPSED = 56;
const TRANSITION_DELAY_LONG = "0s";

class TableLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gridData: [],
      open: true,
      close: false,
      transitionDelay: TRANSITION_DELAY_LONG,
      width: WIDTH_COLLAPSED,
      expanded: false,
      currentTab: "Details",
      treeRender: false,
      data: this.props.groupData,
      dataGroups: this.props.data,
      displayName: "",
      enableDeviceView: this.props.showTable,
      csData: this.props.cabiData,
      csId: this.props.cabiData ? this.props.cabiData.id : 0,
      openExportTypeDialog: false,
      openEditColumnDailog: false,
      savedColumns: [],
      menuAnchorEl: null,
    };
  }

  componentWillMount = () => {
    computerSystemsApi.getSavedColumns().then((response) => {
      let columns = response.data.columns;
      this.setState({
        savedColumns: columns && columns.length > 0 ? columns : [],
      });
    });
  };

  componentDidMount() {}
  componentWillReceiveProps = (nextProps) => {
    this.setState({
      csData: nextProps.cabiData,
      csId: nextProps.cabiData ? nextProps.cabiData.id : 0,
      enableDeviceView: nextProps.showTable,
    });
    if (nextProps.reloadDetailView) {
      this.forceUpdate();
    }
  };
  handleOpenExportDialog = () => {
    this.setState({ openExportTypeDialog: true, menuAnchorEl: null });
  };
  handleExportClose = () => {
    this.setState({ openExportTypeDialog: false });
  };
  handleOpenEditColumnDialog = () => {
    this.setState({ openEditColumnDailog: true, menuAnchorEl: null });
  };
  handleSaveSelectedColumns(selectedColumns) {
    this.setState({ openEditColumnDailog: false });
    let toSaveColList = [];
    selectedColumns.forEach((col) => {
      toSaveColList.push(col.headerName);
    });
    let params = {
      columns: toSaveColList,
      colFilters: JSON.stringify({}),
    };
    computerSystemsApi.saveSelectedColumns(params).then((response) => {
      if (response.data) {
        computerSystemsApi.getSavedColumns().then((response) => {
          let columns = response.data.columns;
          this.setState({
            savedColumns: columns && columns.length > 0 ? columns : [],
          });
        });
      }
    });
  }
  handleMenuClick = (event) => {
    this.setState({ menuAnchorEl: event.currentTarget });
  };
  handleActionMenuClose = (event) => {
    this.setState({ menuAnchorEl: null });
  };
  getDataCount = (data) =>{
    return data?.props?.groups?.data?.data?.length || 0;
  }

  render() {
    const styles = {
      default_tab: {
        backgroundColor: "#FFFFFF",
        color: "#58676D",
        fontFamily: "roboto_condensed_bold,Arial,sans-serif",
        buttonStyle: { background: "blue", height: "10px" },
      },
      active_tab: {
        backgroundColor: "#FFFFFF",
        color: "#00AEEF",
        fontFamily: "roboto_condensed_bold,Arial,sans-serif",
        buttonStyle: { background: "blue", height: "10px" },
      },
      inkBar: {
        background: "#00AEEF",
        height: "5px",
        width: "80px",
        marginLeft: "59px",
      },
    };

    let view = (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "calc(100% - 40px )", //"calc(100vh - 150px)"
          overflow: "hidden",
        }}
      >
        <div
          className={
            this.state.open
              ? "iconMenu_parent_div_before_collapse"
              : "iconMenu_parent_div_after_collapse"
          }
        >
          <div style={{
            height:'100%',
            display: 'flex',
            alignItems: "center",
            justifyContent: "space-between",
            padding: "0 12px",
          }}>
            <div
              style={{
                color: "#58676d",
                fontFamily: "ca_sans_web_bold",
                fontSize: "1.5rem",
                padding: "10px",
                marginLeft: this.props.isTreeOpen ? "0px" : "36px",
                display: "inline-block",
              }}
            >
              {"Member Group"}
            </div>
            <span
              style={{ marginTop:'3px' }}
            >
              {/*<Filter
                onChange={this.props.handleChange}
                value={this.props.searchTerm}
                filterHint={this.props.filterHint}
                
              />*/}
             <AccessibleTooltip title="More Actions">
              <IconButton aria-label={"More Actions"}
                aria-controls="menuActionTable"
                aria-haspopup="true"
                onClick={this.handleMenuClick}
              >
                <MoreIcon role="figure" />
              </IconButton>
              </AccessibleTooltip>
              <Menu
                id="menuActionTable"
                anchorEl={this.state.menuAnchorEl}
                keepMounted
                onClose={this.handleActionMenuClose}
                open={this.state.menuAnchorEl != null}
              >
                <MenuItem
                  children={"Export"}
                  onClick={this.handleOpenExportDialog}
                />
                {(this.props.entityType === entityTypes.AUTOMATIC ||
                  this.props.entityType === entityTypes.STATIC ||
                  this.props.entityType === entityTypes.DYNAMIC) && (
                  <MenuItem
                    children={"Edit Columns"}
                    onClick={this.handleOpenEditColumnDialog}
                  />
                )}
              </Menu>
            </span>
          </div>
        </div>

        {this.state.open || this.state.treeRender ? (
          <div
            style={{
              border: "1px solid white",
              float: "left",
              backgroundColor: "white",
              overflow: "hidden",
              width: "100%",
              flex: "1 1 auto",
            }}
          >
            <div
              className="Members"
              style={{
                position: "relative",
                top: "1%",
                left: "2%",
                fontSize: "14px",
                fontFamily: "Robot Regular",
                margin: "5px",
              }}
            ></div>
            {this.state.enableDeviceView === true ? (
              <Table
                {...this.props}
                onRef={(ref) => (this.child = ref)}
                savedColumns={this.state.savedColumns}
                setGroupTreeFilteredData={this.props.setGroupTreeFilteredData}
              />
            ) : (
              <div style={{ width: "100%", marginLeft: "4px", height: "95%" }}>
                {/* <CabiRedux
                    key={this.state.csId}
                    className="cabi__main--treeview"
                    csData={this.state.csData}
                    csId={this.state.csId}
                    referer={"treeView"}
                  /> */}
                <DashboardContainerRedux
                  key={this.state.csId}
                  className="cabi__main--treeview"
                  csData={this.state.csData}
                  csId={this.state.csId}
                  referer={"treeView"}
                />
              </div>
            )}
          </div>
        ) : (
          <div
            style={{
              width: "99%",
              border: "1px solid #8080803d",
              backgroundColor: "white",
              height: "64vh",
              float: "left",
              backgroundColor: "White",
              overflow: "scroll",
            }}
          >
            <div
              className="Members"
              style={{
                position: "relative",
                top: "1%",
                left: "2%",
                fontSize: "14px",
                fontFamily: "Robot Regular",
                margin: "5px",
              }}
            ></div>
            <div>
              <span
                style={{ position: "relative", left: "2px", right: "17px" }}
              >
                {this.state.enableDeviceView === false ? (
                  <Table {...this.props} onRef={(ref) => (this.child = ref)} />
                ) : (
                  <div
                    style={{ width: "100%", marginLeft: "4px", height: "95%" }}
                  >
                    {/* <CabiRedux
                      key={this.state.csId}
                      className="cabi__main--treeview"
                      csData={this.state.csData}
                      csId={this.state.csId}
                      referer={"treeView"}
                    /> */}
                    <DashboardContainerRedux
                      key={this.state.csId}
                      className="cabi__main--treeview"
                      csData={this.state.csData}
                      csId={this.state.csId}
                      referer={"treeView"}
                    />
                  </div>
                )}
              </span>
            </div>
          </div>
        )}
        <ExportGroupDetailsDialog
          open={this.state.openExportTypeDialog}
          requestClose={this.handleExportClose}
          savedColumns={this.state.savedColumns}
          {...this.props}
        />
        <CustomColumnsDialog
          open={this.state.openEditColumnDailog}
          presistedColumns={this.state.savedColumns}
          saveSelectedCols={this.handleSaveSelectedColumns.bind(this)}
          handleClose={() => {
            this.setState({ openEditColumnDailog: false });
          }}
        />
      </div>
    );
    return view;
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    // setBreadCrumbs: (items) => dispatch(setBreadCrumbs(items)),
    handleChange: (searchTerm) => {
      dispatch(setQuickFilterEntities(searchTerm));
      dispatch(setCardPage(0));
    },
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    sid: state.sid,
    filterHint: ownProps.filterHint,
    searchTerm: state.inventoryEntityFilter.quickFilter,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TableLayout));
