// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Remote monitoring (also known as agentless monitoring) requires a monitoring host. The monitoring host is responsible for collecting data from the remote device that you are monitoring.
  content["Remote monitoring (also known as agentless monitoring) requires a monitoring host. The monitoring host is responsible for collecting data from the remote device that you are monitoring."] =
  "远程监控 (也称为无代理监控) 需要监控主机。监控主机负责从您监控的远程设备收集数据。";

  //Monitoring host server
  content["Monitoring host server"] =
  "监控主机服务器";

  //Deploy a monitoring
  content["Deploy a monitoring"] =
  "部署监控";

  //Recommended minimum system requirements
  content["Recommended minimum system requirements"] =
  "建议的最低系统要求";

  //Docker monitoring requires an agent (aka Robot) to be installed on the Docker Engine server.
  content["Docker monitoring requires an agent (aka Robot) to be installed on the Docker Engine server."] =
  "Docker 监控需要在 Docker 引擎服务器上安装代理 (也称作 Robot)。";

  //IP address
  content["IP address"] =
  "IP 地址";

  //Username
  content["Username"] =
  "用户名";

  //Password
  content["Password"] =
  "密码";

  //Sudo Password (Linux only)
  content["Sudo Password (Linux only)"] =
  "Sudo 密码 (仅 Linux)";

  //4 GB of available memory
  content["4 GB of available memory"] =
  "4 GB 可用内存";

  //2 or more CPUs
  content["2 or more CPUs"] =
  "2 个或更多 CPU";

  //10 GB of available disk space
  content["10 GB of available disk space"] =
  "10 GB 可用磁盘空间";

  //Ports 48000 to 48025 open
  content["Ports 48000 to 48025 open"] =
  "端口 48000 到 48025 处于打开状态";

  // END OF LOCALIZATION

export default content;
