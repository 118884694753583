// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Relay Hub Installers
  content["Relay Hub Installers"] =
  "リレー ハブ インストーラ";

  //A relay hub sends metrics and alarms data to your instance of Infrastructure Management.
  content["A relay hub sends metrics and alarms data to your instance of Infrastructure Management."] =
  "リレー ハブは、メトリクスおよびアラーム データを Infrastructure Management のインスタンスに送信します。";

  //Pick the correct installer for your environment
  content["Pick the correct installer for your environment"] =
  "お使いの環境用の正しいインストーラを選択してください";

  //If you will be monitoring Windows devices or a mix of Windows and Linux devices, use the Windows installer. The Linux relay hub can only monitor Linux servers.
  content["If you will be monitoring Windows devices or a mix of Windows and Linux devices, use the Windows installer. The Linux relay hub can only monitor Linux servers."] =
  "Windows デバイスまたは Windows および Linux デバイスの混合をモニタする場合は、Windows インストーラを使用します。Linux リレー ハブは、Linux サーバのみモニタできます。";

  //If monitoring cloud-based infrastructure, a relay hub is needed for each isolated virtual network (such as AWS Virtual Private Cloud or Azure Virtual Network).
  content["If monitoring cloud-based infrastructure, a relay hub is needed for each isolated virtual network (such as AWS Virtual Private Cloud or Azure Virtual Network)."] =
  "クラウド ベースのインフラストラクチャをモニタする場合、各隔離仮想ネットワーク (AWS Virtual Private Cloud または Azure Virtual Network など) に対して、リレー ハブが必要です。";

  //Ensure the relay hub can phone home
  content["Ensure the relay hub can phone home"] =
  "リレー ハブで通信が可能なことを確認します";

  //Ports 48000 to 48025 must be open
  content["Ports 48000 to 48025 must be open"] =
  "ポート 48000 から 48025 までがオープンである必要があります";

  //for the relay hub to communicate with the Infrastructure Management application.
  content["for the relay hub to communicate with the Infrastructure Management application."] =
  "(リレー ハブが Infrastructure Management アプリケーションと通信するために)。";

  //The system where you install the relay hub must meet the following minimum requirements:
  content["The system where you install the relay hub must meet the following minimum requirements:"] =
  "リレー ハブをインストールするシステムは、次の最小要件を満たす必要があります:";

  //Requirements
  content["Requirements"] =
  "要件";

  //Infrastructure Management supports the same Windows and Linux x64 operating systems that are supported for the on-premises CA UIM product.
  content["Infrastructure Management supports the same Windows and Linux x64 operating systems that are supported for the on-premises CA UIM product. "] =
  "Infrastructure Management は、オンプレミス CA UIM 製品でサポートされているのと同じ Windows および Linux x64 オペレーティング システムをサポートしています。 ";

  //For more information about which operating systems are supported, refer to the
  content["For more information about which operating systems are supported, refer to the"] =
  "サポートされているオペレーティング システムの詳細については、次を参照してください";

  //Compatibility Support Matrix
  content["Compatibility Support Matrix"] =
  "互換性サポート マトリクス";

  //Issues?
  content["Issues?"] =
  "問題が発生した場合";

  //If the relay hub does not appear within Infrastructure Management under the appropriate Operating System group after 10 minutes,  there is likely a communication issue.
  content["If the relay hub does not appear within Infrastructure Management under the appropriate Operating System group after 10 minutes, there is likely a communication issue."] =
  "10 分後に、リレー ハブが Infrastructure Management 内の、適切な [オペレーティング システム] グループの下に表示されない場合、通信の問題がある可能性があります。";

  //Make sure the relay hub server has an active Internet connection.
  content["Make sure the relay hub server has an active Internet connection."] =
  "リレー ハブ サーバにアクティブなインターネット接続があることを確認します。";

  //For more guidance on deploying a relay hub see
  content["For more guidance on deploying a relay hub see"] =
  "リレー ハブ展開の詳細については、次を参照してください";

  //windowslabel message
  content["WINDOWS"] =
  "Windows";

  //linuxlabel message
  content["LINUX"] =
  "Linux";

  //Install Relay Hub
  content["Install Relay Hub"] =
  "リレー ハブのインストール";

  //Unable to access installers
  content["Unable to access installers."] =
  "インストーラにアクセスできません。";

  //DONE
  content["DONE"] =
  "完了";

  //Information
  content["Information"] =
  "情報";

  //Home
  content["Home"] =
  "ホーム";

  // Download and install a relay hub
  content["Download and install a relay hub"] =
  "リレー ハブのダウンロードおよびインストール";

  //4 GB of available memory
  content["4 GB of available memory"] =
  "4 GB の利用可能なメモリ";

  //2 or more CPUs
  content["2 or more CPUs"] =
  "2 つ以上の CPU";

  //10 GB of available disk space
  content["10 GB of available disk space"] =
  "10 GB の利用可能なディスク容量";

  //Valid external IP address for the system
  content["Valid external IP address for the system"] =
  "システムの有効な外部 IP アドレス";

  //Ability to connect to the Internet
  content["Ability to connect to the Internet"] =
  "インターネット接続機能";

  //HELP
  content["HELP"] =
  "ヘルプ";

  //Help
  content["Help"] =
  "ヘルプ";

  // END OF LOCALIZATION

export default content;
