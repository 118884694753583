import {
  REQUEST_PROBES,
  FETCH_PROBES_SUCCESS,
  FETCH_PROBES_FAILURE, 
} from './actionTypes'
import isEqual from 'lodash/isEqual'


const wizardProbeList = (state = {
  isFetching: false,
  didInvalidate: false,
  items: [],
  count: 0
}, action) => {
  switch (action.type) {
    case REQUEST_PROBES:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false
      });
    case FETCH_PROBES_SUCCESS:
      const items = action.probes._items;
      const count = action.probes.total;
      const updatedState = Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        items: items,
        count: count
      });
      return isEqual(updatedState, state) ? state : updatedState
    case FETCH_PROBES_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        errorMessage: action.errorMessage,
        items: [],
        count: 0
      });
    default:
      return state
  }
};

export default wizardProbeList
