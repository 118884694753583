import React from 'react'
import './icons.less'

const Icon = (props) => {
  let classes = [
    'icon',
    props.className,
  ].join(' ')
  return (
    <svg className={classes} role={props.role || 'figure'} aria-label="[title]" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g>
        <title>{props.title}</title>
        <path fillRule="evenodd" d="M3,13 L11,13 L11,3 L3,3 L3,13 L3,13 Z M3,21 L11,21 L11,15 L3,15 L3,21 L3,21 Z M13,21 L21,21 L21,11 L13,11 L13,21 L13,21 Z M13,3 L13,9 L21,9 L21,3 L13,3 L13,3 Z"/>
      </g>
    </svg>
  )
}

export default Icon

