import React, { Component } from "react";
import {
  Checkbox,
  Dialog,
  DialogTitle,
  Button as FlatButton,
  LinearProgress,
  Button as RaisedButton,
  TextField,
  Switch as Toggle,
} from "@mineral/core";
import InlineMessage from "./../../../message/InlineMessage";
import * as messageTypes from "./../../../message/InlineMessageTypes";
import "./serviceNowConfig.less";
import connect from "./../../../../utils/connect";
import IntegrationLogo from "./../../../icons/IntegrationLogo";
import { withRouter } from "react-router-dom";
import {
  get,
  save,
  deleteServiceNowConfig,
} from "./../../../../api/integrations/servicenow/actions";
import * as IntegrationError from "./../IntegrationError";
import AdvancedCheckbox from "./AdvancedCheckbox";
import SeverityIcon, {
  LEVEL_CRITICAL,
  LEVEL_MAJOR,
  LEVEL_MINOR,
  LEVEL_WARNING,
  LEVEL_INFO,
} from "./../../../icons/SeverityIcon";
import { setBreadCrumbs } from "./../../../breadcrumbs/actions";
import {
  ErrorIcon as InfoIcon,
  CenterError,
} from "../../../ui-components/uim-components";
import {CircularProgress} from '@mineral/core';
import Resource from "./Resource";

const SERVICENOW_APIKEY_URL =
  "https://support.pagerduty.com/hc/en-us/articles/202829310-Generating-an-API-Key";
// const SERVICENOW_HELP_URL =
//   "http://docops.ca.com/rest/ca/product/latest/topic?hid=HID_Integrate_ServiceNow&space=DXIIM&language=&format=rendered";

const styles = {
  block: {
    maxWidth: 250,
    marginTop: 15,
  },
  toggle: {
    marginBottom: 16,
    fontSize: 16,
    lineHeight: 24,
  },
};

class ServiceNowConfig extends Component {
  state = {
    isDirty: false,
    showDeleteDialog: false,
    deleteDialogActionsDisabled: false,
    isDisabled: !this.props.config.enabledGroupAssignment,
    helperText: "",
  };

  componentWillMount() {
    this.props.setBreadCrumbs([
      {
        //Settings
        name: Resource.get("Settings"),
        link: "/settings",
      },
      {
        //ServiceNow
        name: Resource.get("ServiceNow "),
        link: "/settings/integrations/servicenow",
      },
    ]);
    this.props.fetchConfig();
  }

  stateChanged = () => {
    this.setState({ isDirty: true });
  };

  handleCancel = () => {
    this.props.history.push("/settings");
  };

  handleSave = () => {
    this.props.save({ ...this.props.config }, this.saveSuccess);
  };

  handleDelete = () => {
    this.setState({
      showDeleteDialog: true,
      deleteDialogActionsDisabled: false,
    });
  };

  handleUserNameChange = (event) => {
    this.stateChanged();
    this.props.config.userName = event.target.value;
  };

  handleGroupNameChange = (event) => {
    //Invalid format: ###-###-####
    const InvalidFormatMsg = Resource.get("Invalid format: ###-###-####");
    var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    if (!format.test(event.target.value)) {
      this.stateChanged();
      this.setState({ helperText: "" });
    } else {
      this.setState({ helperText: InvalidFormatMsg });
    }
    this.props.config.groupName = event.target.value;
  };
  handleUserPasswordChange = (event) => {
    this.stateChanged();
    this.props.config.userPassword = event.target.value;
  };

  handleUserURL = (event) => {
    this.stateChanged();
    this.props.config.userURL = event.target.value;
  };

  handleResolveServiceNowIncidentCheck = (event) => {
    this.stateChanged();
    this.props.config.autoResolveIncidents = event.target.checked;
  };
  handleAutoCloseTicket = (event) => {
    this.stateChanged();
    this.props.config.autoCloseTicket = event.target.checked;
  };
  handleAutoCloseAlarm = (event) => {
    this.stateChanged();
    this.props.config.autoCloseAlarm = event.target.checked;
  };
  saveSuccess = () => {
    this.props.history.push("/settings");
  };

  deleteSuccess = () => {
    this.setState({ showDeleteDialog: false });
    this.props.history.push("/settings");
  };

  deleteFailure = () => {
    this.setState({ showDeleteDialog: false });
  };

  saveButtonDisabled = () => {
    if (
      this.state.isDirty &&
      this.props.config.userName &&
      this.props.config.userPassword &&
      this.props.config.userURL &&
      this.state.helperText === ""
    ) {
      return false;
    }

    return true;
  };

  getSeverityCheckboxState = (level) => {
    if (this.props.config.severityOptions) {
      return this.props.config.severityOptions.indexOf(level) >= 0;
    }

    return false;
  };

  handleSeverityCheck = (isChecked, severity) => {
    var items = this.props.config.severityOptions;
    if (items.includes(NaN)) {
      items.splice(this.props.config.severityOptions.indexOf(NaN), 1);
    }
    var index;
    if (items) {
      if (isChecked) {
        index = items.indexOf(severity);
        if (index === -1) {
          items.push(severity);
          this.props.config.severityOptions = items;
          this.stateChanged();
        }
      } else {
        index = items.indexOf(severity);
        if (index > -1) {
          items.splice(index, 1);
          this.props.config.severityOptions = items;
          this.stateChanged();
        }
      }

      // Setting activebased on severity options selected
      this.props.config.active = items.length > 0;
    }
  };

  getGeneralErrorMessageComponent = () => {
    const generalError = IntegrationError.getErrorMessageForKey(
      IntegrationError.ERROR_GENERAL,
      this.props.responseError
    );
    if (generalError != null) {
      return <InlineMessage type={messageTypes.ERROR} message={generalError} />;
    }

    return null;
  };

  handleGroupEnable = (event) => {
    this.stateChanged();
    this.setState({ isDisabled: !event.target.checked });
    this.props.config.enabledGroupAssignment = !event.target.checked;
  };

  getDialogComponent = () => {
    const actions = [
      <FlatButton
        variant="text"
        disabled={this.state.deleteDialogActionsDisabled}
        //CANCEL
        children={Resource.get("CANCEL")}
        color="primary"
        onClick={this.handleDeleteDialogCancel}
      />,
      <FlatButton
        variant="text"
        disabled={this.state.deleteDialogActionsDisabled}
        //DELETE
        children={Resource.get("DELETE")}
        color="primary"
        onClick={this.handleDeleteDialogAccept}
      />,
    ];

    //This action removes the ServiceNow integration.
    const ActionMsg = Resource.get(
      "This action removes the ServiceNow integration."
    );
    //Alarms will no longer be synchronized between CA Infrastructure Management and ServiceNow.
    const AlarmMsg = Resource.get(
      "Alarms will no longer be synchronized between CA Infrastructure Management and ServiceNow."
    );

    return (
      <Dialog
        style={{ width: "330px" }}
        actions={actions}
        open={this.state.showDeleteDialog}
        modal={true}
        aria-labelledby="dialog-title"
      >
        <DialogTitle id="dialog-title">
          {
            //Delete integration?
            Resource.get("Delete integration?")
          }
        </DialogTitle>
        <span style={{ lineHeight: "24px" }}>
          <span>{ActionMsg}</span>
          <br />
          <br />
          <span>{AlarmMsg}</span>
        </span>
      </Dialog>
    );
  };

  handleDeleteDialogCancel = () => {
    this.setState({ showDeleteDialog: false });
  };

  handleDeleteDialogAccept = () => {
    this.setState({ deleteDialogActionsDisabled: true });
    this.props.deleteServiceNowConfig(
      { ...this.props.config },
      this.deleteSuccess,
      this.deleteFailure
    );
  };

  render = () => {
    //URL
    const URLMsg = Resource.get("URL");
    //User name
    const UserNameMsg = Resource.get("User name");
    //Password
    const PasswordMsg = Resource.get("Password");
    //DefaultGroupName
    const DefGroupNameMsg = Resource.get("Default group name");
    //Create new ServiceNow tickets when Infrastructure Management generates alarms with the following severities:
    const InstructionMsg = Resource.get(
      "Create new ServiceNow tickets when Infrastructure Management generates alarms with the following severities:"
    );
    //ServiceNow integration
    const IntegrationName = Resource.get("ServiceNow integration");
    //instruction msg
    const InstructionMsg1 = Resource.get(
      "This integration acts as a gateway between the Infrastructure Management and ServiceNow Incident Management system. The integration generates an incident ticket in the Service desk that is based on the Infrastructure Management alarm. Generating an incident helps the ServiceNow user to take corrective actions to resolve the issue. The integration periodically polls the service desk for the status of incidents and updates the corresponding alarms."
    );
    //Enable Group Assignment
    const EnableGroupAssignMsg = Resource.get("Enable Group Assignment");
    //Auto close alarm
    const AutocloseAlarm = Resource.get("Auto close alarm");
    //Auto close tickets
    const AutocloseTickets = Resource.get("Auto close tickets");
    //Severity levels
    const SeverityLevels = Resource.get("Severity levels");
    //Ticket on Severity Change
    const TicketonSevChange = Resource.get("Ticket on Severity Change");
    let fetchErrorMessage = IntegrationError.getErrorMessageForKey(
      IntegrationError.ERROR_FETCH,
      this.props.responseError
    );
    if (this.props.isFetching) {
      return (
        <div>
          <LinearProgress style={{ height: "8px" }} />
        </div>
      );
    } else if (fetchErrorMessage) {
      return (
        <div
          style={{ display: "flex", alignItems: "center", marginTop: "100px" }}
        >
          <CenterError
            message={fetchErrorMessage}
            //Communication Error
            title={Resource.get("Communication Error")}
          />
        </div>
      );
    }

    return (
      <div className="service-now-config__wrapper">
        {this.getGeneralErrorMessageComponent()}
        {this.getDialogComponent()}

        <div className="service-now-config__content">
          <div className="service-now-config__leftcontent">
            <div className="service-now-config__header">
              <IntegrationLogo height="26px" type="servicenow" />
            </div>

            <div className="service-now-config__body">
              <div className="service-now-config__item">
                <div className="service-now-config__input-label">{URLMsg}</div>
                <TextField
                  disabled={
                    this.props.state === "EXISTING" || this.props.isSaving
                  }
                  value={this.props.config.userURL || ""}
                  //errorStyle={{ marginTop: "6px" }}
                  helperText={IntegrationError.getErrorMessageForKey(
                    IntegrationError.ERROR_SERVICENOW_URL,
                    this.props.responseError
                  )}
                  onChange={this.handleUserURL}
                />
              </div>
              <div className="service-now-config__item">
                <div className="service-now-config__input-label">
                  {UserNameMsg}
                </div>
                <TextField
                  disabled={
                    this.props.state === "EXISTING" || this.props.isSaving
                  }
                  value={this.props.config.userName || ""}
                  //errorStyle={{ marginTop: "6px" }}
                  helperText={IntegrationError.getErrorMessageForKey(
                    IntegrationError.ERROR_SERVICENOW_USERNAME,
                    this.props.responseError
                  )}
                  onChange={this.handleUserNameChange}
                />
              </div>
              <div
                className="service-now-config__item"
                style={{ display: "none" }}
              >
                <div className="service-now-config__input-label">
                  {PasswordMsg}
                </div>
                <TextField
                  disabled={
                    this.props.state === "EXISTING" || this.props.isSaving
                  }
                  value={this.props.config.userPassword || ""}
                  //errorStyle={{ marginTop: "6px" }}
                  type="password"
                  helperText={IntegrationError.getErrorMessageForKey(
                    IntegrationError.ERROR_SERVICENOW_PASSWORD,
                    this.props.responseError
                  )}
                />
              </div>
              <div className="service-now-config__item">
                <div className="service-now-config__input-label">
                  {PasswordMsg}
                </div>
                <TextField
                  disabled={
                    this.props.state === "EXISTING" || this.props.isSaving
                  }
                  value={this.props.config.userPassword || ""}
                  //errorStyle={{ marginTop: "6px" }}
                  type="password"
                  helperText={IntegrationError.getErrorMessageForKey(
                    IntegrationError.ERROR_SERVICENOW_PASSWORD,
                    this.props.responseError
                  )}
                  onChange={this.handleUserPasswordChange}
                />
              </div>
              <div className="service-now-config__item" style={styles.block}>
                <Toggle
                  children={EnableGroupAssignMsg}
                  checked={this.props.config.enabledGroupAssignment}
                  style={styles.toggle}
                  onClick={this.handleGroupEnable}
                />
                <div className="service-now-config__input-label">
                  {DefGroupNameMsg}
                </div>
                <TextField
                  value={this.props.config.groupName || ""}
                  disabled={this.state.isDisabled}
                  //errorStyle={{ marginTop: "6px" }}
                  helperText={this.state.helperText}
                  onChange={this.handleGroupNameChange}
                />
              </div>
              <div className="service-now-config__item">
                <Checkbox
                  disabled={this.props.isSaving}
                  checked={this.props.config.autoCloseAlarm}
                  onChange={this.handleAutoCloseAlarm}
                  labelStyle={{ fontSize: "16px", maxWidth: "350px" }}
                  label={AutocloseAlarm}
                  style={{ display: "inline-block", maxWidth: "200px" }}
                />
                <Checkbox
                  disabled={this.props.isSaving}
                  checked={this.props.config.autoCloseTicket}
                  onChange={this.handleAutoCloseTicket}
                  labelStyle={{ fontSize: "16px", maxWidth: "350px" }}
                  label={AutocloseTickets}
                  style={{ display: "inline-block", maxWidth: "200px" }}
                />
              </div>

              <div className="service-now-config__item">
                <div className="service-now-config__input-header-label">
                  {InstructionMsg}
                </div>
                <div>
                  <div style={{ paddingLeft: "16px" }}>
                    <div
                      style={{ display: "inline-block", verticalAlign: "top" }}
                    >
                      <AdvancedCheckbox
                        disabled={this.props.isSaving}
                        icon={
                          <SeverityIcon level={LEVEL_CRITICAL} size="24px" />
                        }
                        //Critical
                        label={Resource.get("Critical")}
                        checked={this.getSeverityCheckboxState(LEVEL_CRITICAL)}
                        onCheck={(event, isChecked) => {
                          this.handleSeverityCheck(isChecked, LEVEL_CRITICAL);
                        }}
                      />
                      <AdvancedCheckbox
                        disabled={this.props.isSaving}
                        icon={<SeverityIcon level={LEVEL_MAJOR} size="24px" />}
                        //Major
                        label={Resource.get("Major")}
                        checked={this.getSeverityCheckboxState(LEVEL_MAJOR)}
                        onCheck={(event, isChecked) => {
                          this.handleSeverityCheck(isChecked, LEVEL_MAJOR);
                        }}
                      />
                      <AdvancedCheckbox
                        disabled={this.props.isSaving}
                        icon={<SeverityIcon level={LEVEL_MINOR} size="24px" />}
                        //MInor
                        label={Resource.get("Minor")}
                        checked={this.getSeverityCheckboxState(LEVEL_MINOR)}
                        onCheck={(event, isChecked) => {
                          this.handleSeverityCheck(isChecked, LEVEL_MINOR);
                        }}
                      />
                    </div>
                    <div
                      style={{
                        display: "inline-block",
                        verticalAlign: "top",
                        marginLeft: "48px",
                      }}
                    >
                      <AdvancedCheckbox
                        disabled={this.props.isSaving}
                        icon={
                          <SeverityIcon level={LEVEL_WARNING} size="24px" />
                        }
                        //Warning
                        label={Resource.get("Warning")}
                        checked={this.getSeverityCheckboxState(LEVEL_WARNING)}
                        onCheck={(event, isChecked) => {
                          this.handleSeverityCheck(isChecked, LEVEL_WARNING);
                        }}
                      />
                      <AdvancedCheckbox
                        disabled={this.props.isSaving}
                        icon={<SeverityIcon level={LEVEL_INFO} size="24px" />}
                        //Info
                        label={Resource.get("Info")}
                        checked={this.getSeverityCheckboxState(LEVEL_INFO)}
                        onCheck={(event, isChecked) => {
                          this.handleSeverityCheck(isChecked, LEVEL_INFO);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="service-now-config__item">
                <Checkbox
                  disabled={this.props.isSaving}
                  checked={this.props.config.autoResolveIncidents}
                  onChange={this.handleResolveServiceNowIncidentCheck}
                  labelStyle={{ fontSize: "16px", maxWidth: "350px" }}
                  //Ticket on severity chnage
                  label={Resource.get("Ticket on severity change")}
                />
              </div>
            </div>
          </div>
          <div className="service-now-config__rightcontent">
            <div className="service-now-config__rightcontent__infobox">
              <div className="service-now-config__rightcontent__infobox-header">
                <InfoIcon className="service-now-config__rightcontent__infobox-header-icon" />
                <span>{IntegrationName}</span>
              </div>
              <div className="service-now-config__rightcontent__infobox-content">
                <div>
                  {InstructionMsg1}

                  <div className="service-now-content-help">{URLMsg}</div>
                  {
                    //Specify the base URL for the ServiceNow instance.The default format is: https://instancename.service-now.com.
                    Resource.get(
                      "Specify the base URL for the ServiceNow instance.The default format is: https://instancename.service-now.com."
                    )
                  }
                  <div className="service-now-content-help">{UserNameMsg}</div>
                  {
                    //Enter the ServiceNow admin username.
                    Resource.get("Enter the ServiceNow admin username.")
                  }
                  <div className="service-now-content-help">{PasswordMsg}</div>
                  {
                    //Enter the ServiceNow admin password.
                    Resource.get("Enter the ServiceNow admin password.")
                  }
                  <div className="service-now-content-help">
                    {EnableGroupAssignMsg}
                  </div>
                  {
                    //Toggle to activate assigning tickets and alarms to Groups in Infrastructure Manager.
                    Resource.get(
                      "Toggle to activate assigning tickets and alarms to Groups in Infrastructure Manager."
                    )
                  }
                  <div className="service-now-content-help">
                    {EnableGroupAssignMsg}
                  </div>
                  {
                    //Specify the default Group to which ServiceNow tickets and alarms are assigned.
                    Resource.get(
                      "Specify the default Group to which ServiceNow tickets and alarms are assigned."
                    )
                  }
                  <div className="service-now-content-help">
                    {AutocloseAlarm}
                  </div>
                  {
                    //Select to configure alarms to close automatically.
                    Resource.get(
                      "Select to configure alarms to close automatically."
                    )
                  }
                  <div className="service-now-content-help">
                    {AutocloseTickets}
                  </div>
                  {
                    //Select to configure tickets to close automatically.
                    Resource.get(
                      "Select to configure tickets to close automatically."
                    )
                  }
                  <div className="service-now-content-help">
                    {SeverityLevels}
                  </div>
                  {
                    //Specify the severity of alarms in Infrastructure Manager which will create incidents in ServiceNow.
                    Resource.get(
                      "Specify the severity of alarms in Infrastructure Manager which will create incidents in ServiceNow."
                    )
                  }
                  <div className="service-now-content-help">
                    {TicketonSevChange}
                  </div>
                  {
                    // Ticket on Sev Change Message
                    Resource.get(
                      "Select the option to create a ticket in ServiceNow when the severity of an existing ticket changes. If you do not select the option, only the severity of existing ticket changes."
                    )
                  }
                </div>
                {/* <div>
                  For more information on integrating ServiceNow, see{' '}
                  <a href={SERVICENOW_HELP_URL} target="_blank">
                    Help
                  </a>.
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="service-now-config__footer">
          <div className="service-now-config__footer-left">
            <RaisedButton
              variant="contained"
              //DELETE
              children={Resource.get("DELETE")}
              disabled={this.props.state === "NEW"}
              onClick={this.handleDelete}
            />
          </div>
          <div className="service-now-config__footer-right">
            <RaisedButton
              variant="contained"
              //CANCEL
              children={Resource.get("CANCEL")}
              disabled={this.props.isSaving}
              onClick={this.handleCancel}
            />
            {this.props.isSaving ? (
              <RaisedButton disabled={true} variant="contained">
                <CircularProgress style={{color: '#3272D9'}} />
              </RaisedButton>
            ) : (
              <RaisedButton
                variant="contained"
                disabled={this.saveButtonDisabled()}
                //SAVE
                children={Resource.get("SAVE")}
                color="primary"
                onClick={this.handleSave}
              />
            )}
          </div>
        </div>
      </div>
    );
  };
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setBreadCrumbs: (items) => dispatch(setBreadCrumbs(items)),
    fetchConfig: () => dispatch(get()),
    save: (config, successCallback, failureCallback) =>
      dispatch(save(config, successCallback, failureCallback)),
    deleteServiceNowConfig: (config, successCallback, failureCallback) =>
      dispatch(
        deleteServiceNowConfig(config, successCallback, failureCallback)
      ),
  };
};

const mapStateToProps = (state) => {
  return {
    isValidating: state.serviceNow.isValidating,
    isFetching: state.serviceNow.isFetching,
    isSaving: state.serviceNow.isSaving,
    state: state.serviceNow.config.state,
    config: state.serviceNow.mutableConfig,
    responseError: state.serviceNow.responseError,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ServiceNowConfig));
