// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  // Unsupported browser message
  content["Infrastructure management works best on a select set of browsers. "] =
  "Infrastructure Management는 특정 브라우저에서 가장 잘 작동합니다. ";

  //text to link to documentation of unsupported browsers
  content["List of browsers"] =
  "브라우저 목록";

  // END OF LOCALIZATION

export default content;
