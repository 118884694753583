
import React, { Component} from 'react'
import {
  Dialog,
  DialogTitle,
  Button as FlatButton,IconButton,
  ListItem,
  ListItemText,FormControlLabel,Typography,
  ListItemIcon,
  Checkbox,
  List,
  DialogActions,Tooltip,DialogContent
} from "@mineral/core";
import {
  ClearIcon as Clear,
  ErrorIcon as Information,
  RobotIcon,
} from "../ui-components/uim-components";
import * as _ from "lodash";
import moment from "moment";
import get from "lodash/get";
import { monitorProbes as probeTitles } from "@uim/uim-common-strings";
import Resource from "./Resource";
import { VpnKeyIcon as KeyIcon } from "@material-ui/icons/VpnKey";
import "./CustomColumnsDialog.less";
import { getDefaultColumns, getTableColumns } from './columnData';

export default class CustomColumnsDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      saveButtonDisabled: true,
      availableColumns: getTableColumns(),
      selectedColumns: this.props.presistedColumns,
    };
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.presistedColumns && nextProps.presistedColumns.length > 0) {
      let columns = getTableColumns();
      let selectedCols = [];
      let storedColumnList = nextProps.presistedColumns;
      storedColumnList.forEach((data) => {
        selectedCols.push(columns.find((col) => col.headerName == data));
      });
      this.setState({ selectedColumns: [...selectedCols] });
    } else{
        this.setState({ selectedColumns: getDefaultColumns()[0]});
    }
  }

  handleSave() {
    this.setState({ saveButtonDisabled: true });
    this.props.saveSelectedCols(this.state.selectedColumns?.length > 0 ? this.state.selectedColumns : getDefaultColumns()[0]);
  }
  onClose() {
    this.setState({
      // saveError: null,
      saveButtonDisabled: true,
      selectedColumns: this.props.presistedColumns,
      //availableColumns:_.differenceWith(this.getAlarmColumns(),this.props.selectedColumns,_.isEqual)
    });
    this.props.handleClose();
  }
  isCheckboxSelected(column) {
    let data = _.find(this.state.selectedColumns, function (o) {
      if(o)
      return o.headerName === column.headerName;
    });
    return data ? true : false;
  }
  updateSelectedColumnCheck(headerName, event) {
    
    let selectedIndexList = [...this.state.selectedColumns];
    if (event.target.checked) {
      this.state.availableColumns.forEach((data) => {
        selectedIndexList.push(
          this.state.availableColumns.find(
            (col) => col.headerName == headerName
          )
        );
      });
    } else {
      selectedIndexList = selectedIndexList.filter(
        (aCol) => aCol.headerName != headerName
      );
    }
    selectedIndexList = _.uniq(selectedIndexList);
    this.setState({
      selectedColumns: [...selectedIndexList],
      saveButtonDisabled: false
    });
  }

  render() {
    let title = "Edit Columns";
    const actions = [
      <FlatButton
        variant="text" disableRipple={true} style={{textTransform:'capitalize'}}
        //Cancel
        children={Resource.get("Cancel")}
        onClick={this.onClose.bind(this)}
      />,
      <FlatButton
        variant="contained" disableRipple={true} style={{textTransform:'capitalize'}}
        //Ok
        children={Resource.get("Save")}
      
        onClick={this.handleSave.bind(this)}
        disabled={this.state.saveButtonDisabled}
      />,
    ];

    return (
      <Dialog       
       
        modal={false}
        open={this.props.open}
        className= "customColumnDialog"
        onClose={this.onClose.bind(this)}
      >
        <DialogTitle >{title}</DialogTitle>
        <div style={{height:'0px'}}>
        <Tooltip title="Close">
        <IconButton
          style={{
           
          marginTop:'-50px',
          float: 'right',
          marginRight:'8px'

       
          }} title={"Close"} 
          onClick={this.onClose.bind(this)}
          autoFocus
          aria-label="Close"
        >
        
      
          <Clear role="figure" title="Close"/>
          </IconButton>
        
        </Tooltip>
        </div>
        <DialogContent>
        
              <List>
                {this.state.availableColumns.sort(function(obj1, obj2){
                                  if(obj1.headerName < obj2.headerName) { return -1; }
                                  if(obj1.headerName > obj2.headerName) { return 1; }
                                  return 0;
                              }).map((data, index) => (
                  <ListItem key={data.headerName+"-"+index}>
                    {/*<!--ListItemIcon aria-labelledby={data.headerName+"column name"}>
                    
                      <Checkbox
                          checked={this.isCheckboxSelected(data)}
                          onChange={this.updateSelectedColumnCheck.bind(
                            this,
                            data.headerName
                          )}
                          
                          color= 'primary'
                      />
                    </!--ListItemIcon>
                    <ListItemText id={data.headerName+"column name"}  primary={data.headerName} id={data.headerName}/>*/}
                    <FormControlLabel
        label={<Typography variant="span">{data.headerName}</Typography>}
       
        control={
          <Checkbox
            checked={this.isCheckboxSelected(data)}
            onChange={this.updateSelectedColumnCheck.bind(
                            this,
                            data.headerName
                          )}
            
          />
        }

        />
                  </ListItem>
                ))}
              </List>
           
        </DialogContent>
        <DialogActions>
          {actions}
        </DialogActions>
      </Dialog>
    );
  }
}
