import {
  SELECTION_TOGGLE,
} from './actionTypes'


const initialState = {
  hide: false,
}

const selection = (state=initialState, action) => {
  switch(action.type) {
    case SELECTION_TOGGLE:
      return {...state, hide: !state.hide}
    default:
      return state
  }
}

export default selection
