import {
  FETCH_CONFIG,
  FETCH_CONFIG_SUCCESS,
  FETCH_CONFIG_FAILURE
} from './actionTypes'

const config = (state = {
  isFetching: false,
  didInvalidate: false,
  keys: {},
}, action) => {
  switch (action.type) {
    case FETCH_CONFIG:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false
      });
    case FETCH_CONFIG_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        keys: action.config
      });
    case FETCH_CONFIG_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        errorMessage: action.errorMessage,
        keys: {}
      });
    default:
      return state
  }
};

export default config
