import connect from "./../../utils/connect";
import { setBreadCrumbs } from "./../breadcrumbs/actions";
import Inventory from "./Inventory";
import { createSelector } from "reselect";
import filter from "lodash/filter";
import orderBy from "lodash/orderBy";
import {
  deleteDevicesFromInventory,
  getStoredColumns,
} from "./../../api/computer-systems/actions";
import { switchTreeViewNav,disableTreeViewNav } from "./../../api/groups/actions";

const getComputerSystems = (state) => state.entities;
const getInventorySearchData = (state) => state.inventoryEntityFilter;

const getCsSelector = createSelector(
  [getComputerSystems],
  (computerSystems) => computerSystems
);

// const getFilteredCsSelector = createSelector(
//   [getCsSelector, getSearchTerm],
//   (systems, searchTerm) => {
//
//     let filteredEntities = filter(systems, (system) => {
//       var stringToCheckAgainst = getString(system);
//       if(searchTerm && (searchTerm.trim().length>2 || searchTerm.trim().length==0))
//       return (
//         stringToCheckAgainst.toLowerCase().indexOf(searchTerm.toLowerCase()) >=
//         0
//       );
//       else
//       return true;
//     });
//     return filteredEntities;
//   }
// );
const getFilteredCsSelector = createSelector(
  [getCsSelector, getInventorySearchData],
  (systems, searchData) => {
    let filteredEntities = filter(systems, (system) => {
      var stringToCheckAgainst = getString(system);
      if (
        searchData.quickFilter &&
        (searchData.quickFilter.trim().length > 2 ||
          searchData.quickFilter.trim().length == 0)
      )
        return (
          stringToCheckAgainst
            .toLowerCase()
            .indexOf(searchData.quickFilter.toLowerCase()) >= 0
        );
      else return true;
    });

    // filteredEntities = filter(systems, (system) => {
    //   var stringToCheckAgainst = getString(system);

    //   if (searchData.colFilters.length)
    //     return (
    //       stringToCheckAgainst.toLowerCase().indexOf(searchData.quickFilter.toLowerCase()) >=
    //       0
    //     );
    //   else
    //     return true;
    // });

    return filteredEntities;
  }
);

function getString(system) {
  var ips = "";
  for (var ip in system.ipAddresses) {
    if (system.ipAddresses.hasOwnProperty(ip))
      ips += system.ipAddresses[ip] + " ";
  }
  if (system.attributes) {
    return (
      system.name +
      " " +
      ips +
      " " +
      system.totalAlarmCount +
      " " +
      system.role +
      " " +
      system.osName +
      " " +
      system.attributes?.monitoredBy
    );
  } else {
    return (
      system.name +
      " " +
      ips +
      " " +
      system.totalAlarmCount +
      " " +
      system.role +
      " " +
      system.osName
    );
  }
}

const getSortedCsSelector = createSelector(
  [getFilteredCsSelector],
  (csSystems) => {
    return orderBy(csSystems, "attributes.create_time", "desc");
  }
);
const getRowsSelector = createSelector([getSortedCsSelector], (cards) => {
  return cards;
});

const mapStateToProps = (state) => {
  return {
    data: getRowsSelector(state),
    selectedCsIds: state.selectedCsIds,
    visibleDataCount: state.columnFilter,
    acl: state.acl,
    fromTableData: state.filteredInventoryData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setBreadCrumbs: (items) => dispatch(setBreadCrumbs(items)),
    deleteDevicesFromInventory: (ids, options) =>
      dispatch(deleteDevicesFromInventory(ids, options)),
    switchTreeViewNav: (currentNav) => dispatch(switchTreeViewNav(currentNav)),
    disableTreeViewNav: (menus) => dispatch(disableTreeViewNav(menus)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Inventory);
