// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //ALARM SUMMARY
  content["ALARM SUMMARY"] =
  "RESUMEN DE LAS ALARMAS";

  //ALARM HISTORY LAST 30 DAYS
  content["ALARM HISTORY LAST 30 DAYS"] =
  "HISTORIAL DE ALARMAS DE LOS ÚLTIMOS 30 DÍAS";

  //ALARM HISTORY
  content["ALARM HISTORY"] =
  "HISTORIAL DE ALARMAS";

  // END OF LOCALIZATION

export default content;
