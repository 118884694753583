import * as React from 'react';
import { SvgIcon } from '@mineral/core';

export const FilledTriangleUp = React.forwardRef(
  (props, ref) => (
    <SvgIcon viewBox="0 0 40 40" {...props} ref={ref}>
      <path d="M20 6L36 34H4L20 6Z" fill={props.color} />
    </SvgIcon>
  )
);

export default FilledTriangleUp;