import React, { Component } from "react";
import { Dialog,DialogTitle, Button as FlatButton } from "@mineral/core";
import Resource from "./Resource";
import { setSelectedIfIds } from "../../api/groups/actions";
import InterfaceTable from "../interfaces/InterfaceTable";
import "./dialogButtonStyle.less";

class RemoveDevicesDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disableButton: false,
    };
  }
  componentWillReceiveProps() {
    this.setState({
      disableButton: false,
    });
  }
  render() {
    let actions = [
      <FlatButton
      variant = "text"
        //Cancel
        children={Resource.get("Cancel")}
        color = "primary"
        onClick={this.onClose}
      />,
      <FlatButton
      variant = "text"
        //Remove
        children={Resource.get("Remove")}
        color = "primary"
        keyboardFocused={true}
        onClick={this.handleRemoveDevices}
        disabled={this.state.disableButton}
      />,
    ];
    //Your individual devices will not be deleted
    const helplabelmessage = Resource.get(
      "Your individual devices will not be deleted "
    );
    const title = `Remove ${
      this.props.isInterfaceGroup ||
      this.props.location.pathname.endsWith("interfaces")
        ? this.props.selectedIfIds.length
        : this.props.selectedCsIds.length
    } devices?`;
    let content = (
      <div>
        <p>
          This action removes your{" "}
          {this.props.isInterfaceGroup ||
          this.props.location.pathname.endsWith("interfaces")
            ? this.props.selectedIfIds.length
            : this.props.selectedCsIds.length}{" "}
          selected devices from the group
        </p>
        <p>{helplabelmessage}</p>
      </div>
    );

    return (
      <Dialog
        style={{ width: "300px" }}
        //title={title}
        actions={actions}
        modal={false}
        open={this.props.open}
        aria-labelledby="dialog-title"
        className= "dialogConatiner"
        >
         <DialogTitle id="dialog-title"> {title}</DialogTitle>
        {content}
        <div>{this.errorMsg}</div>
      </Dialog>
    );
  }
  onClose = () => {
    this.errorMsg = null;
    this.props.handleClose("removeDevicesOpen");
  };
  handleRemoveDevices = () => {
    this.setState({
      disableButton: true,
    });
    let info = {
      csId: this.props.selectedId,
      isGroup: true,
    };
    if (
      this.props.isInterfaceGroup ||
      this.props.location.pathname.endsWith("interfaces")
    ) {
      this.props
        .removeInterfacesFromGroup(
          this.props.selectedId,
          this.props.selectedIfIds
        )
        .then((response) => {
          sessionStorage.setItem("deviceRemoved", true);
          this.props.refreshTree();
          if (response.type === "REMOVE_INTERFACES_SUCCESS") {
            this.props.removeEntities(this.props.selectedIfIds);
            this.props.setSelectedIfIds([]);
            this.props.handleClose("removeDevicesOpen");
            this.props.getInterfaces(info);
            <InterfaceTable interfaceData={this.state.interfaceData} />;
          } else {
            this.props.handleClose("removeDevicesOpen");
            this.props.handleErrorDialogOpen("removeDevice");
          }
        })
        .catch((error) => {
         // console.log("Remove Devices " + error);
          this.props.handleClose("removeDevicesOpen");
          this.props.handleErrorDialogOpen("removeDevice");
        });
    } else {
      this.props
        .removeDevicesFromGroup(this.props.selectedId, this.props.selectedCsIds)
        .then((response) => {
          sessionStorage.setItem("deviceRemoved", true);
          this.props.refreshTree();
          if (response.type === "REMOVE_DEVICES_SUCCESS") {
            this.props.removeEntities(this.props.selectedCsIds);
            this.props.setSelectedCsIds([]);
            this.props.handleClose("removeDevicesOpen");
          } else {
            this.props.handleClose("removeDevicesOpen");
            this.props.handleErrorDialogOpen("removeDevice");
          }
        })
        .catch((error) => {
          this.props.handleClose("removeDevicesOpen");
          this.props.handleErrorDialogOpen("removeDevice");
        });
    }
  };
}
RemoveDevicesDialog.defaultProps = {
  selectedCsIds: [],
};
export default RemoveDevicesDialog;
