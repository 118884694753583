import React, { Component } from "react";
import DeviceDetails from "./panels/DeviceDetails";
import Metrics from "./panels/Metrics";
import TotalAlarms from "./panels/TotalAlarms";
import "./dashboard-container.less";
import "./dashborad-container-responsive.less";
import Resource from "../inventory/Resource";
import DeviceGroups from "./panels/DeviceGroups";

class DeviceDashboard extends Component {
  constructor(props) {
    super(props);
  }
  componentWillMount() {
    // this.props.setBreadCrumbs();
  }
  render() {
    //Metrics Monitoring
    let title = Resource.get("Metrics Monitoring");
    //By Severity, By Alarms
    // let sevTitle = Resource.get("By Severity, By Alarms");
    let cardHeader = (
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span className="dashboard_container_title">{title}</span>
        </div>
      </div>
    );
    return (
      <div className="dashboard dashboard__main" style={{ marginLeft: "0px",height:'100%' }}>
        <section className="device-dashboard-cards" style={{height:'100%'}}>
          <div
            className="dashboard_row_container"
            style={{
              display: "flex",
              gridGap: "16px",
              flex: "1",
              flexDirection: "column",
              minWidth:
                this.props.fromTreeView && this.props.isTreeOpen
                  ? "26vw"
                  : "32vw",
            }}
          >
            {/* <div className="dashboard__alarms--total dashboard__row-item ">
              <TotalAlarms
                selectedEntity={this.props.selectedEntity}
                csId={this.props.csId}
                hideCloseButton={true}
              />
            </div> */}
            <div
              className="dashboard_container2 dashboard__row-item"
              style={{ minHeight: "50%", maxHeight: "60%" }}
            >
              <DeviceDetails
                className="dashboard__monitored-tech"
                csId={this.props.csId}
                {...this.props}
              />
            </div>
            <div
              className="dashboard_container4 dashboard__row-item"
              style={{ minHeight: "40%", maxHeight: "50%" }}
            >
              <DeviceGroups
                className="dashboard__device-group"
                csId={this.props.csId}
              />
            </div>
          </div>

          <div
            className="dashboard_container3 dashboard_row_container"
            style={{
              minWidth:
                this.props.fromTreeView && this.props.isTreeOpen
                  ? "46vw"
                  : "60vw",
              minHeight: "70vh",
            }}
          >
            {cardHeader}
            <div
              style={{
                display: "flex",
                gridGap: "16px",
                background: "white",
                overflow: "auto",
                height: "69vh",
              }}
            >
              <Metrics
                csId={this.props.csId}
                isInterface={this.props.isInterface}
                isTreeOpen={this.props.isTreeOpen}
              />
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default DeviceDashboard;
