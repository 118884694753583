import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './advancedCheckbox.less';
import {Checkbox} from '@mineral/core';

class AdvancedCheckbox extends Component {
  state = {
    checked: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      checked: props.checked
    }
  }

  render = () => {
    if(this.props.icon === undefined) {
      return (
          <div className='advancedcheckbox-wrapper' onClick={this.onClick}>
            <Checkbox {...this.props} />
          </div>
      )
    } else {
      return (
          <div className='advancedcheckbox-wrapper' onClick={this.onClick}>
            <Checkbox {...this.props} label='' style={{width:'auto'}} />
            <div className='advancedcheckbox-icon-wrapper'>{this.props.icon}</div>
            <span className='advancedcheckbox-label' style={this.props.labelStyle}>{this.props.label}</span>
          </div>
      )
    }
  }

  onClick = (...args) => {
    this.handleCheck(args[1], !this.state.checked)
  }

  handleCheck = (event, isChecked) => {
    this.setState({checked: isChecked});
    if(this.props.onCheck) {
      this.props.onCheck(event, isChecked);
    }
  };

}

AdvancedCheckbox.propTypes = {
  icon: PropTypes.element,
  onCheck: PropTypes.func
}

export default AdvancedCheckbox;
