import {
    GROUP_DASHBOARD_COMPONENTS_CHANGE,
    REQUEST_TOP_SUB_GROUP,
    FETCH_TOP_SUB_GROUP_SUCCESS,
    FETCH_TOP_SUB_GROUP_FAILURE,
} from './actionTypes'
import isEqual from 'lodash/isEqual'
const groupsDashboard = (state = {
    isFetching: false,
    didTopSubGroupsInvalidate: false,
    groupDashboardComponents:{
        showOpenAlarms:true,
        showDevicesByRoles:true,
        showTopEntitiesContainer:true,
        showTopTechnologies:true,
        showTopGroups:true,
        showTopDevices:true,
    },
    topSubGroups:[] 
}, action) => {
    switch (action.type) {
        case GROUP_DASHBOARD_COMPONENTS_CHANGE:
        return Object.assign({}, state, {
            groupDashboardComponents:action.currentState,
            
        });
        case REQUEST_TOP_SUB_GROUP:
        return Object.assign({}, state, {
            isFetching: true,
            didTopSubGroupsInvalidate: false
        });
        case FETCH_TOP_SUB_GROUP_SUCCESS:
        const topSubGroups = action.data;
        
        const topSubGroupsState = Object.assign({}, state, {
            isFetching: false,
            didTopSubGroupsInvalidate: false,
            topSubGroups: topSubGroups,
            
        });
        return isEqual(topSubGroupsState, state) ? state : topSubGroupsState
        case FETCH_TOP_SUB_GROUP_FAILURE:
        return Object.assign({}, state, {
            isFetching: false,
            didTopSubGroupsInvalidate: true,
            errorMessage: action.errorMessage,
            topSubGroups: []
            
        });
        
        default:
        return state
    }
}

export default groupsDashboard
