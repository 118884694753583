import React, { Component } from "react";
import { Provider } from "react-redux";

import configureStore from "./store";
import MetricPaletteViewer from "./MetricPaletteViewer";
import { isEqual } from "lodash";

class MetricPalette extends Component {
  constructor(props) {
    super(props);
    this.store = configureStore();
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.fromGroup || nextProps.fromCsView) {
      if (
        !isEqual(nextProps.devices, this.props.devices) ||
        !isEqual(nextProps.singleDevice, this.props.singleDevice) ||
        !isEqual(nextProps.isTreeOpen, this.props.isTreeOpen) ||
        !isEqual(nextProps.isInterface, this.props.isInterface) ||
        !isEqual(nextProps.masterId, this.props.masterId) ||
        !isEqual(nextProps.name, this.props.name)
      ) {
        return true;
      } else return false;
    } else return true;
  }

  render() {
    return (
      <div style={{height:'100%'}}>
        <Provider store={this.store}>
          <MetricPaletteViewer {...this.props} />
        </Provider>
      </div>
    );
  }
}

export default MetricPalette;

export { MetricPalette };
