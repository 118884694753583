import computerSystemsApi from "./../../../api/computer-systems/computerSystems";
const stringTypeFilter = (name, id) => {

  return {
    id: id,
    name: name,
    isDate: false,
    sortingDataType: 'string',
    selectedOptions: ['startsWith'],
    extraData: '',
    options: [
      { id: 'equals', name: 'Equals' },
      //{ id: 'NOTEQUAL', name: 'Not equals' },
      { id: 'contains', name: 'Contains' },
      //{ id: 'NOTCONTAINS', name: 'Does not contain' },
      { id: 'startsWith', name: 'Starts with' },
      { id: 'endsWith', name: 'Ends with' },
    ],
  }
}

const dateTypeFilter = (name, id) => {

  return {
    id: name,
    name: name,
    isDate: true,
    sortingDataType: 'string',
    selectedOptions: ['greaterThan'],
    extraData: '',
    options: [
      { id: 'greaterThan', name: 'Greater than' },
      { id: 'greaterThanOrEqual', name: 'Greater than or equal' },
      { id: 'lessThan', name: 'less than' },
      { id: 'lessThanOrEqual', name: 'Less than or equal' },
    ],
  }
}

const multiSelectTypeFilter = (name, id, options) => {
  return {
    id: id,
    name: name,
    sortingDataType: "multiselect",
    selectedOptions: [],
    extraData: '',
    options: [],
  }
}

const dateTimeFilter = (name, id) => {
  return {
    id: name,
    name: name,
    sortingDataType: "datetime",
    selectedOptions: ["< 4 hours", "< 10 hours", "< 1 day", "< 2 days", "< 1 week", "< 1 month", "< 3 months", "< 6 months", "< 1 year", "all"],
    extraData: '',
    options: [
      { id: '< 4 hours', name: '< 4 hours', },
      { id: '< 10 hours', name: '< 10 hours' },
      { id: '< 1 day', name: '< 1 day' },
      { id: '< 2 days', name: '< 2 days' },
      { id: '< 1 week', name: '< 1 week' },
      { id: '< 1 month', name: '< 1 month' },
      { id: '< 3 months', name: '< 3 months' },
      { id: '< 6 months', name: '< 6 months' },
      { id: '< 1 year', name: '< 1 year' },
      { id: 'all', name: 'All' },
    ],
  }
}

const selectBusType = (name, id) => {
  return {
    id: name,
    name: name,
    sortingDataType: "multiselect",
    selectedOptions: ["Yes", "No"],
    extraData: '',
    options: [
      { id: 'Yes', name: 'Yes', },
      { id: 'No', name: 'No' },
    ],
  }
}
const selectBooleanType = (name, id) => {
  return {
    id: name,
    name: name,
    sortingDataType: "multiselect",
    selectedOptions: ["True", "False"],
    extraData: '',
    options: [
      { id: 'True', name: 'True', },
      { id: 'False', name: 'False' },
    ],
  }
}
const booleanTypeFilter = (name, id) => {
  return {
    id: id,
    name: name,
    sortingDataType: 'boolean',
    selectedOptions: ['true'],
    extraData: null,
    options: [
      { id: 'true', name: 'True' },
      { id: 'false', name: 'False' },
    ],
  }
}


export const defaultFiltersActiveObjects = (columns) => {

 
  return [...columns.map((data, index) => {
    
  
    if(data.filter=="agTextColumnFilter")
    return stringTypeFilter(data.headerName,data.field)
    if(data.filter=="customSetFilter")
    { 
      let filterData = multiSelectTypeFilter(data.headerName,data.field, index);
      filterData = {
        ...filterData, options: [...data.filterParams.values.map(item => {
          return { id: item, name: item }
        })]
      };
      return filterData;
    }
    
  }
  )]
}