import {
  SHOW_INVENTORY_FILTER,
} from './actionTypes'

const inventoryFilter = (state = {
  showFilter: false,
}, action) => {
  switch (action.type) {
    case SHOW_INVENTORY_FILTER:
      return action.showFilter
    default:
      return state
  }
}
export default inventoryFilter
