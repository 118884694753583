import React, { useEffect, useState } from 'react';
import { Checkbox, IconButton, Tooltip,TextField ,FormControl} from '@mineral/core';
import { MagnifyingGlass as SearchIcon, ArrowUp } from '@mineral/icons';
import { changeCheckedSearchItems } from '../../utils/changeCheckedSearchItems';
import { changeCheckedItems } from '../../utils/changeCheckedItems';
import { sortByChecked } from '../../utils/sortByChecked';
import { getDevicesBySearch } from '../../utils/getDevicesBySearch';

export const Content = ({ items, allDisabled, setItems, setDisabled }) => {
  const [searchText, setSearchText] = useState('');
  const [allChecked, setAllChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [sort, setSort] = useState(false);

  const handleChecked = ({ target: { id } }) => {
    if (!id) return;

    setDisabled(false);

    if (id === 'All' && searchText.trim()) {
      const params = [items, allChecked, searchText, getDevicesBySearch];

      return setItems(sortByChecked(changeCheckedSearchItems(...params)));
    }

    if (id !== 'All') return setItems(changeCheckedItems({ items, id }));

    setItems(changeCheckedItems({ items, checked: !allChecked }));
  };

  const handleSort = () => {
    if (allDisabled) return;

    setSort((oldState) => !oldState);
    setItems(sortByChecked(items, sort));
  };

  const handleSearch = (e) => !allDisabled && setSearchText(e.target.value);

  const getItems = (arr) =>
    arr.filter(({ name }) => getDevicesBySearch(name, searchText));

  useEffect(() => {
    if (!items.length) return;

    const searchItems = items.filter(({ name }) =>
      getDevicesBySearch(name, searchText)
    );

    const isSelectedAll = !searchItems.find(({ checked }) => !checked);

    setAllChecked(searchItems.length ? isSelectedAll : false);
  }, [items, searchText]);

  useEffect(() => {
    const isSelectedAll = items.findIndex(({ checked }) => !checked) !== -1;
    const isSomeSelected = items.findIndex(({ checked }) => checked) !== -1;

    setIndeterminate(isSelectedAll && isSomeSelected);
  }, [items])

  return (
    <div className="update-devices-content">
      <div className="update-devices-content-search" style={{height:"10%", overflow:"hidden"}}>
        <Tooltip title={allChecked ? 'Unselect All' : 'Select All'}>
          <Checkbox style={{top:'10px',left:'9px'}}
            id="All"
            inputProps={{'aria-label':allChecked ? 'Unselect All' : 'Select All'}}
            checked={allChecked}
            disabled={allDisabled}
            onChange={handleChecked}
            indeterminate={indeterminate}
          />
        </Tooltip>
        <span className="update-devices-content-search__line" />
        <Tooltip title="Search Devices" placement="top">
        <FormControl fullWidth style={{ marginLeft: '3.75rem',
    marginTop: '-0.75rem',
    marginBottom: '0.75rem'}}>
          <TextField size="small" 
           // className="update-devices-content-search__input"
            placeholder="Search by name"
            value={searchText}
            onChange={handleSearch}
            InputProps={{style:{marginRight:'none',},
            endAdornment:( <SearchIcon/>)}}
          />
          </FormControl>
        </Tooltip>
       
        <Tooltip title="Sort" placement="top-start">
          <IconButton style={{margin:'5px'}}
            className={`icon arrow-icon ${sort && 'down'}`}
            disabled={allDisabled}
            onClick={handleSort}
          >
            <ArrowUp />
          </IconButton>
        </Tooltip>
      </div>
      <div className="update-devices-content-devices" style={{height:"90%"}}>
        {!!items.length &&
          getItems(items).map(({ name, csId, checked }) => (
            <div key={csId} className="update-devices-content-devices-device">
              <Tooltip title={checked ? 'Unselect' : 'Select'}>
                <Checkbox
                  id={csId}
                  inputProps={{'aria-label':name+` ${checked}`}}
                  checked={checked}
                  disabled={allDisabled}
                  onChange={handleChecked}
                />
              </Tooltip>
              <span className="update-devices-content-devices-device__name">
                {name}
              </span>
            </div>
          ))}
        {! getItems(items)?.length && (
          <div className="update-devices-content-devices__empty">
            There are no devices
          </div>
        )}
      </div>
    </div>
  );
};
