import React, { Component } from "react";
import { List, ListItem, Avatar,Snackbar,SnackbarContent ,TextField,InputAdornment,FormControl} from '@mineral/core';
import { IconButton ,Button} from "@mineral/core";
import { SearchIcon } from "../../ui-components/uim-components";
import "./credentialVault.less";
import credentials from "./../../../api/DeviceDiscovery/credentials/credentials";
import keyIcon from "../../../assets/icons/keyIcon.svg";
import deleteIcon from "../../../assets/icons/deleteIcon.svg";
import discover from "../../../assets/icons/discover.svg";
import isEqual from "lodash/isEqual";
import Resource from "./Resource";
import { Plus as PlusIcon } from "@mineral/icons";
import AccessibleTooltip from '../../common/AccessibleTooltip'
/**
 * @Author patan11
 */

export default class CredentialVaultCatalog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchQuery: "",
      catalogItems: this.props.catalogItems,
      filteredCatalogItems: this.props.catalogItems,
      unixCredItems: this.props.unixCredItems,
      filteredUnixCreds: this.props.unixCredItems,
      snmpCredItems: this.props.snmpCredItems,
      filteredSnmpCredItems: this.props.snmpCredItems,
      deletedItem: "",
      selectedItem: "",
      savepopUpOpen: false,
		  popMassage:""
    };
  }

  componentDidMount() {
    if (this.props && this.props.type === "WMI") {
      this.props.getWMI();
    } else if (this.props && this.props.type === "UNIX") {
      this.props.getUNIX();
    } else if (this.props && this.props.type === "SNMP") {
      this.props.getSNMP();
    }

    let api = {
      refreshPostDelete: this.refreshPostDelete,
      refreshPostCreate: this.refreshPostCreate,
      refreshPostUpdate: this.refreshPostUpdate,
    };
    if (this.props.OnCredintialsReady) {
      this.props.OnCredintialsReady(api);
    }
  }
  componentWillMount() {
    this.pollCredentials(this.props.type);
  }
  componentWillUnmount() {
    if (this.props.type === "WMI") {
      this.props.pollWmiCredStop();
    }
    if (this.props.type === "UNIX") {
      this.props.pollUnixCredStop();
    }
    if (this.props.type === "SNMP") {
      this.props.pollsnmpCredStop();
    }
  }
  componentWillReceiveProps = (newProps) => {
    if (this.props.type === "WMI") {
      if (!isEqual(newProps, this.props.catalogItems)) {
        this.setState({
          catalogItems: newProps.catalogItems,
          filteredCatalogItems: newProps.catalogItems,
        });
        //this.props.wmiCredsIsFetching =true;
      }
    } else if (this.props.type === "UNIX") {
      if (!isEqual(newProps, this.props.unixCredItems)) {
        this.setState({
          unixCredItems: newProps.unixCredItems,
          filteredUnixCreds: newProps.unixCredItems,
        });
      }
    } else if (this.props.type === "SNMP") {
      if (!isEqual(newProps, this.props.snmpCredItems)) {
        this.setState({
          snmpCredItems: newProps.snmpCredItems,
          filteredSnmpCredItems: newProps.snmpCredItems,
        });
      }
    }
  };
  pollCredentials = (type) => {
    if (type === "WMI") {
      this.props.pollWmiCredStart();
    } else if (type === "UNIX") {
      this.props.pollUnixCredStart();
    } else if (type === "SNMP") {
      this.props.pollsnmpCredStart();
    }
  };

  handleSearchTextChange = (e) => {
    const filterItem = e.target.value.replace(/^\s+/g, "");
    this.filterOnSearch(filterItem, true);
  };
  filterOnSearch = (filterItem, allowSearch) => {
    let searchList = [];
    if (filterItem.length !== 0) {
      if (this.props && this.props.type === "WMI") {
        searchList = this.props.catalogItems.filter((item) => {
          if (
            item.name.toLowerCase().indexOf(filterItem.toLowerCase()) !== -1
          ) {
            return true;
          }
        });
        if (
          !isEqual(searchList, this.state.filteredCatalogItems) ||
          allowSearch
        ) {
          this.setState({
            searchQuery: filterItem,
            filteredCatalogItems: searchList,
          });
        }
      } else if (this.props && this.props.type === "UNIX") {
        searchList = this.props.unixCredItems.filter((item) => {
          if (item.name.toLowerCase().indexOf(filterItem.toLowerCase()) != -1) {
            return true;
          }
        });
        if (!isEqual(searchList, this.state.filteredUnixCreds) || allowSearch) {
          this.setState({
            searchQuery: filterItem,
            filteredUnixCreds: searchList,
          });
        }
      } else if (this.props && this.props.type === "SNMP") {
        searchList = this.props.snmpCredItems.filter((item) => {
          if (item.name.toLowerCase().indexOf(filterItem.toLowerCase()) != -1) {
            return true;
          }
        });
        if (
          !isEqual(searchList, this.state.filteredSnmpCredItems) ||
          allowSearch
        ) {
          this.setState({
            searchQuery: filterItem,
            filteredSnmpCredItems: searchList,
          });
        }
      }
    } else {
      this.setState({
        searchQuery: "",
        filteredCatalogItems: this.props.catalogItems,
        filteredUnixCreds: this.props.unixCredItems,
        filteredSnmpCredItems: this.props.snmpCredItems,
      });
    }
  };
  handleClickItem = (item) => {
    this.setState({ selectedItem: item.id });
    if (item) {
      this.props.populateCred(item);
    } else {
      this.props.populateCred(null);
    }
  };
  handleAddNewCredential = () => {
    this.setState({ selectedItem: "" });
    if (this.props) {
      this.props.populateCred(null);
    }
  };
  refreshPostDelete = (deletedId, type) => {
    if (type === "WMI") {
      this.props.deleteWmiCred(deletedId);
    } else if (type === "UNIX") {
      this.props.deleteUnixCred(deletedId);
    } else if (type === "SNMP") {
      this.props.deleteSNMPCred(deletedId);
    }
  };

  handleDeleteItem = (filterItem, type) => {
    if (filterItem) {
      credentials.deleteCredential(filterItem.id, type).then(
        (response) => {
          this.refreshPostDelete(response.data.id, type);
          this.setState({ deletedItem: "" });
          if (this.state.selectedItem === response.data.id) {
            this.props.refreshWelcome();
          }
          this.setState({
            savepopUpOpen: true,
            popMassage:"Removed Successfully"
            })
        },
        (err) => {
          // console.log(err)
        }
      );
    }
  };

  refreshPostUpdate = (credObject, type) => {
    if (type === "WMI") {
      this.props.updateWmiCred(credObject);
    }
    if (type === "UNIX") {
      this.props.updateUnixCred(credObject);
    }
    if (type === "SNMP") {
      this.props.updateSNMPCred(credObject);
    }
  };
  refreshPostCreate = (credObject, type) => {
    if (type === "WMI" && this.state.filteredCatalogItems) {
      this.props.addWmiCred(credObject);
      this.setState({ selectedItem: credObject.id });
    }
    if (type === "UNIX" && this.state.filteredUnixCreds) {
      this.props.addUnixCred(credObject);
      this.setState({ selectedItem: credObject.id });
    }
    if (type === "SNMP" && this.state.filteredSnmpCredItems) {
      this.props.addSNMP(credObject);
      this.setState({ selectedItem: credObject.id });
    }
  };

  deleteCatalogItem = (id) => {
    this.setState({ deletedItem: id });
  };
  handleNoClick = () => {
    this.setState({ deletedItem: "" });
  };
  renderCatalogList(item) {
    let removeLabel = "";
    let enableStyle = ["catalogListItem"];
    let enableKey = ["avatar-key"];
    if (this.state.selectedItem === item.id) {
      enableStyle.push("enableSelectedItem");
      enableKey.push("enableSelectedItem");
    }

    let type;
    if (this.props) {
      type = this.props.type;
      if (type === "SCOPE") {
        removeLabel = "Remove this scope ?";
      } else {
        removeLabel = "Remove this credential ?";
      }
    }
    return (
      <div
        className={enableStyle.join(" ")}
        style={{ marginLeft: "5px" }}
        ref={this.props.inputRef}
      >
        <ListItem  component="div"
          key={item.id} 
          style={{
            marginLeft: "16px",
            padding: "0px",
            lineHeight: "14px",
            cursor: "inherit",
            backgroundColor: "none",
            alignItems:'center',
            width:'97%'
          }}
          
        >
          {this.state.deletedItem === item.id ? (
            <div style={{ width: "355px",display:'flex',flexDirection:'row',marginLeft:'24px' }}>
              <span style={{ fontSize: "14px", fontFamily: "Roboto" ,marginRight:'8px',marginTop:'10px'}}>
                {removeLabel}
              </span>
              <Button style={{marginRight:'8px'}} variant="contained" color="error" size="small"
                autoFocus={true}
                onClick={() => this.handleDeleteItem(item, type)}
              >
                Yes
              </Button>
              <Button  size="small" variant="outlined"
               
                onClick={() => this.handleNoClick()}
              >
                No
              </Button>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "16px",
                width: "100%",
              }}
            >
              <button
                style={{
                  display: "flex",
                  paddingRight: "10px",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  width: "93%",
                  cursor: "pointer",
                  alignItems: "center",
                }}
                className="credentialvault-transparent-btn"
                tabIndex={0}
                onClick={() => this.handleClickItem(item)}
              >
                {type === "WMI" || type === "UNIX" || type === "SNMP" ? (
                  <Avatar className={enableKey.join(" ")} src={keyIcon} 
                  role="figure" imgProps={{alt:type}}
                  //Key Icon
                  aria-label= {Resource.get("Key Icon")} />
                ) : (
                  <Avatar  role="figure" className="avatar-key" imgProps={{alt:type}} src={discover} />
                )}
                <span
                  style={{
                    cursor: "pointer",
                    fontSize: "14px",
                    fontFamily: "Roboto",
                  }}
                >
                  {item.name}
                </span>
              </button>
              <div>
              <AccessibleTooltip title={`Remove ${this.props.type} ${type === "SCOPE"?"scope":"credential"} - ${item.name}`}>
                <IconButton aria-label={`Remove ${this.props.type} ${type === "SCOPE"?"scope":"credential"} - ${item.name}`}
                  style={{ float: "right", cursor: "pointer", padding: 0 }}
                  onClick={() => this.deleteCatalogItem(item.id)}
                >
                  <img alt={`Remove ${this.props.type} ${type === "SCOPE"?"scope":"credential"} - ${item.name}`} src={deleteIcon} />
                </IconButton>
                </AccessibleTooltip>
              </div>
            </div>
          )}
        </ListItem>
        {this.state.savepopUpOpen === true? (
        <div>
           <Snackbar
            key="snackbar"
            open={this.state.savepopUpOpen}
            autoHideDuration={2000}
            onClose={() => this.setState({ savepopUpOpen: null })}
            // onActionTouchTap={this.onDescription}
            // onRequestClose={this.handleRequestClose}
            >
            <SnackbarContent
              message={this.state.popMassage}
            />
          </Snackbar>
        </div>
      ):([])}
      </div>
    );
  }
  render() {
    let noItemsLabel = Resource.get("No Items Available"); //No Items Available
    let catalogList = "";
    if (this.state.searchQuery !== "") {
      this.filterOnSearch(this.state.searchQuery);
    }
    if (this.props && this.props.type === "WMI") {
      if (this.state.filteredCatalogItems.length !== 0) {
        catalogList = this.state.filteredCatalogItems.map((item) =>
          this.renderCatalogList(item)
        );
      }
    } else if (this.props && this.props.type === "UNIX") {
      if (this.state.filteredUnixCreds.length !== 0) {
        catalogList = this.state.filteredUnixCreds.map((item) =>
          this.renderCatalogList(item)
        );
      }
    } else if (this.props && this.props.type === "SNMP") {
      if (this.state.filteredSnmpCredItems.length !== 0) {
        catalogList = this.state.filteredSnmpCredItems.map((item) =>
          this.renderCatalogList(item)
        );
      }
    }

    return (
      <div className="credential-vault-catalog">
        
          
           <FormControl style={{width:'350px'}}>
              <TextField
                
                onChange={this.handleSearchTextChange.bind(this)}
                style={{ marginLeft: "24px" ,marginTop:'8px'}}
                placeholder="Search"
                
                value={this.state.searchQuery}
                InputProps={{
           
            
           endAdornment: (
             <InputAdornment position="end" >
                <AccessibleTooltip title={"Search"}>
                 <SearchIcon  className="selecticon" style={{ height: 16 }} />
                 </AccessibleTooltip>
             </InputAdornment>
           )}}

              />
              </FormControl>
               <AccessibleTooltip title={`Add ${this.props.type} Credentials`}>
               <IconButton
          
            onClick={this.handleAddNewCredential.bind(this)}
            aria-label={`Add ${this.props.type} Credentials`}
            style={{  margin: "12px" }}
          >
            <PlusIcon className="addIcon" />
          </IconButton>
</AccessibleTooltip>
              
            
           
      
         
        
        <div style={{ padding: "5px", backgroundColor: "#F8F8F8" ,paddingTop:'16px'}}>
         
          {catalogList.length !== 0 ? (
            <List  component="div" role="group" className="list">{catalogList}</List>
          ) : (
            <div style={{ marginTop: "20px", marginLeft: "20px" }}>
              {noItemsLabel}
            </div>
          )}
        </div>
      </div>
    );
  }
}
