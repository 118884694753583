// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //ok message
  content["OK"] =
  "Aceptar";

  // CSV template help message
  content["A maximum of 50 devices can be imported in a single CSV import. Only the first 50 records were imported."] =
  "Se puede importar un máximo de 50 dispositivos en una sola importación CSV. Únicamente se han importado los 50 primeros registros.";

  //Import CSV
  content["Import CSV"] =
  "Importar CSV";

  // creation help message
  content["To add devices in bulk, create a CSV file with the following information for each robot:"] =
  "Para agregar los dispositivos de forma masiva, cree un archivo CSV con la siguiente información para cada robot:";

  //IP Address
  content["IP Address"] =
  "Dirección IP";

  // IP4 help message
  content["IPv4 address of system where robot/monitoring agent is to be installed"] =
  "La dirección IPv4 del sistema donde se instalará el robot o el agente de monitorización.";

  //Operating System
  content["Operating System"] =
  "Sistema operativo";

  // Values help message
  content["Values: centos, debian, opensuse, rhel, ubuntu or windows"] =
  "Valores: centos, debian, opensuse, rhel, ubuntu o windows";

  //Architecture
  content["Architecture"] =
  "Arquitectura";

  // Architecture help message
  content["Values: 32 or 64"] =
  "Valores: 32 o 64";

  //Username
  content["Username"] =
  "Nombre de usuario";

  // Username help message
  content["Enter username with installation permissions for system where robot is to be installed"] =
  "Introduzca el nombre de usuario con permisos de instalación para el sistema donde se instalará el robot.";

  //Password
  content["Password"] =
  "Contraseña";

  // Password help message
  content["Password for username on the robot system"] =
  "La contraseña para el nombre de usuario en el sistema del robot.";

  //Sudo password
  content["Sudo password"] =
  "Contraseña sudo";

  // Sudo password help message
  content["If username does not have root-level installation permissions, provide sudo password to elevate permissions to root-level for installation"] =
  "Si el nombre de usuario no dispone de permisos de instalación a nivel root, proporcione la contraseña sudo para conceder permisos de instalación a nivel root.";

  // Note message
  content["Note: Up to 50 devices can be imported from CSV file."] =
  "Nota: Se pueden importar hasta 50 dispositivos desde un archivo CSV.";

  //Example CSV file
  content["Example CSV file"] =
  "Archivo CSV de ejemplo";

  // END OF LOCALIZATION

export default content;
