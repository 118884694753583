export const SET_TIME_RANGE_PROP = 'SET_TIME_RANGE_PROP'
export const SHOW_TIME_RANGE_SELECT = 'SHOW_TIME_RANGE_SELECT'

export const TIME_RANGE_TYPES = {
  ONE_HOUR: '1',
  TWO_HOUR: '2',
  TWELVE_HOUR: '12',
  ONE_DAY: '24',
  THREE_DAY: '72',
  ONE_WEEK: '168',
  TWO_WEEK: '336',
  ONE_MONTH: '720',
  THREE_MONTH: '2160'
}