import React, { Component } from "react";
import {
  ErrorIcon as InfoIcon,
  CenterError,
} from "../../../ui-components/uim-components";
import {CircularProgress} from '@mineral/core'
import {
  Checkbox,
  Dialog,
  DialogTitle,
  Select as DropDownMenu,
  Button as FlatButton,
  LinearProgress,
  MenuItem,
  Button as RaisedButton,
  TextField,
} from "@mineral/core";
import InlineMessage from "./../../../message/InlineMessage";
import * as messageTypes from "./../../../message/InlineMessageTypes";
import "./pagerDutyConfig.less";
import connect from "./../../../../utils/connect";
import IntegrationLogo, {
  TYPE_PAGERDUTY,
} from "./../../../icons/IntegrationLogo";
import { withRouter } from "react-router-dom";
import {
  get,
  save,
  deletePagerDutyConfig,
} from "./../../../../api/integrations/pagerduty/actions";
import * as IntegrationError from "./../IntegrationError";
import AdvancedCheckbox from "./AdvancedCheckbox";
import SeverityIcon, {
  LEVEL_CRITICAL,
  LEVEL_MAJOR,
  LEVEL_MINOR,
  LEVEL_WARNING,
  LEVEL_INFO,
} from "./../../../icons/SeverityIcon";
import { setBreadCrumbs } from "./../../../breadcrumbs/actions";
import Resource from "./Resource";

const PAGERDUTY_APIKEY_URL =
  "https://support.pagerduty.com/hc/en-us/articles/202829310-Generating-an-API-Key";
// const PAGERDUTY_HELP_URL =
//   'http://docops.ca.com/rest/ca/product/latest/topic?hid=HID_Integrate_PagerDuty&space=DXIIM&language=&format=rendered'
const labelStyle = { fontSize: "16px", maxWidth: "450px" };

class PagerDutyConfig extends Component {
  state = {
    isDirty: false,
    showDeleteDialog: false,
    deleteDialogActionsDisabled: false,
  };

  componentWillMount() {
    this.props.setBreadCrumbs([
      {
        //Settings
        name: Resource.get("Settings"),
        link: "/settings",
      },
      {
        //PagerDuty
        name: Resource.get("PagerDuty"),
        link: "/settings/integrations/pagerduty",
      },
    ]);
    this.props.fetchConfig();
  }

  stateChanged = () => {
    this.setState({ isDirty: true });
  };

  handleCancel = () => {
    this.props.history.push("/settings");
  };

  handleSave = () => {
    this.props.save({ ...this.props.config }, this.saveSuccess);
  };

  handleDelete = () => {
    this.setState({
      showDeleteDialog: true,
      deleteDialogActionsDisabled: false,
    });
  };

  handleAPIKeyChange = (event) => {
    this.stateChanged();
    this.props.config.apiKey = event.target.value;
  };

  handleServiceNameChange = (event) => {
    this.stateChanged();
    this.props.config.serviceName = event.target.value;
  };

  handleResolvePagerDutyIncidentCheck = (event) => {
    this.stateChanged();
    this.props.config.autoResolveIncidents = event.target.checked;
  };

  handleAutoCreateIncidentsDelay = (event) => {
    this.stateChanged();
    this.props.config.autoTriggerDelayInSeconds = event.target.value;
  };

  saveSuccess = () => {
    this.props.history.push("/settings");
  };

  deleteSuccess = () => {
    this.setState({ showDeleteDialog: false });
    this.props.history.push("/settings");
  };

  deleteFailure = () => {
    this.setState({ showDeleteDialog: false });
  };

  saveButtonDisabled = () => {
    if (
      this.state.isDirty &&
      this.props.config.apiKey &&
      this.props.config.serviceName
    ) {
      return false;
    }

    return true;
  };

  getSeverityCheckboxState = (level) => {
    if (this.props.config.severityOptions) {
      return this.props.config.severityOptions.indexOf(level) >= 0;
    }

    return false;
  };

  handleSeverityCheck = (isChecked, severity) => {
    var items = this.props.config.severityOptions;
    var index;
    if (items) {
      if (isChecked) {
        index = items.indexOf(severity);
        if (index === -1) {
          items.push(severity);
          this.props.config.severityOptions = items;
          this.stateChanged();
        }
      } else {
        index = items.indexOf(severity);
        if (index > -1) {
          items.splice(index, 1);
          this.props.config.severityOptions = items;
          this.stateChanged();
        }
      }

      // the ems api needs this flag to be set if there are any severity options selected
      this.props.config.autoTriggerIncidents = items.length > 0;
    }
  };

  getGeneralErrorMessageComponent = () => {
    const generalError = IntegrationError.getErrorMessageForKey(
      IntegrationError.ERROR_GENERAL,
      this.props.responseError
    );
    if (generalError != null) {
      return <InlineMessage type={messageTypes.ERROR} message={generalError} />;
    }

    return null;
  };

  getDialogComponent = () => {
    const actions = [
      <FlatButton
        variant="text"
        disabled={this.state.deleteDialogActionsDisabled}
        //CANCEL
        children={Resource.get("CANCEL")}
        color="primary"
        onClick={this.handleDeleteDialogCancel}
      />,
      <FlatButton
        variant="text"
        disabled={this.state.deleteDialogActionsDisabled}
        //DELETE
        children={Resource.get("DELETE")}
        color="primary"
        onClick={this.handleDeleteDialogAccept}
      />,
    ];
    //This action removes the PagerDuty integration
    const ActionMsg = Resource.get(
      "This action removes the PagerDuty integration"
    );
    //help message
    const SyncMsg = Resource.get(
      "Alarms will no longer be synchronized between CA Infrastructure Management and PagerDuty."
    );
    return (
      <Dialog
        style={{ width: "330px" }}
        actions={actions}
        //title= {Resource.get("Delete integration?")}
        open={this.state.showDeleteDialog}
        modal={true}
        aria-labelledby="dialog-title"
      >
        <DialogTitle id="dialog-title">
          {
            //Delete Integration?
            Resource.get("Delete integration?")
          }
        </DialogTitle>
        <span style={{ lineHeight: "24px" }}>
          <span>{ActionMsg}</span>
          <br />
          <br />
          <span>{SyncMsg}</span>
        </span>
      </Dialog>
    );
  };

  handleDeleteDialogCancel = () => {
    this.setState({ showDeleteDialog: false });
  };

  handleDeleteDialogAccept = () => {
    this.setState({ deleteDialogActionsDisabled: true });
    this.props.deletePagerDutyConfig(
      { ...this.props.config },
      this.deleteSuccess,
      this.deleteFailure
    );
  };

  render = () => {
    let fetchErrorMessage = IntegrationError.getErrorMessageForKey(
      IntegrationError.ERROR_FETCH,
      this.props.responseError
    );
    if (this.props.isFetching) {
      return (
        <div>
          <LinearProgress style={{ height: "8px" }} />
        </div>
      );
    } else if (fetchErrorMessage) {
      return (
        <div
          style={{ display: "flex", alignItems: "center", marginTop: "100px" }}
        >
          <CenterError
            message={fetchErrorMessage}
            //Communication Error
            title={Resource.get("Communication Error")}
          />
        </div>
      );
    }
    //API Key message
    const PagerDutyAPImessage = Resource.get("PagerDuty generated API key");
    //Service Name message
    const ServicenameMessage = Resource.get("Service name");
    //help message
    const helptxt = Resource.get(
      " Create new PagerDuty incidents when Infrastructure Management generates alarms with the following severities:"
    );
    //Trigger incident creation
    const TriggerCreation = Resource.get("Trigger incident creation");
    //Immediatley
    const ImmediateLabel = Resource.get("Immediatley");
    //After 5 minutes
    const FiveMinMsg = Resource.get("After 5 minutes");
    //After 10 minutes
    const TenMinMsg = Resource.get("After 10 minutes");
    //After 15 minutes
    const FifteenMinMsg = Resource.get("After 15 minutes");
    //After 30 minutes
    const ThirtyMinMsg = Resource.get("After 30 minutes");
    //help message
    const InstructionMsg = Resource.get(
      "Adding a delay may resolve flapping a situation where alarms open and auto-close in a short time frame."
    );
    // Pager Duty Message
    const InstructionMsg1 = Resource.get(
      "To integrate PagerDuty with Infrastructure Management, you must first open an account and generate an API key for use in the configuration window. The following link describes how to generate an API key."
    );
    //Help Message
    const HelpLink = Resource.get("Help");
    //Note message
    const InstructionMsg2 = Resource.get(
      "Note: To enable this functionality, the UIM server must have access to the internet"
    );
    //PagerDuty Integration
    const IntegrationName = Resource.get("PagerDuty Integration");
    return (
      <div className="pager-duty-config__wrapper">
        {this.getGeneralErrorMessageComponent()}
        {this.getDialogComponent()}

        <div className="pager-duty-config__content">
          <div className="pager-duty-config__leftcontent">
            <div className="pager-duty-config__header">
              <IntegrationLogo height="26px" type={TYPE_PAGERDUTY} />
            </div>

            <div className="pager-duty-config__body">
              <div className="pager-duty-config__item">
                <div className="pager-duty-config__input-label">
                  {PagerDutyAPImessage}
                </div>
                <TextField
                  disabled={
                    this.props.state === "EXISTING" || this.props.isSaving
                  }
                  value={this.props.config.apiKey || ""}
                  type="password"
                  //errorStyle={{ marginTop: '6px' }}
                  helperText={IntegrationError.getErrorMessageForKey(
                    IntegrationError.ERROR_PAGERDUTY_APIKEY,
                    this.props.responseError
                  )}
                  onChange={this.handleAPIKeyChange}
                />
              </div>
              <div className="pager-duty-config__item">
                <div className="pager-duty-config__input-label">
                  {ServicenameMessage}
                </div>
                <TextField
                  disabled={
                    this.props.state === "EXISTING" || this.props.isSaving
                  }
                  value={this.props.config.serviceName || ""}
                  //errorStyle={{ marginTop: '6px' }}
                  helperText={IntegrationError.getErrorMessageForKey(
                    IntegrationError.ERROR_PAGERDUTY_SERVICENAME,
                    this.props.responseError
                  )}
                  onChange={this.handleServiceNameChange}
                />
              </div>

              <div className="pager-duty-config__item">
                <div className="pager-duty-config__input-header-label">
                  {helptxt}
                </div>
                <div>
                  <div style={{ paddingLeft: "16px" }}>
                    <div
                      style={{ display: "inline-block", verticalAlign: "top" }}
                    >
                      <AdvancedCheckbox
                        disabled={this.props.isSaving}
                        icon={
                          <SeverityIcon level={LEVEL_CRITICAL} size="24px" />
                        }
                        //Critical
                        label={Resource.get("Critical")}
                        checked={this.getSeverityCheckboxState(LEVEL_CRITICAL)}
                        onCheck={(event, isChecked) => {
                          this.handleSeverityCheck(isChecked, LEVEL_CRITICAL);
                        }}
                      />
                      <AdvancedCheckbox
                        disabled={this.props.isSaving}
                        icon={<SeverityIcon level={LEVEL_MAJOR} size="24px" />}
                        //Major
                        label={Resource.get("Major")}
                        checked={this.getSeverityCheckboxState(LEVEL_MAJOR)}
                        onCheck={(event, isChecked) => {
                          this.handleSeverityCheck(isChecked, LEVEL_MAJOR);
                        }}
                      />
                      <AdvancedCheckbox
                        disabled={this.props.isSaving}
                        icon={<SeverityIcon level={LEVEL_MINOR} size="24px" />}
                        //Minor
                        label={Resource.get("Minor")}
                        checked={this.getSeverityCheckboxState(LEVEL_MINOR)}
                        onCheck={(event, isChecked) => {
                          this.handleSeverityCheck(isChecked, LEVEL_MINOR);
                        }}
                      />
                    </div>
                    <div
                      style={{
                        display: "inline-block",
                        verticalAlign: "top",
                        marginLeft: "48px",
                      }}
                    >
                      <AdvancedCheckbox
                        disabled={this.props.isSaving}
                        icon={
                          <SeverityIcon level={LEVEL_WARNING} size="24px" />
                        }
                        //Warning
                        label={Resource.get("Warning")}
                        checked={this.getSeverityCheckboxState(LEVEL_WARNING)}
                        onCheck={(event, isChecked) => {
                          this.handleSeverityCheck(isChecked, LEVEL_WARNING);
                        }}
                      />
                      <AdvancedCheckbox
                        disabled={this.props.isSaving}
                        icon={<SeverityIcon level={LEVEL_INFO} size="24px" />}
                        //Info
                        label={Resource.get("Info")}
                        checked={this.getSeverityCheckboxState(LEVEL_INFO)}
                        onCheck={(event, isChecked) => {
                          this.handleSeverityCheck(isChecked, LEVEL_INFO);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="pager-duty-config__item">
                <div
                  className="pager-duty-config__input-header-label"
                  style={{ paddingLeft: "16px" }}
                >
                  {TriggerCreation}
                </div>
                <DropDownMenu
                  disabled={this.props.isSaving}
                  value={this.props.config.autoTriggerDelayInSeconds}
                  onChange={this.handleAutoCreateIncidentsDelay}
                >
                  <MenuItem value={0} children={ImmediateLabel} />
                  <MenuItem value={5 * 60} children={FiveMinMsg} />
                  <MenuItem value={10 * 60} children={TenMinMsg} />
                  <MenuItem value={15 * 60} children={FifteenMinMsg} />
                  <MenuItem value={30 * 60} children={ThirtyMinMsg} />
                </DropDownMenu>
                <div
                  className="pager-duty-config__input-label"
                  style={{ paddingLeft: "16px" }}
                >
                  {InstructionMsg}
                </div>
              </div>

              <div className="pager-duty-config__item">
                <Checkbox
                  disabled={this.props.isSaving}
                  checked={this.props.config.autoResolveIncidents}
                  onChange={this.handleResolvePagerDutyIncidentCheck}
                  classes={{ label: labelStyle }}
                  //instruction message
                  label={Resource.get(
                    "When an alarm is closed in Infrastructure Management, resolve the associated incident in PagerDuty."
                  )}
                />
              </div>
            </div>
          </div>
          <div className="pager-duty-config__rightcontent">
            <div className="pager-duty-config__rightcontent__infobox">
              <div className="pager-duty-config__rightcontent__infobox-header">
                <InfoIcon className="pager-duty-config__rightcontent__infobox-header-icon" />
                <span>{IntegrationName}</span>
              </div>
              <div className="pager-duty-config__rightcontent__infobox-content">
                <div>{InstructionMsg1}</div>
                <div>
                  <a href={PAGERDUTY_APIKEY_URL} target="_blank">
                    {PAGERDUTY_APIKEY_URL}
                  </a>
                </div>
                {/* <div>
                  For more information on integrating PagerDuty, see{' '}
                  <a href={PAGERDUTY_HELP_URL} target="_blank">
                  {HelpLink}
                  </a>.
                </div> */}
                {!this.props.saas ? <div>{InstructionMsg2}</div> : null}
              </div>
            </div>
          </div>
        </div>

        <div className="pager-duty-config__footer">
          <div className="pager-duty-config__footer-left">
            <RaisedButton
              variant="contained"
              //DELETE
              children={Resource.get("DELETE")}
              disabled={this.props.state === "NEW"}
              onClick={this.handleDelete}
            />
          </div>

          <div className="pager-duty-config__footer-right">
            <RaisedButton
              variant="contained"
              //CANCEL
              children={Resource.get("CANCEL")}
              disabled={this.props.isSaving}
              onClick={this.handleCancel}
            />

            {this.props.isSaving ? (
              <RaisedButton variant="contained" disabled={true}>
                <CircularProgress style={{color: '#3272D9'}} />
              </RaisedButton>
            ) : (
              <RaisedButton
                variant="contained"
                disabled={this.saveButtonDisabled()}
                //SAVE
                children={Resource.get("SAVE")}
                color="primary"
                onClick={this.handleSave}
              />
            )}
          </div>
        </div>
      </div>
    );
  };
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setBreadCrumbs: (items) => dispatch(setBreadCrumbs(items)),
    fetchConfig: () => dispatch(get()),
    save: (config, successCallback, failureCallback) =>
      dispatch(save(config, successCallback, failureCallback)),
    deletePagerDutyConfig: (config, successCallback, failureCallback) =>
      dispatch(deletePagerDutyConfig(config, successCallback, failureCallback)),
  };
};

const mapStateToProps = (state) => {
  return {
    isValidating: state.pagerDuty.isValidating,
    isFetching: state.pagerDuty.isFetching,
    isSaving: state.pagerDuty.isSaving,
    state: state.pagerDuty.config.state,
    config: state.pagerDuty.mutableConfig,
    responseError: state.pagerDuty.responseError,
    saas: state.saas,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PagerDutyConfig));
