// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Relay Hub Installers
  content["Relay Hub Installers"] =
  "릴레이 허브 설치 관리자";

  //A relay hub sends metrics and alarms data to your instance of Infrastructure Management.
  content["A relay hub sends metrics and alarms data to your instance of Infrastructure Management."] =
  "릴레이 허브가 메트릭 및 경보 데이터를 Infrastructure Management 인스턴스로 보냅니다.";

  //Pick the correct installer for your environment
  content["Pick the correct installer for your environment"] =
  "환경에 적합한 설치 관리자 선택";

  //If you will be monitoring Windows devices or a mix of Windows and Linux devices, use the Windows installer. The Linux relay hub can only monitor Linux servers.
  content["If you will be monitoring Windows devices or a mix of Windows and Linux devices, use the Windows installer. The Linux relay hub can only monitor Linux servers."] =
  "Windows 장치 또는 Windows 및 Linux 장치 조합을 모니터링하려는 경우 Windows 설치 관리자를 사용하십시오. Linux 릴레이 허브는 Linux 서버만 모니터링할 수 있습니다.";

  //If monitoring cloud-based infrastructure, a relay hub is needed for each isolated virtual network (such as AWS Virtual Private Cloud or Azure Virtual Network).
  content["If monitoring cloud-based infrastructure, a relay hub is needed for each isolated virtual network (such as AWS Virtual Private Cloud or Azure Virtual Network)."] =
  "클라우드 기반 인프라를 모니터하는 경우 분리된 각 가상 네트워크(예: AWS 가상 개인 클라우드 또는 Azure 가상 네트워크)에 릴레이 허브가 필요합니다.";

  //Ensure the relay hub can phone home
  content["Ensure the relay hub can phone home"] =
  "릴레이 허브가 집에 전화를 걸 수 있는지 확인";

  //Ports 48000 to 48025 must be open
  content["Ports 48000 to 48025 must be open"] =
  "포트 48000 - 48025가 열려 있어야 합니다.";

  //for the relay hub to communicate with the Infrastructure Management application.
  content["for the relay hub to communicate with the Infrastructure Management application."] =
  "릴레이 허브가 Infrastructure Management 응용 프로그램과 통신하기 위해";

  //The system where you install the relay hub must meet the following minimum requirements:
  content["The system where you install the relay hub must meet the following minimum requirements:"] =
  "릴레이 허브를 설치하는 시스템은 다음의 최소 요구 사항을 충족해야 합니다.";

  //Requirements
  content["Requirements"] =
  "요구 사항";

  //Infrastructure Management supports the same Windows and Linux x64 operating systems that are supported for the on-premises CA UIM product.
  content["Infrastructure Management supports the same Windows and Linux x64 operating systems that are supported for the on-premises CA UIM product. "] =
  "Infrastructure Management는 온-프레미스 CA UIM 제품에 대해 지원되는 동일한 Windows 및 Linux x64 운영 체제를 지원합니다. ";

  //For more information about which operating systems are supported, refer to the
  content["For more information about which operating systems are supported, refer to the"] =
  "지원되는 운영 체제에 대한 자세한 내용은 다음을 참조하십시오.";

  //Compatibility Support Matrix
  content["Compatibility Support Matrix"] =
  "호환성 지원 매트릭스";

  //Issues?
  content["Issues?"] =
  "문제가 있습니까?";

  //If the relay hub does not appear within Infrastructure Management under the appropriate Operating System group after 10 minutes,  there is likely a communication issue.
  content["If the relay hub does not appear within Infrastructure Management under the appropriate Operating System group after 10 minutes, there is likely a communication issue."] =
  "10분 후에도 Infrastructure Management 내에서 적절한 운영 체제 그룹 아래에 릴레이 허브가 나타나지 않는 경우 통신 문제가 있을 가능성이 높습니다.";

  //Make sure the relay hub server has an active Internet connection.
  content["Make sure the relay hub server has an active Internet connection."] =
  "릴레이 허브 서버가 인터넷에 연결되어 있는지 확인합니다.";

  //For more guidance on deploying a relay hub see
  content["For more guidance on deploying a relay hub see"] =
  "릴레이 허브 배포에 대한 자세한 내용은 다음을 참조하십시오.";

  //Install Relay Hub
  content["Install Relay Hub"] =
  "릴레이 허브 설치";

  // Download and install a relay hub
  content["Download and install a relay hub"] =
  "릴레이 허브 다운로드 및 설치";

  //4 GB of available memory
  content["4 GB of available memory"] =
  "4GB의 사용 가능한 메모리";

  //2 or more CPUs
  content["2 or more CPUs"] =
  "2개 이상의 CPU";

  //10 GB of available disk space
  content["10 GB of available disk space"] =
  "10GB의 사용 가능한 디스크 공간";

  //Valid external IP address for the system
  content["Valid external IP address for the system"] =
  "시스템의 올바른 외부 IP 주소";

  //Ability to connect to the Internet
  content["Ability to connect to the Internet"] =
  "인터넷 연결 기능";

  //HELP
  content["HELP"] =
  "도움말";

  //Help
  content["Help"] =
  "도움말";

  // END OF LOCALIZATION

export default content;
