import config from './../config'
import axios from 'axios'

const URL = `${config.basename}/api/v1/app-discovery/profiles`
class Discovery {
  // *LIMITATION*: This api only supports the following type params: unix, windows, apache.
  //   Api changes will be required to enable other probes.
  get(params) {
    return axios.get(URL, { params })
  }
  post(formData) {
    axios.post(URL, formData)
  }
}

let discovery = new Discovery()
export default discovery
