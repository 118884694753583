// import { Button } from '@mui/material'
// import { Button, withStyles } from '@mineral/core'
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button,IconButton,Menu,MenuItem } from "@mineral/core";
import { get } from "lodash";
import Topology from "./../icons/Topology";
import GridIcon from "./../icons/GridIcon";
import ListIcon from "./../icons/ListIcon";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import {NavLink, Route} from "react-router-dom";
import entityTypes from "../../api/entityTypes";
import {
  deleteGroupDialog,
  toggleAddDeviceToGroup,
  toggleAddGroupDialog,
  toggleEditSubGroup,
} from "../../api/treeView/actions";
import GroupPath from "../groups/secondaryNav/controls/GroupPath";
import "./treeContainer.less";
import ToggleIconsRedux from "../groups/secondaryNav/toggleIcons/ToggleIconsRedux";
import EntityTypes from "./../../api/entityTypes";
// const DeleteButton = withStyles((theme) => ({
//   root: {
//     color: 'white',
//     backgroundColor: '#DE1B1B',
//     '&:hover': {
//       backgroundColor: '#bf2222',
//     },
//   },
// }))(Button)

const Link = ({to, entityType, isDisabled, title, children}) => {
  isDisabled =
    isDisabled !== undefined ? isDisabled : entityType !== EntityTypes.GROUP;
  let link = (
    <NavLink
      to={to}
      className="link"
      activeclassname="link--active"
      title={title}
    >
      {children}
    </NavLink>
  );
  if (isDisabled) {
    link = (
      <span className="link nav-icon--disabled" title={title}>
        {children}
      </span>
    );
  }
  return link;
};

const GroupsMainHeader = (props) => {
  const dispatch = useDispatch();
  const allGroups = useSelector((state) =>
    state.tree_view.groups[0] ? state.tree_view.groups[0].children : []
  );
  const entity = useSelector((state) => state.entity);
  const stateDataGroups = useSelector(
    (state) => state.tree_view.stateData.Groups
  );
  const entityType = useSelector((state) => state.entity.type);
  const treeView = useSelector((state) => state.tree_view);
  const acl = useSelector((state) => state.acl);
  const entities = useSelector((state) => state.entities);

  const canModifyGroup = get(acl.aclInfo, "roles", "").includes(
    "USM Group Modification"
  );
  const canAddGroup = get(acl.aclInfo, "roles", "").includes("OC Group Add");
  const canEditGroup = get(acl.aclInfo, "roles", "").includes("OC Group Edit");
  const canRemoveGroup = get(acl.aclInfo, "roles", "").includes(
    "OC Group Delete"
  );
  const selectedNodeType = window.location.pathname.endsWith("/treeviewnew")
    ? treeView.treeNode.entityType
    : entityType;
  const groupType = window.location.pathname.endsWith("/treeviewnew")
    ? selectedNodeType
    : entityType;
  let parentGroupType = window.location.pathname.endsWith("/treeviewnew")
    ? treeView.treeNode.parentType
    : "";
  let childrenType = window.location.pathname.endsWith("/treeviewnew")
    ? treeView.treeNode.childrenType
    : "";

  if (!window.location.pathname.endsWith("/treeviewnew")) {
    parentGroupType = "EMPTY";
    childrenType = "";
    if (entityType === entityTypes.CONTAINER && entities.length > 0) {
      childrenType = entities[0].type;
      if (childrenType === "AUTOMATIC") {
        parentGroupType = "AUTOMATIC";
      } else if (
        childrenType === "DYNAMIC" ||
        childrenType === "STATIC" ||
        childrenType === "CONTAINER"
      ) {
        parentGroupType = "NON_REMOVABLE";
      }
    }
  }

  const groupPathStyles = {
    fontFamily: "'Open Sans', sans-serif",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "22px",
    lineHeight: "28px",
    color: "#333840",
    paddingLeft: "16px",
    marginTop:"10px",
    marginBottom:"10px",
    
  };

  return (
    <div className="groups-main-header" style={{display:'flex',flexDirection:'row',    width: '96.5%',
    marginLeft: '67px',
    backgroundColor: '#FFFFFF',borderBottom: '1px solid #DDE3ED'}}>
      <div className="group-path">
        {/*!window.location.pathname.search("treeviewnew") > -1 ? (*/}
          <GroupPath
            allGroups={allGroups}
            entity={entity}
            stateDataGroups={stateDataGroups}
            groupPathStyles={groupPathStyles}
          />
        {/*) : (
          <h2 style={groupPathStyles}>Groups</h2>
        )}*/}
      </div>
      <div className="button-group">
        <ToggleIconsRedux isFromGroupsMainHeader  view={props.view} tabValue={props.tabValue}/>
        {(canModifyGroup || canAddGroup) &&
        (groupType === entityTypes.CONTAINER ||
          groupType === entityTypes.ROOT) &&
        !(
          parentGroupType === entityTypes.AUTOMATIC &&
          childrenType === entityTypes.AUTOMATIC
        ) 
        //&&
        //!window.location.pathname.endsWith("/alarms") &&
        //!window.location.pathname.endsWith("/dashboard") &&
        //!window.location.pathname.endsWith("/configuration") 
        ? (
          <Button style={{marginRight:'8px',width:'200px'}}
            onClick={() => {
              dispatch(toggleAddGroupDialog(true));
            }}
            
            variant="outlined"
            color="primary"

          
          >
            Add Group
          </Button>
        ) : null}

        {(canModifyGroup || canEditGroup) &&
        groupType !== entityTypes.STATIC &&
        groupType !== entityTypes.ROOT &&
        groupType !== entityTypes.AUTOMATIC 
        &&
        !window.location.pathname.endsWith("/groups/0") &&
        !window.location.pathname.endsWith("/groups/0/alarms") &&
        !window.location.pathname.endsWith("/groups/0/dashboard") &&
        !window.location.pathname.endsWith("/groups/0/card"        )
         ? (
          <Button style={{marginRight:'8px',width:'200px'}}
            onClick={() => {
              dispatch(toggleEditSubGroup(true));
            }}
            
            variant="outlined"
            color="primary"
          >
            Edit Group
          </Button>
        ) : null}

        {(canModifyGroup || canEditGroup) &&
        groupType !== entityTypes.CONTAINER &&
        groupType !== entityTypes.ROOT &&
        groupType === entityTypes.STATIC ? (
          <Button style={{marginRight:'8px',width:'200px'}}
            onClick={() => {
              dispatch(toggleAddDeviceToGroup(true));
            }}
            
            variant="outlined"
            color="primary"
          >
            Edit Group
          </Button>
        ) : null}

        {(canModifyGroup || canRemoveGroup) &&
        groupType !== entityTypes.ROOT &&
        groupType !== entityTypes.AUTOMATIC &&
        parentGroupType !== "NON_REMOVABLE" &&
        !(
          parentGroupType === entityTypes.AUTOMATIC &&
          childrenType === entityTypes.AUTOMATIC
        )
         &&
       !window.location.pathname.endsWith("/groups/0") &&
        !window.location.pathname.endsWith("/groups/0/alarms") &&
        !window.location.pathname.endsWith("/groups/0/cabi") &&
        !window.location.pathname.endsWith("/groups/0/card") 
       ? (
          <Button style={{marginRight:'8px',width:'200px'}}
            onClick={() => {
              dispatch(deleteGroupDialog(true));
            }}
            
            variant="outlined"
            color="secondary"
          >
            Delete Group
          </Button>
        ) : null}
      </div>
    </div>
  );
};

export default GroupsMainHeader;
