import React, {Component} from 'react'
import {
  ErrorIcon as Information,
} from '../ui-components/uim-components'
import {
  MenuItem, Select as SelectField, FormControl, InputLabel,FormHelperText,Grid
} from "@mineral/core"
import connect from './../../utils/connect'
import {requestHubs} from './../../api/hubs/actions'
import Resource from './Resource'

//Devices added must be able to communicate with the selected  hub.
let deviceMsg = Resource.get('Devices added must be able to communicate with the selected  hub.');// with the selected hub
//Ports 48000 to 48025 must be open
let portMsg = Resource.get('Ports 48000 to 48025 must be open');
//for the  hub to communicate with the CA Infrastructure Management application.
let relayMsg = Resource.get('for the  hub to communicate with the CA Infrastructure Management application.');
//If monitoring cloud-based infrastructure, a  hub is needed for each isolated virtual network (such as AWS Virtual Private Cloud or Azure Virtual Network).
let cloudMsg = Resource.get('If monitoring cloud-based infrastructure, a  hub is needed for each isolated virtual network (such as AWS Virtual Private Cloud or Azure Virtual Network).');
//Ensure that the
let devMsg = Resource.get('Ensure that the ');
//If you are configuring monitoring technologies supported only on Windows, such as Windows NT Services, select a Windows  hub.
let windowsRelayHubMsg=Resource.get('If you are configuring monitoring technologies supported only on Windows, such as Windows NT Services, select a Windows  hub.');


class HubSelect extends Component {
  state = {
    value: "select",
  }
  componentWillMount() {
    if (this.props.getHubs) {
      this.props.getHubs()
    }
    if(this.props.hub!==null){
      
      for(var i = 0; i < this.props.hubs.length; i++) {
          if (this.props.hubs[i].hubRobotName == this.props.hub.hubRobotName) {
            this.setState({value:this.props.hub.hubRobotName});
              break;
          }
      }
    }
  }
  componentWillReceiveProps(nextProps) {
    let hubs = nextProps.hubs
    if (hubs.length && !this.state.value) {
      this.setState({value: hubs[0].hubRobotName})
    }
    if (hubs.length && !this.state.value && this.props.onChange) {
      // trigger change on initial state update so listeners will be updated
      this.props.onChange(hubs[0])
    }
  }
  handleChange = (event,index) => {
    let value = event.target.value;
    let hub = value;

    if (hub !== 'add_hub' && hub !== 'select') {
     let selectedHubDetails= this.props.hubs.find(data=>data.hubRobotName==hub);
      hub = selectedHubDetails;
    }
    if (hub === 'select'){
      hub = null
    }
    if (this.props.onChange) {
      this.props.onChange(hub)
    }
    return this.setState({value})
  }

  render() {
    // commented this line because in mysql DB the windows hub are not visiable 
	  // let windowhubs=[];
	  // if(this.props.supportedOs=="windows" && this.props.hubs){
		//   windowhubs = this.props.hubs.filter(function (el) {
	  //   	 return el.osMajor.toLowerCase()=="windows";
	  //    });
	  //    this.props.hubs= windowhubs;
    // } 


    const requiredLabel= Resource.get("Required"); //Required

    let hubs; 
    hubs = this.props.hubs || [];
    const hubItems = hubs.map((hub, idx) => {
      return <MenuItem value={hub.hubRobotName} children={hub.hubRobotName + " (" + hub.hubIp + ") "} key={idx}/>
    })
    return (
      
      <Grid container spacing={2}>
      <Grid item xs={2}>
        <FormControl fullWidth={true}>
        <InputLabel htmlFor="hub-select" id="hub-select-label" >Select Hub<span className="credentialvault-required-label">{requiredLabel}</span></InputLabel>
        <SelectField role="combobox"
        variant="outlined"
        
        id= "hub-select" 
        aria-label={"Select Hub"}
          aria-labelledby="hub-select-label"
          inputProps={{'aria-label':"Select Hub",
                                role: 'combobox',
                                'aria-required':true}}
        //helperText={(this.state.value === 'select' || this.state.value === 'add_hub')?Resource.get('*No hub selected'):''} // No hub selected
        value={this.state.value}
          onChange={this.handleChange}
          fullWidth
        >          
          <MenuItem value="select" 
        	//Select a hub
          children= {Resource.get('Select a hub')}  />
        {hubItems}
          {/* <Divider />
          
          <MenuItem value="add_hub" disabled={this.props.location.pathname !=='/getting-started'}
        //ADD RELAY HUB MESSAGE
          children= {Resource.get('ADD  HUB...')} // ADD  HUB...
          style={{color: this.props.location.pathname !=='/getting-started' ? '#00000061' : '#00AEEF', fontFamily: 'roboto_condensed_bold,Arial,sans-serif', fontSize: '14px', marginTop: '1.5rem'}}/> 
           */}
      </SelectField>
      
      <FormHelperText style={{color:'#B80D0D'}} error={true}>{//No hub selected
      (this.state.value === 'select' || this.state.value === 'add_hub')?
           Resource.get('*No hub selected'):''} 
      </FormHelperText>
      </FormControl>
      </Grid>
      <Grid item xs={10}>
        <div className='installScreen__hub-select-info'>
        <Information className='installScreen__hub-select-notice-icon'/>
  	      <p className='installScreen__information-title'>{windowsRelayHubMsg}</p><br/> 
          <Information className='installScreen__hub-select-notice-icon'/>
          <p className='installScreen__information-title'>{deviceMsg}</p>
          <p style={{paddingTop: '10px'}}>{devMsg} <b>{portMsg}</b> {relayMsg}</p>
          <p>{cloudMsg}</p>
        </div>
        </Grid>
        </Grid>
     
    )
  }
}

const mapStateToProps = (state) => {
  return {
    hubs: state.hubs.items,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    getHubs: () => dispatch(requestHubs()),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(HubSelect)
