// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Home
  content["Home"] =
  "Home";

  //Alarms
  content["Alarms"] =
  "Alarms";

  //Groups
  content["Groups"] =
  "Groups";

  //Inventory
  content["Inventory"] =
  "Inventory";

  //Dashboards
  content["Dashboards"] =
  "Dashboards";

  //Reports
  content["Reports"] =
  "Reports";

  //SLM
  content["SLM"] =
  "SLM";

  //Settings
  content["Settings"] =
  "Settings";

  //Setup Wizard
  content["Setup Wizard"] =
  "Setup Wizard";

  //Community
  content["Community"] =
  "Community";

  //Help
  content["Help"] =
  "Help";

  // END OF LOCALIZATION

export default content;
