import axios from "axios";


import config from './../config'





class Reports {
  static REPORTS_BASE_URI = "/public/ca/uim/reports/library";

  get(path, cabiInfo = {}) {
    if (path == "customreports") {
      const userUrl = `/cabi/api/user-reports`;
      const postbody = {
        jaspertoken: cabiInfo.token,
        orgidl: cabiInfo.orgidl,
      };
      let promise = new Promise((resolve, reject) => {
        
          axios.post(userUrl, postbody).then(
            (data1) => {
              const customReports = data1.data;
              const reports = [];
              customReports.forEach((item) => {
                const reportUnitPath = item.uri;
                // Derive the parent folder from the full reportUnitPath
                const reportParent = reportUnitPath.split("/").pop().split("/");
                item.reportURL =
                  cabiInfo.address +
                  "/flow.html?_flowId=viewReportFlow&decorate=no&standAlone=true" +
                  "&ParentFolderUri=" +
                  encodeURIComponent(reportParent) +
                  "&reportUnit=" +
                  encodeURIComponent(reportUnitPath);

                item.scheduleURL =
                  cabiInfo.address +
                  "/scheduler/main.html?" +
                  "reportUnitURI=" +
                  encodeURIComponent(reportUnitPath) +
                  "&resourceType=ReportUnit" +
                  "&parentReportUnitURI=" +
                  "#" +
                  reportUnitPath;
                reports.push(item);
              });
              let data = {
                data: {
                  resourceLookup: reports,
                },
              };
              resolve(data);
            },
            (error) => {
              reject(error);
            }
          )
       
      });
      return promise;
    } else {
      const folderUri = path
        ? `${Reports.REPORTS_BASE_URI}/${path}`
        : Reports.REPORTS_BASE_URI;
      const fullUrl = `/cabi/api/folder-info`;
      const postbody = {
        folderUri: folderUri,
        jaspertoken: cabiInfo.token,
        orgidl: cabiInfo.orgidl,
      };
      let promise = new Promise((resolve, reject) => {
        
          axios.post(fullUrl, postbody).then(
            (data) => {
              const folderResources = data.data.resourceLookup;
              // augment any reports with a redirect url
              folderResources.forEach((item) => {
                if (item.resourceType === "reportUnit") {
                  const reportUnitPath = item.uri;
                  // Derive the parent folder from the full reportUnitPath
                  const reportParent = reportUnitPath
                    .split("/")
                    .pop()
                    .split("/");
                  item.reportURL =
                    cabiInfo.address +
                    "/flow.html?_flowId=viewReportFlow&decorate=no&standAlone=true" +
                    "&ParentFolderUri=" +
                    encodeURIComponent(reportParent) +
                    "&reportUnit=" +
                    encodeURIComponent(reportUnitPath);

                  item.scheduleURL =
                    cabiInfo.address +
                    "/scheduler/main.html?" +
                    "reportUnitURI=" +
                    encodeURIComponent(reportUnitPath) +
                    "&resourceType=ReportUnit" +
                    "&parentReportUnitURI=" +
                    "#" +
                    reportUnitPath;
                }
              });
              //making a call to get custom reports
              if (Reports.REPORTS_BASE_URI === folderUri) {
                const userUrl = `/cabi/api/user-reports`;
                const postbody = {
                  jaspertoken: cabiInfo.token,
                  orgidl: cabiInfo.orgidl,
                };
                axios
                  .post(userUrl, postbody)
                  .then((data1) => {
                    const customReports = data1.data;
                    if (customReports != undefined && (Array.isArray(customReports) && customReports.length!=0)) {
                      let customReportFolder = {
                        description: "",
                        inputControls: null,
                        label: "Custom Reports",
                        reportCount: customReports.length,
                        resourceType: "folder",
                        uri: "/public/ca/uim/reports/library/customreports",
                      };
                      data.data.resourceLookup.push(customReportFolder);
                    }

                    resolve(data);
                  })
                  .catch((error) => {
                    resolve(data);
                  });
              } else {
                resolve(data);
              }
            },
            (error) => {
              reject(error);
            }
          )
        
      });

      return promise;
    }
  }

  getSlaQosData(){
    return axios.get([config.basename, 'api/getQosReport'].join('/'));
  }
}

let reports = new Reports();
export default reports;
