import {
  FETCH_DEVICE_GROUPS,
  FETCH_DEVICE_GROUPS_SUCCESS,
  FETCH_DEVICE_GROUPS_FAILURE,  
} from "./actionTypes";

const devicegroups = (
  state = {
    isFetching: false,
    didInvalidate: false,
    isFetchingAll: false,
    didInvalidateFetchAll: false,
    errorInFetchingAll: "",
    isFetchingDvc: false,
    didInvalidateDvc: false,
    errorMessageDvc: "",
    dvcGroupsInfo: {},
    status:"",
  },
  action
) => {
  switch (action.type) {
  
    case FETCH_DEVICE_GROUPS:
      return Object.assign({}, state, {
        isFetchingDvc: true,
        didInvalidateDvc: false,
        status: "loading",
      });
    case FETCH_DEVICE_GROUPS_SUCCESS:
      return Object.assign({}, state, {
        isFetchingDvc: false,
        didInvalidateDvc: false,
        dvcGroupsInfo: action.dvcGroupsInfo,
        status: "loaded",
      });
    case FETCH_DEVICE_GROUPS_FAILURE:
      return Object.assign({}, state, {
        isFetchingDvc: false,
        didInvalidateDvc: true,
        errorMessageDvc: action.errorMessage,
        status: "failure",
      });
    default:
      return state;
  }
};

export default devicegroups;
