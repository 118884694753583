// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //debian based systems message
  content["Only 64 bit package is allowed for debian based systems."] =
  "Only 64 bit package is allowed for debian based systems.";

  //Sudo Password is only applicable for Linux Systems
  content["Sudo Password is only applicable for Linux systems where username is not \'root\'."] =
  "Sudo Password is only applicable for Linux systems where username is not \'root\'.";

  //Windows Relay Hub Required
  content["Windows Relay Hub Required."] =
  "Windows Relay Hub Required.";

  //IP Address Required
  content["IP Address Required"] =
  "IP Address Required";

  //Remote monitoring (also known as agentless monitoring) requires a monitoring host. The monitoring host is responsible for collecting data from the remote device that you are monitoring.
  content["Remote monitoring (also known as agentless monitoring) requires a monitoring host. The monitoring host is responsible for collecting data from the remote device that you are monitoring."] =
  "Remote monitoring (also known as agentless monitoring) requires a monitoring host. The monitoring host is responsible for collecting data from the remote device that you are monitoring.";

  //Monitoring host server
  content["Monitoring host server"] =
  "Monitoring host server";

  //host
  content["host"] =
  "host";

  //Deploy a monitoring
  content["Deploy a monitoring"] =
  "Deploy a monitoring";

  //Recommended minimum system requirements
  content["Recommended minimum system requirements"] =
  "Recommended minimum system requirements";

  //Home
  content["Home"] =
  "Home";

  //Docker engine message
  content["Docker engine"] =
  "Docker engine";

  //agent message
  content["agent"] =
  "agent";

  //Docker monitoring requires an agent (aka Robot) to be installed on the Docker Engine server.
  content["Docker monitoring requires an agent (aka Robot) to be installed on the Docker Engine server."] =
  "Docker monitoring requires an agent (aka Robot) to be installed on the Docker Engine server.";

  //IP address
  content["IP address"] =
  "IP address";

  // Operating systems message
  content["Operating system"] =
  "Operating system";

  //Architecture
  content["Architecture"] =
  "Architecture";

  //64-bit
  content["64-bit"] =
  "64-bit";

  //32-bit
  content["32-bit"] =
  "32-bit";

  // System Credentials
  content["System Credentials"] =
  "System Credentials";

  //Username
  content["Username"] =
  "Username";

  //Password
  content["Password"] =
  "Password";

  //Sudo Password (Linux only)
  content["Sudo Password (Linux only)"] =
  "Sudo Password (Linux only)";

  //Instructions
  content["Instructions"] =
  "Instructions";

  //4 GB of available memory
  content["4 GB of available memory"] =
  "4 GB of available memory";

  //2 or more CPUs
  content["2 or more CPUs"] =
  "2 or more CPUs";

  //10 GB of available disk space
  content["10 GB of available disk space"] =
  "10 GB of available disk space";

  //Ports 48000 to 48025 open
  content["Ports 48000 to 48025 open"] =
  "Ports 48000 to 48025 open";

  // END OF LOCALIZATION

export default content;
