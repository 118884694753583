// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Homestub
  content["Home"] =
  "홈";

  //Home
  content["breadcrumb"] =
  "홈";

  // END OF LOCALIZATION

export default content;
