import {
  CLOSE_WALK_THROUGH,
  OPEN_WALK_THROUGH,
} from './actionTypes'

const walkThrough = (state = {
  isOpen: true
}, action) => {
  switch (action.type) {
    case OPEN_WALK_THROUGH:
      return Object.assign({}, state, {
        isOpen: true,
      })
    case CLOSE_WALK_THROUGH:
      return Object.assign({}, state, {
        isOpen: false,
      })
    default:
      return state
  }
};

export default walkThrough
