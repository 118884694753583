import connect from './../../utils/connect'
import {get as fetchConfig} from './actions'

let loaded = false
const ConfigApi = (props) => {
  // load flat config.JSON file if not already loaded
  if (!loaded) {
    loaded = true
    props.getConfig()
  }
  return null
}

const mapDispatchToProps = (dispatch) => {
  return {
    getConfig: () => dispatch(fetchConfig()),
  }
}
export default connect(null, mapDispatchToProps)(ConfigApi)