// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  // instructions message
  content["To add additional monitoring to this device, a local robot must be installed"] =
  "To add additional monitoring to this device, a local robot must be installed";

  // instructions message1
  content["Use the Setup Wizard to add local monitoring based on a device\'s operating system: Windows or Linux, and select manual installation."] =
  "Use the Setup Wizard to add local monitoring based on a device\'s operating system: Windows or Linux, and select manual installation.";

  //Local robot required
  content["Local robot required"] =
  "Local robot required";

  //Cancel
  content["Proceed"] =
  "Proceed";

  //Add devices...
  content["Add Robots..."] =
  "Add Robots...";

  // instruction message
  content["To add additional monitoring to the devices in this group, local robots must be installed"] =
  "To add additional monitoring to the devices in this group, local robots must be installed";

  //  instruction message1
  content["Use the Setup Wizard to add local monitoring based on a device\'s operating system: Windows or Linux."] =
  "Use the Setup Wizard to add local monitoring based on a device\'s operating system: Windows or Linux.";

  // END OF LOCALIZATION

export default content;
