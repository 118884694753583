import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import connect from "./../../../utils/connect";
import GroupDialogHeader from "../GroupDialogHeader";
import {
  Dialog,
  DialogTitle,
  DialogActions,Typography,DialogContent,
  Button as FlatButton,Tooltip,IconButton
} from "@mineral/core";
import Resource from "./Resource";
import "./dynamicGroupDialog.less";
import MatchingDevices from "./MatchingDevices";
import groupsApi from "./../../../api/groups/groups";
import arrow_down from "../../../assets/icons/arrow_down.svg";
import Group from "./Group";
import { requestGroup } from "./../../../api/groups/actions";
import Criteria from "./criteria";
import {CircularProgress} from "@mineral/core";
import { refreshTree } from "./../../../api/treeView/actions";
import entityTypes from "./../../../api/entityTypes";
import AddDeviceToGroupDialogRedux from "../AddDeviceToGroupDialogRedux";
import isEqual from "lodash/isEqual";
import group from "./../../../api/groups/groups";
import { setBreadCrumbs } from "./../../breadcrumbs/actions";
import {
  CenterError as Error,
  ClearIcon as Clear,
} from "../../ui-components/uim-components";

import CustomSnackbar from '../../common/CustomSnackbar';
const isInterfaceContainer = (entity) => {
  return (
    entity &&
    entity.type !== entityTypes.ROOT &&
    entity.type !== entityTypes.CONTAINER &&
    entity.masterElementGroup
  );
};

class DynamicGroupDialog extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
  };
  constructor(props) {
    super(props);
    this.node = undefined;
    this.state = {
      showParentTree: false,
      accountName: "",
      accountInfoList: [],
      criteriaRef: null,
      matchingDevicesInfo: this.initializeMatchingDevicesContext(),
      showErrorMsg: false,
      errorMsg: "",
      selectedGroup: null,
      isSaving: false,
      show: true,
      path: "/groups/0",
      groups: [],
      parentId:
        props.mode == "ADD Group" || props.mode == "ADD"
          ? this.props.entity.id
          : this.props.entity.parentId,
      isDynamicChecked: false,
      saveError: null,
      addButtonDisabled:
        props.mode == "ADD Group" || props.mode == "ADD" ? true : false,
      selectedDevices: [],
      unselectedDevices: [],
      defaultCaseUpdated: false,
      groupDetails: {
        accountId:
          props.entity.accountId == null
            ? -1
            : parseInt(props.entity.accountId),
        elementType:
          props.mode == "ADD Group" || props.mode == "ADD"
            ? "DEVICES"
            : isInterfaceContainer(props.entity) //props.isInterfaceGroup
            ? "INTERFACES"
            : "DEVICES",
        type:
          props.entityType == "AUTOMATIC" ? "AUTOMATIC" :
          props.entityType == "STATIC"
            ? "STATIC"
            : props.mode == "ADD Group" ||
              ((props.mode == "ADD" || props.mode == "EDIT") &&
                props.entityType == "DYNAMIC")
            ? "DYNAMIC"
            : "CONTAINER",
        name:
          props.mode == "ADD Group" || props.mode == "ADD"
            ? ""
            : props.entity.name,
        description:
          props.mode == "ADD Group" || props.mode == "ADD"
            ? ""
            : props.entity.description
            ? props.entity.description
            : "",
      },

      groupErrorMsg: "",
    };
  }

  initializeMatchingDevicesContext = () => {
    let obj = {
      matchingDevices: [],
      error: null,
      updatingFromCriteria: false,
    };
    return obj;
  };
  getItem = (id, items) => {
    //recursive find operations don't work as expected(it returns the parent object instead of the child object when found)
    //so we use a recursive for loop
    let item = {};
    if (items && items.length) {
      for (let i = 0; i < items.length; i++) {
        if (parseInt(items[i].id, 10) === parseInt(id, 10)) {
          //this.setState({ selectedGroup: items[i] })
          return items[i];
        }
        if (items[i].groups) {
          item = this.getItem(id, items[i].groups);
          if (item) {
            return item;
          }
        }
      }
    }
  };
  getGroups = () => {
    groupsApi
      .getAllGroups()
      .then((response) => {
        const rootGroup = {
          id: "0",
          parentId: "-1",
          name: "Groups",
          description: "",
          type: "CONTAINER",
          show: true,
          path: "/groups",
        };
        const displayedGroups = response.data._items.filter(
          (group) => parseInt(group.parentId, 10) === -1
        );
        if (displayedGroups.length === 0) {
          response.data._items.push(rootGroup);
        }

        this.setState({
          groups: response.data._items,
          isLoading: false,
        });
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
          error: err,
        });
      });
  };
  handleGroup = (group) => {
    if (!group) {
      this.handleClickOutside("close");
      return;
    }
    if (this.props.mode === "ADD Group" || this.props.mode === "ADD") {
      this.setState({
        selectedGroup: group,
        showErrorMsg: false,
        errorMsg: "",
      });
      if (group !== undefined) {
        this.handleClickOutside("close");
      }
    } else {
      if (
        (group.id !== "0" &&
          this.state.groupDetails.accountId === -1 &&
          group.accountId !== "nimbus account") ||
        (group.id !== "0" &&
          this.state.groupDetails.accountId !== -1 &&
          group.accountId !== this.state.groupDetails.accountId.toString())
      ) {
        //Please select parent of same account
        let msg = Resource.get("Please select parent of same account");
        this.setState({
          showErrorMsg: true,
          errorMsg: msg,
        });
      } else {
        this.setState({
          selectedGroup: group,
          showErrorMsg: false,
          errorMsg: "",
        });
      }
      if (group !== undefined) {
        this.handleClickOutside("close");
      }
    }
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.open !== this.props.open && nextProps.open) {
      this.getGroups();
      let groupDetails = {
        accountId:
          nextProps.entity.accountId == null
            ? -1
            : parseInt(nextProps.entity.accountId),
        elementType:
          nextProps.mode == "ADD Group" || nextProps.mode == "ADD"
            ? "DEVICES"
            : isInterfaceContainer(nextProps.entity) //nextProps.isInterfaceGroup
            ? "INTERFACES"
            : "DEVICES",
        type:
          nextProps.entityType == "STATIC"
            ? "STATIC"
            : nextProps.mode == "ADD Group" ||
              ((nextProps.mode == "ADD" || nextProps.mode == "EDIT") &&
                nextProps.entityType == "DYNAMIC")
            ? "DYNAMIC"
            : "CONTAINER",
        name:
          nextProps.mode == "ADD Group" || nextProps.mode == "ADD"
            ? ""
            : nextProps.entity.name,
        description:
          nextProps.mode == "ADD Group" || nextProps.mode == "ADD"
            ? null
            : nextProps.entity.description
            ? nextProps.entity.description
            : this.state.groupDetails.description
            ? this.state.groupDetails.description
            : null,
      };
      if (!isEqual(groupDetails, this.state.groupDetails)) {
        groupDetails = { ...this.state.groupDetails };
        this.setState({
          parentId:
            nextProps.mode == "ADD Group" || nextProps.mode == "ADD"
              ? nextProps.entity.id
              : nextProps.entity.parentId,
          saveError: null,
          groupDetails: groupDetails,
        });
      }
    }
    if (
      nextProps.groupErrorMsg != null &&
      nextProps.groupErrorMsg != undefined
    ) {
      this.setState({ groupErrorMsg: nextProps.groupErrorMsg });
    }
  }
  componentDidMount() {
    this.getGroups();
    groupsApi
      .getAccountsInfo()
      .then((response) => {
        this.setState({ accountInfoList: response.data });
      })
      .catch((error) => {
        //console.log(error);
      });
    document.addEventListener("mousedown", this.handleClickOutside, false);
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside, false);
  }
  //When the user clicks anywhere outside of the modal, close it
  handleClickOutside = (event, action) => {
    if (
      (this.node && !this.node.contains(event.target)) ||
      action === "close"
    ) {
      this.node.style.display = "none";
    }
  };
  getErrorMsg = () => {
    let classNames = ["dynamic-group__info"];
    //Error
    let msg1 = Resource.get("Error"),
      msg2 = this.state.errorMsg;
    msg2 = this.state.errorMsg
      ? msg2.replace("Unknown SQL exception", "Invalid Sql Query ")
      : "";
    if (!this.state.showErrorMsg) {
      classNames.push("hide");
    }
    return (
      <div style={{ position: "relative" }} className={classNames.join(" ")}>
        <div
          style={{
            width: "calc(100% - 60px)",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          <div className="dynamic-group__contentImg"></div>
          <span className="dynamic-group__content">
            <span
              style={{
                padding: "2px 13px 2px 20px",
                borderRight: "2px solid #999999",
              }}
            >
              {msg1}
            </span>
            <span style={{ paddingLeft: "9px" }}>{msg2}</span>
          </span>
        </div>
        <div
          className="dynamic-group__content_error_Close"
          onClick={() => {
            this.setState({ showErrorMsg: false, errorMsg: "" });
          }}
        ></div>
      </div>
    );
  };

  getParentId = () => {
    if (this.props.mode == "ADD Group" || this.props.mode == "ADD") {
      if (
        this.props.entity.type === entityTypes.CONTAINER &&
        Array.isArray(this.props.entity.attributes) && 
        this.props.entity.attributes.indexOf("AUTO_PARENT") === -1
      ) {
        return this.props.entity.id
      } else {
        return "0"
      }
    } else {
      return this.props.entity.parentId;
    }
  }

  render() {
    let deviceCriteria = {
      isCsFilter: true,
      criteriaOperator: "AND",
      subCriteria: [
        {
          isCsFilter: true,
          negated: false,
          attribute: "DisplayName",
          filterOperator: "EQUALS",
          value: ["null"],
        },
      ],
    };
    let interfaceInnerCriteria = {
      isCsFilter: false,
      criteriaOperator: "AND",
      subCriteria: [
        {
          isCsFilter: false,
          negated: false,
          attribute: "DisplayName",
          filterOperator: "CONTAINS",
          value: [""],
        },
      ],
    };
    let combineCriteria = {
      criteriaOperator: "AND",
      isCsFilter: false,
      subCriteria: [interfaceInnerCriteria, deviceCriteria],
    };
    let name =
      this.state.groupDetails.name != null ? this.state.groupDetails.name : "";

    const groups = this.state.groups;
    let parentId = this.getParentId();
    let group = this.state.selectedGroup
      ? this.state.selectedGroup
      : this.getItem(parentId, groups);

    const header = "Configure " + name;
    const actions = [
      <FlatButton
        variant="text"
        //Cancel
        children={Resource.get("Cancel")}
        color="primary"
        onClick={this.onClose}
      />,
      <FlatButton
        variant="contained"
        //Save
        children={
          this.state.groupDetails.type == "AUTOMATIC" ?
            Resource.get("Ok") :  // Ok
            Resource.get("Save")  // Save
        }
        disabled={this.state.addButtonDisabled}
        color="primary"
        onClick={
          this.state.groupDetails.type == "AUTOMATIC" ?
            this.onClose :
            this.props.mode === "ADD Group" || this.props.mode == "ADD"
              ? this.handleAddGroup
              : this.handleSave
        }
      />,
    ];

    let topSection = (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "calc(100% - 4px)",
          marginLeft:'2px',
          marginRight:'2px',
          minHeight:this.state.groupDetails.type==='CONTAINER'?'250px':'0px',
          boxSizing: "border-box",
         
        }}
      >
        <GroupDialogHeader
          onGroupDetailsChanged={this.onGroupDetailsChanged}
          {...this.props}
          handleGroup={this.handleGroup}
          selectedGroup={group}
          groupDetails={this.state.groupDetails}
          onNodeSelected={(node) => {
            this.node = node;
          }}
          onParentClick={this.onParentClick}
        />
           <CustomSnackbar severity={'success'}
          open={this.state.showSuccessMsg}
          message={this.state.successMsg}
          autoHideDuration={4000}
          
          close={()=>{this.setState({showSuccessMsg:false,successMsg:''})}}
        />      
      </div>
    );
    const container_style = {
      width: "100%",
      marginRight: "72px",
      marginLeft: "72px",
      height: "100vh",
      backgroundColor: "#fff",
      transform: "translate(0px,0px)",
      paddingBottom: "0px",
      paddingTop: "0px",
    };

    return (
      <Dialog
        fullWidth={true}
        maxWidth={"xl"}
        modal={false}
        open={this.props.open}
       onClose={this.onClose}
        className="dynamic-group-dialog"
        PaperProps={{
    sx: {
     
      minHeight: 300
    }
  }}
       
      >
        <DialogTitle disableTypography component="div">
       
          <Typography component="h1" variant="h3"> {header}</Typography>
        
        </DialogTitle>
       
         <div style={{height:'0px'}}>
         
          <div
            style={{
            
              float:'right',marginRight:'8px',
              zIndex: "5",
            }}
          >
             <Tooltip  title={"Close"} > 
        <IconButton style={{marginRight:'8px',float:'right',top:'-50px'}} tabIndex={0} autoFocus  onClick={this.onClose} >
          <Clear role='figure' />
        </IconButton>
         </Tooltip> 
         {this.state.isSaving ? (  <div style={{marginRight:'8px',float:'right',marginTop:'-52px'}}>
            <CircularProgress style={{ color: "#3272D9"}} />
            </div>):null}
      
          </div>


        </div>
        <DialogContent>
       <>
        {this.state.errorMsg && this.getErrorMsg()}
        <div
          style={{
            overflowX: "hidden",
            width: "100%",
            //height: this.state.showErrorMsg ? "calc(100% - 50px)" : "",
            height: this.state.showErrorMsg ? "calc(100% - 50px)" : "100%",
          }}
        >
          <div style={{ height: "100%"}}>
            {topSection}
            {
              this.state.groupDetails.type == "DYNAMIC" ||
              this.state.groupDetails.type == "STATIC" ||
              this.state.groupDetails.type == "AUTOMATIC" ? (
                <Criteria
                  showCriteria={(value) => {
                    this.setState({
                      show: value,
                    });
                  }}
                  onLoad={(criteriaRef) => {
                    this.setState({ criteriaRef: criteriaRef });
                  }}
                  onDevicesFind={(info) => {
                    this.setState({ matchingDevicesInfo: info, isSaving: false });
                  }}
                  onErrorMsg={(info) => {
                    this.setState({ errorMsg: info.errorMsg });
                  }}
                  onErrorFlg={(info) => {
                    this.setState({ showErrorMsg: info.showErrorMsg });
                  }}
                  onBeforeDevicesFind={(value) => {
                    this.setState({
                      isSaving: value,
                    });
                  }}
                  accountId={this.state.groupDetails.accountId}
                  entity={
                    this.props.mode == "ADD Group" ||
                    this.props.mode == "ADD" ||
                    (isInterfaceContainer(this.state.groupDetails) && //this.props.isInterfaceGroup //dp039841
                      !(this.state.groupDetails.elementType == "INTERFACES")) ||
                    (!isInterfaceContainer(this.state.groupDetails) && //this.props.isInterfaceGroup &&
                      !(this.state.groupDetails.elementType == "DEVICES") &&
                      this.state.groupDetails.type !== "DYNAMIC")
                      ? this.state.groupDetails.elementType == "DEVICES"
                        ? { criteria: deviceCriteria }
                        : { criteria: combineCriteria }
                      : this.props.entity
                  }
                  type={this.state.groupDetails.type}
                />
              ) : null
            }
            {this.state.groupDetails.type == "DYNAMIC" ? (
              <MatchingDevices
                matchingDevicesInfo={this.state.matchingDevicesInfo}
                mode={this.props.mode}
                elementType={this.state.groupDetails.elementType}
                type={this.state.groupDetails.type}
              />
            ) : this.state.groupDetails.type == "STATIC" || this.state.groupDetails.type == "AUTOMATIC" ? (
              <AddDeviceToGroupDialogRedux
                accountId={this.state.groupDetails.accountId}
                matchingDevicesInfo={this.state.matchingDevicesInfo}
                handleErrorDialogOpen={this.props.handleErrorDialogOpen}
                onSelectedDevicesChanged={this.onSelectedDevicesChanged}
                rowSelectionRef={(ref) => {
                  this.rowSelectionRef = ref;
                }}
                elementType={this.state.groupDetails.elementType}
                type={this.state.groupDetails.type}
                {...this.props}
                showCriteria={this.state.show}
              />
            ) : null}
          </div>
        </div>
        </>
        </DialogContent>
        <DialogActions className="dynamic-group-dialog">
          {actions}
        </DialogActions>
      </Dialog>
    );
  }
  onSelectedDevicesChanged = (selectedDevices, unselectedDevices) => {
    this.setState({
      selectedDevices: selectedDevices,
      unselectedDevices: unselectedDevices,
    });
  };
  onGroupDetailsChanged = (groupDetailObject, addButtonDisabled) => {
    this.setState({
      groupDetails: groupDetailObject,
      addButtonDisabled: addButtonDisabled,
    });
  };

  handleSave = () => {
    let criteriaObject = null;
    let selectedData = [];
    if (this.state.groupDetails.type !== "CONTAINER") {
      criteriaObject = this.state.criteriaRef.getFilterInfo();
      if (this.state.groupDetails.type == "STATIC") {
        let i,
          len,
          data = this.props.entities.slice(0);
        selectedData = [...this.state.selectedDevices];
        len = data.length;
        for (i = 0; i < len; i++) {
          if (selectedData.includes(data[i].id)) {
            selectedData.splice(selectedData.indexOf(data[i].id), 1);
          }
        }
      }
    }
    let data = {
      id: this.props.entity.id,
      parentId: this.state.selectedGroup
        ? this.state.selectedGroup.id
        : this.getParentId(),
      criteria: criteriaObject,
      masterElementGroup:
        this.state.groupDetails.elementType === "DEVICES" ? "0" : "1",
      ...this.state.groupDetails,
      devicesToAdd: selectedData.map((id) => parseInt(id)),
      devicesToRemove: this.state.unselectedDevices.map((id) => parseInt(id)),
    };
    this.setState({ isSaving: true });
    groupsApi
      .updateGroups(data)
      .then((response) => {
        if (this.state.groupDetails.type == "STATIC") {
          if(this.state.groupDetails.elementType !== "DEVICES")
          sessionStorage.setItem("editInterfaceGroupStatic", true);
          this.rowSelectionRef.clear();
        }
        /*if (
          this.props.location.pathname.search(
            "treeviewnew" && this.props.mode === "EDIT"
          ) > -1
        ) {
          this.props.getGroup(response.data.parentId);
        } else {
          this.props.getGroup(this.props.entity.id);
        }*/
        if (this.props.location.pathname.search("treeviewnew") >= 0) {
          this.props.getGroup(response.data.id);
        } else {
          //history.push(newPath);
          this.props.getGroup(this.props.entity.id);
        }
        
        sessionStorage.setItem("devicesAdded", true);
        sessionStorage.setItem("devicesAddData", JSON.stringify(response.data));
        sessionStorage.setItem(
          "devicesAddDataLayout",
          JSON.stringify(response.data)
        );

        const history = this.props.history; //"/groups/0/16/21/30/list"
        const pathname = history.location.pathname.replace("/card", ""); //"/groups/0/16/21/30"
        const totalPath = this.handlePath(pathname, response.data.parentId);
        let newPath = totalPath;
        if (totalPath.search("/interfaces") == -1) {
          newPath =
            totalPath.includes("cabi") ||
            totalPath.includes("configuration") ||
            totalPath.includes("alarms")
              ? `${totalPath}`
              : isInterfaceContainer(this.props.entity)
              ? `${totalPath}/${response.data.id}/interfaces`
              : `${totalPath}`;
        }
        if (this.props.location.pathname.search("treeviewnew") < 0)
          history.push(newPath);

        this.setState({
          showErrorMsg: false,
          showSuccessMsg:true,
          successMsg: 'Group updation is in progress. The changes will be reflected in a few seconds.', //'Group updated successfully',
          isSaving: false,
          matchingDevicesInfo: this.initializeMatchingDevicesContext(),
          show: true,
        });
        this.props.refreshTree();
        this.props.handleClose("dynamicGroupsOpen");
      })
      .then(()=>{
        this.props.handleUpdateSnackbar();
      })
      .catch((error) => {
        if (this.state.groupDetails.type == "STATIC") {
          this.rowSelectionRef.clear();
        }
        // if (this.props.location.pathname.indexOf("treeviewnew") > -1) {
        //   this.props.getGroup(
        //     this.state.selectedGroup
        //     ? this.state.selectedGroup.id
        //     : this.props.entity.parentId
        //     );
        //   }
        this.setState({
          showErrorMsg: true,
          isSaving: false,
          errorMsg: error.response ? error.response.data.error : "",
          matchingDevicesInfo: this.initializeMatchingDevicesContext(),
          show: true,
        });
      });
    // this.props.refresh;
    //  this.props.refreshTree && this.props.refreshTree();
      this.props.handleClose("dynamicGroupsOpen");
  };
  handleAddGroup = () => {
    this.props.pollGroupsStop(this.state.parentId);
    this.setState({
      addButtonDisabled: true,
    });
    let criteriaObject = this.state.criteriaRef.getFilterInfo();

    if (this.state.groupDetails.type === "DYNAMIC") {
      let info = {
        criteria: criteriaObject,
        parentId: this.state.selectedGroup
          ? this.state.selectedGroup.id
          : this.getParentId(),
        masterElementGroup:
          this.state.groupDetails.elementType === "DEVICES" ? "0" : "1",
        name: this.state.groupDetails.name,
        ...this.state.groupDetails,
      };

      this.props
        .addGroup(info)
        .then((response) => {
          if (this.state.groupDetails.type == "STATIC") {
            this.rowSelectionRef.clear();
          }
          const history = this.props.history;
          const pathname = history.location.pathname.replace("/card", "");
          const isListView = !history.location.pathname.includes("/card");

          const totalPath = this.handlePath(pathname, response.json.parentId);
          console.log(isListView);
          const newPath =
            totalPath.includes("cabi") ||
            totalPath.includes("configuration") ||
            totalPath.includes("alarms")
              ? `${totalPath}`
              : isInterfaceContainer(response.json)
              ? `${totalPath}/${response.json.id}/interfaces`
              : isListView
              ? `${totalPath}/${response.json.id}`
              : `${totalPath}/card`;
          console.log(newPath);
          const name = this.state.groupDetails.name;
          console.log(this.props.location.pathname.search("treeviewnew"));
          if (this.props.location.pathname.search("treeviewnew") >= 0) {
            this.props.getGroup(response.json.id);
          } else {
            history.push(newPath);
            this.props.getGroup(response.json.id);
          }
          sessionStorage.setItem("devicesAdded", true);
          sessionStorage.setItem(
            "devicesAddData",
            JSON.stringify(response.json)
          );
         this.setState({showSuccessMsg:true,
          successMsg:'Group added successfully'});
          this.props.handleClose("subGroupOpen", {
            isDynamicChecked: true,
            name: name,
            json: response.json,
            type: "ADD",
          });

          this.props.setBreadCrumbs([
            {
              //Groups
              name: Resource.get("Groups"),
              link: "/groups/0",
            },
            {
              //Child group
              name: name,
              link: `${totalPath}/${response.json.id}/`,
            },
          ]);

          this.resetState();
          this.props.refreshTree();
        })
        .catch((error) => {
          if (this.state.groupDetails.type == "STATIC") {
            this.rowSelectionRef.clear();
          }
          console.log(error);
          // if (this.props.location.pathname.indexOf("treeviewnew") > -1) {
          //   this.props.getGroup(
          //     this.state.selectedGroup
          //     ? this.state.selectedGroup.id
          //     : this.props.entity.id
          //     );
          //   }

          this.setState({
            showErrorMsg: true,
            isSaving: false,
            errorMsg: error.response
              ? error.response.json
                ? error.response.json.error
                : ""
              : this.state.groupErrorMsg
              ? this.state.groupErrorMsg
              : "",
            matchingDevicesInfo: this.initializeMatchingDevicesContext(),
            show: true,
          });

          let errorDesc = this.state.groupErrorMsg;
          if (!errorDesc.includes("Unknown SQL")) {
            this.resetState();
            this.props.handleClose("subGroupOpen");
            if (errorDesc != null && errorDesc == "Not_Same_Account_Container")
              this.props.handleErrorDialogOpen("addGroup_DifferentAccount");
            else this.props.handleErrorDialogOpen("addGroup");
          }
        });
    } else {
      let requestPayload = {};
      if (this.state.groupDetails.type == "STATIC") {
        requestPayload = {
          ...this.state.groupDetails,
          parentId: this.state.selectedGroup
            ? this.state.selectedGroup.id
            : this.getParentId(),
          devicesToAdd: this.state.selectedDevices.map((id) => parseInt(id)),
        };
      } else {
        requestPayload = {
          ...this.state.groupDetails,
          parentId: this.state.selectedGroup
            ? this.state.selectedGroup.id
            : this.getParentId(),
        };
      }

      this.props
        .addGroup(requestPayload)
        .then((response) => {
          if (this.state.groupDetails.type == "STATIC") {
            this.rowSelectionRef.clear();
          }
          const history = this.props.history;
          const isListView = history.location.pathname.includes("/card");
          const pathname = history.location.pathname.replace("/card", "");

          const totalPath = this.handlePath(pathname, response.json.parentId);
          const newPath =
            totalPath.includes("cabi") ||
            totalPath.includes("configuration") ||
            totalPath.includes("alarms")
              ? `${totalPath}`
              : isInterfaceContainer(response.json)
              ? `${totalPath}/${response.json.id}/interfaces`
              : isListView
              ? `${totalPath}/${response.json.id}`
              : `${totalPath}/card`;

          // Breacrumb Updates
          this.props.setBreadCrumbs([
            {
              //Groups
              name: Resource.get("Groups"),
              link: isListView ? "/groups/0" : "/groups/0/card",
            },
            {
              name: this.state.groupDetails.name, //Group Name
              link: `${totalPath}`,
            },
          ]);

          if (pathname.indexOf("treeviewnew") > -1) {
            this.props.getGroup(response.json.id);
          } else {
            history.push(newPath);
            this.props.getGroup(response.json.id);
          }

          sessionStorage.setItem("devicesAdded", true);
          sessionStorage.setItem(
            "devicesAddData",
            JSON.stringify(response.json)
          );

          this.resetState();
          this.props.refreshTree();
          this.props.handleClose("subGroupOpen");
        })
        .catch((error) => {
          if (this.state.groupDetails.type == "STATIC") {
            this.rowSelectionRef.clear();
          }
          // if (this.props.location.pathname.indexOf("treeviewnew") > -1) {
          //   this.props.getGroup(
          //     this.state.selectedGroup
          //     ? this.state.selectedGroup.id
          //     : this.props.entity.id
          //     );
          //   }
          this.setState({
            showErrorMsg: true,
            isSaving: false,
            errorMsg: error.response
              ? error.response.json
                ? error.response.json.error
                : ""
              : "",
            matchingDevicesInfo: this.initializeMatchingDevicesContext(),
          });
          this.resetState();
          this.props.handleClose("subGroupOpen");
          //this.props.handleErrorDialogOpen("addGroup");
          let errorDesc = this.state.groupErrorMsg;

          if (errorDesc != null && errorDesc == "Not_Same_Account_Container")
            this.props.handleErrorDialogOpen("addGroup_DifferentAccount");
          else this.props.handleErrorDialogOpen("addGroup");
        });
    }
    // this.props.refreshTree();
  };
  resetState = () => {
    this.setState({
      saveError: null,
      isDynamicChecked: false,
      addButtonDisabled: true,
      selectedDevices: [],
      groupDetails: {
        accountId: -1,
        elementType: "DEVICES",
        type: "DYNAMIC",
        name: "",
        description: "",
      },
    });
  };
  handlePath = (historyPath, parentId) => {
    var finalIndex = historyPath.lastIndexOf("/");
    var res = this.state.selectedGroup
      ? this.state.selectedGroup.path +
        "/" +
        this.state.selectedGroup.id +
        historyPath.substring(finalIndex)
      : historyPath;
    return res;
  };
  onClose = () => {
    this.setState({
      showErrorMsg: false,
      isSaving: false,
      errorMsg: "",
      matchingDevicesInfo: this.initializeMatchingDevicesContext(),
      show: true,
    });
    if (
      this.props.location.pathname.indexOf("treeviewnew") > -1 &&
      (this.props.mode === "ADD" || this.props.mode === "ADD Group")
    ) {
      this.props.getGroup(
        this.state.selectedGroup
          ? this.state.selectedGroup.id
          : this.props.entity.id
          ? this.props.entity.id
          : this.props.entity.parentId
      );
    }
    if (this.state.groupDetails.type == "STATIC") {
      this.rowSelectionRef.clear();
    }
    this.props.handleClose("dynamicGroupsOpen");
  };
  onParentClick = () => {
    if (this.node) {
      this.node.style.display = "block";
    }
    if (!this.state.showParentTree) {
      this.setState({ showParentTree: true });
    }
  };
  setAccount = (evt, index, value) => {
    if (value) {
      this.setState({ accountName: value });
    }
  };
}

DynamicGroupDialog.defaultProps = {
  handleClose: function () {},
  getGroup: function () {},
};

const mapStateToProps = (state) => {
  return {
    entity: state.entity,
    saas: state.saas,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getGroup: (id) => dispatch(requestGroup({ id: id })),
    refreshTree: () => dispatch(refreshTree(true)),
    setBreadCrumbs: (items) => dispatch(setBreadCrumbs(items)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DynamicGroupDialog));
