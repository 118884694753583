import React, { Component } from 'react'
import Spinner from './Spinner'
import './icons.less'
import './delayedSpinner.less'

class DelayedSpinner extends Component {
  state = {
    run: false,
  }
  componentDidMount() {
    this.tm = setTimeout(() => {
      this.setState({
        run: true,
      })
    }, this.props.timeout)
  }
  componentWillUnmount() {
    clearTimeout(this.tm)
  }
  render() {
    return (
      <div className={`delayed__spinner ${this.props.className || ''}`} key={this.props.key}>
        {this.state.run ? <Spinner {...this.props} /> : null}
      </div>
    )
  }
}

DelayedSpinner.defaultProps = {
  // 0.3 seconds
  timeout: 300,
}

export default DelayedSpinner
