import axios from 'axios'
import { setIsTimedout } from "./../api/isTimedOut/actions";

class SessionTimeoutCheck {
  constructor(dispatch) {
    this.pollInterval = 15000
    this.dispatch = dispatch    
  }
 

  start() {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {

       // console.log("Session timeout check");
      if(window.OPERATOR_CONSOLE.sessionExpired)
        {
           // console.log('session expired in the frame')
            this.dispatch(setIsTimedout(true))
        
        } 
      this.start();
        
        
    }, this.pollInterval)
  }
}

export default SessionTimeoutCheck
