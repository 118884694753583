import React, { Component } from "react";
import { Button as RaisedButton, LinearProgress ,TextField,InputLabel,FormControl,InputAdornment,Divider} from "@mineral/core";
import {
  Tooltip,
  Button,
  IconButton,
  FormControlLabel,
  Checkbox,
} from "@mineral/core";
import Resource from "./Resource";
import "./discoveryAgent.less";
import isEqual from "lodash/isEqual";
import downloads from "../../../api/downloads/downloads";
import orderBy from "lodash/orderBy";
import computerSystemsApi from "./../../../api/computer-systems/computerSystems";
import { HelpIcon, SearchIcon } from "../../ui-components/uim-components";
import {CircularProgress} from "@mineral/core";
import CustomScopeDiscoveryDialog from "./CustomScopeDiscoveryDialog";
import * as constant from "./DiscoveryConstants";
import DiscoveryRunProgressDialog from "./DiscoveryRunProgressDialog";
import AccessibleTooltip from "../../common/AccessibleTooltip";
import AZsorted from '../../ui-components/components/icons/a-z_sort'

import ZAsorted from '../../ui-components/components/icons/z-a_sort'

//Spinner
import Spinner from "../../ui-components/components/Spinner/Spinner";
//end Spinner

export default class DiscoveryAgent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      agents: this.props.agents,
      agentsStatus: this.props.agentsStatus,
      selectedAgent: this.props.selectedAgentInfo,
      searchTerm: "",
      sortOrder: "asc",
      showOnlyAvailable: false,
      downloadLocation: null,
      errorMessage: null,
      openCustomScopeDialog: false,
      openDiscoveryRunProgressDialog: false,
      selectedCustomScope: [],

      showJobStatus: false,
      initialJobFetchInProgress: false,
      isProgressNeeded: false,
      statusMsg: "",
      isSpinner: false,
    };
  }
  visibleAgentsCount = 0;
  scheduledAgentsCount = 0;
  componentWillMount() {
    this.props.fetchAgents();
    this.props.fetchAgentsStatus();
    this.props.pollAgentsStart();
    this.props.pollAgentsStatusStart();
    this.props.requestAgents();
    this.props.requestAgentsStatus();
    downloads
      .getDownloadLocation()
      .then((response) => {
        this.setState({
          downloadLocation: response.data.entity,
          errorMessage: null,
        });
      })
      .catch((error) => {
        this.setState({
          downloadLocation: null,
          //Unable to access installers
          errorMessage: Resource.get("Unable to access installers."),
        });
      });

    computerSystemsApi.getInventory().then((computerSystemsAction) => {
      const agentsCustomScopeData = computerSystemsAction.data;
      this.setState({
        agentsCustomScopeData,
      });
    });
  }
  componentWillReceiveProps(nextProps) {
    if (
      nextProps.agents.length !== this.props.agents.length ||
      !isEqual(nextProps.agents, this.props.agents)
    ) {
      this.setState({ agents: nextProps.agents });
    }
    if (
      nextProps.agentsStatus.length !== this.props.agentsStatus.length ||
      !isEqual(nextProps.agentsStatus, this.props.agentsStatus)
    ) {
      this.setState({ agentsStatus: nextProps.agentsStatus });
    }
  }
  componentWillUnmount() {
    this.stopPolling();
  }
  handleCancel = () => {
    this.stopPolling();
    this.props.history.push(`/getting-started`);
  };
  handleConfigure = () => {
    this.stopPolling();
    this.props.onAgentSelect();
    this.props.showNextContainer();
  };
  stopPolling() {
    this.props.pollAgentsStop();
    this.props.pollAgentsStatusStop();
  }
  getStatusInfo = (address) => {
    let info = {};
    for (let i = 0; i < this.state.agentsStatus.length; i++) {
      if (this.state.agentsStatus[i].address === address) {
        info = this.state.agentsStatus[i];
        break;
      }
    }
    return info;
  };

  onAgentClick = (item) => {
    if (
      this.state.selectedAgent &&
      this.state.selectedAgent.address === item.address
    ) {
      return;
    }
    let itemStatus = this.getStatusInfo(item.address),
      statusState = itemStatus.state ? itemStatus.state : "";
    if (
      item.active === false ||
      statusState.toLowerCase() === "unresponsive" ||
      statusState.toLowerCase() === "unknown"
    ) {
      return;
    }
    this.props.selectedAgent(item);
    this.setState(
      { selectedAgent: item, isSpinner: true },
      this.setCustomScopeData
    );
  };

  setCustomScopeData() {
    const customScopeData = this.state.agentsCustomScopeData.children.filter(
      (item) => item.id === this.state.selectedAgent.id
    );
    this.props.setCustomScopeDiscovery(customScopeData);
    this.setState({
      isSpinner: false,
    });
  }

  onFilterMouseUp = (e) => {
    const filter = e.target.value.replace(/^\s+/g, "");
    this.setState({ searchTerm: filter });
  };

  getActions = () => {
    let sortIconClasses = ["sort-icon"];
    let count = this.scheduledAgentsCount,
      availableLabel = Resource.get("Show Only Available"); //Show Only Available

    count += " Scheduled";
    if (this.state.sortOrder === "desc") {
      sortIconClasses.push("reverse");
    }
    return (
      <div className="actions" style={{display:'flex',flexDirection:'row'}}>
      <FormControl >
        <InputLabel>
          Search Agent</InputLabel>
         
            <TextField autoFocus={true} id={"first-content-element"}
              onChange={this.onFilterMouseUp}
              placeholder="Search agents"
              value={this.state.searchTerm}
              InputProps={{         
            
            endAdornment: (
              <InputAdornment position="end">
                 <AccessibleTooltip title={"Search"}>
                  <SearchIcon  className="selecticon" style={{ height: 16 }} />
                  </AccessibleTooltip>
              </InputAdornment>
            )}}
            />
           
         
          </FormControl>
          <FormControl style={{marginTop:'5px',marginLeft:'8px',marginRight:'8px'}}>
          <AccessibleTooltip title={"Sort agents"}>
        <IconButton
         
          onClick={() => {
            let order = "asc";
            if (this.state.sortOrder === "asc") {
              order = "desc";
            }
            this.setState({ sortOrder: order });
          }}
          aria-label="Sort agents"
          tabIndex="0"
         
        >{this.state.sortOrder==='asc'?<ZAsorted/>:<AZsorted/>}</IconButton>
        

       </AccessibleTooltip>
       </FormControl>
       <Divider orientation="vertical" style={{marginTop:"5px"}}/>
        <FormControlLabel
          label={availableLabel}
          style={{
            marginTop:'8px',
            marginLeft: "30px",
          }}
          control={
            <Checkbox
              checked={this.state.showOnlyAvailable ? true : false}
              style={{ marginRight: "10px" }}
              onChange={(event) => {
                this.setState({ showOnlyAvailable: event.target.checked });
              }}
            />
          }
        ></FormControlLabel>
        <div style={{marginLeft:'auto'}}>
        <span >{count}</span>
        <AccessibleTooltip title="Help">
          <IconButton style={{marginRight:'8px',marginLeft:'8px'}}
            aria-roledescription="Click to open Help link"
             
            disableRipple={true}
            onClick={() =>
              window.open(
                "https://techdocs.broadcom.com/bin/gethidpage?hid=OC_Discovery&space=UIM204&language=&format=rendered",
                "_blank"
              )
            }
            role="link"
          >
            <HelpIcon role="figure"  title="Help" />
          </IconButton>
        </AccessibleTooltip>
        
        </div>
      </div>
    );
  };
  getSearchResults = () => {
    let searchTerm = this.state.searchTerm.toLowerCase(),
      info = [];
    for (let i = 0; i < this.state.agents.length; i++) {
      let agent,
        itemStatus = this.getStatusInfo(this.state.agents[i].address),
        statusState = itemStatus.state ? itemStatus.state : "";
      if (
        this.state.showOnlyAvailable &&
        (this.state.agents[i].active === false ||
          statusState.toLowerCase() === "unresponsive" ||
          statusState.toLowerCase() === "unknown")
      ) {
        continue;
      }
      agent = this.state.agents[i].hub + "/" + this.state.agents[i].robot;
      if (
        agent.toLowerCase().indexOf(searchTerm) !== -1 ||
        this.state.agents[i].ip.toLowerCase().indexOf(searchTerm) !== -1
      ) {
        info.push(this.state.agents[i]);
      }
    }
    return info;
  };
  getStatusState = (statusState) => {
    switch (statusState) {
      case "WAITING_FOR_CONFIGURATION":
        return Resource.get("Waiting for Configuration"); //Waiting for Configuration
      case "NO_SCHEDULE":
        return Resource.get("No Schedule"); //No Schedule
      case "EXPIRED_SCHEDULE":
        return Resource.get("Schedule Expired, Reconfigure"); //Schedule Expired Reconfigure
      case "SCHEDULED":
        return Resource.get("Job Scheduled"); //Job Scheduled
      case "RUNNING":
        return Resource.get("Running Discovery"); //Running Discovery
      case "UNRESPONSIVE":
        return Resource.get("Unable to Contact Agent"); //Unable to Contact Agent
      case "PROCESSING":
        return Resource.get("Processing Results"); //Processsing Results
      case "UNKNOWN":
        return Resource.get("Status Unknown"); //Status Unknown
      case "LATENT":
        return Resource.get("Awaiting Agent Status"); //Awaiting Agent Status
      default:
        return statusState.split("_").join(" ");
    }
  };
  getNewLookInfo = () => {
    const downloadLoc = this.state.downloadLocation;
    const errorMsg = this.state.errorMessage;
    if (errorMsg === null) {
      //No  Hubs available. Please download the required  hub installers to proceed
      let noRelayhubsMsg = Resource.get(
        "No  Hubs available. Please download the required  hub installers to proceed"
      );
      let classNames = ["setup-wizard__localprobes__info"];
      //Relay Hub Installers
      let msg2 = Resource.get("Download Installers");
      //Windows
      let msg3 = Resource.get("Windows");
      //Linux
      let msg4 = Resource.get("Linux");
      return (
        <div style={{ position: "relative" }} className={classNames.join(" ")}>
          <div
            style={{
              width: "calc(100% - 60px)",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            <div className="nohubs__contentImg"></div>
            <span className="setup-wizard__localprobes__content">
              <span
                style={{
                  padding: "2px 13px 2px 20px",
                  borderRight: "2px solid #999999",
                }}
              >
                {noRelayhubsMsg}
              </span>
              <span style={{ paddingLeft: "9px" }}>{msg2}</span>
              <span
                style={{ color: "#3272D9", paddingLeft: 20, cursor: "pointer" }}
              >
                <a
                  style={{ textDecoration: "none" }}
                  href={downloadLoc + "/rest/v1/download/relayhub/windows"}
                  download="setupCAUIMSaasRelayHub_win64.exe"
                >
                  {msg3}
                </a>
              </span>
              <span
                style={{ color: "#3272D9", paddingLeft: 20, cursor: "pointer" }}
              >
                <a
                  style={{ textDecoration: "none" }}
                  href={downloadLoc + "/rest/v1/download/relayhub/linux"}
                  download="setupCAUIMSaasRelayHub_linux64.bin"
                >
                  {msg4}
                </a>
              </span>
            </span>
          </div>
        </div>
      );
    }
  };

  setSelectedScope(value) {
    this.setState({ selectedCustomScope: value });
  }

  openCustomScopeDialogHandler = () => {
    this.setState({ openCustomScopeDialog: true });
  };

  closeCustomScopeDialogHandler = () => {
    this.setState({ openCustomScopeDialog: false });
    this.setState({ selectedCustomScope: [] });
  };

  closeDiscoveryRunProgressDialogHandler = () => {
  //  document.getElementById("first-content-element")?.focus();
    this.setState({ openDiscoveryRunProgressDialog: false });
  };

  openDiscoveryRunProgressDialogHandler = () => {
    this.setState({ openDiscoveryRunProgressDialog: true });
  };

  showJobStatusHandler = () => {
    this.setState({ showJobStatus: true });
  };

  initialJobFetchInProgressHandler = (value) => {
    this.setState({ initialJobFetchInProgress: value });
  };

  isProgressNeededHandler = () => {
    this.setState({ isProgressNeeded: true });
  };

  statusMsgHandler = (msg) => {
    this.setState({ statusMsg: msg });
  };

  render() {
    const cancelButtonLabel = constant.CANCEL;
    const configureButtonLabel = Resource.get("Configure Discovery"); //Configure Discovery
    const contentHeader = Resource.get("Choose a Discovery Agent"); //Choose Agent
    const customScopeButtonLabel = Resource.get("Custom Scope Discovery"); //Custom Scope Discovery
    let agentInfo;
    if (this.props.selectedAgentInfo) {
      agentInfo =
        this.props.selectedAgentInfo.hub +
        "/" +
        this.props.selectedAgentInfo.robot;
    }

    let searchedResults =
        this.state.searchTerm !== "" || this.state.showOnlyAvailable
          ? this.getSearchResults()
          : this.state.agents,
      agentsOrder = orderBy(
        searchedResults || [],
        [(agent) => agent.hub.toLowerCase() + "/" + agent.robot.toLowerCase()],
        this.state.sortOrder
      ),
      contentList;

    this.visibleAgentsCount = searchedResults ? searchedResults.length : 0;
    if (this.props.isFetching || this.props.isStatusFetching) {
      contentList = (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress style={{ color: "#3272D9" }} />
        </div>
      );
    } else if (this.props.agents.length === 0) {
      contentList = <div>{this.getNewLookInfo()}</div>;
    } else {
      this.scheduledAgentsCount = 0;
      contentList = agentsOrder.map((item, index) => {
        let itemStatus = this.getStatusInfo(item.address),
          statusState = itemStatus.state ? itemStatus.state : "",
          statusClasses = ["status"],
          listContainerClasses = ["list-container"],
          isSelected =
            this.state.selectedAgent &&
            this.state.selectedAgent.address === item.address
              ? true
              : false,
          deSelectIfSelected = false;
        if (item.active === false) {
          statusClasses.push("in-active");
          listContainerClasses.push("disable");
          deSelectIfSelected = true;
          statusState = Resource.get("Robot Unavailable"); //Robot Unavailable
        } else if (
          statusState.toLowerCase() === "unresponsive" ||
          statusState.toLowerCase() === "unknown"
        ) {
          statusClasses.push("unknown");
          listContainerClasses.push("disable");
          deSelectIfSelected = true;
        }
        if (statusState.toLowerCase() === "scheduled") {
          this.scheduledAgentsCount++;
        }

        if (isSelected) {
          listContainerClasses.push("selected");
          if (deSelectIfSelected && this.state.selectedAgent !== null) {
            this.props.selectedAgent(null);
            this.setState({ selectedAgent: null });
          }
        }
        return (
          <Button
            key={item.address + "-" + index}
            className={listContainerClasses.join(" ")}
            onClick={() => this.onAgentClick(item)}
            disabled={listContainerClasses.includes("disable")}
            aria-label={`Discovery agent ${item.hub}/${item.robot} ${this.getStatusState(statusState)}`}
            tabIndex="0"
          >
            <div
              className="section1"
              style={{ flexBasis: "70%", overflow: "hidden" }}
            >
              <div className={statusClasses.join(" ")}></div>
              <div className="address-container">
                {isSelected ? <div className="selectedImg"></div> : ""}
                <Tooltip title={item.hub + "/" + item.robot}>
                  <span style={{ verticalAlign: "super" }}>
                    {/* <span className="tooltiptext">
                    {item.hub + "/" + item.robot}
                  </span> */}
                    {item.hub + "/" + item.robot}
                  </span>
                </Tooltip>
              </div>
              <div className="ip">{item.ip}</div>
            </div>
            <div className="section2" style={{ flexBasis: "30%" }}>
              <span className="state" style={{ paddingRight: "16px" }}>
                {this.getStatusState(statusState)}
              </span>
              {statusState.toLowerCase() === "running" &&
              typeof itemStatus.currentChecked === "number" ? (
                <div style={{ display: "inline-block" }}>
                  <div
                    style={{
                      width: "100px",
                      display: "inline-block",
                      paddingRight: "8px",
                    }}
                  >
                    <LinearProgress
                     
                      variant="determinate"
                      value={itemStatus.currentChecked}
                    />
                  </div>
                  <span className="percent">
                    {itemStatus.currentChecked + "%"}
                  </span>
                </div>
              ) : null}
            </div>
          </Button>
        );
      });
    }
    return (
      <>
        {this.state.isSpinner ? (
          <Spinner />
        ) : (
          <div className="agent-container">
            <div className="content">
              <div style={{display:'flex',flexDirection:'row'}}>
              <h2 className="header">{contentHeader} </h2>
             
        </div>
              {this.getActions()}
              <div className="agents-info">{contentList}</div>
            </div>
            <div className="footer">
              <div>
                <Button
                  variant="contained"
                  children={cancelButtonLabel}
                  //className="cancel-button"
                  style={{ height: 32, lineHeight: "32px" }}
                  onClick={this.handleCancel}
                  //labelStyle={buttonLabelStyle}
                  classes={{ root: "cancel-button", label: "buttonLabelStyle" }}
                />
              </div>
              <div>
                <Button
                  variant="contained"
                  children={customScopeButtonLabel}
                  color="primary"
                  disabled={this.state.selectedAgent ? false : true}
                  style={{
                    height: 32,
                    lineHeight: "32px",
                    marginRight: "20px",
                  }}
                  classes={{
                    root: "configure-button",
                    label: "buttonLabelStyle",
                  }}
                  onClick={this.openCustomScopeDialogHandler}
                />
                <CustomScopeDiscoveryDialog
                  open={this.state.openCustomScopeDialog}
                  onClose={this.closeCustomScopeDialogHandler}
                  agentInfo={agentInfo}
                  data={
                    this.props.selectedAgentCustomScopeInfo
                      ? this.props.selectedAgentCustomScopeInfo
                      : []
                  }
                  selected={this.state.selectedCustomScope}
                  setSelected={(value) => this.setSelectedScope(value)}
                  openDiscoveryRunProgressDialog={
                    this.openDiscoveryRunProgressDialogHandler
                  }
                  showJobStatus={this.state.showJobStatus}
                  initialJobFetchInProgress={
                    this.state.initialJobFetchInProgress
                  }
                  isProgressNeeded={this.state.isProgressNeeded}
                  showJobStatusHandler={this.showJobStatusHandler}
                  initialJobFetchInProgressHandler={(value) =>
                    this.initialJobFetchInProgressHandler(value)
                  }
                  isProgressNeededHandler={this.isProgressNeededHandler}
                  statusMsgHandler={(msg) => this.statusMsgHandler(msg)}
                />
                <DiscoveryRunProgressDialog
                  open={this.state.openDiscoveryRunProgressDialog}
                  onClose={this.closeDiscoveryRunProgressDialogHandler}
                  showJobStatus={this.state.showJobStatus}
                  initialJobFetchInProgress={
                    this.state.initialJobFetchInProgress
                  }
                  isProgressNeeded={this.state.isProgressNeeded}
                  statusMsg={this.state.statusMsg}
                  // showJobStatusHandler={this.showJobStatusHandler}
                  // initialJobFetchInProgressHandler={(value) => this.initialJobFetchInProgressHandler(value)}
                  // isProgressNeededHandler={this.isProgressNeededHandler}
                />
                <Button
                  variant="contained"
                  children={configureButtonLabel}
                  color="primary"
                  disabled={this.state.selectedAgent ? false : true}
                  //className="configure-button"
                  style={{ height: 32, lineHeight: "32px" }}
                  onClick={this.handleConfigure}
                  classes={{
                    root: "configure-button",
                    label: "buttonLabelStyle",
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}
