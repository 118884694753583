// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Relay hubs & robots
  content["Relay hubs & robots"] =
  "Hubs de retransmissão e robôs";

  //Deleted devices
  content["Deleted devices"] =
  "Dispositivos excluídos";

  //Name
  content["Name"] =
  "Nome";

  //IP address
  content["IP address"] =
  "Endereço IP";

  //Role
  content["Role"] =
  "Função";

  //OS Type
  content["OS Type"] =
  "Tipo de SO";

  //OS Name
  content["OS Name"] =
  "Nome do SO";

  //OS Version
  content["OS Version"] =
  "Versão do SO";

  //Settings
  content["Settings"] =
  "Configurações";

  //Deleted Devices
  content["Deleted Devices"] =
  "Dispositivos excluídos";

  //Filter
  content["Filter"] =
  "Filtrar";

  // END OF LOCALIZATION

export default content;
