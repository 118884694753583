import React from "react";
import { Route } from "react-router-dom";
// import SlmContainer from "../components/slm/SlmRedux";
import SLM from "../components/slm/SLM";

const SlmRoute = () => {
  return <Route path="/slm" component={SLM} />;
};

export default SlmRoute;
