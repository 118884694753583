import * as React from 'react';
import { SvgIcon } from '@mineral/core';

export const OutlinedStar03 = React.forwardRef(
  (props, ref) => (
    <SvgIcon data-testid="LearnIcon" viewBox="0 0 40 40" {...props} ref={ref}>
      <path d="M20 7L22.4828 15.6997L31.2583 13.5L24.9656 20L31.2583 26.5L22.4828 24.3003L20 33L17.5172 24.3003L8.74167 26.5L15.0344 20L8.74167 13.5L17.5172 15.6997L20 7Z" fill={props.color} stroke="#1D5BBF" stroke-width="3" />
    </SvgIcon>
  )
);
