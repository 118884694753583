// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Essentials subscription
  content["Essentials subscription"] =
  "Suscripción a Essentials";

  //50 max
  content["50 max"] =
  "50 máx.";

  //Total devices discovered
  content["Total devices discovered"] =
  "Número total de dispositivos detectados";

  //VIEW INVENTORY
  content["VIEW INVENTORY"] =
  "VER INVENTARIO";

  //No Roles found
  content["No Roles found"] =
  "No se ha encontrado roles.";

  //Devices by role
  content["Devices by role"] =
  "Dispositivos por rol";

  //Alarm Severity Message
  content["Highest Alarm Severity Level and Total Alarm Count"] =
  "Nivel de severidad de alarma más alto y recuento total de alarmas";

  //Max Alarm Severity
  content["Max Alarm Severity"] =
  "Nivel de severidad de alarma máximo";

  //Monitored technology
  content["Monitored technology"] =
  "Tecnología monitorizada";

  //Total Alarms
  content["Total Alarms"] =
  "Núm. total de alarmas";

  //INFO
  content["INFO"] =
  "INFORMACIÓN";

  //WARNING
  content["WARNING"] =
  "ADVERTENCIA";

  //MINOR
  content["MINOR"] =
  "LEVE";

  //MAJOR
  content["MAJOR"] =
  "GRAVE";

  //CRITICAL
  content["CRITICAL"] =
  "CRÍTICA";

  //Monitored teechnologies
  content["Monitored Technologies"] =
  "Tecnologías monitorizadas";

  //Name
  content["Name"] =
  "Nombre";

  //Role
  content["Role"] =
  "Rol";

  //Top Devices by Alarm
  content["Top Devices by Alarm"] =
  "Dispositivos principales por alarma";

  //Group name
  content["Group name"] =
  "Nombre del grupo";

  //Total alarms
  content["Total alarms"] =
  "Núm. total de alarmas";

  //Total alarms by group
  content["Total alarms by group"] =
  "Núm. total de alarmas por grupo";

  // END OF LOCALIZATION

export default content;
