import React from "react";
import ReactDragListView from "react-drag-listview";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import { FormControl, Select, TextField, Checkbox, IconButton, InputLabel, MenuItem } from "@mineral/core";

import { makeStyles } from '@mui/styles'
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { Tooltip } from "@mineral/core";
import "./AdvancedFilterDialog.less";
import "./AdvancedFilterDialogContent.less";

const useStyles = makeStyles((theme) => ({}));

const AdvancedFilterDialogContent = (props) => {
  const classes = useStyles();

  const fieldOptions = [
    "Source",
    "Probe",
    "QoS",
    "Target",
    "Robot",
    "Origin",
    "User Tag 1",
    "User Tag 2",
  ];
  const operatorOptions = ["is", "contains", "starts with", "ends with"];
  const logicalOptions = ["and", "or"];
  const [name, setName] = React.useState(props.filter.groupName);

  const handleChangeName = (event) => {
    props.setName(event.target.value);
    setName(event.target.value);
    if (event.target.value.trim() === "") props.setNameError(true);
    else props.setNameError(false);
  };

  const handleInputItemChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...props.inputItemGroupList];

    list[index][name] = value;
    props.setInputItemGroupList(list);
  };

  const handleNotChange = (e, index) => {
    const { name, checked } = e.target;
    const list = [...props.inputItemGroupList];
    list[index][name] = checked;
    props.setInputItemGroupList(list);
  };
  const handleAddClick = () => {
    props.setInputItemGroupList((prevState) => [
      ...props.inputItemGroupList,
      {
        number: prevState[prevState.length - 1].number + 1,
        logical: "and",
        field: "Source",
        not: false,
        operator: "contains",
        value: "",
      },
    ]);
  };

  const handleRemoveClick = (index) => {
    const list = [...props.inputItemGroupList];

    list.splice(index, 1);
    list.forEach((item, idx) => (item.number = idx + 1));
    props.setInputItemGroupList(list);
  };

  const handleFilterClearClick = () => {
    props.setInputItemGroupList(props.inputItemGroup);
  };

  //const fileterdList = props.displayNameList && props.displayNameList.length < 1 ? true : false
  const fileterdList = props.clickVerifyFilter ? false : true;

  const frmNameLabel = fileterdList
    ? "frmFullNameLabel filters-titles__filterName"
    : "frmNameLabel filters-titles__filterName";
  const frmFieldLabel = fileterdList
    ? "frmFullFieldLabel filters-titles__filterName"
    : "frmFieldLabel filters-titles__filterName";
  const frmNotLabel = fileterdList
    ? "frmFullNotLabel filters-titles__filterName"
    : "frmNotLabel filters-titles__filterName";
  const frmOperatorLabel = fileterdList
    ? "frmFullOperatorLabel filters-titles__filterName"
    : "frmOperatorLabel filters-titles__filterName";
  const frmValueLabel = fileterdList
    ? "frmFullValueLabel filters-titles__filterName"
    : "frmValueLabel filters-titles__filterName";
  const frmName = fileterdList
    ? "frmFullName filters-titles__filterName"
    : "frmName filters-titles__filterName";
  const frmLogical = fileterdList
    ? "frmFullLogical filters-titles__filterName"
    : "frmLogical filters-titles__filterName";
  const frmField = fileterdList
    ? "frmFullField filters-titles__filterName"
    : "frmField filters-titles__filterName";
  const frmNot = fileterdList
    ? "frmFullNot filters-titles__filterName"
    : "frmNot filters-titles__filterName";
  const frmOperator = fileterdList
    ? "frmFullOperator filters-titles__filterName"
    : "frmOperator filters-titles__filterName";
  const frmValue = fileterdList
    ? "frmFullValue filters-titles__filterName"
    : "frmValue filters-titles__filterName";

  const dragFilterListProps = {
    onDragEnd(fromIndex, toIndex) {
      const data = [...props.inputItemGroupList];
      const item = data.splice(fromIndex, 1)[0];
      data.splice(toIndex, 0, item);
      props.setInputItemGroupList(data);
    },
    nodeSelector: "li",
    handleSelector: "a",
  };

  return (
    <>
      <div className="filters-wrapper padTitle">
        <div className="filters-titles">
          <h5 className={frmNameLabel}>Name</h5>
        </div>
        <FormControl variant="outlined" className={frmName}>
          <TextField
            //  name="Filter_Name"
            onChange={(e) => handleChangeName(e)}
            id={`filter_name`}
            name={props.name}
            value={name}
            error={(name && name.length > 0) ? false : true}
            helperText={(name && name.length > 0) ? "" : "Enter valid filter name"}
            variant="outlined"
            inputProps={{ style: { padding: 5 } }}
          />
        </FormControl>
      </div>

      <div className="filters-wrapper padTitle" style={{ marginTop: `2em` }}>
        <div className="filters-titles">
          <InputLabel className={frmFieldLabel}>Field</InputLabel>
          <InputLabel className={frmNotLabel}>Not</InputLabel>
          <InputLabel className={frmOperatorLabel}>Operator</InputLabel>
          <InputLabel className={frmValueLabel}>Value</InputLabel>
        </div>
        <div className="filter-field-scroll">
          <ReactDragListView {...dragFilterListProps}>
            <ol id="qosFilter">
              {props.inputItemGroupList.map((item, index) => {
                return (
                  <li key={index}>
                    <div key={item.number} className="filters-fields" style={{ marginLeft: '8px' }}>
                      <div className="filters-fields__item" style={{ marginBottom: '8px' }}>
                        <Tooltip arrow title="Drag">
                          <a tabIndex={-1} aria-hidden={true}
                            style={{ paddingRight: 5, paddingTop: 5 }}
                            href="#"
                          >
                            <DragIndicatorIcon />
                          </a>
                        </Tooltip>
                        <p className="filters-fields__item-number" style={{ color: '#000000' }} aria-label={"Row" + (index + 1)}>
                          {index + 1}
                        </p>
                        <form style={{ marginTop: '8px', minWidth: "550px" }}>
                          <FormControl

                            // style={{paddingRight:item.logical=='and'?'8px':'17px',width:"15%"}}
                            style={{ paddingRight: '8px', width: "15%" }}
                          >
                            <Select role='combobox' fullWidth
                              onChange={(e) => handleInputItemChange(e, index)}
                              disabled={index === 0}
                              id={`logicalid-${index}`}
                              name="logical"

                              value={item.logical}
                              inputProps={{
                                style: { padding: 5 },
                                "aria-label": "logical",
                                role: 'combobox'
                              }}
                              MenuProps={{

                                MenuListProps: {
                                  'aria-label': `logical-label-ul-${index}`
                                }
                              }}
                            >
                              {logicalOptions.map((logicalOption, index) => (
                                <MenuItem
                                  id={index + '-' + logicalOption}
                                  key={logicalOption}
                                  value={logicalOption}
                                >
                                  {logicalOption}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <FormControl
                            style={{ width: "18%" }}
                          >
                            <Select role='combobox'
                              onChange={(e) => handleInputItemChange(e, index)}

                              name="field"
                              value={item.field}
                              inputProps={{
                                style: { padding: 5 },
                                "aria-label": "field",
                                role: 'combobox'
                              }}
                              MenuProps={{

                                MenuListProps: {
                                  'aria-label': `field-label-ul-${index}`
                                }
                              }}
                            >
                              {fieldOptions.map((fieldOption, index) => (
                                <MenuItem id={index + '-' + fieldOption} key={fieldOption} value={fieldOption} style={{display:"flex",justifyContent:"flex-start",paddingLeft:"12px"}}>
                                  {fieldOption}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <FormControl style={{ paddingLeft: '8px', paddingRight: '8px' }}>
                            <Checkbox
                              onChange={(e) => handleNotChange(e, index)}
                              checked={item.not == true || item.not == "true"}

                              inputProps={{ "aria-label": "not" }}
                              name="not"
                            />
                          </FormControl>
                          <FormControl
                            style={{ width: "20%" }}
                          >
                            <Select role='combobox'
                              onChange={(e) => handleInputItemChange(e, index)}

                              name="operator"
                              value={item.operator}
                              inputProps={{
                                style: { padding: 5 },
                                "aria-label": "operator",
                                role: 'combobox'
                              }}
                              MenuProps={{

                                MenuListProps: {
                                  'aria-label': `operator-label-ul-${index}`
                                }
                              }}
                            >
                              {operatorOptions.map((operatorOption, index) => (
                                <MenuItem id={index + '-' + operatorOption}
                                  key={operatorOption}
                                  value={operatorOption}
                                  style={{display:"flex",justifyContent:"flex-start",paddingLeft:"12px"}}
                                >
                                  {operatorOption}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <FormControl style={{ marginLeft: '16px', width: "35%" }}>
                            <TextField
                              name="value"
                              value={item.value}
                              onChange={(e) => handleInputItemChange(e, index)}
                              id={`value field ${item.number}`}
                              variant="outlined"
                              inputProps={{
                                style: { padding: 5 },
                                "aria-label": "value",
                              }}
                            />
                          </FormControl>
                        </form>

                        <div className="filters-fields__item-buttons-wrapper" style={{ display: 'flex', flexDirection: 'row' }}>
                          <Tooltip title="Remove">

                            <IconButton
                              disabled={props.inputItemGroupList.length === 1}
                              style={{ color: '#000000', opacity: props.inputItemGroupList.length === 1 ? "0.6" : 1 }}
                              aria-label={"Remove"}
                              onClick={() => handleRemoveClick(index)}
                            >

                              <RemoveIcon

                              />
                            </IconButton>

                          </Tooltip>
                          <Tooltip title="Add">

                            <IconButton
                              style={{ color: '#000000', opacity: props.inputItemGroupList.some((item) => item.value === "") ? "0.6" : 1 }}
                              aria-label={"Add"}
                              onClick={handleAddClick}
                              disabled={props.inputItemGroupList.some(
                                (item) => item.value === ""
                              )}
                            >
                              <AddIcon
                              />
                            </IconButton>

                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ol>
          </ReactDragListView>
        </div>
      </div>
    </>
  );
};

export default AdvancedFilterDialogContent;
