import React from 'react'
import connect from './../../utils/connect'
import CabiDetails from './CabiDetails'
import { setBreadCrumbs, addBreadCrumb } from './../breadcrumbs/actions'
import Resource from './Resource'

const CabiContainer = props => {
  const dashboardBreadcrumb = {
    //Breadcrumb
    name: Resource.get('Dashboards'),
    link: '/technologies',
  }
  let dashboardName="";
    if (props.location.search) {
      let rawParams = props.location.search; // will be something like '?foo=bar&a=b'

      if (rawParams.length > 0) {

        rawParams = decodeURI(rawParams.substr(1, rawParams.length)); // remove '?' and url encoding
        let params=new URLSearchParams(rawParams);
        params.forEach(function(value, key) {
          console.log(value, key);
          if(key=="dashboardName")
          dashboardName= value;
          
        });       
      }
    }
    if(dashboardName && dashboardName!="")
    {
      props.setBreadCrumbs([
        dashboardBreadcrumb,
        {
          //Breadcrumb
          name: dashboardName,
          link:'/uim-cabi',
        },
      ])

    }else
    {

  props.setBreadCrumbs([
    dashboardBreadcrumb,
    {
      //Breadcrumb
      name: Resource.get('Overview'),
      link: '/uim-cabi',
    },
  ])
}
  return (
    <main className="uim-cabi__main">
      <CabiDetails {...props} />
    </main>
  )
}

const mapStateToProps = state => {
  return {
    sid: state.sid,
    saas: state.saas,
    doiurl:state.user.doiurl,
    featureFlags: state.configuration.items
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setBreadCrumbs: item => dispatch(setBreadCrumbs(item)),
    addBreadCrumb: item => dispatch(addBreadCrumb(item)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CabiContainer)
