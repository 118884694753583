// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Cards
  content["Cards"] =
  "Cartões";

  //List
  content["List"] =
  "Lista";

  //Alarms
  content["Alarms"] =
  "Alarmes";

  //Dashboards
  content["Dashboards"] =
  "Painéis";

  //Metrics
  content["Metrics"] =
  "Métricas";

  //Monitoring Config
  content["Monitoring Config"] =
  "Configuração de monitoramento";

  // END OF LOCALIZATION

export default content;
