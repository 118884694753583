import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import connect from "./../../../../utils/connect";
import { setBreadCrumbs } from "./../../../breadcrumbs/actions";
import "./accAdmin.less";
import {CircularProgress} from '@mineral/core'
import ReactFrame from "./../ReactFrame";
import Resource from "./Resource";

class AccAdmin extends Component {
  state = {
    sid: this.props.sid,
  };

  

  componentDidMount() {
    this.props.setBreadCrumbs([
      {
        //Settings
        name: Resource.get("Settings"),
        link: "/settings",
      },
      {
        //Account Admin
        name: Resource.get("Account Admin"),
        link: "/settings/acc-admin",
      },
    ]);
  }

  render() {
    let view = (
        <div className="spinner" style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><CircularProgress style={{color: '#3272D9'}} /></div>
    );

    if (this.state.sid) {
        let do_heartbeat = window.OPERATOR_CONSOLE.do_heartbeat
        if (do_heartbeat !== 'true' && do_heartbeat !== 'false') {
          do_heartbeat = false
        }
       
      view = (
        <ReactFrame
          url="accountadmin/jsp/index.jsp"
          sid={this.state.sid}
          keepalive={do_heartbeat}
          portletId="acc-admin"
          method="GET"
        />
      );
    }

    return view;
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setBreadCrumbs: (items) => dispatch(setBreadCrumbs(items)),
  };
};

const mapStateToProps = (state) => {
  return {
    saas: state.saas,
    sid: state.sid,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AccAdmin));
