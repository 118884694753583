import React from 'react'
import {InProgressIcon as PendingIcon} from '../../ui-components/uim-components'
import Message from './CenterMessage'
import './deployPending.less'
import Resource from './Resource'

const DeployPending = (props) => {
  return (
    <Message
      className='message--pending'
      title={props.title}
      message={props.message}
      icon={PendingIcon}
    />
  )
}

DeployPending.defaultProps = {
  //Deployment progress message
  title: Resource.get('Deployment is in progress.'),
  //help message
  message: Resource.get('When your installation is complete, click NEXT to continue.'),
}

export default DeployPending