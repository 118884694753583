import React, { Component } from 'react'
import { CenterError as Error, InfoIcon } from '../ui-components/uim-components'
import {CircularProgress} from '@mineral/core'
import { Tabs, Tab ,IconButton,Typography,Box} from "@mineral/core"
import Resource from './Resource'
import GroupTable from './GroupTable'
import GroupProfileTable from './GroupProfileTable'
import GroupDeviceTable from './GroupDeviceTable'
import DeviceDetails from './DeviceDetails'
import ProfileDetails from './ProfileDetails'
import MCSDashBoardCard from './MCSDashBoardCard'
import './card.less'
import './McsDashBoard.less'
import arrowImage from './assets/grey_arrow.png';
import ProfilesIcon from '../icons/ProfilesIcon';
import GroupsIcon from '../icons/GroupsIcon';
import DevicesIcon from '../icons/DevicesIcon';
import moment from 'moment'
import { CSVLink } from "react-csv";
import CenterMessage from '../robotDeploy/deployedMessage/CenterMessage'
import {Tooltip} from "@mineral/core";
import MCSNameColumnCellRenderer from './MCSNameColumnCellRenderer'
import MCSProfileNameColumnCellRenderer from './MCSProfileNameColumnCellRenderer'
import MCSGroupProfileNameColumnCellRenderer from './MCSGroupProfileNameColumnCellRenderer'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
const TabHeaders = {
    VIEW_BY_GROUP: 'View By Groups',
    VIEW_BY_DEVICE: 'View By Devices',
};
const dashCardViews = {
    DASHCARDVIEW_GROUPS_PROFILES_DEVICES: 'Dashcard View Groups Profiles Devices',
    DASHCARDVIEW_PROFILES: 'Dashcard View Profiles',
    DASHCARDVIEW_DEVICES: 'Dashcard View Devices',
    DASHCARDVIEW_DEVICE_DETAILS: 'Dashcard View Device Details',
    DASHCARDVIEW_DEVICES_PROFILES: 'Dashcard View Groups Profiles Devices',
    DASHCARDVIEW_DPROFILES: 'Dashcard View DProfiles',
    DASHCARDVIEW_PROFILE_DETAILS: 'Dashcard View Profile Details',

}

const INIT_PAGE_NUMBER = 0
const PAGE_SIZE = 50
const GROUPSTABLESUMMARY=Resource.get("Groups table summarizing group names with path, number of group profiles and consolidated profile deployment status.")//Groups table summary

const PROFILESTABLESUMMARY=Resource.get("Profiles table summarizing profile name, number of devices and consolidated profile deployment status.")//Profiles table summary

const DEVICESTABLESUMMARY=Resource.get("Profiles table summarizing device name,  last deployment status and status message.")//Devices table summary

const groupCols = [
    {
        //Group name
        headerName: Resource.get('Name'),
        field: 'groupName',
        tooltipField: 'Group name',
        suppressMovable: true,
        suppressResize: true,
        width: 100,
        minWidth: 100,
        filter: "agTextColumnFilter",
        sortable: true,
        filterParams: {
            suppressAndOrCondition: true,
            filterOptions: ['startsWith', 'endsWith', 'contains', 'equals'],
            clearButton: true
        },cellRendererFramework: MCSNameColumnCellRenderer,
       
        headerComponentParams: {

            enableMenu: false
        },
        suppressNavigable:false
    },
    {
        //Group name
        headerName: 'Profiles',
        field: 'groupProfileCount',
        tooltipField: 'Group profile count',
        suppressMovable: true,
        suppressResize: true,
        sortable: true,
        
        suppressNavigable:true
    },
    {
        //Group name
        headerName: 'Status',
        field: 'status',
        tooltipField: 'Status',
        suppressMovable: true,
        suppressResize: true,
        sortable: true,        
        suppressNavigable:true,
        cellRendererFramework: params => {
            let item = params.data
            let statusButton = ''
            if (item !== undefined && item.status !== undefined && item.status != null) {

                if (item.status.includes('Deployed'))
                    statusButton = (<span style={{
                        'color': '#038537', 'font-size': '15px',
                        'vertical-align': 'top'
                    }}>●</span>)
                else if (item.status.includes('Failed'))
                    statusButton = (<span style={{
                        'color': '#DE1B1B', 'font-size': '15px',
                        'vertical-align': 'top'
                    }}>●</span>)
                else if (item.status.includes('Pending'))
                    statusButton = (<span style={{
                        'color': '#8E99AB', 'font-size': '15px',
                        'vertical-align': 'top'
                    }} >●</span>)
                else if (item.status.includes('Not Applicable') || item.status.includes('NA'))
                    statusButton = (<span style={{
                        'color': '#CCCCCC', 'font-size': '15px',
                        'vertical-align': 'top'
                    }} >●</span>)
                return (
                    <div>
                        {statusButton}
                        <span>{` ` + item.status}</span>
                    </div>
                )
            }
            else {
                return (
                  
                   
                  
                    <Box component={'span'} style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }} >
                      { 'NA'}
                    </Box>
                
                )
            }

        }

    }
]



const profilesCols = [
    {
        //Group name
        headerName: 'Profile Name',
        field: 'profileName',
        tooltipField: 'Profile name',
        suppressMovable: true,
        suppressResize: true,
        filter: "agTextColumnFilter",
        sortable: true,
        filterParams: {
            suppressAndOrCondition: true,
            filterOptions: ['startsWith', 'endsWith', 'contains', 'equals'],
            clearButton: true
        },
        cellRendererFramework: MCSGroupProfileNameColumnCellRenderer,
        headerComponentParams: {

            enableMenu: false
        },
        suppressNavigable:false
    
    },
    {
        //Group name
        headerName: 'Devices',
        field: 'deviceCount',
        tooltipField: 'Device count',
        suppressMovable: true,
        suppressResize: true,
        sortable: true,
        suppressNavigable:true
  
    },
    {
        //Group name
        headerName: 'Status',
        field: 'status',
        tooltipField: 'Status',
        suppressMovable: true,
        suppressResize: true,
        sortable: true,
        suppressNavigable:true,
        cellRendererFramework: params => {
            let item = params.data
            let statusButton = ''
            if (item !== undefined && item.status !== undefined && item.status != null) {

                if (item.status.includes('Deployed'))
                    statusButton = (<span style={{
                        'color': '#038537', 'font-size': '15px',
                        'vertical-align': 'top'
                    }}>●</span>)
                else if (item.status.includes('Failed'))
                    statusButton = (<span style={{
                        'color': '#DE1B1B', 'font-size': '15px',
                        'vertical-align': 'top'
                    }}>●</span>)
                else if (item.status.includes('Pending'))
                    statusButton = (<span style={{
                        'color': '#8E99AB', 'font-size': '15px',
                        'vertical-align': 'top'
                    }} >●</span>)
                else if (item.status.includes('Not Applicable') || item.status.includes('NA'))
                    statusButton = (<span style={{
                        'color': '#CCCCCC', 'font-size': '15px',
                        'vertical-align': 'top'
                    }} >●</span>)
                return (
                    <div>
                        {statusButton}
                        <span>{` ` + item.status}</span>
                    </div>
                )
            } else {
                return (
                   
                   
                   
                    <Box component={'span'} style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }} >
                      { 'NA'}
                    </Box>
                  
                )
            }

        }
    },
]

const profilesColsForDevice = [
    {
        //Group name
        headerName: 'Name',
        field: 'profileName',
        tooltipField: 'Profile name',
        suppressMovable: true,
        suppressResize: true,
        sortable: true,
        filter: "agTextColumnFilter",
        suppressNavigable:false,
        filterParams: {
            suppressAndOrCondition: true,
            filterOptions: ['startsWith', 'endsWith', 'contains', 'equals'],
            clearButton: true
        },
        cellRendererFramework:MCSProfileNameColumnCellRenderer ,
                headerComponentParams: {

            enableMenu: false
        }
    },

    {
        //Group name
        headerName: 'Last Deployment Status',
        field: '',
        tooltipField: 'Last Deployment Status',
        suppressMovable: true,
        suppressResize: true,
        sortable: true,
        suppressNavigable:true,
        cellRendererFramework: model => {
            let item = model.data
            let statusButton = ''
            if (item !== undefined && item.status !== undefined && item.status != null) {

                if (item.status.includes('Deployed'))
                    statusButton = (<span style={{
                        'color': '#038537', 'font-size': '15px',
                        'vertical-align': 'top'
                    }}>●</span>)
                else if (item.status.includes('Failed'))
                    statusButton = (<span style={{
                        'color': '#DE1B1B', 'font-size': '15px',
                        'vertical-align': 'top'
                    }}>●</span>)
                else if (item.status.includes('Pending'))
                    statusButton = (<span style={{
                        'color': '#8E99AB', 'font-size': '15px',
                        'vertical-align': 'top'
                    }} >●</span>)
                else if (item.status.includes('Not Applicable') || item.status.includes('NA'))
                    statusButton = (<span style={{
                        'color': '#CCCCCC', 'font-size': '15px',
                        'vertical-align': 'top'
                    }} >●</span>)

                let lastDeployedDate = '';
                if (item.lastDeployedDate != null && item.lastDeployedDate != undefined)
                    lastDeployedDate = item.lastDeployedDate
                return (
                    <div >{statusButton}
                        <span>{`  ` + item.status + `  ` + lastDeployedDate}</span>
                    </div>
                )
            } else {
                return (
                    
                   
                   
                    <Box component={'span'} style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }} >
                      { 'NA'}
                    </Box>
                 
                )
            }

        }
    },
    {
        //Group name
        headerName: 'Status Message',
        field: 'latestDeploymentSummary',
        tooltipField: 'Status Message',
        suppressMovable: true,
        suppressResize: true,
        sortable: true,
        suppressNavigable:true,
        cellRendererFramework: params => {
            let item = params.data
            let statusButton = ''
            if (item !== undefined && item.status !== undefined && item.status != null) {


                return (
                    <div>

                        <span >{item.latestDeploymentSummary}</span>
                    </div>
                )
            } else {
                return (
                   
                   
                    
                    <Box component={'span'} style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }} >
                      { 'NA'}
                    </Box>
                 
                )
            }

        }
    },
]

const deviceCols = [
    {
        //Group name
        headerName: 'Name',
        field: 'deviceName',
        tooltipField: 'Device Name',
        suppressMovable: true,
        suppressResize: true,
        width: 50,
        minWidth: 50,
        cellRendererFramework: MCSNameColumnCellRenderer,
        filter: "agTextColumnFilter",
        sortable: true,
        filterParams: {
            suppressAndOrCondition: true,
            filterOptions: ['startsWith', 'endsWith', 'contains', 'equals'],
            clearButton: true
        },
        headerComponentParams: {

            enableMenu: false
        },
        suppressNavigable:false
    },
    {
        //Group name
        headerName: 'Last Deployment Status',
        field: '',
        tooltipField: 'Last Deployment Status',
       // filter: "agTextColumnFilter",
        sortable: true,
        filterParams: {
            suppressAndOrCondition: true,
            filterOptions: ['startsWith', 'endsWith', 'contains', 'equals'],
            clearButton: true
        },
        suppressNavigable:true,
        headerComponentParams: {

            enableMenu: false
        },
        cellRendererFramework: model => {
            let item = model.data

            let statusButton = ''
            if (item !== undefined && item.status !== undefined && item.status != null) {

                if (item.status.includes('Deployed'))
                    statusButton = (<span style={{
                        'color': '#038537', 'font-size': '15px',
                        'vertical-align': 'top'
                    }}>●</span>)
                else if (item.status.includes('Failed'))
                    statusButton = (<span style={{
                        'color': '#DE1B1B', 'font-size': '15px',
                        'vertical-align': 'top'
                    }}>●</span>)
                else if (item.status.includes('Pending'))
                    statusButton = (<span style={{
                        'color': '#8E99AB', 'font-size': '15px',
                        'vertical-align': 'top'
                    }} >●</span>)
                else if (item.status.includes('Not Applicable') || item.status.includes('NA'))
                    statusButton = (<span style={{
                        'color': '#61676D', 'font-size': '15px',
                        'vertical-align': 'top'
                    }} >● Not Applicable</span>)

                let profName = '';
                if (item.profileName !== undefined && item.profileName !== null)
                    profName = item.profileName;
                let lastDeployedDate = '';
                if (item.lastDeployedDate !== undefined && item.lastDeployedDate !== null)
                    lastDeployedDate = item.lastDeployedDate;
                return (
                    <div>
                        {statusButton}
                        <span>{`  ` + profName + `  ` + lastDeployedDate}</span>
                    </div>
                )
            } else {
                return (
                  
                   
                    
                    <Box component={'span'} style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }} >
                      { 'NA'}
                    </Box>
                                )
            }

        },
        suppressMovable: true,
        suppressResize: true,
        width: 70,
        minWidth: 70,
    },


    {
        //Group name
        headerName: 'Status Message',
        field: 'latestDeploymentSummary',
        tooltipField: 'Status Message',
        suppressMovable: true,
        suppressResize: true,
        sortable: true,
        suppressNavigable:true,
        filter: "agTextColumnFilter",
        filterParams: {
            suppressAndOrCondition: true,
            filterOptions: ['startsWith', 'endsWith', 'contains', 'equals'],
            clearButton: true
        },
        headerComponentParams: {

            enableMenu: false
        },
        cellRendererFramework: params => {
            let item = params.data
            let statusButton = ''
            if (item !== undefined && item.latestDeploymentSummary !== undefined && item.latestDeploymentSummary != null) {


                return (
                    <div>

                        <span>{item.latestDeploymentSummary}</span>
                    </div>
                )
            } else {
                return (
                   
                   
                    
                    <Box component={'span'} style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }} >
                      { 'NA'}
                    </Box>
                 
                )
            }

        }
    },
]

const deviceColsForDevice = [
    {
        //Group name
        headerName: 'Name',
        field: 'deviceName',
        tooltipField: 'Device Name',
        suppressMovable: true,
        suppressResize: true,
        cellRendererFramework: MCSNameColumnCellRenderer,
        width: 50,
        minWidth: 50,
        filter: "agTextColumnFilter",
        sortable: true,
        filterParams: {
            suppressAndOrCondition: true,
            filterOptions: ['startsWith', 'endsWith', 'contains', 'equals'],
            clearButton: true
        },
        headerComponentParams: {

            enableMenu: false
        }, 
        suppressNavigable:false
    }

    ,
    {
        //Group name
        headerName: 'Latest Deployment Status',
        field: '',
        tooltipField: 'Latest Deployment Status',
        sortable: true,
        suppressNavigable:true,
        cellRendererFramework: model => {
            let item = model.data
            let statusButton = ''
            if (item !== undefined) {

                if (item.status !== undefined && item.status !== null && item.status.includes('Deployed'))
                    statusButton = (<span style={{
                        'color': '#038537', 'font-size': '15px',
                        'vertical-align': 'top'
                    }}>●</span>)
                else if (item.status !== undefined && item.status !== null && item.status.includes('Failed'))
                    statusButton = (<span style={{
                        'color': '#DE1B1B', 'font-size': '15px',
                        'vertical-align': 'top'
                    }}>●</span>)
                else if (item.status !== undefined && item.status !== null && item.status.includes('Pending'))
                    statusButton = (<span style={{
                        'color': '#8E99AB', 'font-size': '15px',
                        'vertical-align': 'top'
                    }} >●</span>)
                else if (item.status !== undefined && item.status !== null && (item.status.includes('Not Applicable') || item.status.includes('NA')))
                    statusButton = (<span style={{
                        'color': '#61676D', 'font-size': '15px',
                        'vertical-align': 'top'
                    }} >● Not Applicable</span>)

                let profName = '';
                if (item.profileName !== undefined && item.profileName !== null)
                    profName = item.profileName;
                let lastDeployedDate = '';
                if (item.lastDeployedDate !== undefined && item.lastDeployedDate !== null)
                    lastDeployedDate = item.lastDeployedDate;
                return (
                    <div >{statusButton}
                        <span>{`  ` + profName + `  ` + lastDeployedDate}</span>
                    </div>
                )
            } else {
                return (
                   
                   
                   
                    <Box component={'span'} style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }} >
                      { 'NA'}
                    </Box>
                  
                )
            }

        },
        filter: "agTextColumnFilter",
        filterParams: {
            suppressAndOrCondition: true,
            filterOptions: ['startsWith', 'endsWith', 'contains', 'equals'],
            clearButton: true
        },
        headerComponentParams: {

            enableMenu: false
        },

        suppressMovable: true,
        suppressResize: true,
        width: 70,
        minWidth: 70,
    },


    {
        //Group name
        headerName: 'Status Message',
        field: 'latestDeploymentSummary',
        tooltipField: 'Latest deployment summary',
        filter: "agTextColumnFilter",
        sortable: true,
        suppressNavigable:true,
        filterParams: {
            suppressAndOrCondition: true,
            filterOptions: ['startsWith', 'endsWith', 'contains', 'equals'],
            clearButton: true
        },
        headerComponentParams: {

            enableMenu: false
        },
        suppressMovable: true,
        suppressResize: true,
        cellRendererFramework: params => {
            let item = params.data
            let statusButton = ''
            if (item !== undefined && item.latestDeploymentSummary !== undefined && item.latestDeploymentSummary != null) {


                return (
                    <div>

                        <span>{item.latestDeploymentSummary}</span>
                    </div>
                )
            } else {
                return (
                   
                    
              <Box component={'span'} style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }} >
                { 'NA'}
              </Box>
           
                )
            }

        }
    },


]
//{filterColumn: "", filterOperator: "", filterValue: ""}
const INIT_GROUP_OPTIONS = { pageNumber: 0, pageSize: PAGE_SIZE, status: '', filter: [], sort: { sortColumn: '', sortDirection: 'asc' }, exportData: false }; //status:'',filter:[], sort:{sortColumn: '', sortDirection: 'asc'}
const INIT_PROFILE_OPTIONS = { pageNumber: 0, pageSize: PAGE_SIZE, groupId: '', deviceId: '', status: '', filter: [], sort: { sortColumn: '', sortDirection: 'asc' }, exportData: false };//status:'',filter:[], sort:{sortColumn: '', sortDirection: 'asc'}
const INIT_DEVICE_OPTIONS = { pageNumber: 0, pageSize: PAGE_SIZE, profileId: '', status: '', filter: [], sort: { sortColumn: '', sortDirection: 'asc' }, exportData: false };//status:'',filter:[], sort:{sortColumn: '', sortDirection: 'asc'}


export default class McsDashboardContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            status: 'loading',
            groups: { data: [], pageNumber: 0, pageSize: PAGE_SIZE, totalCount: 0, filteredItems: 0, options: { status: '', filter: [], sort: { sortColumn: '', sortDirection: 'asc' } }, groupsPercentages: {}, groupProfilePercentages: {}, groupDevicePercentages: {} },
            profiles: { data: [], pageNumber: 0, pageSize: PAGE_SIZE, totalCount: 0, filteredItems: 0, options: { status: '', groupId: '', filter: [], sort: { sortColumn: '', sortDirection: 'asc' } } },
            devices: { data: [], pageNumber: 0, pageSize: PAGE_SIZE, totalCount: 0, filteredItems: 0, options: { status: '', profileId: '', filter: [], sort: { sortColumn: '', sortDirection: 'asc' } }, devicePercentages: {}, deviceProfilePercentages: {} },
            exportData: { data: [] },
            device: {},
            profile: {},
            requestType: 'group',
            value: 'VIEW_BY_GROUP',
            dashCardView: 'DASHCARDVIEW_GROUPS_PROFILES_DEVICES',
            groupNameSelected: [],
            profileNameSelected: [],
            deviceNameSelected: [],
            groupIdSelected: [],
            profileIdSelected: [],
            deviceIdSelected: [],
            breadCrumbState: [{ "method": "this.props.fetchGroups", "options": INIT_GROUP_OPTIONS }],
            filter: '',
            disableGroupsBars: [false, false, false, false],
            disableProfilesBars: [false, false, false, false],
            disableDevicesBars: [false, false, false, false],
            highlightedCard: 'Groups'




        }
        this.textInput = React.createRef();
        this.focusTextInput = this.focusTextInput.bind(this);
        this.startRow = 0
        //this.getRowsCount=0
        this.paramsInfo = null
        this.firstLoad = true
        this.deviceOptions = Object.assign({}, INIT_DEVICE_OPTIONS)
        this.groupOptions = Object.assign({}, INIT_GROUP_OPTIONS)
        this.profileOptions = Object.assign({}, INIT_PROFILE_OPTIONS)
        this.showNoRowsOverlay = false;
        const input = document.getElementById('first_name');
        // Remove focus from specific element
        //input.blur();
    }
    //{filterColumn: '',filterOperator: '',filterValue: ''}

    focusTextInput = () => {
        // Explicitly focus the text input using the raw DOM API
        // Note: we're accessing "current" to get the DOM node
        this.textInput?.current?.focus();
    }
      componentDidMount() {
        this.focusTextInput();
      }
    onGridReady = (params) => {
        this.api = params.api;
        params.api.sizeColumnsToFit();
        this.api.showLoadingOverlay();
        if (this.state.requestType === 'device' && this.paramsInfo == null) //&& this.state.value==='VIEW_BY_DEVICE'
        {
            this.startRow = 0;
            this.firstLoad = false;

            this.api.setGridAriaProperty('label', DEVICESTABLESUMMARY)
            this.setGridDataSource(this.state.devices.data)
            this.setGridDataSource(this.state.devices.data)
        } else
            if (this.state.requestType === 'profile' && this.paramsInfo == null) {
                this.startRow = 0;
                this.firstLoad = false;
                this.api.setGridAriaProperty('label', PROFILESTABLESUMMARY)
                this.setGridDataSource(this.state.profiles.data)
                this.setGridDataSource(this.state.profiles.data)
            } else
                if (this.state.requestType === 'group' && this.paramsInfo == null) {
                    this.startRow = 0;
                    this.firstLoad = false;
                    this.api.setGridAriaProperty('label', GROUPSTABLESUMMARY)
                    this.setGridDataSource(this.state.groups.data)
                    this.setGridDataSource(this.state.groups.data)
                }





    }
    onGridSizeChanged(params) {
        if (this.api) {
            this.api.sizeColumnsToFit()
        }
    }

    get_filter(filter) {
        let filter_type = filter.column.colDef.filter
        let filter_field = filter.column.colId
        let filter_values = []
        if (filter_type == "agTextColumnFilter") {
            let filter_text = filter.filterPromise.resolution.appliedModel != null ? filter.filterPromise.resolution.appliedModel.filter : null;
            let filter_value = filter.filterPromise.resolution.appliedModel != null ?
                filter.filterPromise.resolution.appliedModel.type : null;
            if (filter_text != null && filter_text != undefined) {
                return {
                    filterColumn: filter.column !== undefined && filter_field !== undefined ? filter_field : '',
                    filterOperator: filter_value.toUpperCase(),
                    filterValue: filter_text
                }
            } else {
                return -1
            }
        }
    }


    get_filters() {
        let colFilters = []
        let active_filters = this.api.filterManager.allFilters ? this.api.filterManager.allFilters : this.api.filterManager.allAdvancedFilters


        if (active_filters.get("groupName") != undefined) {
            let c_filter = this.get_filter(active_filters.get("groupName"))
            if (c_filter != -1) {
                colFilters.push(c_filter)
            }
        }

        if (active_filters.get("status") != undefined) {
            let c_filter = this.get_filter(active_filters.get("status"))
            if (c_filter != -1) {
                colFilters.push(c_filter)
            }
        }



        if (active_filters.get("profileName") != undefined) {
            let c_filter = this.get_filter(active_filters.get("profileName"))
            if (c_filter != -1) {
                colFilters.push(c_filter)
            }
        }

        if (active_filters.get("status") != undefined) {
            let c_filter = this.get_filter(active_filters.get("status"))
            if (c_filter != -1) {
                colFilters.push(c_filter)
            }
        }


        if (active_filters.get("deviceName") != undefined) {
            let c_filter = this.get_filter(active_filters.get("deviceName"))
            if (c_filter != -1) {
                colFilters.push(c_filter)
            }
        }

        if (active_filters.get("latestDeploymentSummary") != undefined) {
            let c_filter = this.get_filter(active_filters.get("latestDeploymentSummary"))
            if (c_filter != -1) {
                colFilters.push(c_filter)
            }
        }



        if (this.state.requestType === 'profile' && this.state.value === 'VIEW_BY_DEVICE') {
            if (active_filters.get("0") != undefined) {
                let c_filter = this.get_filter(active_filters.get("0"))
                if (c_filter != -1) {
                    /*c_filter.column= 'status'
                        colFilters.push(c_filter)*/
                    c_filter.filterColumn = 'lastDeployedDate'
                    colFilters.push(c_filter)
                }
            }
        }





        if (this.state.requestType === 'device' && this.state.value === 'VIEW_BY_GROUP') {
            if (active_filters.get("0") != undefined) {
                let c_filter = this.get_filter(active_filters.get("0"))
                if (c_filter != -1) {
                    /*c_filter.column= 'status'
                        colFilters.push(c_filter) */
                    c_filter.filterColumn = 'profileName'
                    colFilters.push(c_filter)
                    /*c_filter.column= 'lastDeployedDate'             
                        colFilters.push(c_filter) */
                }
            }

        }

        if (this.state.requestType === "device" && this.state.value === "VIEW_BY_DEVICE") {
            if (active_filters.get("0") != undefined) {
                let c_filter = this.get_filter(active_filters.get("0"))
                if (c_filter != -1) {
                    /* c_filter.column= 'status'
                         colFilters.push(c_filter)   */
                    c_filter.filterColumn = 'profileName'
                    colFilters.push(c_filter)
                    /*c_filter.column= 'lastDeployedDate'             
                        colFilters.push(c_filter)   */
                }
            }

        }


        return colFilters
    }

    onBtExport() {
        let params = {

            allColumns: true,
            suppressQuotes: true,
            fileName: 'DevicesData',
            columnSeparator: ','
        };

        this.api.exportDataAsCsv(params);
    }
    onBtnClickExport() {



        if (this.state.requestType === 'group' && this.state.value === 'VIEW_BY_GROUP') {

            let groupOptions = Object.assign({}, this.groupOptions);
            groupOptions.exportData = true;
            groupOptions.pageNumber = 0;
            groupOptions.pageSize = 0;
            this.props.fetchGroups(groupOptions);



        } else
            if (this.state.requestType === 'device' && this.state.value === 'VIEW_BY_DEVICE') {


                let deviceOptions = Object.assign({}, this.deviceOptions);
                deviceOptions.exportData = true;
                deviceOptions.pageNumber = 0;
                deviceOptions.pageSize = 0;
                this.props.fetchDevices(deviceOptions);

            } else if (this.state.requestType === 'device' && this.state.value === 'VIEW_BY_GROUP') {
                //let options=;

                let deviceOptions = Object.assign({}, this.deviceOptions);
                deviceOptions.exportData = true;
                deviceOptions.pageNumber = 0;
                deviceOptions.pageSize = 0;
                this.props.fetchGroupDevices(deviceOptions);

            } else if (this.state.requestType === 'profile' && this.state.value === 'VIEW_BY_GROUP') {
                //let options=;

                let profileOptions = Object.assign({}, this.profileOptions);
                profileOptions.exportData = true;
                profileOptions.pageNumber = 0;
                profileOptions.pageSize = 0;

                this.props.fetchGroupProfiles(profileOptions);

            } else if (this.state.requestType === 'profile' && this.state.value === 'VIEW_BY_DEVICE') {
                //let options=;

                let profileOptions = Object.assign({}, this.profileOptions);
                profileOptions.exportData = true;
                profileOptions.pageNumber = 0;
                profileOptions.pageSize = 0;
                this.props.fetchDeviceProfiles(profileOptions);

            }
    }

    onSort() {
        let sorts = []

        for (let column of this.api.sortController.columnController.gridColumns) {
            if (column.sort != null) {
                let columnId = column.colId;
                if (this.state.requestType === 'profile' && this.state.value === 'VIEW_BY_DEVICE' && columnId == '0') {
                    columnId = 'lastDeployedDate';
                }
                else if (this.state.requestType === 'device' && this.state.value === 'VIEW_BY_GROUP' && columnId == '0') {
                    columnId = 'lastDeployedDate';
                }
                else if (this.state.requestType === 'device' && this.state.value === 'VIEW_BY_DEVICE' && columnId == '0') {
                    columnId = 'lastDeployedDate';
                }


                sorts.push({
                    sortColumn: columnId,
                    sortDirection: column.sort,
                    sortedAt: column.sortedAt,
                })
            }
        }
        if (sorts != undefined && sorts.length != 0) {
            if (this.api) {
                this.api.showLoadingOverlay()
            }
        }

        sorts.sort((a, b) => {
            return a.sortedAt - b.sortedAt
        })
        sorts = sorts.map(sort => {
            return { sortColumn: sort.sortColumn, sortDirection: sort.sortDirection }
        })

        this.setState({
            need_data_source_refresh: true
        })

        if (this.state.requestType === 'group' && this.state.value === 'VIEW_BY_GROUP') {
            if (sorts != undefined && sorts.length != 0) {
                this.groupOptions.sort = sorts[0];
                this.groupOptions.pageNumber = 0;
                this.props.fetchGroups(this.groupOptions);
            }


        } else
            if (this.state.requestType === 'device' && this.state.value === 'VIEW_BY_DEVICE') {
                //let options=;
                if (sorts != undefined && sorts.length != 0) {
                    this.deviceOptions.sort = sorts[0];
                    this.deviceOptions.pageNumber = 0;

                    this.props.fetchDevices(this.deviceOptions);
                }
            } else if (this.state.requestType === 'device' && this.state.value === 'VIEW_BY_GROUP') {
                //let options=;
                if (sorts != undefined && sorts.length != 0) {
                    this.deviceOptions.sort = sorts[0];
                    this.deviceOptions.pageNumber = 0;

                    this.props.fetchGroupDevices(this.deviceOptions);
                }
            } else if (this.state.requestType === 'profile' && this.state.value === 'VIEW_BY_GROUP') {
                //let options=;
                if (sorts != undefined && sorts.length != 0) {
                    this.profileOptions.sort = sorts[0];
                    this.profileOptions.pageNumber = 0;

                    this.props.fetchGroupProfiles(this.profileOptions);
                }
            } else if (this.state.requestType === 'profile' && this.state.value === 'VIEW_BY_DEVICE') {
                //let options=;
                if (sorts != undefined && sorts.length != 0) {
                    this.profileOptions.sort = sorts[0];

                    this.profileOptions.pageNumber = 0;
                    this.props.fetchDeviceProfiles(this.profileOptions);
                }
            }

    }

    onFilter(filterdata) {
        //let colFilters = this.get_filters()
        
        let colFilters = filterdata.colFilters;
        this.setState({
            need_data_source_refresh: true,

        })
        if (this.api) {
            this.api.showLoadingOverlay()
        }

        if (this.state.requestType === 'group' && this.state.value === 'VIEW_BY_GROUP') {
            if (colFilters != undefined && colFilters.length != 0) {
                this.groupOptions.filter = colFilters;
            }
            else {
                this.groupOptions.filter = [];
            }
            this.groupOptions.pageNumber = 0;
            this.props.fetchGroups(this.groupOptions);



        } else
            if (this.state.requestType === 'device' && this.state.value === 'VIEW_BY_DEVICE') {
                //let options=;
                if (colFilters != undefined && colFilters.length != 0) {
                    this.deviceOptions.filter = colFilters;
                }
                else
                    this.deviceOptions.filter = [];

                this.deviceOptions.pageNumber = 0;

                this.props.fetchDevices(this.deviceOptions);
            } else if (this.state.requestType === 'device' && this.state.value === 'VIEW_BY_GROUP') {
                //let options=;
                if (colFilters != undefined && colFilters.length != 0) {
                    this.deviceOptions.filter = colFilters;
                }
                else
                    this.deviceOptions.filter = [];
                this.deviceOptions.pageNumber = 0;
                this.props.fetchGroupDevices(this.deviceOptions);
            } else if (this.state.requestType === 'profile' && this.state.value === 'VIEW_BY_GROUP') {
                //let options=;
                if (colFilters != undefined && colFilters.length != 0) {
                    this.profileOptions.filter = colFilters;
                }
                else
                    this.profileOptions.filter = [];

                this.profileOptions.pageNumber = 0;

                this.props.fetchGroupProfiles(this.profileOptions);
            } else if (this.state.requestType === 'profile' && this.state.value === 'VIEW_BY_DEVICE') {
                //let options=;
                if (colFilters != undefined && colFilters.length != 0) {
                    this.profileOptions.filter = colFilters;
                }
                else
                    this.profileOptions.filter = [];
                this.profileOptions.pageNumber = 0;

                this.props.fetchDeviceProfiles(this.profileOptions);

            }
    }


    apiError = () => {
        this.setState({
            status: 'failure',
        })
    }

    onPageChange(pageNumber) {


        if (this.state.requestType === 'device' && this.state.value === 'VIEW_BY_DEVICE') {

            this.deviceOptions.pageNumber = pageNumber;
            this.props.fetchDevices(this.deviceOptions);
        } else if (this.state.requestType === 'profile' && this.state.value === 'VIEW_BY_DEVICE') {

            this.profileOptions.pageNumber = pageNumber;
            this.props.fetchDeviceProfiles(this.profileOptions);
        } else if (this.state.requestType === 'device' && this.state.value === 'VIEW_BY_GROUP') {

            this.deviceOptions.pageNumber = pageNumber;
            this.props.fetchGroupDevices(this.deviceOptions);
        } else if (this.state.requestType === 'profile' && this.state.value === 'VIEW_BY_GROUP') {

            this.profileOptions.pageNumber = pageNumber;
            this.props.fetchGroupProfiles(this.profileOptions);
        }
        else if (this.state.requestType === 'group' && this.state.value === 'VIEW_BY_GROUP') {
            this.groupOptions.pageNumber = pageNumber;
            this.props.fetchGroups(this.groupOptions);
        }

    }

    updateData(data, params) {
        if (params.context) {
            params.context.paramsInfo = params
            if (params.context.startRow != params.startRow) {

                params.context.startRow = params.startRow
                // Change the page number in store and state
                let pageSize = params.endRow - params.startRow,
                    nextPageNumber = params.startRow / pageSize;
                if (params.context.api) {
                    params.context.api.showLoadingOverlay()
                }
                /*if(params.context.getRowsCount==2){
                    nextPageNumber=0
                    params.context.startRow=0
                    params.context.paramsInfo.startRow=0
                    params.context.paramsInfo.endRow=PAGE_SIZE
                    
                }*/
                params.context.onPageChange(nextPageNumber)



            } else {

                setTimeout(function () {

                    var lastRow = -1;
                    if (data.totalCount <= params.endRow) {
                        lastRow = data.totalCount;
                    }

                    // params.context.getRowsCount=params.context.getRowsCount+1;
                    if (params.context && params.context.api) {
                        params.context.api.hideOverlay()
                    }


                    params.successCallback(data.data, lastRow);


                }, 500);
            }
        }
    }

    setGridDataSource(data) {
        
        var datasource = {
            rowCount: null,
            getRows: function (params) {

                params.context.updateData(data, params)
            }


        };
        
        if (this.api) {
            this.api.setDatasource(datasource)
        }

    }

    componentWillMount() {

        if (this.state.value === 'VIEW_BY_GROUP') {
            this.groupOptions = Object.assign({}, INIT_GROUP_OPTIONS);
            this.props.fetchGroups(this.groupOptions);
        }


    }

    componentWillReceiveProps(nextProps) {

        this.setState({
            profiles: nextProps.profiles,
            groups: nextProps.groups,
            devices: (typeof nextProps.devices !== 'undefined') ? nextProps.devices : [],
            allGroupMemberDeviceDetails: (typeof nextProps.allGroupMemberDeviceDetails !== 'undefined') ? nextProps.allGroupMemberDeviceDetails : [],
            requestType: nextProps.requestType,
            dashCardView: nextProps.dashCardView,
            device: nextProps.device,
            profile: nextProps.profile,
            filter: nextProps.filter,
            status: nextProps.errorMessage != null && nextProps.errorMessage != undefined && nextProps.errorMessage != '' ? 'failure' : 'ready'

        }, () => {
            if (this.api !== undefined) {
                this.api.sizeColumnsToFit();
                this.api.refreshCells({ force: true });//,columns: ["name"]
            }
        })

        if (nextProps.requestType == 'device') {
            let data = nextProps.devices.data;
            if (data != null && data != undefined && data.data != null && data.data != undefined && data.data.length != 0) {
                this.showNoRowsOverlay = false
            }
            else {
                this.showNoRowsOverlay = true
            }

            if (this.paramsInfo != null && !this.state.need_data_source_refresh) {

                this.updateData(nextProps.devices.data, this.paramsInfo)
            }
            else if (this.state.need_data_source_refresh) {
                this.setState({
                    need_data_source_refresh: false,
                })
                this.setGridDataSource(nextProps.devices.data)
                this.setGridDataSource(nextProps.devices.data)

            }

        }
        else if (nextProps.requestType == 'profile') {
            let data = nextProps.profiles.data;
            if (data != null && data != undefined && data.data != null && data.data != undefined && data.data.length != 0) {
                this.showNoRowsOverlay = false
            }
            else {
                this.showNoRowsOverlay = true
            }

            if (this.paramsInfo != null && !this.state.need_data_source_refresh) {

                this.updateData(nextProps.profiles.data, this.paramsInfo)
            }
            else if (this.state.need_data_source_refresh) {
                this.setState({
                    need_data_source_refresh: false,
                })
                this.setGridDataSource(nextProps.profiles.data)
                this.setGridDataSource(nextProps.profiles.data)

            }

        }
        else if (nextProps.requestType == 'group') {
            let data = nextProps.groups.data;
            if (data != null && data != undefined && data.data != null && data.data != undefined && data.data.length != 0) {
                this.showNoRowsOverlay = false
            }
            else {
                this.showNoRowsOverlay = true
            }

            if (this.paramsInfo != null && !this.state.need_data_source_refresh) {


                this.updateData(nextProps.groups.data, this.paramsInfo)
            }
            else if (this.state.need_data_source_refresh) {
                this.setState({
                    need_data_source_refresh: false,
                })
                this.setGridDataSource(nextProps.groups.data)
                this.setGridDataSource(nextProps.groups.data)

            }

        }

        if (this.state.exportData !== undefined && this.state.exportData != null && nextProps.exportData.data.length > 0) {

            this.processAndExportData(nextProps.exportData);
        }

    }

    processAndExportData = (inputExportData) => {

        let columnDelimiter = ',';
        let lineDelimiter = '\n';
        let dataToExport = '';
        let keys = [];
        let cntr = 0;
        let exportFileName = "GroupsData_"

        if (this.state.requestType === 'profile')
            exportFileName = "ProfilesData_"
        else if (this.state.requestType === 'device')
            exportFileName = "DevicesData_"

        for (let column of this.api.sortController.columnController.gridColumns) {


            if (cntr > 0) dataToExport += columnDelimiter
            dataToExport = dataToExport + column.colDef.headerName;
            let fieldName = column.colDef.field;
            if (fieldName !== undefined && fieldName == '')
                keys.push('customMCSDashboardField')
            else
                keys.push(column.colDef.field)
            cntr++;
        }

        dataToExport += lineDelimiter
        inputExportData.data.forEach(item => {
            let ctr = 0
            keys.forEach(key => {
                if (ctr > 0) dataToExport += columnDelimiter
                let itemValue = item[key]

                if (key == 'customMCSDashboardField') {
                    if (this.state.requestType === 'profile' && this.state.value === 'VIEW_BY_DEVICE') {
                        let lastDeployedDate = '';
                        if (item.lastDeployedDate != null && item.lastDeployedDate != undefined)
                            lastDeployedDate = item.lastDeployedDate
                        let status = '';
                        if (item.status != null && item.status != undefined)
                            status = item.status


                        itemValue = status + `  ` + lastDeployedDate
                    }
                    else if (this.state.requestType === 'device' && (this.state.value === 'VIEW_BY_GROUP' || this.state.value === 'VIEW_BY_DEVICE')) {
                        let status = '';
                        if (item.status != null && item.status != undefined)
                            status = item.status
                        let profName = '';
                        if (item.profileName !== undefined && item.profileName !== null)
                            profName = item.profileName;
                        let lastDeployedDate = '';
                        if (item.lastDeployedDate !== undefined && item.lastDeployedDate !== null)
                            lastDeployedDate = item.lastDeployedDate;
                        itemValue = status + `  ` + profName + `  ` + lastDeployedDate
                    }
                }

                if (itemValue == null || itemValue == undefined) {
                    dataToExport += ''
                }/* else if (itemValue && key === 'lastUpdateTime') {	
    	                          dataDownload += '"' + moment(+itemValue).format('lll') + '"'	
    	                      } else if (itemValue && typeof itemValue === 'string' && itemValue.includes(',')) {	
    	                          dataDownload += '"' + item[key] + '"'	
    	                      } else if (itemValue && typeof itemValue === 'object' && key === 'group') {	
    	                          var objectToAdd = itemValue.filter(item => { return item })	
    	                          var stringConversion = ''	
    	                          stringConversion += objectToAdd	
    	                          dataDownload += '"' + stringConversion + '"'	
    	                      }*/ else {
                    dataToExport += itemValue
                }
                ctr++
            })
            dataToExport += lineDelimiter
        })
        this.setState({
            exportData: { data: dataToExport },
            exportFileName: exportFileName + moment() + '.csv'
        }, () => {
            // click the CSVLink component to trigger the CSV download	
            this.dataDownloadLink.link.click()
        })
    }

    /*if(this.state.requestType === 'group' && this.state.value==='VIEW_BY_GROUP')
    {
     
         let groupOptions=Object.assign({},INIT_GROUP_OPTIONS);
         groupOptions.exportData=true;    	
         groupOptions.pageNumber=0;
         groupOptions.pageSize=PAGE_SIZE;
         this.props.fetchGroups(groupOptions);
	
	
     
    } else 
if(this.state.requestType === 'device' && this.state.value==='VIEW_BY_DEVICE')
{
	
    	
         let deviceOptions=Object.assign({},INIT_DEVICE_OPTIONS);
         deviceOptions.exportData=true;    	
         deviceOptions.pageNumber=0;
         deviceOptions.pageSize=PAGE_SIZE;
        this.props.fetchDevices(deviceOptions);
 
}else if(this.state.requestType === 'device' && this.state.value==='VIEW_BY_GROUP')
{
       //let options=;
       
     let deviceOptions=Object.assign({},INIT_DEVICE_OPTIONS);
     deviceOptions.exportData=true;    
     deviceOptions.pageNumber=0;
     deviceOptions.pageSize=PAGE_SIZE;
       this.props.fetchGroupDevices(this.deviceOptions);
 
}else if(this.state.requestType === 'profile' && this.state.value==='VIEW_BY_GROUP')
{
     //let options=;
 
     let profileOptions=Object.assign({},INIT_PROFILE_OPTIONS);
     profileOptions.exportData=true;  
     profileOptions.pageNumber=0;
     profileOptions.pageSize=PAGE_SIZE;
	
      this.props.fetchGroupProfiles(profileOptions);
 
}else if(this.state.requestType === 'profile' && this.state.value==='VIEW_BY_DEVICE')
{
     //let options=;
      
     let profileOptions=Object.assign({},INIT_PROFILE_OPTIONS);
     profileOptions.exportData=true;  
     profileOptions.pageNumber=0;
     profileOptions.pageSize=PAGE_SIZE;
      this.props.fetchDeviceProfiles(profileOptions);
 
}*/




    handleTableClick = row => {
        const history = this.props.history
        this.setState({ status: 'loading', highlightedCard: '' })
        this.paramsInfo = null;
        if (!this.props.customRowClicking) {
            //let el = row.event.target
            //let columnName = el.closest('[col-id]').getAttribute('col-id')
            //let itemType = row.data.entityType
            if (this.state.requestType === 'group') {
                if (this.state.value === 'VIEW_BY_GROUP') {
                    this.profileOptions = Object.assign({}, INIT_PROFILE_OPTIONS);
                    this.profileOptions.groupId = row.data.groupId;

                    this.state.breadCrumbState.push({ "method": "this.props.fetchGroupProfiles", "options": this.profileOptions })
                    this.state.groupNameSelected.push(row.data.groupName)
                    this.state.groupIdSelected.push(row.data.groupId)
                    this.props.fetchGroupProfiles(this.profileOptions)




                    //this.state.requestType === 'profile'
                }
            } else if (this.state.requestType === 'profile') {
                this.state.profileNameSelected.push(row.data.profileName)

                if (this.state.value === 'VIEW_BY_GROUP') {
                    this.state.profileIdSelected.push(row.data.groupProfileId)
                    this.deviceOptions = Object.assign({}, INIT_DEVICE_OPTIONS);
                    this.deviceOptions.profileId = row.data.groupProfileId;
                    this.state.breadCrumbState.push({ "method": "this.props.fetchGroupDevices", "options": this.deviceOptions })
                    this.props.fetchGroupDevices(this.deviceOptions);
                    // this.props.handleGroupProfileClick(row, history)

                }
                else if (this.state.value === 'VIEW_BY_DEVICE') {


                    this.state.profileIdSelected.push(row.data.deviceProfileId)
                    this.state.breadCrumbState.push({ "method": "this.props.handleProfileClick", "row": row })
                    this.props.handleProfileClick(row, history)

                }
                //this.state.requestType === 'device'
            } else if (this.state.requestType === 'device') {

                this.state.deviceNameSelected.push(row.data.deviceName)

                if (this.state.value === 'VIEW_BY_GROUP') {
                    if (this.state.profileIdSelected !== undefined && this.state.profileIdSelected.length != 0) {
                        let groupProfileId = this.state.profileIdSelected[this.state.profileIdSelected.length - 1]
                        row.data.groupProfileId = groupProfileId;
                    }
                    this.state.deviceIdSelected.push(row.data.deviceId)
                    this.state.breadCrumbState.push({ "method": "this.props.handleGroupDeviceClick", "row": row })
                    this.props.handleGroupDeviceClick(row, history)

                }
                else
                    if (this.state.value === 'VIEW_BY_DEVICE') {
                        this.profileOptions = Object.assign({}, INIT_PROFILE_OPTIONS);
                        this.profileOptions.deviceId = row.data.csId;
                        this.state.deviceIdSelected.push(row.data.csId)

                        this.state.breadCrumbState.push({ "method": "this.props.fetchDeviceProfiles", "options": this.profileOptions })
                        // this.props.handleDeviceClick(row,history) //TODO kolas01 

                        this.props.fetchDeviceProfiles(this.profileOptions)



                    }
            }
        }
    }

    cardClickHandler = (event) => {
        this.setState({
            status: 'loading', disableGroupsBars: [false, false, false, false],
            disableProfilesBars: [false, false, false, false],
            disableDevicesBars: [false, false, false, false],
            highlightedCard: event.type
        })
        this.paramsInfo = null;

        if (typeof event.type !== 'undefined' && event.type === 'Groups' && typeof this.state.value !== 'undefined' && this.state.value === 'VIEW_BY_GROUP' && this.state.dashCardView == 'DASHCARDVIEW_GROUPS_PROFILES_DEVICES') {
            this.groupOptions = Object.assign({}, INIT_GROUP_OPTIONS);
            this.state.breadCrumbState.push({ "method": "this.props.fetchGroups", "options": this.groupOptions })
            this.props.fetchGroups(this.groupOptions);

        } else if (typeof event.type !== 'undefined' && event.type === 'Profiles' && typeof this.state.value !== 'undefined' && this.state.value === 'VIEW_BY_GROUP' && this.state.dashCardView == 'DASHCARDVIEW_GROUPS_PROFILES_DEVICES') {
            this.profileOptions = Object.assign({}, INIT_PROFILE_OPTIONS);
            this.state.breadCrumbState.push({ "method": "this.props.fetchGroupProfiles", "options": this.profileOptions })
            this.props.fetchGroupProfiles(this.profileOptions);

        } else if (typeof event.type !== 'undefined' && event.type === 'Devices' && typeof this.state.value !== 'undefined' && this.state.value === 'VIEW_BY_GROUP' && this.state.dashCardView == 'DASHCARDVIEW_GROUPS_PROFILES_DEVICES') {
            this.deviceOptions = Object.assign({}, INIT_DEVICE_OPTIONS);
            this.state.breadCrumbState.push({ "method": "this.props.fetchGroupDevices", "options": this.deviceOptions })

            //this.props.fetchGroupsMemberDevicesByStatus('all');
            this.props.fetchGroupDevices(this.deviceOptions)
        } else if (typeof event.type !== 'undefined' && event.type === 'Profiles' && typeof this.state.value !== 'undefined' && this.state.value === 'VIEW_BY_GROUP' && this.state.dashCardView == 'DASHCARDVIEW_PROFILES') {
            if (this.state.groupIdSelected !== undefined && this.state.groupIdSelected.length != 0) {
                let groupId = this.state.groupIdSelected[this.state.groupIdSelected.length - 1];
                //this.props.fetchGroupProfilesByStatusAndGroupId('all',groupId)
                this.profileOptions = Object.assign({}, INIT_PROFILE_OPTIONS);
                this.profileOptions.groupId = groupId;
                this.props.fetchGroupProfiles(this.profileOptions);
                // this.state.breadCrumbState.push({"method":"this.props.fetchGroupProfilesByStatusAndGroupId","status":"all","identifier":groupId})
            }
            else {
                this.groupOptions = Object.assign({}, INIT_GROUP_OPTIONS);
                this.state.breadCrumbState.push({ "method": "this.props.fetchGroups", "options": this.groupOptions })
                this.props.fetchGroups(this.groupOptions);
            }



        } else if (typeof event.type !== 'undefined' && event.type === 'Devices' && typeof this.state.value !== 'undefined' && this.state.value === 'VIEW_BY_GROUP' && this.state.dashCardView == 'DASHCARDVIEW_DEVICES') {
            if (this.state.profileIdSelected !== undefined && this.state.profileIdSelected.length != 0) {
                let groupProfileId = this.state.profileIdSelected[this.state.profileIdSelected.length - 1]
                this.deviceOptions = Object.assign({}, INIT_DEVICE_OPTIONS);
                this.deviceOptions.profileId = groupProfileId;
                //this.props.fetchGroupsMemberDevicesByStatusAndProfileId('all',groupProfileId)
                this.props.fetchGroupDevices(this.deviceOptions);
                //this.state.breadCrumbState.push({"method":"this.props.fetchGroupsMemberDevicesByStatusAndProfileId","status":"all","identifier":groupProfileId})
            }
            else {
                this.groupOptions = Object.assign({}, INIT_GROUP_OPTIONS);
                this.state.breadCrumbState.push({ "method": "this.props.fetchGroups", "options": this.groupOptions })
                this.props.fetchGroups(this.groupOptions);
            }


        }
        else if (typeof event.type !== 'undefined' && event.type === 'Profiles' && typeof this.state.value !== 'undefined' && this.state.value === 'VIEW_BY_DEVICE' && this.state.dashCardView == 'DASHCARDVIEW_DEVICES_PROFILES') {


            this.profileOptions = Object.assign({}, INIT_PROFILE_OPTIONS);
            this.state.breadCrumbState.push({ "method": "this.props.fetchDeviceProfiles", "options": this.profileOptions })
            this.props.fetchDeviceProfiles(this.profileOptions);

        } else if (typeof event.type !== 'undefined' && event.type === 'Devices' && typeof this.state.value !== 'undefined' && this.state.value === 'VIEW_BY_DEVICE' && this.state.dashCardView == 'DASHCARDVIEW_DEVICES_PROFILES') {
            this.deviceOptions = Object.assign({}, INIT_DEVICE_OPTIONS);
            this.state.breadCrumbState.push({ "method": "this.props.fetchDevices", "options": this.deviceOptions })
            this.props.fetchDevices(this.deviceOptions);

        } else if (typeof event.type !== 'undefined' && event.type === 'Profiles' && typeof this.state.value !== 'undefined' && this.state.value === 'VIEW_BY_DEVICE' && this.state.dashCardView == 'DASHCARDVIEW_DPROFILES') {
            if (this.state.deviceIdSelected !== undefined && this.state.deviceIdSelected.length != 0) {
                let deviceIdSelected = this.state.deviceIdSelected[this.state.deviceIdSelected.length - 1]
                this.profileOptions = Object.assign({}, INIT_PROFILE_OPTIONS);
                this.profileOptions.deviceId = deviceIdSelected;
                //this.props.fetchProfilesByStatusAndDeviceId('all',deviceIdSelected)
                this.props.fetchDeviceProfiles(this.profileOptions)
                // this.state.breadCrumbState.push({"method":"this.props.fetchProfilesByStatusAndDeviceId","status":"all","identifier":deviceIdSelected})
            }
            else {
                this.deviceOptions = Object.assign({}, INIT_DEVICE_OPTIONS);

                this.state.breadCrumbState.push({ "method": "this.props.fetchDevices", "options": this.deviceOptions })

                this.props.fetchDevices(this.deviceOptions);
            }

        }

    }

    handleBreadCrumbClick = () => {
        this.setState({
            status: 'loading', disableGroupsBars: [false, false, false, false],
            disableProfilesBars: [false, false, false, false],
            disableDevicesBars: [false, false, false, false]
        })
        let breadCrumbToClick = {};
        this.paramsInfo = null;
        /*  if(this.state.filter!==undefined && this.state.filter!=='')
          {
          breadCrumbToClick={"method":"this.props.fetchGroups"};
          }
          else */
        if (this.state.breadCrumbState.length == 1)
            breadCrumbToClick = this.state.breadCrumbState.pop();
        else if (this.state.breadCrumbState.length > 1) {

            this.state.breadCrumbState.pop();
            breadCrumbToClick = this.state.breadCrumbState.pop();
        }
        else {

            breadCrumbToClick = { "method": "this.props.fetchGroups", "options": INIT_GROUP_OPTIONS };
        }


        switch (breadCrumbToClick.method) {
            /*   case "this.props.handleGroupClick": 
                                                       this.state.breadCrumbState.push({"method":"this.props.handleGroupClick","row":breadCrumbToClick.row});
                                                       this.state.groupNameSelected.push(breadCrumbToClick.row.data.groupName);
                                                       this.props.handleGroupClick(breadCrumbToClick.row);
                                                       if(this.state.groupIdSelected!==undefined && this.state.groupIdSelected.length!=0)
                                                       {
                                                           this.state.groupIdSelected.pop();        
                                                       }
                                                       break;
               case "this.props.handleGroupProfileClick":
                                                       this.state.breadCrumbState.push({"method":"this.props.handleGroupProfileClick","row":breadCrumbToClick.row});
                                                       this.state.profileNameSelected.push(breadCrumbToClick.row.data.profileName);
                                                       this.props.handleGroupProfileClick(breadCrumbToClick.row);
                                                       if(this.state.profileIdSelected!==undefined && this.state.profileIdSelected.length!=0)
                                                       {
                                                           this.state.profileIdSelected.pop();        
                                                       }
                                                       break;
              
               
               case "this.props.handleDeviceClick": this.state.deviceNameSelected.push(breadCrumbToClick.row.data.deviceName);
                                                    this.props.handleDeviceClick(breadCrumbToClick.row);
                                                   break;*/

            case "this.props.fetchGroups": breadCrumbToClick.options.pageNumber = 0;
                breadCrumbToClick.options.status = '';
                this.state.breadCrumbState.push({ "method": "this.props.fetchGroups", "options": breadCrumbToClick.options });
                this.groupOptions = Object.assign({}, breadCrumbToClick.options);
                this.props.fetchGroups(breadCrumbToClick.options); break;
            case "this.props.fetchGroupProfiles": breadCrumbToClick.options.pageNumber = 0;
                breadCrumbToClick.options.status = '';
                this.profileOptions = Object.assign({}, breadCrumbToClick.options);
                this.state.breadCrumbState.push({ "method": "this.props.fetchGroupProfiles", "options": breadCrumbToClick.options });

                this.props.fetchGroupProfiles(breadCrumbToClick.options); break;
            case "this.props.fetchGroupDevices": breadCrumbToClick.options.pageNumber = 0;
                breadCrumbToClick.options.status = '';
                this.deviceOptions = Object.assign({}, breadCrumbToClick.options);
                this.state.breadCrumbState.push({ "method": "this.props.fetchGroupDevices", "options": breadCrumbToClick.options });
                this.props.fetchGroupDevices(breadCrumbToClick.options); break;
            case "this.props.handleGroupDeviceClick":
                this.state.breadCrumbState.push({ "method": "this.props.handleGroupDeviceClick", "row": breadCrumbToClick.row });
                this.state.deviceNameSelected.push(breadCrumbToClick.row.data.deviceName);
                this.props.handleGroupDeviceClick(breadCrumbToClick.row);
                break;
            case "this.props.fetchDevices": breadCrumbToClick.options.pageNumber = 0;
                breadCrumbToClick.options.status = '';
                this.deviceOptions = Object.assign({}, breadCrumbToClick.options);
                this.state.breadCrumbState.push({ "method": "this.props.fetchDevices", "options": breadCrumbToClick.options });
                this.props.fetchDevices(breadCrumbToClick.options); break;
            case "this.props.fetchDeviceProfiles": breadCrumbToClick.options.pageNumber = 0;
                breadCrumbToClick.options.status = '';
                this.profileOptions = Object.assign({}, breadCrumbToClick.options);
                this.state.breadCrumbState.push({ "method": "this.props.fetchDeviceProfiles", "options": breadCrumbToClick.options });
                this.props.fetchDeviceProfiles(breadCrumbToClick.options); break;

            case "this.props.handleProfileClick":
                this.state.breadCrumbState.push({ "method": "this.props.handleProfileClick", "row": breadCrumbToClick.row });
                this.state.profileNameSelected.push(breadCrumbToClick.row.data.profileName);
                this.props.handleProfileClick(breadCrumbToClick.row);
                /* if(this.state.profileIdSelected!==undefined && this.state.profileIdSelected.length!=0)
                 {
                     this.state.profileIdSelected.pop();        
                 }*/
                break;


            /*case "this.props.fetchDeviceProfiles":this.state.breadCrumbState.push({"method":"this.props.fetchDeviceProfiles"});
            this.props.fetchDeviceProfiles();break;
            case "this.props.fetchDevices":this.state.breadCrumbState.push({"method":"this.props.fetchDevices",options:{"pageNumber":INIT_PAGE_NUMBER,"pageSize":PAGE_SIZE}});
            this.props.fetchDevices(this.getInitDevicesOptions());break;*/
            /* case "this.props.getGroupMemberDevicesByStatus": this.state.breadCrumbState.push({"method":" this.props.getGroupMemberDevicesByStatus","data":breadCrumbToClick.data})           
             this.props.getGroupMemberDevicesByStatus(breadCrumbToClick.data);break;
             case "this.props.getDevicesByStatus": this.state.breadCrumbState.push({"method":" this.props.getDevicesByStatus","data":breadCrumbToClick.data})     
             this.props.getDevicesByStatus(breadCrumbToClick.data);break;
             case "this.props.fetchGroupProfilesByStatus": this.state.breadCrumbState.push({"method":" this.props.fetchGroupProfilesByStatus","data":breadCrumbToClick.data})
             this.props.fetchGroupProfilesByStatus(breadCrumbToClick.data);break;
             case "this.props.fetchProfilesByStatus": this.state.breadCrumbState.push({"method":" this.props.fetchProfilesByStatus","data":breadCrumbToClick.data})
             this.props.fetchProfilesByStatus(breadCrumbToClick.data);break;
             case "this.props.fetchGroupsByStatus": this.state.breadCrumbState.push({"method":" this.props.fetchGroupsByStatus","data":breadCrumbToClick.data})
             this.props.fetchGroupsByStatus(breadCrumbToClick.data);break;
             case "this.props.fetchProfilesByStatusAndDeviceId": this.state.breadCrumbState.push({"method":" this.props.fetchProfilesByStatusAndDeviceId","data":breadCrumbToClick.data}) 
             this.props.fetchProfilesByStatusAndDeviceId(breadCrumbToClick.data.itemName,breadCrumbToClick.data.deviceProfileId);break;
              case "this.props.fetchGroupProfilesByStatusAndGroupId": this.state.breadCrumbState.push({"method":" this.props.fetchGroupProfilesByStatusAndGroupId","data":breadCrumbToClick.data}) 
             this.props.fetchGroupProfilesByStatusAndGroupId(breadCrumbToClick.data.itemName,breadCrumbToClick.data.groupId);break;
              case "this.props.fetchGroupsMemberDevicesByStatusAndProfileId": this.state.breadCrumbState.push({"method":" this.props.fetchGroupsMemberDevicesByStatusAndProfileId","data":breadCrumbToClick.data}) 
             this.props.fetchGroupsMemberDevicesByStatusAndProfileId(breadCrumbToClick.data.itemName,breadCrumbToClick.data.groupProfileId);break;
 */
            default: this.setState({
                groupNameSelected: [],
                profileNameSelected: [],
                deviceNameSelected: [],
                groupIdSelected: [],
                profileIdSelected: [],
                deviceIdSelected: [],
                breadCrumbState: this.state.value === 'VIEW_BY_GROUP' ? [{ "method": "this.props.fetchGroups", "options": INIT_GROUP_OPTIONS }] : [{ "method": "this.props.fetchDevices", "options": INIT_DEVICE_OPTIONS }],
                filter: '',
                highlightedCard: this.state.value === 'VIEW_BY_GROUP' ? 'Groups' : 'Devices'
            });

                if (this.state.value === 'VIEW_BY_GROUP') {
                    this.groupOptions = Object.assign({}, INIT_GROUP_OPTIONS);
                    this.props.fetchGroups(this.groupOptions)
                }
                else {
                    this.deviceOptions = Object.assign({}, INIT_DEVICE_OPTIONS);
                    this.props.fetchDevices(this.deviceOptions); break;
                }
                break;
        }




    }

    barClickHandler = (data) => {
        this.setState({
            status: 'loading',

            highlightedCard: data.type
        }

        )
        this.paramsInfo = null;
        var event = data.event;
        event.cancelBubble = true;
        if (event.stopPropagation) event.stopPropagation();

        let disableDevicesBars = [false, false, false, false];
        let disableProfilesBars = [false, false, false, false];
        let disableGroupsBars = [false, false, false, false];

        if (data.type === 'Devices') {

            this.deviceOptions.pageNumber = INIT_PAGE_NUMBER;
            this.deviceOptions.status = data.itemName;
            disableDevicesBars = [true, true, true, true];
            disableDevicesBars[data.i] = false
            this.setState({ disableDevicesBars: disableDevicesBars, disableProfilesBars: disableProfilesBars, disableGroupsBars: disableGroupsBars });
            if (this.state.value === 'VIEW_BY_GROUP' && this.state.dashCardView == 'DASHCARDVIEW_GROUPS_PROFILES_DEVICES') {
                //  this.state.breadCrumbState.push({"method":" this.props.fetchGroupsMemberDevicesByStatus","data":data})   
                //this.props.fetchGroupsMemberDevicesByStatus(data.itemName);
                this.props.fetchGroupDevices(this.deviceOptions);


            }
            else if (this.state.value === 'VIEW_BY_GROUP' && this.state.dashCardView == 'DASHCARDVIEW_DEVICES') {
                if (this.state.profileIdSelected !== undefined && this.state.profileIdSelected.length != 0) {
                    let profileIdSelected = this.state.profileIdSelected[this.state.profileIdSelected.length - 1]
                    this.deviceOptions.profileId = profileIdSelected;
                    //this.state.breadCrumbState.push({"method":" this.props.fetchGroupsMemberDevicesByStatusAndProfileId","data":{"itemName":data.itemName,"groupProfileIdSelected":profileIdSelected}})   
                    //this.props.fetchGroupsMemberDevicesByStatusAndProfileId(data.itemName,profileIdSelected)

                    this.props.fetchGroupDevices(this.deviceOptions);

                }
                else {
                    this.groupOptions = Object.assign({}, INIT_GROUP_OPTIONS);
                    this.state.breadCrumbState.push({ "method": "this.props.fetchGroups", "options": this.groupOptions })
                    this.props.fetchGroups(this.groupOptions);
                }




            }
            else if (this.state.value === 'VIEW_BY_DEVICE' && this.state.dashCardView == 'DASHCARDVIEW_DEVICES_PROFILES') {
                // this.state.breadCrumbState.push({"method":" this.props.fetchDevicesByStatus","data":data})   
                // this.props.fetchDevicesByStatus(data.itemName);

                this.props.fetchDevices(this.deviceOptions)

            }
        } else if (data.type === 'Profiles') {
            this.profileOptions.status = data.itemName;
            this.profileOptions.pageNumber = INIT_PAGE_NUMBER;
            disableProfilesBars = [true, true, true, true];
            disableProfilesBars[data.i] = false
            this.setState({ disableDevicesBars: disableDevicesBars, disableProfilesBars: disableProfilesBars, disableGroupsBars: disableGroupsBars });
            if (this.state.value === 'VIEW_BY_GROUP' && this.state.dashCardView == 'DASHCARDVIEW_GROUPS_PROFILES_DEVICES') {
                // this.state.breadCrumbState.push({"method":" this.props.fetchGroupProfilesByStatus","data":data})   
                //this.props.fetchGroupProfilesByStatus(data.itemName);
                this.props.fetchGroupProfiles(this.profileOptions);

            } else if (this.state.value === 'VIEW_BY_GROUP' && this.state.dashCardView == 'DASHCARDVIEW_PROFILES') {



                if (this.state.groupIdSelected !== undefined && this.state.groupIdSelected.length != 0) {
                    let groupIdSelected = this.state.groupIdSelected[this.state.groupIdSelected.length - 1]
                    this.profileOptions.groupId = groupIdSelected

                    // this.state.breadCrumbState.push({"method":" this.props.fetchGroupProfilesByStatusAndGroupId","data":{"itemName":data.itemName,"groupIdSelected":groupIdSelected}})   
                    //this.props.fetchGroupProfilesByStatusAndGroupId(data.itemName,groupIdSelected)
                    this.props.fetchGroupProfiles(this.profileOptions)


                }
                else {
                    this.groupOptions = Object.assign({}, INIT_GROUP_OPTIONS);
                    this.state.breadCrumbState.push({ "method": "this.props.fetchGroups", "options": this.groupOptions })
                    this.props.fetchGroups(this.groupOptions);
                }





            }

            else if (this.state.value === 'VIEW_BY_DEVICE' && this.state.dashCardView == 'DASHCARDVIEW_DEVICES_PROFILES') {
                // this.state.breadCrumbState.push({"method":" this.props.fetchProfilesByStatus","data":data})  

                //this.props.fetchProfilesByStatus(data.itemName);
                this.props.fetchDeviceProfiles(this.profileOptions);
            }
            else if (this.state.value === 'VIEW_BY_DEVICE' && this.state.dashCardView == 'DASHCARDVIEW_DPROFILES') {
                if (this.state.deviceIdSelected !== undefined && this.state.deviceIdSelected.length != 0) {
                    let deviceIdSelected = this.state.deviceIdSelected[this.state.deviceIdSelected.length - 1]
                    this.profileOptions.deviceId = deviceIdSelected;
                    // this.props.fetchProfilesByStatusAndDeviceId(data.itemName,deviceIdSelected);
                    // this.state.breadCrumbState.push({"method":"this.props.fetchProfilesByStatusAndDeviceId","status":"all","identifier":deviceIdSelected})
                    this.props.fetchDeviceProfiles(this.profileOptions)
                }
                else {
                    this.deviceOptions = Object.assign({}, INIT_DEVICE_OPTIONS);
                    this.state.breadCrumbState.push({ "method": "this.props.fetchDevices", "options": this.deviceOptions })
                    this.props.fetchDevices(this.deviceOptions);
                }


            }
        } else if (data.type === 'Groups') {
            disableGroupsBars = [true, true, true, true];
            disableGroupsBars[data.i] = false
            this.groupOptions.status = data.itemName;
            this.groupOptions.pageNumber = INIT_PAGE_NUMBER;

            this.setState({ disableDevicesBars: disableDevicesBars, disableProfilesBars: disableProfilesBars, disableGroupsBars: disableGroupsBars });
            //this.state.breadCrumbState.push({"method":" this.props.fetchGroupsByStatus","data":data})   
            // this.props.fetchGroupsByStatus(data.itemName);
            this.props.fetchGroups(this.groupOptions)

        }
    }

    getTabsInfo = () => {
        let info = [], buttonStyle = { height: '24px', fontFamily: 'Roboto', fontSize: '14px', fontWeight: 700, paddingRight: '32px' };
        for (let key in TabHeaders) {
            info.push((
                <Tab variant="fullWidth" id={key === 'VIEW_BY_GROUP'?"first-content-element":""} role="tab" inputRef={key === 'VIEW_BY_GROUP' ?? this.textInput} aria-label={TabHeaders[key]} label={TabHeaders[key]}
                    value={key} autoFocus={key === 'VIEW_BY_GROUP' ?true:false}
                  //  buttonStyle={key === 'VIEW_BY_GROUP' ? buttonStyle : Object.assign({}, buttonStyle, { paddingLeft: '32px', borderLeft: '1px solid #cccccc' })}
                   // className="tab-section"
                   // style={{ color: this.state.value === key ? '#333' : '#3272D9', height: '100% !important' }}
                />

            ));
        }
        return info;
    }
    getActiveTabContent = () => {

        let selectedStyle = { 'border': '1px solid #2A92B9', 'border-radius': '1px' };
        let groupsSelectedStyle = '';
        let profilesSelectedStyle = '';
        let devicesSelectedStyle = '';
        let backButton = Resource.get("Back"); //Back
        if (this.state.highlightedCard === 'Groups')
            groupsSelectedStyle = selectedStyle;
        else if (this.state.highlightedCard === 'Profiles')
            profilesSelectedStyle = selectedStyle;
        else if (this.state.highlightedCard === 'Devices')
            devicesSelectedStyle = selectedStyle;

        if (this.state.value === 'VIEW_BY_GROUP') {
            if (this.state.dashCardView == 'DASHCARDVIEW_GROUPS_PROFILES_DEVICES') {

                /*let showBreadcrumb=(this.state.filter!==undefined && this.state.filter!=='');
            
                if (showBreadcrumb == true)
                {*/

                return (
                    <div className="content-grid-mcs mcs-dashboard-grid" style={{ marginLeft: '10px' }}>

                        <MCSDashBoardCard type="Groups" style={Object.assign({}, groupsSelectedStyle)} counts={this.state.groups.groupsPercentages} disableKeys={this.state.disableGroupsBars} onCardclick={this.cardClickHandler} onBarClick={this.barClickHandler} />
                        <img src={arrowImage} alt='Forward Arrow Icon' alt='To' className="bitmap" />
                        <MCSDashBoardCard type="Profiles" style={Object.assign({}, profilesSelectedStyle)} counts={this.state.groups.groupProfilePercentages} disableKeys={this.state.disableProfilesBars} onCardclick={this.cardClickHandler} onBarClick={this.barClickHandler} />
                        <img src={arrowImage} alt='Forward Arrow Icon' alt='To' className="bitmap" />
                        <MCSDashBoardCard type="Devices" style={Object.assign({}, devicesSelectedStyle)} counts={this.state.groups.groupDevicePercentages} disableKeys={this.state.disableDevicesBars} onCardclick={this.cardClickHandler} onBarClick={this.barClickHandler} />

                    </div>
                )
                /*else
                {
                        return( 
                            
            <div className="content-grid-mcs mcs-dashboard-grid" >
                            <MCSDashBoardCard type="Groups" style={Object.assign({}, groupsSelectedStyle)}  counts={this.state.groups.groupsPercentages}  disableKeys={this.state.disableGroupsBars} onCardclick={this.cardClickHandler} onBarClick={this.barClickHandler} />
                        <img  src={arrowImage} className="bitmap" />
                            <MCSDashBoardCard type="Profiles" style={Object.assign({},profilesSelectedStyle)} counts={this.state.groups.groupProfilePercentages} disableKeys={this.state.disableProfilesBars} onCardclick={this.cardClickHandler} onBarClick={this.barClickHandler} />
                        <img  src={arrowImage} className="bitmap"/>
                            <MCSDashBoardCard type="Devices" style={Object.assign({},devicesSelectedStyle)} counts={this.state.groups.groupDevicePercentages} disableKeys={this.state.disableDevicesBars} onCardclick={this.cardClickHandler} onBarClick={this.barClickHandler} />
                       
                       
                        </div>)
                }*/

            } if (this.state.dashCardView == 'DASHCARDVIEW_PROFILES') {
                let groupNameSelected = '';
                if (this.state.groupNameSelected.length >= 1)
                    groupNameSelected = this.state.groupNameSelected[this.state.groupNameSelected.length - 1];


                let showBreadcrumb = (groupNameSelected !== 'undefined' && groupNameSelected !== '');
                /* if (this.state.filter!==undefined && this.state.filter!=='')
             {
               groupNameSelected='';
             }*/

                if (showBreadcrumb == true)
                    return (<div>
                        <div style={{display: 'inline-flex'}}>
                        <Tooltip arrow title={backButton} placement="top">
                        <IconButton autoFocus  onClick={this.state.status === 'loading' ? '' : this.handleBreadCrumbClick}  aria-label={backButton} style={{marginLeft:'3px',marginRight:'3px',marginTop:'3px'}}><ArrowBackIcon fontSize="large"/></IconButton>
                        </Tooltip>
                           <GroupsIcon style={{ 'vertical-align': 'middle', 'margin-right': '7px' }} />
                            <Typography component="h2" variant="h3">{groupNameSelected}</Typography>
                        </div>
                        <div className="content-grid-mcs mcs-dashboard-grid">

                            <MCSDashBoardCard type="Profiles" style={Object.assign({}, profilesSelectedStyle, { 'width': '100%' })} counts={this.state.profiles.groupProfilePercentages} disableKeys={this.state.disableProfilesBars} onCardclick={this.cardClickHandler} onBarClick={this.barClickHandler} />
                        </div></div>)
                else
                    return (
                        <div className="content-grid-mcs mcs-dashboard-grid">

                            <MCSDashBoardCard type="Profiles" style={Object.assign({}, profilesSelectedStyle, { 'width': '100%' })} counts={this.state.profiles.groupProfilePercentages} disableKeys={this.state.disableProfilesBars} onCardclick={this.cardClickHandler} onBarClick={this.barClickHandler} />
                        </div>)
            } else if (this.state.dashCardView == 'DASHCARDVIEW_DEVICES') {

                let profileNameSelected = '';
                if (this.state.profileNameSelected.length >= 1)
                    profileNameSelected = this.state.profileNameSelected[this.state.profileNameSelected.length - 1];

                let showBreadcrumb = profileNameSelected !== 'undefined' && profileNameSelected !== '';
                /* if (this.state.filter!==undefined && this.state.filter!=='')
             {
               profileNameSelected='';
             }*/
                if (showBreadcrumb == true)
                    return (<div>
                        <div style={{display: 'inline-flex'}}>
                        <Tooltip arrow title={backButton} placement="top">
                        <IconButton autoFocus  onClick={this.state.status === 'loading' ? '' : this.handleBreadCrumbClick}  aria-label={backButton} style={{marginLeft:'3px',marginRight:'3px',marginTop:'3px'}}><ArrowBackIcon fontSize="large"/></IconButton>
                        </Tooltip>
                        <ProfilesIcon style={{ 'vertical-align': 'middle', 'margin-right': '7px' }} />
                        <Typography component="h2" variant="h3">{profileNameSelected}</Typography>
                        </div>
                        <div className="content-grid-mcs mcs-dashboard-grid">

                            <MCSDashBoardCard type="Devices" style={Object.assign({}, devicesSelectedStyle, { 'width': '100%' })} counts={this.state.devices.groupDevicePercentages} disableKeys={this.state.disableDevicesBars} onCardclick={this.cardClickHandler} onBarClick={this.barClickHandler} />
                        </div></div>)
                else
                    return (<div className="content-grid-mcs mcs-dashboard-grid">

                        <MCSDashBoardCard type="Devices" style={Object.assign({}, devicesSelectedStyle, { 'width': '100%' })} counts={this.state.devices.groupDevicePercentages} disableKeys={this.state.disableDevicesBars} onCardclick={this.cardClickHandler} onBarClick={this.barClickHandler} />
                    </div>)

            } else if (this.state.dashCardView == 'DASHCARDVIEW_DEVICE_DETAILS') {

                let deviceNameSelected = '';
                if (this.state.deviceNameSelected.length >= 1)
                    deviceNameSelected = this.state.deviceNameSelected[this.state.deviceNameSelected.length - 1];

                let showBreadcrumb = deviceNameSelected !== 'undefined' && deviceNameSelected !== '';
                if (showBreadcrumb == true)
                    return (<div style={{display: 'inline-flex'}}>
                        <Tooltip arrow title={backButton} placement="top">
                        <IconButton autoFocus  onClick={this.state.status === 'loading' ? '' : this.handleBreadCrumbClick} aria-label={backButton} style={{marginLeft:'3px',marginRight:'3px',marginTop:'3px'}}><ArrowBackIcon fontSize="large"/></IconButton>
                        </Tooltip>
                        <DevicesIcon style={{ 'vertical-align': 'middle', 'margin-right': '7px' }} />
                        <h2 style={{'color': '#6B6B6B',margin: '0px'}}>{deviceNameSelected}</h2>
                    </div>)
                else
                    return (<div> </div>)

            }
        }
        else if (this.state.value === 'VIEW_BY_DEVICE') {
            if (this.state.dashCardView == 'DASHCARDVIEW_DEVICES_PROFILES') {




                /*let showBreadcrumb=(this.state.filter!==undefined && this.state.filter!=='');
                        
                            if (showBreadcrumb == true)
                            {
                */
                return (


                    <div className="content-grid-mcs mcs-dashboard-grid">
                        <MCSDashBoardCard type="Devices" style={Object.assign({}, devicesSelectedStyle, { 'width': 'calc((100% - 50px)/2)' })} counts={this.state.devices.devicePercentages} disableKeys={this.state.disableDevicesBars} onCardclick={this.cardClickHandler} onBarClick={this.barClickHandler} />
                        <img src={arrowImage}  alt='Forward Arrow Icon'  className="bitmap" />
                        <MCSDashBoardCard type="Profiles" style={Object.assign({}, profilesSelectedStyle, { 'width': 'calc((100% - 50px)/2)' })} counts={this.state.devices.deviceProfilePercentages} disableKeys={this.state.disableProfilesBars} onCardclick={this.cardClickHandler} onBarClick={this.barClickHandler} />


                    </div>
                )
                /* }
                 else
                 {
     
     
     
     
                     
     
                     return (<div className="content-grid-mcs mcs-dashboard-grid">
                         <MCSDashBoardCard type="Devices"  style={Object.assign({}, devicesSelectedStyle,{'width': 'calc(~\'(100% - 50px)\'/2)'})}counts={this.state.devices.devicePercentages} disableKeys={this.state.disableDevicesBars} onCardclick={this.cardClickHandler} onBarClick={this.barClickHandler} />
                         <img src={arrowImage} className="bitmap" />
                         <MCSDashBoardCard type="Profiles" style={Object.assign({}, profilesSelectedStyle,{'width': 'calc(~\'(100% - 50px)\'/2)'})} counts={this.state.devices.deviceProfilePercentages} disableKeys={this.state.disableProfilesBars} onCardclick={this.cardClickHandler} onBarClick={this.barClickHandler} /></div>)
                 }*/
            } else if (this.state.dashCardView == 'DASHCARDVIEW_DPROFILES') {
                let deviceNameSelected = '';
                if (this.state.deviceNameSelected.length >= 1)
                    deviceNameSelected = this.state.deviceNameSelected[this.state.deviceNameSelected.length - 1];
                let showBreadcrumb = deviceNameSelected !== 'undefined' && deviceNameSelected !== '';



                /* if (this.state.filter!==undefined && this.state.filter!=='')
                 {
                   deviceNameSelected='';
                 }*/
                if (showBreadcrumb == true)
                    return (<div>
                        <div style={{display: 'inline-flex'}}>
                        <Tooltip arrow title={backButton}>
                        <IconButton autoFocus  onClick={this.state.status === 'loading' ? '' : this.handleBreadCrumbClick}  aria-label={backButton} style={{marginLeft:'3px',marginRight:'3px',marginTop:'3px'}}><ArrowBackIcon fontSize="large"/></IconButton>
                        </Tooltip>
                        <DevicesIcon style={{ 'vertical-align': 'middle', 'margin-right': '7px' }} />
                        <Typography component="h2" variant="h3">{deviceNameSelected}</Typography>
                        </div>
                        <div className="content-grid-mcs mcs-dashboard-grid">

                            <MCSDashBoardCard type="Profiles" style={Object.assign({}, profilesSelectedStyle, { 'width': '100%' })} counts={this.state.profiles.deviceProfilePercentages} disableKeys={this.state.disableProfilesBars} onCardclick={this.cardClickHandler} onBarClick={this.barClickHandler} />
                        </div></div>)
                else
                    return (<div className="content-grid-mcs mcs-dashboard-grid">

                        <MCSDashBoardCard type="Profiles" style={Object.assign({}, profilesSelectedStyle, { 'width': '100%' })} counts={this.state.profiles.deviceProfilePercentages} disableKeys={this.state.disableProfilesBars} onCardclick={this.cardClickHandler} onBarClick={this.barClickHandler} />
                    </div>)
            } else if (this.state.dashCardView == 'DASHCARDVIEW_PROFILE_DETAILS') {
                let profileNameSelected = '';
                if (this.state.profileNameSelected.length >= 1)
                    profileNameSelected = this.state.profileNameSelected[this.state.profileNameSelected.length - 1];

                let showBreadcrumb = profileNameSelected !== 'undefined' && profileNameSelected !== '';
                if (showBreadcrumb == true)
                    return (<div style={{display: 'inline-flex'}}>
                        <Tooltip arrow title={backButton}>
                        <IconButton autoFocus onClick={this.state.status === 'loading' ? '' : this.handleBreadCrumbClick} title={backButton} style={{marginLeft:'3px',marginRight:'3px',marginTop:'3px'}}><ArrowBackIcon fontSize="large"/></IconButton>
                        </Tooltip>
                        <ProfilesIcon style={{ 'vertical-align': 'middle', 'margin-right': '7px' }} />
                        <h2 style={{ 'color': '#6B6B6B',margin: '0px'}}>{profileNameSelected}</h2>

                    </div>)
                else
                    return (<div> </div>)

            }
        }
    }


    handleChange = (event, value) => {
        this.setState({ status: 'loading', highlightedCard: '' })
        if (value === 'VIEW_BY_DEVICE') {
            if (this.state.dashCardView !== 'DASHCARDVIEW_DEVICES_PROFILES' && this.state.dashCardView !== 'DASHCARDVIEW_DPROFILES' && this.state.dashCardView !== 'DASHCARDVIEW_PROFILE_DETAILS') {

                this.paramsInfo = null;
                //this.getRowsCount=0
                this.deviceOptions = Object.assign({}, INIT_DEVICE_OPTIONS);
                this.props.fetchDevices(this.deviceOptions);
                this.setState({
                    groupNameSelected: [],
                    profileNameSelected: [],
                    deviceNameSelected: [],
                    groupIdSelected: [],
                    profileIdSelected: [],
                    deviceIdSelected: [],
                    highlightedCard: 'Devices',
                    breadCrumbState: [{ "method": "this.props.fetchDevices", "options": INIT_DEVICE_OPTIONS }],
                    filter: '',
                    disableGroupsBars: [false, false, false, false],
                    disableProfilesBars: [false, false, false, false],
                    disableDevicesBars: [false, false, false, false]
                });

            }
        } else if (value === 'VIEW_BY_GROUP') {
            if (this.state.dashCardView !== 'DASHCARDVIEW_GROUPS_PROFILES_DEVICES' && this.state.dashCardView !== 'DASHCARDVIEW_PROFILES' && this.state.dashCardView !== 'DASHCARDVIEW_DEVICES' && this.state.dashCardView !== 'DASHCARDVIEW_DEVICE_DETAILS') {
                this.paramsInfo = null;

                this.groupOptions = Object.assign({}, INIT_GROUP_OPTIONS);
                //this.getRowsCount=0
                this.props.fetchGroups(this.groupOptions);
                this.setState({
                    groupNameSelected: [],
                    profileNameSelected: [],
                    deviceNameSelected: [],
                    groupIdSelected: [],
                    profileIdSelected: [],
                    deviceIdSelected: [],
                    breadCrumbState: [{ "method": "this.props.fetchGroups", "options": INIT_GROUP_OPTIONS }],
                    filter: '',
                    highlightedCard: 'Groups',
                    disableGroupsBars: [false, false, false, false],
                    disableProfilesBars: [false, false, false, false],
                    disableDevicesBars: [false, false, false, false]
                });

            }
        }
        this.setState({
            value: value
        });
    };

    render() {
        let tabsInfo = this.getTabsInfo();
        let cardInfo = (
            <div style={{ height: '100%', marginBottom: '20px' }}>
                {this.getActiveTabContent()}
            </div>
        )
        let view = null;
        let noOverlayView = null;
        let noDataFound = Resource.get('No data found');
        if (this.showNoRowsOverlay == true && this.state.status !== 'loading' && this.state.status !== 'failure') {


            noOverlayView = <CenterMessage role="alert"
                className="no-data-overlay"
                title={noDataFound}
                message=""
                icon={InfoIcon}
            />
        }

        if (this.state.status === 'loading') {
            view = <div style={{ width: '100%', height: this.props.currentHeight?this.props.currentHeight:'100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><CircularProgress style={{ color: '#3272D9' }} /></div>
        }
        else
            if (this.state.status === 'failure') {
                tabsInfo = ''
                cardInfo = ''
                view = <Error />
            } else
                if (this.state.value === 'VIEW_BY_GROUP') {
                    if (this.state.requestType === 'group') {
                        view = (<GroupTable filter={this.state.filter} currentHeight={this.props.currentHeight}
                            //rowData={this.state.groups}
                            columnDefs={groupCols}
                            onGridReady={this.onGridReady}
                            onRowClicked={this.handleTableClick}
                            rowModelType='infinite'
                            context={this}
                            cacheBlockSize={PAGE_SIZE}
                            cacheOverflowSize={2}
                            maxConcurrentDatasourceRequests={1}
                            maxBlocksInCache={10}
                            onGridSizeChanged={this.onGridSizeChanged.bind(this)}
                            onFilterChanged={this.onFilter.bind(this)}
                            onBtnClickExport={this.onBtnClickExport.bind(this)}
                            enableSorting={true}
                            onSortChanged={this.onSort.bind(this)} />)
                    } else if (this.state.requestType === 'profile') {
                        view = (<GroupProfileTable tableHeading='Group Profiles' filter={this.state.filter} currentHeight={this.props.currentHeight}
                            //rowData={this.state.profiles}
                            columnDefs={profilesCols}
                            onGridReady={this.onGridReady}
                            onRowClicked={this.handleTableClick}
                            rowModelType='infinite'
                            context={this}
                            cacheBlockSize={PAGE_SIZE}
                            cacheOverflowSize={2}
                            onGridSizeChanged={this.onGridSizeChanged.bind(this)}
                            maxConcurrentDatasourceRequests={1}
                            maxBlocksInCache={10}
                            onFilterChanged={this.onFilter.bind(this)}
                            onBtnClickExport={this.onBtnClickExport.bind(this)}
                            enableSorting={true}
                            onSortChanged={this.onSort.bind(this)} />)
                    } else if (this.state.requestType === 'device') {
                        view = (<GroupDeviceTable filter={this.state.filter} currentHeight={this.props.currentHeight}
                            //rowData={this.state.devices}
                            columnDefs={deviceCols}
                            onGridReady={this.onGridReady}
                            onRowClicked={this.state.requestType === 'device' && this.state.dashCardView !== 'DASHCARDVIEW_GROUPS_PROFILES_DEVICES' ? this.handleTableClick : ''}

                            rowModelType='infinite'
                            context={this}
                            cacheBlockSize={PAGE_SIZE}
                            cacheOverflowSize={2}
                            maxConcurrentDatasourceRequests={1}
                            maxBlocksInCache={10}
                            onFilterChanged={this.onFilter.bind(this)}
                            enableSorting={true}
                            onGridSizeChanged={this.onGridSizeChanged.bind(this)}
                            onBtnClickExport={this.onBtnClickExport.bind(this)}
                            onSortChanged={this.onSort.bind(this)} />)
                    } else if (this.state.requestType === 'device_detail') {
                        view = (<DeviceDetails currentHeight={this.props.currentHeight}
                            device={this.state.device} />)
                    }
                }
                else if (this.state.value === 'VIEW_BY_DEVICE') {

                    if (this.state.requestType === 'profile') {
                        view = (<GroupProfileTable tableHeading='Profiles' filter={this.state.filter} currentHeight={this.props.currentHeight}
                            // rowData={this.state.profiles}
                            columnDefs={profilesColsForDevice}
                            onGridReady={this.onGridReady}
                            rowModelType='infinite'
                            context={this}
                            cacheBlockSize={PAGE_SIZE}
                            cacheOverflowSize={2}
                            maxConcurrentDatasourceRequests={1}
                            maxBlocksInCache={10}
                            onFilterChanged={this.onFilter.bind(this)}
                            enableSorting={true}
                            onSortChanged={this.onSort.bind(this)}
                            onGridSizeChanged={this.onGridSizeChanged.bind(this)}
                            onBtnClickExport={this.onBtnClickExport.bind(this)}
                            onRowClicked={this.handleTableClick}
                        />)
                    } else if (this.state.requestType === 'device') {
                        view = (<GroupDeviceTable filter={this.state.filter} currentHeight={this.props.currentHeight}
                            // rowData={this.state.devices}
                            columnDefs={deviceColsForDevice}
                            onGridReady={this.onGridReady}
                            onRowClicked={this.handleTableClick}
                            onFilterChanged={this.onFilter.bind(this)}
                            onBtnClickExport={this.onBtnClickExport.bind(this)}
                            //Infinite scrolling 
                            rowModelType='infinite'
                            context={this}
                            cacheBlockSize={PAGE_SIZE}
                            cacheOverflowSize={2}
                            maxConcurrentDatasourceRequests={1}
                            maxBlocksInCache={10}
                            onGridSizeChanged={this.onGridSizeChanged.bind(this)}
                            enableSorting={true}
                            onSortChanged={this.onSort.bind(this)}



                        />)
                    } else if (this.state.requestType === 'profile_detail') {
                        view = (<ProfileDetails currentHeight={this.props.currentHeight}
                            profile={this.state.profile} />)
                    }

                }
        return (
            <div className="mcs-dashboard-grid">
                <div>
                    <div style={{ height: 'calc(100% - 49px)', position: 'relative' }}>
                        <Tabs variant="fullWidth"
                            value={this.state.value}
                            onChange={this.handleChange}
                          //  className="tab-root_1"
                           // contentContainerClassName="tab-content"
                           // inkBarStyle={{ backgroundColor: '#fff' }}>
                            >{tabsInfo}
                        </Tabs>
                        {cardInfo}
                    </div>

                </div>

                <CSVLink
                    onFocus={false}
                    id="csvLink"
                    data={this.state.exportData.data}
                    filename={this.state.exportFileName}
                    className="hidden"
                    ref={(r) => this.dataDownloadLink = r}
                    target="_blank"
                    style={{ display: "none" }} />

                {noOverlayView}
                {view}



            </div>
        )
    }
}





