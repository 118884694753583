import React from 'react'
import { Route } from 'react-router-dom'
import ManageGroupsRedux from './../components/manageGroups/ManageGroupsRedux'

const ManageGroups = () => {
  return (
    <main>
      <ManageGroupsRedux/>
    </main>
  )
}

const ManageGroupsRoute = () => {
  return (
    <Route path='/manage-groups' component={ManageGroups}/>
  )
}

export default ManageGroupsRoute;