export const REQUEST_HISTORIC_ALARMS_FOR_GROUP = 'REQUEST_HISTORIC_ALARMS_FOR_GROUP'
export const FETCH_HISTORIC_ALARMS_FOR_GROUP = 'FETCH_HISTORIC_ALARMS_FOR_GROUP'
export const FETCH_HISTORIC_ALARMS_SUCCESS_FOR_GROUP = 'FETCH_HISTORIC_ALARMS_SUCCESS_FOR_GROUP'
export const FETCH_HISTORIC_ALARMS_FAILURE_FOR_GROUP = 'FETCH_HISTORIC_ALARMS_FAILURE_FOR_GROUP'
export const REQUEST_HISTORIC_ALARMS_FOR_CS = 'REQUEST_HISTORIC_ALARMS_FOR_CS'
export const FETCH_HISTORIC_ALARMS_FOR_CS = 'FETCH_HISTORIC_ALARMS_FOR_CS'
export const FETCH_HISTORIC_ALARMS_SUCCESS_FOR_CS = 'FETCH_HISTORIC_ALARMS_SUCCESS_FOR_CS'
export const FETCH_HISTORIC_ALARMS_FAILURE_FOR_CS = 'FETCH_HISTORIC_ALARMS_FAILURE_FOR_CS'



