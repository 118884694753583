import * as React from 'react';
import { SvgIcon } from '@mineral/core';

export const FilledStar06 = React.forwardRef(
  (props, ref) => (
    <SvgIcon viewBox="0 0 24 24" {...props} ref={ref}>
      <path d="M20 4L24.4697 9.20909L31.3137 8.68629L30.7909 15.5303L36 20L30.7909 24.4697L31.3137 31.3137L24.4697 30.7909L20 36L15.5303 30.7909L8.68629 31.3137L9.20909 24.4697L4 20L9.20909 15.5303L8.68629 8.68629L15.5303 9.20909L20 4Z" fill={props.color} />
    </SvgIcon>
  )
);

export default FilledStar06;