// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //System update available
  content["System update available"] =
  "Systemaktualisierung verfügbar";

  //A new version of the robot software is available.
  content["A new version of the robot software is available."] =
  "Es ist eine neue Version der Robot-Software verfügbar.";

  //Deployment takes about 1 minute, during which time no metrics are collected and no alarms are created.
  content["Deployment takes about 1 minute, during which time no metrics are collected and no alarms are created."] =
  "Die Bereitstellung dauert ca. 1 Minute. Während diese Zeit werden keine Kennzahlen erfasst und keine Alarme erstellt.";

  //LATER
  content["LATER"] =
  "SPÄTER";

  //DEPLOY
  content["DEPLOY"] =
  "BEREITSTELLEN";

  // END OF LOCALIZATION

export default content;
