import * as actions from './actionTypes'
import closerule from './closerule'
import * as IntegrationError from './../../components/settings/integrations/IntegrationError'

export function fetchConfig() {
  return {
    type: actions.FETCH_CLOSERULE_CONFIG,
  }
}

export function fetchConfigSuccess(json) {
  return {
    type: actions.FETCH_CLOSERULE_CONFIG_SUCCESS,
    config: json,
  }
}

export function fetchConfigFailure(error) {
  return {
    type: actions.FETCH_CLOSERULE_CONFIG_FAILURE,
    responseError: error,
  }
}

export function saveConfig() {
  return {
    type: actions.SAVE_CLOSERULE_CONFIG,
  }
}

export function saveConfigSuccess() {
  return {
    type: actions.SAVE_CLOSERULE_CONFIG_SUCCESS,
  }
}

export function saveConfigFailure(error) {
  return {
    type: actions.SAVE_CLOSERULE_CONFIG_FAILURE,
    responseError: error,
  }
}

function getResponseError(error) {
  if (error.response.data && error.response.data.message) {
    return error.response.data
  } else {
    return IntegrationError.getDefaultError()
  }
}

export function get() {
  return dispatch => {
    dispatch(fetchConfig())
    return closerule
      .get()
      .then(response => {
        dispatch(fetchConfigSuccess(response.data))
      })
      .catch(function(error) {
        dispatch(fetchConfigFailure(getResponseError(error)))
      })
  }
}

export function save(closeRuleConfig, successCallback) {
  return dispatch => {
    dispatch(saveConfig())
    return closerule
      .save(closeRuleConfig)
      .then(response => {
        dispatch(saveConfigSuccess())
        if (successCallback) {
          successCallback()
        }
      })
      .catch(function(error) {
        dispatch(saveConfigFailure(getResponseError(error)))
      })
  }
}
