import React, { Component } from "react";
import axios from "axios";
import config from "./../../api/config";
import prd from    "./../../api/performanceReports/prd"
import Resource from "./Resource";
import {
  
  CardContent,
  IconButton,
  Divider,
  Dialog,
  List,
  ListItem,Tooltip,
  ListItemIcon,
  ListItemText,
  LinearProgress,
  Button as RaisedButton,  
  Button as FlatButton,  DialogContent,
  TextField,
  DialogActions,FormControl,InputLabel,Typography
} from "@mineral/core";
import{
  BulletPointIcon as BulletPoint,HelpIcon,
  GoodIcon,
  SetupWizardIcon,
  ErrorIcon,
  InProgressIcon as InProgress, 
} from "../ui-components/uim-components"
import { Link } from "react-router-dom";
import "./createMonitoringProbe.less";
import theme from "../../../src/theme";
import FilesList from "./filesList";
import { Snackbar } from "@mineral/core";
const flatBtnlabelStyle={
  paddingRight: 10,
  color: theme.palette.secondaryTextColor,
}
const uploadSchemaUrl = prd.getRestmonUploadUrl();


export const STAGE = {
  INITIAL: "initial",
  READY: "ready",
  INPROGRESS: "inprogress",
  SYNTAXVALID: "syntaxvalid",
  APICONNECTIONVALID: "apiconnectionvalid",
  SYNTAXERROR: "syntaxerror",
  APIERROR: "apierror",
};
const FailedImportDialog = (props) => {
  let actions = [];
  if (props.confirmationNeeded) {
    actions = [
      <FlatButton
      //OK
      children={Resource.get("Yes")}
      variant="text" autoFocus={true}
      color = "primary"
      onClick={() => {
        props.onClose("yes");
      }}
      />,
      <FlatButton
      //OK
      children={Resource.get("No")}
      autoFocus={true}
      variant="text"
      color = "primary"
      onClick={() => {
        props.onClose("no");
      }}
      />,
    ];
  } else {
    actions = [
      <FlatButton
      //OK
      children={Resource.get("OK")}
      autoFocus={true}
      variant="text"
      color = "primary"
      onClick={() => {
        props.onClose("close");
      }}
      />,
    ];
  }
  return (
    <Dialog
      modal={false}
      open={props.open}
      onClose={props.onClose}
      className= "failedImportDialog"
    >
    <DialogContent>
    <Typography component="span">{props.text}</Typography>
    </DialogContent>
    <DialogActions>
      {actions}
    </DialogActions>
    </Dialog>
    );
  };
  
  
  
  class CreateMonitoringProbe extends Component {
    constructor(props) {
      super(props);
      this.state = {
        schemaTestState: STAGE.INITIAL,
        testButtonDisable: true,
        browseButtonDisable: false,
        uploadButtonDisable: true,
        doneButtonDisable: true,
        resetButtonDisable: true,
        progressBar: 0,
        progressColor: "rgb(0, 174, 239)",
        progressMessage: "",
        debugMessagesButton: false,
        showProgressError: false,
        progressError: false,
        downloadLocation: null,
        errorMessage: null,
        uploadedFileName: null,
        status: false,
        text: null,
        files: [],
        friendlyName: "",
        friendlyLabelText: "",
        disableFriendlyName:false,
        friendlyLabelErrorText: "",
        confirmationNeeded: false,
        isUploadSuccess: false,
        uploadedFileNames: "",
        isUploadInProgress: false,
        showProgress: false,
        showFriendlyNameError: false,
        errMassage: "",
        openSnackBar: false,
      };
      this.fileInput = React.createRef();
      this.confirmationInfo = { data: null, callback: null, statusCode: null };
      this.debugMessages = [];
      this.getTestSchemaMessage = this.getTestSchemaMessage.bind(this);
      this.getSyntaxValidationIcon = this.getSyntaxValidationIcon.bind(this);
      this.setCloseCSVImport = this.setCloseCSVImport.bind(this);
      this.handleClick = this.handleClick.bind(this);
      this.clearPollerId = null;
      this.isJobStatusFetchingInProgress = false;
    }
    jobId = null;
    setCloseCSVImport() {
      this.setState({
        state: true,
      });
    }
    handleClick(){
      // 👇️ open file input box on click of other element
      this.fileInput?.current?.click();
    };
    // handleKeyDown = (e) => {
    //   e.stopPropagation();
    //   if ([38, 40].includes(e.keyCode)) menuItemEl.current.focus();
    // };
    getTestSchemaMessage() {
      let currentStage = this.state.schemaTestState;
      //Upload JSON file(s)
      const helplabelmessage = Resource.get("Upload JSON file(s)");
      //Click TEST to begin
      const helplabelmessage1 = Resource.get("Click PROCEED to begin");
      // Processing...
      const helplabelmessage2 = Resource.get("Processing...");
      //Attempting to communicate with API
      const helplabelmessage3 = Resource.get(
        "Attempting to communicate with API"
        );
        // Schema passed tests
        const helplabelmessage4 = Resource.get(
          "Schema passed tests, click DONE below"
          );
          //Schema could not be processed.Check logs below
          const helplabelmessage5 = Resource.get("Schema could not be processed.Check logs below");
          //Unable  to communicate with API
          const helplabelmessage6 = Resource.get("Unable to communicate with API");
          //Click TEST to begin
          const helplabelmessage7 = Resource.get("click TEST to begin");
          
          switch (currentStage) {
            case STAGE.INITIAL:
            return helplabelmessage;
            case STAGE.READY:
            return helplabelmessage1;
            case STAGE.INPROGRESS:
            return helplabelmessage2;
            case STAGE.SYNTAXVALID:
            return helplabelmessage3;
            case STAGE.APICONNECTIONVALID:
            return helplabelmessage4;
            case STAGE.SYNTAXERROR:
            return helplabelmessage5;
            case STAGE.APIERROR:
            return helplabelmessage6;
            default:
            return helplabelmessage7;
          }
        }
        
        getAPIConnectionIcon() {
          let currentStage = this.state.schemaTestState;
          
          switch (currentStage) {
            case STAGE.INITIAL:
            return <HelpIcon className="helpIconState" />;
            case STAGE.READY:
            return <HelpIcon className="helpIconState" />;
            case STAGE.INPROGRESS:
            return <HelpIcon className="helpIconState" />;
            case STAGE.SYNTAXVALID:
            return <InProgress className="inProgressState" />;
            case STAGE.APICONNECTIONVALID:
            return <GoodIcon className="successState" />;
            case STAGE.SYNTAXERROR:
            return <HelpIcon className="helpIconState" />;
            case STAGE.APIERROR:
            return <ErrorIcon className="errorState" />;
            default:
            return <HelpIcon className="helpIconState" />;
          }
        }
        
        getSyntaxValidationIcon() {
          let currentStage = this.state.schemaTestState;
          switch (currentStage) {
            case STAGE.INITIAL:
            return <HelpIcon className="helpIconState" />;
            case STAGE.READY:
            return <HelpIcon className="helpIconState" />;
            case STAGE.INPROGRESS:
            return <InProgress className="inProgressState" />;
            case STAGE.SYNTAXVALID:
            return <GoodIcon className="successState" />;
            case STAGE.APICONNECTIONVALID:
            return <GoodIcon className="successState" />;
            case STAGE.APIERROR:
            return <GoodIcon className="successState" />;
            case STAGE.SYNTAXERROR:
            return <ErrorIcon className="errorState" />;
            default:
            return <HelpIcon className="helpIconState" />;
          }
        }
        state = {
          status: false,
          text: null,
        };
        
        resetData = () => {
          if (this.fileInput?.current) {
            this.fileInput.current.value = "";
          }
          this.debugMessages.length = 0;
          this.setState({
            uploadButtonDisable: true,
            testButtonDisable: true,
            browseButtonDisable: false,
            progressBar: 0,
            schemaTestState: STAGE.INITIAL,
            progressMessage: "",
            debugMessagesButton: false,
            showProgressError: false,
            progressError: false,
            files: [],
            friendlyName: "",
            friendlyLabelText: "",
            disableFriendlyName:false,
            friendlyLabelErrorText: "",
            confirmationNeeded: false,
            isUploadSuccess: false,
            uploadedFileNames: "",
            isUploadInProgress: false,
            showProgress: false,
            showFriendlyNameError: false,
          });
        };
        
        resetOnValidateNo = () => {
          this.setState({
            testButtonDisable: true,
            browseButtonDisable: false,
            progressBar: 0,
            schemaTestState: STAGE.READY,
            progressMessage: "",
            debugMessagesButton: false,
            showProgressError: false,
            progressError: false,
            confirmationNeeded: false,
          });
        };
        
        downloadSchema = (evt) => {
          this.setState({ showFriendlyNameError: false });
          axios
          .get(`${config.basename}/api/v1/restmon/downloadSchema/default_schema`)
          .then((response) => {
            var file = new Blob([JSON.stringify(response.data, null, "\t")], {
              type: "application/json",
            });
            //Handle in IE using msSaveOrOpenBlob
            if (typeof window.navigator.msSaveOrOpenBlob === "function") {
              window.navigator.msSaveOrOpenBlob(file, "default_schema.json");
            } else {
              let anchor = document.createElement("a");
              anchor.href = URL.createObjectURL(file);
              anchor.download = "default_schema.json";
              document.body.appendChild(anchor);
              anchor.click();
              document.body.removeChild(anchor);
            }
          })
          .catch((error) => {
            this.setState({
              status: true,
              text: `There was a problem downloading default schema. Please contact an administrator.`,
              confirmationNeeded: false,
            });
          });
        };
        changeShowProgressError = () => {
          this.setState((prevState) => ({
            showProgressError: !prevState.showProgressError,
          }));
        };
        onFileDelete = (data) => {
          let idx,
          files,
          uploadButtonDisable = false,
          resetButtonDisable = false;
          idx = this.state.files.indexOf(data);
          if (idx !== -1) {
            files = this.state.files.slice(0);
            files.splice(idx, 1);
            if (files.length === 0) {
              document.getElementById("browsebuttonrestmon")?.focus();
            //  "fileuploadedrestmon"+index+data.name
              uploadButtonDisable = true;
              resetButtonDisable = true;
            }else
            {
              if(idx==0)
              {
                document.getElementById("browsebuttonrestmon")?.focus();
              }
              else
              {
                document.getElementById("fileuploadedrestmon"+idx)?.focus();
              }
            }
            this.setState({
              files: files,
              uploadButtonDisable: uploadButtonDisable,
              resetButtonDisable: resetButtonDisable,
              showFriendlyNameError: false,
              friendlyLabelText: "",
              friendlyLabelErrorText: "",
              testButtonDisable: true,
            });
          }
        };
        isAllowed = (evt) => {
          evt = evt ? evt : window.event;
          var charCode = evt.which ? evt.which : evt.keyCode,
          codes = [191, 220, 56, 34, 60, 62, 124],
          shiftCodes = [191, 222, 186, 188, 190, 220];
          if (
            codes.indexOf(charCode) !== -1 ||
            (evt.shiftKey && shiftCodes.indexOf(charCode) !== -1)
            ) {
              this.setState({ showFriendlyNameError: true });
              evt.preventDefault();
              return false;
            } else {
              this.setState({ showFriendlyNameError: false });
            }
            return true;
          };
          onFriendlyNameChange = (evt) => {
            let value = evt.target.value,
            helperText = "Required",
            testButtonDisable = true;
            if (value !== "") {
              helperText = "";
              if (this.state.isUploadSuccess) {
                testButtonDisable = false;
              }
            }
            this.setState({
              friendlyLabelText: value,
              friendlyLabelErrorText: helperText,
              testButtonDisable: testButtonDisable,
            });
          };
          testFiles = (existCheckfalse) => {
            let i,
            len = this.state.files.length,
            checkIfExist = true,
            friendlyName = this.state.friendlyLabelText,
            encodedStr,
            jobId = new Date().getTime().toString();
            this.jobId = jobId;
            if (existCheckfalse === true) {
              checkIfExist = false;
            }
            this.setState({
              uploadButtonDisable: true,
              debugMessagesButton: false,
              showProgressError: false,
              browseButtonDisable: true,
              testButtonDisable: true,
              resetButtonDisable: true,
              schemaTestState: STAGE.INPROGRESS,
              progressMessage: "",
              progressBar: 0,
              showProgress: true,
              showFriendlyNameError: false,
            });
            encodedStr = this.state.uploadedFileNames;
            this.startJobStatusPoller(jobId);
            
              axios.get(
                  `${config.basename}/api/v1/restmon/validateSchema?fileNames=${encodedStr}&checkIfExist=${checkIfExist}&friendlyName=${friendlyName}&jobId=${jobId}`
              )
              .then((response) => {
                clearInterval(this.clearPollerId);
                this.clearPollerId = null;
                this.isJobStatusFetchingInProgress = false;
                if (
                  response.data.apiconflictresponse &&
                  typeof response.data.apiconflictresponse.message === "string"
                  ) {
                    if (response.data.apiconflictresponse.statusCode === 100) {
                      //TODO: status 100 (Template Name already exists)
                      this.confirmationInfo.data = true;
                      this.confirmationInfo.callback = this.testFiles;
                      this.confirmationInfo.statusCode = 100;
                      this.setState({
                        status: true,
                        text: response.data.apiconflictresponse.message,
                        confirmationNeeded: true,
                        progressBar: 0,
                      });
                    } else if (response.data.apiconflictresponse.statusCode === 409) {
                      //TODO: status 409 (profile already exists)
                      this.setState({
                        status: true,
                        text: response.data.apiconflictresponse.message,
                        confirmationNeeded: false,
                        progressBar: 0,
                      });
                    }
                  } else {
                    this.setState({
                      schemaTestState: STAGE.APICONNECTIONVALID,
                      progressError: true,
                      progressMessage:
                      typeof response.data === "object"
                      ? response.data.apisuccessresponse.message[0]
                      : null,
                      browseButtonDisable: false,
                      testButtonDisable: true,
                      doneButtonDisable: false,
                      resetButtonDisable: false,
                      progressBar: 100,
                      files: [],
                    });
                  }
                  document.getElementById("ProceedButton")?.focus();
                })
                .catch((error) => {
                  clearInterval(this.clearPollerId);
                  this.clearPollerId = null;
                  this.isJobStatusFetchingInProgress = false;
                  if (
                    error.response.data.apiconflictresponse &&
                    typeof error.response.data.apiconflictresponse.message === "string"
                    ) {
                      if (error.response.data.apiconflictresponse.statusCode === 409) {
                        //TODO: status 409 (profile already exists)
                        this.confirmationInfo.statusCode = 409;
                        this.setState({
                          status: true,
                          text: error.response.data.apiconflictresponse.message,
                          confirmationNeeded: false,
                          progressBar: 0,
                        });
                      }
                    } else if (
                      error.response.data.apierrorresponse &&
                      typeof error.response.data.apierrorresponse.errorMessage === "string"
                      ) {
                        this.debugMessages =
                        error.response.data.apierrorresponse.debugMessage;
                        this.setState({
                          schemaTestState: STAGE.SYNTAXERROR,
                          progressError: true,
                          progressMessage: error.response.data.apierrorresponse.errorMessage,
                          debugMessagesButton: true,
                          browseButtonDisable: false,
                          testButtonDisable: false,
                          resetButtonDisable: false,
                          progressBar: 0,
                        });
                      }
                      document.getElementById("ProceedButton")?.focus();
                    });
                  };
                  startJobStatusPoller = (jobId) => {
                    if (this.clearPollerId !== null) {
                      clearInterval(this.clearPollerId);
                      this.clearPollerId = null;
                      this.isJobStatusFetchingInProgress = false;
                    }
                    this.clearPollerId = setInterval(() => {
                      if (!this.isJobStatusFetchingInProgress) {
                        this.getJobStatus(jobId);
                      }
                    }, 1000);
                  };
                  componentWillUnmount() {
                    if (this.clearPollerId !== null) {
                      clearInterval(this.clearPollerId);
                      axios
                      .get(`${config.basename}/api/v1/restmon/clearLock/${this.jobId}`)
                      .then(
                        (response) => {},
                        (err) => {
                          //  console.log(err);
                        }
                        );
                      }
                    }
                    getJobStatus = (jobId) => {
                      this.isJobStatusFetchingInProgress = true;
                      axios
                      .get(`${config.basename}/api/v1/restmon/jobStatus/${jobId}`)
                      .then((response) => {
                        if (response.data && response.data.schemavalidationstatusresponse) {
                          let msg =
                          response.data.schemavalidationstatusresponse.progress_bar_message;
                          this.isJobStatusFetchingInProgress = false;
                          if (msg === null || (typeof msg === "string" && msg.length < 1)) {
                            msg = this.state.progressMessage;
                          }
                          if (!this.state.confirmationNeeded) {
                            this.setState({
                              progressMessage: msg,
                              progressBar:
                              response.data.schemavalidationstatusresponse.percent_completed,
                            });
                          } else {
                            this.setState({
                              progressMessage: "",
                              progressBar: 0,
                            });
                          }
                        }
                      })
                      .catch((error) => {
                        this.isJobStatusFetchingInProgress = false;
                        //console.log(error);
                      });
                    };
                    handleFile = (evt) => {
                      const files = evt.target.files,
                      data = [];
                      if (this.state.isUploadSuccess) {
                        this.resetOnValidateNo();
                        this.setState({
                          isUploadSuccess: false,
                          schemaTestState: STAGE.INITIAL,
                        });
                      }
                      this.storeFilesData(files, true);
                      if (this.fileInput?.current) {
                        this.fileInput.current.value = "";
                      }
                    };
                    storeFilesData = (files, checkForPrevious) => {
                      let i,
                      file,
                      fileName,
                      enableUpload = true,
                      data = [],
                      previousData = this.state.files.slice(0),
                      uploadedNames = [];
                      data.push.apply(data, files);
                      if (files) {
                        for (i = 0; i < files.length; i++) {
                          file = files[i];
                          fileName = file.name.split(".")[0];
                          if (fileName.endsWith("_schema") === false) {
                            this.setState({
                              status: true,
                              text: `Please ensure that file name should end with _schema.json`,
                              confirmationNeeded: false,
                              showFriendlyNameError: false,
                            });
                            this.resetData();
                            enableUpload = false;
                            break;
                          }
                          uploadedNames.push(fileName);
                        }
                        if (checkForPrevious && previousData && previousData.length > 0) {
                          for (i = 0; i < previousData.length; i++) {
                            file = previousData[i];
                            fileName = file.name.split(".")[0];
                            if (uploadedNames.indexOf(fileName) === -1) {
                              data.push(file);
                              uploadedNames.push(fileName);
                            }
                          }
                        }
                        if (enableUpload) {
                          this.setState({
                            uploadButtonDisable: false,
                            isUploadSuccess: false,
                            resetButtonDisable: false,
                            files: data,
                            showFriendlyNameError: false,
                          });
                        }
                      }
                    };
                    upload = () => {
                      let testButtonDisable = true,
                      friendlyLabelErrorText = "Required",
                      uploadedNames = [],
                      uploadedFileNames;
                      if (this.state.files && this.state.files.length > 0) {
                        let i = 0,
                        formData = new FormData(),
                        fileName;
                        for (i = 0; i < this.state.files.length; i++) {
                          fileName = this.state.files[i].name;
                          uploadedNames.push(fileName);
                          formData.append("file", this.state.files[i]);
                        }
                        uploadedFileNames = encodeURIComponent(uploadedNames.join(","));
                        this.setState({
                          resetButtonDisable: true,
                          isUploadInProgress: true,
                          showProgress: false,
                          showFriendlyNameError: false,
                        });
                        axios
                        .post(uploadSchemaUrl, formData)
                        .then((response) => {
                          let resFriendlyName = response.data &&
                                                response.data.apisuccessresponse.friendlyName &&
                                                response.data.apisuccessresponse.friendlyName !== "" ?
                                                response.data.apisuccessresponse.friendlyName
                                                :
                                                "";
                          if (this.state.friendlyLabelText !== "") {
                            testButtonDisable = false;
                            friendlyLabelErrorText = "";
                          }
                          this.setState({
                            testButtonDisable: testButtonDisable,
                            doneButtonDisable: true,
                            resetButtonDisable: false,
                            schemaTestState: STAGE.READY,
                            fileName: fileName ? fileName : null,
                            progressMessage: "",
                            progressError: false,
                            progressBar: 0,
                            isUploadSuccess: true,
                            uploadedFileNames: uploadedFileNames,
                            isUploadInProgress: false,
                            friendlyLabelErrorText: resFriendlyName !== "" ? "" : friendlyLabelErrorText,
                            friendlyLabelText: resFriendlyName !== "" ? resFriendlyName : "",
                            disableFriendlyName: resFriendlyName!=="" ? true:"",
                            testButtonDisable: resFriendlyName !== "" ? false : true,
                            openSnackBar:true,
                            errMassage:Resource.get("Uploaded Succesfully")//"Uploaded Succesfully"
                          });
                        })
                        .catch((error) => {
                          let helperText;
                          if (
                            error.response &&
                            error.response.data &&
                            error.response.data.apierrorresponse.errorMessage &&
                            error.response.data.apierrorresponse.errorMessage !== ""
                            ) {
                              helperText = error.response.data.apierrorresponse.errorMessage;
                            } else {
                              helperText = `There was a problem while importing. Please contact administrator.`;
                            }
                            this.resetData();
                            this.setState({
                              status: true,
                              text: helperText,
                              confirmationNeeded: false,
                            });
                          });
                        }
                      };
                      
                      ondialogClose = (info) => {
                        if (
                          info === "yes" &&
                          this.confirmationInfo.callback &&
                          this.confirmationInfo.data
                          ) {
                            //this.testFiles(true);
                            this.confirmationInfo.callback.call(this, this.confirmationInfo.data);
                          } else if (info === "no" && this.confirmationInfo.statusCode === 100) {
                            this.resetData();
                          } else if (this.confirmationInfo.statusCode === 409) {
                            this.resetData();
                          }
                          this.setState({ status: false, text: null, confirmationNeeded: false });
                          this.confirmationInfo.callback = null;
                          this.confirmationInfo.data = null;
                          this.confirmationInfo.statusCode = null;
                        };
                        handleCloseSnackBar = () => {
                          this.setState({ openSnackBar: false });
                        };
                        render() {
                          const setupWizardImg = <SetupWizardIcon />;
                          let { saas } = this.props,
                          helpLink;
                          if (saas) {
                            helpLink =
                            "https://techdocs.broadcom.com/bin/gethidpage?hid=webservices_rest&space=UIMPGA&language=&format=rendered";
                          } else {
                            helpLink =
                            "https://techdocs.broadcom.com/bin/gethidpage?hid=HID_RESTMON&space=UIM204&language=&format=rendered";
                          }
                          // Download RESTMon schema template
                          const instructionmessage = Resource.get("Download RESTMon schema template");
                          // Download the RESTMon schema template and then configure to monitor RESTful API
                          const instructionmessage1 = Resource.get(
                            "Download the RESTMon schema template and then configure to monitor your RESTful API"
                            );
                            //RESTMon schema template
                            const instructionmessage2 = Resource.get("RESTMon schema template");
                            //DOWNLOAD
                            const instructionmessage3 = Resource.get("Download");
                            //Upload custom RESTMon schema
                            const instructionmessage4 = Resource.get("Upload custom RESTMon schema");
                            // RESTMon instruction message
                            const instructionmessage5 = Resource.get(
                              "After modifying the RESTMon schema to meet your needs, upload the JSON file to be tested"
                              );
                              // Upload file...
                              const instructionmessage6 = Resource.get("Upload File...");
                              // Test custom schema
                              const instructionmessage7 = Resource.get(
                                "Validate and deploy custom schema"
                                );
                                //schema validation & deployment
                                const instructionmessage8 = Resource.get(
                                  "schema validation & probe package deployment"
                                  );
                                  //TEST
                                  const instructionmessage9 = Resource.get("Proceed");
                                  //Directions
                                  const instructionmessage10 = Resource.get("Directions");
                                  //Home
                                  const instructionmessage11 = Resource.get("Home");
                                  // Modify schema to collect API-defined metrics
                                  const instructionmessage12 = Resource.get(
                                    "Modify schema to collect API-defined metrics	"
                                    );
                                    // Upload modified schema (JSON file)
                                    const instructionmessage13 = Resource.get(
                                      "Upload schema file(s) related to a technology"
                                      );
                                      // Test schema syntax
                                      const instructionmessage14 = Resource.get("Test schema syntax");
                                      //Select Done
                                      const instructionmessage15 = Resource.get("Select Done");
                                      //HELP
                                      const instructionmessage16 = Resource.get("Help");
                                      //Help
                                      const instructionmessage17 = Resource.get("Help");
                                      //Reset
                                      const instructionmessage18 = Resource.get("Reset");
                                      //Done
                                      const instructionmessage19 = Resource.get("Done");
                                      //Browse
                                      const instructionmessage20 = Resource.get("Browse");
                                      
                                      //To validate and deploy the schema upload the file(s) and provide a friendly name, and then, click Proceed
                                      const instructionmessage21 = Resource.get(
                                        "To validate and deploy the schema upload the file(s) and provide a friendly name, and then, click Proceed"
                                        ); //Upload text
                                        
                                        //Default template schemas(restmon-sample-schemas) can be found at support.nimsoft.com
                                        const instructionmessage22 = Resource.get(
                                          "Default template schemas (restmon-sample-schemas) can be found at support.nimsoft.com"
                                          );
                                          //Friendly Name can not contain any of the following characters
                                          const friendlyNameMsg = Resource.get("Friendly Name can not contain any of the following characters");
                                          //Information about valid CI and Metric types supported, refer to
                                          const instructionmessage23 = Resource.get("Information about valid CI and Metric types supported, refer to");
                                          //SUPPORTED_CI_METRIC_TYPES.XLSX
                                          const instructionmessage24 = Resource.get("SUPPORTED_CI_METRIC_TYPES.XLSX");
                                          //For more information, click
                                          const instructionmessage25 = Resource.get("For more information, click");
                                          // Bullet Icon
                                          const BULLET_ICON = Resource.get('Bullet Point Icon')
                                          // Browse button click
                                          const BROWSE_BTN_CLICK = Resource.get("Browse button click to select schema JSON Files")
                                          // Upload button click
                                          const UPLOAD_BTN_CLICK = Resource.get("Upload button click to upload schema JSON Files")
                                          // Download button click
                                          const DOWNLOAD_BTN_CLICK = Resource.get("Download button click to download default schema")
                                          // Hide log button
                                          const HIDE_LOG_BTN = Resource.get("hide log button click to hide log information")
                                          // Show log button
                                          const SHOW_LOG_BTN = Resource.get("Show log  button click to see log information")
                                          // Show log
                                          const SHOW_LOG = Resource.get("Show log")
                                          // Hide log
                                          const HIDE_LOG = Resource.get("Hide log")
                                          // Enter Friendly Name
                                          const ENTER_FRIENDLY_NAME = Resource.get("Enter Friendly Name")
                                          // name label
                                          const NAME_LABEL = Resource.get('Name')
                                          // Proceed with this
                                          const PROCEED_WITH_THIS = Resource.get("Proceed with this ")
                                          // Name to deploy
                                          const NAME_TO_DEPLOY = Resource.get(" name to deploy the schema upload file(s)")
                                          // Reset all page changes
                                          const RESET_ALL_PAGE = Resource.get("Reset all page changes")
                                          // Select Done
                                          const SELECT_DONE = Resource.get("Select Done")
                                          const characters = '\\ / : * ? " < > | ';
                                          const textWithImg = function () {
                                            if (saas) {
                                              return (
                                                <span style={{ fontSize: "15px" }}>
                                                In Setup Wizard(
                                                  {
                                                    <div style={{ display: "inline", verticalAlign: "-7px" }}>
                                                    <SetupWizardIcon role='figure'/>
                                                    </div>
                                                  }
                                                  ) launch newly created monitoring probe to finish configuration.
                                                  </span>
                                                  );
                                                } else {
                                                  return (
                                                    <span style={{ fontSize: "15px" }}>
                                                    Start monitoring the newly created probe by configuring it.
                                                    </span>
                                                    );
                                                  }
                                                };
                                                return (
                                                  <div style={{ height: "100%" }}>
                                                   <input
                                                  type="file"
                                                  accept=".json"
                                                  onChange={this.handleFile}
                                                  //disabled={this.state.browseButtonDisable}
                                                  ref={this.fileInput}
                                                  style={{
                                                    display:"none"
                                                  }}
                                                  />
                                                  <div className="createProbeScreen__content" >
                                                  <div className="createProbeScreen__content_left">
                                                  <div className="createProbeScreen__left">
                                                  <div>
                                                  {/* <div className="createProbeScreen__cardTitle"> */}
                                                  <h2>{instructionmessage}</h2>
                                                  {/* </div> */}
                                                  <Divider />
                                                  
                                                  <div className="createProbeScreen__Text">
                                                  <div className="createProbeScreen__TextLeft">
                                                  <p className="createProbeScreen-largeText"  id='createProbeScreen_large_Text'>
                                                  {instructionmessage1}
                                                  </p>
                                                  </div>
                                                  <div className="createProbeScreen__TextRight">
                                                  <div className="createProbeScreen-mediumText">
                                                  {instructionmessage2}
                                                  </div>
                                                  <RaisedButton
                                                  variant="contained"
                                                  children={instructionmessage3}
                                                  onClick={this.downloadSchema}
                                                  
                                                  color = "primary"
                                                  className="createProbeScreen__hubButton"
                                                  aria-roledescription={this.state.text?this.state.text:DOWNLOAD_BTN_CLICK}
                                                  />
                                                  </div>
                                                  </div>
                                                  
                                                  {/* <div className="createProbeScreen__cardTitle"> */}
                                                  <h2>{instructionmessage4}</h2>
                                                  {/* </div> */}
                                                  <Divider />
                                                  
                                                  <div className="createProbeScreen__Text">
                                                  <div className="createProbeScreen__TextLeft">
                                                  <p className="createProbeScreen-largeText"  id='create_ProbeScreen_largeText'>
                                                  {instructionmessage5}
                                                  </p>
                                                 
                                                  </div>
                                                  <div
                                                  className="createProbeScreen__TextRight"
                                                  
                                                  >
                                                   
                                                  <RaisedButton id="browsebuttonrestmon"
                                                  variant="contained"
                                                  href="#"
                                                  color = "primary"
                                                  disabled={this.state.browseButtonDisable}
                                                  classes={{containedPrimary:"createProbeScreen__hubButton"}}
                                                  onClick={this.handleClick}
                                                  aria-roledescription={BROWSE_BTN_CLICK}
                                                 role="button"
                                                  >
                                                  {instructionmessage20}
                                                 
                                                  <FailedImportDialog
                                                  open={this.state.status}
                                                  text={this.state.text}
                                                  confirmationNeeded={this.state.confirmationNeeded}
                                                  onClose={(info) => this.ondialogClose(info)}
                                                  />
                                                  </RaisedButton>
                                                  {this.state.isUploadInProgress ?
                                                  <div style={{ position: "relative" }}>
                                                  
                                                    <span
                                                    style={{
                                                      position: "absolute",
                                                      top: "17px",
                                                      left: "-30px",
                                                    }}
                                                    >
                                                    <InProgress className="inProgressState" />
                                                    </span>
                                                    </div>
                                                    
                                                     
                                                     : 
                                                      null
                                                      }
                                                     
                                                      </div>
                                                      </div>
                                                      <div className="createProbeScreen__Text">
                                                      <div className="createProbeScreen__TextLeft">
                                                 
                                                  <FilesList
                                                  data={this.state.files}
                                                  onFileDelete={this.onFileDelete}
                                                  />
                                                  </div>
                                                  <div
                                                  className="createProbeScreen__TextRight"
                                                  
                                                  >
                                                   <RaisedButton 
                                                      variant="contained"
                                                      children={instructionmessage6}
                                                      disabled={this.state.uploadButtonDisable}
                                                      color = "primary"
                                                      classes={{containedPrimary:"createProbeScreen__hubButton"}}
                                                      onClick={this.upload}
                                                      aria-roledescription={UPLOAD_BTN_CLICK}
                                                      />
                                                   </div>
                                                      </div>
                                                      
                                                      {/* <div className="createProbeScreen__cardTitle"> */}
                                                      <h2>{instructionmessage7}</h2>
                                                      {/* </div> */}
                                                      <Divider />
                                                      
                                                      <div className="createProbeScreen__Text">
                                                      <div className="createProbeScreen__TextLeft">
                                                      {this.state.showProgress ? (
                                                        <div tabIndex={-1}>
                                                        <div tabIndex={-1}
                                                        style={{
                                                          fontWeight: "800",
                                                          color: "#E81123",
                                                          marginBottom: "10px",
                                                        }}
                                                        className="createProbeScreen-largeText" id='createProbeScreen_largeText'
                                                        >
                                                        <div tabIndex={-1}
                                                        style={{
                                                          verticalAlign: "middle",
                                                          display: "inline-block",
                                                          marginRight: "8px",
                                                        }}
                                                        >
                                                        {this.getSyntaxValidationIcon()}
                                                        </div>
                                                        {this.state.progressMessage === ""
                                                        ? instructionmessage8
                                                        : this.state.progressMessage}
                                                        </div>
                                                        <LinearProgress  tabIndex={-1}
                                                        mode="determinate" aria-label={this.state.progressMessage === ""
                                                        ? instructionmessage8
                                                        : this.state.progressMessage}
                                                        value={this.state.progressBar}
                                                        />
                                                        <div className="createProbeSyntaxValidation__Text"></div>
                                                      
                                                          </div>
                                                          ) : (
                                                            <p className="createProbeScreen-largeText" id='createProbeScreen_largeText'>
                                                            {instructionmessage21}
                                                            </p>
                                                            )}
                                                            </div>
                                                            <div
                                                            style={{ position: "relative" }}
                                                            className="createProbeScreen__TextRight"
                                                            >
                                                            {this.state.showFriendlyNameError ? (
                                                              <div className="tooltiptext">
                                                              {friendlyNameMsg}
                                                              <div>{characters}</div>
                                                              </div>
                                                              ) : (
                                                                ""
                                                                )}
                                                                <FormControl >
                                                                <InputLabel><span style={{fontSize: '11px',lineHeight: '16px',color: '#DE1B1B',float:'right'}}>Required</span></InputLabel>
                                                                <TextField  
                                                              //  placeholder={ENTER_FRIENDLY_NAME}
                                                                onKeyDown={this.isAllowed}
                                                                value={this.state.friendlyLabelText}
                                                                disabled={this.state.disableFriendlyName}
                                                               // helperText={this.state.friendlyLabelErrorText}
                                                                onChange={this.onFriendlyNameChange}
                                                                inputProps={{maxLength: 100,"aria-label":NAME_LABEL,'aria-required':true}}
                                                                FormHelperTextProps={{style:{
                                                                  color: "#E81123"
                                                                }}}
                                                                />
                                                                </FormControl>
                                                                <RaisedButton id="ProceedButton"
                                                                variant="contained"
                                                                children={instructionmessage9}
                                                                disabled={this.state.testButtonDisable}
                                                                color ="primary"
                                                                className="createProbeScreen__hubButton"
                                                                onClick={this.testFiles}
                                                                aria-roledescription={PROCEED_WITH_THIS +  `${this.state.friendlyLabelText}` + NAME_TO_DEPLOY}
                                                                />
                                                                  <div style={{ paddingTop: "10px" }}>
                                                        {this.state.debugMessagesButton ? (
                                                          <span style={{ paddingLeft: "5px" }}>
                                                          <FlatButton tabIndex={0}
                                                          children={
                                                            this.state.showProgressError
                                                            ? HIDE_LOG
                                                            : SHOW_LOG
                                                          }
                                                          style={{ height: "24px", lineHeight: "20px" }}
                                                          variant="text"
                                                          color = "primary"
                                                          size="small"
                                                          onClick={this.changeShowProgressError}
                                                          //className= "logButtonStyle"
                                                          //containerElement="button"
                                                          aria-label={
                                                            this.state.showProgressError
                                                            ? HIDE_LOG_BTN
                                                            : SHOW_LOG_BTN
                                                          }
                                                          aria-roledescription={
                                                            this.state.showProgressError
                                                            ? HIDE_LOG_BTN
                                                            : SHOW_LOG_BTN
                                                          }
                                                          />
                                                          </span>
                                                          ) : null}
                                                          </div>
                                                                </div>
                                                                </div>
                                                                </div>
                                                                {this.state.showProgressError ? (
                                                                  <div tabIndex={0}
                                                                  style={{
                                                                    height: "calc(100% - 680px)",
                                                                    overflow: "auto",
                                                                    marginTop: "5px",
                                                                    width: "94%",
                                                                    fontSize: "12px",
                                                                    display: "flex",
                                                                    boxSizing: "border-box",
                                                                    minHeight: "100px",
                                                                  }}
                                                                  >
                                                                  <List aria-label="Error log messages">
                                                                  {this.debugMessages.map((message, index) => (
                                                                    <ListItem key={index}>{message}</ListItem>
                                                                    ))}
                                                                    </List>
                                                                    </div>
                                                                    ) : null}
                                                                    </div>
                                                                    </div>
                                                                    
                                                                    <div className="createProbeScreen__sidebar">
                                                                    <div className="directionsScreen__background">
                                                                    <div style={{padding:'16px'}}>
                                                                    <p className="createProbeScreen__sidebarHeader">
                                                                    {instructionmessage10}
                                                                    </p>
                                                                    <List aria-label={instructionmessage10} className="createProbeScreen__sidebar__requirementsList">
                                                                    <ListItem
                                                                    style={{ padding: 0 }}
                                                                    disabled={true}
                                                                    className="directionsList__item" 
                                                                    >
                                                                    <ListItemIcon>
                                                                    <BulletPoint
                                                                    className="relayList__icon"
                                                                    title={BULLET_ICON}
                                                                    role='figure'
                                                                    alt={BULLET_ICON}
                                                                    />   
                                                                    </ListItemIcon>
                                                                    <ListItemText classes={{primary:'direction-list-item-text'}} title={instructionmessage} primary={instructionmessage} />
                                                                    </ListItem>
                                                                    
                                                                    <ListItem
                                                                    style={{ padding: 0 }}
                                                                    disabled={true}
                                                                    className="directionsList__item" 
                                                                    >
                                                                    <ListItemIcon>
                                                                    <BulletPoint
                                                                    className="relayList__icon"
                                                                    title={instructionmessage11}
                                                                    role='figure'
                                                                    />
                                                                    </ListItemIcon>
                                                                    <ListItemText classes={{primary:'direction-list-item-text'}} title={instructionmessage22} primary={instructionmessage22} />
                                                                    </ListItem>
                                                                    
                                                                    <ListItem
                                                                    style={{ padding: 0 }}
                                                                    disabled={true}
                                                                    className="directionsList__item"                                 
                                                                    >
                                                                    <ListItemIcon>
                                                                    <BulletPoint
                                                                    className="relayList__icon"
                                                                    title={instructionmessage11}
                                                                    role='figure'
                                                                    />
                                                                    </ListItemIcon>
                                                                    <ListItemText classes={{primary:'direction-list-item-text'}} title={instructionmessage12} primary={instructionmessage12} />
                                                                    </ListItem>
                                                                    
                                                                    <ListItem
                                                                    style={{ padding: 0 }}
                                                                    disabled={true}
                                                                    className="directionsList__item"                                    
                                                                    >
                                                                    <ListItemIcon>
                                                                    <BulletPoint
                                                                    className="relayList__icon"
                                                                    title={instructionmessage11}
                                                                    role='figure'
                                                                    />
                                                                    </ListItemIcon>
                                                                    <ListItemText classes={{primary:'direction-list-item-text'}} title={instructionmessage13} primary={instructionmessage13} />
                                                                    </ListItem>
                                                                    
                                                                    <ListItem
                                                                    style={{ padding: 0 }}
                                                                    disabled={true}
                                                                    className="directionsList__item"
                                                                    >
                                                                    <ListItemIcon>
                                                                    <BulletPoint
                                                                    className="relayList__icon"
                                                                    title={instructionmessage11}
                                                                    role='figure'
                                                                    />
                                                                    </ListItemIcon>
                                                                    <ListItemText classes={{primary:'direction-list-item-text'}} title={instructionmessage14} primary={instructionmessage14} />
                                                                    </ListItem>
                                                                    
                                                                    <ListItem
                                                                    style={{ padding: 0 }}
                                                                    disabled={true}
                                                                    className="directionsList__item"
                                                                    >
                                                                    <ListItemIcon>
                                                                    <BulletPoint
                                                                    className="relayList__icon"
                                                                    title={instructionmessage11}
                                                                    role='figure'
                                                                    />
                                                                    </ListItemIcon>
                                                                    <ListItemText classes={{primary:'direction-list-item-text'}} title={instructionmessage15} primary={instructionmessage15} />
                                                                    </ListItem>
                                                                    
                                                                    <ListItem
                                                                    style={{ padding: 0 }}
                                                                    disabled={true}
                                                                    className="directionsList__item"
                                                                    >
                                                                    <ListItemIcon>
                                                                    <div
                                                                    style={{
                                                                      position: "relative",
                                                                      left: "0px",
                                                                      margin: "0px",
                                                                      width: "10px",
                                                                      height: "100%",
                                                                      float: "left",
                                                                      paddingRight: "10px",
                                                                    }}
                                                                    >
                                                                    <BulletPoint
                                                                    className="relayList__icon"
                                                                    title={instructionmessage11}
                                                                    role='figure'
                                                                    />
                                                                    </div>
                                                                    </ListItemIcon>
                                                                    <ListItemText classes={{primary:'direction-list-item-text'}} primary={textWithImg()} />
                                                                    </ListItem>
                                                                    </List>
                                                                    
                                                                    <p
                                                                    style={{
                                                                      paddingTop: "10px",
                                                                      fontSize: "15px",
                                                                      fontWeight: "500",
                                                                      color: "#000000",
                                                                      
                                                                    }}
                                                                    >
                                                                    {instructionmessage23}
                                                                    <a
                                                                    href={`${config.basename}/SUPPORTED_CI_METRIC_TYPES.xlsx`}
                                                                    target="_blank"
                                                                    aria-roledescription="Click to download the supported CI Metrics file"
                                                                    download
                                                                    style={{
                                                                      textDecoration: "none",
                                                                      color: "#3272D9",
                                                                      fontFamily: "roboto_bold",
                                                                      marginLeft: "4px",
                                                                    fontSize:'15px'
                                                                    }}
                                                                    >
                                                                    {instructionmessage24}
                                                                    </a>
                                                                    </p>
                                                                    <div style={{ paddingTop: "30px" }}>
                                                                    <CardContent style={{ padding: 0 }}>
                                                                    <div className="help-section" >
                                                                    {" "}
                                                                    <Typography component="span" style={{fontSize:'15px',color:'#000000'}}>{`${instructionmessage25} `}</Typography>
                                                                    <Tooltip title="Help">
                                                                    <IconButton role="link" 
                                                                    onClick={() => window.open(helpLink, "_blank")}
                                                                    aria-roledescription="Click to HELP link open the documentation"
                                                                    inputProps={{'aria-role':"link",'aria-roledescription':"Click to open the documentation"}}
                                                                   >
                                                                      <HelpIcon
                                                                     // className="help-icon"
                                                                      title={instructionmessage17}
                                                                      role='figure'
                                                                      />
                                                                    </IconButton>
                                                                    </Tooltip>
                                                                   
                                                                                  
                                                                    
                                                                    </div>
                                                                    </CardContent>
                                                                    </div>
                                                                    
                                                                    </div></div>
                                                                    </div>
                                                                    </div>
                                                                    <DialogActions style={{padding:'0px',marginRight:'33%'}}>
                                                                   
                                                                    <FlatButton
                                                                    children={instructionmessage18}
                                                                    variant="text"
                                                                    color = "primary"
                                                                    disabled={this.state.resetButtonDisable}
                                                                    onClick={this.resetData}
                                                                    //containerElement="button"
                                                                    style={{ float: "left" }}
                                                                   
                                                                   
                                                                    />
                                                                    <FlatButton variant="contained"
                                                                    children={instructionmessage19}
                                                                    style={{float:'right'}}
                                                                    color = "primary"
                                                                    disabled={this.state.doneButtonDisable}
                                                                    onClick={() => {
                                                                      this.props.onDone();
                                                                    }}
                                                                    // containerElement="button"
                                                                    aria-roledescription={SELECT_DONE}
                                                                    />
                                                                   
                                                                    </DialogActions>
                                                    <Snackbar
                                                      open={this.state.openSnackBar}
                                                      message={this.state.errMassage}
                                                      autoHideDuration={2000}
                                                      onClose={this.handleCloseSnackBar}
                                                    />
                                                                    </div>
                                                                    );
                                                                  }
                                                                }
                                                                
                                                                export default CreateMonitoringProbe;
                                                                