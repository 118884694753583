import React from 'react'
import CabiTimeRange from './../timeRange/CabiTimeRange'

const CabiControls = (props) => {
  let {
    onTimeRangeChange,
    timeRange,
    start_date,
    end_date,
    showTimeRange
    } = props
  return (
    <aside className='section-controls'>
      { showTimeRange ? <CabiTimeRange timeRange={timeRange} onChange={onTimeRangeChange}
                            start_date={start_date} end_date={end_date} /> : null}
    </aside>
  )
}

export default CabiControls