import React from 'react'
import get from 'lodash/get'
import Resource from './Resource'
import { Component } from 'react'
import connect from './../../../../utils/connect'
import {CircularProgress} from '@mineral/core';
import {
  CenterError as Error,
  CenterInfo,
} from '../../../ui-components/uim-components'
import computerSystems from './../../../../api/computer-systems/computerSystems'
import isEmpty from 'lodash/isEmpty'

const attributeKeyMap = {
  //Name 		
  name: Resource.get('Name'),
  //OS Type
  osType: Resource.get('OS Type'),
  //OS Name
  osName: Resource.get('OS Name'),
  //OS Description
  osDescription: Resource.get('OS Description'),
  //Primary Role
  role: Resource.get('Primary Role'),
  //IP Addresses
  ipAddresses: Resource.get('IP Addresses'),
  //Primary MAC Address
  'attributes.PrimaryMacAddress': Resource.get('Primary MAC Address'),
  //Number Of Cores
  'attributes.NumberOfCores': Resource.get('Number Of Cores'),
  //Computer Name
  'attributes.ComputerName': Resource.get('Computer Name'),
  //VM Name
  'attributes.VMName': Resource.get('VM Name'),
  //Virtualization Environment
  'attributes.VirtualizationEnvironment': Resource.get('Virtualization Environment'),
  //Physical Serial Number
  'attributes.PhysSerialNumber': Resource.get('Physical Serial Number'),
  //System Name
  'attributes.sysName': Resource.get('System Name'),
  //Vendor
  'attributes.Vendor':Resource.get('Vendor'),
  //Model
  'attributes.Model': Resource.get('Model'),
  //Firmware Version
  'attributes.FirmwareVersion': Resource.get('Firmware Version'),
  // ---- VMWARE ----
  //Power State
  'attributes.powerState': Resource.get('Power State'),
  //Tools Version
  'attributes.toolsVersion':Resource.get('Tools Version'),
  //Tools Status
  'attributes.toolsStatus': Resource.get('Tools Status'),
  //Resource Pool
  'attributes.resourcePool': Resource.get('Resource Pool'),
  //Hostname
  'attributes.hostName': Resource.get('Hostname'),
  // ---- AWS ----
  //AWS Instance State
  'attributes.AWSInstanceState': Resource.get('AWS Instance State'),
  //AWS Availability Zone
  'attributes.AWSAvailabilityZone': Resource.get('AWS Availability Zone'),
  //AWS VPC
  'attributes.AWSVPCId':Resource.get('AWS VPC'),
  //AWS Instance Type
  'attributes.AWSInstanceType': Resource.get('AWS Instance Type'),
  //AWS Monitoring State
  'attributes.AWSMonitoringState': Resource.get('AWS Monitoring State'),
}

const POWER_STATE = {
  '1.00': 'On',
  '0.00': 'Off',
  '-1.00': 'Off',
}
const TOOLS_VERSION = {
  '2.00': 'Needs Update',
  '1.00': 'Current',
  '0.00': 'Not Installed',
  '-1.00': 'Unmanaged',
}
const TOOLS_STATUS = {
  '1.00': 'Running',
  '0.00': 'Not Running',
  // -1.00 =
}

const Tr = props => {
  const tdStyle = {
    verticalAlign: 'top',
  }
  let value = Array.isArray(props.value) ? props.value.join(', ') : props.value
  switch (props.attributeKey) {
    case 'attributes.powerState':
      value = POWER_STATE[value] || value
      break
    case 'attributes.toolsVersion':
      value = TOOLS_VERSION[value] || value
      break
    case 'attributes.toolsStatus':
      value = TOOLS_STATUS[value] || value
      break
    default:
      break
  }
  return (
    <tr>
      <td style={tdStyle}>{props.attribute}:</td>
      <td style={tdStyle}>{value}</td>
    </tr>
  )
}

const DeviceSummary = props => {
  const entity = props.entity
  return (
    <table style={{borderSpacing: '0.5rem'}}>
      <colgroup>
        <col style={{ width: '30%' }} />
        <col style={{ width: '70%' }} />
      </colgroup>
      <tbody>
        {Object.keys(attributeKeyMap).map(key => {
          const value = get(entity, key)
          if (value == null) {
            return null
          }
          return <Tr key={key} attributeKey={key} attribute={attributeKeyMap[key]} value={value} />
        })}
      </tbody>
    </table>
  )
}

DeviceSummary.defaultProps = {
  entity: {},
}


class PanelRedux extends Component {
  state = {
    entity: this.props.entity,
    loadStatus: 'loading',
  }
  _getComputerSystems = (props) => {
    const params = {
      id: get(props, 'entity.id'),
      enhanced: true,
    }
    if (props.entity) {
      // computerSystems service requires a post
      computerSystems
        .get(params)
        .then(res => res.data)
        .then(computerSystem => {
          this.setState({
            entity: computerSystem,
            loadStatus: 'complete'
          })
        })
        .catch(err => {
          this.setState({ loadStatus: 'fail' })
        })
    }
  }
  componentWillMount() {
    this._getComputerSystems(this.props)
  }
  componentWillReceiveProps(nextProps) {
    this._getComputerSystems(nextProps)
  }
  render() {
    let view
    switch (this.state.loadStatus) {
      case 'complete':
        view = isEmpty(this.state.entity) ?
          <CenterInfo 
          //No computer system details found
          title='No computer system details found' /> :
          <DeviceSummary entity={this.state.entity} />
        break
      case 'fail':
        view = <Error />
        break
      case 'loading':
      default:
        view = view = <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><CircularProgress style={{color: '#3272D9'}} /></div>
        break
    }
    return view
  }
}

export default connect(null, null)(PanelRedux)