import React from "react";
import moment from "moment";

const style = {
  padding: 6,
  backgroundColor: "#fff",
  border: "1px solid #ccc"
};

const CustomTooltip = props => {  
    return (
      <div className="area-chart-tooltip">
        {props.aggregationValue===-1?moment(props.label*1000).toString().substr(0,24):moment(new Date(props.label).getTime()).toString().substr(0,24)}
      </div>
    );
};

export default CustomTooltip;
