import React, { Component} from "react";
import PropTypes from 'prop-types';
import { Route } from "react-router-dom";
import ToggleIcons from "./toggleIcons/ToggleIconsRedux";
import ControlsRedux from "./controls/ControlsRedux";
import CabiControlsRedux from "./../../cabi/secondaryNav/controls/CabiControlsRedux";
import "./secondaryNav.less";
import CrossLaunchHeader from "./crossLaunchHeader/CrossLaunchHeader";

class ControlsReduxWrapperNoSort extends Component {
  render() {
    return <><ControlsRedux showSort={false} /></>;
  }
}
class ControlsReduxWrapper extends Component {
  render() {
    return <><ControlsRedux/></>;
  }
}
/* <!--Route
        exact
        path={`${context.entityMatchRegex}/treeviewnew`}
        component={ControlsReduxWrapper}
      /-->*/
const SecondaryNav = (props, context) => {
  let dropdownValue=
      window.location.pathname.search("card") > -1
        ? 1
        : window.location.pathname.search("treeviewnew") > -1
          ? 3
          : 2;
  return (
    <nav className="groups_nav--secondary" style={{height:!props.makeMargin100 && (dropdownValue===3 || dropdownValue===2 || dropdownValue===1 || props.isInventoryView)?'40px':'92px',width:props.view===3?'100%':'96.5%',marginLeft:props.view===3?'0px':'67px'}}>
    <ToggleIcons  view={props.view} tabValue={props.tabValue}/>
    <div style={{width:'100%'}}>
      <Route
        exact
        path={`${context.entityMatchRegex}/card`}
        component={ControlsReduxWrapper}
      />
      <Route
        exact
        path={`${context.entityMatchRegex}/treeview`}
        component={ControlsReduxWrapperNoSort}
      />
     
      
     
      <Route
        path={`${context.entityMatchRegex}/cabi`}
        component={CabiControlsRedux}
      />
     
      
   
      </div>
      
    </nav>
  );
};

SecondaryNav.contextTypes = {
  entityMatchRegex: PropTypes.string,
};

export default SecondaryNav;
