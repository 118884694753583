import React from 'react'
import { Route } from 'react-router-dom'
import SetupWizardRedux from '../components/setupWizard/SetupWizardRedux'
import connect from './../utils/connect'
import AclTypes from './../api/aclTypes'

const SetupWizard = () => {
	let className = ['main-setupwizard'].join(' ');
  return (
    <main className={className}>
      <SetupWizardRedux />
    </main>
  )
}

const SetupWizardRoute = props => {

    return <Route path="/remote-cloud-tiles" component={SetupWizard} />

  return null
}

const mapStateToProps = state => {
  return {
    saas: state.saas,
    acl: state.acl,
  }
}

export default connect(mapStateToProps, null)(SetupWizardRoute)
