export const strokeColors = [
  '#FF3CD2',
  '#C01CDB',
  '#6F20A8',
  '#A8235F',
  '#FF9900',
  '#F52400',
  '#F50C75',
  '#F55D0B',
  '#AD4400',
  '#C45600',
  '#A87B19',
  '#1A11DA',
  '#0D67D6',
  '#1597A8',
  '#89AD8F',
  '#506B07',
  '#00451F',
  '#006C00',
  '#4EA800',
  '#06DB5B',
];
  
  export const customStroke = [
    'dashed',
    'spacedDashed',
    'dotted',
    'dashDot',
    'solid'
  ];
  
  export const customStrokeThickness = [
    '2px',
    '4px',
    '8px',
  ];
  
  export const iconTypes = [
    'filledCircle',
    'filledHex',
    'filledSquare',
    'filledPolygon',
    'filledRhombus',
    'filledStar1',
    'filledStar2',
    'filledStar3',
    'filledStar4',
    'filledStar5',
    'filledStar6',
    'filledTriangleUp',
    'filledTriangleDown',
    'outlinedCircle',
    'outlinedHex',
    'outlinedSquare',
    'outlinedPolygon',
    'outlinedRomb',
    'outlinedStar1',
    'outlinedStar2',
    'outlinedStar3',
    'outlinedStar4',
    'outlinedStar5',
    'outlinedStar6',
    'outlinedTriangleUp',
    'outlinedTriangleDown',
  ];
  
  
  function combineArraysRecursively(array_of_arrays) {
    if (!array_of_arrays) {
      return [];
    }
    if (!Array.isArray(array_of_arrays)) {
      return [];
    }
    if (array_of_arrays.length === 0) {
      return [];
    }
  
    for (let i = 0; i < array_of_arrays.length; i++) {
      if (!Array.isArray(array_of_arrays[i]) || array_of_arrays[i].length === 0) {
        return [];
      }
    }
  
    let odometer = new Array(array_of_arrays.length);
    odometer.fill(0);
  
    let output = [];
  
    let newCombination = formCombination(odometer, array_of_arrays);
  
    output.push(newCombination);
  
    while (odometer_increment(odometer, array_of_arrays)) {
      newCombination = formCombination(odometer, array_of_arrays);
      output.push(newCombination);
    }
  
    return output;
  }
  
  
  function formCombination(odometer, array_of_arrays) {
    const comboSet = {};
    return odometer.reduce(
      function (accumulator, odometer_value, odometer_index) {
        if(odometer_index === 0) {
           comboSet.color = array_of_arrays[odometer_index][odometer_value]
        }
        else if(odometer_index === 1) {
           comboSet.stroke = array_of_arrays[odometer_index][odometer_value];
        }
        else if(odometer_index === 2) {
           comboSet.icon = array_of_arrays[odometer_index][odometer_value];
        }
        return comboSet
      },
      {}
    )
  }
  
  function odometer_increment(odometer, array_of_arrays) {
  
    for (let i_odometer_digit = odometer.length - 1; i_odometer_digit >= 0; i_odometer_digit--) {
  
      let maxee = array_of_arrays[i_odometer_digit].length - 1;
  
      if (odometer[i_odometer_digit] + 1 <= maxee) {
        odometer[i_odometer_digit]++;
        return true;
      }
      else {
        if (i_odometer_digit - 1 < 0) {
          return false;
        }
        else {
          odometer[i_odometer_digit] = 0;
          continue;
        }
      }
    }
  }
  
  export const strokeCombinations = combineArraysRecursively([strokeColors, customStroke, iconTypes])