

import { TextField } from '@mineral/core';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import React from 'react';
import './mineralTimePicker.less'
import dayjs from 'dayjs';

export const MineralTimePicker = ({
  value,
  label,
  onValueChange,
  inputFormat,
  size,
  ...restProps
  //   updateTime,
}) => {
  const [timeValue, setValue] = React.useState(dayjs(value));
  const [open, setOpen] = React.useState(false);
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <TimePicker
        ampm={true}
        open={open}
        label={label}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false)
        }}
        value={timeValue}
        closeOnSelect
        onChange={(newValue) => {
          setValue(newValue);
          onValueChange(newValue.$d);
        }}
        PaperProps={{
          style: {
            marginTop: '10px'
          }
        }}
        PopperProps={{
          onKeyDown: (event) => {
            if (event.key === 'Enter')
              setOpen(false);
          }
        }}
        OpenPickerButtonProps={{tooltip:'Change time'}}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              placeholder: inputFormat,
              readOnly:true
            }}
            size={size}
          />
        )}
        {...restProps}
      />
    </LocalizationProvider>
  );
};