// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Administration
  content["Administration"] =
  "Administración";

  //Configure automatic groups
  content["Configure automatic groups"] =
  "Configurar grupos automáticos";

  //policies
  content["policies"] =
  "políticas";

  // policy
  content["policy"] =
  "política";

  //Existing policies
  content["Existing policies"] =
  "Políticas existentes";

  //Alarm Policies
  content["Alarm Policies"] =
  "Políticas de alarmas";

  //Manage when alarms are created
  content["Manage when alarms are created"] =
  "Gestionar cuando se crean las alarmas";

  //Settings
  content["Settings"] =
  "Configuración";

  // END OF LOCALIZATION

export default content;
