import React, { Component } from "react";
import connect from "../../utils/connect";
import {
  Dialog,
  DialogTitle,
  Button as FlatButton,
  Checkbox,
  Snackbar,
  DialogActions,
  FormControlLabel,
  Tooltip,
  IconButton,Typography,DialogContent,Grid
} from "@mineral/core";
import Resource from "./Resource";
import entityTypes from "./../../api/entityTypes";
import computerSystemsApi from "../../api/computer-systems/computerSystems";
import groupApi from "../../api/groups/groups";
import FileSaver from "file-saver";
import * as XLSX from "xlsx";
import moment from "moment";
import get from "lodash/get";
import "./treeContainer.less";
import {
  ClearIcon as Clear,
} from "../ui-components/uim-components";

import CustomSnackbar from '../common/CustomSnackbar'


class ExportGroupDetailsDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      groupDetail: false,
      deviceDetail: false,
      mcsProfile: false,
      interfaceDetails: false,
      alarmPolicy: false,
      mcsData: [],
      alarmPolicyData: [],
      interfaceData: [],
      groupDeviceData: [],
      openExportSnackBar: false,
      savedColumns: this.props.savedColumns,
      isInterfaceGroup: this.props.isInterfaceGroup,
      groupName: this.props.name ? this.props.name : this.props.groupName,
      interfaceGroupName: "",
      isOkbtnClicked: false,
      open:false,
      hoverValue:"",
      openNoDataToExportAlert:false
    };
    this.exportDialog=React.createRef();
  }
  handler = (e) => {
    if (e.key === 'Escape') {
      this.onCloseTooltip();
      this.exportDialog.current.removeEventListener('keydown',this.handler)
    }
  }
  onOpen = (value) => {
 
    this.setState({ open:true,hoverValue:value });
    this.exportDialog.current.addEventListener('keydown',this.handler)
  }
  onCloseTooltip = () => {
     this.setState({ open:false,hoverValue:"" });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open) {
      computerSystemsApi.getSavedColumns().then((response) => {
        let columns = response.data.columns;
        this.setState({
          savedColumns: columns && columns.length > 0 ? columns : [],
        });
      });
      this.setState({
        isInterfaceGroup: nextProps.isInterfaceGroup,
        groupName: nextProps.name ? nextProps.name : nextProps.groupName,
      });
    }
  }

  onClose = () => {
    this.props.requestClose();
    if (!this.state.isOkbtnClicked) {
      this.setState({
        openExportSnackBar: false,
        isOkbtnClicked: false,
        groupDetail: false,
        deviceDetail: false,
        mcsProfile: false,
        interfaceDetails: false,
        alarmPolicy: false,
        mcsData: [],
        alarmPolicyData: [],
        interfaceData: [],
        groupDeviceData: [],
      });
    }
  };

  handleExportDetails = (props, state) => {
    let resGroupData = [];
    let resGroupMemberCountData = [];
    let resDevicedata = [];
    let resInterfacedata = [];
    let resMcsdata = [];
    let resPolicydata = [];
    let selectedGroupId = props.selectedId ? props.selectedId : props.groupId;
    let interfaceGroupName = "";

    if (
      this.state.deviceDetail &&
      (props.entityType === entityTypes.AUTOMATIC ||
        props.entityType === entityTypes.STATIC ||
        props.entityType === entityTypes.DYNAMIC) &&
      !this.state.interfaceDetails &&
      !this.state.mcsProfile &&
      !this.state.alarmPolicy
    )
      this.preapreCSV(props, state);
    else {
      this.setState({ isOkbtnClicked: true });
      //promise all
      Promise.all([
        this.state.groupDetail ? groupApi.getGroupInfo(selectedGroupId) : [],
        this.state.deviceDetail && props.entityType === entityTypes.CONTAINER
          ? groupApi.getGroupDevices(selectedGroupId)
          : [],

        this.state.interfaceDetails
          ? groupApi.getGroupInterfaces(selectedGroupId)
          : [],

        this.state.mcsProfile
          ? props.location.pathname.search("/computer-systems") > -1 ||
            (props.treeNode &&
              props.treeNode.entityType == entityTypes.COMPUTER_SYSTEM)
            ? computerSystemsApi.getCSMcsDetails(props.csId)
            : computerSystemsApi.getMcsDetails(selectedGroupId)
          : [],

        this.state.alarmPolicy
          ? props.location.pathname.search("/computer-systems") > -1 ||
            (props.treeNode &&
              props.treeNode.entityType == entityTypes.COMPUTER_SYSTEM)
            ? computerSystemsApi.getCSAlarmPolicyDetails(props.csId)
            : computerSystemsApi.getAlarmPolicyDetails(selectedGroupId)
          : [],
        this.state.groupDetail
          ? groupApi.getGroupDeviceCount(selectedGroupId)
          : [],
      ])
        .then((response) => {
          response.map((res, index) => {
            if (
              index == 0 &&
              res.data &&
              res.data.groups &&
              res.data.groups.length > 0
            ) {
              resGroupData = res.data.groups;
            } else if (
              index == 5 &&
              res.data &&
              res.data._items &&
              res.data._items.length > 0
            ) {
              resGroupMemberCountData = res.data._items;
            } else if (
              index == 1 &&
              res.data &&
              res.data._items &&
              res.data._items.length > 0
            ) {
              resDevicedata = res.data._items;
              this.setState({ groupDeviceData: [...res.data._items] });
            } else if (
              index == 2 &&
              res.data &&
              res.data.iface &&
              res.data.iface.length > 0
            ) {
              resInterfacedata = res.data.iface;
              interfaceGroupName = res.data.itemName;
              this.setState({
                interfaceData: [...res.data.iface],
                interfaceGroupName: res.data.itemName,
              });
            } else if (index == 3 && res.data && res.data.length > 0) {
              resMcsdata = res.data;
              this.setState({ mcsData: [...res.data] });
            } else if (
              index == 4 &&
              res.data &&
              res.data._items &&
              res.data._items.length > 0
            ) {
              resPolicydata = res.data._items;
              this.setState({ alarmPolicyData: [...res.data._items] });
            } else if (
              index == 4 &&
              (props.location.pathname.search("/computer-systems") > -1 ||
                (props.treeNode &&
                  props.treeNode.entityType == entityTypes.COMPUTER_SYSTEM)) &&
              res.data &&
              res.data.policies &&
              res.data.policies.length > 0
            ) {
              resPolicydata = res.data.policies;
              this.setState({ alarmPolicyData: [...res.data.policies] });
            }
          });

          this.preapreCSV(
            props,
            state,
            resGroupData,
            resGroupMemberCountData,
            resDevicedata,
            resInterfacedata,
            resMcsdata,
            resPolicydata,
            interfaceGroupName
          );
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };
  preapreCSV(
    props,
    state,
    resGroupData,
    resGroupMemberCountData,
    resDevicedata,
    resInterfacedata,
    resMcsdata,
    resPolicydata,
    interfaceGroupName
  ) {
    let workbook = XLSX.utils.book_new();
    let d = new Date();
    let dformat = `${d.getDate()}-${
      d.getMonth() + 1
    }-${d.getFullYear()}-${d.getHours()}-${d.getMinutes()}-${d.getSeconds()}`;
    let fileName = "";
    if (
      props.location.pathname.search("/computer-systems") > -1 ||
      (props.treeNode &&
        props.treeNode.entityType == entityTypes.COMPUTER_SYSTEM)
    )
      fileName =
        (props.deviceInfo ? props.deviceInfo.name : "Device Details") +
        "_" +
        dformat;
    else if (
      props.location.pathname.search("/interfaces") > -1 &&
      this.state.interfaceDetails
    )
      fileName = "Group_" + interfaceGroupName + "_" + dformat;
    else fileName = "Group_" + state.groupName + "_" + dformat;

    let exportData = [];
    let csvGroupData = [];
    let csvProfileData = [];
    let csvPolicyData = [];
    let csvInterfaceData = [];
    let devicedata = [];

    //device export
    if (this.state.deviceDetail) {
      let savedColumns =
        state.savedColumns.length > 0
          ? state.savedColumns
          : [
              "Name",
              "Device Type",
              "OS Name",
              "Origin",
              "IP Address",
              "Monitored By",
              "Bus Type",
              "Discovered",
            ];

      if (
        props.entityType === entityTypes.AUTOMATIC ||
        props.entityType === entityTypes.STATIC ||
        props.entityType === entityTypes.DYNAMIC
      ) {
        devicedata = props.fromTreeView
          ? props.filteredGroupTreeData
          : props.location.pathname.search("list") > -1 &&
            props.fromListViewRow.length > 0
          ? props.fromListViewRow
          : props.entities;
      } else if (
        props.entityType === entityTypes.CONTAINER &&
        resDevicedata.length > 0
      )
        devicedata = resDevicedata; //this.state.groupDeviceData;

      devicedata &&
        devicedata.length > 0 &&
        devicedata.forEach(function (device) {
          let time = "";
          if (device.attributes.create_time) {
            const createdTime = parseInt(device.attributes.create_time[0], 10);
            time = moment(createdTime).fromNow();
          }

          let csvData = {};
          savedColumns.forEach((headerName) => {
            switch (headerName) {
              case "Name":
                csvData[headerName] = device.name;
                break;
              case "Alias":
                csvData[headerName] = device.alias;
                break;
              case "IP Address":
                csvData[headerName] = device.ipAddresses
                  ? device.ipAddresses.toString()
                  : "";
                break;
              case "Caption":
                csvData[headerName] = device.attributes.caption
                  ? device.attributes.caption.toString()
                  : "";
                break;
              case "Device Type":
                csvData[headerName] = device.role;
                break;
              case "Description":
                csvData[headerName] = device.attributes.description
                  ? device.attributes.description.toString()
                  : "";
                break;
              case "Dedicated":
                csvData[headerName] = device.attributes.dedicated
                  ? device.attributes.dedicated.toString()
                  : "";
                break;
              case "OS Type":
                csvData[headerName] = device.osType;
                break;
              case "OS Name":
                csvData[headerName] = device.osName;
                break;
              case "OS Version":
                csvData[headerName] = device.osVersion;
                break;
              case "OS Description":
                csvData[headerName] = device.osDescription;
                break;
              case "Origin":
                csvData[headerName] = device.attributes.origin
                  ? device.attributes.origin.toString()
                  : "";
                break;
              case "MAC Address":
                csvData[headerName] = device.attributes.mac
                  ? device.attributes.mac.toString()
                  : "";
                break;
              case "User Tag 1":
                csvData[headerName] = device.attributes.user_tag_1
                  ? device.attributes.user_tag_1.toString()
                  : "";
                break;
              case "User Tag 2":
                csvData[headerName] = device.attributes.user_tag_2
                  ? device.attributes.user_tag_2.toString()
                  : "";
                break;
              case "Monitored By":
                csvData[headerName] = device.attributes.monitoredBy
                  ? device.attributes.monitoredBy.toString()
                  : "";
                break;
              case "Bus Type":
                csvData[headerName] = device.busType;
                break;
              case "CS Id":
                csvData[headerName] = device.attributes.cs_id
                  ? device.attributes.cs_id.toString()
                  : "";
                break;
              case "CS Key":
                csvData[headerName] = device.attributes.cs_key
                  ? device.attributes.cs_key.toString()
                  : "";
                break;
              case "Discovered":
                csvData[headerName] = time;
                break;
              case "Has KeyIcon":
                csvData[headerName] =
                  (device.attributes &&
                    device.attributes.wmi_profile != null) ||
                  (device.attributes && device.attributes.shell_profile != null)
                    ? "Yes"
                    : "No";
                break;
              case "Last Updated":
                csvData[headerName] =
                  device.attributes && device.attributes.change_time != null
                    ? moment(
                        parseInt(device.attributes.change_time.toString(), 10)
                      ).format("DD/MM/YYYY")
                    : "";
                break;
              case "Removed":
                csvData[headerName] = device.attributes.deleted
                  ? device.attributes.deleted.toString()
                  : "False";
                break;
            }
          });
          exportData.push(csvData);
        });
      if (
        exportData.length > 0 ||
        (this.state.interfaceDetails && resInterfacedata.length > 0) ||
        (this.state.groupDetail && resGroupData.length > 0)
      ) {
        let worksheet = XLSX.utils.json_to_sheet(exportData);
        XLSX.utils.book_append_sheet(workbook, worksheet, "Device Details");
      }
    }

    //group export
    if (
      this.state.groupDetail &&
      (resGroupData.length > 0 ||
        (this.state.deviceDetail && devicedata.length > 0) ||
        (this.state.interfaceDetails && resInterfacedata.length > 0))
    ) {
      let groupInfo = resGroupData;
      groupInfo.forEach(function (group) {
        let memberCount = 0;
        resGroupMemberCountData.forEach((data) => {
          if (data.id == group.id) {
            memberCount = data.members;
          }
        });

        csvGroupData.push({
          Name: group.name,
          Type: group.type,
          Count: memberCount,
          "Last Updated": new Date(group.lastUpdate).toUTCString(),
        });
      });
      let worksheet = XLSX.utils.json_to_sheet(csvGroupData);
      XLSX.utils.book_append_sheet(workbook, worksheet, "Group Details");
    }
    //interface
    if (
      this.state.interfaceDetails &&
      (resInterfacedata.length > 0 ||
        (this.state.deviceDetail && devicedata.length > 0) ||
        (this.state.groupDetail && resGroupData.length > 0))
    ) {
      let interfaceData = resInterfacedata;
      const KBPS = 1000;
      const MBPS = KBPS * 1000;
      const GBPS = MBPS * 1000;
      interfaceData.forEach(function (dataInterface) {
        let speed = dataInterface.ifSpeed;
        if (speed == null || isNaN(speed) || speed === 0) {
          speed = "";
        } else if (speed >= GBPS) {
          speed = speed / GBPS;
          speed = parseFloat(speed).toFixed(2) + " Gbps";
        } else if (speed >= MBPS) {
          speed = speed / MBPS;
          speed = parseFloat(speed).toFixed(2) + " Mbps";
        } else if (speed >= KBPS) {
          speed = speed / KBPS;
          speed = parseFloat(speed).toFixed(2) + " Kbps";
        } else {
          speed = parseFloat(speed).toFixed(2) + " bps";
        }
        csvInterfaceData.push({
          Name: dataInterface.displayName,
          Alias: dataInterface.displayAlias,
          Device: dataInterface.parent_displayName,
          "Instance Name": dataInterface.instanceName,
          "Template Name": dataInterface.templateName,
          Type: dataInterface.type,
          ifName: dataInterface.ifName,
          ifAlias: dataInterface.ifAlias,
          Speed: speed,
          Origin: dataInterface.origins ? dataInterface.origins.origin : "",
        });
      });
      let worksheet = XLSX.utils.json_to_sheet(csvInterfaceData);
      XLSX.utils.book_append_sheet(workbook, worksheet, "Interface Details");
    }
    //profile
    if (
      this.state.mcsProfile &&
      (resMcsdata.length > 0 ||
        (this.state.alarmPolicy && resPolicydata.length > 0) ||
        (this.state.deviceDetail && devicedata.length > 0))
    ) {
      let profiledata = resMcsdata;
      profiledata.forEach(function (profile) {
        csvProfileData.push({
          "Profile ID": profile.profileId,
          "Profile Name": profile.name,
          "Template Name": profile.templateName,
          "Template Version": profile.version,
          "Profile Origin": profile.profileStatusData.profileOrigin,
          "Created By": profile.profileStatusData.profileCreatedBy,
          Created: profile.profileStatusData.profileCreationDate,
          "Last Updated": profile.profileStatusData.profileUpdationDate,
          "Last Updated by": profile.profileStatusData.profileUpdatedBy,
          "Last Deployed": profile.profileStatusData.lastProfileDeploymentDate,
          "Last Reconciled": profile.profileStatusData.lastReconciliationDate,
          "Derived From Group": profile.profileStatusData.ancestorProfileGroup,
          "Retry Count": profile.profileStatusData.retryCount,
          "Last Audit Message": profile.profileStatusData.lastAuditMessage,
          "Profile Deployment Status":
            profile.profileStatusData.profileDeploymentStatus,
        });
      });
      let worksheet = XLSX.utils.json_to_sheet(csvProfileData);
      XLSX.utils.book_append_sheet(workbook, worksheet, "MCS Profiles");
    }
    //policy
    if (
      this.state.alarmPolicy &&
      (resPolicydata.length > 0 ||
        (this.state.deviceDetail && devicedata.length > 0) ||
        (this.state.mcsData && resMcsdata.length > 0))
    ) {
      let policyData = resPolicydata; //this.state.alarmPolicyData//this.props.policyDataStatus.alarmPolicyData._items
      policyData.forEach(function (policy) {
        let metricnames = "";
        let appliesTo = "";
        let oldValue = "";
        policy.conditions.map((cond) => {
          metricnames = metricnames + cond.policyMetric.name;
          metricnames += ",";
          let value = cond.policyTarget.name + ":" + cond.policyTarget.type;
          appliesTo = value == oldValue ? appliesTo : appliesTo + value + ", ";
          oldValue = value;
        });
        csvPolicyData.push({
          Monitor: policy.conditions[0].policyMetric.probeName,
          "Alarm Policy": policy.name,
          "Metric Name(s)":
            metricnames.length > 0
              ? metricnames.substring(0, metricnames.length - 1)
              : "",
          "Applies To":
            appliesTo.length > 0
              ? appliesTo.substring(0, appliesTo.length - 2)
              : policy.description,
          "Profile Name": policy.profile ? policy.profile.profileName : "",
          Creator: policy.author,
          "Creation Date": policy.creationDate
            ? new Date(policy.creationDate).toUTCString()
            : "",
          "Last Modified Date": policy.lastModifyDate
            ? new Date(policy.lastModifyDate).toUTCString()
            : "",
          "Profile Deployment Status":
            policy.state == "OK" ? "Deployed" : "Failed",
          "Profile State": policy.enabled ? "Enabled" : "Disabled",
          Conditions: JSON.stringify(policy.conditions),
        });
      });
      let worksheet = XLSX.utils.json_to_sheet(csvPolicyData);
      XLSX.utils.book_append_sheet(workbook, worksheet, "Alarm Policies");
    }
    if (workbook.SheetNames.length != 0) {
      XLSX.writeFile(workbook, `${fileName}.xlsx`);
    } else this.setState({openNoDataToExportAlert:true});
    this.setState({ isOkbtnClicked: false });
    this.onClose();
  }

  render() {
    let actions = [
      //Cancel
      <Tooltip arrow open={this.state.hoverValue === "Cancel" && this.state.open} onOpen={()=> this.onOpen("Cancel")} onClose={this.onCloseTooltip} title={"Cancel"} placement="top">
      <FlatButton
      className="ActionCancel"
        variant="outlined"
        children={Resource.get("Cancel")}
        color="primary"
        onClick={this.onClose}
      />
      </Tooltip>,
      //Ok
      <Tooltip arrow open={this.state.hoverValue === "Ok" && this.state.open} onOpen={()=> this.onOpen("Ok")} onClose={this.onCloseTooltip} title={"Ok"} placement="top">
      <FlatButton
        className="ActionOk"
        variant="contained"
        children={Resource.get("Ok")}
        color="primary"
        keyboardFocused={true}
        onClick={() => {
          this.setState({ openExportSnackBar: true });
          this.handleExportDetails(this.props, this.state);
        }}
        disabled={
          !(
            this.state.deviceDetail ||
            this.state.interfaceDetails ||
            this.state.groupDetail ||
            this.state.alarmPolicy ||
            this.state.mcsProfile
          )
        }
      />
      </Tooltip>,
    ];
    let content = (
      <>
        <Typography component="p">
          Please select which details need to be exported from the below list.
        </Typography>
        
        {/* <Tooltip arrow open={this.state.hoverValue === "DeviceDetail" && this.state.open} onOpen={()=> this.onOpen("DeviceDetail")} onClose={this.onCloseTooltip} title={"Device Detail"} placement="top"> */}
        <Grid container style={{marginTop:'8px'}}>
          <FormControlLabel fullWidth
            //Device Details
            label={Resource.get("Device Detail")}
            control={
              <Checkbox  style={{marginRight:'4px'}} 
                color="primary"
                onChange={(event) => {
                  this.setState({ deviceDetail: event.target.checked });
                }}
                disabled={
                  this.props.location.pathname.search("computer-systems") >
                    -1 ||
                  this.state.isInterfaceGroup ||
                  (this.props.treeNode &&
                    this.props.treeNode.entityType ==
                      entityTypes.COMPUTER_SYSTEM)
                }
              />
            }
          />
        </Grid>
       
        
        {/* <Tooltip arrow open={this.state.hoverValue === "GroupDetail" && this.state.open} onOpen={()=> this.onOpen("GroupDetail")} onClose={this.onCloseTooltip} title={"Group Detail"} placement="top"> */}
        <Grid container style={{marginTop:'8px'}}>
          <FormControlLabel fullWidth
            //Group Details
            label={Resource.get("Group Detail")}
            control={
              <Checkbox  style={{marginRight:'4px'}}
                color="primary"
                onChange={(event, isChecked) => {
                  this.setState({ groupDetail: isChecked });
                }}
                disabled={
                  this.props.location.pathname.search("computer-systems") >
                    -1 ||
                  this.props.entityType === entityTypes.AUTOMATIC ||
                  this.props.entityType === entityTypes.STATIC ||
                  this.props.entityType === entityTypes.DYNAMIC ||
                  (this.props.treeNode &&
                    this.props.treeNode.entityType ==
                      entityTypes.COMPUTER_SYSTEM)
                }
              />
            }
          /></Grid>
          {/* </Tooltip> */}
       
        
        {/* <Tooltip arrow open={this.state.hoverValue === "InterfceDetail" && this.state.open} onOpen={()=> this.onOpen("InterfceDetail")} onClose={this.onCloseTooltip} title={"Interfce Detail"} placement="top"> */}
        <Grid container style={{marginTop:'8px'}}><FormControlLabel fullWidth
            //Interfcae Details
            label={Resource.get("Interface Detail")}
            control={
              <Checkbox  style={{marginRight:'4px'}}
                color="primary"
                onChange={(event) => {
                  this.setState({ interfaceDetails: event.target.checked });
                }}
                disabled={
                  this.props.location.pathname.search("computer-systems") >
                    -1 ||
                  ((this.props.entityType === entityTypes.AUTOMATIC ||
                    this.props.entityType === entityTypes.STATIC ||
                    this.props.entityType === entityTypes.DYNAMIC) &&
                    !this.state.isInterfaceGroup) ||
                  (this.props.treeNode &&
                    this.props.treeNode.entityType ==
                      entityTypes.COMPUTER_SYSTEM)
                }
              />
            }
          /></Grid>
          {/* </Tooltip> */}
       
        
          {(get(this.state.aclInfo, "roles", "").includes(
            "MCS Read-Only Access"
          ) ||
            this.props.acl.canEditMCSTemplates ||
            this.props.acl.canMonitorConfigService) && (
            // <Tooltip arrow open={this.state.hoverValue === "MCSProfiles" && this.state.open} onOpen={()=> this.onOpen("MCSProfiles")} onClose={this.onCloseTooltip} title={"MCS Profiles"} placement="top">
            <Grid container style={{marginTop:'8px'}}><FormControlLabel fullWidth
              //mcs Details
              label={Resource.get("MCS Profiles")}
              control={
                <Checkbox  style={{marginRight:'4px'}}
                  color="primary"
                  onChange={(event) => {
                    this.setState({ mcsProfile: event.target.checked });
                  }}
                  disabled={
                    this.props.entityType === entityTypes.CONTAINER ||
                    this.state.isInterfaceGroup ||
                    (this.props.treeNode &&
                      this.props.treeNode.entityType === entityTypes.CONTAINER)
                  }
                />
              }
            /></Grid>
            // </Tooltip>
          )}
       
        
          {(this.props.acl.canManagePolicy || this.props.acl.canViewPolicy) && (
            // <Tooltip arrow open={this.state.hoverValue === "AlarmPolicies" && this.state.open} onOpen={()=> this.onOpen("AlarmPolicies")} onClose={this.onCloseTooltip} title={"Alarm Policies"} placement="top">
            <Grid container style={{marginTop:'8px'}}><FormControlLabel fullWidth
              //policy Details
              label={Resource.get("Alarm Policies")}
              control={
                <Checkbox  style={{marginRight:'4px'}}
                  color="primary"
                  onChange={(event) => {
                    this.setState({ alarmPolicy: event.target.checked });
                  }}
                  disabled={
                    this.props.entityType === entityTypes.CONTAINER ||
                    this.state.isInterfaceGroup ||
                    (this.props.treeNode &&
                      this.props.treeNode.entityType === entityTypes.CONTAINER)
                  }
                />
              }
            /></Grid>
            // </Tooltip>
          )}
       
      </>
    );
    let exportGroupDetails = Resource.get("Export Group Details");//Export Group Details
    return (
      <div>
        {/*<Snackbar
          open={this.state.openExportSnackBar}
          message={"Please wait while your report is getting downloaded."}
          onRequestClose={this.onClose}
          
        />*/}
         <CustomSnackbar severity={'info'}
          open={this.state.openExportSnackBar}
          message={"Please wait while your report is getting downloaded."}
          autoHideDuration={4000}
          
          close={()=>{this.setState({openExportSnackBar:false})}}/>
            <CustomSnackbar severity={'info'}
          open={this.state.openNoDataToExportAlert}
          message={"No data to export."}
          autoHideDuration={4000}
          
          close={()=>{this.setState({openNoDataToExportAlert:false})}}/>

        <Dialog
          style={{ maxHeight: "400px !important" }}
          modal={false}
          open={this.props.open}
        
         onClose={this.onClose}
          ref={this.exportDialog}
        >
          
          <DialogTitle component="div" disableTypography> 
           <Typography  component="h1" variant="h4">{exportGroupDetails}</Typography>
          </DialogTitle>
         <div style={{height:'0px'}}>
          <Tooltip arrow open={this.state.hoverValue === "Close" && this.state.open} onOpen={()=> this.onOpen("Close")} onClose={this.onCloseTooltip} title={"Close"} placement="bottom">
          <IconButton style={{top:'-50px',marginRight:'8px',float:'right'}} autoFocus = "true" tabIndex={0} role='button' onClick={this.onClose} >
          <Clear role="figure" />
          </IconButton>
          </Tooltip>
          </div>
       <DialogContent>
          {content}
          
          </DialogContent>    <DialogActions>{actions}</DialogActions>
         
        </Dialog>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    isInterfaceGroup:
      state.tree_view.treeNode.isInterfaceGroup ||
      state.entity.masterElementGroup,
  };
};

export default connect(mapStateToProps, null)(ExportGroupDetailsDialog);
//export default ExportGroupDetailsDialog;
