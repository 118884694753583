import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import connect from "../../utils/connect";
import { setBreadCrumbs } from "./../breadcrumbs/actions";
import "./treeContainer.less";
import Resource from "./Resource";
import TableLayout from "./TableLayout";
import TreeLayout from "./TreeLayout";
import {
  getGroups,
  getStateData,
  getCountData,
} from "./../../api/treeView/actions";
import DashboardContainerRedux from "./../dashboardContainer/DashboardContainerRedux";
import AlarmsRedux from "./../alarms/AlarmsRedux";
import MetricsRedux from "./../metrics/MetricsRedux";
import InterfacesRedux from "./../interfaces/InterfacesRedux";
import { pollGroupsStart } from "../../api/groups/actions";
import MaintenanceRedux from "../maintenance/MaintenanceRedux";
import {
  RightArrow as Forward,
  LeftArrow as Bac,
} from "../ui-components/uim-components";
import ProtectedConfiguration from "./../configuration/ProtectedConfiguration";
import { refreshTree } from "./../../api/treeView/actions";
import { NAV_MENUS } from "../groups/secondaryNav/toggleIcons/ToggleIcons";
import { entityMatchRegex } from "./../../routes/Groups";
import { switchTreeViewNav,disableTreeViewNav } from "./../../api/groups/actions";
import entityTypes from "./../../api/entityTypes";
import {
  setSelectedCsIds,
  requestGroup,
  setGroupTreeFilteredData,
} from "../../api/groups/actions";
import { setTreeNode } from "./../../api/treeView/actions";
import { isEqual } from "lodash";
import computerSystemsApi from "./../../api/computer-systems/computerSystems";
import InterfaceDashboard from "./../interfaces/InterfaceDashboard";
import { SplitView } from "../../utils/SplitView";
import { Tooltip, IconButton } from "@mineral/core";

import Topology from "../icons/Topology";
import { TreeLayoutContainer } from "./TreeLayoutContainer";
import { fetchInterfaces } from "../../api/interfaces/actions";
import { Preloader } from "../maintenance/components/UpdateDevices/components/Preloader";

let GroupAlarmMapper = {};
let DeviceAlarmMapper = {};
let InterfaceAlarmMapper = {};

const RootGroup = {
  id: 0,
  parentId: -1,
  name: "Groups",
  description: null,
  type: "ROOT",
  masterElementGroup: false,
  accountId: null,
  criteria: null,
  nameToken: null,
  descriptionToken: null,
  lastUpdate: null,
  attributes: [],
  state: 1,
  priority: 0,
  computerSystems: [],
  children: [],
};
// Collapsed Tree
const COLLAPSED_TREE = Resource.get("Collapsed Tree");
class TreeContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      childDetails: {},
      groupData: [],
      countData: {},
      refreshTree: true,
      currentProps:props,
      isLoadingContent: false,
      applyBtnClicked: false,
      openSnackbar: false,
      group: {
        id: 0,
        parentId: -1,
        name: "Groups",
        description: null,
        type: "ROOT",
        masterElementGroup: false,
        accountId: null,
        criteria: null,
        nameToken: null,
        descriptionToken: null,
        lastUpdate: null,
        attributes: [],
        state: 1,
        priority: 0,
        computerSystems: [],
        children: [],
      },
      gridData: [],
      showTable: true,
      showCabi: false,
      cabiData: {},
      openTree: true,
      currentTreeViewNav: props.currentTreeViewNav,
      showTreeViewNav: props.currentTreeViewNav != undefined,
      groupId: undefined,
      csId: undefined,
      masterId: undefined,
      isInterfacGroup: false,
      reloadDetailView: false,
      groupCount: -1,
      savedColumns: [],
      interfaceDetailView: false,
      selInterfaceData: {},
    };
  }

  groupApiExecution(groupProps, isRefresh) {
    const level = 0; // hard coded as zero to bring level 0 data
    groupProps.fetchGroups(level).then(() => {
      if (
        sessionStorage.getItem("devicesAdded") ||
        sessionStorage.getItem("deviceRemoved")
      ) {
        let groupSelected = this.state.selectedGroup;
        if (
          sessionStorage.getItem("devicesAdded") &&
          !this.state.selectedGroup
        ) {
          groupSelected = JSON.parse(sessionStorage.getItem("devicesAddData"));
        }
        this.setTreeFromTable(
          [groupSelected.id],
          {},
          false,
          {},
          {
            ...groupSelected,
            isLastNode: this.state.isLeaf,
          },
          true
        );
      }
      if (sessionStorage.getItem("groupDeleted")) {
        this.setTreeFromTable(["0"], {}, false, {}, RootGroup, true);
        this.expandCsParent(null, 0, true);
        this.props.getGroup(0);
        this.props.updateTreeNode({
          entityType: entityTypes.ROOT,
          isInterfaceGroup: false,
          element_id: 0,
        });
      }
      sessionStorage.removeItem("devicesAdded");
      sessionStorage.removeItem("devicesAddData");
      sessionStorage.removeItem("deviceRemoved");
      sessionStorage.removeItem("groupDeleted");
    });
    groupProps.fetchStates(level);
    groupProps.fetchCount(level);
  }

  componentWillMount() {
    this.props.switchTreeViewNav("DETAIL_VIEW");
    this.setState({ currentTreeViewNav: "DETAIL_VIEW", showTreeViewNav: true });
    computerSystemsApi.getSavedColumns().then((response) => {
      let columns = response.data.columns;
      this.setState({
        savedColumns: columns && columns.length > 0 ? columns : [],
      });
    });
  }

  componentDidMount() {
    this.groupApiExecution(this.props);
    this.props.updateTreeNode({
      entityType: entityTypes.ROOT,
      isInterfaceGroup: false,
      element_id: 0,
    });
  }

  componentDidUpdate(prevProps, prevState){
    if(!isEqual(prevState, this.state)){
      if(this.state.applyBtnClicked){
        this.setState({openSnackbar: false})
        this.props
          .interfacesDataOnAppy({
            csId: this.props.selectedId,
            isGroup: true,
          })
          .then((result) => {
            console.log("result data", result.data);
            this.setState({ applyBtnClicked: false, openSnackbar: true });
          });
      }
    }
  }

  componentWillReceiveProps = (nextProps) => {
    if (!isEqual(nextProps, this.state.currentProps)) {
      this.setState({currentProps:nextProps})
      if (
        sessionStorage.getItem("renamedGroup") ||
        (nextProps.refreshTree && nextProps.refreshTree === true)
      ) {
        let newProps=nextProps!=null?Object.assign({},nextProps):null;
        nextProps.refreshTree=false; //May way for next refresh
        this.setState({currentProps:newProps})
        this.groupApiExecution(nextProps, false);
        this.props.refreshGroupTree();
        sessionStorage.getItem("renamedGroup") &&
          this.state.currentTreeViewNav == NAV_MENUS.MCS &&
          this.props.switchTreeViewNav(NAV_MENUS.DETAIL_VIEW);
        sessionStorage.removeItem("renamedGroup");
      }
      if(nextProps.treeNode.entityType===entityTypes.INTERFACES)
      {
       this.props.disableTreeViewNav([NAV_MENUS.INTERFACES,NAV_MENUS.MCS])
      }
      else 
       if((nextProps.treeNode.entityType===entityTypes.STATIC || nextProps.treeNode.entityType===entityTypes.DYNAMIC) && (nextProps.treeNode.isInterfaceGroup===true))
{
  this.props.disableTreeViewNav([NAV_MENUS.METRICS])
}else
      {
        this.props.disableTreeViewNav([]);
      }
      let rootSeverity = 0;
      nextProps.childDetails.Groups &&
        nextProps.childDetails.Groups.map((grp) => {
          GroupAlarmMapper["id" + grp.grpId + "parentId" + grp.parentId] =
            grp.state;
          rootSeverity = rootSeverity < grp.state ? grp.state : rootSeverity;
          return true;
        });
      let topNodes =
        nextProps.groupData &&
        nextProps.groupData.length &&
        nextProps.groupData[0].children &&
        nextProps.groupData[0].children.length &&
        nextProps.groupData[0].children.map((grp) => ({
          id: grp.id,
          parentId: grp.parentId,
        }));
      if (
        nextProps.groupData &&
        nextProps.groupData.length &&
        nextProps.groupData[0].children
      ) {
        this.setState({
          groupCount: nextProps.groupData[0].children.length,
        });
      }
      let topAlarmStates =
        topNodes && topNodes.length
          ? topNodes.map((grp) =>
              GroupAlarmMapper.hasOwnProperty(
                "id" + grp.id + "parentId" + grp.parentId
              )
                ? GroupAlarmMapper["id" + grp.id + "parentId" + grp.parentId]
                : 0
            )
          : 0;
      GroupAlarmMapper[0] = rootSeverity;
      DeviceAlarmMapper = {};
      nextProps.childDetails.Devices &&
        nextProps.childDetails.Devices.map(
          (dvc) => (DeviceAlarmMapper[dvc.devId] = dvc.state)
        );
      nextProps.childDetails.Interface &&
        nextProps.childDetails.Interface.map(
          (master) => (InterfaceAlarmMapper[master.interfaceId] = master.state)
        );

      if (nextProps.groupData && nextProps.groupData.length) {
        this.setState({
          group: Object.assign({}, nextProps.groupData[0], {
            state: topAlarmStates
              ? Math.max(...topAlarmStates)
              : nextProps.groupData[0].state,
            "computer-systems": this.state.group.computerSystem,
          }),
          countData: nextProps.countData,
          refreshGroupTree: nextProps.refreshTree,
          currentTreeViewNav: nextProps.currentTreeViewNav,
          showTreeViewNav: nextProps.currentTreeViewNav != undefined,
        });
      }
    }
    this.idProps(nextProps);
  };

  handleContentLoading = (e)=>{
    this.setState({isLoadingContent:e});
  }

  breadcrumbSetter = (selectedGroup, parentIds) => {
    let routeArr = [];
    if (parentIds && parentIds.size) {
      for (let [key, value] of parentIds) {
        routeArr.push({
          name: value,
          link: `/groups/0/${key.replace(/id/g, "")}/treeviewnew`,
        });
      }
    }
    this.props.setBreadCrumbs([
      {
        //Groups
        name: Resource.get("Groups"),
        link: "/groups/0",
      },
      ...routeArr,
      {
        name:
          selectedGroup.type != "COMPUTER_SYSTEM"
            ? selectedGroup.name
            : selectedGroup.attributes.DisplayName[0],
        link: `/groups/0/${selectedGroup.id}/treeviewnew`,
      },
    ]);
  };

  collapseTreeView = () => {
    this.setState({
      openTree: !this.state.openTree,
    });
  };

  enterPressed(event) {
    var code = event.keyCode || event.which;
    if (code === 13) {
      this.setState({
        openTree: !this.state.openTree,
      });
    }
  }

  handleApplyBtnClicked =() => {
    this.setState({applyBtnClicked: true})
  }

  handleCloseSnackbar = () => {
    this.setState({openSnackbar: false})
  }

  openCabiView = (csData) => {
    this.breadcrumbSetter(csData);
    this.setState({
      showTable: false,
      showCabi: true,
      showTreeViewNav: false,
      cabiData: { ...csData },
    });
    let entityType = null;
    let isGroup = true;
    if (csData && csData.type) {
      switch (csData.type) {
        case "CONTAINER":
          entityType = entityTypes.CONTAINER;
          break;
        case "ROOT":
          entityType = entityTypes.ROOT;
          break;
        case "DYNAMIC":
          entityType = entityTypes.DYNAMIC;
          break;
        case "STATIC":
          entityType = entityTypes.STATIC;
          break;
        case "AUTOMATIC":
          entityType = entityTypes.AUTOMATIC;
          break;
        case "NetworkInterface":
          isGroup = false;
          entityType = entityTypes.INTERFACES;
          break;
        case "COMPUTER_SYSTEM":
          isGroup = false;
          entityType = entityTypes.COMPUTER_SYSTEM;
          break;
        default:
          entityType = entityTypes.CONTAINER;
          break;
      }
    }
    let parentType = "EMPTY";
    if (
      entityType === entityTypes.CONTAINER &&
      csData &&
      csData.children &&
      csData.children.length > 0
    ) {
      let childrenType = csData.children[0].type;
      if (childrenType === "AUTOMATIC") {
        parentType = "AUTOMATIC";
      } else if (
        childrenType === "DYNAMIC" ||
        childrenType === "STATIC" ||
        childrenType === "CONTAINER"
      ) {
        parentType = "NON_REMOVABLE";
      }
    }
    if (csData) {
      this.props.updateTreeNode({
        entityType: entityType,
        parentType: parentType,
        isInterfaceGroup: csData.masterElementGroup,
        element_id: csData.id,
      });
      if (isGroup) {
        this.props.getGroup(csData.id);
      }
      if (
        csData &&
        (csData.hasOwnProperty("groupId") || csData.hasOwnProperty("parentId"))
      ) {
        this.expandCsParent(
          csData.hasOwnProperty("groupId") ? csData.groupId : csData.parentId,
          csData.id
        );
      }
    }
  };
  openDetailInterfaceFromTreeView = (rowData) => {
    this.props.switchTreeViewNav(NAV_MENUS.DETAIL_VIEW);
    this.setState({
      selInterfaceData: rowData,
      interfaceDetailView: true,
    });
  };

  openAlarmViewForInterface = (intData) => {
    this.breadcrumbSetter(intData);
    this.props.switchTreeViewNav(NAV_MENUS.ALARMS);
    this.setState({
      selectedGroup: intData,
      selInterfaceData: intData,
      // gridData: {},
      // isLeaf,
    });
    // let entityType = null;
    // let isGroup = false;
    let entityType = entityTypes.INTERFACES;

    let parentType = "EMPTY";

    if (intData) {
      this.props.updateTreeNode({
        entityType: entityType,
        parentType: parentType,
        isInterfaceGroup: intData.masterElementGroup,
        element_id: intData.masterId,
      });
      // if (isGroup) {
      //   this.props.getGroup(intData.masterId);
      // }
      if (intData && intData.hasOwnProperty("parent_instanceId")) {
        this.expandCsParent(
          intData.parent_instanceId.toString(),
          intData.masterId
        );
      }
    }
  };

  setGridData = (selectedNode, selectedGroup, isLeaf, parentIds, isEdit) => {
    if (
      selectedGroup.typeName == "NetworkInterface" ||
      selectedGroup.type == "NetworkInterface" || selectedGroup.type==null
    ) {
      this.props.setCsId([]);
      //this.props.switchTreeViewNav(NAV_MENUS.ALARMS);
      // this.props.switchTreeViewNav(NAV_MENUS.DETAIL_VIEW);
      this.setState({
        gridData: selectedNode,
        selectedGroup,
        isLeaf,
        selInterfaceData: selectedGroup,
        interfaceDetailView: true,
        isInterfacGroup: false,
        isInterface:true,
      });
      //TODO Kolas01 I feel this is not needed for interface as the it has interface related details in selectedGroup..       
      this.props.pollGroupsStart(selectedGroup.id);
    } else if (selectedGroup.type != "COMPUTER_SYSTEM") {
      this.props.pollGroupsStart(selectedGroup.id);
      this.props.setCsId([]);
      (selectedGroup.type == "CONTAINER" || selectedGroup.masterElementGroup) &&
        this.state.currentTreeViewNav == NAV_MENUS.MCS &&
        this.props.switchTreeViewNav(NAV_MENUS.DETAIL_VIEW);
      this.setState({
        gridData: selectedNode,
        selectedGroup,
        isInterfacGroup: selectedGroup.masterElementGroup,
        showTable: true,
        showCabi: false,
        showTreeViewNav: false,
        isLeaf,
        isCalledFromTree: true,
        isEdit,
        interfaceDetailView: false,
        isInterface:false,
      });
      this.props.pollGroupsStart(selectedGroup.id);
    } else {
      this.props.setCsId([selectedGroup.id]);
      this.setState(
        {
          showTable: false,
          showCabi: true,
          showTreeViewNav: false,
          cabiData: { ...selectedGroup },
          isInterface:false,
          interfaceDetailView:false
        },
        () => {
          this.props.setCsId([selectedGroup.id]);
        }
      );
    }
    this.breadcrumbSetter(selectedGroup, parentIds);
    this.idProps(this.props);
  };

  handleAlarmSeverityView = (alarmSeverity) => {
    this.props.switchTreeViewNav(NAV_MENUS.ALARMS);
    this.setState({
      alarmSeverity: alarmSeverity,
    });
  };
  render() {
    let showDetailView = true;
    let showAlarmView = false;
    let showMaintenanceView = false;
    let showMetricView = false;
    let showInterfacesView = false;
    let showMcsView = false;
    let showCabiView = false;
    if (this.state.showTreeViewNav) {
      switch (this.state.currentTreeViewNav) {
        case NAV_MENUS.DETAIL_VIEW:
          showDetailView = true;
          showAlarmView = false;
          showMaintenanceView = false;
          showMetricView = false;
          showInterfacesView = false;
          showMcsView = false;
          showCabiView = false;
          break;
        case NAV_MENUS.ALARMS:
          showAlarmView = true;
          showDetailView = false;
          break;
        case NAV_MENUS.MAINTENANCE:
          showMaintenanceView = true;
          showDetailView = false;
          break;
        case NAV_MENUS.METRICS:
          showMetricView = true;
          showDetailView = false;
          break;
        case NAV_MENUS.INTERFACES:
          showInterfacesView = true;
          showDetailView = false;
          break;
        case NAV_MENUS.MCS:
          showMcsView = true;
          showDetailView = false;
          break;
        case NAV_MENUS.CABI:
          showCabiView = true;
          showDetailView = false;
      }
    }

    let view = (
      <div className="tree-container" style={{ height: "calc(100vh - 136px)" }}>
        <SplitView
          openTree={this.state.openTree}
          left={
            <TreeLayoutContainer
              GroupAlarmMapper={GroupAlarmMapper}
              DeviceAlarmMapper={DeviceAlarmMapper}
              InterfaceAlarmMapper={InterfaceAlarmMapper}
              groupData={this.state.group}
              countData={this.state.countData}
              gridSetter={this.setGridData}
              selectNodeFromTable={(method) => (this.setTreeFromTable = method)}
              expandParent={(method) => (this.expandCsParent = method)}
              openTree={this.state.openTree}
              collapseTreeView={this.collapseTreeView}
              acl={this.props.acl}
              updateLoadingContent = {(e)=>this.handleContentLoading(e)}
            />
          }
          right={
            <>
            {this.props.secondaryNavComponent}
              {!this.state.openTree && (
                <div className="halfCircleGroups">
                  <Tooltip title="Expand Tree">
                    <IconButton
                      autoFocus
                      aria-label="Expand Tree"
                      onClick={this.collapseTreeView}
                    >
                      <Topology />
                    </IconButton>
                  </Tooltip>
                </div>
              )}
              {showDetailView &&
              !this.state.isInterfacGroup && !this.state.isInterface && 
              !this.state.showCabi ?
                !this.state.isLoadingContent ? <TableLayout
                  selectedNode={this.state.gridData}
                  selectedGroup={this.state.selectedGroup}
                  isCalledFromTree={this.state.isCalledFromTree}
                  {...this.props}
                  setTreeData={this.setTreeFromTable}
                  openCabiView={this.openCabiView}
                  showTable={this.state.showTable && showDetailView}
                  cabiData={this.state.cabiData}
                  reloadDetailView={this.state.reloadDetailView}
                  groupCount={this.state.groupCount}
                  isTreeOpen={this.state.openTree}
                  savedColumns={this.state.savedColumns}
                  fromTreeView={true}
                  setGroupTreeFilteredData={this.props.setGroupTreeFilteredData}
                />:
                <div style={{height:'80%',position:'absolute',left:'50%',top:"50%",transform:'translate(-50%,-50%)'}}>
                  <Preloader/>
                </div>
                : null}
              {this.state.showCabi && showDetailView && !this.state.interfaceDetailView? (
                <div
                  style={{
                    width: "100%",
                    marginLeft: "4px",
                    height: "95%",
                    backgroundColor: "white",
                  }}
                >
                  <DashboardContainerRedux
                    className="cabi__main--treeview"
                    csData={this.state.cabiData}
                    entityType={this.props.entityType}
                    csId={this.state.cabiData.id}
                    referer={"treeView"}
                    isInterface={
                      this.props.treeNode.entityType ===
                        entityTypes.INTERFACES ||
                      this.props.treeNode.entityType ===
                        entityTypes.NETWORK_INTERFACE
                    }
                    isTreeOpen={this.state.openTree}
                    {...this.props}
                  />
                </div>
              ) : null}

              {showAlarmView ? (
                <div
                  style={{
                    width: `${this.state.openTree ? "100%" : "100%"}`,
                    marginLeft: `${this.state.openTree ? "0px" : "0px"}`,
                    height: "calc(100% - 40px)",
                    overflow: "auto",
                  }}
                >
                  <AlarmsRedux
                    groupId={this.state.groupId}
                    csId={this.state.csId}
                    masterId={this.state.masterId}
                    alarmSeverity={this.state.alarmSeverity}
                    isFromOperatorConsole={true}
                  />
                </div>
              ) : null}
              {showCabiView && !showDetailView && !this.state.interfaceDetailView? (
                <div
                  style={{
                    width: `${this.state.openTree ? "100%" : "100%"}`,
                    marginLeft: "4px",
                    height: "calc(100% - 40px)",
                    backgroundColor: "white",
                    overflow: "auto",
                  }}
                >
                  <DashboardContainerRedux
                    className="cabi__main--treeview"
                    groupId={this.state.groupId}
                    referer={"treeView"}
                    entityType={this.props.entityType}
                    csId={this.state.csId}
                    onAlarmSeverityViewClick={this.handleAlarmSeverityView}
                    isInterface={
                      this.props.treeNode.entityType ===
                        entityTypes.INTERFACES ||
                      this.props.treeNode.entityType ===
                        entityTypes.NETWORK_INTERFACE
                    }
                    isTreeOpen={this.state.openTree}
                  />
                </div>
              ) : null}
              {showMaintenanceView ? (
                <div
                  style={{
                    height: "calc(100% - 48px)",
                    width: `${this.state.openTree ? "100%" : "98%"}`,
                  }}
                >
                  <MaintenanceRedux
                    openTree={this.state.openTree}
                    // tableWidth={`${this.state.openTree ? "76vw" : "94vw"}`}
                    fromTreeView={true}
                  />
                </div>
              ) : null}
              {showMetricView ? (
                <div style={{ width: "100%",  height: "calc(100% - 48px)",}}>
                  <MetricsRedux
                    fromGroup={this.state.isInterface?false:true}
                    isFromTreeView={true}
                    isTreeOpen={this.state.openTree}
                    groupId={this.state.groupId ? this.state.groupId : 0}
                    isInterface={this.state.isInterface}
                    interfaceDisplayName={this.state.isInterface?(this.state.selInterfaceData?.instanceName==null?this.state.selInterfaceData?.displayName:this.state.selInterfaceData?.instanceName):null}
                   
                    masterId={this.state.isInterface?this.props.treeNode.element_id :null}
                  
                  
                    csId={
                      this.state.isInterface 
                        ?this.state.selInterfaceData?.csId
                        : this.state.csId
                    }
                  />
                </div>
              ) : null}
              {showInterfacesView ? (
                <div style={{ width: "100%", height: "calc(100% - 48px)", }}>
                  <InterfacesRedux
                    groupId={this.state.groupId}
                    csId={this.state.csId}
                    className="interface__main--treeview"
                    fromTreeView={true}
                    openAlarmViewForInterface={this.openAlarmViewForInterface}
                    openDetailInterfaceFromTreeView={
                      this.openDetailInterfaceFromTreeView
                    }
                    groupData={this.state.selectedGroup}
                    isEdit={this.state.isEdit}
                    isTreeOpen={this.state.openTree}
                    {...this.props}
                  />
                </div>
              ) : null}
              {showInterfacesView &&
              this.state.interfaceDetailView &&
              this.state.isInterfacGroup ? 
              !this.state.isLoadingContent ?
                <div
                  style={{ width: "100%", marginLeft: "4px",  height: "calc(100% - 48px)", }}
                >
                  <InterfaceDashboard
                    data={this.state.selInterfaceData}
                    fromTreeView={true}
                    isTreeOpen={this.state.openTree}
                    isInterface={true}
                    canEditDevice={this.props.acl.canEditDevice}
                    applyBtnClicked={this.state.applyBtnClicked}
                    handleApplyBtnClicked={this.handleApplyBtnClicked}
                    handleCloseSnackbar={this.handleCloseSnackbar}
                    openSnackbar={this.state.openSnackbar}u
                  />
                </div>
                :
                <div style={{height:'80%',position:'absolute',left:'50%',top:"50%",transform:'translate(-50%,-50%)'}}>
                  <Preloader/>
                </div>
                : null}
              {!this.state.interfaceDetailView &&
              showDetailView &&
              this.state.isInterfacGroup &&
              !showAlarmView ? 
                !this.state.isLoadingContent ?
                <div style={{ width: "100%",  height: "calc(100% - 48px)", }}>
                  <InterfacesRedux
                    groupId={this.state.groupId}
                    csId={this.state.csId}
                    className="interface__main--treeview"
                    fromTreeView={true}
                    openAlarmViewForInterface={this.openAlarmViewForInterface}
                    openDetailInterfaceFromTreeView={
                      this.openDetailInterfaceFromTreeView
                    }
                    groupData={this.state.selectedGroup}
                    isEdit={this.state.isEdit}
                    isTreeOpen={this.state.openTree}
                    {...this.props}
                  />
                </div>
                : 
                <div style={{height:'80%',position:'absolute',left:'50%',top:"50%",transform:'translate(-50%,-50%)'}}>
                  <Preloader/>
                </div>
                : null}
              {this.state.interfaceDetailView &&
              showDetailView &&
              !this.state.isInterfacGroup &&
              !showAlarmView ? (
                <div
                  style={{ width: "100%", marginLeft: "4px", height: "calc(100% - 48px)", }}
                >
                  <InterfaceDashboard
                    data={this.state.selInterfaceData}
                    fromTreeView={true}
                    isTreeOpen={this.state.openTree}
                    isInterface={true}
                    canEditDevice={this.props.acl.canEditDevice}
                    applyBtnClicked={this.state.applyBtnClicked}
                    handleApplyBtnClicked={this.handleApplyBtnClicked}
                    handleCloseSnackbar={this.handleCloseSnackbar}
                    openSnackbar={this.state.openSnackbar}
                  />
                </div>
              ) : null}

{/* {showMetricView && showInterfacesView && this.state.selInterfaceData.length !== 0 ? (
                <div style={{ width: "100%", height: "100%" }}>
                  <MetricsRedux
                    fromGroup={true}
                    isTreeOpen={this.state.openTree}
                    groupId={this.state.groupId ? this.state.groupId : 0}
                    csId={this.state.selInterfaceData.masterId}
                  />
                </div>
              ) : null} */}

              {showMcsView ? (
                <div
                  style={{ width: "calc(100%)", marginLeft: "4px",  height: "calc(100% - 48px)",}}
                >
                  <ProtectedConfiguration fromTreeView={true}
                    groupId={this.state.groupId}
                    csId={this.state.csId}
                    entityMatchRegex={`${entityMatchRegex}`}
                  />
                </div>
              ) : null}
            </>
          }
        />
      </div>
    );

    return view;
  }

  idProps = (props) => {
    const groupId =
      props.treeNode.entityType !== entityTypes.COMPUTER_SYSTEM &&
      props.treeNode.entityType !== entityTypes.INTERFACES
        ? props.treeNode.element_id
        : null;
    const csId =
      props.treeNode.entityType === entityTypes.COMPUTER_SYSTEM
        ? props.treeNode.element_id
        : null;
    const masterId =
      props.treeNode.entityType === entityTypes.INTERFACES ||
      props.treeNode.entityType === entityTypes.NETWORK_INTERFACE
        ? props.treeNode.element_id
        : null;
    if (this.state.groupId !== groupId) {
      this.setState({ groupId });
    }
    if (this.state.csId !== csId) {
      this.setState({ csId });
    }
    if (this.state.masterId !== masterId) {
      this.setState({ masterId });
    }
    // this.setState({
    //   groupId,
    //   csId,
    //   masterId,
    // });
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    setBreadCrumbs: (items) => dispatch(setBreadCrumbs(items)),
    fetchGroups: (level, isPoller) => dispatch(getGroups(level, isPoller)),
    fetchStates: (level, isPoller) => dispatch(getStateData(level, isPoller)),
    fetchCount: (level, isPoller) => dispatch(getCountData(level, isPoller)),
    pollGroupsStart: (id) => dispatch(pollGroupsStart(id, true)),
    refreshGroupTree: () => dispatch(refreshTree(false)),
    switchTreeViewNav: (menu) => dispatch(switchTreeViewNav(menu)),
    disableTreeViewNav: (menus) => dispatch(disableTreeViewNav(menus)),
    interfacesDataOnAppy: (params) => dispatch(fetchInterfaces(params)),
   
    setCsId: (id) => dispatch(setSelectedCsIds(id)),
    updateTreeNode: (selectedNode) => {
      return dispatch(setTreeNode(selectedNode));
    },
    getGroup: (id) => dispatch(requestGroup({ id: id })),
    setGroupTreeFilteredData: (rowData) =>
      dispatch(setGroupTreeFilteredData(rowData)),
  };
};

const mapStateToProps = (state) => {
  return {
    sid: state.sid,
    childDetails: state.tree_view.stateData,
    groupData: state.tree_view.groups,
    countData: state.tree_view.countData,
    refreshTree: state.tree_view.refreshTree,
    treeNode: Object.assign({}, state.tree_view.treeNode),
    filteredGroupTreeData: state.filteredGroupTreeData,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TreeContainer));
