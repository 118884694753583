import {
  FETCH_MAINTENANCE,
  FETCH_MAINTENANCE_SUCCESS,
  FETCH_MAINTENANCE_FAILURE,
  POLL_MAINTENANCE_START,
  POLL_MAINTENANCE_STOP,
  FETCH_SCHEDULE_DEVICES,
  FETCH_SCHEDULE_DEVICES_SUCCESS,
  FETCH_SCHEDULE_DEVICES_FAILURE,
  FETCH_GROUP_DEVICES,
  FETCH_GROUP_DEVICES_SUCCESS,
  FETCH_GROUP_DEVICES_FAILURE,
  FETCH_TIMEZONES_SUCCESS,
  FETCH_TIMEZONES_FAILURE,
  FETCH_CS_MAINTENANCE_LIST,
  FETCH_CS_MAINTENANCE_LIST_SUCCESS,
  FETCH_CS_MAINTENANCE_LIST_FAILURE,
  REMOVE_CS_MAINTENANCE_LIST,
  REMOVE_CS_MAINTENANCE_LIST_SUCCESS,
  REMOVE_CS_MAINTENANCE_LIST_FAILURE
} from './actionTypes'
import maintenance from './maintenance'
import tree_view from '../treeView/treeView'


export function getMaintenance() {
  return {
    type: FETCH_MAINTENANCE,
  }
}

export function fetchMaintenanceSuccess(json) {
  return {
    type: FETCH_MAINTENANCE_SUCCESS,
    maintenance: json
  }
}

export function fetchMaintenanceFailure(error) {
  return {
    type: FETCH_MAINTENANCE_FAILURE,
    errorMessage: error.message
  }
}

export function pollMaintenanceStart() {
  return {
    type:POLL_MAINTENANCE_START
  }
}

export function pollMaintenanceStop() {
  return {
    type:POLL_MAINTENANCE_STOP
  }
}

export function fetchScheduleDevices(){
  return{
    type:FETCH_SCHEDULE_DEVICES
  }
}

export function getDevicesBySchedule(scheduleId){
  return dispatch => {
    dispatch(fetchScheduleDevices())
    return maintenance.getDevicesFromSchedule(scheduleId)
      .then(response =>  dispatch(fetchScheduleDevicesSuccess(response.data)))
      .catch(function(error) {
        dispatch(fetchScheduleDevicesFailure(error))
      })
  }
}

export function fetchScheduleDevicesSuccess(json) {
  return {
    type: FETCH_SCHEDULE_DEVICES_SUCCESS,
    devices: json
  }
}

export function fetchScheduleDevicesFailure(error) {
  return {
    type: FETCH_SCHEDULE_DEVICES_FAILURE,
    errorMessage: error.message
  }
}

export function fetchGroupDevices(){
  return{
    type:FETCH_GROUP_DEVICES
  }
}

export function getDevicesByGroup(groupId){
  return dispatch => {
    dispatch(fetchGroupDevices())
    return tree_view.getDeviceData(groupId)
      .then(response =>  dispatch(fetchGroupDevicesSuccess(response.data)))
      .catch(function(error) {
        dispatch(fetchGroupDevicesFailure(error))
      })
  }
}
export function fetchSchedulesForDevice(){
  return{
    type:FETCH_CS_MAINTENANCE_LIST
  }
}
export function getScheduleseByDevice(csId){
  return dispatch => {
    dispatch(fetchSchedulesForDevice())
    return maintenance.getScheduleForDevices(csId)
      .then(response =>  dispatch(fetchGroupDevicesSuccess(response.data)))
      .catch(function(error) {
        dispatch(fetchGroupDevicesFailure(error))
      })
  }
}

export function fetchDevicesSchedulesSuccess(json) {
  return {
    type: FETCH_CS_MAINTENANCE_LIST_SUCCESS,
    devices: json
  }
}

export function fetchDevicesSchedulesFailure(error) {
  return {
    type: FETCH_CS_MAINTENANCE_LIST_FAILURE,
    errorMessage: error.message
  }
}

export function fetchGroupDevicesSuccess(json) {
  return {
    type: FETCH_GROUP_DEVICES_SUCCESS,
    devices: json
  }
}

export function fetchGroupDevicesFailure(error) {
  return {
    type: FETCH_GROUP_DEVICES_FAILURE,
    errorMessage: error.message
  }
}

export function fetchTimeZoneSuccess(json) {
  return {
    type: FETCH_TIMEZONES_SUCCESS,
    timezones: json
  }
}

export function fetchTimeZoneFailure(error) {
  return {
    type: FETCH_TIMEZONES_FAILURE,
    errorMessage: error.message
  }
}
export function getTimeZones(){
  return dispatch => {
    dispatch(fetchScheduleDevices())
    return maintenance.getTimeZones()
      .then(response =>  dispatch(fetchTimeZoneSuccess(response.data)))
      .catch(function(error) {
        dispatch(fetchTimeZoneFailure(error))
      })
  }
}
