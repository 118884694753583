import React from 'react'
import {
  InfoIcon,
} from '../../ui-components/uim-components'
import DeployErrorMessage from './../deployedMessage/DeployErrorMessage'
import './deployError.less'

const DeployError = (props) => {
  return (
    <div className='deploy__error'>
      <InfoIcon />
      <DeployErrorMessage helperText={props.helperText} />
    </div>
  )
}

export default DeployError