import {
  FETCH_DEVICES,
  FETCH_DEVICES_SUCCESS,
  FETCH_DEVICES_FAILURE,
  FETCH_SAVED_METRICS,
  FETCH_SAVED_METRICS_SUCCESS,
  FETCH_SAVED_METRICS_FAILURE,
  FETCH_DEVICE_METRICS,
  FETCH_DEVICE_METRICS_SUCCESS,
  FETCH_DEVICE_METRICS_FAILURE,
  FETCH_GOLDEN_METRICS,
  FETCH_GOLDEN_METRICS_SUCCESS,
  FETCH_GOLDEN_METRICS_FAILURE,
  UPDATE_ALIAS,
  UPDATE_ALIAS_SUCCESS,
  UPDATE_ALIAS_FAILURE,
  FETCH_DEVICE_DATA,
  FETCH_DEVICE_DATA_SUCCESS,
  FETCH_DEVICE_DATA_FAILURE,
} from "./actionTypes";

const devices = (
  state = {
    isFetching: false,
    didInvalidate: false,
    items: [],
    isFetchingAll: false,
    didInvalidateFetchAll: false,
    errorInFetchingAll: "",
    savedMetrics: { MyViews: [], SharedViews: [] },
    isFetchingDevice: false,
    didInvalidateDevice: false,
    metricsForDevices: [],
    errorDevice: "",
    deviceIds: "",
    selectedMetrics: [],
    hours: "",
    endDate: null,
    selectedCIs: {},
    isInterface: false,
    isFetchingGolden: false,
    didInvalidateFetchGolden: false,
    errorInFetchingGolden: "",
    goldenMetrics: {},
    isUpdatingAlias: false,
    didInvalidateUpdateAlias: false,
    errorInUpdateAlias: "",
    updateAliasMsg: "",
    isFetchingDvc: false,
    didInvalidateDvc: false,
    errorMessageDvc: "",
    dvcInfo: {},
  },
  action
) => {
  switch (action.type) {
    case FETCH_DEVICES:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false,
      });
    case FETCH_DEVICES_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        items: action.devices._items,
      });
    case FETCH_DEVICES_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        errorMessage: action.errorMessage,
        items: [],
      });
    case FETCH_SAVED_METRICS:
      return Object.assign({}, state, {
        isFetchingAll: true,
        didInvalidateFetchAll: false,
      });
    case FETCH_SAVED_METRICS_SUCCESS:
      return Object.assign({}, state, {
        isFetchingAll: false,
        didInvalidateFetchAll: false,
        savedMetrics: action.savedMetrics,
      });
    case FETCH_SAVED_METRICS_FAILURE:
      return Object.assign({}, state, {
        isFetchingAll: false,
        didInvalidateFetchAll: true,
        errorInFetchingAll: action.errorMessage,
      });
    case FETCH_DEVICE_METRICS:
      return Object.assign({}, state, {
        isFetchingDevice: true,
        didInvalidateDevice: false,
      });
    case FETCH_DEVICE_METRICS_SUCCESS:
      return Object.assign({}, state, {
        isFetchingDevice: false,
        didInvalidateDevice: false,
        metricsForDevices: action.metricData,
        deviceIds: action.deviceIds,
        selectedMetrics: action.metricTypes,
        hours: action.hours,
        selectedCIs: action.selectedCIs,
        isInterface: action.isInterface,
        endDate: action.endDate,
      });
    case FETCH_DEVICE_METRICS_FAILURE:
      return Object.assign({}, state, {
        isFetchingDevice: false,
        didInvalidateDevice: true,
        errorDevice: action.errorMessage,
        deviceIds: action.deviceIds,
        selectedMetrics: action.metricTypes,
        hours: action.hours,
        selectedCIs: action.selectedCIs,
        isInterface: action.isInterface,
        endDate: action.endDate,
      });
    case FETCH_GOLDEN_METRICS:
      return Object.assign({}, state, {
        isFetchingGolden: true,
        didInvalidateFetchGolden: false,
      });
    case FETCH_GOLDEN_METRICS_SUCCESS:
      return Object.assign({}, state, {
        isFetchingGolden: false,
        didInvalidateFetchGolden: false,
        goldenMetrics: action.goldenMetrics,
      });
    case FETCH_GOLDEN_METRICS_FAILURE:
      return Object.assign({}, state, {
        isFetchingGolden: false,
        didInvalidateFetchGolden: true,
        errorInFetchingGolden: action.errorMessage,
      });
    case UPDATE_ALIAS:
      return Object.assign({}, state, {
        isUpdatingAlias: true,
        didInvalidateUpdateAlias: false,
      });
    case UPDATE_ALIAS_SUCCESS:
      return Object.assign({}, state, {
        isUpdatingAlias: false,
        didInvalidateUpdateAlias: false,
        updateAliasMsg: action.successMsg,
      });
    case UPDATE_ALIAS_FAILURE:
      return Object.assign({}, state, {
        isUpdatingAlias: false,
        didInvalidateUpdateAlias: true,
        errorInUpdateAlias: action.errorMessage,
      });
    case FETCH_DEVICE_DATA:
      return Object.assign({}, state, {
        isFetchingDvc: true,
        didInvalidateDvc: false,
      });
    case FETCH_DEVICE_DATA_SUCCESS:
      return Object.assign({}, state, {
        isFetchingDvc: false,
        didInvalidateDvc: false,
        dvcInfo: action.dvcInfo,
      });
    case FETCH_DEVICE_DATA_FAILURE:
      return Object.assign({}, state, {
        isFetchingDvc: false,
        didInvalidateDvc: true,
        errorMessageDvc: action.errorMessage,
      });
    default:
      return state;
  }
};

export default devices;
