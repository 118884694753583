import React, { Component } from "react";
import { TextField, ListItem, Avatar, Checkbox,FormControlLabel,FormLabel,InputAdornment,FormControl } from "@mineral/core";
import { SearchIcon } from "../../ui-components/uim-components";
import "./scopes.less";
import "./credentialVault.less";
import Resource from "./Resource";
import { CheckBoxOutlineBlankIcon as UnCheckedIcon } from "@material-ui/icons/CheckBoxOutlineBlank";
import keyIcon from "../../../assets/icons/keyIcon.svg";
import checkIcon from "../../../assets/icons/check.svg";
import isEqual from "lodash/isEqual";
import credentials from "../../../api/DeviceDiscovery/credentials/credentials";
import AccessibleTooltip from '../../common/AccessibleTooltip'
const textFieldStyle = {
  height: "28px",
  marginLeft: "20px",
  width: "175px !important",
  fontSize: "14px !important",
  padding: "4px",
  marginRight: "20px",
};

export default class ScopesCredentials extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchQuery: "",
      unixCredentials: [],
      filteredUnixCredentials: [],
      wmiCredentials: [],
      filteredwmiCredentials: [],
      selectedUnixCredentials: [],
      selectedWMICredentials: [],
      selectAllCredentials: true,
      hideUnUsedCredentials: false,
      snmpCredItems: [],
      filterdSNMPCredentials: [],
      SelectedSNMPCredentials: [],
      smpCredentials: [],
    };
  }
  
  componentWillReceiveProps = (newProps) => {
    if (!isEqual(newProps.unixCredItems, this.props.unixCredItems)) {
      let newUnixCreds = newProps.unixCredItems;
      let filteredUnixList = [];
      //apply filter here
      if (this.state.searchQuery !== "") {
        filteredUnixList = newUnixCreds.filter((item) => {
          if (
            item.name
            .toLowerCase()
            .indexOf(this.state.searchQuery.toLowerCase()) != -1
            ) {
              return true;
            }
          });
          this.setState({
            unixCredentials: newProps.unixCredItems,
            filteredUnixCredentials: filteredUnixList,
          });
        } else {
          this.setState({
            unixCredentials: newProps.unixCredItems,
            filteredUnixCredentials: newProps.unixCredItems,
          });
        }
      }
      if (!isEqual(newProps.wmiCredentials, this.props.wmiCredentials)) {
        let newWinCreds = newProps.wmiCredentials;
        let filteredWinList = [];
        //apply filter here
        if (this.state.searchQuery !== "") {
          filteredWinList = newWinCreds.filter((item) => {
            if (
              item.name
              .toLowerCase()
              .indexOf(this.state.searchQuery.toLowerCase()) != -1
              ) {
                return true;
              }
            });
            this.setState({
              wmiCredentials: newProps.wmiCredentials,
              filteredwmiCredentials: filteredWinList,
            });
          } else {
            this.setState({
              wmiCredentials: newProps.wmiCredentials,
              filteredwmiCredentials: newProps.wmiCredentials,
            });
          }
        }
        if (!isEqual(newProps.snmpCredItems, this.props.snmpCredItems)) {
          let newSNMPCreds = newProps.snmpCredItems;
          let filteredSNMPList = [];
          //apply filter here
          if (this.state.searchQuery !== "") {
            filteredSNMPList = newSNMPCreds.filter((item) => {
              if (
                item.name
                .toLowerCase()
                .indexOf(this.state.searchQuery.toLowerCase()) != -1
                ) {
                  return true;
                }
              });
              this.setState({
                smpCredentials: newProps.snmpCredItems,
                filterdSNMPCredentials: filteredSNMPList,
              });
            } else {
              this.setState({
                smpCredentials: newProps.snmpCredItems,
                filterdSNMPCredentials: newProps.snmpCredItems,
              });
            }
          }
          //selected items
          let newMode = newProps.mode;
          if (newMode === "add") {
            if (!isEqual(newProps.mode, this.props.mode)) {
              this.setState({
                selectedWMICredentials: newProps.wmiCredentials.map((c) => {
                  return c.id;
                }),
                selectedUnixCredentials: newProps.unixCredItems.map((c) => {
                  return c.id;
                }),
                SelectedSNMPCredentials: newProps.snmpCredItems.map((c) => {
                  return c.id;
                }),
                
                selectAllCredentials: true,
                hideUnUsedCredentials: false,
              });
              this.props.makeScopeDirty(false);
            }
          } else if (newMode === "edit") {
            if (
              !isEqual(newProps.mode, this.props.mode) ||
              newProps.scopeId !== this.props.scopeId
              ) {
                let showAll =
                newProps.selectedUnixCredentials.length ===
                newProps.unixCredItems.length &&
                newProps.selectedWMICredentials.length ===
                newProps.wmiCredentials.length &&
                newProps.SelectedSNMPCredentials.length ===
                newProps.snmpCredItems.length;
                this.setState({
                  selectedWMICredentials: newProps.selectedWMICredentials,
                  selectedUnixCredentials: newProps.selectedUnixCredentials,
                  SelectedSNMPCredentials: newProps.SelectedSNMPCredentials,
                  selectAllCredentials: showAll,
                  hideUnUsedCredentials: false,
                });
                this.props.makeScopeDirty(false);
              }
            }
          };
          componentWillUnmount() {
            this.props.pollWmiCredStop();
            this.props.pollUnixCredStop();
            this.props.pollsnmpCredStop();
          }
          componentDidMount() {
            let mode = "add";
            if (this.props) {
              if (this.props.mode) {
                mode = this.props.mode;
              }
            }
            credentials.getWMI().then(
              (response) => {
                let list = response.data;
                let idList = [],
                selectAllCredentials = false;
                if (mode === "add") {
                  idList = list.map((c) => {
                    return c.id;
                  });
                } else if (mode === "edit") {
                  idList = this.props ? this.props.selectedWMICredentials : [];
                }
                if (
                  idList.length === list.length &&
                  this.state.selectedUnixCredentials.length ===
                  this.state.unixCredentials.length
                  ) {
                    selectAllCredentials = true;
                  }
                  this.setState({
                    selectedWMICredentials: idList,
                    wmiCredentials: list,
                    filteredwmiCredentials: list,
                    selectAllCredentials: selectAllCredentials,
                  });
                },
                (err) => {
                  // console.log(err);
                  /*let errorDescription = err.response.data.error
                  let error = {
                    message: errMssg,
                    description: errorDescription,
                  }
                  this.shouldRefresh = false;
                  this.props.handleError(error)*/
                }
                );
                //SNMP//
                credentials.getSNMP().then(
                  (response) => {
                    let list = response.data;
                    let idList = [],
                    selectAllCredentials = false;
                    if (mode === "add") {
                      idList = list.map((c) => {
                        return c.id;
                      });
                    } else if (mode === "edit") {
                      idList = this.props
                      ? this.props.SelectedSNMPCredentials
                      ? this.props.SelectedSNMPCredentials
                      : []
                      : [];
                    }
                    if (
                      idList.length === list.length &&
                      this.state.selectedUnixCredentials.length ===
                      this.state.unixCredentials.length &&
                      this.state.selectedWMICredentials.length ===
                      this.state.wmiCredentials.length
                      ) {
                        selectAllCredentials = true;
                      }
                      this.setState({
                        SelectedSNMPCredentials: idList,
                        smpCredentials: list,
                        filterdSNMPCredentials: list,
                        selectAllCredentials: selectAllCredentials,
                      });
                    },
                    (err) => {
                      // console.log(err);
                      /*let errorDescription = err.response.data.error
                      let error = {
                        message: errMssg,
                        description: errorDescription,
                      }
                      this.shouldRefresh = false;
                      this.props.handleError(error)*/
                    }
                    );
                    // END//
                    credentials.getUNIX().then(
                      (response) => {
                        let list = response.data;
                        let idList = [],
                        selectAllCredentials = false;
                        if (mode === "add") {
                          idList = list.map((c) => {
                            return c.id;
                          });
                        } else if (mode === "edit") {
                          idList = this.props ? this.props.selectedUnixCredentials : [];
                        }
                        if (
                          idList.length === list.length &&
                          this.state.selectedWMICredentials.length ===
                          this.state.wmiCredentials.length
                          ) {
                            selectAllCredentials = true;
                          }
                          this.setState({
                            unixCredentials: list,
                            selectedUnixCredentials: idList,
                            filteredUnixCredentials: list,
                            selectAllCredentials: selectAllCredentials,
                          });
                        },
                        (err) => {
                          //console.log(err);
                          /*let errorDescription = err.response.data.error
                          let error = {
                            message: errMssg,
                            description: errorDescription,
                          }
                          this.shouldRefresh = false;
                          this.props.handleError(error)*/
                        }
                        );
                        
                        this.props.pollWmiCredStart();
                        this.props.pollUnixCredStart();
                        this.props.pollsnmpCredStart();
                        let api = {
                          getSelectedWMICredentials: this.getSelectedWMICredentials,
                          getSelectedUnixCredentials: this.getSelectedUnixCredentials,
                          getSelectedSNMPCredentials: this.getSelectedSNMPCredentials,
                          resetCredentialsSelection: this.resetCredentialsSelection,
                        };
                        if (this.props.OnCredintialsReady) {
                          this.props.OnCredintialsReady(api);
                        }
                      }
                      //API Methods for parent Scope.js
                      getSelectedWMICredentials = () => {
                        return this.state.selectedWMICredentials;
                      };
                      getSelectedUnixCredentials = () => {
                        return this.state.selectedUnixCredentials;
                      };
                      getSelectedSNMPCredentials = () => {
                        return this.state.SelectedSNMPCredentials;
                      };
                      resetCredentialsSelection = () => {
                        if (this.props && this.props.mode == "add") {
                          this.selectAllCredentials();
                        } else if (this.props && this.props.mode == "edit") {
                          this.setState({
                            selectedWMICredentials: this.props.selectedWMICredentials,
                            selectedUnixCredentials: this.props.selectedUnixCredentials,
                            SelectedSNMPCredentials: this.props.SelectedSNMPCredentials,
                          });
                        }
                        if (this.props) {
                          this.props.makeScopeDirty(false);
                        }
                      };
                      selectAllCredentials = () => {
                        let selectedWMIItems = this.state.wmiCredentials.map((credential) => {
                          return credential.id;
                        });
                        let selectedUNIXItems = this.state.unixCredentials.map((credential) => {
                          return credential.id;
                        });
                        let selectedSNMPItems = this.state.smpCredentials.map((credential) => {
                          return credential.id;
                        });
                        this.setState({
                          selectAllCredentials: true,
                          selectedWMICredentials: selectedWMIItems,
                          selectedUnixCredentials: selectedUNIXItems,
                          SelectedSNMPCredentials: selectedSNMPItems,
                        });
                      };
                      unselectAllCredentials = () => {
                        this.setState({
                          selectAllCredentials: false,
                          selectedWMICredentials: [],
                          selectedUnixCredentials: [],
                          SelectedSNMPCredentials: [],
                        });
                      };
                      //Check box functionality
                      handleSelectAllCredentials = (e) => {
                        //console.log('selectAllCredentials called')
                        if (e.target.checked) {
                          this.selectAllCredentials();
                        } else {
                          this.unselectAllCredentials();
                        }
                        //make the value as dirty when user selects or deselects all credentials
                        this.setState({
                          isDirty: true,
                        });
                        if (this.props) {
                          this.props.makeScopeDirty(true);
                        }
                      };
                      //TODO
                      hideUnusedCredentials = (e) => {
                        if (e.target.checked) {
                          this.setState({
                            hideUnUsedCredentials: true,
                          });
                        } else {
                          this.setState({
                            hideUnUsedCredentials: false,
                          });
                        }
                      };
                      //search text functionality
                      handleSearchTextChange = (e) => {
                        const filterItem = e.target.value.replace(/^\s+/g, "");
                        let unixSearchList = [];
                        let winSearchList = [];
                        let snmpSeachList = [];
                        if (filterItem.length !== 0 && this.searchQuery !== "") {
                          unixSearchList = this.state.unixCredentials.filter((item) => {
                            if (item.name.toLowerCase().indexOf(filterItem.toLowerCase()) != -1) {
                              return true;
                            }
                          });
                          winSearchList = this.state.wmiCredentials.filter((item) => {
                            if (item.name.toLowerCase().indexOf(filterItem.toLowerCase()) != -1) {
                              return true;
                            }
                          });
                          snmpSeachList = this.state.smpCredentials.filter((item) => {
                            if (item.name.toLowerCase().indexOf(filterItem.toLowerCase()) != -1) {
                              return true;
                            }
                          });
                          
                          this.setState({
                            searchQuery: filterItem,
                            filteredUnixCredentials: unixSearchList,
                            filteredwmiCredentials: winSearchList,
                            filterdSNMPCredentials: snmpSeachList,
                          });
                        } else {
                          this.setState({
                            searchQuery: "",
                            filteredUnixCredentials: this.props.unixCredItems,
                            filteredwmiCredentials: this.props.wmiCredentials,
                            filterdSNMPCredentials: this.props.snmpCredItems,
                          });
                        }
                      };
                      //select item functionality
                      handleClickCredential = (item, type) => {
                        let selectedWMIItems = [];
                        let selectedUnixItems = [];
                        let selectedSNMPItems = [];
                        if (type === "WMI") {
                          //Already Present?
                          let isPresent = this.state.selectedWMICredentials.includes(item.id);
                          if (isPresent) {
                            //Remove
                            selectedWMIItems = this.state.selectedWMICredentials.filter((id) => {
                              return id !== item.id;
                            });
                            this.setState({
                              selectedWMICredentials: selectedWMIItems,
                            });
                          } else {
                            //Add
                            selectedWMIItems = this.state.selectedWMICredentials;
                            selectedWMIItems.push(item.id);
                            this.setState({
                              selectedWMICredentials: selectedWMIItems,
                            });
                          }
                        } else if (type == "UNIX") {
                          //Already Present?
                          let isPresent = this.state.selectedUnixCredentials.includes(item.id);
                          if (isPresent) {
                            //Remove
                            selectedUnixItems = this.state.selectedUnixCredentials.filter((id) => {
                              return id !== item.id;
                            });
                            this.setState({
                              selectedUnixCredentials: selectedUnixItems,
                            });
                          } else {
                            //Add
                            selectedUnixItems = this.state.selectedUnixCredentials;
                            selectedUnixItems.push(item.id);
                            this.setState({
                              selectedUnixCredentials: selectedUnixItems,
                            });
                          }
                        } else if (type == "SNMP") {
                          //Already Present?
                          let isPresent = this.state.SelectedSNMPCredentials.includes(item.id);
                          if (isPresent) {
                            //Remove
                            selectedSNMPItems = this.state.SelectedSNMPCredentials.filter((id) => {
                              return id !== item.id;
                            });
                            this.setState({
                              SelectedSNMPCredentials: selectedSNMPItems,
                            });
                          } else {
                            //Add
                            selectedSNMPItems = this.state.SelectedSNMPCredentials;
                            selectedSNMPItems.push(item.id);
                            this.setState({
                              SelectedSNMPCredentials: selectedSNMPItems,
                            });
                          }
                        }
                        //selectAll check box re render
                        if (
                          this.state.wmiCredentials.length === selectedWMIItems &&
                          this.state.unixCredentials.length === selectedUnixItems &&
                          this.state.SelectedSNMPCredentials.length === selectedSNMPItems
                          ) {
                            this.setState({
                              selectAllCredentials: true,
                            });
                          } else {
                            this.setState({
                              selectAllCredentials: false,
                            });
                          }
                          //make the value as dirty when user selects or deselects credential
                          this.setState({
                            isDirty: true,
                          });
                          if (this.props) {
                            this.props.makeScopeDirty(true);
                          }
                        };
                        renderCredentialList = (item, type) => {
                          let itemList = [];
                          if (type === "WMI") {
                            itemList = this.state.selectedWMICredentials;
                          } else if (type === "UNIX") {
                            itemList = this.state.selectedUnixCredentials;
                          } else if (type === "SNMP") {
                            itemList = this.state.SelectedSNMPCredentials;
                          }
                          // let itemList= type==='WMI' ? this.state.selectedWMICredentials ? type === 'UNIX' ? this.state.selectedUnixCredentials
                          // ?type === 'SNMP': this.state.SelectedSNMPCredentials:this.state.selectedWMICredentials:this.state.selectedUnixCredentials:this.state.SelectedSNMPCredentials
                          return (
                            <div>
                            {!this.state.hideUnUsedCredentials || itemList.includes(item.id) ? (
                              <div
                              className="scopes-credential-list"
                             
                              >
                              <ListItem component="div"
                              key={item.id}
                            //  onClick={() => this.handleClickCredential(item, type)}
                              >
                              <div className="might-overflow">
                              <Avatar className="avatar-key" src={keyIcon}  role="figure" imgProps={{alt:`${type} ${item.name} ${item.type}`}} />
                             
                                <FormControlLabel       label={item.name}
                                      control={
                                        <Checkbox style={{marginRight:'4px'}}
                                        id={"selectFromList"+item.name+item.type+item.id}
                                        //classes={{root:"credentialvault-checkbox-container"}}
                                        onClick={() => this.handleClickCredential(item, type)}
                                       
                                        checked={itemList.includes(item.id)}
                                        />}/>
                                       
                               
                                 
                                  </div>
                                  </ListItem>
                                  </div>
                                  ) : (
                                    null
                                    )}
                                    </div>
                                    );
                                  };
                                  render() {
                                    let CredentialsLabel = Resource.get("Credentials"); //Credentials
                                    let linuxHeader = Resource.get("Linux/Unix"); //Linux/Unix
                                    let wmiHeader = Resource.get("WMI"); //Wmi
                                    let allLabel = Resource.get("All"); //All
                                    let snmpLabel = Resource.get("SNMP"); //SNMPLabel
                                    let hideUnusedLabel = Resource.get("Hide Unused"); //Hide Unused
                                    
                                    let wmiCredentialList = "";
                                    let unixCredentialList = "";
                                    let snmpCredentialsLIst = "";
                                    if (this.state.filteredUnixCredentials.length !== 0) {
                                      unixCredentialList = this.state.filteredUnixCredentials.map((item) =>
                                      this.renderCredentialList(item, "UNIX")
                                      );
                                    }
                                    if (this.state.filteredwmiCredentials.length !== 0) {
                                      wmiCredentialList = this.state.filteredwmiCredentials.map((item) =>
                                      this.renderCredentialList(item, "WMI")
                                      );
                                    }
                                    if (this.state.filterdSNMPCredentials.length !== 0) {
                                      snmpCredentialsLIst = this.state.filterdSNMPCredentials.map((item) =>
                                      this.renderCredentialList(item, "SNMP")
                                      );
                                    }
                                    
                                    return (
                                      <div className="credential-div">
                                      <div id="credential-ribbon" style={{ width: "100%",display:'flex',flexDirection:'row' }}>
                                      <FormLabel >{CredentialsLabel}</FormLabel>
                                      
                                    <div style={{marginLeft:'auto'}}>
                                      <FormControl style={{marginRight:'8px',marginBottom:'8px'}}>
                                      <TextField
                                      placeholder="Search"
                                      aria-label={Resource.get("Search")}//Search
                                      InputProps={{
           
            
           endAdornment: (
             <InputAdornment position="end" >
                <AccessibleTooltip title={"Search"}>
                 <SearchIcon  className="selecticon" style={{ height: 16 }} />
                 </AccessibleTooltip>
             </InputAdornment>
           )}}
                                      value={this.state.searchQuery}
                                      onChange={this.handleSearchTextChange.bind(this)}
                                      /></FormControl>
                                      
                                      
                                      <FormControlLabel
                                      label=  {allLabel}
                                      style={{ marginRight: "10px" }}
                                      
                                      control={
                                        <Checkbox
                                        id="showAllCredBox"
                                        classes={{root:"credentialvault-checkbox-container"}}
                                        
                                        onChange={this.handleSelectAllCredentials.bind(this)}
                                        checked={this.state.selectAllCredentials}
                                        />
                                      }
                                      />
                                      <FormControlLabel
                                     
                                      label={hideUnusedLabel}
                                      control={
                                        <Checkbox
                                        id="hideUnusedCheckBox"
                                       
                                        classes={{root:"credentialvault-checkbox-container"}}
                                       
                                        onChange={this.hideUnusedCredentials.bind(this)}
                                        checked={this.state.hideUnUsedCredentials}
                                        />
                                      }
                                      />
                                      </div>
                                      
                                      </div>
                                      <div
                                      style={{ marginTop: "10px", borderTop: "solid 2px #E0E0E0" }}
                                      ></div>
                                      <div
                                      className="input-span"
                                      style={{
                                        fontWeight: 500,
                                        fontStyle: "italic",
                                        marginTop: "7px",
                                        marginBottom: "16px",
                                        color: "#676767",
                                      }}
                                      >
                                      {linuxHeader}
                                      </div>
                                      {unixCredentialList}
                                      <div
                                      className="input-span"
                                      style={{
                                        fontWeight: 500,
                                        fontStyle: "italic",
                                        marginTop: "7px",
                                        marginBottom: "16px",
                                        color: "#676767",
                                      }}
                                      >
                                      {wmiHeader}
                                      </div>
                                      {wmiCredentialList}
                                      <div
                                      className="input-span"
                                      style={{
                                        fontWeight: 500,
                                        fontStyle: "italic",
                                        marginTop: "7px",
                                        marginBottom: "16px",
                                        color: "#676767",
                                      }}
                                      >
                                      {snmpLabel}
                                      </div>
                                      {snmpCredentialsLIst}
                                      </div>
                                      );
                                    }
                                  }
                                  