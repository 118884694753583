// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Dashboards
  content["Dashboards"] =
  "ダッシュボード";

  //title message
  content["Monitoring Configuring Service"] =
  "モニタリング設定サービス";

  //Search for Dashboard Path
  content["Search for Dashboard Path"] =
  "ダッシュボード パスの検索";

  //title message
  content["Infrastructure Mgmt Overview"] =
  "Infrastructure Management 概要";

  // END OF LOCALIZATION

export default content;
