import config from './../config'
import axios from 'axios'

const url = `${config.basename}/api/v1/robots/child-robots`
class Robots {
  get() {
    if(process.env.NODE_ENV=='development'){
      return axios.get("http://10.252.25.208:8080/castlemock/mock/rest/project/TK8t3o/application/UXxhjW/child-robots")
    }
    return axios.get(url)
  }
  post(data = []) {
    return axios.post(url, data)
  }
  getStatus() {
    return axios.get(`${config.basename}/api/v1/robots/robot-deployment-status`)
  }
  getRobotsForUpgrade() {
    return axios.get(`${config.basename}/api/v1/robots/upgrade-check`)
  }
  upgradeRobots(data = []) {
    return axios.post(`${config.basename}/api/v1/robots/upgrade-robots`, data)
  }
  deployRobot(ids =[]) {
	    var url = [config.basename, 'api/v1/robots/deploy-robots']
	    return axios.post(url.join('/'), ids)
	  }
}

let robots = new Robots()
export default robots
