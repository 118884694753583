// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //dashError
  content["Dashboard failed to load."] =
  "Se ha producido un error al cargar el cuadro de mandos.";

  //Systemcomponentmissing message
  content["System component missing"] =
  "Falta un componente del sistema.";

  //missing component
  content["CA Business Intelligence (CABI) system component is needed."] =
  "Se requiere el componente del sistema de CA Business Intelligence (CABI).";

  // Installation and configuration instructions
  content["Installation and configuration instructions"] =
  "Instrucciones de instalación y configuración";

  //Breadcrumb
  content["Dashboards"] =
  "Cuadros de mandos";

  //Breadcrumb
  content["Overview"] =
  "Descripción general";

  // END OF LOCALIZATION

export default content;
