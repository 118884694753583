import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import createSagaMiddleware from 'redux-saga'
import { composeWithDevTools,actionCreators } from 'redux-devtools-extension/developmentOnly'
import operatorConsoleApp from './reducers'
import rootSaga from './sagas'

const sagaMiddleware = createSagaMiddleware()
const middlewares = [thunk, sagaMiddleware]
const middlewareEnhancer = applyMiddleware(...middlewares)
const storeEnhancers = [middlewareEnhancer]
const composeEnhancers = composeWithDevTools({
    actionCreators,
    trace: true,
    traceLimit: 25
});
const composedEnhancer = composeWithDevTools(...storeEnhancers)

const store = createStore(operatorConsoleApp, composeEnhancers(...storeEnhancers))

sagaMiddleware.run(rootSaga)

export default function configureStore() {
  return store
}
