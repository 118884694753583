import React, { Component } from "react";
import { ShowMoreIcon } from "../ui-components/uim-components";
import { Popover } from "@mineral/core";
import "./breadcrumbs.less";

class BreadcrumbOverflow extends Component {
  state = {
    open: false,
    anchorEl: null,
  };
  handleTouchTap = (event) => {
    event.preventDefault();
    this.setState({
      open: true,
      anchorEl: event.currentTarget,
    });
  };
  handleRequestClose = () => {
    this.setState({
      open: false,
      anchorEl: null,
    });
  };

  render() {
    let home = this.props.crumbs[0];
    let rest = this.props.crumbs.slice(1, -2);
    let secondToLast = this.props.crumbs.slice(-2, -1);
    let last = this.props.crumbs.slice(-1);
    return (
      <span className="breadcrumbs" title={this.props.title}>
        {home}
        <span
          className="breadcrumb__link popover__ellipsis"
          onClick={this.handleTouchTap}
        >
          ... <ShowMoreIcon className="breadcrumb_separator" role="img" alt="Separator" aria-label={"Separator"} />
        </span>
        <Popover
          className="breadcrumb__overflow--popover"
          open={this.state.open}
          anchorEl={this.state.anchorEl}
          onClose={this.handleRequestClose}
          style={{
            overflowY: "visibile",
          }}
        >
          <div className="popover__triangle" />
          {rest}
        </Popover>
        {secondToLast}
        {last}
      </span>
    );
  }
}

export default BreadcrumbOverflow;
