import {
  FETCH_AG,
  FETCH_AG_SUCCESS,
  FETCH_AG_FAILURE,
  UPDATE_AG,
  UPDATE_AG_SUCCESS,
  UPDATE_AG_FAILURE,
  FETCH_PAG,
  FETCH_PAG_SUCCESS,
  FETCH_PAG_FAILURE,
  UPDATE_PAG,
  UPDATE_PAG_SUCCESS,
  UPDATE_PAG_FAILURE,
} from "./actionTypes";

const automatic_group = (
  state = {
    isFetching: false,
    didInvalidate: false,
    items: [],
    isUpdating: false,
    error: "",

    isPFetching: false,
    didPInvalidate: false,
    preItems: [],
    isPUpdating: false,
    

  },
  action
) => {
  switch (action.type) {
    case FETCH_AG:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false,
      });
    case FETCH_AG_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        items: action.automatic_group,
      });
    case FETCH_AG_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        errorMessage: action.errorMessage,
        items: [],
      });
    case UPDATE_AG:
      return {
        ...state,
        isUpdating: true,
      };
    case UPDATE_AG_SUCCESS:
      return {
        ...state,
        isUpdating: false,
      };
    case UPDATE_AG_FAILURE:
      return {
        ...state,
        isUpdating: false,
        didInvalidate: true,
        errorMessage: action.errorMessage,
      };
      case FETCH_PAG:
      return Object.assign({}, state, {
        isPFetching: true,
        didPInvalidate: false,
        preItems: [],
      });
    case FETCH_PAG_SUCCESS:
      return Object.assign({}, state, {
        isPFetching: false,
        didPInvalidate: false,
        preItems: action.pre_automatic_group,
      });
    case FETCH_PAG_FAILURE:
      return Object.assign({}, state, {
        isPFetching: false,
        didPInvalidate: true,
        preErrorMessage: action.preErrorMessage,
        preItems: [],
      });
    case UPDATE_PAG:
      return {
        ...state,
        isPUpdating: true,
      };
    case UPDATE_PAG_SUCCESS:
      return {
        ...state,
        isPUpdating: false,
      };
    case UPDATE_PAG_FAILURE:
      return {
        ...state,
        isPUpdating: false,
        didPInvalidate: true,
        preErrorMessage: action.preErrorMessage,
      };
    default:
      return state;
  }
};

export default automatic_group;
