// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Redirection failed
  content["Redirection failed"] =
  "リダイレクトに失敗しました";

  //Redirection error
  content["Verify if the profile associated with the group or device exists."] =
  "グループまたはデバイスに関連付けられたプロファイルが存在するかどうかを確認してください。";

  //OK
  content["OK"] =
  "OK";

  //settings
  content["Settings"] =
  "設定";

  //alarm policies
  content["Alarm Policies"] =
  "アラーム ポリシー";

  //Inventory
  content["Inventory"] =
  "インベントリ";

  //Setup Wizard
  content["Setup Wizard"] =
  "セットアップ ウィザード";

  //Remote And Cloud
  content["Remote And Cloud"] =
  "リモートおよびクラウド";

  // END OF LOCALIZATION

export default content;
