import axios from 'axios';
import config from './../../config';

class PagerDuty {
  get(params) {
    return axios.get([config.basename, 'api/integration/pagerduty'].join('/'));
  }

  save(pagerDutyConfig) {
    return axios.post([config.basename, 'api/integration/pagerduty'].join('/'), pagerDutyConfig);
  }

  test(pagerDutyConfig) {
    return axios.post([config.basename, 'api/integration/pagerduty/test'].join('/'), pagerDutyConfig);
  }

  deleteConfig(pagerDutyConfig) {
    return axios.delete([config.basename, 'api/integration/pagerduty'].join('/'), pagerDutyConfig);
  }
}
export default new PagerDuty();
