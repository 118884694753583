import React from 'react'
import './icons.less'

const Icon = (props) => {
  let classes = [
    'icon',
    props.className,
  ].join(' ')
  return (
    <svg className={classes} role={props.role || 'figure'} aria-label="[title]" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g>
        <title>{props.title}</title>
        <path fillRule="evenodd" d="M3,18 L9,18 L9,16 L3,16 L3,18 L3,18 Z M3,6 L3,8 L21,8 L21,6 L3,6 L3,6 Z M3,13 L15,13 L15,11 L3,11 L3,13 L3,13 Z"/>
      </g>
    </svg>
  )
}

export default Icon
