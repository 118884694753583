import React from "react";
import './spinner.less';
import {CircularProgress} from '@mineral/core';

const Spinner = () => {

    return <>
        <div className="spinner" style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><CircularProgress style={{color: '#3272D9'}} /></div>
        </>
}

export default Spinner;