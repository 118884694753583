import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import connect from './../../../../utils/connect';
import { setBreadCrumbs } from './../../../breadcrumbs/actions';

import CreateMonitoringProbe from '../../../createProbe/createMonitoringProbe';
import { ClearIcon as Close,} from '../../../ui-components/uim-components';
import {Dialog,DialogContent,DialogTitle,IconButton,Typography,Tooltip} from "@mineral/core"
import './RestmonConfig.less';
import Resource from './Resource'

class RestmonConfig extends Component{
  componentDidMount() {
    this.props.setBreadCrumbs([
      {
        //Settings
        name: Resource.get('Settings'),
        link: '/settings',
      },
      {
        //Restmon
        name: Resource.get('Restmon'),
        link: '/settings/integrations/restmon',
      }
    ])
  }
  
  // Close Dialog handler
  handleClose() {
    //TODO:Recheck what to do on dialog close
    this.props.history.push('/settings');
  }
  
  render(){
    //Create a custom probe for monitoring via REST APIs
    const RESTMonMessage = Resource.get('Create a custom probe for monitoring via REST APIs')

    const CLOSE = Resource.get('Close') //Close
    
    const CLOSE_ICON = Resource.get('Close icon') //Close icon
    let title =  RESTMonMessage
    
      
      return(
        <Dialog 
        open={true}
        onClose={() => this.handleClose()}
        
        modal={false}
        
        classes={{root:'restmon_body'} }        
        style={{ paddingTop: 3,height:'100% !important', padding: 0, width: '100%' }}
        aria-labelledby="dialog-title-restmon"
        >
      
        <DialogTitle   disableTypography> <Typography  component="h1" variant="h2">{title}</Typography></DialogTitle> 
        <div style={{height:'0px'}}>
        <Tooltip title="Close">
        <IconButton
     
      onClick={() => this.handleClose()}
      style={{ top: '-50px',float:'right',marginRight: '8px'}}
      aria-label={CLOSE}
      autoFocus
      >
      <Close  role="figure" title={CLOSE} alt={CLOSE_ICON}/>
      </IconButton>
      </Tooltip>
      </div>
      
     
        <DialogContent classes={{root:'restmon_content'}}>
        
        <CreateMonitoringProbe 
          addBreadCrumb={false}
          hideDoneButton={true}
          saas={this.props.saas}
          onDone={()=>{this.handleClose()}}
       
        />
        </DialogContent>
        
        
        
        </Dialog>
        )
      }
    }
    
    
    const mapDispatchToProps = (dispatch) => {
      return {
        setBreadCrumbs: items => dispatch(setBreadCrumbs(items))
      }
    }
    
    const mapStateToProps = state => {
      return {
        saas: state.saas
      }
    }
    
    export default connect(mapStateToProps, mapDispatchToProps)(
      withRouter(RestmonConfig),
      )