import React,{ Component, useMemo } from "react";
import isEqual from "lodash/isEqual";
import connect from "./../../../utils/connect";
import {
  CenterError as Error,
  CenterInfo,
  ClearIcon as Clear,
} from "../../ui-components/uim-components";
import {CircularProgress} from "@mineral/core";
import { Card, IconButton ,Button} from "@mineral/core";
import {
  Cell,
  Bar,
  BarChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  LabelList,
} from "recharts";

import {Tooltip as MTooltip} from "@mineral/core";
import Resource from "./Resource";
import theme from "./../../../theme";
import { isValidParam } from "../DashboardContainerRedux";
import {
  fetchRoleDevicespoll,
  pollRoleDevicesStop,
} from "./../../../api/home-screen/actions";

const chartColors = [
  "#E91E63",
  "#FF9800",
  "#009688",
  "#2196F3",
  "#A11D2E",
  "#F7922F",
  "#FEE276",
  "#9EC6D0",
  "#6200EA",
  "#1A237E",
  "#006064",
  "#F57F17",
  "#795548",
];
const renderLegend = (props) => {
  const { payload } = props;

  return (
    <ul tabIndex={0}>
      {
        payload.map((entry, index) => (
          <li key={`item-${index}`}>{entry.value}</li>
        ))
      }
    </ul>
  );
}

let LegendSquare = (props) => {
  return (
    <div
      aria-label={props.entityId}
      onClick={props.onClick}
      title={props.entityId}
      style={{
        padding: "0px",
        padding: "0px",
        background: "none",
        border: "none",
        paddingTop: "4px",
        cursor: props.isFromOperatorConsole ? "auto" : "pointer",
      }}
    >
      <svg
        width="20"
        height="21"
        aria-label={props.entityId}
        title={props.entityId}
        onClick={props.onClick}
        viewBox="0 0 20 21"
        fill={props.fill}
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="1"
          y="1.5"
          width="18"
          height="18"
          rx="4"
          fill={props.fill}
          stroke="white"
          stroke-width="2"
        />
      </svg>
    </div>
  );
};

const RenderLegend = (props) => {
  const { payload } = props;
  /*
  value: entry.deviceRole + " " + entry.deviceCount,
      type: "rect",
      id: `${entry.deviceRole + "-" + index}`,
      color: `${chartColors[index]}`,*/
  return (
    <div style={{marginLeft:'8px', display: 'flex', alignItems: 'center'}}>
    <table tabIndex={0} summary="Device By Role chart legend">
       
      {props?.legendData?.map((entry, index) => (
        <tr style={{ padding: "none" }}>
          <td>
            <LegendSquare
              entityId={entry.id}             
              strokeDasharray={"1"}
              stroke={entry.color}
              strokeWidth={1}
              fill={entry.color}
             
            />
          </td>
          <td>
            <span
              entityId={entry.value}
              key={`devicebyrolelegenditem-${index}`}
              
              style={{
                textAlign: "left",
                fontStyle: "normal",
                paddingRight: "30px",
                fontWeight: "normal",
                fontSize: "14px",
                color: "#333840",
                textTransform: "capitalize",
              }}
            >
              {entry.value}
            </span>
          </td>
          
        </tr>
      ))}
    </table>
    </div>
  );
};



const Panel = (props) => {
  useMemo(() => {
    if (props?.data) {
      props?.data.map((item, idx) => {
        item.deviceRoleDeviceCount = `${item.deviceRole}-${item.deviceCount}`;
      });
    }
  }, [props?.data]);

  //Device Count
  let deviceCountText = Resource.get("Device Count");
  let legendData = props.data.map((entry, index) => {
    return {
      value: entry.deviceRole + " " + entry.deviceCount,
      type: "rect",
      id: `${entry.deviceRole + "-" + index}`,
      color: `${chartColors[index]}`,
    };
  });
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="chartTooltip">
          <div>{`${label.split('-')[0]}`}</div>
          <div>{`${deviceCountText} : ${payload[0].value}`}</div>
        </div>
      );
    }

    return null;
  };
  return (
    <div
      style={{ flex: "90%", height: "100%", width: "100%" }}
      role="img"
      tabIndex={0}
      aria-label={
        "A bar graph showing device count statistics by role with Roles on X axis and device count on Y axis: " +
        props?.data?.map(
          (entry) => entry?.deviceRole + " Device Count " + entry?.deviceCount
        ) +
        " Click on bars to navigate to device of a specific role"
      }
    >
      <ResponsiveContainer>
        <BarChart data={props.data} style={{ background: "#f4f4f4" }}
          margin={{
            top: 16,
            bottom:16,
          }}
        >
          <Bar dataKey="deviceCount" maxBarSize={70}>
            <LabelList dataKey="deviceCount" position="top" />
            {props.data.map((entry, index) => (
              <Cell
                className={"chart__severity--" + entry.deviceRole}
                key={`cell-${index}`}
                fill={chartColors[index]}
              />
            ))}
          </Bar>
          <Tooltip content={<CustomTooltip />} cursor={{ fill: "#dedede70" }} />
          <Legend  content={
              <RenderLegend legendData={legendData} legendHeight={ window.innerHeight < 800
            ? "20vh"
            : window.innerHeight == 800
            ? "24vh"
            : window.innerHeight < 1080
            ? "25vh"
            : "26vh"}
               
               
              />
            }
            layout="vertical"
            verticalAlign="middle"
            align="right"
            iconSize={12}
          />

          <XAxis tick={false} tickLine={false} dataKey="deviceRoleDeviceCount" />
          <YAxis />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

class PanelRedux extends Component {
  state = {
    data: [],
    loadStatus: "loading",
  };

  apiError = () => {
    this.setState({
      status: "failure",
    });
  };

  componentDidMount() {
    let groupIds = String(this.props.groupIds);
    let probeId = String(this.props.probeId);
    this.props.fetchRoleDevicespoll(
      isValidParam(groupIds) ? groupIds : null,
      isValidParam(probeId) ? probeId : null
    );
  }
  componentWillUnmount() {
    this.props.pollRoleDevicesStop();
  }
  componentWillReceiveProps(nextProps) {
    // if(!isEqual(nextProps.data,this.state.data)){
    this.setState({
      data: nextProps.data,
      loadStatus: nextProps.isRoleDevicesFetching ? "loading" : "complete",
    });
    //}
  }
  render() {
    //No Roles found
    let noRolesText = Resource.get("No Roles found");
    //Devices By Roles
    let title = Resource.get("Devices By Roles");
    //View All
    let viewAllText = Resource.get("View All");
    let cardHeader = (
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h2 className="overview_container_title">{title}</h2>
        <div style={{ display: "flex", alignItems: "center" }}>
        <MTooltip title={viewAllText}>
          <Button
            variant="text"
            style={{height:'16px'}}
aria-label="Click to view all devices"
            onClick={() => {
              this.props.history.push("/inventory");
            }}
            
          >
            {viewAllText}
          </Button>
          </MTooltip>

          {!this.props.hideCloseButton && (
            <span className="overview_header_seprator">|</span>
          )}
          {!this.props.hideCloseButton && (
          <MTooltip title={"Close"}>
            <IconButton
              style={{ padding: 0 }}
              title={"Close"}
              onClick={() => {
                this.props.handleCloseDialog("showDevicesByRoles");
              }}
            >
            
              <Clear className="clearIcon"  role="figure"
            title="Close"/>
            </IconButton>
             </MTooltip>
          )}
        </div>
      </div>
    );
    let view = <Panel data={this.state.data} {...this.props} />;
    if (
      this.state.loadStatus === "loading" &&
      !this.props.didRoleDevicesInvalidate
    ) {
      view = (
        <div
          className="no_data_panel"
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress style={{ color: "#3272D9" }} />
        </div>
      );
    } else if (
      this.state.loadStatus === "failure" ||
      this.props.didRoleDevicesInvalidate
    ) {
      view = (
        <div className="no_data_panel" style={{ height: "150%" }}>
          <Error />
        </div>
      );
    } else if (
      this.state.loadStatus === "complete" &&
      this.state.data.length == 0
    ) {
      view = (
        <div className="no_data_panel" style={{ height: "150%" }}>
          <CenterInfo title={noRolesText} />
        </div>
      );
    }
    return (
      <Card
        className={this.props.className}
        style={{
          height:
            window.innerHeight < 800
              ? "30vh"
              : window.innerHeight == 800
              ? "34vh"
              : window.innerHeight < 1080
              ? "35vh"
              : "38vh",
          display: "flex",
          flexDirection: "column",

          padding: "16px",
        }}
      >
        {cardHeader}
        {view}
      </Card>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchRoleDevicespoll: (groupIds, probeId) =>
      dispatch(fetchRoleDevicespoll(groupIds, probeId)),
    pollRoleDevicesStop: () => dispatch(pollRoleDevicesStop()),
  };
};
const mapStateToProps = (state) => {
  return {
    data: state.homeScreen.roleDevices,
    isRoleDevicesFetching: state.homeScreen.isRoleDevicesFetching,
    didRoleDevicesInvalidate: state.homeScreen.didRoleDevicesInvalidate,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PanelRedux); //connect so that we can get a handle on history
