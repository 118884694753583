import React, { Component } from "react";
import get from "lodash/get";
import { HelpIcon } from "../../ui-components/uim-components";
import { Checkbox, Button as FlatButton,FormControlLabel } from "@mineral/core";
import Configuration from "./../../configuration/ConfigurationRedux";
import InstallRelayHubScreen from "../../installRelayHubScreen/InstallRelayHubScreen";
import RobotDeploy from "./../../robotDeploy/RobotDeployWizard";
import discoveryApi from "./../../../api/discovery/discovery";
import "../setupWizard.less";
import "./setupWizardWalkThrough.less";
import Resource from "./Resource";

//For more information, click
let infoMessage = Resource.get("For more information, click ");
const labelStyle = { paddingRight: 10, color: "rgba(0,0,0,0.54)" };

class SetupWizardWalkThrough extends Component {
  submitted = false;
  robots = [];
  state = {
    pending: [],
    failed: [],
    succeeded: [],
    hub: null,
    probeHasProfiles: false,
  };
  styles = {
    block: {
      maxWidth: 250,
    },
    
  };
  componentDidMount() {
    const type = get(this.props, "probe.name");
    discoveryApi.get({ type: type }).then((response) => {
      this.setState({
        probeHasProfiles: !!response.data[type],
      });
    });
  }
  handleClose = () => {
    this.robots = [];
    this.props.handleDialogClose();
  };
  submit = (robotDeployment) => {
    if (robotDeployment.submitted) {
      this.submitted = true;
      if (robotDeployment.robots.length > 0) {
        this.setState({
          pending: [],
          failed: [],
          succeeded: [],
        });
        this.props.setRobotsBeingDeployed(robotDeployment.robots);
      }
    }
  };
  onHubChange = (hub) => {
    this.setState({
      hub: hub,
    });
  };
  onDeployedStatusChange = (robotDeploymentStatus) => {
    this.setState({
      failed: robotDeploymentStatus.failed,
      succeeded: robotDeploymentStatus.succeeded,
      pending: robotDeploymentStatus.pending,
    });
  };
  
  onExit(robots) {
    this.robots = robots;
  }
  
  onExitHub(hub) {
    this.setState({
      hub: hub,
    });
  }
  
  render() {
    // console.log(this.props);
    const { probe, ip, probeAddress } = this.props;
    let messageForAutomaticMonitoring = Resource.get(
      "Default system monitoring for CPU, disk & memory will be applied"
      ),
      helpLink =
      "https://techdocs.broadcom.com/bin/gethidpage?hid=SetupWizard&space=UIM204&language=&format=rendered";
      if (probe.name !== "windows" && probe.name !== "unix") {
        messageForAutomaticMonitoring = `Default monitoring for ${probe.displayname} plus system monitoring will be applied.`;
      } else if (probe.name === "windows") {
        helpLink = null;
      } else {
        helpLink = null;
      }
      let content = (
        <Configuration
        {...Object.assign({}, this.props, {
          hideBreadcrumbs: true,
          launchPoint: "createProfile",
          isSetupWizard: true,
          probe: probe,
          probeAddress: probeAddress,
          ip: ip,
          launchFrom: "getting-started",
        })}
        />
        );
        if (probe.probeType === "cloud") {
        } else {
          if (this.props.activePage === "relayHub")
          content = (
            <div>
            <InstallRelayHubScreen
            {...Object.assign({}, this.props, {
              addBreadCrumb: false,
              hideDoneButton: true,
            })}
            />
            </div>
            );
            else if (this.props.activePage === "deploy") {
              content = (
                <RobotDeploy
                {...Object.assign(
                  {},
                  {
                    submit: (robotDeployment) => this.submit(robotDeployment),
                    submitted: this.submitted,
                    probe: probe,
                    onComplete: (robotsStatus) =>
                    this.props.onComplete(robotsStatus),
                    onDeployedStatusChange: (status) =>
                    this.onDeployedStatusChange(status),
                    hub: this.state.hub,
                    onHubChange: (hub) => this.onHubChange(hub),
                    succeededRobots: this.state.succeeded,
                    failedRobots: this.state.failed,
                    pendingRobots: this.state.pending,
                    robots: this.robots,
                    onExit: (robots) => this.onExit(robots),
                    onExitHub: (hub) => this.onExitHub(hub),
                    setWizardPage: (page) => this.props.setWizardPage(page),
                  }
                  )}
                  />
                  );
                } else if (
                  this.props.activePage === "done" &&
                  probe.probeType === "local"
                  ) {
                    if (probe.appDiscoveryEnabled) {
                      this.props.enableMonitoringCheckbox({}, true);
                      content = (
                        <div className="setup-wizard-walk-through__done">
                        <div>
                        <FormControlLabel
                        //Automatically monitor
                        label={
                          Resource.get("Automatically monitor ") +
                          probe.displayname +
                          // devices
                          Resource.get(" devices")
                        }
                        style={this.styles.checkbox}
                        classes={{label:'setup-wizard-auto-config-checkbox'}}
                        control={ <Checkbox autoFocus={true}
                          color='primary'
                          classes={{colorPrimary:'setup-wizard-auto-conf-checkbox-icon'}}
                          defaultChecked={true}
                          disabled={this.state.probeHasProfiles}
                          onChange={(event) =>
                            this.props.enableMonitoringCheckbox(
                              event,
                              event.target.checked
                              )
                            }
                            />}
                            ></FormControlLabel>
                            
                            <div className="setup-wizard-walk-through__done-explanation">
                            {" "}
                            {messageForAutomaticMonitoring}
                            </div>
                            {helpLink !== null ? (
                              <div className="setup-wizard__additional-monitors-help">
                              {" "}
                              &nbsp;
                              <FlatButton
                              variant="text"
                              onClick={() => window.open(helpLink, "_blank")}
                              //HELP
                              children={Resource.get("HELP")}
                              endIcon={
                                <HelpIcon
                                className="setup-wizard__additional-monitors-help-icon"
                                title="Help"
                                />
                              }
                              style={{
                                width: 82,
                                border: "1px solid rgba(0,0,0,0.12)",
                                borderRadius: 2,
                              }}
                              classes={{ label: labelStyle }}
                              />
                              </div>
                              ) : null}
                              </div>
                              </div>
                              );
                            } else {
                              // Probe type local and app discovery not supported flow
                              content = (
                                <div className="setup-wizard-walk-through__done">
                                <div>
                                <div className="setup-wizard-walk-through__done-explanation">
                                Auto monitoring for <strong>{probe.displayname}</strong> not
                                supported, we are currently working on it, Please add device
                                monitoring using <strong>"Inventory"</strong>
                                </div>
                                <div className="setup-wizard__additional-monitors-help">
                                {" "}
                                {infoMessage} &nbsp;
                                <FlatButton autoFocus={true}
                                variant="text"
                                onClick={() => window.open(helpLink, "_blank")}
                                //HELP
                                children={Resource.get("HELP")}
                                endIcon={
                                  <HelpIcon
                                  className="setup-wizard__additional-monitors-help-icon"
                                  title="Help"
                                  />
                                }
                                style={{
                                  width: 82,
                                  border: "1px solid rgba(0,0,0,0.12)",
                                  borderRadius: 2,
                                }}
                                classes={{ label: labelStyle }}
                                />
                                </div>
                                </div>
                                </div>
                                );
                              }
                            }
                          }
                          return (
                            <div style={{ height: "calc(100% - 145px) !important" }}>
                            <div className="setup-wizard-walk-through__content" tabIndex={0}>{content}</div>
                            </div>
                            );
                          }
                        }
                        
                        export default SetupWizardWalkThrough;
                        