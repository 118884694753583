// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Relay Hub Installers
  content["Relay Hub Installers"] =
  "中继站安装程序";

  //A relay hub sends metrics and alarms data to your instance of Infrastructure Management.
  content["A relay hub sends metrics and alarms data to your instance of Infrastructure Management."] =
  "中继站将度量标准和警报数据发送到 Infrastructure Management。";

  //Pick the correct installer for your environment
  content["Pick the correct installer for your environment"] =
  "为您的环境选取正确的安装程序";

  //If you will be monitoring Windows devices or a mix of Windows and Linux devices, use the Windows installer. The Linux relay hub can only monitor Linux servers.
  content["If you will be monitoring Windows devices or a mix of Windows and Linux devices, use the Windows installer. The Linux relay hub can only monitor Linux servers."] =
  "如果您要监控 Windows 设备或 Windows 和 Linux 设备组合，请使用 Windows 安装程序。Linux 中继站仅可以监控 Linux 服务器。";

  //If monitoring cloud-based infrastructure, a relay hub is needed for each isolated virtual network (such as AWS Virtual Private Cloud or Azure Virtual Network).
  content["If monitoring cloud-based infrastructure, a relay hub is needed for each isolated virtual network (such as AWS Virtual Private Cloud or Azure Virtual Network)."] =
  "如果监控基于云的基础架构，每个独立虚拟网络 (如 AWS Virtual Private Cloud 或 Azure 虚拟网络) 都需要中继站。";

  //Ensure the relay hub can phone home
  content["Ensure the relay hub can phone home"] =
  "确保中继站可以回拨";

  //Ports 48000 to 48025 must be open
  content["Ports 48000 to 48025 must be open"] =
  "端口 48000 到 48025 必须处于打开状态";

  //for the relay hub to communicate with the Infrastructure Management application.
  content["for the relay hub to communicate with the Infrastructure Management application."] =
  "以便中继站可以与 Infrastructure Management 应用程序通信。";

  //The system where you install the relay hub must meet the following minimum requirements:
  content["The system where you install the relay hub must meet the following minimum requirements:"] =
  "安装中继站的系统必须满足以下最低要求:";

  //Requirements
  content["Requirements"] =
  "要求";

  //Infrastructure Management supports the same Windows and Linux x64 operating systems that are supported for the on-premises CA UIM product.
  content["Infrastructure Management supports the same Windows and Linux x64 operating systems that are supported for the on-premises CA UIM product. "] =
  "Infrastructure Management 支持内部部署 CA UIM 产品支持的相同 Windows 和 Linux x64 操作系统。 ";

  //For more information about which operating systems are supported, refer to the
  content["For more information about which operating systems are supported, refer to the"] =
  "有关受支持操作系统的详细信息，请参阅";

  //Compatibility Support Matrix
  content["Compatibility Support Matrix"] =
  "兼容支持表";

  //Issues?
  content["Issues?"] =
  "问题?";

  //If the relay hub does not appear within Infrastructure Management under the appropriate Operating System group after 10 minutes,  there is likely a communication issue.
  content["If the relay hub does not appear within Infrastructure Management under the appropriate Operating System group after 10 minutes, there is likely a communication issue."] =
  "如果 10 分钟后在 Infrastructure Management 内中继站未出现相应操作系统组下，则可能是通信问题。";

  //Make sure the relay hub server has an active Internet connection.
  content["Make sure the relay hub server has an active Internet connection."] =
  "确保中继站服务器有活动的 Internet 连接。";

  //For more guidance on deploying a relay hub see
  content["For more guidance on deploying a relay hub see"] =
  "有关部署中继站的更多指导，请参阅";

  //Install Relay Hub
  content["Install Relay Hub"] =
  "安装中继站";

  // Download and install a relay hub
  content["Download and install a relay hub"] =
  "下载并安装中继站";

  //4 GB of available memory
  content["4 GB of available memory"] =
  "4 GB 可用内存";

  //2 or more CPUs
  content["2 or more CPUs"] =
  "2 个或更多 CPU";

  //10 GB of available disk space
  content["10 GB of available disk space"] =
  "10 GB 可用磁盘空间";

  //Valid external IP address for the system
  content["Valid external IP address for the system"] =
  "系统的有效外部 IP 地址";

  //Ability to connect to the Internet
  content["Ability to connect to the Internet"] =
  "能够连接到 Internet";

  //HELP
  content["HELP"] =
  "帮助";

  //Help
  content["Help"] =
  "帮助";

  // END OF LOCALIZATION

export default content;
