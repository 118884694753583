import React from 'react';
import IntegrationCard from './../IntegrationCard';
import connect from './../../../../utils/connect';
import {get} from './../../../../api/integrations/slack/actions';
import {CircularProgress} from '@mineral/core'
import IntegrationLogo, {TYPE_SLACK} from './../../../icons/IntegrationLogo';
import IntegrationStateIcon, {TYPE_GOOD, TYPE_NEW, TYPE_ERROR} from './../../../icons/IntegrationStateIcon';
import Resource from './Resource'

class SlackCard extends IntegrationCard {

  componentDidMount() {
    this.props.fetchConfig();
  }

  getIcon() {
    if(this.props.isFetching) {
      return (
          <span style={{padding:'3px'}}>
            <CircularProgress style={{color: '#3272D9'}} className='integration__card--icon-spinner' />
          </span>
      );
    } else if(this.props.responseError && this.props.responseError.message) {
      return (
          <span style={{padding:'3px'}} title={this.props.responseError.message}>
            <IntegrationStateIcon size='30px' type={TYPE_ERROR} />
          </span>
      );
    } else if(this.props.state === 'NEW') {
      return (
          <span style={{padding:'3px'}}>
            <IntegrationStateIcon size='30px' type={TYPE_NEW} />
          </span>
      );
    } else {
      return (
          <span style={{padding:'3px'}}>
            <IntegrationStateIcon size='30px' type={TYPE_GOOD} />
          </span>
      );
    }
  }

  getSubLabelTwo() {
	  //Add
	  const helplabelmessage = Resource.get('Add');
	  //Setup complete
	  const helplabelmessage1 = Resource.get('Setup complete');
    if(this.props.state) {
      if(this.props.state === 'NEW') {
        return helplabelmessage;
      } else {
        return helplabelmessage1;
      }
    } else {
      return '';
    }
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchConfig:() => dispatch(get())
  }
};

const mapStateToProps = (state) => {
  return {
    isFetching:state.slack.isFetching,
    responseError:state.slack.responseError,
    state:state.slack.config.state,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SlackCard)

SlackCard.defaultProps = {
  //slack
  className:Resource.get('slack'),
  logo:<IntegrationLogo height="26px" type={TYPE_SLACK} />,
  //Push alarms to Slack
  subLabelOne:Resource.get('Push alarms to Slack'),
  subLabelTwo:'',
  configRoute:'/settings/integrations/slack'
};
