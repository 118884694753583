import React from 'react'
import PropTypes from 'prop-types'
import './message.less'
import Resource from './Resource'

const Message = ({icon: Icon, title, message,role}) => {
  return (
    <article className='message' title={title}>
      <Icon className='message__icon'/>
      <section className='message__section'>
        <header className='message__header'role={role}>{title}</header>
        <p className='message__text' role={role}>{message}</p>
      </section>
    </article>
  )
}

Message.propTypes = {
  title: PropTypes.string,
  message: PropTypes.any,
  icon: PropTypes.func.isRequired,
  role: PropTypes.string,
}

Message.defaultProps = {
	//A Message
  title: Resource.get('A Message'),
  // You are great
  message: Resource.get('You are great.'),
  role:'alert'
}

export default Message
