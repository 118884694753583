import React, { Component } from 'react'
import CardRedux from './CardRedux'
import { Paginate, ErrorIcon as Information } from '../ui-components/uim-components'
import DetailPopUp from './../groups/detailPopUp/DetailPopUp'
import './cards.less'
import Resource from './Resource'

class Cards extends Component {
  constructor(props) {
    super(props)
    this.state = {
      cardPage: 0,
      showDialog: false,
      device: null,
      showAddDevicesDialog: false,
    }
  }
  componentWillUnmount() {
    this.props.resetCardPage()
  }

  setShowDialog = (element, device) => {
    this.setState({
      showDialog: true,
      device: device,
    })
  }

  handleExitClickFlag = () => {
    this.setState({
      showDialog: false,
    })
  }

  render() {
    let mockThis = this
    let {
      items,
      handlePageClick,
      handleChartChange,
      handleEntityDrillDown,
      onAlarmClick,
      pageLimit,
      totalItems,
      cardPage,
    } = this.props
    //No results found 
    const noResultsFound = Resource.get('No results found')
    const numPages = Math.ceil(totalItems / pageLimit)
    let view
    if (items.length === 0) {
      view = (
        <div className="no-profile-message">
          <Information
            title={noResultsFound}
            className="no-profiles__infomation--icon"
          />
          <p className="no-profiles__infomation--message"> {noResultsFound}</p>
        </div>
      )
    } else {
      let cards = items.map(function(item) {
        return (
          <CardRedux
            key={item.id}
            item={item}
            handleChartChange={handleChartChange}
            handleEntityDrillDown={link => handleEntityDrillDown(item, link)}
            onAlarmClick={link => onAlarmClick(item, link)}
            setShowDialog={mockThis.setShowDialog}
          />
        )
      })

      view = (
        <div>
          <div className="content-grid">
            {cards}
            <DetailPopUp
              entity={this.state.device}
              showDialog={this.state.showDialog}
              handleExitClickFlag={this.handleExitClickFlag}
              featureflag={this.props.featureFlags}
              saas={this.props.saas}
              doiurl={this.props.doiurl}
              acl={this.props.acl}
            />
          </div>
          {numPages > 1 ? (
            <Paginate
              pageCount={numPages}
              forcePage={cardPage}
              onPageChange={handlePageClick}
            />
          ) : null}
        </div>
      )
    }
    return view
  }
}

export default Cards
