import React from "react";
import Portlet from "../Portlet";
import ArrowIcon from "../../ArrowIcon";
import Resource from "./Resource";
import {Tooltip} from '@mineral/core';

export default class ReportSchedulerCard extends Portlet {
  getIcon() {
    return <ArrowIcon />;
  }

  getSubLabelTwo = () => {
    return this.props.subLabelTwo;
  };
}

const TextLogo = ({ label }) => {
  return (
    <Tooltip title={label}>
      <span className="settings-text-logo admin__console--card">{label}</span>
    </Tooltip>
  );
};

//Report Schedules
const ReportSchedulerLabel = Resource.get("Report Schedules");
//View and manage report schedules
const reportSchedulerDesc = Resource.get("View and manage report schedules");

ReportSchedulerCard.defaultProps = {
  logo: <TextLogo label={ReportSchedulerLabel} />,
  configRoute: "/settings/reportscheduler",
  subLabelOne: reportSchedulerDesc,
  subLabelTwo: "",
};
