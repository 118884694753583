import {
  FILTER_ENTITIES,
  SET_ENTITIES,
  SET_ENTITY,
  REMOVE_ENTITIES,
  RENAME_ENTITY,
  ADD_ENTITIES,
  FILTERED_ROW_ENTITIES,
  INVENTORY_FILTER_ENTITIES,
  SET_QUICK_FILTER,
} from "./actionTypes";

export const inventoryfilterEntities = (searchData) => {
  return {
    type: INVENTORY_FILTER_ENTITIES,
    searchData,
  };
};

export const setQuickFilterEntities = (quickFilter) => {
  return {
    type: SET_QUICK_FILTER,
    quickFilter,
  };
};

export const filteredRowEntities = (visibleRowCount) => {
  return {
    type: FILTERED_ROW_ENTITIES,
    visibleRowCount: visibleRowCount,
  };
};
export function setEntities(items) {
  return {
    type: SET_ENTITIES,
    items: items,
  };
}

export function addEntities(items) {
  return {
    type: ADD_ENTITIES,
    items: items,
  };
}

export function setEntity(item) {
  return {
    type: SET_ENTITY,
    item: item,
  };
}

export function removeEntities(items) {
  return {
    type: REMOVE_ENTITIES,
    items: items,
  };
}

export function renameEntity(name) {
  return {
    type: RENAME_ENTITY,
    name: name,
  };
}
