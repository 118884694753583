import React from 'react'
import './icons.less'

const Icon = (props) => {
  let classes = [
    'icon',
    props.className,
  ].join(' ')
  return (
    <svg className={classes} role={props.role || 'figure'} aria-label="[title]" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
     <g fill="none" fillRule="evenodd">
      <title>{props.title}</title>
        <path fill="#58676D" fillRule="nonzero" d="M21,6 L19,6 L19,15 L6,15 L6,17 C6,17.55 6.45,18 7,18 L18,18 L22,22 L22,7 C22,6.45 21.55,6 21,6 Z M17,12 L17,3 C17,2.45 16.55,2 16,2 L3,2 C2.45,2 2,2.45 2,3 L2,17 L6,13 L16,13 C16.55,13 17,12.55 17,12 Z"/>
    </g>
    </svg>
  )
}

export default Icon
