import axios from "axios";
import config from "./../config";

class Devices {
  get(params = {}) {
    let url = [config.basename];
    const id = params.id;
    const csid = params.csid;
    if (id != null) {
      delete params.id;
      url = url.concat(["api/v1/devices", id != null ? id : ""]);
    }
    if (csid != null) {
      delete params.csid;
      url = url.concat(["api/v1/devices/csid", csid != null ? csid : ""]);
    }
    return axios.get(url.join("/"), {
      params: params,
    });
  }

  getDeviceData(csId) {
    var url = process.env.NODE_ENV == "development" ?
    'http://10.252.25.208:8080/castlemock/mock/rest/project/TK8t3o/application/UXxhjW/deviceData' :
    [config.basename,"api/v1/computer-systems",csId != null ? csId : "", ].join("/");

    return axios.get(url);
  }

  getAllSavedMetrics(body) {   
    if (body.hasOwnProperty("masterId")) {
      var url = process.env.NODE_ENV == "development" ?
       'http://10.252.25.208:8080/castlemock/mock/rest/project/TK8t3o/application/UXxhjW/named-views/csId' :
       [config.basename, `api/v1/metrics/named-views?masterId=${body.masterId}&csId=${body.csId}`].join( "/");

      return axios.get(url); 
      
    }else   if (body.hasOwnProperty("csId")) {
      var url = process.env.NODE_ENV == "development" ?
       'http://10.252.25.208:8080/castlemock/mock/rest/project/TK8t3o/application/UXxhjW/named-views/csId' :
       [config.basename, `api/v1/metrics/named-views?csId=${body.csId}`].join( "/");

      return axios.get(url); 
      
    } else {
      var url = process.env.NODE_ENV == "development" ?
      "http://10.252.25.208:8080/castlemock/mock/rest/project/ns92vK/application/gwx4M1/named-views" :
      [config.basename, `api/v1/metrics/named-views?groupId=${body.groupId}`].join("/");
      return axios.get(url);
    }
  }

  async getAllMetricDevices(
    deviceIds,
    metricTypes,
    hours,
    selectedCIs,
    isInterface,
    endDate,
    aggregationValue,
    aggregateFunction,
    ciName
  ) {
    let endDateVal = endDate || new Date().getTime();
  if(process.env.NODE_ENV == "development"){
      return axios.get('http://10.252.25.208:8080/castlemock/mock/rest/project/ns92vK/application/Qw2Qzs/charts');
    }
  else{
    if (isInterface) {
      let endDateVal = endDate || new Date().getTime();
      return await Promise.all(        
        metricTypes.map((metric) => {
          let urlString = `api/v1/metrics/chart?metricTypeId=${encodeURI(
            metric
          )}&csIds=${encodeURI(deviceIds)}&hours=${encodeURI(
            hours
          )}&timezone=${encodeURI(
            Intl.DateTimeFormat().resolvedOptions().timeZone
          )}&endDateEpoch=${new Date(endDateVal).setSeconds(0,0)}&aggregationInterval=${aggregationValue}&aggregateFunction=${aggregateFunction}`; //dp039841
          if (
            selectedCIs.hasOwnProperty(metric)
          ) {
            urlString += `&ciNames=${encodeURI(ciName)}`;
          }
          return axios.get([config.basename, urlString].join("/"));
        })
      );
    } else {
      return await Promise.all(
        metricTypes.map((metricQos) => {

          let metQosArr =metricQos.includes("--")?metricQos.split("--"):[];
          let metric = metQosArr.length > 1 ? metQosArr[0] : metricQos;
          let qos = metQosArr.length > 1 ? metQosArr[1] : "";

          let urlString = `api/v1/metrics/chart?metricTypeId=${encodeURI(
            metric
          )}&csIds=${encodeURI(deviceIds)}&hours=${encodeURI(
            hours
          )}&timezone=${encodeURI(
            Intl.DateTimeFormat().resolvedOptions().timeZone
          )}&endDateEpoch=${new Date(endDateVal).setSeconds(0,0)}&aggregationInterval=${aggregationValue}&aggregateFunction=${aggregateFunction}`; //dp039841
          if (
            selectedCIs.hasOwnProperty(metric) &&
            selectedCIs[metric].length
          ) {
            urlString += `&ciNames=${encodeURI(selectedCIs[metric].join())}`;
          }
          if(qos.length > 0){ 
            urlString += `&qosName=${encodeURI(
              qos
            )}`;
          }
          return axios.get([config.basename, urlString].join("/"));
        })
      )
    }
   }
  }
  getGoldenMetricsData(isInterface) {
    // return axios.get(
    //   [config.basename, "api/v1/metrics/getGoldenMetrics"].join("/")
    // ); // TODO: Remove this as it is a mock
    return isInterface
      ? axios.get(
          [
            config.basename,
            "api/v1/metrics/named-views/interfaceGoldenMetrices",
          ].join("/")
        )
      : axios.get(
          [
            config.basename,
            "api/v1/metrics/named-views/deviceGoldenMetrices",
          ].join("/")
        );
  }

  updateAlias(newAlias, cs_id) {
    // return axios.get([config.basename, "api/v1/updateAlias"].join("/")); // TODO: Remove this as it is a mock
    return axios.put(
      [
        config.basename,
        `api/v1/computer-systems/update/${cs_id}?name=${newAlias}`,
      ].join("/")
    );
  }

  getMonitoringHosts(csId) {
      var url = process.env.NODE_ENV == "development" ?
          'http://10.252.25.208:8080/castlemock/mock/rest/project/TK8t3o/application/UXxhjW/monitoringhosts' :
          [config.basename, `api/v1/computer-systems/monitoringhosts?csId=${csId}`,].join("/");

      return axios.get(url);
  }
}

let devices = new Devices();
export default devices;
