// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //reports message
  content["{0} reports"] =
  "{0} 件のレポート";

  //Run Now
  content["Run Now"] =
  "今すぐ実行";

  //Schedule
  content["Schedule"] =
  "スケジュール";

  //Reports
  content["Reports"] =
  "レポート";

  //Unable to get CABI info
  content["Unable to get CABI info."] =
  "CABI 情報を取得できません。";

  // END OF LOCALIZATION

export default content;
