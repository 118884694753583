import React, { Component } from "react";
import DevicesByRole from "./panels/DevicesByRole";
import TotalAlarms from "./panels/TotalAlarms";
import TotalAlarmsByProbeDevice from "./panels/TotalAlarmsByProbeDevice";
import "./dashboard-container.less";
import "./dashborad-container-responsive.less";
import isEqual from "lodash/isEqual";
import NoViewPanel from "../overview/NoViewPanel"
import Resource from "../inventory/Resource";
import ResourceConsumptionChart from "./panels/ResourceConsumptionChart";

class ProbeDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showOpenAlarms: true,
      showDevicesByRoles: true,
      showTopEntitiesContainer: true,
      showTopTechnologies: true,
      showTopDevices: true,
      showProbeMetrics: true,
    };
  }
  
  // componentWillReceiveProps(nextProps) {
  //   if (!isEqual(nextProps.groupDashboardComponents, this.state)) {
  //     this.setState({ ...nextProps.groupDashboardComponents });
  //   }
  // }
  // componentWillUpdate(prevState, currState) {
  //   if (!isEqual(this.state, currState)) {
  //     this.props.groupDashboardComponentsChanged(currState);
  //   }
  // }
  render() {
    let anyMenuSelected =
    this.state.showOpenAlarms ||
    this.state.showDevicesByRoles ||
    this.state.showTopTechnologies
    ||   this.state.showTopDevices;
    const handleCloseDialog = (propertyName) => {
      if (propertyName == "showTopEntitiesContainer") {
        this.setState({
          showTopEntitiesContainer: false,
          showTopTechnologies: false,
          showTopDevices: false,
          showProbeMetrics: false,
        });
      } else {
        this.setState({
          [propertyName]: false,
        });
      }
    };
    return (
      <div className="dashboard dashboard__main"
      style={{marginLeft:'0px',backgroundColor:'transparent',height:'100%'}}
      >
      {anyMenuSelected ? (
        <section className="dashboard__cards" style={{height:'100%'}}>
        {this.state.showOpenAlarms ||
          this.state.showDevicesByRoles ? (
            <div
            className="dashboard_row_container"
            style={{ display: "flex", gridGap: "16px", flex: "1" }}
            >
            {this.state.showOpenAlarms ? (
              <div className="dashboard__alarms--total dashboard__row-item ">
              <TotalAlarms 
              handleCloseDialog={handleCloseDialog} 
              groupIds={this.props.groupId}
              csId={this.props.csId}
              probeId={this.props.probeId}
              hideCloseButton={true}
              selectedEntity={this.props.selectedEntity}
              />
              </div>
              ) : null}
              { this.state.showDevicesByRoles ? (
                <div className="dashboard_container2 dashboard__row-item">
                
                {this.state.showDevicesByRoles ? (
                  <DevicesByRole showDevicesByRoles={this.state.showDevicesByRoles}
                  handleCloseDialog={handleCloseDialog}
                  groupIds={this.props.groupId}
                  probeId={this.props.probeId}
                  hideCloseButton={true}
                  className="dashboard__devices--by-role "
                  />
                  ) : null}
                  </div>
                  ) : null}
                  </div>
                  ) : null}
                  <div className="dashboard_row_container" 
                  style={{ display: "flex", gridGap: "16px", flex: "1" }}>
                  {(this.state.showTopDevices)? (
                    <div className="dashboard__row-item dashboard_probe_container3">
                    
                    <TotalAlarmsByProbeDevice
                    handleCloseDialog={handleCloseDialog}
                    className="dashboard__alarms--by-device "
                    groupIds={this.props.groupId}
                    probeId={this.props.probeId}
                    hideCloseButton={true}
                    />
                    </div>
                    ) : null}
                    {//this.state.showOpenAlarms ? (
                      this.state.showProbeMetrics ? (
                        <div className="dashboard_probe_container4 dashboard__row-item ">
                        <ResourceConsumptionChart
                        probeId={this.props.probeId}
                        hideCloseButton={true}
                        handleCloseDialog={handleCloseDialog} 
                        />
                        </div>
                        ) : null}
                        </div>
                        </section>
                        
                        ): <NoViewPanel />}</div>
                        );
                      }
                    }
                    
                    export default ProbeDashboard;
                    
                    