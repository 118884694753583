// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //settings
  content["Settings"] =
  "设置";

  //alarm policies
  content["Alarm Policies"] =
  "警报策略";

  // END OF LOCALIZATION

export default content;
