import axios from 'axios'

import config from '../config'
const headersConfig = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  }
}
const chartSeriesParams=["pLine","scale","style","graphColor","displayName","unit_type","unit_short","unit"];
class PRD {
  getChartBaselineData(params) {
    
    let url=[config.basename, `api/v1/prd/chartBaselineData?`].join('/')
    if(process.env.NODE_ENV=="development"){
      return axios.post("http://10.252.25.208:8080/castlemock/mock/rest/project/TK8t3o/application/UXxhjW/prd/chartBaselineData");
    }else{
      
      for(var key in params) {        
        let keyValue = (params[key] && params[key]!=null) ? encodeURIComponent(params[key]) : ''
        
        if(key === "interval" && keyValue === "")
          keyValue = 0
        
        if(url.indexOf('?')==url.length-1)
          url = url.concat(`${key}=${keyValue}`)
        else
          url = url.concat(`&${key}=${keyValue}`)        
      }
      
      return axios.post(url, {}, headersConfig);      
    }
  }

  getchartSeriesInfo(params) {    
    let url=[config.basename, `api/v1/prd/chartSeriesInfo?`].join('/')
    if(process.env.NODE_ENV=="development"){
      return axios.post("http://10.252.25.208:8080/castlemock/mock/rest/project/TK8t3o/application/UXxhjW/prd/chartSeriesInfo");
    } else {      
      for(var key in params) {        
        let keyValue = (params[key] && params[key]!=null) ? encodeURIComponent(params[key]) : ''
        
        if(key === "interval" && keyValue === "")
          keyValue = 0
        
        if(url.indexOf('?')==url.length-1)
          url = url.concat(`${key}=${keyValue}`)
        else
          url = url.concat(`&${key}=${keyValue}`)
      }
      
      return axios.post(url, {}, headersConfig);    
    }
  }

  getchartSeriesData(params) { 
    let url=[config.basename, `api/v1/prd/chartSeriesData?`].join('/')
    if(process.env.NODE_ENV=="development"){
      return axios.post("http://10.252.25.208:8080/castlemock/mock/rest/project/TK8t3o/application/UXxhjW/prd/chartSeriesData");
    } else {      
      for(var key in params){
        if(chartSeriesParams.includes(key)){
          continue;
        }
        
        let keyValue = (params[key] && params[key]!=null) ? encodeURIComponent(params[key]) : ''
        
        if(key === "interval" && keyValue === "")
          keyValue = 0
        
        if(url.indexOf('?')==url.length-1)
          url = url.concat(`${key}=${keyValue}`)
        else
          url = url.concat(`&${key}=${keyValue}`)
      }
      
      return axios.post(url, {}, headersConfig);
    }
  }
  
  getQosSource(){
    
    let url=[config.basename,`api/v1/qosData/sourceList?searchText=`].join('/');
    if(process.env.NODE_ENV=='development'){
      url = "http://10.252.25.208:8080/castlemock/mock/rest/project/ek7Poq/application/3XH68b/host"
    }
    return url;
  }
  getQosListBySource(hostName){
    
    let url=[config.basename,`api/v1/qosData/qosListBySource?source=${encodeURIComponent(hostName)}&searchText=`].join('/');
    if(process.env.NODE_ENV=='development'){
      url = `http://10.252.25.208:8080/castlemock/mock/rest/project/ek7Poq/application/3XH68b/host/${encodeURIComponent(hostName)}`
    }
    return url;
  }
  gettargetListBySourceAndQos(hostName,qos){
    
    let url=[config.basename,`api/v1/qosData/targetListBySourceAndQos?source=${encodeURIComponent(hostName)}&qos=${encodeURIComponent(qos)}&searchText=`].join('/');
    if(process.env.NODE_ENV=='development'){
      url= `http://10.252.25.208:8080/castlemock/mock/rest/project/ek7Poq/application/3XH68b/host/${encodeURIComponent(hostName)}/${encodeURIComponent(qos)}`
    }
    return url;
  }
  getQosList(){
    
    let url=[config.basename,`api/v1/qosData/qosList?searchText=`].join('/');
    if(process.env.NODE_ENV=='development'){
      url = `http://10.252.25.208:8080/castlemock/mock/rest/project/ek7Poq/application/sqVEHr/target`
    }
    return url;
  }
  getTargetListByQos(qos){
    
    let url=[config.basename,`api/v1/qosData/targetListByQos?qos=${encodeURIComponent(qos)}&searchText=`].join('/');
    if(process.env.NODE_ENV=='development'){
      url = `http://10.252.25.208:8080/castlemock/mock/rest/project/ek7Poq/application/sqVEHr/target/${encodeURIComponent(qos)}`
    }
    return url;
  }
  getSourceListByQosAndTarget(qos,target){
    
    let url=[config.basename,`api/v1/qosData/sourceListByQosAndTarget?target=${encodeURIComponent(target)}&qos=${encodeURIComponent(qos)}&searchText=`].join('/');
    if(process.env.NODE_ENV=='development'){
      url= `http://10.252.25.208:8080/castlemock/mock/rest/project/ek7Poq/application/sqVEHr/target/${encodeURIComponent(qos)}/${encodeURIComponent(target)}`
    }
    return url;
  }
  
  savePrdReport(requestBody){
    let url=[config.basename, `api/v1/prd/saveReport`].join('/')
    return axios.post(url,requestBody);
  }

  deletePrdReport(reportName){
    let url=[config.basename, `/api/v1/prd/deleteReport?report=${encodeURIComponent(reportName)}`].join('/')
    return axios.post(url,{});
  }

  loadReport(reportName){
    let url=[config.basename, `api/v1/prd/loadReport?report=${encodeURIComponent(reportName)}`].join('/')
    if(process.env.NODE_ENV=='development'){
      url = `http://10.252.25.208:8080/castlemock/mock/rest/project/TK8t3o/application/UXxhjW/prd/loadReport`
    }
    return axios.post(url,{});
  }
  
  listPrdReports(){
    let url=[config.basename, `api/v1/prd/listReports`].join('/')
    if(process.env.NODE_ENV=='development'){
      url = `http://10.252.25.208:8080/castlemock/mock/rest/project/TK8t3o/application/UXxhjW/prd/listReports`
    }
    return axios.post(url,{});
  }
  filterPRDOutput(requestBody, filterId, apply){
    let url=[config.basename, `api/v1/prd/filterSearch?filterId=${filterId}&apply=${apply}`].join('/')
    if(process.env.NODE_ENV=='development'){
      url = `http://10.252.25.208:8080/castlemock/mock/rest/project/ek7Poq/application/SbSbmz/filter`
    }
    return axios.post(url,requestBody);
  }
  getRestmonUploadUrl() {
    let url = [config.basename,`api/v1/restmon/uploadSchema`].join('/');

    if(process.env.NODE_ENV=='development')
      url = "http://10.252.25.208:8080/castlemock/mock/rest/project/ek7Poq/application/mH7xXt/restmon/uploadSchema"

    return url;
  }

  // 90 Days Password Expired Notification API
  expiredPasswordInfo(){
    let url=[config.basename, `api/v1/contactPasswordLog/expiredPasswordInfo`].join('/')
    if(process.env.NODE_ENV=='development'){
      url = "http://10.252.25.208:8080/castlemock/mock/rest/project/TK8t3o/application/UXxhjW/expiredPasswordURI"
    }
    return axios.get(url);
  }
 
}

let prd = new PRD()
export default prd
