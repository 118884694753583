import React, { Component } from 'react'
import Breadcrumb from './Breadcrumb'
import BreadcrumbOverflow from './BreadcrumbOverflow'
import './breadcrumbs.less'


class Breadcrumbs extends Component {
  getBreadCrumbs = () => {
    let indexOfLastPopOver = -1
    let breadcrumbArray = window.location.pathname.endsWith("/treeviewnew")?this.props.breadCrumbs.slice(0,2):this.props.breadCrumbs;
    breadcrumbArray = breadcrumbArray.filter((v,i,a)=>a.findIndex(v2=>['name'].every(k=>v2[k] ===v[k]))===i);

    breadcrumbArray = breadcrumbArray.filter((bredCrumb) => bredCrumb.name !== undefined)
    if (breadcrumbArray.length > 3) {
      indexOfLastPopOver = breadcrumbArray.length - 3
    }

    return breadcrumbArray.map((crumb, index) => {
      return (
        <Breadcrumb
          item={crumb}
          key={index}
          handleClick={this.props.handleClick}
          isFirstCrumb={index === 0}
          isFinalCrumb={
            index === breadcrumbArray.length - 1
          }
          resetCardPage={this.props.resetCardPage}
        />
      )
    })
  }
  getTitle = () => {
    let breadCrumbsForTooltip = this.props.breadCrumbs.filter((v,i,a)=>a.findIndex(v2=>['name'].every(k=>v2[k] ===v[k]))===i);
    return breadCrumbsForTooltip.reduce((acc, crumb, index) => {
      const isFinalCrumb = index === this.props.breadCrumbs.length - 1
      if (!crumb) {
        return ''
      }
      let title = (acc += crumb.name)
      if (!isFinalCrumb) {
        title += ' > '
      }
    
      return title
    }, '')
  }
  render() {
    let crumbs = this.getBreadCrumbs()
    let title = this.getTitle()

   
    let breadcrumbArray = window.location.pathname.endsWith("/treeviewnew")?this.props.breadCrumbs.slice(0,2):this.props.breadCrumbs;
    breadcrumbArray = breadcrumbArray.filter((v,i,a)=>a.findIndex(v2=>['name'].every(k=>v2[k] ===v[k]))===i);

    breadcrumbArray = breadcrumbArray.filter((bredCrumb) => bredCrumb.name !== undefined)
   
      
    document.title="Operator Console - "+(breadcrumbArray?.[breadcrumbArray?.length - 1]?.name?breadcrumbArray[breadcrumbArray?.length - 1]?.name:"");
    let breadcrumbs = (
      <span className="breadcrumbs" style={{paddingTop:breadcrumbArray.length===1?'0px':'16px'}} title={title}>
        {crumbs}
      </span>
    )
    if (crumbs.length > 3) {
      breadcrumbs = <BreadcrumbOverflow crumbs={crumbs} title={title} />
    }
    return breadcrumbs
  }
}

export default Breadcrumbs
