export const GET_WMI_CREDENTIALS = 'GET_WMI_CREDENTIALS'
export const FETCH_WMI_CREDENTIALS_SUCCESS = 'FETCH_WMI_CREDENTIALS_SUCCESS'
export const FETCH_WMI_CREDENTIALS_FAILURE = 'FETCH_WMI_CREDENTIALS_FAILURE'

export const GET_SNMP_CREDENTIALS = 'GET_SNMP_CREDENTIALS'
export const FETCH_SNMP_CREDENTIALS_SUCCESS = 'FETCH_SNMP_CREDENTIALS_SUCCESS'
export const FETCH_SNMP_CREDENTIALS_FAILURE = 'FETCH_SNMP_CREDENTIALS_FAILURE'

export const POLL_SNMP_START = 'POLL_SNMP_START'
export const POLL_SNMP_STOP = 'POLL_SNMP_STOP'

export const POLL_WMI_START = 'POLL_WMI_START'
export const POLL_WMI_STOP = 'POLL_WMI_STOP'

export const GET_UNIX_CREDENTIALS = 'GET_UNIX_CREDENTIALS'
export const FETCH_UNIX_CREDENTIALS_SUCCESS = 'FETCH_UNIX_CREDENTIALS_SUCCESS'
export const FETCH_UNIX_CREDENTIALS_FAILURE = 'FETCH_UNIX_CREDENTIALS_FAILURE'
	
export const POLL_UNIX_START = 'POLL_UNIX_START'
export const POLL_UNIX_STOP = 'POLL_UNIX_STOP'
	
export const DELETE_WMI_CRED = 'DELETE_WMI_CRED'
	
export const DELETE_SNMP_CRED = 'DELETE_SNMP_CRED'

export const UPDATE_WMI_CRED = 'UPDATE_WMI_CRED'
export const UPDATE_SNMP_CRED = 'UPDATE_SNMP_CRED'

export const ADDED_WMI_CRED = 'ADDED_WMI_CRED'
export const ADDED_SNMP_CRED ='ADDED_SNMP_CRED'