// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //FIX ISSUES
  content["FIX ISSUES"] =
  "FIX ISSUES";

  //device
  content["device"] =
  "device";

  //devices
  content["devices"] =
  "devices";

  //LasrDeployment message
  content["Last Deployment"] =
  "Last Deployment";

  //pending
  content["pending"] =
  "pending";

  //succeeded
  content["succeeded"] =
  "succeeded";

  // failed
  content["failed"] =
  "failed";

  //Setup Infrastructure Monitoring
  content["Setup Infrastructure Monitoring"] =
  "Setup Infrastructure Monitoring";

  //For more information, click
  content["For more information, click"] =
  "For more information, click";

  //Setup Wizard
  content["Setup Wizard"] =
  "Setup Wizard";

  //Remote And Cloud
  content["Remote And Cloud"] =
  "Remote And Cloud";

  //Local Monitoring
  content["Local Monitoring"] =
  "Local Monitoring";

  //You can configure additional monitoring in context of existing devices and groups.
  content["You can configure additional monitoring in context of existing devices and groups."] =
  "You can configure additional monitoring in context of existing devices and groups.";

  //Read more
  content["Read more"] =
  "Read more";

  // Close
  content["Close"] =
  "Close";

  // Search
  content["Search"] =
  "Search";

  // help text
  content["Don\'t show this screen by default"] =
  "Don\'t show this screen by default";

  // dashboard restmon
  content["dashboard restmon"] =
  "dashboard restmon";

  //ADD
  content["ADD"] =
  "ADD";

  // Add Monitoring
  content["Add Monitoring for "] =
  "Add Monitoring for ";

  //CONFIG
  content["CONFIG"] =
  "CONFIG";

  //Show All
  content["Show All"] =
  "Show All";

  //Show Less
  content["Show Less"] =
  "Show Less";

  //Technology
  content["Technology"] =
  "Technology";

  //Technologies
  content["Technologies"] =
  "Technologies";

  //Cancel
  content["Cancel"] =
  "Cancel";

  //Previous
  content["Previous"] =
  "Previous";

  //next
  content["Next"] =
  "Next";

  //Finish
  content["Finish"] =
  "Finish";

  //Presenting the All New Setup Wizard
  content["Presenting the All New Setup Wizard"] =
  "Presenting the All New Setup Wizard";

  //Towards improving your experience with managing and monitoring your infrastructure, we are presenting this new enhanced interface that meets your needs better.
  content["Towards improving your experience with managing and monitoring your infrastructure, we are presenting this new enhanced interface that meets your needs better."] =
  "Towards improving your experience with managing and monitoring your infrastructure, we are presenting this new enhanced interface that meets your needs better.";

  //See what's changed
  content["See what\'s changed"] =
  "See what\'s changed";

  // Close Icon
  content["Close Icon"] =
  "Close Icon";

  //Discover Devices
  content["Discover Devices"] =
  "Discover Devices";

  //Welcome to Setup Wizard
  content["Welcome to Setup Wizard"] =
  "Welcome to Setup Wizard";

  //Your home to setup and configure monitoring infrastructure
  content["Your home to setup and configure monitoring infrastructure"] =
  "Your home to setup and configure monitoring infrastructure";

  // wizard image
  content["wizard image"] =
  "wizard image";

  // END OF LOCALIZATION

export default content;
