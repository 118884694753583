import React from 'react'
import {
  Card,
  CardContent,
  CardMedia
} from '@mineral/core'
import image from './../../assets/images/reports_report.png'
import icon from './../../assets/images/reports_report_icon.png'
// import runNow from './../../assets/images/play_circle.svg'
import schedule from './../../assets/images/schedule.svg'
// import runNowHover from './../../assets/images/play_circle_hover.svg'
import scheduleHover from './../../assets/images/schedule_hover.svg'
import Resource from './Resource'
import PlayCircle from '../icons/PlayCircle'
import PlayCircleHover from '../icons/PlayCircleHover'
import ScheduleIcon from '../icons/ScheduleIcon'
import ScheduleIconHover from '../icons/ScheduleIconHover'
import ReportImage from '../icons/ReportImage'


const ReportCard = (props) => {
  const {scheduleURL,reportURL,uri, label} = props

  const cardStyle = {
    height: '15.625rem',
    width: '18.75rem',
    marginBottom: '1rem',
    marginRight: '1rem',
  }

  const cardTextStyle = {
    display: 'flex',
    fontSize: '1.25rem',
    height: '3.6rem'
  }
  
  const buttonStyle = {
   paddingTop: '1.8rem',
   paddingLeft: '7.2rem'
 }

  const iconStyle = {
    height: '1.56rem',
    width: '1.5rem',
    paddingRight: '1rem'
  }
  let run_now_label = Resource.get("Run Now") //Run Now
  let schedule_label = Resource.get("Schedule") //Schedule
  /**
   * Note: We set an id on the card to enable automated Selenium testing
   */
  return (
      <Card style={cardStyle} id={uri} >
        <CardMedia>
          <ReportImage/>
        </CardMedia>
        <CardContent style={cardTextStyle}>
          <img style={iconStyle} src={icon} role='figure' alt=""/>
          <div>
            {label}
          </div>
          </CardContent>
         
         <div style={buttonStyle}>
             <a href={reportURL} target="_blank" style={{ textDecoration: 'none' }} className='report-card-options'>
                 <div style={{display:'inline-block',height:'20px'}} >
                     <div style={{display:'inline-block',height:'100%'}}>
                         <PlayCircle className={'show-img'}/>
                         <PlayCircleHover className={'show-img-hover'}/>
                     </div>
                     <div className='img-label'>{run_now_label}</div>
                 </div>
             </a>
             <a href={scheduleURL} target="_blank" style={{ textDecoration: 'none', paddingLeft:'17.5px'}} className='report-card-options' >
                 <div style={{display:'inline-block'}}>
                     <div style={{display:'inline-block'}}>
                         <ScheduleIcon className={'show-img'}/>
                         <ScheduleIconHover className={'show-img-hover'}/>
                     </div>
                     <div className='img-label' style={{marginTop:'-8px'}}>{schedule_label}</div>
                 </div>
             </a>

         </div>
      </Card>
  )
}

export default ReportCard