const getCookie = (name) => {
  var value = "; " + document.cookie
  var parts = value.split("; " + name + "=")
  if (parts.length === 2) {
    return parts.pop().split(";").shift()
  }
}
const COOKIE_NAME = 'CA_CLOUD_MANAGEMENT'

class CookieManager {
  getCloudManagerCookie = () => {
    try {
      return JSON.parse(atob(decodeURIComponent(getCookie(COOKIE_NAME))));
    } catch (err) {
      return JSON.parse(getCookie(COOKIE_NAME) || null);
    }	    
  }
}
const instance = new CookieManager()
export default instance

