import {
    GET_SCOPES,
    GET_SCOPE_BY_ID,
    FETCH_SCOPES_SUCCESS,
    FETCH_SCOPES_FAILURE,
    POLL_SCOPES_START,
    POLL_SCOPES_STOP,
    ADDED_SCOPES,
    DELETE_SCOPES,
	UPDATE_SCOPES,
} from './actionTypes'
import scopes from './scopes'

//Get Scopes
export function getScopes(agentId) {
	return dispatch => {
	return scopes.getScopes({agentId:agentId})
    .then(response => response.data)
    .then(json => dispatch(fetchScopesSuccess(json)))
    .catch(function (error) {
      dispatch(fetchScopesFailure(error))
    })
	}
}

export function getScopeById() {
    return {
        type: GET_SCOPES,
    }
}

export function fetchScopesSuccess(json) {
    return {
        type: FETCH_SCOPES_SUCCESS,
        scopes: json
    }
}

export function fetchScopesFailure(error) {
    return {
        type: FETCH_SCOPES_FAILURE,
        errorMessage: error.message
    }
}

export function pollScopesStart(agentId) {
    return {
        type: POLL_SCOPES_START,
        agentId:agentId
    }
}

export function pollScopesStop() {
    return {
        type: POLL_SCOPES_STOP
    }
}
export function deleteScopes(deletedItem) {
	  return {
	    type:DELETE_SCOPES,
	    deletedScopes: deletedItem
	  }
	}
export function updateScopes(updatedItem) {
	  return {
	    type:UPDATE_SCOPES,
	    updatedScopes: updatedItem
	  }
	}
export function addScopes(addedItem) {
	  return {
	    type:ADDED_SCOPES,
	    addedScopes: addedItem
	  }
	}