import {
  FETCH_PROBE_METRICS,
  FETCH_PROBE_METRICS_SUCCESS,
  FETCH_PROBE_METRICS_FAILURE,
  FETCH_PROBE_METRICS_DEVICES,
  FETCH_PROBE_METRICS_DEVICES_SUCCESS,
  FETCH_PROBE_METRICS_DEVICES_FAILURE,
  CLEAR_PROBE_CHART_DATA,
  FETCH_PROBE_METRICS_DEVICES_CINAME,
  FETCH_PROBE_METRICS_DEVICES_CINAME_SUCCESS,
  FETCH_PROBE_METRICS_DEVICES_CINAME_FAILURE,
  REQUEST_PROBE_METRICS_DEVICES_CHART,
  FETCH_PROBE_METRICS_DEVICES_CHART_SUCCESS,
  FETCH_PROBE_METRICS_DEVICES_CHART_FAILURE,
} from "./actionTypes";
  import fetchStatusTypes from '../fetchStatusTypes'
  
  const dashboard_metric_data = (
    state = {
      isFetching: false,
      didInvalidate: false,
      metrics: [],
      error: "",
      isFetchingDevice: false,
      didInvalidateDevice: false,
      metricsForDevices: null,
      errorDevice: "",
      deviceIds: "",
      selectedMetrics: [],
      hours: "",
      endDate: null,
      selectedCIs: {},
      isInterface: false,
      isFetchingAll: false,
      didInvalidateFetchAll: false,
      errorInFetchingAll: "",
      isFetchingById: false,
      didInvalidateFetchById: false,
      errorInFetchingById: "",
      
      fetchStatus: fetchStatusTypes.UNDEFINED,
      devices: [],
    }
    , action) => {
  
    switch (action.type) {
      case FETCH_PROBE_METRICS:
        return Object.assign({}, state, {
          isFetching: true,
          fetchStatus: fetchStatusTypes.FETCHING,
          didInvalidate: false,
          items: [],
          devices: [],
          ciNames:[],
          metricsForDevices:null,
        });
      case FETCH_PROBE_METRICS_SUCCESS:
        return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        fetchStatus: fetchStatusTypes.COMPLETE,
        metrics: action.metrics,
          
        });
      case FETCH_PROBE_METRICS_FAILURE:
        return Object.assign({}, state, {
          isFetching: false,
          fetchStatus: fetchStatusTypes.FAIL,
          didInvalidate: true,
          error: action.errorMessage,
          metrics: [],
        });
        case FETCH_PROBE_METRICS_DEVICES:
        return Object.assign({}, state, {
          isFetching: true,
          fetchStatus: fetchStatusTypes.FETCHING,
          didInvalidate: false,
          devices: []
        });
      case FETCH_PROBE_METRICS_DEVICES_SUCCESS:
        return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        fetchStatus: fetchStatusTypes.COMPLETE,
        devices: action.devices,
          
        });
      case FETCH_PROBE_METRICS_DEVICES_FAILURE:
        return Object.assign({}, state, {
          isFetching: false,
          fetchStatus: fetchStatusTypes.FAIL,
          didInvalidate: true,
          error: action.errorMessage,
          devices: [],
        });
        case FETCH_PROBE_METRICS_DEVICES_CINAME:
        return Object.assign({}, state, {
          isFetching: true,
          fetchStatus: fetchStatusTypes.FETCHING,
          didInvalidate: false,
          ciNames: []
        });
      case FETCH_PROBE_METRICS_DEVICES_CINAME_SUCCESS:
        return Object.assign({}, state, {
          isFetching: false,
        didInvalidate: false,
        fetchStatus: fetchStatusTypes.COMPLETE,
        ciNames: action.ciNames,
          
        });
      case FETCH_PROBE_METRICS_DEVICES_CINAME_FAILURE:
        return Object.assign({}, state, {
          isFetching: false,
          fetchStatus: fetchStatusTypes.FAIL,
          didInvalidate: true,
          error: action.errorMessage,
          ciNames: [],
        });
        case REQUEST_PROBE_METRICS_DEVICES_CHART:
          return Object.assign({}, state, {
            isFetchingDevice: true,
            didInvalidateDevice: false,
          });
        case FETCH_PROBE_METRICS_DEVICES_CHART_SUCCESS:
          return Object.assign({}, state, {
            isFetchingDevice: false,
            didInvalidateDevice: false,
            metricsForDevices: action.metricData,
            deviceIds: action.deviceIds,
            selectedMetrics: action.metricTypes,
            hours: action.hours,
            selectedCIs: action.selectedCIs,
            isInterface: action.isInterface,
            endDate: action.endDate,
          });
        case FETCH_PROBE_METRICS_DEVICES_CHART_FAILURE:
          return Object.assign({}, state, {
            isFetchingDevice: false,
            didInvalidateDevice: true,
            errorDevice: action.errorMessage,
            deviceIds: action.deviceIds,
            selectedMetrics: action.metricTypes,
            hours: action.hours,
            selectedCIs: action.selectedCIs,
            isInterface: action.isInterface,
            endDate: action.endDate,
          });
        case CLEAR_PROBE_CHART_DATA:
          return Object.assign({}, state, {
            metricsForDevices: null,
            deviceIds: "",
            selectedMetrics: [],
            selectedCIs: {},
            hours: 24,
            endDate: null,
          });  
      default:
        return state
    }
  };
  
  export default dashboard_metric_data
  