// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //reports message
  content["{0} reports"] =
  "{0} reports";

  //Run Now
  content["Run Now"] =
  "Run Now";

  //Schedule
  content["Schedule"] =
  "Schedule";

  //Reports
  content["Reports"] =
  "Reports";

  //CABI is not available or accessible
  content["CABI is not available or accessible"] =
  "CABI is not available or accessible";

  //Please contact your administrator.
  content[". Please contact your administrator."] =
  ". Please contact your administrator.";

  // END OF LOCALIZATION

export default content;
