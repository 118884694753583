// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Severity
  content["Severity"] =
  "Severity";

  //Alarm Severity
  content["Alarm Severity"] =
  "Alarm Severity";

  //Message
  content["Message"] =
  "Message";

  //Device
  content["Device"] =
  "Device";

  //Type
  content["Type"] =
  "Type";

  //Created
  content["Created"] =
  "Created";

  //Duration
  content["Duration"] =
  "Duration";

  //No alarms found
  content["No alarms found"] =
  "No alarms found";

  //Name
  content["Name"] =
  "Name";

  //OS Type
  content["OS Type"] =
  "OS Type";

  //OS Name
  content["OS Name"] =
  "OS Name";

  //OS Description
  content["OS Description"] =
  "OS Description";

  //Primary Role
  content["Primary Role"] =
  "Primary Role";

  //IP Addresses
  content["IP Addresses"] =
  "IP Addresses";

  //Primary MAC Address
  content["Primary MAC Address"] =
  "Primary MAC Address";

  //Number Of Cores
  content["Number Of Cores"] =
  "Number Of Cores";

  //Computer Name
  content["Computer Name"] =
  "Computer Name";

  //VM Name
  content["VM Name"] =
  "VM Name";

  //Virtualization Environment
  content["Virtualization Environment"] =
  "Virtualization Environment";

  //Physical Serial Number
  content["Physical Serial Number"] =
  "Physical Serial Number";

  //System Name
  content["System Name"] =
  "System Name";

  //Vendor
  content["Vendor"] =
  "Vendor";

  //Model
  content["Model"] =
  "Model";

  //Firmware Version
  content["Firmware Version"] =
  "Firmware Version";

  // ---- VMWARE ----
  //Power State
  content["Power State"] =
  "Power State";

  //Tools Version
  content["Tools Version"] =
  "Tools Version";

  //Tools Status
  content["Tools Status"] =
  "Tools Status";

  //Resource Pool
  content["Resource Pool"] =
  "Resource Pool";

  //Hostname
  content["Hostname"] =
  "Hostname";

  // ---- AWS ----
  //AWS Instance State
  content["AWS Instance State"] =
  "AWS Instance State";

  //AWS Availability Zone
  content["AWS Availability Zone"] =
  "AWS Availability Zone";

  //AWS VPC
  content["AWS VPC"] =
  "AWS VPC";

  //AWS Instance Type
  content["AWS Instance Type"] =
  "AWS Instance Type";

  //AWS Monitoring State
  content["AWS Monitoring State"] =
  "AWS Monitoring State";

  //Max Alarm Severity
  content["Max Alarm Severity"] =
  "Max Alarm Severity";

  //Total Alarms
  content["Total Alarms"] =
  "Total Alarms";

  //No Roles found
  content["No Roles found"] =
  "No Roles found";

  // END OF LOCALIZATION

export default content;
