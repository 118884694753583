import config from './../config'

class Login {
  get(params) {
    let headers = new Headers()
    let userName = params && params.userName
    let password = params && params.password

    headers.append('saas-user-name',userName)
    headers.append('UMP_PASSWORD',password)
    headers.append('Accept','application/json, text/javascript')
    var apiConfig = {
      method:'GET',
      headers:headers,
      credentials: 'same-origin',
    }
    return fetch([config.basename, 'api/v1/login'].join('/'), apiConfig)
  }
}

let login = new Login()
export default login
