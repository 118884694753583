import * as ActionTypes from './actionTypes';

export const DefaultState = {
  restoredSnackVisible:null,
}

const reducer = (state = DefaultState, action) => {
  switch(action.type) {
    case ActionTypes.SET_RESTORED_SNACKBAR_VISIBLE:
      return {
        ...state,
        restoredSnackVisible:action.restoredSnackVisible,
      }
    default:
      return state
  }
}

export default reducer;
