import React from 'react'
import './icons.less'

const Icon = (props) => {
  let classes = [
    'icon',
    props.className,
  ].join(' ')
  return (
    <svg className={classes} role={props.role || 'figure'} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 -6 24 24">
      <g>
        <title>{props.title}</title>
        <path d="M11.961 16L4.78503 8.69193C4.60242 8.50596 4.5 8.25496 4.5 7.99341C4.5 7.44476 4.94161 7 5.48637 7H18.5136C18.7752 7 19.026 7.10462 19.211 7.29085C19.5963 7.67874 19.5963 8.30773 19.2112 8.69574L11.961 16Z" fill="#333840"/>
      </g>
    </svg>
  )
}
export default Icon
