import React, {Component} from "react";
import ReactDOM from "react-dom";
import './customSetFilter.less';

let AllCheckBoxState = Object.freeze({"ALL":1, "INDETERMINATE":2, "NONE":3})

export default class CustomSetFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            text:'',
            isLoading:false,
            selectedValues:[],
            allCheckBox:AllCheckBoxState.ALL,
            values:Array.isArray(this.props.values)?this.props.values:[]
        };
        this.valueGetter = this.props.valueGetter;
        this.onSearchChange = this.onSearchChange.bind(this);
        this.onFilterChange = this.onFilterChange.bind(this);
        this.onValuesCB = this.onValuesCB.bind(this);
    }
    componentDidMount(){
        let values = this.state.values?this.state.values:[];
        if(typeof this.props.values === 'function'){
            this.props.values({colDef:this.props.colDef,success:this.onValuesCB});
            this.setState({isLoading:true})
        }
        if(this.state.allCheckBox === AllCheckBoxState.ALL){
            let selectAllRef = ReactDOM.findDOMNode(this.refs.selectAll);
            if(selectAllRef){
                selectAllRef.checked = true;
            }
            this.setState({
                selectedValues:values
            })
        }
    }
    componentDidUpdate(prevProps, prevState){
        if(prevState.allCheckBox !== this.state.allCheckBox){
            let selectAllRef = ReactDOM.findDOMNode(this.refs.selectAll);
            if(selectAllRef){
                if(this.state.allCheckBox === AllCheckBoxState.ALL){
                    selectAllRef.checked = true;
                    selectAllRef.indeterminate =false;
                }
                else if(this.state.allCheckBox === AllCheckBoxState.INDETERMINATE){
                    selectAllRef.checked = false;
                    selectAllRef.indeterminate =true;
                }
                else{
                    selectAllRef.checked = false;
                    selectAllRef.indeterminate =false;
                }
            }
        }
    }
    onValuesCB(values){
      if(values && Array.isArray(values)){
          let selectedValues = [...values];
          this.setState({
            values:values,
            allCheckBox:AllCheckBoxState.ALL,
            selectedValues:selectedValues,
            isLoading:false
          })
      }
    }

    isFilterActive() {
        return this.state.allCheckBox !== AllCheckBoxState.ALL;
    }

    doesFilterPass(params) {
        let value = this.valueGetter(params.node)?.toString()?.toLowerCase(),isPresent = false;
        if(this.state.allCheckBox === AllCheckBoxState.ALL){
              isPresent =  true;
        }
        else if(this.props.doesFilterPass && typeof this.props.doesFilterPass === 'function'){
          isPresent = this.props.doesFilterPass(params.data,params.node,[...this.state.selectedValues]);
        }
        else {
            if(this.props.valueToCompare && typeof this.props.valueToCompare === 'function'){
                let valueToCompare;
                valueToCompare = this.props.valueToCompare(params.data,params.node);
                if(typeof valueToCompare === 'string'){
                    value = valueToCompare.toLowerCase();
                }
            }
            let len = this.state.selectedValues.length;
            for(let i=0;i<len;i++){
                if(this.state.selectedValues[i].toLowerCase()=== value ){
                  isPresent =  true;
                  break;
                }
            }
        }
        return isPresent;
    }

    getModel() {
        if(this.state.allCheckBox === AllCheckBoxState.ALL){
            return null;
        }
        else{
            return {type:'set',values: [...this.state.selectedValues]};
        }
    }

    setModel(model) {
        let selectedValues = model ? model.values : [],         
        allCheckBoxStatus = AllCheckBoxState.INDETERMINATE;
         if(model == null){
            selectedValues = this.state.values;
          }
        if(selectedValues.length === this.state.values.length){
            allCheckBoxStatus = AllCheckBoxState.ALL;
          }
          else if(selectedValues.length === 0){
            allCheckBoxStatus = AllCheckBoxState.NONE;
          }
        this.setState({
            selectedValues:selectedValues,
            allCheckBox: allCheckBoxStatus
        },() => {
            this.props.filterChangedCallback();
        })
    }

    afterGuiAttached(params) {
        this.focus();
    }

    focus() {
        setTimeout(() => {
            let searchRef = ReactDOM.findDOMNode(this.refs.search);
            if (searchRef) {
                searchRef.focus();
            }
        })
    }
    onSearchChange(event) {
        let newValue = event.target.value;
        if (this.state.text !== newValue) {
            this.setState({
                text: newValue
            });

        }
    }

    onFilterChange(event,selectAll){
       var target = event.target,
       newValue = target.checked,
       name = target.name,
       values = [...this.state.selectedValues],
       allCheckBoxStatus = AllCheckBoxState.INDETERMINATE;

       if(selectAll){
           if(newValue){
            values = [...this.state.values];
            allCheckBoxStatus = AllCheckBoxState.ALL;
           }
           else{
               values =[];
               allCheckBoxStatus = AllCheckBoxState.NONE;
           }
       }
       else{
          if(newValue){
            values.push(name);
          }
          else{
            let index = values.indexOf(name);
            if (index !== -1) {
                values.splice(index, 1);
            }
          }
          if(values.length === this.state.values.length){
            allCheckBoxStatus = AllCheckBoxState.ALL;
          }
          else if(values.length === 0){
            allCheckBoxStatus = AllCheckBoxState.NONE;
          }
       }
       this.setState({
        selectedValues:values,
        allCheckBox: allCheckBoxStatus
    },() => {
        this.props.filterChangedCallback();
    })
    }
    
    constructValues(){
        let values = this.state.values?this.state.values:[],
        selectedValues = this.state.selectedValues,
        searchText = this.state.text;
        if(searchText && searchText !== ''){
            values = values.filter((item)=>{
                if(item.toLowerCase().includes(searchText.toLowerCase())){
                    return true;
                }
                return false;
            })
        }
        return values.map((item,i)=>{
            return (
                <div key={i.toString()} style={{marginBottom:'5px'}}>
                  {selectedValues && selectedValues.indexOf(item) !== -1 ? 
                            <label className="checkbox-container"><input type="checkbox" name={item} onChange={this.onFilterChange} tabIndex="-1" checked/><span className="checkmark" tabIndex={0}></span>{item}</label> :
                            <label className="checkbox-container"><span></span><input type="checkbox" name={item} onChange={this.onFilterChange} tabIndex="-1"/><span className="checkmark" tabIndex={0}></span>{item}</label>}
                </div>
            )
        })
    }

    render() {
        let style = {
            width: "200px",
            maxHeight: "250px",
            fontSize: '12px',
            color: '#333',
            fontFamily:'Roboto'
        };
        let values = this.constructValues();
        return (
            <div style={style}>
            {this.state.isLoading?<div className="loading">Loading...</div>:''}
            <div><input placeholder="Search..." style={{height: "17px",width:'180px',padding:'0px',margin:'5px 0px 5px 5px'}} ref="search" value={this.state.text} onChange={this.onSearchChange}/></div>
            <label className="checkbox-container"><input type="checkbox" ref="selectAll" name="select_All" onChange={(e)=>this.onFilterChange(e,true)}/><span className="checkmark" tabIndex={0}></span>(Select All)</label>
            <hr/>
             <div style={{maxHeight:'185px',overflowY:'auto'}}>{values.length > 0? values:<div style={{textAlign:'center',padding:'10px'}}>No matches</div>}</div>
            </div>
        );
    }
};

