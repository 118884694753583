// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Robot installer download and instructions
  content["Robot installer download and instructions"] =
  "Download e instruÃ§Ãµes do programa de instalaÃ§Ã£o do robÃ´";

  //Robots manage the probes (monitoring agents) used to collect metric data from your systems, sending the data to Infrastructure Management via the relay hub.
  content["Robots manage the probes (monitoring agents) used to collect metric data from your systems, sending the data to Infrastructure Management via the relay hub."] =
  "Os robÃ´s gerenciam os probes (agentes de monitoramento) usados para coletar dados de mÃ©tricas dos seus sistemas, enviando os dados para o Infrastructure Management por meio do hub de retransmissÃ£o.";

  //ROBOT INSTALLERS
  content["ROBOT INSTALLERS"] =
  "PROGRAMAS DE INSTALAÃ‡ÃƒO DE ROBÃ”S";

  //Add Devices
  content["Add Devices"] =
  "Adicionar dispositivos";

  //Device IP
  content["Device IP"] =
  "IP do dispositivo";

  //OS
  content["OS"] =
  "SO";

  //Architecture
  content["Architecture"] =
  "Arquitetura";

  //Username
  content["Username"] =
  "Nome de usuÃ¡rio";

  //Password
  content["Password"] =
  "Senha";

  //Sudo password (Linux only)
  content["Sudo password (Linux only)"] =
  "Senha sudo (somente Linux)";

  //AUTO DEPLOY
  content["AUTO DEPLOY"] =
  "IMPLANTAÃ‡ÃƒO AUTOMÃ�TICA";

  //MANUAL DEPLOY
  content["MANUAL DEPLOY"] =
  "IMPLANTAÃ‡ÃƒO MANUAL";

  //"Deploy" starts the processes of adding devices to UIM.  Enabling monitoring happens on the next step.
  content["Deploy starts the processes of adding devices to UIM.  Enabling monitoring happens on the next step."] =
  "A implantaÃ§Ã£o iniciarÃ¡ os processos de inclusÃ£o dos dispositivos ao UIM. A ativaÃ§Ã£o do monitoramento ocorrerÃ¡ na prÃ³xima etapa.";

  //Click deploy to install the monitoring host.  When complete, click Next.
  content["Click deploy to install the monitoring host.  When complete, click Next."] =
  "Clique em Implantar para instalar o host de monitoramento. Ao concluir, clique em AvanÃ§ar.";

  //Deploy
  content["Deploy"] =
  "Implantar";

  //Devices added must be able to communicate with the selected relay hub.
  content["Devices added must be able to communicate with the selected relay hub."] =
  "Os dispositivos adicionados devem ser capazes de se comunicar com o hub de retransmissÃ£o selecionado.";

  //Ports 48000 to 48025 must be open
  content["Ports 48000 to 48025 must be open"] =
  "As portas de 48000 a 48025 devem estar abertas";

  //for the relay hub to communicate with the CA Infrastructure Management application.
  content["for the relay hub to communicate with the CA Infrastructure Management application."] =
  "para que o hub de retransmissÃ£o se comunique com o aplicativo CA Infrastructure Management.";

  //If monitoring cloud-based infrastructure, a relay hub is needed for each isolated virtual network (such as AWS Virtual Private Cloud or Azure Virtual Network).
  content["If monitoring cloud-based infrastructure, a relay hub is needed for each isolated virtual network (such as AWS Virtual Private Cloud or Azure Virtual Network)."] =
  "Se estiver monitorando uma infraestrutura com base em nuvem, um hub de retransmissÃ£o serÃ¡ necessÃ¡rio para cada rede virtual isolada (como o AWS Virtual Private Cloud ou a rede virtual do Azure).";

  //Select relay hub
  content["Select a relay hub"] =
  "Selecione um hub de retransmissÃ£o";

  //If you are not installing with root access, use either sudo
  content["If you are not installing with root access, use either sudo"] =
  "Se nÃ£o estiver instalando com acesso raiz, use o sudo";

  //You can also use su to get a root shell and execute the command.
  content["You can also use su to get a root shell and execute the command."] =
  "TambÃ©m Ã© possÃ­vel usar su para obter um shell raiz e executar o comando.";

  //Copy the appropriate Windows installer to any folder on the target system:
  content["Copy the appropriate Windows installer to any folder on the target system:"] =
  "Copie o programa de instalaÃ§Ã£o apropriado do Windows em qualquer pasta do sistema de destino:";

  //Copy the nms-robot-vars.cfg answer file to the same folder.
  content["Copy the nms-robot-vars.cfg answer file to the same folder."] =
  "Copie o arquivo de resposta nms-robot-vars.cfg na mesma pasta.";

  //Install the robot by executing:
  content["Install the robot by executing:"] =
  "Instale o robÃ´ executando:";

  //Installation
  content["Installation"] =
  "InstalaÃ§Ã£o";

  //Robot Installers (including answer file)
  content["Robot Installers (including answer file)"] =
  "Programas de instalaÃ§Ã£o de robÃ´s (incluindo o arquivo de resposta)";

  //The installation is complete. To start the robot, execute the following command:
  content["The installation is complete. To start the robot, execute the following command:"] =
  "A instalaÃ§Ã£o foi concluÃ­da. Para iniciar o robÃ´, execute o seguinte comando:";

  //Execute the RobotConfigurer.sh script to configure the robot when the installer exits.
  content["Execute the RobotConfigurer.sh script to configure the robot when the installer exits."] =
  "Execute o script RobotConfigurer.sh para configurar o robÃ´ quando o programa de instalaÃ§Ã£o for encerrado.";

  //The rpm flags function as follows:
  content["The rpm flags function as follows:"] =
  "O rpm sinaliza a funÃ§Ã£o da seguinte forma:";

//The default install directory is /opt/nimsoft. To specify the installation directory, execute the following command, where <directory> is the full path:
  content["The default install directory is /opt/nimsoft. To specify the installation directory, execute the following command, where <directory> is the full path:"] =
  "O diretÃ³rio de instalaÃ§Ã£o padrÃ£o Ã© &#x2F;opt&#x2F;nimsoft. Para especificar o diretÃ³rio de instalaÃ§Ã£o, execute o comando a seguir, em que &lt;directory&gt; Ã© o caminho completo:";

//Copy the appropriate Linux installer to /opt on the target system:
  content["Copy the appropriate Linux installer to /opt on the target system:"] =
  "Copie o programa de instalaÃ§Ã£o apropriado do Linux em &#x2F;opt no sistema de destino:";

  //Copy the nms-robot-vars.cfg answer file to &#x2F;opt.
  content["Copy the nms-robot-vars.cfg answer file to &#x2F;opt."] =
  "Copie o arquivo de resposta nms-robot-vars.cfg em &#x2F;opt.";

  //Install the robot with the following command, where &lt;arch&gt;is i386 or x86_64:
  content["Install the robot with the following command, where <arch> is i386 or x86_64:"] =
  "Instale o robÃ´ com o seguinte comando, em que &lt;arch&gt; Ã© i386 ou x86_64:";

//The default install folder is C:\Program Files (x86)\Nimsoft for 32-bit systems, and C:\Program Files\Nimsoft for 64-bit systems.
  content["The default install folder is C:\\Program Files (x86)\\Nimsoft for 32-bit systems, and C:\\Program Files\\Nimsoft for 64-bit systems."] =
  "A pasta de instalaÃ§Ã£o padrÃ£o Ã© C:\\Arquivos de Programas (x86)\\Nimsoft para os sistemas de 32 bits e C:\\Arquivos de Programas\\Nimsoft para os sistemas de 64 bits.";

  //To specify the folder, append the following parameter to the command. Quotation marks are required.
  content["To specify the folder, append the following parameter to the command. Quotation marks are required."] =
  "Para especificar a pasta, anexe o parÃ¢metro a seguir ao comando. As aspas sÃ£o necessÃ¡rias.";

  //To specify the log file, append:
  content["To specify the log file, append:"] =
  "Para especificar o arquivo de log, anexe:";

  //After installation, the robot starts automatically.
  content["After installation, the robot starts automatically."] =
  "ApÃ³s a instalaÃ§Ã£o, o robÃ´ serÃ¡ iniciado automaticamente.";

//Copy the Debian installer to /opt on the target system:
  content["Copy the Debian installer to /opt on the target system:"] =
  "Copie o programa de instalaÃ§Ã£o do Debian em &#x2F;opt no sistema de destino:";

  //Note that only 64 bit Debian systems are supported as robots.
  content["Note that only 64 bit Debian systems are supported as robots."] =
  "Observe que apenas os sistemas Debian de 64 bits sÃ£o suportados como robÃ´s.";

  //Install the robot with the following command:
  content["Install the robot with the following command:"] =
  "Instale o robÃ´ com o seguinte comando:";

//Copy the Ubuntu installer to /opt on the target system:
  content["Copy the Ubuntu installer to /opt on the target system:"] =
  "Copie o programa de instalaÃ§Ã£o do Ubuntu em &#x2F;opt no sistema de destino:";

  //Note that only 64 bit Ubuntu systems are supported as robots.
  content["Note that only 64 bit Ubuntu systems are supported as robots."] =
  "Observe que apenas os sistemas Ubuntu de 64 bits sÃ£o suportados como robÃ´s.";

  //(Ubuntu 16 only) Execute the following command to enable the robot service. Note: This step is not required for Ubuntu 14.
  content["(Ubuntu 16 only) Execute the following command to enable the robot service. Note: This step is not required for Ubuntu 14."] =
  "(Apenas Ubuntu 16) Execute o comando a seguir para ativar o serviÃ§o do robÃ´. ObservaÃ§Ã£o: esta etapa nÃ£o Ã© obrigatÃ³ria para o Ubuntu 14.";

  // END OF LOCALIZATION

export default content;
