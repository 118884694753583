import * as ActionTypes from './actionTypes'

export const DefaultState = {
  config: {},
  mutableConfig: {
    severityOptions: [],
  },
  responseError: {},
  isFetching: false,
  isSaving: false,
  isValidating: false,
}

const reducer = (state = DefaultState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_CLOSERULE_CONFIG:
      return {
        ...state,
        isFetching: true,
        responseError: {},
      }
    case ActionTypes.FETCH_CLOSERULE_CONFIG_SUCCESS:
      return {
        ...state,
        isFetching: false,
        config: { ...action.config },
        mutableConfig: { ...action.config },
      }
    case ActionTypes.FETCH_CLOSERULE_CONFIG_FAILURE:
      return {
        ...state,
        isFetching: false,
        responseError: action.responseError,
        config: {},
        mutableConfig: { severityOptions: [] },
      }
    case ActionTypes.SAVE_CLOSERULE_CONFIG:
      return {
        ...state,
        isSaving: true,
        responseError: {},
      }
    case ActionTypes.SAVE_CLOSERULE_CONFIG_SUCCESS:
      return {
        ...state,
        isSaving: false,
        config: { ...action.config },
        mutableConfig: { ...action.config },
      }
    case ActionTypes.SAVE_CLOSERULE_CONFIG_FAILURE:
      return {
        ...state,
        isSaving: false,
        responseError: action.responseError,
      }
    default:
      return state
  }
}

export default reducer
