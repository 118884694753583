import React, { Component } from 'react'
import {
  Dialog,
  Tabs,
  Tab,  
} from '@mineral/core';
import {ClearIcon as Clear} from "../../ui-components/uim-components"
import SeverityIcon from './../../icons/SeverityIcon'
import DevicesByRole from './panels/DevicesByRole'
import DeviceSummary from './panels/DeviceSummary'
import Alarms from './panels/Alarms'
import DevicesByAlarm from './panels/DevicesByAlarm'
import KPI from './panels/KPI'
import EntityTypes from './../../../api/entityTypes'
import theme from './../../../theme'
import './detailPopUp.less'
import MaintenanceInfoDialog from '../../inventory/MaintenanceInfoDialog'

class PopUp extends Component {
  state = {
    tab: 0,
  }
  handleExitClickFlag = () => {
    this.setState({
      tab: 0
    })
    this.props.handleExitClickFlag()
  }
  handleTabClick = (element,tabValue) => {
    let tabs = document.querySelectorAll('.cabiDashboard__tab')
    if(tabs && tabs[this.state.tab])
      tabs[this.state.tab].className = tabs[this.state.tab].className.replace(' active', '')
  /*   let chartId = 0
    if (element.props.label === 'Alarms') {
      chartId = 1
    }
    if (element.props.label === 'Maintenance') {
      chartId = 3
    }
    if (element.props.label === 'KPI' || element.props.label === 'Devices by Alarm') {
      chartId = 2
    } */
    this.setState({
      tab: tabValue
    })
  }
  render() {
    const entity = this.props.entity || {}
    const isComputerSystem = entity.entityType === EntityTypes.COMPUTER_SYSTEM
    let tabStyles = {
      color: theme.palette.textColor,
      background: 'white',
      textTransform: 'none'
    }
   /*  let deviceSummaryTabStyles = {
      overflow: 'auto',
    } */
    // let sevLevel = entity.highestAlarmSeverity == -1 ? 0: entity.highestAlarmSeverity
    const alarmSeverities = entity.alarmCountSummary;
    let sevLevel = alarmSeverities && 
                   (alarmSeverities[0].cnt ? 5 
                    : alarmSeverities[1].cnt ? 4
                    : alarmSeverities[2].cnt ? 3
                    : alarmSeverities[3].cnt ? 2
                    : alarmSeverities[4].cnt ? 1
                    : 0)
    return (
      <Dialog            
        open={this.props.showDialog}
        onClose={this.handleExitClickFlag}
        className="dialog__content"
        >
        <div>
          {Object.keys(entity).length ? (
            <div className='cabiDialog__header'>
              <p className='cabiHeader__closeIcon' onClick={this.handleExitClickFlag}><Clear /></p>
              <p className='dialog__alarmIcon'><SeverityIcon level={sevLevel} /></p>
              <p className='cabiHeader__deviceName'>{entity.name}</p>
              <p className='cabiHeader__deviceType'>{entity.role}</p>
            </div>
          ) : null}
          <Tabs         
            value={this.state.tab}
            onChange={this.handleTabClick}
            indicatorColor='primary'
            className= "detailPopup_tab"            
            >
            
            <Tab
                className={`cabiDashboard__tab ${this.state.tab === 0 ? 'active' : ''}`}
                label='Summary'
                value={0}
                style={tabStyles}/>                              
           
            {!(this.props.saas && this.props.doiurl && this.props.featureflag && this.props.featureflag.globalAlarmsView) ? (
            <Tab
                className={`cabiDashboard__tab ${this.state.tab === 1 ? 'active' : ''}`}
                label='Alarms'
                value={1}
                style={tabStyles}/> 
              ) : null}
            {isComputerSystem?(
              <Tab
                  className={`cabiDashboard__tab ${this.state.tab === 3 ? 'active' : ''}`}
                  label='Maintenance'
                  value={3}
                  style={tabStyles}/>
             ):null
            }
            {!isComputerSystem ? (
              <Tab
                  className={`cabiDashboard__tab ${this.state.tab === 2 ? 'active' : ''}`}
                  label={!isComputerSystem ? 'Devices by Alarm' : 'KPI'}
                  value={2}
                  style={tabStyles}/>
              ) : null}
          </Tabs>
        </div>
        {this.state.tab == 0 && 
          <div
          className={`details-popup__container ${!isComputerSystem ? 'deviceByRole' : 'deviceSummary'}`}
          /* style={isComputerSystem ? deviceSummaryTabStyles : null} */>
          {!isComputerSystem ? (
            <DevicesByRole entity={this.props.entity} />
          ) : (
            <DeviceSummary entity={this.props.entity} />
          )}
        </div>}
        {this.state.tab == 1 && 
            <div className='details-popup__container'>
            <Alarms
              entity={this.props.entity}
              contextType={!isComputerSystem ? 'GROUP_ID' : 'CS_ID'} />
          </div>
        }
        {this.state.tab == 2 && 
            <div className='details-popup__container'>
            {!isComputerSystem ? (
              <DevicesByAlarm
                entity={this.props.entity}
                contextType={!isComputerSystem ? 'GROUP_ID' : 'CS_ID'} />
            ) : (
              <KPI entity={this.props.entity} />
            )}
          </div>
        }
        {this.state.tab == 3 && 
                <div className='details-popup__container'>
                  <MaintenanceInfoDialog
                    entity={this.props.entity}
                    contextType={!isComputerSystem ? 'GROUP_ID' : 'CS_ID'}
                    acl = {this.props.acl} />
                </div>}
      </Dialog>
    )
  }
}
export default PopUp
