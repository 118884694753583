import React from 'react'
import {Route} from 'react-router-dom'
import Inventory from './../components/inventory/InventoryRedux'

const InventoryRoute = () => {
  return (
    <Route path='/inventory' component={Inventory} />
  )
}

export default InventoryRoute