export const GET_SCOPES = 'GET_SCOPES'
export const GET_SCOPE_BY_ID = 'GET_SCOPE_BY_ID'
export const FETCH_SCOPES_SUCCESS = 'FETCH_SCOPES_SUCCESS'
export const FETCH_SCOPES_FAILURE = 'FETCH_SCOPES_FAILURE'

export const POLL_SCOPES_START = 'POLL_SCOPES_START'
export const POLL_SCOPES_STOP = 'POLL_SCOPES_STOP'
	
export const DELETE_SCOPES = 'DELETE_SCOPES'
export const UPDATE_SCOPES = 'UPDATE_SCOPES'
export const ADDED_SCOPES = 'ADDED_SCOPES'