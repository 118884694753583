import React from 'react'
import {Route} from 'react-router-dom'
import RobotDeploy from './../components/robotDeploy/RobotDeployRedux'

const RobotDeployMain = (props) => {
  return (
    <main>
      <RobotDeploy {...props} />
    </main>
  )
}

const RobotDeployRoute = () => {
  return (
    <Route path='/robot-deploy' component={RobotDeployMain}/>
  )
}

export default RobotDeployRoute