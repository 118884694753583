import React, { Component } from "react";
import Resource from "./Resource";
import {
  TextField,
  MenuItem,
  Select as SelectField,
  FormControl,
  InputLabel,Button,Tooltip,IconButton
} from "@mineral/core";
import Group from "./dynamicGroup/Group";


import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

//Group description
const GroupDescriptionText = Resource.get("Group Description");
//Group Name
const GroupNameText = Resource.get("Group Name");

export default class GroupDialogHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      accounts: [],
      addButtonDisabled:
        props.mode === "ADD Group" || props.mode === "ADD" ? true : false,
      groupDetails: props.groupDetails,
      editMode: props.mode === "ADD Group" || props.mode === "ADD" ? false : true,
    };
    this.entities = props.entities;

    this.isFieldDisabled = this.isFieldDisabled.bind(this);
  }
  componentDidMount() {
    this.props.getAccount();
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.accounts && nextProps.accounts.length > 0) {
      this.setState({
        accounts: nextProps.accounts,
      });
      if (!this.props.acl.isBusUser) {
        let groupDetails = { ...this.state.groupDetails };
        if (nextProps.mode === "ADD Group" || nextProps.mode === "ADD") {
          groupDetails.accountId = nextProps.accounts[0].accountId;
        } else {
          groupDetails = nextProps.groupDetails;
        }
        this.setState({
          groupDetails: groupDetails,
        });
      }
    }
    this.entities = nextProps.entities;
  }
  handleNameChange = (event) => {
    const newName = event.target.value.trimLeft();
    const filteredEntities = this.entities.filter(
      (data) => data.entityType === "GROUP"
    );
    const siblings = filteredEntities.map((entity) =>
      entity.name.toLowerCase()
    );
    let groupDetails = { ...this.state.groupDetails };
    groupDetails.name = newName;
    if (newName.length > 255) {
      this.setState({
        //Namemustbelessthan256 characters message
        nameError: Resource.get("Name must be less than 256 characters"),
        groupDetails: groupDetails,
        addButtonDisabled: true,
      });
    } else if (siblings.includes(newName.toLowerCase())) {
      this.setState({
        //Groupnamelareadyexists message
        nameError: Resource.get("Group name already exists"),
        groupDetails: groupDetails,
        addButtonDisabled: true,
      });
    } else if (newName.length < 1) {
      this.setState({
        groupDetails: groupDetails,
        addButtonDisabled: true,
      });
    } else {
      this.setState({
        nameError: null,
        groupDetails: groupDetails,
        addButtonDisabled: false,
      });
    }

    this.onGroupDetailsChanged();
  };
  handleTypeChange = (event) => {
    let payload = event.target.value;
    let groupDetails = { ...this.state.groupDetails };
    groupDetails.type = payload;
    this.setState({ groupDetails: groupDetails });
    this.onGroupDetailsChanged();
  };
  handleDescriptionChange = (event) => {
    let groupDetails = { ...this.state.groupDetails };
    groupDetails.description = event.target.value;
    this.setState({ groupDetails: groupDetails });
    this.onGroupDetailsChanged();
  };
  handleElementChange = (event) => {
    let payload = event.target.value;
    let groupDetails = { ...this.state.groupDetails };
    groupDetails.elementType = payload;
    this.setState({ groupDetails: groupDetails });
    this.onGroupDetailsChanged();
  };
  handleAccountChange = (event) => {
    let value = event.target.value;
    let groupDetails = { ...this.state.groupDetails };
    groupDetails.accountId = value;
    this.setState({
      groupDetails: groupDetails,
    });

    this.onGroupDetailsChanged();
  };

  onGroupDetailsChanged() {
    let context = this;
    setTimeout(() => {
      context.props.onGroupDetailsChanged(
        this.state.groupDetails,
        this.state.addButtonDisabled
      );
    }, 500);
  }

  isFieldDisabled(fieldType) {
    const checkValue = this.state.groupDetails.name.trimLeft();
    const checkArray = ['Operating Systems', 'Application Discovery', 'Discovered Application Systems', 'Setup Application Discovery'];
      const isDisabled = checkArray.indexOf(checkValue) >= 0 || this.state.groupDetails.type === "AUTOMATIC";
    return isDisabled;
  }

  render() {
    // A group may contain either devices or additional groups, not a mix.
    const helplabelmessage = Resource.get(
      " A group may contain either devices or additional groups, not a mix."
    );
    // This group will contain...
    const groupTypeLabel = Resource.get("Group Type"); //Groupt Type from Static, Dynamic or Contianer
    const Account = Resource.get("Account"); //Account for Group

    const memberTypeLabel = Resource.get("Member Type"); // Member Type
    const no_account = Resource.get("No Account"); //Default value for dropdown

    let groupTypes = [
      {
        value: "DYNAMIC",
        //Dynamic
        label: `${Resource.get("Dynamic")}`,
      },
      {
        value: "STATIC",
        //Static
        label: `${Resource.get("Static")}`,
      },
      {
        value: "CONTAINER",
        //CONTAINER
        label: `${Resource.get("Container")}`,
      },
    ].map((item) => (
      <MenuItem
        key={item.label}
        value={item.value}
        label={item.label}
        children={item.label}
      />
    ));
    let elementTypes = [
      {
        value: "DEVICES",
        //Devices
        label: `${Resource.get("Devices")}`,
      },
      {
        value: "INTERFACES",
        //Interfaces
        label: `${Resource.get("Interface")}`,
      },
    ].map((item) => (
      <MenuItem
        key={item.label}
        value={item.value}
        label={item.label}
        children={item.label}
      />
    ));

    let accountOptions =
      this.state.accounts &&
      this.state.accounts.map((item) => (
        <MenuItem
          value={item.accountId}
          label={item.contactName}
          children={item.contactName}
        />
      ));

    let finalAccounts = this.props.acl.isBusUser ? accountOptions : [];
    //    let finalAccounts = accountOptions

    let group = this.props.selectedGroup;
    let styleInfo = {
        borderBottom: "1px solid rgb(224 224 224)",
        display: "inline-block",
        height: "28px",
        width: "100%",
        textOverflow: "ellipsis",
        overflow: "hidden",
        paddingRight: "20px",
        paddingTop: "1px",
      },
      selectClasses = ["select-field"];
    let parentLabel = Resource.get("Parent"); //Parent
    let topSection = (
      <FormControl
        style={{ width: "225px", position: "relative" }}
        onKeyDownCapture={(event) => {
          if (event.key === "Escape") {
            console.log("close");
            this.props.handleGroup();
            event.stopPropagation();
          }
        }}
        disabled={this.isFieldDisabled('groupParent')}
      >
        <InputLabel id="groupParentLabelid" htmlFor="groupParent">{parentLabel}</InputLabel>
        
        <TextField variant="outlined" role="combobox" labelId="groupParentLabelid"
         style={{top:'-2px'}} aria-label={parentLabel} 
          aria-expanded={Boolean(this.state.isOpen)}
          disabled={this.isFieldDisabled('groupParent')}
          onClick={this.props.onParentClick}
          inputProps={{style: {cursor: 'pointer'}, readOnly:true, role:"combobox",'aria-expanded':Boolean(this.state.isOpen),id:'groupParent'}}
          InputProps={{endAdornment:(<Tooltip title="Select Parent Group"><IconButton style={{right:'-28px'}} aria-label="Select Parent Group" onClick={
            this.props.onParentClick
                      }>


<ArrowDropDownIcon
  style={{ transform: 'scale(1.8)', color: '#3272D9' }}
  fontSize="large"
  color="#3272D9"
/>
</IconButton></Tooltip>)}}
        //  className={selectClasses.join(" ")}
         value= {group ? group.name : "Groups"}
        >
           </TextField>
        <div
          id="parentGroup"
          className="dynamic-group-modal"
          ref={(node) => this.props.onNodeSelected(node)}
          onBlur={(event) => {
            if (
              event.relatedTarget &&
              !event.relatedTarget.closest("#parentGroup")
            ) {
              this.props.handleGroup();
            }
          }}
        >
          <Group
            {...this.props}
            onSelectGroup={this.props.handleGroup}
            isEditMode={this.state.editMode}
          />
        </div>
      </FormControl>
    );

    return (
      <div style={{ display: "flex", justifyContent: "space-between" }}>
      <FormControl disabled={this.isFieldDisabled('groupName')}>
      <InputLabel id="groupNameLabel" htmlFor="groupName">{GroupNameText}<span style={{ fontSize: '12px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    lineHeight: '14px',
    color: '#B80D0D',
    height: '14px',
    float: 'right'}}>{"Required"}</span></InputLabel>
        <TextField labelId="groupNameLabel"
          placeholder={GroupNameText}
          onChange={this.handleNameChange}
          value={this.state.groupDetails.name.trimLeft()}
          disabled={this.isFieldDisabled('groupName')}
          style={{ width: "225px" }}
          helperText={this.state.nameError}
          inputProps={{required:true,id:'groupName'}}
          sx={{
   input: {
    
    "&::placeholder": {  // <----- Add this.
      color: 'black !important',
      opacity:0.8
     
    },
   }}}
          
        />
        </FormControl>
        <TextField
          placeholder={GroupDescriptionText}
          label={GroupDescriptionText}
          onChange={this.handleDescriptionChange}
          value={this.state.groupDetails.description}
          disabled={this.isFieldDisabled('groupName')}
          style={{ width: "225px"  }}
          sx={{
   input: {
    
    "&::placeholder": {  // <----- Add this.
      color: 'black !important',
      opacity:0.8
     
    },
   }}}
        />
        <FormControl disabled={this.isFieldDisabled('groupAccount')}>
          <InputLabel htmlFor="account-select">{Account}</InputLabel>
          <SelectField role="combobox"
            onChange={this.handleAccountChange}
            aria-label={Account}
            value={this.state.groupDetails.accountId}
            disabled={this.isFieldDisabled('groupAccount')}
            id="account-select"
            style={{ width: "225px"  }}
            inputProps={{'aria-label':Account,
                           role: 'combobox',}}
                           MenuProps={{
       MenuListProps:{
         
         'aria-label' :Account,
         title:Account
        }}}
          >
            {this.props.acl.isBusUser ? (
              <MenuItem value={-1} label={no_account} children={no_account} />
            ) : this.state.accounts ? (
              this.state.accounts.length > 0 ? (
                <MenuItem
                  value={this.state.groupDetails.accountId}
                  label={this.state.accounts[0].contactName}
                  children={this.state.accounts[0].contactName}
                />
              ) : (
                <MenuItem value={-1} label={no_account} children={no_account} />
              )
            ) : (
              <MenuItem value={-1} label={no_account} children={no_account} />
            )}
            {finalAccounts}
          </SelectField>
        </FormControl>
        {topSection}
        <FormControl>
          <InputLabel htmlFor="groupType-select">{groupTypeLabel}</InputLabel>
          <SelectField role="combobox"
            onChange={this.handleTypeChange}  aria-label={groupTypeLabel}
            value={this.state.groupDetails.type}
            disabled={this.state.editMode}
            id="groupType-select"
            style={{ width: "225px"  }}
            inputProps={{'aria-label':{groupTypeLabel},
                           role: 'combobox',}}
                           MenuProps={{
       MenuListProps:{
         
         'aria-label' :groupTypeLabel,
         title:groupTypeLabel
        }}}
          >
            {groupTypes}
          </SelectField>
        </FormControl>

        <FormControl>
          <InputLabel htmlFor="memoryType-select">{memberTypeLabel}</InputLabel>
          <SelectField role="combobox"  aria-label={memberTypeLabel}
            onChange={this.handleElementChange}
            value={this.state.groupDetails.elementType}
            style={{ width: "225px"  }}
            disabled={
              this.state.groupDetails.type === "CONTAINER" ||
              this.state.editMode
                ? true
                : false
            }
            inputProps={{'aria-label':{memberTypeLabel},
                           role: 'combobox',}}
                           MenuProps={{
       MenuListProps:{
         
         'aria-label' :memberTypeLabel,
         title:memberTypeLabel
        }}}
            id="memoryType-select"
          >
            {elementTypes}
          </SelectField>
        </FormControl>
      </div>
    );
  }
}
