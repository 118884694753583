import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import connect from "./../../../../utils/connect";
import { setBreadCrumbs } from "./../../../breadcrumbs/actions";
import "./selfCert.less";

import {CircularProgress} from '@mineral/core'
import ReactFrame from "./../ReactFrame";
import Resource from "./Resource";

class SelfCert extends Component {
  state = {
    sid: null,
  };

  componentWillMount = () => {
   
        this.setState({ sid : this.props.sid});
     
  };

  componentDidMount() {
    this.props.setBreadCrumbs([
      {
        //Settings
        name: Resource.get("Settings"),
        link: "/settings",
      },
      {
        //Device Self Certification
        name: Resource.get("Device Self Certification"),
        link: "/settings/self-cert",
      },
    ]);
  }

  render() {
    let view = (
      <div className="spinner" style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><CircularProgress style={{color: '#3272D9'}} /></div>

    );

    if (this.state.sid) {
        let do_heartbeat = window.OPERATOR_CONSOLE.do_heartbeat
        if (do_heartbeat !== 'true' && do_heartbeat !== 'false') {
          do_heartbeat = false
        }
      view = (
        <ReactFrame
          url="selfcert/jsp/index.jsp"
          sid={this.state.sid}
          portletId="self-cert"
          keepalive={do_heartbeat}
          method="POST"
        />
      );
    }

    return view;
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setBreadCrumbs: (items) => dispatch(setBreadCrumbs(items)),
  };
};

const mapStateToProps = (state) => {
  return {
    saas: state.saas,
    sid: state.sid,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SelfCert));
