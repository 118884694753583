import React from "react";
import connect from "./../../utils/connect";
import { Route } from "react-router-dom";
import get from "lodash/get";
import {
  fetchComputerSystems,
  fetchAllComputerSystems,
  searchComputerSystems,
} from "./../../api/computer-systems/actions";
import { setEntities } from "./../entities/actions";
import entityTypes from "./../../api/entityTypes";

class ComputerSystems extends React.Component {
  _getComputerSystem = (props) => {
    let queryString = { q: "" };
    let makeApiCall = true;
    if (props.probeParam != "") {
      queryString = { probeName: props.probeParam };
    }
    if (makeApiCall == true && this.props.entityFilter === "")
      props
        .searchComputerSystems(
          0,
          { sortColumn: undefined, sortOrder: undefined },
          []
        )
        .then((computerSystemsAction) => {
          const computerSystems = computerSystemsAction.data;
          return props.setEntities(computerSystems);
        });
  };
  componentWillMount() {
    this._getComputerSystem(this.props);
  }
  componentWillReceiveProps(nextProps) {
    this._getComputerSystem(nextProps);
  }
  render() {
    return null;
  }
}
const ComputerSystemsApi = (props) => {
  return (
    <Route
      path="/inventory"
      render={({ location }) => {
        let urlParams = new URLSearchParams(location.search);
        let probeParam = urlParams.get("probe") || "";
        let query = urlParams.get("q") || "";

        return (
          <ComputerSystems probeParam={probeParam} query={query} {...props} />
        );
      }}
    />
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getComputerSystems: (params) => dispatch(fetchComputerSystems(params)),
    searchComputerSystems: () =>
      dispatch(
        searchComputerSystems(
          0,
          { sortColumn: undefined, sortOrder: undefined },
          []
        )
      ),
    setEntities: (computerSystems) => {
      const items = get(computerSystems, "_items", []).map((item) => {
        return Object.assign({}, item, {
          entityType: entityTypes.COMPUTER_SYSTEM,
        });
      });
      return dispatch(setEntities(items));
    },
  };
};

function mapStateToProps(state) {
  return {
    entityFilter: state.inventoryEntityFilter.quickFilter,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(ComputerSystemsApi);
