import React from 'react'
import './crossLaunchHeader.less'
import Resource from './Resource'

const CrossLaunchHeader = (props) => {
    let view = (
      <aside className='section-controls'>
      </aside>
    )
    if(props.location.pathname.includes('/alarms')) {
      view = (
        <aside className='section-controls'>
          <h2 className='crossLaunch__titlebar'>{// Alarms
        	Resource.get('Alarms')}  </h2>

        </aside>
      )
    }

    if(props.location.pathname.includes('/cabi')) {
      view = (
        <aside className='section-controls'>
          <h2 className='crossLaunch__titlebar'>{// Dashboards
        	Resource.get('Dashboards')}  </h2>

        </aside>
      )
    }
    if(props.location.pathname.includes('/dashboard')) {
      view = (
        <aside className='section-controls'>
          <h2 className='crossLaunch__titlebar'>{// Dashboards
        	Resource.get('Dashboards')}  </h2>

        </aside>
      )
    }

    if(props.location.pathname.includes('/metrics')) {
      view = (
        <aside className='section-controls'>
          <h2 className='crossLaunch__titlebar' style={{visibility:"hidden"}}>{// Metrics
        	Resource.get('Metrics')}  </h2>

        </aside>
      )
    }

   /* if(props.location.pathname.includes('/interfaces')) {
      view = (
        <aside className='section-controls'>
          <h2 className='crossLaunch__titlebar'>{// Interfaces
        	Resource.get('Interfaces')}  </h2>

        </aside>
      )
    }*/

    return view
}
export default CrossLaunchHeader
