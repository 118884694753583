import {
  REQUEST_CONFIGURATION,
  FETCH_CONFIGURATION_SUCCESS,
  FETCH_CONFIGURATION_FAILURE,
} from './actionTypes'
import isEqual from 'lodash/isEqual'

const configuration = (
  state = {
    isFetching: false,
    didInvalidate: false,
    items: {},
  },
  action,
) => {
  switch (action.type) {
    case REQUEST_CONFIGURATION:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false,
      })
    case FETCH_CONFIGURATION_SUCCESS:
      const items = action.configuration
      const updatedState = Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        items: items,
      })
      return isEqual(updatedState, state) ? state : updatedState
    case FETCH_CONFIGURATION_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        errorMessage: action.errorMessage,
        items: {},
      })
    default:
      return state
  }
}

export default configuration
