import {
  FETCH_GROUPSTACK,
  FETCH_GROUPSTACK_SUCCESS,
  FETCH_GROUPSTACK_FAILURE,
  FETCH_STATE,
  FETCH_STATE_SUCCESS,
  FETCH_STATE_FAILURE,
  FETCH_COUNT_STATE,
  FETCH_COUNT_STATE_SUCCESS,
  FETCH_COUNT_STATE_FAILURE,
  SET_TREE_NODE_SELECTED,
  REFRESH_TREE,
  TOGGLE_ADD_GROUP_DIALOG,
  DELETE_GROUP_DIALOG,
  TOGGLE_ADD_DEVICE_TO_GROUP,
  TOGGLE_EDIT_SUB_GROUP
} from "./actionTypes";
import entityTypes from "../../api/entityTypes";
import { isEqual } from "lodash";

const tree_view = (
  state = {
    isOpenAddGroupDialog: false,
    isFetchingGroup: false,
    isFetchingState: false,
    isFetchingCount: false,
    didInvalidateGroupCall: false,
    didInvalidateStateCall: false,
    didInvalidateCountCall: false,
    refreshTree: false,
    groups: [],
    stateData: [],
    countData: {},
    groupError: "",
    stateError: "",
    countError: "",
    treeNode: {
      entityType: entityTypes.ROOT,
      isInterfaceGroup: false,
      element_id: 0,
    },
  },
  action
) => {
  switch (action.type) {
    case FETCH_GROUPSTACK:
      return Object.assign({}, state, {
        isFetchingGroup: true,
        didInvalidateGroupCall: false,
      });
    case FETCH_GROUPSTACK_SUCCESS:
      if (action.isPoller) {
        // if (JSON.stringify(state.groups) == JSON.stringify(action.groups)) {
        if (isEqual(state.groups, action.groups)) {
          return state;
        } else {
          return Object.assign({}, state, {
            groups: action.groups,
          });
        }
      } else {
        return Object.assign({}, state, {
          isFetchingGroup: false,
          didInvalidateGroupCall: false,
          groups: action.groups,
        });
      }
    case FETCH_GROUPSTACK_FAILURE:
      return Object.assign({}, state, {
        isFetchingGroup: false,
        didInvalidateGroupCall: true,
        groupError: action.errorMessage,
        // groups: [],
      });
    case FETCH_STATE:
      return Object.assign({}, state, {
        isFetchingState: true,
        didInvalidateStateCall: false,
      });
    case FETCH_STATE_SUCCESS:
      if (action.isPoller) {
        if (
          // JSON.stringify(state.stateData) == JSON.stringify(action.stateData)
          isEqual(state.stateData, action.stateData)
        ) {
          return state;
        } else {
          return Object.assign({}, state, {
            stateData: action.stateData,
          });
        }
      } else {
        return Object.assign({}, state, {
          isFetchingState: false,
          didInvalidateStateCall: false,
          stateData: action.stateData,
        });
      }
    case FETCH_STATE_FAILURE:
      return Object.assign({}, state, {
        isFetchingState: false,
        didInvalidateStateCall: true,
        stateError: action.errorMessage,
        // stateData: [],
      });
    case FETCH_COUNT_STATE:
      return Object.assign({}, state, {
        isFetchingCount: true,
        didInvalidateCountCall: false,
      });
    case FETCH_COUNT_STATE_SUCCESS:
      if (action.isPoller) {
        if (isEqual(state.countData, action.countData)) {
          return state;
        } else {
          return Object.assign({}, state, {
            countData: action.countData,
          });
        }
      } else {
        return Object.assign({}, state, {
          isFetchingCount: false,
          didInvalidateCountCall: false,
          countData: action.countData,
        });
      }
    case FETCH_COUNT_STATE_FAILURE:
      return Object.assign({}, state, {
        isFetchingCount: false,
        didInvalidateCountCall: true,
        countError: action.errorMessage,
        // countData: [],
      });
    case SET_TREE_NODE_SELECTED:
      return Object.assign({}, state, {
        treeNode: { ...action.treeNode },
      });
    case REFRESH_TREE:
      return Object.assign({}, state, {
        refreshTree: action.refreshTree,
      });
    case TOGGLE_ADD_GROUP_DIALOG:
      return Object.assign({}, state, {
        isToggleAddGroupDialog: action.isToggleAddGroupDialog,
      });

    case DELETE_GROUP_DIALOG:
      return Object.assign({}, state, {
        isDeleteGroupDialog: action.isDeleteGroupDialog,
      });

    case TOGGLE_ADD_DEVICE_TO_GROUP:
      return Object.assign({}, state, {
        toggleAddDeviceToGroup: action.toggleAddDeviceToGroup,
      });

    case TOGGLE_EDIT_SUB_GROUP:
      return Object.assign({}, state, {
        toggleEditSubGroup: action.toggleEditSubGroup,
      });
    default:
      return state;
  }
};

export default tree_view;
