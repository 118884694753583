// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Dashboard Designer
  content["Dashboard Designer"] =
  "Dashboard Designer";

  //Create dashboards using dashboard designer
  content["Create dashboards using dashboard designer"] =
  "Create dashboards using dashboard designer";

  //Settings
  content["Settings"] =
  "Settings";

  // END OF LOCALIZATION

export default content;
