import React from "react";
import {
  FilledCircle,
  FilledHex,
  FilledPolygon,
  FilledSquare,
  FilledRhombus,
  FilledStar01,
  FilledStar02,
  FilledStar03,
  FilledStar04,
  FilledStar05,
  FilledStar06,
  FilledTriangleDown,
  FilledTriangleUp,
  OutlinedHexagon,
  OutlinedPolygon,
  OutlinedRhombus,
  OutlinedStar01,
  OutlinedStar02,
  OutlinedStar03,
  OutlinedStar04,
  OutlinedStar05,
  OutlinedStar06,
  OutlinedTriangleUp,
  OutlinedTriangleDown,
} from "./shapeSVG";

const CustomPlotPoint = (props) => {
  const { cx = 0, cy = 0, color, shape, width = 15, height = 15 } = props;
  let customDot = null;

  switch (shape) {
    case 'filledCircle':
      customDot = (<FilledCircle x={cx - 5} y={cy - 8} color={color} width={width} height={height} />);
      break;
    // case 'FilledSquare':
    //   customDot = (<FilledSquare x={cx - 5} y={cy - 5} color={color} width={15} height={15} />);
    //   break;
    case 'filledHex':
      customDot = (<FilledHex x={cx - 5} y={cy - 5} color={color} width={15} height={15} />);
      break;
    case 'filledPolygon':
      customDot = (<FilledPolygon x={cx - 5} y={cy - 5} color={color} width={15} height={15} />);
      break;
    case 'filledRhombus':
      customDot = (<FilledRhombus x={cx - 5} y={cy - 5} color={color} width={15} height={15} />);
      break;
    case 'filledStar01':
      customDot = (<FilledStar01 x={cx - 5} y={cy - 5} color={color} width={15} height={15} />);
      break;
    case 'filledStar02':
      customDot = (<FilledStar02 x={cx - 5} y={cy - 5} color={color} width={15} height={15} />);
      break;
    case 'filledStar03':
      customDot = (<FilledStar03 x={cx - 5} y={cy - 5} color={color} width={15} height={15} />);
      break;
    case 'filledStar04':
      customDot = (<FilledStar04 x={cx - 5} y={cy - 5} color={color} width={15} height={15} />);
      break;
    case 'filledStar05':
      customDot = (<FilledStar05 x={cx - 5} y={cy - 5} color={color} width={15} height={15} />);
      break;
    case 'filledStar06':
      customDot = (<FilledStar06 x={cx - 5} y={cy - 5} color={color} width={15} height={15} />);
      break;
    case 'filledTriangleUp':
      customDot = (<FilledTriangleDown x={cx - 5} y={cy - 5} color={color} width={15} height={15} />);
      break;
    case 'filledTriangleDown':
      customDot = (<FilledTriangleUp x={cx - 5} y={cy - 5} color={color} width={15} height={15} />);
      break;
      case 'outlinedCircle':
        customDot = (<FilledCircle x={cx - 5} y={cy - 8} color={color} width={width} height={height} />);
        break;
      case 'outlinedSquare':
        customDot = (<FilledSquare x={cx - 5} y={cy - 5} color={color} width={15} height={15} />);
        break;
      case 'outlinedHex':
        customDot = (<OutlinedHexagon x={cx - 5} y={cy - 5} color={color} width={15} height={15} />);
        break;
      case 'outlinedPolygon':
        customDot = (<OutlinedPolygon x={cx - 5} y={cy - 5} color={color} width={15} height={15} />);
        break;
      case 'outlinedRhombus':
        customDot = (<OutlinedRhombus x={cx - 5} y={cy - 5} color={color} width={15} height={15} />);
        break;
      case 'outlinedStar01':
        customDot = (<OutlinedStar01 x={cx - 5} y={cy - 5} color={color} width={15} height={15} />);
        break;
      case 'outlinedStar02':
        customDot = (<OutlinedStar02 x={cx - 5} y={cy - 5} color={color} width={15} height={15} />);
        break;
      case 'outlinedStar03':
        customDot = (<OutlinedStar03 x={cx - 5} y={cy - 5} color={color} width={15} height={15} />);
        break;
      case 'outlinedStar04':
        customDot = (<OutlinedStar04 x={cx - 5} y={cy - 5} color={color} width={15} height={15} />);
        break;
      case 'outlinedStar05':
        customDot = (<OutlinedStar05 x={cx - 5} y={cy - 5} color={color} width={15} height={15} />);
        break;
      case 'outlinedStar06':
        customDot = (<OutlinedStar06 x={cx - 5} y={cy - 5} color={color} width={15} height={15} />);
        break;
      case 'outlinedTriangleUp':
        customDot = (<OutlinedTriangleDown x={cx - 5} y={cy - 5} color={color} width={15} height={15} />);
        break;
      case 'outlinedTriangleDown':
        customDot = (<OutlinedTriangleUp x={cx - 5} y={cy - 5} color={color} width={15} height={15} />);
        break;
    case 'filledSquare':
    default: customDot = (
      <FilledSquare x={cx - 5} y={cy - 5} color={color} width={15} height={15} />
    )
  }
  //console.log("DATAPOINTS",props.value);
  if(props.value!==null && props.value!==undefined)
  return <FilledSquare x={cx - 5} y={cy - 5} color={color} width={15} height={15} />;
  else
  return <></>;
};

export default CustomPlotPoint;
