import {
  REQUEST_HUBS,
  FETCH_HUBS_SUCCESS,
  FETCH_HUBS_FAILURE,
  FETCH_HUBS,
  POLL_HUBS_START,
  POLL_HUBS_STOP,
  SELECTED_HUB
} from './actionTypes'

export function requestHubs() {
  return {
    type: REQUEST_HUBS
  }
}

export function fetchHubsSuccess(json) {
  return {
    type: FETCH_HUBS_SUCCESS,
    hubs: json
  }
}

export function fetchHubsFailure(error) {
  return {
    type: FETCH_HUBS_FAILURE,
    errorMessage: error.message
  }
}

export function fetchHubs() {
  return {
    type:FETCH_HUBS
  }
}

export function pollHubsStart() {
  //console.log("Start polling hubs in action of pollHubsStart")
  return {
    type:POLL_HUBS_START
  }
}

export function pollHubsStop() {
  //console.log("Stop polling hubs in action for pollHubsStop")
  return {
    type:POLL_HUBS_STOP
  }
}

export function setSelectedHub(hub){
  return{
    type:SELECTED_HUB,
    selectedHub:hub
  }
}



