// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Alarms
  content["Alarms"] =
  "경보";

  // END OF LOCALIZATION

export default content;
