import Resource from "./Resource";
import moment from "moment";
import { get } from "lodash";
import { monitorProbes as probeTitles } from "@uim/uim-common-strings";
import { RobotIcon } from "../ui-components/uim-components";
import { VpnKeyIcon as KeyIcon } from "@material-ui/icons/VpnKey";

export const getTableColumns = () => {
  //Name
  const NAME = Resource.get("Name");
  //alias
  const ALIAS = Resource.get("Alias");
  //devicetype
  const DEVICE = Resource.get("Device Type");
  //caption
  const CAPTION = Resource.get("Caption");
  //desc
  const DESCRIPTION = "Description";
  //dedicated
  const DEDICATED = Resource.get("Dedicated");
  //origin
  const ORIGIN = Resource.get("Origin");
  //latestorigin
  const LATESTORIGIN = Resource.get("Latest Origin");
  //mac
  const MAC = Resource.get("MAC Address");
  //monitor
  const MONITOR_BY = Resource.get("Monitored By");
  //robot
  const HAS_ROBOT = Resource.get("Bus Type");
  //csid
  const CS_ID = Resource.get("CS Id");
  //cskey
  const CS_KEY = Resource.get("CS Key");
  //discover
  const DISCOVERED = Resource.get("Discovered");
  //changetime
  const CHANGED = Resource.get("Last Updated");
  //deleted
  const DELETED = Resource.get("Removed");
  let csCols = [
    {
      //Name
      headerName: NAME,
      headerTooltip: NAME,
      field: "name",
      tooltipField: "name",
      minWidth: 112,
      filter: "agTextColumnFilter",
      sortable: true,
      filterParams: {
        filterOptions: ["contains", "startsWith", "endsWith", "equals"],
        suppressAndOrCondition: true,
        newRowsAction: "keep",
        clearButton: true,
      },
      headerComponentParams: {
        enableMenu: true,
      },
      suppressNavigable: true
    },
    {
      //Alias
      headerName: ALIAS,
      headerTooltip: ALIAS,
      field: "alias",
      tooltipField: "alias",
      minWidth: 100,
      filter: "agTextColumnFilter",
      sortable: true,
      filterParams: {
        filterOptions: ["contains", "startsWith", "endsWith", "equals"],
        suppressAndOrCondition: true,
        newRowsAction: "keep",
        clearButton: true,
      },
      headerComponentParams: {

        enableMenu: false,
      },
      suppressNavigable: true
    },
    {
      //Device Type
      headerName: DEVICE,
      headerTooltip: DEVICE,
      field: "role",
      tooltipField: "role",
      minWidth: 100,
      filter: "customSetFilter",
      filterParams: {
        values: [
          "ApplicationComponent",
          "ApplicationSystem",
          "API Endpoint",
          "AWS DataBase",
          "AWSAutoScalingGroup",
          "Cluster",
          "CiscoUcsManager",
          "DatabaseServer",
          "Device",
          "Firewall",
          "Host",
          "Interface",
          "LoadBalancer",
          "Printer",
          "Router",
          "SecurityAppliance",
          "Storage",
          "StorageSegment",
          "Switch",
          "SwitchRouter",
          "vCenter",
          "VirtualMachine",
          "VirtualMachineHost",
          "WAN",
          "WebServer",
          "WirelessAccessPoint",
        ],
        newRowsAction: "keep",
      },
      sortable: true,
      headerComponentParams: {
        enableMenu: false,
      },
      suppressNavigable: true
    },
    {
      //Caption
      headerName: CAPTION,
      field: "attributes.caption",
      headerTooltip: CAPTION,
      tooltipField: "attributes.caption",
      minWidth: 100,
      sortable: true,
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: ["contains", "startsWith", "endsWith", "equals"],
        suppressAndOrCondition: true,
        newRowsAction: "keep",
        clearButton: true,
      },
      headerComponentParams: {
        enableMenu: false,
      },
      suppressNavigable: true
    },
    {
      //csDescription
      headerName: DESCRIPTION,
      headerTooltip: DESCRIPTION,
      field: "attributes.description",
      tooltipField: "attributes.description",
      minWidth: 100,
      filter: "agTextColumnFilter",
      sortable: true,
      filterParams: {
        filterOptions: ["contains", "startsWith", "endsWith", "equals"],
        suppressAndOrCondition: true,
        newRowsAction: "keep",
        clearButton: true,
      },
      headerComponentParams: {
        enableMenu: false,
      },
      suppressNavigable: true
    },

    {
      //Dedicated
      headerName: DEDICATED,
      headerTooltip: DEDICATED,
      field: "attributes.dedicated",
      tooltipField: "attributes.dedicated",
      minWidth: 100,
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: ["contains", "startsWith", "endsWith", "equals"],
        suppressAndOrCondition: true,
        newRowsAction: "keep",
        clearButton: true,
      },
      sortable: true,
      headerComponentParams: {
        enableMenu: false,
      },
      suppressNavigable: true
    },
    {
      //OS TYPE
      headerName: Resource.get("OS Type"),
      //Operating system type
      headerTooltip: Resource.get("Operating System Type"),
      field: "osType",
      tooltipField: "osType",
      minWidth: 120,
      filter: "agTextColumnFilter",
      cellRenderer: (params) => {
        if (params.data.osType != null) return params.data.osType;
        else return "";
      },
      filterParams: {
        filterOptions: ["contains", "startsWith", "endsWith", "equals"],
        suppressAndOrCondition: true,
        newRowsAction: "keep",
        clearButton: true,
      },
      sortable: true,
      headerComponentParams: {
        enableMenu: false,
      },
      suppressNavigable: true
    },

    {
      //osname
      headerName: Resource.get("OS Name"),
      //Operating system name
      headerTooltip: Resource.get("Operating System Name"),
      field: "osName",
      tooltipField: "osName",
      minWidth: 120,
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: ["contains", "startsWith", "endsWith", "equals"],
        suppressAndOrCondition: true,
        newRowsAction: "keep",
        clearButton: true,
      },
      sortable: true,
      headerComponentParams: {
        enableMenu: false,
      },
      suppressNavigable: true
    },
    {
      //osversion
      headerName: Resource.get("OS Version"),
      //Operating system version
      headerTooltip: Resource.get("Operating System Version"),
      field: "osVersion",
      tooltipField: "osVersion",
      minWidth: 120,
      filter: "agTextColumnFilter",
      cellRenderer: (params) => {
        if (params.data.osVersion != null) return params.data.osVersion;
        else return "";
      },
      filterParams: {
        filterOptions: ["contains", "startsWith", "endsWith", "equals"],
        suppressAndOrCondition: true,
        newRowsAction: "keep",
        clearButton: true,
      },
      sortable: true,
      headerComponentParams: {
        enableMenu: false,
      },
      suppressNavigable: true
    },
    {
      //osdesc
      headerName: Resource.get("OS Description"),
      //Operating system desc
      headerTooltip: Resource.get("Operating System Description"),
      field: "osDescription",
      tooltipField: "osDescription",
      minWidth: 120,
      filter: "agTextColumnFilter",
      cellRenderer: (params) => {
        if (params.data.osDescription != null)
          return params.data.osDescription;
        else return "";
      },
      filterParams: {
        filterOptions: ["contains", "startsWith", "endsWith", "equals"],
        suppressAndOrCondition: true,
        newRowsAction: "keep",
        clearButton: true,
      },
      sortable: true,
      headerComponentParams: {
        enableMenu: false,
      },
      suppressNavigable: true
    },
    {
      //Origin
      headerName: ORIGIN,
      headerTooltip: ORIGIN,
      field: "attributes.origin",
      tooltipField: "attributes.origin",
      minWidth: 110,
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: ["contains", "startsWith", "endsWith", "equals"],
        suppressAndOrCondition: true,
        newRowsAction: "keep",
        clearButton: true,
      },
      sortable: true,
      headerComponentParams: {
        enableMenu: false,
      },
      suppressNavigable: true
    },
    {
      //Latest Origin
      headerName: LATESTORIGIN,
      headerTooltip: LATESTORIGIN,
      field: "attributes.latestOrigin",
      tooltipField: "attributes.latestOrigin",
      minWidth: 110,
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: ["contains", "startsWith", "endsWith", "equals"],
        suppressAndOrCondition: true,
        newRowsAction: "keep",
        clearButton: true,
      },
      sortable: true,
      headerComponentParams: {
        enableMenu: false,
      },
      suppressNavigable: true
    },
    {
      //macaddress
      headerName: MAC,
      headerTooltip: MAC,
      field: "attributes.mac",
      tooltipField: "attributes.mac",
      minWidth: 110,
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: ["contains", "startsWith", "endsWith", "equals"],
        suppressAndOrCondition: true,
        newRowsAction: "keep",
        clearButton: true,
      },
      sortable: true,
      headerComponentParams: {
        enableMenu: false,
      },
      suppressNavigable: true
    },
    {
      //Origin
      headerName: Resource.get("User Tag 1"),
      //usertag1
      headerTooltip: Resource.get("UserTag 1"),
      field: "attributes.user_tag_1",
      tooltipField: "attributes.user_tag_1",
      minWidth: 130,
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: ["contains", "startsWith", "endsWith", "equals"],
        suppressAndOrCondition: true,
        newRowsAction: "keep",
        clearButton: true,
      },
      sortable: true,
      headerComponentParams: {
        enableMenu: false,
      },
      suppressNavigable: true
    },
    {
      //tag2
      headerName: Resource.get("User Tag 2"),
      //usertag2
      headerTooltip: Resource.get("UserTag 2"),
      field: "attributes.user_tag_2",
      tooltipField: "attributes.user_tag_2",
      minWidth: 130,
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: ["contains", "startsWith", "endsWith", "equals"],
        suppressAndOrCondition: true,
        newRowsAction: "keep",
        clearButton: true,
      },
      sortable: true,
      headerComponentParams: {
        enableMenu: false,
      },
      suppressNavigable: true
    },
    {
      //IP address
      headerName: Resource.get("IP Address"),
      //ipaddr
      headerTooltip: Resource.get("IP address"),
      field: "ipAddresses",
      tooltipField: "ipAddresses",
      minWidth: 100,
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: ["contains", "startsWith", "endsWith", "equals"],
        suppressAndOrCondition: true,
        newRowsAction: "keep",
        clearButton: true,
      },
      sortable: true,
      headerComponentParams: {
        enableMenu: false,
      },
      valueGetter: (model) => {
        let probes = get(model, "data.ipAddresses", []);
        return probes.join(", ");
      },
      suppressNavigable: true
    },
    {
      //Monitored by
      headerName: MONITOR_BY,
      //monitireby
      headerTooltip: MONITOR_BY,
      field: "attributes.monitoredBy",
      tooltipField: "attributes.monitoredBy",
      filter: "customSetFilter",
      filterParams: {
        values: Object.keys(probeTitles),
        doesFilterPass: function (data, node, selectedValues) {
          let len = selectedValues.length,
            isPresent = false;
          let probes = get(data, "attributes.monitoredBy", []);
          for (let i = 0; i < len; i++) {
            if (probes.includes(selectedValues[i])) {
              isPresent = true;
              break;
            }
          }
          return isPresent;
        },
      },
      minWidth: 100,
      sortable: true,
      headerComponentParams: {
        enableMenu: false,
      },
      valueGetter: (model) => {
        let probes = get(model, "data.attributes.monitoredBy", []);
        return probes.join(", ");
      },
      suppressNavigable: true
    },
    {
      headerName: HAS_ROBOT,
      field: "busType",
      filter: "customSetFilter",
      filterParams: {
        values: ["Yes", "No"],
        valueToCompare: function (data, node) {
          return data.busType === "hub" || data.busType === "robot"
            ? "Yes"
            : "No";
        },
      },
      cellRendererFramework: (model) => {
        const item = model.data;
        const hasRobot = item.busType === "hub" || item.busType === "robot";
        return hasRobot ? (
          <div title={hasRobot}>
            <RobotIcon role="figure" title={hasRobot} />
          </div>
        ) : null;
      },
      //Has Robot
      headerTooltip: HAS_ROBOT,
      suppressMovable: true,
      suppressSizeToFit: true,
      suppressSorting: true,
      suppressResize: true,
      width: 100,
      headerComponentParams: {
        //Has Robot
        //icon: <RobotIcon title={Resource.get("Has Robot")} />,
        enableMenu: false,
      },
      valueGetter: (model) => {
        const item = model.data;
        const hasRobot = item.busType === "hub" || item.busType === "robot";
        return hasRobot;
      },
      suppressNavigable: true
    },
    {
      //csid
      headerName: CS_ID,
      headerTooltip: CS_ID,
      field: "attributes.cs_id",
      tooltipField: "attributes.cs_id",
      minWidth: 100,
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: ["contains", "startsWith", "endsWith", "equals"],
        suppressAndOrCondition: true,
        newRowsAction: "keep",
        clearButton: true,
      },
      sortable: true,
      headerComponentParams: {
        enableMenu: false,
      },
      suppressNavigable: true
    },
    {
      //cskey
      headerName: CS_KEY,
      headerTooltip: CS_KEY,
      field: "attributes.cs_key",
      tooltipField: "attributes.cs_key",
      minWidth: 100,
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: ["contains", "startsWith", "endsWith", "equals"],
        suppressAndOrCondition: true,
        newRowsAction: "keep",
        clearButton: true,
      },
      sortable: true,
      headerComponentParams: {
        enableMenu: false,
      },
      suppressNavigable: true
    },
    {
      //Discovered
      headerName: DISCOVERED,
      headerTooltip: DISCOVERED,
      field: "attributes.create_time",
      sortingOrder: ["asc", "desc", null],
      filter: "customSetFilter",
      filterParams: {
        values: [
          "< 4 hours",
          "< 10 hours",
          " < 1 day",
          " < 2 days",
          " < 1 week",
          " < 1 month",
          " < 3 months",
          " < 6 months",
          " < 1 year",
          " > 1 year",
        ],
        doesFilterPass: function (data, node, selectedValues) {
          const createdTime = parseInt(
            get(data, "attributes.create_time[0]", 0),
            10
          );
          let currentTime = moment();
          let presentTime = currentTime.valueOf();
          let fourHrFromNow = moment().subtract(4, "h");
          let tenHrFromNow = moment().subtract(10, "h");
          let oneDyFromNow = moment().subtract(1, "d");
          let twoDyFromNow = moment().subtract(2, "d");
          let oneWkFromNow = moment().subtract(1, "w");
          let oneMnthFromNow = moment().subtract(1, "M");
          let threeMnthFromNow = moment().subtract(3, "M");
          let sixMnthFromNow = moment().subtract(6, "M");
          let oneYrFromNow = moment().subtract(1, "y");

          let len = selectedValues.length,
            isPresent = false;
          for (let i = 0; i < len; i++) {
            if (
              selectedValues[i] === "< 4 hours" &&
              createdTime > fourHrFromNow
            ) {
              isPresent = true;
              break;
            } else if (
              selectedValues[i] === "< 10 hours" &&
              createdTime > tenHrFromNow
            ) {
              isPresent = true;
              break;
            } else if (
              selectedValues[i] === " < 1 day" &&
              createdTime > oneDyFromNow
            ) {
              isPresent = true;
              break;
            } else if (
              selectedValues[i] === " < 2 days" &&
              createdTime > twoDyFromNow
            ) {
              isPresent = true;
              break;
            } else if (
              selectedValues[i] === " < 1 week" &&
              createdTime > oneWkFromNow
            ) {
              isPresent = true;
              break;
            } else if (
              selectedValues[i] === " < 1 month" &&
              createdTime > oneMnthFromNow
            ) {
              isPresent = true;
              break;
            } else if (
              selectedValues[i] === " < 3 months" &&
              createdTime > threeMnthFromNow
            ) {
              isPresent = true;
              break;
            } else if (
              selectedValues[i] === " < 6 months" &&
              createdTime > sixMnthFromNow
            ) {
              isPresent = true;
              break;
            } else if (
              selectedValues[i] === " < 1 year" &&
              createdTime > oneYrFromNow
            ) {
              isPresent = true;
              break;
            } else if (
              selectedValues[i] === " > 1 year" &&
              createdTime < oneYrFromNow
            ) {
              isPresent = true;
              break;
            }
          }
          return isPresent;
        },
      },
      minWidth: 120,
      sortable: true,
      headerComponentParams: {
        enableMenu: false,
      },
      cellRendererFramework: (model) => {
        const createdTime = parseInt(
          get(model, "data.attributes.create_time[0]", 0),
          10
        );
        const time = moment(createdTime).fromNow();
        const formattedTime = moment(createdTime).format(
          "MMMM Do YYYY, h:mm a"
        );
        let view = (
          <span
            className="ag-cell"
            style={{ width: "100%" }}
            title={formattedTime}
          >
            {time}
          </span>
        );
        if (!createdTime) {
          view = null;
        }
        return view;
      },
      suppressNavigable: true
    },
    {
      headerName: "Has KeyIcon",
      //  field: "",
      filter: "customSetFilter",
      filterParams: {
        values: ["Yes", "No"],
        valueToCompare: function (data, node) {
          if (
            (data.attributes && data.attributes.wmi_profile != null) ||
            (data.attributes && data.attributes.shell_profile != null)
          ) {
            return "Yes";
          } else {
            return "No";
          }
        },
      },
      cellRendererFramework: (model) => {
        const item = model.data;
        const hasKeyIcon =
          (item.attributes && item.attributes.wmi_profile != null) ||
          (item.attributes && item.attributes.shell_profile != null);
        return hasKeyIcon ? (
          <div title={hasKeyIcon}>
            <KeyIcon title={hasKeyIcon} />
          </div>
        ) : null;
      },
      //Has KeyIcon
      headerTooltip: Resource.get("Has KeyIcon"),
      suppressMovable: true,
      suppressSizeToFit: true,
      suppressSorting: true,
      suppressResize: true,
      width: 100,
      headerComponentParams: {
        //Has KeyIcon
        //icon: <KeyIcon title={Resource.get("Has KeyIcon")} />,
        enableMenu: false,
      },
      valueGetter: (model) => {
        const item = model.data;
        const hasKeyIcon =
          (item.attributes && item.attributes.wmi_profile != null) ||
          (item.attributes && item.attributes.shell_profile != null);
        return hasKeyIcon;
      },
      suppressNavigable: true
    },
    {
      headerName: CHANGED,
      headerTooltip: CHANGED,
      field: 'attributes.change_time',
      cellRendererFramework: (model) => {
        const changedTime = parseInt(
          get(model, "data.attributes.change_time[0]", 0),
          10
        );
        const formattedTime = moment(changedTime).format("DD/MM/YYYY");
        return <div><span title={formattedTime}>{formattedTime}</span></div>;
      },
      hide: false,
      sortable: true,
      comparator: this?.honorBackendCompare,
      filter: 'agDateColumnFilter',
      filterParams: {
        comparator: function (filterLocalDateAtMidnight, cellValue) {
          const changedTime = parseInt(cellValue, 10);
          const dateAsString = moment(changedTime).format("DD/MM/YYYY");
          if (dateAsString == null) return -1;
          var dateParts = dateAsString.split('/');
          var cellDate = new Date(
            Number(dateParts[2]),
            Number(dateParts[1]) - 1,
            Number(dateParts[0])
          );
          if (filterLocalDateAtMidnight.getTime() == cellDate.getTime()) {
            return 0;
          }
          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          }
          if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          }
        },
        filterOptions: ["greaterThan", "greaterThanOrEqual", "lessThan", "lessThanOrEqual"],
        browserDatePicker: true,
        suppressAndOrCondition: true,
        clearButton: true
      },
      headerComponentParams: {
        enableMenu: false,
      },
      suppressNavigable: true


    },
    {
      headerName: DELETED,
      headerTooltip: DELETED,
      sortable: true,
      cellRendererFramework: (params) => {
        let value = (params.data && params.data.attributes) ?
          params.data.attributes.deleted ? 'True' : 'False'
          : 'False';
        return (
          <div>{value}</div>)
      },
      minWidth: 100,
      filter: "customSetFilter",
      filterParams: {
        values: ['True', 'False'],
        valueToCompare: function (data, node) {
          if (data.attributes && data.attributes.deleted) {
            return 'True';
          } else {
            return 'False';
          }
        },
        clearButton: true
      },
      valueGetter: (model) => {
        const item = model.data;
        let deleted = false;
        deleted = (item.attributes && item.attributes.deleted != null);
        return deleted;
      },
      headerComponentParams: {
        enableMenu: false,
      },
      suppressNavigable: true

    },
  ];
  return csCols;
}

export const getDefaultColumns = () => {
  let columns = getTableColumns();
  let selectedColumns = [];
  let storedColumnList = [];
  storedColumnList.push("Name");
  storedColumnList.push("Device Type");
  storedColumnList.push("OS Name");
  storedColumnList.push("Latest Origin");
  storedColumnList.push("IP Address");
  storedColumnList.push("Monitored By");
  storedColumnList.push("Bus Type");
  storedColumnList.push("Discovered");

  storedColumnList.forEach((data) => {
    selectedColumns.push(columns.find((col) => col.headerName == data));
  });

  return [selectedColumns, storedColumnList];
}