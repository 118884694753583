import * as React from 'react';
import { SvgIcon } from '@mineral/core';

export const OutlinedTriangleUp = React.forwardRef(
  (props, ref) => (
    <SvgIcon data-testid="LearnIcon" viewBox="0 0 40 40" {...props} ref={ref}>
      <path d="M7.44636 32L20 10.0311L32.5536 32H7.44636Z" fill={props.color} stroke="#1D5BBF" stroke-width="4" />
    </SvgIcon>
  )
);
