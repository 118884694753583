import React, { Component } from 'react'
import {  
 Button as FlatButton,  
  MenuItem,
  Select as SelectField,
  TextField,
  FormControl,FormHelperText, InputLabel
} from '@mineral/core'
import {Form} from "../../ui-components/uim-components"
import {CircularProgress} from '@mineral/core'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import isIP from 'validator/lib/isIP'
import UtilPoller from './../../../utils/poller'
import { requestHubs } from './../../../api/hubs/actions'
import connect from './../../../utils/connect'
import { saveRobots } from './../../../api/robots/actions'
import {
  fetchRobotsStatus,
  pollFetchRobotsStatus,
} from './../../../api/robotsStatus/actions'


const RobotStatus = (getRobotsStatus, pollRobotsStatus, adeRobotAddress, adeJobIds) => {
  let poller = null
  return new Promise((resolve, reject) => {
    const _resolve = (response) => {
      poller.stop()
      resolve(response)
    }
    const _reject = (error) => {
      poller.stop()
      reject(error)
    }
    const _failed = (error) => {
      _reject(error)
    }
    const _success = (response) => {
      const robotStatus = response.robots._items[0]
      _resolve(robotStatus)
    }
    const _successResponse = (response) => {
      const robotStatus = response.robots._items[0]
      switch (robotStatus.state) {
        case 'SUCCEEDED':
          _success(response)
          break
        case 'FAILED':
          _failed(robotStatus)
          break
        case 'IN_PROGRESS':
        default:
          break
      }
    }
    poller = UtilPoller(() => pollRobotsStatus(adeRobotAddress, adeJobIds), {
      timeout: 15*1000,
      pollFailure: error => _failed(error),
      pollSuccess: response => _successResponse(response),
    })
    poller.start()
    // query immediately to get initial check prior to waiting
    getRobotsStatus(adeRobotAddress, adeJobIds)
      .then(response => _successResponse(response))
      .catch(error => _failed(error))
  })
}

const defaultModel = {
  hub: '',
  hostname: '',
  profile: null,
  arch: null,
  username: '',
  password: '',
  sudoPassword: '',
}

const DEBIAN_32_BIT_ERROR = 'Only 64 bit package is allowed for debian based systems.'
const DISABLE_SUDO_PASSWORD_TEXT = 'Sudo Password is only applicable for Linux systems where username is not "root".'
const WINDOWS_ON_UNIX_ERROR = 'Windows Relay Hub Required.'

class McsRobotDeployForm extends Component {
  state = {
    saving: false,
    hub: defaultModel.hub,
    fields: Object.assign({}, defaultModel, {
      hostname: get(this.props.device, 'devIp', defaultModel.hostname),
    }),
  }
  _debianBase = () => {
    const profile = get(this.state, 'fields.profile')
    return profile === 'debian' || profile === 'ubuntu'
  }
  _debian32Bit = (val = this.state.fields.arch) => {
    return this._debianBase() && (val === '32')
  }
  _pollRobotStatus = (adeRobotAddress, adeJobIds) => {
    return RobotStatus(this.props.getRobotsStatus, this.props.pollRobotsStatus, adeRobotAddress, adeJobIds)
  }
  _saveRobot = (robot) => {
    return this.props.saveRobots([robot])
      .then(response => {
        const robot = response.data._items[0]
        return robot
      })
      .then(savedRobot => {
        // adeRobotAddress is hubRobotAddress
        const adeRobotAddress = savedRobot.hubRobotAddress
        const adeJobIds = [savedRobot.adeJobId]
        return this._pollRobotStatus(adeRobotAddress, adeJobIds)
      })
  }
  _sudoPasswordDisabled = () => {
    const profile = get(this.state, 'fields.profile')
    const username = get(this.state, 'fields.username')
    const sudoPassword = get(this.state, 'fields.sudoPassword')
    return  sudoPassword && (profile === 'windows' || username === 'root')
  }
  componentWillMount() {
    this.props.getHubs()
  }
  componentWillReceiveProps(nextProps) {
    const hubsChanged = !isEqual(this.props.hubs, nextProps.hubs)
    if (hubsChanged) {
      this.setState({ hub: nextProps.hubs[0] })
    }
  }
  handleSave = (robot) => {
    const hub = this.state.hub || {}
    const _robot = {
      hostname: robot.hostname,
      robotIp: robot.hostname,
      profile: robot.profile,
      arch: robot.arch,
      username: robot.username,
      password: robot.password,
      hubIp: hub.hubIp,
      hubRobotAddress: hub.hubRobotAddress,
    }
    if (robot.sudoPassword) {
      _robot['sudoPassword'] = robot.sudoPassword
    }
    return this._saveRobot(_robot)
  }
  incompatibleOs = (val = this.state.fields.profile) => {
    return get(this.state.hub, 'osMajor') !== 'Windows' && val === 'windows'
  }
  render() {
    return (
      <Form
        fields={
          Object.assign({}, this.state.fields, {
            hub: get(this.state, 'hub.hubRobotName'),
          })
        }
        actionsContainerStyle={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
        actions={[
          <FlatButton
          variant= 'text'
            children='Cancel'
            color="primary"
            onClick={this.props.close || (() => {})}
          />,
          <FlatButton
            children='Deploy'
            disabled={this.state.saving}
           color="primary"
           variant= 'text'
            type='submit'>
            {this.state.saving ? 
              <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><CircularProgress style={{color: '#3272D9'}} /></div>
              : null}
          </FlatButton>
        ]}
        onChange={form => {
          const hub = this.props.hubs.find(hub => hub.hubRobotName === form.state.fields.hub)
          this.setState({
            fields: form.state.fields,
            hub: hub,
          })
          this.props.handleError(false)
        }}
        validate={(fields) => {
          const robot = {
            arch: fields.arch,
            hostname: fields.hostname,
            password: fields.password,
            profile: fields.profile,
            sudoPassword: fields.sudoPassword,
            username: fields.username,
            nonAdminUserName: fields.nonAdminUserName,
            nonAdminUserPassword:fields.nonAdminUserPassword
          }
          const robotMatchesSchema = (robot) => {
            const schema = [
              'hostname',
              'profile',
              'arch',
              'username',
              'password',
            ]
            return schema.reduce((acc, attribute) => {
              return acc &&
                Object.keys(robot).includes(attribute) && !isEmpty(robot[attribute])
            }, true)
          }
          // return on first robot found that doesn't match schema
          const hasSchemaErrors = (robots) =>
            !!robots.find((robot) => !robotMatchesSchema(robot))

          return hasSchemaErrors([robot]) ||
            this.incompatibleOs(fields.profile) ||
            this._debian32Bit(fields.arch) ||
            this._sudoPasswordDisabled()
        }}
        onFormSubmit={(form) => {
          const robot = form.state.fields
          this.setState({ saving: true })
          this.handleSave(robot)
            .then(response => {
              form.setState({
                fields: defaultModel,
                saving: false,
              })
              if (this.props.close) {
                this.props.close()
              }
            }, error => {
              console.error(error)
              this.setState({ saving: false })
              this.props.handleError(error)
            })
        }}>
        <p>To monitor this device a local robot must be installed.</p>
        <p>A robot deployment usually takes less than 1 minute per device.</p>
        <FormControl>
        <InputLabel id="hub-select">Hub</InputLabel>
        <SelectField
          labelId = "hub-select"
          id="hub-select"
          name='hub'
          disabled={this.state.saving}
          //placeholder='Hub'
          fullWidth={true}
          validate={val => (val ? false : 'Hub Required')}>
          {(this.props.hubs || []).map((hub, idx) => {
            return <MenuItem value={hub.hubRobotName} children={hub.hubRobotName} key={idx} />
          })}
        </SelectField>        
        </FormControl>
        <br />
        <TextField
          autoFocus={true}
          name='hostname'
          disabled={this.state.saving}
          placeholder='123.123.123.123'
          fullWidth={true}
          validate={val => {
            return val && isIP(val) ? false : 'IP Address Required'
          }}
        />
        <br />
        <FormControl>
        <InputLabel id="os-select">Operating system</InputLabel>
        <SelectField
        labelId = "os-select"
         id="os-select"         
          style={{
            width: '160px',
            marginRight: '1.5rem',
          }}
          name='profile'
          disabled={this.state.saving}
         // helperText={this.incompatibleOs() ? WINDOWS_ON_UNIX_ERROR : null}
         // placeholder='Operating system'
          validate={val => {
            const errorMessage = this.incompatibleOs(val) ? WINDOWS_ON_UNIX_ERROR : 'OS Required'
            return val ? false : errorMessage
          }}>
          <MenuItem value='centos' children='CentOS' />
          <MenuItem value='debian' children='Debian' />
          <MenuItem value='opensuse' children='openSUSE' />
          <MenuItem value='rhel' children='RHEL' />
          <MenuItem value='suse' children='SUSE' />
          <MenuItem value='ubuntu' children='Ubuntu' />
          <MenuItem
            value='windows'
            children='Windows'
            disabled={get(this.state.hub, 'osMajor') !== 'Windows'} />
        </SelectField>
        <FormHelperText>{this.incompatibleOs() ? WINDOWS_ON_UNIX_ERROR : null}</FormHelperText>
        </FormControl>

        <FormControl>
        <InputLabel id="architecture">Architecture</InputLabel>
        <SelectField
          labelId = "architecture"
          id="architecture" 
          style={{
            width: '120px',
          }}
          name='arch'
          disabled={this.state.saving}
         // helperText={this._debian32Bit() ? DEBIAN_32_BIT_ERROR : null}
          //placeholder='Architecture'
          validate={val => {
            const errorMessage = this._debian32Bit(val) ? DEBIAN_32_BIT_ERROR : 'Architecture Required'
            return val ? false : errorMessage
          }}>
          <MenuItem value='64' children='64-bit' />
          <MenuItem
            value='32'
            children='32-bit'
            disabled={this._debianBase()} />
        </SelectField>
        <FormHelperText>{this._debian32Bit() ? DEBIAN_32_BIT_ERROR : null}</FormHelperText>
        </FormControl>
        <br />
        <TextField
          placeholder='Username'
          name='username'
          disabled={this.state.saving}
          fullWidth={true}
          validate={val => (val ? false : 'Username Required')}
        />
        <br />
        <TextField
          placeholder='Password'
          name='password'
          type='password'
          disabled={this.state.saving}
          fullWidth={true}
          validate={val => (val ? false : 'Password Required')}
        />
       
        <br />
        <TextField
          placeholder='Sudo password (optional)'
          name='sudoPassword'
          type='password'
          value={this.state.fields.sudoPassword}
          helperText={this._sudoPasswordDisabled() ? DISABLE_SUDO_PASSWORD_TEXT : null}
          disabled={this.state.saving}
          fullWidth={true}
        />
      </Form>
    )
  }
}


const mapStateToProps = (state) => {
  return {
    hubs: state.hubs.items,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    getHubs: () => dispatch(requestHubs()),
    saveRobots: (data) => dispatch(saveRobots(data)),
    // adeRobotAddress is hubRobotAddress
    getRobotsStatus: (adeRobotAddress, adeJobIds) =>
      dispatch(fetchRobotsStatus({
        adeRobotAddress: adeRobotAddress,
        adeJobIds: adeJobIds,
      })),
    pollRobotsStatus: (adeRobotAddress, adeJobIds) =>
      dispatch(pollFetchRobotsStatus({
        adeRobotAddress: adeRobotAddress,
        adeJobIds: adeJobIds,
      })),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(McsRobotDeployForm)