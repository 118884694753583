import React, { useEffect } from "react";
import {
  Radio,
  RadioGroup,
  Popover,
  List,
  ListItem,
  ListItemText,
  Button,
  Typography,FormControlLabel,FormControl,
  DialogContent,Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  DialogContentText,
} from "@mineral/core";
import { Tooltip } from "@mineral/core";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from '@mui/styles'
import { useDispatch, useSelector } from "react-redux";
import "./PerformanceLayoutSelection.less";
import PerformanceLayoutHost from "./hostLayout/PerformanceLayoutHost";
import PerformanceLayoutTarget from "./targetLayout/PerformanceLayoutTarget";
import AdvancedFilterDialog from "./filterDialog/AdvancedFilterDialog";
import {
  setPrdSource,
  clearQosSelectionData,
  addNewPreparedCharts,
  DeleteChartFilter,
  addNewPreparedFilterCharts,
} from "../../../../../../api/performanceReports/actions";
import PrdFilterRadioButton from "./PrdFilterRadioButton";

const useStyles = makeStyles((theme) => ({
  formLabel: {
    marginRight: 12,
  },
}));

const PerformanceLayoutSelection = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [radioButton, setRadioButton] = React.useState("host");
  const [filterButton, setFilterButton] = React.useState("");
  const [selectedCheckbox, setSelectedCheckbox] = React.useState([]);
  const [value, setValue] = React.useState("");
  const [isOpenButtonPopover, setButtonPopover] = React.useState(null);
  const [openDeleteConfirmation,setopenDeleteConfirmation] = React.useState(false)
  const [filterId,setFilterId]= React.useState(null);

  const getChartList = (rows) => {
    let chartList = [];
    for (let i = 0; i < rows.length; i++) {
      if (rows[i].chart.length) {
        for (let j = 0; j < rows[i].chart.length; j++) {
          chartList = [...chartList, rows[i].chart[j]];
        }
      }
    }
    return chartList;
  };
  const chartData = useSelector((state) =>
    getChartList(state.performanceReports.chartData.State)
  );
  const chartList = useSelector((state) =>
    getChartList(state.performanceReports.chartData.State.page.row)
  );
  const filterList = useSelector(
    (state) => state.performanceReports.chartData.State.groups.group
  );

  const handleChangeRadio = (event) => {
    setRadioButton(event.target.value);
    dispatch(setPrdSource(event.target.value));
  };

  const clearSelection = (event) => {
    dispatch(clearQosSelectionData());
    dispatch(addNewPreparedCharts(null));
  };

  useEffect(() => {
    return clearSelection();
  }, []);

  const addCharts = (id) => {
    if (radioButton === "savedFilter") {
      let addChartRequests = [
        {
          chartId: id,
          data: filterList.find((f) => f.filterId == filterButton),
        },
      ];
      dispatch(addNewPreparedFilterCharts(addChartRequests));
    } else {
      let addChartRequests = [{ chartId: id, data: selectedCheckbox }];
      dispatch(addNewPreparedCharts(addChartRequests));
    }
  };
  const deleteFilter = (filterId) => {
    //dispatch(DeleteChartFilter(filterId));
    setFilterId(filterId);
    setopenDeleteConfirmation(true);
  };
  return (
    <>
    <Dialog
        open={openDeleteConfirmation}
        onClose={() => setopenDeleteConfirmation(false)}
        aria-labelledby="alert-chart-clear-dialog-title"
        aria-describedby="alert-chart-clear-dialog-description"
      >
        <DialogTitle component="h1">
         
            <Typography
             
              variant="h4"
              component="div"
            >
              {"Delete filter"}
            </Typography>
           
          
        </DialogTitle>
        <div style={{height:'0px'}}>
        <Tooltip title="Close">
        <IconButton style={{top:'-50px',float:'right',marginRight:'8px'}}
              aria-label="Close"
              autoFocus
              onClick={() => setopenDeleteConfirmation(false)}
            >
              <CloseIcon />
            </IconButton> 
            </Tooltip>
            </div>
        <DialogContent>
          <DialogContentText id="alert-chart-clear-dialog-description">
            <pre>
              {`Do you want to delete filter?`}
            </pre>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            onClick={() => setopenDeleteConfirmation(false)}
          >
            Cancel
          </Button>
          <Button variant="contained" 
            onClick={() => {
              setopenDeleteConfirmation(false);            
              dispatch(DeleteChartFilter(filterId));
            }}
          >
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    <div
      className={`${
        props.isToggleQos ? "open-width" : "close-width"
      } selection-wrapper`}
    >
      <div
        style={{
          padding: "11px",
          height: "100%",
          overflow: "auto",
          flex: "1 1 auto",
        }}
      >
        <div>
          <div>
            <h2 className="qosSelection-title">QoS Selection</h2>
          </div>
          <FormControl component="fieldset">
            <RadioGroup
              row
              aria-label="QoS Selection"
              name="QoS Selection"
              value={radioButton}
              onChange={handleChangeRadio}
            >
              <FormControlLabel style={{marginRight:'24px'}}
                className={classes.formLabel}
                value="host"
                control={
                  <Radio
                    color="primary"
                    size="small"
                    style={{marginRight:'4px'}}
                    inputProps={{ id: "first-content-element" }}
                  />
                }
                label="Host"
              />

              <FormControlLabel style={{marginRight:'24px'}}
                className={classes.formLabel}
                value="target"
                control={<Radio  style={{marginRight:'4px'}} color="primary" size="small" />}
                label="Target"
              />

              <FormControlLabel
                className={classes.formLabel}
                value="savedFilter"
                control={<Radio  style={{marginRight:'4px'}} color="primary" size="small" />}
                label="Filter List"
              />
            </RadioGroup>
          </FormControl>
        </div>

        <div>
          {radioButton === "host" && (
            <PerformanceLayoutHost
              isSelectedTarget={(selectedCheckbox) =>
                setSelectedCheckbox(selectedCheckbox)
              }
              isQosValue={(value) => setValue(value)}
            />
          )}

          {radioButton === "target" && (
            <PerformanceLayoutTarget
              isSelectedHost={(selectedCheckbox) =>
                setSelectedCheckbox(selectedCheckbox)
              }
              isTargetValue={(value) => setValue(value)}
            />
          )}
          {radioButton === "savedFilter" && (
            <FormControl component="filterSet" style={{ width: "100%" }}>
              <div>
                {filterList &&
                  filterList.length > 0 &&
                  filterList.map((filter, index) => {
                    return (
                      <PrdFilterRadioButton
                        value={filterButton}
                        deleteFilter={deleteFilter}
                        onChange={(val) => setFilterButton(val)}
                        filter={filter}
                      />
                    );
                  })}
              </div>
            </FormControl>
          )}
          {radioButton === "savedFilter" ? (
            filterList && filterList.length == 0 ? (
              <Typography
                variant="caption"
                classes={{ caption: "no-filter-text" }}
              >
                <pre
                  style={{ color: "#747474" }}
                >{`Create a filter \n from \n Advanced Filter Option`}</pre>
              </Typography>
            ) : null
          ) : null}
        </div>
      </div>

      <div className="btn-wrapper" style={{padding:"1px 10px 10px 10px"}}>
        <div className="btn-wrapper_filter">
          {radioButton === "savedFilter" && <AdvancedFilterDialog />}
        </div>

        <div className="btn-wrapper_secondary">
          <Tooltip title="Cancel">
            <span>
              <Button
                color="primary"
                style={{ height: "32px" }}
                variant="contained"
                type="button"
                disabled={!selectedCheckbox.length && !value}
                onClick={clearSelection}
              >
                Cancel
              </Button>
            </span>
          </Tooltip>
          <Tooltip title="Create Chart to">
            <span>
              <Button
                
                disabled={
                  radioButton === "savedFilter"
                    ? filterButton.length == 0
                    : selectedCheckbox.length === 0 ||
                      !value ||
                      chartList.length === 0
                }
               
                aria-describedby="btn-wrapper-popover"
                variant="contained"
                onClick={(event) => {
                  setButtonPopover(event.currentTarget);
                }}
              >
                <span>Create Chart to</span>
                <div
                  className={
                    isOpenButtonPopover
                      ? "button-chart-triangle active"
                      : "button-chart-triangle"
                  }
                ></div>
              </Button>
            </span>
          </Tooltip>
        </div>
      </div>
      <Popover
        id="btn-wrapper-popover"
        open={Boolean(isOpenButtonPopover)}
        anchorEl={isOpenButtonPopover}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        onClose={() => setButtonPopover(null)}
      >
        <List component="div" style={{ maxHeight: "280px", overflow: "auto" }}>
          {chartList.map((chart, index) => {
            return (
              <ListItem
                key={index}
                button
                onClick={() => {
                  setButtonPopover(null);
                  addCharts(chart.chartId);
                }}
              >
                <ListItemText primary={chart.title} />
              </ListItem>
            );
          })}
        </List>
      </Popover>
    </div>
    </>
  );
};

export default PerformanceLayoutSelection;
