// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //OK
  content["Yes"] =
  "Yes";

  //OK
  content["No"] =
  "No";

  //OK
  content["OK"] =
  "OK";

  //Upload JSON file(s)
  content["Upload JSON file(s)"] =
  "Upload JSON file(s)";

  //Click TEST to begin
  content["Click PROCEED to begin"] =
  "Click PROCEED to begin";

  // Processing...
  content["Processing..."] =
  "Processing...";

  //Attempting to communicate with API
  content["Attempting to communicate with API"] =
  "Attempting to communicate with API";

  // Schema passed tests
  content["Schema passed tests, click DONE below"] =
  "Schema passed tests, click DONE below";

  //Schema could not be processed.Check logs below
  content["Schema could not be processed.Check logs below"] =
  "Schema could not be processed.Check logs below";

  //Unable  to communicate with API
  content["Unable to communicate with API"] =
  "Unable to communicate with API";

  //Click TEST to begin
  content["click TEST to begin"] =
  "click TEST to begin";

  //"Uploaded Succesfully"
  content["Uploaded Succesfully"] =
  "Uploaded Succesfully";

  // Download RESTMon schema template
  content["Download RESTMon schema template"] =
  "Download RESTMon schema template";

  // Download the RESTMon schema template and then configure to monitor RESTful API
  content["Download the RESTMon schema template and then configure to monitor your RESTful API"] =
  "Download the RESTMon schema template and then configure to monitor your RESTful API";

  //RESTMon schema template
  content["RESTMon schema template"] =
  "RESTMon schema template";

  //DOWNLOAD
  content["Download"] =
  "Download";

  //Upload custom RESTMon schema
  content["Upload custom RESTMon schema"] =
  "Upload custom RESTMon schema";

  // RESTMon instruction message
  content["After modifying the RESTMon schema to meet your needs, upload the JSON file to be tested"] =
  "After modifying the RESTMon schema to meet your needs, upload the JSON file to be tested";

  // Upload file...
  content["Upload File..."] =
  "Upload File...";

  // Test custom schema
  content["Validate and deploy custom schema"] =
  "Validate and deploy custom schema";

  //schema validation & deployment
  content["schema validation & probe package deployment"] =
  "schema validation & probe package deployment";

  //TEST
  content["Proceed"] =
  "Proceed";

  //Directions
  content["Directions"] =
  "Directions";

  //Home
  content["Home"] =
  "Home";

  // Modify schema to collect API-defined metrics
  content["Modify schema to collect API-defined metrics	"] =
  "Modify schema to collect API-defined metrics	";

  // Upload modified schema (JSON file)
  content["Upload schema file(s) related to a technology"] =
  "Upload schema file(s) related to a technology";

  // Test schema syntax
  content["Test schema syntax"] =
  "Test schema syntax";

  //Select Done
  content["Select Done"] =
  "Select Done";

  //HELP
  content["Help"] =
  "Help";

  //Reset
  content["Reset"] =
  "Reset";

  //Done
  content["Done"] =
  "Done";

  //Browse
  content["Browse"] =
  "Browse";

  //To validate and deploy the schema upload the file(s) and provide a friendly name, and then, click Proceed
  content["To validate and deploy the schema upload the file(s) and provide a friendly name, and then, click Proceed"] =
  "To validate and deploy the schema upload the file(s) and provide a friendly name, and then, click Proceed";

  //Default template schemas(restmon-sample-schemas) can be found at support.nimsoft.com
  content["Default template schemas (restmon-sample-schemas) can be found at support.nimsoft.com"] =
  "Default template schemas (restmon-sample-schemas) can be found at support.nimsoft.com";

  //Friendly Name can not contain any of the following characters
  content["Friendly Name can not contain any of the following characters"] =
  "Friendly Name can not contain any of the following characters";

  //Information about valid CI and Metric types supported, refer to
  content["Information about valid CI and Metric types supported, refer to"] =
  "Information about valid CI and Metric types supported, refer to";

  //SUPPORTED_CI_METRIC_TYPES.XLSX
  content["SUPPORTED_CI_METRIC_TYPES.XLSX"] =
  "SUPPORTED_CI_METRIC_TYPES.XLSX";

  //For more information, click
  content["For more information, click"] =
  "For more information, click";

  // Bullet Icon
  content["Bullet Point Icon"] =
  "Bullet Point Icon";

  // Browse button click
  content["Browse button click to select schema JSON Files"] =
  "Browse button click to select schema JSON Files";

  // Upload button click
  content["Upload button click to upload schema JSON Files"] =
  "Upload button click to upload schema JSON Files";

  // Download button click
  content["Download button click to download default schema"] =
  "Download button click to download default schema";

  // Hide log button
  content["hide log button click to hide log information"] =
  "hide log button click to hide log information";

  // Show log button
  content["Show log  button click to see log information"] =
  "Show log  button click to see log information";

  // Show log
  content["Show log"] =
  "Show log";

  // Hide log
  content["Hide log"] =
  "Hide log";

  // Enter Friendly Name
  content["Enter Friendly Name"] =
  "Enter Friendly Name";

  // name label
  content["Name"] =
  "Name";

  // Proceed with this
  content["Proceed with this "] =
  "Proceed with this ";

  // Name to deploy
  content[" name to deploy the schema upload file(s)"] =
  " name to deploy the schema upload file(s)";

  // Reset all page changes
  content["Reset all page changes"] =
  "Reset all page changes";

  //Remove file
  content["Remove file"] =
  "Remove file";

  // END OF LOCALIZATION

export default content;
