import React from 'react'
import {
  AlarmsIcon,
  CenterError as Error,
  CenterInfo, Grid  
} from '../../../ui-components/uim-components'
import { Component } from 'react'
import connect from './../../../../utils/connect'
import {CircularProgress} from '@mineral/core';
import computerSystems from './../../../../api/computer-systems/computerSystems'
import isEmpty from 'lodash/isEmpty'
import orderBy from 'lodash/orderBy'

import debounce from 'lodash/debounce'
import get from 'lodash/get'
import SeverityIcon from './../../../icons/SeverityIcon'
import './alarms.less'
import Resource from './Resource'

const severityLables = ['Clear', 'Info', 'Warning', 'Minor', 'Major', 'Critical']

const groupCols = [
  {
	  //Severity
    headerName: Resource.get('Severity'),
    field: 'severity',
    sortable: true,
    maxWidth: 200,
    cellRendererFramework: model => {
      return (
        <div className='devices-dy-alarm__icon'>
          <SeverityIcon level={get(model, 'data.severity')} />
        </div>
      )
    },
    headerClass: 'details-popup-alarms__header--severity',
    //Alarm Severity
    headerTooltip: Resource.get('Alarm Severity'),
    tooltipValueGetter: params =>  severityLables[params.data.severity],
    headerComponentParams: {
      icon: <AlarmsIcon 
      //Max Alarm Severity
      title= {Resource.get('Max Alarm Severity')} />,
    },
  },
  {
	  //Device
    headerName: Resource.get('Device'),
    field: 'attributes.DisplayName',
    tooltipField: 'attributes.DisplayName',
    sortable: true,
    cellRendererFramework: params => params.data.name
  },
  {
	  //Primary Role
    headerName: Resource.get('Primary Role'),
    field: 'attributes.PrimaryRole',
    tooltipField: 'attributes.PrimaryRole',
    maxWidth: 200,
    sortable: true,
    cellRendererFramework: params => params.data.role
  },
  {
	  //Total Alarms
    headerName: Resource.get('Total Alarms'),
    field: 'totalAlarms',
    tooltipField: 'totalAlarms',
    maxWidth: 200,
    sortable: true,
    cellRendererFramework: params => params.data.totalAlarms
  },
]

const Panel = (props) => {
  return (
    <div className='ag-material details-popup-alarms'>
      <Grid
        headerHeight={30}
        rowHeight={60}
        columnDefs={groupCols}
        rowData={props.data}
        tooltipShowDelay={0}
        onGridReady={(params) => {
          const gridApi = params.api
          gridApi.sizeColumnsToFit()
          window.onresize = debounce(event => {
            gridApi.sizeColumnsToFit()
          }, 200)
        }} />
    </div>
  )
}

Panel.defaultProps = {
  data: [],
}


class PanelRedux extends Component {
  state = {
    data: [],
    loadStatus: 'loading',
  }
  _getDeviceByAlarms = (props) => {
    if (props.entity) {
      computerSystems
        .get({ groupId: props.entity.id })
        .then(res => res.data._items)
        .then(computerSystems => {
          const data = computerSystems
            .map(computerSystem => {
              let severity = null
              if (get(computerSystem, 'alarmCountSummary.critical')) {
                severity = 5
              } else if (get(computerSystem, 'alarmCountSummary.major')) {
                severity = 4
              } else if (get(computerSystem, 'alarmCountSummary.minor')) {
                severity = 3
              } else if (get(computerSystem, 'alarmCountSummary.warning')) {
                severity = 2
              } else if (get(computerSystem, 'alarmCountSummary.information')) {
                severity = 1
              }
              return Object.assign({}, computerSystem, { severity: severity })
            })
            .filter(computerSystem => computerSystem.severity)

          // this is only the inital sort other sorting is handled by the grid
          const sortedData = orderBy(data,
            ['severity', 'totalAlarms'],
            ['desc', 'desc']
          )
          this.setState({
            data: sortedData,
            loadStatus: 'complete'
          })
        })
        .catch(err => {
          this.setState({ loadStatus: 'fail' })
        })
    }
  }
  componentWillMount() {
    this._getDeviceByAlarms(this.props)
  }
  componentWillReceiveProps(nextProps) {
    this._getDeviceByAlarms(nextProps)
  }
  render() {
    let view
    switch (this.state.loadStatus) {
      case 'complete':
        view = isEmpty(this.state.data) ?
          <CenterInfo 
          //No alarms found
          title= {Resource.get('No alarms found')} /> :
          <Panel data={this.state.data} {...this.props} />
        break
      case 'fail':
        view = <Error />
        break
      case 'loading':
      default:
        view = view = <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><CircularProgress style={{color: '#3272D9'}} /></div>
        break
    }
    return view
  }
}

export default connect(null, null)(PanelRedux)