var config = {
  testCredentials: {
    username: '',
    password: '',
  },
  basename: '/operatorconsole_portlet',
  mockBaseName:'http://10.252.25.208:8080/castlemock/mock/rest/project/TK8t3o/application/UXxhjW'
 
  
}

export default config
