import {
  REQUEST_DISCOVERY,
  FETCH_DISCOVERY_SUCCESS,
  FETCH_DISCOVERY_FAILURE,
  REQUEST_POST_DISCOVERY,
  POST_DISCOVERY_FAILURE,
  POST_DISCOVERY_SUCCESS
} from './actionTypes'
import isEqual from 'lodash/isEqual'

const discovery = (state = {
  isFetching: false,
  isPosting: false,
  didInvalidatePost: false,
  didInvalidate: false,
  items: []
}, action) => {
  switch (action.type) {
    case REQUEST_DISCOVERY:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false
      });
    case REQUEST_POST_DISCOVERY:
      return Object.assign({}, state, {
        isPosting: true,
        didInvalidatePost: false
      });
    case FETCH_DISCOVERY_SUCCESS:
      const items = action.discovery
      let updatedState = Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        items: items
      });
      return isEqual(updatedState, state) ? state : updatedState
    case POST_DISCOVERY_SUCCESS:
       updatedState = Object.assign({}, state, {
        isPosting: false,
        didInvalidatePost: false
      });
      return isEqual(updatedState, state) ? state : updatedState
    case FETCH_DISCOVERY_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        errorMessage: action.errorMessage,
      });
    case POST_DISCOVERY_FAILURE:
      return Object.assign({}, state, {
        isPosting: false,
        didInvalidatePost: true,
        postErrorMessage: action.errorMessage
      });
    default:
      return state
  }
};

export default discovery
