import {
  FETCH_INTERFACE_LIST,
  FETCH_INTERFACE_LIST_SUCCESS,
  FETCH_INTERFACE_LIST_FAILURE,
  FETCH_INTERFACE_LIST_ADD_SUCCESS,
  FETCH_INTERFACE_LIST_ADD_FAILURE,
  FETCH_METRIC_LIST,
  FETCH_METRIC_LIST_SUCCESS,
  FETCH_METRIC_LIST_FAILURE,
  CHANGE_INTERFACES_COUNT,
} from "./actionTypes";

const interfacesReducer = (
  state = {
    isFetchingInterfaces: false,
    didInvalidateInterfaceCall: false,
    interfaceData: {},
    interfaceListData: {},
    interfacesError: "",
    isFetchingMetric: false,
    didInvalidateMetricCall: false,
    metricData: {},
    metricsError: "",
    interfacesCount: 0,
  },
  action
) => {
  switch (action.type) {
    case FETCH_INTERFACE_LIST:
      return Object.assign({}, state, {
        isFetchingInterfaces: true,
        didInvalidateInterfaceCall: false,
      });
    case FETCH_INTERFACE_LIST_SUCCESS:
      return Object.assign({}, state, {
        isFetchingInterfaces: false,
        didInvalidateInterfaceCall: false,
        interfaceData: action.data,
      });
    case FETCH_INTERFACE_LIST_FAILURE:
      return Object.assign({}, state, {
        isFetchingInterfaces: false,
        didInvalidateInterfaceCall: true,
        interfacesError: action.errorMessage,
      });
    case FETCH_INTERFACE_LIST_ADD_SUCCESS:
      return Object.assign({}, state, {
        isFetchingInterfaces: false,
        didInvalidateInterfaceCall: false,
        interfaceListData: action.data,
      });
    case FETCH_INTERFACE_LIST_ADD_FAILURE:
      return Object.assign({}, state, {
        isFetchingInterfaces: false,
        didInvalidateInterfaceCall: true,
        interfacesError: action.errorMessage,
      });
    case FETCH_METRIC_LIST:
      return Object.assign({}, state, {
        isFetchingMetric: true,
        didInvalidateMetricCall: false,
      });
    case FETCH_METRIC_LIST_SUCCESS:
      return Object.assign({}, state, {
        isFetchingMetric: false,
        didInvalidateMetricCall: false,
        metricData: action.data,
      });
    case FETCH_METRIC_LIST_FAILURE:
      return Object.assign({}, state, {
        isFetchingMetric: false,
        didInvalidateMetricCall: true,
        metricsError: action.errorMessage,
      });
      case CHANGE_INTERFACES_COUNT:
      return {...state, interfacesCount: action.interfacesCount};
    default:
      return state;
  }
};

export default interfacesReducer;
