import React from "react";
import Portlet from "../Portlet";
import ArrowIcon from "../../ArrowIcon";
import Resource from "./Resource";
import {Tooltip} from '@mineral/core';
export default class EcometerAdminCard extends Portlet {

  getIcon() {
    return <ArrowIcon />;
  }

  getSubLabelTwo = () => {
    return this.props.subLabelTwo;
  };
}

const TextLogo = ({ label }) => {
  return (
    <Tooltip title={label}>
      <span className="settings-text-logo admin__console--card">{label}</span>
    </Tooltip>
  );
};

//Ecometer Admin
const EcometerAdminLabel = Resource.get("Ecometer Admin");
//Manage accounts using account admin
const Creating = Resource.get("Manage Ecometer monitor using Ecometer Admin");

EcometerAdminCard.defaultProps = {
  logo: <TextLogo label={EcometerAdminLabel} />,
    configRoute: "/settings/dcimadmin",
  subLabelOne: Creating,
  subLabelTwo: "",
};

