import {
  FETCH_ACTIVE_PROBES,
  FETCH_ACTIVE_PROBES_SUCCESS,
  FETCH_ACTIVE_PROBES_FAILURE
} from './actionTypes'
import activeProbes from './activeProbes'


export function fetchActiveProbesSuccess(json) {
  return {
    type: FETCH_ACTIVE_PROBES_SUCCESS,
    probes: json
  }
}
export function fetchActiveProbesFailure(error) {
  return {
    type: FETCH_ACTIVE_PROBES_FAILURE,
    errorMessage: error.message
  }
}
export function fetchActiveProbes() {
  return dispatch => {
    dispatch({type: FETCH_ACTIVE_PROBES})

    return activeProbes.get()
      .then(response => {
        dispatch(fetchActiveProbesSuccess(response.data))
      })
      .catch(function (error) {
        dispatch(fetchActiveProbesFailure(error))
      })
  }
}