// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //For more information, click
  content["For more information, click "] =
  "Um weitere Informationen zu erhalten, klicken Sie auf ";

  // console.log(this.props);
  content["Default system monitoring for CPU, disk & memory will be applied"] =
  "Standardmäßige Systemüberwachung für CPU, Festplatte und Speicher wird angewendet";

  //Automatically monitor
  content["Automatically monitor "] =
  "Automatisch überwachen ";

  // devices
  content[" devices"] =
  " Geräte";

  //HELP
  content["HELP"] =
  "HILFE";

  // END OF LOCALIZATION

export default content;
