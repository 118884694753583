import React, { Component } from "react";
import connect from "./../../utils/connect";
import {CenterError as Error} from "../ui-components/uim-components";
import {CircularProgress} from '@mineral/core';
import get from "lodash/get";
import { fetchComputerSystem } from "./../../api/computer-systems/actions";
import { get as fetchDevices } from "./../../api/devices/actions";
import ConfigurationRedirect from "./ConfigurationRedirect";
import ConfigurationRedux from "./ConfigurationRedux";
import McsRobotDeploy from "./../robotDeploy/mcs/McsRobotDeploy";
import { setBreadCrumbs } from "./../breadcrumbs/actions";
import Resource from "./Resource";

class ProtectedDeviceConfiguration extends Component {
  state = {
    hasRobot: false,
    error: false,
    device: null,
    bypassToConfig: false,
  };
  _getDevice = (props) => {
    const id = props.deviceId ? props.deviceId : props.id;
    return props.getDevices(id).then((deviceAction) => {
      const device = deviceAction.data._items[0];
      this.setState({ device });
    });
  };
  _getComputerSystem = (props) => {
    const id = props.deviceId ? props.deviceId : props.id;
    return props.getComputerSystem(id).then((computerSystemAction) => {
      const computerSystem = computerSystemAction.data;
      const hasRobot =
        computerSystem.busType === "hub" || computerSystem.busType === "robot";
      this.setState({ hasRobot });
    });
  };

  _load = () => {
    Promise.all([
      this._getComputerSystem(this.props),
      this._getDevice(this.props),
    ]).then(
      () => {
        this.setState({ ready: true });
      },
      (error) => {
        this.setState({ error });
      }
    );
  };
  componentWillMount() {
    this._load();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.id !== nextProps.id) {
      this._load();
    }
  }
  redirectToLocation = (location, id) => {
    if (location === "alarmPolicy") {
      this.props.history.push("/settings/alarm-policies/" + id);
    }
  };
  render() {
    const isVmware = get(this.state.device, "probeName") === "vmware";
    let view = <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><CircularProgress style={{color: '#3272D9'}} /></div>;

    if (this.state.ready && !this.state.hasRobot) {
      view = (
        <ConfigurationRedirect
          acl={this.props.acl}
          history={this.props.history}
          open={true}
          onClose={() => {
            this.setState({ bypassToConfig: true });
          }}
        />
      );
    }
    // if (this.state.ready && !this.state.hasRobot && isVmware ) {
    //   view = (
    //     <McsRobotDeploy
    //       open={true}
    //       device={this.state.device}
    //       onClose={() => {
    //         this.setState({ bypassToConfig: true });
    //       }}
    //     />
    //   );
    // }
    if (this.state.bypassToConfig || this.state.hasRobot) {
      view = (
        <ConfigurationRedux
          redirectToLocation={this.redirectToLocation}
          {...this.props}
        />
      );
    }
    if (this.state.error) {
      view = <Error />;
    }
    return view;
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getComputerSystem: (id) => dispatch(fetchComputerSystem({ id: id })),
    getDevices: (id) =>
      dispatch(
        fetchDevices({
          csid: id,
          devAttrKey: "Roles",
          devAttrValue: "VirtualMachine",
        })
      ),
  };
};

const mapStateToProps = (state) => {
  return {
    deviceId: state.selectedCsIds.length ? state.selectedCsIds[0] : null,
    acl:state.acl
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProtectedDeviceConfiguration);
