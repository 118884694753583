import React from "react";
import Portlet from "../Portlet";
import ArrowIcon from "../../ArrowIcon";
import Resource from "./Resource";
import {Tooltip} from '@mineral/core';
export default class DashboardCard extends Portlet {
  getIcon() {
    return <ArrowIcon />;
  }

  getSubLabelTwo = () => {
    return this.props.subLabelTwo;
  };
}

const TextLogo = ({ label }) => {
  return (
    <Tooltip title={label}>
      <span className="settings-text-logo admin__console--card">{label}</span>
    </Tooltip>
  );
};

//Dashboard Designer
const DashboardLabel = Resource.get("Dashboard Designer");
//Create dashboards using dashboard designer
const Creating = Resource.get("Create dashboards using dashboard designer");

DashboardCard.defaultProps = {
  logo: <TextLogo label={DashboardLabel} />,
  configRoute: "/settings/dashboard",
  subLabelOne: Creating,
  subLabelTwo: "",
};
