import React, { Component } from "react";
import {
  ClearIcon as Clear,
  CenterError as Error,
  CenterInfo,
} from "../../ui-components/uim-components";
import {CircularProgress} from "@mineral/core";
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  LinearProgress,
  TableContainer,
  Tooltip as CustomTooltip,Button,
  IconButton,
} from "@mineral/core";
import connect from "./../../../utils/connect";

import {Tooltip as MTooltip} from "@mineral/core";
import {
  fetchTopDevicespoll,
  pollTopDevicesStop,
} from "./../../../api/home-screen/actions";
import isEqual from "lodash/isEqual";
import { sortColumns } from "./../../../utils/tableUtils";
//import CustomTooltip from "../../tooltip/CustomTooltip"
//import Tooltip as CustomTooltip from "@material-ui/core/Tooltip";
import Resource from "./Resource";
const chartColors = ["#D9E9EC", "#9EC6D0", "#FEE276", "#F7922F", "#A11D2E"];
const Panel = (props) => {
  const handleDrillDown = (rowNumber, colId) => {
    props.history.push(
      `/computer-systems/${props.data[rowNumber].csId}/dashboard`
    );
  };
  // MIN = Minimum expected value
  // MAX = Maximium expected value
  // Function to normalise the values (MIN / MAX could be integrated)
  const normalise = (value, min, max) => ((value - min) * 100) / (max - min);
  return (
    <div className="technologies_grid">
      <TableContainer
        style={{
          maxHeight: `${
            window.innerHeight < 800
              ? "25vh"
              : window.innerHeight == 800
              ? "29vh"
              : window.innerHeight < 1080
              ? "31vh"
              : "34vh"
          }`,
        }}
      >
        <Table
          height={
            window.innerHeight < 800
              ? "25vh"
              : window.innerHeight == 800
              ? "29vh"
              : window.innerHeight < 1080
              ? "31vh"
              : "34vh"
          }
        >
          <TableBody stripedRows={false} displayRowCheckbox={false}>
            {props.data.map((row, index) => (
              <TableRow
                onClick={() => {
                  handleDrillDown(index, row.csId);
                }}
                role="row"
               
                key={row.csId}
                className="technologies-row"
              >
                <TableCell colSpan="1" className="technologies_grid_col_name">
                  {/* <CustomTooltip title={row.hostName}> */}
                
                  <Button variant="text" style={{padding:0,display:'flex',justifyContent: 'flex-start',width: '100%',color:'#0361D9',
}} ><span style={{
    textOverflow: 'ellipsis',overflow:'hidden', whiteSpace: 'nowrap'}}>
                  {row.hostName}</span></Button>
                  {/* </CustomTooltip> */}
                </TableCell>
                <TableCell
                  colSpan="2"
                  className="technologies_grid_col_progress"
                >
                  {/* <CustomTooltip title={"Alarm Count"}> */}
                  <LinearProgress
                    variant="determinate"
                    color="primary"
                    aria-label={"Alarm count linear progress"}
                    value={normalise(row.alarmCount, 0, props.maxCount)}
                    className="technologies-progress-bar"
                    classes={{
                      barColorPrimary: `${"chart-sev" + row.alarmLevel}`,
                    }}
                  />
                  {/* </CustomTooltip> */}
                </TableCell>

                <TableCell className="technologies_grid_col_alarm">
                  {row.alarmCount}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

class PanelContainer extends Component {
  state = {
    status: "loading",
    csSystems: [],
  };
  apiError = () => {
    this.setState({
      status: "failure",
    });
  };

  componentDidMount() {
    this.props.fetchTopDevicespoll(
      this.props.groupIds ? this.props.groupIds : null,
      this.props.probeId ? this.props.probeId : null
    );
  }
  componentWillUnmount() {
    this.props.pollTopDevicesStop();
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      csSystems: nextProps.data,
      status: "complete",
      maxCount:
        nextProps.data.length > 0
          ? sortColumns("alarmCount", "desc", nextProps.data)[0].alarmCount
          : 0,
    });
  }
  render() {
    let view = (
      <Panel
        style={{ background: "#f4f4f4" }}
        className={this.props.className}
        maxCount={this.state.maxCount}
        data={this.state.csSystems}
        history={this.props.history}
      />
    );
    //Devices
    let title = Resource.get("Devices");
    //No Devices Found
    let noDevicesText = Resource.get("No Devices Found");
    //View All
    let viewAllText = Resource.get("View All");
    let cardHeader = (
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <span className="overview_container_subheader">{title}</span>
        <div style={{ display: "flex", alignItems: "center" }}>
          {!this.props.didTopDevicesInvalidate && (
            <MTooltip title={viewAllText}>
            <Button variant="text"
             
             
              onClick={() => {
                this.props.history.push("/inventory");
              }}
              aria-label="Click to view all devices" style={{height:'16px'}}
            >
              {viewAllText}
            </Button>
            </MTooltip>
          )}
          <span className="overview_header_seprator">|</span>
          <MTooltip title={"Close"}>
          <IconButton
            style={{ padding: 0 }}
            aria-label={"Close"}
            onClick={() => {
              this.props.handleCloseDialog("showTopDevices");
            }}
          >
           
            <Clear className="clearIcon" role="figure"
            title="Close" />
          </IconButton>
          </MTooltip>
        </div>
      </div>
    );
    if (
      this.state.status === "loading" &&
      !this.props.didTopDevicesInvalidate
    ) {
      view = (
        <div
          className="no_data_panel"
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress style={{ color: "#3272D9" }} />
        </div>
      );
    } else if (
      this.state.status === "failure" ||
      this.props.didTopDevicesInvalidate
    ) {
      view = (
        <div className="no_data_panel">
          <Error />
        </div>
      );
    } else if (
      this.state.status === "complete" &&
      this.state.csSystems.length == 0
    ) {
      view = (
        <div className="no_data_panel">
          <CenterInfo title={noDevicesText} />
        </div>
      );
    }
    return (
      <div
        className={this.props.className}
        // style={{
        //   height: null,
        //   width: null,
        //   margin: null,
        // }}
        style={{
         // height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        {cardHeader}
        {view}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchTopDevicespoll: (groupIds, probeId) =>
      dispatch(fetchTopDevicespoll(groupIds, probeId)),
    pollTopDevicesStop: () => dispatch(pollTopDevicesStop()),
  };
};
const mapStateToProps = (state) => {
  return {
    data: state.homeScreen.topDevices,
    isFetching: state.homeScreen.isFetching,
    didTopDevicesInvalidate: state.homeScreen.didTopDevicesInvalidate,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PanelContainer); //connect so that we can get a handle on history
