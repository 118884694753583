import axios from 'axios'
import config from './../config'

class CloseRule {
  get(params) {
    var url = [config.basename, 'api/close-rule']
    return axios.get(url.join('/'))
  }

  save(closeRule) {
    return axios.post([config.basename, 'api/close-rule'].join('/'), closeRule)
  }

  test(closeRule) {
    return axios.post([config.basename, 'api/close-rule/test'].join('/'), closeRule)
  }
}

export default new CloseRule()
