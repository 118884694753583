// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Remote monitoring (also known as agentless monitoring) requires a monitoring host. The monitoring host is responsible for collecting data from the remote device that you are monitoring.
  content["Remote monitoring (also known as agentless monitoring) requires a monitoring host. The monitoring host is responsible for collecting data from the remote device that you are monitoring."] =
  "원격 모니터링(또는 에이전트 없는 모니터링)을 사용하려면 모니터링 호스트가 필요합니다. 모니터링 호스트는 모니터링 중인 원격 장치의 데이터를 수집합니다.";

  //Monitoring host server
  content["Monitoring host server"] =
  "모니터링 호스트 서버";

  //Deploy a monitoring
  content["Deploy a monitoring"] =
  "모니터링 배포";

  //Recommended minimum system requirements
  content["Recommended minimum system requirements"] =
  "권장 최소 시스템 요구 사항";

  //Docker monitoring requires an agent (aka Robot) to be installed on the Docker Engine server.
  content["Docker monitoring requires an agent (aka Robot) to be installed on the Docker Engine server."] =
  "Docker 모니터링을 사용하려면 Docker Engine 서버에 에이전트(즉, 로봇)를 설치해야 합니다.";

  //IP address
  content["IP address"] =
  "IP 주소";

  //Username
  content["Username"] =
  "사용자 이름";

  //Password
  content["Password"] =
  "암호";

  //Sudo Password (Linux only)
  content["Sudo Password (Linux only)"] =
  "Sudo 암호(Linux만 해당)";

  //4 GB of available memory
  content["4 GB of available memory"] =
  "4GB의 사용 가능한 메모리";

  //2 or more CPUs
  content["2 or more CPUs"] =
  "2개 이상의 CPU";

  //10 GB of available disk space
  content["10 GB of available disk space"] =
  "10GB의 사용 가능한 디스크 공간";

  //Ports 48000 to 48025 open
  content["Ports 48000 to 48025 open"] =
  "포트 48000 - 48025 열림";

  // END OF LOCALIZATION

export default content;
