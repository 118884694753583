import React, { Component } from "react";
import { IconButton,Tooltip } from "@mineral/core";
import arrow_down from "../../../assets/icons/arrow_down.svg";
class TreeListItem extends Component {
  state = {
    show: true,
  };
  handleShow = () => {
    this.setState({ show: !this.state.show });
  };
  handleGroup = (e) => {
    if (e.target.nodeName !== "IMG") {
      this.props.onChange(e.target.id);
    }
  };

  render() {
    const { group, children, currGroup } = this.props;
    this.handleRadioChange = this.props.handleRadioChange;
    let disabledClass = {};
    if (group.attributes) {
      if (group.attributes.indexOf("AUTO_PARENT") !== -1) {
       disabledClass = { color: "#58606E", pointerEvents: "none" ,opacity: '0.5'};
     }
    }
    const disabled = Object.keys(disabledClass).length !== 0;
    const filtered = children
      ? children.filter(function (el) {
          return el != undefined;
        })
      : {};
    const selectClasses =
      currGroup && group && group.name === currGroup.name
        ? "li-tree-select   parent-tree-list-item"
        : " parent-tree-list-item ";
    return (
      <li key={group.id} className={selectClasses} value={group.id}>
        <div style={disabledClass} className="li-tree">
          {filtered.length > 0 ? (
            <Tooltip title="Expand">
            <IconButton aria-label="Expand"
              style={{
                transform: `${
                  this.state.show || group.show ? "" : "rotate(-90deg)"
                }`,
                verticalAlign: `middle`,
                height: "32px",
                width: "32px",
                padding: "0px",
              }}
              onClick={this.handleShow}
              disabled={disabled}
            >
              <div className="parent-arrow">
                <img src={arrow_down} alt="" />
              </div>
            </IconButton>
            </Tooltip>
          ) : (
            <IconButton
              tabIndex={-1}
              style={{
                verticalAlign: `middle`,
                height: "32px",
                width: "15px",
                padding: "0px",
              }}
            />
          )}
          <button aria-label={group.name}
            id={group.id}
            onClick={this.handleGroup.bind(this)}
            disabled={disabled}
            style={{
              backgroundColor: "transparent",
              border: "none",
              textAlign: "start",
            }}
          >
            {group.name}
          </button>
        </div>
        {this.state.show || group.show ? (
          <ul
            key={group.id}
            style={{ marginLeft: "-20px" }}
            onClick={this.handleGroup}
          >
            {children}
          </ul>
        ) : null}
      </li>
    );
  }
}

export default TreeListItem;
