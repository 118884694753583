import React from "react";
import PropTypes from "prop-types";
import { Link, Route } from "react-router-dom";
import { ShowMoreIcon } from "../ui-components/uim-components";
import Resource from "./Resource";
// import { Link } from '@mineral/core';

//Homestub
const HomeStub = Resource.get("Home");

//Dashboards
const Dashboards = Resource.get("Dashboards");

//Monitoring Configuring Service
const MonitoringConfiguringService = Resource.get(
  "Monitoring Configuring Service"
);

const Breadcrumb = (props) => {
  const { item, isFirstCrumb, isFinalCrumb } = props;
  const onClick = () => {
    props.resetCardPage();
    props.handleClick(props.item);
  };
  if (!item) {
    return null;
  }
  const crumbPath = Object.values(item)[1];
  const mcsBreadcrumb =
    crumbPath === "/technologies" &&
    window.location.pathname === "/operatorconsole_portlet/mcsDeploymentStatus";
  return !mcsBreadcrumb ? (
    <span className="breadcrumb">
      <Route
        path="/"
        render={({ location }) => {
          return !isFinalCrumb ? (
            <Link
              to={item.link} role="navigation"
              className="breadcrumb__link"
              title={item.name}
              onKeyPress={(e) => {
                if (e.charCode === 13) {
                  onClick();
                }
              }} aria-label={isFirstCrumb && !isFinalCrumb ? HomeStub : item.name}
              onClick={(e) => {
                window.dispatchEvent(
                  new CustomEvent("updateNavLinks", { detail: item.link })
                );
                onClick();
              }}
            >
              {isFirstCrumb && !isFinalCrumb ? HomeStub : item.name}
              {!isFinalCrumb ? (
                <ShowMoreIcon className="breadcrumb_separator" role="img" alt="Separator" aria-label={"Separator"} />
              ) : null}
            </Link>
          ) : (
            <h1 className="breadcrumb__link" role="navigation" aria-label={item.name}>{item.name}</h1>
          );
        }}
      />
    </span>
  ) : (
    <>
      <span className="breadcrumb">
        <Link
          to="/technologies" aria-label={`${Dashboards}`}
          className="breadcrumb__link" role="navigation"
          title="Dashboards"
          onClick={(e) => {
            window.dispatchEvent(
              new CustomEvent("updateNavLinks", { detail: "/technologies" })
            );
            onClick();
          }}
        >
          {` ${Dashboards}`}
          <ShowMoreIcon className="breadcrumb_separator" role="img" alt="Separator" aria-label={"Separator"} />
        </Link>
      </span>
      <span className="breadcrumb">
        <h1 className="breadcrumb__link" role="navigation" aria-label={MonitoringConfiguringService}>{MonitoringConfiguringService}</h1>
      </span>
    </>
  );
};

Breadcrumb.defaultProps = {
  isFinalCrumb: false,
};

Breadcrumb.propTypes = {
  isFinalCrumb: PropTypes.bool,
  handleClick: PropTypes.func,
};

export default Breadcrumb;
