import React from 'react'
import './icons.less'

const Icon = (props) => {
  let classes = [
    'icon',
    props.className,
  ].join(' ')
  return (
    <svg className={classes} role={props.role || 'figure'} aria-label="[title]" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g>
        <title>{props.title}</title>
        <path fillRule="evenodd" d="M18.984,4.01600177 C19.5153333,4.01600177 19.984,4.21900177 20.39,4.62500177 C20.796,5.03100177 20.999,5.49966844 20.999,6.03100177 L20.999,20.0000018 C20.999,20.5313351 20.796,21.0000018 20.39,21.4060018 C19.984,21.8120018 19.5153333,22.0150018 18.984,22.0150018 L5.015,22.0150018 C4.48366667,22.0150018 4.015,21.8120018 3.609,21.4060018 C3.203,21.0000018 3,20.5313351 3,20.0000018 L3,6.03100177 C3,5.49966844 3.203,5.03100177 3.609,4.62500177 C4.015,4.21900177 4.48366667,4.01600177 5.015,4.01600177 L9.187,4.01600177 C9.40566667,3.42200177 9.765,2.93766844 10.265,2.56300177 C10.765,2.1883351 11.343,2.00066844 11.999,2.00000177 C12.655,1.9993351 13.233,2.18700177 13.733,2.56300177 C14.233,2.93900177 14.5923333,3.4233351 14.811,4.01600177 L18.983,4.01600177 L18.984,4.01600177 Z M12,4.01600177 C11.7186667,4.01600177 11.4843333,4.10966844 11.297,4.29700177 C11.1096667,4.4843351 11.016,4.71866844 11.016,5.00000177 C11.016,5.2813351 11.1096667,5.52366844 11.297,5.72700177 C11.4843333,5.9303351 11.7186667,6.03200177 12,6.03200177 C12.2813333,6.03200177 12.5156667,5.9303351 12.703,5.72700177 C12.8903333,5.52366844 12.984,5.2813351 12.984,5.00000177 C12.984,4.71866844 12.8903333,4.4843351 12.703,4.29700177 C12.5156667,4.10966844 12.2813333,4.01600177 12,4.01600177 L12,4.01600177 Z M5,9.01600177 L7,9.01600177 L7,11.0160018 L5,11.0160018 L5,9.01600177 Z M5,13.0160018 L7,13.0160018 L7,15.0160018 L5,15.0160018 L5,13.0160018 Z M9,9.01600177 L19,9.01600177 L19,11.0160018 L9,11.0160018 L9,9.01600177 Z M9,13.0160018 L19,13.0160018 L19,15.0160018 L9,15.0160018 L9,13.0160018 Z M5,17.0160018 L7,17.0160018 L7,19.0160018 L5,19.0160018 L5,17.0160018 Z M9,17.0160018 L19,17.0160018 L19,19.0160018 L9,19.0160018 L9,17.0160018 Z"/>
      </g>
    </svg>
  )
}

export default Icon