import React, { Component } from "react";
import { connect } from "react-redux";
import TimeRange from "./timerange/TimeRange";
import { ColumnOne, ColumnTwo, ColumnThree, ColumnFour } from "./columns/icons";
import { setColumns } from "./columns/actions";
import { setTimerange } from "./timerange/actions";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import CheckIcon from "@material-ui/icons/Check";
import { ClearIcon } from "../../ui-components/uim-components";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { HelpIcon } from "../../ui-components/uim-components";
import {
  FormHelperText,
  Drawer,
  Button,
  Snackbar, Grid,
  TextField,
  FormControl, Typography,
  Button as FlatButton, InputLabel
} from "@mineral/core";
import {
  IconButton,
  Tab,
  Tabs,
  Popover,
  OutlinedInput,
  Select as DropDownMenu,
  MenuItem,
  Tooltip,
} from "@mineral/core";
// import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import ExportIcon from "../../../assets/icons/export.js";
import {
  saveMetric,
  fetchAllMetric,
  fetchMetricById,
  getMetricDevices,
  exportMetrics,
} from "./../api/deviceMetrics/actions";
import metricAPIs from "./../api/deviceMetrics/metric";
import { isEqual } from "lodash";
import *  as XLSX from "xlsx";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import "./Nav.less";
import { arraysEqual } from "../MetricPaletteViewer";
const aggregationIntervalRaw = "Raw"; //None
const aggregationInterval1Minute = "1 Minute"; //1 Minute
const aggregationInterval5Minutes = "5 Minutes"; //5 Minutes
const aggregationInterval15Minutes = "15 Minutes"; //15 Minutes
const aggregationInterval30Minutes = "30 Minutes"; //30 Minutes
const aggregationInterval1Hour = "1 Hour"; //1 Hour
const aggregationInterval2Hours = "2 Hours"; //2 Hours
const aggregationInterval4Hours = "4 Hours"; //4 Hours
const aggregationInterval8Hours = "8 Hours"; //8 Hours
const aggregationInterval12Hours = "12 Hours"; //12 Hours
const aggregationInterval1Day = "1 Day"; //1 Day
const interpolationHelpText = "Provides linear interpolation in the QOS series based on the actual samples collected by the probe."; //interpolation help text

const textfiledInputStyle = {
  color: "transparent",
  textShadow: "0 0 0 #020d17",
  width: "90%",
};

class Nav extends Component {
  state = {
    open: false,
    name: "",
    selectedAcc: [],
    openSnackBar: false,
    savedViewSearch: "",
    isSharedView: false,
    isDefault: false,
    isOpen: false,
    selectedMetric: {
      name: "",
      id: null,
      definition: "",
      isDefault: "",
      viewType: "",
    },
    tabValue: 1,
    myViewLength: 0,
    sharedViewLength: 0,
    openDrawer: false,
    exportVal: "csv",
    pageSizeVal: "4",
    aggregationIntervalValue: -1,
    aggregateFunction: "AVG",
    timeRangeValue: 1,
    selectedRange: "",
    endDate: new Date().getTime(),
    isInterpolation: false,
    openInterpoleSnackBar: false,
    isEnableAggregationInterval:true,
  };

  toggling = () => this.setState({ isOpen: !this.state.isOpen });

  componentDidMount() {
    let body = {};
    if (this.props.isInterface) {
      body.masterId = this.props.masterId;
      body.csId = this.props.contextValue;
    } else if (this.props.fromCsView) {
      body.csId = this.props.contextValue;
    } else if (this.props.fromGroup) {
      body.groupId = this.props.groupId;
    }
    this.props.fetchAllMetric(body).then(() => {
      let defaultView = this.props.namedMetricList.MyViews.filter(
        (mtr) => mtr.isDefault
      );
      defaultView = defaultView && defaultView.length && defaultView[0];
      if (defaultView) {
        let selectedCIs = {};
        let mtrIds = [];
        let metricDef =
          defaultView.definition && JSON.parse(defaultView.definition);
        if (defaultView.definition) {
          metricDef.metrics.map((mtr) => {
            selectedCIs[mtr.metricTypeId] = mtr.ciNames;
            mtrIds.push(mtr.metricTypeId);
          });
          let finalEndDate =
            this.state.selectedRange == "Custom"
              ? this.state.endDate
              : new Date().getTime();
          this.props.getMetricForDevices(
            metricDef.csIds.join(),
            mtrIds,
            this.props.timerange,
            selectedCIs,
            this.props.deviceMetrics.isInterface,
            //this.props.timerangeEndDate,
            finalEndDate,
            this.state.aggregationIntervalValue,
            this.state.aggregateFunction,
            this.state.isInterpolation,
          );
        }
        this.props.loadedView();
      }
      this.setState({
        selectedMetric: defaultView,
        myViewLength: this.props.namedMetricList.MyViews.length,
        sharedViewLength: this.props.namedMetricList.SharedViews.length,
      });
    });
    /* window.addEventListener("click", (e) => {
       !(
         document.getElementById("namedDropdown") &&
         document.getElementById("namedDropdown").contains(e.target)
       ) && this.setState({ isOpen: false });
     });*/
  }

  componentWillReceiveProps(nextProps) {
    if (
      !arraysEqual(nextProps.devices.map(i => i.id), this.props.devices.map(i => i.id)) ||
      nextProps.fromCsView != this.props.fromCsView ||
      nextProps.fromGroup != this.props.fromGroup || nextProps.isInterface != this.props.isInterface || nextProps.masterId != this.props.masterId
    ) {
      let body = {};
      if (nextProps.isInterface) {
        body.masterId = nextProps.masterId;
        body.csId = nextProps.contextValue;
      } else if (nextProps.fromCsView) {
        body.csId = nextProps.contextValue;
      } else if (nextProps.fromGroup) {
        body.groupId = nextProps.groupId;
      }
      this.props.fetchAllMetric(body).then(() => {
        let defaultView = this.props.namedMetricList.MyViews.filter(
          (mtr) => mtr.isDefault
        );
        defaultView = defaultView && defaultView.length && defaultView[0];
        if (defaultView) {
          let selectedCIs = {};
          let mtrIds = [];
          let metricDef =
            defaultView.definition && JSON.parse(defaultView.definition);
          if (defaultView.definition) {
            metricDef.metrics.map((mtr) => {
              selectedCIs[mtr.metricTypeId] = mtr.ciNames;
              mtrIds.push(mtr.metricTypeId);
            });
            let finalEndDate =
              this.state.selectedRange == "Custom"
                ? this.state.endDate
                : new Date().getTime();
            this.props.getMetricForDevices(
              metricDef.csIds.join(),
              mtrIds,
              this.props.timerange,
              selectedCIs,
              this.props.deviceMetrics.isInterface,
              //this.props.timerangeEndDate,
              finalEndDate,
              this.state.aggregationIntervalValue,
              this.state.aggregateFunction,
              this.state.isInterpolation,
            );
          }
          this.props.loadedView();
        }
        this.setState({
          selectedMetric: defaultView || this.state.selectedMetric,
          myViewLength: this.props.namedMetricList.MyViews.length,
          sharedViewLength: this.props.namedMetricList.SharedViews.length,
        });
      });
    }

    if (!isEqual(nextProps.namedMetricList, this.props.namedMetricList)) {
      this.setState({
        selectedMetric: nextProps.deviceMetrics.savedData.id
          ? nextProps.deviceMetrics.savedData
          : this.state.selectedMetric,
        myViewLength: nextProps.namedMetricList.MyViews.length,
        sharedViewLength: nextProps.namedMetricList.SharedViews.length,
      });
    }
    if (
      !this.props.deviceMetrics.didInvalidateSave &&
      nextProps.deviceMetrics.didInvalidateSave
    ) {
      this.setState({
        snackMsg: nextProps.deviceMetrics.errorInSaving,
        openSnackBar: true,
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("click", () => { });
  }

  onMetricClicked = (value) => () => {
    this.props.fetchMetricById(value).then(() => {
      let metricDef = JSON.parse(this.props.namedMetricData.definition);
      let selectedCIs = {};
      let mtrIds = [];
      metricDef.metrics.map((mtr) => {
        selectedCIs[mtr.metricTypeId] = mtr.ciNames;
        mtrIds.push(mtr.metricTypeId);
      });
      let finalEndDate =
        this.state.selectedRange == "Custom"
          ? this.state.endDate
          : new Date().getTime();
      this.props.getMetricForDevices(
        metricDef.csIds.join(),
        mtrIds,
        this.props.timerange,
        selectedCIs,
        this.props.deviceMetrics.isInterface,
        //this.props.timerangeEndDate,
        finalEndDate,
        this.state.aggregationIntervalValue,
        this.state.aggregateFunction,
        this.state.isInterpolation,
      );
    });
    this.props.loadedView();
    this.setState({ selectedMetric: value, isOpen: false });
  };
  editView = () => {
    const viewDetails = this.state.selectedMetric;
    sessionStorage.setItem("editCalled", true);
    this.props.fromGroup && sessionStorage.setItem("editForDvcCalled", true);
    this.props.toggleSelection();
    this.props.isViewChanged();
    this.props.getViewDetails(viewDetails);
  };
  // handleView = (view) => {
  //   view == 1 && sessionStorage.setItem("newCalled", true);
  //   this.props.toggleSelection();
  //   this.props.whichViewClicked(view);
  // };

  setNumberOfColumns(value) {
    this.props.setColumns(value);
  }

  onTimeChange(value, endDate, selectedRange) {    
    this.setState({
      timeRangeValue: value,
      endDate: endDate,
      selectedRange: selectedRange,
      aggregationIntervalValue: -1,
      isInterpolation: false,
      isEnableAggregationInterval:parseInt(value)>=168?false:true,
    });
    this.props.setTimerange(value, endDate, -1, this.state.aggregateFunction, this.state.isInterpolation);
  }

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleCloseSnackBar = () => {
    this.setState({ openSnackBar: false });
  };

  handleCloseInterpoleSnackBar = () => {
    this.setState({ openInterpoleSnackBar: false });
  };

  handleChange = (event) => {
    this.setState({
      name: event.target.value,
    });
  };

  handleSearchChange = (evt) => {
    this.setState({
      savedViewSearch: evt.target.value,
    });
  };

  handleAccChng = (ind) => {
    this.state.selectedAcc.includes(ind)
      ? this.setState({
        selectedAcc: this.state.selectedAcc.splice(
          this.state.selectedAcc.indexOf(ind),
          1
        ),
      })
      : this.setState({ selectedAcc: [...this.state.selectedAcc, ind] });
  };

  updateCheck = () => {
    this.setState((oldState) => {
      return {
        isSharedView: !oldState.isSharedView,
      };
    });
  };

  handleDrawer = () => {
    this.setState((prevState) => ({ openDrawer: !prevState.openDrawer }));
  };

  handleExport = () => {
    let finalEndDate =
      this.state.selectedRange == "Custom"
        ? this.state.endDate
        : new Date().getTime();
    if (this.state.exportVal == "csv") {
      this.setState({
        openSnackBar: true,
        snackMsg: "CSV file is being generated and it will download shortly ",
      });
      this.props
        .exportMetrics(
          this.props.deviceMetrics.deviceIds,
          this.props.deviceMetrics.selectedMetrics,
          this.props.timerange,
          this.props.deviceMetrics.selectedCIs,
          this.props.deviceMetrics.isInterface,
          //this.props.timerangeEndDate,
          finalEndDate,
          this.state.aggregationIntervalValue,
          this.state.aggregateFunction
        )
        .then(() => {
          let workbook = XLSX.utils.book_new();
          let metricNames = this.props.deviceMetrics.exportData.map(
            (mtr) => mtr.name
          );
          this.props.deviceMetrics.exportData.map((mtr, index) => {
            //metricNames.indexOf(mtr.name) == metricNames.lastIndexOf(mtr.name);
            let data = [[mtr.name], ["Time"]];
            let sheet_name = `${mtr.name.slice(0, 25)} ${index}`; //Sheet names matching when slicing upto 30 chars - always include index
            mtr.chartDefination.map((chartData) => {
              let dataArr = [chartData.sampleTime];
              mtr.deviceData.map((device) => {
                let dataKey = device.deviceName;
                if (device.ciName) {
                  dataKey = `${dataKey}-${device.ciName}`;
                }
                !data[1].includes(dataKey) && data[1].push(dataKey);
                dataArr.push(chartData[dataKey]);
              });
              data.push(dataArr);
            });
            var sheet_data = data;
            var worksheet = XLSX.utils.aoa_to_sheet(sheet_data);
            XLSX.utils.book_append_sheet(workbook, worksheet, sheet_name);
          });
          XLSX.writeFile(workbook, `${this.state.selectedMetric.name}.xlsx`);
          this.setState({
            openSnackBar: true,
            snackMsg: "CSV file exported successfully",
          });
        }).catch((error) => {
          console.log("error in csv", error);
          return this.setState({
            openSnackBar: true,
            snackMsg:
              error.response?.data?.error &&
                error.response.data.error == "No data present for the report."
                ? "No data present for the report."
                : "There was an unexpected error. Please try again after some time.",
          });
        });
    } else {
      let reqBody = this.props.deviceMetrics.selectedMetrics.map((mtr) => ({
        ids: encodeURI(this.props.deviceMetrics.deviceIds),
        metricTypeId: encodeURI(mtr),
        minutes: Math.floor(this.props.timerange * 60),
        timezone: encodeURI(Intl.DateTimeFormat().resolvedOptions().timeZone),
        //endTimeEpoch: this.props.timerangeEndDate,
        endTimeEpoch: finalEndDate,
        aggregationInterval: this.state.aggregationIntervalValue,
        appliedAggregateFuntion: this.state.aggregateFunction,
        ciNames: this.props.deviceMetrics.selectedCIs[mtr] || null,
      }));
      this.setState({
        openSnackBar: true,
        snackMsg: "PDF is being generated and it will download shortly ",
      });
      metricAPIs
        .generatePdf(
          this.state.selectedMetric.name,
          reqBody,
          this.props.sid,
          this.props.deviceMetrics.isInterface,
          this.state.pageSizeVal
        )
        .then((response) => {
          const url = window.URL.createObjectURL(response.data);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `${this.state.selectedMetric.name}.pdf`
          );
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          this.setState({
            openSnackBar: true,
            snackMsg: "PDF exported successfully",
          });
        })
        .catch((error) => {
          console.log("error in pdf", error);
          return this.setState({
            openSnackBar: true,
            snackMsg:
              error.response?.data?.error &&
                error.response.data.error == "No data present for the report."
                ? "No data present for the report."
                : "Report Export Failed.",
          });
        });
    }
  };

  handleSave = () => {
    let reqBody = {
      name: this.state.name,
      hours: this.props.timerange,
      isDefault: false,
      csIds: this.props.deviceMetrics.deviceIds.split(","),
      account: this.state.selectedAcc,
      metrics: this.props.deviceMetrics.selectedMetrics.map((mtrId) => ({
        metricTypeId: mtrId,
        ciNames: this.props.deviceMetrics.selectedCIs[mtrId]
          ? this.props.deviceMetrics.selectedCIs[mtrId]
          : [],
      })),
    };
    this.props
      .saveMetric(reqBody)
      .then(() =>
        this.setState({
          open: false,
          name: "",
          selectedAcc: [],
          isSharedView: false,
        })
      )
      .catch((err) => this.setState({ openSnackBar: true }));
  };

  handleTabChange = (event, value) => {
    this.setState({ tabValue: value, savedViewSearch: "" });
  };

  //dpo39841
  handleAggregationIntervalChange = (event, i) => {
    let value = event.target.value;
    this.setState({ aggregationIntervalValue: value, isInterpolation: value === -1 ? false : this.state.isInterpolation });
    let finalEndDate =
      this.state.selectedRange == "Custom"
        ? this.state.endDate
        : new Date().getTime();
    this.props.setTimerange(
      this.state.timeRangeValue,
      finalEndDate,
      value,
      this.state.aggregateFunction,
      this.state.isInterpolation
    );
    if (
      this.state.selectedMetric &&
      this.state.selectedMetric.definition &&
      this.state.selectedMetric.definition.length > 0
    ) {
      let metricDef = JSON.parse(this.state.selectedMetric.definition);
      let selectedCIs = {};
      let mtrIds = [];
      metricDef.metrics.map((mtr) => {
        selectedCIs[mtr.metricTypeId] = mtr.ciNames;
        mtrIds.push(mtr.metricTypeId);
      });

      this.props.getMetricForDevices(
        metricDef.csIds.join(),
        mtrIds,
        this.props.timerange,
        selectedCIs,
        this.props.deviceMetrics.isInterface,
        //this.props.timerangeEndDate,
        finalEndDate,
        value,
        this.state.aggregateFunction,
        this.state.isInterpolation,
      );
    } else {
      alert("Please select metric");
    }
    this.props.loadedView();
  };
  handleAggregateFunctionChange = (event, i) => {
    let value = event.target.value;
    this.setState({ aggregateFunction: value });
    let finalEndDate =
      this.state.selectedRange == "Custom"
        ? this.state.endDate
        : new Date().getTime();
    this.props.setTimerange(
      this.state.timeRangeValue,
      finalEndDate,
      this.state.aggregationIntervalValue,
      value,
      this.state.isInterpolation
    );
    if (
      this.state.selectedMetric &&
      this.state.selectedMetric.definition &&
      this.state.selectedMetric.definition.length > 0
    ) {
      let metricDef = JSON.parse(this.state.selectedMetric.definition);
      let selectedCIs = {};
      let mtrIds = [];
      metricDef.metrics.map((mtr) => {
        selectedCIs[mtr.metricTypeId] = mtr.ciNames;
        mtrIds.push(mtr.metricTypeId);
      });

      this.props.getMetricForDevices(
        metricDef.csIds.join(),
        mtrIds,
        this.props.timerange,
        selectedCIs,
        this.props.deviceMetrics.isInterface,
        //this.props.timerangeEndDate,
        finalEndDate,
        this.state.aggregationIntervalValue,
        value,
        this.state.isInterpolation,
      );
    } else {
      alert("Please select metric");
    }
    this.props.loadedView();
  };

  handleInterpolationChange = (event, i) => {
    let value = event.target.value;
    value = value === "Yes" ? true : false
    this.setState({ isInterpolation: value, openInterpoleSnackBar: value })
    let finalEndDate =
      this.state.selectedRange == "Custom"
        ? this.state.endDate
        : new Date().getTime();
    this.props.setTimerange(
      this.state.timeRangeValue,
      finalEndDate,
      this.state.aggregationIntervalValue,
      this.state.aggregateFunction,
      value
    );
    if (
      this.state.selectedMetric &&
      this.state.selectedMetric.definition &&
      this.state.selectedMetric.definition.length > 0
    ) {
      let metricDef = JSON.parse(this.state.selectedMetric.definition);
      let selectedCIs = {};
      let mtrIds = [];
      metricDef.metrics.map((mtr) => {
        selectedCIs[mtr.metricTypeId] = mtr.ciNames;
        mtrIds.push(mtr.metricTypeId);
      });

      this.props.getMetricForDevices(
        metricDef.csIds.join(),
        mtrIds,
        this.props.timerange,
        selectedCIs,
        this.props.deviceMetrics.isInterface,
        //this.props.timerangeEndDate,
        finalEndDate,
        this.state.aggregationIntervalValue,
        this.state.aggregateFunction,
        value,
      );
    } else {
      alert("Please select metric");
    }
    this.props.loadedView();
  };

  render() {
    const { columns, timerange, isFetching } = this.props;
    // const selectColumns = [
    //   { index: 1, icon: <ColumnOne />, title: "1 column", disabled: false },
    //   { index: 2, icon: <ColumnTwo />, title: "2 columns", disabled: this.props.deviceMetrics.metricsForDevices.length < 3 },
    // ].map(({ index, icon, title, disabled }) => (
    //   <Tooltip title={title}>
    //     <IconButton
    //       style={{ padding: 0, marginRight: "16px" }}
    //       shape='rounded'
    //       className={disabled ? 'graph-layout--disabled' : columns === index ? "graph-layout-active" : ""}
    //       // style={{ padding: '4px', margin: "0 4px" }}
    //       // className={columns === index ? "link--active" : ""}
    //       onClick={() => this.setNumberOfColumns(index)}
    //       key={index}
    //       disabled={disabled}
    //     >
    //       {icon}
    //     </IconButton>
    //   </Tooltip>
    // ));

    const selectColumns = [
      { index: 1, icon: <ColumnOne />, title: "1 column" },
      { index: 2, icon: <ColumnTwo />, title: "2 columns" },
      { index: 3, icon: <ColumnThree />, title: "3 columns" },
      { index: 4, icon: <ColumnFour />, title: "4 columns" },
    ].map(({ index, icon, title }) => (
      <Tooltip title={title}>
        <IconButton
          style={{ padding: '4px', margin: "0 4px" }}
          className={columns === index ? "graph-layout-active" : ""}
          onClick={() => this.setNumberOfColumns(index)}
          key={index}
        >
          {icon}
        </IconButton>
      </Tooltip>
    ));

    const actions = [
      <FlatButton
        children="Cancel"
        color="primary"
        variant="text"
        onClick={this.handleClose}
      />,
      <FlatButton
        variant="text"
        children="Save"
        color="primary"
        disabled={!this.state.name.length}
        onClick={this.handleSave}
      />,
    ];
    return (
      <div>
        {this.state.myViewLength || this.state.sharedViewLength ? (
          <div className="metrics-layout-nav" style={{ marginLeft: '16px', marginTop: '16px' }}>
            <Grid container spacing={2} >
              <Grid item xs={2}>

                <FormControl fullWidth>

                  <InputLabel id="selectMetricLabel" htmlFor="selectMetric" style={{ display: 'flex', flexDirection: 'row', height: '24px' }}>Select Metric
                    <div style={{ height: '0px', marginLeft: 'auto' }}>
                      <Tooltip title="Edit View">
                        <IconButton style={{ float: 'right', padding: '0px' }}
                          // <IconButton style={{float:'right',padding:'0px',top:'24px',marginRight:'2px',marginBottom:'2px'}}
                          onClick={this.editView}

                          disabled={this.state.selectedMetric.name ? false : true}
                        >
                          <EditIcon
                            style={{
                              fill: this.state.selectedMetric.name
                                ? "#3272D9"
                                : "#666666",
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </InputLabel>


                  <TextField labelId="selectMetriclabel"
                    key="selectedView" id="selectMetric"
                    placeholder="Select Metric"
                    value={this.state.selectedMetric.name || ""}
                    size="small"
                    onKeyDown={
                      (event) => {
                        if (event.key === "Enter") {
                          this.setState({ isOpen: event.currentTarget });
                          event.stopPropagation();
                        }
                      }
                    }
                    inputProps={{ readOnly: true, role: "combobox", 'aria-expanded': Boolean(this.state.isOpen), style: { paddingRight: '0px' } }}

                    InputProps={{
                      endAdornment: (<Tooltip title="Select Metric"><IconButton style={{
                        marginLeft: '-40px',
                        right: '-32px', outline: "none"
                      }} aria-label="Select Metric" onClick={(event) => {
                        this.setState({ isOpen: event.currentTarget });
                        event.stopPropagation();
                      }
                      }>


                        <ArrowDropDownIcon
                          style={{ transform: 'scale(1.9)', color: '#3272D9' }}
                          fontSize="large"
                          color="#3272D9"
                        />
                      </IconButton></Tooltip>
                      )
                    }}
                  />

                </FormControl>

              </Grid>

              {this.state.isOpen && (
                <Popover
                  open={Boolean(this.state.isOpen)}
                  anchorEl={this.state.isOpen}
                  onClose={() => this.setState({ isOpen: false })}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  style={{ width: "700px", left: "-200px", top: '5px' }}
                ><div style={{ width: "330px", margin: '16px' }}>
                    <Tabs variant="fullWidth"
                      value={this.state.tabValue}
                      onChange={this.handleTabChange}
                    >
                      <Tab variant="fullWidth"
                        label={`My Views (${this.state.myViewLength})`}
                        value={1}
                        /* buttonStyle={{
                          fontFamily: "inherit",
                          fontSize: "inherit",
                          fontWeight: "inherit",
                          textTransform: "none",
                        }} */
                        autoFocus

                      />
                      <Tab variant="fullWidth"
                        label={`Shared With Me (${this.state.sharedViewLength})`}
                        value={2}

                      />
                    </Tabs>
                  </div>
                  {this.state.tabValue == 1 && (
                    <div
                      className="drop-down-list"
                      style={{
                        display: "flex",
                        flexFlow: "column nowrap",
                        alignItems: "center",
                        margin: '16px'
                      }}
                    >
                      <TextField
                        key="filterText"
                        placeholder="Filter"
                        fullWidth
                        value={this.state.savedViewSearch}
                        onChange={this.handleSearchChange}
                      />
                      <ul
                        style={{
                          marginBlockStart: "1em",
                          marginBlockEnd: "0em",
                          marginInlineStart: "0px",
                          marginInlineEnd: "0px",
                          paddingInlineStart: "0px",
                        }}
                      >
                        {this.props.namedMetricList.MyViews.length ? (
                          this.props.namedMetricList.MyViews.filter(
                            (view) =>
                              view.name
                                .toLowerCase()
                                .indexOf(
                                  this.state.savedViewSearch.toLowerCase()
                                ) > -1 || !this.state.savedViewSearch
                          )?.length == 0 ? <div><Typography role="alert" component="span" style={{
                            display: 'table',
                            margin: '0 auto'
                          }}>No metric views found</Typography></div> : this.props.namedMetricList.MyViews.filter(
                            (view) =>
                              view.name
                                .toLowerCase()
                                .indexOf(
                                  this.state.savedViewSearch.toLowerCase()
                                ) > -1 || !this.state.savedViewSearch
                          ).map((option) => (
                            <button
                              className="drop-list-item"
                              onClick={this.onMetricClicked(option)}
                              key={option.id}
                              style={{
                                width: "300px",
                                backgroundColor: "transparent",
                                border: "none",
                              }}
                            >
                              <span style={{ width: "30px" }}>
                                {this.state.selectedMetric.id == option.id ? (
                                  <CheckIcon
                                    style={{
                                      color: "#3272D9",
                                      float: "left",
                                    }}
                                  />
                                ) : null}
                              </span>
                              <div
                                title={option.name}
                                className="truncate-li-text"
                                style={{ textAlign: "start" }}
                              >
                                {option.name}
                              </div>
                              <span style={{ float: "right", color: "grey" }}>
                                {option.viewType}
                              </span>
                            </button>
                          ))
                        ) : (
                          <div><Typography role="alert" component="span" style={{
                            display: 'table',
                            margin: '0 auto'
                          }}>No metric views found</Typography></div>
                        )}
                      </ul>
                    </div>
                  )}
                  {this.state.tabValue == 2 && (
                    <div className="drop-down-list" style={{ margin: '16px' }}>
                      <TextField fullWidth
                        key="filterText"
                        placeholder="Filter"
                        value={this.state.savedViewSearch}
                        onChange={this.handleSearchChange}

                      />
                      <ul
                        style={{
                          marginBlockStart: "1em",
                          marginBlockEnd: "0em",
                          marginInlineStart: "0px",
                          marginInlineEnd: "0px",
                          paddingInlineStart: "0px",
                        }}
                      >
                        {this.props.namedMetricList.SharedViews.length ? (
                          this.props.namedMetricList.SharedViews.filter(
                            (view) =>
                              view.name
                                .toLowerCase()
                                .indexOf(
                                  this.state.savedViewSearch.toLowerCase()
                                ) > -1 || !this.state.savedViewSearch
                          )?.length == 0 ? <div><Typography role="alert" component="span" style={{
                            display: 'table',
                            margin: '0 auto'
                          }}>No metric views found</Typography></div> : this.props.namedMetricList.SharedViews.filter(
                            (view) =>
                              view.name
                                .toLowerCase()
                                .indexOf(
                                  this.state.savedViewSearch.toLowerCase()
                                ) > -1 || !this.state.savedViewSearch
                          ).map((option) => (
                            <button
                              className="drop-list-item"
                              onClick={this.onMetricClicked(option)}
                              key={option.id}
                              style={{
                                width: "300px",
                                backgroundColor: "transparent",
                                border: "none",
                              }}
                            >
                              <span style={{ width: "30px" }}>
                                {this.state.selectedMetric.id == option.id ? (
                                  <CheckIcon
                                    style={{
                                      color: "#3272D9",
                                      float: "left",
                                    }}
                                  />
                                ) : null}
                              </span>
                              <div
                                title={option.name}
                                className="truncate-li-text"
                                style={{ textAlign: "start" }}
                              >
                                {option.name}
                              </div>
                            </button>
                          ))
                        ) : (
                          <div><Typography role="alert" component="span" style={{
                            display: 'table',
                            margin: '0 auto'
                          }}>No metric views found</Typography></div>
                        )}
                      </ul>
                    </div>
                  )}
                </Popover>
              )}
              <Grid item xs={2}>
                <FormControl>

                  <TimeRange
                    isFetching={isFetching}
                    value={timerange}
                    interval={this.state.aggregationIntervalValue}
                    onChange={(value, endDate, selectedRange) =>
                      this.onTimeChange(value, endDate, selectedRange)
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6} style={{ display: 'flex', flexDirection: 'row' }}>
                <FormControl >
                  <InputLabel id="metric-agr-level" htmlFor="aggInt" style={{ marginBottom: '4px', height: '24px' }}>Aggregation Interval</InputLabel>
                  <DropDownMenu
                    id="aggInt"
                    labelId="metric-agr-level"
                    onChange={this.handleAggregationIntervalChange}
                    size="small"

                    MenuProps={{
                      MenuListProps: {
                        'aria-label': `Aggregation Interval Options`
                      },
                    }}
                    role='combobox'
                    value={this.state.aggregationIntervalValue}
                    inputProps={{ "aria-labelledby": "metric-agr-level", role: 'combobox', 'aria-label': 'Aggregation Interval' }}
                  >
                    <MenuItem
                      value={-1}
                      label={aggregationIntervalRaw}
                      children={aggregationIntervalRaw}                      
                    />
                    <MenuItem
                      value={60}
                      label={aggregationInterval1Minute}
                      children={aggregationInterval1Minute}
                      disabled={!this.state.isEnableAggregationInterval}
                    />
                    <MenuItem
                      value={300}
                      label={aggregationInterval5Minutes}
                      children={aggregationInterval5Minutes}
                      disabled={!this.state.isEnableAggregationInterval}
                    />
                    <MenuItem
                      value={900}
                      label={aggregationInterval15Minutes}
                      children={aggregationInterval15Minutes}                      
                    />
                    <MenuItem
                      value={1800}
                      label={aggregationInterval30Minutes}
                      children={aggregationInterval30Minutes}                      
                    />
                    <MenuItem
                      value={3600}
                      label={aggregationInterval1Hour}
                      disabled={Math.round(timerange) == 1 ? true : false}
                      children={aggregationInterval1Hour}
                    />
                    <MenuItem
                      value={7200}
                      label={aggregationInterval2Hours}
                      disabled={
                        timerange < 2 ||
                          Math.round(timerange) == 2
                          ? true
                          : false
                      }
                      children={aggregationInterval2Hours}
                    />
                    <MenuItem
                      value={14400}
                      label={aggregationInterval4Hours}
                      disabled={
                        timerange < 4 ||
                          Math.round(timerange) == 4
                          ? true
                          : false
                      }
                      children={aggregationInterval4Hours}
                    />
                    <MenuItem
                      value={28800}
                      label={aggregationInterval8Hours}
                      disabled={
                        timerange < 8 ||
                          Math.round(timerange) == 8
                          ? true
                          : false
                      }
                      children={aggregationInterval8Hours}
                    />
                    <MenuItem
                      value={43200}
                      label={aggregationInterval12Hours}
                      disabled={
                        timerange < 12 ||
                          Math.round(timerange) == 12
                          ? true
                          : false
                      }
                      children={aggregationInterval12Hours}
                    />
                    <MenuItem
                      value={86400}
                      label={aggregationInterval1Day}
                      disabled={
                        timerange < 24 ||
                          Math.round(timerange) == 24
                          ? true
                          : false
                      }
                      children={aggregationInterval1Day}
                    />
                  </DropDownMenu>
                </FormControl>


                {this.state.aggregationIntervalValue == -1 ? null : (
                  <div>
                    <FormControl style={{ marginLeft: "24px", }}>
                      <InputLabel id="metric-agr-function" style={{ marginBottom: '4px' }}>Aggregate Function</InputLabel>
                      <DropDownMenu
                        onChange={this.handleAggregateFunctionChange}
                        style={{
                          width: "88px",


                        }} role='combobox'
                        size="small"
                        MenuProps={{
                          MenuListProps: {
                            'aria-label': `Aggregate function options`
                          },
                        }}
                        labelId="metric-agr-function"
                        value={this.state.aggregateFunction}
                        inputProps={{ "aria-labelledby": "metric-agr-function", role: 'combobox', 'aria-role': 'Aggregation Function' }}
                      >
                        <MenuItem value={"AVG"} label={"AVG"} children={"AVG"} />
                        <MenuItem value={"MIN"} label={"MIN"} children={"MIN"} />
                        <MenuItem value={"MAX"} label={"MAX"} children={"MAX"} />
                      </DropDownMenu>
                    </FormControl>

                  </div>
                )}
                <div>
                  <FormControl style={{ marginLeft: "24px", }}>
                    <InputLabel id="metric-interpolate" style={{ marginBottom: '5px' }}>Interpolate
                      <div
                        className="helpToolTip"
                        style={{ display: "inline-block" }}
                      >
                        <span tabIndex={0} style={{ cursor: "pointer", marginLeft: "9px" }}>
                          <span
                            className="tooltiptext"
                            style={{ marginTop: "1.5rem", marginRight: "2rem" }}
                          >
                            <div style={{ float: "left", paddingLeft: "16px", textAlign: "left", whiteSpace: "pre-wrap" }}>
                              <span
                                style={{ color: "#333333", fontFamily: "Roboto", fontSize: "12px" }}
                              >
                                {interpolationHelpText}
                              </span>
                            </div>
                          </span>
                          <HelpIcon className="helpIcon" title="Help" style={{ transform: 'translateY(-4px)' }} />
                        </span>
                      </div>
                    </InputLabel>
                    <DropDownMenu
                      onChange={this.handleInterpolationChange}
                      style={{
                        width: "88px",


                      }} role='combobox'
                      size="small"
                      MenuProps={{
                        MenuListProps: {
                          'aria-label': `Interpolate options`
                        },
                      }}
                      labelId="metric-interpolate"
                      value={this.state.isInterpolation ? "Yes" : "No"}
                      inputProps={{ "aria-labelledby": "metric-interpolate", role: 'combobox', 'aria-role': 'Interpolate' }}
                    >
                      <MenuItem value={"No"} label={"No"} children={"No"} />
                      <MenuItem value={"Yes"} label={"Yes"} children={"Yes"} />
                    </DropDownMenu>
                  </FormControl>
                </div>
                <FormControl style={{ marginLeft: "10px", marginTop: '24px' }}>
                  <Tooltip title={"Export View"}>
                    <IconButton
                      onClick={this.handleDrawer}
                      disabled={!this.state.selectedMetric.name}

                    >
                      <ExitToAppIcon sx={{ fontSize: 80 }} />
                    </IconButton>
                  </Tooltip>
                </FormControl>
              </Grid>
            </Grid>
            <div className="content-wrapper">
              {/*<div className="changeColumns">{selectColumns}</div>*/}
            </div>
            {/* <div className="right-section">
              <Button
                variant='text'
                color='#1885c7'
                style={{height: '32px', textTransform: 'none', width: '105px', padding: 0}}
                startIcon={<AddIcon />}
                onClick={() => this.handleView(1)}
              >
                New View
              </Button>
              <div style={{width: '0px', height: '10px', border: '1px solid #DDE3ED', marginRight: '5px'}}></div>
              <Button
                variant='text'
                color='#1885c7'
                style={{height: '32px', textTransform: 'none', width: '105px', padding: 0}}
                onClick={() => this.handleView(3)}
              >
                Manage Views
              </Button>
            </div> */}
            <Snackbar
              key="snackbar"
              open={this.state.openSnackBar}
              message={
                this.state.snackMsg || this.props.deviceMetrics.errorInSaving
              }
              autoHideDuration={4000}
              onClose={this.handleCloseSnackBar}
            />
            <Snackbar
              key="snackbar"
              open={false}
              message={"Interpolating missing QOS values for the selected metrics."}
              autoHideDuration={4000}
              onClose={this.handleCloseInterpoleSnackBar}
            />
            <Drawer
              anchor="right"
              open={this.state.openDrawer}
              className="edit-metrics"
              onClose={this.handleDrawer}
            >
              <div
                style={{
                  width: "480px",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ margin: '16px' }}>
                  <div className="drawer-header">
                    <h1 className="drawer-title">Export View</h1>
                    <Tooltip title="Close">
                      <IconButton onClick={this.handleDrawer}>
                        <ClearIcon className="clearIcon" />
                      </IconButton>
                    </Tooltip>
                  </div>
                  <h2 className="drawer-name">
                    {this.state.selectedMetric.name}
                  </h2>
                  <div>
                    <FormControl fullWidth style={{ marginTop: '8px' }}>
                      <InputLabel >Export As</InputLabel>
                      <DropDownMenu fullWidth role='combobox'
                        value={this.state.exportVal}
                        //underlineStyle={{ display: "none" }}

                        inputProps={{ 'aria-label': 'Export As', role: 'combobox' }}
                        MenuProps={{
                          MenuListProps: {
                            'aria-label': `Export options`
                          },
                        }}
                        onChange={(event) =>
                          this.setState({ exportVal: event.target.value })
                        }
                      >
                        <MenuItem value="pdf" children="PDF" />
                        <MenuItem value="csv" children="CSV" />
                      </DropDownMenu>
                    </FormControl>

                    {this.state.exportVal == "pdf" ? (
                      <FormControl fullWidth disable={this.state.exportVal == "csv"} style={{ marginTop: '16px' }}>
                        <InputLabel>Page Size</InputLabel>
                        <DropDownMenu role='combobox'
                          fullWidth
                          value={this.state.pageSizeVal}

                          MenuProps={{
                            MenuListProps: {
                              'aria-label': `Page size options`
                            },
                          }}
                          inputProps={{ 'aria-label': 'Page Size', role: 'combobox' }}
                          onChange={(event) =>
                            this.setState({ pageSizeVal: event.target.value })
                          }
                        >
                          <MenuItem value="0" children="A0" />
                          <MenuItem value="1" children="A1" />
                          <MenuItem value="2" children="A2" />
                          <MenuItem value="3" children="A3" />
                          <MenuItem value="4" children="A4" />
                          <MenuItem value="5" children="A5" />
                        </DropDownMenu>
                      </FormControl>
                    ) : null}
                  </div>
                </div>
                <div
                  style={{
                    padding: "15px",
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <Button
                    variant="text"
                    onClick={this.handleDrawer}
                    style={{ marginRight: "16px" }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={this.handleExport}
                    disabled={this.state.selectedMetric == 0}
                  >
                    Export
                  </Button>
                </div>
              </div>
            </Drawer>
          </div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const timerange = state.timerange.hours;
  const timerangeEndDate = state.timerange.endDate;
  const isFetching = state.deviceMetrics.isFetching;
  const deviceMetrics = state.deviceMetrics;
  const namedMetricList = state.deviceMetrics.named_metric_list;
  const namedMetricData = state.deviceMetrics.named_metric_data;

  let columns = state.columns;
  if (!columns) {
    columns = ownProps.columns || 1;
  }

  const options = {
    timerange,
    timerangeEndDate,
  };

  return {
    columns,
    timerange,
    options,
    isFetching,
    deviceMetrics,
    namedMetricList,
    namedMetricData,
    timerangeEndDate,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setColumns: (n) => dispatch(setColumns(n)),
    setTimerange: (n, endDate, interval, aggr_function, isInterpolation = false) =>
      dispatch(setTimerange(n, endDate, interval, aggr_function, isInterpolation)),
    saveMetric: (data) => dispatch(saveMetric(data)),
    fetchAllMetric: (body) => dispatch(fetchAllMetric(body)),
    fetchMetricById: (data) => dispatch(fetchMetricById(data)),
    getMetricForDevices: (...data) => dispatch(getMetricDevices(...data)),
    exportMetrics: (...data) => dispatch(exportMetrics(...data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Nav);
