export const removeRepeatDevices = (arr) => {
  if (!arr.length) return [];

  const cb = (acc, current, index, self) => {
    const deviceIndex = self.findIndex((el) => el.csId === current.csId);

    if (deviceIndex === index) return acc.concat(current);

    if (!self[deviceIndex]?.checked) return acc.concat(current);

    return acc;
  };

  return arr.reduce(cb, []).sort((a) => a?.checked && -1);
};
