import React from "react";
import connect from "./../../utils/connect";
import CabiDetails from "./CabiDetails";
import { addBreadCrumb } from "./../breadcrumbs/actions";
import { setShowTimeRange } from "./../../api/cabi/actions";

const CabiContainer = (props) => {
  return (
    <div className="cabi__main--treeview || cabi__main--groups">
      <CabiDetails {...props} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    sid: state.sid,
    entity: state.entity,
    timeRange: state.cabiInput.timeRange,
    start_date: state.cabiInput.start_date,
    end_date: state.cabiInput.end_date,
    saas: state.saas,
    doiurl: state.user.doiurl,
    featureFlags: state.configuration.items,
    breadcrumbs: state.breadCrumbs,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addBreadCrumb: (item) => dispatch(addBreadCrumb(item)),
    setShowTimeRange: (showTimeRange) =>
      dispatch(setShowTimeRange(showTimeRange)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CabiContainer);
