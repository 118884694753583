export const PAGE = {
    CONFIGURE: 'configure',
    RELAY_HUB: 'relayHub',
    DEPLOY: 'deploy',
    MULTITEMPLATE:'mutlitemplate',
    DONE: 'done'
};
function SetupWizardFlow() {

    var step1Label = 'Install Hub';

    const cloud = (activeStep, dialogProps) => {
        var flow = {};
        flow.activePage = PAGE.CONFIGURE;
        return flow;
    }

    const remote = (activeStep, dialogProps) => {
        var flow = {};
        //Step - "0" Install replay hub screen
        if (activeStep === 0) {
            flow.activePage = PAGE.RELAY_HUB;
        }
        //Step - "1" Deploy Host device screen
        if (activeStep === 1) {
            flow.activePage = PAGE.DEPLOY;
        }
        //Step - "2" Configure profile screen screen
        if (activeStep === 2) {
            flow.activePage = PAGE.CONFIGURE;
        }
        if(activeStep === 3)
            flow.activePage = PAGE.MULTITEMPLATE;
        flow.step1Label = step1Label;

        flow.step2Label = dialogProps.probe.monitoringAgentRequired ? 'Deploy Monitoring Agent' : 'Deploy Monitoring Host';
        flow.step3Label = `Create Profile`;
        flow.step4Label = `Customize Monitoring`;
        return flow;
    }

    const local = (activeStep, dialogProps) => {
        var flow = {};
        //Step - "0" Install replay hub screen
        if (activeStep === 0) {
            flow.activePage = PAGE.RELAY_HUB;
        }
        //Step - "1" Deploy Host device screen
        if (activeStep === 1) {
            flow.activePage = PAGE.DEPLOY;
        }
        //Step - "2" Configure App discovery screen screen
        if (activeStep === 2) {
            if (dialogProps.probe.appDiscoveryEnabled) {
                flow.activePage = PAGE.DONE;
            } else {
                flow.activePage = PAGE.CONFIGURE;
            }
            //flow.activePage = PAGE.DONE;
        }
        flow.step1Label = step1Label;
        flow.step2Label = 'Add Devices';
        flow.step3Label = dialogProps.probe.appDiscoveryEnabled ? `Enable Monitoring` : flow.step3Label = `Create Profile`;

        return flow;
    }



    return {
        cloud: cloud,
        remote: remote,
        local: local
    };

};
let setupWizardFlow = new SetupWizardFlow();
export default setupWizardFlow;
