// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Severity
  content["Severity"] =
  "重大度";

  //Alarm Severity
  content["Alarm Severity"] =
  "アラーム重大度";

  //Message
  content["Message"] =
  "メッセージ";

  //Device
  content["Device"] =
  "デバイス";

  //Type
  content["Type"] =
  "タイプ";

  //Created
  content["Created"] =
  "作成日時";

  //Duration
  content["Duration"] =
  "期間";

  //No alarms found
  content["No alarms found"] =
  "アラームが見つかりませんでした";

  //Name
  content["Name"] =
  "名前";

  //OS Type
  content["OS Type"] =
  "OS タイプ";

  //OS Name
  content["OS Name"] =
  "OS 名";

  //OS Description
  content["OS Description"] =
  "OS 説明";

  //Primary Role
  content["Primary Role"] =
  "プライマリ役割";

  //IP Addresses
  content["IP Addresses"] =
  "IP アドレス";

  //Primary MAC Address
  content["Primary MAC Address"] =
  "プライマリ MAC アドレス";

  //Number Of Cores
  content["Number Of Cores"] =
  "コアの数";

  //Computer Name
  content["Computer Name"] =
  "コンピュータ名";

  //VM Name
  content["VM Name"] =
  "VM 名";

  //Virtualization Environment
  content["Virtualization Environment"] =
  "仮想化環境";

  //Physical Serial Number
  content["Physical Serial Number"] =
  "物理シリアル番号";

  //System Name
  content["System Name"] =
  "システム名";

  //Vendor
  content["Vendor"] =
  "ベンダー";

  //Model
  content["Model"] =
  "モデル";

  //Firmware Version
  content["Firmware Version"] =
  "ファームウェア バージョン";

  // ---- VMWARE ----
  //Power State
  content["Power State"] =
  "電源状態";

  //Tools Version
  content["Tools Version"] =
  "ツール バージョン";

  //Tools Status
  content["Tools Status"] =
  "ツール ステータス";

  //Resource Pool
  content["Resource Pool"] =
  "リソース プール";

  //Hostname
  content["Hostname"] =
  "ホスト名";

  // ---- AWS ----
  //AWS Instance State
  content["AWS Instance State"] =
  "AWS インスタンス状態";

  //AWS Availability Zone
  content["AWS Availability Zone"] =
  "AWS 使用可能ゾーン";

  //AWS VPC
  content["AWS VPC"] =
  "AWS VPC";

  //AWS Instance Type
  content["AWS Instance Type"] =
  "AWS インスタンス タイプ";

  //AWS Monitoring State
  content["AWS Monitoring State"] =
  "AWS モニタリング状態";

  //Max Alarm Severity
  content["Max Alarm Severity"] =
  "最大アラーム重大度";

  //Total Alarms
  content["Total Alarms"] =
  "合計アラーム数";

  //No Roles found
  content["No Roles found"] =
  "役割が見つかりませんでした";

  // END OF LOCALIZATION

export default content;
