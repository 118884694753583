// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Relay hubs & robots
  content["Relay hubs & robots"] =
  "Relay hubs & robots";

  //Managed via
  content["Managed via"] =
  "Managed via";

  //Admin Console
  content["Admin Console"] =
  "Admin Console";

  //Managed via
  content["Manage hubs,robots & probes"] =
  "Manage hubs,robots & probes";

  //Deleted devices
  content["Deleted devices"] =
  "Deleted devices";

  //Available for restore
  content["Available for restore"] =
  "Available for restore";

  //Personalize
  content["Personalize"] =
  "Personalize";

  //personalizeDesc
  content["Configure default operator console view"] =
  "Configure default operator console view";

  //Product Usage
  content["Product Usage"] =
  "Product Usage";

  //View usage metrics for this product instance.
  content["View usage metrics for this product instance."] =
  "View usage metrics for this product instance.";

  //Name
  content["Name"] =
  "Name";

  //IP address
  content["IP address"] =
  "IP address";

  //Role
  content["Role"] =
  "Role";

  //OS Type
  content["OS Type"] =
  "OS Type";

  //OS Name
  content["OS Name"] =
  "OS Name";

  //OS Version
  content["OS Version"] =
  "OS Version";

  //Settings
  content["Settings"] =
  "Settings";

  //Deleted Devices
  content["Deleted Devices"] =
  "Deleted Devices";

  //Filter
  content["Filter"] =
  "Filter";

  //Cancel
  content["Cancel"] =
  "Cancel";

  //Restore
  content["Restore"] =
  "Restore";

  // END OF LOCALIZATION

export default content;
