// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Add
  content["Add"] =
  "Add";

  //Setup complete
  content["Setup complete"] =
  "Setup complete";

  //ServiceNow Ticketing
  content["ServiceNow Ticketing"] =
  "ServiceNow Ticketing";

  //Settings
  content["Settings"] =
  "Settings";

  //ServiceNow
  content["ServiceNow "] =
  "ServiceNow ";

  //Invalid format: ###-###-####
  content["Invalid format: ###-###-####"] =
  "Invalid format: ###-###-####";

  //CANCEL
  content["CANCEL"] =
  "CANCEL";

  //DELETE
  content["DELETE"] =
  "DELETE";

  //This action removes the ServiceNow integration.
  content["This action removes the ServiceNow integration."] =
  "This action removes the ServiceNow integration.";

  //Alarms will no longer be synchronized between CA Infrastructure Management and ServiceNow.
  content["Alarms will no longer be synchronized between CA Infrastructure Management and ServiceNow."] =
  "Alarms will no longer be synchronized between CA Infrastructure Management and ServiceNow.";

  //Delete integration?
  content["Delete integration?"] =
  "Delete integration?";

  //URL
  content["URL"] =
  "URL";

  //User name
  content["User name"] =
  "User name";

  //Password
  content["Password"] =
  "Password";

  //DefaultGroupName
  content["Default group name"] =
  "Default group name";

  //Create new ServiceNow tickets when Infrastructure Management generates alarms with the following severities:
  content["Create new ServiceNow tickets when Infrastructure Management generates alarms with the following severities:"] =
  "Create new ServiceNow tickets when Infrastructure Management generates alarms with the following severities:";

  //ServiceNow integration
  content["ServiceNow integration"] =
  "ServiceNow integration";

  //instruction msg
  content["This integration acts as a gateway between the Infrastructure Management and ServiceNow Incident Management system. The integration generates an incident ticket in the Service desk that is based on the Infrastructure Management alarm. Generating an incident helps the ServiceNow user to take corrective actions to resolve the issue. The integration periodically polls the service desk for the status of incidents and updates the corresponding alarms."] =
  "This integration acts as a gateway between the Infrastructure Management and ServiceNow Incident Management system. The integration generates an incident ticket in the Service desk that is based on the Infrastructure Management alarm. Generating an incident helps the ServiceNow user to take corrective actions to resolve the issue. The integration periodically polls the service desk for the status of incidents and updates the corresponding alarms.";

  //Enable Group Assignment
  content["Enable Group Assignment"] =
  "Enable Group Assignment";

  //Auto close alarm
  content["Auto close alarm"] =
  "Auto close alarm";

  //Auto close tickets
  content["Auto close tickets"] =
  "Auto close tickets";

  //Severity levels
  content["Severity levels"] =
  "Severity levels";

  //Ticket on Severity Change
  content["Ticket on Severity Change"] =
  "Ticket on Severity Change";

  //Communication Error
  content["Communication Error"] =
  "Communication Error";

  //Critical
  content["Critical"] =
  "Critical";

  //Major
  content["Major"] =
  "Major";

  //MInor
  content["Minor"] =
  "Minor";

  //Warning
  content["Warning"] =
  "Warning";

  //Info
  content["Info"] =
  "Info";

  //Ticket on severity chnage
  content["Ticket on severity change"] =
  "Ticket on severity change";

  //Specify the base URL for the ServiceNow instance.The default format is: https://instancename.service-now.com.
  content["Specify the base URL for the ServiceNow instance.The default format is: https://instancename.service-now.com."] =
  "Specify the base URL for the ServiceNow instance.The default format is: https://instancename.service-now.com.";

  //Enter the ServiceNow admin username.
  content["Enter the ServiceNow admin username."] =
  "Enter the ServiceNow admin username.";

  //Enter the ServiceNow admin password.
  content["Enter the ServiceNow admin password."] =
  "Enter the ServiceNow admin password.";

  //Toggle to activate assigning tickets and alarms to Groups in Infrastructure Manager.
  content["Toggle to activate assigning tickets and alarms to Groups in Infrastructure Manager."] =
  "Toggle to activate assigning tickets and alarms to Groups in Infrastructure Manager.";

  //Specify the default Group to which ServiceNow tickets and alarms are assigned.
  content["Specify the default Group to which ServiceNow tickets and alarms are assigned."] =
  "Specify the default Group to which ServiceNow tickets and alarms are assigned.";

  //Select to configure alarms to close automatically.
  content["Select to configure alarms to close automatically."] =
  "Select to configure alarms to close automatically.";

  //Select to configure tickets to close automatically.
  content["Select to configure tickets to close automatically."] =
  "Select to configure tickets to close automatically.";

  //Specify the severity of alarms in Infrastructure Manager which will create incidents in ServiceNow.
  content["Specify the severity of alarms in Infrastructure Manager which will create incidents in ServiceNow."] =
  "Specify the severity of alarms in Infrastructure Manager which will create incidents in ServiceNow.";

  // Ticket on Sev Change Message
  content["Select the option to create a ticket in ServiceNow when the severity of an existing ticket changes. If you do not select the option, only the severity of existing ticket changes."] =
  "Select the option to create a ticket in ServiceNow when the severity of an existing ticket changes. If you do not select the option, only the severity of existing ticket changes.";

  //SAVE
  content["SAVE"] =
  "SAVE";

  // END OF LOCALIZATION

export default content;
