// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  // Alarms
  content["Alarms"] =
  "Alarms";

  // Dashboards
  content["Dashboards"] =
  "Dashboards";

  // Metrics
  content["Metrics"] =
  "Metrics";

  // END OF LOCALIZATION

export default content;
