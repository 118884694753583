import React, { Component } from 'react'
import {Grid} from '../ui-components/uim-components'
import './McsDashBoard.less'
import { defaultFiltersActiveObjects } from './filter/DefaultFilters'
import TableFilters from './filter/TableFilters'
import './filter/tableFilters.less'
import Resource from './Resource'
import {
  Button
 } from "@mineral/core";
import { Typography } from '@mineral/core'

let tabForward;
let tabBackward;
function getAallFocussableElementsOf(el) {
  return Array.from(
    el.querySelectorAll(
      'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
    )
  ).filter((el) => {
    return el.tabIndex !== -1;
  });
}
const GroupProfileTable = (props) => {
    const operatorConversion =(operator, feToBe) =>{
        let operatorMap = new Map();
        let updatedOp = operator;
        if (feToBe) {
          operatorMap.set("greaterThan", "GT");
          operatorMap.set("greaterThanOrEqual", "GTE");
          operatorMap.set("lessThan", "LT");
          operatorMap.set("lessThanOrEqual", "LTE");
        } else {
          operatorMap.set("GT", "greaterThan");
          operatorMap.set("GTE", "greaterThanOrEqual");
          operatorMap.set("LT", "lessThan");
          operatorMap.set("LTE", "lessThanOrEqual");
          operatorMap.set("EQUALS", "equals");
          operatorMap.set("CONTAINS", "contains");
          operatorMap.set("STARTSWITH", "startsWith");
          operatorMap.set("ENDSWITH", "endsWith");
        }
        if (operatorMap.has(operator)) {
          updatedOp = operatorMap.get(operator);
        }
        return updatedOp;
      }
    const handleUpdateFilters = (filters) => {
      if (false)
        if (filters.colFilters) {
          const readyColumnFilter = [];
          filters.colFilters.forEach((colFilter) => {
            let fieldName = colFilter.column;
            let operator = operatorConversion(colFilter.operator, true);
            let isNegated =
              operator.toUpperCase() === "NOTEQUALS" ||
              operator.toUpperCase() === "NOTCONTAINS"
                ? true
                : false;
            let operator_final =
              operator.toUpperCase() === "NOTEQUALS"
                ? "EQUALS"
                : operator.toUpperCase() === "NOTCONTAINS"
                ? "CONTAINS"
                : operator.toUpperCase();
            readyColumnFilter.push({
              column: fieldName,
              operator: operator_final.toUpperCase(),
              value: colFilter.value,
              negated: isNegated,
            });
          });
          //this.props.setInventorySearchData(filters);
    
          //this.props.handleSaveSelectedColumns(null, readyColumnFilter);
        }
    };
    const getDataCount = (data) =>{
      return data?.props?.profiles?.data?.totalCount || 0;
    }
    const onSuppressKeyboardEvent=(params)=> {
      let key = params.event.key;
      let shiftKey = params.event.shiftKey;
      let isTabbingForward = key === 'Tab' && shiftKey === false;
      let isTabbingBackWards = key === 'Tab' && shiftKey === true;
  
      // Handle cell children tabbing
      if (isTabbingForward || isTabbingBackWards) {
        tabForward = isTabbingForward;
        tabBackward = isTabbingBackWards;
  
        if (params.editing) {
          return false;
        }
  
        let eGridCell = params.event.path.find((el) => {
          if (el.classList === undefined) return false;
          return el.classList.contains('ag-cell');
        });
  
        let focusableChildrenElements = getAallFocussableElementsOf(eGridCell);
        let lastCellChildEl =
          focusableChildrenElements[focusableChildrenElements.length - 1];
        let firstCellChildEl = focusableChildrenElements[0];
  
        //  FORWARD
        // allow to movee to next cell when the cells last element is focused
        if (isTabbingForward && focusableChildrenElements.length > 0) {
          let isLastChildFocused =
            lastCellChildEl && document.activeElement === lastCellChildEl;
  
          if (isLastChildFocused === false) {
            return true;
          }
        }
  
        //  BACKWARDS
        //  focus last element if none are focused
        //  allow to tab backwards when first element is focused
        if (isTabbingBackWards && focusableChildrenElements.length > 0) {
          let cellHasFocusedChildren =
            eGridCell.contains(document.activeElement) &&
            eGridCell !== document.activeElement;
  
          if (!cellHasFocusedChildren) {
            params.event.preventDefault();
            lastCellChildEl.focus();
          }
  
          let isFirstChildFocused =
            firstCellChildEl && document.activeElement === firstCellChildEl;
  
          if (isFirstChildFocused === false) {
            return true;
          }
        }
      }
  
      return false;
    }
    const onSuppressHeaderKeyboardEvent=(params) =>{
      let key = params.event.key;
      let shiftKey = params.event.shiftKey;
      let isTabbingForward = key === 'Tab' && shiftKey === false;
      let isTabbingBackWards = key === 'Tab' && shiftKey === true;
      // Handle cell children tabbing
  
      if (isTabbingForward || isTabbingBackWards) {
        let eGridCell = params.event.path.find((el) => {
          if (el.classList === undefined) return false;
          return el.classList.contains('ag-header-cell');
        });
  
        let focusableChildrenElements = getAallFocussableElementsOf(eGridCell);
        let lastCellChildEl =
          focusableChildrenElements[focusableChildrenElements.length - 1];
        let firstCellChildEl = focusableChildrenElements[0];
  
        //  FORWARD
        // allow to movee to next cell when the cells last element is focused
        if (isTabbingForward && focusableChildrenElements.length > 0) {
          let isLastChildFocused =
            lastCellChildEl && document.activeElement === lastCellChildEl;
  
          if (isLastChildFocused === false) {
            return true;
          }
        }
  
        //  BACKWARDS
        //  focus last element if none are focused
        //  allow to tab backwards when first element is focused
        if (isTabbingBackWards && focusableChildrenElements.length > 0) {
          let cellHasFocusedChildren =
            eGridCell.contains(document.activeElement) &&
            eGridCell !== document.activeElement;
          if (!cellHasFocusedChildren) {
            params.event.preventDefault();
  
            lastCellChildEl.focus();
          }
  
          let isFirstChildFocused =
            firstCellChildEl && document.activeElement === firstCellChildEl;
  
          if (isFirstChildFocused === false) {
            return true;
          }
        }
      }
  
      return false;
    }
    const onCellFocused=(params)=> {
      if (tabForward) {
        let cell = document.activeElement;
        let focusableChildren = getAallFocussableElementsOf(cell);
  
        if (focusableChildren.length > 0) {
          focusableChildren[0].focus();
        }
  
        tabForward = false;
      }
  
      if (tabBackward) {
        let cell = document.activeElement;
        let focusableChildren = getAallFocussableElementsOf(cell);
  
        if (focusableChildren.length > 0) {
          focusableChildren[focusableChildren.length - 1].focus();
        }
  
        tabBackward = false;
      }
    }

  //No Data found. You may define a new filter criteria.
  const NODATAFOUND=Resource.get("No Data found. You may define a new filter criteria.");

  //Export Data
  const EXPORTDATA=Resource.get("Export Data")
  const overlayMessageTemplate = <span>{NODATAFOUND}</span>
    return (
      <>

<div className="grouptable_header" style={{display:'flex',flexDirection:'row'}} >
             <Typography variant='h4' component="h2" style={{width:'150px'}} >{props.tableHeading}</Typography>
             <TableFilters
              defaultFilters={defaultFiltersActiveObjects(
                props.columnDefs
               
              )}
              handleUpdateFilters={props.onFilterChanged}
              searchResultsNumber={getDataCount(props.context)}
             
            />
            <span className='col_filter'>{(props.filter===undefined || props.filter==='')?'':`Filtered: `+props.filter}</span>
            <Button variant='text' onClick={props.onBtnClickExport} className="exportbutton" disableRipple={true}>{EXPORTDATA}</Button>  </div>  
        <div className="ag-material tablecontainer grouptable" style={props.currentHeight?{height:props.currentHeight}:{}}>

      
            <Grid

                rowClass={'policyRows'}
                
                rowBuffer={0}
                rowHeight={48}
                headerHeight={36}
                columnDefs={props.columnDefs}
               
                onGridReady={props.onGridReady}
                cacheQuickFilter={false}
                onRowClicked={props.onRowClicked}
                onCellFocused={onCellFocused}
                        
                          suppressCellFocus={true}
                          suppressKeyboardEvent= {onSuppressKeyboardEvent}
                          suppressHeaderKeyboardEvent= {onSuppressHeaderKeyboardEvent}
                          overlayNoRowsTemplate={overlayMessageTemplate}     
            
            onGridSizeChanged={props.onGridSizeChanged}
            onFilterChanged = {props.onFilterChanged}
            rowModelType={props.rowModelType}
            cacheBlockSize={props.cacheBlockSize}
            cacheOverflowSize={props.cacheOverflowSize}
            maxConcurrentDatasourceRequests = {props.maxConcurrentDatasourceRequests}
            maxBlocksInCache = {props.maxBlocksInCache}
            enableSorting={props.enableSorting}
            onSortChanged={props.onSortChanged}
            context ={props.context}
            />
        </div>
        </>
    )
}

export default GroupProfileTable;
