import React, { Component} from "react";
import PropTypes from 'prop-types'
import { withRouter } from "react-router-dom";
import {
  Dialog,
  DialogTitle,
  Button as FlatButton,
  RadioGroup as RadioButtonGroup,
  Radio as RadioButton,
  TextField,
  MenuItem,
  Select as SelectField,
  FormControl,
  InputLabel,
  DialogActions,
} from "@mineral/core";
import Resource from "./Resource";
import get from "lodash/get";
import "./dialogButtonStyle.less";

class AddSubgroupDialog extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
  };
  constructor(props) {
    super(props);
    this.state = {
      parentId: props.selectedId,
      name: null,
      type: "STATIC",
      isDynamicChecked: false,
      saveError: null,
      addButtonDisabled: true,
      elementType: "DEVICES",
      accounts: [],
      accountId: 0,
    };
    this.entities = props.entities;
  }
  componentDidMount() {
    this.props.getAccount();
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      parentId: nextProps.selectedId,
      saveError: null,
    });
    if (nextProps.accounts && nextProps.accounts.length > 0) {
      this.setState({
        accounts: nextProps.accounts,
      });
      if (!this.props.acl.isBusUser) {
        this.setState({
          accountId: nextProps.accounts[0].accountId,
        });
      }
    }
    this.entities = nextProps.entities;
  }
  handleNameChange = (event) => {
    const newName = event.target.value;
    const siblings = this.entities.map((entity) => entity.name.toLowerCase());
    if (newName.length > 255) {
      this.setState({
        //Namemustbelessthan256 characters message
        nameError: Resource.get("Name must be less than 256 characters"),
        name: newName,
        addButtonDisabled: true,
      });
    } else if (siblings.includes(newName.toLowerCase())) {
      this.setState({
        //Groupnamelareadyexists message
        nameError: Resource.get("Group name already exists"),
        name: newName,
        addButtonDisabled: true,
      });
    } else if (newName.length < 1) {
      this.setState({
        name: newName,
        addButtonDisabled: true,
      });
    } else {
      this.setState({
        nameError: null,
        name: newName,
        addButtonDisabled: false,
      });
    }
  };
  handleTypeChange = (event) => {
    this.setState({ type: event.target.value });
  };
  handleElementChange = (event) => {
    this.setState({ elementType: event.target.value });
  };
  resetState = () => {
    this.setState({
      saveError: null,
      isDynamicChecked: false,
      addButtonDisabled: true,
      name: null,
      type: "STATIC",
      elementType: "DEVICES",
      accountId: 0,
    });
  };
  render() {
    // A group may contain either devices or additional groups, not a mix.
    const helplabelmessage = Resource.get(
      " A group may contain either devices or additional groups, not a mix."
    );
    // This group will contain...
    const helplabelmessage1 = Resource.get("Group Type"); //Groupt Type from Static, Dynamic or Contianer
    const Account = Resource.get("Account"); //Account for Group
    const dynamicLabel = Resource.get("Dynamic Group"); // Dynamic Group
    const elementTypeLabel = Resource.get("Element Type"); // Element Type
    const no_account = Resource.get("No Account"); //Default value for dropdown
    const genericError = this.state.saveError;
    //let isUserAdmin = this.props.acl.acl === AclTypes.ADMINISTRATOR;
    let canModifyGroup = get(this.props.acl.aclInfo, "roles", "").includes(
      "USM Group Modification"
    );
    let canAddGroup = get(this.props.acl.aclInfo, "roles", "").includes(
      "OC Group Add"
    );
    let accountOptions =
      this.state.accounts &&
      this.state.accounts.map((item) => (
        <MenuItem
          value={item.accountId}
          label={item.contactName}
          children={item.contactName}
        />
      ));

    let finalAccounts = this.props.acl.isBusUser ? accountOptions : [];

    const actions = [
      <FlatButton
        variant="text"
        //Cancel
        children={Resource.get("Cancel")}
        color="primary"
        onClick={this.onClose}
      />,
      <FlatButton
        variant="text"
        //Add
        children={Resource.get("Add")}
        color="primary"
        onClick={this.handleSave}
        disabled={this.state.addButtonDisabled}
      />,
    ];
    let dialog = (
      <Dialog
        style={{ width: "300px", paddingBottom: "0px", paddingTop: "0px" }}        
        modal={false}
        open={this.props.open}
        aria-labelledby="dialog-title"
        className= "dialogConatiner"
      >
        <DialogTitle id="dialog-title"> {"Add group"}</DialogTitle>
        <TextField
          //Group Name
          placeholder={Resource.get("Group Name")}
          //Group name
          label={Resource.get("Group name")}
          sx={{
   input: {
    
    "&::placeholder": {  // <----- Add this.
      color: 'black !important',
      opacity:0.8
     
    },
   }}}
          onChange={this.handleNameChange}
          autoFocus={true}
          helperText={this.state.nameError}
        />

        {/* <p style={{ fontSize: "16px" }}>{Account}</p> */}
        <FormControl>
          <InputLabel htmlFor="grouped-select">{Account}</InputLabel>
          <SelectField
            // className="input-text select-field"
            // style={{
            //   border: "1px solid #CCCCCC",
            //   borderRadius: "3px",
            //   display: "inline-block",
            //   height: "30px",
            //   width: "100%",
            //   marginRight: "15px",
            //   float: "left",
            //   position: "inherit",
            // }}
            onChange={this.handleAccountChange}
            value={this.state.accountId}
            labelId="grouped-select"
            id="grouped-select"
            selectedmenuitemstyle={{ color: "rgb(0, 125, 171)" }}
          >
            {this.props.acl.isBusUser ? (
              <MenuItem
                value={this.state.accountId}
                label={no_account}
                children={no_account}
              />
            ) : this.state.accounts ? (
              this.state.accounts.length > 0 ? (
                <MenuItem
                  value={this.state.accountId}
                  label={this.state.accounts[0].contactName}
                  children={this.state.accounts[0].contactName}
                />
              ) : (
                <MenuItem
                  value={this.state.accountId}
                  label={no_account}
                  children={no_account}
                />
              )
            ) : (
              <MenuItem
                value={this.state.accountId}
                label={no_account}
                children={no_account}
              />
            )}
            {finalAccounts}
          </SelectField>
        </FormControl>
        <p style={{ fontSize: "16px" }}>{helplabelmessage1}</p>
        <RadioButtonGroup
          name="groupType"
          defaultValue="STATIC"
          value={this.state.type}
          onChange={this.handleTypeChange}
        >
          <RadioButton
            value="STATIC"
            //Static
            label={Resource.get("Static")}
            style={{
              paddingBottom: "5px",
              fontSize: "14px",
              fontWeight: "500",
            }}
            checked={this.state.type == "STATIC"}
          ></RadioButton>
          <RadioButton
            value="DYNAMIC"
            //Dynamic
            label={Resource.get("Dynamic")}
            style={{
              paddingBottom: "5px",
              fontSize: "14px",
              fontWeight: "500",
            }}
            checked={this.state.type == "DYNAMIC"}
          />
          <RadioButton
            value="CONTAINER"
            //Container
            label={Resource.get("Container")}
            style={{
              paddingBottom: "5px",
              fontSize: "14px",
              fontWeight: "500px",
            }}
            checked={this.state.type == "CONTAINER"}
          />
        </RadioButtonGroup>
        <p style={{ fontSize: "16px" }}>{elementTypeLabel}</p>
        <RadioButtonGroup
          name="elementType"
          defaultValue="DEVICES"
          value={this.state.elementType}
          onChange={this.handleElementChange}
        >
          <RadioButton
            value="DEVICES"
            //Devices
            label={Resource.get("Devices")}
            disabled={this.state.type === "CONTAINER" ? true : false}
            checked={this.state.elementType == "DEVICES"}
            style={{
              paddingBottom: "5px",
              fontSize: "14px",
              fontWeight: "500px",
            }}
          />
          <RadioButton
            value="INTERFACES"
            //Interfaces
            label={Resource.get("Interfaces")}
            checked={this.state.elementType == "INTERFACES"}
            disabled={this.state.type === "CONTAINER" ? true : false}
            style={{
              paddingBottom: "5px",
              fontSize: "14px",
              fontWeight: "500px",
            }}
          />
        </RadioButtonGroup>
        <p style={{ fontSize: "12px" }}>{helplabelmessage}</p>

        <div
          style={{
            color: "rgb(244, 67, 54)",
            fontSize: "12px",
          }}
        >
          {genericError}
        </div>
        <DialogActions>
          {actions}
        </DialogActions>
      </Dialog>
    );
    if (!(canModifyGroup || canAddGroup)) {
      dialog = (
        <p style={{ wordBreak: "break-word", width: "100px" }}>
          Not Authorized for this action.
        </p>
      );
    }
    return dialog;
  }
  handleAccountChange = (event) => {
    this.setState({
      accountId: event.target.value,
    });
  };
  handleSave = () => {
    let deviceCriteria = {
      isCsFilter: true,
      criteriaOperator: "AND",
      subCriteria: [
        {
          isCsFilter: true,
          negated: false,
          attribute: "DisplayName",
          filterOperator: "EQUALS",
          value: ["null"],
        },
      ],
    };
    let interfaceInnerCriteria = {
      isCsFilter: false,
      criteriaOperator: "AND",
      subCriteria: [
        {
          isCsFilter: false,
          negated: false,
          attribute: "DisplayName",
          filterOperator: "CONTAINS",
          value: [""],
        },
      ],
    };
    let combineCriteria = {
      criteriaOperator: "AND",
      subCriteria: [interfaceInnerCriteria, deviceCriteria],
    };
    this.props.pollGroupsStop(this.state.parentId);
    this.setState({
      addButtonDisabled: true,
    });
    if (this.state.type === "DYNAMIC") {
      let info = {
        criteria:
          this.state.elementType === "DEVICES"
            ? deviceCriteria
            : combineCriteria,
        name: this.state.name,
        parentId: this.state.parentId,
        masterElementGroup: this.state.elementType === "DEVICES" ? "0" : "1",
        type: "DYNAMIC",
        accountId: this.state.accountId,
      };

      this.props
        .addGroup(info)
        .then((response) => {
          const history = this.props.history;
          const pathname = history.location.pathname.replace("/list", "");
          const newPath = history.location.pathname.endsWith("/list")
            ? `${pathname}/${response.json.id}/list`
            : `${pathname}/${response.json.id}`;
          const name = this.state.name;
          if (this.props.location.pathname.search("treeviewnew") >= 0) {
            this.props.getGroup(response.json.id);
          } else {
            history.push(newPath);
          }
          this.props.handleClose("subGroupOpen", {
            isDynamicChecked: true,
            name: name,
            json: response.json,
            type: "ADD",
          });
          this.resetState();
        })
        .catch((error) => {
          this.props.handleClose("subGroupOpen");
          this.props.handleErrorDialogOpen("addGroup");
          this.resetState();
        });
    } else {
      this.props
        .addGroup(this.state)
        .then((response) => {
          const history = this.props.history;
          const pathname = history.location.pathname.replace("/list", "");
          const newPath = history.location.pathname.endsWith("/list")
            ? `${pathname}/${response.json.id}/list`
            : `${pathname}/${response.json.id}`;
          if (pathname.indexOf("treeviewnew") > -1) {
            this.props.getGroup(response.json.parentId);
          } else {
            history.push(newPath);
          }
          this.props.handleClose("subGroupOpen");
          this.setState({ saveError: null });
          this.resetState();
        })
        .catch((error) => {
          this.props.handleClose("subGroupOpen");
          this.props.handleErrorDialogOpen("addGroup");
          this.resetState();
        });
    }
    this.props.refreshTree();
  };
  onClose = () => {
    this.resetState();
    this.props.handleClose("subGroupOpen");
  };
}

AddSubgroupDialog.defaultProps = {
  handleClose: function () {},
  addGroup: function () {},
};

export default AddSubgroupDialog;
