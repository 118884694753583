// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Thank you message
  content["Thank you, your sales representative<br />will contact you soon"] =
  "Thank you, your sales representative<br />will contact you soon";

  //CLOSE
  content["CLOSE"] =
  "CLOSE";

  //Trial Exired
  content["Trial Expired"] =
  "Trial Expired";

  // trial expired message
  content["Unfortunately, your trial period expired."] =
  "Unfortunately, your trial period expired.";

  //System update available
  content["System update available"] =
  "System update available";

  //A new version of the robot software is available.
  content["A new version of the robot software is available."] =
  "A new version of the robot software is available.";

  //Deployment takes about 1 minute, during which time no metrics are collected and no alarms are created.
  content["Deployment takes about 1 minute, during which time no metrics are collected and no alarms are created."] =
  "Deployment takes about 1 minute, during which time no metrics are collected and no alarms are created.";

  //LATER
  content["LATER"] =
  "LATER";

  //DEPLOY
  content["DEPLOY"] =
  "DEPLOY";

  // END OF LOCALIZATION

export default content;
