import React, { Component } from "react";
import {CircularProgress} from '@mineral/core'
import ReactFrame from "../settings/portlets/ReactFrame";
import connect from "../../utils/connect";
import { setBreadCrumbs } from "../breadcrumbs/actions";
import { withRouter } from "react-router-dom";
import Resource from "./Resource";

//slm
const SLM_NAME = Resource.get("SLM");
class SLM extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillMount = () => {
   
        this.setState({ sid:this.props.sid })
  };

  componentDidMount() {
    this.props.setBreadCrumbs({
      //SLM
      name: SLM_NAME,
      link: "/slm",
    });
  }

  render() {
    let view = (
        <div className="spinner" style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><CircularProgress style={{color: '#3272D9'}} /></div>
    );

    if (this.state.sid) {
      view = (
        <ReactFrame
          url="slm/standalone_login.jsp"
          sid={this.state.sid}
          portletId="slm"
          method="GET"
        />
      );
    }

    return view;
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setBreadCrumbs: (items) => dispatch(setBreadCrumbs(items)),
  };
};

const mapStateToProps = (state) => {
  return {
    saas: state.saas,
    sid: state.sid,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SLM));
