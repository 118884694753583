import React from "react";
import {
  Select,
  MenuItem,
  FormControl,
  TextField,
  Divider,
  Menu,
  ListItemIcon,
  Typography,
  Popover,
  Modal,
  Box,
  Tooltip, ListItem
} from "@mineral/core";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
} from "@mineral/core";
import { IconButton } from "@mineral/core";
import CrossIcon from "../../../../../../../assets/icons/cross-icon";
import ExpandIcon from "../../../../../../../assets/icons/expand-icon";
import CollapseIcon from "../../../../../../../assets/icons/collapse-icon";
import SettingIcon from "../../../../../../../assets/icons/setting-icon";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import EditIcon from "@material-ui/icons/Edit";
import axios from "axios";
import "./QosChartControlsToolbar.less";
import { Link } from "react-router-dom";
import moment from "moment";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  expandChart,
  setMinMaxYChart,
} from "../../../../../../../api/performanceReports/actions";
import ArrowForward from "@material-ui/icons/ArrowForward";
import CloseIcon from "@material-ui/icons/Close";
import { Check, NavigateNext, Close } from "@material-ui/icons";
import config from "../../../../../../../api/config";
import QosExportChartDialog from "./QosExportChartDialog";
import prd from "../../../../../../../api/performanceReports/prd";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { isNull } from "lodash";
import { isArray, isEqual, result } from "lodash";
import { addNewPreparedCharts } from "../../../../../../../api/performanceReports/actions";
export const chartMenuOptions = [
  "Stacked",
  "Legend View",
  "Chart View",
  "Delete Series",
  "Export Chart",
  "Clear Chart",
  "Chart Url",
  "Trendline",
  "Baseline",
  "Percentile",
  "Scale", //, "Min Max Y Axis"
];
export const chartMenuOptionsUrl = ["Chart View", "Export Chart"];
export const chartViewNestedMenuOptions = [
  "Column Chart",
  "Area Chart",
  "Line Chart",
  "USM Chart",
];
export const legendViewNestedMenuOptions = ["Table"];

function copyToClipboard(textToCopy) {
  // navigator clipboard api needs a secure context (https)
  if (navigator.clipboard && window.isSecureContext && false) {
    // navigator clipboard api method'
    return navigator.clipboard.writeText(textToCopy);
  } else {
    // text area method
    let textArea = document.createElement("textarea");
    textArea.value = textToCopy;
    // make the textarea out of viewport
    textArea.style.position = "fixed";
    textArea.style.left = "-999999px";
    textArea.style.top = "-999999px";
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    return new Promise((res, rej) => {
      // here the magic happens
      document.execCommand("copy") ? res() : rej();
      textArea.remove();
    });
  }
}

const QosChartControlsToolbar = (props) => {
  const qosNameRef = React.useRef();
  const chartUrlArea = React.useRef();
  const [anchorEl, setAnchorEl] = useState(null);
  const [popupAnchorEl, setPopupAnchorEl] = useState(null);
  const [nestedAnchorEl, setNestedAnchorEl] = useState(null);
  const [selectedNestedMenu, setSelectedNestedMenu] = useState(null);
  const [showAdditionalField, setShowAdditionalField] = useState(null);
  const [openUrlModal, setOpenUrlModal] = useState(false);
  const [openDeleteSeriesModal, setOpenDeleteSeriesModal] = useState(false);
  const [openDeleteChartModal, setOpenDeleteChartModal] = useState(false);
  const [sourceList, setSourceList] = useState([]);
  const [targetList, setTargetList] = useState([]);
  const [openedSnackbar, setOpenedSnackbar] = useState(false);
  const [copiedSnackbar, setCopiedSnackbar] = useState(false);
  const [openExportChartDialog, setOpenExportChartDialog] =
    React.useState(false);
  const [sourceTargetMap, setSourceTargetMap] = useState(new Map());
  const [errorText, setErrorText] = React.useState();
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const [chartList, setChartList] = useState(props.chartList);
  const [chartTitleList, setChartTitleList] = useState(
    props.chartList ? props.chartList.map(ch => ch.title) : []
  );
  const fromTarget = useSelector(
    (state) => state.performanceReports.selectedSource == "target"
  );
  const isChartExpanded = useSelector(
    (state) =>
      state.performanceReports.chartData.State.GraphMaximized ==
      props.chartObject.chartId
  );
  const chartData = useSelector((state) => state.performanceReports.chartData);

  const [editChartTitle, setEditChartTitle] = React.useState(false);
  const [chartTitle, setChartTitle] = React.useState(props.chartObject ? props.chartObject.title : "");
  const [showAdditionalFieldToolbar, setShowAdditionalFieldToolbar] = useState(null);
  const [percentValue, setPercentValue] = useState("");
  const [percentValid, setPercentValid] = useState(false);
  const [scaleValuePop, setScaleValuePop] = useState("");
  const [scaleValidPop, setScaleValidPop] = useState(false);



  const truncateString = (string, limit) => {
    if (string.length > limit) {
      return string.substring(0, limit) + "...";
    } else {
      return string;
    }
  };

  const handleSettingMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
    setNestedAnchorEl(null);
  };

  const handleClose = () => {
    setNestedAnchorEl(null);
    setSelectedNestedMenu(null);
    setAnchorEl(null);
  };

  const getNestedMenuOptions = (option) => {
    if (option == chartMenuOptions[1]) {
      return legendViewNestedMenuOptions;
    } else if (option == chartMenuOptions[2]) {
      return chartViewNestedMenuOptions;
    } else {
      return [];
    }
  };

  const handleNestedChartOptionClick = (nestedOption) => {
    console.log(nestedOption);
    if (nestedOption == chartViewNestedMenuOptions[0]) {
      updateChartViewInSeries("col");
    } else if (nestedOption == chartViewNestedMenuOptions[1]) {
      updateChartViewInSeries("area");
    } else if (nestedOption == chartViewNestedMenuOptions[2]) {
      updateChartViewInSeries("line");
    } else if (nestedOption == chartViewNestedMenuOptions[3]) {
      updateChartViewInSeries("usm");
    } else if (nestedOption == legendViewNestedMenuOptions[0]) {
      updateLegendViewInSeries(nestedOption);
    } else if (nestedOption == legendViewNestedMenuOptions[1]) {
      updateLegendViewInSeries(nestedOption);
    }
    handleClose();
  };

  const updateChartViewInSeries = (viewName) => {
    let chartSeries = props.chartObject.series;
    chartSeries.forEach((series) => {
      series.style = viewName;
    });

    props.handleChartObjectChange({
      ...props.chartObject,
      series: chartSeries,
    });
  };
  const updateLegendViewInSeries = (viewName) => {
    if (viewName == legendViewNestedMenuOptions[0]) {
      props.handleChartObjectChange({
        ...props.chartObject,
        showLegend: "false",
      });
    } else {
      props.handleChartObjectChange({
        ...props.chartObject,
        showLegend: "true",
      });
    }
  };

  const handleChartMenuItemSelection = (event, menuItem, index) => {
    if (props.fromUrl) {
      if (index == 0) {
        setNestedAnchorEl(event.currentTarget);
        setSelectedNestedMenu(menuItem);
      } else if (index === 1) {
        handleClose();
        setOpenExportChartDialog(true);
      }
    } else {
      if (index == 1 || index == 2) {
        setNestedAnchorEl(event.currentTarget);
        setSelectedNestedMenu(menuItem);
      } else if (index == 3) {
        if (props.selectedSeries?.length > 0) {
          handleClose();
          setOpenDeleteSeriesModal(true);
        }
      } else if (index == 4) {
        handleClose();
        setOpenExportChartDialog(true);
      } else if (index == 6) setOpenUrlModal(true);
      else if (index == 9) {
        handleClose();
        setShowAdditionalField("percentile");
        setPopupAnchorEl(event.currentTarget)
      } else if (index == 10) {
        handleClose();
        setShowAdditionalField("scale");
        setPopupAnchorEl(event.currentTarget)
      } else if (index === 11) {
        handleClose();
        setShowAdditionalField("minMaxY");
      } else {
        dispatch(addNewPreparedCharts(null));
        props.handleChartMenuItemSelection(menuItem);
        handleClose();
      }
    }
  };

  const handleScaleChange = (event) => {
    let scaleValue = event.target.value;
    setScaleValuePop(scaleValue);
    setScaleValidPop(event.target.validity.valid);

  };

  const handlePercentileChange = (event) => {
    let percentValue = event.target.value;
    setPercentValue(percentValue);
    setPercentValid(event.target.validity.valid);
  };
  useEffect(() => {
    if (!isEqual(props.chartObject.title, chartTitle)) {
      setChartTitle(props.chartObject.title)
    }
  }, [props.chartObject.title]);

  useEffect(() => {
    if (!isEqual(props.chartList, chartList)) {
      console.log("Chart List changed.");
      setChartList(props.chartList);
      setChartTitleList(props.chartList ? props.chartList.map(ch => ch.title) : [])
    }
  }, [props.chartList]);

  useEffect(async () => {
    if (props.filteredChartData.length > 0) {
      let sourceRequestList = [],
        targetRequestList = [],
        dropDownRequestList = [];
      let newMap = sourceTargetMap;
      let id = Math.random() * 10000;
      newMap.set(props.filteredChartData[0].chartId, id);
      setSourceTargetMap(newMap);      
      sourceRequestList = props.filteredChartData.filter((value, index, self) =>{
        if(index === self.findIndex((t) => (
          t.source === value.source
        )))
        return value.source;
      }
      ).map(item=>item.source);

      targetRequestList = props.filteredChartData.filter((value, index, self) =>{
        if(index === self.findIndex((t) => (
          t.target === value.target
        )))
        return value.target;
      }
      ).map(item=>item.target);
      //console.log("sourceRequestList", sourceRequestList);
      /*if (
        sourceRequestList.length <= props.filteredChartData.length &&
        !fromTarget
      ) {
        dropDownRequestList = sourceRequestList.map((d) => {
          if (process.env.NODE_ENV == "development") {
            return axios.get(prd.getSourceListByQosAndTarget(d.qos, d.target));
          } else {
            return axios.post(prd.getSourceListByQosAndTarget(d.qos, d.target));
          }
        });
        let response = null;
        try {
          response = await Promise.all(dropDownRequestList);
        } catch (e) {
          console.log(e);
        }
        let sourceList = [];
        let storedId = sourceTargetMap.get(props.filteredChartData[0].chartId);
        if (id === storedId) {
          for (let resolvedData of response) {
            if (Array.isArray(resolvedData.data)) {
              sourceList =
                sourceList.length == 0
                  ? [...resolvedData.data]
                  : sourceList.filter((d) => resolvedData.data.includes(d));
            }
            // sourceList = Array.isArray(resolvedData.data)
            // 	? [...sourceList, ...resolvedData.data]
            // 	: [...sourceList];
          }
        }
        sourceList = sourceList.filter(
          (value, index, self) => self.indexOf(value) === index
        );
        setSourceList(sourceList);
        setTargetList([]);
      } else if (targetRequestList.length <= props.filteredChartData.length) {
        dropDownRequestList = targetRequestList.map((d) => {
          if (process.env.NODE_ENV == "development") {
            return axios.get(prd.gettargetListBySourceAndQos(d.source, d.qos));
          } else {
            return axios.post(prd.gettargetListBySourceAndQos(d.source, d.qos));
          }
        });
        let response = null;
        try {
          response = await Promise.all(dropDownRequestList);
        } catch (e) {
          console.log(e);
        }
        let targetList = [];
        for (let resolvedData of response) {
          // targetList = Array.isArray(resolvedData.data)
          // 	? [...targetList, ...resolvedData.data]
          // 	: [...targetList];
          if (Array.isArray(resolvedData.data)) {
            targetList =
              targetList.length == 0
                ? [...resolvedData.data]
                : targetList.filter((d) => resolvedData.data.includes(d));
          }
        }
        targetList = targetList.filter(
          (value, index, self) => self.indexOf(value) === index
        );
        // targetList = targetList.filter(
        // 	(d) => props.filteredChartData.find((k) => k.source == d) == undefined
        // );
        setTargetList(targetList);
        setSourceList([]);
      } else {
        setSourceList([]);
        setTargetList([]);
      }*/

      if (!fromTarget) { 
        if(sourceRequestList.length===1)
          setSourceList(sourceRequestList);
        else
          setSourceList([]);
      }
      else { 
        if(targetRequestList.length===1)
        setTargetList(targetRequestList);
        else
        setTargetList([]);
      }

    } else {
      setSourceList([]);
      setTargetList([]);
    }
  }, [props.filteredChartData.length]);

  function copyToClipboard(textToCopy) {
    // navigator clipboard api needs a secure context (https)
    chartUrlArea.current.focus();
    chartUrlArea.current.select();
    if (navigator.clipboard && window.isSecureContext) {
      // navigator clipboard api method'
      return navigator.clipboard.writeText(textToCopy);
    } else {
      return new Promise((res, rej) => {
        document.execCommand("copy") ? res() : rej();
      });
    }
  }

  let scaleValue =
    props.chartObject &&
      props.chartObject.series &&
      props.chartObject.series.length > 0 &&
      props.chartObject.series[0].scale
      ? props.chartObject.series[0].scale
      : "";
  let percentileValue =
    props.chartObject &&
      props.chartObject.series &&
      props.chartObject.series.length > 0 &&
      props.chartObject.series[0].pLine &&
      props.chartObject.series[0].pLine.percentile
      ? props.chartObject.series[0].pLine.percentile
      : "";

  let selectedSource =
    props.selectedSource.length == 0
      ? sourceList.length > 0
        ? props.filteredChartData.length > 0
          ? props.filteredChartData[props.filteredChartData.length - 1].source
          : sourceList[0]
        : targetList.length > 0
          ? props.filteredChartData.length > 0
            ? props.filteredChartData[props.filteredChartData.length - 1].target
            : targetList[0]
          : ""
      : sourceList.length > 0
        ? props.filteredChartData.length > 0
          ? props.selectedSource ==
            props.filteredChartData[props.filteredChartData.length - 1].source
            ? props.selectedSource
            : props.filteredChartData[props.filteredChartData.length - 1].source
          : sourceList.includes(props.selectedSource)
            ? props.selectedSource
            : sourceList[0]
        : targetList.length > 0
          ? props.filteredChartData.length > 0
            ? props.selectedSource ==
              props.filteredChartData[props.filteredChartData.length - 1].target
              ? props.selectedSource
              : props.filteredChartData[props.filteredChartData.length - 1].target
            : targetList.includes(props.selectedSource)
              ? props.selectedSource
              : targetList[0]
          : props.selectedSource;

  return (
    <>
      <div className="qos-chart-controls-toolbar">
        <div className="qos-toolbar-left-controls">
          <Tooltip title="Drag button">
            <IconButton
              tabIndex={-1}
              inputProps={{ tabIndex: -1 }}
              style={{ justifyContent: "right" }}
              name="Drag button"
            >
              <DragIndicatorIcon className="dragIconColor" role="img" />
            </IconButton>
          </Tooltip>
          {/* <Tooltip arrow title="Edit">
            <IconButton
              style={{  marginRight: "4px" }}
              aria-label="Edit"
              onClick={() => {
                setEditChartTitle(true);
                setTimeout(() => {
                  qosNameRef?.current?.firstChild?.firstChild?.focus();
                });
              }}
            >
              <EditIcon className="editIconColor" />
            </IconButton>
          </Tooltip>*/}
          <Tooltip arrow title={editChartTitle ? chartTitle : props.chartObject.title}>
            <TextField
              ref={qosNameRef}
              disabled={navigator.userAgent.indexOf("Firefox") > 0 ? false : !editChartTitle}
              onChange={(event) => {
                setChartTitle(event.target.value);
                const titleExist = chartTitleList.some(element => {
                  return element.trim().toLowerCase() === event.target.value.trim().toLowerCase();
                });
                if (!titleExist) {
                  setErrorText("");
                } else {
                  setErrorText("already exist...");
                }
              }
              }
              helperText={errorText}
              error={errorText}
              onBlur={(event) => {
                setEditChartTitle(false);
                const titleExist = chartTitleList.some(element => {
                  return element.trim().toLowerCase() === event.target.value.trim().toLowerCase();
                });
                if (!titleExist) {
                  setErrorText("");
                  props.handleChartObjectChange({
                    ...props.chartObject,
                    title: event.target.value,
                  })
                } else {
                  setChartTitle(props.chartObject.title);
                  setErrorText("");
                }
              }}
              onClick={() => {
                setEditChartTitle(true);
              }}
              size="small"
              value={
                editChartTitle
                  //? props.chartObject.title
                  ? chartTitle
                  : isChartExpanded
                    ? truncateString(props.chartObject.title, 24)
                    : truncateString(props.chartObject.title, 24)
              }
              // classes={{ root: "qos-chart-name" }}
              inputProps={{ "aria-label": "Chart title" }}
              name={"Chart title"}
            //InputProps={{ inputProps: { maxLength: 16 } }}
            />
          </Tooltip>
          {showAdditionalFieldToolbar === "scale" && (
            <Tooltip title={scaleValue + " (scale)"}>
              <Typography variant="h6" component="div" style={{ marginLeft: "5px" }} noWrap>
                {scaleValue}
              </Typography>
            </Tooltip>
          )}
          {showAdditionalFieldToolbar === "percentile" && (
            <Tooltip title={percentileValue + " percentile"}>
              <Typography variant="h6" component="div" style={{ marginLeft: "5px" }} noWrap>
                {percentileValue + " percentile"}
              </Typography>
            </Tooltip>
          )}
          {showAdditionalField === "minMaxY" && (
            <div style={{ display: "flex" }}>
              <TextField
                onChange={(event) =>
                  props.handleChartObjectChange({
                    ...props.chartObject,
                    minYAxis1: event.target.value,
                    minYAxis2: event.target.value,
                  })
                }
                size="small"
                type="number"
                placeholder="Min"
                defaultValue={props.chartObject.minYAxis1}
                classes={{
                  root: `${isChartExpanded
                    ? "qos-percent-expand-field"
                    : "qos-scale-field"
                    }`,
                }}
              />
              <TextField
                onChange={(event) =>
                  props.handleChartObjectChange({
                    ...props.chartObject,
                    maxYAxis1: event.target.value,
                    maxYAxis2: event.target.value,
                  })
                }
                size="small"
                type="number"
                placeholder="Max"
                defaultValue={props.chartObject.maxYAxis1}
                classes={{
                  root: `${isChartExpanded
                    ? "qos-percent-expand-field"
                    : "qos-scale-field"
                    }`,
                }}
              />
            </div>
          )}
        </div>

        <div className="qos-toolbar-right-controls">
          {sourceList.length > 0 || targetList.length > 0 ? (
            <FormControl
              className={
                isChartExpanded ? "source-large-dropdown" : "source-dropdown"
              }
            >

              <Select
                id="source-select"
                style={{ height: '32px' }}
                value={selectedSource}
                onChange={(event) => {
                  props.onSourceChange(
                    event.target.value,
                    sourceList.length > 0
                  );
                }}
              >
                {sourceList.length > 0 &&
                  sourceList.map((source, index) => (
                    <MenuItem
                      key={`${source}-${index}-menuitem`}
                      value={source}
                    >
                      {source}
                    </MenuItem>
                  ))}
                {targetList.length > 0 &&
                  targetList.map((target, index) => (
                    <MenuItem
                      key={`${target}-${index}-menuitem`}
                      value={target}
                    >
                      {target}
                    </MenuItem>
                  ))}
              </Select>

            </FormControl>
          ) : null}
          <Tooltip arrow title="Settings">
            <IconButton
              style={!isChartExpanded ? { padding: "3px", marginLeft: "4px" } : {}}
              onClick={handleSettingMenuClick}
              aria-label="Settings"
            >
              <SettingIcon />
            </IconButton>
          </Tooltip>
          {openExportChartDialog && (
            <QosExportChartDialog
              open={openExportChartDialog}
              copiedSnackbar={setCopiedSnackbar}
              filteredChartData={props.filteredChartData}
              finalArray={props.finalArray}
              chartData={chartData}
              chartObject={props.chartObject}
              aggLevel={props.aggLevel}
              startTime={props.startTime}
              stopTime={props.stopTime}
              handleExportChartDialogClose={() =>
                setOpenExportChartDialog(false)
              }
            />
          )}
          <Snackbar
            open={copiedSnackbar}
            autoHideDuration={5000}
            onClose={() => setCopiedSnackbar(false)}
          >
            <Alert severity="success">
              Chart data was successfully copied to the clipboard.
            </Alert>
          </Snackbar>
          <Popover
            open={Boolean(popupAnchorEl)}
            anchorEl={popupAnchorEl}
            anchorPosition={{ top: 100, left: 200 }}
            aria-label="Percentile"
            onClose={() => {
              setPopupAnchorEl(null);
            }}
            className="popoverBox"
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            {(showAdditionalField === "percentile") ? (
              <div>
                <Typography variant="h5" component="div">Percentile </Typography>

                <TextField
                  onChange={handlePercentileChange}
                  size="small"
                  type="number"
                  placeholder="Percentile"
                  value={percentValue}
                  error={percentValue < 1}
                  style={{ width: "100%" }}
                  InputProps={{
                    inputProps: { "aria-label": "Percentile", min: 1, max: 99, pattern: "[0-9]d*" },
                  }}
                  classes={{
                    root: `${isChartExpanded
                      ? "qos-percent-expand-field-perc"
                      : "qos-scale-field-perc"
                      }`,
                  }}
                />
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-end", padding: "2px", maxHeight: "20px" }}>
                  <Button
                    variant="text"
                    children="Apply"
                    disabled={
                      !percentValue
                    }
                    onClick={() => {
                      setPopupAnchorEl(null);
                      setShowAdditionalFieldToolbar("percentile");
                      if (percentValid || percentValue === "") {
                        let series = props.chartObject.series;

                        series.forEach((obj) => {
                          obj.pLine.percentile = percentValue;
                          obj.pLine.active = "true";
                        });

                        setTimeout(() => {
                          props.handleChartObjectChange({ ...props.chartObject, series: series });
                        }, 500);
                      }
                    }}
                  />
                  <Button
                    children="Cancel"
                    onClick={() => {
                      setPopupAnchorEl(null);
                    }}
                  />

                </div>
              </div>
            ) :
              showAdditionalField === "scale" ? (
                <div>
                  <Typography variant="h5" component="div">Scale </Typography>

                  <TextField
                    onChange={handleScaleChange}
                    size="small"
                    type="text"
                    placeholder="Scale"
                    value={scaleValuePop}
                    style={{ width: "100%" }}
                    classes={{
                      root: `${isChartExpanded
                        ? "qos-percent-expand-field-perc"
                        : "qos-scale-field-perc"
                        }`,
                    }}
                    error={scaleValuePop < 1}
                    // helperText={ scaleValue < 1 ? 'Min. 1' : '' }
                    InputProps={{
                      inputProps: {
                        min: 0,
                        pattern: "[+]?([0-9]+(?:[.][0-9]*)?|.[0-9]+)",
                      },
                    }}
                  />
                  <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-end", padding: "2px", maxHeight: "20px" }}>
                    <Button
                      variant="text"
                      children="Apply"
                      disabled={
                        !scaleValuePop
                      }
                      onClick={() => {
                        setPopupAnchorEl(null);
                        setShowAdditionalFieldToolbar("scale");
                        if (scaleValidPop || scaleValuePop === "") {
                          let series = props.chartObject.series;

                          series.forEach((obj) => {
                            obj.scale = scaleValuePop;
                          });

                          setTimeout(() => {
                            props.handleChartObjectChange({ ...props.chartObject, series: series });
                          }, 500);
                        }
                      }}
                    />
                    <Button
                      variant="text"
                      children="Cancel"
                      onClick={() => {
                        setPopupAnchorEl(null);
                      }}
                    />
                  </div>
                </div>
              ) : null}
          </Popover>

          <Menu

            className="qos-chart-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                maxHeight: 48 * 4.5,
                width: "22ch",
              },
            }}
          >
            {(props.fromUrl ? chartMenuOptionsUrl : chartMenuOptions).map(
              (option, index) => (
                <MenuItem
                  onClick={(event) => {
                    handleChartMenuItemSelection(event, option, index);
                  }}
                  key={option}
                  disabled={(index === 3 && props.selectedSeries?.length === 0) ? true : false}
                >
                  {(!props.fromUrl && (index == 1 || index == 2)) ||
                    (props.fromUrl && index === 0) ? (
                    <div>
                      <div className="qos-menu-item-container">
                        <div className="qos-menu-item">
                          <Typography variant="inherit" noWrap>
                            {option}
                          </Typography>
                          <ListItemIcon
                            aria-label={
                              selectedNestedMenu == option &&
                                nestedAnchorEl != null &&
                                anchorEl != null
                                ? "Expanded"
                                : "Collapsed"
                            }
                            role="button"
                          >
                            <NavigateNext fontSize="small" role="img" />
                          </ListItemIcon>
                        </div>
                      </div>
                      <Menu tabIndex={-1}
                        anchorEl={nestedAnchorEl}
                        open={
                          selectedNestedMenu == option &&
                          nestedAnchorEl != null &&
                          anchorEl != null
                        }
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        onClose={handleClose}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                      >
                        {getNestedMenuOptions(option).map(
                          (nestedOption, nestedIndex) => (
                            <MenuItem
                              onClick={(event) => {
                                handleNestedChartOptionClick(
                                  nestedOption,
                                  nestedIndex
                                );
                                event.stopPropagation();

                              }}
                              key={nestedOption}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  {((props.chartObject.showLegend == "true" &&
                                    nestedOption == "Line") ||
                                    (props.chartObject.showLegend == "false" &&
                                      nestedOption == "Table")) && (
                                      <ListItemIcon
                                        aria-label="Selected"
                                        role="figure"
                                        style={{ minWidth: "28px" }}
                                      >
                                        <Check fontSize="small" />
                                      </ListItemIcon>
                                    )}
                                  <Typography variant="inherit" noWrap>
                                    {nestedOption}
                                  </Typography>
                                </div>
                              </div>
                            </MenuItem>
                          )
                        )}
                      </Menu>
                    </div>
                  ) : (
                    <div>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {((props.chartObject.stacked == "true" &&
                            index == 0) ||
                            (props.chartObject.trend == "true" && index == 7) ||
                            (props.chartObject.baseline == "true" &&
                              index == 8)) && (
                              <ListItemIcon>
                                <Check fontSize="small" />
                              </ListItemIcon>
                            )}
                          <Typography variant="inherit" noWrap>
                            {option}
                          </Typography>
                        </div>
                      </div>
                    </div>
                  )}

                  {/* {(index==2 ||index==5 || index==7)?
                                                                                                                <Divider/>:null
                                                                                                            } */}
                </MenuItem>
                // {(index==2 ||index==5 || index==7)?
                //     <Divider/>:null
                // }
              )
            )}
            {scaleValue && (
              <MenuItem
                onClick={() => {
                  let series = props.chartObject.series;
                  setShowAdditionalField("");
                  if (percentileValue) {
                    setShowAdditionalFieldToolbar("percentile");
                  } else {
                    setShowAdditionalFieldToolbar("");
                  }
                  setScaleValidPop(false);
                  setScaleValuePop("");
                  series.forEach((obj) => {
                    obj.scale = "";
                  });

                  setTimeout(() => {
                    props.handleChartObjectChange({
                      ...props.chartObject,
                      series: series,
                    });
                  }, 500);
                  handleClose();
                }}
                key={"RemoveScale"}
              >
                <div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Typography variant="inherit" noWrap>
                        Remove Scale
                      </Typography>
                    </div>
                  </div>
                </div>
              </MenuItem>
            )}
            {percentileValue && (
              <MenuItem
                onClick={() => {
                  let series = props.chartObject.series;
                  setShowAdditionalField("");
                  if (scaleValue) {
                    setShowAdditionalFieldToolbar("scale");
                  } else {
                    setShowAdditionalFieldToolbar("");
                  }
                  setPercentValid(false);
                  setPercentValue("");
                  series.forEach((obj) => {
                    obj.pLine.percentile = "";
                    obj.pLine.active = "false";
                  });

                  setTimeout(() => {
                    props.handleChartObjectChange({
                      ...props.chartObject,
                      series: series,
                    });
                  }, 500);
                  handleClose();
                }}
                key={"RemovePercentile"}
              >
                <div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Typography variant="inherit" noWrap>
                        Remove Percentile
                      </Typography>
                    </div>
                  </div>
                </div>
              </MenuItem>
            )}
          </Menu>
          <Tooltip

            title={isChartExpanded ? "Collapse Chart" : "Expand Chart"}
          >
            <IconButton
              onClick={() => {
                isChartExpanded ? dispatch(expandChart(-1)) : dispatch(expandChart(props.chartObject.chartId))
              }}
              aria-label={isChartExpanded ? "Collapse Chart" : "Expand Chart"}
              style={!isChartExpanded ? { padding: "3px" } : {}}
            >
              {isChartExpanded ? (
                <CollapseIcon
                />
              ) : (
                <ExpandIcon
                />
              )}
            </IconButton>
          </Tooltip>
          <div className="controls-seprator"></div>
          {props.fromUrl ? null : (
            <Tooltip arrow title="Remove Chart">
              <IconButton

                aria-label="Remove Chart"
                onClick={() => setOpenDeleteChartModal(true)}
                style={!isChartExpanded ? { padding: "3px" } : {}}
              >
                <CrossIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </div>
      <Modal
        open={openUrlModal}
        onClose={() => setOpenUrlModal(false)}
        aria-labelledby="modal-url-title"
        aria-describedby="modal-url-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            bgcolor: "background.paper",
            transform: "translate(-50%, -50%)",
            boxShadow: 24,
            p: 4,
            width: 400,
            padding: '20px',
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "10px",
            }}
          >
            <Typography id="modal-url-title" variant="h3" component="h1">
              Chart Url
            </Typography>
            <Tooltip title="Close">
              <IconButton autoFocus
                onClick={() => setOpenUrlModal(false)}
                aria-label="Close"

              >
                <Close />
              </IconButton>
            </Tooltip>
          </div>
          <textarea
            id="standard-multiline-static"
            style={{
              width: "100%",
              height: "200px",
              fontSize: "16px",
              resize: "none",
            }}
            // disabled="true"
            multiline
            aria-label="Chart URL"
            rows={8}

            onFocus={(event) => event.target.select()}
            ref={chartUrlArea}
            // disabled={true}
            // onFocus={(event) => event.target.select()}
            readonly="readonly"
            defaultValue={`${window.location.origin + config.basename
              }/standalone_login.jsp?view=prd&params=${window.btoa(encodeURIComponent(
                JSON.stringify(props.urlData))
              )}`}
          />
          <div
            style={{
              marginTop: "10px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Tooltip title="Copy url to clipboard">
              <span>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    copyToClipboard(
                      `${window.location.origin + config.basename
                      }/standalone_login.jsp?view=prd&params=${window.btoa(encodeURIComponent(
                        JSON.stringify(props.urlData))
                      )}`
                    ).then(() => setOpenedSnackbar(true));
                  }}
                >
                  Copy url
                </Button>
              </span>
            </Tooltip>
          </div>
        </Box>
      </Modal>
      <Snackbar
        open={openedSnackbar}
        autoHideDuration={5000}
        onClose={() => setOpenedSnackbar(false)}
      >
        <Alert severity="success">
          Chart url was successfully copied to the clipboard.
        </Alert>
      </Snackbar>
      <Dialog
        open={openDeleteSeriesModal}
        onClose={() => setOpenDeleteSeriesModal(false)}
        aria-labelledby="alert-delete-series-dialog-title"
        aria-describedby="alert-delete-series-dialog-description"
      >
        <DialogTitle component="h1">

          <Typography

            variant="h4"
            component="div"
          >
            {"Delete series"}
          </Typography>


        </DialogTitle>
        <div style={{ height: '0px' }}>
          <Tooltip title="Close">
            <IconButton style={{ top: '-50px', float: 'right', marginRight: '8px' }}
              aria-label="Close"
              autoFocus
              onClick={() => setOpenDeleteSeriesModal(false)}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </div>
        <DialogContent>
          <DialogContentText id="alert-delete-series-dialog-description">
            <pre>
              {`Do you want to delete series:\n${props.selectedSeries
                ? props.selectedSeries
                  .map((selectedNode) => selectedNode.displayName)
                  .join("\n")
                : ""
                }?`}
            </pre>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            onClick={() => setOpenDeleteSeriesModal(false)}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              setOpenDeleteSeriesModal(false);
              props.handleChartMenuItemSelection(chartMenuOptions[3]);
              handleClose();
            }}
          >
            Agree
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDeleteChartModal}
        onClose={() => setOpenDeleteChartModal(false)}
        aria-labelledby="alert-delete-chart-dialog-title"
        aria-describedby="alert-delete-chart-dialog-description"
      >
        <DialogTitle component="h1">

          <Typography

            variant="h4"
            component="div"
          >
            {"Remove chart"}
          </Typography>


        </DialogTitle>
        <div style={{ height: '0px' }}>
          <Tooltip title="Close">
            <IconButton style={{ top: '-50px', float: 'right', marginRight: '8px' }}
              aria-label="Close"
              autoFocus
              onClick={() => setOpenDeleteChartModal(false)}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </div>
        <DialogContent>
          <DialogContentText id="alert-delete-chart-dialog-description">
            <pre>
              {`Are you sure you want to remove chart: ${props.chartObject.title}?`}
            </pre>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            onClick={() => setOpenDeleteChartModal(false)}
          >
            Cancel
          </Button>
          <Button variant="contained"
            onClick={() => {
              setOpenDeleteChartModal(false);
              props.onQosChartDelete()

            }}
          >
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default QosChartControlsToolbar;
