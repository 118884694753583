import React from "react";
import PropTypes from "prop-types";

import { Tooltip} from "@mineral/core";

const DEFAULT_ICON_SIZE = "20px";
const InventoryIcons = (props) => {
  if (props.type === "A") {
    return (
      <Tooltip title="Automatic Discovery">
      <svg
        width="20"
        height="20"
        viewBox="0 0 24 24"
        fill="none" style={{marginTop: '5px'}}
        xmlns="http://www.w3.org/2000/svg"
      className="grid__icon" role="img" tabIndex={-1} aria-label="Automatic Discovery"
      >
        <g fill="#333840" fillRule="evenodd">
          <title>{props.title}</title>
          <path
            d="M13 6.79198V17.208C13.883 17.5938 14.5 18.4748 14.5 19.5C14.5 20.8807 13.3807 22 12 22C10.6193 22 9.5 20.8807 9.5 19.5C9.5 18.4748 10.117 17.5938 11 17.208V6.79198C10.117 6.4062 9.5 5.52516 9.5 4.5C9.5 3.11929 10.6193 2 12 2C13.3807 2 14.5 3.11929 14.5 4.5C14.5 5.52516 13.883 6.4062 13 6.79198Z"
            fill="#333840"
          />
          <path
            opacity="0.8"
            fillRule="evenodd"
            clipRule="evenodd #333840"
            d="M16.8584 10.3856L8 15.5C8 16.8807 6.88071 18 5.5 18C4.11929 18 3 16.8807 3 15.5C3 14.1193 4.11929 13 5.5 13C6.15179 13 6.74532 13.2494 7.19035 13.658L16.001 8.57122C16.0003 8.54756 16 8.52382 16 8.5C16 7.11929 17.1193 6 18.5 6C19.8807 6 21 7.11929 21 8.5C21 9.88071 19.8807 11 18.5 11C17.8717 11 17.2976 10.7683 16.8584 10.3856Z"
            fill="#333840"
          />
          <path
            opacity="0.6"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.0002 15.4642L7.16616 10.3639C6.72392 10.7595 6.14005 11 5.5 11C4.11929 11 3 9.88071 3 8.5C3 7.11929 4.11929 6 5.5 6C6.88071 6 8 7.11929 8 8.5C8 8.51194 7.99992 8.52386 7.99975 8.53575L16.8338 13.6361C17.2761 13.2405 17.86 13 18.5 13C19.8807 13 21 14.1193 21 15.5C21 16.8807 19.8807 18 18.5 18C17.1193 18 16 16.8807 16 15.5C16 15.4881 16.0001 15.4761 16.0002 15.4642Z"
            fill="#333840"
          />
        </g>
      </svg></Tooltip>
    );
  } else if (props.type === "E") {
    return (
      <Tooltip title="External Import">
      <svg
        width="20"
        height="20"
        viewBox="0 0 24 24"
        fill="none" style={{marginTop: '5px'}}
        xmlns="http://www.w3.org/2000/svg"
        className="grid__icon" role="img" tabIndex={-1} aria-label="External Import"
      >
        <g fill="#333840" fillRule="evenodd">
          <title>{props.title}</title>
          <path
            opacity="0.8"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4 8H20V14H4V8ZM10 10V12H14V10H10ZM7 21H9V22C9 22.5523 8.55228 23 8 23C7.44772 23 7 22.5523 7 22V21ZM15 21H17V22C17 22.5523 16.5523 23 16 23C15.4477 23 15 22.5523 15 22V21Z"
            fill="#333840"
          />
          <path
            d="M4 7V3C4 1.89543 4.89543 1 6 1H18C19.1046 1 20 1.89543 20 3V7H4ZM10 3V5H14V3H10ZM4 15H20V19C20 20.1046 19.1046 21 18 21H6C4.89543 21 4 20.1046 4 19V15ZM10 17V19H14V17H10Z"
            fill="#333840"
          />
        </g>
      </svg>
      </Tooltip>
    );
  } else if (props.type === "I") {
    return (
      <Tooltip title="Inventory Root">
      <svg
        width="20"
        height="20"
        viewBox="0 0 24 24"
        fill="none" style={{marginTop: '5px'}}
        xmlns="http://www.w3.org/2000/svg"
        className="grid__icon" role="img" tabIndex={-1} aria-label="Inventory Tree Root Node"
      >
        <g fill="#333840" fillRule="evenodd">
          <title>{props.title}</title>
          <path
            opacity="0.8"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 6C2 4.89543 2.89543 4 4 4H20C21.1046 4 22 4.89543 22 6V11H20V6H4V18H11V20H4C2.89543 20 2 19.1046 2 18V6ZM15 9V11H13H6V9H15ZM6 13H11V15H6V13ZM13 14.0403C13 13.4658 13.4713 13 14.0526 13H21.9474C22.5287 13 23 13.4658 23 14.0403V19C22.6903 18.823 22.3308 18.7217 21.9474 18.7217H14.0526C13.6692 18.7217 13.3097 18.823 13 19V14.0403Z"
            fill="#333840"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13 21C13 20.4477 13.4713 20 14.0526 20H21.9474C22.5287 20 23 20.4477 23 21V22C23 22.5523 22.5287 23 21.9474 23H14.0526C13.4713 23 13 22.5523 13 22V21ZM14.0526 22V21H16.1579V22H14.0526Z"
            fill="#333840"
          />
        </g>
      </svg>
      </Tooltip>
    );
  } else if (props.type === "S") {
    return (
      <Tooltip title="Discovery Range Scope">
      <svg
        width="20"
        height="20"
        viewBox="0 0 24 24"
        fill="none" style={{marginTop: '5px'}}
        xmlns="http://www.w3.org/2000/svg"
        className="grid__icon" role="img" tabIndex={-1} aria-label="Discovery Range Scope"
      >
        <g fill="#333840" fillRule="evenodd">
          <title>{props.title}</title>
          <path
            d="M17 17H23V23H17V17ZM19 19V21H21V19H19ZM9 17H15V23H9V17ZM11 19V21H13V19H11ZM1 17H7V23H1V17ZM3 19V21H5V19H3ZM12 7C13.1046 7 14 6.10457 14 5C14 3.89543 13.1046 3 12 3C10.8954 3 10 3.89543 10 5C10 6.10457 10.8954 7 12 7ZM12 9C9.79086 9 8 7.20914 8 5C8 2.79086 9.79086 1 12 1C14.2091 1 16 2.79086 16 5C16 7.20914 14.2091 9 12 9Z"
            fill="#333840"
          />
          <path
            opacity="0.6"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19 14H5V17H3V12H21V17H19V14Z"
            fill="#333840"
          />
          <path opacity="0.8" d="M13 7H11V17H13V7Z" fill="#333840" />
        </g>
      </svg>
      </Tooltip>
    );
  } else if (props.type === "M") {
    return (
      <Tooltip title="Manual - Discovery Agent">
      <svg
        width="20"
        height="20"
        viewBox="0 0 24 24"
        fill="none" style={{marginTop: '5px'}}
        xmlns="http://www.w3.org/2000/svg"
        className="grid__icon" role="img" tabIndex={-1} aria-label="Manual - Discovery Agent"
      >
        <g fill="#333840" fillRule="evenodd">
          <title>{props.title}</title>
          <path
            opacity="0.8"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1 4C1 2.89543 1.89543 2 3 2H19C20.1046 2 21 2.89543 21 4V9.5118C20.614 8.78584 20.117 8.10584 19.5108 7.49771C19.3455 7.33186 19.175 7.17427 19 7.02494V4H3V13H5.01574C4.97526 13.6678 5.01285 14.3394 5.12849 15H3C1.89543 15 1 14.1046 1 13V4ZM15.25 11.6365C16.2379 12.6276 16.2379 14.2346 15.25 15.2257C14.262 16.2168 12.6603 16.2168 11.6724 15.2257C10.6844 14.2346 10.6844 12.6276 11.6724 11.6365C12.6603 10.6454 14.262 10.6454 15.25 11.6365Z"
            fill="#333840"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22.1512 22.1281C22.2349 22.0441 22.2951 21.9454 22.3317 21.8403C22.5829 21.3767 22.5131 20.7844 22.1222 20.3922L18.8954 17.155C20.596 14.6199 20.329 11.1515 18.0943 8.90964C15.5563 6.36345 11.4415 6.36345 8.90348 8.90964C6.36551 11.4558 6.36551 15.584 8.90348 18.1302C11.1557 20.3897 14.6498 20.6441 17.1816 18.8933L20.399 22.1211C20.809 22.5325 21.4386 22.5894 21.9094 22.2918C21.9973 22.2545 22.0796 22.2 22.1512 22.1281ZM16.7349 10.1468C18.543 11.9607 18.543 14.9016 16.7349 16.7154C14.9269 18.5293 11.9955 18.5293 10.1874 16.7154C8.37938 14.9016 8.37938 11.9607 10.1874 10.1468C11.9955 8.33287 14.9269 8.33287 16.7349 10.1468Z"
            fill="#333840"
          />
        </g>
      </svg>
      </Tooltip>
    );
  }else{
    return null;
  }
};

InventoryIcons.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};
export default InventoryIcons;
