import {
    FETCH_GROUPS_DASHBOARD_SUCCESS, FETCH_GROUPS_DASHBOARD_FAILURE, 
    FETCH_GROUP_PROFILES_DASHBOARD_SUCCESS,FETCH_GROUP_PROFILES_DASHBOARD_FAILURE,
    FETCH_GROUP_DEVICES_DASHBOARD_SUCCESS, FETCH_GROUP_DEVICES_DASHBOARD_FAILURE,   
    FETCH_DEVICE_BY_DEVICEID_DASHBOARD_SUCCESS,FETCH_DEVICE_BY_DEVICEID_DASHBOARD_FAILURE
    
} from './groups/actionTypes'

import {
    FETCH_DEVICES_DASHBOARD_SUCCESS, FETCH_DEVICES_DASHBOARD_FAILURE,
    FETCH_DEVICE_PROFILES_DASHBOARD_SUCCESS,FETCH_DEVICE_PROFILES_DASHBOARD_FAILURE,	
    FETCH_PROFILE_BY_PROFILEID_DASHBOARD_SUCCESS,FETCH_PROFILE_BY_PROFILEID_DASHBOARD_FAILURE

} from './devices/actionTypes'

const mcsDashboardReducer = (state ={
groups:[],
status:[],
profiles : [],
devices : [],
didInvalidate:false,
errorMessage:null,
groupProfiles : [],
requestType:null,
dashCardView:null,
profile:{},
device:{},
filter:'',
exportData:{data:[]}},action)=> {
    switch (action.type) {
        case FETCH_GROUPS_DASHBOARD_SUCCESS:
        	if(action.groupsDetails.options.exportData==false)
        		{
        		
            return Object.assign({}, state, {
            	exportData:{data:[]},
                groups: action.groupsDetails,
				requestType : 'group',
                dashCardView :  'DASHCARDVIEW_GROUPS_PROFILES_DEVICES',
                 filter:action.groupsDetails.options.status!==undefined && action.groupsDetails.options.status!=='' ?action.groupsDetails.options.status:'',
                	 errorMessage:''
				
            });
        		}
        	else
        		{
        		return Object.assign({}, state, {
                    exportData: action.groupsDetails.data    				   				
                });
        		}

        case FETCH_GROUPS_DASHBOARD_FAILURE:
            return Object.assign({}, state, {
            	exportData:{data:[]},
                errorMessage: action.errorMessage,
				requestType : 'group',				
                dashCardView : 'DASHCARDVIEW_GROUPS_PROFILES_DEVICES',
                filter:action.options.status!==undefined && action.options.status!=='' ?action.options.status:''
            });

        case FETCH_GROUP_PROFILES_DASHBOARD_SUCCESS:
        	if(action.profilesDetails.options.exportData==false)
    		{
    		
            return Object.assign({}, state, {
            	exportData:{data:[]},
                profiles: action.profilesDetails,
				requestType : 'profile',
				dashCardView : action.profilesDetails.options.groupId!==undefined && action.profilesDetails.options.groupId!==''?'DASHCARDVIEW_PROFILES':'DASHCARDVIEW_GROUPS_PROFILES_DEVICES',
                filter:action.profilesDetails.options.status!==undefined && action.profilesDetails.options.status!=='' ?action.profilesDetails.options.status:'',
                   	 errorMessage:''
            });
    		}
        	else
        		{
        		return Object.assign({}, state, {
                    exportData: action.profilesDetails.data   				   				
                });
        		}

        case FETCH_GROUP_PROFILES_DASHBOARD_FAILURE:
            return Object.assign({}, state, {
            	exportData:{data:[]},
                errorMessage: action.errorMessage,
                requestType : 'profile',
				dashCardView : action.options.groupId!==undefined && action.options.groupId!==''?'DASHCARDVIEW_PROFILES':'DASHCARDVIEW_GROUPS_PROFILES_DEVICES',
                filter:action.options.status!==undefined && action.options.status!=='' ?action.options.status:''
            });

        case FETCH_GROUP_DEVICES_DASHBOARD_SUCCESS:
        	if(action.devicesDetails.options.exportData==false)
    		{
    		
            return Object.assign({}, state, {
            	exportData:{data:[]},
                devices: action.devicesDetails,
				requestType : 'device',
				dashCardView : action.devicesDetails.options.profileId!==undefined && action.devicesDetails.options.profileId!==''?'DASHCARDVIEW_DEVICES':'DASHCARDVIEW_GROUPS_PROFILES_DEVICES',		                
                filter:action.devicesDetails.options.status!==undefined && action.devicesDetails.options.status!=='' ?action.devicesDetails.options.status:'',
               	 errorMessage:''
            });
    		}
        	else
        		{
        		return Object.assign({}, state, {
                    exportData: action.devicesDetails.data   				   				
                });
        		}

        case FETCH_GROUP_DEVICES_DASHBOARD_FAILURE:
            return Object.assign({}, state, {
            	exportData:{data:[]},
                errorMessage: action.errorMessage,
				requestType : 'device',				
				dashCardView : action.options.profileId!==undefined && action.options.profileId!==''?'DASHCARDVIEW_DEVICES':'DASHCARDVIEW_GROUPS_PROFILES_DEVICES',			               
				filter:action.options.status!==undefined && action.options.status!=='' ?action.options.status:''
            });

       /* case FETCH_ALL_GROUPS_PROFILES_DASHBOARD_SUCCESS:
            return Object.assign({}, state, {
                profiles: action.allGroupsProfilesDetails,
				requestType : 'profile',
				dashCardView : 'DASHCARDVIEW_GROUPS_PROFILES_DEVICES',
                filter:'Filtered: '
            });

        case FETCH_ALL_GROUPS_PROFILES_DASHBOARD_FAILURE:
            return Object.assign({}, state, {
                errorMessage: action.errorMessage,
				requestType : 'profile',
				dashCardView : 'DASHCARDVIEW_GROUPS_PROFILES_DEVICES',
                filter:'Filtered: '
            });

        case FETCH_GROUP_MEMBER_DEVICES_BY_STATUS_SUCCESS:
            return Object.assign({}, state, {
                devices: action.allGroupMemberDeviceDetails,
                requestType : 'device',
                filter:'Filtered: '
				//dashCardView : 'DASHCARDVIEW_GROUPS_PROFILES_DEVICES'
				
            });

        case FETCH_GROUP_MEMBER_DEVICES_BY_STATUS_FAILURE:
            return Object.assign({}, state, {
                errorMessage: action.errorMessage,
                requestType : 'device',
                filter:'Filtered: '
				//dashCardView : 'DASHCARDVIEW_GROUPS_PROFILES_DEVICES'
            });
        case FETCH_GROUP_PROFILES_BY_STATUS_SUCCESS:
            return Object.assign({}, state, {
                profiles: action.profileDetails,
                requestType : 'profile',
                filter:'Filtered: '
				//dashCardView : 'DASHCARDVIEW_GROUPS_PROFILES_DEVICES'
				
            });

        case FETCH_GROUP_PROFILES_BY_STATUS_FAILURE:
            return Object.assign({}, state, {
                errorMessage: action.errorMessage,
                requestType : 'profile',
                filter:'Filtered: '
                //dashCardView : 'DASHCARDVIEW_GROUPS_PROFILES_DEVICES'
                
            });
        case FETCH_GROUPS_BY_STATUS_SUCCESS:
            return Object.assign({}, state, {
                 groups: action.groupDetails,
                requestType : 'group',
                filter:'Filtered: '
				
				//dashCardView : 'DASHCARDVIEW_GROUPS_PROFILES_DEVICES'
				
            });

        case FETCH_GROUPS_BY_STATUS_FAILURE:
            return Object.assign({}, state, {
                errorMessage: action.errorMessage,
                requestType : 'group',
                filter:'Filtered: '
                //dashCardView : 'DASHCARDVIEW_GROUPS_PROFILES_DEVICES'
                
			});*/
			
		case FETCH_DEVICES_DASHBOARD_SUCCESS:
			if(action.devicesDetails.options.exportData==false)
    		{
    		
            return Object.assign({}, state, {
            	exportData:{data:[]},
                devices: action.devicesDetails,
				requestType : 'device',
				dashCardView : 'DASHCARDVIEW_DEVICES_PROFILES',
				filter:action.devicesDetails.options.status!==undefined && action.devicesDetails.options.status!=='' ?action.devicesDetails.options.status:'',
	                	 errorMessage:''
            });
    		}else
        		{
        		return Object.assign({}, state, {
                    exportData: action.devicesDetails.data   				   				
                });
        		}

        case FETCH_DEVICES_DASHBOARD_FAILURE:
            return Object.assign({}, state, {
            	exportData:{data:[]},
                errorMessage: action.errorMessage,
				requestType : 'device',
				dashCardView : 'DASHCARDVIEW_DEVICES_PROFILES',
				filter:action.options.status!==undefined && action.options.status!=='' ?action.options.status:''
            });
           /* case FETCH_DEVICES_BY_STATUS_SUCCESS:
            return Object.assign({}, state, {
                devices: action.devicesDetails,
				requestType : 'device',
				dashCardView : 'DASHCARDVIEW_DEVICES_PROFILES',
               filter:'Filtered: '
            });

        case FETCH_DEVICES_BY_STATUS_FAILURE:
            return Object.assign({}, state, {
                errorMessage: action.errorMessage,
				requestType : 'device',
				dashCardView : 'DASHCARDVIEW_DEVICES_PROFILES',
                filter:'Filtered: '
            });
            
            case FETCH_DEVICE_PROFILES_BY_STATUS_SUCCESS:
            return Object.assign({}, state, {
                profiles: action.profilesDetails,
				requestType : 'profile',
				dashCardView : 'DASHCARDVIEW_DEVICES_PROFILES',
                filter:'Filtered: '
            });

        case FETCH_DEVICE_PROFILES_BY_STATUS_FAILURE:
            return Object.assign({}, state, {
                errorMessage: action.errorMessage,
				requestType : 'profile',
				dashCardView : 'DASHCARDVIEW_DEVICES_PROFILES',
                filter:'Filtered: '
			});
			*/
		case FETCH_DEVICE_PROFILES_DASHBOARD_SUCCESS:
			if(action.profilesDetails.options.exportData==false)
    		{
            return Object.assign({}, state, {
            	exportData:{data:[]},
                profiles: action.profilesDetails,
				requestType : 'profile',
				dashCardView : action.profilesDetails.options.deviceId!==undefined && action.profilesDetails.options.deviceId!==''?'DASHCARDVIEW_DPROFILES':'DASHCARDVIEW_DEVICES_PROFILES',
				filter:action.profilesDetails.options.status!==undefined && action.profilesDetails.options.status!=='' ?action.profilesDetails.options.status:'',
	                	 errorMessage:''
            });
    		}else{
        		return Object.assign({}, state, {
                    exportData: action.profilesDetails.data   				   				
                });
        		}


        case FETCH_DEVICE_PROFILES_DASHBOARD_FAILURE:
            return Object.assign({}, state, {
            	exportData:{data:[]},
                errorMessage: action.errorMessage,
				requestType : 'profile',
				dashCardView : action.options.deviceId!==undefined && action.options.deviceId!==''?'DASHCARDVIEW_DPROFILES':'DASHCARDVIEW_DEVICES_PROFILES',
				filter:action.options.status!==undefined && action.options.status!=='' ?action.options.status:'',
	                	 errorMessage:''
			});
		/*case FETCH_DEVICE_PROFILES_BY_DEVICEID_DASHBOARD_SUCCESS:
            return Object.assign({}, state, {
                profiles: action.profilesDetails,
				requestType : 'profile',
				dashCardView : 'DASHCARDVIEW_DPROFILES',
                filter:''
            });

        case FETCH_DEVICE_PROFILES_BY_DEVICEID_DASHBOARD_FAILURE:
            return Object.assign({}, state, {
                errorMessage: action.errorMessage,
                requestType : 'profile',
				dashCardView : 'DASHCARDVIEW_DPROFILES',
                filter:''
            });

            case FETCH_DEVICE_PROFILES_BY_STATUS_AND_DEVICEID_SUCCESS:
            return Object.assign({}, state, {
                profiles: action.profilesDetails,
				requestType : 'profile',
				dashCardView : 'DASHCARDVIEW_DPROFILES',
                filter:'Filtered: '
            });

        case FETCH_DEVICE_PROFILES_BY_STATUS_AND_DEVICEID_FAILURE:
            return Object.assign({}, state, {
                errorMessage: action.errorMessage,
                requestType : 'profile',
				dashCardView : 'DASHCARDVIEW_DPROFILES',
                filter:'Filtered: '
            });*/

 
        case FETCH_DEVICE_BY_DEVICEID_DASHBOARD_SUCCESS:
            return Object.assign({}, state, {
            	exportData:{data:[]},
                device: action.deviceDetails,
				requestType : 'device_detail',
				dashCardView : 'DASHCARDVIEW_DEVICE_DETAILS',
                filter:'',
           	 errorMessage:''
            });

        case FETCH_DEVICE_BY_DEVICEID_DASHBOARD_FAILURE:
            return Object.assign({}, state, {
            	exportData:{data:[]},
                errorMessage: action.errorMessage,
                requestType : 'device_detail',
				dashCardView : 'DASHCARDVIEW_DEVICE_DETAILS',
                filter:''
            });
            case FETCH_PROFILE_BY_PROFILEID_DASHBOARD_SUCCESS:
            return Object.assign({}, state, {
            	exportData:{data:[]},
                profile: action.profileDetails,
				requestType : 'profile_detail',
				dashCardView : 'DASHCARDVIEW_PROFILE_DETAILS',
                filter:'',
           	 errorMessage:''
            });

        case FETCH_PROFILE_BY_PROFILEID_DASHBOARD_FAILURE:
            return Object.assign({}, state, {
            	exportData:{data:[]},
                errorMessage: action.errorMessage,
                requestType : 'profile_detail',
				dashCardView : 'DASHCARDVIEW_PROFILE_DETAILS',
                filter:''
            });

           /* case FETCH_DEVICE_PROFILES_BY_DEVICEID_DASHBOARD_SUCCESS:
            return Object.assign({}, state, {
                profiles: action.profilesDetails,
				requestType : 'profile',
				dashCardView : 'DASHCARDVIEW_DPROFILES',
                filter:''
            });

        case FETCH_DEVICE_PROFILES_BY_DEVICEID_DASHBOARD_FAILURE:
            return Object.assign({}, state, {
                errorMessage: action.errorMessage,
                requestType : 'profile',
				dashCardView : 'DASHCARDVIEW_DPROFILES',
                filter:''
            });

            case FETCH_GROUP_MEMBER_DEVICES_BY_STATUS_AND_PROFILE_ID_SUCCESS:
            return Object.assign({}, state, {
                devices: action.allGroupMemberDeviceDetails,
                requestType : 'device',
                filter:'Filtered: '
				//dashCardView : 'DASHCARDVIEW_GROUPS_PROFILES_DEVICES'
				
            });

        case FETCH_GROUP_MEMBER_DEVICES_BY_STATUS_AND_PROFILE_ID_FAILURE:
            return Object.assign({}, state, {
                errorMessage: action.errorMessage,
                requestType : 'device',
                filter:'Filtered: '
				//dashCardView : 'DASHCARDVIEW_GROUPS_PROFILES_DEVICES'
            });

            case FETCH_GROUP_PROFILES_BY_STATUS_AND_GROUPID_SUCCESS:
            return Object.assign({}, state, {
                profiles: action.profileDetails,
                requestType : 'profile',
                filter:'Filtered: '
				//dashCardView : 'DASHCARDVIEW_GROUPS_PROFILES_DEVICES'
				
            });

        case FETCH_GROUP_PROFILES_BY_STATUS_AND_GROUPID_FAILURE:
            return Object.assign({}, state, {
                errorMessage: action.errorMessage,
                requestType : 'profile',
                filter:'Filtered: '
                //dashCardView : 'DASHCARDVIEW_GROUPS_PROFILES_DEVICES'
                
            });*/



        default:
            return state
    }
}

export default mcsDashboardReducer;
