import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import connect from "./../../../../utils/connect";
import { setBreadCrumbs } from "./../../../breadcrumbs/actions";
import {CircularProgress} from '@mineral/core';
import Resource from "./Resource";
import PerformanceLayout from "./PerformanceLayout/PerformanceLayout";
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'

//Performance Reports Designer
export const prd_name_text= Resource.get("Performance Reports Designer");
class PerformanceReports extends Component {
  state = {
    sid: null,
  };
  
  componentWillMount = () => {
    
    this.setState({ sid:this.props.sid });
    
  };
  
  componentDidMount() {
    this.props.setBreadCrumbs([
      {
        //Settings
        name: Resource.get("Settings"),
        link: "/settings",
      },
      {
        
        name: prd_name_text,
        link: "/settings/performancereports",
      },
    ]);
  }
  
  render() {
    let view = (
      <div className="spinner" style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><CircularProgress style={{color: '#3272D9'}} /></div>

      );
      
      if (this.state.sid) {
        let do_heartbeat = window.OPERATOR_CONSOLE.do_heartbeat
        if (do_heartbeat !== 'true' && do_heartbeat !== 'false') {
          do_heartbeat = false
        }
        view = (
          <DndProvider backend={HTML5Backend}>
          <PerformanceLayout/>
          </DndProvider>
          );
        }
        
        return view;
      }
    }
    
    const mapDispatchToProps = (dispatch) => {
      return {
        setBreadCrumbs: (items) => dispatch(setBreadCrumbs(items)),
      };
    };
    
    const mapStateToProps = (state) => {
      return {
        sid: state.sid,
      };
    };
    
    export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PerformanceReports));