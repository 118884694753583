 export const requestAlarms = (state,action) => {
     if(state[action.id]){
        return state
      }
      return Object.assign({}, state, {
        [action.id]: {
          isFetching: true,
          didInvalidate: false,
          items:[]
        }
      });
 }

 export const fetchAlarmsSuccess = (state,action) =>{
     const items = action.data._items.map((item) => {
        return {
          day: item.date,
          Info: item.alarmCountSummary.information,
          Warning: item.alarmCountSummary.warning,
          Minor: item.alarmCountSummary.minor,
          Major: item.alarmCountSummary.major,
          Critical: item.alarmCountSummary.critical
        }
      })
      return Object.assign({}, state, 
        {
          [action.id]: {
          isFetching: false,
          didInvalidate: false,
          items:items
        }
    })
 }

 export const fetchAlarmsFailure = (state,action) =>{
     return Object.assign({}, state, {
       [action.id]: {
          isFetching: false,
          didInvalidate: true,
          errorMessage: action.errorMessage,
          items:[]
        }
      });
 }