import React, { Component } from 'react'
import './credentialVault.less'
import Resource from './Resource'




class ScopeWelcomeText extends Component{
	constructor(){
		  super();
		  this.state = {}
	  }
	
	
	render(){
		
		let scopeLabel1=Resource.get('Define network addresses, ranges, or masks where devices are to be discovered.')//Define network addresses, ranges, or masks where devices are to be discovered.
		let scopeLabel2=Resource.get('Click')//click
		let scopeLabel3=Resource.get('to add a ')//to add a
		let scopeLabel4=Resource.get('range scope')//range scope
		let scopeLabel5=Resource.get(', or click an existing scope to make changes. The credentials allow you to assign any combination of Linux/Unix, and WMI authentication profiles to a scope')//scope welcome
	    
			return(
					<div className='welcome-text' >
					<span>{scopeLabel1}</span>
					<span style={{display:'block',paddingTop:'20px'}}>{scopeLabel2}
					<span className='addIcon-image-disabled'/>
					<span>{scopeLabel3}</span>
					<span style={{fontWeight:600}}>{scopeLabel4}</span>
					<span>{scopeLabel5}</span>
					</span>
					</div>
					
					)		
		
	}
}
 export default ScopeWelcomeText