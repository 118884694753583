import React, { Component } from "react";

import GroupTree from "./GroupTree";
import groupsApi from "./../../../api/groups/groups";
import {CircularProgress} from "@mineral/core";
import Resource from "./Resource";

class Group extends Component {
  constructor(props) {
    super(props);
    this.state = {
      groups: [],
      error: null,
      selectedGroup: null,
      groupId: null,
    };
    this.getItem = this.getItem.bind(this);
  }
  getItem = (id, items) => {
    //recursive find operations don't work as expected(it returns the parent object instead of the child object when found)
    //so we use a recursive for loop
    let item = {};
    if (items && items.length) {
      for (let i = 0; i < items.length; i++) {
        if (parseInt(items[i].id, 10) === parseInt(id, 10)) {
          //this.setState({ selectedGroup: items[i] })
          return items[i];
        }
        if (items[i].groups) {
          item = this.getItem(id, items[i].groups);
          if (item) {
            return item;
          }
        }
      }
    }
  };
  getGroups = () => {
    groupsApi
      .getAllGroups()
      .then((response) => {
        const rootGroup = {
          id: "0",
          parentId: "-1",
          name: "Groups",
          description: null,
          type: "CONTAINER",
          show: true,
          path: "/groups",
        };
        const displayedGroups = response.data._items.filter(
          (group) => parseInt(group.parentId, 10) === -1
        );
        if (displayedGroups.length === 0) {
          response.data._items.push(rootGroup);
        }
        this.setState({
          groups: response.data._items,
          isLoading: false,
        });
      })
      .catch((err) => {
        // console.log(err)
        this.setState({
          isLoading: false,
          error: err,
        });
      });
  };

  componentDidMount() {
    this.setState({
      isLoading: true,
    });
    this.getGroups();
  }
  onGroupChange = (e, value) => {
    const groups = this.state.groups;
    this.setState({ groupId: e });
    this.props.onSelectGroup(this.getItem(e, groups));
  };

  render() {
    const { show } = this.props;
    const selectAGroupLabel = Resource.get("Select a group"); // label
    const noGroupsLabel = Resource.get("No groups available"); // message
    const groupTitle = Resource.get("Group"); // group
    const groups = this.state.groups;
    const currGroup =
      this.state.groupId && this.state.groupId != null
        ? this.getItem(this.state.groupId, groups)
        : this.props.isEditMode
        ? this.props.entity.parentId
          ? this.getItem(this.props.entity.parentId, groups)
          : {}
        : this.props.entity.id
        ? this.getItem(this.props.entity.id, groups)
        : {};
    let view = (
      <GroupTree
        {...this.props}
        isEditMode={this.props.isEditMode}
        handleRadioChange={this.onGroupChange}
        currGroup={currGroup}
      >
        {groups}
      </GroupTree>
    );

    if (this.state.isLoading) {
      view = (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress style={{ color: "#3272D9" }} />
        </div>
      );
    } else if (!this.state.isLoading && groups.length === 0) {
      view = <p>{noGroupsLabel}</p>;
    }
    return <div>{view}</div>;
  }
}

export default Group;
