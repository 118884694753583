import React from "react";
import { SvgIcon } from "../ui-components/uim-components";

const Icon = (props) => {
  return (
    <SvgIcon {...props}>
     

      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path 
        d="M3 2H11C12.1046 2 13 2.89543 13 4V7H3V2Z" 
        fill={props.color?props.color:"#333840"}/>
      <path 
        opacity="0.8" fillRule="evenodd" clipRule="evenodd" 
        d="M5 21H3V9H5V16H9V18H5V21ZM15 9C16.1046 9 17 9.89543 17 11V14H7V9H15Z" 
        fill={props.color?props.color:"#333840"}
      />
      <path 
        opacity="0.6" 
        fillRule="evenodd" 
        clipRule="evenodd" 
        d="M11 16H19C20.1046 16 21 16.8954 21 18V21H11V16Z" 
        fill={props.color?props.color:"#333840"}/>
    </svg>
    </SvgIcon>
  );
};

export default Icon;
