import {
    FETCH_AGENTS,
    FETCH_AGENTS_STATUS,
    GET_JOBS_RUNNING,
    POLL_AGENTS_START,
    POLL_AGENTS_STOP,
    POLL_AGENTS_STATUS_START,
    POLL_AGENTS_STATUS_STOP,
    POLL_JOBS_RUNNING_START,
    POLL_JOBS_RUNNING_STOP
  } from './actionTypes'
  import {
    call,
    put,
    takeEvery,
    takeLatest,
    take,
    cancel,
  } from 'redux-saga/effects'
  import { delay } from 'redux-saga/effects'
  import agents from './agents'

  import {
    fetchAgentsSuccess,
    fetchAgentsFailure,
    fetchAgentsStatusSuccess,
    fetchAgentsStatusFailure,
    fetchJobsRunningSuccess,
    fetchJobsRunningFailure
    } from './actions'

function* getJobsCountpoll(){
    try{
        const response = yield call(agents.getRunningJobs)
        yield put(fetchJobsRunningSuccess(response.data))
    }catch(error){
        yield put(fetchJobsRunningFailure(error));
    }
}

function* fetchAgentspoll(){
    try{
      const response = yield call(agents.get)
      yield put(fetchAgentsSuccess(response.data))
    }catch(error){
        yield put(fetchAgentsFailure(error));
    }
}

function* fetchAgentsStatuspoll(){
    try{
      const response = yield call(agents.getStatus)
      yield put(fetchAgentsStatusSuccess(response.data))
    }catch(error){
        yield put(fetchAgentsStatusFailure(error));
    }
}

function* pollJobsCount(action) {
    while (true) {
      yield delay(30000)
      yield call(getJobsCountpoll)
    }
}

function* pollAgents(action) {
    while (true) {
      yield delay(30000)
      yield call(fetchAgentspoll)
    }
}

function* pollAgentsStatus(action) {
    while (true) {
      yield delay(5000)
      yield call(fetchAgentsStatuspoll)
    }
}

export function* jobsCountRequestSaga() {
    yield takeEvery(GET_JOBS_RUNNING, getJobsCountpoll)
}
  
export function* jobsCountPollingSaga() {
    while (true) {
        const watcherInstance = yield takeLatest(POLL_JOBS_RUNNING_START, pollJobsCount)
        yield take(POLL_JOBS_RUNNING_STOP)
        yield cancel(watcherInstance)
    }
}

export function* agentsRequestSaga() {
    yield takeEvery(FETCH_AGENTS, fetchAgentspoll)
}
  
export function* agentsPollingSaga() {
    while (true) {
        const watcherInstance = yield takeLatest(POLL_AGENTS_START, pollAgents)
        yield take(POLL_AGENTS_STOP)
        yield cancel(watcherInstance)
    }
}


export function* agentsStatusRequestSaga() {
    yield takeEvery(FETCH_AGENTS_STATUS, fetchAgentsStatuspoll)
}
  
export function* agentsStatusPollingSaga() {
    while (true) {
        const watcherInstance = yield takeLatest(POLL_AGENTS_STATUS_START, pollAgentsStatus)
        yield take(POLL_AGENTS_STATUS_STOP)
        yield cancel(watcherInstance)
    }
}

