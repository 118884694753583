import React, { Component } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ReferenceLine,
} from "recharts";
import {
  fetchAllMetric,
  getMetricDevices,
  fetchGoldenMetricData,
} from "../../../api/devices/actions";
import connect from "../../../utils/connect";
import isEqual from "lodash/isEqual";
import isEmpty from 'lodash/isEmpty';
import moment from "moment";
import {CircularProgress} from "@mineral/core";
import { strokeCombinations } from "../../metricpalettecode/components/MineralMetricChart/dataset";
import { MatricsChart } from "./MetricsChart";

const randomColor = [
  "#2F8DFA",
  "#5C6BC0",
  "#AB47BC",
  "#e67e22",
  "#ef5350",
  "#26C6DA",
  "#66BB6A",
  "#D4E157",
  "#00897B",
  "#FFCA28",
  "#FFA726",
  "#FF7043",
  "#8D6E63",
  "#BDBDBD",
  "#78909C",
  "#0099BC",
  "#8E8CD8",
  "#00B294",
  "#10893E",
  "#567C73",
  "#7E735F",
  "#2D7D9A",
  "#767676",
  "#7A7574",
];
const randomColoFn = () => {
  let color =
    "#" +
    ("000000" + Math.floor(Math.random() * 16777215).toString(16)).slice(-6);
  return color;
};

let getContainerWidth = (isTreeOpen) => {
  if (isTreeOpen) {
    return window.innerWidth > 1700
      ? 780
      : window.innerWidth > 1550
      ? 680
      : window.innerWidth > 1375
      ? 630
      : 545;
  } else {
    return window.innerWidth > 1700
      ? 950
      : window.innerWidth > 1500
      ? 840
      : 740;
  }
};
let getContainerChartWidth = (isTreeOpen) => {
  if (isTreeOpen) {
    return window.innerWidth > 1700
      ? 770
      : window.innerWidth > 1550
      ? 670
      : window.innerWidth > 1375
      ? 630
      : 545;
  } else {
    return window.innerWidth > 1700
      ? 925
      : window.innerWidth > 1500
      ? 825
      : 725;
  }
};
let chartArray = [];
const  aggregationValue= -1;
const aggregateFunction= "AVG" ;

class Metrics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      containerWidth: getContainerWidth(this.props.isTreeOpen),
      containerChartWidth: getContainerChartWidth(this.props.isTreeOpen),
    };
  }

  componentDidMount() {
    this.fetchMetricData();
    window.addEventListener("resize", this.updateWidth);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWidth);
  }

  componentWillReceiveProps(nextProps) {
    if (!isEqual(this.props.csId, nextProps.csId) || !isEqual(this.props.isInterface,nextProps.isInterface) || !isEqual(this.props.masterId,nextProps.masterId)) {
      this.fetchMetricData(nextProps);
    }
    if (nextProps.isTreeOpen != this.props.isTreeOpen) {
      this.setState({
        containerWidth: getContainerWidth(nextProps.isTreeOpen),
        containerChartWidth: getContainerChartWidth(nextProps.isTreeOpen),
      });
    }
  }
  updateWidth = () => {
    this.setState({
      containerWidth: getContainerWidth(this.props.isTreeOpen),
      containerChartWidth: getContainerChartWidth(this.props.isTreeOpen),
    });
  };

  fetchMetricData(localProps) {
    let props = localProps ? localProps : this.props;
    let body = {};
    
    if(props.isInterface){
      body.masterId=props.masterId;
      body.csId=props.csId;
    }else
    {
      body.csId = props.csId;
    }
    props.fetchAllMetric(body).then(() => {
      let defaultView = this.props.devices.savedMetrics.MyViews.filter(
        (mtr) => mtr.isDefault
      );
      defaultView = defaultView && defaultView.length && defaultView[0];
      if (defaultView) {
        let selectedCIs = {};
        let mtrIds = [];
        let metricDef =
          defaultView.definition && JSON.parse(defaultView.definition);
        if (defaultView.definition) {
          metricDef.metrics.map((mtr) => {
            selectedCIs[mtr.metricTypeId] = mtr.ciNames;
            mtrIds.push(mtr.metricTypeId);
          });
          props.getMetricForDevices(
            metricDef.csIds.join(),
            mtrIds,
            24, //props.timerange,
            selectedCIs,
            this.props.devices.isInterface,
            props.timerangeEndDate,
            aggregationValue,
            aggregateFunction
          );
        }
      } else {
        props.fetchGoldenMetricData(props.isInterface).then(() => {
          let selectedCIs = {};
          let mtrIds = [];
          let metricDef =
            this.props.devices.goldenMetrics.definition &&
            JSON.parse(this.props.devices.goldenMetrics.definition);
          if (this.props.devices.goldenMetrics.definition) {
            metricDef.metrics.map((mtr) => {
              selectedCIs[mtr.metricTypeId] = mtr.ciNames;
              mtrIds.push(mtr.metricTypeId+"--"+mtr.qosName);
            });
            props.getMetricForDevices(
              props.csId,
              mtrIds,
              24, //props.timerange,
              selectedCIs,
              this.props.devices.isInterface,
              props.timerangeEndDate,
              aggregationValue,
              aggregateFunction
            );
          }
        });
      }
    });
  }
  xAxisTickFormatter = (tick) => {
    tick = moment(tick*1000).format("YYYY-MM-DD HH:mm");
  return tick;
  };
  tooltipLabelFormatter = (val) => {
    val = moment(val*1000).format("YYYY-MM-DD HH:mm");
  return val;
};
  getDeviceChartData = (metricDevice) => {
    let lineChartArray = [];
    if (metricDevice.deviceData) {
      metricDevice.deviceData.map((device, i) => {
        const shuffledCombos = strokeCombinations.sort(() => 0.5 - Math.random());
        let selectedCombos = shuffledCombos.slice(0, metricDevice.sampleTimeLength);
        let dataKey = device.deviceName;
        if (device.ciName) {
          dataKey = `${dataKey}-${device.ciName}`;
        }
        let lineData = metricDevice?(metricDevice.chartDefinationRaw?
          metricDevice.chartDefinationRaw[dataKey]
        :null):null;
        lineChartArray.push(
          <Line           
            dataKey={dataKey}
            key={dataKey}
            data = {lineData}
            dot={true}            
            stroke={selectedCombos[i].color}
          />
        );
      });
    }
    return lineChartArray;
  };

  getMyColor = () => {
    let n = (Math.random() * 0xfffff * 1000000).toString(16);
    return '#' + n.slice(0, 6);
  };

  getCharts = () => {
    if (
      (this.props.devices.isFetchingDevice ||
      this.props.devices.isFetchingAll ||
      this.props.devices.isFetching ||
      this.props.devices.isFetchingDvc ||
      this.props.devices.isFetchingGolden) && (chartArray.length || !chartArray.length)
    ) {
      //return <div className="no-metrics">Loading...</div>;
      return <div 
              className="no-metrics" 
              style={{display:"flex", justifyContent:"center", alignItems:"center", height:"100%"}}>
              <CircularProgress style={{ color: "#3272D9" }} />
             </div>
    }  
    chartArray = [];
    this.props.devices.metricsForDevices &&
      this.props.devices.metricsForDevices.map((metricDevice, index) => {
        if (
          (metricDevice.chartDefination &&
            metricDevice.chartDefination.length) || !isEmpty(metricDevice.chartDefinationRaw )
        ) {
          //const shuffledCombos = strokeCombinations.sort(() => 0.5 - Math.random());
          //let selectedCombos = shuffledCombos.slice(0, metricDevice.sampleTimeLength);
        const count = metricDevice.sampleTimeLength > 0 ? metricDevice.sampleTimeLength : 1;
        let selectedCombos = [];
        let i;
        for(i=0;i<count;i++)
        {
          selectedCombos.push({
            color: this.getMyColor(),
            stroke: "dashed",
            icon: "filledCircle"
        })
        }
          chartArray.push({
            chartName: `${metricDevice.name} (in ${metricDevice.unit})`,
            chartInfo: (
              <MatricsChart
              metricDevice={metricDevice}
              aggregationValue={aggregationValue}
              selectedCombos={selectedCombos}
              index={index}
               />
            ),
          });
        }
      });

    if (!chartArray.length) {
      return <div className="no-metrics">No metrics found</div>;
    }

    let splitChartArr = [chartArray];

    return (
      <div className={this.props.fromGroup ? "tree-view-style" : ""}>
        {splitChartArr.map((chartData, index) => (
          <div
            key={index}
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            {chartData.map((cData, index) => (
              <>
                <div className="title" title={cData.chartName}>
                  {cData.chartName}
                </div>
                <div
                  className="timeserie-wrapper"
                  style={{
                    width: this.state.containerWidth,
                    height: "300px",
                  }}
                  key={index}
                >
                  {cData.chartInfo}
                </div>
              </>
            ))}
          </div>
        ))}
      </div>
    );
  };

  render() {
    return (
      <div className="metrics" style={{width:"100%"}}>
        <div className="charts" style={(chartArray.length || !chartArray.length) && {height:"95%"}}>{this.getCharts()}</div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchAllMetric: (body) => dispatch(fetchAllMetric(body)),
    getMetricForDevices: (...data) => {
      dispatch(getMetricDevices(...data.slice(0,4), ownProps.isInterface, ...data.slice(5,8), ownProps.ciName))
    },
    fetchGoldenMetricData: (isInterface) =>
      dispatch(fetchGoldenMetricData(isInterface)),
  };
};
const mapStateToProps = (state) => {
  return {
    savedMetrics: state.devices.savedMetrics,
    devices: state.devices,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Metrics);