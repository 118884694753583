import React from 'react'

import noDataFound from './../../assets/images/uim_table_no_data.png'


const NoTableDataComponent = (props) => {
  return (
      <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
        <img src={noDataFound} alt={'No data found'} style={{marginBottom: '15px'}} />
        <p>{props.noDataText}</p>
      </div>
  )
}

export default NoTableDataComponent
