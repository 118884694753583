import {
  REQUEST_HUBS,
  FETCH_HUBS_SUCCESS,
  FETCH_HUBS_FAILURE,
  SELECTED_HUB
} from './actionTypes'
import isEqual from 'lodash/isEqual'

const hubs = (state = {
  isFetching: false,
  didInvalidate: false,
  items: [],
  selectedHub:null
}, action) => {
  switch (action.type) {
    case REQUEST_HUBS:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false
      });
    case FETCH_HUBS_SUCCESS:
      const items = action.hubs._items
      const updatedState = Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        items: items
      });
      return isEqual(updatedState, state) ? state : updatedState
    case FETCH_HUBS_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        errorMessage: action.errorMessage,
        items: []
      });
    case SELECTED_HUB:
    return Object.assign({}, state, {
      selectedHub:action.selectedHub
    });
    default:
      return state
  }
};

export default hubs
