import React from "react";
import connect from "../../utils/connect";
import {setBreadCrumbs} from "../breadcrumbs/actions";
import {groupDashboardComponentsChanged} from "../../api/groups/actions";
import DeviceDashboard from "./DeviceDashboard";
import GroupDashboard from "./GroupDashboard";
import ProbeDashboard from "./ProbeDashboard";
import {withRouter} from "react-router-dom";

export const isValidParam = (param) => {
  if (
    param == null ||
    param == "null" ||
    param == undefined ||
    param == "undefined"
  ) {
    return false;
  } else {
    return true;
  }
};
const mapDispatchToProps = (dispatch) => {
  return {
    setBreadCrumbs: (crumbs) => dispatch(setBreadCrumbs(crumbs)),
    groupDashboardComponentsChanged: (data) =>
      dispatch(groupDashboardComponentsChanged(data)),
  };
};
const mapStateToProps = (state) => {
  return {
    groupDashboardComponents: state.groupsDashboard.groupDashboardComponents,
    selectedEntity: state.entity,
  };
};

const Container = (props) => {
  return props.groupId !== undefined && props.groupId != null ? (
    <GroupDashboard {...props} />
  ) : props.probeId !== undefined && props.probeId != null ? (
    <ProbeDashboard {...props} />
  ) : (
    <DeviceDashboard
      {...props}
      csId={props.csId || (props.location.state && props.location.state.csId)}
      fromTreeView={props.location.pathname.includes("treeviewnew")}
    />
  );
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Container));
