// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Home
  content["Home"] =
  "Startseite";

  //Alarms
  content["Alarms"] =
  "Alarme";

  //Groups
  content["Groups"] =
  "Gruppen";

  //Inventory
  content["Inventory"] =
  "Inventar";

  //Dashboards
  content["Dashboards"] =
  "Dashboards";

  //Reports
  content["Reports"] =
  "Berichte";

  //Settings
  content["Settings"] =
  "Einstellungen";

  //Setup Wizard
  content["Setup Wizard"] =
  "Setup-Assistent";

  //Community
  content["Community"] =
  "Community";

  //Help
  content["Help"] =
  "Hilfe";

  // END OF LOCALIZATION

export default content;
