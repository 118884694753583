import React from 'react'
import {  ShowMoreIcon } from '../../ui-components/uim-components'
import {IconButton} from "@mineral/core"
import connect from './../../../utils/connect'
import { showInventoryFilter } from './actions'

const filterIconStyle=(props)=>{
  
  return ({  width: '0.4rem',
  transform: `${props.show ? 'rotate(90deg)' : ''}`,
  transition: 'transform .2s ease',
})
//iconStyle replced
}
const ShowFiltersButton = (props)=> {
  return (
    <IconButton
    classes={{root: "show-filters__icon",       
  }}
  onClick={() => {
    if (props.onTouchTap) {
      props.onTouchTap()
    }
  }}      
  >
  <ShowMoreIcon style={filterIconStyle(props)} />
  </IconButton>
  )
}
ShowFiltersButton.defaultProps = {
  show: false,
}



const ShowFiltersButtonRedux = (props) => {
  return (
    <ShowFiltersButton
    show={props.showFilter}
    onClick={() => {
      props.setShowFilter(!props.showFilter)
    }}
    />
    )
  }
  const mapStateToProps = state => {
    return {
      showFilter: state.inventoryFilter.showFilter,
    }
  }
  const mapDispatchToProps = dispatch => {
    return {
      setShowFilter: showFilter => dispatch(showInventoryFilter(showFilter)),
    }
  }
  export default connect(mapStateToProps, mapDispatchToProps)(
    ShowFiltersButtonRedux,
    )
    