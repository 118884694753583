import React from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import './mineralDatePicker.less'

export const MineralDateOnlyPicker = ({
  id,
  disabled,
  label,
  placeholder,
  ...restProps
}) => {
  const [value, setValue] = React.useState(null);
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
       id={id}
        label={label}
        value={value}
        className="mineralDateTimePickerWidget"
        disabled={disabled}
        onChange={(newValue) => {
          setValue(newValue);
        }}
        componentsProps={{
          switchViewButton: {
            sx: {
              margin: '20px 5px'
            }
          }
        }}
        PaperProps={{
          className: 'mineralDatePicker',
        }}
        InputProps={{
          className: 'mineralDateTimePicker_TextBox',
        }}
        PopperProps={{
          "aria-label":{label}
        }}
        OpenPickerButtonProps={{tooltip:'Change date'}}
        renderInput={(params) => (
          <TextField
            {...params}

            InputProps={{
              ...params.InputProps,
              style: { height: '32px'},
            }}
            
            inputProps={{
              ...params.inputProps,
              placeholder: placeholder || 'Select Date',
              readOnly:true
            }}
           
            
          />
        )}
        {...restProps}
      />
    </LocalizationProvider>
  );
};