// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Settings
  content["Settings"] =
  "Settings";

  //Device Self Certification
  content["Device Self Certification"] =
  "Device Self Certification";

  //Device self certification for SNMP collector
  content["Device self certification for SNMP collector"] =
  "Device self certification for SNMP collector";

  // END OF LOCALIZATION

export default content;
