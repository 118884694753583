// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Import CSV
  content["Import CSV"] =
  "导入 CSV";

  // END OF LOCALIZATION

export default content;
