// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //ALARM SUMMARY
  content["ALARM SUMMARY"] =
  "アラーム サマリ";

  //ALARM HISTORY LAST 30 DAYS
  content["ALARM HISTORY LAST 30 DAYS"] =
  "アラーム履歴過去 30 日間";

  //ALARM HISTORY
  content["ALARM HISTORY"] =
  "アラーム履歴";

  // END OF LOCALIZATION

export default content;
