// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //dashError
  content["Dashboard failed to load."] =
  "Dashboard konnte nicht geladen werden";

  //missing component
  content["CA Business Intelligence (CABI) system component is needed."] =
  "CA Business Intelligence (CABI)-Systemkomponente ist erforderlich";

  //Breadcrumb
  content["Dashboards"] =
  "Dashboards";

  //Breadcrumb
  content["Overview"] =
  "Übersicht";

  // END OF LOCALIZATION

export default content;
