import React from "react";
import { SvgIcon } from "../ui-components/uim-components";

const Icon = (props) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4,12 L9,12 L9,6 L4,6 L4,12 L4,12 Z M4,19 L9,19 L9,13 L4,13 L4,19 L4,19 Z M10,19 L15,19 L15,13 L10,13 L10,19 L10,19 Z M16,19 L21,19 L21,13 L16,13 L16,19 L16,19 Z M10,12 L15,12 L15,6 L10,6 L10,12 L10,12 Z M16,6 L16,12 L21,12 L21,6 L16,6 L16,6 Z"
          fill={props.color?props.color:"#333840"}
        />
      </svg>
    </SvgIcon>
  );
};

export default Icon;

