import * as actions from "./actionTypes";
import servicenow from "./servicenow";
import * as IntegrationError from "./../../../components/settings/integrations/IntegrationError";

var msgHubRobotAddress = '';
var sdgtwRobotAddress = '';
var servicenowResponse ={
  "autoResolveIncidents": "",
  "severityOptions": [],
  "autoCloseAlarm": "",
  "autoCloseTicket": "",
  "active": "",
  "userName" : "",
  "userPassword":"",
  "userURL":"",
  "enabledGroupAssignment":"",
  "groupName": ""
};
export function fetchConfig() {
  return {
    type: actions.FETCH_SERVICENOW_CONFIG
  };
}

export function fetchConfigSuccess(json) {
  return {
    type: actions.FETCH_SERVICENOW_CONFIG_SUCCESS,
    config: json
  };
}

export function fetchConfigFailure(error) {
  return {
    type: actions.FETCH_SERVICENOW_CONFIG_FAILURE,
    responseError: error
  };
}

export function saveConfig() {
  return {
    type: actions.SAVE_SERVICENOW_CONFIG
  };
}

export function saveConfigSuccess() {
  return {
    type: actions.SAVE_SERVICENOW_CONFIG_SUCCESS
  };
}

export function saveConfigFailure(error) {
  return {
    type: actions.SAVE_SERVICENOW_CONFIG_FAILURE,
    responseError: error
  };
}

export function deleteConfig() {
  return {
    type: actions.DELETE_SERVICENOW_CONFIG
  };
}

export function deleteConfigSuccess(json) {
  return {
    type: actions.DELETE_SERVICENOW_CONFIG_SUCCESS
  };
}

export function deleteConfigFailure(error) {
  return {
    type: actions.DELETE_SERVICENOW_CONFIG_FAILURE,
    responseError: error
  };
}

function getResponseError(error) {
  if (error.response.data && error.response.data.message) {
    return error.response.data;
  } else {
    return IntegrationError.getDefaultError();
  }
}


export function get() {
  return dispatch => {
    dispatch(fetchConfig());
  return servicenow.get().then(response => {
      for(var i=0; i< response.data.length; i++){
        var keyName = response.data[i].key;
        switch(keyName) {
          case "incident_on_severity_change":
          servicenowResponse.autoResolveIncidents = response.data[i].value === 'true' ? true : false;
          break;
          case "auto_close_ticket":
          servicenowResponse.autoCloseTicket = response.data[i].value === 'true' ? true : false;
          break;
          case "enabled_tkts_for_alarm_severity":
          if((response.data[i].value) !== "" || (response.data[i].value) !== null){
            servicenowResponse.severityOptions = (response.data[i].value).replace("information", "1").replace("warning", "2").replace("minor", "3").replace("major", "4").replace("critical", "5").split(",");
            servicenowResponse.severityOptions= servicenowResponse.severityOptions.map(function (x) {
                return parseInt(x,10);
            });
          }else{
            servicenowResponse.severityOptions =[]
          }
          break;
          case "auto_close_alarm":
          servicenowResponse.autoCloseAlarm = response.data[i].value === 'true' ? true : false;
          break;
          case "active":
          servicenowResponse.active = response.data[i].value === 'true' ? true : false;
          break;
          case "URL":
          servicenowResponse.userURL = response.data[i].value;
          break;
          case "Username":
          servicenowResponse.userName = response.data[i].value;
          break;
          case "Password":
          servicenowResponse.userPassword = response.data[i].value;
          break;
          case "enable_group_assignment":
          servicenowResponse.enabledGroupAssignment = response.data[i].value === 'true' ? true : false;
          break;
          case "default_group_name":
          servicenowResponse.groupName = response.data[i].value;
          break;
        }
      }
    dispatch(fetchConfigSuccess(servicenowResponse));
    }).catch(function(error) {
      dispatch(fetchConfigFailure(getResponseError(error)));
    });
};
}

export function save(serviceNowConfig, successCallback) {
  var severityOptions ='';
  if(serviceNowConfig.severityOptions.length > 0){
    severityOptions = serviceNowConfig.severityOptions.join().replace("1", "information").replace("2", "warning").replace("3", "minor").replace("4", "major").replace("5", "critical")
  }
  var serviceNowConfigObject = [
    {
      key: "incident_on_severity_change",
      value: (serviceNowConfig.autoResolveIncidents).toString()
    },
    {
      key: "is_SaaS_enabled",
      value: "true"
    },
    {
      key: "enabled_tkts_for_alarm_severity",
      value: severityOptions
      },
    {
      key: "auto_close_alarm",
      value: (serviceNowConfig.autoCloseAlarm).toString()
    },
    {
      key: "auto_close_ticket",
      value: (serviceNowConfig.autoCloseTicket).toString()
    },
    {
      key: "active",
      value: (serviceNowConfig.active).toString()
    },
    {
      key: "Username",
      value: serviceNowConfig.userName
    },
    {
      key: "Password",
      value: serviceNowConfig.userPassword
    },
    {
      key: "URL",
      value: serviceNowConfig.userURL
    },
    {
      key: "ServiceNowClientURL",
      value: serviceNowConfig.userURL
    },
    {
      key: "enable_group_assignment",
      value: (serviceNowConfig.enabledGroupAssignment).toString()
    },
    {
      key: "default_group_name",
      value: serviceNowConfig.groupName
    }
  ];
  return dispatch => {
    dispatch(saveConfig());
    return servicenow.save(serviceNowConfigObject)
      .then(response => {
        dispatch(saveConfigSuccess());
        if (successCallback) {
          successCallback();
        }
      })
      .catch(function(error) {
        dispatch(saveConfigFailure(getResponseError(error)));
      });
  };
}

export function deleteServiceNowConfig(
  serviceNowConfig,
  successCallback,
  failureCallback
) {

  var serviceNowConfigObject = [
    {
      key: "incident_on_severity_change",
      value: "false"
    },
    {
      key: "is_SaaS_enabled",
      value: "true"
    },
    {
      key: "enabled_tkts_for_alarm_severity",
      value: ''
    },
    {
      key: "auto_close_alarm",
      value: "false"
    },
    {
      key: "auto_close_ticket",
      value: "false"
    },
    {
      key: "active",
      value: "false"
    },
    {
      key: "Username",
      value: ''
    },
    {
      key: "Password",
      value: ''
    },
    {
      key: "URL",
      value: ''
    },
    {
      key: "ServiceNowClientURL",
      value: ''
    },
    {
      key: "enable_group_assignment",
      value: "false"
    },
    {
      key: "default_group_name",
      value: ''
    }
  ];
  return dispatch => {
    dispatch(deleteConfig());
    return servicenow
      .deleteConfig(serviceNowConfigObject, msgHubRobotAddress, sdgtwRobotAddress)
      .then(response => {
        dispatch(deleteConfigSuccess());
        if (successCallback) {
          successCallback();
        }
      })
      .catch(function(error) {
        dispatch(deleteConfigFailure(getResponseError(error)));
        if (failureCallback) {
          failureCallback();
        }
      });
  };
}
