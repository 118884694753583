import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { IFrame } from '../ui-components/uim-components'
import {CenterError as Error} from '../ui-components/uim-components'
import {CircularProgress} from '@mineral/core'


import './crossLaunch.less'

class CrossLaunch extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ready: true,
      error: false,
    }
  }

  render() {
    let view = <IFrame {...this.props}/>
    if(!this.state.ready) {
      view = <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><CircularProgress style={{color: '#3272D9'}} /></div>;
    }
    if (this.state.error) {
      view = <Error/>
    }
    let cssClass = 'crosslaunch'
    if (this.props.isSetupWizard) {
      cssClass = 'crosslaunch__setup-wizard'
    if (this.props.probeType !== "cloud") {
          cssClass = 'crosslaunch__setup-wizard-remote'
      }
    }else if(this.props.src.search("mcs-ui-app") > -1){
      cssClass = 'crosslaunch__mcsuiGroup'
    }
    return (
      <div className={cssClass}>
        {view}
      </div>
    )
  }

 
}

CrossLaunch.propTypes = {
  src: PropTypes.string.isRequired,
  sid: PropTypes.string.isRequired,
  isSetupWizard: PropTypes.bool,
  probe:  PropTypes.string
};

export default CrossLaunch
