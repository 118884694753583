import {
  ADD_BREADCRUMB,
  SELECT_BREADCRUMB,
  SET_BREADCRUMBS,
  SET_HOME_BREADCRUMB,
  POP_LAST_BREADCRUMB,
} from './actionTypes'

export function addBreadCrumb(item) {
  return {
    type: ADD_BREADCRUMB,
    item: item,
  }
}

export function popBreadCrumb() {
  return {
    type: POP_LAST_BREADCRUMB,
  }
}

export function selectBreadCrumb(item) {
  return {
    type: SELECT_BREADCRUMB,
    item: item,
  }
}

export function setBreadCrumbs(items) {
  return {
    type: SET_BREADCRUMBS,
    items: items,
  }
}

export function setHomeBreadCrumb(item) {
  return {
    type: SET_HOME_BREADCRUMB,
    item: item,
  }
}