// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Administration
  content["Administration"] =
  "管理";

  //Configure automatic groups
  content["Configure automatic groups"] =
  "自動グループの設定";

  //policies
  content["policies"] =
  "ポリシー";

  // policy
  content["policy"] =
  "ポリシー";

  //Existing policies
  content["Existing policies"] =
  "既存のポリシー";

  //Alarm Policies
  content["Alarm Policies"] =
  "アラーム ポリシー";

  //Manage when alarms are created
  content["Manage when alarms are created"] =
  "アラームが作成されるタイミングを管理します";

  //Settings
  content["Settings"] =
  "設定";

  // END OF LOCALIZATION

export default content;
