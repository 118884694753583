import React, { Component } from "react";
import isEqual from "lodash/isEqual";
import pick from "lodash/pick";
import values from "lodash/values";

import { Paginate } from "../../ui-components/uim-components";
import TimeserieChart from "./charts/TimeserieChart";
import Columns from "./columns/Columns";
import { connect } from "react-redux";

class Metrics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 0,
    };
  }

  getNumberOfPages() {
    const { metricTypes, pageLimit } = this.props;
    return Math.ceil(metricTypes.length / pageLimit);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !isEqual(this.state, nextState) || !isEqual(this.props, nextProps);
  }

  handlePageChange(page) {
    this.setState({
      currentPage: page.selected,
    });
  }

  getPaginatedItems() {
    const { metricTypes, pageLimit } = this.props;
    const { currentPage } = this.state;

    let startIndex = currentPage * pageLimit;
    const paginatedMetricTypes = metricTypes.slice(
      startIndex,
      startIndex + pageLimit
    );
    return paginatedMetricTypes;
  }

  render() {
    const { currentPage } = this.state;

    const { columns } = this.props;

    const numberOfPages = this.getNumberOfPages();

    const containerWidth = 800;

    const width = Math.floor(containerWidth / columns);

    const charts = this.getPaginatedItems().map((metricType, index) => {
      return (
        <ConnectedTS
          width={width}
          name={metricType.name}
          unit={metricType.unitShort}
          metrics={metricType.metrics}
          key={metricType.metricType}
        />
      );
    });

    const paginate =
      numberOfPages > 1 ? (
        <Paginate
          pageCount={numberOfPages}
          forcePage={currentPage}
          onPageChange={(page) => this.handlePageChange(page)}
        />
      ) : null;

    return (
      <div className="metrics">
        <div className="charts">
          <Columns n={columns}>{charts}</Columns>
        </div>
        {paginate}
      </div>
    );
  }
}

Metrics.defaultProps = {
  pageLimit: 20,
};


const ConnectedTS = connect((state, props) => {
  const metrics = values(pick(state.types.items, props.metrics))
    .filter(({ selected }) => selected)
    .map(({ id }) => id);

  let selectedMetric;
  let keys = [];
  let samples = [];
  let minArr = [],
    maxArr = [],
    avgArr = [];

  metrics.forEach((metricId) => {
    selectedMetric = metricId;
    const metric = state.metrics.items[metricId] || {};
    keys.push(metric.ciName || state.types.items[metricId].ciName);
    if (metric.samples) {
      minArr.push(metric.min);
      maxArr.push(metric.max);
      avgArr.push(metric.avg);
      for (var index = 0; index < metric.samples.length; index++) {
        if (!samples[metric.samples[index].epoch]) {
          samples[metric.samples[index].epoch] = {};
          samples[metric.samples[index].epoch].epoch =
            metric.samples[index].epoch;
        }
        samples[metric.samples[index].epoch][metric.ciName] =
          metric.samples[index].value;
      }
    }
  });

  var average = (arr) => arr.reduce((p, c) => p + c, 0) / arr.length;
  let minVal = minArr.length ? Math.min(...minArr) : 0;
  let maxVal = minArr.length ? Math.max(...maxArr) : 0;
  let avgVal = minArr.length ? average(avgArr) : 0;

  let yDomain = null;
  // arbitrary numbers
  if (minVal > 30000000) {
    yDomain = [minVal * 0.8, maxVal * 1.2];
  }

  return {
    name: props.name,
    unit: props.unit,
    width: props.width,
    values: keys,
    data: Object.values(samples),
    minVal,
    maxVal,
    avgVal,
    yDomain,
    ...state.metrics.items[selectedMetric],
    selectedMetricOrder: state.types.selectedMetricsGroup,
    selectedArrayChanged: state.types.selectedMetricsArrayChanged,
    timerange: state.timerange.hours,
    timerangeEndDate: state.timerange.endDate,
  };
})(TimeserieChart);

export default Metrics;
