import React from 'react'
import { SvgIcon } from '../ui-components/uim-components'

const Icon = props => {
  return (
    <SvgIcon {...props}>
      <svg 
        alt={props.title}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24">
        <g fill="#CCCCCC" >
        <role>{"figure"}</role>
        <title>{props.title}</title>
       <path fillRule="evenodd" d="M20.001,11.984 L20.001,3.968 L14.985,3.968 L14.985,11.984 L17.469,10.484 L20.001,11.984 Z M20.001,2 C20.5323333,2 20.9933333,2.19533333 21.384,2.586 C21.7746667,2.97666667 21.97,3.43766667 21.97,3.969 L21.97,15.969 C21.97,16.5003333 21.7746667,16.969 21.384,17.375 C20.9933333,17.781 20.5323333,17.984 20.001,17.984 L8.001,17.984 C7.46966667,17.984 7.001,17.781 6.595,17.375 C6.189,16.969 5.986,16.5003333 5.986,15.969 L5.986,3.969 C5.986,3.43766667 6.189,2.97666667 6.595,2.586 C7.001,2.19533333 7.46966667,2 8.001,2 L20.001,2 L20.001,2 Z M3.969,5.984 L3.969,20 L17.985,20 L17.985,21.969 L3.969,21.969 C3.43766667,21.969 2.97666667,21.7736667 2.586,21.383 C2.19533333,20.9923333 2,20.5313333 2,20 L2,5.984 L3.969,5.984 L3.969,5.984 Z"/>
</g>
      </svg>
    </SvgIcon>
  )
}

export default Icon
