import axios from 'axios';
import {CircularProgress} from '@mineral/core'
import Resource from './Resource'
import React,{Component} from 'react';
import ErrorDialog from './../titlebar/ErrorDialog'
import computerSystems from './../../api/computer-systems/computerSystems'
import {Snackbar,Dialog,DialogTitle, DialogActions, DialogContent, TextField, Box, FormLabel, InputAdornment,Tooltip} from '@mineral/core'
import { Cross as CloseIcon } from '@mineral/icons'
import { IconButton } from '@mineral/core';
import "./DeleteDevicesInventoryDialog.less"
import { Upload as UploadIcon } from '@mineral/icons';
import { Button, Typography } from '@mineral/core';

class DeviceImportDialog extends Component {

    constructor(props){
        super(props)
        this.state = {
            // Initially, no file is selected
            isError:false,
            selectedFile: null,
            disableUploadButton:true,
            showSpinner:false,
            isUpload:props.open,
            fileImportMsg:'',
            isSuccess:false,
            isEmpty:false
        };
        this.buttonUploadRef=React.createRef();
    }

    componentWillReceiveProps(nextProps) {   
      if (nextProps.open !== this.state.isUpload) {
        this.setState({isUpload: nextProps.open})
      }     
        
        if(this.state.isUpload === false){
        this.setState({isSuccess:false, selectedFile:null});
    }
    }
    // On file select (from the pop up)
    onFileChange = event => {

        // Update the state
        this.setState({ selectedFile: event.target.files[0] ,disableUploadButton:false});
    };

    // On file upload (click the upload button)
    onFileUpload = () => {

        // Create an object of formData
        const formData = new FormData();
        if(this.state.selectedFile === null){
            this.setState({isEmpty: true})
        }

        // Update the formData object
        formData.append(
            "importFile",
            this.state.selectedFile,
            this.state.selectedFile.name
            );

            // Request made to the backend api
            // Send formData object
            this.setState({
                showSpinner:true,
                selectedFile:null,
                isEmpty:false,
                disableUploadButton:true
            })
            computerSystems.discoveryImport(formData).then(response=>{
                const importMsg = response.data.Result;
                this.setState({
                    showSpinner:false,
                    fileImportMsg:importMsg,
                    isSuccess:true,
                    isEmpty:false,
                })                
                this.onClose()
            }).catch(error=>{
                this.setState({
                    showSpinner:false,
                    isError:true,
                    isUpload:false,
                    isSuccess:false
                })
            })

        };

        // File content to be displayed after
        // file upload is complete
        fileData = () => {            
            if (this.state.selectedFile) {
                //File Details:
                const  fileDetailsText=Resource.get("File Details:")
                //File Name
                const  fileNameText=Resource.get("File Name:")
                //File Type:
                const fileTypeText=Resource.get("File Type:")
                return (
                    <div>
                    <Typography style={{color: '#58606E', fontWeight: '600'}}>{fileNameText}</Typography>
                    <p>{this.state.selectedFile.name}</p>
                    </div>
                    );
                } else {
                    //File upload text
                    const  fileUploadText=Resource.get("Select file before Pressing the Upload button")
                    return (
                        <div style={{textAlign:"center"}}>
                        <p style={{margin: 0}}></p>
                        </div>
                        );
                    }
                };

                render() {
                    const actions = [
                        <Button disableRipple={true}
                            variant="text"
                            //Cancel
                            children= {Resource.get("Cancel")}
                            color="primary"
                            onClick={this.onClose.bind(this)}
                        />,

                        <Button disableRipple={true}
                         //Import
                            children= {Resource.get("Import")}
                            variant="contained"
                            color="primary"
                            disabled={this.state.disableUploadButton}
                            onClick={this.onFileUpload}
                        />
                    ]
                    let title = Resource.get('Import Device');//device import
                    // Upload an XML file
                    const uploadXMLFileText = Resource.get("Upload an XML file");
                    // Re-upload an XML file
                    const reUploadXMLFileText = Resource.get("Re-upload an XML file")
                    // From your computer
                    const fromComputerText = Resource.get("from your computer")
                    // Upload file
                    const uploadFile = Resource.get("Upload File")
                    return (
                       
                      <div>
                        <ErrorDialog
                          open={this.state.isError}
                          handleClose={this.onClose.bind(this)}
                          errorType={"import"}
                        />
                        <Snackbar
                          open={this.state.isSuccess}
                          message={this.state.fileImportMsg}
                          autoHideDuration={4000}
                          onClose={this.onClose.bind(this)}
                        />
                        <Snackbar
                          open={this.state.isEmpty}
                          message={this.state.fileUploadText}
                          autoHideDuration={4000}
                          onClose={() => this.setState({isEmpty:false})}
                        />
                        
                        <Dialog
                            aria-labelledby="import-dialog-title" 
                            aria-label={title}                          
                            role='dialog'
                            modal={false}
                            open={this.state.isUpload}
                            onClose={this.onClose.bind(this)}
                        >
                            <DialogTitle component="div" disableTypography>  
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div style={{ display:'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <UploadIcon />
                                        <Typography component='h1' variant="h3">
                                            {title}
                                        </Typography>
                                    </div>
                                </div>
                               
                            </DialogTitle> 
                            <div style={{height:'0px'}}>
                            <Tooltip title="Close">
                            <IconButton style={{float:'right', marginTop: '-35px',marginRight:'12px'}}
                                    aria-label="close"
                                   
                                    onClick={this.onClose.bind(this)}
                                    autoFocus
                                    >
                                    <CloseIcon />

                                </IconButton>
                                </Tooltip>
                                </div>
                                <DialogContent>
                                <div style={{color: 'black', margin: '5px'}}>
                                    <div style={{width: '450px'}}>
                                        <Button variant="text"
                                        onClick={()=>{this.buttonUploadRef?.current?.click()}}
                                           
                                            aria-label={this.state.selectedFile === null ? uploadXMLFileText : reUploadXMLFileText}
                                            >
                                            {this.state.selectedFile === null ? uploadXMLFileText : reUploadXMLFileText}
                                            <input ref={this.buttonUploadRef}
                                            type="file"
                                            hidden
                                            onChange={this.onFileChange}
                                            />
                                        </Button>
                                        {fromComputerText}
                            {this.fileData()}
                            {this.state.showSpinner ?
                                        <div className='circular-progress' style={{overflow:"hidden"}}><CircularProgress style={{color: '#3272D9'}} key="deviceImportDialog_Spinner" /></div>
                                            : null}
                                        </div>
                            </div>
                                </DialogContent>
                            <DialogActions >
                                {actions}
                            </DialogActions>
                        </Dialog>
                      </div>
                        );
                    }

                    onClose(dialogType) {                        
                        if(dialogType && dialogType==="errorDialog"){                           
                          this.setState({
                              isError:false,
                              selectedFile:null,                              
                          });
                        }else if(dialogType && dialogType==="clickaway"){                           
                            this.setState({
                                selectedFile:null,
                                //fileImportMsg:'',
                           //    isSuccess:false
                            });                           
                        }
                        this.props.handleClose()
                    }
                }

                export default DeviceImportDialog;
