// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Select what you would like to monitor...
  content["Select what you would like to monitor..."] =
  "ëª¨ë‹ˆí„°ë§� ëŒ€ìƒ� ì„ íƒ�...";

  //For more information, click
  content["For more information, click"] =
  "ìž�ì„¸í•œ ë‚´ìš©ì�„ ë³´ë ¤ë©´ ë‹¤ì�Œì�„ í�´ë¦­í•˜ì‹­ì‹œì˜¤.";

 

  //Setup Wizard
  content["Setup Wizard"] =
  "ì„¤ì¹˜ ë§ˆë²•ì‚¬";

  //HELP
  content["HELP"] =
  "ë�„ì›€ë§�";

  //Help
  content["Help"] =
  "ë�„ì›€ë§�";

  //ADD
  content["ADD"] =
  "ì¶”ê°€";

  //CONFIG
  content["CONFIG"] =
  "êµ¬ì„±";

  //Cancel
  content["Cancel"] =
  "ì·¨ì†Œ";

  //PREVIOUS
  content["PREVIOUS"] =
  "ì�´ì „";

  //next
  content["next"] =
  "ë‹¤ì�Œ";

  //FINISH
  content["FINISH"] =
  "ë§ˆì¹¨";

  // END OF LOCALIZATION

export default content;
