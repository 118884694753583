import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Popover,Tooltip } from "@mineral/core";
import { IconButton } from "@mineral/core";
import { SearchIcon as Search } from "../ui-components/uim-components";
import SearchField from "./../inventory/InventorySearch";
import "./titleBarAccount.less";
import Resource from "./Resource";

class InventorySearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSearch: false,
      anchorEl: null,
    };
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.location !== nextProps.location) {
      this.setState({
        showSearch: false,
      });
    }
  }
  // onKeyUp = (evt) => {
  //   //close filter when user presses esc key or enter key
  //   console.log(evt);
  //   if (evt.which === 27 || evt.which === 13) {
  //     this.handleRequestClose();
  //   }
  // };
  handleTouchTap = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({
      showSearch: true,
      anchorEl: event.currentTarget,
    });
  };
  handleRequestClose = () => {
    this.setState({
      showSearch: false,
      anchorEl: null,
    });
    ReactDOM.findDOMNode(this.searchButton).focus();
    this.props.setQuickFilter();
  };
  render() {
    //Search For Computer Systems
    const helplabelmessage = Resource.get("Search For Computer Systems");
    return (
      <span>
      <Tooltip title={helplabelmessage}>
        <IconButton
          id="search-icon-main-button"
          className="search__icon"
          title={helplabelmessage}
          onClick={this.handleTouchTap}
          ref={(button) => (this.searchButton = button)}
        >
          <Search title={helplabelmessage} />
        </IconButton>
        </Tooltip>
        <Popover
          className="inventorySearch__popover"
          open={this.state.showSearch}
          anchorEl={this.state.anchorEl}
          onClose={this.handleRequestClose}
        >
          <SearchField screenReaderLabel={helplabelmessage}
            onKeyUp={this.onKeyUp}
            setSearchTerm={(value) => {
              this.props.setSearchTerm(value);
              this.handleRequestClose();
            }}
          />
        </Popover>
      </span>
    );
  }
}

export default InventorySearch;
