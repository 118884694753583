import React, { Component } from "react";
import { RefreshIcon, EditIcon } from "../ui-components/uim-components";
import moment from "moment";
import ShowFiltersButton from "./filter/ShowFiltersButton";
import theme from "../ui-components/theme";
import "./../groups/secondaryNav/secondaryNav.less";
import ControlsRedux from "./../groups/secondaryNav/controls/ControlsRedux";
import {
  inventoryfilterEntities,
  setQuickFilterEntities,
  setEntities,
} from "./../entities/actions";
import Resource from "./Resource";
import { Menu, MenuItem } from "@mineral/core";
import { IconButton ,Tooltip,Button} from "@mineral/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import get from "lodash/get";
import connect from "./../../utils/connect";
import {
  exportComputerSystem,
  searchComputerSystems,
} from "../../api/computer-systems/actions";
import { defaultFiltersActiveObjects } from "./filter/DefaultFilters";
import TableFilters from "./filter/TableFilters";
import "./filter/tableFilters.less";

import InventoryRobotsDeploymentStatusRedux from "./InventoryRobotsDeploymentStatusRedux";
import { isEqual } from "lodash";
//Refreshed
let refresh = Resource.get("Refreshed");
//Results
let results = Resource.get("Results");
const Results = (props) => {
  return (
    <div style={props.style}>
      <label
        style={{
          fontSize: "0.75rem",
          marginRight: "0.5rem",
          display: "none",
          color: theme.palette.hintTextColor,
        }}
      >
        {results}
      </label>
      <span style={{ fontSize: "1.25rem" }}>{props.visibleDataCount}</span>
    </div>
  );
};
Results.defaultProps = {
  data: "",
};

const RefreshedDate = (props) => {
  return (
    <div
      style={{
        ...props.style,
       
        display: "flex",
        flexDirection:'column',
        alignItems: "center",
        
  justifyContent: "center"
      }}
    >
      {/* <label
        style={{
          fontSize: "14px",
          marginRight: "0.5rem",
          display: "none",
          color: theme.palette.hintTextColor,
        }}
      >
        {refresh}
      </label> */}
      <span
        style={{
          fontSize: "14px",
          fontStyle: "normal",
          textAlign: "center",
          color: "#58606E",
        }}
      >
        Last Refreshed 
      </span>
      <span
        style={{
          fontSize: "14px",
          fontStyle: "italic",
          fontWeight: "600",
          textAlign: "center",
          color: "#58606E",
        }}
      >
        {props.refreshDate}
      </span>
    </div>
  );
};
RefreshedDate.defaultProps = {
  refreshDate: moment().fromNow(),
};

class RefreshContainer extends Component {
  state = {
    interval: this.props.interval || 1000,
    originalRefreshDate: Date.now(),
    refreshDate: moment().fromNow(),
  };

  componentWillMount() {
    this.tm = setInterval(() => {
      this.setState({
        refreshDate: moment(this.state.originalRefreshDate).fromNow(),
      });
    }, this.state.interval);
  }

  componentWillUnmount() {
    this.props.setSearchTerm("");
    clearInterval(this.tm);
  } 

  render() {
    return (
      <div style={this.props.style} >
        <Button fullWidth variant="outlined" style={{width:'100px',backgroundColor:'#FFFFFF',marginRight:'8px',height:'32px'}} size="small"
         // className="inventory__nav-button"
          onClick={() => {
            try {
              const path = `/inventory`;
              this.setState({
                originalRefreshDate: Date.now(),
              });
              this.props.history.push(path);
              // this.props.setSearchTerm('')
              const searchTerm = this.props.searchTerm;
              setTimeout(() => {
                if (searchTerm) this.props.setSearchTerm(searchTerm);
              }, 100);
              this.props.setQuickFilter();
              this.props.handleInventoryTreeUpdate()
            } catch (e) {}
          }}
        >
          Refresh
        </Button>
        <RefreshedDate refreshDate={this.state.refreshDate} />
      </div>
    );
  }
}

class InventoryNav extends Component {
  constructor(props) {
    super(props);
    this.featureFlags = props.featureFlags || {};
    //Import
    this.deviceImportText = Resource.get("Import");
    //Discovery Wizard
    this.discoveryWizardText = Resource.get("Discovery Wizard");
    //Robot Deployment
    this.robotDeploymentText = Resource.get("Robot Deployment");
    //Delete Devices
    this.deleteSelected = Resource.get("Delete Devices");

    this.state = {
      isCheckboxSelected: props.isCheckboxSelected,
      menuAnchorEl: null,
      isRobotDeployment: false,
    };
    this.currentHeight = 0;
    this.navElement = React.createRef();
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      isCheckboxSelected: nextProps.isCheckboxSelected,
      isRobotDeployment: nextProps.isRobotDeployment,
    });
    if (
      !isEqual(this.props.savedFilters, nextProps.savedFilters) ||
      this.props.quickFilter !== nextProps.quickFilter
    ) {
      this.props.setColumnFilter(nextProps.savedFilters);
    }
  }

  componentWillUnmount() {
    this.props.clearEntities();
  }

  deploymentStatus = (adeJobIds) => {
    if (
      this.props.robots.is_post_deployment_done ||
      (this.props.robotsStatus && this.props.robotsStatus.length > 0)
    ) {
      return (
        <InventoryRobotsDeploymentStatusRedux
          open={this.props.showRobotDeployStatusButton}
          adeRobotAddress={""}
          adeJobIds={adeJobIds}
          callbackEnabled={false}
          probe={""}
          fromSetupWizard={false}
        />
      );
    } else return null;
  };

  handleActionMenuOpen = (event) => {
    this.setState({ menuAnchorEl: event.currentTarget });
  };

  handleActionMenuClose = (event) => {
    this.setState({ menuAnchorEl: null });
  };

  handleUpdateFilters = (filters) => {
    // this.props.setInventorySearchData(filters)
    if (filters.colFilters) {
      const readyColumnFilter = [];
      filters.colFilters.forEach((colFilter) => {
        // let fieldName = this.fieldCoversion(colFilter.column);
        let fieldName = colFilter.column;
        let operator = this.operatorConversion(colFilter.operator, true);
        let isNegated =
          operator.toUpperCase() === "NOTEQUALS" ||
          operator.toUpperCase() === "NOTCONTAINS"
            ? true
            : false;
        let operator_final =
          operator.toUpperCase() === "NOTEQUALS"
            ? "EQUALS"
            : operator.toUpperCase() === "NOTCONTAINS"
            ? "CONTAINS"
            : operator.toUpperCase();
        readyColumnFilter.push({
          column: fieldName,
          operator: operator_final.toUpperCase(),
          value: colFilter.value,
          negated: isNegated,
        });
      });
      // this.setState({ columnFilter: columnFilter });
      // this.props.setColumnFilters(
      //   columnFilter,
      //   false,
      //   this.props.isFromOperatorConsole
      // );
      // }));
      // this.props.setInventorySearchData(filters);
      // if (!isEqual(readyColumnFilter, this.props.savedFilters)) {
      //   this.props.handleSaveSelectedColumns(null, readyColumnFilter);
      // }
      this.props.setInventorySearchData(filters);

      this.props.handleSaveSelectedColumns(null, readyColumnFilter);
      // this.props.updateColumnFilters(columnFilter, true);
      // setTimeout(() => {
      //   this.props.setRefreshAlarmGrid(true);
      // }, 300);
      //setSelectedActiveQosObjTable([])
      //setFiltersServerSide(filters)
    }
  };

  fieldCoversion(field) {
    let fieldMap = new Map();
    fieldMap.set("Device Name", "hostname");
    fieldMap.set("Creation time & date", "timeorigin");
    fieldMap.set("Arrival Date", "arrival");
    fieldMap.set("Acknowledged At", "acknowledgedat");
    fieldMap.set("Assigned At", "assignedat");
    fieldMap.set("Alarm Type", "subsystem");
    fieldMap.set("Alarm ID", "alarmid");
    fieldMap.set("Alarm Message", "message");
    fieldMap.set("Assigned To", "assignedto");
    fieldMap.set("Device Type", "deviceAttributes.primaryrole");
    fieldMap.set("Duration", "timeorigin");
    fieldMap.set("Subsystem ID", "sid");
    fieldMap.set("Visibility", "visible");
    fieldMap.set("Suppression Key", "supp_key");
    fieldMap.set("Acknowledged At", "acknowledgeAt");
    fieldMap.set("Acknowledged By", "acknowledgeBy");
    fieldMap.set("Owner", "assignedTo");
    fieldMap.set("Custom 1", "custom1");
    fieldMap.set("Custom 2", "custom2");
    fieldMap.set("Custom 3", "custom3");
    fieldMap.set("Custom 4", "custom4");
    fieldMap.set("Custom 5", "custom5");
    //fieldMap.set("User Tag 1", " user_tag_1");
    //fieldMap.set("User Tag 2", "user_tag_2");
    fieldMap.set("User Tag 1", "userTag1");
    fieldMap.set("User Tag 2", "userTag2");
    fieldMap.set("Event Count", "count");
    fieldMap.set("Last Occurrence", "lastOccurenceTime");
    fieldMap.set("Dedicated", "deviceAttributes.dedicated");
    let updateField = fieldMap.has(field) ? fieldMap.get(field) : field;
    return updateField;
  }

  operatorConversion(operator, feToBe) {
    let operatorMap = new Map();
    let updatedOp = operator;
    if (feToBe) {
      operatorMap.set("greaterThan", "GT");
      operatorMap.set("greaterThanOrEqual", "GTE");
      operatorMap.set("lessThan", "LT");
      operatorMap.set("lessThanOrEqual", "LTE");
    } else {
      operatorMap.set("GT", "greaterThan");
      operatorMap.set("GTE", "greaterThanOrEqual");
      operatorMap.set("LT", "lessThan");
      operatorMap.set("LTE", "lessThanOrEqual");
      operatorMap.set("EQUALS", "equals");
      operatorMap.set("CONTAINS", "contains");
      operatorMap.set("STARTSWITH", "startsWith");
      operatorMap.set("ENDSWITH", "endsWith");
    }
    if (operatorMap.has(operator)) {
      updatedOp = operatorMap.get(operator);
    }
    return updatedOp;
  }

  updateHeight = () => {
    const newHeight = this.navElement.current.getBoundingClientRect().height;
    if (newHeight !== this.currentHeight) {
      this.currentHeight = newHeight;
      this.props.updateHeight(newHeight);
    }
  };

  render() {
    let adeJobIds = [];
    if (
      this.props.robots.is_post_deployment_done &&
      this.props.robots.itemsDeployed
    ) {
      this.props.robots.itemsDeployed.map((item) => {
        if (
          adeJobIds.length <= 0 ||
          (adeJobIds.length > 0 && !adeJobIds.includes(item.adeJobId))
        ) {
          adeJobIds.push(item.adeJobId);
        }
      });
    }
    let deploymentStatus = this.deploymentStatus(adeJobIds);
    let inventoryHintText = Resource.get("Filter (Minimum 3 characters)"); //Filter (Minimum 3 characters)
    return (
      <nav
        className="inventory__nav--secondary"
        style={{
          justifyContent: "initial",
          display: "inline-flex",
          width: "100%",
          backgroundColor: "#FFFFFF",
        }} //, marginBottom: 10 }}
        ref={this.navElement}
      >
        {this.featureFlags.inventoryFilter ? <ShowFiltersButton /> : null}
        {/* <ControlsRedux showSort={false} filterHint={inventoryHintText} />
        <Results
          visibleDataCount={this.props.visibleDataCount}
          style={{ marginLeft: "1rem" }}
        /> */}
        {/* defaultFilters={defaultFiltersActiveObjects(this.props.savedColumns)} */}
        <TableFilters
          defaultFilters={defaultFiltersActiveObjects(
            this.props.savedColumns,
            this.props.monitoringProbes
          )}
          handleUpdateFilters={this.handleUpdateFilters}
          searchResultsNumber={this.props.visibleDataCount}
          currentFilters={this.props.savedFilters}
          currentQuickFilter={this.props.quickFilter}
          updateHeight={this.updateHeight}
        />
        {this.props.acl && this.props.acl.isBusUser ? (
          <Button fullWidth variant="outlined" style={{maxWidth:'fit-content',backgroundColor:'#FFFFFF',marginRight:'8px',height:'32px'}} size="small" 
            //className="inventory__nav-button"
            onClick={this.props.toggleDrawer}
          >
            Manage Discovery
          </Button>
        ) : null}
        {deploymentStatus}
        <RefreshContainer
          history={this.props.history}
          style={{
            marginRight: "10px",
            display: "flex",
            minWidth: "max-content",
            height:'32px'
          }}
          setSearchTerm={this.props.setSearchTerm}
          searchTerm={this.props.quickFilter}
          setQuickFilter={this.props.setQuickFilter}
          handleInventoryTreeUpdate={this.props.handleInventoryTreeUpdate}
        />
        <div
          style={{
            marginRight: "16px",
            // marginBlock: "auto",
            // marginBottom: "2px",
            marginTop: "6px",
            height:'32px'
          }}
        ><Tooltip title="More actions">
          
          <IconButton
            aria-controls="menuAction"
            aria-haspopup="true"
            onClick={this.handleActionMenuOpen}
          
            aria-label="More Actions"
          >
            <MoreVertIcon style={{ color: "black" }} />
          </IconButton>
          </Tooltip>
        </div>
        <Menu
          id="menuAction"
          anchorEl={this.state.menuAnchorEl}
          keepMounted
          onClose={this.handleActionMenuClose}
          open={this.state.menuAnchorEl != null}
          PaperProps={{
            style: {
              width: "200px",
              padding: "16px",
              display: "table-cell",
            },
          }}
        >
          <MenuItem
            id={"export"}
            children={
              //export
              Resource.get("Export")
            }
            disabled={false}
            onClick={(event) => {
              this.handleActionMenuClose();
              this.props.onGlobalActionMenu(
                event,
                this.props.exportComputerSystem()
              );
            }}
          />
          <MenuItem
            id={"editcolumn"}
            children={
              //editcol
              Resource.get("Edit Columns")
            }
            disabled={false}
            onClick={(event) => {
              this.handleActionMenuClose();
              this.props.onGlobalActionMenu(event);
            }}
          />

          {this.props.acl &&
            this.props.acl.isBusUser &&
            this.props.acl.canManageDiscovery && (
              <MenuItem
                id={"devImport"}
                children={`${this.deviceImportText}`}
                disabled={false}
                onClick={(event) => {
                  this.handleActionMenuClose();
                  this.props.onGlobalActionMenu(event);
                }}
              />
            )}
          {/*    {this.props.acl &&
            this.props.acl.isBusUser && this.props.acl.canManageDiscovery
             && (
            <MenuItem
              id={"dwizard"}
              children={`${this.discoveryWizardText}`}
              disabled={false}
              onClick= {(event)=>{                
                this.props.onGlobalActionMenu(event);
                this.handleActionMenuClose();
              }}
            />
            )} */}
          {this.props.acl &&
            this.props.acl.isBusUser &&
            this.props.acl.canDeployRobot && (
              <MenuItem
                id={"robotDeploy"}
                children={`${this.robotDeploymentText}`}
                disabled={
                  !this.state.isCheckboxSelected ||
                  !this.state.isRobotDeployment
                }
                onClick={(event) => {
                  this.handleActionMenuClose();
                  this.props.onGlobalActionMenu(event);
                }}
              />
            )}
          {this.props.acl && this.props.acl.isBusUser && (
            <MenuItem
              id={"deleteDevicesFromInventory"}
              children={`${this.deleteSelected}`}
              disabled={this.props.isDisabled || !this.state.isCheckboxSelected }
              onClick={(event) => {
                this.handleActionMenuClose();
                this.props.onGlobalActionMenu(event);
              }}
            />
          )}
        </Menu>
      </nav>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    featureFlags: state.configuration.items,
    acl: state.acl,
    robots: state.robots,
    robotsStatus: state.robotsStatus.status,
    visibleDataCount: state.entitiesFilterSortConfig.totalItems,
    quickFilter: state.inventoryEntityFilter.quickFilter,
    monitoringProbes: state.computerSystems.monitoringProbes,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setSearchTerm: (searchTerm) => dispatch(setQuickFilterEntities(searchTerm)),
    setInventorySearchData: (searchData) =>
      dispatch(inventoryfilterEntities(searchData)),
    exportComputerSystem: () => dispatch(exportComputerSystem()),
    setQuickFilter: () =>
      dispatch(searchComputerSystems(null, null, null, null)),
    setColumnFilter: (newFilterColumnFilter) =>
      dispatch(searchComputerSystems(null, null, newFilterColumnFilter, null)),
    clearEntities: () => dispatch(setEntities([])),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(InventoryNav);