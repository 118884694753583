import React from "react";
import Portlet from "../Portlet";
import ArrowIcon from "../../ArrowIcon";
import Resource from "./Resource";
import {Tooltip} from '@mineral/core';
export default class AccAdminCard extends Portlet {
  getIcon() {
    return <ArrowIcon />;
  }

  getSubLabelTwo = () => {
    return this.props.subLabelTwo;
  };
}

const TextLogo = ({ label }) => {
  return (
    <Tooltip title={label}>
      <span className="settings-text-logo admin__console--card">{label}</span>
    </Tooltip> 
  );
};

//Account Admin
const AccAdminLabel = Resource.get("Account Admin");
//Manage accounts using account admin
const Creating = Resource.get("Manage accounts using account admin");

AccAdminCard.defaultProps = {
  logo: <TextLogo label={AccAdminLabel} />,
  configRoute: "/settings/acc-admin",
  subLabelOne: Creating,
  subLabelTwo: "",
};
