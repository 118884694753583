// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Settings
  content["Settings"] =
  "Settings";

  //Ecometer Admin
  content["Ecometer Admin"] =
  "Ecometer Admin";

  //Manage accounts using account admin
  content["Manage Ecometer monitor using Ecometer Admin"] =
  "Manage Ecometer monitor using Ecometer Admin";

  // END OF LOCALIZATION

export default content;
