import {
    REQUEST_GROUPS,FETCH_GROUPS_DASHBOARD_SUCCESS, FETCH_GROUPS_DASHBOARD_FAILURE,
    REQUEST_GROUP_PROFILES,FETCH_GROUP_PROFILES_DASHBOARD_SUCCESS,FETCH_GROUP_PROFILES_DASHBOARD_FAILURE,
    REQUEST_GROUP_DEVICES,FETCH_GROUP_DEVICES_DASHBOARD_SUCCESS,FETCH_GROUP_DEVICES_DASHBOARD_FAILURE,
    REQUEST_DEVICE_BY_DEVICEID,FETCH_DEVICE_BY_DEVICEID_DASHBOARD_SUCCESS,FETCH_DEVICE_BY_DEVICEID_DASHBOARD_FAILURE   
} from './actionTypes'

  export function fetchGroups(options) {
      return {
          type: REQUEST_GROUPS,
          options:options
      }
  }
    export function fetchGroupsSuccess(json) {
        return {
            type: FETCH_GROUPS_DASHBOARD_SUCCESS,
            groupsDetails: json
        }
    }

    export function fetchGroupsFailure(error) {
        return {
            type: FETCH_GROUPS_DASHBOARD_FAILURE,
            errorMessage: error.message,
            options:error.options
        }
    }

    export function fetchGroupProfiles(options) {
        return {
            type: REQUEST_GROUP_PROFILES,
            options:options
        }
    }
export function fetchGroupProfilesFailure(error) {
    return {
        type: FETCH_GROUP_PROFILES_DASHBOARD_FAILURE,
        errorMessage: error.message,
        options:error.options
    }
}

    export function fetchGroupProfilesSuccess(json) {
        return {
            type: FETCH_GROUP_PROFILES_DASHBOARD_SUCCESS,
            profilesDetails: json
        }
    }
    
    export function fetchGroupDevices(options) {
        return {
            type: REQUEST_GROUP_DEVICES,
            options:options
        }
    }
export function fetchGroupDevicesFailure(error) {
    return {
        type: FETCH_GROUP_DEVICES_DASHBOARD_FAILURE,
        errorMessage: error.message,
        options:error.options
    }
}

    export function fetchGroupDevicesSuccess(json) {
        return {
            type: FETCH_GROUP_DEVICES_DASHBOARD_SUCCESS,
            devicesDetails: json
        }
    }

   

       export function fetchDeviceByDeviceId(deviceId,profileId) {
        return {
            type: REQUEST_DEVICE_BY_DEVICEID,
            deviceId : deviceId,
            profileId: profileId
        }
    }
    export function fetchDeviceByDeviceIdSuccess(json) {
        return {
            type: FETCH_DEVICE_BY_DEVICEID_DASHBOARD_SUCCESS,
            deviceDetails: json
        }
    }

    export function fetchDeviceByDeviceIdFailure(error) {
        return {
            type: FETCH_DEVICE_BY_DEVICEID_DASHBOARD_FAILURE,
            errorMessage: error.message
        }

    }


  
  
  