import React from 'react';

const SheduleIcon = ({className}) => {
    return (
        <svg width="16px" height="16px" className={className} role='figure' viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <title>schedule</title>
            <desc>Created with Sketch.</desc>
            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fillRule="evenodd">
                <g id="1-Run-And-Custom-Report" transform="translate(-502.000000, -252.000000)">
                    <g id="Group" transform="translate(327.000000, 63.000000)">
                        <g id="Group-6" transform="translate(85.000000, 188.000000)">
                            <g id="Group-4" transform="translate(89.000000, 0.000000)">
                                <g id="outline-schedule-24px">
                                    <polygon id="Shape" points="0 0 18 0 18 18 0 18"></polygon>
                                    <path d="M8.9925,1.5 C4.8525,1.5 1.5,4.86 1.5,9 C1.5,13.14 4.8525,16.5 8.9925,16.5 C13.14,16.5 16.5,13.14 16.5,9 C16.5,4.86 13.14,1.5 8.9925,1.5 Z M9,15 C5.685,15 3,12.315 3,9 C3,5.685 5.685,3 9,3 C12.315,3 15,5.685 15,9 C15,12.315 12.315,15 9,15 Z M9.375,5.25 L8.25,5.25 L8.25,9.75 L12.1875,12.1125 L12.75,11.19 L9.375,9.1875 L9.375,5.25 Z" id="Shape" fill="#333333" fillRule="nonzero"></path>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default SheduleIcon;