// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  // Card View
  content["Card View"] =
  "Vista de la tarjeta";

  // List View
  content["List View"] =
  "Vista de lista";

  // Tree View
  content["Tree View"] =
  "Vista de árbol";

  //Cards
  content["Cards"] =
  "Tarjetas";

  //List
  content["List"] =
  "Lista";

  //List
  content["Tree"] =
  "Árbol";

  //Detail
  content["Detail View"] =
  "Vista de detalles";

  //Alarms
  content["Alarms"] =
  "Alarmas";

  //Dashboards
  content["Dashboards"] =
  "Cuadros de mandos";

  //Maintenance
  content["Maintenance"] =
  "Mantenimiento";

  //Metrics
  content["Metrics"] =
  "Métricas";

  //Monitoring Config
  content["Monitoring Config"] =
  "Configuración de la monitorización";

  //Interfaces
  content["Interfaces"] =
  "Interfaces";

  // END OF LOCALIZATION

export default content;
