import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { CircularProgress, Tooltip } from "@mineral/core";


import { IconButton, Dialog, DialogTitle as MuiDialogTitle, DialogContent as MuiDialogContent, DialogActions as MuiDialogActions } from "@mineral/core";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { AddChartFilter } from "../../../../../../../api/performanceReports/actions";
import prd from "../../../../../../../api/performanceReports/prd";
import "./AdvancedFilterDialog.less";
import AdvancedFilterEditDialogContent from "./AdvancedFilterEditDialogContent";
import { List, ListItem, ListItemText } from "@mineral/core";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {},
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const AdvancedFilterDialog = (props) => {
  const [open, setOpen] = React.useState(false);
  const [name, setName] = React.useState("");
  const [nameError, setNameError] = React.useState(false);
  const [displayNameList, setDisplayNameList] = React.useState([]);
  const [clickVerifyFilter, setClickVerifyFilter] = React.useState(false);
  const [tooManyRecordsError, setTooManyRecordsError] = React.useState(false);
  const [disableSave, setDisableSave] = React.useState(true);
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [loading, setLoading] = React.useState(false)
  const dispatch = useDispatch();
  useEffect(() => {
    setName(props.filter.groupName);
  })
  const filterList = useSelector(
    (state) => state.performanceReports.chartData.State.groups.group
  );
  const inputItemGroup = props.filter.rowSelectors.rowSelector;
  const [inputItemGroupList, setInputItemGroupList] =
    React.useState(inputItemGroup);
  const handleClickOpen = () => {
    setOpen(true);
    setDisableSave(true);
  };
  const handlVerifyFilter = () => {
    (async () => {
      let filterId = props.filter.filterId;
      let requestBody = [];
      let apply = true;
      inputItemGroupList.forEach((input) => {
        let filter = {};
        filter.operator = input.logical;
        filter.negationIdentifier =
          input.not == "true" || input.not == true ? "not" : "";
        filter.field =
          input.field === "User Tag 1"
            ? "user_tag_1"
            : input.field === "User Tag 2"
              ? "user_tag_2"
              : input.field;
        filter.filterCondition = input.operator;
        filter.filterValue = input.value;
        requestBody.push(filter);
      });
      setLoading(true)
      let displayNameObj = await prd.filterPRDOutput(
        requestBody,
        filterId,
        apply
      );

      setLoading(false)
      let displayNameList = displayNameObj.data.filterTargetDto;
      let targetDtoSize =  displayNameObj.data.filterTargetDto.length;
      setClickVerifyFilter(true);
      setDisplayNameList(displayNameList);
      
      if(targetDtoSize>0){
	      if (!displayNameObj.data.maxRecords) {
	        setTooManyRecordsError(false);     
	        setDisableSave(false);
	        setTotalRecords(0);
	      } else {
	        setTooManyRecordsError(true);
	        setDisableSave(false);
	        setTotalRecords(displayNameObj.data.totalRecords);
	      }
      }
      else{
      	setTooManyRecordsError(false);     
	    setDisableSave(true);
	    setTotalRecords(0);
      }

    })();
  };
  const handleUpdateFilter = () => {
    let editGroupName = name !== "" ? name : props.filter.groupName;
    if (editGroupName.trim() === "") setNameError(true);
    else {
      //setNameError(false);

      let groupEditFilterObject = props.filter;
      groupEditFilterObject.groupName = editGroupName;

      inputItemGroupList.forEach((input, index) => {
        let filter = {};
        filter.logical = input.logical;
        filter.not = input.not;
        filter.field = input.field;
        //filter.number = input.number;
        filter.number = index + 1;
        filter.operator = input.operator;
        filter.value = input.value;

        let rowSelectors = groupEditFilterObject.rowSelectors;
        let tempRowSelectors = rowSelectors.rowSelector;
        let updateRowSelectors = rowSelectors.rowSelector.findIndex(
          (f) => f.number == filter.number
        );
        if (updateRowSelectors === -1) {
          rowSelectors.rowSelector.push(filter);
        } else {
          tempRowSelectors[updateRowSelectors] = filter;
        }
        rowSelectors = { rowSelector: tempRowSelectors };
      });
      handleClose();
      dispatch(AddChartFilter(groupEditFilterObject));
    }
  };
  const handleClose = () => {
    setOpen(false);
    setClickVerifyFilter(false);
    setName("");
    setNameError(true);
  };
  const handleFilterClearClick = () => {
    let emptyItemGroup = [
      {
        number: 1,
        logical: "and",
        field: "Source",
        not: false,
        operator: "contains",
        value: "",
      },
    ];
    setInputItemGroupList(emptyItemGroup);
    setClickVerifyFilter(false);
    setTooManyRecordsError(false);
    setDisableSave(true);
    setDisplayNameList([]);
  };

  const DialogTitleStyled = withStyles(styles)((props) => {
    const { children, classes, id, onClose, ...other } = props;
    return (
      <DialogTitle
        style={{ display: "flex", justifyContent: "space-between" }}
        disableTypography
        className={classes.root}
        {...other}
      >

        {children}


      </DialogTitle>
    );
  });

  const DialogContentStyled = withStyles((theme) => ({
    root: {},
  }))(DialogContent);

  const DialogActionsStyled = withStyles((theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(1),
    },
  }))(DialogActions);

  return (
    <div style={{ display: "inline-block" }}>
      <Tooltip arrow title="Edit">
        <span>
          <IconButton
            classes={{ root: "filterlist-icon" }}
            onClick={handleClickOpen}
          >
            <EditIcon className="filterlist-icons-color" />
          </IconButton>
        </span>
      </Tooltip>

      <Dialog
        fullWidth
        onClose={handleClose}
        aria-labelledby="Show records matching"
        open={open}
        maxWidth="md"
      >
        <DialogTitleStyled disableTypography component="div" style={{ minWidth: "10px" }}>
          <Typography id="Show records matching " component="h1" variant="h4" style={{ fontSize: "1.125rem", fontWeight: "700", minWidth: "10px" }}>
            Advanced Filter :: Edit
          </Typography>
        </DialogTitleStyled>
        <div style={{ height: '0px' }}>
          <Tooltip title="Close">
            <IconButton
              aria-label="close"
              style={{ float: 'right', top: '-50px', marginRight: '8px' }}
              onClick={handleClose}
              autoFocus
            >
              <CloseIcon color="red" fontSize="3" />
            </IconButton>
          </Tooltip>
        </div>
        <DialogContent>
          <>
            {tooManyRecordsError && <p style={{ color: "red", display: "flex", padding: "16px" }}>
             Only top 50 filtered qos series shown in below output will be saved.
            </p>}
             {loading && <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}><CircularProgress style={{color: '#3272D9'}}/></div>}
       
            <div style={{ display: "flex", padding: "6px", flexDirection: 'column' }}>
              <div style={{ paddingTop: "5px" }}>
                <AdvancedFilterEditDialogContent
                  name={props.filter.groupName}
                  setName={setName}
                  nameError={nameError}
                  setNameError={setNameError}
                  inputItemGroup={inputItemGroup}
                  inputItemGroupList={inputItemGroupList}
                  displayNameList={displayNameList}
                  setInputItemGroupList={setInputItemGroupList}
                  filter={props.filter}
                  clickVerifyFilter={clickVerifyFilter}
                  tooManyRecordsError={tooManyRecordsError}
                  disableSave={disableSave}
                  setTooManyRecordsError={setTooManyRecordsError}
                  setDisableSave={setDisableSave}
                />
              </div>
              <div
                style={{
                  display: clickVerifyFilter
                      ? "block"
                      : "none",
                  width: "100%",
                  paddingTop: "3px",
                }}
              >
                <p style={{ margin: "0px", paddingLeft: "13px" }}>Filter Output</p>
                { tooManyRecordsError && <p style={{ margin: "0px", paddingLeft: "13px" }}>          
                  Showing 50 out of total <span style={{ fontWeight: 'bold' }}>{totalRecords}</span> records...       
                  </p>
                }
                <div class="filter-list-wrapper">
                  {displayNameList && displayNameList.length < 1 && (
                    <List className="filter-list-scroll" tabIndex="0">
                      <div style={{textAlign: "center"}}>
                          No matching QoS found for provided filter criteria
                      </div>
                    </List>
                  )}
                  {displayNameList && displayNameList.length > 0 && (
                    <List className="filter-list-scroll" tabIndex="0">
                      {displayNameList.map((input) => (
                        <ListItem
                          style={{ padding: 0 }}
                          title
                          key={`${input.displayName}`}
                        >
                          <Tooltip arrow title={input.displayName}>
                            <ListItemText primary={`${input.displayName}`} />
                          </Tooltip>
                        </ListItem>
                      ))}
                    </List>
                  )}
                </div>
              </div>
            </div>
          </>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleUpdateFilter}
            variant="text"
            color="primary"
            disabled={disableSave || !name}
            style={{ fontSize: "0.875rem", textDecoration: "none", fontWeight: "600", textTransform: "capitalize" }}
          >
            Update Filter
          </Button>
          <Button
            type="button"
            onClick={handleFilterClearClick}
            color="primary"
            variant="outlined"
            style={{ fontSize: "0.875rem", textDecoration: "none", fontWeight: "600", textTransform: "capitalize" }}
          >
            Clear Filter
          </Button>
          <Button onClick={handlVerifyFilter}
            color="primary"
            variant="contained"
            style={{ fontSize: "0.875rem", textDecoration: "none", fontWeight: "600", textTransform: "capitalize" }}
          >
            Verify Filter
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AdvancedFilterDialog;
