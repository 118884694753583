import React, { Component } from "react";
import { FilterIcon } from "../../../ui-components/uim-components";
import { TextField ,InputAdornment} from "@mineral/core";
import "./filter.less";
import Resource from "./Resource";
import debounce from "lodash.debounce";
import AccessibleTooltip from "../../../common/AccessibleTooltip";

let setTimeoutId = null;

class Filter extends Component {
  debouncedChangeValue = debounce((text) => this.props.onChange(text), 600);

  constructor(props) {
    super(props);
    this.state = {
      valueInput: props.value,
    };
  }

  handleChange = (event) => {
    let value = event.target.value;
    this.setState({
      valueInput: value,
    });
    if (this.props.onChange) {
      this.debouncedChangeValue(value);
    }
  };

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.value !== this.props.value) {
      clearTimeout(setTimeoutId);
      this.setState({
        valueInput: nextProps.value,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value)
      this.props.value === "" && this.setState({ valueInput: "" });
    //clear filter when user click on current menu item again
  }

  onKeyUp = (evt) => {
    let value = "";
    //clear filter when user presses esc key
    if (evt.which === 27) {
      this.props.onChange(value);
    }
  };
  render() {
    return (
      <div className="filter"  style={{backgroundColor:'#FFFFFF',marginLeft:'8px'}}>
       
        <TextField
          {...this.props}
          value={this.state.valueInput}
          name="Filter"
          //Filter
          placeholder={Resource.get("Filter")}
          inputProps={{
            "aria-label": "Filter",
          }}
          
          InputProps={{
           
            
           endAdornment: (
             <InputAdornment position="end" >
                <AccessibleTooltip title={"Search"}>
                <FilterIcon className="filter__icon" />
                 </AccessibleTooltip>
             </InputAdornment>
           )}}

          onChange={this.handleChange}
          onKeyUp={this.onKeyUp}
        />
      </div>
    );
  }
}

export default Filter;
