import config from '../config'
import axios from 'axios'

const url = `${config.basename}/api/v1/user/preferences`
class UserPreferences {
  getGlobalAndPersonalUserPreferences() {
   
    if(process.env.NODE_ENV=='development'){
      return axios.get(
        "http://10.252.25.208:8080/castlemock/mock/rest/project/6bM7nh/application/CENxVX/geHomePagePreferenceByUser"
        );
      }
      return axios.get(url+"/geHomePagePreferenceByUser");
  }

  getAccountUserPreferences() {
    if(process.env.NODE_ENV=='development'){
      return axios.get(
   "http://10.252.25.208:8080/castlemock/mock/rest/project/TK8t3o/application/UXxhjW/geHomePagePreferenceForBusUser"
    );
  }
     return axios.get(url+"/geHomePagePreferenceForBusUser");
  }
  createUserPreference(data = []) {
    return axios.post(url, data)
  }

  updateUserPreference(data = []) {
    return axios.put(url, data)
  }

  deleteUserPreference(userPreferenceIds)
  {
    /*let params = new URLSearchParams();
    params.append("id", userPreferenceId);
    
    let request = {
      params: params
    }*/
    return axios.put(url+"/resetPreferences",userPreferenceIds);

  }

  getUserPreference(userPreferenceId)
  {
    /*let params = new URLSearchParams();
    params.append("id", userPreferenceId);
    
    let request = {
      params: params
    }*/
    return axios.get(url,"/"+userPreferenceId);

  }
  
}

let userpreferences = new UserPreferences()
export default userpreferences