import React, {Component, Fragment} from 'react'
import {TimeIcon} from '../../../ui-components/uim-components'
import {ListItem, Select as SelectField, TextField, Button, List, Divider, Popover} from '@mineral/core'
import {DateTimePicker} from "@material-ui/pickers";
import {TIME_RANGE_TYPES} from './../../../../api/cabi/actionTypes'
import './cabiTimeRange.less'
//import Resource from './Resource'
import moment from "moment/moment";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Resource from "./Resource";
const today = new Date();
const labelArray = [{label: '1 Hour', value: '1'}, {label: '2 Hours', value: '2'}, {label: '12 Hours', value: '12'},
  {label: '24 Hours', value: '24'}, {label: '3 Days', value: '72'}, {label: '1 Week', value: '168'}, {
    label: '2 Weeks',
    value: '336'
  },
  {label: '1 Month', value: '720'}, {label: '3 Months', value: '2160'}];

class CabiTimeRange extends Component {
  state = {
    timeRange: this.props.timeRange || '',
    start_date:  this.props.start_date?moment(this.props.start_date).format("YYYY-MM-DD HH:mm:ss"): null,
    end_date: moment().format("YYYY-MM-DD HH:mm:ss"),
    openList: false,
    selectedTimeRange: ''
  }
  handleChange = (evt, value) => {
    if (this.props.onChange) {
      this.props.onChange(value, null, null)
    }
    this.toggleList();
    return this.setState({timeRange: value})
  }
  handleDateChange = (event, key) => {
    let date = moment(event).format('YYYY-MM-DD HH:mm:ss');
    this.setState({[key]: date, timeRange: ''})
  }
  toggleList = () => {
    let current = this.state.openList
    this.setState({openList: !current})
  }
  handleApply = () => {
    this.toggleList();
    this.setState({timeRange:-1});
    if (this.props.onChange) {
      this.props.onChange(-1,  moment(this.state.start_date).format("YYYY-MM-DDTHH:mm:ss"),  moment().format("YYYY-MM-DDTHH:mm:ss"))
    }
  }

  validateTime() {
    if (this.state.start_date) {
      let start = moment(this.state.start_date, "YYYY-MM-DD HH:mm:ss");
      let end = moment();
      if (start.date() === end.date()) {
        return end.isAfter(start)
      } else {
        return true
      }
    } else {
      return true
    }
  }

  render() {
    const item = labelArray.filter(item => {
      return item.value === this.state.timeRange
    });
    return (
      <div className="timeRange">
        {
          <div>
            <TimeIcon className={'time__icon'}/>
          </div>
        }
        <div>
          <div style={{display: 'flex', cursor: 'pointer'}} onClick={this.toggleList}>
            <TextField
              value={this.state.timeRange != '' && this.state.timeRange != -1 ? (item && item.length > 0) ? item[0].label : '' :
                (this.state.start_date != null && this.state.end_date != null) ? 'Custom' : ''}
              style={{width: '100px'}}
              disabled
            />
            <ArrowDropDownIcon style={{transform: "translateY(10px)"}}/>
          </div>

          <Popover
            open={this.state.openList}
            onClose={this.toggleList}
          >
            <List style={{background: '#f2f2f2'}}>
              {labelArray.map((item) =>
                <ListItem value={item.value} children={item.label}
                          button
                          selected={this.state.timeRange === item.value}
                          onClick={(e) => this.handleChange(e, item.value)}/>
              )}
            </List>
            <Divider/>
            <div style={{display: "grid", margin: "10px"}}>
                  <span style={{
                    fontSize: "14px", color: "#666666",
                    fontWeight: "400",
                    fontFamily: "Roboto",
                    margin: "5px 0px",
                  }}>Custom</span>
              <Fragment>
                <DateTimePicker
                  ampm={false}
                  value={this.state.start_date}
                  onChange={(e) => this.handleDateChange(e, "start_date")}
                  label="Start Date"
                  format="YYYY-MM-DD HH:mm:ss"
                  maxDate={today}
                  maxDateMessage={"Date should not be after today's date"}
                />
              </Fragment>
              <Fragment>
               
                 <TextField
         //CurrentDate
          value={Resource.get('Current Date')}
          label="End Date"
          disabled={true}
          variant="standard"
         
        />
              </Fragment>

              {this.validateTime() ? null :
                <span style={{fontSize: '0.75rem', color: '#f44336'}}>End time should not be before start</span>}
              <Divider/>
              <div>
                <Button
                  variant="text"
                  children="Apply"
                  style={{float: "right"}}
                  disabled={this.state.start_date == null || this.state.end_date == null || !this.validateTime()}
                  onClick={this.handleApply}
                />
              </div>
            </div>

          </Popover>
        </div>
      </div>
    )
  }
}

export default CabiTimeRange
