import { call, put, takeLatest } from 'redux-saga/effects'
import { fetchAccountSuccess, fetchAccountFailure } from './actions'
import { FETCH_ACCOUNT, FETCH_AXA_ACCOUNT } from './actionTypes'
import account from './account'
import { loadCommon } from './../../utils/common'

function* fetchAccountSaga(action) {
  try {
    const response = yield call(account.getUser, action.params)
    const group = response.data
    yield put(fetchAccountSuccess(group))
    return response
  } catch (error) {
    yield put(fetchAccountFailure(error))
  }
}

export function* watchFetchAccount() {
  yield takeLatest(FETCH_ACCOUNT, fetchAccountSaga)
}

function* fetchAxaAccountSaga(action) {
  try {
    const caCloud = yield call(loadCommon)
    const response = yield call(caCloud.getUser)
    const account = response.data
    yield put(fetchAccountSuccess(account))
    return response
  } catch (error) {
    yield put(fetchAccountFailure(error))
  }
}

export function* watchFetchAxaAccount() {
  yield takeLatest(FETCH_AXA_ACCOUNT, fetchAxaAccountSaga)
}
