import React, { createRef, useEffect, useState } from "react";
import SplitResizeIcon from "../components/icons/SplitResizeIcon";
import Resource from "../components/treeViewNew/Resource";

import {Tooltip} from "@mineral/core";

const MIN_WIDTH = window.screen.width * 0.1;
const MAX_WIDTH = window.screen.width * 0.35;
const DEFAULT_WIDTH = window.screen.width * 0.15;

const LeftPane = ({ children, leftWidth, setLeftWidth, openTree }) => {
  const leftRef = createRef();

  useEffect(() => {
    if (leftRef.current) {
      if (!leftWidth) {
        setLeftWidth(leftRef.current.clientWidth);
        return;
      }

      leftRef.current.style.width = `${leftWidth}px`;
    }
  }, [leftRef, leftWidth, setLeftWidth]);

  return (
    <div
      ref={leftRef}
      className={openTree ? "open-groups-width" : "close-groups-width"}
      style={{
        overflow: "auto",
        position: "relative",
        height: "100%",
      }}
      
    >
      {children}
    </div>
  );
};

export const SplitView = ({
  left,
  right,
  className,
  openTree,
  collapseTreeView,
}) => {
  const [leftWidth, setLeftWidth] = useState(DEFAULT_WIDTH);
  const [separatorXPosition, setSeparatorXPosition] = useState(undefined);
  const [dragging, setDragging] = useState(false);

  const splitPaneRef = createRef();

  const onMouseDown = (e) => {
    setSeparatorXPosition(e.clientX);
    setDragging(true);
  };

  const onTouchStart = (e) => {
    setSeparatorXPosition(e.touches[0].clientX);
    setDragging(true);
  };

  const onMove = (clientX) => {
    if (dragging && leftWidth && separatorXPosition) {
      const newLeftWidth = leftWidth + clientX - separatorXPosition;
      setSeparatorXPosition(clientX);

      if (newLeftWidth < MIN_WIDTH) {
        setLeftWidth(MIN_WIDTH);
        return;
      }

      if (newLeftWidth > MAX_WIDTH) {
        setLeftWidth(MAX_WIDTH);
        return;
      }

      if (splitPaneRef.current) {
        const splitPaneWidth = splitPaneRef.current.clientWidth;

        if (newLeftWidth > splitPaneWidth - MIN_WIDTH) {
          setLeftWidth(splitPaneWidth - MIN_WIDTH);
          return;
        }
      }

      setLeftWidth(newLeftWidth);
    }
  };

  const onMouseMove = (e) => {
    // e.preventDefault();
    onMove(e.clientX);
  };

  const onTouchMove = (e) => {
    onMove(e.touches[0].clientX);
  };

  const onMouseUp = () => {
    setDragging(false);
  };

  React.useEffect(() => {
    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("touchmove", onTouchMove);
    document.addEventListener("mouseup", onMouseUp);

    return () => {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("touchmove", onTouchMove);
      document.removeEventListener("mouseup", onMouseUp);
    };
  });

  //Resize
  const RESIZE=Resource.get("Resize");
  return (
    <div className={`splitView ${className ?? ""}`} ref={splitPaneRef} style={{height:'100%'}}
     >
      {openTree ? (
        <>
          <LeftPane
            openTree={openTree}
            leftWidth={leftWidth}
            setLeftWidth={setLeftWidth}
          >
            {left}
          </LeftPane>
          
          <Tooltip title={RESIZE}  >
          <button
            className="divider-hitbox"
             tabIndex={-1}
            onMouseDown={onMouseDown}
            onTouchStart={onTouchStart}
            onTouchEnd={onMouseUp}
            aria-label={RESIZE} 
          >
            <SplitResizeIcon role="slider" />
          </button>
          </Tooltip>
        </>
      ) : null}
      <div
        className="rightPane"
        style={{height:'100%',
          width: openTree
            ? `calc(100vw - (67px + ${leftWidth}px + 14px))`
            : `calc(100vw - (67px + 14px))`,
        }}
      >
        {right}
      </div>
    </div>
  );
};
