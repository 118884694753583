// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Add
  content["Add"] =
  "追加";

  //Setup complete
  content["Setup complete"] =
  "セットアップの完了";

  //ServiceNow Ticketing
  content["ServiceNow Ticketing"] =
  "ServiceNow チケット";

  //Settings
  content["Settings"] =
  "設定";

  //ServiceNow
  content["ServiceNow "] =
  "ServiceNow";

  //Invalid format: ###-###-####
  content["Invalid format: ###-###-####"] =
  "無効な形式: ###-###-####";

  //CANCEL
  content["CANCEL"] =
  "キャンセル";

  //DELETE
  content["DELETE"] =
  "削除";

  //This action removes the ServiceNow integration.
  content["This action removes the ServiceNow integration."] =
  "このアクションによって、ServiceNow 統合が削除されます。";

  //Alarms will no longer be synchronized between CA Infrastructure Management and ServiceNow.
  content["Alarms will no longer be synchronized between CA Infrastructure Management and ServiceNow."] =
  "CA Infrastructure Management と ServiceNow の間で、アラームは同期されなくなります。";

  //Delete integration?
  content["Delete integration?"] =
  "統合を削除しますか?";

  //URL
  content["URL"] =
  "URL";

  //User name
  content["User name"] =
  "ユーザ名";

  //Password
  content["Password"] =
  "パスワード";

  //DefaultGroupName
  content["Default group name"] =
  "デフォルト グループ名";

  //Create new ServiceNow tickets when Infrastructure Management generates alarms with the following severities:
  content["Create new ServiceNow tickets when Infrastructure Management generates alarms with the following severities:"] =
  "次の重大度で Infrastructure Management がアラームを生成する場合に、新しい ServiceNow チケットを作成します:";

  //ServiceNow integration
  content["ServiceNow integration"] =
  "ServiceNow 統合";

  //instruction msg
  content["This integration acts as a gateway between the Infrastructure Management and ServiceNow Incident Management system. The integration generates an incident ticket in the Service desk that is based on the Infrastructure Management alarm. Generating an incident helps the ServiceNow user to take corrective actions to resolve the issue. The integration periodically polls the service desk for the status of incidents and updates the corresponding alarms."] =
  "この統合は、Infrastructure Management と ServiceNow Incident Management システムの間のゲートウェイとして機能します。統合によって、Infrastructure Management アラームに基づくサービス デスクにインシデント チケットが生成されます。インシデントの生成により、ServiceNow ユーザは修正措置を実行して問題を解決することができます。統合では、インシデントのステータスについてサービス デスクが定期的にポーリングされ、対応するアラームを更新します。";

  //Enable Group Assignment
  content["Enable Group Assignment"] =
  "グループ アサインを有効化";

  //Auto close alarm
  content["Auto close alarm"] =
  "アラームの自動クローズ";

  //Auto close tickets
  content["Auto close tickets"] =
  "チケットの自動クローズ";

  //Severity levels
  content["Severity levels"] =
  "重大度レベル";

  //Ticket on Severity Change
  content["Ticket on Severity Change"] =
  "重大度の変更に対するチケット";

  //Communication Error
  content["Communication Error"] =
  "通信エラー";

  //Critical
  content["Critical"] =
  "クリティカル";

  //Major
  content["Major"] =
  "メジャー";

  //MInor
  content["Minor"] =
  "マイナー";

  //Warning
  content["Warning"] =
  "警告";

  //Info
  content["Info"] =
  "情報";

  //Ticket on severity chnage
  content["Ticket on severity change"] =
  "重大度の変更に対するチケット";

  //Specify the base URL for the ServiceNow instance.The default format is: https://instancename.service-now.com.
  content["Specify the base URL for the ServiceNow instance.The default format is: https://instancename.service-now.com."] =
  "ServiceNow インスタンスのベース URL を指定します。デフォルトの形式は https://instancename.service-now.com です。";

  //Enter the ServiceNow admin username.
  content["Enter the ServiceNow admin username."] =
  "ServiceNow 管理者ユーザ名を入力してください。";

  //Enter the ServiceNow admin password.
  content["Enter the ServiceNow admin password."] =
  "ServiceNow の管理パスワードを入力してください。";

  //Toggle to activate assigning tickets and alarms to Groups in Infrastructure Manager.
  content["Toggle to activate assigning tickets and alarms to Groups in Infrastructure Manager."] =
  "チケットおよびアラームのインフラストラクチャ マネージャ内のグループへのアサインをアクティブ化に切り替えます。";

  //Specify the default Group to which ServiceNow tickets and alarms are assigned.
  content["Specify the default Group to which ServiceNow tickets and alarms are assigned."] =
  "ServiceNow チケットおよびアラームがアサインされるデフォルト グループを指定します。";

  //Select to configure alarms to close automatically.
  content["Select to configure alarms to close automatically."] =
  "自動的にクローズするアラームを設定する場合に選択します。";

  //Select to configure tickets to close automatically.
  content["Select to configure tickets to close automatically."] =
  "自動的にクローズするチケットを設定する場合に選択します。";

  //Specify the severity of alarms in Infrastructure Manager which will create incidents in ServiceNow.
  content["Specify the severity of alarms in Infrastructure Manager which will create incidents in ServiceNow."] =
  "ServiceNow でインシデントを作成するインフラストラクチャ マネージャでアラームの重大度を指定します。";

  // Ticket on Sev Change Message
  content["Select the option to create a ticket in ServiceNow when the severity of an existing ticket changes. If you do not select the option, only the severity of existing ticket changes."] =
  "既存のチケットの重大度が変更された場合に ServiceNow でチケットを作成するには、このオプションを選択します。選択しない場合は、既存のチケットの重大度のみが変更されます。";

  //SAVE
  content["SAVE"] =
  "保存";

  // END OF LOCALIZATION

export default content;
