import {
  FETCH_GLOBAL_AND_PERSONAL_USER_PREFERENCES,
  FETCH_GLOBAL_AND_PERSONAL_USER_PREFERENCES_SUCCESS,
  FETCH_GLOBAL_AND_PERSONAL_USER_PREFERENCES_FAILURE,
  
  FETCH_ACCOUNT_USER_PREFERENCES,
  FETCH_ACCOUNT_USER_PREFERENCES_SUCCESS,
  FETCH_ACCOUNT_USER_PREFERENCES_FAILURE,
  
  
  CREATE_USER_PREFERENCES,
  CREATE_USER_PREFERENCES_SUCCESS,
  CREATE_USER_PREFERENCES_FAILURE,
  
  
  UPDATE_USER_PREFERENCES,
  UPDATE_USER_PREFERENCES_SUCCESS,
  UPDATE_USER_PREFERENCES_FAILURE,
  
  DELETE_USER_PREFERENCES,
  DELETE_USER_PREFERENCES_SUCCESS,
  DELETE_USER_PREFERENCES_FAILURE,

  
  SETDEFAULTVIEW_USER_PREFERENCES,
  SETDEFAULTVIEW_USER_PREFERENCES_SUCCESS,
  SETDEFAULTVIEW_USER_PREFERENCES_FAILURE,


  RESET_STORE

  } from "./actionTypes";
  
  const INIT_STATE= {   
       
      
     
    userPreferencesGlobalPersonalListGetStatus:{isFetching: false,status: 'none',userpreferences: {items:[],totalCount:0},},
    userPreferencesAccountListGetStatus:{isFetching: false,status: 'none',userpreferences: {items:[],totalCount:0},},  
    userPreferenceCreateStatus:{status:'none',userpreference:{},preferencename:''},
    userPreferenceUpdateStatus:{status:'none',userpreference:{},preferencename:''},
    userPreferencesSetDefaultViewStatus:{status:'none',userpreference:{},preferencename:''},
    userPreferenceDeleteStatus:{status:'none',preferencename:''},
   
  
  
  };
  const userpreference = (
    state =INIT_STATE,
    action
  ) => {
    switch (action.type) {
      case RESET_STORE:
        return Object.assign({},state,INIT_STATE);
     
      case FETCH_GLOBAL_AND_PERSONAL_USER_PREFERENCES:
          return Object.assign({}, state, {
            userPreferencesGlobalPersonalListGetStatus:{isFetching: true,
          didInvalidate: false,
          userpreferences:[],
          status:'inprogress'
          }});
      case FETCH_GLOBAL_AND_PERSONAL_USER_PREFERENCES_SUCCESS:
        return Object.assign({}, state, {
          userPreferencesGlobalPersonalListGetStatus:{isFetching: false,
                didInvalidate: false,
                userpreferences: action.userpreferences,
          status:'success'
        }});
      case FETCH_GLOBAL_AND_PERSONAL_USER_PREFERENCES_FAILURE:
        return {
          ...state,
          userPreferencesGlobalPersonalListGetStatus:{isFetching: false,
          didInvalidate: true,
          userpreferences: [],
        status:'failure'}
        };
        case FETCH_ACCOUNT_USER_PREFERENCES:
          return Object.assign({}, state, {
            userPreferencesAccountListGetStatus:{status:'inprogress',
            jobId: action.id,
            userpreferences:{},
          }});
        case FETCH_ACCOUNT_USER_PREFERENCES_SUCCESS:
          return Object.assign({}, state, {
            userPreferencesAccountListGetStatus:{status:'success',
            userpreferences:action.userpreferences,
          }});
        case FETCH_ACCOUNT_USER_PREFERENCES_FAILURE:
          return {
            ...state,
            userPreferencesAccountListGetStatus:{status:'failure',
            userpreferences:{},
          }};
         
          case CREATE_USER_PREFERENCES:
            return Object.assign({}, state, {
              userPreferenceCreateStatus:{status:'inprogress',userpreference:{}},
            });
          case CREATE_USER_PREFERENCES_SUCCESS:
            return Object.assign({}, state, {
              userPreferenceCreateStatus:{status:'success',userpreference:action.userpreference,preferencename:action.preferencename},
         
            });
          case CREATE_USER_PREFERENCES_FAILURE:
            return {
              ...state,
              userPreferenceCreateStatus:{status:'failure',userpreference:{},preferencename:action.preferencename},
         
            };
            case UPDATE_USER_PREFERENCES:
              return Object.assign({}, state, {
                userPreferenceUpdateStatus:{status:'inprogress',userpreference:{},preferencename:action.preferencename},
              });
            case UPDATE_USER_PREFERENCES_SUCCESS:
              return Object.assign({}, state, {
                userPreferenceUpdateStatus:{status:'success',userpreference:action.userpreference,preferencename:action.preferencename},
           
              });
            case UPDATE_USER_PREFERENCES_FAILURE:
              return {
                ...state,
                userPreferenceUpdateStatus:{status:'failure',userpreference:{},preferencename:action.preferencename},
           
              };
              case SETDEFAULTVIEW_USER_PREFERENCES:
                return Object.assign({}, state, {
                  userPreferencesSetDefaultViewStatus:{status:'inprogress',userpreference:{},preferencename:action.preferencename},
                });
              case SETDEFAULTVIEW_USER_PREFERENCES_SUCCESS:
                return Object.assign({}, state, {
                  userPreferencesSetDefaultViewStatus:{status:'success',userpreference:action.userpreference,preferencename:action.preferencename},
             
                });
              case SETDEFAULTVIEW_USER_PREFERENCES_FAILURE:
                return {
                  ...state,
                  userPreferencesSetDefaultViewStatus:{status:'failure',userpreference:{},preferencename:action.preferencename},
             
                };
  
          case DELETE_USER_PREFERENCES:
            return Object.assign({}, state, {
              userPreferenceDeleteStatus:{status:'inprogress',preferencename:action.preferencename},
              
            });
          case DELETE_USER_PREFERENCES_SUCCESS:
            return Object.assign({}, state, {
                userPreferenceDeleteStatus:{status:'success', id:action.id,preferencename:action.preferencename},
            });
          case DELETE_USER_PREFERENCES_FAILURE:
            return {
              ...state,
              userPreferenceDeleteStatus:{status:'failure',preferencename:action.preferencename},
         
            };      
      default:
        return state;
    }
  };
  export default userpreference;
  