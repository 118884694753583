export const FETCH_PAGERDUTY_CONFIG = 'FETCH_PAGERDUTY_CONFIG';
export const FETCH_PAGERDUTY_CONFIG_SUCCESS = 'FETCH_PAGERDUTY_CONFIG_SUCCESS';
export const FETCH_PAGERDUTY_CONFIG_FAILURE = 'FETCH_PAGERDUTY_CONFIG_FAILURE';
export const SAVE_PAGERDUTY_CONFIG = 'SAVE_PAGERDUTY_CONFIG';
export const SAVE_PAGERDUTY_CONFIG_SUCCESS = 'SAVE_PAGERDUTY_CONFIG_SUCCESS';
export const SAVE_PAGERDUTY_CONFIG_FAILURE = 'SAVE_PAGERDUTY_CONFIG_FAILURE';
// export const VALIDATE_PAGERDUTY_CONFIG = 'VALIDATE_PAGERDUTY_CONFIG';
// export const VALIDATE_PAGERDUTY_CONFIG_SUCCESS = 'VALIDATE_PAGERDUTY_CONFIG_SUCCESS';
// export const VALIDATE_PAGERDUTY_CONFIG_FAILURE = 'VALIDATE_PAGERDUTY_CONFIG_FAILURE';
export const DELETE_PAGERDUTY_CONFIG = 'DELETE_PAGERDUTY_CONFIG';
export const DELETE_PAGERDUTY_CONFIG_SUCCESS = 'DELETE_PAGERDUTY_CONFIG_SUCCESS';
export const DELETE_PAGERDUTY_CONFIG_FAILURE = 'DELETE_PAGERDUTY_CONFIG_FAILURE';

