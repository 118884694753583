import React from 'react'
import { FormHelperText } from '@mineral/core'

const HelpingText = ({ errorMessage, disabled, hint = '' }) => {
  if (errorMessage?.length > 0 && !disabled) {
    return <FormHelperText sx={{color: errorMessage ? '#DE1B1B !important': 'unset' }}>{errorMessage}</FormHelperText>
  }

  if (hint?.length > 0){
    return <FormHelperText className="input__hint">{hint}</FormHelperText>
  }

  return null
}

export default HelpingText
