import {
  SET_CARD_PAGE}
from './actionTypes'

const cardPage = (state = 0, action) => {
  switch (action.type) {
    case SET_CARD_PAGE:
      return action.page
    default:
      return state
  }
}
export default cardPage
