import React from "react";

const Icon = (props) => {
  let classes = ["icon", props.className].join(" ");
  return (
    <svg
      // className={classes}
      role={props.role || "figure"}
      aria-label={props.title}
      width="24px"
      height="16px"
      viewBox="0 0 24 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{props.title}</title>
      <g>
        <polygon points="0 6.54428591 23.995715 6.54428591 23.995715 0 0 0"></polygon>
        <polygon points="0 15.2700005 23.995715 15.2700005 23.995715 8.72571455 0 8.72571455"></polygon>
      </g>
    </svg>
  );
};

export default Icon;
