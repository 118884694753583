import { SET_GROUPMANAGEMENT_PERMISSION } from './actionTypes'

const groupManagementPermission = (state = false, action) => {
  switch (action.type) {
    case SET_GROUPMANAGEMENT_PERMISSION:
      return action.groupManagementPermission
    default:
      return state
  }
}
export default groupManagementPermission
