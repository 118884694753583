import React, { useEffect } from "react";
import {
  ChipPopover,
  Box,
  TextField,
  Typography,
  Tooltip,
  Button
} from "@mineral/core";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDateTimePicker } from "@mui/x-date-pickers/StaticDateTimePicker";

// import { StaticTimePicker } from '@mui/x-date-pickers/StaticTimePicker';
import dayjs from 'dayjs';
import moment from "moment";

const CalendarPicker = props => {

  const { referenceData, setRefLineData, minTime, maxTime } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [newTimeStamp, setNewTimeStamp] = React.useState(0);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const [value, setValue] = React.useState(referenceData?.ts ? dayjs(referenceData?.ts * 1000) : dayjs((new Date(referenceData?.sampleTime).getTime())));

  return (
    <ChipPopover
      {...props}
      anchorEl={anchorEl}
      onClose={handleClose}
      open={open}
      ChipProps={{
        ...props.ChipProps,
        label: (
          <Tooltip title={value.$d.toString().substr(0, 24)}>
            <Typography variant="body2"
              style={{ maxWidth: 'inherit', overflow: 'hidden', textOverflow: 'ellipsis' }}
            >
              {value.$d.toString().substr(0, 24)}
            </Typography>
          </Tooltip>
        ),
        onClick: handleClick,
        style: {
          maxWidth: '100%'
        }
      }}
    >

      <Box display="flex" justifyContent="flex-end" padding={1} style={{ zIndex: 999999 }}>
        <LocalizationProvider dateAdapter={AdapterDayjs} >
          <StaticDateTimePicker
            displayStaticWrapperAs="desktop"
            ampm={false}
            value={value}
            onChange={(newValue) => {
              setValue(newValue);
              const newTimestamp = {};
              newTimestamp.ts = newValue.$d.getTime() / 1000;
              setNewTimeStamp(newTimestamp);
              //setRefLineData(newTimestamp);
            }}
            minDateTime={dayjs(minTime * 1000)}
            maxDateTime={dayjs(maxTime * 1000)}
            renderInput={(params) => <TextField {...params} />}
            sx={{ zIndex: 9999999 }}
          />
        </LocalizationProvider>
      </Box>
      <Box display="flex" justifyContent="flex-end" padding={1} style={{ zIndex: 999999 }}>
        <Button variant="text" color="primary" onClick={() => {
          handleClose();
        }}>CANCEL</Button>
        <Button variant="text" color="primary" onClick={() => {
          handleClose();
          if (newTimeStamp !== 0)
            if(props.aggregationValue===-1)
            setRefLineData(newTimeStamp);
            else
            {
              const newDateTime = {};
              newDateTime.sampleTime = moment(newTimeStamp.ts*1000).format('YYYY-MM-DD HH:mm');
              setRefLineData(newDateTime);
            }            
        }}>OK</Button>
      </Box>
    </ChipPopover>
  );
};

export default CalendarPicker;
