export const FETCH_AG = "FETCH_AG";
export const FETCH_AG_SUCCESS = "FETCH_AG_SUCCESS";
export const FETCH_AG_FAILURE = "FETCH_AG_FAILURE";
export const UPDATE_AG = "UPDATE_AG";
export const UPDATE_AG_SUCCESS = "UPDATE_AG_SUCCESS";
export const UPDATE_AG_FAILURE = "UPDATE_AG_FAILURE";
export const FETCH_PAG = "FETCH_PAG";
export const FETCH_PAG_SUCCESS = "FETCH_PAG_SUCCESS";
export const FETCH_PAG_FAILURE = "FETCH_PAG_FAILURE";
export const UPDATE_PAG = "UPDATE_PAG";
export const UPDATE_PAG_SUCCESS = "UPDATE_PAG_SUCCESS";
export const UPDATE_PAG_FAILURE = "UPDATE_PAG_FAILURE";

