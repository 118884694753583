// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //File Details:
  content["File Details:"] =
  "Detalles del archivo:";

  //File Name
  content["File Name:"] =
  "Nombre del archivo:";

  //File Type:
  content["File Type:"] =
  "Tipo de archivo:";

  //File upload text
  content["Select file before Pressing the Upload button"] =
  "Seleccione el archivo antes de pulsar el botón Cargar.";

  //Cancel
  content["Cancel"] =
  "Cancelar";

  //Upload
  content["Upload"] =
  "Cargar";

  //device import
  content["Device Import"] =
  "Importación de dispositivos";

  //Inventory
  content["Inventory"] =
  "Inventario";

  //Refreshed
  content["Refreshed"] =
  "Actualizado";

  //Results
  content["Results"] =
  "Resultados";

  //Import
  content["Import"] =
  "Importar";

  //Discovery Wizard
  content["Discovery Wizard"] =
  "Asistente para la detección";

  //Robot Deployment
  content["Robot Deployment"] =
  "Implementación del robot";

  // Search computer systems
  content["Search computer systems"] =
  "Buscar sistemas informáticos";

  // END OF LOCALIZATION

export default content;
