import {
    REQUEST_GROUP_DEVICES,
    REQUEST_GROUPS, 
    REQUEST_GROUP_PROFILES,
    REQUEST_DEVICE_BY_DEVICEID
} from './actionTypes'
import {
    call,
    put,
    takeEvery,
  } from 'redux-saga/effects'
  import groups from './groups'

import {
    fetchGroupsSuccess,fetchGroupsFailure,
    fetchGroupProfilesFailure,fetchGroupProfilesSuccess,
    fetchGroupDevicesFailure,fetchGroupDevicesSuccess,
    fetchDeviceByDeviceIdSuccess,fetchDeviceByDeviceIdFailure
   
} from './actions'


function* fetchGroupsForSaga(action){
    try{
      const response = yield call(groups.getGroups,action.options)
      yield put(fetchGroupsSuccess(response.data))
    }catch(error){
        yield put(fetchGroupsFailure(error));
    }
}

export function* groupRequestSaga() {
    yield takeEvery(REQUEST_GROUPS, fetchGroupsForSaga);
}

function* fetchGroupProfilesForSaga(action){
    try{
        const response = yield call(groups.getGroupProfiles,action.options)
        yield put(fetchGroupProfilesSuccess(response.data))
    }catch(error){
        yield put(fetchGroupProfilesFailure(error));
    }
}

export function* profileRequestSaga() {
    yield takeEvery(REQUEST_GROUP_PROFILES, fetchGroupProfilesForSaga);
}

function* fetchDevicesForSaga(action){
    try{
        const response = yield call(groups.getGroupDevices,action.options)
        yield put(fetchGroupDevicesSuccess(response.data))
    }catch(error){
        yield put(fetchGroupDevicesFailure(error));
    }
}

export function* deviceRequestSaga() {
    yield takeEvery(REQUEST_GROUP_DEVICES, fetchDevicesForSaga);
}

function* fetchDeviceByDeviceId(action){
    try{
      const response = yield call(groups.getDeviceByDeviceId,action.deviceId,action.profileId)
      yield put(fetchDeviceByDeviceIdSuccess(response.data))
    }catch(error){
        yield put(fetchDeviceByDeviceIdFailure(error));
    }
}

export function* deviceByDeviceIdRequestSaga() {
    yield takeEvery(REQUEST_DEVICE_BY_DEVICEID,fetchDeviceByDeviceId);
}

