import {
  SET_SELECTED_CS_IDS,
} from './actionTypes'

const selectedCsIds = (state = [], action) => {
  switch (action.type) {
    case SET_SELECTED_CS_IDS:
      return action.ids ? [...action.ids] : state
    default:
      return state
  }
}

export default selectedCsIds
