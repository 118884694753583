// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //customdashboard
  content["Custom Dashboard"] =
  "Custom Dashboard";

  //cabiErrorRetrieve
  content["Error retrieving cabi information"] =
  "Error retrieving cabi information";

  //contactAdmin
  content["Cabi is not available or accessible. Please contact your administrator."] =
  "Cabi is not available or accessible. Please contact your administrator.";

  //OK
  content["OK"] =
  "OK";

  //successfulcreate
  content["Successfully created user dashboard"] =
  "Successfully created user dashboard";

  //failedcreate
  content["Failed to create user dashboard"] =
  "Failed to create user dashboard";

  //cabiurls
  content["Cabi URL"] =
  "Cabi URL";

  //dashboardPath
  content["Dashboard Path*"] =
  "Dashboard Path*";

  //defaultAlarmState
  content["Default Alarm State"] =
  "Default Alarm State";

  //defaultTimeRange
  content["Default Time Range"] =
  "Default Time Range";

  //defaultMinimumAlarm
  content["Default Minimum Alarm"] =
  "Default Minimum Alarm";

  //defaultTopN
  content["Default Top N"] =
  "Default Top N";

  //staticFilters
  content["Static Filters"] =
  "Static Filters";

  //dynamicFilters
  content["Dynamic Filters"] =
  "Dynamic Filters";

  //dashcardname
  content["Dashcard name"] =
  "Dashcard name";

  //description
  content["Description"] =
  "Description";

  //openalarms
  content["Open Alarms"] =
  "Open Alarms";

  //closedalarms
  content["Closed Alarms"] =
  "Closed Alarms";

  //allalarms
  content["All Alarms"] =
  "All Alarms";

  //1hour
  content["1 Hour"] =
  "1 Hour";

  //2hourss
  content["2 Hours"] =
  "2 Hours";

  //12hours
  content["12 Hours"] =
  "12 Hours";

  //24hours
  content["24 Hours"] =
  "24 Hours";

  //3days
  content["3 Days"] =
  "3 Days";

  //1week
  content["1 Week"] =
  "1 Week";

  //2weeks
  content["2 Weeks"] =
  "2 Weeks";

  //1month
  content["1 Month"] =
  "1 Month";

  //3months
  content["3 Months"] =
  "3 Months";

  //minor
  content["Minor"] =
  "Minor";

  //information
  content["Information"] =
  "Information";

  //warning
  content["Warning"] =
  "Warning";

  //major
  content["Major"] =
  "Major";

  //critical
  content["Critical"] =
  "Critical";

  //Required
  content["Required"] =
  "Required";

  // Save
  content["Save"] =
  "Save";

  // Create
  content["Create"] =
  "Create";

  // Cancel
  content["Cancel"] =
  "Cancel";

  // Ok
  content["Ok"] =
  "Ok";

  //top5
  content["Top 5"] =
  "Top 5";

  //top10
  content["Top 10"] =
  "Top 10";

  //top20
  content["Top 20"] =
  "Top 20";

  //top50
  content["Top 50"] =
  "Top 50";

  //top100
  content["Top 100"] =
  "Top 100";

  //edit Dashboard
  content["Edit Dashboard"] =
  "Edit Dashboard";

  //add dashboard
  content["Add Dashboard"] =
  "Add Dashboard";

  //successfuldelete
  content["Successfully deleted user dashboard"] =
  "Successfully deleted user dashboard";

  //faileddelete
  content["Failed to delete user dashboard"] =
  "Failed to delete user dashboard";

  //successfulupdate
  content["Successfully updated user dashboard"] =
  "Successfully updated user dashboard";

  //failedupdate
  content["Failed to update user dashboard"] =
  "Failed to update user dashboard";

  //onClick={() => {this.props.folderClick(path, filterTxt)}}
  content["No dashboard found"] =
  "No dashboard found";

  //Dashboards
  content["Dashboards"] =
  "Dashboards";

  // Search Dashboard
  content["Search Dashboard"] =
  "Search Dashboard";

  //Monitoring Configuring Service
  content["Monitoring Configuring Service"] =
  "Monitoring Configuring Service";

  //Search...
  content["Search"] =
  "Search";

  //Infrastructure Mgmt Overview
  content["Infrastructure Management Overview"] =
  "Infrastructure Management Overview";

  //deletecustomdashboard;
  content["Delete custom dashboard"] =
  "Delete custom dashboard";

  //deletCustomconfirmation
  content["Are you sure you want to delete the custom dashboard : "] =
  "Are you sure you want to delete the custom dashboard : ";

  // END OF LOCALIZATION

export default content;
