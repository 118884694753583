import React, { Component } from 'react'
import {CircularProgress} from '@mineral/core'
import { Policy } from '@uim/alarm-policy-components'
import get from 'lodash/get'
import axios from 'axios'
import './policies.less'
import Resource from './Resource'
import HelpButton from '../../utils/help'

class PolicyContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
   acl:props.acl,
     sid: null,
  }
    }
  _setBreadcrumbs = policy => {
    const crumbs = [
      {
	//settings
        name: Resource.get('Settings'),
        link: '/settings',
      },
      {
	//alarm policies
        name: Resource.get('Alarm Policies'),
        link: '/settings/alarm-policies',
      },
      {
        name: policy.name,
        link: `/settings/alarm-policies/${policy.id}`,
      },
    ]
    this.props.setBreadCrumbs(crumbs)
  }
  componentDidMount = () => {
	  let overrideSid=axios.defaults.headers.common["Authorization"];
      if(window.OPERATOR_CONSOLE.refreshToken && window.OPERATOR_CONSOLE.refreshToken!=="")
          {
          overrideSid=window.OPERATOR_CONSOLE.refreshToken;
          axios.defaults.headers.common["Authorization"]=window.OPERATOR_CONSOLE.refreshToken;
          window.OPERATOR_CONSOLE.refreshToken="";
          
          }
        this.setState({ sid: overrideSid})
      
  }
  onPolicyAction=(e)=>{
    if(this.props.launchFrom && this.props.launchFrom==='group'){
        this.props.goBack();
    }
    else{
     //this.props.history.goBack()
     this.props.history.push('/settings/alarm-policies');
    }
  }
  render() {
    const policyId = this.props.launchFrom && this.props.launchFrom==='group'?this.props.policyId:get(this.props, 'match.params.policyId')
    let spinner = (
      <div className="policy__spinner" style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <CircularProgress style={{color: '#3272D9'}} />
      </div>
    )
    return (
      <main className="policy__main" >
        {this.state.sid ? (
		 <div style={{position:'relative',overflow:'auto',height:'100%'}}>
          <Policy
          sid={this.state.sid}
          axiosInterceptors={axios.default.interceptors}
            account={this.props.account.item}
		    probeWhiteListSTB={get(this.props.featureFlags, 'probeWhiteListSTB', false)}
            apiInfo={this.props.apiInfo}
            handlePolicy={policy => {
              if(!this.props.launchFrom){
               this._setBreadcrumbs(policy)
              }
            }}
            handleCancel={this.onPolicyAction}
            handleDeletePolicy={this.onPolicyAction}
            handleSavePolicy={this.onPolicyAction}
            policyId={policyId}
            launchFrom={this.props.launchFrom}
            groupId={this.props.groupId}
            saas={this.props.saas}
            acl={this.props.acl}
          /></div>
        ) : (
          spinner
        )}
      </main>
    )
  }
}
export default PolicyContainer
