
export const REQUEST_DEVICES = 'REQUEST_DEVICES'
export const FETCH_DEVICES_DASHBOARD_SUCCESS = 'FETCH_DEVICES_DASHBOARD_SUCCESS'
export const FETCH_DEVICES_DASHBOARD_FAILURE = 'FETCH_DEVICES_DASHBOARD_FAILURE'

export const REQUEST_DEVICE_PROFILES = 'REQUEST_DEVICE_PROFILES'
export const FETCH_DEVICE_PROFILES_DASHBOARD_SUCCESS = 'FETCH_DEVICE_PROFILES_DASHBOARD_SUCCESS'
export const FETCH_DEVICE_PROFILES_DASHBOARD_FAILURE = 'FETCH_DEVICE_PROFILES_DASHBOARD_FAILURE'

export const REQUEST_PROFILE_BY_PROFILEID = 'REQUEST_PROFILE_BY_PROFILEID'
export const FETCH_PROFILE_BY_PROFILEID_DASHBOARD_SUCCESS = 'FETCH_PROFILE_BY_PROFILEID_DASHBOARD_SUCCESS'
export const FETCH_PROFILE_BY_PROFILEID_DASHBOARD_FAILURE = 'FETCH_PROFILE_BY_PROFILEID_DASHBOARD_FAILURE'


