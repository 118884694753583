import React from 'react'
import './icons.less'

const Icon = (props) => {
  let classes = [
    'icon',
    props.className,
  ].join(' '),
  height = props.height || 24,
  width = props.width || 24,
  fillVal = props.fill || '#007DAB',
  fillOpacity =  props.fillOpacity || 1;
  return (
    <svg className={classes} role={props.role || 'figure'} aria-label="[title]" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24"> 
        <path d="M11.4702939,5.36865234 L10.2910947,5.36865234 L9.82234472,4.14916992 L7.67634863,4.14916992 L7.2332334,5.36865234 L6.08333105,5.36865234 L8.17439551,0 L9.32063574,0 L11.4702939,5.36865234 Z M1.49999772,10.516734 L1.49999772,1.28531901 C1.49999772,1.00917664 1.72385534,0.78531901 1.99999772,0.78531901 C2.27614009,0.78531901 2.49999772,1.00917664 2.49999772,1.28531901 L2.49999772,10.5320105 L3.13767409,9.89301979 C3.3325344,9.69775785 3.64879097,9.69743227 3.84405291,9.89229257 C3.84417424,9.89241365 3.8442955,9.89253479 3.8444167,9.892656 C4.03941125,10.0876505 4.03941125,10.4037992 3.8444167,10.5987937 C3.8442553,10.5989551 3.84409379,10.5991164 3.84393217,10.5992776 L2.71126958,11.7288377 L2.0075199,12.4319236 L0.146720742,10.5760472 C-0.0485410613,10.3813019 -0.0489598183,10.0651388 0.145785422,9.86987696 C0.145941102,9.86972087 0.146096886,9.86956488 0.146252773,9.86940899 C0.341447906,9.67421386 0.657921746,9.67421386 0.85311688,9.86940899 C0.853157318,9.86944943 0.85319775,9.86948988 0.853238175,9.86953033 L1.49999772,10.516734 Z M9.47444433,3.24462891 L8.00960058,3.24462891 L8.73469824,1.25244141 L9.47444433,3.24462891 Z M6.58056413,13.285319 L6.58056413,12.3075358 L9.40038834,8.82486979 L6.89916764,8.82486979 L6.89916764,7.91666667 L10.828611,7.91666667 L10.828611,8.75895182 L7.88793717,12.380778 L10.9421364,12.380778 L10.9421364,13.285319 L6.58056413,13.285319 Z" id="path-2" style={{fill:fillVal,fillOpacity:fillOpacity}}></path>
    </svg>
  )
}

export default Icon

