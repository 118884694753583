import React, { Component } from "react";
import "./credentialVault.less";
import CredentialVaultCatalogRedux from "./CredentialVaultCatalogRedux";
import credentials from "./../../../api/DeviceDiscovery/credentials/credentials";
import Resource from "./Resource";
import CredentialWelcomeText from "./CredentialWelcomeText";
import { Checkbox,Snackbar,SnackbarContent,FormControl,TextField,InputLabel,FormControlLabel } from "@mineral/core";
import { Button } from "@mineral/core";
import { CheckBoxOutlineBlankIcon as UnCheckedIcon } from "@material-ui/icons/CheckBoxOutlineBlank";
import deleteIcon from "../../../assets/icons/deleteIcon.svg";
import errorIcon from "../../../assets/icons/error_alert.svg";

import CustomSnackbar from '../../common/CustomSnackbar'

/**
 * @Author namam01
 */
class WindowsCredentialVault extends Component {
  constructor() {
    super();
    this.state = {
      credentialObject: {},
      credentialName: "",
      isCredentialNameValid: true,
      credentialId: "",
      user: "",
      isUserValid: true,
      password: "",
      isPasswordValid: true,
      showPassword: false,
      showNonadmCred:false,
      nauser: "",
      isnaUserValid: true,
      napassword: "",
      isnaPasswordValid: true,
      nashowPassword: false,
      showWelcomeText: true,
      editLabel: "",
      isDirty: false,
      isPasswordTouched: false,
      isnaPasswordTouched:false,
      showRemoveButton: true,
      savepopUpOpen: false,
      popMassage:"",
      popErrorType:'info',
      error: {
        errorOccurred: false,
        errorMessage: "",
        errorDescription: "",
      },
    };
  }
  credentialVaultHandle = null;
  enableWelcomeText = () => {
    this.setState({
      showWelcomeText: true,
    });
  };
  handleCredentialNameChange = (e) => {
    let name = e.target.value.trim();
    if (name === "") {
      this.setState({
        credentialName: name,
        isCredentialNameValid: false,
        isDirty: true,
      });
    } else {
      this.setState({
        credentialName: name,
        isCredentialNameValid: true,
        isDirty: true,
      });
    }
  };
  handleUserNameChange = (e) => {
    let user = e.target.value.trim();
    if (user === "") {
      this.setState({
        user: user,
        isUserValid: false,
        isDirty: true,
      });
    } else {
      this.setState({
        user: user,
        isUserValid: true,
        isDirty: true,
      });
    }
  };
  handlePasswordChange = (e) => {
    let pass = e.target.value.trim();
    let crdId = this.state.credentialId;
    if (pass === "") {
      this.setState({
        password: pass,
        isPasswordValid: false,
        isDirty: true,
        // isPasswordTouched: false,
      });
    } else {
      this.setState({
        password: pass,
        isPasswordValid: true,
        isDirty: true,
        isPasswordTouched: crdId === "" ? false : true,
      });
    }
  };
  //non admin
  handleNonadminUserNameChange = (e) => {
    let nauser = e.target.value.trim();
    if (nauser === "") {
      this.setState({
        nauser: nauser,
        isnaUserValid: false,
        isDirty: true,
      });
    } else {
      this.setState({
        nauser: nauser,
        isnaUserValid: true,
        isDirty: true,
      });
    }
  };
  handleNonadminPasswordChange = (e) => {
    let napassword = e.target.value.trim();
    let crdId = this.state.credentialId;
    if (napassword === "") {
      this.setState({
        napassword: napassword,
        isnaPasswordValid: false,
        isDirty: true,
        // isPasswordTouched: false,
      });
    } else {
      this.setState({
        napassword: napassword,
        isnaPasswordValid: true,
        isDirty: true,
       isnaPasswordTouched: crdId === "" ? false : true,
      });
    }
  };
  SaveOrUpdateTheCredential = (e) => {
    let credentialsInfo = {};
    if (this.state.credentialId === "") {
      credentialsInfo.name = this.state.credentialName;
      credentialsInfo.password = this.state.password;
      credentialsInfo.userName = this.state.user;
      credentialsInfo.nonRootUserName= this.state.nauser;
      credentialsInfo.nonRootPassword= this.state.napassword;
      //Add
      credentials.saveWMI(credentialsInfo).then(
        (response) => {
          this.SaveOrUpdateTheCredentialSuccess(response.data);
          if (this.credentialVaultHandle) {
            this.credentialVaultHandle.refreshPostCreate(response.data, "WMI");
            this.setState({
              savepopUpOpen: true,
              popMassage:"Added Successfully",
              popErrorType:'success'
            })
          }
          this.closeError();
        },
        (err) => {
          let errMssg = Resource.get("Error Adding Credentials"); //Error Adding Credentials
          let errorDescription = err.response.data.errorMsg;
          let error = {
            message: errMssg,
            description: errorDescription,
          };
          this.setState({
            savepopUpOpen: true,
            popMassage:errMssg,
            popErrorType:'error'
          })
          this.setError(error);
          //console.log(err)
        }
      );
    } else {
      //edit
      if (this.state.credentialName !== this.state.credentialObject.name) {
        credentialsInfo.name = this.state.credentialName;
      }
      if (this.state.user !== this.state.credentialObject.userName) {
        credentialsInfo.userName = this.state.user;
      }
      if (this.state.password !== this.state.credentialObject.password) {
        credentialsInfo.password = this.state.password;
      }
      if (this.state.nauser !== this.state.credentialObject.nonRootUserName) {
        credentialsInfo.nonRootUserName = this.state.nauser;
      }
      if (this.state.napassword !== this.state.credentialObject.nonRootPassword) {
        credentialsInfo.nonRootPassword = this.state.napassword;
      }
      credentialsInfo.id = this.state.credentialId;


      console.log("credentialObject---",this.state.credentialObject);
      console.log('credentialsInfo---',credentialsInfo)
      credentials.editWMI(credentialsInfo).then(
        (response) => {
          this.SaveOrUpdateTheCredentialSuccess(response.data);
          if (this.credentialVaultHandle) {
            this.credentialVaultHandle.refreshPostUpdate(response.data, "WMI");
            this.setState({
              savepopUpOpen: true,
              popMassage:"Updated Successfully",
              popErrorType:'success'

            })
          }
          this.closeError();
        },
        (err) => {
          let errMssg = Resource.get("Error Updating Credentials"); //Error Updating Credentials
          let errorDescription = err.response.data.errorMsg;
          let error = {
            message: errMssg,
            description: errorDescription,
          };
          this.setState({
            savepopUpOpen: true,
            popMassage:errMssg,
            popErrorType:'error'
          })
          this.setError(error);
          //console.log(err)
        }
      );
    }
  };
  setError = (error) => {
    this.setState({
      error: {
        errorOccurred: true,
        errorMessage: error.message,
        errorDescription: error.description,
      },
    });
  };
  deleteCredential = () => {
    credentials.deleteWMI(this.state.credentialId).then(
      (response) => {
        this.setState({
          showWelcomeText: true,
          showRemoveButton: true,
        });
        if (this.credentialVaultHandle) {
          this.credentialVaultHandle.refreshPostDelete(
            this.state.credentialId,
            "WMI"
          );
          this.setState({
            savepopUpOpen: true,
            popMassage:"Removed Successfully",
            popErrorType:'success'
          })
        }
        this.closeError();
      },
      (err) => {
        let errMssg = Resource.get("Error Deleting Credentials"); //Error Deleting Credentials
        let errorDescription = err.response.data.errorMsg;
        let error = {
          message: errMssg,
          description: errorDescription,
        };
        this.setState({
          savepopUpOpen: true,
          popMassage:errMssg,
          popErrorType:'error'
        })
        this.setError(error);
        //console.log(err)
      }
    );
  };
  closeDeleteCredential = () => {
    //console.log('No called check how to close this mess')
    this.setState({
      showRemoveButton: true,
    });
  };
  deleteCredentialConfirmation = () => {
    this.setState({
      showRemoveButton: false,
    });
    //console.log('called delete')
  };
  SaveOrUpdateTheCredentialSuccess = (obj) => {
    this.setState({
      credentialObject: obj,
      credentialName: obj.name,
      credentialId: obj.id,
      user: obj.userName,
      password: obj.password,
      nonRootUserName:obj.nauser,
      nonRootPassword:obj.napassword,
      showPassword: false,
      editLabel: obj.name,
      isDirty: false,
      isPasswordTouched: false,
      isnaPasswordTouched:false,
      showNonadmCred:false,
      showNonadmCred:obj.nauser || obj.napassword,
    });
  };
  handleShowPasswordChange = (e) => {
    let val = e.target.checked;
    this.setState({
      showPassword: val,
      isDirty: true,
    });
  };
  handleShownaPasswordChange = (e) => {
    let val = e.target.checked;
    this.setState({
      nashowPassword: val,
      isDirty: true,
    });
  };
  handleShownaCredentials =(e)=>{
    let val = e.target.checked;
    this.setState({
      showNonadmCred:val,
    })
  }
  makePasswordTouchedDirty = (e) => {
    if (this.state.credentialId !== "") {
      this.setState({
        isPasswordTouched: false,
        password: "",
        isPasswordValid: false,
      });
    }
  };
  makenaPasswordTouchedDirty = (e) => {
    if (this.state.credentialId !== "") {
      this.setState({ 
        isnaPasswordTouched:false,
        napassword: "",
      isnaPasswordValid: true,
      });
    }
  };
  resetTheCredentialForm = () => {
    this.closeError();
    if (this.state.credentialId === "") {
      this.setState({
        credentialName: "",
        isCredentialNameValid: true,
        credentialId: "",
        user: "",
        isUserValid: true,
        password: "",
        isPasswordValid: true,
        showPassword: false,
        nauser: "",
      isnaUserValid: true,
      napassword: "",
      isnaPasswordValid: true,
      nashowPassword: false,
        showWelcomeText: false,
        showNonadmCred: false,
        editLabel: "",
        isDirty: false,
        isPasswordTouched: false,
        isnaPasswordTouched:false,
        savepopUpOpen: true,
        popMassage:"Reset Successfully",
        popErrorType:'success'
      });
    } else {
      let temp = this.state.credentialObject;
      this.setState({
        credentialName: temp.name,
        isCredentialNameValid: true,
        credentialId: temp.id,
        user: temp.userName,
        isUserValid: true,
        password: temp.password,
        isPasswordValid: true,
        showPassword: false,
        nauser: temp.nonRootUserName?temp.nonRootUserName:"",
        isnaUserValid: true,
        napassword: temp.nonRootPassword?temp.nonRootPassword:"",
        isnaPasswordValid: true,
      showNonadmCred:temp.nonRootUserName || temp.nonRootPassword ? true : false,
        nashowPassword: false,
        showWelcomeText: false,
        editLabel: temp.name,
        isDirty: false,
        isPasswordTouched: false,
        isnaPasswordTouched:false,
        savepopUpOpen: true,
        popMassage:"Reset Successfully",
        popErrorType:'success'
      });
    }
  };
  updateCredentialForm = (credObject) => {
    console.log(' in update form credObject-----',credObject)
    this.closeError();
    if (!credObject) {
      this.setState({
        credentialObject: {},
        credentialName: "",
        editLabel: "",
        isCredentialNameValid: true,
        credentialId: "",
        user: "",
        isUserValid: true,
        password: "",
        isPasswordValid: true,
        showPassword: false,
        nauser: "",
        isnaUserValid: true,
        napassword: "",
        isnaPasswordValid: true,
        nashowPassword: false,
        showWelcomeText: false,
        isDirty: false,
        isPasswordTouched: false,
        isnaPasswordTouched: false,
        showRemoveButton: true,
        showNonadmCred:false,
      });
    } else {
      this.setState({
        credentialObject: credObject,
        credentialName: credObject.name,
        editLabel: credObject.name,
        isCredentialNameValid: true,
        credentialId: credObject.id,
        user: credObject.userName,
        isUserValid: true,
        password: credObject.password,
        isPasswordValid: true,
        showPassword: false,
        nauser:credObject.nonRootUserName?credObject.nonRootUserName: "",
        isnaUserValid: true,
        showNonadmCred: credObject.nonRootPassword || credObject.nonRootUserName,
        napassword: credObject.nonRootPassword?credObject.nonRootPassword: "",
        isnaPasswordValid: true,
        nashowPassword: false,
        showWelcomeText: false,
        isDirty: false,
        isPasswordTouched: false,
        isnaPasswordTouched:false,
        showRemoveButton: true,
      });
    }
  };
  getDeleteTag = () => {
    let removeLabel = Resource.get("Remove"); //Remove
    let confirmationMessage = Resource.get("Remove this credential?"); //Remove this credential?
    let yesLabel = Resource.get("Yes"); //Yes
    let noLabel = Resource.get("No"); //No
    if (this.state.showRemoveButton) {
      return (
        <div style={{ paddingTop: "25px" }}>
          <Button
           
          
           variant="contained" color="error"
            onClick={this.deleteCredentialConfirmation}
          >
            {removeLabel}
          </Button>
        </div>
      );
    } else {
      return (
        <div style={{ paddingTop: "25px" }}>
          <span style={{ fontSize: "14px", marginRight: "15px" }}>
            {confirmationMessage}
          </span>
          <Button
           
           style={{marginRight:'8px'}}
          
            onClick={this.deleteCredential.bind(this)}
          >
            {yesLabel}
          </Button>
          <Button
            
           
            onClick={this.closeDeleteCredential.bind(this)}
          >
            {noLabel}
          </Button>
        </div>
      );
    }
  };
  closeError = () => {
    this.setState({
      error: {
        errorOccurred: false,
        errorMessage: "",
        errorDescription: "",
      },
    });
  };
  onDescription = () => {
    this.setState({
      savepopUpOpen: false,
    });
  };
  handleRequestClose = () => {
    this.setState({
      savepopUpOpen: false,
    });
  };
  render() {

      
    let addHeaderLabel = Resource.get("New WMI Credential"); //New WMI Credential
    let headerLabel =
      this.state.credentialId === "" ? addHeaderLabel : this.state.editLabel;
    let nameLabel = Resource.get("Name"); //Name
    let IDLabel = Resource.get("ID"); //ID
    let passwordLabel = Resource.get("Password"); //Password
    let userLabel = Resource.get("User"); //User
    let nauserLabel = Resource.get("Non admin User");//Non admin User
    let nauserPasswordLabel = Resource.get("Non admin Password");//Non admin Password
    let showPasswordLabel = Resource.get("Show Password"); //Show Password
    let nonadminLabel = Resource.get("Run Robot as non admin user");//Run Robot as non admin user
    let requiredLabel = Resource.get("Required"); //Required
    let showWelcomeTxt = this.state.showWelcomeText;
    let resetLabel = Resource.get("Reset"); //Reset
    let addLabel = Resource.get("Add"); //Add
    let updateLabel = Resource.get("Update"); //Update

    let deleteText = this.getDeleteTag();

    return (
      <div className="credential-vault">
        <CredentialVaultCatalogRedux
          type="WMI"
          handleError={this.setError}
          refreshWelcome={this.enableWelcomeText}
          populateCred={this.updateCredentialForm}
          OnCredintialsReady={(api) => {
            this.credentialVaultHandle = api;
          }}
        />
        {showWelcomeTxt ? (
          <CredentialWelcomeText type="WMI" />
        ) : (
          <>
          <div className="add-edit-credential" id={"add-edit-credential"}>
            <h3 className="header"> {headerLabel}</h3>
            
            {/*this.state.error.errorOccurred ? (
              <div className="error-ribbon">
                <div style={{ display: "inline-block", width: "90%" }}>
                  <div
                    style={{
                      display: "inline-block",
                      marginLeft: "22px",
                      marginRight: "22px",
                      verticalAlign: "middle",
                    }}
                  >
                    <a style={{ float: "left" }}>
                      <img src={errorIcon} />
                    </a>
                  </div>
                  <div style={{ display: "inline-block", width: "90%" }}>
                    <span>{this.state.error.errorMessage}</span>
                    <span
                      style={{
                        borderLeft: "2px solid #999999",
                        display: "inline-block",
                        margin: "10px 15px -5px",
                        paddingTop: "20px",
                      }}
                    ></span>
                    <span>{this.state.error.errorDescription}</span>
                  </div>
                  <div
                    style={{
                      display: "inline-block",
                      cursor: "pointer",
                      marginRight: "20px",
                      marginLeft: "10px",
                      verticalAlign: "middle",
                    }}
                    onClick={() => this.closeError()}
                  >
                    <a style={{ float: "left" }}>
                      {" "}
                      <img src={deleteIcon} />
                    </a>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )*/}
            <div style={{ width: "90%" }}>
<FormControl style={{ width: "90%" }}>
            <InputLabel id="input-nameLabel" htmlFor="name" > {nameLabel}<span className="credentialvault-required-label">{requiredLabel}</span></InputLabel>
                          
                           
                          <TextField labelId="input-nameLabel"
                           inputProps={{'aria-required':true}}
                          autoFocus
                          error={!this.state.isCredentialNameValid}
                          fullWidth variant="outlined" size="small"
                           id="name"   type="text"
                           value={this.state.credentialName}
                         //  onBlur={this.handleCredentialNameChange.bind(this)}
                onChange={this.handleCredentialNameChange.bind(this)}/>

</FormControl>
</div>
            
            {this.state.credentialId !== "" ? (
              
              <div style={{ width: "420px" ,marginTop:'8px' }}>
<FormControl style={{ width: "420px" }}>
            <InputLabel id="input-IDLabel" htmlFor="credentialId" > {IDLabel}</InputLabel>
                          
                           
                          <TextField labelId="input-IDLabel"
                          
                           
                        
                          fullWidth variant="outlined" size="small"
                           id="credentialId"
                           type="text" disabled="true"
                           value={this.state.credentialId}
                          
                          
                          />

</FormControl>
</div>
            ) : (
              ""
            )}
           <div style={{width: "90%" ,marginTop:'8px' }}>
<FormControl style={{width: "90%" }}>
            <InputLabel id="input-userLabel" htmlFor="user" > {userLabel}<span className="credentialvault-required-label">{requiredLabel}</span></InputLabel>
                          
                           
                          <TextField labelId="input-userLabel"
                           inputProps={{'aria-required':true}}
                           
                          error={!this.state.isUserValid}
                          fullWidth variant="outlined" size="small"
                           id="user"
                           value={this.state.user}
                          onChange={this.handleUserNameChange.bind(this)}/>

</FormControl>
</div>



<div style={{width: "90%",marginTop:'8px' }}>
<FormControl style={{ width: "90%" }}>
            <InputLabel id="input-passwordLabel" htmlFor="password" > {passwordLabel}<span className="credentialvault-required-label">{requiredLabel}</span></InputLabel>
                          
                           
                          <TextField labelId="input-passwordLabel"
                           inputProps={{'aria-required':true}}
                          error={!this.state.isPasswordValid}
                          fullWidth variant="outlined" size="small"
                           id="password"
                           value={this.state.password}
                           type={this.state.showPassword ? "text" : "password"}
                         onClick={this.makePasswordTouchedDirty.bind(this)}
                onBlur={this.handlePasswordChange.bind(this)}
                onChange={this.handlePasswordChange.bind(this)}
                          />

</FormControl>
</div>
            
          {this.state.credentialId === "" || this.state.isPasswordTouched ? (  <div style={{marginTop:'8px'}}>


              <FormControlLabel
                                                              control={
                                                                <Checkbox 
                                                                color="primary"
                                                              
                                                                checked={this.state.showPassword}
                                                                onChange={this.handleShowPasswordChange.bind(this)}
                                                               
                                                                    />}
                                                              label={showPasswordLabel}/>
                
              </div>):null}
            
            {!this.state.isDirty && this.state.credentialId !== "" ? (
              deleteText
            ) : (
              <div style={{ paddingTop: "38px" }}>
                <Button
                  type="button"
                  aria-label={this.state.credentialId === "" ? addLabel : updateLabel}
                  style={{ marginRight: "10px" }}
                  disabled={
                    this.state.credentialName == "" ||
                    this.state.user == "" ||
                    this.state.password == ""
                  }
                  onClick={this.SaveOrUpdateTheCredential.bind(this)}
                >
                  {this.state.credentialId === "" ? addLabel : updateLabel}{" "}
                </Button>
                <Button
                  aria-label={resetLabel}
                  variant="text"
                  onClick={this.resetTheCredentialForm.bind(this)}
                >
                  <span>{resetLabel}</span>
                </Button>
              </div>
            )}
          </div>
          {/* <div> */}
          <div className="add-edit-non-credential" id={"add-edit-credential"}>
          <FormControlLabel
                                                              control={
                                                                <Checkbox 
                                                                color="primary"
                                                              
                                                                checked={this.state.showNonadmCred}
                                                                onChange={this.handleShownaCredentials.bind(this)}
                                                                    />}
                                                              label={nonadminLabel}/>
           
                      <div style={{ width: "90%" ,marginTop:'8px',marginBottom:'8px' }}>
<FormControl style={{ width: "90%" }} >
            <InputLabel id="input-userLabel" htmlFor="nauser" > {nauserLabel}</InputLabel>  
                          <TextField labelId="input-userLabel"
                           inputProps={{'aria-required':true}}
                           disabled={!this.state.showNonadmCred}

                          error={!this.state.isUserValid}
                          fullWidth variant="outlined" size="small"
                           id="nauser"
                           value={this.state.nauser}
                          onChange={this.handleNonadminUserNameChange.bind(this)}/>

</FormControl>
</div>



<div style={{width: "90%",marginTop:'8px',marginBottom:'8px' }}>
<FormControl style={{ width: "90%" }} >
            <InputLabel id="input-passwordLabel" htmlFor="napassword" > {nauserPasswordLabel}</InputLabel>
                          
                           
                          <TextField labelId="input-passwordLabel"
                          inputProps={{'aria-required':true}}
                          disabled={!this.state.showNonadmCred}
                          // error={!this.state.isnaPasswordValid}
                        fullWidth variant="outlined" size="small"
                           id="napassword"
                           value={this.state.napassword}
                           type={this.state.nashowPassword ? "text" : "password"}
                         onClick={this.makenaPasswordTouchedDirty.bind(this)}
                onBlur={this.handleNonadminPasswordChange.bind(this)}
                onChange={this.handleNonadminPasswordChange.bind(this)}
                
                          />

</FormControl>
</div>
            
          {this.state.credentialId === "" || this.state.isnaPasswordTouched ? (  <div style={{marginTop:'8px'}}>


              <FormControlLabel
                                                              control={
                                                                <Checkbox 
                                                                color="primary"
                                                              
                                                                checked={this.state.nashowPassword}
                                                                onChange={this.handleShownaPasswordChange.bind(this)}
                                                               
                                                                    />}
                                                              label={showPasswordLabel}/>
                
              </div>):null}
            
            {/* {!this.state.isDirty && this.state.credentialId !== "" ? (
              deleteText
            ) : (
              <div style={{ paddingTop: "38px" }}>
                <Button
                  type="button"
                  aria-label={this.state.credentialId === "" ? addLabel : updateLabel}
                  style={{ marginRight: "10px" }}
                  disabled={
                    this.state.credentialName == "" ||
                    this.state.user == "" ||
                    this.state.password == ""
                  }
                  onClick={this.SaveOrUpdateTheCredential.bind(this)}
                >
                  {this.state.credentialId === "" ? addLabel : updateLabel}{" "}
                </Button>
                <Button
                  aria-label={resetLabel}
                  variant="text"
                  onClick={this.resetTheCredentialForm.bind(this)}
                >
                  <span>{resetLabel}</span>
                </Button>
              </div>
            )} */}
          </div>
          {/* </div> */}
          
          </>
        )}
       
      {this.state.savepopUpOpen === true && this.state.popErrorType!=='error'? (
              <div>
                {/*<Snackbar
                  key="snackbar"
                  open={this.state.savepopUpOpen}
                  autoHideDuration={2000}
                  onClose={() => this.setState({ savepopUpOpen: null })}
                // onActionTouchTap={this.onDescription}
                // onRequestClose={this.handleRequestClose}
                >
                 
                    {this.state.popMassage}
                  
                </Snackbar>*/}
                <CustomSnackbar message={this.state.popMassage} severity={this.state.popErrorType} close={() => this.setState({ savepopUpOpen: null })} autoHideDuration={4000} open={this.state.savepopUpOpen} />
     
              </div>
            ) : ([])}
      <div>
          
          <CustomSnackbar  message={`${this.state.error.errorMessage} ${this.state.error.errorDescription?": "+this.state.error.errorDescription:""}`} severity={"error"} 
          close={() => this.closeError()} autoHideDuration={4000} open={this.state.error.errorOccurred} />
     
        </div>
      </div>
    );
  }
}
export default WindowsCredentialVault;
