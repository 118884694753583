import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Card,Button} from '@mineral/core';
import './integrationCard.less';
import {NavLink} from 'react-router-dom';
import Resource from './Resource';

class IntegrationCard extends Component {

  getCardClassName() {
    return ['integration__card', this.props.className].join(' ');
  }

  render() {
    // Link purpose
    const linkPurpose = this.props.logo.props.label? `Navigate  to ${this.props.logo.props.label}`: `Navigate to ${this.props.logo.props.type}`
    return (
        <div className='settings-card'  tabIndex={-1} >
          <NavLink className="settings__card--navlink" tabIndex={-1} to={this.props.configRoute}>
            <Button style={{backgroundColor:'#FFFFFF'}} variant="outlined" role ="link" aria-label={linkPurpose} className={this.getCardClassName()}  id={this.props.id} autoFocus={this.props.autoFocusAttr}>
             
              <div className='integration__card--body' style={{flexDirection:'column'}}>
              
            {this.props.logo}
            
                <div style={{marginTop: '10px'}}>
                  <span className='integration__card--subLabelOne'>{this.props.subLabelOne}</span>
                  </div>
                  <div style={{display:'flex',flexDirection:'row'}}>
                  <span className='integration__card--subLabelTwo'>{this.getSubLabelTwo()}</span>
                  <div className='integration__card--icon'>
                  {this.getIcon()}
                </div>
                </div>
               
              </div>
            </Button>
          </NavLink>
        </div>
    );
  }
}

export default IntegrationCard;

IntegrationCard.propTypes = {
  logo:PropTypes.element.isRequired,
  subLabelOne:PropTypes.string.isRequired,
  subLabelTwo:PropTypes.string.isRequired,
  configRoute:PropTypes.string.isRequired
};
