import React from 'react'
import Error from './Error'
import './centerError.less'

const CenterError = (props) => {
  let classes = [
    'center-error',
    props.className,
  ].join(' ')
  return (
    <div className={classes} style={props.style}>
      <Error {...props} />
    </div>
  )
}

export default CenterError
