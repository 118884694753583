import React, { Component } from 'react'
import {
  TextField,
  Select as SelectField,FormControl,FormHelperText,
  MenuItem,
  List,
  ListItem,  ListItemIcon,ListItemText 
} from '@mineral/core'
import {BulletPointIcon as BulletPoint,} from "../../ui-components/uim-components"
import './agentlessMonitoring.less'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import isIP from 'validator/lib/isIP'
import Resource from './Resource'

//debian based systems message
const DEBIAN_32_BIT_ERROR = Resource.get('Only 64 bit package is allowed for debian based systems.')
//Sudo Password is only applicable for Linux Systems
const DISABLE_SUDO_PASSWORD_TEXT = Resource.get('Sudo Password is only applicable for Linux systems where username is not \'root\'.')
//Windows Relay Hub Required
const WINDOWS_ON_UNIX_ERROR = Resource.get('Windows Relay Hub Required.')

class AgentlessMonitoring extends Component {
  state = {
    hostname: '',
    os: null,
    arch: null,
    username: '',
    password: '',
    sudoPassword: '',
    statusError: null,
  }
  _debianBase = () => {
    const profile = this.state.os
    return profile === 'debian' || profile === 'ubuntu'
  }
  _debian32Bit = (val = this.state.arch) => {
    return this._debianBase() && val === '32'
  }
  _incompatibleOs = (val = this.state.os) => {
    return get(this.props.hub, 'osMajor') !== 'Windows' && val === 'windows'
  }
  _sudoPasswordDisabled = () => {
    const profile = this.state.os
    const username = this.state.username
    const sudoPassword = this.state.sudoPassword
    return sudoPassword && (profile === 'windows' || username === 'root')
  }
  _isOSDisabled = value => {
	let disabled = false;
	if(this.props.hub && this.props.probe.supportedOS){
		if(this.props.hub.osMajor.toUpperCase() === 'WINDOWS'){
		  if(this.props.probe.supportedOS.toUpperCase() === 'WINDOWS' ){
		    if(value !== 'windows'){
		      disabled =  true;
		    }
		  } else if(this.props.probe.supportedOS.toUpperCase() === 'UNIX' ){
		    if(value === 'windows'){
		      disabled =  true;
		    }
		  }
		} else {
		   if(value === 'windows'){
			  disabled =  true;
		  } else if(this.props.probe.supportedOS.toUpperCase() === 'WINDOWS' ){
			  disabled =  true;
		  } 
		}      
	} else {
		disabled = true;
	}
	return disabled;
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.onChange) {
      let robot = {
        arch: this.state.arch,
        hostname: this.state.hostname,
        password: this.state.password,
        profile: this.state.os,
        sudoPassword: this.state.sudoPassword,
        username: this.state.username,
        statusError: this.state.statusError,
      }
      this.props.onChange([robot], this.validate())
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.robots[0]) {
      this.setState({
        arch: nextProps.robots[0].arch,
        hostname: nextProps.robots[0].hostname,
        password: nextProps.robots[0].password,
        os: nextProps.robots[0].profile,
        sudoPassword: nextProps.robots[0].sudoPassword,
        username: nextProps.robots[0].username,
        statusError: nextProps.robots[0].statusError,
      })
    }
  }

  handleOSChange = (event) => this.setState({ os: event.target.value })
  handleArchChange = (event) => this.setState({ arch: event.target.value })
  handleHostNameChange = (event) => this.setState({ hostname: event.target.value })
  handleUsernameChange = (event) => this.setState({ username: event.target.value })
  handlePasswordChange = (event) => this.setState({ password: event.target.value })
  handleSudoPasswordChange = (event) =>
    this.setState({ sudoPassword: event.target.value })

  ipValidate = value => {
    let error = false
    if (!value || !isIP(value)) {
    	//IP Address Required
      error = Resource.get('IP Address Required')
    }
    return error
  }
  validate = () => {
    const robot = {
      arch: this.state.arch,
      hostname: this.state.hostname,
      password: this.state.password,
      profile: this.state.os,
      sudoPassword: this.state.sudoPassword,
      username: this.state.username,
    }
    const robots = [robot]
    const robotMatchesSchema = robot => {
      const schema = ['hostname', 'profile', 'arch', 'username', 'password']
      return schema.reduce((acc, attribute) => {
        return (
          acc &&
          Object.keys(robot).includes(attribute) &&
          !isEmpty(robot[attribute])
        )
      }, true)
    }
    // return on first robot found that doesn't match schema
    const hasSchemaErrors = robots =>
      !!robots.find(robot => !robotMatchesSchema(robot))

    return (
      hasSchemaErrors(robots) ||
      this.ipValidate(robot.hostname) ||
      this._incompatibleOs(robot.os) ||
      this._debian32Bit(robot.arch) ||
      this._sudoPasswordDisabled()
    )
  }

  render() {
	  //Remote monitoring (also known as agentless monitoring) requires a monitoring host. The monitoring host is responsible for collecting data from the remote device that you are monitoring.
    let instructions = Resource.get('Remote monitoring (also known as agentless monitoring) requires a monitoring host. The monitoring host is responsible for collecting data from the remote device that you are monitoring.')
    //Monitoring host server
    let subTitleText = Resource.get('Monitoring host server')
    //host 
    let titleText = Resource.get('host')
    //Deploy a monitoring
    const depMon = Resource.get('Deploy a monitoring')
    //Recommended minimum system requirements
    const reqText = Resource.get('Recommended minimum system requirements')
    //Home 
    const HomeLabel  = Resource.get('Home')
    /*let windowsMenuItem = (
      <MenuItem
        value="windows"
        children="Windows"
        disabled={get(this.props.hub, 'osMajor') !== 'Windows'}
      />
    )*/
    if (this.props.probe.name === 'docker_monitor') {
    	//Docker engine message
      subTitleText = Resource.get('Docker engine')
      //agent message
      titleText = Resource.get('agent')
      //Docker monitoring requires an agent (aka Robot) to be installed on the Docker Engine server.
      instructions = Resource.get('Docker monitoring requires an agent (aka Robot) to be installed on the Docker Engine server.')
      //windowsMenuItem = null
    }
    let error = this.ipValidate(this.state.hostname)
    let view = (
      <div className="deploy__monitoringAgent">
        <div className="deploy__monitoringAgent--form">
          <p className="deploy__monitoringAgent--title">
            {' '}
            {depMon} {titleText}{' '}
          </p>
          <p> {subTitleText} </p>

          <TextField
            classes={{root:"deploy__monitoringAgent--hostname"}}
            fullWidth={true}
            helperText={this.state.hostname && error}           
            name="hostname"
            placeholder="123.123.123.123"
            	//IP address
            label={Resource.get("IP address")}
            autoFocus={true}
            value={this.state.hostname}
            onChange={this.handleHostNameChange}
          />
          <div className="deploy__monitoringAgent--dropDownMenus">
          <FormControl>
            <SelectField
              className="deploy__monitoringAgent--os"
              name="os"
              style={{
                width: null,
              }}
              value={this.state.os}
              //helperText={this._incompatibleOs() ? WINDOWS_ON_UNIX_ERROR : null}
              onChange={this.handleOSChange}>
              <MenuItem
                disabled={true}
                value={null}
              // Operating systems message
                children= {Resource.get('Operating system')}
              />
              <MenuItem value="centos" children="CentOS" disabled={this._isOSDisabled("centos")}/>
              <MenuItem value="debian" children="Debian" disabled={this._isOSDisabled("debian")}/>
              <MenuItem value="opensuse" children="openSUSE" disabled={this._isOSDisabled("opensuse")} />
              <MenuItem value="rhel" children="RHEL" disabled={this._isOSDisabled("rhel")}/>
              <MenuItem value="suse" children="SUSE" disabled={this._isOSDisabled("suse")}/>
              <MenuItem value="ubuntu" children="Ubuntu" disabled={this._isOSDisabled("ubuntu")}/>
              <MenuItem value="windows" children="Windows" disabled={this._isOSDisabled("windows")}/>
            </SelectField>
            <FormHelperText>{this._incompatibleOs() ? WINDOWS_ON_UNIX_ERROR : null}</FormHelperText>
            </FormControl>

            <FormControl>
            <SelectField
              className="deploy__monitoringAgent--arch"
              name="arch"
              style={{
                width: null,
              }}
              value={this.state.arch}
              //helperText={this._debian32Bit() ? DEBIAN_32_BIT_ERROR : null}
              onChange={this.handleArchChange}>
              <MenuItem
                disabled={true}
                value={null}
              //Architecture
                children= {Resource.get('Architecture')}
              />              
              <MenuItem value="64"
            	//64-bit
              children= {Resource.get('64-bit')} />
            	
              <MenuItem
                value="32"
                //32-bit
                children= {Resource.get('32-bit')}
                disabled={this._debianBase()}
              />
            </SelectField>
            <FormHelperText>{this._debian32Bit() ? DEBIAN_32_BIT_ERROR : null}</FormHelperText>
            </FormControl>
          </div>
          <div className="deploy__monitoringAgent--credentials">
            <p>{// System Credentials
            	Resource.get('System Credentials') }</p>            
            <TextField
              classes={{root:"deploy__monitoringAgent--username"}}
              fullWidth={true}              
              name="username"
            	  //Username
              label={Resource.get("Username")}
              value={this.state.username}
              onChange={this.handleUsernameChange}
            />
            <TextField
              classes={{root:"deploy__monitoringAgent--password"}}
              fullWidth={true}              
              name="password"
            	  //Password
              label={Resource.get("Password")}
              type="password"
              value={this.state.password}
              onChange={this.handlePasswordChange}
            />

            <TextField
              classes={{root:"deploy__monitoringAgent--sudoPassword"}}
              fullWidth={true}              
              name="sudoPassword"
            	  //Sudo Password (Linux only)
              label={Resource.get("Sudo Password (Linux only)")}
              type="password"
              helperText={
                this._sudoPasswordDisabled() ? DISABLE_SUDO_PASSWORD_TEXT : null
              }
              value={this.state.sudoPassword}
              onChange={this.handleSudoPasswordChange}
            />
          </div>
        </div>
        <div className="deploy__monitoringAgent--sidebar">
          <p className="deploy__monitoringAgent__sidebarHeader">{//Instructions
        	 Resource.get('Instructions')}</p>
          
          <p>{instructions} </p>
          <p> {reqText}</p>
          <List className="deploy__monitoringAgent__requirementsList">
            <ListItem
              style={{ padding: 0, color: 'none', fontSize: 'none' }}
              disabled={true}
              className="monitoringList__item"            	
            >
            <ListItemIcon>
              <BulletPoint className="relayList__icon" title= {HomeLabel} />
            </ListItemIcon>
          <ListItemText 	//4 GB of available memory
               primary={Resource.get("4 GB of available memory")} />
         </ListItem>


            <ListItem
              style={{ padding: 0, color: 'none', fontSize: 'none' }}
              disabled={true}
              className="monitoringList__item"            	 
            >
              <ListItemIcon>
                 <BulletPoint className="relayList__icon" title= {HomeLabel} />
             </ListItemIcon>
              <ListItemText 	//2 or more CPUs
                     primary={Resource.get("2 or more CPUs")} />
           </ListItem>

            <ListItem
              style={{ padding: 0, color: 'none', fontSize: 'none' }}
              disabled={true}
              className="monitoringList__item"
             >
            <ListItemIcon>
               <BulletPoint className="relayList__icon" title= {HomeLabel} />
             </ListItemIcon>
             <ListItemText 	//10 GB of available disk space
                 primary={Resource.get("10 GB of available disk space")} />
            </ListItem>

            <ListItem
              style={{ padding: 0, color: 'none', fontSize: 'none' }}
              disabled={true}
              className="monitoringList__item"            
            >
              <ListItemIcon>
               <BulletPoint className="relayList__icon" title= {HomeLabel} />
             </ListItemIcon>
             <ListItemText 	//Ports 48000 to 48025 open
                 primary={Resource.get("Ports 48000 to 48025 open")} />
            </ListItem>
          </List>
        </div>
      </div>
    )

    return view
  }
}
export default AgentlessMonitoring
