import connect from '../../utils/connect'
import MaintenanceDialog from './MaintenanceDialog'
import {fetchComputerSystemsForAdd} from '../../api/computer-systems/actions'
import {getMaintenance,getTimeZones}  from '../../api/maintenance/actions';
import {getAccount} from '../../api/account/actions'
const mapDispatchToProps = (dispatch) => {
  return {
    getMaintenance: () => dispatch(getMaintenance()),
    getAllComputerSystems: () => dispatch(fetchComputerSystemsForAdd()),
    getTimezones:()=>dispatch(getTimeZones()),
    getAccount:()=>dispatch(getAccount()),
  }

}
const mapStateToProps = (state) => {
  return {
    maintenanceItems: state.maintenance.items,
    didInvalidate: state.computerSystems.didInvalidate,
    isFetching: state.computerSystems.isFetching,
    timezones:state.maintenance.timezones,
    accounts:state.account.accounts,
    acl:state.acl
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MaintenanceDialog)
