import React from 'react';
import Portlet from '../Portlet';
import ArrowIcon from '../../ArrowIcon';
import Resource from './Resource';
import { title } from './ListDashboardDesigner';
import {Tooltip} from '@mineral/core';

//List Dashboard Designer
const label = title;
//List Dashboard Designer page
const desc = Resource.get('This List Viewer allows administrators to view the lists created');

export default class ListDashboardDesignerCard extends Portlet {
  getIcon() {
    return <ArrowIcon />;
  }

  getSubLabelTwo = () => {
    return this.props.subLabelTwo;
  };
}

const TextLogo = ({ label }) => {
  return (
    <Tooltip title={label}>
     <span className="settings-text-logo admin__console--card">{label}</span>
    </Tooltip>
  );
};

ListDashboardDesignerCard.defaultProps = {
  logo: <TextLogo label={label} />,
  configRoute: '/settings/listviewer',
  subLabelOne: desc,
  subLabelTwo: '',
};
