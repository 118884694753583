import React, { Component } from 'react';
import PropTypes from 'prop-types';
import connect from './utils/connect';
import keymap from './keymap';
import {ShortcutManager, Shortcuts} from 'react-shortcuts';
import ComponentInfoDialog from './components/info/ComponentInfoDialog';
import { setShowingComponentInfo, getApiComponentInfo } from './api/info/actions';
import AppRedux from './AppRedux';

const SHORTCUT_MANAGER = new ShortcutManager(keymap);

class KeymapApp extends Component {
  state = {
    showDebugOutline: false
  };

  componentWillMount() {
    this.props.fetchApiComponentInfo();
  }

  render() {

    let classes = [
      this.props.className,
      'operatorconsole-wrapper',
      (this.state.showDebugOutline ? 'debug-outline' : '')
    ].join(' ');

    return (
        <div className={classes}>
          <Shortcuts tabIndex = {0} name="OPERATORCONSOLE"
              className="operatorconsole-wrapper__shortcuts-wrapper"
              isolate={true}
              handler={this.handleShortcuts}>
            <AppRedux />
            <ComponentInfoDialog
                isVisible={this.props.isShowingComponentInfo}
                apiInfo={this.props.apiInfo}
                handleClose={this.props.setShowingComponentInfo}
            />
          </Shortcuts>
        </div>
    );
  }

  getChildContext() {
    return {
      shortcuts:SHORTCUT_MANAGER,
    };
  }

  handleShortcuts = (action, event) => {
    switch (action) {
      case 'COMPONENTINFO_OPEN':
        this.props.setShowingComponentInfo(true);
        break;

      case 'TOGGLE_DEBUG_OUTLINE':
        this.setState({showDebugOutline: !this.state.showDebugOutline});
        break;

      default:
        break;
    }
  }

}

KeymapApp.childContextTypes = {
  shortcuts:PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    isShowingComponentInfo: state.info.isShowingComponentInfo,
    apiInfo: state.info.apiInfo,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setShowingComponentInfo: (isVisible) => dispatch(setShowingComponentInfo(isVisible)),
    fetchApiComponentInfo: () => dispatch(getApiComponentInfo()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(KeymapApp);
