import React, { Component } from "react";
import {
  CenterError as Error,
  FloatingActionButton,
  ErrorIcon as Information,
  PlusIcon as ContentAdd  
} from "../ui-components/uim-components";
import {CircularProgress} from '@mineral/core'
import Cards from "./Cards";
import DynamicGroupDialog from "./../titlebar/dynamicGroup/DynamicGroupDialog";
import ErrorDialog from "./../titlebar/ErrorDialog";
import "./cards.less";
import Resource from "./Resource";
import AclTypes from "./../../api/aclTypes";
import infoIcon from "../../assets/icons/infomessage.svg";
import get from "lodash/get";
import Table from '../table/Table'
import ExportGroupDetailsDialog from "./../treeViewNew/ExportGroupDetailsDialog"
import computerSystemsApi from './../../api/computer-systems/computerSystems'
import GroupsMainHeader from '../treeViewNew/GroupsMainHeader'

class CardsManager extends Component {
  state = {
    showAddSubgroupDialog: false,
    showAddDevicesDialog: false,
    showErrorForAddDevices: false,
    showErrorForAddGroups: false,
    editSubGroupOpen: false,
    openDynamicGroupDialog: false,
    openExportTypeDialog: false,    
    savedColumns:[],
  };

  componentWillMount() { 
    computerSystemsApi.getSavedColumns()
    .then((response)=>{
      let columns = response.data.columns
      this.setState({ savedColumns: columns && columns.length>0?columns:[]});
    })  
  }

  handleAddSubgroup = () => {
    this.setState({
      showAddSubgroupDialog: true ,
      showAddDevicesDialog: false,
      showErrorForAddDevices: false,
      showErrorForAddGroups: false,
      editSubGroupOpen: false,
      openDynamicGroupDialog: true,
    });
  };
  handleAddDevices = () => {
    this.setState(
      {
        showAddDevicesDialog: true ,
        showAddSubgroupDialog: false,
        showErrorForAddDevices: false,
        showErrorForAddGroups: false,
        editSubGroupOpen: false,
        openDynamicGroupDialog: false,
      });
    };
    handleAddCriteria = () => {
      this.setState({
        editSubGroupOpen: true ,
        showAddDevicesDialog: true ,
        showAddSubgroupDialog: false,
        showErrorForAddDevices: false,
        showErrorForAddGroups: false,
        openDynamicGroupDialog: false,

      });
    };


    handleClose= (dialogName) => {

      if (dialogName === "dynamicGroupsOpen") {
        this.setState({ openDynamicGroupDialog: false, dynamicGroupInfo: null });

        if (this.state.editSubGroupOpen) {
          this.setState({
            editSubGroupOpen: false
          })
        }
        if (this.state.showAddSubgroupDialog) {
          this.setState({
            showAddSubgroupDialog: false
          })
        }
        if(this.state.showAddDevicesDialog){
          this.setState({
            showAddDevicesDialog: false
          })
        }
      } else  if (dialogName === "subGroupOpen") {

        if (this.state.showAddSubgroupDialog) {
          this.setState({
            showAddSubgroupDialog: false,
            openDynamicGroupDialog:false
          })
        }
      }
    };


    handleErrorClose = () => {

      if(this.state.showErrorForAddDevices){
        this.setState({
          showErrorForAddDevices: false,
        });
      }else  if(this.state.showErrorForAddGroups){
        this.setState({
          showErrorForAddGroups: false,
        });
      }


    }; 

    render() {
      let {
        computerSystemsIsFetching,
        computerSystemsDidInvalidate,
        groupsIsFetching,
        groupsDidInvalidate,
        entityType,
        entities,
      } = this.props;
      let view;
      if (
        (groupsIsFetching || computerSystemsIsFetching) &&
        !this.state.showAddDevicesDialog
        ) {
          view = (
            <div style={{ height: "calc(100vh - 205px)", width: "100%", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress style={{color: '#3272D9'}} />
            </div>
            );
          } else if (groupsDidInvalidate || computerSystemsDidInvalidate) {
            view = <Error style={{ height: "calc(100vh - 205px)" }} />;
          }else if (
            this.state.editSubGroupOpen ||
            this.state.openDynamicGroupDialog ||
            this.state.showAddSubgroupDialog ||
            this.state.showAddDevicesDialog
            ) {

              let dynamicDialogMode= "ADD Group"
              if(this.state.showAddSubgroupDialog){
                dynamicDialogMode= "ADD Group"

              }else  if(this.state.openDynamicGroupDialog){
                dynamicDialogMode= "ADD"
              }else{
                dynamicDialogMode= "EDIT"
              }
              view = (
                <DynamicGroupDialog
                open={true}
                mode={dynamicDialogMode}
                handleClose={this.handleClose}
                handleErrorDialogOpen={this.handleErrorClose}
                {...this.props}
                />
                );
              } else if (entities && entities.length > 0) {
                let canModifyGroup = get(this.props.acl.aclInfo, "roles", "").includes(
                  "USM Group Modification"
                  );
                let canAddGroup = get(this.props.acl.aclInfo, "roles", "").includes(
                  "OC Group Add"
                  );
                  view = (
                    <div>
                    
                      
                    <Cards {...this.props} />
                    
                    {/*this.props.entityType === "CONTAINER" && (canModifyGroup || canAddGroup) ? (
                      <FloatingActionButton
                        onClick={this.handleAddSubgroup}
                        style={{
                          position: "fixed",
                          bottom: "1rem",
                          right: "1rem",
                          zIndex: 2,
                          marginBottom: "80px",
                          backgroundColor: 'rgb(0, 174, 239)',
                          color: 'white'
                        }}
                      >
                      <ContentAdd />
                      </FloatingActionButton>
                      ) : (
                        ""
                        )*/}
                        </div>
                        );
                      }  else if (this.state.showErrorForAddDevices) {
                        view = (
                          <ErrorDialog
                          open={true}
                          handleClose={this.handleErrorClose}
                          errorType="addDevices"
                          />
                          );
                        } else if (this.state.showErrorForAddGroups) {
                          view = (
                            <ErrorDialog
                            open={true}
                            handleClose={this.handleErrorClose}
                            errorType="addGroup"
                            />
                            );
                          } else {
                            let noElsMsg = "";
                            //Edit group Text
                            let editGroupText=Resource.get("Edit Group")
                            let clickHandler;
                            if (entityType === "STATIC") {
                              //No devices have been added
                              noElsMsg = Resource.get("No devices have been added");
                              clickHandler = this.handleAddDevices;
                            } else if (entityType === "DYNAMIC") {
                              //No devices have been added
                              noElsMsg = Resource.get("No devices have been added");
                              clickHandler = this.handleAddCriteria;
                            } else {
                              //No subgroups have been added
                              noElsMsg = Resource.get("No subgroups have been added");
                              clickHandler = this.handleAddCriteria;
                            }

                            view = (
                              // <p className="no-devices-have-been-message">
                              // <img
                              // style={{
                              //   marginRight: "8px",
                              //   verticalAlign: "bottom",
                              //   height: "20px",
                              //   width: "20px",
                              // }}
                              // src={infoIcon}
                              // alt=""
                              // />
                              // {noElsMsg}
                              // <span className="no-devices-have-been-edit" onClick={clickHandler}>
                              // {" "+editGroupText}
                              // </span>
                              // </p>

                              <Table {...this.props} data={[]}  
                              savedColumns={this.state.savedColumns}/>
                              );

                            }
                            return view;
                          }
                        }

                        export default CardsManager;
