import React from "react";
import IntegrationCard from "../integrations/IntegrationCard";
import ArrowIcon from "../ArrowIcon";
import Resource from "./Resource";
import "./../settings.less";
import {Tooltip} from '@mineral/core';
export default class PersonalizationCard extends IntegrationCard {
  getIcon() {
    return <ArrowIcon />;
  }

  getSubLabelTwo = () => {
    return this.props.subLabelTwo;
  };
}

const TextLogo = ({ label }) => {
  return (
    <Tooltip title={label}>
      <span className="settings-text-logo admin__console--card">{label}</span>
    </Tooltip>
  );
};

PersonalizationCard.defaultProps = {
  logo: (
    <TextLogo
      //Personalize
      label={Resource.get("Personalize")}
    />
  ),
  //personalizeDesc
  subLabelOne: Resource.get("Configure default operator console view"),
  configRoute: "/settings/personalize",
};
