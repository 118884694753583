// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Redirection failed
  content["Redirection failed"] =
  "Redirección errónea";

  //Redirection error
  content["Verify if the profile associated with the group or device exists."] =
  "Compruebe si existe el perfil asociado con el grupo o dispositivos.";

  //OK
  content["OK"] =
  "Aceptar";

  //settings
  content["Settings"] =
  "Configuración";

  //alarm policies
  content["Alarm Policies"] =
  "Políticas de alarmas";

  //Inventory
  content["Inventory"] =
  "Inventario";

  //Setup Wizard
  content["Setup Wizard"] =
  "Asistente de instalación";

  //Remote And Cloud
  content["Remote And Cloud"] =
  "Remotos y en la nube";

  // END OF LOCALIZATION

export default content;
