import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FormControl, InputAdornment, FormHelperText, InputLabel, TextareaAutosize, TextField } from '@mineral/core'
import './customInputs.less'
import { useStyles } from './muiStyle'
import HelpingText from './HelpingText'
import { REQUIRED } from '../../settings/portlets/slm/ResourceKeys'

import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded'

const CustomTextArea = ({
  children,
  id,
  value,
  error,
  errorMessage,
  hint,
  name,
  style,
  placeholder,
  onChange,
  onValidation,
  required,
  fullWidth,
  multiline,
  InputProps,
  rows,
  disabled,
  minRows,
  maxRows,
  inputPropsStyle,
  labelPropsStyle,
}) => {
  const classes = useStyles()
  const [edited, setEdited] = useState(false)
  const [isValid, setIsValid] = useState(true)

  const handleChange = (e) => {
    if (!edited) setEdited(true)

    if (onValidation) {
      let isValidFlag = onValidation(e)
      let value = e.target.value
      if (isValidFlag === true && required && (!value || value.length === 0)) {
        isValidFlag = false
      }
      setIsValid(isValidFlag)
    }

    if (!disabled) onChange(e)
  }

  if (!onValidation && errorMessage && isValid) setIsValid(false)
  if (!onValidation && !errorMessage && !isValid) setIsValid(true)
  if (disabled && !isValid) setIsValid(true)

  return (
    <FormControl className={isValid ? 'input__container__textarea' : 'input__container__textarea_error'} style={style}>
      <div style={{ display: 'flex', justifyContent: 'space-between', ...labelPropsStyle }}>
        <label className="input__label" htmlFor={id} id={`${id}-text`}>
          {name}
        </label>
        <span className="input__error">{required && REQUIRED}</span>
      </div>

      <TextareaAutosize
        id={id}
        value={value}
        placeholder={placeholder}
        style={{
          backgroundColor: disabled ? '#EBEFF5' : '#FFFFFF',
          borderRadius: 4,
          padding: '7px 8px',
          boxSizing: 'border-box',
          fontSize: 14,
          borderColor: error && 'red',
          ...style,
        }}
        type="text"
        disabled={disabled}
        size="small"
        variant="outlined"
        minRows={minRows}
        onChange={handleChange}
        aria-describedby={name ? `${id}-text` : null}
        rows={rows}
        required
        maxRows={maxRows}
        {...InputProps}
        // inputProps={{
        //   ...InputProps,
        //   endAdornment: !isValid && (
        //     <InputAdornment position="end">
        //       <ReportProblemRoundedIcon style={{ height: 16, color: '#DE1B1B' }} />
        //     </InputAdornment>
        //   ),
        //   classes: {
        //     input: classes.input,
        //     textarea: classes.textarea,
        //     root: classes.root,
        //     notchedOutline: isValid ? classes.notchedOutline : classes.notchedOutlineError,
        //   },
        //   style: inputPropsStyle,
        // }}
      />

      {(!isValid || error) && <HelpingText hint={hint} errorMessage={errorMessage} disabled={disabled} />}
    </FormControl>
  )
}

CustomTextArea.propTypes = {
  id: PropTypes.string,
  errorMessage: PropTypes.string,
  hint: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onValidation: PropTypes.func,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  multiline: PropTypes.bool,
  fullWidth: PropTypes.bool,
  minRows: PropTypes.number,
  maxRows: PropTypes.number,
}

CustomTextArea.defaultProps = {
  required: false,
  disabled: false,
}

export default CustomTextArea
