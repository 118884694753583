import * as React from 'react';
import { SvgIcon } from '@mineral/core';

export const FilledStar01 = React.forwardRef(
  (props, ref) => (
    <SvgIcon viewBox="0 0 24 24" {...props} ref={ref}>
      <path d="M20 4L23.5922 15.0557H35.2169L25.8123 21.8885L29.4046 32.9443L20 26.1115L10.5954 32.9443L14.1877 21.8885L4.7831 15.0557H16.4078L20 4Z" fill={props.color} />

    </SvgIcon>
  )
);

export default FilledStar01;