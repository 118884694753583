import * as React from 'react';
import { SvgIcon } from '@mineral/core';

export const OutlinedTriangleDown = React.forwardRef(
  (props, ref) => (
    <SvgIcon data-testid="LearnIcon" viewBox="0 0 40 40" {...props} ref={ref}>
        <path d="M32.5536 8L20 29.9689L7.44636 8L32.5536 8Z" fill={props.color} stroke="#1D5BBF" stroke-width="4"/>

    </SvgIcon>
  )
);
