import * as React from 'react';
import { SvgIcon } from '@mineral/core';

export const OutlinedStar06 = React.forwardRef(
  (props, ref) => (
    <SvgIcon data-testid="LearnIcon" viewBox="0 0 40 40" {...props} ref={ref}>
        <path d="M20 7.07128L22.9519 10.5115L23.6118 11.2805L24.6221 11.2033L29.142 10.858L28.7967 15.3779L28.7195 16.3882L29.4885 17.0481L32.9287 20L29.4885 22.9519L28.7195 23.6118L28.7967 24.6221L29.142 29.142L24.6221 28.7967L23.6118 28.7195L22.9519 29.4885L20 32.9287L17.0481 29.4885L16.3882 28.7195L15.3779 28.7967L10.858 29.142L11.2033 24.6221L11.2805 23.6118L10.5115 22.9519L7.07128 20L10.5115 17.0481L11.2805 16.3882L11.2033 15.3779L10.858 10.858L15.3779 11.2033L16.3882 11.2805L17.0481 10.5115L20 7.07128Z" fill={props.color} stroke="#1D5BBF" stroke-width="4"/>

    </SvgIcon>
  )
);
