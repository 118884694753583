import axios from 'axios';
import config from './../config';

class credentials { 
   getWMI() {
    return axios.get([config.basename, 'api/v2/groups/Tree'].join('/'));
  }
   getUNIX(){
    return axios.get([config.basename, 'api/v1/credentials/shell'].join('/')); 
   }
  saveWMI(credentialObj) {
    return axios.post([config.basename, 'api/v1/credentials/wmi'].join('/'), credentialObj);
  }
  editWMI(credentialObj) {
    return axios.put([config.basename, 'api/v1/credentials/wmi'].join('/'), credentialObj);
  }
  saveUNIX(credentialObj) {
	return axios.post([config.basename, 'api/v1/credentials/shell'].join('/'), credentialObj);
 }
  editUNIX(credentialObj) {
    return axios.put([config.basename, 'api/v1/credentials/shell'].join('/'), credentialObj);
  }
  deleteWMI(id) {
        return axios.delete([config.basename, 'api/v1/credentials/wmi/'+id].join('/'));
  }
  deleteUNIX(id) {
        return axios.delete([config.basename, 'api/v1/credentials/shell/'+id].join('/'));
  }
  deleteCredential(id,type) {
	  if(type ==='WMI'){
		  return this.deleteWMI(id)
	  }else if(type ==='UNIX') {
		  return this.deleteUNIX(id)
	  }
  }
}
export default new credentials();