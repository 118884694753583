import React, { Component } from "react";
import {
  ClearIcon as Clear,
  CenterError as Error,
  CenterInfo,
} from "../../ui-components/uim-components";

import {Tooltip} from "@mineral/core";
import {CircularProgress} from "@mineral/core";
import CustomTooltip from "../../tooltip/CustomTooltip";
import isEqual from "lodash/isEqual";
import { LinearProgress } from "@mineral/core";
import { sortColumns } from "./../../../utils/tableUtils";
import Resource from "./Resource";
import { isValidParam } from "../DashboardContainerRedux";
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  IconButton,Button
} from "@mineral/core";
import connect from "./../../../utils/connect";
import {
  fetchTopTechnologiespoll,
  pollTopTechnologiesStop,
} from "./../../../api/home-screen/actions";

const chartColors = ["#D9E9EC", "#9EC6D0", "#FEE276", "#F7922F", "#A11D2E"];
const Panel = (props) => {
  const handleDrillDown = (rowNumber, colId) => {
    props.history.push(
      `/technologies/${props.data[rowNumber].probeName}/dashboard`
    );
  };
  const normalise = (value, min, max) => ((value - min) * 100) / (max - min);
  const getImageForProbe = (probeName) => {
    try {
      return probeName.endsWith("(RESTMon)")
        ? require("./../../../assets/images/dashboard_restmon.png").default
        : require("./../../../assets/images/img_" + probeName + "-colorBkg.png")
            .default;
    } catch (e) {
      return require("./../../../assets/images/dashboard_generic_monitor.png")
        .default;
    }
  };

  return (
    <div className="technologies_grid">
      <TableContainer
        style={{
          maxHeight: `${
            window.innerHeight < 800
              ? "12vh"
              : window.innerHeight == 800
              ? "18vh"
              : window.innerHeight < 1080
              ? "20vh"
              : "24vh"
          }`,
        }}
      >
        <Table>
          <TableBody stripedRows={false} displayRowCheckbox={false}>
            {props.data.map((row, index) => (
              <TableRow
                key={index}
                className="technologies-row"
                onClick={() => {
                  handleDrillDown(index, row.probeName);
                }}
                role="row"
                
              >
                <TableCell className="technologies_grid_col_img">
                  <img alt={row.probeName}
                    width="95%"
                    height="60%"
                    className="home-page-tech-img"
                    src={getImageForProbe(row.probeName)}
                    role="figure"
                  />
                </TableCell>
                <TableCell colSpan="2" className="technologies_grid_col_name">
                  {/* <CustomTooltip message={row.probeName}> */}
                  <Button variant="text" style={{padding:0,display:'flex',justifyContent: 'flex-start',width: '100%',color:'#0361D9',
}} ><span style={{
    textOverflow: 'ellipsis',overflow:'hidden', whiteSpace: 'nowrap'}}>{row.probeName}</span></Button>
                  {/* </CustomTooltip> */}
                </TableCell>
                <TableCell
                  colSpan="2"
                  className="technologies_grid_col_progress"
                >
                  {/* <CustomTooltip message={"Alarm Count"}> */}
                  <LinearProgress
                    variant="determinate"
                    color="primary"
                    aria-label={"Alarm count linear progress"}
                    value={normalise(row.alarmCount, 0, props.maxCount)}
                    className="technologies-progress-bar"
                    classes={{
                      barColorPrimary: `${"chart-sev" + row.alarmLevel}`,
                    }}
                  />
                  {/* </CustomTooltip> */}
                </TableCell>
                <TableCell className="technologies_grid_col_alarm">
                  {row.alarmCount}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

class PanelContainer extends Component {
  state = {
    status: "loading",
    probes: [],
  };
  userExists = (probes, probeName) => {
    return probes.filter((probe) => {
      return probe.prid === probeName;
    });
  };

  apiError = () => {
    this.setState({
      status: "failure",
    });
  };

  componentDidMount() {
    let groupIds = String(this.props.groupIds);
    let csId = String(this.props.csId);
    this.props.fetchTopTechnologiespoll(
      isValidParam(csId) ? (csId == "0" ? null : csId) : null,
      isValidParam(groupIds) ? groupIds : null
    );
  }

  componentWillUnmount() {
    this.props.pollTopTechnologiesStop();
  }

  componentWillReceiveProps(nextProps) {
    // if(!isEqual(nextProps.data,this.state.groups)){
    this.setState({
      probes: nextProps.data,
      status: "complete",
      maxCount:
        nextProps.data.length > 0
          ? sortColumns("alarmCount", "desc", nextProps.data)[0].alarmCount
          : 0,
    });
    //  }
  }

  render() {
    let view = (
      <Panel
        className={this.props.className}
        maxCount={this.state.maxCount}
        data={this.state.probes}
        history={this.props.history}
      />
    );
    //Technologies
    let title = Resource.get("Technologies");
    //No Technologies Found
    let noTechnologiesText = Resource.get("No Technologies Found");
    //View All
    let viewAllText = Resource.get("View All");
    let cardHeader = (
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <span className="overview_container_subheader">{title}</span>
        <div style={{ display: "flex", alignItems: "center" }}>
        <Tooltip title={viewAllText}>
          <Button variant="text"
           
           style={{height:'16px'}}
            onClick={() => {
              this.props.history.push("/technologies");
            }}
          >
            {viewAllText}
          </Button>
          </Tooltip>
          <span className="overview_header_seprator">|</span>
          <Tooltip title="Close">
          <IconButton 
            style={{ padding: 0 }}
            aria-label={"Close Technologies"}
            onClick={() => {
              this.props.handleCloseDialog("showTopTechnologies");
            }}
          >
          
            <Clear className="clearIcon"  role="figure"
            title="Close"/>
          </IconButton>
          </Tooltip>
        </div>
      </div>
    );
    if (
      this.state.status === "loading" &&
      !this.props.didTopTechnologiesInvalidate
    ) {
      view = (
        <div
          className="no_data_technologies_panel"
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress style={{ color: "#3272D9" }} />
        </div>
      );
    } else if (
      this.state.status === "failure" ||
      this.props.didTopTechnologiesInvalidate
    ) {
      view = (
        <div className="no_data_technologies_panel">
          <Error />
        </div>
      );
    } else if (
      this.state.status === "complete" &&
      this.state.probes.length == 0
    ) {
      view = (
        <div className="no_data_technologies_panel">
          <CenterInfo title={noTechnologiesText} />
        </div>
      );
    }
    return (
      <div
        className={this.props.className}
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        {cardHeader}
        {view}
        <div style={{ backgroundColor: "#d3d3d3" }}></div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchTopTechnologiespoll: (csId, groupIds) =>
      dispatch(fetchTopTechnologiespoll(csId, groupIds)),
    pollTopTechnologiesStop: () => dispatch(pollTopTechnologiesStop()),
  };
};
const mapStateToProps = (state) => {
  return {
    data: state.homeScreen.topTechnologies,
    isFetching: state.homeScreen.isFetching,
    didTopTechnologiesInvalidate: state.homeScreen.didTopTechnologiesInvalidate,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PanelContainer); //connect so that we can get a handle on history
