// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Remote monitoring (also known as agentless monitoring) requires a monitoring host. The monitoring host is responsible for collecting data from the remote device that you are monitoring.
  content["Remote monitoring (also known as agentless monitoring) requires a monitoring host. The monitoring host is responsible for collecting data from the remote device that you are monitoring."] =
  "O monitoramento remoto (também conhecido como monitoramento sem agente) requer um host de monitoramento. O host de monitoramento é responsável pela coleta de dados do dispositivo remoto que você está monitorando.";

  //Monitoring host server
  content["Monitoring host server"] =
  "Servidor do host de monitoramento";

  //Deploy a monitoring
  content["Deploy a monitoring"] =
  "Implantar um monitoramento";

  //Recommended minimum system requirements
  content["Recommended minimum system requirements"] =
  "Requisitos mínimos de sistema recomendados";

  //Docker monitoring requires an agent (aka Robot) to be installed on the Docker Engine server.
  content["Docker monitoring requires an agent (aka Robot) to be installed on the Docker Engine server."] =
  "O monitoramento do Docker precisa que um agente (também conhecido como robô) esteja instalado no servidor do mecanismo de Docker.";

  //IP address
  content["IP address"] =
  "Endereço IP";

  //Username
  content["Username"] =
  "Nome de usuário";

  //Password
  content["Password"] =
  "Senha";

  //Sudo Password (Linux only)
  content["Sudo Password (Linux only)"] =
  "Senha sudo (somente Linux)";

  //4 GB of available memory
  content["4 GB of available memory"] =
  "4 GB de memória disponível";

  //2 or more CPUs
  content["2 or more CPUs"] =
  "Duas ou mais CPUs";

  //10 GB of available disk space
  content["10 GB of available disk space"] =
  "10 GB de espaço disponível em disco";

  //Ports 48000 to 48025 open
  content["Ports 48000 to 48025 open"] =
  "Portas de 48000 a 48025 abertas";

  // END OF LOCALIZATION

export default content;
