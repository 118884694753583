// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Add
  content["Add"] =
  "追加";

  //Service Name
  content["Service Name"] =
  "サービス名";

  //Settings
  content["Settings"] =
  "設定";

  //PagerDuty
  content["PagerDuty"] =
  "PagerDuty";

  //CANCEL
  content["CANCEL"] =
  "キャンセル";

  //DELETE
  content["DELETE"] =
  "削除";

  //This action removes the PagerDuty integration
  content["This action removes the PagerDuty integration"] =
  "このアクションによって、PagerDuty 統合が削除されます";

  //help message
  content["Alarms will no longer be synchronized between CA Infrastructure Management and PagerDuty."] =
  "CA Infrastructure Management と PagerDuty の間で、アラームは同期されなくなります。";

  //Delete Integration?
  content["Delete integration?"] =
  "統合を削除しますか?";

  //Communication Error
  content["Communication Error"] =
  "通信エラー";

  //API Key message
  content["PagerDuty generated API key"] =
  "PagerDuty 生成 API キー";

  //Service Name message
  content["Service name"] =
  "サービス名";

  //help message
  content[" Create new PagerDuty incidents when Infrastructure Management generates alarms with the following severities:"] =
  "次の重大度で Infrastructure Management がアラームを生成する場合に、新しい PagerDuty インシデントを作成します:";

  //Trigger incident creation
  content["Trigger incident creation"] =
  "インシデント作成のトリガ";

  //Immediatley
  content["Immediatley"] =
  "すぐに実行";

  //After 5 minutes
  content["After 5 minutes"] =
  "5 分後";

  //After 10 minutes
  content["After 10 minutes"] =
  "10 分後";

  //After 15 minutes
  content["After 15 minutes"] =
  "15 分後";

  //After 30 minutes
  content["After 30 minutes"] =
  "30 分後";

  //help message
  content["Adding a delay may resolve \'flapping\' a situation where alarms open and auto-close in a short time frame."] =
  "遅延を追加すると、\'フラッピング\' が解決される可能性があります。これは、短時間にアラームが開き、自動的に閉じる状況です。";

  // Pager Duty Message
  content["To integrate PagerDuty with Infrastructure Management, you must first open an account and generate an API key for use in the configuration window. The following link describes how to generate an API key."] =
  "PagerDuty を Infrastructure Management と統合するには、最初にアカウントを開き、設定ウィンドウで使用する API キーを生成する必要があります。以下のリンクに、API キーの生成方法に関する説明があります。";

  //Help Message
  content["Help"] =
  "ヘルプ";

  //Note message
  content["Note: To enable this functionality, the UIM server must have access to the internet"] =
  "注: この機能を有効にするには、UIM サーバがインターネットにアクセスできることが必要です";

  //PagerDuty Integration
  content["PagerDuty Integration"] =
  "PagerDuty 統合";

  //Critical
  content["Critical"] =
  "クリティカル";

  //Major
  content["Major"] =
  "メジャー";

  //Minor
  content["Minor"] =
  "マイナー";

  //Warning
  content["Warning"] =
  "警告";

  //Info
  content["Info"] =
  "情報";

  //instruction message
  content["When an alarm is closed in Infrastructure Management, resolve the associated incident in PagerDuty."] =
  "Infrastructure Management でアラームがクローズされた場合に、PagerDuty で関連付けられているインシデントを解決します。";

  //SAVE
  content["SAVE"] =
  "保存";

  // END OF LOCALIZATION

export default content;
