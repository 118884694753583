// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Select what you would like to monitor...
  content["Select what you would like to monitor..."] =
  "Wählen Sie aus, was Sie überwachen möchten...";

  //For more information, click
  content["For more information, click"] =
  "Weitere Informationen finden Sie unter";

 
  //Setup Wizard
  content["Setup Wizard"] =
  "Setup-Assistent";

  //HELP
  content["HELP"] =
  "HILFE";

  //Help
  content["Help"] =
  "Hilfe";

  //ADD
  content["ADD"] =
  "HINZUFÜGEN";

  //CONFIG
  content["CONFIG"] =
  "KONFIG.";

  //Cancel
  content["Cancel"] =
  "Abbrechen";

  //PREVIOUS
  content["PREVIOUS"] =
  "ZURÜCK";

  //next
  content["next"] =
  "Weiter";

  //FINISH
  content["FINISH"] =
  "Beenden";

  // END OF LOCALIZATION

export default content;
