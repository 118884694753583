import React, {Component} from 'react'
import {ErrorIcon} from '../../ui-components/uim-components'
import{IconButton,Popover} from "@mineral/core"
import get from 'lodash/get'
import DeployErrorMessage from './../deployedMessage/DeployErrorMessage'
import './deployErrorIcon.less'
import Resource from './Resource'

class DeployListErrorIcon extends Component {
  state = {
    open: false,
  }
  focusIn = () => {
    let el = this.props.eGridCell.querySelector('button')
    el.focus()
  }
  getValue = () => {
    return null
  }
  handleTouchTap = (event) => {
    // This prevents ghost click.
    event.preventDefault()
    this.setState({
      open: true,
      anchorEl: event.currentTarget,
    })
  }
  handleRequestClose = () => {
    this.setState({open: false})
  }
  render() {
    const helperText = get(this.props, 'node.data.statusError')
    //No Errors
    const NoErrLabel = Resource.get('No Errors')
    //Errors
    const ErrLabel = Resource.get('Errors')
    let disabled = !helperText
    return (
      <div>
        <IconButton
          title={disabled ? NoErrLabel : ErrLabel }
          className='icon-button icon-button--error'
          disabled={disabled}
          style={{
            padding: null,
            position: null,
          }}
          onClick={this.handleTouchTap}>
          <ErrorIcon title={disabled ? NoErrLabel : ErrLabel} />
        </IconButton>
        <Popover
          className='deploy-error__popover'
          open={this.state.open}
          anchorEl={this.state.anchorEl}
          onClose={this.handleRequestClose}
          style={{
            overflowY: 'visibile',
          }}>
          <div className='popover__triangle'></div>
          <DeployErrorMessage helperText={helperText} />
        </Popover>
      </div>
    )
  }
}

export default DeployListErrorIcon