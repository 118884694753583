import isEqual from 'lodash/isEqual'
import capitalize from 'lodash/capitalize'
import { SET_ENTITIES, REMOVE_ENTITIES, ADD_ENTITIES } from './actionTypes'
import Resource from './Resource'

export function cleanItems(itemsInputed) {
  let max = 0
  const items = itemsInputed
    .map(item => {
      if(item && !item.alarmCountSummary){
        return item;
      }
      const alarmCountSummary = Object.keys(item.alarmCountSummary).map(function(key) {
        let name = key === 'information' ? 'info' : key
        let cnt = item.alarmCountSummary[key]
        max = max >= cnt ? max : cnt
        return { name: capitalize(name), cnt: cnt }
      })
      let maxAlarmSeverity = 0
      if (alarmCountSummary[0].cnt > 0) {
        maxAlarmSeverity = {
          //CRITICAL
          label: Resource.get('CRITICAL'),
          value: 5,
          cnt: alarmCountSummary[0].cnt,
        }
      } else if (alarmCountSummary[1].cnt > 0) {
        maxAlarmSeverity = {
          //MAJOR
          label: Resource.get('MAJOR'),
          value: 4,
          cnt: alarmCountSummary[1].cnt,
        }
      } else if (alarmCountSummary[2].cnt > 0) {
        maxAlarmSeverity = {
          //MINOR
          label:Resource.get('MINOR'),
          value: 3,
          cnt: alarmCountSummary[2].cnt,
        }
      } else if (alarmCountSummary[3].cnt > 0) {
        maxAlarmSeverity = {
          //WARNING
          label: Resource.get('WARNING'),
          value: 2,
          cnt: alarmCountSummary[3].cnt,
        }
      } else if (alarmCountSummary[4].cnt > 0) {
        maxAlarmSeverity = {
          // INFO
          label: Resource.get('INFO'),
          value: 1,
          cnt: alarmCountSummary[4].cnt,
        }
      } else {
        maxAlarmSeverity = {
          // NONE
          label: Resource.get('NONE'),
          value: 0,
          cnt: 0,
        }
      }
      var totalAlarmCount = alarmCountSummary.reduce(function(total, alarm) {
        return alarm.cnt + total
      }, 0)
      return Object.assign({}, item, {
        alarmCountSummary: alarmCountSummary,
        maxAlarmSeverity: maxAlarmSeverity,
        totalAlarmCount: totalAlarmCount,
      })
    })
    .map(item => Object.assign(item, { maxAlarmCount: max }))
  return items
}

const entities = (state = [], action) => {
  switch (action.type) {
    case SET_ENTITIES:
      let items = cleanItems(action.items)
      const updatedState = [...items]
      return isEqual(updatedState, state) ? state : updatedState

    case ADD_ENTITIES:
      let addItems = cleanItems(action.items)
      return state.concat(addItems)

    case REMOVE_ENTITIES:
      const deletedEntities = action.items
      const remainingEntities = state.filter(entity => {
        return !deletedEntities.includes(entity.id)
      })
      return remainingEntities
    default:
      return state
  }
}

export default entities
