// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Error help message
  content["Deployment failed. This may be due to an inability to communicate with the target device."] =
  "Se ha producido un error al ejecutar la implementación. Es posible que se deba a la imposibilidad de establecer comunicación con el dispositivo de destino.";

  //Robot deploy error message
  content["To troubleshoot, verify your network connection or firewall settings on the host."] =
  "Para solucionar el problema, compruebe la conexión de red o la configuración del cortafuegos en el host.";

  //FIX ISSUES
  content["FIX ISSUES"] =
  "SOLUCIONAR INCIDENCIAS";

  //Deploymentfailed message
  content["Deployment failed"] =
  "Error de implementación";

  // Confirmation check message
  content["Confirm your target device details and re-deploy."] =
  "Confirme los detalles del dispositivo de destino y vuelva a ejecutar la implementación.";

  //Deployment progress message
  content["Deployment is in progress."] =
  "La implementación está en curso.";

  //help message
  content["When your installation is complete, click NEXT to continue."] =
  "Cuando se complete la instalación, haga clic en SIGUIENTE para continuar.";

  //Deployment status message
  content["Deployment was successful"] =
  "La implementación se ha realizado correctamente.";

  //NEXT help message
  content["Click NEXT below to finish your monitoring setup."] =
  "Haga clic en SIGUIENTE para completar la configuración de la monitorización.";

  //A Message
  content["A Message"] =
  "Un mensaje";

  // You are great
  content["You are great."] =
  "Es increíble.";

  // END OF LOCALIZATION

export default content;
