import React, { useEffect, useRef, useState, useLayoutEffect } from "react";
import "./tableFilters.less";
import {
  MenuItem,
  Select,
  Button,
  TextField,
  IconButton,
  OutlinedInput,
  Menu,
  MenuList,
  Paper,
  Popover,
  Tooltip,
} from "@mineral/core";
import ActiveFilter from "./filterTypes/ActiveFilter";
import { useToggle } from "../../../utils/useToggle";
import { FilterIcon } from "../../ui-components/uim-components";
// import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import AddIcon from "@mui/icons-material/Add";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

import { isEqual } from "lodash";
import { FILTER_SEARCH_PLACEHOLDER } from "../../settings/portlets/slm/ResourceKeys";
import { getOverflowingElementsNumber } from "../../../utils/getOverflowingElementsNumber";
import moment from "moment";
import { options } from "numeral";

const TableFilters = ({
  searchResultsNumber = 0,
  currentFilters,
  defaultFilters = [],
  handleUpdateFilters,
currentQuickFilter = "",
}) => {
  const [activeFilters, setActiveFilters] = useState([]);
  const [quickFilter, setQuickFilter] = useState(currentQuickFilter);
  const [isCompact, toggleCompact] = useToggle(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [overflowingElementsNumber, setOverflowingElementsNumber] = useState(0);
  const [isChevronDisabled, toggleChevronDisabled] = useToggle(true);
  let previousFilterObject = useRef({});

  const usedFiltersIDs = activeFilters.map((user) => user.id);

  const filterData = defaultFilters.filter(
    (filter) => filter !== undefined
  );
  const allFilters = filterData.filter(
    (filter) => !usedFiltersIDs.includes(filter.id)
  );

  if (!isCompact && activeFilters.length === 0) toggleCompact();

  useEffect(() => {
    if (currentFilters) {
      const newFilters = currentFilters.filter(
        (currentFilter) =>
          !activeFilters.find(
            (activeFilter) =>
              activeFilter.id.toLowerCase() ===
              currentFilter.column.toLowerCase()
          )
      );
      const matchingFilters = [];
      newFilters.forEach((newFilter) => {
        const matchingFilter = allFilters.find(
          (filter) =>
            filter?.id.toLowerCase() === newFilter.column.toLowerCase()
        );
        if (matchingFilter) {
          switch (matchingFilter.sortingDataType) {
            case "string":
              matchingFilter.selectedOptions = [
                newFilter.operator.toUpperCase(),
              ];
              matchingFilter.extraData = matchingFilter.isDate
                ? moment(newFilter.value).format("MM/DD/YYYY")
                : newFilter.value;
              break;
            case "multiselect":
              matchingFilter.selectedOptions = newFilter.value.split(",");
              matchingFilter.isModified = true;
              break;
            case "boolean":
              matchingFilter.selectedOptions = [newFilter.value ? 1 : 0];
              break;
          
            default:
              break;
          }
          matchingFilters.push(matchingFilter);
        }
      });
      if (matchingFilters.length) {
        setActiveFilters([...activeFilters, ...matchingFilters]);
      }
    }
  }, [currentFilters]);

  const getFiltersObject = () => ({
    colFilters: [
      ...activeFilters
        .filter((activeFilter) => activeFilter.selectedOptions.length > 0)
        .map((activeFilter) => {
          switch (activeFilter.sortingDataType) {
            case "string":
              if (!activeFilter.extraData) return null;
              return {
                filterType:'text',
                filterColumn: activeFilter.id,
                filterOperator: activeFilter.selectedOptions[0],
                filterValue: activeFilter.isDate
                  ? getDateValue(activeFilter.extraData)
                  : activeFilter.extraData,
              };
            case "boolean":
              return {
                filterColumn: activeFilter.id,
                filterOperator: "EQUALS",
                filterValue: activeFilter.selectedOptions[0] === "true" ? 1 : 0,
              };
            case "multiselect":
              if (!activeFilter.isModified) return null;
              return {
                filterType:'set',
                filterColumn: activeFilter.id,
                filterOperator: "IN",
                filterValue: getMultiDiscoveredValue(
                  activeFilter.selectedOptions
                    .filter((option) => option !== "uncheckall")
                    .toString(),
                  activeFilter.id
                ),
                isSelectedAll:
                  activeFilter.selectedOptions.filter(
                    (option) => option !== "uncheckall"
                  ).length === activeFilter.options.length,
              };
            case "datetime":
              if (!activeFilter.isModified) return null;
              return {
                filterColumn: activeFilter.id,
                filterOperator: getDiscoveredOperator(
                  activeFilter.selectedOptions,
                  activeFilter.options
                ),
                filterValue: getDiscoveredValue(
                  activeFilter.selectedOptions,
                  activeFilter.options
                ),
                isSelectedAll:
                  activeFilter.selectedOptions.filter(
                    (option) => option !== "uncheckall"
                  ).length === activeFilter.options.length,
              };
            default:
              return null;
            // case 'selectbustype':
            // case 'selectbooleantype':
            //   //if (!activeFilter.extraData) return null
            //   return {
            //     column: activeFilter.id,
            //     operator: "IN",
            //     value: activeFilter.selectedOptions.filter(option => option !== "uncheckall").toString(),
            //     isSelectedAll: activeFilter.selectedOptions.filter(option => option !== "uncheckall").length === activeFilter.options.length

            //   }
          }
        })
        .filter(Boolean),
    ],
    quickFilter: quickFilter,
  });

  const getDiscoveredOperator = (selectedValues) => {
    if (selectedValues.includes("all")) {
      return "GT";
    }
    return "SW";
  };
  const getDateValue = (date) => {    
    return moment(date).valueOf();
  };
  const getMultiDiscoveredValue = (values, col) => {
    if (col === "Removed") return values === "" ? "Nothing" : values;
    else return values;
  };
  const getDiscoveredValue = (selectedValues) => {
    let final_time = "";
    // if (
    //   selectedValues.includes("uncheckall") ||
    //   selectedValues.includes("all")
    // ) {
    //   return "";
    // }
    if (selectedValues.includes("all")) {
      final_time = null;
    } else if (selectedValues.includes("uncheckall")) {
      final_time = 0;
    } else if (selectedValues.includes("< 1 year")) {
      final_time = moment().subtract(1, "y").valueOf();
    } else if (selectedValues.includes("< 6 months")) {
      final_time = moment().subtract(6, "M").valueOf();
    } else if (selectedValues.includes("< 3 months")) {
      final_time = moment().subtract(3, "M").valueOf();
    } else if (selectedValues.includes("< 1 month")) {
      final_time = moment().subtract(1, "M").valueOf();
    } else if (selectedValues.includes("< 1 week")) {
      final_time = moment().subtract(1, "w").valueOf();
    } else if (selectedValues.includes("< 2 days")) {
      final_time = moment().subtract(2, "d").valueOf();
    } else if (selectedValues.includes("< 1 day")) {
      final_time = moment().subtract(1, "d").valueOf();
    } else if (selectedValues.includes("< 10 hours")) {
      final_time = moment().subtract(10, "h").valueOf();
    } else if (selectedValues.includes("< 4 hours")) {
      final_time = moment().subtract(4, "h").valueOf();
    }
    return final_time !== null && final_time !== ""
      ? parseInt(final_time / 1000)
      : "";
  };

  const getCleanFilters = (filterObject) => {
    return {
      ...filterObject,
      colFilters: filterObject.colFilters.filter(
        (col) => delete col.isSelectedAll
      ),
    };
  };

  const getValidFilters = (filterObject) => {
    return {
      ...filterObject,
      colFilters: filterObject.colFilters
        .filter((col) => !col.isSelectedAll)
        .filter((col) => delete col.isSelectedAll),
    };
  };
  useEffect(() => {
    let newFilterObject = getFiltersObject();
    let filterObj = getCleanFilters(newFilterObject);
    if (isEqual(previousFilterObject.current, filterObj)) {
      return;
    } else if (Object.keys(previousFilterObject.current).length === 0) {
      previousFilterObject.current = filterObj;
      return;
    }
    previousFilterObject.current = filterObj;
    handleUpdateFilters(getValidFilters(newFilterObject));
  }, [quickFilter, activeFilters]);

  useEffect(() => {
    const newOverflowingElementsNumber = getOverflowingElementsNumber(
      ".filter",
      24
    );
    const updatedIsChevronDisabled =
      activeFilters.length === 0 || overflowingElementsNumber === 0;

    if (newOverflowingElementsNumber !== overflowingElementsNumber) {
      setOverflowingElementsNumber(newOverflowingElementsNumber);
    }
    //if (updatedIsChevronDisabled && !isCompact) toggleCompact()
    if (updatedIsChevronDisabled !== isChevronDisabled) toggleChevronDisabled();
    if (!updatedIsChevronDisabled && isCompact) toggleCompact();
  }, [quickFilter, activeFilters, overflowingElementsNumber]);

  let updateQuickFilterTimeout = null;
  const handleUpdateQuickFilter = (e) => {
    clearTimeout(updateQuickFilterTimeout);

    updateQuickFilterTimeout = setTimeout(() => {
      setQuickFilter(e?.target?.value);
    }, 750);
  };

  const handleMakeFilterActive = (id) => {
    let matchingFilterInActive = activeFilters.find(
      (filter) => filter.id.toLowerCase() === id.toLowerCase()
    );
    if (matchingFilterInActive) return;

    const matchingFilter = allFilters.find(
      (filter) => filter?.id.toLowerCase() === id.toLowerCase()
    );
    if (matchingFilter) setActiveFilters([...activeFilters, matchingFilter]);
  };

  const handleMakeFilterInactive = (id) => {
    setActiveFilters([...activeFilters.filter((filter) => filter?.id !== id)]);
  };

  const handleClearActiveFilters = () => {
    setActiveFilters([]);
  };

  const handleUpdateFilter = (filterToUpdate) => {
    let index = activeFilters.findIndex(
      (filter, index) => filter.id === filterToUpdate.id
    );
    if (index === -1) return;

    let updatedActiveFilters = activeFilters;
    updatedActiveFilters[index] = filterToUpdate;
    setActiveFilters([...updatedActiveFilters]);
  };

  const Children = allFilters.map((filter,index) => (
    <MenuItem component="div"
      role="button"
      className="filters-dropdown__menu-item"
      key={filter?.id + "options"}
      tabIndex={0} autoFocus={index===0?true:false}
      onClick={() => handleMakeFilterActive(filter?.id)}
    >
      {filter?.name}
    </MenuItem>
  ));

  const FiltersDropdown = (
    <div className="filters-dropdown-wrapper table-filters">
      <div className="filters-dropdown">
        <div className="filters__text-field-wrapper">
          <FilterIcon width={16} height={16} />
          <input id="first-content-element"
            placeholder={"Add Filter(s)"}
            onChange={handleUpdateQuickFilter}
            defaultValue={quickFilter}
            aria-label={"Add Filter(s)"}
          />
          <Tooltip title="Add filter">
          <button
            onClick={(event) => {
              setAnchorEl(
                event.currentTarget
              );
            }}
            aria-label="Add filter"
          >
            <AddIcon style={{ color: "white" }} />
          </button>
          </Tooltip>
        </div>

        <Popover
          // className="filters__multiselect"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          id=""
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Paper
            sx={{
              width: 230,
              maxHeight: 350,
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            <MenuList component="div" role="group">{Children}</MenuList>
          </Paper>
        </Popover>
      </div>
    </div>
  );

  const ActiveFilters = (
    <>
      {activeFilters.map((filter, index) => (
        <ActiveFilter
          key={filter.id}
          filterInitial={filter}
          handleUpdateFilter={handleUpdateFilter}
          handleMakeFilterInactive={handleMakeFilterInactive}
          isFirstLine={
            activeFilters.length - overflowingElementsNumber >= index + 1
          }
          hide={
            isCompact &&
            activeFilters.length - overflowingElementsNumber < index + 1
          }
        />
      ))}
    </>
  );

  const Chevron = (
    <div className="filters__toggle">
      <Tooltip
        title={
          !isChevronDisabled
            ? isCompact
              ? "Expand filters"
              : "Hide filters"
            : null
        }
      >
        <IconButton
          style={{ padding: 0 }}
          role="button"
          disabled={isChevronDisabled}
          onClick={toggleCompact}
          aria-label={!isChevronDisabled
            ? isCompact
              ? "Expand filters"
              : "Hide filters"
            : "Disabled Expand Filters/Hide Filters button"}
        >
          <ArrowRightIcon
            style={{
              cursor: "pointer",
              // color: isChevronDisabled ? "#8E99AB" : "#3272D9",
              fontSize: 28,
              transform: isCompact ? "rotate(0deg)" : "rotate(90deg)",
            }}
          />
        </IconButton>
      </Tooltip>
    </div>
  );

  const SearchResultsNumber = (
    <div className="filters__search-results" role="alert" aria-live="assertive">
      {searchResultsNumber} Results
    </div>
  );

  const ClearButton = (hidden, isStatic) => (
    <button
      className="filters-active__clear"
      disabled={hidden}
      style={
        hidden
          ? {
              opacity: 0,
              marginRight: isStatic ? 16 : 0,
              marginTop: isStatic ? 4 : 0,
            }
          : { marginRight: isStatic ? 16 : 0, marginTop: isStatic ? 4 : 0 }
      }
      onClick={handleClearActiveFilters}
    >
      Clear All
    </button>
  );
  const ClearButton2 = (hidden) => (
    <Button
      className="filters-active__clear"
      disabled={hidden}
      style={hidden ? { opacity: 0 } : {}}
      variant="text"
      onClick={handleClearActiveFilters}
    >
      Clear
    </Button>
  );
  return (
    <div className="filters__wrapper">
      {Chevron}
      <div
        className={`filters ${
          isCompact ? "filters--compact" : "filters--not-compact"
        }`}
      >
        {FiltersDropdown}
       {SearchResultsNumber}
        {ActiveFilters}
        {(!isCompact && activeFilters.length > 0) && ClearButton(!(!isCompact && activeFilters.length > 0), false)}
      </div>
      {!(!isCompact || overflowingElementsNumber < 1) && 
      <button
        className="filters__badge"
        onClick={toggleCompact}
        style={
          !isCompact || overflowingElementsNumber < 1
            ? { opacity: 0, marginRight: 8, marginTop: 4 }
            : { marginRight: 8, marginTop: 4 }
        }
        disabled={!isCompact || overflowingElementsNumber < 1}
      >
        +{overflowingElementsNumber} more
      </button>}
      {(isCompact && activeFilters.length > 0) && ClearButton(!(isCompact && activeFilters.length > 0), true)}
    </div>
  );
};

export default TableFilters;