import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import entityTypes from "./../../api/entityTypes";
import { AlarmsIcon, Grid, RobotIcon, ErrorIcon as Information } from "../ui-components/uim-components";
// import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import moment from "moment";
import numeral from "numeral";
import each from "lodash/each";
import get from "lodash/get";
import cloneDeep from "lodash/cloneDeep";
import DetailPopUp from "./../groups/detailPopUp/DetailPopUp";
import SeverityIcon from "./../icons/SeverityIcon";
import SeverityBadge from "../alarms/SeverityBadges";
import AlarmIcon from "./../icons/AlarmIcon";
import "./table.less";
import { sortColumns } from "../../utils/tableUtils";
import AclTypes from "./../../api/aclTypes";
import rowSelectionCache from "../../utils/rowSelectionCache";
import Resource from "./Resource";
import KeyIcon from "@material-ui/icons/VpnKey";
import MaintenanceIcon from "./../icons/MaintenanceIcon";
import InventoryInfoIcon from "../icons/InventoryInfoIcon";
import isEqual from "lodash/isEqual";
import alarms from "../../api/alarms/alarms";
import { requestRobotsForUpgrade } from "../../api/robots/actions";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import debounce from "lodash/debounce";
import { Box, CircularProgress, IconButton } from "@mineral/core"
import AccessibleTooltip from "../common/AccessibleTooltip";
import NameColumnCellRenderer from './NameColumnCellRenderer'

import { defaultFiltersActiveObjects } from './filter/DefaultFilters'
import TableFilters from './filter/TableFilters'
import { useNextMonthDisabled } from "@mui/x-date-pickers/internals/hooks/date-helpers-hooks";
let tabForward;
let tabBackward;
function getAallFocussableElementsOf(el) {
  return Array.from(
    el.querySelectorAll(
      'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
    )
  ).filter((el) => {
    return el.tabIndex !== -1;
  });
}
const alarmSevMap = { Info: 1, Warning: 2, Minor: 3, Major: 4, Critical: 5 };
const severityLabels = [
  "clear",
  "info",
  "warning",
  "minor",
  "major",
  "critical",
];

const HEADERHEIGHT = 65;
const FOOTERHEIGHT = 24;
const NAVIGATIONHEIGHT = 48;

class Table extends Component {
  constructor(props) {
    super(props);
    this.pageLimit = 15;
    this.numPages = 0;
    this.groupPage = 0;
    this.updateRowSelectionCache = true;
    this.page_start_index = 0;
    this.state = {
      currentColumns: [],
      selectedColumns: [],
      loadingTableData: false,
      savedColumnNames:
        !(this.props.location.pathname.search("/card") > -1)
          ? this.props.savedColsList
          : this.props.savedColumns,
      sortedData: this.props.data,
      pageNumber: 0,
      showDialog: false,
      device: null,
      suppressGridUpdate: false,
      tooltipShowDelay: 0,
      overlayNoRowsDisplay:
        '<div><div class="nohubs__contentImg"></div><span style="color: #333333;font-family: Roboto;font-size: 1.25rem;">No Data found. Try different filter options.</span></div>',
      isSort_Filter: false,
      rolesList: [],
      probeList: [],
      selectedDevices: null,
      typeSelected: null,
      filterIcon: true,
    };
    this.hideCheckboxes = false;
    if (this.props.inventorySearch) {
      this.hideCheckboxes = false;
    } else if (this.props.acl.acl === AclTypes.OPERATOR) {
      this.hideCheckboxes = true;
    }
    this.prevIds = null;
    this.timeOutColMoved = null;
  }
  addAlarmIconToData = (model) => {
    var item = model.data;
    if (!item) {
      return <div></div>;
    }
    let level = 0;

    let currentLevel =
      item.maxAlarmSeverity && item.maxAlarmSeverity.value
        ? item.maxAlarmSeverity.value
        : item.highestAlarmSeverity
          ? item.highestAlarmSeverity
          : 0;
    if (currentLevel) level = currentLevel;
    if (level) {
      level = level == -1 ? 0 : level;
    }
    return (
      <div className="alarm__icon-link" style={{ margin: '8px' }}>
        {item.maintanceActive && item.maintanceActive == true ? (
          <MaintenanceIcon
            style={{ height: "18px", width: "20px", marginTop: "-2px" }}
            className="nav-icon"
          />
        ) : (
          <IconButton style={{ padding: '0px' }}>
            {model.column.actualWidth > 99 ? (
              <SeverityBadge level={level} />
            ) : (
              <SeverityIcon level={level} class="grid__icon" />
            )}
          </IconButton>
        )}
      </div>
    );
  };
  addInformationIcon = () => {
    return (
      <div className="information__icon--dialog">
        <InventoryInfoIcon title="Information" />
      </div>
    );
  };

  async componentDidMount() {
    // ***IMPORTANT*** The list needs to be populated before setting Columns
    const response = await this.props.getLookupList("roles");
    const roles = await response.data;

    const response2 = await this.props.getLookupList("monitoringprobes");
    const probes = await response2.data;

    this.setState({ rolesList: roles });
    this.setState({ probeList: probes });
    this.setState({ currentColumns: this.getColDefs(this.props) });
    rowSelectionCache.clear();
    window.addEventListener("mouseover", (e) => this.mouseOverClassAddition(e));
    window.addEventListener("mouseout", (e) => this.mouseOutClassDeletion(e));
    window.addEventListener("beforeunload", this.componentCleanup);
    this.autoSizeColumns();
  }

  componentDidUpdate(prevProps, prevState) {
    // if (prevProps.columnDefs !== this.props.columnDefs) {
    //}
    if (
      this.api &&
      this.api.rowModel?.rowsToDisplay &&
      prevProps.visibleDataCount !== this.api.rowModel?.rowsToDisplay?.length &&
      this.props.handleColumnFilterChange
    ) {
      this.props.handleColumnFilterChange(
        this.api.rowModel.rowsToDisplay?.length
      );
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      sortedData: nextProps.data,
      pageNumber: 0,
    });

    if (
      this.props.columnDefs !== nextProps.columnDefs ||
      !isEqual(this.props.savedColsList, nextProps.savedColsList) ||
      !isEqual(this.props.savedColumns, nextProps.savedColumns)
    ) {
      this.setState({
        currentColumns: this.getColDefs(nextProps),
      });
      this.autoSizeColumns();
    }
    if (this.props.rowModelType === "infinite" && this.api) {
      // if (!isEqual(this.props.quickFilter, nextProps.quickFilter)) {
      //   this.api.showLoadingOverlay();
      // }
      if (nextProps.computerSystemsIsFetching) {
        this.setState({loadingTableData: true})
      }
      else if (!nextProps.computerSystemsIsFetching && nextProps.data.length < 1) {
        this.updateData(
          nextProps.data,
          nextProps.data?.length,
          this.paramsInfo
        );
        this.api.showNoRowsOverlay();
        this.setState({loadingTableData: false})
      }
      else if (
        nextProps.refreshGridData != undefined &&
        nextProps.computerSystemsItems
      ) {
        if (nextProps.refreshGridData) {
          this.paramsInfo = null;
          this.setGridDataSource(
            nextProps.computerSystemsItems.items,
            nextProps.computerSystemsItems.total
          );
          this.setState({loadingTableData: false})
        } else if (!nextProps.refreshGridData) {
          this.updateData(
            nextProps.computerSystemsItems.items,
            nextProps.computerSystemsItems.total,
            this.paramsInfo
          );
          this.setState({loadingTableData: false})
        }
      }
    }
  }

  componentWillUnmount() {
    this.componentCleanup(true);
  }

  componentCleanup = (onactualUnmount) => {
    rowSelectionCache.clear();
    if (this.props.handleCsRowsSelected) {
      const ids = rowSelectionCache.getData();
      this.props.handleCsRowsSelected(ids);
    }
    window.removeEventListener("beforeunload", this.componentCleanup);
    if (!onactualUnmount) {
      let mountNode = React.findDOMNode(this.refs.viewref);
      if (mountNode) {
        let unmount = React.unmountComponentAtNode(mountNode);
      }
    }
  };

  getSelectedColumn = (savedCols) => {
    let columns = this.getCustomColumns();
    let selectedColumns = [];
    let storedColumnList = savedCols;
    if (storedColumnList) {
      if (storedColumnList.length == 0) {
        storedColumnList.push("Name");
        storedColumnList.push("Device Type");
        storedColumnList.push("OS Name");
        storedColumnList.push("Origin");
        storedColumnList.push("IP Address");
        storedColumnList.push("Monitored By");
        storedColumnList.push("Bus Type");
        storedColumnList.push("Discovered");
      } //dp039841
      storedColumnList.forEach((data) => {
        selectedColumns.push(columns.find((col) => col.headerId == data));
      });
    }
    // this.setState({
    // csCols:[...this.getCSColumns(),...selectedColumns],
    // selectedColumns: [...selectedColumns]
    // })
    return selectedColumns;
  };

  updateType = (model) => {
    var item = model.data;
    let type = item.type;
    if (type && type.length > 0) {
      return (
        <div className="alarm__icon-link">
          {type[0] + type.substring(1).toLowerCase()}
        </div>
      );
    } else return <div></div>;
  };

  getGroupColumns() {
    const groupCols = [
      {
        headerName: "Alarm Count",
        field: "totalAlarms",
        cellRendererFramework: (params) => {
          return (
            <div style={{ width: "90px", textAlign: "center" }}>
              {params.value}
            </div>
          );
        },
        headerTooltip: "Total Alarm Count",
        // tooltipField: "maxAlarmSeverity.label",
        tooltipValueGetter: (params) =>
          params.data
            ? params.data.totalAlarms
              ? params.data.totalAlarms
              : params.data.totalAlarmCount
            : null,
        suppressMovable: false,
        width: 115,
        minWidth: 115,
        maxWidth: 115,
        sortable: this.props.rowModelType !== "infinite",
        suppressMenu: true,
        suppressSizeToFit: false,
        resizable: true,
        comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
          const aAlarmCount = nodeA.data.totalAlarms
            ? nodeA.data.totalAlarms
            : nodeA.data.totalAlarmCount;
          const bAlarmCount = nodeB.data.totalAlarms
            ? nodeB.data.totalAlarms
            : nodeB.data.totalAlarmCount;

          return aAlarmCount - bAlarmCount;
        },
        filter: "agNumberColumnFilter",
        filterParams: {
          suppressAndOrCondition: true,
          filterOptions: [
            "greaterThan",
            "greaterThanOrEqual",
            "lessThan",
            "lessThanOrEqual",
          ],
          clearButton: true,
        },
        valueGetter: (params) => {
          return params.data?.totalAlarms
            ? params.data.totalAlarms
            : params.data?.totalAlarmCount;
        },
        headerComponentParams: {
          // Max Alarm Severity
          // icon: <AlarmsIcon title={Resource.get("Max Alarm Severity")} />,
          enableMenu: false,
        },
        suppressNavigable: true
      },
      {
        headerName: "Max Severity",
        field: "maxAlarmSeverity",
        cellRendererFramework: this.addAlarmIconToData,
        //Highest Alarm Severity Level and Total Alarm Count
        headerTooltip: Resource.get("Highest Alarm Severity Level"),
        // tooltipField: "maxAlarmSeverity.label",
        tooltipValueGetter: (params) =>
          params.data?.maxAlarmSeverity?.label
            ? params.data.maxAlarmSeverity.label
            : "",
        suppressMovable: false,
        suppressSizeToFit: true,
        resizable: true,
        sortable: true,
        filter: "customSetFilter",
        filterParams: {
          values: ["Info", "Warning", "Minor", "Major", "Critical", "None"],
          valueToCompare: function (data, node) {
            return data.maxAlarmSeverity.label;
          },
        },
        comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
          const firstValue = severityLabels.indexOf(valueA.toLowerCase());
          const secondValue = severityLabels.indexOf(valueB.toLowerCase());
          if (firstValue === secondValue) {
            return 0;
          } else {
            return firstValue > secondValue ? 1 : -1;
          }
        },
        valueGetter: (params) => {
          return params.data?.maxAlarmSeverity?.label
            ? params.data.maxAlarmSeverity.label
            : "";
        },
        width: 130,
        minWidth: 50,
        headerComponentParams: (params) => {
          if (params.column.actualWidth > 110) {
            return {
              enableMenu: false,
            };
          } else {
            return {
              enableMenu: false,
              icon: <AlarmsIcon title={Resource.get("Max Alarm Severity")} />,
            };
          }
        },
        suppressNavigable: true
      },
      // {
      //   headerName: "",
      //   field: " ",
      //   cellRendererFramework: this.addInformationIcon,

      //   minWidth: 50,
      //   width: 50,
      //   maxWidth: 50,
      //   suppressMovable: true,
      //   suppressSizeToFit:true,
      //   sortable: true,

      // },

      {
        headerName: Resource.get("Name"), //Name
        field: "name",
        tooltipField: "name",
        minWidth: 80,
        sortable: true,
        cellRendererFramework: NameColumnCellRenderer,
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains", "startsWith", "endsWith", "equals"],
          suppressAndOrCondition: true,
          newRowsAction: "keep",
          // clearButton: true,
          closeOnApply: true,
          buttons: ["reset", "cancel", "apply"],
          // debounceMs: 200
        },
        headerComponentParams: {
          enableMenu: false,
        },
        suppressNavigable: false
      },
      {
        //Type
        headerName: Resource.get("Type"),
        field: "type",
        tooltipField: "type",
        cellRendererFramework: this.updateType,
        minWidth: 100,
        //width: 50,
        sortable: true,
        resizable: true,
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains", "startsWith", "endsWith", "equals"],
          suppressAndOrCondition: true,
          newRowsAction: "keep",
          // clearButton: true,
          closeOnApply: true,
          buttons: ["reset", "cancel", "apply"],
          // debounceMs: 200
        },
        headerComponentParams: {
          enableMenu: false,
        },
        suppressNavigable: true
      },

      {
        //Elements
        headerName: Resource.get("Elements"),
        field: "memberCount",
        tooltipField: "memberCount",
        minWidth: 80,
        sortable: true,
        filter: "agNumberColumnFilter",
        filterParams: {
          suppressAndOrCondition: true,
          filterOptions: [
            "greaterThan",
            "greaterThanOrEqual",
            "lessThan",
            "lessThanOrEqual",
          ],
          clearButton: true,
        },
        headerComponentParams: {
          enableMenu: false,
        },
        suppressNavigable: true
      },
    ];

    return groupCols;
  }

  getCustomColumns() {
    //Name
    const NAME = Resource.get("Name");

    //alias
    const ALIAS = Resource.get("Alias");

    //devicetype
    const DEVICE = Resource.get("Device Type");
    //caption
    const CAPTION = Resource.get("Caption");
    //desc
    const DESCRIPTION = "Description";
    //dedicated
    const DEDICATED = Resource.get("Dedicated");
    //origin
    const ORIGIN = Resource.get("Origin");
    //latestorigin
    const LATESTORIGIN = Resource.get("Latest Origin");
    //mac
    const MAC = Resource.get("MAC Address");
    //monitor
    const MONITOR_BY = Resource.get("Monitored By");
    //robot
    const HAS_ROBOT = Resource.get("Bus Type");
    //csid
    const CS_ID = Resource.get("CS Id");
    //cskey
    const CS_KEY = Resource.get("CS Key");
    //discover
    const DISCOVERED = Resource.get("Discovered");
    //changetime
    const CHANGED = Resource.get("Last Updated");
    //deleted
    const DELETED = Resource.get("Removed");
    let columns = [
      {
        //Name
        headerId: "Name",
        headerName: NAME,
        headerClass: "center-header-vertically",
        cellClass: "center-cell-vertically",
        headerTooltip: NAME,
        field: "name",
        //tooltipField: "name",
        minWidth: 180,
        sortable: true,
        resizable: true,
        suppressMovable: false,
        cellRendererFramework: (params) => {
          return (
            <>

              <button
                className="custom-transparent-btn"
                onClick={() => { console.log("Global table row click will get invoked") }}

              >
                {/*<span style={{color:"#3272D9"}}> {this.props.data && this.props.data.name?this.props.data.name:""}</span>*/}
                <AccessibleTooltip title={params.data?.name ? params.data.name : ""}>
                  <Box component={'span'} style={{ overflow: 'hidden', color: "#3272D9", whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                    {params.data?.name ? params.data.name : ""}
                  </Box>
                </AccessibleTooltip>
              </button>
            </>
          );
        },
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains", "startsWith", "endsWith", "equals"],
          suppressAndOrCondition: true,
          newRowsAction: "keep",
          // clearButton: true,
          closeOnApply: true,
          buttons: ["reset", "cancel", "apply"],
          // debounceMs: 200
        },

        headerComponentParams: {
          enableMenu: false, //true
        },

      },
      {
        //Alias
        headerId: "Alias",
        headerName: ALIAS,
        headerTooltip: ALIAS,
        field: "alias",
        tooltipField: "alias",
        sortable: true,
        resizable: true,
        suppressMovable: false,
        minWidth: 150,
        filter: "agTextColumnFilter",
        cellRendererFramework: (params) => {
          return (
            <p
              style={{
                margin: 0,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {params.data?.alias ? params.data.alias : ""}
            </p>
          );
        },
        filterParams: {
          filterOptions: ["contains", "startsWith", "endsWith", "equals"],
          suppressAndOrCondition: true,
          newRowsAction: "keep",
          closeOnApply: true,
          buttons: ["reset", "cancel", "apply"],
        },
        headerComponentParams: {
          enableMenu: false, //true
        },
        cellRendererFramework: (model) => {
          return model.data && model.data.alias ? (
            <div className="ag-cell-value">
              <span>{model.data.alias}</span>
            </div>
          ) : (
            ""
          );
        },
        suppressNavigable: true
      },
      {
        //Device Type
        headerId: "Device Type",
        headerName: DEVICE,
        headerTooltip: DEVICE,
        field: "role",
        tooltipField: "role",
        minWidth: 150,
        sortable: true,
        resizable: true,
        suppressMovable: false,
        filter: "agTextColumnFilter",
        cellRendererFramework: (params) => {
          return (
            <p
              style={{
                margin: 0,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {params.data?.role ? params.data.role : ""}
            </p>
          );
        },
        filterParams: {
          filterOptions: ["contains", "startsWith", "endsWith", "equals"],
          suppressAndOrCondition: true,
          newRowsAction: "keep",
          closeOnApply: true,
          buttons: ["reset", "cancel", "apply"],
        },
        headerComponentParams: {
          enableMenu: false, //true
        },
        suppressNavigable: true
      },
      {
        //Caption
        headerId: "Caption",
        headerName: CAPTION,
        field: "attributes.caption",
        headerTooltip: CAPTION,
        tooltipField: "attributes.caption",
        minWidth: 150,
        filter: "agTextColumnFilter",
        sortable: true,
        resizable: true,
        suppressMovable: false,
        cellRendererFramework: (params) => {
          return (
            <p
              style={{
                margin: 0,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {params.data?.attributes?.caption
                ? params.data.attributes.caption
                : ""}
            </p>
          );
        },
        filterParams: {
          filterOptions: ["contains", "startsWith", "endsWith", "equals"],
          suppressAndOrCondition: true,
          newRowsAction: "keep",
          closeOnApply: true,
          buttons: ["reset", "cancel", "apply"],
        },
        headerComponentParams: {
          enableMenu: false, //true
        },
        suppressNavigable: true
      },
      {
        //csDescription
        headerId: "Description",
        headerName: DESCRIPTION,
        headerTooltip: DESCRIPTION,
        field: "attributes.description",
        tooltipField: "attributes.description",
        minWidth: 150,
        sortable: true,
        resizable: true,
        suppressMovable: false,
        filter: "agTextColumnFilter",
        cellRendererFramework: (params) => {
          return (
            <p
              style={{
                margin: 0,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {params.data?.attributes?.description
                ? params.data.attributes.description
                : ""}
            </p>
          );
        },
        filterParams: {
          filterOptions: ["contains", "startsWith", "endsWith", "equals"],
          suppressAndOrCondition: true,
          newRowsAction: "keep",
          closeOnApply: true,
          buttons: ["reset", "cancel", "apply"],
        },
        headerComponentParams: {
          enableMenu: false, //true
        },
        suppressNavigable: true
      },

      {
        //Dedicated
        headerId: "Dedicated",
        headerName: DEDICATED,
        headerTooltip: DEDICATED,
        field: "attributes.dedicated",
        tooltipField: "attributes.dedicated",
        minWidth: 150,
        sortable: true,
        resizable: true,
        suppressMovable: false,
        filter: "agTextColumnFilter",
        cellRendererFramework: (params) => {
          return (
            <p
              style={{
                margin: 0,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {params.data?.attributes?.dedicated
                ? params.data.attributes.dedicated
                : ""}
            </p>
          );
        },
        filterParams: {
          filterOptions: ["contains", "startsWith", "endsWith", "equals"],
          suppressAndOrCondition: true,
          newRowsAction: "keep",
          closeOnApply: true,
          buttons: ["reset", "cancel", "apply"],
        },
        headerComponentParams: {
          enableMenu: false, //true
        },
        suppressNavigable: true
      },
      {
        //OS TYPE
        headerId: "OS Type",
        headerName: Resource.get("OS Type"),
        //Operating system type
        headerTooltip: Resource.get("Operating System Type"),
        field: "osType",
        tooltipField: "osType",
        minWidth: 130,
        filter: "agTextColumnFilter",
        sortable: true,
        resizable: true,
        suppressMovable: false,
        cellRendererFramework: (params) => {
          return (
            <p
              style={{
                margin: 0,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {params.data?.osType ? params.data.osType : ""}
            </p>
          );
        },
        filterParams: {
          filterOptions: ["contains", "startsWith", "endsWith", "equals"],
          suppressAndOrCondition: true,
          newRowsAction: "keep",
          closeOnApply: true,
          buttons: ["reset", "cancel", "apply"],
        },
        headerComponentParams: {
          enableMenu: false, //true
        },
        suppressNavigable: true
      },

      {
        //osname
        headerId: "OS Name",
        headerName: Resource.get("OS Name"),
        //Operating system name
        headerTooltip: Resource.get("Operating System Name"),
        field: "osName",
        tooltipField: "osName",
        minWidth: 140,
        sortable: true,
        resizable: true,
        suppressMovable: false,
        filter: "agTextColumnFilter",
        cellRendererFramework: (params) => {
          return (
            <p
              style={{
                margin: 0,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {params.data?.osName ? params.data.osName : ""}
            </p>
          );
        },
        filterParams: {
          filterOptions: ["contains", "startsWith", "endsWith", "equals"],
          suppressAndOrCondition: true,
          newRowsAction: "keep",
          closeOnApply: true,
          buttons: ["reset", "cancel", "apply"],
        },
        headerComponentParams: {
          enableMenu: false, //true
        },
        cellRendererFramework: (model) => {
          return model.data && model.data.osName ? (
            <div className="ag-cell-value">
              <span>{model.data.osName}</span>
            </div>
          ) : (
            ""
          );
        },
        suppressNavigable: true
      },
      {
        //osversion
        headerId: "OS Version",
        headerName: Resource.get("OS Version"),
        //Operating system version
        headerTooltip: Resource.get("Operating System Version"),
        field: "osVersion",
        tooltipField: "osVersion",
        minWidth: 150,
        filter: "agTextColumnFilter",
        sortable: true,
        resizable: true,
        suppressMovable: false,
        cellRenderer: (params) => {
          if (params.data && params.data.osVersion != null)
            return params.data.osVersion;
          else return "";
        },

        filterParams: {
          filterOptions: ["contains", "startsWith", "endsWith", "equals"],
          suppressAndOrCondition: true,
          newRowsAction: "keep",
          closeOnApply: true,
          buttons: ["reset", "cancel", "apply"],
        },
        headerComponentParams: {
          enableMenu: false, //true
        },
        suppressNavigable: true
      },
      {
        //osdesc
        headerId: "OS Description",
        headerName: Resource.get("OS Description"),
        //Operating system desc
        headerTooltip: Resource.get("Operating System Description"),
        field: "osDescription",
        tooltipField: "osDescription",
        minWidth: 175,
        filter: "agTextColumnFilter",
        sortable: true,
        resizable: true,
        suppressMovable: false,
        cellRenderer: (params) => {
          if (params.data && params.data.osDescription != null)
            return params.data.osDescription;
          else return "";
        },
        filterParams: {
          filterOptions: ["contains", "startsWith", "endsWith", "equals"],
          suppressAndOrCondition: true,
          newRowsAction: "keep",
          closeOnApply: true,
          buttons: ["reset", "cancel", "apply"],
        },
        headerComponentParams: {
          enableMenu: false, //true
        },
        suppressNavigable: true
      },
      {
        //Origin
        headerId: "Origin",
        headerName: ORIGIN,
        headerTooltip: ORIGIN,
        field: "attributes.origin",
        tooltipField: "attributes.origin",
        minWidth: 140,
        sortable: true,
        resizable: true,
        suppressMovable: false,
        filter: "agTextColumnFilter",
        cellRendererFramework: (params) => {
          return (
            <p
              style={{
                margin: 0,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {params.data?.attributes?.origin
                ? params.data.attributes.origin
                : ""}
            </p>
          );
        },
        filterParams: {
          filterOptions: ["contains", "startsWith", "endsWith", "equals"],
          suppressAndOrCondition: true,
          newRowsAction: "keep",
          closeOnApply: true,
          buttons: ["reset", "cancel", "apply"],
        },
        headerComponentParams: {
          enableMenu: false, //true
        },
        cellRendererFramework: (model) => {
          return model.data &&
            model.data.attributes &&
            model.data.attributes.origin ? (
            <div className="ag-cell-value">
              <span>{model.data.attributes.origin}</span>
            </div>
          ) : (
            ""
          );
        },
        suppressNavigable: true

      },

      {
        //Latest Origin
        headerId: "Latest Origin",
        headerName: LATESTORIGIN,
        headerTooltip: LATESTORIGIN,
        field: "attributes.latestOrigin",
        tooltipField: "attributes.latestOrigin",
        minWidth: 140,
        sortable: true,
        resizable: true,
        suppressMovable: false,
        filter: "agTextColumnFilter",
        cellRendererFramework: (params) => {
          return (
            <p
              style={{
                margin: 0,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {params.data?.attributes?.latestOrigin
                ? params.data.attributes.latestOrigin
                : ""}
            </p>
          );
        },
        filterParams: {
          filterOptions: ["contains", "startsWith", "endsWith", "equals"],
          suppressAndOrCondition: true,
          newRowsAction: "keep",
          closeOnApply: true,
          buttons: ["reset", "cancel", "apply"],
        },
        headerComponentParams: {
          enableMenu: false, //true
        },
        cellRendererFramework: (model) => {
          return model.data &&
            model.data.attributes &&
            model.data.attributes.latestOrigin ? (
            <div className="ag-cell-value">
              <span>{model.data.attributes.latestOrigin}</span>
            </div>
          ) : (
            ""
          );
        },
        suppressNavigable: true

      },
      {
        //macaddress
        headerId: "MAC Address",
        headerName: MAC,
        headerTooltip: MAC,
        field: "attributes.mac",
        tooltipField: "attributes.mac",
        minWidth: 165,
        sortable: true,
        resizable: true,
        suppressMovable: false,
        filter: "agTextColumnFilter",
        cellRendererFramework: (params) => {
          return (
            <p
              style={{
                margin: 0,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {params.data?.attributes?.mac ? params.data.attributes.mac : ""}
            </p>
          );
        },
        filterParams: {
          filterOptions: ["contains", "startsWith", "endsWith", "equals"],
          suppressAndOrCondition: true,
          newRowsAction: "keep",
          closeOnApply: true,
          buttons: ["reset", "cancel", "apply"],
        },
        headerComponentParams: {
          enableMenu: false, //true
        },
        cellRendererFramework: (model) => {
          return model.data &&
            model.data.attributes &&
            model.data.attributes.mac ? (
            <div className="ag-cell-value">
              <span>{model.data.attributes.mac}</span>
            </div>
          ) : (
            ""
          );
        },
        suppressNavigable: true
      },
      {
        //tag1
        headerId: "User Tag 1",
        headerName: Resource.get("User Tag 1"),
        //usertag1
        headerTooltip: Resource.get("UserTag 1"),
        field: "attributes.user_tag_1",
        tooltipField: "attributes.user_tag_1",
        minWidth: 155,
        sortable: true,
        resizable: true,
        suppressMovable: false,
        filter: "agTextColumnFilter",
        cellRendererFramework: (params) => {
          return (
            <p
              style={{
                margin: 0,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {params.data?.attributes?.user_tag_1
                ? params.data.attributes.user_tag_1
                : ""}
            </p>
          );
        },
        filterParams: {
          filterOptions: ["contains", "startsWith", "endsWith", "equals"],
          suppressAndOrCondition: true,
          newRowsAction: "keep",
          closeOnApply: true,
          buttons: ["reset", "cancel", "apply"],
        },
        headerComponentParams: {
          enableMenu: false, //true
        },
        suppressNavigable: true
      },
      {
        //tag2
        headerId: "User Tag 2",
        headerName: Resource.get("User Tag 2"),
        //usertag2
        headerTooltip: Resource.get("UserTag 2"),
        field: "attributes.user_tag_2",
        tooltipField: "attributes.user_tag_2",
        minWidth: 150,
        sortable: true,
        resizable: true,
        suppressMovable: false,
        filter: "agTextColumnFilter",
        cellRendererFramework: (params) => {
          return (
            <p
              style={{
                margin: 0,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {params.data?.attributes?.user_tag_2
                ? params.data.attributes.user_tag_2
                : ""}
            </p>
          );
        },
        filterParams: {
          filterOptions: ["contains", "startsWith", "endsWith", "equals"],
          suppressAndOrCondition: true,
          newRowsAction: "keep",
          closeOnApply: true,
          buttons: ["reset", "cancel", "apply"],
        },
        headerComponentParams: {
          enableMenu: false, //true
        },
        suppressNavigable: true
      },

      {
        //IP address
        headerId: "IP Address",
        headerName: Resource.get("IP Address"),
        //ipaddr
        headerTooltip: Resource.get("IP address"),
        field: "ipAddresses",
        tooltipField: "ipAddresses",
        minWidth: 150,
        sortable: true,
        resizable: true,
        suppressMovable: false,
        filter: "agTextColumnFilter",
        cellRendererFramework: (params) => {
          return (
            <p
              style={{
                margin: 0,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {params.value ? params.value : ""}
            </p>
          );
        },
        filterParams: {
          filterOptions: ["contains", "startsWith", "endsWith", "equals"],
          suppressAndOrCondition: true,
          newRowsAction: "keep",
          closeOnApply: true,
          buttons: ["reset", "cancel", "apply"],
        },
        headerComponentParams: {
          enableMenu: false, //true
        },
        valueGetter: (model) => {
          let probes = get(model, "data.ipAddresses", []);
          return probes.join(", ");
        },
        suppressNavigable: true
      },
      {
        //Monitored by
        headerId: "Monitored By",
        headerName: MONITOR_BY,
        //monitireby
        headerTooltip: MONITOR_BY,
        field: "attributes.monitoredBy",
        tooltipField: "attributes.monitoredBy",
        filter: "customSetFilter",
        cellRendererFramework: (params) => {
          let probes = params.data
            ? get(params, "data.attributes.monitoredBy", [])
            : [];
          return (
            <p
              style={{
                margin: 0,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {params.data?.attributes?.monitoredBy ? probes.join(", ") : ""}
            </p>
          );
        },
        filterParams: {
          values: this.state.probeList,
          suppressAndOrCondition: true,
          newRowsAction: "keep",
          closeOnApply: true,
          buttons: ["reset", "cancel", "apply"],
        },
        minWidth: 150,
        sortable: true,
        resizable: true,
        suppressMovable: false,
        headerComponentParams: {
          enableMenu: false, //true
        },
        suppressNavigable: true
      },
      {
        //bus type
        headerId: "Bus Type",
        headerName: HAS_ROBOT,
        field: "busType",
        filter: "customSetFilter",
        minWidth: 80,
        filterParams: {
          values: ["Yes", "No"],
          suppressAndOrCondition: true,
          newRowsAction: "keep",
          closeOnApply: true,
          buttons: ["reset", "cancel", "apply"],
        },

        cellRendererFramework: (model) => {
          const item = model.data;
          const hasRobot =
            item && (item.busType === "hub" || item.busType === "robot");
          return hasRobot ? (
            <div title={hasRobot}>
              <RobotIcon role="figure" title={hasRobot} />
            </div>
          ) : null;
        },
        //Has Robot
        headerTooltip: HAS_ROBOT,
        suppressMovable: false,
        suppressSorting: false,
        sortable:true,
        width: 100,
        headerComponentParams: {
          //Has Robot
          icon: <RobotIcon title={Resource.get("Has Robot")} />,
          enableMenu: false, //true
        },
        valueGetter: (model) => {
          const item = model.data;
          const hasRobot =
            item && (item.busType === "hub" || item.busType === "robot");
          return hasRobot;
        },
        suppressNavigable: true
      },
      {
        //csid
        headerId: "CS Id",
        headerName: CS_ID,
        headerTooltip: CS_ID,
        field: "attributes.cs_id",
        tooltipField: "attributes.cs_id",
        minWidth: 150,
        sortable: true,
        filter: "agTextColumnFilter",
        cellRendererFramework: (params) => {
          return (
            <p
              style={{
                margin: 0,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {params.data?.attributes?.cs_id
                ? params.data.attributes.cs_id
                : ""}
            </p>
          );
        },
        filterParams: {
          filterOptions: ["contains", "startsWith", "endsWith", "equals"],
          suppressAndOrCondition: true,
          newRowsAction: "keep",
          closeOnApply: true,
          buttons: ["reset", "cancel", "apply"],
        },
        headerComponentParams: {
          enableMenu: false, //true
        },
        suppressNavigable: true
      },
      {
        //cskey
        headerId: "CS Key",
        headerName: CS_KEY,
        headerTooltip: CS_KEY,
        field: "attributes.cs_key",
        tooltipField: "attributes.cs_key",
        width: 150,
        minWidth: 150,
        sortable: true,
        resizable: true,
        suppressMovable: false,
        filter: "agTextColumnFilter",
        cellRendererFramework: (params) => {
          return (
            <p
              style={{
                margin: 0,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {params.data?.attributes?.cs_key
                ? params.data.attributes.cs_key
                : ""}
            </p>
          );
        },
        filterParams: {
          filterOptions: ["contains", "startsWith", "endsWith", "equals"],
          suppressAndOrCondition: true,
          newRowsAction: "keep",
          closeOnApply: true,
          buttons: ["reset", "cancel", "apply"],
        },
        headerComponentParams: {
          enableMenu: false, //true
        },
        cellRendererFramework: (model) => {
          return model.data &&
            model.data.attributes &&
            model.data.attributes.cs_key ? (
            <div className="ag-cell-value">
              <span>{model.data.attributes.cs_key}</span>
            </div>
          ) : (
            ""
          );
        },
        suppressNavigable: true
      },
      {
        //Discovered
        headerId: "Discovered",
        headerName: DISCOVERED,
        headerTooltip: DISCOVERED,
        field: "attributes.create_time",
        sortingOrder: ["asc", "desc", null],
        filter: "customSetFilter",
        width: 140,
        filterParams: {
          values: [
            "< 4 hours",
            "< 10 hours",
            " < 1 day",
            " < 2 days",
            " < 1 week",
            " < 1 month",
            " < 3 months",
            " < 6 months",
            " < 1 year",
            "All",
          ],
          suppressAndOrCondition: true,
          newRowsAction: "keep",
          closeOnApply: true,
          buttons: ["reset", "cancel", "apply"],
        },
        minWidth: 140,
        sortable: true,
        headerComponentParams: {
          enableMenu: false, //true
        },
        cellRendererFramework: (model) => {
          if (model.data) {
            const createdTime = parseInt(
              get(model, "data.attributes.create_time[0]", 0),
              10
            );
            const time = moment(createdTime).fromNow();
            const formattedTime = moment(createdTime).format(
              "MMMM Do YYYY, h:mm a"
            );
            let view = (
              <p style={{ width: "100%" }} title={formattedTime}>
                {time}
              </p>
            );
            if (!createdTime) {
              view = null;
            }
            return view;
          } else {
            return null;
          }
        },
        suppressNavigable: true
      },

      {
        //has key icon
        headerId: "Has KeyIcon",
        headerName: "Has KeyIcon",
        field: "hasKey",
        filter: "customSetFilter",
        filterParams: {
          values: ["Yes", "No"],
          suppressAndOrCondition: true,
          newRowsAction: "keep",
          closeOnApply: true,
          buttons: ["reset", "cancel", "apply"],
        },
        cellRendererFramework: (model) => {
          if (model.data) {
            const item = model.data;
            const hasKeyIcon =
              (item.attributes && item.attributes.wmi_profile != null) ||
              (item.attributes && item.attributes.shell_profile != null) ||
              (item.attributes && item.attributes.snmp_profile != null);
            let keyIconTitle = "";
            if (item.attributes && item.attributes.wmi_profile != null)
              keyIconTitle = "wmi: " + item.attributes.wmi_profile_name;
            else if (item.attributes && item.attributes.shell_profile != null)
              keyIconTitle = "shell: " + item.attributes.shell_profile_name;
            else if (item.attributes && item.attributes.snmp_profile != null)
              keyIconTitle = "snmp: " + item.attributes.snmp_profile_name;
            return hasKeyIcon ? (
              <div>
                <KeyIcon />
              </div>
            ) : (
              <div></div>
            );
          } else {
            return null;
          }
        },
        //Has KeyIcon
        headerTooltip: Resource.get("Has KeyIcon"),
        suppressMovable: false,
        suppressSorting: true,
        sortable: true,
        minWidth: 100,
        width: 100,
        maxWidth: 100,
        headerComponentParams: {
          //Has KeyIcon
          icon: <KeyIcon title={Resource.get("Has KeyIcon")} />,
          enableMenu: false, //true
          enableSorting: true,
        },
        tooltipValueGetter: (model) => {
          if (model.data) {
            const item = model.data;
            const hasKeyIcon =
              (item.attributes && item.attributes.wmi_profile != null) ||
              (item.attributes && item.attributes.shell_profile != null) ||
              (item.attributes && item.attributes.snmp_profile != null);
            let keyIconTitle = "";
            if (item.attributes && item.attributes.wmi_profile != null)
              keyIconTitle = "wmi: " + item.attributes.wmi_profile_name;
            else if (item.attributes && item.attributes.shell_profile != null)
              keyIconTitle = "shell: " + item.attributes.shell_profile_name;
            else if (item.attributes && item.attributes.snmp_profile != null)
              keyIconTitle = "snmp: " + item.attributes.snmp_profile_name;
            if (hasKeyIcon) return keyIconTitle;
          }
        },
        valueGetter: (model) => {
          if (model.data) {
            const item = model.data;
            const hasKeyIcon =
              (item.attributes && item.attributes.wmi_profile != null) ||
              (item.attributes && item.attributes.shell_profile != null) ||
              (item.attributes && item.attributes.snmp_profile != null);
            return hasKeyIcon;
          }
        },
        suppressNavigable: true
      },
      {
        //last updated
        headerId: "Last Updated",
        headerName: CHANGED,
        headerTooltip: CHANGED,
        field: "attributes.change_time",
        cellRendererFramework: (model) => {
          if (model.data) {
            const changedTime = parseInt(
              get(model, "data.attributes.change_time[0]", 0),
              10
            );
            const formattedTime = moment(changedTime).format("DD/MM/YYYY");
            return (
              <div>
                <span title={formattedTime}>{formattedTime}</span>
              </div>
            );
          } else {
            return <div></div>;
          }
        },
        hide: false,
        comparator: this.honorBackendCompare,
        filter: "agDateColumnFilter",
        minWidth: 150,
        sortable: true,
        resizable: true,
        filterParams: {
          filterOptions: [
            "greaterThan",
            "greaterThanOrEqual",
            "lessThan",
            "lessThanOrEqual",
          ],
          browserDatePicker: true,
          suppressAndOrCondition: true,
          closeOnApply: true,
          buttons: ["reset", "cancel", "apply"],
        },
        headerComponentParams: {
          enableMenu: false, //true
        },
        suppressNavigable: true
      },
      {
        //removed
        headerId: "Removed",
        headerName: DELETED,
        headerTooltip: DELETED,
        field: "deleted",
        cellRendererFramework: (params) => {
          let value =
            params.data && params.data.attributes
              ? params.data.attributes.deleted
                ? "True"
                : "False"
              : "False";
          return <div>{value}</div>;
        },
        minWidth: 130,
        filter: "customSetFilter",
        sortable: true,
        resizable: true,
        filterParams: {
          values: ["True", "False"],
          suppressAndOrCondition: true,
          newRowsAction: "keep",
          closeOnApply: true,
          buttons: ["reset", "cancel", "apply"],
        },
        valueGetter: (model) => {
          if (model.data) {
            const item = model.data;
            let deleted = false;
            deleted = item.attributes && item.attributes.deleted != null;
            return deleted;
          }
        },
        headerComponentParams: {
          enableMenu: false, //true,
        },
        suppressNavigable: true
      },
    ];
    return columns;
  }

  getCSColumns() {
    let inventoryCsColumns = [
      {
        headerName: "",
        field: "checkbox",
        checkboxSelection: true,
        headerCheckboxSelection:
          this.props.rowModelType === "infinite" ? false : true,
        suppressMenu: true,
        suppressSorting: true,
        suppressSizeToFit: true,
        minWidth: 50,
        width: 50,
        maxWidth: 50,
        lockPosition: true,
        suppressNavigable: false,
        headerComponentFramework:
          this.props.rowModelType === "infinite"
            ? (params) => {
              return (
                <input
                  type="checkbox"
                  id="table-header-checkbox"
                  style={{
                    width: "18px",
                    height: "16px",
                    margin: "10px 16px",
                  }} className="table-checkbox"
                  tabIndex="0"
                  aria-label={"Select all controls"}
                  onChange={(event) =>
                    this.handleCheckboxChange(event.target.checked)
                  }
                />
              );
            }
            : null,
      },
      {
        headerName: "Alarm Count",
        field: "totalAlarms",
        cellRendererFramework: (params) => {
          return (
            <div style={{ width: "90px", textAlign: "center" }}>
              {params.value}
            </div>
          );
        },
        headerTooltip: "Total Alarm Count",
        // tooltipField: "maxAlarmSeverity.label",
        tooltipValueGetter: (params) =>
          params.data
            ? params.data.totalAlarms
              ? params.data.totalAlarms
              : params.data.totalAlarmCount
            : null,
        suppressMovable: false,
        width: 115,
        minWidth: 115,
        maxWidth: 115,
        sortable: false,
        suppressMenu: true,
        suppressSizeToFit: false,
        resizable: true,
        comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
          const aAlarmCount = nodeA.data.totalAlarms
            ? nodeA.data.totalAlarms
            : nodeA.data.totalAlarmCount;
          const bAlarmCount = nodeB.data.totalAlarms
            ? nodeB.data.totalAlarms
            : nodeB.data.totalAlarmCount;

          return aAlarmCount - bAlarmCount;
        },
        filter: "agNumberColumnFilter",
        filterParams: {
          suppressAndOrCondition: true,
          filterOptions: [
            "greaterThan",
            "greaterThanOrEqual",
            "lessThan",
            "lessThanOrEqual",
          ],
          clearButton: true,
        },
        valueGetter: (params) => {
          return params.data?.totalAlarms
            ? params.data.totalAlarms
            : params.data?.totalAlarmCount;
        },
        headerComponentParams: {
          // Max Alarm Severity
          // icon: <AlarmsIcon title={Resource.get("Max Alarm Severity")} />,
          enableMenu: false,
        },
        suppressNavigable: true,
      },
      {
        headerName: "Max Severity",
        field: "maxAlarmSeverity",
        cellRendererFramework: this.addAlarmIconToData,
        //Highest Alarm Severity Level and Total Alarm Count
        headerTooltip: Resource.get("Highest Alarm Severity Level"),

        // tooltipField: "maxAlarmSeverity.label",
        tooltipValueGetter: (params) =>
          params.data?.maxAlarmSeverity?.label
            ? params.data.maxAlarmSeverity.label
            : "",
        suppressMovable: false,
        suppressSizeToFit: true,
        resizable: true,
        sortable: true,
        filter: "customSetFilter",
        filterParams: {
          values: ["Info", "Warning", "Minor", "Major", "Critical", "None"],
          valueToCompare: function (data, node) {
            return data.maxAlarmSeverity.label;
          },
        },
        comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
          const firstValue = severityLabels.indexOf(valueA.toLowerCase());
          const secondValue = severityLabels.indexOf(valueB.toLowerCase());
          if (firstValue === secondValue) {
            return 0;
          } else {
            return firstValue > secondValue ? 1 : -1;
          }
        },
        valueGetter: (params) => {
          return params.data?.maxAlarmSeverity?.label
            ? params.data.maxAlarmSeverity.label
            : "";
        },
        width: 130,
        minWidth: 50,
        headerComponentParams: (params) => {
          if (params.column.actualWidth > 110) {
            return {
              enableMenu: false
            };
          } else {
            return {
              enableMenu: false,
              icon: <AlarmsIcon title={Resource.get("Max Alarm Severity")} />,
            };
          }
        },
      },
    ];

    const groupPageCsColumns = [
      {
        headerName: "Alarm Count",
        field: "totalAlarms",
        cellRendererFramework: (params) => {
          return (
            <div style={{ width: "90px", textAlign: "center" }}>
              {params.value}
            </div>
          );
        },
        headerTooltip: "Total Alarm Count",
        // tooltipField: "maxAlarmSeverity.label",
        tooltipValueGetter: (params) =>
          params.data
            ? params.data.totalAlarms
              ? params.data.totalAlarms
              : params.data.totalAlarmCount
            : null,
        suppressMovable: false,
        width: 115,
        minWidth: 115,
        maxWidth: 115,
        sortable: false,
        suppressMenu: true,
        suppressSizeToFit: false,
        resizable: true,
        comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
          const aAlarmCount = nodeA.data.totalAlarms
            ? nodeA.data.totalAlarms
            : nodeA.data.totalAlarmCount;
          const bAlarmCount = nodeB.data.totalAlarms
            ? nodeB.data.totalAlarms
            : nodeB.data.totalAlarmCount;

          return aAlarmCount - bAlarmCount;
        },
        filter: "agNumberColumnFilter",
        filterParams: {
          suppressAndOrCondition: true,
          filterOptions: [
            "greaterThan",
            "greaterThanOrEqual",
            "lessThan",
            "lessThanOrEqual",
          ],
          clearButton: true,
        },
        valueGetter: (params) => {
          return params.data?.totalAlarms
            ? params.data.totalAlarms
            : params.data?.totalAlarmCount;
        },
        headerComponentParams: {
          // Max Alarm Severity
          // icon: <AlarmsIcon title={Resource.get("Max Alarm Severity")} />,
          enableMenu: false,
        },
        suppressNavigable: true
      },
      {
        headerName: "Max Severity",
        field: "maxAlarmSeverity",
        cellRendererFramework: this.addAlarmIconToData,
        //Highest Alarm Severity Level and Total Alarm Count
        headerTooltip: Resource.get("Highest Alarm Severity Level"),
        // tooltipField: "maxAlarmSeverity.label",
        tooltipValueGetter: (params) =>
          params.data?.maxAlarmSeverity?.label
            ? params.data.maxAlarmSeverity.label
            : "",
        suppressMovable: false,
        suppressSizeToFit: true,
        resizable: true,
        sortable: true,
        filter: "customSetFilter",
        filterParams: {
          values: ["Info", "Warning", "Minor", "Major", "Critical", "None"],
          valueToCompare: function (data, node) {
            return data.maxAlarmSeverity.label;
          },
        },
        comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
          const firstValue = severityLabels.indexOf(valueA.toLowerCase());
          const secondValue = severityLabels.indexOf(valueB.toLowerCase());
          if (firstValue === secondValue) {
            return 0;
          } else {
            return firstValue > secondValue ? 1 : -1;
          }
        },
        valueGetter: (params) => {
          return params.data?.maxAlarmSeverity?.label
            ? params.data.maxAlarmSeverity.label
            : "";
        },
        width: 130,
        minWidth: 50,
        suppressNavigable: true,
        headerComponentParams: (params) => {
          if (params.column.actualWidth > 110) {
            return {
              enableMenu: false,
            };
          } else {
            return {
              enableMenu: false,
              icon: <AlarmsIcon title={Resource.get("Max Alarm Severity")} />,
            };
          }
        },
      },
    ];
    if (this.hideCheckboxes && this.props.rowModelType !== "infinite") {
      inventoryCsColumns[0].checkboxSelection = false;
      inventoryCsColumns[0].headerCheckboxSelection = false;
    }

    if (this.props.history.location.pathname.includes("inventory")) {
      // if (this.state.filterIcon) {
      //   this.setState({ filterIcon: false });
      // }
      return inventoryCsColumns;
    } else {
      return groupPageCsColumns;
    }
  }
  handleCheckboxChange = (event, idsSelected) => {
    if (idsSelected || event === null) {
      this.api.forEachNode(function (node) {
        if (idsSelected.includes(node.data?.id)) {
          node.setSelected(true);
        } else {
          node.setSelected(false);
        }
      });
    } else {
      if (event) {
        this.api.forEachNode(function (node) {
          node.setSelected(true);
        });
      } else {
        this.api.deselectAll();
      }
    }
    // this.api.redrawRows();
  };
  handleDrillDown = (row) => {
    const history = this.props.history;
    if (!this.props.customRowClicking) {
      let el = row.event.target;
      let columnName = el.closest("[col-id]").getAttribute("col-id");
      let itemType = row.data.entityType;
      if (
        itemType !== undefined &&
        itemType !== entityTypes.COMPUTER_SYSTEM &&
        columnName !== "maxAlarmSeverity" &&
        columnName !== " "
      ) {
        this.props.handleGroupCellClick(row, history);
      } else if (columnName !== "checkbox") {
        if (columnName === "maxAlarmSeverity") {
          this.props.handleAlarmCellClick(
            row,
            history,
            this.props.saas,
            this.props.doiurl,
            this.props.featureFlags
          );
        } else {
          this.props.handleCabiCellClick(row, history);
        }
      }
    }
  };
  handleExitClickFlag = (element) => {
    this.setState({
      showDialog: false,
    });
  };
  handlePageClick = (page) => {
    this.setState({
      pageNumber: page.selected,
    });
  };
  handleSetDialogFlag = (element, row) => {
    let device = row.data;
    this.setState({
      showDialog: true,
      device: device,
    });
  };
  getColDefs(props) {
    if (props.columnDefs === "groupCols") {
      return [...this.getGroupColumns()];
    } else if (props.columnDefs === "csCols") {
      return [
        ...this.getCSColumns(),
        ...this.getSelectedColumn(
          !(props.location.pathname.search("/card") > -1)
            ? props.savedColsList
            : props.savedColumns
        ),
      ];
    } else {
      return props.columnDefs;
    }
  }

  //TODO KOLAS01 getColDefs /list 

  mouseOverClassAddition(e) {
    if (e.target && e.target.closest(".ag-cell")) {
      let hoveredCell = e.target.closest(".ag-cell");
      if (e.target.closest(".ag-row")) {
        let children =
          e.target.closest(".ag-row").attributes.class.ownerElement.childNodes;
        if (hoveredCell.getAttribute("col-id") === "maxAlarmSeverity") {
          each(children, (child) => {
            if (child.getAttribute("col-id") === "maxAlarmSeverity") {
              child.className += " grid__row--hover";
            } else if (
              child.getAttribute("col-id") === "checkbox" &&
              this.hideCheckboxes
            ) {
              child.className += " grid__row--hover";
            }
          });
        } else if (hoveredCell.getAttribute("col-id") === " ") {
          each(children, (child) => {
            if (child.getAttribute("col-id") === " ") {
              child.className += " grid__row--hover";
            }
          });
        } else if (
          hoveredCell.getAttribute("col-id") === "checkbox" &&
          this.hideCheckboxes
        ) {
          each(children, (child) => {
            if (
              child.getAttribute("col-id") === "maxAlarmSeverity" ||
              child.getAttribute("col-id") === "checkbox"
            ) {
              child.className += " grid__row--hover";
            }
          });
        } else {
          each(children, (child) => {
            if (
              child.getAttribute("col-id") !== "maxAlarmSeverity" &&
              child.getAttribute("col-id") !== " "
            ) {
              if (
                this.hideCheckboxes &&
                child.getAttribute("col-id") === "checkbox"
              ) {
                return;
              } else {
                child.className += " grid__row--hover";
              }
            }
          });
        }
      }
    }
  }
  mouseOutClassDeletion(e) {
    if (e.target && e.target.closest(".ag-cell")) {
      if (e.target.closest(".ag-row")) {
        let children =
          e.target.closest(".ag-row").attributes.class.ownerElement.childNodes;
        each(children, (child) => {
          child.className = child.className.replace(" grid__row--hover", "");
        });
      }
    }
  }
  autoSizeColumns() {
    // var allColumnIds = [];
    // this.columnApi &&
    // this.columnApi.getAllColumns().forEach(function (column) {
    //   allColumnIds.push(column.colId);
    // });
    // this.columnApi && this.columnApi.autoSizeColumns(allColumnIds, false);
    // console.log("autosize");
    // console.log(
    //   this.api &&
    //     this.api.columnController.scrollWidth >
    //       this.api.columnController.bodyWidth
    // );
    setTimeout(() => {
      if (
        this.api &&
        this.api.columnController?.scrollWidth >
        this.api.columnController?.bodyWidth
      ) {
        this.api.sizeColumnsToFit();
      }
    }, 100);
    // if (
    //   this.api &&
    //   this.api.columnController.scrollWidth >
    //     this.api.columnController.bodyWidth
    // ) {
    //   setTimeout(() => {
    //     this.api.sizeColumnsToFit();
    //   }, 100);
    // }
  }

  onFirstDataRendered = (params) => {
    //  if (this.columnApi.getAllColumns().length == 8) {
    // this.autoSizeColumns();
    // }else{
    this.api.sizeColumnsToFit();
    // }
  };

  // Update data in the grid
  updateData(data, total_data_length, params) {
    if(params && params.context)
    {
    params.context.paramsInfo = params;
    // if (this.state.suppressGridUpdate) {
    //   params.context.api.showLoadingOverlay();
    //   return;
    // }
    if (params.context.page_start_index !== params.startRow) {
      params.context.page_start_index = params.startRow;
      // Change the page number
      let pagesize = params.endRow - params.startRow,
        next_page_number = params.startRow / pagesize;
      if (params.context.api) {
        params.context.api.showLoadingOverlay();
      }
      this.props.searchComputerSystems(next_page_number);
    } else {
      let rowsThisPage = data;
      let lastRow = -1;
      if (total_data_length <= params.endRow) {
        lastRow = total_data_length;
      }
      if (params.context.api) {
        params.context.api.hideOverlay();
      }
      params.successCallback(rowsThisPage, lastRow);
      if (this.state.typeSelected === "byPage") {
        const settingsCheckboxElement = document.getElementById(
          "table-header-checkbox"
        );
        if (settingsCheckboxElement && settingsCheckboxElement.checked) {
          settingsCheckboxElement.checked = false;
        }
        this.setState({ typeSelected: null });
        this.handleCheckboxChange(null, this.props.selectedCsIds);
      } else if (
        this.state.typeSelected === "allPages" &&
        this.props.selectedCsIds.length == this.props.data.length
      ) {
        this.handleCheckboxChange(true);
      } else {
        this.handleCheckboxChange(null, this.props.selectedCsIds);
      }
    }
  }
  }

  //set the grid datasource
  setGridDataSource(data, total_data_length) {
    const datasource = {
      rowCount: null,
      getRows: function (params) {
        params.context.updateData(data, total_data_length, params);
      },
    };
    this.api.setDatasource(datasource);

    // if (this.api) {
    //   this.api.sizeColumnsToFit();
    // }
    this.setState({ isSort_Filter: false });
    // if (this.api && !isEqual(data.sort(), this.props.data.sort())) {
    //   this.api.setDatasource(datasource);
    // } else if (data && data.length === 0) {
    //   this.api.hideOverlay()
    // }
  }
  onGridReady(params) {
    this.api = params.api;
    this.columnApi = params.columnApi;
    // this.autoSizeColumns();
    this.api.setGridAriaProperty('label', this.props.fromInventory ? "Inventory table" : "UIM component table");


    if (this.props.onGridReady) {
      this.props.onGridReady(params);
    }
    if (this.props.rowModelType === "infinite") {
      this.setGridDataSource(
        this.props.data,
        this.props.entitiesFilterSortConfig.totalItems
      );
    } else {
      if (
        this.api &&
        this.props.visibleDataCount !==
        this.api.rowModel.rowsToDisplay?.length &&
        this.props.handleColumnFilterChange
      ) {
        this.props.handleColumnFilterChange(
          this.api.rowModel.rowsToDisplay?.length
        );
      }
      //dp039841
      let rowData = [];
      this.api.forEachNodeAfterFilter((node) => {
        rowData.push(node.data);
      });
      this.props.setInvetoryFilteredData(rowData);
      this.autoSizeColumns();

      this.updateRowSelection();
    }
  }
  rowDataChanged = () => {
    this.updateRowSelection();
  };
  filterChanged = (params) => {
    this.api = params.api;
    if (this.props.rowModelType === "infinite") {
      const tableFilters = this.api.getFilterModel();
      const allColumns = this.getColDefs(this.props);
      const updatedFIlters = [];
      Object.keys(tableFilters).forEach((nameFilter) => {
        let operator =
          tableFilters[nameFilter].type == "set"
            ? "IN"
            : tableFilters[nameFilter].type.toUpperCase();
        let value =
          tableFilters[nameFilter].filter == undefined &&
            nameFilter != "attributes.change_time"
            ? tableFilters[nameFilter].values.toString()
            : tableFilters[nameFilter].filter;
        if (nameFilter == "attributes.create_time") {
          if (tableFilters[nameFilter].values.includes("All")) {
            operator = "GT";
            value = "";
          } else {
            operator = "SW";
            value = this.getDiscoveredValue(tableFilters[nameFilter].values);
          }
        }
        if (nameFilter == "deleted" && value == "") {
          value = "Nothing";
        }
        if (nameFilter == "maxAlarmSeverity") {
          let sevVals = [];
          tableFilters[nameFilter].values.forEach((key) => {
            sevVals.push(alarmSevMap[key]);
          });
          value = sevVals.toString();
        }
        if (nameFilter == "attributes.change_time") {
          let changedTime = tableFilters[nameFilter].dateFrom;
          const dateFrom = moment(changedTime).valueOf();
          let change_op = "";
          switch (operator) {
            case "LESSTHANOREQUAL":
              change_op = "LTE";
              break;
            case "LESSTHAN":
              change_op = "LT";
              break;
            case "GREATERTHAN":
              change_op = "GT";
              break;
            case "GREATERTHANOREQUAL":
              change_op = "GTE";
              break;
            default:
              change_op = "GTE";
              break;
          }
          operator = change_op;
          value = dateFrom;
        }

        updatedFIlters.push({
          column: allColumns.find((column) => column.field === nameFilter)
            .headerName,
          value: value,
          operator: operator,
          negated: false,
        });
      });
      this.setState(
        {
          suppressGridUpdate: true,
        },
        () => {
          this.setState({loadingTableData: true})
          this.props.setFilter(updatedFIlters);
        }
      );
    } else {
      if (this.api.rowModel.rowsToDisplay?.length === 0) {
        this.api.showNoRowsOverlay();
      } else {
        this.api.hideOverlay();
      }

      if (
        this.api &&
        this.props.visibleDataCount !==
        this.api.rowModel.rowsToDisplay?.length &&
        this.props.handleColumnFilterChange
      ) {
        this.props.handleColumnFilterChange(
          this.api.rowModel.rowsToDisplay?.length
        );
      }
      if (this.props.handleCsRowsSelected) {
        let filteredIds = [];
        const ids = rowSelectionCache.getData();
        this.api.rowModel.rowsToDisplay?.forEach((item) => {
          if (ids.indexOf(item.data.id) !== -1) {
            filteredIds.push(item.data.id * 1);
          }
        });
        this.props.handleCsRowsSelected(filteredIds);
      }

      let rowData = [];
      this.api.forEachNodeAfterFilter((node) => {
        rowData.push(node.data);
      });
      this.props.setInvetoryFilteredData(rowData);
    }
  };

  backendSort = (event) => {
    const sortConfig = {
      sortColumn: null,
      sortDirection: null,
    };
    const allColumns = this.getColDefs(this.props);
    if (event) {
      let columnFound = allColumns.find(
        (column) => column.field === event.column
      )
      if (columnFound !== undefined && columnFound.sortable !== false) {
        sortConfig.sortColumn = columnFound.headerName;

        sortConfig.sortColumn =
          sortConfig.sortColumn === "Max Severity"
            ? "maxAlarmSeverity"
            : sortConfig.sortColumn;
        sortConfig.sortDirection = event.sortOrder
          ? event.sortOrder.toUpperCase()
          : null;

        if (event ) {
          this.setState(
            {
              isSort_Filter: true,
            },
            () => {
              this.setState({loadingTableData: true})
              this.props.sortChange(sortConfig);
            }
          );
        }
      }
    }
  };

  onSortChanged = () => {
    let rowData = [];
    this.props.data
      .sort(function (obj1, obj2) {
        if (obj1.name < obj2.name) {
          return -1;
        }
        if (obj1.name > obj2.name) {
          return 1;
        }
        return 0;
      })
      .map((data, index) => rowData.push(data));
    this.props.setInvetoryFilteredData(rowData);
  };
  getDiscoveredValue = (selectedValues) => {
    let final_time = "";
    if (selectedValues.includes(" < 1 year")) {
      final_time = moment().subtract(1, "y").valueOf();
    } else if (selectedValues.includes(" < 6 months")) {
      final_time = moment().subtract(6, "M").valueOf();
    } else if (selectedValues.includes(" < 3 months")) {
      final_time = moment().subtract(3, "M").valueOf();
    } else if (selectedValues.includes(" < 1 month")) {
      final_time = moment().subtract(1, "M").valueOf();
    } else if (selectedValues.includes(" < 1 week")) {
      final_time = moment().subtract(1, "w").valueOf();
    } else if (selectedValues.includes(" < 2 days")) {
      final_time = moment().subtract(2, "d").valueOf();
    } else if (selectedValues.includes(" < 1 day")) {
      final_time = moment().subtract(1, "d").valueOf();
    } else if (selectedValues.includes("< 10 hours")) {
      final_time = moment().subtract(10, "h").valueOf();
    } else if (selectedValues.includes("< 4 hours")) {
      final_time = moment().subtract(4, "h").valueOf();
    }
    return final_time != null && final_time != ""
      ? parseInt(final_time / 1000)
      : "";
  };

  getNoRowInfo = () => {
    let noDataMsg = Resource.get("No Data Found"); //No Data Found
    let classNames = ["setup-wizard__localprobes__info"];
    let msg2 = Resource.get("Try different filter options"); //Try different filter options
    return (
      <div style={{ position: "relative" }} className={classNames.join(" ")}>
        <div
          style={{
            width: "calc(100% - 60px)",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          <div className="nohubs__contentImg"></div>
          <span className="setup-wizard__localprobes__content">
            <span
              style={{
                padding: "2px 13px 2px 20px",
                borderRight: "2px solid #999999",
              }}
            >
              {noDataMsg}
            </span>
            <span style={{ paddingLeft: "9px" }}>{msg2}</span>
          </span>
        </div>
      </div>
    );
  };

  updateRowSelection = () => {
    setTimeout(
      function () {
        if (this.api) {
          this.updateRowSelectionCache = false;
          rowSelectionCache.updateOnRowDataChanged(this.api);
          this.updateRowSelectionCache = true;
          if (this.api.rowModel.rowsToDisplay?.length === 0) {
            this.api.showNoRowsOverlay();
          } else {
            this.api.hideOverlay();
          }
          if (
            this.props.visibleDataCount !==
            this.api.rowModel.rowsToDisplay?.length &&
            this.props.handleColumnFilterChange
          ) {
            this.props.handleColumnFilterChange(
              this.api.rowModel.rowsToDisplay?.length
            );
          }
        }
      }.bind(this),
      0
    );
  };

  selectionChanged = (event) => {
    if (this.updateRowSelectionCache && this.api) {
      rowSelectionCache.onChange(this.api);
    }
    if (this.props.handleCsRowsSelected) {
      let filteredIds = [];
      let selRowData = [];
      if (this.props.rowModelType === "infinite") {
        event.api.getSelectedNodes().forEach((row) => {
          filteredIds.push(+row.data.id);
          selRowData.push(row.data);
        });
        const settingsCheckboxElement = document.getElementById(
          "table-header-checkbox"
        );
        const currentLengthRows =
          event.api.getModel().getRowCount() <
            this.props.entitiesFilterSortConfig.totalItems
            ? event.api.getModel().getRowCount() - 1
            : event.api.getModel().getRowCount();
        if (
          filteredIds.length ===
          this.props.entitiesFilterSortConfig.totalItems &&
          this.state.typeSelected !== "allPages"
        ) {
          this.setState({
            typeSelected: "allPages",
            selectedDevices: filteredIds.length,
          });
          this.props.handleCsRowsSelected(filteredIds);
          this.props.setSelectedRowData(selRowData);
        } else if (
          filteredIds.length < currentLengthRows &&
          this.state.typeSelected
        ) {
          this.setState({ typeSelected: null });
          if (settingsCheckboxElement && settingsCheckboxElement.checked) {
            settingsCheckboxElement.checked = false;
          }
        } else if (
          filteredIds.length === currentLengthRows &&
          this.state.typeSelected !== "byPage" &&
          this.state.typeSelected !== "allPages"
        ) {
          if (settingsCheckboxElement && !settingsCheckboxElement.checked) {
            settingsCheckboxElement.checked = true;
          }
          this.setState({
            typeSelected: "byPage",
            selectedDevices: filteredIds.length,
          });
        } else if (this.state.typeSelected === "allPages") {
          this.setState({
            selectedDevices: filteredIds.length,
          });
        }
      } else {
        const ids = rowSelectionCache.getData();
        this.api.rowModel.rowsToDisplay?.forEach((item) => {
          if (ids.indexOf(item.data.id) !== -1) {
            filteredIds.push(item.data.id * 1);
            selRowData.push(item.data);
          }
        });
      }
      if (
        !isEqual(this.prevIds, filteredIds) &&
        this.state.typeSelected !== "allPages"
      ) {
        this.props.handleCsRowsSelected(filteredIds);
        this.props.setSelectedRowData(selRowData);
      }
      const headerCheckbox = document.querySelector("#table-header-checkbox");
      if (filteredIds.length === 0 && headerCheckbox?.indeterminate) {
        headerCheckbox.indeterminate = false;
      } else if (
        filteredIds.length > 0 &&
        filteredIds.length !== this.state.sortedData.length &&
        headerCheckbox.indeterminate !== true
      ) {
        document.querySelector("#table-header-checkbox").indeterminate = true;
      }
      this.prevIds = filteredIds;
    }
  };

  setSelectionAllPages() {
    this.props.getAllComputerSystems().then((resp) => {
      const allIds = resp.data._items.map((item) => item.id);
      this.setState({ typeSelected: "allPages" });
      this.props.handleCsRowsSelected(allIds);
      this.props.setSelectedRowData(resp.data._items);
      this.prevIds = allIds;
    });
  }

  sizeToFit = () => {
    this.api.sizeColumnsToFit();
  };

  onColumnResized(event) {
    if (event?.column && event.column.colId === "maxAlarmSeverity") {
      event.api.refreshCells({
        force: true,
        columns: ["maxAlarmSeverity"],
      });
      event.api.setColumnDefs(event.api.getColumnDefs());
    }
  }

  onColumnMoved(params) {
    if (
      this.props.rowModelType === "infinite" &&
      params.type === "dragStopped" &&
      this.props.columnDefs === "csCols" &&
      window.location.pathname.includes("inventory") &&
      window.location.pathname.includes("/card")
    ) {
      if (this.timeOutColMoved) clearTimeout(this.timeOutColMoved);
      this.timeOutColMoved = setTimeout(() => {
        let newColumnState = this.columnApi.getColumnState();
        if (
          newColumnState &&
          this.props.savedColumns &&
          this.props.savedColumns.length
        ) {
          const allDataColumns = this.getColDefs(this.props).filter((column) =>
            this.props.savedColumns.includes(column.headerName)
          );
          let columnNamesToSave = [];
          newColumnState.forEach((column, indexColumn) => {
            let columnFound = allDataColumns.find((col) => {
              return col.field == column.colId && col.headerName !== "";
            });
            if (columnFound) {
              columnNamesToSave.push(columnFound);
            }
          });
          if (columnNamesToSave && columnNamesToSave.length != 0) {
            this.props.handleSaveSelectedColumns(columnNamesToSave);
          }
        }
        clearTimeout(this.timeOutColMoved);
      }, 1000); // after one seconds

      // setTimeout(() => {
      //   this.sorthanddle();
      // }, 2000);
    }
  }

  debouncedColumnResized = debounce(
    (event) => this.onColumnResized(event),
    600
  );
  onSuppressKeyboardEvent(params) {
    let key = params.event.key;
    let shiftKey = params.event.shiftKey;
    let isTabbingForward = key === 'Tab' && shiftKey === false;
    let isTabbingBackWards = key === 'Tab' && shiftKey === true;

    // Handle cell children tabbing
    if (isTabbingForward || isTabbingBackWards) {
      tabForward = isTabbingForward;
      tabBackward = isTabbingBackWards;

      if (params.editing) {
        return false;
      }

      let eGridCell = params.event.path.find((el) => {
        if (el.classList === undefined) return false;
        return el.classList.contains('ag-cell');
      });

      let focusableChildrenElements = getAallFocussableElementsOf(eGridCell);
      let lastCellChildEl =
        focusableChildrenElements[focusableChildrenElements.length - 1];
      let firstCellChildEl = focusableChildrenElements[0];

      //  FORWARD
      // allow to movee to next cell when the cells last element is focused
      if (isTabbingForward && focusableChildrenElements.length > 0) {
        let isLastChildFocused =
          lastCellChildEl && document.activeElement === lastCellChildEl;

        if (isLastChildFocused === false) {
          return true;
        }
      }

      //  BACKWARDS
      //  focus last element if none are focused
      //  allow to tab backwards when first element is focused
      if (isTabbingBackWards && focusableChildrenElements.length > 0) {
        let cellHasFocusedChildren =
          eGridCell.contains(document.activeElement) &&
          eGridCell !== document.activeElement;

        if (!cellHasFocusedChildren) {
          params.event.preventDefault();
          lastCellChildEl.focus();
        }

        let isFirstChildFocused =
          firstCellChildEl && document.activeElement === firstCellChildEl;

        if (isFirstChildFocused === false) {
          return true;
        }
      }
    }

    return false;
  }

  onSuppressHeaderKeyboardEvent(params) {
    let key = params.event.key;
    let shiftKey = params.event.shiftKey;
    let isTabbingForward = key === 'Tab' && shiftKey === false;
    let isTabbingBackWards = key === 'Tab' && shiftKey === true;
    // Handle cell children tabbing

    if (isTabbingForward || isTabbingBackWards) {
      let eGridCell = params.event.path.find((el) => {
        if (el.classList === undefined) return false;
        return el.classList.contains('ag-header-cell');
      });

      let focusableChildrenElements = getAallFocussableElementsOf(eGridCell);
      let lastCellChildEl =
        focusableChildrenElements[focusableChildrenElements.length - 1];
      let firstCellChildEl = focusableChildrenElements[0];

      //  FORWARD
      // allow to movee to next cell when the cells last element is focused
      if (isTabbingForward && focusableChildrenElements.length > 0) {
        let isLastChildFocused =
          lastCellChildEl && document.activeElement === lastCellChildEl;

        if (isLastChildFocused === false) {
          return true;
        }
      }

      //  BACKWARDS
      //  focus last element if none are focused
      //  allow to tab backwards when first element is focused
      if (isTabbingBackWards && focusableChildrenElements.length > 0) {
        let cellHasFocusedChildren =
          eGridCell.contains(document.activeElement) &&
          eGridCell !== document.activeElement;
        if (!cellHasFocusedChildren) {
          params.event.preventDefault();

          lastCellChildEl.focus();
        }

        let isFirstChildFocused =
          firstCellChildEl && document.activeElement === firstCellChildEl;

        if (isFirstChildFocused === false) {
          return true;
        }
      }
    }

    return false;
  }
  onCellFocused2(params) {
    if (tabForward) {
      let cell = document.activeElement;
      let focusableChildren = getAallFocussableElementsOf(cell);

      if (focusableChildren.length > 0) {
        focusableChildren[0].focus();
      }

      tabForward = false;
    }

    if (tabBackward) {
      let cell = document.activeElement;
      let focusableChildren = getAallFocussableElementsOf(cell);

      if (focusableChildren.length > 0) {
        focusableChildren[focusableChildren.length - 1].focus();
      }

      tabBackward = false;
    }
  }
  filterChanged1 = (params) => {
    let model={}; // its only for groups that this filters here are working for.
    if(params.quickFilter)
    {
      model["name"]={
        filter:params.quickFilter,
        filterType: "text",
        type: "contains"}
        
    }
    params.colFilters.map((filter)=>{
      if(filter.filterType==="set")
      {
        model[filter.filterColumn]={
          values:filter.filterValue?.split(','),
          filterType: filter.filterType,
        }

      } else if (filter.filterType == 'text') {
        model[filter.filterColumn] = {
          filter: filter.filterValue,
          filterType: filter.filterType,
          type: filter.filterOperator
        }
      }

    })

    this.api.setFilterModel(model);

  }

  render() {
    // No results found
    const NoReultsMsg = Resource.get("No results found");
    let view;
    const headerHeight = 36;
    const rowHeight = 48;

    let rowData = this.state.sortedData;
    let heightOfDiv = 15 * rowHeight + headerHeight + 30;
    let onSelectionChanged = null;
    let rowSelection = "single";
    if (this.props.columnDefs === "csCols") {
      onSelectionChanged = this.selectionChanged;
      rowSelection = "multiple";
    }
    view = (
      <div ref="viewref" style={{height:this.props.fromInventory?'calc(100% - 64px)':'100%'}}>
        {this.props.rowModelType === "infinite" && this.state.typeSelected ? (
          <div style={{ textAlign: "center" }}>
            <p
              style={{
                margin: "5px 0",
                position: "absolute",
                top: 61,
                left: "44%",
                zIndex: 1000,
              }}
            >
              {this.state.typeSelected === "allPages"
                ? `All ${this.props.entitiesFilterSortConfig.totalItems}`
                : this.state.selectedDevices}{" "}
              devices are selected.
              {this.state.typeSelected === "byPage" ? (
                <span
                  style={{
                    color: "#1a73e8",
                    cursor: "pointer",
                    margin: "0 4px",
                  }}
                  onClick={() => {
                    this.setSelectionAllPages();
                  }}
                >
                  Select all {this.props.entitiesFilterSortConfig.totalItems}{" "}
                  devices
                </span>
              ) : (
                <span
                  style={{
                    color: "#1a73e8",
                    cursor: "pointer",
                    margin: "0 4px",
                  }}
                  onClick={() => this.handleCheckboxChange(false)}
                >
                  Clear selection
                </span>
              )}
            </p>
          </div>
        ) : null}
       
        {this.props.showTableFilters &&  <div style={{marginTop:'8px',marginBottom:'8px'}}><TableFilters
              defaultFilters={defaultFiltersActiveObjects(
                this.state.currentColumns
               
              )}
              handleUpdateFilters={this.filterChanged1.bind(this)}
              searchResultsNumber={this.api?.getDisplayedRowCount()}
             
            /> </div>}
           
        <div 
          className="ag-material inventory-tablecontainer"
          style={{
            height: this.props.rowModelType === "infinite" ? `calc(100vh - ${HEADERHEIGHT}px - ${this.props.navHeight}px - ${FOOTERHEIGHT}px)` 
            :( this.props.fromGroupsView?`calc( 100% - 40px)`:`calc(100vh - ${HEADERHEIGHT}px - ${NAVIGATIONHEIGHT}px - ${FOOTERHEIGHT}px)`),
            overflow: "hidden",
          }}
        >
          {this.state.loadingTableData && 
          <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <CircularProgress style={{color: '#3272D9'}} />
          </div>
          }
          <Grid
            {...this.props}
            customClassName={this.props.fromInventory ? 'oc-grid inventory-oc-grid' : 'oc-grid'}
            headerHeight={headerHeight}
            rowHeight={rowHeight}
            rowData={rowData}
            // columnDefs={this.state.csCols}
            columnDefs={this.state.currentColumns}
            enableColResize={true}
            onRowClicked={this.handleDrillDown}
            onGridReady={this.onGridReady.bind(this)}
            overlayNoRowsTemplate={this.state.overlayNoRowsDisplay}
            onSelectionChanged={onSelectionChanged}
            onFilterChanged={this.filterChanged.bind(this)}
            onSortChanged={this.onSortChanged}
            onRowDataChanged={this.rowDataChanged}
            onColumnResized={this.debouncedColumnResized.bind(this)}
            onFirstDataRendered={this.onFirstDataRendered.bind(this)}
            rowSelection={rowSelection}
            rowMultiSelectWithClick={this.props.rowModelType === "infinite"}
            tooltipShowDelay={this.state.tooltipShowDelay}
            context={this}
            suppressRowHoverClass={true}
            suppressRowClickSelection={true}
            onDragStopped={this.onColumnMoved.bind(this)}
            backendSort={
              this.props.rowModelType === "infinite" ? this.backendSort : null
            }
            backendSortConfig={{
              enable: this.props.rowModelType === "infinite" ? true : false,
              callback: this.backendSort,
              defaultSortColumnConfig: {
                id: "attributes.create_time",
                type: "desc",
              },
            }}
            onCellFocused={this.onCellFocused2.bind(this)}
            //suppressCellFocus={true}
            suppressKeyboardEvent={this.onSuppressKeyboardEvent}
            suppressHeaderKeyboardEvent={this.onSuppressHeaderKeyboardEvent}
          />

        </div>
        <div className="pagination">
          <DetailPopUp
            entity={this.state.device}
            showDialog={this.state.showDialog}
            handleExitClickFlag={this.handleExitClickFlag}
            featureflag={this.props.featureFlags}
            saas={this.props.saas}
            doiurl={this.props.doiurl}
            acl={this.props.acl}
          />
        </div>
        {/* <Snackbar open={Boolean(this.state.selectedDevices)} autoHideDuration={5000} onClose={() => this.setState({selectedDevices: null})}>
                      <Alert severity="success">Selected {this.state.selectedDevices} devices.</Alert>
                    </Snackbar> */}
      </div>
    );
    return view;
  }
}

export default withRouter(Table);