// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //OK
  content["Yes"] =
  "はい";

  //OK
  content["No"] =
  "いいえ";

  //OK
  content["OK"] =
  "OK";

  //Upload JSON file(s)
  content["Upload JSON file(s)"] =
  "JSON ファイルのアップロード";

  //Click TEST to begin
  content["Click PROCEED to begin"] =
  "開始するには、[次へ] をクリックしてください";

  // Processing...
  content["Processing..."] =
  "処理中です...";

  //Attempting to communicate with API
  content["Attempting to communicate with API"] =
  "API との通信を試行しています";

  // Schema passed tests
  content["Schema passed tests, click DONE below"] =
  "スキーマがテストに合格しました。以下の [完了] をクリックしてください";

  //Schema could not be processed.Check logs below
  content["Schema could not be processed.Check logs below"] =
  "スキーマを処理できませんでした。以下のログを確認してください";

  //Unable  to communicate with API
  content["Unable to communicate with API"] =
  "API と通信できません";

  //Click TEST to begin
  content["click TEST to begin"] =
  "開始するには、[テスト] をクリックしてください";

  // Download RESTMon schema template
  content["Download RESTMon schema template"] =
  "RESTMon スキーマ テンプレートのダウンロード";

  // Download the RESTMon schema template and then configure to monitor RESTful API
  content["Download the RESTMon schema template and then configure to monitor your RESTful API"] =
  "RESTMon スキーマ テンプレートをダウンロードし、RESTful API をモニタするよう設定します";

  //RESTMon schema template
  content["RESTMon schema template"] =
  "RESTMon スキーマ テンプレート";

  //DOWNLOAD
  content["DOWNLOAD"] =
  "ダウンロード";

  //Upload custom RESTMon schema
  content["Upload custom RESTMon schema"] =
  "カスタム RESTMon スキーマのアップロード";

  // RESTMon instruction message
  content["After modifying the RESTMon schema to meet your needs, upload the JSON file to be tested"] =
  "RESTMon スキーマをニーズを満たすように修正したら、JSON ファイルをアップロードしてテストします";

  // Upload file...
  content["Upload File..."] =
  "ファイルのアップロード...";

  // Test custom schema
  content["Validate and deploy custom schema"] =
  "カスタム スキーマの検証および展開";

  //schema validation & deployment
  content["schema validation & probe package deployment"] =
  "スキーマの検証およびプローブ パッケージの展開";

  //TEST
  content["PROCEED"] =
  "次へ";

  //Directions
  content["Directions"] =
  "方向";

  //Home
  content["Home"] =
  "ホーム";

  // Modify schema to collect API-defined metrics
  content["Modify schema to collect API-defined metrics	"] =
  "スキーマを変更して API 定義済みメトリクスを収集します";

  // Upload modified schema (JSON file)
  content["Upload schema file(s) related to a technology"] =
  "テクノロジに関連するスキーマ ファイルのアップロード";

  // Test schema syntax
  content["Test schema syntax"] =
  "スキーマ構文のテスト";

  //Select Done
  content["Select Done"] =
  "[完了] を選択";

  //HELP
  content["HELP"] =
  "ヘルプ";

  //Help
  content["Help"] =
  "ヘルプ";

  //Reset
  content["Reset"] =
  "リセット";

  //Done
  content["Done"] =
  "完了";

  //Browse
  content["Browse"] =
  "参照";

  //To validate and deploy the schema upload the file(s) and provide a friendly name, and then, click Proceed
  content["To validate and deploy the schema upload the file(s) and provide a friendly name, and then, click Proceed"] =
  "スキーマを検証および展開するには、ファイルをアップロードし、フレンドリ名を指定してから、[次へ] をクリックします";

  //Default template schemas(restmon-sample-schemas) can be found at support.nimsoft.com
  content["Default template schemas (restmon-sample-schemas) can be found at support.nimsoft.com"] =
  "デフォルト テンプレート スキーマ (restmon-sample-schemas) は support.nimsoft.com にあります";

  //Friendly Name can not contain any of the following characters
  content["Friendly Name can not contain any of the following characters"] =
  "フレンドリ名には以下のいずれの文字も含めることはできません";

  // END OF LOCALIZATION

export default content;
