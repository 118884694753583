import React from 'react'
import { Route } from 'react-router-dom'
import Reports from './../components/reports/ReportsRedux'

const entityMatchRegex = '/reports/:folderIds*'

const ReportsComp = () => {
  return (
    <main>
      <Reports />
    </main>
  )
}

const ReportsRoute = () => {
  return (
      <Route path={`${entityMatchRegex}`} render={() => <ReportsComp/>} />
  )
}

export default ReportsRoute;