import React from "react";
import PropTypes from "prop-types";
import { NavLink, Route } from "react-router-dom";
import get from "lodash/get";
import {
  GridIcon,
  ListIcon,
  AlarmsIcon as AlarmIcon,
  DashboardsIcon,
  SettingsIcon as SettingIcon,
  MetricsIcon,
} from "../../../ui-components/uim-components";
import EntityTypes from "./../../../../api/entityTypes";
import "./toggleIcons.less";
import Resource from "./Resource";
import { getAlarmUrl } from "./../../../../utils/common";

import {MenuItem, Menu, IconButton, Button,Tabs,Tab,Select,Tooltip} from '@mineral/core'

import { ALARMS, DASHBOARDS, DEVICE_DETAILS, INTERFACES, MAINTENANCE, METRICS, MONITORING_CONFIG } from "../../../groups/secondaryNav/toggleIcons/ResourceKeys";
export const NAV_MENUS = {
  DETAIL_VIEW: "DETAIL_VIEW",
  ALARMS: "ALARMS",
  MAINTENANCE: "MAINTENANCE",
  METRICS: "METRICS",
  INTERFACES: "INTERFACES",
  MCS: "MCS",
  CABI: "CABI",
  DASHBOARDS:'DASHBOARDS'
};
const Link = ({ to, entityType, isDisabled, title, children }) => {
  isDisabled =
    isDisabled !== undefined ? isDisabled : entityType !== EntityTypes.GROUP;
  let link = (
    <NavLink
      to={to}
      exact
      className="link"
      activeclassname="link--active"
      title={title}
    >
      {children}
    </NavLink>
  );
  if (isDisabled) {
    link = (
      <span className="link nav-icon--disabled" title={title}>
        {children}
      </span>
    );
  }
  return link;
};

const ToggleIcons = (props, context) => {

  const [tabValue,setTabValue]=React.useState(NAV_MENUS.DASHBOARDS)

  const onNavLinkSelect=(link, menu)=> {
    setTabValue(menu)
   
      props.history.push(link);
    
  
  }
 
  return (
    <Route
      path={context.entityMatchRegex}
      render={({ match }) => {
        const pathname = match.url;
        return (

          <div
            className={`nav-icons ${
              props.location.pathname.search("dashboard") > -1
                ? "left-dashboard-nav-icons"
                : "left-nav-icons"
            }`}
          >
         
           
            <Tabs value={ pathname.endsWith("alarms")?NAV_MENUS.ALARMS:(pathname.endsWith("dashboard")?NAV_MENUS.DASHBOARDS:tabValue)} style={{marginLeft:'auto'}}>
            {props.saas &&
            props.doiurl &&
            props.featureFlags &&
            props.featureFlags.globalAlarmsView ? (
              <Tab value={NAV_MENUS.ALARMS} label={ALARMS} title={ALARMS} tabIndex={0} id={'first-content-element'}
                 
               
                 onClick={()=>{onNavLinkSelect(
                   getAlarmUrl(
                     this.props.doiurl,
                     "probe=" +
                     match.params.prid 
                   ),
                   NAV_MENUS.ALARMS
                 )}}
               >
               {ALARMS}
               </Tab>
              
            ) : (
              <Tab   id="first-content-element" value={NAV_MENUS.ALARMS} label={ALARMS} title={ALARMS} tabIndex={0} style={{maxWidth:'134px'}}
                  
                  disabled={false}
                  onClick={()=>{onNavLinkSelect(
                    `${pathname.replace('/dashboard', '/alarms')}`,
                    NAV_MENUS.ALARMS
                  )}}
                >
                  {/* <SettingIcon
                    className="nav-icon"
                    //Monitoring Config
                    title={Resource.get("Monitoring Config")}
                  /> */}
                  {ALARMS}
                </Tab>
             
            )}
         
            <Tab value={NAV_MENUS.DASHBOARDS} label={DASHBOARDS} title={DASHBOARDS} tabIndex={0} style={{maxWidth:'134px'}}
                  
                  disabled={false}
                  onClick={()=>{onNavLinkSelect(
                    `${pathname.replace('/alarms', '/dashboard')}`,
                    NAV_MENUS.DASHBOARDS
                  )}}
                >
                  {/* <SettingIcon
                    className="nav-icon"
                    //Monitoring Config
                    title={Resource.get("Monitoring Config")}
                  /> */}
                  {DASHBOARDS}
                </Tab>
           
            <Tab value={NAV_MENUS.METRICS} label={METRICS} title={METRICS} tabIndex={0} style={{maxWidth:'134px'}}
                  
                  disabled={true}
                  onClick={()=>{onNavLinkSelect(
                    `${pathname}/metrics`,
                    NAV_MENUS.METRICS
                  )}}
                >
                  {/* <SettingIcon
                    className="nav-icon"
                    //Monitoring Config
                    title={Resource.get("Monitoring Config")}
                  /> */}
                  {METRICS}
                </Tab>
           
            <Tab value={NAV_MENUS.MCS} label={MONITORING_CONFIG} title={MONITORING_CONFIG} tabIndex={0} style={{maxWidth:'134px'}}
                  
                  disabled={true}
                  onClick={()=>{onNavLinkSelect(
                    `${pathname}/configuration`,
                    NAV_MENUS.MCS
                  )}}
                >
                  {/* <SettingIcon
                    className="nav-icon"
                    //Monitoring Config
                    title={Resource.get("Monitoring Config")}
                  /> */}
                  {MONITORING_CONFIG}
                </Tab>
            </Tabs>
          </div>
        );
      }}
    />
  );
};

ToggleIcons.contextTypes = {
  entityMatchRegex: PropTypes.string,
};

export default ToggleIcons;
