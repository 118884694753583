import axios from "axios";
import config from "./../config";

class TreeView {
  getAllGroups(id) {
     var url = process.env.NODE_ENV == "development" ? 
     "http://10.252.25.208:8080/castlemock/mock/rest/project/ViXRbO/application/CHrXQ3/groups" :
     [config.basename, "api/v2/groups/stack", id].join("/");

    return axios.get(url);    
  }

  getAlarmState(id) {
    var url = process.env.NODE_ENV == "development" ?
    "http://10.252.25.208:8080/castlemock/mock/rest/project/ViXRbO/application/xb77Al/children" : 
    [config.basename, "api/v2/groups/stack", id, "child-details"].join("/");
    return axios.get(url  );
         
  }

  getGroupById(id) {
    var url = process.env.NODE_ENV == "development" ?
    "http://10.252.25.208:8080/castlemock/mock/rest/project/ViXRbO/application/xb77Al/groups/id" :
    [config.basename, "api/v2/groups", id].join("/");
    return axios.get(url);
  }

  getDeviceData(id) {
    var url = process.env.NODE_ENV == "development" ?
    "http://10.252.25.208:8080/castlemock/mock/rest/project/ViXRbO/application/xb77Al/devices" :
    [config.basename, `api/v1/computer-systems?groupId=${id}`].join("/") ;

    return axios.get(url);
  }

  getInterfaceData(id) {
    return axios.get(
      [config.basename, `api/v1/interface?groupId=${id}`].join("/")
    );
  }

  getChildDetails(id) {
    var url = process.env.NODE_ENV == "development" ?
    "http://10.252.25.208:8080/castlemock/mock/rest/project/ViXRbO/application/xb77Al/children" : 
    [config.basename, `api/v2/groups/${id}/child-details`].join("/");

    return axios.get(url  );
  }
  getCountDetails(id) {    
  var url = process.env.NODE_ENV == "development" ?
    "http://10.252.25.208:8080/castlemock/mock/rest/project/ViXRbO/application/CHrXQ3/count":
    [config.basename, `api/v2/groups/stack/${id}/group-child-count`].join("/");

    return axios.get(url);    
  }
}

let tree_view = new TreeView();
export default tree_view;
