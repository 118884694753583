import connect from "./../../utils/connect";
import InventoryRobotsDeploymentStatus from './InventoryRobotsDeploymentStatus'
import {
  fetchRobotsStatus,
  pollFetchRobotsStatus,
} from './../../api/robotsStatus/actions'

const mapStateToProps = (state) => {
  return {
    robotsStatus: state.robotsStatus.status,
    probesStatus:state.robotsStatus.probesStatus,
    probeJobIds:state.robotsStatus.probeJobIds,
    robotsStatusfulfilled:state.robotsStatus.robotsStatusfulfilled,
    reduxAdeRobotAddress: state.clientRobots.adeRobotAddress,
    reduxAdeJobIds: state.clientRobots.adeJobIds,
    featureFlags: state.configuration.items,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    // adeRobotAddress is hubRobotAddress
    getRobotsStatus: (adeRobotAddress, adeJobIds,probe,callbackEnabled) =>
      dispatch(fetchRobotsStatus({
        adeRobotAddress: adeRobotAddress,
        adeJobIds: adeJobIds,
        probe:probe,
        callbackEnabled:callbackEnabled
      })),
    pollRobotsStatus: (adeRobotAddress, adeJobIds,probe,callbackEnabled) =>
      dispatch(pollFetchRobotsStatus({
        adeRobotAddress: adeRobotAddress,
        adeJobIds: adeJobIds,
        probe:probe,
        callbackEnabled:callbackEnabled
      })),
 
   
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(InventoryRobotsDeploymentStatus)