import React, {Component} from 'react'
import {Divider} from '@mineral/core'
import './manualRobotDeploy.less'
import RobotInstallerMenu from './RobotInstallerMenu'
import RobotInstallerInstructions from './RobotInstallerInstructions'
import Resource from './Resource'

//Robot installer download and instructions
let instMsg = Resource.get('Robot installer download and instructions');
//Robots manage the probes (monitoring agents) used to collect metric data from your systems, sending the data to Infrastructure Management via the  hub.
let robotMsg = Resource.get('Robots manage the probes (monitoring agents) used to collect metric data from your systems, sending the data to Infrastructure Management via the hub.');//via relay hub
//ROBOT INSTALLERS
let robotInst = Resource.get('ROBOT INSTALLERS');

class ManualRobotDeploy extends Component {
  state = {installer: "windows"}

  handleInstallerChange = (event, value) => {
    this.setState( {installer: value} )
  }

  componentWillMount(){
    this.setState({installer: this.props.selectedInstaller})
  }

  render(){

    return (
        <div>
          <Divider />
          <div className='robot-installer__intro'>
            <p className='robot-installer__header'>{instMsg}</p>
            <p className='robot-installer__title-text'>{robotMsg}</p>
          </div>
          <Divider />
          <div className='robot-installer__download'>
            <div className='robot-installer__menu'>
              <div className='robot-installer__menu-header'>{robotInst}</div>
              <RobotInstallerMenu selectedItem={this.state.installer} onChangeHandler={this.handleInstallerChange} supportedOs={this.props.supportedOs} />
            </div>
            <RobotInstallerInstructions selectedInstaller={this.state.installer}/>
          </div>
        </div>
    )
  }
}
export default ManualRobotDeploy


