import React from 'react'
import { Route } from 'react-router-dom'
import ConfigurationRedux from './../components/configuration/ConfigurationRedux'

const Configuration = (props) => {
	let redirectToLocation=(location,id)=>{
	    if (location ==="alarmPolicy") {
  		  props.history.push('/settings/alarm-policies/' + id)
  	  }
	}
  return (
    <main>
      <ConfigurationRedux redirectToLocation={redirectToLocation} {...props}/>
    </main>
  )
}
//match /groups/<number> or /computer-systems/<number> or /groups/<number>/computer-systems/<number>
const entityMatchRegex =
  '/:groupId(\\d+)?/(onMaxEdit)?/:profileId(\\d+)?'

const ConfigurationRoute = () => {
  return (
    <Route path={`/configuration/:probe/:probeType${entityMatchRegex}`} component={Configuration}/>
  )
}

export default ConfigurationRoute;