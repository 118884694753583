import React from 'react'
import './icons.less'
import Resource from '../../../icons/Resource';

const Icon = (props) => {

//Alarms Icon
  let ALARMSTEXT=Resource.get("Alarms Icon");

  let classes = [
    'icon',
    props.className,
  ].join(' ')
  return (
    <svg className={classes} role={props.role || 'figure'} aria-label={props.ariaLabel?props.ariaLabel:ALARMSTEXT} alt={props.ariaLabel?props.ariaLabel:ALARMSTEXT} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g>
        <title>{props.title}</title>
        <path fillRule="evenodd" d="M13,15 L13,10.969 L11.031,10.969 L11.031,15 L13,15 L13,15 Z M13,18.984 L13,16.968 L11.031,16.968 L11.031,18.984 L13,18.984 L13,18.984 Z M1,21.984 L12.016,3 L23.032,21.984 L1.001,21.984 L1,21.984 Z"/>
      </g>
    </svg>
  )
}

export default Icon
