// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Elements
  content["Elements"] =
  "エレメント数";

  //Total Alarms
  content["Total Alarms"] =
  "合計アラーム数";

  //Graph Error message
  content["The data required for this graph is not currently available."] =
  "このグラフに必要なデータは現在利用できません。";

  //No results found
  content["No results found"] =
  "結果が見つかりません";

  //No subgroups have been added
  content["No devices have been added"] =
  "デバイスが追加されていません";

  //No subgroups have been added
  content["No subgroups have been added"] =
  "サブグループが追加されていません";

  //No profiles in this group
  content["No profiles in this group"] =
  "このグループのプロファイルがありません";

  //No devices have been added yet.
  content["No devices have been added yet."] =
  "まだデバイスが追加されていません。";

  // END OF LOCALIZATION

export default content;
