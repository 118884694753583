import React from 'react'
import { Route } from 'react-router-dom'
import AdminConsoleRedux from './../components/adminConsole/AdminConsoleRedux'
import connect from './../utils/connect'
import AclTypes from './../api/aclTypes'

const AdminConsole = () => {
  return (
    <main>
      <AdminConsoleRedux />
    </main>
  )
}

const AdminConsoleRoute = props => {
  if (!props.saas || props.acl.acl === AclTypes.OPERATOR) {
    return null
  }
  return <Route path="/settings/admin-console" component={AdminConsole} />
}
const mapStateToProps = state => {
  return {
    saas: state.saas,
    acl: state.acl,
  }
}

export default connect(mapStateToProps, null)(AdminConsoleRoute)
