import React from 'react'
import axios from 'axios'

class BrandName extends React.Component{
    state = {
      bName: '',
    }
    componentDidMount() {
      axios.get('/operatorconsole_portlet/api/v1/whiteLabel/brandName'
      )
        .then(res => {
          const brandName = res.data.brand_name;            
          this.setState({bName: brandName});        
        })      
        
    }
    render() {
      if(this.state.bName=='' || this.state.bName == null){
           return(<p1>DX Unified Infrastructure Management</p1>)
      }else{
            return (<p1>{this.state.bName}</p1>);
      }
    }
  }

  export default BrandName