import React from 'react'

const ComplianceSlaIcon = ({ width, height }) => {
    return (
        <svg width={width ? width : '16'} height={height ? height : '16'} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" aria-label="Compliant SLA" role="img">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 0C12.4164 0 16 3.58362 16 8C16 12.4164 12.4164 16 8 16C3.58362 16 0 12.4164 0 8C0 3.58362 3.58362 0 8 0Z"
                fill="white" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.6667 7.99992C14.6667 4.31992 11.68 1.33325 8.00004 1.33325C4.32004 1.33325 1.33337 4.31992 1.33337 7.99992C1.33337 11.6799 4.32004 14.6666 8.00004 14.6666C11.68 14.6666 14.6667 11.6799 14.6667 7.99992ZM6.87056 11.0454L6.85122 11.0261L6.84778 11.0295L3.74746 7.92922C3.49103 7.67279 3.49103 7.25702 3.74746 7.00058C4.0039 6.74414 4.41967 6.74414 4.67611 7.00058L6.86712 9.19159L10.8033 5.2554C11.0598 4.99896 11.4755 4.99896 11.732 5.2554C11.9884 5.51184 11.9884 5.9276 11.732 6.18404L6.87056 11.0454Z"
                fill="#2A854E" />
        </svg>
    )
}

export default ComplianceSlaIcon
