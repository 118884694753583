import { connect } from 'react-redux'
import { closeModal } from '../../api/modals/actions'
import ModalManager from './ModalManager'

const mapStateToProps = state => {
  return {
    selectedModal: state.selectedModal,
    daysExpired: state.trialDaysRemaining * -1,
    featureFlags: state.configuration.items,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    handleCloseModal: () => {
      dispatch(closeModal())
    },
    redirectToLogin: () => {
      window.location = '/sppclient'
    }
  }
}
const ModalManagerRedux = connect(mapStateToProps, mapDispatchToProps)(
  ModalManager
)

export default ModalManagerRedux
