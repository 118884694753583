import config from './../config'
import axios from 'axios';

class ActiveProbes {
  get(params) {
    if(process.env.NODE_ENV=='development'){
      return axios.get(
        "http://10.252.25.208:8080/castlemock/mock/rest/project/TK8t3o/application/UXxhjW/active-probes"
        );
      }
      return axios.get([config.basename, 'api/v1/cabi/active-probes'].join('/'));
    }
  }
  
  let activeProbes = new ActiveProbes()
  export default activeProbes