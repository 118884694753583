// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //For more information, click
  content["For more information, click "] =
  "Para obtener más información, haga clic en ";

  // console.log(this.props);
  content["Default system monitoring for CPU, disk & memory will be applied"] =
  "Se aplicará la monitorización del sistema predeterminada para la CPU, el disco y la memoria.";

  //Automatically monitor
  content["Automatically monitor "] =
  "Monitorizar automáticamente ";

  // devices
  content[" devices"] =
  " dispositivos";

  //HELP
  content["HELP"] =
  "Ayuda";

  // END OF LOCALIZATION

export default content;
