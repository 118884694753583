import {
  FETCH_CONFIG,
  FETCH_CONFIG_SUCCESS,
  FETCH_CONFIG_FAILURE
} from './actionTypes'
import config from './config'


export function fetchConfig() {
  return {
    type: FETCH_CONFIG
  }
}
export function fetchConfigSuccess(json) {
  return {
    type: FETCH_CONFIG_SUCCESS,
    config: json
  }
}
export function fetchConfigFailure(error) {
  return {
    type: FETCH_CONFIG_FAILURE,
    errorMessage: error.message,
    error: error.response,
  }
}
export function get() {
  return dispatch => {
    dispatch(fetchConfig())
    return config.get()
      .then(response => response.data)
      .then(json => dispatch(fetchConfigSuccess(json)))
      .catch(function(error) {
        dispatch(fetchConfigFailure(error))
      })
  }
}