import {
  REQUEST_METRIC,
  FETCH_METRIC_SUCCESS,
  FETCH_METRIC_FAILURE,
} from './actionTypes'

const initialState = {
  items: {},
}

const metrics = (state = initialState, action) => {
  let updatedItems = {}
  let newMetrics = {}
  switch (action.type) {
    case REQUEST_METRIC:
      newMetrics = Object.assign({}, state.items)
      action.data.forEach((metricId) => {
        newMetrics[metricId] = newMetrics[metricId] || {}
        newMetrics[metricId].isFetching = true
      })
      updatedItems = Object.assign({}, state.items, newMetrics)
      return Object.assign({}, state, {
        items: Object.assign({}, state.items, updatedItems)
      });
    case FETCH_METRIC_SUCCESS:
      newMetrics = Object.assign({}, state.items)
      action.data.forEach((data) => {
        newMetrics[data.metricType] = newMetrics[data.metricType] || {}
        newMetrics[data.metricType].id = data.metricType
        newMetrics[data.metricType].isFetching = false
        if (!data.error) {
          newMetrics[data.metricType].samples = data.items || []
          newMetrics[data.metricType].avg = data.avg
          newMetrics[data.metricType].max = data.max
          newMetrics[data.metricType].min = data.min
          newMetrics[data.metricType].ciName = data.ciName;
        } else {
          newMetrics[data.metricType].error = data.error
        }
      })
      updatedItems = Object.assign({}, state.items, newMetrics)
      const updatedState = Object.assign({}, state, {
        items: Object.assign({}, state.items, updatedItems)
      });
      return updatedState;
  case FETCH_METRIC_FAILURE:
    return Object.assign({}, state, {
      errorMessage: action.errorMessage,
    });

  default:
    return state
  }
}

export default metrics;
