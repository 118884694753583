import React from 'react'
import { Route } from 'react-router-dom'
import connect from './../utils/connect'
import WizardRedux from '../components/setupWizard/WizardRedux'
import AclTypes from './../api/aclTypes'

const Wizard =()=>{
    let className = ['main-setupwizard'].join(' ');
    return(
        <main className={className}>
        <WizardRedux/>
        </main>
    )
}

const WizardRoute = props =>{
   
        return (props.acl &&
            props.acl.isBusUser && (props.acl.canManageDiscovery || (props.acl.canDeployRobot && props.acl.canMonitorConfigService 
                && props.acl.canEditMCSTemplates && props.acl.canMonitorConfigService)))?(
            <Route path="/getting-started" component={Wizard} />
            ):null

    return null
}

const mapStateToProps = state => {
    return {
        saas: state.saas,
        acl: state.acl,
    }
}

export default connect(mapStateToProps,null)(WizardRoute)
