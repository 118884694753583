import React, { Component } from "react";
import {
  CenterError as Error,
  ClearIcon as Clear,
} from "../ui-components/uim-components";
import { CircularProgress } from "@mineral/core";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button as FlatButton,
  Snackbar,
  FormControlLabel,
  Radio as RadioButton,
  RadioGroup as RadioButtonGroup, Box,
  Tooltip, FormControl, InputLabel, TextField, Select, MenuItem, Grid
} from "@mineral/core";

import HelpingText from '../common/HelpingText'
import get from "lodash/get";
import entityTypes from "../../api/entityTypes";
import differenceBy from "lodash/differenceBy";
import { sortColumns } from "../../utils/tableUtils";
import rowSelectionCache from "../../utils/rowSelectionCache";
import Resource from "./Resource";
import "./dailog.less";
import maintenance from "../../api/maintenance/maintenance";
import { SCHEDULE_ID_RESOURCE } from "./Table";
import momentTZ from "moment-timezone";
import { isEqual, toInteger } from "lodash";
import { Button, DialogContent, IconButton, Typography, Checkbox } from '@mineral/core';

import moment from "moment/moment";
import CustomNumberField from '../common/customInputs/CustomNumberField'

import { MineralDateOnlyPicker } from '../common/datepickers/MineralDateOnlyPicker'
import { MineralTimePicker } from '../common/datepickers/MineralTimePicker'


import DatePickerIcon from '../icons/DatePickerIcon'

class MaintenanceDialog extends Component {
  constructor(props) {
    super(props);
    let getMonth = new Date().getMonth() + 1;
    let getdate = new Date().getDate();
    if (getMonth < 10) {
      getMonth = "0" + getMonth;
    } else {
      getMonth = getMonth;
    }
    if (getdate < 10) {
      getdate = "0" + getdate;
    } else {
      getdate = getdate;
    }
    let tomorrow = moment().add(1, 'days').toDate();
    let getMonthTmro = tomorrow.getMonth() + 1;
    let getdateTmro = tomorrow.getDate();
    if (getMonthTmro < 10) {
      getMonthTmro = "0" + getMonthTmro;
    } else {
      getMonthTmro = getMonthTmro;
    }
    if (getdateTmro < 10) {
      getdateTmro = "0" + getdateTmro;
    } else {
      getdateTmro = getdateTmro;
    }
    this.editedScheduleName = this.props.entities
      ? this.props.entities.data
        ? this.props.entities.data.schedule_name
        : ""
      : "";
    var curDate = new Date();
    curDate.setHours(curDate.getHours(), curDate.getMinutes() + 5, 0, 0);
    let timeString = curDate.toLocaleTimeString("en-US", { hour12: true });
    this.state = {
      items: [],
      totalSet: [],
      disableAddButton: true,
      pageNumber: 0,
      once: "o",
      daily: "",
      weekly: "",
      recurrenceDaysOfWeek: "1",
      schedule_name: this.props.entities
        ? this.props.entities.data
          ? this.props.entities.data.schedule_name
          : ""
        : "",
      description: "",
      account_id: -1,
      end_date: tomorrow.getFullYear() + "-" + getMonthTmro + "-" + getdateTmro,
      start_time: timeString.split(":")[0],
      time: timeString.substring(timeString.length - 2),
      start_minute: timeString.split(":")[1],
      schedule_id: "",
      minute: 0,
      duration: 1,
      duration_value: 60,
      recurrence_period: 1,
      monthly: "",
      showDate: "",
      showEndDate: false,
      the: true,
      recurrence_instance: 1,
      oNdate: false,
      recurrence_day_of_the_month: undefined,
      recurrenceDaysOfWeeks: "1",
      timezone: "Asia/Calcutta",
      startingDate: this.getFormattedDate(), //   new Date().toLocaleString().split(",")[0],
      startDate: "",
      getMonth: getMonth,
      getdate: getdate,
      start_date: new Date().getFullYear() + "-" + getMonth + "-" + getdate,
      showdataCliked: false,
      editMaintenance: false,
      delete: false,
      deletedRowId: "",
      deleteMaintenanceSystem: false,
      editedDateValue: false,
      editedEndDate: "",
      editedEndTime: "",
      recEndDate: "",
      endMaintenance: false,
      timezonesList: [],
      accounts: [],
      accountsArr: [],
      isError: false,
      errorMessage: {},
      disiableSave: false,
      savepopUpOpen: false,
      content: "",
      save_open: false,
      data: null,
      disableSchedule: false, //dp039841
      dateForPicker: curDate,
      ConfirmDailog: false,
      startDateError: null,
      endDateError: null
    };
    this.rowData = [];
    this.updateRowSelectionCache = true;
  }
  componentDidMount() {
    //console.log("Maintenance Daialog Mounted");
    this.props.getTimezones().then(() => {
      let currentTimezone = momentTZ.tz.guess();
      this.state.timezonesList.map((item) => {
        if (item.timeZoneKey == currentTimezone) {
          this.setState({
            timezone: item.timeZoneKey,
          });
        }
      });
    });
    this.props.getAccount();
  }
  componentDidUpdate(prevProps, prevState) { }
  componentWillReceiveProps(nextProps) {
    var curDate = new Date();
    curDate.setHours(curDate.getHours(), curDate.getMinutes() + 5, 0, 0);
    let timeString = curDate.toLocaleTimeString("en-US", { hour12: true });
    if (nextProps.timezones && nextProps.timezones.length > 0) {
      this.setState({
        timezonesList: nextProps.timezones,
      });
    }

    if (nextProps.accounts && nextProps.accounts.length > 0) {
      let accountArrTemp = [];
      let firstObejct = {
        accountId: -1,
        contactName: "No Account",
      };
      accountArrTemp.push(firstObejct);
      nextProps.accounts.map((item) => accountArrTemp.push(item));
      this.setState({
        accounts: nextProps.accounts,
        accountsArr: accountArrTemp,
      });
      if (!this.props.acl.isBusUser) {
        this.setState({
          account_id: nextProps.accounts[0].accountId,
        });
      }
    }
    // if (nextProps.entities === undefined || nextProps.entities === []) {
    //   this.editedScheduleName = "";
    //   this.setState({
    //     value: "",
    //     items: this.state.totalSet,
    //     once: "o",
    //     daily: "",
    //     weekly: "",
    //     recurrenceDaysOfWeek: "1",
    //     schedule_name: this.props.entities
    //       ? this.props.entities.data
    //         ? this.props.entities.data.schedule_name
    //         : this.props.entities.data.schedule_name
    //       : "",
    //     description: "",
    //     account_id: !this.props.acl.isBusUser
    //       ? nextProps.accounts && nextProps.accounts.length > 0
    //         ? nextProps.accounts[0].accountId
    //         : -1
    //       : -1,
    //     end_date: "",
    //     start_time: timeString.split(":")[0],
    //     schedule_id: "",
    //     minute: 0,
    //     duration: 1,
    //     duration_value: 60,
    //     time: timeString.substring(timeString.length - 2),
    //     start_minute: timeString.split(":")[1],
    //     recurrence_period: 1,
    //     monthly: "",
    //     showDate: "",
    //     showEndDate: false,
    //     the: true,
    //     recurrence_instance: 1,
    //     oNdate: false,
    //     recurrence_day_of_the_month: undefined,
    //     recurrenceDaysOfWeeks: 1,
    //     timezone: this.state.timezone,
    //     startingDate: this.getFormattedDate(), // new Date().toLocaleString().split(",")[0],
    //     startDate: "",
    //     showdataCliked: false,
    //     saveEnd_date: "",
    //   });
    // }

    if (nextProps.entities) {
      if (!isEqual(nextProps.entities.data, this.state.data)) {
        let hours = nextProps.entities.data.duration / 60;
        let rhours = Math.floor(hours);
        let minutes = (hours - rhours) * 60;
        var rminutes = Math.round(minutes);
        let recurranceDayofweek = Array.of(
          nextProps.entities.data.recurrence_days_of_the_week
        );
        let value = [];
        for (var i in recurranceDayofweek) {
          value.push(i);
        }
        this.editedScheduleName = nextProps.entities.data.schedule_name;
        this.setState({
          data: nextProps.entities.data,
          disableSchedule:
            nextProps.entities.data.status == "Active" ? true : false, //dp039841
          disiableSave:
            nextProps.entities.data.status == "Active" ? false : true,

          schedule_name: nextProps.entities.data.schedule_name,
          schedule_id: nextProps.entities.data.schedule_id,
          description: nextProps.entities.data.description,
          account_id: parseInt(nextProps.entities.data.account_id),
          duration: rhours,
          duration_value: nextProps.entities.data.duration,
          minute: rminutes,
          timezone: nextProps.entities.data.timezone,
          recurrence_period: nextProps.entities.data.recurrence_period,
          recurrence_instance: nextProps.entities.data.recurrence_instance
            ? nextProps.entities.data.recurrence_instance == "0"
              ? 1
              : nextProps.entities.data.recurrence_instance
            : 1,
          recurrence_day_of_the_month: nextProps.entities.data
            .recurrence_day_of_the_month
            ? nextProps.entities.data.recurrence_day_of_the_month
            : undefined,
          recurrenceDaysOfWeeks: nextProps.entities.data
            .recurrence_days_of_the_week
            ? nextProps.entities.data.recurrence_days_of_the_week
            : "1",
          //recurrenceDaysOfWeek: nextProps.entities.data
          // .recurrence_days_of_the_week
          // ? nextProps.entities.data.recurrence_days_of_the_week.split(",")
          //: [1],
          recurrenceDaysOfWeek: nextProps.entities.data
            .recurrence_days_of_the_week
            ? nextProps.entities.data.recurrence_days_of_the_week
            : "1",
        });
        if (
          nextProps.entities.data.recurrence_day_of_the_month != undefined &&
          nextProps.entities.data.recurrence_day_of_the_month != "0" &&
          nextProps.entities.data.recurrence_day_of_the_month != null
        ) {
          this.setState({
            the: false,
            oNdate: true,
          });
        }
        if (nextProps.entities.data.start_time) {
          let start = nextProps.entities.data.start_time.split(" ")[0];
          let Startyear = new Date(start).getFullYear();
          let startmonth = new Date(start).getMonth() + 1;

          if (startmonth < 10) {
            startmonth = "0" + startmonth;
          } else {
            startmonth = startmonth;
          }
          let startdates = new Date(start).getDate();
          if (startdates < 10) {
            startdates = "0" + startdates;
          } else {
            startdates = startdates;
          }
          let startDate = Startyear + "-" + startmonth + "-" + startdates;
          this.setState({
            startingDate: startmonth + "/" + startdates + "/" + Startyear,
            start_date: startDate,
          });
        }
        if (nextProps.entities.data.recurrence_pattern == "0") {
          this.setState({
            once: "o",
            daily: "",
            weekly: "",
            monthly: "",
          });
        } else if (nextProps.entities.data.recurrence_pattern == "1") {
          this.setState({
            daily: "D",
            once: "",
            weekly: "",
            monthly: "",
          });
        } else if (nextProps.entities.data.recurrence_pattern == "2") {
          this.setState({
            weekly: "W",
            daily: "",
            once: "",
            monthly: "",
          });
        } else if (nextProps.entities.data.recurrence_pattern == "3") {
          this.setState({
            monthly: "M",
            weekly: "",
            daily: "",
            once: "",
          });
        }

        if (nextProps.entities.data.recurrence_end_time === null) {
          this.setState({
            showEndDate: false,
          });
        } else {
          let end = nextProps.entities.data.recurrence_end_time.split(" ")[0];
          let endYear = new Date(end).getFullYear();
          let endMonth = new Date(end).getMonth() + 1;
          let endDate = new Date(end).getDate();
          if (endMonth < 10) {
            endMonth = "0" + endMonth;
          } else {
            endMonth = endMonth;
          }
          if (endDate < 10) {
            endDate = "0" + endDate;
          } else {
            endDate = endDate;
          }
          this.setState({
            editedDateValue: true,
            editedEndDate: endMonth + "/" + endDate + "/" + endYear,
            recEndDate: nextProps.entities.data.recurrence_end_time,
            showEndDate: true,
            end_date: endYear + "-" + endMonth + "-" + endDate,
            saveEnd_date: endMonth + "/" + endDate + "/" + endYear,
          });
        }

        let dateObject = new Date(nextProps.entities.data.start_time);
        let timeString = dateObject.toLocaleTimeString("en-US", {
          hour12: true,
        });

        let start_time = timeString.split(":")[0];
        let startMIn = timeString.split(":")[1];
        let startdate = dateObject.toLocaleDateString("en-US");
        let Startyear = dateObject.getFullYear();
        let startmonth = dateObject.getMonth() + 1;
        let time = timeString.substring(timeString.length - 2);
        if (startmonth < 10) {
          startmonth = "0" + startmonth;
        }
        let startdates = new Date(startdate).getDate();
        if (startdates < 10) {
          startdates = "0" + startdates;
        }
        let startDate = Startyear + "-" + startmonth + "-" + startdates;
        this.setState({
          start_time: start_time,
          start_minute: startMIn,
          start_date: startDate,
          time: time,
          dateForPicker: dateObject
        });
      }
    }
    if (nextProps.handleData === "Edit") {
      this.setState({
        editMaintenance: true,
      });
    } else {
      this.setState({
        editMaintenance: false,
      });
    }
    if (nextProps.handleData === "Delete") {
      this.setState({
        delete: true,
      });
    } else {
      this.setState({
        delete: false,
      });
    }
    if (nextProps.handleData === "DeleteMaintenance") {
      this.setState({
        deleteMaintenanceSystem: true,
      });
    } else {
      this.setState({
        deleteMaintenanceSystem: false,
      });
    }
    if (nextProps.handleData === "EndMaintenance") {
      this.setState({
        endMaintenance: true,
      });
    } else {
      this.setState({
        endMaintenance: false,
      });
    }
    if (nextProps.deletedRowId) {
      this.setState({
        deletedRowId: nextProps.deletedRowId,
      });
    }
    if (nextProps.open && !this.props.open) {
      this.props.getAllComputerSystems().then((computerSystemsAction) => {
        this.computerSystems = computerSystemsAction.data;
        this.items = this.getEntities(this.computerSystems);
        this.items = differenceBy(this.items, nextProps.entities, "id");
        this.items = sortColumns("name", "asc", this.items);
        if (this.state.items.length !== this.items.length) {
          this.setState({
            items: this.items,
            totalSet: this.items,
          });
        }
      });
    }
  }
  getEntities = (computerSystems) => {
    const items = get(computerSystems, "_items", []).map((item) => {
      return Object.assign({}, item, {
        entityType: entityTypes.COMPUTER_SYSTEM,
      });
    });
    return items;
  };

  getSelectedBusAccount = (items, selectedAccountId) => {
    if (items && items.length) {
      for (let i = 0; i < items.length; i++) {
        if (
          parseInt(items[i].accountId, 10) === parseInt(selectedAccountId, 10)
        ) {
          return items[i];
        }
      }
    }
  };
  getFilteredAccounts = (availAccounts, accId) => {
    let tempAcc = [];
    if (availAccounts && availAccounts.length) {
      for (let i = 0; i < availAccounts.length; i++) {
        if (parseInt(availAccounts[i].accountId, 10) !== parseInt(accId, 10)) {
          tempAcc.push(
            <MenuItem value={availAccounts[i].accountId}>
              {availAccounts[i].contactName}
            </MenuItem>
          );
        }
      }
      return tempAcc;
    }
  };
  handlekeypress = (evt) => {
    //console.log(evt)
  };
  handleNameChange = (e) => {
    let name = e.target.value;
    if (name.length) {
      this.setState({ schedule_name: name, disiableSave: true });
    }
    else {
      this.setState({ schedule_name: name, disiableSave: false });
    }
  };
  handleDiscribtionChange = (e) => {
    let description = e.target.value;
    this.setState({
      description: description,
    });
  };
  handleAccountChange = (e) => {
    let account = e.target.value;
    this.setState({
      account_id: account,
    });
  };
  handleduration = (e) => {
    if (e.target.value.length > 5) {
      e.target.value = this.state.duration;
    }
    if (e.target.value > 99999) {
      e.target.value = this.state.duration;
    }
    let val = parseInt(e.target.value, 10);
    let values = val * 60;
    let minutes = this.state.minute;
    console.log("val minutes" + val, minutes)
    this.setState({
      duration_value: values + minutes,
      duration: val,
    });
  };
  handleATChange = (data, value) => {
    if (data) {
      // this.setState({
      //   start_time: toInteger(value.slice(0,2)) ,
      //   start_minute: toInteger(value.slice(3,5)),
      //   dateForPicker: data._d
      // });

      // let time = value.slice(6,8)
      // if(time === "AM" || time === "PM") {
      //   this.setState({time});
      // }

      this.setState({
        start_time: moment(data).format("hh"),
        start_minute: moment(data).format("mm"),
        dateForPicker: moment(data).format("dd/mm/yyyy")
      });

      let time = moment(data).format("A")
      if (time === "AM" || time === "PM") {
        this.setState({ time });
      }
    }
  };

  handleminute = (e) => {
    if (e.target.value.length > 2) {
      e.target.value = this.state.minute;
    }
    if (e.target.value > 59) {
      e.target.value = this.state.minute;
    }
    let value = parseInt(e.target.value, 10);
    this.setState({
      duration_value: this.state.duration * 60 + value,
      minute: value,
    });
  };
  handletimeChange = (e) => {
    let val = e.target.value;
    this.setState({
      time: val,
    });
  };
  // handleStartMinChange = (e) => {
  //   if (e.target.value.length > 2) {
  //     e.target.value = this.state.start_minute;
  //   }
  //   if (e.target.value > 59) {
  //     e.target.value = this.state.start_minute;
  //   }
  //   let val = e.target.value;
  //   console.log(val)
  //   this.setState({
  //     start_minute: val,
  //   });
  // };
  handleEveryChange = (e) => {
    if (this.state.monthly == "M") {
      e.target.max = 12;
      if (e.target.value.length > 2) {
        e.target.value = this.state.recurrence_period;
      }
      if (e.target.value > 12) {
        e.target.value = this.state.recurrence_period;
      }
    }
    if (this.state.weekly == "W") {
      e.target.max = 52;
      if (e.target.value.length > 2) {
        e.target.value = this.state.recurrence_period;
      }
      if (e.target.value > 52) {
        e.target.value = this.state.recurrence_period;
      }
    }
    if (this.state.daily == "D") {
      e.target.max = 365;
      if (e.target.value.length > 3) {
        e.target.value = this.state.recurrence_period;
      }
      if (e.target.value > 365) {
        e.target.value = this.state.recurrence_period;
      }
    }
    let val = e.target.value;
    this.setState({
      recurrence_period: val,
    });
  };
  handleMonthlyCLick = (e) => {
    this.setState({
      monthly: "M",
      weekly: "",
      daily: "",
      once: "",
      showdataCliked: false,
    });
  };
  handleclick = () => {
    this.setState({
      showdataCliked: false,
    });
  };
  handlerecurranceInstance = (e) => {
    let val = e.target.value;
    this.setState({
      recurrence_instance: val,
    });
  };
  convertTime12to24 = (time12h) => {
    const [time, modifier] = time12h.split(" ");

    let [hours, minutes] = time.split(":");

    if (hours === "12") {
      hours = "00";
    }

    if (modifier === "PM") {
      hours = parseInt(hours, 10) + 12;
    }

    return `${hours}:${minutes}`;
  };
  handlesvemethod = () => {
    if (this.state.duration < 0 || isNaN(this.state.duration)) {
      let msg = Resource.get("Hour value is invalid"); // Hour value is invalid
      this.setError({ message: msg });
    } else if (isNaN(this.state.minute)) {
      let msg = Resource.get("Minute value is invalid"); // Minute value is invalid
      this.setError({ message: msg });
    } else {
      this.setState({ isError: false, errorMessage: "", disiableSave: false });
      this.handleSave();
    }
  };
  hanldeErrorMethod = () => {
    this.setState({
      savepopUpOpen: true,
      content: "Error in adding the Maintenance",
      disiableSave: true,
      save_open: true,
    });
  };
  handleafterSave = (value) => {
    var curDate = new Date();
    curDate.setHours(curDate.getHours(), curDate.getMinutes() + 5, 0, 0);
    let timeString = curDate.toLocaleTimeString("en-US", { hour12: true });
    this.setState({
      savepopUpOpen: true,
      content: value,
      disiableSave: true,
      save_open: true,
      data: null,
      value: "",
      items: this.state.totalSet,
      once: "o",
      daily: "",
      weekly: "",
      recurrenceDaysOfWeek: "1",
      schedule_name: this.props.entities
        ? this.props.entities.data
          ? this.props.entities.data.schedule_name
          : this.props.entities.data.schedule_name
        : "",
      description: "",
      account_id: -1,
      end_date: "",
      start_time: timeString.split(":")[0],
      time: timeString.substring(timeString.length - 2),
      start_minute: timeString.split(":")[1],
      schedule_id: "",
      minute: 0,
      duration: 1,
      duration_value: 60,
      recurrence_period: 1,
      monthly: "",
      showDate: "",
      showEndDate: false,
      the: true,
      recurrence_instance: 1,
      oNdate: false,
      recurrence_day_of_the_month: undefined,
      recurrenceDaysOfWeeks: "1",
      timezone: this.state.timezone,
      startingDate: this.getFormattedDate(), // new Date().toLocaleString().split(",")[0],
      startDate: "",
      //  getMonth:getMonth,
      //  getdate:getdate,
      //  start_date:new Date().getFullYear() + "-" + getMonth +"-" + getdate,
      showdataCliked: false,
      dateForPicker: curDate
    });
    rowSelectionCache.clear();
    this.props.handleClose("addDeviceToGroupOpen");
    this.props.getMaintenance();
  };
  handleafterSaveError = (error) => {
    var curDate = new Date();
    curDate.setHours(curDate.getHours(), curDate.getMinutes() + 5, 0, 0);
    let timeString = curDate.toLocaleTimeString("en-US", { hour12: true });
    this.setState({
      savepopUpOpen: true,
      disiableSave: true,
      save_open: true,
      content: error.detail,
      errorMessage: error,
      data: null,
      value: "",
      items: this.state.totalSet,
      once: "o",
      daily: "",
      weekly: "",
      recurrenceDaysOfWeek: "1",
      schedule_name: this.props.entities
        ? this.props.entities.data
          ? this.props.entities.data.schedule_name
          : this.props.entities.data.schedule_name
        : "",
      description: "",
      account_id: -1,
      end_date: "",
      schedule_id: "",
      minute: 0,
      duration: 1,
      duration_value: 60,
      start_time: timeString.split(":")[0],
      time: timeString.substring(timeString.length - 2),
      start_minute: timeString.split(":")[1],
      recurrence_period: 1,
      monthly: "",
      showDate: "",
      showEndDate: false,
      the: true,
      recurrence_instance: 1,
      oNdate: false,
      recurrence_day_of_the_month: undefined,
      recurrenceDaysOfWeeks: "1",
      timezone: this.state.timezone,
      startingDate: this.getFormattedDate(), // new Date().toLocaleString().split(",")[0],
      startDate: "",
      //  getMonth:getMonth,
      //  getdate:getdate,
      //  start_date:new Date().getFullYear() + "-" + getMonth +"-" + getdate,
      showdataCliked: false,
      dateForPicker: curDate
    });
    rowSelectionCache.clear();
    this.props.handleClose("addDeviceToGroupOpen");
    this.props.getMaintenance();
  };
  handleDuplicateRecords = () => {
    this.setState({
      savepopUpOpen: true,
      // save_open:true,
      content: "Duplicate Records",
      disiableSave: true,
    });
  };

  handleSave = (e) => {
    // this.handlesvemethod("save")
    if (this.editedScheduleName != this.state.schedule_name) {
      if (this.props.maintenanceItems != null) {
        if (
          this.props.maintenanceItems.filter(
            (data) =>
              data.schedule_name.toLowerCase().trim() ==
              this.state.schedule_name.toLowerCase().trim()
          ).length > 0
        ) {
          //if(this.state.schedule_id.length!=0){
          //Duplicate Message
          let msg = Resource.get("The entered Schedule Name already exists");
          this.setError({ message: msg });
          this.setState({ disiableSave: true });
          return;
          //}
        }
      }
    }
    let Starttime;
    let startMin;
    let convertedTime;
    //  if(this.props.handleData === undefined || this.props.handleData === null){
    let stateHours = this.state.start_time;
    let stateMins = this.state.start_minute;
    if (stateHours) {
      if (stateHours < 10 && `${stateHours}`.charAt(0) != "0") {
        Starttime = "0" + stateHours;
      } else {
        Starttime = stateHours;
      }
    }
    if (stateMins) {
      if (parseInt(stateMins) < 10 && `${stateMins}`.charAt(0) != "0") {
        startMin = "0" + stateMins;
      } else {
        startMin = stateMins;
      }
    } else {
      startMin = "00";
    }

    // }
    convertedTime = this.convertTime12to24(
      `${Starttime}:${startMin} ${this.state.time}`
    );
    let convertedEndTime = "23:59";

    let credentialsInfo = {};
    if (this.state.schedule_id === "") {
      if (this.state.once == "o") {
        credentialsInfo.schedule_name = this.state.schedule_name;
        credentialsInfo.description = this.state.description;
        credentialsInfo.account_id = this.state.account_id;
        credentialsInfo.start_time =
          this.state.startingDate.split(",")[0] + " " + convertedTime;
        credentialsInfo.end_time = this.state.showEndDate
          ? this.state.saveEnd_date != ""
            ? this.state.saveEnd_date + " " + "23:59"
            : null
          : null;
        credentialsInfo.duration = this.state.duration_value;
        credentialsInfo.recurrence_pattern = null;
        credentialsInfo.recurrence_period = 1;
        credentialsInfo.recurrence_days_of_the_week = null;
        credentialsInfo.recurrence_day_of_the_month = null;
        credentialsInfo.recurrence_instance = null;
        credentialsInfo.recurrence_end_time = null;
        credentialsInfo.windowStart = null;
        credentialsInfo.windowEnd = null;
        credentialsInfo.timezone = this.state.timezone;
        credentialsInfo.nextFire = "";
        credentialsInfo.initialCsIds = null;
        credentialsInfo.cs_ids = null;
      }
      if (this.state.daily == "D") {
        credentialsInfo.schedule_name = this.state.schedule_name;
        credentialsInfo.description = this.state.description;
        credentialsInfo.account_id = this.state.account_id;
        credentialsInfo.start_time =
          this.state.startingDate.split(",")[0] + " " + convertedTime;
        credentialsInfo.end_time = this.state.showEndDate
          ? this.state.saveEnd_date != ""
            ? this.state.saveEnd_date + " " + "23:59"
            : null
          : null;
        credentialsInfo.duration = this.state.duration_value;
        credentialsInfo.recurrence_pattern = "D";
        credentialsInfo.recurrence_period = this.state.recurrence_period;
        credentialsInfo.recurrence_days_of_the_week = null;
        credentialsInfo.recurrence_day_of_the_month = null;
        credentialsInfo.recurrence_instance = null;
        credentialsInfo.recurrence_end_time = null;
        credentialsInfo.windowStart = null;
        credentialsInfo.windowEnd = null;
        credentialsInfo.timezone = this.state.timezone;
        credentialsInfo.nextFire = "";
        credentialsInfo.initialCsIds = null;
        credentialsInfo.cs_ids = null;
      }
      if (this.state.monthly == "M") {
        credentialsInfo.schedule_name = this.state.schedule_name;
        credentialsInfo.description = this.state.description;
        credentialsInfo.account_id = this.state.account_id;
        credentialsInfo.start_time =
          this.state.startingDate.split(",")[0] + " " + convertedTime;
        credentialsInfo.end_time = this.state.showEndDate
          ? this.state.saveEnd_date != ""
            ? this.state.saveEnd_date + " " + "23:59"
            : null
          : null;
        credentialsInfo.duration = this.state.duration_value;
        credentialsInfo.recurrence_pattern = "M";
        credentialsInfo.recurrence_period = this.state.recurrence_period;
        credentialsInfo.recurrence_days_of_the_week = this.state
          .recurrenceDaysOfWeeks
          ? this.state.recurrenceDaysOfWeeks
          : null;
        credentialsInfo.recurrence_day_of_the_month = this.state
          .recurrence_day_of_the_month
          ? this.state.recurrence_day_of_the_month != "0"
            ? this.state.recurrence_day_of_the_month
            : null
          : null;
        credentialsInfo.recurrence_instance =
          this.state.recurrence_instance != null
            ? this.state.recurrence_instance
            : this.state.recurrence_day_of_the_month == null
              ? 1
              : null;
        credentialsInfo.recurrence_end_time = null;
        credentialsInfo.windowStart = null;
        credentialsInfo.windowEnd = null;
        credentialsInfo.timezone = this.state.timezone;
        credentialsInfo.nextFire = "";
        credentialsInfo.initialCsIds = null;
        credentialsInfo.cs_ids = null;
      }
      if (this.state.weekly === "W") {
        credentialsInfo.schedule_name = this.state.schedule_name;
        credentialsInfo.description = this.state.description;
        credentialsInfo.account_id = this.state.account_id;
        credentialsInfo.start_time =
          this.state.startingDate.split(",")[0] + " " + convertedTime;
        credentialsInfo.end_time = this.state.showEndDate
          ? this.state.saveEnd_date != ""
            ? this.state.saveEnd_date + " " + "23:59"
            : null
          : null;
        credentialsInfo.duration = this.state.duration_value;
        credentialsInfo.recurrence_pattern = "W";
        credentialsInfo.recurrence_period = this.state.recurrence_period;
        credentialsInfo.recurrence_days_of_the_week =
          this.state.recurrenceDaysOfWeek.length > 0
            ? Array.isArray(this.state.recurrenceDaysOfWeek)
              ? this.state.recurrenceDaysOfWeek.join()
              : this.state.recurrenceDaysOfWeek
            : null;
        credentialsInfo.recurrence_day_of_the_month = null;
        credentialsInfo.recurrence_instance = null;
        credentialsInfo.recurrence_end_time = null;
        credentialsInfo.windowStart = null;
        credentialsInfo.windowEnd = null;
        credentialsInfo.timezone = this.state.timezone;
        credentialsInfo.nextFire = "";
        credentialsInfo.initialCsIds = null;
        credentialsInfo.cs_ids = null;
      }

      //Attach Device to Maintenance Schedule
      if (this.props.initialcsIds) {
        credentialsInfo.initialCsIds = this.props.initialcsIds;
        credentialsInfo.cs_ids = [this.props.initialcsIds];
      }

      //Add
      maintenance.saveMaintenance(credentialsInfo).then(
        (response) => {
          if (response) {
            this.handleafterSave("Saved Successfully");

            // this.onClose();
          }
          // this.props.getMaintenance();
          // this.onClose();
        },
        (err) => {
          let errMssg = Resource.get("Error Adding Maintenance"); //Error Adding Maintenance
          let errorDescription = err.response.data.errorMsg;
          let errorDetail = err.response.data.errorDetail;
          let getError = [];
          if (errorDetail != undefined) {
            if (errorDetail.includes("Note:")) {
              getError.push(errorDetail.split("Note:"))
            }
            else {
              getError.push(errorDetail);
            }
          }
          else {
            getError.push(errMssg);
          }
          let error = {
            message: getError[0],
            description: errorDescription,
            detail: errorDetail,
          };
          if (errorDescription == "Schedule Configuration Warning") {
            this.handleafterSaveError(error);
          } else {
            this.setState({ disiableSave: true });
            this.setError(error);
          }
          //console.log(err);
        }
      );
    } else {
      if (this.state.once == "o") {
        credentialsInfo.schedule_name = this.state.schedule_name;
        credentialsInfo.schedule_id = this.state.schedule_id;
        credentialsInfo.description = this.state.description;
        credentialsInfo.account_id = this.state.account_id;
        credentialsInfo.start_time =
          this.state.startingDate + " " + convertedTime;
        credentialsInfo.end_time = this.state.showEndDate
          ? this.state.editedDateValue
            ? this.state.editedEndDate + " " + convertedEndTime
            : this.state.saveEnd_date != ""
              ? this.state.saveEnd_date + " " + "23:59"
              : null
          : null;
        credentialsInfo.duration = this.state.duration_value;
        credentialsInfo.recurrence_pattern = null;
        credentialsInfo.recurrence_period = 1;
        credentialsInfo.recurrence_days_of_the_week = null;
        credentialsInfo.recurrence_day_of_the_month = null;
        credentialsInfo.recurrence_instance = null;
        credentialsInfo.recurrence_end_time = null;
        credentialsInfo.windowStart = null;
        credentialsInfo.windowEnd = null;
        credentialsInfo.timezone = this.state.timezone;
        credentialsInfo.nextFire = null;
        credentialsInfo.initialCsIds = null;
        credentialsInfo.cs_ids = null;
      }
      if (this.state.daily == "D") {
        credentialsInfo.schedule_id = this.state.schedule_id;
        credentialsInfo.schedule_name = this.state.schedule_name;
        credentialsInfo.description = this.state.description;
        credentialsInfo.account_id = this.state.account_id;
        credentialsInfo.start_time =
          this.state.startingDate + " " + convertedTime;
        credentialsInfo.end_time = this.state.showEndDate
          ? this.state.editedDateValue
            ? this.state.editedEndDate + " " + convertedEndTime
            : this.state.saveEnd_date != ""
              ? this.state.saveEnd_date + " " + "23:59"
              : null
          : null;
        credentialsInfo.duration = this.state.duration_value;
        credentialsInfo.recurrence_pattern = "D";
        credentialsInfo.recurrence_period = this.state.recurrence_period;
        credentialsInfo.recurrence_days_of_the_week = null;
        credentialsInfo.recurrence_day_of_the_month = null;
        credentialsInfo.recurrence_instance = null;
        credentialsInfo.recurrence_end_time = null;
        credentialsInfo.windowStart = null;
        credentialsInfo.windowEnd = null;
        credentialsInfo.timezone = this.state.timezone;
        credentialsInfo.nextFire = null;
        credentialsInfo.initialCsIds = null;
        credentialsInfo.cs_ids = null;
      }
      if (this.state.monthly == "M") {
        credentialsInfo.schedule_name = this.state.schedule_name;
        credentialsInfo.schedule_id = this.state.schedule_id;
        credentialsInfo.description = this.state.description;
        credentialsInfo.account_id = this.state.account_id;
        credentialsInfo.start_time =
          this.state.startingDate.split(",")[0] + " " + convertedTime;
        credentialsInfo.end_time = this.state.showEndDate
          ? this.state.editedDateValue
            ? this.state.editedEndDate + " " + convertedEndTime
            : this.state.saveEnd_date != ""
              ? this.state.saveEnd_date + " " + "23:59"
              : null
          : null;
        credentialsInfo.duration = this.state.duration_value;
        credentialsInfo.recurrence_pattern = "M";
        credentialsInfo.recurrence_period = this.state.recurrence_period;
        credentialsInfo.recurrence_days_of_the_week = this.state
          .recurrenceDaysOfWeeks
          ? this.state.recurrenceDaysOfWeeks
          : null;
        credentialsInfo.recurrence_day_of_the_month = this.state
          .recurrence_day_of_the_month
          ? this.state.recurrence_day_of_the_month != "0"
            ? this.state.recurrence_day_of_the_month
            : null
          : null;
        credentialsInfo.recurrence_instance =
          this.state.recurrence_instance != null
            ? this.state.recurrence_instance
            : this.state.recurrence_day_of_the_month == null
              ? 1
              : null;
        credentialsInfo.recurrence_end_time = null;
        credentialsInfo.windowStart = null;
        credentialsInfo.windowEnd = null;
        credentialsInfo.timezone = this.state.timezone;
        credentialsInfo.nextFire = "";
        credentialsInfo.initialCsIds = null;
        credentialsInfo.cs_ids = null;
      }
      if (this.state.weekly == "W") {
        credentialsInfo.schedule_name = this.state.schedule_name;
        credentialsInfo.schedule_id = this.state.schedule_id;
        credentialsInfo.description = this.state.description;
        credentialsInfo.account_id = this.state.account_id;
        credentialsInfo.start_time =
          this.state.startingDate.split(",")[0] + " " + convertedTime;
        credentialsInfo.end_time = this.state.showEndDate
          ? this.state.editedDateValue
            ? this.state.editedEndDate + " " + convertedEndTime
            : this.state.saveEnd_date != ""
              ? this.state.saveEnd_date + " " + "23:59"
              : null
          : null;
        credentialsInfo.duration = this.state.duration_value;
        credentialsInfo.recurrence_pattern = "W";
        credentialsInfo.recurrence_period = this.state.recurrence_period;
        credentialsInfo.recurrence_days_of_the_week =
          this.state.recurrenceDaysOfWeek.length > 0
            ? Array.isArray(this.state.recurrenceDaysOfWeek)
              ? this.state.recurrenceDaysOfWeek.join()
              : this.state.recurrenceDaysOfWeek
            : null;
        credentialsInfo.recurrence_day_of_the_month = this.state
          .recurrence_day_of_the_month
          ? this.state.recurrence_day_of_the_month != "0"
            ? this.state.recurrence_day_of_the_month
            : null
          : null;
        credentialsInfo.recurrence_instance = null;
        credentialsInfo.recurrence_end_time = null;
        credentialsInfo.windowStart = null;
        credentialsInfo.windowEnd = null;
        credentialsInfo.timezone = this.state.timezone;
        credentialsInfo.nextFire = null;
        credentialsInfo.initialCsIds = null;
        credentialsInfo.cs_ids = null;
      }
      maintenance.editMaintenance(credentialsInfo).then(
        (response) => {
          if (response) {
            this.handleafterSave("Updated Successfully");

            // this.onClose();
          }
          // this.props.getMaintenance();
          // this.onClose();
        },
        (err) => {
          let errMssg = Resource.get("Error editing  Maintenance"); //Error editing  Maintenance
          let errorDescription = err.response.data.errorMsg;
          let errorDetail = err.response.data.errorDetail;
          let error = {
            message: errMssg,
            description: errorDescription,
            detail: errorDetail,
          };
          if (errorDescription == "Schedule Configuration Warning") {
            this.handleafterSaveError(error);
          } else {
            this.setError(error);
          }
          // console.log(error);
        }
      );
    }
  };

  handleDailyClick = (changeEvent) => {
    this.setState({
      daily: "D",
      once: "",
      weekly: "",
      monthly: "",
      showdataCliked: false,
    });
  };
  handleScheduleTypeChange(changeEvent) {
    switch (changeEvent.target.value) {
      case "o":
        this.setState({
          once: "o",
          daily: "",
          weekly: "",
          monthly: "",
        });
        break;
      case "D":
        this.setState({
          daily: "D",
          once: "",
          weekly: "",
          monthly: "",
          recurrence_period: 1,
          showdataCliked: false,
        });
        break;
      case "W":
        this.setState({
          weekly: "W",
          daily: "",
          once: "",
          monthly: "",
          recurrence_period: 1,
          showdataCliked: false,
        });
        break;
      case "M":
        this.setState({
          monthly: "M",
          weekly: "",
          daily: "",
          once: "",
          recurrence_period: 1,
          showdataCliked: false,
        });
        break;
    }
  }
  handleWeeklyClick = (changeEvent) => {
    this.setState({
      weekly: "W",
      daily: "",
      once: "",
      monthly: "",
      showdataCliked: false,
    });
  };
  onCloseConfirmDailog = () => {
    this.setState({ ConfirmDailog: false })
  }
  onCloseMaintenaceDailog = () => {
    this.setState({ ConfirmDailog: true })
    this.onClose() //vs044513, Fix for DE554426, 10/01/2023
  }
  onClose = () => {
    var curDate = new Date();
    curDate.setHours(curDate.getHours(), curDate.getMinutes() + 5, 0, 0);
    let timeString = curDate.toLocaleTimeString("en-US", { hour12: true });
    this.setState({
      save_open: false,
      savepopUpOpen: false,
      disiableSave: false,
      value: "",
      data: null,
      items: this.state.totalSet,
      once: "o",
      daily: "",
      weekly: "",
      recurrenceDaysOfWeek: "1",
      schedule_name: this.props.entities
        ? this.props.entities.data
          ? this.props.entities.data.schedule_name
          : this.props.entities.data.schedule_name
        : "",
      description: "",
      account_id: -1,
      end_date: moment().add(1, 'days'),
      endDateError: null,
      schedule_id: "",
      minute: 0,
      duration: 1,
      duration_value: 60,
      start_time: timeString.split(":")[0],
      time: timeString.substring(timeString.length - 2),
      start_minute: timeString.split(":")[1],
      recurrence_period: 1,
      monthly: "",
      showDate: "",
      showEndDate: false,
      the: true,
      recurrence_instance: 1,
      oNdate: false,
      recurrence_day_of_the_month: undefined,
      recurrenceDaysOfWeeks: "1",
      timezone: this.state.timezone,
      startingDate: this.getFormattedDate(), // new Date().toLocaleString().split(",")[0],
      startDate: "",
      //  getMonth:getMonth,
      //  getdate:getdate,
      //  start_date:new Date().getFullYear() + "-" + getMonth +"-" + getdate,
      showdataCliked: false,
      isError: false,
      dateForPicker: curDate,
      ConfirmDailog: false
    });
    rowSelectionCache.clear();
    this.props.handleClose("addDeviceToGroupOpen");
  };
  handleEndDateChange = (e) => {
    //  let val = e.target.value;
    let dateTime = moment(e).toDate();
    let year = new Date(dateTime).getFullYear();
    let month = new Date(dateTime).getMonth() + 1;
    let date = new Date(dateTime).getDate();
    let value = month + "/" + date + "/" + year;
    if (moment(e)?.isBefore()) {
      this.setState(
        {
          end_date: dateTime,
          editedDateValue: true,
          saveEnd_date: value,
          editedEndDate: value,
          endDateError: "Date cannot be a past date",
        });
    } else {
      this.setState({
        end_date: dateTime,
        editedDateValue: true,
        saveEnd_date: value,
        editedEndDate: value,
        endDateError: null
      });
    }

  };
  getFormattedDate = () => {
    let currentDate = new Date();
    let year = currentDate.getFullYear();
    let checkmonth = currentDate.getMonth() + 1;
    let checkdate = currentDate.getDate();
    if (checkmonth < 10) {
      checkmonth = "0" + checkmonth;
    } else {
      checkmonth = checkmonth;
    }
    if (checkdate < 10) {
      checkdate = "0" + checkdate;
    } else {
      checkdate = checkdate;
    }
    return checkmonth + "/" + checkdate + "/" + year;
  };
  handleStartDateChange = (e) => {
    // let val = e.target.value;
    //let dateTime = val + " 00:00";
    let dateTime = moment(e).toDate();
    let year = new Date(dateTime).getFullYear();
    let month = new Date(dateTime).getMonth() + 1;
    let date = new Date(dateTime).getDate();
    let value = month + "/" + date + "/" + year;
    if (moment(e)?.isBefore()) {
      this.setState(
        {
          startingDate: value,
          start_date: dateTime,
          startDateError: "Date cannot be a past date",

        });
    } else {
      this.setState({
        startingDate: value,
        start_date: dateTime,
        startDateError: null,

      });
    }
  };
  handleShowDataClicked = (e) => {
    let val = e.target.value;
    this.setState({
      showdataCliked: true,
      start_date: val,
      showEndDate: false,
    });
  };
  handlneverClick = () => {
    this.setState({
      showEndDate: true,
    });
  };
  handlecrossClick = () => {
    this.setState({
      showEndDate: false,
    });
  };
  handleDate = (e, date) => {
    // let val=e.target.checked ;
    date.setHours(this.state.startDateTime.getHours());
    date.setMinutes(this.state.startDateTime.getMinutes());
    this.setState(
      {
        startDateTime: date,
        scheduleModified: true,
      },
      this.handleFinishButton
    );
  };

  handleWeekchnage = (e) => {
    var options = e.target.value;
    // var recurrenceDaysOfWeek = [];
    // recurrenceDaysOfWeek.push(typeof options === 'string' ? options.split(',') : value);
    /* for (var i = 0, l = options.length; i < l; i++) {
       if (options[i].selected) {
         recurrenceDaysOfWeek.push(options[i].value);
       }
     }*/
    const optionsArray = Array.isArray(options) ? options : [options]
    const finalArray = [];
    optionsArray.forEach((i) => {
      const existingIndex = finalArray.findIndex(j => j.toString() === i.toString())
      if (existingIndex >= 0) {
        finalArray.splice(existingIndex, 1)
      } else {
        if(i.length != 0)
        finalArray.push(i)
      }
    });
    this.setState({
      recurrenceDaysOfWeek: finalArray.join(),
    });
  };

  handleMonthlychnage = (e) => {
    let val = e.target.value;
    this.setState({
      recurrenceDaysOfWeeks: val,
    });
  };
  handleONdateChange = () => {
    this.setState({
      oNdate: true,
      the: false,
    });
  };
  handleTheChanage = () => {
    this.setState({
      oNdate: false,
      the: true,
      recurrence_day_of_the_month: undefined,
    });
  };
  handleDaychnage = (e) => {
    let val = e.target.value;
    this.setState({
      recurrence_day_of_the_month: val,
      recurrence_instance: null,
      recurrenceDaysOfWeek: null,
    });
  };
  handletimezoneChange = (e) => {
    let val = e.target.value;
    this.setState({
      timezone: val,
    });
  };
  handledeleteMaintenance = () => {
    let schedule_id = parseInt(this.state.deletedRowId.schedule_id);
    maintenance.deleteMaintenance(schedule_id).then(
      (response) => {
        if (response) {
          this.handleafterSave("Deleted Successfully");

          // this.onClose();
        }
        // this.props.getMaintenance();
        // this.onClose();
      },
      (err) => {
        let errMssg = Resource.get("Error Deleting Maintenace"); //Error Deleting Maintenance
        let errorDescription = err.response.data.errorMsg;
        let errorDetail = err.response.data.errorDetail;
        let error = {
          message: errMssg,
          description: errorDescription,
          detail: errorDetail,
        };
        this.setError(error);
        // console.log(err);
      }
    );
  };
  handledeleteMaintenancesystem = () => {
    let schedule_id = parseInt(this.state.deletedRowId.schedule_id);
    maintenance.deleteMaintenanceSystem(schedule_id).then(
      (response) => {
        if (response) {
          this.handleafterSave("Deleted  Successfully");

          // this.onClose();
        }
        // this.onClose();
      },
      (err) => {
        let errMssg = Resource.get("Error Deleting Maintenance System"); //Error Deleting Maintenance System
        let errorDescription = err.response.data.errorMsg;
        let error = {
          message: errMssg,
          description: errorDescription,
        };
        this.setError(error);
        // console.log(error);
      }
    );
  };
  handleEndMaintenance = () => {
    let schedule_id = parseInt(this.state.deletedRowId.schedule_id);
    maintenance.endMaintenance(schedule_id).then(
      (response) => {
        if (response) {
          this.handleafterSave("Ended successfully");
        }
        // this.onClose();
      },
      (err) => {
        let errMssg = Resource.get("Error Deleting End Maintenance"); //Error Deleting End Maintenance
        let errorDescription = err.response.data.errorMsg;
        let error = {
          message: errMssg,
          description: errorDescription,
        };
        this.setError(error);
      }
    );
  };

  setError(error) {
    // console.log(error);
    this.setState({
      isError: true,
      errorMessage: error,
    });
  }

  render() {
    console.log(this.state.recurrenceDaysOfWeek)

    const noEndDateLabel = Resource.get("No End Date"); //End Date
    let save_actions = [
      <FlatButton
        //Cancel
        children={Resource.get("Cancel")}
        variant="text"
        color="primary"
        onClick={this.onClose}
      />,
    ];
    if (this.state.savepopUpOpen === true) {
      return (
        <div>
          <Snackbar
            className="maintenanceSnackbar"
            open={this.state.savepopUpOpen}
            message={this.state.content}
            autoHideDuration={2000}
            // action={save_actions}
            onActionTouchTap={this.onDescription}
            onClose={this.onClose}
            style={{
              minWidth: "unset !important"
            }}
          />
        </div>
      );
    }
    //   if(this.state.savepopUpOpen === true){

    //     return (     <Snackbar
    //       open={true}
    //       message={this.state.content}
    //       autoHideDuration={4000}
    //       action="succes Description"
    //       onActionTouchTap={this.onErrorDescription}
    //       onRequestClose={this.onClose()}
    //     />)
    // //    return (
    // //    <Dialog
    // //     className="addDevice__dialog"
    // //     contentStyle={{
    // //       width: "30vw",
    // //       maxWidth: null,
    // //       height: "40vh !important",
    // //     }}
    // //     bodyStyle={{
    // //       paddingBottom: "0px",
    // //       paddingTop: "0px",
    // //       maxHeight: "230px",
    // //       height: "40vh !important",
    // //     }}
    // //     title={title}
    // //    actions={save_actions}
    // //     modal={false}
    // //     open={true}

    // //   >
    // //     <div style={{ margin: "0 auto" }}>
    // //       <div><p>{this.state.content}</p></div>

    // //     </div>
    // //   </Dialog>
    // // )
    //   }
    let getMonth = new Date().getMonth() + 1;
    if (getMonth < 10) {
      getMonth = "0" + getMonth;
    } else {
      getMonth = getMonth;
    }
    let date = new Date().getDate();
    if (date < 10) {
      date = "0" + date;
    } else {
      date = date;
    }
    let min = new Date().getFullYear() + "-" + getMonth + "-" + date;

    let deleteMaintenanceHeadText = Resource.get("Delete the Maintenance?"); //Delete the Maintenance?
    //Are you sure to delete this Maintenance permanently?
    let deleteMaintenanceBodyText = Resource.get(
      "Are you sure to delete this Maintenance permanently?"
    );
    let removeMaintenanceHeadText = Resource.get("Remove the Maintenance?"); //Remove the Maintenance?
    //Are you sure to remove all the devices from this Maintenance?
    let removeMaintenanceBodyText = Resource.get(
      "Are you sure to remove all the devices from this Maintenance?"
    );
    let endMaintenanceHeadText = Resource.get("End the Maintenance?"); //Remove the Maintenance?
    //Are you sure to stop this Maintenance?
    let endMaintenanceBodyText = Resource.get(
      "Are you sure to stop this Maintenance?"
    );
    let yesLabel = Resource.get("Yes"); //Yes
    let noLabel = Resource.get("No"); //No
    let timezoneOptions =
      this.state.timezonesList &&
      this.state.timezonesList.map((item) => (
        <MenuItem value={item.timeZoneKey}>{item.timeZoneValue}</MenuItem>
      ));
    let accountOptions =
      this.state.accounts &&
      this.state.accounts.map((item) => (
        <MenuItem value={item.accountId}>{item.contactName}</MenuItem>
      ));
    let selectedAccount = this.getSelectedBusAccount(
      this.state.accounts,
      this.state.account_id
    );
    let filteredAccounts = this.getFilteredAccounts(
      this.state.accountsArr,
      this.state.account_id
    );
    let finalAccounts = this.props.acl.isBusUser ? accountOptions : [];

    const createMsg = Resource.get("Create Maintenance Schedule"); //Create Maintenance schedule
    // const helplabelmessage = this.props.initialDeviceName
    //   ? this.props.initialDeviceName + " - " + createMsg
    //   : createMsg;
    const initialDeviceName = this.props.initialDeviceName
      ? this.props.initialDeviceName : "";
    const helplabelmessage = createMsg;
    const editMaintenanceLabel = Resource.get("Edit Maintenance Schedule"); //edit
    //Nodevicestoadd
    const helplabelmessage1 = Resource.get("No devices to add");
    const name = Resource.get("Name"); //Name
    const star = Resource.get("*"); //start
    const description = Resource.get("Description:"); //Description
    const account = Resource.get("Account"); // AccountDetails
    const noaccount = Resource.get("No Account"); // NOAccount
    const oPeratorconsole = Resource.get("Operator console"); //  Operatorconsolent
    const once = Resource.get("Once"); //  once
    const daily = Resource.get("Daily"); //  daily
    const weekly = Resource.get("Weekly"); //  weekly
    const monthly = Resource.get("Monthly"); //  monthly
    const every = Resource.get("Every"); //  Every
    const day = Resource.get("Day(s)"); //  day
    const days = Resource.get("Days"); //  days
    const week = Resource.get("Week(s)"); //  Week
    const month = Resource.get("Month(s)"); //  Month
    const ending = Resource.get("Schedule End Date"); //  Schedule End Date
    const ex = Resource.get("X"); //x
    const never = Resource.get("Never"); //never
    const starting = Resource.get("Schedule Start Date"); //Schedule Start Date
    const timeZone = Resource.get("TimeZone"); //timeZone
    const am = Resource.get("AM"); //am
    const pm = Resource.get("PM"); //PM
    const calcutta = Resource.get("(UTC+05:30)calcutta"); //(UTC+05:30)calcutt
    const colombo = Resource.get("(UTC+05:00)colombo"); //(UTC+05:00)colombo
    const kathmandu = Resource.get("(UTC+05:45)kathmandu"); //(UTC+05:45)kathmandu
    const dhaka = Resource.get("(UTC+05:00)Dhaka"); //(UTC+05:00)Dhaka
    const yekaterinbrug = Resource.get("(UTC+05:30)yekaterinbrug"); //(UTC+05:30)yekaterinbrug
    const sunday = Resource.get("Sunday"); //sunday
    const monday = Resource.get("Monday"); //Monday
    const tuesday = Resource.get("Tuesday"); //tuesday
    const wed = Resource.get("Wednesday"); //Wed
    const thur = Resource.get("Thursday"); //Thur
    const frid = Resource.get("Friday"); //Frid
    const sat = Resource.get("Saturday"); //Sat
    const maintenance = Resource.get("Maintenance"); //maintenance
    const first = Resource.get("First"); //First
    const second = Resource.get("Second"); //Second
    const third = Resource.get("Third"); //Third
    const fourth = Resource.get("Fourth"); //Fourth
    const last = Resource.get("Last"); //Last
    const shedule = SCHEDULE_ID_RESOURCE;
    const At = Resource.get("At"); //AT
    const fr = Resource.get("For"); //For
    const On = Resource.get("On"); //On
    const and = Resource.get("And"); //And
    const hour = Resource.get("Hour"); //Hour
    const minute = Resource.get("Minute"); //Hour
    const onTheText = Resource.get("On The"); //On The
    const deleteConfirmationText = Resource.get("Delete Confirmation"); //Delete Confirmation
    const timePicker = Resource.get("Time Picker"); //Time Picker
    const enterDescription = Resource.get("Enter Description"); //Enter Description
    const enterName = Resource.get("Enter Name"); //Enter Name
    let saveText = Resource.get("Save");//Save
    let editText = Resource.get("Update");//Update
    let datePicker = Resource.get("Date Picker");//Date Picker
    let end = Resource.get("end");//end
    let close = Resource.get("Close");//Close
    let CONFIRMEXIT = Resource.get('Confirm Exit');//Confirm Exit
    let confirmMassage = Resource.get('Are you sure you want to close the dialog?');//Are you sure you want to close the dialog?
    const requiredLabel = Resource.get("Required"); //Required
    let title = this.state.delete
      ? deleteMaintenanceHeadText
      : this.state.deleteMaintenanceSystem
        ? removeMaintenanceHeadText
        : this.state.endMaintenance
          ? endMaintenanceHeadText
          : this.state.editMaintenance
            ? editMaintenanceLabel
            : helplabelmessage;





    let cancel = Resource.get("Cancel");//Cancel
    let yes = Resource.get("Yes");//Yes
    let no = Resource.get("No");//No
    const confirmActions = [

      <FlatButton

        children={no}
        variant="outlined"
        color="primary"
        onClick={this.onCloseConfirmDailog}

      />
      ,

      <FlatButton

        children={yes}
        variant="contained"
        color="error"
        onClick={this.onClose}

      />
    ]
    const actions = [

      <FlatButton
        style={{ marginRight: '8px' }}
        children={cancel}
        variant="outlined"
        color="primary"
        onClick={this.onClose}

      />
      ,
      this.state.disiableSave ? (

        <FlatButton

          children={
            this.state.delete === true ||
              this.state.deleteMaintenanceSystem === true ||
              this.state.endMaintenance === true
              ? yesLabel
              : this.props
                ? this.props.entities
                  ? this.props.entities.data
                    ? this.props.entities.data.schedule_id
                      ? editText
                      : saveText
                    : saveText
                  : saveText
                : saveText
          }
          variant="contained"
          color= {this.state.delete === true || this.state.deleteMaintenanceSystem === true || this.state.endMaintenance === true? "error":"primary"}

          onClick={
            this.state.delete === true
              ? this.handledeleteMaintenance.bind(this)
              : this.state.deleteMaintenanceSystem === true
                ? this.handledeleteMaintenancesystem.bind(this)
                : this.state.endMaintenance === true
                  ? this.handleEndMaintenance.bind(this)
                  : this.handlesvemethod.bind(this)
          }
          disabled={
            (this.state.weekly == "W" && this.state.recurrenceDaysOfWeek != null && this.state.recurrenceDaysOfWeek.length == 0)? true :
            ((this.state.disableSchedule && this.state.endMaintenance === false)
              ? true
              :(this.state.delete === true ||
                this.state.deleteMaintenanceSystem === true ||
                this.state.endMaintenance === true ||
                this.props
                ? (this.props.entities
                  ? (this.props.entities.data
                    ? (this.props.entities.data.schedule_id
                      ? false
                      : false)
                    : false)
                  : false)
                : (this.state.disiableSave == true
                  ? false
                  : true))
            )
          }
        />

      ) : (

        <FlatButton

          children={
            this.state.delete === true ||
              this.state.deleteMaintenanceSystem === true ||
              this.state.endMaintenance === true
              ? yesLabel
              : this.props
                ? this.props.entities
                  ? this.props.entities.data
                    ? this.props.entities.data.schedule_id
                      ? editText
                      : saveText
                    : saveText
                  : saveText
                : saveText
          }
          variant="contained"
          color="primary"
          onClick={
            this.state.delete === true
              ? this.handledeleteMaintenance.bind(this)
              : this.state.deleteMaintenanceSystem === true
                ? this.handledeleteMaintenancesystem.bind(this)
                : this.state.endMaintenance === true
                  ? this.handleEndMaintenance.bind(this)
                  : this.handlesvemethod.bind(this)
          }
          disabled={
            (this.state.weekly == "W" && this.state.recurrenceDaysOfWeek != null && this.state.recurrenceDaysOfWeek.length == 0)? true :
           ( this.state.disableSchedule && this.state.endMaintenance === false
              ? true
              : this.props
                ? this.props.entities
                  ? this.props.entities.data
                    ? this.props.entities.data.schedule_id
                      ? false
                      : true
                    : true
                  : true
                : true)
          }
        />

      ),
    ];
    let dialogContent;

    let selectedDays = this.state.recurrenceDaysOfWeek?.split(',');

    if (this.props.didInvalidate) {
      dialogContent = <Error />;
    } else if (
      this.state.delete === true ||
      this.state.deleteMaintenanceSystem === true ||
      this.state.endMaintenance === true
    ) {
      dialogContent = this.state.delete
        ? deleteMaintenanceBodyText
        : this.state.deleteMaintenanceSystem
          ? removeMaintenanceBodyText
          : this.state.endMaintenance
            ? endMaintenanceBodyText
            : deleteMaintenanceBodyText;



      //endMaintenance
    } else if (this.props.isFetching) {
      dialogContent = (
        <div style={{ height: "calc(100vh - 205px)", width: "100%", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress style={{ color: '#3272D9' }} />
        </div>
      );
    } else {
      const pageLimit = 9;
      const numPages = Math.ceil(this.state.items.length / pageLimit);
      const startIndex = this.state.pageNumber * pageLimit;
      const tempData = this.state.items.slice(
        startIndex,
        startIndex + pageLimit
      );
      const diffs = differenceBy(tempData, this.rowData, "id");

      if (diffs.length > 0 || tempData.length !== this.rowData.length) {
        this.rowData = tempData;
      }
      const weekDaysArray = [{ key: "Monday", value: 1, ariaLabel: "Monday" },
      { key: "Tuesday", value: 2, ariaLabel: "Tuesday" },
      { key: "Wednesday", value: 3, ariaLabel: "Wednesday" },
      { key: "Thursday", value: 4, ariaLabel: "Thursday" },
      { key: "Friday", value: 5, ariaLabel: "Friday" },
      { key: "SATURDAY", Saturday: 6, ariaLabel: "Saturday" },
      { key: "Sunday", value: 7, ariaLabel: "Sunday" }];
      dialogContent = (
        <>
          {initialDeviceName !== "" && (<Typography component="span" variant="h5"> {initialDeviceName}</Typography>)}

          <FormControl fullWidth style={{ marginTop: '8px' }}>
            <InputLabel id="input-maintenenceScheduleNameLabel" htmlFor="maintenenceScheduleName" > {name}<span className="credentialvault-required-label">{requiredLabel}</span></InputLabel>
            <TextField size="small"
              fullWidth inputProps={{ 'aria-required': true }}
              id="maintenenceScheduleName" labelId="input-maintenenceScheduleNameLabel"

              value={this.state.schedule_name}
              onChange={this.handleNameChange.bind(this)}
              disabled={this.state.disableSchedule}
              placeholder={enterName}
              sx={{
                input: {

                  "&::placeholder": { // <----- Add this.
                    color: 'black !important',
                    opacity: 0.8

                  },
                }
              }}


            />

          </FormControl>
          <FormControl fullWidth style={{ marginTop: '8px', marginBottom: '8px' }}>
            <InputLabel id="input-maintenenceScheduleDescLabel" htmlFor="maintenenceScheduleDesc" > {description}</InputLabel>
            <TextField size="small"
              fullWidth
              id="maintenenceScheduleDesc" labelId="input-maintenenceScheduleDescLabel"
              sx={{
                input: {

                  "&::placeholder": { // <----- Add this.
                    color: 'black !important',
                    opacity: 0.8

                  },
                }
              }}
              value={this.state.description}
              onChange={this.handleDiscribtionChange.bind(this)}
              disabled={this.state.disableSchedule}
              placeholder={enterDescription}

            />

          </FormControl>



          <Grid container spacing={2} style={{ marginBottom: '8px' }} >
            <Grid item xs={6}>
              <FormControl fullWidth={true}>
                <InputLabel htmlFor="maintenenceaccount" id="input-maintenenceaccountLabel">{account}</InputLabel>
                <Select size="small" fullWidth MenuProps={{ MenuListProps: { 'aria-label': "maintenenceaccount-ul" } }}
                  id="maintenenceaccount"
                  value={this.state.account_id}
                  onChange={this.handleAccountChange.bind(this)}
                  disabled={this.state.disableSchedule}


                >

                  {this.props.acl.isBusUser ? (
                    selectedAccount ? (
                      <MenuItem value={this.state.account_id}>
                        {selectedAccount.contactName}
                      </MenuItem> //to stop disappear of value from dropdpwn
                    ) : this.state.accountArr ? (
                      <MenuItem value={this.state.account_id}>
                        {this.state.accountArr[0].contactName}
                      </MenuItem> //to see the accname already there other thn noaccount
                    ) : (
                      <MenuItem value={this.state.account_id}>{noaccount}</MenuItem>
                    )
                  ) : this.state.accounts ? (
                    this.state.accounts.length > 0 ? (
                      <MenuItem value={this.state.account_id}>
                        {this.state.accounts[0].contactName}
                      </MenuItem>
                    ) : (
                      <MenuItem value={this.state.account_id}>{noaccount}</MenuItem>
                    )
                  ) : (
                    <MenuItem value={this.state.account_id}>{noaccount}</MenuItem>
                  )}
                  {this.props.acl.isBusUser && selectedAccount && filteredAccounts
                    ? filteredAccounts
                    : finalAccounts}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>

              <FormControl fullWidth={true}>
                <InputLabel htmlFor="maintenencescheduletype" id="input-maintenencescheduletypeLabel">{shedule}</InputLabel>
                <Select size="small" fullWidth MenuProps={{ MenuListProps: { 'aria-label': "maintenenceaccount-ul" } }}
                  id="maintenencescheduletype" labelId="input-maintenencescheduletypeLabel"
                  value={this.state.once == "o"
                    ? "o"
                    : this.state.daily == "D"
                      ? "D"
                      : this.state.weekly == "W"
                        ? "W"
                        : this.state.monthly == "M"
                          ? "M"
                          : "o"}
                  onChange={this.handleScheduleTypeChange.bind(this)}



                >

                  <MenuItem value={'o'}>
                    {once}
                  </MenuItem>
                  <MenuItem value={'D'}>
                    {daily}
                  </MenuItem>
                  <MenuItem value={'W'}>
                    {weekly}
                  </MenuItem>
                  <MenuItem value={'M'}>
                    {monthly}
                  </MenuItem>
                </Select>
              </FormControl>


            </Grid>
          </Grid>
          <FormControl style={{ marginBottom: '8px' }} fullWidth={true}>
            <InputLabel htmlFor="maintenencetimezone" id="input-maintenencetimezoneLabel">{timeZone}</InputLabel>
            <Select size="small" fullWidth MenuProps={{ MenuListProps: { 'aria-label': "maintenenctimezone-ul" } }}
              id="maintenencetimezone" labelId="input-maintenencetimezoneLabel"
              value={this.state.timezone}
              onChange={this.handletimezoneChange.bind(this)}
              disabled={this.state.disableSchedule}


            >

              {timezoneOptions}
            </Select>
          </FormControl>

          {/* <label
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            <div className="label-text"> {shedule}</div>

            <RadioButtonGroup              
              name="schedule_type"
              defaultValue={
                this.state.once == "o"
                  ? "o"
                  : this.state.daily == "D"
                  ? "D"
                  : this.state.weekly == "W"
                  ? "W"
                  : this.state.monthly == "M"
                  ? "M"
                  : "o"
              }
              className="content-item"
              onChange={this.handleScheduleTypeChange.bind(this)}
              style={{ display: "flex" , flexDirection: "row"}}
            >
             <FormControlLabel 
              value={"o"}
              control={
                <RadioButton
                  type="radio"
                  style={{ height: "25px" }}
                  value={"o"}
                  checked={this.state.once == "o"}                
                  disabled={this.state.disableSchedule}
                />
              }
              label={once}             
             />
             <FormControlLabel 
                value={"D"}
                control={
                  <RadioButton
                    type="radio"
                    style={{ height: "25px" }}
                                      
                    value={"D"}
                    checked={this.state.daily == "D"}
                    disabled={this.state.disableSchedule}
                  />
                }
                label={daily}
              />
               <FormControlLabel 
                  value={"W"}
                  control={
                    <RadioButton
                      checked={this.state.weekly == "W"}
                      type="radio"
                      style={{ height: "25px" }}                   
                      value={"W"}
                      disabled={this.state.disableSchedule}
                    />
                  }
                  label={weekly}
                />
                <FormControlLabel
                  value={"M"}
                  control={
                    <RadioButton
                      checked={this.state.monthly == "M"}
                      type="radio"
                      value={"M"}                    
                      style={{ height: "25px" }}
                    disabled={this.state.disableSchedule}
                  />
                  }
                  label={monthly}
                /> 
            </RadioButtonGroup>
          </label> */}
          <Grid container >




            {this.state.once == "o" ? (
              null) : (<FormControl display="flex" flexDirection="column">
                <InputLabel>{every}</InputLabel>
                <CustomNumberField
                  id={`recurrenceevery`}
                  type="number"
                  value={this.state.recurrence_period}
                  disabled={this.state.disableSchedule}
                  style={{ padding: 'none' }}
                  size="small"
                  variant="outlined"
                  onChange={this.handleEveryChange.bind(this)}
                  InputProps={{
                    'aria-label': `${every} ${this.state.recurrence_period} ${this.state.daily
                      ? day
                      : this.state.weekly
                        ? week
                        : this.state.monthly
                          ? month
                          : ""} }`
                  }}
                />
                <Typography component="span">
                  {this.state.daily
                    ? day
                    : this.state.weekly
                      ? week
                      : this.state.monthly
                        ? month
                        : ""}
                  {/* <span style={{ margin: "0px 20px" }}>{starting}</span> */}
                </Typography>
              </FormControl>)}






            <FormControl fullWidth style={{ marginTop: '8px' }}>
              <MineralDateOnlyPicker
                value={this.state.start_date}
                onChange={this.handleStartDateChange.bind(this)}
                disabled={this.state.disableSchedule}

                variant="inline"
                //minDateTime={this.state.presentDate}
                // minDate={min}
                keyboardIcon={<DatePickerIcon />}
                label={starting}
                ampm={false}
              />
              <HelpingText
                hint={null}
                errorMessage={this.state.startDateError}
                disabled={!this.state.startDateError}
              />

            </FormControl>
            {this.state.once == "o" ? (
              null) : (
              <>
                <FormControlLabel style={{ marginTop: '8px' }}
                  control={
                    <Checkbox
                      color="primary"
                      style={{ marginRight: '5px' }}

                      checked={!this.state.showEndDate}
                      disabled={this.state.disableSchedule}
                      onChange={(event, isChecked) => {

                        this.setState({
                          ...this.state,
                          showEndDate: !isChecked,
                        });
                      }}
                    />}
                  label={noEndDateLabel}
                />
                <FormControl fullWidth style={{ marginTop: '8px' }}>
                  <MineralDateOnlyPicker
                    value={this.state.end_date}
                    onChange={this.handleEndDateChange.bind(this)}
                    disabled={!this.state.showEndDate || this.state.disableSchedule}

                    variant="inline"
                    //minDateTime={this.state.presentDate}
                    //minDate={min}
                    keyboardIcon={<DatePickerIcon />}
                    label={ending}
                    ampm={false}
                  />
                  {this.state.endDateError && <HelpingText
                    hint={null}
                    errorMessage={this.state.endDateError}
                    disabled={!this.state.endDateError}
                  />}

                </FormControl>
              </>

            )}





            {this.state.weekly == "W" ? (
              <FormControl style={{ marginTop: '8px' }}>
                <InputLabel id="input-maintenencweekchangeLabel" htmlFor="maintenencweekchange">{On}</InputLabel>
                <Select size="small" fullWidth MenuProps={{ MenuListProps: { 'aria-label': "maintenencweekchange-ul" } }}
                  id="maintenencweekchange" labelId="input-maintenencweekchangeLabel"
                  value={selectedDays}
                  onChange={this.handleWeekchnage.bind(this)}
                  disabled={this.state.disableSchedule}
                  multiple={true}
                >
                  <MenuItem value={1} selected={selectedDays?.includes('1')}>
                    <Checkbox checked={selectedDays?.includes('1')} label={sunday} name={sunday} style={{ marginRight: 8 }} />
                    {sunday}
                  </MenuItem>
                  <MenuItem value={2} selected={selectedDays?.includes('2')}>
                    <Checkbox checked={selectedDays?.includes('2')} label={monday} name={monday} style={{ marginRight: 8 }} />
                    {monday}
                  </MenuItem>
                  <MenuItem value={3} selected={selectedDays?.includes('3')}>
                    <Checkbox checked={selectedDays?.includes('3')} label={tuesday} name={tuesday} style={{ marginRight: 8 }} />
                    {tuesday}
                  </MenuItem>
                  <MenuItem value={4} selected={selectedDays?.includes('4')}>
                    <Checkbox checked={selectedDays?.includes('4')} label={wed} name={wed} style={{ marginRight: 8 }} />
                    {wed}
                  </MenuItem>
                  <MenuItem value={5} selected={selectedDays?.includes('5')}>
                    <Checkbox checked={selectedDays?.includes('5')} label={thur} name={thur} style={{ marginRight: 8 }} />
                    {thur}
                  </MenuItem>
                  <MenuItem value={6} selected={selectedDays?.includes('6')}>
                    <Checkbox checked={selectedDays?.includes('6')} label={frid} name={frid} style={{ marginRight: 8 }} />
                    {frid}
                  </MenuItem>
                  <MenuItem value={7} selected={selectedDays?.includes('7')}>
                    <Checkbox checked={selectedDays?.includes('7')} label={sat} name={sat} style={{ marginRight: 8 }} />
                    {sat}
                  </MenuItem>
                </Select>
              </FormControl>
            ) : (
              null
            )}

          </Grid>
          <Grid container style={{ marginTop: '16px' }}>
            {this.state.monthly == "M" ? (
              <Grid container direction="column">
                <InputLabel>Run</InputLabel>
                <Grid container direction="row"
                  justifyContent="space-between">
                  <Grid xs="2">

                    <FormControlLabel InputProps={{ marginLeft: '2px' }}
                      control={
                        <RadioButton
                          type="radio"
                          style={{ height: "20px", marginRight: '2px' }}
                          onClick={this.handleTheChanage.bind(this)}
                          value={this.state.the}
                          checked={this.state.the == true ? true : false}
                          disabled={this.state.disableSchedule} inputProps={{ 'aria-label': 'On the' }}
                        />} id="OnTheMonthly"
                      label={onTheText}
                    ></FormControlLabel>
                  </Grid>
                  <Grid xs="4">
                    <FormControl fullWidth >

                      <Select size="small" style={{ marginRight: '3px' }} fullWidth MenuProps={{ MenuListProps: { 'aria-label': "maintenencmonthlyrecuurencrchange-ul" } }}
                        id="maintenencmonthlyrecuurencrchange" labelId="input-maintenencmonthlyrecuurencrchangeLabel"
                        value={this.state.recurrence_instance}
                        onChange={this.handlerecurranceInstance.bind(this)}
                        disabled={this.state.the == false ? true : false}




                      >


                        <MenuItem value="1">{first}</MenuItem>
                        <MenuItem value="2">{second}</MenuItem>
                        <MenuItem value="3">{third}</MenuItem>
                        <MenuItem value="4">{fourth}</MenuItem>
                        <MenuItem value="5">{last}</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid xs="5">
                    <FormControl fullWidth>

                      <Select size="small" fullWidth MenuProps={{ MenuListProps: { 'aria-label': "maintenencmonthlyrecuurencrdayschange-ul" } }}
                        id="maintenencmonthlyrecuurencrdayschange" labelId="input-maintenencmonthlyrecuurencrdayschangeLabel"
                        value={this.state.recurrenceDaysOfWeeks.split(",")}
                        onChange={this.handleMonthlychnage.bind(this)}
                        disabled={
                          this.state.disableSchedule
                            ? true
                            : this.state.the == false
                              ? true
                              : false
                        }




                      >

                        <MenuItem value="1">{sunday}</MenuItem>
                        <MenuItem value="2">{monday}</MenuItem>
                        <MenuItem value="3">{tuesday}</MenuItem>
                        <MenuItem value="4">{wed}</MenuItem>
                        <MenuItem value="5">{thur}</MenuItem>
                        <MenuItem value="6">{frid}</MenuItem>
                        <MenuItem value="7">{sat}</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container direction="row"
                  justifyContent="space-between" style={{ marginTop: '8px' }}>
                  <Grid xs="2">
                    <FormControlLabel inputProps={{ marginLeft: '2px' }}
                      control={
                        <RadioButton
                          type="radio" inputProps={{ 'aria-label': 'Every' }}
                          style={{ height: "20px" }}
                          value={this.state.oNdate}
                          onClick={this.handleONdateChange.bind(this)}
                          checked={this.state.oNdate == true ? true : false}
                          disabled={this.state.disableSchedule}

                        />} id="monthlyEvery" label={"Every"}

                    ></FormControlLabel>
                  </Grid>
                  <Grid xs="4" container direction="row">
                    <CustomNumberField fullWidth={true}
                      id={`daychangerecurrencedayofmonth`}
                      type="number"
                      min="1"
                      max="31"
                      value={this.state.recurrence_day_of_the_month}
                      disabled={this.state.oNdate == true ? false : true}
                      style={{ padding: 'none' }}
                      size="small"
                      variant="outlined"
                      onChange={this.handleDaychnage.bind(this)}
                      InputProps={{
                        'aria-label': `${every} ${this.state.recurrence_day_of_the_month} ${this.state.daily
                          ? day
                          : this.state.weekly
                            ? week
                            : this.state.monthly
                              ? month
                              : ""} }`
                      }}
                    />

                    <InputLabel style={{ marginLeft: "2px" }}>{days}</InputLabel>
                  </Grid>
                  <Grid xs="5">

                  </Grid>
                </Grid>
              </Grid>

            ) : (
              ""
            )}
          </Grid>

          <Grid container >
            <FormControl fullWidth>

              <InputLabel id="maintenencetimeLabel" htmlFor="maintenencetime" >At</InputLabel>
              <MineralTimePicker inputProps={{ id: 'maintenencetime' }}
                size="small"
                mask="__:__ _M"
                value={this.state.dateForPicker}
                onValueChange={this.handleATChange.bind(this)}
                KeyboardButtonProps={{
                  'aria-label': timePicker,
                }}

              />
            </FormControl>
          </Grid>

          <Grid container spacing={2} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>




            <Grid item xs={6}>
              <FormControl style={{ marginTop: '8px', }} fullWidth>
                <InputLabel>{fr}</InputLabel>


                <CustomNumberField
                  id={`maintenancedurationhours`}
                  type="number"
                  min="0"
                  max="99999"
                  value={this.state.duration}
                  placeholder="Hours"
                  disabled={this.state.disableSchedule}
                  style={{ padding: 'none' }}
                  size="small"
                  variant="outlined"
                  onChange={this.handleduration.bind(this)}
                  InputProps={{ 'aria-label': `In maintenenance for ${this.state.duration} hours and ${this.state.minute} minutes` }}
                />

                <InputLabel >Hours</InputLabel>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth style={{ marginTop: '8px', }}>
                <InputLabel>{and}</InputLabel>
                <CustomNumberField
                  id={`maintenancedurationminutes`}
                  type="number"
                  min="00"
                  max="59"
                  value={this.state.minute}
                  placeholder="Hours"
                  disabled={this.state.disableSchedule}
                  style={{ padding: 'none' }}
                  size="small"
                  variant="outlined"
                  onChange={this.handleminute.bind(this)}
                  InputProps={{ 'aria-label': `In maintenenance for ${this.state.duration} hours and ${this.state.minute} minutes` }}
                />

                <InputLabel >Minutes</InputLabel>
              </FormControl>
            </Grid>



          </Grid>

        </>
      );
    }
    return this.state.delete ||
      this.state.deleteMaintenanceSystem ||
      this.state.endMaintenance ? (
      <Dialog

        open={this.props.open}
        onClose={this.onClose}

      >
        <DialogTitle component="div" disableTypography><Typography component="h1" variant="h4">{title}</Typography> </DialogTitle>
        <div style={{ height: '0px' }}>
          <Tooltip title="Close">
            <IconButton style={{ float: 'right', marginRight: '8px', top: '-50px' }} autoFocus onClick={this.onClose} tabIndex="0" aria-label={close}>
              <Clear />
            </IconButton>
          </Tooltip>
        </div>
        <DialogContent>{dialogContent}</DialogContent>

        <DialogActions>
          {actions}
        </DialogActions>
      </Dialog>
    ) : (
      <div>
        <Dialog
          id="creatMaintenanceDialoge"

          maxWidth={"lg"}
          modal="false"
          open={this.props.open}
          onClose={this.onCloseMaintenaceDailog}
          className="maintanceDialogConatiner"
        >

          <DialogTitle component="div" disableTypography>


            <Typography style={{ marginTop: '16px' }} component="h1" variant="h4">
              {title}
            </Typography>

          </DialogTitle>
          {/* <IconButton  className="addDevice-header__closeIcon" onClick={this.onClose} tabIndex="0" aria-label="Close" style={{marginTop: '-45px', marginLeft: '385px', width: '55px'}}>
           <Clear />
       </IconButton> */}
          <div style={{ height: '0px' }}>
            <Tooltip title={close} >
              <IconButton style={{ marginRight: '8px', float: 'right', top: '-50px' }} tabIndex={0} autoFocus onClick={this.onCloseMaintenaceDailog} >
                <Clear role='figure' />
              </IconButton>
            </Tooltip>
          </div>
          <DialogContent>


            {dialogContent}


          </DialogContent>



          <DialogActions >
            <Box style={{ marginBottom: '16px' }}>
              {actions}
            </Box>
          </DialogActions>
        </Dialog>
        <Snackbar
          open={this.state.isError}
          message={this.state.errorMessage.message}
          autoHideDuration={4000}
          onActionTouchTap={this.onErrorDescription}
          onRequestClose={this.handleRequestClose}
        />
        <Dialog
          open={this.state.ConfirmDailog}
          onClose={() => {
            this.onCloseConfirmDailog();
          }}
        >
          <DialogTitle component="div" disableTypography>


            <Typography component="h1" variant="h4">
              {CONFIRMEXIT}
            </Typography>

          </DialogTitle>
          <div style={{ height: '0px' }}>

            <Tooltip title={close} style={{ float: 'right', marginRight: '8px', top: '-50px' }}>
              <IconButton autoFocus onClick={this.onCloseConfirmDailog}>
                <Clear role='figure' />
              </IconButton>
            </Tooltip>
          </div>
          <DialogContent>{confirmMassage}</DialogContent>
          <DialogActions>
            {confirmActions}
          </DialogActions>
        </Dialog>
      </div>
    );
  }
  handleRequestClose = () => {
    this.setState({
      isError: false,
    });
  };
  onErrorDescription = () => {
    this.setState({
      isError: false,
    });
    alert(this.state.errorMessage.detail);
  };

  onDescription = () => {
    this.setState({
      savepopUpOpen: false,
    });
  };
}

export default MaintenanceDialog;
