import connect from './../../../utils/connect'
import ScopesCatalog from './ScopesCatalog'
import {getScopes,pollScopesStart,pollScopesStop,addScopes,updateScopes,deleteScopes}  from './../../../api/DeviceDiscovery/scopes/actions';
import { setSelectedAgent } from '../../../api/DeviceDiscovery/agents/actions'

const mapStateToProps = state => {
  return {
	  scopesItems: state.scopes.items,
	  scopesIsFetching: state.scopes.isFetching,
	  selectedAgentInfo:state.agents.selectedAgent
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getScopes: (agentId) => dispatch(getScopes(agentId)),
    pollScopesStart: (agentId) => dispatch(pollScopesStart(agentId)),
    pollScopesStop: () => dispatch(pollScopesStop()),
    addScopes: (credObject) => dispatch(addScopes(credObject)),
    updateScopes: (credObject) => dispatch(updateScopes(credObject)),
    deleteScopes: (agentId) => dispatch(deleteScopes(agentId)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ScopesCatalog)
