import React from 'react'
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid
} from 'recharts'
import './chart.less'
import Resource from './Resource'

const threshold = 10
const renderLegend = (props) => {
  return (
    <div className='chart-legend'>{//ALARM SUMMARY
      Resource.get('ALARM SUMMARY')}	</div>
      
      )
    }
    
    const CustomizedLabel = ({ x, y, fill, label, width}) => {      
      //const { x, y, fill, label, width } = this.props;
      let svgDetail=null
      if (label > threshold) {
        svgDetail=
        <svg>
        <rect x={x - width / 2} y={y - 9} width={width} height="8" fill={fill} />
        <text x={x} y={y - 16} fill='black' fontSize={14} fontWeight='bolder' textAnchor="middle">{label}</text>
        </svg>
        
      }
      else {
        svgDetail=
        <svg>
        <text x={x} y={y} fill='black' fontSize={14} fontWeight='bolder' textAnchor="middle">{label ? label : null}</text>
        </svg>
        
      }
      return( svgDetail) 
     
    }
    
    const CustomTooltip = ({ active, payload, label }) => {
      if (!active) return null
      return (
        <div className="recharts-default-tooltip chart-custom-tooltip ">
        <p className="recharts-tooltip-label">{`${label} Alarms : ${payload[0].payload.label}`}</p>
        </div>
        );
      }
      const SimpleBarChart = (props) => {
        const data = props.data.map((item) => {
          var copy = Object.assign({}, item)
          copy.label = item.cnt
          if (copy.cnt > threshold) {
            copy.cnt = threshold
          }
          return copy
        })
        return (
          <BarChart width={275} height={186} data={data} margin={{
            top: 25,
            right: 0,
            left: 0,
            bottom: 5
          }}>
          <Bar dataKey="cnt" name="Alarm Summary" label={<CustomizedLabel />} >
          {data.map((entry, index) => (<Cell key={`cell-${index}`} fill={props.chartColors[index]} />))}
          </Bar>
          <YAxis domain={[0, 15]} hide={true} ticks={[0, 5, 10, 15]} />
          <XAxis dataKey="name" axisLine={false} tickLine={false} />
          <CartesianGrid vertical={false} />
          <Tooltip content={<CustomTooltip />} cursor={{fill:"#dedede70"}}/>
          <Legend content={renderLegend} />
          </BarChart>
          )
        }
        
        export default SimpleBarChart;
        
        