class Poller {
  constructor(pollFunc, opts = {}) {
    if (!pollFunc) {
      throw Error('Poller: you must provide a function to poll')
    }
    this.tm = null
    this.pollFunc = pollFunc
    this.timeout = opts.timeout || (30*1000)
    this.pollFailure = opts.pollFailure || (() => {})
    this.pollSuccess = opts.pollSuccess || (() => {})
  }
  poll = () => {
    if (this.tm) {
      return this
    }
    this.tm = setTimeout(() => {
      this.pollFunc()
        .then(this._pollSuccess, this._pollFailure)
      }, this.timeout)
    return this
  }
  _pollFailure = (e, ...rest) => {
    if (!this.tm) {
      return this
    }
    console.error('Poller: unable to poll on provided function', e)
    this.tm = null
    this.poll()
    this.pollFailure.apply(null, [e, ...rest])
  }
  _pollSuccess = (...args) => {
    if (!this.tm) {
      return this
    }
    this.tm = null
    this.poll()
    this.pollSuccess.apply(null, args)
  }
  start = () => {
    this.poll()
    return this
  }
  stop = () => {
    clearTimeout(this.tm)
    this.tm = null
    return this
  }
}

export default ((pollFunc, opts) => {
  return new Poller(pollFunc, opts)
})
