import connect from "./../../utils/connect";
import { createSelector } from "reselect";
import filter from "lodash/filter";
import orderBy from "lodash/orderBy";

import { setCardPage } from "./actions";
import { addGroup, pollGroupsStop } from "../../api/groups/actions";
import { setQuickFilterEntities } from "./../entities/actions";
import CardsManager from "./CardsManager";
import { GROUP_SORT_TYPES } from "./../../api/groups/actionTypes";
import { getAccount } from "../../api/account/actions";

const getCards = (state) => state.entities;
const getGroupSortColumn = (state) => state.groupSorter;
const getSearchTerm = (state) => state.inventoryEntityFilter.quickFilter;
const getCardPage = (state) => state.cardPage;
const pageLimit = 15;

const getCardsSelector = createSelector([getCards], (cards) => {
  return cards;
});

const getFilteredCardsSelector = createSelector(
  [getCardsSelector, getSearchTerm],
  (cards, searchTerm) => {
    return filter(cards, (card) => {
      if (card.name == null) {
        // console.log("No card name");
        // console.log(card);
      }
      return (
        card.name != null &&
        card.name.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0
      );
    });
  }
);

const getSortedCardsSelector = createSelector(
  [getFilteredCardsSelector, getGroupSortColumn],
  (cards, sortKey) => {
    const getSortFunction = (sortKey) => {
      let func;
      let sortCol = sortKey.prop;
      let order = sortKey.order;
      switch (sortCol) {
        case GROUP_SORT_TYPES.NAME:
          func = (arr) =>
            orderBy(
              arr,
              [
                function (o) {
                  return o.name.toLowerCase();
                },
              ],
              order
            );
          break;
        case GROUP_SORT_TYPES.SEVERITY:
          func = (arr) =>
            orderBy(
              arr,
              [
                sortCol,
                "maxAlarmSeverity.cnt",
                function (o) {
                  return o.name.toLowerCase();
                },
              ],
              [order, order, "asc"]
            );
          break;
        default:
          func = (arr) => orderBy(arr, sortCol, order);
          break;
      }
      return func;
    };
    return getSortFunction(sortKey)([...cards]);
  }
);
const getPagedCardsSelector = createSelector(
  [getSortedCardsSelector, getCardPage],
  (cards, groupPage) => {
    let startIndex = groupPage * pageLimit;
    return cards.slice(startIndex, startIndex + pageLimit);
  }
);

const mapDispatchToProps = (dispatch) => {
  return {
    handleEntityDrillDown: (item, link) => {
      dispatch(setQuickFilterEntities(""));
    },
    onAlarmClick: (item, link) => {
      dispatch(setQuickFilterEntities(""));
    },
    handlePageClick: (selectedPage) => {
      dispatch(setCardPage(selectedPage.selected));
    },
    resetCardPage: () => {
      dispatch(setCardPage(0));
    },
    addGroup: (newGroup) => dispatch(addGroup(newGroup)),
    pollGroupsStop: (id) => dispatch(pollGroupsStop(id)),
    getAccount: () => dispatch(getAccount()),
  };
};

const mapStateToProps = (state) => {
  return {
    featureFlags: state.configuration.items,
    groupsDidInvalidate: state.groups.didInvalidate,
    groupsIsFetching: state.groups.isFetching,
    groupDeleteFailed: state.groups.groupDeleteFailed,
    computerSystemsDidInvalidate: state.computerSystems.didInvalidate,
    computerSystemsIsFetching: state.computerSystems.isFetching,
    items: getPagedCardsSelector(state),
    pageLimit: pageLimit,
    totalItems: getFilteredCardsSelector(state).length,
    cardPage: state.cardPage,
    entityType: state.entity.type,
    selectedId: state.entity.id,
    entity: state.entity,
    entities: state.entities,
    name: state.entity.name,
    breadcrumbs: state.breadCrumbs,
    saas: state.saas,
    doiurl: state.user.doiurl,
    acl: state.acl,
    accounts: state.account.accounts,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CardsManager);
