import React, {Component} from 'react'
import {Button, Divider,Box,Grid,Typography} from '@mineral/core'
import AddIcon from '@material-ui/icons/Add';
import Table from './Table'
import '../cards/cards.less'
import './table.less'
import MaintenaneDialogRedux
  from './MaintenaneDialogRedux'
import Resource from './Resource'


class MaintenanceClass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAddSubgroupDialog: false,
      showAddDevicesDialog: false,
      showErrorForAddDevices: false,
      showErrorForAddGroups: false,
      editSubGroupOpen: false,
      openDynamicGroupDialog: false,
      catalogItems: this.props.catalogItems
    }
  }

  handleAddSubgroup = () => {
    this.setState({showAddSubgroupDialog: true})
  }

  handleAddDevices = () => {
    this.setState({showAddDevicesDialog: true})
  }
  handleAddDevicesClose = () => {
    this.setState({showAddDevicesDialog: false})
  }


  handleErrorDialogOpenAddDevices = () => {
    this.setState({
      showErrorForAddDevices: true
    })
  }
  handleErrorDialogCloseAddDevices = () => {
    this.setState({
      showErrorForAddDevices: false
    })
  }

  componentDidMount() {
    this.props.getMaintenance();
    this.props.pollMaintenanceStart();
  }

  componentWillUnmount() {
    this.props.pollMaintenanceStop()
  }

  render() {
    const schedules = Resource.get('Schedules')//schedules
    const news = Resource.get('New')//New
    let view
    let dialog = (
      <MaintenaneDialogRedux
        open={this.state.showAddDevicesDialog}
        handleClose={this.handleAddDevicesClose}
        selectedId={this.props.selectedId}
        handleErrorDialogOpen={this.handleErrorDialogOpenAddDevices}
        entities={this.props.entities}
      />
    )

    let addIcon = (
      <Button style={{float:'right',marginRight:'16px'}}
        variant='contained'
        color='primary'
        
        onClick={this.handleAddDevices.bind(this)}
       
      >
        {news}
      </Button>
    )

    if (!this.props.canEditMaintMode) {
      addIcon = null;
    }

    view = (


      <div style={{height:'100%'}}>
     
        <Grid container direction="row"
  justifyContent="space-between" 
  >
            <Grid item style={{marginTop:'8px'}} >
              <Typography style={{float:'left',marginLeft:!this.props.openTree?'52px':'16px'}} component="span" variant="h3">{schedules}</Typography>
            </Grid>
           
            <Grid item style={{marginTop:'8px'}}>
            {addIcon}
            </Grid>
            </Grid>
           
       

        {dialog}
        <div style={{height:'100%'}}>
        <Table {...this.props} />
        </div>
        </div>

    )
    if (this.props.canViewMaintenanceMode) {
      return view
    } else {
      return null
    }
  }
}

export default MaintenanceClass
    