import axios from 'axios';
import config from './../../config';

class scopes {
    getScopes(params) {
        if(process.env.NODE_ENV == "development" )
        return axios.get('http://10.252.25.208:8080/castlemock/mock/rest/project/d0QO1h/application/YedIdg/scopes');

        return axios.get([config.basename, 'api/v1/scopes/'+params.agentId].join('/'));
    }
    getScope(agentId,scopeId) {

        if(process.env.NODE_ENV == "development" )
        return axios.get('http://10.252.25.208:8080/castlemock/mock/rest/project/d0QO1h/application/YedIdg/scopes1');

        return axios.get([config.basename, 'api/v1/scopes/'+agentId+'/scope/'+scopeId].join('/'));
    }
    saveScope(scope,agentId){
        return axios.post([config.basename, 'api/v1/scopes/'+agentId].join('/'), scope);
    }
    deleteScope(agentId,scopeId) {
        return axios.delete([config.basename, 'api/v1/scopes/'+agentId+'/scope/'+scopeId].join('/'));
    }
    updateScope(scope,agentId){
    	 return axios.put([config.basename, 'api/v1/scopes/'+agentId].join('/'), scope);
    }
}
export default new scopes();