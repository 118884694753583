import React from 'react'
import Filter from './../filter/Filter'
import Sort from './../sort/Sort'
import GroupPath from './GroupPath'
import {

  MoreIcon
} from "../../../ui-components/uim-components";

import EntityTypes from "./../../../../api/entityTypes";

import {MenuItem, Menu, IconButton, Button} from '@mineral/core'
import get from "lodash/get";
const Controls = (props) => {
  let {
    sort,
    handleChange,
    onSortChange,
    searchTerm,
    showSort,
    filterHint,
    entity,
    stateDataGroups,
    location,
    allGroups,
  } = props

  const [menuAnchorEl,setMenuAnchorEl]=React.useState(null);
  const [dropdownValue,setDropdownValue]=React.useState(
  props.location.pathname.search("list") > -1
    ? 2
    : props.location.pathname.search("treeviewnew") > -1
      ? 3
      : 1);
  const handleMenuClick = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };
  
  const handleActionMenuClose = (event) => {
    setMenuAnchorEl(null);
  };

  const handleOpenExportDialog=(event)=>{

  }

  const handleOpenEditColumnDialog=(event)=>{
    
  }
  return (
    <div className='section-controls' style={{width:'100%',display:'flex',backgroundColor:'#FFFFFF',marginTop:'-2px'}} >
     { showSort ? <Sort sort={sort} onChange={onSortChange} /> : null}
      {(location.pathname.search("treeviewnew") > -1) ?
      null
      :
      <Filter onChange={handleChange} value={searchTerm} filterHint={filterHint}/>}
     
      {/*!props.isFromGroupsMainHeader && dropdownValue != 3
                    && props.location.pathname.search("computer-systems") < 0 && 
                    <span style={{float: 'right'}}>
                      <IconButton
                        aria-controls="action-menu" aria-haspopup="true"
                        onClick={handleMenuClick}> <MoreIcon/> </IconButton>
                          <Menu
                            id="action-menu"
                            anchorEl={menuAnchorEl}
                            keepMounted
                            onClose={handleActionMenuClose}
                            open={menuAnchorEl != null}
                          >
                              <MenuItem children={"Export"} onClick={handleOpenExportDialog}/>
                            {props.location.pathname.search("/list") > -1 &&
                            (get(props.selectedEntity, "type") ===
                              EntityTypes.AUTOMATIC ||
                              get(props.selectedEntity, "type") ===
                              EntityTypes.STATIC ||
                              get(props.selectedEntity, "type") ===
                              EntityTypes.DYNAMIC) &&
                            <MenuItem children={"Edit Columns"} onClick={handleOpenEditColumnDialog}/>}
                              </Menu>
                            </span>*/}
      
    </div>
  )
}

Controls.defaultProps = {
  showSort: true
}
export default Controls
