import React, { Component } from "react";
import "./credentialVault.less";
import CredentialVaultCatalogRedux from "./CredentialVaultCatalogRedux";
import credentials from "./../../../api/DeviceDiscovery/credentials/credentials";
import Resource from "./Resource";
import CredentialWelcomeText from "./CredentialWelcomeText";
import { Checkbox, MenuItem, Select as SelectField, Snackbar, SnackbarContent, FormControl, TextField, InputLabel, FormControlLabel, FormLabel, Radio, RadioGroup, Grid } from "@mineral/core";
import { CheckBoxOutlineBlankIcon as UnCheckedIcon } from "@material-ui/icons/CheckBoxOutlineBlank";
import { Button } from "@mineral/core";
import deleteIcon from "../../../assets/icons/deleteIcon.svg";
import errorIcon from "../../../assets/icons/error_alert.svg";
import "./scopes.less";
import isEmpty from "lodash/isEmpty";
import CustomSnackbar from '../../common/CustomSnackbar'

class SnmpCredentialVault extends Component {
  constructor() {
    super();
    this.state = {
      credentialObject: {},
      credentialName: "",
      isCredentialNameValid: true,
      credentialId: "",
      user: "",
      isUserValid: true,
      isPortEmpty: false,
      isPortValid: true,
      password: "",
      isPasswordValid: true,
      isPrivPasswordValid: true,
      isPrivPassworTouched: false,
      showPassword: false,
      showCommunityString: false,
      showWelcomeText: true,
      editLabel: "",
      isDirty: false,
      isPasswordTouched: false,
      isCommunityStringTouched: false,
      showRemoveButton: true,
      savepopUpOpen: false,
      popMassage: "",
      popErrorType:'info',
      error: {
        errorOccurred: false,
        errorMessage: "",
        errorDescription: "",
      },
      version: 1,
      method: "MD5",
      security: "NOAuthNoPriv",
      privtype: "DES",
      seclevel: "NOAuthNoPriv",
      privpwd: "",
      port: "161",
      password: "",
      userName: "",
    };
  }
  credentialVaultHandle = null;
  scopes = ["1", "2", "3"];
  enableWelcomeText = () => {
    this.setState({
      showWelcomeText: true,
    });
  };
  handleCredentialNameChange = (e) => {
    let name = e.target.value.trim();
    if (name === "") {
      this.setState({
        credentialName: name,
        isCredentialNameValid: false,
        isDirty: true,
      });
    } else {
      this.setState({
        credentialName: name,
        isCredentialNameValid: true,
        isDirty: true,
      });
    }
  };
  handleUserNameChange = (e) => {
    let user = e.target.value.trim();
    if (user === "") {
      this.setState({
        user: user,
        isUserValid: false,
        isDirty: true,
      });
    } else {
      this.setState({
        user: user,
        isUserValid: true,
        isDirty: true,
      });
    }
  };
  handlePortNameChange = (e) => {
    let port = e.target.value.trim();
    var numbers = /^[0-9]+$/;
    if (port === "") {
      this.setState({
        port: port,
        isPortEmpty: true,
        isPortValid: false,
        isDirty: true,
      });
    } else if (!port.match(numbers)) {
      this.setState({
        port: port,
        isPortValid: false,
        isPortEmpty: false,
        isDirty: true,
      });
    } else if (port < 0 || port > 65535) {
      this.setState({
        port: port,
        isPortValid: false,
        isPortEmpty: false,
        isDirty: true,
      });
    } else {
      this.setState({
        port: port,
        isPortValid: true,
        isPortEmpty: false,
        isDirty: true,
      });
    }
  };

  handlecommunityChange = (e) => {
    let pass = e.target.value.trim();
    let crdId = this.state.credentialId ? this.state.credentialId : "";
    if (
      pass === "" &&
      !(
        this.state.version == 3 &&
        (this.state.seclevel === "AuthNoPriv" ||
          this.state.seclevel === "NOAuthNoPriv")
      )
    ) {
      this.setState({
        privpwd: pass,
        isPasswordValid: false,
        isDirty: true,
      });
    } else {
      this.setState({
        privpwd: pass,
        isPasswordValid: true,
        isDirty: true,
        isPasswordTouched: crdId === "" ? false : true,
      });
    }
  };

  handlePasswordChange = (e) => {
    let pass = e.target.value.trim();
    let crdId = this.state.credentialId;
    if (
      pass === "" &&
      this.state.version != 1 &&
      this.state.version != 2 &&
      !(
        this.state.version == 3 &&
        (this.state.seclevel === "AuthNoPriv" ||
          this.state.seclevel === "NOAuthNoPriv")
      )
    ) {
      this.setState({
        privpwd: pass,
        isPrivPasswordValid: false,
        isDirty: true,
      });
    } else {
      this.setState({
        privpwd: pass,
        isPrivPasswordValid: true,
        isDirty: true,
        isPrivPassworTouched: crdId === "" ? false : true,
      });
    }
  };
  // password upadte for community strung

  handleversionChange = (event) => {
    let value = event.target.value;

    if (
      value == 3 &&
      (this.state.seclevel === "AuthNoPriv" ||
        this.state.seclevel === "NOAuthNoPriv")
    ) {
      let crdId = this.state.credentialId;
      this.setState({
        isPasswordValid: true,
        isDirty: true,
        isPasswordTouched: crdId === "" ? false : true,
      });
    }

    if (value === "") {
      this.setState({
        version: value,
        isUserValid: false,
        isDirty: true,
      });
    } else {
      this.setState({
        version: value,
        isUserValid: true,
        isDirty: true,
      });
    }
  };

  handleMethodChange = (event) => {
    let value = event.target.value;
    if (value === "") {
      this.setState({
        method: value,
        isUserValid: false,
        isDirty: true,
      });
    } else {
      this.setState({
        method: value,
        isUserValid: true,
        isDirty: true,
      });
    }
  };

  handleSecurityChange = (event) => {
    let value = event.target.value;

    if (value === "NOAuthNoPriv" || value === "AuthNoPriv") {
      let crdId = this.state.credentialId;
      this.setState({
        isPasswordValid: true,
        isDirty: true,
      });
      this.setState({
        isPrivPasswordValid: true,
        isDirty: true,
      });
    }

    if (value === "") {
      this.setState({
        seclevel: value,
        isUserValid: false,
        isDirty: true,
      });
    } else {
      this.setState({
        seclevel: value,
        isUserValid: true,
        isDirty: true,
      });
    }
  };
  handleProtocolChange = (event) => {
    let value = event.target.value;
    if (value === "") {
      this.setState({
        privtype: value,
        isUserValid: false,
        isDirty: true,
      });
    } else {
      this.setState({
        privtype: value,
        isUserValid: true,
        isDirty: true,
      });
    }
  };
  handlePassword_community_Change = (e) => {
    let pass = e.target.value.trim();
    let crdId = this.state.credentialId;
    if (
      pass === "" &&
      !(
        this.state.version == 3 &&
        (this.state.seclevel === "AuthNoPriv" ||
          this.state.seclevel === "NOAuthNoPriv")
      )
    ) {
      this.setState({
        password: pass,
        isPasswordValid: false,
        isDirty: true,
      });
    } else {
      this.setState({
        password: pass,
        isPasswordValid: true,
        isDirty: true,
        isCommunityStringTouched: crdId === "" ? false : true,
      });
    }
  };
  //end
  SaveOrUpdateTheCredential = (e) => {
    let credentialsInfo = {};
    if (this.state.credentialId === "") {
      credentialsInfo.name = this.state.credentialName;
      credentialsInfo.password = this.state.password;
      credentialsInfo.userName = this.state.user;
      credentialsInfo.version = this.state.version ? this.state.version : 1;
      credentialsInfo.seclevel = this.state.seclevel
        ? this.state.seclevel
        : "NOAuthNoPriv";
      credentialsInfo.method = this.state.method ? this.state.method : "MD5";
      credentialsInfo.privpwd = this.state.privpwd;
      credentialsInfo.privtype = this.state.privtype
        ? this.state.privtype
        : "DES";
      credentialsInfo.port = this.state.port ? this.state.port : 161;
      //Add
      credentials.saveSNMP(credentialsInfo).then(
        (response) => {
          this.SaveOrUpdateTheCredentialSuccess(response.data);
          if (this.credentialVaultHandle) {
            this.credentialVaultHandle.refreshPostCreate(response.data, "SNMP");
          }
          this.setState({
            savepopUpOpen: true,
            popMassage: "Added Successfully",
            popErrorType:'success'
          })
          this.closeError();
        },
        (err) => {
          let errMssg = Resource.get("Error Adding Credentials"); //Error Adding Credentials
          let errorDescription = err.response.data.errorMsg;
          let error = {
            message: errMssg,
            description: errorDescription,
          };
          this.setState({
            savepopUpOpen: true,
            popMassage: errMssg,
            popErrorType:'error'
          })
          this.setError(error);
        }
      );
    } else {
      //edit
      if (this.state.credentialName !== this.state.credentialObject.name)
        credentialsInfo.name = this.state.credentialName;
      else credentialsInfo.name = this.state.credentialObject.name;

      if (this.state.user !== this.state.credentialObject.userName)
        credentialsInfo.userName = this.state.user;

      if (this.state.password !== this.state.credentialObject.password)
        credentialsInfo.password = this.state.password;
      else credentialsInfo.password = this.state.credentialObject.password;

      if (this.state.version !== this.state.credentialObject.version)
        credentialsInfo.version = this.state.version;
      else credentialsInfo.version = this.state.credentialObject.version;

      if (this.state.seclevel !== this.state.credentialObject.seclevel)
        credentialsInfo.seclevel = this.state.seclevel;
      else credentialsInfo.seclevel = this.state.credentialObject.seclevel;

      if (this.state.method !== this.state.credentialObject.method)
        credentialsInfo.method = this.state.method;
      else credentialsInfo.method = this.state.credentialObject.method;

      if (this.state.privtype !== this.state.credentialObject.privtype)
        credentialsInfo.privtype = this.state.privtype;
      else credentialsInfo.privtype = this.state.credentialObject.privtype;

      if (this.state.privpwd !== this.state.credentialObject.privpwd)
        credentialsInfo.privpwd = this.state.privpwd;
      else credentialsInfo.privpwd = this.state.credentialObject.privpwd;

      if (this.state.port !== this.state.credentialObject.port)
        credentialsInfo.port = this.state.port;
      else credentialsInfo.port = this.state.credentialObject.port;

      credentialsInfo.id = this.state.credentialId;
      credentialsInfo.userName = this.state.user;
      credentialsInfo.credName = this.state.credentialName;
      credentials.editSNMP(credentialsInfo).then(
        (response) => {
          this.SaveOrUpdateTheCredentialSuccess(response.data);
          if (this.credentialVaultHandle) {
            this.credentialVaultHandle.refreshPostUpdate(response.data, "SNMP");
          }
          this.setState({
            savepopUpOpen: true,
            popMassage: "Updated Successfully",
            popErrorType:'success'
          })
          this.closeError();
        },
        (err) => {
          let errMssg = Resource.get("Error Updating Credentials"); //Error Updating Credentials
          let errorDescription = err.response.data.errorMsg;
          let error = {
            message: errMssg,
            description: errorDescription,
          };
          this.setState({
            savepopUpOpen: true,
            popMassage: errMssg,
            popErrorType:'error'
            
            


          })
          this.setError(error);
        }
      );
    }
  };
  setError = (error) => {
    this.setState({
      error: {
        errorOccurred: true,
        errorMessage: error.message,
        errorDescription: error.description,
      },
    });
  };
  deleteCredential = () => {
    credentials.deleteSNMP(this.state.credentialId).then(
      (response) => {
        this.setState({
          showWelcomeText: true,
          showRemoveButton: true,
        });
        if (this.credentialVaultHandle) {
          this.credentialVaultHandle.refreshPostDelete(
            this.state.credentialId,
            "SNMP"
          );
        }
        this.setState({
          savepopUpOpen: true,
          popMassage: "Removed Successfully",
          popErrorType:'success'
        })
        this.closeError();
      },
      (err) => {
        let errMssg = Resource.get("Error Deleting Credentials"); //Error Deleting Credentials
        let errorDescription = err.response.data.errorMsg;
        let error = {
          message: errMssg,
          description: errorDescription,
        };
        this.setState({
          savepopUpOpen: true,
          popMassage: errMssg,
          popErrorType:'error'

        })
        this.setError(error);
      }
    );
  };
  closeDeleteCredential = () => {
    this.setState({
      showRemoveButton: true,
    });
  };
  deleteCredentialConfirmation = () => {
    this.setState({
      showRemoveButton: false,
    });
  };
  SaveOrUpdateTheCredentialSuccess = (obj) => {
    this.setState({
      credentialObject: obj,
      name: obj.name,
      id: obj.id,
      credentialId: obj.id,
      user: obj.userName,
      password: obj.password,
      showPassword: false,
      showCommunityString: false,
      editLabel: obj.name,
      isDirty: false,
      isPasswordTouched: false,
      isCommunityStringTouched: false,
      port: obj.port,
      privpwd: obj.privpwd,
      method: obj.method,
      seclevel: obj.seclevel,
      privtype: obj.privtype,
      version: obj.version,
    });
  };
  handleShowPasswordChange = (e) => {
    let val = e.target.checked;
    this.setState({
      showPassword: val,
      isDirty: true,
    });
  };

  handleShowCommunityStringChange = (e) => {
    let val = e.target.checked;
    this.setState({
      showCommunityString: val,
      isDirty: true,
    });
  };

  makePasswordTouchedDirty = (e) => {
    if (this.state.credentialId !== "") {
      this.setState({
        isPrivPassworTouched: false,
        privpwd: "",
        isPrivPasswordValid: false,
      });
    }
  };
  //communty string dirty

  makePassword_community_TouchedDirty = (e) => {
    if (this.state.credentialId !== "") {
      this.setState({
        isCommunityStringTouched: false,
        password: "",
        isPasswordValid: false,
      });
    }
  };
  //end
  resetTheCredentialForm = () => {
    this.closeError();
    if (this.state.credentialId === "") {
      this.setState({
        credentialName: "",
        isCredentialNameValid: true,
        credentialId: "",
        user: "",
        userName: "",
        isUserValid: true,
        password: "",
        isPasswordValid: true,
        showPassword: false,
        showCommunityString: false,
        showWelcomeText: false,
        editLabel: "",
        isDirty: false,
        isPasswordTouched: false,
        isCommunityStringTouched: false,
        version: "",
        port: 161,
        seclevel: "",
        privpwd: "",
        method: "",
        privtype: "",
        savepopUpOpen: true,
        popMassage: "Reset Successfully",
        popErrorType:'success'
      });
    } else {
      let temp = this.state.credentialObject;
      this.setState({
        credentialId: temp.id,
        editLabel: temp.name,
        credentialName: temp.name,
        version: temp.version,
        user: temp.userName,
        password: temp.password,
        port: temp.port,
        method: temp.method,
        seclevel: temp.seclevel,
        privtype: temp.privtype,
        privpwd: temp.privpwd,
        isCredentialNameValid: true,
        isUserValid: true,
        isPasswordValid: true,
        showPassword: false,
        showCommunityString: false,
        showWelcomeText: false,
        isDirty: false,
        isPasswordTouched: false,
        isCommunityStringTouched: false,
        savepopUpOpen: true,
        popMassage: "Reset Successfully",
        popErrorType:'success'

      });
    }
  };
  updateCredentialForm = (credObject) => {
    this.closeError();
    if (!credObject) {
      this.setState({
        credentialObject: {},
        credentialName: "",
        userName: "",
        editLabel: "",
        isCredentialNameValid: true,
        credentialId: "",
        user: "",
        isUserValid: true,
        password: "",
        isPasswordValid: true,
        showPassword: false,
        showCommunityString: false,
        showWelcomeText: false,
        isDirty: false,
        isPasswordTouched: false,
        isCommunityStringTouched: false,
        showRemoveButton: true,
        version: 1,
        method: "MD5",
        security: "NOAuthNoPriv",
        privtype: "DES",
        seclevel: "NOAuthNoPriv",
        privpwd: "",
        port: "161",
      });
    } else {
      this.setState({
        credentialObject: credObject,
        credentialName: credObject.name,
        user: credObject.userName ? credObject.userName : credObject.user,
        editLabel: credObject.name,
        isCredentialNameValid: true,
        credentialId: credObject.id,
        version: credObject.version,
        method: credObject.method,
        seclevel: credObject.seclevel,
        privtype: credObject.privtype,
        privpwd: credObject.privpwd,
        port: credObject.port,
        password: credObject.password,
        isUserValid: true,
        isPasswordValid: true,
        showPassword: false,
        showCommunityString: false,
        showWelcomeText: false,
        isDirty: false,
        isPasswordTouched: false,
        isCommunityStringTouched: false,
        showRemoveButton: true,
      });
    }
  };
  getDeleteTag = () => {
    let removeLabel = Resource.get("Remove"); //Remove
    let confirmationMessage = Resource.get("Remove this credential?"); //Remove this credential?
    let yesLabel = Resource.get("Yes"); //Yes
    let noLabel = Resource.get("No"); //No
    if (this.state.showRemoveButton) {
      return (
        <div
          style={{ paddingTop: "25px", }}
        >
          <Button
           variant="contained" color="error"

            onClick={this.deleteCredentialConfirmation}
          >
            {removeLabel}
          </Button>
        </div>
      );
    } else {
      return (
        <div
          style={{ paddingTop: "25px", }}
        >
          <span style={{ fontSize: "14px", marginRight: "15px" }}>
            {confirmationMessage}
          </span>
          <Button
           
            style={{marginRight:'8px'}}
          

            onClick={this.deleteCredential.bind(this)}
          >
            {yesLabel}
          </Button>
          <Button
           

            onClick={this.closeDeleteCredential.bind(this)}
          >
            {noLabel}
          </Button>
        </div>
      );
    }
  };
  closeError = () => {
    this.setState({
      error: {
        errorOccurred: false,
        errorMessage: "",
        errorDescription: "",
      },
    });
  };
  onDescription = () => {
    this.setState({
      savepopUpOpen: false,
    });
  };
  handleRequestClose = () => {
    this.setState({
      savepopUpOpen: false,
    });
  };
  render() {
    let addHeaderLabel = Resource.get("New SNMP Credential"); //New WMI Credential
    let headerLabel =
      this.state.credentialId === "" ? addHeaderLabel : this.state.editLabel;
    let nameLabel = Resource.get("Name"); //Name
    let IDLabel = Resource.get("ID"); //ID
    let passwordLabel = Resource.get("Password"); //Password
    let userLabel = Resource.get("User"); //User
    let showPasswordLabel = Resource.get("Show Password"); //Show Password
    let requiredLabel = Resource.get("Required"); //Required
    let showWelcomeTxt = this.state.showWelcomeText;
    let resetLabel = Resource.get("Reset"); //Reset
    let addLabel = Resource.get("Add"); //Add
    let updateLabel = Resource.get("Update"); //Update

    let deleteText = this.getDeleteTag();
    let version = Resource.get("Version"); //version
    let one = Resource.get("1"); //one
    let two = Resource.get("2"); //two
    let three = Resource.get("3"); //three
    let communitystring = Resource.get("Community string"); //community string
    let port = Resource.get("Port"); //Port
    let method = Resource.get("Method"); //Method
    let MD5 = Resource.get("MD5"); //MD5
    let SHA = Resource.get("SHA"); //SHA
    let SHA256 = Resource.get("SHA-256"); //SHA-256
    let security = Resource.get("Security"); //security
    let NOAuthNoPriv = Resource.get("NOAuthNoPriv"); //NOAuthNoPriv
    let PrivProtocol = Resource.get("Priv.Protocol"); //PrivProtocol
    let DES = Resource.get("DES"); // DES
    let AES = Resource.get("AES"); //AES
    let AES256 = Resource.get("AES-256"); //AES-256
    let AuthPriv = Resource.get("AuthPriv"); //AuthPriv
    let AuthNoPriv = Resource.get("AuthNoPriv"); //AuthNoPriv
    let privPassword = Resource.get("Priv.Password"); //priv.password
    let versionStyle = {
      position: "relative",
      bottom: "63px",
    };
    let versionWidth = {
      width: "420px",
    };
    const passwordRequired =
      this.state.version == 3 && this.state.seclevel === "AuthNoPriv"
        ? ""
        : "required";

    return (
      <div className="credential-vault">
        <CredentialVaultCatalogRedux
          type="SNMP"
          handleError={this.setError}
          refreshWelcome={this.enableWelcomeText}
          populateCred={this.updateCredentialForm}
          OnCredintialsReady={(api) => {
            this.credentialVaultHandle = api;
          }}
        />
        {showWelcomeTxt ? (
          <CredentialWelcomeText type="SNMP" />
        ) : (
          <div className="add-edit-credential" id={"add-edit-credential"}>
            <h3 className="header"> {headerLabel}</h3>
            {/*this.state.error.errorOccurred ? (
              <div className="error-ribbon">
                <div style={{ display: "inline-block", width: "100%" }}>
                  <div
                    style={{
                      display: "inline-block",
                      marginLeft: "22px",
                      marginRight: "22px",
                      verticalAlign: "middle",
                    }}
                  >
                    <a style={{ float: "left" }}>
                      <img src={errorIcon}
                        //Error Icon
                        aria-label={Resource.get("Error Icon")} />
                    </a>
                  </div>
                  <div style={{ display: "inline-block", width: "90%" }}>
                    <span>{this.state.error.errorMessage}</span>
                    <span
                      style={{
                        borderLeft: "2px solid #999999",
                        display: "inline-block",
                        margin: "10px 15px -5px",
                        paddingTop: "20px",
                      }}
                    ></span>
                    <span>{this.state.error.errorDescription}</span>
                  </div>
                  <div
                    style={{
                      display: "inline-block",
                      cursor: "pointer",
                      marginRight: "20px",
                      marginLeft: "10px",
                      verticalAlign: "middle",
                    }}
                    onClick={() => this.closeError()}
                  >
                    <a style={{ float: "left" }}>
                      {" "}
                      <img src={deleteIcon}
                        //Delete Icon
                        aria-label={Resource.get("Delete Icon")} />
                    </a>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )*/}
            <div style={{ width: "100%", marginBottom: '8px', display: 'flex', flexDirection: 'row' }}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel id="input-nameLabel" htmlFor="name" > {nameLabel}<span className="credentialvault-required-label">{requiredLabel}</span></InputLabel>


                    <TextField labelId="input-nameLabel"
                      inputProps={{ 'aria-required': true }}
autoFocus
                      error={!this.state.isCredentialNameValid}
                      fullWidth variant="outlined" size="small"
                      id="name" type="text"
                      value={this.state.credentialName}
                     // onBlur={this.handleCredentialNameChange.bind(this)}
                      onChange={this.handleCredentialNameChange.bind(this)} />

                  </FormControl>
                </Grid>
                <Grid item xs={4}>

                  {this.state.credentialId !== "" ? (<FormControl fullWidth >
                    <InputLabel id="input-IDLabel" htmlFor="credentialId" > {IDLabel}</InputLabel>


                    <TextField labelId="input-IDLabel"



                      fullWidth variant="outlined" size="small"
                      id="credentialId"
                      type="text" disabled="true"
                      value={this.state.credentialId}


                    />

                  </FormControl>) : (
                    null
                  )}
                </Grid>
              </Grid>
            </div>
            <div
              style={{ width: "100%" }}
            >
              <Grid container spacing={2}>
                {this.state.credentialId ? (


                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <InputLabel id="versionLabel" htmlFor="version"


                      >
                        {version}
                      </InputLabel>
                      <SelectField   size="small" 
                       // MenuProps={{ MenuListProps: { 'aria-label': version }, PaperProps: "" }}

                       


                        onChange={this.handleversionChange}
                        value={this.state.version ? this.state.version : 1}


                        role="combobox"
                        id="version" labelId="versionLabel"
         
          aria-label={version}
          aria-labelledby="viewBoxLabel"
          inputProps={{'aria-label':{version},
                                role: 'combobox',}}
         
            MenuProps={{
            MenuListProps:{
              'aria-labelledby' : "versionLabel",
              'aria-label' :version+" list",
              title:version
             }}}

                      >
                        <MenuItem value={1} label="1" children={one} />
                        <MenuItem value={2} label="2" children={two} />
                        <MenuItem value={3} label="3" children={three} />
                      </SelectField>
                    </FormControl>
                  </Grid>

                ) : (
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <InputLabel

                      >
                        {version}
                      </InputLabel>
                      <SelectField   size="small"
                       
                        
                        role="combobox"
                        id="version" labelId="versionLabel"
         
          aria-label={version}
          aria-labelledby="viewBoxLabel"
          inputProps={{'aria-label':{version},
                                role: 'combobox',}}
         
            MenuProps={{
            MenuListProps:{
              'aria-labelledby' : "versionLabel",
              'aria-label' :version+" list",
              title:version
             }}}


                        onChange={this.handleversionChange}
                        value={this.state.version ? this.state.version : 1}

                      >
                        <MenuItem value={1} label="1" children={one} />
                        <MenuItem value={2} label="2" children={two} />
                        <MenuItem value={3} label="3" children={three} />
                      </SelectField>
                    </FormControl>
                  </Grid>

                )}
              </Grid>
              </div>

              {this.state.credentialId ? (
                <div
                  style={{
                    width: "100%",marginTop:'8px'

                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <FormControl fullWidth>
                        <InputLabel id="input-userLabel" htmlFor="user" > {userLabel}<span className="credentialvault-required-label">{requiredLabel}</span></InputLabel>


                        <TextField labelId="input-userLabel"
                          inputProps={{ 'aria-required': true }}
                          disabled={this.state.version == 3 ? false : true}
                          error={!this.state.isUserValid}
                          fullWidth variant="outlined" size="small"
                          id="user"
                          value={this.state.user}
                          //onBlur={this.handleUserNameChange.bind(this)}
                          onChange={this.handleUserNameChange.bind(this)} />

                      </FormControl>
                    </Grid>


                    <Grid item xs={4}>
                      <FormControl fullWidth>
                        <InputLabel id="input-passwordLabel" htmlFor="password" > {this.state.version == 3 ? passwordLabel : communitystring}{this.state.isPasswordValid ? null : <span className="credentialvault-required-label">{requiredLabel}</span>}</InputLabel>


                        <TextField labelId="input-passwordLabel"
                          inputProps={{ 'aria-required': true }}

                          error={!this.state.isPasswordValid}
                          fullWidth variant="outlined" size="small"
                          id="password"
                          value={this.state.password}
                          type={this.state.showCommunityString ? "text" : "password"}
                          disabled={
                            this.state.version == 3 &&
                              (this.state.seclevel === "AuthNoPriv" ||
                                this.state.seclevel === "NOAuthNoPriv")
                              ? "disabled"
                              : ""
                          }
                          onClick={this.makePassword_community_TouchedDirty.bind(this)}
                          onBlur={this.handlePassword_community_Change.bind(this)}
                          onChange={this.handlePassword_community_Change.bind(this)}
                        />
                         {this.state.credentialId === "" ||
                this.state.isCommunityStringTouched ? (
                          <div
                            style={{ marginTop: '8px' }}
                          >
                            <FormControlLabel
                              control={
                                <Checkbox inputProps={{ 'aria-label': showPasswordLabel }}
                                  color="primary"
                                  classes={{ root: "credentialvault-checkbox-container" }}
                                  onChange={this.handleShowCommunityStringChange.bind(this)}
                                  checked={this.state.showCommunityString}
                                  id="credShowCommunityString_CheckBox"
                                />}
                              label={showPasswordLabel} />


                          </div>):null}
                        

                      </FormControl>
                    </Grid></Grid>



                </div>


              ) : (
                <div
                  style={{
                    width: "100%",marginTop:'8px'

                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={4}>

                      <FormControl fullWidth>
                        <InputLabel id="input-userLabel" htmlFor="user" > {userLabel}<span className="credentialvault-required-label">{requiredLabel}</span></InputLabel>


                        <TextField labelId="input-userLabel"
                          inputProps={{ 'aria-required': true }}
                          disabled={this.state.version == 3 ? false : true}
                          error={!this.state.isUserValid}
                          fullWidth variant="outlined" size="small"
                          id="user"
                          value={this.state.user}
                          onBlur={this.handleUserNameChange.bind(this)}
                          onChange={this.handleUserNameChange.bind(this)} />

                      </FormControl>
                    </Grid>

                    <Grid item xs={4}>
                      <FormControl fullWidth>
                        <InputLabel id="input-passwordLabel" htmlFor="password" > {this.state.version == 3 ? passwordLabel : communitystring}{this.state.isPasswordValid ? null : <span className="credentialvault-required-label">{requiredLabel}</span>}</InputLabel>


                        <TextField labelId="input-passwordLabel"
                          inputProps={{ 'aria-required': true }}

                          error={!this.state.isPasswordValid}
                          fullWidth variant="outlined" size="small"
                          id="password"
                          value={this.state.password}
                          type={this.state.showCommunityString ? "text" : "password"}
                          disabled={
                            this.state.version == 3 &&
                              (this.state.seclevel === "AuthNoPriv" ||
                                this.state.seclevel === "NOAuthNoPriv")
                              ? "disabled"
                              : ""
                          }
                          onClick={this.makePassword_community_TouchedDirty.bind(this)}
                          onBlur={this.handlePassword_community_Change.bind(this)}
                          onChange={this.handlePassword_community_Change.bind(this)}
                        />
                      {this.state.credentialId === "" ||
                this.state.isCommunityStringTouched ? (
                          <div
                            style={{
                              marginTop: '8px'
                            }}
                          >
                            <FormControlLabel
                              control={
                                <Checkbox inputProps={{ 'aria-label': showPasswordLabel }}
                                  color="primary"
                                  classes={{ root: "credentialvault-checkbox-container" }}
                                  onChange={this.handleShowCommunityStringChange.bind(this)}
                                  checked={this.state.showCommunityString}
                                  id="credShowCommunityString_CheckBox_2"
                                />}
                              label={showPasswordLabel} />

                          </div>):null}
                       

                      </FormControl>


                    </Grid>
                  </Grid>

                </div>
              )}

              {this.state.credentialId ? (
                <div
                  style={{
                    marginTop: '8px',width:'100%'
                  }}
                >
                 <Grid container spacing={2}>
                    <Grid item xs={4}>


                  <FormControl fullWidth>
                    <InputLabel id="input-portLabel" htmlFor="port" > {port}<span className="credentialvault-required-label">{requiredLabel}</span></InputLabel>


                    <TextField labelId="input-portLabel"

                      inputProps={{ 'aria-required': true }}
                      disabled={this.state.version == 3 ? false : true}
                      error={this.state.isPortValid && !this.state.isPortEmpty ? null : "Invalid Port (Valid range from 0 to 65535)"}
                      fullWidth variant="outlined" size="small"
                      id="port"
                      value={this.state.port}
                      onBlur={this.handlePortNameChange.bind(this)}
                      onChange={this.handlePortNameChange.bind(this)} />

                  </FormControl>
                  </Grid>
                  </Grid>
                </div>
              ) : (
                <div
                  style={{ width: "100%",marginTop:'8px' }}
                >
<Grid container spacing={2}>
                    <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel id="input-portLabel" htmlFor="port" > {port}<span className="credentialvault-required-label">{requiredLabel}</span></InputLabel>


                    <TextField labelId="input-portLabel"

                      inputProps={{ 'aria-required': true }}

                      error={this.state.isPortValid && !this.state.isPortEmpty ? null : "Invalid Port (Valid range from 0 to 65535)"}
                      fullWidth variant="outlined" size="small"
                      id="port"
                      value={this.state.port}
                      onBlur={this.handlePortNameChange.bind(this)}
                      onChange={this.handlePortNameChange.bind(this)} />

                  </FormControl>
</Grid></Grid>
                </div>
              )}
              <div style={{ display: 'flex', flexDirection: 'row',width:'100%',marginTop:'8px' }}

              >
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel  htmlFor="method" id="methodLabel"

                    >
                      {method}
                    </InputLabel>

                    <SelectField  size="small"
                     
                      aria-label={method}
id="method"
                      // onChange={this.handleversionChange}
                      value={this.state.method ? this.state.method : "MD5"}


                      
         
                      role='combobox'  labelId="methodLabel" 
          aria-labelledby="methodLabel"
          inputProps={{'aria-label':{method},
                                role: 'combobox',}}
         
            MenuProps={{
            MenuListProps:{
              'aria-labelledby' : "methodLabel",
              'aria-label' :method+" list",
              title:method
             }}}

                      disabled={this.state.version == 3 ? "" : "disabled"}
                      onChange={this.handleMethodChange}
                    >
                      <MenuItem value={"MD5"} label="MD5" children={MD5} />
                      <MenuItem value={"SHA"} label="SHA" children={SHA} />
                      <MenuItem value={"SHA-256"} label="SHA-256" children={SHA256} />
                      <MenuItem value="None" label="None" children="None" />
                    </SelectField>
                  </FormControl>
                </Grid>

                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel
htmlFor="security" id="securityLabel"

                    >
                      {security}
                    </InputLabel>
                    <SelectField  role='combobox' size="small" labelId="securityLabel" id="security"
                     
                      aria-label={security}


                    
          aria-labelledby="securityLabel"
          inputProps={{'aria-label':{security},
                                role: 'combobox',}}
         
            MenuProps={{
            MenuListProps:{
              'aria-labelledby' : "securityLabel",
              'aria-label' :security+" list",
              title:security
             }}}

                      value={
                        this.state.seclevel ? this.state.seclevel : "NOAuthNoPriv"
                      }

                      disabled={this.state.version == 3 ? "" : "disabled"}
                      onChange={this.handleSecurityChange}
                    >
                      <MenuItem
                        value={"NOAuthNoPriv"}
                        label="NOAuthNoPriv"
                        children={NOAuthNoPriv}
                      />
                      <MenuItem
                        value={"AuthPriv"}
                        label="AuthPriv"
                        children={AuthPriv}
                      />
                      <MenuItem
                        value={"AuthNoPriv"}
                        label="AuthNoPriv"
                        children={AuthNoPriv}
                      />
                    </SelectField>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel
id="privProtocolLabel" htmlFor="privProtocol"
                    >
                      {PrivProtocol}
                    </InputLabel>

                    <SelectField  role='combobox' size="small" htmlFor="privProtocol" id="privProtocol"
                      //MenuProps={{ MenuListProps: { 'aria-label': { PrivProtocol } } }}
                      aria-label={PrivProtocol}
                      
          aria-labelledby="privProtocolLabel"
          inputProps={{'aria-label':{PrivProtocol},
                                role: 'combobox',}}
         
            MenuProps={{
            MenuListProps:{
              'aria-labelledby' : "privProtocolLabel",
              'aria-label' :PrivProtocol+" list",
              title:PrivProtocol
             }}}

                      value={this.state.privtype ? this.state.privtype : "DES"}

        //aria-expanded={false}
                      disabled={
                        this.state.version == 3 && this.state.seclevel === "AuthPriv"
                          ? ""
                          : "disabled"
                      }
                      onChange={this.handleProtocolChange.bind(this)}
                    >
                      <MenuItem value={"DES"} label="DES" children={DES} />
                      <MenuItem value={"AES"} label="AES" children={AES} />
                      <MenuItem value={"AES-256"} label="AES-256" children={AES256} />
                    </SelectField>
                  </FormControl></Grid>
              </Grid>
              </div>

              <Grid container spacing={2} style={{marginTop:'8px'}}>
                    <Grid item xs={4}>
                <FormControl fullWidth>
                  <InputLabel id="input-privPasswordLabel" htmlFor="privPassword" > {privPassword}<span className="credentialvault-required-label">{requiredLabel}</span></InputLabel>


                  <TextField labelId="input-privPasswordLabel"
                    inputProps={{ 'aria-required': true }}


                    fullWidth variant="outlined" size="small"
                    id="privPassword"
                    value={this.state.privpwd}
                    type={this.state.showPassword ? "text" : "password"}
                    onClick={this.makePasswordTouchedDirty.bind(this)}
                    onBlur={this.handlePasswordChange.bind(this)}
                    onChange={this.handlePasswordChange.bind(this)}
                    disabled={
                      this.state.version == 1 ||
                        this.state.version == 2 ||
                        this.state.seclevel === "AuthNoPriv" ||
                        this.state.seclevel === "NOAuthNoPriv"
                        ? "disabled"
                        : ""
                    }
                  />
                 
                 {this.state.credentialId === "" ||
            this.state.isPrivPassworTouched ? ( <div
                            style={{
                              marginTop: '8px'
                            }}
                          >
                            <FormControlLabel
                              control={
                                <Checkbox inputProps={{ 'aria-label': showPasswordLabel }}
                                  color="primary"
                                  classes={{ root: "credentialvault-checkbox-container" }}
                                  onChange={this.handleShowPasswordChange.bind(this)}
                    checked={this.state.showPassword}
                                  id="credShowpass_CheckBox"
                                />}
                              label={showPasswordLabel} />

                          </div>):null}
                 
               

                </FormControl>
              </Grid>
              </Grid>

              
              {!this.state.isDirty && this.state.credentialId !== "" ? (
                deleteText
              ) : (
                <div style={{width:'100%',marginTop:'25px'}}>
                  <Button
                    type="button" style={{marginRight:'8px'}}
                    aria-label={this.state.credentialId === "" ? addLabel : updateLabel}
                    variant="contained"
                    disabled={
                      this.state.version === 3 &&
                        (this.state.seclevel === "AuthNoPriv" ||
                          this.state.seclevel === "NOAuthNoPriv")
                        ? isEmpty(this.state.credentialName) ||
                        this.state.port === null ||
                        this.state.port <= 0
                        : this.state.version === 3
                          ? isEmpty(this.state.credentialName) ||
                          isEmpty(this.state.privpwd) ||
                          this.state.port === null ||
                          this.state.port <= 0 ||
                          isEmpty(this.state.user) ||
                          isEmpty(this.state.password)
                          : isEmpty(this.state.credentialName)
                    }

                    onClick={this.SaveOrUpdateTheCredential.bind(this)}
                  >
                    {this.state.credentialId === "" ? addLabel : updateLabel}{" "}
                  </Button>
                  <Button
                    type="button"
                    aria-label={resetLabel}
                    variant="text"
                    onClick={this.resetTheCredentialForm.bind(this)}
                  >
                    <span>{resetLabel}</span>
                  </Button>
                </div>
              )}
            </div>
        )}
        {this.state.savepopUpOpen === true  && this.state.popErrorType!=='error'? (
              <div>
                {/*<Snackbar
                  key="snackbar"
                  open={this.state.savepopUpOpen}
                  autoHideDuration={2000}
                  onClose={() => this.setState({ savepopUpOpen: null })}
                // onActionTouchTap={this.onDescription}
                // onRequestClose={this.handleRequestClose}
                >
                 
                    {this.state.popMassage}
                  
                </Snackbar>*/}
                <CustomSnackbar message={this.state.popMassage} severity={this.state.popErrorType} close={() => this.setState({ savepopUpOpen: null })} autoHideDuration={4000} open={this.state.savepopUpOpen} />
     
              </div>
            ) : ([])}
      <div>
          
          <CustomSnackbar  message={`${this.state.error.errorMessage} ${this.state.error.errorDescription?": "+this.state.error.errorDescription:""}`} severity={"error"} 
          close={() => this.closeError()} autoHideDuration={4000} open={this.state.error.errorOccurred} />
     
        </div>
          </div>
        );
  }
}
        export default SnmpCredentialVault;
