// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //File Details:
  content["File Details:"] =
  "ファイルの詳細:";

  //File Name
  content["File Name:"] =
  "ファイル名:";

  //File Type:
  content["File Type:"] =
  "ファイル タイプ:";

  //File upload text
  content["Select file before Pressing the Upload button"] =
  "ファイルを選択してから [アップロード] ボタンを押してください";

  //Cancel
  content["Cancel"] =
  "キャンセル";

  //Upload
  content["Upload"] =
  "アップロード";

  //device import
  content["Device Import"] =
  "デバイスのインポート";

  //Inventory
  content["Inventory"] =
  "インベントリ";

  //Refreshed
  content["Refreshed"] =
  "更新時間";

  //Results
  content["Results"] =
  "結果数";

  //Import
  content["Import"] =
  "インポート";

  //Discovery Wizard
  content["Discovery Wizard"] =
  "ディスカバリ ウィザード";

  //Robot Deployment
  content["Robot Deployment"] =
  "ロボット展開";

  // Search computer systems
  content["Search computer systems"] =
  "コンピュータ システムの検索";

  // END OF LOCALIZATION

export default content;
