import React, { useEffect, useMemo, useState } from "react";
import moment from "moment";
import {
  ComposedChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Area,
  Bar,
  ReferenceLine,
  Label
} from "recharts";
// import "./QosChartView.less";
import { useSelector } from "react-redux";
import { Box, Typography, Switch, FormControlLabel } from "@mineral/core";
import prd from "../../../../../../../api/performanceReports/prd";
import { isArray, isEqual } from "lodash";
import { decToHex, randomColoFn } from "../qos-chart-container/QosChart";
import CustomTooltip from "./CustomTooltip";
import CustomLegend from "./CustomLegend";
import CustomPlotPoint from "./CustomPlotPoint";
import { strokeColors } from './dataset'
import { strokeCombinations } from "../mineral-chart-container/dataset";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
//import './ChartView.less';

const ChartView = (props) => {
  const [selectedSnackbar, setSelectedSnackbar] = useState(false);
  const [selectedNode, setSelectedNode] = useState("");
  const [selected, setSelected] = useState(false);  
  const [isBaseline,setIsBaseline]=useState(false);
  const [isTrendLine,setIsTrendLine]=useState(false);  
  const timeFrame = useSelector(
    (state) => state.performanceReports.chartData.State.timeFrame
  );
  const startTime = useSelector(
    (state) => state.performanceReports.chartData.State.TimeStart
  );
  const stopTime = useSelector(
    (state) => state.performanceReports.chartData.State.TimeStop
  );

  const isChartExpanded = useSelector(
    (state) =>
      state.performanceReports.chartData.State.GraphMaximized ==
      props.chartObject.chartId
  );
  const [finalDataArray, setFinalArray] = useState([]);
  const [rechartsResizeHeight, setRechartsResizeHeight] = useState("100%");

  let filteredChartData = props.filteredChartData;
  let selectedCombos = props.selectedCombos;
  let finalArray = [];
  let percentileValue =
    props.chartObject.series && props.chartObject.series.length > 0
      ? props.chartObject.series[0].pLine.percentile.length > 0
        ? props.chartObject.series[0].pLine.percentile
        : 0
      : 0;
  let scaledValue =
    props.chartObject.series && props.chartObject.series.length > 0
      ? props.chartObject.series[0].scale && props.chartObject.series[0].scale.length > 0
        ? props.chartObject.series[0].scale
        : 1
      : 1;
  const showMaxMin = props.showMaxMin;
  const changeMaxMin = (val) => {
    props.changeMaxMin(val);
  }
  const changeRefRecord = (refobj) => {
    let finalArrayNew = finalArray.filter((item) => {
      //console.log("finalArrayNew",new Date(item.t_0));
      //console.log("finalArrayNew",item.t_0);
      if (parseInt(item.t_0) >= refobj.t_0)
        return item;
    });

    if(finalArrayNew.length===0)
    {
      finalArrayNew = finalArray.filter((item) => {
        //console.log("finalArrayNew",new Date(item.t_0));
        //console.log("finalArrayNew",item.t_0);
        if (parseInt(item.t_0) <= refobj.t_0)
          return item;
      });
    }
    setRefRecord(finalArrayNew[0]);
    if (finalArrayNew[0] !== undefined) {
      setDefaultRefPoint(finalArrayNew[0]);
    }
  }
  useEffect(() => {
    if (rechartsResizeHeight === "100%") {
      setRechartsResizeHeight("99%");
    } else {
      setRechartsResizeHeight("100%");
    }
  }, [props.chartObject.showLegend]);

  useEffect(() => {
    (async () => {
      if (props.chartObject.baseline == "true") {
        setIsBaseline(true);
        let computedArray = props.finalArray;
        let baselineUrlRequests = [];
        for (let currentChart in filteredChartData) {
          let urlRequest = {
            r_table: filteredChartData[currentChart].r_table,
            startTime: props.startTime,
            stopTime: props.stopTime,
            table_id: filteredChartData[currentChart].table_id,
          };

          baselineUrlRequests.push(prd.getChartBaselineData(urlRequest));
        }

        let [...resolvedBaseLineData] = await Promise.all(baselineUrlRequests);        

        let baselineArray = [];

        for (let i = 0; i < computedArray.length; i++) {
          let mergedObject = { ...computedArray[i] };

          if (mergedObject[`t_${0}`] == null) {
            mergedObject = {
              ...mergedObject,
              //...resolvedBaseLineData.map((k,k_index)=>{[`baseline_${k_index}`]=null})
            };
          } else {
            for (let j = 0; j < resolvedBaseLineData.length; j++) {
              let baselineData = resolvedBaseLineData[j].data;
              var target = moment(mergedObject[`t_${0}`]);
              var targetBaselineData = baselineData.find((d) => {
                var start = moment(d.starttime);
                var finish = moment(d.stoptime);

                return target.isBetween(start, finish, "milliseconds", "[]");
              });             
              let mappedObject = {};
              if (targetBaselineData) {
                mappedObject = {
                  [`baseline_${j}`]: targetBaselineData.samplevalue,
                };
              } else {
                mappedObject = {
                  [`baseline_${j}`]: null,
                };
              }
              mergedObject = {
                ...mergedObject,
                ...mappedObject,
              };
            }
          }
          baselineArray.push(mergedObject);
        }
        setFinalArray(baselineArray);
      }
      else
      {
        setIsBaseline(false);
      }
      if (props.chartObject.trend == "true") {
        setIsTrendLine(true);
      }
      else
      {
        setIsTrendLine(false);
      }
    })();
  }, [props.chartObject, props.finalArray, props.startTime, props.stopTime]);


  if (props.chartObject.baseline == "false") {
    finalArray = props.finalArray;
  } else {
    finalArray = finalDataArray;
  }

  const selectNode = (node) => {
    handleRowClick(node);
  }


  const isSelected = (node) => {
    return (
      props.selectedSeries.find((selectedNode) =>
        isEqual(selectedNode, node)
      ) != undefined
    );
  };
  const handleRowClick = (node) => {
    let selectedSeriesDataList = [];
    if (isSelected(node)) {
      selectedSeriesDataList = props.selectedSeries.filter(
        (d) => !isEqual(d, node)
      );
    } else {
      selectedSeriesDataList = [...props.selectedSeries, node];
    }
    setSelected(!isSelected(node));
    props.handleSelectedSeries(selectedSeriesDataList);
    if (node.displayName !== undefined) {
      setSelectedNode(node.displayName)
      setSelectedSnackbar(true);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      set_ref(finalArray, 1);
    }, 1000)

  }, [finalArray])

  const set_ref = (finalArray, position) => {    
    const refpoint = finalArray[finalArray.length - parseInt(position)]
    if(refpoint!==undefined){
    if (refpoint.avg_0 !== null || position === finalArray.length) {
      setRefRecord(refpoint);
      console.log("refpoint",refpoint);
      //setDefaultRefPoint(refRecord);
      //const [refRecord, setRefRecord] = React.useState(set_ref(finalArray,1));
      //const [defaultRefPoint, setDefaultRefPoint] = React.useState(refRecord);
    }
    else
      set_ref(finalArray, parseInt(position) + 1);
  }
  }

  

  let chartType =
    props.chartObject.stacked == "true"
      ? "col"
      : props.chartObject.series && props.chartObject.series.length > 0
        ? props.chartObject.series[0].style
        : "line";
  //const [refRecord, setRefRecord] = React.useState(set_ref(finalArray,1));
  //const [defaultRefPoint, setDefaultRefPoint] = React.useState(refRecord);
  const [refRecord, setRefRecord] = React.useState({});
  const [defaultRefPoint, setDefaultRefPoint] = React.useState({});

  useEffect(()=>{
    setDefaultRefPoint(refRecord);    
  },[refRecord])


  const ReferenceLineLabel = (props) => {
    const { viewBox, label, layout } = props;
    console.log("props", props);
    let xcoord = 0
    if (layout === 'vertical') {
      if (viewBox.x > 150 && viewBox.x < 550) {
        xcoord = viewBox.x - 170
      }
      else if (viewBox.x < 150) {
        xcoord = viewBox.x - 50
      }
      else {
        xcoord = viewBox.x - 275
      }
    }
    else {
      if (viewBox.x > 150 && viewBox.x < 500) {
        xcoord = viewBox.x - 170
      }
      else if (viewBox.x < 150) {
        xcoord = viewBox.x - 50
      }
      else {
        xcoord = viewBox.x - 270
      }
    }
    return (
      <g>
        <foreignObject
          x={xcoord}
          y={viewBox.y - 20}
          width={275}
          height={viewBox.height + 20}
        >
          <Box
            sx={{
              backgroundColor: "#434A54",
              color: "white",
              textAlign: "center",
              borderRadius: 1,
              height: "20px",
            }}
          >
            <Typography variant="body2" sx={{ color: "white" }}>{label}</Typography>
          </Box>
        </foreignObject>
      </g>
    );
  };

  return (
    <>
      <Snackbar open={selectedSnackbar}
        autoHideDuration={5000}
        onClose={() => setSelectedSnackbar(false)}
      >
        <Alert severity="success">
          {selectedNode} has been {selected ? "selected" : "unselected"}.
        </Alert>
      </Snackbar>
      {<ResponsiveContainer
        width="100%" height="80%"
      >
        <ComposedChart
          data={finalArray}
          connectNulls={false}
          onClick={(nextState, event) => {
            if (nextState) {
              setRefRecord(nextState.activePayload[0].payload);
              setDefaultRefPoint(nextState.activePayload[0].payload);
            }
          }}

          onMouseMove={(nextState, event) => {
            if (nextState.isTooltipActive) {
              setDefaultRefPoint(nextState.activePayload[0].payload);
            }
            else {
              setDefaultRefPoint(refRecord);
            }
          }}
          onMouseLeave={() => {
            setDefaultRefPoint(refRecord);
          }}

          margin={{
            top: 25,
            right: 30,
            left: 20,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="1 1" />
          <XAxis
            type="number"
            domain={["dataMin", "dataMax"]}
            dataKey="t_0"
            tickCount={15}
            padding={
              chartType == "col"
                ? finalArray.length * filteredChartData.length < 15 ||
                  filteredChartData.length > 1
                  ? { left: 20, right: 20 }
                  : { left: 20, right: 20 }
                : { left: 0, right: 0 }
            }
            interval={moment(+stopTime).diff(moment(+startTime), "days") > 1 ? isChartExpanded ? 1 : "preserveStartEnd" : "preserveStartEnd"}
            tickFormatter={(tickItem) => {
              return moment(tickItem).format(
                `${moment(+stopTime).diff(moment(+startTime), "days") >= 1
                  ? moment(+stopTime).diff(moment(+startTime), "days") < 8 ?
                    (navigator.language ? (navigator.language == 'de' ? "DD/MM HH:mm" : "MM/DD HH:mm") : "MM/DD/YYYY") :
                    (navigator.language ? (navigator.language == 'de' ? "DD/MM/YYYY" : "MM/DD/YYYY") : "MM/DD/YYYY")
                  : "HH:mm"
                }`
              );
            }}
            dy={6}
          />

          <YAxis
            yAxisId={"left"}
            orientation={"left"}
            domain={
              props.chartObject.minYAxis1
                ? props.chartObject.minYAxis1.length > 0 &&
                  props.chartObject.minYAxis1 != 0
                  ? [
                    parseInt(props.chartObject.minYAxis1),
                    parseInt(props.chartObject.maxYAxis1),
                  ]
                  : [0, "auto"]
                : [0, "auto"]
            }
            label={{
              value:
                filteredChartData.length > 0 ? filteredChartData[0].unit : "",
              angle: -90,
              position: "insideLeft",
              style: { textAnchor: "middle" },
            }}
          />
          {filteredChartData.length > 1 &&
            filteredChartData.filter((d) => d.unit != filteredChartData[0].unit)
              .length > 0 ? (
            <YAxis
              yAxisId={"right"}
              orientation={"right"}
              domain={
                props.chartObject.minYAxis1
                  ? props.chartObject.minYAxis1.length > 0 &&
                    props.chartObject.minYAxis1 != 0
                    ? [
                      parseInt(props.chartObject.minYAxis1),
                      parseInt(props.chartObject.maxYAxis1),
                    ]
                    : [0, "auto"]
                  : [0, "auto"]
              }
              label={{
                value: filteredChartData.filter(
                  (d) => d.unit != filteredChartData[0].unit
                )[0].unit,
                angle: -90,
                position: "right",
                style: { textAnchor: "middle", fill: "#5B676C" },
              }}
            />
          ) : null}
          <Tooltip content={<CustomTooltip />} />
          {props.chartObject.showLegend == 'false' ? <Legend
            wrapperStyle={{
              width: '97%',
              height: `${isChartExpanded ? '45%' : '60%'}`,
            }}
            layout={"horizontal"}
            verticalAlign={"bottom"}
            content={(props) => CustomLegend({
              data: filteredChartData,
              referenceData: defaultRefPoint.t_0,
              refLineData: refRecord,
              setRefLineData: changeRefRecord,
              selectedCombos: selectedCombos,
              startTime: startTime,
              endTime: stopTime,
              percentileValue: percentileValue,
              label: moment(refRecord.t_0).toString(),
              showMaxMin: showMaxMin,
              changeMaxMin: changeMaxMin,
              scaledValue: scaledValue,
              selectNode: selectNode,
              isChartExpanded: isChartExpanded,
              isBaseline:isBaseline,
              baselineValue:defaultRefPoint?.baseline_0,
              isTrendLine:isTrendLine,
              trendLineValue:defaultRefPoint?.trendline_0,              
              ...props
            })}
          /> : <Legend wrapperStyle={{
            width: '97%',
            height: '25%',
            maxHeight: '25%',
            overflow: "auto",
          }} layout={"horizontal"}
            verticalAlign={"bottom"} />}

          <ReferenceLine x={refRecord.t_0} yAxisId={"left"} stroke="#434A54" strokeDasharray="3 3">
            <Label
              position={"top"}
              content={""}
            ></Label>
          </ReferenceLine>
          {filteredChartData.map((chart, index) => {
            const unit = filteredChartData[index].unit_short;
            let propsObject = {
              key: `${chart.unit}-${index}`,
              yAxisId: `${index == 0
                ? "left"
                : filteredChartData[0].unit == filteredChartData[index].unit
                  ? "left"
                  : "right"
                }`,
              type: "monotone",
              dataKey: `avg_${index}`,
              unit: `${filteredChartData[index].unit_short}`,
              name: `${filteredChartData[index].source}, ${filteredChartData[index].qos
                }, ${filteredChartData[index].target}, ${filteredChartData[index].dataMin != null
                  ? filteredChartData[index].dataMin
                  : null
                } ${filteredChartData[index].dataMin != null ? unit : ""}, ${filteredChartData[index].dataMax != null
                  ? filteredChartData[index].dataMax
                  : null
                } ${filteredChartData[index].dataMax != null ? unit : ""},
                                                                                        ${filteredChartData[
                  index
                ]
                  .dataStdDev !=
                  null
                  ? filteredChartData[
                    index
                  ].dataStdDev.toFixed(
                    2
                  )
                  : "null"
                } ${filteredChartData[index].dataStdDev != null ? unit : ""
                },
                                                                                            ${filteredChartData[
                  index
                ]
                  .dataAvg !=
                  null
                  ? filteredChartData[
                    index
                  ].dataAvg.toFixed(
                    2
                  )
                  : null
                } ${filteredChartData[index].dataAvg != null ? unit : ""
                }
                                                                                                `,
              animationDuration: 0,
              strokeWidth: 1,
              dot: false,
              connectNulls: false,
              stroke: selectedCombos[index].color,
              fill: selectedCombos[index].color,
              isAnimationActive: false,
            };
            if (props.chartObject.stacked == "true") {
              chartType = "col";
              propsObject = {
                ...propsObject,
                stackId: `${index == 0
                  ? "left"
                  : filteredChartData[0].unit == filteredChartData[index].unit
                    ? "left"
                    : "right"
                  }`,
              };
            }
            if (chartType == "col") {
              //if (finalArray.length * filteredChartData.length < 15) {
              propsObject = {
                ...propsObject,
                barSize: 10,
                strokeWidth: 1,
              };
              //}              
            }

            let areaObject = {
              ...propsObject,
              isAnimationActive: false,
              fillOpacity: 0.5,
              strokeWidth: 1,
            };
            let percentileObject = {
              ...propsObject,
              dataKey: `percentile_${index}`,
              key: `${chart.unit}-${index}-percentile`,
              isAnimationActive: false,
              stroke: decToHex(randomColoFn()),
              fill: decToHex(randomColoFn()),
            };
            let usmObject = {
              ...propsObject,
              dataKey: `usm_min_max_${index}`,
              key: `${chart.unit}-${index}-max`,
              isAnimationActive: false,
              fillOpacity: 0.3,
              strokeOpacity: 0,
              dot: false,
            };
            let baselineObject = {
              ...propsObject,
              dataKey: `baseline_${index}`,
              key: `${chart.unit}-${index}-baseline`,
              stroke: decToHex(randomColoFn()),
              strokeDasharray: "5 5",
              isAnimationActive: false,
              yAxisId: `${index == 0
                ? "left"
                : filteredChartData[0].unit == filteredChartData[index].unit
                  ? "left"
                  : "right"
                }`,
              dot: false,
              stroke: selectedCombos[index].color,
              fill: selectedCombos[index].color,
              isAnimationActive: false,
            };
            let trendlineObject = {
              ...propsObject,
              dataKey: `trendline_${index}`,
              key: `${chart.unit}-${index}-trendline`,
              stroke: decToHex(randomColoFn()),
              strokeDasharray: "5 5",
              isAnimationActive: false,
              yAxisId: `${index == 0
                ? "left"
                : filteredChartData[0].unit == filteredChartData[index].unit
                  ? "left"
                  : "right"
                }`,
              dot: false,
              stroke: selectedCombos[index].color,
              fill: selectedCombos[index].color,
              isAnimationActive: false,
            };
            let plottedData =
              chartType == "area"
                ? [<Area {...areaObject} />]
                : chartType == "col"
                  ? [<Bar {...propsObject} />]
                  : chartType == "line"
                    ? [<Line {...propsObject} />]
                    : chartType == "usm"
                      ? [<Area {...usmObject} />, <Line {...propsObject} />]
                      : [<Line {...propsObject} />];

            /*if (percentileValue != 0) {
              plottedData.push(<Line {...percentileObject} />);
            }*/

            if (props.chartObject.baseline == "true") {
              plottedData.push(<Line {...baselineObject} />);
            }

            if (props.chartObject.trend == "true") {
              plottedData.push(<Line {...trendlineObject} />);
            }

            return plottedData;
          })}
        </ComposedChart>
      </ResponsiveContainer>}
    </>
  );
};

export default ChartView;
