import React, { Component } from 'react'
import Resource from './Resource'
import './ProfileDetails.less'


		 

const ProfileDetails = (props) => {

 let profileDetailsHeading=Resource.get('Profile Properties')//Profile Properties
 let profileTypeHeading=Resource.get('Profile Type');//Profile Type
 let alarmPolicyHeading=Resource.get('Alarm Policy');//Alarm Policy
 let lastDeploymentStatusHeading=Resource.get('Last Deployment Status');//Last Deployment Status
 let lastDeploymentTimeHeading=Resource.get('Last Deployment Time');//Last Deployment Time
 let lastDeploymentStatusMessage=Resource.get('Last Deployment Status Message');//Last Deployment Status Message                   
		let status=props.profile.status
	let statusButton=''
				if(status!==undefined && status!==undefined)
      {
          
         if( status.includes('Deployed'))
               statusButton=(<span style={{'color': '#038537','font-size': '25px',
  'vertical-align': 'sub'}}>●</span>)
         else if( status.includes('Failed'))
statusButton=(<span style={{'color': '#DE1B1B','font-size': '25px',
  'vertical-align': 'sub'}}>●</span>)
         else if( status.includes('Pending'))
statusButton=(<span style={{'color': '#8E99AB','font-size': '25px',
  'vertical-align': 'sub'}} >●</span>)
         else if( status.includes('Not Applicable') || status.includes('NA'))
statusButton=(<span style={{'color': '#CCCCCC','font-size': '25px',
  'vertical-align': 'sub'}} >●</span>)
      }								
    return (
         <div className="profile_details" style={{height:props.currentHeight}}>
			 			<h3>{profileDetailsHeading}</h3>
						 <div style ={{paddingTop: "5px"}} className="head">
							<div> {profileTypeHeading}</div>
						 </div>
						 <div className="text">
							<div> {props.profile.ancestorProfile!==undefined && props.profile.ancestorProfile!==''? 'Group':'Device'}</div>
						 </div>
						 <div className="head">
							<div> {alarmPolicyHeading}</div>
						 </div>
						 <div className="text">
							<div> {props.profile.alarmPolicyName}</div>
						 </div>
						 <div className="head">
							<div> {lastDeploymentStatusHeading}</div>
						 </div>
						 <div className="text">
							<div> {statusButton}<span>{`  `+props.profile.status}</span></div>
						 </div>
						  <div className="head">
							<div> {lastDeploymentTimeHeading}</div>
						 </div>
						 <div className="text">
							<div> {props.profile.lastDeployedTime}</div>
						 </div>
						 <div className="head">
							<div> {lastDeploymentStatusMessage}</div>
						 </div>
						 <div className="text" style={{'padding-bottom': '20px'}}>
							<div> {props.profile.latestDeploymentSummary}</div>
						 </div>
					  </div>
    )
}

export default ProfileDetails;