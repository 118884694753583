import {
  FETCH_ACTIVE_PROBES,
  FETCH_ACTIVE_PROBES_SUCCESS,
  FETCH_ACTIVE_PROBES_FAILURE
} from './actionTypes'
import fetchStatusTypes from '../fetchStatusTypes'

const activeProbes = (state = {
  isFetching: false,
  fetchStatus: fetchStatusTypes.UNDEFINED,
  didInvalidate: false,
  items: []
}, action) => {

  switch (action.type) {
    case FETCH_ACTIVE_PROBES:
      return Object.assign({}, state, {
        isFetching: true,
        fetchStatus: fetchStatusTypes.FETCHING,
        didInvalidate: false,
        items: []
      });
    case FETCH_ACTIVE_PROBES_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        fetchStatus: fetchStatusTypes.COMPLETE,
        didInvalidate: false,
        items: action.probes._items
      });
    case FETCH_ACTIVE_PROBES_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        fetchStatus: fetchStatusTypes.FAIL,
        didInvalidate: true,
        errorMessage: action.errorMessage,
        items: []
      });
    default:
      return state
  }
};

export default activeProbes
