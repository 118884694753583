// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Elements
  content["Elements"] =
  "Elementos";

  //Total Alarms
  content["Total Alarms"] =
  "Núm. total de alarmas";

  //Graph Error message
  content["The data required for this graph is not currently available."] =
  "Los datos requeridos para esta gráfica no están disponibles actualmente.";

  //No results found
  content["No results found"] =
  "No se ha encontrado ningún resultado.";

  //No subgroups have been added
  content["No devices have been added"] =
  "No se ha agregado ningún dispositivo.";

  //No subgroups have been added
  content["No subgroups have been added"] =
  "No se ha agregado ningún subgrupo.";

  //No profiles in this group
  content["No profiles in this group"] =
  "No existe ningún perfil en este grupo.";

  //No devices have been added yet.
  content["No devices have been added yet."] =
  "No se ha agregado ningún dispositivo todavía.";

  // END OF LOCALIZATION

export default content;
