import {
  SET_CLIENT_ROBOTS,
  SET_ADE_ROBOT_ADDRESS,
  SET_ADE_JOB_IDS,
} from './actionTypes'

export function setClientRobots(robots) {
  return {
    type: SET_CLIENT_ROBOTS,
    robots: robots,
  }
}

export function setAdeRobotAddress(adeRobotAddress) {
  return {
    type: SET_ADE_ROBOT_ADDRESS,
    adeRobotAddress: adeRobotAddress,
  }
}

export function setAdeJobIds(adeJobIds) {
  return {
    type: SET_ADE_JOB_IDS,
    adeJobIds: adeJobIds,
  }
}