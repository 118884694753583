import * as actions from './actionTypes'
import pagerduty from './pagerduty'
import * as IntegrationError from './../../../components/settings/integrations/IntegrationError';

export function fetchConfig() {
  return {
    type:actions.FETCH_PAGERDUTY_CONFIG
  }
}

export function fetchConfigSuccess(json) {
  return {
    type:actions.FETCH_PAGERDUTY_CONFIG_SUCCESS,
    config:json
  }
}

export function fetchConfigFailure(error) {
  return {
    type:actions.FETCH_PAGERDUTY_CONFIG_FAILURE,
    responseError:error
  }
}

export function saveConfig() {
  return {
    type:actions.SAVE_PAGERDUTY_CONFIG
  }
}

export function saveConfigSuccess() {
  return {
    type:actions.SAVE_PAGERDUTY_CONFIG_SUCCESS
  }
}

export function saveConfigFailure(error) {
  return {
    type:actions.SAVE_PAGERDUTY_CONFIG_FAILURE,
    responseError:error
  }
}

// export function validateConfig() {
//   return {
//     type:actions.VALIDATE_PAGERDUTY_CONFIG
//   }
// }
//
// export function validateConfigSuccess(json) {
//   return {
//     type:actions.VALIDATE_PAGERDUTY_CONFIG_SUCCESS,
//     config:json
//   }
// }
//
// export function validateConfigFailure(error) {
//   return {
//     type:actions.VALIDATE_PAGERDUTY_CONFIG_FAILURE,
//     responseError:error
//   }
// }

export function deleteConfig() {
  return {
    type:actions.DELETE_PAGERDUTY_CONFIG
  }
}

export function deleteConfigSuccess(json) {
  return {
    type:actions.DELETE_PAGERDUTY_CONFIG_SUCCESS
  }
}

export function deleteConfigFailure(error) {
  return {
    type:actions.DELETE_PAGERDUTY_CONFIG_FAILURE,
    responseError:error
  }
}

function getResponseError(error) {
  if(error.response.data && error.response.data.message) {
    return error.response.data;
  } else {
    return IntegrationError.getDefaultError();
  }
}

export function get() {
  return dispatch => {
    dispatch(fetchConfig());
    return pagerduty.get()
                    .then((response) => {
                      dispatch(fetchConfigSuccess(response.data));
                    })
                    .catch(function(error) {
                      dispatch(fetchConfigFailure(getResponseError(error)))
                    })
  }
}

export function save(pagerDutyConfig, successCallback) {
  return dispatch => {
    dispatch(saveConfig());
    return pagerduty.save(pagerDutyConfig)
                    .then((response) => {
                      dispatch(saveConfigSuccess());
                      if(successCallback) {
                        successCallback();
                      }
                    })
                    .catch(function(error) {
                      dispatch(saveConfigFailure(getResponseError(error)));
                    })
  }
}

  // export function validate(pagerDutyConfig, successCallback, failureCallback) {
  //   return dispatch => {
  //     dispatch(validateConfig());
  //     return pagerduty.validate(pagerDutyConfig)
  //                     .then((response) => {
  //                       dispatch(validateConfigSuccess(response.data));
  //                       if(successCallback) {
  //                         successCallback();
  //                       }
  //                     })
  //                     .catch(function(error) {
  //                       dispatch(validateConfigFailure(error.response.data));
  //                       if(failureCallback) {
  //                         failureCallback();
  //                       }
  //                     })
  //   }
  // }

    export function deletePagerDutyConfig(pagerDutyConfig, successCallback, failureCallback) {
      return dispatch => {
        dispatch(deleteConfig());
        return pagerduty.deleteConfig(pagerDutyConfig)
                        .then((response) => {
                          dispatch(deleteConfigSuccess());
                          if(successCallback) {
                            successCallback();
                          }
                        })
                        .catch(function(error) {
                          dispatch(deleteConfigFailure(getResponseError(error)));
                          if(failureCallback) {
                            failureCallback();
                          }
                        })
      }
}
