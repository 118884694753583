import React from 'react'
import './icons.less'

const Icon = props => {
  return (
    <svg
      className={`icon ${props.className}`}
      role={props.role || 'figure'}
      aria-label={props['aria-label']?props['aria-label']:"[title]"}
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="20"
      viewBox="0 0 11 20"
      {...props}
    >
      <g>
        <title>{props.title}</title>
        <polygon
          fill="#000000"
          fillRule="evenodd"
          points="5.375 5 14.75 13.467 13.386 14.677 5.375 7.42 -2.636 14.677 -4 13.467"
          transform="rotate(90 5.375 9.839)"
        />
      </g>
    </svg>
  )
}

export default Icon
