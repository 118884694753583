import React from "react";
import RequestTrials from "./RequestTrials";
import "./trialbar.less";
import { getWalkMeVariables } from "./../../../utils/walkMeLoad";
import Resource from "./Resource";
import { Component } from "react";
import { loadCommon } from "./../../../utils/common";

const TrialBar = ({
  trialsExpiry,
  extnRequested,
  handleExtendTrials,
  handleBuy,
  featureFlags,
  saas,
}) => {
  if (!saas) {
    return null;
  }
  if (
    document.getElementsByClassName("operator-console__application").length > 0
  ) {
    document
      .getElementsByClassName("operator-console__application")[0]
      .classList.add("trial");
  }
  let walkMeVariables = getWalkMeVariables();
  let trialbarClass = "trialbar";
  let trialBarMessage = "";
  let daysRemaining;
  if (trialsExpiry) {
    daysRemaining = Math.floor(
      (trialsExpiry - Date.now()) / (60 * 60 * 24 * 1000)
    );
    walkMeVariables.daysLeft = daysRemaining;
    const showExpiringClass = daysRemaining < 6;
    if (showExpiringClass) {
      trialbarClass += " trialbar--expiring";
    }
    //Expired
    trialBarMessage =
      daysRemaining > 0
        ? `${daysRemaining} days remaining`
        : Resource.get("Expired");
  }

  return (
    <div className={trialbarClass}>
      <div className="trialbar-label-container">
        <span className="trialbar-trial">
          {
            //Trial
            Resource.get("Trial")
          }
        </span>

        <span>{trialBarMessage}</span>
      </div>
      <div className="trialbar-separator" />
      <div className="trial-bar-status">
        <RequestTrials
          extnRequested={extnRequested}
          daysRemaining={daysRemaining}
          handleExtendTrials={handleExtendTrials}
        />
        {featureFlags.buy ? (
          <span className={"trialbar__button--buy"} onClick={handleBuy}>
            {
              //BUY
              Resource.get("BUY")
            }
          </span>
        ) : null}
      </div>
    </div>
  );
};
const TRIALS = "TRIALS";
class TrialBarContainer extends Component {
  state = {
    extnRequested: false,
    products: [],
    trialsExpiry: null,
    accountType: TRIALS,
    showTrialBar: false,
  };
  componentWillMount() {
    loadCommon().then((ca_cloud) => {
      ca_cloud.getProducts().then((response) => {
        const productList = response.data.products;
        const extnRequested = productList.some(
          (product) => product.extnRequested === true
        );
        const cloudManagement = productList.find(
          (product) => product.name === "CLOUD_MANAGEMENT"
        );
        const uim = productList.find((product) => product.name === "UIM");
        if (!uim.accountType) {
          uim.accountType = TRIALS;
        }
        let buyUrl;
        if (
          cloudManagement.axaTokenRedirectUrl.endsWith("#/") ||
          cloudManagement.axaTokenRedirectUrl.endsWith("#")
        ) {
          buyUrl = cloudManagement.axaTokenRedirectUrl + "/purchase/UIM";
        } else {
          buyUrl = cloudManagement.axaTokenRedirectUrl + "/#/purchase/UIM";
        }
        this.setState({
          extnRequested: extnRequested,
          products: productList,
          trialsExpiry: response.data.trialsExpiryInMillis,
          buyUrl: buyUrl,
          showTrialBar: uim.accountType === TRIALS ? true : false,
        });
      });
    });
  }
  handleExtendTrials = () => {
    loadCommon().then((ca_cloud) => {
      ca_cloud.extendTrials();
    });
    this.setState({ extnRequested: true });
    this.props.handleExtendTrials();
  };
  handleBuy = () => {
    if (this.state.buyUrl) {
      window.location.href = this.state.buyUrl;
    } else {
      console.error("The buy url is not defined correctly");
    }
  };
  render() {
    return this.state.showTrialBar ? (
      <TrialBar
        trialsExpiry={this.state.trialsExpiry}
        extnRequested={this.state.extnRequested}
        handleExtendTrials={this.handleExtendTrials}
        handleBuy={this.handleBuy}
        featureFlags={this.props.featureFlags || {}}
        saas={this.props.saas}
      />
    ) : null;
  }
}
export default TrialBarContainer;
