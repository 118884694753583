import { all } from 'redux-saga/effects'
import {
  fetchGroupSetEntitiesSaga,
  groupsPollingSaga,
  topSubGroupsRequestSaga,
  topSubGroupsPollingSaga
} from './api/groups/groupsSaga'
import { hubsRequestSaga, hubsPollingSaga } from './api/hubs/hubsSaga'
import { 
  openAlarmsRequestSaga, 
  openAlarmsPollingSaga,
  activeDevicesRequestSaga, 
  activeDevicesPollingSaga,
  roleDevicesRequestSaga,
  roleDevicesPollingSaga,
  topTechnologiesRequestSaga,
  topTechnologiesPollingSaga,
  topGroupsRequestSaga,
  topGroupsPollingSaga,
  topDevicesRequestSaga,
  topDevicesPollingSaga  

} from './api/home-screen/homeScreenSaga'
import { getWmiSaga,wmiPollingSaga,getsnmpSaga,snmpPollingSaga } from './api/DeviceDiscovery/credentials/catalogSaga'
import {getWmiSagaDetails} from './api/groupTrees/catalogSaga'
import { getUnixSaga,unixPollingSaga } from './api/DeviceDiscovery/unixCredentials/catalogUnixSaga'
import { getScopesSaga,scopesPollingSaga } from './api/DeviceDiscovery/scopes/catalogScopesSaga'
import { fetchReportFolderSaga } from './api/reports/reportsSaga'
import { watchFetchAccount, watchFetchAxaAccount } from './api/account/saga'
import { probesRequestSaga, probesPollingSaga } from './api/setup-wizard/setupWizardSaga'
import {agentsRequestSaga, agentsPollingSaga, agentsStatusRequestSaga, agentsStatusPollingSaga, jobsCountRequestSaga, jobsCountPollingSaga} from './api/DeviceDiscovery/agents/agentsSaga'
import {
  groupRequestSaga,
  profileRequestSaga,
  deviceRequestSaga,
  deviceByDeviceIdRequestSaga,
 

} from './api/mcsDashboard/groups/groupsSaga'
import {devicesRequestSaga,deviceProfilesRequestSaga,deviceProfilesByProfileIdRequestSaga} from './api/mcsDashboard/devices/devicesSaga'
import {getMaintenanceSaga,maintenancePollingSaga} from './api/maintenance/maintenanceSaga'
import { metricDeviceChartRequestSaga, metricDeviceChartPollingSaga } from './api/dashboardMetrics/dashboardMetricSaga'
export default function* rootSaga() {
  yield all([
    topSubGroupsRequestSaga(),
    topSubGroupsPollingSaga(),  
    activeDevicesRequestSaga(), 
    activeDevicesPollingSaga(),
    roleDevicesRequestSaga(),
    roleDevicesPollingSaga(),
    topTechnologiesRequestSaga(),
    topTechnologiesPollingSaga(),
    topGroupsRequestSaga(),
    topGroupsPollingSaga(),
    topDevicesRequestSaga(),
    topDevicesPollingSaga (),
    openAlarmsPollingSaga(),
    openAlarmsRequestSaga(),
    fetchGroupSetEntitiesSaga(),
    groupsPollingSaga(),
    hubsRequestSaga(),
    hubsPollingSaga(),
    fetchReportFolderSaga(),
    watchFetchAccount(),
    watchFetchAxaAccount(),
    probesRequestSaga(),
    probesPollingSaga(),
    getWmiSaga(),
    wmiPollingSaga(),
    getUnixSaga(),
    unixPollingSaga(),
    agentsRequestSaga(),
    agentsPollingSaga(),
    agentsStatusRequestSaga(),
    agentsStatusPollingSaga(),
    getScopesSaga(),
    scopesPollingSaga(),
    jobsCountPollingSaga(),
    jobsCountRequestSaga(),
    groupRequestSaga(),
    profileRequestSaga(),
    deviceRequestSaga(),   
    devicesRequestSaga(),
    deviceProfilesRequestSaga(),  
    deviceByDeviceIdRequestSaga(),
    deviceProfilesByProfileIdRequestSaga(),
    getWmiSagaDetails(),
    getsnmpSaga(),
    snmpPollingSaga(),
    getMaintenanceSaga(),
    maintenancePollingSaga(),
    metricDeviceChartRequestSaga(),
    metricDeviceChartPollingSaga(),
    
  ])
}
