import * as React from 'react';
import { SvgIcon } from '@mineral/core';

export const FilledCircle = React.forwardRef(
  (props, ref) => {

    return (
      <SvgIcon data-testid="LearnIcon" viewBox="0 0 40 40" {...props} ref={ref}>
        <rect x="4" y="4" width={props.width} height={props.height} rx={props.height * 2} fill={props.color} />
      </SvgIcon>
    )
  }
);

