import connect from "./../../utils/connect";
import { setQuickFilterEntities } from "./../entities/actions";
import PrimaryNav from "./PrimaryNav";
import {
  getGroups,
  getStateData,
  getCountData,
} from "./../../api/treeView/actions";

const mapStateToProps = (state) => {
  return {
    featureFlags: state.configuration.items,
    saas: state.saas,
    acl: state.acl,
    doiurl: state.user.doiurl,
    ocviews: state.ocviews,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleRedux: () => {
      dispatch(setQuickFilterEntities(""));
    },
    fetchGroups: (level, isPoller) => dispatch(getGroups(level, isPoller)),
    fetchStates: (level, isPoller) => dispatch(getStateData(level, isPoller)),
    fetchCount: (level, isPoller) => dispatch(getCountData(level, isPoller)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PrimaryNav);
