// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //FIX ISSUES
  content["FIX ISSUES"] =
  "SOLUCIONAR INCIDENCIAS";

  //device
  content["device"] =
  "dispositivo";

  //devices
  content["devices"] =
  "dispositivos";

  //LasrDeployment message
  content["Last Deployment"] =
  "Última implementación";

  //pending
  content["pending"] =
  "pendientes";

  //succeeded
  content["succeeded"] =
  "correctos";

  // failed
  content["failed"] =
  "erróneos";

  //Setup Infrastructure Monitoring
  content["Setup Infrastructure Monitoring"] =
  "Configuración de la monitorización de la infraestructura";

  //For more information, click
  content["For more information, click"] =
  "Para obtener más información, haga clic en";

  //Setup Wizard
  content["Setup Wizard"] =
  "Asistente de instalación";

  //Remote And Cloud
  content["Remote And Cloud"] =
  "Remotos y en la nube";

  //Local Monitoring
  content["Local Monitoring"] =
  "Monitorización local";

  //You can configure additional monitoring in context of existing devices and groups.
  content["You can configure additional monitoring in context of existing devices and groups."] =
  "Se puede configurar la monitorización adicional en el contexto de dispositivos y grupos existentes.";

  //Read more
  content["Read more"] =
  "Leer más";

  // help text
  content["Don\'t show this screen by default"] =
  "No mostrar esta pantalla de forma predeterminada";

  //ADD
  content["ADD"] =
  "AGREGAR";

  //CONFIG
  content["CONFIG"] =
  "CONFIGURAR";

  //Show All
  content["Show All"] =
  "Mostrar todo";

  //Show Less
  content["Show Less"] =
  "Mostrar menos";

  //Technology
  content["Technology"] =
  "Tecnología";

  //Technologies
  content["Technologies"] =
  "Tecnologías";

  //Cancel
  content["Cancel"] =
  "Cancelar";

  //PREVIOUS
  content["PREVIOUS"] =
  "ANTERIOR";

  //next
  content["next"] =
  "siguiente";

  //FINISH
  content["FINISH"] =
  "FINALIZAR";

  //Presenting the All New Setup Wizard
  content["Presenting the All New Setup Wizard"] =
  "Nuevo Asistente para la instalación";

  //Towards improving your experience with managing and monitoring your infrastructure, we are presenting this new enhanced interface that meets your needs better.
  content["Towards improving your experience with managing and monitoring your infrastructure, we are presenting this new enhanced interface that meets your needs better."] =
  "Se ha optimizado la interfaz con el fin de mejorar su experiencia con la gestión y monitorización de la infraestructura y ajustarnos mejor a sus necesidades.";

  //See what's changed
  content["See what\'s changed"] =
  "Novedades";

  //Discover Devices
  content["Discover Devices"] =
  "Detección de los dispositivos";

  //Welcome to Setup Wizard
  content["Welcome to Setup Wizard"] =
  "Bienvenido al Asistente para la instalación";

  //Your home to setup and configure monitoring infrastructure
  content["Your home to setup and configure monitoring infrastructure"] =
  "La página principal para la instalación y configuración de la infraestructura de monitorización";

  // END OF LOCALIZATION

export default content;
