// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //App Experience Analytics
  content["App Experience Analytics"] =
  "App Experience Analytics";

  //Infrastructure Management
  content["Infrastructure Management"] =
  "Infrastructure Management";

  //Application Performance Management
  content["Application Performance Management"] =
  "Application Performance Management";

  //Digital Operational Intelligence
  content["Digital Operational Intelligence"] =
  "Digital Operational Intelligence";

  //Launch Pad
  content["Launch Pad"] =
  "Launch Pad";

  //Single Sign on Dashboards
  content["Single Sign on Dashboards"] =
  "Single Sign on Dashboards";

  //Single Sign on Dashboards
  content["Network Operations Analytics"] =
  "Network Operations Analytics";

  // END OF LOCALIZATION

export default content;
