import React from 'react'
import {Route} from 'react-router-dom'
import { CenterError as Error } from '../components/ui-components/uim-components'

const Inaccessible = () => {
  return (
    <main>
      <Error style={{height: 'calc(100vh - 205px)'}}
        title='Inaccessible'
        message='You do not have enough permissions to view this page.'/>
    </main>
  )
}

const InaccessibleRoute = () => {
  return (
    <Route path='/noaccess' component={Inaccessible}/>
  )
}

export default InaccessibleRoute