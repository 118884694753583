import { combineReducers } from "redux";

import metrics from "./api/metrics/reducer";
import types from "./api/metricTypes/reducer";
import deviceMetrics from "./api/deviceMetrics/reducer";
import columns from "./components/columns/reducer";
import timerange from "./components/timerange/reducer";
import selection from "./components/selection/reducer";

const reducers = combineReducers({
  metrics,
  types,
  columns,
  timerange,
  selection,
  deviceMetrics,
});

export default reducers;
