import React from 'react'
import {
  Card,
  CardContent,
  CardMedia
} from '@mineral/core'
import { Link } from 'react-router-dom'
// import image from './../../assets/images/reports_folder.png'
import icon from './../../assets/images/reports_folder_icon.png'
import MessageFormat from 'message-format'
import FolderImage from '../icons/FolderImage'
import Resource from './Resource'

const FolderCard = (props) => {
  const {uri, label, description,reportCount} = props
  const maxDescriptionLength = 82;
  // Trim description to just 3 lines
  const trimedDescription = description.length < maxDescriptionLength ? description : description.substring(0,maxDescriptionLength-3) + '...'

  // Uppercase first character of label
  const titleLabel = label.charAt(0).toUpperCase() + label.slice(1)

  const route = `/reports${uri.replace('/public/ca/uim/reports/library', '')}`

  const cardStyle = {
    height: '15.625rem',
    width: '18.75rem',
    marginBottom: '1rem',
    marginRight: '1rem'
  }

  const cardTextStyle = {
    display: 'flex'
  }

  const folderTitleStyle = {
    fontSize: '1.25rem',
    paddingBottom: '.4rem'
  }

  const folderDescriptionStyle = {
    fontSize: '.875rem',
    color: 'rgba(0,0,0,0.54)',
    height: '4rem',
    lineHeight: '1.25rem'
  }

  const reportCountStyle = {
    fontSize: '1rem',
    paddingTop: '.5rem'
  }

  const iconStyle = {
    height: '1.25rem',
    width: '1.5rem',
    paddingRight: '1rem'
  }
  //reports message
  let message = Resource.get('{0} reports')
  let result = new MessageFormat(message).format({0:reportCount})

  return (
    <Link to={route} style={{ textDecoration: 'none' }} >
      <Card style={cardStyle} id={uri} >
        <CardMedia>
          <FolderImage/>
        </CardMedia>
        <CardContent style={cardTextStyle}>
          <div>
            <img style={iconStyle} src={icon} role='figure' />
          </div>

          <div>
            <div style={folderTitleStyle}>
              {titleLabel}
            </div>
            <div style={folderDescriptionStyle}>{trimedDescription}</div>

            <div style={reportCountStyle}>{result}</div>
          </div>


        </CardContent>
      </Card>
    </Link>
  )
}

export default FolderCard