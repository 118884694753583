import React from "react";
import axios from "axios";

class FooterName extends React.Component {
  state = {
    fName: "",
  };
  componentDidMount() {
    const url =
      process.env.NODE_ENV == "development"
        ? "http://10.252.25.208:8080/castlemock/mock/rest/project/TK8t3o/application/UXxhjW/footerBar"
        : "/operatorconsole_portlet/api/v1/whiteLabel/footerBar";
    axios.get(url).then((res) => {
      const footerName = res.data.footer_bar;
      this.setState({ fName: footerName });
    });
  }
  render() {
    let currentYear = new Date().getFullYear();
    let copyRightText =
      " Broadcom. All Rights Reserved. The term 'Broadcom' refers to Broadcom Inc. and/or its subsidiaries.";

    var fbar = this.state.fName;
    if (fbar == "" || fbar == null) {
      //All rights reserved.
      // return (<div>&copy;{currentYear} CA.  {Resource.get('All rights reserved.')}</div>)
      return (
        <div>
          &copy;{`${currentYear} ${copyRightText}`}
        </div>
      );
    } else {
      let bar = fbar.replace("\\u00A9", "\u00A9");
      return <p1>{bar}</p1>;
    }
  }
}

export default FooterName;
