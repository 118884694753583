// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Extension message
  content["Extension requested"] =
  "延長がリクエストされました";

  //Request message
  content["Request extension"] =
  "延長のリクエスト";

  //Expired
  content["Expired"] =
  "期限切れ";

  //Trial
  content["Trial"] =
  "トライアル";

  //BUY
  content["BUY"] =
  "購入";

  // END OF LOCALIZATION

export default content;
