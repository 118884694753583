// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Settings
  content["Settings"] =
  "設定";

  //Restmon
  content["Restmon"] =
  "Restmon";

  //Create a custom probe for monitoring via REST APIs
  content["Create a custom probe for monitoring via REST APIs"] =
  "REST API を使用してモニタリングを行うカスタム プローブを作成します";

  //RESTMon
  content["RESTMon"] =
  "RESTMon";

  //Create custom RESTful API Monitoring
  content["Create custom RESTful API Monitoring"] =
  "カスタム RESTful API モニタリングの作成";

  // END OF LOCALIZATION

export default content;
