import connect from '../../utils/connect'
import MaintenanceClass from './Maintenance'
import {getMaintenance,pollMaintenanceStart,pollMaintenanceStop,getDevicesBySchedule,getDevicesByGroup}  from '../../api/maintenance/actions';

const mapStateToProps = state => {
  return {
    maintenanceItems: state.maintenance.items,
    scheduleDevices:state.maintenance.devices,
    groupDevices:state.maintenance.groupDevices,
    canEditMaintMode : state.acl.canEditMaintMode,
    canEditMaintModeDevice : state.acl.canEditMaintModeDevice,
    canViewMaintenanceMode : state.acl.canViewMaintenanceMode,
    selectedGroupId: state.entity.id,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getMaintenance: () => dispatch(getMaintenance()),
    getDevicesBySchedule: (scheduleId) => dispatch(getDevicesBySchedule(scheduleId)),
    getDevicesByGroup: (groupId) => dispatch(getDevicesByGroup(groupId)),
    pollMaintenanceStart : () =>dispatch(pollMaintenanceStart()),
    pollMaintenanceStop : () =>dispatch(pollMaintenanceStop()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MaintenanceClass)
