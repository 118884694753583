import React, { Component } from "react";
import TreeListItem from "./TreeListItem";
import "./tree.less";

const CONTAINER = "CONTAINER";

class GroupTree extends Component {
  state = {
    selectedId: this.props.selectedId,
    groups: this.props.children,
    isEditMode: false,
    currGroup: this.props.currGroup,
  };

  componentWillReceiveProps(nextProps) {
    this.setState({
      selectedId: nextProps.selectedId,
      groups: nextProps.children,
      isEditMode: nextProps.isEditMode,
      currGroup: nextProps.currGroup,
    });
  }
  createTree = (groups) => {
    if (!Array.isArray(groups)) return null;
    const displayedGroups = groups.filter(
      (group) => parseInt(group.parentId, 10) === -1
    );
    let sortedGroups = this.sortGroups(displayedGroups);
    if (this.props.sortOrder === "desc") {
      sortedGroups.reverse();
    }
    return sortedGroups.map(this.getGroupTree);
  };
  getGroupTree = (grp, index) => {
    if (grp.type === "CONTAINER") {
      // if(this.state.isEditMode && (grp.id==this.state.currGroup.id)){
      //   return null
      // }else{
      return this.listItemFactory(grp, index);
      // }
    }
  };

  listItemFactory = (group, index) => {
    const children = this.getGroupChildren(group);
    if (!Array.isArray(children)) {
      return null;
    }
    for (let i = 0; i < children.length; i++) {
      children[i].path = group.path + "/" + group.id;
    }
    const sortedGroups = this.sortGroups(children);
    if (this.props.sortOrder === "desc") {
      sortedGroups.reverse();
    }
    const radioButtons = sortedGroups.map(this.getGroupTree);
    return (
      <TreeListItem
        isEditMode={this.state.isEditMode}
        key={index || 0}
        group={group}
        count={children.length}
        onChange={this.handleRadioChange}
        currGroup={this.props.currGroup}
      >
        {radioButtons}
      </TreeListItem>
    );
  };
  getGroupChildren = (currentGroup) => {
    return this.state.groups.filter(
      (group) => group.parentId === currentGroup.id
    );
  };

  render() {
    this.handleRadioChange = this.props.handleRadioChange;
    const tree = this.createTree(this.state.groups);
    return <ul style={{ padding: "0px", margin: "0px" }}>{tree}</ul>;
  }
  sortByName = (group1, group2) => {
    const a = (group1.name || "").toLowerCase();
    const b = (group2.name || "").toLowerCase();
    if (a < b) {
      return -1;
    } else if (a > b) {
      return 1;
    } else {
      return 0;
    }
  };
  sortGroups = (groups) => {
    return groups.sort((group1, group2) => {
      if (group1.type === CONTAINER && group2.type !== CONTAINER) {
        return -1;
      } else if (group1.type !== CONTAINER && group2.type === CONTAINER) {
        return 1;
      } else {
        return this.sortByName(group1, group2);
      }
    });
  };
}
export default GroupTree;
