import {
  SET_CLIENT_ROBOTS,
  SET_ADE_ROBOT_ADDRESS,
  SET_ADE_JOB_IDS,
} from './actionTypes'

const clientRobots = (state = {
  adeRobotAddress: null,
  adeJobIds: [],
  robots: [],
}, action) => {
  switch (action.type) {
    case SET_CLIENT_ROBOTS:
      return Object.assign({}, state, {
        robots: action.robots,
      });
    case SET_ADE_ROBOT_ADDRESS:
      return Object.assign({}, state, {
        adeRobotAddress: action.adeRobotAddress,
      });
    case SET_ADE_JOB_IDS:
      return Object.assign({}, state, {
        adeJobIds: action.adeJobIds,
      });
    default:
      return state
  }
}
export default clientRobots
