// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Dashboards
  content["Dashboards"] =
  "Cuadros de mandos";

  //title message
  content["Monitoring Configuring Service"] =
  "Servicio de configuración de la monitorización";

  //Search for Dashboard Path
  content["Search for Dashboard Path"] =
  "Buscar por ruta del cuadro de mandos";

  //title message
  content["Infrastructure Mgmt Overview"] =
  "Descripción general de Infrastructure Management";

  // END OF LOCALIZATION

export default content;
