// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Home
  content["Home"] =
  "Casa";

  //Alarms
  content["Alarms"] =
  "Alarmas";

  //Groups
  content["Groups"] =
  "Grupos";

  //Inventory
  content["Inventory"] =
  "Inventario";

  //Dashboards
  content["Dashboards"] =
  "Cuadros de mandos";

  //Reports
  content["Reports"] =
  "Informes";

  //Settings
  content["Settings"] =
  "Configuración";

  //Setup Wizard
  content["Setup Wizard"] =
  "Asistente de instalación";

  //Community
  content["Community"] =
  "Comunidad";

  //Help
  content["Help"] =
  "Ayuda";

  // END OF LOCALIZATION

export default content;
