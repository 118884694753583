import connect from "./../../utils/connect";
import AddDeviceToGroupDialog from "./AddDeviceToGroupDialog";
import {
  addDeviceToGroup,
  addInterfacesToGroup,
} from "../../api/groups/actions";
import { fetchComputerSystemsForAdd } from "./../../api/computer-systems/actions";
import differenceBy from "lodash/differenceBy";
import { addEntities } from "./../entities/actions";
import { fetchInterfacesToAdd } from "./../../api/interfaces/actions";
import { refreshTree } from "./../../api/treeView/actions";
const mapDispatchToProps = (dispatch) => {
  return {
    addToGroup: (
      groupId,
      deviceList,
      entities,
      openError,
      closeDialog,
      isInterfaceGroup
    ) => {
      let newListOfEntites = differenceBy(deviceList, entities, "id");
      if (newListOfEntites.length > 0) {
        if (isInterfaceGroup) {
          dispatch(addInterfacesToGroup(groupId, deviceList))
            .then((response) => {
              if (response.type === "ADD_INTERFACES_TO_GROUP_SUCCESS") {
                dispatch(addEntities(newListOfEntites));
                closeDialog("addDeviceToGroupOpen");
              } else {
                closeDialog("addDeviceToGroupOpen");
                openError("addDevices");
              }
            })
            .catch((error) => {
              closeDialog("addDeviceToGroupOpen");
              openError("addDevices");
            });
        } else {
          dispatch(addDeviceToGroup(groupId, deviceList))
            .then((response) => {
              if (response.type === "ADD_TO_GROUP_SUCCESS") {
                dispatch(addEntities(newListOfEntites));
                closeDialog("addDeviceToGroupOpen");
              } else {
                closeDialog("addDeviceToGroupOpen");
                openError("addDevices");
              }
            })
            .catch((error) => {
              closeDialog("addDeviceToGroupOpen");
              openError("addDevices");
            });
        }
      } else {
        closeDialog("addDeviceToGroupOpen");
      }
    },
    getAllComputerSystems: () => dispatch(fetchComputerSystemsForAdd()),
    getAllInterfaces: (filter) => dispatch(fetchInterfacesToAdd(filter)),
    refreshTree: () => dispatch(refreshTree(true)),
  };
};
const mapStateToProps = (state) => {
  return {
    didInvalidate: state.computerSystems.didInvalidate,
    isFetching: state.computerSystems.isFetching,
    isInterfaceGroup:
      state.tree_view.treeNode.isInterfaceGroup ||
      state.entity.masterElementGroup,
    groupId: state.tree_view.groupId,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddDeviceToGroupDialog);
