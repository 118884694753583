import React, { Component } from "react";
import {
  CenterError as Error,
  FloatingActionButton,
  ErrorIcon as Information,
  PlusIcon as ContentAdd,
} from "../ui-components/uim-components";
import {CircularProgress} from "@mineral/core";
import Table from "./Table";
import "../cards/cards.less";
import "./table.less";
import DynamicGroupDialog from "./../titlebar/dynamicGroup/DynamicGroupDialog";
import ErrorDialog from "./../titlebar/ErrorDialog";
import Resource from "./Resource";
import get from "lodash/get";
class TableManager extends Component {
  state = {
    showAddSubgroupDialog: false,
    showAddDevicesDialog: false,
    showErrorForAddDevices: false,
    showErrorForAddGroups: false,
    editSubGroupOpen: false,
    openDynamicGroupDialog: false,
    savedColumns: [],
    // openExportTypeDialog: false,
    // openEditColumnDailog: false,
  };
  componentWillMount() {
    /* computerSystemsApi.getSavedColumns()
    .then((response)=>{
      let columns = response.data.columns
      this.setState({ savedColumns: columns && columns.length>0?columns:[]});
    })  */
    this.setState({
      savedColumns:
        this.props.savedColsListView && this.props.savedColsListView.length > 0
          ? this.props.savedColsListView
          : []
    });
  }
  componentWillReceiveProps(nextProps) {
    /* this.forceUpdate();
  computerSystemsApi.getSavedColumns()
    .then((response)=>{
      let columns = response.data.columns
      this.setState({ savedColumns: columns && columns.length>0?columns:[]});
    }) */
    this.setState({
      savedColumns:
        nextProps.savedColsListView && nextProps.savedColsListView.length > 0
          ? nextProps.savedColsListView
          : []
    });
    // if (
    //   JSON.stringify(this.props.quickFilter) !== JSON.stringify(nextProps.quickFilter) &&
    //   nextProps.rowModelType === "infinite"
    // ) {
    //   this.props.setQuickFilter();
    // }
  }

  handleAddSubgroup = () => {
    this.setState({
      showAddSubgroupDialog: true,
      showAddDevicesDialog: false,
      showErrorForAddDevices: false,
      showErrorForAddGroups: false,
      editSubGroupOpen: false,
      openDynamicGroupDialog: false,
    });
  };
  handleAddDevices = () => {
    this.setState({
      showAddDevicesDialog: true,
      showAddSubgroupDialog: false,
      showErrorForAddDevices: false,
      showErrorForAddGroups: false,
      editSubGroupOpen: false,
      openDynamicGroupDialog: false,
    });
  };
  handleAddCriteria = () => {
    this.setState({
      editSubGroupOpen: true,
      showAddDevicesDialog: true,
      showAddSubgroupDialog: false,
      showErrorForAddDevices: false,
      showErrorForAddGroups: false,
      openDynamicGroupDialog: false,
    });
  };

  handleClose = (dialogName) => {
    if (dialogName === "dynamicGroupsOpen") {
      this.setState({ openDynamicGroupDialog: false, dynamicGroupInfo: null });

      if (this.state.editSubGroupOpen) {
        this.setState({
          editSubGroupOpen: false,
        });
      }
      if (this.state.showAddSubgroupDialog) {
        this.setState({
          showAddSubgroupDialog: false,
        });
      }
      if (this.state.showAddDevicesDialog) {
        this.setState({
          showAddDevicesDialog: false,
        });
      }
    } else if (dialogName === "subGroupOpen") {
      if (this.state.showAddSubgroupDialog) {
        this.setState({
          showAddSubgroupDialog: false,
        });
      }
    }
  };

  handleErrorClose = () => {
    if (this.state.showErrorForAddDevices) {
      this.setState({
        showErrorForAddDevices: false,
      });
    } else if (this.state.showErrorForAddGroups) {
      this.setState({
        showErrorForAddGroups: false,
      });
    }
  };
  render() {
    let {
      computerSystemsIsFetching,
      computerSystemsDidInvalidate,
      groupsIsFetching,
      groupsDidInvalidate,
      entityType,
      entities,
      data,
    } = this.props;
    let view;

    if (groupsIsFetching && !this.state.showAddDevicesDialog) {
      view = (
        <div
          style={{
            height: "calc(100vh - 205px)",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress style={{ color: "#3272D9" }} />
        </div>
      );
    } else if (groupsDidInvalidate || computerSystemsDidInvalidate) {
      view = <Error style={{ height: "calc(100vh - 205px)" }} />;
    } else if (
      this.state.editSubGroupOpen ||
      this.state.openDynamicGroupDialog ||
      this.state.showAddSubgroupDialog ||
      this.state.showAddDevicesDialog
    ) {
      let dynamicDialogMode = "ADD Group";
      if (this.state.showAddSubgroupDialog) {
        dynamicDialogMode = "ADD Group";
      } else if (this.state.openDynamicGroupDialog) {
        dynamicDialogMode = "ADD";
      } else {
        dynamicDialogMode = "EDIT";
      }
      view = (
        <DynamicGroupDialog
          open={true}
          mode={dynamicDialogMode}
          handleClose={this.handleClose}
          handleErrorDialogOpen={this.handleErrorClose}
          {...this.props}
        />
      );
    } else if (this.state.showErrorForAddDevices) {
      view = (
        <ErrorDialog
          open={true}
          handleClose={this.handleErrorClose}
          errorType="addDevices"
        />
      );
    } else if (this.state.showErrorForAddGroups) {
      view = (
        <ErrorDialog
          open={true}
          handleClose={this.handleErrorClose}
          errorType="addGroup"
        />
      );
    } else {
      let noElsMsg = "";
      //Edit group Text
      let editGroupText = Resource.get("Edit Group");
      let clickHandler;
      if (entityType === "STATIC") {
        //No devices have been added
        noElsMsg = Resource.get("No devices have been added");
        clickHandler = this.handleAddDevices;
      } else if (entityType === "DYNAMIC") {
        //No devices have been added
        noElsMsg = Resource.get("No devices have been added");
        clickHandler = this.handleAddCriteria;
      } else {
        //No subgroups have been added
        noElsMsg = Resource.get("No subgroups have been added");
        clickHandler = this.handleAddCriteria;
      }

      // let message = Resource.get("No devices found");
      let message = "No devices found";
      let canModifyGroup = get(this.props.acl.aclInfo, "roles", "").includes(
        "USM Group Modification"
      );
      let canAddGroup = get(this.props.acl.aclInfo, "roles", "").includes(
        "OC Group Add"
      );
      view = this.props.inventorySearch ? (
        <>
          <Table {...this.props} savedColsList={this.state.savedColumns} />
          {this.props.entityType === "CONTAINER" &&
          (canModifyGroup || canAddGroup) ? (
            <FloatingActionButton
              onClick={this.handleAddSubgroup}
              style={{
                position: "fixed",
                bottom: "1rem",
                right: "1rem",
                zIndex: 2,
                marginBottom: "80px",
                backgroundColor: "rgb(0, 174, 239)",
                color: "white",
              }}
            >
              <ContentAdd />
            </FloatingActionButton>
          ) : (
            ""
          )}
          {(!entities || entities.length === 0) && !this.props.refreshGridData && (
            <div className="no-profile-message">
              <Information
                //No profiles in  this group
                title={Resource.get("No profiles in this group")}
                className="no-profiles__infomation--icon"
              />
              <p className="no-profiles__infomation--message">{message}</p>
            </div>
          )}
         
        </>
      ) : (
        <div style={{height:'calc( 100% - 12px )'}}>
          <Table {...this.props} savedColsList={this.state.savedColumns} />
        </div>
      );
    }
    return view;
  }
}

export default TableManager;
