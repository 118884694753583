// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Select what you would like to monitor...
  content["Select what you would like to monitor..."] =
  "Selecione o que deseja monitorar...";

  //For more information, click
  content["For more information, click"] =
  "Para obter mais informações, clique em";

  

  //Setup Wizard
  content["Setup Wizard"] =
  "Assistente de instalação";

  //HELP
  content["HELP"] =
  "AJUDA";

  //Help
  content["Help"] =
  "Ajuda";

  //ADD
  content["ADD"] =
  "ADICIONAR";

  //CONFIG
  content["CONFIG"] =
  "CONFIGURAÇÃO";

  //Cancel
  content["Cancel"] =
  "Cancelar";

  //PREVIOUS
  content["PREVIOUS"] =
  "VOLTAR";

  //next
  content["next"] =
  "seguinte";

  //FINISH
  content["FINISH"] =
  "Concluir";

  // END OF LOCALIZATION

export default content;
