import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import connect from "./../../../../utils/connect";
import { setBreadCrumbs } from "./../../../breadcrumbs/actions";
import "./ecometer.less";
import {CircularProgress} from '@mineral/core'
import ReactFrame from "./../ReactFrame";
import Resource from "./Resource";

class EcometerAdmin extends Component {
  state = {
    sid: null,
  };

  componentWillMount = () => {
    
        this.setState({ sid:this.props.sid });
      
  };

  componentDidMount() {
    this.props.setBreadCrumbs([
      {
        //Settings
        name: Resource.get("Settings"),
        link: "/settings",
      },
      {
        //Ecometer Admin
        name: Resource.get("Ecometer Admin"),
        link: "/settings/dcimadmin",
      },
    ]);
  }

  render() {
    let view = (
      <div className="spinner" style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><CircularProgress style={{color: '#3272D9'}} /></div>

    );

    if (this.state.sid) {
        let do_heartbeat = window.OPERATOR_CONSOLE.do_heartbeat
        if (do_heartbeat !== 'true' && do_heartbeat !== 'false') {
          do_heartbeat = false
        }
          view = (
            <ReactFrame
              url="dcimadmin"
              keepalive={do_heartbeat}
              sid={this.state.sid}
              portletId="dcimadmin"
              method="GET"
            />
          );
        }
        return view;
    };
}

const mapDispatchToProps = (dispatch) => {
  return {
    setBreadCrumbs: (items) => dispatch(setBreadCrumbs(items)),
  };
};

const mapStateToProps = (state) => {
  return {
    saas: state.saas,
    sid: state.sid,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EcometerAdmin));
