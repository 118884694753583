import axios from 'axios'
import config from './../api/config'
import { setHeartbeat } from './../api/heartbeat/actions'
class Heartbeat {
  constructor(dispatch) {
    this.pollInterval = 15000
    this.dispatch = dispatch
    // 3 minutes
    this.failTimeout = 3 * 60 * 1000
    this.failCount = 0
  }
  ping() {
    return axios.get(`${config.basename}/api/v1/session/keep-alive`);
  }

  start() {
    if (process.env.NODE_ENV === 'development') return
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      this.ping()
        .then(response=> {
          this.failCount = 0
          this.dispatch(setHeartbeat(true))
          this.pollInterval = 15000
          this.start()
        })
        .catch(err => {
          this.failCount += 1
          this.dispatch(setHeartbeat(false))
          clearTimeout(this.timeout)
          this.pollInterval = 5000
          if (this.failCount * this.pollInterval < this.failTimeout) {
            this.start()
          }
        })
    }, this.pollInterval)
  }
}

export default Heartbeat
