import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { Tooltip ,Dialog,DialogTitle,DialogContent,DialogActions,IconButton,Button,Typography, CircularProgress} from "@mineral/core";

import CloseIcon from "@material-ui/icons/Close";
import { AddChartFilter } from "../../../../../../../api/performanceReports/actions";
import prd from "../../../../../../../api/performanceReports/prd";
import "./AdvancedFilterDialog.less";
import AdvancedFilterDialogContent from "./AdvancedFilterDialogContent";
import { List, ListItem, ListItemText } from "@mineral/core";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitleStyled = withStyles(styles)((props) => {
  const { children, classes, id ,onClose,  ...other } = props;
  return (
    <DialogTitle 
      style={{ display: "flex", justifyContent: "space-between" }}
      disableTypography
      className={classes.root}
      {...other}
    >
      
        {children}
     
    
    </DialogTitle>
  );
});

const DialogContentStyled = withStyles((theme) => ({
  root: {},
}))(DialogContent);

const DialogActionsStyled = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(DialogActions);

const AdvancedFilterDialog = (props) => {
  const [open, setOpen] = React.useState(false);
  const [name, setName] = React.useState("");
  const [nameError, setNameError] = React.useState(true);
  const [displayNameList, setDisplayNameList] = React.useState([]);
  const [clickVerifyFilter, setClickVerifyFilter] = React.useState(false);
  const [tooManyRecordsError, setTooManyRecordsError] = React.useState(false);
  const [disableSave, setDisableSave] = React.useState(true);
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [loading, setLoading] = React.useState(false)
  const dispatch = useDispatch();
  const inputItemGroup = [
    {
      number: 1,
      logical: "and",
      field: "Source",
      not: false,
      operator: "contains",
      value: "",
    },
  ];
  const [inputItemGroupList, setInputItemGroupList] =
    React.useState(inputItemGroup);
  const handleClickOpen = () => {
    setOpen(true);
    setDisableSave(true);
  };
  const handlVerifyFilter = () => {
    (async () => {
      let filterId = "f" + Date.now();
      let requestBody = [];
      let apply = true;
      inputItemGroupList &&
        inputItemGroupList.forEach((input) => {
          let filter = {};
          filter.operator = input.logical;
          filter.negationIdentifier =
            input.not == "true" || input.not == true ? "not" : "";
          filter.field =
            input.field === "User Tag 1"
              ? "user_tag_1"
              : input.field === "User Tag 2"
              ? "user_tag_2"
              : input.field;
          filter.filterCondition =
            input.operator == "starts with"
              ? "starts"
              : input.operator == "ends with"
              ? "ends"
              : input.operator;
          filter.filterValue = input.value;
          requestBody.push(filter);
        });
        setLoading(true)
      let displayNameObj = await prd.filterPRDOutput(
        requestBody,
        filterId,
        apply
      );
      setLoading(false)
      let displayNameList = displayNameObj.data.filterTargetDto;
      let targetDtoSize =  displayNameObj.data.filterTargetDto.length;
      setClickVerifyFilter(true);
      setDisplayNameList(displayNameList);
      
      if(targetDtoSize>0){
	      if (!displayNameObj.data.maxRecords) {
	        setTooManyRecordsError(false);     
	        setDisableSave(false);
	        setTotalRecords(0);
	      } else {
	        setTooManyRecordsError(true);
	        setDisableSave(false);
	        setTotalRecords(displayNameObj.data.totalRecords);
	      }
      }
      else{
      	setTooManyRecordsError(false);     
	    setDisableSave(true);
	    setTotalRecords(0);
      }
      
    })();
  };
  const handleSaveFilter = () => {
    if (name.trim() === "") setNameError(true);
    else {
      setNameError(false);
      let groupFilterObject = {
        groupName: "",
        filterId: "",
        rowSelectors: { rowSelector: [] },
      };
      groupFilterObject.groupName = name;
      groupFilterObject.filterId = "f" + Date.now();
      inputItemGroupList &&
        inputItemGroupList.forEach((input, index) => {
          let filter = {};
          filter.logical = input.logical;
          filter.not = input.not == "true" || input.not == true;
          //filter.attribute = input.field;
          filter.field = input.field;
          filter.number = index + 1;
          //filter.number = input.number;
          filter.operator = input.operator;
          filter.value = input.value;
          groupFilterObject.rowSelectors.rowSelector.push(filter);
        });
      handleClose();
      dispatch(AddChartFilter(groupFilterObject));
    }
  };
  const handleClose = () => {
    setOpen(false);
    handleFilterClearClick();
    setName("");
    setNameError(true);
  };
  const handleFilterClearClick = () => {
    setInputItemGroupList(inputItemGroup);
    setClickVerifyFilter(false);
    setTooManyRecordsError(false);
    setDisableSave(true);
    setDisplayNameList([]);
  };

  return (
    <div class="prd-performance-report">
      <Button variant="text" className="button-filter" onClick={handleClickOpen}>
        Advanced Filter...
      </Button>

      <Dialog
        fullWidth
        onClose={handleClose}
        aria-labelledby="Show records matching "
        open={open}
        maxWidth="md"
      >
        <DialogTitleStyled  disableTypography component="div">
        <Typography id="Show records matching " component="h1" variant="h4">
          Advanced Filter
          </Typography>
        </DialogTitleStyled>
        <div style={{height:'0px'}}>
        <Tooltip title="Close">
        <IconButton
          aria-label="close"
          style={{float:'right',top:'-50px',marginRight:'8px'}}
          onClick={handleClose}
          autoFocus
        >
          <CloseIcon color="black" />
        </IconButton>
        </Tooltip>
        </div>
        <DialogContent>
    <>
        { tooManyRecordsError && <p style={{ color: "red", display: "flex", padding: "16px" }}>          
             Only top 50 filtered qos series shown in below output will be saved.            
        </p>}
        {loading && <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}><CircularProgress style={{color: '#3272D9'}}/></div>}
        <div style={{ display: "flex", padding: "6px" ,flexDirection:'column'}}>
          
          <div style={{ paddingTop: "5px",width:'100%' }}>
            <AdvancedFilterDialogContent
              name={name}
              setName={setName}
              nameError={nameError}
              setNameError={setNameError}
              inputItemGroup={inputItemGroup}
              inputItemGroupList={inputItemGroupList}
              displayNameList={displayNameList}
              setInputItemGroupList={setInputItemGroupList}
              filter={props.filter}
              clickVerifyFilter={clickVerifyFilter}
              tooManyRecordsError={tooManyRecordsError}
              disableSave={disableSave}
              setTooManyRecordsError={setTooManyRecordsError}
              setDisableSave={setDisableSave}
            />
            </div>
            <div
            style={{
              display: clickVerifyFilter
                ? "block"
                : "none",
              width: "100%",
              paddingTop: "3px",
            }}
          >
            <p style={{ margin: "0px", paddingLeft: "13px" }}>Filter Output</p>
            { tooManyRecordsError && <p style={{ margin: "0px", paddingLeft: "13px" }}>          
              Showing 50 out of total <span style={{ fontWeight: 'bold' }}>{totalRecords}</span> records...      
              </p>
            }
            
            <div class="filter-list-wrapper">
              {displayNameList && displayNameList.length < 1 && (
                <List className="filter-list-scroll" tabIndex="0">
                	<div style={{textAlign: "center"}}>
             			 No matching QoS found for provided filter criteria
           	 		</div>
               </List>
              )}
              {displayNameList && displayNameList.length > 0 && (
                <List className="filter-list-scroll" tabIndex="0">
                  {displayNameList.map((input) => (
                    <ListItem
                      style={{ padding: 0 }}
                      title
                      key={`${input.displayName}`}
                    >
                      <Tooltip arrow title={input.displayName}>
                        <ListItemText primary={`${input.displayName}`} />
                      </Tooltip>
                    </ListItem>
                  ))}
                </List>
              )}
            </div>
          </div>
            </div>
          
            </>
            </DialogContent>
            <DialogActions>
            <Button
                type="button"
                variant="text"
                
                onClick={handleFilterClearClick}
              >
                Clear Filter
              </Button>
              <Button
                onClick={handlVerifyFilter}
                color="primary"
                variant="outlined"
              >
                Verify Filter
              </Button>
              <Button
                onClick={handleSaveFilter}
                color="primary" variant="contained"
                disabled={disableSave || !name}
              >
                Save Filter
              </Button>
             
             
            </DialogActions>
         
      
      </Dialog>
    </div>
  );
};

export default AdvancedFilterDialog;
