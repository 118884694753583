import axios from "axios";
import config from "../config";

class DashboardMetric {
  getMetricsById(probeId) {
    console.log("getMetricsById :: " + probeId);
    if(process.env.NODE_ENV=='development'){
      return axios.get(
        "http://10.252.25.208:8080/castlemock/mock/rest/project/pvTZwK/application/GZuvSu/metricdata"
        );
      }
      return axios.get(
        [
          config.basename,
          `api/v1/metrics/metricListForProbe?probeName=${probeId}`,
        ].join("/")
        );
      }
      async getDevicesByMetric(probeId, metricTypeId) {
        console.log("getDevicesByMetric :: " + probeId + ", " + metricTypeId);
        if(process.env.NODE_ENV=='development'){
          return axios.get(
            "http://10.252.25.208:8080/castlemock/mock/rest/project/pvTZwK/application/GZuvSu/deviceList"
            );
          }
          return axios.get(
            [
              config.basename,
              `api/v1/metrics/deviceMetricForProbe?probeName=${probeId}&metricTypeId=${metricTypeId}`,
            ].join("/")
            );
          }
          getCiNames(probeId, metricTypeId, csId) {
            console.log("probeId, metricTypeId, csId", probeId, metricTypeId, csId);
            console.log("getCiNames :: " + probeId + ", " + metricTypeId + ", " + csId);
            if(process.env.NODE_ENV=='development'){
              return axios.get(
                "http://10.252.25.208:8080/castlemock/mock/rest/project/pvTZwK/application/GZuvSu/ciNames"
                );
              }
              return axios.get(
                [
                  config.basename,
                  `api/v1/metrics/ciDeviceMetricForProbe?probeName=${probeId}&metricTypeId=${metricTypeId}&csId=${csId}`,
                ].join("/")
                );
              }
              
              getAllMetricDevicesChart(params) {
                let deviceIds = params.deviceIds
                ? params.deviceIds.length
                ? params.deviceIds.join()
                : params.deviceIds
                : params.deviceIds,
                metric = params.metric,
                hours = params.hours,
                selectedCIs = params.selectedCIs,
                isInterface = params.isInterface,
                //endDate = params.endDate;
                endDate = Date.now();
                console.log(
                  "getAllMetricDevicesChart :: " +
                  deviceIds +
                  ", " +
                  metric +
                  ", " +
                  selectedCIs +
                  ", " +
                  endDate
                  );
                  if(process.env.NODE_ENV=='development'){
                    return axios.get(
                      "http://10.252.25.208:8080/castlemock/mock/rest/project/pvTZwK/application/704KL4/metricChart"
                      );
                    }
                    if (isInterface) {
                      let urlString = `api/v1/metrics/chart?metricTypeId=${encodeURI(
                        metric
                        )}&csIds=${encodeURI(deviceIds)}&hours=${encodeURI(
                          hours
                          )}&timezone=${encodeURI(
                            Intl.DateTimeFormat().resolvedOptions().timeZone
                            )}&endDateEpoch=${new Date(endDate).setSeconds(0,0)}`;
                            if (selectedCIs && selectedCIs.length) {
                              urlString += `&ciNames=${encodeURI(selectedCIs.join())}`;
                            }
                            return axios.get([config.basename, urlString].join("/"));
                          } else {
                            let urlString = `api/v1/metrics/chart?metricTypeId=${encodeURI(
                              metric
                              )}&csIds=${encodeURI(deviceIds)}&hours=${encodeURI(
                                hours
                                )}&timezone=${encodeURI(
                                  Intl.DateTimeFormat().resolvedOptions().timeZone
                                  )}&endDateEpoch=${new Date(endDate).setSeconds(0,0)}`;
                                  if (selectedCIs && selectedCIs.length) {
                                    urlString += `&ciNames=${encodeURI(selectedCIs.join())}`;
                                  }
                                  return axios.get([config.basename, urlString].join("/"));
                                }
                              }
                            }
                            
                            let DashBMetricAPI = new DashboardMetric();
                            export default DashBMetricAPI;
                            