// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //CRITICAL
  content["CRITICAL"] =
  "クリティカル";

  //MAJOR
  content["MAJOR"] =
  "メジャー";

  //MINOR
  content["MINOR"] =
  "マイナー";

  //WARNING
  content["WARNING"] =
  "警告";

  // INFO
  content["INFO"] =
  "情報";

  // NONE
  content["NONE"] =
  "なし";

  // END OF LOCALIZATION

export default content;
