import connect from './../../utils/connect'
import {setBreadCrumbs} from './../breadcrumbs/actions'
import Interfaces from './Interfaces'

const mapStateToProps = (state) => {
  return {
    sid: state.sid,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addBreadCrumb: (crumb) => dispatch(setBreadCrumbs(crumb)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Interfaces)
