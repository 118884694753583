import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import connect from "../../utils/connect";
import "./treeLayout.less";
import Resource from "./Resource";
import "./index.less";
import Tree, { TreeNode } from "rc-tree";
import SeverityIcon from "./../ui-components/components/icons/SeverityIcon";
import treeViewAPI from "./../../api/treeView/treeView";
import capitalize from "lodash/capitalize";
import { Snackbar, CircularProgress, IconButton } from "@mineral/core";
import TreeIcons from "./../icons/TreeIcons";
import CloseIcon from "@material-ui/icons/Close";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import entityTypes from "./../../api/entityTypes";
import { requestGroup } from "./../../api/groups/actions";
import MaintenanceIcon from "./../icons/MaintenanceIcon";
import { isEqual } from "lodash";
import get from "lodash/get";
import {
  setTreeNode,
  toggleAddGroupDialog,
} from "./../../api/treeView/actions";
import SearchGroup from "./SearchGroup";
import AddGroupIcon from "../icons/AddGroupIcon";
import DialogManager from "../titlebar/DialogManager";
import TreeViewActionMenu from "./TreeViewActionMenu";

const WIDTH_COLLAPSED = 56;
// const WIDTH_EXPANDED = 256;
const TRANSITION_DELAY_LONG = "0s";
const TRANSITION_DELAY_NONE = "0s";
let selectedGroup, parentIds;
let previousSearchString = "";
const NETWORK_INTERFACE = "NetworkInterface"

//NONE
const NONE = Resource.get("NONE");
let defaultAlarmData = {
  maxAlarmSeverity: {
    label: NONE,
    value: 0,
    cnt: 0,
  },
  totalAlarmCount: 0,
  alarmCountSummary: [
    { name: "Critical", cnt: 0 },
    { name: "Major", cnt: 0 },
    { name: "Minor", cnt: 0 },
    { name: "Warning", cnt: 0 },
    { name: "Info", cnt: 0 },
  ],
  maxAlarmCount: 0,
  memberCount: 0,
};

const Tooltip = ({children, title}) => {
  return (
    <span title={title}>
      {children}
    </span>
  )
}

const Button = ({ children, className, style}) => {
  return (
    <div
      className={className}
      style={{
        fontWeight: '600',
        fontSize: '0.875rem',
        lineHeight: '1.625', 
        ...style
      }}
    >
      {children}
    </div>
  )
}

class TreeLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gridData: [],
      groupId: 0,
      open: true,
      close: false,
      transitionDelay: TRANSITION_DELAY_LONG,
      width: WIDTH_COLLAPSED,
      expanded: false,
      currentTab: "Details",
      treeData: this.props.groupData,
      dataGroups: this.props.data,
      countData: this.props.countData,
      displayName: "",
      selectedKeys: ["0"],
      group: [],
      item_value: [],
      expandedKeys: ["31"],
      openSnackBar: false,
      showWhiteBg: true,
      filterGroupNew: "",
      autoExpandParent: true,
      loadedNodes: [],
      groupIdLoaded: -1001,
      lastUpdateTime: new Date(),
      // selectedNode: "",
      // onHoverTreeNode: false,
      // subGroupOpen: false,
      anchorTreeActionMenuEl: null,
    };
  }

  componentWillMount = () => {};

  componentDidUpdate(prevProps) {
    let lastUpdateTime = this.state.lastUpdateTime;
    if (!isEqual(prevProps.groupData, this.props.groupData)) {
      let treeData = this.props.groupData;
      let loadedNodes = this.state.loadedNodes;
      let treeDataLocal = this.state.treeData;
      let groupMaps = new Map();
      if (loadedNodes && loadedNodes.length) {
        let getNodeLoop = (data) => {
          data.map((item) => {
            if (loadedNodes.includes(item.id)) {
              groupMaps.set(item.id, item);
            }
            getNodeLoop(item.children);
            return true;
          });
        };
        getNodeLoop([treeDataLocal]);
      }
      this.refreshAsyncTree(treeData, groupMaps, true);
    }
    if (
      (new Date().getTime() - lastUpdateTime.getTime()) / (1000 * 60) >= 1 ||
      sessionStorage.getItem("devicesAddDataLayout")
    ) {
      let loadedNodes = this.state.loadedNodes;
      let groupMaps = new Map();
      let treeData = this.state.treeData;
      let requests = loadedNodes.map((id) => treeViewAPI.getGroupById(id));
      Promise.all(requests).then((responses) => {
        responses.forEach((response) =>
          groupMaps.set(response.data.id, response.data)
        );
        this.refreshAsyncTree(treeData, groupMaps, false);
      });
      if (sessionStorage.getItem("devicesAddDataLayout") !== null) {
        sessionStorage.removeItem("devicesAddDataLayout");
        lastUpdateTime = new Date();
        this.setState({ lastUpdateTime: lastUpdateTime });
      } else {
        lastUpdateTime = new Date();
        this.setState({ lastUpdateTime: lastUpdateTime });
      }
    }
  }

  refreshAsyncTree = (treeData, groupMaps, isInternal) => {
    let treeLoop = (data) => {
      data.map((item) => {
        let modifiedNode = groupMaps.get(item.id);
        if (modifiedNode && isInternal) {
          item.computerSystem = modifiedNode.computerSystem;
          item.interfaceSystem = modifiedNode.interfaceSystem.sort(this.sortInterfaceData);
        } else if (modifiedNode && !isInternal) {
          item.computerSystem = modifiedNode.computerSystems;
          item.interfaceSystem = modifiedNode.interfaceSystems.sort(this.sortInterfaceData);
        }
        treeLoop(item.children);
        return true;
      });
    };
    treeLoop([treeData]);
    if (!isEqual(this.state.treeData, treeData)) {
      this.setState({ treeData: treeData });
    }
  };

  componentDidMount() {
    this.props.selectNodeFromTable(this.onSelect);
    this.props.expandParent(this.setExpandedKeys);
  }

  handleCloseSnackBar = () => {
    this.setState({ openSnackBar: false });
  };

  mapAlarmMetaData = (data) => {
    let max = 0;
    return data
      .map((item) => {
        const alarmCountSummary =
          item.alarmCountSummary &&
          Object.keys(item.alarmCountSummary).map(function (key) {
            let name = key === "information" ? "info" : key;
            let cnt = item.alarmCountSummary[key];
            max = max >= cnt ? max : cnt;
            return { name: capitalize(name), cnt: cnt };
          });
        let maxAlarmSeverity = 0;
        if (alarmCountSummary && alarmCountSummary.length) {
          if (alarmCountSummary[0].cnt > 0) {
            maxAlarmSeverity = {
              //CRITICAL
              label: Resource.get("CRITICAL"),
              value: 5,
              cnt: alarmCountSummary[0].cnt,
            };
          } else if (alarmCountSummary[1].cnt > 0) {
            maxAlarmSeverity = {
              //MAJOR
              label: Resource.get("MAJOR"),
              value: 4,
              cnt: alarmCountSummary[1].cnt,
            };
          } else if (alarmCountSummary[2].cnt > 0) {
            maxAlarmSeverity = {
              //MINOR
              label: Resource.get("MINOR"),
              value: 3,
              cnt: alarmCountSummary[2].cnt,
            };
          } else if (alarmCountSummary[3].cnt > 0) {
            maxAlarmSeverity = {
              //WARNING
              label: Resource.get("WARNING"),
              value: 2,
              cnt: alarmCountSummary[3].cnt,
            };
          } else if (alarmCountSummary[4].cnt > 0) {
            maxAlarmSeverity = {
              // INFO
              label: Resource.get("INFO"),
              value: 1,
              cnt: alarmCountSummary[4].cnt,
            };
          } else {
            maxAlarmSeverity = {
              label: NONE,
              value: 0,
              cnt: 0,
            };
          }
        }
        var totalAlarmCount =
          alarmCountSummary &&
          alarmCountSummary.reduce(function (total, alarm) {
            return alarm.cnt + total;
          }, 0);
        return Object.assign({}, item, {
          alarmCountSummary: alarmCountSummary,
          maxAlarmSeverity: maxAlarmSeverity,
          totalAlarmCount: totalAlarmCount,
        });
      })
      .map((item) =>
        Object.assign(item, {
          maxAlarmCount: max,
          memberCount: item.memberCount ? item.memberCount : item.members,
        })
      );
  };

  sortInterfaceData(value1, value2) {
    if(value1.displayName < value2.displayName) {
      return -1
    }
    if(value1.displayName > value2.displayName) {
      return 1
    }
    return 0
  }

  getSelectedNodeData = (id, group, isLeaf, parentIds, isEdit) => {
    this.props.updateLoadingContent(true);
    if (group.type === "COMPUTER_SYSTEM") {
      return this.props.gridSetter({}, group, isLeaf, parentIds);
    }
    if (
      group.type === "NetworkInterface" || group.type==null ||
      group.typeName === "NetworkInterface"
    ) {
      return this.props.gridSetter({}, group, isLeaf, parentIds);
    }

    let groupIdData, compSysData;
    this.setState({
      openSnackBar: false,
    });
    return treeViewAPI
      .getGroupById(group.id)
      .then((grpData) => {
        groupIdData = grpData.data;
        if (groupIdData.computerSystems && groupIdData.computerSystems.length) {
          return treeViewAPI.getDeviceData(group.id);
        } else if (group.masterElementGroup) {
          return treeViewAPI.getInterfaceData(group.id);
        }
      })
      .then((csData) => {
        compSysData = csData && csData.data && csData.data._items;
        if (
          compSysData &&
          compSysData.length &&
          groupIdData.computerSystems &&
          groupIdData.computerSystems.length
        ) {
          compSysData = this.mapAlarmMetaData(compSysData);
          compSysData.map((system) => {
            
              for (let i = 0; i < groupIdData.computerSystems.length; i++) {
                if (groupIdData.computerSystems[i].id == system.id) {
                  groupIdData.computerSystems[i] = {
                    ...groupIdData.computerSystems[i],
                    ...system,
                    groupId: group.id,
                    parentId: group.id,
                  };
                  break;
                }
              }
            
          });
        } else if (groupIdData.masterElementGroup === true) {
          // compSysData = this.mapAlarmMetaData(compSysData);
          compSysData.map((system) => {
            groupIdData.interfaceSystem.push({
              ...system,
              ...defaultAlarmData,
              groupId: group.id,
              parentId: group.id,
            });
          });
        }

        return treeViewAPI.getChildDetails(group.id);
      })
      .then((alarmData) => {
        let childData = this.mapAlarmMetaData(alarmData.data._items);
        if (childData.length) {
          childData.map((child) => {
            if (groupIdData.groups && groupIdData.groups.length) {
              for (let i = 0; i < groupIdData.groups.length; i++) {
                if (groupIdData.groups[i].id == child.id) {
                  groupIdData.groups[i] = {
                    ...groupIdData.groups[i],
                    ...child,
                  };
                  break;
                }
              }
            }
          });
        } else if (!compSysData || (compSysData && !compSysData.length)) {
          if (
            groupIdData.computerSystems &&
            groupIdData.computerSystems.length
          ) {
            for (let i = 0; i < groupIdData.computerSystems.length; i++) {
              groupIdData.computerSystems[i] = {
                ...groupIdData.computerSystems[i],
                ...defaultAlarmData,
                groupId: group.id,
                parentId: group.id,
              };
            }
          }
          if (groupIdData.groups && groupIdData.groups.length) {
            for (let i = 0; i < groupIdData.groups.length; i++) {
              groupIdData.groups[i] = {
                ...groupIdData.groups[i],
                ...defaultAlarmData,
              };
            }
          }
          if (
            groupIdData.interfaceSystem &&
            groupIdData.interfaceSystem.length
          ) {
            for (let i = 0; i < groupIdData.interfaceSystem.length; i++) {
              groupIdData.interfaceSystem[i] = {
                ...groupIdData.interfaceSystem[i],
                ...defaultAlarmData,
                groupId: group.id,
                parentId: group.id,
              };
            }
          }
        }

        let isLastNode = false;
        if (
          (!groupIdData.computerSystems &&
            !groupIdData.groups &&
            !groupIdData.interfaceSystem) ||
          (groupIdData.computerSystems &&
            groupIdData.computerSystems.length == 0 &&
            groupIdData.groups &&
            groupIdData.groups.length == 0 &&
            groupIdData.interfaceSystem &&
            groupIdData.interfaceSystem.length == 0)
        ) {
          isLastNode = true;
        }
        this.props.gridSetter(
          groupIdData.computerSystems && groupIdData.computerSystems.length
            ? groupIdData.computerSystems
            : groupIdData.interfaceSystem && groupIdData.interfaceSystem.length
            ? groupIdData.interfaceSystem
            : groupIdData.groups,
          group,
          isLastNode,
          new Map(),
          isEdit
        );
      })
      .catch((err) => {
        this.setState({
          openSnackBar: true,
          groupErrMsg: err
            .toString()
            .includes("Request failed with status code")
            ? "Unable to load data.Request failed with status code 500"
            : "Something went wrong, please try after some time.",
        });
      })
      .finally(()=>{
        this.props.updateLoadingContent(false);
      });
  };

  getValue = (item, key) =>
    item.attributes && item.attributes[key] && item.attributes[key][0];

  locateId = (id, parentId, group, parentGroup) => {
    if (selectedGroup) {
      return selectedGroup;
    }
    if (group.id == id && parentId === group.parentId) {
      if (parentGroup) {
        parentIds.set("id" + group.parentId.toString(), group.name);
      }
      selectedGroup = group;
      return selectedGroup;
    }
    if (!selectedGroup) {
      if (group.computerSystem && group.computerSystem.length) {
        for (let i = 0; i < group.computerSystem.length; i++) {
          if (group.computerSystem[i].id == id) {
            selectedGroup = group.computerSystem[i];
            parentIds.set("id" + group.id.toString(), group.name);
            break;
          }
        }
      }
      if (group.interfaceSystem && group.interfaceSystem.length) {
        for (let i = 0; i < group.interfaceSystem.length; i++) {
          if (group.interfaceSystem[i].id == id) {
            selectedGroup = group.interfaceSystem[i];
            parentIds.set("id" + group.id.toString(), group.name);
            break;
          }
        }
      }
      if (group.children && group.children.length) {
        for (let j = 0; j < group.children.length; j++) {
          if (
            group.children[j].id == id &&
            parentId == group.children[j].parentId
          ) {
            selectedGroup = group.children[j];
            parentIds.set("id" + group.id.toString(), group.name);
            break;
          }
          if (group.children[j].children && !selectedGroup) {
            parentIds.set("id" + group.id.toString(), group.name);
            this.locateId(id, parentId, group.children[j], group);
            if (!selectedGroup) {
              parentIds.delete("id" + group.id.toString());
            }
          } else {
            parentIds.delete("id" + group.id.toString());
          }
        }
      }
    }
    return selectedGroup;
  };

  onSelect = (
    selectedKeys,
    info,
    isCallFromTable,
    dataFromTable,
    newData,
    isDataChanged
  ) => {
    selectedGroup = "";
    // this.setState({ selectedNode: info?.node?.props?.eventKey });
    parentIds = new Map();
    if (isCallFromTable && dataFromTable.keySelected.length) {
      this.props.getGroup(dataFromTable.keySelected[0]);
      this.locateId(
        dataFromTable.keySelected[0],
        dataFromTable.rowData.parentId,
        this.state.treeData
      );
      this.getSelectedNodeData(
        dataFromTable.keySelected[0],
        dataFromTable.rowData,
        dataFromTable.isLastNode,
        parentIds
      );
      let selectedKeys = dataFromTable.keySelected;
      selectedKeys = "grp_" + selectedKeys[0];
      this.setState({
        open: true,
        selectedKeys: selectedKeys,
        showWhiteBg: false,
        expandedKeys: [
          ...this.state.expandedKeys,
          "grp_" + dataFromTable.rowData.parentId?.toString(),
        ],
      });
      let entityType = null;
      let isGroup = true;
      if(dataFromTable.rowData && dataFromTable.rowData.type==null)
        {
          isGroup = false;
          entityType = entityTypes.INTERFACES;
        }
      if (dataFromTable.rowData && dataFromTable.rowData.type) {
        switch (dataFromTable.rowData.type) {
          case "CONTAINER":
            entityType = entityTypes.CONTAINER;
            break;
          case "ROOT":
            entityType = entityTypes.ROOT;
            break;
          case "DYNAMIC":
            entityType = entityTypes.DYNAMIC;
            break;
          case "STATIC":
            entityType = entityTypes.STATIC;
            break;
          case "AUTOMATIC":
            entityType = entityTypes.AUTOMATIC;
            break;
          case "NetworkInterface":
            isGroup = false;
            entityType = entityTypes.INTERFACES;
            break;
          case "COMPUTER_SYSTEM":
            isGroup = false;
            entityType = entityTypes.COMPUTER_SYSTEM;
            break;
          default:
            entityType = entityTypes.CONTAINER;
            break;
        }
      }
      let parentType = "EMPTY";
      let childrenType = "";
      /* if (
        entityType === entityTypes.CONTAINER &&
        dataFromTable.rowData &&
        dataFromTable.rowData.children &&
        dataFromTable.rowData.children.length > 0
      ) */
      if (
        entityType === entityTypes.CONTAINER &&
        dataFromTable.rowData &&
        dataFromTable.rowData.childGroups &&
        dataFromTable.rowData.childGroups.length > 0
      ) {
        childrenType = dataFromTable.rowData.childGroups[0].type;
        if (childrenType === "AUTOMATIC") {
          parentType = "AUTOMATIC";
        } else if (
          childrenType === "DYNAMIC" ||
          childrenType === "STATIC" ||
          childrenType === "CONTAINER"
        ) {
          parentType = "NON_REMOVABLE";
        }
      }
      if (dataFromTable.rowData) {
        this.props.updateTreeNode({
          entityType: entityType,
          parentType: parentType,
          childrenType,
          isInterfaceGroup: dataFromTable.rowData.masterElementGroup,
          element_id: dataFromTable.rowData.id,
        });
        if (isGroup) {
          this.props.getGroup(dataFromTable.rowData.id);
        }
      }
    } else if (isDataChanged) {
      this.locateId(selectedKeys[0], newData.parentId, this.state.treeData);
      this.getSelectedNodeData(
        selectedKeys[0],
        newData,
        newData.isLastNode,
        parentIds,
        true
      );
      this.setState({
        open: true,
        selectedKeys: selectedKeys,
        showWhiteBg: true,
      });
      let entityType = null;
      let isGroup = true;
      if(newData.type==null){
        isGroup = false;
            entityType = entityTypes.INTERFACES;
      }
      if (newData.type) {
        switch (newData.type) {
          case "CONTAINER":
            entityType = entityTypes.CONTAINER;
            break;
          case "ROOT":
            entityType = entityTypes.ROOT;
            break;
          case "DYNAMIC":
            entityType = entityTypes.DYNAMIC;
            break;
          case "STATIC":
            entityType = entityTypes.STATIC;
            break;
          case "AUTOMATIC":
            entityType = entityTypes.AUTOMATIC;
            break;
          case "NetworkInterface":
            isGroup = false;
            entityType = entityTypes.INTERFACES;
            break;
          case "COMPUTER_SYSTEM":
            isGroup = false;
            entityType = entityTypes.COMPUTER_SYSTEM;
            break;
          default:
            entityType = entityTypes.CONTAINER;
            break;
        }
      }
      let parentType = "EMPTY";
      let childrenType = "";
      if (
        entityType === entityTypes.CONTAINER &&
        newData &&
        newData.children &&
        newData.children.length > 0
      ) {
        childrenType = newData.children[0].type;
        if (childrenType === "AUTOMATIC") {
          parentType = "AUTOMATIC";
        } else if (
          childrenType === "DYNAMIC" ||
          childrenType === "STATIC" ||
          childrenType === "CONTAINER"
        ) {
          parentType = "NON_REMOVABLE";
        }
      }
      if (newData) {
        this.props.updateTreeNode({
          entityType: entityType,
          parentType: parentType,
          isInterfaceGroup: newData.masterElementGroup,
          element_id: newData.id,
          childrenType,
        });
        if (isGroup) {
          this.props.getGroup(newData.id);
        }
      }
    } else if (selectedKeys.length) {
      this.locateId(
        selectedKeys[0],
        info.node.props.nodeData.parentId,
        this.state.treeData
      );
      let entityType = null;
      let isGroup = true;
      let parentType = "EMPTY";
      let childrenType = "";
      if(info.node && info.node.props && info.node.props.type==null)
      {
        isGroup = false;
        entityType = entityTypes.INTERFACES;
      }
      if (info.node && info.node.props && info.node.props.type) {
        switch (info.node.props.type) {
          case "CONTAINER":
            entityType = entityTypes.CONTAINER;
            break;
          case "ROOT":
            entityType = entityTypes.ROOT;
            break;
          case "DYNAMIC":
            entityType = entityTypes.DYNAMIC;
            break;
          case "STATIC":
            entityType = entityTypes.STATIC;
            break;
          case "AUTOMATIC":
            entityType = entityTypes.AUTOMATIC;
            break;
          case "NetworkInterface":
            isGroup = false;
            entityType = entityTypes.INTERFACES;
            break;
          case "COMPUTER_SYSTEM":
            isGroup = false;
            entityType = entityTypes.COMPUTER_SYSTEM;
            break;
          default:
            entityType = entityTypes.CONTAINER;
            break;
        }
      }
      if (isGroup) {
        this.props.getGroup(info.node.props.id);
      }
      if (
        entityType === entityTypes.CONTAINER &&
        info.node.props.nodeData &&
        info.node.props.nodeData.children &&
        info.node.props.nodeData.children.length > 0
      ) {
        childrenType = info.node.props.nodeData.children[0].type;
        if (childrenType === "AUTOMATIC") {
          parentType = "AUTOMATIC";
        } else if (
          childrenType === "DYNAMIC" ||
          childrenType === "STATIC" ||
          childrenType === "CONTAINER"
        ) {
          parentType = "NON_REMOVABLE";
        }
      }
      if (info.node && info.node.props) {
        this.props.updateTreeNode({
          entityType: entityType,
          parentType: parentType,
          isInterfaceGroup: info.node.props.masterElementGroup,
          element_id: info.node.props.id,
          childrenType,
        });
      }
      this.getSelectedNodeData(
        selectedKeys[0],
        info.node.props.nodeData,
        info.node.isLeaf(),
        parentIds
      );
      this.setState({
        open: true,
        selectedKeys: selectedKeys,
        showWhiteBg: true,
      });
    }
    let parentType = "EMPTY";
    let childrenType = "";
  };

  setExpandedKeys = (key, id, isGrpDel) => {
    if (isGrpDel) {
      this.setState({
        expandedKeys: [id.toString()],
        selectedKeys: [id.toString()],
      });
    } else {
      this.setState({
        expandedKeys: [...this.state.expandedKeys, "grp_" + key.toString()],
        selectedKeys: ["cs_" + key.toString() + "_" + id.toString()],
      });
    }
  };

  onLoadData = (treeNode) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        if (
          treeNode.props.nodeData.type === "DYNAMIC" ||
          treeNode.props.nodeData.type === "STATIC" ||
          treeNode.props.nodeData.type === "AUTOMATIC"
        ) {
          this.setState({ groupIdLoaded: treeNode.props.id });
          treeViewAPI
            .getGroupById(treeNode.props.id)
            .then((grpData) => {
              return grpData.data;
            })
            .then((groupIdData) => {
              const treeData = this.state.treeData;
              const loadedNodes = this.state.loadedNodes;
              loadedNodes.push(treeNode.props.id);
              this.addNewNode(treeNode.props.id, groupIdData, treeData);
              this.setState({
                treeData: treeData,
                loadedNodes: loadedNodes,
                groupIdLoaded: -1001,
              });
              resolve();
            });
        } else {
          resolve();
        }
      }, 0);
    });
  };
  addNewNode = (id, groupData, treeData) => {
    const nodeFound = false;
    const treeIterate = (data) => {
      data.map((item) => {
        if (nodeFound) {
          return true;
        }
        if (item.id === id) {
          item.computerSystem = groupData.computerSystems;
          item.interfaceSystem = groupData.interfaceSystems.sort(this.sortInterfaceData);
          return true;
        } else {
          return treeIterate(item.children);
        }
      });
    };
    treeIterate([treeData]);
  };
  onChange = (event) => {
    this.setState({
      filterGroupNew: event.target.value,
    });
  };

  // onExpandKey = (event, key) => {
  //   if (event.code === "Enter") {
  //     const indexItem = this.state.expandedKeys.indexOf(key);
  //     if (indexItem !== -1) {
  //       this.setState((prevState) => ({
  //         expandedKeys: [
  //           ...prevState.expandedKeys.slice(0, indexItem),
  //           ...prevState.expandedKeys.slice(indexItem + 1),
  //         ],
  //         autoExpandParent: false,
  //       }));
  //     } else {
  //       this.setState((prevState) => ({
  //         expandedKeys: [...prevState.expandedKeys, key],
  //         autoExpandParent: false,
  //       }));
  //     }
  //   }
  // };

  onExpand = (expandedKeys) => {
    console.log("t> onExpand: ", expandedKeys);
    // if not set autoExpandParent to false, if children expanded, parent can not collapse.
    // or, you can remove all expanded chilren keys.
    this.setState({
      expandedKeys,
      autoExpandParent: false,
    });
  };

  // handleOnMouseEnter = (key) => {
  //   this.setState({ onHoverTreeNode: key });
  // };
  // handleOnMouseLeave = () => {
  //   this.setState({ onHoverTreeNode: null });
  // };

  handleAddGroupOpen = (e) => {
    e.stopPropagation();
    this.props.toggleAddGroupDialog(true);
  };

  handleActionMenuOpen = (event) => {
    this.setState({ anchorTreeActionMenuEl: event.currentTarget });
    // event.stopPropagation();
  };

  handleActionMenuClose = () => {
    this.setState({ anchorTreeActionMenuEl: null });
  };

  render() {
    const arrowPath =
      "M4.97402 6.16666L0.190021 1.29461C0.06828 1.17063 0 1.0033 0 0.828928C0 0.463166 0.294408 0.166656 0.657578 0.166656H9.34242C9.51679 0.166656 9.68401 0.236402 9.80733 0.360557C10.0642 0.619149 10.0642 1.03848 9.80747 1.29715L4.97402 6.16666Z";
    //Group(s)
    const Groups = Resource.get("Groups ");
    //Loading
    const Loading = Resource.get("Loading... ");
    // Search Group
    const SEARCH_GROUP = Resource.get("Search Group");
    const filterText = this.state.filterGroupNew;

    // Select
    const SELECT = Resource.get("Select");
    let canModifyGroup = get(this.props.acl.aclInfo, "roles", "").includes(
      "USM Group Modification"
    );
    let canAddGroup = get(this.props.acl.aclInfo, "roles", "").includes("OC Group Add");

    const getSvg = (path, style = {}, obj) => {
      return (
        <svg
          width="20"
          height="14"
          viewBox="0 0 20 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{
            ...style,
            position: "absolute",
            top: obj.expanded ? "5px" : "-5px",
            left: "2px",
          }}
        >
          <path d={path} fill="#58606E" />
        </svg>
      );
    };

    const switcherIcon = (obj) => {
      if (!obj.isLeaf) {
        return (
        
            <div>
              <Tooltip title={obj.expanded ? "Hide" : "Expand"} arrow>
              <IconButton
                style={{
                  width: "16px",
                  height: "16px",
                  padding: 0,
                  marginTop: "10px",
                }}
                aria-label={obj.expanded ? "Hide" : "Expand"}
                role="button"
                // onFocus={() => this.handleOnMouseEnter(obj.eventKey)}
                // onKeyPress={(event) => {
                //   console.log(obj.eventKey);
                //   this.onExpandKey(event, obj.eventKey);
                // }}
              >
                {getSvg(
                  arrowPath,
                  { transform: `rotate(${!obj.expanded ? -90 : 0}deg)` },
                  obj
                )}
              </IconButton>
              </Tooltip>
            </div>
         
        );
      }
    };

    const iconProps = {
      switcherIcon,
    };

    let searchExpandedKeys = [];

    const expandedSearch = (treeData) => {
      if (treeData && filterText.trim().length > 0) {
        treeData.forEach((item) => {
          if (item?.props?.children) {
            searchExpandedKeys.push(item.key);
            expandedSearch(item.props.children[0]);
            // console.log(searchExpandedKeys)
          }
          return;
        });
      }
      return;
    };

    const loop = (data, countmap, parent, isParentNameMatchSearch = false) => {
      return (
        data.length &&
        data.map((item) => {
          let deviceCount = countmap[item.id] ? countmap[item.id] : 0;
          let label =
            item.name ||
            item.DisplayName ||
            item.displayName ||
            (item.attributes && item.attributes.DisplayName
              ? item.attributes.DisplayName[0]
              : "");
          label = label === "Root" ? "Groups" : label;
          let mapperPicker = this.props.GroupAlarmMapper;
          let keyToMap =
            mapperPicker["id" + item.id + "parentId" + item.parentId];
          if (item.type == entityTypes.COMPUTER_SYSTEM) {
            mapperPicker = this.props.DeviceAlarmMapper;
            keyToMap = mapperPicker[item.id];
          } else if (
            item.IfType ||
            item.type == entityTypes.NETWORK_INTERFACE || item.type==null
          ) {
            mapperPicker = this.props.InterfaceAlarmMapper;
            keyToMap = mapperPicker[item.id];
            label = item.displayName ? item.displayName : label;
          } else {
            label = label + " (" + deviceCount + ")";
          }
          let circle =
            this.state.groupIdLoaded === item.id ? (
              <CircularProgress
                size={15}
                thickness={3}
                color="primary" /* "#292925" */
              />
            ) : null;
          let keySub =
            item.type === "NetworkInterface" || item.type === null
              ? "int_" + parent + "_"
              : item.type === "COMPUTER_SYSTEM"
              ? "cs_" + parent + "_"
              : "grp_";
          const isCurrentNameMatchSearch =
            filterText &&
            item.name &&
            item.name
              .toLocaleLowerCase()
              .includes(filterText.toLocaleLowerCase());

          if (
            (item.children && item.children.length) ||
            (item.computerSystem && item.computerSystem.length) ||
            (item.interfaceSystem && item.interfaceSystem.length)
          ) {
            const groupChildren =
              item.children && item.children.length
                ? loop(
                    item.children,
                    countmap,
                    item.id,
                    isCurrentNameMatchSearch || isParentNameMatchSearch
                  )
                : null;
            const deviceChildren = item.computerSystem
              ? loop(
                  item.computerSystem,
                  countmap,
                  item.id,
                  isCurrentNameMatchSearch || isParentNameMatchSearch
                )
              : null;
            const interfaceChildren = item.interfaceSystem
              ? loop(
                  item.interfaceSystem,
                  countmap,
                  item.id,
                  isCurrentNameMatchSearch || isParentNameMatchSearch
                )
              : null;

            let filterNotMatching = false;

            if (
              filterText &&
              item.name &&
              item.name
                .toLocaleLowerCase()
                .indexOf(filterText.toLocaleLowerCase()) < 0
            ) {
              filterNotMatching = true;
              if (groupChildren) {
                var isGroupNotNull = groupChildren.some(function (el) {
                  return el !== null;
                });
                filterNotMatching = !isGroupNotNull;
              }
            }

            if (
              filterText &&
              filterText.length > 0 &&
              !isParentNameMatchSearch &&
              !isCurrentNameMatchSearch &&
              filterNotMatching
            ) {
              return null;
            }

            
           
            

            return (
              <TreeNode
                className="cus-label"
                id={item.id}
                isLeaf={
                  item.type == entityTypes.STATIC ||  item.type == entityTypes.DYNAMIC ||  item.type == entityTypes.CONTAINER || item.type==entityTypes.ROOT || item.type==entityTypes.AUTOMATIC
                  ? false
                  : true
                }
                titlename={
                  item.name
                    ? item.name
                    : item.DisplayName || item.displayName
                    ? item.DisplayName || item.displayName
                    : item.attributes && item.attributes.DisplayName
                    ? item.attributes.DisplayName[0]
                    : ""
                }
                type={item.type}
                count={item.count ? item.count : "groups(0)Member(1)"}
                os_name={this.getValue(item, "os_name")}
                typeName={this.getValue(item, "typeName")}
                OSDescription={this.getValue(item, "OSDescription")}
                origin={this.getValue(item, "origin")}
                os_type={this.getValue(item, "os_type")}
                dedicated={this.getValue(item, "dedicated")}
                alias={this.getValue(item, "DisplayAlias")}
                displayName={this.getValue(item, "DisplayName")}
                maxAlarmSeverity={item.maxAlarmSeverity}
                masterElementGroup={item.masterElementGroup}
                icon={""}
                nodeData={item}
                key={keySub + item.id}
                title={
                  item.parentId == -1 || item.parentId == null ? (
                    //Root node
                    <Button fullWidth style={{justifyContent:'start'}} variant="text" component="div" 
                      className="tree-item"
                      aria-label={SELECT+" "+label}
                      // onMouseEnter={() =>
                      //   this.handleOnMouseEnter(keySub + item.id)
                      // }
                      role="group"
                      // onMouseLeave={this.handleOnMouseLeave}
                    >
                    <Tooltip title={label}>
                      <div style={{ display: "flex" ,width:'100%' }}>
                        {circle}
                        <TreeIcons
                          type={item.type}
                          class="grid__icon"
                          name={label}
                          level={item.state == -1 ? 0 : item.state}
                        />
                        <p style={{ margin: "0", color: "#3d424a" }}>{label}</p>
                      </div>
                      </Tooltip>
                    
                        {(canModifyGroup || canAddGroup ) && <div style={{marginLeft:'auto'}} > <Tooltip title={"Add Group"} arrow>
                          <IconButton
                            style={{ padding: "2px" ,margin:'2px'}}
                            onClick={(e) => this.handleAddGroupOpen(e)}
                            role="button"
                            name="Add Group"
                            aria-label="Add Group"
                          >
                            <AddGroupIcon />
                          </IconButton></Tooltip>
                        </div>}
                     
                    </Button>
                  ) : // ) : mapperPicker[item.id] === 5 ? (
                  keyToMap === 5 ? (
                    <Button fullWidth style={{justifyContent:'start'}} variant="text" component="div"  role="group"
                      className="tree-item"
                      aria-label={SELECT+" "+label}
                      // onMouseEnter={() =>
                      //   this.handleOnMouseEnter(keySub + item.id)
                      // }
                      // onMouseLeave={this.handleOnMouseLeave}
                    > <Tooltip title={label}>
                      <div style={{ color: "#3d424a",width:'100%'  }}>
                        {circle}
                        <TreeIcons
                          type={item.type}
                          class="grid__icon"
                          name={label}
                          level={5}
                        />
                        {label}
                      </div>
                      </Tooltip>
                      {/*this.state.selectedNode === keySub + item.id ||
                      this.state.onHoverTreeNode === keySub + item.id ? (*/}
                        
                          <div style={{marginLeft:'auto'}}>
                          { (item.type==="CONTAINER" || item.type === "DYNAMIC" || item.type === "STATIC" || item.type === "AUTOMATIC") && <Tooltip title={"More Actions"} arrow>
                            <IconButton
                              style={{ padding: "2px", margin:'2px' }}
                              onClick={(e) => this.handleActionMenuOpen(e)}
                              // onBlur={() => this.handleOnMouseLeave()}
                              name="Actions"
                              aria-label="Actions"
                              role="button"
                            >
                              <MoreVertIcon style={{ color: "#3272D9" }} />
                            </IconButton>
                            </Tooltip>}
                          </div>
                     {/* ) : null*/}
                    </Button>
                  ) : keyToMap === 4 ? (
                    <Button fullWidth style={{justifyContent:'start'}} variant="text" component="div"  role="group"
                      className="tree-item"
                      aria-label={SELECT+" "+label}
                      // onMouseEnter={() =>
                      //   this.handleOnMouseEnter(keySub + item.id)
                      // }
                      // onMouseLeave={this.handleOnMouseLeave}
                    > <Tooltip title={label}>
                      <div style={{ color: "#3d424a" ,width:'100%' }}>
                        {circle}
                        <TreeIcons
                          type={item.type}
                          class="grid__icon"
                          name={label}
                          level={4}
                        />
                        {label}
                      </div>
                      </Tooltip>
                      {/*this.state.selectedNode === keySub + item.id ||
                      this.state.onHoverTreeNode === keySub + item.id ? (*/}
                      <div style={{marginLeft:'auto'}}>
                      { (item.type==="CONTAINER" || item.type === "DYNAMIC" || item.type === "STATIC" || item.type === "AUTOMATIC" ) && <Tooltip title={"More Actions"} arrow>
                          
                            <IconButton
                              style={{ padding: "2px"  ,margin:'2px'}}
                              onClick={(e) => this.handleActionMenuOpen(e)}
                              // onBlur={() => this.handleOnMouseLeave()}
                              role="button"
                              name="Actions"
                              aria-label="Actions"
                            >
                              <MoreVertIcon style={{ color: "#3272D9" }} />
                            </IconButton>
                         
                        </Tooltip>}
                        </div>
                      {/*) : null*/}
                    </Button>
                  ) : keyToMap === 3 ? (
                    <Button fullWidth style={{justifyContent:'start'}} variant="text" component="div"  role="group"
                      className="tree-item"
                      aria-label={SELECT+" "+label}
                      // onMouseEnter={() =>
                      //   this.handleOnMouseEnter(keySub + item.id)
                      // }
                      // onMouseLeave={this.handleOnMouseLeave}
                    > <Tooltip title={label}>
                      <div style={{ color: "#3d424a" ,width:'100%' }}>
                        {circle}
                        <TreeIcons
                          type={item.type}
                          class="grid__icon"
                          name={label}
                          level={3}
                        />
                        {label}
                      </div>
                      </Tooltip>
                      {/*this.state.selectedNode === keySub + item.id ||
                      this.state.onHoverTreeNode === keySub + item.id ? (*/}
                       
                          <div style={{marginLeft:'auto'}}>
                          { (item.type==="CONTAINER" || item.type === "DYNAMIC" || item.type === "STATIC" || item.type === "AUTOMATIC" ) && <Tooltip title={"More Actions"} arrow>
                            <IconButton
                              style={{ padding: "2px"  ,margin:'2px'}}
                              onClick={(e) => this.handleActionMenuOpen(e)}
                              // onBlur={() => this.handleOnMouseLeave()}
                              aria-label="Actions"
                              name="Actions"
                              role="button"
                            >
                              <MoreVertIcon style={{ color: "#3272D9" }} />
                            </IconButton>
                            </Tooltip>}
                          </div>
                        
                     {/* ) : null}*/}
                    </Button>
                  ) : keyToMap === 2 ? (
                    <Button fullWidth style={{justifyContent:'start'}} variant="text" component="div"  role="group"
                      className="tree-item"
                      aria-label={SELECT+" "+label}
                      // onMouseEnter={() =>
                      //   this.handleOnMouseEnter(keySub + item.id)
                      // }
                      // onMouseLeave={this.handleOnMouseLeave}
                    > <Tooltip title={label}>
                      <div style={{ color: "#3d424a",width:'100%'  }}>
                        {circle}
                        <TreeIcons
                          type={item.type}
                          class="grid__icon"
                          name={label}
                          level={2}
                        />
                        {label}
                      </div>
                      </Tooltip>
                      {/*this.state.selectedNode === keySub + item.id ||
                      this.state.onHoverTreeNode === keySub + item.id ? (*/}
                      <div style={{marginLeft:'auto'}}>
                      { (item.type==="CONTAINER" || item.type === "DYNAMIC" || item.type === "STATIC" || item.type === "AUTOMATIC" ) && <Tooltip title={"More Actions"} arrow>
                         
                            <IconButton
                              style={{ padding: "2px" ,margin:'2px' }}
                              onClick={(e) => this.handleActionMenuOpen(e)}
                              // onBlur={() => this.handleOnMouseLeave()}
                              aria-label="Actions"
                              name="Actions"
                              role="button"
                            >
                              <MoreVertIcon style={{ color: "#3272D9" }} />
                            </IconButton>
                         
                        </Tooltip>}
                        </div>
                     {/* ) : null*/}
                    </Button>
                  ) : keyToMap == 1 ? (
                    <Button fullWidth style={{justifyContent:'start'}} variant="text" component="div"  role="group"
                      className="tree-item"
                      aria-label={SELECT+" "+label}
                      // onMouseEnter={() =>
                      //   this.handleOnMouseEnter(keySub + item.id)
                      // }
                      // onMouseLeave={this.handleOnMouseLeave}
                    > <Tooltip title={label}>
                      <div style={{ color: "#3d424a",width:'100%'  }}>
                        {circle}
                        <TreeIcons
                          type={item.type}
                          class="grid__icon"
                          name={label}
                          level={1}
                        />
                        {label}
                      </div>
                      </Tooltip>
                      {/*this.state.selectedNode === keySub + item.id ||
                      this.state.onHoverTreeNode === keySub + item.id ? (*/}
                      <div style={{marginLeft:'auto'}}>
                      { (item.type==="CONTAINER" || item.type === "DYNAMIC" || item.type === "STATIC" || item.type === "AUTOMATIC" ) && <Tooltip title={"More Actions"} arrow>
                        
                            <IconButton
                              style={{ padding: "2px" ,margin:'2px' }}
                              onClick={(e) => this.handleActionMenuOpen(e)}
                              // onBlur={() => this.handleOnMouseLeave()}
                              aria-label="Actions"
                              name="Actions"
                              role="button"
                            >
                              <MoreVertIcon style={{ color: "#3272D9" }} />
                            </IconButton>
                         
                        </Tooltip>}
                        </div>
                     { /*) : null}*/}
                    </Button>
                  ) : (
                    <Button fullWidth style={{justifyContent:'start'}} variant="text" component="div"  role="group"
                      className="tree-item" 
                      aria-label={SELECT+" "+label}
                      // onMouseEnter={() =>
                      //   this.handleOnMouseEnter(keySub + item.id)
                      // }
                      // onMouseLeave={this.handleOnMouseLeave}
                    > <Tooltip title={label}>
                      <div style={{ color: "#3d424a",width:'100%' }}>
                        {circle}
                        <TreeIcons
                          type={item.type}
                          class="grid__icon"
                          name={label}
                          level={0}
                        />
                       {label}
                      </div>
                      </Tooltip>
                      {/*this.state.selectedNode === keySub + item.id ||
                      this.state.onHoverTreeNode === keySub + item.id ? (*/}
                       
                          <div style={{marginLeft:'auto'}}>
                          { (item.type==="CONTAINER" || item.type === "DYNAMIC" || item.type === "STATIC" || item.type === "AUTOMATIC" )
                          
                && <Tooltip title={"More Actions"} arrow>
                            <IconButton
                              style={{ padding: "2px"  ,margin:'2px'}}
                              onClick={(e) => this.handleActionMenuOpen(e)}
                              // onBlur={() => this.handleOnMouseLeave()}
                              aria-label="Actions"
                              name="Actions"
                              role="button"
                            >
                              <MoreVertIcon style={{ color: "#3272D9" }} />
                            </IconButton>
                            </Tooltip>}
                          </div>
                       
                      {/*}) : null}*/}
                    </Button>
                  )
                }
              >
                {groupChildren}
                {deviceChildren}
                {interfaceChildren}
              </TreeNode>
            );
          }

          if (
            filterText &&
            item.name &&
            !isParentNameMatchSearch &&
            !isCurrentNameMatchSearch
          ) {
            return null;
          }

          return (
            <TreeNode
              className="cus-label"
              id={item.id}
              type={item.type}
              isLeaf={
                item.type == entityTypes.STATIC ||  item.type == entityTypes.DYNAMIC ||  item.type == entityTypes.CONTAINER || item.type==entityTypes.ROOT || item.type==entityTypes.AUTOMATIC
                  ? false
                  : true
              }
              os_name={this.getValue(item, "os_name")}
              typeName={this.getValue(item, "typeName")}
              OSDescription={this.getValue(item, "OSDescription")}
              origin={this.getValue(item, "origin")}
              os_type={this.getValue(item, "os_type")}
              dedicated={this.getValue(item, "dedicated")}
              alias={this.getValue(item, "DisplayAlias")}
              displayName={this.getValue(item, "DisplayName")}
              masterElementGroup={item.masterElementGroup}
              key={keySub + item.id}
              nodeData={item}
              titlename={label}
              title={
                (item.type == entityTypes.STATIC ||  item.type == entityTypes.DYNAMIC ||  item.type == entityTypes.CONTAINER || item.type==entityTypes.ROOT || item.type==entityTypes.AUTOMATIC)
                &&
                (item.parentId == -1 || item.parentId == null) ?  (
                    //Root node
                    <Button fullWidth style={{justifyContent:'start'}} variant="text" component="div" 
                      className="tree-item"
                      aria-label={SELECT+" "+label}
                      // onMouseEnter={() =>
                      //   this.handleOnMouseEnter(keySub + item.id)
                      // }
                      role="group"
                      // onMouseLeave={this.handleOnMouseLeave}
                    >
                    <Tooltip title={label}>
                      <div style={{ display: "flex",width:'100%' }}>
                        {circle}
                        <TreeIcons
                          type={item.type}
                          class="grid__icon"
                          name={label}
                          level={item.state == -1 ? 0 : item.state}
                        />
                        <p style={{ margin: "0", color: "#3d424a" }}>{label}</p>
                      </div>
                      </Tooltip>
                    
                        {(canModifyGroup || canAddGroup ) && <div style={{marginLeft:'auto'}} > <Tooltip title={"Add Group"} arrow>
                          <IconButton
                            style={{ padding: "2px" ,margin:'2px'}}
                            onClick={(e) => this.handleAddGroupOpen(e)}
                            role="button"
                            name="Add Group"
                            aria-label="Add Group"
                          >
                            <AddGroupIcon />
                          </IconButton></Tooltip>
                        </div>}
                     
                    </Button>
                  ) : keyToMap === 5 ? (
                  <Button fullWidth style={{justifyContent:'start'}} variant="text" component="div"  role="group"
                    className="tree-item"
                    aria-label={SELECT+" "+label}
                    // onMouseEnter={() =>
                    //   this.handleOnMouseEnter(keySub + item.id)
                    // }
                    // onMouseLeave={this.handleOnMouseLeave}
                  > <Tooltip title={label}>
                    <div style={{ color: "#3d424a",width:'100%'  }}>
                      {circle}
                      {item.maintanceActive && item.maintanceActive == true ? (
                        <MaintenanceIcon
                          className="nav-icon"
                          style={{
                            width: "20px",
                            height: "20px",
                            margin: 0,
                            paddingRight: "10px",
                          }}
                        />
                      ) : (
                        <TreeIcons
                          type={item.type}
                          class="grid__icon"
                          name={label}
                          level={keyToMap}
                        />
                      )}
                      {label}
                    </div></Tooltip>
                    {/*this.state.selectedNode === keySub + item.id ||
                    this.state.onHoverTreeNode === keySub + item.id ? (*/}
                    { (item.type==="CONTAINER" || item.type === "DYNAMIC" || item.type === "STATIC" || item.type === "AUTOMATIC" ) && <div style={{marginLeft:'auto'}}><Tooltip title="More Actions">
                      <IconButton
                        style={{ padding: "2px" ,margin:'2px' }}
                        onClick={(e) => this.handleActionMenuOpen(e)}
                        // onBlur={() => this.handleOnMouseLeave()}
                        aria-label="Actions"
                        role="button"
                      >
                        <MoreVertIcon style={{ color: "#3272D9" }} />
                      </IconButton>
                      </Tooltip></div>}
                    {/*}) : null*/}
                  </Button>
                ) : keyToMap === 4 ? (
                  <Button fullWidth style={{justifyContent:'start'}} variant="text" component="div"  role="group"
                    className="tree-item"
                    aria-label={SELECT+" "+label}
                    // onMouseEnter={() =>
                    //   this.handleOnMouseEnter(keySub + item.id)
                    // }
                    // onMouseLeave={this.handleOnMouseLeave}
                  > <Tooltip title={label}>
                    <div style={{ color: "#3d424a",width:'100%'  }}>
                      {circle}
                      {item.maintanceActive && item.maintanceActive == true ? (
                        <MaintenanceIcon
                          className="nav-icon"
                          style={{
                            width: "20px",
                            height: "20px",
                            margin: 0,
                            paddingRight: "10px",
                          }}
                        />
                      ) : (
                        <TreeIcons
                          type={item.type}
                          class="grid__icon"
                          name={label}
                          level={keyToMap}
                        />
                      )}
                      {label}
                    </div></Tooltip>
                    {/*this.state.selectedNode === keySub + item.id ||
                    this.state.onHoverTreeNode === keySub + item.id ? (*/}
                    { (item.type==="CONTAINER" || item.type === "DYNAMIC" || item.type === "STATIC" || item.type === "AUTOMATIC" ) && <div style={{marginLeft:'auto'}}><Tooltip title="More Actions">
                      <IconButton
                        style={{ padding: "2px"  ,margin:'2px'}}
                        onClick={(e) => this.handleActionMenuOpen(e)}
                        // onBlur={() => this.handleOnMouseLeave()}
                        aria-label="Actions"
                        role="button"
                      >
                        <MoreVertIcon style={{ color: "#3272D9" }} />
                      </IconButton>
                      </Tooltip></div>}
                   {/* ) : null*/}
                  </Button>
                ) : keyToMap === 3 ? (
                  <Button fullWidth style={{justifyContent:'start'}} variant="text" component="div"  role="group"
                    className="tree-item"
                    aria-label={SELECT+" "+label}
                    // onMouseEnter={() =>
                    //   this.handleOnMouseEnter(keySub + item.id)
                    // }
                    // onMouseLeave={this.handleOnMouseLeave}
                  > <Tooltip title={label}>
                    <div style={{ color: "#3d424a",width:'100%'  }}>
                      {circle}
                      {item.maintanceActive && item.maintanceActive == true ? (
                        <MaintenanceIcon
                          className="nav-icon"
                          style={{
                            width: "20px",
                            height: "20px",
                            margin: 0,
                            paddingRight: "10px",
                          }}
                        />
                      ) : (
                         <TreeIcons
                          type={item.type}
                          class="grid__icon"
                          name={label}
                          level={keyToMap}
                        />
                      )}
                      {label}
                    </div>
                    </Tooltip>
                    {/*this.state.selectedNode === keySub + item.id ||
                    this.state.onHoverTreeNode === keySub + item.id ? (*/}
                    { (item.type==="CONTAINER" || item.type === "DYNAMIC" || item.type === "STATIC" || item.type === "AUTOMATIC" ) && <div style={{marginLeft:'auto'}}><Tooltip title="More Actions">
                      <IconButton
                        style={{ padding: "2px"  ,margin:'2px'}}
                        onClick={(e) => this.handleActionMenuOpen(e)}
                        // onBlur={() => this.handleOnMouseLeave()}
                        aria-label="Actions"
                        role="button"
                      >
                        <MoreVertIcon style={{ color: "#3272D9" }} />
                      </IconButton>
                      </Tooltip></div>}
                    {/*) : null*/}
                  </Button>
                ) : keyToMap === 2 ? (
                  <Button fullWidth style={{justifyContent:'start'}} variant="text" component="div"  role="group"
                    className="tree-item"
                    aria-label={SELECT+" "+label}
                    // onMouseEnter={() =>
                    //   this.handleOnMouseEnter(keySub + item.id)
                    // }
                    // onMouseLeave={this.handleOnMouseLeave}
                  > <Tooltip title={label}>
                    <div style={{ color: "#3d424a",width:'100%'  }}>
                      {circle}
                      {item.maintanceActive && item.maintanceActive == true ? (
                        <MaintenanceIcon
                          className="nav-icon"
                          style={{
                            width: "20px",
                            height: "20px",
                            margin: 0,
                            paddingRight: "10px",
                          }}
                        />
                      ) : (
                         <TreeIcons
                          type={item.type}
                          class="grid__icon"
                          name={label}
                          level={keyToMap}
                        />
                      )}
                      {label}
                    </div>
                    </Tooltip>
                    {/*this.state.selectedNode === keySub + item.id ||
                    this.state.onHoverTreeNode === keySub + item.id ? (*/}
                    { (item.type==="CONTAINER" || item.type === "DYNAMIC" || item.type === "STATIC" || item.type === "AUTOMATIC" ) && <div style={{marginLeft:'auto'}}><Tooltip title="More Actions">
                      <IconButton
                        style={{ padding: "2px"  ,margin:'2px'}}
                        onClick={(e) => this.handleActionMenuOpen(e)}
                        // onBlur={() => this.handleOnMouseLeave()}
                        aria-label="Actions"
                        role="button"
                      >
                        <MoreVertIcon style={{ color: "#3272D9" }} />
                      </IconButton>
                      </Tooltip></div>}
                    {/*}) : null*/}
                  </Button>
                ) : keyToMap == 1 ? (
                  <Button fullWidth style={{justifyContent:'start'}} variant="text" component="div"  role="group"
                    className="tree-item"
                    aria-label={SELECT+" "+label}
                    // onMouseEnter={() =>
                    //   this.handleOnMouseEnter(keySub + item.id)
                    // }
                    // onMouseLeave={this.handleOnMouseLeave}
                  > <Tooltip title={label}>
                    <div style={{ color: "#3d424a",width:'100%'  }}>
                      {circle}
                      {item.maintanceActive && item.maintanceActive == true ? (
                        <MaintenanceIcon
                          className="nav-icon"
                          style={{
                            width: "20px",
                            height: "20px",
                            margin: 0,
                            paddingRight: "10px",
                          }}
                        />
                      ) : (
                        <TreeIcons
                          type={item.type}
                          class="grid__icon"
                          name={label}
                          level={keyToMap}
                        />
                      )}
                      {label}
                    </div>
                    </Tooltip>
                    {/*this.state.selectedNode === keySub + item.id ||
                    this.state.onHoverTreeNode === keySub + item.id ? (*/}
                    { (item.type==="CONTAINER" || item.type === "DYNAMIC" || item.type === "STATIC" || item.type === "AUTOMATIC" ) && <div style={{marginLeft:'auto'}}><Tooltip title="More Actions">
                      <IconButton
                        style={{ padding: "2px"  ,margin:'2px'}}
                        onClick={(e) => this.handleActionMenuOpen(e)}
                        // onBlur={() => this.handleOnMouseLeave()}
                        aria-label="Actions"
                        role="button"
                      >
                        <MoreVertIcon style={{ color: "#3272D9" }} />
                      </IconButton>
                      </Tooltip></div>}
                    {/*}) : null*/}
                  </Button>
                ) : (
                  <Button fullWidth style={{justifyContent:'start'}} variant="text" component="div"  role="group"
                    className="tree-item"
                    aria-label={SELECT+" "+label}
                    // onMouseEnter={() =>
                    //   this.handleOnMouseEnter(keySub + item.id)
                    // }
                    // onMouseLeave={this.handleOnMouseLeave}
                  > <Tooltip title={label}>
                    <div style={{ color: "#3d424a",width:'100%'  }}>
                      {circle}
                      {item.maintanceActive && item.maintanceActive == true ? (
                        <MaintenanceIcon
                          className="nav-icon"
                          style={{
                            width: "20px",
                            height: "20px",
                            margin: 0,
                            paddingRight: "10px",
                          }}
                        />
                      ) : (
                        <TreeIcons
                          type={item.type}
                          class="grid__icon"
                          name={label}
                          level={0}
                        />
                      )}
                     {label}
                    </div>
                    </Tooltip>
                    {/*this.state.selectedNode === keySub + item.id ||
                    this.state.onHoverTreeNode === keySub + item.id ? (*/}
                    { (item.type==="CONTAINER" || item.type === "DYNAMIC" || item.type === "STATIC" || item.type === "AUTOMATIC" ) && <div style={{marginLeft:'auto'}}><Tooltip title="More Actions">
                      <IconButton
                        style={{ padding: "2px"  ,margin:'2px'}}
                        onClick={(e) => this.handleActionMenuOpen(e)}
                        // onBlur={() => this.handleOnMouseLeave()}
                        aria-label="Actions"
                        role="button"
                      >
                        <MoreVertIcon style={{ color: "#3272D9" }} />
                      </IconButton>
                      </Tooltip></div>}
                    {/*}) : null*/}
                  </Button>
                )
              }
            />
          );
        })
      );
    };

    let currentSearchString = filterText.trim();
    if (
      currentSearchString.length === 0 &&
      previousSearchString !== currentSearchString
    ) {
      this.setState({
        expandedKeys: [],
      });
    }

    if (
      currentSearchString.length > 0 &&
      previousSearchString !== currentSearchString
    ) {
      this.setState({
        expandedKeys: searchExpandedKeys,
      });
    }

    let autoExpandParent = this.state.autoExpandParent;

    let treeNodes;
    let countData = this.props.countData;
    treeNodes = loop([this.state.treeData], countData);
    previousSearchString = currentSearchString;
    expandedSearch(treeNodes);

    const searchTextFieldStyles = {
      display: "flex",
      justifyContent: "center",
      padding: "8px 0 8px 19px",
      flex: "1 1 auto",
    };
    const searchInputPropsStyle = { height: "32px" };
    const searchIconStyles = {
      color: "#3272D9",
      width: "16px",
      height: "16px",
    };
    previousSearchString = currentSearchString;
    let view = (
      <>
        <div
          style={{
            // padding: "0px 45px 0px 0px",
            display: "flex",
            alignItems: "center",
          }}
        >
          {/* <TextField
            name="filterGroupNew"
            //Search Group
            label={Resource.get("Search Group")}

            fullWidth={true}
            onChange={this.onChange}
          /> */}
          <SearchGroup
            placeholder={"Search by Group name"}
            handleSearch={this.onChange}
            textFieldStyles={searchTextFieldStyles}
            inputPropsStyle={searchInputPropsStyle}
            searchIconStyles={searchIconStyles}
          />
          {this.props.openTree && (
            <Tooltip title="Collapse Tree" arrow>
              <IconButton
                onClick={this.props.collapseTreeView}
                style={{
                  // position: "absolute",
                  // top: 0,
                  // right: 0,
                  // cursor: "pointer",
                  // zIndex: "1",
                  marginLeft: "10px",
                  marginRight: "2px",
                 
                }}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
        <Snackbar
          open={this.state.openSnackBar}
          message={this.state.groupErrMsg}
          autoHideDuration={4000}
          onClose={this.handleCloseSnackBar}
        />
        <div className={this.state.showWhiteBg && "white-bg"}>
          <div
            className={
              // this.state.open
              this.props.openTree
                ? "treeView_parent_div_after_collapse"
                : "treeView_parent_div_before_collapse"
            }
           // style={{ width: "calc(100% - 29px)" }}
          >
            {this.state.treeData && this.state.treeData.children ? (
              <Tree
                onExpand={this.onExpand}
                expandedKeys={this.state.expandedKeys}
                autoExpandParent={autoExpandParent}
                defaultExpandParent={true}
                onSelect={this.onSelect}
                selectable={true}
                loadData={this.onLoadData}
                selectedKeys={this.state.selectedKeys}
                showIcon={false}
                {...iconProps}
              >
                {treeNodes}
              </Tree>
            ) : (
              <div style={{ marginLeft: 30, marginTop: 50 }}>{Loading}</div>
            )}
            
            {treeNodes[0] ? null : (
              <div style={{ textAlign: "center", marginTop: "20px" }}>
                No groups
              </div>
            )}
            
          </div>
        </div>
        <TreeViewActionMenu
          anchorEl={this.state.anchorTreeActionMenuEl}
          handleActionMenuClose={this.handleActionMenuClose}
        />
      </>
    );

    return view;
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateTreeNode: (selectedNode) => {
      return dispatch(setTreeNode(selectedNode));
    },
    getGroup: (id) => dispatch(requestGroup({ id: id })),
    toggleAddGroupDialog: (isToggle) =>
      dispatch(toggleAddGroupDialog(isToggle)),
  };
};

const mapStateToProps = (state) => {
  return {
    sid: state.sid,
    treeNodeCount: state.treeNodeCount,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TreeLayout));