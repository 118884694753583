import * as React from 'react';
import { SvgIcon } from '@mineral/core';

export const OutlinedStar05 = React.forwardRef(
  (props, ref) => (
    <SvgIcon data-testid="LearnIcon" viewBox="0 0 40 40" {...props} ref={ref}>
        <path d="M24.2066 31.0161L24.4898 30.8393L24.815 30.764L29.6465 29.6465L30.764 24.815L30.8393 24.4898L31.0161 24.2066L33.6422 20L31.0161 15.7934L30.8393 15.5102L30.764 15.185L29.6465 10.3535L24.815 9.23598L24.4898 9.16074L24.2066 8.98394L20 6.35776L15.7934 8.98394L15.5102 9.16074L15.185 9.23598L10.3535 10.3535L9.23598 15.185L9.16074 15.5102L8.98394 15.7934L6.35776 20L8.98394 24.2066L9.16074 24.4898L9.23598 24.815L10.3535 29.6465L15.185 30.764L15.5102 30.8393L15.7934 31.0161L20 33.6422L24.2066 31.0161Z" fill={props.color} stroke="#1D5BBF" stroke-width="4"/>

    </SvgIcon>
  )
);
