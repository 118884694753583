// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Highest Alarm Severity Level and Total Alarm Count
  content["Highest Alarm Severity Level and Total Alarm Count"] =
  "最も高いアラーム重大度レベルおよび合計アラーム数";

  //Max Alarm Severity
  content["Max Alarm Severity"] =
  "最大アラーム重大度";

  //Name
  content["Name"] =
  "名前";

  //Elements
  content["Elements"] =
  "エレメント数";

  //Device Type
  content["Device Type"] =
  "デバイス タイプ";

  //Operating system
  content["Operating system"] =
  "オペレーティング システム";

  //IP address
  content["IP address"] =
  "IP アドレス";

  //Monitored by
  content["Monitored by"] =
  "モニタ元";

  //Has Robot
  content["Has Robot"] =
  "ロボットあり";

  //Discovered
  content["Discovered"] =
  "検出時間";

  //No Data Found
  content["No Data Found"] =
  "データが見つかりませんでした";

  //Try different filter options
  content["Try different filter options"] =
  "別のフィルタ オプションを試行";

  // No results found
  content["No results found"] =
  "結果が見つかりません";

  // No devices have been added
  content["No devices have been added"] =
  "デバイスが追加されていません";

  //No subgroups have been added
  content["No subgroups have been added"] =
  "サブグループが追加されていません";

  //No profiles in  this group
  content["No profiles in this group"] =
  "このグループのプロファイルがありません";

  //No devices found
  content["No devices found"] =
  "デバイスが見つかりません";

  //No devices have been added yet.
  content["No devices have been added yet."] =
  "まだデバイスが追加されていません。";

  // END OF LOCALIZATION

export default content;
