import React from 'react'
import './icons.less'

const Icon = (props) => {
  let classes = [
    'icon',
    props.className,
  ].join(' '),
  height = props.height || 24,
  width = props.width || 24,
  fillVal = props.fill || "#000000";
  return (
    <svg className={classes} role={props.role || 'figure'} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
      <g>
        <title>{props.title}</title>
        <polygon style={{fill:fillVal}} fillRule="evenodd" points="3 2 21 2 21 2 21 2 21 4 20.92 4 14 10.92 14 22.91 10 18.91 10 10.91 3.09 4 3 4"/>
      </g>
    </svg>
  )
}

export default Icon
