// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //debian based systems message
  content["Only 64 bit package is allowed for debian based systems."] =
  "Debian ベースのシステムでは 64 ビット パッケージのみ許可されています。";

  //Sudo Password is only applicable for Linux Systems
  content["Sudo Password is only applicable for Linux systems where username is not \'root\'."] =
  "sudo パスワードは、ユーザ名が \'root\ ではない Linux システムにのみ適用できます。";

  //Windows Relay Hub Required
  content["Windows Relay Hub Required."] =
  "Windows のリレー ハブが必要です。";

  //IP Address Required
  content["IP Address Required"] =
  "IP アドレスが必要";

  //Remote monitoring (also known as agentless monitoring) requires a monitoring host. The monitoring host is responsible for collecting data from the remote device that you are monitoring.
  content["Remote monitoring (also known as agentless monitoring) requires a monitoring host. The monitoring host is responsible for collecting data from the remote device that you are monitoring."] =
  "リモート モニタリング (「エージェントレス モニタリング」とも言う) には、モニタリング ホストが必要です。モニタリング ホストは、モニタしているリモート デバイスからのデータの収集を担当します。";

  //Monitoring host server
  content["Monitoring host server"] =
  "モニタリング ホスト サーバ";

  //host
  content["host"] =
  "ホスト";

  //Deploy a monitoring
  content["Deploy a monitoring"] =
  "モニタリングの展開";

  //Recommended minimum system requirements
  content["Recommended minimum system requirements"] =
  "推奨される最小システム要件";

  //Home
  content["Home"] =
  "ホーム";

  //Docker engine message
  content["Docker engine"] =
  "Docker エンジン";

  //agent message
  content["agent"] =
  "エージェント";

  //Docker monitoring requires an agent (aka Robot) to be installed on the Docker Engine server.
  content["Docker monitoring requires an agent (aka Robot) to be installed on the Docker Engine server."] =
  "Docker モニタリングでは、エージェント (「ロボット」とも言います) が Docker エンジン サーバにインストールされる必要があります。";

  //IP address
  content["IP address"] =
  "IP アドレス";

  // Operating systems message
  content["Operating system"] =
  "オペレーティング システム";

  //Architecture
  content["Architecture"] =
  "アーキテクチャ";

  //64-bit
  content["64-bit"] =
  "64 ビット";

  //32-bit
  content["32-bit"] =
  "32 ビット";

  // System Credentials
  content["System Credentials"] =
  "システム認証情報";

  //Username
  content["Username"] =
  "ユーザ名";

  //Password
  content["Password"] =
  "パスワード";

  //Sudo Password (Linux only)
  content["Sudo Password (Linux only)"] =
  "sudo パスワード (Linux のみ)";

  //Instructions
  content["Instructions"] =
  "手順";

  //4 GB of available memory
  content["4 GB of available memory"] =
  "4 GB の利用可能なメモリ";

  //2 or more CPUs
  content["2 or more CPUs"] =
  "2 つ以上の CPU";

  //10 GB of available disk space
  content["10 GB of available disk space"] =
  "10 GB の利用可能なディスク容量";

  //Ports 48000 to 48025 open
  content["Ports 48000 to 48025 open"] =
  "ポート 48000 から 48025 までオープン";

  // END OF LOCALIZATION

export default content;
