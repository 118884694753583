import React from "react";

const InventoryInfoIcon = ({ title }) => {
  return (
    <svg
      width="20"
      height="20"
      // style={{width: 20, height: 20}}
      className="grid__icon"
      viewBox="0 -5 24 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      title={title.toString()}
    >
      <g clip-path="url(#clip0_2680:82206)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 0C18.6246 0 24 5.37543 24 12C24 18.6246 18.6246 24 12 24C5.37543 24 0 18.6246 0 12C0 5.37543 5.37543 0 12 0Z"
          fill="white"
        />
        <path
          d="M12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12C2 6.48 6.48 2 12 2ZM12 4C7.58457 4 4 7.58457 4 12C4 16.4154 7.58457 20 12 20C16.4154 20 20 16.4154 20 12C20 7.58457 16.4154 4 12 4ZM13.12 10.5V18.17H10.87V10.5H13.12ZM12 5.49317C12.8284 5.49317 13.5 6.16474 13.5 6.99317C13.5 7.82159 12.8284 8.49317 12 8.49317C11.1716 8.49317 10.5 7.82159 10.5 6.99317C10.5 6.16474 11.1716 5.49317 12 5.49317Z"
          fill="#7F8E9D"
        />
      </g>
      <defs>
        <clipPath id="clip0_2680:82206">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default InventoryInfoIcon;
