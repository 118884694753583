import { call, put, takeEvery, takeLatest, take, cancel } from 'redux-saga/effects'
import { delay } from 'redux-saga/effects'
import { GET_UNIX_CREDENTIALS,POLL_UNIX_START,POLL_UNIX_STOP } from './actionTypes'
import { fetchUNIXSuccess, fetchUNIXFailure } from './actions'
import credentials from './unixCredentials';

// worker Saga: will be fired on GET_WMI_CREDENTIALS actions


function* fetchUNIXCreds() {
	  try {
	    const response = yield call(credentials.getUNIX)
	    yield put(fetchUNIXSuccess(response.data))
	  } catch (error) {
	    yield put(fetchUNIXFailure(error))
	  }
	}

	function* pollUNIXCreds() {
	  while (true) {
	    yield delay(30000)
	    yield call(fetchUNIXCreds)
	  }
	}

/*
  Starts fetchWMICreds on each dispatched `GET_WMI_CREDENTIALS` action.
  Allows concurrent fetches of user.
*/

export function* getUnixSaga() {
 yield takeEvery(GET_UNIX_CREDENTIALS, fetchUNIXCreds)
}

export function* unixPollingSaga() {
	  while (true) {
	    const watcherInstance = yield takeLatest(POLL_UNIX_START, pollUNIXCreds)
	    // cancel polling on POLL_HUBS_STOP
	    yield take(POLL_UNIX_STOP)
	    yield cancel(watcherInstance)
	  }
	}
