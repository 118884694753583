import React from 'react'
import IntegrationCard from '../integrations/IntegrationCard'
import ArrowIcon from './../ArrowIcon'
import Resource from './Resource'
import {Tooltip} from '@mineral/core';

export default class AdminConsoleCard extends IntegrationCard {
  getIcon() {
    return <ArrowIcon />
  }

  getSubLabelTwo = () => {
    return this.props.subLabelTwo
  }
}

const TextLogo = ({ label }) => {
  return (
    <Tooltip title={label}>
      <span className="settings-text-logo admin__console--card">{label}</span>
    </Tooltip>  
  )
}




AdminConsoleCard.defaultProps = {
  logo: <TextLogo 
  //Relay hubs & robots
  label={Resource.get("Relay hubs & robots")} />,
		//Managed via 
  subLabelOne:  Resource.get('Managed via'),
//Admin Console
  subLabelTwo:  Resource.get('Admin Console'),
  configRoute: '/settings/admin-console',
}
