import config from './../config'
import axios from 'axios'

class Configuration {
  get() {

    let configUrl= process.env.NODE_ENV=='development'?"http://10.252.25.208:8080/castlemock/mock/rest/project/6bM7nh/application/CENxVX/config"
    :`${config.basename}/api/v1/config`
    return axios
      .get(configUrl, {
        params: { reload: true },
      })
      .then(response => {
        // return true data types - not just strings
        const data = Object.keys(response.data).reduce((data, key) => {
          let value = response.data[key]
          if (value === 'false') {
            value = false
          } else if (value === 'true') {
            value = true
          }
          data[key] = value
          return data
        }, {})
        return Object.assign({}, response, { data })
      })
  }
}

let configuration = new Configuration()
export default configuration
