import React from 'react';
import { Close as CloseIcon } from '@material-ui/icons';
import { IconButton, Tooltip } from '@mineral/core';

export const Header = ({ children, selectedDevices, allDisabled, handleClose }) => {
  return (
    <div className="update-devices-header">
      <div className="update-devices-header-title">
        <h1 className="update-devices-header-title__name">{children}</h1>
        <div className="update-devices-header-title__line" />
        <div className="update-devices-header-title__count">
          {`${selectedDevices} Selected`}
        </div>
      </div>
      <Tooltip title="Close">
        <IconButton
autoFocus
         aria-label="Close"
         // disabled={allDisabled}
          onClick={handleClose}
        >
          <CloseIcon fontSize="large"/>
        </IconButton>
      </Tooltip>
    </div>
  );
};
