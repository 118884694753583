import {
  COLUMNS_SET,
} from './actionTypes'


const initialState = null

const columns = (state=initialState, action) => {
  switch(action.type) {
    case COLUMNS_SET:
      return action.columns
    default:
      return state
  }
}

export default columns
