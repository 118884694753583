// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  // Settings
  content["Settings"] =
  "Settings";

  //Report Schedules
  content["Report Schedules"] =
  "Report Schedules";

  //View and manage report schedules
  content["View and manage report schedules"] =
  "View and manage report schedules";

  // END OF LOCALIZATION

export default content;
