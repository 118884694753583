import React from 'react'
import {
  Card,
  CardHeader,
  CardMedia,Button
} from '@mineral/core'
import {DashboardsIcon} from '../ui-components/uim-components'
import { makeStyles } from '@material-ui/core/styles';

import { Link } from 'react-router-dom'
import theme from '../../theme'
import '../setupWizard/setupWizardCard.less'
const useStyles = makeStyles({
  overlayContentStyle : {
    background: theme.palette.lightBlack,
    height: '2rem',
    paddingTop: '5px',
    paddingBottom: '5px',
    top: '208px'
    
  },
  titleStyle : {
    fontSize: '1rem',
    fontWeight: 'normal',
    lineHeight: '1.5rem',
    color: theme.palette.fullWhite
  },
  cardTitleContainerStyle :{
    display: 'flex',
    
    background: theme.palette.lightBlack,
    height: '2rem',
   
    alignItems:'left'
  },
  cardTitleStyle : {
    padding: '0px !important',
  },
  cardMediaStyle:{
    display:'flex',
    width:"300px",
    height:'208px'
  }
  
});

const DashCard = (props) => {
  const classes = useStyles();
  const cardStyle = {
    height: '15rem',
    width: '18.75rem'
  }
  
  const cardTitleLableStyle = {
    color:'#FFFFFF',
  
  fontSize:'16px',
  lineHeight:'24px',
  fontWeight:'400'
  }
  /**
  * Note: We set an id on the card to enable automated Selenium testing
  */
  return (
    <Link to={props.linkTo} tabIndex={-1} style={{textDecoration:'none', marginBottom: '1rem', marginRight: '1rem'}}>
    <Button variant="text">
    <Card style={cardStyle} id={props.id} >
    <CardMedia classes={{root:classes.cardMediaStyle}} style={{position: "relative"}}>
    <img src={props.image} role='img' width="100%" alt={props.nameOnImage ? props.nameOnImage : props.title} />
    {props.nameOnImage && 
      <div className='restmon-probeName' title={props.nameOnImage} style={{width:'75%',maxWidth:'none',minWidth:'none',zIndex:'3'}}>{props.nameOnImage}</div>
    }
    </CardMedia>
    <CardHeader disableTypography={true}
    classes={{
      root:classes.cardTitleStyle,
      title:classes.titleStyle,
      content:classes.cardTitleContainerStyle
      
    }}
    title={
      <div style={{display:"flex"}}>
      <DashboardsIcon
      className='dashboard-title__icon'
      title={props.title} />
      <div style={cardTitleLableStyle}>
      {props.title}
      </div>
      </div>
    }
    />
    </Card>
    </Button>
    </Link>
    )
  }
  
  export default DashCard