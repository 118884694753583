import React from 'react';
import { Button, Tooltip } from '@mineral/core';

export const Buttons = (props) => {
  const { disabled, allDisabled, handleClose, handleSubmit } = props;

  const buttons = [
    {
      name: 'Cancel',
      variant: 'outlined',
      disabled: allDisabled,
      onClick: handleClose,
    },
    {
      name: 'Update',
      variant: 'contained',
      disabled: disabled || allDisabled,
      onClick: handleSubmit,
    },
  ];

  return (
    <div className="update-devices__buttons">
      {buttons.map(({ name, variant, disabled, onClick }) => (
        <Tooltip key={name} title={name} placement="top">
          <span>
            <Button variant={variant} disabled={disabled} onClick={onClick}>
              {name}
            </Button>
          </span>
        </Tooltip>
      ))}
    </div>
  );
};
