import React, {Component} from 'react'
import PropTypes from 'prop-types';
import Resource from './Resource'
import "./inventoryRobotDeployment.less"
import {
  NavLink,
  Route,
} from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import UtilPoller from '../../utils/poller'
import theme from '../../theme'
import get from 'lodash/get'
import {
    Table,
    TableBody,
    TableHead,
    TableCell,
    TableRow,
    Dialog,
   Button as FlatButton,    
    Button as RaisedButton,
    DialogActions,
    DialogTitle,
    DialogContent,IconButton
  } from "@mineral/core";
import CloseIcon from '@material-ui/icons/Close'
import { Typography } from '@mineral/core';

const STATUS = {
  SUCCEEDED: 'SUCCEEDED',
  FAILED: 'FAILED',
  IN_PROGRESS: 'IN_PROGRESS',
  TRUE:true,
  FALSE:false
}

const CustomLink = ({to, isActive, children, adeRobotAddress, adeJobIds, ...rest}) => {
  const href = `/robot-deploy?adeRobotAddress=${adeRobotAddress}&adeJobIds=${adeJobIds}`
  const style = isActive() ? {color: theme.palette.primary1Color} : {
    cursor: 'not-allowed',
    opacity: '0.12',
  }
  //FIX ISSUES
  const helplabelmessage = Resource.get('FIX ISSUES')
  return (
    <Route children={({history}) => (
      <NavLink
        to={to}
        isActive={isActive}
        onClick={(e) => {
          e.preventDefault()
          if (isActive()) {
            history.push(href)
          }
        }}
        style={style}
        {...rest}
        tabIndex="0"
        >
          {helplabelmessage}
        </NavLink>
    )} />
  )
}

class InventoryRobotsDeploymentStatus extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired
  }
  constructor(props) {
    super(props);
    this.state = {
    succeeded: this.props.succeeded || 0,
    failed: this.props.failed || 0,
    pending: this.props.pending || 0,
    probe: this.props.probe || {},
    open:this.props.open,
    showDialog:false
  }
}
  _isCallBackEnabled(){
	  if(this.props.probe && this.props.probe.name){
		  const probe =this.props.probe,
		  callbackProbeList=this.props.featureFlags.callbackProbeList?this.props.featureFlags.callbackProbeList:'',
		  callbackEnabled = callbackProbeList.includes(probe.name);
		  return callbackEnabled;
		}
	  else if(this.props.fromSetupWizard && this.props.probesStatus && this.props.probesStatus.length > 0 ){
		  return true;
	  }
	  else{
		  return false;
	  }
  }
  _getRobotStatus(props) {
    const adeRobotAddress = props.adeRobotAddress
    const adeJobIds = props.adeJobIds
    const probe =props.probe
    const callbackEnabled = this._isCallBackEnabled();
    if (!adeRobotAddress && isEmpty(adeJobIds)) {
      console.warn('InventoryRobotsDeploymentStatus - unable to GET robots status without "adeRobotAddress" or "adeJobIds".')
      return this
    }
    if (props.getRobotsStatus) {
      props.getRobotsStatus(adeRobotAddress, adeJobIds,probe,callbackEnabled)
    }
    if (props.pollRobotsStatus) {
      if (this.poller) {
        this.poller.stop()
      }
      this.poller = UtilPoller(() => props.pollRobotsStatus(adeRobotAddress, adeJobIds,probe,callbackEnabled), {
        timeout: 15*1000,
      })
      this.poller.start()
    }
    return this
  }
  _getRobotCount = (robotsStatus,type)=>{
	    let cnt = 0;
	    robotsStatus.forEach(status => {
	      if (status.state === type) {
	          cnt++;
	      }
	    })

	    return cnt;
  }

  _setRobotsStatus = (robotsStatus, cb = () => {}) => {
    let succeeded = 0
    let failed = 0
    let pending = 0
    robotsStatus.forEach(status => {
      switch (status.state) {
        case STATUS.SUCCEEDED:
        	if(this._isCallBackEnabled()){
        		pending++;
        	}
        	else{
        		succeeded++
        	}
          break
        case STATUS.FAILED:
          failed++
          break
        case STATUS.IN_PROGRESS:
    	default:
    		pending++;
    	break;
      }
    })

    if(succeeded !== this.state.succeeded || failed !== this.state.failed || pending !== this.state.pending) {
      this.setState({succeeded, failed, pending}, cb)
    }
  }
  componentWillMount() {
    if(this.props.open){
    this._getRobotStatus(this.props)
    	if(!isEmpty(this.props.robotsStatus)) {
  	      this._setRobotsStatus(this.props.robotsStatus)
        }
      }
  }
  componentWillReceiveProps(nextProps) {
    const prevState = this.state
    const newAdeRobotAddress = nextProps.adeRobotAddress !== this.props.adeRobotAddress
    const newAdeJobIds = !isEqual(nextProps.adeJobIds, this.props.adeJobIds)
    if (newAdeRobotAddress || newAdeJobIds) {
      // new query params update GET/poll
      this._getRobotStatus(nextProps)
    }
    this._setRobotsStatus(nextProps.robotsStatus, () => {
	    this._update(prevState, nextProps.robotsStatus,false)
	 })

  }
  componentWillUnmount() {
    if (this.poller ) {
      this.poller.stop()
    }
  }
  _update = (prevState, statusArr,fromProbes) => {
    const state = this.state
    const noPendingRobots = !state.pending
    const newStatus = (
      state.succeeded !== prevState.succeeded ||
      state.failed !== prevState.failed ||
      state.pending !== prevState.pending
    )
    let succeeded=[],failed=[],robotsFailed=[],pending=[],data = this.props.robotsStatus;
        succeeded = statusArr.filter(status => status.state === STATUS.SUCCEEDED);
        failed = statusArr.filter(status => status.state === STATUS.FAILED);
        pending = statusArr.filter(status => status.state === STATUS.IN_PROGRESS);
        if(this._isCallBackEnabled()){
        	pending =  pending.concat(succeeded);
        	succeeded =[];
        }


    if (newStatus && this.props.onStatusChange) {
      this.props.onStatusChange({
        succeeded: succeeded,
        failed: failed,
        pending: pending
      })
    }

    if (newStatus && noPendingRobots && !isEmpty(this.props.robotsStatus) ) {
      // robot deployment is done stop polling
       if (this.poller) {
        this.poller.stop()
      }


    }
  }
  handleClose = () => {
    this.setState({ showDialog:false })
  }
  
  render() {
    // Host
    const HOST_NAME = Resource.get("Host")
    //Robot Address
      const ROBOT_ADDR = Resource.get("Robot Address")
    //Robot Status
    const ROBOT_STATUS= Resource.get("Robot Status")

    //Message
    const MSG= Resource.get("Message")
    //Robot Deployment Status
    let title = Resource.get("Robot Deployment Status") 
    // Status not available
    const STATUS_NOT_AVAILABLE = Resource.get("Status is not available, Please wait .... ")

    let robotStatusDataList =null;
    if(this.props.robotsStatus && this.props.robotsStatus.length > 0){
    robotStatusDataList = this.props.robotsStatus.map((item) => {
        return (
          <TableRow >
            <TableCell>{item.host}</TableCell>
            <TableCell>
            <span title={item.robotAddress}  > {item.robotAddress}
            </span>
              </TableCell>
            <TableCell>{item.state}</TableCell>
            <TableCell>
            <span title={item.errorMessage}  > {item.errorMessage}
            </span>
            </TableCell>
          
          </TableRow>
        )
      })
    }
    let robotStatusTable =(
      <Table 
      fixedHeader={true}
      fixedFooter={true}
      aria-label={title}
      role="table"
      tabIndex="0"
      >
        <TableHead
        displayRowCheckbox={false}
        displaySelectAll={false}
        adjustForCheckbox={false} >
          <TableRow>
            <TableCell style = {{fontWeight:"bold",fontSize:"14px"}}>{HOST_NAME}</TableCell>
            <TableCell style = {{fontWeight:"bold",fontSize:"14px"}}>{ROBOT_ADDR}</TableCell>
            <TableCell style = {{fontWeight:"bold",fontSize:"14px"}}>{ROBOT_STATUS}</TableCell>
            <TableCell style = {{fontWeight:"bold",fontSize:"14px"}}>{MSG}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody displayRowCheckbox={false}>
          
          {robotStatusDataList}

        </TableBody>
      </Table>
    )    
    const {
      succeeded,
      failed,
      pending,
    } = this.state
    		//device
    		  const helpvariable = Resource.get('device')
              //devices
              const helpvariable1 = Resource.get('devices')
              //LasrDeployment message
              const helplabelmessage = Resource.get('Last Deployment')
              	  //OK
	  const OkLabel = Resource.get('Ok');
      const actions = [
        <FlatButton 
          variant="contained" children= {OkLabel} color ="primary"
         
          onClick={this.handleClose} />
      ]
      let statusButton =(       
            <RaisedButton size="small" variant="outlined" style={{marginRight:'8px', minWidth:"fit-content", height:"32px"}} children ={'Robot Deployment Status'} 
                 onClick={ () => {this.setState({showDialog:true})}}/>);
       

    statusButton = this.props.open?statusButton:null;
      let robotDataDialog =(
        <Dialog
          /* contentStyle={{
              width: "fit-content",
              minWidth: "500px",
              maxWidth: "700px",
            }} */
          open={this.state.showDialog}
          onClose={this.handleClose}
          //autoScrollBodyContent={true}
          role="dialog"
          scroll="body"
         // className= "robotDialog"
         fullWidth={true}
        maxWidth={"lg"}
          title={title}
        >
          <DialogTitle  disableTypography component="div">  
           
                <Typography component="h1" variant='h3' >
                    {title}
                </Typography>
              
          
          </DialogTitle>
          <div style={{height:'0px'}}>
          <IconButton autoFocus style={{float:'right',top:'-50px',marginRight:'8px'}}
                aria-label="Close"
               
                onClick={this.handleClose}
                
                >
                    <CloseIcon />
                </IconButton>
          </div>
          <DialogContent role="dialog" style={{overflow: 'scroll'}}>
            {robotStatusDataList!==null?robotStatusTable:(
              <div>
              <Typography>{STATUS_NOT_AVAILABLE}</Typography>
            </div>
            )}
          </DialogContent>

    <DialogActions>
    {actions}
    </DialogActions>
    </Dialog>
      )
    return (

      < >
        {statusButton}
        {robotDataDialog}
      </>
    )
  }
}

InventoryRobotsDeploymentStatus.defaultProps = {
  fixLink: true,
}

export default InventoryRobotsDeploymentStatus
