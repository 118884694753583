// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //dashError
  content["Dashboard failed to load."] =
  "Dashboard failed to load.";

  //Systemcomponentmissing message
  content["System component missing"] =
  "System component missing";

  //missing component
  content["CA Business Intelligence (CABI) system component is needed."] =
  "CA Business Intelligence (CABI) system component is needed.";

  // Installation and configuration instructions
  content["Installation and configuration instructions"] =
  "Installation and configuration instructions";

  //Breadcrumb
  content["Dashboards"] =
  "Dashboards";

  //Breadcrumb
  content["Overview"] =
  "Overview";

  // END OF LOCALIZATION

export default content;
