import React, { Component } from 'react'
import { connect } from 'react-redux'
import values from 'lodash/values'

import Selection from './Selection'
import {
  fetchMetricTypes,
  selectAllMetricTypes,
} from '../../api/metricTypes/actions'

import { setColumns, } from '../columns/actions'


const mapStateToProps = (state, props) => {
  const types = values(state.types.groups)
  return {
    types,
    hide: state.selection.hide,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const {
    getMetricTypes,
  } = ownProps

  return {
    fetchMetricTypes: (contextValue) => {
      return dispatch(fetchMetricTypes(() => getMetricTypes(contextValue)))
    },
    onSelectAll: (contextValue, selected) => {
      return dispatch(selectAllMetricTypes(contextValue, selected))
    },
    setColumns: (n) => dispatch(setColumns(n)),
  }
}


class SelectionRedux extends Component {
  componentWillReceiveProps(nextProps) {
    if (this.props.contextValue !== nextProps.contextValue) {
      this.props.fetchMetricTypes(nextProps.contextValue)
    }
  }

  componentWillMount() {
    this.props.fetchMetricTypes(this.props.contextValue)
  }

  render() {
    return <Selection {...this.props} />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectionRedux)
