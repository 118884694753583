// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Inventory
  content["Inventory"] =
  "清单";

  //Refreshed
  content["Refreshed"] =
  "已刷新";

  //Results
  content["Results"] =
  "结果";

  // END OF LOCALIZATION

export default content;
