import React from 'react'
import {Link} from 'react-router-dom'
import '../../assets/css/index.less'
import './pageNotFound.less'
import Resource from './Resource'

const PageNotFound = (props) => {
    return (
      <div className='page-not-found' >
        <h2>{props.title}</h2>
        <div className='line-separator page-not-found__line-separator' ></div>
        <div className='page-not-found__message'>
          {props.message}
        </div>
        <div className='page-not-found__link-container'>
          <Link to='/' className='page-not-found__link'>{props.returnLink}</Link>
        </div>
      </div>
    )
}

PageNotFound.defaultProps ={
	//Page Not Found
  title : Resource.get('Page Not Found'),
  //The requested pag ecannot be found
  message: Resource.get('The requested page cannot be found'),
  //Return to Operator Console
  returnLink : Resource.get('Return to Operator Console'),
}
export default PageNotFound