import {
    FETCH_DASHBOARD_PATHS,
    FETCH_DASHBOARD_PATHS_SUCCESS,
    FETCH_DASHBOARD_PATHS_FAILURE,
    FETCH_DASHBOARD_ROUTE_MAP,
    FETCH_DASHBOARD_ROUTE_MAP_SUCCESS,
    FETCH_DASHBOARD_ROUTE_MAP_FAILURE
  } from './actionTypes'
  import dashboardPaths from './dashboardPaths'

export function fetchDashboardPathsSuccess(json) {
    return {
      type: FETCH_DASHBOARD_PATHS_SUCCESS,
      paths: json
    }
  }
  export function fetchDashboardPathsFailure(error) {
    return {
      type: FETCH_DASHBOARD_PATHS_FAILURE,
      errorMessage: error.message
    }
  }
  export function fetchDashboardPaths(params) {
    return dispatch => {
      dispatch({type: FETCH_DASHBOARD_PATHS})
  
      return dashboardPaths.get(params)
        .then(response => {
          dispatch(fetchDashboardPathsSuccess(response.data))
        })
        .catch(function (error) {
          dispatch(fetchDashboardPathsFailure(error))
        })
    }
  }

  export function fetchDashboardRouteMapSuccess(json) {
    return {
      type: FETCH_DASHBOARD_ROUTE_MAP_SUCCESS,
      routes: json
    }
  }
  export function fetchDashboardRouteMapFailure(error) {
    return {
      type: FETCH_DASHBOARD_ROUTE_MAP_FAILURE,
      errorMessage: error.message
    }
  }
  export function fetchDashboardRouteMap(params) {
    return dispatch => {
      dispatch({type: FETCH_DASHBOARD_ROUTE_MAP})
  
      return dashboardPaths.getDashboardRouteMap()
        .then(response => {
          dispatch(fetchDashboardRouteMapSuccess(response.data))
        })
        .catch(function (error) {
          dispatch(fetchDashboardRouteMapFailure(error))
        })
    }
  }