// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //No Errors
  content["No Errors"] =
  "No Errors";

  //Errors
  content["Errors"] =
  "Errors";

  // END OF LOCALIZATION

export default content;
