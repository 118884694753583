import entityTypes from '../entityTypes'

export const ROUTE_DASHBOARD_MAP = [
  // CORE DETAIL DASHBOARDS
  {
    opConId: 'csId',
    cabiId: 'cs_id',
    route: '/computer-systems/[csId]/cabi',
    path: '/public/ca/uim/dashboards/common/device_summary'
  },
  {
    opConId: 'groupId',
    cabiId: 'group_id',
    route: '/groups/0/[groupId]/list',
    path: '/public/ca/uim/dashboards/common/group_summary'
  },
  {
    opConId: 'groupId',
    cabiId: 'group_id',
    route: '/groups/0/[groupId]/list',
    path: '/public/ca/uim/dashboards/common/container_group_summary',
    entityType: entityTypes.CONTAINER
  },
  {
    opConId: 'probe',
    cabiId: 'prid',
    route: '/technologies/[probe]/cabi',
    path: '/public/ca/uim/dashboards/common/probe_detail'
  },
  // PROBE DASHBOARDS
  {
    idValue: 'aws',
    opConId: 'probe',
    cabiId: 'prid',
    route: '/technologies/[probe]/cabi',
    path: '/public/ca/uim/dashboards/aws/aws_dashboard'
  },
  {
    idValue: 'aws-service',
    opConId: 'probe',
    cabiId: 'prid',
    contextParams: ['service_name','localized_service_name'],
    route: '/technologies/[probe]/cabi',
    path: '/public/ca/uim/dashboards/aws/aws_service'
  },
  {
    idValue: 'aws-sprawl',
    opConId: 'probe',
    cabiId: 'prid',
    route: '/technologies/[probe]/cabi',
    path: '/public/ca/uim/dashboards/aws/aws_sprawl'
  },
  {
    idValue: 'aws-vpc',
    opConId: 'probe',
    cabiId: 'prid',
    route: '/technologies/[probe]/cabi',
    path: '/public/ca/uim/dashboards/aws/aws_vpc'
  },
  {
    idValue: 'azure',
    opConId: 'probe',
    cabiId: 'prid',
    route: '/technologies/[probe]/cabi',
    path: '/public/ca/uim/dashboards/azure/azure_dashboard'
  },
  {
    idValue: 'azure-service',
    opConId: 'probe',
    cabiId: 'prid',
    contextParams: ['service_name','localized_service_name'],
    route: '/technologies/[probe]/cabi',
    path: '/public/ca/uim/dashboards/azure/azure_service_dashboard'
  },
  {
    idValue: 'cpa',
    opConId: 'probe',
    cabiId: 'prid',
    route: '/technologies/[probe]/cabi',
    path: '/public/ca/uim/dashboards/cpa/cpa_overview'
  },
  {
    idValue: 'cpa-service',
    opConId: 'probe',
    cabiId: 'prid',
    route: '/technologies/[probe]/cabi',
    contextParams: ['metric_code'],
    path: '/public/ca/uim/dashboards/cpa/service_overview'
  },
  {
    idValue: 'docker_monitor',
    opConId: 'probe',
    cabiId: 'prid',
    route: '/technologies/[probe]/cabi',
    path: '/public/ca/uim/visualization/dashboards/docker/docker_summary'
  },
  {
    idValue: 'nutanix_monitor',
    opConId: 'probe',
    cabiId: 'prid',
    route: '/technologies/[probe]/cabi',
    path: '/public/ca/uim/visualization/dashboards/nutanix/nutanix_summary'
  },
  {
    idValue: 'nutanix_monitor-avgPerf',
    opConId: 'probe',
    cabiId: 'prid',
    route: '/technologies/[probe]/cabi',
    path: '/public/ca/uim/visualization/dashboards/nutanix/nutanix_average_performance'
  },
  {
    idValue: 'nutanix_monitor-clusters',
    opConId: 'probe',
    cabiId: 'prid',
    route: '/technologies/[probe]/cabi',
    path: '/public/ca/uim/visualization/dashboards/nutanix/nutanix_clusters_summary'
  },
  {
    idValue: 'nutanix_monitor-clustersPerf',
    opConId: 'probe',
    cabiId: 'prid',
    route: '/technologies/[probe]/cabi',
    path: '/public/ca/uim/visualization/dashboards/nutanix/nutanix_performance_by_cluster'
  },
  {
    idValue: 'nutanix_monitor-consumers',
    opConId: 'probe',
    cabiId: 'prid',
    route: '/technologies/[probe]/cabi',
    path: '/public/ca/uim/visualization/dashboards/nutanix/nutanix_top_consumers'
  },
  {
    idValue: 'nutanix_monitor-hostPerf',
    opConId: 'probe',
    cabiId: 'prid',
    route: '/technologies/[probe]/cabi',
    path: '/public/ca/uim/visualization/dashboards/nutanix/nutanix_host_performance'
  },
  {
    idValue: 'nutanix_monitor-poolPerf',
    opConId: 'probe',
    cabiId: 'prid',
    route: '/technologies/[probe]/cabi',
    path: '/public/ca/uim/visualization/dashboards/nutanix/nutanix_storagepools_performance'
  },
  {
    idValue: 'openstack',
    opConId: 'probe',
    cabiId: 'prid',
    route: '/technologies/[probe]/cabi',
    path: '/public/ca/uim/visualization/dashboards/openstack/openstack_summary'
  },
  {
    idValue: 'sap_basis',
    opConId: 'probe',
    cabiId: 'prid',
    route: '/technologies/[probe]/cabi',
    path: '/public/ca/uim/dashboards/sap/sap_monitoring_overview'
  },
  {
    idValue: 'vmware',
    opConId: 'probe',
    cabiId: 'prid',
    route: '/technologies/[probe]/cabi',
    path: '/public/ca/uim/dashboards/vmware/vmware_summary'
  },
  {
    idValue: "xendesktop",
    opConId: "probe",
    cabiId: "prid",
    route: "/technologies/[probe]/cabi",
    path: "/public/ca/uim/dashboards/citrix/xendesktop/xendesktop_summary"
  }
]
