// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Add
  content["Add"] =
  "Agregar";

  //Setup complete
  content["Setup complete"] =
  "Instalación completada";

  //ServiceNow Ticketing
  content["ServiceNow Ticketing"] =
  "Control de tickets de ServiceNow";

  //Settings
  content["Settings"] =
  "Configuración";

  //ServiceNow
  content["ServiceNow "] =
  "ServiceNow ";

  //Invalid format: ###-###-####
  content["Invalid format: ###-###-####"] =
  "El formato no es válido: ###-###-####";

  //CANCEL
  content["CANCEL"] =
  "CANCELAR";

  //DELETE
  content["DELETE"] =
  "SUPRIMIR";

  //This action removes the ServiceNow integration.
  content["This action removes the ServiceNow integration."] =
  "Esta acción eliminará de la integración de ServiceNow.";

  //Alarms will no longer be synchronized between CA Infrastructure Management and ServiceNow.
  content["Alarms will no longer be synchronized between CA Infrastructure Management and ServiceNow."] =
  "Las alarmas dejarán de sincronizarse entre CA Infrastructure Management y ServiceNow.";

  //Delete integration?
  content["Delete integration?"] =
  "¿Suprimir integración?";

  //URL
  content["URL"] =
  "URL";

  //User name
  content["User name"] =
  "Nombre de usuario";

  //Password
  content["Password"] =
  "Contraseña";

  //DefaultGroupName
  content["Default group name"] =
  "Nombre de grupo predeterminado";

  //Create new ServiceNow tickets when Infrastructure Management generates alarms with the following severities:
  content["Create new ServiceNow tickets when Infrastructure Management generates alarms with the following severities:"] =
  "Cree nuevos tickets de ServiceNow cuando Infrastructure Management genere las alarmas con las severidades siguientes:";

  //ServiceNow integration
  content["ServiceNow integration"] =
  "Integración de ServiceNow";

  //instruction msg
  content["This integration acts as a gateway between the Infrastructure Management and ServiceNow Incident Management system. The integration generates an incident ticket in the Service desk that is based on the Infrastructure Management alarm. Generating an incident helps the ServiceNow user to take corrective actions to resolve the issue. The integration periodically polls the service desk for the status of incidents and updates the corresponding alarms."] =
  "Esta integración funciona como una puerta de enlace entre Infrastructure Management y el sistema de gestión de incidentes de ServiceNow. La integración genera un ticket de incidente en ServiceDesk que se basa en la alarma de Infrastructure Management. La generación de un incidente ayuda al usuario de ServiceNow a realizar las acciones correctivas adecuadas para solucionar el problema. La integración periódicamente sondea Service Desk para saber el estado de los incidentes y actualizar las alarmas correspondientes.";

  //Enable Group Assignment
  content["Enable Group Assignment"] =
  "Activar asignación del grupo";

  //Auto close alarm
  content["Auto close alarm"] =
  "Alarma de cierre automático";

  //Auto close tickets
  content["Auto close tickets"] =
  "Tickets cerrados automáticamente";

  //Severity levels
  content["Severity levels"] =
  "Niveles de severidad";

  //Ticket on Severity Change
  content["Ticket on Severity Change"] =
  "Ticket en el caso de cambio de la severidad";

  //Communication Error
  content["Communication Error"] =
  "Error de comunicación";

  //Critical
  content["Critical"] =
  "Crítica";

  //Major
  content["Major"] =
  "Grave";

  //MInor
  content["Minor"] =
  "Leve";

  //Warning
  content["Warning"] =
  "Advertencia";

  //Info
  content["Info"] =
  "Información";

  //Ticket on severity chnage
  content["Ticket on severity change"] =
  "Ticket en el caso de cambio de la severidad";

  //Specify the base URL for the ServiceNow instance.The default format is: https://instancename.service-now.com.
  content["Specify the base URL for the ServiceNow instance.The default format is: https://instancename.service-now.com."] =
  "Especifique la dirección URL base para la instancia de ServiceNow. El formato predeterminado es: https://nombreinstancia.service-now.com.";

  //Enter the ServiceNow admin username.
  content["Enter the ServiceNow admin username."] =
  "Introduzca el nombre de usuario administrador de ServiceNow.";

  //Enter the ServiceNow admin password.
  content["Enter the ServiceNow admin password."] =
  "Introduzca la contraseña de administrador de ServiceNow.";

  //Toggle to activate assigning tickets and alarms to Groups in Infrastructure Manager.
  content["Toggle to activate assigning tickets and alarms to Groups in Infrastructure Manager."] =
  "Alterne la activación de la asignación de tickets y alarmas a grupos en el Gestor de la infraestructura.";

  //Specify the default Group to which ServiceNow tickets and alarms are assigned.
  content["Specify the default Group to which ServiceNow tickets and alarms are assigned."] =
  "Especifique el grupo predeterminado al que se asignarán los tickets y alarmas de ServiceNow.";

  //Select to configure alarms to close automatically.
  content["Select to configure alarms to close automatically."] =
  "Seleccione esta opción para configurar las alarmas para que se cierren automáticamente.";

  //Select to configure tickets to close automatically.
  content["Select to configure tickets to close automatically."] =
  "Seleccione esta opción para configurar los tickets para que se cierren automáticamente.";

  //Specify the severity of alarms in Infrastructure Manager which will create incidents in ServiceNow.
  content["Specify the severity of alarms in Infrastructure Manager which will create incidents in ServiceNow."] =
  "Especifique la severidad de las alarmas en el Gestor de la infraestructura que creará incidentes en ServiceNow.";

  // Ticket on Sev Change Message
  content["Select the option to create a ticket in ServiceNow when the severity of an existing ticket changes. If you do not select the option, only the severity of existing ticket changes."] =
  "Seleccione esta opción para crear un ticket en ServiceNow cuando cambie la severidad de un ticket existente. Si no se selecciona esta opción, únicamente se cambiará la severidad de los tickets existentes.";

  //SAVE
  content["SAVE"] =
  "GUARDAR";

  // END OF LOCALIZATION

export default content;
