// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Settings
  content["Settings"] =
  "Configuración";

  //Close Rule
  content["Close Rule"] =
  "Regla de cierre";

  ////1 hour
  content["1 hour"] =
  "1 hora";

  //12 hours
  content["12 hours"] =
  "12 horas";

  //24 hours
  content["24 hours"] =
  "24 horas";

  //3 days
  content["3 days"] =
  "3 días";

  //1 week
  content["1 week"] =
  "1 semana";

  //2 weeks
  content["2 weeks"] =
  "2 semanas";

  //1 month
  content["1 month"] =
  "1 mes";

  //3 months
  content["3 months"] =
  "3 meses";

  //Never
  content["Never"] =
  "Nunca";

  //Communication Error
  content["Communication Error"] =
  "Error de comunicación";

  // help message
  content["Automatically close non-threshold violating alarms (for example, system log events or server reboots) if they have been open more than..."] =
  "Cierra automáticamente todas las alarmas que no infringen el umbral (por ejemplo, los eventos de registro del sistema o los reinicios del servidor) si se han abierto hace más de:";

  // Close Rule help message
  content["And their severity is..."] =
  "Y su severidad es:";

  //Critical
  content["Critical"] =
  "Crítica";

  //Major
  content["Major"] =
  "Grave";

  //Minor
  content["Minor"] =
  "Leve";

  //Warning
  content["Warning"] =
  "Advertencia";

  //Info
  content["Info"] =
  "Información";

  //CANCEL
  content["CANCEL"] =
  "CANCELAR";

  //SAVE
  content["SAVE"] =
  "GUARDAR";

  // END OF LOCALIZATION

export default content;
