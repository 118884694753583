import React, { useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { Preloader } from './components/Preloader';
import { Header } from './components/Header';
import { Content } from './components/Content';
import { Buttons } from './components/Buttons';
import { removeRepeatDevices } from './utils/removeRepeatDevices';
import { transformItems } from './utils/transformItems';
import maintenance from '../../../../api/maintenance/maintenance';
import Resource from '../../Resource';

import './styles.less';

const UPDATE_DEVICES = Resource.get('Update Devices'); // Update Devices

export const UpdateDevices = (props) => {
  const { schedule, group, data, onUpdate, onFinish } = props;

  const [items, setItems] = useState([]);
  const [selectedDevices, setSelectedDevices] = useState(0);
  const [allDisabled, setAllDisabled] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(true);

  const finishLoading = debounce(setLoading, 1000);

  const handleClose = () => !allDisabled && onUpdate();

  const handleSubmit = () => {
    setAllDisabled(true);

    const { schedule_id, schedule_name } = data;

    const cb = (t, el) => {
      el.checked && t.push(el.csId);
      return t;
    };

    const csIdList = items.reduce(cb, []);

    const request = { schedule_id, schedule_name, cs_ids: csIdList };

    maintenance
      .saveMaintenanceSystems(request)
      .then(() => onFinish('success', schedule_name))
      .catch(() => onFinish('error'));
  };

  useEffect(() => {
    const cb = ({ code }) => code === 'Escape' && handleClose();

    window.addEventListener('keyup', cb);

    return () => {
      window.removeEventListener('keyup', cb);
    };
  }, []);

  useEffect(() => {
    const cb = (a, b) => a.name.localeCompare(b.name);

    const newItems = [
      ...transformItems(schedule, data.cs_ids),
      ...transformItems(group, data.cs_ids).sort(cb),
    ];

    finishLoading(false);
    setItems(removeRepeatDevices(newItems));
  }, [schedule, group, data.cs_ids]);

  useEffect(() => {
    setSelectedDevices(items.filter((el) => el.checked).length);
  }, [items]);

  const headerProps = { selectedDevices, allDisabled, handleClose };
  const contentProps = { items, allDisabled, setItems, setDisabled };
  const buttonsProps = { disabled, allDisabled, handleClose, handleSubmit };

  if (loading) return <Preloader />;

  return (
    <div className="update-devices">
      <Header {...headerProps}>{UPDATE_DEVICES}</Header>
      <Content {...contentProps} />
      <Buttons {...buttonsProps} />
    </div>
  );
};
