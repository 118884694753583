import * as React from 'react';
import { SvgIcon } from '@mineral/core';

export const FilledStar02 = React.forwardRef(
  (props, ref) => (
    <SvgIcon viewBox="0 0 40 40" {...props} ref={ref}>
      <path d="M20 4L24.3215 15.6785L36 20L24.3215 24.3215L20 36L15.6785 24.3215L4 20L15.6785 15.6785L20 4Z" fill={props.color} />
    </SvgIcon>
  )
);

export default FilledStar02;