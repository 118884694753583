import React, { Component } from "react";
import ReactDOM from "react-dom";
import {Tooltip} from "@mineral/core";
export default class CustomTooltip extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      displayTooltip: false,
    };
    this.hideTooltip = this.hideTooltip.bind(this);
    this.showTooltip = this.showTooltip.bind(this);
  }

  hideTooltip() {
    this.setState({ displayTooltip: false });
  }
  showTooltip() {
    let obj = ReactDOM.findDOMNode(
      this.refs["UniqueElementIdentifier"]
    ).getBoundingClientRect();
    let targetElementStyle = {};
    for (let x in obj) {
      targetElementStyle = { ...targetElementStyle, [x]: obj[x] + "px" };
    }
    targetElementStyle = {
      ...targetElementStyle,
      height: "20px",
      textAlign: "center",
    };
    this.setState({
      displayTooltip: true,
      targetElementStyle: targetElementStyle,
    });
  }

  render() {
    let message = this.props.message;

    return (
      <span
        ref="UniqueElementIdentifier"
        onMouseLeave={this.hideTooltip}
        onMouseOver={this.showTooltip}
      >
        {/* <Tooltip style={this.state.targetElementStyle} label={message} show={this.state.displayTooltip}></Tooltip> */}
        <Tooltip
          style={this.state.targetElementStyle}
          title={message}
          open={this.state.displayTooltip}
        >
        <span>{message}</span>
        </Tooltip>
        {this.props.children}
      </span>
    );
  }
}
