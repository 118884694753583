import axios from 'axios'

import config from './../config'

// we have to renew the sid for every request because the alarm api is not under the same namespace



class Alarms {
  getAllAlarms() {
    return axios.get([config.basename, 'api/v1/alarms'].join('/'));
  }
  getOpenAlarms() {
   // return axios.get([config.basename, 'api/homescreen/reports/openAlarmsInformation'].join('/'));
    return axios.get([config.basename, 'api/openAlarms'].join('/'));
  }
  getTopDevicesByAlarms(){
   // return axios.get([config.basename, 'api/homescreen/reports/topDevicesByAlarmInformation'].join('/'));
    return axios.get([config.basename, 'api/topDevices'].join('/'));
  }
  getTopGroupsByAlarms(){
   // return axios.get([config.basename, 'api/homescreen/reports/topGroupsByAlarmInformation'].join('/'));
    return axios.get([config.basename, 'api/topGroups'].join('/'));
  }
  getActiveDevices(){
  //  return axios.get([config.basename, 'api/homescreen/reports/activeDevicesInformation'].join('/'));
    return axios.get([config.basename, 'api/activeDevices'].join('/'));
  }
  getMonitoredTechnologies(){
  //  return axios.get([config.basename, 'api/homescreen/reports/fetchMonitoredTechnologiesInformation'].join('/'));
  return axios.get([config.basename, 'api/technologies'].join('/'));
  }
  getDeviceByRole(){
    //  return axios.get([config.basename, 'api/homescreen/reports/deviceByRoleInformation'].join('/'));
    return axios.get([config.basename, 'api/roleDevices'].join('/'));
    }
  get(params) {
    return  axios.get('/alarmviewer-api/alarm', params);
  }
  post(params) {
    const url = process.env.NODE_ENV === "development" ?
    "http://10.252.25.208:8080/castlemock/mock/rest/project/TK8t3o/application/UXxhjW/alarmviewer-api/alarm" :
    '/alarmviewer-api/alarm'
    return axios.post(url, params);
  }
}

let alarms = new Alarms()
export default alarms
