import React from 'react'
import PropTypes from 'prop-types'
import CrossLaunch from '../crossLaunch/CrossLaunch'
import Resource from './Resource'

const AdminConsole = ({ groupId, csId, sid, ...props }) => {
  let url = `/adminconsole_portlet_embedded/standalone.jsp?persistLogin=${sid}`
  if (groupId) {
    url += `&grp_id=${groupId}`
  }
  if (csId) {
    url += `&cs_id=${csId}`
  }
  let breadCrumbs = [
      {
	//settings
        name: Resource.get('Settings'),
        link: '/settings',
      },
      {
	//admin console
        name: Resource.get('Admin Console'),
        link: '/settings/admin-console'
      }
    ]
  props.setBreadCrumbs(breadCrumbs)
  return <CrossLaunch src={url} sid={sid} />
}
AdminConsole.propTypes = {
  sid: PropTypes.string.isRequired
}
export default AdminConsole
