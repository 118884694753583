import React from "react";
import Portlet from "../Portlet";
import ArrowIcon from "../../ArrowIcon";
import Resource from "./Resource";
import {prd_name_text} from './PerformanceReports'
import {Tooltip} from '@mineral/core';
export default class PerformanceReportsCard extends Portlet {
    getIcon() {
        return <ArrowIcon />;
      }
    
      getSubLabelTwo = () => {
        return this.props.subLabelTwo;
      };
    }
    
    const TextLogo = ({ label }) => {
      return (
	  <Tooltip title={label}>
        <span className="settings-text-logo admin__console--card">{label}</span>
		</Tooltip>
      );
    };
    
    //Performance reports
    const PerformanceReportsLabel = prd_name_text;
    //The Performance Reports Designer allows you to see a visual representation of QoS data
    const performanceReportsDesc = Resource.get("The Performance Reports Designer allows you to see a visual representation of QoS data");
    
    PerformanceReportsCard.defaultProps = {
      logo: <TextLogo label={PerformanceReportsLabel} />,
      configRoute: "/settings/performancereports",
      subLabelOne: performanceReportsDesc,
      subLabelTwo: "",
}