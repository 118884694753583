import axios from 'axios'
import config from "../config";

class ProductUsageMetrics {
  getUser(params) {
    let url = "";
    if(process.env.NODE_ENV==='development')
    url="http://10.252.25.208:8080/castlemock/mock/rest/project/vDXVgx/application/IbO5U6/monthlyUsageSummary"
    else
    url=[config.basename,
      `api/v1/productUsage/monthlyUsageSummary?month=${params.month}&year=${params.year}`,
    ].join("/");

    return axios.post(url);   
  }
  
}

const productUsageMetrics= new ProductUsageMetrics()
export default productUsageMetrics