import {SET_HEARTBEAT} from './actionTypes'

const heartbeat = (state = true, action) => {
  switch (action.type) {
    case SET_HEARTBEAT:
      return  action.beating
    default:
      return state
  }
}
export default heartbeat
