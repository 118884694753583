// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  // one hour MenuItem
  content["1 Hour"] =
  "1시간";

  // two hours MenuItem
  content["2 Hours"] =
  "2시간";

  // twelve hours MenuItem
  content["12 Hours"] =
  "12시간";

  // one day MenuItem
  content["24 Hours"] =
  "24시간";

  // three days MenuItem
  content["3 Days"] =
  "3일";

  // one week MenuItem
  content["1 Week"] =
  "1주";

  // two weeks MenuItem
  content["2 Weeks"] =
  "2주";

  // one month MenuItem
  content["1 Month"] =
  "1개월";

  // three months MenuItem
  content["3 Months"] =
  "3개월";

  // END OF LOCALIZATION

export default content;
