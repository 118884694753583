import React from "react";
import { connect } from "react-redux";
import { Component } from "react";
import axios from "axios";
import { getAccount } from "./../../../api/account/actions";
import Resource from "./Resource";
import isEqual from "lodash/isEqual";
import {getOcviewData} from "./../../../api/ocviews/actions";
import {  Grid } from "../../ui-components/uim-components";
import {CircularProgress} from '@mineral/core'
import {
  MenuItem,
  ListItemText,
  Select as SelectField,
  Button as FlatButton,
  Snackbar,
  RadioGroup as RadioButtonGroup,
  Radio as RadioButton, FormControlLabel,
  Box,
  Button,InputLabel
} from "@mineral/core";
import {fetchDashboardPaths} from './../../../api/dashboard/actions';
import './personalize.less'
// import Select from "material-ui/core/Select";
import {fetchGlobalAndPersonalUserPreferences,fetchAccountUserPreferences,createUserPreference,updateUserPreference,deleteUserPreference,resetStore} from "./../../../api/userpreferences/actions";
const scopeLbl=Resource.get("Scope"); //Scope
const viewLbl=Resource.get("View"); //View
const publishedDashboardsLbl=Resource.get("Published Dashboards"); //published Dashboards
const accountsLbl= Resource.get("Accounts"); //Accounts
const selectAllLbl= Resource.get("Select All"); //Selectall
const clearAllLbl= Resource.get("Clear All"); //Clearall
const multiSelectHint=Resource.get("Hold Shift or Command or Control key to select multiple."); //multiSelectHint
const unassignedAccountHint=Resource.get("Unassigned accounts default to Home."); //unassignedAccountHint
const restoreLbl= Resource.get("Clear");//Clear
const resetLbl= Resource.get("Delete");//Delete
const applyLbl=Resource.get("Apply"); //Apply


class LeftPane extends Component {
  constructor(props) {
    super(props);
    let ocViews = new Map([
      ["Home", true],
      ["Alarms", true],
      ["Groups", true],
      ["Inventory", true],      
      ["Dashboard", true],
      ["Reports", true],
      //["Published Dashboards", true],
      ["SLA", true],
      
    ]);
    
    let finalOcViews=[];
    props.ocviews.unAccessibleList.map((obj) => {
      switch (obj) {
        case "Home":
          
          ocViews.set("Home", false);
          break;
        case "Alarms":
         
          ocViews.set("Alarms", false);

          break;
        case "Inventory":
         
          ocViews.set("Inventory", false);

          break;
        case "Reports":
          
          ocViews.set("Reports", false);

          break;
        case "Dashboard":
          
          ocViews.set("Dashboard", false);
        //  ocViews.set("Published Dashboards", false);
          break;
        case "SLA":
          
          ocViews.set("SLA", false);

          break;
        case "Groups":
         
          ocViews.set("Groups", false);
          break;
       /* case "Badge":
          
          ocViews.set("Group - Card View", false);

          break;
        case "List":
          
          ocViews.set("Group - List View", false);

          break;
          */
      }  
       
     
    });
    ocViews.forEach( (value, key, map) => {
      if(value==true)
      finalOcViews.push(key);
    });
    this.state = {
      showFloatingMessage: false,
      floatingMessageValue:"",
      valueSelected: props.acl.isBusUser && props.acl.canPersonalize?"Global":(props.acl.canPersonalize?"Account":"Personal"),
      dropdownValueSelected: "",
      dropdownValueSelectedBeforeEdit: "",
      publishedDashboardValues: "0",
      publishedDashboardDropDownValues: props.dashboardPaths.items,
      publishedDashboardStatus: props.dashboardPaths.fetchStatus,
      publishedDashboardDropDownValueSelected: "",
      publishedDashboardDropDownValueSelectedBeforeEdit: "",
      accountsSelectionChange: "All accounts",
      accountsDropdownValueSelected: [],
      selectedDropDown: "",
      numberOfAccountsSelected: "",
      accounts: [],
      ocViews: finalOcViews,
      pref_id:null,
      ocViewsInState: props.ocviews.unAccessibleList,
      userPreferencesGlobalPersonalListGetStatus:{isFetching: false,status: 'none',userpreferences: {items:[],totalCount:0},},
      userPreferencesAccountListGetStatus:{isFetching: false,status: 'none',userpreferences: {items:[],totalCount:0},},  
      userPreferenceCreateStatus:{status:'none',userpreference:{},preferencename:''},
      userPreferenceUpdateStatus:{status:'none',userpreference:{},preferencename:''},
      userPreferenceDeleteStatus:{status:'none',preferencename:''},
      isFetching:true,
      overlayNoRowsDisplay:'<div><div class="nohubs__contentImg"></div><span style="color: #333333;font-family: Roboto;font-size: 1.25rem;">No Data found. Try different filter options.</span></div>',
  
      accountToUserPreferenceMap:new Map(),   
      
    };
    this.columns=[{headerName: 'Path',field:'path'}]
  }
  /*getOCViews() {
    axios
      .get("/operatorconsole_portlet/api/v1/oc-views")
      .then((res) => {
        let ocViews = ["Home","Alarms","Inventory","Reports","Dashboard","SLM - SLA View","Group - Tree View","Group - Card View","Group - List View"];
        let ocViews=[];
        res.map((obj) => {
          switch (obj) {
            case "Home":
              ocViews.push("Home");
              break;
            case "Alarms":
              ocViews.push("Alarms");
  
              break;
            case "Inventory":
              ocViews.push("Inventory");
  
              break;
            case "Reports":
              ocViews.push("Reports");
  
              break;
            case "Dashboard":
              ocViews.push("Dashboard");
  
              break;
            case "SLA":
              ocViews.push("SLM - SLA view");
  
              break;
            case "Tree":
              ocViews.push("Group - Tree View");
  
              break;
            case "Badge":
              ocViews.push("Group - Card View");
  
              break;
            case "List":
              ocViews.push("Group - List View");
  
              break;
          }  
           
         
        });
        this.setState({
          ocViews: ocViews,
        });
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
  }*/
 /* geHomePagePreferenceByUser() {
    axios
      .get(
        "/operatorconsole_portlet/api/v1/user/preferences/geHomePagePreferenceByUser"
      )
      .then((res) => {
        this.setState({
          valueSelected: res[0].preferenceLevel,
          dropdownValueSelected: res[0].landingPage,
        });
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
  }*/
  
  componentWillMount() {
    //this.geHomePagePreferenceByUser();
    var params = new URLSearchParams();
      params.append("pageNumber", 0);
      params.append("pageSize", 50);
      params.append("filterText", '');
    this.props.fetchDashboardPaths(params);
    this.props.fetchAccount();

    if(this.state.valueSelected=="Account")
       this.props.fetchAccountUserPreferences();
       else
       this.props.fetchGlobalAndPersonalUserPreferences();
      
    //this.props.fetchGlobalAndPersonalUserPreferences();
    this.props.resetStore();
    //this.props.fetchAccountUserPreferences();
   
  }

  componentWillReceiveProps(nextProps) {
    
    if (!isEqual(this.state.accounts, nextProps.accounts)) {     
      this.setState({
        accounts: nextProps.accounts})
    }
    if (!isEqual(this.state.ocViewsInState, nextProps.ocviews.unAccessibleList)) {

      let ocViews = new Map([
        ["Home", true],
        ["Alarms", true],
        ["Groups", true],
        ["Inventory", true],
        ["Dashboard", true],
        ["Reports", true],       
       // ["Published Dashboards", true],
        ["SLA", true],
        ]);
      
      let finalOcViews=[];
      nextProps.ocviews.unAccessibleList.map((obj) => {
        switch (obj) {
          case "Home":            
            ocViews.set("Home", false);
            break;
          case "Alarms":           
            ocViews.set("Alarms", false);

            break;
          case "Inventory":           
            ocViews.set("Inventory", false);

            break;
          case "Reports":            
            ocViews.set("Reports", false);

            break;
          case "Dashboard":            
            ocViews.set("Dashboard", false);
           // ocViews.set("Published Dashboards", false);
            break;
          case "SLA":            
            ocViews.set("SLA", false);

            break;
          case "Groups":           
            ocViews.set("Groups", false);
            break;
         }  
         
       
      });
      ocViews.forEach( (value, key, map) => {
        if(value==true)
        finalOcViews.push(key);
      });
      
      this.setState({
        ocViews: finalOcViews,
        ocViewsInState: nextProps.ocviews.unAccessibleList
      });

    }

    if(!isEqual(this.state.userPreferenceUpdateStatus,nextProps.userpreferences.userPreferenceUpdateStatus))
    {
      this.setState({userPreferenceUpdateStatus:nextProps.userpreferences.userPreferenceUpdateStatus})
      
      if(nextProps.userpreferences.userPreferenceUpdateStatus.status=='success')
      {
       let message=Resource.get("User preferences saved successfully");//UserPrefsSuccess
       this.setState({showFloatingMessage:true,floatingMessageValue:message,isFetching:true})
       if(this.state.valueSelected=="Account")
       this.props.fetchAccountUserPreferences();
       else
       this.props.fetchGlobalAndPersonalUserPreferences();
      }
      else if(nextProps.userpreferences.userPreferenceUpdateStatus.status=='failure')
      {
        let message=Resource.get("Failed to save user preferences");//UserPrefsFailure
       this.setState({showFloatingMessage:true,floatingMessageValue:message})
      }
      
      
    }

    if(!isEqual(this.state.userPreferencesAccountListGetStatus,nextProps.userpreferences.userPreferencesAccountListGetStatus))
    {

      this.setState({userPreferencesAccountListGetStatus: nextProps.userpreferences.userPreferencesAccountListGetStatus})
      if(nextProps.userpreferences.userPreferencesAccountListGetStatus.status=='success')
      {
            this.setState({isFetching:false})
            //All accounts have preferences or not
            let allAreSame=true;
            let currentLandingPage=""; 
            let countByAccountScope=0; 
            let accountToUserPreferenceMap=new Map();
          for(let userPreference of nextProps.userpreferences.userPreferencesAccountListGetStatus.userpreferences)
        {
              if(this.state.valueSelected==userPreference.pref_level && userPreference.pref_level=="Account")
              {
              if(currentLandingPage=="")
              {
                currentLandingPage=userPreference['@landing_page'];
              }
              if(currentLandingPage!=userPreference['@landing_page'])
              {
                allAreSame=false; //There are accounts with different prefs, dont have All accounts selected
              
              }
              userPreference.landingPage=userPreference['@landing_page'];
              userPreference.preferenceLevel=userPreference.pref_level;
              accountToUserPreferenceMap.set(userPreference.account_id, userPreference);
              countByAccountScope++;
            }
          
        }
        this.setState({accountToUserPreferenceMap:accountToUserPreferenceMap});
        if(countByAccountScope!=this.state.accounts.length) //Not all accounts are having preference
        {
          allAreSame=false;
        }
        if(allAreSame==true && this.state.valueSelected!=currentLandingPage && currentLandingPage != "")
        {
          if(this.state.ocViews.includes(currentLandingPage))
            this.props.selectedDropDown(currentLandingPage);
          else
            this.props.selectedDropDown("Inaccessible")
          this.setState({accountsSelectionChange: "All accounts",dropdownValueSelected: currentLandingPage,
              dropdownValueSelectedBeforeEdit: currentLandingPage});
        }
        else if(allAreSame && this.state.valueSelected==currentLandingPage)
          this.setState({accountsSelectionChange:"All accounts",dropdownValueSelectedBeforeEdit:currentLandingPage})
        else  if(allAreSame && ""==currentLandingPage)
        {
          if(nextProps.userpreferences.userPreferencesAccountListGetStatus.userpreferences.length<1)
          currentLandingPage = this.state.ocViews && this.state.ocViews.length>0?this.state.ocViews[0]:"Home"  ;//dp039841   
          if(this.state.ocViews.includes(currentLandingPage))          
          this.props.selectedDropDown(currentLandingPage);
          else
          this.props.selectedDropDown("Inaccessible")
          this.setState({accountsSelectionChange:"All accounts",dropdownValueSelectedBeforeEdit:"",
          dropdownValueSelected: currentLandingPage})
        }
        else
        {
          currentLandingPage = this.state.ocViews && this.state.ocViews.length>0?this.state.ocViews[0]:"Home"  ;//dp039841   
          if(this.state.ocViews.includes(currentLandingPage))
            this.props.selectedDropDown(currentLandingPage);
          else
            this.props.selectedDropDown("Inaccessible");
        this.setState({accountsSelectionChange: "By account",dropdownValueSelected: currentLandingPage,
              dropdownValueSelectedBeforeEdit: ''})
        }

     }
    }

    if(!isEqual(this.state.userPreferencesGlobalPersonalListGetStatus,nextProps.userpreferences.userPreferencesGlobalPersonalListGetStatus))
    {

          this.setState({userPreferencesGlobalPersonalListGetStatus: nextProps.userpreferences.userPreferencesGlobalPersonalListGetStatus})
          if(nextProps.userpreferences.userPreferencesGlobalPersonalListGetStatus.status=='success')
          {
            this.setState({isFetching:false})
            if(nextProps.userpreferences.userPreferencesGlobalPersonalListGetStatus.userpreferences.length!=0)
            {
                let prefFound=false;
                for(let userPreference of nextProps.userpreferences.userPreferencesGlobalPersonalListGetStatus.userpreferences)
                {
                    if(this.state.valueSelected==userPreference.preferenceLevel)
                    {
                      prefFound=true;
                      if(this.state.landingPage!=userPreference.landingPage)
                      {
                        if(this.state.ocViews.includes(userPreference.landingPage))          
                      this.props.selectedDropDown(userPreference.landingPage);
                      else
                      this.props.selectedDropDown("Inaccessible");
                      this.setState({
                        pref_id:userPreference.id,
                    
                    dropdownValueSelected: userPreference.landingPage,
                    dropdownValueSelectedBeforeEdit: userPreference.landingPage,
                      });
                    }
                    }
              }
              if(prefFound==false)
              {
                let currentLandingPage = this.state.ocViews && this.state.ocViews.length>0?this.state.ocViews[0]:"Home" ;
                if(this.state.ocViews.includes(currentLandingPage))
          
                this.props.selectedDropDown(currentLandingPage);
                else
                this.props.selectedDropDown("Inaccessible");
                this.setState({
                  pref_id:null,
                  dropdownValueSelected: currentLandingPage,
                  dropdownValueSelectedBeforeEdit: "",
                });
            }

            }else
            {
              let currentLandingPage = this.state.ocViews && this.state.ocViews.length>0?this.state.ocViews[0]:"Home" ;
              if(this.state.ocViews.includes(currentLandingPage))          
              this.props.selectedDropDown(currentLandingPage);
              else
              this.props.selectedDropDown("Inaccessible");
              this.setState({
                pref_id: null,
                dropdownValueSelected: currentLandingPage,
                dropdownValueSelectedBeforeEdit: "",
             });
            }
            

          }
    }

    //dp039841
    if(!isEqual(this.state.publishedDashboardStatus, nextProps.dashboardPaths.fetchStatus))
    {
      this.setState({ publishedDashboardStatus: nextProps.dashboardPaths.fetchStatus});
      if(nextProps.dashboardPaths.fetchStatus == 'COMPLETE'){
            this.setState({
                publishedDashboardDropDownValues: nextProps.dashboardPaths.items,              
               })
         }
    }

    if(!isEqual(this.state.userPreferenceDeleteStatus,nextProps.userpreferences.userPreferenceDeleteStatus))
    {
      this.setState({userPreferenceDeleteStatus:nextProps.userpreferences.userPreferenceDeleteStatus})
      
      if(nextProps.userpreferences.userPreferenceDeleteStatus.status=='success')
      {
       let message=Resource.get("User preferences deleted successfully");//UserPrefsResetSuccess
       this.setState({showFloatingMessage:true,floatingMessageValue:message,isFetching:true})
       if(this.state.valueSelected=="Account")
       this.props.fetchAccountUserPreferences();
       else
       this.props.fetchGlobalAndPersonalUserPreferences();
      }
      else if(nextProps.userpreferences.userPreferenceUpdateStatus.status=='failure')
      {
        let message=Resource.get("Failed to delete user preferences");//UserPrefsResetFail
       this.setState({showFloatingMessage:true,floatingMessageValue:message})
      }
      
      
    }
  }    

  handleScheduleTypeChange(changeEvent) {
   // console.log(changeEvent);
    switch (changeEvent.target.value) {
      case "Global":
        this.setState({
          valueSelected: "Global",
        });
        break;
      case "Personal":
        this.setState({
          valueSelected: "Personal",
        });
        break;
      case "Account":
        this.setState({
          valueSelected: "Account",
        });
        //
        break;
    }
    if(changeEvent.target.value=="Account")
    {
      this.setState({
        pref_id:null,
        dropdownValueSelected: "",
        dropdownValueSelectedBeforeEdit:"",
        isFetching:true
      });
    this.props.fetchAccountUserPreferences();
    }
    else if(changeEvent.target.value=="Personal" || changeEvent.target.value=="Global")
    {
      this.setState({
        pref_id:null,
        dropdownValueSelected: "",
        dropdownValueSelectedBeforeEdit:"",
        isFetching:true
      });
      this.props.fetchGlobalAndPersonalUserPreferences();     
     
    }
  }
  accountSelectionChange(changeEvent) {
  //  console.log(changeEvent);
    switch (changeEvent.target.value) {
      case "All accounts":
        this.setState({
          accountsSelectionChange: "All accounts",
        });
        break;
      case "By account":
        this.setState({
          accountsSelectionChange: "By account",
        });
        break;
    }
  }
  handleViewDropdownValueChange(target, key, payload) {
    this.setState(
      {
        dropdownValueSelected: payload,
        selectedDropDown:
        payload === "Published Dashboards"
            ? this.state.publishedDashboardValues.length > 0
              ? this.state.publishedDashboardValues
              : ""
            : payload,
      },
      function () {
        this.props.selectedDropDown(this.state.selectedDropDown);
      }
    );
  }
  publishDashboardPathSelectionChanged=(target, key, payload) =>{
    this.setState(
      {
        publishedDashboardDropDownValueSelected: payload,
        /*selectedDropDown:
        payload === "Published Dashboards"
            ? this.state.publishedDashboardValues.length > 0
              ? this.state.publishedDashboardValues
              : ""
            : payload,*/
      });/*,
      function () {
        this.props.selectedDropDown(this.state.selectedDropDown);
      }
    );  */
  }
  restore(oldDropDownVal) {
    if(!oldDropDownVal || oldDropDownVal.trim()=="")
    oldDropDownVal=this.state.ocViews && this.state.ocViews.length>0?this.state.ocViews[0]:"Home" ;

    if(this.state.landingPage!=oldDropDownVal)
      {          if(this.state.ocViews.includes(oldDropDownVal))                
    this.props.selectedDropDown(oldDropDownVal);
    else
    this.props.selectedDropDown("Inaccessible");
      }
                     
    this.setState({dropdownValueSelected: oldDropDownVal})
    //this.setState((prevState, props) => {dropdownValueSelected:prevState.dropdownValueSelectedBeforeEdit})
  }

  reset() {
    let defaultPage=this.state.ocViews && this.state.ocViews.length>0?this.state.ocViews[0]:"Home";
    if(      this.state.valueSelected!="Account" &&
    this.state.pref_id==null && this.state.dropdownValueSelected!=defaultPage )
    {
      if(this.state.ocViews.includes(defaultPage))          
      this.props.selectedDropDown(defaultPage);
      else
      this.props.selectedDropDown("Inaccessible");
      let message=Resource.get("No user preferences to delete");//UserPrefsDeleteNo
      this.setState({dropdownValueSelected:defaultPage,showFloatingMessage:true,floatingMessageValue:message,isFetching:true})
  }
    else
    {
      if(this.state.valueSelected!="Account")
    {
    
      this.props.deleteUserPreference([this.state.pref_id])
   
    }else
    {
      let params = [];
      let accountsAffetced = [];
      if(this.state.accountsSelectionChange == "All accounts"){
        this.state.accounts.map((account)=>
            accountsAffetced.push(account.accountId)
        )
      }
      else 
        accountsAffetced = this.state.accountsDropdownValueSelected ; //dp039841

      if(accountsAffetced && accountsAffetced.length>0){
      for(let acc of accountsAffetced)
      {
        params.push(this.state.accountToUserPreferenceMap.has(parseInt(acc))? this.state.accountToUserPreferenceMap.get(parseInt(acc)).pref_id:null);
        
      }
      this.props.deleteUserPreference(params);
    }
    }
  }
    //this.setState((prevState, props) => {dropdownValueSelected:prevState.dropdownValueSelectedBeforeEdit})
  }
    //Grid functionalities
  onGridReady(params) {       
      this.api = params.api;
      this.api.sizeColumnsToFit();    
       this.api.showLoadingOverlay();
       this.paramsInfo=null;

       if((this.state.publishedDashboardDropDownValues.length === undefined || this.state.publishedDashboardDropDownValues.length === 0) 
              && this.state.publishedDashboardStatus =='COMPLETE')
       {
           this.api.hideOverlay();
          this.api.showNoRowsOverlay();
       }
 else if(this.paramsInfo==null  && this.state.publishedDashboardStatus =='COMPLETE'){
      this.startRow=0;				
      this.firstLoad=false;
      this.setGridDataSource(this.state.publishedDashboardDropDownValues);       
  }    
  
}

onGridSizeChanged(params) {
  if (this.api) {
    this.api.sizeColumnsToFit();
  }
}
setGridDataSource(data){
  var datasource = {
      rowCount: null,
      getRows: function(params) {
          
         params.context.updateData(data,params)
      } 
  };
  if(this.api){       	  
        this.api.setDatasource(datasource)        	
    }
}

updateData(data,params){  
      if(params.context )
      {
          params.context.paramsInfo = params
          if(params.context.startRow != params.startRow )
              {
                  params.context.startRow = params.startRow
                  // Change the page number in store and state
                  let pageSize=params.endRow-params.startRow,
                  nextPageNumber = params.startRow/pageSize;
                   if(params.context.api ){
                      params.context.api.showLoadingOverlay()
                  }           
                  params.context.onPageChange(nextPageNumber)  

              }else{         
              setTimeout(function() {              
                  var lastRow = -1;
                  if (data.length <= params.endRow) {
                      lastRow = data.length;
                  }
                   if(params.context && params.context.api){
                      params.context.api.hideOverlay()
                  } 
                  params.successCallback(data, lastRow);                   
                  
              }, 500);
          }
      }
  }
  onPageChange(pageNumber){
    var params = new URLSearchParams();
    params.append("pageNumber", pageNumber);
    params.append("pageSize", 50);
    params.append("filterText", '');
  this.props.fetchDashboardPaths(params);
  }

  apply() {
    if(this.state.valueSelected!="Account")
    {
    const params = 
      [{
        pref_id: this.state.pref_id,
        pref_level: this.state.valueSelected,
        "@landing_page": this.state.dropdownValueSelected,        
      }]
    
    this.setState({isFetching: true});
    this.props.updateUserPreference(params);
    }
    else
    {
      let params = [];
      let accountsAffetced = [];
      if(this.state.accountsSelectionChange == "All accounts"){
        this.state.accounts.map((account)=>
            accountsAffetced.push(account.accountId)
        )
      }
      else 
        accountsAffetced = this.state.accountsDropdownValueSelected ; //dp039841

      for(let acc of accountsAffetced)
      {
        params.push({
          pref_id: this.state.accountToUserPreferenceMap.has(parseInt(acc))? this.state.accountToUserPreferenceMap.get(parseInt(acc)).pref_id:null,
        pref_level: this.state.valueSelected,
        "@landing_page": this.state.dropdownValueSelected,
          account_id:acc,
        })

      }
      this.setState({isFetching: true});
      this.props.updateUserPreference(params);

    }
    
  }
 /* reset() {
    if(this.state.valueSelected!="Account")
    {
    const params = 
      [{
        pref_id: this.state.pref_id,
        pref_level: this.state.valueSelected,
        "@landing_page": this.state.dropdownValueSelected,        
      }]
    
    this.setState({isFetching: true});
    this.props.updateUserPreference(params);
    }
    else
    {
      let params = [];
      let accountsAffetced = [];
      if(this.state.accountsSelectionChange == "All accounts"){
        this.state.accounts.map((account)=>
            accountsAffetced.push(account.accountId)
        )
      }
      else 
        accountsAffetced = this.state.accountsDropdownValueSelected ; //dp039841

      for(let acc of accountsAffetced)
      {
        params.push({
          pref_id: this.state.accountToUserPreferenceMap.has(parseInt(acc))? this.state.accountToUserPreferenceMap.get(parseInt(acc)).pref_id:null,
        pref_level: this.state.valueSelected,
        "@landing_page": this.state.dropdownValueSelected,
          account_id:acc,
        })

      }
      this.setState({isFetching: true});
      this.props.updateUserPreference(params);

    }
    
  }*/
  selectAllClearAllAccounts(selectAll) {
    const value = [];
    if (selectAll) {
      for (let i = 0, l = this.state.accounts.length; i < l; i += 1) {
        value.push(this.state.accounts[i].accountId); //accountId added by dp039841
      }
    }
    this.setState({
      accountsDropdownValueSelected: value,
      numberOfAccountsSelected: value.length,
    });
  }
  handleKeypress(event) {
    //it triggers by pressing the enter key
    if (event.keyCode === 13) {
      debugger;
      console.log("Accountdata", this.selectAccountRef.current);
      console.log("Accountdata_event", event);
      //this.accountsDropDownChange(event);
      let value = event.target.value;
      let selecetedValues = [...this.state.accountsDropdownValueSelected];
      if (selecetedValues.includes(value)) {
        selecetedValues = [...selecetedValues.filter(x => x !== value)];
      }
      else {
        selecetedValues = [...selecetedValues, value]
      }

      this.setState({
        accountsDropdownValueSelected: selecetedValues,
        numberOfAccountsSelected: selecetedValues.length,
      });
    }
  };
  accountsDropDownChange(event) {
    const { options } = event.target;
    const value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    this.setState({
      accountsDropdownValueSelected: value,
      numberOfAccountsSelected: value.length,
    });
  }

  onFloatingMessageClose=()=>{

    this.setState({showFloatingMessage:false,floatingMessageValue:""});
  }

  isSelectedValue(value){
    return this.state.accountsDropdownValueSelected.includes(value.toString());
  } 
  render() {
    const rowHeight = 48;
    let isLoadingContent=null;
    if(this.state.isFetching==true)
    {
      isLoadingContent= (<div style={{width: '100%', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><CircularProgress aria-label="Loading page..." style={{color: '#3272D9',backgroundColor:'transparent'}} className="prefdelayed__spinner"/></div>);
    }
    
      let accountsTemp=JSON.parse(JSON.stringify(this.state.accounts));
      let personalizeTypeRadioButton=[];
      if(this.props.acl.isBusUser && this.props.acl.canPersonalize)
      {
        personalizeTypeRadioButton.push("Global")
      }
      if(this.props.acl.canPersonalize)
      personalizeTypeRadioButton.push("Account");
      personalizeTypeRadioButton.push("Personal");
      let personalizeTypeRadioButtonOptions =
      personalizeTypeRadioButton &&
      personalizeTypeRadioButton.map((item, key) => (
        <FormControlLabel style={{marginRight:'16px'}}
          value={item} 
          label={item}
          control={
            <RadioButton  style={{marginRight:'5px'}} id= { item==="Global"?"first-content-element":""} autoFocus={key===0?true:false} type="radio" value={item} />
          }       
        />
      ));
      
      for(let acc of accountsTemp)
      {
        if(this.state.accountToUserPreferenceMap.has(acc.accountId))
        { 
          let userPrefAcc = this.state.accountToUserPreferenceMap.get(acc.accountId);
          acc.contactName = acc.contactName+
          "        (assigned "+userPrefAcc.landingPage+")" ;               
          //acc['accountView'] =  userPrefAcc.landingPage;          
        }
      }
       
      let accountOptions =
      accountsTemp &&
      accountsTemp.map((item, key) => (
        <option value={item.accountId} 
        style={{marginBottom:'8px'}}//background: this.isSelectedValue(item.accountId)?"#2196F3":"",color: this.isSelectedValue(item.accountId)?"#ffffff":""
        tabIndex ={0} selected={this.isSelectedValue(item.accountId)} >{item.contactName}</option>
      ));      
     
      let defaultTxtLbl=Resource.get("Default") //default
      let availableTxtLbl=Resource.get("Available") //Available

      
  

return (
  <div className="personalizeLeftPane"
   style={{ height:"calc(100vh - (64px + 40px + 10px + 40px )",width: "376px", "border-right": "1px solid #CCCCCC","border-top":"1px solid #BCBDC0", "border-left": "none","border-bottom": "none",position: "relative"}}
  >
   <div style={{ marginTop: "16px", marginLeft: "16px" }}>
     <InputLabel > {scopeLbl} </InputLabel>
     <RadioButtonGroup
       id="preferenceType"
       name="preferenceType"
       defaultValue={this.state.valueSelected}
      // className="personalizecontent-item"
       onChange={this.handleScheduleTypeChange.bind(this)}
       style={{ display: "flex", paddingTop: "10px",flexDirection: "row"}}
     >
     {personalizeTypeRadioButtonOptions}
       
     </RadioButtonGroup>
   </div>
   { this.state.isFetching==true?isLoadingContent:(<div style={{marginRight:"16px"}}>
   <div
     style={{
       marginTop: "16px",
       marginLeft: "16px",
       display: "flex",
       flexDirection: "column",
     }}
   >
     <InputLabel style={{paddingBottom: "6px"}} id="viewBoxLabel" htmlFor="viewBox"> {viewLbl} </InputLabel>
     <SelectField role="combobox"
     id="viewBox"
     name="selectViewPage" labelId="viewBoxLabel"
     aria-label={viewLbl}
     aria-labelledby="viewBoxLabel"
     inputProps={{'aria-label':{viewLbl},
                           role: 'combobox',}}
     // inputProps={{style:{width: "unset"}}}
     variant="outlined"
     // role = "listbox"
       value={this.state.dropdownValueSelected}
       // tabIndex = {0}
       MenuProps={{
       MenuListProps:{
         'aria-labelledby' : "viewBoxLabel",
         'aria-label' :viewLbl,
         title:viewLbl
        }}}
       style={{
         height: "27px !important",
         // lineHeight: "16px",
         // fontSize: "14px",
         // width: "85%",
         // backgroundColor: "white",
       }}
       onChange={(event,key,payload) => this.handleViewDropdownValueChange(event,key,event.target.value)}
     >
       {this.state.ocViews.map((object,key) => (            
         <MenuItem 
           key={key} value={object}>
            <ListItemText  
           className="leftPaneMenuListItems"
               aria-labelledby="viewBox"
               primary={object=="SLA"?"SLM":object} 
               secondary = {object== "Published Dashboards" ? <span style={{color:'#58606E'}}>
                 {this.state.publishedDashboardDropDownValues.length}{availableTxtLbl}</span> :
               (object== "Home" ? <span style={{color:'#58606E', marginRight:'181px'}}>{defaultTxtLbl}</span>: "")} />
         </MenuItem>           
       ))}
     </SelectField>
   </div>
   {this.state.valueSelected === "Account" && (
     <div style={{ paddingTop: "10px", paddingLeft: "16px" }}>
       <RadioButtonGroup
         name="accountSel"
         defaultValue={this.state.accountsSelectionChange}
         className="personalizecontent-item-acc"
         onChange={this.accountSelectionChange.bind(this)}
         style={{ display: "flex", paddingTop: "10px" ,flexDirection: "row"}}
       >
         <FormControlLabel style={{marginRight:'16px'}}
           value={"All accounts"}
           label={"All Accounts"}
           control={
             <RadioButton  style={{marginRight:'5px'}}
            
             disableRipple={true} 
               type="radio"
               value={"All accounts"}
             />}
           />
         <FormControlLabel
           id="selectByAccount"
           label={"By Account"}
           value={"By account"}
           control={
             <RadioButton  style={{marginRight:'5px'}}
            
             disableRipple={true} 
               type="radio"
               value={"By account"}
             />}
         />
       </RadioButtonGroup>
     </div>
   )}
   {this.state.dropdownValueSelected === "Published Dashboards" && (
     <div><div
     style={{
       marginTop: "16px",
       marginLeft: "16px",
       display: "flex",
       flexDirection: "column",
     }}
   >
     <span style={{fontFamily: "Roboto",fontStyle: "normal",fontWeight: 500,fontSize: "14px",
     lineHeight: "16px","color": "#666666",paddingBottom: "6px"}}> {publishedDashboardsLbl} </span>
     <SelectField
     name="selectByAccount"
     aria-label="By Account"
     aria-labelledby="selectByAccount"
       value={this.state.dropdownValueSelected}
       /*style={{
         height: "25px",
         lineHeight: "16px",
         fontSize: "14px",
         width: "85%",
         backgroundColor: "white",
       }}*/
      
       onChange={(event,key,payload) => this.publishDashboardPathSelectionChanged(event,key,event.target.value)}
     >
       {this.state.publishedDashboardDropDownValues.map((object,key) => (            
         <MenuItem key={key} value={object.id} label={object.path} children={object.path}/>             
       ))}
     </SelectField>
   </div>
     <div
       style={{
         paddingTop: "15px",
         paddingLeft: "16px",
         display: "flex",
         flexDirection: "column",
       }}
     >
       <span style={{ paddingBottom: "6px" }}> {publishedDashboardsLbl} </span>
      
       <div
         className="ag-material tablecontainer"
         style={{ height: "77vh", width: "22vw", marginLeft:'0px !important' }}
       >
         <Grid                                        
           headerHeight={0}
           rowHeight={rowHeight}                                            
           columnDefs={this.columns}
           onGridReady={this.onGridReady.bind(this)}                                      
           rowModelType='infinite'
           overlayNoRowsTemplate={this.state.overlayNoRowsDisplay}  
           context={this}
           cacheBlockSize={50}
           cacheOverflowSize={2}
           maxConcurrentDatasourceRequests={1}
           maxBlocksInCache={10}
           onGridSizeChanged={this.onGridSizeChanged.bind(this)}  
           rowSelection='single'                                      
           onSelectionChanged={this.publishDashboardPathSelectionChanged} 
          />
       </div>
     </div>
     </div>
   )}
   {this.state.valueSelected === "Account" &&
     this.state.accountsSelectionChange === "By account" && (
       <div
         style={{
           paddingTop: "15px",
           paddingLeft: "16px",
           display: "flex",
           flexDirection: "column",
         }}
       >
         <div
           style={{
             display: "flex",
             flexDirection: "row",
             position: "relative",
           }}
         >
           <InputLabel id="account" style={{ paddingBottom: "6px",paddingBottom: "6px"}}>
             {accountsLbl} &nbsp;&nbsp;&nbsp;&nbsp;
           </InputLabel>
           {this.state.numberOfAccountsSelected !== "" && (
             <span
               style={{
                 fontSize: "x-small",
                 color: "#58606E",
                 height: "25px",
                 paddingTop: "4px",
               }}
             >
               {"   " +
                 this.state.numberOfAccountsSelected +
                 "/"+this.state.accounts.length+" Selected"}
             </span>
           )}
           {this.state.numberOfAccountsSelected === "" && (
             <span
               style={{
                 fontSize: "x-small",
                 color: "#58606E",
                 height: "25px",
                 paddingTop: "4px",
               }}
             >
               {"   " +
                 this.state.accounts.length+" Available"}
             </span>
           )}
           <div
             className="selectClearButtons"
           >
             <Button
               type="button"
               variant="text"
               // class="btn btn-primary"
               onClick={() => this.selectAllClearAllAccounts(true)}
             >
               {selectAllLbl}
             </Button>
             <Button
               type="button"
               variant="text"
               // class="btn btn-primary"
               onClick={() => this.selectAllClearAllAccounts(false)}
             >
               {clearAllLbl}
             </Button>
           </div>
         </div>

        
         <select
         ref={this.selectAccountRef}
         name = "selectAccounts"
        
         aria-label="select Accounts"
         aria-labelledby="account"
           value={this.state.accountsDropdownValueSelected}
           style={{
             lineHeight: "16px",
             fontSize: "14px",
             width: "344px",
             height: '200px',
             backgroundColor: "white",
           }}               
           multiple={true}
           selectedMenuItemStyle={{ color: "#3272D9" }}
           onChange={(event) => this.accountsDropDownChange(event)}
           onKeyDown={(event) => this.handleKeypress(event)}
         >
           {accountOptions}
          {/*  {accountsTemp && accountsTemp.map((item, key) => 
                <MenuItem value={item.accountId}  
                       primaryText={item.contactName}
                       secondaryText={<span style={{color: 'gray', marginRight:'120px'}}>{`(assigned  ${item.accountView})`}</span>}          
                       />
             )} */}
         </select>
         <span
           style={{
             fontSize: "x-small",
             color: "#58606E",
             paddingTop: "4px",
             paddingBottom: "2px",
           }}
         >
           {multiSelectHint}
         </span>
         <span style={{ fontSize: "x-small", color: "#58606E" }}>
          {unassignedAccountHint}
         </span>
       </div>
     )}
   <div
     style={{
       display: "flex",
       flexDirection: "row",
       justifyContent: "flex-end",
       right: "16px",
       position: "absolute",
       bottom: "16px",
       marginTop: "15px",
     
     }}
   >
     <FlatButton
       children={resetLbl} 
       color="primary"          
       style={{ float: "right",marginRight: "10px" }}
       variant="outlined"
       
       onClick={() => this.reset()}
       disabled={(this.state.valueSelected!="Account" && this.state.pref_id==null )||  
       (this.state.valueSelected=="Account" && (this.state.accountToUserPreferenceMap && this.state.accountToUserPreferenceMap.size<1) &&
       ((this.state.accountsSelectionChange == "All accounts" && this.state.accounts && this.state.accounts.length<1)
       ||(this.state.accountsSelectionChange != "All accounts" && this.state.accountsDropdownValueSelected  && this.state.accountsDropdownValueSelected.length<1))
       )}
       className= "flatButtonStyle"
     />
     <FlatButton
       children={restoreLbl} 
       color="primary"           
       style={{ float: "right",marginRight: "10px"}}
       variant="outlined"
      
       onClick={() => this.restore(this.state.dropdownValueSelectedBeforeEdit)}
       className= "flatButtonStyle"
     />
      
     <FlatButton
       children={applyLbl}
       color="primary"
       className="personalize-button"
       style={{ float: "right"}}
       variant="contained"
       
       disabled={this.state.dropdownValueSelected=="" || this.state.dropdownValueSelected==this.state.dropdownValueSelectedBeforeEdit || 
       (this.state.valueSelected=="Account" && (this.state.accountToUserPreferenceMap && this.state.accountToUserPreferenceMap.size<1) && ((this.state.accountsSelectionChange == "All accounts" && this.state.accounts && this.state.accounts.length<1)
       ||(this.state.accountsSelectionChange != "All accounts" && this.state.accountsDropdownValueSelected  && this.state.accountsDropdownValueSelected.length<1))
       )}      
       onClick={() => this.apply()}           
     />
   </div>
   <Snackbar
       open={this.state.showFloatingMessage}
       message={this.state.floatingMessageValue}
       autoHideDuration={4000}
       onClose={this.onFloatingMessageClose}
     />
  </div>)}
 </div>
);
}
}


const mapStateToProps = (state, ownProps) => ({
  acl: state.acl,
  ocviews: state.ocviews,
  accounts: state.account.accounts,
  userpreferences: state.userpreferences,
  dashboardPaths: state.dashboardPaths,

});

const mapDispatchToProps = (dispatch) => {
  return {
    fetchAccount: () => dispatch(getAccount()), 
    fetchDashboardPaths: (params) => dispatch(fetchDashboardPaths(params)), 
    fetchGlobalAndPersonalUserPreferences: ()=> dispatch(fetchGlobalAndPersonalUserPreferences())  ,
    fetchAccountUserPreferences: ()=> dispatch(fetchAccountUserPreferences()),
    createUserPreference:(params)=> dispatch(createUserPreference(params)),
    updateUserPreference:(params)=> dispatch(updateUserPreference(params)),
    deleteUserPreference:(id)=> dispatch(deleteUserPreference(id)),
    resetStore:()=>dispatch(resetStore()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LeftPane);