export const FILTER_ENTITIES = "FILTER_ENTITIES";
export const INVENTORY_FILTER_ENTITIES = "INVENTORY_FILTER_ENTITIES";
export const SET_ENTITIES = "SET_ENTITIES";
export const SET_ENTITY = "SET_ENTITY";
export const REMOVE_ENTITIES = "REMOVE_ENTITIES";
export const RENAME_ENTITY = "RENAME_ENTITY";
export const ADD_ENTITIES = "ADD_ENTITIES";
export const FILTERED_ROW_ENTITIES = "FILTERED_ROW_ENTITIES";
export const MONITORD_BY_FILTER_LIST = "MONITORD_BY_FILTER_LIST";
export const SET_QUICK_FILTER = "SET_QUICK_FILTER";
