import {
  FETCH_ACCOUNT,
  FETCH_AXA_ACCOUNT,
  FETCH_ACCOUNT_SUCCESS,
  FETCH_ACCOUNT_FAILURE,
  FETCH_ACCOUNT_LIST_FAILURE,
  FETCH_ACCOUNT_LIST_SUCCESS,
} from './actionTypes'
import fetchStatusTypes from './../fetchStatusTypes'

const account = (
  state = {
    isFetching: false,
    fetchStatus: fetchStatusTypes.UNDEFINED,
    didInvalidate: false,
    item: {},
    accounts:[]
  },
  action,
) => {
  switch (action.type) {
    case FETCH_ACCOUNT:
    case FETCH_AXA_ACCOUNT:
      return Object.assign({}, state, {
        isFetching: true,
        fetchStatus: fetchStatusTypes.FETCHING,
        didInvalidate: false,
        item: {},
      })
    case FETCH_ACCOUNT_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        fetchStatus: fetchStatusTypes.COMPLETE,
        didInvalidate: false,
        // normalize `userName`
        item: Object.assign({}, action.item, {
          userName: action.item.name || action.item.username,
        }),
      })
    case FETCH_ACCOUNT_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        fetchStatus: fetchStatusTypes.FAIL,
        didInvalidate: true,
        errorMessage: action.errorMessage,
        item: {},
      })
      case FETCH_ACCOUNT_LIST_SUCCESS:
        return Object.assign({}, state, {
          accounts:action.accounts,
        })
      case FETCH_ACCOUNT_LIST_FAILURE:
        return Object.assign({}, state, {
          errorMessage: action.errorMessage,
        })
    default:
      return state
  }
}

export default account
