import { SET_ECOMETER_ADMIN,
} from './actionTypes'
import fetchStatusTypes from './../fetchStatusTypes'

const wasp = (state = {
  isFetching: false,
  fetchStatus: fetchStatusTypes.UNDEFINED,
  didInvalidate: false,

}, action) => {
  switch (action.type) {
    case SET_ECOMETER_ADMIN:
      return Object.assign({}, state, {
        isFetching: false,
        fetchStatus: fetchStatusTypes.COMPLETE,
        didInvalidate: false,
        ecometerAdmin: action.ecometerAdmin,
      })
    default:
      return state
  }
}
export default wasp