// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //dashError
  content["Dashboard failed to load."] =
  "ダッシュボードのロードに失敗しました。";

  //Systemcomponentmissing message
  content["System component missing"] =
  "システム コンポーネントが見つかりません";

  //missing component
  content["CA Business Intelligence (CABI) system component is needed."] =
  "CA Business Intelligence (CABI) システム コンポーネントが必要です。";

  // Installation and configuration instructions
  content["Installation and configuration instructions"] =
  "インストールと設定の手順";

  //Breadcrumb
  content["Dashboards"] =
  "ダッシュボード";

  //Breadcrumb
  content["Overview"] =
  "概要";

  // END OF LOCALIZATION

export default content;
