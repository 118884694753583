// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Cancel
  content["Cancel"] =
  "Cancel";

  //Ok
  content["Ok"] =
  "Ok";

  //Device Details
  content["Device Detail"] =
  "Device Detail";

  //Group Details
  content["Group Detail"] =
  "Group Detail";

  //Interfcae Details
  content["Interface Detail"] =
  "Interface Detail";

  //mcs Details
  content["MCS Profiles"] =
  "MCS Profiles";

  //policy Details
  content["Alarm Policies"] =
  "Alarm Policies";

  //Export Group Details
  content["Export Group Details"] =
  "Export Group Details";

  //IPAddress
  content["IP address"] =
  "IP address";

  //Highest Alarm Severity Level and Total Alarm Count
  content["Highest Alarm Severity Level"] =
  "Highest Alarm Severity Level";

  // tooltipField: "maxAlarmSeverity.label",
  content["Max Alarm Severity"] =
  "Max Alarm Severity";

  //Name
  content["Name"] =
  "Name";

  //Type
  content["Type"] =
  "Type";

  //Elements
  content["Count"] =
  "Count";

  //Name message
  content["Display Name"] =
  "Display Name";

  //Alias message
  content["Display Alias"] =
  "Display Alias";

  //Description message
  content["Description"] =
  "Description";

  //instanceName message
  content["Instance Name"] =
  "Instance Name";

  //alias
  content["Alias"] =
  "Alias";

  //devicetype
  content["Device Type"] =
  "Device Type";

  //caption
  content["Caption"] =
  "Caption";

  //dedicated
  content["Dedicated"] =
  "Dedicated";

  //origin
  content["Origin"] =
  "Origin";

  //latestorigin
  content["Latest Origin"] =
  "Latest Origin";

  //mac
  content["MAC Address"] =
  "MAC Address";

  //monitor
  content["Monitored By"] =
  "Monitored By";

  //robot
  content["Bus Type"] =
  "Bus Type";

  //csid
  content["CS Id"] =
  "CS Id";

  //cskey
  content["CS Key"] =
  "CS Key";

  //discover
  content["Discovered"] =
  "Discovered";

  //changetime
  content["Last Updated"] =
  "Last Updated";

  //deleted
  content["Removed"] =
  "Removed";

  //OS TYPE
  content["OS Type"] =
  "OS Type";

  //Operating system type
  content["Operating System Type"] =
  "Operating System Type";

  //osname
  content["OS Name"] =
  "OS Name";

  //Operating system name
  content["Operating System Name"] =
  "Operating System Name";

  //osversion
  content["OS Version"] =
  "OS Version";

  //Operating system version
  content["Operating System Version"] =
  "Operating System Version";

  //osdesc
  content["OS Description"] =
  "OS Description";

  //Operating system desc
  content["Operating System Description"] =
  "Operating System Description";

  //tag1
  content["User Tag 1"] =
  "User Tag 1";

  //usertag1
  content["UserTag 1"] =
  "UserTag 1";

  //tag2
  content["User Tag 2"] =
  "User Tag 2";

  //usertag2
  content["UserTag 2"] =
  "UserTag 2";

  //IP address
  content["IP Address"] =
  "IP Address";

  //ipaddress
  content["IPAddress"] =
  "IPAddress";

  //Has Robot
  content["Has Robot"] =
  "Has Robot";

  //Has KeyIcon
  content["Has KeyIcon"] =
  "Has KeyIcon";

  //No Data Found
  content["No Data Found"] =
  "No Data Found";

  //Try different filter options
  content["Try different filter options"] =
  "Try different filter options";

  // No results found
  content["No results found"] =
  "No results found";

  // Collapsed Tree
  content["Collapsed Tree"] =
  "Collapsed Tree";

  //Groups
  content["Groups"] =
  "Groups";

  //NONE
  content["NONE"] =
  "NONE";

  //CRITICAL
  content["CRITICAL"] =
  "CRITICAL";

  //MAJOR
  content["MAJOR"] =
  "MAJOR";

  //MINOR
  content["MINOR"] =
  "MINOR";

  //WARNING
  content["WARNING"] =
  "WARNING";

  // INFO
  content["INFO"] =
  "INFO";

  //Group(s)
  content["Groups "] =
  "Groups ";

  //Loading
  content["Loading... "] =
  "Loading... ";

  // Search Group
  content["Search Group"] =
  "Search Group";

  // Select
  content["Select"] =
  "Select";

  // END OF LOCALIZATION

export default content;
