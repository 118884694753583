export const REQUEST_AGENTS = 'REQUEST_AGENTS'
export const REQUEST_AGENTS_STATUS = 'REQUEST_AGENTS_STATUS'
export const FETCH_AGENTS = 'FETCH_AGENTS'
export const FETCH_AGENTS_SUCCESS = 'FETCH_AGENTS_SUCCESS'
export const FETCH_AGENTS_FAILURE = 'FETCH_AGENTS_FAILURE'
export const FETCH_AGENTS_STATUS = 'FETCH_AGENTS_STATUS'
export const FETCH_AGENTS_STATUS_SUCCESS = 'FETCH_AGENTS_STATUS_SUCCESS'
export const FETCH_AGENTS_STATUS_FAILURE = 'FETCH_AGENTS_STATUS_FAILURE'
export const POLL_AGENTS_START = 'POLL_AGENTS_START'
export const POLL_AGENTS_STOP = 'POLL_AGENTS_STOP'
export const POLL_AGENTS_STATUS_START = 'POLL_AGENTS_STATUS_START'
export const POLL_AGENTS_STATUS_STOP = 'POLL_AGENTS_STATUS_STOP'
export const SELECTED_AGENT = 'SELECTED_AGENT'
export const GET_JOBS_RUNNING = 'GET_JOBS_RUNNING'
export const FETCH_JOBS_RUNNING_SUCCESS = 'FETCH_JOBS_RUNNING_SUCCESS'
export const FETCH_JOBS_RUNNING_FAILURE = 'FETCH_JOBS_RUNNING_FAILURE'
export const POLL_JOBS_RUNNING_START = 'POLL_JOBS_RUNNING_START'
export const POLL_JOBS_RUNNING_STOP = 'POLL_JOBS_RUNNING_STOP'
export const CUSTOM_SCOPE_DISCOVERY = 'CUSTOM_SCOPE_DISCOVERY'
