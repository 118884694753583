import {
  REQUEST_ROBOTS_FOR_UPGRADE,
  FETCH_ROBOTS_FOR_UPGRADE_SUCCESS,
  FETCH_ROBOTS_FOR_UPGRADE_FAILURE
} from './actionTypes'
import isEqual from 'lodash/isEqual'

const upgradeableRobots = (
  state = {
    isFetching: false,
    didInvalidate: false,
    items: []
  },
  action
) => {
  switch (action.type) {
    case REQUEST_ROBOTS_FOR_UPGRADE:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false
      })
    case FETCH_ROBOTS_FOR_UPGRADE_SUCCESS:
      const items = action.robots._items
      const updatedState = Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        items: items
      })
      return isEqual(updatedState, state) ? state : updatedState
    case FETCH_ROBOTS_FOR_UPGRADE_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        errorMessage: action.errorMessage,
        items: []
      })

    default:
      return state
  }
}

export default upgradeableRobots
