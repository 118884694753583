import {
  REQUEST_HISTORIC_ALARMS_FOR_CS,
  FETCH_HISTORIC_ALARMS_SUCCESS_FOR_CS,
  FETCH_HISTORIC_ALARMS_FAILURE_FOR_CS
} from './actionTypes'

import {
  requestAlarms,
  fetchAlarmsSuccess,
  fetchAlarmsFailure
} from './utils'

const historicAlarms = (state = {} , action) => {
  switch (action.type) {
    case REQUEST_HISTORIC_ALARMS_FOR_CS:
      return requestAlarms(state,action)
    case FETCH_HISTORIC_ALARMS_SUCCESS_FOR_CS:
       return fetchAlarmsSuccess(state,action)
    case FETCH_HISTORIC_ALARMS_FAILURE_FOR_CS:
      return fetchAlarmsFailure(state,action)
    default:
      return state
  }
};

export default historicAlarms
