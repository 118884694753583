// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //OK
  content["OK"] =
  "Aceptar";

  // END OF LOCALIZATION

export default content;
