import React from 'react'
import axios from 'axios'

class BrandProduct extends React.Component{
    state = {
      pName: '',
    }
    componentDidMount() {
      axios.get('/operatorconsole_portlet/api/v1/whiteLabel/brandProduct'
      )
        .then(res => {
          const product = res.data.brand_product;            
          this.setState({pName: product});        
        })      
        
    }
    render() {
      if(this.state.pName=='' || this.state.pName== null){
        return(<p1>Operator Console</p1>)
   }else{
      return (<p1>{this.state.pName}</p1>);
   }
    }
  }

  export default BrandProduct