// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Redirection failed
  content["Redirection failed"] =
  "Redirection failed";

  //Redirection error
  content["Verify if the profile associated with the group or device exists."] =
  "Verify if the profile associated with the group or device exists.";

  //OK
  content["OK"] =
  "OK";

  //settings
  content["Settings"] =
  "Settings";

  //alarm policies
  content["Alarm Policies"] =
  "Alarm Policies";

  //Inventory
  content["Inventory"] =
  "Inventory";

  //Setup Wizard
  content["Setup Wizard"] =
  "Setup Wizard";

  //Remote And Cloud
  content["Remote And Cloud"] =
  "Remote And Cloud";

  // END OF LOCALIZATION

export default content;
