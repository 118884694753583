import { CLOSE_MODAL, OPEN_MODAL } from './actions'

export default function selectedModal(state = null, action = {}) {
  switch (action.type) {
    case CLOSE_MODAL:
      return null
    case OPEN_MODAL:
      return action.id
    default:
      return state
  }
}
