import React, { Component } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import CrossLaunch from '../crossLaunch/CrossLaunch'
import UtilPoller from './../../utils/poller'
import {CircularProgress} from '@mineral/core'
import './configuration.less'
import axios from "axios";

class Configuration extends Component {
  state = {
    sid: null,
  }
  _renewSid = () => {
      let overrideSid=axios.defaults.headers.common["Authorization"];
      if(window.OPERATOR_CONSOLE.refreshToken && window.OPERATOR_CONSOLE.refreshToken!=="")
          {
          overrideSid=window.OPERATOR_CONSOLE.refreshToken;
          axios.defaults.headers.common["Authorization"]=window.OPERATOR_CONSOLE.refreshToken;
          window.OPERATOR_CONSOLE.refreshToken="";
          
          }   
   let sid=overrideSid;
        if (this.state.sid) {
          // we don't want to set the state here or MCS iframe will rerender
          // but we do need to update `window.mcs.sid`
          this._setWindowContext(sid)
          return this
        }
        this.setState({ sid: sid }, this._setWindowContext)
     
  }
  _setWindowContext = (sid = this.state.sid) => {
    let groupId = this.props.groupId || get(this.props.match, 'params.groupId')
    let launchFrom = this.props.launchFrom || 'configuration'
    let probeName =
      get(this.props, 'probe.name') || get(this.props, 'match.params.probe')
    let useEnhancedTemplates = get(
      this.props.featureFlags,
      'useEnhancedTemplates',
      true
    )
    let probeType = get(this.props, 'probe.probeType')|| get(this.props, 'match.params.probeType')
    let callbackProbeList=this.props.featureFlags.callbackProbeList?this.props.featureFlags.callbackProbeList:''
    let probeWhiteList=useEnhancedTemplates?(this.props.featureFlags.probeWhiteListSTB?this.props.featureFlags.probeWhiteListSTB:''):this.props.featureFlags.probeWhiteList?this.props.featureFlags.probeWhiteList:''
    let callbackEnabled = callbackProbeList.includes(probeName)
    let csId = this.props.csId
    let profileInfo = this.props.location.state && this.props.location.state.profileInfo?this.props.location.state.profileInfo:null;
     
    if (this.props.groupId) {
      launchFrom = 'groups'
    }
    if (this.props.csId) {
      launchFrom = 'computer-systems'
    }
    let profileId = get(this.props.match, 'params.profileId'),onMaxEdit = false;
    if(profileId){
    	onMaxEdit = true;
    }
    // we're enabling mcs to access these values via `window.top.location`
    // to resolve an issue when navigating `back` from the flash UI
    window.mcs = Object.assign({}, window.mcs, {
      sid: sid,
      probeName: probeName,
      probeType: probeType,
      probeWhiteList:probeWhiteList,
      callbackEnabled:callbackEnabled,
      callbackProbeList:callbackProbeList,
      isSaas: true,
      autoOpen: this.props.location.state && this.props.location.state.autoOpen?true:false,
      profileInfo:profileInfo,
      hideBreadcrumbs: this.props.hideBreadcrumbs,
      launchPoint: this.props.launchPoint,
      groupId: groupId,
      profileId:profileId,
      onMaxEdit:onMaxEdit,
      handleDialogClose: this.props.handleDialogClose,
      csId: csId,
      probe: this.props.probeAddress,
      ip: this.props.ip,
      launchFrom: launchFrom,
      setupWizardComp: {
        scope: this.props.setupWizardScope,
        setActive: this.props.setActive,
        onMaxProfileEdit:this.props.onMaxProfileEdit,
        redirectToLocation:this.props.redirectToLocation
      },
    })
  }
  componentWillMount() {
    this._renewSid()
    if (this.poller) {
      this.poller.stop()
    }
    this.poller = UtilPoller(() => this._renewSid())
    this.poller.start()
  }
  componentWillUnmount() {
    if (this.poller) {
      this.poller.stop()
    }
  }
  render() {
    let view = (
      <div className="configuration__spinner" style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <CircularProgress style={{color: '#3272D9'}} />
      </div>
    )
    let useEnhancedTemplates = get(
      this.props.featureFlags,
      'useEnhancedTemplates',
      true
    )

    let probeName =
      get(this.props, 'probe.name') || get(this.props, 'match.params.probe')
    let probeType = get(this.props, 'probe.probeType')|| get(this.props, 'match.params.probeType')
    let callbackProbeList=this.props.featureFlags.callbackProbeList?this.props.featureFlags.callbackProbeList:''
    let probeWhiteList=useEnhancedTemplates?(this.props.featureFlags.probeWhiteListSTB?this.props.featureFlags.probeWhiteListSTB:''):this.props.featureFlags.probeWhiteList?this.props.featureFlags.probeWhiteList:''
	  let callbackEnabled = callbackProbeList.includes(probeName)
    let mcsGroupId = get(this.props.match, 'params.groupId')
    let launchFrom = this.props.launchFrom
    let autoOpen = this.props.location.state && this.props.location.state.autoOpen?true:false
    let do_heartbeat = window.OPERATOR_CONSOLE.do_heartbeat
    if (do_heartbeat !== 'true' && do_heartbeat !== 'false') {
      do_heartbeat = false
    }
    if (!launchFrom) {
      launchFrom = 'configuration'
    }
    let profileId = get(this.props.match, 'params.profileId'),onMaxEdit = false;
    let showonlyremotetemplate=this.props.showonlyremotetemplate?this.props.showonlyremotetemplate:false
    if(profileId){
    	onMaxEdit = true;
    }
    
    let url = [
      `/mcs-ui-app/standalone.jsp`,
      `?sid=${this.state.sid}`,
      `&probeName=${probeName}`,
      `&probeType=${probeType}`,
      `&callbackEnabled=${callbackEnabled}`,
      `&probeWhiteList=${probeWhiteList}`,
      `&isSaas=true`,
      `&hideBreadcrumbs=${this.props.hideBreadcrumbs}`,
      `&launchPoint=${this.props.launchPoint}`,
      `&groupId=${mcsGroupId}`,
      `&probe=${this.props.probeAddress}`,
      `&ip=${this.props.ip}`,
      `&launchFrom=${launchFrom}`,
      `&do_heartbeat=${do_heartbeat}`,
      `&useEnhancedTemplates=${useEnhancedTemplates}`,
      `&profileId=${profileId}`,
      `&onMaxEdit=${onMaxEdit}`,
      `&autoOpen=${autoOpen}`
    ]
    if (this.props.groupId) {
      url = [
        `/mcs-ui-app/standalone.jsp`,
        `?sid=${this.state.sid}`,
        `&groupId=${this.props.groupId}`,
        `&probeWhiteList=${probeWhiteList}`,
        `&callbackProbeList=${callbackProbeList}`,
        `&isSaas=true`,
        `&hideBreadcrumbs=${this.props.hideBreadcrumbs}`,
        `&launchPoint=${this.props.launchPoint}`,
        `&launchFrom=groups`,
        `&do_heartbeat=${do_heartbeat}`,
        `&useEnhancedTemplates=${useEnhancedTemplates}`,
        `&profileId=${profileId}`,
        `&onMaxEdit=${onMaxEdit}`,
        `&autoOpen=${autoOpen}`,
        `&showonlyremotetemplate=${showonlyremotetemplate}`
      ]
    }
    if (this.props.csId) {
      url = [
        `/mcs-ui-app/standalone.jsp`,
        `?sid=${this.state.sid}`,
        `&csId=${this.props.csId}`,
        `&probeWhiteList=${probeWhiteList}`,
        `&callbackProbeList=${callbackProbeList}`,
        `&isSaas=true`,
        `&hideBreadcrumbs=${this.props.hideBreadcrumbs}`,
        `&launchPoint=${this.props.launchPoint}`,
        `&launchFrom=computer-systems`,
        `&do_heartbeat=${do_heartbeat}`,
        `&useEnhancedTemplates=${useEnhancedTemplates}`,
        `&profileId=${profileId}`,
        `&onMaxEdit=${onMaxEdit}`,
        `&autoOpen=${autoOpen}`,
        `&showonlyremotetemplate=${showonlyremotetemplate}`
      ]
    }
    if (this.state.sid) {
      view = (
        <CrossLaunch
          src={url.join('')}
          sid={this.state.sid}
          isSetupWizard={this.props.isSetupWizard}
          probe={probeName}
          probeType={probeType}
        />
      )
    }
    return view
  }
}
Configuration.propTypes = {
  featureFlags: PropTypes.object.isRequired,
  sid: PropTypes.string.isRequired,
}
export default Configuration
