// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Highest Alarm Severity Level and Total Alarm Count
  content["Highest Alarm Severity Level and Total Alarm Count"] =
  "Nivel de severidad de alarma más alto y recuento total de alarmas";

  //Max Alarm Severity
  content["Max Alarm Severity"] =
  "Nivel de severidad de alarma máximo";

  //Name
  content["Name"] =
  "Nombre";

  //Elements
  content["Elements"] =
  "Elementos";

  //Device Type
  content["Device Type"] =
  "Tipo de dispositivo";

  //Operating system
  content["Operating system"] =
  "Sistema operativo";

  //IP address
  content["IP address"] =
  "Dirección IP";

  //Monitored by
  content["Monitored by"] =
  "Monitorizado por";

  //Has Robot
  content["Has Robot"] =
  "Tiene robot";

  //Discovered
  content["Discovered"] =
  "Detectado";

  //No Data Found
  content["No Data Found"] =
  "No se han encontrado datos";

  //Try different filter options
  content["Try different filter options"] =
  "Intente utilizar diferentes opciones de filtro";

  // No results found
  content["No results found"] =
  "No se ha encontrado ningún resultado.";

  // No devices have been added
  content["No devices have been added"] =
  "No se ha agregado ningún dispositivo.";

  //No subgroups have been added
  content["No subgroups have been added"] =
  "No se ha agregado ningún subgrupo.";

  //No profiles in  this group
  content["No profiles in this group"] =
  "No existe ningún perfil en este grupo.";

  //No devices found
  content["No devices found"] =
  "Ningún dispositivo encontrado";

  //No devices have been added yet.
  content["No devices have been added yet."] =
  "No se ha agregado ningún dispositivo todavía.";

  // END OF LOCALIZATION

export default content;
