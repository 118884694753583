import React, { Component } from 'react'
import { Button as RaisedButton, Divider } from '@mineral/core'
import get from 'lodash/get'
import connect from './../../utils/connect'
import RobotHubSelect from './RobotHubSelect'
import RobotDeployList from './RobotDeployListRedux'
import ManualRobotDeploy from './ManualRobotDeploy'
import DeployPending from './deployedMessage/DeployPending'
import DeploySuccess from './deployedMessage/DeploySuccess'
import DeployFailed from './deployedMessage/DeployFailed'
import DeployErrorDialog from './deployErrorDialog/DeployErrorDialog'
import DeploymentStatusRedux from './../setupWizard/DeploymentStatusRedux'
import './robotDeployWizard.less'
import { saveRobots } from './../../api/robots/actions'
import {
  setClientRobots,
  setAdeRobotAddress,
  setAdeJobIds,
} from './clientRobots/actions'
import AgentlessMonitoring from './agentlessMonitoring/AgentlessMonitoring'
import DeployErrorMessage from './deployedMessage/DeployErrorMessage'
import { resetRobotsStatus } from '../../api/robotsStatus/actions'
import { setSelectedHub } from '../../api/hubs/actions'
import './tabs.less'
import Resource from './Resource'

import theme from '../../../src/theme'
//AUTO DEPLOY
const autoDeploy = Resource.get('Auto Deploy');
//MANUAL DEPLOY
// const manualDeploy = Resource.get('MANUAL DEPLOY');
class RobotDeploy extends Component {
  // sticking this in the state will cause redundant renders resulting in perf issues
  robots = this.props.robots || []
  state = {
    view: 'auto',
    hub: this.props.hub|| null,
    fieldErrors: {},
    submitted: this.props.submitted || false,
    failedRobotsStatus: this.props.failedRobots || [],
    succeededRobotsStatus: this.props.succeededRobots || [],
    pendingRobotsStatus: this.props.pendingRobots || [],
    adeRobotAddress: null,
    adeJobIds: null,
    failedRobotDeploy: null,
  }
  componentWillUnmount() {
    this.props.onExit(this.robots)
	this.props.onExitHub(this.state.hub)
  }
  _reset = (robots = []) => {
    this.robots = robots
    this.setState({
      fieldErrors: {},
      submitted: false,
      failedRobotsStatus: [],
      succeededRobotsStatus: [],
      pendingRobotsStatus:[],
      adeRobotAddress: null,
      adeJobIds: null,
      failedRobotDeploy: null,
    })
  }
  _statusMessage = () => {
    const succeeded = this.state.succeededRobotsStatus
    const failed = this.state.failedRobotsStatus
    const pending = this.state.pendingRobotsStatus
    let statusMessage = <DeployPending />

    if (succeeded && succeeded.length) {
      statusMessage = <DeploySuccess />
    }
    if (failed && failed.length) {
      statusMessage = (
        <DeployFailed
          onClick={() => {
            const failedRobotHostnames = this.state.failedRobotsStatus.map(
              robotsStatus => robotsStatus.host,
            )
            const failedRobots = this.robots
              .map(robot => {
                const failedRobotStatus = this.state.failedRobotsStatus.find(
                  robotStatus => robotStatus.host === robot.hostname,
                )
                return Object.assign({}, robot, {
                  statusError: get(failedRobotStatus, 'errorMessage'),
                })
              })
              .filter(robot => failedRobotHostnames.includes(robot.hostname))
            this._reset(failedRobots)
          }}
        />
      )
    }
    if(pending && pending.length){
      statusMessage = <DeployPending />
    }
    return statusMessage
  }
  getDeployFormat = ({ component: Component }) => {
    //console.log('component----',Component)
    console.log('this.state.hub--',this.state.hub);
    console.log('this.robots--',this.robots);


    return (
      <Component
        hub={this.state.hub}
        robots={this.robots}
        probe={this.props.probe}
        onChange={(robots, hasErrors) => {
          const robotErrors = this.state.fieldErrors['robots']
          this.robots = robots
          if (robotErrors !== hasErrors) {
            this.setState({
              fieldErrors: Object.assign({}, this.state.fieldErrors, {
                robots: hasErrors,
              }),
            })
          }
        }}
      />
    )
  }

  checkForEmptyFields  = (robots) => {
    console.log('this.props.probe.displayname--',this.props.probe.displayname)
    let robotsValues = robots.map((item, i) => {
      if(this.props.probe.displayname !== 'Linux') {
        delete item.sudoPassword
      }
      if(this.props.probe.displayname !== 'Windows') {
        delete item.nonAdminUserName
        delete item.nonAdminUserPassword

      }
      let values = Object.values(item) 
      return [...values]
    })

    const val = (currVal) => currVal === ""

    return robotsValues.flat().some(val)
  }

  validate = () => {
    let invalid = false
    const fieldErrors = this.state.fieldErrors
    // return on first fieldError
    const hasError = !!Object.keys(fieldErrors).find(key => fieldErrors[key])

    if (!this.state.hub) {
      invalid = true
    }
    if (!this.robots.length) {
      invalid = true
    }
    if (this.state.submitted) {
      invalid = true
    }
    // if (hasError) {
    //   invalid = true
    // }
    return invalid
  }

  selectHub = (hub) => {
    if (hub === 'add_hub') {
      this.props.setWizardPage(0)
    } else {
      this.setState({ hub })
      this.props.onHubChange(hub)
    }
  }

  deployRobots = () => {
    this.props.resetRobotsStatus()
    const hub = this.state.hub
    this.state.probe=this.props.probe
    const _robots = this.robots.map(robot => {
      let _robot = {
        hostname: robot.hostname,
        robotIp: robot.hostname,
        profile: robot.profile,
        arch: robot.arch,
        username: robot.username,
        password: robot.password,
        hubIp: hub.hubIp,
        hubRobotAddress: hub.hubRobotAddress,
      }
      if (robot.sudoPassword) {
        _robot['sudoPassword'] = robot.sudoPassword
      }

      if(robot.nonAdminUserName)
      {
        _robot['nonAdminUserName'] = robot.nonAdminUserName
      }

      if(robot.nonAdminUserPassword)
      {
        _robot['nonAdminUserPassword'] = robot.nonAdminUserPassword
      }
      return _robot
    })
    this.props.saveRobots(_robots).then(
      response => {
        const robots = response.data._items
        // adeRobotAddress is hubRobotAddress
        // we expect all robots to have the same hubRobotAddress so just grab the first one
        const adeRobotAddress = (robots.find(robot => robot.hubRobotAddress) ||
          {}).hubRobotAddress
        const adeJobIds = robots
          .filter(robot => robot.adeJobId)
          .map(robot => robot.adeJobId)
        this.props.submit({ submitted: true, robots: robots })
        this.robots = robots
        this.setState({
          submitted: true,
          adeRobotAddress,
          adeJobIds,
          pendingRobotsStatus: robots,
        })
        this.props.setAdeRobotAddress(adeRobotAddress)
        this.props.setAdeJobIds(adeJobIds)
        this.props.setRobots(robots)
        if(this.state.hub  && this.state.hub !== 'add_hub'){
          this.props.setSelectedHub(hub);
        }
      },
      error => {
        this.setState({
          failedRobotDeploy: {
        	//Failed Robot Deployment
            title: error.statusPhrase || Resource.get('Failed Robot Deployment'),
            //Check your server for proper configuration
            message:
            this.checkForEmptyFields(this.robots) ? 'fields should not be empty' : error.error || Resource.get('Check your server for proper configuration.'),
          },
        })
      },
    )
  }

  render() {
    let hubClasses = 'hub__section'
    let robotDeployFormType = RobotDeployList
    let statusError = null
    //Click "Deploy" to add the devices to UIM
    let typeOfDevice = Resource.get('Click Deploy to add the devices to UIM. Enabling monitoring happens on the next step.')

    
    if (this.props.probe.probeType === 'remote') {
      hubClasses += ' deploy__monitoringAgent--hubSelect'
      robotDeployFormType = AgentlessMonitoring
      //Click deploy to install the monitoring host.  When complete, click Next.
     typeOfDevice = Resource.get('Click deploy to install the monitoring host.  When complete, click Next.')

      statusError = get(this.robots.find(robot => robot.statusError), 'statusError')
    }
    let robotDeployForm = this.getDeployFormat({ component: robotDeployFormType })

    const submitted = this.state.submitted
    const failedRobotDeploy = this.state.failedRobotDeploy

    return (
      <article className="wizard__robot-deploy">
        <DeployErrorDialog
          open={!!failedRobotDeploy}
          title={get(failedRobotDeploy, 'title')}
          message={get(failedRobotDeploy, 'message')}
          handleClose={() => this.setState({ failedRobotDeploy: null })}
        />
        {submitted
          ? <DeploymentStatusRedux
            fixLink={false}
            probe={this.probe || this.props.probe}
            adeRobotAddress={this.state.adeRobotAddress}
            adeJobIds={this.state.adeJobIds}
            succeeded={this.state.succeededRobotsStatus.length}
            failed={this.state.failedRobotsStatus.length}
            pending={this.state.pendingRobotsStatus.length}
            onStatusChange={({
                succeeded = [],
              failed = [],
              pending = [],
              }) => {
              let totalCombinationOfStuff = {
                succeeded: succeeded,
                failed: failed,
                pending: pending,
              }
              this.props.onDeployedStatusChange(totalCombinationOfStuff)
              this.setState({
                succeededRobotsStatus: succeeded,
                failedRobotsStatus: failed,
                pendingRobotsStatus: pending,
              })
            }}
            onComplete={({
                succeeded = [],
              failed = [],
              robotsStatus = [],
              }) => {
              this.props.onComplete(robotsStatus)
              this.setState({
                succeededRobotsStatus: succeeded,
                failedRobotsStatus: failed,
                pendingRobotsStatus:[]
              })
            }}
          />
          : null}
        {statusError && (this.props.probe.probeType === 'remote')
          ? <section className="deploy-error">
            <DeployErrorMessage helperText={statusError} />
          </section>
          : null}
        {submitted
          ? null
          : <div className={hubClasses}>
            <RobotHubSelect onChange={(hub) => this.selectHub(hub)} supportedOs={this.props.probe.supportedOS} hub={this.state.hub}/>
          </div>}
        {(this.props.probe.probeType === 'local') &&
          !submitted
          ? <div>
            <div className="tabcontainer">
              <button
                className={
                  this.state.view === 'auto'
                    ? 'tab tab--active'
                    : 'tab tab--inactive'
                } tabIndex={-1} style={{textTransform:'none'}}
                onClick={() => this.setState({ view: 'auto' })}
              >
                {autoDeploy}
                </button>
              {/* <button
                className={
                  this.state.view === 'manual'
                    ? 'tab tab--active'
                    : 'tab tab--inactive'
                }
                onClick={() => this.setState({ view: 'manual' })}
              >
                {manualDeploy}
                </button> */}
              <div style={{
                left: `${this.state.view === 'auto' ? '0%' : '50%'}`,
                width: '50%',
                bottom: '0px',
                display: 'block',
                backgroundColor: '#3272D9',
                height: '2px',
                marginTop: '-2px',
                position: 'relative',
                transition: 'left 1s cubic-bezier(0.23, 1, 0.32, 1) 0ms',
              }}></div>
            </div>
            <Divider />
          </div>
          : null}
        {this.state.view === 'auto'
          ? <section className="robot__section">
            {submitted ? null : robotDeployForm}
            {submitted ? this._statusMessage() : null}
            {submitted ? null :
              (
                <aside className="robot__actions">
                  <label className="robot__actions-label">
                    {typeOfDevice}
                  </label>
                  <RaisedButton
                  variant="contained"
                  //Deploy
                    children={Resource.get("Deploy")}
                    
                    disabled={this.validate()}
                    onClick={() => this.deployRobots()}
                  />
                </aside>
              )}
          </section>
          : <ManualRobotDeploy
            selectedInstaller={this.props.probe.name.toLowerCase()}
            supportedOs={this.props.probe.supportedOS}
          />}
      </article>
    )
  }
}

const mapStateToProps = state => {
  return {
    isCreating: state.robots.isCreating,
    didInvalidateCreate: state.robots.didInvalidateCreate,
  }
}
const mapDispatchToProps = dispatch => {
  return {
    saveRobots: data => dispatch(saveRobots(data)),
    setRobots: robots => dispatch(setClientRobots(robots)),
    setAdeRobotAddress: adeRobotAddress =>
      dispatch(setAdeRobotAddress(adeRobotAddress)),
    setAdeJobIds: adeJobIds => dispatch(setAdeJobIds(adeJobIds)),
    resetRobotsStatus: () => {
      dispatch(resetRobotsStatus())
    },
    setSelectedHub:selectedHub => 
     dispatch(setSelectedHub(selectedHub)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(RobotDeploy)
