import axios from "axios";
import {ROUTE_DASHBOARD_MAP} from "./routeDashboardMapping";

class Cabi {
  constructor() {
    this._dashToRouteMap = undefined;
    this._propToDashMap = undefined;
  }

  get(locale = "EN", sid = null) {
    const postbody = {
      locale: locale
    }
    if (process.env.NODE_ENV == 'development') {
      return axios.get(`http://10.252.25.208:8080/castlemock/mock/rest/project/TK8t3o/application/UXxhjW/cabi_system_info`)
    }
    return axios.post(`/cabi/api/server_info`, postbody)
  }

  getDashToRouteMapping() {
    if (!this._dashToRouteMap) {
      let dashToRouteMap = {};

      for (var i = 0; i < ROUTE_DASHBOARD_MAP.length; i++) {
        let dashEntry = ROUTE_DASHBOARD_MAP[i];

        // map routes based on dashboard path
        dashToRouteMap[dashEntry.path] = {
          idValue: dashEntry.idValue,
          opConId: dashEntry.opConId,
          cabiId: dashEntry.cabiId,
          contextParams: dashEntry.contextParams,
          route: dashEntry.route,
        };
      }

      this._dashToRouteMap = dashToRouteMap;
    }

    return this._dashToRouteMap;
  }

  getPropToDashMappingFromApi() {
    const url = '/operatorconsole_portlet/api/v1/wasp/routedashboardmap'
    if (process.env.NODE_ENV == 'development') {
      return axios.get(
        "http://10.252.25.208:8080/castlemock/mock/rest/project/TK8t3o/application/UXxhjW/wasp/routedashboardmap"
      );
    }
    return axios.get(url);
  }

  getPropToDashMapping() {
    if (!this._propToDashMap) {
      let propToDashMap = {};

      for (let i = 0; i < ROUTE_DASHBOARD_MAP.length; i++) {
        let dashEntry = ROUTE_DASHBOARD_MAP[i];

        // map dashboard paths by op con property
        let propEntry = propToDashMap[dashEntry.opConId];
        if (!propEntry) propEntry = {};

        if (dashEntry.entityType) {
          // if entry had an entity type, map within the property entry
          if (!propEntry.entityTypeMap) propEntry.entityTypeMap = {};
          propEntry.entityTypeMap[dashEntry.entityType] = dashEntry.path;
        } else if (dashEntry.idValue) {
          // if entry had a specified id value, map within the property entry
          if (!propEntry.idValueMap) propEntry.idValueMap = {};
          propEntry.idValueMap[dashEntry.idValue] = dashEntry.path;
        } else {
          // otherwise map path as default for that property
          propEntry.path = dashEntry.path;
        }

        propToDashMap[dashEntry.opConId] = propEntry;
      }

      this._propToDashMap = propToDashMap;
    }

    return this._propToDashMap;
  }
}

const cabi = new Cabi();
export default cabi;
