// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //List Viewer
  content["List Viewer"] =
  "List Viewer";

  //Settings
  content["Settings"] =
  "Settings";

  //List Dashboard Designer page
  content["This List Viewer allows administrators to view the lists created"] =
  "This List Viewer allows administrators to view the lists created";

  // END OF LOCALIZATION

export default content;
