// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  // one hour MenuItem
  content["1 Hour"] =
  "1 小时";

  // two hours MenuItem
  content["2 Hours"] =
  "2 小时";

  // twelve hours MenuItem
  content["12 Hours"] =
  "12 小时";

  // one day MenuItem
  content["24 Hours"] =
  "24 小时";

  // three days MenuItem
  content["3 Days"] =
  "3 天";

  // one week MenuItem
  content["1 Week"] =
  "1 周";

  // two weeks MenuItem
  content["2 Weeks"] =
  "2 周";

  // one month MenuItem
  content["1 Month"] =
  "1 个月";

  // three months MenuItem
  content["3 Months"] =
  "3 个月";

  // END OF LOCALIZATION

export default content;
