import React from "react"
import "./icons.less"
const Icon = (props) => {
  let classes = [
    'icon',
    props.className,
  ].join(' ')
  return (
      <svg className={classes} role={props.role || 'figure'} aria-label="[title]"  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g>        
      <path d="M6.5 3V5.5C6.5 7.433 8.067 9 10 9C11.933 9 13.5 7.433 13.5 5.5V3H16C18.2091 3 20 4.79086 20 7V23H4V3H6.5ZM15 13V20H17.5V13H15ZM10.75 11V20H13.25V11H10.75ZM6.5 16V20H9V16H6.5Z" fill="#333840"/>
      <path opacity="0.6" fillRule="evenodd" clipRule="evenodd" d="M8.5 1H11.5V5.5C11.5 6.32843 10.8284 7 10 7C9.17157 7 8.5 6.32843 8.5 5.5V1Z" fill="#333840"/>
      </g>
      </svg>
  );
};

export default Icon;