import axios from "axios";
import config from "./../config";
import entityTypes from "./../entityTypes";

class Group {
  getByPath(group) {
    return axios.get(config.basename + "/api/v2/groups?path=" + group);
  }
  get(params = {}) {
    // TODO: use this code when we have the API support
    // var url = [
    //     config.basename,
    //     'api/v1/groups',
    //   ]
    // if (params.id != null) {
    //   var query = '?_embed=groups&_embed=computer-systems'
    //   url = url.concat([
    //     params.id + query,
    //   ])
    // }
    // return axios.get(url.join('/'))

    // this is a quick fix for getting group results with relationships
    // return results will look like
    // {
    //    id:0,
    //    groups: [],
    //    computer-systems: []
    // }
    const id = params.id || 0;
    let groupUrl =
      process.env.NODE_ENV == "development"
        ? "http://10.252.25.208:8080/castlemock/mock/rest/project/ViXRbO/application/xb77Al/groups/id"
        : [config.basename, "api/v2/groups", id].join("/");

    return axios.get(groupUrl).then((groupsRes) => {
      let group = groupsRes.data;
      const isContainer = (entity) => {
        return (
          entity &&
          (entity.type == entityTypes.ROOT ||
            entity.type == entityTypes.CONTAINER)
        );
      };
      const isInterfaceGroup = (entity) => {
        return entity && entity.masterElementGroup;
      };
      let entityKey = isContainer(group)
        ? "groups"
        : isInterfaceGroup(group)
        ? "interfaceSystems"
        : "computerSystems";
      if (isInterfaceGroup(group) && !group.interfaceSystems) {
        entityKey = "interfaceSystem";
      }
      // if (entityKey == "computerSystems" && !group.computerSystems) {
      //   entityKey = "computerSystem";
      // }
      let groupChildren = group[entityKey];
      let childDetailsUrl = [config.basename];

      if (isContainer(group)) {
        childDetailsUrl =
          process.env.NODE_ENV == "development"
            ? [
                "http://10.252.25.208:8080/castlemock/mock/rest/project/ViXRbO/application/xb77Al/children",
              ]
            : childDetailsUrl.concat(["/api/v2/groups/", id, "/child-details"]);
      } else if (isInterfaceGroup(group)) {
        childDetailsUrl =
          process.env.NODE_ENV == "development"
            ? [
                "http://10.252.25.208:8080/castlemock/mock/rest/project/ViXRbO/application/VgCY5v/interfaces",
              ]
            : childDetailsUrl.concat(["/api/v1/interface?groupId=", id]);
      } else {
        childDetailsUrl =
          process.env.NODE_ENV == "development"
            ? [
                "http://10.252.25.208:8080/castlemock/mock/rest/project/ViXRbO/application/xb77Al/computers",
              ]
            : childDetailsUrl.concat(["/api/v1/computer-systems?groupId=", id]);
      }

      return axios.get(childDetailsUrl.join("")).then((childDetailsRes) => {
        const childDetails = childDetailsRes.data._items;
        childDetails.forEach((detail) => {
          const id = detail.id;
          let groupChild = groupChildren.find(
            (child) => child.id.toString() === id.toString()
          );
          if (!groupChild) {
            console.warn("found child details for unrealted group");
            return;
          }
          Object.assign(groupChild, detail, {
            // need to remap the name here
            memberCount: detail.members,
          });
        });
        group["computer-systems"] = group.computerSystems;
        group["interface-system"] = group.Interfaces;
        groupsRes.data = group;
        return groupsRes;
      });
    });
  }

  post(params = {}) {
    let url;
    if (process.env.NODE_ENV == "development") {
      url =
        "http://10.252.25.208:8080/castlemock/mock/rest/project/TK8t3o/application/ZufrWw/groups";
    } else {
      url = [config.basename, "api/v2/groups"].join("/");
    }

    if (params.type === "DYNAMIC") {
      return axios.post(url, {
        name: params.name.trim(),
        parentId: params.parentId,
        type: params.type,
        criteria: params.criteria,
        masterElementGroup: params.masterElementGroup,
        accountId: params.accountId,
        description: params.description,
      });
    } else if (params.type === "STATIC") {
      return axios.post(url, {
        name: params.name.trim(),
        parentId: params.parentId,
        type: "STATIC",
        masterElementGroup: params.elementType === "DEVICES" ? "0" : "1",
        accountId: params.accountId,
        devicesToAdd: params.devicesToAdd,
        description: params.description,
      });
    } else {
      return axios.post(url, {
        name: params.name.trim(),
        parentId: params.parentId,
        type: params.type,
        accountId: params.accountId,
        description: params.description,
      });
    }
  }
  findMatchingDevices(params = {}, accountId) {
    if (process.env.NODE_ENV == "development") {
      const url =
        "http://10.252.25.208:8080/castlemock/mock/rest/project/TK8t3o/application/UXxhjW/groups/search/-1";
      // "http://10.252.25.208:8080/castlemock/mock/rest/project/kIbM1R/application/USFJhI/";
      return axios.get(url);
    } else {
      const url = [config.basename, "api/v2/groups/search/" + accountId].join(
        "/"
      );
      return axios.post(url, params);
    }
  }
  findInterfaces(params = {}, accountId) {
    if (process.env.NODE_ENV == "development") {
      const url =
        "http://10.252.25.208:8080/castlemock/mock/rest/project/TK8t3o/application/UXxhjW/groups/search/-1";
      // "http://10.252.25.208:8080/castlemock/mock/rest/project/ViXRbO/application/VgCY5v/interfaces";
      return axios.get(url);
    } else {
      const url = [config.basename, "api/v2/groups/search/" + accountId].join(
        "/"
      );
      return axios.post(url, params);
    }
  }
  addDevicesToGroup(params = {}) {
    const url = [
      config.basename,
      "api/v2/groups",
      params.groupId,
      "devices",
    ].join("/");
    return axios.post(url, {
      add: params.add,
      remove: [],
    });
  }
  removeDevicesFromGroup(params = {}) {
    const url = [
      config.basename,
      `api/v2/groups/${params.groupId}/devices`,
    ].join("/");
    return axios.post(url, {
      add: [],
      remove: params.remove,
    });
  }

  addInterfaceToGroup(params = {}) {
    const url = [
      config.basename,
      "api/v2/groups",
      params.groupId,
      "interfaces",
    ].join("/");
    return axios.post(url, {
      add: params.add,
      remove: [],
    });
  }
  removeInterfacesFromGroup(params = {}) {
    const url = [
      config.basename,
      `api/v2/groups/${params.groupId}/interfaces`,
    ].join("/");
    return axios.post(url, {
      add: [],
      remove: params.remove,
    });
  }
  getLeafGroups() {
    const url = [config.basename, "api/v2/groups/alarmSummary"].join("/");
    return axios.get(url);
  }
  getAccountsInfo() {
    const url =
      process.env.NODE_ENV == "development"
        ? "http://10.252.25.208:8080/castlemock/mock/rest/project/TK8t3o/application/UXxhjW/account"
        : [config.basename, "api/v1/accountinfo/accounts"].join("/");
    return axios.get(url);
  }

  updateGroups(params = {}) {
    const url = [config.basename, `api/v2/groups/${params.id}`].join("/");
    if (params.type === "DYNAMIC") {
      return axios.put(url, {
        name: params.name.trim(),
        parentId: params.parentId,
        type: params.type,
        criteria: params.criteria,
        masterElementGroup: params.masterElementGroup,
        accountId: params.accountId,
        description: params.description,
      });
    } else if (params.type === "STATIC") {
      return axios.put(url, {
        name: params.name.trim(),
        parentId: params.parentId,
        type: "STATIC",
        masterElementGroup: params.masterElementGroup,
        accountId: params.accountId,
        devicesToAdd: params.devicesToAdd,
        description: params.description,
        devicesToRemove: params.devicesToRemove,
      });
    } else {
      return axios.put(url, {
        name: params.name.trim(),
        parentId: params.parentId,
        type: params.type,
        accountId: params.accountId,
        description: params.description,
      });
    }
  }

  put(params = {}) {
    const url = [config.basename, `api/v2/groups/${params.id}`].join("/");
    return axios.put(url, {
      name: params.name.trim(),
      parentId: params.parentId,
      criteria: params.criteria,
    });
  }

  delete(params = {}) {
    const url = [config.basename, "api/v2/groups", params].join("/");
    return axios.delete(url);
  }
  getCabiDetails(id) {
    var dashboardName = "/public/uim/dashboards/common/device_summary";
    var cabiServerLocation = "";
    var params = {};
    params.cs_id = id;
    var dashboardParams = params;
    var dynamicFilters = "[]";
    var helpLink =
      "https://techdocs.broadcom.com/us/en/ca-enterprise-software/it-operations-management/ca-unified-infrastructure-management-probes/GA.html";
    var url =
      "/cabi/jsp/index.jsp?dashboardName=" +
      dashboardName +
      "&cabiServerLocation=" +
      cabiServerLocation +
      "&dashboardParams=" +
      dashboardParams +
      "&dynamicFilters=" +
      dynamicFilters +
      "&helpLink=" +
      helpLink;
    return fetch(url);
  }
  getAllGroups() {
    const url =
      process.env.NODE_ENV == "development"
        ? "http://10.252.25.208:8080/castlemock/mock/rest/project/ViXRbO/application/xb77Al/groups"
        : [config.basename, "api/v2/groups"].join("/");
    return axios.get(url);
  }
  getDynamicGroupFilters() {
    const url =
      process.env.NODE_ENV == "development"
        ? "http://10.252.25.208:8080/castlemock/mock/rest/project/ViXRbO/application/xb77Al/filters"
        : [config.basename, "api/v2/groups/filters"].join("/");
    return axios.get(url);
  }

  getTopSubGroupsByDeviceCount(params) {
    let groupId = params.groupId;
    let url = [
      config.basename,
      "api/dashboard/component/fetchSubGroupsByDeviceCount",
    ].join("/");
    if (groupId == null) {
      //return axios.get(url);
      url = url.concat("?groupId=0");
      return axios.get(url);
    } else {
      if (groupId != null) {
        url = url.concat("?groupId=" + groupId);
      }

      return axios.get(url);
    }

    //return axios.get([config.basename, 'api/topGroups'].join('/'));
  }

  //dp039841
  getGroupDevices(groupId) {
    let url = [
      config.basename,
      "api/v1/computer-systems/exportDeviceData?groupId=" + groupId,
    ].join("/");
    return axios.get(url);
  }
  getGroupInterfaces(groupId) {
    let url = [
      config.basename,
      "api/v1/interface/exportInterfaceData?groupId=" + groupId,
    ].join("/");
    return axios.get(url);
  }
  getGroupInfo(groupId) {
    let url = [config.basename, `api/v2/groups/${groupId}`].join("/");
    return axios.get(url);
  }
  getGroupDeviceCount(groupId) {
    let url = [config.basename, `api/v2/groups/${groupId}/child-details`].join(
      "/"
    );
    return axios.get(url);
  }

  deleteDevicesGroup(csIds, groupId) {
    if (process.env.NODE_ENV == "development") {
      return new Promise((res, rej) => res("success"));
    } else {
      var url = [config.basename, "api/v2/groups/deleteDevices"];
      return axios.post(url.join("/"), { groupId, ids: csIds });
    }
  }

  addDevicesGroup(csIds, groupId) {
    if (process.env.NODE_ENV == "development") {
      return new Promise((res, rej) => res("success"));
    } else {
      var url = [config.basename, "api/v2/groups/addDevices"];
      return axios.post(url.join("/"), { groupId, ids: csIds });
    }
  }
}

let group = new Group();
export default group;
