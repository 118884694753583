export const FETCH_SLACK_CONFIG = 'FETCH_SLACK_CONFIG';
export const FETCH_SLACK_CONFIG_SUCCESS = 'FETCH_SLACK_CONFIG_SUCCESS';
export const FETCH_SLACK_CONFIG_FAILURE = 'FETCH_SLACK_CONFIG_FAILURE';
export const SAVE_SLACK_CONFIG = 'SAVE_SLACK_CONFIG';
export const SAVE_SLACK_CONFIG_SUCCESS = 'SAVE_SLACK_CONFIG_SUCCESS';
export const SAVE_SLACK_CONFIG_FAILURE = 'SAVE_SLACK_CONFIG_FAILURE';
export const DELETE_SLACK_CONFIG = 'DELETE_SLACK_CONFIG';
export const DELETE_SLACK_CONFIG_SUCCESS = 'DELETE_SLACK_CONFIG_SUCCESS';
export const DELETE_SLACK_CONFIG_FAILURE = 'DELETE_SLACK_CONFIG_FAILURE';

