import * as React from 'react';
import { SvgIcon } from '@mineral/core';

export const FilledHex = React.forwardRef(
  (props, ref) => (
    <SvgIcon data-testid="LearnIcon" viewBox="0 0 40 40" width={props.width} height={props.height} {...props} ref={ref}>
      <path d="M20 36L26.88 31.9165L33.8564 28L33.76 20L33.8564 12L26.88 8.08349L20 4L13.12 8.08349L6.14359 12L6.24 20L6.14359 28L13.12 31.9165L20 36Z" fill={props.color} />
    </SvgIcon>
  )
);
