import React from 'react'
import Info from './Info'
import './centerInfo.less'

const CenterInfo = (props) => {
  let classes = [
    'center-info',
    props.className,
  ].join(' ')
  return (
    <div className={classes} style={props.style}>
      <Info {...props} />
    </div>
  )
}

export default CenterInfo
