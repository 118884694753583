import React, { Component } from "react";
import { connect } from "react-redux";
import values from "lodash/values";
import isEqual from "lodash/isEqual";
import pick from "lodash/pick";
import difference from "lodash/difference";
import sortBy from "lodash/sortBy";

import Metrics from "./Metrics";
import { fetchMetrics } from "../api/metrics/actions";

const mapStateToProps = (state, props) => {
  let metricTypes = values(state.types.groups).filter(
    (metricType) => metricType.selected
  );
  const selectedmetrics = state.types.selectedMetricsGroup;
  selectedmetrics.forEach(function (item) {
    var metricItem = metricTypes.filter(
      (metricType) => metricType.metricType === item
    );
    metricTypes.splice(metricTypes.indexOf(metricItem[0]), 1);
    metricTypes.push(metricItem[0]);
  });

  const metrics = values(state.types.items)
    .filter((metric) => metric.selected)
    .map(({ id }) => id);
  const timerange = state.timerange.hours;
  const timerangeEndDate = state.timerange.endDate;
  let columns = state.columns;
  if (!columns) {
    columns = props.columns || 1;
  }

  const options = {
    timerange,
    timerangeEndDate,
  };

  return {
    metrics,
    metricTypes,
    columns,
    options,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { getMetrics } = ownProps;
  return {
    fetchMetrics: (contextValue, metricIds, options) => {
      const promises = metricIds.map((metricId) => {
        return getMetrics(contextValue, metricId, options);
      });
      return dispatch(
        fetchMetrics(() => {
          return Promise.all(
            promises.map((promise) => {
              return promise.catch((error) => {
                return {
                  contextValue,
                  error,
                };
              });
            })
          ).then((values) => values);
        }, metricIds)
      );
    },
  };
};

class MetricsRedux extends Component {
  getMetrics(props) {
    const { contextValue, fetchMetrics } = this.props;

    const previous = this.props.metrics;
    const next = props.metrics;

    if (isEqual(previous, next) && isEqual(this.props.options, props.options)) {
      return false;
    }
    // only fetch new metrics
    let diff = difference(next, previous);
    if (!isEqual(this.props.options, props.options)) {
      diff = next;
    }
    fetchMetrics(contextValue, diff, props.options);
  }

  componentWillMount() {
    this.getMetrics(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.getMetrics(nextProps);
  }

  render() {
    const { metricTypes } = this.props;
    if (metricTypes.length === 0) {
      return <div className="no-metrics">No metrics selected.</div>;
    }

    return <Metrics {...this.props} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MetricsRedux);
