import React from 'react'
import { Route } from 'react-router-dom'
import UimAlarmsRedux from './../components/alarms/UimAlarmsRedux'

const Alarms = () => {
  return (
    <main>
      <UimAlarmsRedux/>
    </main>
  )
}

const AlarmsRoute = () => {
  return (
    <Route path='/uim-alarms' component={Alarms}/>
  )
}

export default AlarmsRoute;