import React, {Component} from 'react'
import {  
  Dialog,  
  DialogActions,
  DialogTitle,
  DialogContent,Tooltip,IconButton
} from '@mineral/core'
import { Button } from '@mineral/core';
import CloseIcon from '@material-ui/icons/Close'
import Resource from './Resource'
import {CenterError as Error} from "../ui-components/uim-components"
import { Upload as UploadIcon } from '@mineral/icons';
import "./dialogButtonStyle.less";

import NotComplianceSlaIcon from '../../assets/icons/notComplianceSlaIcon'

class ErrorDialog extends Component {

  render(){
	  //Group Management Failed message
    var titleText = Resource.get('Group Management Failed')
    
    switch(this.props.errorType) {
      case 'addDevices' :
    	  //Add Devices Failed message
        titleText = Resource.get( 'Add Devices Failed')
        break
      case 'deleteGroup' :
    	  //Delete Group Failed message
        titleText = Resource.get('Delete Group Failed')
        break
      case 'rename' :
    	  //Rename Group Failed message
        titleText = Resource.get('Rename Group Failed')
        break
      case 'addGroup' :
    	  //Add Group Failed message
        titleText = Resource.get( 'Add Group Failed')
        break        
      case 'addGroup_DifferentAccount' :
    	  //Add Group Failed message
        titleText = Resource.get( 'Add Group Failed')
        
        break
      case 'removeDevice' :
    	  //Removing Devices Failed message
        titleText = Resource.get('Removing Devices Failed')
        break
      case 'import' :
        //Removing Devices Failed message
        titleText = Resource.get('Importing Devices Failed.')
        break
      case 'deleteDeviceFromInventory' :
          //Delete Device(s) Failed message
        titleText = Resource.get('Delete Device(s) Failed')
        break
      default :
        break;
    }
    let actions = [
      <Button
      variant= "text"
        //Close
        children= {Resource.get("Close")}
        color = "primary"
        onClick={this.onClose}
        style={{ textTransform: 'none'}}
      />,
    ]
    //Please contact an administrator
    const helplabelmessage = Resource.get('Please contact an administrator.')
    //Please check file format for data
    const importHelpMessage = Resource.get('Please check file format for data.')

    //Failure Description
    const groupAccountErrorDesc =  Resource.get( 'Group creation failed as parent group and child group do not share the same account. Please contact an administrator.')
    let content = (
      <div style={{fontSize: '16px', padding: '0px 24px 24px'}}>
       <Error style={{margin: '0 auto'}} title={titleText} message= {this.props.errorType==='import'?importHelpMessage:
           this.props.errorType == 'addGroup_DifferentAccount'?groupAccountErrorDesc: this.props.errorType == 'addGroup'?this.props.groupErrorMsg: helplabelmessage} />
      </div>
    )

    return(
      <Dialog        
        modal={false}
        open={this.props.open}
        onClose={this.onClose}
       
        
      
        >
          <DialogTitle component="div" disableTypography>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <NotComplianceSlaIcon size={'30'} />
                <h1 style={{ fontWeight: 'bold', fontSize: '18px', color: '#58606E', marginLeft: '10px' }}>
                  Error
                </h1>
              </div>
             
          </div>
      </DialogTitle>
      <div style={{height:'0px'}}>

     <Tooltip title="Close">
      <IconButton style={{top:'-60px',marginRight:'8px',float:'right'}}
                aria-label="Close"
                autoFocus
                onClick={this.onClose}
                >
                <CloseIcon />
              </IconButton>
              </Tooltip>
              </div>
      <DialogContent>
        <p style={{ color: '#333840', marginBottom: '8px' }}>
          {content}
          {/* {DELETE_SLA_CONFIRMATION} <span style={{ fontWeight: '700' }}> */}
        </p>
      </DialogContent>
        <DialogActions>
        {actions}
        </DialogActions>
      </Dialog>
    )
  }
  onClose = () =>{
    this.props.handleClose('errorDialog')
  }

}

export default ErrorDialog
