import axios from 'axios'
import config from './../config'

class Info {
  get(params) {
    var url = process.env.NODE_ENV == "development" ?
    "http://10.252.25.208:8080/castlemock/mock/rest/project/TK8t3o/application/UXxhjW/info" :
     [config.basename, 'api/v1/info'].join('/');
    return axios.get(url);
  }
}

export default new Info()
