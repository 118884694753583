import {
	GET_WMI_CREDENTIALS,
	FETCH_WMI_CREDENTIALS_SUCCESS,
	FETCH_WMI_CREDENTIALS_FAILURE,
	POLL_WMI_START,
	POLL_WMI_STOP,
	DELETE_WMI_CRED,
	UPDATE_WMI_CRED,
	ADDED_WMI_CRED
} from './actionTypes'
import credentials from './credentials'

//Get WMI Credentials
export function getWMI() {
	  return {
	    type: GET_WMI_CREDENTIALS,
	  }
	}

export function fetchWMISuccess(json) {
	  return {
	    type: FETCH_WMI_CREDENTIALS_SUCCESS,
	    credentials: json
	  }
}

 export function fetchWMIFailure(error) {
	  return {
	    type: FETCH_WMI_CREDENTIALS_FAILURE,
	    errorMessage: error.message
	  }
}

 export function pollWmiCredStart() {
	  return {
	    type:POLL_WMI_START
	  }
	}

	export function pollWmiCredStop() {
	  return {
	    type:POLL_WMI_STOP
	  }
	}
	export function deleteWmiCred(deletedItem) {
		  return {
		    type:DELETE_WMI_CRED,
		    deletedCredential: deletedItem
		  }
		}
	export function updateWmiCred(updatedItem) {
		  return {
		    type:UPDATE_WMI_CRED,
		    updatedCredential: updatedItem
		  }
		}
	export function addWmiCred(addedItem) {
		  return {
		    type:ADDED_WMI_CRED,
		    addedCredential: addedItem
		  }
		}
