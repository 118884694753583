import React from "react";
import "./icons.less";

const Icon = (props) => {
  let classes = ["icon", props.className].join(" ");
  return (
    <svg
      className={classes}
      role={props.role || "figure"}
      aria-label={props.ariaLabel || "[title]"}
      xmlns="http://www.w3.org/2000/svg"
      width="2em"
      height="2em"
      viewBox="0 0 24 24"
    >
      <circle cx="12" cy="12" r="11.5" fill="white" stroke="#747474" />
      <text
        x="8"
        y="17"
        text-anchor="top"
        fontFamily="Roboto"
        fontStyle="normal"
        fontWeight="500"
        fill="#747474"
      >
        {props.data ? props.data : 1}
      </text>
    </svg>
  );
};

export default Icon;
