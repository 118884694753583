import React, { Component } from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import isNaN from "lodash/isNaN";
import SecondaryNav from "./../components/groups/secondaryNav/SecondaryNav";
import CardsRedux from "./../components/cards/CardsRedux";
import CabiRedux from "./../components/cabi/CabiRedux";
import DashboardContainerRedux from "./../components/dashboardContainer/DashboardContainerRedux";
import AlarmsRedux from "./../components/alarms/AlarmsRedux";
import MetricsRedux from "./../components/metrics/MetricsRedux";
import TableRedux from "./../components/table/TableRedux";
import ProtectedConfiguration from "./../components/configuration/ProtectedConfiguration";
import BreadcrumbRoutes from "./../components/groups/BreadcrumbRoutes";
import InstallRelayHubScreenRedux from "./../components/installRelayHubScreen/InstallRelayHubScreenRedux";
import GroupsApi from "./../api/groups/GroupsApi";
import "./../components/groups/groups.less";
import connect from "./../utils/connect";
import AclTypes from "./../api/aclTypes";
import TreeViewRedux from "./../components/TreeView/TreeViewRedux";
import TreeViewNewRedux from "./../components/treeViewNew/TreeViewNewRedux";
import Maintenance from "../components/maintenance/Maintenance";
import MaintenanceRedux from "../components/maintenance/MaintenanceRedux";
import InterfacesRedux from "./../components/interfaces/InterfacesRedux";

import  GroupsMainHeader from "./../components/treeViewNew/GroupsMainHeader";
import {NAV_MENUS} from "./../components/groups/secondaryNav/toggleIcons/ToggleIcons"
// match /groups/<number> or /computer-systems/<number> or /groups/<number>/computer-systems/<number>
export const entityMatchRegex =
  "/(groups)?/:groupIds(\\d+)*/(computer-systems)?/:csIds(\\d+)*";

class Groups extends Component {
  static childContextTypes = {
    entityMatchRegex: PropTypes.string,
  };
/*
  componentDidMount() {
    document.title = "Infrastructure Monitoring - Groups";
  }*/

  getChildContext() {
    return {
      entityMatchRegex: entityMatchRegex,
    };
  }

  render() {
    return (
      <div style={{width:'100%'}}>
       {!this.props.saas && this.props.location.pathname.search("computer-systems") < 0 && <GroupsMainHeader view={this.props.view} tabValue={this.props.tabValue}/>}
         {this.props.view!==3 && <SecondaryNav makeMargin100={this.props.makeMargin100} view={this.props.view} isInventoryView={!(!this.props.saas && this.props.location.pathname.search("computer-systems") < 0 )}/>}
        <main className={this.props.view!==3 && this.props.location.pathname.search("computer-systems") < 0?(this.props.view===1 && this.props.tabValue===NAV_MENUS.DETAIL_VIEW?"groups__main__cardview":"groups__main__listview"):"groups__main"}> { this.props.component}
       </main>
      </div>
    );
  }
}

const GroupsRoute = (props) => {
  const idProps = (params) => {
    const getIds = (key) =>
      (params[key] ? params[key].split("/") : [])
        .map((id) => parseInt(id, 10))
        .filter((id) => !isNaN(id));
    const groupIds = getIds("groupIds");
    const csIds = getIds("csIds");
    const groupId = groupIds[groupIds.length - 1];
    const csId = csIds[csIds.length - 1];
    const hasComputerSystems = params[1];
    // we only want to return the groupId or csId based on the route
    return {
      groupId: hasComputerSystems ? null : groupId,
      csId: hasComputerSystems ? csId : null,
    };
  };
  const VIEWS_CARDVIEW=1;
  const VIEWS_LISTVIEW=2;
  const VIEWS_TREEVIEW=3;
  return (
    // wrapped in an outer `Route` to ensure only `/groups` or `/computer-systems` url match
    // because the `entityMatchRegex` match will all so match `/`
    <Route
      path={`${"/(groups|computer-systems)"}`}
      render={(props) => (
        <div style={{height:'100%'}}>
          <GroupsApi entityMatchRegex={`${entityMatchRegex}`} />
          <BreadcrumbRoutes entityMatchRegex={`${entityMatchRegex}`} />
          {/*redirect /groups to /groups/*/}
          <Route
            exact
            path="/groups"
            render={() => {
              return <Redirect to="/groups/0" />;
            }}
          />
          <Route
            exact
            path={`${entityMatchRegex}`}
            render={() => <Groups view={VIEWS_LISTVIEW} tabValue={NAV_MENUS.DETAIL_VIEW}  saas={props.saas} location={props.location} component={<TableRedux fromGroupsView={true} showTableFilters={true}/>} />}
          />
          <Route
            exact
            path={`${entityMatchRegex}/card`}
            render={() => <Groups view={VIEWS_CARDVIEW} tabValue={NAV_MENUS.DETAIL_VIEW} makeMargin100={true} saas={props.saas} location={props.location} component={<CardsRedux  />} />}
          />
         
            <Route
            exact
            path={`${entityMatchRegex}/treeviewnew`}
            render={() => <Groups view={VIEWS_TREEVIEW}  tabValue={NAV_MENUS.DETAIL_VIEW} saas={props.saas} location={props.location} component={<TreeViewNewRedux
             secondaryNavComponent={ <SecondaryNav makeMargin100={false} 
             view={VIEWS_TREEVIEW} isInventoryView={!(!props.saas && props.location.pathname.search("computer-systems") < 0 )}/>
     }/>} />}
          />
          <Route
            exact
            path={`${entityMatchRegex}/dashboard`}
            render={({ match }) => (
              <Groups view={VIEWS_LISTVIEW} saas={props.saas} location={props.location} tabValue={NAV_MENUS.CABI}
                component={
                  <DashboardContainerRedux {...idProps(match.params)} />
                }
              />
            )}
          />
           <Route
            exact
            path={`${entityMatchRegex}/cabi`}
            render={({ match }) => (
              <Groups  view={VIEWS_LISTVIEW} saas={props.saas} tabValue={NAV_MENUS.CABI} location={props.location} component={<CabiRedux {...idProps(match.params)} />} />
            )}
          />
          <Route
            exact
            path={`${entityMatchRegex}/alarms`}
            render={({ match }) => (
              <Groups  view={VIEWS_LISTVIEW} saas={props.saas} location={props.location} tabValue={NAV_MENUS.ALARMS}
                component={
                  <AlarmsRedux
                    isFromOperatorConsole={true}
                    {...idProps(match.params)}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path={`${entityMatchRegex}/maintenance`}
            render={({ match }) => <Groups view={VIEWS_LISTVIEW} tabValue={NAV_MENUS.MAINTENANCE} saas={props.saas} location={props.location} component={<MaintenanceRedux />} />}
          />
          <Route
            exact
            path={`${entityMatchRegex}/interfaces`}
            render={({ match }) => (
              <Groups saas={props.saas} view={VIEWS_LISTVIEW} location={props.location} tabValue={NAV_MENUS.INTERFACES}
                component={<InterfacesRedux fromGroupsView={true} {...idProps(match.params)} />}
              />
            )}
          />
          {/* <Route
            exact
            path={`${entityMatchRegex}/treeview`}
            render={() => <Groups component={<TreeViewRedux />} />}
          /> */}
        
          <Route
            exact
            path={`${entityMatchRegex}/configuration`}
            render={({ match }) => (
              <Groups saas={props.saas} view={VIEWS_LISTVIEW} tabValue={NAV_MENUS.MCS} location={props.location}
                component={
                  <ProtectedConfiguration 
                    {...idProps(match.params)}
                    entityMatchRegex={`${entityMatchRegex}`}
                  />
                }
              />
            )}
          />

          <Route
            exact
            path={`${entityMatchRegex}/metrics`}
            render={({ match }) => (
              <Groups view={VIEWS_LISTVIEW} tabValue={NAV_MENUS.METRICS} saas={props.saas} location={props.location} component={<MetricsRedux {...idProps(match.params)} />} />
            )}
          />

          
<Route
            exact
            path={`${entityMatchRegex}/card/dashboard`}
            render={({ match }) => (
              <Groups view={VIEWS_CARDVIEW} saas={props.saas} location={props.location} tabValue={NAV_MENUS.CABI}
                component={
                  <DashboardContainerRedux {...idProps(match.params)} />
                }
              />
            )}
          />
           <Route
            exact
            path={`${entityMatchRegex}/card/cabi`}
            render={({ match }) => (
              <Groups  view={VIEWS_CARDVIEW} saas={props.saas} tabValue={NAV_MENUS.CABI} location={props.location} component={<CabiRedux {...idProps(match.params)} />} />
            )}
          />
          <Route
            exact
            path={`${entityMatchRegex}/card/alarms`}
            render={({ match }) => (
              <Groups  view={VIEWS_CARDVIEW} saas={props.saas} location={props.location} tabValue={NAV_MENUS.ALARMS}
                component={
                  <AlarmsRedux
                    isFromOperatorConsole={true}
                    {...idProps(match.params)}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path={`${entityMatchRegex}/card/maintenance`}
            render={({ match }) => <Groups view={VIEWS_CARDVIEW} tabValue={NAV_MENUS.MAINTENANCE} saas={props.saas} location={props.location} component={<MaintenanceRedux />} />}
          />
          <Route
            exact
            path={`${entityMatchRegex}/card/interfaces`}
            render={({ match }) => (
              <Groups saas={props.saas} view={VIEWS_CARDVIEW} location={props.location} tabValue={NAV_MENUS.INTERFACES}
                component={<InterfacesRedux fromGroupsView={true} {...idProps(match.params)} />}
              />
            )}
          />
          {/* <Route
            exact
            path={`${entityMatchRegex}/treeview`}
            render={() => <Groups component={<TreeViewRedux />} />}
          /> */}
        
          <Route
            exact
            path={`${entityMatchRegex}/card/configuration`}
            render={({ match }) => (
              <Groups saas={props.saas} view={VIEWS_CARDVIEW} tabValue={NAV_MENUS.MCS} location={props.location}
                component={
                  <ProtectedConfiguration
                    {...idProps(match.params)}
                    entityMatchRegex={`${entityMatchRegex}`}
                  />
                }
              />
            )}
          />

          <Route
            exact
            path={`${entityMatchRegex}/card/metrics`}
            render={({ match }) => (
              <Groups view={VIEWS_CARDVIEW} tabValue={NAV_MENUS.METRICS} saas={props.saas} location={props.location} component={<MetricsRedux {...idProps(match.params)} />} />
            )}
          />
          <Route
            path="/install-relay-hub"
            render={() => <Groups saas={props.saas} location={props.location} component={<InstallRelayHubScreenRedux />} />}
          />
        </div>
      )}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    saas: state.saas,
    acl: state.acl,
    ocviews: state.ocviews,
  };
};

export default connect(mapStateToProps, null)(GroupsRoute);
