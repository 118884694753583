// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Home
  content["Home"] =
  "Início";

  //Alarms
  content["Alarms"] =
  "Alarmes";

  //Groups
  content["Groups"] =
  "Grupos";

  //Inventory
  content["Inventory"] =
  "Inventário";

  //Dashboards
  content["Dashboards"] =
  "Painéis";

  //Reports
  content["Reports"] =
  "Relatórios";

  //Settings
  content["Settings"] =
  "Configurações";

  //Setup Wizard
  content["Setup Wizard"] =
  "Assistente de instalação";

  //Community
  content["Community"] =
  "Comunidade";

  //Help
  content["Help"] =
  "Ajuda";

  // END OF LOCALIZATION

export default content;
