import React from 'react'
import IntegrationCard from './../integrations/IntegrationCard'
import AlarmPoliciesApi from './../../../api/alarm-policies/alarmPolicies'
import ArrowIcon from './../ArrowIcon'
import Resource from './Resource'
import {Tooltip} from '@mineral/core';

export default class AlarmPoliciesCard extends IntegrationCard {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentWillMount() {
    this.getPolicyCount()
  }

  getIcon() {
    return <ArrowIcon />
  }

  getPolicyCount() {
    AlarmPoliciesApi.getCount()
      .then(response => {
        let policyCount = response.data.total
        policyCount = policyCount === -1 ? 0 : policyCount
        this.setState({
          policyCount,
        })
      })
      .catch(function(error) {
        //console.log(error)
      })
  }

  getSubLabelTwo() {
	  //policies
	  const policiesMsg = Resource.get('policies')
	  // policy
	  const helplabelmessage1 = Resource.get('policy')		  
    if (
      typeof this.state.policyCount !== 'undefined' &&
      this.state.policyCount !== -1
    ) {
      const policyString = this.state.policyCount !== 1 ? policiesMsg : helplabelmessage1
      return `${this.state.policyCount} existing ${policyString}`
    } else {
    	//Existing policies
      return Resource.get('Existing policies')
    }
  }
}

const TextLogo = ({ label }) => {
  return (
      <Tooltip title={label}>
        <span className="settings-text-logo">{label}</span>
      </Tooltip>
  );
}

AlarmPoliciesCard.defaultProps = {
  className: 'alarmpolicy',
  logo: <TextLogo 
  //Alarm Policies
  label={Resource.get("Alarm Policies")} />,
  //Manage when alarms are created
  subLabelOne: Resource.get('Manage when alarms are created'),
  subLabelTwo: '<>',
  configRoute: '/settings/alarm-policies',
}
