// A list of all theme attributes
// https://github.com/callemall/material-ui/blob/master/src/styles/getMuiTheme.js

const primary1Color = '#00AEEF'
const fullWhite = 'rgba(255, 255, 255, 1)'

const theme = {
  palette: {
    primary1Color: primary1Color,
    disabledTextColor: 'rgba(0,0,0,0.38)',
    disabledColor: 'rgba(0,0,0,0.38)',
    disabledIconsColor: 'rgba(0,0,0,0.26)',
    dividerColor: 'rgba(0,0,0,0.12)',
    errorColor: '#E63333',
    hintTextColor: 'rgba(0,0,0,0.38)',
    iconsColor: 'rgba(0,0,0,0.38)',
    verticalDividerColor: 'rgba(0,0,0,0.05)',
    
    transparent: 'rgba(0, 0, 0, 0)',
    fullBlack: 'rgba(0, 0, 0, 1)',
    darkBlack: 'rgba(0, 0, 0, 0.87)',
    lightBlack: 'rgba(0, 0, 0, 0.54)',
    minBlack: 'rgba(0, 0, 0, 0.26)',
    faintBlack: 'rgba(0, 0, 0, 0.12)',
    fullWhite: fullWhite,
    darkWhite: 'rgba(255, 255, 255, 0.87)',
    lightWhite: 'rgba(255, 255, 255, 0.54)'
  },
  dropDownMenu: {
    accentColor: 'rgba(0,0,0,0.54)'
  },
  
  overrides: {
    MuiPickersModal: {
      dialogRootWider: {
        minWidth: '350px'
      }
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true,
        disableTouchRipple: true,
        disableElevation: true,

       
        size: 'small',
       
      },
      text: {
        color: '#3272D9',
        textTransform:'capitalize',
        '&:focus':{outline: 'rgb(29, 91, 191) solid 1px',
        outlineOffset: '1px'},
        '&:hover':{ borderColor: 'rgb(29, 91, 191)',backgroundColor: 'rgba(50, 114, 217, 0.04)',},
        '&:disabled':{color:'#8E99AB',background:'#FFFFFF'}
      },
      outlined: {
        color: '#3272D9',
        textTransform:'capitalize',
        border:'1px solid #C8D1E0',
        '&:focus':{outline: 'rgb(29, 91, 191) solid 1px',
        outlineOffset: '1px'},
        '&:hover':{    borderColor: 'rgb(29, 91, 191)',outline: 'rgb(29, 91, 191) solid 1px',backgroundColor: 'rgba(50, 114, 217, 0.04)',
        outlineOffset: '1px',
          color: 'rgb(29, 91, 191)'
      },
        '&:disabled':{background:'#C8D1E0',color:'#8E99AB'},
        '&:enabled':{ border:'1px solid #C8D1E0',color: '#3272D9',}
      },
      contained: {
        color: '#FFFFFF',
        textTransform:'capitalize',
        background:'#3272D9',
        '&:disabled':{
          background:'#EBEFF5',
          color:'#97A1B1'
        },
        '&:focus':{outline: 'rgb(29, 91, 191) solid 1px',
          outlineOffset: '1px'},
        '&:hover':{backgroundColor: 'rgb(29, 91, 191)'},
        '&:enabled':{background:'#3272D9'},
        
      },
    },
  },

  
  raisedButton: {
    primaryTextColor: fullWhite,
    secondaryTextColor: primary1Color
  }
}

export default theme
