export const FETCH_GLOBAL_AND_PERSONAL_USER_PREFERENCES = 'FETCH_GLOBAL_AND_PERSONAL_USER_PREFERENCES'
export const FETCH_GLOBAL_AND_PERSONAL_USER_PREFERENCES_SUCCESS = 'FETCH_GLOBAL_AND_PERSONAL_USER_PREFERENCES_SUCCESS'
export const FETCH_GLOBAL_AND_PERSONAL_USER_PREFERENCES_FAILURE = 'FETCH_GLOBAL_AND_PERSONAL_USER_PREFERENCES_FAILURE'



export const CREATE_USER_PREFERENCES = 'CREATE_USER_PREFERENCES'
export const CREATE_USER_PREFERENCES_SUCCESS = 'CREATE_USER_PREFERENCES_SUCCESS'
export const CREATE_USER_PREFERENCES_FAILURE = 'CREATE_USER_PREFERENCES_FAILURE'


export const UPDATE_USER_PREFERENCES = 'UPDATE_USER_PREFERENCES'
export const UPDATE_USER_PREFERENCES_SUCCESS = 'UPDATE_USER_PREFERENCES_SUCCESS'
export const UPDATE_USER_PREFERENCES_FAILURE = 'UPDATE_USER_PREFERENCES_FAILURE'



export const SETDEFAULTVIEW_USER_PREFERENCES = 'SETDEFAULTVIEW_USER_PREFERENCES'
export const SETDEFAULTVIEW_USER_PREFERENCES_SUCCESS = 'SETDEFAULTVIEW_USER_PREFERENCES_SUCCESS'
export const SETDEFAULTVIEW_USER_PREFERENCES_FAILURE = 'SETDEFAULTVIEW_USER_PREFERENCES_FAILURE'


export const DELETE_USER_PREFERENCES = 'DELETE_USER_PREFERENCES'
export const DELETE_USER_PREFERENCES_SUCCESS = 'DELETE_USER_PREFERENCES_SUCCESS'
export const DELETE_USER_PREFERENCES_FAILURE = 'DELETE_USER_PREFERENCES_FAILURE'

export const FETCH_ACCOUNT_USER_PREFERENCES = 'FETCH_ACCOUNT_USER_PREFERENCES'
export const FETCH_ACCOUNT_USER_PREFERENCES_SUCCESS = 'FETCH_ACCOUNT_USER_PREFERENCES_SUCCESS'
export const FETCH_ACCOUNT_USER_PREFERENCES_FAILURE = 'FETCH_ACCOUNT_USER_PREFERENCES_FAILURE'

export const RESET_STORE="RESET_STORE"


