import {
  REQUEST_REPORTS_FOLDER,
  FETCH_REPORTS_FOLDER_SUCCESS,
  FETCH_REPORTS_FOLDER_FAILURE
} from './actionTypes'


export function fetchReportsFolderSuccess(json) {
  return {
    type: FETCH_REPORTS_FOLDER_SUCCESS,
    items: json
  }
}
export function fetchReportsFolderFailure(error) {
  return {
    type: FETCH_REPORTS_FOLDER_FAILURE,
    errorMessage: error.message
  }
}
export function requestReportsFolder(path, cabiInfo) {
  return {
    type: REQUEST_REPORTS_FOLDER,
    path: path,
    cabiInfo: cabiInfo
  }
}