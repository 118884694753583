import React, {Component} from "react";

import Resource from "./Resource";
import "./dialogButtonStyle.less";
import {IconButton,Snackbar} from '@mineral/core';
import CloseIcon from "@material-ui/icons/Close";

const DISCOVERY_WIZARD_ERR_DIALOG = "DiscoveryWizardErrorDialog";

class DiscoveryWizardErrorDialog extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    //selectDiscoveryAgent
    let discoveryAgenetMsg= Resource.get("Please select a discovery agent from an Inventory tree and then click on Discovery wizard");

    const action = (
      <>
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={this.onClose}
        >
          <CloseIcon fontSize="small"/>
        </IconButton>
      </>
    );

    return (
      <Snackbar
        open={this.props.open}
        autoHideDuration={6000}
        onClose={this.onClose}
        message={discoveryAgenetMsg}
        action={action}
      />
    );
  }

  onClose = () => {
    this.errorMsg = null;
    this.props.handleClose(DISCOVERY_WIZARD_ERR_DIALOG);
  };
}

export default DiscoveryWizardErrorDialog;
