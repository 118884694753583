import React from 'react';
import IntegrationCard from './../IntegrationCard';
import connect from './../../../../utils/connect';
import {get} from './../../../../api/integrations/pagerduty/actions';
import {CircularProgress} from '@mineral/core';
import IntegrationLogo, {TYPE_PAGERDUTY} from './../../../icons/IntegrationLogo';
import IntegrationStateIcon, {TYPE_GOOD, TYPE_NEW, TYPE_ERROR} from './../../../icons/IntegrationStateIcon';
import Resource from './Resource'

class PagerDutyCard extends IntegrationCard {

  componentDidMount() {
    this.props.fetchConfig();
  }

  getIcon() {
    if(this.props.isFetching) {
      return (
          <span style={{padding:'3px'}}>
            <CircularProgress className='integration__card--icon-spinner' style={{color: '#3272D9'}} />
            
          </span>
      );
    } else if(this.props.responseError && this.props.responseError.message) {
      return (
          <span style={{padding:'3px'}} title={this.props.responseError.message}>
            <IntegrationStateIcon size='30px' type={TYPE_ERROR} />
          </span>
      );
    } else if(this.props.state === 'NEW') {
      return (
          <span style={{padding:'3px'}}>
            <IntegrationStateIcon size='30px' type={TYPE_NEW} />
          </span>
      );
    } else {
      return (
          <span style={{padding:'3px'}}>
            <IntegrationStateIcon size='30px' type={TYPE_GOOD} />
          </span>
      );
    }
  }

  getSubLabelTwo() {
    if(this.props.state === 'NEW') {
    	//Add
      return Resource.get('Add');
    } else {
      return this.props.serviceName;
    }
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchConfig:() => dispatch(get())
  }
};

const mapStateToProps = (state) => {
  return {
    isFetching:state.pagerDuty.isFetching,
    responseError:state.pagerDuty.responseError,
    state:state.pagerDuty.config.state,
    serviceName:state.pagerDuty.config.serviceName,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PagerDutyCard)

PagerDutyCard.defaultProps = {
  className:'pager-duty',
  logo:<IntegrationLogo height="26px" type={TYPE_PAGERDUTY} />,
  //Service Name
  subLabelOne:Resource.get('Service Name'),
  subLabelTwo:'<>',
  configRoute:'/settings/integrations/pagerduty'
};
