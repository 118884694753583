import * as React from 'react';
import { SvgIcon } from '@mineral/core';

export const OutlinedStar04 = React.forwardRef(
  (props, ref) => (
    <SvgIcon data-testid="LearnIcon" viewBox="0 0 40 40" {...props} ref={ref}>
      <path d="M20 6L21.829 14.975L28.999 9.27538L24.6311 17.3262L33.7873 17.5689L25.2663 20.9286L32.1244 27L23.4373 24.0964L24.7883 33.1557L20 25.3475L15.2117 33.1557L16.5627 24.0964L7.87564 27L14.7337 20.9286L6.21269 17.5689L15.3689 17.3262L11.001 9.27538L18.171 14.975L20 6Z" fill={props.color} stroke="#1D5BBF" stroke-width="3"/>

    </SvgIcon>
  )
);
