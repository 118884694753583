import connect from './../../../../utils/connect'
import CabiControls from './CabiControls'
import {setTimeRange} from './../../../../api/cabi/actions'

function mapStateToProps(state) {
  return {
    timeRange: state.cabiInput.timeRange,
    start_date: state.cabiInput.start_date,
    end_date: state.cabiInput.end_date,
    showTimeRange: state.cabiInput.showTimeRange
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onTimeRangeChange: (timeRange,start_date,end_date) => {
      dispatch(setTimeRange(timeRange,start_date,end_date));

   
      if (Window.updateDashboard) {
        console.log("udate dashboard "+timeRange+" "+start_date+" "+end_date);
        if(timeRange==-1)
        {
        Window.updateDashboard({
          time_range: ["-1"],
          start_date: [start_date],
          end_date: [end_date]
        });
      }else 
      {
        Window.updateDashboard({
          time_range: [timeRange]
        });
      }
       

      }
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CabiControls)