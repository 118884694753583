import axios from 'axios'
import config from './../config'

class ComputerSystems {
  getMonitoredSystemsCount() {
    const url = [config.basename, 'api/v1/computer-systems/monitoredComputerSystemCount'].join('/')
    return axios.get(url)
  }
  get(params = {}) {
    const id = params.id
    delete params.id
    if(process.env.NODE_ENV == "development" ){
      return axios.get("http://10.252.25.208:8080/castlemock/mock/rest/project/ViXRbO/application/xb77Al/computers");
    }else{
      var url = [config.basename, 'api/v1/computer-systems', id != null ? id : '']
      return axios.get(url.join('/'), {
        params: params,
      })
    }
     
    
    
  }
  search(params = {}) {     
    if(process.env.NODE_ENV === "development"){
      return axios.get("http://10.252.25.208:8080/castlemock/mock/rest/project/ViXRbO/application/xb77Al/computers", params );
    }
    else{
      var url = [config.basename, 'api/v1/computer-systems/search'];
      return axios.post(url.join('/'), params)
    }   
    
  }

  export(params = {}) {
    const url = [config.basename, 'api/v1/computer-systems/exportInventoryData']
    return axios.post(url.join('/'), params)
  }

  getCount() {
    var url = [config.basename, 'api/v1/computer-systems/counts?type=windows&type=unix&type=apache']
    return axios.get(url.join('/'))
  }
  delete(ids = []) {
    var url = [config.basename, 'api/v1/computer-systems/delete']
    return axios.post(url.join('/'), ids)
  }
  deleteFromInventory(ids = [],options) {
    let formData={csIds:[]}
    formData.csIds=ids
    Object.assign(formData,options)
    var url = [config.basename, 'api/v1/devices/deleteDevices']
    return axios.post(url.join('/'), formData)
  }
  getDeletedDevices() {
    var url = [config.basename, 'api/v1/computer-systems/deletedDevices']
    return axios.get(url.join('/'))
  }
  undelete(ids = []) {
    var url = [config.basename, 'api/v1/computer-systems/undelete']
    return axios.post(url.join('/'), ids)
  }
  discoveryImport(formData) {
    var url = [config.basename, 'api/v1/computer-systems/discoveryImport']
    return axios.post(url.join('/'), formData,{
      headers: {
          'content-type': 'multipart/form-data'
      }})
  }
  getInventory(){
    var url = process.env.NODE_ENV == "development" ?
          'http://10.252.25.208:8080/castlemock/mock/rest/project/TK8t3o/application/UXxhjW/inventoryTree':    
          [config.basename, 'api/v1/app-discovery/inventoryTree'].join('/') ;
   
    return axios.get(url)
  }
  
//dp039841
  getSavedColumns(){
    var url =  process.env.NODE_ENV == "development" ?

    // uncomment to get all columns
    "http://10.252.25.208:8080/castlemock/mock/rest/project/TK8t3o/application/UXxhjW/column"

    // uncomment to get only four columns
    // "http://10.252.25.208:8080/castlemock/mock/rest/project/TK8t3o/application/UXxhjW/fourColumns" 
    :
    [config.basename, 'api/v1/computer-systems/column'].join("/");  
    
    return axios.get(url);
  }
  saveSelectedColumns(params){
    var url =  [config.basename, 'api/v1/computer-systems/column'].join("/");
    return axios.post(url,params);
  }
  getMcsDetails(id) {   
    return axios.get("/mcs-ui-app/api/profile-summary?addMonitoringStatus=true&fullSummary=true&groupId="+id+
    "&rootProfiles=true&useEnhancedTemplates=true");
  }
  getCSMcsDetails(csId) {   
    return axios.get("/mcs-ui-app/api/profile-summary?addMonitoringStatus=true&csId="+csId+
    "&fullSummary=true&rootProfiles=true&useEnhancedTemplates=true");
  }
  getAlarmPolicyDetails(groupId) {     
      return axios.post("/policy_management_ws/api/v0/policy/list",{
        groupId:groupId
      });
  }
  getCSAlarmPolicyDetails(csId) {     
    return axios.post("/policy_management_ws/api/v0/policy/export?deviceId="+csId);
  }

  getLookupList(lookupType){
    var url = ''
    switch(lookupType){
      case 'monitoringprobes' :
        url =  process.env.NODE_ENV == "development" ?
        // uncomment to get all probes
        "http://10.252.25.208:8080/castlemock/mock/rest/project/kIbM1R/application/mEyHAm/monitoringprobes"
        :
        [config.basename, 'api/v1/computer-systems/monitoringprobes'].join("/");      
        break;
      default :
        url =  process.env.NODE_ENV == "development" ?
        // uncomment to get all roles
        "http://10.252.25.208:8080/castlemock/mock/rest/project/kIbM1R/application/mEyHAm/roles"
        :
        [config.basename, 'api/v1/computer-systems/roles'].join("/");
        break;
    }

    return axios.get(url);
  }
}

let computerSystems = new ComputerSystems()
export default computerSystems
