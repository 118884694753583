import React , { useEffect,useState } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import TitleBar from "./components/titlebar/TitleBarRedux";
import TrialBarRedux from "./components/titlebar/trialbar/TrialBarRedux";
import Navigation from "./components/primaryNav/PrimaryNavRedux";
import Groups from "./routes/Groups";
import Inventory from "./routes/Inventory";
import UimAlarms from "./routes/UimAlarms";
import UimCabi from "./routes/UimCabi";
import AdminConsole from "./routes/AdminConsole";
import Configuration from "./routes/Configuration";
import SetupWizard from "./routes/SetupWizard";
import ManageGroups from "./routes/ManageGroups";
import RobotDeploy from "./routes/RobotDeploy";
import Unauthorized from "./routes/Unauthorized";
import Inaccessible from "./routes/Inaccessible";
import PageNotFound from "./components/pageNotFound/PageNotFound";
import SessionExpiry from "./components/pageNotFound/SessionExpiry";
import ConfigApi from "./api/config/ConfigApi";
import DashboardPicker from "./routes/Dashboard";
import Overview from "./routes/Overview";
import Settings from "./routes/Settings";
import AlarmPolicies from "./routes/AlarmPolicies";
import Reports from "./routes/Reports";
import Footer from "./components/footer/FooterBar";
import storageManager from "./utils/LocalStorageManager";
import SupportedBrowser from "./components/supportedBrowser/SupportedBrowser";
import Resource from "./Resource";
import MessageFormat from "message-format";
import Wizard from "./routes/Wizard";
import DeviceDiscovery from "./routes/DeviceDiscovery";
import Slm from "./routes/Slm";
import QosChartUrl from "./components/settings/portlets/performanceReports/PerformanceLayout/qosData/qos-chart-container/QosChartUrl"

import ModalManagerRedux from "./components/modals/ModalManagerRedux";
import ListDashboardDesigner from "./components/settings/portlets/ListDashboardDesigner/ListDashboardDesigner";

//Infrastructure Monitoring
const infrastructureMonitoring = Resource.get('Infrastructure Monitoring');
//Overview
const overview = Resource.get('Overview');
//Alarms
const alarms = Resource.get('Alarms');
//Groups
const groups = Resource.get('Groups');
//Inventory
const inventory = Resource.get('Inventory');
//Device Details
const deviceDetails = Resource.get('Device Details');
//Service Level Management
const slm = Resource.get('Service Level Management');
//App Discovery
const appDiscovery = Resource.get('App Discovery');
//Personalize
const personalize = Resource.get('Personalize');
//Groups Maintenance Schedules
const groupsMaintenanceSchedules = Resource.get('Groups Maintenance Schedules');
//Settings
const settings = Resource.get('Settings');
// Report scheduler
const REPORT_SCHEDULER = Resource.get('Report Scheduler')
// Dashboards
const DASHBOARDS = Resource.get("Dashboards")

const App = (props) => {
  let saas = props.saas;
 /* const [titlelist, setTitleList] = useState(
    [ {path:"overview", title: overview},
      {path:"uim-alarms", title:alarms},
      {path:"groups", title: groups},
      {path:"inventory", title: inventory},
      {path:"dashboard", title:deviceDetails},
      {path:"alarms", title:alarms},
      {path:"slm", title:slm},
      {path:"administration", title:appDiscovery},
      {path:"personalize", title:personalize},
      {path:"treeviewnew", title:groupsMaintenanceSchedules},
      {path:"reportscheduler", title:REPORT_SCHEDULER},
    ]);
    //const [title, setTitle] = useState("");*/

  const onHomeComponentChanged=(data)=>{
  }
  const referrer = storageManager.getItem(
    "OPERATOR_CONSOLE",
    "referrer"
  );
  let landingPage = storageManager.getItem(
    "OPERATOR_CONSOLE",
    "landingPage"
  );
  if (referrer) {
    landingPage=referrer;
  }
  /*useEffect(() => {
    titlelist.map((list) => {
      if(window.location.pathname.includes(list.path))
      setTitle(infrastructureMonitoring +" - "+ list.title)
    })
    if(title !== ""){
      document.title = title
    }
    else{
      document.title = infrastructureMonitoring
    }
    
  })*/
  let component = (
    <div className="react__root operator-console__application">
      <SupportedBrowser />
      <TrialBarRedux />

      <div style={{ flexGrow: "2" }}>
        <ConfigApi />
        <Route
          exact
          path={`${"/(standalone.jsp|standalone_login.jsp|axalogin)*"}`}
          render={(props) => {
            const referrer = storageManager.getItem(
              "OPERATOR_CONSOLE",
              "referrer"
            );
            const landingPage = storageManager.getItem(
              "OPERATOR_CONSOLE",
              "landingPage"
            );
            if (referrer) {
              storageManager.removeItem("OPERATOR_CONSOLE", "referrer");
              return <Redirect to={referrer} />;
            }
            //below code will effect the loading page on login.
            // else if (
            //   storageManager.getItem(
            //     'OPERATOR_CONSOLE',
            //     'showSetupWizardAgain'
            //   ) !== 'false' && saas ) {
            //   return <Redirect to="/getting-started" />
            // }
            else if (
              props.location.pathname.endsWith("standalone.jsp") ||
              props.location.pathname.endsWith("standalone_login.jsp") ||
              props.location.pathname.endsWith("axalogin")
            ) {
              if(landingPage && landingPage!="")
              return <Redirect to={landingPage}/>
              else
              {
               return <Redirect to="/noaccess"/>
              }
              
            }
            if(landingPage && landingPage!="")
              return <Redirect to={landingPage}/>
              else
              {
                return <Redirect to="/noaccess"/>
              }
          }}
        />
        <TitleBar/>
        <Navigation landingPage={landingPage} />
      <Overview />
        <Groups />
        <Inventory />
        <UimAlarms />
       
        <UimCabi />
        <AdminConsole />
        <Configuration />
        <ManageGroups />
        <RobotDeploy />
        <Wizard />
        <DeviceDiscovery />
        <SetupWizard />
        
       <DashboardPicker saas={saas} />
        <Reports />
        <Settings />
        <AlarmPolicies />
        
        <Unauthorized />
        <Inaccessible/>
        <Footer />
        <ModalManagerRedux />
       <Slm />
      </div>
    </div>
  );

  if (window.location.href.includes('prdChart')) {
    component = (<div className="react__root operator-console__application prdChartUrlContainer">
      <SupportedBrowser />
      <TrialBarRedux />
      <Route path='/prdChart' component={QosChartUrl} />
    </div>)
  };

  if (window.location.href.includes('clonedListview')) {
    component = (
      <div>
        <SupportedBrowser />
        <TrialBarRedux />
        <Route
          path='/clonedListview'
          render={() => {
            return <ListDashboardDesigner copyURL={true} />;
          }}
        />
      </div>
    )
  };

  if (props.isTimedout) {
   const hostIp = window.location;
      let message = Resource.get(
        "Your current session is expired. Click on the below link to re-login"
      );
      let result = new MessageFormat(message).format({ hostIp });
      component = (
        <section>
          <SessionExpiry
          //Session Expired
            title={Resource.get("Session Expired")}
            message={result}
          />
        </section>
      );
  } else if (!props.heartbeat) {
    const hostIp = window.location;
    //help msg
    let message = Resource.get(
      "Unable to connect to Operator Console. Restore the connection to {0} and verify that wasp is running. Attempting to reestablish connection..."
    );
    let result = new MessageFormat(message).format({ hostIp });
    component = (
      <section>
        <PageNotFound
          //Unable to connect to server
          title={Resource.get("Unable to connect to server")}
          message={result}
          returnLink={null}
        />
      </section>
    );
  }
  return component;
};

export default App;
