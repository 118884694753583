import connect from './../../utils/connect'
import InstallRelayHubScreen from './InstallRelayHubScreen'
import {addBreadCrumb} from './../breadcrumbs/actions'

const mapStateToProps = (state) => {
  return {
    hubsIsFetching: state.hubs.isFetching
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addBreadCrumb: (items) => dispatch(addBreadCrumb(items))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InstallRelayHubScreen)