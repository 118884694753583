import React, { Component } from 'react'
import './MCSDashBoardBar.less'
import {Tooltip} from "@mineral/core";
import ProfilesIcon from '../icons/ProfilesIcon';
import GroupsIcon from '../icons/GroupsIcon';
import DevicesIcon from '../icons/DevicesIcon';
import Resource from './Resource';
import { Button } from '@mineral/core';

const clear = Resource.get("Clear");//Clear
class MCSDashBoardBar extends React.Component {
    constructor(props) {
        super(props);
       this.state = {
        open:false,
        hoverValue:"",
       }
    }
    handler = (e) => {
        if (e.key === 'Escape') {
          this.onCloseTooltip();
          window.removeEventListener('keydown',this.handler)
        }
      }
      onOpen = (value) => {
    
        this.setState({ open:true,hoverValue:value });
        window.addEventListener('keydown',this.handler)
      }
      onCloseTooltip = () => {
         this.setState({ open:false,hoverValue:"" });
      }

    render() {
        const parent = this.props;
        const type = parent.type;
        const noOfEntities= parent.noOfEntities;
       

        let sumOfPercentages=0;
        let lengthOfParent=parent.readings && parent.readings.length? parent.readings.length:0
        		
         let bars = parent.readings && parent.readings.length && parent.readings.map(function(item, i) {
            if(item.value > 0) {
                let itemName = item.name;
                let itemValue=item.value;
                
                	if(lengthOfParent>=2)
                		{
                if(i!=lengthOfParent-1)
                sumOfPercentages=sumOfPercentages+item.value;
                else if(i==(lengthOfParent-1) && item.value!=0)
                	itemValue=100-sumOfPercentages;
                		}
                	
                let styleOfBar={'backgroundColor': item.color, 'width': itemValue + '%',flexDirection:'row',border:'none'}
                if(parent.disableKeys[i]===true)
                styleOfBar={'background': item.color, 'opacity': 0.2,'width': itemValue + '%'}
                return (
                    <Tooltip arrow open={this.state.hoverValue === itemName && this.state.open} onOpen={()=> this.onOpen(itemName)} onClose={this.onCloseTooltip} title={itemValue + '% '+ itemName} placement="top">
                    <Button style={styleOfBar}  role = 'button' key={i} onClick={(event) => this.props.onBarClick({event,i,type,itemName})} title={itemValue + '% '+ itemName} aria-label={itemValue + '% '+ itemName}>
                    </Button>
                    </Tooltip>
                )
            }
        }, this);

        let legends = parent.readings && parent.readings.length && parent.readings.map(function(item, i) {
            if(item.value > 0) {
                let labelLegend = item.value +"% "+item.name;
                return (
                    <div className="legend" aria-label= {labelLegend} key={i}>
                        <span className="dot" style={{'color': item.color}}>●</span>
                        <span className="label">{item.value}%</span>
                        <span className="label">{item.name}</span>
                    </div>
                )
            }
        }, this);

        let icon='';
        if(type==='Groups')
        icon= <GroupsIcon  title={type} />
        else if (type==='Profiles')
        icon= <ProfilesIcon  title={type}/>
        else if(type==='Devices')
        icon=<DevicesIcon title={type}/>
        let showClear=true
        if(parent.disableKeys[0]=== false && parent.disableKeys[1]===false && parent.disableKeys[2]===false && parent.disableKeys[3]===false)
showClear=false
//56A9C7
        return (
            <div className="multicolor-bar">
          
            <div style={{'text-align':'left','padding-top': '10px','padding-bottom': '10px'}}>
           {icon}
            <span style={{'color' : '#6B6B6B','font-size':'15px','vertical-align': 'super','padding-left': '10px'}}>{noOfEntities+`  `+type}</span>
            <button  style={{border:'none','color' : '#3272D9','font-size':'14px','vertical-align': 'super','float':'right','display':showClear==false?'none':'inline'} } onClick={ (event) => this.props.onClearClick({type})} aria-label={clear}>{clear}</button>
       
        </div>    
            <div>
                <div className="bars" style={{display:'flex'}}>
                    {bars == ''?'':bars}
                </div>
                <div className="legends">
                    {legends == ''?'':legends}
                </div>
                
                </div>
                
            </div>
        );
    }
}

export default MCSDashBoardBar;
