import React from 'react';

const SplitResizeIcon = () => {
  return (
    <svg width="6" height="18" viewBox="0 0 6 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path 
        d="M0.333374 0H2.33337V3H0.333374V0ZM0.333374 5H2.33337V8H0.333374V5ZM0.333374 10H2.33337V13H0.333374V10ZM0.333374 15H2.33337V18H0.333374V15ZM3.66671 0H5.66671V3H3.66671V0ZM3.66671 5H5.66671V8H3.66671V5ZM3.66671 10H5.66671V13H3.66671V10ZM3.66671 15H5.66671V18H3.66671V15Z" 
        fill="#58606E"
      />
    </svg>
  );
};

export default SplitResizeIcon;
