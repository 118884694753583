import config from './../config'
import axios from 'axios'

const url = `${config.basename}/api/v1/logout`

class Logout {
  delete() {
    return axios.delete(url)
  }
}

let logout = new Logout()
export default logout
