import React, { Component } from "react";
import { connect } from "react-redux";
import {
  ListItem,
  ListItemText,
  ListItemIcon,
  Checkbox,
} from "@mineral/core";
import { selectMetricType } from "../../api/metricTypes/actions";

class MetricListItem extends Component {
  handleChange(metricType, id, event) {
    let selected = event.target.checked;
    let selectedState = selected === this.props.selected ? !selected : selected;
    this.props.onCheck(metricType, id, selectedState);
  }

  render() {
    const { primaryText, secondaryText, selected, metricType, id } = this.props;

    const checkbox = (
      <Checkbox
        style={{ left: "24px" }}
        iconStyle={!selected ? { fill: "#C9C9C9" } : null}
        defaultChecked={selected}
        onChange={(event) => this.handleChange(metricType, id, event)}
        aria-labelledby={primaryText}
      />
    );

    let style = {
      color: "#fff",
      backgroundColor: selected ? "#4D4D4D" : null,
    };
    return (
      <ListItem
        style={style}
        key={id}
        //secondaryTextLines={2}
      >
        <ListItemIcon>{checkbox}</ListItemIcon>
        <ListItemText
          primary={primaryText}
          id={primaryText}
          secondary={secondaryText}
        />
      </ListItem>
    );
  }
}

const mapStateToProps = (state, props) => {
  const metricType = state.types.items[props.metricId];
  return {
    metricType: metricType.metricType,
    id: metricType.id,
    primaryText: props.child
      ? metricType.ciName
      : metricType.name && metricType.name !== "null"
      ? metricType.name
      : metricType.qosName,
    secondaryText: props.child
      ? null
      : `${metricType.description}, ${metricType.unit}`,
    selected: metricType.selected,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onCheck: (metricTypeId, metricId, selected) =>
      dispatch(selectMetricType(metricTypeId, metricId, selected)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MetricListItem);
