import React from 'react'
import {
  Cell,
  Pie,
  PieChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
} from 'recharts'
import Resource from './Resource'
import { Component } from 'react'
import connect from './../../../../utils/connect'
import computerSystems from './../../../../api/computer-systems/computerSystems'
import {CircularProgress} from '@mineral/core';
import {
  CenterError as Error,
  CenterInfo,
} from '../../../ui-components/uim-components'
import isEmpty from 'lodash/isEmpty'

const chartColors = ['#E91E63', '#FF9800', '#009688', '#2196F3', '#A11D2E', '#F7922F', '#FEE276', '#9EC6D0']

const Panel = (props) => {
  return (
    <ResponsiveContainer>
      <PieChart margin={{ bottom: 14 * 2 }}>
        <Pie data={props.data} innerRadius={'40%'} outerRadius={'70%'} fill='#000'>
          {props.data.map((entry, index) =>
            // fill color will alway be a color from `chartColors`
            <Cell className={'chart__role--' + entry.name} key={`cell-${index}`} fill={chartColors[index%chartColors.length]} />
          )}
        </Pie>
        <Tooltip />
        <Legend verticalAlign='bottom' iconSize={12} />
      </PieChart>
    </ResponsiveContainer>
  )
}

Panel.defaultProps = {
  data: [],
}



class PanelRedux extends Component {
  state = {
    data: [],
    loadStatus: 'loading',
  }
  _getComputerSystems = (props) => {
    if (props.entity) {
      computerSystems
        .get({
          groupId: props.entity.id,
        })
        .then(res => {
          return res.data._items
        })
        .then(computerSystems => {
          // example data
          // const data = [
          //   {
          //     name: 'Virtual Machine',
          //     value: 2400,
          //   },
          //   {
          //     name: 'Apache',
          //     value: 4567,
          //   },
          // ]
          const data = computerSystems.reduce((deviceByRoles, computerSystem, idx) => {
            // get device already added to deviceByRoles or add new deviceByRole
            let deviceByRole = deviceByRoles.find(deviceByRole => deviceByRole.name === computerSystem.role)

            if (!deviceByRole) {
              deviceByRole = {
                name: computerSystem.role,
                value: 0,
              }
              deviceByRoles.push(deviceByRole)
            }
            deviceByRole.value += 1
            return deviceByRoles
          }, [])
          this.setState({
            data: data,
            loadStatus: 'complete'
          })
        })
        .catch(err => {
          this.setState({ loadStatus: 'fail' })
        })
    }
  }
  componentWillMount() {
    this._getComputerSystems(this.props)
  }
  componentWillReceiveProps(nextProps) {
    this._getComputerSystems(nextProps)
  }
  render() {
    let view
    switch (this.state.loadStatus) {
      case 'complete':
        view = isEmpty(this.state.data) ?
          <CenterInfo 
          //No Roles found
          title= {Resource.get('No Roles found')} /> :
          <Panel data={this.state.data} {...this.props} />
        break
      case 'fail':
        view = <Error />
        break
      case 'loading':
      default:
        view = view = <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><CircularProgress style={{color: '#3272D9'}} /></div>
        break
    }
    return view
  }
}

export default connect(null, null)(PanelRedux)