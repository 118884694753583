// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //debian based systems message
  content["Only 64 bit package is allowed for debian based systems."] =
  "Solamente se permiten los paquetes de 64 bits para los sistemas basados en Debian.";

  //Sudo Password is only applicable for Linux Systems
  content["Sudo Password is only applicable for Linux systems where username is not \'root\'."] =
  "La contraseña de Sudo solamente se aplica a los sistemas Linux donde el nombre de usuario no sea root.";

  //Windows Relay Hub Required
  content["Windows Relay Hub Required."] =
  "El concentrador de transmisión de Windows es obligatorio.";

  //IP Address Required
  content["IP Address Required"] =
  "La dirección IP es obligatoria.";

  //Remote monitoring (also known as agentless monitoring) requires a monitoring host. The monitoring host is responsible for collecting data from the remote device that you are monitoring.
  content["Remote monitoring (also known as agentless monitoring) requires a monitoring host. The monitoring host is responsible for collecting data from the remote device that you are monitoring."] =
  "La monitorización remota (también conocida como monitorización sin agente) requiere un host de monitorización. El host de monitorización se encarga de recopilar datos del dispositivo remoto monitorizado.";

  //Monitoring host server
  content["Monitoring host server"] =
  "Servidor del host de monitorización";

  //host
  content["host"] =
  "host";

  //Deploy a monitoring
  content["Deploy a monitoring"] =
  "Implemente la monitorización.";

  //Recommended minimum system requirements
  content["Recommended minimum system requirements"] =
  "Requisitos mínimos del sistema recomendados";

  //Home
  content["Home"] =
  "Casa";

  //Docker engine message
  content["Docker engine"] =
  "Motor de Docker";

  //agent message
  content["agent"] =
  "agente";

  //Docker monitoring requires an agent (aka Robot) to be installed on the Docker Engine server.
  content["Docker monitoring requires an agent (aka Robot) to be installed on the Docker Engine server."] =
  "La monitorización de Docker requiere un agente (también conocido como robot) que se debe instalar en el servidor del motor de Docker.";

  //IP address
  content["IP address"] =
  "Dirección IP";

  // Operating systems message
  content["Operating system"] =
  "Sistema operativo";

  //Architecture
  content["Architecture"] =
  "Arquitectura";

  //64-bit
  content["64-bit"] =
  "64 bits";

  //32-bit
  content["32-bit"] =
  "32 bits";

  // System Credentials
  content["System Credentials"] =
  "Credenciales del sistema";

  //Username
  content["Username"] =
  "Nombre de usuario";

  //Password
  content["Password"] =
  "Contraseña";

  //Sudo Password (Linux only)
  content["Sudo Password (Linux only)"] =
  "Contraseña sudo (solo Linux)";

  //Instructions
  content["Instructions"] =
  "Instructor";

  //4 GB of available memory
  content["4 GB of available memory"] =
  "4 GB de memoria disponibles";

  //2 or more CPUs
  content["2 or more CPUs"] =
  "2 o más CPU";

  //10 GB of available disk space
  content["10 GB of available disk space"] =
  "10 GB de espacio en disco disponibles";

  //Ports 48000 to 48025 open
  content["Ports 48000 to 48025 open"] =
  "Puertos 48000-48025 abiertos";

  // END OF LOCALIZATION

export default content;
