// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Add
  content["Add"] =
  "追加";

  //Setup complete
  content["Setup complete"] =
  "セットアップの完了";

  //slack
  content["slack"] =
  "slack";

  //Push alarms to Slack
  content["Push alarms to Slack"] =
  "Slack にアラームを送信";

  // END OF LOCALIZATION

export default content;
