import React from 'react'
import {ErrorIcon} from '../../ui-components/uim-components'
import Message from './CenterMessage'
import theme from './../../../theme'
import './deployFailed.less'
import Resource from './Resource'
import { Button } from '@mineral/core'
//FIX ISSUES
const FixIssuesLabel = Resource.get('FIX ISSUES')
//Deploymentfailed message
const DeploymentFailed = Resource.get('Deployment failed')
// Fix Deployment Issues
const FIX_DEPLOYMENT_ISSUES = Resource.get("Fix Deployment Issues")
const DeployPending = (props) => {
  const FixIssues = (
    <Button className='link'
      href={null} autoFocus
      onClick={(evt) => {
        if (props.onClick) {
          props.onClick(evt)
        }
      }} aria-roledescription={"Click to fix deployment issues"}
      tabIndex="0"
      aria-label={FIX_DEPLOYMENT_ISSUES}
      role="link"
     >{FixIssuesLabel}
    </Button>
  )
  const Text = (
    <span>{// Confirmation check message
      Resource.get('Confirm your target device details and re-deploy.')}{FixIssues}
    </span>
  )
  return (
    <Message
      className='message--failed'
      title= {DeploymentFailed}
      message={Text}
      icon={ErrorIcon}
    />
  )
}

export default DeployPending