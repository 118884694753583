import connect from './../../utils/connect'
import ManageGroups from './ManageGroups'

function mapStateToProps(state) {
  return {
    sid: state.sid,
  };
}

export default connect(mapStateToProps, null)(ManageGroups)
