import connect from './../../../utils/connect'
import DiscoveryAgent from './DiscoveryAgent'
import { 
  fetchAgents,
  pollAgentsStart,
  pollAgentsStop,
  fetchAgentsStatus,
  pollAgentsStatusStart,
  pollAgentsStatusStop,setSelectedAgent,
  requestAgentsStatus, requestAgents, setCustomScopeDiscovery } from '../../../api/DeviceDiscovery/agents/actions'

const mapStateToProps = state => {
    return {
      agents:state.agents.items,
      agentsStatus:state.agents.status,
      selectedAgentInfo:state.agents.selectedAgent,
      isFetching:state.agents.isAgentsFetching,
      isStatusFetching:state.agents.isAgentsStatusFetching,
      selectedAgentCustomScopeInfo: state.agents.selectedAgentCustomScope,
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
      fetchAgents:()=>dispatch(fetchAgents()),
      pollAgentsStart:()=>dispatch(pollAgentsStart()),
      pollAgentsStop:()=>dispatch(pollAgentsStop()),
      fetchAgentsStatus:()=>dispatch(fetchAgentsStatus()),
      pollAgentsStatusStart:()=>dispatch(pollAgentsStatusStart()),
      pollAgentsStatusStop:()=>dispatch(pollAgentsStatusStop()),
      selectedAgent:(agentInfo)=>dispatch(setSelectedAgent(agentInfo)),
      requestAgents:()=>dispatch(requestAgents()),
      requestAgentsStatus:() =>dispatch(requestAgentsStatus()),
      setCustomScopeDiscovery: (customScope) =>dispatch(setCustomScopeDiscovery(customScope)),
    }
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(DiscoveryAgent)