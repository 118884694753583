import React from "react";
import Portlet from "../Portlet";
import ArrowIcon from "../../ArrowIcon";
import Resource from "./Resource";
import {Tooltip} from '@mineral/core';
export default class SelfCertCard extends Portlet {
  getIcon() {
    return <ArrowIcon />;
  }

  getSubLabelTwo = () => {
    return this.props.subLabelTwo;
  };
}

const TextLogo = ({ label }) => {
  return (
    <Tooltip title={label}>
      <span className="settings-text-logo admin__console--card">{label}</span>
    </Tooltip>
  );
};

//Device Self Certification
const SelfCertLabel = Resource.get("Device Self Certification");
//Device self certification for SNMP collector
const Creating = Resource.get("Device self certification for SNMP collector");

SelfCertCard.defaultProps = {
  logo: <TextLogo label={SelfCertLabel} />,
  configRoute: "/settings/self-cert",
  subLabelOne: Creating,
  subLabelTwo: "",
};
