import {
  REQUEST_HISTORIC_ALARMS_FOR_GROUP,
  FETCH_HISTORIC_ALARMS_SUCCESS_FOR_GROUP,
  FETCH_HISTORIC_ALARMS_FAILURE_FOR_GROUP,
  REQUEST_HISTORIC_ALARMS_FOR_CS,
  FETCH_HISTORIC_ALARMS_SUCCESS_FOR_CS,
  FETCH_HISTORIC_ALARMS_FAILURE_FOR_CS
  } from './actionTypes'
import historicAlarms from './historicAlarms'

export function requestHistoricAlarmsForGroup(id) {
  return {
    type: REQUEST_HISTORIC_ALARMS_FOR_GROUP,
    id: id
  }
}
export function fetchHistoricAlarmsSuccessForGroup(json,id) {
  return {
    type: FETCH_HISTORIC_ALARMS_SUCCESS_FOR_GROUP,
    data: json,
    id: id,
  }
}

export function fetchHistoricAlarmsFailureForGroup(error,id) {
  return {
    type: FETCH_HISTORIC_ALARMS_FAILURE_FOR_GROUP,
    id: id,
    errorMessage: error.message
  }
}
export function fetchHistoricAlarmsForGroup(params) {
  return dispatch => {
    dispatch(requestHistoricAlarmsForGroup(params.groupId))
    return historicAlarms.get(params)
      .then(response => response.data)
      .then(json => dispatch(fetchHistoricAlarmsSuccessForGroup(json,params.groupId)))
      .catch(function(error) {
        dispatch(fetchHistoricAlarmsFailureForGroup(error,params.groupId))
      })
  }
}

export function requestHistoricAlarmsForCs(id) {
  return {
    type: REQUEST_HISTORIC_ALARMS_FOR_CS,
    id: id
  }
}
export function fetchHistoricAlarmsSuccessForCs(json,id) {
  return {
    type: FETCH_HISTORIC_ALARMS_SUCCESS_FOR_CS,
    data: json,
    id: id,
  }
}

export function fetchHistoricAlarmsFailureForCs(error,id) {
  return {
    type: FETCH_HISTORIC_ALARMS_FAILURE_FOR_CS,
    errorMessage: error.message,
    id: id,
  }
}
export function fetchHistoricAlarmsForCs(params) {
  return dispatch => {
    dispatch(requestHistoricAlarmsForCs(params.csId))
    return historicAlarms.get(params)
      .then(response => response.data)
      .then(json => dispatch(fetchHistoricAlarmsSuccessForCs(json,params.csId)))
      .catch(function(error) {
        dispatch(fetchHistoricAlarmsFailureForCs(error,params.csId))
      })
  }
}
