// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Device Properties
  content["Device Properties"] =
  "Device Properties";

  //Device Type
  content["Device Type"] =
  "Device Type";

  //Operating System
  content["Operating System"] =
  "Operating System";

  //Monitored By
  content["Monitored By"] =
  "Monitored By";

  //Last Deployment Status
  content["Last Deployment Status"] =
  "Last Deployment Status";

  //Last Deployment Time
  content["Last Deployment Time"] =
  "Last Deployment Time";

  //Last Deployment Status Message
  content["Last Deployment Status Message"] =
  "Last Deployment Status Message";

  //No Data found. You may define a new filter criteria.
  content["No Data found. You may define a new filter criteria."] =
  "No Data found. You may define a new filter criteria.";

  //Devices
  content["Devices"] =
  "Devices";

  //Export Data
  content["Export Data"] =
  "Export Data";

  //Groups
  content["Groups"] =
  "Groups";

  //Clear
  content["Clear"] =
  "Clear";

  //Groups table summary
  content["Groups table summarizing group names with path, number of group profiles and consolidated profile deployment status."] =
  "Groups table summarizing group names with path, number of group profiles and consolidated profile deployment status.";

  //Profiles table summary
  content["Profiles table summarizing profile name, number of devices and consolidated profile deployment status."] =
  "Profiles table summarizing profile name, number of devices and consolidated profile deployment status.";

  //Devices table summary
  content["Profiles table summarizing device name,  last deployment status and status message."] =
  "Profiles table summarizing device name,  last deployment status and status message.";

  //Group name
  content["Name"] =
  "Name";

  //Back
  content["Back"] =
  "Back";

  //this.getRowsCount=0
  content["No data found"] =
  "No data found";

  //Profile Properties
  content["Profile Properties"] =
  "Profile Properties";

  //Profile Type
  content["Profile Type"] =
  "Profile Type";

  //Alarm Policy
  content["Alarm Policy"] =
  "Alarm Policy";

  // END OF LOCALIZATION

export default content;
