import React, { Component, PureComponent } from "react";
import ReactDOM from "react-dom";
import {
  LineChart,
  Line,
  CartesianGrid,
  YAxis,
  XAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ComposedChart,
  ReferenceLine,
  Label
} from "recharts";
import { setColumns } from "../columns/actions";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getMetricDevices } from "../../api/deviceMetrics/actions";
import CircularProgress from '@material-ui/core/CircularProgress';
import { Typography, Button as RaisedButton, Box,IconButton } from "@mineral/core";
import FileSaver from "file-saver";
import moment from "moment";
import CustomTooltip from './CustomTooltip';
import CustomLegend from "./CustomLegend";
import CustomizedDot from './CustomPlotPoint';
import { strokeCombinations } from './dataset';
import { MineralChart } from './MineralChart'
import { Maximize } from "@material-ui/icons";
import { PlusIcon } from "../../../ui-components/uim-components";
import ExpandIcon from "../../../../assets/icons/expand-icon";
import CollapseIcon from "../../../../assets/icons/collapse-icon";
import Grid from '@mui/material/Grid';

// import ExpandIcon from "../../../../../../../assets/icons/expand-icon";

const randomColor = [
  "#2F8DFA", "#5C6BC0", "#AB47BC", "#e67e22", "#ef5350", "#068596", "#0bd615", "#e16c57", "#00897B", "#00a81c",
  "#d68102", "#FF7043", "#8D6E63", "#b12121", "#005681", "#0099BC", "#8E8CD8", "#00B294", "#10893E", "#017c5f",
  "#7E735F", "#0a6e92", "#802d2d", "#502c24", "#FFE4C4", "#FF69B4", "#FFFACD", "#FFEBCD", "#FFF8DC", "#FAFAD2",
  "#FAEBD7", "#FFE4B5", "#FFDAB9", "#FFE4E1", "#FFFFE0", "#D2691E", "#F5F5DC", "#DEB887", "#CD853F", "#FFDEAD",
  "#C0C0C0", "#808080", "#800000", "#808000", "#008000", "#778899", "#800080", "#008080", "#000080", "#E9967A",
  "#006400", "#2F4F4F", "#7FFF00", "#008080", "#8FBC8F", "#00FA9A", "#00FF7F", "#ADFF2F", "#2E8B57", "#66CDAA",
  "#40E0D0", "#48D1CC", "#0000CD", "#CD5C5C", "#708090", "#F08080", "#BDB76B", "#F0E68C", "#808000", "#FFFF00",
  "#FFD700", "#B8860B", "#DAA520", "#00FF00", "#EEE8AA", "#9ACD32", "#90EE90", "#98FB98", "#228B22", "#3CB371",
  "#FF6347", "#B22222", "#FF8C00", "#FF0000", "#00FF00", "#0000FF", "#FFFF00", "#00FFFF", "#FF00FF", "#FA8072",
  "#FF0000", "#556B2F", "#32CD32", "#A52A2A", "#6B8E23", "#D2B48C", "#FFB6C1", "#FFC0CB", "#FFA500", "#FF7F50",
  "#FFA07A", "#B0C4DE", "#800000", "#8B0000", "#7CFC00", "#008000", "#008B8B", "#DC143C", "#FF4500", "#20B2AA",
  "#0000FF", "#4169E1", "#000080", "#00FFFF", "#AFEEEE", "#8A2BE2", "#1E90FF", "#7FFFD4", "#191970", "#00CED1",
  "#00008B", "#ADD8E6", "#00FFFF", "#E0FFFF", "#87CEEB", "#87CEFA", "#EE82EE", "#8B008B", "#9400D3", "#B0E0E6",
  "#5F9EA0", "#C71585", "#DA70D6", "#FF00FF", "#8B4513", "#A0522D", "#BC8F8F", "#DB7093", "#FF1493", "#F5DEB3",
  "#2F8DFA", "#5C6BC0", "#AB47BC", "#e67e22", "#ef5350", "#068596", "#0bd615", "#e16c57", "#00897B", "#00a81c"
];
const randomColoFn = () => {
  let color =
    "#" +
    ("000000" + Math.floor(Math.random() * 16777215).toString(16)).slice(-6);
  return color;
};

let chartArray = [];
let xAxisTimeMap = new Map();
let xAxisYearMap = new Map();


const ReferenceLineLabel = ({ viewBox, label }) => {
  return (
    <g>
      <foreignObject
        x={viewBox.x > 150 ? (viewBox.x - 150) : viewBox.x - 50}
        y={viewBox.y - 25}
        width={300}
        height={viewBox.height + 20}
      >
        <Box
          sx={{
            backgroundColor: "#434A54",
            color: "white",
            textAlign: "center",
            borderRadius: 1,
            height: "25px",
          }}
        >
          <Typography variant="body2">{label}</Typography>
        </Box>
      </foreignObject>
    </g>
  );
};

class CustomizedAxisTick extends PureComponent {
  render() {
    const { x, y, stroke, payload, id } = this.props;
    let xAxisYear = xAxisYearMap.get(id);
    let xAxisTime = xAxisTimeMap.get(id);
    if (xAxisYear == undefined) {
      xAxisYear = '0';
      xAxisTime = '0/0'
      xAxisYearMap.set(id, '0')
      xAxisTimeMap.set(id, '0/0')
    }
    let dateArray1 = payload.value.split(" ");
    let dateArray2 = dateArray1[0].split("-");
    let localTimewdYear = dateArray2[0];
    let highLightText = false;
    let localTime = dateArray1[1];
    if (localTimewdYear !== xAxisYear && xAxisYear !== '0') {
      xAxisYearMap.set(id, localTimewdYear);
      xAxisTime.set(id, dateArray2[1] + "/" + dateArray2[2]);
      localTime = dateArray2[0];
      highLightText = true;
    } else {
      let localTimeDate = dateArray2[1] + "/" + dateArray2[2];
      if (localTimeDate !== xAxisTime) {
        xAxisTimeMap.set(id, localTimeDate);
        localTime = localTimeDate;
        highLightText = true;
      }
    }
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="end" fill="#666" style={{ fontWeight: highLightText ? "bold" : "normal" }}>
          {localTime}
        </text>
      </g>
    );
  }
}

class Chart extends Component {
  constructor(props) {
    super(props);    
    this.state = {
      containerWidth:
        this.props.location.pathname.includes("treeviewnew") &&
          this.props.isTreeOpen
          ? 'calc(100vw - 550px)'
          : 'calc(100vw - 170px)',
      containerChartWidth: this.props.location.pathname.includes("treeviewnew")
        ? this.props.isTreeOpen
          ? 'calc(100vw - 520px)'
          : 'calc(100vw - 150px)'
        : this.props.fromCsView
          ? 1200
          : 1100,
      deviceValue: null,
      activeTooltip: false,
      deviceName: null,
      deviceFill: null,
      sampleTime: null,
      refRecord: null,
      defaultRefPoint: null,
      expandedChartIndex: -1
    };
  }

  componentWillReceiveProps(nextProps, prevProps) {
    (nextProps.timerange !== this.props.timerange ||
      ((nextProps.timerangeEndDate != this.props.timerangeEndDate) &&
        (nextProps.aggregationValue == this.props.aggregationValue &&
          nextProps.aggregateFunction == this.props.aggregateFunction))) &&
      this.props.getMetricForDevices(
        this.props.deviceMetrics.deviceIds,
        this.props.deviceMetrics.selectedMetrics,
        nextProps.timerange,
        this.props.deviceMetrics.selectedCIs,
        this.props.deviceMetrics.isInterface,
        nextProps.timerangeEndDate,
        nextProps.aggregationValue,
        nextProps.aggregateFunction,
        nextProps.isInterpolation
      );
    if (nextProps.isTreeOpen != this.props.isTreeOpen) {
      this.props.getMetricForDevices(
        this.props.deviceMetrics.deviceIds,
        this.props.deviceMetrics.selectedMetrics,
        nextProps.timerange,
        this.props.deviceMetrics.selectedCIs,
        this.props.deviceMetrics.isInterface,
        nextProps.timerangeEndDate,
        nextProps.aggregationValue,
        nextProps.aggregateFunction,
        nextProps.isInterpolation,
      );
      if (this.props.location.pathname.includes("treeviewnew")) {
        nextProps.isTreeOpen
          ? this.setState({
            containerWidth: 'calc(100vw - 550px)',
            containerChartWidth: 'calc(100vw - 520px)',
          })
          : this.setState({ containerWidth: 'calc(100vw - 170px)', containerChartWidth: 'calc(100vw - 150px)' });
      } else {
        !this.props.fromCsView && nextProps.isTreeOpen
          ? this.setState({
            containerWidth: 1000,
            containerChartWidth: 900,
          })
          : this.setState({ containerWidth: 1200, containerChartWidth: 1100 });
      }
    }

  }
  handleCreateView = () => {
    this.props.toggleDrawer();
    this.props.createNewView();
  };

  getDeviceChartData = (metricDevice, aggInterval, selectedCombos) => {
    let lineChartArray = [];
    let sampleDataLength = 0;
    if (aggInterval !== -1) {
      sampleDataLength = metricDevice ? (metricDevice.chartDefination ? metricDevice.chartDefination.length : 0) : 0;
    } else {
      sampleDataLength = metricDevice ? (metricDevice.sampleTimeLength ? metricDevice.sampleTimeLength : 0) : 0;
    }
    if (metricDevice.deviceData) {
      metricDevice.deviceData.map((device, i) => {
        let dataKey = device.deviceName;
        if (device.ciName) {
          dataKey = `${dataKey}-${device.ciName}`;
        }
        let lineData = metricDevice ? (metricDevice.chartDefinationRaw ?
          metricDevice.chartDefinationRaw[dataKey]
          : null) : null;
        if (aggInterval == -1) {
          lineChartArray.push(
            <Line
              data={lineData}
              animationDuration={0}
              strokeWidth={2}
              dataKey={dataKey}
              key={dataKey}
              iconType={'circle'}
              dot={(<CustomizedDot color={selectedCombos[i].color} shape={selectedCombos[i].icon} />)}
              type="monotone"
              connectNulls={false}
              stroke={selectedCombos[i].color}
            />
          );
        } else {
          lineChartArray.push(
            <Line
              data={lineData}
              type="monotone"
              animationDuration={0}
              strokeWidth={2}
              dataKey={dataKey}
              key={dataKey}
              iconType={'square'}
              dot={(<CustomizedDot color={selectedCombos[i].color} shape={selectedCombos[i].icon} />)}
              connectNulls={false}
              // strokeDasharray= {`5 ${i * i}`}
              stroke={selectedCombos[i].color}
            />
          );
        }
      });
    }
    return lineChartArray;
  };

  customMouseOver = (props, event) => {
    if (event.dataKey) {
      this.setState({
        deviceValue: event.payload[event.dataKey],
        deviceName: event.dataKey,
        deviceFill: event.fill,
        activeTooltip: true,
        sampleTime: event.payload.sampleTime
      });
    } else if (props.dataKey) {
      this.setState({
        deviceValue: props.payload[props.dataKey],
        deviceName: props.dataKey,
        deviceFill: props.fill,
        activeTooltip: true,
        sampleTime: props.payload.sampleTime
      });
    }
  };

  over = () => {
    this.setState({
      activeTooltip: false,
    });
  };
  xAxisTickFormatter = (tick, id, aggInterval) => {
    if (aggInterval == -1) {
      tick = moment(tick * 1000).format("YYYY-MM-DD HH:mm");
    }
    let xAxisYear = xAxisYearMap.get(id);
    let xAxisTime = xAxisTimeMap.get(id);
    if (xAxisYear == undefined) {
      xAxisYearMap.set(id, '0')
      xAxisTimeMap.set(id, '0/0')
      xAxisYear = '0';
      xAxisTime = '0/0';
    }
    if (tick !== undefined && tick !== null && tick !== 'auto' && tick !== 0) {
      let dateArray1 = tick.split(" ");
      let dateArray2 = dateArray1[0].split("-");
      if (dateArray2[0] !== xAxisYear && xAxisYear !== '0') {
        xAxisYearMap.set(id, dateArray2[0]);
        xAxisTimeMap.set(id, dateArray2[1] + "/" + dateArray2[2]);
        let localTime = dateArray2[0];
        return localTime;
      } else {
        let localTime = dateArray2[1] + "/" + dateArray2[2];
        if (localTime !== xAxisTime) {
          xAxisTimeMap.set(id, localTime);
          xAxisYearMap.set(id, dateArray2[0]);
          return localTime;
        }
      }
      return dateArray1[1];
    }
    return tick;
  };
  dataInterPolation = (dataArray, aggregationInterval) => {
    if (aggregationInterval == -1) {
      return dataArray;
    }
    let outputArray = [];
    let count = 0;
    let keys = [];
    dataArray && dataArray.forEach(dataObject => {
      outputArray.push(dataObject);
      if (count < dataArray.length - 1) {
        let nextObject = dataArray[count + 1];
        if (count === 0) {
          keys = Object.keys(dataObject);
          keys = keys.filter(key => key !== "sampleTime");
        }
        let dataObjectMilli = this.getDateInMilli(dataObject.sampleTime);
        let nextObjectMilli = this.getDateInMilli(nextObject.sampleTime);
        let sampleDiff = nextObjectMilli - dataObjectMilli;
        let copiedObject = dataObject;
        while (sampleDiff > (aggregationInterval * 1000)) {
          let newObject = {};
          let newSampleTime = dataObjectMilli + (aggregationInterval * 1000);
          let newDate = new Date(newSampleTime);
          newObject['sampleTime'] = newDate.getFullYear() + "-" + ((newDate.getMonth() + 1) + '').padStart(2, '0')
            + "-" + (newDate.getDate() + '').padStart(2, '0')
            + " " + (newDate.getHours() + '').padStart(2, '0')
            + ":" + (newDate.getMinutes() + '').padStart(2, '0');
          keys && keys.forEach(key => {
            let value = copiedObject[key];
            let value1 = nextObject[key];
            let newValue = null;
            if (value !== null && value1 !== null) {
              let y = value + (newSampleTime - dataObjectMilli) * ((value1 - value) / (nextObjectMilli - dataObjectMilli))
              newValue = y < 0 ? (y * -1) : y;
              newValue = newValue == 0 ? 0 : ((newValue + Number.EPSILON) * 100) / 100;
            }
            newObject[key] = newValue;
          })
          outputArray.push(newObject);
          sampleDiff = nextObjectMilli - newSampleTime;
          dataObjectMilli = newSampleTime;
          copiedObject = newObject;
        }
        count++;
      }

    })
    return outputArray;
  }

  getDateInMilli = (dateObject) => {
    let dataDateObjArray = dateObject.split(" ");
    let dataTimeArray = dataDateObjArray[1].split(":");
    let dataDateArray = dataDateObjArray[0].split("-");
    let dataDate = new Date(dataDateArray[0], dataDateArray[1] * 1 - 1, dataDateArray[2], dataTimeArray[0], dataTimeArray[1]);
    return dataDate.getTime();
  }

  setRefRecord = (value) => {
    this.setState({ refRecord: value });
  }

  setDefaultRefPoint = (value) => {
    this.setState({ defaultRefPoint: value });
  }

  getMyColor = () => {
    let n = (Math.random() * 0xfffff * 1000000).toString(16);
    return '#' + n.slice(0, 6);
  };

  getCharts = () => {    
    chartArray = [];
    let hasNoMetric = false;    
    this.props.deviceMetrics.metricsForDevices &&
      this.props.deviceMetrics.metricsForDevices.map((metricDevice, index) => {
        let sampleDataLength = metricDevice.sampleTimeLength ? metricDevice.sampleTimeLength : 0;
        hasNoMetric = metricDevice.name == "NO-DATA";
        let data1 = this.dataInterPolation(metricDevice.chartDefination, this.props.aggregationValue)
        //let recordArray = metricDevice.chartDefinationRaw[`${metricDevice.deviceData[0].deviceName}-${metricDevice.deviceData[0].ciName}`]
        //const shuffledCombos = strokeCombinations.sort(() => 0.5 - Math.random());
        //let selectedCombos = shuffledCombos.slice(0, metricDevice.sampleTimeLength>0?metricDevice.sampleTimeLength:1);
        const count = metricDevice?.deviceData?.length;
        let prevcount = 0
        if(localStorage.getItem("count_target_"+index)!==null)
        {
          prevcount = localStorage.getItem("count_target_"+index)
        }        
        localStorage.setItem("count_target_"+index,count)
        const isColorChange = parseInt(prevcount)===parseInt(count)?false:true;        
        let selectedCombos = [];
        let i;
        for(i=0;i<count;i++)
        {
          selectedCombos.push({
            color: this.getMyColor(),
            stroke: "dashed",
            icon: "filledCircle"
        })
        }
        let layout = 'horizontal';
        const noOfCharts = this.props.deviceMetrics.metricsForDevices.length;
        if (this.props.columns === 1 || this.props.columns === null) {
        //   if (noOfCharts === 1) {
        //     layout = 'horizontal'
        //   }
        //   else {
             layout = 'horizontal'
        //   }
        }
        // else {
        //   if (noOfCharts <= 3) {
        //     layout = 'horizontal'
        //   }
        //   else {
        //     layout = 'vertical'
        //   }
        // }
        metricDevice.name !== "NO-DATA" &&
          chartArray.push({
            chartName: `${metricDevice.name} (in ${metricDevice.unit})`,
            chartInfo: data1.length == 0 && sampleDataLength == 0 ?
            <Typography style={{ padding: '20px', height: '350px' }} variant="h6">{"No data to display"}</Typography>
              : (
                <MineralChart
                  metricDevice={metricDevice}
                  selectedCombos={selectedCombos}
                  index={index}
                  width={Math.floor(this.state.containerChartWidth)}
                  height={index === this.state.expandedChartIndex ? 600 : 350}
                  aggregationValue={this.props.aggregationValue}
                  xAxisTickFormatter={this.xAxisTickFormatter}
                  layout={layout}                  
                  ref={(chart) => (this[`${metricDevice.name}_${index}`] = chart)}
                  columns={2}
                  expandedChartIndex={this.state.expandedChartIndex}
                  isColorChange={isColorChange}
                />
              ),
          });
      });
    if (!chartArray.length) {
      if (hasNoMetric) {
        return (<div></div>);
      }
      else if (this.props.namedMetricList.MyViews.length ||
        this.props.namedMetricList.SharedViews.length
      ) {
        return (
          <div className="no-metrics empty-view">
            No metric view is selected. Please select one metric view from
            metric views list or define a default metric view in managed views
          </div>
        );
      } else {
        return (
          <div className="no-metrics empty-view">
            You have no metric views defined yet.
            <a className="create-view" onClick={this.handleCreateView}>
              {" "}
              Create a view{" "}
            </a>
            now to get started
          </div>
        );
      }
    }

    if (this.props.columns === 2 || chartArray.length === 1) {
      return (
       
        <div style={{ backgroundColor: '#f4f4f4', padding: '8px 16px 16px 8px', borderRadius: '4px' }}>

          <RaisedButton
            variant="contained"
            style={{ marginLeft: "20px", display: "none" }}
            children="Export Charts"
            onClick={this.exportChart}
          />
           <Grid container spacing={0} >
          {chartArray.map((cData, index) => (
            <Grid item xs={(this.state.expandedChartIndex === index)?12:6} >
            <div
              className="timeserie-wrapper time-serie__2-column"              
              key={index}
            >   
            {(this.state.expandedChartIndex === index) && <div id="selectedView"></div>}            
            <Box display='flex' justifyContent='space-between' className="title" >
                <Typography><b>{cData.chartName}</b></Typography>                
                <Box title={this.state.expandedChartIndex === index ? "Collapse Chart" : "Expand Chart"}>                  
                  <IconButton name="expand-icon-button" style={{marginBottom:"2px"}} onClick={() => {
                    if (this.state.expandedChartIndex === index) {
                      this.setState({ expandedChartIndex: -1 })                      
                    }
                    else {
                      this.setState({ expandedChartIndex: index }) 
                      setTimeout(()=>{
                        const element = document.getElementById("selectedView");
                        element.scrollIntoView();
                      },1500)                      
                    }
                  }}>{this.state.expandedChartIndex === -1?<ExpandIcon />:<CollapseIcon  />}</IconButton>                  
                </Box>                
              </Box>              
              {cData.chartInfo}         
                         
            </div>
            </Grid>
          ))}
          </Grid>
        </div>        
      );
    }

    let splitChartArr = [];
    while (chartArray.length) {
      splitChartArr.push(chartArray.splice(0, this.props.columns));
    }
    console.log("splitChartArr",splitChartArr);
    //this.setState({ expandedChartIndex: -1 })
    return (
      <div style={{ backgroundColor: '#f4f4f4', padding: '8px 16px 16px', borderRadius: '4px' }}>
        <RaisedButton
          variant="contained"
          style={{ marginLeft: "20px", display: "none" }}
          children="Export Charts"
          onClick={this.exportChart}
        />


        {splitChartArr.map((chartData, index) => { return (
          <div style={{display: 'flex', flexWrap: "row wrap", gap: 20}}>
            {chartData.map((cData, index1) => {              
              const chartIndex = 2*index + index1;
              return(
              <div className="timeserie-wrapper"
                style={{
                  width: `${100 / this.props.columns}%`
                }}
                key={chartIndex}
              >
                <Box display='flex' justifyContent='space-between' className="title" title={this.state.expandedChartIndex === chartIndex ? "Collapse Chart" : "Expand Chart"}>
                <Typography><b>{cData.chartName}</b></Typography>
                <Box>
                  <IconButton name="expand-icon-button" onClick={() => {
                    if (this.state.expandedChartIndex === chartIndex) {
                      this.setState({ expandedChartIndex: -1 })
                    }
                    else {
                      this.setState({ expandedChartIndex: chartIndex })
                    }
                  }}>{this.state.expandedChartIndex === -1?<ExpandIcon />:<CollapseIcon />}</IconButton>
                </Box>
              </Box>
                {cData.chartInfo}
              </div>
            )})}
          </div>
        )})}
      </div>
    );
  };
  exportChart = (asSVG) => {
    this.props.deviceMetrics.metricsForDevices.map((data, index) => {
      let chartSVG = ReactDOM.findDOMNode(this[`${data.name}_${index}`])
        .children[0];
      if (asSVG) {
        let svgURL = new XMLSerializer().serializeToString(chartSVG);
        let svgBlob = new Blob([svgURL], {
          type: "image/svg+xml;charset=utf-8",
        });
        FileSaver.saveAs(svgBlob, `${data.name}_${index}.svg`);
      } else {
        let svgBlob = new Blob([chartSVG.outerHTML], {
          type: "text/html;charset=utf-8",
        });
        FileSaver.saveAs(svgBlob, `${data.name}_${index}.html`);
      }
    });
  };
  render() {
    xAxisYearMap.clear();
    xAxisTimeMap.clear();
    if (
      this.props.deviceMetrics.isFetchingDevice ||
      this.props.deviceMetrics.isFetching
    ) {
      return <div className="metrics-spinner"><CircularProgress style={{ color: '#00AEEF' }} /></div>;
    }
    return (
      <div className="metrics">
        <div className="charts">{this.getCharts()}</div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    // setBreadCrumbs: (items) => dispatch(setBreadCrumbs(items)),
    setColumns: ()=>dispatch(setColumns(1)),
    getMetricForDevices: (
      deviceIds,
      metricTypes,
      hours,
      selectedCIs,
      isInterface,
      endDate,
      aggregationValue,
      aggregateFunction,
      isInterpolation
    ) =>
    
      dispatch(
        getMetricDevices(
          deviceIds,
          metricTypes,
          hours,
          selectedCIs,
          isInterface,
          endDate,
          aggregationValue,
          aggregateFunction,
          isInterpolation
        )
      ),
  };
};

const mapStateToProps = (state) => {
  return {
    timerange: state.timerange.hours,
    timerangeEndDate: state.timerange.endDate,
    aggregationValue: state.timerange.aggregationInterval,
    aggregateFunction: state.timerange.aggregationFunction,
    isInterpolation: state.timerange.isInterpolation,
    columns: 2,
    deviceMetrics: state.deviceMetrics,
    namedMetricList: state.deviceMetrics.named_metric_list,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Chart));
