import React, { Component,useMemo } from "react";
import connect from "../../../utils/connect";
import {
  fetchOpenAlarmspoll,
  pollOpenAlarmsStop,
} from "../../../api/home-screen/actions";
import {
  ClearIcon as Clear,
  CenterError as Error,
  CenterInfo,
} from "../../ui-components/uim-components";
import {CircularProgress} from "@mineral/core";
import { Card, IconButton ,Button} from "@mineral/core";
import {
  Bar,
  BarChart,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  LabelList,
} from "recharts";
import theme from "../../../theme";
import isEqual from "lodash/isEqual";
import Resource from "./Resource";
import { isValidParam } from "../DashboardContainerRedux";

import AccessibleTooltip  from "./../../common/AccessibleTooltip";

//const chartColors = ['#A11D2E', '#F7922F', '#FEE276', '#9EC6D0', '#D9E9EC']
const chartColors = ["#DE1B1B", "#F78F4A", "#F7CD3B", "#80BED9", "#3272D9"];

const Panel = (props) => {
  useMemo(() => {
    if (props?.data ) {
      props?.data.map((item, idx) => {
        item.severityNameAlarmCount = `${item.severityName}-${item.alarmCount}`
       
      })
    }
  }, [props?.data])
  const CustomLabel = ({ x, y, stroke, value, width, index }) => {
    try {
      const labelX = x + width / 2;
      return (
        <text
          className="recharts-text"
          onClick={(e) => onClick(e, index)}
          x={labelX}
          y={y}
          dy={-4}
          fill={stroke}
          style={{ fill: chartColors[index], cursor: "pointer" }}
          fontSize={14}
          textAnchor="middle"
        >
          <tspan>{value}</tspan>
        </text>
      );
    } catch (e) {
      return null;
    }
  };

  //Alarm Count
  let alarmCountText = Resource.get("Alarm Count");
  const onClick = (e, index) => {
    if (
      props.history.location.pathname.includes("groups") ||
      props.history.location.pathname.includes("computer-systems")
    ) {
      if (
        props.history.location.pathname.search("treeviewnew") > -1 &&
        (props.selectedEntity.id != 0 || props.csId)
      ) {
        if (props.csId) {
          props.history.replace(
            `/computer-systems/${props.csId}/alarms?severity=${5 - index}`
          );
        } else {
          props.history.push(
            `${props.selectedEntity.id}/alarms?severity=${5 - index}`
          );
        }
      } else {
        props.history.push(`alarms?severity=${5 - index}`);
      }
    } else if (props.history.location.pathname.includes("technologies")) {
      props.history.push(`alarms?severity=${5 - index}`);
    }
  };
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="chartTooltip">
          <div>{`${label.split('-')[0]}`}</div>
          <div>{`${alarmCountText} : ${payload[0].value}`}</div>
        </div>
      );
    }

    return null;
  };
  return (
    <div style={{ flex: "90%", height: "100%", width: "100%" }} tabIndex={0} role="img" aria-label={"A bar graph showing alarm count statistics with Severity on X axis and open alarm count on Y axis: "+props?.data?.map(entry=> entry?.severityName+" "+alarmCountText+" "+entry?.alarmCount)+" Click on bars to navigate to alarms of a specific severity"}>
      <ResponsiveContainer>
        <BarChart data={props.data} style={{ backgroundColor: "#f4f4f4" }}
        margin={{
          top: 16,
          bottom:16,
        }}
        >
          <Bar
            onClick={onClick}
tabIndex={0}
            dataKey="alarmCount"
            maxBarSize={70}
            label={<CustomLabel />}
          >
            {/*<LabelList dataKey="alarmCount" position="top"/>*/}
            {props.data.map((entry, index) => (
              <Cell
                className={
                  "total-alarm__overview--bar chart__severity--" +
                  entry.severityName.toUpperCase()
                }
                key={`cell-${index}`}
                fill={chartColors[index]}
              />
            ))}
          </Bar>
          <XAxis dataKey="severityNameAlarmCount" axisLine={true} tickLine={false} />

          <YAxis />
          <Tooltip
            isAnimationActive="false"
            cursor={{ fill: "transparent"}}
            content={<CustomTooltip />}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};
class PanelContainer extends Component {
  state = {
    status: "loading",
    alarms: [],
  };

  apiError = () => {
    this.setState({
      status: "failure",
    });
  };
  componentDidMount() {
    let groupIds = String(this.props.groupIds);
    let probeId = String(this.props.probeId);
    let csId = String(this.props.csId);
    this.props.fetchOpenAlarms(
      isValidParam(csId) ? (csId == "0" ? null : csId) : null,
      isValidParam(groupIds) ? groupIds : null,
      isValidParam(probeId) ? probeId : null
    );
  }

  componentWillUnmount() {
    this.props.pollOpenAlarmsStop();
  }
  componentWillReceiveProps(nextProps) {
    // if (!isEqual(nextProps.openAlarms, this.state.alarms)) {
    this.setState({
      alarms: nextProps.openAlarms,
      status: nextProps.isOpenAlarmsFetching ? "loading" : "complete",
    });
    // }
  }

  handleViewAllClick = () => {
    if (
      this.props.history.location.pathname.includes("groups") ||
      this.props.history.location.pathname.includes("computer-systems")
    ) {
      if (
        this.props.history.location.pathname.search("treeviewnew") > -1 &&
        (this.props.selectedEntity.id != 0 || this.props.csId)
      ) {
        if (this.props.csId) {
          this.props.history.replace(
            `/computer-systems/${this.props.csId}/alarms`
          );
        } else {
          this.props.history.push(`${this.props.selectedEntity.id}/alarms`);
        }
      } else {
        this.props.history.push(`alarms`);
      }
    } else if (this.props.history.location.pathname.includes("technologies")) {
      this.props.history.push(`alarms`);
    }
  };
  render() {
    let view = (
      <Panel
        className={this.props.className}
        data={this.state.alarms}
        history={this.props.history}
        selectedEntity={this.props.selectedEntity}
        csId={this.props.csId}
      />
    );
    //No Alarms Found
    let noAlarmsText = Resource.get("No Alarms Found");
    //Open Alarms
    let title = Resource.get("Open Alarms");
    //View All
    let viewAllText = Resource.get("View All");
    let cardHeader = (
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h2 className="overview_container_title">{title}</h2>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Button variant="text"
           style={{height:'16px'}}
            onClick={() => {
              this.handleViewAllClick();
            }}
          >
            {viewAllText}
          </Button>
          {!this.props.hideCloseButton && (
            <span className="overview_header_seprator">|</span>
          )}
          {!this.props.hideCloseButton && (
          <AccessibleTooltip title={"Close"}>
            <IconButton
              style={{ padding: 0 }}
              title={"Close"}
              onClick={() => {
                this.props.handleCloseDialog("showOpenAlarms");
              }}
            >
             
              <Clear className="clearIcon"  role="figure"
            title="Close"/>
            </IconButton>
             </AccessibleTooltip>
          )}
        </div>
      </div>
    );

    if (
      this.state.status === "loading" &&
      !this.props.didOpenAlarmsInvalidate
    ) {
      view = (
        <div
          className="no_data_panel"
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress style={{ color: "#3272D9" }} />
        </div>
      );
    } else if (
      this.state.status === "failure" ||
      this.props.didOpenAlarmsInvalidate
    ) {
      view = (
        <div className="no_data_panel">
          <Error />
        </div>
      );
    } else if (
      this.state.status === "complete" &&
      this.state.alarms.length == 0
    ) {
      view = (
        <div className="no_data_panel">
          <CenterInfo title={noAlarmsText} />
        </div>
      );
    }
    return (
      <Card
        className={this.props.className}
        // style={{
        //   height: "100%",
        //   width: null,
        //   margin: null,
        // }}
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          padding: "16px",
          boxSizing: "border-box",
        }}
      >
        {cardHeader}
        {view}
      </Card>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchOpenAlarms: (csId, groupIds, probeId) =>
      dispatch(fetchOpenAlarmspoll(csId, groupIds, probeId)),
    pollOpenAlarmsStop: () => dispatch(pollOpenAlarmsStop()),
  };
};
const mapStateToProps = (state) => {
  return {
    openAlarms: state.homeScreen.openAlarms,
    isOpenAlarmsFetching: state.homeScreen.isOpenAlarmsFetching,
    didOpenAlarmsInvalidate: state.homeScreen.didOpenAlarmsInvalidate,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PanelContainer); //connect so that we can get a handle on history
