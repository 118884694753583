// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Administration
  content["Configure"] =
  "Configure";

  //Configure automatic groups
  content["Configure application discovery and automatic groups"] =
  "Configure application discovery and automatic groups";

  //policies
  content["policies"] =
  "policies";

  // policy
  content["policy"] =
  "policy";

  //Existing policies
  content["Existing policies"] =
  "Existing policies";

  //Alarm Policies
  content["Alarm Policies"] =
  "Alarm Policies";

  //Manage when alarms are created
  content["Manage when alarms are created"] =
  "Manage when alarms are created";

  //Parent Group
  content["Parent Group"] =
  "Parent Group";

  //Settings
  content["Settings"] =
  "Settings";

  //Administration
  content["Administration"] =
  "Administration";

  //Required
  content["Required"] =
  "Required";

  //Saved successfully.
  content["Saved successfully."] =
  "Saved successfully.";

  //OS Type
  content["OS Type"] =
  "OS Type";

  //OS Name
  content["OS Name"] =
  "OS Name";

  //Origin
  content["Origin"] =
  "Origin";

  //User Tag 1
  content["User Tag 1"] =
  "User Tag 1";

  //User Tag 2
  content["User Tag 2"] =
  "User Tag 2";

  //Save
  content["Save"] =
  "Save";

  // END OF LOCALIZATION

export default content;
