import axios from 'axios';
import config from './../../config';

class Slack {
  get(params) {
    return axios.get([config.basename, 'api/integration/slack'].join('/'));
  }

  save(params) {
    return axios.post([config.basename, 'api/integration/slack'].join('/'), params);
  }

  test(params) {
    return axios.post([config.basename, 'api/integration/slack/test'].join('/'), params);
  }

  deleteConfig(params) {
    return axios.delete([config.basename, 'api/integration/slack'].join('/'), params);
  }
}
export default new Slack();
