import React from 'react'
import { ClearIcon } from '../ui-components/uim-components'
import './modals.less'
import Resource from './Resource'

const RequestTrialsExtension = ({ handleCloseModal }) => {
  return (
    <div className="modal">
      <div className="modal__close" onClick={handleCloseModal}>
        <ClearIcon />
      </div>
      <p className="modal__primarytext">
      {//Thank you message
        Resource.get('Thank you, your sales representative<br />will contact you soon')}
      </p>
      <button className="modal__button" onClick={handleCloseModal}>
      {//CLOSE
        Resource.get('CLOSE')}
      </button>
    </div>
  )
}

RequestTrialsExtension.defaultProps = {
  handleCloseModal: function() {
    alert('Close modal')
  }
}
export default RequestTrialsExtension
