import React, { Component } from "react";
import {
  CenterError as Error,
  FloatingActionButton,
  ErrorIcon as Information,
  PlusIcon as ContentAdd,
} from "../ui-components/uim-components";
import {CircularProgress} from "@mineral/core";
import Table from "./Table";
import AddSubgroupDialog from "../titlebar/AddSubgroupDialog";
import "../cards/cards.less";
import "./table.less";
import AddDeviceToGroupDialogRedux from "./../titlebar/AddDeviceToGroupDialogRedux";
import DynamicGroupDialog from "./../titlebar/dynamicGroup/DynamicGroupDialog";
import ErrorDialog from "./../titlebar/ErrorDialog";
import Resource from "./Resource";
import infoIcon from "../../assets/icons/infomessage.svg";
import "./treeview.less";
import "./index.less";
import { Tabs, Tab, Divider } from "@mineral/core";
import Back from "../../assets/icons/back.svg";
import MenuOpen from "../../assets/icons/Menu Open.svg";
import Forward from "../../assets/icons/forward.svg";
import { Route } from "react-router-dom";
import DialogManager from "./../titlebar//DialogManager";
import Tree, { TreeNode } from "rc-tree";
import SeverityIcon from "./../icons/SeverityIcon";
import axios from "axios";
import config from "../../api/config";
import entityTypes from "./../../api/entityTypes";
import capitalize from "lodash/capitalize";
import AclTypes from "./../../api/aclTypes";

const WIDTH_COLLAPSED = 56;
const WIDTH_EXPANDED = 256;
const TRANSITION_DELAY_LONG = "0s";
const TRANSITION_DELAY_NONE = "0s";
let Selected = "";
let GroupAlarmMapper = {};
let DeviceAlarmMapper = {};

class TreeViewManager extends Component {
  constructor(props) {
    super(props);
    this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
    this.selectFunction = this.selectFunction.bind(this);
    this.handleRowClickedChange = this.handleRowClickedChange.bind(this);
    this.onSelect = this.onSelect.bind(this);
  }
  state = {
    gridData: [],
    showMenu: false,
    subGroupOpen: false,
    showAddSubgroupDialog: false,
    showAddDevicesDialog: false,
    showErrorForAddDevices: false,
    showErrorForAddGroups: false,
    editSubGroupOpen: false,
    openDynamicGroupDialog: false,
    launchFrom: "groups",
    groupId: 0,
    open: true,
    close: false,
    transitionDelay: TRANSITION_DELAY_LONG,
    width: WIDTH_COLLAPSED,
    expanded: false,
    currentTab: "Details",
    language: "",
    treeRender: false,
    data: this.props.groupData,
    dataGroups: this.props.data,
    treeview: [...this.props.entities],
    slectedValue: [],
    row: [],
    rowInfo: true,
    displayName: "",
    addGroup: true,
    deleteGroup: false,
    renameGroup: false,
    editGroup: false,
    noRowSelected: false,
    deleteDevice: false,
    rename: false,
    AddgroupFirst: false,
    deleteDevicesOpen: false,
    deleteSubGroupOpen: false,
    selectedKeys: ["0"],
    enableDeviceView: false,
    renderVIew: false,
    group: [],
    item_value: [],
  };
  componentWillMount() {}

  componentWillUnmount() {}
  componentDidMount() {
    const id = 0;
    let group = {
      id: 0,
      parentId: -1,
      name: "Root",
      description: null,
      type: "CONTAINER",
      masterElementGroup: false,
      accountId: null,
      criteria: null,
      nameToken: null,
      descriptionToken: null,
      lastUpdate: null,
      attributes: [],
      state: 1,
      priority: 0,
      computerSystems: [],
    };
    //let groupUrl = [config.basename, "api/v2/groups/stack", id].join("/");
    let groupUrl =
      process.env.NODE_ENV == "development"
        ? "http://10.252.25.208:8080/castlemock/mock/rest/project/ViXRbO/application/CHrXQ3/groups"
        : [config.basename, "api/v2/groups/stack", id].join("/");
    // [config.basename, "api/v2/groups/stack"].join("/"); //for dev
    return axios.get(groupUrl).then((groupsRes) => {
      group.children = groupsRes.data;
      // group.children = groupsRes.data._items; //for dev

      const isComputerSystemContainer = (entity) => {
        return (
          entity &&
          entity.type !== entityTypes.ROOT &&
          entity.type !== entityTypes.CONTAINER
        );
      };
      const entityKey = isComputerSystemContainer(group)
        ? "computerSystem"
        : "children";
      let groupChildren = group[entityKey];

      let childDetailsUrl = [config.basename];
      if (isComputerSystemContainer(group)) {
        childDetailsUrl =
          process.env.NODE_ENV == "development"
            ? "http://10.252.25.208:8080/castlemock/mock/rest/project/ViXRbO/application/xb77Al/computers"
            : childDetailsUrl.concat(["/api/v1/computer-systems?groupId=", id]);
      } else {
        childDetailsUrl =
          process.env.NODE_ENV == "development"
            ? "http://10.252.25.208:8080/castlemock/mock/rest/project/ViXRbO/application/xb77Al/children"
            : childDetailsUrl.concat([
                "/api/v2/groups/stack/",
                id,
                "/child-details",
              ]);
      }
      return axios.get(childDetailsUrl.join("")).then((childDetailsRes) => {
        const childDetails = childDetailsRes.data;

        childDetails.Groups &&
          childDetails.Groups.map(
            (grp) => (GroupAlarmMapper[grp.grpId] = grp.state)
          );
        childDetails.Devices &&
          childDetails.Devices.map(
            (dvc) => (DeviceAlarmMapper[dvc.devId] = dvc.state)
          );

        // group["computer-systems"] = group.computerSystems;
        group["computer-systems"] = group.computerSystem;
        groupsRes.data = group;
        this.setState({
          group: group,
        });
      });
    });
  }
  componentDidUpdate(prevProps, prevState) {}
  forceUpdateHandler() {}
  handleGroupCellClick(row, histor) {
    this.props.handleGroupCellClick(row, histor);
  }
  handleAddSubgroup = () => {
    this.setState({ showAddSubgroupDialog: true });
  };
  handleAddSubgroupClose = (dialogName, info) => {
    this.setState({ showAddSubgroupDialog: false });
    if (dialogName === "subGroupOpen" && info && info.isDynamicChecked) {
      this.setState({ openDynamicGroupDialog: true, dynamicGroupInfo: info });
    }
  };

  handleAddDevices = () => {
    this.setState({ showAddDevicesDialog: true });
  };
  handleAddDevicesClose = () => {
    this.setState({ showAddDevicesDialog: false });
  };

  handleAddCriteria = () => {
    this.setState({ editSubGroupOpen: true });
  };
  handleAddCriteriaClose = (dialogName) => {
    if (dialogName === "dynamicGroupsOpen") {
      this.setState({ openDynamicGroupDialog: false, dynamicGroupInfo: null });
    }
    this.setState({ editSubGroupOpen: false });
  };

  handleErrorDialogOpenAddDevices = () => {
    this.setState({
      showErrorForAddDevices: true,
    });
  };
  handleErrorDialogCloseAddDevices = () => {
    this.setState({
      showErrorForAddDevices: false,
    });
  };
  handleErrorDialogOpenAddGroups = () => {
    this.setState({
      showErrorForAddGroups: true,
    });
  };
  handleErrorDialogCloseAddGroups = () => {
    this.setState({
      showErrorForAddGroups: false,
    });
  };

  clsoeTreeView = () => {
    if (this.state.open == true) {
      this.setState({
        open: false,
        expanded: false,
        width: WIDTH_COLLAPSED,
        transitionDelay: TRANSITION_DELAY_NONE,
      });
    } else {
      this.setState({
        open: true,
        expanded: false,
        width: WIDTH_COLLAPSED,
        transitionDelay: TRANSITION_DELAY_NONE,
      });
    }
  };
  handleLanguage = (langValue, info, arr) => {
    this.setState({ language: arr, treeRender: true });
  };
  handleRequestChange = (open) => {
    this.setState({
      showMenu: open,
    });
  };
  //Handling all three dot Menu Funtionality
  handleOnAddGroupMenuClick = () => {
    this.setState({
      showMenu: false,
      subGroupOpen: true,
      showAddSubgroupDialog: true,
    });
  };
  handleDialogClose = (dialogName) => {
    this.setState({
      [dialogName]: false,
      errorType: "none",
    });
  };
  handleDeleteDevicesClick = () => {
    this.setState({
      showMenu: false,
      deleteDevicesOpen: true,
    });
  };
  handleRenameGroupMenuClick = () => {
    this.setState({
      showMenu: false,
      renameSubGroupOpen: true,
    });
  };
  handleOnDeleteGroupMenuClick = () => {
    this.setState({
      showMenu: false,
      deleteSubGroupOpen: true,
    });
  };
  //ENd of methods of all three dot menu Functionality
  //Start of GroupTreeViw//
  getDefaultProps() {
    return {};
  }
  getInitialState() {
    return {
      selectedKeys: ["0"],
    };
  }

  componentWillUpdate(nextProps, nextState) {
    // // console.log("from componentwillupdate",nextProps)
    // // invoked immediately before rendering with new props or state, not for initial 'render'
    // // see componentWillReceiveProps if you need to call setState
    // // console.log(nextState.gData === this.state.gData);
    // if (nextState.gData === this.state.gData) {
    //   this.notReRender = true;
    // } else {
    //   this.notReRender = false;
    // }
  }
  onCheck(checkedKeys) {
    this.setState({
      checkedKeys,
    });
  }
  onCheckStrictly(checkedKeys1 /* extra*/) {
    // console.log(arguments);
    this.setState({
      checkedKeys1,
    });
  }
  selectFunction(info) {
    this.setState({
      language: info.node.props.children,
      treeRender: true,
    });
  }
  handleLangChange(selectedKeys, info) {
    // console.log(this);
    //  selectFunction(info);
    // // this.setState({
    // //   language:info.node.props.children,
    // //   treeRender:true
    // // })
  }

  onSelect(selectedKeys, info) {
    // this.child.handleDrillDown(info.node.props)
    // console.log(info);
    const gridArray = [];
    let gridDisplayname = "";
    // if (info.node.props.groups != undefined) {
    if (info.node.props.children != undefined) {
      // for (var i = 0; i < info.node.props.groups.length; i++) {
      for (var i = 0; i < info.node.props.children.length; i++) {
        // if (info.node.props.groups[i].length) {
        if (info.node.props.children[i].length) {
          // for (var j = 0; j < info.node.props.groups[i].length; j++) {
          for (var j = 0; j < info.node.props.children[i].length; j++) {
            // gridArray.push(info.node.props.groups[i][j].props);
            gridArray.push(info.node.props.children[i][j].props);

            // else{
            //   gridArray.push(info.node.props)
            // }
          }
        }
      }
      if (!gridArray.length) {
        gridArray.push(info.node.props);
        if (gridArray.length == 1) {
          if (gridArray && gridArray[0] && gridArray[0].displayName != null) {
            gridDisplayname = gridArray[0].displayName;
          }
        }
      }
    } else {
      gridArray.push(info.node.props);
      if (gridArray.length == 1) {
        if (gridArray && gridArray[0] && gridArray[0].displayName != null) {
          gridDisplayname = gridArray[0].displayName;
        }
      }
    }

    //  this.callMethod(gridArray);
    this.setState({
      gridData: gridArray,
      displayName: info.node.props.titlename,
      treeRender: true,
      enableDeviceView: gridDisplayname ? true : false,
      name: "praneet",
      open: true,
      noRowSelected: false,
      AddgroupFirst: true,
      deleteGroup: false,
      selectedKeys: selectedKeys,
    });
    this.child.handleDrillDown(info.node.props);
    //  this.props.handleCabiCellClick(info.node.props, history)
    //this.props.handleCabiCellClick(info.node.props,'/groups/0/37/list')
  }

  handleRowClickedChange(row, history) {
    let itemType = row.type;
    let columnName = row.titlename;
    if (
      itemType !== row.type.COMPUTER_SYSTEM &&
      columnName !== "maxAlarmSeverity.value" &&
      columnName !== " "
    ) {
      this.props.handleGroupCellClick(row, history);
    } else if (columnName !== "checkbox") {
      if (columnName === "maxAlarmSeverity.value") {
        this.props.handleAlarmCellClick(
          row,
          history,
          this.props.saas,
          this.props.doiurl,
          this.props.featureFlags
        );
      } else {
        this.props.handleCabiCellClick(row, history);
      }
    }
    //console.log("from handleRowClikc", row);
    //   row.data.children.map((rowdata) => {
    //     if(rowdata.name != "" && rowdata.name != undefined )
    //     {
    //       this.setState({
    //         addGroup:true,
    //         renameGroup:true,
    //         deleteGroup:true,
    //         editGroup:false,
    //         noRowSelected:true,
    //         deleteDevice:false,
    //         rename:true
    //       })
    //     }
    //     else{
    //      this.setState({
    //       deleteGroup:true,
    //       addGroup:false,
    //       AddgroupFirst:false,
    //       deleteDevice:true,
    //       editGroup:false,
    //       noRowSelected:true,
    //       rename:true
    //      })
    //     }
    //   })
    // this.setState({
    //   row:row,
    //   rowInfo:true
    // })
  }

  //end of GroupTree View
  render() {
    // console.log(this.props.data);
    let item_value = [];
    let max = 0;
    // const value = this.state.group.groups;
    const value = this.state.group.children;
    if (value) {
      item_value = value
        .map((item) => {
          const alarmCountSummary =
            item.alarmCountSummary &&
            Object.keys(item.alarmCountSummary).map(function (key) {
              let name = key === "information" ? "info" : key;
              let cnt = item.alarmCountSummary[key];
              max = max >= cnt ? max : cnt;
              return { name: capitalize(name), cnt: cnt };
            });
          let maxAlarmSeverity = 0;
          if (alarmCountSummary && alarmCountSummary.length) {
            if (alarmCountSummary[0].cnt > 0) {
              maxAlarmSeverity = {
                //CRITICAL
                label: Resource.get("CRITICAL"),
                value: 5,
                cnt: alarmCountSummary[0].cnt,
              };
            } else if (alarmCountSummary[1].cnt > 0) {
              maxAlarmSeverity = {
                //MAJOR
                label: Resource.get("MAJOR"),
                value: 4,
                cnt: alarmCountSummary[1].cnt,
              };
            } else if (alarmCountSummary[2].cnt > 0) {
              maxAlarmSeverity = {
                //MINOR
                label: Resource.get("MINOR"),
                value: 3,
                cnt: alarmCountSummary[2].cnt,
              };
            } else if (alarmCountSummary[3].cnt > 0) {
              maxAlarmSeverity = {
                //WARNING
                label: Resource.get("WARNING"),
                value: 2,
                cnt: alarmCountSummary[3].cnt,
              };
            } else if (alarmCountSummary[4].cnt > 0) {
              maxAlarmSeverity = {
                // INFO
                label: Resource.get("INFO"),
                value: 1,
                cnt: alarmCountSummary[4].cnt,
              };
            } else {
              maxAlarmSeverity = {
                // NONE
                label: Resource.get("NONE"),
                value: 0,
                cnt: 0,
              };
            }
          }
          var totalAlarmCount =
            alarmCountSummary &&
            alarmCountSummary.reduce(function (total, alarm) {
              return alarm.cnt + total;
            }, 0);
          return Object.assign({}, item, {
            alarmCountSummary: alarmCountSummary,
            maxAlarmSeverity: maxAlarmSeverity,
            totalAlarmCount: totalAlarmCount,
          });
        })
        .map((item) => Object.assign(item, { maxAlarmCount: max }));
      //return item_value
    }
    // console.log("from render",item_value);

    // return items
    //creating the icon //
    const bubblePath =
      "M632 888H392c-4.4 0-8 3.6-8 8v32c0 " +
      "17.7 14.3 32 32 32h192c17.7 0 32-14.3 32-32v-3" +
      "2c0-4.4-3.6-8-8-8zM512 64c-181.1 0-328 146.9-3" +
      "28 328 0 121.4 66 227.4 164 284.1V792c0 17.7 1" +
      "4.3 32 32 32h264c17.7 0 32-14.3 32-32V676.1c98" +
      "-56.7 164-162.7 164-284.1 0-181.1-146.9-328-32" +
      "8-328z m127.9 549.8L604 634.6V752H420V634.6l-3" +
      "5.9-20.8C305.4 568.3 256 484.5 256 392c0-141.4" +
      " 114.6-256 256-256s256 114.6 256 256c0 92.5-49" +
      ".4 176.3-128.1 221.8z";

    const clearPath =
      "M793 242H366v-74c0-6.7-7.7-10.4-12.9" +
      "-6.3l-142 112c-4.1 3.2-4.1 9.4 0 12.6l142 112c" +
      "5.2 4.1 12.9 0.4 12.9-6.3v-74h415v470H175c-4.4" +
      " 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h618c35.3 0 64-" +
      "28.7 64-64V306c0-35.3-28.7-64-64-64z";

    const newArrowPath =
      "M11.961 16L4.78503 8.69193C4.60242 8.50596 4.5 8.25496 4.5 7.99341C4.5 7.44476 4.94161 7 5.48637 7H18.5136C18.7752 7 19.026 7.10462 19.211 7.29085C19.5963 7.67874 19.5963 8.30773 19.2112 8.69574L11.961 16Z";

    const arrowPath =
      "M765.7 486.8L314.9 134.7c-5.3-4.1" +
      "-12.9-0.4-12.9 6.3v77.3c0 4.9 2.3 9.6 6.1 12.6l36" +
      "0 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6" +
      ".7 7.7 10.4 12.9 6.3l450.8-352.1c16.4-12.8 16.4-3" +
      "7.6 0-50.4z";
    const Groups = Resource.get("Groups(#)");

    const getSvg = (path, iStyle = {}, style = {}) => {
      return (
        <i style={iStyle}>
          <svg
            viewBox="0 0 1024 1024"
            width="1em"
            height="1em"
            fill="currentColor"
            style={{ verticalAlign: "-.125em", ...style }}
          >
            <path d={path} />
          </svg>
        </i>
      );
    };

    const switcherIcon = (obj) => {
      if (!obj.isLeaf) {
        return getSvg(
          arrowPath,
          { cursor: "pointer", backgroundColor: "white" },
          { transform: `rotate(${obj.expanded ? 90 : 0}deg)` }
        );
      }
      // if (obj.isLeaf) {
      //   return getSvg(null);
      // }
      // return getSvg(
      //   arrowPath,
      //   { cursor: "pointer", backgroundColor: "white" },
      //   { transform: `rotate(${obj.expanded ? 90 : 0}deg)` }
      // );
    };

    const inputIcon = getSvg(bubblePath);
    const clearIcon = getSvg(clearPath);
    const removeIcon = getSvg(clearPath);

    const iconProps = {
      inputIcon,
      clearIcon,
      removeIcon,
      switcherIcon,
    };

    const iconPropsFunction = {
      inputIcon: () => inputIcon,
      clearIcon: () => clearIcon,
      removeIcon: () => removeIcon,
      switcherIcon,
    };
    //end of creating the icon

    const loop = (data) => {
      let displayName = "";

      return data.map((item) => {
        let mapperPicker = GroupAlarmMapper;
        mapperPicker =
          item.type === "COMPUTER_SYSTEM"
            ? DeviceAlarmMapper
            : GroupAlarmMapper;
        if (
          // (item.groups && item.groups.length) ||
          (item.children && item.children.length) ||
          // (item.computerSystems && item.computerSystems.length)
          (item.computerSystem && item.computerSystem.length)
        ) {
          if (item.maxAlarmSeverity) {
            //  console.log("from treenode",item.maxAlarmSeverity.label)
          }
          return (
            <TreeNode
              className="cus-label"
              id={item.id}
              titlename={item.name ? item.name : item.attributes.DisplayName[0]}
              type={item.type}
              count={item.count ? item.count : "groups(0)Member(1)"}
              os_name={
                item.attributes &&
                item.attributes.os_name &&
                item.attributes.os_name[0]
              }
              // os_name={
              //   item.attributes
              //     ? item.attributes.os_name
              //       ? item.attributes.os_name[0]
              //         ? item.attributes.os_name[0]
              //         : null
              //       : null
              //     : null
              // }
              typeName={
                item.attributes
                  ? item.attributes.typeName
                    ? item.attributes.typeName[0]
                      ? item.attributes.typeName[0]
                      : null
                    : null
                  : null
              }
              OSDescription={
                item.attributes
                  ? item.attributes.OSDescription
                    ? item.attributes.OSDescription[0]
                      ? item.attributes.OSDescription[0]
                      : null
                    : null
                  : null
              }
              origin={
                item.attributes
                  ? item.attributes.origin
                    ? item.attributes.origin[0]
                      ? item.attributes.origin[0]
                      : null
                    : null
                  : null
              }
              os_type={
                item.attributes
                  ? item.attributes.os_type
                    ? item.attributes.os_type[0]
                      ? item.attributes.os_type[0]
                      : null
                    : null
                  : null
              }
              dedicated={
                item.attributes
                  ? item.attributes.dedicated
                    ? item.attributes.dedicated[0]
                      ? item.attributes.dedicated[0]
                      : null
                    : null
                  : null
              }
              alias={
                item.attributes
                  ? item.attributes.DisplayAlias
                    ? item.attributes.DisplayAlias[0]
                      ? item.attributes.DisplayAlias[0]
                      : null
                    : null
                  : null
              }
              displayName={
                item.attributes
                  ? item.attributes.DisplayName
                    ? item.attributes.DisplayName[0]
                      ? item.attributes.DisplayName[0]
                      : null
                    : null
                  : null
              }
              maxAlarmSeverity={item.maxAlarmSeverity}
              key={item.id}
              title={
                mapperPicker[item.id] === 5 ? (
                  <span>
                    <SeverityIcon level={5} class="grid__icon" />
                    {item.name || item.attributes.DisplayName[0]}
                  </span>
                ) : mapperPicker[item.id] === 4 ? (
                  <span>
                    <SeverityIcon level={4} class="grid__icon" />
                    {item.name || item.attributes.DisplayName[0]}
                  </span>
                ) : mapperPicker[item.id] === 3 ? (
                  <span>
                    <SeverityIcon level={3} class="grid__icon" />
                    {item.name || item.attributes.DisplayName[0]}
                  </span>
                ) : mapperPicker[item.id] === 2 ? (
                  <span>
                    <SeverityIcon level={2} class="grid__icon" />
                    {item.name || item.attributes.DisplayName[0]}
                  </span>
                ) : mapperPicker[item.id] === 1 &&
                  mapperPicker[item.id] === -1 ? (
                  <span>
                    <SeverityIcon level={1} class="grid__icon" />
                    {item.name || item.attributes.DisplayName[0]}
                  </span>
                ) : (
                  <span>
                    <SeverityIcon level={0} class="grid__icon" />
                    {item.name || item.attributes.DisplayName[0]}
                  </span>
                )
              }
              // title={
              //   item.maxAlarmSeverity &&
              //   item.maxAlarmSeverity.label == "CRITICAL" ? (
              //     <span>
              //       <SeverityIcon level={5} class="grid__icon" />
              //       {item.name || item.attributes.DisplayName[0]}
              //     </span>
              //   ) : item.maxAlarmSeverity &&
              //     item.maxAlarmSeverity.label == "MAJOR" ? (
              //     <span>
              //       <SeverityIcon level={0} class="grid__icon" />
              //       {item.name || item.attributes.DisplayName[0]}
              //     </span>
              //   ) : item.maxAlarmSeverity &&
              //     item.maxAlarmSeverity.label == "NONE" ? (
              //     <span>
              //       <SeverityIcon level={0} class="grid__icon" />
              //       {item.name || item.attributes.DisplayName[0]}
              //     </span>
              //   ) : (
              //     <span>
              //       <SeverityIcon level={0} class="grid__icon" />
              //       {item.name || item.attributes.DisplayName[0]}
              //     </span>
              //   )
              // }
            >
              {/* {item.groups ? loop(item.groups) : null} */}
              {item.children ? loop(item.children) : null}
              {/* {item.computerSystems ? loop(item.computerSystems) : null} */}
              {item.computerSystem ? loop(item.computerSystem) : null}
            </TreeNode>
          );
        }

        return (
          <TreeNode
            className="cus-label"
            id={item.id}
            type={item.type}
            os_name={
              item.attributes
                ? item.attributes.os_name
                  ? item.attributes.os_name[0]
                    ? item.attributes.os_name[0]
                    : null
                  : null
                : null
            }
            typeName={
              item.attributes
                ? item.attributes.typeName
                  ? item.attributes.typeName[0]
                    ? item.attributes.typeName[0]
                    : null
                  : null
                : null
            }
            OSDescription={
              item.attributes
                ? item.attributes.OSDescription
                  ? item.attributes.OSDescription[0]
                    ? item.attributes.OSDescription[0]
                    : null
                  : null
                : null
            }
            origin={
              item.attributes
                ? item.attributes.origin
                  ? item.attributes.origin[0]
                    ? item.attributes.origin[0]
                    : null
                  : null
                : null
            }
            os_type={
              item.attributes
                ? item.attributes.os_type
                  ? item.attributes.os_type[0]
                    ? item.attributes.os_type[0]
                    : null
                  : null
                : null
            }
            dedicated={
              item.attributes
                ? item.attributes.dedicated
                  ? item.attributes.dedicated[0]
                    ? item.attributes.dedicated[0]
                    : null
                  : null
                : null
            }
            alias={
              item.attributes
                ? item.attributes.DisplayAlias
                  ? item.attributes.DisplayAlias[0]
                    ? item.attributes.DisplayAlias[0]
                    : null
                  : null
                : null
            }
            displayName={
              item.attributes
                ? item.attributes.DisplayName
                  ? item.attributes.DisplayName[0]
                    ? item.attributes.DisplayName[0]
                    : null
                  : null
                : null
            }
            key={item.id}
            titlename={
              item.name != undefined
                ? item.name
                : item.attributes
                ? item.attributes.DisplayName
                  ? item.attributes.DisplayName[0]
                    ? item.attributes.DisplayName[0]
                    : null
                  : null
                : null
            }
            //  maxAlarmSeverity={item.maxAlarmSeverity} Count={item.lastUpdate} lastupdate={item.lastUpdate}
            title={
              mapperPicker[item.id] === 5 ? (
                <span>
                  <SeverityIcon level={5} class="grid__icon" />
                  {item.name || item.attributes.DisplayName[0]}
                </span>
              ) : mapperPicker[item.id] === 4 ? (
                <span>
                  <SeverityIcon level={4} class="grid__icon" />
                  {item.name || item.attributes.DisplayName[0]}
                </span>
              ) : mapperPicker[item.id] === 3 ? (
                <span>
                  <SeverityIcon level={3} class="grid__icon" />
                  {item.name || item.attributes.DisplayName[0]}
                </span>
              ) : mapperPicker[item.id] === 2 ? (
                <span>
                  <SeverityIcon level={2} class="grid__icon" />
                  {item.name || item.attributes.DisplayName[0]}
                </span>
              ) : mapperPicker[item.id] === 1 &&
                mapperPicker[item.id] === -1 ? (
                <span>
                  <SeverityIcon level={1} class="grid__icon" />
                  {item.name || item.attributes.DisplayName[0]}
                </span>
              ) : (
                <span>
                  <SeverityIcon level={0} class="grid__icon" />
                  {item.name || item.attributes.DisplayName[0]}
                </span>
              )
            }
            // title={
            //   item.maxAlarmSeverity ? (
            //     item.maxAlarmSeverity.label ? (
            //       item.maxAlarmSeverity.label === "NONE" ? (
            //         item.name || item.attributes.DisplayName[0]
            //       ) : (
            //         item.name || item.attributes.DisplayName[0]
            //       )
            //     ) : (
            //       item.name || item.attributes.DisplayName[0]
            //     )
            //   ) : (
            //     <span>
            //       <SeverityIcon level={0} class="grid__icon" />
            //       {item.name || item.attributes.DisplayName[0]}
            //     </span>
            //   )
            // }
            //      title={item.maxAlarmSeverity == 'CRITICAL'?
            //    <span><p className=""> <img src={Back} alt="" />
            //  </p>{item.name || item.attributes.DisplayName[0]}</span>
            //    :item.maxAlarmSeverity == 'Major'?<span><SeverityIcon level={4} class="grid__icon" />{item.name || item.attributes.DisplayName[0]}</span>:  item.maxAlarmSeverity == 'Clear'?<span><SeverityIcon level={0} class="grid__icon" />{item.name || item.attributes.DisplayName[0]}</span>
            //    :item.name || item.attributes.DisplayName[0]

            //    }
          />
        );
        // return <TreeNode  key={item.id} title={item.maxAlarmSeverity == 'critical'?<span><SeverityIcon level={5} class="grid__icon" />{item.name}</span>:item.name}/>;
      });
    };

    let treeNodes;
    treeNodes = loop(item_value);
    // treeNodes=loop(this.state.treeRender ? this.state.treeViewData :this.props.data)
    //  if (this.treeNodes && this.notReRender) {
    //    treeNodes = this.treeNodes;
    //  } else {
    //    treeNodes = loop(this.props.groupData);
    //    this.treeNodes = treeNodes;
    //  }
    //end of GroupTreeView
    let classes = "titlebar__action-menu";
    let clickTarget = this.handleRequestChange;

    let disableMenu = false;
    const expandedView = this.state.expanded;

    const styles = {
      default_tab: {
        backgroundColor: "#FFFFFF",
        color: "#58676D",
        fontFamily: "roboto_condensed_bold,Arial,sans-serif",
        buttonStyle: { background: "blue", height: "10px" },
      },
      active_tab: {
        backgroundColor: "#FFFFFF",
        color: "#00AEEF",
        fontFamily: "roboto_condensed_bold,Arial,sans-serif",
        buttonStyle: { background: "blue", height: "10px" },
      },
      inkBar: {
        background: "#00AEEF",
        height: "5px",
        width: "80px",
        marginLeft: "59px",
      },
    };
    let {
      computerSystemsIsFetching,
      computerSystemsDidInvalidate,
      groupsIsFetching,
      groupsDidInvalidate,
      entityType,
      entities,
    } = this.props;
    let view;
    let dialog = (
      <AddDeviceToGroupDialogRedux
        open={this.state.showAddDevicesDialog}
        handleClose={this.handleAddDevicesClose}
        selectedId={this.props.selectedId}
        handleErrorDialogOpen={this.handleErrorDialogOpenAddDevices}
        entities={this.props.entities}
      />
    );

    if (
      (groupsIsFetching || computerSystemsIsFetching) &&
      !this.state.showAddDevicesDialog
    ) {
      view = (
        <div
          style={{
            height: "calc(100vh - 205px)",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress style={{ color: "#3272D9" }} />
        </div>
      );
    } else if (groupsDidInvalidate || computerSystemsDidInvalidate) {
      view = <Error style={{ height: "calc(100vh - 205px)" }} />;
    } else if (this.state.showAddSubgroupDialog) {
      view = (
        <AddSubgroupDialog
          open={true}
          handleClose={this.handleAddSubgroupClose}
          handleErrorDialogOpen={this.handleErrorDialogOpenAddGroups}
          {...this.props}
        />
      );
    } else if (
      this.state.editSubGroupOpen ||
      this.state.openDynamicGroupDialog
    ) {
      view = (
        <DynamicGroupDialog
          open={true}
          mode={this.state.openDynamicGroupDialog ? "ADD" : "EDIT"}
          info={this.state.dynamicGroupInfo}
          handleClose={this.handleAddCriteriaClose}
          {...this.props}
        />
      );
    } else if (this.state.showErrorForAddDevices) {
      view = (
        <ErrorDialog
          open={true}
          handleClose={this.handleErrorDialogCloseAddDevices}
          errorType="addDevices"
        />
      );
    } else if (this.state.showErrorForAddGroups) {
      view = (
        <ErrorDialog
          open={true}
          handleClose={this.handleErrorDialogCloseAddGroups}
          errorType="addGroup"
        />
      );
    } else if (entities && entities.length > 0) {
      view = (
        <div>
          <div style={{ width: "100%", height: "100%" }}>
            <div
              className={
                this.state.open
                  ? "treeView_parent_div_after_collapse"
                  : "treeView_parent_div_before_collapse"
              }
            >
              <span>
                <div style={{ padding: "0 20px" }}>
                  {/* <div style={{ display: 'flex' }}> */}
                  <div>
                    <div className="group">{Groups}</div>
                    <div className="rectangle">
                      <span>All Groups</span>
                      {/* <input placeholder="Search" value={this.state.inputValue} onChange={this.onChange} /> */}
                    </div>
                    <div style={{ marginRight: 20 }}>
                      <Tree
                        defaultExpandAll
                        defaultExpandedKeys={this.state.expandedKeys}
                        onCheck={this.onCheck}
                        checkedKeys={this.state.checkedKeys}
                        onSelect={this.onSelect}
                        selectable={true}
                        selectedKeys={this.state.selectedKeys}
                        showIcon={false}
                        {...iconProps}
                      >
                        {treeNodes}
                      </Tree>
                    </div>
                  </div>
                </div>
                {/* <TreeView onSelectLanguage={this.handleLanguage} {...this.props.GroupTreeListItems} />  */}
              </span>
            </div>

            {this.state.open ? (
              <div
                style={{
                  width: "79%",
                  float: "left",
                  backgroundColor: "White",
                }}
              >
                <div className="halfCircle">
                  {/* <p  className="border_radius"> place this after excatly knowling the border radius  */}
                  <span className="">
                    <img
                      onClick={this.clsoeTreeView}
                      style={{
                        padding: "5px 0px 5px 2px",
                      }}
                      src={Back}
                      alt=""
                    />
                  </span>
                </div>
                <div className="GroupName" style={{ float: "left" }}>
                  {this.state.treeRender ? (
                    <p>{this.state.displayName}</p>
                  ) : (
                    <p>Groups</p>
                  )}
                </div>
              </div>
            ) : (
              <div
                style={{
                  width: "99%",
                  float: "left",
                  backgroundColor: "White",
                }}
              >
                <div className="halfCircle">
                  {/* <p  className="border_radius"> place this after excatly knowling the border radius  */}
                  <span className="">
                    <img
                      onClick={this.clsoeTreeView}
                      style={{
                        padding: "5px 0px 5px 2px",
                      }}
                      src={Forward}
                      alt=""
                    />
                  </span>
                </div>
                <div className="GroupName" style={{ float: "left" }}>
                  {this.state.treeRender ? (
                    <p>{this.state.displayName}</p>
                  ) : (
                    <p>Groups</p>
                  )}
                </div>
              </div>
            )}
            <div
              className={
                this.state.open
                  ? "iconMenu_parent_div_before_collapse"
                  : "iconMenu_parent_div_after_collapse"
              }
            >
              {/* end of icon Menu */}
              <div>
                <Tabs
                  tabItemContainerStyle={{
                    background: "white",
                    width: "600px",
                  }}
                  inkBarStyle={styles.inkBar}
                >
                  <Tab
                    label="Details"
                    value="overview"
                    style={{ color: "00AEEF" }}
                  >
                    <Divider />
                    {/* <AlarmOverview alarmData={alarmDetail}/> */}
                  </Tab>

                  <Tab
                    label="Alarms"
                    value="overview"
                    style={{ color: "black" }}
                  >
                    <Divider />
                    {/* <AlarmOverview alarmData={alarmDetail}/> */}
                  </Tab>
                  <Tab
                    label="Maintainence"
                    value="overview"
                    style={{ color: "black" }}
                  >
                    <Divider />
                    {/* <AlarmOverview alarmData={alarmDetail}/> */}
                  </Tab>
                </Tabs>
              </div>
              {/* //end of the tabs */}
            </div>

            {this.state.open || this.state.treeRender ? (
              <div
                style={{
                  width: "79%",
                  height: "65vh",
                  border: "1px solid #8080803d",
                  float: "left",
                  backgroundColor: "white",
                  overflow: "scroll",
                }}
              >
                <div
                  className="Members"
                  style={{
                    position: "relative",
                    top: "1%",
                    left: "2%",
                    fontSize: "14px",
                    fontFamily: "Robot Regular",
                    margin: "5px",
                  }}
                >
                  {this.state.enableDeviceView == false ? (
                    <span>List of member group</span>
                  ) : (
                    ""
                  )}
                </div>

                <span
                  style={{ position: "relative", left: "2px", right: "17px" }}
                >
                  {this.state.enableDeviceView == false ? (
                    <Table
                      {...this.props}
                      onRef={(ref) => (this.child = ref)}
                    />
                  ) : (
                    // <Table  onRef={ref => (this.child = ref)} data = {this.state.gridData }  handleRowClickedChange={this.handleRowClickedChange}   /> :
                    <div className="box-centerside">
                      <p>
                        <span>
                          {" "}
                          <SeverityIcon level={0} class="grid__icon" />
                        </span>
                        {this.state.gridArray
                          ? this.state.gridArray[0]
                            ? this.state.gridArray[0].displayName
                              ? this.state.gridArray[0].displayName
                              : "Test"
                            : "Testing"
                          : this.state.gridData[0].displayName}
                      </p>

                      <p>
                        Name:
                        <span className="spanStyle">
                          {" "}
                          {this.state.gridData[0].displayName}
                        </span>
                      </p>
                      <p>
                        Alia{" "}
                        <span className="spanStyle">
                          {this.state.gridData[0].alias}
                        </span>
                      </p>
                      <p>
                        Dedicated:
                        <span className="spanStyle">
                          {this.state.gridData[0].dedicated}
                        </span>
                      </p>
                      <p>
                        OS Type:
                        <span style={{ marginLeft: "79px" }}>
                          {this.state.gridData[0].os_type}
                        </span>
                      </p>
                      <p>
                        OS Name:
                        <span style={{ marginLeft: "75px" }}>
                          {this.state.gridData[0].os_name}
                        </span>
                      </p>
                      <p>
                        OS Description :
                        <span style={{ marginLeft: "43px" }}>
                          {this.state.gridData[0].OSDescription}
                        </span>
                      </p>
                      <p>
                        Orgin:{" "}
                        <span style={{ marginLeft: "100px" }}>
                          {" "}
                          {this.state.gridData[0].origin}
                        </span>
                      </p>
                      <p>
                        ComputerName:
                        <span style={{ marginLeft: "43px" }}>
                          {this.state.gridData[0].typeName}
                        </span>
                      </p>
                      <p>
                        VM Guest Name:
                        <span style={{ marginLeft: "39px" }}>
                          {this.state.gridData[0].displayName}
                        </span>
                      </p>
                      <p>
                        Virtualization:<span className="spanStyle">VMWare</span>
                      </p>
                    </div>
                  )}
                </span>
              </div>
            ) : (
              <div
                style={{
                  width: "99%",
                  border: "1px solid #8080803d",
                  backgroundColor: "white",
                  height: "64vh",
                  float: "left",
                  backgroundColor: "White",
                  overflow: "scroll",
                }}
              >
                <div
                  className="Members"
                  style={{
                    position: "relative",
                    top: "1%",
                    left: "2%",
                    fontSize: "14px",
                    fontFamily: "Robot Regular",
                    margin: "5px",
                  }}
                >
                  {this.state.enableDeviceView == false ? (
                    <span>List of member group</span>
                  ) : (
                    ""
                  )}
                </div>
                <div>
                  <span
                    style={{ position: "relative", left: "2px", right: "17px" }}
                  >
                    {this.state.enableDeviceView === false ? (
                      <Table
                        {...this.props}
                        onRef={(ref) => (this.child = ref)}
                      />
                    ) : (
                      <div className="box-centerside">
                        <p>
                          {this.state.gridArray
                            ? this.state.gridArray[0]
                              ? this.state.gridArray[0].displayName
                                ? this.state.gridArray[0].displayName
                                : "Test"
                              : "Testing"
                            : this.state.gridData[0].displayName}
                        </p>
                        <SeverityIcon level={0} class="grid__icon" />
                        <p>
                          Name:
                          <span className="spanStyle">
                            {" "}
                            {this.state.gridData[0].displayName}
                          </span>
                        </p>
                        <p>
                          Alia{" "}
                          <span className="spanStyle">
                            {this.state.gridData[0].alias}
                          </span>
                        </p>
                        <p>
                          Dedicated:
                          <span className="spanStyle">
                            {this.state.gridData[0].dedicated}
                          </span>
                        </p>
                        <p>
                          OS Type:
                          <span style={{ marginLeft: "79px" }}>
                            {this.state.gridData[0].os_type}
                          </span>
                        </p>
                        <p>
                          OS Name:
                          <span style={{ marginLeft: "75px" }}>
                            {this.state.gridData[0].os_name}
                          </span>
                        </p>
                        <p>
                          OS Description :
                          <span style={{ marginLeft: "43px" }}>
                            {this.state.gridData[0].OSDescription}
                          </span>
                        </p>
                        <p>
                          Orgin:{" "}
                          <span style={{ marginLeft: "100px" }}>
                            {" "}
                            {this.state.gridData[0].origin}
                          </span>
                        </p>
                        <p>
                          ComputerName:
                          <span style={{ marginLeft: "43px" }}>
                            {this.state.gridData[0].typeName}
                          </span>
                        </p>
                        <p>
                          VM Guest Name:
                          <span style={{ marginLeft: "39px" }}>
                            {this.state.gridData[0].displayName}
                          </span>
                        </p>
                        <p>
                          Virtualization:
                          <span className="spanStyle">VMWare</span>
                        </p>
                      </div>
                    )}
                  </span>
                </div>
              </div>
            )}
          </div>
          {/* {(this.props.entityType === 'CONTAINER' && this.props.acl.acl === AclTypes.ADMINISTRATOR)? (
                <FloatingActionButton
                  onClick={this.handleAddSubgroup}
                  style={{
                    position: 'fixed',
                    bottom: '1rem',
                    right: '1rem',
                    zIndex: 2,
                    marginBottom: '80px'
                  }}>
                  <ContentAdd />
                </FloatingActionButton>
              ):''}  */}
        </div>
      );
    } else {
      let noElsMsg = "No devices have been added";
      let clickHandler = this.handleAddDevices;
      let showPlus = false;
      if (entityType === "STATIC") {
        // No devices have been added
        noElsMsg = Resource.get("No devices have been added");
        clickHandler = this.handleAddDevices;
      } else if (entityType === "DYNAMIC") {
        if (this.props.criteria !== null) {
          clickHandler = this.handleAddCriteria;
        } else {
          showPlus = false;
        }
      } else {
        //No subgroups have been added
        noElsMsg = Resource.get("No subgroups have been added");
        clickHandler = this.handleAddSubgroup;
      }
      let plusIcon = (
        <FloatingActionButton
          style={{
            position: "relative",
            top: "46px",
            left: "12px",
            backgroundColor: "rgb(0, 174, 239)",
            color: "white",
          }}
          onClick={clickHandler}
        >
          <ContentAdd />
        </FloatingActionButton>
      );
      view = (
        <div style={{ height: "80vh" }}>
          <div className="no-profile-message">
            <Information
              //No profiles in  this group
              title={Resource.get("No profiles in this group")}
              className="no-profiles__infomation--icon"
            />
            <p className="no-profiles__infomation--message"> {noElsMsg} </p>
            {showPlus ? plusIcon : ""}
            {dialog}
          </div>
        </div>
      );
      if (this.props.inventorySearch) {
        //No devices found
        let message = Resource.get("No devices found");
        view = (
          <div className="no-profile-message">
            <Information
              //No profiles in  this group
              title={Resource.get("No profiles in this group")}
              className="no-profiles__infomation--icon"
            />
            <p className="no-profiles__infomation--message"> {message} </p>
          </div>
        );
      }
      if (entityType === "DYNAMIC" && this.props.criteria !== null) {
        //No devices have been added yet.
        let message = Resource.get("No devices have been added yet.");
        view = (
          <p className="no-devices-have-been-message">
            <img
              style={{
                marginRight: "8px",
                verticalAlign: "bottom",
                height: "20px",
                width: "20px",
              }}
              src={infoIcon}
              alt=""
            />
            {message}
            <span className="no-devices-have-been-edit" onClick={clickHandler}>
              {" "}
              Edit Group
            </span>
          </p>
        );
      }
    }
    return view;
  }
}

export default TreeViewManager;
