// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Cards
  content["Cards"] =
  "Karten";

  //List
  content["List"] =
  "Liste";

  //Alarms
  content["Alarms"] =
  "Alarme";

  //Dashboards
  content["Dashboards"] =
  "Dashboards";

  //Metrics
  content["Metrics"] =
  "Kennzahlen";

  //Monitoring Config
  content["Monitoring Config"] =
  "Überwachungskonfiguration";

  // END OF LOCALIZATION

export default content;
