import * as React from 'react';
import axios from "axios";
import { useEffect } from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableRow,
  TableCell,Box,
  TextField,Tooltip,  
  TableHead,TableSortLabel,TableContainer,IconButton
} from '@mineral/core';
import{
 HelpIcon
} from "../../ui-components/uim-components"
import {CircularProgress} from '@mineral/core';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker'
import { KeyboardDatePicker } from '@material-ui/pickers'

import { styled } from "@mui/material/styles";
import { tableCellClasses } from '@mui/material/TableCell';

import { visuallyHidden } from '@mui/utils';
import './productUsageReporting.less'

import { setBreadCrumbs } from "./../../breadcrumbs/actions";
import Resource from "../Resource";
import connect from "./../../../utils/connect";
import NoTableDataComponent from './../../uimreacttable/NoTableDataComponent'
import moment from "moment";
import {getProductUsageMetricsData} from './../../../api/productUsageMetrics/actions'
import { MineralYearMonthPicker } from '../../common/datepickers/MineralYearMonthPicker'
import { Typography } from '@mineral/core';


const SETTINGS = Resource.get("Settings") // Settings
const CARDTITLE = Resource.get("Product Usage") // Product Usage
const PAGETITLE = Resource.get("Licensed Metric - #DXIM Devices") // Licensed Metric - #DXIM Devices

const PRODUCTVERSION = Resource.get("Product Version") //Product Version 
const PERIODLABEL = Resource.get("Period") // Period
const TOTAUSAGELABEL = Resource.get("Total Usage") // Total Usage
const BILLINGSUMMARYLABEL = Resource.get("Billing Summary") // Billing Summary
const TOTALLABEL = Resource.get("Total") // Total
const MONITORINGAREALABEL = Resource.get("Monitoring Area") // Monitoring Area 
const USAGERATIOLABEL = Resource.get("Usage (Ratio)") // Usage (Ratio)
const DXIMDEVICESLABEL = Resource.get("DXIM Devices") // DXIM Devices
const NODATATEXT=Resource.get("No Data Found") //No Data Found

const SELECTEDPERIOD=Resource.get('Selected Period');//Selected Period

const StyledTableCell = styled(TableCell)(({ theme }) => ({
 
  [`&.${tableCellClasses.head}`]: {
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '32px',    
    color: '#333840',
    borderRight:'1px dotted #707A8A',
    '&:last-child':{
      borderRight:'none',

    }
    
   
  },
  
  [`&.${tableCellClasses.body}`]: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '18px',    
    color: '#333840',
    height:'40px',
    paddingTop:'0px',
    paddingBottom:'0px'
   
  },
}));
const StyledTableHead = styled(TableHead)(({ theme }) => ({
  height:'48px',
  
  

  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
  
}));


const headCells = [
  {
    id: 'monitoringArea',
    numeric: false,
    disablePadding: true,
    label: MONITORINGAREALABEL,
  },
  {
    id: 'usageRatio',
    numeric: false,
    disablePadding: true,
    label: USAGERATIOLABEL,
  },
  {
    id: 'licensedMetricNoOfDevices',
    numeric: true,
    disablePadding: true,
    label: DXIMDEVICESLABEL,
  }
];

const headCellsVersiontable = [
  {
    id: 'productVersion',
    numeric: false,
    disablePadding: true,
    label: PRODUCTVERSION,
  },
  {
    id: 'period',
    numeric: false,
    disablePadding: true,
    label: PERIODLABEL,
  },
  {
    id: 'totalUsage',
    numeric: true,
    disablePadding: true,
    label: TOTAUSAGELABEL,
  }
];

function EnhancedTableHead(props) {
  

  return (
    <StyledTableHead>
      <TableRow>
        {props.headCells.map((headCell, index) => (
          <StyledTableCell
            key={headCell.id}
           
            padding={headCell.disablePadding ? 'none' : 'normal'}
            style={{...props.style,paddingLeft: "16px" }}
             
          >
           {headCell.label}
            
          </StyledTableCell>
        ))}
      </TableRow>
    </StyledTableHead>
  );
}



function ProductUsageReporting(props) {  
  const [rows, setRows] = React.useState([]);
  const [productVersion,setProductVersion] = React.useState('Unknown');
  const [totalCount, setTotalCount] = React.useState(0);
  const [deviceCnt,setDeviceCnt] = React.useState(0);
  // const [openPicker, setOpenPicker] = React.useState(false);
  const [productPeriod, setProductPeriod] = React.useState(new Date(moment().subtract(1, "month")));
  const productUsageData = useSelector((state) =>state.productUsageMetrics);
  

  // useEffect(() => {
  //   props.getProductUsageMetricsData(
  //     {month:moment().subtract(1, 'month').format('MM'),
  //     year:moment().subtract(1, 'month').format('YYYY')
  //   });
     
  // },[])
  // const [open, setOpen] = React.useState(false);
  // const onOpen = () => setOpen(true);
  // const onClose = () => setOpen(false);

  // useEffect(() => {
  //   const handleEsc = (event) => {
    
  //      if (event.keyCode === 27) {
  //       onClose();
  //     }
  //   };
  //   window.addEventListener('keydown', handleEsc);

  //   return () => {
  //     window.removeEventListener('keydown', handleEsc);
  //   };
  // }, []);
  useEffect(() => {
    props.setBreadCrumbs([
      {
        //Settings
        name: SETTINGS,
        link: "/settings",
      },
      {
        // Product Usage
        name: CARDTITLE,
        link: "/settings/usagemetrics",
      },
    ]);
     
  })
  useEffect(()=> {
    if(typeof productUsageData!==undefined )
    {
      setRows(productUsageData?.productUsageMetricsResponse?.monthlyUmDeviceDataList)
      setProductVersion(productUsageData?.productUsageMetricsResponse?.productVersion)
      setTotalCount(productUsageData?.productUsageMetricsResponse?.allPacksTotalCount)
     
    setDeviceCnt(productUsageData?.productUsageMetricsResponse?.allPacksTotalCount);
    }

  },[productUsageData])

  
  useEffect(() => {
    //console.log("productPeriod: " + moment(productPeriod).format('MMMM, YYYY'))
    //if (!openPicker && !open) {
      console.log(moment(productPeriod).format('MM'))
      console.log(moment(productPeriod).format('YYYY'))

      props.getProductUsageMetricsData(
        {month:moment(productPeriod).format('MM'),
        year:moment(productPeriod).format('YYYY')});
    //}
  }, [productPeriod])

  const handelDateChange =(newValue) => {
    // if (openPicker && !open)
    setProductPeriod(newValue)
  }

  // const handelClosePicker = () => {
  //   if(!open)
  //     setOpenPicker(false)
  // }

    
if(typeof productUsageData===undefined || productUsageData?.isFetching===true)
return( <div className="usage-metrics_container"><div className="usage-metrics__spinner" style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
<CircularProgress style={{color: '#3272D9'}} />
</div></div>)
else
  return (
    <div className="usage-metrics_container">
   
            <div className="usage-metrics__header">
            <span style={{display: "inline-flex"}}><Typography variant='h2' className="usage-metrics__header-title" id="productMetricsTitle">{PAGETITLE}  </Typography>
            <Tooltip title="Help">
                <IconButton autoFocus
                style={{marginLeft: "5px",top: '-5px'}}
                role="link" id="first-content-element"  aria-label="Help"
                tabIndex={0} aria-roledescription="Click to open documentation"
                onClick={() =>
                  window.open( 
                    "https://techdocs.broadcom.com/bin/gethidpage?hid=HID_ESD_PLA_TELEMETRY&space=UIM204&language=&format=rendered",
                    "_blank"
                    )
                  }
                  ><HelpIcon role='figure' /></IconButton></Tooltip></span>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                {/* <DatePicker
                  PopperProps={{className:'ProductUsageDataPickerDropdown'}}
                  OpenPickerButtonProps={{disableRipple:true}}
                  open={openPicker}
                  onOpen={() => setOpenPicker(true)}
                  onClose={handelClosePicker}
                  views={['year', 'month']}
                  inputFormat="MMMM, yyyy"
                  //value={productPeriod}
                  onChange={handelDateChange}
                  style={{border: '1px solid #AFBACC',
                  boxSizing: 'border-box',
                 }}
                  renderInput={(props) => (
                    <Tooltip arrow 
                    open={open}
                    onOpen={onOpen}
                    onClose={onClose}
                    title={`${SELECTEDPERIOD} - ${moment(productPeriod).format('MMMM, YYYY')}`} placement="bottom">
                      <TextField className='productUsageSelectDate'
                        variant="outlined" 
                        {...props}  
                        inputProps={{
                          "aria-readonly":true,
                          'aria-label': `${SELECTEDPERIOD}- ${moment(productPeriod).format('MMMM, YYYY')}`,
                        'title':`${SELECTEDPERIOD}`,
                        'name': `${SELECTEDPERIOD}` }}
                        value={moment(productPeriod).format('MMMM, YYYY')}
                        label={null} 
                      />
                    </Tooltip>
                  )}
                  
                />   */}
               
              <MineralYearMonthPicker
              value={moment(productPeriod).format('MMMM, YYYY')}
              onValueChange={(val)=> handelDateChange(val)}
             
              variant="inline"
              // minDateTime={this.state.presentDate}
              
              label={null}
              ampm={false}
            />
                </LocalizationProvider>
                    </div>
                    <div className="usage-metrics__main">
                <div className="usage-metrics__table">
          <Table sx={{ minWidth: 750 }} aria-labelledby="productMetricsTitle" size={'medium'} >
          <EnhancedTableHead headCells={headCellsVersiontable}
             style={{ borderBottom: '1px solid #707A8A',
             borderTop: '1px solid #707A8A'}}
             />
            
            <TableBody>
              <TableRow>
                <StyledTableCell align="left"  >{productVersion}</StyledTableCell>
                <StyledTableCell align="left" >{moment(productPeriod).format('MMMM, YYYY')}</StyledTableCell>
                <StyledTableCell align="left" >{Number(totalCount).toFixed(2)}</StyledTableCell>
              </TableRow>
            </TableBody>
          </Table>
          </div>
          </div>
          <div className="usage-metrics__header" style={{marginTop:'16px',borderTop:'none'}}>
                <h2 className="usage-metrics__header-title" id="billingTitle">{BILLINGSUMMARYLABEL} - {moment(productPeriod).format('MMMM, YYYY')}</h2>
</div>
 {rows?.length===0?
            <div style={{marginTop:'50px'}}>
           <NoTableDataComponent noDataText={NODATATEXT}/>
           </div>:
          
          
                <div className="usage-metrics__table_billing">
 <TableContainer className="usage-metrics__table_billing_tablecontainer" tabIndex={0}>
          <Table sx={{ minWidth: 750 }} aria-labelledby="billingTitle" size={'medium'} >
            <EnhancedTableHead headCells={headCells} style={{ borderBottom: '1px solid #707A8A',
    borderTop: '1px solid #707A8A'}}
            />
          
            <TableBody>
              {rows?.map((row, index) => {
                return (
                  <TableRow>
                    <StyledTableCell align="left" style={{ width:"60%"}}  >{row.packName}</StyledTableCell>
                    <StyledTableCell align="left" style={{width:"20%"}}>{Number(row.totalCount).toFixed(2)} ({row.usageRatio})</StyledTableCell>
                    <StyledTableCell align="left" style={{width:"20%"}} >{Number(row.dximCount).toFixed(2)}</StyledTableCell>
                  </TableRow>
                );
              })}
              <TableRow>
                <StyledTableCell style={{fontWeight:700}} colSpan={2} >{TOTALLABEL}</StyledTableCell>
                <StyledTableCell  style={{fontWeight:700}}>{Number(deviceCnt).toFixed(2)}</StyledTableCell>
              </TableRow>

             
            </TableBody>
           
          </Table>
          </TableContainer>
       </div>}
       
       </div>
  );
}

const mapStateToProps = (state) => {
  return {
    productUsageMetrics: state.productUsageMetrics,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setBreadCrumbs: (breadCrumbs) => dispatch(setBreadCrumbs(breadCrumbs)),
    getProductUsageMetricsData:(params)=>dispatch(getProductUsageMetricsData(params))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductUsageReporting)