// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Thank you message
  content["Thank you, your sales representative<br />will contact you soon"] =
  "Gracias, el representante de ventas<br />se pondrá en contacto con usted pronto.";

  //CLOSE
  content["CLOSE"] =
  "CERRAR";

  //Trial Exired
  content["Trial Expired"] =
  "Versión de prueba caducada";

  // trial expired message
  content["Unfortunately, your trial period expired."] =
  "Lamentamos informarle de que el período de prueba ha caducado.";

  //System update available
  content["System update available"] =
  "Actualización del sistema disponible";

  //A new version of the robot software is available.
  content["A new version of the robot software is available."] =
  "Hay una nueva versión del software del robot disponible.";

  //Deployment takes about 1 minute, during which time no metrics are collected and no alarms are created.
  content["Deployment takes about 1 minute, during which time no metrics are collected and no alarms are created."] =
  "La implementación tarda aproximadamente 1 minuto. Durante este período de tiempo, no se recopilará ninguna métrica ni se crearán alarmas.";

  //LATER
  content["LATER"] =
  "MÁS ADELANTE";

  //DEPLOY
  content["DEPLOY"] =
  "IMPLEMENTAR";

  // END OF LOCALIZATION

export default content;
