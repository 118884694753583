import React, { Component } from 'react';
import { PlusIcon as Plus, SettingsIcon } from '../ui-components/uim-components'
import {Card, CardActions, CardMedia, Button as FlatButton} from "@mineral/core"
import './setupWizard.less'
import './setupWizardCard.less'
import Resource from './Resource'



export default class SetupWizardCard extends Component {

    configDisabled(probe) {
        return (probe.probeType === "local" && probe.appDiscoveryEnabled) ? (!probe.profileCount || !probe.groupId) : !probe.profileCount;
    }

    handleConfigClick(probe) {
        this.props.onhandleConfigClick(probe);
    }
    handleShoWalkthrough(probe) {
        this.props.onShowWalkThrough(probe);
    }
    getName(probeName){
        if(probeName.endsWith("(RESTMon)")){
            probeName = probeName.replace(/^setup[ ]?/i,"");
            probeName = probeName.replace(/[ ]?\(restmon\)$/i,"");
            return (
            		<div className='restmon-probeName' title={probeName} style={{width:'72%',maxWidth:'none',minWidth:'none'}}>
                        {probeName}</div>
            );
        }
    }
    render() { 
        var configDisabled = this.configDisabled(this.props.probe);
        var probeName = this.props.probe.name;
        // dashboard restmon 
        const RESTMON = Resource.get("dashboard restmon");
        // Added to replace "ad_server" name to "activedirectory_server" - adBlocker blocks the image if image name contains "ad".
        if (!probeName.endsWith("(RESTMon)") && /ad|ads/i.test(probeName)) probeName = probeName.replace("ad", "activedirectory");
        require("./../../assets/images/dashboard_restmon.png");
        return (
            <Card className='card setup-wizard-card'>
                <CardMedia style={{position: "relative"}}>                    
                    <img className="setup-wizard__card_img" src={probeName.endsWith("(RESTMon)")?require("./../../assets/images/dashboard_restmon.png").default:require("./../../assets/images/img_" + probeName + "-colorBkg.png").default}
                    alt={probeName.endsWith("(RESTMon)")?RESTMON:probeName}
                    />
                   {this.getName(probeName)}
                   
                </CardMedia>
                <CardActions className="card-actions" style={{justifyContent:'center'}}>
                    <FlatButton
                       variant= "text"
                        color = "primary"
                        className='setup-wizard__add-button'
                        	//ADD
                        children={Resource.get("ADD")}
                        onClick={() => this.handleShoWalkthrough(this.props.probe)}
                        startIcon={<Plus />}
                        classes={{label:'setup-card-label-style'}}
                        // Add Monitoring
                        aria-label={Resource.get('Add Monitoring for ') +this.props.probe?.name}
                    />
                    <FlatButton aria-label={"Configure "+this.props.probe?.name}
                        variant= "text"
                        color = "primary" style={{marginBottom:'0px'}}
                        disabled={configDisabled}
                        className={configDisabled ? 'setup-wizard__edit--button disabled' : 'setup-wizard__edit--button'}
                        startIcon={<SettingsIcon className={configDisabled ? 'setup-wizard__edit--icon disabled' : 'setup-wizard__edit--icon'} />}
                        onClick={(probe) => { this.handleConfigClick(this.props.probe) }
                        }
                        //CONFIG
                        children={<div className="setup-wizard__card-count-profiles">{Resource.get('CONFIG')}</div>}
                        classes={{label:'setup-card-label-style'}}
                    />
                    <FlatButton 
                        className='setup-wizard__device-count'
                        variant= "text"
                        disabled={true}
                        children={`${this.props.probe.label}: ${this.props.probe.profileCount}`}
                    />
                </CardActions>
            </Card>
        );
    }
}


