// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //For more information, click
  content["For more information, click "] =
  "Para obter mais informações, clique em ";

  // console.log(this.props);
  content["Default system monitoring for CPU, disk & memory will be applied"] =
  "O monitoramento padrão do sistema para a CPU, o disco e a memória será aplicado";

  //Automatically monitor
  content["Automatically monitor "] =
  "Monitorar automaticamente ";

  // devices
  content[" devices"] =
  " dispositivos";

  //HELP
  content["HELP"] =
  "AJUDA";

  // END OF LOCALIZATION

export default content;
