import React from 'react'
import {SvgIcon as SvgIconMui} from '@mineral/core';

const SvgIcon = (props) => <SvgIconMui {...props} />

SvgIcon.defaultProps = {
  className: 'svgicon',
}

export default SvgIcon
