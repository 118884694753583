import React from "react";
import connect from "./../../utils/connect";
import { Route } from "react-router-dom";
import axios from "axios";
import get from "lodash/get";
import isEqual from "lodash/isEqual";
import isNaN from "lodash/isNaN";
import last from "lodash/last";
import unionWith from "lodash/unionWith";
import {
  addBreadCrumb,
  popBreadCrumb,
  setBreadCrumbs,
} from "./../breadcrumbs/actions";
import config from "./../../api/config";
import Resource from "./Resource";
import { Snackbar } from "@mineral/core";

class Breadcumbs extends React.Component {
  state = {
    breadcrumbs: [],
    openSnackBar: false,
    failMsg: "",
  };
  handleCloseSnackBar = () => {
    this.setState({ openSnackBar: false, failMsg: "" });
  };
  _getBreadcrumbs = (props) => {
    const context = props.match.params.context;
    const groupUrls = process.env.NODE_ENV == "development" ?
    ["http://10.252.25.208:8080/castlemock/mock/rest/project/ViXRbO/application/xb77Al/groups/id"] :
    props.groupIds.map(
      (id) => `${config.basename}/api/v2/groups/${id}`
    );
    const csUrls = 
    process.env.NODE_ENV == "development" ?
    ["http://10.252.25.208:8080/castlemock/mock/rest/project/ViXRbO/application/xb77Al/computers"] :    
    props.csIds.map(
      (id) => `${config.basename}/api/v1/computer-systems/${id}`
    );

    const urls = [...groupUrls, ...csUrls];

    console.log("from breadcrum route");
    return axios
      .all(urls.map((url) => axios.get(url)))
      .then((results) => {
        let link;
        const groups = results
          .slice(0, groupUrls.length)
          .map((response) => response.data);
        const computerSystems = results
          .slice(groupUrls.length)
          .map((response) => response.data);
        const groupBreadcrumbs = groups.map((entity, index) => {
          link =
            index === 0
              ? `/${props.match.params[0]}/${entity.id}`
              : `${link}/${entity.id}`;
          return {
            //Groups
            name:
              parseInt(entity.id, 10) === 0
                ? Resource.get("Groups")
                : entity.name,
            link: link,
          };
        });
        const computerSystemBreadcrumbs = computerSystems.map(
          (entity, index) => {
            if (!link && index === 0) {
              link = `/${props.match.params[1]}/${entity.id}`;
            } else if (link && index === 0) {
              link = `${link}/${props.match.params[1]}/${entity.id}`;
            } else {
              link = `${link}/${entity.id}`;
            }
            return {
              name: entity.name,
              link: link,
            };
          }
        );
        let breadcrumbs = [...groupBreadcrumbs, ...computerSystemBreadcrumbs];
        // add `/list` to the end of breadcrumbs if we're on the list view
        // otherwise `context` to the end of the last breadcrumb
        breadcrumbs = breadcrumbs.map((breadcrumb, index) => {
          const lastBreadcrumb = index === breadcrumbs.length - 1;
          if (context === "list") {
            breadcrumb = Object.assign({}, breadcrumb, {
              link: `${breadcrumb.link}`,
            });
          }else if (context === "treeview") {
            breadcrumb = Object.assign({}, breadcrumb, {
              link: `${breadcrumb.link}/treeview`,
            });
          } else if (context && lastBreadcrumb) {
            breadcrumb = Object.assign({}, breadcrumb, {
              link: `${breadcrumb.link}/${context}`,
            });
          }
          return breadcrumb;
        });
        return breadcrumbs;
      })
      .catch((error) => {
        this.setState({
          openSnackBar: true,
          failMsg: `Failed to get ${urls.join("\n")}`,
        });
        console.error(`Failed to get ${urls.join("\n")}:`, error);
      });
  };
  _setBreadcrumbs = (props) => {
    const hasGroups = !!props.match.params[0];
    const hasComputerSystems = !!props.match.params[1];

    this._getBreadcrumbs(props)
      .then((breadcrumbs) => {
        let method = "setBreadCrumbs";
        if (isEqual(this.state.breadcrumbs, breadcrumbs)) {
          return this;
        }
        // If this is a top-level `/computer-system` route we're just going to add
        // the computer system(s) breadcrumb so we don't wipeout wanted breadcrumbs.
        // This is a common case when linking to `/computer-systems` from a different view.
        // e.g. `/inventory`
        if (!hasGroups && hasComputerSystems) {
          // When the context menu switches we want to update the breadcrumb link
          // but we don't want to add another breadcrumb so we pop off the last
          // breadcrumb and add a the new one.
          let stateBreadcrumbs = Array.isArray(this.state.breadcrumbs)
            ? this.state.breadcrumbs.slice()
            : [this.state.breadcrumbs];
          const regex = new RegExp(
            "/(list|alarms|cabi|metrics|configuration|interfaces|maintenance)+$",
            "g"
          );
          let lastStateBreadcrumbLink = get(last(stateBreadcrumbs), "link", "");
          let lastBreadcrumbLink = get(last(breadcrumbs), "link", "");
          if (
            lastStateBreadcrumbLink.replace(regex, "") ===
            lastBreadcrumbLink.replace(regex, "")
          ) {
            stateBreadcrumbs.pop();
            this.props.popBreadCrumb();
          } else if (hasComputerSystems) {
            this.props.setBreadCrumbs([
              {
                //Inventory
                name: Resource.get("Inventory"),
                link: "/inventory",
              },
            ]);
          }
          breadcrumbs = stateBreadcrumbs.concat(breadcrumbs);
          // remove duplicates that occur from `concat`
          breadcrumbs = unionWith(breadcrumbs, isEqual);
          method = "addBreadCrumb";
        }
        this.props[method](breadcrumbs);
        this.setState({ breadcrumbs });
      })
      .catch((error) => {
        this.setState({
          breadcrumbs: {
            name: "Error",
            link: "/overview",
          },
        });
      });
  };
  componentWillMount() {
    this._setBreadcrumbs(this.props);
  }
  componentWillReceiveProps(nextProps) {
    this._setBreadcrumbs(nextProps);
  }
  render() {
    return (
      <Snackbar
        open={this.state.openSnackBar}
        message={this.state.failMsg}
        autoHideDuration={4000}
        onClose={this.handleCloseSnackBar}
      />
    );
  }
}

const BreadcumbRoutes = (props) => {
  return (
    <Route
      path={`${props.entityMatchRegex}/:context?`}
      render={({ match }) => {
        const getIds = (key) =>
          (match.params[key] ? match.params[key].split("/") : [])
            .map((id) => parseInt(id, 10))
            .filter((id) => !isNaN(id));
        const groupIds = getIds("groupIds");
        const csIds = getIds("csIds");
        return (
          <Breadcumbs
            groupIds={groupIds}
            csIds={csIds}
            match={match}
            addBreadCrumb={props.addBreadCrumb}
            popBreadCrumb={props.popBreadCrumb}
            setBreadCrumbs={props.setBreadCrumbs}
          />
        );
      }}
    />
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addBreadCrumb: (item) => dispatch(addBreadCrumb(item)),
    popBreadCrumb: () => dispatch(popBreadCrumb()),
    setBreadCrumbs: (items) => dispatch(setBreadCrumbs(items)),
  };
};
export default connect(null, mapDispatchToProps)(BreadcumbRoutes);
