import connect from "./../../../../utils/connect";
import ToggleIcons from "./ToggleIcons";
import { switchTreeViewNav, sendSavedColumns } from "./../../../../api/groups/actions";
import { withRouter } from "react-router-dom";
function mapStateToProps(state) {
  return {
    selectedEntity: state.entity,
    selectedId: state.entity.id,
    name: state.entity.name,
    entityType: state.entity.type,    
    entities: state.entities,
    saas: state.saas,
    acl: state.acl,
    doiurl: state.user.doiurl,
    featureFlags: state.configuration.items,
    breadcrumbs: state.breadCrumbs,
    treeNode: state.tree_view.treeNode,
    currentView: state.groups.currentTreeViewNav,
    disabledTreeViewNavs: state.groups.disabledTreeViewNavs,
    fromListViewRow: state.filteredInventoryData,    
  };
}
function mapDispatchToProps(dispatch) {
  return {
    switchTreeViewNav: (currentNav) => dispatch(switchTreeViewNav(currentNav)),
    sendSavedColumns: (columns) => dispatch(sendSavedColumns(columns))
    
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ToggleIcons));
