import React from 'react'
import {CardContent, Button as FlatButton, } from '@mineral/core'
import {HelpIcon} from '../components/ui-components/uim-components'
import theme from '../theme'
const labelStyle={
  paddingRight: 10,
  color: theme.palette.secondaryTextColor,
}
const HelpButton = props => {
	return(
			<div style={props.style}>
            <CardContent style={{ padding: 0 }}>
              <div className="help-section">
                {' '}
                For more information, click &nbsp;
                <FlatButton
                  onClick={() =>
                    window.open(
                      props.link,
                      '_blank',
                    )}
                  children="HELP"
                  endIcon={
                    <HelpIcon
                      className="help-icon"
                      title="Help"
                    />
                  }
                  style={{
                    width: 82,
                    border: '1px solid rgba(0,0,0,0.12)',
                    borderRadius: 2,
                  }}
                  classes={{label: labelStyle}}
                />
              </div>
            </CardContent>
        </div>
	);
}

export default HelpButton;