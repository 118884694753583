import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  DialogActions,
  Typography,
  IconButton,
  Divider,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  InputLabel,
  Tooltip,
  Select,
  MenuItem,TextField
} from "@mineral/core";
import { useDispatch, useSelector } from "react-redux";
import { getAccount } from "../../../../../../../api/account/actions";
import {
  SET_REPORT_PATH_VISIBILITY,
  SET_SAVE_STATUS,
  SET_STATUS_MESSAGE,
} from "../../../../../../../api/performanceReports/actionTypes";
import prd from "../../../../../../../api/performanceReports/prd";
import {
  Close as CloseIcon,
  CompassCalibrationOutlined,
} from "@material-ui/icons";
import {
  updateImportedXmlData,
  updateXmlData,
  setReportTitle,
  clearQosSelectionData,
} from "../../../../../../../api/performanceReports/actions";
import X2JS from "xmljson-converter";
import { js2xml } from "xml-js";
import "./QosImportChartDialog.less";
import { ReactComponent as FolderCloseIcon } from "../icons/ic_folder_close.svg";
import { ReactComponent as FolderOpenIcon } from "../icons/ic_folder_open.svg";
import { ReactComponent as ArrowCloseIcon } from "../icons/ic_arrow_close.svg";
import { ReactComponent as ArrowOpenIcon } from "../icons/ic_arrow_open.svg";
import { ReactComponent as FileIcon } from "../icons/ic_file.svg";
import { ReactComponent as FileSelectIcon } from "../icons/ic_file_select.svg";
import { ReactComponent as NewFolderIcon } from "../icons/ic_new_folder.svg";
import { ReactComponent as DangerIcon } from "../icons/ic_danger.svg";
import debounce from "lodash.debounce";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

const QosImportChartDialog = (props) => {
  const dispatch = useDispatch();
  const inputFile = React.useRef(null);
  // const source = useSelector((state) =>state.performanceReports.selectedSource=='host'?state.performanceReports.byHost.host:undefined)
  // const qosValue = useSelector((state) =>state.performanceReports.selectedSource=='host'?state.performanceReports.byHost.qos:state.performanceReports.byTarget.qos)
  // const target = useSelector((state) =>state.performanceReports.selectedSource=='target'?state.performanceReports.byTarget.target:undefined)
  const accountList = useSelector((state) => state.account.accounts);
  const accountOwner = useSelector(
    (state) => state.performanceReports.chartData.State.visibility.owner
  );
  // snack bar
  const snackMessage = useSelector((state) => state.performanceReports.message);
  //End sncak bar

  const [reportList, setReportList] = useState({});
  const [selectedItem, setSelectedItem] = useState({ path: "", newItem: null });
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [openedFolders, setOpenedFolders] = useState([]);
  const [currentReportList, setCurrentReportList] = useState({});
  const [typeLevel, setTypeLevel] = useState("private");
  const [selectedAccount, setSelectedAccount] = useState("");
  const [readyPaths, setReadyPaths] = useState([]);
  const [deleteAction, setDeleteAction] = useState(false);
  const [newFolderAction, setNewFolderAction] = useState(null);
  const [responseData, setResponseData] = useState([]);
  const [deletedReports, setDeletedReports] = useState([]);
  const [openedSnackbar, setOpenedSnackbar] = useState(false);
  const [showImportSuccess, setShowImportSuccess] = useState(false);
  const [disableSave, setDisableSave] = React.useState(
    props.isSaved ? true : false
  );
  // Snack Bar
  const [openSnackBar, setOpenSnackBar] = React.useState(false);

  useEffect(() => {
    if (props.open) {
      openNewReports();
      dispatch(getAccount());
      if (props.type === "save") {
        setDeletedReports([]);
        setSelectedItem({ path: props.chartData.State.path, newItem: true });
      } else {
        setDeletedReports([]);
        setSelectedItem({ path: "", newItem: null });
      }
    }
  }, [props.open]);

  useEffect(() => {
    if (typeLevel === "account") validateSelAccountName();
  }, [typeLevel]);

  const openNewReports = () => {
    (async () => {
      setReadyPaths([]);
      const resolvedData = await prd.listPrdReports();
      const newResponseData = resolvedData.data;
      setResponseData(newResponseData);
      const resultList = {};
      const newReadyPaths = [...readyPaths];
      newResponseData.forEach((item) => {
        addItemsToResultList(item, resultList, null, newReadyPaths);
      });
      // if (props.type === 'save' && inputPath.current) {
      //     inputPath.current.value = ''
      // }
      setReportList(resultList);
      setCurrentReportList(resultList);
      setReadyPaths(newReadyPaths);

      // setSelectedItem({path:'', newItem: null})
    })();
  };

  const addItemsToResultList = (item, resultList, newFile, newReadyPaths) => {
    const pathList = item.path.split("/");
    let currentItem = resultList;
    pathList.forEach((itemPath, indexPath) => {
      if (!currentItem[itemPath]) {
        if (pathList.length !== indexPath + 1) {
          currentItem[itemPath] = {};
          currentItem = currentItem[itemPath];
        } else {
          // if (item.isFolder) {
          //     currentItem[itemPath] =
          // }
          currentItem[itemPath] = [pathList.join("/"), newFile];
          if (newReadyPaths) {
            newReadyPaths.push([pathList.join("/"), newFile]);
          }
        }
      } else {
        currentItem = currentItem[itemPath];
      }
    });
  };

  // const reportTitle=(path)=>{

  //     let pathArray=path.split("/")
  //     return pathArray[pathArray.length-1];

  // }

  const handleOpen = (newItem) => {
    (async () => {
      let data;
      if (newItem) {
        data = newItem;
      } else {
        const resolvedData = await prd.loadReport(selectedItem.path);
        data = resolvedData.data;
        if (data.reportStructure !== "" && data.reportStructure !== null)
          dispatch({ type: SET_SAVE_STATUS, saved: true }); // ENABLED PRD REPORT SAVE
      }
      openReadyChart(data);
    })();
  };

  const verifyLegacyReport = (seriesJsonData) => {
    if (seriesJsonData.State.aggLevel == undefined) {
      let State = { ...seriesJsonData.State };
      return seriesJsonData;
    } else {
      return seriesJsonData;
    }
  };
  const openReadyChart = async (data) => {
    let seriesCallArray = [];
    let seriesJsonData = getJsonFromXML(data.reportStructure);
    //	seriesJsonData = verifyLegacyReport(seriesJsonData);
    let pageRows = seriesJsonData.State.page.row;
    let count = 0;
    pageRows.forEach((element) => {
      let charts = element.chart;
      charts.forEach((chart) => {
        count++;
        if (chart?.series === undefined) {
          chart.series = [];
        }

        chart.series =
          chart.series.length == 1
            ? chart.series[0] == ""
              ? []
              : chart.series
            : chart.series;
        let seriesArray = chart.series;
        if (seriesArray) {
          var counter = 0;
          //Restrict number of series in a chart
          var maxSeries = 50;
          for (var i = 0; i < seriesArray.length; i++) {
            counter++;
            if (counter > maxSeries) break;
            var dataSeries = seriesArray[i];
            let chartId = chart.chartId ? chart.chartId : count;
            seriesCallArray.push(
              chartSeriesInfo(
                chartId,
                dataSeries.displayName.split("|")[0],
                dataSeries.displayName.split("|")[1],
                dataSeries.displayName.split("|")[2],
                dataSeries.graphColor,
                dataSeries.style,
                dataSeries.scale,
                dataSeries.pla,
                dataSeries.pLine.active === "false"
                  ? ""
                  : dataSeries.pLine.percentile
              )
            );
          }
        }
      });
    });
    let values = await Promise.all(seriesCallArray);
    let updatedData = chartImportDataUpdate(values, seriesJsonData);
    // console.log(seriesJsonData)
    // //let seriesJsonData=JSON.parse(xml2json(resolvedData.data.reportStructure, {compact: true, spaces: 4}));
    // // var options = {fullTagEmptyElement:true,compact: true, ignoreComment: true, spaces: 4,ignoreCdata:true,ignoreDoctype:true,ignoreDeclaration:true};
    // // var result = json2xml(seriesJsonData, options);
    // var result = x2js.json2xml_str( seriesJsonData );
    dispatch(updateImportedXmlData(updatedData));
    setShowImportSuccess(true);
    props.handleImportDialogClose();
  };

  const handleSaveNewPRD = () => {
    (async () => {
      const options = {
        fullTagEmptyElement: true,
        compact: true,
        ignoreComment: true,
        spaces: 0,
        ignoreCdata: true,
        ignoreDoctype: true,
        ignoreDeclaration: true,
      };
      let visibility = "private";
      let visibilityIdentifier = 0;
      const chartData = { ...props.chartData };
      if (selectedItem.path) {
        chartData.State.path = selectedItem.path;
        chartData.State.helpPath = selectedItem.path;
        const pathData = selectedItem.path.split("/");
        chartData.State.title = pathData[pathData.length - 1];
      }
      chartData.State.visibility.type = typeLevel;
      if (typeLevel === "account" && selectedAccount) {
        chartData.State.visibility.owner = selectedAccount;
        visibility = "account";
        visibilityIdentifier = selectedAccount;
      } else {
        chartData.State.visibility.owner = typeLevel;
        visibility = typeLevel;
      }
      var x2js = new X2JS({ skipEmptyTextNodesForObj: false });
      var result = x2js.json2xml_str(chartData);
      //const result = js2xml(chartData, options);
      const encodedString = Buffer.from(result).toString("base64");
      const requestBody = {
        report: selectedItem.path,
        base64EncodedReportStructure: encodedString,
        visibility: visibility,
        visibilityIdentifier: visibilityIdentifier,
        reportOldName: "",
        isNameModified: false,
      };
      await prd.savePrdReport(requestBody);
      dispatch(setReportTitle(chartData.State.title));
      const resolvedData = await prd.savePrdReport(requestBody);
      dispatch({
        type: SET_REPORT_PATH_VISIBILITY,
        payload: {
          path: chartData.State.path,
          helpPath: chartData.State.helpPath,
          visibility: {
            type: chartData.State.visibility.type,
            owner: chartData.State.visibility.owner,
          },
          visibilityIdentifier: visibilityIdentifier,
        },
      });
      dispatch({
        type: SET_SAVE_STATUS,
        saved: true,
      });
      dispatch({
        type: SET_STATUS_MESSAGE,
        payload: resolvedData.data,
      });
      props.handleImportDialogClose();
    })();
    // Snack Bar
    setOpenSnackBar(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackBar(false);
  };
  //Close Snack Bar

  const handleDelete = () => {
    (async () => {
      await prd.deletePrdReport(selectedItem.path);
      setDeletedReports((reports) => [...reports, selectedItem.path]);
      openNewReports();
      setDeleteAction(false);
    })();
  };

  const handleOpenedFolder = (structure, path) => {
    const newOpenedFolders = [...openedFolders];
    const index = openedFolders.indexOf(structure);
    if (index === -1) {
      newOpenedFolders.push(structure);
      setSelectedFolder(structure);
      if (props.type === "save") {
        const resultPrevPath = selectedItem.path.split("/");
        setSelectedItem({
          path: path + resultPrevPath[resultPrevPath.length - 1],
          newItem: null,
        });
      }
    } else {
      newOpenedFolders.splice(index, 1);
      setSelectedFolder(null);
      if (props.type === "save") {
        const resultPrevPath = selectedItem.path.split("/");
        setSelectedItem({
          path: resultPrevPath[resultPrevPath.length - 1],
          newItem: null,
        });
      }
    }
    setOpenedFolders(newOpenedFolders);
  };

  const checkPathStructure = (structure, newStructure, searchPath) => {
    Object.keys(structure).forEach((childStructure) => {
      if (
        childStructure
          .toLocaleLowerCase()
          .includes(searchPath.toLocaleLowerCase())
      ) {
        newStructure[childStructure] = structure[childStructure];
      } else {
        if (!Array.isArray(structure[childStructure])) {
          checkPathStructure(
            structure[childStructure],
            newStructure,
            searchPath
          );
        }
      }
    });
  };

  const chartSeriesInfo = async function (
    chartId,
    source,
    qos,
    target,
    graphColor,
    style,
    scale,
    pla,
    ptile
  ) {
    return prd.getchartSeriesInfo({
      chartId: chartId,
      source: source,
      qos: qos,
      target: target,
      graphColor: graphColor,
      style: style,
      scale: scale,
      pla: pla,
      ptile: ptile,
    });
  };

  const chartImportDataUpdate = (values, newData) => {
    let chartSeriesMap = new Map();
    values.forEach((value) => {
      var x2js = new X2JS({
        arrayAccessFormPaths: ["data.series"],
      });
      var seriesJsonData = x2js.xml_str2json(value.data);
      if (seriesJsonData.data.series == undefined) {
        seriesJsonData.data.series = [];
      }
      seriesJsonData.data.series =
        seriesJsonData.data.series.length == 1
          ? seriesJsonData.data.series[0] == ""
            ? []
            : seriesJsonData.data.series
          : seriesJsonData.data.series;
      seriesJsonData.data.series &&
        seriesJsonData.data.series.forEach((series) => {
          let seriesArray = [];
          let seriesChartId = series.chartId;
          seriesChartId = `${seriesChartId}`;
          seriesChartId = Number.isInteger(seriesChartId)
            ? seriesChartId
            : parseInt(seriesChartId, 10);
          if (chartSeriesMap.has(seriesChartId)) {
            seriesArray = chartSeriesMap.get(seriesChartId);
          }
          seriesArray.push(series);
          chartSeriesMap.set(seriesChartId, seriesArray);
        });
    });
    let pageRows = newData.State.page.row;
    let version = newData.State._ver;
    let count = 0;
    pageRows.forEach((element) => {
      let charts = element.chart;
      charts.forEach((chart) => {
        count++;
        let chartId = chart.chartId ? chart.chartId : count;
        chartId = Number.isInteger(chartId) ? chartId : parseInt(chartId, 10);
        let series = chartSeriesMap.get(chartId);
        chart.series = series;
        if (version === "200") {
          if (chart.showLegend === "true" && chart.legend.table === "true") {
            chart.showLegend = "false";
          } else if (
            chart.showLegend === "false" &&
            chart.legend.table === "false"
          ) {
            chart.showLegend = "true";
          }
        }
      });
    });
    let aggregationInterval = newData.State.aggregationInterval;

    newData.State._ver = "204";
    newData.State.aggregationInterval =
      aggregationInterval === "-1" ? "60" : aggregationInterval;
    return newData;
  };
  const onChangeFile = async (event) => {
    event.stopPropagation();
    event.preventDefault();
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsText(file);

    reader.onload = async function () {
      const xmlString = reader.result;
      const options = {
        fullTagEmptyElement: true,
        compact: true,
        ignoreComment: true,
        spaces: 0,
        ignoreCdata: true,
        ignoreDoctype: true,
        ignoreDeclaration: true,
      };
      const newData = getJsonFromXML(xmlString);
      const resolvedData = await prd.listPrdReports();      
      const savedPaths = resolvedData.data;
      if (
        !(
          savedPaths.some(
            (savedPath) => savedPath.path.trim() === newData.State.path.trim()
          ) &&
          !savedPaths.find(
            (savedPath) => savedPath.path.trim() === newData.State.path.trim()
          )[1]
        )
      ) {
        setOpenedSnackbar(false);
      } else {
        setOpenedSnackbar(true);
        return;
      }
      /*if (
        !(
          readyPaths.some(
            (readyPath) => readyPath[0].trim() === newData.State.path.trim()
          ) &&
          !readyPaths.find(
            (readyPath) => readyPath[0].trim() === newData.State.path.trim()
          )[1]
        )
      ) {
        setOpenedSnackbar(false);
      } else {
        setOpenedSnackbar(true);
        return;
      }*/
      const newResults = { ...reportList };
      var x2js = new X2JS({ skipEmptyTextNodesForObj: false });
      var newXml = x2js.json2xml_str(newData);
      //let newXml = js2xml(updatedData, options);
      const newItem = {
        path: newData.State.path,
        title: newData.State.title,
        reportStructure: newXml,
        visibility: newData.State.visibility.type,
        visibilityIdentifier: 0,
      };
      const newReadyPath = [...readyPaths];
      addItemsToResultList(newItem, newResults, newItem, newReadyPath);
      setReportList(newResults);
      setCurrentReportList(newResults);
      setReadyPaths(newReadyPath);
      setSelectedItem({ path: newItem.path, newItem: newItem });
    };

    reader.onerror = function () {
      console.log(reader.error);
    };
  };

  const getJsonFromXML = (xmlString) => {
    const x2js = new X2JS({
      //emptyNodeForm: "object",
      arrayAccessFormPaths: [
        "State.page.row",
        "State.page.row.chart",
        "State.page.row.chart.series",
        "State.groups.group",
        "State.groups.group.rowSelectors.rowSelector",
      ],
    });
    return x2js.xml_str2json(xmlString);
  };

  const getStructure = (structure, pathLink,level) => {
    return Object.keys(structure).map((childStructure,index) => {
      if (Array.isArray(structure[childStructure])) {
        if (childStructure) {
          return (
            <Button variant="text"
              key={childStructure} aria-label={childStructure+" report at level "+level}
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                marginBottom: "10px",
              }}
              tabIndex={0}
              onClick={(event) => {
                event.stopPropagation();
                setSelectedItem({
                  path: structure[childStructure][0],
                  newItem: structure[childStructure][1],
                });
                setDeleteAction(false);
                const pathElement = responseData.find(
                  (pathElement) =>
                    pathElement.path === structure[childStructure][0]
                );
                if (pathElement) {
                  const account = accountList.find(
                    (account) =>
                      account.accountId === pathElement.visibilityIdentifier
                  );
                  if (pathElement.visibility === "account" && account) {
                    setSelectedAccount(account.accountId);
                    validateSelAccountName();
                  }
                  setTypeLevel(pathElement.visibility);
                }
              }}
            >
              {selectedItem.path === structure[childStructure][0] ? (
                <FileSelectIcon />
              ) : (
                <FileIcon />
              )}
              <span
                className={
                  selectedItem.path === structure[childStructure][0]
                    ? "qos-import__file-name select"
                    : "qos-import__file-name"
                }
              >
                {childStructure}
              </span>
            </Button>
          );
        } else {
          return null;
        }
      } else {
        return (
          <>
            <Button variant="text" aria-label={childStructure+" folder "+(openedFolders.includes(structure[childStructure])?"expanded":"collapsed")+" at level "+level}
              key={childStructure}
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              tabIndex={0}
              onClick={(event) => {
                event.stopPropagation();
                handleOpenedFolder(
                  structure[childStructure],
                  pathLink + childStructure + "/"
                );
              }}
            >
              {openedFolders.includes(structure[childStructure]) ? (
                <>
                  <ArrowOpenIcon />
                  <FolderOpenIcon />
                </>
              ) : (
                <>
                  <ArrowCloseIcon />
                  <FolderCloseIcon />
                </>
              )}
              <span
                className={
                  selectedFolder === structure[childStructure]
                    ? "qos-import__folder-name active"
                    : "qos-import__folder-name"
                }
              >
                {childStructure}
                {/* {'(' + Object.keys(structure[childStructure]).length + ')'} */}
              </span>
            </Button>
            <div className="qos-import__content">
              {openedFolders.includes(structure[childStructure])
                ? getStructure(
                    structure[childStructure],
                    pathLink + childStructure + "/",level+1
                  )
                : null}
            </div>
            {selectedFolder === structure[childStructure] &&
            newFolderAction !== null ? (
              <input tabIndex={0}
                name="New folder"
                autoFocus
                style={{ marginBottom: "10px" }}
                onBlur={() => createNewFolder(pathLink + childStructure + "/")}
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    createNewFolder(pathLink + childStructure + "/");
                  }
                }}
                value={newFolderAction}
                onChange={(event) => setNewFolderAction(event.target.value)}
              />
            ) : null}
          </>
        );
      }
    });
  };

  const debouncedChangeValue = debounce((pathValue) => {
    const newStructure = {};
    checkPathStructure(reportList, newStructure, pathValue);
    setSelectedItem({ path: "", newItem: null });
    setCurrentReportList(newStructure);
  }, 600);

  const prepareNewFolder = () => {
    // inputNewFolder.current.style.display = 'block';
    // inputNewFolder.current.focus();
    setNewFolderAction("");
  };
  const handleClose = () => {
    if (
      props.chartData.State.path &&
      deletedReports.includes(props.chartData.State.path)
    ) {
      dispatch(clearQosSelectionData());
    }
    props.handleImportDialogClose();
  };
  const createNewFolder = (path) => {
    if (newFolderAction) {
      const newItem = {
        path: path + newFolderAction + "/",
      };
      const newResults = { ...reportList };
      addItemsToResultList(newItem, newResults, true);
      setReportList(newResults);
      setCurrentReportList(newResults);
      const resultPrevPath = selectedItem.path.split("/");
      setSelectedItem({
        path: newItem.path + resultPrevPath[resultPrevPath.length - 1],
        newItem: null,
      });
    }
    // inputNewFolder.current.style.display = 'none';
    // inputNewFolder.current.value = '';
    setNewFolderAction(null);
  };

  const onChangePathName = (event) => {
    setSelectedItem({ path: event.target.value, newItem: null });
    validatePathName(event.target.value);
  };

  const onFocusPathName = (event) => {
    validatePathName(event.target.value);
  };

  const validatePathName = (pathName) => {
    if (props.isSaved) {
      if (
        !(
          readyPaths.some(
            (readyPath) => readyPath[0].trim() === pathName.trim()
          ) &&
          !readyPaths.find(
            (readyPath) => readyPath[0].trim() === pathName.trim()
          )[1]
        )
      ) {
        setOpenedSnackbar(false);
        setDisableSave(false);
      } else {
        setOpenedSnackbar(true);
        setDisableSave(true);
      }
    } else {
      setOpenedSnackbar(false);
      setDisableSave(false);
    }
  };

  const validateSelAccountName = () => {
    if (typeLevel === "account") {
      let acctFirstId = 1;
      if (accountList && accountList.length > 0)
        acctFirstId = Object.values(accountList)[0].accountId;

      if (
        selectedAccount === "" ||
        selectedAccount === 0 ||
        selectedAccount === "0" ||
        selectedAccount === undefined ||
        selectedAccount === "undefined" ||
        selectedAccount === null ||
        selectedAccount < 0
      ) {
        setSelectedAccount(acctFirstId);
      } else {
        const acctSelId = accountList.find(
          (account) => account.accountId === selectedAccount
        );
        if (!acctSelId) {
          if (
            accountOwner === "" ||
            accountOwner === "account" ||
            accountOwner === "public" ||
            accountOwner === "private"
          )
            setSelectedAccount(acctFirstId);
          else setSelectedAccount(accountOwner);
        }
      }
    }
  };

  //Snackbar Action
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSnackbar}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  // Close snackBar action

  return (
    <>
      <Dialog
        className="qos-import-chart-dialog"
        open={props.open}
        fullWidth={true}
        maxWidth={"sm"}
        onClose={handleClose}
      >
        <DialogTitle component="h1" disableTypography>
          
        <Typography variant="h2" component="div"> {props.type === "import"
              ? "Import Performance Report"
              : props.isSaved
              ? "Save As Performance Report"
              : "Save Performance Report"}
          </Typography>
         
        </DialogTitle>
        <div style={{height:'0px'}}>
      <Tooltip title="Close">
      <IconButton autoFocus style={{float:'right',top:'-50px',marginRight:'8px'}}
          aria-label="Close"
          onClick={handleClose} 
        >
          <CloseIcon />
        </IconButton>
        </Tooltip>
        </div>
        <p
          style={{
            color: "#e50000",
            display: "flex",
            marginBottom: "5px",
            marginTop: "auto",
            paddingTop: "0px",
            paddingRight: "0px",
            paddingLeft: "24px",
            paddingBottom: "0px",
          }}
        >
          Maximum 50 series can be imported for each chart
        </p>
        <DialogContent dividers={true}>
          {/* <List>
        {reportList.length>0 && reportList.map((report,index)=> 
            <div key={`${"report-list"+index}`}> 
            <ListItem 
            button 
            selected={selectedItem.path==report.path}
            onClick={()=>setSelectedItem(report)}
            >
            <ListItemText primary={reportTitle(report.path)}  />
            </ListItem>
            <Divider />
            </div>)
        }
        </List> */}
          {props.type === "save" ? (
            <p className="qos-import-chart-dialog__label">
              {props.isSaved
                ? "Type in the path to save as."
                : "Select a folder to save to, or type in the path."}
            </p>
          ) : (
            <p className="qos-import-chart-dialog__label">
              Select a folder to import to, type in the path, or{" "}
              <Button variant="text"
                className="qos-import-chart-dialog__label-link"
                onClick={() => {
                  inputFile.current.click();
                }}
              >
                open it from the local file system.
              </Button>
            </p>
          )}
          <div className="qos-import-chart-dialog__file-wrapper">
            {getStructure(currentReportList, "",1)}
            {selectedFolder === null && newFolderAction !== null ? (
              <input
                name="New folder"
                autoFocus
                onBlur={() => createNewFolder("")}
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    createNewFolder("");
                  }
                }}
                value={newFolderAction}
                onChange={(event) => setNewFolderAction(event.target.value)}
              />
            ) : null}
          </div>
          <input
            type="file"
            id="import-file-qos"
            onChange={onChangeFile}
            ref={inputFile}
            accept="text/xml"
            style={{ display: "none" }}
          />
          {props.type === "save" ? (
            <>
              <div className="qos-import-chart-dialog__search-path">
                <div
                  style={{
                    display: "flex",
                    flexFlow: "row nowrap",
                    justifyContent: "space-between",
                    marginBottom: "3px",
                  }}
                >
                  <InputLabel
                    htmlFor="name-path"
                    
                  >
                    Path
                  </InputLabel>
                  {props.type === "save" ? (
                    <div
                      className="qos-import-chart-dialog__search-path-folder"
                       
                    >
                     
                      <Button variant="text" onClick={prepareNewFolder} className="qos-import-chart-dialog__search-path-folder-label">
                      <NewFolderIcon />
                        New Folder
                      </Button>
                    </div>
                  ) : null}
                </div>
                <TextField 
                 fullWidth
                  name="name"
                  id="name-path"
                
                  placeholder={"Type path or select from tree view"}
                  value={selectedItem.path}
                  onChange={onChangePathName}
                  onFocus={onFocusPathName}
                  onBlur={onFocusPathName}
                />
              </div>
              <div className="qos-import-chart-dialog__visibility">
                <RadioGroup
                  row
                  aria-label="Save PRD at"
                  name="row-radio-buttons-group"
                  onChange={(event) => {
                    setTypeLevel(event.target.value);
                  }}
                  onClick={(event) => {
                    validateSelAccountName();
                  }}
                >
                  <FormControlLabel style={{marginRight:'8px'}}
                    value="account"
                    control={
                      <Radio style={{marginRight:'4px'}}
                        checked={typeLevel === "account"}
                        color="primary"
                      />
                    }
                    label="Account level"
                  />
                  <FormControlLabel style={{marginRight:'8px'}}
                    value="private"
                    control={
                      <Radio style={{marginRight:'4px'}}
                        checked={typeLevel === "private"}
                        color="primary"
                      />
                    }
                    label="Private level"
                  />
                  <FormControlLabel
                    value="public"
                    control={
                      <Radio style={{marginRight:'4px'}} checked={typeLevel === "public"} color="primary" />
                    }
                    label="Public level"
                  />
                </RadioGroup>
                {typeLevel === "account" &&
                accountList &&
                accountList.length ? (
                  <div style={{ width: "160px" }}>
                    <span style={{ fontSize: "14px" }}>Account</span>
                    <FormControl className="qos-import-chart-dialog__save-select">
                      <Select
                        id="prd-account-select"
                        value={selectedAccount}
                        variant="outlined"
                        displayEmpty
                        onChange={(event) => {
                          setSelectedAccount(event.target.value);
                        }}
                      >
                        {accountList.map((account, index) => {
                          return (
                            <MenuItem
                              value={account.accountId}
                              key={"account-" + index}
                            >
                              {account.contactName}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </div>
                ) : null}
              </div>
            </>
          ) : (
            <div className="qos-import-chart-dialog__search-path">
              <InputLabel
                htmlFor="search-path"
                className="qos-import-chart-dialog__search-path-label"
              >
                Search path
              </InputLabel>
              <TextField fullWidth
                 sx={{
      input: {
        
         "&::placeholder": {    // <----- Add this.
            color: 'black !important',
            opacity:0.8
           
         },
      },
      
   }}
                name="path"
                id="search-path"
                placeholder={"Type path or select from tree view"}
                // value={selectedItem.path}
                onChange={(event) => {
                  debouncedChangeValue(event.target.value);
                  // setSelectedItem({path: event.target.value, newItem: null})
                }}
              />
            </div>
          )}
        </DialogContent>
        <DialogActions>
          {deleteAction ? (
            <>
              <div className="qos-import-chart-dialog__delete">
                <DangerIcon />
                <p className="qos-import-chart-dialog__delete-text">
                  Are you sure you want to delete the selected view?
                </p>
              </div>
              <Tooltip title="Cancel">
                <span>
                  <Button
                    onClick={() => setDeleteAction(false)}
                    color="primary"
                  >
                    Cancel
                  </Button>
                </span>
              </Tooltip>
              <Tooltip title="Delete">
                <span>
                  <Button
                    onClick={handleDelete} color="error"
                    
                  >
                    Delete
                  </Button>
                </span>
              </Tooltip>
            </>
          ) : (
            <>
              <Tooltip title="Delete" style={{ marginRight: "auto" }}>
                <span>
                  <Button color="error"
                    onClick={() => setDeleteAction(true)}
                    disabled={
                      !(
                        readyPaths.some(
                          (readyPath) => readyPath[0] === selectedItem.path
                        ) &&
                        !readyPaths.find(
                          (readyPath) => readyPath[0] === selectedItem.path
                        )[1]
                      )
                    }
                   
                  >
                    Delete
                  </Button>
                </span>
              </Tooltip>
              <Tooltip title="Cancel">
                <span>
                  <Button onClick={handleClose} color="primary">
                    Cancel
                  </Button>
                </span>
              </Tooltip>
              {props.type === "import" ? (
                <>
                  <Tooltip title="Import">
                    <span>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() =>
                          handleOpen(
                            readyPaths.find(
                              (readyPath) => readyPath[0] === selectedItem.path
                            )[1]
                          )
                        }
                        disabled={
                          !readyPaths.some(
                            (readyPath) => readyPath[0] === selectedItem.path
                          )
                        }
                      >
                        Import
                      </Button>
                    </span>
                  </Tooltip>
                </>
              ) : (
                <>
                  <Tooltip title={"Save"}>
                    <span>
                      <Button
                        color="primary"
                        variant="contained"
                        disabled={
                          !typeLevel ||
                          !selectedItem.path ||
                          (typeLevel === "account" && !selectedAccount)
                        }
                        onClick={handleSaveNewPRD}
                      >
                        {"Save"}
                      </Button>
                    </span>
                  </Tooltip>
                </>
              )}
            </>
          )}
        </DialogActions>
      </Dialog>
      <Snackbar
        open={openedSnackbar}
        autoHideDuration={5000}
        onClose={() => setOpenedSnackbar(false)}
      >
        <Alert severity="error">
          A report with the same name already exists.
        </Alert>
      </Snackbar>
      <Snackbar
        open={openSnackBar}
        autoHideDuration={5000}
        message={snackMessage}
        onClose={() => setOpenSnackBar(false)}
      />
        <Snackbar
        open={showImportSuccess}
        autoHideDuration={5000}
        message={"Import submitted successfully"}
        onClose={() => setShowImportSuccess(false)}
      />
    </>
  );
};
export default QosImportChartDialog;
