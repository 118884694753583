import connect from "../../utils/connect";
import { createSelector } from "reselect";
import filter from "lodash/filter";
import orderBy from "lodash/orderBy";
import { setQuickFilterEntities } from "../entities/actions";
import {
  addGroup,
  setSelectedCsIds,
  pollGroupsStop,
} from "../../api/groups/actions";

import TreeViewManager from "./TableManager";
import entityTypes from "../../api/entityTypes";
import { GROUP_SORT_TYPES } from "../../api/groups/actionTypes";
import { setCardPage } from "../cards/actions";
import { getAlarmUrl, getContainerGroupFilters } from "../../utils/common";
const getEntity = (state) => state.entity;
const getCards = (state) => state.entities;
const getGroupSortColumn = (state) => state.groupSorter;
const getSearchTerm = (state) => state.inventoryEntityFilter.quickFilter;
const getCardPage = (state) => state.cardPage;

let columnDefs = "groupCols";

const getCardsSelector = createSelector(
  [getCards, getEntity],
  (cards, entity) => {
    const entityType = entity && entity.type;
    columnDefs =
      entityType === entityTypes.CONTAINER || entityType === entityTypes.ROOT
        ? "groupCols"
        : "csCols";
    return cards;
  }
);
const getFilteredCardsSelector = createSelector(
  [getCardsSelector, getSearchTerm],
  (cards, searchTerm) => {
    return filter(cards, (card) => {
      var stringToCheckAgainst = getString(card);
      return (
        stringToCheckAgainst.toLowerCase().indexOf(searchTerm.toLowerCase()) >=
        0
      );
    });
  }
);
const getSortedCardsSelector = createSelector(
  [getFilteredCardsSelector, getGroupSortColumn],
  (cards, sortCol) => {
    const getSortFunction = (sortKey) => {
      let func;
      let sortCol = sortKey.prop;
      let order = sortKey.order;
      switch (sortCol) {
        case GROUP_SORT_TYPES.NAME:
          func = (arr) => orderBy(arr, sortCol, order);
          break;
        case GROUP_SORT_TYPES.SEVERITY:
          func = (arr) =>
            orderBy(arr, [sortCol, "maxAlarmSeverity.cnt"], [order, order]);
          break;
        default:
          func = (arr) => orderBy(arr, sortCol, order);
          break;
      }
      return func;
    };
    return getSortFunction(sortCol)([...cards]);
  }
);
const getRowsSelector = createSelector(
  [getSortedCardsSelector, getCardPage],
  (cards, groupPage) => {
    return cards;
  }
);
function getString(card) {
  if (columnDefs === "groupCols") {
    return (
      card.name +
      " " +
      card.memberCount +
      " " +
      card.totalAlarmCount +
      " " +
      card.maxAlarmSeverity.label
    );
  } else {
    var ips = "";
    for (var ip in card.ipAddresses) {
      if (card.ipAddresses.hasOwnProperty(ip))
        ips += card.ipAddresses[ip] + " ";
    }
    return (
      card.name +
      " " +
      ips +
      " " +
      card.totalAlarmCount +
      " " +
      card.maxAlarmSeverity.label +
      " " +
      card.alias +
      " " +
      card.busType +
      " " +
      card.osType +
      " " +
      card.osName +
      " " +
      card.osVersion
    );
  }
}
const mapStateToProps = (state) => {
  return {
    featureFlags: state.configuration.items,
    groupsDidInvalidate: state.groups.didInvalidate,
    groupsIsFetching: state.groups.isFetching,
    groupDeleteFailed: state.groups.groupDeleteFailed,
    computerSystemsDidInvalidate: state.computerSystems.didInvalidate,
    computerSystemsIsFetching: state.computerSystems.isFetching,
    data: getRowsSelector(state),
    columnDefs: columnDefs,
    entityType: state.entity.type,
    criteria: state.entity.criteria,
    selectedId: state.entity.id,
    entities: state.entities,
    name: state.entity.name,
    breadcrumbs: state.breadCrumbs,
    acl: state.acl,
    saas: state.saas,
    doiurl: state.user.doiurl,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleAlarmCellClick: (row, history, saas, doiurl, featureFlags) => {
      if (saas && doiurl && featureFlags && featureFlags.globalAlarmsView) {
        let filters;
        if (row.data.entityType === entityTypes.GROUP) {
          let groupIds = [row.data.id];
          let groupNames = [row.data.name];
          filters = getContainerGroupFilters(row.data, groupIds, groupNames);
        } else {
          filters = `csId=${row.data.id}&deviceName=${row.data.name}`;
        }
        dispatch(setQuickFilterEntities(""));
        window.location.href = getAlarmUrl(doiurl, filters);
      } else {
        const pathname = `${history.location.pathname.replace(
          "/treeView",
          ""
        )}`;
        const newRoute =
          row.data.entityType === entityTypes.GROUP
            ? `${pathname}/${row.data.id}/alarms`
            : `${pathname}/computer-systems/${row.data.id}/alarms`;
        history.push(newRoute);
        dispatch(setQuickFilterEntities(""));
      }
    },
    handleCabiCellClick: (row, history) => {
      const pathname = `${history.location.pathname.replace("/treeview", "")}`;

      // const newRoute = `${pathname}/computer-systems/${row.id}/cabi`
      const newRoute = `/computer-systems/${row.data.id}/dashboard`;
      history.push({
        pathname: newRoute,
        state: { detail: row.data, csId: row.data.id },
      });
      dispatch(setQuickFilterEntities(""));
    },
    handleGroupCellClick: (row, history) => {
      const pathname = `${history.location.pathname.replace("/treeview", "")}`;
      let rowData = row.data ? (row.data.id ? row.data.id : row.id) : row.id;
      const newRoute = `${pathname}/${rowData}/treeview`;
      history.push(newRoute);
      dispatch(setQuickFilterEntities(""));
    },
    handlePageClick: (selectedPage) =>
      dispatch(setCardPage(selectedPage.selected)),
    resetCardPage: () => dispatch(setCardPage(0)),
    addGroup: (newGroup) => dispatch(addGroup(newGroup)),
    handleCsRowsSelected: (ids) => {
      dispatch(setSelectedCsIds(ids));
    },
    pollGroupsStop: (id) => dispatch(pollGroupsStop(id)),
    // FETCH_GROUP_TREE_SUCCESS:() => dispatch(FETCH_GROUP_TREE_SUCCESS)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TreeViewManager);
