export const FETCH_QoS = "FETCH_QoS";
export const FETCH_Groups = "FETCH_Groups";
export const GET_Groups = "GET_Groups";
export const GET_EACH_QOS = "GET_EACH_QOS";
export const FETCH_QoS_Groups = "FETCH_QoS_Groups";
export const Group_SUCCESS = "Group_SUCCESS";
export const QOS_EACH_SUCCESS = "QOS_EACH_SUCCESS";
export const QOS_EACH_FAILURE = "QOS_EACH_FAILURE";
export const FETCH_QoS_Groups_FAILURE = "FETCH_QoS_Groups_FAILURE";
export const FETCH_QoS_SUCCESS = "FETCH_QoS_SUCCESS";
export const FETCH_QoS_FAILURE = "FETCH_QoS_FAILURE";
export const SAVE_QoS = "SAVE_QoS";
export const SAVE_QoS_SUCCESS = "SAVE_QoS_SUCCESS";
export const SAVE_QoS_FAILURE = "SAVE_QoS_FAILURE";
export const DELETE_QoS = "DELETE_QoS";
export const DELETE_SUCCESS = "DELETE_SUCCESS";
export const DELETE_FAILURE = "DELETE_FAILURE";