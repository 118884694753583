import axios from 'axios'
import config from './../config'

class Interfaces {
  getRegularFields(params) {
    const url = process.env.NODE_ENV == "development"
    ? [
        "http://10.252.25.208:8080/castlemock/mock/rest/project/ViXRbO/application/VgCY5v/interface_id",
      ]
    : [config.basename, 'api/v1/interface',params.csId].join('/')
    return axios.get(url+"?isGroup="+params.isGroup)
  }
  getMetricFields(params) {
     const id = params.get("cs_id")
     var url = process.env.NODE_ENV == "development"
      ? [
         "http://10.252.25.208:8080/castlemock/mock/rest/project/ViXRbO/application/VgCY5v/interface/metric",
       ]
      : [config.basename, 'api/v1/interface/metric', id != null ? id : '']
     return axios.post(url.join('/'),params)
  }
  getMasterElements(filter) {
    const url = [config.basename, 'api/v1/interface'].join('/')
    if(filter){
      return axios.get(url+"?q="+filter)
    }else{
      return axios.get(url)
    }
  }
  updateAliasInterface(newAlias, master_id) {
    return axios.put(
      [config.basename, `api/v1/interface/update/${master_id}?name=${newAlias}`].join("/")
    );
  }
  getInterfaceAlias(master_id) {
    return axios.get(
      [config.basename, `api/v1/interface/getAlias/${master_id}`].join("/")
    );
  }
}
let interfaces = new Interfaces()
export default interfaces