// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  // Alarms
  content["Alarms"] =
  "Alarmas";

  // Dashboards
  content["Dashboards"] =
  "Cuadros de mandos";

  // Metrics
  content["Metrics"] =
  "Métricas";

  // Interfaces
  content["Interfaces"] =
  "Interfaces";

  // END OF LOCALIZATION

export default content;
