import React, { Component } from "react";
import InterfaceTable from "./InterfaceTable";
import InterfaceDashboard from "./InterfaceDashboard";
import { fetchInterfaces } from "./../../api/interfaces/actions";
import { withRouter } from "react-router-dom";
import connect from "../../utils/connect";
import "./interfaces.less";
import { isEqual } from "lodash";
import {
  Menu,Tooltip,
  IconButton,
  MenuItem,
  CircularProgress,    
} from "@mineral/core";
import {MoreIcon} from "../ui-components/uim-components"
import ExportGroupDetailsDialog from "./../treeViewNew/ExportGroupDetailsDialog"
import Resource from './Resource'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import CustomSnackbar from '../common/CustomSnackbar'
//Interface group members
const INTERFACE_GRUP_MEMEBERS=Resource.get("Interface group members")

class Interfaces extends Component {
  constructor(props) {
    super(props);
    this.state = {
      interfaceData: {},
      csId: null,
      groupId: null,
      isEditFetched: false,
      openExportTypeDialog: false,
      menuAnchorEl: null,
      selectedInterfaceData: {},
      showInterfaceDetail: false,
      openNoDataToExportAlert:false,
      applyBtnClicked: false,
      openSnackbar: false
    };
  }
  componentWillMount = () => {
    this.groupApiExecution(this.props);
  };

  groupApiExecution(groupProps) {
    
    let csId = groupProps.csId;
    let groupId = groupProps.groupId;
    let info = {
      csId: csId ? csId : groupId,
      isGroup: csId ? false : true,
    };
    if (csId !== this.state.csId || groupId !== this.state.groupId) {
      this.setState({
        csId: csId,
        groupId: groupId,
        isEditFetched: false,
      });
      groupProps.getInterfaces(info);
    }
    if (
      (groupProps.isEdit && !this.state.isEditFetched) ||
      sessionStorage.getItem("deviceRemoved") || sessionStorage.getItem("editInterfaceGroupStatic")
    ) {
      groupProps.getInterfaces(info).then(() =>
        this.setState({
          isEditFetched: groupProps.isEdit,
        })
      );
      sessionStorage.getItem("deviceRemoved") &&
        sessionStorage.removeItem("deviceRemoved");
        sessionStorage.getItem("editInterfaceGroupStatic") &&
        sessionStorage.removeItem("editInterfaceGroupStatic");
    }
  }
  componentDidMount() {}

  componentWillReceiveProps = (nextProps) => {
    if(!isEqual(nextProps, this.props)) {
      if(this.state.applyBtnClicked){
        this.setState({openSnackbar: false})
        this.props
          .interfacesDataOnAppy({
            csId: nextProps.interfaceData.itemId,
            isGroup: true,
          })
          .then((result) => {
            this.setState({ interfaceData: result.data });
            this.setState({ applyBtnClicked: false, openSnackbar: true });
          });
      }
      this.groupApiExecution(nextProps);
    }
    if (!isEqual(this.state.interfaceData, nextProps.interfaceData)) {
      this.setState({
        interfaceData: nextProps.interfaceData,
      });
    }
  };
  
  handleApplyBtnClicked =() => {
    this.setState({applyBtnClicked: true})
  }

  handleCloseSnackbar = () => {
    this.setState({openSnackbar: false})
  }

  handleExportClose =() =>{
    this.setState({openExportTypeDialog: false})
  }
  handleOpenExportDialog=()=>{
    if(this.state.interfaceData && this.state.interfaceData.iface.length > 0)
      this.setState({openExportTypeDialog: true})
    else 
   this.setState({openNoDataToExportAlert:true});
  }
  handleMenuClick = (event) => {
    this.setState({menuAnchorEl:event.currentTarget});
  };
handleActionMenuClose = (event) => {
    this.setState({menuAnchorEl: null});
  };
   openDetailedViewInterface = (iData) =>{
    //this.props.switchTreeViewNav(NAV_MENUS.DETAIL_VIEW);
    this.setState({
      selectedInterfaceData: iData,
      isInterfacGroup: true,
      showInterfaceDetail: true,
    })
  }

  render() {
    return (
      <div style={{height:'100%'}}> 
      {this.props.fromTreeView && this.props.treeNode.entityType !== "COMPUTER_SYSTEM" && 
        <div style={{display: "flex", flexDirection: 'row', justifyContent: 'space-between',marginBottom: '-16px'}}>
          <div className="interface-table-head"><h3 style={{padding:'0px'}}>{INTERFACE_GRUP_MEMEBERS} ({this.props.interfaceData ?(this.props.interfaceData.iface?this.props.interfaceData.iface.length:0):0})</h3></div>
          <span style={{float: 'right'}}>
          <Tooltip title="More Actions">
            <IconButton 
              aria-controls="action-menu"  aria-haspopup="true"
              onClick={this.handleMenuClick}
            > 
              <MoreIcon/> 
            </IconButton>
            </Tooltip>
            <Menu 
              id="action-menu"
              anchorEl={this.state.menuAnchorEl}
              keepMounted
              onClose={this.handleActionMenuClose}
              open={this.state.menuAnchorEl!= null}           
            >
              <MenuItem children={"Export"}  onClick={this.handleOpenExportDialog}/>                
            </Menu>
          </span>
      </div>
          } 
        {/*this.props.fromTreeView?null:(
      {<div>
        <label
        style={{
          display: "flex",
          marginLeft:"12px",
          marginBottom:"5px"
        }}
      >
          Showing interfaces for {this.state.interfaceData && this.state.interfaceData.iface &&
            this.state.interfaceData.iface.length > 0 ?
                this.state.interfaceData.iface[0].parent_displayName : ""} (
            {this.state.interfaceData && this.state.interfaceData.iface ? 
              this.state.interfaceData.iface.length : 0}
          )
         </label>
        
      </div>}
        )*/} 
        {
          //Tree: 'calc(100% - 50px)' Interfaces 100%
        }
    {!this.state.showInterfaceDetail &&
     <div style={{ width: "100%", marginTop:this.props.fromTreeView?'10px':'0px', height:this.props.fromTreeView?'calc(100% - 42px)':'calc(100%)'}}>
        <main className="interface__main--treeview || interface__main" style={{height:'100%'}}>        
          <InterfaceTable
            interfaceData={this.state.interfaceData}
            fromTreeView={this.props.fromTreeView}
            fromGroupsView={this.state.groupId!==null?this.props.fromGroupsView:false}
            openAlarmViewForInterface={this.props.openAlarmViewForInterface}
            groupData={this.props.groupData}
            isTreeOpen={this.props.openTree}
            openDetailedViewInterface={this.openDetailedViewInterface}  
            openDetailInterfaceFromTreeView = {this.props.openDetailInterfaceFromTreeView}
            showInterfaceDetail={this.state.showInterfaceDetail}          

          />
        </main>
      </div>}
      {this.state.showInterfaceDetail && 
            <div style={{ width: "100%", height: "100%" }}>
            <Tooltip title="Back to interfaces">
            <IconButton style={{marginTop:'8px',marginLeft:'8px'}} aria-label="Back to interfaces" tabIndex={0} autoFocus onClick={()=>{
              this.setState({showInterfaceDetail: false})}}
          >
            <ArrowBackIcon />
            </IconButton>
            </Tooltip>
               <InterfaceDashboard 
                  data={this.state.selectedInterfaceData}
                  fromTreeView={this.props.fromTreeView}
                  isTreeOpen={this.props.isTreeOpen}
                  isInterface= {true}
                  canEditDevice= {this.props.acl.canEditDevice}
                  handleApplyBtnClicked={this.handleApplyBtnClicked}
                  applyBtnClicked={this.state.applyBtnClicked}
                  handleCloseSnackbar={this.handleCloseSnackbar}
                  openSnackbar={this.state.openSnackbar}
                /></div>                
        }
      <ExportGroupDetailsDialog 
          open ={this.state.openExportTypeDialog}
          requestClose = {this.handleExportClose}    
          {...this.props}
          fromTreeView={this.props.fromTreeView}
        />
         <CustomSnackbar severity={'info'}
          open={this.state.openNoDataToExportAlert}
          message={"No data to export."}
          autoHideDuration={4000}
          
          close={()=>{this.setState({openNoDataToExportAlert:false})}}/>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    getInterfaces: (params) => dispatch(fetchInterfaces(params)),
    interfacesDataOnAppy: (params) => dispatch(fetchInterfaces(params)),
  };
};

const mapStateToProps = (state) => {
  try {
    return {
      sid: state.sid,
      acl: state.acl,
      interfaceData: state.interfaces.interfaceData,
      interfacesCount: state.interfaces.interfacesCount
    };
  } catch (e) {
    return {
      error: e,
    };
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Interfaces));
