// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Robot installer download and instructions
  content["Robot installer download and instructions"] =
  "ë¡œë´‡ ì„¤ì¹˜ ê´€ë¦¬ìž� ë‹¤ìš´ë¡œë“œ ë°� ì§€ì¹¨";

  //Robots manage the probes (monitoring agents) used to collect metric data from your systems, sending the data to Infrastructure Management via the relay hub.
  content["Robots manage the probes (monitoring agents) used to collect metric data from your systems, sending the data to Infrastructure Management via the relay hub."] =
  "ë¡œë´‡ì�€ ì‹œìŠ¤í…œì—�ì„œ ë©”íŠ¸ë¦­ ë�°ì�´í„°ë¥¼ ìˆ˜ì§‘í•˜ëŠ” ë�° ì‚¬ìš©ë�˜ëŠ” í”„ë¡œë¸Œ(ëª¨ë‹ˆí„°ë§� ì—�ì�´ì „íŠ¸)ë¥¼ ê´€ë¦¬í•˜ë©° ë¦´ë ˆì�´ í—ˆë¸Œë¥¼ í†µí•´ Infrastructure Managementì—� ë�°ì�´í„°ë¥¼ ë³´ëƒ…ë‹ˆë‹¤.";

  //ROBOT INSTALLERS
  content["ROBOT INSTALLERS"] =
  "ë¡œë´‡ ì„¤ì¹˜ ê´€ë¦¬ìž�";

  //Add Devices
  content["Add Devices"] =
  "ìž¥ì¹˜ ì¶”ê°€";

  //Device IP
  content["Device IP"] =
  "ìž¥ì¹˜ IP";

  //OS
  content["OS"] =
  "OS";

  //Architecture
  content["Architecture"] =
  "ì•„í‚¤í…�ì²˜";

  //Username
  content["Username"] =
  "ì‚¬ìš©ìž� ì�´ë¦„";

  //Password
  content["Password"] =
  "ì•”í˜¸";

  //Sudo password (Linux only)
  content["Sudo password (Linux only)"] =
  "Sudo ì•”í˜¸(Linuxë§Œ í•´ë‹¹)";

  //AUTO DEPLOY
  content["AUTO DEPLOY"] =
  "ìž�ë�™ ë°°í�¬";

  //MANUAL DEPLOY
  content["MANUAL DEPLOY"] =
  "ì§�ì ‘ ë°°í�¬";

  //"Deploy" starts the processes of adding devices to UIM.  Enabling monitoring happens on the next step.
  content["Deploy starts the processes of adding devices to UIM.  Enabling monitoring happens on the next step."] =
  "ë°°í�¬ì—�ì„œ ìž¥ì¹˜ë¥¼ UIMì—� ì¶”ê°€í•˜ëŠ” í”„ë¡œì„¸ìŠ¤ë¥¼ ì‹œìž‘í•©ë‹ˆë‹¤. ë‹¤ì�Œ ë‹¨ê³„ì—�ì„œ ëª¨ë‹ˆí„°ë§�ì�„ í™œì„±í™”í•©ë‹ˆë‹¤.";

  //Click deploy to install the monitoring host.  When complete, click Next.
  content["Click deploy to install the monitoring host.  When complete, click Next."] =
  "ëª¨ë‹ˆí„°ë§� í˜¸ìŠ¤íŠ¸ë¥¼ ì„¤ì¹˜í•˜ë ¤ë©´ ë°°í�¬ë¥¼ í�´ë¦­í•©ë‹ˆë‹¤.  ì™„ë£Œë�˜ë©´ ë‹¤ì�Œì�„ í�´ë¦­í•˜ì‹­ì‹œì˜¤.";

  //Deploy
  content["Deploy"] =
  "ë°°í�¬";

  //Devices added must be able to communicate with the selected relay hub.
  content["Devices added must be able to communicate with the selected relay hub."] =
  "ì¶”ê°€ë�œ ìž¥ì¹˜ëŠ” ì„ íƒ�ë�œ ë¦´ë ˆì�´ í—ˆë¸Œì™€ í†µì‹ í•  ìˆ˜ ìžˆì–´ì•¼ í•©ë‹ˆë‹¤.";

  //Ports 48000 to 48025 must be open
  content["Ports 48000 to 48025 must be open"] =
  "í�¬íŠ¸ 48000 - 48025ê°€ ì—´ë ¤ ìžˆì–´ì•¼ í•©ë‹ˆë‹¤.";

  //for the relay hub to communicate with the CA Infrastructure Management application.
  content["for the relay hub to communicate with the CA Infrastructure Management application."] =
  "ë¦´ë ˆì�´ í—ˆë¸Œê°€ CA Infrastructure Management ì�‘ìš© í”„ë¡œê·¸ëž¨ê³¼ í†µì‹ í•˜ê¸° ìœ„í•´";

  //If monitoring cloud-based infrastructure, a relay hub is needed for each isolated virtual network (such as AWS Virtual Private Cloud or Azure Virtual Network).
  content["If monitoring cloud-based infrastructure, a relay hub is needed for each isolated virtual network (such as AWS Virtual Private Cloud or Azure Virtual Network)."] =
  "í�´ë�¼ìš°ë“œ ê¸°ë°˜ ì�¸í”„ë�¼ë¥¼ ëª¨ë‹ˆí„°í•˜ëŠ” ê²½ìš° ë¶„ë¦¬ë�œ ê°� ê°€ìƒ� ë„¤íŠ¸ì›Œí�¬(ì˜ˆ: AWS ê°€ìƒ� ê°œì�¸ í�´ë�¼ìš°ë“œ ë˜�ëŠ” Azure ê°€ìƒ� ë„¤íŠ¸ì›Œí�¬)ì—� ë¦´ë ˆì�´ í—ˆë¸Œê°€ í•„ìš”í•©ë‹ˆë‹¤.";

  //Select relay hub
  content["Select a relay hub"] =
  "ë¦´ë ˆì�´ í—ˆë¸Œ ì„ íƒ�";

  //If you are not installing with root access, use either sudo
  content["If you are not installing with root access, use either sudo"] =
  "ë£¨íŠ¸ ì•¡ì„¸ìŠ¤ ê¶Œí•œìœ¼ë¡œ ì„¤ì¹˜ ì¤‘ì�´ì§€ ì•Šì�„ ë•ŒëŠ” sudoë¥¼ ì‚¬ìš©í•˜ê±°ë‚˜";

  //You can also use su to get a root shell and execute the command.
  content["You can also use su to get a root shell and execute the command."] =
  "suë¥¼ ì‚¬ìš©í•˜ì—¬ ë£¨íŠ¸ ì…¸ì�„ íš�ë“�í•˜ì—¬ ëª…ë ¹ì�„ ì‹¤í–‰í•  ìˆ˜ë�„ ìžˆìŠµë‹ˆë‹¤.";

  //Copy the appropriate Windows installer to any folder on the target system:
  content["Copy the appropriate Windows installer to any folder on the target system:"] =
  "ëŒ€ìƒ� ì‹œìŠ¤í…œì�˜ ìž„ì�˜ í�´ë�”ì—� ì �ì ˆí•œ Windows ì„¤ì¹˜ ê´€ë¦¬ìž� ë³µì‚¬:";

  //Copy the nms-robot-vars.cfg answer file to the same folder.
  content["Copy the nms-robot-vars.cfg answer file to the same folder."] =
  "nms-robot-vars.cfg ì�‘ë‹µ íŒŒì�¼ì�„ ë�™ì�¼í•œ í�´ë�”ì—� ë³µì‚¬í•©ë‹ˆë‹¤.";

  //Install the robot by executing:
  content["Install the robot by executing:"] =
  "ë‹¤ì�Œì�„ ì‹¤í–‰í•˜ì—¬ ë¡œë´‡ ì„¤ì¹˜:";

  //Installation
  content["Installation"] =
  "ì„¤ì¹˜";

  //Robot Installers (including answer file)
  content["Robot Installers (including answer file)"] =
  "ë¡œë´‡ ì„¤ì¹˜ ê´€ë¦¬ìž�(ì�‘ë‹µ íŒŒì�¼ í�¬í•¨)";

  //The installation is complete. To start the robot, execute the following command:
  content["The installation is complete. To start the robot, execute the following command:"] =
  "ì„¤ì¹˜ê°€ ì™„ë£Œë�˜ì—ˆìŠµë‹ˆë‹¤. ë¡œë´‡ì�„ ì‹œìž‘í•˜ë ¤ë©´ ë‹¤ì�Œ ëª…ë ¹ì�„ ì‹¤í–‰í•˜ì‹­ì‹œì˜¤.";

  //Execute the RobotConfigurer.sh script to configure the robot when the installer exits.
  content["Execute the RobotConfigurer.sh script to configure the robot when the installer exits."] =
  "RobotConfigurer.sh ìŠ¤í�¬ë¦½íŠ¸ë¥¼ ì‹¤í–‰í•˜ì—¬ ì„¤ì¹˜ ê´€ë¦¬ìž�ê°€ ìžˆëŠ” ê²½ìš° ë¡œë´‡ì�„ êµ¬ì„±í•©ë‹ˆë‹¤.";

  //The rpm flags function as follows:
  content["The rpm flags function as follows:"] =
  "RPMì�€ í•¨ìˆ˜ë¥¼ ë‹¤ì�Œê³¼ ê°™ì�´ í”Œëž˜ê·¸ ì§€ì •:";

//The default install directory is /opt/nimsoft. To specify the installation directory, execute the following command, where <directory> is the full path:
  content["The default install directory is /opt/nimsoft. To specify the installation directory, execute the following command, where <directory> is the full path:"] =
  "ê¸°ë³¸ ì„¤ì¹˜ ë””ë ‰í„°ë¦¬ëŠ” &#x2F;opt&#x2F;nimsoftìž…ë‹ˆë‹¤. ì„¤ì¹˜ ë””ë ‰í„°ë¦¬ë¥¼ ì§€ì •í•˜ë ¤ë©´ ë‹¤ì�Œ ëª…ë ¹ì�„ ì‹¤í–‰í•˜ì‹­ì‹œì˜¤(&lt;directory&gt;ëŠ” ì „ì²´ ê²½ë¡œ).";

//Copy the appropriate Linux installer to /opt on the target system:
  content["Copy the appropriate Linux installer to /opt on the target system:"] =
  "ëŒ€ìƒ� ì‹œìŠ¤í…œì�˜ &#x2F;optì—� ì �ì ˆí•œ Linux ì„¤ì¹˜ ê´€ë¦¬ìž� ë³µì‚¬:";

  //Copy the nms-robot-vars.cfg answer file to &#x2F;opt.
  content["Copy the nms-robot-vars.cfg answer file to &#x2F;opt."] =
  "nms-robot-vars.cfg ì�‘ë‹µ íŒŒì�¼ì�„ &#x2F;optì—� ë³µì‚¬í•©ë‹ˆë‹¤.";

  //Install the robot with the following command, where &lt;arch&gt;is i386 or x86_64:
  content["Install the robot with the following command, where <arch> is i386 or x86_64:"] =
  "ë‹¤ì�Œ ëª…ë ¹ìœ¼ë¡œ ë¡œë´‡ ì„¤ì¹˜(ì—¬ê¸°ì„œ &lt;arch&gt;ëŠ” i386 ë˜�ëŠ” x86_64):";

//The default install folder is C:\Program Files (x86)\Nimsoft for 32-bit systems, and C:\Program Files\Nimsoft for 64-bit systems.
  content["The default install folder is C:\\Program Files (x86)\\Nimsoft for 32-bit systems, and C:\\Program Files\\Nimsoft for 64-bit systems."] =
  "ê¸°ë³¸ ì„¤ì¹˜ ë””ë ‰í„°ë¦¬ëŠ” C:\\Program Files (x86)\\Nimsoft(32ë¹„íŠ¸ ì‹œìŠ¤í…œ) ë°� C:\\Program Files\\Nimsoft(64ë¹„íŠ¸ ì‹œìŠ¤í…œ)ìž…ë‹ˆë‹¤.";

  //To specify the folder, append the following parameter to the command. Quotation marks are required.
  content["To specify the folder, append the following parameter to the command. Quotation marks are required."] =
  "í�´ë�”ë¥¼ ì§€ì •í•˜ë ¤ë©´ ëª…ë ¹ì—� ë‹¤ì�Œ ë§¤ê°œ ë³€ìˆ˜ë¥¼ ì¶”ê°€í•˜ì‹­ì‹œì˜¤. ë”°ì˜´í‘œëŠ” í•„ìˆ˜ìž…ë‹ˆë‹¤.";

  //To specify the log file, append:
  content["To specify the log file, append:"] =
  "ë¡œê·¸ íŒŒì�¼ì�„ ì§€ì •í•˜ë ¤ë©´ ë‹¤ì�Œì�„ ì¶”ê°€:";

  //After installation, the robot starts automatically.
  content["After installation, the robot starts automatically."] =
  "ì„¤ì¹˜ í›„ ë¡œë´‡ì�´ ìž�ë�™ìœ¼ë¡œ ì‹œìž‘ë�©ë‹ˆë‹¤.";

//Copy the Debian installer to /opt on the target system:
  content["Copy the Debian installer to /opt on the target system:"] =
  "ëŒ€ìƒ� ì‹œìŠ¤í…œì�˜ &#x2F;optì—� Debian ì„¤ì¹˜ ê´€ë¦¬ìž� ë³µì‚¬:";

  //Note that only 64 bit Debian systems are supported as robots.
  content["Note that only 64 bit Debian systems are supported as robots."] =
  "64ë¹„íŠ¸ Debian ì‹œìŠ¤í…œë§Œ ë¡œë´‡ìœ¼ë¡œ ì§€ì›�ë�©ë‹ˆë‹¤.";

  //Install the robot with the following command:
  content["Install the robot with the following command:"] =
  "ë‹¤ì�Œ ëª…ë ¹ì�„ ì‚¬ìš©í•˜ì—¬ ë¡œë´‡ ì„¤ì¹˜:";

//Copy the Ubuntu installer to /opt on the target system:
  content["Copy the Ubuntu installer to /opt on the target system:"] =
  "ëŒ€ìƒ� ì‹œìŠ¤í…œì�˜ &#x2F;optì—� Ubuntu ì„¤ì¹˜ ê´€ë¦¬ìž� ë³µì‚¬:";

  //Note that only 64 bit Ubuntu systems are supported as robots.
  content["Note that only 64 bit Ubuntu systems are supported as robots."] =
  "64ë¹„íŠ¸ Ubuntu ì‹œìŠ¤í…œë§Œ ë¡œë´‡ìœ¼ë¡œ ì§€ì›�ë�©ë‹ˆë‹¤.";

  //(Ubuntu 16 only) Execute the following command to enable the robot service. Note: This step is not required for Ubuntu 14.
  content["(Ubuntu 16 only) Execute the following command to enable the robot service. Note: This step is not required for Ubuntu 14."] =
  "(Ubuntu 16ì—�ë§Œ í•´ë‹¹) ë¡œë´‡ ì„œë¹„ìŠ¤ë¥¼ í™œì„±í™”í•˜ë ¤ë©´ ë‹¤ì�Œ ëª…ë ¹ì�„ ì‹¤í–‰í•©ë‹ˆë‹¤. ì°¸ê³ : Ubuntu 14ì—�ì„œëŠ” ì�´ ë‹¨ê³„ê°€ í•„ìš”í•˜ì§€ ì•ŠìŠµë‹ˆë‹¤.";

  // END OF LOCALIZATION

export default content;
