import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  Button,
  FormControl,
  Select,
  MenuItem,Tooltip,InputLabel
} from "@mineral/core";
import { Close as CloseIcon } from "@material-ui/icons";
import "./QosExportPdfDialog.less";
import axios from "axios";
import { useSelector } from "react-redux";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import {
  EXPORT_AS_XML,
  EXPORT_AS_PDF,
  EXPORT_XML,
  EXPORT_PDF,
  EXPORT_PREFERENCES,
  EXPROT_TXT,
  ORIENTATION,
  SIZE,
  CANCEL,
  LANDSCAPE,
  PORTRAIT,
  A0,
  A1,
  A2,
  A3,
  A4,
  A5,
  LEGAL,
  LETTER,
  TABLOID,
} from "./QosExportDialogConstants";
export const exportOptions = [EXPORT_AS_XML, EXPORT_AS_PDF];
export const orientationOptions = [LANDSCAPE, PORTRAIT];
export const sizeOptions = [A0, A1, A2, A3, A4, A5, LEGAL, LETTER, TABLOID];

const QosExportPdfDialog = (props) => {
  const [exportType, setExportType] = React.useState(EXPORT_AS_XML);
  const [orientationType, setOrientationType] = React.useState(LANDSCAPE);
  const [sizeType, setSizeType] = React.useState(A4);
  const [showExportSuccess, setShowExportSuccess] = React.useState(false);
  const [showExportFailure, setShowExportFailure] = React.useState(false);
  const qosTitle = useSelector(
    (state) => state.performanceReports.chartData.State.title
  );
  const QosPath = useSelector(
    (state) => state.performanceReports.chartData.State.path
  );
  const QosTimeStart = useSelector(
    (state) => state.performanceReports.chartData.State.TimeStart
  );
  const QosTimeStop = useSelector(
    (state) => state.performanceReports.chartData.State.TimeStop
  );
  const QosAggLevel = useSelector(
    (state) => state.performanceReports.chartData.State.aggregationInterval
  );
  const sid = useSelector((state) => state.sid);
  const isSaved = useSelector((state) => state.performanceReports.saved);
  const exportOptions = isSaved
    ? [EXPORT_AS_XML, EXPORT_AS_PDF]
    : [EXPORT_AS_XML];

  const onChangeExportType = (event) => {
    setExportType(event.target.value);
  };
  const onChangeOrientationType = (event) => {
    setOrientationType(event.target.value);
  };
  const onChangeSizeType = (event) => {
    setSizeType(event.target.value);
  };
  const exportBtn = exportType === EXPORT_AS_XML ? EXPORT_XML : EXPORT_PDF;

  const exportAsXml = () => {
    props.xml();
    setShowExportSuccess(true);
  };

  const exportData = () => {
    if (exportType === EXPORT_AS_XML) exportAsXml();
    else exportAsPdf();

    props.handleExportDialogClose();
  };

  const getSizeCode = (sizeType) => {
    let sizeCode = "";
    switch (sizeType) {
      case A0:
        sizeCode = 0;
        break;
      case A1:
        sizeCode = 1;
        break;
      case A2:
        sizeCode = 2;
        break;
      case A3:
        sizeCode = 3;
        break;
      case A4:
        sizeCode = 4;
        break;
      case A5:
        sizeCode = 5;
        break;
      case LETTER:
        sizeCode = 40;
        break;
      case LEGAL:
        sizeCode = 41;
        break;
      case TABLOID:
        sizeCode = 44;
        break;
      default:
        sizeCode = 4;
        break;
    }

    return sizeCode;
  };

  const generatePdf = (
    sid,
    report,
    startTime,
    stopTime,
    aggregationInterval,
    mediaSizeCode,
    orientationRequestedCode
  ) => {
    let url = `${window.location.protocol}//${window.location.host}/reportscheduler/api/v1/report/prdReport`;
    //let url = 'http://10.252.25.208:8080/castlemock/mock/rest/project/wvRpVi/application/b0tUPU/prdReport';
    let viewName = "";

    return axios({
      url,
      method: "post",
      params: {
        report,
        startTime,
        stopTime,
        aggregationInterval,
        mediaSizeCode,
        orientationRequestedCode,
      },
      headers: { ocAuthorizationToken: sid },
      responseType: "blob",
    });
  };

  const exportAsPdf = () => {
    let orientationCode = orientationType === PORTRAIT ? 3 : 4;
    let sizeCode = getSizeCode(sizeType);
    let reportPath = encodeURI(QosPath);
    let reqBody = {
      report: QosPath,
      startTime: QosTimeStart,
      stopTime: QosTimeStop,
      aggregationInterval: QosAggLevel,
      mediaSizeCode: sizeCode,
      orientationRequestedCode: orientationCode,
    };

    generatePdf(
      sid,
      reportPath,
      QosTimeStart,
      QosTimeStop,
      QosAggLevel,
      sizeCode,
      orientationCode
    )
      .then((response) => {
        const url = window.URL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${qosTitle}.pdf`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setShowExportSuccess(true);
      })
      .catch((err) => {
        console.log("error in pdf", err);
        setShowExportFailure(true);
      });
  };

  return (
    <>
    <Dialog
      className="qos-export-pdf-dialog"
      open={props.open}
      fullWidth={true}
      maxWidth={"sm"}
      onClose={() => props.handleExportDialogClose()}
    >
     <DialogTitle component="h1" disableTypography style={{marginRight: "25px"}}>
          
          <Typography variant="h3" component="div">
       {EXPORT_PREFERENCES}
        </Typography>
      </DialogTitle>
      <div style={{height:'0px'}}>
      <Tooltip title="Close">
      <IconButton style={{float:'right',top:'-50px',marginRight:'8px'}}
          aria-label="close" autoFocus
          onClick={() => props.handleExportDialogClose()}
        >
          <CloseIcon />
        </IconButton>
        </Tooltip>
        </div>
      <DialogContent dividers={true}>
        
       
          <FormControl fullWidth={true} style={{marginTop:'8px'}}>
          <InputLabel id="export-type-select-label" htmlFor="export-type-select">{EXPROT_TXT}</InputLabel>
            <Select
              id="export-type-select"
              labelId="export-type-select-label"
              value={exportType}
              onChange={onChangeExportType}
              fullWidth
              role="combobox" 
              aria-label={EXPROT_TXT}
     aria-labelledby="export-type-select-label"
              inputProps={{'aria-label':{EXPROT_TXT},
                           role: 'combobox',}}
            MenuProps={{ MenuListProps:{
                 'aria-label': `Export options`,
                 'aria-labelledby' : "export-type-select-label",
       
       title:EXPROT_TXT
               }}}
            >
              {exportOptions.map((source, index) => (
                <MenuItem
                  
                  key={`${source}-${index}-export-type`}
                  value={source}
                >
                  {source}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        
          {exportType !== EXPORT_AS_XML?<>
         
            <FormControl fullWidth={true} style={{marginTop:'8px'}}>
            <InputLabel id="orientation-type-type-select-label" htmlFor="orientation-type-type-select">{ORIENTATION}</InputLabel>
              <Select
                id="orientation-type-type-select"
                labelId="orientation-type-type-select-label"
                aria-label={ORIENTATION}
     aria-labelledby="orientation-type-type-select-label"
												role='combobox'
												inputProps={{'aria-label':{ORIENTATION},
                           role: 'combobox',}}
                value={orientationType}
                onChange={onChangeOrientationType}
                fullWidth   MenuProps={{ MenuListProps:{
                 'aria-label': `Orientation options list`,
                 'aria-labelledby' : "orientation-type-type-select-label",
       
         title:ORIENTATION
               }}}
            >
                {orientationOptions.map((source, index) => (
                  <MenuItem
                    
                    key={`${source}-${index}-orientation-type`}
                    value={source}
                  >
                    {source}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          
            <FormControl fullWidth={true} style={{marginTop:'8px'}}>
            <InputLabel id="size-type-type-select-label" htmlFor="size-type-type-select">{SIZE}</InputLabel>
           
              <Select
                id="size-type-type-select"
               fullWidth
               aria-label={SIZE}
     aria-labelledby="size-type-type-select-label"
                value={sizeType}
                onChange={onChangeSizeType}
                autoWidth
                labelId="size-type-type-select-label"
												
												role='combobox'
												inputProps={{'aria-label':{SIZE},
                           role: 'combobox',}}
                        MenuProps={{ MenuListProps:{
                 'aria-label': `Size options list`,
                 'aria-labelledby' : "size-type-type-select-label",
       
       title:SIZE
               }}}
              >
                {sizeOptions.map((source, index) => (
                  <MenuItem
                   
                    key={`${source}-${index}-size-type`}
                    value={source}
                  >
                    {source}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            </>:null}
          
        
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.handleExportDialogClose()} variant="text" color="primary">
          {CANCEL}
        </Button>
        <Button
          color="primary"
          variant="contained"
          
          onClick={() => exportData()}
        >
          {exportBtn}
        </Button>
      </DialogActions>
    </Dialog>
    <Snackbar
    open={showExportSuccess}
    autoHideDuration={5000}
    message={"Report Exported successfully"}
    onClose={() => setShowExportSuccess(false)}
  />
   <Snackbar
    open={showExportFailure}
    autoHideDuration={5000}
    onClose={() => setShowExportFailure(false)}
  >
    <Alert severity="error">
          Report Export Failed.
        </Alert>
  </Snackbar>
	 
  </>
  );
};

export default QosExportPdfDialog;
