import React, { Component} from 'react'
import PropTypes from 'prop-types'
import {Select as SelectField} from '@mineral/core'

class SelectFieldInput extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    validate: PropTypes.func,
    onChange: PropTypes.func,
  }
  state = {
    value: this.props.value,
    error: this.props.errorText || false,
  }
  componentWillReceiveProps(nextProps) {
    this.setState({ value: nextProps.value })
  }
  onChange = (evt, index, value) => {
    const name = this.props.name
    const error = this.props.validate ? this.props.validate(value) : false

    this.setState({ value, error })

    this.props.onChange({ name, value, error })
  }
  render() {
    const { validate, ...rest } = this.props
    return (
      <SelectField
        {...rest}
        errorText={this.props.errorText || this.state.error}
        name={this.props.name}
        onChange={this.onChange}
        value={this.state.value}>
        {this.props.children}
      </SelectField>
    )
  }
}
export default SelectFieldInput