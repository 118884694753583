import React from 'react'
import PropTypes from 'prop-types'

const TYPE_ANGLE_RIGHT = 'right'
const DEFAULT_ICON_SIZE = '24px'

const AngleArrowIcon = props => {
  var style = {}
  if (props.size) {
    style.width = props.size
    style.height = props.size
  } else {
    style.width = DEFAULT_ICON_SIZE
    style.height = DEFAULT_ICON_SIZE
  }

  if (props.type === TYPE_ANGLE_RIGHT) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style={style} {...props}>
        <g fill="none" fillRule="evenodd">
          <polygon
            fill="#000000"
            points="11.75 8 19.5 15 18.373 16 11.75 10.001 5.127 16 4 15"
            transform="rotate(90 11.75 12)"
          />
        </g>
      </svg>
    )
  } else {
    return null
  }
}

AngleArrowIcon.propTypes = {
  type: PropTypes.string.isRequired,
  size: PropTypes.string,
}

export default AngleArrowIcon
export { TYPE_ANGLE_RIGHT }
