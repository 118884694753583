import React, {Component} from 'react'
import PropTypes from 'prop-types';
import Resource from './Resource'
import {
  NavLink,
  Route,
} from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import UtilPoller from './../../utils/poller'
import theme from '../ui-components/theme'
import get from 'lodash/get'

const STATUS = {
  SUCCEEDED: 'SUCCEEDED',
  FAILED: 'FAILED',
  IN_PROGRESS: 'IN_PROGRESS',
  TRUE:true,
  FALSE:false
}

const CustomLink = ({to, isActive, children, adeRobotAddress, adeJobIds, ...rest}) => {
  const href = `/robot-deploy?adeRobotAddress=${adeRobotAddress}&adeJobIds=${adeJobIds}`
  const style = isActive() ? {color: theme.palette.primary1Color} : {
    cursor: 'not-allowed',
    opacity: '0.12',
  }
  //FIX ISSUES
  const helplabelmessage = Resource.get('FIX ISSUES')
  return (
    <Route children={({history}) => (
      <NavLink
        to={to}
        isActive={isActive}
        onClick={(e) => {
          e.preventDefault()
          if (isActive()) {
            history.push(href)
          }
        }}
        style={style}
        {...rest}>{helplabelmessage}
        </NavLink>
    )} />
  )
}

class DeploymentStatus extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired
  }

  state = {
    succeeded: this.props.succeeded || 0,
    failed: this.props.failed || 0,
    pending: this.props.pending || 0,
    probe: this.props.probe || {}
  }
  
  _isCallBackEnabled(){
	  if(this.props.probe && this.props.probe.name){
		  const probe =this.props.probe,
		  callbackProbeList=this.props.featureFlags.callbackProbeList?this.props.featureFlags.callbackProbeList:'',
		  callbackEnabled = callbackProbeList.includes(probe.name);
		  return callbackEnabled;
		}
	  else if(this.props.fromSetupWizard && this.props.probesStatus && this.props.probesStatus.length > 0 ){
		  return true;
	  }
	  else{
		  return false;
	  }
  }
  _getRobotStatus(props) {
    const adeRobotAddress = props.adeRobotAddress
    const adeJobIds = props.adeJobIds
    const probe =this.props.probe
    const callbackEnabled = this._isCallBackEnabled();
    if (!adeRobotAddress || isEmpty(adeJobIds)) {
      console.warn('DeploymentStatus - unable to GET robots status without "adeRobotAddress" or "adeJobIds".')
      return this
    }
    if (props.getRobotsStatus) {
      props.getRobotsStatus(adeRobotAddress, adeJobIds,probe,callbackEnabled) 
    }
    if (props.pollRobotsStatus) {
      if (this.poller) {
        this.poller.stop()
      }
      this.poller = UtilPoller(() => props.pollRobotsStatus(adeRobotAddress, adeJobIds,probe,callbackEnabled), {
        timeout: 15*1000,
      })
      this.poller.start()
    }
    return this
  }
  _getRobotCount = (robotsStatus,type)=>{
	    let cnt = 0;
	    robotsStatus.forEach(status => {
	      if (status.state === type) {
	          cnt++;
	      }
	    })
	    
	    return cnt;
  }
  
  _setRobotsStatus = (robotsStatus, cb = () => {}) => {
    let succeeded = 0
    let failed = 0
    let pending = 0
    robotsStatus.forEach(status => {
      switch (status.state) {
        case STATUS.SUCCEEDED:
        	if(this._isCallBackEnabled()){
        		pending++;
        	}
        	else{
        		succeeded++
        	}
          break
        case STATUS.FAILED:
          failed++
          break
        case STATUS.IN_PROGRESS:
    	default:
    		pending++;
    	break;
      }
    })

    if(succeeded !== this.state.succeeded || failed !== this.state.failed || pending !== this.state.pending) {
      this.setState({succeeded, failed, pending}, cb)
    } 
  }
  _setProbesStatus = (probesStatus, cb = () => {}) => {
	    let succeeded = 0;
	    let failed = this._getRobotCount(this.props.robotsStatus,STATUS.FAILED);
	    let pending = this._getRobotCount(this.props.robotsStatus,STATUS.SUCCEEDED);
    	probesStatus.forEach(info => {
	          pending--;
  	      switch (info.status) {
  	        case STATUS.TRUE:
  	          succeeded++;
  	          break
  	        case STATUS.FALSE:
  	          failed++;
  	          break
  	      }
  	    })

	    if(succeeded !== this.state.succeeded || failed !== this.state.failed || pending !== this.state.pending) {
	      this.setState({succeeded, failed, pending}, cb)
	    } 
	  }
  componentWillMount() {
    this._getRobotStatus(this.props)
    if(this.props.robotsStatusfulfilled && this._isCallBackEnabled()){
    	if(!isEmpty(this.props.probesStatus)) {
    	      this._setProbesStatus(this.props.probesStatus)
    	    }
    }
    else{
    	if(!isEmpty(this.props.robotsStatus)) {
  	      this._setRobotsStatus(this.props.robotsStatus)
  	    }
    }
  }
  componentWillReceiveProps(nextProps) {
    const prevState = this.state
    const newAdeRobotAddress = nextProps.adeRobotAddress !== this.props.adeRobotAddress
    const newAdeJobIds = !isEqual(nextProps.adeJobIds, this.props.adeJobIds)
    if (newAdeRobotAddress || newAdeJobIds) {
      // new query params update GET/poll
      this._getRobotStatus(nextProps)
    }
    if(this.props.robotsStatusfulfilled !== nextProps.robotsStatusfulfilled){
    	this.props.getProbesStatus(nextProps.probeJobIds);
    }
    if(nextProps.robotsStatusfulfilled  && this._isCallBackEnabled()){
    	this._setProbesStatus(nextProps.probesStatus, () => {
    	      this._update(prevState, nextProps.probesStatus,true)
	    })
    }
    else{
  	    this._setRobotsStatus(nextProps.robotsStatus, () => {
	      this._update(prevState, nextProps.robotsStatus,false)
	    })
    }
  }
  componentWillUnmount() {
    if (this.poller) {
      this.poller.stop()
    }
  }
  _update = (prevState, statusArr,fromProbes) => {
    const state = this.state
    const noPendingRobots = !state.pending
    const newStatus = (
      state.succeeded !== prevState.succeeded ||
      state.failed !== prevState.failed ||
      state.pending !== prevState.pending
    )
    let succeeded=[],failed=[],robotsFailed=[],pending=[],data = this.props.robotsStatus;
    if(fromProbes){
    	succeeded = statusArr.filter(info => info.status === STATUS.TRUE);
        failed = statusArr.filter(info => info.status === STATUS.FALSE);
        //TODO: Recheck - remap probes data to robots status data based on probes status
        let i,len,probeFailRobotsIds=[],info;
 	   	if(failed.length >0){
 		   len = failed.length;
 		   for(i=0;i<len;i++){
 			   this.props.probeJobIds.forEach(function(item,index,arr){
 				   if(item.fromError === true || item.jobId === failed[i].jobId){
 					  probeFailRobotsIds.push(item.robotInfo.adejobID);
 				   }
 			   })
 		   }
 		   if(probeFailRobotsIds.length>0){
 			   data=[];
 			  this.props.robotsStatus.forEach(function(item,index,arr){
 				  if(probeFailRobotsIds.indexOf(item.adejobID) !== -1){
 					  info = Object.assign({},item,{state:STATUS.FAILED});
 				  }
 				  else{
 					 info = Object.assign({},item);
 				  }
 				 data.push(info);
 			  })
 		   }
 		   
 	   }
 	   failed = data.filter(status => status.state === STATUS.FAILED);
    }
    else{
        succeeded = statusArr.filter(status => status.state === STATUS.SUCCEEDED);
        failed = statusArr.filter(status => status.state === STATUS.FAILED);
        pending = statusArr.filter(status => status.state === STATUS.IN_PROGRESS);
        if(this._isCallBackEnabled()){
        	pending =  pending.concat(succeeded);
        	succeeded =[];
        }
    }

    if (newStatus && this.props.onStatusChange) {
      this.props.onStatusChange({
        succeeded: succeeded,
        failed: failed,
        pending: pending
      })
    }

    if (newStatus && noPendingRobots && !isEmpty(this.props.robotsStatus) && this.props.onComplete) {
      // robot deployment is done stop polling
       if (this.poller) {
        this.poller.stop()
      }
       
       if(this._isCallBackEnabled()){
    	   if(this.props.probesStatus && this.props.probesStatus.length === 0){
    		   return;
    	   }
       }
      this.props.onComplete({
        robotsStatus: data,
        succeeded: succeeded,
        failed: failed,
      })
    }
  }
  render() {
    const {
      succeeded,
      failed,
      pending,
    } = this.state
    		//device
    		  const helpvariable = Resource.get('device')
              //devices
              const helpvariable1 = Resource.get('devices')
              //LasrDeployment message
              const helplabelmessage = Resource.get('Last Deployment')
    return (
      <div className="setup-wizard__deployment-status-overview">
        <div className="setup-wizard__deployment-status-summary">
          <span>{helplabelmessage}
            <span style={{
              marginLeft: 35,
              marginRight: 5,
              fontWeight: 500,
              fontSize: 20
            }}>
              {this.props.robotsStatus.length}
            </span>
                      
            {this.props.robotsStatus.length === 1 ? helpvariable:helpvariable1}
          </span>
        </div>
        <div className="setup-wizard__deployment-status-statuses">
          <div className="setup-wizard__deployment-status">
            <span className="setup-wizard__deployment-status--pending">{pending} </span><span style={{color:'#000000'}} > {//pending
            	Resource.get('pending')}</span>
            
          </div>
          <div className="setup-wizard__deployment-status">
            <span className="setup-wizard__deployment-status--successful">{succeeded} </span><span style={{color:'#000000'}}> {//succeeded
            	Resource.get('succeeded')}</span>
            
          </div>
          <div className="setup-wizard__deployment-status">
            <span className="setup-wizard__deployment-status--failed">{failed} </span><span style={{color:'#000000'}}> {// failed
            	Resource.get('failed')}</span>
          </div>
          {this.props.fixLink ? (
            <CustomLink
              to={'/robot-deploy'}
              isActive={() => !pending && !!failed}
              adeRobotAddress={this.props.adeRobotAddress || this.props.reduxAdeRobotAddress}
              adeJobIds={!isEmpty(this.props.adeJobIds) ? this.props.adeJobIds : this.props.reduxAdeJobIds} />
          ) : null}
        </div>
      </div>
    )
  }
}

DeploymentStatus.defaultProps = {
  fixLink: true,
}

export default DeploymentStatus