import React, { Component } from 'react'
import { Dialog, Button as FlatButton } from '@mineral/core'
import ComponentInfo from './../../resources/ComponentInfo'
import Resource from './Resource'

export default class ComponentInfoDialog extends Component {
  handleClose() {
    this.props.handleClose(false)
  }

  render() {
    const actions = [
      <FlatButton
        variant="text"
        //Close
        children= {Resource.get('Close')}
        color ="primary"
        onClick={this.handleClose.bind(this)}
      />,
    ]
    //Client:
    const ClientLabel = Resource.get('Client:')
    //Branch:
    const BranchLabel = Resource.get('Branch:')
    //Rev:
    const RevLabel = Resource.get('Rev:')
    //Date:
    const DateLabel = Resource.get('Date:')
    //Server/API:
    const ServerApiLabel = Resource.get('Server/API:')
    //

    return (
      <Dialog actions={actions} open={this.props.isVisible} modal={false}>
        <div style={{ display: 'flex', fontFamily: 'monospace' }}>
          <span
            style={{ width: '100px', marginRight: '10px', textAlign: 'right' }}>
            {ClientLabel}
          </span>
          <span>
            <b>
              {ComponentInfo.COMPONENT_NAME} v{ComponentInfo.VERSION}.{ComponentInfo.BUILD_NUMBER}
            </b>
            <br />
            {BranchLabel} {ComponentInfo.BRANCH}
            <br />
            {RevLabel} {ComponentInfo.COMMIT_ID}
            <br />
            {DateLabel} {ComponentInfo.BUILD_DATE}
          </span>
        </div>
        <div
          style={{
            display: 'flex',
            fontFamily: 'monospace',
            marginTop: '10px',
          }}>
          <span
            style={{ width: '100px', marginRight: '10px', textAlign: 'right' }}>
          {ServerApiLabel}
          </span>
          <span>
            <b>
              {this.props.apiInfo.componentName} v{this.props.apiInfo.version}.{this.props.apiInfo.buildNumber}
            </b>
            <br />
            {BranchLabel} {this.props.apiInfo.branch}
            <br />
            {RevLabel} {this.props.apiInfo.commitId}
            <br />
            {DateLabel} {this.props.apiInfo.buildDate}
            <br />
          </span>
        </div>
      </Dialog>
    )
  }
}
