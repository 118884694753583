// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Error help message
  content["Deployment failed. This may be due to an inability to communicate with the target device."] =
  "展開に失敗しました。これは、ターゲット デバイスと通信できないことが原因である可能性があります。";

  //Robot deploy error message
  content["To troubleshoot, verify your network connection or firewall settings on the host."] =
  "トラブルシューティングを行うには、ホスト上のネットワーク接続またはファイアウォール設定を確認します。";

  //FIX ISSUES
  content["FIX ISSUES"] =
  "問題の修正";

  //Deploymentfailed message
  content["Deployment failed"] =
  "展開に失敗しました";

  // Confirmation check message
  content["Confirm your target device details and re-deploy."] =
  "ターゲット デバイスの詳細を確認し、再展開します。";

  //Deployment progress message
  content["Deployment is in progress."] =
  "展開中。";

  //help message
  content["When your installation is complete, click NEXT to continue."] =
  "インストールが完了したら、[次へ] をクリックして続行します。";

  //Deployment status message
  content["Deployment was successful"] =
  "展開が正常に終了しました";

  //NEXT help message
  content["Click NEXT below to finish your monitoring setup."] =
  "モニタリング セットアップを終了するには、下の [次へ] をクリックします。";

  //A Message
  content["A Message"] =
  "メッセージ";

  // You are great
  content["You are great."] =
  "すばらしい。";

  // END OF LOCALIZATION

export default content;
