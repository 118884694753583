import React from 'react'
import './icons.less'

const Icon = (props) => {
  let classes = [
    'icon',
    props.className,
  ].join(' ')
  return (
    <svg className={classes} role={props.role || 'figure'} aria-label="[title]" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g fill="#5CBA49" fillRule="evenodd">
        <title>{props.title}</title>
        <path d="M11.99,2 C6.47,2 2,6.48 2,12 C2,17.52 6.47,22 11.99,22 C17.52,22 22,17.52 22,12 C22,6.48 17.52,2 11.99,2 Z M12,20 C7.58,20 4,16.42 4,12 C4,7.58 7.58,4 12,4 C16.42,4 20,7.58 20,12 C20,16.42 16.42,20 12,20 Z"/>
        <path d="M13.3000002,13.8033009 L9.30330086,13.8033009 L9.30330086,15.8033009 L15.3033009,15.8033009 L15.3033009,13.8033009 L15.3000002,13.8033009 L15.3000002,5.80000019 L13.3000002,5.80000019 L13.3000002,13.8033009 Z" transform="rotate(45 12.303 10.802)"/>
      </g>
    </svg>
  )
}

export default Icon
