import React, { Component } from 'react'
import ComputerSystems from '../../../api/computer-systems/computerSystems'
import {
  Grid,
  FilterIcon,  
  Paginate,
} from '../../ui-components/uim-components'
import { withRouter } from 'react-router-dom'
import {TextField,Button as  RaisedButton} from "@mineral/core"
import '../settings.less'

import orderBy from 'lodash/orderBy'
import filter from 'lodash/filter'
import { connect } from 'react-redux'
import { setRestoredSnackVisible } from '../../../api/settings/actions'
import { setBreadCrumbs } from './../../breadcrumbs/actions'
import Resource from './Resource'

const labelColor= {color : "#00AEEF"}

class RestoreDeletedDevices extends Component {
  constructor(props) {
    super(props)
    this.numPages = 0
    this.pageLimit = 10
    this.state = {
      pageNumber: 0,
      filterVal: '',
      deletedDevices: null,
      items: null,
      rowData: null,
      disableRestoreButton: true,
      tooltipShowDelay: 0
    }

    this.colDefs = [
      {
        headerName: '',
        field: 'checkbox',
        checkboxSelection: true,
        headerCheckboxSelection: true,
        suppressMenu: true,
        suppressSorting: true,
        suppressResize: true,
        suppressSizeToFit: true,
        width: 50,
      },
      {
    	//Name
        headerName: Resource.get('Name'),
        field: 'name',
        tooltipField: 'name',
        minWidth: 50,
        headerComponentParams: {
          columnSorting: this.sortColumns,
        },
      },
      { //IP address
        headerName: Resource.get('IP address'),
        field: 'ipAddresses.0',
        valueGetter: this.makeIPsACommaSeparatedList,
        tooltipField: 'ipAddresses.0',
        minWidth: 50,
        headerComponentParams: {
          columnSorting: this.sortColumns,
        },
      },
      { //Role
        headerName: Resource.get('Role'),
        field: 'role',
        tooltipField: 'role',
        minWidth: 50,
        headerComponentParams: {
          columnSorting: this.sortColumns,
        },
      },
      { //OS Type
        headerName: Resource.get('OS Type'),
        field: 'osType',
        tooltipField: 'osType',
        minWidth: 50,
        headerComponentParams: {
          columnSorting: this.sortColumns,
        },
      },
      { //OS Name
        headerName: Resource.get('OS Name'),
        field: 'osName',
        tooltipField: 'osName',
        minWidth: 50,
        headerComponentParams: {
          columnSorting: this.sortColumns,
        },
      },
      { //OS Version
        headerName: Resource.get('OS Version'),
        field: 'osVersion',
        tooltipField: 'osVersion',
        minWidth: 50,
        headerComponentParams: {
          columnSorting: this.sortColumns,
        },
      },
    ]
  }
  sortColumns = (column, order) => {
    let newSortedData = null
    if (column === 'name') {
      newSortedData = orderBy(
        this.state.items,
        [
          function(o) {
            return o.name.toLowerCase()
          },
        ],
        order,
      )
    } else {
      newSortedData = orderBy(this.state.items, column, order)
    }
    this.deselectAll()
    this.setState({
      deletedDevices: newSortedData,
      items: newSortedData,
      rowData: this.getRowData(newSortedData, 0),
      pageNumber: 0,
    })
  }

  getRowData(items, pageNumber) {
    let myPageNumber = this.state.pageNumber
    if (pageNumber !== undefined) {
      myPageNumber = pageNumber
    }
    const startIndex = myPageNumber * this.pageLimit
    return items.slice(startIndex, startIndex + this.pageLimit)
  }

  componentWillMount() {
    let breadCrumbs = [
      {//Settings
        name: Resource.get('Settings'),
        link: '/settings',
      },
      {//Deleted Devices
        name: Resource.get('Deleted Devices'),
        link: '/settings/infrastructure/deleted-devices',
      },
    ]
    this.props.setBreadCrumbs(breadCrumbs)
  }

  componentDidMount() {
    ComputerSystems.getDeletedDevices()
      .then(response => {
        this.setState({
          deletedDevices: response.data._items,
          items: response.data._items,
          rowData: this.getRowData(response.data._items),
        })
      })
      .catch(error => {
          //console.log(error)
        }
      )
  }

  onGridReady(params) {
    this.api = params.api
    this.api.sizeColumnsToFit()
    if (this.props.onGridReady) {
      this.props.onGridReady(params)
    }
  }
  selectionChanged = () => {
    const selectedRows = this.api.getSelectedRows()
    const ids = selectedRows.map(row => row.id)

    this.selectedIds = ids
    this.setState({ disableRestoreButton: ids.length < 1 })
  }

  onKeyUp = evt => {
    if (evt.which === 27) {
      evt.target.value = ''
    }

    const filterVal = evt.target.value
    let filteredItems = filter(this.state.deletedDevices, item => {
      const stringToCheckAgainst = item.name + ' ' + item.ipAddresses.join(' ')
      return (
        stringToCheckAgainst.toLowerCase().indexOf(filterVal.toLowerCase()) >= 0
      )
    })
    this.deselectAll()
    this.selectedIds = []
    this.setState({
      items: filteredItems,
      pageNumber: 0,
      rowData: this.getRowData(filteredItems),
    })
  }

  handleCancel = () => {
    this.props.history.push('/settings')
  }

  handleSave = () => {
    ComputerSystems.undelete(this.selectedIds)
      .then(response => {
        this.props.setRestoredSnackVisible(true)
        this.props.history.push('/settings')
      })
      .catch(error => {
        // TODO handle the error case
        //console.log('error undeleting devices')
      })
  }

  deselectAll = () => {
    this.api.deselectAll()
    this.selectedIds = []
  }
  handlePageClick = page => {
    this.deselectAll()
    this.setState({
      pageNumber: page.selected,
      rowData: this.getRowData(this.state.items, page.selected),
    })
  }

  render() {
    if (this.state.items === null) {
      this.heightOfDiv = this.pageLimit * 60 + 60
      return null
    }

    this.numPages = Math.ceil(this.state.items.length / this.pageLimit)

    return (
      <main>
        <div
          className="ag-material tablecontainer"
          style={{ height: this.heightOfDiv + 'px', width: '94vw' }}>
          <div className="addDevice__filterBox">
            <FilterIcon className="addDevice__filterBox--icon" />
            <TextField
            //Filter
              placeholder={Resource.get("Filter")}
              name="q"
              onKeyUp={evt => this.onKeyUp(evt)}
            />
          </div>
          <Grid
            rowData={this.state.rowData}
            columnDefs={this.colDefs}
            onGridReady={this.onGridReady.bind(this)}
            onSelectionChanged={this.selectionChanged.bind(this)}
            rowSelection="multiple"
            tooltipShowDelay={this.state.tooltipShowDelay}
          />
          <div className="pagination">
            {this.numPages > 1 ? (
              <Paginate
                pageCount={this.numPages}
                onPageChange={this.handlePageClick}
              />
            ) : null}
          </div>
          <div className="settings__footer">
            <div className="settings__footer-left" />
            <div className="settings__footer-right">
              {/* Hard coded color. This should be handled by the theme at some point. */}
              <RaisedButton
              variant="contained"                
                classes={{label:labelColor}} 
                //Cancel
                children= {Resource.get('Cancel')}                
                onClick={this.handleCancel}
              />

              <RaisedButton
              variant="contained"
                //Restore
                children= {Resource.get('Restore')}
               color ="primary"
                onClick={this.handleSave}
                disabled={this.state.disableRestoreButton}
              />
            </div>
          </div>
        </div>
      </main>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setRestoredSnackVisible: visible =>
      dispatch(setRestoredSnackVisible(visible)),
    setBreadCrumbs: breadCrumbs => dispatch(setBreadCrumbs(breadCrumbs)),
  }
}

export default connect(null, mapDispatchToProps)(
  withRouter(RestoreDeletedDevices),
)
