import {
  REQUEST_METRIC_TYPES,
  FETCH_METRIC_TYPES_SUCCESS,
  FETCH_METRIC_TYPES_FAILURE,
  SELECT_METRIC_TYPE,
  SELECT_ALL_METRIC_TYPES,
  SELECT_METRIC_TYPE_GROUP,
} from "./actionTypes";

export function requestMetricTypes() {
  return {
    type: REQUEST_METRIC_TYPES,
  };
}

export function fetchMetricTypes(getMetricTypes) {
  return (dispatch) => {
    dispatch(requestMetricTypes());
    return getMetricTypes().then((json) =>
      dispatch(fetchMetricTypesSuccess(json))
    );
  };
}

export function fetchMetricTypesSuccess(json) {
  return {
    type: FETCH_METRIC_TYPES_SUCCESS,
    data: json,
  };
}

export function fetchMetricTypesFailure(error) {
  return {
    type: FETCH_METRIC_TYPES_FAILURE,
    errorMessage: error.message,
  };
}

export function selectMetricType(metricTypeId, metricId, selected) {
  return {
    type: SELECT_METRIC_TYPE,
    metricTypeId,
    metricId,
    selected,
  };
}

export function selectAllMetricTypes(deviceId, selected) {
  return {
    type: SELECT_ALL_METRIC_TYPES,
    deviceId,
    selected,
  };
}

export function selectMetricTypeGroup(metricTypeGroup, selected) {
  return {
    type: SELECT_METRIC_TYPE_GROUP,
    metricTypeGroup,
    selected,
  };
}
