import React from 'react'

const Vr = () => (
  <hr style={{
    borderColor: 'rgba(0,0,0,0.05)',
    // remove the right boarder so we only have a 1px vr
    borderRight: 0,
    boxSizing: 'border-box',
    height: '80%',
    margin: '0 1.5rem',
    transform: 'rotate("90deg")',
  }}/>
)

export default Vr