/*
  EQUALS,
  GT,
  GTE,
  LT,
  LTE,
  STARTSWITH,
  ENDSWITH,
  CONTAINS,
  IN,
  BETWEEN,
*/
import computerSystemsApi from "./../../../api/computer-systems/computerSystems";
const stringTypeFilter = (name, id) => {

  return {
    id: name,
    name: name,
    isDate: false,
    sortingDataType: 'string',
    selectedOptions: ['STARTSWITH'],
    extraData: '',
    options: [
      { id: 'EQUALS', name: 'Equals' },
      //{ id: 'NOTEQUAL', name: 'Not equals' },
      { id: 'CONTAINS', name: 'Contains' },
      //{ id: 'NOTCONTAINS', name: 'Does not contain' },
      { id: 'STARTSWITH', name: 'Starts with' },
      { id: 'ENDSWITH', name: 'Ends with' },
    ],
  }
}

const dateTypeFilter = (name, id) => {

  return {
    id: name,
    name: name,
    isDate: true,
    sortingDataType: 'string',
    selectedOptions: ['GT'],
    extraData: '',
    options: [
      { id: 'GT', name: 'Greater than' },
      { id: 'GTE', name: 'Greater than or equal' },
      { id: 'LT', name: 'less than' },
      { id: 'LTE', name: 'Less than or equal' },
    ],
  }
}

const multiSelectTypeFilter = (name, id, options) => {
  return {
    id: name,
    name: name,
    sortingDataType: "multiselect",
    selectedOptions: [],
    extraData: '',
    options: [],
  }
}

const dateTimeFilter = (name, id) => {
  return {
    id: name,
    name: name,
    sortingDataType: "datetime",
    selectedOptions: ["< 4 hours", "< 10 hours", "< 1 day", "< 2 days", "< 1 week", "< 1 month", "< 3 months", "< 6 months", "< 1 year", "all"],
    extraData: '',
    options: [
      { id: '< 4 hours', name: '< 4 hours', },
      { id: '< 10 hours', name: '< 10 hours' },
      { id: '< 1 day', name: '< 1 day' },
      { id: '< 2 days', name: '< 2 days' },
      { id: '< 1 week', name: '< 1 week' },
      { id: '< 1 month', name: '< 1 month' },
      { id: '< 3 months', name: '< 3 months' },
      { id: '< 6 months', name: '< 6 months' },
      { id: '< 1 year', name: '< 1 year' },
      { id: 'all', name: 'All' },
    ],
  }
}

const selectBusType = (name, id) => {
  return {
    id: name,
    name: name,
    sortingDataType: "multiselect",
    selectedOptions: ["Yes", "No"],
    extraData: '',
    options: [
      { id: 'Yes', name: 'Yes', },
      { id: 'No', name: 'No' },
    ],
  }
}
const selectBooleanType = (name, id) => {
  return {
    id: name,
    name: name,
    sortingDataType: "multiselect",
    selectedOptions: ["True", "False"],
    extraData: '',
    options: [
      { id: 'True', name: 'True', },
      { id: 'False', name: 'False' },
    ],
  }
}
const booleanTypeFilter = (name, id) => {
  return {
    id: id,
    name: name,
    sortingDataType: 'boolean',
    selectedOptions: ['true'],
    extraData: null,
    options: [
      { id: 'true', name: 'True' },
      { id: 'false', name: 'False' },
    ],
  }
}
// export const defaultFilters = [

//   computerSystemsApi.getSavedColumns().then((response) => {
//     
//     let columns = response.data.columns;
//     stringTypeFilter(columns, columns)
//   })
//   // Temporary disabled filters
//   // stringTypeFilter('Type', 'type'),
//   // stringTypeFilter('Objects', 'count'),
//   // stringTypeFilter('Rows', 'rRows'),
//   // stringTypeFilter('Size (KB)', 'rSize'),
//   // stringTypeFilter('Historic', 'hRows'),
//   // stringTypeFilter('Size (KB)', 'hSize'),
// ]
export const defaultFiltersPage = [
  stringTypeFilter('QoS Name', 'name'),
  stringTypeFilter('Group', 'qosGroup'),
  stringTypeFilter('Abbreviation', 'unitShort'),
  stringTypeFilter('Unit', 'unit'),
  booleanTypeFilter('Is Boolean', 'isBool'),
  booleanTypeFilter('Has Max', 'hasMax'),

  // Temporary disabled filters
  // stringTypeFilter('Type', 'type'),
  // stringTypeFilter('Objects', 'count'),
  // stringTypeFilter('Rows', 'rRows'),
  // stringTypeFilter('Size (KB)', 'rSize'),
  // stringTypeFilter('Historic', 'hRows'),
  // stringTypeFilter('Size (KB)', 'hSize'),
]

export const defaultFiltersActiveObjects = (columns, monitoringProbe = []) => {

  // let columns = [];
  // computerSystemsApi.getSavedColumns().then((response) => {
  //   
  //   columns = response.data.columns;

  // })
  return [...columns.map((headerName, index) => {
    // if(headerName == "Last Updated"){
    //   dateTypeFilter(headerName, index)
    // }
    // else if(headerName == "Type" || headerName == "Alarm Count"){
    //   numberTypeFilter(headerName, index)
    // }
    // else{multiselect
    if (headerName === "Monitored By") {
      let filterData = multiSelectTypeFilter(headerName, index);
      filterData = {
        ...filterData, selectedOptions: monitoringProbe, options: [...monitoringProbe.map(item => {
          return { id: item, name: item }
        })]
      };
      return filterData;
    }
    if (headerName === "Discovered") {
      return dateTimeFilter(headerName, index)
    }
    if (headerName === "Bus Type" || headerName === "Has KeyIcon") {
      return selectBusType(headerName, index)
    }
    if (headerName === "Removed") {
      return selectBooleanType(headerName, index)
    }
    if (headerName === "Last Updated") {
      return dateTypeFilter(headerName, index)
    }
    return stringTypeFilter(headerName, index)
  }
  )]
  // stringTypeFilter('Probe', 'probe'),
  // stringTypeFilter('QoS Name', 'qos'),
  // stringTypeFilter('Source', 'source'),
  // stringTypeFilter('Target', 'target'),
  // stringTypeFilter('Table ID', 'tableId'),
  // stringTypeFilter('Host', 'host'),
  // stringTypeFilter('Origin', 'origin'),
}
