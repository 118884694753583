import {
    SAVEDCOLUMNS_LISTVIEW 
    } from './actionTypes'
    
    
    const savedColsListView = (state = '', action) => {
      switch (action.type) {
      case SAVEDCOLUMNS_LISTVIEW:
        return action.savedcolumns
      default:
        return state
      }
    }
    
    export default savedColsListView