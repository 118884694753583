import {FILTERED_ROW_ENTITIES} from './actionTypes'

const columnFilter = (state = '', action) => {
  switch (action.type) {
  case FILTERED_ROW_ENTITIES:
    return action.visibleRowCount
  default:
    return state
  }
}

export default columnFilter
