import React from 'react'
import {SvgIcon} from '@mineral/core';

const Icon = (props) => {
  return (
    <SvgIcon {...props}>
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
    >
      <g>
        <path d="M3 13h8V3H3v10zm0 8h8v-6H3v6zm10 0h8V11h-8v10zm0-18v6h8V3h-8z" />
      </g>
    </svg>
    </SvgIcon >
  )
}

export default Icon