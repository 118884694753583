// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Essentials subscription
  content["Essentials subscription"] =
  "Essentials の購読";

  //50 max
  content["50 max"] =
  "最大 50";

  //Total devices discovered
  content["Total devices discovered"] =
  "検出されたデバイスの合計";

  //VIEW INVENTORY
  content["VIEW INVENTORY"] =
  "インベントリの表示";

  //No Roles found
  content["No Roles found"] =
  "役割が見つかりませんでした";

  //Devices by role
  content["Devices by role"] =
  "ロール別デバイス";

  //Alarm Severity Message
  content["Highest Alarm Severity Level and Total Alarm Count"] =
  "最も高いアラーム重大度レベルおよび合計アラーム数";

  //Max Alarm Severity
  content["Max Alarm Severity"] =
  "最大アラーム重大度";

  //Monitored technology
  content["Monitored technology"] =
  "モニタ対象テクノロジ";

  //Total Alarms
  content["Total Alarms"] =
  "合計アラーム数";

  //INFO
  content["INFO"] =
  "情報";

  //WARNING
  content["WARNING"] =
  "警告";

  //MINOR
  content["MINOR"] =
  "マイナー";

  //MAJOR
  content["MAJOR"] =
  "メジャー";

  //CRITICAL
  content["CRITICAL"] =
  "クリティカル";

  //Monitored teechnologies
  content["Monitored Technologies"] =
  "モニタ対象テクノロジ";

  //Name
  content["Name"] =
  "名前";

  //Role
  content["Role"] =
  "役割";

  //Top Devices by Alarm
  content["Top Devices by Alarm"] =
  "アラーム別の上位デバイス";

  //Group name
  content["Group name"] =
  "グループ名";

  //Total alarms
  content["Total alarms"] =
  "合計アラーム数";

  //Total alarms by group
  content["Total alarms by group"] =
  "合計アラーム (グループ別)";

  // END OF LOCALIZATION

export default content;
