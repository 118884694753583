import React, {Component} from 'react'
import {  
  CenterError,
} from '../../ui-components/uim-components'
import { Dialog,Button as  FlatButton} from "@mineral/core"
import Resource from './Resource'

class DeployErrorDialog extends Component {
  state = {
    open: this.props.open,
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.open) {
      this.setState({open: nextProps.open})
    }
  }
  handleClose = () => {
    this.setState({open: false})
    if (this.props.handleClose) {
      this.props.handleClose()
    }
  }
  render() {
    const actions = [
      <FlatButton
        //OK 
        children= {Resource.get('OK')}
        color="primary"
        variant="text"
        onClick={this.handleClose}
      />,
    ]
    return (
      <Dialog
        actions={actions}
        modal={false}
        open={this.state.open}
        onClose={this.handleClose}>
        <CenterError title={this.props.title} message={this.props.message} />
      </Dialog>
    )
  }
}

export default DeployErrorDialog