import connect from './../../utils/connect'
import Wizard from './Wizard'
import { addBreadCrumb, setBreadCrumbs } from './../breadcrumbs/actions'
import { fetchProbes, pollProbesStart, pollProbesStop } from '../../api/setup-wizard/actions'
import { getJobsRunning, pollJobsRunningStart, pollJobsRunningStop } from '../../api/DeviceDiscovery/agents/actions'
import { setSelectedAgent } from '../../api/DeviceDiscovery/agents/actions'

const mapStateToProps = state => {
    return {
      sid: state.sid,
      featureFlags: state.configuration.items,
      probeCount: state.wizardProbeList.count,
      probeList: state.wizardProbeList.items,
      jobsCount: state.agents.runningJobsCount,
      acl:state.acl,
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
      setBreadCrumbs: items => dispatch(setBreadCrumbs(items)),
      addBreadCrumb: item => dispatch(addBreadCrumb(item)),
      fetchProbes: (count,useEnhancedTemplates,categories) => dispatch(fetchProbes(count,useEnhancedTemplates,categories)),
      pollProbesStart: (useEnhancedTemplates,categories) => dispatch(pollProbesStart(useEnhancedTemplates,categories)),
      pollProbesStop: () => dispatch(pollProbesStop()),
      getJobsRunning: () => dispatch(getJobsRunning()),
      pollJobsRunningStart: () => dispatch(pollJobsRunningStart()),
      pollJobsRunningStop: () => dispatch(pollJobsRunningStop()),
      selectedAgent:(agentInfo)=>dispatch(setSelectedAgent(agentInfo)),
    }
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(Wizard)