import config from './../config'
import axios from 'axios';

class DashboardPaths {
  get(params) {
    var url = [config.basename, '/api/v1/dashboard/path'];
    var landingPage = params.toString().indexOf("&filterText=&filterType=FOLDERVIEW")
    var folderOne = params.toString().indexOf("&filterText=Sales%2F&filterType=FOLDERVIEW")
    var folderTwo = params.toString().indexOf("&filterText=Sales%2FTerritory%2F&filterType=FOLDERVIEW")
    var folderThree = params.toString().indexOf("&filterText=Sales%2FTerritory%2FHyd%2F&filterType=FOLDERVIEW")
    var searchPage = params.toString().indexOf("&filterType=DASHBOARDVIEW")

    if(process.env.NODE_ENV=='development') {
      if(landingPage !== -1)
        return axios.get('http://10.252.25.208:8080/castlemock/mock/rest/project/pvTZwK/application/CujwsQ/dashboard/path');
      else if(folderOne !== -1)
        return axios.get('http://10.252.25.208:8080/castlemock/mock/rest/project/pvTZwK/application/CujwsQ/sales');
      else if(folderTwo !== -1)
        return axios.get('http://10.252.25.208:8080/castlemock/mock/rest/project/pvTZwK/application/CujwsQ/Territory');
      else if(folderThree !== -1)
        return axios.get('http://10.252.25.208:8080/castlemock/mock/rest/project/pvTZwK/application/CujwsQ/Hyd');
      else if(searchPage !== -1)
        return axios.get('http://10.252.25.208:8080/castlemock/mock/rest/project/pvTZwK/application/CujwsQ/search');
      else
        return axios.get('http://10.252.25.208:8080/castlemock/mock/rest/project/pvTZwK/application/CujwsQ/dashboard/path');
    }

    return axios.post(url.join('/'),params)   
  }

  getDashboardRouteMap() {
    var url = [config.basename, 'api/v1/wasp/routedashboardmap'];
    if(process.env.NODE_ENV=='development'){
      return axios.get(
        "http://10.252.25.208:8080/castlemock/mock/rest/project/TK8t3o/application/UXxhjW/wasp/routedashboardmap"
        );
      }
      return axios.get(url.join('/'))        
      // return axios.get('http://10.252.25.208:8080/castlemock/mock/rest/project/pvTZwK/application/CujwsQ/dashboard/path');
      
    }
  }
  
  let dashboardPaths = new DashboardPaths()
  export default dashboardPaths