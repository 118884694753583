import React, { Component } from "react";
import { CenterError as Error } from "../ui-components/uim-components";
import {CircularProgress} from '@mineral/core';
import visualize from "./../../utils/visualize";
import CabiApi from "../../api/cabi/cabi";
import entityTypes from "../../api/entityTypes";
import { TIME_RANGE_TYPES } from "../../api/cabi/actionTypes";
import "./cabi.less";
import isEqual from "lodash/isEqual";
import Resource from "./Resource";
import LocalStorageManager from "./../../utils/LocalStorageManager";

class CabiDetails extends Component {
  state = {
    loadStatus: "loading",
    referer: "",
  };
  _loadDashboard = (props) => {
    this.setState({
      loadStatus: "loading",
    });

    let reportParams = this.getUrlParams(props);
    if(!reportParams.cs_id)
    reportParams.cs_id = props.csId ? [props.csId] : [""];
    if(!reportParams.group_id)
    reportParams.group_id = parseInt(props.groupId) ? [props.groupId] : [""];
    if(!reportParams.group_name)
    reportParams.group_name = ["YAda"];
    if(!reportParams.prid)
    {
      if(props.probe==null)
      reportParams.prid=[""];
      else
    reportParams.prid = props.probe
      ? [props.probe.split("-")[0]]
      : [props.probe]; // converts 'aws-service' or similar to 'aws'
    }
      if(!reportParams.minimum_alarm_level)
    reportParams.minimum_alarm_level = props.minimumAlarmLevel ? [props.minimumAlarmLevel]:["1"];
    if(!reportParams.time_range && props.timeRange!=-1)
    {
    reportParams.time_range = props.timeRange
      ? [props.timeRange]
      : [TIME_RANGE_TYPES.TWO_HOUR];
    }

    
    if(props.timeRange &&  props.timeRange==-1)
    {
     
      reportParams.time_range=["-1"];
      if(!reportParams.start_date && props.start_date)
      reportParams.start_date=[props.start_date];

      if(!reportParams.end_date && props.end_date)
      reportParams.end_date=[props.end_date];
    }
    if(reportParams.path && reportParams.path.length>0)
      props.path=decodeURIComponent(reportParams.path[0]);
    CabiDetails.getDashboardPath(props, this.state).then((path) => {
      const cabiOptions = {
        url: this.state.cabiUrl,
        params: reportParams,
        dashboardPath: path,
        jasperToken: this.state.jasperToken,
        orgidl: this.state.orgidl,
        container: "dashContainer",
        locale: this.state.userLocale,
        disableBrowserNavHistory: true,
        navOverride: (route) => {
          props.history.push(route);
        },
        onDashboardDrillAcross: (info) => {
          reportParams = info.dashboardParams;
        },
        onDashboardSuccess: (info) => {
          this.setState({
            loadStatus: "complete",
          });

          if (props.setShowTimeRange) {
            let showTimeRange = false;

            if (Array.isArray(info.availableDashParams)) {
              let availableDashParams = info.availableDashParams;
              for (let i = 0; i < availableDashParams.length; i++) {
                if (availableDashParams[i].id === "time_range") {
                  showTimeRange = true;
                  break;
                }
              }
            }

            props.setShowTimeRange(showTimeRange);
          }
        },
      };

      CabiApi.getPropToDashMappingFromApi().then((success)=>{
      
        let ROUTE_DASHBOARD_MAP=success.data
        let dashToRouteMap = {};

      for (var i = 0; i < ROUTE_DASHBOARD_MAP.length; i++) {
        let dashEntry = ROUTE_DASHBOARD_MAP[i];

        // map routes based on dashboard path
        dashToRouteMap[dashEntry.path] = {
          idValue: dashEntry.idValue,
          opConId: dashEntry.opConId,
          cabiId: dashEntry.cabiId,
          contextParams: dashEntry.contextParams,
          route: dashEntry.route,
        };
      }

      let viz = new visualize();
      viz.setProductList(props);
      viz
        .loadDashboard(cabiOptions, dashToRouteMap)
        .then((dashboard) => {
          Window.updateDashboard = (params) => {
            let newParams = Object.assign({}, reportParams, params);
            dashboard.update(newParams);
          };
        })
        .catch((error) => {
         // console.log("error in loading dashboard", error);
          this.setState({
            loadStatus: "failed",
          });
        });
    }).catch((error)=>{
      this.setState({
        loadStatus: "failed",
      });
    })
  }).catch((error)=>{
    this.setState({
      loadStatus: "failed",
    });
  })
}
  
  componentWillMount() {
    if (this.props.setShowTimeRange) this.props.setShowTimeRange(false);
    this.initializeCabiData(this.props);
    this.setState({ referer: this.props.referer });
    //CabiApi.getPropToDashMapping();
    //CabiApi.getDashToRouteMapping();
  }
  componentWillUnmount() {
    LocalStorageManager.removeStorageForNameSpace("DASHBOARDDRILLDOWNPARAMS");
  }
  initializeCabiData(props) {
   CabiApi.get("en", props.sid)
      .then((response) => response.data)
      .then((json) => {
        this.setState(
          {
            jasperToken: json.token,
            cabiUrl: json.address,
            userLocale: json.locale,
            orgidl: json.orgidl ? json.orgidl : "ca", // add default of "ca" for reverse compatibilty
          },
          () => this._loadDashboard(props)
        );
      })
      .catch((error) => {
        console.error("Error retrieving cabi info: ", error);
        if (
          !props.saas &&
          (error.response.status === 404 || error.response.status === 503)
        ) {
          this.setState({
            loadStatus: "cabiNotFound",
          });
        } else {
          this.setState({
            loadStatus: "failed",
          });
        }
      });
  }

  getUrlParams(props) {
    if (props.location.search) {
      let rawParams = props.location.search; // will be something like '?foo=bar&a=b'

      if (rawParams.length > 0) {
        rawParams = decodeURI(rawParams.substr(1, rawParams.length)); // remove '?' and url encoding
        let pairs = rawParams.split("&");
        let params = {};

        pairs.forEach((element) => {
          var keyValue = element.split("=");
          params[keyValue[0]] = [keyValue[1]];
        });

        return params;
      }
    }

    return {};
  }
  getObjectDiff = (obj1, obj2) => {
    const diff = Object.keys(obj1).reduce((result, key) => {
      if (!obj2.hasOwnProperty(key)) {
        result.push(key);
      } else if (isEqual(obj1[key], obj2[key])) {
        const resultKeyIndex = result.indexOf(key);
        result.splice(resultKeyIndex, 1);
      }
      return result;
    }, Object.keys(obj2));

    return diff;
  };
  shouldComponentUpdate(nextProps, nextState) {
    if (
      nextProps.csId != this.props.csId ||
      nextProps.groupId != this.props.groupId
    ) {
      return true;
    }
    const propsDiff = this.getObjectDiff(this.props, nextProps);
    const stateDiff = this.getObjectDiff(this.state, nextState);
    let diffCount=0;
    if(propsDiff && propsDiff.length>0)
    {
      for( let i = 0; i < propsDiff.length; i++){ 
        if ( propsDiff[i] === "timeRange") { 
          diffCount++;
        } else
        if ( propsDiff[i] === "start_date") { 
          diffCount++;
        } else
        if ( propsDiff[i] === "end_date") { 
          diffCount++
        }
    }
    }
    if (propsDiff.length === 0 && stateDiff.length === 0) {
      return false;
    }
    else if(propsDiff.length>diffCount)
    {
      // time range is updated without a component update
      return false;
    }
    return true;
  }
  componentWillReceiveProps(nextProps) {
    const propsDiff = this.getObjectDiff(this.props, nextProps);
    const hasRequiredState =
      this.state.cabiUrl &&
      this.state.jasperToken &&
      this.state.userLocale &&
      this.state.orgidl;
    if (hasRequiredState) {
      // time range is updated without a component update
      let diffCount=0;
      if(propsDiff && propsDiff.length>0)
    {
      for( let i = 0; i < propsDiff.length; i++){ 
        if ( propsDiff[i] === "timeRange") { 
          diffCount++;
        } else
        if ( propsDiff[i] === "start_date") { 
          diffCount++;
        } else
        if ( propsDiff[i] === "end_date") { 
          diffCount++; 
        }
    }
    }
      if (
        propsDiff.length > diffCount) {
        if (this.state.referer && this.state.referer === "treeView") {
          this.initializeCabiData(nextProps);
        } else {
          this._loadDashboard(nextProps);
        }
      }
    } else {
      if (!isEqual(nextProps, this.props)) {
        if (this.state.referer && this.state.referer === "treeView") {
          this.initializeCabiData(nextProps);
        }
      }
    }
  }
  render() {
    let view = null;
    let overlayStyle = {
      display: "block",
      height: "100%",
      position: "absolute",
      top: 0,
      width: "100%",
    };
    switch (this.state.loadStatus) {
      case "loading":
        view = <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><CircularProgress style={{color: '#3272D9'}} /></div>;
        break;
      case "failed":
        //dashError
        const dashError = Resource.get("Dashboard failed to load.");
        view = (
          <Error
            //dashError
            message={Resource.get("Dashboard failed to load.")}
          />
        );
        break;
      case "cabiNotFound":
        view = (
          <div className="cabi__missing--on-prem">
            <Error
              //Systemcomponentmissing message
              title={Resource.get("System component missing")}
              message={
                <p>
                  {
                    //missing component
                    Resource.get(
                      "CA Business Intelligence (CABI) system component is needed."
                    )
                  }
                  <br /> <br />
                  <a
                    href="https://techdocs.broadcom.com/bin/gethidpage?hid=cabi_db&space=UIMPGA&language=&format=rendered"
                    target="_blank"
                  >
                    {
                      // Installation and configuration instructions
                      Resource.get(
                        "Installation and configuration instructions"
                      )
                    }
                  </a>
                </p>
              }
            />
          </div>
        );
        break;
      case "complete":
      default:
        overlayStyle.display = "none";
        break;
    }
    return (
      <div
        style={{
          height: "100%",
          position: "relative",
        }}
      >
        <div id="dashContainer" className="cabiContainer" />
        <div style={overlayStyle}>{view}</div>
      </div>
    );
  }
 
  static getDashboardPath(props, state) {
    const SUMMARY_DASHBOARD =
      "/public/ca/uim/dashboards/common/uim_summary_saas";

    return new Promise((resolve) => {
      CabiApi.getPropToDashMappingFromApi().then((response)=>{

        let ROUTE_DASHBOARD_MAP=response.data;
        let propToDashMap = {};

        for (let i = 0; i < ROUTE_DASHBOARD_MAP.length; i++) {
          let dashEntry = ROUTE_DASHBOARD_MAP[i];
  
          // map dashboard paths by op con property
          let propEntry = propToDashMap[dashEntry.opConId];
          if (!propEntry) propEntry = {};
  
          if (dashEntry.entityType) {
            // if entry had an entity type, map within the property entry
            if (!propEntry.entityTypeMap) propEntry.entityTypeMap = {};
            propEntry.entityTypeMap[dashEntry.entityType] = dashEntry.path;
          } else if (dashEntry.idValue) {
            // if entry had a specified id value, map within the property entry
            if (!propEntry.idValueMap) propEntry.idValueMap = {};
            propEntry.idValueMap[dashEntry.idValue] = dashEntry.path;
          } else {
            // otherwise map path as default for that property
            propEntry.path = dashEntry.path;
          }
  
          propToDashMap[dashEntry.opConId] = propEntry;
        }
    
      let keys = Object.keys(propToDashMap);
      let relevantPropFound = false;

      for (let i = 0; i < keys.length; i++) {
        let prop = keys[i];
        if (
          props[prop] != null &&
          props[prop] != undefined &&
          props[prop] !== ""
        ) {
          relevantPropFound = true;
          let idValue = props[prop];
          let dashEntry = propToDashMap[prop];
          let dashPath = dashEntry.path.replace(`[${prop}]`, idValue); // generate default route for property

          if (
            dashEntry.entityTypeMap &&
            dashEntry.entityTypeMap[props.entity.type]
          ) {
            // if there is a valid entity-type-specific entry, use it
            resolve(dashEntry.entityTypeMap[props.entity.type]);
          } else if (dashEntry.idValueMap && dashEntry.idValueMap[idValue]) {
            // check if specified dashboard is available
            let viz = new visualize();
            viz.setProductList(props);
            viz.checkAndLoadVisualize(state.cabiUrl).then(
              (isLoaded) => {
                if (isLoaded) {
                  viz
                    .doesResourceExist({
                      url: state.cabiUrl,
                      resource: dashEntry.idValueMap[idValue],
                      resourceType: "dashboard",
                      jasperToken: state.jasperToken,
                      orgidl: state.orgidl,
                      locale: state.userLocale,
                    })
                    .then(
                      (doesExist) => {
                        if (doesExist === true) {
                          // if there is a valid id-value-specific entry, use it
                          resolve(dashEntry.idValueMap[idValue]);
                        }
                        resolve(dashPath);
                      },
                      () => {
                        resolve(dashPath);
                      }
                    );
                } else {
                  resolve(dashPath);
                }
              },
              () => {
                console.error("Failed to load dashboard");
              }
            );
          } else {
            resolve(dashPath);
          }
        }
      }
      if(props.path && props.path!="")
      resolve(props.path);

      if (!relevantPropFound) {
        // Default to overview summary dashboard
        resolve(SUMMARY_DASHBOARD);
      }
    }).catch((error)=>{
      console.log("Failed to get route dashboard mapping");
      resolve(SUMMARY_DASHBOARD);
    })
     
    });
  }
}

export default CabiDetails;
