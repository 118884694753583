import React from 'react'

const AddGroupIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 3C0 1.34315 1.34315 0 3 0H21C22.6569 0 24 1.34315 24 3V21C24 22.6569 22.6569 24 21 24H3C1.34315 24 0 22.6569 0 21V3Z" fill="white"/>
      <path d="M12 6C12.5523 6 13 6.44772 13 7V10.9993L17 11C17.5523 11 18 11.4477 18 12C18 12.5523 17.5523 13 17 13L13 12.9993V17C13 17.5523 12.5523 18 12 18C11.4477 18 11 17.5523 11 17V12.9993L7 13C6.44772 13 6 12.5523 6 12C6 11.4477 6.44772 11 7 11L11 10.9993V7C11 6.44772 11.4477 6 12 6Z" fill="#3272D9"/>
      <path d="M3 1H21V-1H3V1ZM23 3V21H25V3H23ZM21 23H3V25H21V23ZM1 21V3H-1V21H1ZM3 23C1.89543 23 1 22.1046 1 21H-1C-1 23.2091 0.790861 25 3 25V23ZM23 21C23 22.1046 22.1046 23 21 23V25C23.2091 25 25 23.2091 25 21H23ZM21 1C22.1046 1 23 1.89543 23 3H25C25 0.790861 23.2091 -1 21 -1V1ZM3 -1C0.790861 -1 -1 0.790861 -1 3H1C1 1.89543 1.89543 1 3 1V-1Z" fill="#C8D1E0"/>
    </svg>
  )
}

export default AddGroupIcon