import React, { Component } from "react";
import AddSubgroupDialog from "./AddSubgroupDialog";
import DeleteSubgroupDialog from "./DeleteSubgroupDialog";
import RenameSubgroupDialog from "./RenameSubgroupDialog";
import RemoveDevicesDialog from "./RemoveDevicesDialog";
import DeleteDevicesDialog from "./DeleteDevicesDialog";
import InstallRobotDialog from "./InstallRobotDialog";

import { Snackbar } from "@mineral/core";

import ErrorDialog from "./ErrorDialog";
import DynamicGroupDialog from "./dynamicGroup/DynamicGroupDialog";
import CustomSnackbar from '../common/CustomSnackbar';

class DialogManager extends Component {
  state = {
    openDynamicGroupDialog: false,
    dynamicGroupInfo: null,
    initialEntity: this.props.entitySelected,
    snackBarMessage: null,
    snackMessageStatus:'success'
  };
  handleClose = (dialogName, info) => {
    if (dialogName === "dynamicGroupsOpen") {
      this.setState({
        openDynamicGroupDialog: false,
        subGroupOpen: false,
        dynamicGroupInfo: null,
      });
      if (this.props.editSubGroupOpen) {
        this.props.handleDialogClose("editSubGroupOpen");
      }
      if (this.props.subGroupOpen) {
        this.props.handleDialogClose("subGroupOpen");
      }
      if (this.props.addDeviceToGroupOpen) {
        this.props.handleDialogClose("addDeviceToGroupOpen");
      }
    } else {
      this.props.handleDialogClose(dialogName);
      if (dialogName === "subGroupOpen" && info && info.isDynamicChecked) {
        this.setState({ subGroupOpen: false, dynamicGroupInfo: info });
      }
    }
  };
  handleDialogClose = (type) => {
    this.props.handleDialogClose(type);
  };
  componentWillReceiveProps(nextProps) {
    this.setState({
      subGroupOpen: nextProps.subGroupOpen || false,
      deleteSubGroupOpen: nextProps.deleteSubGroupOpen || false,
      renameSubGroupOpen: nextProps.renameSubGroupOpen || false,
      addDeviceToGroupOpen: nextProps.addDeviceToGroupOpen || false,
      removeDevicesOpen: nextProps.removeDevicesOpen || false,
      errorDialog: nextProps.errorDialog || false,
    });
  }

  handleSnackbar(message,status) {
    this.setState({ snackBarMessage: message,snackMessageStatus:status });
  }

  render() {
    let dynamicDialogMode = "ADD Group";
    if (this.props.subGroupOpen) {
      dynamicDialogMode = "ADD Group";
    } else if (this.state.openDynamicGroupDialog) {
      dynamicDialogMode = "ADD";
    } else {
      dynamicDialogMode = "EDIT";
    }

    return (
      <div>
        {this.state.openDynamicGroupDialog ||
        this.props.editSubGroupOpen ||
        this.state.subGroupOpen ||
        this.props.addDeviceToGroupOpen ? (
          <DynamicGroupDialog
            open={
              this.state.openDynamicGroupDialog ||
              this.props.editSubGroupOpen ||
              this.state.subGroupOpen ||
              this.props.addDeviceToGroupOpen
            }
            mode={dynamicDialogMode}
            info={this.state.dynamicGroupInfo}
            handleClose={this.handleClose}
            addGroup={this.props.addGroup}
            initialEntity={this.state.initialEntity}
            parentId={this.props.selectedId}
            handleErrorDialogOpen={this.props.handleErrorDialogOpen}
            handleUpdateSnackbar={()=>this.handleSnackbar('Group updation is in progress. The changes will be reflected in a few seconds.','success')}
            {...this.props}
          />
        ) : null}
        {/* <AddDeviceToGroupDialogRedux
          open={this.props.addDeviceToGroupOpen}
          handleClose={this.handleClose}
          parentId={this.props.selectedId}
          handleErrorDialogOpen={this.props.handleErrorDialogOpen}
          {...this.props}
        /> */}
        {/* <RenameSubgroupDialog
          open={this.props.renameSubGroupOpen}
          handleClose={this.handleClose}
          groupName={this.props.selectedName}
          renameGroup={this.props.renameGroup}
          handleErrorDialogOpen={this.handleErrorDialogOpen}
          {...this.props}
        /> */}
        {/* <DynamicGroupDialog
          open={this.props.subGroupOpen}
          handleClose={this.handleClose}
          addGroup={this.props.addGroup}
          handleErrorDialogOpen={this.handleErrorDialogOpen}
          {...this.props}
          initialEntity={this.state.initialEntity}
        /> */}
        <DeleteSubgroupDialog
          open={this.props.deleteSubGroupOpen}
          handleClose={this.handleClose}
          parentId={this.props.selectedId}
          handleErrorDialogOpen={this.props.handleErrorDialogOpen}
          handleDeleteSnackbar={() =>
            this.handleSnackbar("Group deleted successfully","success")
          }
          {...this.props}
        />

        <RemoveDevicesDialog
          open={this.props.removeDevicesOpen}
          handleClose={this.handleClose}
          parentId={this.props.selectedId}
          devices={this.props.selecedDevices}
          handleErrorDialogOpen={this.props.handleErrorDialogOpen}
          {...this.props}
        />
        <DeleteDevicesDialog
          open={this.props.deleteDevicesOpen}
          handleClose={this.handleClose}
          devices={this.props.selecedDevices}
          handleErrorDialogOpen={this.props.handleErrorDialogOpen}
          {...this.props}
        />
        <InstallRobotDialog
          open={this.props.installRobotOpen}
          handleClose={this.handleClose}
          devices={this.props.selecedDevices}
          handleErrorDialogOpen={this.props.handleErrorDialogOpen}
          {...this.props}
        />
        <ErrorDialog
          open={this.props.errorDialog}
          handleClose={this.handleClose}
          {...this.props}
        />
       
        <CustomSnackbar severity={this.state.snackMessageStatus}
          open={Boolean(this.state.snackBarMessage)}
          message={this.state.snackBarMessage}
          autoHideDuration={4000}
          
          close={() => this.setState({ snackBarMessage: null })}
        />      
      </div>
    );
  }
}

DialogManager.defaultProps = {
  subGroupOpen: false,
  deleteSubGroupOpen: false,
  renameSubGroupOpen: false,
};
export default DialogManager;
