export const load = (url,  successCallback = () => {}, failedCallback = () => {}) => {
  if (!window.WalkMeAPI) {
    var OPERATOR_CONSOLE = window.OPERATOR_CONSOLE;
    var nonce = OPERATOR_CONSOLE['cspNonce'];
    var script = document.createElement('script')
    script.type = 'text/javascript'
    script.nonce = nonce
    script.async = true
    if (script.readyState) {  //IE
      script.onreadystatechange = function () {
        if (script.readyState === 'loaded' ||
          script.readyState === 'complete') {
          script.onreadystatechange = null
          successCallback(window.WalkMeAPI)
        } else {
          failedCallback(Error('failed to load:', url))
        }
      }
    } else {  //Others
      script.onload = function () {
        successCallback(window.WalkMeAPI)
      }
      script.onerror = (error) => {
        failedCallback(error)
      }
    }
    script.src = url
    document.getElementsByTagName('head')[0].appendChild(script)
    window._walkmeConfig = {smartLoad: true}
  } else {
    successCallback(window.WalkMeAPI)
  }
}

export const getWalkMeVariables = () => {
  if(!window.WalkMe) {
    window.WalkMe = {}
  } 
  return window.WalkMe
}