import React, {Component,Fragment} from "react";
import connect from "./../../../utils/connect";
import "./scheduleDiscovery.less";
import Resource from "./Resource";
import moment from "moment/moment";
import schedule from "./../../../api/DeviceDiscovery/schedule/scheduleDiscovery";
import {
  getScopes,
  pollScopesStart,
  pollScopesStop,
} from "./../../../api/DeviceDiscovery/scopes/actions";
import {CheckBoxOutlineBlankIcon as UnCheckedIcon} from "@material-ui/icons/CheckBoxOutlineBlank";
import {Checkbox, TextField, FormControlLabel,FormControl} from "@mineral/core";

import HelpingText from '../../common/HelpingText'
import { MineralDateTimePicker } from '../../common/datepickers/MineralDateTimePicker'
import DatePickerIcon from '../../icons/DatePickerIcon'

import CustomNumberField from '../../common/customInputs/CustomNumberField' 
/**
 * @Author mansi10
 */
class ScheduleDiscovery extends Component {
  constructor(props) {
    super(props);
    let startDateTime = new Date(),
      presentDate = new Date(startDateTime);
    if (startDateTime.getHours() < 23) {
      startDateTime = new Date(
        startDateTime.getFullYear(),
        startDateTime.getMonth(),
        startDateTime.getDate(),
        startDateTime.getHours() + 1,
        startDateTime.getMinutes(),
        startDateTime.getSeconds()
      );
    } else {
      startDateTime = new Date(
        startDateTime.getFullYear(),
        startDateTime.getMonth(),
        startDateTime.getDate() + 1,
        0,
        startDateTime.getMinutes(),
        startDateTime.getSeconds()
      );
    }
    this.state = {
      discoverNow: true,
      installRobot: false,
      scheduleDiscovery: false,
      startDateTime: startDateTime,
      presentDate: presentDate,
      handleRecurranceHours: true,
      recurrance: 24,
      selectedAgent: this.props.selectedAgent,
      scheduleModified: false,
      actualScheduleDiscovery: false,
      scopesCount: this.props.scopesInfo ? this.props.scopesInfo.length : 0,
      startDateError:null
    };
  }

  isPostCallNeeded = false;

  componentDidMount() {
    if (this.state.selectedAgent) {
      schedule.getSchedule(this.state.selectedAgent.id).then(
        (response) => {
          if (typeof response.data === "object") {
            let handleRecurranceHours = true,
              interval = response.data.interval / 3600;
            if (interval <= 0) {
              interval = 0;
              handleRecurranceHours = false;
            }
            this.setState({
              scheduleDiscovery: true,
              recurrance: interval,
              startDateTime: new Date(response.data.time),
              handleRecurranceHours: handleRecurranceHours,
              actualScheduleDiscovery: true,
            });
          } else {
            this.isPostCallNeeded = true;
          }
        },
        (err) => {
        }
      );
    }
    if (this.props.onLoad) {
      this.props.onLoad({onFinish: this.onFinish});
    }
    this.props.getScopes(this.state.selectedAgent.id);
    this.props.pollScopesStart(this.state.selectedAgent.id);
    this.handleFinishButton();
  }

  componentWillUnmount() {
    this.props.pollScopesStop();
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.scopesInfo &&
      nextProps.scopesInfo.length !== this.state.scopesCount
    ) {
      this.setState(
        {scopesCount: nextProps.scopesInfo.length},
        this.handleFinishButton
      );
    }
  }

  onFinish = () => {
    let agentId = this.state.selectedAgent.id,
      discoveryNowPromiseRef,
      schedulePromiseRef;
    let isDeployRobot = this.state.installRobot;
    if (this.state.discoverNow) {
      discoveryNowPromiseRef = schedule.runDiscovery(agentId, isDeployRobot, "");
    }
    if (this.state.scheduleModified) {
      let time = this.state.startDateTime.toISOString();
      let interval = 0;
      //remove millisecs
      time = time.split(".")[0] + "Z";

      if (this.state.handleRecurranceHours && this.state.recurrance !== "") {
        interval = parseInt(this.state.recurrance, 10);
        if (isNaN(interval)) {
          interval = 0;
        }
      }
      //First time creating schedule discovery for selected agent
      if (this.isPostCallNeeded) {
        if (this.state.scheduleDiscovery) {
          schedulePromiseRef = schedule.postSchedule(agentId, {
            time: time,
            interval: interval,
            isDeployRobot: isDeployRobot,
          });
        }
      } else {
        //already schedule discovery existes for selected agent
        if (this.state.scheduleDiscovery) {
          schedulePromiseRef = schedule.putSchedule(agentId, {
            time: time,
            interval: interval,
            isDeployRobot: isDeployRobot,
          });
        } else {
          schedulePromiseRef = schedule.deleteSchedule(agentId);
        }
      }
    }

    this.props.onFinishCall({
      discoveryNowPromiseRef: discoveryNowPromiseRef,
      schedulePromiseRef: schedulePromiseRef,
      scheduleDiscovery: this.state.scheduleDiscovery,
    });
  };
  updateRecurranceHours = (event) => {
    let val = parseInt(event.target.value, 10);
    if (isNaN(val)) {
      val = 0;
    }
    if (val > 9999) {
      val = 9999;
    }
    if (val < 1) {
      val = 1;
    }
    this.setState(
      {
        recurrance: val,
        scheduleModified: true,
      },
      this.handleFinishButton
    );
  };
  handleTime = (event) => {
    let date = moment(event).toDate();
    let currentDateTime = this.state.startDateTime,
      now = moment(),
      strcurrentDateTime = currentDateTime.getTime(),
      strDate = date.getTime();
    if (now.isAfter(moment(strDate))) {
      date = now;
    } else {
      date = moment(strDate);
    }
    if (now.isAfter(moment(strcurrentDateTime))) {
      currentDateTime = now;
    } else {
      currentDateTime = moment(strcurrentDateTime);
    }
    let tempDate = new Date(
      currentDateTime.year(),
      currentDateTime.month(),
      currentDateTime.date(),
      date.hour(),
      date.minute()
    );
    this.setState(
      {
        startDateTime: tempDate,
        scheduleModified: true,
      },
      this.handleFinishButton
    );
  };
  handleDate = (event) => {
    let date = moment(event).toDate();

   
    // let val=e.target.checked ;
    date.setHours(this.state.startDateTime.getHours());
    date.setMinutes(this.state.startDateTime.getMinutes());
    if(moment(event)?.isBefore())
    {
      this.setState(
        {
          startDateTime: date,
          scheduleModified: true,
          startDateError:"Date cannot be a past date"
        },
        this.handleFinishButton);
    }
    else
    {
   
    this.setState(
      {
        startDateTime: date,
        scheduleModified: true,
        startDateError:null
      },
      this.handleFinishButton
    );
    }
  };
  handleFinishButton = () => {
    let disabledStatus = false;

    if (!this.state.discoverNow && !this.state.scheduleDiscovery) {
      disabledStatus = true;
    }
    if (this.state.scopesCount === 0) {
      disabledStatus = true;
    }
    this.props.handleFinishButton(disabledStatus);
  };
  getScopesErrorMsg = () => {
    let classNames = ["setup-wizard__localprobes__info"];
    //No scopes available
    let msg1 = Resource.get(
      "No scopes available. Please configure at least one scope to run discovery"
    );
    return (
      <div style={{position: "relative"}} className={classNames.join(" ")}>
        <div
          style={{
            width: "calc(100% - 60px)",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          <div className="noscopes__contentImg"></div>
          <span className="setup-wizard__localprobes__content">
                          <span style={{padding: "2px 13px 2px 20px"}}>{msg1}</span>
                          </span>
        </div>
      </div>
    );
  };

  render() {
    let headerLabel = Resource.get("Discovery Schedule"); //New Linux / Unix Credential
    //Description1
    let Description1Label = Resource.get(
      "You can run discovery immediately and / or in the future."
    );
    let configureLabel = Resource.get("Configure"); //configure
    let Description2Label1 = Resource.get(" Run Discovery Now "); //Description2label1
    //Description2label2
    let Description2Label2 = Resource.get(
      "terminates any discovery currently executing and initiates the new discovery."
    );
    let Description2Label3 = Resource.get(" Scheduled Discovery "); //Description2label3
    //Description2label4
    let Description2Label4 = Resource.get(
      "overwrites the previously configured discovery and initiates the latest discovery at the scheduled time."
    );
    //Description2Label5
    let Description2Label5 = Resource.get(
      "Configuring a previous date and time always resets it to the current date and time."
    );
    let discoverNowLabel = Resource.get("Run Discovery Now"); //discoverNow
    let scheduleDiscoveryLabel = Resource.get("Schedule Discovery"); //scheduleDiscovery
    let postDiscoveryLabel = Resource.get("Post Discovery"); //postDiscovery
    let installRobotLabel = Resource.get("Install Robot on Discovered Hosts"); //installRobot
    let startDateLabel = Resource.get("Starting"); //startDate
    let timeLabel = Resource.get("At"); //time
    let timeHoursLabel = Resource.get("(24 Hour)"); //time
    let occuranceLabel = Resource.get("Recurring Every"); //occurance
    let hoursLabel = Resource.get("Hour(s)"); //Hour(s)
    //WindowsRobot
    let windowsRobotLabel = Resource.get(
      "You can auto deploy a Windows robot only on the Windows relay hub."
    );
    if (this.state.scopesCount == 0) {
      return <div className="discovery">{this.getScopesErrorMsg()}</div>;
    } else {
      return (
        <div className="discovery">
          <h3 className="head">{headerLabel}</h3>
          <div className="text" style={{' font-weight': '400', 'font-style': 'normal'}}>
            <div> {Description1Label}</div>
            <div style={{marginTop: "10px"}}>
              <span>{Description2Label1}</span>
              {Description2Label2}
              <div style={{marginTop: "10px"}}>
                <span>{Description2Label3}</span>
                {Description2Label4}
              </div>
              <div style={{marginTop: "10px"}}>{Description2Label5}</div>
            </div>
          </div>
          <div style={{marginTop: "110px", marginBottom: "16px"}}>
            {" "}
            {configureLabel}
          </div>
          <div>
            <FormControlLabel
              label={discoverNowLabel}
              style={{display: "inline-block", width: "200px", top: "7px"}}
              control={
                <Checkbox autoFocus
                  style={{marginRight: "4px"}}
                  checked={this.state.discoverNow ? true : false}
                  
                  onChange={(event) => {
                    this.setState(
                      {
                        discoverNow: event.target.checked,
                      },
                      this.handleFinishButton
                    );
                  }}

                />
              }
            >

            </FormControlLabel>

          </div>
          <div style={{paddingTop: "16px"}}>

            <FormControlLabel
              label={scheduleDiscoveryLabel}
              style={{display: "inline-block", width: "200px", top: "7px"}}
              control={
                <Checkbox
                  style={{marginRight: "4px"}}
                  checked={this.state.scheduleDiscovery ? true : false}
                 
                  onChange={(event) => {
                    this.setState(
                      {
                        scheduleDiscovery: event.target.checked,
                        scheduleModified: true,
                      },
                      this.handleFinishButton
                    );
                  }}

                />

              }
            >


            </FormControlLabel>

          </div>
          {this.state.scheduleDiscovery ? (
            <div style={{marginLeft: "25px", marginTop: "25px"}}>
              
                {/*<tr>
                  <td width="160px">
                    <span> {startDateLabel}</span>
                  </td>
                  <td>
                    <span> {timeLabel}</span>
                  </td>
                </tr>*/}
                

                <FormControl style={{width:'400px'}}>
            <MineralDateTimePicker
              value={this.state.startDateTime}
              onValueChange={this.handleDate}
             
             
              variant="inline"
             // minDateTime={this.state.presentDate}
              keyboardIcon={<DatePickerIcon />}
              label={`${startDateLabel} ${timeLabel}`}
              ampm={false}
            />
             <HelpingText
              hint={null}
              errorMessage={this.state.startDateError}
              disabled={!this.state.startDateError}
            />
           
          </FormControl>

               
                  {/*<td>
                    <div
                      className="calendar"
                      style={{
                        maxWidth: "150px",
                        border: "1px solid #cccccc",
                        borderRadius: "3px",
                        height: "32px",
                      }}
                    >
                      <DatePicker
                        // style={{
                        //   width: "138px",
                        //   top: "-7px",
                        //   left: "12px",
                        //   position: "relative",
                        // }}
                        textFieldStyle={{width: "138px"}}
                        minDate={this.state.presentDate}
                        id="startDate"
                        onChange={this.handleDate}
                        value={this.state.startDateTime}
                        placeholder="mm/dd/yyyy"
                        locale="en-US"
                      />
                      <div className="icon"></div>
                    </div>
                  </td>
                  <td>
                    <div
                      style={{
                        maxWidth: "80px",
                        border: "1px solid #cccccc",
                        borderRadius: "3px",
                        height: "32px",
                      }}
                    >
                      <TimePicker
                        id="startTime"
                        // style={{
                        //   width: "68px",
                        //   top: "-7px",
                        //   left: "12px",
                        //   position: "relative",
                        // }}
                        textFieldStyle={{width: "68px"}}
                        onChange={this.handleTime}
                        ampm={false}
                        value={this.state.startDateTime}
                        placeholder="hh:mm"
                      />
                    </div>
                  </td>
                  <td>
                    <span className="text">{timeHoursLabel}</span>
                  </td>*/}
               
              <div style={{marginTop: "20px", display: 'flex'}}>
              <FormControlLabel label={occuranceLabel}
                  control={<Checkbox
                  
                  
                  iconStyle={{marginRight: "10px"}}
                  checked={this.state.handleRecurranceHours ? true : false}
                 
                  onChange={(event) => {
                    let recurrance = this.state.recurrance;
                    if (event.target.checked && this.state.recurrance <= 0) {
                      recurrance = 1;
                    } else if (!event.target.checked) {
                      recurrance = 0;
                    }
                    this.setState(
                      {
                        handleRecurranceHours: event.target.checked,
                        scheduleModified: true,
                        recurrance: recurrance,
                      },
                      this.handleFinishButton
                    );
                  }}
                 
                />
                }

                />
                 </div>
                <div style={{marginTop: "10px", display: 'flex', flexDirection:'row'}} >
                <CustomNumberField
                              id={`recurrenceevery`}
                              type="number"
                              value={this.state.recurrance}
                              style={{ padding: 'none' }}
                              size="small"
                              variant="outlined"
                              onChange={this.updateRecurranceHours}
                              InputProps={{'aria-label':`${occuranceLabel} ${this.state.recurrance} ${hoursLabel}` }}
                            />
                  
                  
                  
                  <span className="text" style={{marginLeft: "10px", marginBottom: '3px'}}>{hoursLabel}
                  </span>
                </div>
             
            </div>
          ) : (
            ""
          )}
          {/* <hr className="divider"/> */}
          {/* <div className="head">{postDiscoveryLabel}</div>
                                                  <div>
                                                  <Checkbox
                                                  label={installRobotLabel}
                                                  labelPosition="right"
                                                  iconStyle={{marginRight:'10px'}}
                                                  checked = {this.state.installRobot?true:false}
                                                  uncheckedIcon={<UnCheckedIcon style={{fill: '#CCCCCC'}} /> } 
                                                  onCheck={(event, isChecked) => {
                                                    this.setState({
                                                      installRobot:isChecked,
                                                    },this.handleFinishButton);
                                                  }}
                                                  style={{display:'inline-block',width:'500px',top:'7px'}}
                                                  />				 	
                                                  <div className='cardInformation__icon' >
                                                  <span className='spacing'><Information title='Information' className='cardInformation__icon--img' /></span>
                                                  {windowsRobotLabel}
                                                  </div>
                                                </div> */}
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    selectedAgent: state.agents.selectedAgent,
    scopesInfo: state.scopes.items,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getScopes: (agentId) => dispatch(getScopes(agentId)),
    pollScopesStart: (agentId) => dispatch(pollScopesStart(agentId)),
    pollScopesStop: () => dispatch(pollScopesStop()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleDiscovery);
                                          