import React, { Component } from 'react'
import './credentialVault.less'
import Resource from './Resource'

/**
 * @Author namam01
 */

class CredentialWelcomeText extends Component{
	constructor(){
		  super();
		  this.state = {}
	  }
	render(){
		let wmiHeader=Resource.get('WMI (Windows Management Instrumentation)   ')//WMI (Windows Management Instrumentation)   
		let wmiLabel1=Resource.get('discovery scans servers running Windows to gather system information.')//discovery scans servers running Windows to gather system information.
		let wmiLabel2=Resource.get('to add a WMI authentication profile, or click on an existing authentication profile to make changes.')//Click + to add a WMI authentication profile, or click on an existing authentication profile to make changes.
		let select =Resource.get('Select ')//Select
		let sshLabel=Resource.get('SSH (Secure Shell) ')//SSH (Secure Shell)
		let snmpLabel=Resource.get('SNMP (Simple Network Management Protocol) discovery supports SNMP versions 1, 2c, and 3 ')//SNMP
		let snmpLableClick=Resource.get('to add an SNMP authentication profile, or click on an existing authentication profile to make changes.')//Snmp Click acton
		let orLabel=Resource.get('or') //or
		let telnetLabel= Resource.get(' Telnet ')// Telnet
		let unixLabel1=Resource.get('to gather system information. ')// to gather system information.
		let unixLabel2=Resource.get('to add a Linux / Unix authentication profile, or click on an existing authentication profile to make changes.')//Click + to add a Linux / Unix authentication profile, or click on an existing authentication profile to make changes.
		let click = Resource.get('Click')//Click
		if(this.props.type==='WMI'){
			return(
					<div className='welcome-text' >
						<div>
							<span style={{fontWeight:600}}>{wmiHeader}</span>
							<span>{wmiLabel1}</span>
						</div>
						<div style={{paddingTop:'20px'}}>
							<span>{click}</span>
							<span className='addIcon-image-disabled'/>
							<span >{wmiLabel2}</span>
						</div>
					</div>
					)
		}else if(this.props.type==='UNIX'){
			return(
					<div className='welcome-text'  >
                        <span>{select}</span>
						<span style={{fontWeight:600}}>{sshLabel}</span>
                        <span>{orLabel}</span>
                        <span style={{fontWeight:600}}>{telnetLabel}</span>
						<span>{unixLabel1}</span>
						<div style={{paddingTop:'20px'}}>
							<span>{click}</span>
							<span className='addIcon-image-disabled'/>
							<span >{unixLabel2}</span>
						</div>
					</div>
					)		
		}else if(this.props.type==='SNMP'){
			return(
					<div className='welcome-text'  >
                        <span>{select}</span>
						<span style={{fontWeight:600}}>{snmpLabel}</span>
                        <span>{orLabel}</span>
                        <span style={{fontWeight:600}}>{telnetLabel}</span>
						<span>{unixLabel1}</span>
						<div style={{paddingTop:'20px'}}>
							<span>{click}</span>
							<span className='addIcon-image-disabled'/>
							<span >{snmpLableClick}</span>
						</div>
					</div>
					)		
		}		
	}
}
 export default CredentialWelcomeText