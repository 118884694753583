// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Robot installer download and instructions
  content["Robot installer download and instructions"] =
  "Robot å®‰è£…ç¨‹åº�ä¸‹è½½å’Œè¯´æ˜Ž";

  //Robots manage the probes (monitoring agents) used to collect metric data from your systems, sending the data to Infrastructure Management via the relay hub.
  content["Robots manage the probes (monitoring agents) used to collect metric data from your systems, sending the data to Infrastructure Management via the relay hub."] =
  "Robot ç®¡ç�†ç”¨äºŽä»Žæ‚¨çš„ç³»ç»Ÿæ”¶é›†åº¦é‡�æ ‡å‡†æ•°æ�®çš„æŽ¢é’ˆ (ç›‘æŽ§ä»£ç�†)ï¼Œé€šè¿‡ä¸­ç»§ç«™å°†æ•°æ�®å�‘é€�åˆ° Infrastructure Managementã€‚";

  //ROBOT INSTALLERS
  content["ROBOT INSTALLERS"] =
  "ROBOT å®‰è£…ç¨‹åº�";

  //Add Devices
  content["Add Devices"] =
  "æ·»åŠ è®¾å¤‡";

  //Device IP
  content["Device IP"] =
  "è®¾å¤‡ IP";

  //OS
  content["OS"] =
  "æ“�ä½œç³»ç»Ÿ";

  //Architecture
  content["Architecture"] =
  "ä½“ç³»ç»“æž„";

  //Username
  content["Username"] =
  "ç”¨æˆ·å��";

  //Password
  content["Password"] =
  "å¯†ç �";

  //Sudo password (Linux only)
  content["Sudo password (Linux only)"] =
  "Sudo å¯†ç � (ä»… Linux)";

  //AUTO DEPLOY
  content["AUTO DEPLOY"] =
  "è‡ªåŠ¨éƒ¨ç½²";

  //MANUAL DEPLOY
  content["MANUAL DEPLOY"] =
  "æ‰‹åŠ¨éƒ¨ç½²";

  //"Deploy" starts the processes of adding devices to UIM.  Enabling monitoring happens on the next step.
  content["Deploy starts the processes of adding devices to UIM.  Enabling monitoring happens on the next step."] =
  "éƒ¨ç½²å�¯åŠ¨å°†è®¾å¤‡æ·»åŠ åˆ° UIM çš„è¿›ç¨‹ã€‚å°†åœ¨ä¸‹ä¸€æ­¥å�¯ç”¨ç›‘æŽ§ã€‚";

  //Click deploy to install the monitoring host.  When complete, click Next.
  content["Click deploy to install the monitoring host.  When complete, click Next."] =
  "å�•å‡»â€œéƒ¨ç½²â€�ä»¥å®‰è£…ç›‘æŽ§ä¸»æœºã€‚å®Œæˆ�å�Žï¼Œè¯·å�•å‡»â€œä¸‹ä¸€æ­¥â€�ã€‚";

  //Deploy
  content["Deploy"] =
  "éƒ¨ç½²";

  //Devices added must be able to communicate with the selected relay hub.
  content["Devices added must be able to communicate with the selected relay hub."] =
  "æ·»åŠ çš„è®¾å¤‡å¿…é¡»èƒ½å¤Ÿä¸Žé€‰å®šä¸­ç»§ç«™é€šä¿¡ã€‚";

  //Ports 48000 to 48025 must be open
  content["Ports 48000 to 48025 must be open"] =
  "ç«¯å�£ 48000 åˆ° 48025 å¿…é¡»å¤„äºŽæ‰“å¼€çŠ¶æ€�";

  //for the relay hub to communicate with the CA Infrastructure Management application.
  content["for the relay hub to communicate with the CA Infrastructure Management application."] =
  "ä»¥ä¾¿ä¸­ç»§ç«™å�¯ä»¥ä¸Ž CA Infrastructure Management åº”ç”¨ç¨‹åº�é€šä¿¡ã€‚";

  //If monitoring cloud-based infrastructure, a relay hub is needed for each isolated virtual network (such as AWS Virtual Private Cloud or Azure Virtual Network).
  content["If monitoring cloud-based infrastructure, a relay hub is needed for each isolated virtual network (such as AWS Virtual Private Cloud or Azure Virtual Network)."] =
  "å¦‚æžœç›‘æŽ§åŸºäºŽäº‘çš„åŸºç¡€æž¶æž„ï¼Œæ¯�ä¸ªç‹¬ç«‹è™šæ‹Ÿç½‘ç»œ (å¦‚ AWS Virtual Private Cloud æˆ– Azure è™šæ‹Ÿç½‘ç»œ) éƒ½éœ€è¦�ä¸­ç»§ç«™ã€‚";

  //Select relay hub
  content["Select a relay hub"] =
  "é€‰æ‹©ä¸­ç»§ç«™";

  //If you are not installing with root access, use either sudo
  content["If you are not installing with root access, use either sudo"] =
  "å¦‚æžœæ‚¨æœªä½¿ç”¨æ ¹è®¿é—®æ�ƒé™�å®‰è£…ï¼Œè¯·ä½¿ç”¨ sudo";

  //You can also use su to get a root shell and execute the command.
  content["You can also use su to get a root shell and execute the command."] =
  "æ‚¨å�¯ä»¥ä½¿ç”¨ su èŽ·å�–æ ¹ shell å¹¶æ‰§è¡Œè¯¥å‘½ä»¤ã€‚";

  //Copy the appropriate Windows installer to any folder on the target system:
  content["Copy the appropriate Windows installer to any folder on the target system:"] =
  "å°†é€‚å½“çš„ Windows å®‰è£…ç¨‹åº�å¤�åˆ¶åˆ°ç›®æ ‡ç³»ç»Ÿä¸Šçš„ä»»ä½•æ–‡ä»¶å¤¹ä¸­:";

  //Copy the nms-robot-vars.cfg answer file to the same folder.
  content["Copy the nms-robot-vars.cfg answer file to the same folder."] =
  "å°† nms-robot-vars.cfg åº”ç­”æ–‡ä»¶å¤�åˆ¶åˆ°å�Œä¸€æ–‡ä»¶å¤¹ä¸­ã€‚";

  //Install the robot by executing:
  content["Install the robot by executing:"] =
  "é€šè¿‡æ‰§è¡Œä»¥ä¸‹å‘½ä»¤å®‰è£… Robot:";

  //Installation
  content["Installation"] =
  "å®‰è£…";

  //Robot Installers (including answer file)
  content["Robot Installers (including answer file)"] =
  "Robot å®‰è£…ç¨‹åº� (åŒ…æ‹¬åº”ç­”æ–‡ä»¶)";

  //The installation is complete. To start the robot, execute the following command:
  content["The installation is complete. To start the robot, execute the following command:"] =
  "å®‰è£…å®Œæˆ�ã€‚è¦�å�¯åŠ¨ Robotï¼Œè¯·æ‰§è¡Œä»¥ä¸‹å‘½ä»¤:";

  //Execute the RobotConfigurer.sh script to configure the robot when the installer exits.
  content["Execute the RobotConfigurer.sh script to configure the robot when the installer exits."] =
  "åœ¨å®‰è£…ç¨‹åº�é€€å‡ºæ—¶æ‰§è¡Œ RobotConfigurer.sh è„šæœ¬ä»¥é…�ç½® Robotã€‚";

  //The rpm flags function as follows:
  content["The rpm flags function as follows:"] =
  "rpm æ ‡è®°ä½œç”¨å¦‚ä¸‹:";

//The default install directory is /opt/nimsoft. To specify the installation directory, execute the following command, where <directory> is the full path:
  content["The default install directory is /opt/nimsoft. To specify the installation directory, execute the following command, where <directory> is the full path:"] =
  "é»˜è®¤å®‰è£…ç›®å½•ä¸º &#x2F;opt&#x2F;nimsoftã€‚è¦�æŒ‡å®šå®‰è£…ç›®å½•ï¼Œè¯·æ‰§è¡Œä»¥ä¸‹å‘½ä»¤ï¼Œå…¶ä¸­ &lt;directory&gt; æ˜¯å®Œæ•´è·¯å¾„:";

//Copy the appropriate Linux installer to /opt on the target system:
  content["Copy the appropriate Linux installer to /opt on the target system:"] =
  "å°†é€‚å½“çš„ Linux å®‰è£…ç¨‹åº�å¤�åˆ¶åˆ°ç›®æ ‡ç³»ç»Ÿä¸Šçš„ &#x2F;opt:";

  //Copy the nms-robot-vars.cfg answer file to &#x2F;opt.
  content["Copy the nms-robot-vars.cfg answer file to &#x2F;opt."] =
  "å°† nms-robot-vars.cfg åº”ç­”æ–‡ä»¶å¤�åˆ¶åˆ° &#x2F;optã€‚";

  //Install the robot with the following command, where &lt;arch&gt;is i386 or x86_64:
  content["Install the robot with the following command, where <arch> is i386 or x86_64:"] =
  "ä½¿ç”¨ä»¥ä¸‹å‘½ä»¤å®‰è£… Robotï¼Œå…¶ä¸­ &lt;arch&gt; æ˜¯ i386 æˆ– x86_64:";

//The default install folder is C:\Program Files (x86)\Nimsoft for 32-bit systems, and C:\Program Files\Nimsoft for 64-bit systems.
  content["The default install folder is C:\\Program Files (x86)\\Nimsoft for 32-bit systems, and C:\\Program Files\\Nimsoft for 64-bit systems."] =
  "é»˜è®¤å®‰è£…æ–‡ä»¶å¤¹æ˜¯ C:\\Program Files (x86)\\Nimsoft (å¯¹äºŽ 32 ä½�ç³»ç»Ÿ) ä»¥å�Š C:\\Program Files\\Nimsoft (å¯¹äºŽ 64 ä½�ç³»ç»Ÿ)ã€‚";

  //To specify the folder, append the following parameter to the command. Quotation marks are required.
  content["To specify the folder, append the following parameter to the command. Quotation marks are required."] =
  "è¦�æŒ‡å®šæ–‡ä»¶å¤¹ï¼Œå°†ä»¥ä¸‹å�‚æ•°é™„åŠ å‘½ä»¤å�Žé�¢ã€‚éœ€è¦�ä½¿ç”¨å¼•å�·ã€‚";

  //To specify the log file, append:
  content["To specify the log file, append:"] =
  "è¦�æŒ‡å®šæ—¥å¿—æ–‡ä»¶ï¼Œè¯·é™„åŠ :";

  //After installation, the robot starts automatically.
  content["After installation, the robot starts automatically."] =
  "å®‰è£…å�Žï¼ŒRobot å°†è‡ªåŠ¨å�¯åŠ¨ã€‚";

//Copy the Debian installer to /opt on the target system:
  content["Copy the Debian installer to /opt on the target system:"] =
  "å°† Debian å®‰è£…ç¨‹åº�å¤�åˆ¶åˆ°ç›®æ ‡ç³»ç»Ÿä¸Šçš„ &#x2F;opt:";

  //Note that only 64 bit Debian systems are supported as robots.
  content["Note that only 64 bit Debian systems are supported as robots."] =
  "è¯·æ³¨æ„�ï¼Œä»…æ”¯æŒ� 64 ä½� Debian ç³»ç»Ÿä½œä¸º Robotã€‚";

  //Install the robot with the following command:
  content["Install the robot with the following command:"] =
  "ä½¿ç”¨ä»¥ä¸‹å‘½ä»¤å®‰è£… Robot:";

  //Copy the Ubuntu installer to /opt on the target system:
  content["Copy the Ubuntu installer to /opt on the target system:"] =
  "å°† Ubuntu å®‰è£…ç¨‹åº�å¤�åˆ¶åˆ°ç›®æ ‡ç³»ç»Ÿä¸Šçš„ &#x2F;opt:";

  //Note that only 64 bit Ubuntu systems are supported as robots.
  content["Note that only 64 bit Ubuntu systems are supported as robots."] =
  "è¯·æ³¨æ„�ï¼Œä»…æ”¯æŒ� 64 ä½� Ubuntu ç³»ç»Ÿä½œä¸º Robotã€‚";

  //(Ubuntu 16 only) Execute the following command to enable the robot service. Note: This step is not required for Ubuntu 14.
  content["(Ubuntu 16 only) Execute the following command to enable the robot service. Note: This step is not required for Ubuntu 14."] =
  "(ä»… Ubuntu 16) æ‰§è¡Œä»¥ä¸‹å‘½ä»¤ä»¥å�¯ç”¨ Robot æœ�åŠ¡ã€‚æ³¨æ„�: Ubuntu 14 ä¸�éœ€è¦�æ­¤æ­¥éª¤ã€‚";

  // END OF LOCALIZATION

export default content;
