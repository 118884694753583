import React, { Component } from 'react'
import connect from './../../../../utils/connect'
import { setBreadCrumbs } from './../../../breadcrumbs/actions'
import { get, save } from './../../../../api/close-rules/actions'
import ClockIcon from '../../../../assets/icons/ic_clock.svg'
import * as IntegrationError from './../../integrations/IntegrationError'
import {
  Select as DropDownMenu,
  MenuItem,
  Button as RaisedButton,
  Button as FlatButton,
  LinearProgress,  
} from '@mineral/core'
import {CenterError} from '../../../ui-components/uim-components'
import {CircularProgress} from '@mineral/core'
import InlineMessage from './../../../message/InlineMessage'
import * as messageTypes from './../../../message/InlineMessageTypes'
import './CloseRuleConfig.less'
import CloseCheckbox from './CloseCheckbox'
import SeverityIcon, {
  LEVEL_CRITICAL,
  LEVEL_MAJOR,
  LEVEL_MINOR,
  LEVEL_WARNING,
  LEVEL_INFO,
} from './../../../icons/SeverityIcon'
import Resource from './Resource'

const styles = {
  fontSize: {
    fontSize: 16,
  },
  flatButtonMargin: {
    marginRight: 5,
  },
}

class CloseRuleConfig extends Component {
  componentWillMount() {
    this.props.setBreadCrumbs([
      {
    	//Settings   
        name: Resource.get('Settings'),
        link: '/settings',
      },
      {
    	//Close Rule  
        name: Resource.get('Close Rule'),
        link: '/settings/close-rules',
      },
    ])

    this.props.fetchConfig()
  }
  state = {
    isDirty: false,
  }

  stateChanged = () => {
    this.setState({ isDirty: true })
  }

  handleCancel = () => {
    this.props.history.push('/settings')
  }

  handleChange = (event) => {
    this.stateChanged()
    this.props.config.timeDuration = event.target.value
  }

  handleSeverityCheck = (isChecked, severity) => {
    var items = this.props.config.severityOptions
    var index
    if (items) {
      if (isChecked) {
        index = items.indexOf(severity)
        if (index === -1) {
          items.push(severity)
          this.props.config.severityOptions = items
          this.stateChanged()
        }
      } else {
        index = items.indexOf(severity)
        if (index > -1) {
          items.splice(index, 1)
          this.props.config.severityOptions = items
          this.stateChanged()
        }
      }
    }
  }

  handleSave = () => {
    this.props.save(
      {
        ...this.props.config,
      },
      this.saveSuccess,
    )
  }

  saveButtonDisabled = () => {
    if (this.state.isDirty) {
      if (this.props.config.severityOptions !== undefined && this.props.config.severityOptions.length > 0) {
        return false
      } else {
        return true
      }
    } else {
      return true
    }
  }

  saveSuccess = () => {
    this.props.history.push('/settings')
  }

  constructor(props) {
    super(props)
    this.state = { value: this.props.config.timeDuration }
  }

  getGeneralErrorMessageComponent = () => {
    const generalError = IntegrationError.getErrorMessageForKey(
      IntegrationError.ERROR_GENERAL,
      this.props.responseError,
    )
    if (generalError !== null) {
      return <InlineMessage type={messageTypes.ERROR} message={generalError} />
    }

    return null
  }

  getSeverityCheckboxState = level => {
    if (this.props.config.severityOptions) {
      return this.props.config.severityOptions.indexOf(level) >= 0
    }

    return false
  }

  render() {
    let fetchErrorMessage = IntegrationError.getErrorMessageForKey(
      IntegrationError.ERROR_FETCH,
      this.props.responseError,
    ),
    timeMsg1 = Resource.get('1 hour'),////1 hour
    timeMsg2 = Resource.get('12 hours'),//12 hours
    timeMsg3 = Resource.get('24 hours'),//24 hours
    timeMsg4 = Resource.get('3 days'),//3 days
    timeMsg5 = Resource.get('1 week'),//1 week
    timeMsg6 = Resource.get('2 weeks'),//2 weeks
    timeMsg7 = Resource.get('1 month'),//1 month
    timeMsg8 = Resource.get('3 months'),//3 months
    NeverMsg = Resource.get('Never');//Never
    if (this.props.isFetching) {
      return (
        <div>
          <LinearProgress style={{ height: '8px' }} />
        </div>
      )
    } else if (fetchErrorMessage) {
      return (
        <div style={{ display: 'flex', alignItems: 'center', marginTop: '100px' }}>
          //Communication Error
          <CenterError message={fetchErrorMessage} title= {Resource.get('Communication Error')} />
        </div>
      )
    }
    return (
      <div>
        <div className="close-rule-wrapper">
          {this.getGeneralErrorMessageComponent()}
          <div className="close-rule__content">
            <div className="close-rule__leftcontent">
              <div className="close-rule__header">{//Close Rule
            	  Resource.get('Close Rule')}</div>
            
              <div className="close-rule__body">
                <div className="close-rule__item">
                  <div className="automatically-close">
                  {// help message
                    Resource.get('Automatically close non-threshold violating alarms (for example, system log events or server reboots) if they have been open more than...')}
                  </div>
                  <div className="atom-text-input-copy">
                    <div className="drop-down-icon">
                      <img className="icon-ic-filter" src={ClockIcon} role="figure" />
                    </div>
                    <DropDownMenu
                      value={this.props.config.timeDuration}
                      onChange={this.handleChange}
                      style={styles.fontSize}>
                      <MenuItem value={1} label="1 hour" children= {timeMsg1} />
                      <MenuItem value={12} label="12 hours" children= {timeMsg2} />
                      <MenuItem value={24} label="24 hours" children= {timeMsg3} />
                      <MenuItem value={3 * 24} label="3 days" children= {timeMsg4} />
                      <MenuItem value={7 * 24} label="1 week" children= {timeMsg5} />
                      <MenuItem value={14 * 24} label="2 weeks" children= {timeMsg6} />
                      <MenuItem value={30 * 24} label="1 month" children= {timeMsg7} />
                      <MenuItem value={90 * 24} label="3 months" children= {timeMsg8} />
                      <MenuItem value={-1} label="Never" children= {NeverMsg} />
                    </DropDownMenu>
                  </div>
                </div>
                <div className="close-rule__item">
                  <div className="sub-heading-two">{// Close Rule help message
                	  Resource.get('And their severity is...')}</div>
                  <div className="close-rule__item">
                    <div className="checkbox-parent" style={{ display: 'inline-block', verticalAlign: 'top' }}>
                      <CloseCheckbox
                        disabled={this.props.isSaving}
                        icon={<SeverityIcon level={LEVEL_CRITICAL} size="24px" />}
                        //Critical
                        label= {Resource.get('Critical')}
                        checked={this.getSeverityCheckboxState(LEVEL_CRITICAL)}
                        onCheck={(event, isChecked) => {
                          this.handleSeverityCheck(isChecked, LEVEL_CRITICAL)
                        }}
                      />
                      <CloseCheckbox
                        disabled={this.props.isSaving}
                        icon={<SeverityIcon level={LEVEL_MAJOR} size="24px" />}
                        //Major
                        label= {Resource.get('Major')}
                        checked={this.getSeverityCheckboxState(LEVEL_MAJOR)}
                        onCheck={(event, isChecked) => {
                          this.handleSeverityCheck(isChecked, LEVEL_MAJOR)
                        }}
                      />
                      <CloseCheckbox
                        disabled={this.props.isSaving}
                        icon={<SeverityIcon level={LEVEL_MINOR} size="24px" />}
                        //Minor
                        label= {Resource.get('Minor')}
                        checked={this.getSeverityCheckboxState(LEVEL_MINOR)}
                        onCheck={(event, isChecked) => {
                          this.handleSeverityCheck(isChecked, LEVEL_MINOR)
                        }}
                      />
                    </div>
                    <div style={{ display: 'inline-block', verticalAlign: 'top', marginLeft: '48px' }}>
                      <CloseCheckbox
                        disabled={this.props.isSaving}
                        icon={<SeverityIcon level={LEVEL_WARNING} size="24px" />}
                        //Warning
                        label= {Resource.get('Warning')}
                        checked={this.getSeverityCheckboxState(LEVEL_WARNING)}
                        onCheck={(event, isChecked) => {
                          this.handleSeverityCheck(isChecked, LEVEL_WARNING)
                        }}
                      />
                      <CloseCheckbox
                        disabled={this.props.isSaving}
                        icon={<SeverityIcon level={LEVEL_INFO} size="24px" />}
                        //Info
                        label= {Resource.get('Info')}
                        checked={this.getSeverityCheckboxState(LEVEL_INFO)}
                        onCheck={(event, isChecked) => {
                          this.handleSeverityCheck(isChecked, LEVEL_INFO)
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="close-rule__footer">
                <FlatButton
                  //CANCEL
                  children= {Resource.get('CANCEL')}
                  variant= "text"
                  color="primary"
                  disabled={this.props.isSaving}
                  onClick={this.handleCancel}
                  style={styles.flatButtonMargin}
                />

                {this.props.isSaving
                  ? <RaisedButton variant="contained" disabled={true} >
                      <CircularProgress style={{color: '#3272D9'}} />
                    </RaisedButton>
                  : <RaisedButton
                  variant="contained"
                      disabled={this.saveButtonDisabled()}
                      //SAVE
                      children= {Resource.get('SAVE')}
                      color ="primary"
                      onClick={this.handleSave}
                    />}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setBreadCrumbs: items => dispatch(setBreadCrumbs(items)),
    fetchConfig: () => dispatch(get()),
    save: (config, successCallback, failureCallback) => dispatch(save(config, successCallback, failureCallback)),
  }
}

const mapStateToProps = state => {
  return {
    isValidating: state.closeRule.isValidating,
    isFetching: state.closeRule.isFetching,
    isSaving: state.closeRule.isSaving,
    state: state.closeRule.config.state,
    config: state.closeRule.mutableConfig,
    responseError: state.closeRule.responseError,
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CloseRuleConfig)
