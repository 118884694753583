// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Settings
  content["Settings"] =
  "设置";

  //INFRASTRUCTURE
  content["INFRASTRUCTURE"] =
  "基础架构";

  //SYSTEM ADMINISTRATION
  content["SYSTEM ADMINISTRATION"] =
  "系统管理";

  //INTEGRATIONS
  content["INTEGRATIONS"] =
  "集成";

  //ALARMS AND NOTIFICATIONS
  content["ALARMS AND NOTIFICATIONS"] =
  "警报和通知";

  // END OF LOCALIZATION

export default content;
