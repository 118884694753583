// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Elements
  content["Elements"] =
  "Elements";

  //Total Alarms
  content["Total Alarms"] =
  "Total Alarms";

  //Graph Error message
  content["The data required for this graph is not currently available."] =
  "The data required for this graph is not currently available.";

  //No results found
  content["No results found"] =
  "No results found";

  //Edit group Text
  content["Edit Group"] =
  "Edit Group";

  //No devices have been added
  content["No devices have been added"] =
  "No devices have been added";

  //No subgroups have been added
  content["No subgroups have been added"] =
  "No subgroups have been added";

  // END OF LOCALIZATION

export default content;
