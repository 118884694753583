const EntityTypes = {
  ROOT: "ROOT",
  STATIC: "STATIC",
  DYNAMIC: "DYNAMIC",
  AUTOMATIC: "AUTOMATIC",
  CONTAINER: "CONTAINER",
  // a group is (ROOT || CONTAINER || STATIC || DYNAMIC || AUTOMATIC)
  GROUP: "GROUP",
  COMPUTER_SYSTEM: "COMPUTER_SYSTEM",
  INTERFACES: "INTERFACES",
  NETWORK_INTERFACE: "NetworkInterface",
};

export default EntityTypes;
