// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //App Experience Analytics
  content["App Experience Analytics"] =
  "アプリ エクスペリエンス分析";

  //Infrastructure Management
  content["Infrastructure Management"] =
  "Infrastructure Management";

  //Application Performance Management
  content["Application Performance Management"] =
  "Application Performance Management";

  //Digital Operational Intelligence
  content["Digital Operational Intelligence"] =
  "デジタル操作インテリジェンス";

  //Launch Pad
  content["Launch Pad"] =
  "起動パッド";

  //Capacity Predective Analytics
  content["Capacity Predictive Analytics"] =
  "容量予測分析";

  //Single Sign on Dashboards
  content["Single Sign on Dashboards"] =
  "ダッシュボードのシングル サイン オン";

  //Single Sign on Dashboards
  content["Network Operations Analytics"] =
  "ネットワーク操作分析";

  // END OF LOCALIZATION

export default content;
