import React from 'react'
import './icons.less'

const Icon = (props) => {
  let classes = [
    'icon',
    props.className,
  ].join(' ')
  return (
    <svg className={classes} role={props.role || 'figure'} aria-label={props['aria-label']?props['aria-label']:"[title]"} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g>
        <title>{props.title}</title>
        <path fillRule="evenodd" d="M12,16 C13.1,16 14,16.9 14,18 C14,19.1 13.1,20 12,20 C10.9,20 10,19.1 10,18 C10,16.9 10.9,16 12,16 L12,16 Z M12,14 C10.9,14 10,13.1 10,12 C10,10.9 10.9,10 12,10 C13.1,10 14,10.9 14,12 C14,13.1 13.1,14 12,14 L12,14 Z M12,8 C10.9,8 10,7.1 10,6 C10,4.9 10.9,4 12,4 C13.1,4 14,4.9 14,6 C14,7.1 13.1,8 12,8 L12,8 Z"/>
      </g>
    </svg>
  )
}

export default Icon
