import React from 'react'
import './icons.less'

const Icon = (props) => {
let classes = [
  'icon',
  props.className,
].join(' ')
return (
  <svg className={classes} role={props.role || 'figure'} aria-label={props['aria-label']?props['aria-label']:"[title]"} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
  <g>
    <title>{props.title}</title>
    <path fillRule="evenodd" d="M15.502,9.491 L14.708,9.491 L14.432,9.765 C15.407,10.902 16,12.376 16,13.991 C16,17.581 13.09,20.491 9.5,20.491 C5.91,20.491 3,17.581 3,13.991 C3,10.401 5.91,7.491 9.5,7.491 C11.115,7.491 12.588,8.083 13.725,9.057 L14.001,8.783 L14.001,7.991 L18.999,3 L20.49,4.491 L15.502,9.491 L15.502,9.491 Z M9.5,9.491 C7.014,9.491 5,11.505 5,13.991 C5,16.476 7.014,18.491 9.5,18.491 C11.985,18.491 14,16.476 14,13.991 C14,11.505 11.985,9.491 9.5,9.491 L9.5,9.491 Z" transform="matrix(1 0 0 -1 0 23.491)"/>
  </g>
  </svg>
)
}

export default Icon