// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Performance Reports Designer
  content["Performance Reports Designer"] =
  "Performance Reports Designer";

  //Settings
  content["Settings"] =
  "Settings";

  //The Performance Reports Designer allows you to see a visual representation of QoS data
  content["The Performance Reports Designer allows you to see a visual representation of QoS data"] =
  "The Performance Reports Designer allows you to see a visual representation of QoS data";

  // END OF LOCALIZATION

export default content;
