import React, { Component } from 'react'
import {InfoIcon,} from '../../ui-components/uim-components'
import {Dialog, DialogTitle} from "@mineral/core"
import RobotDeployForm from './RobotDeployForm'
import ManualDeployInfo from './ManualDeployInfo'
import DeployError from './DeployError'
import './mcsRobotDeploy.less'

class McsRobotDeploy extends Component {
  state = {
    open: this.props.open || false,
    error: false,
  }
  handleClose = () => {
    this.setState({ open: false });
    if (this.props.onClose) {
      this.props.onClose()
    }
  }
  handleOpen = () => {
    this.setState({ open: true });
  }
  render() {
    return (
      <Dialog
       /*  title={
          <div className='mcs-robot-deploy__title'>
            <InfoIcon />
            Local robot required
          </div>
        } */
       /*  titleStyle={{
          paddingLeft: '1rem',
        }} */
        style={{
          padding: null,
        }}
        modal={true}
        open={this.state.open}
        aria-labelledby="dialog-title"
        >
        <DialogTitle id="dialog-title" style={{paddingLeft: '1rem'}}>{
          <div className='mcs-robot-deploy__title'>
            <InfoIcon />
            Local robot required
          </div>
        }</DialogTitle>
        {this.state.error ? <DeployError helperText={this.state.error.errorMessage} /> : null}
        <RobotDeployForm
          close={this.handleClose}
          device={this.props.device}
          handleError={error => {
            this.setState({ error })
          }} />
        <ManualDeployInfo />
      </Dialog>
    )
  }
}
export default McsRobotDeploy