import React, { Component } from 'react'
import { InfoIcon} from '../ui-components/uim-components'
import { Dialog,DialogTitle, Button as FlatButton, DialogActions} from "@mineral/core"
import ManualDeployInfo from './../robotDeploy/mcs/ManualDeployInfo'
import './groupConfigurationInfo.less'
import Link from './../common/reactRouter/Link'

class NoRobotRedirect extends Component {
  state = {
    open: this.props.open || false,
  }
  handleClose = () => {
    this.setState({ open: false })
    if (this.props.onClose) {
      this.props.onClose()
    }
  }
  handleOpen = () => {
    this.setState({ open: true })
  }
  render() {
    const {
      deviceCount,
      deviceHasRobotCount,
    } = this.props

    let message = (
      <span> WMI or SSH access with installation permissions are required for automated robot install using <Link to='/getting-started'>Setup Wizard</Link>. For other situations (e.g. AWS & Azure or no installation permission) use the manual installation process through the Setup Wizard.</span>
    )
    return (
      <Dialog
        className='group-config__dialog'        
        style={{
          marginLeft: 'calc(24px + 1rem)',
        }}
        modal={true}
        open={this.state.open}
        aria-labelledby="dialog-title"
        >
          <DialogTitle id="dialog-title"> {
          <div className='group-config__title'>
            <InfoIcon />
            Unable to configure all devices
          </div>
        }</DialogTitle>
        <div className="group-config__dialog_body">
          <p>This group contains a mix of remotely and locally-monitored devices.</p>
          <p>{deviceCount} devices selected…</p>
          <p>{deviceHasRobotCount} locally-monitored devices will be configured on the next screen(s). However, Remote System Monitoring can be configured on all the devices in the Group.</p>
          <p>Deploy robots to the {deviceCount - deviceHasRobotCount} remotely-monitored devices to enable more monitoring options.</p>
        </div>
        <ManualDeployInfo message={message}/>
        <DialogActions>
          {[
            <FlatButton
              variant="text"
              children='OK'
              color="primary"
              onClick={this.handleClose}
            />
          ]}
        </DialogActions>
      </Dialog>
    )
  }
}
export default NoRobotRedirect
