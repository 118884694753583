import React from 'react'
import './icons.less'
import './spinner.less'

const Icon = props => {
  return (
    <svg
      className={`icon spinner ${props.className || ''}`}
      role={props.role || 'figure'}
      aria-label="[title]"
      width="65px"
      height="65px"
      viewBox="0 0 66 66"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <title>{props.title}</title>
        <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30" />
      </g>
    </svg>
  )
}

export default Icon
