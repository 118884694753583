import axios from 'axios'
import uniq from 'lodash/uniq'
import config from './../config'

const url = `${config.basename}/api/v1/robots/robot-deployment-status`

const toQueryString = (paramsObject) => {
  return Object
    .keys(paramsObject)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(paramsObject[key])}`)
    .join('&')
}

class RobotsStatus {
  get(params={}) {
    if (params.adeJobIds) {
      params = Object.assign({}, params, {
        adeJobIds: uniq(params.adeJobIds)
      })
    }
    return axios.get(url, {
      params: params,
      paramsSerializer: toQueryString,
    })
  }
  deployProbe(inputs={}) {
    return axios.post(`${config.basename}/api/v1/robots/probe-deploypackage?probename=`+inputs.probe.name, inputs.data)
  }
  deployProbeStatus(inputs=[]){
    return axios.get(`${config.basename}/api/v1/robots/probe-deployment-status?adeJobIds=`+inputs)
  }
}

export default new RobotsStatus()
