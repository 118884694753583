import {
  FETCH_API_COMPONENT_INFO_SUCCESS,
  SET_SHOWING_COMPONENT_INFO
} from './actionTypes'

export const DefaultState = {
  isShowingComponentInfo: false,
  apiInfo: {},
};

const reducer = (state = DefaultState, action) => {
  switch(action.type) {
    case SET_SHOWING_COMPONENT_INFO:
      return {
        ...state,
        isShowingComponentInfo:action.isShowingComponentInfo,
      };
    case FETCH_API_COMPONENT_INFO_SUCCESS:
      return {
        ...state,
        apiInfo: action.info,
      };
    default:
      return state;
  }
};

export default reducer;
