// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Relay hubs & robots
  content["Relay hubs & robots"] =
  "Concentradores de transmisión y robots";

  //Managed via
  content["Managed via"] =
  "Gestionado por medio de";

  //Admin Console
  content["Admin Console"] =
  "Consola de administración";

  //Deleted devices
  content["Deleted devices"] =
  "Dispositivos suprimidos";

  //Available for restore
  content["Available for restore"] =
  "Disponible para la restauración";

  //Name
  content["Name"] =
  "Nombre";

  //IP address
  content["IP address"] =
  "Dirección IP";

  //Role
  content["Role"] =
  "Rol";

  //OS Type
  content["OS Type"] =
  "Tipo de SO";

  //OS Name
  content["OS Name"] =
  "Nombre del SO";

  //OS Version
  content["OS Version"] =
  "Versión del SO";

  //Settings
  content["Settings"] =
  "Configuración";

  //Deleted Devices
  content["Deleted Devices"] =
  "Dispositivos suprimidos";

  //Filter
  content["Filter"] =
  "Filtrar";

  //Cancel
  content["Cancel"] =
  "Cancelar";

  //Restore
  content["Restore"] =
  "Restaurar";

  // END OF LOCALIZATION

export default content;
