// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Relay hubs & robots
  content["Relay hubs & robots"] =
  "中继站和 Robot";

  //Deleted devices
  content["Deleted devices"] =
  "已删除设备";

  //Name
  content["Name"] =
  "名称";

  //IP address
  content["IP address"] =
  "IP 地址";

  //Role
  content["Role"] =
  "角色";

  //OS Type
  content["OS Type"] =
  "操作系统类型";

  //OS Name
  content["OS Name"] =
  "操作系统名称";

  //OS Version
  content["OS Version"] =
  "操作系统版本";

  //Settings
  content["Settings"] =
  "设置";

  //Deleted Devices
  content["Deleted Devices"] =
  "已删除设备";

  //Filter
  content["Filter"] =
  "筛选";

  // END OF LOCALIZATION

export default content;
