import {
  REQUEST_REPORTS_FOLDER,
  FETCH_REPORTS_FOLDER_SUCCESS,
  FETCH_REPORTS_FOLDER_FAILURE
} from './actionTypes'
import fetchStatusTypes from '../fetchStatusTypes'

const reports = (state = {
  isFetching: false,
  fetchStatus: fetchStatusTypes.UNDEFINED,
  didInvalidate: false,
  items: []
}, action) => {

  switch (action.type) {
    case REQUEST_REPORTS_FOLDER:
      return Object.assign({}, state, {
        isFetching: true,
        fetchStatus: fetchStatusTypes.FETCHING,
        didInvalidate: false,
        items: []
      });
    case FETCH_REPORTS_FOLDER_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        fetchStatus: fetchStatusTypes.COMPLETE,
        didInvalidate: false,
        items: action.items.data.resourceLookup
      });
    case FETCH_REPORTS_FOLDER_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        fetchStatus: fetchStatusTypes.FAIL,
        didInvalidate: true,
        errorMessage: action.errorMessage,
        items: []
      });
    default:
      return state
  }
};

export default reports
