import {
  FETCH_METRICS,
  FETCH_METRICS_SUCCESS,
  FETCH_METRICS_FAILURE,
  FETCH_METRICS_DEVICES,
  FETCH_METRICS_DEVICES_SUCCESS,
  FETCH_METRICS_DEVICES_FAILURE,
  CLEAR_CHART_DATA,
  SAVE_NAMED_METRICS,
  SAVE_NAMED_METRICS_FAILURE,
  SAVE_NAMED_METRICS_SUCCESS,
  FETCH_NAMED_METRICS,
  FETCH_NAMED_METRICS_FAILURE,
  FETCH_NAMED_METRICS_SUCCESS,
  FETCH_NAMED_METRICS_BY_ID,
  FETCH_NAMED_METRICS_BY_ID_FAILURE,
  FETCH_NAMED_METRICS_BY_ID_SUCCESS,
  DELETE_NAMED_VIEWS,
  DELETE_NAMED_VIEWS_SUCCESS,
  DELETE_NAMED_VIEWS_FAILURE,
  GET_DEVICE,
  GET_DEVICE_SUCCESS,
  GET_DEVICE_FAILURE,
  EXPORT_METRICS,
  EXPORT_METRICS_SUCCESS,
  EXPORT_METRICS_FAILURE,
  COPY_METRICS,
  COPY_METRICS_SUCCESS,
  COPY_METRICS_FAILURE,  
  COPY_DEVICE_METRICVIEW,
  COPY_DEVICE_METRICVIEW_SUCCESS,
  COPY_DEVICE_METRICVIEW_FAILURE,
} from "./actionTypes";

const metric_data = (
  state = {
    isFetching: false,
    didInvalidate: false,
    metrics: [],
    error: "",
    isFetchingDevice: false,
    didInvalidateDevice: false,
    metricsForDevices: [],
    errorDevice: "",
    deviceIds: "",
    selectedMetrics: [],
    hours: "",
    endDate: null,
    selectedCIs: {},
    isInterface: false,
    isSaving: false,
    didInvalidateSave: false,
    errorInSaving: "",
    savedData: {},
    isFetchingAll: false,
    didInvalidateFetchAll: false,
    errorInFetchingAll: "",
    named_metric_list: { MyViews: [], SharedViews: [] },
    isFetchingById: false,
    didInvalidateFetchById: false,
    errorInFetchingById: "",
    named_metric_data: {},
    deleteMsg: "",
    exportData: [],
    isExportFetching: false,
    didInvalidateExportFetch: false,
    errorInExportFetch: "",
    isCopyFetching: false,
    didInvalidateCopyFetch: false,
    errorInCopyFetch: "",
    isCopySuccess: false,
    isNextPage:false,
    copyMetricViewDeviceLevel: [],
    copyMetricViewDeviceLevelErrorMsg: "",
    copyMetricViewDeviceLevelSuccess: false
  },
  action
) => {
  switch (action.type) {
    case FETCH_METRICS:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false,
      });
    case FETCH_METRICS_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        metrics: action.metrics,
      });
    case FETCH_METRICS_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        error: action.errorMessage,
        metrics: [],
      });
    case FETCH_METRICS_DEVICES:
      return Object.assign({}, state, {
        isFetchingDevice: true,
        didInvalidateDevice: false,
      });
    case FETCH_METRICS_DEVICES_SUCCESS:
      return Object.assign({}, state, {
        isFetchingDevice: false,
        didInvalidateDevice: false,
        metricsForDevices: action.metricData,
        deviceIds: action.deviceIds,
        selectedMetrics: action.metricTypes,
        hours: action.hours,
        selectedCIs: action.selectedCIs,
        isInterface: action.isInterface,
        endDate: action.endDate,
      });
    case FETCH_METRICS_DEVICES_FAILURE:
      return Object.assign({}, state, {
        isFetchingDevice: false,
        didInvalidateDevice: true,
        errorDevice: action.errorMessage,
        deviceIds: action.deviceIds,
        selectedMetrics: action.metricTypes,
        hours: action.hours,
        selectedCIs: action.selectedCIs,
        isInterface: action.isInterface,
        endDate: action.endDate,
      });
    case CLEAR_CHART_DATA:
      return Object.assign({}, state, {
        metricsForDevices: [],
        deviceIds: "",
        selectedMetrics: [],
        selectedCIs: {},
        hours: 24,
        endDate: null,
      });
    case SAVE_NAMED_METRICS:
      return Object.assign({}, state, {
        isSaving: true,
        didInvalidateSave: false,
        savedData: {},
        isUpdateView: action.isUpdateView,
      });
    case SAVE_NAMED_METRICS_SUCCESS:
      return Object.assign({}, state, {
        isSaving: false,
        didInvalidateSave: false,
        savedData: action.named_metric,
        isUpdateView: action.isUpdateView,
      });
    case SAVE_NAMED_METRICS_FAILURE:
      return Object.assign({}, state, {
        isSaving: false,
        didInvalidateSave: true,
        errorInSaving: action.errorMessage,
        savedData: {},
      });
    case FETCH_NAMED_METRICS:
      return Object.assign({}, state, {
        isFetchingAll: true,
        didInvalidateFetchAll: false,
      });
    case FETCH_NAMED_METRICS_SUCCESS:
      return Object.assign({}, state, {
        isFetchingAll: false,
        didInvalidateFetchAll: false,
        named_metric_list: action.named_metric_list,
      });
    case FETCH_NAMED_METRICS_FAILURE:
      return Object.assign({}, state, {
        isFetchingAll: false,
        didInvalidateFetchAll: true,
        errorInFetchingAll: action.errorMessage,
      });
    case FETCH_NAMED_METRICS_BY_ID:
      return Object.assign({}, state, {
        isFetchingById: true,
        didInvalidateFetchById: false,
      });
    case FETCH_NAMED_METRICS_BY_ID_SUCCESS:
      return Object.assign({}, state, {
        isFetchingById: false,
        didInvalidateFetchById: false,
        named_metric_data: action.named_metric_data,
        savedData: {},
      });
    case FETCH_NAMED_METRICS_BY_ID_FAILURE:
      return Object.assign({}, state, {
        isFetchingById: false,
        didInvalidateFetchById: true,
        errorInFetchingById: action.errorMessage,
      });
    case DELETE_NAMED_VIEWS:
      return Object.assign({}, state, {
        isdeletingView: true,
        deleteMsg: "",
      });
    case DELETE_NAMED_VIEWS_SUCCESS:
      return Object.assign({}, state, {
        isdeletingView: false,
        deleteMsg: action.deleteMsg,
      });
    case DELETE_NAMED_VIEWS_FAILURE:
      return Object.assign({}, state, {
        isdeletingView: false,
        deleteMsg: "",
      });
    case GET_DEVICE:
      return Object.assign({}, state, {
        searchDeviceResults: "",
      });
    case GET_DEVICE_SUCCESS:
      return Object.assign({}, state, {
        searchDeviceResults: action.devices.computerSystemList,
        isNextPage:action.devices.nextPage,
      });
    case GET_DEVICE_FAILURE:
      return Object.assign({}, state, {
        searchDeviceResults: "",
      });
    case EXPORT_METRICS:
      return Object.assign({}, state, {
        isExportFetching: true,
        didInvalidateExportFetch: false,
        errorInExportFetch: "",
      });
    case EXPORT_METRICS_SUCCESS:
      return Object.assign({}, state, {
        exportData: action.exportData,
        isExportFetching: false,
        didInvalidateExportFetch: false,
        errorInExportFetch: "",
      });
    case EXPORT_METRICS_FAILURE:
      return Object.assign({}, state, {
        isExportFetching: false,
        didInvalidateExportFetch: true,
        errorInExportFetch: action.error,
      });
    case COPY_METRICS:
      return Object.assign({}, state, {
        isCopyFetching: true,
        isCopySuccess: false,
        didInvalidateCopyFetch: false,
        errorInCopyFetch: "",
      });
    case COPY_METRICS_SUCCESS:
      return Object.assign({}, state, {
        isCopyFetching: false,
        isCopySuccess: true,
        didInvalidateCopyFetch: false,
        errorInCopyFetch: "",
      });
    case COPY_METRICS_FAILURE:
      return Object.assign({}, state, {
        isCopyFetching: false,
        didInvalidateCopyFetch: true,
        isCopySuccess: false,
        errorInCopyFetch: action.error,
      });
    case COPY_DEVICE_METRICVIEW_SUCCESS:
      return Object.assign({}, state, {
        copyMetricViewDeviceLevel: action.data,
        copyMetricViewDeviceLevelSuccess: true,
        copyMetricViewDeviceLevelErrorMsg: ""
      });
    case COPY_DEVICE_METRICVIEW_FAILURE:
      return Object.assign({}, state, {
        copyMetricViewDeviceLevel: [],
        copyMetricViewDeviceLevelSuccess: false,
        copyMetricViewDeviceLevelErrorMsg: action.errorMessage
      });
    default:
      return state;
  }
};

export default metric_data;
