
import config from '../config'; 
import axios from 'axios';


export function resetPassword(params) {

const url = [config.basename, `api/changepassword`].join("/");

let formData = new FormData();
formData.append('oldpwd', params.oldPassword);
formData.append('newpwd', params.newPassword);

return axios.post(url, formData);

}

  
