import React, { Component, Fragment } from "react";
import {
  TextField,
  MenuItem,
  Button,
  List,
  ListItem,
  Divider,InputLabel,FormControl
} from "@mineral/core";
//import { DatePicker , DateTimePicker } from "@material-ui/pickers";
/*import DateTextField from "@mui/material/TextField";
import MobileDateTimePicker from "@mui/lab/MobileDateTimePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";*/
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { TimeIcon } from "../../../ui-components/uim-components";
import { OutlinedInput, Popover, Tooltip } from "@mineral/core";
import moment from "moment/moment";
import { useDebugValue } from "react";
import "./timeRange.less";
import { isEqual } from "lodash";

import { MineralDateTimePicker } from '../../../common/datepickers/MineralDateTimePicker'

import HelpingText from '../../../common/HelpingText'
import DatePickerIcon from '../../../icons/DatePickerIcon'
const items = [
  { value: 1, text: "1 Hour" },
  { value: 12, text: "12 Hours" },
  { value: 24, text: "24 Hours" },
  // { value: 24 * 3, text: "3 Days" },
  { value: 24 * 7, text: "1 Week" },
  // { value: 24 * 7 * 2, text: "2 Weeks" },
  { value: 24 * 30, text: "1 Month" },
  { value: 24 * 30 * 3, text: "3 Months" },
];

const today = new Date();
const maxStartDate = new Date(today.setHours(today.getHours() - 1));
const prevYear = new Date(new Date().setFullYear(today.getFullYear() - 1));

class TimeRange extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value,
      isFetching: this.props.isFetching,
      showRange: false,
      startDate: maxStartDate,
      startTime: null,
      endDate: new Date(),
      endTime: null,
      selectedRange: this.props.value,
      interval: this.props.interval,
    };
  }

  /*  componentDidMount() {
    let initialVal = items.filter(
      ({ value, text }) => value == this.props.value
    )[0];
    let selectedRange = initialVal ? initialVal.text : "Custom";
    this.setState({
      selectedRange,
    });

    window.addEventListener("click", (e) => {
      !(
        (document.getElementById("timeRangeDropDown") &&
          document.getElementById("timeRangeDropDown").contains(e.target)) ||
        this.state.pickerOpen
      ) && this.setState({ showRange: false });
    });
  } */
  setTimeRange = () => {
    let initialVal = items.filter(
      ({ value, text }) => value == this.props.value
    )[0];
    let setVal = initialVal ? initialVal.text : "Custom";
    return setVal;
  };

  toggleRange = (event) => {
    if (this.state.showRange) {
      this.setState({ showRange: null });
    } else {
      this.setState({ showRange: event.target });
    }
  };

  componentWillReceiveProps(nextProps) {
    if (!isEqual(nextProps.interval, this.props.interval)) {
      this.setState({ interval: nextProps.interval });
    }
  }

  handleDateChange = (event, key) => {
    let date = moment(event).toDate();
    return this.setState({ [key]: date });
  };
  handleApply = () => {
    // let hours = Math.floor(
    //   Math.abs(this.state.endDate - this.state.startDate) / 36e5
    // );
    // if (hours < 1) {
    //   hours = 1
    // }
    let hours = Math.abs(this.state.endDate - this.state.startDate) / 36e5;
    this.props.onChange(hours, this.state.endDate.getTime(), "Custom");
    return this.setState({
      value: hours,
      showRange: false,
      selectedRange: "Custom",
    });
  };
  handleRangeClick = (value) => {
    this.props.onChange(value, new Date().getTime(), "");
    return this.setState({
      value,
      startDate: maxStartDate,
      endDate: new Date(),
      showRange: false,
      selectedRange: value,
      // pickerOpen: false,
    });
  };

  render() {
    //let rangeDiffMinutes = Math.round(Math.abs(this.state.endDate - this.state.startDate) / 6e4);
    return (
      <div>
        <div
          id="timeRangeDropDown"
          className="drop-down-container"
          style={{  width: "100%" }}
        >
           <FormControl >
            <InputLabel style={{marginBottom: '4px',height:'24px'}} id="timeRangeLabel" htmlFor="timeRange">Time Range</InputLabel>
              <OutlinedInput id={"timeRange"} labelId={"timeRangeLabel"}
                disabled={
                  this.state.isFetching
                    ? this.state.isFetching === true
                      ? false
                      : true
                    : false
                }
                size="small"
               
                inputProps={{role:'combobox','aria-expanded':Boolean(this.state.showRange)}}
                onClick={this.toggleRange}
                onKeyUp={(event) => {
                  if (event.key === "Enter") {
                    this.toggleRange(event);
                  }
                }}
              value={
                this.state.selectedRange == "Custom"
                  ? "Custom"
                  : this.setTimeRange()
              }
                startAdornment={<TimeIcon className="timeIcon" />}
                endAdornment={
                  
                  <ArrowDropDownIcon  style={{ transform: 'scale(1.2)', color: '#3272D9' }}
                          fontSize="large"
                          color="#3272D9"
                   
                  />
                }
              />
            </FormControl>
        
          <Popover
            open={Boolean(this.state.showRange)}
            anchorEl={this.state.showRange}
            onClose={this.toggleRange}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <List component="div" role="group" >
              {items.map((item) => (
                <ListItem component="div"
                  value={item.value}
                  children={item.text}
                  key={item.value}
                  button
                  selected={this.state.selectedRange === item.value}
                  onClick={(e) => this.handleRangeClick(item.value)}
                />
              ))}
            </List>
            <Divider />
            <div style={{ display: "grid", margin: "10px" }}>
              <span
                style={{
                  fontSize: "14px",
                  color: "#666666",
                  fontWeight: "400",
                  fontFamily: "Roboto",
                  margin: "5px 0px",
                }}
              >
                Custom
              </span>
              <div className="metric-timeRange-date-picker">
              <MineralDateTimePicker
              value={this.state.startDate}
              onValueChange={(event) => {
                if(moment(event).isAfter(moment(this.state.endDate)))
                this.setState({startDateError:'Start date cannot be after end date'})
                else
                {
                  this.setState({startDateError:null})
                          this.handleDateChange(event, "startDate")
                      
                    }}}
             
             
              variant="inline"
              //maxDateTime={endDate}
              keyboardIcon={<DatePickerIcon />}
              label="Start Date"
              ampm={false}
            />
              <HelpingText
              hint={null}
              errorMessage={this.state.startDateError}
              disabled={!this.state.startDateError}
            />
                

                <MineralDateTimePicker
              value={this.state.endDate}
              onValueChange={(event) => {
                if(moment(event).isBefore(moment(this.state.startDate).add(1, "minute")))
               this.setState({endDateError:'End date cannot be before start date'})
                if(moment(event).isAfter())
                this.setState({endDateError:'End date cannot be in future'})
                else{
                  this.setState({endDateError:null})
                          this.handleDateChange(event, "endDate")
                      
                    }}}
             
             
              variant="inline"
              //maxDateTime={endDate}
              keyboardIcon={<DatePickerIcon />}
              label="End Date"
              ampm={false}
            />
              <HelpingText
              hint={null}
              errorMessage={this.state.endDateError}
              disabled={!this.state.endDateError}
            />
                
              </div>
              <Divider />
              <div>
                <Button
                  variant="text"
                  children="Apply"
                  style={{ float: "right" }}
                  disabled={
                    this.state.startDate == null || this.state.endDate == null || !moment(this.state.startDate).isBefore(this.state.endDate)
                  }
                  onClick={this.handleApply}
                />
              </div>
            </div>
          </Popover>
        </div>
      </div>
    );
  }
}

export default TimeRange;
