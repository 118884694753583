export const FETCH_PROBE_METRICS = "FETCH_PROBE_METRICS";
export const FETCH_PROBE_METRICS_SUCCESS = "FETCH_PROBE_METRICS_SUCCESS";
export const FETCH_PROBE_METRICS_FAILURE = "FETCH_PROBE_METRICS_FAILURE";
export const FETCH_PROBE_METRICS_DEVICES = "FETCH_PROBE_METRICS_DEVICES";
export const FETCH_PROBE_METRICS_DEVICES_SUCCESS = "FETCH_PROBE_METRICS_DEVICES_SUCCESS";
export const FETCH_PROBE_METRICS_DEVICES_FAILURE = "FETCH_PROBE_METRICS_DEVICES_FAILURE";
export const CLEAR_PROBE_CHART_DATA = "CLEAR_PROBE_CHART_DATA";
export const FETCH_PROBE_METRICS_DEVICES_CINAME = "FETCH_PROBE_METRICS_DEVICES_CINAME";
export const FETCH_PROBE_METRICS_DEVICES_CINAME_SUCCESS = "FETCH_PROBE_METRICS_DEVICES_CINAME_SUCCESS";
export const FETCH_PROBE_METRICS_DEVICES_CINAME_FAILURE = "FETCH_PROBE_METRICS_DEVICES_CINAME_FAILURE";
export const REQUEST_PROBE_METRICS_DEVICES_CHART = "REQUEST_PROBE_METRICS_DEVICES_CHART";
export const FETCH_PROBE_METRICS_DEVICES_CHART = "FETCH_PROBE_METRICS_DEVICES_CHART";
export const FETCH_PROBE_METRICS_DEVICES_CHART_SUCCESS = "FETCH_PROBE_METRICS_DEVICES_CHART_SUCCESS";
export const FETCH_PROBE_METRICS_DEVICES_CHART_FAILURE = "FETCH_PROBE_METRICS_DEVICES_CHART_FAILURE";
export const POLL_METRIC_DEVICE_CHART_START = "POLL_METRIC_DEVICE_CHART_START";
export const POLL_METRIC_DEVICE_CHART_STOP = "POLL_METRIC_DEVICE_CHART_STOP";
