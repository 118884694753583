// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //For more information, click
  content["For more information, click "] =
  "有关详细信息，请单击";

  // console.log(this.props);
  content["Default system monitoring for CPU, disk & memory will be applied"] =
  "将应用 CPU、磁盘和内存的默认系统监控";

  //Automatically monitor
  content["Automatically monitor "] =
  "自动监控 ";

  // devices
  content[" devices"] =
  " 个设备";

  //HELP
  content["HELP"] =
  "帮助";

  // END OF LOCALIZATION

export default content;
