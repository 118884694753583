// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //CRITICAL
  content["CRITICAL"] =
  "CRÍTICA";

  //MAJOR
  content["MAJOR"] =
  "GRAVE";

  //MINOR
  content["MINOR"] =
  "LEVE";

  //WARNING
  content["WARNING"] =
  "ADVERTENCIA";

  // INFO
  content["INFO"] =
  "INFORMACIÓN";

  // NONE
  content["NONE"] =
  "NINGUNO";

  // END OF LOCALIZATION

export default content;
