// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //instruction message
  content["To use robot installation form above, WMI or SSH access with installation permissions are required.For other situations (e.g. AWS & Azure or no installation permission) use the manual installation process through the"] =
  "上記のロボット インストール フォームを使用するには、インストール権限を持つ WMI または SSH アクセスが必要です。その他の場合 (例: AWS & Azure またはインストール権限なし) は、手動のインストール プロセスを使用します";

  //SetupWizard
  content["Setup Wizard"] =
  "セットアップ ウィザード";

  // END OF LOCALIZATION

export default content;
