import * as React from 'react';
import { SvgIcon } from '@mineral/core';

export const FilledStar05 = React.forwardRef(
  (props, ref) => (
    <SvgIcon viewBox="0 0 40 40" {...props} ref={ref}>
      <path d="M20 36L25.2657 32.7126L31.3137 31.3137L32.7126 25.2657L36 20L32.7126 14.7343L31.3137 8.68629L25.2657 7.28742L20 4L14.7343 7.28742L8.68629 8.68629L7.28742 14.7343L4 20L7.28742 25.2657L8.68629 31.3137L14.7343 32.7126L20 36Z" fill={props.color} />
    </SvgIcon>
  )
);

export default FilledStar05;