import React, { Component } from 'react'
import { MenuItem, Menu } from '@mineral/core'
import Resource from './Resource'

class RobotInstallerMenu extends Component {

  _isInstallerDisabled = value => {
    let disabled = false;
    if(this.props.supportedOs=="windows" && value!=="windows"){
     
              disabled = true;
              
          }
    else if(this.props.supportedOs=="unix" && value=="windows"){
            
                     disabled =  true;
                 }
   else {
                   disabled=false;
                 
       }     
    return disabled;
    }
  
  render() {
    const styles = {
      menu: {
        width: '250px',
        marginTop: '-9px',
        position:'relative',
      },
      selectedItemStyle: {
        backgroundColor: '#00AEEF',
        color: 'white',
      },
    }
  //Windows
    const WindowstextLabel = Resource.get('Windows')
    //Debian
    const DebiantextLabel = Resource.get('Debian')
    //Ubuntu
    const UbuntutextLabel = Resource.get('Ubuntu')
    //RHEL & CentOS
    const RHELandCentOsLabel = Resource.get('RHEL & CentOS')
    //Solaris
    const SolarisLabel = Resource.get('Solaris')
    //AIX
    const AIXLabel = Resource.get('AIX')
    return (
       
      <Menu
        value={this.props.selectedItem}
        selectedmenuitemstyle={styles.selectedItemStyle}
        style={styles.menu}
        onChange={this.props.onChangeHandler}>
        <MenuItem children= {WindowstextLabel} value="windows" disabled={this._isInstallerDisabled("windows")}/>
        <MenuItem children= {DebiantextLabel} value="debian" disabled={this._isInstallerDisabled("debian")}/>
        <MenuItem children= {UbuntutextLabel} value="ubuntu" disabled={this._isInstallerDisabled("ubuntu")}/>
        <MenuItem children= {RHELandCentOsLabel} value="unix" disabled={this._isInstallerDisabled("unix")}/>  
        <MenuItem children= {SolarisLabel} value="solaris" disabled={this._isInstallerDisabled("solaris")}/>
        <MenuItem children= {AIXLabel} value="aix" disabled={this._isInstallerDisabled("aix")}/>
        </Menu>
    )
  }
}

export default RobotInstallerMenu
