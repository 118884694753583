import React, {Component} from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  Tooltip,
  Legend
} from 'recharts'
import './chart.less'
import Resource from './Resource'

const renderLegend = (props) => {
  return (
    <div style={{
      color: '#3D8DA0',
      fontSize: '12px',
      textAlign: 'center'
    }}>{//ALARM HISTORY
    	Resource.get('ALARM HISTORY')}</div>
  )
}

class StackedBarChart extends Component {

  render() {			  
    return (
      <BarChart width={275} height={176} data={this.props.data} margin={{
        top: 25,
        right: 0,
        left: 0,
        bottom: 5
      }}>
           
          <Bar dataKey= "info" stackId="a" fill={this.props.chartColors[4]}/>
          <Bar dataKey="warning" stackId="a" fill={this.props.chartColors[3]} />
          <Bar dataKey="minor" stackId="a" fill={this.props.chartColors[2]} />
          <Bar dataKey="major" stackId="a" fill={this.props.chartColors[1]} />
          <Bar dataKey="critical" stackId="a" fill={this.props.chartColors[0]} />
          <XAxis dataKey="range"/>
        <Tooltip cursor={{fill:"#dedede70"}}/>
        <Legend content={renderLegend}/>
      </BarChart>
    )
  }
}

export default StackedBarChart;

// <Bar dataKey="cnt" fill="#8884d8" name="Alarm Summary" />
//<CartesianGrid strokeDasharray="3 3"/>
