import React from 'react'
//import axios from 'axios'
class BrandLogo extends React.Component{

    // componentDidMount() {
    //     axios.get('/operatorconsole_portlet/api/v1/whiteLabel/brandLogo'
    //     )
    // }
    render(){
    const url = "/operatorconsole_portlet/logostacked.svg";
    return <img src={url} style={{ width: 100, height: 60 }} alt={"CA Technologies,a Broadcom Company Logo"} />;
    }
}
export default BrandLogo