export const FETCH_DEVICES = "FETCH_DEVICES";
export const FETCH_DEVICES_SUCCESS = "FETCH_DEVICES_SUCCESS";
export const FETCH_DEVICES_FAILURE = "FETCH_DEVICES_FAILURE";
export const FETCH_SAVED_METRICS = "FETCH_SAVED_METRICS";
export const FETCH_SAVED_METRICS_SUCCESS = "FETCH_SAVED_METRICS_SUCCESS";
export const FETCH_SAVED_METRICS_FAILURE = "FETCH_SAVED_METRICS_FAILURE";
export const FETCH_DEVICE_METRICS = "FETCH_DEVICE_METRICS";
export const FETCH_DEVICE_METRICS_SUCCESS = "FETCH_DEVICE_METRICS_SUCCESS";
export const FETCH_DEVICE_METRICS_FAILURE = "FETCH_DEVICE_METRICS_FAILURE";
export const FETCH_GOLDEN_METRICS = "FETCH_GOLDEN_METRICS";
export const FETCH_GOLDEN_METRICS_SUCCESS = "FETCH_GOLDEN_METRICS_SUCCESS";
export const FETCH_GOLDEN_METRICS_FAILURE = "FETCH_GOLDEN_METRICS_FAILURE";
export const UPDATE_ALIAS = "UPDATE_ALIAS";
export const UPDATE_ALIAS_SUCCESS = "UPDATE_ALIAS_SUCCESS";
export const UPDATE_ALIAS_FAILURE = "UPDATE_ALIAS_FAILURE";
export const FETCH_DEVICE_DATA = "FETCH_DEVICE_DATA";
export const FETCH_DEVICE_DATA_SUCCESS = "FETCH_DEVICE_DATA_SUCCESS";
export const FETCH_DEVICE_DATA_FAILURE = "FETCH_DEVICE_DATA_FAILURE";
export const FETCH_MONITORINGHOSTS_DATA = "FETCH_MONITORINGHOSTS_DATA";
export const FETCH_MONITORINGHOSTS_DATA_SUCCESS = "FETCH_MONITORINGHOSTS_DATA_SUCCESS";
export const FETCH_MONITORINGHOSTS_DATA_FAILURE = "FETCH_MONITORINGHOSTS_DATA_FAILURE";
