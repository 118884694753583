import {
  call,
  put,
  takeEvery,
  takeLatest,
  take,
  cancel,
} from 'redux-saga/effects'
import { delay } from 'redux-saga/effects'
import {
  FETCH_OPEN_ALARMS,
  POLL_OPEN_ALARMS_START,
  POLL_OPEN_ALARMS_STOP,
  FETCH_ACTIVE_DEVICES,
  POLL_ACTIVE_DEVICES_START,
  POLL_ACTIVE_DEVICES_STOP,
  FETCH_ROLE_DEVICES,
  POLL_ROLE_DEVICES_START,
  POLL_ROLE_DEVICES_STOP,
  FETCH_TOP_TECHNOLOGIES,
  POLL_TOP_TECHNOLOGIES_START,
  POLL_TOP_TECHNOLOGIES_STOP,
  FETCH_TOP_GROUPS,
  POLL_TOP_GROUPS_START,
  POLL_TOP_GROUPS_STOP,
  FETCH_TOP_DEVICES,
  POLL_TOP_DEVICES_START,
  POLL_TOP_DEVICES_STOP,
  
} from './actionTypes'
import { 
  fetchOpenAlarmsSuccess, 
  fetchOpenAlarmsFailure,
  fetchActiveDevicesSuccess, 
  fetchActiveDevicesFailure,
  fetchRoleDevicesSuccess, 
  fetchRoleDevicesFailure,
  fetchTopTechnologiesFailure, 
  fetchTopTechnologiesSuccess,
  fetchTopDevicesFailure,
  fetchTopDevicesSuccess,
  fetchTopGroupsFailure,
  fetchTopGroupsSuccess   
} from './actions'
import homeScreen from './homeScreen.js'

//------------------------------------ Open Alarms Polling Functions----------------------------
function* fetchOpenAlarmspoll(params) {
  
  try {
    const response = yield call(homeScreen.getOpenAlarms, params)
    yield put(fetchOpenAlarmsSuccess(response.data))
  } catch (error) {
    yield put(fetchOpenAlarmsFailure(error))
  }
}

function* pollOpenAlarms(action) {
  while (true) {
    yield call(fetchOpenAlarmspoll,{ 
      csId:action.csId,
      groupIds:action.groupIds,
      probeId:action.probeId})
      yield delay(30000)
    }
  }
  
  export function* openAlarmsRequestSaga() {
    yield takeEvery(FETCH_OPEN_ALARMS, fetchOpenAlarmspoll)
  }
  
  export function* openAlarmsPollingSaga() {
    while (true) {
      const watcherInstance = yield takeLatest(POLL_OPEN_ALARMS_START, pollOpenAlarms)
      // cancel polling on POLL_HUBS_STOP
      yield take(POLL_OPEN_ALARMS_STOP)
      yield cancel(watcherInstance)
    }
  }
  
  
  //------------------------------------ Active Devices Polling Functions----------------------------
  function* fetchActiveDevicespoll(params) {
    
    try {
      const response = yield call(homeScreen.getActiveDevices, params)
      yield put(fetchActiveDevicesSuccess(response.data))
    } catch (error) {
      yield put(fetchActiveDevicesFailure(error))
    }
  }
  
  function* pollActiveDevices(action) {
    while (true) {
      yield call(fetchActiveDevicespoll)
      yield delay(30000)
    }
  }
  
  export function* activeDevicesRequestSaga() {
    yield takeEvery(FETCH_ACTIVE_DEVICES, fetchActiveDevicespoll)
  }
  
  export function* activeDevicesPollingSaga() {
    while (true) {
      const watcherInstance = yield takeLatest(POLL_ACTIVE_DEVICES_START, pollActiveDevices)
      // cancel polling on POLL_HUBS_STOP
      yield take(POLL_ACTIVE_DEVICES_STOP)
      yield cancel(watcherInstance)
    }
  }
  
  //------------------------------------ RoleDevices Polling Functions----------------------------
  function* fetchRoleDevicespoll(params) {
    
    try {
      const response = yield call(homeScreen.getDeviceByRole, params)
      yield put(fetchRoleDevicesSuccess(response.data))
    } catch (error) {
      yield put(fetchRoleDevicesFailure(error))
    }
  }
  
  function* pollRoleDevices(action) {
    while (true) {
      yield call(fetchRoleDevicespoll,{ 
        groupIds:action.groupIds,
        probeId:action.probeId})
        yield delay(30000)
        
      }
    }
    
    export function* roleDevicesRequestSaga() {
      yield takeEvery(FETCH_ROLE_DEVICES, fetchRoleDevicespoll)
    }
    
    export function* roleDevicesPollingSaga() {
      while (true) {
        const watcherInstance = yield takeLatest(POLL_ROLE_DEVICES_START, pollRoleDevices)
        // cancel polling on POLL_HUBS_STOP
        yield take(POLL_ROLE_DEVICES_STOP)
        yield cancel(watcherInstance)
      }
    }
    //------------------------------------ TopTechnologies Polling Functions----------------------------
    function* fetchTopTechnologiespoll(params) {
      
      try {
        const response = yield call(homeScreen.getMonitoredTechnologies, params)
        yield put(fetchTopTechnologiesSuccess(response.data))
      } catch (error) {
        yield put(fetchTopTechnologiesFailure(error))
      }
    }
    
    function* pollTopTechnologies(action) {
      while (true) {
        yield call(fetchTopTechnologiespoll,{ 
          csId:action.csId,
          groupIds:action.groupIds,
        })
        yield delay(30000)
        
      }
    }
    
    export function* topTechnologiesRequestSaga() {
      yield takeEvery(FETCH_TOP_TECHNOLOGIES, fetchTopTechnologiespoll)
    }
    
    export function* topTechnologiesPollingSaga() {
      while (true) {
        const watcherInstance = yield takeLatest(POLL_TOP_TECHNOLOGIES_START, pollTopTechnologies)
        // cancel polling on POLL_HUBS_STOP
        yield take(POLL_TOP_TECHNOLOGIES_STOP)
        yield cancel(watcherInstance)
      }
    }
    //------------------------------------ TopGroups Polling Functions----------------------------
    function* fetchTopGroupspoll(params) {
      
      try {
        const response = yield call(homeScreen.getTopGroupsByAlarms, params)
        yield put(fetchTopGroupsSuccess(response.data))
      } catch (error) {
        yield put(fetchTopGroupsFailure(error))
      }
    }
    
    function* pollTopGroups(action) {
      while (true) {
        yield call(fetchTopGroupspoll,{ 
          csId:action.csId,
        })
        yield delay(30000)
        
      }
    }
    
    export function* topGroupsRequestSaga() {
      yield takeEvery(FETCH_TOP_GROUPS, fetchTopGroupspoll)
    }
    
    export function* topGroupsPollingSaga() {
      while (true) {
        const watcherInstance = yield takeLatest(POLL_TOP_GROUPS_START, pollTopGroups)
        // cancel polling on POLL_HUBS_STOP
        yield take(POLL_TOP_GROUPS_STOP)
        yield cancel(watcherInstance)
      }
    }
    //------------------------------------ TopDevices Polling Functions----------------------------
    function* fetchTopDevicespoll(params) {
      
      try {
        const response = yield call(homeScreen.getTopDevicesByAlarms, params)
        yield put(fetchTopDevicesSuccess(response.data))
      } catch (error) {
        yield put(fetchTopDevicesFailure(error))
      }
    }
    
    function* pollTopDevices(action) {
      while (true) {
        yield call(fetchTopDevicespoll,{ 
          groupIds:action.groupIds,
          probeId:action.probeId})
          yield delay(30000)
          
        }
      }
      
      export function* topDevicesRequestSaga() {
        yield takeEvery(FETCH_TOP_DEVICES, fetchTopDevicespoll)
      }
      
      export function* topDevicesPollingSaga() {
        while (true) {
          const watcherInstance = yield takeLatest(POLL_TOP_DEVICES_START, pollTopDevices)
          // cancel polling on POLL_HUBS_STOP
          yield take(POLL_TOP_DEVICES_STOP)
          yield cancel(watcherInstance)
        }
      }