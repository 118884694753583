import axios from 'axios';
import config from './../../config';

class ServiceNow {
      get() {
      return axios.get([config.basename, 'api/integration/servicenow'].join('/'),{
        params: {
          'probe': 'sdgtw'
        }
      });
    }
  save(serviceNowConfig) {
    return axios.put([config.basename, 'api/integration/servicenow'].join('/'),serviceNowConfig);
  }

  test(serviceNowConfig) {
    return axios.put([config.basename, 'api/integration/servicenow/test'].join('/'), serviceNowConfig);
  }

  deleteConfig(serviceNowConfig) {
    return axios.put([config.basename, 'api/integration/servicenow'].join('/'),serviceNowConfig);
  }
}
export default new ServiceNow();