import React, {Component} from "react";
import PropTypes from 'prop-types';
import {NavLink, Route} from "react-router-dom";
import get from "lodash/get";
import {
  AlarmsIcon as AlarmIcon,
  DashboardsIcon,
  SettingsIcon as SettingIcon,
  MetricsIcon,
  MoreIcon
} from "../../../ui-components/uim-components";
import {MenuItem, Menu, IconButton, Button,Tabs,Tab,Select,Tooltip,SvgIcon} from '@mineral/core'
import AclTypes from "./../../../../api/aclTypes";
import EntityTypes from "./../../../../api/entityTypes";
import "./toggleIcons.less";
import Resource from "./Resource";
import {
  getAlarmUrl,
  getContainerGroupFilters,
} from "./../../../../utils/common";
import InterfacesIcon from "./../../../icons/InterfaceIcon";
import GroupDetailIcon from "./../../../icons/GroupDetailIcon";
// import Topology from "./../../../../assets/icons/Topology.svg";
import Topology from "./../../../icons/Topology";
import GridIcon from "./../../../icons/GridIcon";
import ListIcon from "./../../../icons/ListIcon";
import MaintenanceIcon from "./../../../icons/MaintenanceIcon";
import computerSystemsApi from './../../../../api/computer-systems/computerSystems'
import ExportGroupDetailsDialog from "./../../../treeViewNew/ExportGroupDetailsDialog"
import CustomColumnsDialog from "./../../../inventory/CustomColumnsDialog";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';


import { ALARMS, DASHBOARDS, DEVICE_DETAILS, INTERFACES, MAINTENANCE, METRICS, MONITORING_CONFIG } from "./ResourceKeys";


export const NAV_MENUS = {
  DETAIL_VIEW: "DETAIL_VIEW",
  ALARMS: "ALARMS",
  MAINTENANCE: "MAINTENANCE",
  METRICS: "METRICS",
  INTERFACES: "INTERFACES",
  MCS: "MCS",
  CABI: "CABI",
};

const Link = ({to, entityType, isDisabled, title, children}) => {
  isDisabled =
    isDisabled !== undefined ? isDisabled : entityType !== EntityTypes.GROUP;
  let link = (
    <NavLink
      to={to}
      className="link"
      activeclassname="link--active"
      title={title}
    >
      {children}
    </NavLink>
  );
  if (isDisabled) {
    link = (
      <span className="link nav-icon--disabled" title={title}>
        {children}
      </span>
    );
  }
  return link;
};

// Card View
const CardView = Resource.get("Card View");
// List View
const ListView = Resource.get("List View");
// Tree View
const TreeView = Resource.get("Tree View");

class ToggleIcons extends Component {
  constructor(props, context) {
    super(props);
  
    


    this.state = {
      tabValue:this.props.tabValue?this.props.tabValue:NAV_MENUS.DETAIL_VIEW,
      dropdownValue:this.props.view,
      
      CONTAINER_GROUP_ICONS: [
        NAV_MENUS.DETAIL_VIEW,
        NAV_MENUS.ALARMS,
        NAV_MENUS.CABI,
        NAV_MENUS.MAINTENANCE,
        NAV_MENUS.METRICS,
      ],
      DEVICE_GROUP_ICONS: [
        NAV_MENUS.DETAIL_VIEW,
        NAV_MENUS.ALARMS,
        NAV_MENUS.CABI,
        NAV_MENUS.MAINTENANCE,
        NAV_MENUS.METRICS,
        NAV_MENUS.MCS,
      ],
      INTERFACE_GROUP_ICONS: [
        NAV_MENUS.ALARMS,
        NAV_MENUS.METRICS,
        NAV_MENUS.INTERFACES,
      ],
      DEVICE_ICONS: [
        NAV_MENUS.DETAIL_VIEW,
        NAV_MENUS.ALARMS,
        NAV_MENUS.METRICS,
        NAV_MENUS.INTERFACES,
        NAV_MENUS.MCS,
      ],
      INTERFACE_ICONS: [NAV_MENUS.ALARMS, NAV_MENUS.METRICS],
      openExportTypeDialog: false,
      openEditColumnDailog: false,
      savedColumns: [],
      menuAnchorEl: null,
      menuViewAnchorEl: null,
    };
    this.context = context;
  }

  isIconAllowed = (type) => {
    var isAllowed = false;
    if (this.props.location.pathname.search("treeviewnew") >= 0) {
      switch (this.props.treeNode.entityType) {
        case EntityTypes.CONTAINER:
        case EntityTypes.ROOT:
          isAllowed = this.state.CONTAINER_GROUP_ICONS.indexOf(type) >= 0;
          break;
        case EntityTypes.DYNAMIC:
        case EntityTypes.STATIC:
        case EntityTypes.AUTOMATIC:
          isAllowed = this.props.treeNode.isInterfaceGroup
            ? this.state.INTERFACE_GROUP_ICONS.indexOf(type) >= 0
            : this.state.DEVICE_GROUP_ICONS.indexOf(type) >= 0;
          break;
        case EntityTypes.COMPUTER_SYSTEM:
          isAllowed = this.state.DEVICE_ICONS.indexOf(type) >= 0;
          break;
        case EntityTypes.INTERFACES:
          isAllowed = this.state.INTERFACE_ICONS.indexOf(type) >= 0;
          break;
        default:
          isAllowed = this.state.CONTAINER_GROUP_ICONS.indexOf(type) >= 0;
          break;
      }
    }
    return isAllowed;
  };
  getPath = (type, pathname) => {
    return this.props.location.pathname.search("treeviewnew") > -1
      ? `/groups/${this.props.selectedEntity.id}/${type}`
      :   (this.state.dropdownValue !== 3?(this.state.dropdownValue===2?`${pathname}/${type}`:`${pathname}/card/${type}`):"");
    
                     
  };

  onNavLinkSelect(link, menu) {
    this.setState({tabValue:menu})
    if (this.state.dropdownValue === 3) {
      this.props.switchTreeViewNav(menu);
    } else {
      this.props.switchTreeViewNav(menu);
      this.props.history.push(link, {
        fromCsView:
          this.props.location.pathname.search("computer-systems") > -1,
        fromGroup: true,
      });
    }
  }

  handleDropdownChange(event) {
    if (event.target.value !== 3) {
      this.props.switchTreeViewNav(NAV_MENUS.DETAIL_VIEW);
    }
  }

  componentWillReceiveProps(nextProps){
    if (
      !nextProps.treeNode.isInterfaceGroup &&
      (nextProps.treeNode.entityType === "DYNAMIC" ||
        nextProps.treeNode.entityType === "STATIC" || 
        nextProps.treeNode.entityType === "CONTAINER" ||
        nextProps.treeNode.entityType === "AUTOMATIC" ||
        nextProps.treeNode.entityType === "ROOT" && 
        nextProps.treeNode.entityType !== "COMPUTER_SYSTEM") &&
        ((nextProps.currentView === "INTERFACES" ||
        nextProps.currentView === "DETAIL_VIEW") &&
        nextProps.currentView !== "ALARMS" &&
        nextProps.currentView !== "CABI" &&
        nextProps.currentView !== "MAINTENANCE" &&
        nextProps.currentView !== "METRICS" &&
        nextProps.currentView !== "MCS")
    ) {
      this.setState({ tabValue: NAV_MENUS.DETAIL_VIEW });
      this.props.switchTreeViewNav(NAV_MENUS.DETAIL_VIEW);
    }
    else if (
      (nextProps.disabledTreeViewNavs.includes("METRICS") && (nextProps.currentView === "DETAIL_VIEW" || nextProps.currentView === "METRICS")) ||
      (nextProps.disabledTreeViewNavs.includes("INTERFACES") && nextProps.currentView === "INTERFACES") || (nextProps.disabledTreeViewNavs.includes("MCS") && nextProps.currentView === "MCS")
    ) {
      this.setState({ tabValue: NAV_MENUS.DETAIL_VIEW });
      this.props.switchTreeViewNav(NAV_MENUS.DETAIL_VIEW);
    }
  }

  //dp039841
  componentWillMount() {
    computerSystemsApi.getSavedColumns()
      .then((response) => {
        let columns = response.data.columns
        this.setState({savedColumns: columns && columns.length > 0 ? columns : []});
        this.props.sendSavedColumns(this.state.savedColumns);
      })

  }

  handleMenuClick = (event) => {
    this.setState({menuAnchorEl: event.currentTarget});
  };
  handleActionMenuClose = (event) => {
    this.setState({menuAnchorEl: null});
  };
  handleViewMenuOpen = (event) => {
    this.setState({menuViewAnchorEl: event.currentTarget});
  };
  handleViewMenuClose = () => {
    this.setState({menuViewAnchorEl: null});
  };

  handleOpenExportDialog = () => {
    this.setState({openExportTypeDialog: true, menuAnchorEl: null})
  }
  handleExportClose = () => {
    this.setState({openExportTypeDialog: false, menuAnchorEl: null})
  }
  handleOpenEditColumnDialog = () => {
    this.setState({openEditColumnDailog: true, menuAnchorEl: null})
  }

  handleSaveSelectedColumns(selectedColumns) {
    this.setState({openEditColumnDailog: false});
    let toSaveColList = [];
    selectedColumns.forEach(col => {
      toSaveColList.push(col.headerName)
    })
    let params = {
      columns: toSaveColList,
      colFilters: JSON.stringify({})
    }
    computerSystemsApi.saveSelectedColumns(params)
      .then((response) => {
        if (response.data) {
          computerSystemsApi.getSavedColumns()
            .then((response) => {
              let columns = response.data.columns
              this.setState({savedColumns: columns && columns.length > 0 ? columns : []});
              this.props.sendSavedColumns(this.state.savedColumns);
            })
        }
      });

  }


  render() {
    let roles = get(this.props.acl.aclInfo, "roles", "");
    let disableMaintenanceIcon = !(
      this.props.acl.canEditMaintMode ||
      this.props.acl.canEditMaintModeDevice ||
      this.props.acl.canViewMaintenanceMode
    );
    let disableMCS = !(
      roles.includes("USM Monitoring Configuration Service") ||
      roles.includes("USM Edit Monitoring Templates") ||
      roles.includes("MCS Read-Only Access")
    );

    let currentLocation=window.location.pathname;
    console.log("Current Location: ",currentLocation)
    let entityMatchRegex = "/(groups)?/:groupIds(\\d+)*/(computer-systems)?/:csIds(\\d+)*";

    


    if(currentLocation.match(`${entityMatchRegex}`))
    console.log("Cards View - Detail VIEW")
    if(currentLocation.match(`${entityMatchRegex}/list`))
    console.log("List View - Detail VIEW")
    if(currentLocation.match(`${entityMatchRegex}/treeviewnew`))
    console.log("Tree View - Detail VIEW")
    if(currentLocation.match(`${entityMatchRegex}/cabi`))
    console.log("Dont know View - Dont know tab")
    if(currentLocation.match(`${entityMatchRegex}/card/dashboard`))
    console.log("Card View- CABI")
    if(currentLocation.match(`${entityMatchRegex}/card/alarms`))
    console.log("Card View- Alarms")
    if(currentLocation.match(`${entityMatchRegex}/card/maintenance`))
    console.log("Card View- Maintenance")
    if(currentLocation.match(`${entityMatchRegex}/card/interfaces`))
    console.log("Card View- Interfaces")
    if(currentLocation.match(`${entityMatchRegex}/card/configuration`))
    console.log("Card View- MCS")
    if(currentLocation.match(`${entityMatchRegex}/card/metrics`))
    console.log("Card View- Metrics")

let currentTabValue=NAV_MENUS.DETAIL_VIEW;
    if(currentLocation.endsWith(`dashboard`))
    currentTabValue=NAV_MENUS.CABI
    if(currentLocation.endsWith(`alarms`))
    currentTabValue=NAV_MENUS.ALARMS
    if(currentLocation.endsWith(`maintenance`))
    currentTabValue=NAV_MENUS.MAINTENANCE
    if(currentLocation.endsWith(`interfaces`))
    currentTabValue=NAV_MENUS.INTERFACES
    if(currentLocation.endsWith(`configuration`))
    currentTabValue=NAV_MENUS.MCS
    if(currentLocation.endsWith(`metrics`))
    currentTabValue=NAV_MENUS.METRICS
   

    return (
      <div style={this.props.location.pathname.search("dashboard") > -1 ? {width: '100%'} : {width:'100%'}}>
        <Route
          path={this.context && this.context.entityMatchRegex}
          render={({match}) => {
            const pathname = match.url;
            let groupId,
              groupName = [],
              deviceName,
              filters = null;
            if (match.params && match.params.groupIds) {
              const getIds = match.params.groupIds
                .split("/")
                .map((id) => parseInt(id, 10))
                .filter((id) => !isNaN(id));

              groupId = [getIds[getIds.length - 1]];
            }
            if (
              groupId &&
              groupId.length &&
              this.props.selectedEntity &&
              this.props.selectedEntity.id === groupId[0].toString()
            ) {
              groupName = [this.props.selectedEntity.name];
              if (
                this.props.selectedEntity &&
                this.props.selectedEntity.groups &&
                this.props.selectedEntity.groups.length
              ) {
                this.props.selectedEntity.groups
                  .filter(function (group) {
                    return group.type !== "CONTAINER";
                  })
                  .forEach(function (group) {
                    let totalalarm;
                    if (group.alarmCountSummary) {
                      totalalarm =
                        group.alarmCountSummary.critical +
                        group.alarmCountSummary.major +
                        group.alarmCountSummary.minor +
                        group.alarmCountSummary.warning +
                        group.alarmCountSummary.information;
                    }
                    if (totalalarm) {
                      groupId.push(group.id);
                      groupName.push(group.name);
                    }
                  });
                this.props.selectedEntity.groups
                  .filter(function (group) {
                    return group.type === "CONTAINER";
                  })
                  .forEach(function (group) {
                    filters = getContainerGroupFilters(group, groupId, groupName);
                  });
              }
              filters = filters
                ? filters
                : `groupId=${groupId.toString()}&group=${groupName.toString()}`;
            }
            if (match.params.csIds && this.props.breadcrumbs) {
              let length = this.props.breadcrumbs.length;
              deviceName = this.props.breadcrumbs[length - 1].name;
            }

             /*<div style={{marginRight: '16px', marginTop: '4px'}}>
                  <IconButton
                    disabled={this.props.location.pathname.search("computer-systems") > -1}
                    aria-controls="viewMenu"
                    aria-haspopup="true"
                    onClick={this.handleViewMenuOpen}

                  > 
                    {this.state.dropdownValue == 1 ? <GridIcon/> :
                      this.state.dropdownValue == 2 ? <ListIcon/> :
                        <Topology/>
                    }
                    <ArrowDropDownIcon />
                  </IconButton>
                  <Menu
                    id="viewMenu"
                    anchorEl={this.state.menuViewAnchorEl}
                    keepMounted
                    onClose={this.handleViewMenuClose}
                    open={this.state.menuViewAnchorEl != null}
                    className='viewMenuIcon'
                  >
                    <MenuItem value={1} onClick={this.handleViewMenuClose}>
                      <div>
                        <Link
                          to={`${pathname}`}
                          entityType={get(
                            this.props.selectedEntity,
                            "entityType"
                          )}
                          isDisabled={!!pathname.match(/computer-systems/)}
                          title="Cards"
                        >
                          <GridIcon
                            className={this.state.dropdownValue == 1 ? "selectedButton" : "icon nav-icon"}
                            //Cards
                            title={Resource.get("Cards")}
                            value={1}
                          />
                          <span
                            style={{
                              display: "inline-block",
                              verticalAlign: "middle",
                            }}
                          >
                          {CardView}
                        </span>
                        </Link>
                      </div>
                    </MenuItem>

                    <MenuItem value={2} onClick={this.handleViewMenuClose}>
                      <div>
                        <Link
                          to={`${pathname}/list`}
                          entityType={get(
                            this.props.selectedEntity,
                            "entityType"
                          )}
                          isDisabled={!!pathname.match(/computer-systems/)}
                          title="List"
                        >
                          <ListIcon
                            className={this.state.dropdownValue == 2 ? "selectedButton" : "icon nav-icon"}
                            value={2}
                            //List
                            title={Resource.get("List")}

                          />
                          <span
                            style={{
                              display: "inline-block",
                              verticalAlign: "middle",
                            }}
                          >
                          {ListView}
                        </span>
                        </Link>
                      </div>
                    </MenuItem>
                    <MenuItem value={3} onClick={this.handleViewMenuClose}>
                      <div>
                        <Link
                          to={`${pathname}/treeviewnew`}
                          entityType={get(
                            this.props.selectedEntity,
                            "entityType"
                          )}
                          isDisabled={!!pathname.match(/computer-systems/)}
                          title="Tree"
                        >
                          <Topology
                            className={this.state.dropdownValue == 3 ? "selectedButton" : "icon nav-icon"}
                            value={3}
                            //List
                            title={Resource.get("Tree")}

                          />
                          <span
                            style={{
                              display: "inline-block",
                              verticalAlign: "middle",
                            }}
                          >
                          {TreeView}
                        </span>
                        </Link>
                      </div>
                    </MenuItem>
                  </Menu>
                </div>*/
            return (
              <div 
                className={`nav-icons ${
                  this.props.location.pathname.search("dashboard") > -1
                    ? "left-dashboard-nav-icons"
                    : "left-nav-icons"
                }`} 
                
              >
              {this.props.isFromGroupsMainHeader && <><div style={{marginLeft:'auto'}}> 
                          
                          <Button variant="outlined" role="combobox" component="div" tabIndex={0}
                           aria-expanded={Boolean(this.state.menuViewAnchorEl)}
                            disabled={this.props.location.pathname.search("computer-systems") > -1}
                            aria-controls="viewMenu" id="first-content-element" autoFocus="true"
                            aria-haspopup="true" style={{marginRight:'8px'}}
                            aria-label={"Toggle groups view"}
                            onClick={this.handleViewMenuOpen} 
        
                          > 
                            {this.state.dropdownValue == 1 ? <><GridIcon color='#3272D9'/> Card View</>:
                              this.state.dropdownValue == 2 ? <><ListIcon color='#3272D9'/>  List View</>:
                                <><Topology color='#3272D9'/> Tree View</>
                            }
                            <ArrowDropDownIcon />
                          </Button>
                          </div>
                          <Menu
                            id="viewMenu"
                            anchorEl={this.state.menuViewAnchorEl}
                            keepMounted
                            onClose={this.handleViewMenuClose}
                            open={this.state.menuViewAnchorEl != null}
                            //className='viewMenuIcon'
                          >
                            <MenuItem value={1} onClick={this.handleViewMenuClose}>
                              <div>
                                <Link
                                  to={`${pathname}/card`}
                                  entityType={get(
                                    this.props.selectedEntity,
                                    "entityType"
                                  )}
                                  isDisabled={!!pathname.match(/computer-systems/)}
                                  title="Card View"
                                >
                                  <GridIcon color={this.state.dropdownValue == 1?'#3272D9':'#333840'}
                                    className={this.state.dropdownValue == 1 ?  " selectedButton" : "icon nav-icon"}
                                    style={{margin:0,marginRight:'0.25rem'}}
                                    //Cards
                                    title={Resource.get("Cards")}
                                    value={1}
                                  />
                                  <span
                                    style={{
                                      display: "inline-block",
                                      verticalAlign: "middle",
                                      color:this.state.dropdownValue == 1?'#3272D9':'#333840' ,
                                      marginTop:this.state.dropdownValue == 1?'-12px':'0px'   
                                    }}
                                  >
                                  {CardView}
                                </span>
                                </Link>
                              </div>
                            </MenuItem>
        
                            <MenuItem value={2} onClick={this.handleViewMenuClose}>
                              <div>
                                <Link
                                  to={`${pathname}`}
                                  entityType={get(
                                    this.props.selectedEntity,
                                    "entityType"
                                  )}
                                  isDisabled={!!pathname.match(/computer-systems/)}
                                  title="List View"
                                >
                                  <ListIcon color={this.state.dropdownValue == 2?'#3272D9':'#333840'}
                                    className={this.state.dropdownValue == 2 ?  " selectedButton" : "icon nav-icon"}
                                    style={{margin:0,marginRight:'0.25rem'}}
                                    value={2}
                                    //List
                                    title={Resource.get("List")}
        
                                  />
                                  <span
                                    style={{
                                      display: "inline-block",
                                      verticalAlign: "middle",
                                      color:this.state.dropdownValue == 2?'#3272D9':'#333840' ,
                                      marginTop:this.state.dropdownValue == 2?'-12px':'0px'   
                                    }}
                                  >
                                  {ListView}
                                </span>
                                </Link>
                              </div>
                            </MenuItem>
                            <MenuItem value={3} onClick={this.handleViewMenuClose}>
                              <div>
                                <Link
                                  to={`${pathname}/treeviewnew`}
                                  entityType={get(
                                    this.props.selectedEntity,
                                    "entityType"
                                  )}
                                  isDisabled={!!pathname.match(/computer-systems/)}
                                  title="Tree View"
                                >
                                  <Topology color={this.state.dropdownValue == 3?'#3272D9':'#333840'}
                                    className={this.state.dropdownValue == 3 ? " selectedButton" : "icon nav-icon"}
                                    value={3}
                                    style={{margin:0,marginRight:'0.25rem'}}
                                    //List
                                    title={Resource.get("Tree")}
        
                                  />
                                  <span
                                    style={{
                                      display: "inline-block",
                                      verticalAlign: "middle",
                                      color:this.state.dropdownValue == 3?'#3272D9':'#333840' ,
                                      marginTop:this.state.dropdownValue == 3?'-12px':'0px'                              }}
                                  >
                                  {TreeView}
                                </span>
                                </Link>
                              </div>
                            </MenuItem>
                          </Menu></>
                            }
                {!this.props.isFromGroupsMainHeader && <div style={{width:'100%',display:'flex',flexDirection:'row',borderBottom: '1px solid #DDE3ED'}}>
                    {this.state.dropdownValue !== 3
                    && !(this.props.location.pathname.search("computer-systems") > -1 )&& <>
                    <div style={{float: 'right'}}>
                    
                    <Button aria-label="Export" onClick={this.handleOpenExportDialog} variant="outlined" style={{marginLeft:'8px',marginRight:'8px',marginTop:'3px'}}>Export</Button>
                    {!(this.props.location.pathname.search("/card") > -1) &&
                            (get(this.props.selectedEntity, "type") ===
                              EntityTypes.AUTOMATIC ||
                              get(this.props.selectedEntity, "type") ===
                              EntityTypes.STATIC ||
                              get(this.props.selectedEntity, "type") ===
                              EntityTypes.DYNAMIC) && <Button aria-label="Edit Columns" onClick={this.handleOpenEditColumnDialog} variant="outlined" style={{marginTop:'3px'}}>Edit Columns</Button>}


                    {/*<IconButton aria-label={"More Actions"}
                aria-controls="action-menu-listcardview"
                aria-haspopup="true"
                onClick={this.handleMenuClick}
              >
                <MoreIcon role="figure" />
              </IconButton>
              <Menu
                            id="action-menu-listcardview"
                            anchorEl={this.state.menuAnchorEl}
                            keepMounted
                            onClose={this.handleActionMenuClose}
                            open={this.state.menuAnchorEl != null}
                          >
                              <MenuItem children={"Export"} onClick={this.handleOpenExportDialog}/>
                            {!this.props.location.pathname.search("/card") > -1 &&
                            (get(this.props.selectedEntity, "type") ===
                              EntityTypes.AUTOMATIC ||
                              get(this.props.selectedEntity, "type") ===
                              EntityTypes.STATIC ||
                              get(this.props.selectedEntity, "type") ===
                              EntityTypes.DYNAMIC) &&
                            <MenuItem children={"Edit Columns"} onClick={this.handleOpenEditColumnDialog}/>}
                              </Menu>*/}
                         
                          </div>
                       
                         </>
                          }
                            
<Tabs  value={ this.state.dropdownValue == 3?this.state.tabValue:currentTabValue} style={{marginLeft:'auto'}}>
                {!(this.props.location.pathname.search("computer-systems") > -1) && 
                  <Tab value={NAV_MENUS.DETAIL_VIEW} tabIndex={0} title={this.props.treeNode?.entityType===EntityTypes.COMPUTER_SYSTEM || 
                  this.props.treeNode?.entityType===EntityTypes.INTERFACES || get(this.props.selectedEntity, "typeName") ===
                        EntityTypes.NETWORK_INTERFACE || get(this.props.selectedEntity, "typeName") ===
                        EntityTypes.COMPUTER_SYSTEM ?"Details":"Members"} label={this.props.treeNode?.entityType===EntityTypes.COMPUTER_SYSTEM || this.props.treeNode?.entityType===EntityTypes.INTERFACES || get(this.props.selectedEntity, "typeName") ===
                        EntityTypes.NETWORK_INTERFACE || get(this.props.selectedEntity, "typeName") ===
                        EntityTypes.COMPUTER_SYSTEM ?"Details":"Members"}

                        disabled={(this.state.dropdownValue==1 || this.state.dropdownValue==2) && (get(this.props.selectedEntity, "typeName") ===
                        EntityTypes.NETWORK_INTERFACE ||  this.props.treeNode?.entityType===EntityTypes.INTERFACES ||
                        this.props.selectedEntity.masterElementGroup)}
                        
                    onClick={this.onNavLinkSelect.bind(
                      this,
                      this.state.dropdownValue !== 3?(this.state.dropdownValue===2?`${pathname}`:`${pathname}/card`):"",
                      NAV_MENUS.DETAIL_VIEW
                    )
                  }
                    /*  disabled={
                       this.props.selectedEntity.masterElementGroup
                        || get(this.props.selectedEntity, "typeName") ===  EntityTypes.NETWORK_INTERFACE
                     } */
                  >
                    {/* <GroupDetailIcon
                      className="nav-icon group-detail"
                      //Detail
                      title={Resource.get("Detail View")}
                    /> */}
                    {DEVICE_DETAILS}
                  </Tab>

                }

                {this.props.saas &&
                this.props.doiurl &&
                this.props.featureFlags &&
                this.props.featureFlags.globalAlarmsView ? (
                  match.params.csIds ? (
                    <Tab value={NAV_MENUS.ALARMS} label={ALARMS} title={ALARMS} tabIndex={0} id={!this.props.isFromGroupsMainHeader?'first-content-element':'tab_alarms'}
                 
               
                      onClick={this.onNavLinkSelect.bind(
                        this,
                        getAlarmUrl(
                          this.props.doiurl,
                          "csId=" +
                          match.params.csIds +
                          "&deviceName=" +
                          deviceName
                        ),
                        NAV_MENUS.ALARMS
                      )}
                    >
                      {/* <AlarmIcon
                        className="nav-icon"
                        //Alarms
                        title={Resource.get("Alarms")}
                      /> */}
                      {ALARMS}
                    </Tab>
                  ) : groupId && groupId.length && groupId[0] !== 0 ? (
                    <Tab  value={NAV_MENUS.ALARMS} label={ALARMS} title={ALARMS} tabIndex={0} id={!this.props.isFromGroupsMainHeader?'first-content-element':'tab_alarms'}
                    
                      onClick={this.onNavLinkSelect.bind(
                        this,
                        getAlarmUrl(this.props.doiurl, filters),
                        NAV_MENUS.ALARMS
                      )}
                    >
                      {/* <AlarmIcon
                        className="nav-icon"
                        //Alarms
                        title={Resource.get("Alarms")}
                      /> */}
                      {ALARMS}
                    </Tab>
                  ) : (
                    <Tab value={NAV_MENUS.ALARMS} label={ALARMS} title={ALARMS} tabIndex={0} id={!this.props.isFromGroupsMainHeader?'first-content-element':'tab_alarms'}
                     
                      onClick={this.onNavLinkSelect.bind(
                        this,
                        getAlarmUrl(this.props.doiurl),
                        NAV_MENUS.ALARMS
                      )}
                    >
                      {/* <AlarmIcon
                        className="nav-icon"
                        //Alarms
                        title={Resource.get("Alarms")}
                      /> */}
                      {ALARMS}
                    </Tab>
                  )
                ) : (
                  <Tab value={NAV_MENUS.ALARMS} label={ALARMS} title={ALARMS} tabIndex={0} id={!this.props.isFromGroupsMainHeader?'first-content-element':'tab_alarms'}
                   
                    onClick={this.onNavLinkSelect.bind(
                      this,
                      this.state.dropdownValue !== 3?(this.state.dropdownValue===2?`${pathname}/alarms`:`${pathname}/card/alarms`):"",
                     
                      NAV_MENUS.ALARMS
                    )}
                  >
                    {/* <AlarmIcon
                      className="nav-icon"
                      //Alarms
                      title={Resource.get("Alarms")}
                    /> */}
                    {ALARMS}
                  </Tab>
                )}
                <Tab value={NAV_MENUS.CABI} label={"Summary"} title={"Summary"} tabIndex={0}
                 
                  // disabled={
                  //   !(
                  //     this.props.location.pathname.search("computer-systems") >
                  //       -1 && this.isIconAllowed(NAV_MENUS.CABI)
                  //   )
                  // }
                  // disabled={
                  //   (this.props.location.pathname.search("computer-systems") ==
                  //     -1 &&
                  //     (get(this.props.selectedEntity, "typeName") ===
                  //       EntityTypes.NETWORK_INTERFACE ||
                  //       this.props.selectedEntity.masterElementGroup)) ||
                  //   (this.props.location.pathname.search("treeviewnew") > -1 &&
                  //     !this.isIconAllowed(NAV_MENUS.CABI)) ||
                  //   (this.props.location.pathname.search("treeviewnew") == -1 &&
                  //     this.props.location.pathname.search("computer-systems") ==
                  //       -1)
                  // }
                  disabled={
                    (this.props.location.pathname.search("computer-systems") ==
                      -1 &&
                      (get(this.props.selectedEntity, "typeName") ===
                        EntityTypes.NETWORK_INTERFACE ||
                        this.props.selectedEntity.masterElementGroup)) ||
                    (this.props.location.pathname.search("treeviewnew") > -1 &&
                      !this.isIconAllowed(NAV_MENUS.CABI))
                    //    ||
                    // (this.props.location.pathname.search("treeviewnew") == -1 &&
                    //   this.props.location.pathname.search("computer-systems") ==
                    //     -1)
                  }
                  onClick={this.onNavLinkSelect.bind(
                    this,
                    this.getPath("dashboard", pathname),
                    NAV_MENUS.CABI
                  )}
                >
                  {/* <DashboardsIcon
                    className="nav-icon"
                    //Dashboards
                    title={Resource.get("Dashboards")}
                  /> */}
                  {DASHBOARDS}
                </Tab>
                <Tab value={NAV_MENUS.MAINTENANCE} label={MAINTENANCE} title={MAINTENANCE} tabIndex={0}
                  
                 
                  disabled={
                    disableMaintenanceIcon ||
                    this.props.selectedEntity.masterElementGroup ||
                    get(this.props.selectedEntity, "typeName") ===
                    EntityTypes.NETWORK_INTERFACE ||
                    this.props.location.pathname.search("computer-systems") >
                    -1 ||
                    (this.props.location.pathname.search("treeviewnew") > -1 &&
                      !this.isIconAllowed(NAV_MENUS.MAINTENANCE))
                  }
                  onClick={this.onNavLinkSelect.bind(
                    this,
                    this.state.dropdownValue !== 3?(this.state.dropdownValue===2?`${pathname}/maintenance`:`${pathname}/card/maintenance`):"",
                    NAV_MENUS.MAINTENANCE
                  )}
                >
                  {/* <MaintenanceIcon
                    className="nav-icon"
                    //Maintenance
                    title={Resource.get("Maintenance")}
                  /> */}
                  {MAINTENANCE}
                </Tab>
                <Tab value={NAV_MENUS.METRICS} label={METRICS} title={METRICS} tabIndex={0}
                disabled={ this.props.disabledTreeViewNavs.includes(NAV_MENUS.METRICS)}
                 
                  /*disabled={ get(this.props.selectedEntity, "typeName") ===
                        EntityTypes.NETWORK_INTERFACE ||  this.props.treeNode?.entityType===EntityTypes.INTERFACES ||
                        this.props.selectedEntity.masterElementGroup }*/
                 
                  onClick={this.onNavLinkSelect.bind(
                    this,
                    this.state.dropdownValue !== 3?(this.state.dropdownValue===2?`${pathname}/metrics`:`${pathname}/card/metrics`):"",
                    NAV_MENUS.METRICS
                  )}
                >
                  {/* <MetricsIcon
                    className="nav-icon"
                    //Metrics
                    title={Resource.get("Metrics")}
                  /> */}
                  {METRICS}
                </Tab>
                <Tab value={NAV_MENUS.MCS} label={MONITORING_CONFIG} title={MONITORING_CONFIG} tabIndex={0} style={{maxWidth:'134px'}}
                  
                  disabled={
                    disableMCS ||
                    (this.props.location.pathname.search("computer-systems") ==
                      -1 && this.props.location.pathname.search("treeviewnew")===-1 &&
                      (this.props.selectedEntity.masterElementGroup ||
                        get(this.props.selectedEntity, "typeName") ===
                        EntityTypes.NETWORK_INTERFACE)) ||
                    !(
                      this.props.location.pathname.search("computer-systems") >
                      -1 ||
                      (this.props.location.pathname.search("treeviewnew")>=0 && this.isIconAllowed(NAV_MENUS.MCS)) ||
                      (this.props.location.pathname.search("treeviewnew")===-1 &&(
                      get(this.props.selectedEntity, "type") ===
                      EntityTypes.AUTOMATIC ||
                      get(this.props.selectedEntity, "type") ===
                      EntityTypes.STATIC ||
                      get(this.props.selectedEntity, "type") ===
                      EntityTypes.DYNAMIC
                    )))
                  }
                  onClick={this.onNavLinkSelect.bind(
                    this,
                    this.state.dropdownValue !== 3?(this.state.dropdownValue===2?`${pathname}/configuration`:`${pathname}/card/configuration`):"",
                    NAV_MENUS.MCS
                  )}
                >
                  {/* <SettingIcon
                    className="nav-icon"
                    //Monitoring Config
                    title={Resource.get("Monitoring Config")}
                  /> */}
                  {MONITORING_CONFIG}
                </Tab>
                <Tab value={NAV_MENUS.INTERFACES} label={INTERFACES} title={INTERFACES} tabIndex={0}
                  
                  disabled={ this.props.disabledTreeViewNavs.includes(NAV_MENUS.INTERFACES) ||
                    !(
                      this.props.selectedEntity.masterElementGroup ||
                      get(this.props.selectedEntity, "typeName") ===
                      EntityTypes.NETWORK_INTERFACE ||
                      this.props.location.pathname.search("computer-systems") >
                      -1 ||
                      (this.props.location.pathname.search("treeviewnew") > -1 &&
                        !this.isIconAllowed(NAV_MENUS.MAINTENANCE))
                    )
                  }
                  
                  onClick={this.onNavLinkSelect.bind(
                    this,
                    this.state.dropdownValue !== 3?(this.state.dropdownValue===2?`${pathname}/interfaces`:`${pathname}/card/interfaces`):"",
                    NAV_MENUS.INTERFACES
                  )}
                >
                  {/* <InterfacesIcon
                    className="nav-icon"
                    //Interfaces
                    title={Resource.get("Interfaces")}
                  /> */}
                  {INTERFACES}
                </Tab>
                </Tabs>
                        
               
       
                            
                              
                 
                
                </div> }
              </div>
            );
          }}
        />
        <ExportGroupDetailsDialog
          open={this.state.openExportTypeDialog}
          requestClose={this.handleExportClose}
          savedColumns={this.state.savedColumns}
          groupName={this.props.name}
          {...this.props}
          fromTreeView={false}
        />
        <CustomColumnsDialog
          open={this.state.openEditColumnDailog}
          presistedColumns={this.state.savedColumns}
          saveSelectedCols={this.handleSaveSelectedColumns.bind(this)}
          handleClose={() => {
            this.setState({openEditColumnDailog: false});
          }}
        />
      </div>
    );
  }
}

ToggleIcons.contextTypes = {
  entityMatchRegex: PropTypes.string,
};

export default ToggleIcons;
