export const FETCH_OPS = "FETCH_OPS";
export const FETCH_OPS_SUCCESS = "FETCH_OPS_SUCCESS";
export const FETCH_OPS_FAILURE = "FETCH_OPS_FAILURE";
export const SAVE_PERIOD = "SAVE_PERIOD";
export const SAVE_PERIOD_SUCCESS = "SAVE_PERIOD_SUCCESS";
export const SAVE_PERIOD_FAILURE = "SAVE_PERIOD_FAILURE";
export const DELETE_OP = "DELETE_OP";
export const DELETE_OP_SUCCESS = "DELETE_OP_SUCCESS";
export const DELETE_OP_FAILURE = "DELETE_OP_FAILURE";
export const FETCH_ID_SUCCESS = "FETCH_ID_SUCCESS";
export const FETCH_BY_ID = "FETCH_BY_ID";
export const FETCH_ID_FAILURE = "FETCH_ID_FAILURE";
