import { TIMERANGE_SET } from "./actionTypes";

const initialState = {
  hours: 1,
  endDate: new Date().getTime(),
  aggregationInterval: -1,
  aggregationFunction: "AVG",
  isInterpolation:false,
};

const timerange = (state = initialState, action) => {  
  switch (action.type) {
    case TIMERANGE_SET:      
      return action.timerange;
    default:
      return state;
  }
};

export default timerange;
