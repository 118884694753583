import React from 'react'
import { AlarmsIcon,CenterError as Error, CenterInfo,Grid } from '../../../ui-components/uim-components'

import debounce from 'lodash/debounce'
import moment from 'moment'
import SeverityIcon from './../../../icons/SeverityIcon'
import './alarms.less'
import Resource from './Resource'
import { Component } from 'react'
import {CircularProgress} from '@mineral/core';
import alarms from './../../../../api/alarms/alarms'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import orderBy from 'lodash/orderBy'

const severityLables = ['Clear', 'Info', 'Warning', 'Minor', 'Major', 'Critical']
const groupCols = [
  {
	  //Severity
    headerName: Resource.get('Severity'),
    field: 'severity',
    maxWidth: 60,
    sortable: true,
    cellRendererFramework: model => {
      const item = model.data
      return (
        <div className="details-popup-alarms__icon">
          <SeverityIcon level={item.severity} />
        </div>
      )
    },
    headerClass: 'details-popup-alarms__header--severity',
    //Alarm Severity
    headerTooltip: Resource.get('Alarm Severity'),
    tooltipValueGetter: params =>  severityLables[params.data.severity],
    headerComponentParams: {
      icon: <AlarmsIcon title="Max Alarm Severity" />,
    },
  },
  {
	  //Message
    headerName: Resource.get('Message'),
    field: 'message',
    tooltipField: 'message',
    sortable: true,
    maxWidth: 250,
    cellRendererFramework: params => {
      return (
        <div className="ag-cell-value">
          {params.data.message}
        </div>
      )
    }

  },
  {
	  //Device
    headerName: Resource.get('Device'),
    field: 'deviceAttributes.DisplayName',
    tooltipField: 'deviceAttributes.DisplayName',
    sortable: true,
    cellRendererFramework: params => {
      return (
        <div className="ag-cell-value">
          {params.data.deviceAttributes.DisplayName[0]}
        </div>
      )
    }
  },
  {
	  //Type
    headerName: Resource.get('Type'),
    field: 'deviceAttributes.PrimaryRole',
    tooltipField: 'deviceAttributes.PrimaryRole',
    maxWidth: 100,
    sortable: true,
    cellRendererFramework: params => params.data.deviceAttributes.PrimaryRole[0]

  },
  {
	  //Created
    headerName: Resource.get('Created'),
    field: 'timeOrigin',
    maxWidth: 200,
    sortable: true,
    cellRendererFramework: params => {
      const timeOrigin = params.data.timeOrigin * 1000
      const time = moment(timeOrigin).format('MMMM Do YYYY, h:mm a')
      return (
        <div className="ag-cell-value">
          <span title={time}>
            {time}
          </span>
        </div>
      )
    },
  },
  {
	  //Duration
    headerName: Resource.get('Duration'),
    field: 'duration',
    maxWidth: 120,
    cellRendererFramework: params => {
      const timeOrigin = params.data.timeOrigin * 1000
      const time = moment(timeOrigin).fromNow()
      // const formattedTime = moment(timeOrigin).format('MMMM Do YYYY, h:mm a')
      return (
        <div className="ag-cell-value">
          <span title={time}>
            {time}
          </span>
        </div>
      )
    },
  },
]

const Panel = props => {
  return (
    <div className="ag-material details-popup-alarms">
      <Grid
        headerHeight={30}
        rowHeight={60}
        columnDefs={groupCols}
        rowData={props.data}
        tooltipShowDelay={0}
        onGridReady={params => {
          const gridApi = params.api
          gridApi.sizeColumnsToFit()
          window.onresize = debounce(event => {
            gridApi.sizeColumnsToFit()
          }, 200)
        }}
      />
    </div>
  )
}

Panel.defaultProps = {
  data: [],
}



class PanelRedux extends Component {
  state = {
    data: [],
    loadStatus: 'loading',
  }
  _getAlarms = props => {
    const params = {
      context: {
        type: props.contextType,
        value: get(props, 'entity.id'),
      },
      filter: '',
      pageNumber: 0,
      pageSize: null,
      sort: [{ sortColumn: 'duration', sortDirection: 'asc' }],
    }
    if (props.entity) {
      alarms
        .post(params)
        .then(res => res.data.alarms)
        .then(alarms => {
          // this is only the inital sort other sorting is handled by the grid
          const sortedData = orderBy(alarms, ['severity'], ['desc'])
          this.setState({
            data: sortedData,
            loadStatus: 'complete',
          })
        })
        .catch(err => {
          this.setState({ loadStatus: 'fail' })
        })
    }
  }
  componentWillMount() {
    this._getAlarms(this.props)
  }
  componentWillReceiveProps(nextProps) {
    this._getAlarms(nextProps)
  }
  render() {
    let view
    switch (this.state.loadStatus) {
      case 'complete':
        view = isEmpty(this.state.data) ? (
          <CenterInfo 
          //No alarms found
          title= {Resource.get("No alarms found")} />
        ) : (
          <Panel data={this.state.data} />
        )
        break
      case 'fail':
        view = <Error />
        break
      case 'loading':
      default:
        view = <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><CircularProgress style={{color: '#3272D9'}} /></div>
        break
    }
    return view
  }
}

export default PanelRedux
