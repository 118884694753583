import axios from "axios";
import config from "../config";

class DeviceGroups {
  getDeviceGroups(csId) {
    if(process.env.NODE_ENV == "development"){
        return axios.get('http://10.252.25.208:8080/castlemock/mock/rest/project/TK8t3o/application/UXxhjW/getDeviceGroups');
    }else{
    var url = [
      config.basename,
      'api/dashboard/component/devicegroups',
    ];
    return axios.get(url.join("/").concat("?csId="+csId));
  }
}
}

let devicegroups = new DeviceGroups();
export default devicegroups;
