import React from "react";
// import {TextField} from '@mineral/core';
import {TextField} from "@mineral/core";
import SearchIcon from "@material-ui/icons/Search";
import { InputAdornment, Tooltip } from "@mineral/core";



const SearchGroup = (props) => {
 
  return (
    <Tooltip title={"Search"} PopperProps={{ style: { marginTop: -16 } }} arrow>
      <TextField
        onChange={props.handleSearch}
        id="search"
        placeholder={props.placeholder}
        variant="outlined"
        style={props.textFieldStyles}
        InputProps={{
         
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon
                style={props.searchIconStyles}
                role="search"
                name="search"
              />
            </InputAdornment>
          ),
          style: props.inputPropsStyle,
         
          "aria-label": "search",
        }}
        autoComplete="off"
      />
    </Tooltip>
  );
};

export default SearchGroup;
