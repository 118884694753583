// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Highest Alarm Severity Level and Total Alarm Count
  content["Highest Alarm Severity Level and Total Alarm Count"] =
  "Nível de gravidade mais alto do alarme e contagem total de alarmes";

  //Max Alarm Severity
  content["Max Alarm Severity"] =
  "Gravidade máxima do alarme";

  //Name
  content["Name"] =
  "Nome";

  //Elements
  content["Elements"] =
  "Elementos";

  //Device Type
  content["Device Type"] =
  "Tipo de dispositivo";

  //Operating system
  content["Operating system"] =
  "Sistema operacional";

  //IP address
  content["IP address"] =
  "Endereço IP";

  //Monitored by
  content["Monitored by"] =
  "Item(ns) monitorado(s) por";

  //Has Robot
  content["Has Robot"] =
  "Tem robô";

  //Discovered
  content["Discovered"] =
  "Detectado";

  // END OF LOCALIZATION

export default content;
