// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Settings
  content["Settings"] =
  "Settings";

  //Restmon
  content["Restmon"] =
  "Restmon";

  //Create a custom probe for monitoring via REST APIs
  content["Create a custom probe for monitoring via REST APIs"] =
  "Create a custom probe for monitoring via REST APIs";

  //Close
  content["Close"] =
  "Close";

  //Close icon
  content["Close icon"] =
  "Close icon";

  //RESTMon
  content["RESTMon"] =
  "RESTMon";

  //Create custom RESTful API Monitoring
  content["Create custom RESTful API Monitoring"] =
  "Create custom RESTful API Monitoring";

  // END OF LOCALIZATION

export default content;
