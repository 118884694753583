import config from './../config'
import axios from 'axios'

const downloadLocURL = `${config.basename}/api/v1/downloads/relayhubinstaller_ws`

class Downloads {
  getDownloadLocation() {
    return axios.get(downloadLocURL)
  }
}
let downloads = new Downloads()
export default downloads
