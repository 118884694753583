export const SAVE_WMI_CREDENTIALS = 'SAVE_WMI_CREDENTIALS'
export const SAVE_WMI_CREDENTIALS_SUCCESS = 'SAVE_WMI_CREDENTIALS_SUCCESS'
export const SAVE_WMI_CREDENTIALS_FAILURE = 'SAVE_WMI_CREDENTIALS_FAILURE'
	
export const EDIT_WMI_CREDENTIALS = 'EDIT_WMI_CREDENTIALS'
export const EDIT_WMI_CREDENTIALS_SUCCESS = 'EDIT_WMI_CREDENTIALS_SUCCESS'
export const EDIT_WMI_CREDENTIALS_FAILURE = 'EDIT_WMI_CREDENTIALS_FAILURE'
	
export const SAVE_UNIX_CREDENTIALS = 'SAVE_UNIX_CREDENTIALS'
export const SAVE_UNIX_CREDENTIALS_SUCCESS = 'SAVE_UNIX_CREDENTIALS_SUCCESS'
export const SAVE_UNIX_CREDENTIALS_FAILURE = 'SAVE_UNIX_CREDENTIALS_FAILURE'
			
export const EDIT_UNIX_CREDENTIALS = 'EDIT_UNIX_CREDENTIALS'
export const EDIT_UNIX_CREDENTIALS_SUCCESS = 'EDIT_UNIX_CREDENTIALS_SUCCESS'
export const EDIT_UNIX_CREDENTIALS_FAILURE = 'EDIT_UNIX_CREDENTIALS_FAILURE'
	
export const GET_WMI_CREDENTIALS = 'GET_WMI_CREDENTIALS'
export const FETCH_WMI_CREDENTIALS_SUCCESS = 'FETCH_WMI_CREDENTIALS_SUCCESS'
export const FETCH_WMI_CREDENTIALS_FAILURE = 'FETCH_WMI_CREDENTIALS_FAILURE'

export const POLL_WMI_START = 'POLL_WMI_START'
export const POLL_WMI_STOP = 'POLL_WMI_STOP'

export const GET_UNIX_CREDENTIALS = 'GET_UNIX_CREDENTIALS'
export const FETCH_UNIX_CREDENTIALS_SUCCESS = 'FETCH_UNIX_CREDENTIALS_SUCCESS'
export const FETCH_UNIX_CREDENTIALS_FAILURE = 'FETCH_UNIX_CREDENTIALS_FAILURE'
	
export const POLL_UNIX_START = 'POLL_UNIX_START'
export const POLL_UNIX_STOP = 'POLL_UNIX_STOP'
	
export const DELETE_UNIX_CRED = 'DELETE_UNIX_CRED'
export const UPDATE_UNIX_CRED = 'UPDATE_UNIX_CRED'
export const ADDED_UNIX_CRED = 'ADDED_UNIX_CRED'