import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
  input: {
    fontSize: 14,
    '&::placeholder': {
      fontStyle: 'italic',
      fontSize: 14,
    },
  },

  disabledInput: {
    WebkitTextFillColor: '#58606E !important',
    color: '#58606E  !important',
  },

  textarea: {
    fontSize: 14,
    '&::placeholder': {
      fontStyle: 'italic',
      fontSize: 14,
    },
  },

  select: {
    fontSize: 14,
    '&:before': {
      fontSize: 14,
    },
    '&:after': {
      fontSize: 14,
    },
  },

  root: {
    '&:hover $notchedOutline': {
      borderColor: '#3272D9',
    },
    '&.MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(0, 0, 0, 0.23)',
    },
    '&:hover $notchedOutlineError': {
      borderColor: 'red',
    },
    '&:focus-within $notchedOutline': {
      borderColor: '#3272D9',
    },
    '&:focus-within $notchedOutlineError': {
      borderColor: 'red',
    },
  },

  selectRoot: {
    '&:focus-within $notchedOutlineError': {
      borderColor: 'red',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#3272D9',
    },
    '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#3272D9',
    },
  },

  notchedOutline: {},
  notchedOutlineError: {
    borderColor: 'red',
    '&:hover $notchedOutline': {
      borderColor: 'red',
    },
  },
  adornEnd: {
    pointerEvents: 'unset'
  }
}))
