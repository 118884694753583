import React from "react";
import moment from "moment";

const style = {
  padding: 6,
  backgroundColor: "#fff",
  border: "1px solid #ccc"
};

const CustomTooltip = props => {
    return (
      <div className="area-chart-tooltip" style={style}>
        {(moment(props.label).toString()).substr(0,24)}
      </div>
    );
};

export default CustomTooltip;
