import jstz from "jstz";
import connect from "./connect";
import { getAlarmUrl, getContainerGroupFilters } from "./common";
import LocalStorageManager from "./LocalStorageManager";

class Visualize {
  _initialize(
    url,
    windowVariable,
    successCallback = () => {},
    failedCallback = () => {}
  ) {
    if (!window[windowVariable]) {
      var OPERATOR_CONSOLE = window.OPERATOR_CONSOLE;
      var nonce = OPERATOR_CONSOLE["cspNonce"];
      var script = document.createElement("script");
      script.type = "text/javascript";
      script.nonce = nonce;
      if (script.readyState) {
        //IE
        script.onreadystatechange = function () {
          if (
            script.readyState === "loaded" ||
            script.readyState === "complete"
          ) {
            script.onreadystatechange = null;
            successCallback(window[windowVariable]);
          } else {
            failedCallback(Error("failed to load:", url));
          }
        };
      } else {
        //Others
        script.onload = function () {
          successCallback(window[windowVariable]);
        };
        script.onerror = (error) => {
          failedCallback(error);
        };
      }
      script.src = url;
      document.getElementsByTagName("head")[0].appendChild(script);
    } else {
      successCallback(window[windowVariable]);
    }
  }
  loadCSS() {
    return new Promise((resolve, reject) => {
      let styleLink = document.createElement("link");
      styleLink.type = "text/css";
      styleLink.rel = "stylesheet";
      styleLink.onload = () => resolve();
      styleLink.href = "/cabi/css/style.css";

      let canvasLink = document.createElement("link");
      canvasLink.type = "text/css";
      canvasLink.rel = "stylesheet";
      canvasLink.onload = () => resolve();
      canvasLink.href = "/cabi/css/canvas.css";

      let jasperuiLink = document.createElement("link");
      jasperuiLink.type = "text/css";
      jasperuiLink.rel = "stylesheet";
      jasperuiLink.onload = () => resolve();
      jasperuiLink.href = "/cabi/css/jasper-ui.css";

      let paginationLink = document.createElement("link");
      paginationLink.type = "text/css";
      paginationLink.rel = "stylesheet";
      paginationLink.onload = () => resolve();
      paginationLink.href = "/cabi/css/pagination.css";

      let headScript = document.getElementsByTagName("head")[0];
      headScript.appendChild(styleLink);
      headScript.appendChild(canvasLink);
      headScript.appendChild(jasperuiLink);
      headScript.appendChild(paginationLink);
    });
  }
  config(opts = {}) {
    const visualize = window.visualize;
    let currentThemePath =
      opts.currentThemePath ||
      "theme/default/theme.css".split("/").slice(0, -1);

    if (visualize) {
      visualize.config({
        server: opts.server || "",
        scripts: "optimized-scripts",
        logEnabled: opts.logEnabled || true,
        logLevel: "error",
        _showInputControls: opts.showInputControls || "false",
        theme: {
          baseUrl: opts.baseUrl || "",
          path: currentThemePath[0],
          name: currentThemePath[1],
        },
      });
    }
  }
  use(opts = {}) {
    const visualize = window.visualize;
    if (visualize) {
      const timeZone = jstz.determine().name();
      visualize(
        {
          auth: {
            loginFn: function (properties, request) {
              return request({
                url:
                  opts.cabiUrl +
                  "/?jaspertoken=" +
                  opts.jasperToken +
                  "&orgidl=ca" +
                  "&userLocale=" +
                  opts.userLocale +
                  "&userTimezone=" +
                  timeZone,
              });
            },
          },
        },
        function (v) {
          v(opts.container || "#testIds").report({
            resource:
              opts.resource ||
              "/public/ca/uim/reports/common/device/device_details",
            container: opts.container || "#testIds",
            params: opts.params || { cs_id: ["91"] },
            error: opts.handleError || handleError,
          });
          function handleError(err) {
            //console.log(err.message);
          }
        }
      );
    }
  }
  checkAndLoadVisualize(url) {
    return new Promise((resolve, reject) => {
      if (window.visualize) {
        resolve(true);
      } else {
        return Promise.all([
          this.load(url + "/client/visualize.js"),
          this.load("/cabi/js/cabiDashboard.js", "cabiDashboard"),
          this.loadCSS(),
        ]).then(
          (responses) => {
            resolve(true);
          },
          (err) => {
            reject();
          }
        );
      }
    });
  }
  doesResourceExist(opts = {}) {
    return new Promise((resolve, reject) => {
      const visualize = window.visualize;
      if (visualize) {
        const timeZone = jstz.determine().name();
        visualize(
          {
            auth: {
              loginFn: function (properties, request) {
                return request({
                  url:
                    opts.url +
                    "/?jaspertoken=" +
                    opts.jasperToken +
                    "&orgidl=" +
                    opts.orgidl +
                    "&userLocale=" +
                    opts.locale +
                    "&userTimezone=" +
                    timeZone,
                });
              },
            },
          },
          function (v) {
            const resourcePath = opts.resource;
            let lastIndex = resourcePath.lastIndexOf("/");
            let folderUri = resourcePath.substring(0, lastIndex);

            let search = v.resourcesSearch({
              folderUri: folderUri,
              types: [opts.resourceType],
              recursive: false,
              limit: 500,
              sortBy: "label",
              showHiddenItems: false,
              success: (resources) => {
                let resourceFound = false;
                for (let i = 0; i < resources.length; i++) {
                  if (resources[i].uri === resourcePath) {
                    resourceFound = true;
                    break;
                  }
                }

                resolve(resourceFound);
              },
              error: (error) => {
                resolve(false);
              },
            });
          }
        );
      } else {
        reject("No visualize instance");
      }
    });
  }
  
  getDashboardUrls(opts = {}) {
    return new Promise((resolve, reject) => {
      const visualize = window.visualize;
      if (visualize) {
        const timeZone = jstz.determine().name();
        visualize(
          {
            auth: {
              loginFn: function (properties, request) {
                return request({
                  url:
                    opts.url +
                    "/?jaspertoken=" +
                    opts.jasperToken +
                    "&orgidl=" +
                    opts.orgidl +
                    "&userLocale=" +
                    opts.locale +
                    "&userTimezone=" +
                    timeZone,
                });
              },
            },
          },
          function (v) {
           
            let search = v.resourcesSearch({
              folderUri: "/",
              types: [opts.resourceType],
              recursive: true,
              limit: 500,
              sortBy: "label",
              showHiddenItems: false,
              success: (resources) => {
                                resolve(resources);
              },
              error: (error) => {
                resolve(error);
              },
            });
          }
        );
      } else {
        reject("No visualize instance");
      }
    });
  }


  justLogin(opts = {}) {
    return new Promise((resolve, reject) => {
      const visualize = window.visualize;
      if (visualize) {
        const timeZone = jstz.determine().name();
        visualize(
          {
            auth: {
              loginFn: function (properties, request) {
                return request({
                  url:
                    opts.url +
                    "/?jaspertoken=" +
                    opts.jasperToken +
                    "&orgidl=" +
                    opts.orgidl +
                    "&userLocale=" +
                    opts.locale +
                    "&userTimezone=" +
                    timeZone,
                });
              },
            },
          },
          function (v) {
           
            if(v)
            console.log("Successfully logged into jasperserver");
            resolve(true);
          }
        );
      } else {
        reject("No visualize instance");
      }
    });
  }


  setProductList(cabiProps) {
    this.saas = cabiProps.saas;
    this.doiurl = cabiProps.doiurl;
    this.featureFlags = cabiProps.featureFlags;
    // this.groupName=cabiProps.entity ? cabiProps.entity.name : ''
    this.entity = cabiProps.entity;
    let length = cabiProps.breadcrumbs ? cabiProps.breadcrumbs.length : -1;
    this.breadcrumb = length > 0 ? cabiProps.breadcrumbs[length - 1].name : "";
  }
  loadDashboard(dashboardState = {}, dashToRouteMap = {}) {
    return Promise.all([
      this.load(dashboardState.url + "/client/visualize.js"),
      this.load("/cabi/js/cabiDashboard.js", "cabiDashboard"),
      this.loadCSS(),
    ]).then(
      (responses) => {
        // let visualize = responses[0]
        let cabiDashboard = responses[1];
        const cabiServerLocation = dashboardState.url;
        const currentThemePath = "themes/default/theme.css"
          .split("/")
          .slice(0, -1);
        const timeZone = jstz.determine().name();
        return new Promise((resolve, reject) => {
          const alarmDashPath =
            "/public/ca/uim/dashboards/common/alarm_summary";
          let dashboard = cabiDashboard({
            cabiServerUrl: cabiServerLocation,
            isOpCon: true,
            theme: {
              baseUrl: cabiServerLocation,
              path: currentThemePath[0],
              name: currentThemePath[1],
              href: "",
            },
            auth: {
              loginFn: function (properties, request) {
                return request({
                  url:
                    cabiServerLocation +
                    "/?jaspertoken=" +
                    dashboardState.jasperToken +
                    "&orgidl=" +
                    dashboardState.orgidl +
                    "&userLocale=" +
                    dashboardState.locale +
                    "&userTimezone=" +
                    timeZone,
                });
              },
            },
            dashboardContainerId: dashboardState.container || "dashContainer",
            breadcrumb: "Home",
            dashboardPath: dashboardState.dashboardPath,
            dashboardParams: dashboardState.params,
            // overrideHistory: dashboardState.overrideHistory || false,
            overrideHistory: true,
            disableBrowserNavHistory:
              dashboardState.disableBrowserNavHistory || true,
            onDashboardSuccess: function (info) {
              resolve(dashboard);

              if (dashboardState.onDashboardSuccess)
                dashboardState.onDashboardSuccess(info);
            },
            onDashboardFailure: function (err) {
              console.error("Error loading dashboard:", err);
              reject(err, dashboard);
            },
            onDashboardDrillAcross: function (info) {
              dashboardState.dashboardParams = info.dashboardParams;
              LocalStorageManager.setItem("DASHBOARDDRILLDOWNPARAMS", {
                dashboardParams: info.dashboardParams,
              });
              if (dashboardState.onDashboardDrillAcross)
                dashboardState.onDashboardDrillAcross(info);
            },
            // onDashboardDrillDown will return true when navigation is handled by Operator Console
            onDashboardDrillDown: (info) => {
              let dashboardPath = info.dashboardPath;
              let dashboardParams = info.dashboardParams;
              dashboardState.dashboardPath = dashboardPath;
              dashboardState.dashboardParams = dashboardParams;

              // alarm view override logic (special case that can't be mapped properly)
              if (dashboardPath == alarmDashPath) {
                if (
                  this.saas &&
                  this.doiurl &&
                  this.featureFlags &&
                  this.featureFlags.globalAlarmsView
                ) {
                  let route = getAlarmUrl(this.doiurl);

                  if (Array.isArray(dashboardParams.cs_id)) {
                    // route = `/computer-systems/${dashboardParams.cs_id[0]}/alarms`
                    let drilldownParams = LocalStorageManager.getItem(
                      "DASHBOARDDRILLDOWNPARAMS",
                      "dashboardParams"
                    );
                    if (
                      drilldownParams &&
                      Object.keys(drilldownParams).length !== 0 &&
                      (drilldownParams.prid[0] || drilldownParams.group_id[0])
                    ) {
                      let filters = drilldownParams.prid[0]
                        ? `&probe=${drilldownParams.prid[0]}`
                        : `&groupId=${drilldownParams.group_id[0]}&group=${this.breadcrumb}`;
                      route = getAlarmUrl(
                        this.doiurl,
                        "csId=" +
                          dashboardParams.cs_id[0] +
                          "&deviceName=" +
                          drilldownParams.name[0] +
                          filters
                      );
                    } else {
                      route = getAlarmUrl(
                        this.doiurl,
                        "csId=" +
                          dashboardParams.cs_id[0] +
                          "&deviceName=" +
                          this.breadcrumb
                      );
                    }
                  } else if (Array.isArray(dashboardParams.group_id)) {
                    //route = `/groups/0/${dashboardParams.group_id[0]}/alarms`
                    let groupId = [dashboardParams.group_id],
                      groupName = [];
                    let filters = null;
                    if (this.entity && this.entity.name) {
                      groupName = [this.entity.name];
                      if (this.entity.groups && this.entity.groups.length) {
                        this.entity.groups
                          .filter(function (group) {
                            return group.type !== "CONTAINER";
                          })
                          .forEach(function (group) {
                            let totalalarm =
                              group.alarmCountSummary.critical +
                              group.alarmCountSummary.major +
                              group.alarmCountSummary.minor +
                              group.alarmCountSummary.warning +
                              group.alarmCountSummary.information;
                            if (totalalarm) {
                              groupId.push(group.id);
                              groupName.push(group.name);
                            }
                          });
                        this.entity.groups
                          .filter(function (group) {
                            return group.type === "CONTAINER";
                          })
                          .forEach(function (group) {
                            filters = getContainerGroupFilters(
                              group,
                              groupId,
                              groupName
                            );
                          });
                      }
                    }
                    filters = filters
                      ? filters
                      : `groupId=${groupId.toString()}&group=${groupName.toString()}`;
                    route = getAlarmUrl(this.doiurl, filters);
                  } else if (Array.isArray(dashboardParams.prid)) {
                    //route = `/technologies/${dashboardParams.prid[0]}/alarms`

                    route = getAlarmUrl(
                      this.doiurl,
                      "probe=" + dashboardParams.prid[0]
                    );
                  }

                  if (Array.isArray(dashboardParams.alarm_severity)) {
                    if (dashboardParams.alarm_severity[0] === "1")
                      route = `${route}&severity=information`;
                    else if (dashboardParams.alarm_severity[0] === "2")
                      route = `${route}&severity=warning`;
                    else if (dashboardParams.alarm_severity[0] === "3")
                      route = `${route}&severity=minor`;
                    else if (dashboardParams.alarm_severity[0] === "4")
                      route = `${route}&severity=major`;
                    else if (dashboardParams.alarm_severity[0] === "5")
                      route = `${route}&severity=critical`;
                  }

                  //dashboardState.navOverride(route)
                  window.location.href = route;
                  return true;
                } else {
                  let route = "/uim-alarms";

                  if (Array.isArray(dashboardParams.cs_id)) {
                    route = `/computer-systems/${dashboardParams.cs_id[0]}/alarms`;
                  } else if (Array.isArray(dashboardParams.group_id)) {
                    route = `/groups/0/${dashboardParams.group_id[0]}/alarms`;
                  } else if (Array.isArray(dashboardParams.prid)) {
                    route = `/technologies/${dashboardParams.prid[0]}/alarms`;
                  }

                  if (Array.isArray(dashboardParams.alarm_severity)) {
                    route = `${route}?severity=${dashboardParams.alarm_severity[0]}`;
                  }

                  dashboardState.navOverride(route);
                  return true;
                }
              }

              // if an operator console route mapping exists for the provided dashboard path
              if (dashToRouteMap[dashboardPath]) {
                let routeEntry = dashToRouteMap[dashboardPath];
                // use custom idValue for routes that require more context beyond id, otherwise use provided link id
                let id = routeEntry.idValue
                  ? routeEntry.idValue
                  : Array.isArray(dashboardParams[routeEntry.cabiId])
                  ? dashboardParams[routeEntry.cabiId][0]
                  : "";
                let urlParamsString = "";

                // build url params for additional context params
                if (
                  Array.isArray(routeEntry.contextParams) &&
                  routeEntry.contextParams.length > 0
                ) {
                  urlParamsString = "?";

                  routeEntry.contextParams.forEach((element) => {
                    if (Array.isArray(dashboardParams[element])) {
                      let key = encodeURI(element);
                      let value = encodeURI(dashboardParams[element][0]);
                      urlParamsString = `${urlParamsString}${key}=${value}&`;
                    }
                  });

                  urlParamsString = urlParamsString.substr(
                    0,
                    urlParamsString.length - 1
                  ); // remove trailing '&' or unused '?'
                }

                let route =
                  routeEntry.route.replace(`[${routeEntry.opConId}]`, id) +
                  urlParamsString;
                dashboardState.navOverride(route);
                return true;
              }
              /*else
              {
                if(dashboardParams.cs_id)
                {
                  if (Array.isArray(dashboardParams.cs_id)) {                
                  let customDrilldownParams = LocalStorageManager.getItem(
                    "DASHBOARDDRILLDOWNPARAMS",
                    "dashboardParams"
                  );
                  dashboardParams.cs_id=dashboardParams.cs_id[0];

                  }
                }else if(dashboardParams.alarm_state)
                {
                  if (Array.isArray(dashboardParams.alarm_state)) {                
                  let customDrilldownParams = LocalStorageManager.getItem(
                    "DASHBOARDDRILLDOWNPARAMS",
                    "dashboardParams"
                  );
                  dashboardParams.cs_id=dashboardParams.alarm_state[0];

                  }
                }else if(dashboardParams.minimum_alarm_level)
                {
                  if (Array.isArray(dashboardParams.minimum_alarm_level)) {                
                  let customDrilldownParams = LocalStorageManager.getItem(
                    "DASHBOARDDRILLDOWNPARAMS",
                    "dashboardParams"
                  );
                  dashboardParams.cs_id=dashboardParams.minimum_alarm_level[0];

                  }
                }else if(dashboardParams.top_n)
                {
                  if (Array.isArray(dashboardParams.top_n)) {                
                  let customDrilldownParams = LocalStorageManager.getItem(
                    "DASHBOARDDRILLDOWNPARAMS",
                    "dashboardParams"
                  );
                  dashboardParams.cs_id=dashboardParams.top_n[0];

                  }
                }
              }*/
            

              return false;
            },
          });
        });
      },
      (err) => {
        console.error("Failed to load dashboard");
      }
    );
  }
  load = (
    url = "/cabijs/client/visualize.js",
    windowVariable = "visualize"
  ) => {
    let promise = new Promise((resolve, reject) => {
      this._initialize(
        url,
        windowVariable,
        (windowVariable) => {
          resolve(windowVariable);
        },
        (e) => {
          console.error("failed to load:", url);
          reject(e);
        }
      );
    });
    return promise;
  };
}

export default Visualize;
