import React, {Component} from 'react'
import axios from 'axios'
import {
  Dialog,
  Button as FlatButton,
} from '@mineral/core'
import config from './../../../api/config'
import Resource from './Resource'

const FailedImportDialog = (props) => {
  const actions = [
    <FlatButton
    variant="text"
    //ok message
      children= {Resource.get('OK')}
     color="primary"
      onClick={props.onClose}
    />,
  ]
  return (
    <Dialog
      actions={actions}
      modal={false}
      open={props.open}
      onClose={props.onClose}>
      <p>
        There was a problem importing <var>{props.file}</var>. Please review the contents of the file.
      </p>
    </Dialog>
  )
}

class ImportCsv extends Component {
  state = {
    status: false,
    file: null,
  }
  handleFile = (evt) => {
    const file = evt.target.files[0]
    if (file) {
      this.upload(file)
    }
  }
  upload = (file) => {
    let formData = new FormData()
    formData.append('file', file)
    axios.post(`${config.basename}/api/v1/robots`, formData)
      .then(response => {
        console.info('uploaded csv file')
        response.data._items = response.data._items
          .map(robot => {
            return Object.assign({}, robot, {
              hostname: robot.hostname || robot.robotIp
            })
          })
        this.props.onUploadSuccess(response)
      })
      .catch(error => {
        console.error('error uploading csv file', error)
        this.setState({
          status: true,
          file: file,
        })
      })
  }
  render() {
    const file = (this.state.file || {}).name
    // Import CSV
    const IMPORT_CSV = Resource.get("Import CSV")
    return (
      <FlatButton style={{marginRight:'4px'}}
        disabled={this.props.disabled }        
        //style={{ color: "#3272D9"}}
        aria-label="Import CSV"
       role="group" component="div"
        >
          {this.props.label}
        <input
          type='file'
          accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
          onChange={this.handleFile}
          disabled={this.props.disabled}
          style={{
            cursor: 'pointer',
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            width: '100%',
            opacity: 0,
          }} 
          tabIndex="-1"
          aria-label={IMPORT_CSV}
          />
          <FailedImportDialog
            open={this.state.status}
            file={file}
            onClose={() => this.setState({status: false})}/>
      </FlatButton>
    )
  }
}

ImportCsv.defaultProps = {
 //Import CSV
  label: Resource.get('Import CSV'),
  disabled: false,
}

export default ImportCsv
