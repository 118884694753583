// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //settings
  content["Settings"] =
  "Configurações";

  //alarm policies
  content["Alarm Policies"] =
  "Diretivas de alarme";

  // END OF LOCALIZATION

export default content;
