import { sortByName } from './sortByName';

export const sortByChecked = (arr, sort) => {
  if (!arr.length) return;
  
  const cb = (a, b) => (sort ? sortByName(a, b) : sortByName(b, a));

  const selectedItems = arr.filter((el) => el.checked);
  const unselectedItems = arr.filter((el) => !el.checked).sort(cb);

  return [...selectedItems, ...unselectedItems];
};
