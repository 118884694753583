// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Highest Alarm Severity Level and Total Alarm Count
  content["Highest Alarm Severity Level and Total Alarm Count"] =
  "最高警报重要级别和警报总数";

  //Max Alarm Severity
  content["Max Alarm Severity"] =
  "最大警报重要级别";

  //Name
  content["Name"] =
  "名称";

  //Elements
  content["Elements"] =
  "元素";

  //Device Type
  content["Device Type"] =
  "设备类型";

  //Operating system
  content["Operating system"] =
  "操作系统";

  //IP address
  content["IP address"] =
  "IP 地址";

  //Monitored by
  content["Monitored by"] =
  "监控者";

  //Has Robot
  content["Has Robot"] =
  "有 Robot";

  //Discovered
  content["Discovered"] =
  "已发现";

  // END OF LOCALIZATION

export default content;
