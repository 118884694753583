// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Add
  content["Add"] =
  "Agregar";

  //Setup complete
  content["Setup complete"] =
  "Instalación completada";

  //slack
  content["slack"] =
  "Slack";

  //Push alarms to Slack
  content["Push alarms to Slack"] =
  "Publicar alarmas en Slack";

  // END OF LOCALIZATION

export default content;
