// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //slm
  content["SLM"] =
  "SLM";

  // END OF LOCALIZATION

export default content;
