import config from './../../config'
import axios from 'axios'

class Agents {
  get() {
    if(process.env.NODE_ENV=='development'){
      return axios.get(`${config.mockBaseName}/app-discovery/agents`)
    }
    return axios.get(`${config.basename}/api/v1/app-discovery/agents`)
  }
  getStatus(){
    if(process.env.NODE_ENV=='development'){
      return axios.get(`${config.mockBaseName}/app-discovery/status`)
    }
    return axios.get(`${config.basename}/api/v1/app-discovery/status`)
  }
  getRunningJobs(){
    if(process.env.NODE_ENV=='development'){
      return axios.get(`${config.mockBaseName}/app-discovery/jobs/count`)
    }
    return axios.get(`${config.basename}/api/v1/app-discovery/jobs/count`)
  }
}

let agents = new Agents()
export default agents