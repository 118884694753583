import React from 'react'
import PropTypes from 'prop-types'
import CrossLaunch from '../crossLaunch/CrossLaunch'

const ManageGroups = ({sid}) =>  {
  let url = `/usm/jsp/standalone.jsp?persistLogin=${sid}&groupTabs=details&memberTabs=details`
  return(
    <CrossLaunch src={url} sid={sid}/>
  )
}
ManageGroups.propTypes = {
  sid: PropTypes.string.isRequired
};
export default ManageGroups
