import React, {Component} from 'react'
import {ErrorIcon as InfoIcon} from '../../ui-components/uim-components'
import {
  Button as FlatButton,
  IconButton,
  Popover,
} from "@mineral/core"
import './csvTemplate.less'
import Resource from './Resource'
import axios from "axios";
import { Tooltip } from '@mineral/core'
import {Cross as CloseIcon } from "@mineral/icons"

class ImportCsv extends Component {
  state = {
    open: false,
  }
  handleTouchTap = (event) => {
    // This prevents ghost click.
    event.preventDefault()
    this.setState({
      open: true,
      anchorEl: event.currentTarget,
    })
  }
  handleRequestClose = () => {
    this.setState({open: false})
  }
  handleFetchExampleCSVAndRequestClose = () => {
    axios
      .get("/operatorconsole_portlet/api/v1/downloads/robot-deploy-config.csv"        
      )
      .then((res) => {
        var file = new Blob([res.data], {
          type: "text/csv",
        });
        //Handle in IE using msSaveOrOpenBlob
        if (typeof window.navigator.msSaveOrOpenBlob === "function") {
          window.navigator.msSaveOrOpenBlob(file, "robot-deploy-config.csv");
        } else {
          let anchor = document.createElement("a");
          anchor.href = URL.createObjectURL(file);
          anchor.download = "robot-deploy-config.csv";
          document.body.appendChild(anchor);
          anchor.click();
          document.body.removeChild(anchor);
        }
      }
        )
      .catch(function (response) {
        //handle error
        console.log(response);
      });
    this.setState({open: false})
  }
  render() {
    return (
      <div style={{
          position: 'relative',
          width:'50px'
        }}>
          <Tooltip title={this.props.label}>
        <IconButton
          onClick={this.handleTouchTap}
          className="importCSV__button"
          >
          <InfoIcon className='importCSV__icon' />
        </IconButton>
        </Tooltip>
        <Popover
          className='csv-popover'
          open={this.state.open}
          anchorEl={this.state.anchorEl}
          onClose={this.handleRequestClose}
          style={{
            overflowY: 'visibile',
          }}>
          <div className='popover__triangle'></div>
          <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: '2px', marginRight: '2px'}}>
            <IconButton onClick={this.handleRequestClose} aria-label="Close Dialog">
              <CloseIcon />
            </IconButton>
          </div>
          <section
            className='csv-info'>
            <header className='csv-info__header'>{//Import CSV
            	Resource.get('Import CSV')}</header>
            	{// creation help message
            Resource.get('To add devices in bulk, create a CSV file with the following information for each robot:')}

            <ul className='csv-info__list'>
              <li className='csv-info__list-item'>
                <section>
                  <header className='csv-info__header'>{//IP Address
                  Resource.get('IP Address')}</header>
                  {// IP4 help message
                  Resource.get('IPv4 address of system where robot/monitoring agent is to be installed')}
                </section>
              </li>
              <li className='csv-info__list-item'>
                <section>
                  <header className='csv-info__header'>{//Operating System
                	Resource.get('Operating System')}</header>
                	{// Values help message
                  Resource.get('Values: centos, debian, opensuse, rhel, ubuntu or windows')}
                </section>
              </li>
              <li className='csv-info__list-item'>
                <section>
                  <header className='csv-info__header'>{//Architecture
                	 Resource.get('Architecture') }</header>
                	 {// Architecture help message
                 Resource.get('Values: 32 or 64')}
                </section>
              </li>
              <li className='csv-info__list-item'>
                <section>
                  <header className='csv-info__header'>{//Username
                	Resource.get('Username')  }</header>
                	{// Username help message
                  Resource.get('Enter username with installation permissions for system where robot is to be installed')}
                </section>
              </li>
              <li className='csv-info__list-item'>
                <section>
                  <header className='csv-info__header'>{//Password
                	 Resource.get('Password') }</header>
                	 {// Password help message
                  Resource.get('Password for username on the robot system')}
                </section>
              </li>
              <li className='csv-info__list-item'>
                <section>
                  <header className='csv-info__header'>{//Sudo password
                	Resource.get('Sudo password')  }</header>
                	{// Sudo password help message
                  Resource.get('If username does not have root-level installation permissions, provide sudo password to elevate permissions to root-level for installation')}
                </section>
              </li>
            </ul>
            <span className='csv-info__list-item'>{// Note message 
            	Resource.get('Note: Up to 50 devices can be imported from CSV file.')}</span>
            
          </section>
          <FlatButton
           variant="text"
            download='robot-deploy-config.csv'
           children={this.props.label}
            onClick={this.handleFetchExampleCSVAndRequestClose}
            primary={true}
            style={{color: "rgb(0, 174, 239)"}}
            title={this.props.label} />
        </Popover>
      </div>
    )
  }
}

ImportCsv.defaultProps = {
  //Example CSV file
  label: Resource.get('Example CSV file'),
}

export default ImportCsv

