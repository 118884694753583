import React from 'react'
import IntegrationCard from '../integrations/IntegrationCard'
import ComputerSystems from '../../../api/computer-systems/computerSystems'
import ArrowIcon from './../ArrowIcon'
import Resource from './Resource'
import {Tooltip} from '@mineral/core';

export default class DeletedDevicesCard extends IntegrationCard {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    ComputerSystems.getDeletedDevices()
      .then(response => {
        this.setState({ deletedDeviceCount: response.data.total })
      })
      .catch(error =>{
        // console.log(error)
         })
  }

  getIcon() {
    return <ArrowIcon />
  }

  getSubLabelTwo = () => {
    if (this.state.deletedDeviceCount >= 0) {
      return this.state.deletedDeviceCount + ' devices'
    } else {
      return ''
    }
  }
}

const TextLogo = ({ label }) => {
  return (<Tooltip title={label}><span className="settings-text-logo">{label}</span></Tooltip>)
}


DeletedDevicesCard.defaultProps = {
  logo: <TextLogo
  //Deleted devices
  label={Resource.get("Deleted devices")} />,
		//Available for restore
  subLabelOne:  Resource.get("Available for restore"),
  subLabelTwo: '<>',
  configRoute: '/settings/infrastructure/deleted-devices',
}
