import React, { Component } from "react";
import Metrics from "./../dashboardContainer/panels/Metrics";
import Resource from "../inventory/Resource";
import {
  Card,  
  CardContent,  
  Menu,
  IconButton,Button,
  TextField,FormControl,Popover,Typography,InputLabel,Tooltip
} from "@mineral/core";
import "./interfaces.less";
import interfaceApi from "./../../api/interfaces/interfaces";
import isEqual from "lodash/isEqual";
import EditIcon from '@material-ui/icons/Edit';
import {CircularProgress} from '@mineral/core';
import { useLocation } from "react-router-dom";

import {ClearIcon as Clear, RemoveIcon,Error } from '../ui-components/uim-components'
import { useHistory } from "react-router-dom";
import CustomSnackbar from "../common/CustomSnackbar";

const Panel = (props) => {

  const params = useLocation();
  const navigate = useHistory();
  
  const getadminStatus = (adminStatusId) => {
    let adminStatus = '';
    if(adminStatusId === '0')
      adminStatus = 'Unknown';
    else if(adminStatusId === '1')
      adminStatus = 'Up';
    else if(adminStatusId === '2')
      adminStatus = 'Down';
    else if(adminStatusId === '3')
      adminStatus = 'Testing';
    
    return adminStatus;
  }

  const getOperStatus = (operStatusId) => {
    let operStatus = '';          
    if(operStatusId === '1')
      operStatus = 'Up';
    else if(operStatusId === '2')
      operStatus = 'Down';
    else if(operStatusId === '3')
      operStatus = 'Testing';
    else if(operStatusId === '4')
      operStatus = 'Unknown';
    else if(operStatusId === '5')
      operStatus = 'Dormant';
    else if(operStatusId === '6')
      operStatus = 'Not Present';
    else if(operStatusId === '7')
      operStatus = 'Lower Layer Down';

    return operStatus;

  }

  const speedDataCellRenderer = (value) => {
    const KBPS = 1000;
    const MBPS = KBPS * 1000;
    const GBPS = MBPS * 1000;
    if (value == null || isNaN(value) || value === 0) {
      return null;
    }

    if (value >= GBPS) {
      value = value / GBPS;
      //field value of interface speed -- {0}: speed in gigabits per second
      return <span>{parseFloat(value).toFixed(2)} Gbps</span>;
    } else if (value >= MBPS) {
      value = value / MBPS;
      //field value of interface speed -- {0}: speed in megabits per second
      return <span>{parseFloat(value).toFixed(2)} Mbps</span>;
    } else if (value >= KBPS) {
      value = value / KBPS;
      //field value of interface speed -- {0}: speed in kilobits per second
      return <span>{parseFloat(value).toFixed(2)} Kbps</span>;
    } else {
      //field value of interface speed -- {0}: speed in bits per second
      return <span>{parseFloat(value).toFixed(2)} bps</span>;
    }
  };
  const history = useHistory()
  
  return (
    <CardContent
      style={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#f4f4f4",
        padding: "10px",
        color: "#5B676C",
        overflow: "hidden auto",
        height: "37vh",
      }}
    >
      <table>
        <tr>
          <td>
            <div style={{ padding: "2px 0px", width: '100px' }}>Name:</div>
          </td>
          <td>
            <div style={{ padding: "2px 0px" }}>
              <b> {props.interfaceData.displayName}</b>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div style={{ padding: "2px 0px", width: '100px' }}>Alias:</div>
          </td>
          <td style={{width: "100%"}}>
          <Tooltip title={props.aliasName}>
      <div className="alias_div_txt">
        {props.applyBtnClicked ? <CircularProgress style={{justifySelf: "center"}} size={20}/> : props.aliasName}
      </div>
      </Tooltip>
      <div className="alias_edit_icon_div">
             
             {props.canEditDevice 
                 &&  <Tooltip title={"Edit Alias"}><IconButton 
                  style={{height:'0px',width:'0px'}} title="Edit Alias" 
                   aria-controls="aliasMenu"  aria-haspopup="true" 
                   onClick={(e)=>{
                     props.handleMenuClick(e);                       
                     }}>
                <EditIcon style={{ color: "#3272D9" }} />
              </IconButton>
              </Tooltip>
             } 
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div style={{ padding: "2px 0px", width: '100px' }}>IP Address: </div>
          </td>
          <td>
            <div style={{ padding: "2px 0px" }}>
              {" "}

              {props.interfaceData.ipAddress ? props.interfaceData.ipAddress[0]: 
                props.interfaceData.otherIPAddress ? props.interfaceData.otherIPAddress: ""}
            </div>
          </td>
        </tr>        
         <tr>
          <td>
            <div style={{ padding: "2px 0px" }}>Physical Address: </div>
          </td>
          <td>
            <div style={{ padding: "2px 0px" }}>
              {props.interfaceData.ifPhysAddress ? props.interfaceData.ifPhysAddress : ""}
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div style={{ padding: "2px 0px" }}>Device: </div>
          </td>
          <td>
            {/* <div style={{ padding: "2px 0px" }}>
              {props.interfaceData?.parent_displayName}</div> */}
            <a 
              className={!params.pathname.includes('groups') && 'interface-device'}
              onClick={(e)=>(e.preventDefault(), !params.pathname.includes('groups') && navigate.push(params.pathname.replace('interfaces','dashboard')))}>
              {props.interfaceData?.parent_displayName}
            </a>
          </td>
        </tr>
        <tr>
          <td>
            <div style={{ padding: "2px 0px" }}>IfAlias: </div>
          </td>
          <td>
            <div style={{ padding: "2px 0px" }}>
              {props.interfaceData.ifAlias ? props.interfaceData.ifAlias : ""}</div>
          </td>
        </tr>
        <tr>
          <td>
            <div style={{ padding: "2px 0px" }}>IfName:</div>
          </td>
          <td>
            <div style={{ padding: "2px 0px" }}>
              {props.interfaceData.ifName ? props.interfaceData.ifName : ""}</div>
          </td>
        </tr>
        <tr>
          <td>
            <div style={{ padding: "2px 0px" }}>Description:</div>
          </td>
          <td>
            <div style={{ padding: "2px 0px" }}>
              {props.interfaceData.ifDescr ? props.interfaceData.ifDescr : ""}</div>
          </td>
        </tr>
        <tr>
          <td>
            <div style={{ padding: "2px 0px" }}>Nominal Speed:</div>
          </td>
          <td>
            <div style={{ padding: "2px 0px" }}>
              {props.interfaceData.ifSpeed ? speedDataCellRenderer(props.interfaceData.ifSpeed) : ""}</div>
          </td>
        </tr>
        <tr>
          <td>
            <div style={{ padding: "2px 0px" }}>Index: </div>
          </td>
          <td>
            <div style={{ padding: "2px 0px" }}>{props.interfaceData?.ifIndex}</div>
          </td>
        </tr>
        <tr>
          <td>
            <div style={{ padding: "2px 0px" }}>MAC Address: </div>
          </td>
          <td>
            <div style={{ padding: "2px 0px" }}>{props.interfaceData?.ifPhysAddress}</div>
          </td>
        </tr>
        <tr>
          <td>
            <div style={{ padding: "2px 0px" }}>Admin Status: </div>
          </td>
          <td>
            <div style={{ padding: "2px 0px" }}>
              {props.interfaceData.ifAdminStatus ? getadminStatus(props.interfaceData.ifAdminStatus) : ""}</div>
          </td>
        </tr>
        <tr>
          <td>
            <div style={{ padding: "2px 0px" }}>Oper Status: </div>
          </td>
          <td>
            <div style={{ padding: "2px 0px" }}>
              {props.interfaceData.ifOperStatus ? getOperStatus(props.interfaceData.ifOperStatus) : ""}</div>
          </td>
        </tr>
        <tr>
          <td>
            <div style={{ padding: "2px 0px" }}>Type: </div>
          </td>
          <td>
            <div style={{ padding: "2px 0px" }}>
              {props.interfaceData.ifType == '0'?'Unknown':props.interfaceData.ifType}</div>
          </td>
        </tr>
        <tr>
          <td>
            <div style={{ padding: "2px 0px" }}>Origin: </div>
          </td>
          <td>
            <div style={{ padding: "2px 0px" }}>
              {props.interfaceData.origin ? props.interfaceData.origin : props.interfaceData.origins ? 
              props.interfaceData.origins.origin : ""}</div>
          </td>
        </tr>

      </table>
      <hr />      
    </CardContent>
  );
};

class InterfaceDashboard extends Component {
  constructor(props) {
    super(props);
    this.state={
      aliasName: this.props.data.displayAlias,
      openAliasMenu: false,
      masterId: this.props.data.masterId? this.props.data.masterId: this.props.data.id,
      toBeChangeAlias: this.props.data.displayAlias ,
      menuAnchorEl: null
    }
  }
  componentWillReceiveProps(nextProps) {
    if (!isEqual(this.props.data, nextProps.data)) {
      this.setState({
        aliasName: this.getInterfaceAlias(nextProps.data.masterId? nextProps.data.masterId: nextProps.data.id),
        openAliasMenu: false,
        masterId: nextProps.data.masterId ? nextProps.data.masterId: nextProps.data.id,
        toBeChangeAlias: nextProps.data.displayAlias 
      })
    }

  }
  getInterfaceAlias=(masterId)=>{
    interfaceApi.getInterfaceAlias(masterId).then((response)=>{
        let data = response.data;
        this.setState({aliasName: data});
    })
  }

  onChangeAlias = (event) => {
    this.setState({ toBeChangeAlias: event.target.value });
  };
 /*  showAliasMenu = () => {
    this.setState({ openAliasMenu: !this.state.openAliasMenu });
  }; */
  cancelShowAliasMenu = () =>{
    this.setState({ 
      menuAnchorEl: null,
      toBeChangeAlias: this.state.aliasName,
     });
  }
  updateAliasName = () => {   
     interfaceApi.updateAliasInterface(this.state.toBeChangeAlias, this.state.masterId)
      .then((response) =>{       
       if(response.status && response.status == 200){
        this.setState({       
      	
          aliasName: this.state.toBeChangeAlias
         });
       }
      });
      this.setState({ menuAnchorEl: null }); 
      this.props.handleApplyBtnClicked()
  };
   handleMenuClick = (event) => {
    this.setState({menuAnchorEl: event.currentTarget});
  };
  handleActionMenuClose = () => {
    this.setState({menuAnchorEl: null});
  };
  render() {
    //Metrics Monitoring
    let title = Resource.get("Metrics Monitoring");    
    let metricCardHeader = (
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span className="dashboard_container_title">{title}</span>
        </div>
      </div>
    );
    let interfaceCardHeader = (
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span className="dashboard_container_title">Interface Details</span>
        </div>
      </div>
    );
    return (
      <div className="dashboard dashboard__main" style={{ marginLeft: "0px" ,height:'100%'}}>
        <section className="interface-dashboard-cards" style={{marginTop: "24px",height:'100%'}}>
          <div
            className="dashboard_row_container"
            style={{
              display: "flex",
              gridGap: "16px",
              flex: "1",
              flexDirection: "column",
              minWidth:
                this.props.fromTreeView && this.props.isTreeOpen
                  ? "26vw"
                  : "32vw",
            }}
          >
            <div
              className="dashboard_container2 dashboard__row-item"
              style={{ minHeight: "47vh", maxHeight: "47vh" }}
            >
              <CustomSnackbar
                severity="info"
                autoHideDuration={6000}
                message="Updating Alias name"
                open={this.props.applyBtnClicked}
                close={this.props.handleCloseSnackbar}
              />
              <CustomSnackbar
                severity="success"
                autoHideDuration={6000}
                message="Successfully updated alias"
                open={this.props.openSnackbar}
                close={this.props.handleCloseSnackbar}
              />
              <Card                
                style={{
                  height: null,
                  width: null,
                  margin: null,
                }}
                containerStyle={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  padding: "16px",
                }}>
      
                {interfaceCardHeader}
               <Panel                  
                  interfaceData= {this.props.data }
                  aliasName= {this.state.aliasName}
                  onChangeAlias= {this.onChangeAlias}
                  updateAliasName= {this.updateAliasName}
                  //showAliasMenu= {this.showAliasMenu} 
                  cancelShowAliasMenu = {this.cancelShowAliasMenu}
      		        handleMenuClick={this.handleMenuClick}
                  canEditDevice = {this.props.canEditDevice}
                  applyBtnClicked={this.props.applyBtnClicked}
                  {...this.state}               
                />
               
                
              <Popover
                               open={this.state.menuAnchorEl!=null}
                               anchorEl={this.state.menuAnchorEl}
                              anchorOrigin={{
                                vertical: "center",
                                horizontal: "center",
                              }}
                              transformOrigin={{
                                vertical: "center",
                                horizontal: "center",
                              }}
                              onClose={this.cancelShowAliasMenu}
                             
                            >
                            <div style={{height:'170px',width:'400px',margin:'16px'}}>
                            <div style={{width:'100%',display:'flex',flexDirection:'row'}}>
                            <Typography component="h1" variant="h4">Edit Alias</Typography>
                            <div style={{marginLeft:'auto'}}>
                            <Tooltip title={"Close"} > 
        <IconButton autoFocus style={{top:'-5px'}} onClick={this.cancelShowAliasMenu}>
          <Clear role='figure' />
        </IconButton>
         </Tooltip> 
         </div>
                            </div>
                              <FormControl fullWidth style={{marginTop:'8px'}}>
                              <InputLabel>Alias</InputLabel>
                <TextField inputProps={{'aria-label':'Alias'}}
                title={this.state.aliasName}
                classes={{root:"alias-text"}}
                
                onChange={this.onChangeAlias}
                  value={this.state.toBeChangeAlias}
                  autoFocus={true}
                  fullWidth={true}
                />
                </FormControl>
                <FormControl style={{float:'right',marginTop:'16px',display:'flex',flexDirection:'row'}}>
                <Button 
                variant="text" style={{marginRight:'8px',}}
                tabIndex={0}
                onClick={this.cancelShowAliasMenu}> Cancel</Button>  
                 
                    <Button 
                    variant="contained"
                tabIndex={0}
                onClick={this.updateAliasName}
                // style={{ display: "block" }}
                >
                Apply
                </Button> 
                </FormControl>    
                </div>
                            </Popover>
                
               
                  
              </Card>
            </div>
            
          </div>

          <div
            className="dashboard_container3 dashboard_row_container"
            style={{
              minWidth:
                this.props.fromTreeView && this.props.isTreeOpen
                  ? "46vw"
                  : "60vw",
              minHeight: "75vh",
            }}
          >
            {metricCardHeader}
             <div
              style={{
                display: "flex",
                gridGap: "16px",
                background: "white",
                overflow: "auto",
                height: "69vh",
              }}
            >
              <Metrics masterId={this.state.masterId}
                 csId={this.props.data.parent_instanceId!==null && this.props.data.parent_instanceId!==undefined?this.props.data.parent_instanceId:this.props.data.csId}
                 isInterface={true}
                 isTreeOpen={this.props.isTreeOpen}
                 ciName={this.props.data.displayName}
              />
            </div> 
          </div>
        </section>
      </div>
    );
  }
}
export default InterfaceDashboard
