// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Extension message
  content["Extension requested"] =
  "Ampliación solicitada";

  //Request message
  content["Request extension"] =
  "Solicitar ampliación";

  //Expired
  content["Expired"] =
  "Caducado";

  //Trial
  content["Trial"] =
  "Prueba";

  //BUY
  content["BUY"] =
  "COMPRAR";

  // END OF LOCALIZATION

export default content;
