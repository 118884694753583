export const FETCH_GROUPSTACK = "FETCH_GROUPSTACK";
export const FETCH_GROUPSTACK_SUCCESS = "FETCH_GROUPSTACK_SUCCESS";
export const FETCH_GROUPSTACK_FAILURE = "FETCH_GROUPSTACK_FAILURE";
export const FETCH_STATE = "FETCH_STATE";
export const FETCH_STATE_SUCCESS = "FETCH_STATE_SUCCESS";
export const FETCH_STATE_FAILURE = "FETCH_STATE_FAILURE";
export const FETCH_COUNT_STATE = "FETCH_COUNT_STATE";
export const FETCH_COUNT_STATE_SUCCESS = "FETCH_COUNT_STATE_SUCCESS";
export const FETCH_COUNT_STATE_FAILURE = "FETCH_COUNT_STATE_FAILURE";
export const SET_TREE_NODE_SELECTED = "FETCH_TREE_NODE_SELECTED";
export const REFRESH_TREE = "REFRESH_TREE";
export const TOGGLE_ADD_GROUP_DIALOG = "TOGGLE_ADD_GROUP_DIALOG";
export const DELETE_GROUP_DIALOG = "DELETE_GROUP_DIALOG";
export const TOGGLE_ADD_DEVICE_TO_GROUP = "TOGGLE_ADD_DEVICE_TO_GROUP";
export const TOGGLE_EDIT_SUB_GROUP = "TOGGLE_EDIT_SUB_GROUP";