import React, {Component} from 'react'
import debounce from 'lodash/debounce'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import fill from 'lodash/fill'
import get from 'lodash/get'
import isIP from 'validator/lib/isIP'
import {
  ErrorIcon as Information,
} from '../ui-components/uim-components'
import {
  Dialog,
  Select as DropDownMenu,
  Button as FlatButton,
  IconButton,
  MenuItem,
  TextField,
} from '@mineral/core'
import {
  Tooltip
} from '@mineral/core'
import {
  AddIcon,
  CopyIcon,
  DeleteIcon,
  Grid,EditIcon
} from "../ui-components/uim-components"
import ImportCsv from './importCsv/ImportCsv'
import CsvTemplate from './importCsv/CsvTemplate'
import DeployErrorIcon from './deployErrorIcon/DeployErrorIcon'
import theme from './../../theme'
import './robotDeployList.less'
import Resource from './Resource';
import DoneIcon from '@material-ui/icons/Done';

const WINDOWS_ON_UNIX_ERROR = 'Windows Relay Hub Required'
const DEBIAN_32_BIT_ERROR =
  'Only 64 bit package is allowed for debian/aix based systems.'
const SOLARIS_ERROR =
  'Sparcv9 is allowed only for Solaris based systems.'
//Add Devices
const addDev = Resource.get('Add Devices');
//Windows devices are not supported on this monitoring technology
const WinMesg = Resource.get('Windows devices are not supported on this monitoring technology');
let tabForward,tabBackward;
function getAallFocussableElementsOf(el) {
  return Array.from(
    el.querySelectorAll(
      'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
    )
  ).filter((el) => {
    return el.tabIndex !== -1;
  });
}
class ErrorDialog extends Component {
  state = {
    open: this.props.open || false,
    error: false,
    successfulImport: false,
  }

  componentWillReceiveProps(nextProps) {
    const newError = nextProps.error !== this.state.error
    if (newError) {
      this.setState({
        open: !!nextProps.error,
        error: nextProps.error,
      })
    }
  }

  handleClose = () => {
    this.setState({open: false})
  }

  render() {
    //OK
    const OkLabel = Resource.get('OK');
    const actions = [
      <FlatButton variant="text" children={OkLabel} color="primary" onClick={this.handleClose}/>
    ]
    return (
      <Dialog
        actions={actions}
        modal={false}
        open={this.state.open}
        onClose={this.handleClose}>
        {this.props.message}
      </Dialog>
    )
  }
}

ErrorDialog.defaultProps = {
  //Windowds deploy help message
  message: Resource.get('A Windows relay hub must be selected to deploy a Windows device.')
}

class HostnameCellEditor extends Component {
  name = 'hostname'
  state = {
    value: this.props.value.value ? this.props.value.value : '',
    error: false,
  }
  considerCursorPos = false;
  

  componentDidMount() {
    this.considerCursorPos = true;
    setTimeout(() => {
      const el = this.props.eGridCell.querySelector('input');
      if (el) {
        el.addEventListener('keydown', this.keyDown, true);
      }
    });
  }

  componentWillUnmount() {
    this.considerCursorPos = false;
    const el = this.props.eGridCell.querySelector('input');
    if (el) {
      el.removeEventListener('keydown', this.keyDown, true);
    }
  }

  focusIn = () => {
    const el = this.props.eGridCell.querySelector('input')
    console.log("focusin of HostnameCellEditor")
    el?.focus();
    if (this.considerCursorPos && typeof this.props.value.cursorPosition === 'number') {
      el.setSelectionRange(this.props.value.cursorPosition, this.props.value.cursorPosition);
      this.considerCursorPos = false;
    }
  }
  getValue = () => {
    return this.state.value
  }
  keyDown = (evt) => {
    if (evt.keyCode === 37 || evt.keyCode === 39) {
      evt.stopPropagation();
      return true;
    }
  }
  handleChange = (evt, val) => {
    let value = evt.target.value;
    const error = this.validate(value)
    this.setState({value, error})
    if (this.props.column.colDef.onChange) {
      this.props.column.colDef.onChange(
        this.props.rowIndex,
        this.name,
        value,
        error,
        evt.target.selectionStart
      )
    }
  }

  render() {
    const error = this.validate(this.state.value)
    return (
      <TextField style={{marginLeft:'8px',marginTop:this.state.value && error !== false?'16px':'0px'}}
        fullWidth={true}
        name={this.name}
        placeholder="123.123.123.123"
        helperText={this.state.value && <span className='robot-installer__error-label'>{error}</span>}
        value={this.state.value}
        onChange={this.handleChange}
        variant="outlined"
      />
    )
  }

  duplicates = value => {
    const otherRobots = [...this.props.value.robots]
    otherRobots.splice(this.props.rowIndex, 1)
    const match = otherRobots.find(robot => robot.hostname === value)
    return match
  }
  validate = value => {
    let error = false
    if (!value || !isIP(value)) {
      //IP Address Required
      error = Resource.get('IP Address Required');
    }
    else if (this.duplicates(value)) {
      //No duplicate IPs
      error = Resource.get('No duplicate IPs');
    }
    else
    {
      error = false
    }
          return error;
  }
}

class HostnameCellRenderer extends Component {
  componentWillMount() {
    const colDef = this.props.colDef
    const value = get(this.props, 'value.value')
    const previousError =
      !value || get(this.props, 'value.fieldErrors.hostname')
    const error = this.validate(value)
    // we just care about the truthyness of this otherwise we'll loop
    const sameError = !!previousError === !!error
    if (colDef.onChangeFieldError && !sameError) {
      colDef.onChangeFieldError(this.props.rowIndex, colDef.field, error);
    }
  }

  duplicates = value => {
    const otherRobots = [...this.props.value.robots]
    otherRobots.splice(this.props.rowIndex, 1)
    const match = otherRobots.find(robot => robot.hostname === value)
    return match
  }
  validate = value => {
    let error = false
    if (!value || !isIP(value)) {
      //IP Address Required
      error = Resource.get('IP Address Required');
    }
    if (this.duplicates(value)) {
      //No duplicate IPs
      error = Resource.get('No duplicate IPs');
    }
    if(value && isIP(value) )
    {
      error = false
    }
    return error
  }

  render() {
    const value = get(this.props, 'value.value')
    if (!touchedRows[this.props.rowIndex] && !value) {
      return <TextField style={{marginLeft:'8px'}}
        fullWidth={true}
        name={this.name}
        type="text"
        disabled={true}
        //Hostname
        placeholder={Resource.get('Hostname')}
      />
    }
    //Required
    const ReqLabel = Resource.get('Required')
    const error = !value || get(this.props, 'value.fieldErrors.hostname')
    // <span className={`${error ? 'error' : ''}` + ' robot-list-cell-data'}>

    return (
      <span className={`${error ? 'error' : ''}` + ' robot-list-cell-data'}>
            {value ? value : ReqLabel}
      </span>
    )
  }
}

class UsernameCellEditor extends Component {
  name = 'username'
  state = {
    value: this.props.value.value,
    error: false,
  }
  considerCursorPos = false

  componentDidMount() {
    this.considerCursorPos = true;
    setTimeout(() => {
      const el = this.props.eGridCell.querySelector('input');
      if (el) {
        el.addEventListener('keydown', this.keyDown, true);
      }
    });
  }

  componentWillUnmount() {
    this.considerCursorPos = false;
    const el = this.props.eGridCell.querySelector('input');
    if (el) {
      el.removeEventListener('keydown', this.keyDown, true);
    }
  }

  focusIn = () => {
    const el = this.props.eGridCell.querySelector('input');
    console.log("focusin of UsernameCellEditor")
    el?.focus();
    if (this.considerCursorPos && typeof this.props.value.cursorPosition === 'number') {
      el.addEventListener('keydown', this.keyDown, true);
      el.setSelectionRange(this.props.value.cursorPosition, this.props.value.cursorPosition);
      this.considerCursorPos = false;
    }
  }
  getValue = () => {
    return this.state.value
  }
  handleChange = (event) => {
    let value = event.target.value;
    const idx = this.props.rowIndex
    const error = this.validate(value)
    this.setState({value, error})
    if (this.props.column.colDef.onChange) {
      this.props.column.colDef.onChange(idx, this.name, value, error, event.target.selectionStart);
    }
  }
  keyDown = (evt) => {
    if (evt.keyCode === 37 || evt.keyCode === 39) {
      evt.stopPropagation();
      return true;
    }
  }

  render() {
    return (
      <TextField style={{marginLeft:'8px',marginTop: this.state.error?'16px':'0px'}}
        fullWidth={true}
        name={this.name}
        //Username
        placeholder={Resource.get('Username')}
        helperText={  <span className='robot-installer__error-label'>{this.state.error}</span>}
        value={this.state.value}
        onChange={this.handleChange}
        autoComplete="username"
        variant="outlined"
      />
    )
  }

  validate = value => {
    //Username Required
    return value ? false : Resource.get('Username Required')
  }
}

class UsernameCellRenderer extends Component {
  render() {
    const value = get(this.props, 'value.value')

    if (!touchedRows[this.props.rowIndex] && !value) {
      return <TextField style={{marginLeft:'8px'}}
        fullWidth={true}
        name={this.name}
        type="text"       
        disabled={true}
         //Username
        placeholder={Resource.get('Username')}
      />
    }

    //Required
    const ReqLabel = Resource.get('Required')
    const error = !value || get(this.props, 'value.fieldErrors.username')
    return (
      <span className={`${error ? 'error' : ''}` + ' robot-list-cell-data'}>
               {value ? value : ReqLabel}
      </span>
    )
  }
}

class PasswordCellEditor extends Component {
  name = 'password'
  state = {
    value: this.props.value.value,
    error: false,
  }
  considerCursorPos = false;

  componentDidMount() {
    this.considerCursorPos = true;
    setTimeout(() => {
      const el = this.props.eGridCell.querySelector('input');
      if (el) {
        el.addEventListener('keydown', this.keyDown, true);
      }
    });
  }

  componentWillUnmount() {
    this.considerCursorPos = false;
    const el = this.props.eGridCell.querySelector('input');
    if (el) {
      el.removeEventListener('keydown', this.keyDown, true);
    }
  }

  focusIn = () => {
    const el = this.props.eGridCell.querySelector('input')
    el?.focus()
    console.log("focusin of Passwordcelleditor")
    if (this.considerCursorPos && typeof this.props.value.cursorPosition === 'number') {
      el.setSelectionRange(this.props.value.cursorPosition, this.props.value.cursorPosition);
      this.considerCursorPos = false;
    }
  }
  getValue = () => {
    return this.state.value
  }
  keyDown = (evt) => {
    if (evt.keyCode === 37 || evt.keyCode === 39) {
      evt.stopPropagation();
      return true;
    }
  }
  handleChange = (evt, val) => {
    let value = evt.target.value;
    const idx = this.props.rowIndex
    const error = this.validate(value)
    this.setState({value, error})
    if (this.props.column.colDef.onChange) {
      this.props.column.colDef.onChange(idx, this.name, value, error, evt.target.selectionStart);
    }
  }

  render() {
    return (
      <TextField style={{marginLeft:'8px',marginTop: this.state.error?'16px':'0px'}}
        fullWidth={true}
        name={this.name}
        type="password"
        //Password
        placeholder={Resource.get('Password')}
        helperText={  <span className='robot-installer__error-label'>{this.state.error}</span>}
        value={this.state.value}
        onChange={this.handleChange}
        autoComplete="new-password"
        variant="outlined"
        
      />
    )
  }

  validate = value => {
    //Password Required
    return value ? false : Resource.get('Password Required')
  }
}

class PasswordCellRenderer extends Component {
  render() {

    const value = get(this.props, 'value.value')
    if (!touchedRows[this.props.rowIndex] && !value) {
      return <TextField style={{marginLeft:'8px'}}
        fullWidth={true}
        name={this.name}
        type="password"
        disabled={true}
        //Password
        placeholder={Resource.get('Password')}
      />
    }
    //Required
    const ReqLabel = Resource.get('Required')
    const error = !value || get(this.props, 'value.fieldErrors.password')
    const blackCircles = fill(Array(8), '\u25CF').join('')
    return (
      <span className={`${error ? 'error' : ''}` + ' robot-list-cell-data'}>
                    {value ? blackCircles : ReqLabel}
                    </span>
    )
  }
}

class SudoPasswordCellEditor extends Component {
  name = 'sudoPassword'
  state = {
    value: this.props.value.value
  }
  considerCursorPos = false;

  componentDidMount() {
    this.considerCursorPos = true;
    setTimeout(() => {
      const el = this.props.eGridCell.querySelector('input');
      if (el) {
        el.addEventListener('keydown', this.keyDown, true);
      }
    });
  }

  componentWillUnmount() {
    this.considerCursorPos = false;
    const el = this.props.eGridCell.querySelector('input');
    if (el) {
      el.removeEventListener('keydown', this.keyDown, true);
    }
  }

  focusIn = () => {
    const el = this.props.eGridCell.querySelector('input')
    console.log("focusin of SudoPasswordCelleditor")
    el?.focus()
    if (this.considerCursorPos && typeof this.props.value.cursorPosition === 'number') {
      el.setSelectionRange(this.props.value.cursorPosition, this.props.value.cursorPosition);
      this.considerCursorPos = false;
    }
  }
  getValue = () => {
    return this.state.value
  }
  keyDown = (evt) => {
    if (evt.keyCode === 37 || evt.keyCode === 39) {
      evt.stopPropagation();
      return true;
    }
  }
  handleChange = (evt, val) => {
    let value = evt.target.value;
    const idx = this.props.rowIndex
    const error = this.validate(value)
    this.setState({value, error})
    if (this.props.column.colDef.onChange) {
      this.props.column.colDef.onChange(idx, this.name, value, error, evt.target.selectionStart);
    }
  }
  validate = value => {
    return false
  }

  render() {
    const value = this.state.value
    // sudo password is disabled if the username is `root`
    // or the os type is `windows`
    const rowData = get(this.props, 'node.data', {})
    const disabled =
      rowData.username === 'root' || rowData.profile === 'windows'
    //Sudo Password Required Message
    const disabledText = Resource.get('Sudo Password is only applicable for Linux systems where username is not \'root\'.')
    if (value && disabled && this.props.column.colDef.onChange) {
      // WARNING: anti-pattern - this will trigger a `setState` which should not be done within `render`
      // we check to ensure that the value is changeing so this only triggers 1 rerender to prevent
      // an infinite loop. Yay ag-grid!
      this.props.column.colDef.onChange(
        this.props.rowIndex,
        this.name,
        null,
        this.validate(value),
      )
    }
    return (
      <TextField style={{marginLeft:'8px'}}
        fullWidth={true}
        name={this.name}
        type="password"
        disabled={disabled}
        //Sudo password
        placeholder={Resource.get('Sudo password')}
        value={value}
        onChange={this.handleChange}
        variant="outlined"
      
      />
    )
  }
}
//NA password cell Editor
//NA passwordcell renderer
// class NonadminPasswordCellRenderer extends Component {
//   render() {
//     const blackCircles = fill(Array(8), '\u25CF').join('');
//     const value = get(this.props, 'value.value');

//     if (!touchedRows[this.props.rowIndex] && !value) {
//       return <TextField style={{marginLeft:'8px'}}
//         fullWidth={true}
//         name={this.name}
//         type="text"
//         disabled={true}
       
//         placeholder={Resource.get('Non Admin Password')}
//       />
//     }

//     return (
//       <span className={'robot-list-cell-data'}>
//                           {value ? blackCircles : value}
//                           </span>
//     )
//   }
// }

class SudoPasswordCellRenderer extends Component {
  render() {
    const blackCircles = fill(Array(8), '\u25CF').join('');
    const value = get(this.props, 'value.value');

    if (!touchedRows[this.props.rowIndex] && !value) {
      return <TextField style={{marginLeft:'8px'}}
        fullWidth={true}
        name={this.name}
        type="text"
        disabled={true}
        //Sudo password
        placeholder={Resource.get('Sudo password')}
      />
    }

    return (
      <span className={'robot-list-cell-data'}>
                          {value ? blackCircles : value}
                          </span>
    )
  }
}

class MenuCellEditor extends Component {
  state = {
    value: this.props.option
    //value: (this.props.option!=null && this.props.option!=undefined)?this.props.option:{...OS_OPTIONS[0], disabled:true}
  }

  componentDidMount() {
    const colDef = this.props.column.colDef
    const value = get(this.props, 'value.value')
    const previousError =
      !value || get(this.props, 'value.fieldErrors.' + this.props.name)
    const error = this.props.validate(value)
    // we just care about the truthyness of this otherwise we'll loop
    const sameError = !!previousError === !!error
    if (colDef.onChange && !sameError) {
      colDef.onChange(this.props.rowIndex, this.props.name, value, error)
    }
  }

  getValue = () => {
    return this.props.option
  }
  handleChange = (evt, index, val) => {
    let value = evt.target.value;
    const error = this.props.validate(value)
    this.setState({value})
    if (this.props.column.colDef.onChange) {
      this.props.column.colDef.onChange(
        this.props.rowIndex,
        this.props.name,
        value,
        error,
      )
    }
  }

  render() {
    const options = this.props.options || []
    var i;
    for (i = 0; i < options.length; i++) {
      if (options[i].value == "windows" && this.props.value.probe && this.props.value.probe.supportedOS == "unix") {
        options[i].disabledTitle = WinMesg
        break;
      }
    }
    const value = this.state.value || (options[0] && options[0].value)
    const option = options.find(option => option.value === value)
    const items = options.map((option, idx) => {
      return (
        <MenuItem
          value={option.value}
          children={option.name}
          disabled={!option.value || option.disabled}
          title={
            option.disabled && option.disabledTitle
              ? option.disabledTitle
              : null
          }
          key={idx}
        />
      )
    })


    return (
      <DropDownMenu role="combobox" style={{marginLeft:'8px'}}
       aria-label={this.props.name}
       inputProps={{'aria-label':this.props.name,
                                role: 'combobox',}}
                                MenuProps={{
            MenuListProps:{
            
              'aria-label' :this.props.name,
              title:this.props.name+"-ul"
             }}}
        tabIndex={0}
        variant="outlined"
        //underlineStyle={{ display: 'none', }}
        displayEmpty
       
        value={value}
        onChange={this.handleChange}>
        {items}
      </DropDownMenu>
    )
  }
}

const OS_OPTIONS = [
  {
    //OS
    name: Resource.get('OS'),
    value: null,
  },
  {
    //CentOS
    name: Resource.get('CentOS'),
    value: 'centos',
  },
  {
    //Debian
    name: Resource.get('Debian'),
    value: 'debian',
  },
  {
    //Open SUSE
    name: Resource.get('openSUSE'),
    value: 'opensuse',
  },
  {
    //RHEL
    name: Resource.get('RHEL'),
    value: 'rhel',
  },
  {
    //SUSE
    name: Resource.get('SUSE'),
    value: 'suse',
  },
  {
    //Ubuntu
    name: Resource.get('Ubuntu'),
    value: 'ubuntu',
  },
  {
    //solaris
    name: Resource.get('Solaris'),
    value: 'solaris',
  },
  {
    //aix
    name: Resource.get('AIX'),
    value: 'aix',
  },
  {
    //Windows
    name: Resource.get('Windows'),
    value: 'windows',
    //help message of deploying on linux hub
    disabledTitle: Resource.get('Windows can not be deployed on a UNIX hub')
  },
]

class OSCellEditor extends Component {
  name = 'profile'
  _windowsOnUnixHub = value => {
    const unixHub = get(this.props, 'value.hub.osMajor') === 'UNIX'
    return unixHub && value === 'windows'
  }
  _isOSDisabled = value => {
    let disabled = false;
    if (this.props.value.hub && this.props.value.probe.supportedOS) {
      if (this.props.value.hub.osMajor.toUpperCase() === 'WINDOWS') {
        if (this.props.value.probe.supportedOS.toUpperCase() === 'WINDOWS') {
          if (value !== 'windows') {
            disabled = true;
          }
        } else if (this.props.value.probe.supportedOS.toUpperCase() === 'UNIX' && value === 'windows') {
          disabled = true;
        }
      } else {
        if (value === 'windows') {
          disabled = true;
        } else if (this.props.value.probe.supportedOS.toUpperCase() === 'WINDOWS') {
          disabled = true;
        }
      }
    } else {
      disabled = true;
    }
    return disabled;
  }
  focusIn = () => {
    this.props.eGridCell?.focus()
    //console.log('haha ashwini',this.props.eGridCell.querySelectorAll('[role="button"]'))
    this.props.eGridCell.querySelectorAll('[role="button"]')?.[0]?.focus()
  }
  getValue = () => {
    return this.value
  }
  validate = value => {
    let error = false
    if (!value) {
      //Profile Required
      error = Resource.get('Profile Required')
    }
    if (this._windowsOnUnixHub(value)) {
      error = WINDOWS_ON_UNIX_ERROR
    }
    return error
  }

  render() {
    this.value = get(this.props, 'value.value', OS_OPTIONS[0].value)
    const options = OS_OPTIONS.map(option => {
      if (this.props.value.probe) {
        return Object.assign({}, option, {
          disabled: this._isOSDisabled(option.value)
        });
      }
      return Object.assign({}, option, {
        // disable windows when a UNIX hub is selected
        disabled: this._windowsOnUnixHub(option.value),
      })
    })

    let foobar = {
      name: this.name,
      options: options,
      option: this.value,
      validate: this.validate,
      ...this.props
    }

    return (
      <MenuCellEditor
        name={this.name}
        options={options}
        option={this.value} 
        validate={this.validate}
        {...this.props}
      />
    )
  }
}

class OSCellRenderer extends Component {
  _windowsOnUnixHub = value => {
    const unixHub = get(this.props, 'value.hub.osMajor') === 'UNIX'
    return unixHub && value === 'windows'
  }

  componentDidMount() {
    const colDef = this.props.colDef
    const value = get(this.props, 'value.value')
    const previousError = !value || get(this.props, 'value.fieldErrors.profile')
    const error = this.validate(value)
    // we just care about the truthyness of this otherwise we'll loop
    const sameError = !!previousError === !!error
    if (colDef.onChangeFieldError && !sameError) {
      colDef.onChangeFieldError(this.props.rowIndex, colDef.field, error);
    }
  }

  validate = value => {
    let error = false
    if (!value) {
      //Profile Required
      error = Resource.get('Profile Required')
    }
    if (this._windowsOnUnixHub(value)) {
      error = WINDOWS_ON_UNIX_ERROR
    }
    return error
  }

  render() {
    const value = this.props.value.value

    if (!touchedRows[this.props.rowIndex] && !value) {
      return <DropDownMenu
        classes={{
          root: "robot-deploy__select",
        }}
        role="combobox"
       aria-label={'OS at row '+this.props.rowIndex}
       inputProps={{'aria-label':this.props.name,
                                role: 'combobox',}}
                                MenuProps={{
            MenuListProps:{
            
              'aria-label' :this.props.name,
              title:this.props.name+" options list"
             }}}
        disabled={true}
        //underlineStyle={{ display: 'none', }}
        displayEmpty
        style={{
          fontFamily: null,
        }}
        value={'OS'}
        onChange={this.handleChange}>
        <MenuItem
          value={'OS'}
          children={'OS'}
          disabled={true}
          title={
            'OS'
          }
          key={'1'}
        />
      </DropDownMenu>
    }

    const error = this.validate(value)
    let option = OS_OPTIONS.find(option => value === option.value)
    option = option || OS_OPTIONS[0]
    //Required
    const ReqLabel = Resource.get('Required')
    return (
      <div className={`${error ? 'error' : ''}` + ' robot-list-cell-data'}>
        {option.value ? option.name : ReqLabel}
      </div>
    )
  }
}

const ARCH_OPTIONS = [
  {
    //ARCH
    name: Resource.get('ARCH'),
    vlaue: null,
  },
  {
    //64-bit
    name: Resource.get('64-bit'),
    value: '64',
  },
  {
    //32-bit
    name: Resource.get('32-bit'),
    value: '32',
    disabledTitle: DEBIAN_32_BIT_ERROR,
  },
  {
    //sparcv9/sparc
    name: Resource.get('sparcv9'),
    value: 'sparcv9',
    disabledTitle: SOLARIS_ERROR,
  },

]

class ArchCellEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profile: null
    }
  }

  name = 'arch'
  _debianSystem = value => {
    const profile = typeof this.state.profile === 'string' ? this.state.profile : get(this.props, 'value.profile')
    const debianBase = profile === 'debian' || profile === 'ubuntu' || profile === 'aix'
    return debianBase && value === '32'
  }
  _nonSolarisSystem = value => {
    const profile = typeof this.state.profile === 'string' ? this.state.profile : get(this.props, 'value.profile')
    const solarisBase = profile !== 'solaris'
    return solarisBase && value === 'sparcv9'
  }
  _solarisSystem = value => {
    const profile = typeof this.state.profile === 'string' ? this.state.profile : get(this.props, 'value.profile')
    const solarisBase = profile === 'solaris'
    return solarisBase && (value === '32' || value === '64')
  }
 
  focusIn = () => {
    this.props.eGridCell?.focus()
    //console.log('haha ashwini',this.props.eGridCell.querySelectorAll('[role="button"]'))
    this.props.eGridCell.querySelectorAll('[role="button"]')?.[0]?.focus()
  }
  getValue = () => {
    return this.value
  }
  updateInfo = (profile) => {
    this.setState({profile});
  }
  validate = value => {
    let error = false
    if (!value) {
      //Architecture Required
      error = Resource.get('Architecture Required')
    }
    if (this._debianSystem(value)) {
      error = DEBIAN_32_BIT_ERROR
    }
    if (this._nonSolarisSystem(value) || this._solarisSystem(value)) {
      error = SOLARIS_ERROR
    }
    return error
  }

  render() {
    this.value = get(this.props, 'value.value', ARCH_OPTIONS[0].value)
    const options = ARCH_OPTIONS.map(option => {
      return Object.assign({}, option, {
        // disable 32 bit when debian profile is selected
        disabled: this._debianSystem(option.value) || this._solarisSystem(option.value) || this._nonSolarisSystem(option.value),
      })
    })
    return (
      <MenuCellEditor
        name={this.name}
        options={options}
        option={this.value}
        validate={this.validate}
        {...this.props}
      />
    )
  }
}

class ArchCellRenderer extends Component {
  _debianSystem = value => {
    const profile = get(this.props, 'value.profile')
    const debianBase = profile === 'debian' || profile === 'ubuntu' || profile === 'aix'
    return debianBase && value === '32'
  }
  _nonSolarisSystem = value => {
    const profile = get(this.props, 'value.profile')
    const solarisBase = profile !== 'solaris'
    return solarisBase && value === 'sparcv9'
  }
  _solarisSystem = value => {
    const profile = get(this.props, 'value.profile')
    const solarisBase = profile === 'solaris'
    return solarisBase && (value === '32' || value === '64')
  }

  componentDidMount() {
    const colDef = this.props.colDef
    const value = get(this.props, 'value.value')
    const previousError = !value || get(this.props, 'value.fieldErrors.arch')
    const error = this.validate(value)
    // we just care about the truthyness of this otherwise we'll loop
    const sameError = !!previousError === !!error
    if (colDef.onChangeFieldError && !sameError) {
      colDef.onChangeFieldError(this.props.rowIndex, colDef.field, error);
    }
  }

  validate = value => {
    let error = false
    if (!value) {
      //Architecture Required
      error = Resource.get('Architecture Required')
    }
    if (this._debianSystem(value)) {
      error = DEBIAN_32_BIT_ERROR
    }
    if (this._nonSolarisSystem(value) || this._solarisSystem(value)) {
      error = SOLARIS_ERROR
    }
    return error
  }

  render() {
    const value = this.props.value.value
    if (!touchedRows[this.props.rowIndex] && !value) {
      return <DropDownMenu
        classes={{
          root: "robot-deploy__select",
        }}
        role="combobox"
       aria-label={'ARCH at row '+this.props.rowIndex}
       inputProps={{'aria-label':this.props.name,
                                role: 'combobox',}}
                                MenuProps={{
            MenuListProps:{
            
              'aria-label' :this.props.name,
              title:this.props.name+" options list"
             }}}
        disabled={true}
        //underlineStyle={{ display: 'none', }}
        displayEmpty
        style={{
          fontFamily: null,
        }}
        value={'ARCH'}
        onChange={this.handleChange}>
        <MenuItem
          value={'ARCH'}
          children={'ARCH'}
          disabled={true}
          title={
            'ARCH'
          }
          key={'2'}
        />
      </DropDownMenu>
    }
    const error = this.validate(value)
    let option = ARCH_OPTIONS.find(option => value === option.value)
    option = option || ARCH_OPTIONS[0]
    //Required
    const ReqLabel = Resource.get('Required')
    return (
      <div className={`${error ? 'error' : ''}` + ' robot-list-cell-data'}>
        {option.value ? option.name : ReqLabel}
      </div>
    )
  }
}

const IconButtonBase = ({children, ...rest}) => {
  return (
    <IconButton
      style={{
        padding: null,
        position: null,
      }}
      {...rest}>
      {children}
    </IconButton>
  )
}

class AddButtonEditor extends Component {
  focusIn = () => {
    let el = this.props.eGridCell.querySelector('[type="button"]')
   // console.log('hahahaha',el)
   console.log("focusin of addbuttoneditor")
    el?.focus()
  }
  getValue = () => {
    return null
  }

  render() {

    //console.log("AddButtonEditor",this.props)
    //Add Row
    const AddRowLabel = Resource.get("Add Row")
    return (
      <Tooltip title={AddRowLabel}>
      <IconButton style={{marginTop:'16px'}} id={'add-'+this.props?.node.rowIndex} className="icon-button button__add-row" title={AddRowLabel}>
        <AddIcon />
      </IconButton>
      </Tooltip>
    )
  }
}

class EditButtonEditor extends Component {
  focusIn = () => {
    let el = this.props.eGridCell.querySelector('button')
    //console.log('hahahaha',el)
    console.log("focusin of Editbuttoneditor")
    console.log("")
    el?.focus()
  }
  getValue = () => {
    return null
  }

  render() {
    console.log("EditButton",this.props.eGridCell)
    //Edit Row
    const EditRowLabel = Resource.get("Edit Row")
    return (
      <Tooltip title={EditRowLabel}>
      <IconButton style={{marginTop:'16px'}} id={'edit-'+this.props?.node.rowIndex} className="icon-button button__add-row" title={EditRowLabel}>
        <EditIcon />
      </IconButton>
      </Tooltip>
    )
  }
}

class EditButtonRender extends Component {
  focusIn = () => {
    let el = this.props.eGridCell.querySelector('button')
    //console.log('hahahaha',el)
    console.log("focusin of EditButtonRender")
    console.log("")
    el?.focus()
  }
  getValue = () => {
    return null
  }

  render() {
    console.log("EditButtonRender",this.props.eGridCell)
    //Save Row
    const SaveRowLabel = Resource.get("Save Row")
    return (
      <Tooltip title={SaveRowLabel}>
      <IconButton style={{marginTop:'16px'}} id={'edit-'+this.props?.node.rowIndex} className="icon-button button__add-row" title={SaveRowLabel}>
        <DoneIcon role="figure" />
      </IconButton>
      </Tooltip>
    )
  }
}

class AddButtonRender extends Component {
  render() {
    return null
  }
}

class DuplicateButton extends Component {
  focusIn = () => {
    let el = this.props.eGridCell.querySelector('button')
    el?.focus()
  }
  getValue = () => {
    return null
  }

  render() {
    //Duplicate Row
    const DuplicateRowLabel = Resource.get('Duplicate Row')
    return (
      <Tooltip title={DuplicateRowLabel}>
      <IconButton style={{marginTop:'16px'}} id={'duplicate-'+this.props?.node.rowIndex}  title={DuplicateRowLabel} className="icon-button button__duplicate-row">
        <CopyIcon />
      </IconButton>
      </Tooltip>
    )
  }
}

class DeviceGrid extends Component {
  nonadmincolumns=[
{//non admin Username
  headerName: Resource.get('Non Admin Username'),
  field: 'nonAdminUserName',
  headerComponentFramework:()=>{
return(
<div style={{ display: "flex", alignItems: "center", justifyContent:"center",marginLeft:"5px" }}>
  <div>
    <p style={{fontWeight:'bold'}}> Non Admin Username</p>
  </div>
  <Tooltip componentsProps={{
    arrow:{
      sx:{
        color:'#61c6e5',
      }
     },
      tooltip: {
       
        sx: {
          backgroundColor:'#61c6e5'
        },
      },
    }} title="This will help you to run the robot with Non Admin User Account">

  <div>
 <span> <Information className='info_non-root-cred-icon'/></span>
  </div>
  </Tooltip>
  </div>
  

)
  },
headerTooltip: 'Non Admin Username',
  editable: true,
  cellEditorFramework: UsernameCellEditor,
  cellRendererFramework: UsernameCellRenderer,
  valueGetter: (params) => {
    return {
      value: params.data.nonAdminUserName || '',
      fieldErrors: this.fieldErrors[params.node.rowIndex],
      cursorPosition: this._getCursorPosition(params.node.rowIndex, 'nonAdminUserName')
    }
  },
  onChange: (idx, name, value, error, cursorPosition) => {
    const robots = this.state.robots.slice()
    this.fieldErrors[idx] = this.fieldErrors[idx] || {}
    this.fieldErrors[idx][name] = error
    robots[idx] = robots[idx] || {}
    robots[idx][name] = value
    this._setCursorPosition(idx, name, cursorPosition);
    this.setState({robots, currentField: name})
  },
},

{//Non admin Password
  headerName: Resource.get('Non Admin Password'),
  field: 'nonAdminUserPassword',
  headerComponentFramework:()=>{
    return(
    <div style={{ display: "flex", alignItems: "center", justifyContent:"center",marginLeft:"5px" }}>
      <div>
        <p style={{fontWeight:'bold'}}> Non Admin Password</p>
      </div>
      <Tooltip componentsProps={{
       arrow:{
        sx:{
          color:'#61c6e5',
        }
       },
      tooltip: {
        sx: {
          backgroundColor:'#61c6e5',
        },
        
      },
     
    }} title="This will help you to run the robot with Non Admin User Account">
    
      <div>
     <span> <Information className='info_non-root-cred-icon'/></span>
      </div>
      </Tooltip>
      </div>
      
    
    )
      },
  headerTooltip: 'Non Admin Password',
  editable: true,
  cellEditorFramework: PasswordCellEditor,
  cellRendererFramework: PasswordCellRenderer,
  valueGetter: ({data, node}) => {
    return {
      value: data.nonAdminUserPassword || '',
      //fieldErrors: this.fieldErrors[node.rowIndex],
      cursorPosition: this._getCursorPosition(node.rowIndex, 'nonAdminUserPassword')
    }
  },
  onChange: (idx, name, value, error, cursorPosition) => {
    const robots = this.state.robots.slice()
    this.fieldErrors[idx] = this.fieldErrors[idx] || {}
    this.fieldErrors[idx][name] = error
    robots[idx] = robots[idx] || {}
    robots[idx][name] = value;
    this._setCursorPosition(idx, name, cursorPosition);
    this.setState({robots, currentField: name})
  },
},
  ]
  sudopw = [
{//Sudo password (Linux only)
  headerName: Resource.get('Sudo password (Linux only)'),
  field: 'sudoPassword',
  //Sudo password (Linux only)
  headerTooltip: Resource.get('Sudo password (Linux only)'),
  tooltipField: null,
  editable: true,
  cellEditorFramework: SudoPasswordCellEditor,
  cellRendererFramework: SudoPasswordCellRenderer,
  valueGetter: ({data, node}) => {
    return {
      value: data.sudoPassword || '',
      cursorPosition: this._getCursorPosition(node.rowIndex, 'sudoPassword')
    }
  },
  onChange: (idx, name, value, error, cursorPosition) => {
    const robots = this.state.robots.slice()
    this.fieldErrors[idx] = this.fieldErrors[idx] || {}
    this.fieldErrors[idx][name] = error
    robots[idx] = robots[idx] || {}
    robots[idx][name] = value;
    this._setCursorPosition(idx, name, cursorPosition);
    this.setState({robots, currentField: name})
  },
},

  ]
  columns = [
    {
      headerName: '',
      field: 'checkbox',
      checkboxSelection: true,
      headerCheckboxSelection: true,
      suppressMenu: true,
      suppressSorting: true,
      suppressResize: true,
      suppressSizeToFit: true,
      width: 50,
    },
    {
      colId: 'deploy-error',
      headerName: null,
      field: null,
      editable: true,
      cellEditorFramework: DeployErrorIcon,
      cellRendererFramework: DeployErrorIcon,
      newValueHandler: () => {
        return null
      },
      suppressMenu: true,
      suppressSorting: true,
      suppressResize: true,
      suppressSizeToFit: true,
      width: 50,
    },
    {//Device IP
      headerName: Resource.get('Device IP'),
      field: 'hostname',
      //Device IP
      headerTooltip: Resource.get('Device IP'),
      tooltipField: 'Device IP',
      editable: true,
      cellEditorFramework: HostnameCellEditor,
      cellRendererFramework: HostnameCellRenderer,
      valueGetter: ({data, node, colDef}) => {
        return {
          value: data.hostname,
          fieldErrors: this.fieldErrors[node.rowIndex],
          robots: this.state.robots,
          node: node,
          cursorPosition: this._getCursorPosition(node.rowIndex, 'hostname')
        }
      },
      onChange: (idx, name, value, error, cursorPosition) => {
        const robots = this.state.robots.slice()
        this.fieldErrors[idx] = this.fieldErrors[idx] || {};
        this.fieldErrors[idx][name] = error;

        robots[idx] = robots[idx] || {}
        robots[idx][name] = value;
        this._setCursorPosition(idx, name, cursorPosition);
        this.setState({robots, currentField: name});
      },
      onChangeFieldError: (idx, name, error) => {
        this.fieldErrors[idx] = this.fieldErrors[idx] || {};
        this.fieldErrors[idx][name] = error || ' ';
      }
    },
    {//OS
      headerName: Resource.get('OS'),
      field: 'profile',
      //OS
      headerTooltip: Resource.get('OS'),
      tooltipField: 'profile',
      editable: true,
      suppressResize: true,
      suppressSizeToFit: true,
      width: 70,
      cellEditorFramework: OSCellEditor,
      cellRendererFramework: OSCellRenderer,
      valueGetter: ({data, node, colDef}) => {
      
       
        return {
          value:data.profile ,
          fieldErrors: this.fieldErrors[node.rowIndex],
          hub: this.props.hub,
          probe: this.props.probe
        }
        
      },
      valueSetter: function (params) {
        var newVal = params.newValue;
        var valueChanged = false;
        // if(params.data.profile){
        //   if(newVal!=null){
        //     var valueChanged = params.data.profile !== newVal;
        //     if (valueChanged) {
        //       params.data.profile = newVal;
        //     }
        //   }

        // }

        return valueChanged;
      },
      onChange: (idx, name, value, error) => {
       

        let robots = this.state.robots.slice()
        this.fieldErrors[idx] = this.fieldErrors[idx] || {};
        this.fieldErrors[idx][name] = error;
        robots[idx] = robots[idx] || {}
        robots[idx][name] = value
        this.setState({robots, currentField: name}, () => {
          this._updateArchInfo(idx, value);
        });
      },
      onChangeFieldError: (idx, name, error) => {
        this.fieldErrors[idx] = this.fieldErrors[idx] || {}
        this.fieldErrors[idx][name] = error;
      }
    },
    {//Architecture
      headerName: Resource.get('Architecture'),
      field: 'arch',
      //Architecture
      headerTooltip: Resource.get('Architecture'),
      tooltipField: 'arch',
      editable: true,
      suppressResize: true,
      suppressSizeToFit: true,
      width: 90,
      cellEditorFramework: ArchCellEditor,
      cellRendererFramework: ArchCellRenderer,
      valueGetter: ({data, node, colDef}) => {
        return {
          value: data.arch,
          fieldErrors: this.fieldErrors[node.rowIndex],
          profile: data.profile,
        }
      },
      valueSetter: function (params) {
        var newVal = params.newValue;
        var valueChanged = false;
        // if(params.data.arch){
        //   if(newVal!=null){
        //     var valueChanged = params.data.arch !== newVal;
        //     if (valueChanged) {
        //       params.data.arch = newVal;
        //     }
        //   }

        // }

        return valueChanged;
      },
      onChange: (idx, name, value, error) => {
        const robots = this.state.robots.slice()
        this.fieldErrors[idx] = this.fieldErrors[idx] || {}
        this.fieldErrors[idx][name] = error
        robots[idx] = robots[idx] || {}
        robots[idx][name] = value
        this.setState({robots, currentField: name})
      },
      onChangeFieldError: (idx, name, error) => {
        this.fieldErrors[idx] = this.fieldErrors[idx] || {}
        this.fieldErrors[idx][name] = error;
      }
    },
    {//Username
      headerName: Resource.get('Username'),
      field: 'username',
      //Username
      headerTooltip: Resource.get('Username'),
      tooltipField: 'username',
      editable: true,
      cellEditorFramework: UsernameCellEditor,
      cellRendererFramework: UsernameCellRenderer,
      valueGetter: (params) => {
        return {
          value: params.data.username || '',
          fieldErrors: this.fieldErrors[params.node.rowIndex],
          cursorPosition: this._getCursorPosition(params.node.rowIndex, 'username')
        }
      },
      onChange: (idx, name, value, error, cursorPosition) => {
        const robots = this.state.robots.slice()
        this.fieldErrors[idx] = this.fieldErrors[idx] || {}
        this.fieldErrors[idx][name] = error
        robots[idx] = robots[idx] || {}
        robots[idx][name] = value
        this._setCursorPosition(idx, name, cursorPosition);
        this.setState({robots, currentField: name})
      },
    },
    {//Password
      headerName: Resource.get('Password'),
      field: 'password',
      //Password
      headerTooltip: Resource.get('Password'),
      tooltipField: null,
      editable: true,
      cellEditorFramework: PasswordCellEditor,
      cellRendererFramework: PasswordCellRenderer,
      valueGetter: ({data, node}) => {
        return {
          value: data.password || '',
          fieldErrors: this.fieldErrors[node.rowIndex],
          cursorPosition: this._getCursorPosition(node.rowIndex, 'password')
        }
      },
      onChange: (idx, name, value, error, cursorPosition) => {
        const robots = this.state.robots.slice()
        this.fieldErrors[idx] = this.fieldErrors[idx] || {}
        this.fieldErrors[idx][name] = error
        robots[idx] = robots[idx] || {}
        robots[idx][name] = value;
        this._setCursorPosition(idx, name, cursorPosition);
        this.setState({robots, currentField: name})
      },
    },
     
    
  ]
  actions =[
    {
      colId: 'add',
      headerName: "Add",
      field: null,
      editable: true,
      cellEditorFramework: AddButtonEditor,
      cellRendererFramework: AddButtonEditor,
      newValueHandler: () => {
        return null
      },
      suppressMenu: true,
      suppressSorting: true,
      suppressResize: true,
      suppressSizeToFit: true,
      width: 50,
    },
    {
      colId: 'edit',
      headerName: "Edit",
      field: null,
      editable: true,
      cellEditorFramework: EditButtonRender,
      cellRendererFramework: EditButtonEditor,
      newValueHandler: () => {
        return null
      },
      suppressMenu: true,
      suppressSorting: true,
      suppressResize: true,
      suppressSizeToFit: true,
      width: 50,
    },
    {
      colId: 'duplicate',
      headerName: "Copy",
      field: null,
      editable: true,
      cellEditorFramework: DuplicateButton,
      cellRendererFramework: DuplicateButton,
      newValueHandler: () => {
        return null
      },
      suppressMenu: true,
      suppressSorting: true,
      suppressResize: true,
      suppressSizeToFit: true,
      width: 50,
    },
  ]
  state = {
    robots: isEmpty(this.props.robots) ? [{}] : this.props.robots,
    currentRowIndex: 0,
    currentField: 'hostname',
    scrollPosition: 0,
    disableDeleteButton: true,
    currentFocussableElementId:null
  }
  fieldErrors = {}
  currentCursorInfo = {rowIndex: null, columnName: null, position: null}
  _updateArchInfo = (rowIndex, value) => {
    let gridApi = this.gridApi;
    if (gridApi) {
      let row = gridApi.rowRenderer.rowConsByRowIndex[rowIndex],
        column;
      if (row) {
        for (let i = 0; i < gridApi.rowRenderer.columnController.gridColumns.length; i++) {
          if (gridApi.rowRenderer.columnController.gridColumns[i].colId === 'arch') {
            column = gridApi.rowRenderer.columnController.gridColumns[i];
            break;
          }
        }


        let cell = row.getRenderedCellForColumn(column);
        if (cell && cell.cellEditor && cell.cellEditor.componentInstance) {
          cell.cellEditor.componentInstance.updateInfo(value);
        }
      }
      // setInterval(() => {
      //   const instances = gridApi.getCellEditorInstances();
      //   if (instances.length > 0) {
      //     const instance = instances[0];
      //     for(let i=0;i<instances.length;i++){
      //       if(instances[i].componentInstance.name === "arch"){
      //         instance= instances[i];
      //         break;
      //       }
      //     }
      //     if (instance.updateInfo) {
      //       instance.updateInfo(value);
      //       // console.log(
      //       //   `found editing cell: row index = ${result.rowIndex}, column = ${result.colId}.`
      //       // );
      //     } else {
      //       console.log(
      //         'found editing cell, but method updateInfo not found, must be the default editor.'
      //         );
      //       }
      //     } else {
      //       console.log('found not editing cell.');
      //     }
      //   }, 1000);

      //   }
    }
  }
  _setCursorPosition = (rowIdx, columnName, position) => {
    this.currentCursorInfo.rowIndex = rowIdx;
    this.currentCursorInfo.columnName = columnName;
    this.currentCursorInfo.position = position;
  }
  _getCursorPosition = (cellRowIdx, columnName) => {
    return (this.currentCursorInfo.rowIndex === cellRowIdx && this.currentCursorInfo.columnName === columnName) ? this.currentCursorInfo.position : null
  }
  _debian32BitSystem = () => {
    // return error string or undefined
    const fieldErrors = this.fieldErrors
    return Object.keys(fieldErrors)
      .map(idx => fieldErrors[idx].arch)
      .find(error => error === DEBIAN_32_BIT_ERROR)
  }
  _nonSolarisSystem = () => {
    // return error string or undefined
    const fieldErrors = this.fieldErrors
    return Object.keys(fieldErrors)
      .map(idx => fieldErrors[idx].arch)
      .find(error => error === SOLARIS_ERROR)
  }
  _solarisSystem = () => {
    // return error string or undefined
    const fieldErrors = this.fieldErrors
    return Object.keys(fieldErrors)
      .map(idx => fieldErrors[idx].arch)
      .find(error => error === SOLARIS_ERROR)
  }


  _adjustfocusColumn = (
    gridApi,
    field = this.state.currentField,
    rowIndex = this.state.currentRowIndex,
  ) => {

    currentRow = rowIndex
    /*if (touchedRows[rowIndex] !== undefined) {
      touchedRows[rowIndex] = true
    } else {
      touchedRows[rowIndex] = false
    }*/

    if (gridApi) {
      gridApi.ensureIndexVisible(rowIndex)
      let elmnt = document.querySelector('.ag-body-viewport')
      elmnt.scrollTop = this.state.scrollPosition

      /*gridApi.startEditingCell({
        rowIndex: rowIndex,
        colKey: field,
      })*/
      // gridApi.setFocusedCell(rowIndex, field)
      // const row = gridApi.rowRenderer.renderedRows[rowIndex]
      // row.renderedCells[field].setFocusInOnEditor()

      let row = gridApi.rowRenderer.rowConsByRowIndex[rowIndex],
        column;
      if (row) {
        for (let i = 0; i < gridApi.rowRenderer.columnController.gridColumns.length; i++) {
          if (gridApi.rowRenderer.columnController.gridColumns[i].colId === field) {
            column = gridApi.rowRenderer.columnController.gridColumns[i];
            console.log(gridApi.rowRenderer.columnController)
            console.log(column)
            break;
          }
        }
        let cell = row.getRenderedCellForColumn(column);
        if (cell && cell.cellEditor) {
          cell.cellEditor.focusIn();
         // cell.focusIn();
        }
      }
      // setInterval(() => {
      //   const instances = gridApi.getCellEditorInstances();
      //   if (instances.length > 0) {
      //     const instance = instances[0];
      //     for(let i=0;i<instances.length;i++){
      //       if(instances[i].componentInstance.name === field){
      //         instance= instances[i];
      //         break;
      //       }
      //     }
      //     if (instance.focusIn) {
      //       instance.focusIn();
      //       // console.log(
      //       //   `found editing cell: row index = ${result.rowIndex}, column = ${result.colId}.`
      //       // );
      //     } else {
      //       console.log(
      //         'found editing cell, but method myCustomFunction not found, must be the default editor.'
      //         );
      //       }
      //     } else {
      //       console.log('found not editing cell.');
      //     }
      //   }, 1000);
    }
    //  }
  }

  _focusRow = (
    gridApi,
    field = this.state.currentField,
    rowIndex = this.state.currentRowIndex,
  ) => {

    currentRow = rowIndex
    if (touchedRows[rowIndex] !== undefined) {
      touchedRows[rowIndex] = true
    } else {
      touchedRows[rowIndex] = false
    }

    if (gridApi) {
      gridApi.ensureIndexVisible(rowIndex)
      let elmnt = document.querySelector('.ag-body-viewport')
      elmnt.scrollTop = this.state.scrollPosition

      gridApi.startEditingCell({
        rowIndex: rowIndex,
        colKey: field,
      })
      // gridApi.setFocusedCell(rowIndex, field)
      // const row = gridApi.rowRenderer.renderedRows[rowIndex]
      // row.renderedCells[field].setFocusInOnEditor()

      let row = gridApi.rowRenderer.rowConsByRowIndex[rowIndex],
        column;
      if (row) {
        for (let i = 0; i < gridApi.rowRenderer.columnController.gridColumns.length; i++) {
          if (gridApi.rowRenderer.columnController.gridColumns[i].colId === field) {
            column = gridApi.rowRenderer.columnController.gridColumns[i];
           
            break;
          }
        }
        let cell = row.getRenderedCellForColumn(column);
        if (cell && cell.cellEditor) {
          cell.cellEditor.focusIn();
        }
      }
      // setInterval(() => {
      //   const instances = gridApi.getCellEditorInstances();
      //   if (instances.length > 0) {
      //     const instance = instances[0];
      //     for(let i=0;i<instances.length;i++){
      //       if(instances[i].componentInstance.name === field){
      //         instance= instances[i];
      //         break;
      //       }
      //     }
      //     if (instance.focusIn) {
      //       instance.focusIn();
      //       // console.log(
      //       //   `found editing cell: row index = ${result.rowIndex}, column = ${result.colId}.`
      //       // );
      //     } else {
      //       console.log(
      //         'found editing cell, but method myCustomFunction not found, must be the default editor.'
      //         );
      //       }
      //     } else {
      //       console.log('found not editing cell.');
      //     }
      //   }, 1000);
    }
    //  }
  }
  _handleCellClicked = cell => {
    let robots = this.state.robots
    let fieldErrors = this.fieldErrors
    let currentRowIndex = cell.rowIndex
    let currentField = cell.colDef.field
    let elmnt = document.querySelector('.ag-body-viewport')
    let scrollPosition = elmnt.scrollTop

    // don't do anything if the add column was clicked and the button is not present
    if (
      cell.colDef.colId === 'add' &&
      !cell.event.target.closest('button.button__add-row')
    ) {
      return this
    }
    let currentFocussableElementId=null;

    switch (cell.colDef.colId) {
      case 'add':
        robots = robots.concat({})
        currentRowIndex = robots.length - 1
        currentField = 'hostname'
        currentFocussableElementId='add-'+cell?.rowIndex;
        break;
      case 'edit':
        if (cell.api) {
          let editingCells=cell.api.getEditingCells({
            column: cell.node.rowIndex,
            // gets the first columnKey
            colKey: cell.columnApi.getDisplayedCenterColumns()[0].colId,
            undefined
          })


          /*cell.api.stopEditing({
            rowIndex: cell.node.rowIndex,
            // gets the first columnKey
            colKey: cell.columnApi.getDisplayedCenterColumns()[0].colId
          });*/
          console.log(cell)
          console.log(cell.api)
          console.log('editingcells',editingCells)
          if(editingCells?.length>0)
          cell.api.stopEditing();
        else
        cell.api.startEditingCell({
          rowIndex: cell.node.rowIndex,
          // gets the first columnKey
          colKey: cell.columnApi.getDisplayedCenterColumns()[0].colId
        });
        currentFocussableElementId='edit-'+cell.rowIndex;
      }
      //  this._setCursorPosition(cell.node.rowIndex, "hostname", 0);
          break;
      case 'duplicate':
        robots = robots.concat(Object.assign({}, cell.data))
        currentField = 'hostname'
        // set duplicate field error for both rows
        fieldErrors = Object.assign({}, fieldErrors)
        fieldErrors[currentRowIndex] = fieldErrors[currentRowIndex] || {}
        fieldErrors[currentRowIndex][currentField] = 'No duplicate IPs'
        currentRowIndex = robots.length - 1
        fieldErrors[currentRowIndex] = fieldErrors[currentRowIndex] || {}
        fieldErrors[currentRowIndex][currentField] = 'No duplicate IPs'
        currentFocussableElementId='duplicate-'+cell.rowIndex;
        break;
      case 'deploy-error':
        return this
      default:
        break;
    }
    this.fieldErrors = fieldErrors;
    this.setState({
      robots,
      currentRowIndex,
      currentField,
      scrollPosition,
      currentFocussableElementId
    });
   
  }
 
  _oneRowProbe = () => {
    return ['docker_monitor', 'vmware'].includes(this.props.probe)
  }
  _pendingRobotTotal = (props = this.props, state = this.state) => {
    return props.deployedRobots.length + state.robots.length
  }
  _showAddColumns = show => {
    if (this.gridApi && this.columnApi) {
      this.columnApi.setColumnsVisible(['add', 'duplicate'], show)
      this.gridApi.sizeColumnsToFit()
    }
  }
  _showErrorColumn = show => {
    if (this.gridApi && this.columnApi) {
      this.columnApi.setColumnsVisible(['deploy-error'], show)
      this.gridApi.sizeColumnsToFit()
    }
  }
  _windowsOnUnixHub = () => {
    // return error string or undefined
    const fieldErrors = this.fieldErrors
    return Object.keys(fieldErrors)
      .map(idx => fieldErrors[idx].profile)
      .find(error => error === WINDOWS_ON_UNIX_ERROR)
  }

  componentWillMount() {
    touchedRows = {}
    currentRow = 0
    this.props.getRobots()
    window.onresize = debounce(event => {
      this.gridApi.sizeColumnsToFit()
    }, 200)
  }

  componentWillReceiveProps(nextProps) {
    const newRobots = !isEqual(nextProps.robots, this.state.robots)
    const newHub = !isEqual(nextProps.hub, this.state.hub)
    const newPendingRobotTotal =
      this._pendingRobotTotal() !== this._pendingRobotTotal(nextProps)

    if (newPendingRobotTotal) {
      this._showAddColumns(true)
    }

    if (newRobots && !isEmpty(nextProps.robots)) {
      this.setState({robots: nextProps.robots})
      this._showErrorColumn(
        !!nextProps.robots.find(robot => !!robot.statusError),
      )
    }
    if (newHub && this.gridApi) {
      // ensure the grid is updated when a new hub is selected
      this.gridApi.redrawRows()
      document.getElementById(this.state.currentFocussableElementId)?.focus();
    }
   
   
  }

  componentDidUpdate(prevProps, prevState) {    
    const newPendingRobotTotal =
      this._pendingRobotTotal() !==
      this._pendingRobotTotal(prevProps, prevState)

    if (this.props.onChange) {
      this.props.onChange(this.state.robots, this.validate())
    }
    if (newPendingRobotTotal) {
      this._showAddColumns(true)
      document.getElementById(this.state.currentFocussableElementId)?.focus();
    }
   
   // this._focusRow(this.gridApi)
  }

  validate = () => {
    const fieldErrors = this.fieldErrors
    const robots = this.state.robots
    const robotMatchesSchema = robot => {
      const schema = ['hostname', 'profile', 'arch', 'username', 'password']
      return schema.reduce((acc, attribute) => {
        return (
          acc &&
          Object.keys(robot).includes(attribute) &&
          !isEmpty(robot[attribute])
        )
      }, true)
    }
    const robotHasError = robot => {
      return !!Object.keys(robot).find(key => !!robot[key])
    }
    // return on first found fieldError
    const hasErrors = fieldErrors =>
      !!Object.keys(fieldErrors).find(idx => robotHasError(fieldErrors[idx]))

    // return on first robot found that doesn't match schema
    const hasSchemaErrors = robots =>
      !!robots.find(robot => !robotMatchesSchema(robot))

   return hasErrors(fieldErrors) || hasSchemaErrors(robots) ;
  }	  
  enableDeleteButton = () => {
    let disableDeleteButton = true
    if (this.gridApi) {
      if (this.gridApi.getSelectedRows().length > 0) {
        disableDeleteButton = false
      }
    }
    this.setState({
      disableDeleteButton: disableDeleteButton,
    })
  }
  getImportCsvDisabledTitle = () => {
    //Too many robots deployed
    const RobotsDeployed = Resource.get('Too many robots deployed')
    // A CSV file was already imported
    const CSVimported = Resource.get('A CSV file was already imported')
    if (this._oneRowProbe())
      return `Import is unavailable for ${this.props.probe}`
   
    if (this.state.successfulImport) return CSVimported
    return null
  }

  onSuppressKeyboardEvent(params) {
    let key = params.event.key;
    let shiftKey = params.event.shiftKey;
    let isTabbingForward = key === 'Tab' && shiftKey === false;
    let isTabbingBackWards = key === 'Tab' && shiftKey === true;

    // Handle cell children tabbing
    if (isTabbingForward || isTabbingBackWards) {
      tabForward = isTabbingForward;
      tabBackward = isTabbingBackWards;

      if (params.editing) {
        return false;
      }

      let eGridCell = params.event.path.find((el) => {
        if (el.classList === undefined) return false;
        return el.classList.contains('ag-cell');
      });

      let focusableChildrenElements = getAallFocussableElementsOf(eGridCell);
      let lastCellChildEl =
        focusableChildrenElements[focusableChildrenElements.length - 1];
      let firstCellChildEl = focusableChildrenElements[0];

      //  FORWARD
      // allow to movee to next cell when the cells last element is focused
      if (isTabbingForward && focusableChildrenElements.length > 0) {
        let isLastChildFocused =
          lastCellChildEl && document.activeElement === lastCellChildEl;

        if (isLastChildFocused === false) {
          return true;
        }
      }

      //  BACKWARDS
      //  focus last element if none are focused
      //  allow to tab backwards when first element is focused
      if (isTabbingBackWards && focusableChildrenElements.length > 0) {
        let cellHasFocusedChildren =
          eGridCell.contains(document.activeElement) &&
          eGridCell !== document.activeElement;

        if (!cellHasFocusedChildren) {
          params.event.preventDefault();
          lastCellChildEl.focus();
        }

        let isFirstChildFocused =
          firstCellChildEl && document.activeElement === firstCellChildEl;

        if (isFirstChildFocused === false) {
          return true;
        }
      }
    }

    return false;
  }

  onCellFocused2(params) {
    if (tabForward) {
      let cell = document.activeElement;
      let focusableChildren = getAallFocussableElementsOf(cell);

      if (focusableChildren.length > 0) {
        focusableChildren[0].focus();
      }

      tabForward = false;
    }

    if (tabBackward) {
      let cell = document.activeElement;
      let focusableChildren = getAallFocussableElementsOf(cell);

      if (focusableChildren.length > 0) {
        focusableChildren[focusableChildren.length - 1].focus();
      }

      tabBackward = false;
    }
  }
  render() {

    let classForDeleteButton = 'robot__button--delete robot-list__action'
    if (this.state.disableDeleteButton) {
      classForDeleteButton += ' nav-icon--disabled'
    }
    return (
      <div>
        
        <ErrorDialog error={this._windowsOnUnixHub()}/>
        <ErrorDialog
          error={this._debian32BitSystem()}
          message={DEBIAN_32_BIT_ERROR}
        />
        <ErrorDialog
          error={this._nonSolarisSystem() || this._solarisSystem()}
          message={SOLARIS_ERROR}
        />
        <div className="robot-list__title">{addDev}</div>
        <aside className="robot-list__actions">
          <ImportCsv
            disabled={             
              this._oneRowProbe() ||
              this.state.successfulImport
            }
            disabledTitle={this.getImportCsvDisabledTitle()}
            onUploadSuccess={response => {
              const pendingRobots = this.state.robots.filter(robot => {
                // only return robots with user entered values
                const emptyRobot = Object.keys(
                  robot,
                ).reduce((acc, attribute) => {
                  return acc && isEmpty(robot[attribute])
                }, true)
                return !emptyRobot
              })
              const robots = pendingRobots.concat(response.data._items)
              this.setState({
                robots,
                
                successfulImport: true,
              })
            }}
          />
          <CsvTemplate/>
          <hr
            style={{
              alignSelf: 'stretch',
              borderColor: 'rgba(0, 0, 0, 0.12)',
              borderRight: 'none',
            }}
          />

          <Tooltip
              title={
                //Delete Row(s)
                Resource.get('Delete Row(s)')}>
          <IconButton 
          
            disabled={this.state.disableDeleteButton}
            onClick={() => {
              let robots = this.state.robots.slice()
              let fieldErrors = Object.assign({}, this.fieldErrors)
              let currentRowIndex = 0
              let currentField = 'hostname'
              // using `getSelectedNodes` behaves weird
              this.gridApi.forEachNode((node, idx) => {
                const rowIndex = node.rowIndex
                robots[rowIndex].remove = node.selected
                delete fieldErrors[rowIndex]
              })
              var successfulImport = this.state.successfulImport
              robots = robots.filter(robot => !robot.remove)
              if (!robots.length) {
                robots = [{}]
                fieldErrors = {}
                currentRowIndex = 0
                currentField = 'hostname'
                successfulImport = false //reset the Import button if all of the rows were deleted
              }
              this.fieldErrors = fieldErrors;
              this.setState(
                {
                  robots,
                  currentRowIndex,
                  currentField,
                  successfulImport,
                },
                state => {
                  this.gridApi.deselectAll()
                },
              )
            }}>
            <DeleteIcon
              />
          </IconButton>
          </Tooltip>
        </aside>
        <div className="ag-material robot__list" style={{marginTop:'8px'}}>
          <Grid
            defaultColDef={null}
            hub={this.props.hub}
            rowData={this.state.robots}
            rowHeight={75}
            columnDefs={this.props.probe.name.toUpperCase()=== 'WINDOWS'?[...this.columns,...this.nonadmincolumns,...this.actions]:this.props.probe.name.toUpperCase()==='UNIX'?[...this.columns,...this.sudopw,...this.actions]:[...this.columns,...this.actions]}
            suppressClickEdit={true}
            editType="fullRow"
           // singleClickEdit={true}
            enableSorting={false}
            enableColResize={false}
            rowSelection="multiple"
            suppressRowClickSelection={true}
            onCellFocused={this.onCellFocused2.bind(this)}
            onCellClicked={this._handleCellClicked}
            tooltipShowDelay={0}
            onSelectionChanged={this.enableDeleteButton}
            suppressCellFocus={true}
            suppressKeyboardEvent= {this.onSuppressKeyboardEvent}
            onRowEditingStarted=  {(params) =>{
              // scrolls to the first column, or first editable column. you can pass in the correct index
var firstEditCol = params.columnApi.getAllDisplayedColumns()[0];
/////
params.api.ensureColumnVisible(firstEditCol );

// sets focus into the first grid cell
params.api.setFocusedCell(params.rowIndex, firstEditCol);  
    /*params.api.refreshCells({
      columns: ["edit"],
      rowNodes: [params.node],
      force: true
    });*/
  }}
  onRowEditingStopped= {(params) => {
   /* params.api.refreshCells({
      columns: ["edit"],
      rowNodes: [params.node],
      force: true
    });*/
  }}
            onGridReady={params => {             
              this.gridApi = params.api
              this.columnApi = params.columnApi
              this._showAddColumns(
                !this._oneRowProbe() ,
              )
              this._showErrorColumn(
                !!this.state.robots.find(robot => !!robot.statusError),
              )
              setTimeout(()=>{

                document.getElementById(this.state.currentFocussableElementId)?.focus();
                
                },200);
              //this._focusRow(params.api)
            }}
          />
        </div>
      </div>
    )
  }
}

let currentRow = 0
let touchedRows = {}

export default DeviceGrid
                                                                  