import orderBy from 'lodash/orderBy'
import get from 'lodash/get'
import * as _ from 'lodash'
export const sortColumns = (sortColumn, sortOrder, originalData) => {
  let newSortedData = null
  if (sortColumn === 'name') {
    let lowerCaseData = _.clone(originalData);
    lowerCaseData = lowerCaseData.map((sysName) => {
      sysName.name = sysName.name.toLowerCase();
      return sysName;
    });
    newSortedData = orderBy(
      lowerCaseData,
      [
        function(o) {
          return get(o, 'name', '').toLowerCase()
        },
      ],
      sortOrder
    )
  }else if (sortColumn === 'attributes.DisplayName') {
    newSortedData = orderBy(
      originalData,
      [
        function(o) {
          return get(o, 'attributes.DisplayName[0]', '').toLowerCase()
        },
      ],
      sortOrder
    )
  } else if (sortColumn === 'maxAlarmSeverity.value') {
    newSortedData = orderBy(originalData, ['maxAlarmSeverity.value', 'maxAlarmSeverity.cnt',function(o) { return get(o, 'name', '').toLowerCase() }], [sortOrder, sortOrder, 'asc'])
  } else if (sortColumn === 'busType') {
    newSortedData = orderBy(
      originalData,
      [
        function(o) {
          let busType = get(o, 'busType', '')
          return busType === 'hub' || busType === 'robot'
        },
      ],
      sortOrder
    )
  } else if (sortColumn === 'ipAddresses') {
    newSortedData = orderBy(
      originalData,
      [
        function(o) {
          return get(o, 'ipAddresses', []).join(', ')
        },
      ],
      sortOrder
    )
  } else if (sortColumn === 'attributes.create_time') {
	  let sortOrderUpdated = ''
	  if(sortOrder != ''){
		 sortOrderUpdated = sortOrder === "asc" ? "desc" : "asc";
	  }
	    newSortedData = orderBy(
	      originalData,
	      [
	        function(o) {
	          return get(o, 'attributes.create_time', '')
	        },
	      ],
	      sortOrderUpdated
	    )
  } else if (sortColumn === 'attributes.monitoredBy') {
    newSortedData = orderBy(
      originalData,
      [
        function(o) {
          return get(o, 'attributes.monitoredBy', [])
            .sort()
            .join(', ')
        },
      ],
      sortOrder
    )
  } else {
    newSortedData = orderBy(originalData, sortColumn, sortOrder)
  }
  return newSortedData
}
