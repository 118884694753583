import connect from "./../../utils/connect";
import { setCardPage } from "./../cards/actions";
import { setQuickFilterEntities } from "./../entities/actions";
import { selectBreadCrumb } from "./actions";
import Breadcrumbs from "./Breadcrumbs";

function mapStateToProps(state) {
  return {
    breadCrumbs: state.breadCrumbs,
  };
}
const mapDispatchToProps = (dispatch) => {
  return {
    handleClick: (item) => {
      dispatch(selectBreadCrumb(item));
      dispatch(setQuickFilterEntities(""));
    },
    resetCardPage: () => {
      dispatch(setCardPage(0));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Breadcrumbs);
