// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Error help message
  content["Deployment failed. This may be due to an inability to communicate with the target device."] =
  "Deployment failed. This may be due to an inability to communicate with the target device.";

  //Robot deploy error message
  content["To troubleshoot, verify your network connection or firewall settings on the host."] =
  "To troubleshoot, verify your network connection or firewall settings on the host.";

  //FIX ISSUES
  content["FIX ISSUES"] =
  "FIX ISSUES";

  //Deploymentfailed message
  content["Deployment failed"] =
  "Deployment failed";

  // Fix Deployment Issues
  content["Fix Deployment Issues"] =
  "Fix Deployment Issues";

  // Confirmation check message
  content["Confirm your target device details and re-deploy."] =
  "Confirm your target device details and re-deploy.";

  //Deployment progress message
  content["Deployment is in progress."] =
  "Deployment is in progress.";

  //help message
  content["When your installation is complete, click NEXT to continue."] =
  "When your installation is complete, click NEXT to continue.";

  //Deployment status message
  content["Deployment was successful"] =
  "Deployment was successful";

  //NEXT help message
  content["Click NEXT below to finish your monitoring setup."] =
  "Click NEXT below to finish your monitoring setup.";

  //A Message
  content["A Message"] =
  "A Message";

  // You are great
  content["You are great."] =
  "You are great.";

  // END OF LOCALIZATION

export default content;
