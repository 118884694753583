import {
	GET_WMI_CREDENTIALS,
	FETCH_WMI_CREDENTIALS_SUCCESS,
	FETCH_WMI_CREDENTIALS_FAILURE,
	DELETE_WMI_CRED,
	UPDATE_WMI_CRED,
  ADDED_WMI_CRED,
  GET_SNMP_CREDENTIALS,
  FETCH_SNMP_CREDENTIALS_FAILURE,
  FETCH_SNMP_CREDENTIALS_SUCCESS,
  ADDED_SNMP_CRED,
  DELETE_SNMP_CRED,
  UPDATE_SNMP_CRED 

} from './actionTypes'
import isEqual from 'lodash/isEqual'


const credentials = (
  state = {
    didInvalidate: false,
    isFetching: false,
    items: [],
    sncmpItems:[],
    unixItems:[],
  },
  action,
) => {
  switch (action.type) {
    case GET_WMI_CREDENTIALS:
      return Object.assign({}, state, {
    	    isFetching: true,
      	    didInvalidate: false,
     })
    case FETCH_WMI_CREDENTIALS_SUCCESS:
        const items = action.credentials
        const updatedState = Object.assign({}, state, {
          isFetching: false,
          didInvalidate: false,
          items: items
        })
        return isEqual(updatedState, state) ? state : updatedState
      case FETCH_WMI_CREDENTIALS_FAILURE:
        return Object.assign({}, state, {
          isFetching: false,
          didInvalidate: true,
          errorMessage: action.errorMessage,
          items: []
        })
        case GET_SNMP_CREDENTIALS:
          return Object.assign({}, state, {
              isFetching: true,
                didInvalidate: false,
         })
        //  case FETCH_SNMP_CREDENTIALS_SUCCESS:
        // const itemSNMP = action.credentials
        // const updatedStates = Object.assign({}, state, {
        //   isFetching: false,
        //   didInvalidate: false,
        //   items: itemSNMP
        // })
        // return isEqual(updatedStates, state) ? state : updatedStates
        

        case FETCH_SNMP_CREDENTIALS_SUCCESS:
          const sncmpItems = action.credentials
          const snmpUpdatedState = Object.assign({}, state, {
            isFetching: false,
            didInvalidate: false,
            sncmpItems: sncmpItems
          })
          return isEqual(snmpUpdatedState, state) ? state : snmpUpdatedState

          
      case FETCH_SNMP_CREDENTIALS_FAILURE:
        return Object.assign({}, state, {
          isFetching: false,
          didInvalidate: true,
          errorMessage: action.errorMessage,
          items: []
        })
    case DELETE_WMI_CRED:
    	const deletedCred = action.deletedCredential
    	let filteredList=[];
            filteredList=state.items.filter(item => {
                return item.id !== deletedCred
            })
        const updatedItem =  Object.assign({}, state, {
          isFetching: false,
          didInvalidate: false,
          items: filteredList
        })
        return isEqual(updatedItem, state) ? state : updatedItem
        case DELETE_SNMP_CRED:
    	const deletedSNMPCred = action.deletedCredentialSNMP
    	let filteredSNMPList=[];
      filteredSNMPList=state.sncmpItems.filter(item => {
                return item.id !== deletedSNMPCred
            })
        const updatedSNMPItem =  Object.assign({}, state, {
          isFetching: false,
          didInvalidate: false,
          sncmpItems: filteredSNMPList
        })
        return isEqual(updatedSNMPItem, state) ? state : updatedSNMPItem
    case UPDATE_WMI_CRED:
	const updatedCred = action.updatedCredential
    	let updatedCatalog = state.items
    	for (let i = 0; i < updatedCatalog.length ; i++){
            if(updatedCred.id === updatedCatalog[i].id){
                updatedCatalog[i]=updatedCred;
                break;
            }
        }
        const updatedCatalogItem =  Object.assign({}, state, {
          isFetching: false,
          didInvalidate: false,
          items: updatedCatalog
        })
        return isEqual(updatedCatalogItem, state) ? state : updatedCatalogItem
        case UPDATE_SNMP_CRED:
          const updatedSNMPCred = action.updatedCredential
              let updatedSnmpCatalog = state.sncmpItems
              for (let i = 0; i < updatedSnmpCatalog.length ; i++){
                    if(updatedSNMPCred.id === updatedSnmpCatalog[i].id){
                      updatedSnmpCatalog[i]=updatedSNMPCred;
                        break;
                    }
                }
                const updatedSNMPCatalogItem =  Object.assign({}, state, {
                  isFetching: false,
                  didInvalidate: false,
                  sncmpItems: updatedSnmpCatalog
                })
                return isEqual(updatedSNMPCatalogItem, state) ? state : updatedSNMPCatalogItem
        
    case ADDED_WMI_CRED:
	const addedCred = action.addedCredential
    	let addedCatalog = state.items
    	let isNewCredential=true
        for (let i = 0; i < addedCatalog.length ; i++){
            if(addedCred.id === addedCatalog[i].id){
               	isNewCredential=false
				break;
            }
        }
        if(isNewCredential){
        	addedCatalog.push(addedCred);
		}
        const addCatalogItem =  Object.assign({}, state, {
          isFetching: false,
          didInvalidate: false,
          items: addedCatalog
        })
        return isEqual(addCatalogItem, state) ? state : addCatalogItem
        case ADDED_SNMP_CRED:
          const addedSNMPCred = action.addedCredentialSNMP
              let addedSNMPCatalog = state.sncmpItems
              let isNewSNMPCredential=true
                for (let i = 0; i < addedSNMPCatalog.length ; i++){
                    if(addedSNMPCred.id === addedSNMPCatalog[i].id){
                      isNewSNMPCredential=false
                break;
                    }
                }
                if(isNewSNMPCredential){
                  addedSNMPCatalog.push(addedSNMPCred);
            }
                const addCatalogSNMPItem =  Object.assign({}, state, {
                  isFetching: false,
                  didInvalidate: false,
                  sncmpItems: addedSNMPCatalog
                })
                return isEqual(addCatalogSNMPItem, state) ? state : addCatalogSNMPItem
     default:
      return state
  }
}

export default credentials
