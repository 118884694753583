import React, { Component } from "react";
import { Checkbox, Button as RaisedButton, Snackbar, FormControlLabel, FormGroup ,FormControl,TextField,InputLabel} from "@mineral/core";
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Resource from "./Resource";
import { withRouter } from "react-router-dom";
import connect from "./../../../utils/connect";
import { setBreadCrumbs } from "./../../breadcrumbs/actions";
import "./administration.less";
import { getAutomaticGroup } from "../../../api/automaticGroups/actions";
import { update } from "../../../api/automaticGroups/actions";
import { getPreConfigureGroup } from "../../../api/automaticGroups/actions";
import { updatePreconfGroup } from "../../../api/automaticGroups/actions";
import rowSelectionCache from "../../../utils/rowSelectionCache";
import  "../../setupWizard/deviceDiscovery/credentialVault.less"

const checkboxTheme = createMuiTheme({
  palette: {
    secondary: {
      main: '#3272D9',
    },
  },
})

const styles = {
  style: {
    width: "18%",
    marginBottom: "8px"
  },

  inputStyle: {
    marginLeft: "10px",
    marginTop: "2px",
  },

  redBorder: {
    marginLeft: "10px",
    marginTop: "2px",
    border: "1px solid red",
  },

  labelStyle: {
    width: "max-content",
  },

  displayCenterStyle: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "10px",
  },

  buttonStyle: {
    marginBottom: "50px",
    cursor: "pointer",
  },
};

export class ParentGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEnabled: false,
      osTypeChecked: false,
      osNameChecked: false,
      originChecked: false,
      userTag1Checked: false,
      userTag2Checked: false,
      inputOsType: "",
      inputOsName: "",
      inputOrigin: "",
      inputUserTag1: "",
      inputUserTag2: "",
      automatic_group: [],
      toCreate: [],
      toDelete: [],
      preAutomaticGroup: [],
      //Parent Group
      parentGroup:Resource.get("Parent Group"),
      automaticGroups: [
        {
          field: "os_type",
          parent: "",
          status: false,
        },
        {
          field: "os_name",
          parent: "",
          status: false,
        },
        {
          field: "ANY_ORIGIN",
          parent: "",
          status: false,
        },
        {
          field: "user_tag_1",
          parent: "",
          status: false,
        },
        {
          field: "user_tag_2",
          parent: "",
          status: false,
        },
      ],
      updateAutomaticGroupSuccess: false,
      updatepreConfigGroupSuccess: false,
      updateAutomaticGroupFail: false,
      updatepreConfigGroupFail: false,
    };
  }

  componentDidMount() {
    this.props.setBreadCrumbs([
      {
        //Settings
        name: Resource.get("Settings"),
        link: "/settings",
      },
      {
        //Administration
        name: Resource.get("Administration"),
        link: "/settings/administration",
      },
    ]);

    this.fetchPreConfigureGroup();
    this.getAutomaticGroup();
  }

  fetchPreConfigureGroup = () => {
    this.props.getPreConfigureGroup().then(() => {
      this.state.toDelete = [];
      const pcongData = this.props.automatic_group.preItems.map((item) => {
        if (this.state.toCreate.indexOf(item.id) === -1) {
          if (item.grpId > 0) {
            this.state.toCreate.push(item.id);
          }
        }

        return {
          ...item,
          status: item.grpId > 0 ? true : false,
        };
      });

      this.setState({
        preAutomaticGroup: pcongData,
      });
    });
  };

  getAutomaticGroup = () => {
    this.props.fetchAutomaticGroup().then(() => {
      this.props.automatic_group.items.forEach((item) => {
        if (item.field === "os_type") {
          this.setState({
            inputOsType: item.parent,
            osTypeChecked: true,
          });
        }
        if (item.field === "os_name") {
          this.setState({
            inputOsName: item.parent,
            osNameChecked: true,
          });
        }
        if (item.field === "ANY_ORIGIN" || item.field === "origin") {
          this.state.inputOrigin = item.parent;
          this.setState({
            inputOrigin: item.parent,
            originChecked: true,
          });
        }
        if (item.field === "user_tag_1") {
          this.setState({
            inputUserTag1: item.parent,
            userTag1Checked: true,
          });
        }
        if (item.field === "user_tag_2") {
          this.setState({
            inputUserTag2: item.parent,
            userTag2Checked: true,
          });
        }
      });
    });
  };

  onCheckHandler = (param, inputName, inputValue) => {
   
    this.setState(
      (oldState) => {
        if (oldState[param] === true) {
          inputValue = "";
          return {
            [inputName]: "",
            [param]: !oldState[param],
          };
        }
        else {
          return {
            [param]: !oldState[param],
          };
        }
      },
      () => {
        if (inputValue.length > 0) {
          let isEnabled =
            this.state.inputOsName ||
            this.state.inputOsType ||
            this.state.inputOrigin ||
            this.state.inputUserTag1 ||
            this.state.inputUserTag2;
          this.setState({ isEnabled });
        } else {
          let status = param;
          if (this.state[status]) {
            this.setState({ isEnabled: false });
          } else {
            let isEnable =
              (this.state.inputOsName.length < 1 && this.state.osNameChecked) ||
              (this.state.inputOsType.length < 1 && this.state.osTypeChecked) ||
              (this.state.inputOrigin.length < 1 && this.state.originChecked) ||
              (this.state.inputUserTag1.length < 1 &&
                this.state.userTag1Checked) ||
              (this.state.inputUserTag2.length < 1 &&
                this.state.userTag2Checked);
            this.setState({ isEnabled: !isEnable });
          }
        }
      }
    );
  };

  onChangeHandler = (param, value) => {
    this.setState(
      {
        [param]: value,
      },
      () => {
        let isEnabled =
          this.state.inputOsName ||
          this.state.inputOsType ||
          this.state.inputOrigin ||
          this.state.inputUserTag1 ||
          this.state.inputUserTag2;
        this.setState({ isEnabled });
        let isEnable =
          (this.state.inputOsName.length < 1 && this.state.osNameChecked) ||
          (this.state.inputOsType.length < 1 && this.state.osTypeChecked) ||
          (this.state.inputOrigin.length < 1 && this.state.originChecked) ||
          (this.state.inputUserTag1.length < 1 && this.state.userTag1Checked) ||
          (this.state.inputUserTag2.length < 1 && this.state.userTag2Checked);

        if (value.length < 1 || isEnable) {
          this.setState({ isEnabled: false });
        }
      }
    );
  };

  onPreConfCheckHandler = (id, grpId, status) => {
    let isEnable =
      (this.state.inputOsName.length < 1 && this.state.osNameChecked) ||
      (this.state.inputOsType.length < 1 && this.state.osTypeChecked) ||
      (this.state.inputOrigin.length < 1 && this.state.originChecked) ||
      (this.state.inputUserTag1.length < 1 && this.state.userTag1Checked) ||
      (this.state.inputUserTag2.length < 1 && this.state.userTag2Checked);

    let isDisabled = this.state.isEnabled;
    let updatedData = this.state.preAutomaticGroup.map((item) => {
      if (item.status) {
        isDisabled = true;
      }

      if (item.id === id) {
        item.status = !item.status;
      }
      return item;
    });

    this.setState({
      preAutomaticGroup: updatedData,
    });

    if (!status) {
      if (!this.state.toCreate.indexOf(id) !== -1) {
        this.state.toCreate.push(id);
      }

      if (grpId > 0) {
        let toDeleteIndex = this.state.toDelete.indexOf(grpId);
        if (toDeleteIndex !== -1) {
          this.state.toDelete.splice(toDeleteIndex, 1);
        }
      }

      isDisabled = true;
    } else {
      let tocreateIndex = this.state.toCreate.indexOf(id);
      if (tocreateIndex !== -1) {
        this.state.toCreate.splice(tocreateIndex, 1);
      }

      if (!this.state.toCreate.indexOf(grpId) !== -1 && grpId > 1) {
        this.state.toDelete.push(grpId);
      }
    }

    if (isEnable) {
      isDisabled = false;
    }

    this.setState({
      isEnabled: isDisabled,
    });
  };
  save = () => {
    this.state.automaticGroups.forEach((item) => {

      if (item.field === 'os_type') {
        item.parent = this.state.inputOsType;
        item.status = this.state.osTypeChecked;

      }
      if (item.field === 'os_name') {
        item.parent = this.state.inputOsName;
        item.status = this.state.osNameChecked;
      }

      if (item.field === 'ANY_ORIGIN' || item.field === "origin") {
        item.parent = this.state.inputOrigin;
        item.status = this.state.originChecked;
      }

      if (item.field === 'user_tag_1') {
        item.parent = this.state.inputUserTag1;
        item.status = this.state.userTag1Checked;
      }

      if (item.field === 'user_tag_2') {
        item.parent = this.state.inputUserTag2;
        item.status = this.state.userTag2Checked;
      }
    })
    this.props.updateAutomaticGroup(this.state.automaticGroups).then(() => {
      this.getAutomaticGroup();
      this.setState({
        updateAutomaticGroupSuccess: true,
        updateAutomaticGroupFail: false
      })

    })//dp039841
      .catch((error) => {
        this.setState({ updateAutomaticGroupFail: true, updateAutomaticGroupSuccess: false, });
      });

    this.props
      .updateAutomaticGroup(this.state.automaticGroups)
      .then(() => {
        this.getAutomaticGroup();
        this.setState({
          updateAutomaticGroupSuccess: true,
          updateAutomaticGroupFail: false,
        });
      }) //dp039841
      .catch((error) => {
        this.setState({
          updateAutomaticGroupFail: true,
          updateAutomaticGroupSuccess: false,
        });
      });

    this.props
      .updatePreconfGroup(this.state.toCreate, this.state.toDelete)
      .then(() => {
        this.fetchPreConfigureGroup();
        this.setState({
          updatepreConfigGroupSuccess: true,
          updatepreConfigGroupFail: false,
        });
      }) //dp039841
      .catch((error) => {
        this.setState({
          updatepreConfigGroupFail: true,
          updatepreConfigGroupSuccess: false,
        });
      });

    this.setState({ isEnabled: false });
  }

  handleCloseSnackBar = () => {
    this.setState({
      updateAutomaticGroupFail: false,
      updateAutomaticGroupSuccess: false,
      updatepreConfigGroupFail: false,
      updatepreConfigGroupSuccess: false,
    });
    rowSelectionCache.clear();
  };
  // onClose = () => {
  //   rowSelectionCache.clear();
  // };
  render() {
    let requiredLabel = Resource.get("Required"); //Required
    return (
      <div className="administrationCardContent"  style={{display: "flex",flexDirection: "column",alignItems: "center",justifyContent: "space-between",overflow:'scroll'}}>
        {this.state.updateAutomaticGroupSuccess &&
          this.state.updatepreConfigGroupSuccess && (
            <Snackbar
              open={true}
              message={Resource.get("Saved successfully.")}//Saved successfully.
              autoHideDuration={2000}
              onClose={this.handleCloseSnackBar}
            />
          )}
        {(this.state.updateAutomaticGroupFail ||
          this.state.updatepreConfigGroupFail) && (
            <Snackbar
              open={true}
              message={"Saving is failed."}
              autoHideDuration={2000}
              onRequestClose={this.handleCloseSnackBar}
            />
          )}

        <div className="leftcontainer" >
          <div className="leftpane">
            <p style={{ padding: "10px" }}>
              Select device properties to group by automatically. Note: Each
              selection you make below creates one or more unique groups under
              the specified parent group. Selecting more than one property does
              not combine those groups.
            </p>
            <h2 title={this.state.parentGroup} aria-label = {this.state.parentGroup} role="heading" style={{ display: "flex", justifyContent: "center", color:"#58676D" }}>{this.state.parentGroup}</h2>
            {/* <ThemeProvider theme={checkboxTheme}> */}
            <div style={styles.displayCenterStyle}>
              <FormControlLabel 
                style={{ width: '120px' }}
                id="txtostype" 
                label={Resource.get("OS Type")}//OS Type
                control={<Checkbox autoFocus style={{marginRight:'4px' }} 
                  checked={this.state.osTypeChecked}
                  onChange={() => {
                    this.onCheckHandler("osTypeChecked", "inputOsType", this.state.inputOsType);
                  }}
                  
                  
                  
                  id="first-content-element"
                />}
              />
              <FormControl>
             <InputLabel> <span className="credentialvault-required-label" style={{float:'right'}}>{requiredLabel}</span></InputLabel>

              <TextField size="small"
                type="text"
                aria-label={Resource.get("OS Type")}//OS Type
                aria-labelledby="txtostype"
              
                //style={styles.redBorder}
                value={this.state.inputOsType}
                disabled={!this.state.osTypeChecked}
                onChange={(event) => {
                  this.onChangeHandler("inputOsType", event.target.value);
                }}
              />
              </FormControl>


            </div>

            <div style={styles.displayCenterStyle}>
              <FormControlLabel
                style={{ width: '120px'  }}
                id="txtosname"
                label={Resource.get("OS Name")}//OS Name
                control={<Checkbox style={{marginRight:'4px' }}
                  checked={this.state.osNameChecked}
                  onChange={() => {
                    this.onCheckHandler("osNameChecked", "inputOsName", this.state.inputOsName);
                  }}
                  
                  
                  
                />}
              />
               <FormControl>
             <InputLabel> <span className="credentialvault-required-label" style={{float:'right'}}>{requiredLabel}</span></InputLabel>

              <TextField size="small"
                type="text"
                aria-label={Resource.get("OS Name")}//OS Name
                aria-labelledby="txtosname"
              
                //style={styles.redBorder}
                value={this.state.inputOsName}
                disabled={!this.state.osNameChecked}
                onChange={(event) => {
                  this.onChangeHandler("inputOsName", event.target.value);
                }}
              />
              </FormControl>
             
            </div>

            <div style={styles.displayCenterStyle}>
              <FormControlLabel
                style={{ width: '120px'  }}
                id="txtorigin"
                label={Resource.get("Origin")}//Origin
                control={
                  <Checkbox style={{marginRight:'4px' }}
                    checked={this.state.originChecked}
                    onChange={() => {
                      this.onCheckHandler("originChecked", "inputOrigin", this.state.inputOrigin);
                    }}
                    
                    
                    
                  />
                }
              />

<FormControl>
             <InputLabel> <span className="credentialvault-required-label" style={{float:'right'}}>{requiredLabel}</span></InputLabel>

              <TextField size="small"
               
                aria-label={Resource.get("Origin")}//Origin
                aria-labelledby="txtorigin"
              
                //style={styles.redBorder}
                value={this.state.inputOrigin}
                disabled={!this.state.originChecked}
                onChange={(event) => {
                  this.onChangeHandler("inputOrigin", event.target.value);
                }}
              />
              </FormControl>

            
            </div>

            <div style={styles.displayCenterStyle}>
              <FormControlLabel
                style={{ width: '120px'  }}
                id="txtusertag1"
                label={Resource.get("User Tag 1")}//User Tag 1
                control={
                  <Checkbox style={{marginRight:'4px' }}
                    checked={this.state.userTag1Checked}
                    onChange={() => {
                      this.onCheckHandler(
                        "userTag1Checked",
                        "inputUserTag1",
                        this.state.inputUserTag1
                      );
                    }}
                    
                    
                    
                  />
                }
              />
<FormControl>
             <InputLabel> <span className="credentialvault-required-label" style={{float:'right'}}>{requiredLabel}</span></InputLabel>

              <TextField size="small"
               
               aria-label={Resource.get("User Tag 1")}//User Tag 1
                aria-labelledby="txtusertag1"
              
                //style={styles.redBorder}
                value={this.state.inputUserTag1}
                disabled={!this.state.userTag1Checked}
                onChange={(event) => {
                  this.onChangeHandler("inputUserTag1", event.target.value);
                }}
              />
              </FormControl>
             
            </div>

            <div style={styles.displayCenterStyle}>
              <FormControlLabel
                style={{ width: '120px'  }}
                id="txtusertag2"
                label={Resource.get("User Tag 2")}//User Tag 2
                control={
                  <Checkbox style={{marginRight:'4px' }}
                    checked={this.state.userTag2Checked}
                    onChange={() => {
                      this.onCheckHandler(
                        "userTag2Checked",
                        "inputUserTag2",
                        this.state.inputUserTag2
                      );
                    }}
                    
                    
                    
                  />
                }
              />

<FormControl>
             <InputLabel> <span className="credentialvault-required-label" style={{float:'right'}}>{requiredLabel}</span></InputLabel>

              <TextField size="small"
               
               aria-label={Resource.get("User Tag 2")}//User Tag 2
                aria-labelledby="txtusertag2"
              
                //style={styles.redBorder}
                value={this.state.inputUserTag2}
                disabled={!this.state.userTag2Checked}
                onChange={(event) => {
                  this.onChangeHandler("inputUserTag2", event.target.value);
                }}
              />
              </FormControl>
             
            </div>
            {/* </ThemeProvider> */}
          </div>

          <div className="rightpane">
            <p style={{ padding: "10px" }}>
              To use the Discovery Application feature, check at least one of
              the Application Discovery groups below. Important! If you clear
              the UNIX Application Discovery or the Windows Application
              Discovery group, then you disable Application Discovery. For
              Application Discovery to work, you must also download the needed
              probes to your archive and have a current license for them. For
              more information, see "Use Application Discovery" on the CA UIM
              documentation wiki.
            </p>
            <div className="rightpanecheckbox">
              {this.state.preAutomaticGroup.map((item) => (
                <FormControlLabel
                  label={
                    item.description === "Unix Devices" ||
                      item.description === "Windows Devices"
                      ? "Amazon: " + item.name
                      : "Application Discovery: " + item.name
                  }
                  control={<Checkbox style={{marginRight:'4px' }}
                    checked={item.status}
                    key={item.id}
                    onChange={() => {
                      this.onPreConfCheckHandler(
                        item.id,
                        item.grpId,
                        item.status
                      );
                    }}
                  />}
                />
              ))}
            </div>
          </div>
        </div>

        <div  style={{width:'100%',display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'}}>

        <RaisedButton 
          variant="contained"
          children={Resource.get("Save")}//Save
          disabled={!this.state.isEnabled}
          //style={styles.buttonStyle}
          onClick={this.save}
        />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setBreadCrumbs: (items) => dispatch(setBreadCrumbs(items)),
    fetchAutomaticGroup: () => dispatch(getAutomaticGroup()),
    updateAutomaticGroup: (automaticGroups) =>
      dispatch(update(automaticGroups)),
    getPreConfigureGroup: () => dispatch(getPreConfigureGroup()),
    updatePreconfGroup: (toCreate, toDelete) =>
      dispatch(updatePreconfGroup(toCreate, toDelete)),
  };
};

const mapStateToProps = (state) => {
  return {
    saas: state.saas,
    sid: state.sid,
    automatic_group: state.automatic_group,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ParentGroup));
