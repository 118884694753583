// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Settings
  content["Settings"] =
  "Configuración";

  //Alarms and Notifications
  content["Alarms and Notifications"] =
  "Alarmas y notificaciones";

  //Dashboard and Reports
  content["Dashboard and Reports"] =
  "Cuadro de mandos e informes";

  //Integrations
  content["Integrations"] =
  "Integraciones";

  //Infrastructure
  content["Infrastructure"] =
  "Infraestructura";

  //System Administration
  content["System Administration"] =
  "Administración del sistema";

  // END OF LOCALIZATION

export default content;
