import React from 'react'

const SavedFilters = (props) => {
  return (
    <section>
      All the filters!
    </section>
  )
}

export default SavedFilters