import React from 'react'
import {
  Card,
  CardContent,
  CardMedia
} from '@mineral/core'
import MessageFormat from 'message-format'
import Resource from './Resource'
import add from './../../assets/images/AddReport.svg'
import addHover from './../../assets/images/AddReportHover.svg'

const CreateReportCard = (props) => {
	
  const { cabiInfo } = props
  
  const  cabiURL =   window.sessionStorage.getItem("cabiAddress")+ '/flow.html?_flowId=adhocFlow'
	  
  const cardStyle = {
		    height: '15.625rem',
		    width: '18.75rem',
		    marginBottom: '1rem',
		    marginRight: '1rem',
		  }

  const imgStyle = {
	    paddingTop: '1.3rem',
	    paddingLeft: '125px'
  }
  const imgTagStyle= {

  }
  
  
  const uri = 'createReports'
  return (
      <div style={{display:'inline-block'}}>
          <a href={cabiURL} target="_blank" style={{ textDecoration: 'none',display:'inline-block' }} >
          <Card  className ='create-custom-report-card' style={cardStyle} id={uri} >
          <CardMedia className ='create-custom-report-media' style={imgStyle}>
            <img src={add} className='add-new-disabled' role='figure'/>
            <img src={addHover} className='add-new' role='figure' />
          </CardMedia>
           <CardContent style={{padding:'0px !important'}}>
           <div style={{height: '16px',color: '#333333',fontFamily: 'Roboto',fontSize: '14px',fontWeight: '600',
               lineHeight: '16px',textAlign: 'center',paddingTop: '16px'}}> New </div>
           <div style={{height: '21px', color: '#333333',fontFamily: 'Roboto',fontSize: '18px',fontWeight: '600',
               lineHeight: '21px',textAlign: 'center',paddingTop: '8px'}}> Custom Report </div>
           </CardContent>
          </Card>
          </a>
      </div>
  )
}

export default CreateReportCard