import React, { Component } from "react";
import { AlarmViewer, AlarmContextTypes } from "@uim/uim-alarmviewer";
import {CircularProgress} from '@mineral/core';
import axios from "axios";
import "./alarms.less";
import isEqual from "lodash/isEqual";
import get from 'lodash/get';

class Alarms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sid: null,
      groupId: props.groupId,
      csId: props.csId,
      masterId: props.masterId,
      //external: false,
      accountInfo:props.acl.aclInfo,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (
      !isEqual(nextProps.groupId, this.state.groupId) ||
      !isEqual(nextProps.csId, this.state.csId) ||
      !isEqual(nextProps.masterId, this.state.masterId) ||
      !isEqual(nextProps.acl.aclInfo, this.state.accountInfo)
    ) {
      this.setState({
        groupId: nextProps.groupId,
        csId: nextProps.csId,
        masterId: nextProps.masterId,
        accountInfo:nextProps.acl.aclInfo
      });
    }
  }
  handleNavFromAlarm = (params) => {
    this.props.history.push(
      "computer-systems/" + params.cs_id + "/configuration"
    );
  };
  componentWillMount = () => {

	  let overrideSid=axios.defaults.headers.common["Authorization"];
      if(window.OPERATOR_CONSOLE.refreshToken && window.OPERATOR_CONSOLE.refreshToken!=="")
          {
          overrideSid=window.OPERATOR_CONSOLE.refreshToken;
          axios.defaults.headers.common["Authorization"]=window.OPERATOR_CONSOLE.refreshToken;
          window.OPERATOR_CONSOLE.refreshToken="";

          }
        this.setState({ sid: overrideSid})
  };

  render() {
    let contextType = null;
    let contextValue = null;
    let view = (
      <div className="alarms__spinner" style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <CircularProgress style={{color: '#3272D9'}} />
      </div>
    );

    if (this.state.groupId != null) {
      contextType = AlarmContextTypes.GROUP_ID;
      contextValue = this.state.groupId;
    } else if (this.state.csId != null) {
      contextType = AlarmContextTypes.CS_ID;
      contextValue = this.state.csId;
    } else if (this.state.masterId != null) {
      contextType = AlarmContextTypes.MASTER_ID;
      contextValue = this.state.masterId;
    }

    let hasTechnologiesInPath = this.props.location.pathname.includes(
      "technologies"
    );
    const urlParams = new URLSearchParams(this.props.location.search);
    const alarmId = urlParams.get("alarmId");
    const severity = urlParams.get("severity");
    const probe = urlParams.get("probe");
    const externalFlag = urlParams.get("external");
    const hostname = urlParams.get("hostname");
    const viewOptions = urlParams.get("viewOptions");

    let external =
      (externalFlag !== null && externalFlag === "true") ||
      hasTechnologiesInPath;
    let isFromOperatorConsole = external || this.props.isFromOperatorConsole; 
    let filters = [];
    if (alarmId) {
      filters = [`alarmId = ${alarmId}`];
    } else if (severity) {
      filters = [`severity = ${severity}`];
    } else if (this.props.alarmSeverity) {
      filters = [`severity = ${this.props.alarmSeverity}`];
    }


    if (this.props.probe) {
      let probe = this.props.probe.split("-")[0]; // converts 'aws-service' or similar to 'aws'
      filters.push(`probe = ${probe}`);
    } else if (probe) {
      filters.push(`probe = ${probe}`);
    }
    if(hostname){
      filters.push(`source = ${hostname}`);
    }
    if(viewOptions){
      filters.push(`viewOptions=${viewOptions}`)
    }
    //let viewOptionHasVisibleFilter = viewOptions?viewOptions.includes("'field' : 'visible'"):false;
    if(!viewOptions){
	   //implemeted for show invisible switch
    filters.push('visible = true');
    }
	let userRoles=get(this.state.accountInfo, 'roles', '')
	//console.log("OC accountInfo :: "+userRoles)
    if (this.state.sid && userRoles != ''){
      view = (
        <AlarmViewer
          axiosInterceptors={axios.default.interceptors}
          sid={this.state.sid}
          accountInfo={this.state.accountInfo}
          contextType={contextType}
          contextValue={contextValue}
          filters={filters}
          addBreadCrumb={this.props.addBreadCrumb}
          key = {contextType+"_"+contextValue}
          external={external}
          isFromOperatorConsole={isFromOperatorConsole}
          clearAlarmSeverityFilter={() => {
            // remove the alarm severity filter query parameter
            this.props.history.push(this.props.location.pathname);
          }}
          handleNavFromAlarm={(params) => {
            if (params.linkType === "computerSystem") {
              this.props.history.push(
                "/computer-systems/" + params.cs_id + "/alarms"
              );
            } else if (params.linkType === "policy") {
              this.props.history.push(
                "/settings/alarm-policies/" + params.cs_id
              );
            } else if (params.linkType === "slm") {
              this.props.history.push(
                "/slm" 
              );
            } else if (params.linkType === "groups") {
              this.props.history.push(
                "/groups/" + params.group_id+"/alarms"
              );
            } else if (params.linkType === "probe") {
              this.props.history.push(
                "/technologies/"+params.probe+"/alarms"
              );
            }
          }}
        />
      );
    }
    return view;
  }
}

export default Alarms;
