import {
    SET_ISTIMEDOUT
} from './actionTypes'

export function setIsTimedout(isTimedout) {
    return {
        type: SET_ISTIMEDOUT,
        isTimedout: isTimedout
    }
}
