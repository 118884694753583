import connect from './../../utils/connect'
import Configuration from './Configuration'

const mapStateToProps = state => {
  return {
    featureFlags: state.configuration.items || {},
    sid: state.sid,
  }
}

export default connect(mapStateToProps, null)(Configuration)
