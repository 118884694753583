import React, { Component } from "react";
import DashCard from "./DashCard";
import UserDashboardDashCard from "./UserDashboardDashCard";
import uim_overview_image from "./../../assets/images/dashboard_UIM-overview.png";
import mcs_status_image from "./../../assets/images/dashboard_mcs_status_new.png";
import axios from "axios";
import { monitorProbes as probeTitles } from "@uim/uim-common-strings";
import "./dashboards.less";
import Resource from "./Resource";
import { BulletPointIcon as BulletPoint, Paginate, FilterIcon } from "../ui-components/uim-components";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import ArrowRight from "@material-ui/icons/ArrowRight";
import { Toolbar,Switch as Toggle,FormControlLabel,Tabs,Tab,Box  } from "@mineral/core";
import { Button, FormControl, IconButton, InputAdornment, TextField } from '@mineral/core';
import dashboardPaths from "../../api/dashboard/dashboardPaths";
import DashboardTable from "./DashboardTable";
import CreateDashboardCard from "./CreateDashboardCard";
import AccessibleTooltip from "../common/AccessibleTooltip";

class Dashboards extends Component {
  constructor(props) {
    super(props);
    this.state = {
      restmonProbesInfo: [],
      isChecked: false,
      value: "",
      dashboradBreadcrumb: [],
      filterLevel: 0,
      pageNumber: 0,
      pageSize: 20,
      tabValue:"Cabi Dashboards",
      folderName:"",
      sortingField:"path",
      sortOrder:"asc",
    };

    this.hostname = window.location.hostname;
    this.port = window.location.port;
    this.showPublishedDashboards = this.showPublishedDashboards.bind(this);
    this.hidePublishedDashboards = this.hidePublishedDashboards.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
    this.debounceRef = null;
    this.folderClick = this.folderClick.bind(this);
    this.breadcrumbFilterFn = this.breadcrumbFilterFn.bind(this);
    this.folderView = "FOLDERVIEW";
    this.dashboardView = "DASHBOARDVIEW";
    this.handleSortClick = this.handleSortClick.bind(this)
  }

  componentWillMount() {
    this.props.setBreadCrumbs({
      //Dashboards
      name: Resource.get("Dashboards"),
      link: "/technologies",
    });

    axios
    .get("/mcs-ui-app/api/_count/restmon-probe-list?useEnhancedTemplates=true")
      .then((response) => { this.setState({ restmonProbesInfo: response.data._items }); })
      .catch((error) => { this.setState({}); });
  }

  componentDidUpdate() {
    this.props.setBreadCrumbs({
        //Dashboards
        name: Resource.get("Dashboards"),
        link: "/technologies",
      });
    }

    handleChangeTabs = (event,value) => {

      if(value==="Cabi Dashboards")
      this.hidePublishedDashboards();
      else
      if(value==="Dashboard Designer Dashboards")
      this.showPublishedDashboards();
      this.setState({tabValue:value})
      
  };

    render() {
      const { activeProbes } = this.props;
      const { dashboardPaths } = this.props;
      const { userdashboards } = this.props;

      const pageLimit = 20;
      const numPages = Math.ceil(dashboardPaths.count / pageLimit);
      const dashboardCnt = dashboardPaths.count

      let mcsDashCardView = null;
      // Search Dashboard
      const SEARCH_DASHBOARD = Resource.get("Search Dashboard")

      if (this.props.saas == false)
        mcsDashCardView = (
          <DashCard
            id="mcsStatus"
            //Monitoring Configuring Service
            title={Resource.get("Monitoring Configuring Service")}
            linkTo="/mcsDeploymentStatus"
            image={mcs_status_image}
          />
        );
        
      let createDashboardCard=<CreateDashboardCard/>

      // sort list of active probes by probe id
      activeProbes.sort((a, b) => a.prid.localeCompare(b.prid));

      let appTabs = (
        <div>
          <Toolbar className="root">
          <Box sx={{ width: "50%" }}>
               <Tabs variant="fullWidth"  value={this.state.tabValue}  onChange={this.handleChangeTabs}>
                               
           <Tab  fullWidth="true" id={"first-content-element"} autoFocus label={"CA Business Intelligence"} value={"Cabi Dashboards"} />

         
           <Tab  fullWidth="true" id={"Dashboard Designer Dashboards"} label={"Dashboard Designer"} value={"Dashboard Designer Dashboards"} />
            
          
           </Tabs>
           
            </Box>
          </Toolbar>

          {this.state.isChecked ? (
            <div className="dashboradBreadcrumb" style={{flexDirection:'column'}}>
            { this.state.filterLevel > 0 ? (
              <div style={{width:'100%'}}>
                <Button variant="text" role="link" aria-roledescription={"Click to view All Dashboards"}
                  onClick={() => {this.breadcrumbFilterFn("", 0)}} 
                  className="dashboradBreadcrumbLink"  autoFocus={this.state.dashboradBreadcrumb?.length>0?true:false}
                >
                  {"All Dashboards"}
                </Button>
                <ArrowRight className="breadcrumbArrow" />
                {this.state.dashboradBreadcrumb.map((name,index) => (
                  <>
                    <Button variant="text"  role="link"  aria-roledescription={"Click to view "+ name }
                      onClick={() => {this.breadcrumbFilterFn(name, index+1)}} 
                      className={ index+1 === this.state.filterLevel ? "dashboradBreadcrumbTxt" : "dashboradBreadcrumbLink" }
                      style={{visibility: index >= this.state.filterLevel ? "hidden" : "visible"}}
                    >
                      {name}    
                    </Button>
                    {index+1 < this.state.filterLevel ? 
                      <ArrowRight className="breadcrumbArrow" /> : ""
                    }
                  </>
                ))}
              </div>
            ) : null
            }

<div style={{display:'flex',flexDirection:'row',width:'100%'}}>
            <div style={{width:"95%", float:"left"}}>
              <h2 className="allDashboardCntTxt" aria-live="polite">
                {
                  this.state.isChecked && this.state.filterLevel > 0 ? 
                    this.state.folderName + " (" + dashboardCnt + ")" : "All Dashboards" + " (" + dashboardCnt + ")"
                }
              </h2>
            </div>
                <TextField autoFocus={this.state.dashboradBreadcrumb?.length==0?true:false}
                  //className="searchBox"
                  value={this.state.value}
                  onChange={this.handleChange}
                  placeholder={Resource.get("Search")} //Search...
                  style={{width: '163px'}}
                  onKeyUp={(evt) => this.onKeyUp(evt)}
                  autoComplete="off"
                  name="q"
                  inputProps={{'aria-label':SEARCH_DASHBOARD}}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                          <AccessibleTooltip title={"Search"}>
                          <SearchIcon className="selecticon" style={{ height: 16 }} />
                          </AccessibleTooltip>
                      </InputAdornment>
                    ),
                    endAdornment:(
                      <InputAdornment position="end">
                        <IconButton onClick={this.clearSearch} 
                        className={this.state.value.trim().length > 0 ? "closeIcon close-icon-visible" : "closeIcon close-icon-hidden"} 
                        edge="end"
                        >
                          <CloseIcon  />
                          </IconButton>
                      </InputAdornment>
                    )
                  }}
            />
          </div>
	      </div>
          ) : null
          }
        </div>
      )

      /**
      * The design intention here is to:
      *  1 - Always show the Summary card first
      *  2 - for each active probe, show a specific card if we have it,
      *      otherwise show the generic dashboard card
      */
      let ip = this.hostname;
      let port = this.port;
      let sid = this.props.sid;
      let do_heartbeat = false;

      if (sid) {
        do_heartbeat = window.OPERATOR_CONSOLE.do_heartbeat
        if (do_heartbeat !== 'true' && do_heartbeat !== 'false') {
          do_heartbeat = false
        }
      }

        return (
          <div>
          {appTabs}

          {this.state.isChecked ? (
            <div>
              <DashboardTable 
                tableData = {dashboardPaths.items || []}
                sid = {sid}
                folderClick = {this.folderClick}
                filterLevel = {this.state.filterLevel}
                searchTxt = {this.state.value}
                dashboradBreadcrumbCnt = {this.state.dashboradBreadcrumb.length}
                keepalive = {do_heartbeat}
                dashboardCnt = {dashboardCnt}
				fetchDashboardPaths = {this.props.fetchDashboardPaths}
                handleSortClick = {this.handleSortClick}
              />

              <div className="pagination">
                {numPages > 1 ? (
                  <Paginate
                    pageCount={numPages}
                    forcePage={this.state.pageNumber}
                    onPageChange={this.handlePageClick}
                    previousLabel={"prev"}
                    nextLabel={"next"}
                    pageRangeDisplayed={5}
                  />
                  ) : null
                }
              </div>
            </div>
          ) : (
            <div className="dashboard-picker">
              {(
                <>
                {createDashboardCard}
                <DashCard
                  id="summary"
                  title={Resource.get("Infrastructure Management Overview")} //Infrastructure Mgmt Overview
                  linkTo="/uim-cabi"
                  image={uim_overview_image}
                />
                {mcsDashCardView}
                {activeProbes.map((item, index) => this.getDashboardCardForPrid(item.prid, index))}
                {userdashboards && userdashboards._items && userdashboards._items.length>0? userdashboards._items.map((item, index) =>
                  this.getUserDashboardCard(item, index)):null}
                </>
              )}
            </div>
          )}
          </div>
        );
    }  // render method end

    showPublishedDashboards() {
      this.setState(() => ({isChecked: true}));
      this.setState({ folderName: ""});
      this.setState({ filterLevel: 0});
      this.setState({ dashboradBreadcrumb: []});

      this.debounceRef = setTimeout(
        function () {
          this.debounceRef = null;
          var params = new URLSearchParams();
          params.append("pageNumber", this.state.pageNumber);
          params.append("pageSize",   this.state.pageSize);
          params.append("filterText", this.state.value);
          params.append("filterType", this.folderView);
          params.append("sortingField", this.state.sortingField);
          params.append("sortOrder", this.state.sortOrder);

          this.props.fetchDashboardPaths(params);
        }.bind(this), 500
      );
    }

    hidePublishedDashboards() {
      this.setState(() => ({isChecked: false}));
      this.setState({ folderName: ""});
      this.setState({ filterLevel: 0});
      this.setState({ dashboradBreadcrumb: []});
    }

    getImageForProbe = (prid) => {
      let restmonProbeNames = this.state.restmonProbesInfo.map(
      (restmonProbesInfo) => restmonProbesInfo.probeName
      ),
      index = restmonProbeNames.indexOf(prid);

      try {
        if (index !== -1) {
          if (
            this.state.restmonProbesInfo[index] &&
            this.state.restmonProbesInfo[index].isOOBTemplate
            ) {
              return require("./../../assets/images/dashboard_" + prid + ".png");
            } else {
              return require("./../../assets/images/dashboard_restmon.png");
            }
        } else {
          return require("./../../assets/images/dashboard_" + prid + ".png");
        }
      } catch (e) {
        return require("./../../assets/images/dashboard_generic_monitor.png");
      }
    };

    getNameOnImage = (prid) => {
      let restmonProbeNames = this.state.restmonProbesInfo.map(
        (restmonProbesInfo) => restmonProbesInfo.probeName
        );
        const index = restmonProbeNames.indexOf(prid);
        if (index !== -1 && !this.state.restmonProbesInfo[index].isOOBTemplate) {
          return this.state.restmonProbesInfo[index].templateName;
        }
    };

    /**
    * Construct an appropriate dashboard card for a given probe id (prid)
    */
    getDashboardCardForPrid(prid, index) {
      // Determine appropriate title, image and link for this probe id
      const title = probeTitles[prid] ? probeTitles[prid] : prid;
      let link = `/technologies/${prid}/cabi`;
      if(this.props.dashboardPaths.routes && this.props.dashboardPaths.routes.length > 0) {
        link =  this.props.dashboardPaths.routes.filter(route => route.idValue?prid == route.idValue:false).length > 0 ?
        link : `/technologies/${prid}/dashboard`        
      }
      const image = this.getImageForProbe(prid);

      return (
        <DashCard
          key={index}
          id={prid}
          title={title}
          linkTo={link}
          image={image.default}
          nameOnImage={this.getNameOnImage(prid)}
        />
      );
    }

    getUserDashboardCard(item,index) {
      let id="customUserDashboard"+item.id;

      return (
        <UserDashboardDashCard
          id={id}
          title={item.dashboardName}         
          image={uim_overview_image}
          history={this.props.history}
          currentuserdashboard={item}
          cabiUrl={this.state.cabiUrl} 
          jaspertoken={this.state.jaspertoken} 
          orgidl={this.state.orgidl} 
          userLocale={this.state.userLocale}
        />
      );     
    }
     
    
    handleSortClick = (sortBy, sortOrder) => {
      this.setState({ sortingField: sortBy });
      this.setState({ sortOrder: sortOrder});


      var params = new URLSearchParams();
      params.append("pageNumber", this.state.pageNumber);
      params.append("pageSize", this.state.pageSize);
      
      if(this.state.filterPath == undefined || this.state.filterPath.trim().length == 0)
        params.append("filterText", this.state.value);
      else
        params.append("filterText", (this.state.filterPath + "/" +this.state.value).replaceAll("//", "/"));

      if(this.state.value.trim().length == 0)
        params.append("filterType", this.folderView);
      else
        params.append("filterType", this.dashboardView);

      params.append("sortingField", sortBy);
      params.append("sortOrder", sortOrder);

      if (this.state.isChecked && this.props.fetchDashboardPaths) {
        this.debounceRef = setTimeout(
          function () {
            this.debounceRef = null;
            this.props.fetchDashboardPaths(params);
          }.bind(this),
          500
          );
        }
    };

    folderClick = (filterPath, filterTxt) => {
      this.setState({ pageNumber: 0 });
      this.setState({ folderName: filterTxt});
      this.setState({ filterPath: filterPath});
      this.setState({ filterLevel: this.state.filterLevel+1});
      this.setState({ dashboradBreadcrumb: filterPath?filterPath.split("/"):[]});

      var params = new URLSearchParams();
      params.append("pageNumber", 0);
      params.append("pageSize", this.state.pageSize);
      //params.append("filterText", filterPath + "/");
      if(filterPath == undefined || filterPath.trim().length == 0)
         params.append("filterText", this.state.value);
      else
         params.append("filterText", (filterPath + "/" +this.state.value).replaceAll("//", "/"));          


        if(this.state.value.trim().length == 0)
          params.append("filterType", this.folderView);
        else
          params.append("filterType", this.dashboardView);
        
        params.append("sortingField", this.state.sortingField);
        params.append("sortOrder", this.state.sortOrder);   
          
      if (this.state.isChecked && this.props.fetchDashboardPaths) {
        this.debounceRef = setTimeout(
          function () {
            this.debounceRef = null;
            this.props.fetchDashboardPaths(params);
          }.bind(this), 500);
      }
    }

    breadcrumbFilterFn = (filterTxt, filterLevel) => {
      let prevFilterPath = "";
      this.state.dashboradBreadcrumb.map((name,index) => {
        if(index < filterLevel)
          prevFilterPath += name + "/";
      })
      
      if(filterLevel !== this.state.filterLevel) {
        this.setState({ pageNumber: 0});
        this.setState({ folderName: filterTxt});
        this.setState({ filterLevel: filterLevel});
        this.setState({ filterPath: prevFilterPath});
        this.setState({ dashboradBreadcrumb: prevFilterPath?prevFilterPath.split("/"):[]});
        
        var params = new URLSearchParams();
        params.append("pageNumber", 0);
        params.append("pageSize", this.state.pageSize);
        //params.append("filterText", prevFilterPath);
        //params.append("filterType", this.folderView);
        if(prevFilterPath == undefined || prevFilterPath.trim().length == 0)
         params.append("filterText", this.state.value);
        else
         params.append("filterText", (prevFilterPath + "/" +this.state.value).replaceAll("//", "/"));          


        if(this.state.value.trim().length == 0)
          params.append("filterType", this.folderView);
        else
          params.append("filterType", this.dashboardView);
         
          params.append("sortingField", this.state.sortingField);
          params.append("sortOrder", this.state.sortOrder);  

        if (this.state.isChecked && this.props.fetchDashboardPaths) {
          this.debounceRef = setTimeout(
            function () {
              this.debounceRef = null;
              this.props.fetchDashboardPaths(params);
            }.bind(this), 500);
        }
      }
    }

    clearSearch = (event) => {
      this.setState({ value: "",pageNumber: 0 });
      //this.setState({ folderName: ""});
      //this.setState({ filterLevel: 0});
        
      var params = new URLSearchParams();
      params.append("pageNumber", 0);
      params.append("pageSize", this.state.pageSize);
      if(this.state.filterPath == undefined || this.state.filterPath.trim().length == 0){
        params.append("filterText", "");
        this.setState({folderName: ""});
      }
      else{
        params.append("filterText", (this.state.filterPath + "/" +"").replaceAll("//", "/"));
        let newText = (this.state.filterPath ).replaceAll("//", "/").split('/').pop()
        
        this.setState({ folderName: newText != ""?newText:this.state.filterPath.replaceAll('/',"")});
      }          

      params.append("filterType", this.folderView);
      params.append("sortingField", this.state.sortingField);
      params.append("sortOrder", this.state.sortOrder);

      if (this.state.isChecked && this.props.fetchDashboardPaths) {
      this.debounceRef = setTimeout(
        function () {
          this.debounceRef = null;
          this.props.fetchDashboardPaths(params);
        }.bind(this),
        10
      );
      }
    };

    handleChange = (event ) => {
      let value = event.target.value;
      this.setState({ value: value, pageNumber: 0 });
      if (value.trim().length == 0 || value.trim().length >= 3) {
        //this.setState({ folderName: ""});
        //this.setState({ filterLevel: 0});
        
        var params = new URLSearchParams();
        params.append("pageNumber", 0);
        params.append("pageSize", this.state.pageSize);
        if(this.state.filterPath == undefined || this.state.filterPath.trim().length == 0){
         params.append("filterText", value);
         this.setState({ folderName: ""});
        }
        else{
         params.append("filterText", (this.state.filterPath + "/" +value).replaceAll("//", "/"));
         let newText = (this.state.filterPath ).replaceAll("//", "/").split('/').pop()
         this.setState({ folderName: newText != ""?newText:this.state.filterPath.replaceAll('/',"")});
        
        }
    
        if(value.trim().length == 0)
          params.append("filterType", this.folderView);
        else
          params.append("filterType", this.dashboardView);

        params.append("sortingField", this.state.sortingField);
        params.append("sortOrder", this.state.sortOrder);

        if (this.state.isChecked && this.props.fetchDashboardPaths) {
          this.debounceRef = setTimeout(
          function () {
            this.debounceRef = null;
            this.props.fetchDashboardPaths(params);
          }.bind(this),
          500
          );
        }
      }
    };

    onKeyUp = (evt) => {
      let value = "";
      //clear filter when user presses esc key
      if (evt.which === 27) {
        this.setState({ value: "", pageNumber: 0 });
        if (this.debounceRef) {
          clearTimeout(this.debounceRef);
          this.debounceRef = null;
        }
        var params = new URLSearchParams();
        params.append("pageNumber", 0);
        params.append("pageSize", this.state.pageSize);
        params.append("filterText", value);

        if(value.trim().length == 0)
          params.append("filterType", this.folderView);
        else
          params.append("filterType", this.dashboardView);

        params.append("sortingField", this.state.sortingField);
        params.append("sortOrder", this.state.sortOrder);  

        if (this.state.isChecked && this.props.fetchDashboardPaths) {
          this.debounceRef = setTimeout(
            function () {
              this.debounceRef = null;
              this.props.fetchDashboardPaths(params);
            }.bind(this),
            500
          );
        }
      }
    };

    handlePageClick = (page) => {
      this.setState({ pageNumber: page.selected });
      var params = new URLSearchParams();
      params.append("pageNumber", page.selected);
      params.append("pageSize", this.state.pageSize);
      if(this.state.filterPath == undefined || this.state.filterPath.trim().length == 0)
       params.append("filterText", this.state.value);
      else
        params.append("filterText", (this.state.filterPath + "/" +this.state.value).replaceAll("//", "/"));

      if(this.state.value.trim().length == 0)
        params.append("filterType", this.folderView);
      else
        params.append("filterType", this.dashboardView);
        
      params.append("sortingField", this.state.sortingField);
      params.append("sortOrder", this.state.sortOrder);
      
      if (this.state.isChecked && this.props.fetchDashboardPaths) {
        this.debounceRef = setTimeout(
          function () {
            this.debounceRef = null;
            this.props.fetchDashboardPaths(params);
          }.bind(this),
          500
          );
        }
      };
    }

export default Dashboards;