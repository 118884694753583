// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Relay hubs & robots
  content["Relay hubs & robots"] =
  "릴레이 허브 및 로봇";

  //Deleted devices
  content["Deleted devices"] =
  "삭제된 장치";

  //Name
  content["Name"] =
  "이름";

  //IP address
  content["IP address"] =
  "IP 주소";

  //Role
  content["Role"] =
  "역할";

  //OS Type
  content["OS Type"] =
  "OS 유형";

  //OS Name
  content["OS Name"] =
  "OS 이름";

  //OS Version
  content["OS Version"] =
  "OS 버전";

  //Settings
  content["Settings"] =
  "설정";

  //Deleted Devices
  content["Deleted Devices"] =
  "삭제된 장치";

  //Filter
  content["Filter"] =
  "필터";

  // END OF LOCALIZATION

export default content;
