// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Settings
  content["Settings"] =
  "Settings";

  //Account Admin
  content["Account Admin"] =
  "Account Admin";

  //Manage accounts using account admin
  content["Manage accounts using account admin"] =
  "Manage accounts using account admin";

  // END OF LOCALIZATION

export default content;
