import React from "react";
import connect from "./../../utils/connect";
import Alarms from "./Alarms";
import { setBreadCrumbs } from "./../breadcrumbs/actions";
import Resource from "./Resource";
import { getAccount } from "./../../api/account/actions";

const AlarmsContainer = (props) => {
  props.setBreadCrumbs({
    //Alarms
    name: Resource.get("Alarms"),
    link: "/uim-alarms",
  });
  return <Alarms {...props} />;
};

const mapStateToProps = (state) => {
  return {
    sid: state.sid,
    accountInfo: state.account ? state.account.item : null,
    acl: state.acl,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setBreadCrumbs: (items) => dispatch(setBreadCrumbs(items)),
    getAccount: () => dispatch(getAccount()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AlarmsContainer);
