import axios from 'axios';
import config from './../../config';

class credentials { 
   getWMI() {

    if(process.env.NODE_ENV == "development" )
    return axios.get('http://10.252.25.208:8080/castlemock/mock/rest/project/d0QO1h/application/YedIdg/wmi');

	return axios.get([config.basename, 'api/v1/credentials/wmi'].join('/'));
  }
  getSNMP() {
    if(process.env.NODE_ENV == "development" )
    return axios.get('http://10.252.25.208:8080/castlemock/mock/rest/project/d0QO1h/application/YedIdg/snmp');
    return axios.get([config.basename, 'api/v1/credentials/snmp'].join('/'));
    }
   getUNIX(){
    if(process.env.NODE_ENV == "development" )
    return axios.get('http://10.252.25.208:8080/castlemock/mock/rest/project/d0QO1h/application/YedIdg/shell');

    return axios.get([config.basename, 'api/v1/credentials/shell'].join('/')); 
   }
  saveWMI(credentialObj) {
    return axios.post([config.basename, 'api/v1/credentials/wmi'].join('/'), credentialObj);
  }
  saveSNMP(credentialObj) {
    return axios.post([config.basename, 'api/v1/credentials/snmp'].join('/'), credentialObj);
  }
  editWMI(credentialObj) {
    return axios.put([config.basename, 'api/v1/credentials/wmi'].join('/'), credentialObj);
  }
  editSNMP(credentialObj) {
    return axios.put([config.basename, 'api/v1/credentials/snmp'].join('/'), credentialObj);
  }
  saveUNIX(credentialObj) {
	return axios.post([config.basename, 'api/v1/credentials/shell'].join('/'), credentialObj);
 }
  editUNIX(credentialObj) {
    return axios.put([config.basename, 'api/v1/credentials/shell'].join('/'), credentialObj);
  }
  deleteWMI(id) {
        return axios.delete([config.basename, 'api/v1/credentials/wmi/'+id].join('/'));
  }
  deleteSNMP(id) {
    return axios.delete([config.basename, 'api/v1/credentials/snmp/'+id].join('/'));
}
  deleteUNIX(id) {
        return axios.delete([config.basename, 'api/v1/credentials/shell/'+id].join('/'));
  }
  deleteCredential(id,type) {
	  if(type ==='WMI'){
		  return this.deleteWMI(id)
	  }else if(type ==='UNIX') {
		  return this.deleteUNIX(id)
	  }else if(type === 'SNMP'){
      return this.deleteSNMP(id)
    }
  }
}
export default new credentials();