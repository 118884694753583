// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //dashError
  content["Dashboard failed to load."] =
  "대시보드를 로드하지 못했습니다.";

  //missing component
  content["CA Business Intelligence (CABI) system component is needed."] =
  "CABI(CA Business Intelligence) 시스템 구성 요소가 필요합니다.";

  //Breadcrumb
  content["Dashboards"] =
  "대시보드";

  //Breadcrumb
  content["Overview"] =
  "개요";

  // END OF LOCALIZATION

export default content;
