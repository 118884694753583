import React from "react";
import ArrowIcon from './../ArrowIcon';
import Resource from './Resource';
import Portlet from "./../portlets/Portlet";
import {Tooltip} from '@mineral/core';

export default class AdministrationCard extends Portlet{

    getIcon() {
        return <ArrowIcon />;
      }

      getSubLabelTwo = () => {
        return this.props.subLabelTwo;
      };
}

const TextLogo = ({ label }) => {
    return (
      <Tooltip title={label}>
        <span className="settings-text-logo admin__console--card">{label}</span>
      </Tooltip>
    );
  };

//Administration
const AdministrationLabel = Resource.get("Configure");
//Configure automatic groups
const Creating = Resource.get("Configure application discovery and automatic groups");

AdministrationCard.defaultProps = {
    logo: <TextLogo label={AdministrationLabel} />,
    configRoute: "/settings/administration",
    subLabelOne: Creating,
    subLabelTwo: "",
  };
