import {
  FETCH_DEVICE_GROUPS,
  FETCH_DEVICE_GROUPS_SUCCESS,
  FETCH_DEVICE_GROUPS_FAILURE,
} from "./actionTypes";
import devicegroups from "./devicegroups";

export function fetchDeviceGroupsInit() {
  return {
    type: FETCH_DEVICE_GROUPS,
  };
}

export function fetchDeviceGroupsSuccess(json) {
  return {
    type: FETCH_DEVICE_GROUPS_SUCCESS,
    dvcGroupsInfo: json,
    status: "loaded",
  };
}

export function fetchDeviceGroupsFailure(error) {
  return {
    type: FETCH_DEVICE_GROUPS_FAILURE,
    errorMessage: error.message,
    status: "failure",
  };
}

export function getDeviceGroups(csId) {
  return (dispatch) => {
    dispatch(fetchDeviceGroupsInit());
    return devicegroups
      .getDeviceGroups(csId)
      .then((response) => response.data)
      .then((json) => dispatch(fetchDeviceGroupsSuccess(json)))
      .catch(function (error) {
        dispatch(fetchDeviceGroupsFailure(error));
      });
  };
}