import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { SearchIcon } from "../ui-components/uim-components";
import { TextField ,InputAdornment,Tooltip} from "@mineral/core";
import "./inventory.less";
import Resource from "./Resource";
import { connect } from "react-redux";

class InventorySearch extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
  };
  state = {
    value: this.props.value || "",
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.state.value) {
      this.setState({ value: nextProps.value });
    }
  }

  handleChange = (event) => {
    this.setState({ value: event.target.value });
  };

  onKeyUp = (evt) => {
    const value = evt.target.value;
    const path = `/inventory`;
    //clear filter when user presses esc key
    if (evt.which === 27) {
      this.setState({ value: "" });
    }

    // route to inventory view on enter
    if (evt.which === 13) {
      try {
        if (value && (value.trim().length > 2 || value.trim().length === 0)) {
          this.props.setSearchTerm(value);
          // setTimeout(() => {
          //   this.props.setSearchTerm(value)
          // }, 150)
          this.props.history.push(path);
        }
      } catch (e) {}
    }

    if (evt.which === 13 && this.props.setBreadCrumbs) {
      this.props.setBreadCrumbs({
        name: "Inventory",
        link: path,
      });
    }
    if (this.props.onKeyUp) {
      this.props.onKeyUp(evt);
    }
  };

  render() {
    return (
      <div style={{marginBottom:'16px'}}>
        <label className="inventorySearch__popover--label" style={{margin:'8px'}} id="inventorySearchGlobalLabel" htmlFor="inventorySearchGlobal">
          {
            // Search computer systems(Enter minimum 3 characters)
            Resource.get("Search computer systems (Minimum 3 characters)")
          }
        </label>

        <div className="inventorySearch__popover--contents" style={{margin:'8px'}}>
          <TextField variant="standard"  id="inventorySearchGlobal" labelId="inventorySearchGlobalLabel"
            classes={{ root: "inventorySearch__popover--textField" }}
            name="q"
            autoFocus inputProps={{'aria-label':this.props.screenReaderLabel}}
            value={this.state.value}
            onChange={this.handleChange}
            onKeyUp={(evt) => this.onKeyUp(evt)}
            InputProps={{
           
            
           endAdornment: (
             <InputAdornment position="end" >
                <Tooltip title={"Search"}>
                 <SearchIcon  className="selecticon" style={{ height: 16 }} />
                 </Tooltip>
             </InputAdornment>
           )}}
          />
         
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  entityFilter: state?.inventoryEntityFilter.quickFilter,
});

export default withRouter(connect(mapStateToProps)(InventorySearch));
