import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import {
  Grid, MoreIcon
} from "../ui-components/uim-components";
import {Drawer, Menu, MenuItem, Divider, IconButton} from "@mineral/core";
import each from "lodash/each";
import DetailPopUp from "./../groups/detailPopUp/DetailPopUp";
import {sortColumns} from "../../utils/tableUtils";
import rowSelectionCache from "../../utils/rowSelectionCache";
import Resource from "./Resource";
import MaintenaneDialogRedux from "./MaintenaneDialogRedux";

import { UpdateDevices } from './components/UpdateDevices';
import { Alert } from '@mineral/core';
import "./table.less";
import { defaultFiltersActiveObjects } from './filter/DefaultFilters'
import TableFilters from './filter/TableFilters'
let tabForward;
let tabBackward;
function getAallFocussableElementsOf(el) {
  return Array.from(
    el.querySelectorAll(
      'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
    )
  ).filter((el) => {
    return el.tabIndex !== -1;
  });
}
export const SCHEDULE_ID_RESOURCE = Resource.get("Schedule"); //Schedule
const NOVALUE= Resource.get("No Value"); //novalue
const actionMenuText = Resource.get("Action Menu"); //Action Menu
class Table extends Component {
  constructor(props) {
    super(props);
    this.pageLimit = 15;
    this.numPages = 0;
    this.groupPage = 0;
    this.updateRowSelectionCache = true;
    this.state = {
      showAddDevicesDialog: false,
      sortedData: this.props.maintenanceItems,
      scheduleDevices: this.props.scheduleDevices,
      groupDevices: this.props.groupDevices,
      pageNumber: 0,
      showDialog: false,
      device: null,
      viewConformation: false,
      deletedRowId: "",
      showUpdateDevicesDrawer: false,
      tooltipShowDelay: 0,
      overlayNoRowsDisplay:
        '<div><div class="nohubs__contentImg"></div><span style="color: #333333;font-family: Roboto;font-size: 1.25rem;" role="alert">No schedules are defined.</span></div>',
      menuAnchorEl: null,
      alert: null,
      lastActionMenuClicked:null
    };
    this.hideCheckboxes = false;
    if (
      (!this.props.saas && this.props.inventorySearch) ||
      !this.props.canEditMaintMode
    ) {
      this.hideCheckboxes = true;
    }
    this.groupCols = [
      // empty column
      // {
      //   headerName: "",
      //   field: " ",
      //   suppressResize: true,
      //   width: 50,
      //   suppressMovable: true,
      //   suppressSizeToFit: true,
      //   sortable: false,
      // },
      {
        //Name
        headerName: Resource.get("Name"),
        //Maintenance Name
        headerTooltip: Resource.get("Maintenance Name"),
        field: "schedule_name",
        tooltipField: "name",
        minWidth: 50,
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains", "startsWith", "endsWith", "equals"],
          suppressAndOrCondition: true,
          newRowsAction: "keep",
          clearButton: true,
        },
        resizable: true,
        suppressCellFocus:true,
        cellRendererFramework: (params) => {
          return ( <p role="tooltip" style={{margin: 0, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}} aria-label={params.data?.schedule_name ? params.data.schedule_name : NOVALUE}>
             {params.data?.schedule_name ? params.data.schedule_name : ''}
           </p>                       
           )
         },
        sortable: true,
        headerComponentParams: {
          enableMenu: false,
        },
        suppressNavigable:true
      },
      {
        //Description
        headerName: Resource.get("Description"),
        //Maintenance Description
        headerTooltip: Resource.get("Maintenance Description"),
        field: "description",
        tooltipField: "name",
        minWidth: 50,
        sortable: true,
        resizable: true,
        suppressCellFocus:true,
        cellRendererFramework: (params) => {
          return ( <p role="tooltip" style={{margin: 0, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}  aria-label={params.data?.description && params.data?.description!=="" ? params.data.description : NOVALUE}>
             {params.data?.description ? params.data.description : ''}
           </p>                       
           )
         },
         suppressNavigable:true
      },
      {
        //Schedules
        headerName: Resource.get("Schedule Details"),
        //Maintenance Schedule Details
        headerTooltip: Resource.get("Maintenance Schedule Details"),
        field: "message",
        tooltipField: "message",
        minWidth: 50,
        resizable: true,
        sortable: true,
        suppressCellFocus:true,
        cellRendererFramework: (params) => {
          return ( <p role="tooltip" style={{margin: 0, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}} aria-label={params.data?.message ? params.data.message : NOVALUE}>
             {params.data?.message ? params.data.message : ''}
           </p>                       
           )
         },
         suppressNavigable:true
      },
      {
        //Status
        headerName: Resource.get("Status"),
        //Maintenance Status
        headerTooltip: Resource.get("Maintenance Status"),
        field: "status",
        tooltipField: "status",
        minWidth: 50,
        filter: "customSetFilter",
        resizable: true,
        filterParams: {
          values: ["Active", "Inactive", "Empty"],
          newRowsAction: "keep",
        },
        cellRendererFramework: (params) => {
          return ( <p style={{margin: 0, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
             {params.data?.status ? params.data.status : ''}
           </p>                       
           )
         },
        sortable: true,
        suppressCellFocus:true,
        headerComponentParams: {
          enableMenu: false,
        },
        suppressNavigable:true
      },
      {
        //actions
        headerName: Resource.get("Actions"),
        //Maintenance Actions
        headerTooltip: Resource.get("Maintenance Actions"),
        field: "ACTION",
        headerClass: "center-header-vertically",
        cellClass: "center-cell-vertically",
        // resizable: true,
        cellRendererFramework: (model) => {
          const item = model.data;
          return (
            <div style={{ display: "flex" ,padding:'5px'}}>
              <IconButton id={`MaintenanceActionMenu_${model?.node?.rowIndex}`} style={{padding:'0px'}}
                aria-controls="action-menu-maintenance"
                aria-haspopup="true"
                aria-label={actionMenuText}
                onClick={(event)=>{
                  this.handleMenuClick(event,model)}}
                
              >
                <MoreIcon/>
              </IconButton>
              </div>
           
          );
        },
        suppressResize: true,
        suppressCellFocus:false,
        width: 100,
        suppressMovable: true,
        suppressSizeToFit: true,
        suppressSorting: true,
        hide: !(
          this.props.canEditMaintMode || this.props.canEditMaintModeDevice
        ),
        
      },
    ];
  }

  handleMenuClick = (event,row) => {
    this.handleDrillDown(row);
    this.setState({menuAnchorEl: event.currentTarget,lastActionMenuClicked:event.currentTarget.id});
  };
  handleActionMenuClose = () => {
    this.setState({menuAnchorEl: null});
  };

  handleEditGroup = () => {
    let data = this.selectedRowData.data;
    this.handleAddDevices("Edit");
    this.handleActionMenuClose();
  };
  handleDeleteConfirm = (data) => {
    this.handleAddDevices("Delete", data);
  };

  handledeleteMaintenance = () => {
    let data = this.selectedRowData.data;
    this.handleDeleteConfirm(data);
    this.handleActionMenuClose();
  };
  handledeleteMaintenanceSystems = (data) => {
    this.handleAddDevices("DeleteMaintenance", data);
  };
  handledeleteMaintenanceSystem = () => {
    let data2 = this.selectedRowData.data;
    this.handledeleteMaintenanceSystems(data2);
    this.handleActionMenuClose();
  };
  handleEndMaintenancesData = (data) => {
    this.handleAddDevices("EndMaintenance", data);
  };
  handleendMaintenance = () => {
    let dataEndMaintenance = this.selectedRowData.data;
    this.handleEndMaintenancesData(dataEndMaintenance);
    this.handleActionMenuClose();
  };
  handleUpdateDevices = () => {
    this.handleActionMenuClose();
    this.props.getDevicesBySchedule(this.selectedRowData.data.schedule_id);
    if (this.props.selectedGroupId != undefined) {
      this.props.getDevicesByGroup(this.props.selectedGroupId);
    }

    this.setState((prevState) => ({
      showUpdateDevicesDrawer: !prevState.showUpdateDevicesDrawer,
    }));
  };

  componentDidMount() {
    window.addEventListener("mouseover", (e) => this.mouseOverClassAddition(e));
    window.addEventListener("mouseout", (e) => this.mouseOutClassDeletion(e));
    window.addEventListener("beforeunload", this.componentCleanup);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.columnDefs !== this.props.columnDefs) {
      if (this.api) {
        // Added condition for DE457904
        this.api.sizeColumnsToFit();
      }
    }
    if (
      this.api &&
      prevProps.visibleDataCount !== this.api.rowModel.rowsToDisplay.length &&
      this.props.handleColumnFilterChange
    ) {
      this.props.handleColumnFilterChange(
        this.api.rowModel.rowsToDisplay.length
      );
    }
  }

  componentCleanup = (onactualUnmount) => {
    rowSelectionCache.clear();
    if (this.props.handleCsRowsSelected) {
      const ids = rowSelectionCache.getData();
      this.props.handleCsRowsSelected(ids);
    }
    window.removeEventListener("beforeunload", this.componentCleanup);
    if (!onactualUnmount) {
      let mountNode = React.findDOMNode(this.refs.viewref);
      if (mountNode) {
        let unmount = React.unmountComponentAtNode(mountNode);
      }
    }
  };

  componentWillReceiveProps(nextProps) {
    this.setState({
      sortedData: nextProps.maintenanceItems,
      pageNumber: 0,
      scheduleDevices: nextProps.scheduleDevices,
      groupDevices: nextProps.groupDevices,
    });

    setTimeout(() => {
      if (nextProps.tableWidth) {
        if (this.api) {
          this.api.sizeColumnsToFit();
        }
      }
    }, 100);
  }

  componentWillUnmount() {
    this.componentCleanup(true);
  }

  handleDrillDown = (row) => {
    this.selectedRowData = row;
  };
  handleAddDevicesClose = () => {
    this.setState({showAddDevicesDialog: false}, function() {
      setTimeout(()=>{ document.getElementById(this.state.lastActionMenuClicked)?.focus();},500)
     
    });
    
  };
  handleExitClickFlag = (element) => {
    this.setState({
      showDialog: false,
    });
  };
  handlePageClick = (page) => {
    this.setState({
      pageNumber: page.selected,
    });
  };
  handleSetDialogFlag = (element, row) => {
    let device = row.data;
    this.setState({
      showDialog: true,
      device: device,
    });
  };

  getColDefs(props) {
    return this.groupCols;
  }

  mouseOverClassAddition(e) {
    if (e.target && e.target.closest(".ag-cell")) {
      let hoveredCell = e.target.closest(".ag-cell");
      if (e.target.closest(".ag-row")) {
        let children =
          e.target.closest(".ag-row").attributes.class.ownerElement.childNodes;
        if (hoveredCell.getAttribute("col-id") === "maxAlarmSeverity.value") {
          each(children, (child) => {
            if (child.getAttribute("col-id") === "maxAlarmSeverity.value") {
              child.className += " grid__row--hover";
            } else if (
              child.getAttribute("col-id") === "checkbox" &&
              this.hideCheckboxes
            ) {
              child.className += " grid__row--hover";
            }
          });
        } else if (hoveredCell.getAttribute("col-id") === " ") {
          each(children, (child) => {
            if (child.getAttribute("col-id") === " ") {
              child.className += " grid__row--hover";
            }
          });
        } else if (
          hoveredCell.getAttribute("col-id") === "checkbox" &&
          this.hideCheckboxes
        ) {
          each(children, (child) => {
            if (
              child.getAttribute("col-id") === "maxAlarmSeverity.value" ||
              child.getAttribute("col-id") === "checkbox"
            ) {
              child.className += " grid__row--hover";
            }
          });
        } else {
          each(children, (child) => {
            if (
              child.getAttribute("col-id") !== "maxAlarmSeverity.value" &&
              child.getAttribute("col-id") !== " "
            ) {
              if (
                this.hideCheckboxes &&
                child.getAttribute("col-id") === "checkbox"
              ) {
                return;
              } else {
                child.className += " grid__row--hover";
              }
            }
          });
        }
      }
    }
  }

  mouseOutClassDeletion(e) {
    if (e.target && e.target.closest(".ag-cell")) {
      if (e.target.closest(".ag-row")) {
        let children =
          e.target.closest(".ag-row").attributes.class.ownerElement.childNodes;
        each(children, (child) => {
          child.className = child.className.replace(" grid__row--hover", "");
        });
      }
    }
  }

  onGridSizeChanged = (params) => {
    if (this.api) {
      this.api.sizeColumnsToFit();
    }
  };

  onGridReady(params) {
    this.api = params.api;
    this.api.sizeColumnsToFit();
    this.api.setGridAriaProperty('label', "Maintenance Schedules listing name, description, details, status")
    if (this.props.onGridReady) {
      this.props.onGridReady(params);
    }
    setTimeout(
      function () {
        if (
          this.api &&
          this.props.visibleDataCount !==
          this.api.rowModel.rowsToDisplay.length &&
          this.props.handleColumnFilterChange
        ) {
          this.props.handleColumnFilterChange(
            this.api.rowModel.rowsToDisplay.length
          );
        }
      }.bind(this),
      0
    );

    this.updateRowSelection();
  }

  rowDataChanged = () => {
    this.updateRowSelection();
  };

  filterChanged1 = (params) => {
    let model={};
    params.colFilters.map((filter)=>{
      if(filter.filterType==="set")
      {
        model[filter.filterColumn]={
          values:filter.filterValue?.split(','),
          filterType: filter.filterType,
          }

      }else if(filter.filterType=='text')
      {
      model[filter.filterColumn]={
      filter:filter.filterValue,
      filterType: filter.filterType,
      type: filter.filterOperator}
      }

    })

    this.api.setFilterModel(model);

  }
  filterChanged = (params) => {
    this.api = params.api;
    if (this.api.rowModel.rowsToDisplay.length === 0) {
      this.api.showNoRowsOverlay();
    } else {
      this.api.hideOverlay();
    }
    if (
      this.api &&
      this.props.visibleDataCount !== this.api.rowModel.rowsToDisplay.length &&
      this.props.handleColumnFilterChange
    ) {
      this.props.handleColumnFilterChange(
        this.api.rowModel.rowsToDisplay.length
      );
    }
  };

  getNoRowInfo = () => {
    let noDataMsg = Resource.get("No Data Found"); //No Data Found
    let classNames = ["setup-wizard__localprobes__info"];
    return (
      <div style={{position: "relative"}} className={classNames.join(" ")}>
        <div
          style={{
            width: "calc(100% - 60px)",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          <div className="nohubs__contentImg"></div>
          <span className="setup-wizard__localprobes__content">
            <span
              style={{
                padding: "2px 13px 2px 20px",
                borderRight: "2px solid #999999",
              }}
            >
              {noDataMsg}
            </span>
          </span>
        </div>
      </div>
    );
  };

  updateRowSelection = () => {
    setTimeout(
      function () {
        if (this.api) {
          this.updateRowSelectionCache = false;
          rowSelectionCache.updateOnRowDataChanged(this.api);
          this.updateRowSelectionCache = true;
          if (this.api.rowModel.rowsToDisplay.length === 0) {
            this.api.showNoRowsOverlay();
          } else {
            this.api.hideOverlay();
          }
        }
      }.bind(this),
      0
    );
  };
  selectionChanged = () => {
    if (this.updateRowSelectionCache && this.api) {
      rowSelectionCache.onChange(this.api);
    }
    if (this.props.handleCsRowsSelected) {
      const ids = rowSelectionCache.getData();
      this.props.handleCsRowsSelected(ids);
    }
  };
  sortColumns = (column, order) => {
    const newSortedData = sortColumns(column, order, this.state.sortedData);
    this.setState({
      sortedData: newSortedData,
      pageNumber: 0,
    });

    this.updateRowSelection();
  };
  handleAddDevices = (value, data) => {
    this.setState({
      showAddDevicesDialog: true,
      viewConformation: value,
      deletedRowId: data,
    });
  };
  
  onSuppressKeyboardEvent=(params)=> {
    let key = params.event.key;
    let shiftKey = params.event.shiftKey;
    let isTabbingForward = key === 'Tab' && shiftKey === false;
    let isTabbingBackWards = key === 'Tab' && shiftKey === true;

    // Handle cell children tabbing
    if (isTabbingForward || isTabbingBackWards) {
      tabForward = isTabbingForward;
      tabBackward = isTabbingBackWards;

      if (params.editing) {
        return false;
      }

      let eGridCell = params.event.path.find((el) => {
        if (el.classList === undefined) return false;
        return el.classList.contains('ag-cell');
      });

      let focusableChildrenElements = getAallFocussableElementsOf(eGridCell);
      let lastCellChildEl =
        focusableChildrenElements[focusableChildrenElements.length - 1];
      let firstCellChildEl = focusableChildrenElements[0];

      //  FORWARD
      // allow to movee to next cell when the cells last element is focused
      if (isTabbingForward && focusableChildrenElements.length > 0) {
        let isLastChildFocused =
          lastCellChildEl && document.activeElement === lastCellChildEl;

        if (isLastChildFocused === false) {
          return true;
        }
      }

      //  BACKWARDS
      //  focus last element if none are focused
      //  allow to tab backwards when first element is focused
      if (isTabbingBackWards && focusableChildrenElements.length > 0) {
        let cellHasFocusedChildren =
          eGridCell.contains(document.activeElement) &&
          eGridCell !== document.activeElement;

        if (!cellHasFocusedChildren) {
          params.event.preventDefault();
          lastCellChildEl.focus();
        }

        let isFirstChildFocused =
          firstCellChildEl && document.activeElement === firstCellChildEl;

        if (isFirstChildFocused === false) {
          return true;
        }
      }
    }

    return false;
  }
  onSuppressHeaderKeyboardEvent=(params) =>{
    let key = params.event.key;
    let shiftKey = params.event.shiftKey;
    let isTabbingForward = key === 'Tab' && shiftKey === false;
    let isTabbingBackWards = key === 'Tab' && shiftKey === true;
    // Handle cell children tabbing

    if (isTabbingForward || isTabbingBackWards) {
      let eGridCell = params.event.path.find((el) => {
        if (el.classList === undefined) return false;
        return el.classList.contains('ag-header-cell');
      });

      let focusableChildrenElements = getAallFocussableElementsOf(eGridCell);
      let lastCellChildEl =
        focusableChildrenElements[focusableChildrenElements.length - 1];
      let firstCellChildEl = focusableChildrenElements[0];

      //  FORWARD
      // allow to movee to next cell when the cells last element is focused
      if (isTabbingForward && focusableChildrenElements.length > 0) {
        let isLastChildFocused =
          lastCellChildEl && document.activeElement === lastCellChildEl;

        if (isLastChildFocused === false) {
          return true;
        }
      }

      //  BACKWARDS
      //  focus last element if none are focused
      //  allow to tab backwards when first element is focused
      if (isTabbingBackWards && focusableChildrenElements.length > 0) {
        let cellHasFocusedChildren =
          eGridCell.contains(document.activeElement) &&
          eGridCell !== document.activeElement;
        if (!cellHasFocusedChildren) {
          params.event.preventDefault();

          lastCellChildEl.focus();
        }

        let isFirstChildFocused =
          firstCellChildEl && document.activeElement === firstCellChildEl;

        if (isFirstChildFocused === false) {
          return true;
        }
      }
    }

    return false;
  }
  onCellFocused=(params)=> {
    if (tabForward) {
      let cell = document.activeElement;
      let focusableChildren = getAallFocussableElementsOf(cell);

      if (focusableChildren.length > 0) {
        focusableChildren[0].focus();
      }

      tabForward = false;
    }

    if (tabBackward) {
      let cell = document.activeElement;
      let focusableChildren = getAallFocussableElementsOf(cell);

      if (focusableChildren.length > 0) {
        focusableChildren[focusableChildren.length - 1].focus();
      }

      tabBackward = false;
    }
  }

   getDataCount = (data) =>{
    return data?.length || 0;
  }
  handleAlertClose = () => this.setState({ alert: null })

  handleFinish = (type, value) => {
    const alerts = {
      success: 'Device(s) updated successfully for the',
      error: 'Something went wrong',
    }

    this.setState({ alert: { type, text: alerts[type], value}, showUpdateDevicesDrawer: false})

    setTimeout(this.handleAlertClose, 5000);
  }
  
  render() {
  const { alert } = this.state;
    let view;
    const headerHeight = 36;
    const rowHeight = 48;

    let confirmationMessage = Resource.get("Remove this credential?"); //Remove this credential?
    //Edit Maintenance
    let editMaintenanceText = Resource.get("Edit Maintenance");
    //Delete Maintenance
    let delMaintenanceText = Resource.get("Delete Maintenance");
    //Remove Maintenance
    let removeMaintennace = Resource.get("Remove Maintenance System");
    //End Maintenance'
    let endMaintenance = Resource.get("End Maintenance");
    //Update Devices
    let updateDevicesText = Resource.get("Update Devices");
    // Schedules
    let SCHEDULES = Resource.get("Schedules");
    let viewGrid = (
      <span style={{fontSize: "14px", marginRight: "15px"}}>
              {confirmationMessage}
            </span>
    );

    let dialog = (
      <MaintenaneDialogRedux
        open={this.state.showAddDevicesDialog}
        handleClose={this.handleAddDevicesClose}
        selectedId={this.props.selectedId}
        handleErrorDialogOpen={this.handleErrorDialogOpenAddDevices}
        handleData={this.state.viewConformation}
        entities={this.selectedRowData}
        deletedRowId={this.state.deletedRowId}
      />
    );

    let rowData = this.state.sortedData ? this.state.sortedData : [];
    let onSelectionChanged = null;
    let rowSelection = "single";

    if (this.props.columnDefs === "csCols") {
      onSelectionChanged = this.selectionChanged;
      rowSelection = "multiple";
    }
    view = (
      <div ref="viewref" className="maintenance-table-content">
      <div className="maintenence_table_header" >
       <TableFilters
              defaultFilters={defaultFiltersActiveObjects(
                this.getColDefs()
               
              )}
              handleUpdateFilters={this.filterChanged1.bind(this)}
              //searchResultsNumber={this.api?.getDisplayedRowCount()}
             
            />
         </div>
        <div
          className="ag-material maintenance-tablecontainer"
          style={{
            height: `${this.props.fromTreeView ? "calc(100vh - 280px)" : "75vh"}`,
            // width: `${this.props.tableWidth ? this.props.tableWidth : "94vw"}`,
            marginLeft: "1px",
          }}
       
          aria-label={SCHEDULES}
          
        >
          <Grid
            {...this.props}
            headerHeight={headerHeight}
            rowHeight={rowHeight}
            rowData={rowData}
            columnDefs={this.getColDefs()}
            accentedSort={true}
            //onRowClicked={this.handleDrillDown}

            onGridReady={this.onGridReady.bind(this)}
            onGridSizeChanged={this.onGridSizeChanged}
            overlayNoRowsTemplate={this.state.overlayNoRowsDisplay}
           // onSelectionChanged={onSelectionChanged}
            onFilterChanged={this.filterChanged.bind(this)}
           // onRowDataChanged={this.rowDataChanged}
          //  rowSelection={rowSelection}
          suppressRowHoverClass={true}
            suppressRowClickSelection={true}
            tooltipShowDelay={this.state.tooltipShowDelay}
            suppressHorizontalScroll={false}
            onCellFocused={this.onCellFocused.bind(this)}
                         // onCellKeyDown={this.onCellKeyDown.bind(this)}
                          suppressCellFocus={true}
                          suppressKeyboardEvent= {this.onSuppressKeyboardEvent}
                          suppressHeaderKeyboardEvent= {this.onSuppressHeaderKeyboardEvent}
          />
        </div>
        <div className="pagination">
          <DetailPopUp
            entity={this.state.device}
            showDialog={this.state.showDialog}
            handleExitClickFlag={this.handleExitClickFlag}
            featureflag={this.props.featureFlags}
            saas={this.props.saas}
            doiurl={this.props.doiurl}
          />
        </div>
        {dialog}
        <Drawer anchor='right'  open={this.state.showUpdateDevicesDrawer}>
          <UpdateDevices
            schedule={this.state.scheduleDevices}
            group={this.state.groupDevices}
            data={this.selectedRowData?.data && this.selectedRowData?.data}
            onFinish={this.handleFinish}
            onUpdate={this.handleUpdateDevices}
          />
        </Drawer>
        <div>
          <Menu
            className="menuStyle"
            id="action-menu-maintenance"
            anchorEl={this.state.menuAnchorEl}
            keepMounted
            onClose={this.handleActionMenuClose}
            open={this.state.menuAnchorEl != null}
            PaperProps={{
              style: {
                //maxHeight: '754px',
                width: '280px',
                padding: '8px 0px',
                display: 'table-cell'
              },
            }}
            inputProps={{"aria-label": actionMenuText}}
          >
           
            {this.props.canEditMaintMode ? (
              <MenuItem inputProps={{autoFocus:this.props.canEditMaintMode?true:false}}
                onClick={this.handleEditGroup}
                children={editMaintenanceText}
              />
            ) : null}
            {this.props.canEditMaintMode ? (
              <MenuItem
                onClick={this.handledeleteMaintenance}
                children={delMaintenanceText}
              />
            ) : null}
            {this.props.canEditMaintModeDevice ? (
              <MenuItem  inputProps={{autoFocus:!this.props.canEditMaintMode && this.props.canEditMaintModeDevice?true:false}}
                onClick={this.handledeleteMaintenanceSystem}
                children={removeMaintennace}
              />
            ) : null}
            {this.props.canEditMaintMode ? (
              <MenuItem
                onClick={this.handleendMaintenance}
                children={endMaintenance}
              />
            ) : null}
            {this.props.canEditMaintModeDevice ? (
              <MenuItem
                onClick={this.handleUpdateDevices}
                children={updateDevicesText}
              />
            ) : null}
          </Menu>
        </div>
        { alert && 
          <Alert className="devices-alert" severity={alert.type} onClose={this.handleAlertClose}>
           { alert.text }
           { alert.value && <span> {alert.value}</span> }
          </Alert> 
        }
      </div>
    );
    return view;
  }
}

export default withRouter(Table);
