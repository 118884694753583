export const REQUEST_GROUPS = 'REQUEST_GROUPS'
export const FETCH_GROUPS_DASHBOARD_SUCCESS = 'FETCH_GROUPS_DASHBOARD_SUCCESS'
export const FETCH_GROUPS_DASHBOARD_FAILURE = 'FETCH_GROUPS_DASHBOARD_FAILURE'

export const REQUEST_GROUP_PROFILES = 'REQUEST_GROUP_PROFILES'
export const FETCH_GROUP_PROFILES_DASHBOARD_SUCCESS = 'FETCH_GROUP_PROFILES_DASHBOARD_SUCCESS'
export const FETCH_GROUP_PROFILES_DASHBOARD_FAILURE = 'FETCH_GROUP_PROFILES_DASHBOARD_FAILURE'
	
export const REQUEST_GROUP_DEVICES = 'REQUEST_GROUP_DEVICES'
export const FETCH_GROUP_DEVICES_DASHBOARD_SUCCESS = 'FETCH_GROUP_DEVICES_DASHBOARD_SUCCESS'
export const FETCH_GROUP_DEVICES_DASHBOARD_FAILURE = 'FETCH_GROUP_DEVICES_DASHBOARD_FAILURE'
	

export const REQUEST_DEVICE_BY_DEVICEID = 'REQUEST_DEVICE_BY_DEVICEID'
export const FETCH_DEVICE_BY_DEVICEID_DASHBOARD_SUCCESS = 'FETCH_DEVICE_BY_DEVICEID_DASHBOARD_SUCCESS'
export const FETCH_DEVICE_BY_DEVICEID_DASHBOARD_FAILURE = 'FETCH_DEVICE_BY_DEVICEID_DASHBOARD_FAILURE'



