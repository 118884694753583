import React from 'react'
import GridIcon from '../../../assets/icons/grid-icon.svg'
import EndUserMon from '../../../assets/icons/endUserMon'
import AppMon from '../../../assets/icons/appMon'
import DoiMon from '../../../assets/icons/doiicon'
import SsdMon from '../../../assets/icons/ssdicon'
import NetopsMon from '../../../assets/icons/netopsicon'
import InfraMon from '../../../assets/icons/infrastructureMon'
import CloudManagement from '../../icons/CloudManagement'
import MenuItemRedux from './MenuItemRedux'
import Resource from './Resource'
import './globalMenu.less'

const Menu = ({ productList, open, handleCloseMenu, openMenu }) => {
  const getMenuItem = (appId, endpointUrl) => {
    let menuItem
    switch (appId) {
      case 'AXA':
      menuItem = (
        <MenuItemRedux
        //App Experience Analytics
        label= {Resource.get('App Experience Analytics')}
        icon={EndUserMon}
        key="AXA"
        appId={appId}
        endpointUrl={endpointUrl}
        />
        )
        break
        case 'UIM':
        menuItem = (
          <MenuItemRedux
          //Infrastructure Management
          label= {Resource.get('Infrastructure Management')}
          icon={InfraMon}
          key="UIM"
          appId={appId}
          endpointUrl={endpointUrl}
          selected={true}
          />
          )
          break
          case 'APM':
          menuItem = (
            <MenuItemRedux
            //Application Performance Management
            label= {Resource.get('Application Performance Management')}
            icon={AppMon}
            key="APM"
            appId={appId}
            endpointUrl={endpointUrl}
            />
            )
            break
            case 'DOI':
            menuItem = (
              <MenuItemRedux
              //Digital Operational Intelligence
              label= {Resource.get('Digital Operational Intelligence')}
              icon={DoiMon}
              key="DOI"
              appId={appId}
              endpointUrl={endpointUrl}
              />
              )
              break
              case 'CLOUD_MANAGEMENT':
              menuItem = (
                <MenuItemRedux
                //Launch Pad
                label= {Resource.get('Launch Pad')}
                icon={CloudManagement}
                key="CLOUD_MANAGEMENT"
                appId={appId}
                endpointUrl={endpointUrl}
                />
                )
                break
                case 'SDD':
                menuItem = (
                  <MenuItemRedux
                  //Single Sign on Dashboards
                  label= {Resource.get('Single Sign on Dashboards')}
                  icon={SsdMon}
                  key="SSD"
                  appId={appId}
                  endpointUrl={endpointUrl}
                  />
                  )
                  break
                  case 'NETOPS':
                  menuItem = (
                    <MenuItemRedux
                    //Single Sign on Dashboards
                    label= {Resource.get('Network Operations Analytics')}
                    icon={NetopsMon}
                    key="NETOPS"
                    appId={appId}
                    endpointUrl={endpointUrl}
                    />
                    )
                    break    
                    default:
                    break
                  }
                  return menuItem
                }
                
                let menuItems = productList
                .filter(
                  product => product.status === 'PROVISIONED' && !product.maintenanceModeEnabled && product.name !== 'DATA_STUDIO',
                  )
                  .map(product => {
                    return getMenuItem(product.name, product.axaTokenRedirectUrl)
                  })
                  let menuClass = 'nav-popover-content-container'
                  if (open) {
                    menuClass += ' open'
                  }
                  return (
                    <div className="nav-popover-container">
                    <div className="nav-popover-icon-container" onClick={openMenu}>
                    <img src={GridIcon} role="figure" />
                    </div>
                    <div className={menuClass}>
                    <div className="nav-popover-inner-content-container">
                    <div className="nav-popover-content">
                    <div className="menu-items-container">
                    <ul className="menu-items-list">{menuItems}</ul>
                    </div>
                    </div>
                    <div className="nav-popover-icon-container" onClick={handleCloseMenu}>
                    <img src={GridIcon} role="figure" />
                    </div>
                    </div>
                    </div>
                    </div>
                    )
                  }
                  
                  export default Menu
                  