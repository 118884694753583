// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //System update available
  content["System update available"] =
  "시스템 업데이트 있음";

  //A new version of the robot software is available.
  content["A new version of the robot software is available."] =
  "새 버전의 로봇 소프트웨어를 사용할 수 있습니다.";

  //Deployment takes about 1 minute, during which time no metrics are collected and no alarms are created.
  content["Deployment takes about 1 minute, during which time no metrics are collected and no alarms are created."] =
  "배포는 약 1분이 걸리며 이 시간 동안에는 메트릭이 수집되지 않으며 경보가 생성되지 않습니다.";

  //LATER
  content["LATER"] =
  "나중에";

  //DEPLOY
  content["DEPLOY"] =
  "배포";

  // END OF LOCALIZATION

export default content;
