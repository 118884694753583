// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //For more information, click
  content["For more information, click "] =
  "詳細については、次をクリック してください ";

  // console.log(this.props);
  content["Default system monitoring for CPU, disk & memory will be applied"] =
  "CPU、ディスク、およびメモリのデフォルト システム モニタリングが適用されます";

  //Automatically monitor
  content["Automatically monitor "] =
  "次を自動的にモニタします: ";

  // devices
  content[" devices"] =
  " デバイス";

  //HELP
  content["HELP"] =
  "ヘルプ";

  // END OF LOCALIZATION

export default content;
