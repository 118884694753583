import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import config from "../../api/config";
import { fetchComputerSystem } from "./../../api/computer-systems/actions";
// import MetricPalette from 'metric-palette'
import MetricPalette from "../metricpalettecode/MetricPalette";

import axios from "axios";

const formatMetricType = (item, isDuplicate) => {
  let name = isDuplicate
    ? `${item.metricDefinition.descriptionShort}`
    : `${item.metricDefinition.descriptionShort} (${item.ciName})`;
  return {
    csId: item.csId,
    id: item.metId,
    ciName: item.ciName,
    descriptionShort: item.metricDefinition.descriptionShort,
    name,
    target: item.target,
    qosName: item.qosName,
    unit: item.metricDefinition.unit,
    unitShort: item.metricDefinition.unitShort,
    metricType: item.metricType,
    description: item.metricDefinition.description,
  };
};

const BASELINES = {
  DatabaseServer: [
    "4.2:10",
    "4.2:15",
    "4.2:20",
    "4.2:4",
    "4.1:12",
    "4.1:50",
    "4.1:7",
    "4.1:75",
    "4.5:15",
  ],
  WebServer: [
    "3.7:5",
    "3.7:3",
    "3.7:4",
    "3.5:5",
    "3.5:3",
    "3.5:6",
    "3.15:1",
    "3.15:3",
    "3.15:4",
    "3.15:8",
    "3.2:11",
    "1.6:1",
    "1.5:1",
    "1.1:2",
  ],
  vCenter: [
    "1.6:1",
    "1.5:1",
    "1.1:3",
    "1.16:11",
    "1.17.2:15",
    "1.17.1:13",
    "1.1:14",
    "1.6:2",
    "1.16:8",
  ],
  VirtualMachineHost: [
    "1.6:1",
    "1.6:2",
    "1.5:1",
    "1.1:3",
    "1.16:11",
    "1.17.2:15",
    "1.17.1:13",
  ],
  VirtualMachine: ["1.6:1", "1.5:1", "1.1:3", "1.6:29", "1.1:14", "1.6:2"],
  Host: ["1.6:1", "1.5:1", "1.1:3", "1.1:14", "1.6:2"],
  ApplicationSystem: ["1.6:1", "1.5:1", "1.1:3", "1.1:14", "1.6:2"],
  LoadBalancer: ["11.4:1", "11.2:22"],
  APIEndpoint: ["1.5:1", "1.6:2", "3.50.9:8"],
  Default: ["1.6:1", "1.5:1", "1.1:3", "1.1:14", "1.6:2"],
  Printer: ["1.6:1", "1.5:1", "1.1:3", "1.1:14", "1.6:2"],
  Storage: ["1.6:1", "1.5:1", "1.1:3", "1.1:14", "1.6:2"],
  Firewall: ["1.6:1", "1.5:1", "1.1:3", "1.1:14", "1.6:2"],
  WirelessAccessPoint: ["1.6:1", "1.5:1", "1.1:3", "1.1:14", "1.6:2"],
  SwitchRouter: ["1.6:1", "1.5:1", "1.1:3", "1.1:14", "1.6:2"],
  Router: ["1.6:1", "1.5:1", "1.1:3", "1.1:14", "1.6:2"],
  Switch: ["1.6:1", "1.5:1", "1.1:3", "1.1:14", "1.6:2"],
};

const getBaseline = (role) => {
  return BASELINES[role] || BASELINES["Default"];
};

const fetchMetrics = (contextValue, metricType, options) => {
  const url = `${config.basename}/api/v1/metrics?metricId=${metricType}&includeSamples=true&timeDuration=${options.timerange}`;
  //const url = `${config.basename}/metrics/metrics`
  return axios
    .get(url)
    .then((response) => response.data)
    .then((data) => {
      let itemIndex = data._items.findIndex((item) => item.samples.length); // Loop over and get the index where sample values present in the response
      const item = data._items[itemIndex] || data._items[0];
      const items =
        item.samples &&
        item.samples
          .map((sample) => {
            return {
              epoch: sample.timeSinceEpoch * 1000,
              time: sample.time,
              value: parseInt(sample.value, 10),
            };
          })
          .sort((a, b) => {
            return a.epoch - b.epoch;
          });

      return {
        contextValue,
        metricType,
        ciName: item.ciName,
        avg: item.meanSample,
        max: item.maximumSample,
        min: item.minimumSample,
        items,
      };
    });
};

const fetchMetricTypes = (contextValue) => {
  const url = `${config.basename}/api/v1/metrics?csId=${contextValue}&includeSamples=false`;
  //const url =`${config.basename}/metrics/definitions`
  return axios
    .get(url)
    .then((response) => response.data)
    .then((data) => {
      let groups = {};
      data._items.forEach((item) => {
        let group = groups[item.csId] || {};
        const isDuplicate =
          data._items.filter(({ metricDefinition }) => {
            return (
              metricDefinition.descriptionShort ===
              item.metricDefinition.descriptionShort
            );
          }).length !== 1;
        group[item.metId] = formatMetricType(item, isDuplicate);
        groups[item.csId] = group;
      });
      return groups;
    });
};

class MetricsRedux extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    this.props.getComputerSystem(this.props.csId);
  }
  render() {
    const {
      contextValue,
      fetchMetricTypes,
      name,
      computerName,
      groupName,
      fromGroup,
      sid,
    } = this.props;
    return (
      <MetricPalette
        name={name ? name : groupName ? groupName : computerName}
        contextValue={contextValue}
        getMetricTypes={fetchMetricTypes}
        getMetrics={fetchMetrics}
        devices={this.props.devices}
        fromGroup={
          (this.props.location.state && this.props.location.state.fromGroup) ||
          this.props.fromGroup
        }
        fromCsView={
          (this.props.location.state && this.props.location.state.fromCsView) ||
          this.props.csId
        }
        singleDevice={this.props.singleDevice}
        isTreeOpen={this.props.isTreeOpen}
        isFromTreeView={this.props.isFromTreeView}
        isInterface={this.props.isInterface}
        masterId={this.props.masterId}
        account={this.props.account}
        acl={this.props.acl}
        groupId={this.props.groupId}
        groupName={this.props.groupName}
        sid={sid}
      />
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getComputerSystem: (id) => dispatch(fetchComputerSystem({ id: id })),
  };
};
const mapStateToProps = (state, props) => {
  const entity = state.entities.find((entity) => entity.id == props.csId) || {};
  const role = entity.role;
  const computerSystem = state.computerSystems;
  const propsObj = {
    contextValue: props.csId,
  
    name: props.isInterface?(computerSystem ? (computerSystem.item? computerSystem.item.name : ""): "")+" interface: "+props.interfaceDisplayName:entity.name,   
    acl: state.acl,
    sid: state.sid,
    account: state.account,
    computerName: computerSystem
      ? computerSystem.item
        ? computerSystem.item.name
        : ""
      : "",

    fetchMetricTypes: (contextValue) => {
      return fetchMetricTypes(contextValue).then((response) => {
        const baseline = getBaseline(role);
        let root = response[contextValue];
        for (let key in root) {
          if (root.hasOwnProperty(key)) {
            const metricType = root[key].metricType;
            root[key].selected = baseline.indexOf(metricType) !== -1;
          }
        }
        return response;
      });
    },
  };

  if (props.isInterface!==true &&  Number(props.csId)) {
    propsObj["devices"] = [props.csId];
    propsObj["singleDevice"] = true;
    if (!propsObj.name && !propsObj.computerName) {
      let dvc =
        state.computerSystems &&
        state.computerSystems.item &&
        state.computerSystems.item._items &&
        state.computerSystems.item._items.length &&
        state.computerSystems.item._items.find((cs) => cs.id == props.csId);
      dvc = dvc ? (propsObj.name = dvc.name) : null;
    }
  } else if (props.isInterface===true || state.entity.typeName === "NetworkInterface") {
    propsObj["isInterface"] = true;
    propsObj["devices"] = [Number(props.masterId)];
    propsObj["singleDevice"] = true;
  } else if (
    (props.fromGroup && props.groupId >= 0) ||
    !state.selectedCsIds.length
  ) {
    propsObj["devices"] = state.entity.computerSystems;
    propsObj["groupId"] = props.groupId;
    propsObj["groupName"] = state.entity.name ? state.entity.name : "Root";
  } else if (state.selectedCsIds.length) {
    propsObj["devices"] = [state.selectedCsIds[0]];
    propsObj["singleDevice"] = true;
  }

  if (!propsObj["devices"]) {
    propsObj["devices"] = [props.csId];
  }
  return propsObj;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MetricsRedux));
