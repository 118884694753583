
import React, { Component,useRef,useImperativeHandle,forwardRef } from "react";

import {
  Box
} from "@mineral/core";
import get from "lodash/get";
import {
  Button
 } from "@mineral/core";


import AccessibleTooltip from './../common/AccessibleTooltip'
export default class MCSProfileNameColumnCellRenderer extends Component {
 

  handleTableClick() {
    this.props.context.handleTableClick(this.props);
  }


  render() {
    console.log(this)
    let model = this.props;
    let item = model != null && model != undefined ? model.data : null;

    let deviceName = '';
    let prfName = '';
    if (item != null && item !== undefined && item.deviceName != null && item.deviceName !== undefined && model.context != null && model.context !== undefined && model.context.state != null && model.context.state !== undefined && model.context.state.dashCardView === 'DASHCARDVIEW_DEVICES_PROFILES')
        deviceName = item.deviceName
    if (item != null && item !== undefined && item.profileName != null && item.profileName !== undefined)
        prfName = item.profileName
    let viewIn="";
    if(deviceName !== '') {
         
            viewIn=  prfName + `   (` + deviceName + `)`;
           
        
    } else {
         
            viewIn= prfName;
        
    }

    let view = (
      <div style={{ display: "flex" }} tabIndex={-1}>
       

        <Button role="link" disableRipple={true}
                className="mcsdashboard-transparent-btn"
                onClick={this.handleTableClick.bind(this)} aria-roledescription={"Click to view details"}
                
                >
                {/*<span style={{color:"#3272D9"}}> {this.props.data && this.props.data.name?this.props.data.name:""}</span>*/}
                <AccessibleTooltip title={viewIn?viewIn:""}>
      <Box component={'span'} style={{ overflow: 'hidden',color:"#3272D9", whiteSpace: 'nowrap', textOverflow: 'ellipsis' ,textTransform:'none'}}>
     { viewIn!==null && viewIn!==undefined?viewIn:''}
      </Box>
    </AccessibleTooltip>
                </Button>
        </div>
       )

    return view;
  }
}



