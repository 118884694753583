import * as React from 'react';
import { SvgIcon } from '@mineral/core';

export const FilledRhombus = React.forwardRef(
  (props, ref) => (
    <SvgIcon viewBox="0 0 40 40" {...props} ref={ref}>
      <path d="M10.5 20L19.9706 3.02944L29.4411 20L19.9706 36.9706L10.5 20Z" fill={props.color} />
    </SvgIcon>
  )
);

export default FilledRhombus;