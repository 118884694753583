import {
    SET_SID
} from './actionTypes'

export function setSid(sid) {
    return {
        type: SET_SID,
        sid: sid
    }
}
