// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //schedules
  content["Schedules"] =
  "Schedules";

  //New
  content["New"] =
  "New";

  // Hour value is invalid
  content["Hour value is invalid"] =
  "Hour value is invalid";

  // Minute value is invalid
  content["Minute value is invalid"] =
  "Minute value is invalid";

  //if(this.state.schedule_id.length!=0){
  //Duplicate Message
  content["The entered Schedule Name already exists"] =
  "The entered Schedule Name already exists";

  //Error Adding Maintenance
  content["Error Adding Maintenance"] =
  "Error Adding Maintenance";

  //Error editing  Maintenance
  content["Error editing  Maintenance"] =
  "Error editing  Maintenance";

  //Error Deleting Maintenance
  content["Error Deleting Maintenace"] =
  "Error Deleting Maintenace";

  //Error Deleting Maintenance System
  content["Error Deleting Maintenance System"] =
  "Error Deleting Maintenance System";

  //Error Deleting End Maintenance
  content["Error Deleting End Maintenance"] =
  "Error Deleting End Maintenance";

  //End Date
  content["No End Date"] =
  "No End Date";

  //Cancel
  content["Cancel"] =
  "Cancel";

  //Delete the Maintenance?
  content["Delete the Maintenance?"] =
  "Delete the Maintenance?";

  //Are you sure to delete this Maintenance permanently?
  content["Are you sure to delete this Maintenance permanently?"] =
  "Are you sure to delete this Maintenance permanently?";

  //Remove the Maintenance?
  content["Remove the Maintenance?"] =
  "Remove the Maintenance?";

  //Are you sure to remove all the devices from this Maintenance?
  content["Are you sure to remove all the devices from this Maintenance?"] =
  "Are you sure to remove all the devices from this Maintenance?";

  //Remove the Maintenance?
  content["End the Maintenance?"] =
  "End the Maintenance?";

  //Are you sure to stop this Maintenance?
  content["Are you sure to stop this Maintenance?"] =
  "Are you sure to stop this Maintenance?";

  //Yes
  content["Yes"] =
  "Yes";

  //No
  content["No"] =
  "No";

  //Create Maintenance schedule
  content["Create Maintenance Schedule"] =
  "Create Maintenance Schedule";

  //edit
  content["Edit Maintenance Schedule"] =
  "Edit Maintenance Schedule";

  //Nodevicestoadd
  content["No devices to add"] =
  "No devices to add";

  //Name
  content["Name"] =
  "Name";

  //start
  content["*"] =
  "*";

  //Description
  content["Description:"] =
  "Description:";

  // AccountDetails
  content["Account"] =
  "Account";

  // NOAccount
  content["No Account"] =
  "No Account";

  //  Operatorconsolent
  content["Operator console"] =
  "Operator console";

  //  once
  content["Once"] =
  "Once";

  //  daily
  content["Daily"] =
  "Daily";

  //  weekly
  content["Weekly"] =
  "Weekly";

  //  monthly
  content["Monthly"] =
  "Monthly";

  //  Every
  content["Every"] =
  "Every";

  //  day
  content["Day(s)"] =
  "Day(s)";

  //  days
  content["Days"] =
  "Days";

  //  Week
  content["Week(s)"] =
  "Week(s)";

  //  Month
  content["Month(s)"] =
  "Month(s)";

  //  Schedule End Date
  content["Schedule End Date"] =
  "Schedule End Date";

  //x
  content["X"] =
  "X";

  //never
  content["Never"] =
  "Never";

  //Schedule Start Date
  content["Schedule Start Date"] =
  "Schedule Start Date";

  //timeZone
  content["TimeZone"] =
  "TimeZone";

  //am
  content["AM"] =
  "AM";

  //PM
  content["PM"] =
  "PM";

  //(UTC+05:30)calcutt
  content["(UTC+05:30)calcutta"] =
  "(UTC+05:30)calcutta";

  //(UTC+05:00)colombo
  content["(UTC+05:00)colombo"] =
  "(UTC+05:00)colombo";

  //(UTC+05:45)kathmandu
  content["(UTC+05:45)kathmandu"] =
  "(UTC+05:45)kathmandu";

  //(UTC+05:00)Dhaka
  content["(UTC+05:00)Dhaka"] =
  "(UTC+05:00)Dhaka";

  //(UTC+05:30)yekaterinbrug
  content["(UTC+05:30)yekaterinbrug"] =
  "(UTC+05:30)yekaterinbrug";

  //sunday
  content["Sunday"] =
  "Sunday";

  //Monday
  content["Monday"] =
  "Monday";

  //tuesday
  content["Tuesday"] =
  "Tuesday";

  //Wed
  content["Wednesday"] =
  "Wednesday";

  //Thur
  content["Thursday"] =
  "Thursday";

  //Frid
  content["Friday"] =
  "Friday";

  //Sat
  content["Saturday"] =
  "Saturday";

  //maintenance
  content["Maintenance"] =
  "Maintenance";

  //First
  content["First"] =
  "First";

  //Second
  content["Second"] =
  "Second";

  //Third
  content["Third"] =
  "Third";

  //Fourth
  content["Fourth"] =
  "Fourth";

  //Last
  content["Last"] =
  "Last";

  //AT
  content["At"] =
  "At";

  //For
  content["For"] =
  "For";

  //On
  content["On"] =
  "On";

  //And
  content["And"] =
  "And";

  //Hour
  content["Hour"] =
  "Hour";

  //Hour
  content["Minute"] =
  "Minute";

  //On The
  content["On The"] =
  "On The";

  //Delete Confirmation
  content["Delete Confirmation"] =
  "Delete Confirmation";

  //Time Picker
  content["Time Picker"] =
  "Time Picker";

  //Enter Description
  content["Enter Description"] =
  "Enter Description";

  //Enter Name
  content["Enter Name"] =
  "Enter Name";

  //Save
  content["Save"] =
  "Save";

  //Update
  content["Update"] =
  "Update";

  //Date Picker
  content["Date Picker"] =
  "Date Picker";

  //end
  content["end"] =
  "end";

  //Close
  content["Close"] =
  "Close";

  //Confirm Exit
  content["Confirm Exit"] =
  "Confirm Exit";

  //Are you sure you want to close the dialog?
  content["Are you sure you want to close the dialog?"] =
  "Are you sure you want to close the dialog?";

  //Required
  content["Required"] =
  "Required";

  //Schedule
  content["Schedule"] =
  "Schedule";

  //novalue
  content["No Value"] =
  "No Value";

  //Action Menu
  content["Action Menu"] =
  "Action Menu";

  //Maintenance Name
  content["Maintenance Name"] =
  "Maintenance Name";

  //Description
  content["Description"] =
  "Description";

  //Maintenance Description
  content["Maintenance Description"] =
  "Maintenance Description";

  //Schedules
  content["Schedule Details"] =
  "Schedule Details";

  //Maintenance Schedule Details
  content["Maintenance Schedule Details"] =
  "Maintenance Schedule Details";

  //Status
  content["Status"] =
  "Status";

  //Maintenance Status
  content["Maintenance Status"] =
  "Maintenance Status";

  //actions
  content["Actions"] =
  "Actions";

  //Maintenance Actions
  content["Maintenance Actions"] =
  "Maintenance Actions";

  //No Data Found
  content["No Data Found"] =
  "No Data Found";

  //Remove this credential?
  content["Remove this credential?"] =
  "Remove this credential?";

  //Edit Maintenance
  content["Edit Maintenance"] =
  "Edit Maintenance";

  //Delete Maintenance
  content["Delete Maintenance"] =
  "Delete Maintenance";

  //Remove Maintenance
  content["Remove Maintenance System"] =
  "Remove Maintenance System";

  //End Maintenance'
  content["End Maintenance"] =
  "End Maintenance";

  //Update Devices
  content["Update Devices"] =
  "Update Devices";

  // END OF LOCALIZATION

export default content;
