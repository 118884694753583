// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Alarm Policies
  content["Alarm Policies"] =
  "警报策略";

  // END OF LOCALIZATION

export default content;
