// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Robot installer download and instructions
  content["Robot installer download and instructions"] =
  "Robot installer download and instructions";

  //via relay hub
  content["Robots manage the probes (monitoring agents) used to collect metric data from your systems, sending the data to Infrastructure Management via the hub."] =
  "Robots manage the probes (monitoring agents) used to collect metric data from your systems, sending the data to Infrastructure Management via the hub.";

  //ROBOT INSTALLERS
  content["ROBOT INSTALLERS"] =
  "ROBOT INSTALLERS";

  // Setup Wizard
  content["Setup Wizard"] =
  "Setup Wizard";

  //Robot deployment
  content["Robot deployment"] =
  "Robot deployment";

  //Deploy
  content["Deploy"] =
  "Deploy";

  //Add Devices
  content["Add Devices"] =
  "Add Devices";

  //Windows devices are not supported on this monitoring technology
  content["Windows devices are not supported on this monitoring technology"] =
  "Windows devices are not supported on this monitoring technology";

  //OK
  content["OK"] =
  "OK";

  //Windowds deploy help message
  content["A Windows relay hub must be selected to deploy a Windows device."] =
  "A Windows relay hub must be selected to deploy a Windows device.";

  //IP Address Required
  content["IP Address Required"] =
  "IP Address Required";

  //No duplicate IPs
  content["No duplicate IPs"] =
  "No duplicate IPs";

  //Hostname
  content["Hostname"] =
  "Hostname";

  //Required
  content["Required"] =
  "Required";

  //Username
  content["Username"] =
  "Username";

  //Username Required
  content["Username Required"] =
  "Username Required";

  //Password
  content["Password"] =
  "Password";

  //Password Required
  content["Password Required"] =
  "Password Required";

  //Sudo Password Required Message
  content["Sudo Password is only applicable for Linux systems where username is not \'root\'."] =
  "Sudo Password is only applicable for Linux systems where username is not \'root\'.";

  //Sudo password
  content["Sudo password"] =
  "Sudo password";

  //OS
  content["OS"] =
  "OS";

  //CentOS
  content["CentOS"] =
  "CentOS";

  //Debian
  content["Debian"] =
  "Debian";

  //Open SUSE
  content["openSUSE"] =
  "openSUSE";

  //RHEL
  content["RHEL"] =
  "RHEL";

  //SUSE
  content["SUSE"] =
  "SUSE";

  //Ubuntu
  content["Ubuntu"] =
  "Ubuntu";

  //solaris
  content["Solaris"] =
  "Solaris";

  //aix
  content["AIX"] =
  "AIX";

  //Windows
  content["Windows"] =
  "Windows";

  //help message of deploying on linux hub
  content["Windows can not be deployed on a UNIX hub"] =
  "Windows can not be deployed on a UNIX hub";

  //Profile Required
  content["Profile Required"] =
  "Profile Required";

  //ARCH
  content["ARCH"] =
  "ARCH";

  //64-bit
  content["64-bit"] =
  "64-bit";

  //32-bit
  content["32-bit"] =
  "32-bit";

  //sparcv9/sparc
  content["sparcv9"] =
  "sparcv9";

  //Architecture Required
  content["Architecture Required"] =
  "Architecture Required";

  //console.log("AddButtonEditor",this.props)
  //Add Row
  content["Add Row"] =
  "Add Row";

  //Edit Row
  content["Edit Row"] =
  "Edit Row";

  //Save Row
  content["Save Row"] =
  "Save Row";

  //Duplicate Row
  content["Duplicate Row"] =
  "Duplicate Row";

  //non admin Username
  content["Non Admin Username"] =
  "Non Admin Username";

  //Non admin Password
  content["Non Admin Password"] =
  "Non Admin Password";

  //Sudo password (Linux only)
  content["Sudo password (Linux only)"] =
  "Sudo password (Linux only)";

  //Device IP
  content["Device IP"] =
  "Device IP";

  //Architecture
  content["Architecture"] =
  "Architecture";

  //Too many robots deployed
  content["Too many robots deployed"] =
  "Too many robots deployed";

  // A CSV file was already imported
  content["A CSV file was already imported"] =
  "A CSV file was already imported";

  //Delete Row(s)
  content["Delete Row(s)"] =
  "Delete Row(s)";

  //AUTO DEPLOY
  content["Auto Deploy"] =
  "Auto Deploy";

  //Failed Robot Deployment
  content["Failed Robot Deployment"] =
  "Failed Robot Deployment";

  //Check your server for proper configuration
  content["Check your server for proper configuration."] =
  "Check your server for proper configuration.";

  //Click "Deploy" to add the devices to UIM
  content["Click Deploy to add the devices to UIM. Enabling monitoring happens on the next step."] =
  "Click Deploy to add the devices to UIM. Enabling monitoring happens on the next step.";

  //Click deploy to install the monitoring host.  When complete, click Next.
  content["Click deploy to install the monitoring host.  When complete, click Next."] =
  "Click deploy to install the monitoring host.  When complete, click Next.";

  // with the selected hub
  content["Devices added must be able to communicate with the selected  hub."] =
  "Devices added must be able to communicate with the selected  hub.";

  //Ports 48000 to 48025 must be open
  content["Ports 48000 to 48025 must be open"] =
  "Ports 48000 to 48025 must be open";

  //for the  hub to communicate with the CA Infrastructure Management application.
  content["for the  hub to communicate with the CA Infrastructure Management application."] =
  "for the  hub to communicate with the CA Infrastructure Management application.";

  //If monitoring cloud-based infrastructure, a  hub is needed for each isolated virtual network (such as AWS Virtual Private Cloud or Azure Virtual Network).
  content["If monitoring cloud-based infrastructure, a  hub is needed for each isolated virtual network (such as AWS Virtual Private Cloud or Azure Virtual Network)."] =
  "If monitoring cloud-based infrastructure, a  hub is needed for each isolated virtual network (such as AWS Virtual Private Cloud or Azure Virtual Network).";

  //Ensure that the
  content["Ensure that the "] =
  "Ensure that the ";

  //If you are configuring monitoring technologies supported only on Windows, such as Windows NT Services, select a Windows  hub.
  content["If you are configuring monitoring technologies supported only on Windows, such as Windows NT Services, select a Windows  hub."] =
  "If you are configuring monitoring technologies supported only on Windows, such as Windows NT Services, select a Windows  hub.";

  //Select a hub
  content["Select a hub"] =
  "Select a hub";

  // ADD  HUB...
  content["ADD  HUB..."] =
  "ADD  HUB...";

  //No hub selected
  content["*No hub selected"] =
  "*No hub selected";

  //If you are not installing with root access, use either sudo
  content["If you are not installing with root access, use either sudo"] =
  "If you are not installing with root access, use either sudo";

  //You can also use su to get a root shell and execute the command.
  content["You can also use su to get a root shell and execute the command."] =
  "You can also use su to get a root shell and execute the command.";

  //Copy the appropriate Windows installer to any folder on the target system:
  content["Extract the zip file and copy the appropriate Windows installer to any folder on the target system:"] =
  "Extract the zip file and copy the appropriate Windows installer to any folder on the target system:";

  //Copy the nms-robot-vars.cfg answer file to the same folder.
  content["Copy the nms-robot-vars.cfg answer file to the same folder and define the following parameters:"] =
  "Copy the nms-robot-vars.cfg answer file to the same folder and define the following parameters:";

  //update hub ip address in the nms-robot-vars.cfg file
  content["hubip: IP address of the server configured as the relay hub."] =
  "hubip: IP address of the server configured as the relay hub.";

  //update robot ip address in the nms-robot-vars.cfg file
  content["robotip: IP address of the server where you will execute the installer."] =
  "robotip: IP address of the server where you will execute the installer.";

  //Install the robot by executing:
  content["Install the robot by executing:"] =
  "Install the robot by executing:";

  //Installation
  content["Installation"] =
  "Installation";

  //Robot Installers (including answer file)
  content["Robot Installers (including answer file)"] =
  "Robot Installers (including answer file)";

  //The installation is complete. To start the robot, execute the following command:
  content["The installation is complete. To start the robot, execute the following command:"] =
  "The installation is complete. To start the robot, execute the following command:";

  //Execute the RobotConfigurer.sh script to configure the robot when the installer exits.
  content["Execute the RobotConfigurer.sh script to configure the robot when the installer exits."] =
  "Execute the RobotConfigurer.sh script to configure the robot when the installer exits.";

  //The rpm flags function as follows:
  content["The rpm flags function as follows:"] =
  "The rpm flags function as follows:";

  //The default install directory is /opt/nimsoft. To specify the installation directory, execute the following command, where <directory> is the full path:
  content["The default install directory is /opt/nimsoft. To specify the installation directory, execute the following command, where <directory> is the full path:"] =
  "The default install directory is /opt/nimsoft. To specify the installation directory, execute the following command, where <directory> is the full path:";

  //Copy the appropriate Linux installer to /opt on the target system:
  content["Extract the zip file and copy the appropriate Linux installer to /opt on the target system:"] =
  "Extract the zip file and copy the appropriate Linux installer to /opt on the target system:";

  //Install the robot with the following command, where &lt;arch&gt;is i386 or x86_64:
  content["Install the robot with the following command, where <arch> is i386 or x86_64:"] =
  "Install the robot with the following command, where <arch> is i386 or x86_64:";

  //The default install folder is C:\Program Files (x86)\Nimsoft for 32-bit systems, and C:\Program Files\Nimsoft for 64-bit systems.
  content["The default install folder is C:\\Program Files (x86)\\Nimsoft for 32-bit systems, and C:\\Program Files\\Nimsoft for 64-bit systems."] =
  "The default install folder is C:\\Program Files (x86)\\Nimsoft for 32-bit systems, and C:\\Program Files\\Nimsoft for 64-bit systems.";

  //To specify the folder, append the following parameter to the command. Quotation marks are required.
  content["To specify the folder, append the following parameter to the command. Quotation marks are required."] =
  "To specify the folder, append the following parameter to the command. Quotation marks are required.";

  //To specify the log file, append:
  content["To specify the log file, append:"] =
  "To specify the log file, append:";

  //After installation, the robot starts automatically.
  content["After installation, the robot starts automatically."] =
  "After installation, the robot starts automatically.";

  //Copy the Debian installer to /opt on the target system:
  content["Extract the zip file and copy the Debian installer to /opt on the target system:"] =
  "Extract the zip file and copy the Debian installer to /opt on the target system:";

  //Note that only 64 bit Debian systems are supported as robots.
  content["Note that only 64 bit Debian systems are supported as robots."] =
  "Note that only 64 bit Debian systems are supported as robots.";

  //Install the robot with the following command:
  content["Install the robot with the following command:"] =
  "Install the robot with the following command:";

  //Copy the Ubuntu installer to /opt on the target system:
  content["Extract the zip file and copy the Ubuntu installer to /opt on the target system:"] =
  "Extract the zip file and copy the Ubuntu installer to /opt on the target system:";

  //Note that only 64 bit Ubuntu systems are supported as robots.
  content["Note that only 64 bit Ubuntu systems are supported as robots."] =
  "Note that only 64 bit Ubuntu systems are supported as robots.";

  //(Ubuntu 16 only) Execute the following command to enable the robot service. Note: This step is not required for Ubuntu 14.
  content["(Ubuntu 16 only) Execute the following command to enable the robot service. Note: This step is not required for Ubuntu 14."] =
  "(Ubuntu 16 only) Execute the following command to enable the robot service. Note: This step is not required for Ubuntu 14.";

  //Copy the solaris installer to /opt on the target system:
  content["Extract the zip file and copy the Solaris installer to /opt on the target system:"] =
  "Extract the zip file and copy the Solaris installer to /opt on the target system:";

  //extract the solaris installer to /opt on the target system:
  content["Extract the gz file into to /opt on the target system:"] =
  "Extract the gz file into to /opt on the target system:";

  //Copy the aix installer to /opt on the target system:
  content["Extract the zip file and copy the AIX installer to /opt on the target system:"] =
  "Extract the zip file and copy the AIX installer to /opt on the target system:";

  //Note that only 64 bit AIX systems are supported as robots.
  content["Note that only 64 bit AIX systems are supported as robots."] =
  "Note that only 64 bit AIX systems are supported as robots.";

  //Execute the following command as the root user to install the robot to the default directory
  content["Install the robot with the following command as a root user."] =
  "Install the robot with the following command as a root user.";

  //Enable the Nimbus service as root
  content["Enable the Nimbus service as root."] =
  "Enable the Nimbus service as root.";

  //Start the robot as root. If the robot is configured to run as a nonroot account, the processes are running as the nonroot user.
  content["Installation is complete.Start the robot as root. If the robot is configured to run as a nonroot account, the processes are running as the nonroot user."] =
  "Installation is complete.Start the robot as root. If the robot is configured to run as a nonroot account, the processes are running as the nonroot user.";

  //To run the robot as a nonroot user account, take the following steps
  content["Steps to run the robot as a nonroot user account"] =
  "Steps to run the robot as a nonroot user account";

  //Add the nonroot user to
  content["Add the nonroot user to"] =
  "Add the nonroot user to";

  //Change the ownership of the
  content["Change the ownership of the"] =
  "Change the ownership of the";

  //file, and the UIM installation to the nonroot user
  content["file, and the UIM installation to the nonroot user:"] =
  "file, and the UIM installation to the nonroot user:";

  //installing message
  content["install the software package"] =
  "install the software package";

  //status message
  content["display a simple status line to show what is being installed (verbose mode)"] =
  "display a simple status line to show what is being installed (verbose mode)";

  // display message
  content["display fifty hash marks (#) to show the status as thevinstall proceeds; when all fifty have displayed, installation is complete."] =
  "display fifty hash marks (#) to show the status as thevinstall proceeds; when all fifty have displayed, installation is complete.";

  //RHEL & CentOS
  content["RHEL & CentOS"] =
  "RHEL & CentOS";

  // END OF LOCALIZATION

export default content;
