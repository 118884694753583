import connect from './../../utils/connect'
import AlarmPolicies from './AlarmPolicies'
//import { addBreadCrumb } from './../breadcrumbs/actions'
import {setBreadCrumbs} from './../breadcrumbs/actions'

const mapStateToProps = state => {
  return {
    featureFlags: state.configuration.items,
    sid: state.sid,
    saas: state.saas,
    acl: state.acl,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setBreadCrumbs: items => dispatch(setBreadCrumbs(items)),
    //addBreadCrumb: (crumb) => dispatch(addBreadCrumb(crumb)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AlarmPolicies)
