import React from "react";
import { useDrag } from "react-dnd";
import { Radio, FormControlLabel } from "@mineral/core";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import { makeStyles } from '@mui/styles'
import EditIcon from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import {Tooltip} from "@mineral/core";
import {IconButton} from "@mineral/core";
import "./PrdFilterRadioButton.less";
import AdvancedFilterEditDialog from "./filterDialog/AdvancedFilterEditDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "200px",
    maxHeight: "250px",
    overflow: "auto",
    width: "100%",
  },
  formLabelSelectAll: {
    margin: 0,
    borderBottom: "1px solid #C8D1E0",
  },
  formLabel: {
    margin: 0,
  },
  formControl: {
    width: "100%",
  },
  indeterminateColor: {
    color: "#00aeef",
  },
  dragIconColor: {
    color: "#8E99AB",
  },
  searchFieldHeight: {
    height: "10px",
  },
}));

const PrdFilterRadioButton = (props) => {
  const classes = useStyles();
  const [{ isDragging }, drag, dragPreview] = useDrag(() => ({
    // "type" is required. It is used by the "accept" specification of drop targets.
    type: "FilterItem",
    item: { filter: props.filter },
    // The collect function utilizes a "monitor" instance (see the Overview for what this is)
    // to pull important pieces of state from the DnD system.
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));
  return (
    <div style={{ display: "flex" }}>
      <div ref={drag} style={{ width: "75%" }}>
        <FormControlLabel
          className={classes.formLabel}
          value={props.filter.filterId}
          control={
            <>
              <DragIndicatorIcon />
              <Radio
                color="primary"
                size="small"
                checked={props.filter.filterId === props.value}
                onChange={() => {
                  props.onChange(props.filter.filterId);
                }}
                value={props.filter.filterId}
              />
            </>
          }
          label={props.filter.groupName}
        />
      </div>
      <div style={{ marginTop: "5px" }}>
        <AdvancedFilterEditDialog
          filterId={props.filter.filterId}
          filterValue={props.value}
          filter={props.filter}
        />
        <Tooltip arrow title="Delete">
          <span>
            <IconButton
              classes={{ root: "filterlist-icon" }}
              onClick={() => {
                props.deleteFilter(props.filter.filterId);
              }}
            >
              <Delete className="filterlist-redicon-color" />
            </IconButton>
          </span>
        </Tooltip>
      </div>
    </div>
  );
};

export default PrdFilterRadioButton;
