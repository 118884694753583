import {
    FETCH_DASHBOARD_PATHS,
    FETCH_DASHBOARD_PATHS_SUCCESS,
    FETCH_DASHBOARD_PATHS_FAILURE,
    FETCH_DASHBOARD_ROUTE_MAP,
    FETCH_DASHBOARD_ROUTE_MAP_SUCCESS,
    FETCH_DASHBOARD_ROUTE_MAP_FAILURE
  } from './actionTypes'
  import fetchStatusTypes from '../fetchStatusTypes'
  
  const dashboardPaths = (state = {
    isFetching: false,
    fetchStatus: fetchStatusTypes.UNDEFINED,
    didInvalidate: false,
    items: [],
    count:0,
    isFetchingRoutes: false,
    didInvalidateRoutes:false,
    routes:[],
  }, action) => {
  
    switch (action.type) {
      case FETCH_DASHBOARD_PATHS:
        return Object.assign({}, state, {
          isFetching: true,
          fetchStatus: fetchStatusTypes.FETCHING,
          didInvalidate: false,
          items: []
        });
      case FETCH_DASHBOARD_PATHS_SUCCESS:
        return Object.assign({}, state, {
          isFetching: false,
          fetchStatus: fetchStatusTypes.COMPLETE,
          didInvalidate: false,
          items: action.paths.paths,
          count: action.paths.count,
          
        });
      case FETCH_DASHBOARD_PATHS_FAILURE:
        return Object.assign({}, state, {
          isFetching: false,
          fetchStatus: fetchStatusTypes.FAIL,
          didInvalidate: true,
          errorMessage: action.errorMessage,
          items: []
        });
        case FETCH_DASHBOARD_ROUTE_MAP:
        return Object.assign({}, state, {
          isFetchingRoutes: true,
          fetchStatus: fetchStatusTypes.FETCHING,
          didInvalidateRoutes: false,
          routes: []
        });
      case FETCH_DASHBOARD_ROUTE_MAP_SUCCESS:
        return Object.assign({}, state, {
          isFetchingRoutes: false,
          fetchStatus: fetchStatusTypes.COMPLETE,
          didInvalidateRoutes: false,
          routes: action.routes,
          
        });
      case FETCH_DASHBOARD_ROUTE_MAP_FAILURE:
        return Object.assign({}, state, {
          isFetchingRoutes: false,
          fetchStatus: fetchStatusTypes.FAIL,
          didInvalidateRoutes: true,
          errorMessage: action.errorMessage,
          routes: []
        });
      
      default:
        return state
    }
  };
  
  export default dashboardPaths
  