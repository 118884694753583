import React, { Component } from "react";
import Tree, { TreeNode } from "rc-tree";
import { connect } from "react-redux";
import "./inventoryTree.less";
import { searchComputerSystems,getInventory} from "../../api/computer-systems/actions";
import InventoryIcons from "./../icons/InventoryIcons";
import entityTypes from './../../api/entityTypes'
import get from 'lodash/get'
import { setEntities } from './../entities/actions'
import {setSelectedAgent} from './../../api/DeviceDiscovery/agents/actions'

import { Button ,IconButton,Tooltip} from "@mineral/core";



class InventoryTree extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inventory: {},
    }
  }
  componentWillMount() {
    this.fetchInventoryInformation(this.props);
  }
  componentWillReceiveProps(nextProps) {
      this.fetchInventoryInformation(nextProps);
  }
  fetchInventoryInformation=(treeProps)=>{
    this.setState({
      inventory: treeProps.inventoryTreeData
    })
  }
  onSelect = (selectedKeys, info) => {
      var params = {daId: null, scopeId: null};
      if(info.node.props.agent_id===-1 || !info.node.props.agent_id){
       // params.append("q", "");
        this.props.openDiscoveryWizard(false);
      }else{
        params.daId = info.node.props.agent_id;
        if(info.node.props.node_type==='S'){
          params.scopeId = info.node.props.scope_id;
        }
        /*
        if(info.node.props.agent_id !==-10000001 && info.node.props.agent_id !==-10000002 && info.node.props.node_type!=='S'){
          this.props.setSelectedAgent({...info.node.props.data})
          this.props.openDiscoveryWizard(true);
        }else{
          this.props.openDiscoveryWizard(false);
        }
        */
        this.props.setSelectedAgent({...info.node.props.data})
        this.props.openDiscoveryWizard(true);
      }
      this.props.onNodeSelection(params).then(computerSystemsAction => {
        const computerSystems = computerSystemsAction.data
        return this.props.setEntities(computerSystems)
      });
  };
  render() {
    const arrowPath =
    "M7 6.55757C7 6.26494 7.08397 5.97823 7.24229 5.7303C7.70763 5.00157 8.68654 " +
    "4.78121 9.42874 5.2381L18.2562 10.6722C18.4583 10.7967 18.6293 10.9643 18.7563 " +
    "11.1626C19.2227 11.8907 18.9996 12.8521 18.2581 13.31L9.43061 18.761C9.17768 18.9171 8.88496 " +
    "19 8.58616 19C7.71015 19 7 18.3027 7 17.4426V6.55757Z";
    let data = this.state.inventory;
    const getSvg = (path, isExpanded,iStyle = {}, style = {}) => {
      return (
        <Tooltip title={isExpanded?'Collapse':'Expand'}>
        <IconButton  style={{padding:'2px',top:'5px'}} tabIndex={0}>
        <i role="button" aria-label={isExpanded?'Expanded':'Collapsed'} style={iStyle}>
        <svg
        viewBox="0 0 24 24"
        width="16"
        height="16"
        fill="#333840"
        style={{verticalAlign: "-.125em", ...style }}
        >
        <path d={path} />
        </svg>
        </i>
        </IconButton>
        </Tooltip>
        );
      };
      const switcherIcon = (obj) => {
        console.log(obj)
        if (!obj.isLeaf) {
         
          return getSvg(
            arrowPath,obj.expanded,
            { cursor: "pointer", backgroundColor: "#FFFFFF" },
            { transform: `rotate(${obj.expanded ? 90 : 0}deg)` }
            );
          }
        };
        const iconProps = {
          switcherIcon,
        };
        let agents = [data];
        const loop = (data,parentId) => {
          return data.map((item) => {
            let disco_id = item.id;
            let scope_id = item.id;
            const childrenNode = item.children
            ? loop(item.children,item.id)
            : null;
            if(item.type==='S'){
              disco_id = parentId;
            }
            return(
              <TreeNode
                className="cus-inventory-label"
                id={item.id}
                titlename={item.name}
                displayName={item.name}
                key={item.id}
                agent_id={disco_id}
                scope_id={scope_id}
              
                node_type={item.type}
                data={item}
                title={<span>
                <InventoryIcons tabIndex={-1}
                type={item.type}
                class="grid_inventory_icon"
                name={item.name} 
                /> <Button  disableRipple style={{padding :'none'}}  variant="text" tabIndex={0}>{item.name}({item.count})</Button>
                </span>}
                >
                {childrenNode}
                </TreeNode>
                );
              })
            };
            let treeNodes =agents?loop(agents,null):null;

            let view = (            
              <Tree
                onExpand={this.onExpand}
                autoExpandParent={true}
                defaultExpandParent={true}
                onSelect={this.onSelect}
                selectable={true}
                showIcon={false}
                {...iconProps}
                tabIndex={-1}
              >
              {treeNodes}
              </Tree>
              
              );
              return view;
            }
          }
          const mapDispatchToProps = (dispatch) => {
            return {
              onNodeSelection: (params) =>
              dispatch(searchComputerSystems(null,null,null,params)),
              getInventory:()=> dispatch(getInventory()),
              setEntities: computerSystems => {
                const items = get(computerSystems, '_items', []).map(item => {
                  return Object.assign({}, item, {
                    entityType: entityTypes.COMPUTER_SYSTEM
                  })
                })
                return dispatch(setEntities(items))
              },
              //set selected Agent in redux to open Discovery Wizard
              setSelectedAgent:(agentInfo)=>dispatch(setSelectedAgent(agentInfo)),
            };
          };
          export default connect(null,mapDispatchToProps)(InventoryTree);
