import React from 'react'
import {
  InfoIcon,
} from '../../ui-components/uim-components'
import Link from './../../common/reactRouter/Link'
import './manualDeployInfo.less'
import Resource from './Resource'


const ManualDeployInfo = (props) => {
	//instruction message
	const instructionmessage = Resource.get('To use robot installation form above, WMI or SSH access with installation permissions are required.For other situations (e.g. AWS & Azure or no installation permission) use the manual installation process through the')
	//SetupWizard
	const Setupwizard = Resource.get('Setup Wizard')		
  let message = (
    <p className='manual-deploy__info'>
      {instructionmessage} <Link to='/getting-started'>{Setupwizard}</Link>.
    </p>
  )
  if (props.message) {
    message = (
      <p className='manual-deploy__info'>
        {props.message}
      </p>
    )
  }
  return (
    <div className='manual-deploy'>
      <InfoIcon />
      {message}
    </div>
  )
}
export default ManualDeployInfo