// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  // one hour MenuItem
  content["1 Hour"] =
  "1 Stunde";

  // two hours MenuItem
  content["2 Hours"] =
  "2 Stunden";

  // twelve hours MenuItem
  content["12 Hours"] =
  "12 Stunden";

  // one day MenuItem
  content["24 Hours"] =
  "24 Stunden";

  // three days MenuItem
  content["3 Days"] =
  "3 Tage";

  // one week MenuItem
  content["1 Week"] =
  "1 Woche";

  // two weeks MenuItem
  content["2 Weeks"] =
  "2 Wochen";

  // one month MenuItem
  content["1 Month"] =
  "1 Monat";

  // three months MenuItem
  content["3 Months"] =
  "3 Monate";

  // END OF LOCALIZATION

export default content;
