import axios from 'axios'

class AlarmPolicies {
  getCount() {
    const url = '/policy_management_ws/api/v0/policy'
    return axios.get(url + '?onlyCount=true')
  }
}

const alarmPolicies = new AlarmPolicies()
export default alarmPolicies
