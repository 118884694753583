import React, { Component } from 'react'
import connect from './../../utils/connect'
import GroupConfigurationInfo from './GroupConfigurationInfo'
import ConfigurationRedux from './ConfigurationRedux'
import ConfigurationRedirect from './ConfigurationRedirect'
import entityTypes from './../../api/entityTypes'
import Resource from './Resource'

class ProtectedGroupConfiguration extends Component {
  state = {
    bypassToConfig: false,
  }
  deviceHasRobotCount = (entity) => {
    const devices = entity['computer-systems'] || []
    return devices.reduce((accumulator, device) => {
      const hasRobot = device.busType === "hub" || device.busType === "robot"

      if (!hasRobot) {
        return accumulator
      }
      return ++accumulator
    }, 0)
  }
  isComputerSystemContainer = (entity) => {
    return entity &&
      entity.type !== entityTypes.ROOT &&
      entity.type !== entityTypes.CONTAINER
  }
  redirectToLocation=(location,id)=>{
	    //console.log("redirect");
	    if(location ==='setupWizard') {
	    	this.props.history.push('/getting-started');
	    }
	    else if(location ==='inventory'){
	    	this.props.history.push('/inventory');
      }
      else if(location === 'alarmPolicy' && this.props.policyRedirect){
        this.props.policyRedirect(id);
      }
 }
  
  render() {
	// instruction message 
	const helplabelmessage = Resource.get('To add additional monitoring to the devices in this group, local robots must be installed')  
	//  instruction message1 
    const helplabelmessage1 = Resource.get('Use the Setup Wizard to add local monitoring based on a device\'s operating system: Windows or Linux.')			
    const isComputerSystemContainer = this.isComputerSystemContainer(this.props.entity)
    const deviceHasRobotCount = this.deviceHasRobotCount(this.props.entity)
    const deviceCount = (this.props.entity['computer-systems'] || []).length
    let view = <ConfigurationRedux redirectToLocation={this.redirectToLocation} {...this.props} />

    if (isComputerSystemContainer && (deviceHasRobotCount !== deviceCount)) {
      view = (
        <GroupConfigurationInfo
          open={true}
          deviceCount={deviceCount}
          deviceHasRobotCount={deviceHasRobotCount}
          onClose={() => {
            this.setState({bypassToConfig: true})
          }} />
      )
    }
    if (isComputerSystemContainer && (deviceHasRobotCount === 0) && (deviceCount !== 0)) {
      let showonlyremotetemplate=true;
      view = <ConfigurationRedux redirectToLocation={this.redirectToLocation} showonlyremotetemplate={showonlyremotetemplate} {...this.props} />
	  }
    
    if (this.state.bypassToConfig) {
      view = <ConfigurationRedux redirectToLocation={this.redirectToLocation} {...this.props} />
    }
    return view
  }
}
const mapStateToProps = (state) => {
  return {
    entity: state.entity,
  }
}
export default connect(mapStateToProps, null)(ProtectedGroupConfiguration)