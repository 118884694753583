import axios from 'axios'
import config from './../config'

class HistoricAlarms {
  get(params = {}) {
    let url = [config.basename]
    if (params.groupId) {
      url = url.concat([
        `api/v1/historical-alarms?groupId=${params.groupId}`,
      ])
    }
    if (params.csId) {
      url = url.concat([
        `api/v1/historical-alarms?csId=${params.csId}`,
      ])
    }
    return axios.get(url.join('/'))
  }
}

const historicAlarms = new HistoricAlarms()
export default historicAlarms

