import React from "react";
import styled from "styled-components";
import { Component } from "react";
import SplitPane from "react-split-pane";
import LeftPane from "./LeftPane";
import RightPane from "./RightPane";
import Resource from "./Resource";
import connect from "./../../../utils/connect";
import { setBreadCrumbs } from "./../../breadcrumbs/actions";
import { withRouter } from "react-router-dom";
import { Typography } from "@mineral/core";

const manageDisplayPreferencesLabel=Resource.get("Manage display preferences") //Manage Display Preferences
const customizeSubTitle=Resource.get("Customize default views to user after login.") //customizeSubTitle
const Wrapper = styled.div`
  .Resizer {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background: #000;
    opacity: 0.2;
    z-index: 1;
    -moz-background-clip: padding;
    -webkit-background-clip: padding;
    background-clip: padding-box;
  }

  .Resizer:hover {
    -webkit-transition: all 2s ease;
    transition: all 2s ease;
  }

  .Resizer.horizontal {
    height: 11px;
    margin: -5px 0;
    border-top: 5px solid rgba(255, 255, 255, 0);
    border-bottom: 5px solid rgba(255, 255, 255, 0);
    cursor: row-resize;
    width: 100%;
  }

  .Resizer.horizontal:hover {
    border-top: 5px solid rgba(0, 0, 0, 0.5);
    border-bottom: 5px solid rgba(0, 0, 0, 0.5);
  }

  .Resizer.vertical {
    width: 11px;
    margin: 0 -5px;
    border-left: 5px solid rgba(255, 255, 255, 0);
    border-right: 5px solid rgba(255, 255, 255, 0);
    cursor: col-resize;
  }

  .Resizer.vertical:hover {
    border-left: 5px solid rgba(0, 0, 0, 0.5);
    border-right: 5px solid rgba(0, 0, 0, 0.5);
  }
  .Pane1 {
    background-color: white;
  }
  .Pane2 {
    background-color: white;
  }
`;

class SplitView extends Component {
  constructor() {
    super();
    this.state = {
      selectedDropdownValue: "",
      selectedPage: "",
    };
    this.selectedDropDown = this.selectedDropDown.bind(this);
  }
  componentDidMount() {
    this.props.setBreadCrumbs([
      {
        //Settings
        name: Resource.get("Settings"),
        link: "/settings",
      },
      {
        //Personalize
        name: Resource.get("Personalize"),
        link: "/settings/personalize",
      },
    ]);
  }
  componentWillMount() {
    this.setState({
      btmHeight: "",
    });
  }
  selectedDropDown(value) {
    let page = "";
    switch (value) {
      case "Home":
        page = "overview";
        break;
      case "Groups":
        page = "groups/0/treeviewnew";
        break;
      case "Alarms":
        page = "uim-alarms";
        break;
      case "Inventory":
          page = "inventory";
          break;
      case "Reports":
          page = "reports";
          break;
      case "Dashboard":
            page = "technologies";
            break;
      case "SLA":
            page = "slm";
            break;
      case "Inaccessible":
            page="noaccess"
            break;
      
      default:
        break;
    }
    this.setState({ selectedDropdownValue: value, selectedPage: page });
  }
  render() {
    return (
      <Wrapper style={{"background-color":"#FFFFFF"}}>
        <div style={{ display: "flex", flexDirection: "column" , height:"64px"}}>
          <Typography variant="h2"
            style={{
              "font-family": "Roboto",
"font-style": "normal",
"font-weight": "bold",
"font-size": "20px",
"line-height": "23px",

"color": "#5B676C",
marginLeft:"16px", 
marginTop:"12px",

            }}
          >
            {manageDisplayPreferencesLabel}
          </Typography>
          <span style={{ paddingBottom: "6px", 
              "font-family": "Roboto",
"font-style": "normal",
"font-weight": "normal",
"font-size": "12px",
"line-height": "14px",

"color": "#666666",
marginLeft:"16px", 
marginTop:"8px",
            }}>
            {customizeSubTitle}
            
          </span>
        </div>
        <div
          style={{
            display: "flex",
            flex: "1 1 10 %",
            height: "100%",
            position: "absolute",
            outline: "none",
            overflow: "hidden",
            userSelect: "text",
            flexDirection: "row",
            width: "100%",
            left: "56px",
            right: "inherit",
          }}
        >
          <LeftPane selectedDropDown={this.selectedDropDown} />
          <RightPane
            selectedValue={this.state.selectedDropdownValue}
            selectedPage={this.state.selectedPage}
          />
        </div>
      </Wrapper>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    setBreadCrumbs: (items) => dispatch(setBreadCrumbs(items)),    
  };
};
//export default SplitView;

export default connect(null,mapDispatchToProps)(withRouter(SplitView));