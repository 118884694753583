import React from 'react'
import {Link as RouterLink} from 'react-router-dom'
import theme from './../../../theme'

const Link = (props) => <RouterLink {...props} />

Link.defaultProps = {
  style: {
    color: theme.palette.primary1Color,
  },
}

export default Link
