import {
  FETCH_QoS,
  FETCH_QoS_Groups,
  FETCH_QoS_SUCCESS,
  FETCH_QoS_FAILURE,
  SAVE_QoS,
  SAVE_QoS_SUCCESS,
  SAVE_QoS_FAILURE,
  Group_SUCCESS,
  FETCH_QoS_Groups_FAILURE,
  FETCH_Groups,
  QOS_EACH_SUCCESS,
  QOS_EACH_FAILURE,
  GET_EACH_QOS,
  DELETE_QoS,
  DELETE_SUCCESS,
  DELETE_FAILURE


} from "./actionTypes";

const qos = (
  state = {
    isFetching: false,
    didInvalidate: false,
    isSaving: false,
    error: "",
    isDelete: false,
  },
  action
) => {
  switch (action.type) {

    case FETCH_QoS:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false,
      });

    case FETCH_Groups:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false,
      });

    case GET_EACH_QOS:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false,
      });

    case FETCH_QoS_Groups:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false,
      });

    case FETCH_QoS_SUCCESS:

      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false,
        items: action.qos,
      });

    case Group_SUCCESS:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false,
        qosgroups: action.qosgroups,
      });
    case QOS_EACH_SUCCESS:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false,
        allqos: action.allqos,
      });

    case QOS_EACH_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        errorMessage: action.errorMessage,
        allqos: [],
      });
    case FETCH_QoS_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        errorMessage: action.errorMessage,
        items: [],
      });
    case FETCH_QoS_Groups_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        errorMessage: action.errorMessage,
        items: [],
      });
    case SAVE_QoS:
      return {
        ...state,
        isSaving: true,
      };
      case DELETE_QoS:
        return {
          ...state,
          isDelete: true,
        };

    case SAVE_QoS_SUCCESS:
      return {
        ...state,
        isSaving: true,
      };
    case SAVE_QoS_FAILURE:
      return {
        ...state,
        isSaving: false,
        didInvalidate: true,
        errorMessage: action.errorMessage,
      };

      case DELETE_SUCCESS:
        return {
          ...state,
          isDelete: true,
        };
      case DELETE_FAILURE:
        return {
          ...state,
          isDelete: false,
          didInvalidate: true,
          errorMessage: action.errorMessage,
        };

    default:
      return state;
  }
};

export default qos;
